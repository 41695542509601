import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoginLogo from 'assets/images/LoginLogo.png';
import styles from './style.module.scss';

export default class Layout extends Component {
  renderLeftBow = () => {
    return (
      <svg
        className={styles['left-bow']}
        width="632"
        height="607"
        viewBox="0 0 632 607"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.812"
          d="M-836.705 -626.904C-787.382 -646.539 -731.103 -649.407 -679.376 -634.922C148.911 -404.081 500.779 131.211 613.621 350.643C661.061 442.9 611.474 550.417 503.13 590.746C451.044 610.123 392.041 611.108 339.101 593.483C286.161 575.859 243.621 541.068 220.839 496.766C131.128 322.306 -149.185 -103.44 -812.858 -288.399C-853.71 -299.784 -889.594 -321.353 -915.849 -350.306C-942.104 -379.259 -957.518 -414.257 -960.089 -450.757C-962.66 -487.256 -952.269 -523.57 -930.266 -554.982C-908.262 -586.394 -875.663 -611.453 -836.701 -626.904L-836.705 -626.904Z"
          fill="white"
          fill-opacity="0.03"
        />
      </svg>
    );
  };
  renderRightBow = () => {
    return (
      <svg
        className={styles['right-bow']}
        width="674"
        height="714"
        viewBox="0 0 674 714"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.812"
          d="M1443.56 1639.84C1382.1 1653.92 1316.64 1647.49 1260.57 1621.88C362.418 1213.07 85.3051 536.187 7.84508 264.139C-24.7182 149.761 58.0333 34.5866 192.362 6.894C256.937 -6.40703 325.08 2.65575 381.8 32.0887C438.519 61.5216 479.17 108.914 494.808 163.839C556.387 380.131 777.312 918.57 1496.97 1246.13C1541.27 1266.29 1577.41 1297.32 1600.71 1335.18C1624.02 1373.04 1633.4 1415.98 1627.64 1458.43C1621.88 1500.88 1601.25 1540.88 1568.42 1573.23C1535.59 1605.58 1492.09 1628.79 1443.55 1639.84L1443.56 1639.84Z"
          fill="white"
          fill-opacity="0.03"
        />
      </svg>
    );
  };

  render() {
    return (
      <div className={styles['background']}>
        {this.renderLeftBow()}
        {this.renderRightBow()}
        <PerfectScrollbar>
          <div className={styles['column']}>
            <div className={styles['logo']}>
              <img src={LoginLogo} alt="logo" />
            </div>
            <div className={styles['container']}>{this.props.children}</div>
            <div className={styles['sign-up']}>
              Don't have an account? <a href="https://mainter.com/sv/intresseanmalan">Sign up</a>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}
