/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getEditingWorkOrder = state => {
  const { newWorkOrder } = state;
  return newWorkOrder.editingWorkOrder == null ? null : newWorkOrder.editingWorkOrder;
};

const editingWorkOrderIsRecurring = state => {
  const { newWorkOrder } = state;
  const { editingWorkOrder } = newWorkOrder;
  if (editingWorkOrder) {
    if (editingWorkOrder.completed_before_registration !== true) {
      if (editingWorkOrder.frequency) {
        return true;
      }
      if (editingWorkOrder.meter_interval) {
        return true;
      }
    }
  }
  return false;
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const hasUnsavedChanges = state => state.newWorkOrder.hasUnsavedChanges;

export default {
  hasUnsavedChanges,
  getEditingWorkOrder,
  editingWorkOrderIsRecurring,
};
