import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Decimal } from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingSelectors } from 'state/ducks/stocktaking';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, InlineModal, List, Field, Tooltip, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  decimalFieldRef = null;

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      value: props.stocktakingRow ? props.stocktakingRow.quantity : '',
      showEditModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.setFocus === false && this.props.setFocus === true && this.decimalFieldRef) {
      this.decimalFieldRef.select();
    }
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.save();
    }
  };

  save = () => {
    if (!this.state.value) return null;

    if (this.props.stocktakingRow) {
      this.updateStocktakingRow();
    } else {
      this.createStocktakingRow();
    }
  };

  createStocktakingRow = () => {
    this.setState({ isSaving: true });
    const stocktakingId = this.props.match.params.id;
    const params = {
      spare_part_id: this.props.sparePart.id,
      quantity: this.state.value,
    };

    this.props
      .createStocktakingRow(stocktakingId, params)
      .then(({ data }) => {
        this.setState({ isSaving: false, displayEdit: false });
        this.props.onListWasSaved();
      })
      .catch(e => {
        this.setState({ isSaving: false, displayEdit: false });
      });
  };

  updateStocktakingRow = () => {
    this.setState({ isSaving: true });
    const params = {
      spare_part_id: this.props.sparePart.id,
      quantity: this.state.value,
    };

    this.props
      .updateStocktakingRow(this.props.stocktakingRow.id, params)
      .then(({ data }) => {
        this.setState({ isSaving: false, showEditModal: false });
      })
      .catch(e => {
        this.setState({ isSaving: false, showEditModal: false });
      });
  };

  deleteStocktakingRow = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteStocktakingRow(this.props.stocktakingRow.id)
      .then(({ data }) => {
        this.setState({ isDeleting: false, value: '' });
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  renderIconButtons = () => {
    if (!this.props.stocktakingRow || this.props.stocktaking.completed) return null;
    if (this.props.canCreateWithdrawals === false) {
      return null;
    }
    return (
      <div className={styles['icon-buttons']}>
        {this.renderEditInlineModal(
          <Tooltip
            trigger={
              <div>
                <Button
                  type="icon"
                  icon={<Icon regular type="pen" />}
                  onClick={() => {
                    this.setState({ showEditModal: true, value: this.props.stocktakingRow.quantity });
                  }}
                />
              </div>
            }
            label={<FormattedMessage id="screens.stocktaking-detail.item.edit-tooltip" />}
          />
        )}
        <Tooltip
          trigger={
            <div>
              <Button
                type="icon"
                icon={<Icon regular red type="trash-alt" />}
                loading={this.state.isDeleting}
                onClick={this.deleteStocktakingRow}
              />
            </div>
          }
          label={<FormattedMessage id="screens.stocktaking-detail.item.delete-tooltip" />}
        />
      </div>
    );
  };

  renderEditInlineModal = trigger => (
    <>
      <div ref={ref => (this.inlineModalPositioningRef = ref)}>{trigger}</div>
      <InlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.showEditModal}
        onClose={() => this.setState({ showEditModal: false })}
        position="right"
      >
        <React.Fragment>
          <InlineModal.Header
            title={<FormattedMessage id="screens.stocktaking-detail.edit-row-modal.title" />}
            onClose={() => this.setState({ showEditModal: false })}
          />
          <InlineModal.Body width={290}>
            <Field.Decimal
              autoFocus
              rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
              value={this.state.value}
              onKeyPress={this.handleKeyPress}
              onChange={value => {
                this.setState({ value });
              }}
            />
            <InlineModal.Footer>
              <Button primary loading={this.state.isSaving} label="general.save" onClick={this.save} />
            </InlineModal.Footer>
          </InlineModal.Body>
        </React.Fragment>
      </InlineModal>
    </>
  );

  renderDifference = () => {
    if (new Decimal(this.props.stocktakingRow.difference).greaterThan(new Decimal(0))) {
      return (
        <span className={styles['difference-positive']}>
          {'(+'}
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.stocktakingRow.difference}
          />
          {')'}
        </span>
      );
    } else if (new Decimal(this.props.stocktakingRow.difference).lessThan(new Decimal(0))) {
      return (
        <span className={styles['difference-negative']}>
          {'('}
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.stocktakingRow.difference}
          />
          {')'}
        </span>
      );
    } else {
      return (
        <span className={styles['difference-neutral']}>
          {'(+-'}
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.stocktakingRow.difference}
          />
          {')'}
        </span>
      );
    }
  };

  renderInventoryColumn = () => {
    if (!this.props.stocktakingRow || this.state.displayEdit) {
      if (this.props.canCreateWithdrawals) {
        return (
          <div className={styles['field-container']}>
            <div className={styles['label']}>
              <FormattedMessage id="screens.stocktaking-detail.item.quantity" />:
            </div>
            <div className={styles['input']}>
              <Field.Decimal
                alignRight
                ref={ref => (this.decimalFieldRef = ref)}
                rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
                value={this.state.value}
                onKeyPress={this.handleKeyPress}
                onChange={value => {
                  this.setState({ value });
                }}
              />
            </div>
            <Button
              primary
              disabled={!this.state.value}
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
            />
          </div>
        );
      }
      return (
        <span className={styles['not-filled-in']}>
          <FormattedMessage id="screens.stocktaking-detail.not-filled-in" />
        </span>
      );
    } else {
      return (
        <span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.stocktakingRow.quantity}
          />
          <span> {this.renderDifference()}</span>
        </span>
      );
    }
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <>
          <List.Item iconButtons={this.renderIconButtons()}>
            <List.Item.ImageColumn
              imageId={this.props.images.length === 0 ? null : this.props.images[0].id}
            />
            <List.Item.Column width={70}>
              <span className={styles['art-no']}>#{this.props.sparePart.article_number}</span>
            </List.Item.Column>
            <List.Item.TitleColumn title={this.props.sparePart.title} />
            <List.Item.Column width={350} alignRight>
              {this.renderInventoryColumn()}
            </List.Item.Column>
          </List.Item>
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createStocktakingRow: SDKReduxOperations.createStocktakingRow,
      updateStocktakingRow: SDKReduxOperations.updateStocktakingRow,
      deleteStocktakingRow: SDKReduxOperations.deleteStocktakingRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  const stocktakingRow = StocktakingSelectors.getStocktakingRowForSparePart(state, sparePart.id);
  let stocktaking;
  if (stocktakingRow) {
    stocktaking = EntitySelectors.getStocktaking(state, stocktakingRow.stocktaking_id);
  }

  return {
    sparePart,
    stocktakingRow,
    stocktaking,
    images: EntitySelectors.getImages(state, sparePart.images),
    sparePartType: EntitySelectors.getSparePartType(state, sparePart.spare_part_type_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartListItem));
