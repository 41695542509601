const getLoading = state => state.stocktaking.loading;
const getStocktakingId = state => state.stocktaking.id;
const getShowSparePartLocationTab = (state, id) => state.stocktaking.showSparePartLocationTab;
const getHideCompletedCard = (state, id) => state.stocktaking.hideCompletedCard;
const getIsDeleted = state => state.stocktaking.isDeleted;

const getSparePartLocationsForParentId = (state, parentId) => {
  const { stocktaking, entities } = state;
  if (!stocktaking.sparePartLocationIds) return null;

  return stocktaking.sparePartLocationIds
    .map(id => entities.sparePartLocationById[id])
    .filter(sparePartLocation => sparePartLocation.spare_part_location_parent_id === parentId);
};

const getCountForLocation = (state, id) => state.stocktaking.sparePartLocationCounts[id];

const getSparePartIds = state => state.stocktaking.spareParts.ids;
const getSparePartsTotalEntries = state => state.stocktaking.spareParts.totalEntries;
const getSparePartsIsFullyLoaded = state => state.stocktaking.spareParts.isFullyLoaded;
const getSparePartsPaginateFrom = state => state.stocktaking.spareParts.paginateFrom;
const getSparePartsLoading = state => state.stocktaking.spareParts.loading;
const getSparePartsLoadingMore = state => state.stocktaking.spareParts.loadingMore;

const getStocktakingRowForSparePart = (state, sparePartId) => {
  const { stocktaking, entities } = state;

  const stocktakingRowId = stocktaking.stocktakingRowIdForSparePart[sparePartId];
  return entities.stocktakingRowById[stocktakingRowId];
};

export default {
  getLoading,
  getStocktakingId,
  getShowSparePartLocationTab,
  getHideCompletedCard,
  getIsDeleted,
  getSparePartLocationsForParentId,
  getCountForLocation,
  getSparePartIds,
  getSparePartsTotalEntries,
  getSparePartsIsFullyLoaded,
  getSparePartsPaginateFrom,
  getSparePartsLoading,
  getSparePartsLoadingMore,
  getStocktakingRowForSparePart,
};
