import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Banner } from 'views/components/Shared/General';
import styles from './style.module.scss';

export const SCIMFieldsDisabledBanner = () => {
  return (
    <div className={styles['banner-container']}>
      <Banner orange>
        <FormattedMessage id="screens.settings.user.general.sso-disabled-fields" />
      </Banner>
    </div>
  );
};
