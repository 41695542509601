import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash-es';
import { Decimal } from 'decimal.js';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { normalizeSparePart } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TemplateField } from 'views/components/TemplateField';
import {
  Field,
  Button,
  FieldErrorWrapper,
  Icon,
  Loader,
  Tooltip,
  MoneyWithCurrency,
} from 'views/components/Shared/General';
import { SelectVendorModal, NewVendorModal } from 'views/components/Vendor';
import { SideModal, Grid } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import { ChangeCurrencyModal } from 'views/components/General';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import SparePartTypeField from './SparePartTypeField';
import SparePartUnitField from './SparePartUnitField';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import SparePartStorageLocationField from './SparePartStorageLocationField';
import SparePartVendorInfo from './SparePartVendorInfo';
import DuplicateSparePartsModal from './DuplicateSparePartsModal';
import styles from './styles.module.scss';

const checkDuplicateSparePartsRequest = HelperFunctions.getCancelTokenForRequest();

class NewSparePartModal extends Component {
  initialState = () => ({
    isSaving: false,
    isSavingWithCreateNew: false,
    hasUnsavedChanges: false,
    editingSparePart: this.buildEmptyEditingSparePart(),
    editingSparePartBeforeChange: this.buildEmptyEditingSparePart(),
    editingSparePartFields: [],
    showMinLargerThanMaxError: false,
    showMaxSmallerThanMinError: false,
    showStockQuantityRequired: false,
    activateStockQuantity: true,
    showNameError: false,
    defaultSparePartUnit: this.getDefaultSparePartUnit() || null,
    duplicateVendors: true,
    duplicateAssets: true,
    duplicateFiles: true,
    showSelectVendorModal: false,
    showCreateVendorModal: false,
    createSparePartVendorForVendorId: null,
    showPackageQuantity: false,
    showPackageQuantityBiggerThanZeroError: false,
    primaryVendor: false,
    isCheckingDuplicateArticleNumber: false,
    showArticleNumberAlreadyExistsWarning: false,
    showDuplicateSparePartsModal: false,
    showChangeCurrencyModal: false,
    duplicateSparePartIds: [],
    editingSparePartVendor: {
      title: '',
      number: null,
      purchase_price: null,
      package_quantity: null,
    },
    editingSparePartAsset: {
      quantity: null,
    },
  });

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.checkDuplicatedArticleNumber(this.props.currentSystem.next_spare_part_article_number);
      let activateStockQuantity = true;
      if (
        this.props.duplicate &&
        this.props.defaultParams &&
        this.props.defaultParams.stock_quantity == null
      ) {
        activateStockQuantity = false;
      }
      this.setState({
        ...this.initialState(),
        createSparePartVendorForVendorId: this.props.createForVendorId,
        activateStockQuantity,
        editingSparePartFields: this.buildEditingSparePartFields(),
      });
    }

    if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  checkDuplicatedArticleNumber = article_number => {
    const params = {
      article_number: {
        [HelperFunctions.FILTER_COMPARABLES.Exact]: article_number,
      },
    };
    API.listSpareParts(
      this.props.currentSystem.id,
      params,
      checkDuplicateSparePartsRequest.getCancelTokenConfig()
    )
      .then(({ data }) => {
        const { entities, result: duplicateSparePartIds } = normalizeSparePart(data);
        this.props.updateEntities(entities);
        this.setState({
          isCheckingDuplicateArticleNumber: false,
          showArticleNumberAlreadyExistsWarning: data.length > 0,
          duplicateSparePartIds,
        });
      })
      .catch(() => {
        this.setState({
          isCheckingDuplicateArticleNumber: false,
          showArticleNumberAlreadyExistsWarning: false,
        });
      });
  };

  getDefaultSparePartUnit = () => find(this.props.sparePartUnits, { default: true });

  buildEmptyEditingSparePart = () => {
    const {
      title,
      description,
      spare_part_unit_id,
      spare_part_type_id,
      spare_part_location_id,
      stock_quantity,
      minimum_quantity,
      maximum_quantity,
      reorder_point,
      purchase_price,
      purchase_price_exchange_rate,
      purchase_price_currency,
    } = this.props.duplicateFromSparePart || {};
    const defaultSparePartUnit = this.getDefaultSparePartUnit();

    return {
      title: '',
      description: '',
      article_number: '',
      spare_part_type_id: null,
      spare_part_unit_id: defaultSparePartUnit == null ? null : defaultSparePartUnit.id,
      spare_part_location_id: null,
      purchase_price: null,
      stock_quantity: null,
      reorder_point: null,
      minimum_quantity: null,
      maximum_quantity: null,
      primary_vendor_id: null,
      purchase_price_exchange_rate: purchase_price_exchange_rate || '1',
      purchase_price_currency: purchase_price_currency || this.props.currentSystem.currency,
      ...{
        title,
        description,
        spare_part_unit_id,
        spare_part_type_id,
        spare_part_location_id,
        purchase_price,
        stock_quantity,
        minimum_quantity,
        maximum_quantity,
        reorder_point,
      },
      ...this.props.defaultParams,
    };
  };

  buildEditingSparePartFields = () => {
    return this.props.templateFields.reduce((acc, templateField) => {
      const sparePartField = this.props.sparePartFieldsFromDuplicatedSparePart.find(
        sparePartField => sparePartField.template_field_id === templateField.id
      );

      //sparePartField can be null if a new templatefield is created through the socket
      return {
        ...acc,
        [templateField.id]: sparePartField == null ? '' : sparePartField.value,
      };
    }, {});
  };

  duplicateSparePart = reopenAfterSave => {
    const { duplicateAssets, duplicateVendors, duplicateFiles, editingSparePart, activateStockQuantity } =
      this.state;
    this.props
      .duplicateSparePart(this.props.duplicateFromSparePartId, {
        ...editingSparePart,
        spare_part_fields: this.buildSparePartFieldsData(),
        stock_quantity: activateStockQuantity ? editingSparePart.stock_quantity : null,
        include_spare_part_assets: duplicateAssets,
        include_spare_part_vendors: duplicateVendors,
        include_attachments: duplicateFiles,
      })
      .then(({ data: sparePart }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.new-spare-part-modal.duplicate.duplicate-success" />}
          />
        );
        this.setState({ isSaving: false, isSavingWithCreateNew: false });
        if (reopenAfterSave) {
          this.props.onCreatedWithReopen(sparePart);
        } else {
          this.props.onCreated(sparePart);
        }
      });
  };

  getParamsForNewSparePart = () => {
    const {
      showPackageQuantity,
      editingSparePart,
      editingSparePartVendor,
      editingSparePartAsset,
      activateStockQuantity,
    } = this.state;
    let params = {
      ...editingSparePart,
      spare_part_fields: this.buildSparePartFieldsData(),
    };
    if (activateStockQuantity) {
      params = {
        ...params,
        stock_quantity: editingSparePart.stock_quantity,
      };
    }
    if (this.state.createSparePartVendorForVendorId) {
      const { number, purchase_price, title, package_quantity } = editingSparePartVendor;
      params = {
        ...params,
        spare_part_vendors: [
          {
            vendor_id: this.state.createSparePartVendorForVendorId,
            number,
            purchase_price,
            title,
            package_quantity: showPackageQuantity ? package_quantity : null,
          },
        ],
      };
    }
    if (this.props.createForAsset) {
      const { quantity } = editingSparePartAsset;
      params = {
        ...params,
        spare_part_assets: [
          {
            asset_id: this.props.createForAsset.id,
            quantity,
          },
        ],
      };
    }

    return params;
  };

  createSparePart = reopenAfterSave => {
    this.props.createSparePart(this.props.currentSystem.id, this.getParamsForNewSparePart()).then(data => {
      this.setState({ isSaving: false, isSavingWithCreateNew: false });
      if (reopenAfterSave) {
        this.props.onCreatedWithReopen(data.data);
      } else {
        this.props.onCreated(data.data);
      }
    });
  };

  save = reopenAfterSave => {
    if (!this.validateSave()) return null;
    if (reopenAfterSave) {
      this.setState({
        isSavingWithCreateNew: true,
        hasUnsavedChanges: false,
      });
    } else {
      this.setState({ isSaving: true, hasUnsavedChanges: false });
    }

    if (this.props.duplicate) {
      this.duplicateSparePart(reopenAfterSave);
    } else {
      this.createSparePart(reopenAfterSave);
    }
  };

  validateSave = () => {
    let stateErrors = {};
    if (
      this.state.showPackageQuantity &&
      new Decimal(this.state.editingSparePartVendor.package_quantity || 0).lessThanOrEqualTo(new Decimal(1))
    ) {
      stateErrors = {
        ...stateErrors,
        showPackageQuantityBiggerThanZeroError: true,
      };
    }
    if (!this.state.editingSparePart.title) {
      stateErrors = {
        ...stateErrors,
        showNameError: true,
      };
    }
    if (this.props.hasProTier) {
      if (this.state.activateStockQuantity && !this.state.editingSparePart.stock_quantity) {
        stateErrors = {
          ...stateErrors,
          showStockQuantityRequired: true,
        };
      }
      if (this.state.showMinLargerThanMaxError) {
        stateErrors = {
          ...stateErrors,
          showMinLargerThanMaxError: true,
        };
      }
      if (this.state.showMaxSmallerThanMinError) {
        stateErrors = {
          ...stateErrors,
          showMaxSmallerThanMinError: true,
        };
      }
    }
    this.setState({
      ...stateErrors,
    });
    return Object.keys(stateErrors).length === 0;
  };

  setSparePartValue = values => {
    const editingSparePart = { ...this.state.editingSparePart, ...values };

    const hasUnsavedChanges = !isEqual(this.state.editingSparePartBeforeChange, editingSparePart);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      editingSparePart,
      hasUnsavedChanges,
    });
  };

  buildSparePartFieldsData = () => {
    const sparePartFields = this.state.editingSparePartFields;

    return Object.keys(sparePartFields).map(key => ({
      template_field_id: key,
      value: sparePartFields[key],
    }));
  };

  renderFooter = () => (
    <Button.Group>
      <Button primary label="general.save" loading={this.state.isSaving} onClick={() => this.save()} />
      {this.props.disableCreateMultiple == true ? null : (
        <Button
          label="general.save-and-create-new"
          loading={this.state.isSavingWithCreateNew}
          onClick={() => this.save(true)}
        />
      )}
      <Button label="general.cancel" onClick={this.props.onClose} />
    </Button.Group>
  );

  renderStockInformation = () => (
    <div className={styles['stock-container']}>
      <Grid.Row>
        <Grid.Column>
          <Field
            label={<FormattedMessage id="resources.spare-part.in-stock" />}
            questionTooltipContent={
              <FormattedMessage id="screens.spare-part.info.order-information.in-stock-tooltip" />
            }
          >
            <FieldErrorWrapper
              position="top"
              show={this.state.showStockQuantityRequired}
              errorElement={<FormattedMessage id="general.errors.is-required" />}
            >
              <Field.Decimal
                value={this.state.editingSparePart.stock_quantity || ''}
                error={this.state.showStockQuantityRequired}
                onChange={stock_quantity => {
                  this.setSparePartValue({ stock_quantity });
                }}
                rightLabel={
                  this.state.defaultSparePartUnit == null
                    ? null
                    : this.state.defaultSparePartUnit.abbreviation
                }
                onBlur={stock_quantity => {
                  if (!stock_quantity) {
                    this.setState({ showStockQuantityRequired: true });
                  } else {
                    this.setState({ showStockQuantityRequired: false });
                  }
                  this.setSparePartValue({ stock_quantity });
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            label={<FormattedMessage id="resources.spare-part.min-quantity" />}
            questionTooltipContent={
              <FormattedMessage id="screens.spare-part.info.order-information.min-quantity-tooltip" />
            }
          >
            <FieldErrorWrapper
              position="top"
              show={this.state.showMinLargerThanMaxError}
              errorElement={
                <FormattedMessage id="screens.spare-part.info.order-information.errors.min-quantity-larger-than-max-quantity" />
              }
            >
              <Field.Decimal
                error={this.state.showMinLargerThanMaxError}
                value={this.state.editingSparePart.minimum_quantity}
                onChange={minimum_quantity => {
                  this.setSparePartValue({ minimum_quantity });
                }}
                rightLabel={
                  this.state.defaultSparePartUnit == null
                    ? null
                    : this.state.defaultSparePartUnit.abbreviation
                }
                onBlur={minimum_quantity => {
                  this.setSparePartValue({ minimum_quantity });
                  if (
                    minimum_quantity &&
                    this.state.editingSparePart.maximum_quantity &&
                    new Decimal(minimum_quantity).greaterThan(
                      new Decimal(this.state.editingSparePart.maximum_quantity)
                    )
                  ) {
                    this.setState({ showMinLargerThanMaxError: true });
                  } else {
                    this.setState({
                      showMinLargerThanMaxError: false,
                      showMaxSmallerThanMinError: false,
                    });
                  }
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            label={<FormattedMessage id="resources.spare-part.max-quantity" />}
            questionTooltipContent={
              <FormattedMessage id="screens.spare-part.info.order-information.max-quantity-tooltip" />
            }
          >
            <FieldErrorWrapper
              position="top"
              show={this.state.showMaxSmallerThanMinError}
              errorElement={
                <FormattedMessage id="screens.spare-part.info.order-information.errors.max-quantity-larger-than-min-quantity" />
              }
            >
              <Field.Decimal
                value={this.state.editingSparePart.maximum_quantity}
                error={this.state.showMaxSmallerThanMinError}
                rightLabel={
                  this.state.defaultSparePartUnit == null
                    ? null
                    : this.state.defaultSparePartUnit.abbreviation
                }
                onBlur={maximum_quantity => {
                  this.setSparePartValue({ maximum_quantity });
                  if (
                    maximum_quantity &&
                    this.state.editingSparePart.minimum_quantity &&
                    new Decimal(maximum_quantity).lessThan(
                      new Decimal(this.state.editingSparePart.minimum_quantity)
                    )
                  ) {
                    this.setState({ showMaxSmallerThanMinError: true });
                  } else {
                    this.setState({
                      showMaxSmallerThanMinError: false,
                      showMinLargerThanMaxError: false,
                    });
                  }
                }}
                onChange={maximum_quantity => {
                  this.setSparePartValue({ maximum_quantity });
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            label={<FormattedMessage id="resources.spare-part.reorder-point" />}
            questionTooltipContent={
              <FormattedMessage id="screens.spare-part.info.order-information.reorder-point-tooltip" />
            }
          >
            <Field.Decimal
              value={this.state.editingSparePart.reorder_point}
              rightLabel={
                this.state.defaultSparePartUnit == null ? null : this.state.defaultSparePartUnit.abbreviation
              }
              onChange={reorder_point => {
                this.setSparePartValue({ reorder_point });
              }}
              onBlur={reorder_point => {
                this.setSparePartValue({ reorder_point });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <div className={`${styles['overlay']} ${this.state.activateStockQuantity ? styles['open'] : ''}`} />
    </div>
  );

  renderDuplicateSparePart = () => {
    if (this.props.duplicate === false) return null;
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox.Group>
              <Field.Checkbox
                checked
                label={<FormattedMessage id="components.new-spare-part-modal.duplicate.duplicate-info" />}
                disabled
              />
              <Field.Checkbox
                checked={this.state.duplicateVendors}
                label={<FormattedMessage id="components.new-spare-part-modal.duplicate.duplicate-vendors" />}
                onChange={() => {
                  this.setState(prevState => ({
                    duplicateVendors: !prevState.duplicateVendors,
                  }));
                }}
              />
              <Field.Checkbox
                checked={this.state.duplicateAssets}
                label={<FormattedMessage id="components.new-spare-part-modal.duplicate.duplicate-assets" />}
                onChange={() => {
                  this.setState(prevState => ({
                    duplicateAssets: !prevState.duplicateAssets,
                  }));
                }}
              />
              <Field.Checkbox
                checked={this.state.duplicateFiles}
                label={<FormattedMessage id="components.new-spare-part-modal.duplicate.duplicate-files" />}
                onChange={() => {
                  this.setState(prevState => ({
                    duplicateFiles: !prevState.duplicateFiles,
                  }));
                }}
              />
            </Field.Checkbox.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderCreateForAssetImage = () => {
    if (this.props.createForAsset.images == null || this.props.createForAsset.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.createForAsset.images[0]})`,
        }}
      />
    );
  };

  renderCreateForAsset = () => {
    if (this.props.createForAsset == null) {
      return null;
    }
    let defaultSparePartUnitAbbrevation = null;
    if (this.state.defaultSparePartUnit) {
      defaultSparePartUnitAbbrevation = this.state.defaultSparePartUnit.abbreviation;
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['asset-container']}>
              {this.renderCreateForAssetImage()}
              <div className={styles['info']}>
                <div className={styles['name']}>{this.props.createForAsset.title}</div>
                <div className={styles['subtitle']}>
                  <TreePath fullPath assetId={this.props.createForAsset.id} />
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <span>
                  <FormattedMessage id="resources.spare-part-asset.quantity" />
                  <span className={styles['optional-label']}>
                    <span> - </span>
                    <FormattedMessage id="general.optional" />
                  </span>
                </span>
              }
            >
              <Field.Number
                value={this.state.editingSparePartAsset.number}
                rightLabel={defaultSparePartUnitAbbrevation}
                onChange={quantity =>
                  this.setState({
                    editingSparePartAsset: {
                      ...this.state.editingSparePartAsset,
                      quantity,
                    },
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderAddPrimaryVendorButton = () => {
    if (this.props.createForVendorId) {
      return null;
    }
    if (this.state.createSparePartVendorForVendorId) {
      return null;
    }
    if (this.props.disableAddVendor) {
      return null;
    }
    return (
      <Button
        gray
        small
        label="components.new-spare-part-modal.add-vendor"
        onClick={() => {
          this.setState({ showSelectVendorModal: true });
        }}
      />
    );
  };

  renderChangeSparePartVendorButtons = () => {
    if (this.props.createForVendorId) {
      return null;
    }
    if (this.state.createSparePartVendorForVendorId) {
      return (
        <Button.Group>
          <Button
            gray
            small
            label="components.new-spare-part-modal.change-vendor"
            onClick={() => {
              this.setState({ showSelectVendorModal: true });
            }}
          />
          <Button
            gray
            small
            label="general.remove"
            onClick={() => {
              this.setState({ createSparePartVendorForVendorId: null });
            }}
          />
        </Button.Group>
      );
    }
    return null;
  };

  renderSparePartVendorInformation = () => {
    if (this.props.duplicateFromSparePartId) {
      return null;
    }
    return (
      <>
        {this.renderAddPrimaryVendorButton()}
        <SparePartVendorInfo
          editingSparePart={this.state.editingSparePart}
          editingSparePartVendor={this.state.editingSparePartVendor}
          sparePartUnitId={this.state.editingSparePart.spare_part_unit_id}
          showPackageQuantityBiggerThanZeroError={this.state.showPackageQuantityBiggerThanZeroError}
          showPackageQuantity={this.state.showPackageQuantity}
          onTogglePackageQuantity={() => {
            this.setState(prevState => ({
              showPackageQuantity: !prevState.showPackageQuantity,
            }));
          }}
          titlePlaceholder={this.state.editingSparePart.title}
          vendorId={this.state.createSparePartVendorForVendorId}
          onChangeEditingSparePart={params => {
            this.setSparePartValue({ ...params });
          }}
          onChangeEditingSparePartVendor={params => {
            if (params.package_quantity && this.state.showPackageQuantityBiggerThanZeroError) {
              this.setState({ showPackageQuantityBiggerThanZeroError: false });
            }
            this.setState({
              editingSparePartVendor: {
                ...this.state.editingSparePartVendor,
                ...params,
              },
            });
          }}
        />
        {this.renderChangeSparePartVendorButtons()}
      </>
    );
  };

  renderArticleNumberAlreadyExistsWarning = () => {
    if (this.state.showArticleNumberAlreadyExistsWarning) {
      return (
        <Grid.Row>
          <Grid.Column>
            <div className={styles['article-number-already-exists-warning-container']}>
              <div className={styles['article-number-exists-warning']}>
                <FormattedMessage id="components.new-spare-part-modal.article-number-already-exists-warning" />
              </div>
              <Button.Group>
                <Button
                  gray
                  small
                  onClick={() => this.setState({ showDuplicateSparePartsModal: true })}
                  label="components.new-spare-part-modal.show-duplicates"
                />
                <Button
                  gray
                  small
                  onClick={() => this.setState({ showArticleNumberAlreadyExistsWarning: false })}
                  label="components.new-spare-part-modal.ignore"
                />
              </Button.Group>
            </div>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  getSparePartFieldValueForTemplateField = templateField => {
    return this.state.editingSparePartFields[templateField.id];
  };

  setSparePartFieldValue = (templateField, value) => {
    const newEditingSparePartFields = {
      ...this.state.editingSparePartFields,
      [templateField.id]: value,
    };

    this.setState({
      editingSparePartFields: newEditingSparePartFields,
    });
  };

  renderTemplateField = templateField => {
    return (
      <TemplateField
        templateField={templateField}
        editing
        value={this.getSparePartFieldValueForTemplateField(templateField)}
        onValueChange={value => {
          this.setSparePartFieldValue(templateField, value);
        }}
      />
    );
  };

  renderTemplateFields = () => {
    if (this.props.templateFields.length === 0) return null;
    const fields = this.props.templateFields.sort((a, b) => {
      if (parseInt(a.sort) === parseInt(b.sort)) {
        return parseInt(a.column) - parseInt(b.column);
      }
      return parseInt(a.sort) - parseInt(b.sort);
    });
    const numOfRows = fields.length;

    let rows = [];

    for (var i = 0; i < numOfRows; i++) {
      rows = [
        ...rows,

        <Grid.Row key={i}>
          <Grid.Column>{this.renderTemplateField(fields[i])}</Grid.Column>
        </Grid.Row>,
      ];
    }

    return (
      <>
        <Grid.Separator />
        {rows}
      </>
    );
  };

  renderConvertionRateAndLocalCurrency = () => {
    const { purchase_price, purchase_price_currency, purchase_price_exchange_rate } =
      this.state.editingSparePart;
    const { currency: systemCurrency } = this.props.currentSystem;
    if (purchase_price_currency !== systemCurrency) {
      let localValue;
      try {
        localValue = new Decimal(purchase_price)
          .times(new Decimal(purchase_price_exchange_rate))
          .toDecimalPlaces(2)
          .toString();
      } catch (e) {
        localValue = 0;
      }

      return (
        <div className={styles['convertion-rate-description-container']}>
          <div className={styles['convertion-rate']}>
            <Field
              fontSize={12}
              view
              singleRow
              label={<FormattedMessage id="resources.spare-part.currency-conversion-rate" />}
            >
              {new Decimal(purchase_price_exchange_rate).toDecimalPlaces(4).toString()}
            </Field>
            <div
              className={styles['icon-button']}
              onClick={() => this.setState({ showChangeCurrencyModal: true })}
            >
              <Icon regular size={11} type="pen" />
            </div>
          </div>
          <div className={styles['system-currency']}>
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />
          </div>
        </div>
      );
    }
    return null;
  };

  renderLatestPurchasePriceField = () => {
    if (this.props.hasProTier) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <div className={styles['purchase-label-container']}>
                  <div className={styles['label']}>
                    <FormattedMessage id="resources.spare-part.latest-purchase-price" />
                    <Tooltip
                      position="top center"
                      trigger={
                        <div className={styles['more-info-icon-container']}>
                          <Icon regular type="question-circle" />
                        </div>
                      }
                      label={
                        <FormattedMessage id="screens.spare-part.info.order-information.purchase-price-tooltip" />
                      }
                    />
                  </div>
                  <div>
                    <Button
                      type="text"
                      primary
                      fontSize={12}
                      label="screens.spare-part.info.order-information.change-currency"
                      noUnderline
                      onClick={() => {
                        this.setState({ showChangeCurrencyModal: true });
                      }}
                    />
                  </div>
                </div>
              }
              description={this.renderConvertionRateAndLocalCurrency()}
            >
              <Field.Money
                currency={this.state.editingSparePart.purchase_price_currency}
                value={this.state.editingSparePart.purchase_price}
                onBlur={purchase_price => {
                  this.setSparePartValue({ purchase_price });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderContent = () => (
    <React.Fragment>
      <Grid>
        {this.renderDuplicateSparePart()}
        {this.renderCreateForAsset()}
        <Grid.Row>
          <Grid.Column md={8}>
            <Field label={<FormattedMessage id="resources.spare-part.title" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showNameError}
                errorElement={<FormattedMessage id="general.errors.is-required" />}
              >
                <Field.Text
                  autoFocus
                  error={this.state.showNameError}
                  value={this.state.editingSparePart.title}
                  onChange={title => {
                    if (this.state.showNameError && title.length > 0) {
                      this.setState({ showNameError: false });
                    }
                    this.setSparePartValue({ title });
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
          <Grid.Column md={4}>
            <Field label={<FormattedMessage id="resources.spare-part.article-number" />}>
              <Field.Text
                rightLabel={this.state.isCheckingDuplicateArticleNumber ? <Loader tiny /> : null}
                placeholder={this.props.currentSystem.next_spare_part_article_number.toString()}
                value={this.state.editingSparePart.article_number}
                onChange={article_number => {
                  this.setSparePartValue({ article_number });
                  if (this.state.showArticleNumberAlreadyExistsWarning) {
                    this.setState({ showArticleNumberAlreadyExistsWarning: false });
                  }
                }}
                onBlur={article_number => {
                  checkDuplicateSparePartsRequest.cancel();
                  if (article_number) {
                    this.setState({ isCheckingDuplicateArticleNumber: true });
                    this.checkDuplicatedArticleNumber(article_number);
                  }
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderArticleNumberAlreadyExistsWarning()}
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part.description" />}>
              <Field.Textarea
                value={this.state.editingSparePart.description}
                onChange={description => this.setSparePartValue({ description })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <SparePartTypeField
              value={this.state.editingSparePart.spare_part_type_id}
              onChange={spare_part_type_id => {
                this.setSparePartValue({ spare_part_type_id });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SparePartStorageLocationField
              value={this.state.editingSparePart.spare_part_location_id}
              onChange={spare_part_location_id => {
                this.setSparePartValue({ spare_part_location_id });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SparePartUnitField
              value={this.state.editingSparePart.spare_part_unit_id}
              onChange={sparePartUnit => {
                this.setState({ defaultSparePartUnit: sparePartUnit });
                this.setSparePartValue({ spare_part_unit_id: sparePartUnit.id });
              }}
              onClear={() => {
                this.setState({
                  defaultSparePartUnit: null,
                });
                this.setSparePartValue({ spare_part_unit_id: null });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderLatestPurchasePriceField()}
        {this.renderSparePartVendorInformation()}
        {this.renderTemplateFields()}
        {this.props.hasProTier === false ? null : (
          <>
            <Grid.Separator />
            <Grid.Row>
              <Grid.Column>
                <div className={styles['stock-title-container']}>
                  <p>
                    <FormattedMessage id="components.new-spare-part-modal.stock-title" />
                  </p>
                  <Field.Checkbox
                    checked={this.state.activateStockQuantity}
                    label={<FormattedMessage id="components.new-spare-part-modal.activate-stock" />}
                    onChange={checked => {
                      if (checked) {
                        this.setState({
                          activateStockQuantity: true,
                        });
                      } else {
                        this.setState({
                          showMinLargerThanMaxError: false,
                          showMaxSmallerThanMinError: false,
                          showStockQuantityRequired: false,
                          activateStockQuantity: false,
                        });
                      }
                    }}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            {this.renderStockInformation()}
          </>
        )}
      </Grid>
    </React.Fragment>
  );

  renderHeaderSubtitle = () => {
    if (this.props.subtitle) {
      return this.props.subtitle;
    }
    if (this.props.createForAssetId) {
      return (
        <FormattedMessage
          id="components.new-spare-part-modal.add-to-asset-subtitle"
          values={{
            asset: <span className={styles['create-for-asset']}>{this.props.createForAsset.title}</span>,
          }}
        />
      );
    }
    return null;
  };

  renderCreateVendorModal = () => {
    return (
      <NewVendorModal
        open={this.state.showCreateVendorModal}
        onClose={() => {
          this.setState({ showCreateVendorModal: false });
          setTimeout(() => {
            this.setState({ showSelectVendorModal: true });
          }, 100);
        }}
        onCreated={({ id }) => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({
                showSelectVendorModal: false,
                createSparePartVendorForVendorId: id,
                editingSparePartVendor: {
                  ...this.state.editingSparePartVendor,
                  vendor_id: id,
                },
                showCreateVendorModal: false,
              });
            }, 350);
          });
        }}
        onCreatedWithReopen={({ id }) => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({
                showSelectVendorModal: false,
                createSparePartVendorForVendorId: id,
                editingSparePartVendor: {
                  ...this.state.editingSparePartVendor,
                  vendor_id: id,
                },
                showCreateVendorModal: true,
              });
            }, 350);
          });
        }}
      />
    );
  };

  renderDuplicatedSparePartsModal = () => {
    let artNo = this.props.currentSystem.next_spare_part_article_number;
    if (this.state.editingSparePart.article_number) {
      artNo = this.state.editingSparePart.article_number;
    }
    return (
      <DuplicateSparePartsModal
        open={this.state.showDuplicateSparePartsModal}
        number={artNo}
        ids={this.state.duplicateSparePartIds}
        onClose={() => this.setState({ showDuplicateSparePartsModal: false })}
      />
    );
  };

  renderChangeCurrencyModal = () => {
    return (
      <ChangeCurrencyModal
        currency={this.state.editingSparePart.purchase_price_currency}
        exchangeRate={this.state.editingSparePart.purchase_price_exchange_rate}
        open={this.state.showChangeCurrencyModal}
        onSave={({ exchangeRate, currency }) => {
          this.setSparePartValue({
            purchase_price_currency: currency,
            purchase_price_exchange_rate: exchangeRate,
          });
          this.setState({ showChangeCurrencyModal: false });
        }}
        onClose={() => {
          this.setState({ showChangeCurrencyModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal
          open={this.props.open}
          footerComponent={this.renderFooter()}
          onClose={() => {
            if (this.state.hasUnsavedChanges) {
              const confirmed = window.confirm(
                this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
              );
              if (confirmed) {
                this.props.onClose();
              }
            } else {
              this.props.onClose();
            }
          }}
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header
                title={this.props.title}
                subtitle={this.renderHeaderSubtitle()}
                onClose={this.props.onClose}
              />
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
        <SelectVendorModal
          open={this.state.showSelectVendorModal}
          subtitle={<FormattedMessage id="components.new-spare-part-modal.select-vendor-modal-subtitle" />}
          onCreateVendor={() => {
            this.setState({ showSelectVendorModal: false });
            setTimeout(() => {
              this.setState({ showCreateVendorModal: true });
            }, 100);
          }}
          listItemRightComponent={({ id }) => {
            const { createSparePartVendorForVendorId } = this.state;
            if (createSparePartVendorForVendorId === id) {
              return (
                <div className={styles['already-added-spare-part']}>
                  <FormattedMessage id="general.added" />
                </div>
              );
            }
            return (
              <Button
                gray
                small
                label="general.choose"
                onClick={() => {
                  this.setState({
                    showSelectVendorModal: false,
                    createSparePartVendorForVendorId: id,
                    editingSparePartVendor: {
                      ...this.state.editingSparePartVendor,
                      vendor_id: id,
                    },
                  });
                }}
              />
            );
          }}
          onClose={() => this.setState({ showSelectVendorModal: false })}
        />
        {this.renderCreateVendorModal()}
        {this.renderDuplicatedSparePartsModal()}
        {this.renderChangeCurrencyModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePart: SDKReduxOperations.createSparePart,
      duplicateSparePart: SDKReduxOperations.duplicateSparePart,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { duplicateFromSparePartId, createForAssetId } = ownProps;
  const duplicateFromSparePart = EntitySelectors.getSparePart(state, duplicateFromSparePartId);
  let sparePartFieldsFromDuplicatedSparePart = [];
  if (duplicateFromSparePart) {
    sparePartFieldsFromDuplicatedSparePart = EntitySelectors.getSparePartFields(
      state,
      duplicateFromSparePart.spare_part_fields
    );
  }
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
    createForAsset: EntitySelectors.getAsset(state, createForAssetId),
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    duplicateFromSparePart,
    sparePartFieldsFromDuplicatedSparePart,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewSparePartModal));

NewSparePartModal.propTypes = {
  open: PropTypes.bool.isRequired,
  disableCreateMultiple: PropTypes.bool,
  disableAddVendor: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onCreatedWithReopen: PropTypes.func,
  title: PropTypes.element,
  defaultParams: PropTypes.object,
  footerComponent: PropTypes.element,
  duplicate: PropTypes.bool,
  duplicateFromSparePartId: PropTypes.string,
  createForVendorId: PropTypes.string,
  createForAssetId: PropTypes.string,
  currency: PropTypes.string,
};

NewSparePartModal.defaultProps = {
  title: <FormattedMessage id="components.new-spare-part-modal.title" />,
  subtitle: null,
  disableAddVendor: false,
  duplicate: false,
  duplicateFromSparePartId: null,
  createForVendorId: null,
  createForAssetId: null,
  currency: null,
};
