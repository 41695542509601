import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { AssetOperations, AssetSelectors } from 'state/ducks/asset';
import Day from './Day';
import PreviewInstance from './PreviewInstance';
import PreviewWorkOrders from './PreviewWorkOrders';
import DragLayer from './DragLayer';
import styles from './style.module.scss';

class Calendar extends Component {
  onCreateWorkOrder = params => {
    this.props.onCreateWorkOrder(params);
  };

  renderDay = dayOfWeek => {
    return moment().day(dayOfWeek).format('dddd');
  };

  renderWeeks = () => {
    let children = [];
    for (
      const date = moment(this.props.fromDate).clone();
      !date.isAfter(this.props.endDate, 'week');
      date.add(1, 'weeks')
    ) {
      const week = date.format('w');
      const monday = date.isoWeekday(1).clone();
      const tuesday = date.isoWeekday(2).clone();
      const wednesday = date.isoWeekday(3).clone();
      const thursday = date.isoWeekday(4).clone();
      const friday = date.isoWeekday(5).clone();
      const saturday = date.isoWeekday(6).clone();
      const sunday = date.isoWeekday(7).clone();
      children = [
        ...children,
        <div className={styles['row']}>
          <div className={styles['week-number']}>{week}</div>
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={monday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={tuesday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={wednesday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={thursday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={friday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={saturday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
          <Day
            clickable={this.props.canCarryOutWorkOrders}
            firstDateOfMonth={this.props.firstDateOfMonth}
            date={sunday.format('YYYY-MM-DD')}
            onCreateWorkOrder={this.onCreateWorkOrder}
          />
        </div>,
      ];
    }
    return children;
  };

  renderPreviewInstance = () => {
    const { top, left, width, instanceId, open } = this.props.previewOverlayData;
    return (
      <PreviewInstance
        open={open}
        top={top}
        left={left}
        extraWidth={width}
        instanceId={instanceId}
        onClose={this.props.hidePreviewOverlay}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['calendar']}>
          <div className={styles['weekdays']}>
            <div className={`${styles['weekday']} ${styles['week-number-placeholder']}`} />
            <div className={styles['weekday']}>{this.renderDay(1)}</div>
            <div className={styles['weekday']}>{this.renderDay(2)}</div>
            <div className={styles['weekday']}>{this.renderDay(3)}</div>
            <div className={styles['weekday']}>{this.renderDay(4)}</div>
            <div className={styles['weekday']}>{this.renderDay(5)}</div>
            <div className={styles['weekday']}>{this.renderDay(6)}</div>
            <div className={styles['weekday']}>{this.renderDay(7)}</div>
          </div>
          <div className={styles['container']}>
            <div className={styles['weeks']}>{this.renderWeeks()}</div>
            <div className={`${styles['loader']} ${this.props.loading ? styles['show'] : ''}`} />
          </div>
        </div>
        <DragLayer />
        {this.renderPreviewInstance()}
        <PreviewWorkOrders />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hidePreviewOverlay: AssetOperations.hidePreviewOverlay,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    previewOverlayData: AssetSelectors.getPreviewOverlayData(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Calendar));
