import types from './types';

const fetchMeterForView = payload => ({
  type: types.FETCH_METER_FOR_VIEW,
  payload: payload,
});

const fetchMeterForViewSuccess = payload => ({
  type: types.FETCH_METER_FOR_VIEW_SUCCESS,
  payload: payload,
});

const listMeterReadingsSuccess = payload => ({
  type: types.LIST_METER_READINGS_SUCCESS,
  payload: payload,
});

export default {
  fetchMeterForView,
  fetchMeterForViewSuccess,
  listMeterReadingsSuccess,
};
