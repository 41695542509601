import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { Button, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations, API } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { normalizeChecklistTemplate, normalizeWorkOrder, normalizeRecurringMaintenance } from 'sdk/Schemas';
import { ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { AuthSelectors } from 'state/ducks/auth';
import EditTitleModal from './EditTitleModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    isSavingAsReusable: false,
  };

  deleteChecklist = () => {
    if (this.props.recurringMaintenanceId) {
      return this.removeTemplateFromRecurringMaintenance();
    } else {
      if (this.props.checklistTemplate.reusable === false) {
        return this.deleteChecklistTemplate();
      }
    }
  };

  deleteChecklistTemplate = () => {
    return this.props.deleteChecklistTemplate(this.props.checklistTemplate.id).then(() => {
      return API.fetchWorkOrder(this.props.workOrderId).then(({ data: workOrder }) => {
        const { entities } = normalizeWorkOrder(workOrder);
        this.props.updateEntities(entities);
      });
    });
  };

  removeTemplateFromRecurringMaintenance = () => {
    return this.props.deleteChecklistTemplateLink(this.props.checklistTemplateLinkId).then(() => {
      return API.fetchRecurringMaintenance(this.props.recurringMaintenanceId).then(
        ({ data: recurringMaintenance }) => {
          const { entities } = normalizeRecurringMaintenance(recurringMaintenance);
          this.props.updateEntities(entities);
        }
      );
    });
  };

  addReusableChecklistTemplate = id => {
    if (this.props.recurringMaintenanceId) {
      return this.props.createChecklistTemplateLinkForRecurringMaintenance(
        this.props.recurringMaintenanceId,
        { checklist_template_id: id }
      );
    } else if (this.props.workOrderId) {
      return this.props.createChecklistTemplateLinkForWorkOrder(this.props.workOrderId, {
        checklist_template_id: id,
      });
    } else {
      return this.props.createChecklistTemplateLinkForOperationalMaintenanceTemplateVersion(
        this.props.operationalMaintenanceTemplateVersionId,
        {
          checklist_template_id: id,
        }
      );
    }
  };

  createChecklistTemplate = () => {
    this.setState({ isSavingAsReusable: true });
    if (this.props.new) {
      this.props
        .updateChecklistTemplate(this.props.checklistTemplate.id, {
          title: this.props.checklistTemplateVersion.title,
          reusable: true,
        })
        .then(({ data: checklistTemplate }) => {
          const { entities } = normalizeChecklistTemplate(checklistTemplate);
          this.props.updateEntities(entities);
          this.props.onAddToLibrarySuccess();
        });
    } else if (this.props.checklistTemplate.draft === true) {
      this.props
        .updateChecklistTemplate(this.props.checklistTemplate.id, {
          title: this.props.checklistTemplateVersion.title,
          reusable: true,
        })
        .then(({ data: checklistTemplate }) => {
          const { entities } = normalizeChecklistTemplate(checklistTemplate);
          this.props.updateEntities(entities);
          this.addReusableChecklistTemplate(checklistTemplate.id).then(() => {
            this.props.onAddToLibrarySuccess();
          });
        });
    } else {
      /*
        When adding exising checklist to library from work order / recurring maintenance
      */
      this.props
        .createChecklistTemplate(this.props.system.id, {
          title: this.props.checklistTemplateVersion.title,
          duplicate_from_id: this.props.checklistTemplateVersion.checklist_template_id,
        })
        .then(({ data: checklistTemplate }) => {
          this.props
            .updateChecklistTemplate(checklistTemplate.id, { reusable: true })
            .then(({ data: checklistTemplate }) => {
              const { entities } = normalizeChecklistTemplate(checklistTemplate);
              this.props.updateEntities(entities);
              this.deleteChecklist().then(() => {
                this.addReusableChecklistTemplate(checklistTemplate.id).then(() => {
                  this.props.onAddToLibrarySuccess();
                });
              });
            });
        });
    }
  };

  save = () => {
    if (this.props.loading) {
      return;
    }
    if (this.props.isRevertingChanges) {
      return;
    }
    if (this.props.saveAsReusable) {
      this.createChecklistTemplate();
    } else {
      this.props.onSave();
    }
  };

  revertChanges = () => {
    if (this.props.isSaving) {
      return;
    }
    this.props.onRevertChanges();
  };

  close = () => {
    if (this.props.loading) {
      return;
    }
    if (this.props.isSaving) {
      return;
    }
    this.props.onClose();
  };

  /*
    Render
  */

  renderTitle = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: 200, height: 30 }}>
          <ContentLoader
            speed={2}
            width={200}
            height={33}
            viewBox="0 0 200 33"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="3" y="1" rx="3" ry="3" width="180" height="30" />
            <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          </ContentLoader>
        </div>
      );
    }
    return this.props.checklistTemplateVersion && this.props.checklistTemplateVersion.title;
  };

  renderEditTitleContainer = () => {
    if (this.props.loading) {
      return null;
    }
    return (
      <div className={styles['edit-container']}>
        <Button
          type="icon"
          icon={<Icon regular type="pen" />}
          onClick={() => this.setState({ showEditTitleModal: true })}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles['header']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>{this.renderTitle()}</div>
            {this.renderEditTitleContainer()}
          </div>
          <div className={styles['button-container']}>
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={this.save}
                loading={this.props.isSaving || this.state.isSavingAsReusable}
              />
              {this.props.showAbortChangesButton ? (
                <Button
                  label="components.edit-checklist-modal.revert-changes"
                  onClick={this.revertChanges}
                  loading={this.props.isRevertingChanges}
                />
              ) : null}
              <Button
                label="general.cancel"
                onClick={this.close}
                loading={this.props.isRevertingChanges && this.props.showAbortChangesButton === false}
              />
            </Button.Group>
          </div>
        </div>
        <EditTitleModal
          open={this.state.showEditTitleModal}
          onClose={() => {
            this.setState({ showEditTitleModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
      updateChecklistTemplate: SDKReduxOperations.updateChecklistTemplate,
      deleteChecklistTemplate: SDKReduxOperations.deleteChecklistTemplate,
      deleteChecklistTemplateLink: SDKReduxOperations.deleteChecklistTemplateLink,
      createChecklistTemplateLinkForWorkOrder: SDKReduxOperations.createChecklistTemplateLinkForWorkOrder,
      createChecklistTemplateLinkForRecurringMaintenance:
        SDKReduxOperations.createChecklistTemplateLinkForRecurringMaintenance,
      createChecklistTemplateLinkForOperationalMaintenanceTemplateVersion:
        SDKReduxOperations.createChecklistTemplateLinkForOperationalMaintenanceTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const checklistTemplateVersion = ChecklistEditModalSelectors.getChecklistTemplateVersion(state);
  return {
    system: AuthSelectors.getCurrentSystem(state),
    saveAsReusable: ChecklistEditModalSelectors.saveAsReusable(state),
    checklistTemplateVersion,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
