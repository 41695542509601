import React, { Component } from 'react';
import { API, HelperFunctions } from 'sdk';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { NewInlineModal, EmptyDataSet, Button, Field, Icon } from 'views/components/Shared/General';
import { TreePath, NewSelectAssetModal, NewAssetModal, AssetTitle } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { normalizeAsset } from 'sdk/Schemas';
import SelectedTreeAssetItem from './SelectedTreeAssetItem';
import SelectAssetButton from './SelectAssetButton';
import styles from './style.module.scss';

const Tabs = {
  TreeParent: 'tree_parent',
  All: 'all',
};

class ChooseAssetInlineModal extends Component {
  getInitialState = () => ({
    isOpen: false,
    searchTerm: '',
    isFetching: true,
    isSearching: false,
    currentPage: 0,
    searchedAssets: [],
    assets: [],
    treeParentId: null,
    pagination: {
      currentPage: 0,
      totalPages: 0,
    },
    showSelectAssetModal: false,
    showCreateAssetModal: false,
    selectedTab: Tabs.TreeParent,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.debouncedSearch = debounce(() => {
      let params = { page: this.state.pagination.currentPage, search: this.state.searchTerm };
      if (this.state.selectedTab === Tabs.TreeParent) {
        params = {
          ...params,
          tree_children_for_asset_id: this.props.defaultTreeParentId,
        };
      }
      this.fetchSearchedAssets(params, false).then(({ assets, pagination }) => {
        this.setState({
          searchedAssets: assets,
          pagination,
          isSearching: false,
        });
      });
    }, 400);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    const { selectedAsset, defaultTreeParentId, defaultTreeParentAsset } = this.props;
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      let treeParentId = defaultTreeParentId;
      const selectedAssetId = defaultTreeParentId || this.props.selectedAssetId || null;
      if (selectedAsset) {
        treeParentId = selectedAsset.tree_parent_id;
      }
      if (defaultTreeParentId && defaultTreeParentAsset == null) {
        API.fetchAsset(defaultTreeParentId).then(({ data: asset }) => {
          const { entities } = normalizeAsset(asset);
          this.props.updateEntities(entities);
          this.initialize({ treeParentId, selectedAssetId });
        });
      } else {
        this.initialize({ treeParentId, selectedAssetId });
      }
    }
  };

  initialize = state => {
    this.setState(
      {
        ...this.getInitialState(),
        ...state,
        isOpen: true,
      },
      () => {
        this.fetchTree().then(assets => {
          this.setState({
            assets,
            isFetching: false,
          });
        });
      }
    );
  };

  fetchTree = () => {
    let params = {
      no_pagination: true,
      archived: false,
      sort: 'tree_sort',
    };
    if (this.state.treeParentId) {
      params = {
        ...params,
        tree_parent_id: this.state.treeParentId,
      };
    } else {
      params = {
        ...params,
        tree_parent_id: {
          [HelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
    }
    return API.listAssets(this.props.currentSystem.id, params)
      .then(({ data: assets }) => {
        const { entities, result } = normalizeAsset(assets);
        this.props.updateEntities(entities);
        return result.map(id => entities.assetById[id]);
      })
      .catch(() => {});
  };

  fetchSearchedAssets = attrs => {
    return API.listAssets(this.props.currentSystem.id, { page_size: 8, archived: false, ...attrs })
      .then(({ data: assets, headers }) => {
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        const { entities, result } = normalizeAsset(assets);
        this.props.updateEntities(entities);
        return { assets: result.map(id => entities.assetById[id]), pagination };
      })
      .catch(() => {});
  };

  navigateInTree = assetId => {
    this.setState({ isFetching: true, treeParentId: assetId }, () => {
      this.fetchTree().then(assets => {
        this.setState({
          assets,
          isFetching: false,
        });
      });
    });
  };

  selectAsset = assetId => {
    if (this.props.multiple) {
      if (this.props.selectedAssetIds.includes(assetId)) {
        this.props.onRemoveAsset(assetId);
      } else {
        this.props.onAddAsset(assetId);
      }
    } else {
      if (this.state.searchTerm.length > 0) {
        this.setState(
          {
            isOpen: false,
          },
          () => {
            this.props.onSelectAsset(assetId);
          }
        );
      } else {
        this.setState({
          selectedAssetId: assetId,
        });
      }
    }
  };

  selectPage = page => {
    this.setState({ pagination: { ...this.state.pagination, currentPage: page }, isSearching: true }, () => {
      this.debouncedSearch();
    });
  };

  selectTab = tab => {
    this.setState(
      {
        isSearching: true,
        selectedTab: tab,
        treeParentId: tab === Tabs.TreeParent ? this.props.defaultTreeParentId : null,
        pagination: {
          currentPage: 1,
          totalPages: 1,
        },
      },
      () => {
        if (this.state.searchTerm.length > 0) {
          this.debouncedSearch();
        } else {
          this.fetchTree().then(assets => {
            this.setState({
              assets,
              isSearching: false,
            });
          });
        }
      }
    );
  };

  renderSearchEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderGoBackInTreeButton = () => {
    if (
      this.state.selectedTab === Tabs.TreeParent &&
      this.props.defaultTreeParentId == this.state.treeParentId
    ) {
      return null;
    }

    if (this.state.treeParentId) {
      return (
        <SelectedTreeAssetItem
          id={this.state.treeParentId}
          onBack={assetId => this.navigateInTree(assetId)}
        />
      );
    }
    return null;
  };

  renderTreeListItem = asset => {
    if (this.props.multiple) {
      const selected = this.props.selectedAssetIds.includes(asset.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={asset.id}
          onClick={e => this.selectAsset(asset.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectAsset(asset.id);
              }}
            />
          }
          rightComponent={
            asset.tree_has_children ? (
              <div className={styles['navigate-in-tree-button']}>
                <Button
                  type="icon"
                  icon={<Icon regular size={18} type="angle-right" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.navigateInTree(asset.id);
                  }}
                />
              </div>
            ) : null
          }
        >
          <AssetTitle id={asset.id} />
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.state.selectedAssetId === asset.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={asset.id}
          selected={selected}
          onClick={e => this.selectAsset(asset.id)}
          onDoubleClick={() => {
            if (asset.tree_has_children) {
              this.navigateInTree(asset.id);
            }
          }}
          rightComponent={
            asset.tree_has_children ? (
              <div className={styles['navigate-in-tree-button']}>
                <Button
                  type="icon"
                  icon={<Icon regular size={18} type="angle-right" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.navigateInTree(asset.id);
                  }}
                />
              </div>
            ) : null
          }
        >
          <AssetTitle id={asset.id} />
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderSearchedListItem = asset => {
    if (this.props.multiple) {
      const selected = this.props.selectedAssetIds.includes(asset.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={asset.id}
          onClick={e => this.selectAsset(asset.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectAsset(asset.id);
              }}
            />
          }
        >
          <div>
            <div>
              <AssetTitle id={asset.id} />
            </div>
            <div>
              <TreePath assetId={asset.id} />
            </div>
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.state.selectedAssetId === asset.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={asset.id}
          selected={selected}
          onClick={e => this.selectAsset(asset.id)}
        >
          <div>
            <div>
              <span>{asset.title}</span>
              {this.props.settings.asset_number_activated ? (
                <span className={styles['number']}> - #{asset.number}</span>
              ) : null}
            </div>
            <div>
              <TreePath assetId={asset.id} />
            </div>
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderTree = () => {
    return (
      <>
        {this.renderGoBackInTreeButton()}
        {this.state.assets.map(asset => {
          return this.renderTreeListItem(asset);
        })}
      </>
    );
  };

  renderList = () => {
    if (this.state.searchedAssets.length === 0) {
      return this.renderSearchEmptyDataset();
    }
    return (
      <>
        {this.state.searchedAssets.map(asset => {
          return this.renderSearchedListItem(asset);
        })}
      </>
    );
  };

  renderTreeItemLoader = () => {
    const amountOfAssets = this.state.assets.length === 0 ? 2 : this.state.assets.length;

    return (
      <>
        <NewInlineModal.Dropdown>
          {this.renderInlineModalHeader()}

          <NewInlineModal.Dropdown.Items>
            {this.renderGoBackInTreeButton()}
            {Array(amountOfAssets)
              .fill()
              .map(() => (
                <NewInlineModal.Dropdown.Item loading />
              ))}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderSearchedListItemLoader = () => {
    const amountOfAssets = this.state.searchedAssets.length === 0 ? 2 : this.state.searchedAssets.length;
    return (
      <>
        {this.renderInlineModalHeader()}
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            {Array(amountOfAssets)
              .fill()
              .map(() => (
                <NewInlineModal.Dropdown.Item loading />
              ))}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderSearchField = () => (
    <NewInlineModal.Header.Search
      ref={ref => (this.inlineModalSearchRef = ref)}
      placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
      value={this.state.searchTerm}
      onChange={searchTerm => {
        let pagination = { ...this.state.pagination };
        if (searchTerm.length === 0) {
          pagination = {
            currentPage: 1,
            totalPages: 1,
          };
        }
        this.setState(
          {
            isSearching: true,
            searchTerm,
            pagination,
          },
          () => {
            if (this.state.searchTerm.length > 0) {
              this.debouncedSearch();
            } else {
              this.fetchTree().then(assets => {
                this.setState({
                  assets,
                  isSearching: false,
                });
              });
            }
          }
        );
      }}
      onClear={() => {
        this.setState({
          searchTerm: '',
          isSearching: true,
          pagination: {
            currentPage: 0,
            totalPages: 0,
          },
        });
        this.fetchTree().then(assets => {
          this.setState({
            assets,
            isSearching: false,
          });
        });
      }}
      rightLabel={
        <Button
          type="text"
          fontSize={12}
          primary
          label="general.more-options"
          noUnderline
          onClick={() => this.setState({ isOpen: false, showSelectAssetModal: true })}
        />
      }
    />
  );

  renderDefaultTreeParentTitle = () => {
    if (this.props.defaultTreeParentAsset) {
      return this.props.defaultTreeParentAsset.title;
    }
    return null;
  };

  renderHeaderTabs = () => {
    if (this.props.defaultTreeParentId) {
      return (
        <NewInlineModal.Header.Tabs
          tabs={[
            {
              id: Tabs.TreeParent,
              translate: false,
              title: this.renderDefaultTreeParentTitle(),
              loading: this.props.defaultTreeParentAsset == null,
            },
            {
              id: Tabs.All,
              title: 'components.asset-inline-modal.all-assets',
              loading: this.props.defaultTreeParentAsset == null,
            },
          ]}
          selectedTab={this.state.selectedTab}
          onSelectTab={id => this.selectTab(id)}
        />
      );
    }
    return null;
  };

  renderInlineModalHeader = () => {
    return (
      <NewInlineModal.Header>
        {this.renderHeaderTabs()}
        {this.renderSearchField()}
      </NewInlineModal.Header>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return this.renderTreeItemLoader();
    }
    if (this.state.isSearching) {
      return this.renderSearchedListItemLoader();
    }
    if (this.state.searchTerm.length > 0) {
      return (
        <>
          {this.renderInlineModalHeader()}
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderList()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </>
      );
    }
    if (this.state.assets.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.asset-inline-modal.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <>
        {this.renderInlineModalHeader()}
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>{this.renderTree()}</NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderFooter = () => {
    const { totalPages, currentPage } = this.state.pagination;
    if (this.state.searchTerm.length > 0) {
      if (totalPages > 1) {
        return (
          <NewInlineModal.Footer>
            <NewInlineModal.Footer.Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onSelectPage={page => this.selectPage(page)}
            />
          </NewInlineModal.Footer>
        );
      }
      return null;
    }
    if (this.props.multiple) {
      return null;
    }
    return (
      <NewInlineModal.Footer>
        <SelectAssetButton
          assetId={this.state.selectedAssetId}
          onClick={() => {
            this.setState(
              {
                isOpen: false,
              },
              () => {
                this.props.onSelectAsset(this.state.selectedAssetId);
              }
            );
          }}
        />
      </NewInlineModal.Footer>
    );
  };

  renderCreateAssetModal = () => {
    return (
      <NewAssetModal
        open={this.state.showCreateAssetModal}
        onClose={() => {
          this.setState({ showCreateAssetModal: false });
        }}
        onCreated={asset => {
          this.setState({ showCreateAssetModal: false });
          this.props.onSelectAsset(asset.id);
        }}
        onCreatedWithReopen={asset => {
          this.setState({ showCreateAssetModal: false });
          setTimeout(() => {
            this.setState({ showCreateAssetModal: true });
          }, 200);
          this.props.onSelectAsset(asset.id);
        }}
      />
    );
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton={this.props.hideCreateButton}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={asset => {
          return (
            <Button
              gray
              small
              label="general.choose"
              onClick={() => {
                this.setState({ showSelectAssetModal: false });
                this.props.onSelectAsset(asset.id);
              }}
            />
          );
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
        onCreateAsset={() => {
          this.setState({ showSelectAssetModal: false });
          setTimeout(() => {
            this.setState({ showCreateAssetModal: true });
          }, 100);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderContent()}
          {this.renderFooter()}
        </NewInlineModal>
        {this.renderSelectAssetModal()}
        {this.renderCreateAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    selectedAsset: EntitySelectors.getAsset(state, ownProps.selectedAssetId),
    selectedAssets: EntitySelectors.getAssets(state, ownProps.selectedAssetIds),
    defaultTreeParentAsset: EntitySelectors.getAsset(state, ownProps.defaultTreeParentId),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseAssetInlineModal));

ChooseAssetInlineModal.propTypes = {
  selectedAssetId: PropTypes.string,
  selectedAssetIds: PropTypes.array,
  onSelectAsset: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  hideCreateButton: PropTypes.bool,
  defaultTreeParentId: PropTypes.string,
};

ChooseAssetInlineModal.defaultProps = {
  selectedAssetId: null,
  selectedAssetIds: [],
  onSelectAsset: () => {},
  onAddAsset: () => {},
  onRemoveAsset: () => {},
  onClear: () => {},
  multiple: false,
  hideCreateButton: false,
  defaultTreeParentId: null,
};
