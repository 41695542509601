import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class DuplicateSparePartsModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderSpareParts = () => {
    return (
      <List light>
        {this.props.spareParts.map(sparePart => (
          <List.Item small>
            <List.Item.ImageColumn imageId={sparePart.images.length === 0 ? null : sparePart.images[0].id} />
            <List.Item.TitleColumn title={sparePart.title} />
          </List.Item>
        ))}
      </List>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={600}>
          <Modal.Header
            ignoreLine
            title={
              <FormattedMessage id="components.new-spare-part-modal.duplicated-spare-parts-modal.title" />
            }
            subtitle={`#${this.props.number}`}
            onClose={this.props.onClose}
          />
          <Modal.Content>{this.renderSpareParts()}</Modal.Content>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    spareParts: EntitySelectors.getSpareParts(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(DuplicateSparePartsModal);
