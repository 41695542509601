import actions from './actions';
import moment from 'moment';

const {
  setErrors,
  removeErrors,
  incrementImageLoaderCount,
  decrementImageLoaderCount,
  prepareNewMaintenanceTemplateVersion,
  setEditingOperationalMaintenanceTemplateVersionValue,
} = actions;

const setEditingOperationalMaintenanceTemplateVersion = id => (dispatch, getState) => {
  const { entities } = getState();
  const {
    operationalMaintenanceTemplateById,
    operationalMaintenanceTemplateVersionById,
    sparePartReservationById,
    imageById,
    checklistTemplateLinkById,
    attachmentById,
  } = entities;
  const operationalMaintenanceTemplate = operationalMaintenanceTemplateById[id];
  const operationalMaintenanceVersions =
    operationalMaintenanceTemplate.operational_maintenance_template_versions.map(
      id => operationalMaintenanceTemplateVersionById[id]
    );
  const activeVersion = operationalMaintenanceVersions
    .sort((a, b) => {
      return new Date(b.from_date) - new Date(a.from_date);
    })
    .find(version => {
      const { from_date } = version;
      return moment(from_date).isSameOrBefore(moment()) || from_date == null;
    });

  const { spare_part_reservations, checklist_template_links, attachments, images } = activeVersion;
  const clientSparePartReservations = spare_part_reservations.map(id => {
    const { spare_part_id, quantity } = sparePartReservationById[id];
    return {
      spare_part_id,
      quantity,
    };
  });
  const clientAttachments = attachments.map(loopedId => {
    const { id, attachment_version } = attachmentById[loopedId];
    return {
      attachment_id: id,
      attachment_version_id: attachment_version,
    };
  });

  const clientChecklistTemplates = checklist_template_links.map(loopedId => {
    const { checklist_template_id } = checklistTemplateLinkById[loopedId];
    return {
      checklist_template_id,
    };
  });

  const clientImages = images.map(id => {
    return imageById[id];
  });
  let editingOperationalTemplateVersion = {
    ...activeVersion,
    sparePartReservations: clientSparePartReservations,
    attachments: clientAttachments,
    checklists: clientChecklistTemplates,
    images: clientImages,
  };

  dispatch(actions.setEditingOperationalMaintenanceTemplateVersion(editingOperationalTemplateVersion));
};

export default {
  setEditingOperationalMaintenanceTemplateVersionValue,
  setEditingOperationalMaintenanceTemplateVersion,
  prepareNewMaintenanceTemplateVersion,
  incrementImageLoaderCount,
  decrementImageLoaderCount,
  setErrors,
  removeErrors,
};
