import React from 'react';
import { Field, Icon } from 'views/components/Shared/General';
import { TemplateFieldType } from 'sdk/TemplateField';
import { EntitySelectors } from 'sdk/State/entities';
import { connect } from 'react-redux';
import styles from './style.module.scss';

class TemplateField extends React.Component {
  renderFieldLabel = () => {
    if (this.props.templateField) {
      return this.props.templateField.title;
    }
    return '-';
  };
  renderField = () => {
    const type = this.props.templateField?.type || this.props.type;
    switch (type) {
      case TemplateFieldType.Text: {
        return (
          <Field label={this.renderFieldLabel()}>
            <Field.Text disabled />
          </Field>
        );
      }
      case TemplateFieldType.TextArea: {
        return (
          <Field label={this.renderFieldLabel()}>
            <Field.Textarea disabled />
          </Field>
        );
      }
      case TemplateFieldType.ValueList: {
        return (
          <Field label={this.renderFieldLabel()}>
            <Field.Dropdown disabled />
          </Field>
        );
      }
      case TemplateFieldType.Date: {
        return (
          <Field label={this.renderFieldLabel()}>
            <Field.Date disabled icon={<Icon regular type="calendar-alt" />} />
          </Field>
        );
      }
      default:
        return null;
    }
  };

  render() {
    let classNames = [styles['field-container']];
    if (this.props.selected || this.props.isDragging) {
      classNames = [...classNames, styles['selected']];
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          if (this.props.templateField) {
            this.props.onSelectTemplateField(this.props.id);
          }
        }}
      >
        <div
          style={{
            pointerEvents: 'none',
          }}
        >
          {this.renderField()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  if (id) {
    return {
      templateField: EntitySelectors.getTemplateField(state, ownProps.id),
    };
  }
  return {};
}

export default connect(mapStateToProps)(TemplateField);
