import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import { getVendorSubtitle } from 'sdk/Vendor';

class VendorListItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item>
          <List.Item.TitleColumn
            title={this.props.vendor.name}
            subtitle={getVendorSubtitle(this.props.vendor)}
          />
          <List.Item.Column alignRight>
            {this.props.listItemRightComponent(this.props.vendor)}
          </List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorListItem);
