import React, { Component } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Field } from 'views/components/Shared/General';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { Type } from 'sdk/ChecklistTemplateRow';
import ChecklistField from './Field';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

class SelectField extends Component {
  renderSettings = () => {
    const { checklistTemplate, isCreatingChecklistFromTemplatesLibrary, loading, hideSaveAsReusable } =
      this.props;
    if (
      hideSaveAsReusable ||
      loading ||
      isCreatingChecklistFromTemplatesLibrary ||
      checklistTemplate.reusable
    ) {
      return null;
    }
    return (
      <div className={styles['settings']}>
        <div className={styles['title-text']}>
          <FormattedMessage id="components.edit-checklist-modal.editor.settings.title" />
        </div>
        <div className={styles['options']}>
          {checklistTemplate.draft === false && checklistTemplate.reusable ? null : (
            <div className={styles['option']}>
              <Field.Checkbox
                label={
                  <FormattedMessage id="components.edit-checklist-modal.editor.settings.add-as-reusable" />
                }
                checked={this.props.saveAsReusable}
                onChange={() => {
                  this.props.setSaveAsReusable(!this.props.saveAsReusable);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={parentStyles['right-bar-wrapper']}>
          <div className={parentStyles['right-bar-container']}>
            <PerfectScrollbar>
              <div className={parentStyles['right-bar']}>
                <div className={styles['fields-title']}>
                  <FormattedMessage id="components.edit-checklist-modal.editor.title" />
                </div>
                <div className={styles['fields']}>
                  <div className={styles['field-group']}>
                    <ChecklistField loading={this.props.loading} type={Type.Inspection} />
                    <ChecklistField loading={this.props.loading} type={Type.Meter} />
                  </div>
                  <div className={styles['separator']} />
                  <ChecklistField loading={this.props.loading} type={Type.Section} />
                </div>
                {this.renderSettings()}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSaveAsReusable: ChecklistEditModalOperations.setSaveAsReusable,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateVersion = ChecklistEditModalSelectors.getChecklistTemplateVersion(state);
  let checklistTemplate = null;
  if (checklistTemplateVersion) {
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    );
  }
  return {
    isCreatingChecklistFromTemplatesLibrary:
      ChecklistEditModalSelectors.isCreatingChecklistFromTemplatesLibrary(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    saveAsReusable: ChecklistEditModalSelectors.saveAsReusable(state),
    checklistTemplateVersion,
    checklistTemplate,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectField);
