import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { AssetOperations } from 'state/ducks/asset';
import { StatusIcon } from 'views/components/WorkOrder';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const cardSource = {
  canDrag({ canAdministrateWorkOrders, workOrder, intl }) {
    if (!canAdministrateWorkOrders) {
      return;
    }
    if (workOrder && workOrder.status === WorkOrderStatus.Completed) {
      toast(
        <ToastMessage
          error
          text={<FormattedMessage id="screens.calendar.errors.cant-drag-completed-work-order" />}
        />
      );
      return false;
    }
    return true;
  },
  beginDrag(props) {
    const { date, instance } = props;
    const {
      id,
      recurring_maintenance_date: recurringMaintenanceDate,
      work_order: workOrderId,
      recurring_maintenance: recurringMaintenanceId,
    } = instance;
    if (workOrderId) {
      props.beginDrag({
        workOrderId,
      });
      return {
        date,
        instanceId: id,
        workOrderId,
      };
    } else {
      props.beginDrag({
        recurringMaintenanceId,
      });
      return {
        date,
        recurringMaintenanceDate,
        instanceId: id,
        recurringMaintenanceId,
      };
    }
  },
  endDrag(props, monitor, component) {
    props.endDrag();
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class WorkOrderListItem extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  renderContent = () => {
    if (this.props.instance.type === 'work_order') {
      return <div className={styles['title']}>{this.props.workOrder.title}</div>;
    }
    return <div className={styles['title']}>{this.props.recurringMaintenance.title}</div>;
  };

  renderStatusIcon = () => {
    if (this.props.instance.type === 'work_order') {
      switch (this.props.workOrder.status) {
        case WorkOrderStatus.InProgress:
          return (
            <div>
              <StatusIcon status={WorkOrderStatus.InProgress} />
            </div>
          );
        case WorkOrderStatus.Paused:
          return (
            <div>
              <StatusIcon status={WorkOrderStatus.Paused} />
            </div>
          );
        case WorkOrderStatus.Completed:
          return (
            <div>
              <StatusIcon status={WorkOrderStatus.Completed} />
            </div>
          );
        default:
          return null;
      }
    }
    return null;
  };

  renderSubtitle = () => {
    const { assignedToUsers, assignedToGroups, assignedToVendors } = this.props;
    let assignees = [...assignedToUsers, ...assignedToGroups, ...assignedToVendors];
    if (assignees.length > 1) {
      return (
        <div className={styles['subtitle']}>
          <FormattedPlural
            value={assignees.length}
            two={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.amount-of-assignees.two"
                values={{ amount: assignees.length }}
              />
            }
            few={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.amount-of-assignees.few"
                values={{ amount: assignees.length }}
              />
            }
            many={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.amount-of-assignees.many"
                values={{ amount: assignees.length }}
              />
            }
            other={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.amount-of-assignees.other"
                values={{ amount: assignees.length }}
              />
            }
          />
        </div>
      );
    }
    if (assignees.length === 1) {
      if (assignedToUsers.length === 1) {
        return (
          <div className={styles['subtitle']}>
            <UserNameWrapper user={assignedToUsers[0]} />
          </div>
        );
      } else if (assignedToGroups.length === 1) {
        return <div className={styles['subtitle']}>{assignedToGroups[0].title}</div>;
      } else if (assignedToVendors.length === 1) {
        return <div className={styles['subtitle']}>{assignedToVendors[0].name}</div>;
      }
    }
    return <div className={styles['subtitle']}>-</div>;
  };

  render() {
    return this.props.connectDragSource(
      <div
        onClick={e => {
          e.stopPropagation();
          const { top, left, width } = this.workOrderListItemRef.getBoundingClientRect();
          const instanceId = this.props.instance.id;
          this.props.showPreviewOverlay({ top, left: left - 10, width, instanceId });
        }}
        className={`overlay-trigger ${styles['work-order-list-item']}`}
        ref={ref => (this.workOrderListItemRef = ref)}
      >
        <div className={styles['title-container']}>
          {this.renderContent()}
          {this.renderSubtitle()}
        </div>
        {this.renderStatusIcon()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showPreviewOverlay: AssetOperations.showPreviewOverlay,
      beginDrag: AssetOperations.beginDrag,
      endDrag: AssetOperations.endDrag,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.instance.type === 'work_order') {
    const workOrder = EntitySelectors.getWorkOrder(state, ownProps.instance.work_order);
    return {
      workOrder,
      assignedToUsers: EntitySelectors.getUsers(state, ownProps.instance.users),
      assignedToGroups: EntitySelectors.getGroups(state, ownProps.instance.groups),
      assignedToVendors: EntitySelectors.getVendors(state, ownProps.instance.vendors),
      canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    };
  }
  const recurringMaintenance = EntitySelectors.getRecurringMaintenance(
    state,
    ownProps.instance.recurring_maintenance
  );
  return {
    recurringMaintenance,
    assignedToUsers: EntitySelectors.getUsers(state, ownProps.instance.users),
    assignedToGroups: EntitySelectors.getGroups(state, ownProps.instance.groups),
    assignedToVendors: EntitySelectors.getVendors(state, ownProps.instance.vendors),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DragSource('month', cardSource, collect)(WorkOrderListItem))
);
