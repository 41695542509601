import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { TemplateFieldType } from 'sdk/TemplateField';
import { Field, Icon } from 'views/components/Shared/General';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import styles from './style.module.scss';

function DraggableField(props) {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: props.field.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const renderField = () => {
    switch (props.field.type) {
      case TemplateFieldType.Text: {
        return (
          <Field label={props.field.title} view={false}>
            <Field.Text />
          </Field>
        );
      }
      case TemplateFieldType.TextArea: {
        return (
          <Field label={props.field.title} view={false}>
            <Field.Textarea />
          </Field>
        );
      }
      case TemplateFieldType.ValueList: {
        return (
          <Field label={props.field.title} view={false}>
            <Field.Dropdown />
          </Field>
        );
      }
      case TemplateFieldType.Date: {
        return (
          <Field label={props.field.title} view={false}>
            <Field.Date icon={<Icon regular type="calendar-alt" />} />
          </Field>
        );
      }
      default:
        return null;
    }
  };

  function getClassNames() {
    let classNames = [styles['field-container']];
    if (active && active.id === props.field.id) {
      classNames = [...classNames, styles['is-being-dragged']];
    }
    if (props.selectedTemplateField) {
      const isSelected = props.selectedTemplateField.id === props.field.id;
      if (isSelected) {
        classNames = [...classNames, styles['highlight']];
      } else {
        classNames = [...classNames, styles['hide']];
      }
    }
    return classNames;
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={() => {
        if (props.selectedTemplateField && props.selectedTemplateField.id === props.field.id) {
          props.unselectTemplateField();
        } else {
          props.selectTemplateField(props.field);
        }
      }}
    >
      <div className={getClassNames().join(' ')} key={props.field.id}>
        <div
          style={{
            pointerEvents: 'none',
          }}
        >
          {renderField()}
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectTemplateField: SettingsOperations.selectTemplateField,
      unselectTemplateField: SettingsOperations.unselectTemplateField,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedTemplateField: SettingsSelectors.getSelectedTemplateField(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableField);
