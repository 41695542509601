import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HelperFunctions from 'utilities/HelperFunctions';
import { PurchaseOrderOperations, PurchaseOrderSelectors } from 'state/ducks/purchaseOrder';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import NotFound from './NotFound';
import { Information, Deliveries, WorkOrders, Attachments } from './Tabs';

class PurchaseOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      show: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.PurchaseOrders);
    this.fetchPurchaseOrder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchPurchaseOrder();
    }
  }

  fetchPurchaseOrder = () => {
    this.props
      .fetchPurchaseOrder(this.props.match.params.id)
      .then(purchaseOrder => {
        const { system_id } = purchaseOrder;
        if (system_id !== this.props.currentSystem.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(
            this.props.intl.formatMessage(
              {
                id: 'screens.purchase-order.document-title',
              },
              {
                number: purchaseOrder.number,
              }
            )
          );
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.purchaseOrder) {
      return <Loader centerInParent />;
    } else {
      return (
        <Switch key={this.props.match.params.id}>
          <Route path={`/purchase-orders/:id`} exact component={Information} />
          <Route path={`/purchase-orders/:id/deliveries`} exact component={Deliveries} />
          <Route path={`/purchase-orders/:id/work-orders`} exact component={WorkOrders} />
          <Route path={`/purchase-orders/:id/Attachments`} exact component={Attachments} />
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrder: PurchaseOrderOperations.fetchPurchaseOrder,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    purchaseOrder: PurchaseOrderSelectors.getPurchaseOrder(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetail)));
