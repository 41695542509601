import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'views/components/Shared/General';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { RequestsOperations, RequestsSelectors } from 'state/ducks/requests';
import { AuthSelectors } from 'state/ducks/auth';
import { FilterButton } from 'views/components/Shared/General';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import ListItem from './Section/ListItem';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LIST_TYPES, getParamsForList } from 'sdk/Request';
import AssigneeFilterDropdown from '../AssigneeFilterDropdown';
import ApproveForAssetsDropdown from '../ApproveForAssetsDropdown';
import styles from './style.module.scss';
import RequestImage from 'assets/images/EmptyDataSet/Requests.png';
import Section from './Section';

const PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA = 350;

class Inbox extends Component {
  fetchMoreRequests = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (this.props.isFetchingMoreRequests || !this.props.moreDataCanBeFetched) return;
    let params = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...getParamsForList(list, this.props.currentUser.id),
      ...this.getAssetParamsForProductionSupervisor(),
      ...this.getOperatorAssetParams(),
      paginate_from: this.props.paginateFrom,
    };
    this.props.fetchMoreRequestsForInbox(this.props.selectedSystem.id, params, list);
  };

  getAssetParamsForProductionSupervisor = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { isProductionSupervisor, assetDropdownParams } = this.props;

    if (list !== LIST_TYPES.ToApprove && isProductionSupervisor && assetDropdownParams) {
      return { asset_with_tree_children_id: this.props.assetDropdownParams };
    }
    return {};
  };

  getOperatorAssetParams = () => {
    if (this.props.isOperator && this.props.operatorCheckedInAssetId) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: this.props.operatorCheckedInAssetId,
        },
      };
    }
    return {};
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.search?.length > 0;
  };

  renderHeaderLeftSideContent = () => {
    const { list } = queryString.parse(this.props.location.search);
    let showAssigneeDropdown = true;
    if (this.props.hasProTier === false) {
      showAssigneeDropdown = false;
    }
    if (this.props.canAdministrateRequests === false && this.props.isViewOnly === false) {
      showAssigneeDropdown = false;
    }
    if (this.props.settings.request_multiple_assignees_active === false) {
      showAssigneeDropdown = false;
    }
    if (this.props.settings.request_multiple_assignees_active === false) {
      showAssigneeDropdown = false;
    }
    if (list === LIST_TYPES.ToApprove) {
      showAssigneeDropdown = false;
    }
    if (showAssigneeDropdown) {
      return (
        <AssigneeFilterDropdown
          onFilter={filters => this.props.addQueryParameter({ page: null, ...filters })}
        />
      );
    }
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    if (list === LIST_TYPES.ToApprove && has_asset_production_supervisors) {
      return (
        <ApproveForAssetsDropdown
          onFilter={filters => {
            this.props.addQueryParameter({ page: null, ...filters });
          }}
        />
      );
    }
    return null;
  };

  renderHeader = () => {
    return (
      <div className={`${styles['header']}`}>
        <div className={styles['actions']}>
          <div className={styles['left-side-container']}>{this.renderHeaderLeftSideContent()}</div>
          <div className={styles['filter-container']}>
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="screens.work-orders.filter" />}
              filtered={this.props.isFiltering}
              onClick={this.props.onShowFilter}
            />
          </div>
        </div>
      </div>
    );
  };

  renderEmptyDataSetTitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.ToPlan: {
        return <FormattedMessage id="screens.requests.empty-data-sets.to-plan.title" />;
      }
      case LIST_TYPES.WorkOrderCreated: {
        return <FormattedMessage id="screens.requests.empty-data-sets.work-order-created.title" />;
      }
      case LIST_TYPES.WorkOrderCompleted: {
        return <FormattedMessage id="screens.requests.empty-data-sets.work-order-completed.title" />;
      }
      case LIST_TYPES.Archive: {
        return <FormattedMessage id="screens.requests.empty-data-sets.archived.title" />;
      }
      case LIST_TYPES.MyCreated: {
        return <FormattedMessage id="screens.requests.empty-data-sets.my-created.title" />;
      }
      default:
        return <FormattedMessage id="screens.requests.empty-data-sets.all.title" />;
    }
  };

  renderEmptyDataSetSubtitle = () => {
    return <FormattedMessage id="screens.requests.empty-data-sets.subtitle" />;
  };

  renderEmptyDataSet = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['image-container']}>
          <img src={RequestImage} alt="" />
        </div>
        <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
        <div className={styles['subtitle']}>{this.renderEmptyDataSetSubtitle()}</div>
      </div>
    );
  };

  renderEmptyDataSetWhenFiltering = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['image-container']}>
        <img src={RequestImage} alt="" />
      </div>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
    </div>
  );

  renderLoader = () => {
    return (
      <div className={styles['request-list-content']}>
        <Section loading />
        <ListItem loading />
        <ListItem loading />
      </div>
    );
  };

  renderListContent = () => {
    const sectionKeys = Object.keys(this.props.inboxSections);
    if (this.props.isFetching) {
      return this.renderLoader();
    }
    if (sectionKeys.length === 0) {
      if (this.isFiltering()) {
        return this.renderEmptyDataSetWhenFiltering();
      }
      return this.renderEmptyDataSet();
    }
    return (
      <>
        <div className={styles['request-list-content']}>
          <PerfectScrollbar>
            <InfiniteScroll
              className={styles['infinte-scroll']}
              loadMore={this.fetchMoreRequests}
              hasMore={this.props.moreDataCanBeFetched}
              loader={
                this.props.isFetchingMoreRequests ? (
                  <div className={styles['load-more-items']}>
                    <Loader tiny />
                  </div>
                ) : null
              }
              useWindow={false}
              initialLoad={false}
              threshold={PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA}
            >
              {sectionKeys.map(sectionKey => (
                <Section sectionKey={sectionKey} />
              ))}
            </InfiniteScroll>
          </PerfectScrollbar>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderListContent()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    inboxSections: RequestsSelectors.getInboxSections(state),
    isFetchingMoreRequests: RequestsSelectors.isFetchingMoreRequests(state),
    moreDataCanBeFetched: RequestsSelectors.moreDataCanBeFetched(state),
    paginateFrom: RequestsSelectors.getPaginateFrom(state),
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    isFetching: RequestsSelectors.isFetching(state),
    isFiltering: RequestsSelectors.isFiltering(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    settings: AuthSelectors.getSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    filters: RequestsSelectors.getFilters(state),
    queryParameters: RequestsSelectors.getQueryParameters(state),
    filtersAsQueryParams: RequestsSelectors.getFiltersAsQueryParams(state),
    isOperator: AuthSelectors.isOperator(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    operatorCheckedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addQueryParameter: RequestsOperations.addQueryParameter,
      fetchMoreRequestsForInbox: RequestsOperations.fetchMoreRequestsForInbox,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Inbox));
