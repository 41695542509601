import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { withRouter, matchPath } from 'react-router';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Menu } from 'views/components/Shared/General';
import { NewPurchaseOrderModal } from 'views/components/PurchaseOrder';
import { ListLayout } from 'views/components/Shared/Layout';
import { PurchaseOrdersSelectors, PurchaseOrdersOperations } from 'state/ducks/purchaseOrders';
import HelperFunctions from 'utilities/HelperFunctions';
import PurchaseOrders from './PurchaseOrders';
import PreviousDeliveries from './PreviousDeliveries';

class PurchaseOrdersList extends Component {
  state = {
    showCreateNewPurchaseOrderModal: false,
    purchaseOrdersSearchTerm: this.props.queryParameters.search || '',
  };

  openPurchaseOrder = purchaseOrderId => {
    this.props.history.push(`/purchase-orders/${purchaseOrderId}`);
  };

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.PurchaseOrders);
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.purchase-orders.document-title' })
    );
  }

  renderHeader = () => {
    const { totalEntries } = this.props.pagination;
    if (matchPath(this.props.location.pathname, { path: '/purchase-orders', exact: true }) != null) {
      return (
        <ListLayout.Header
          title={<FormattedMessage id="screens.purchase-orders.title" />}
          searchable
          searchValue={this.state.purchaseOrdersSearchTerm}
          searchPlaceHolder={this.props.intl.formatMessage({
            id: 'screens.purchase-orders.search-placeholder',
          })}
          totalEntries={
            <FormattedMessage
              id="screens.purchase-orders.total-entries"
              values={{
                amount: totalEntries,
              }}
            />
          }
          onSearch={purchaseOrdersSearchTerm => {
            this.props.showLoader();
            this.setState({ purchaseOrdersSearchTerm });
          }}
          onDebouncedSearch={() => {
            this.props.addQueryParameter({ page: 1, number: this.state.purchaseOrdersSearchTerm || null });
          }}
          onClearSearch={() => {
            this.setState({ purchaseOrdersSearchTerm: '' });
            this.props.showLoader();
            this.props.addQueryParameter({ page: 1, number: null });
          }}
        />
      );
    }
    return <ListLayout.Header title={<FormattedMessage id="screens.purchase-orders.title" />} />;
  };

  renderCreateButton = () => {
    if (this.props.canEditPurchaseOrders) {
      return (
        <>
          <Button
            primary
            fullWidth
            label="screens.purchase-orders.new-button"
            onClick={() => this.setState({ showCreateNewPurchaseOrderModal: true })}
          />
          <Menu.Separator />
        </>
      );
    }

    return null;
  };

  renderLeftMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <ListLayout.Content.Menu.Content>
            {this.renderCreateButton()}
            <Menu.Item
              linkTo={{
                pathname: `/purchase-orders`,
                search: 'list=draft',
              }}
              title={<FormattedMessage id="screens.purchase-orders.left-menu.drafts" />}
              selected={
                matchPath(this.props.location.pathname, { path: '/purchase-orders', exact: true }) != null &&
                list === 'draft'
              }
            />
            <Menu.Item
              linkTo={{
                pathname: `/purchase-orders`,
                search: 'list=awaiting_delivery',
              }}
              title={<FormattedMessage id="screens.purchase-orders.left-menu.awaiting-delivery" />}
              selected={
                matchPath(this.props.location.pathname, {
                  path: '/purchase-orders',
                  exact: true,
                }) != null && list === 'awaiting_delivery'
              }
            />
            <Menu.Item
              linkTo={{
                pathname: `/purchase-orders`,
                search: 'list=fully_delivered',
              }}
              title={<FormattedMessage id="screens.purchase-orders.left-menu.fully-delivered" />}
              selected={
                matchPath(this.props.location.pathname, {
                  path: '/purchase-orders',
                  exact: true,
                }) != null && list === 'fully_delivered'
              }
            />
            <Menu.Separator />
            <Menu.Item
              linkTo={{
                pathname: `/purchase-orders`,
              }}
              title={<FormattedMessage id="screens.purchase-orders.left-menu.all-purchase-orders" />}
              selected={
                matchPath(this.props.location.pathname, {
                  path: '/purchase-orders',
                  exact: true,
                }) != null && list == null
              }
            />
            <Menu.Item
              linkTo={{
                pathname: `/purchase-orders/deliveries`,
              }}
              title={<FormattedMessage id="screens.purchase-orders.left-menu.deliveries" />}
              selected={
                matchPath(this.props.location.pathname, {
                  path: '/purchase-orders/deliveries',
                  exact: true,
                }) != null
              }
            />
          </ListLayout.Content.Menu.Content>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <Switch>
          <Route path="/purchase-orders" exact component={PurchaseOrders} />
          <Route path="/purchase-orders/deliveries" exact component={PreviousDeliveries} />
        </Switch>
      </ListLayout.Content.MainContent>
    );
  };

  renderNewPurchaseOrderModal = () => (
    <NewPurchaseOrderModal
      open={this.state.showCreateNewPurchaseOrderModal}
      onClose={() => {
        this.setState({ showCreateNewPurchaseOrderModal: false });
      }}
      onCreated={purchaseOrder => {
        this.setState(
          {
            showCreateNewPurchaseOrderModal: false,
          },
          () => {
            setTimeout(() => {
              this.openPurchaseOrder(purchaseOrder.id);
            }, 350);
          }
        );
      }}
      onCreatedWithReopen={purchaseOrder => {
        this.setState(
          {
            showCreateNewPurchaseOrderModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ showCreateNewPurchaseOrderModal: true });
            }, 400);
          }
        );
      }}
    />
  );

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderNewPurchaseOrderModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    pagination: PurchaseOrdersSelectors.getPagination(state),
    queryParameters: PurchaseOrdersSelectors.getQueryParameters(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addQueryParameter: PurchaseOrdersOperations.addQueryParameter,
      showLoader: PurchaseOrdersOperations.showLoader,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersList)));
