import types from './types';

const fetchAttachmentsSuccess = payload => ({
  type: types.FETCH_ATTACHMENTS_SUCCESS,
  payload,
});

export default {
  fetchAttachmentsSuccess,
};
