import types from './types';
import { isEqual } from 'lodash-es';
import update from 'immutability-helper';

/* 
  Structure of editingOperationalMaintenanceTemplateVersion:
  {
    title: '',
    description: '',
    checklists: [{
      checklist_template_id: '',
    }],
    sparePartReservations: [{
      spare_part_id: '',
      quantity: '',
    }],
    attachments: [{
      attachment_id,
      attachment_version_id: '',
    }],
    images: [{
      image_id: '',
    }],
  }
*/

const INITIAL_STATE = {
  editingOperationalMaintenanceTemplateVersionBeforeEdit: {},
  editingOperationalMaintenanceTemplateVersion: {},
  hasUnsavedChanges: false,
  imageLoaderCount: 0,
  errors: {
    title: false,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_ERRORS: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    }
    case types.REMOVE_ERORRS: {
      return update(state, {
        errors: {
          $unset: [action.payload],
        },
      });
    }
    case types.INCREMENT_IMAGE_LOADER_COUNT: {
      return {
        ...state,
        imageLoaderCount: state.imageLoaderCount + 1,
      };
    }
    case types.DECREMENT_IMAGE_LOADER_COUNT: {
      return {
        ...state,
        imageLoaderCount: state.imageLoaderCount - 1 <= 0 ? 0 : state.imageLoaderCount - 1,
      };
    }
    case types.PREPARE_NEW_MAINTENANCE_TEMPLATE_VERSION: {
      const editingOperationalMaintenanceTemplateVersion = {
        title: '',
        description: '',
        checklists: [],
        sparePartReservations: [],
        attachments: [],
        images: [],
      };
      return {
        ...INITIAL_STATE,
        editingOperationalMaintenanceTemplateVersionBeforeEdit: editingOperationalMaintenanceTemplateVersion,
        editingOperationalMaintenanceTemplateVersion,
      };
    }
    case types.SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION: {
      const editingOperationalTemplateVersion = action.payload;
      return {
        ...INITIAL_STATE,
        editingOperationalMaintenanceTemplateVersionBeforeEdit: editingOperationalTemplateVersion,
        editingOperationalMaintenanceTemplateVersion: editingOperationalTemplateVersion,
      };
    }
    case types.SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_VALUES: {
      const data = action.payload;
      const editingOperationalMaintenanceTemplateVersion = {
        ...state.editingOperationalMaintenanceTemplateVersion,
        ...data,
      };
      return {
        ...state,
        editingOperationalMaintenanceTemplateVersion,
        hasUnsavedChanges: !isEqual(
          state.editingOperationalMaintenanceTemplateVersionBeforeEdit,
          editingOperationalMaintenanceTemplateVersion
        ),
      };
    }
    default:
      return state;
  }
};
