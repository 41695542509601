import React, { Component } from 'react';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WhiteCard } from 'views/components/Shared/General';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Separator, UnsavedChangesBottomBar } from 'views/scenes/Settings/components';
import General from './General';
import ContactInformation from './ContactInformation';
import Language from './Language';
import ChangePassword from './ChangePassword';

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingUser: props.currentUser,
      editingUserBeforeEdit: props.currentUser,
      showBottomSaveBar: false,
    };
  }

  changeUserValue = params => {
    this.setState(
      {
        editingUser: { ...this.state.editingUser, ...params },
      },
      () => {
        const amountOfChangedProperties = Object.keys(
          HelperFunctions.getDataChanges(this.state.editingUser, this.state.editingUserBeforeEdit)
        ).length;
        this.setState({ showBottomSaveBar: amountOfChangedProperties > 0 });
      }
    );
  };

  updateMe = () => {
    this.setState({ isSaving: true });
    const changes = HelperFunctions.getDataChanges(this.state.editingUser, this.state.editingUserBeforeEdit);
    this.props
      .updateMe(changes)
      .then(() => {
        if (changes.language) {
          document.documentElement.lang = changes.language;
          moment.locale(changes.language);
        }
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({
          isSaving: false,
          showBottomSaveBar: false,
          editingUser: this.props.currentUser,
          editingUserBeforeEdit: this.props.currentUser,
        });
      })
      .catch(e => {
        this.setState({ isSaving: false, showBottomSaveBar: false });
      });
  };

  renderChangePassword = () => {
    if (this.props.isGroupAuthenticated) {
      return null;
    }
    return (
      <>
        <Separator />
        <ChangePassword />
      </>
    );
  };

  render() {
    const { scim } = this.props.currentUser;
    return (
      <WhiteCard>
        <Prompt
          when={this.state.showBottomSaveBar}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <General />
        <Separator />
        {!scim ? (
          <>
            <ContactInformation
              editingUser={this.state.editingUser}
              onChange={params => this.changeUserValue(params)}
            />
            <Separator />
          </>
        ) : null}

        <Language editingUser={this.state.editingUser} onChange={params => this.changeUserValue(params)} />

        {!scim ? <>{this.renderChangePassword()} </> : null}

        <UnsavedChangesBottomBar
          show={this.state.showBottomSaveBar}
          loading={this.state.isSaving}
          onClose={() => this.setState({ showBottomSaveBar: false })}
          onSave={() => this.updateMe()}
        />
      </WhiteCard>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMe: SDKReduxOperations.updateMe,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MyProfile));
