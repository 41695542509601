import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { ToastMessage } from 'views/components/Shared/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import {
  Button,
  NewTab,
  WhiteCard,
  SectionHeader,
  Toggle,
  ConfirmModal,
} from 'views/components/Shared/General';
import { Section, Option, SectionChild } from 'views/scenes/Settings/components';
import { RequestReceiverType } from 'sdk/Settings';
import Types from './Types';
import { Assignees, Basic } from './Assignees';
import EditRequiredFieldsModal from './EditRequiredFieldsModal';
import { SendRequestToProductionSupervisorModal } from 'views/scenes/Settings/components';
import styles from './style.module.scss';

class Request extends Component {
  state = {
    showEditRequiredFieldsModal: false,
    showSendRequestToProductionSupervisorModal: false,
    isSavingSettings: false,
    updatingRequestProductionSupervisorApproval: false,
    showCreatorChooseIsActivatedConfirmModal: false,
  };

  routeIsActive = path => {
    return (
      matchPath(this.props.location.pathname, {
        path: path,
        exact: true,
      }) != null
    );
  };

  updateSettings = params => {
    if (this.state.isSavingSettings) return;
    this.setState({ isSavingSettings: true });
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          isSavingSettings: false,
          showEditRequiredFieldsModal: false,
          showSendRequestToProductionSupervisorModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          isSavingSettings: false,
          showEditRequiredFieldsModal: false,
          showSendRequestToProductionSupervisorModal: false,
        });
      });
  };

  renderSendRequestToProductionSupervisorModal = () => (
    <SendRequestToProductionSupervisorModal
      open={this.state.showSendRequestToProductionSupervisorModal}
      onClose={() => this.setState({ showSendRequestToProductionSupervisorModal: false })}
      settings={this.props.settings}
      onSave={() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ showSendRequestToProductionSupervisorModal: false });
      }}
    />
  );

  renderRootSettings = () => (
    <Option.Container>
      <Option
        title={<FormattedMessage id="screens.settings.requests.types.title" />}
        subtitle={<FormattedMessage id="screens.settings.requests.types.subtitle" />}
        rightContainer={
          <>
            <Link to="/settings/requests/types">
              <Button primary label="general.manage" />
            </Link>
          </>
        }
      />
      <Option
        title={<FormattedMessage id="screens.settings.requests.required-fields.title" />}
        subtitle={<FormattedMessage id="screens.settings.requests.required-fields.subtitle" />}
        rightContainer={
          <Button
            disabled={this.state.isSavingSettings}
            primary
            label="general.manage"
            onClick={() => this.setState({ showEditRequiredFieldsModal: true })}
          />
        }
      />
    </Option.Container>
  );

  renderTabs = () => {
    return (
      <div className={styles['tab-container']}>
        <NewTab.Container>
          <NewTab selected={this.routeIsActive('/settings/requests')} linkTo="/settings/requests">
            <FormattedMessage id="screens.settings.requests.tabs.general" />
          </NewTab>
          <NewTab
            selected={this.routeIsActive('/settings/requests/receivers')}
            linkTo="/settings/requests/receivers"
            locked={this.props.hasProTier === false}
          >
            <FormattedMessage id="screens.settings.requests.tabs.assignees" />
          </NewTab>
        </NewTab.Container>
      </div>
    );
  };

  renderCreatorChoosesIsActivatedConfirm = () => {
    return (
      <ConfirmModal
        open={this.state.showCreatorChooseIsActivatedConfirmModal}
        title={
          <FormattedMessage id="screens.settings.requests.production-supervisor-approval.creator-chooses-activated-confim.title" />
        }
        message={
          <FormattedMessage id="screens.settings.requests.production-supervisor-approval.creator-chooses-activated-confim.subtitle" />
        }
        confirmButtonText="general.ok"
        onConfirm={() => {
          this.setState({ showCreatorChooseIsActivatedConfirmModal: false });
        }}
        onCancel={() => {
          this.setState({ showCreatorChooseIsActivatedConfirmModal: false });
        }}
      />
    );
  };

  renderSettings = () => {
    const onRootSettings =
      matchPath(this.props.location.pathname, {
        path: '/settings/requests',
        exact: true,
      }) != null;
    if (onRootSettings) {
      return (
        <>
          <div className={styles['list-group']}>
            <SectionHeader paddingHorizontal={50} horizontalBorders>
              <div className={styles['settings']}>
                <div className={styles['title']}>
                  <FormattedMessage id="screens.settings.requests.section-title" />
                </div>
              </div>
            </SectionHeader>
            <SectionChild
              title={<FormattedMessage id="screens.settings.requests.production-supervisor-approval.title" />}
              subtitle={
                <div>
                  <div>
                    <FormattedMessage id="screens.settings.requests.production-supervisor-approval.subtitle" />
                  </div>
                  {this.props.settings.production_board_activated ? null : (
                    <div className={styles['warning-subtitle']}>
                      <FormattedMessage id="screens.settings.requests.production-supervisor-approval.warning" />
                    </div>
                  )}
                </div>
              }
              rightComponent={
                <div className={styles['toggle-and-button-container']}>
                  <Toggle
                    loading={this.state.updatingRequestProductionSupervisorApproval}
                    disabled={!this.props.settings.production_board_activated}
                    checked={
                      this.props.settings.request_production_supervisor_approval_activated &&
                      this.props.settings.production_board_activated
                    }
                    onClick={() => {
                      const { request_multiple_assignees_active, request_multiple_assignees_type } =
                        this.props.settings;
                      if (
                        this.props.hasProTier &&
                        request_multiple_assignees_active &&
                        request_multiple_assignees_type === RequestReceiverType.SelectedByCreator
                      ) {
                        this.setState({ showCreatorChooseIsActivatedConfirmModal: true });
                        return;
                      }

                      this.setState({ updatingRequestProductionSupervisorApproval: true });
                      const nextVal = !this.props.settings.request_production_supervisor_approval_activated;
                      this.props
                        .updateSettings(this.props.system.id, {
                          request_production_supervisor_approval_activated: nextVal,
                        })
                        .then(() => {
                          this.setState({ updatingRequestProductionSupervisorApproval: false });
                          toast(
                            <ToastMessage success text={<FormattedMessage id="general.update-success" />} />
                          );
                        });
                    }}
                  />
                  <Button
                    disabled={
                      !this.props.settings.request_production_supervisor_approval_activated ||
                      !this.props.settings.production_board_activated
                    }
                    gray
                    label={'general.manage'}
                    onClick={() => this.setState({ showSendRequestToProductionSupervisorModal: true })}
                  />
                </div>
              }
            />
          </div>
          {this.renderSendRequestToProductionSupervisorModal()}
          {this.renderCreatorChoosesIsActivatedConfirm()}
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <WhiteCard>
          <Route
            exact
            path={['/settings/requests', '/settings/requests/receivers']}
            render={() => (
              <div className={styles['section-container']}>
                <div className={styles['section']}>
                  <Section
                    header
                    title={<FormattedMessage id="screens.settings.requests.title" />}
                    subtitle={<FormattedMessage id="screens.settings.requests.subtitle" />}
                  />
                  {this.renderTabs()}
                </div>
              </div>
            )}
          />
          <Switch>
            <Route exact path="/settings/requests" render={() => this.renderRootSettings()} />
            <Route exact path="/settings/requests/types" component={Types} />
            {this.props.hasProTier === false ? (
              <Route exact path="/settings/requests/receivers" component={Basic} />
            ) : (
              <Route exact path="/settings/requests/receivers" component={Assignees} />
            )}
          </Switch>
          <EditRequiredFieldsModal
            open={this.state.showEditRequiredFieldsModal}
            onClose={() => {
              this.setState({ showEditRequiredFieldsModal: false });
            }}
          />
        </WhiteCard>

        {this.renderSettings()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    system: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);
