import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { SparePartsSelectors, SparePartsOperations } from 'state/ducks/spareParts';
import { FILTER_TYPES } from 'views/scenes/SpareParts/SparePartList/FilterModal';

class ArticleNumber extends Component {
  render() {
    const { value } = this.props;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Text
          title={<FormattedMessage id="resources.spare-part.article-number" />}
          comparator={HelperFunctions.FILTER_COMPARABLES.Search}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.ArticleNumber,
              data: {
                [FILTER_TYPES.ArticleNumber]: '',
              },
            })
          }
        >
          <SideModal.Container.Filter.AppliedFilters.Value>
            {value}
          </SideModal.Container.Filter.AppliedFilters.Value>
        </SideModal.Container.Filter.AppliedFilters.Filter.Text>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: SparePartsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    value: SparePartsSelectors.getFiltersForKey(state, FILTER_TYPES.ArticleNumber),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleNumber);
