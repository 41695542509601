import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Button, EmptyDataSet } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeRequestSelectableAssigneee } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import RequestSelectableAssignee from './RequestSelectableAssignee';

class ChooseAssigneeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestSelectableAssigneeIds: [],
      isFetching: false,
      isSkippingAssignee: false,
      isSavingUserIdAsAssignee: null,
      isSavingGroupIdAsAssignee: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        requestSelectableAssigneeIds: [],
        isFetching: true,
        isSkippingAssignee: false,
        isSavingUserIdAsAssignee: null,
        isSavingGroupIdAsAssignee: null,
      });
      this.fetchRequestSelectableAssignees();
    }
  }

  fetchRequestSelectableAssignees = () => {
    API.listRequestSelectableAssignee(this.props.system.id, { no_pagination: true })
      .then(({ data }) => {
        const { entities, result: requestSelectableAssigneeIds } = normalizeRequestSelectableAssigneee(data);
        this.props.updateEntities(entities);
        this.setState({
          isFetching: false,
          requestSelectableAssigneeIds,
        });
      })
      .catch(e => {
        throw e;
      });
  };

  isCreatingRequest = () => {
    if (this.state.isSkippingAssignee) {
      return true;
    }
    if (this.state.isSavingUserIdAsAssignee) {
      return true;
    }
    if (this.state.isSavingGroupIdAsAssignee) {
      return true;
    }
  };

  createRequest = params => {
    this.props
      .createRequest(this.props.system.id, { ...this.props.request, ...params })
      .then(({ data: request }) => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.create-success" />} />);
        this.props.onCreated(request);
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  renderRequestSelectableAssignees = () => {
    if (this.state.isFetching) {
      return (
        <List light>
          <List.Item>
            <List.Item.ImageColumn loading size={34} />
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.ImageColumn loading size={34} />
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.requestSelectableAssigneeIds.length === 0) {
      return (
        <EmptyDataSet
          title={<FormattedMessage id="components.choose-request-assignee-modal.empty-data-set.title" />}
          subtitle={
            <FormattedMessage id="components.choose-request-assignee-modal.empty-data-set.subtitle" />
          }
          modal
        />
      );
    }
    return (
      <List light>
        {this.state.requestSelectableAssigneeIds.map(id => (
          <RequestSelectableAssignee
            id={id}
            key={id}
            isSavingUserIdAsAssignee={this.state.isSavingUserIdAsAssignee}
            isSavingGroupIdAsAssignee={this.state.isSavingGroupIdAsAssignee}
            onSelectUser={id => {
              if (this.isCreatingRequest()) {
                return;
              }
              this.setState({ isSavingGroupIdAsAssignee: null, isSavingUserIdAsAssignee: id });
              this.createRequest({ assigned_to_user_id: id });
            }}
            onSelectGroup={id => {
              if (this.isCreatingRequest()) {
                return;
              }
              this.setState({ isSavingUserIdAsAssignee: null, isSavingGroupIdAsAssignee: id });
              this.createRequest({ assigned_to_group_id: id });
            }}
          />
        ))}
      </List>
    );
  };

  renderSkipAssigneeButton = () => {
    if (this.props.settings.request_allow_skip_assignee) {
      return (
        <Button
          primary
          loading={this.state.isSkippingAssignee}
          label="components.choose-request-assignee-modal.skip"
          onClick={() => {
            if (this.isCreatingRequest()) {
              return;
            }
            this.setState({ isSkippingAssignee: true });
            this.createRequest();
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={485}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.choose-request-assignee-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderRequestSelectableAssignees()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            {this.renderSkipAssigneeButton()}
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createRequest: SDKReduxOperations.createRequest,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAssigneeModal);
