import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';


const Button = props => {
  const clickButton = e => {
    if (props.disabled || props.loading) return;
    props.onClick(e);
  };

  const renderLabel = () => {
    if (props.icon) {
      return props.icon;
    } else {
      return props.label;
    }
  };

  return (
    <button disabled={props.disabled} className={styles['button']} onClick={clickButton}>
      <div className={styles['loader-container']}>
        <div className={styles['loader']}>{props.loading ? <Loader light={!props.gray} tiny /> : null}</div>
      </div>
      <span className={props.loading ? styles['hide-label'] : ''}>
        {renderLabel()}
      </span>
    </button>
  );
};

export default Button;

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  lightHover: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  loading: false,
  onClick: () => null
};
