import types from './types';
import { isEqual, mergeWith, uniq } from 'lodash-es';
import moment from 'moment';
import update from 'immutability-helper';
import { SDKReduxTypes } from 'sdk';
import { MenuTypes } from 'state/ducks/menu';
import { generateEmptyRequest, mergeOldInboxSectionsWithNewInboxSections, GROUP_BY_TYPES } from 'sdk/Request';

const INITIAL_STATE = {
  isFetching: true,
  isFetchingMoreRequests: false,
  moreDataCanBeFetched: true,
  selectedRequestId: null,
  isNewRequest: false,
  editingRequest: null,
  editingRequestBeforeEdit: null,
  hasUnsavedChanges: false,
  groupInboxBy: null,
  isFiltering: false,
  showingAppliedFilters: false,
  showApplyFilterForField: null,
  selectedRequests: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesAreSelected: false,
    ids: {},
  },
  queryParams: {
    page: 1,
    page_size: 25,
  },
  appliedFilters: [],
  filters: {},
  inboxSections: {},
  ids: [],
  pagination: {
    paginateFrom: null,
    totalEntries: 0,
    totalPages: 1,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SELECT_FILTER_TYPE: {
      return update(state, {
        showApplyFilterForField: {
          $set: action.payload,
        },
      });
    }
    case types.ADD_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        appliedFilters: {
          $apply: appliedFilters => uniq([...appliedFilters, key]),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.REMOVE_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        showingAppliedFilters: {
          $set: state.appliedFilters.filter(id => id !== key).length > 0,
        },
        showApplyFilterForField: {
          $set: null,
        },
        appliedFilters: {
          $apply: appliedFilters => appliedFilters.filter(id => id !== key),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.RESET_FILTER: {
      return {
        ...state,
        showingAppliedFilters: false,
        appliedFilters: [],
        filters: INITIAL_STATE.filters,
      };
    }
    case types.SHOW_APPLIED_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: true,
        showApplyFilterForField: null,
      };
    }
    case types.SHOW_AVAILABLE_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: false,
      };
    }
    /*
      MultiAction
    */
    case types.SELECT_PAGE: {
      if (state.selectedRequests.pageIsSelected) {
        return update(state, {
          selectedRequests: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedRequests.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedRequests: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedRequests.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedRequests.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedRequests.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_REQUEST: {
      const requestId = action.payload;
      if (state.selectedRequests.ids[requestId]) {
        return update(state, {
          selectedRequests: {
            ids: {
              $unset: [requestId],
            },
            totalEntriesAreSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== requestId)
          .every(id => state.selectedRequests.ids[id] === true);
        return update(state, {
          selectedRequests: {
            ids: {
              [requestId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedRequests: {
          totalEntriesAreSelected: {
            $set: true,
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedRequests: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesAreSelected: {
            $set: false,
          },
        },
      });
    }
    case types.RESET_SELECTED_REQUESTS: {
      return update(state, {
        selectedRequests: {
          $set: {
            ...INITIAL_STATE.selectedRequests,
          },
        },
      });
    }
    case types.INITIALIZE_VIEW:
      return {
        ...INITIAL_STATE,
      };
    case types.FETCH_REQUESTS_FOR_INBOX:
      return {
        ...state,
        isFetching: true,
      };
    case types.SET_GROUP_BY: {
      return {
        ...state,
        groupInboxBy: action.payload,
      };
    }
    case types.FETCH_REQUESTS_FOR_INBOX_SUCCESS: {
      const { inboxSections, amountOfRequests, pagination } = action.payload;
      return {
        ...state,
        moreDataCanBeFetched: amountOfRequests >= 20,
        inboxSections,
        isFetching: false,
        pagination,
      };
    }
    case types.FETCH_MORE_REQUESTS_FOR_INBOX:
      return {
        ...state,
        isFetchingMoreRequests: true,
      };
    case types.FETCH_MORE_REQUESTS_FOR_INBOX_SUCCESS: {
      const { amountOfRequests, inboxSections, paginateFrom } = action.payload;
      let currentData = { ...state.inboxSections };
      return {
        ...state,
        inboxSections: mergeOldInboxSectionsWithNewInboxSections(currentData, inboxSections),
        moreDataCanBeFetched: amountOfRequests >= 20,
        isFetchingMoreRequests: false,
        pagination: {
          ...state.pagination,
          paginateFrom,
        },
      };
    }
    case types.FETCH_REQUESTS_FOR_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCH_REQUESTS_FOR_LIST_SUCCESS: {
      const { ids, pagination } = action.payload;
      let allRowsAreSelected = ids.length > 0 && ids.every(id => state.selectedRequests.ids[id] === true);
      if (state.selectedRequests.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        isFetching: {
          $set: false,
        },
        selectedRequests: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    }
    case types.SET_LOADING:
      return { ...state, isFetching: true };
    case types.PREPARE_NEW_REQUEST: {
      if (state.isNewRequest) {
        return state;
      }
      const editingRequest = generateEmptyRequest();
      return {
        ...state,
        isNewRequest: true,
        hasUnsavedChanges: false,
        editingRequest,
        editingRequestBeforeEdit: editingRequest,
      };
    }
    case types.CANCEL_NEW_REQUEST: {
      return {
        ...state,
        editingRequest: null,
        isNewRequest: false,
      };
    }
    case types.SET_REQUEST_FOR_EDIT: {
      const editingRequest = action.payload;
      return {
        ...state,
        isNewRequest: false,
        editingRequest,
        hasUnsavedChanges: false,
        editingRequestBeforeEdit: editingRequest,
      };
    }
    case types.SET_REQUEST_FOR_VIEW: {
      return {
        ...state,
        isNewRequest: false,
        editingRequest: null,
        editingRequestBeforeEdit: null,
        selectedRequestId: action.payload,
        hasUnsavedChanges: false,
      };
    }
    case types.SET_EDITING_REQUEST_VALUES: {
      const editingRequest = {
        ...state.editingRequest,
        ...action.payload,
      };
      return {
        ...state,
        editingRequest,
        hasUnsavedChanges: !isEqual(editingRequest, state.editingRequestBeforeEdit),
      };
    }
    case SDKReduxTypes.REQUEST_CREATED: {
      const { data: request } = action.payload;
      if (state.isNewRequest) {
        return update(state, {
          selectedRequestId: {
            $set: request.id,
          },
          editingRequest: {
            $set: null,
          },
          isNewRequest: {
            $set: false,
          },
          hasUnsavedChanges: {
            $set: false,
          },
          ids: {
            $set: [request.id, ...state.ids],
          },
          inboxSections: {
            $apply: data => {
              switch (state.groupInboxBy) {
                case GROUP_BY_TYPES.RequestArchivedDate:
                case GROUP_BY_TYPES.WorkOrderCompletedDate:
                case GROUP_BY_TYPES.WorkOrdeDueDate: {
                  return update(data || {}, {
                    none: section =>
                      update(section || [], {
                        $apply: data => [request.id, ...(data || [])],
                      }),
                  });
                }
                default: {
                  const addToSection = moment(request.created_at).isoWeekday(1).format('YYYY-MM-DD');
                  return update(data || {}, {
                    [addToSection]: section =>
                      update(section || [], {
                        $apply: data => [request.id, ...(data || [])],
                      }),
                  });
                }
              }
            },
          },
        });
      } else {
        return state;
      }
    }
    case SDKReduxTypes.REQUEST_DELETED: {
      const { requestId } = action.payload;
      return update(state, {
        ids: {
          $set: state.ids.filter(id => id !== requestId),
        },
        inboxSections: {
          $apply: inboxSections => {
            let newInboxSections = { ...inboxSections };
            Object.keys(newInboxSections).forEach(key => {
              newInboxSections[key] = newInboxSections[key].filter(id => id !== requestId);
              if (newInboxSections[key].length === 0) {
                delete newInboxSections[key];
              }
            });
            return newInboxSections;
          },
        },
      });
    }
    case SDKReduxTypes.REQUEST_UPDATED: {
      const { requestId } = action.payload;
      if (state.selectedRequestId === requestId) {
        return {
          ...state,
          hasUnsavedChanges: false,
          isNewRequest: false,
          editingRequest: null,
          editingRequestBeforeEdit: null,
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.IMAGE_DRAFT_CREATED: {
      const { data } = action.payload;
      const { id } = data;
      if (state.editingRequest) {
        return {
          ...state,
          editingRequest: {
            ...state.editingRequest,
            images: [...state.editingRequest.images, id],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_REQUEST: {
      const { data } = action.payload;
      const { selectedRequestId } = state;

      if (data.request_id !== selectedRequestId) {
        return state;
      }

      return update(
        { ...state },
        {
          editingRequest: {
            images: {
              $push: [data.id],
            },
          },
        }
      );
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId } = action.payload;
      if (state.editingRequest) {
        return {
          ...state,
          editingRequest: {
            ...state.editingRequest,
            images: state.editingRequest.images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REQUEST_TYPE_DELETED: {
      const { requestTypeId: deletedId } = action.payload;
      const { editingRequest } = state;

      if (state.editingRequest == null) return state;
      return {
        ...state,
        editingRequest: {
          ...editingRequest,
          request_type_id:
            editingRequest.request_type_id === deletedId ? null : editingRequest.request_type_id,
        },
      };
    }
    default:
      return state;
  }
};
