import React, { Component } from 'react';
import HelperFunctions from 'utilities/HelperFunctions';
import AppStoreBadge from 'assets/images/app-store-badge.svg';
import GooglePlayBadge from 'assets/images/google-play-badge.png';
import { bindActionCreators } from 'redux';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import { connect } from 'react-redux';
import { Button } from 'views/components/Shared/General';
import Logo from 'assets/images/Logo.png';
import styles from './style.module.scss';

class RedirectToApp extends Component {
  renderStoreBadge = () => {
    switch (HelperFunctions.getMobileOperatingSystem()) {
      case 'ios':
        return (
          <a href="https://apps.apple.com/us/app/am-service/id1476244662?ls=1">
            <img src={AppStoreBadge} alt="badge" />
          </a>
        );
      case 'android':
        return (
          <a href="https://play.google.com/store/apps/details?id=com.amservice.mobile">
            <img src={GooglePlayBadge} alt="badge" />
          </a>
        );
      default:
        return null;
    }
  };
  render() {
    return (
      <div className={styles['redirect-to-app']}>
        <div className={styles['open-app-container']}>
          <img src={Logo} alt="logo" />
          <p>Mainter</p>
          <a href="amservice://feed">Open the Mainter App</a>
          {this.props.isUserAuthenticated ? (
            <Button translate={false} type="text" label="Log out" onClick={this.props.unauthenticateUser} />
          ) : null}
        </div>
        <div className={styles['download-app-container']}>
          <p>Don’t have the app?</p>
          {this.renderStoreBadge()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unauthenticateUser: AuthOperations.unauthenticateUser,
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToApp);
