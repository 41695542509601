import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SettingsOperations } from 'state/ducks/settings';
import { SDKReduxOperations } from 'sdk';
import DraggingField from './DraggingField';

function DndContextContainer(props) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [activeId, setActiveId] = useState();

  function sortTemplateField({ templateFieldId, fromIndex, toIndex, column }) {
    props.sortTemplateField({ templateFieldId, fromIndex, toIndex, column });
    props
      .updateTemplateField(templateFieldId, {
        sort: toIndex + 1,
      })
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.update-success" />}
          />
        );
      })
      .catch(() => {});
  }

  function sortTemplateFieldBetweenColumns({ templateFieldId, fromIndex, toIndex, fromColumn, toColumn }) {
    props.sortTemplateFieldBetweenColumns({ templateFieldId, fromIndex, toIndex, fromColumn, toColumn });
    props
      .updateTemplateField(templateFieldId, {
        sort: toIndex + 1,
        column: toColumn,
      })
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.update-success" />}
          />
        );
      })
      .catch(() => {});
  }

  const onDragOver = dragData => {
    const { active, over } = dragData;
    const { id: isDraggingId } = active;
    const { id: isOverId } = over;
    const { containerId: fromColumn, index: fromIndex } = active?.data?.current?.sortable || {};
    const { containerId: toColumn, index: toIndex } = over?.data?.current?.sortable || {};

    if (isDraggingId === isOverId) {
      return;
    }
    if (fromColumn !== toColumn) {
      sortTemplateFieldBetweenColumns({
        templateFieldId: isDraggingId,
        fromIndex,
        toIndex: toIndex == null ? 0 : toIndex,
        fromColumn,
        toColumn: toColumn == null ? (fromColumn === 1 ? 2 : 1) : toColumn,
      });
    }
  };

  const onDragEnd = dragData => {
    const { active, over } = dragData;
    const { id: isDraggingId } = active;
    const { id: isOverId } = over;
    const { containerId: fromColumn, index: fromIndex } = active?.data?.current?.sortable;
    const { containerId: toColumn, index: toIndex } = over?.data?.current?.sortable;

    if (isDraggingId === isOverId) {
      return;
    }
    if (fromColumn === toColumn) {
      sortTemplateField({
        templateFieldId: isDraggingId,
        fromIndex,
        toIndex,
        column: toColumn,
      });
    }
    setActiveId(null);
  };

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function renderDraggingItem() {
    if (activeId) {
      return <DraggingField id={activeId} />;
    }
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={() => {
        setActiveId(null);
      }}
    >
      {props.children}
      <DragOverlay>{renderDraggingItem()}</DragOverlay>
    </DndContext>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sortTemplateField: SettingsOperations.sortTemplateField,
      sortTemplateFieldBetweenColumns: SettingsOperations.sortTemplateFieldBetweenColumns,
      updateTemplateField: SDKReduxOperations.updateTemplateField,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DndContextContainer);
