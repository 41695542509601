import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, SectionHeader, Icon, NewInlineModal, ConfirmModal } from 'views/components/Shared/General';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { IntervalModal } from 'views/components/OperationalMaintenance';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import TemplateAssetRecurrenceListItem from './TemplateAssetRecurrenceListItem';
import ChecklistSettingsButton from './ChecklistSettingsButton';

class SettingsForAsset extends Component {
  state = {
    showIntervalModal: false,
    isCreatingRecurrenceVersion: false,
    isDeletingTemplateAsset: false,
  };

  saveRecurringInformation = intervalData => {
    this.setState({ isCreatingRecurrenceVersion: true });
    this.props
      .createForTemplateAsset(this.props.operationalMaintenanceTemplateAssetId, {
        operational_maintenance_template_recurrence_versions: [intervalData],
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.add-interval-success" />
            }
          />
        );
        this.setState({ showIntervalModal: false, isCreatingRecurrenceVersion: false });
      })
      .catch(() => {
        this.setState({ isCreatingRecurrenceVersion: false });
      });
  };

  deleteAssetTemplate = () => {
    this.setState({ isDeletingTemplateAsset: true });
    this.props
      .deleteOperationalMaintenanceTemplateAsset(this.props.operationalMaintenanceTemplateAssetId)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-asset-template-confirm-modal.delete-success" />
            }
          />
        );
        this.setState({ isDeletingTemplateAsset: false });
        this.props.onCancel();
      });
  };

  renderSettingsButton = () => {
    return (
      <ChecklistSettingsButton
        operationalMaintenanceTemplateAssetId={this.props.operationalMaintenanceTemplateAssetId}
      />
    );
  };

  renderAddIntervalButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <div className={styles['add-interval-button-container']}>
          <Button
            gray
            label="screens.operational-maintenance-template.settings.asset.add-interval"
            onClick={() => this.setState({ showIntervalModal: true })}
          />
        </div>
      );
    }
    return null;
  };

  renderRecurrences = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={28}>
          <span>
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.interval" />
          </span>
        </SectionHeader>
        {this.props.operationalMaintenanceTemplateRecurrences.map(({ id }) => (
          <TemplateAssetRecurrenceListItem
            id={id}
            key={id}
            onShowSettingsForTemplateRecurrenceId={() => this.props.onShowSettingsForTemplateRecurrenceId(id)}
          />
        ))}
        {this.renderAddIntervalButton()}
      </>
    );
  };

  renderMoreOptionsDropdown = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.dropdownOpen}
            minWidth={150}
            position="right"
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  destructive
                  onClick={() => {
                    this.setState({ dropdownOpen: false, showConfirmDeleteModal: true });
                  }}
                >
                  <FormattedMessage id="general.delete" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderAsset = () => {
    return (
      <div className={styles['asset']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <div>
              <AssetTitle id={this.props.asset.id} numberClassName={styles['asset-number']} />
            </div>
            <div>
              <TreePath assetId={this.props.asset.id} />
            </div>
          </div>
        </div>
        <div className={styles['menu-icon']}>{this.renderMoreOptionsDropdown()}</div>
      </div>
    );
  };

  renderGoBackButton = () => {
    return (
      <div className={styles['go-back-container']}>
        <Button gray label="general.go-back" onClick={this.props.onCancel} />
      </div>
    );
  };

  renderIntervalModal = () => {
    return (
      <IntervalModal
        open={this.state.showIntervalModal}
        isSaving={this.state.isCreatingRecurrenceVersion}
        onSave={this.saveRecurringInformation}
        onClose={() => this.setState({ showIntervalModal: false })}
      />
    );
  };

  renderDeleteRecurrenceVersionModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-asset-template-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-asset-template-confirm-modal.subtitle" />
        }
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingRecurrence}
        onConfirm={() => {
          this.setState({ isDeletingRecurrence: true });
          this.deleteAssetTemplate();
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderGoBackButton()}
        {this.renderAsset()}
        {this.renderSettingsButton()}
        {this.renderRecurrences()}
        {this.renderIntervalModal()}
        {this.renderDeleteRecurrenceVersionModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createForTemplateAsset: SDKReduxOperations.createForTemplateAsset,
      deleteOperationalMaintenanceTemplateAsset: SDKReduxOperations.deleteOperationalMaintenanceTemplateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateAssetId } = ownProps;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplateAssetId
  );
  const activeOperationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  return {
    activeOperationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplateAsset,
    operationalMaintenanceTemplateRecurrences: EntitySelectors.getOperationalMaintenanceTemplateRecurrences(
      state,
      operationalMaintenanceTemplateAsset.operational_maintenance_template_recurrences
    ),
    asset: EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForAsset);
