import types from './types';

const selectDeviationTab = payload => ({
  type: types.SELECT_DEVIATION_TAB,
  payload: payload,
});

const addMultipleDeviationRows = payload => ({
  type: types.ADD_MULTIPLE_DEVIATION_ROWS,
  payload: payload,
});

const clearDeviationRows = payload => ({
  type: types.CLEAR_DEVIATION_ROWS,
  payload: payload,
});

const addDeviationRow = payload => ({
  type: types.ADD_DEVIATION_ROW,
  payload: payload,
});

const removeDeviationRow = payload => ({
  type: types.REMOVE_DEVIATION_ROW,
  payload: payload,
});

const setEditable = payload => ({
  type: types.SET_IS_EDITABLE,
  payload: payload,
});

const resetState = payload => ({
  type: types.RESET_STATE,
  payload: payload,
});

const selectMenuItem = payload => ({
  type: types.SELECT_MENU_ITEM,
  payload: payload,
});

const setChecklistInstance = payload => ({
  type: types.SET_CHECKLIST_INSTANCE,
  payload: payload,
});

const setChecklistTemplate = payload => ({
  type: types.SET_CHECKLIST_TEMPLATE,
  payload: payload,
});

export default {
  clearDeviationRows,
  addMultipleDeviationRows,
  selectDeviationTab,
  addDeviationRow,
  removeDeviationRow,
  resetState,
  selectMenuItem,
  setEditable,
  setChecklistInstance,
  setChecklistTemplate,
};
