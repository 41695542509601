import * as socket from './Socket';
import SystemChannel from './Socket/SystemChannel';
import NotificationsChannel from './Socket/NotificationsChannel';
import AttachmentFoldersChannel from './Socket/AttachmentFoldersChannel';
import StocktakingChannel from './Socket/StocktakingChannel';
import SettingChannel from './Socket/SettingChannel';
import UserSettingChannel from './Socket/UserSettingChannel';
import OrganisationSettingChannel from './Socket/OrganisationSettingChannel';

import * as assetApi from './Asset/api';
import * as assetBookmarkApi from './AssetBookmark/api';
import * as assetOperatorApi from './AssetOperator/api';
import * as assetTypeApi from './AssetType/api';
import * as assetVendorApi from './AssetVendor/api';
import * as attachmentApi from './Attachment/api';
import * as attachmentFolderApi from './AttachmentFolder/api';
import * as attachmentVersionApi from './AttachmentVersion/api';
import * as assetProductionSupervisorApi from './AssetProductionSupervisor/api';
import * as authApi from './Auth/api';
import * as billingApi from './Billing/api';
import * as billingAdditionalCostsApi from './BillingAdditionalCosts/api';
import * as billingActivitiesApi from './BillingActivity/api';
import * as billingDiscountsApi from './BillingDiscounts/api';
import * as billingInvoiceApi from './BillingInvoice/api';
import * as calendarApi from './Calendar/api';
import * as checklistDeviationApi from './ChecklistDeviationAction/api';
import * as checklistTemplateApi from './ChecklistTemplate/api';
import * as checklistTemplateLinkApi from './ChecklistTemplateLink/api';
import * as checklistTemplateVersionApi from './ChecklistTemplateVersion/api';
import * as checklistTemplateRowApi from './ChecklistTemplateRow/api';
import * as checklistInstanceRowApi from './ChecklistInstanceRow/api';
import * as checklistInstanceApi from './ChecklistInstance/api';
import * as checklistInstanceOptionApi from './ChecklistInstanceOption/api';
import * as contactPersonApi from './ContactPerson/api';
import * as costApi from './Cost/api';
import * as costOtherCategoryApi from './CostOtherCategory/api';
import * as exportApi from './Export/api';
import * as groupApi from './Group/api';
import * as loginCredentialApi from './LoginCredential/api';
import * as downtimeApi from './Downtime/api';
import * as downtimeReasonApi from './DowntimeReason/api';
import * as imageApi from './Image/api';
import * as importApi from './Import/api';
import * as laborTariffApi from './LaborTariff/api';
import * as meterApi from './Meter/api';
import * as meterUnitApi from './MeterUnit/api';
import * as meterReadingApi from './MeterReading/api';
import * as notificationApi from './Notification/api';
import * as permissionProfileApi from './PermissionProfile/api';
import * as purchaseOrderApi from './PurchaseOrder/api';
import * as purchaseOrderDeliveryApi from './PurchaseOrderDelivery/api';
import * as purchaseOrderDeliveryRowApi from './PurchaseOrderDeliveryRow/api';
import * as purchaseOrderDeliveryMethodApi from './PurchaseOrderDeliveryMethod/api';
import * as purchaseOrderPaymentTermApi from './PurchaseOrderPaymentTerm/api';
import * as purchaseOrderRowApi from './PurchaseOrderRow/api';
import * as purchaseOrderShippingAddressApi from './PurchaseOrderShippingAddress/api';
import * as recurringMaintenanceApi from './RecurringMaintenance/api';
import * as requestApi from './Request/api';
import * as requestAutomationRuleApi from './RequestAutomationRule/api';
import * as requestActivityApi from './RequestActivity/api';
import * as requestTypeApi from './RequestType/api';
import * as requestSelectableAssigneeApi from './RequestSelectableAssignee/api';
import * as sessionApi from './Session/api';
import * as settingsApi from './Settings/api';
import * as organisationSettingsApi from './OrganisationSettings/api';
import * as userSettingsApi from './UserSettings/api';
import * as sparePartApi from './SparePart/api';
import * as sparePartAssetApi from './SparePartAsset/api';
import * as sparePartTypeApi from './SparePartType/api';
import * as sparePartLocationApi from './SparePartLocation/api';
import * as sparePartReservationApi from './SparePartReservation/api';
import * as sparePartStockActivityApi from './SparePartStockActivity/api';
import * as sparePartStockAdjustmentApi from './SparePartStockAdjustment/api';
import * as sparePartUnitApi from './SparePartUnit/api';
import * as sparePartVendorApi from './SparePartVendor/api';
import * as sparePartWithdrawalApi from './SparePartWithdrawal/api';
import * as stocktakingApi from './Stocktaking/api';
import * as organisationApi from './Organisation/api';
import * as stocktakingRowApi from './StocktakingRow/api';
import * as systemApi from './System/api';
import * as systemAccessApi from './SystemAccess/api';
import * as templateFieldApi from './TemplateField/api';
import * as templateFieldItemApi from './TemplateFieldItem/api';
import * as userApi from './User/api';
import * as vendorApi from './Vendor/api';
import * as workOrderApi from './WorkOrder/api';
import * as workOrderActivityApi from './WorkOrderActivity/api';
import * as workOrderPurchaseApi from './WorkOrderPurchase/api';
import * as workOrderSpentTimeApi from './WorkOrderSpentTime/api';
import * as workOrderSpentTimeCategoryApi from './WorkOrderSpentTimeCategory/api';
import * as workOrderTypeApi from './WorkOrderType/api';
import * as qrTemplateApi from './QRTemplate/api';
import * as qrTemplateColumnApi from './QRTemplateColumn/api';
import * as qrTemplateRowApi from './QRTemplateRow/api';
import * as operationalMaintenanceApi from './OperationalMaintenance/api';
import * as operationalMaintenanceInstanceApi from './OperationalMaintenanceInstance/api';
import * as operationalMaintenanceTemplateApi from './OperationalMaintenanceBreak/api';
import * as operationalMaintenanceTemplateVersionApi from './OperationalMaintenanceTemplateVersion/api';
import * as operationalMaintenanceTemplateAssetApi from './OperationalMaintenanceTemplateAsset/api';
import * as operationalMaintenanceTemplateRecurrenceApi from './OperationalMaintenanceTemplateRecurrence/api';
import * as operationalMaintenanceTemplateRecurrenceVersionApi from './OperationalMaintenanceTemplateRecurrenceVersion/api';
import * as operationalMaintenanceBreakApi from './OperationalMaintenanceTemplate/api';
import * as productionBoardActivityApi from './ProductionBoardActivity/api';

import * as assetOperatorOperations from './AssetOperator/reduxOperations';
import * as assetProductionSupervisorOperations from './AssetProductionSupervisor/reduxOperations';
import * as billingAdditionalCostsOperations from './BillingAdditionalCosts/reduxOperations';
import * as billingDiscountsOperations from './BillingDiscounts/reduxOperations';
import * as checklistInstanceOperations from './ChecklistInstance/reduxOperations';
import * as checklistInstanceOptionOperations from './ChecklistInstanceOption/reduxOperations';
import * as checklistTemplateOperations from './ChecklistTemplate/reduxOperations';
import * as checklistTemplateLinkOperations from './ChecklistTemplateLink/reduxOperations';
import * as checklistTemplateVersionOperations from './ChecklistTemplateVersion/reduxOperations';
import * as checklistTemplateRowOperations from './ChecklistTemplateRow/reduxOperations';
import * as checklistInstanceRowOperations from './ChecklistInstanceRow/reduxOperations';
import * as exportOperations from './Export/reduxOperations';
import * as sparePartOperations from './SparePart/reduxOperations';
import * as sparePartAssetOperations from './SparePartAsset/reduxOperations';
import * as settingsOperations from './Settings/reduxOperations';
import * as organisationSettingsOperations from './OrganisationSettings/reduxOperations';
import * as userSettingsOperations from './UserSettings/reduxOperations';
import * as downtimeOperations from './Downtime/reduxOperations';
import * as downtimeReasonOperations from './DowntimeReason/reduxOperations';
import * as organisationOperations from './Organisation/reduxOperations';
import * as sparePartVendorOperations from './SparePartVendor/reduxOperations';
import * as purchaseOrderDeliveryOperations from './PurchaseOrderDelivery/reduxOperations';
import * as purchaseOrderDeliveryRowOperations from './PurchaseOrderDeliveryRow/reduxOperations';
import * as purchaseOrderShippingAddressOperations from './PurchaseOrderShippingAddress/reduxOperations';
import * as sparePartTypeOperations from './SparePartType/reduxOperations';
import * as sparePartLocationOperations from './SparePartLocation/reduxOperations';
import * as sparePartUnitOperations from './SparePartUnit/reduxOperations';
import * as sparePartWithdrawalOperations from './SparePartWithdrawal/reduxOperations';
import * as sparePartStockAdjustmentOperations from './SparePartStockAdjustment/reduxOperations';
import * as systemOperations from './System/reduxOperations';
import * as systemAccessOperations from './SystemAccess/reduxOperations';
import * as attachmentOperations from './Attachment/reduxOperations';
import * as attachmentVersionOperations from './AttachmentVersion/reduxOperations';
import * as attachmentFolderOperations from './AttachmentFolder/reduxOperations';
import * as contactPersonOperations from './ContactPerson/reduxOperations';
import * as costOperations from './Cost/reduxOperations';
import * as costOtherCategoryOperations from './CostOtherCategory/reduxOperations';
import * as groupOperations from './Group/reduxOperations';
import * as userOperations from './User/reduxOperations';
import * as imageOperations from './Image/reduxOperations';
import * as assetOperations from './Asset/reduxOperations';
import * as assetBookmarkOperations from './AssetBookmark/reduxOperations';
import * as laborTariffOperations from './LaborTariff/reduxOperations';
import * as assetTypeOperations from './AssetType/reduxOperations';
import * as assetVendorOperations from './AssetVendor/reduxOperations';
import * as meterOperations from './Meter/reduxOperations';
import * as meterUnitOperations from './MeterUnit/reduxOperations';
import * as meterReadingOperations from './MeterReading/reduxOperations';
import * as recurringMaintenanceOperations from './RecurringMaintenance/reduxOperations';
import * as requestOperations from './Request/reduxOperations';
import * as requesAutomationRuleOperations from './RequestAutomationRule/reduxOperations';
import * as requestSelectableAssigneeOperations from './RequestSelectableAssignee/reduxOperations';
import * as requestTypeOperations from './RequestType/reduxOperations';
import * as templateFieldItemOperations from './TemplateFieldItem/reduxOperations';
import * as templateFieldOperations from './TemplateField/reduxOperations';
import * as vendorOperations from './Vendor/reduxOperations';
import * as workOrderOperations from './WorkOrder/reduxOperations';
import * as workOrderPurchaseOperations from './WorkOrderPurchase/reduxOperations';
import * as workOrderTypeOperations from './WorkOrderType/reduxOperations';
import * as workOrderSpentTimeOperations from './WorkOrderSpentTime/reduxOperations';
import * as workOrderSpentTimeCategoryOperations from './WorkOrderSpentTimeCategory/reduxOperations';
import * as sparePartReservationOperations from './SparePartReservation/reduxOperations';
import * as permissionProfileOperations from './PermissionProfile/reduxOperations';
import * as loginCredentialOperations from './LoginCredential/reduxOperations';
import * as purchaseOrderOperations from './PurchaseOrder/reduxOperations';
import * as purchaseOrderRowOperations from './PurchaseOrderRow/reduxOperations';
import * as purchaseOrderDeliveryMethodOperations from './PurchaseOrderDeliveryMethod/reduxOperations';
import * as purchaseOrderPaymentTermOperations from './PurchaseOrderPaymentTerm/reduxOperations';
import * as notificationOperations from './Notification/reduxOperations';
import * as billingInvoiceOperations from './BillingInvoice/reduxOperations';
import * as stocktakingOperations from './Stocktaking/reduxOperations';
import * as stocktakingRowOperations from './StocktakingRow/reduxOperations';
import * as operationalMaintenanceInstanceOperations from './OperationalMaintenanceInstance/reduxOperations';
import * as operationalMaintenanceBreakOperations from './OperationalMaintenanceBreak/reduxOperations';
import * as operationalMaintenanceTemplateOperations from './OperationalMaintenanceTemplate/reduxOperations';
import * as operationalMaintenanceTemplateVersionOperations from './OperationalMaintenanceTemplateVersion/reduxOperations';
import * as operationalMaintenanceTemplateAssetOperations from './OperationalMaintenanceTemplateAsset/reduxOperations';
import * as operationalMaintenanceTemplateRecurrenceVersionOperations from './OperationalMaintenanceTemplateRecurrenceVersion/reduxOperations';
import * as operationalMaintenanceTemplateRecurrenceOperations from './OperationalMaintenanceTemplateRecurrence/reduxOperations';
import * as qrTemplateOperations from './QRTemplate/reduxOperations';
import * as qrTemplateColumnOperations from './QRTemplateColumn/reduxOperations';
import * as qrTemplateRowOperations from './QRTemplateRow/reduxOperations';

import * as settingsTypes from './Settings/reduxTypes';
import * as organisationSettingsTypes from './OrganisationSettings/reduxTypes';
import * as userSettingsTypes from './UserSettings/reduxTypes';
import * as sparePartTypes from './SparePart/reduxTypes';
import * as sparePartAssetTypes from './SparePartAsset/reduxTypes';
import * as sparePartVendorTypes from './SparePartVendor/reduxTypes';
import * as sparePartTypeTypes from './SparePartType/reduxTypes';
import * as sparePartLocationTypes from './SparePartLocation/reduxTypes';
import * as sparePartUnitTypes from './SparePartUnit/reduxTypes';
import * as sparePartWithdrawalTypes from './SparePartWithdrawal/reduxTypes';
import * as sparePartStockAdjustmentTypes from './SparePartStockAdjustment/reduxTypes';
import * as systemTypes from './System/reduxTypes';
import * as systemAccessTypes from './SystemAccess/reduxTypes';
import * as attachmentTypes from './Attachment/reduxTypes';
import * as attachmentVersionTypes from './AttachmentVersion/reduxTypes';
import * as attachmentFolderTypes from './AttachmentFolder/reduxTypes';
import * as checklistTemplateTypes from './ChecklistTemplate/reduxTypes';
import * as checklistTemplateLinkTypes from './ChecklistTemplateLink/reduxTypes';
import * as checklistInstanceTypes from './ChecklistInstance/reduxTypes';
import * as checklistInstanceOptionTypes from './ChecklistInstanceOption/reduxTypes';
import * as checklistInstanceRowTypes from './ChecklistInstanceRow/reduxTypes';
import * as checklistTemplateVersionTypes from './ChecklistTemplateVersion/reduxTypes';
import * as checklistTemplateRowTypes from './ChecklistTemplateRow/reduxTypes';
import * as contactPersonTypes from './ContactPerson/reduxTypes';
import * as costTypes from './Cost/reduxTypes';
import * as costOtherCategoryTypes from './CostOtherCategory/reduxTypes';
import * as exportTypes from './Export/reduxTypes';
import * as groupTypes from './Group/reduxTypes';
import * as userTypes from './User/reduxTypes';
import * as billingAdditionalCostsTypes from './BillingAdditionalCosts/reduxTypes';
import * as billingDiscountsTypes from './BillingDiscounts/reduxTypes';
import * as imageTypes from './Image/reduxTypes';
import * as laborTariffTypes from './LaborTariff/reduxTypes';
import * as assetTypes from './Asset/reduxTypes';
import * as assetBookmarkTypes from './AssetBookmark/reduxTypes';
import * as assetOperatorTypes from './AssetOperator/reduxTypes';
import * as assetTypeTypes from './AssetType/reduxTypes';
import * as assetVendorTypes from './AssetVendor/reduxTypes';
import * as assetProductionSupervisorTypes from './AssetProductionSupervisor/reduxTypes';
import * as meterTypes from './Meter/reduxTypes';
import * as meterUnitTypes from './MeterUnit/reduxTypes';
import * as meterReadingTypes from './MeterReading/reduxTypes';
import * as downtimeTypes from './Downtime/reduxTypes';
import * as downtimeReasonTypes from './DowntimeReason/reduxTypes';
import * as organisationTypes from './Organisation/reduxTypes';
import * as recurringMaintenanceTypes from './RecurringMaintenance/reduxTypes';
import * as requestTypes from './Request/reduxTypes';
import * as requestAutomationRuleTypes from './RequestAutomationRule/reduxTypes';
import * as requestSelectableAssigneeTypes from './RequestSelectableAssignee/reduxTypes';
import * as requestTypeTypes from './RequestType/reduxTypes';
import * as templateFieldTypes from './TemplateField/reduxTypes';
import * as templateFieldItemTypes from './TemplateFieldItem/reduxTypes';
import * as vendorTypes from './Vendor/reduxTypes';
import * as workOrderTypes from './WorkOrder/reduxTypes';
import * as workOrderTypeTypes from './WorkOrderType/reduxTypes';
import * as workOrderPurchaseTypes from './WorkOrderPurchase/reduxTypes';
import * as workOrderSpentTimeTypes from './WorkOrderSpentTime/reduxTypes';
import * as workOrderSpentTimeCategoryTypes from './WorkOrderSpentTimeCategory/reduxTypes';
import * as sparePartReservationTypes from './SparePartReservation/reduxTypes';
import * as permissionProfileTypes from './PermissionProfile/reduxTypes';
import * as purchaseOrderTypes from './PurchaseOrder/reduxTypes';
import * as purchaseOrderDeliveryRowTypes from './PurchaseOrderDeliveryRow/reduxTypes';
import * as purchaseOrderDeliveryTypes from './PurchaseOrderDelivery/reduxTypes';
import * as purchaseOrderRowTypes from './PurchaseOrderRow/reduxTypes';
import * as purchaseOrderDeliveryMethodTypes from './PurchaseOrderDeliveryMethod/reduxTypes';
import * as purchaseOrderPaymentTermTypes from './PurchaseOrderPaymentTerm/reduxTypes';
import * as purchaseOrderShippingAddressTypes from './PurchaseOrderShippingAddress/reduxTypes';
import * as billingInvoiceTypes from './BillingInvoice/reduxTypes';
import * as stocktakingTypes from './Stocktaking/reduxTypes';
import * as stocktakingRowTypes from './StocktakingRow/reduxTypes';
import * as loginCredentialTypes from './LoginCredential/reduxTypes';
import * as operationalMaintenanceInstanceTypes from './OperationalMaintenanceInstance/reduxTypes';
import * as operationalMaintenanceTemplateTypes from './OperationalMaintenanceTemplate/reduxTypes';
import * as operationalMaintenanceTemplateVersionTypes from './OperationalMaintenanceTemplateVersion/reduxTypes';
import * as operationalMaintenanceTemplateAssetTypes from './OperationalMaintenanceTemplateAsset/reduxTypes';
import * as operationalMaintenanceBreakTypes from './OperationalMaintenanceBreak/reduxTypes';
import * as operationalMaintenanceTemplateRecurrenceVersionTypes from './OperationalMaintenanceTemplateRecurrenceVersion/reduxTypes';
import * as operationalMaintenanceTemplateRecurrenceTypes from './OperationalMaintenanceTemplateRecurrence/reduxTypes';
import * as qrTemplateTypes from './QRTemplate/reduxTypes';
import * as qrTemplateColumnTypes from './QRTemplateColumn/reduxTypes';
import * as qrTemplateRowTypes from './QRTemplateRow/reduxTypes';

import * as wsNotificationsTypes from './Socket/NotificationsChannel/reduxTypes';
import * as wsAttachmentFoldersTypes from './Socket/AttachmentFoldersChannel/reduxTypes';
import * as wsSystemTypes from './Socket/SystemChannel/reduxTypes';
import * as wsStocktakingTypes from './Socket/StocktakingChannel/reduxTypes';
import * as wsSettingsTypes from './Socket/SettingChannel/reduxTypes';
import * as wsUserSettingsTypes from './Socket/UserSettingChannel/reduxTypes';
import * as wsOrganisationSettingsTypes from './Socket/OrganisationSettingChannel/reduxTypes';

import _HelperFunctions from './utilities/HelperFunctions';

export const API = {
  ...assetApi,
  ...assetBookmarkApi,
  ...assetOperatorApi,
  ...assetVendorApi,
  ...assetTypeApi,
  ...attachmentApi,
  ...attachmentVersionApi,
  ...attachmentFolderApi,
  ...authApi,
  ...assetProductionSupervisorApi,
  ...billingApi,
  ...billingAdditionalCostsApi,
  ...billingActivitiesApi,
  ...billingDiscountsApi,
  ...billingInvoiceApi,
  ...calendarApi,
  ...checklistDeviationApi,
  ...checklistTemplateApi,
  ...checklistTemplateLinkApi,
  ...checklistInstanceApi,
  ...checklistTemplateVersionApi,
  ...checklistTemplateRowApi,
  ...checklistInstanceRowApi,
  ...checklistInstanceOptionApi,
  ...contactPersonApi,
  ...costApi,
  ...costOtherCategoryApi,
  ...exportApi,
  ...downtimeApi,
  ...downtimeReasonApi,
  ...groupApi,
  ...loginCredentialApi,
  ...imageApi,
  ...importApi,
  ...laborTariffApi,
  ...notificationApi,
  ...permissionProfileApi,
  ...purchaseOrderApi,
  ...purchaseOrderRowApi,
  ...purchaseOrderDeliveryApi,
  ...purchaseOrderDeliveryRowApi,
  ...purchaseOrderDeliveryMethodApi,
  ...purchaseOrderPaymentTermApi,
  ...purchaseOrderShippingAddressApi,
  ...productionBoardActivityApi,
  ...recurringMaintenanceApi,
  ...requestApi,
  ...organisationApi,
  ...qrTemplateApi,
  ...qrTemplateColumnApi,
  ...qrTemplateRowApi,
  ...requestAutomationRuleApi,
  ...requestSelectableAssigneeApi,
  ...requestActivityApi,
  ...requestTypeApi,
  ...settingsApi,
  ...organisationSettingsApi,
  ...userSettingsApi,
  ...sparePartReservationApi,
  ...sparePartApi,
  ...sparePartStockActivityApi,
  ...sparePartStockAdjustmentApi,
  ...sparePartAssetApi,
  ...sparePartVendorApi,
  ...sparePartTypeApi,
  ...sparePartLocationApi,
  ...sparePartUnitApi,
  ...sparePartWithdrawalApi,
  ...stocktakingApi,
  ...stocktakingRowApi,
  ...systemApi,
  ...systemAccessApi,
  ...sessionApi,
  ...templateFieldApi,
  ...templateFieldItemApi,
  ...userApi,
  ...vendorApi,
  ...workOrderApi,
  ...workOrderActivityApi,
  ...workOrderPurchaseApi,
  ...workOrderSpentTimeApi,
  ...workOrderSpentTimeCategoryApi,
  ...workOrderTypeApi,
  ...meterApi,
  ...meterUnitApi,
  ...meterReadingApi,
  ...operationalMaintenanceApi,
  ...operationalMaintenanceInstanceApi,
  ...operationalMaintenanceBreakApi,
  ...operationalMaintenanceTemplateApi,
  ...operationalMaintenanceTemplateVersionApi,
  ...operationalMaintenanceTemplateAssetApi,
  ...operationalMaintenanceTemplateRecurrenceVersionApi,
  ...operationalMaintenanceTemplateRecurrenceApi,
};

export const SDKReduxOperations = {
  ...assetBookmarkOperations,
  ...attachmentOperations,
  ...attachmentVersionOperations,
  ...assetProductionSupervisorOperations,
  ...billingAdditionalCostsOperations,
  ...billingDiscountsOperations,
  ...contactPersonOperations,
  ...checklistInstanceOperations,
  ...checklistInstanceOptionOperations,
  ...meterOperations,
  ...meterUnitOperations,
  ...meterReadingOperations,
  ...checklistTemplateOperations,
  ...checklistTemplateLinkOperations,
  ...checklistTemplateVersionOperations,
  ...checklistTemplateRowOperations,
  ...checklistInstanceRowOperations,
  ...exportOperations,
  ...groupOperations,
  ...imageOperations,
  ...assetOperations,
  ...assetOperatorOperations,
  ...attachmentFolderOperations,
  ...assetTypeOperations,
  ...assetVendorOperations,
  ...costOperations,
  ...costOtherCategoryOperations,
  ...downtimeOperations,
  ...downtimeReasonOperations,
  ...loginCredentialOperations,
  ...organisationOperations,
  ...permissionProfileOperations,
  ...purchaseOrderOperations,
  ...purchaseOrderDeliveryOperations,
  ...purchaseOrderDeliveryRowOperations,
  ...purchaseOrderRowOperations,
  ...purchaseOrderDeliveryMethodOperations,
  ...purchaseOrderPaymentTermOperations,
  ...purchaseOrderShippingAddressOperations,
  ...recurringMaintenanceOperations,
  ...requestOperations,
  ...requesAutomationRuleOperations,
  ...requestSelectableAssigneeOperations,
  ...laborTariffOperations,
  ...requestTypeOperations,
  ...settingsOperations,
  ...organisationSettingsOperations,
  ...userSettingsOperations,
  ...systemOperations,
  ...systemAccessOperations,
  ...templateFieldOperations,
  ...templateFieldItemOperations,
  ...userOperations,
  ...vendorOperations,
  ...workOrderOperations,
  ...workOrderPurchaseOperations,
  ...workOrderTypeOperations,
  ...workOrderSpentTimeOperations,
  ...workOrderSpentTimeCategoryOperations,
  ...sparePartReservationOperations,
  ...notificationOperations,
  ...billingInvoiceOperations,
  ...sparePartOperations,
  ...sparePartAssetOperations,
  ...sparePartVendorOperations,
  ...sparePartTypeOperations,
  ...sparePartLocationOperations,
  ...sparePartUnitOperations,
  ...sparePartWithdrawalOperations,
  ...sparePartStockAdjustmentOperations,
  ...stocktakingOperations,
  ...stocktakingRowOperations,
  ...operationalMaintenanceInstanceOperations,
  ...operationalMaintenanceBreakOperations,
  ...operationalMaintenanceTemplateOperations,
  ...operationalMaintenanceTemplateVersionOperations,
  ...operationalMaintenanceTemplateAssetOperations,
  ...operationalMaintenanceTemplateRecurrenceVersionOperations,
  ...operationalMaintenanceTemplateRecurrenceOperations,
  ...qrTemplateOperations,
  ...qrTemplateColumnOperations,
  ...qrTemplateRowOperations,
};

export const SDKReduxTypes = {
  ...assetBookmarkTypes,
  ...attachmentTypes,
  ...attachmentVersionTypes,
  ...attachmentFolderTypes,
  ...assetProductionSupervisorTypes,
  ...billingAdditionalCostsTypes,
  ...billingDiscountsTypes,
  ...checklistInstanceTypes,
  ...checklistInstanceOptionTypes,
  ...checklistTemplateTypes,
  ...checklistTemplateLinkTypes,
  ...checklistTemplateVersionTypes,
  ...checklistTemplateRowTypes,
  ...checklistInstanceRowTypes,
  ...contactPersonTypes,
  ...exportTypes,
  ...groupTypes,
  ...imageTypes,
  ...assetTypes,
  ...assetOperatorTypes,
  ...assetTypeTypes,
  ...assetVendorTypes,
  ...costTypes,
  ...costOtherCategoryTypes,
  ...downtimeTypes,
  ...downtimeReasonTypes,
  ...meterTypes,
  ...meterReadingTypes,
  ...meterUnitTypes,
  ...laborTariffTypes,
  ...permissionProfileTypes,
  ...purchaseOrderTypes,
  ...purchaseOrderDeliveryTypes,
  ...purchaseOrderDeliveryRowTypes,
  ...purchaseOrderRowTypes,
  ...purchaseOrderDeliveryMethodTypes,
  ...purchaseOrderShippingAddressTypes,
  ...organisationTypes,
  ...purchaseOrderPaymentTermTypes,
  ...recurringMaintenanceTypes,
  ...requestTypes,
  ...requestAutomationRuleTypes,
  ...requestSelectableAssigneeTypes,
  ...requestTypeTypes,
  ...settingsTypes,
  ...organisationSettingsTypes,
  ...userSettingsTypes,
  ...systemTypes,
  ...systemAccessTypes,
  ...templateFieldTypes,
  ...templateFieldItemTypes,
  ...userTypes,
  ...vendorTypes,
  ...workOrderTypes,
  ...workOrderPurchaseTypes,
  ...workOrderTypeTypes,
  ...workOrderSpentTimeTypes,
  ...workOrderSpentTimeCategoryTypes,
  ...sparePartReservationTypes,
  ...billingInvoiceTypes,
  ...sparePartTypes,
  ...sparePartAssetTypes,
  ...sparePartVendorTypes,
  ...sparePartTypeTypes,
  ...sparePartLocationTypes,
  ...sparePartUnitTypes,
  ...sparePartWithdrawalTypes,
  ...sparePartStockAdjustmentTypes,
  ...stocktakingTypes,
  ...stocktakingRowTypes,
  ...operationalMaintenanceInstanceTypes,
  ...operationalMaintenanceBreakTypes,
  ...operationalMaintenanceTemplateTypes,
  ...operationalMaintenanceTemplateVersionTypes,
  ...operationalMaintenanceTemplateAssetTypes,
  ...operationalMaintenanceTemplateRecurrenceVersionTypes,
  ...operationalMaintenanceTemplateRecurrenceTypes,
  ...wsNotificationsTypes,
  ...wsAttachmentFoldersTypes,
  ...wsSystemTypes,
  ...wsSettingsTypes,
  ...wsStocktakingTypes,
  ...loginCredentialTypes,
  ...wsUserSettingsTypes,
  ...wsOrganisationSettingsTypes,
  ...qrTemplateTypes,
  ...qrTemplateColumnTypes,
  ...qrTemplateRowTypes,
};

export const Socket = socket;

export const Channels = {
  SystemChannel,
  SettingChannel,
  NotificationsChannel,
  StocktakingChannel,
  AttachmentFoldersChannel,
  UserSettingChannel,
  OrganisationSettingChannel,
};

export const HelperFunctions = _HelperFunctions;
