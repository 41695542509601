import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { SparePartsSelectors, SparePartsOperations } from 'state/ducks/spareParts';
import ApplyFilter from './ApplyFilter';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from './AppliedFilters';

export const FILTER_TYPES = {
  SparePartTitle: 'title',
  ArticleNumber: 'article_number',
  SparePartDescription: 'description',
  SparePartType: 'spare_part_type_with_children_id',
  SparePartUnit: 'spare_part_unit_id',
  SparePartLocation: 'spare_part_location_with_children_id',
  Vendor: 'vendor_id',
  VendorArticleNumber: 'spare_part_vendor_number',
  ReorderPoint: 'reorder_point',
  MinQuantity: 'minimum_quantity',
  MaxQuantity: 'maximum_quantity',
  StockQuantity: 'stock_quantity',
  SparePartField: 'spare_part_field',
};

class FilterModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open) {
      this.props.selectFilterType(null);
      if (this.props.isFiltering) {
        this.props.showAppliedFilters();
      } else {
        this.props.showAvailableFilters();
      }
    }
  }

  /*
    Helper functions
  */

  close = params => {
    this.props.onClose(params);
  };

  /*
    Rener functions
  */

  renderContent = () => {
    if (this.props.selectedFilterType) {
      return <ApplyFilter />;
    }
    if (this.props.showingAppliedFilters) {
      return <AppliedFilters onClose={() => this.close()} />;
    }
    return <AvailableFilters onClose={() => this.close()} />;
  };

  renderFooter = () => {
    if (this.props.showingAppliedFilters) {
      return (
        <Button.Group>
          <Button
            primary
            onClick={() => {
              this.close(this.props.filtersAsQueryParams);
            }}
            label="screens.requests.filters.advanced.title"
          />
          <Button
            translate={false}
            label={<FormattedMessage id="general.clean" />}
            onClick={() => {
              this.props.resetFilter();
              this.close();
            }}
          />
        </Button.Group>
      );
    }
    return null;
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        width={485}
        footerComponent={this.renderFooter()}
        onClose={() => this.close(this.props.filtersAsQueryParams)}
      >
        <SideModal.Container>{this.renderContent()}</SideModal.Container>
      </SideModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: SparePartsOperations.selectFilterType,
      resetFilter: SparePartsOperations.resetFilter,
      showAppliedFilters: SparePartsOperations.showAppliedFilters,
      showAvailableFilters: SparePartsOperations.showAvailableFilters,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filtersAsQueryParams: SparePartsSelectors.getFiltersAsQueryParams(state),
    selectedFilterType: SparePartsSelectors.getSelectedFilterType(state),
    showingAppliedFilters: SparePartsSelectors.showingAppliedFilters(state),
    filters: SparePartsSelectors.getFilters(state),
    isFiltering: SparePartsSelectors.isFiltering(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
