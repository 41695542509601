import Cookies from 'universal-cookie';
import { setUserAuthToken } from 'sdk/utilities/Axios';
const cookie = new Cookies();

const openAttachment = attachment => {
  if (attachment.type === 'file') {
    window.open(`${process.env.REACT_APP_BROWSER_URL}a/${attachment.id}`, '_blank');
  } else {
    window.open(attachment.link_url, '_blank');
  }
};

const getInitialRootLocationForModal = ({ pathname, canAdministrateWorkOrders, canAdministrateRequests }) => {
  if (pathname.startsWith('/w/')) {
    if (canAdministrateWorkOrders) {
      return { pathname: '/work-orders' };
    } else {
      return { pathname: '/work-orders' };
    }
  } else if (pathname.startsWith('/a/')) {
    return { pathname: '/asset' };
  } else if (pathname.startsWith('/v/')) {
    return { pathname: '/vendors' };
  } else if (pathname.startsWith('/r/')) {
    if (canAdministrateRequests) {
      return { pathname: '/requests/all' };
    } else {
      return { pathname: '/requests/created' };
    }
  }
};

const setDocumentTitle = title => {
  document.title = `${title} | Mainter`;
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};

const updateUserAuthToken = token => {
  cookie.set('token', token, {
    path: '/',
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : 'mainter.com',
  });
  localStorage.setItem('token', token);
  setUserAuthToken(token);
};

const clearUserAuthToken = token => {
  localStorage.removeItem('token');
  cookie.remove('token', {
    path: '/',
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : 'mainter.com',
  });
};

const buildFilter = filters => {
  let params = {};
  Object.keys(filters).forEach((key, i) => {
    if (typeof filters[key] === 'object') {
      const { comparator, value } = filters[key];
      if (comparator) {
        let newValue = value == null ? '' : value.toString();
        if (newValue.length > 0) {
          params = {
            ...params,
            [key]: {
              [comparator]: newValue,
            },
          };
        }
      } else {
        let object = filters[key];
        if (Object.keys(buildFilter(object)).length > 0) {
          params = {
            ...params,
            [key]: {
              ...buildFilter(object),
            },
          };
        }
      }
    } else {
      const value = filters[key];
      let newValue = value == null ? '' : value.toString();
      if (newValue.length > 0) {
        params = {
          ...params,
          [key]: newValue,
        };
      }
    }
  });
  return params;
};

const onlyPageWasChangedFromQueryParams = (prevQueryParams, queryParams) => {
  const changedKeys = Object.keys(prevQueryParams).filter(k => prevQueryParams[k] !== queryParams[k]);
  return changedKeys.length === 1 && changedKeys.includes('page');
};

export default {
  openAttachment,
  getInitialRootLocationForModal,
  setDocumentTitle,
  getMobileOperatingSystem,
  updateUserAuthToken,
  clearUserAuthToken,
  buildFilter,
  onlyPageWasChangedFromQueryParams,
};
