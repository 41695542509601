import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import TreeContent from './TreeContent';

export default class TreeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.getPreSelectedTabFromProps(props),
      selectedAssetIds: this.props.selectedAssetIds,
    };
  }

  getPreSelectedTabFromProps = () => {
    const { hideTree, hideList } = this.props;
    if (!hideList && !hideTree) return 'list';
    return hideList ? 'tree' : 'list';
  };

  selectAsset = assetId => {
    if (this.props.multiSelect) {
      const isSelected = this.state.selectedAssetIds.some(selectedAssetId => selectedAssetId === assetId);

      if (isSelected) {
        const newSelected = this.state.selectedAssetIds.filter(
          selectedAssetId => selectedAssetId !== assetId
        );
        this.setState({ selectedAssetIds: newSelected });
      } else {
        this.setState({ selectedAssetIds: [...this.state.selectedAssetIds, assetId] });
      }
    } else {
      this.props.onSelectAsset(assetId);
      this.props.onClose();
    }
  };

  chooseMultiAssets = () => {
    this.props.onSelectAssets(this.state.selectedAssetIds);
    this.props.onClose();
  };

  renderModalHeaderButtons = () => {
    if (this.props.multiSelect) {
      return (
        <Modal.HeaderButton
          onClick={this.chooseMultiAssets}
          title={
            this.state.selectedAssetIds.length === 0 ? (
              <FormattedMessage id="general.choose" values={{ num: this.state.selectedAssetIds.length }} />
            ) : (
              <FormattedMessage
                id="components.tree-modal.choose-button"
                values={{ num: this.state.selectedAssetIds.length }}
              />
            )
          }
        />
      );
    }
    return null;
  };

  renderContent = () => (
    <TreeContent
      {...this.props}
      selectedAssetIds={this.state.selectedAssetIds}
      onSelectAsset={this.selectAsset}
    />
  );

  render() {
    return (
      <Modal isOpen width={800} onClose={this.props.onClose}>
        <Modal.Header
          onClose={this.props.onClose}
          title={<FormattedMessage id="components.tree-modal.title" />}
          buttons={this.renderModalHeaderButtons()}
        />
        {this.renderContent()}
      </Modal>
    );
  }
}

TreeModal.propTypes = {
  selectedAssetId: PropTypes.string,
  selectedAssetIds: PropTypes.array,
  onSelectAsset: PropTypes.func,
  queryParams: PropTypes.object,
  multiSelect: PropTypes.bool,
  hideTree: PropTypes.bool,
  hideList: PropTypes.bool,
  isSelected: PropTypes.func,
};

TreeModal.defaultProps = {
  queryParams: {},
  onSelectAsset: () => {},
  isSelected: () => false,
  hideTree: false,
  hideList: false,
};
