import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Field, Icon, Toggle, EmptyDataSet, Button } from 'views/components/Shared/General';
import { Section, Separator } from 'views/scenes/Settings/components';
import EditEmailModal from 'views/scenes/Settings/MyProfile/ContactInformation/EditEmailModal';
import styles from '../style.module.scss';

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkOrderNotifications: false,
      showRequestNotifications: false,
      showSparePartNotifications: false,
      showPurchaseOrderNotifications: false,
      showActivateEmailModal: false,
      isSavingEmailNotification: false,
      showEditEmailModal: false,
      showMetersNotifications: false,
      showChecklistNotifications: false,
      showOperationalMaintenancesNotifications: false,
    };
  }

  updateUserSettings = params => {
    this.setState({ isSavingEmailNotification: true });
    this.props
      .updateUserSettings(this.props.currentSystem.id, this.props.currentUser.id, params)
      .then(() => {
        this.setState({ isSavingEmailNotification: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSavingEmailNotification: false });
      });
  };

  renderWorkOrderNotification = () => {
    const { spare_parts_activated } = this.props.settings;
    if (!this.props.canCarryOutWorkOrders) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showWorkOrderNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showWorkOrderNotifications: !prevState.showWorkOrderNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.work-order.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showWorkOrderNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_work_order_daily_digest_email:
                    !this.props.editingUserSettings.notification_work_order_daily_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_daily_digest_email}
                  onChange={() => {
                    this.props.onChange({
                      notification_work_order_daily_digest_email:
                        !this.props.editingUserSettings.notification_work_order_daily_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.daily-digest.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.daily-digest.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_work_order_overdue_digest_email:
                    !this.props.editingUserSettings.notification_work_order_overdue_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_overdue_digest_email}
                  onChange={() => {
                    this.props.onChange({
                      notification_work_order_overdue_digest_email:
                        !this.props.editingUserSettings.notification_work_order_overdue_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.overdue.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.overdue.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_work_order_assigned_email:
                    !this.props.editingUserSettings.notification_work_order_assigned_email,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_assigned_email}
                  onChange={() =>
                    this.props.onChange({
                      notification_work_order_assigned_email:
                        !this.props.editingUserSettings.notification_work_order_assigned_email,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.assigned.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.assigned.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_work_order_reminder_email:
                    !this.props.editingUserSettings.notification_work_order_reminder_email,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_reminder_email}
                  onChange={() =>
                    this.props.onChange({
                      notification_work_order_reminder_email:
                        !this.props.editingUserSettings.notification_work_order_reminder_email,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.reminder.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.reminder.subtitle" />
                }
              />
            </List.Item>
            {this.props.hasProTier && spare_parts_activated ? (
              <List.Item
                clickable
                onClick={() => {
                  this.props.onChange({
                    notification_work_order_purchases_delivered_email:
                      !this.props.editingUserSettings.notification_work_order_purchases_delivered_email,
                  });
                }}
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_work_order_purchases_delivered_email}
                    onChange={() => {
                      this.props.onChange({
                        notification_work_order_purchases_delivered_email:
                          !this.props.editingUserSettings.notification_work_order_purchases_delivered_email,
                      });
                    }}
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.work-order.fully-delivered.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.work-order.fully-delivered.subtitle" />
                  }
                />
              </List.Item>
            ) : null}
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderOperationalMaintenancesNotification = () => {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    if (operationalMaintenanceIsActivated !== true) {
      return null;
    }
    if (this.props.isWorkRequester) {
      return null;
    }
    if (this.props.isOperator) {
      return null;
    }
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showOperationalMaintenancesNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showOperationalMaintenancesNotifications: !prevState.showOperationalMaintenancesNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showOperationalMaintenancesNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_operational_maintenance_daily_digest_email:
                    !this.props.editingUserSettings.notification_operational_maintenance_daily_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings.notification_operational_maintenance_daily_digest_email
                  }
                  onChange={() => {
                    this.props.onChange({
                      notification_operational_maintenance_daily_digest_email:
                        !this.props.editingUserSettings
                          .notification_operational_maintenance_daily_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.daily-digest.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.daily-digest.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_operational_maintenance_daily_missed_digest_email:
                    !this.props.editingUserSettings
                      .notification_operational_maintenance_daily_missed_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings
                      .notification_operational_maintenance_daily_missed_digest_email
                  }
                  onChange={() => {
                    this.props.onChange({
                      notification_operational_maintenance_daily_missed_digest_email:
                        !this.props.editingUserSettings
                          .notification_operational_maintenance_daily_missed_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.overdue.daily.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.overdue.daily.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_operational_maintenance_weekly_digest_email:
                    !this.props.editingUserSettings.notification_operational_maintenance_weekly_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings.notification_operational_maintenance_weekly_digest_email
                  }
                  onChange={() => {
                    this.props.onChange({
                      notification_operational_maintenance_weekly_digest_email:
                        !this.props.editingUserSettings
                          .notification_operational_maintenance_weekly_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.weekly-digest.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.weekly-digest.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_operational_maintenance_weekly_missed_digest_email:
                    !this.props.editingUserSettings
                      .notification_operational_maintenance_weekly_missed_digest_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings
                      .notification_operational_maintenance_weekly_missed_digest_email
                  }
                  onChange={() => {
                    this.props.onChange({
                      notification_operational_maintenance_weekly_missed_digest_email:
                        !this.props.editingUserSettings
                          .notification_operational_maintenance_weekly_missed_digest_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.overdue.weekly.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.operational-maintenances.overdue.weekly.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderMetersNotifications = () => {
    const { meters_activated } = this.props.settings;
    if (this.props.canViewMeters === false) return null;
    if (meters_activated !== true) return null;

    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showMetersNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showMetersNotifications: !prevState.showMetersNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.meters.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showMetersNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_meter_due_email: !this.props.editingUserSettings.notification_meter_due_email,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_meter_due_email}
                  onChange={() => {
                    this.props.onChange({
                      notification_meter_due_email:
                        !this.props.editingUserSettings.notification_meter_due_email,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.meters.daily-digest.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.meters.daily-digest.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderRequestNotification = () => {
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showRequestNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({ showRequestNotifications: !prevState.showRequestNotifications }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.request.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showRequestNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <>
            <div className={styles['notifications-list-section']}>
              <List.Header small background>
                <List.Header.Column width={20}>
                  <Icon regular size={16} type="envelope" />
                </List.Header.Column>
                <List.Header.Column flex>
                  <FormattedMessage id="screens.settings.notifications.types.request.manage.title" />
                </List.Header.Column>
              </List.Header>
              <List small>
                {this.props.canAdministrateRequests ? (
                  <List.Item
                    clickable
                    onClick={() =>
                      this.props.onChange({
                        notification_request_created_email:
                          !this.props.editingUserSettings.notification_request_created_email,
                      })
                    }
                  >
                    <List.Item.Column width={20}>
                      <Field.Checkbox
                        checked={this.props.editingUserSettings.notification_request_created_email}
                        onChange={() =>
                          this.props.onChange({
                            notification_request_created_email:
                              !this.props.editingUserSettings.notification_request_created_email,
                          })
                        }
                      />
                    </List.Item.Column>
                    <List.Item.TitleColumn
                      title={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.new.title" />
                      }
                      subtitle={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.new.subtitle" />
                      }
                    />
                  </List.Item>
                ) : null}

                {this.props.settings.production_board_activated &&
                this.props.settings.request_production_supervisor_approval_activated ? (
                  <List.Item
                    clickable
                    onClick={() =>
                      this.props.onChange({
                        notification_request_awaiting_production_supervisor_approval_email:
                          !this.props.editingUserSettings
                            .notification_request_awaiting_production_supervisor_approval_email,
                      })
                    }
                  >
                    <List.Item.Column width={20}>
                      <Field.Checkbox
                        checked={
                          this.props.editingUserSettings
                            .notification_request_awaiting_production_supervisor_approval_email
                        }
                        onChange={() =>
                          this.props.onChange({
                            notification_request_awaiting_production_supervisor_approval_email:
                              !this.props.editingUserSettings
                                .notification_request_awaiting_production_supervisor_approval_email,
                          })
                        }
                      />
                    </List.Item.Column>
                    <List.Item.TitleColumn
                      title={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.supervisor-approval.title" />
                      }
                      subtitle={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.supervisor-approval.subtitle" />
                      }
                    />
                  </List.Item>
                ) : null}
              </List>
            </div>
            <List.Header small background>
              <List.Header.Column width={20}>
                <Icon regular size={16} type="envelope" />
              </List.Header.Column>
              <List.Header.Column flex>
                <FormattedMessage id="screens.settings.notifications.types.request.created-by.title" />
              </List.Header.Column>
            </List.Header>
            <List small>
              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_archived_email:
                      !this.props.editingUserSettings.notification_request_archived_email,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_archived_email}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_archived_email:
                          !this.props.editingUserSettings.notification_request_archived_email,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.archived.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.archived.subtitle" />
                  }
                />
              </List.Item>
              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_created_email:
                      !this.props.editingUserSettings.notification_request_work_order_created_email,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_created_email}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_created_email:
                          !this.props.editingUserSettings.notification_request_work_order_created_email,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-created.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-created.subtitle" />
                  }
                />
              </List.Item>

              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_paused_email:
                      !this.props.editingUserSettings.notification_request_work_order_paused_email,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_paused_email}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_paused_email:
                          !this.props.editingUserSettings.notification_request_work_order_paused_email,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-paused.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-paused.subtitle" />
                  }
                />
              </List.Item>

              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_completed_email:
                      !this.props.editingUserSettings.notification_request_work_order_completed_email,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_completed_email}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_completed_email:
                          !this.props.editingUserSettings.notification_request_work_order_completed_email,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-completed.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-completed.subtitle" />
                  }
                />
              </List.Item>
            </List>
          </>
        </AnimateHeight>
      </>
    );
  };

  renderSparePartNotification = () => {
    if (!this.props.canEditSpareParts) return null;
    if (this.props.settings.spare_parts_activated === false) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showSparePartNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showSparePartNotifications: !prevState.showSparePartNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.spare-part.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showSparePartNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_spare_part_low_stock_quantity_email:
                    !this.props.editingUserSettings.notification_spare_part_low_stock_quantity_email,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_spare_part_low_stock_quantity_email}
                  onChange={() =>
                    this.props.onChange({
                      notification_spare_part_low_stock_quantity_email:
                        !this.props.editingUserSettings.notification_spare_part_low_stock_quantity_email,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.spare-part.low-stock.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.spare-part.low-stock.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderPurchaseOrderNotification = () => {
    if (!this.props.canEditPurchaseOrders) return null;
    if (this.props.settings.spare_parts_activated === false) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showPurchaseOrderNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showPurchaseOrderNotifications: !prevState.showPurchaseOrderNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.purchase-order.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showPurchaseOrderNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_late_purchase_order_email:
                    !this.props.editingUserSettings.notification_late_purchase_order_email,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_late_purchase_order_email}
                  onChange={() =>
                    this.props.onChange({
                      notification_late_purchase_order_email:
                        !this.props.editingUserSettings.notification_late_purchase_order_email,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.purchase-order.low-stock.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.purchase-order.low-stock.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderChecklistNotification = () => {
    if (!this.props.canAdministrateWorkOrders && !this.props.isProductionSupervisor) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showChecklistNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showChecklistNotifications: !prevState.showChecklistNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.checklist.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showChecklistNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_checklist_instance_deviation_assignee_email:
                    !this.props.editingUserSettings.notification_checklist_instance_deviation_assignee_email,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings.notification_checklist_instance_deviation_assignee_email
                  }
                  onChange={() =>
                    this.props.onChange({
                      notification_checklist_instance_deviation_assignee_email:
                        !this.props.editingUserSettings
                          .notification_checklist_instance_deviation_assignee_email,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.checklist.deviation-assigned.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.checklist.deviation-assigned.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderContent = () => {
    if (this.props.currentUser.email) {
      return (
        <>
          <Section
            title={<FormattedMessage id="screens.settings.notifications.email.activate-title" />}
            subtitle={
              <FormattedMessage
                id="screens.settings.notifications.email.activate-subtitle"
                values={{ email: this.props.currentUser.email }}
              />
            }
            rightComponent={
              <Toggle
                checked={this.props.userSettings.notification_email_activated}
                loading={this.state.isSavingEmailNotification}
                onClick={() => {
                  this.updateUserSettings({
                    notification_email_activated: !this.props.userSettings.notification_email_activated,
                  });
                }}
              />
            }
          />
          <AnimateHeight
            duration={250}
            height={this.props.userSettings.notification_email_activated ? 'auto' : 0}
          >
            <>
              <Separator />
              <Section
                title={<FormattedMessage id="screens.settings.notifications.email.notifications-title" />}
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.email.notifications-subtitle" />
                }
              />
              <div className={styles['notifications-container']}>
                {this.renderWorkOrderNotification()}
                {this.renderMetersNotifications()}
                {this.renderOperationalMaintenancesNotification()}
                {this.renderRequestNotification()}
                {this.renderChecklistNotification()}
                {this.renderSparePartNotification()}
                {this.renderPurchaseOrderNotification()}
              </div>
            </>
          </AnimateHeight>
        </>
      );
    }
    return (
      <EmptyDataSet
        title={<FormattedMessage id="screens.settings.notifications.email.no-email-empty-data-set.title" />}
        subtitle={
          <FormattedMessage id="screens.settings.notifications.email.no-email-empty-data-set.subtitle" />
        }
        button={
          <Button
            primary
            onClick={() => this.setState({ showEditEmailModal: true })}
            label="screens.settings.notifications.email.no-email-empty-data-set.button"
          />
        }
        modal
      />
    );
  };

  render() {
    const { email, email_is_verified } = this.props.currentUser;
    return (
      <>
        {email && email_is_verified === false ? (
          <div className={styles['verify-email-warning']}>
            <FormattedMessage id="screens.settings.notifications.email.verify-email-warning" />
          </div>
        ) : null}
        {this.renderContent()}
        <EditEmailModal
          open={this.state.showEditEmailModal}
          onClose={() => this.setState({ showEditEmailModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettings: SDKReduxOperations.updateUserSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    canViewMeters: AuthSelectors.canViewMeters(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    userSettings: AuthSelectors.getCurrentUserSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    isOperator: AuthSelectors.isOperator(state),
    isWorkRequester: AuthSelectors.isWorkRequester(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Email));
