import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { VendorsSelectors, VendorsOperations } from 'state/ducks/vendors';
import { FILTER_TYPES } from 'views/scenes/Vendors/VendorList/FilterModal';
import { EntitySelectors } from 'sdk/State/entities';

class DeliveryMethod extends Component {
  renderSelectedValue = () => {
    const { comparator } = this.props.filter;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }

    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {this.props.deliveryMethod?.title}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.vendor.delivery-method" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.default_purchase_order_delivery_method_id,
            data: {
              [FILTER_TYPES.default_purchase_order_delivery_method_id]: {
                comparator: SDKHelperFunctions.FILTER_COMPARABLES.Any,
                value: [],
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: VendorsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const { value } = VendorsSelectors.getFiltersForKey(
    state,
    FILTER_TYPES.default_purchase_order_delivery_method_id
  );

  return {
    filter: VendorsSelectors.getFiltersForKey(state, FILTER_TYPES.default_purchase_order_delivery_method_id),
    deliveryMethod: EntitySelectors.getPurchaseOrderDeliveryMethod(state, value),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryMethod);
