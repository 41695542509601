import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineModal, Button, Icon } from 'views/components/Shared/General';

export default class OptionsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState(prevState => ({ isOpen: !prevState.isOpen }))}
        >
          <Button icon={<Icon regular type="plus" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              icon="upload"
              iconThickness="regular"
              title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onCreateNew('file');
              }}
            />
            <InlineModal.ListItem
              icon="link"
              iconThickness="regular"
              title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onCreateNew('link');
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}
