import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'views/components/Shared/General';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';

class DeviationsMenuItem extends Component {
  render() {
    return (
      <Menu.Item
        red
        title={<FormattedMessage id="components.checklist-instance-modal.deviations.title" />}
        onClick={() => this.props.selectMenuItem('deviations')}
        selected={this.props.selectedMenuItem === 'deviations'}
        number={this.props.checklistInstance.deviation_require_action_count}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: ChecklistModalOperations.selectMenuItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistInstance: ChecklistModalSelectors.getChecklistInstance(state),
    selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviationsMenuItem);
