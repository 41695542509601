import React, { Component } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AddSparePartModal } from 'views/components/WorkOrder';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SectionHeader } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import {
  SparePartWithdrawalModal,
  DeleteSparePartWithdrawalModal,
} from 'views/components/SparePartWithdrawal';
import { SDKReduxOperations } from 'sdk';
import SparePartWithdrawalListItem from './SparePartWithdrawalListItem';
import styles from './style.module.scss';

class UsedSpareParts extends Component {
  state = {
    showAddSparePartModal: false,
    showSparePartWithdrawalModal: false,
    showSparePartWithdrawalModalForId: null,
    showDeleteSparePartWithdrawalModal: false,
    showDeleteSparePartWithdrawalModalForId: null,
    isCreatingWithdrawals: false,
  };

  createSparePartWithdrawalsForOperationalMaintenance = async addedSpareParts => {
    if (this.state.isCreatingWithdrawals) return;
    this.setState({ isCreatingWithdrawals: true });
    let requestsToWaitFor = addedSpareParts.map(({ spare_part_id, quantity }) =>
      this.props.createSparePartWithdrawalForOperationalMaintenance(
        this.props.operationalMaintenanceInstance.id,
        {
          spare_part_id,
          quantity,
        }
      )
    );
    try {
      const results = await Promise.all(requestsToWaitFor);

      const createdSparePartWithdrawals = results.map(({ data }) => data);

      const toastMessage =
        createdSparePartWithdrawals.length === 1 ? (
          <FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />
        ) : (
          <FormattedMessage id="components.add-spare-parts-modal.add-spare-parts-success" />
        );

      toast(<ToastMessage success text={toastMessage} />);

      this.setState({ showAddSparePartModal: false, isCreatingWithdrawals: false });
    } catch (error) {
      this.setState({ isCreatingWithdrawals: false });
    }
  };

  renderAddSparePartModal = () => (
    <AddSparePartModal
      open={this.state.showAddSparePartModal}
      assetId={this.props.asset.id}
      isSaving={this.state.isCreatingWithdrawals}
      sparePartReservations={this.props.sparePartReservations}
      onAddSpareParts={addedSpareParts =>
        this.createSparePartWithdrawalsForOperationalMaintenance(addedSpareParts)
      }
      onClose={() => this.setState({ showAddSparePartModal: false })}
    />
  );

  renderSparePartWithdrawalModal = () => {
    return (
      <SparePartWithdrawalModal
        open={this.state.showSparePartWithdrawalModal}
        id={this.state.showSparePartWithdrawalModalForId}
        onClose={() => this.setState({ showSparePartWithdrawalModal: false })}
        onDelete={id => {
          this.setState({ showSparePartWithdrawalModal: false });
          setTimeout(() => {
            this.setState({
              showDeleteSparePartWithdrawalModal: true,
              showDeleteSparePartWithdrawalModalForId: id,
            });
          }, 250);
        }}
      />
    );
  };

  renderDeleteSparePartWithdrawalModal = () => {
    return (
      <DeleteSparePartWithdrawalModal
        open={this.state.showDeleteSparePartWithdrawalModal}
        id={this.state.showDeleteSparePartWithdrawalModalForId}
        onClose={() => this.setState({ showDeleteSparePartWithdrawalModal: false })}
        onDelete={() => {
          this.setState({ showDeleteSparePartWithdrawalModal: false });
        }}
      />
    );
  };

  renderContent = () => {
    const { canCarryOutOperationalMaintenances, canCreateWithdrawals } = this.props;
    if (canCarryOutOperationalMaintenances && canCreateWithdrawals) {
      return (
        <>
          <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
            <FormattedMessage id="screens.operational-maintenance.used-spare-parts.title" />
          </SectionHeader>
          <div className={styles['cards']}>
            {this.props.sparePartWithdrawals.map(({ id }) => (
              <SparePartWithdrawalListItem
                id={id}
                key={id}
                onClick={() =>
                  this.setState({ showSparePartWithdrawalModal: true, showSparePartWithdrawalModalForId: id })
                }
              />
            ))}
            <div
              className={[styles['card']]}
              onClick={() => this.setState({ showAddSparePartModal: true })}
              padding="18px 30px"
            >
              <span className={styles['add-withdrawal']}>
                <FormattedMessage id="screens.operational-maintenance.used-spare-parts.add-button" />
              </span>
            </div>
          </div>
        </>
      );
    }
    if (this.props.sparePartWithdrawals.length > 0) {
      return (
        <>
          <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
            <FormattedMessage id="screens.operational-maintenance.used-spare-parts.title" />
          </SectionHeader>
          <div className={styles['cards']}>
            {this.props.sparePartWithdrawals.map(({ id }) => (
              <SparePartWithdrawalListItem
                id={id}
                onClick={() =>
                  this.setState({ showSparePartWithdrawalModal: true, showSparePartWithdrawalModalForId: id })
                }
              />
            ))}
          </div>
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderAddSparePartModal()}
        {this.renderSparePartWithdrawalModal()}
        {this.renderDeleteSparePartWithdrawalModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartWithdrawalForOperationalMaintenance:
        SDKReduxOperations.createSparePartWithdrawalForOperationalMaintenance,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const { spare_part_withdrawals, operational_maintenance_template_version_id } =
    operationalMaintenanceInstance;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operational_maintenance_template_version_id
  );

  return {
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawals),
    sparePartReservations: EntitySelectors.getSparePartReservations(
      state,
      operationalMaintenanceTemplateVersion.spare_part_reservations
    ),
    asset: EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id),
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsedSpareParts);
