import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, API } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Field, List, ConfirmDeleteInlineModal, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { normalizePurchaseOrder } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class PurchaseOrderDeliveryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      value: new Decimal(props.purchaseOrderDeliveryRow.quantity).toString(),
      isSaving: false,
      isDeleting: false,
    };
  }

  delete = () => {
    this.setState({ isDeleting: true });
    this.props.deletePurchaseOrderDeliveryRow(this.props.purchaseOrderDeliveryRow.id).then(() => {
      API.fetchPurchaseOrder(this.props.purchaseOrderDeliveryRow.purchase_order_id).then(
        ({ data: purchaseOrder }) => {
          const { entities } = normalizePurchaseOrder(purchaseOrder);
          this.props.updateEntities(entities);

          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="components.edit-delivery-modal.delete-article-success" />}
            />
          );
        }
      );
    });
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updatePurchaseOrderDeliveryRow(this.props.purchaseOrderDeliveryRow.id, { quantity: this.state.value })
      .then(() => {
        API.fetchPurchaseOrder(this.props.purchaseOrderRow.purchase_order_id).then(
          ({ data: purchaseOrder }) => {
            const { entities } = normalizePurchaseOrder(purchaseOrder);
            this.props.updateEntities(entities);
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
            this.setState({ isSaving: false, editing: false });
          }
        );
      });
  };

  renderEditRowIcon = () => {
    if (this.props.purchaseOrderRow) {
      return (
        <Button
          type="icon"
          icon={<Icon regular type="pen" />}
          onClick={() => this.setState({ editing: true })}
        />
      );
    }
    return null;
  };

  renderUnitLabel = () => {
    if (this.props.purchaseOrderRow && this.props.purchaseOrderRow.package_quantity) {
      return this.props.intl.formatMessage({ id: 'resources.spare-part-vendor.package-unit' });
    } else if (this.props.sparePartUnit != null) {
      return this.props.sparePartUnit.abbreviation;
    }
    return null;
  }

  renderQuantity = () => {
    if (this.props.purchaseOrderRow && this.props.purchaseOrderRow.package_quantity) {
      return (
        <SparePartUnitWithValue
          isPackage
          value={this.props.purchaseOrderDeliveryRow.quantity}
        />
      )
    }
    else {
      return (
        <SparePartUnitWithValue
          value={this.props.purchaseOrderDeliveryRow.quantity}
          sparePartUnit={this.props.sparePartUnit}
        />
      )
    }
  }

  renderRightContainer = () => {
    if (this.state.editing) {
      return (
        <React.Fragment>
          <div className={styles['field-container']}>
            <Field.Decimal
              autoFocus
              alignRight
              padding={this.props.sparePartUnit ? '0px 5px' : '0px 16px'}
              rightLabel={this.renderUnitLabel()}
              value={this.state.value}
              onChange={value => {
                this.setState({ value });
              }}
              onBlur={value => {
                this.setState({ value });
              }}
            />
          </div>
          <Button
            primary
            label="general.save"
            loading={this.state.isSaving}
            disabled={!this.state.value}
            onClick={this.update}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className={styles['text-container']}>
          <Field
            singleRow
            view
            alignRight
            label={<FormattedMessage id="components.edit-delivery-modal.delivered-quantity" />}
          >
            {this.renderQuantity()}
          </Field>
        </div>
        <div className={styles['buttons']}>
          {this.renderEditRowIcon()}
          <ConfirmDeleteInlineModal
            position="right"
            trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
            title={<FormattedMessage id="components.edit-delivery-modal.delete-article-confirm.title" />}
            subtitle={
              <FormattedMessage id="components.edit-delivery-modal.delete-article-confirm.subtitle" />
            }
            buttonLabel="general.delete"
            onDelete={this.delete}
            loading={this.state.isDeleting}
          />
        </div>
      </React.Fragment>
    );
  };

  renderTitle = () => {
    if (this.props.purchaseOrderRow) {
      if (this.props.purchaseOrderRow.number) {
        return `#${this.props.purchaseOrderRow.number} - ${this.props.purchaseOrderRow.title}`;
      }
      return this.props.purchaseOrderRow.title;
    }
    return <FormattedMessage id="screens.purchase-order.deliveries.removed-purchase-order-row" />;
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow && this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <List.Item>
        <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderPackageQuantity()} />
        <List.Item.Column alignRight>
          <div className={styles['add-container']}>{this.renderRightContainer()}</div>
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deletePurchaseOrderDeliveryRow: SDKReduxOperations.deletePurchaseOrderDeliveryRow,
      updatePurchaseOrderDeliveryRow: SDKReduxOperations.updatePurchaseOrderDeliveryRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderRow = EntitySelectors.getPurchaseOrderRow(
    state,
    ownProps.purchaseOrderDeliveryRow.purchase_order_row_id
  );
  let sparePartUnit = null;
  if (purchaseOrderRow) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, purchaseOrderRow.spare_part_unit_id);
  }
  return {
    purchaseOrderRow,
    sparePartUnit,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDeliveryRow));
