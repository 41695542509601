import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SDKReduxOperations, API } from 'sdk';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import AnimateHeight from 'react-animate-height';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Field, Button, FieldErrorWrapper } from 'views/components/Shared/General';
import styles from './style.module.scss';

class UserPinModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    createWithPassword: false,
    password: '',
    confirmPassword: '',
    showErrorForEmptyPassword: false,
    showErrorForEmptyEmail: false,
    showPasswordsNotMatchingError: false,
  });
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  resetPasswordWithInvite = () => {
    if (!this.props.user.email) {
      this.setState({ showErrorForEmptyEmail: true });
      return;
    }
    this.setState({ isSaving: true });
    API.requestResetPasswordForUser(this.props.user.id, this.props.user.email)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.action-modal.toasts.invite-sent" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  resetPassword = () => {
    if (this.state.password === '') {
      this.setState({ showErrorForEmptyPassword: true });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ showPasswordsNotMatchingError: true });
      return;
    }
    this.setState({ isSaving: true });
    const data = {
      password: this.state.password,
    };
    API.updateLoginCredential(this.props.user.login_credential, data)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <>
        {this.state.showErrorForEmptyEmail ? (
          <div className={styles['info-box']}>
            <p className={styles['info-text']}>
              <FormattedMessage id="screens.users.action-modal.empty-email" />
            </p>
          </div>
        ) : null}
        <Field.Radio.Group>
          <Field.Radio
            label={
              <>
                <FormattedMessage id="screens.users.action-modal.reset-by-mail" />
                <span className={styles['reset-email']}> - {this.props.user.email}</span>
              </>
            }
            checked={!this.state.createWithPassword}
            onChange={() => this.setState({ createWithPassword: false })}
          />
          <Field.Radio
            label={<FormattedMessage id="screens.users.action-modal.with-password" />}
            checked={this.state.createWithPassword}
            onChange={() => this.setState({ createWithPassword: true })}
          />
        </Field.Radio.Group>
        <AnimateHeight
          duration={150}
          height={this.state.createWithPassword ? 'auto' : 0}
          onAnimationEnd={() => this.passwordRef.focus()}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className={styles['password-fields']}>
                  <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
                    <Field.Text
                      ref={ref => (this.passwordRef = ref)}
                      type="password"
                      autocomplete="new-password"
                      error={this.state.showErrorForEmptyPassword}
                      autoFocus
                      value={this.state.password}
                      onChange={password => this.setState({ password, showErrorForEmptyPassword: false })}
                    />
                  </Field>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="screens.users.action-modal.confirm-password" />}>
                  <FieldErrorWrapper
                    show={this.state.showPasswordsNotMatchingError}
                    errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
                  >
                    <Field.Text
                      type="password"
                      autocomplete="new-password"
                      error={this.state.showPasswordsNotMatchingError || this.state.showErrorForEmptyPassword}
                      value={this.state.confirmPassword}
                      onChange={confirmPassword => {
                        if (this.state.showPasswordsNotMatchingError) {
                          this.setState({ showPasswordsNotMatchingError: false });
                        }
                        this.setState({ confirmPassword });
                      }}
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </AnimateHeight>
      </>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.reset-password" />}
          subtitle={
            <FormattedMessage
              id="screens.users.action-modal.change-subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  if (this.state.createWithPassword) this.resetPassword();
                  else this.resetPasswordWithInvite();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateLoginCredential: SDKReduxOperations.updateLoginCredential,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPinModal);
