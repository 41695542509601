import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { VendorsSelectors, VendorsOperations } from 'state/ducks/vendors';
import { FILTER_TYPES } from 'views/scenes/Vendors/VendorList/FilterModal';

class Country extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {value}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  render() {
    const { value, comparator } = this.props.filter;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Text
          title={<FormattedMessage id="resources.vendor.country" />}
          comparator={comparator}
          hasValue={value}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.country,
              data: {
                [FILTER_TYPES.country]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Search,
                  value: '',
                },
              },
            })
          }
        >
          {this.renderSelectedValue()}
        </SideModal.Container.Filter.AppliedFilters.Filter.Text>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: VendorsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: VendorsSelectors.getFiltersForKey(state, FILTER_TYPES.country),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Country);
