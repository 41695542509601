import Vendor from './Vendor';
import Late from './Late';
import Status from './Status';
import SparePartLocation from './SparePartLocation';

export default {
  Vendor,
  Late,
  Status,
  SparePartLocation,
};
