import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { bindActionCreators } from 'redux';
import { Decimal } from 'decimal.js';
import { API } from 'sdk';
import { normalizePurchaseOrderDeliveryRow } from 'sdk/Schemas';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, List, InlineModal } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import EarlierDeliveryRow from './EarlierDeliveryRow';
import styles from './style.module.scss';

class PurchaseOrderRowListItem extends Component {
  constructor(props) {
    super(props);
    const { quantity, delivered_quantity } = props.purchaseOrderRow;
    let value = new Decimal(quantity).minus(new Decimal(delivered_quantity));
    if (value.lessThan(new Decimal(0))) {
      value = '0';
    } else {
      value = value.toString();
    }
    this.state = {
      value,
      selected: new Decimal(delivered_quantity).lessThan(new Decimal(quantity)),
      showEearlierDeliveriesInlineModal: false,
      earlierDeliveries: [],
      isFetchingDeliveries: false,
    };
  }

  fetchEarlierDeliveriesForSamePurchaseOrderRow = () => {
    if (this.state.showEearlierDeliveriesInlineModal) {
      this.setState({ showEearlierDeliveriesInlineModal: false });
      return;
    }
    this.setState({ showEearlierDeliveriesInlineModal: true, isFetchingDeliveries: true });
    API.listPurchaseOrderDeliveryRowsForPurchaseRow(this.props.purchaseOrderRow.id).then(
      ({ data: earlierDeliveries }) => {
        const { entities, result } = normalizePurchaseOrderDeliveryRow(earlierDeliveries);
        this.props.updateEntities(entities);
        this.setState({
          isFetchingDeliveries: false,
          earlierDeliveries: result.map(id => entities.purchaseOrderDeliveryRowById[id]),
        });
      }
    );
  };

  renderEarlierDeliveriesInlineModal = () => {
    const { delivered_quantity } = this.props.purchaseOrderRow;
    return (
      <>
        <p
          className={styles['show-earlier-deliveries']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={this.fetchEarlierDeliveriesForSamePurchaseOrderRow}
        >
          <SparePartUnitWithValue
            value={new Decimal(delivered_quantity).toString()}
            isPackage={this.props.purchaseOrderRow.package_quantity != null}
            sparePartUnit={
              this.props.purchaseOrderRow.package_quantity == null ? this.props.sparePartUnit : null
            }
          />
        </p>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showEearlierDeliveriesInlineModal}
          onClose={() => this.setState({ showEearlierDeliveriesInlineModal: false })}
          position="right"
        >
          <InlineModal.Header
            width={350}
            title={<FormattedMessage id="components.create-delivery-modal.earlier-deliveries-title" />}
            onClose={() => {
              this.setState({ showEearlierDeliveriesInlineModal: false });
            }}
          />
          <InlineModal.Body width={350}>
            {this.state.isFetchingDeliveries ? (
              <Loader tiny />
            ) : (
              this.state.earlierDeliveries.map(purchaseOrderDeliveryRow => (
                <EarlierDeliveryRow
                  isPackage={this.props.purchaseOrderRow.package_quantity != null}
                  sparePartUnit={
                    this.props.purchaseOrderRow.package_quantity == null ? this.props.sparePartUnit : null
                  }
                  purchaseOrderDeliveryRow={purchaseOrderDeliveryRow}
                />
              ))
            )}
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderTextInfo = () => {
    const { quantity, delivered_quantity } = this.props.purchaseOrderRow;
    const isFullyDelivered = new Decimal(delivered_quantity).greaterThanOrEqualTo(new Decimal(quantity));
    if (isFullyDelivered) {
      return <FormattedMessage id="components.create-delivery-modal.fully-delivered" />;
    }
    if (new Decimal(delivered_quantity).greaterThan(new Decimal(0))) {
      return (
        <div className={styles['text-info']}>
          <Field
            singleRow
            alignRight
            view
            fontSize={12}
            label={<FormattedMessage id="components.create-delivery-modal.ordered-quantity" />}
          >
            <SparePartUnitWithValue
              value={quantity}
              isPackage={this.props.purchaseOrderRow.package_quantity != null}
              sparePartUnit={
                this.props.purchaseOrderRow.package_quantity == null ? this.props.sparePartUnit : null
              }
            />
          </Field>
          <Field
            singleRow
            alignRight
            fontSize={12}
            view
            label={<FormattedMessage id="components.create-delivery-modal.already-delivered-quantity" />}
          >
            {this.renderEarlierDeliveriesInlineModal()}
          </Field>
        </div>
      );
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.purchaseOrderRow.number) {
      return `#${this.props.purchaseOrderRow.number} - ${this.props.purchaseOrderRow.title}`;
    }
    return this.props.purchaseOrderRow.title;
  };

  renderFieldUnit = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return this.props.intl.formatMessage({ id: 'resources.spare-part-vendor.package-unit' });
    } else if (this.props.sparePartUnit != null) {
      return this.props.sparePartUnit.abbreviation;
    }
    return null;
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['overlay-container']}>
        {this.state.selected ? null : <div className={styles['overlay']} />}
        <List.Item>
          <div className={styles['checkbox-wrapper']}>
            <List.Item.Column width={30}>
              <Field.Checkbox
                checked={this.state.selected}
                onChange={value =>
                  this.setState({ selected: value }, () => {
                    this.props.onSelect({ selected: this.state.selected, quantity: this.state.value });
                  })
                }
              />
            </List.Item.Column>
          </div>
          <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderPackageQuantity()} />
          <List.Item.Column alignRight>
            <div className={styles['add-container']}>
              <div className={styles['text-container']}>{this.renderTextInfo()}</div>
              <div className={styles['field-container']}>
                <Field.Decimal
                  alignRight
                  padding={this.props.sparePartUnit ? '0px 5px' : '0px 16px'}
                  rightLabel={this.renderFieldUnit()}
                  value={this.state.value}
                  onChange={value => {
                    this.setState({ value });
                  }}
                  onBlur={value => this.props.onBlur(value)}
                />
              </div>
            </div>
          </List.Item.Column>
        </List.Item>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderRowListItem));
