import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AnimateHeight from 'react-animate-height';
import { normalizeSparePartType } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { SparePartTypePath } from 'views/components/SparePart';
import { Button, Menu, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartTypes extends Component {
  state = {
    isFetching: false,
    showSearchField: false,
    searchValue: '',
    sparePartTypes: [],
    noSparePartTypesHasChildren: false,
  };

  componentDidMount() {
    this.fetchSparePartTypes();
  }

  fetchSparePartTypes = () => {
    this.setState({ isFetching: true });
    API.listSparePartTypes(this.props.currentSystem.id, { no_pagination: true }).then(res => {
      const { data } = res;
      const { entities, result } = normalizeSparePartType(data);
      this.props.updateEntities(entities);
      const sparePartTypes = result.map(id => entities.sparePartTypeById[id]);
      this.setState({
        noSparePartTypesHasChildren: sparePartTypes.find(({ parent_id }) => parent_id != null) == null,
        isFetching: false,
        sparePartTypes,
      });
    });
  };

  renderListItemChildren = (spt, depth) => {
    return this.state.sparePartTypes
      .filter(({ parent_id }) => parent_id === spt.id)
      .map(sparePartType => {
        return this.renderTreeListItem(sparePartType, depth);
      });
  };

  sparePartTypeHasChildren = spt => {
    return this.state.sparePartTypes.find(({ parent_id }) => parent_id === spt.id) != null;
  };

  renderTreeListItem = (sparePartType, depth = 1) => {
    return (
      <Menu.Item
        treeStructure={this.state.noSparePartTypesHasChildren === false}
        title={sparePartType.title}
        expandable={this.sparePartTypeHasChildren(sparePartType)}
        expandableComponent={this.renderListItemChildren(sparePartType, depth + 1)}
        indent={depth}
        onClick={() => {
          this.props.onSelectSparePartType(sparePartType.id);
        }}
        selected={this.props.selectedSparePartTypeId === sparePartType.id}
      />
    );
  };

  renderSearchedListItem = sparePartType => {
    return (
      <Menu.Item
        title={sparePartType.title}
        subtitle={<SparePartTypePath sparePartTypeId={sparePartType.id} />}
        onClick={() => {
          this.props.onSelectSparePartType(sparePartType.id);
        }}
        selected={this.props.selectedSparePartTypeId === sparePartType.id}
      />
    );
  };

  renderItems = () => {
    if (this.state.isFetching) {
      return (
        <>
          <Menu.Item loading />
          <Menu.Item loading />
        </>
      );
    }
    if (this.state.sparePartTypes.length === 0) {
      return (
        <div className={styles['search-text']}>
          <FormattedMessage id="screens.spare-parts.left-panel.spare-part-type.empty-data-set.title" />
        </div>
      );
    }
    if (this.state.searchValue.length > 0) {
      const filteredSparePartTypes = this.state.sparePartTypes.filter(
        wot =>
          this.state.searchValue === '' ||
          wot.title.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
      if (filteredSparePartTypes.length === 0) {
        return (
          <div className={styles['search-text']}>
            <FormattedMessage id="general.empty-data-set-search.title" />
          </div>
        );
      }
      return filteredSparePartTypes.map(sparePartType => {
        return this.renderSearchedListItem(sparePartType);
      });
    }
    const rootSparePartTypes = this.state.sparePartTypes.filter(spt => spt.parent_id == null);
    return rootSparePartTypes.map(sparePartType => this.renderTreeListItem(sparePartType));
  };

  renderSearchField = () => {
    let classNames = [styles['search-container']];
    if (this.state.isFocused) classNames = [...classNames, styles['focused']];
    if (this.state.searchValue !== '') classNames = [...classNames, styles['has-value']];

    return (
      <div className={styles['border-positioner']}>
        <AnimateHeight duration={250} height={this.state.showSearchField ? 'auto' : 0}>
          <div className={classNames.join(' ')}>
            <div className={styles['input-container']}>
              <div className={styles['input-logo']}>
                <Icon regular type="search" />
              </div>
              <input
                ref={ref => (this.inputRef = ref)}
                className={styles['input']}
                placeholder={this.props.intl.formatMessage({
                  id: 'general.search-placeholder',
                })}
                autoComplete="off"
                onFocus={() => this.setState({ isFocused: true })}
                onBlur={() => {
                  this.setState({ isFocused: false });
                }}
                value={this.state.searchValue}
                name="search"
                onChange={e => this.setState({ searchValue: e.target.value })}
              />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles['search-header']}>
          <p className={styles['search-title']}>
            <FormattedMessage id="components.select-spare-part-modal.menu.type-title" />
          </p>
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={() => {
              this.setState({ showSearchField: !this.state.showSearchField, searchValue: '' }, () => {
                if (this.state.showSearchField) this.inputRef.focus();
              });
            }}
          />
        </div>
        {this.renderSearchField()}
        <div className={styles['item-container']}>{this.renderItems()}</div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartTypes));
