import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

export default function Field(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
    data: {
      row_type: props.rowType,
      template_field_id: props.templateFieldId,
      title: props.title,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}
