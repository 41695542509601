import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, EmptyDataSet, Button, Tooltip, Icon } from 'views/components/Shared/General';
import ContactPersonSmall from 'assets/images/EmptyDataSet/ContactPersonSmall.png';
import styles from './style.module.scss';

class ContactPersonField extends Component {
  renderEditingField = () => (
    <Field.Dropdown
      clearable
      value={this.props.contactPerson ? this.props.contactPerson.name : ''}
      onClear={() => this.props.onChange(null)}
    >
      {this.renderContactPersons()}
    </Field.Dropdown>
  );

  renderContactPersons = () => {
    if (this.props.contactPersons.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.purchase-order.info.vendor.contact-persons-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.purchase-order.info.vendor.contact-persons-empty-data-set.subtitle" />
            }
            button={
              <Button
                primary
                label="screens.purchase-order.info.vendor.contact-persons-empty-data-set.button"
                small
                onClick={() => this.props.history.push(`/vendors/${this.props.vendorId}`)}
              />
            }
            image={ContactPersonSmall}
            tiny
          />
        </div>
      );
    }
    return this.props.contactPersons.map(contactPerson => (
      <Field.Dropdown.Item
        key={contactPerson.id}
        selected={this.props.value === contactPerson.id}
        onClick={() => this.props.onChange(contactPerson.id)}
      >
        {contactPerson.name}
      </Field.Dropdown.Item>
    ));
  };

  renderContactPerson = () => {
    return (
      <div className={styles['contact-person']}>
        <p>{this.props.contactPerson.name}</p>
        <div className={styles['icons']}>
          <div className={styles['info-container']}>
            {this.props.contactPerson.phone ? (
              <Tooltip trigger={<Icon regular type="phone" />} label={this.props.contactPerson.phone} />
            ) : (
              <Icon regular type="phone" disabled />
            )}
          </div>
          <div className={styles['info-container']}>
            {this.props.contactPerson.email ? (
              <Tooltip trigger={<Icon regular type="envelope" />} label={this.props.contactPerson.email} />
            ) : (
              <Icon regular type="envelope" disabled />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.edit) {
      return this.renderEditingField();
    }
    return this.props.contactPerson == null ? '-' : this.renderContactPerson();
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactPerson: EntitySelectors.getContactPerson(state, ownProps.value),
    contactPersons: EntitySelectors.getContactPersons(state, ownProps.contactPersonIds),
  };
}

export default withRouter(connect(mapStateToProps)(ContactPersonField));
