import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Link } from 'react-router-dom';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import { Button, Icon } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import { AssetsSelectors } from 'state/ducks/assets';
import Menu from './Menu';
import EditAssetModal from './EditAssetModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showEditAssetModal: false,
    reloadTreeOnBack: undefined,
  };

  componentDidUpdate(prevProps) {
    const changedPositionInTree = prevProps.asset.tree_parent_id !== this.props.asset.tree_parent_id;
    const archivedWasChanged = prevProps.asset.archived !== this.props.asset.archived;
    if (changedPositionInTree || archivedWasChanged) {
      this.setState({ reloadTreeOnBack: true });
    }
  }

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/assets',
      search: HelperFunctions.convertObjToQueryParameters(search),
      state: { id: this.props.asset.id, reload: this.state.reloadTreeOnBack },
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.asset.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>{this.props.asset.title}</LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTabs = () => {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/assets/${id}`}
          active={matchPath(this.props.location.pathname, { path: '/assets/:id/', exact: true }) != null}
        >
          <FormattedMessage id="screens.asset.tabs.information" />
        </LayoutHeader.TabBarItem>
        <LayoutHeader.TabBarItem
          to={`/assets/${id}/work-orders`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/assets/:id/work-orders/',
            }) != null
          }
        >
          <FormattedMessage id="screens.asset.tabs.work-orders" />
        </LayoutHeader.TabBarItem>
        {operationalMaintenanceIsActivated ? (
          <LayoutHeader.TabBarItem
            to={`/assets/${id}/operational-maintenances`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/assets/:id/operational-maintenances/',
              }) != null
            }
          >
            <FormattedMessage id="screens.asset.tabs.operational-maintenance" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.settings.spare_parts_activated ? (
          <LayoutHeader.TabBarItem
            to={`/assets/${id}/spare-parts`}
            active={
              matchPath(this.props.location.pathname, { path: '/assets/:id/spare-parts', exact: true }) !=
              null
            }
          >
            <FormattedMessage id="screens.asset.tabs.spare-parts" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.hasProTier && this.props.settings.downtime_activated ? (
          <LayoutHeader.TabBarItem
            to={`/assets/${id}/downtimes`}
            active={
              matchPath(this.props.location.pathname, { path: '/assets/:id/downtimes', exact: true }) != null
            }
          >
            <FormattedMessage id="screens.asset.tabs.downtimes" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.hasProTier && this.props.currentSystem.costs_activated && this.props.canViewFullCosts ? (
          <LayoutHeader.TabBarItem
            to={`/assets/${id}/costs`}
            active={
              matchPath(this.props.location.pathname, { path: '/assets/:id/costs', exact: true }) != null
            }
          >
            <FormattedMessage id="screens.asset.tabs.costs" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.canViewVendors ? (
          <LayoutHeader.TabBarItem
            to={`/assets/${id}/vendors`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/assets/:id/vendors/',
              }) != null
            }
          >
            <FormattedMessage id="screens.asset.tabs.vendors" />
          </LayoutHeader.TabBarItem>
        ) : null}
        <LayoutHeader.TabBarItem
          to={`/assets/${id}/files`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/assets/:id/files/',
            }) != null
          }
        >
          <FormattedMessage id="screens.asset.tabs.attachments" />
        </LayoutHeader.TabBarItem>
      </LayoutHeader.TabBar>
    );
  };

  renderHeaderAssetPath = () => (
    <div className={styles['header-path-container']}>
      <TreePath
        size={13}
        renderItem={asset => (
          <Link to={`/assets/${asset.id}`}>
            <span className={styles['path-item']}>{asset.title}</span>
          </Link>
        )}
        onlyShowPath
        assetId={this.props.asset.id}
      />
    </div>
  );

  render() {
    return (
      <>
        <LayoutHeader
          title={this.props.asset.title}
          titleHoverText={this.props.asset.title}
          subtitle={this.props.settings.asset_number_activated ? `#${this.props.asset.number}` : null}
          extraComponent={this.renderHeaderAssetPath()}
          breadcrumbComponent={this.renderBreadcrumb()}
          tabBarComponent={this.renderTabs()}
          editTitleButton={
            !this.props.canEditAssets ? null : (
              <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => this.setState({ showEditAssetModal: true })}
              />
            )
          }
          buttonsComponent={<Menu />}
        />
        <EditAssetModal
          open={this.state.showEditAssetModal}
          asset={this.props.asset}
          onClose={() => this.setState({ showEditAssetModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  return {
    asset: EntitySelectors.getAsset(state, assetId),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewFullCosts: AuthSelectors.canViewFullCosts(state),
    navigatedTo: AssetsSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
