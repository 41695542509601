import types from './types';
import { SDKReduxTypes } from 'sdk';
import { generateEmptyRequest } from 'sdk/Request';

const INITIAL_STATE = {
  isCreatingRequest: false,
  editingRequest: {},
  editingRequestBeforeEdit: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.PREPARE_NEW_REQUEST: {
      const newRequest = {
        ...generateEmptyRequest(),
        ...action.payload,
      };
      return {
        ...state,
        editingRequest: newRequest,
        editingRequestBeforeEdit: newRequest,
        isCreatingRequest: true,
      };
    }
    case types.CANCEL_NEW_REQUEST: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_EDITING_REQUEST_VALUES: {
      const editingRequest = {
        ...state.editingRequest,
        ...action.payload,
      };
      return {
        ...state,
        editingRequest,
      };
    }
    case SDKReduxTypes.IMAGE_DRAFT_CREATED: {
      const { data } = action.payload;
      const { id } = data;
      if (state.isCreatingRequest) {
        return {
          ...state,
          editingRequest: {
            ...state.editingRequest,
            images: [...state.editingRequest.images, id],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId } = action.payload;
      if (state.isCreatingRequest) {
        return {
          ...state,
          editingRequest: {
            ...state.editingRequest,
            images: state.editingRequest.images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};
