import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

export default ({ backgroundColor, label, inline, small }) => {
  return (
    <div
      className={`${styles['status']} ${inline ? styles['inline'] : ''} ${small ? styles['small'] : ''}`}
      style={{ backgroundColor }}
    >
      <FormattedMessage id={label} />
    </div>
  );
};
