import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export default class Input extends Component {
  state = {
    focused: false,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.setState({ focused: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.focused && this.state.focused) {
      this.inputRef.focus();
    }
  }

  render() {
    let classNames = [styles['input']];
    if (this.props.value) {
      classNames = [...classNames, styles['has-value']];
    }
    if (this.state.focused) {
      classNames = [...classNames, styles['focused']];
    }
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    return (
      <input
        className={classNames.join(' ')} 
        placeholder={this.props.placeholder}
        type={this.props.type}
        ref={ref => (this.inputRef = ref)}
        autoFocus={this.props.autoFocus}
        onKeyPress={this.props.onKeyPress}
        value={this.props.value}
        onFocus={() => {
          this.setState({ focused: true });
        }}
        onBlur={() => {
          this.setState({ focused: false });
        }}
        onChange={e => this.props.onChange(e.target.value)}
      />
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  autoFocus: false,
  value: null,
  onChange: null,
  onBlur: null,
  onKeyPress: null,
};
