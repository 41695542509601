import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { normalizeUser } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, FieldErrorWrapper } from 'views/components/Shared/General';
import { SCIMFieldsDisabledBanner } from 'views/components/User/SCIMFieldsDisabledBanner';

class UserInfoModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    name: this.props.user.name || '',
    email: this.props.user.email || '',
    showErrorForEmptyName: false,
    showRequiredEmailError: false,
    showInvalidEmailErorr: false,
    showAlreadyTakenEmailError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  isSaveable = () => {
    let isSavable = true;
    if (this.state.name.length === 0) {
      this.setState({ showErrorForEmptyName: true });
      isSavable = false;
    }
    return isSavable;
  };

  update = () => {
    if (this.isSaveable() === false) {
      return;
    }
    this.setState({ isSaving: true });
    const params = {
      name: this.state.name,
      email: this.state.email,
    };
    this.props
      .updateUser(this.props.user.id, params)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        const { data } = res;
        const { entities } = normalizeUser(data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        const isRequired = HelperFunctions.hasError(e, { code: '10001', key: 'email' });
        const alreadyExists = HelperFunctions.hasError(e, { code: '10002', key: 'email' });
        const invalidEmail = HelperFunctions.hasError(e, { code: '10018', key: 'email' });
        this.setState({
          showInvalidEmailErorr: invalidEmail,
          showAlreadyTakenEmailError: alreadyExists,
          showRequiredEmailError: isRequired,
          isSaving: false,
        });
      });
  };

  renderEmailErrorMessage = () => {
    const { showRequiredEmailError, showInvalidEmailErorr, showAlreadyTakenEmailError } = this.state;
    if (showRequiredEmailError) {
      return <FormattedMessage id="general.errors.is-required" />;
    }
    if (showInvalidEmailErorr) {
      return <FormattedMessage id="general.errors.invalid-email" />;
    }
    if (showAlreadyTakenEmailError) {
      return <FormattedMessage id="general.errors.email-already-exists" />;
    }
    return null;
  };

  renderFields = () => {
    const { showRequiredEmailError, showInvalidEmailErorr, showAlreadyTakenEmailError } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.user.name" />}>
              <Field.Text
                autoFocus
                error={this.state.showErrorForEmptyName}
                value={this.state.name}
                onChange={name => this.setState({ name, showErrorForEmptyName: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.username" />}>
              <FieldErrorWrapper
                position="top"
                show={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                errorElement={this.renderEmailErrorMessage()}
              >
                <Field.Text
                  autoFocus
                  value={this.state.email}
                  error={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                  onChange={email =>
                    this.setState({
                      email,
                      showRequiredEmailError: false,
                      showInvalidEmailErorr: false,
                      showAlreadyTakenEmailError: false,
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.info-title" />}
          subtitle={
            <FormattedMessage
              id="screens.users.action-modal.change-subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>
          {this.props.user.scim ? <SCIMFieldsDisabledBanner /> : <>{this.renderFields()}</>}
        </Modal.Content>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        {this.renderContent()}
        {!this.props.user.scim ? (
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.update();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoModal);
