import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Field, Button, EmptyDataSet, Icon } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { Title } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { API, SDKReduxOperations } from 'sdk';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import { AuthSelectors } from 'state/ducks/auth';
import SettingsModal from './SettingsModal';
import styles from './style.module.scss';

class PrintQRCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  getInititalState = () => ({
    width: '',
    height: '',
    margin_top: '',
    margin_bottom: '',
    margin_left: '',
    margin_right: '',
    legacy: false,
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
    suggestSize: false,
    open: false,
    showSettingsModal: false,
    showEdgeDistance: false,
  });

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true });
    } else if (!prevState.open && this.state.open) {
      let exportType = null;
      if (this.props.type === 'sparePart') {
        exportType = ExportType.SparePartsQRLabel;
      } else if (this.props.type === 'asset') {
        exportType = ExportType.AssetsQRLabel;
      }
      let width = '';
      let height = '';
      let margin_top = '';
      let margin_bottom = '';
      let margin_left = '';
      let margin_right = '';
      if (this.props.type === 'sparePart') {
        width = this.props.settings.spare_part_qr_width || '';
        height = this.props.settings.spare_part_qr_height || '';
        margin_top = this.props.settings.spare_part_qr_margin_top || '';
        margin_bottom = this.props.settings.spare_part_qr_margin_bottom || '';
        margin_left = this.props.settings.spare_part_qr_margin_left || '';
        margin_right = this.props.settings.spare_part_qr_margin_right || '';
      } else {
        width = this.props.settings.asset_qr_width || '';
        height = this.props.settings.asset_qr_height || '';
        margin_top = this.props.settings.asset_qr_margin_top || '';
        margin_bottom = this.props.settings.asset_qr_margin_bottom || '';
        margin_left = this.props.settings.asset_qr_margin_left || '';
        margin_right = this.props.settings.asset_qr_margin_right || '';
      }
      this.setState({
        ...this.getInititalState(),
        open: true,
        width,
        height,
        margin_top,
        margin_bottom,
        margin_left,
        margin_right,
        showQuantityExceeded: this.props.amountOfQrCodes >= maxQuantityAllowedForType(exportType),
      });
    } else if (prevProps.open && !this.props.open) {
      this.setState({ open: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.open && !nextProps.open && !this.state.open && !nextState.open) return false;
    return true;
  }

  getParamsForPrintQrCodes = () => {
    const { width, height, legacy } = this.state;
    let margin_top = legacy ? 5 : this.state.margin_top;
    let margin_left = legacy ? 5 : this.state.margin_left;
    let margin_right = legacy ? 5 : this.state.margin_right;
    let margin_bottom = legacy ? 5 : this.state.margin_bottom;
    if (this.props.assetId) {
      return {
        id: this.props.assetId,
        width,
        height,
        legacy,
        margin_top,
        margin_left,
        margin_right,
        margin_bottom,
      };
    }
    if (this.props.sparePartId) {
      return {
        id: this.props.sparePartId,
        width,
        height,
        legacy,
        margin_top,
        margin_left,
        margin_right,
        margin_bottom,
      };
    }
    return {
      ...this.props.params,
      width,
      height,
      legacy,
      margin_top,
      margin_left,
      margin_right,
      margin_bottom,
    };
  };

  printQrCodes = () => {
    this.setState({ isExporting: true });
    if (this.props.type === 'sparePart') {
      this.updateSettings();
      const params = {
        params: this.getParamsForPrintQrCodes(),
        type: ExportType.SparePartsQRLabel,
      };

      API.createExport(this.props.currentSystem.id, params).then(({ data }) => {
        this.setState({ showExportLoaderForId: data.id });
      });
    } else if (this.props.type === 'asset') {
      this.updateSettings();
      const params = {
        params: this.getParamsForPrintQrCodes(),
        type: ExportType.AssetsQRLabel,
      };

      API.createExport(this.props.currentSystem.id, params).then(({ data }) => {
        this.setState({ showExportLoaderForId: data.id });
      });
    }
  };

  updateSettings = () => {
    if (this.state.suggestSize === false) {
      return;
    }
    let params = {};
    if (this.props.type === 'sparePart') {
      params = {
        spare_part_qr_width: this.state.width,
        spare_part_qr_height: this.state.height,
        spare_part_qr_margin_top: this.state.margin_top,
        spare_part_qr_margin_bottom: this.state.margin_bottom,
        spare_part_qr_margin_left: this.state.margin_left,
        spare_part_qr_margin_right: this.state.margin_right,
      };
    } else {
      params = {
        asset_qr_width: this.state.width,
        asset_qr_height: this.state.height,
        asset_qr_margin_top: this.state.margin_top,
        asset_qr_margin_bottom: this.state.margin_bottom,
        asset_qr_margin_left: this.state.margin_left,
        asset_qr_margin_right: this.state.margin_right,
      };
    }
    this.props
      .updateSettings(this.props.currentSystem.id, params)
      .then(() => {})
      .catch(() => {});
  };

  renderMaxQuantityErrorMessage = () => {
    return <FormattedMessage id="components.legacy-print-qr-codes.quantity-exceeded" />;
  };

  renderSuggestSizeCheckbox = () => {
    if (this.props.type === 'sparePart' && this.props.canEditSpareParts === false) {
      return null;
    }
    if (this.props.type === 'asset' && this.props.canEditAssets === false) {
      return null;
    }

    const { width, height, margin_right, margin_bottom, margin_left, margin_top } = this.state;

    if (this.props.type === 'sparePart') {
      const {
        spare_part_qr_width,
        spare_part_qr_height,
        spare_part_qr_margin_top,
        spare_part_qr_margin_bottom,
        spare_part_qr_margin_left,
        spare_part_qr_margin_right,
      } = this.props.settings;
      const hasNoDefaultSparePartsSettings = spare_part_qr_width == null && spare_part_qr_height == null;
      const changedSize = width !== spare_part_qr_width || height !== spare_part_qr_height;
      const changedMargins =
        margin_top !== (spare_part_qr_margin_top || '') ||
        margin_bottom !== (spare_part_qr_margin_bottom || '') ||
        margin_left !== (spare_part_qr_margin_left || '') ||
        margin_right !== (spare_part_qr_margin_right || '');

      if (hasNoDefaultSparePartsSettings || changedSize || changedMargins) {
        return (
          <div className={styles['suggest-size-container']}>
            <Field.Checkbox
              label={<FormattedMessage id="components.legacy-print-qr-codes.suggest-size-spare-part" />}
              checked={this.state.suggestSize}
              onChange={() =>
                this.setState(prevState => ({
                  suggestSize: !prevState.suggestSize,
                }))
              }
            />
          </div>
        );
      }
      return null;
    }

    const {
      asset_qr_width,
      asset_qr_height,
      asset_qr_margin_top,
      asset_qr_margin_bottom,
      asset_qr_margin_left,
      asset_qr_margin_right,
    } = this.props.settings;
    const hasNoDefaultAssetSettings = asset_qr_width == null && asset_qr_height == null;
    const changedSize = width !== asset_qr_width || height !== asset_qr_height;
    const changedMargins =
      margin_top !== (asset_qr_margin_top || '') ||
      margin_bottom !== (asset_qr_margin_bottom || '') ||
      margin_left !== (asset_qr_margin_left || '') ||
      margin_right !== (asset_qr_margin_right || '');

    if (hasNoDefaultAssetSettings || changedSize || changedMargins) {
      return (
        <div className={styles['suggest-size-container']}>
          <Field.Checkbox
            label={<FormattedMessage id="components.legacy-print-qr-codes.suggest-size-asset" />}
            checked={this.state.suggestSize}
            onChange={() =>
              this.setState(prevState => ({
                suggestSize: !prevState.suggestSize,
              }))
            }
          />
        </div>
      );
    }
    return null;
  };

  renderEdgeDistance = () => {
    if (this.state.legacy) {
      return null;
    }
    if (this.state.showEdgeDistance) {
      return (
        <div className={styles['field']}>
          <Field label={<FormattedMessage id="components.legacy-print-qr-codes.margins" edit />}>
            <div className={styles['margins-container']}>
              <div className={[styles['margins'], styles['top']].join(' ')}>
                <Field.Number
                  value={this.state.margin_top}
                  placeholder="5"
                  rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                  onChange={margin_top => this.setState({ margin_top })}
                  onBlur={margin_top => this.setState({ margin_top })}
                />
              </div>
              <div className={[styles['margins'], styles['left']].join(' ')}>
                <Field.Number
                  placeholder="5"
                  value={this.state.margin_left}
                  rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                  onChange={margin_left => this.setState({ margin_left })}
                  onBlur={margin_left => this.setState({ margin_left })}
                />
              </div>
              <div className={[styles['margins'], styles['right']].join(' ')}>
                <Field.Number
                  placeholder="5"
                  value={this.state.margin_right}
                  rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                  onChange={margin_right => this.setState({ margin_right })}
                  onBlur={margin_right => this.setState({ margin_right })}
                />
              </div>
              <div className={[styles['margins'], styles['bottom']].join(' ')}>
                <Field.Number
                  placeholder="5"
                  value={this.state.margin_bottom}
                  rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                  onChange={margin_bottom => this.setState({ margin_bottom })}
                  onBlur={margin_bottom => this.setState({ margin_bottom })}
                />
              </div>
              <div className={styles['vertical-line']} />
              <div className={styles['horizontal-line']} />
            </div>
          </Field>
        </div>
      );
    }
    return (
      <div className={styles['field']}>
        <Button
          type="text"
          primary
          label="components.legacy-print-qr-codes.change-margins"
          noUnderline
          onClick={() => this.setState({ showEdgeDistance: true })}
        />
      </div>
    );
  };

  renderContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>{this.renderMaxQuantityErrorMessage()}</div>
        </Modal.Content>
      );
    }
    if (this.props.sparePartId == null && this.props.assetId == null && this.props.amountOfQrCodes === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchImage}
          />
        </div>
      );
    }
    if (this.state.isExporting) {
      return (
        <div className={styles['container']}>
          <div className={styles['left-view']}>
            <div className={styles['qr-code-container']}>
              <div className={styles['width']}>
                <p>
                  <FormattedMessage id="components.legacy-print-qr-codes.width" />
                </p>
              </div>
              <div className={styles['height']}>
                <p>
                  <FormattedMessage id="components.legacy-print-qr-codes.height" />
                </p>
              </div>
              <div className={styles['qr-code']}>
                <Icon type="qrcode" />
              </div>
            </div>
          </div>
          <div className={styles['right-view']}>
            <ExportLoader exportId={this.state.showExportLoaderForId} />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['container']}>
        <div className={styles['left-view']}>
          <div className={styles['qr-code-container']}>
            <div className={styles['width']}>
              <p>
                <FormattedMessage id="components.legacy-print-qr-codes.width" />
              </p>
            </div>
            <div className={styles['height']}>
              <p>
                <FormattedMessage id="components.legacy-print-qr-codes.height" />
              </p>
            </div>
            <div className={styles['qr-code']}>
              <Icon type="qrcode" />
            </div>
          </div>
        </div>
        <div className={styles['right-view']}>
          <div className={styles['deprecated-feature-text-container']}>
            <FormattedMessage id="components.legacy-print-qr-codes.deprecated-text" />
          </div>
          <div className={styles['fields']}>
            <div className={styles['design-choice']}>
              <Field.Radio.Group>
                <Field.Radio
                  label={
                    <span className={styles['label-with-pill']}>
                      <FormattedMessage id="components.legacy-print-qr-codes.new-design" />
                      <div className={styles['data-pill']}>
                        <FormattedMessage id="components.legacy-print-qr-codes.beta" />
                      </div>
                    </span>
                  }
                  checked={this.state.legacy == false}
                  onChange={() => this.setState({ legacy: false })}
                />
                <Field.Radio
                  label={<FormattedMessage id="components.legacy-print-qr-codes.legacy-design" />}
                  checked={this.state.legacy == true}
                  onChange={() => this.setState({ legacy: true })}
                />
              </Field.Radio.Group>
            </div>
            <div>
              <div className={styles['field']}>
                <Field label={<FormattedMessage id="components.legacy-print-qr-codes.width" edit />}>
                  <Field.Decimal
                    value={this.state.width}
                    rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                    onChange={width => this.setState({ width })}
                    onBlur={width => this.setState({ width })}
                  />
                </Field>
              </div>
              <div className={styles['field']}>
                <Field label={<FormattedMessage id="components.legacy-print-qr-codes.height" edit />}>
                  <Field.Decimal
                    value={this.state.height}
                    rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                    onChange={height => this.setState({ height })}
                    onBlur={height => this.setState({ height })}
                  />
                </Field>
              </div>
              {this.renderEdgeDistance()}
            </div>
            {this.renderSuggestSizeCheckbox()}
          </div>
          <div className={styles['button-container']}>
            <Button
              disabled={!this.state.width || !this.state.height}
              primary
              fullWidth
              loading={this.state.isExporting}
              label="components.legacy-print-qr-codes.download-pdf"
              onClick={this.printQrCodes}
            />
          </div>
        </div>
      </div>
    );
  };

  renderSubtitle = () => {
    if (this.props.type === 'sparePart') {
      if (this.props.sparePartId) {
        return <Title sparePart={this.props.sparePart} />;
      } else {
        const amount = this.props.amountOfQrCodes;

        return (
          <FormattedPlural
            value={this.props.amountOfQrCodes}
            zero={<FormattedMessage id="components.legacy-print-qr-codes.subtitle-spare-part.zero" />}
            one={<FormattedMessage id="components.legacy-print-qr-codes.subtitle-spare-part.one" />}
            two={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-spare-part.two"
                values={{ amount }}
              />
            }
            few={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-spare-part.few"
                values={{ amount }}
              />
            }
            many={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-spare-part.many"
                values={{ amount }}
              />
            }
            other={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-spare-part.other"
                values={{ amount }}
              />
            }
          />
        );
      }
    } else if (this.props.type === 'asset') {
      if (this.props.assetId) {
        return this.props.asset.title;
      } else {
        const amount = this.props.amountOfQrCodes;

        return (
          <FormattedPlural
            value={this.props.amountOfQrCodes}
            zero={<FormattedMessage id="components.legacy-print-qr-codes.subtitle-asset.zero" />}
            one={<FormattedMessage id="components.legacy-print-qr-codes.subtitle-asset.one" />}
            two={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-asset.two"
                values={{ amount }}
              />
            }
            few={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-asset.few"
                values={{ amount }}
              />
            }
            many={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-asset.many"
                values={{ amount }}
              />
            }
            other={
              <FormattedMessage
                id="components.legacy-print-qr-codes.subtitle-asset.other"
                values={{ amount }}
              />
            }
          />
        );
      }
    }
  };

  renderTitle = () => {
    if (this.props.sparePartId || this.props.assetId) {
      return <FormattedMessage id="components.legacy-print-qr-codes.title-single" />;
    }
    return <FormattedMessage id="components.legacy-print-qr-codes.title" />;
  };

  renderSettingsModal = () => {
    return (
      <SettingsModal
        open={this.state.showSettingsModal}
        type={this.props.type}
        onClose={() => {
          this.setState({ showSettingsModal: false });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
      />
    );
  };

  renderIconButton = () => {
    if (this.props.type === 'sparePart' && this.props.canEditSpareParts === false) {
      return null;
    }
    if (this.props.type === 'asset' && this.props.canEditAssets === false) {
      return null;
    }
    return (
      <Button
        type="icon"
        icon={<Icon regular size={15} type="cog" />}
        onClick={() => {
          this.setState({ open: false });
          setTimeout(() => {
            this.setState({ showSettingsModal: true });
          }, 250);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} width={700}>
          <Modal.Header
            ignoreLine
            title={this.renderTitle()}
            iconButtons={this.renderIconButton()}
            subtitle={this.renderSubtitle()}
            onClose={this.props.onClose}
          />
          <Modal.Content noPadding>{this.renderContent()}</Modal.Content>
        </Modal>
        {this.renderSettingsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.sparePartId),
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintQRCodeModal);

PrintQRCodeModal.propTypes = {
  amountOfQrCodes: PropTypes.number,
  sparePartId: PropTypes.string,
  type: PropTypes.oneOf(['sparePart', 'asset']).isRequired,
};

PrintQRCodeModal.defaultProps = {
  amountOfQrCodes: 0,
  sparePartId: null,
  type: null,
  open: false,
};
