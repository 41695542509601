import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SparePartSelectors, SparePartOperations } from 'state/ducks/sparePart';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import { ContentContainer } from 'views/components/Shared/Layout';
import { List, EmptyDataSet, Pagination, WhiteCard } from 'views/components/Shared/General';
import {
  SparePartWithdrawalModal,
  DeleteSparePartWithdrawalModal,
} from 'views/components/SparePartWithdrawal';
import { Loader } from 'views/components/Shared/General';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import SparePartWithdrawalListItem from 'views/components/SparePartWithdrawal/SparePartWithdrawalListItem';
import styles from './style.module.scss';

class Withdrawals extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      tableLoading: false,
      viewInitialized: false,
      showDeleteSparePartWithdrawalModal: false,
      showDeleteSparePartWithdrawalModalForId: null,
      isDeletingSparePartWithdrawal: false,
      showSparePartWithdrawalModal: false,
      showSparePartWithdrawalModalForId: null,
      queryParams,
    };
  }

  componentDidMount() {
    this.fetchSparePartWithdrawals()
      .then(() => {
        this.setState({ viewInitialized: true });
      })
      .catch(() => {
        this.setState({ viewInitialized: true });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchSparePartWithdrawals()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchSparePartWithdrawals = () => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);
    return this.props
      .fetchSparePartWithdrawals(this.props.currentSystem.id, {
        spare_part: {
          id: this.props.match.params.id,
        },
        ...filterParams,
      })
      .then(({ data: sparePartWithdrawals }) => {
        return sparePartWithdrawals;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  deleteSparePartWithdrawal = id => {
    this.setState({ isDeletingSparePartWithdrawal: true });
    this.props.deleteSparePartWithdrawal(id).then(() => {
      this.setState({
        isDeletingSparePartWithdrawal: false,
        showDeleteSparePartWithdrawalModal: false,
        showDeleteSparePartWithdrawalModalForId: null,
      });
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.withdrawals.delete-withdrawal-modal.delete-success" />}
        />
      );
    });
  };

  renderDeleteModal = () => {
    return (
      <DeleteSparePartWithdrawalModal
        open={this.state.showDeleteSparePartWithdrawalModal}
        id={this.state.showDeleteSparePartWithdrawalModalForId}
        onDelete={() => {
          this.setState({ showDeleteSparePartWithdrawalModal: false });
        }}
        onClose={() => {
          this.setState({ showDeleteSparePartWithdrawalModal: false });
        }}
      />
    );
  };

  renderSparePartWithdrawalModal = () => {
    return (
      <SparePartWithdrawalModal
        open={this.state.showSparePartWithdrawalModal}
        id={this.state.showSparePartWithdrawalModalForId}
        onClose={() => this.setState({ showSparePartWithdrawalModal: false })}
        onDelete={id => {
          this.setState({ showSparePartWithdrawalModal: false });
          setTimeout(() => {
            this.setState({
              showDeleteSparePartWithdrawalModal: true,
              showDeleteSparePartWithdrawalModalForId: id,
            });
          }, 250);
        }}
      />
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.spare-part.withdrawals.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.spare-part.withdrawals.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.spare-part.withdrawals.empty-data-set.subtitle" />}
        image={SparePartSmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.sparePartWithdrawals.length === 0 ? 1 : this.props.sparePartWithdrawals.length)
              .fill()
              .map(() => (
                <SparePartWithdrawalListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.sparePartWithdrawals.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.sparePartWithdrawals.map(({ id }) => (
            <SparePartWithdrawalListItem
              id={id}
              key={id}
              onDelete={id => {
                this.setState({
                  showDeleteSparePartWithdrawalModal: true,
                  showDeleteSparePartWithdrawalModalForId: id,
                });
              }}
              onClick={() => {
                this.setState({
                  showSparePartWithdrawalModal: true,
                  showSparePartWithdrawalModalForId: id,
                });
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderHeader = () => (
    <List.Header small background paddingRight={75}>
      <List.Header.Column flex>
        <FormattedMessage id="resources.spare-part.resource" />
      </List.Header.Column>
    </List.Header>
  );

  renderPurchaseOrders = () => {
    return (
      <>
        {this.renderHeader()}
        {this.renderList()}
      </>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.sparePartWithdrawals.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderPurchaseOrders();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderDeleteModal()}
        {this.renderSparePartWithdrawalModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartWithdrawals: SparePartOperations.fetchSparePartWithdrawals,
      deleteSparePartWithdrawal: SDKReduxOperations.deleteSparePartWithdrawal,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    sparePartWithdrawals: SparePartSelectors.getSparePartWithdrawals(state),
    pagination: SparePartSelectors.spareParePartWithdrawalPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Withdrawals)));
