const DOMAIN_PREFIX = 'uploadProgress/';

const ATTACHMENT_UPLOAD_STARTED = `${DOMAIN_PREFIX}upload_started`;
const ATTACHMENT_UPLOAD_CANCELLED = `${DOMAIN_PREFIX}upload_cancelled`;
const ATTACHMENT_UPLOAD_UPDATED = `${DOMAIN_PREFIX}upload_update`;
const ATTACHMENT_UPLOAD_COMPLETED = `${DOMAIN_PREFIX}upload_completed`;

export default {
  ATTACHMENT_UPLOAD_STARTED,
  ATTACHMENT_UPLOAD_CANCELLED,
  ATTACHMENT_UPLOAD_UPDATED,
  ATTACHMENT_UPLOAD_COMPLETED,
};
