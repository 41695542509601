/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getAttachments = state => {
  const { entities } = state;
  return state.files.ids.map(id => entities.attachmentById[id]).filter(val => val);
};
const getTotalEntries = state => state.files.totalEntries;
const getIsFullyLoaded = state => state.files.isFullyLoaded;
const getPaginateFrom = state => state.files.paginateFrom;

export default {
  getAttachments,
  getTotalEntries,
  getPaginateFrom,
  getIsFullyLoaded,
};
