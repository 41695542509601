import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.IMAGE_CREATED_FOR_SPARE_PART: {
      const { sparePartId, data } = action.payload;
      const { id } = data;
      if (state[sparePartId]) {
        return update(
          { ...state },
          {
            [sparePartId]: {
              images: {
                $push: [id],
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { spare_part_id } = data;
      if (state[spare_part_id] == null) return state;
      return {
        ...state,
        [spare_part_id]: {
          ...state[spare_part_id],
          images: state[spare_part_id].images.filter(id => id !== imageId),
        },
      };
    }
    case SDKReduxTypes.IMAGE_UPDATED: {
      const { imageId, data, params } = action.payload;
      const { spare_part_id } = data;
      if (state[spare_part_id] == null) return state;
      if (params.sort !== 1) return null;
      return {
        ...state,
        [spare_part_id]: {
          ...state[spare_part_id],
          images: [imageId, ...state[spare_part_id].images.filter(id => id !== imageId)],
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_STOCK_ADJUSTMENT_CREATED: {
      const { data } = action.payload;
      const { spare_part_id, quantity } = data;

      if (state[spare_part_id] == null) {
        return state;
      }

      return {
        ...state,
        [spare_part_id]: {
          ...state[spare_part_id],
          stock_quantity: quantity,
        },
      };
    }
    case EntityTypes.UPDATE_ENTITIES: {
      if (action.payload && action.payload.sparePartById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartById);
      }
      return state;
    }
    default:
      return state;
  }
};
