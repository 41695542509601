import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { PermissionType } from 'sdk/PermissionProfile';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { Button, InlineModal, Field, WarningMessage, Icon } from 'views/components/Shared/General';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import styles from './style.module.scss';

const Item = ({ title, subtitle }) => {
  return (
    <>
      <p className={styles['title']}>
        <FormattedMessage id={title} />
      </p>
      <p className={styles['subtitle']}>
        <FormattedMessage id={subtitle} />
      </p>
    </>
  );
};

const DropdownItem = ({ title, subtitle }) => {
  return (
    <div style={{ padding: '5px 10px' }}>
      <Item title={title} subtitle={subtitle} />
    </div>
  );
};

class EditPermissionProfileModal extends Component {
  state = {
    isSaving: false,
    isDeleting: false,
    hasUnsavedChanges: false,
    deleteInlineModalIsOpen: false,
    showHasUsersError: false,
    permissionProfile: {},
    permissionProfileBeforeEdit: {},
    openModal: false,
    workOrderValues: [],
    requestValues: [],
    assetValues: [],
    meterValues: [],
    sparePartValues: [],
    vendorValues: [],
    purchaseOrderValues: [],
    costValues: [],
    operationalMaintenanceValues: [],
  };

  getDefaultPermissionProfile = () => ({
    title: '',
    asset: PermissionType.Asset.View,
    vendor: PermissionType.Vendor.None,
    request: PermissionType.Request.Create,
    work_order: PermissionType.WorkOrder.CarryOut,
    spare_part: PermissionType.SparePart.StockAdjustment,
    purchase_order: PermissionType.PurchaseOrder.None,
    cost: PermissionType.Cost.None,
    meter: PermissionType.Meters.EditReading,
    operational_maintenance: PermissionType.OperationalMaintenance.View,
  });

  // SETS THE VALUES FOR ALL OF THE PERMISSIONS
  setDefaultMeterList = () => {
    this.setState({
      meterValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.meters.permissions.none.title',
          description:
            'screens.users.permission-profiles.permission.types.meters.permissions.none.description',
          permission: PermissionType.Meters.None,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.meters.permissions.view.title',
          description:
            'screens.users.permission-profiles.permission.types.meters.permissions.view.description',
          permission: PermissionType.Meters.EditReading,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.meters.permissions.full.title',
          description:
            'screens.users.permission-profiles.permission.types.meters.permissions.full.description',
          permission: PermissionType.Meters.Edit,
        },
      ],
    });
  };

  setDefaultAssetList = () => {
    this.setState({
      assetValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.assets.permissions.view.title',
          description:
            'screens.users.permission-profiles.permission.types.assets.permissions.view.description',
          permission: PermissionType.Asset.View,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.assets.permissions.full.title',
          description:
            'screens.users.permission-profiles.permission.types.assets.permissions.full.description',
          permission: PermissionType.Asset.Edit,
        },
      ],
    });
  };

  setDefaultVendorList = () => {
    this.setState({
      vendorValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.vendors.permissions.none.title',
          description:
            'screens.users.permission-profiles.permission.types.vendors.permissions.none.description',
          permission: PermissionType.Vendor.None,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.vendors.permissions.view.title',
          description:
            'screens.users.permission-profiles.permission.types.vendors.permissions.view.description',
          permission: PermissionType.Vendor.View,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.vendors.permissions.full.title',
          description:
            'screens.users.permission-profiles.permission.types.vendors.permissions.full.description',
          permission: PermissionType.Vendor.Edit,
        },
      ],
    });
  };

  setDefaultRequestList = () => {
    this.setState({
      requestValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.requests.permissions.create.title',
          description:
            'screens.users.permission-profiles.permission.types.requests.permissions.create.description',
          permission: PermissionType.Request.Create,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.requests.permissions.administrate.title',
          description:
            'screens.users.permission-profiles.permission.types.requests.permissions.administrate.description',
          permission: PermissionType.Request.Administrate,
        },
      ],
    });
  };

  setDefaultWorkOrderList = () => {
    this.setState({
      workOrderValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.work-orders.permissions.carry-out.title',
          description:
            'screens.users.permission-profiles.permission.types.work-orders.permissions.carry-out.description',
          permission: PermissionType.WorkOrder.CarryOut,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.work-orders.permissions.administrate.title',
          description:
            'screens.users.permission-profiles.permission.types.work-orders.permissions.administrate.description',
          permission: PermissionType.WorkOrder.Administrate,
        },
      ],
    });
  };

  setDefaultOperationalMaintenanceList = () => {
    this.setState({
      operationalMaintenanceValues: [
        {
          title:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.none.title',
          description:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.none.description',
          permission: PermissionType.OperationalMaintenance.None,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.view.title',
          description:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.view.description',
          permission: PermissionType.OperationalMaintenance.View,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.carry-out.title',
          description:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.carry-out.description',
          permission: PermissionType.OperationalMaintenance.CarryOut,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.administrate.title',
          description:
            'screens.users.permission-profiles.permission.types.operational-maintenances.permissions.administrate.description',
          permission: PermissionType.OperationalMaintenance.Administrate,
        },
      ],
    });
  };

  setDefaultSparePartList = () => {
    this.setState({
      sparePartValues: [
        {
          title:
            'screens.users.permission-profiles.permission.types.spare-parts.permissions.stock-adjustment.title',
          description:
            'screens.users.permission-profiles.permission.types.spare-parts.permissions.stock-adjustment.description',
          permission: PermissionType.SparePart.StockAdjustment,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.spare-parts.permissions.edit.title',
          description:
            'screens.users.permission-profiles.permission.types.spare-parts.permissions.edit.description',
          permission: PermissionType.SparePart.Edit,
        },
      ],
    });
  };

  setDefaultPurchaseOrderList = () => {
    this.setState({
      purchaseOrderValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.purchase-orders.permissions.none.title',
          description:
            'screens.users.permission-profiles.permission.types.purchase-orders.permissions.none.description',
          permission: PermissionType.PurchaseOrder.None,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.purchase-orders.permissions.stock-adjustment.title',
          description:
            'screens.users.permission-profiles.permission.types.purchase-orders.permissions.stock-adjustment.description',
          permission: PermissionType.PurchaseOrder.RegisterDeliveries,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.purchase-orders.permissions.edit.title',
          description:
            'screens.users.permission-profiles.permission.types.purchase-orders.permissions.edit.description',
          permission: PermissionType.PurchaseOrder.Edit,
        },
      ],
    });
  };

  setDefaultCostList = () => {
    this.setState({
      costValues: [
        {
          title: 'screens.users.permission-profiles.permission.types.costs.permissions.none.title',
          description:
            'screens.users.permission-profiles.permission.types.costs.permissions.none.description',
          permission: PermissionType.Cost.None,
        },
        {
          title:
            'screens.users.permission-profiles.permission.types.costs.permissions.allowed-work-order.title',
          description:
            'screens.users.permission-profiles.permission.types.costs.permissions.allowed-work-order.description',
          permission: PermissionType.Cost.WorkOrder,
        },
        {
          title: 'screens.users.permission-profiles.permission.types.costs.permissions.full.title',
          description:
            'screens.users.permission-profiles.permission.types.costs.permissions.full.description',
          permission: PermissionType.Cost.Full,
        },
      ],
    });
  };

  setDefaultLists() {
    this.setDefaultWorkOrderList();
    this.setDefaultAssetList();
    this.setDefaultCostList();
    this.setDefaultMeterList();
    this.setDefaultPurchaseOrderList();
    this.setDefaultRequestList();
    this.setDefaultSparePartList();
    this.setDefaultVendorList();
    this.setDefaultOperationalMaintenanceList();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setDefaultLists();
      if (this.props.id == null) {
        this.setState({
          permissionProfile: this.getDefaultPermissionProfile(),
          permissionProfileBeforeEdit: this.getDefaultPermissionProfile(),
          isSaving: false,
          isDeleting: false,
          hasUnsavedChanges: false,
          deleteInlineModalIsOpen: false,
          showHasUsersError: false,
        });
        return;
      }

      if (this.props.permissionProfile) {
        this.setState({
          permissionProfile: this.props.permissionProfile,
          permissionProfileBeforeEdit: this.props.permissionProfile,
          isSaving: false,
          isDeleting: false,
          hasUnsavedChanges: false,
          deleteInlineModalIsOpen: false,
          showHasUsersError: false,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      this.props.removeFieldsFromErrors(['title']);
    }
  }

  save = () => {
    if (this.props.id == null) {
      this.createPermissionProfile();
    } else {
      this.updatePermissionProfile();
    }
  };

  createPermissionProfile = () => {
    const { permissionProfile } = this.state;
    this.setState({ isSaving: true });
    this.props
      .createPermissionProfile(this.props.currentSystem.id, permissionProfile)
      .then(res => {
        this.setState({ hasUnsavedChanges: false }, () => {
          this.closeModal();
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.users.permission-profiles.permission.create-success" />}
            />
          );
        });
      })
      .catch(() => {
        this.setState({ isSaving: false });
        //TODO: Throw general error
      });
  };

  updatePermissionProfile = () => {
    const { permissionProfile } = this.state;
    this.setState({ isSaving: true });
    this.props
      .updatePermissionProfile(permissionProfile.id, permissionProfile)
      .then(() => {
        this.setState({ hasUnsavedChanges: false }, () => {
          this.closeModal();
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        });
      })
      .catch(() => {
        this.setState({ isSaving: false });
        //TODO: Throw general error
      });
  };

  editPermission = obj => {
    const { permissionProfileBeforeEdit, permissionProfile } = this.state;
    this.setState({
      permissionProfile: { ...permissionProfile, ...obj },
      hasUnsavedChanges: !isEqual({ ...permissionProfile, ...obj }, permissionProfileBeforeEdit),
    });
  };

  delete = () => {
    const { permissionProfile } = this.state;
    this.setState({ isDeleting: true });
    this.props
      .deletePermissionProfile(permissionProfile.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.permission-profiles.permission.delete-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
        if (HelperFunctions.hasError(e, { code: '20007' })) {
          this.setState({ showHasUsersError: true });
        }
      });
  };

  closeModal = () => {
    const { hasUnsavedChanges } = this.state;
    const { intl, onClose } = this.props;

    if (hasUnsavedChanges) {
      const confirmed = window.confirm(intl.formatMessage({ id: 'general.abort-unsaved-changes' }));
      if (confirmed) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  renderDeleteContent = () => {
    const { showHasUsersError, isDeleting } = this.state;
    if (showHasUsersError) {
      return (
        <WarningMessage type="destructive">
          <FormattedMessage id="screens.users.permission-profiles.permission.errors.in-use" />
        </WarningMessage>
      );
    } else {
      return (
        <InlineModal.Delete loading={isDeleting} onDelete={this.delete}>
          <FormattedMessage id="general.delete-confirm.subtitle" />
        </InlineModal.Delete>
      );
    }
  };

  renderDeleteInlineModal = () => {
    const { deleteInlineModalIsOpen } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              deleteInlineModalIsOpen: !prevState.deleteInlineModalIsOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular red type="trash-alt" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={deleteInlineModalIsOpen}
          onClose={() => this.setState({ deleteInlineModalIsOpen: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({
                deleteInlineModalIsOpen: false,
              });
            }}
          />
          <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
        </InlineModal>
      </div>
    );
  };

  renderMeterPermission = () => {
    const { meterValues, permissionProfile } = this.state;
    if (this.props.settings.meters_activated !== true) {
      return null;
    }
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.meters.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={meterValues
            .filter(el => el.permission === permissionProfile.meter)
            .map(meterValue => (
              <Item title={meterValue.title} subtitle={meterValue.description} />
            ))}
        >
          {meterValues.map(meterValue => {
            let disabled = false;
            if (
              meterValue.permission === PermissionType.Meters.EditReading ||
              meterValue.permission === PermissionType.Meters.None
            ) {
              disabled = permissionProfile.work_order === PermissionType.WorkOrder.Administrate;
            }

            return (
              <Field.Dropdown.Item
                disabled={disabled}
                selected={meterValue.permission === permissionProfile.meter}
                onClick={e => this.editPermission({ meter: meterValue.permission })}
              >
                <DropdownItem title={meterValue.title} subtitle={meterValue.description} />
              </Field.Dropdown.Item>
            );
          })}
        </Field.Dropdown>
      </div>
    );
  };

  renderAssetPermission = () => {
    const { assetValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.assets.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={assetValues
            .filter(el => el.permission === permissionProfile.asset)
            .map(assetValue => (
              <Item title={assetValue.title} subtitle={assetValue.description} />
            ))}
        >
          {assetValues.map(assetValue => (
            <Field.Dropdown.Item
              selected={assetValue.permission === permissionProfile.asset}
              onClick={e => this.editPermission({ asset: assetValue.permission })}
            >
              <DropdownItem title={assetValue.title} subtitle={assetValue.description} />
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </div>
    );
  };

  renderVendorPermission = () => {
    const { vendorValues, permissionProfile } = this.state;

    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.vendors.title" />
        </p>

        <Field.Dropdown
          clearable={false}
          value={vendorValues
            .filter(el => el.permission === permissionProfile.vendor)
            .map(vendorValue => (
              <Item title={vendorValue.title} subtitle={vendorValue.description} />
            ))}
        >
          {vendorValues.map(vendorValue => (
            <Field.Dropdown.Item
              selected={vendorValue.permission === permissionProfile.vendor}
              onClick={e => this.editPermission({ vendor: vendorValue.permission })}
            >
              <DropdownItem title={vendorValue.title} subtitle={vendorValue.description} />
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </div>
    );
  };

  renderRequestPermissions = () => {
    const { requestValues, permissionProfile } = this.state;

    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.requests.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={requestValues
            .filter(el => el.permission === permissionProfile.request)
            .map(requestValue => (
              <Item title={requestValue.title} subtitle={requestValue.description} />
            ))}
        >
          {requestValues.map(requestValue => {
            const edtPermObj =
              requestValue.permission === PermissionType.Request.Administrate
                ? {
                    request: PermissionType.Request.Administrate,
                    work_order: PermissionType.WorkOrder.Administrate,
                    meter: PermissionType.Meters.Edit,
                  }
                : {
                    request: requestValue.permission,
                  };

            return (
              <Field.Dropdown.Item
                selected={requestValue.permission === permissionProfile.request}
                onClick={e => this.editPermission(edtPermObj)}
              >
                <DropdownItem title={requestValue.title} subtitle={requestValue.description} />
              </Field.Dropdown.Item>
            );
          })}
        </Field.Dropdown>
      </div>
    );
  };

  renderWorkOrderPermissions = () => {
    const { workOrderValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.work-orders.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={workOrderValues
            .filter(el => el.permission === permissionProfile.work_order)
            .map(workOrderValue => (
              <Item title={workOrderValue.title} subtitle={workOrderValue.description} />
            ))}
        >
          {workOrderValues.map(workOrderValue => {
            let disabled = false;
            if (workOrderValue.permission === PermissionType.WorkOrder.CarryOut) {
              disabled = permissionProfile.request === PermissionType.Request.Administrate;
            }
            const edtPermission =
              workOrderValue.permission === PermissionType.WorkOrder.Administrate
                ? {
                    work_order: PermissionType.WorkOrder.Administrate,
                    meter: PermissionType.Meters.Edit,
                  }
                : {
                    work_order: PermissionType.WorkOrder.CarryOut,
                  };

            return (
              <Field.Dropdown.Item
                disabled={disabled}
                selected={workOrderValue.permission === permissionProfile.work_order}
                onClick={e => this.editPermission(edtPermission)}
              >
                <DropdownItem title={workOrderValue.title} subtitle={workOrderValue.description} />
              </Field.Dropdown.Item>
            );
          })}
        </Field.Dropdown>
      </div>
    );
  };

  renderOperationalMaintenancesPermisisons = () => {
    const { operationalMaintenanceValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.operational-maintenances.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={operationalMaintenanceValues
            .filter(el => el.permission === permissionProfile.operational_maintenance)
            .map(permission => (
              <Item title={permission.title} subtitle={permission.description} />
            ))}
        >
          {operationalMaintenanceValues.map(operationalMaintennaceValue => {
            return (
              <Field.Dropdown.Item
                selected={
                  operationalMaintennaceValue.permission === permissionProfile.operational_maintenance
                }
                onClick={e =>
                  this.editPermission({ operational_maintenance: operationalMaintennaceValue.permission })
                }
              >
                <DropdownItem
                  title={operationalMaintennaceValue.title}
                  subtitle={operationalMaintennaceValue.description}
                />
              </Field.Dropdown.Item>
            );
          })}
        </Field.Dropdown>
      </div>
    );
  };

  renderSparePartPermission = () => {
    const { sparePartValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.spare-parts.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={sparePartValues
            .filter(el => el.permission === permissionProfile.spare_part)
            .map(sparePartValue => (
              <Item title={sparePartValue.title} subtitle={sparePartValue.description} />
            ))}
        >
          {sparePartValues.map(sparePartValue => (
            <Field.Dropdown.Item
              selected={sparePartValue.permission === permissionProfile.spare_part}
              onClick={e => this.editPermission({ spare_part: sparePartValue.permission })}
            >
              <DropdownItem title={sparePartValue.title} subtitle={sparePartValue.description} />
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </div>
    );
  };

  renderPurchaseOrderPermission = () => {
    const { purchaseOrderValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.purchase-orders.title" />
        </p>
        <Field.Dropdown
          clearable={false}
          value={purchaseOrderValues
            .filter(el => el.permission === permissionProfile.purchase_order)
            .map(purchaseOrderValue => (
              <Item title={purchaseOrderValue.title} subtitle={purchaseOrderValue.description} />
            ))}
        >
          {purchaseOrderValues.map(purchaseOrderValue => (
            <Field.Dropdown.Item
              selected={purchaseOrderValue.permission === permissionProfile.purchase_order}
              onClick={e => this.editPermission({ purchase_order: purchaseOrderValue.permission })}
            >
              <DropdownItem title={purchaseOrderValue.title} subtitle={purchaseOrderValue.description} />
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </div>
    );
  };

  renderCostPermission = () => {
    const { costValues, permissionProfile } = this.state;
    return (
      <div className={styles['permission-detail-container']}>
        <p className={styles['resource-title']}>
          <FormattedMessage id="screens.users.permission-profiles.permission.types.costs.title" />
        </p>

        <Field.Dropdown
          clearable={false}
          value={costValues
            .filter(el => el.permission === permissionProfile.cost)
            .map(costValue => (
              <Item title={costValue.title} subtitle={costValue.description} />
            ))}
        >
          {costValues.map(costValue => (
            <Field.Dropdown.Item
              selected={costValue.permission === permissionProfile.cost}
              onClick={e => this.editPermission({ cost: costValue.permission })}
            >
              <DropdownItem title={costValue.title} subtitle={costValue.description} />
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </div>
    );
  };

  renderContent = () => {
    const { permissionProfile } = this.state;
    const { id, fieldErrors, setFieldErrors, removeFieldsFromErrors } = this.props;
    return (
      <React.Fragment>
        <Modal.Content ignoreLine>
          <Field label={<FormattedMessage id="resources.permission-profile.title" />}>
            <ConnectedFieldErrorWrapper field="title">
              {hasError => (
                <Field.Text
                  error={hasError}
                  autoFocus
                  value={permissionProfile.title}
                  onChange={title => {
                    if (id != null) {
                      if (title && fieldErrors.title) {
                        removeFieldsFromErrors(['title']);
                      } else if (!title && !fieldErrors.title) {
                        setFieldErrors({ title: 'general.errors.is-required' });
                      }
                    }
                    this.editPermission({ title });
                  }}
                />
              )}
            </ConnectedFieldErrorWrapper>
          </Field>
          <div className={styles['permission-section']}>
            {this.renderWorkOrderPermissions()}
            {this.renderOperationalMaintenancesPermisisons()}
            {this.renderRequestPermissions()}
            {this.renderAssetPermission()}
            {this.renderMeterPermission()}
            {this.renderSparePartPermission()}
            {this.renderVendorPermission()}
            {this.renderCostPermission()}
            {this.renderPurchaseOrderPermission()}
          </div>
        </Modal.Content>
      </React.Fragment>
    );
  };

  render() {
    const { isSaving, permissionProfile } = this.state;
    const { onClose } = this.props;
    return (
      <Modal isOpen={this.props.open} width={550}>
        <Modal.Header
          title={
            this.props.id == null ? (
              <FormattedMessage id="screens.users.permission-profiles.permission.new-title" />
            ) : (
              <FormattedMessage id="screens.users.permission-profiles.permission.edit-title" />
            )
          }
          ignoreLine
          onClose={this.closeModal}
          iconButtons={this.props.id == null ? null : this.renderDeleteInlineModal()}
        />
        {this.renderContent()}
        <Modal.Footer container>
          <Button.Group>
            <Button
              small
              primary
              disabled={!permissionProfile.title}
              loading={isSaving}
              onClick={this.save}
              label="general.save"
            />
            <Button small label="general.cancel" onClick={onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    permissionProfile: EntitySelectors.getPermissionProfile(state, ownProps.id),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    settings: AuthSelectors.getSettings(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePermissionProfile: SDKReduxOperations.deletePermissionProfile,
      updatePermissionProfile: SDKReduxOperations.updatePermissionProfile,
      createPermissionProfile: SDKReduxOperations.createPermissionProfile,
      setFieldErrors: ErrorOperations.setFieldErrors,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditPermissionProfileModal));
