import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Datapill } from 'views/components/Shared/General';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class ActiveRecurrenceVersionDataPillContainer extends Component {
  getActiveRecurrenceVersion = () => {
    const today = moment().tz(this.props.system.timezone);

    return this.props.operationalMaintenanceTemplateRecurrenceVersions.find(version => {
      const from_date =
        version.generated_from_date == null || moment(version.generated_from_date).isSameOrBefore(today);

      const to_date =
        version.generated_to_date == null || moment(version.generated_to_date).isSameOrAfter(today);

      return from_date && to_date;
    });
  };

  render() {
    let dataPillComponent = null;
    const { operationalMaintenanceTemplateRecurrences } = this.props;
    if (operationalMaintenanceTemplateRecurrences.length > 1) {
      dataPillComponent = (
        <Datapill
          orange
          value={
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.has-many-recurrences" />
          }
        />
      );
    } else if (operationalMaintenanceTemplateRecurrences.length === 1 && this.getActiveRecurrenceVersion()) {
      dataPillComponent = (
        <Datapill
          orange
          value={<span>{buildIntervalMessage(this.getActiveRecurrenceVersion(), this.props.intl)}</span>}
        />
      );
    } else {
      dataPillComponent = (
        <Datapill
          gray
          value={
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.no-recurrence" />
          }
        />
      );
    }
    return <Datapill.List>{dataPillComponent}</Datapill.List>;
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateAssetId } = ownProps;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplateAssetId
  );
  const operationalMaintenanceTemplateRecurrences =
    EntitySelectors.getOperationalMaintenanceTemplateRecurrences(
      state,
      operationalMaintenanceTemplateAsset.operational_maintenance_template_recurrences
    );
  let operationalMaintenanceTemplateRecurrenceVersions = [];
  if (operationalMaintenanceTemplateRecurrences && operationalMaintenanceTemplateRecurrences.length === 1) {
    operationalMaintenanceTemplateRecurrenceVersions =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersions(
        state,
        operationalMaintenanceTemplateRecurrences[0].operational_maintenance_template_recurrence_versions
      );
  }

  return {
    system: AuthSelectors.getCurrentSystem(state),
    operationalMaintenanceTemplateRecurrences,
    operationalMaintenanceTemplateRecurrenceVersions,
  };
}

export default injectIntl(connect(mapStateToProps)(ActiveRecurrenceVersionDataPillContainer));
