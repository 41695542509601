import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import ApplyFilter from './ApplyFilter';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from './AppliedFilters';

export const FILTER_TYPES = {
  Asset: 'asset_id',
  AssetWithTreeChildren: 'asset_with_tree_children_id',
  WorkOrderTitle: 'title',
  WorkOrderDescription: 'description',
  Status: 'status',
  DueDate: 'due_date',
  Assignee: 'assignee',
  WorkOrderType: 'work_order_type_id',
  EstimatedMinutes: 'estimated_minutes',
  Priority: 'priority',
  CreatedBy: 'created_by_user_id',
  CreatedAt: 'created_at',
  AssetType: 'asset_type_id',
  WorkOrderSpentTimeDate: 'work_order_spent_time_date',
  WorkOrderSpentTimeCreatedBy: 'work_order_spent_time_created_by',
  WorkOrderSpentTimeComment: 'work_order_spent_time_comment',
  CompletedBy: 'completed_by_user_id',
  CompletedAt: 'completed_date',
  PausedBy: 'paused_by_user_id',
  PausedComment: 'paused_comment',
  InProgressBy: 'in_progress_by_user_id',
  CompletedComment: 'completed_comment',
  UsedSparePart: 'used_spare_part_id',
  RecurringMaintenance: 'recurring_maintenance_id',
  CompletedBeforeRegistration: 'completed_before_registration',
  Request: 'request_id',
  RequestType: 'request_type_id',
  Interval: 'recurring_interval',
  Category: 'category',
};

class FilterModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open) {
      this.props.selectFilterType(null);
      if (this.props.isFiltering) {
        this.props.showAppliedFilters();
      } else {
        this.props.showAvailableFilters();
      }
    }
  }

  /*
    Helper functions
  */

  close = params => {
    this.props.onClose(params);
  };

  /*
    Rener functions
  */

  renderContent = () => {
    if (this.props.selectedFilterType) {
      return <ApplyFilter />;
    }
    if (this.props.showingAppliedFilters) {
      return <AppliedFilters onClose={() => this.close()} />;
    }
    return <AvailableFilters onClose={() => this.close()} />;
  };

  renderFooter = () => {
    if (this.props.showingAppliedFilters) {
      return (
        <Button.Group>
          <Button
            primary
            onClick={() => {
              this.close(this.props.filtersAsQueryParams);
            }}
            label="screens.work-orders.filters.advanced.title"
          />
          <Button
            translate={false}
            label={<FormattedMessage id="general.clean" />}
            onClick={() => {
              this.props.resetFilter();
              this.close();
            }}
          />
        </Button.Group>
      );
    }
    return null;
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        width={485}
        footerComponent={this.renderFooter()}
        onClose={() => this.close(this.props.filtersAsQueryParams)}
      >
        <SideModal.Container>{this.renderContent()}</SideModal.Container>
      </SideModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: WorkOrdersOperations.selectFilterType,
      resetFilter: WorkOrdersOperations.resetFilter,
      showAppliedFilters: WorkOrdersOperations.showAppliedFilters,
      showAvailableFilters: WorkOrdersOperations.showAvailableFilters,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filtersAsQueryParams: WorkOrdersSelectors.getFiltersAsQueryParams(state),
    selectedFilterType: WorkOrdersSelectors.getSelectedFilterType(state),
    showingAppliedFilters: WorkOrdersSelectors.showingAppliedFilters(state),
    filters: WorkOrdersSelectors.getFilters(state),
    isFiltering: WorkOrdersSelectors.isFiltering(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
