import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class CurrencyRateUpgradeProModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.open} width={450}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.currency-rate-basic-modal.title" />}
          subtitle={<FormattedMessage id="components.currency-rate-basic-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div className={styles['basic-currency-example']}>
            <span>
              1 <FormattedMessage id="currencies.SEK" />
            </span>
            <Icon type="arrow-alt-right" />
            <span>
              0.091 <FormattedMessage id="currencies.EUR" />
            </span>
          </div>
          <div className={styles['basic-currency-example']}>
            <span>
              1 <FormattedMessage id="currencies.USD" />
            </span>
            <Icon type="arrow-alt-right" />
            <span>
              0.81 <FormattedMessage id="currencies.GBP" />
            </span>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button upgradeToPro label="general.upgrade-to-pro" />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
