import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizePurchaseOrderShippingAddress } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createPurchaseOrderShippingAddress(systemId, params) {
  return dispatch => {
    return API.createPurchaseOrderShippingAddress(systemId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderShippingAddress(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_SHIPPING_ADDRESS_CREATED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updatePurchaseOrderShippingAddress(purchaseOrderShippingAddressId, params) {
  return dispatch => {
    return API.updatePurchaseOrderShippingAddress(purchaseOrderShippingAddressId, params)
      .then(res => {
        const { entities } = normalizePurchaseOrderShippingAddress(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { purchaseOrderShippingAddressId, params, data: res.data };
        dispatch({ type: types.PURCHASE_ORDER_SHIPPING_ADDRESS_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deletePurchaseOrderShippingAddress(shippingAddressId) {
  return (dispatch, getState) => {
    return API.deletePurchaseOrderShippingAddress(shippingAddressId)
      .then(res => {
        const payload = { shippingAddressId };
        dispatch({ type: types.PURCHASE_ORDER_SHIPPING_ADDRESS_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
