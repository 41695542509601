import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import {
  normalizeUser,
  normalizeWorkOrder,
  normalizeGroup,
  normalizeVendor,
  normalizeCalendarInstance,
  normalizeRequest,
} from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import actions from './actions';

const fetchInstancesRequest = SDKHelperFunctions.getCancelTokenForRequest();

const addAssigneesForInstance = actions.addAssigneesForInstance;
const removeAssigneesFromInstance = actions.removeAssigneesFromInstance;
const showPreviewWorkOrderOverlay = actions.showPreviewWorkOrderOverlay;
const hidePreviewWorkOrderOverlay = actions.hidePreviewWorkOrderOverlay;
const showPreviewRequestOverlay = actions.showPreviewRequestOverlay;
const hidePreviewRequestOverlay = actions.hidePreviewRequestOverlay;
const updateInstance = actions.updateInstance;
const addAssigneeToCalendarInstance = actions.addAssigneeToCalendarInstance;
const hoverCalendarInstance = actions.hoverCalendarInstance;
const setCounts = actions.setCounts;
const beginDrag = actions.beginDrag;
const endDrag = actions.endDrag;
const beginDragFromToolbox = actions.beginDragFromToolbox;
const endDragFromToolbox = actions.endDragFromToolbox;
const showListBar = actions.showListBar;
const hideListBar = actions.hideListBar;
const addInstancesForCalendar = actions.addInstancesForCalendar;
const removeInstancesFromCalendar = actions.removeInstancesFromCalendar;

const fetchWorkOrdersToPlanAndIsMeterBased = (systemId, config) => dispatch => {
  const params = {
    meter_value_reached: true,
    due_date: {
      [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
    },
    status: {
      [SDKHelperFunctions.FILTER_COMPARABLES.NoneOf]: WorkOrderStatus.Completed,
    },
    no_pagination: true,
  };
  return API.listWorkOrders(systemId, params, config).then(({ data: workOrders }) => {
    const { result: ids, entities } = normalizeWorkOrder(workOrders);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchMeterBasedWorkOrdersToPlanSuccess({
        ids,
      })
    );
  });
};

const fetchWorkOrdersToPlan = (systemId, params, config) => dispatch => {
  return API.listWorkOrders(systemId, { ...params, to_plan_in_calendar: true, page_size: 20 }, config).then(
    ({ data: workOrders, headers }) => {
      const { result: ids, entities } = normalizeWorkOrder(workOrders);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchWorkOrdersToPlanSuccess({
          ids,
          paginateFrom: headers['paginate-from-token'] == null ? null : headers['paginate-from-token'],
        })
      );
    }
  );
};

const fetchOverdueWorkOrders = (systemId, params, config) => dispatch => {
  const attrs = {
    ...params,
    sort: 'due_date',
    'sort-order': 'asc',
    page_size: 20,
    is_overdue: true,
  };
  return API.listWorkOrders(systemId, attrs, config).then(({ data: workOrders, headers }) => {
    const { result: ids, entities } = normalizeWorkOrder(workOrders);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchOverdueWorkOrdersSuccess({
        ids,
        paginateFrom: headers['paginate-from-token'] == null ? null : headers['paginate-from-token'],
      })
    );
  });
};

const fetchWorkOrdersAwaitingDelivery = (systemId, params, config) => dispatch => {
  return API.listWorkOrders(systemId, { ...params, page_size: 20 }, config).then(
    ({ data: workOrders, headers }) => {
      const { result: ids, entities } = normalizeWorkOrder(workOrders);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchWorkOrdersAwaitingDeliverySuccess({
          ids,
          paginateFrom: headers['paginate-from-token'] == null ? null : headers['paginate-from-token'],
        })
      );
    }
  );
};

const fetchRequests = (systemId, params, config) => dispatch => {
  return API.listRequests(systemId, { ...params, page_size: 20, status: 'active' }, config).then(
    ({ data: requests, headers }) => {
      const { result: ids, entities } = normalizeRequest(requests);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchRequestsSuccess({
          ids,
          paginateFrom: headers['paginate-from-token'] == null ? null : headers['paginate-from-token'],
        })
      );
    }
  );
};

const fetchAssignees = systemId => dispatch => {
  return API.getAssignees(systemId)
    .then(({ data: { users, vendors, groups } }) => {
      const { entities: userEntities, result: userIds } = normalizeUser(users);
      const { entities: groupEntities, result: groupIds } = normalizeGroup(groups);
      const { entities: vendorEntities, result: vendorIds } = normalizeVendor(vendors);
      dispatch(EntityOperations.updateEntities({ ...userEntities, ...groupEntities, ...vendorEntities }));
      dispatch(actions.setAssignees({ userIds, groupIds, vendorIds }));
    })
    .catch(() => {});
};

const fetchInstancesForDates =
  (systemId, params, options = { resetCalendar: true }) =>
  dispatch => {
    return API.showCalendar(systemId, params, fetchInstancesRequest.getCancelTokenConfig())
      .then(({ data }) => {
        const { instances } = data;
        const instancesWithAssignees = instances.filter(
          ({ groups, users, vendors }) => groups.length > 0 || users.length > 0 || vendors.length > 0
        );
        const { entities } = normalizeCalendarInstance(
          instancesWithAssignees.map(instance => {
            let id = null;
            if (instance.type === 'work_order') {
              id = instance.work_order.id;
            } else {
              id = `${instance.recurring_maintenance_date}_${instance.recurring_maintenance.id}`;
            }
            return {
              ...instance,
              id,
            };
          })
        );
        const { calendarInstanceById, vendorById } = entities;
        dispatch(EntityOperations.updateEntities(entities));
        const { from, to } = params;
        const { resetCalendar } = options;
        dispatch(
          actions.fetchDataBetweenDatesSuccess({
            fromDate: from,
            toDate: to,
            instancesById: calendarInstanceById,
            vendorIds: vendorById == null ? [] : Object.keys(vendorById),
            resetCalendar,
          })
        );
      })
      .catch(() => {});
  };

export default {
  fetchWorkOrdersAwaitingDelivery,
  addInstancesForCalendar,
  removeInstancesFromCalendar,
  fetchRequests,
  showListBar,
  hideListBar,
  beginDrag,
  endDrag,
  addAssigneesForInstance,
  removeAssigneesFromInstance,
  hidePreviewWorkOrderOverlay,
  showPreviewWorkOrderOverlay,
  showPreviewRequestOverlay,
  hidePreviewRequestOverlay,
  hoverCalendarInstance,
  fetchInstancesForDates,
  fetchWorkOrdersToPlan,
  fetchOverdueWorkOrders,
  fetchAssignees,
  addAssigneeToCalendarInstance,
  updateInstance,
  setCounts,
  beginDragFromToolbox,
  endDragFromToolbox,
  fetchWorkOrdersToPlanAndIsMeterBased,
};
