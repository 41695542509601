import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Icon, Button as GeneralButton } from 'views/components/Shared/General';
import { AssetTitle, NewSelectAssetModal, ManageBookmarkedAssetsModal } from 'views/components/Asset';
import {
  BookmarkedAssetsDropdownOperations,
  BookmarkedAssetsDropdownSelectors,
  BookmarkedAssetsDropdownUtils,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import { EntityOperations } from 'sdk/State/entities';

class BookmarkedAssetsDropdown extends Component {
  inlineModalPositioningRef = null;

  state = {
    open: false,
    isFetching: false,
    showSelectAssetModal: false,
    showMangeBookmarkedAssetsModal: false,
  };

  toggle = () => {
    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => {
        if (this.state.open) {
          this.fetchBookmarkedAssets();
        }
      }
    );
  };

  fetchBookmarkedAssets = () => {
    this.setState({ isFetching: true });
    this.props.fetchBookmarkedAssets(this.props.system.id).then(({ data: assets }) => {
      this.setState({ isFetching: false });
    });
  };

  renderSelectedAnotherAsset = () => {
    if (
      this.props.selectedAssetId &&
      this.props.bookmarkedAssets.find(ba => ba.asset_id === this.props.selectedAssetId) == null
    ) {
      return (
        <NewInlineModal.Dropdown.Item selected>
          <div className={styles['asset']}>
            <AssetTitle id={this.props.selectedAssetId} />
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }

    return (
      <NewInlineModal.Dropdown.Items>
        <NewInlineModal.Dropdown.Item
          selected={this.props.selectedDropdownType === BookmarkedAssetsDropdownUtils.MenuItem.MyAssets}
          onClick={() => {
            this.setState({ open: false });
            this.props.selectDropdownType(BookmarkedAssetsDropdownUtils.MenuItem.MyAssets);
          }}
        >
          <FormattedMessage id="screens.operational-maintenances.asset-dropdown.my-assets" />
        </NewInlineModal.Dropdown.Item>
        {this.props.bookmarkedAssets.map(({ asset_id }) => (
          <NewInlineModal.Dropdown.Item
            selected={this.props.selectedAssetId === asset_id}
            onClick={() => {
              this.setState({ open: false });
              this.props.selectAssetId(asset_id);
            }}
          >
            <div className={styles['asset']}>
              <AssetTitle id={asset_id} />
            </div>
          </NewInlineModal.Dropdown.Item>
        ))}
        <NewInlineModal.Dropdown.Item
          onClick={() => this.setState({ open: false, showMangeBookmarkedAssetsModal: true })}
        >
          <span className={styles['subtitle']}>
            <FormattedMessage id="screens.operational-maintenances.asset-dropdown.manage-my-assets" />
          </span>
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Separator />
        <NewInlineModal.Dropdown.Item
          selected={this.props.selectedDropdownType === BookmarkedAssetsDropdownUtils.MenuItem.AllAssets}
          onClick={() => {
            this.setState({ open: false });
            this.props.selectDropdownType(BookmarkedAssetsDropdownUtils.MenuItem.AllAssets);
          }}
        >
          <FormattedMessage id="screens.operational-maintenances.asset-dropdown.all-assets" />
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ open: false, showSelectAssetModal: true });
          }}
        >
          <FormattedMessage id="screens.operational-maintenances.asset-dropdown.select-another-asset" />
        </NewInlineModal.Dropdown.Item>
        {this.renderSelectedAnotherAsset()}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderLabel = () => {
    if (this.props.selectedDropdownType === BookmarkedAssetsDropdownUtils.MenuItem.MyAssets) {
      return <FormattedMessage id="screens.operational-maintenances.asset-dropdown.my-assets" />;
    }
    if (this.props.selectedDropdownType === BookmarkedAssetsDropdownUtils.MenuItem.AllAssets) {
      return <FormattedMessage id="screens.operational-maintenances.asset-dropdown.all-assets" />;
    }
    return <AssetTitle id={this.props.selectedAssetId} />;
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        title={<FormattedMessage id="screens.users.action-dropdown.suggest-checked-in-asset" />}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={asset => {
          return (
            <GeneralButton
              gray
              small
              label="general.choose"
              onClick={e => {
                e.stopPropagation();
                this.setState({ showSelectAssetModal: false });
                this.props.selectAssetId(asset.id);
              }}
            />
          );
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  renderBookmarkedAssetModal = () => {
    return (
      <ManageBookmarkedAssetsModal
        open={this.state.showMangeBookmarkedAssetsModal}
        onClose={() => this.setState({ showMangeBookmarkedAssetsModal: false })}
      />
    );
  };

  render() {
    let classNames = [styles['my-assets']];
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    return (
      <>
        <div
          className={classNames.join(' ')}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.toggle();
          }}
        >
          <div>{this.renderLabel()}</div>
          <div className={styles['icon-container']}>
            <Icon type="caret-down" />
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          minWidth={335}
        >
          <>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </>
        </NewInlineModal>
        {this.renderSelectAssetModal()}
        {this.renderBookmarkedAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      fetchBookmarkedAssets: BookmarkedAssetsDropdownOperations.fetchBookmarkedAssets,
      selectDropdownType: BookmarkedAssetsDropdownOperations.selectDropdownType,
      selectAssetId: BookmarkedAssetsDropdownOperations.selectAssetId,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    selectedDropdownType: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    selectedAssetId: BookmarkedAssetsDropdownSelectors.getAssetId(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkedAssetsDropdown);
