import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Address } from 'views/components/General';
import { Button, Field, NewInlineModal } from 'views/components/Shared/General';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SettingsSelectors, SettingsOperations } from 'state/ducks/settings';
import { AuthSelectors } from 'state/ducks/auth';

class ChooseAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurchaseOrderShippingAddressId: null,
      selectedOtherAddress: false,
      selectedAddress: this.props.address,
      otherAddress: this.props.address,
      isSaving: false,
      isFetching: false,
      chooseAddressInlineIsModalOpen: false,
    };
  }

  getEmptyAddress = () => ({
    name: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: '',
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true }, () => {
        this.props.fetchPurchaseOrderShippingAdresses(this.props.currentSystem.id).then(() => {
          this.findMatchingPurchaseOrderShippingAddress();
          this.setState({ isFetching: false });
        });
      });
    }
  }

  findMatchingPurchaseOrderShippingAddress = () => {
    let matchingAddress = this.props.shippingAddresses.find(address =>
      this.addressesEqual(this.props.address, address)
    );
    if (matchingAddress) {
      this.setState({
        selectedPurchaseOrderShippingAddressId: matchingAddress.id,
        selectedOtherAddress: false,
        selectedAddress: this.props.address,
        otherAddress: this.getEmptyAddress(),
      });
    } else {
      this.setState({
        selectedOtherAddress: true,
        selectedAddress: this.getEmptyAddress(),
        otherAddress: this.props.address,
      });
    }
  };

  addressesEqual = (addrObj1, addrObj2) => {
    return (
      addrObj1.name === addrObj2.name &&
      addrObj1.address1 === addrObj2.address1 &&
      addrObj1.address2 === addrObj2.address2 &&
      addrObj1.zip === addrObj2.zip &&
      addrObj1.city === addrObj2.city &&
      addrObj1.country === addrObj2.country
    );
  };

  save = () => {
    if (this.state.selectedOtherAddress) {
      this.props.onSave(this.state.otherAddress);
    } else {
      this.props.onSave(this.state.selectedAddress);
    }
  };

  close = () => {
    this.props.onClose();
  };

  setOtherAddress = obj => {
    const newOtherAddress = {
      ...this.state.otherAddress,
      ...obj,
    };

    this.setState({
      otherAddress: newOtherAddress,
    });
  };

  renderCurrentAddress = () => {
    if (this.props.shippingAddresses.length == 0) {
      return null;
    }

    if (this.state.selectedOtherAddress) {
      return (
        <FormattedMessage id="screens.purchase-order.info.our-information.edit-shipping-address-modal.other" />
      );
    }

    return (
      <Address
        name={this.state.selectedAddress.name}
        address1={this.state.selectedAddress.address1}
        address2={this.state.selectedAddress.address2}
        zip={this.state.selectedAddress.zip}
        city={this.state.selectedAddress.city}
        country={this.state.selectedAddress.country}
      />
    );
  };

  renderOtherAddressFields = () => {
    return (
      <Grid>
        {this.props.shippingAddresses.length == 0 ? null : <Grid.Separator />}
        <Grid.Row>
          <Grid.Column>
            <Field view={false} label={<FormattedMessage id="resources.system.address-name" />}>
              <Field.Text
                autoFocus
                value={this.state.otherAddress.name}
                onChange={name => {
                  this.setOtherAddress({ name });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view={false} label={<FormattedMessage id="resources.system.address-address1" />}>
              <Field.Text
                value={this.state.otherAddress.address1}
                onChange={address1 => {
                  this.setOtherAddress({ address1 });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view={false} label={<FormattedMessage id="resources.system.address-address2" />}>
              <Field.Text
                value={this.state.otherAddress.address2}
                onChange={address2 => {
                  this.setOtherAddress({ address2 });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column md={4}>
            <Field view={false} label={<FormattedMessage id="resources.system.address-zip" />}>
              <Field.Text
                value={this.state.otherAddress.zip}
                onChange={zip => {
                  this.setOtherAddress({ zip });
                }}
              />
            </Field>
          </Grid.Column>
          <Grid.Column md={8}>
            <Field view={false} label={<FormattedMessage id="resources.system.address-city" />}>
              <Field.Text
                value={this.state.otherAddress.city}
                onChange={city => {
                  this.setOtherAddress({ city });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view={false} label={<FormattedMessage id="resources.system.address-country" />}>
              <Field.Text
                value={this.state.otherAddress.country}
                onChange={country => {
                  this.setOtherAddress({ country });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderChooseAddressInlineModalOtherAddress = () => {
    return (
      <>
        <NewInlineModal.Dropdown.Separator />
        <NewInlineModal.Dropdown.Item
          selected={!this.state.selectedPurchaseOrderShippingAddressId}
          leftComponent={
            <FormattedMessage id="screens.purchase-order.info.our-information.edit-shipping-address-modal.footer" />
          }
          clickable
          onClick={() => {
            this.setState({
              chooseAddressInlineIsModalOpen: false,
              selectedPurchaseOrderShippingAddressId: null,
              selectedOtherAddress: true,
            });
          }}
        />
      </>
    );
  };

  renderChooseAddressInlineModal = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              chooseAddressInlineIsModalOpen: !prevState.chooseAddressInlineIsModalOpen,
            }));
          }}
        >
          <Field.Resource clearable={false} value={this.renderCurrentAddress()} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.chooseAddressInlineIsModalOpen}
          onClose={e => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.props.shippingAddresses.map(address => (
                <NewInlineModal.Dropdown.Item
                  selected={address.id == this.state.selectedPurchaseOrderShippingAddressId}
                  clickable
                  onClick={() => {
                    this.setState({
                      chooseAddressInlineIsModalOpen: false,
                      selectedAddress: address,
                      selectedPurchaseOrderShippingAddressId: address.id,
                      selectedOtherAddress: false,
                    });
                  }}
                  leftComponent={
                    <div style={{ 'padding-top': 3, 'padding-bottom': 3 }}>
                      <Address
                        name={address.name}
                        address1={address.address1}
                        address2={address.address2}
                        zip={address.zip}
                        city={address.city}
                        country={address.country}
                      />
                    </div>
                  }
                />
              ))}
              {this.renderChooseAddressInlineModalOtherAddress()}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return <Modal.Loader />;
    }

    return (
      <React.Fragment>
        <Modal.Content>
          {this.props.shippingAddresses.length == 0 ? null : this.renderChooseAddressInlineModal()}
          {this.state.selectedOtherAddress ? this.renderOtherAddressFields() : null}
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.close} />
          </Button.Group>
        </Modal.Footer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <>
        <Modal ignoreLine isOpen={this.props.open} width={560}>
          <Modal.Header
            title={
              <FormattedMessage id="screens.purchase-order.info.our-information.edit-shipping-address-modal.title" />
            }
            subtitle={
              this.props.shippingAddresses.length == 0 ? null : (
                <FormattedMessage id="screens.purchase-order.info.our-information.edit-shipping-address-modal.subtitle" />
              )
            }
            onClose={this.props.onClose}
          />
          {this.renderContent()}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrderShippingAdresses: SettingsOperations.fetchPurchaseOrderShippingAddresses,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    shippingAddresses: SettingsSelectors.getPurchareOrderShippingAddresses(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseAddressModal));
