import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import EditAddressModal from './EditAddressModal';
import styles from './style.module.scss';

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: false,
    };
  }

  hasNoAddress = () => {
    const { address1, address2, zip, city, country } = this.props.vendor;
    return !address1 && !address2 && !zip && !city && !country;
  };

  renderAddressData = () => {
    const { address1, address2, zip, city, country } = this.props.vendor;
    return (
      <React.Fragment>
        {address1 ? <p>{address1}</p> : null}
        {address2 ? <p>{address2}</p> : null}
        {zip || city ? (
          <p>
            {zip ? <span>{zip} </span> : null}
            {city ? <span>{city}</span> : null}
          </p>
        ) : null}
        {country ? <p>{country}</p> : null}
      </React.Fragment>
    );
  };

  renderNoAddress = () => (
    <span className={styles['no-address']}>
      <FormattedMessage id="screens.vendor.information.address.no-adress-title" />
    </span>
  );

  renderContent = () => {
    let classNames = [styles['whitecard-wrapper']];
    if (this.props.canEditVendors) {
      classNames = [...classNames, styles['clickable']];
    }
    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          if (this.props.canEditVendors) {
            this.setState({ showAddressModal: true });
          }
        }}
      >
        {this.hasNoAddress() ? this.renderNoAddress() : this.renderAddressData()}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderContent()}
        <EditAddressModal
          vendor={this.props.vendor}
          open={this.state.showAddressModal}
          onClose={() => this.setState({ showAddressModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const vendorId = ownProps.match.params.id;

  return {
    vendor: EntitySelectors.getVendor(state, vendorId),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Address)));
