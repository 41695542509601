/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getUsers = state => {
  const { organisation, entities } = state;
  return organisation.ids.map(id => entities.userById[id]);
};

const getAmountOfPayingMembers = state => {
  const { organisation } = state;
  return organisation.amountOfPayingMembers;
};
/*
  --------------------
    UI SELECTORS
  --------------------
*/
const getTotalEntries = state => state.organisation.totalEntries;
const getPagination = state => state.organisation.pagination;
const getQueryParameters = state => {
  const { organisation } = state;
  return organisation.queryParams;
};

export default {
  getUsers,
  getPagination,
  getAmountOfPayingMembers,
  getTotalEntries,
  getQueryParameters,
};
