import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { InlineModal, WarningMessage } from 'views/components/Shared/General';

class Delete extends Component {
  state = {
    isDeleting: false,
    showHasSparePartsError: false,
  };

  deleteSparePartLocation = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteSparePartLocation(this.props.sparePartLocation.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="screens.settings.spare-parts.storage.deleted-success-message"
                values={{ title: this.props.sparePartLocation.title }}
              />
            }
          />
        );
        this.props.onDeleteSparePartLocation();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
        if (HelperFunctions.hasError(e, { code: '100006' })) {
          this.setState({ showHasSparePartsError: true });
        }
      });
  };

  renderContent = () => {
    if (this.state.showHasSparePartsError) {
      return (
        <WarningMessage type="destructive">
          <FormattedMessage id="components.move-spare-part-location-inline-modal.delete.has-spare-parts-error" />
        </WarningMessage>
      );
    } else {
      return (
        <InlineModal.Delete
          loading={this.state.isDeleting}
          title="components.move-spare-part-location-inline-modal.delete.title"
          onDelete={this.deleteSparePartLocation}
        >
          <FormattedMessage id="components.move-spare-part-location-inline-modal.delete.description" />
        </InlineModal.Delete>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <InlineModal.Header
          title={<FormattedMessage id="components.move-spare-part-location-inline-modal.delete.title" />}
          onClose={this.props.onClose}
        />
        <InlineModal.Body width={300}>{this.renderContent()}</InlineModal.Body>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePartLocation: SDKReduxOperations.deleteSparePartLocation,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(Delete));
