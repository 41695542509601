import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import {
  List,
  Button,
  MoneyWithCurrency,
  Icon,
  Tooltip,
  NewInlineModal,
} from 'views/components/Shared/General';
import { MakeVendorPrimaryModal, RemoveVendorAsPrimaryModal } from 'views/components/SparePartVendor';
import { SparePartUnitWithValue, SparePartListItemExpandable } from 'views/components/SparePart';
import { VendorSelectors } from 'state/ducks/vendor';
import { Link } from 'react-router-dom';

class SparePartListItem extends Component {
  state = {
    dropdownOpen: false,
    showMakePrimaryVendorModal: false,
    showRemoveVendorAsPrimaryModal: false,
  };

  renderArticleNumber = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      if (sparePartVendor.number) {
        return (
          <>
            <span>{`#${sparePartVendor.number}`}</span>
            <span> - </span>
          </>
        );
      }
    }

    return null;
  };

  renderArticleTitle = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      const title = sparePartVendor.title || this.props.sparePart.title;
      return <span>{title}</span>;
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.sparePartVendors.length === 1) {
      return (
        <>
          {this.renderArticleNumber()}
          {this.renderArticleTitle()}
        </>
      );
    }
    return (
      <FormattedPlural
        value={this.props.sparePartVendors.length}
        two={
          <FormattedMessage
            id="screens.vendor.spare-parts.amount-of-options.two"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        few={
          <FormattedMessage
            id="screens.vendor.spare-parts.amount-of-options.few"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        many={
          <FormattedMessage
            id="screens.vendor.spare-parts.amount-of-options.many"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        other={
          <FormattedMessage
            id="screens.vendor.spare-parts.amount-of-options.other"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
      />
    );
  };

  renderPrice = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      if (sparePartVendor.purchase_price) {
        return (
          <List.Item.Column alignRight>
            <MoneyWithCurrency
              value={sparePartVendor.purchase_price}
              currency={this.props.vendor.purchase_order_currency}
            />
          </List.Item.Column>
        );
      }
    }
    return <List.Item.Column alignRight>-</List.Item.Column>;
  };

  renderPrimary = () => {
    const primary = this.props.sparePart.primary_vendor_id === this.props.match.params.id;
    return (
      <List.Item.Column alignRight>
        {primary ? (
          <Tooltip
            label={<FormattedMessage id="resources.spare-part.primary-vendor" />}
            trigger={<Icon withBackground type="briefcase" backgroundSize={22} green={primary} size={10} />}
          />
        ) : (
          <Icon withBackground type="briefcase" backgroundSize={22} green={primary} size={10} />
        )}
      </List.Item.Column>
    );
  };

  renderPackageQuantity = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        return (
          <List.Item.Column alignRight>
            <Tooltip
              label={
                <>
                  <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                  <span>: </span>
                  <SparePartUnitWithValue
                    sparePartUnit={this.props.sparePartUnit}
                    value={sparePartVendor.package_quantity}
                  />
                </>
              }
              trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  renderIconButtons = () => {
    if (this.props.sparePart.primary_vendor_id === this.props.vendor.id) {
      return (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            open={this.state.dropdownOpen}
            minWidth={235}
            positionToRef={this.inlineModalPositioningRef}
            onClose={() => this.setState({ dropdownOpen: false })}
            position="right"
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                {this.props.canEditSpareParts && this.props.canEditVendors ? (
                  <>
                    <NewInlineModal.Dropdown.Item
                      onClick={() => {
                        this.setState({ showRemoveVendorAsPrimaryModal: true, dropdownOpen: false });
                      }}
                    >
                      <FormattedMessage id="screens.vendor.spare-parts.remove-primary" />
                    </NewInlineModal.Dropdown.Item>
                    <NewInlineModal.Dropdown.Item
                      onClick={() => {
                        this.setState({ dropdownOpen: false });
                        this.props.onAddArticle();
                      }}
                    >
                      <FormattedMessage id="screens.vendor.spare-parts.add-another-article" />
                    </NewInlineModal.Dropdown.Item>
                    <NewInlineModal.Dropdown.Separator />
                  </>
                ) : null}
                <Link to={`/spare-parts/${this.props.sparePart.id}`}>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({ dropdownOpen: false });
                      this.props.onAddArticle();
                    }}
                  >
                    <FormattedMessage id="screens.vendor.spare-parts.go-to-spare-part" />
                  </NewInlineModal.Dropdown.Item>
                </Link>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          open={this.state.dropdownOpen}
          minWidth={235}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.props.canEditSpareParts && this.props.canEditVendors ? (
                <>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showMakePrimaryVendorModal: true, dropdownOpen: false });
                    }}
                  >
                    <FormattedMessage id="screens.vendor.spare-parts.make-primary" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ dropdownOpen: false });
                      this.props.onAddArticle();
                    }}
                  >
                    <FormattedMessage id="screens.vendor.spare-parts.add-another-article" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Separator />
                </>
              ) : null}
              <Link to={`/spare-parts/${this.props.sparePart.id}`}>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({ dropdownOpen: false });
                    this.props.onAddArticle();
                  }}
                >
                  <FormattedMessage id="screens.vendor.spare-parts.go-to-spare-part" />
                </NewInlineModal.Dropdown.Item>
              </Link>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={() => {
                  this.props.onDelete(this.props.sparePartVendors);
                  this.setState({ dropdownOpen: false });
                }}
              >
                <FormattedMessage id="general.delete" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <>
          <List.Item
            expandable
            expandedComponent={<SparePartListItemExpandable id={this.props.sparePart.id} />}
            clickable
            onClick={() => this.props.onClick(this.props.sparePartVendors)}
            iconButtons={this.renderIconButtons()}
          >
            <List.Item.TitleColumn title={this.props.sparePart.title} subtitle={this.renderSubtitle()} />
            {this.renderPackageQuantity()}
            {this.renderPrice()}
            {this.renderPrimary()}
          </List.Item>
          <MakeVendorPrimaryModal
            open={this.state.showMakePrimaryVendorModal}
            vendorId={this.props.match.params.id}
            sparePartId={this.props.sparePart.id}
            onClose={() => {
              this.setState({ showMakePrimaryVendorModal: false });
            }}
          />
          <RemoveVendorAsPrimaryModal
            open={this.state.showRemoveVendorAsPrimaryModal}
            vendorId={this.props.match.params.id}
            sparePartId={this.props.sparePart.id}
            onClose={() => {
              this.setState({ showRemoveVendorAsPrimaryModal: false });
            }}
          />
        </>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  const sparePartVendorIds = VendorSelectors.getSparePartVendorIdsForSparePart(state, ownProps.id);
  const sparePartVendors = EntitySelectors.getSparePartVendors(state, sparePartVendorIds);
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.match.params.id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    sparePart,
    sparePartVendors,
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartListItem));
