import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Filter } from 'views/components/Shared/General';
import { ChooseAssetInlineModal } from 'views/components/Asset';

class FilterAssetButton extends Component {
  render() {
    return (
      <ChooseAssetInlineModal
        hideCreateButton
        width="300"
        trigger={
          <Filter.Button
            inline
            label="screens.work-orders.filters.quick.asset"
            selectedValueComponent={this.props.asset ? this.props.asset.title : null}
            onClear={this.props.onClear}
          />
        }
        selectedAssetId={this.props.assetId}
        onSelectAsset={this.props.onSelectAsset}
        onClear={this.props.onClear}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: ownProps.assetId ? EntitySelectors.getAsset(state, ownProps.assetId) : null,
  };
}

export default connect(mapStateToProps, null)(FilterAssetButton);
