import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { UserNameWrapper } from 'views/components/User';

class ProductionSupervisorAssetItem extends Component {
  render() {
    return <UserNameWrapper user={this.props.user} />;
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const { user_id } = EntitySelectors.getAssetProductionSupervisor(state, id);
  return {
    user: EntitySelectors.getUser(state, user_id),
  };
}

export default connect(mapStateToProps)(ProductionSupervisorAssetItem);
