import types from './types';

const resetSelectedDowntimes = payload => ({ type: types.RESET_SELECTED_DOWNTMES, payload });

const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const selectDowntime = payload => ({ type: types.SELECT_DOWNTIME, payload });

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchDowntimesSuccess = data => ({
  type: types.FETCH_DOWNTIMES_SUCCESS,
  payload: data,
});
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });

export default {
  fetchDowntimesSuccess,
  addQueryParameter,
  selectPage,
  selectDowntime,
  resetSelectedDowntimes,
  hideSelectTotalEntries,
  selectTotalEntries,
};
