import HelperFunctions from 'utilities/HelperFunctions';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { FILTER_TYPES } from 'views/scenes/SpareParts/SparePartList/FilterModal';

const getNavigatedTo = state => {
  const { spareParts } = state;
  return spareParts.navigatedTo;
};

const getSpareParts = state => {
  const { spareParts, entities } = state;
  if (!spareParts.ids) return null;
  return spareParts.ids.map(id => entities.sparePartById[id]);
};

const getSparePartsPagination = state => state.spareParts.pagination;

/*
  Filter
*/

const getQueryParameters = (state, key) => {
  const { spareParts } = state;
  return spareParts.queryParams;
};

const getFilters = (state, key) => {
  const { spareParts } = state;
  return spareParts.filters;
};

const getFiltersAsQueryParams = (state, key) => {
  const { spareParts } = state;
  let sparePartFilters = { ...spareParts.filters };
  if (spareParts.appliedFilters.includes(FILTER_TYPES.Vendor)) {
    const { comparator, value } = spareParts.filters.spare_part_vendors.vendor_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const { vendor_id, ...sparePartVendorsFilter } = sparePartFilters.spare_part_vendors;
      sparePartFilters = {
        ...sparePartFilters,
        spare_part_vendors: {
          ...sparePartVendorsFilter,
          exists: value,
        },
      };
    }
  }
  return HelperFunctions.buildFilter(sparePartFilters);
};

const getAppliedFilters = (state, key) => {
  const { spareParts } = state;
  return spareParts.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { spareParts } = state;
  return spareParts.filters[key];
};

const isFiltering = (state, key) => {
  const { spareParts } = state;
  return spareParts.appliedFilters.length > 0;
};

const showingAppliedFilters = (state, key) => {
  const { spareParts } = state;
  return spareParts.showingAppliedFilters;
};

const getSelectedFilterType = (state, key) => {
  const { spareParts } = state;
  return spareParts.showApplyFilterForField;
};

/*
  MassEdit
*/

const getSelectedSparePartIds = state => {
  return state.spareParts.selectedSpareParts.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.spareParts.selectedSpareParts.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.spareParts.selectedSpareParts.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.spareParts.selectedSpareParts.pageIsSelected;
};

const getSelectedSparePartCount = state => {
  if (state.spareParts.selectedSpareParts.totalEntriesAreSelected) {
    return state.spareParts.pagination.totalEntries;
  }
  return Object.keys(state.spareParts.selectedSpareParts.ids).length;
};

export default {
  getNavigatedTo,
  getFiltersAsQueryParams,
  getSelectedSparePartIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedSparePartCount,
  getSpareParts,
  getAppliedFilters,
  getSparePartsPagination,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
  getQueryParameters,
  getFiltersForKey,
  getFilters,
};
