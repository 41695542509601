import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Button, Tooltip } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class PreviewRequestHeader extends Component {
  render() {
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            {this.props.request.title}
            <span className={styles['subtitle']}> - #{this.props.request.number}</span>
          </div>
          <div className={styles['buttons-container']}>
            <Tooltip
              trigger={
                <Link to={`/requests/${this.props.request.id}`}>
                  <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.request" />}
            />
            <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
          </div>
        </div>
        {this.props.asset == null ? null : <TreePath size={13} fullPath assetId={this.props.asset.id} />}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const request = EntitySelectors.getRequest(state, ownProps.id);

  return {
    request,
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
    asset: EntitySelectors.getAsset(state, request.asset_id),
    requestType: EntitySelectors.getRequestType(state, request.request_type_id),
    images: EntitySelectors.getImages(state, request.images),
  };
}

export default connect(mapStateToProps)(PreviewRequestHeader);
