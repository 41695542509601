export const PermissionType = {
  Asset: {
    View: 'view',
    Edit: 'edit',
  },
  Vendor: {
    None: 'none',
    View: 'view',
    Edit: 'edit',
  },
  Request: {
    Create: 'create',
    Administrate: 'administrate',
  },
  WorkOrder: {
    CarryOut: 'carry_out',
    Administrate: 'administrate',
  },
  SparePart: {
    None: 'none',
    StockAdjustment: 'stock_adjustments',
    Edit: 'edit',
  },
  PurchaseOrder: {
    None: 'none',
    RegisterDeliveries: 'register_deliveries',
    Edit: 'edit',
  },
  Cost: {
    None: 'none',
    WorkOrder: 'work_order',
    Full: 'full',
  },
  Meters: {
    None: 'none',
    EditReading: 'edit_reading',
    Edit: 'edit',
  },
  OperationalMaintenance: {
    None: 'none',
    View: 'view',
    CarryOut: 'carry_out',
    Administrate: 'administrate',
  },
};
