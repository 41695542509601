import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Decimal } from 'decimal.js';
import { EntitySelectors } from 'sdk/State/entities';
import { SectionHeader } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import SparePartReservationListItem from './SparePartReservationListItem';
import SaveReservedSparePartModal from './SaveReservedSparePartModal';

class ReservedSpareParts extends Component {
  state = {
    showSavedReservdedSparePartModal: false,
    showSavedReservdedSparePartModalForsparePartReservationId: null,
  };

  renderSaveReservdedSparePartModal = () => {
    return (
      <SaveReservedSparePartModal
        open={this.state.showSavedReservdedSparePartModal}
        operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
        sparePartReservationId={this.state.showSavedReservdedSparePartModalForsparePartReservationId}
        onClose={() => this.setState({ showSavedReservdedSparePartModal: false })}
      />
    );
  };

  render() {
    const sparePartReservationsWithQuantityLeft = this.props.sparePartReservations.filter(
      sparePartReservations => {
        const { spare_part_id, quantity } = sparePartReservations;
        const quantityWithdrawedForSparePart = this.props.sparePartWithdrawals
          .filter(sparePartWithdrawal => {
            return sparePartWithdrawal.spare_part_id === spare_part_id;
          })
          .reduce((acc, spw) => acc.add(new Decimal(spw.quantity || 0)), new Decimal(0));
        return new Decimal(quantityWithdrawedForSparePart).lessThan(new Decimal(quantity));
      }
    );
    if (sparePartReservationsWithQuantityLeft.length === 0) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
            <FormattedMessage id="screens.operational-maintenance.reserved-spare-parts.title" />
          </SectionHeader>
          {sparePartReservationsWithQuantityLeft.map(({ id }) => (
            <SparePartReservationListItem
              operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
              id={id}
              onClick={() =>
                this.setState({
                  showSavedReservdedSparePartModal: true,
                  showSavedReservdedSparePartModalForsparePartReservationId: id,
                })
              }
            />
          ))}
        </CardGrid.Row>
        {this.renderSaveReservdedSparePartModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const { operational_maintenance_template_version_id, spare_part_withdrawals } =
    operationalMaintenanceInstance;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operational_maintenance_template_version_id
  );
  return {
    sparePartReservations: EntitySelectors.getSparePartReservations(
      state,
      operationalMaintenanceTemplateVersion.spare_part_reservations
    ),
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawals),
  };
}

export default connect(mapStateToProps)(ReservedSpareParts);
