import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { SDKReduxOperations, API } from 'sdk';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import { buildReduxChecklistRowDataWithInstanceRows } from 'sdk/ChecklistTemplate';
import { Button, NewInlineModal, Icon } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ChecklistOptionsModal } from 'views/components/Checklist';
import { ChecklistModalOperations, ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showMoreOptionsDropdown: false,
    isUpdatingChecklistVersion: false,
    showUpdateChecklistVerisonConfirmModal: false,
    showChecklistOptionsModal: false,
    isSavingOptions: false,
  };

  batchUpdateChecklistOptions = params => {
    const { checklist_instance_options } = params;
    this.setState({ isSavingOptions: true });

    let requestsToWaitFor = checklist_instance_options.map(option => {
      const { meter_id, checklist_template_row_id, type, id } = option;
      if (id) {
        return this.props.updateChecklistInstanceOption(id, {
          meter_id,
        });
      } else {
        return this.props.createForChecklistInstance(this.props.checklistInstance.id, {
          meter_id,
          checklist_template_row_id,
          type,
        });
      }
    });
    return Promise.all(requestsToWaitFor).then(data => {
      API.fetchChecklistInstance(this.props.checklistInstance.id).then(({ data: checklistInstance }) => {
        const { entities } = normalizeChecklistInstance(checklistInstance);
        this.props.updateEntities(entities);
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows: checklistInstance.checklist_template_version.checklist_template_rows,
            checklist_instance_rows: checklistInstance.checklist_instance_rows,
            checklist_instance_options: checklistInstance.checklist_instance_options,
          });
        this.props.setChecklistInstance({
          instanceId: checklistInstance.id,
          childIds: childRowIds,
          templateFieldIds: rootRowIds,
          instanceRowForTemplateRowIds,
          instanceOptionIdsForTemplateRowIds,
        });
        this.setState({ showChecklistOptionsModal: false, isSavingOptions: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      });
    });
  };

  printChecklist = () => {
    if (this.props.checklistInstance) {
      window.open(
        `${process.env.REACT_APP_BROWSER_URL}checklist_instances/${this.props.checklistInstance.id}/checklist.pdf`,
        '_blank'
      );
    } else {
      window.open(
        `${process.env.REACT_APP_BROWSER_URL}checklist_template_versions/${this.props.checklistTemplateVersion.id}/checklist.pdf`,
        '_blank'
      );
    }
  };

  renderTitle = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: 200, height: 30 }}>
          <ContentLoader
            speed={2}
            width={200}
            height={33}
            viewBox="0 0 200 33"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="3" y="1" rx="3" ry="3" width="180" height="30" />
            <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
          </ContentLoader>
        </div>
      );
    }
    return this.props.checklistTemplateVersion.title;
  };

  renderSubtitle = () => {
    if (this.props.loading || this.props.openedFromDeviations === false) {
      return null;
    }
    if (this.props.asset) {
      if (this.props.workOrder) {
        return (
          <div className={styles['subtitle']}>
            <span>
              <FormattedMessage id="resources.work-order.resource" />
              <span> </span>
              <span>#{this.props.workOrder.number}</span>
            </span>
            <span> - </span>
            <span>{this.props.asset.title}</span>
          </div>
        );
      }
      return (
        <div className={styles['subtitle']}>
          <span>{this.props.operationalMaintenanceTemplateVersion.title}</span>
          <span> - </span>
          <span>{this.props.asset.title}</span>
        </div>
      );
    }
    if (this.props.workOrder) {
      return (
        <div className={styles['subtitle']}>
          {this.props.workOrder.title} - #{this.props.workOrder.number}
        </div>
      );
    }
    return <div className={styles['subtitle']}>{this.props.operationalMaintenanceTemplateVersion.title}</div>;
  };

  renderDropdownButton = () => {
    if (this.props.loading) {
      return null;
    }
    return (
      <div
        ref={ref => (this.moreOptionsInlineModalRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
          }));
        }}
      >
        <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
      </div>
    );
  };

  renderSettingsButton = () => {
    const { checklistTemplateVersion } = this.props;
    if (checklistTemplateVersion && checklistTemplateVersion.require_options) {
      return (
        <Button
          type="icon"
          icon={<Icon regular size={15} type="cog" />}
          onClick={() => this.setState({ showChecklistOptionsModal: true })}
        />
      );
    }
    return null;
  };

  renderChecklistOptionsModal = () => {
    if (this.props.loading) {
      return null;
    }
    let checklistInstanceId = null;
    let checklistInstanceOptionIds = [];
    if (this.props.checklistInstance) {
      checklistInstanceOptionIds = this.props.checklistInstance.checklist_instance_options;
      checklistInstanceId = this.props.checklistInstance.id;
    }
    return (
      <ChecklistOptionsModal
        checklistInstanceId={checklistInstanceId}
        checklistInstanceOptionIds={checklistInstanceOptionIds}
        open={this.state.showChecklistOptionsModal}
        isSaving={this.state.isSavingOptions}
        onSave={params => {
          this.batchUpdateChecklistOptions(params);
        }}
        onClose={() => this.setState({ showChecklistOptionsModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['header-wrapper']}>
          <div className={styles['header']}>
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.renderTitle()}</div>
              {this.renderSubtitle()}
            </div>
            <div className={styles['buttons']}>
              <div className={styles['button']}>
                <Button.Group>
                  {this.renderDropdownButton()}
                  {this.renderSettingsButton()}
                  <Button
                    type="icon"
                    icon={<Icon light size={22} type="times" />}
                    onClick={this.props.onClose}
                  />
                </Button.Group>
              </div>
            </div>
          </div>
        </div>
        <NewInlineModal
          minWidth={250}
          position="right"
          positionToRef={this.moreOptionsInlineModalRef}
          open={this.state.showMoreOptionsDropdown}
          onClose={() => this.setState({ showMoreOptionsDropdown: false })}
        >
          <React.Fragment>
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showMoreOptionsDropdown: false,
                    });
                    this.printChecklist();
                  }}
                >
                  <FormattedMessage id="general.print" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </React.Fragment>
        </NewInlineModal>
        {this.renderChecklistOptionsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setChecklistInstance: ChecklistModalOperations.setChecklistInstance,
      updateChecklistInstanceOption: SDKReduxOperations.updateChecklistInstanceOption,
      createForChecklistInstance: SDKReduxOperations.createForChecklistInstance,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  const checklistTemplate = ChecklistModalSelectors.getChecklistTemplate(state);
  const checklistTemplateLink = ChecklistModalSelectors.getChecklistTemplateLink(state);
  if (checklistInstance) {
    const workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
    const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
      state,
      checklistInstance.operational_maintenance_instance_id
    );
    let operationalMaintenanceTemplateVersion = null;
    let asset = null;
    if (workOrder) {
      asset = EntitySelectors.getAsset(state, workOrder.asset_id);
    } else {
      asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
      operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
        state,
        operationalMaintenanceInstance.operational_maintenance_template_version_id
      );
    }
    return {
      checklistInstance,
      checklistTemplate: EntitySelectors.getChecklistTemplate(state, checklistInstance.checklist_template_id),
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version_id
      ),
      checklistInstanceOptions: EntitySelectors.getChecklistInstanceOptions(
        state,
        checklistInstance.checklist_instance_options
      ),
      workOrder,
      operationalMaintenanceInstance,
      operationalMaintenanceTemplateVersion,
      asset,
    };
  }
  if (checklistTemplate) {
    return {
      checklistInstance,
      checklistTemplate,
      checklistTemplateLink,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.active_version
      ),
      checklistInstanceOptions: EntitySelectors.getChecklistInstanceOptions(
        state,
        checklistTemplateLink.checklist_instance_options
      ),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
