import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { ProductionBoardOperations, ProductionBoardSelectors } from 'state/ducks/productionBoard';
import { Icon, Button as GeneralButton } from 'views/components/Shared/General';
import styles from './style.module.scss';

class LastUpdated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 0,
    };
    this.interval = null;
    this.maxMinutesBeforeUpdate = 6;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { minutes } = this.state;
      if (minutes == this.maxMinutesBeforeUpdate) {
        this.update();
      } else {
        this.setState({ minutes: minutes + 1 });
      }
    }, 60_000); // Update every minute
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = () => {
    this.setState({ minutes: 0 });
    this.props.setIsRefreshing(true);
    Promise.all([
      this.props.fetchOperationalMaintenancesForDashboard(this.props.system.id, {
        asset_ids: this.props.checkedInAssetId,
        from_date: moment(this.props.operationalMaintenancesDate).format('YYYY-MM-DD'),
        to_date: moment(this.props.operationalMaintenancesDate).format('YYYY-MM-DD'),
      }),
      this.props.fetchRequestsForDashboard(this.props.system.id, {
        asset_with_tree_children_id: this.props.checkedInAssetId,
      }),
      this.props.fetchProductionBoardActivitiesForDashboard(this.props.system.id, {
        created_at: this.props.productionBoardActivityDate,
        asset_with_tree_children_id: this.props.checkedInAssetId,
      }),
    ])
      .then(([]) => {
        this.props.setIsRefreshing(false);
      })
      .catch(e => this.props.setIsRefreshing(false));
  };

  renderLastUpdatedMessage = () => {
    if (this.state.minutes == 0) {
      return <FormattedMessage id="general.now" />;
    }
    return (
      <FormattedPlural
        value={this.state.minutes}
        one={
          <FormattedMessage
            id="screens.production-board.last-updated-value.one"
            values={{ minutes: this.state.minutes }}
          />
        }
        two={
          <FormattedMessage
            id="screens.production-board.last-updated-value.two"
            values={{ minutes: this.state.minutes }}
          />
        }
        few={
          <FormattedMessage
            id="screens.production-board.last-updated-value.few"
            values={{ minutes: this.state.minutes }}
          />
        }
        many={
          <FormattedMessage
            id="screens.production-board.last-updated-value.many"
            values={{ minutes: this.state.minutes }}
          />
        }
        other={
          <FormattedMessage
            id="screens.production-board.last-updated-value.other"
            values={{ minutes: this.state.minutes }}
          />
        }
      />
    );
  };

  render() {
    return (
      <div className={styles['last-update']}>
        <span>
          <FormattedMessage id="screens.production-board.last-updated" />
          <span>: </span>
          {this.renderLastUpdatedMessage()}
        </span>
        <GeneralButton
          type="icon"
          icon={<Icon regular size={13} type="sync" />}
          gray
          onClick={e => this.update()}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProductionBoardActivitiesForDashboard:
        ProductionBoardOperations.fetchProductionBoardActivitiesForDashboard,
      fetchOperationalMaintenancesForDashboard:
        ProductionBoardOperations.fetchOperationalMaintenancesForDashboard,
      fetchRequestsForDashboard: ProductionBoardOperations.fetchRequestsForDashboard,
      setIsRefreshing: ProductionBoardOperations.setIsRefreshing,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    productionBoardActivityDate: ProductionBoardSelectors.getProductionBoardActivityDate(state),
    operationalMaintenancesDate: ProductionBoardSelectors.getOperationalMaintenancesDate(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LastUpdated);
