import types from './types';
import update from 'immutability-helper';
import { uniq, mergeWith } from 'lodash-es';
import { MenuTypes } from 'state/ducks/menu';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  queryParams: {
    page: 1,
    page_size: 25,
  },
  selectedCosts: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesAreSelected: false,
    ids: {},
  },
  pagination: {
    totalPages: 1,
    totalEntries: 0,
  },
  isFiltering: false,
  showingAppliedFilters: false,
  showApplyFilterForField: null,
  appliedFilters: [],
  filters: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    /*  
      Filter
    */
    case types.SELECT_FILTER_TYPE: {
      return update(state, {
        showApplyFilterForField: {
          $set: action.payload,
        },
      });
    }
    case types.ADD_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        appliedFilters: {
          $apply: appliedFilters => uniq([...appliedFilters, key]),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.REMOVE_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        showingAppliedFilters: {
          $set: state.appliedFilters.filter(id => id !== key).length > 0,
        },
        showApplyFilterForField: {
          $set: null,
        },
        appliedFilters: {
          $apply: appliedFilters => appliedFilters.filter(id => id !== key),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.RESET_FILTER: {
      return {
        ...state,
        isFiltering: false,
        showingAppliedFilters: false,
        appliedFilters: [],
        filters: INITIAL_STATE.filters,
      };
    }
    case types.SHOW_APPLIED_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: true,
        showApplyFilterForField: null,
      };
    }
    case types.SHOW_AVAILABLE_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: false,
      };
    }
    case types.FETCH_COSTS_SUCCESS: {
      const { ids, pagination } = action.payload;
      let allRowsAreSelected = ids.length > 0 && ids.every(id => state.selectedCosts.ids[id] === true);
      if (state.selectedCosts.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        selectedCosts: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    }
    /*
      MultiAction
    */
    case types.SELECT_PAGE: {
      if (state.selectedCosts.pageIsSelected) {
        return update(state, {
          selectedCosts: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedCosts.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedCosts: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedCosts.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedCosts.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedCosts.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_COST: {
      const costId = action.payload;
      if (state.selectedCosts.ids[costId]) {
        return update(state, {
          selectedCosts: {
            ids: {
              $unset: [costId],
            },
            totalEntriesAreSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== costId)
          .every(id => state.selectedCosts.ids[id] === true);
        return update(state, {
          selectedCosts: {
            ids: {
              [costId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedCosts: {
          totalEntriesAreSelected: {
            $set: true,
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedCosts: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesAreSelected: {
            $set: false,
          },
        },
      });
    }
    case types.RESET_SELECTED_COSTS: {
      return update(state, {
        selectedCosts: {
          $set: {
            ...INITIAL_STATE.selectedCosts,
          },
        },
      });
    }

    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }

    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_CREATED: {
      const { data } = action.payload;

      return update(
        { ...state },
        {
          ids: { $unshift: [data.id] },
          pagination: {
            totalEntries: v => v + 1,
          },
        }
      );
    }

    case SDKReduxTypes.COST_DELETED: {
      const { costId } = action.payload;

      return update(
        { ...state },
        {
          ids: { $set: state.ids.filter(id => id !== costId) },
          pagination: {
            totalEntries: v => v - 1,
          },
        }
      );
    }

    default:
      return state;
  }
};
