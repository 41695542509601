import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Menu } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetTypeMenu extends Component {
  filteredAssetTypes = () => {
    const { searchTerm } = this.props;
    return this.props.assetTypes.filter(
      ({ title }) => searchTerm === '' || title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <>
          <Menu.Item loading />
          <Menu.Item loading />
        </>
      );
    }
    const filteredAssetTypes = this.filteredAssetTypes();
    if (filteredAssetTypes.length === 0) {
      return (
        <span className={styles['no-results-text']}>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </span>
      );
    }
    return filteredAssetTypes.map(assetType => (
      <Menu.Item
        selected={this.props.selectedAssetTypeId === assetType.id}
        title={assetType.title}
        onClick={() => this.props.onSelectAssetTypeId(assetType.id)}
        key={assetType.id}
      />
    ));
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTypes: EntitySelectors.getAssetTypes(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(AssetTypeMenu);

AssetTypeMenu.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  searchTerm: PropTypes.string,
  selectedAssetTypeId: PropTypes.string,
  onSelectAssetTypeId: PropTypes.func,
  loading: PropTypes.bool,
};

AssetTypeMenu.defaultProps = {
  ids: [],
  searchTerm: '',
  selectedAssetTypeId: null,
  onSelectAssetTypeId: () => {},
  loading: true,
};
