import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import HelperFunctions from 'utilities/HelperFunctions';
import { AuthSelectors } from 'state/ducks/auth';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { ListLayout } from 'views/components/Shared/Layout';
import Users from './Users';
import WorkOrder from './WorkOrder';
import Asset from './Asset';
import Request from './Request';
import Company from './Company';
import SparePart from './SparePart';
import PurchaseOrder from './PurchaseOrder';
import Costs from './Costs';
import Notifications from './Notifications';
import MyProfile from './MyProfile';
import LeftBarComponent from './LeftBarComponent';
import Meters from './Meters';
import Integrations from './Integrations';
import ProductionBoard from './ProductionBoard';
import OperationalMaintenance from './OperationalMaintenance';

class Settings extends Component {
  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.settings.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Settings);
    if (this.props.location.pathname === '/settings') {
      this.props.history.replace('/settings/user');
    }
  }

  renderRoutes = () => {
    const { isAdmin, isViewOnly, isProductionSupervisor, hasProTier } = this.props;
    const { spare_parts_activated, production_supervisor_permission_users } = this.props.settings;
    if (isAdmin) {
      return (
        <>
          <Route path="/settings/user" component={MyProfile} />
          <Route path="/settings/notifications" component={Notifications} />
          <Route path="/settings/company" component={Company} />
          <Route path="/settings/users" component={Users} />
          <Route path="/settings/work-orders" component={WorkOrder} />
          <Route path="/settings/requests" component={Request} />
          <Route path="/settings/assets" component={Asset} />
          <Route path="/settings/spare-parts" component={SparePart} />
          <Route path="/settings/meters" component={Meters} />
          {hasProTier ? (
            <Route path="/settings/operational-maintenances" component={OperationalMaintenance} />
          ) : null}
          <Route path="/settings/production-boards" component={ProductionBoard} />
          {this.props.hasEnterpriseTier &&
          this.props.currentUser.organisation_admin &&
          this.props.organisationSettings.sso_oidc_toggle ? (
            <Route path="/settings/integrations" component={Integrations} />
          ) : null}
          {spare_parts_activated ? (
            <Route path="/settings/purchase-orders" component={PurchaseOrder} />
          ) : null}
          <Route path="/settings/costs" component={Costs} />
        </>
      );
    }
    if (isViewOnly) {
      return (
        <>
          <Route path="/settings/user" component={MyProfile} />
        </>
      );
    }
    return (
      <>
        <Route path="/settings/user" component={MyProfile} />
        <Route path="/settings/notifications" component={Notifications} />
        {isProductionSupervisor && production_supervisor_permission_users === 'create_operators' ? (
          <Route path="/settings/users" component={Users} />
        ) : null}
      </>
    );
  };

  render() {
    return (
      <ListLayout>
        <ListLayout.Header title={<FormattedMessage id="screens.settings.title" />} />
        <ListLayout.Content>
          <LeftBarComponent />
          <ListLayout.Content.MainContent>
            <ListLayout.Content.MainContent.Content>
              <Switch>{this.renderRoutes()}</Switch>
            </ListLayout.Content.MainContent.Content>
          </ListLayout.Content.MainContent>
        </ListLayout.Content>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    isAdmin: AuthSelectors.isAdmin(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    settings: AuthSelectors.getSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasProTier: AuthSelectors.hasProTier(state),
    hasEnterpriseTier: AuthSelectors.hasEnterpriseTier(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));
