import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions } from 'sdk';
import { FormattedMessage } from 'react-intl';
import {
  OperationalMaintenanceTemplateSelectors,
  OperationalMaintenanceTemplateOperations,
} from 'state/ducks/operationalMaintenanceTemplate';

const request = HelperFunctions.getCancelTokenForRequest();

class VersionListItem extends Component {
  renderFromDate = () => {
    if (this.props.operationalMaintenanceTemplateVersion.from_date == null) {
      return (
        <span style={{ fontStyle: 'italic' }}>
          <FormattedMessage id="screens.operational-maintenance-template.settings.versions.first-version" />
        </span>
      );
    }
    return (
      <FormattedMessage
        id="screens.operational-maintenance-template.settings.versions.from-version"
        values={{
          date: moment(this.props.operationalMaintenanceTemplateVersion.from_date).format('LL'),
        }}
      />
    );
  };

  renderActiveVersion = () => {
    const { id } = this.props.activeOperationalMaintenanceTemplateVersion;
    if (id === this.props.id) {
      return (
        <div className={styles['current-version']}>
          <FormattedMessage id="screens.operational-maintenance-template.settings.versions.current-version" />
        </div>
      );
    }
    return null;
  };

  render() {
    const selected = this.props.currentOperationalMaintenanceTemplateVersion.id === this.props.id;
    let classNames = [styles['item']];
    if (selected) {
      classNames = [...classNames, styles['selected']];
    }
    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          if (selected) {
            return;
          }
          request.cancel();
          this.props.fetchOperationalMaintenanceTemplateVersion(
            this.props.id,
            request.getCancelTokenConfig()
          );
        }}
      >
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            {this.renderFromDate()}
            {this.renderActiveVersion()}
          </div>
          <div className={styles['subtitle']}>
            <UserNameWrapper user={this.props.user} />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenanceTemplateVersion:
        OperationalMaintenanceTemplateOperations.fetchOperationalMaintenanceTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    id
  );

  return {
    operationalMaintenanceTemplateVersion,
    user: EntitySelectors.getUser(state, operationalMaintenanceTemplateVersion.created_by_user_id),
    activeOperationalMaintenanceTemplateVersion:
      OperationalMaintenanceTemplateSelectors.getActiveOperationalMaintenanceTemplateVersion(state),
    currentOperationalMaintenanceTemplateVersion:
      OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionListItem);
