/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getNewNotifications = state => {
  const { entities, notifications } = state;
  return notifications.newIds.map(id => entities.notificationById[id]);
};
const getEarlierNotifications = state => {
  const { entities, notifications } = state;
  return notifications.earlierIds.map(id => entities.notificationById[id]);
};
const getTotalEntries = state => state.notifications.totalEntries;
const getIsFullyLoaded = state => state.notifications.isFullyLoaded;
const getPaginateFrom = state => state.notifications.paginateFrom;
const getBadgeCount = state => state.notifications.badgeCount;

export default {
  getNewNotifications,
  getEarlierNotifications,
  getTotalEntries,
  getIsFullyLoaded,
  getPaginateFrom,
  getBadgeCount,
};
