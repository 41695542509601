import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { Button, Toggle } from 'views/components/Shared/General';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RequestReceiverType } from 'sdk/Settings';
import { Section, Separator, CardOption, UnsavedChangesBottomBar } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import ConfigureAssigneeImageSwe from 'assets/images/RequestAssignee (SV).png';
import ConfigureAssigneeImageEng from 'assets/images/RequestAssignee (EN).png';
import CreatorChooses from './CreatorChooses';
import Automation from './Automation';
import styles from './style.module.scss';

class Receivers extends Component {
  constructor(props) {
    super(props);
    const {
      request_allow_skip_assignee,
      request_default_assignee_group_id,
      request_default_assignee_user_id,
      request_multiple_assignees_active,
      request_multiple_assignees_type,
    } = props.settings;
    this.state = {
      editingSettings: {
        request_multiple_assignees_active,
        request_multiple_assignees_type,
        request_allow_skip_assignee,
        request_default_assignee_group_id,
        request_default_assignee_user_id,
      },
      editingSettingsBeforeEdit: {
        request_multiple_assignees_active,
        request_multiple_assignees_type,
        request_allow_skip_assignee,
        request_default_assignee_group_id,
        request_default_assignee_user_id,
      },
      hasUnsavedChanges: false,
      amountOfChangedProperties: 0,
      isSaving: false,
      showFeaturePreview: request_multiple_assignees_active === false,
    };
  }

  updateSettings = () => {
    this.setState({ isSaving: true });
    this.props
      .updateSettings(
        this.props.system.id,
        HelperFunctions.getDataChanges(this.state.editingSettings, this.state.editingSettingsBeforeEdit)
      )
      .then(() => {
        const editingSettings = {
          ...this.state.editingSettings,
          ...HelperFunctions.getDataChanges(this.state.editingSettings, this.state.editingSettingsBeforeEdit),
        };
        this.setState({
          isSaving: false,
          hasUnsavedChanges: false,
          editingSettings,
          editingSettingsBeforeEdit: editingSettings,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  renderOptions() {
    const { request_multiple_assignees_type } = this.state.editingSettings;
    return (
      <>
        <div className={styles['options']}>
          <CardOption.Container>
            <CardOption
              fullWidth
              icon="user"
              selected={request_multiple_assignees_type === RequestReceiverType.SelectedByCreator}
              title={
                <FormattedMessage id="screens.settings.requests.assignees.options.creator-chooses.title" />
              }
              subtitle={
                <FormattedMessage id="screens.settings.requests.assignees.options.creator-chooses.subtitle" />
              }
              onClick={() =>
                this.changeValue({
                  request_multiple_assignees_type: RequestReceiverType.SelectedByCreator,
                })
              }
            />
            <CardOption
              fullWidth
              icon="cogs"
              selected={request_multiple_assignees_type === RequestReceiverType.Automation}
              title={<FormattedMessage id="screens.settings.requests.assignees.options.automation.title" />}
              subtitle={
                <FormattedMessage id="screens.settings.requests.assignees.options.automation.subtitle" />
              }
              onClick={() => {
                this.changeValue({
                  request_multiple_assignees_type: RequestReceiverType.Automation,
                });
              }}
            />
          </CardOption.Container>
        </div>
      </>
    );
  }

  renderActivateReceiverWarning = () => {
    const { request_multiple_assignees_active } = this.state.editingSettings;
    if (request_multiple_assignees_active === false) {
      return (
        <div className={styles['warning']}>
          <div className={styles['text']}>
            <FormattedMessage id="screens.settings.requests.assignees.activate-warning" />
          </div>
        </div>
      );
    }
    return null;
  };

  renderOptionContents = () => {
    const { request_multiple_assignees_type } = this.state.editingSettings;
    if (request_multiple_assignees_type === RequestReceiverType.SelectedByCreator) {
      return (
        <CreatorChooses
          editingSettings={this.state.editingSettings}
          onChange={params => this.changeValue(params)}
        />
      );
    }
    return (
      <Automation
        editingSettings={this.state.editingSettings}
        onChange={params => this.changeValue(params)}
      />
    );
  };

  changeValue = params => {
    this.setState(
      {
        editingSettings: {
          ...this.state.editingSettings,
          ...params,
        },
      },
      () => {
        const amountOfChangedProperties = Object.keys(
          HelperFunctions.getDataChanges(this.state.editingSettings, this.state.editingSettingsBeforeEdit)
        ).length;
        this.setState({ hasUnsavedChanges: amountOfChangedProperties > 0 });
      }
    );
  };

  renderAssigneeContent = () => {
    return (
      <>
        <Separator dots />
        {this.renderActivateReceiverWarning()}
        {this.renderOptions()}
        {this.renderOptionContents()}
      </>
    );
  };

  renderFeaturePreview = () => {
    return (
      <>
        <Button
          type="text"
          label="screens.settings.requests.assignees.configure-without-activating"
          primary
          onClick={() => {
            this.setState({ showFeaturePreview: false });
          }}
        />
        <Separator dots />
        <div className={styles['configure-assignees-container']}>
          <img
            alt="screenshot"
            src={this.props.language === 'sv' ? ConfigureAssigneeImageSwe : ConfigureAssigneeImageEng}
          />
        </div>
      </>
    );
  };

  renderContent = () => {
    if (this.state.showFeaturePreview) {
      return this.renderFeaturePreview();
    }
    return this.renderAssigneeContent();
  };

  shouldShowUnsavedChangesBottomBar = () => {
    const approveByProdSupervisorIsActive =
      this.props.settings.request_production_supervisor_approval_activated &&
      this.props.settings.production_board_activated;
    const hasCreatorChoosesActivated =
      this.state.editingSettings.request_multiple_assignees_active === true &&
      this.state.editingSettings.request_multiple_assignees_type === RequestReceiverType.SelectedByCreator;
    if (this.state.hasUnsavedChanges) {
      if (approveByProdSupervisorIsActive && hasCreatorChoosesActivated) return false;
      return true;
    }
    return false;
  };

  render() {
    return (
      <>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Section
          title={<FormattedMessage id="screens.settings.requests.assignees.title" />}
          subtitle={<FormattedMessage id="screens.settings.requests.assignees.subtitle" />}
          rightComponent={
            <Toggle
              checked={this.state.editingSettings.request_multiple_assignees_active}
              onClick={() => {
                if (this.props.settings.request_multiple_assignees_active === false) {
                  if (this.state.editingSettings.request_multiple_assignees_active === false) {
                    this.setState({ showFeaturePreview: false });
                  } else {
                    this.setState({ showFeaturePreview: true });
                  }
                } else {
                  this.setState({ showFeaturePreview: false });
                }
                this.changeValue({
                  request_multiple_assignees_active:
                    !this.state.editingSettings.request_multiple_assignees_active,
                });
              }}
            />
          }
        />
        {this.renderContent()}
        <UnsavedChangesBottomBar
          show={this.shouldShowUnsavedChangesBottomBar()}
          amountOfChanges={this.state.amountOfChangedProperties}
          loading={this.state.isSaving}
          onClose={() => this.setState({ hasUnsavedChanges: false })}
          onSave={() => {
            this.updateSettings();
          }}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Receivers));
