import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { Field } from 'views/components/Shared/General';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { ChooseAssigneeInlineModal } from 'views/components/WorkOrder';
import UserContainer from './UserContainer';
import VendorContainer from './VendorContainer';
import GroupContainer from './GroupContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Assignee extends Component {
  constructor(props) {
    super(props);
    const comparator = Object.keys(props.filter)[0];
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      const hasValue = this.props.filter[comparator];
      this.state = {
        comparator,
        userIds: [],
        groupIds: [],
        vendorIds: [],
        hasValue,
        initialFilter: props.filter,
      };
    } else {
      const value = props.filter[comparator];
      const { userIds, groupIds, vendorIds } = value;
      this.state = {
        initialFilter: this.props.filter,
        comparator,
        userIds,
        groupIds,
        vendorIds,
        hasValue: false,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.Assignee,
            data: {
              [FILTER_TYPES.Assignee]: {
                [HelperFunctions.FILTER_COMPARABLES.Exists]: this.state.hasValue,
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.Assignee,
            data: {
              [FILTER_TYPES.Assignee]: {
                [this.state.comparator]: {
                  userIds: this.state.userIds,
                  groupIds: this.state.groupIds,
                  vendorIds: this.state.vendorIds,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    const { userIds, groupIds, vendorIds } = this.state;
    return userIds.length > 0 || groupIds.length > 0 || vendorIds.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedAssignees = () => {
    if (
      this.state.userIds.length === 0 &&
      this.state.vendorIds.length === 0 &&
      this.state.groupIds.length === 0
    ) {
      return null;
    }

    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={[
          ...this.state.userIds.map(id => ({ id, type: 'user' })),
          ...this.state.vendorIds.map(id => ({ id, type: 'vendor' })),
          ...this.state.groupIds.map(id => ({ id, type: 'group' })),
        ]}
        renderItem={({ type, id }) => {
          if (type === 'user') {
            return <UserContainer id={id} />;
          }
          if (type === 'group') {
            return <GroupContainer id={id} />;
          }
          return <VendorContainer id={id} />;
        }}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChooseAssigneeInlineModal
        includeAssignedOperators
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedAssignees()}
            onClear={() => {
              this.setState({
                userIds: [],
                groupIds: [],
                vendorIds: [],
              });
            }}
          />
        }
        selectedUserIds={this.state.userIds}
        selectedGroupIds={this.state.groupIds}
        selectedVendorIds={this.state.vendorIds}
        onAddUser={({ id }) => this.setState({ userIds: [...this.state.userIds, id] })}
        onRemoveUser={({ id }) =>
          this.setState({ userIds: this.state.userIds.filter(userId => userId !== id) })
        }
        onAddGroup={({ id }) => this.setState({ groupIds: [...this.state.groupIds, id] })}
        onRemoveGroup={({ id }) =>
          this.setState({ groupIds: this.state.groupIds.filter(groupId => groupId !== id) })
        }
        onAddVendor={({ id }) => this.setState({ vendorIds: [...this.state.vendorIds, id] })}
        onRemoveVendor={({ id }) =>
          this.setState({ vendorIds: this.state.vendorIds.filter(vendorId => vendorId !== id) })
        }
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Assignee,
                data: {
                  [FILTER_TYPES.Assignee]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Assignee,
                data: {
                  [FILTER_TYPES.Assignee]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order.assignee" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
      addFilter: WorkOrdersOperations.addFilter,
      showAppliedFilters: WorkOrdersOperations.showAppliedFilters,
      selectFilterType: WorkOrdersOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Assignee),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignee);

Assignee.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Assignee.defaultProps = {
  filter: {
    [HelperFunctions.FILTER_COMPARABLES.Any]: {
      userIds: [],
      groupIds: [],
      vendorIds: [],
    },
  },
  initialFilter: {
    [HelperFunctions.FILTER_COMPARABLES.Any]: {
      userIds: [],
      groupIds: [],
      vendorIds: [],
    },
  },
};
