import React from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { Button, Tooltip, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

const ActivePurchaseOrderRow = ({ purchaseOrderRow, purchaseOrder, sparePartUnit, vendor }) => {
  const orderedQuantity = new Decimal(purchaseOrderRow.quantity);
  const deliveredQuantity = new Decimal(purchaseOrderRow.delivered_quantity);

  const packageQuantity = purchaseOrderRow.package_quantity
    ? new Decimal(purchaseOrderRow.package_quantity)
    : new Decimal(1);

  const leftToDeliver = orderedQuantity.minus(deliveredQuantity).times(packageQuantity);

  const renderDeliveryDate = () => {
    const estimatedDate = getEstimatedDeliveryDateText(purchaseOrder);
    if (estimatedDate == null) {
      return (
        <Tooltip
          trigger={
            <div className={`${styles['icon']} ${styles['disabled']}`}>
              <Icon regular type="calendar-alt" />
            </div>
          }
          label={<FormattedMessage id="screens.spare-part.info.stock-information.no-delivery-date" />}
        />
      );
    }
    return (
      <Tooltip
        trigger={
          <div className={styles['icon']}>
            <Icon regular type="calendar-alt" />
          </div>
        }
        label={
          <React.Fragment>
            <FormattedMessage id="screens.spare-part.info.stock-information.delivery-date" />
            <span>: </span>
            <span>{getEstimatedDeliveryDateText(purchaseOrder)}</span>
          </React.Fragment>
        }
      />
    );
  };

  return (
    <div className={styles['row']}>
      <div className={styles['info']}>
        <p className={styles['title']} title={vendor.name}>
          {vendor.name}
        </p>
        <p className={styles['subtitle']}>
          <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={leftToDeliver.toString()} />
        </p>
      </div>
      <div className={styles['icons']}>
        {renderDeliveryDate()}
        <Tooltip
          trigger={
            <Link to={`/purchase-orders/${purchaseOrder.id}`}>
              <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
            </Link>
          }
          label={
            <FormattedMessage id="screens.spare-part.info.stock-information.navigate-to-purchase-order" />
          }
        />
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, ownProps.purchaseOrderRow.purchase_order_id);
  return {
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(ActivePurchaseOrderRow);
