import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Menu, Loader } from 'views/components/Shared/General';
import AllSpareParts from './AllSpareParts';
import SparePartLocations from './SparePartLocations';
import SparePartTypes from './SparePartTypes';
import SparePartTypeListContent from './SparePartTypeListContent';
import styles from './style.module.scss';

class SelectSparePartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 'spare-part',
      searchValue: '',
      isSearching: false,
      selectedSparePartTypeId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ selectedItem: 'spare-part', searchValue: '' });
    }
  }

  renderInventory = () => {
    return (
      <SparePartLocations
        listItemRightComponent={this.props.listItemRightComponent}
        searchValue={this.state.searchValue}
        additionalApiParams={this.props.additionalApiParams}
      />
    );
  };

  renderSparePartList = () => {
    return (
      <AllSpareParts
        additionalApiParams={this.props.additionalApiParams}
        searchValue={this.state.searchValue}
        listItemRightComponent={this.props.listItemRightComponent}
      />
    );
  };

  renderFilteredSpareParts = () => {
    return (
      <SparePartTypeListContent
        additionalApiParams={this.props.additionalApiParams}
        listItemRightComponent={this.props.listItemRightComponent}
        searchValue={this.state.searchValue}
        sparePartTypeId={this.state.selectedSparePartTypeId}
      />
    );
  };

  renderSparePartTypes = () => {
    return (
      <SparePartTypes
        ids={this.state.sparePartTypeIds}
        selectedSparePartTypeId={this.state.selectedSparePartTypeId}
        onSelectSparePartType={sparePartTypeId =>
          this.setState({ selectedSparePartTypeId: sparePartTypeId, selectedItem: 'filtered' })
        }
      />
    );
  };

  renderCreateButton = () => {
    return (
      <Button
        primary
        onClick={this.props.onCreateNew}
        label="components.select-spare-part-modal.menu.create-button"
      />
    );
  };

  renderMenuButtons = () => {
    return (
      <>
        <div className={styles['separator']} />
        <Menu.Item
          selected={this.state.selectedItem === 'spare-part'}
          title={<FormattedMessage id="components.select-spare-part-modal.menu.all-spare-parts" />}
          onClick={() => {
            this.setState({ selectedItem: 'spare-part', selectedSparePartTypeId: null });
          }}
        />
        <Menu.Item
          selected={this.state.selectedItem === 'inventory'}
          title={<FormattedMessage id="components.select-spare-part-modal.menu.inventory" />}
          onClick={() => {
            this.setState({ selectedItem: 'inventory', selectedSparePartTypeId: null });
          }}
        />
      </>
    );
  };

  renderMenuLoader = () => {
    return <Loader />;
  };

  renderMenuContent = () => {
    return (
      <>
        <div className={styles['left-panel']}>
          {this.renderCreateButton()}
          {this.renderMenuButtons()}
        </div>
        {this.renderSparePartTypes()}
      </>
    );
  };

  renderModalView = () => {
    switch (this.state.selectedItem) {
      case 'spare-part':
        return this.renderSparePartList();
      case 'inventory':
        return this.renderInventory();
      case 'filtered':
        return this.renderFilteredSpareParts();
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.select-spare-part-modal.title" />}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
          searchable
          value={this.state.searchValue}
          onSearch={value => {
            this.setState({ searchValue: value, isSearching: true });
          }}
          onClear={() => {
            this.setState({ isSearching: true, searchValue: '' });
          }}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>{this.renderModalView()}</Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

export default withRouter(SelectSparePartModal);
