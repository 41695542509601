import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { Image, Loader } from 'views/components/Image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Button, FileUploadWrapper, FullScreenImagePicker } from 'views/components/Shared/General';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class EditCommentModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    comment: '',
    showingImageDialog: false,
    currentImage: 0,
    deletingImageIds: {},
    uploadingCount: 0,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        comment: this.props.instanceRow.comment,
      });
    }
  }

  addImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createImageForChecklistInstanceRow(this.props.instanceRow.id, { image: file, mime_type, name })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.checklist-instance-modal.add-image-success" />}
          />
        );
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      });
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.checklist-instance-modal.delete-image-success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  save = () => {
    const { comment } = this.state;
    this.setState({ isSaving: true });
    this.props.updateChecklistInstanceRow(this.props.instanceRow.id, { comment }).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.props.onClose();
    });
  };

  renderTitle = () => {
    if (this.props.instanceRow.comment) {
      return <FormattedMessage id="components.checklist-instance-modal.edit-comment-text" />;
    } else {
      return <FormattedMessage id="components.checklist-instance-modal.add-comment-text" />;
    }
  };

  renderSeparator = () => {
    if (this.state.uploadingCount > 0 || this.props.images.length > 0) {
      return <div className={styles['separator']} />;
    }
    return null;
  };

  renderImage = (image, index) => {
    return (
      <Image
        key={image.id}
        small
        circle
        isDeleting={this.state.deletingImageIds[image.id] != null}
        image={image}
        editable
        onClick={() => {
          this.selectImage(index);
        }}
        onDelete={() => {
          this.deleteImage(image);
        }}
      />
    );
  };

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader small circle />
    );
    if (this.props.images.length === 0 && this.state.uploadingCount === 0) {
      return null;
    }
    return (
      <div className={styles['images']}>
        {this.props.images.map((image, index) => this.renderImage(image, index))}
        {loaderElements}
      </div>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={550}>
          <Modal.Header title={this.renderTitle()} onClose={this.props.onClose} />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    view={false}
                    label={
                      <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-comment-modal.comment" />
                    }
                  >
                    <Field.Textarea
                      autoFocus
                      value={this.state.comment}
                      onChange={comment => {
                        this.setState({ comment });
                      }}
                    />
                  </Field>
                  <div className={styles['add-image-button']}>
                    <FileUploadWrapper
                      image
                      accept="image/png, image/jpg, image/jpeg, image/gif"
                      onSelectFile={this.addImage}
                    >
                      <Button gray label="components.checklist-instance-modal.add-image" />
                    </FileUploadWrapper>
                  </div>
                  {this.renderSeparator()}
                  {this.renderImages()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateChecklistInstanceRow: SDKReduxOperations.updateChecklistInstanceRow,
      createImageForChecklistInstanceRow: SDKReduxOperations.createImageForChecklistInstanceRow,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { instanceRowId } = ownProps;
  const instanceRow = EntitySelectors.getChecklistInstanceRow(state, instanceRowId);
  return {
    instanceRow,
    images: EntitySelectors.getImages(state, instanceRow.images),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditCommentModal));
