import React, { Component } from 'react';
import { Modal } from 'views/components/Shared/Layout';
import View from './View';
import Edit from './Edit';
import { normalizeSparePartWithdrawal } from 'sdk/Schemas';
import { API } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SparePartWithdrawalModal extends Component {
  state = {
    mode: 'view',
    isFetching: false,
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id) {
        this.setState({ isFetching: true });
        API.fetchSparePartWithdrawal(this.props.id).then(({ data: sparePartWithdrawal }) => {
          const { entities } = normalizeSparePartWithdrawal(sparePartWithdrawal);
          this.props.updateEntities(entities);
          this.setState({ isFetching: false });
        });
        this.setState({ mode: 'view' });
      }
    }
  }

  renderContent = () => {
    switch (this.state.mode) {
      case 'edit':
        return (
          <Edit
            id={this.props.id}
            onClose={this.props.onClose}
            onCancel={() => {
              this.setState({ mode: 'view' });
            }}
            onSaved={() => {
              this.setState({ mode: 'view' });
            }}
          />
        );
      case 'view':
        if (this.state.isFetching) {
          return (
            <>
              <Modal.Content loading />
            </>
          );
        } else {
          return (
            <View
              id={this.props.id}
              onClose={this.props.onClose}
              onEdit={() => {
                this.setState({ mode: 'edit' });
              }}
              onDelete={this.props.onDelete}
            />
          );
        }
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(SparePartWithdrawalModal);
