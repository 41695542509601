import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, debounce } from 'lodash-es';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { CalendarOperations, CalendarSelectors } from 'state/ducks/calendar';
import { AuthSelectors } from 'state/ducks/auth';
import { NewWorkOrderModal, PreviewWorkOrderOverlay } from 'views/components/WorkOrder';
import { PreviewRequestContent, PreviewRequestOverlay } from 'views/components/Request';
import { PreviewOverlay } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import Week from './Week';
import SideBar from './SideBar';
import Header from './Header';
import DragLayer from './DragLayer';
import PreviewInstance from './PreviewInstance';
import PreviewRequestHeader from './PreviewRequestHeader';
import styles from './style.module.scss';

class Calendar extends Component {
  constructor(props) {
    super(props);
    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      queryParams: {
        date: moment().format('YYYY-MM-DD'),
        ...queryParams,
      },
      showSideBar: false,
      isFetching: true,
      showNewWorkOrderModal: false,
      createWorkOrderForRequestId: null,
      newWorkOrderDefaultParams: {},
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.calendar.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Calendar);
    this.fetchAssigneesForView();
    this.fetchInstancesForDates = debounce(this.fetchInstancesForDates, 400);
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState(
        {
          queryParams: {
            date: moment().format('YYYY-MM-DD'),
            ...queryParams,
          },
        },
        () => {
          const { date } = this.state.queryParams;
          const fromDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
          const toDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
          this.fetchInstancesForDates(fromDate, toDate);
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.hideListBar();
  }

  fetchAssigneesForView = () => {
    Promise.all([
      this.props.fetchAssignees(this.props.system.id),
      API.showCalendarCount(this.props.system.id),
    ]).then(([_, countsRes]) => {
      const { data: counts } = countsRes;
      this.props.setCounts(counts);
      const { date } = this.state.queryParams;
      const fromDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
      const toDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
      this.fetchInstancesForDates(fromDate, toDate);
    });
  };

  fetchInstancesForDates = (fromDate, toDate, recurringMaintenanceId = null, options) => {
    this.props
      .fetchInstancesForDates(
        this.props.system.id,
        {
          from: fromDate,
          to: toDate,
          recurring_maintenance_id: recurringMaintenanceId,
        },
        options
      )
      .then(() => {
        this.setState({ isFetching: false, showNewWorkOrderModal: false, createWorkOrderForRequestId: null });
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  goToPreviousWeek = () => {
    this.setState({ isFetching: true });
    const { date } = this.state.queryParams;
    this.changeQueryParams({
      date: moment(date).subtract(1, 'weeks').format('YYYY-MM-DD'),
    });
  };

  goToNextWeek = () => {
    this.setState({ isFetching: true });
    const { date } = this.state.queryParams;
    this.changeQueryParams({
      date: moment(date).add(1, 'weeks').format('YYYY-MM-DD'),
    });
  };

  goToDate = date => {
    if (this.state.queryParams.date === date) return;
    this.setState({ isFetching: true });
    this.changeQueryParams({
      date,
    });
  };

  toggleToolbar = () => {
    this.setState(prevState => ({ showSideBar: !prevState.showSideBar }));
    this.props.hideListBar();
  };

  createNewWorkOrder = params => {
    this.setState({ newWorkOrderDefaultParams: params || {}, showNewWorkOrderModal: true });
  };

  /*
    Render functions
  */
  renderData = () => {
    let contentClassNames = [styles['content']];
    if (this.state.showSideBar) {
      contentClassNames = [...contentClassNames, styles['show-sidebar']];
    }
    if (this.props.isShowingToolbarList) {
      contentClassNames = [...contentClassNames, styles['show-toolbar-list']];
    }
    return (
      <div className={contentClassNames.join(' ')}>
        <div className={styles['users']}>
          <div className={styles['user-view']}>
            {this.props.userIds.map(userId => (
              <Week
                key={userId}
                userId={userId}
                date={this.state.queryParams.date}
                onCreateWorkOrder={this.createNewWorkOrder}
                onCreateWorkOrderForRequest={(requestId, params) => {
                  this.setState({ createWorkOrderForRequestId: requestId }, () => {
                    this.createNewWorkOrder(params);
                  });
                }}
              />
            ))}
            {this.props.groupIds.map(groupId => (
              <Week
                groupId={groupId}
                key={groupId}
                date={this.state.queryParams.date}
                onCreateWorkOrder={this.createNewWorkOrder}
                onCreateWorkOrderForRequest={(requestId, params) => {
                  this.setState({ createWorkOrderForRequestId: requestId }, () => {
                    this.createNewWorkOrder(params);
                  });
                }}
              />
            ))}
            {this.props.vendorIds.map(vendorId => (
              <Week
                key={vendorId}
                vendorId={vendorId}
                date={this.state.queryParams.date}
                onCreateWorkOrder={this.createNewWorkOrder}
                onCreateWorkOrderForRequest={(requestId, params) => {
                  this.setState({ createWorkOrderForRequestId: requestId }, () => {
                    this.createNewWorkOrder(params);
                  });
                }}
              />
            ))}
            <div className={`${styles['user-view-loader']} ${this.state.isFetching ? styles['show'] : ''}`} />
          </div>
        </div>
      </div>
    );
  };

  renderNewWorkOrderModal = () => (
    <NewWorkOrderModal
      open={this.state.showNewWorkOrderModal}
      forRequestId={this.state.createWorkOrderForRequestId}
      defaultParams={this.state.newWorkOrderDefaultParams}
      onClose={() => {
        this.setState({ showNewWorkOrderModal: false, createWorkOrderForRequestId: null });
      }}
      onCreated={workOrder => {
        if (workOrder.recurring_maintenance_id) {
          const { date } = this.state.queryParams;
          const fromDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
          const toDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
          this.fetchInstancesForDates(fromDate, toDate, workOrder.recurring_maintenance_id, {
            resetCalendar: false,
          });
        } else {
          this.setState({ showNewWorkOrderModal: false, createWorkOrderForRequestId: null });
        }
      }}
      onCreatedWithReopen={workOrder => {
        if (workOrder.recurring_maintenance_id) {
          const { date } = this.state.queryParams;
          const fromDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
          const toDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
          this.fetchInstancesForDates(fromDate, toDate, workOrder.recurring_maintenance_id, {
            resetCalendar: false,
          });
        } else {
          this.setState({ showNewWorkOrderModal: false, createWorkOrderForRequestId: null });
        }
        setTimeout(() => {
          this.setState({ showNewWorkOrderModal: true, createWorkOrderForRequestId: null });
        }, 400);
      }}
    />
  );

  renderPreviewInstance = () => {
    const { top, left, width, instanceId, workOrderId, open } = this.props.previewWorkOrderOverlayData;
    return (
      <PreviewInstance
        open={open}
        top={top}
        left={left}
        extraWidth={width}
        onClose={this.props.hidePreviewWorkOrderOverlay}
        workOrderId={workOrderId}
        instanceId={instanceId}
      />
    );
  };

  renderPreviewRequest = () => {
    const { top, left, width, requestId, open } = this.props.previewRequestOverlayData;
    return (
      <PreviewRequestOverlay
        open={open}
        top={top}
        left={left}
        extraWidth={width}
        editable
        id={requestId}
        onClose={this.props.hidePreviewRequestOverlay}
        onCreateWorkOrder={params => {
          this.setState({ createWorkOrderForRequestId: requestId }, () => {
            this.createNewWorkOrder(params);
          });
        }}
      />
    );
  };

  render() {
    let classNames = [styles['calendar']];
    if (this.state.showSideBar) {
      classNames = [...classNames, styles['show-sidebar']];
    }
    return (
      <>
        <div className={styles['wrapper']}>
          <div className={styles['container']}>
            <div className={classNames.join(' ')}>
              <Header
                date={this.state.queryParams.date}
                showSideBar={this.state.showSideBar}
                onToggleToolbar={this.toggleToolbar}
                onGoToPreviousWeek={this.goToPreviousWeek}
                onGoToNextWeek={this.goToNextWeek}
                onCreateWorkOrder={this.createNewWorkOrder}
              />
              {this.renderData()}
            </div>
            <SideBar
              date={this.state.queryParams.date}
              open={this.state.showSideBar}
              onGoToDate={this.goToDate}
              ref={ref => (this.toolBoxRef = ref)}
            />
          </div>
        </div>
        <DragLayer />
        {this.renderNewWorkOrderModal()}
        {this.renderPreviewInstance()}
        {this.renderPreviewRequest()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
      hideListBar: CalendarOperations.hideListBar,
      setAssignees: CalendarOperations.setAssignees,
      fetchDataBetweenDatesSuccess: CalendarOperations.fetchDataBetweenDatesSuccess,
      setCounts: CalendarOperations.setCounts,
      fetchInstancesForDates: CalendarOperations.fetchInstancesForDates,
      fetchAssignees: CalendarOperations.fetchAssignees,
      hidePreviewWorkOrderOverlay: CalendarOperations.hidePreviewWorkOrderOverlay,
      hidePreviewRequestOverlay: CalendarOperations.hidePreviewRequestOverlay,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    userIds: CalendarSelectors.getUserIds(state),
    groupIds: CalendarSelectors.getGroupIds(state),
    vendorIds: CalendarSelectors.getVendorIds(state),
    previewWorkOrderOverlayData: CalendarSelectors.getPreviewWorkOrderOverlayData(state),
    previewRequestOverlayData: CalendarSelectors.getPreviewRequestOverlayData(state),
    isShowingToolbarList: CalendarSelectors.isShowingList(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Calendar));
