import { SDKReduxTypes, HelperFunctions } from 'sdk';
import update from 'immutability-helper';
import { UserType } from 'sdk/SystemAccess';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.ASSET_OPERATOR_CREATED: {
      const { id, user_id } = action.payload.data;
      return update(state, {
        [user_id]: {
          asset_operators: {
            $apply: val => [id, ...val],
          },
        },
      });
    }
    case SDKReduxTypes.ASSET_OPERATOR_DELETED: {
      const { id, user_id } = action.payload.data;
      if (state[user_id]) {
        return update(state, {
          [user_id]: {
            asset_operators: {
              $apply: val => val.filter(loopedId => loopedId !== id),
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.DELETED_PROFILE_PICTURE: {
      const { userId } = action.payload;
      return {
        ...state,
        [userId]: {
          ...state[userId],
          profile_picture_image_id: null,
        },
      };
    }
    case SDKReduxTypes.CHANGED_PROFILE_PICTURE: {
      const { userId, data } = action.payload;
      return {
        ...state,
        [userId]: {
          ...state[userId],
          profile_picture_image_id: data.id,
        },
      };
    }
    case SDKReduxTypes.LOGIN_CREDENTIAL_DELETED: {
      const { loginCredential } = action.payload;
      const { user_id } = loginCredential;
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          login_credential: null,
        },
      };
    }
    case SDKReduxTypes.LOGIN_CREDENTIAL_CREATED: {
      const { data } = action.payload;
      const { user_id, id } = data;
      return {
        ...state,
        [user_id]: {
          ...state[user_id],
          login_credential: id,
        },
      };
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.userById) {
        return HelperFunctions.mergeEntities(state, action.payload.userById);
      }
      return state;
    default:
      return state;
  }
};
