import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, ConfirmDeleteInlineModal, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

const AssetModalListItem = ({ id, asset, created, isDeleting, onDelete, settings, isViewOnly }) => {
  return (
    <>
      <div className={styles['list-item']} key={id}>
        <div className={styles['content']}>
          <p className={styles['title']}>
            {settings.asset_number_activated ? <span>#{asset.number} - </span> : null}
            <span>{asset.title}</span>
          </p>
          <p className={styles['subtitle']}>
            <TreePath onlyShowPath assetId={asset.id} />
          </p>
        </div>
        {created ? (
          <div className={styles['badge']}>
            <FormattedMessage id="general.new" />
          </div>
        ) : null}
        {isViewOnly === false ? (
          <div className={styles['options']}>
            <ConfirmDeleteInlineModal
              position="right"
              trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
              title={<FormattedMessage id="components.file-related-assets.delete.title" />}
              subtitle={<FormattedMessage id="components.file-related-assets.delete.description" />}
              onDelete={onDelete}
              loading={isDeleting}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
    settings: AuthSelectors.getSettings(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps)(AssetModalListItem);
