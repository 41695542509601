import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { DowntimesSelectors } from 'state/ducks/downtimes';
import styles from './styles.module.scss';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.selectedDowntimesCount >= maxQuantityAllowedForType(ExportType.DowntimesXslx),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    let queryParameters = {
      ...this.props.queryParameters,
    };
    if (queryParameters.from && queryParameters.to) {
      const { from, to } = queryParameters;
      queryParameters = {
        ...queryParameters,
        to: null,
        from: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Between]: `${from}...${to}`,
        },
      };
    }
    if (this.props.totalEntriesIsSelected) {
      return {
        asset_with_tree_children_id,
        ...this.props.listParams,
        ...this.props.queryParameters,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.selectedDowntimeIds).toString(),
      },
    };
  };

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.buildFilterParams(),
      type: ExportType.DowntimesXslx,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.selectedDowntimesCount}
        one={<FormattedMessage id="components.export-modal.total-downtimes.one" />}
        two={
          <FormattedMessage
            id="components.export-modal.total-downtimes.two"
            values={{ count: this.props.selectedDowntimesCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.export-modal.total-downtimes.few"
            values={{ count: this.props.selectedDowntimesCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.export-modal.total-downtimes.many"
            values={{ count: this.props.selectedDowntimesCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.export-modal.total-downtimes.other"
            values={{ count: this.props.selectedDowntimesCount }}
          />
        }
      />
    );
  };

  renderContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            {<FormattedMessage id="components.export-modal.excel-quantity-exceeded" />}
          </div>
        </Modal.Content>
      );
    }
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.showQuantityExceeded) {
      return null;
    }
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.export-modal.title-xslx" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: DowntimesSelectors.getQueryParameters(state),
    selectedDowntimeIds: DowntimesSelectors.getSelectedDowntimeIds(state),
    selectedDowntimesCount: DowntimesSelectors.getSelectedDowntimesCount(state),
    showSelectTotalEntries: DowntimesSelectors.getShowSelectTotalEntries(state),
    totalEntriesIsSelected: DowntimesSelectors.getTotalEntriesIsSelected(state),
  };
}

export default withRouter(connect(mapStateToProps)(ExportModal));
