import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { API, SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TemplateField } from 'views/components/TemplateField';
import { Field, Button, FieldErrorWrapper, Icon, Loader } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { Grid } from 'views/components/Shared/Layout';
import { SideModal } from 'views/components/Shared/Layout';
import AssetTypeField from './AssetTypeField';
import TreeParentField from './TreeParentField';
import { getVendorSubtitle } from 'sdk/Vendor';
import styles from './style.module.scss';

const checkDuplicateAssetsRequest = HelperFunctions.getCancelTokenForRequest();

class NewAssetModal extends Component {
  initialState = () => ({
    isSaving: false,
    isSavingWithCreateNew: false,
    hasUnsavedChanges: false,
    editingAsset: this.buildEmptyEditingAsset(),
    editingAssetBeforeChange: this.buildEmptyEditingAsset(),
    editingAssetFields: [],
    showTitleError: false,
    isCheckingDuplicateAssetNumber: false,
    showAssetNumberAlreadyExistsWarning: false,
    asset_vendor: {
      comment: null,
    },
    spare_part_asset: {
      quantity: null,
    },
  });

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.initialState(),
        editingAsset: this.buildEmptyEditingAsset(this.props.defaultParams),
        editingAssetBeforeChange: this.buildEmptyEditingAsset(this.props.defaultParams),
      });
    }

    if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  checkDuplicatedAssetNumber = number => {
    let params = {
      number: {
        [HelperFunctions.FILTER_COMPARABLES.Exact]: number,
      },
    };
    API.listAssets(this.props.currentSystem.id, params, checkDuplicateAssetsRequest.getCancelTokenConfig())
      .then(({ data }) => {
        this.setState({
          isCheckingDuplicateAssetNumber: false,
          showAssetNumberAlreadyExistsWarning: data.length > 0,
        });
      })
      .catch(() => {
        this.setState({
          showAssetNumberAlreadyExistsWarning: false,
          isCheckingDuplicateAssetNumber: false,
        });
      });
  };

  buildEmptyEditingAsset = (defaultProps = {}) => ({
    title: '',
    number: '',
    asset_type_id: null,
    tree_parent_id: null,
    tree: true,
    ...defaultProps,
  });

  save = reopenAfterSave => {
    if (this.state.isSaving || this.state.isSavingWithCreateNew) return;
    var params = { ...this.getModifiedAssetData(), asset_fields: this.buildAssetFieldsData() };
    if (this.props.createForSparePartId) {
      params = {
        ...params,
        spare_part_assets: [
          {
            spare_part_id: this.props.sparePart.id,
            quantity: this.state.spare_part_asset.quantity,
          },
        ],
      };
    } else if (this.props.createForVendorId) {
      params = {
        ...params,
        asset_vendors: [
          {
            vendor_id: this.props.vendor.id,
            comment: this.state.asset_vendor.comment,
          },
        ],
      };
    }
    if (!this.validateSave(params)) return;
    if (reopenAfterSave) {
      this.setState({ isSavingWithCreateNew: true, hasUnsavedChanges: false });
    } else {
      this.setState({ isSaving: true, hasUnsavedChanges: false });
    }
    this.props
      .createAsset(this.props.currentSystem.id, params)
      .then(({ data: asset }) => {
        if (reopenAfterSave) {
          this.props.onCreatedWithReopen(asset);
        } else {
          this.props.onCreated(asset);
        }
      })
      .catch(e => {
        this.setState({ isSaving: false, isSavingWithCreateNew: false });
      });
  };

  validateSave = params => {
    if (!params.title) {
      this.setState({ showTitleError: true });
      return false;
    }
    if (this.state.showAssetNumberAlreadyExistsWarning) {
      return false;
    }

    return true;
  };

  setAssetValue = values => {
    const editingAsset = { ...this.state.editingAsset, ...values };

    const hasUnsavedChanges = !isEqual(this.state.editingAssetBeforeChange, editingAsset);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      editingAsset,
      hasUnsavedChanges,
    });
  };

  getModifiedAssetData = () => {
    const asset = this.state.editingAsset;

    return Object.keys(asset).reduce(
      (acc, key) => ({
        ...acc,
        [key]: asset[key],
      }),
      {}
    );
  };

  buildAssetFieldsData = () => {
    const assetFields = this.state.editingAssetFields;

    return Object.keys(assetFields).map(key => ({
      template_field_id: key,
      value: assetFields[key],
    }));
  };

  getAssetFieldValueForTemplateField = templateField => {
    return this.state.editingAssetFields[templateField.id];
  };
  setAssetFieldValue = (templateField, value) => {
    const newEditingAssetFields = {
      ...this.state.editingAssetFields,
      [templateField.id]: value,
    };

    this.setState({
      editingAssetFields: newEditingAssetFields,
    });
  };

  renderTemplateField = templateField => {
    return (
      <TemplateField
        templateField={templateField}
        editing
        value={this.getAssetFieldValueForTemplateField(templateField)}
        onValueChange={value => {
          this.setAssetFieldValue(templateField, value);
        }}
      />
    );
  };

  getFieldsInColumn = (column, templateFields) => {
    return templateFields
      .filter(templateField => templateField.column === column)
      .sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
  };

  renderTemplateFields = () => {
    const { templateFields } = this.props;
    const { editingAsset } = this.state;

    if (templateFields.length === 0) return null;

    const generalFields = templateFields.filter(templateField => templateField.asset_type_id === null);
    let templateFieldsForAssetType = [];
    if (editingAsset.asset_type_id !== null) {
      templateFieldsForAssetType = templateFields.filter(
        templateField => templateField.asset_type_id === editingAsset.asset_type_id
      );
    }

    const generalCol1 = this.getFieldsInColumn(1, generalFields);
    const generalCol2 = this.getFieldsInColumn(2, generalFields);

    let generalRows = [];
    for (var i = 0; i < Math.max(generalCol1.length, generalCol2.length); i++) {
      generalRows = [
        ...generalRows,
        <Grid.Row key={i}>
          <Grid.Column>{generalCol1[i] ? this.renderTemplateField(generalCol1[i]) : null}</Grid.Column>
          <Grid.Column>{generalCol2[i] ? this.renderTemplateField(generalCol2[i]) : null}</Grid.Column>
        </Grid.Row>,
      ];
    }
    if (!templateFieldsForAssetType.length) {
      return (
        <React.Fragment>
          <Grid>
            <Grid.Separator />
            {generalRows}
          </Grid>
        </React.Fragment>
      );
    }

    let assetTypeRows = [];
    if (templateFieldsForAssetType.length > 0) {
      const assetTypeCol1 = this.getFieldsInColumn(1, templateFieldsForAssetType);
      const assetTypeCol2 = this.getFieldsInColumn(2, templateFieldsForAssetType);
      for (var j = 0; j < Math.max(assetTypeCol1.length, assetTypeCol2.length); j++) {
        assetTypeRows = [
          ...assetTypeRows,
          <Grid.Row key={[i, 'for_asset_type'].join('_')}>
            <Grid.Column>{assetTypeCol1[j] ? this.renderTemplateField(assetTypeCol1[j]) : null}</Grid.Column>
            <Grid.Column>{assetTypeCol2[j] ? this.renderTemplateField(assetTypeCol2[j]) : null}</Grid.Column>
          </Grid.Row>,
        ];
      }
    }

    return (
      <React.Fragment>
        <Grid>
          <Grid.Separator />
          {generalRows}
        </Grid>
        <Grid.Separator />
        <Grid>{assetTypeRows}</Grid>
      </React.Fragment>
    );
  };

  renderImage = () => {
    if (this.props.sparePart.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.sparePart.images[0]})`,
        }}
      />
    );
  };

  renderSparePartFields = () => {
    if (!this.props.createForSparePartId) return null;
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['spare-part-field']}>
              {this.renderImage()}
              <div className={styles['title']}>
                <Title sparePart={this.props.sparePart} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.quantity" />}>
              <Field.Number
                value={this.state.spare_part_asset.quantity}
                rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
                onChange={quantity => {
                  this.setState({
                    spare_part_asset: {
                      ...this.state.spare_part_asset,
                      quantity,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderVendorFields = () => {
    if (!this.props.createForVendorId) return null;
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['title-field']}>
              <p className={styles['title']}>{this.props.vendor.name}</p>
              <p className={styles['subtitle']}>{getVendorSubtitle(this.props.vendor)}</p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.asset-vendor.comment" />}>
              <Field.Textarea
                value={this.state.asset_vendor.comment}
                onChange={comment => {
                  this.setState({
                    asset_vendor: {
                      ...this.state.asset_vendor,
                      comment,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderFooter = () => (
    <Button.Group>
      <Button primary label="general.save" loading={this.state.isSaving} onClick={() => this.save()} />
      <Button
        label="general.save-and-create-new"
        loading={this.state.isSavingWithCreateNew}
        onClick={() => this.save(true)}
      />
      <Button label="general.cancel" onClick={this.props.onClose} />
    </Button.Group>
  );

  renderNumberAlreadyExistsError = () => {
    if (this.state.showAssetNumberAlreadyExistsWarning) {
      return (
        <span className={styles['number-already-exists']}>
          <FormattedMessage id="components.new-asset-modal.number-already-exists" />
        </span>
      );
    }
    return null;
  };

  renderTitleAndNumberField = () => {
    if (this.props.settings.asset_number_activated) {
      return (
        <Grid.Row>
          <Grid.Column md={8}>
            <Field label={<FormattedMessage id="resources.asset.title" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showTitleError}
                errorElement={<FormattedMessage id="general.errors.is-required" />}
              >
                <Field.Text
                  autoFocus
                  error={this.state.showTitleError}
                  value={this.state.editingAsset.title}
                  onChange={title => {
                    if (this.state.showTitleError && title.length > 0) {
                      this.setState({ showTitleError: false });
                    }
                    this.setAssetValue({ title });
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
          <Grid.Column md={4}>
            <Field label={<FormattedMessage id="resources.asset.number" />}>
              <Field.Text
                error={this.state.showAssetNumberAlreadyExistsWarning}
                rightLabel={this.state.isCheckingDuplicateAssetNumber ? <Loader tiny /> : null}
                value={this.state.editingAsset.number}
                placeholder={this.props.currentSystem.next_asset_number}
                onChange={number => {
                  this.setAssetValue({ number });
                }}
                onBlur={number => {
                  checkDuplicateAssetsRequest.cancel();
                  if (number) {
                    this.setState({ isCheckingDuplicateAssetNumber: true });
                    this.checkDuplicatedAssetNumber(number);
                  } else {
                    this.setState({
                      showAssetNumberAlreadyExistsWarning: false,
                    });
                  }
                }}
              />
            </Field>
          </Grid.Column>
          {this.renderNumberAlreadyExistsError()}
        </Grid.Row>
      );
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.asset.title" />}>
            <FieldErrorWrapper
              position="top"
              show={this.state.showTitleError}
              errorElement={<FormattedMessage id="general.errors.is-required" />}
            >
              <Field.Text
                autoFocus
                error={this.state.showTitleError}
                value={this.state.editingAsset.title}
                onChange={title => {
                  if (this.state.showTitleError && title.length > 0) {
                    this.setState({ showTitleError: false });
                  }
                  this.setAssetValue({ title });
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderContent = () => {
    return (
      <>
        <Grid>
          {this.renderSparePartFields()}
          {this.renderVendorFields()}
          {this.renderTitleAndNumberField()}
          <Grid.Row>
            <Grid.Column>
              <AssetTypeField
                editing
                value={this.state.editingAsset.asset_type_id}
                onChange={assetTypeId => {
                  this.setAssetValue({ asset_type_id: assetTypeId });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <TreeParentField
            onChange={treeParentId => {
              this.setAssetValue({ tree_parent_id: treeParentId });
            }}
            value={this.state.editingAsset.tree_parent_id}
          />
          {this.renderTemplateFields()}
        </Grid>
      </>
    );
  };

  renderSubtitle = () => {
    if (this.props.createForSparePartId) {
      return (
        <FormattedMessage
          id="components.new-asset-modal.spare-part-add-subtitle"
          values={{
            sparePart: (
              <span className={styles['highlight-text']}>
                <Title sparePart={this.props.sparePart} />
              </span>
            ),
          }}
        />
      );
    } else if (this.props.createForVendorId) {
      return (
        <FormattedMessage
          id="components.new-asset-modal.vendor-add-subtitle"
          values={{
            vendor: <span className={styles['highlight-text']}>{this.props.vendor.name}</span>,
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        footerComponent={this.renderFooter()}
        onClose={() => {
          if (this.state.hasUnsavedChanges) {
            const confirmed = window.confirm(
              this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
            );
            if (confirmed) {
              this.props.onClose();
            }
          } else {
            this.props.onClose();
          }
        }}
      >
        <SideModal.Container>
          <SideModal.Container.Content>
            <SideModal.Header
              title={<FormattedMessage id="components.new-asset-modal.title" />}
              subtitle={this.renderSubtitle()}
              onClose={this.props.onClose}
            />
            {this.renderContent()}
          </SideModal.Container.Content>
        </SideModal.Container>
      </SideModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAsset: SDKReduxOperations.createAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  var sparePartUnit = null;
  var sparePart = EntitySelectors.getSparePart(state, ownProps.createForSparePartId);
  if (sparePart != null)
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    templateFields: AuthSelectors.getAssetTemplateFields(state),
    sparePart,
    sparePartUnit,
    vendor: EntitySelectors.getVendor(state, ownProps.createForVendorId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewAssetModal));

NewAssetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onCreatedWithReopen: PropTypes.func.isRequired,
  title: PropTypes.element,
  footerComponent: PropTypes.element,
};
