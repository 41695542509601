import React from 'react';
import PropTypes from 'prop-types';
import ReactContentLoader from 'react-content-loader';

const ContentLoader = (props) => {
  let primaryColor;
  let secondaryColor;

  if(props.dark) {
    primaryColor="#00000014"
    secondaryColor="#0000000A"
  } else if (props.light) {
    primaryColor="#FFFFFF2B"
    secondaryColor="#FFFFFF3D"
  }

  return (
    <div style={{ width: props.width, height: props.height }}>
      <ReactContentLoader 
        width={props.width} 
        height={props.height} 
        viewBox={`0 0 ${props.width} ${props.height}`} 
        preserveAspectRatio="xMinYMin"
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      >
        {props.children}
      </ReactContentLoader>
    </div>
  );
};

export default ContentLoader;

ContentLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  dark: PropTypes.bool,
  light: PropTypes.bool,
};

ContentLoader.defaultProps = {
  dark: false,
  light: false,
};
