import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Status extends Component {
  renderStatus = status => {
    switch (status) {
      case WorkOrderStatus.NotStarted:
        return <FormattedMessage id="resources.work-order.statuses.not-started" />;
      case WorkOrderStatus.InProgress:
        return <FormattedMessage id="resources.work-order.statuses.in-progress" />;
      case WorkOrderStatus.Paused:
        return <FormattedMessage id="resources.work-order.statuses.paused" />;
      case WorkOrderStatus.Completed:
        return <FormattedMessage id="resources.work-order.statuses.completed" />;
      default:
        return null;
    }
  };

  render() {
    const { comparator, value } = this.props.filter.status;

    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.work-order.status" />}
        comparator={comparator}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Status,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.Status]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
              },
            },
          })
        }
      >
        <SideModal.Container.Filter.AppliedFilters.Values
          data={value}
          renderItem={status => this.renderStatus(status)}
        />
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);
