import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const RequestTypeContainer = ({ requestType }) => requestType.title;

function mapStateToProps(state, ownProps) {
  return {
    requestType: EntitySelectors.getRequestType(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(RequestTypeContainer);
