import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Field, Button, Banner, ElapsedTime } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { DowntimeTimer } from 'views/components/Downtime';
import { UserNameWrapper } from 'views/components/User';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class Info extends Component {
  state = {
    isSaving: false,
  };

  getTotalStopTimeAsMinutes = () => {
    const fromDate = moment(this.props.downtime.from);
    const toDate = moment(this.props.downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  downtimeIsActive = () => this.props.downtime.to == null;

  renderActiveDowntime = () => {
    return (
      <Banner orange>
        <FormattedMessage id="resources.downtime.total-downtime" />
        <span>:&nbsp;</span>
        <DowntimeTimer downtime={this.props.downtime} />
      </Banner>
    );
  };

  renderActiveDowntimeContainer = () => {
    return (
      <>
        {this.renderActiveDowntime()}
        <div className={styles['grid-container-active']}>
          <Grid>
            {this.props.canEditDowntimes && (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      small
                      gray
                      noUnderline
                      label="components.downtime-modal.complete-downtime"
                      onClick={this.props.onEdit}
                      loading={this.state.isSaving}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Separator />
              </>
            )}
            <Grid.Row>
              <Grid.Column>
                <Field view label={<FormattedMessage id="resources.downtime.started-at" />} fontSize={12}>
                  {moment(this.props.downtime.from).format('LLL')}
                </Field>
              </Grid.Column>
              <Grid.Column>
                <Field view label={<FormattedMessage id="resources.downtime.ended-at" />} fontSize={12}>
                  -
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field view label={<FormattedMessage id="resources.downtime.created-by" />} fontSize={12}>
                  <UserNameWrapper user={this.props.createdByUser} />
                </Field>
              </Grid.Column>
              <Grid.Column>
                <Field view label={<FormattedMessage id="resources.downtime.reason" />} fontSize={12}>
                  {this.props.downtimeReason ? this.props.downtimeReason.title : '-'}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field view label={<FormattedMessage id="resources.asset.resource" />} fontSize={12}>
                  <>
                    {this.props.asset.title}
                    {this.props.asset.tree_path.length === 0 ? null : (
                      <TreePath assetId={this.props.asset.id} />
                    )}
                  </>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>
    );
  };

  renderTotalStoptime = () => {
    return (
      <div className={styles['total-stop-time']}>
        <Banner orange>
          <FormattedMessage id="resources.downtime.total-downtime" />
          <span>:&nbsp;</span>
          <ElapsedTime elapsedMinutes={this.getTotalStopTimeAsMinutes()} />
        </Banner>
      </div>
    );
  };

  renderCompletedDowntime = () => (
    <>
      {this.renderTotalStoptime()}
      <div className={styles['grid-container']}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field view label={<FormattedMessage id="resources.downtime.started-at" />} fontSize={12}>
                {moment(this.props.downtime.from).format('LLL')}
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field view label={<FormattedMessage id="resources.downtime.ended-at" />} fontSize={12}>
                {moment(this.props.downtime.to).format('LLL')}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field view label={<FormattedMessage id="resources.downtime.created-by" />} fontSize={12}>
                <UserNameWrapper user={this.props.createdByUser} />
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field view label={<FormattedMessage id="resources.downtime.reason" />} fontSize={12}>
                {this.props.downtimeReason ? this.props.downtimeReason.title : '-'}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field view label={<FormattedMessage id="resources.asset.resource" />} fontSize={12}>
                <>
                  {this.props.asset.title}
                  {this.props.asset.tree_path.length === 0 ? null : (
                    <TreePath assetId={this.props.asset.id} />
                  )}
                </>
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );

  render() {
    return this.downtimeIsActive() ? this.renderActiveDowntimeContainer() : this.renderCompletedDowntime();
  }
}

function mapStateToProps(state, ownProps) {
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    canEditDowntimes: AuthSelectors.canEditDowntimes(state),
    asset: EntitySelectors.getAsset(state, downtime.asset_id),
    downtimeReason: EntitySelectors.getDowntimeReason(state, downtime.downtime_reason_id),
    createdByUser: EntitySelectors.getUser(state, downtime.created_by_user_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(Info);
