import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { PathItem, Icon } from 'views/components/Shared/General';
import { SparePartLocation } from 'views/components/SparePartLocation';
import SparePartLocationContainer from './SparePartLocationContainer';
import styles from './style.module.scss';

class SelectSparePartLocationModal extends Component {
  buildSparePartLocationForSparePartLocationId = sparePartLocationId => {
    const sparePartLocation = this.props.allSparePartLocations.find(
      sparePartLocation => sparePartLocationId === sparePartLocation.id
    );
    if (sparePartLocation == null) return [];
    let sparePartLocations = [sparePartLocation];
    this.props.allSparePartLocations.forEach(loopedSparePartLocation => {
      if (sparePartLocation.spare_part_location_parent_id === loopedSparePartLocation.id) {
        sparePartLocations = [
          ...this.buildSparePartLocationForSparePartLocationId(loopedSparePartLocation.id),
          ...sparePartLocations,
        ];
      }
    });
    return sparePartLocations;
  };

  renderSparePartLocationPathTitle = () => {
    const parentSparePartLocationId = this.props.parentSparePartLocationId;
    const sparePartLocationPath =
      this.buildSparePartLocationForSparePartLocationId(parentSparePartLocationId);
    return (
      <div className={styles['spare-part-location-path']}>
        <PathItem
          active={parentSparePartLocationId == null}
          clickable={parentSparePartLocationId != null}
          onClick={() => this.props.onNavigate(null)}
        >
          <FormattedMessage id="screens.settings.spare-parts.storage.root-title" />
        </PathItem>
        {parentSparePartLocationId == null ? null : (
          <React.Fragment>
            {sparePartLocationPath.map((sparePartLocation, index) => {
              if (index !== sparePartLocationPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem clickable onClick={() => this.props.onNavigate(sparePartLocation.id)}>
                      {sparePartLocation.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem active>{sparePartLocation.title}</PathItem>
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderSparePartLocations = () => {
    if (this.props.isFetching) {
      return <SparePartLocation loading />;
    } else {
      return this.props.sparePartLocations
        .sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map(sparePartLocation => (
          <SparePartLocationContainer
            hideSparePartCount={this.props.hideSparePartCount}
            sparePartLocation={sparePartLocation}
            selectedSparePartLocationId={this.props.selectedSparePartLocationId}
            onSelect={() => this.props.onSelect(sparePartLocation.id)}
            onNavigate={() => this.props.onNavigate(sparePartLocation.id)}
          />
        ));
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={styles['title-container']}>{this.renderSparePartLocationPathTitle()}</div>
        <div className={styles['spare-part-locations-container']}>
          <div className={styles['spare-part-locations']}>{this.renderSparePartLocations()}</div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocations: EntitySelectors.getSparePartLocationsForLocation(
      state,
      ownProps.parentSparePartLocationId
    ),
    allSparePartLocations: EntitySelectors.getSparePartLocations(state),
  };
}

export default connect(mapStateToProps)(SelectSparePartLocationModal);
