import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import PreviewWorkOrderHeader from './PreviewWorkOrderHeader';

class WorkOrder extends Component {
  render() {
    return (
      <>
        <PreviewWorkOrderHeader workOrderId={this.props.sparePartWithdrawal.work_order_id} />
        <PreviewWorkOrderContent workOrderId={this.props.sparePartWithdrawal.work_order_id} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartWithdrawal: EntitySelectors.getSparePartWithdrawal(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(WorkOrder);
