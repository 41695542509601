import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { List } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class RequestItem extends Component {
  renderCreatedByUser = () => {
    return (
      <List.Item.Column width={150} alignRight>
        <UserNameWrapper user={this.props.createdByUser} />
      </List.Item.Column>
    );
  };

  renderCreatedAt = () => {
    return (
      <List.Item.Column width={150} alignRight>
        {moment(this.props.request.created_at).format('LLL')}
      </List.Item.Column>
    );
  };

  render() {
    return (
      <List.Item clickable onClick={() => this.props.onSelect(this.props.request.id)}>
        <List.Item.TitleColumn title={this.props.request.title} />
        {this.renderCreatedByUser()}
        {this.renderCreatedAt()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { request } = ownProps;
  return {
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
  };
}

export default connect(mapStateToProps)(RequestItem);
