import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Type } from 'sdk/ChecklistTemplateRow';
import { Menu } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';

class SectionsMenuItem extends Component {
  componentDidMount() {
    const sections = this.props.checklistTemplateRows.filter(({ type }) => type === 'section');
    if (sections.length > 0) {
      const firstSection = sections.sort((a, b) => {
        if (a.parent_row_id && b.parent_row_id == null) {
          return 1;
        } else if (a.parent_row_id == null && b.parent_row_id) {
          return -1;
        }
        return a.sort - b.sort;
      });
      if (this.props.openedFromDeviations === false) {
        this.props.selectMenuItem(firstSection[0].id);
      }
    }
  }

  renderFilledRowsForChecklist = () => {
    if (this.props.checklistInstance) {
      return this.props.checklistInstanceRows.filter(({ value, template_row_type, meter_reading }) => {
        if (template_row_type === Type.Inspection) {
          return value != null;
        }
        if (template_row_type === Type.Meter) {
          return meter_reading != null;
        }
        return false;
      }).length;
    }
    return 0;
  };

  renderAmountOfFilledRowsForSection = templateField => {
    if (this.props.checklistInstance) {
      const checklistTemplateRowsInsideSection = this.props.checklistTemplateRows.filter(
        ({ parent_row_id }) => parent_row_id === templateField.id
      );

      return this.props.checklistInstanceRows.filter(
        ({ checklist_template_row_id, value, template_row_type, meter_reading }) => {
          if (template_row_type === Type.Inspection) {
            return checklistTemplateRowsInsideSection.find(
              ({ id }) => id === checklist_template_row_id && value != null
            );
          }
          if (template_row_type === Type.Meter) {
            return checklistTemplateRowsInsideSection.find(
              ({ id }) => id === checklist_template_row_id && meter_reading != null
            );
          }
          return false;
        }
      ).length;
    }
    return 0;
  };

  renderChecklistRowsWithoutSections = () => {
    return (
      <Menu.Item
        title={<FormattedMessage id="components.checklist-instance-modal.rows-recap-without-section" />}
        number={
          <span>
            {this.renderFilledRowsForChecklist()} / {this.props.checklistTemplateRows.length}
          </span>
        }
        selected={this.props.selectedMenuItem === 'checklist'}
        onClick={() => this.props.selectMenuItem('checklist')}
      />
    );
  };

  renderChecklistRowsWithSections = () => {
    return this.props.checklistTemplateRows
      .filter(({ type }) => type === 'section')
      .sort((a, b) => {
        if (a.parent_row_id && b.parent_row_id == null) {
          return 1;
        } else if (a.parent_row_id == null && b.parent_row_id) {
          return -1;
        }
        return a.sort - b.sort;
      })
      .map(templateField => {
        let amountOfRowsInSection = this.props.checklistTemplateRows.filter(
          ({ parent_row_id }) => parent_row_id === templateField.id
        ).length;
        return (
          <Menu.Item
            onClick={() => {
              this.props.selectMenuItem(templateField.id);
              setTimeout(() => {
                const sectionElement = document.getElementById(`sec:${templateField.id}`);
                sectionElement.scrollIntoView({
                  behavior: 'smooth',
                });
              });
            }}
            title={templateField.title}
            number={
              <span>
                {this.renderAmountOfFilledRowsForSection(templateField)} / {amountOfRowsInSection}
              </span>
            }
            selected={this.props.selectedMenuItem === templateField.id}
          />
        );
      });
  };

  renderRowsRecap = () => {
    const hasNoSections = this.props.checklistTemplateRows.find(({ type }) => type === 'section') == null;
    if (hasNoSections) {
      return this.renderChecklistRowsWithoutSections();
    }
    return this.renderChecklistRowsWithSections();
  };

  render() {
    return this.renderRowsRecap();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: ChecklistModalOperations.selectMenuItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  const checklistTemplate = ChecklistModalSelectors.getChecklistTemplate(state);
  if (checklistInstance) {
    const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
    return {
      checklistInstance,
      checklistTemplateVersion,
      checklistInstanceRows: EntitySelectors.getChecklistInstanceRows(
        state,
        checklistInstance.checklist_instance_rows
      ),
      checklistTemplateRows: EntitySelectors.getChecklistTemplateRows(
        state,
        checklistTemplateVersion.checklist_template_rows
      ),
      selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
      isFetching: ChecklistModalSelectors.isFetching(state),
    };
  } else if (checklistTemplate) {
    const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version_id
    );
    return {
      checklistTemplateVersion,
      checklistTemplateRows: EntitySelectors.getChecklistTemplateRows(
        state,
        checklistTemplateVersion.checklist_template_rows
      ),
      selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
      isFetching: ChecklistModalSelectors.isFetching(state),
    };
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionsMenuItem);
