import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import { AppLoader } from 'views/layout/AppLayout/components';
import Routes from './routes';
import styles from './style.scss';

class App extends Component {
  constructor(props) {
    super(props);
    const { token } = queryString.parse(window.location.search) || {};
    const parsedData = window.location.pathname.split('/');
    const systemId = parsedData[1];
    if (localStorage.getItem('gToken')) {
      this.props.prepareAuthenticatedGroupUser();
    }
    if (localStorage.getItem('token')) {
      this.props.prepareAuthenticatedUser(localStorage.getItem('token'), systemId);
    } else if (token) {
      this.props.prepareAuthenticatedUser(token);
    } else {
      this.props.unauthenticateUser();
    }
  }

  render() {
    return <div className={styles.container}>{this.props.isPreparingApp ? <AppLoader /> : <Routes />}</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prepareAuthenticatedUser: AuthOperations.prepareAuthenticatedUser,
      prepareAuthenticatedGroupUser: AuthOperations.prepareAuthenticatedGroupUser,
      unauthenticateUser: AuthOperations.unauthenticateUser,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    isPreparingApp: AuthSelectors.isPreparingApp(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
