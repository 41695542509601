import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartLocation } from 'views/components/SparePartLocation';

class SparePartLocationContainer extends Component {
  render() {
    return (
      <SparePartLocation
        id={this.props.sparePartLocation.id}
        selectable
        hideSparePartCount={this.props.hideSparePartCount}
        selected={this.props.selectedSparePartLocationId == this.props.sparePartLocation.id}
        hasChildren={this.props.sparePartLocationChildren.length > 0}
        onSelect={() => this.props.onSelect(this.props.sparePartLocation)}
        onNavigate={() => this.props.onNavigate(this.props.sparePartLocation)}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocationChildren: EntitySelectors.getSparePartLocationsForLocation(
      state,
      ownProps.sparePartLocation.id
    ),
  };
}

export default connect(mapStateToProps)(SparePartLocationContainer);
