import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { normalizeAssetVendor } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { Loader, Field, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetVendorModal extends Component {
  state = {
    isSaving: false,
    isDeleting: false,
    showConfirmDeleteModal: false,
    assetParams: {
      comment: '',
    },
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.selectedAssetVendorId !== null) {
        this.setState({
          assetParams: {
            comment: this.props.assetVendor.comment,
          },
        });
      } else {
        this.setState({
          assetParams: {},
        });
      }
    }
  }
  isLoaded = () =>
    (this.props.selectedAssetVendorId != null || this.props.vendorId != null) &&
    !this.props.loading &&
    this.props.vendor != null;
  close = () => {
    if (this.props.selectedAssetVendorId !== null) {
      this.props.onClose('edit');
    } else {
      this.props.onClose();
    }
  };

  delete = () => {
    this.props.onDelete(this.props.assetVendor.id);
    this.close();
  };
  update = () => {
    this.setState({ isSaving: true });
    const params = {
      ...this.state.assetParams,
      asset_id: this.props.asset.id,
      vendor_id: this.props.vendor.id,
    };
    this.props.updateAssetVendor(this.props.assetVendor.id, params).then(res => {
      const { data } = res;
      const { entities } = normalizeAssetVendor(data);
      this.props.updateEntities(entities);
      toast(<ToastMessage success text={<FormattedMessage id="screens.vendor.assets.update-success" />} />);
      this.close();
      this.setState({ isSaving: false });
    });
  };
  create = () => {
    this.setState({
      isSaving: true,
    });
    const params = {
      ...this.state.assetParams,
      asset_id: this.props.assetId,
      vendor_id: this.props.vendorId,
    };
    this.props
      .createAssetVendor(this.props.currentSystem.id, params)
      .then(res => {
        const { data } = res;
        const { entities } = normalizeAssetVendor(data);
        this.props.updateEntities(entities);
        toast(<ToastMessage success text={<FormattedMessage id="screens.vendor.assets.create-success" />} />);
        this.close();
        this.setState({ isSaving: false });
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="screens.vendor.assets.asset-vendor-modal.comment" />}>
              <Field.Textarea
                disabled={!this.props.canEditAssets && !this.props.canEditVendors}
                autoFocus
                value={this.state.assetParams.comment}
                onChange={comment => {
                  this.setState({ assetParams: { comment } });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.isLoaded) return <Loader />;
    else
      return (
        <>
          <Modal.Header
            ignoreLine
            title={
              this.props.canEditAssets && this.props.canEditVendors ? (
                this.props.selectedAssetVendorId !== null ? (
                  <FormattedMessage id="screens.vendor.assets.asset-vendor-modal.edit-title" />
                ) : (
                  <FormattedMessage id="screens.vendor.assets.asset-vendor-modal.title" />
                )
              ) : (
                <FormattedMessage id="screens.vendor.assets.asset-vendor-modal.view-title" />
              )
            }
            subtitle={
              <FormattedMessage
                id="screens.vendor.assets.asset-vendor-modal.vendor-name"
                values={{
                  vendor: <span className={styles['highlighted-text']}>{this.props.vendor.name}</span>,
                }}
              />
            }
            onClose={() => this.close()}
          />
          <Modal.Content>
            {this.renderTitleField()}
            {this.renderFields()}
          </Modal.Content>
        </>
      );
  };

  renderImage = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: this.props.size, height: this.props.size }}>
          <ContentLoader viewBox={`0 0 ${this.props.size} ${this.props.size}`} preserveAspectRatio="xMinYMin">
            <circle cx={this.props.size / 2} cy={this.props.size / 2} r={this.props.size / 2} />
          </ContentLoader>
        </div>
      );
    }
    if (this.props.asset.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.asset.images[0]})`,
        }}
      />
    );
  };

  renderTitleField = () => {
    if (this.props.asset == null) {
      return null;
    }
    return (
      <div className={styles['title-field']}>
        {this.renderImage()}
        <div className={styles['title']}>
          <AssetTitle id={this.props.asset.id} />
          <TreePath assetId={this.props.asset.id} />
        </div>
      </div>
    );
  };

  render() {
    if (!this.isLoaded()) return <Loader />;
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          {this.props.canEditAssets && this.props.canEditVendors ? (
            <Modal.Footer>
              <div className={styles['button-group']}>
                <Button.Group>
                  <Button
                    primary
                    loading={this.state.isSaving}
                    label="general.save"
                    onClick={() => {
                      this.props.selectedAssetVendorId ? this.update() : this.create();
                    }}
                  />
                  <Button label="general.cancel" onClick={() => this.close()} />
                </Button.Group>
                {this.props.selectedAssetVendorId ? (
                  <Button primary destructive label="general.delete" onClick={() => this.delete()} />
                ) : null}
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAssetVendor: SDKReduxOperations.deleteAssetVendor,
      updateAssetVendor: SDKReduxOperations.updateAssetVendor,
      createAssetVendor: SDKReduxOperations.createAssetVendor,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  var asset = null;
  var assetVendor = null;
  var vendor = null;
  if (ownProps.selectedAssetVendorId !== null) {
    assetVendor = EntitySelectors.getAssetVendor(state, ownProps.selectedAssetVendorId);
    asset = EntitySelectors.getAsset(state, assetVendor.asset_id);
    vendor = EntitySelectors.getVendor(state, assetVendor.vendor_id);
  } else {
    asset = EntitySelectors.getAsset(state, ownProps.assetId);
    vendor = EntitySelectors.getVendor(state, ownProps.vendorId);
  }
  return {
    asset,
    vendor,
    assetVendor,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetVendorModal);
