import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { API } from 'sdk';
import { RequestsSelectors } from 'state/ducks/requests';
import { PrintModal as GeneralPrintModal } from 'views/components/General';
import { Field } from 'views/components/Shared/General';

class PrintModal extends Component {
  getInitialState = () => ({
    printMode: this.props.exportType || ExportType.RequestsListPdf,
    isPrinting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
    exclude_images: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.checkedRequestCount >= maxQuantityAllowedForType(this.state.printMode),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.singleRequestId) {
      return { id: this.props.singleRequestId };
    }
    if (this.props.totalEntriesIsSelected) {
      return {
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
        ...this.props.listParams,
      };
    }
    const sort = this.props.queryParameters['sort'];
    const sortOrder = this.props.queryParameters['sort-order'];
    let params = {
      id: {
        any: Object.keys(this.props.checkedRequestIds).toString(),
      },
    };
    if (sort) {
      params = {
        ...params,
        sort,
      };
    }
    if (sortOrder) {
      params = {
        ...params,
        'sort-order': sortOrder,
      };
    }
    return params;
  };

  print = () => {
    this.setState({ isPrinting: true });
    let params = this.buildFilterParams();
    if (this.state.printMode == ExportType.RequestsDetailPdf) {
      params = {
        ...params,
        exclude_images: this.state.exclude_images,
      };
    }
    API.createExport(this.props.system.id, {
      params: params,
      type: this.state.printMode,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    if (this.props.singleRequestId)
      return <FormattedMessage id="components.print-modal.choose-information" />;
    return (
      <FormattedPlural
        value={this.props.checkedRequestCount}
        one={<FormattedMessage id="components.export-modal.total-requests.one" />}
        two={
          <FormattedMessage
            id="components.export-modal.total-requests.two"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.export-modal.total-requests.few"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.export-modal.total-requests.many"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.export-modal.total-requests.other"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
      />
    );
  };

  renderMaxQuantityErrorMessage = () => {
    if (this.state.showQuantityExceeded) {
      return <FormattedMessage id="components.export-modal.pdf-quantity-exceeded" />;
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.singleRequestId) {
      return <FormattedMessage id="components.print-request-modal.title" />;
    }
    return <FormattedMessage id="components.export-modal.title-print" />;
  };

  renderDetailPrintOptions = () => {
    return (
      <Field.Checkbox.Group>
        <Field.Checkbox
          checked
          label={<FormattedMessage id="components.print-request-modal.request-info" />}
          disabled
        />
        <Field.Checkbox
          checked={!this.state.exclude_images}
          label={<FormattedMessage id="components.print-request-modal.images" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_images: !prevState.exclude_images,
            }));
          }}
        />
      </Field.Checkbox.Group>
    );
  };

  render() {
    return (
      <GeneralPrintModal
        open={this.props.open}
        list={this.state.printMode == ExportType.RequestsListPdf}
        detail={this.state.printMode == ExportType.RequestsDetailPdf}
        detailOnly={this.props.singleRequestId != null}
        onSelectList={() => this.setState({ printMode: ExportType.RequestsListPdf })}
        onSelectDetail={() => this.setState({ printMode: ExportType.RequestsDetailPdf })}
        onClose={this.props.onClose}
        title={this.renderTitle()}
        subtitle={this.renderSubtitle()}
        onPrint={this.print}
        detailOptions={this.renderDetailPrintOptions()}
        loadingForExportId={this.state.showExportLoaderForId}
        loading={this.state.isPrinting}
        warning={this.renderMaxQuantityErrorMessage()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: RequestsSelectors.getQueryParameters(state),
    filtersAsQueryParams: RequestsSelectors.getFiltersAsQueryParams(state),
    checkedRequestIds: RequestsSelectors.getSelectedRequestIds(state),
    checkedRequestCount: RequestsSelectors.getSelectedRequestCount(state),
    totalEntriesIsSelected: RequestsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default connect(mapStateToProps)(PrintModal);

PrintModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.RequestsXslx, ExportType.RequestsListPdf]),
  singleRequestId: PropTypes.string,
};

PrintModal.defaultProps = {
  exportType: null,
  singleRequestId: null,
};
