import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import queryString from 'query-string';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { DowntimesOperations, DowntimesSelectors } from 'state/ducks/downtimes';
import { MenuOperations, MenuUtils } from 'state/ducks/menu';
import {
  Button,
  Menu,
  DatePicker,
  List,
  InlineModal,
  Pagination,
  WhiteCard,
  EmptyDataSet,
  FilterButton,
} from 'views/components/Shared/General';
import { DowntimeModal, NewDowntimeModal } from 'views/components/Downtime';
import { ListLayout } from 'views/components/Shared/Layout';
import { AssetMenu, BookmarkedAssetsDropdown } from 'views/components/Asset';
import DowntimeListItem from './DowntimeListItem';
import styles from './style.module.scss';
import ExportModal from './ExportModal';
import { isEqual } from 'lodash-es';

const listDowntimesRequest = SDKHelperFunctions.getCancelTokenForRequest();

const LIST_TYPES = {
  Active: 'active',
  All: 'all',
};

class Downtimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isFetchingMenu: true,
      amoutOfActiveDowntimes: 0,
      showExportModal: false,
      showNewDowntimeModal: false,
      showDowntimeModal: false,
      showDowntimeModalForId: null,
    };
    this.fetdhDebouncedDowntimes = debounce(() => {
      this.fetchDowntimes();
    }, 300);
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.downtimes.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Asset);
    this.props.resetSelectedDowntimes();
    this.fetchDowntimesAndCount();
  }

  componentDidUpdate(prevProps) {
    const { list: oldList, asset_with_tree_children_id: oldAssetId } = queryString.parse(
      prevProps.location.search
    );
    const { list: newList, asset_with_tree_children_id: newAssetId } = queryString.parse(
      this.props.location.search
    );
    const changedCheckedInAsset = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    const changedAssetDropdownParams = prevProps.assetDropdownParams !== this.props.assetDropdownParams;
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedList = oldList !== newList;
    const changedAsset = oldAssetId !== newAssetId;
    const onlyChangedPage = HelperFunctions.onlyPageWasChangedFromQueryParams(
      prevProps.queryParameters,
      this.props.queryParameters
    );
    if (onlyChangedPage) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.setState({ isFetching: true });
      this.fetdhDebouncedDowntimes();
      return;
    }
    if (changedAssetDropdownParams || changedList || changedAsset || changedCheckedInAsset) {
      this.addQueryParameter({ page: 1 });
    }
    if (
      changedAssetDropdownParams ||
      changedQueryParams ||
      changedList ||
      changedAsset ||
      changedCheckedInAsset
    ) {
      this.setState({ isFetching: true });
      this.props.resetSelectedDowntimes();
      this.fetdhDebouncedDowntimes();
    }
  }

  fetchDowntimesAndCount = () => {
    const { list } = queryString.parse(this.props.location.search);
    API.listDowntimes(this.props.currentSystem.id, {
      to: {
        [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
      },
      ...this.getAssetParams(),
    }).then(({ headers }) => {
      const amoutOfActiveDowntimes = SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries;
      this.setState({ amoutOfActiveDowntimes });
      if (amoutOfActiveDowntimes === 0) {
        if (list === LIST_TYPES.All) {
          this.fetchDowntimes();
        } else {
          this.changeList(LIST_TYPES.All);
        }
      } else {
        if (list === LIST_TYPES.Active) {
          this.fetchDowntimes();
        } else {
          this.changeList(LIST_TYPES.Active);
        }
      }
    });
  };

  fetchDowntimes = () => {
    listDowntimesRequest.cancel();
    let queryParameters = {
      ...this.props.queryParameters,
    };
    if (queryParameters.from && queryParameters.to) {
      const { from, to } = queryParameters;
      queryParameters = {
        ...queryParameters,
        to: null,
        from: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Between]: `${from}...${to}`,
        },
      };
    }

    let attrs = {
      ...queryParameters,
      ...this.getListParams(),
      ...this.getAssetParams(),
    };

    return this.props
      .listDowntimes(this.props.currentSystem.id, attrs, listDowntimesRequest.getCancelTokenConfig())
      .then(downtTimes => {
        this.setState({ isFetching: false, isFetchingMenu: false });
        return downtTimes;
      })
      .catch(error => {});
  };

  getAssetParams = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    if (this.props.isProductionSupervisor) {
      return this.getAssetParamsForProductionSupervisor();
    } else if (this.props.isOperator) {
      return this.getAssetParamsForOperator();
    } else if (asset_with_tree_children_id) {
      return {
        asset_with_tree_children_id,
      };
    }
  };

  getAssetParamsForOperator = () => {
    if (this.props.isOperator && this.props.checkedInAssetId) {
      return {
        asset_with_tree_children_id: this.props.checkedInAssetId,
      };
    }
    return {};
  };

  getAssetParamsForProductionSupervisor = () => {
    if (this.props.isProductionSupervisor && this.props.assetDropdownParams) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: this.props.assetDropdownParams,
        },
      };
    }
    return {};
  };

  getListParams = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.Active: {
        return {
          to: {
            [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
          },
          sort: 'from',
          'sort-order': 'desc',
        };
      }

      case LIST_TYPES.All: {
        return {
          sort: 'from',
          'sort-order': 'desc',
        };
      }

      default: {
        return {};
      }
    }
  };

  changeList = list => {
    const { list: listInUrl } = queryString.parse(this.props.location.search);
    if (list === listInUrl) return;
    const params = { list, asset_with_tree_children_id: null };
    this.changeQueryParams(params);
  };

  changeQueryParams = obj => {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        ...obj,
      })}`
    );
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  renderBookmarkedAssetsDropdown = () => {
    if (!this.props.isProductionSupervisor) return null;
    return <BookmarkedAssetsDropdown />;
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        leftContainer={this.renderBookmarkedAssetsDropdown()}
        title={<FormattedMessage id="screens.downtimes.title" />}
        totalEntries={
          <FormattedPlural
            value={this.props.totalEntries}
            zero={
              <FormattedMessage
                id="screens.downtimes.pagination.zero"
                values={{ amount: this.state.isFetching ? '--' : 0 }}
              />
            }
            one={
              <FormattedMessage
                id="screens.downtimes.pagination.one"
                values={{ amount: this.state.isFetching ? '--' : 1 }}
              />
            }
            two={
              <FormattedMessage
                id="screens.downtimes.pagination.two"
                values={{
                  amount: this.state.isFetching ? '--' : this.props.totalEntries,
                }}
              />
            }
            few={
              <FormattedMessage
                id="screens.downtimes.pagination.few"
                values={{
                  amount: this.state.isFetching ? '--' : this.props.totalEntries,
                }}
              />
            }
            many={
              <FormattedMessage
                id="screens.downtimes.pagination.many"
                values={{
                  amount: this.state.isFetching ? '--' : this.props.totalEntries,
                }}
              />
            }
            other={
              <FormattedMessage
                id="screens.downtimes.pagination.other"
                values={{
                  amount: this.state.isFetching ? '--' : this.props.totalEntries,
                }}
              />
            }
          />
        }
      />
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isFetching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.downtimeIds.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedDowntimes()}
        />
      );
    }
    return null;
  };

  renderEmptyDataSet = () => (
    <WhiteCard padding="50px">
      <EmptyDataSet
        title={<FormattedMessage id="screens.asset.downtimes.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.asset.downtimes.empty-data-set.subtitle" />}
      />
    </WhiteCard>
  );

  renderPagination = () => {
    if (this.props.downtimeIds.length === 0) {
      return null;
    }

    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => this.addQueryParameter({ page })}
          onChangePageSize={page_size => this.addQueryParameter({ page_size })}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <>
        <List.Header.Column>
          <FormattedMessage id="resources.downtime.started-at" />
        </List.Header.Column>
      </>
    );
  };

  renderListHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected } = this.props;
    const { isFetching } = this.state;
    return (
      <List.Header
        small
        background
        checkbox
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedDowntimes();
          } else {
            this.props.selectPage();
          }
        }}
        showMultipleOptions={this.props.selectedDowntimesCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.isFetching}
            count={this.props.selectedDowntimesCount}
            buttons={
              <>
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="screens.spare-parts.header-buttons.export" />}
                  onClick={() => {
                    this.setState({
                      showMoreOptionsForMultipleOptions: false,
                      showExportModal: true,
                    });
                  }}
                />
              </>
            }
          />
        }
      >
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderListHeader()}
          <List>
            <DowntimeListItem loading />
            <DowntimeListItem loading />
          </List>
        </>
      );
    }

    if (this.props.downtimeIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.downtimeIds.map(id => {
            let checked = false;
            if (this.props.selectedDowntimeIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <DowntimeListItem
                key={id}
                id={id}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={() => this.props.selectDowntime(id)}
                onClick={() => this.setState({ showDowntimeModal: true, showDowntimeModalForId: id })}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderAssetItems = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    return (
      <AssetMenu
        onSelectAssetId={assetId => {
          this.changeQueryParams({ asset_with_tree_children_id: assetId, list: null });
        }}
        selectedAssetId={asset_with_tree_children_id}
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.settings.downtime_activated && this.props.canEditDowntimes) {
      return (
        <>
          <Button
            primary
            fullWidth
            label="screens.downtimes.create-button"
            onClick={() => this.setState({ showNewDowntimeModal: true, showDowntimeModalForId: null })}
          />
          <Menu.Separator />
        </>
      );
    }
  };

  renderLeftMenuItems = () => {
    const { isFetchingMenu, amoutOfActiveDowntimes } = this.state;
    const { list } = queryString.parse(this.props.location.search);
    if (isFetchingMenu) {
      return (
        <>
          <Menu.Item loading />
          <Menu.Item loading />
        </>
      );
    }
    return (
      <>
        {amoutOfActiveDowntimes === 0 ? null : (
          <Menu.Item
            selected={list === LIST_TYPES.Active}
            number={amoutOfActiveDowntimes}
            title={<FormattedMessage id="screens.downtimes.left-panel.active" />}
            onClick={() => {
              this.changeList(LIST_TYPES.Active);
            }}
          />
        )}

        <Menu.Item
          selected={list === LIST_TYPES.All}
          title={<FormattedMessage id="screens.downtimes.left-panel.all" />}
          onClick={() => {
            this.changeList(LIST_TYPES.All);
          }}
        />
      </>
    );
  };

  renderAssetContainer = () => {
    const { isOperator, isProductionSupervisor } = this.props;
    if (isOperator || isProductionSupervisor) {
      return null;
    }

    return <div className={styles['asset-container']}>{this.renderAssetItems()}</div>;
  };

  renderLeftMenu = () => {
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-items']}>
            <ListLayout.Content.Menu.Content>
              {this.renderCreateButton()}
              {this.renderLeftMenuItems()}
            </ListLayout.Content.Menu.Content>
          </div>
          {this.renderAssetContainer()}
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderExportModal = () => {
    return (
      <ExportModal
        listParams={this.getListParams()}
        open={this.state.showExportModal}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  getDefaultTreeParentIdForNewDowntime = () => {
    if (this.props.isOperator) {
      return this.props.checkedInAssetId;
    }
    return null;
  };

  renderDowntimeModal = () => {
    return (
      <DowntimeModal
        open={this.state.showDowntimeModal}
        id={this.state.showDowntimeModalForId}
        defaultTreeParentId={this.getDefaultTreeParentIdForNewDowntime()}
        isCreatingActiveDowntime={this.state.isCreatingActiveDowntime}
        onClose={() => {
          this.setState({ showDowntimeModal: false });
        }}
      />
    );
  };

  renderNewDowntimeModal = () => (
    <NewDowntimeModal
      open={this.state.showNewDowntimeModal}
      onClose={() => {
        this.setState({ showNewDowntimeModal: false });
      }}
      onCreateCompletedDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: false });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true });
        }, 200);
      }}
      onCreateActiveDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: true });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true });
        }, 200);
      }}
    />
  );

  renderFilterDateButtonLabel = () => {
    const { from, to } = this.props.queryParameters;
    if (from && to) {
      return (
        <span>
          <span>{moment(from).format('L')}</span>
          <span> - </span>
          <span>{moment(to).format('L')}</span>
        </span>
      );
    }
    return <FormattedMessage id="screens.downtimes.filters.date" />;
  };

  renderToolbar = () => {
    const { from, to } = this.props.queryParameters;
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.FilterBar.LeftContent>
          <div ref={ref => (this.inlineModalPositioningRef = ref)}>
            <FilterButton
              filtered={from != null && to != null}
              onClear={e => {
                e.stopPropagation();
                this.addQueryParameter({ from: null, to: null });
                this.setState({ showDateFilterInlineModal: false });
              }}
              label={this.renderFilterDateButtonLabel()}
              onClick={() => {
                this.setState(prevState => ({
                  showDateFilterInlineModal: !prevState.showDateFilterInlineModal,
                }));
              }}
            />
          </div>
          <InlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.showDateFilterInlineModal}
            onClose={() => this.setState({ showDateFilterInlineModal: false })}
          >
            <InlineModal.Body width={337} paddingTop={20}>
              <DatePicker.Range
                inline
                onSelectDate={({ from, to }) => {
                  this.addQueryParameter({ from, to });
                  this.setState({ showDateFilterInlineModal: false });
                }}
                fromDate={from}
                toDate={to}
                disabledDays={{ after: moment().tz(this.props.currentSystem.timezone).toDate() }}
              />
            </InlineModal.Body>
          </InlineModal>
        </ListLayout.Content.MainContent.FilterBar.LeftContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderExportModal()}
        {this.renderNewDowntimeModal()}
        {this.renderDowntimeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSelectedDowntimes: DowntimesOperations.resetSelectedDowntimes,
      selectMenuItem: MenuOperations.selectItem,
      listDowntimes: DowntimesOperations.listDowntimes,
      addQueryParameter: DowntimesOperations.addQueryParameter,
      selectPage: DowntimesOperations.selectPage,
      selectDowntime: DowntimesOperations.selectDowntime,
      selectTotalEntries: DowntimesOperations.selectTotalEntries,
      hideSelectTotalEntries: DowntimesOperations.hideSelectTotalEntries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isOperator: AuthSelectors.isOperator(state),
    settings: AuthSelectors.getSettings(state),
    canEditDowntimes: AuthSelectors.canEditDowntimes(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    queryParameters: DowntimesSelectors.getQueryParameters(state),
    pagination: DowntimesSelectors.getPagination(state),
    downtimeIds: DowntimesSelectors.getDowntimeIds(state),
    totalEntries: DowntimesSelectors.getTotalEntries(state),
    totalEntriesIsSelected: DowntimesSelectors.getTotalEntriesIsSelected(state),
    pageIsSelected: DowntimesSelectors.getPageIsSelected(state),
    selectedDowntimeIds: DowntimesSelectors.getSelectedDowntimeIds(state),
    selectedDowntimesCount: DowntimesSelectors.getSelectedDowntimesCount(state),
    showSelectTotalEntries: DowntimesSelectors.getShowSelectTotalEntries(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Downtimes));
