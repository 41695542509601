import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';

export default props => (
  <Grid>
    <Grid.Row>
      <Grid.Column md={6}>
        <Field label={<FormattedMessage id="resources.system.company-name" />}>
          <Field.Text
            error={props.errors.company_name}
            value={props.editingSystem.company_name}
            onChange={company_name => {
              props.onChange({ company_name });
            }}
          />
        </Field>
      </Grid.Column>
      <Grid.Column md={6}>
        <Field label={<FormattedMessage id="resources.system.company-registration-number" />}>
          <Field.Text
            value={props.editingSystem.company_registration_number}
            onChange={company_registration_number => {
              props.onChange({ company_registration_number });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column md={6}>
        <Field label={<FormattedMessage id="resources.system.company-website" />}>
          <Field.Text
            value={props.editingSystem.company_website}
            onChange={company_website => {
              props.onChange({ company_website });
            }}
          />
        </Field>
      </Grid.Column>
      <Grid.Column md={6}>
        <Field label={<FormattedMessage id="resources.system.company-phone" />}>
          <Field.Text
            value={props.editingSystem.company_phone}
            onChange={company_phone => {
              props.onChange({ company_phone });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column md={6}>
        <Field label={<FormattedMessage id="resources.system.company-email" />}>
          <Field.Text
            value={props.editingSystem.company_email}
            onChange={company_email => {
              props.onChange({ company_email });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
