import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, Button, Icon, ConfirmModal } from 'views/components/Shared/General';
import { RowType } from 'sdk/QRTemplateRow';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import QrTemplateRowTitle from 'views/components/General/PrintQRCodeModal/QrTemplateRowTitle';

const updateRequest = HelperFunctions.getCancelTokenForRequest();

class QrTemplateRowSettings extends Component {
  getInititalState = () => ({
    editingQrTemplateRow: {},
    showConfirmDeleteModal: false,
    isDeleting: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
    this.debouncedSave = debounce(() => {
      this.props
        .updateQrTemplateRow(
          this.props.qrTemplateRowId,
          this.getEditingChanges(),
          updateRequest.getCancelTokenConfig()
        )
        .then(() => {
          this.props.onHideSaveContainer();
        })
        .catch(() => {});
    }, 150);
  }

  componentDidMount() {
    this.setState({
      editingQrTemplateRow: {
        ...this.props.qrTemplateRow,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.qrTemplateRowId !== this.props.qrTemplateRowId) {
      this.setState({
        editingQrTemplateRow: {
          ...this.props.qrTemplateRow,
        },
      });
    }
  }

  getEditingChanges = () => {
    let data = {};
    const editingQrTemplateRow = this.state.editingQrTemplateRow;
    const diffProps = Object.keys(editingQrTemplateRow).filter(
      k => editingQrTemplateRow[k] !== this.props.qrTemplateRow[k]
    );
    diffProps.forEach(key => (data[key] = editingQrTemplateRow[key]));
    return data;
  };

  setEditingQrTemplateRowValues = (params, options = { save: false }) => {
    this.setState(
      {
        editingQrTemplateRow: {
          ...this.state.editingQrTemplateRow,
          ...params,
        },
      },
      () => {
        if (options.save === true) {
          updateRequest.cancel();
          this.props.onShowSaveContainer();
          this.debouncedSave();
        }
      }
    );
  };

  renderTextFieldIcons = () => {
    const { editingQrTemplateRow } = this.state;
    if (this.props.qrTemplateRow.row_type !== RowType.Qr) {
      return (
        <>
          <div
            className={[styles['text-icon'], ...(editingQrTemplateRow.bold ? [styles['selected']] : [])].join(
              ' '
            )}
            onClick={() => {
              this.setEditingQrTemplateRowValues({ bold: !editingQrTemplateRow.bold }, { save: true });
            }}
          >
            <Icon regular type="bold" />
          </div>
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.italic ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              this.setEditingQrTemplateRowValues({ italic: !editingQrTemplateRow.italic }, { save: true });
            }}
          >
            <Icon regular type="italic" />
          </div>
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.underline ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              const underline = !editingQrTemplateRow.underline;
              let strikethrough = editingQrTemplateRow.strikethrough;
              if (underline && strikethrough) {
                strikethrough = false;
              }
              this.setEditingQrTemplateRowValues(
                {
                  underline,
                  strikethrough,
                },
                { save: true }
              );
            }}
          >
            <Icon regular type="underline" />
          </div>
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.strikethrough ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              const strikethrough = !editingQrTemplateRow.strikethrough;
              let underline = editingQrTemplateRow.underline;
              if (underline && strikethrough) {
                underline = false;
              }
              this.setEditingQrTemplateRowValues(
                {
                  underline,
                  strikethrough,
                },
                { save: true }
              );
            }}
          >
            <Icon regular type="strikethrough" />
          </div>
          <div className={styles['vertical-separator']} />
        </>
      );
    }
    return null;
  };

  renderIcons = () => {
    const { editingQrTemplateRow } = this.state;

    return (
      <>
        <div className={styles['text-icons']}>
          {this.renderTextFieldIcons()}
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.horizontal_align === 'left' ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              this.setEditingQrTemplateRowValues({ horizontal_align: 'left' }, { save: true });
            }}
          >
            <Icon regular type="align-left" />
          </div>
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.horizontal_align === 'center' ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              this.setEditingQrTemplateRowValues({ horizontal_align: 'center' }, { save: true });
            }}
          >
            <Icon regular type="align-center" />
          </div>
          <div
            className={[
              styles['text-icon'],
              ...(editingQrTemplateRow.horizontal_align === 'right' ? [styles['selected']] : []),
            ].join(' ')}
            onClick={() => {
              this.setEditingQrTemplateRowValues({ horizontal_align: 'right' }, { save: true });
            }}
          >
            <Icon regular type="align-right" />
          </div>
        </div>
      </>
    );
  };

  renderField = () => {
    return (
      <Field label={<FormattedMessage id="components.print-qr-codes.edit-row-tab.text-size" />}>
        <Field.Dropdown clearable={false} value={`${this.state.editingQrTemplateRow.font_size}px`}>
          {[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(fontSize => (
            <Field.Dropdown.Item
              selected={this.state.editingQrTemplateRow.font_size === fontSize}
              onClick={() => {
                this.setEditingQrTemplateRowValues({ font_size: fontSize }, { save: true });
              }}
            >
              {`${fontSize}px`}
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </Field>
    );
  };

  renderHorizontalAlignField = () => {
    const { editingQrTemplateColumn } = this.state;

    return (
      <Field label={<FormattedMessage id="components.print-qr-codes.edit-column-tab.vertical-adjustment" />}>
        <Field.Dropdown
          clearable={false}
          value={this.renderVerticalAlignValue(editingQrTemplateColumn.vertical_align)}
        >
          {['top', 'center', 'bottom'].map(value => (
            <Field.Dropdown.Item
              selected={editingQrTemplateColumn.vertical_align === value}
              onClick={() => {
                this.setEditingQrTemplateColumnValues({ vertical_align: value }, { save: true });
                this.props.onChangeColumnVerticalAlign(this.props.qrTemplateColumn.id, value);
              }}
            >
              {this.renderVerticalAlignValue(value)}
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </Field>
    );
  };

  renderQrCodeSizeFields = () => {
    const { editingQrTemplateRow } = this.state;

    return (
      <>
        <Field label={<FormattedMessage id="components.print-qr-codes.edit-row-tab.qr-code-size" edit />}>
          <Field.Decimal
            value={editingQrTemplateRow.qr_code_size}
            rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
            onChange={qr_code_size => {
              this.setEditingQrTemplateRowValues({ qr_code_size });
            }}
            onBlur={qr_code_size => {
              this.setEditingQrTemplateRowValues({ qr_code_size }, { save: true });
            }}
          />
        </Field>
      </>
    );
  };

  renderRowOptionsForRowType = () => {
    if (this.props.qrTemplateRow.row_type === RowType.Qr) {
      return (
        <>
          {this.renderQrCodeSizeFields()}
          {this.renderIcons()}
        </>
      );
    }
    return (
      <>
        {this.renderField()}
        {this.renderIcons()}
      </>
    );
  };

  renderMarginFields = () => {
    const { editingQrTemplateRow } = this.state;
    return (
      <div className={styles['margins-wrapper']}>
        <Field label={<FormattedMessage id="components.print-qr-codes.margins" edit />}>
          <div className={styles['margins-container']}>
            <div className={[styles['margins'], styles['top']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateRow.padding_top}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_top => {
                  this.setEditingQrTemplateRowValues({ padding_top });
                }}
                onBlur={padding_top => {
                  this.setEditingQrTemplateRowValues({ padding_top }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['left']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateRow.padding_left}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_left => {
                  this.setEditingQrTemplateRowValues({ padding_left });
                }}
                onBlur={padding_left => {
                  this.setEditingQrTemplateRowValues({ padding_left }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['right']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateRow.padding_right}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_right => {
                  this.setEditingQrTemplateRowValues({ padding_right });
                }}
                onBlur={padding_right => {
                  this.setEditingQrTemplateRowValues({ padding_right }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['bottom']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateRow.padding_bottom}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_bottom => {
                  this.setEditingQrTemplateRowValues({ padding_bottom });
                }}
                onBlur={padding_bottom => {
                  this.setEditingQrTemplateRowValues({ padding_bottom }, { save: true });
                }}
              />
            </div>
            <div className={styles['vertical-line']} />
            <div className={styles['horizontal-line']} />
          </div>
        </Field>
      </div>
    );
  };

  renderDeleteConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={<FormattedMessage id="components.print-qr-codes.confirm-delete-row-modal.title" />}
        message={<FormattedMessage id="components.print-qr-codes.confirm-delete-row-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => {
          let qrTemplateColumnId = this.props.qrTemplateRow.qr_template_column_id;
          let qrTemplateRowId = this.props.qrTemplateRow.id;
          this.setState({ isDeleting: true });
          this.props.deleteQrTemplateRow(this.props.qrTemplateRowId).then(() => {
            this.props.onDeleteRow(qrTemplateColumnId, qrTemplateRowId);
            this.props.onClose();
          });
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
        }}
      />
    );
  };

  renderSeparator = () => {
    return <div className={styles['separator']} />;
  };

  render() {
    return (
      <>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <QrTemplateRowTitle qrTemplateRowId={this.props.qrTemplateRow.id} />
            </div>
            <div className={styles['icon-buttons']}>
              <div>
                <Button
                  type="icon"
                  icon={<Icon regular red type="trash-alt" />}
                  onClick={() => this.setState({ showConfirmDeleteModal: true })}
                />
              </div>
              <div>
                <Button
                  type="icon"
                  icon={<Icon light size={18} type="times" />}
                  onClick={() => this.props.onClose()}
                />
              </div>
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage dark id={'components.print-qr-codes.edit-row-tab.settings-for-field'} />
          </div>
        </div>
        {this.renderRowOptionsForRowType()}
        {this.renderSeparator()}
        {this.renderMarginFields()}
        {this.renderDeleteConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplateRow: SDKReduxOperations.updateQrTemplateRow,
      deleteQrTemplateRow: SDKReduxOperations.deleteQrTemplateRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateRowId } = ownProps;

  let qrTemplateRow = EntitySelectors.getQrTemplateRow(state, qrTemplateRowId);
  let templateField = null;
  if (qrTemplateRow) {
    templateField = EntitySelectors.getTemplateField(state, qrTemplateRow.template_field_id);
  }

  return {
    qrTemplateRow,
    templateField,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrTemplateRowSettings);
