import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import Checklists from './Checklists';
import Basic from './Basic';

class ChecklistContainer extends Component {
  render() {
    if (this.props.hasProTier) {
      return <Checklists {...this.props} />;
    }
    return <Basic {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(ChecklistContainer);
