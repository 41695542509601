import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';

export default class ConfirmPlanAwaitingDeliveryModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={400}>
        <Modal.Header
          ignoreLine
          hideCloseButton
          title={
            <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.confirm-modal.title" />
          }
          subtitle={
            <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.confirm-modal.subtitle" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.ok" onClick={this.props.onPlan} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
