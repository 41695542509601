import { Type } from 'sdk/ChecklistTemplateRow';

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

/*
  checklistModal
*/

const getRootTemplateFieldIds = state => {
  const { entities, checklistModal } = state;
  const { menuItem, childIds, instanceRowForTemplateRowIds, deviations } = checklistModal;
  const { tab } = deviations;
  return checklistModal.templateFieldIds.filter(id => {
    const templateRow = entities.checklistTemplateRowById[id];
    if (menuItem === 'deviations') {
      if (templateRow.type === 'section') {
        return (
          childIds[id].filter(id => {
            const instanceRowId = instanceRowForTemplateRowIds[id];
            const { deviation, deviation_ignored, deviation_has_action } =
              entities.checklistInstanceRowById[instanceRowId];
            if (tab === 'require_action') {
              return deviation === true && deviation_ignored === false && deviation_has_action === false;
            }
            return deviation === true;
          }).length > 0
        );
      }
      const instanceRowId = instanceRowForTemplateRowIds[id];
      const { deviation, deviation_ignored, deviation_has_action } =
        entities.checklistInstanceRowById[instanceRowId];
      if (tab === 'require_action') {
        return deviation === true && deviation_ignored === false && deviation_has_action === false;
      }
      return deviation === true;
    } else {
      return true;
    }
  });
};

const getChildTemplateIdsForRow = (state, id) => {
  const { entities, checklistModal } = state;
  const { menuItem, instanceRowForTemplateRowIds, deviations } = checklistModal;
  const { tab } = deviations;
  return checklistModal.childIds[id].filter(id => {
    if (menuItem === 'deviations') {
      const instanceRowId = instanceRowForTemplateRowIds[id];
      const { deviation, deviation_ignored, deviation_has_action } =
        entities.checklistInstanceRowById[instanceRowId];
      if (tab === 'require_action') {
        return deviation === true && deviation_ignored === false && deviation_has_action === false;
      }
      return deviation === true;
    } else {
      return true;
    }
  });
};

const getInstanceRowForTemplateRowId = (state, templateRowId) => {
  const { checklistModal, entities } = state;
  let instanceRowId = checklistModal.instanceRowForTemplateRowIds[templateRowId];
  return entities.checklistInstanceRowById[instanceRowId];
};

const getChecklistInstanceOptionsForTemplateRowId = (state, templateRowId) => {
  const { checklistModal, entities } = state;
  let checklistInstanceOptionIds = checklistModal.instanceOptionIdsForTemplateRowIds[templateRowId] || [];
  return checklistInstanceOptionIds.map(id => entities.checklistInstanceOptionById[id]);
};

const getChecklistTemplate = state => {
  const { checklistModal, entities } = state;
  return entities.checklistTemplateById[checklistModal.templateId];
};

const getChecklistInstance = state => {
  const { checklistModal, entities } = state;
  return entities.checklistInstanceById[checklistModal.instanceId];
};

const getChecklistTemplateLink = state => {
  const { checklistModal, entities } = state;
  return entities.checklistTemplateLinkById[checklistModal.templateLinkId];
};

const getAllRowsHasBeenFilledIn = state => {
  const { checklistModal, entities } = state;
  const checklistInstanceRowIds = Object.values(checklistModal.instanceRowForTemplateRowIds);
  return (
    checklistInstanceRowIds.length ===
    checklistInstanceRowIds
      .map(id => {
        return entities.checklistInstanceRowById[id];
      })
      .filter(instanceRow => {
        if (instanceRow.template_row_type === Type.Inspection) {
          return instanceRow.value != null;
        } else if (instanceRow.template_row_type === Type.Meter) {
          return instanceRow.meter_reading != null;
        }
        return false;
      }).length
  );
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const isEditable = state => {
  const { checklistModal } = state;
  return checklistModal.editable;
};

const isFetching = state => {
  const { checklistModal } = state;
  return checklistModal.isFetching;
};

const getSelectedMenuItem = state => {
  const { checklistModal } = state;
  return checklistModal.menuItem;
};

const getSelectedDeviationTab = state => {
  const { checklistModal } = state;
  return checklistModal.deviations.tab;
};

const getSelectedInstanceRows = (state, instanceRowId) => {
  const { checklistModal } = state;
  return Object.keys(checklistModal.deviations.selectedChecklistInstanceRowIds);
};
const isChecklistInstanceRowSelected = (state, instanceRowId) => {
  const { checklistModal } = state;
  return checklistModal.deviations.selectedChecklistInstanceRowIds[instanceRowId];
};

export default {
  isFetching,
  getChecklistInstance,
  getRootTemplateFieldIds,
  getChildTemplateIdsForRow,
  getInstanceRowForTemplateRowId,
  getChecklistInstanceOptionsForTemplateRowId,
  isEditable,
  getChecklistTemplate,
  getSelectedMenuItem,
  getSelectedDeviationTab,
  isChecklistInstanceRowSelected,
  getSelectedInstanceRows,
  getChecklistTemplateLink,
  getAllRowsHasBeenFilledIn,
};
