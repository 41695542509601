import types from './types';

const INITIAL_STATE = {
  isFetchingOperationalMaintenanceVersion: false,
  currentOperationalMaintenanceTemplateVersionId: null,
  activeOperationalMaintenanceTemplateVersionId: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return INITIAL_STATE;
    }
    case types.SET_ACTIVE_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION: {
      const id = action.payload;
      return {
        ...state,
        activeOperationalMaintenanceTemplateVersionId: id,
      };
    }
    case types.SET_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION: {
      const id = action.payload;
      return {
        ...state,
        currentOperationalMaintenanceTemplateVersionId: id,
        isFetchingOperationalMaintenanceVersion: true,
      };
    }
    case types.FETCH_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        currentOperationalMaintenanceTemplateVersionId: id,
        isFetchingOperationalMaintenanceVersion: false,
      };
    }
    default:
      return state;
  }
};
