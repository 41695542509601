import React, { Component } from 'react';
import queryString from 'query-string';
import Calendar from './Calendar';
import List from './List';

export default class OperationalMaintenancesContainer extends Component {
  render() {
    const { list } = queryString.parse(this.props.location.search);
    if (list == null) {
      return null;
    }
    if (list == 'calendar') {
      return <Calendar />;
    }
    return <List />;
  }
}
