import update from 'immutability-helper';
import types from './types';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  workOrders: {
    ids: [],
    pagination: {},
    calendar: {},
    instanceById: {},
    isDraggingWorkOrderId: null,
    isDraggingRecurringMaintenanceId: null,
    previewOverlay: {
      open: false,
      instanceId: null,
      workOrderId: null,
      top: 0,
      left: 0,
      width: 0,
    },
    showPreviewMoreWorkOrdersOverlay: {
      open: false,
      top: 0,
      left: 0,
      width: 0,
    },
  },
  assetVendors: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  sparePartAssets: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  downtimes: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_ASSET_FOR_VIEW: {
      return {
        ...state,
        id: action.payload,
        editingAsset: null,
        editingAssetBeforeEdit: null,
        editingFieldById: null,
        editingFieldByIdBeforeEdit: null,
        hasUnsavedChanges: false,
      };
    }

    case types.FETCH_ASSET_FOR_VIEW_SUCCESS:
      return {
        ...state,
        id: action.payload,
        editingAsset: null,
        editingAssetBeforeEdit: null,
        editingFieldById: null,
        editingFieldByIdBeforeEdit: null,
        hasUnsavedChanges: false,
      };
    case types.FETCH_WORK_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }
    case types.FETCH_ASSET_VENDORS_SUCCESS: {
      const { ids, pagination } = action.payload;

      return {
        ...state,
        assetVendors: {
          ...state.assetVendors,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }
    case types.FETCH_DOWNTIMES_SUCCESS: {
      const { ids, pagination } = action.payload;

      return {
        ...state,
        downtimes: {
          ...state.downtimes,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case SDKReduxTypes.VENDOR_CREATED: {
      const { data } = action.payload;
      if (data.asset_vendors) {
        return update(state, {
          assetVendors: {
            ids: { $unshift: [data.asset_vendors[0].id] },
            createdIds: { $unshift: [data.asset_vendors[0].id] },
          },
        });
      } else return state;
    }

    case SDKReduxTypes.ASSET_VENDOR_CREATED: {
      const { data } = action.payload;
      if (data.asset_id === state.id) {
        return update(state, {
          assetVendors: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.ASSET_VENDOR_DELETED: {
      const { assetVendorId } = action.payload;
      return {
        ...state,
        assetVendors: {
          ...state.assetVendors,
          ids: state.assetVendors.ids.filter(id => id !== assetVendorId),
          createdIds: state.assetVendors.createdIds.filter(id => id !== assetVendorId),
        },
      };
    }
    case types.FETCH_SPARE_PART_ASSETS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        sparePartAssets: {
          ...state.sparePartAssets,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_CREATED: {
      const { data } = action.payload;
      const { spare_part_assets } = data;
      if (spare_part_assets && spare_part_assets.length === 1) {
        const { asset_id, id: sparePartAssetId } = spare_part_assets[0];
        if (asset_id === state.id) {
          return update(state, {
            sparePartAssets: {
              ids: { $unshift: [sparePartAssetId] },
              createdIds: { $unshift: [sparePartAssetId] },
              totalEntries: { $apply: v => v + sparePartAssetId },
            },
          });
        } else {
          return state;
        }
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_ASSET_CREATED: {
      const { data } = action.payload;
      if (data.asset_id === state.id) {
        return update(state, {
          sparePartAssets: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.SPARE_PART_ASSET_DELETED: {
      const { sparePartAssetId } = action.payload;
      return {
        ...state,
        sparePartAssets: {
          ...state.sparePartAssets,
          ids: state.sparePartAssets.ids.filter(id => id !== sparePartAssetId),
          createdIds: state.sparePartAssets.createdIds.filter(id => id !== sparePartAssetId),
        },
      };
    }
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { downtimeId } = action.payload;
      return {
        ...state,
        downtimes: {
          ...state.downtimes,
          ids: state.downtimes.ids.filter(id => id !== downtimeId),
          createdIds: state.downtimes.createdIds.filter(id => id !== downtimeId),
        },
      };
    }
    case SDKReduxTypes.DOWNTIME_CREATED: {
      const { data } = action.payload;
      const assetId = data.asset_id;
      if (assetId === state.id) {
        return update(state, {
          downtimes: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }
    case types.DROP_CALENDAR_INSTANCE: {
      const { from, to, instanceId } = action.payload;
      return update(state, {
        workOrders: {
          calendar: calendar =>
            update(calendar, {
              [from]: fromDate =>
                update(fromDate || [], {
                  $apply: fromDate => fromDate.filter(id => id !== instanceId),
                }),
              [to]: toDate =>
                update(toDate || [], {
                  $push: [instanceId],
                }),
            }),
        },
      });
    }
    case types.BEGIN_DRAG: {
      const { workOrderId, recurringMaintenanceId } = action.payload;
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isDraggingWorkOrderId: workOrderId == null ? null : workOrderId,
          isDraggingRecurringMaintenanceId: recurringMaintenanceId == null ? null : recurringMaintenanceId,
          previewOverlay: {
            ...state.workOrders.previewOverlay,
            open: false,
          },
          showPreviewMoreWorkOrdersOverlay: {
            ...state.workOrders.showPreviewMoreWorkOrdersOverlay,
            open: false,
          },
        },
      };
    }
    case types.END_DRAG: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          isDraggingWorkOrderId: null,
          isDraggingRecurringMaintenanceId: null,
        },
      };
    }
    case types.SHOW_PREVIEW_OVERLAY: {
      const { top, left, width, instanceId } = action.payload;
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previewOverlay: {
            ...state.workOrders.previewOverlay,
            open: true,
            instanceId,
            top,
            left,
            width,
          },
        },
      };
    }
    case types.HIDE_PREVIEW_OVERLAY: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previewOverlay: {
            ...state.workOrders.previewOverlay,
            open: false,
          },
        },
      };
    }
    case types.SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY: {
      const { top, left, width, date } = action.payload;
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          showPreviewMoreWorkOrdersOverlay: {
            ...state.workOrders.showPreviewMoreWorkOrdersOverlay,
            open: true,
            top,
            left,
            width,
            date,
          },
        },
      };
    }
    case types.HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY: {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          showPreviewMoreWorkOrdersOverlay: {
            ...state.workOrders.showPreviewMoreWorkOrdersOverlay,
            open: false,
          },
          previewOverlay: {
            ...state.workOrders.previewOverlay,
            open: false,
          },
        },
      };
    }
    case types.FETCH_DATA_BETWEEN_DATES: {
      const { resetCalendar } = action.payload;
      let newState = {
        ...state,
        workOrders: {
          ...state.workOrders,
        },
      };
      if (resetCalendar) {
        newState = {
          ...newState,
          workOrders: {
            ...state.workOrders,
            calendar: {},
          },
        };
      }
      return newState;
    }
    case types.FETCH_DATA_BETWEEN_DATES_SUCCESS: {
      const { ids, instanceById } = action.payload;
      let newState = { ...state };
      ids.forEach(id => {
        const { date } = instanceById[id];
        newState = update(newState, {
          workOrders: {
            calendar: calendar =>
              update(calendar || {}, {
                [date]: date =>
                  update(date || [], {
                    $apply: date => {
                      if (date.includes(id)) {
                        return date;
                      }
                      return [...date, id];
                    },
                  }),
              }),
            instanceById: {
              $merge: instanceById,
            },
          },
        });
      });
      return newState;
    }
    case SDKReduxTypes.WORK_ORDER_CREATED: {
      let workOrder;
      if (action.type === SDKReduxTypes.WORK_ORDER_CREATED) {
        workOrder = action.payload.data;
      } else {
        const { data: recurringMaintenance } = action.payload;
        workOrder = recurringMaintenance.generated_work_order;
      }
      if (workOrder.asset_id !== state.id) {
        return state;
      }
      let date = workOrder.due_date;
      if (workOrder.status === WorkOrderStatus.Completed) {
        date = workOrder.completed_date;
      }
      if (date == null) {
        return update(state, {
          workOrders: {
            ids: ids =>
              update(ids || [], {
                $apply: () => {
                  return [workOrder.id, ...ids];
                },
              }),
          },
        });
      }
      return update(state, {
        workOrders: {
          calendar: calendar =>
            update(calendar, {
              [date]: date =>
                update(date || [], {
                  $push: [workOrder.id],
                }),
            }),
          instanceById: instanceById =>
            update(instanceById || {}, {
              $merge: {
                [workOrder.id]: {
                  id: workOrder.id,
                  date,
                  users: workOrder.assigned_to_users.map(({ id }) => id),
                  groups: workOrder.assigned_to_groups.map(({ id }) => id),
                  vendors: workOrder.assigned_to_vendors.map(({ id }) => id),
                  work_order: workOrder.id,
                  type: 'work_order',
                },
              },
            }),
          ids: ids =>
            update(ids || [], {
              $apply: () => {
                return [workOrder.id, ...ids];
              },
            }),
        },
      });
    }
    default:
      return state;
  }
};
