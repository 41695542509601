import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import { Decimal } from 'decimal.js';
import { HelperFunctions } from 'sdk';
import moment from 'moment';
import { EmptyDataSet } from 'views/components/Shared/General';
import { REPORT_PER_TYPES, WorkOrderCategory, REPORT_SPLIT_PER_TYPES } from 'sdk/WorkOrder';
import styles from './style.module.scss';

const PRIO_LOW = '#313436';
const PRIO_MEDIUM = '#f07f39';
const PRIO_HIGH = '#c55050';

const CORRECTIVE = '#D36729';
const PREVENTIVE = '#4C9AC8';
const IMPROVEMENT = '#2baf2d';
const MODIFICATION = '#c55050';

const BAR_COLOR = '#4C9AC8';

class Chart extends PureComponent {
  state = {
    labels: [],
    datasets: [],
    isBuildingDataStructure: true,
    isEmpty: false,
  };

  componentDidMount() {
    const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
    const showingUsers = this.props.perType === REPORT_PER_TYPES.CompletedBy;
    const showingAssignees = this.props.perType === REPORT_PER_TYPES.Assignee;
    const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
    const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
    const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
    const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
    const showWorkOrderCategorySimple = this.props.perType === REPORT_PER_TYPES.WorkOrderCategorySimple;
    const showWorkOrderCategoryDetailed = this.props.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed;
    if (showingWorkOrderTypes) {
      this.buildWorkOrderTypesDataStructure();
    } else if (showingUsers) {
      this.buildUsersDataStructure();
    } else if (showingAssignees) {
      this.buildAssigneeStructure();
    } else if (showingYears) {
      this.buildYearsDataStructure();
    } else if (showingMonths) {
      this.buildMonthsDataStructure();
    } else if (showingAssets) {
      this.buildAssetsDataStructure();
    } else if (showingPriorities) {
      this.buildPrioritiesDataStructure();
    } else if (showWorkOrderCategorySimple) {
      this.buildWorkOrderCategoriesSimpleDataStructure();
    } else if (showWorkOrderCategoryDetailed) {
      this.buildWorkOrderCategoriesDetailedDataStructure();
    } else {
      this.setState({ isBuildingDataStructure: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isFetching && this.props.isFetching) {
      this.setState({ isBuildingDataStructure: true });
    } else {
      const finishedLoading = prevProps.isFetching && !this.props.isFetching;
      const changedSortOrder = prevProps.sortingOnPerType !== this.props.sortingOnPerType;
      if (finishedLoading || (!this.props.isFetching && changedSortOrder)) {
        const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
        const showingUsers = this.props.perType === REPORT_PER_TYPES.CompletedBy;
        const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
        const showingAssignees = this.props.perType === REPORT_PER_TYPES.Assignee;
        const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
        const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
        const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
        const showWorkOrderCategorySimple = this.props.perType === REPORT_PER_TYPES.WorkOrderCategorySimple;
        const showWorkOrderCategoryDetailed =
          this.props.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed;
        if (showingWorkOrderTypes) {
          this.buildWorkOrderTypesDataStructure();
        } else if (showingUsers) {
          this.buildUsersDataStructure();
        } else if (showingAssignees) {
          this.buildAssigneeStructure();
        } else if (showingYears) {
          this.buildYearsDataStructure();
        } else if (showingMonths) {
          this.buildMonthsDataStructure();
        } else if (showingAssets) {
          this.buildAssetsDataStructure();
        } else if (showingPriorities) {
          this.buildPrioritiesDataStructure();
        } else if (showWorkOrderCategorySimple) {
          this.buildWorkOrderCategoriesSimpleDataStructure();
        } else if (showWorkOrderCategoryDetailed) {
          this.buildWorkOrderCategoriesDetailedDataStructure();
        } else {
          this.setState({ isBuildingDataStructure: false });
        }
      }
    }
  }

  buildUsersDataStructure = () => {
    if (this.props.completedByUsers.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let users = this.props.completedByUsers.map(({ name, id }) => ({
        name,
        low: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['low'] || 0),
        medium: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['medium'] || 0),
        high: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['high'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: users.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: users.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: users.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let users = this.props.completedByUsers.map(({ name, id }) => ({
        name,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let users = this.props.completedByUsers.map(({ name, id }) => ({
        name,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let users = this.props.completedByUsers.map(({ name, id }) => ({
        name,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        users = users.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: users.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildAssigneeStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      const { assigneeUsers, assigneeGroups, assigneeVendors } = this.props;
      if (assigneeUsers.length === 0 && assigneeGroups.length === 0 && assigneeVendors.length === 0) {
        this.setState({ isBuildingDataStructure: false, isEmpty: true });
        return;
      }
      let userData = {};
      let groupData = {};
      let vendorData = {};
      Object.keys(this.props.data).forEach(key => {
        if (key.charAt(0) === 'u') {
          const count = this.props.data[key];
          const userId = key.substring(2);
          userData = {
            ...userData,
            [userId]: count,
          };
        } else if (key.charAt(0) === 'g') {
          const groupId = key.substring(2);
          const count = this.props.data[key];
          groupData = {
            ...groupData,
            [groupId]: count,
          };
        } else if (key.charAt(0) === 'v') {
          const vendorId = key.substring(2);
          const count = this.props.data[key];
          vendorData = {
            ...vendorData,
            [vendorId]: count,
          };
        }
      });
      let assignees = [
        ...assigneeUsers.map(({ name, id }) => ({
          title: name,
          low: new Decimal(userData[id]['low'] || 0),
          medium: new Decimal(userData[id]['medium'] || 0),
          high: new Decimal(userData[id]['high'] || 0),
        })),
        ...assigneeGroups.map(({ title, id }) => ({
          title,
          low: new Decimal(groupData[id]['low'] || 0),
          medium: new Decimal(groupData[id]['medium'] || 0),
          high: new Decimal(groupData[id]['high'] || 0),
        })),
        ...assigneeVendors.map(({ name, id }) => ({
          title: name,
          low: new Decimal(vendorData[id]['low'] || 0),
          medium: new Decimal(vendorData[id]['medium'] || 0),
          high: new Decimal(vendorData[id]['high'] || 0),
        })),
      ];

      if (this.props.sortingOnPerSplitType) {
        assignees.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: assignees.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      const { assigneeUsers, assigneeGroups, assigneeVendors } = this.props;
      if (assigneeUsers.length === 0 && assigneeGroups.length === 0 && assigneeVendors.length === 0) {
        this.setState({ isBuildingDataStructure: false, isEmpty: true });
        return;
      }
      let userData = {};
      let groupData = {};
      let vendorData = {};
      Object.keys(this.props.data).forEach(key => {
        if (key.charAt(0) === 'u') {
          const count = this.props.data[key];
          const userId = key.substring(2);
          userData = {
            ...userData,
            [userId]: count,
          };
        } else if (key.charAt(0) === 'g') {
          const groupId = key.substring(2);
          const count = this.props.data[key];
          groupData = {
            ...groupData,
            [groupId]: count,
          };
        } else if (key.charAt(0) === 'v') {
          const vendorId = key.substring(2);
          const count = this.props.data[key];
          vendorData = {
            ...vendorData,
            [vendorId]: count,
          };
        }
      });
      let assignees = [
        ...assigneeUsers.map(({ name, id }) => ({
          title: name,
          corrective: new Decimal(userData[id]['corrective'] || 0),
          preventive: new Decimal(userData[id]['preventive'] || 0),
          improvement: new Decimal(userData[id]['improvement'] || 0),
          modification: new Decimal(userData[id]['modification'] || 0),
        })),
        ...assigneeGroups.map(({ title, id }) => ({
          title,
          corrective: new Decimal(groupData[id]['corrective'] || 0),
          preventive: new Decimal(groupData[id]['preventive'] || 0),
          improvement: new Decimal(groupData[id]['improvement'] || 0),
          modification: new Decimal(groupData[id]['modification'] || 0),
        })),
        ...assigneeVendors.map(({ name, id }) => ({
          title: name,
          corrective: new Decimal(vendorData[id]['corrective'] || 0),
          preventive: new Decimal(vendorData[id]['preventive'] || 0),
          improvement: new Decimal(vendorData[id]['improvement'] || 0),
          modification: new Decimal(vendorData[id]['modification'] || 0),
        })),
      ];

      if (this.props.sortingOnPerSplitType) {
        assignees.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: assignees.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      const { assigneeUsers, assigneeGroups, assigneeVendors } = this.props;
      if (assigneeUsers.length === 0 && assigneeGroups.length === 0 && assigneeVendors.length === 0) {
        this.setState({ isBuildingDataStructure: false, isEmpty: true });
        return;
      }
      let userData = {};
      let groupData = {};
      let vendorData = {};
      Object.keys(this.props.data).forEach(key => {
        if (key.charAt(0) === 'u') {
          const count = this.props.data[key];
          const userId = key.substring(2);
          userData = {
            ...userData,
            [userId]: count,
          };
        } else if (key.charAt(0) === 'g') {
          const groupId = key.substring(2);
          const count = this.props.data[key];
          groupData = {
            ...groupData,
            [groupId]: count,
          };
        } else if (key.charAt(0) === 'v') {
          const vendorId = key.substring(2);
          const count = this.props.data[key];
          vendorData = {
            ...vendorData,
            [vendorId]: count,
          };
        }
      });
      let assignees = [
        ...assigneeUsers.map(({ name, id }) => ({
          title: name,
          corrective_immediate: new Decimal(userData[id]['corrective_immediate'] || 0),
          corrective_defered: new Decimal(userData[id]['corrective_defered'] || 0),
          preventive_condition_based: new Decimal(userData[id]['preventive_condition_based'] || 0),
          preventive_predetermined: new Decimal(userData[id]['preventive_predetermined'] || 0),
        })),
        ...assigneeGroups.map(({ title, id }) => ({
          title,
          corrective_immediate: new Decimal(groupData[id]['corrective_immediate'] || 0),
          corrective_defered: new Decimal(groupData[id]['corrective_defered'] || 0),
          preventive_condition_based: new Decimal(groupData[id]['preventive_condition_based'] || 0),
          preventive_predetermined: new Decimal(groupData[id]['preventive_predetermined'] || 0),
        })),
        ...assigneeVendors.map(({ name, id }) => ({
          title: name,
          corrective_immediate: new Decimal(vendorData[id]['corrective_immediate'] || 0),
          corrective_defered: new Decimal(vendorData[id]['corrective_defered'] || 0),
          preventive_condition_based: new Decimal(vendorData[id]['preventive_condition_based'] || 0),
          preventive_predetermined: new Decimal(vendorData[id]['preventive_predetermined'] || 0),
        })),
      ];

      if (this.props.sortingOnPerSplitType) {
        assignees.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: assignees.map(({ title }) => title),
        datasets: [
          {
            label: `${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.corrective',
            })} - ${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.corrective-immediate',
            })}`,
            backgroundColor: CORRECTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: `${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.corrective',
            })} - ${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.corrective-defered',
            })}`,
            backgroundColor: CORRECTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: `${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.preventive',
            })} - ${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.preventive-condition-based',
            })}`,
            backgroundColor: PREVENTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: `${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.preventive',
            })} - ${this.props.intl.formatMessage({
              id: 'resources.work-order.categories.preventive-predetermined',
            })}`,
            backgroundColor: PREVENTIVE,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assignees.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      const { assigneeUsers, assigneeGroups, assigneeVendors } = this.props;
      if (assigneeUsers.length === 0 && assigneeGroups.length === 0 && assigneeVendors.length === 0) {
        this.setState({ isBuildingDataStructure: false, isEmpty: true });
        return;
      }
      let userData = {};
      let groupData = {};
      let vendorData = {};
      Object.keys(this.props.data).forEach(key => {
        if (key.charAt(0) === 'u') {
          const count = this.props.data[key];
          const userId = key.substring(2);
          userData = {
            ...userData,
            [userId]: count,
          };
        } else if (key.charAt(0) === 'g') {
          const groupId = key.substring(2);
          const count = this.props.data[key];
          groupData = {
            ...groupData,
            [groupId]: count,
          };
        } else if (key.charAt(0) === 'v') {
          const vendorId = key.substring(2);
          const count = this.props.data[key];
          vendorData = {
            ...vendorData,
            [vendorId]: count,
          };
        }
      });
      let assignees = [
        ...assigneeUsers.map(({ name, id }) => ({
          title: name,
          count: new Decimal(userData[id] || 0),
        })),
        ...assigneeGroups.map(({ title, id }) => ({
          title,
          count: new Decimal(groupData[id] || 0),
        })),
        ...assigneeVendors.map(({ name, id }) => ({
          title: name,
          count: new Decimal(vendorData[id] || 0),
        })),
      ];

      if (this.props.sortingOnType === this.props.type) {
        assignees = assignees.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assignees = assignees.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assignees.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: assignees.map(({ count }) => count.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderCategoriesSimpleDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let categories = [
        {
          category: 'corrective',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          low: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['high'] || 0
          ),
        },
        {
          category: 'preventive',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          low: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['high'] || 0
          ),
        },
        {
          category: 'improvement',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
          low: new Decimal(
            this.props.data['improvement'] == null ? 0 : this.props.data['improvement']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['improvement'] == null ? 0 : this.props.data['improvement']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['improvement'] == null ? 0 : this.props.data['improvement']['high'] || 0
          ),
        },
        {
          category: 'modification',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
          low: new Decimal(
            this.props.data['modification'] == null ? 0 : this.props.data['modification']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['modification'] == null ? 0 : this.props.data['modification']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['modification'] == null ? 0 : this.props.data['modification']['high'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        categories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let categories = [
        {
          category: 'corrective',
          quantity: new Decimal(this.props.data['corrective'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
        },
        {
          category: 'preventive',
          quantity: new Decimal(this.props.data['preventive'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
        },
        {
          category: 'improvement',
          quantity: new Decimal(this.props.data['improvement'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
        },
        {
          category: 'modification',
          quantity: new Decimal(this.props.data['modification'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
        },
      ];
      if (this.props.sortingOnType === this.props.type) {
        categories.sort((a, b) => (b.quantity.greaterThan(a.quantity) ? 1 : -1));
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            minBarLength: 2,
            maxBarThickness: 36,
            backgroundColor: BAR_COLOR,
            hoverBackgroundColor: BAR_COLOR,
            data: categories.map(({ quantity }) => quantity.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderCategoriesDetailedDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let categories = [
        {
          category: 'corrective_immediate',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          low: new Decimal(
            this.props.data['corrective_immediate'] == null
              ? 0
              : this.props.data['corrective_immediate']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['corrective_immediate'] == null
              ? 0
              : this.props.data['corrective_immediate']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['corrective_immediate'] == null
              ? 0
              : this.props.data['corrective_immediate']['high'] || 0
          ),
        },
        {
          category: 'corrective_defered',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          low: new Decimal(
            this.props.data['corrective_defered'] == null
              ? 0
              : this.props.data['corrective_defered']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['corrective_defered'] == null
              ? 0
              : this.props.data['corrective_defered']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['corrective_defered'] == null
              ? 0
              : this.props.data['corrective_defered']['high'] || 0
          ),
        },
        {
          category: 'preventive_condition_based',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          low: new Decimal(
            this.props.data['preventive_condition_based'] == null
              ? 0
              : this.props.data['preventive_condition_based']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['preventive_condition_based'] == null
              ? 0
              : this.props.data['preventive_condition_based']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['preventive_condition_based'] == null
              ? 0
              : this.props.data['preventive_condition_based']['high'] || 0
          ),
        },
        {
          category: 'preventive_predetermined',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          low: new Decimal(
            this.props.data['preventive_predetermined'] == null
              ? 0
              : this.props.data['preventive_predetermined']['low'] || 0
          ),
          medium: new Decimal(
            this.props.data['preventive_predetermined'] == null
              ? 0
              : this.props.data['preventive_predetermined']['medium'] || 0
          ),
          high: new Decimal(
            this.props.data['preventive_predetermined'] == null
              ? 0
              : this.props.data['preventive_predetermined']['high'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        categories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: categories.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let categories = [
        {
          category: WorkOrderCategory.CorrectiveDefered,
          cost: new Decimal(this.props.data['corrective_defered'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-defered',
          })}`,
        },
        {
          category: WorkOrderCategory.CorrectiveImmediate,
          cost: new Decimal(this.props.data['corrective_immediate'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-immediate',
          })}`,
        },
        {
          category: WorkOrderCategory.PreventiveConditionBased,
          cost: new Decimal(this.props.data['preventive_condition_based'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-condition-based',
          })}`,
        },
        {
          category: WorkOrderCategory.PreventivePredetermined,
          cost: new Decimal(this.props.data['preventive_predetermined'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-predetermined',
          })}`,
        },
      ];
      if (this.props.sortingOnType === this.props.type) {
        categories.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            minBarLength: 2,
            maxBarThickness: 36,
            backgroundColor: BAR_COLOR,
            hoverBackgroundColor: BAR_COLOR,
            data: categories.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderTypesDataStructure = () => {
    if (this.props.workOrderTypes.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        low: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['low'] || 0),
        medium: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['medium'] || 0),
        high: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['high'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderTypes.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderTypes.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderTypes.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        workOrderTypes = workOrderTypes.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: workOrderTypes.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildAssetsDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        low: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['low'] || 0),
        medium: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['medium'] || 0),
        high: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['high'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assets.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assets.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assets.map(({ high }) => high),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      if (this.props.assets.length === 0) {
        this.setState({ isBuildingDataStructure: false, isEmpty: true });
        return;
      }
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        count: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        assets = assets.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: assets.map(({ count }) => count.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildYearsDataStructure = () => {
    const currentYear = moment().year();
    let amountOfYearsSinceSystemWasCreated = currentYear - this.props.yearSystemWasCreated;
    let years = [];

    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          low: new Decimal(this.props.data[year] == null ? 0 : this.props.data[year]['low'] || 0),
          medium: new Decimal(this.props.data[year] == null ? 0 : this.props.data[year]['medium'] || 0),
          high: new Decimal(this.props.data[year] == null ? 0 : this.props.data[year]['high'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: years.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: years.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: years.map(({ high }) => high),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          corrective: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['modification'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ modification }) => modification),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          corrective_immediate: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive_predetermined'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else {
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({ year, count: new Decimal(this.props.data[year] || 0) });
      }
      if (this.props.sortingOnType === this.props.type) {
        years.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: years.map(({ count }) => count.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    }
  };

  buildMonthsDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          low: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['low'] || 0),
          medium: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['medium'] || 0),
          high: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['high'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.low' }),
            backgroundColor: PRIO_LOW,
            maxBarThickness: 36,
            minBarLength: 2,
            data: months.map(({ low }) => low),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.medium' }),
            backgroundColor: PRIO_MEDIUM,
            maxBarThickness: 36,
            minBarLength: 2,
            data: months.map(({ medium }) => medium),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.prio.high' }),
            backgroundColor: PRIO_HIGH,
            maxBarThickness: 36,
            minBarLength: 2,
            data: months.map(({ high }) => high),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          corrective: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['corrective'] || 0),
          preventive: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['preventive'] || 0),
          improvement: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['improvement'] || 0),
          modification: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['modification'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ modification }) => modification),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          corrective_immediate: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['preventive_predetermined'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          count: new Decimal(this.props.data[i] || 0),
        });
      }
      if (this.props.sortingOnType === this.props.type) {
        months.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: months.map(({ count }) => count.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    }
  };

  buildPrioritiesDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let priorities = [
        {
          priority: 'high',
          corrective: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['modification'] || 0
          ),
        },
        {
          priority: 'medium',
          corrective: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['modification'] || 0
          ),
        },
        {
          priority: 'low',
          corrective: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['modification'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        priorities.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let priorities = [
        {
          priority: 'high',
          corrective_immediate: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive_predetermined'] || 0
          ),
        },
        {
          priority: 'medium',
          corrective_immediate: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['medium'] == null
              ? 0
              : this.props.data['medium']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['preventive_predetermined'] || 0
          ),
        },
        {
          priority: 'low',
          corrective_immediate: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive_predetermined'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        priorities.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let priorities = [
        { priority: 'high', count: new Decimal(this.props.data['high'] || 0) },
        { priority: 'medium', count: new Decimal(this.props.data['medium'] || 0) },
        { priority: 'low', count: new Decimal(this.props.data['low'] || 0) },
      ];
      if (this.props.sortingOnType === this.props.type) {
        priorities.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: BAR_COLOR,
            data: priorities.map(({ count }) => count.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  renderSplitValues = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.Priority) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['low-priority']}`}>
            <FormattedMessage id="resources.work-order.prio.low" />
          </span>
          <span className={`${styles['split-label']} ${styles['medium-priority']}`}>
            <FormattedMessage id="resources.work-order.prio.medium" />
          </span>
          <span className={`${styles['split-label']} ${styles['high-priority']}`}>
            <FormattedMessage id="resources.work-order.prio.high" />
          </span>
        </div>
      );
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive" />
          </span>
          <span className={`${styles['split-label']} ${styles['improvement']}`}>
            <FormattedMessage id="resources.work-order.categories-short.improvement" />
          </span>
          <span className={`${styles['split-label']} ${styles['modification']}`}>
            <FormattedMessage id="resources.work-order.categories-short.modification" />
          </span>
        </div>
      );
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective-immediate" />
          </span>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective-defered" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive-condition-based" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive-predetermined" />
          </span>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.props.isFetching || this.state.isBuildingDataStructure) return <Loader small />;
    if (this.state.isEmpty) {
      if (this.props.perType === REPORT_PER_TYPES.Asset) {
        if (this.props.showingRootAssets) {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={
                  <FormattedMessage id="components.work-orders-overview.no-assets-empty-data-set.title" />
                }
                subtitle={
                  <FormattedMessage id="components.work-orders-overview.no-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        } else {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={
                  <FormattedMessage id="components.work-orders-overview.no-children-assets-empty-data-set.title" />
                }
                subtitle={
                  <FormattedMessage id="components.work-orders-overview.no-children-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        }
      } else if (this.props.perType === REPORT_PER_TYPES.WorkOrderType) {
        return (
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.work-orders-overview.no-work-order-types-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.work-orders-overview.no-work-order-types-empty-data-set.subtitle" />
            }
          />
        );
      }
    }
    return (
      <Bar
        data={{
          labels: this.state.labels,
          datasets: this.state.datasets,
        }}
        width={582}
        height={410}
        options={{
          legend: false,
          maintainAspectRatio: false,
          tooltips: {
            displayColors: false,
            callbacks: {
              title: (tooltipItem, { datasets }) => {
                const { label, datasetIndex } = tooltipItem[0];
                if (datasets.length > 1) {
                  let tooltipLabel = label;
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    tooltipLabel = this.props.intl.formatMessage({
                      id: `resources.work-order.prio.${label}`,
                    });
                  }
                  return `${tooltipLabel} - ${datasets[datasetIndex].label}`;
                } else {
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    return this.props.intl.formatMessage({ id: `resources.work-order.prio.${label}` });
                  }
                  return label;
                }
              },
              label: ({ value }) => {
                return this.props.intl.formatMessage(
                  {
                    id: 'components.work-orders-overview.count',
                  },
                  {
                    value,
                  }
                );
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  padding: 13,
                  fontStyle: '600',
                  suggestedMax: 10,
                  beginAtZero: true,
                  maxTicksLimit: 4,
                  callback: (value, index, values) => {
                    return this.props.intl.formatMessage(
                      {
                        id: 'components.work-orders-overview.count',
                      },
                      {
                        value,
                      }
                    );
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  fontStyle: '600',
                  callback: (value, index, values) => {
                    if (this.props.perType === REPORT_PER_TYPES.Priority) {
                      return this.props.intl.formatMessage({ id: `resources.work-order.prio.${value}` });
                    }
                    return value;
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
          },
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderSplitValues()}
        <div className={styles['chart-container']}>{this.renderContent()}</div>
      </>
    );
  }
}

export default injectIntl(Chart);
