import { omit } from 'lodash-es';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_CREATED: {
      const { params, data: sparePart } = action.payload;
      if (state[params.spare_part_withdrawal_id]) {
        return {
          ...state,
          [params.spare_part_withdrawal_id]: {
            ...state[params.spare_part_withdrawal_id],
            spare_part_id: sparePart.id,
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.sparePartWithdrawalById) {
        return HelperFunctions.mergeEntities(state, action.payload.sparePartWithdrawalById);
      }
      return state;
    default:
      return state;
  }
};
