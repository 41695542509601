import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { Tooltip, FormattedNumber } from 'views/components/Shared/General';
import styles from './style.module.scss';

class DueDateColumn extends Component {
  renderMeterDueDate = () => {
    if (this.props.workOrder.due_date) {
      return (
        <div className={styles['meter-value']}>
          <div>
            <span>{moment(this.props.workOrder.due_date).format('LL')}</span>
          </div>
          <div className={`${styles['subtitle']} ${styles['small']}`}>
            <span>
              <FormattedNumber
                value={this.props.workOrder.due_meter_value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            </span>
            <span> </span>
            <span>
              (
              <FormattedMessage id="general.now" />
              <span> </span>
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
              )
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['meter-value']}>
        <div>
          <span>
            <FormattedNumber
              value={this.props.workOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
            <span> </span>
            <span className={styles['subtitle']}>
              (
              <FormattedMessage id="general.now" />
              <span> </span>
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
              )
            </span>
          </span>
        </div>
        {this.props.workOrder.estimated_meter_due_date ? (
          <div>
            <span className={`${styles['subtitle']} ${styles['small']}`}>
              <FormattedMessage id="resources.work-order.estimated-meter-due-date-short" />
              <span>: </span>
              <span>{moment(this.props.workOrder.estimated_meter_due_date).format('LL')}</span>
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    if (this.props.workOrder.meter_id) {
      return this.renderMeterDueDate();
    }
    if (!this.props.workOrder.due_date) return '-';

    const today = moment();
    const overdue = moment(this.props.workOrder.due_date).isBefore(today, 'day');
    let classNames = [];

    if (overdue && this.props.workOrder.status !== WorkOrderStatus.Completed) {
      classNames = [...classNames, styles['overdue']];
    }

    let value;
    if (this.props.today) {
      if (moment().isSame(moment(this.props.workOrder.due_date), 'day')) {
        value = (
          <Tooltip
            trigger={
              <span>
                <FormattedMessage id="calendar.today" />
              </span>
            }
            label={<span>{moment(this.props.workOrder.due_date).format('LL')}</span>}
          />
        );
      } else {
        value = moment(this.props.workOrder.due_date).format('LL');
      }
    } else if (this.props.weekDay) {
      if (moment().isSame(moment(this.props.workOrder.due_date), 'week')) {
        value = (
          <Tooltip
            trigger={<span>{moment(this.props.workOrder.due_date).format('dddd')}</span>}
            label={<span>{moment(this.props.workOrder.due_date).format('LL')}</span>}
          />
        );
      } else {
        value = moment(this.props.workOrder.due_date).format('LL');
      }
    } else {
      value = moment(this.props.workOrder.due_date).format('LL');
    }

    return <span className={classNames.join(' ')}>{value}</span>;
  }
}

function mapStateToProps(state, ownProps) {
  let meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
  };
}

export default connect(mapStateToProps)(DueDateColumn);
