import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Field, Icon } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import styles from './style.module.scss';

class SparePartDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view',
      isDeleting: false,
      confirmInput: '',
    };
  }

  componentDidUpdate(prevProps, { mode }) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        mode: 'view',
        isSaving: false,
        confirmInput: '',
      });
    }
  }

  deleteSparePart = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteSparePart(this.props.sparePart.id)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.spare-part-delete.delete-success" />}
          />
        );
        this.props.onDelete();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
        //TODO: Show general error på dessa
        throw e;
      });
  };

  renderConfirmMode = () => (
    <React.Fragment>
      <Modal.Content ignoreLine>
        <Field label={this.props.intl.formatMessage({ id: 'general.confirm-delete-label' })}>
          <Field.Text
            autoFocus
            value={this.state.confirmInput}
            onChange={value => {
              this.setState({ confirmInput: value });
            }}
          />
        </Field>
      </Modal.Content>
      <Modal.Footer container>
        <Button.Group>
          <Button
            destructive
            primary
            disabled={
              this.state.confirmInput.toLowerCase() !==
              this.props.intl.formatMessage({ id: 'general.confirm-delete-value' })
            }
            label="components.spare-part-delete.button-delete"
            loading={this.state.isDeleting}
            onClick={this.deleteSparePart}
          />
          <Button label="general.cancel" translate onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  renderViewMode = () => (
    <React.Fragment>
      <Modal.Content noPadding>
        <React.Fragment>
          <div className={styles['delete-info-container']}>
            <Field label={<FormattedMessage id="components.spare-part-delete.delete-info.title" />}>
              <div className={styles['delete-info']}>
                <div>
                  <Icon fixedWidth regular type="wrench" />
                  <FormattedMessage id="components.spare-part-delete.delete-info.withdrawals-and-reservations" />
                </div>
                <div>
                  <Icon fixedWidth regular type="dollar-sign" />
                  <FormattedMessage id="components.spare-part-delete.delete-info.costs" />
                </div>
                <div>
                  <Icon fixedWidth regular type="box" />
                  <FormattedMessage id="components.spare-part-delete.delete-info.asset-connections" />
                </div>
                <div>
                  <Icon fixedWidth regular type="file-alt" />
                  <FormattedMessage id="components.spare-part-delete.delete-info.attachments" />
                </div>
              </div>
            </Field>
          </div>
        </React.Fragment>
      </Modal.Content>
      <Modal.Footer container>
        <Button.Group>
          <Button
            destructive
            primary
            label="components.spare-part-delete.button-delete"
            loading={this.state.isSaving}
            onClick={() => this.setState({ mode: 'confirm' })}
          />
          <Button label="general.cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="components.spare-part-delete.title" />}
          onClose={this.props.onClose}
          ignoreLine
        />
        {this.state.mode === 'view' ? this.renderViewMode() : this.renderConfirmMode()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePart: SDKReduxOperations.deleteSparePart,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(SparePartDeleteModal));
