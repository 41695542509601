import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { CardGrid } from 'views/components/Shared/Layout';
import { List, SectionHeader } from 'views/components/Shared/General';
import { PreviewChecklistTemplateModal } from 'views/components/Checklist';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import ChecklistTemplateLinkItem from './ChecklistTemplateLinkItem';
import TemplateAssetsSettingsModal from './TemplateAssetsSettingsModal';

class Checklist extends Component {
  state = {
    showPreviewChecklistModal: false,
    showPreviewChecklistModalForTemplateId: null,
    showPreviewChecklistModal: false,
    showAssetTemplateSettingsModal: false,
    showTemplateAssetSettingsModal: false,
  };

  renderChecklistListItem = () => {
    const { checklistTemplateLinks } = this.props;
    if (checklistTemplateLinks && checklistTemplateLinks.length > 0) {
      return (
        <>
          <CardGrid.Row>
            <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
              <FormattedMessage id="resources.checklist-instance.resource" />
            </SectionHeader>
            <List>
              {this.props.checklistTemplateLinks.map(checklistTemplateLink => (
                <ChecklistTemplateLinkItem
                  checklistTemplateLink={checklistTemplateLink}
                  onClickPreview={() => {
                    this.setState({
                      showPreviewChecklistModal: true,
                      showPreviewChecklistModalForTemplateId: checklistTemplateLink.checklist_template_id,
                    });
                  }}
                  onClickSettings={() => {
                    this.setState({
                      showTemplateAssetSettingsModal: true,
                    });
                  }}
                />
              ))}
            </List>
          </CardGrid.Row>
        </>
      );
    }
  };

  renderPreviewChecklistModal = () => {
    return (
      <PreviewChecklistTemplateModal
        hideFooter
        open={this.state.showPreviewChecklistModal}
        id={this.state.showPreviewChecklistModalForTemplateId}
        onClose={() => {
          this.setState({ showPreviewChecklistModal: false });
        }}
      />
    );
  };

  renderTemplateAssetSettingsModal = () => {
    return (
      <TemplateAssetsSettingsModal
        open={this.state.showTemplateAssetSettingsModal}
        onClose={() => {
          this.setState({ showTemplateAssetSettingsModal: false });
        }}
      />
    );
  };

  render() {
    if (this.props.checklistTemplateLinks.length === 0) {
      return null;
    }
    return (
      <>
        {this.renderChecklistListItem()}
        {this.renderPreviewChecklistModal()}
        {this.renderTemplateAssetSettingsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateLinks:
      EntitySelectors.getChecklistTemplateLinks(
        state,
        ownProps.operationalMaintenanceTemplateVersion.checklist_template_links
      ) || [],
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
