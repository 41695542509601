import React from 'react';
import styles from './style.module.scss';
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer';

const Layout = ({ children }) => <div className={styles['container']}>{children}</div>;

Layout.LeftContainer = LeftContainer;
Layout.RightContainer = RightContainer;

export default Layout;
