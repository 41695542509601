import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import { Decimal } from 'decimal.js';
import { HelperFunctions } from 'sdk';
import moment from 'moment';
import { EmptyDataSet } from 'views/components/Shared/General';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { REPORT_PER_TYPES, REPORT_SPLIT_PER_TYPES } from 'sdk/WorkOrderSpentTime';
import styles from './style.module.scss';

const EXTERNAL_COLOR = '#D36729';
const INTERNAL_COLOR = '#4C9AC8';
const LABOR_COLOR = '#4C9AC8';

const CORRECTIVE = '#D36729';
const PREVENTIVE = '#4C9AC8';
const IMPROVEMENT = '#2baf2d';
const MODIFICATION = '#c55050';

class Chart extends PureComponent {
  state = {
    labels: [],
    datasets: [],
    isBuildingDataStructure: true,
    isEmpty: false,
  };

  componentDidMount() {
    const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
    const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
    const showingCategories = this.props.perType === REPORT_PER_TYPES.WorkOrderSpentTimeCategory;
    const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
    const showingUsers = this.props.perType === REPORT_PER_TYPES.User;
    const showingVendors = this.props.perType === REPORT_PER_TYPES.Vendor;
    const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
    const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
    const showingExternal = this.props.perType === REPORT_PER_TYPES.External;
    const showingWorkOrderCategorySimple = this.props.perType === REPORT_PER_TYPES.WorkOrderCategorySimple;
    const showingWorkOrderCategoryDetailed =
      this.props.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed;
    if (showingWorkOrderTypes) {
      this.buildWorkOrderTypesDataStructure();
    } else if (showingYears) {
      this.buildYearsDataStructure();
    } else if (showingMonths) {
      this.buildMonthsDataStructure();
    } else if (showingAssets) {
      this.buildAssetsDataStructure();
    } else if (showingPriorities) {
      this.buildPrioritiesDataStructure();
    } else if (showingCategories) {
      this.buildWorkOrderSpentTimeCategoriesDataStructure();
    } else if (showingVendors) {
      this.buildVendorsDataStructure();
    } else if (showingExternal) {
      this.buildInternalExternalDataStructure();
    } else if (showingUsers) {
      this.buildUsersDataStructure();
    } else if (showingWorkOrderCategorySimple) {
      this.buildWorkOrderCategoriesSimpleDataStructure();
    } else if (showingWorkOrderCategoryDetailed) {
      this.buildWorkOrderCategoriesDetailedDataStructure();
    } else {
      this.setState({ isBuildingDataStructure: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isFetching && this.props.isFetching) {
      this.setState({ isBuildingDataStructure: true });
    } else {
      const finishedLoading = prevProps.isFetching && !this.props.isFetching;
      const changedSortOrder =
        prevProps.sortingOnPerType !== this.props.sortingOnPerType ||
        prevProps.sortingOnPerSplitType !== this.props.sortingOnPerSplitType;
      if (finishedLoading || (!this.props.isFetching && changedSortOrder)) {
        const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
        const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
        const showingCategories = this.props.perType === REPORT_PER_TYPES.WorkOrderSpentTimeCategory;
        const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
        const showingUsers = this.props.perType === REPORT_PER_TYPES.User;
        const showingVendors = this.props.perType === REPORT_PER_TYPES.Vendor;
        const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
        const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
        const showingExternal = this.props.perType === REPORT_PER_TYPES.External;
        const showingWorkOrderCategorySimple =
          this.props.perType === REPORT_PER_TYPES.WorkOrderCategorySimple;
        const showingWorkOrderCategoryDetailed =
          this.props.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed;
        if (showingWorkOrderTypes) {
          this.buildWorkOrderTypesDataStructure();
        } else if (showingYears) {
          this.buildYearsDataStructure();
        } else if (showingMonths) {
          this.buildMonthsDataStructure();
        } else if (showingAssets) {
          this.buildAssetsDataStructure();
        } else if (showingPriorities) {
          this.buildPrioritiesDataStructure();
        } else if (showingCategories) {
          this.buildWorkOrderSpentTimeCategoriesDataStructure();
        } else if (showingVendors) {
          this.buildVendorsDataStructure();
        } else if (showingExternal) {
          this.buildInternalExternalDataStructure();
        } else if (showingWorkOrderCategorySimple) {
          this.buildWorkOrderCategoriesSimpleDataStructure();
        } else if (showingWorkOrderCategoryDetailed) {
          this.buildWorkOrderCategoriesDetailedDataStructure();
        } else if (showingUsers) {
          this.buildUsersDataStructure();
        } else {
          this.setState({ isBuildingDataStructure: false });
        }
      }
    }
  }

  buildWorkOrderTypesDataStructure = () => {
    if (this.props.workOrderTypes.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        internal: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['false'] || 0),
        external: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['true'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderTypes.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderTypes.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderTypes.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderTypes.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
        title,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        workOrderTypes = workOrderTypes.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: workOrderTypes.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: workOrderTypes.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildUsersDataStructure = () => {
    if (this.props.users.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let users = this.props.users.map(({ name, id }) => ({
        name,
        internal: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['false'] || 0),
        external: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['true'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: users.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: users.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let users = this.props.users.map(({ name, id }) => ({
        name,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let users = this.props.users.map(({ name, id }) => ({
        name,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        users.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: users.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let users = this.props.users.map(({ name, id }) => ({
        name,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        users = users.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        users = users.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: users.map(({ name }) => name),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: users.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderSpentTimeCategoriesDataStructure = () => {
    if (this.props.workOrderSpentTimeCategories.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let workOrderSpentTimeCategories = this.props.workOrderSpentTimeCategories.map(({ title, id }) => ({
        title,
        internal: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['false'] || 0),
        external: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['true'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderSpentTimeCategories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderSpentTimeCategories = workOrderSpentTimeCategories.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      }
      this.setState({
        labels: workOrderSpentTimeCategories.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderSpentTimeCategories.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: workOrderSpentTimeCategories.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let workOrderSpentTimeCategories = this.props.workOrderSpentTimeCategories.map(({ title, id }) => ({
        title,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderSpentTimeCategories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderSpentTimeCategories = workOrderSpentTimeCategories.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      }
      this.setState({
        labels: workOrderSpentTimeCategories.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let workOrderSpentTimeCategories = this.props.workOrderSpentTimeCategories.map(({ title, id }) => ({
        title,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        workOrderSpentTimeCategories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderSpentTimeCategories = workOrderSpentTimeCategories.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      }
      this.setState({
        labels: workOrderSpentTimeCategories.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(
              ({ preventive_condition_based }) => preventive_condition_based
            ),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: workOrderSpentTimeCategories.map(
              ({ preventive_predetermined }) => preventive_predetermined
            ),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let workOrderSpentTimeCategories = this.props.workOrderSpentTimeCategories.map(({ title, id }) => ({
        title,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        workOrderSpentTimeCategories = workOrderSpentTimeCategories.sort((a, b) =>
          b.cost.greaterThan(a.cost) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        workOrderSpentTimeCategories = workOrderSpentTimeCategories.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      }
      this.setState({
        labels: workOrderSpentTimeCategories.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: workOrderSpentTimeCategories.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderCategoriesSimpleDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let categories = [
        {
          category: 'corrective',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          internal: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['corrective'] == null ? 0 : this.props.data['corrective']['true'] || 0
          ),
        },
        {
          category: 'preventive',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          internal: new Decimal(
            this.props.data['preventive'] == null ? 0 : this.props.data['preventive']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['preventive'] == null ? 0 : this.props.data['preventive']['true'] || 0
          ),
        },
        {
          category: 'improvement',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
          internal: new Decimal(
            this.props.data['improvement'] == null ? 0 : this.props.data['improvement']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['improvement'] == null ? 0 : this.props.data['improvement']['true'] || 0
          ),
        },
        {
          category: 'modification',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
          internal: new Decimal(
            this.props.data['modification'] == null ? 0 : this.props.data['modification']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['modification'] == null ? 0 : this.props.data['modification']['true'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        categories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            hoverBackgroundColor: INTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: categories.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            hoverBackgroundColor: EXTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: categories.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let categories = [
        {
          category: 'corrective',
          cost: new Decimal(this.props.data['corrective'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
        },
        {
          category: 'preventive',
          cost: new Decimal(this.props.data['preventive'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
        },
        {
          category: 'improvement',
          cost: new Decimal(this.props.data['improvement'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
        },
        {
          category: 'modification',
          cost: new Decimal(this.props.data['modification'] || 0),
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
        },
      ];
      if (this.props.sortingOnType === this.props.type) {
        categories.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            minBarLength: 2,
            maxBarThickness: 36,
            backgroundColor: LABOR_COLOR,
            hoverBackgroundColor: LABOR_COLOR,
            data: categories.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildWorkOrderCategoriesDetailedDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let categories = [
        {
          category: WorkOrderCategory.CorrectiveDefered,
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-defered',
          })}`,
          internal: new Decimal(
            this.props.data['corrective_defered'] == null
              ? 0
              : this.props.data['corrective_defered']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['corrective_defered'] == null
              ? 0
              : this.props.data['corrective_defered']['true'] || 0
          ),
        },
        {
          category: WorkOrderCategory.CorrectiveImmediate,
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-immediate',
          })}`,
          internal: new Decimal(
            this.props.data['corrective_immediate'] == null
              ? 0
              : this.props.data['corrective_immediate']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['corrective_immediate'] == null
              ? 0
              : this.props.data['corrective_immediate']['true'] || 0
          ),
        },
        {
          category: WorkOrderCategory.PreventiveConditionBased,
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-condition-based',
          })}`,
          internal: new Decimal(
            this.props.data['preventive_condition_based'] == null
              ? 0
              : this.props.data['preventive_condition_based']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['preventive_condition_based'] == null
              ? 0
              : this.props.data['preventive_condition_based']['true'] || 0
          ),
        },
        {
          category: WorkOrderCategory.PreventivePredetermined,
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-predetermined',
          })}`,
          internal: new Decimal(
            this.props.data['preventive_predetermined'] == null
              ? 0
              : this.props.data['preventive_predetermined']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['preventive_predetermined'] == null
              ? 0
              : this.props.data['preventive_predetermined']['true'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        categories.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.costs-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            hoverBackgroundColor: INTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: categories.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.costs-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            hoverBackgroundColor: EXTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: categories.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let categories = [
        {
          category: WorkOrderCategory.CorrectiveDefered,
          cost: new Decimal(this.props.data['corrective_defered'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-defered',
          })}`,
        },
        {
          category: WorkOrderCategory.CorrectiveImmediate,
          cost: new Decimal(this.props.data['corrective_immediate'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-immediate',
          })}`,
        },
        {
          category: WorkOrderCategory.PreventiveConditionBased,
          cost: new Decimal(this.props.data['preventive_condition_based'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-condition-based',
          })}`,
        },
        {
          category: WorkOrderCategory.PreventivePredetermined,
          cost: new Decimal(this.props.data['preventive_predetermined'] || 0),
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-predetermined',
          })}`,
        },
      ];
      if (this.props.sortingOnType === this.props.type) {
        categories.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      }
      this.setState({
        labels: categories.map(({ label }) => label),
        datasets: [
          {
            minBarLength: 2,
            maxBarThickness: 36,
            backgroundColor: LABOR_COLOR,
            hoverBackgroundColor: LABOR_COLOR,
            data: categories.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildVendorsDataStructure = () => {
    if (this.props.vendors.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let vendors = this.props.vendors.map(({ name, id }) => ({
        name,
        internal: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['false'] || 0),
        external: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['true'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        vendors.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        vendors = vendors.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: vendors.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: vendors.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: vendors.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let vendors = this.props.vendors.map(({ name, id }) => ({
        name,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        vendors.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        vendors = vendors.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: vendors.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let vendors = this.props.vendors.map(({ name, id }) => ({
        name,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        vendors.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        vendors = vendors.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: vendors.map(({ name }) => name),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: vendors.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let vendors = this.props.vendors.map(({ name, id }) => ({
        name,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        vendors = vendors.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        vendors = vendors.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.setState({
        labels: vendors.map(({ name }) => name),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: vendors.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildAssetsDataStructure = () => {
    if (this.props.assets.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        internal: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['false'] || 0),
        external: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['true'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assets.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: assets.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        corrective: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['corrective'] || 0),
        preventive: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['preventive'] || 0),
        improvement: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['improvement'] || 0),
        modification: new Decimal(this.props.data[id] == null ? 0 : this.props.data[id]['modification'] || 0),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        corrective_immediate: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_immediate'] || 0
        ),
        corrective_defered: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['corrective_defered'] || 0
        ),
        preventive_condition_based: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_condition_based'] || 0
        ),
        preventive_predetermined: new Decimal(
          this.props.data[id] == null ? 0 : this.props.data[id]['preventive_predetermined'] || 0
        ),
      }));
      if (this.props.sortingOnPerSplitType) {
        assets.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: assets.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let assets = this.props.assets.map(({ title, id }) => ({
        title,
        cost: new Decimal(this.props.data[id] || 0),
      }));
      if (this.props.sortingOnType === this.props.type) {
        assets = assets.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        assets = assets.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.setState({
        labels: assets.map(({ title }) => title),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: assets.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildYearsDataStructure = () => {
    const currentYear = moment().year();
    let amountOfYearsSinceSystemWasCreated = currentYear - this.props.yearSystemWasCreated;
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          internal: new Decimal(this.props.data[year] == null ? 0 : this.props.data[year]['false'] || 0),
          external: new Decimal(this.props.data[year] == null ? 0 : this.props.data[year]['true'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: years.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: years.map(({ external }) => external.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          corrective: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['modification'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ modification }) => modification),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({
          year,
          corrective_immediate: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data[year] == null ? 0 : this.props.data[year]['preventive_predetermined'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        years.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: years.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else {
      let years = [];
      for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
        const year = currentYear - i;
        years.push({ year, cost: new Decimal(this.props.data[year] || 0) });
      }
      if (this.props.sortingOnType === this.props.type) {
        years.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        years.sort((a, b) => a.year - b.year);
      }
      this.setState({
        labels: years.map(({ year }) => year),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: years.map(({ cost }) => cost.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    }
  };

  buildMonthsDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          internal: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['false'] || 0),
          external: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['true'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: months.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: months.map(({ external }) => external.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          corrective: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['corrective'] || 0),
          preventive: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['preventive'] || 0),
          improvement: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['improvement'] || 0),
          modification: new Decimal(this.props.data[i] == null ? 0 : this.props.data[i]['modification'] || 0),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: INTERNAL_COLOR,
            hoverBackgroundColor: INTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: EXTERNAL_COLOR,
            hoverBackgroundColor: EXTERNAL_COLOR,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ modification }) => modification),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          corrective_immediate: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data[i] == null ? 0 : this.props.data[i]['preventive_predetermined'] || 0
          ),
        });
      }
      if (this.props.sortingOnPerSplitType) {
        months.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: months.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    } else {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          monthNum: i,
          month: HelperFunctions.capitalizeFirstLetter(
            moment()
              .month(i - 1)
              .format('MMM')
          ),
          cost: new Decimal(this.props.data[i] || 0),
        });
      }
      if (this.props.sortingOnType === this.props.type) {
        months.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      } else if (this.props.sortingOnPerType === this.props.perType) {
        months.sort((a, b) => a.monthNum - b.monthNum);
      }
      this.setState({
        labels: months.map(({ month }) => month),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: months.map(({ cost }) => cost.toString()),
          },
        ],
        isBuildingDataStructure: false,
        isEmpty: false,
      });
    }
  };

  buildPrioritiesDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      let priorities = [
        {
          priority: 'high',
          internal: new Decimal(this.props.data['high'] == null ? 0 : this.props.data['high']['false'] || 0),
          external: new Decimal(this.props.data['high'] == null ? 0 : this.props.data['high']['true'] || 0),
        },
        {
          priority: 'medium',
          internal: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['false'] || 0
          ),
          external: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['true'] || 0
          ),
        },
        {
          priority: 'low',
          internal: new Decimal(this.props.data['low'] == null ? 0 : this.props.data['low']['false'] || 0),
          external: new Decimal(this.props.data['low'] == null ? 0 : this.props.data['low']['true'] || 0),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        priorities.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' }),
            backgroundColor: INTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: priorities.map(({ internal }) => internal.toString()),
          },
          {
            label: this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' }),
            backgroundColor: EXTERNAL_COLOR,
            maxBarThickness: 36,
            minBarLength: 2,
            data: priorities.map(({ external }) => external.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      let priorities = [
        {
          priority: 'high',
          corrective: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['modification'] || 0
          ),
        },
        {
          priority: 'medium',
          corrective: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['modification'] || 0
          ),
        },
        {
          priority: 'low',
          corrective: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['modification'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        priorities.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective }) => corrective),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive }) => preventive),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
            backgroundColor: IMPROVEMENT,
            hoverBackgroundColor: IMPROVEMENT,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ improvement }) => improvement),
          },
          {
            label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
            backgroundColor: MODIFICATION,
            hoverBackgroundColor: MODIFICATION,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ modification }) => modification),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      let priorities = [
        {
          priority: 'high',
          corrective_immediate: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['high'] == null ? 0 : this.props.data['high']['preventive_predetermined'] || 0
          ),
        },
        {
          priority: 'medium',
          corrective_immediate: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['medium'] == null
              ? 0
              : this.props.data['medium']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['medium'] == null ? 0 : this.props.data['medium']['preventive_predetermined'] || 0
          ),
        },
        {
          priority: 'low',
          corrective_immediate: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['low'] == null ? 0 : this.props.data['low']['preventive_predetermined'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        priorities.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-immediate',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective_immediate }) => corrective_immediate),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.corrective-defered',
            }),
            backgroundColor: CORRECTIVE,
            hoverBackgroundColor: CORRECTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ corrective_defered }) => corrective_defered),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-condition-based',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive_condition_based }) => preventive_condition_based),
          },
          {
            label: this.props.intl.formatMessage({
              id: 'resources.work-order.categories-short.preventive-predetermined',
            }),
            backgroundColor: PREVENTIVE,
            hoverBackgroundColor: PREVENTIVE,
            minBarLength: 2,
            maxBarThickness: 36,
            data: priorities.map(({ preventive_predetermined }) => preventive_predetermined),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      let priorities = [
        { priority: 'high', cost: new Decimal(this.props.data['high'] || 0) },
        { priority: 'medium', cost: new Decimal(this.props.data['medium'] || 0) },
        { priority: 'low', cost: new Decimal(this.props.data['low'] || 0) },
      ];
      if (this.props.sortingOnType === this.props.type) {
        priorities.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      }
      this.setState({
        labels: priorities.map(({ priority }) => priority),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: priorities.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  buildInternalExternalDataStructure = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      const items = [
        {
          external: false,
          corrective: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['modification'] || 0
          ),
        },
        {
          external: true,
          corrective: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['corrective'] || 0
          ),
          preventive: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['preventive'] || 0
          ),
          improvement: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['improvement'] || 0
          ),
          modification: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['modification'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        items.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      let datasets = [
        {
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          backgroundColor: CORRECTIVE,
          hoverBackgroundColor: CORRECTIVE,
          minBarLength: 2,
          maxBarThickness: 36,
          data: items.map(({ corrective }) => corrective),
        },
        {
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          minBarLength: 2,
          maxBarThickness: 36,
          backgroundColor: PREVENTIVE,
          hoverBackgroundColor: PREVENTIVE,
          data: items.map(({ preventive }) => preventive),
        },
        {
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
          minBarLength: 2,
          maxBarThickness: 36,
          backgroundColor: IMPROVEMENT,
          hoverBackgroundColor: IMPROVEMENT,
          data: items.map(({ improvement }) => improvement),
        },
        {
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
          minBarLength: 2,
          maxBarThickness: 36,
          backgroundColor: MODIFICATION,
          hoverBackgroundColor: MODIFICATION,
          data: items.map(({ modification }) => modification),
        },
      ];
      this.setState({
        labels: items.map(({ external }) => external),
        datasets,
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      const items = [
        {
          external: false,
          corrective_immediate: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['false'] == null ? 0 : this.props.data['false']['preventive_predetermined'] || 0
          ),
        },
        {
          external: true,
          corrective_immediate: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['corrective_immediate'] || 0
          ),
          corrective_defered: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['corrective_defered'] || 0
          ),
          preventive_condition_based: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['preventive_condition_based'] || 0
          ),
          preventive_predetermined: new Decimal(
            this.props.data['true'] == null ? 0 : this.props.data['true']['preventive_predetermined'] || 0
          ),
        },
      ];
      if (this.props.sortingOnPerSplitType) {
        items.sort((a, b) =>
          b[this.props.sortingOnPerSplitType].greaterThan(a[this.props.sortingOnPerSplitType]) ? 1 : -1
        );
      }
      let datasets = [
        {
          label: this.props.intl.formatMessage({
            id: 'resources.work-order.categories-short.corrective-immediate',
          }),
          backgroundColor: CORRECTIVE,
          hoverBackgroundColor: CORRECTIVE,
          minBarLength: 2,
          maxBarThickness: 36,
          data: items.map(({ corrective_immediate }) => corrective_immediate),
        },
        {
          label: this.props.intl.formatMessage({
            id: 'resources.work-order.categories-short.corrective-defered',
          }),
          backgroundColor: CORRECTIVE,
          hoverBackgroundColor: CORRECTIVE,
          minBarLength: 2,
          maxBarThickness: 36,
          data: items.map(({ corrective_defered }) => corrective_defered),
        },
        {
          label: this.props.intl.formatMessage({
            id: 'resources.work-order.categories-short.preventive-condition-based',
          }),
          backgroundColor: PREVENTIVE,
          hoverBackgroundColor: PREVENTIVE,
          minBarLength: 2,
          maxBarThickness: 36,
          data: items.map(({ preventive_condition_based }) => preventive_condition_based),
        },
        {
          label: this.props.intl.formatMessage({
            id: 'resources.work-order.categories-short.preventive-predetermined',
          }),
          backgroundColor: PREVENTIVE,
          hoverBackgroundColor: PREVENTIVE,
          minBarLength: 2,
          maxBarThickness: 36,
          data: items.map(({ preventive_predetermined }) => preventive_predetermined),
        },
      ];
      this.setState({
        labels: items.map(({ external }) => external),
        datasets,
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    } else {
      const items = [
        { external: false, cost: new Decimal(this.props.data['false'] || 0) },
        { external: true, cost: new Decimal(this.props.data['true'] || 0) },
      ];
      if (this.props.sortingOnType === this.props.type) {
        items.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      }
      this.setState({
        labels: items.map(({ external }) => external),
        datasets: [
          {
            maxBarThickness: 36,
            minBarLength: 2,
            backgroundColor: LABOR_COLOR,
            data: items.map(({ cost }) => cost.toString()),
          },
        ],
        isEmpty: false,
        isBuildingDataStructure: false,
      });
    }
  };

  renderSplitValues = () => {
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['internal']}`}>
            <FormattedMessage id="components.reported-time-overview.internal" />
          </span>
          <span className={`${styles['split-label']} ${styles['external']}`}>
            <FormattedMessage id="components.reported-time-overview.external" />
          </span>
        </div>
      );
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive" />
          </span>
          <span className={`${styles['split-label']} ${styles['improvement']}`}>
            <FormattedMessage id="resources.work-order.categories-short.improvement" />
          </span>
          <span className={`${styles['split-label']} ${styles['modification']}`}>
            <FormattedMessage id="resources.work-order.categories-short.modification" />
          </span>
        </div>
      );
    }
    if (this.props.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) {
      return (
        <div className={styles['split-labels']}>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective-immediate" />
          </span>
          <span className={`${styles['split-label']} ${styles['corrective']}`}>
            <FormattedMessage id="resources.work-order.categories-short.corrective-defered" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive-condition-based" />
          </span>
          <span className={`${styles['split-label']} ${styles['preventive']}`}>
            <FormattedMessage id="resources.work-order.categories-short.preventive-predetermined" />
          </span>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.props.isFetching || this.state.isBuildingDataStructure) return <Loader small />;
    if (this.state.isEmpty) {
      if (this.props.perType === REPORT_PER_TYPES.Asset) {
        if (this.props.showingRootAssets) {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={
                  <FormattedMessage id="components.reported-time-overview.no-assets-empty-data-set.title" />
                }
                subtitle={
                  <FormattedMessage id="components.reported-time-overview.no-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        } else {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={
                  <FormattedMessage id="components.reported-time-overview.no-children-assets-empty-data-set.title" />
                }
                subtitle={
                  <FormattedMessage id="components.reported-time-overview.no-children-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        }
      } else if (this.props.perType === REPORT_PER_TYPES.WorkOrderType) {
        return (
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.reported-time-overview.no-work-order-types-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.reported-time-overview.no-work-order-types-empty-data-set.subtitle" />
            }
          />
        );
      } else if (this.props.perType === REPORT_PER_TYPES.Vendor) {
        return (
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.reported-time-overview.no-vendors-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.reported-time-overview.no-vendors-empty-data-set.subtitle" />
            }
          />
        );
      } else if (this.props.perType === REPORT_PER_TYPES.WorkOrderSpentTimeCategory) {
        return (
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.reported-time-overview.no-work-order-spent-time-categories.title" />
            }
            subtitle={
              <FormattedMessage id="components.reported-time-overview.no-work-order-spent-time-categories.subtitle" />
            }
          />
        );
      }
    }
    return (
      <Bar
        data={{
          labels: this.state.labels,
          datasets: this.state.datasets,
        }}
        width={582}
        height={410}
        options={{
          legend: false,
          maintainAspectRatio: false,
          tooltips: {
            displayColors: false,
            callbacks: {
              title: (tooltipItem, { datasets }) => {
                const { label, datasetIndex } = tooltipItem[0];
                if (datasets.length > 1) {
                  let tooltipLabel = label;
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    tooltipLabel = this.props.intl.formatMessage({
                      id: `resources.work-order.prio.${label}`,
                    });
                  }
                  if (this.props.perType === REPORT_PER_TYPES.External) {
                    tooltipLabel =
                      label === 'true'
                        ? this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' })
                        : this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' });
                  }
                  return `${tooltipLabel} - ${datasets[datasetIndex].label}`;
                } else {
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    return this.props.intl.formatMessage({ id: `resources.work-order.prio.${label}` });
                  }
                  if (this.props.perType === REPORT_PER_TYPES.External) {
                    return label === 'true'
                      ? this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' })
                      : this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' });
                  }
                  return label;
                }
              },
              label: ({ value }) => {
                return this.props.intl.formatMessage(
                  {
                    id: 'components.reported-time-overview.hours',
                  },
                  {
                    value,
                  }
                );
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  padding: 13,
                  fontStyle: '600',
                  suggestedMax: 10,
                  beginAtZero: true,
                  maxTicksLimit: 4,
                  callback: (value, index, values) => {
                    return this.props.intl.formatMessage(
                      {
                        id: 'components.reported-time-overview.hours-short',
                      },
                      {
                        value,
                      }
                    );
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  fontStyle: '600',
                  callback: (value, index, values) => {
                    if (this.props.perType === REPORT_PER_TYPES.Priority) {
                      return this.props.intl.formatMessage({ id: `resources.work-order.prio.${value}` });
                    }
                    if (this.props.perType === REPORT_PER_TYPES.External) {
                      return value
                        ? this.props.intl.formatMessage({ id: 'components.reported-time-overview.external' })
                        : this.props.intl.formatMessage({ id: 'components.reported-time-overview.internal' });
                    }
                    return value;
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
          },
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderSplitValues()}
        <div className={styles['chart-container']}>{this.renderContent()}</div>
      </>
    );
  }
}

export default injectIntl(Chart);
