import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class EditBillingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      purchase_order_billing_name: '',
      purchase_order_billing_address1: '',
      purchase_order_billing_address2: '',
      purchase_order_billing_zip: '',
      purchase_order_billing_city: '',
      purchase_order_billing_country: '',
      purchase_order_billing_email: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        purchase_order_billing_name,
        purchase_order_billing_address1,
        purchase_order_billing_address2,
        purchase_order_billing_zip,
        purchase_order_billing_city,
        purchase_order_billing_country,
        purchase_order_billing_email,
      } = this.props.currentSystem;

      this.setState({
        isSaving: false,
        purchase_order_billing_name,
        purchase_order_billing_address1,
        purchase_order_billing_address2,
        purchase_order_billing_zip,
        purchase_order_billing_city,
        purchase_order_billing_country,
        purchase_order_billing_email,
      });
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    this.setState({ isSaving: true });
    const params = {
      purchase_order_billing_name: this.state.purchase_order_billing_name,
      purchase_order_billing_email: this.state.purchase_order_billing_email,
      purchase_order_billing_address1: this.state.purchase_order_billing_address1,
      purchase_order_billing_address2: this.state.purchase_order_billing_address2,
      purchase_order_billing_zip: this.state.purchase_order_billing_zip,
      purchase_order_billing_city: this.state.purchase_order_billing_city,
      purchase_order_billing_country: this.state.purchase_order_billing_country,
    };

    this.props
      .updateSystem(this.props.currentSystem.id, params)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.system.address-name" />}>
            <Field.Text
              autoFocus
              value={this.state.purchase_order_billing_name}
              onChange={purchase_order_billing_name => {
                this.setState({ purchase_order_billing_name });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.system.address-address1" />}>
            <Field.Text
              value={this.state.purchase_order_billing_address1}
              onChange={purchase_order_billing_address1 => {
                this.setState({ purchase_order_billing_address1 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.system.address-address2" />}>
            <Field.Text
              value={this.state.purchase_order_billing_address2}
              onChange={purchase_order_billing_address2 => {
                this.setState({ purchase_order_billing_address2 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column md={4}>
          <Field view={false} label={<FormattedMessage id="resources.system.address-zip" />}>
            <Field.Text
              value={this.state.purchase_order_billing_zip}
              onChange={purchase_order_billing_zip => {
                this.setState({ purchase_order_billing_zip });
              }}
            />
          </Field>
        </Grid.Column>
        <Grid.Column md={8}>
          <Field view={false} label={<FormattedMessage id="resources.system.address-city" />}>
            <Field.Text
              value={this.state.purchase_order_billing_city}
              onChange={purchase_order_billing_city => {
                this.setState({ purchase_order_billing_city });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.system.address-country" />}>
            <Field.Text
              value={this.state.purchase_order_billing_country}
              onChange={purchase_order_billing_country => {
                this.setState({ purchase_order_billing_country });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.system.purchase-order-billing-email" />}>
            <Field.Text
              type="email"
              value={this.state.purchase_order_billing_email}
              onChange={purchase_order_billing_email => {
                this.setState({ purchase_order_billing_email });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.purchase-orders.billing-address.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.billing-address.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditBillingAddressModal));
