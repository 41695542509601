import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { RequestsOperations } from 'state/ducks/requests';
import { AuthSelectors } from 'state/ducks/auth';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getRequestMenuItems = () => {
    const { request_production_supervisor_approval_activated } = this.props.settings;
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.title',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.RequestTitle);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.description',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.RequestDescription);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.status',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Status);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.request-type',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.RequestType);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.priority',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Priority);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.created-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.requests.filters.advanced.request.created-at',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedAt);
        },
      },
    ];

    if (request_production_supervisor_approval_activated) {
      menuItems = [
        ...menuItems,
        {
          label: this.props.intl.formatMessage({
            id: 'screens.requests.filters.advanced.request.approved-by-production-supervisor',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.ApprovedByProductionSupervisor);
          },
        },
      ];
    }

    menuItems = [
      ...menuItems,
      {
        label: this.props.intl.formatMessage({
          id: 'screens.requests.filters.advanced.request.archived-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.ArchivedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.requests.filters.advanced.request.archived-comment',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.ArchivedComment);
        },
      },
    ];

    return this.getFilteredMenuItems(menuItems);
  };

  getAssetMenuItems = () => {
    let menuItems = [];
    if (this.props.isProductionSupervisor) {
      menuItems = [
        ...menuItems,
        {
          label: this.props.intl.formatMessage({
            id: 'resources.asset.type',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.AssetType);
          },
        },
      ];
    } else {
      menuItems = [
        ...menuItems,
        {
          label: this.props.intl.formatMessage({
            id: 'resources.asset.resource',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.Asset);
          },
        },
        {
          label: this.props.intl.formatMessage({
            id: 'screens.requests.filters.advanced.asset.asset-including-children',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.AssetWithTreeChildren);
          },
        },
        {
          label: this.props.intl.formatMessage({
            id: 'resources.asset.type',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.AssetType);
          },
        },
      ];
    }

    return this.getFilteredMenuItems(menuItems);
  };

  getWorkOrderMenuItems = () => {
    const menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.completed-at',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderCompletedDate);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderRequestFilters = () => {
    const requestItems = this.getRequestMenuItems();
    if (requestItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.requests.filters.advanced.request.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {requestItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderAssetFilters = () => {
    const assetItems = this.getAssetMenuItems();
    if (assetItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.requests.filters.advanced.asset.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {assetItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderWorkOrderFilters = () => {
    const workOrderItems = this.getWorkOrderMenuItems();
    if (workOrderItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.requests.filters.advanced.work-order.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {workOrderItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [
      ...this.getRequestMenuItems(),
      ...this.getAssetMenuItems(),
      ...this.getWorkOrderMenuItems(),
    ];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return (
      <>
        {this.renderRequestFilters()}
        {this.renderAssetFilters()}
        {this.renderWorkOrderFilters()}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.requests.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: RequestsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
