import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import moment from 'moment';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { Field, Button, FieldErrorWrapper, Loader } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { TreePath, AssetTitle } from 'views/components/Asset';
import ReasonField from 'views/components/Downtime/DowntimeModal/Edit/ReasonField';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class DowntimeOptionsDrawer extends Component {
  getInitialState = () => ({
    isFetching: false,
    showAlreadyExistingDowntimeForAsset: false,
    downtimeIsOnAnotherAsset: false,
    anotherAssetId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    const opened = !prevProps.open && this.props.open;
    const closed = prevProps.open && !this.props.open;
    const changedAsset = prevProps.assetId !== this.props.assetId;
    const clearedAsset = prevProps.assetId && this.props.assetId == null;
    const downtimeIsOnAnotherAssetWasChanged = prevState.anotherAssetId !== this.state.anotherAssetId;
    if (opened) {
      this.checkIfAssetHasActiveDowntime(this.props.assetId);
    } else if (this.props.open && changedAsset) {
      this.checkIfAssetHasActiveDowntime(this.props.assetId);
    } else if (this.props.open && downtimeIsOnAnotherAssetWasChanged) {
      this.checkIfAssetHasActiveDowntime(this.state.anotherAssetId);
    } else if (clearedAsset || closed) {
      this.props.onChange({ downtime_id: null, downtime: null });
    }
  }

  checkIfAssetHasActiveDowntime = assetId => {
    this.setState({ isFetching: true });
    return API.listDowntimes(this.props.currentSystem.id, {
      to: {
        [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
      },
      asset_id: assetId,
    }).then(res => {
      const { data: downtimes } = res;
      if (downtimes.length > 0) {
        this.setState(
          {
            showAlreadyExistingDowntimeForAsset: downtimes[0],
            isFetching: false,
          },
          () => {
            this.props.onChange({ downtime_id: downtimes[0].id, downtime: null });
          }
        );
      } else {
        this.props.onChange({
          downtime_id: null,
          downtime: {
            asset_id: assetId,
            from: moment().format('YYYY-MM-DD HH:mm'),
            to: null,
            downtime_reason_id: null,
          },
        });
        this.setState({ showAlreadyExistingDowntimeForAsset: false, isFetching: false });
      }
    });
  };

  renderAssetAlreadyHasActiveDowntime = () => {
    if (!this.state.showAlreadyExistingDowntimeForAsset) return null;
    return (
      <div className={styles['active-downtime-container']}>
        <span className={styles['title']}>
          <FormattedMessage id="screens.request.downtime-drawer.asset-already-has-active-downtime" />
        </span>
        <span className={styles['subtitle']}>
          <TreePath fullPath assetId={this.state.showAlreadyExistingDowntimeForAsset.asset.id} />
        </span>
        <span className={styles['subtitle']}>
          <FormattedMessage id="resources.downtime.started-at" />:{' '}
          {moment(this.state.showAlreadyExistingDowntimeForAsset.from).format('LLL')}
        </span>
        <div className={styles['new-downtime-button']}>
          <Button
            gray
            label="screens.request.downtime-drawer.new-downtime"
            onClick={() => {
              this.props.onChange({
                downtime_id: null,
                downtime: {
                  asset_id: this.props.assetId,
                  from: moment().format('YYYY-MM-DD HH:mm'),
                  to: null,
                  downtime_reason_id: null,
                },
              });
              this.setState({ showAlreadyExistingDowntimeForAsset: false });
            }}
          />
        </div>
      </div>
    );
  };

  renderDowntimeIsOnAnotherAsset = () => {
    if (this.state.downtimeIsOnAnotherAsset) {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.asset.resource" />}>
                <ChooseAssetInlineModal
                  trigger={
                    <Field.Resource
                      angle
                      clearable={false}
                      error={this.props.showAnotherAssetRequiredError}
                      value={
                        this.state.anotherAssetId ? (
                          <>
                            <AssetTitle id={this.state.anotherAssetId} />
                            <TreePath assetId={this.state.anotherAssetId} />
                          </>
                        ) : null
                      }
                    />
                  }
                  selectedAssetId={this.state.anotherAssetId}
                  onSelectAsset={assetId => {
                    this.setState({ anotherAssetId: assetId });
                    this.props.onClearErrors({
                      showAnotherAssetRequiredError: false,
                    });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  };

  renderAddDowntimesOption = () => {
    const { from, downtime_reason_id } = this.props.editingDowntime || {};
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.downtime.started-at" />}>
              <FieldErrorWrapper
                position="top"
                show={this.props.showFromDateRequiredError || this.props.showFromDateInFutureError}
                errorElement={
                  this.props.showFromDateRequiredError ? (
                    <FormattedMessage id="general.errors.is-required" />
                  ) : (
                    <FormattedMessage id="screens.request.downtime-drawer.from-date-in-future-error" />
                  )
                }
              >
                <Field.DateTime
                  error={this.props.showFromDateRequiredError || this.props.showFromDateInFutureError}
                  onChangeDate={date => {
                    if (!date) {
                      this.props.onChange({
                        downtime: {
                          ...this.props.editingDowntime,
                          from: null,
                        },
                      });
                    } else {
                      if (this.props.showFromDateInFutureError || this.props.showFromDateRequiredError) {
                        this.props.onClearErrors({
                          showFromDateInFutureError: false,
                          showFromDateRequiredError: false,
                        });
                      }
                      let newFromDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(from).format(
                        'HH:mm'
                      )}`;
                      if (moment(newFromDateTimeString).isValid() === false) {
                        newFromDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment().format(
                          'HH:mm'
                        )}`;
                      }
                      this.props.onChange({
                        downtime: {
                          ...this.props.editingDowntime,
                          from: newFromDateTimeString,
                        },
                      });
                    }
                  }}
                  onChangeTime={time => {
                    if (!time) {
                      this.props.onChange({
                        downtime: {
                          ...this.props.editingDowntime,
                          from: null,
                        },
                      });
                    } else {
                      if (this.props.showFromDateInFutureError || this.props.showFromDateRequiredError) {
                        this.props.onClearErrors({
                          showFromDateInFutureError: false,
                          showFromDateRequiredError: false,
                        });
                      }
                      let newFromDateTimeString = `${moment(from).format('YYYY-MM-DD')} ${moment(
                        time,
                        'HH:mm'
                      ).format('HH:mm')}`;
                      if (moment(newFromDateTimeString).isValid() === false) {
                        newFromDateTimeString = `${moment().format('YYYY-MM-DD')} ${moment(
                          time,
                          'HH:mm'
                        ).format('HH:mm')}`;
                      }
                      this.props.onChange({
                        downtime: {
                          ...this.props.editingDowntime,
                          from: newFromDateTimeString,
                        },
                      });
                    }
                  }}
                  date={moment(from).format('YYYY-MM-DD')}
                  time={moment(from).format('HH:mm')}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ReasonField
              value={downtime_reason_id}
              onChange={downtime_reason_id =>
                this.props.onChange({
                  downtime: {
                    ...this.props.editingDowntime,
                    downtime_reason_id,
                  },
                })
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              label={<FormattedMessage id="screens.request.downtime-drawer.other-asset-checkbox" />}
              checked={this.state.downtimeIsOnAnotherAsset}
              onChange={() => {
                this.setState(
                  prevState => ({
                    downtimeIsOnAnotherAsset: !prevState.downtimeIsOnAnotherAsset,
                  }),
                  () => {
                    let asset_id = null;
                    if (this.state.downtimeIsOnAnotherAsset === false) {
                      asset_id = this.props.assetId;
                    }
                    this.props.onChange({
                      downtime: {
                        ...this.props.editingDowntime,
                        asset_id,
                      },
                    });
                  }
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {this.renderDowntimeIsOnAnotherAsset()}
      </Grid>
    );
  };

  renderDrawer = () => {
    if (!this.props.open) return null;
    if (this.state.isFetching) {
      return (
        <div className={styles['loader']}>
          <Loader medium />
        </div>
      );
    }
    return this.renderAddDowntimesOption();
  };

  render() {
    return (
      <>
        <AnimateHeight duration={250} height={this.props.open ? 'auto' : 0}>
          <div className={styles['container']}>
            <div className={styles['container-with-left-border']}>
              {this.state.showAlreadyExistingDowntimeForAsset && !this.state.isFetching
                ? this.renderAssetAlreadyHasActiveDowntime()
                : this.renderDrawer()}
            </div>
          </div>
        </AnimateHeight>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(DowntimeOptionsDrawer);
