import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { Type } from 'sdk/ChecklistTemplateRow';
import { List } from 'views/components/Shared/General';
import ChecklistTemplateRow from './ChecklistTemplateRow';
import PlaceHolderDropzone from './PlaceHolderDropzone';
import styles from './style.module.scss';

class ChecklistTemplateRows extends Component {
  renderRow = ({ id, sort, type }, index) => {
    let classNames = [];
    if (type === Type.Section) {
      classNames = [...classNames, styles['spacing']];
    } else {
      classNames = [...classNames, styles['row']];
    }
    return (
      <div className={classNames.join(' ')}>
        <ChecklistTemplateRow id={id} parent={null} index={index} key={id} type={type} />
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <div className={[styles['rows-wrapper'], styles['loader-container']].join(' ')}>
          <div className={styles['width-container']}>
            <div className={styles['rows']}>
              <List>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              </List>
            </div>
          </div>
        </div>
      );
    }
    if (this.props.checklistTemplateVersion == null) {
      return null;
    }
    if (this.props.rootFields.length === 0) {
      return (
        <div className={styles['rows-wrapper']}>
          <div className={styles['width-container']}>
            <PlaceHolderDropzone index={0} fullHeight>
              <div className={styles['empty-data-set-container']}>
                <WhiteCard centerContent>
                  <EmptyDataSet
                    title={<FormattedMessage id="components.edit-checklist-modal.empty-data-set.title" />}
                    subtitle={
                      <FormattedMessage id="components.edit-checklist-modal.empty-data-set.subtitle" />
                    }
                    tiny
                    listContainer
                  />
                </WhiteCard>
              </div>
            </PlaceHolderDropzone>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['rows-wrapper']}>
        <div className={styles['width-container']}>
          <PlaceHolderDropzone index={0} />
          <div className={styles['rows']}>
            {this.props.rootFields.map((data, index) => this.renderRow(data, index))}
          </div>
          <PlaceHolderDropzone
            fullHeight
            index={this.props.rootFields.length - 1 < 0 ? 0 : this.props.rootFields.length}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateVersion: ChecklistEditModalSelectors.getChecklistTemplateVersion(state),
    rootFields: ChecklistEditModalSelectors.getRootFields(state),
  };
}

export default withRouter(connect(mapStateToProps)(ChecklistTemplateRows));
