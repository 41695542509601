import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectChecklistTemplateModal from './SelectChecklistTemplateModal';
import { PreviewChecklistTemplateModal } from 'views/components/Checklist';

export default class SelectChecklistModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectTemplateModal: false,
      showPreviewChecklistModal: false,
      showPreviewChecklistModalForTemplateId: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        showSelectTemplateModal: true,
        showPreviewChecklistModal: false,
        showPreviewChecklistModalForTemplateId: null,
      });
    }
  }

  render() {
    return (
      <>
        <SelectChecklistTemplateModal
          open={this.state.showSelectTemplateModal}
          onPreview={checklistTemplate => {
            this.setState({
              showSelectTemplateModal: false,
            });
            setTimeout(() => {
              this.setState({
                showPreviewChecklistModal: true,
                showPreviewChecklistModalForTemplateId: checklistTemplate.id,
              });
            }, 250);
          }}
          onSelect={checklistTemplate => {
            this.setState({
              showSelectTemplateModal: false,
              showPreviewChecklistModal: false,
              showPreviewChecklistModalForTemplateId: null,
            });
            this.props.onSelect(checklistTemplate);
          }}
          onClose={() => {
            this.setState({
              showSelectTemplateModal: false,
              showPreviewChecklistModal: false,
              showPreviewChecklistModalForTemplateId: null,
            });
            this.props.onClose();
          }}
        />
        <PreviewChecklistTemplateModal
          open={this.state.showPreviewChecklistModal}
          id={this.state.showPreviewChecklistModalForTemplateId}
          onClose={() => {
            this.setState({ showPreviewChecklistModal: false });
            setTimeout(() => {
              this.setState({ showSelectTemplateModal: true });
            }, 250);
          }}
          onSelect={checklistTemplate => {
            this.setState({
              showSelectTemplateModal: false,
              showPreviewChecklistModal: false,
              showPreviewChecklistModalForTemplateId: null,
            });
            this.props.onSelect(checklistTemplate);
          }}
        />
      </>
    );
  }
}

SelectChecklistModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

SelectChecklistModal.defaultProps = {
  onClose: () => {},
  onSelect: () => {},
};
