import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, NewInlineModal } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import { SearchRelevanceSortKey } from '../';

class SortingDropdown extends Component {
  renderArrow = type => {
    if (type === 'desc') {
      return (
        <div className={styles['arrow-container']}>
          <div className={styles['sort-arrow-text']}>
            <FormattedMessage id="sort.descending" />
          </div>
          <Icon regular type="arrow-down" size={12} />
        </div>
      );
    }

    if (type === 'asc') {
      return (
        <div className={styles['arrow-container']}>
          <div className={styles['sort-arrow-text']}>
            <FormattedMessage id="sort.ascending" />
          </div>
          <Icon regular type="arrow-up" size={12} />
        </div>
      );
    }
    return null;
  };

  renderShowSearchRelevanceOption = () => {
    if (this.props.showSearchRelevanceOption) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            selected={this.props.sort === SearchRelevanceSortKey}
            onClick={() => {
              this.props.onSort({
                sort: SearchRelevanceSortKey,
                'sort-order': null,
              });
            }}
          >
            <FormattedMessage id="sort.search-relevance" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <NewInlineModal
        minWidth={267}
        positionToRef={this.props.positionRef}
        position="right"
        open={this.props.open}
        onClose={() => {
          this.props.onClose();
        }}
      >
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            {this.renderShowSearchRelevanceOption()}
            {this.props.sortOptions.map(sortOption => {
              let selected = sortOption.sort === this.props.sort;
              return (
                <NewInlineModal.Dropdown.Item
                  selected={selected}
                  rightComponent={selected ? this.renderArrow(this.props.sortOrder) : null}
                  onClick={() => {
                    let sortOrder = 'asc';
                    if (this.props.sort === sortOption.sort && this.props.sortOrder === 'asc') {
                      sortOrder = 'desc';
                    }
                    this.props.onSort({
                      sort: sortOption.sort,
                      'sort-order': sortOrder,
                    });
                  }}
                >
                  {sortOption.label}
                </NewInlineModal.Dropdown.Item>
              );
            })}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </NewInlineModal>
    );
  }
}

SortingDropdown.propTypes = {
  sortOptions: PropTypes.array,
  label: PropTypes.node,
  positionRef: PropTypes.any,
  open: PropTypes.bool,
  showSearchRelevanceOption: PropTypes.bool,
  sort: PropTypes.string,
  sortOrder: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

SortingDropdown.defaultProps = {
  sortOptions: [],
  label: null,
  positionRef: null,
  open: false,
  showSearchRelevanceOption: false,
  sort: '',
  sortOrder: '',
  onClose: () => {},
  onClick: () => {},
};

export default SortingDropdown;
