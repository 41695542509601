import types from './types';

const beginUpload = data => ({
  type: types.ATTACHMENT_UPLOAD_STARTED,
  payload: data,
});

const cancelUpload = data => ({
  type: types.ATTACHMENT_UPLOAD_CANCELLED,
  payload: data,
});

const updateUpload = data => ({
  type: types.ATTACHMENT_UPLOAD_UPDATED,
  payload: data,
});

const completeUpload = data => ({
  type: types.ATTACHMENT_UPLOAD_COMPLETED,
  payload: data,
});

export default {
  cancelUpload,
  beginUpload,
  updateUpload,
  completeUpload,
};
