import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  WhiteCard,
  Button,
  List,
  InlineModal,
  EmptyDataSet,
  MoneyWithCurrency,
  Icon,
} from 'views/components/Shared/General';
import { NewSparePartModal } from 'views/components/SparePart';
import { CreateDeliveryModal } from 'views/components/PurchaseOrder';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import PurchaseOrderRowListItem from './PurchaseOrderRowListItem';
import UpdateSparePartVendorModal from './UpdateSparePartVendorModal';
import SelectSparePartsModal from './SelectSparePartsModal';
import EditPurchaseOrderRowModal from './EditPurchaseOrderRowModal';
import SelectMultipleSparePartsModal from './SelectMultipleSparePartsModal';

class PurchaseOrderRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddPurchaseOrderRowInlineModal: false,
      showAddNewSparePartModal: false,
      showAddNewSparePartModalforPurchaseOrderRow: {},
      showUpdateSparePartVendorModal: false,
      showUpdateSparePartVendorModalForSparePartVendorId: null,
      showUpdateSparePartVendorModalForPurchaseOrderRowId: null,
      showSelectSparePartsModal: false,
      showCreateDeliveryModal: false,
      showEditPurchaseOrderRowModal: false,
      showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
      paramsForNewPurchaseOrderForEditPurchaseOrderModal: {},
      preserveSelectSparePartsModalState: false,
      showSelectMultipleSparePartsModal: false,
      filterParamsForSelectMultipleSparePartsModal: {},
    };
  }

  renderNewSparePartModal = () => {
    const { spare_part_unit_id, number, title, price, id } =
      this.state.showAddNewSparePartModalforPurchaseOrderRow;
    return (
      <NewSparePartModal
        title={<FormattedMessage id="screens.purchase-order.info.articles.new-spare-part-modal-title" />}
        subtitle={
          <FormattedMessage
            id="screens.purchase-order.info.articles.new-spare-part-modal-subtitle"
            values={{ vendor: this.props.vendor == null ? '' : this.props.vendor.name }}
          />
        }
        disableCreateMultiple
        disableAddVendor
        open={this.state.showAddNewSparePartModal}
        defaultParams={{
          spare_part_unit_id,
          number,
          title,
          purchase_price: price,
          purchase_price_currency: this.props.purchaseOrder.currency,
          purchase_price_exchange_rate: this.props.purchaseOrder.currency_exchange_rate,
          purchase_order_row_id: id,
        }}
        onClose={() => {
          if (this.state.showEditPurchaseOrderRowModalAfterCreatingSparePart) {
            this.setState({
              showAddNewSparePartModal: false,
              showAddNewSparePartModalforPurchaseOrderRow: {},
              showEditPurchaseOrderRowModal: true,
              showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
              showEditPurchaseOrderRowModalAfterCreatingSparePart: false,
            });
          } else {
            this.setState({
              showAddNewSparePartModal: false,
              showAddNewSparePartModalforPurchaseOrderRow: {},
            });
          }
        }}
        onCreated={sparePart => {
          if (this.state.showEditPurchaseOrderRowModalAfterCreatingSparePart) {
            this.setState({
              showAddNewSparePartModal: false,
              showAddNewSparePartModalforPurchaseOrderRow: {},
              showEditPurchaseOrderRowModal: true,
              showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
              showEditPurchaseOrderRowModalAfterCreatingSparePart: false,
            });
          } else {
            this.setState({
              showAddNewSparePartModal: false,
              showAddNewSparePartModalforPurchaseOrderRow: {},
            });
          }
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="screens.purchase-order.info.articles.create-spare-part-from-article-success" />
              }
            />
          );
        }}
      />
    );
  };

  renderUpdateSparePartVendorModal = () => (
    <UpdateSparePartVendorModal
      purchaseOrder={this.props.purchaseOrder}
      open={this.state.showUpdateSparePartVendorModal}
      purchaseOrderRowId={this.state.showUpdateSparePartVendorModalForPurchaseOrderRowId}
      sparePartVendorId={this.state.showUpdateSparePartVendorModalForSparePartVendorId}
      onClose={() => {
        this.setState({
          showUpdateSparePartVendorModal: false,
          showUpdateSparePartVendorModalForPurchaseOrderRowId: null,
          showUpdateSparePartVendorModalForSparePartVendorId: null,
        });
      }}
    />
  );

  renderSelectSparePartsModal = () => (
    <SelectSparePartsModal
      purchaseOrder={this.props.purchaseOrder}
      purchaseOrderRows={this.props.purchaseOrderRows}
      preserveState={this.state.preserveSelectSparePartsModalState}
      open={this.state.showSelectSparePartsModal}
      onClose={() => {
        this.setState({
          showSelectSparePartsModal: false,
          preserveSelectSparePartsModalState: false,
        });
      }}
      onAdd={purchaseOrderRowParams => {
        this.setState({
          showSelectSparePartsModal: false,
          openedEditPurchaseOrderRowModalFromSelectSparePartsModal: true,
          showEditPurchaseOrderRowModal: true,
          paramsForNewPurchaseOrderForEditPurchaseOrderModal: purchaseOrderRowParams,
          showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
        });
      }}
      onAddAllShouldOrder={filterParams => {
        this.setState({
          showSelectSparePartsModal: false,
          showSelectMultipleSparePartsModal: true,
          filterParamsForSelectMultipleSparePartsModal: filterParams,
        });
      }}
    />
  );

  renderSelectMultipleSparePartsModal = () => (
    <SelectMultipleSparePartsModal
      purchaseOrderId={this.props.purchaseOrder.id}
      vendorId={this.props.purchaseOrder.vendor_id}
      filterParams={this.state.filterParamsForSelectMultipleSparePartsModal}
      purchaseOrderRows={this.props.purchaseOrderRows}
      open={this.state.showSelectMultipleSparePartsModal}
      onClose={() => {
        this.setState({
          showSelectMultipleSparePartsModal: false,
        });
      }}
      onAdd={purchaseOrderRows => {
        this.setState({
          showSelectMultipleSparePartsModal: false,
        });
      }}
    />
  );

  renderCreateDeliveryModal = () => (
    <CreateDeliveryModal
      open={this.state.showCreateDeliveryModal}
      purchaseOrder={this.props.purchaseOrder}
      onSave={() => {
        this.setState({ showCreateDeliveryModal: false });
      }}
      onClose={() => {
        this.setState({ showCreateDeliveryModal: false });
      }}
    />
  );

  renderEditPurchaseOrderRowModal = () => (
    <EditPurchaseOrderRowModal
      open={this.state.showEditPurchaseOrderRowModal}
      purchaseOrder={this.props.purchaseOrder}
      purchaseOrderRow={this.state.showEditPurchaseOrderRowModalForPurchaseOrderRow}
      defaultParamsForNewPurchaseOrderRow={this.state.paramsForNewPurchaseOrderForEditPurchaseOrderModal}
      onClose={() => {
        this.setState({
          showEditPurchaseOrderRowModal: false,
          paramsForNewPurchaseOrderForEditPurchaseOrderModal: {},
        });
        if (this.state.openedEditPurchaseOrderRowModalFromSelectSparePartsModal) {
          this.setState({
            showSelectSparePartsModal: true,
            openedEditPurchaseOrderRowModalFromSelectSparePartsModal: false,
            preserveSelectSparePartsModalState: true,
          });
        }
      }}
      onCreate={purchaseOrderRow => {
        this.setState({
          showEditPurchaseOrderRowModal: false,
          paramsForNewPurchaseOrderForEditPurchaseOrderModal: {},
        });
        if (this.state.openedEditPurchaseOrderRowModalFromSelectSparePartsModal) {
          this.setState({
            showSelectSparePartsModal: true,
            openedEditPurchaseOrderRowModalFromSelectSparePartsModal: false,
            preserveSelectSparePartsModalState: true,
          });
        }
      }}
      onCreateAndCreateNew={purchaseOrderRow => {
        this.setState(
          {
            showEditPurchaseOrderRowModal: false,
            paramsForNewPurchaseOrderForEditPurchaseOrderModal: {},
          },
          () => {
            setTimeout(() => {
              this.setState({
                showEditPurchaseOrderRowModal: true,
                showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
              });
            }, 250);
          }
        );
      }}
      onCreateWithNewSparePart={purchaseOrderRow => {
        this.setState(
          {
            showEditPurchaseOrderRowModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showAddNewSparePartModal: true,
                showAddNewSparePartModalforPurchaseOrderRow: purchaseOrderRow,
              });
            }, 250);
          }
        );
      }}
      onCreateAndCreateNewWithSparePart={purchaseOrderRow => {
        this.setState(
          {
            showEditPurchaseOrderRowModalAfterCreatingSparePart: true,
            showEditPurchaseOrderRowModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showAddNewSparePartModal: true,
                showAddNewSparePartModalforPurchaseOrderRow: purchaseOrderRow,
              });
            }, 250);
          }
        );
      }}
      onEditPurchaseOrderRowWithSparePartVendor={({ purchaseOrderRowId, sparePartVendorId }) => {
        this.setState(
          {
            showEditPurchaseOrderRowModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showUpdateSparePartVendorModal: true,
                showUpdateSparePartVendorModalForSparePartVendorId: sparePartVendorId,
                showUpdateSparePartVendorModalForPurchaseOrderRowId: purchaseOrderRowId,
              });
            }, 250);
          }
        );
      }}
    />
  );

  isLoaded = () => this.props.purchaseOrder != null;

  renderEmptyDataset = () => (
    <div className={styles['empty-data-set']}>
      <EmptyDataSet
        title={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.subtitle" />}
        image={SparePartSmall}
        tiny
        horizontal
        listContainer
      />
    </div>
  );

  getPurchaseOrderSummary = () => {
    return this.props.purchaseOrderRows.reduce((acc, purchaseOrderRow) => {
      const quantity = new Decimal(purchaseOrderRow.quantity || 0);
      const price = new Decimal(purchaseOrderRow.price || 0);
      let rowSummary = quantity.times(price);
      return acc.plus(rowSummary);
    }, new Decimal(0));
  };

  renderPurchaseOrderRows = () => (
    <React.Fragment>
      {this.props.purchaseOrderRows.length === 0 ? null : (
        <List.Header
          expandable
          background
          style={{ borderLeft: 'none', borderRight: 'none', borderRadius: 0 }}
          paddingRight={115}
        >
          <List.Header.Column width={100}>
            <FormattedMessage id="resources.purchase-order-row.number" />
          </List.Header.Column>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-row.title" />
          </List.Header.Column>
          <List.Header.Column alignRight width={100}>
            <FormattedMessage id="resources.purchase-order-row.quantity" />
          </List.Header.Column>
          <List.Header.Column alignRight width={120}>
            <FormattedMessage id="resources.purchase-order-row.price" />
          </List.Header.Column>
          <List.Header.Column alignRight width={120}>
            <FormattedMessage id="resources.purchase-order-row.total-cost" />
          </List.Header.Column>
        </List.Header>
      )}
      <List light usedInWhiteCard>
        {this.props.purchaseOrderRows.map(purchaseOrderRow => (
          <PurchaseOrderRowListItem
            key={purchaseOrderRow.id}
            purchaseOrder={this.props.purchaseOrder}
            purchaseOrderRow={purchaseOrderRow}
            onCreateSparePart={purchaseOrderRow => {
              this.setState({
                showAddNewSparePartModal: true,
                showAddNewSparePartModalforPurchaseOrderRow: purchaseOrderRow,
              });
            }}
            onEdit={() => {
              this.setState({
                showEditPurchaseOrderRowModal: true,
                showEditPurchaseOrderRowModalForPurchaseOrderRow: purchaseOrderRow,
              });
            }}
          />
        ))}
        <List.Item>
          <List.Item.Column flex alignRight>
            <p className={styles['total']}>
              <FormattedMessage
                id="screens.purchase-order.info.articles.total"
                values={{
                  value: (
                    <span className={styles['value']}>
                      <MoneyWithCurrency
                        value={this.getPurchaseOrderSummary()}
                        currency={this.props.purchaseOrder.currency}
                      />
                    </span>
                  ),
                }}
              />
            </p>
          </List.Item.Column>
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderCreateDeliveryButton = () => {
    if (this.props.purchaseOrder.status !== PurchaseOrderStatus.Draft && this.props.canCreateWithdrawals) {
      return (
        <Button
          small
          label="screens.purchase-order.info.articles.register-delivery"
          onClick={() => this.setState({ showCreateDeliveryModal: true })}
        />
      );
    }
    return null;
  };

  renderButtons = () => (
    <Button.Group>
      {this.renderCreateDeliveryButton()}
      {this.props.canEditPurchaseOrders ? (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showAddPurchaseOrderRowInlineModal: !prevState.showAddPurchaseOrderRowInlineModal,
              }));
            }}
          >
            <Button
              small
              primary
              translate={false}
              label={
                <div className={styles['add-article-button-label']}>
                  <FormattedMessage id="screens.purchase-order.info.articles.add-article-button" />
                  <Icon regular type="angle-down" />
                </div>
              }
            />
          </div>
          <InlineModal
            open={this.state.showAddPurchaseOrderRowInlineModal}
            positionToRef={this.inlineModalPositioningRef}
            onClose={() => this.setState({ showAddPurchaseOrderRowInlineModal: false })}
            position="right"
          >
            <InlineModal.Body dropdown width={250}>
              <InlineModal.ListItem
                icon="cogs"
                iconThickness="regular"
                title={
                  <FormattedMessage id="screens.purchase-order.info.articles.add-article-dropdown.choose-spare-part" />
                }
                onClick={() => {
                  this.setState({
                    showSelectSparePartsModal: true,
                    showAddPurchaseOrderRowInlineModal: false,
                  });
                }}
              />
              <InlineModal.ListItem
                icon="plus"
                iconThickness="regular"
                title={
                  <FormattedMessage id="screens.purchase-order.info.articles.add-article-dropdown.empty-row" />
                }
                onClick={() => {
                  this.setState({
                    showAddPurchaseOrderRowInlineModal: false,
                    showEditPurchaseOrderRowModal: true,
                    showEditPurchaseOrderRowModalForPurchaseOrderRow: null,
                  });
                }}
              />
            </InlineModal.Body>
          </InlineModal>
        </>
      ) : null}
    </Button.Group>
  );

  renderContent = () => {
    if (this.props.purchaseOrderRows == null)
      return (
        <List light usedInWhiteCard>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    if (this.props.purchaseOrderRows.length === 0) {
      return this.renderEmptyDataset();
    }
    return this.renderPurchaseOrderRows();
  };

  render() {
    return (
      <>
        <WhiteCard noPadding>
          <div className={styles['header']}>
            <div className={styles['title-container']}>
              <p className={styles['title']}>
                <FormattedMessage id="screens.purchase-order.info.articles.title" />
              </p>
            </div>
            {this.renderButtons()}
          </div>
          {this.renderContent()}
        </WhiteCard>
        {this.renderNewSparePartModal()}
        {this.renderUpdateSparePartVendorModal()}
        {this.renderSelectSparePartsModal()}
        {this.renderCreateDeliveryModal()}
        {this.renderEditPurchaseOrderRowModal()}
        {this.renderSelectMultipleSparePartsModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderId);
  return {
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
    purchaseOrderRows: EntitySelectors.getPurchaseOrderRows(state, purchaseOrder.purchase_order_rows),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(PurchaseOrderRows)));
