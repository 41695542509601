import types from './types';

const selectDropdownType = payload => ({
  type: types.SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN,
  payload: payload,
});

const selectAssetId = payload => ({
  type: types.SELECT_ASSET_ID_FOR_ASSET_DROPDOWN,
  payload: payload,
});

const fetchBookmarkedAssetsSuccess = payload => ({
  type: types.FETCH_BOOKMARKED_ASSETS_SUCCESS,
  payload: payload,
});

export default {
  fetchBookmarkedAssetsSuccess,
  selectDropdownType,
  selectAssetId,
};
