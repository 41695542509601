import React, { Component } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import Request_SWE from 'assets/images/RequestAssignee (SV).png';
import Request_ENG from 'assets/images/RequestAssignee (EN).png';
import styles from './style.module.scss';

class Receivers extends Component {
  render() {
    return (
      <div className={styles['wrapper']}>
        <PerfectScrollbar>
          <div className={styles['container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.settings.requests.assignees.upgrade-to-pro.title" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="screens.settings.requests.assignees.upgrade-to-pro.subtitle" />
            </div>
            <div className={styles['upgrade-screenshot-container']}>
              <img
                className={styles['upgrade-screenshot']}
                alt="screenshot"
                src={this.props.language === 'sv' ? Request_SWE : Request_ENG}
              />
            </div>
            <div className={styles['upgrade-buttons-container']}>
              <Button upgradeToPro label="general.upgrade-to-pro" />
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Receivers));
