import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations, API } from 'sdk';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { normalizeWorkOrder, normalizeRecurringMaintenance } from 'sdk/Schemas';
import { List, Icon, InlineModal, Button, Tooltip } from 'views/components/Shared/General';
import { WorkOrderSelectors } from 'state/ducks/workOrder';
import {
  ChecklistEditModal,
  PreviewChecklistTemplateModal,
  CantEditReusableChecklistModal,
} from 'views/components/Checklist';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';

class ChecklistTemplateListItem extends Component {
  state = {
    showMoreOptionsInlineModal: false,
    showDeleteContent: false,
    isDeleting: false,
    showCantEditReusableChecklistModal: false,
    showCantEditReusableChecklistModalForChecklistId: null,
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
    showPreviewChecklistModal: false,
    showPreviewChecklistModalForTemplateId: null,
    isRevertingChangesForChecklistTemplateVersion: false,
    isEditingDraftChecklistTemplateVersion: false,
  };

  closeChecklistEditModal = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId && this.state.isEditingDraftChecklistTemplateVersion === false) {
      this.deleteChecklistTemplateVersion();
    } else {
      this.setState({ showChecklistEditModal: false });
    }
  };

  deleteChecklist = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.removeTemplateFromRecurringMaintenance();
    } else {
      if (this.props.checklistTemplate.reusable === false) {
        this.deleteChecklistTemplate();
      } else {
        if (this.props.instanceId) {
          this.deleteChecklistInstance();
        } else {
          this.removeTemplateFromWorkOrder();
        }
      }
    }
  };

  deleteChecklistInstance = () => {
    this.setState({ isDeleting: true });
    this.props.deleteChecklistInstance(this.props.checklistInstance.id).then(() => {
      API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
        const { entities } = normalizeWorkOrder(workOrder);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.edit.checklist.remove-checklist-success" />}
          />
        );
      });
    });
  };

  deleteChecklistTemplate = () => {
    this.setState({ isDeleting: true });
    this.props.deleteChecklistTemplate(this.props.checklistTemplate.id).then(() => {
      API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
        const { entities } = normalizeWorkOrder(workOrder);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.edit.checklist.delete-checklist-success" />}
          />
        );
      });
    });
  };

  removeTemplateFromRecurringMaintenance = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    this.setState({ isDeleting: true });
    this.props.deleteChecklistTemplateLink(this.props.templateLinkId).then(() => {
      API.fetchRecurringMaintenance(recurringMaintenanceId).then(({ data: recurringMaintenance }) => {
        const { entities } = normalizeRecurringMaintenance(recurringMaintenance);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.edit.checklist.remove-checklist-success" />}
          />
        );
      });
    });
  };

  removeTemplateFromWorkOrder = () => {
    this.setState({ isDeleting: true });
    this.props.deleteChecklistTemplateLink(this.props.templateLinkId).then(() => {
      API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
        const { entities } = normalizeWorkOrder(workOrder);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.edit.checklist.remove-checklist-success" />}
          />
        );
      });
    });
  };

  saveChecklistEditModal = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.activateChecklistTemplateVersion();
    } else {
      this.setState({ showChecklistEditModal: false });
    }
  };

  createNewChecklistTemplateVersion = checklistTemplate => {
    const { id, title } = checklistTemplate;
    this.setState({
      showChecklistEditModal: true,
      showChecklistEditModalForVersionId: null,
      isEditingDraftChecklistTemplateVersion: false,
    });
    this.props
      .createChecklistTemplateVersionForChecklistTemplate(id, {
        title,
      })
      .then(({ data: checklistTemplateVersion }) => {
        this.setState({
          showChecklistEditModalForVersionId: checklistTemplateVersion.id,
        });
      });
  };

  activateChecklistTemplateVersion = () => {
    this.setState({ isActivatingVersion: true });
    this.props
      .updateChecklistTemplateVersion(this.state.showChecklistEditModalForVersionId, {
        status: Status.Active,
      })
      .then(({ data: checklistTemplateVersion }) => {
        this.setState({ showChecklistEditModal: false, isActivatingVersion: false });
      });
  };

  deleteChecklistTemplateVersion = () => {
    this.setState({ isRevertingChangesForChecklistTemplateVersion: true });
    this.props.deleteChecklistTemplateVersion(this.props.checklistTemplate.draft_version).then(() => {
      this.setState({ showChecklistEditModal: false, isRevertingChangesForChecklistTemplateVersion: false });
    });
  };

  previewChecklistTemplate = () => {
    this.setState({
      showPreviewChecklistModal: true,
      showPreviewChecklistModalForTemplateId: this.props.checklistTemplate.id,
    });
  };

  editChecklistTemplate = () => {
    if (this.props.checklistTemplate.reusable) {
      this.setState({
        showCantEditReusableChecklistModal: true,
        showCantEditReusableChecklistModalForChecklistId: this.props.checklistTemplate.id,
      });
      return;
    }
    if (this.props.instanceId) {
      this.setState({
        isEditingDraftChecklistTemplateVersion: false,
        showChecklistEditModal: true,
        showChecklistEditModalForVersionId: this.props.checklistTemplateVersion.id,
      });
    } else {
      if (this.props.checklistTemplate.draft_version) {
        this.setState({
          showChecklistEditModal: true,
          showChecklistEditModalForVersionId: this.props.checklistTemplate.draft_version,
          isEditingDraftChecklistTemplateVersion: true,
        });
      } else {
        this.createNewChecklistTemplateVersion(this.props.checklistTemplate);
      }
    }
  };

  /*
  ---------
    Render functions
  ---------
  */

  renderOptions = () => {
    if (this.props.checklistTemplate.reusable) {
      return (
        <InlineModal.Body dropdown width={250}>
          <InlineModal.ListItem
            destructive
            title={
              <FormattedMessage id="screens.work-order.edit.checklist.remove-checklist-from-work-order" />
            }
            onClick={() => {
              this.setState({ showDeleteContent: true });
            }}
          />
        </InlineModal.Body>
      );
    }
    return (
      <InlineModal.Body dropdown width={250}>
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          iconColor="#C55050"
          destructive
          title={<FormattedMessage id="general.delete" />}
          onClick={() => {
            this.setState({ showDeleteContent: true });
          }}
        />
      </InlineModal.Body>
    );
  };

  renderDeleteContent = () => {
    if (this.props.checklistTemplate.reusable) {
      return (
        <>
          <InlineModal.Header
            width={300}
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showMoreOptionsInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>
            <InlineModal.Delete
              loading={this.state.isDeleting}
              onDelete={this.deleteChecklist}
              label="general.remove"
            >
              <FormattedMessage id="screens.work-order.edit.checklist.remove-checklist-from-work-order" />
            </InlineModal.Delete>
          </InlineModal.Body>
        </>
      );
    }
    return (
      <>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="general.delete-confirm.title" />}
          onClose={() => {
            this.setState({ showMoreOptionsInlineModal: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.deleteChecklist}>
            <FormattedMessage id="screens.work-order.edit.checklist.delete-confirm-text" />
          </InlineModal.Delete>
        </InlineModal.Body>
      </>
    );
  };

  renderInlineModalBodyContent = () => {
    if (this.state.showDeleteContent) {
      return this.renderDeleteContent();
    }
    return this.renderOptions();
  };

  renderDropdownInlineModal = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (this.props.recurringMaintenance && recurringMaintenanceId == null) {
      return <Button type="icon" icon={<Icon type="eye" />} onClick={this.previewChecklistTemplate} />;
    }
    return (
      <>
        <Button.Group>
          <Tooltip
            label={<FormattedMessage id="general.preview" />}
            trigger={
              <Button type="icon" icon={<Icon type="eye" />} onClick={this.previewChecklistTemplate} />
            }
          />

          <Tooltip
            label={<FormattedMessage id="general.edit" />}
            trigger={
              <Button type="icon" icon={<Icon regular type="pen" />} onClick={this.editChecklistTemplate} />
            }
          />

          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showMoreOptionsInlineModal: !prevState.showMoreOptionsInlineModal,
                showDeleteContent: false,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
          </div>
        </Button.Group>
        <InlineModal
          open={this.state.showMoreOptionsInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showMoreOptionsInlineModal: false })}
          position="right"
        >
          {this.renderInlineModalBodyContent()}
        </InlineModal>
      </>
    );
  };

  renderSubtitle = () => {
    if (this.props.checklistTemplate.reusable) {
      return <FormattedMessage id="screens.work-order.edit.checklist.fetched-from-library" />;
    }
    return null;
  };

  renderChecklistItemTitle = () => {
    if (this.props.checklistTemplateVersion) {
      return this.props.checklistTemplateVersion.title;
    }
    return this.props.checklistTemplate.title;
  };

  render() {
    return (
      <>
        <List.Item iconButtons={this.renderDropdownInlineModal()}>
          <List.Item.Column>
            <Icon type="list" withBackground backgroundSize={34} />
          </List.Item.Column>
          <List.Item.Column>
            <List.Item.TitleColumn title={this.renderChecklistItemTitle()} subtitle={this.renderSubtitle()} />
          </List.Item.Column>
        </List.Item>
        <CantEditReusableChecklistModal
          open={this.state.showCantEditReusableChecklistModal}
          id={this.state.showCantEditReusableChecklistModalForChecklistId}
          onClose={() => {
            this.setState({ showCantEditReusableChecklistModal: false });
          }}
        />
        <ChecklistEditModal
          assetId={this.props.editingWorkOrder.asset_id}
          workOrderId={this.props.match.params.id}
          recurringMaintenanceId={queryString.parse(this.props.location.search).recurring_maintenance_id}
          open={this.state.showChecklistEditModal}
          id={this.state.showChecklistEditModalForVersionId}
          checklistTemplateLinkId={this.props.templateLinkId}
          showAbortChangesButton={this.state.isEditingDraftChecklistTemplateVersion}
          isSaving={this.state.isActivatingVersion}
          isRevertingChanges={this.state.isRevertingChangesForChecklistTemplateVersion}
          onSave={this.saveChecklistEditModal}
          onRevertChanges={this.deleteChecklistTemplateVersion}
          onAddToLibrarySuccess={() => {
            this.setState({
              showChecklistEditModal: false,
            });
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.work-order.edit.checklist.save-checklist-to-library-success" />
                }
              />
            );
          }}
          onClose={this.closeChecklistEditModal}
        />
        <PreviewChecklistTemplateModal
          open={this.state.showPreviewChecklistModal}
          id={this.state.showPreviewChecklistModalForTemplateId}
          onClose={() => this.setState({ showPreviewChecklistModal: false })}
          hideFooter
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addChecklistTemplateToWorkOrder: SDKReduxOperations.addChecklistTemplateToWorkOrder,
      addChecklistTemplateToRecurringMaintenance:
        SDKReduxOperations.addChecklistTemplateToRecurringMaintenance,
      updateEntities: EntityOperations.updateEntities,
      deleteChecklistTemplateLink: SDKReduxOperations.deleteChecklistTemplateLink,
      deleteChecklistTemplate: SDKReduxOperations.deleteChecklistTemplate,
      deleteChecklistInstance: SDKReduxOperations.deleteChecklistInstance,
      createChecklistTemplateVersionForChecklistTemplate:
        SDKReduxOperations.createChecklistTemplateVersionForChecklistTemplate,
      updateChecklistTemplateVersion: SDKReduxOperations.updateChecklistTemplateVersion,
      deleteChecklistTemplateVersion: SDKReduxOperations.deleteChecklistTemplateVersion,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.instanceId) {
    const checklistInstance = EntitySelectors.getChecklistInstance(state, ownProps.instanceId);
    const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      checklistInstance,
      checklistTemplateVersion,
      checklistTemplate: EntitySelectors.getChecklistTemplate(
        state,
        checklistTemplateVersion.checklist_template_id
      ),
      recurringMaintenance: WorkOrderSelectors.getEditingWorkOrderRecurringMaintenance(state),
    };
  } else if (ownProps.templateLinkId) {
    const checklistTemplateLink = EntitySelectors.getChecklistTemplateLink(state, ownProps.templateLinkId);
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      checklistTemplateLink,
      checklistTemplate: EntitySelectors.getChecklistTemplate(
        state,
        checklistTemplateLink.checklist_template_id
      ),
      recurringMaintenance: WorkOrderSelectors.getEditingWorkOrderRecurringMaintenance(state),
    };
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistTemplateListItem));
