import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Decimal from 'decimal.js';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { Button, Field, FormattedNumber } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { getEstimatedDueDateFromMeterAsMoment } from 'sdk/WorkOrder';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import styles from './style.module.scss';

class EditMeterDueDateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      due_meter_value: null,
      due_meter_tolerance: null,
      date: null,
      showSetExactDueDate: false,
      removeExactDueDateFromDom: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { due_meter_tolerance, due_meter_value, date } = this.props.editingWorkOrder;
    if (!prevProps.open && this.props.open) {
      this.setState({
        due_meter_value,
        due_meter_tolerance,
        date,
        showSetExactDueDate: date != null,
        removeExactDueDateFromDom: date == null,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  /*
    Helper functions
  */

  save = () => {
    const { due_meter_value, due_meter_tolerance, date, showSetExactDueDate } = this.state;
    this.props.onSave({
      due_meter_value,
      due_meter_tolerance: due_meter_tolerance || 0,
      date: showSetExactDueDate === false ? null : date,
    });
  };

  /*
    Render functions
  */

  renderDueMeterValueIncludingTolerance = () => {
    const { due_meter_tolerance, due_meter_value } = this.state;
    if (!due_meter_value) {
      return (
        <div className={styles['extra-meter-value-row']}>
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            <span>-</span>
          </Field>
        </div>
      );
    }
    let decimalMeterTolerance = null;
    let decimalMeterValue = null;
    try {
      decimalMeterTolerance = new Decimal(due_meter_tolerance);
    } catch (e) {
      decimalMeterTolerance = new Decimal(0);
    }
    try {
      decimalMeterValue = new Decimal(due_meter_value);
    } catch (e) {
      decimalMeterValue = new Decimal(0);
    }
    if (decimalMeterTolerance.greaterThan(new Decimal(0))) {
      return (
        <div className={styles['extra-meter-value-row']}>
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            <FormattedNumber
              value={decimalMeterValue
                .toDecimalPlaces(2)
                .minus(decimalMeterTolerance.toDecimalPlaces(2))
                .toString()}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
            <span> - </span>
            <FormattedNumber
              value={decimalMeterValue
                .toDecimalPlaces(2)
                .plus(decimalMeterTolerance.toDecimalPlaces(2))
                .toString()}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
        </div>
      );
    }
    return (
      <div className={styles['extra-meter-value-row']}>
        <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
          <FormattedNumber
            value={decimalMeterValue.toDecimalPlaces(2).toString()}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </Field>
      </div>
    );
  };

  renderEstimatedDueDateFieldExtraValue = () => {
    const estimatedDate = getEstimatedDueDateFromMeterAsMoment({
      meter_value: this.props.meter.value,
      due_meter_value: this.state.due_meter_value,
      average_daily_usage: this.props.meter.average_daily_usage,
      value_last_updated: this.props.meter.value_last_updated,
    });
    return (
      <div className={styles['extra-meter-value-row']}>
        <Field
          singleRow
          view
          fontSize={12}
          label={<FormattedMessage id="resources.work-order.estimated-meter-due-date" />}
        >
          {!this.state.due_meter_value || estimatedDate == null ? '-' : estimatedDate.format('LL')}
        </Field>
      </div>
    );
  };

  renderExtraMeterValues = () => {
    return (
      <>
        {this.renderDueMeterValueIncludingTolerance()}
        <div className={styles['extra-meter-value-row']}>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="screens.work-order.meter-current-value" />}
          >
            <FormattedNumber
              value={this.props.meter.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
        </div>
        <div className={styles['extra-meter-value-row']}>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="screens.work-order.meter-average-increase" />}
          >
            {this.props.meter.average_daily_usage ? (
              <FormattedMessage
                id="screens.work-order.meter-average-increase-value"
                values={{
                  value: (
                    <FormattedNumber
                      value={this.props.meter.average_daily_usage}
                      unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                    />
                  ),
                }}
              />
            ) : (
              <span>-</span>
            )}
          </Field>
        </div>
        {this.renderEstimatedDueDateFieldExtraValue()}
      </>
    );
  };

  renderSetExactDueDate = () => {
    if (this.props.hideExactDate) {
      return null;
    }
    return (
      <>
        <div style={{ marginBottom: 0 }}>
          <Grid.Row>
            <Grid.Column>
              <Field.Checkbox
                checked={this.state.showSetExactDueDate}
                label={<FormattedMessage id="screens.work-order.meter-set-due-date" />}
                onChange={() => {
                  this.setState(
                    prevState => ({
                      removeExactDueDateFromDom: false,
                    }),
                    () => {
                      this.setState(prevState => ({
                        showSetExactDueDate: !prevState.showSetExactDueDate,
                      }));
                    }
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </div>
        {this.state.removeExactDueDateFromDom ? null : (
          <AnimateHeight
            contentClassName={styles['animate-content-container']}
            duration={250}
            height={this.state.showSetExactDueDate ? 'auto' : 0}
            onAnimationEnd={() => {
              if (this.state.showSetExactDueDate === false) {
                this.setState({ removeExactDueDateFromDom: true });
              }
            }}
          >
            <Grid.Row>
              <Grid.Column>
                <Field.Date
                  value={this.state.date}
                  onChangeDate={date => {
                    this.setState({
                      date,
                    });
                  }}
                  onClear={() => {
                    this.setState({
                      date: null,
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </AnimateHeight>
        )}
      </>
    );
  };

  renderContent = () => {
    if (this.props.meter == null) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ConnectedFieldErrorWrapper field="date">
              {hasError => (
                <Field
                  label={<FormattedMessage id="resources.work-order.due-date" />}
                  description={
                    <Field
                      singleRow
                      view
                      fontSize={12}
                      label={<FormattedMessage id="screens.work-order.meter-current-value" />}
                    >
                      <FormattedNumber
                        value={this.props.meter.value}
                        unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                      />
                    </Field>
                  }
                >
                  <Field.Decimal
                    autoFocus
                    error={hasError}
                    rightLabel={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                    value={this.state.due_meter_value}
                    onChange={due_meter_value => {
                      if (this.props.fieldErrors.date) {
                        this.props.removeFieldsFromErrors(['date']);
                      }
                      this.setState({ due_meter_value });
                    }}
                    onBlur={due_meter_value => this.setState({ due_meter_value })}
                  />
                </Field>
              )}
            </ConnectedFieldErrorWrapper>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={<FormattedMessage id="screens.work-order.meter-tolerance" />}
              description={<FormattedMessage id="screens.work-order.meter-tolerance-description" />}
            >
              <Field.Decimal
                rightLabel={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                value={this.state.due_meter_tolerance}
                onChange={due_meter_tolerance => this.setState({ due_meter_tolerance })}
                onBlur={due_meter_tolerance => this.setState({ due_meter_tolerance })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderSetExactDueDate()}
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>{this.renderExtraMeterValues()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.work-order.edit.due-date-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { editingWorkOrder } = ownProps;
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    editingWorkOrder,
    meter,
    meterUnit,
    fieldErrors: ErrorSelectors.getFieldErrors(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMeterDueDateModal);

EditMeterDueDateModal.propTypes = {
  open: PropTypes.bool,
  hideExactDate: PropTypes.bool,
  editingWorkOrder: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

EditMeterDueDateModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  open: false,
  editingWorkOrder: null,
  hideExactDate: false,
};
