import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeMeter } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import moment from 'moment';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { List, FormattedNumber, Icon, ConfirmModal } from 'views/components/Shared/General';
import { ChangeMeterValueModal } from 'views/components/Meter';
import styles from './style.module.scss';

class MeterReadingListItem extends Component {
  state = {
    isLoading: false,
    showChangeMeterValueModal: false,
    isDeletingReading: false,
    showConfirmDeleteModal: false,
  };

  deleteReading = () => {
    this.props
      .deleteMeterReading(this.props.meterReading.id)
      .then(() => {
        API.fetchMeter(this.props.meterReading.meter_id).then(({ data: meter }) => {
          const { entities } = normalizeMeter(meter);
          this.props.updateEntities(entities);
          this.setState({ isDeletingReading: false });
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.delete-success" />}
            />
          );
        });
      })
      .catch(e => {
        this.setState({ isDeletingReading: false });
      });
  };

  renderDeleteConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.title" />}
        message={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingReading}
        onConfirm={() => {
          this.setState({ isDeletingReading: true });
          this.deleteReading();
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          setTimeout(() => {
            this.setState({ showChangeMeterValueModal: true });
          }, 100);
        }}
      />
    );
  };

  renderChangeValueModal = () => {
    return (
      <ChangeMeterValueModal
        meterId={this.props.meter.id}
        updateForMeterReadingId={this.props.id}
        open={this.state.showChangeMeterValueModal}
        confirmDelete={() => {
          this.setState({ showChangeMeterValueModal: false });
          setTimeout(() => {
            this.setState({ showConfirmDeleteModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showChangeMeterValueModal: false })}
        onUpdateMeterReading={() => {
          API.fetchMeter(this.props.meter.id).then(({ data: meter }) => {
            const { entities } = normalizeMeter(meter);
            this.props.updateEntities(entities);
            this.setState({ showChangeMeterValueModal: false });
          });
        }}
      />
    );
  };

  isLoaded = () => {
    if (this.props.meterReading) return true;
    else return false;
  };

  render() {
    if (this.props.loading || !this.isLoaded())
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    return (
      <>
        <List.Item
          small
          clickable={this.props.canEditMeterReadings}
          onClick={() => this.setState({ showChangeMeterValueModal: true })}
        >
          <List.Item.Column flex>
            <span className={styles['date']}>{moment(this.props.meterReading.date).format('LL')}</span>
          </List.Item.Column>
          <List.Item.Column width={150}>
            <div className={styles['user']}>
              {this.props.user ? (
                <>
                  <ProfilePicture
                    userId={this.props.user.id}
                    size={20}
                    fontSize={10}
                    defaultElement={<Icon type="user" withBackground backgroundSize={20} size={10} />}
                  />

                  <p className={styles['username']}>
                    <UserNameWrapper user={this.props.user} />
                  </p>
                </>
              ) : (
                ' - '
              )}
            </div>
          </List.Item.Column>
          <List.Item.Column alignRight width={250}>
            <FormattedNumber
              value={this.props.meterReading.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </List.Item.Column>
        </List.Item>
        {this.renderDeleteConfirmModal()}
        {this.renderChangeValueModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deleteMeterReading: SDKReduxOperations.deleteMeterReading,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const meterReading = EntitySelectors.getMeterReading(state, ownProps.id);
  const meter = EntitySelectors.getMeter(state, meterReading.meter_id);

  return {
    meterReading,
    meter,
    user: EntitySelectors.getUser(state, meterReading.added_by_user_id),
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
    canEditMeterReadings: AuthSelectors.canEditMeterReadings(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeterReadingListItem));
