import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.CHECKLIST_INSTANCE_CREATED_FROM_TEMPLATE: {
      const { data, meta } = action.payload;
      const { id: instanceId, work_order_id } = data;
      const { checklist_template_link_id } = meta;
      if (state && state[work_order_id]) {
        return {
          ...state,
          [work_order_id]: {
            ...state[work_order_id],
            checklist_template_links: state[work_order_id].checklist_template_links.filter(
              id => id !== checklist_template_link_id
            ),
            checklist_instances: [instanceId, ...state[work_order_id].checklist_instances],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_INSTANCE_DELETED: {
      const { checklistInstanceId, data } = action.payload;
      const { work_order_id } = data;
      if (state && state[work_order_id]) {
        return {
          ...state,
          [work_order_id]: {
            ...state[work_order_id],
            checklist_instances: state[work_order_id].checklist_instances.filter(
              id => id !== checklistInstanceId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_LINK_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      const { id } = data;
      if (state && state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            checklist_template_links: [id, ...state[workOrderId].checklist_template_links],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_LINK_DELETED: {
      const { checklistTemplateLinkId, data } = action.payload;
      const { work_order_id } = data;
      if (state && state[work_order_id]) {
        return {
          ...state,
          [work_order_id]: {
            ...state[work_order_id],
            checklist_template_links: state[work_order_id].checklist_template_links.filter(
              id => id !== checklistTemplateLinkId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_DELETED: {
      const { workOrderId } = action.payload;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            _deleted: true,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_IMAGE_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      const { id } = data;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            registration_images: [id, ...state[workOrderId].registration_images],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.COMPLETION_IMAGE_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      const { id } = data;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            completion_images: [id, ...state[workOrderId].completion_images],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { registration_work_order_id, completion_work_order_id } = data;
      if (state[registration_work_order_id]) {
        return {
          ...state,
          [registration_work_order_id]: {
            ...state[registration_work_order_id],
            registration_images: state[registration_work_order_id].registration_images.filter(
              id => id !== imageId
            ),
          },
        };
      }
      if (state[completion_work_order_id]) {
        return {
          ...state,
          [completion_work_order_id]: {
            ...state[completion_work_order_id],
            completion_images: state[completion_work_order_id].completion_images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.RECURRING_MAINTENANCE_DELETED: {
      const { recurringMaintenanceId } = action.payload;

      return Object.entries(state).reduce((acc, [id, workOrder]) => {
        return {
          ...acc,
          [id]: {
            ...workOrder,
            recurring_maintenance_id:
              workOrder.recurring_maintenance_id === recurringMaintenanceId
                ? null
                : workOrder.recurring_maintenance_id,
            recurring_maintenance:
              workOrder.recurring_maintenance === recurringMaintenanceId
                ? null
                : workOrder.recurring_maintenance,
          },
        };
      }, {});
    }
    case SDKReduxTypes.WORK_ORDER_TYPE_DELETED: {
      const { workOrderTypeId } = action.payload;

      return Object.entries(state).reduce((acc, [id, workOrder]) => {
        return {
          ...acc,
          [id]: {
            ...workOrder,
            work_order_type_id:
              workOrder.work_order_type_id === workOrderTypeId ? null : workOrder.work_order_type_id,
            work_order_type: workOrder.work_order_type === workOrderTypeId ? null : workOrder.work_order_type,
          },
        };
      }, {});
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_ADDED_TO_WORK_ORDER: {
      const { attachmentId, workOrderId } = action.payload;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            registration_attachments: [attachmentId, ...state[workOrderId].registration_attachments],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data, params } = action.payload;
      if (params.type == 'link') {
        if (state[workOrderId]) {
          return {
            ...state,
            [workOrderId]: {
              ...state[workOrderId],
              registration_attachments: [data.id, ...state[workOrderId].registration_attachments],
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { workOrderId, completion } = meta;
      if (state[workOrderId]) {
        if (completion) {
          return {
            ...state,
            [workOrderId]: {
              ...state[workOrderId],
              completion_attachments: [data.attachment_id, ...state[workOrderId].completion_attachments],
            },
          };
        }
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            registration_attachments: [data.attachment_id, ...state[workOrderId].registration_attachments],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.COMPLETION_ATTACHMENT_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data, params } = action.payload;
      if (state[workOrderId] && params.type == 'link') {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            completion_attachments: [data.id, ...state[workOrderId].completion_attachments],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId, data } = action.payload;
      const { completion_work_order_id, registration_work_order_id } = data;
      if (state[registration_work_order_id] && state[registration_work_order_id].registration_attachments) {
        return {
          ...state,
          [registration_work_order_id]: {
            ...state[registration_work_order_id],
            registration_attachments: state[registration_work_order_id].registration_attachments.filter(
              id => id !== attachmentId
            ),
          },
        };
      }
      if (state[completion_work_order_id] && state[completion_work_order_id].completion_attachments) {
        return {
          ...state,
          [completion_work_order_id]: {
            ...state[completion_work_order_id],
            completion_attachments: state[completion_work_order_id].completion_attachments.filter(
              id => id !== attachmentId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.REGISTRATION_ATTACHMENT_REMOVED_FROM_WORK_ORDER: {
      const { workOrderId, attachmentId } = action.payload;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            registration_attachments: state[workOrderId].registration_attachments.filter(
              id => id !== attachmentId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_CREATED: {
      const { workOrderId, data } = action.payload;
      const { cost } = data;
      if (state[workOrderId]) {
        if (cost) {
          return {
            ...state,
            [workOrderId]: {
              ...state[workOrderId],
              work_order_spent_times: [data.id, ...state[workOrderId].work_order_spent_times],
              costs: [cost.id, ...state[workOrderId].costs],
            },
          };
        }
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            work_order_spent_times: [data.id, ...state[workOrderId].work_order_spent_times],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_DELETED: {
      const { workOrderSpentTimeId, data } = action.payload;
      const workOrderId = data.work_order_id;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            work_order_spent_times: state[workOrderId].work_order_spent_times.filter(
              id => id !== workOrderSpentTimeId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      const { cost } = data;
      if (state[workOrderId]) {
        if (state[workOrderId].costs) {
          return {
            ...state,
            [workOrderId]: {
              ...state[workOrderId],
              spare_part_withdrawals: [data.id, ...state[workOrderId].spare_part_withdrawals],
              costs: [cost.id, ...state[workOrderId].costs],
            },
          };
        }
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            spare_part_withdrawals: [data.id, ...state[workOrderId].spare_part_withdrawals],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_RESERVATION_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            spare_part_reservations: [data.id, ...state[workOrderId].spare_part_reservations],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_RESERVATION_DELETED: {
      const { data, sparePartReservationId } = action.payload;
      const workOrderId = data.work_order_id;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            spare_part_reservations: state[workOrderId].spare_part_reservations.filter(
              id => id !== sparePartReservationId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_DELETED: {
      const { data, sparePartWithdrawalId } = action.payload;
      const workOrderId = data.work_order_id;
      if (state[workOrderId] && state[workOrderId].spare_part_withdrawals) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            spare_part_withdrawals: state[workOrderId].spare_part_withdrawals.filter(
              id => id !== sparePartWithdrawalId
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_BATCH_DELETED: {
      const { workOrderId, sparePartWithdrawalIds } = action.payload;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            spare_part_withdrawals: state[workOrderId].spare_part_withdrawals.filter(
              id => !sparePartWithdrawalIds.includes(id)
            ),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.COST_CREATED_FOR_WORK_ORDER: {
      const { workOrderId, data } = action.payload;
      const { id } = data;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            costs: [id, ...state[workOrderId].costs],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.COST_DELETED: {
      const { costId, data } = action.payload;
      const workOrderId = data.work_order_id;
      if (state[workOrderId]) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            costs: state[workOrderId].costs.filter(id => id !== costId),
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { data, meta } = action.payload;
      const { work_order_id } = meta;
      if (state[work_order_id]) {
        return {
          ...state,
          [work_order_id]: {
            ...state[work_order_id],
            downtimes: state[work_order_id].downtimes.filter(loopedId => loopedId !== data.id),
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.DOWNTIME_CREATED: {
      const { data, meta } = action.payload;
      const { work_order_id } = meta;
      if (state[work_order_id]) {
        return {
          ...state,
          [work_order_id]: {
            ...state[work_order_id],
            downtimes: [data.id, ...state[work_order_id].downtimes],
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.DOWNTIME_ADDED_TO_WORK_ORDER: {
      const { downtimeId, workOrderId } = action.payload;

      if (state[workOrderId] && !state[workOrderId].downtimes.includes(downtimeId)) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            downtimes: [downtimeId, ...state[workOrderId].downtimes],
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.DOWNTIME_REMOVED_FROM_WORK_ORDER: {
      const { downtimeId, workOrderId } = action.payload;
      if (state[workOrderId] && state[workOrderId].downtimes) {
        return {
          ...state,
          [workOrderId]: {
            ...state[workOrderId],
            downtimes: state[workOrderId].downtimes.filter(loopedId => loopedId !== downtimeId),
          },
        };
      }
      return state;
    }

    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.workOrderById) {
        return HelperFunctions.mergeEntities(state, action.payload.workOrderById);
      }
      return state;
    default:
      return state;
  }
};
