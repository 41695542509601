import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class RemoveIntervalConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      to_date: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { from_date } = this.props.operationalMaintenanceTemplateRecurrenceVersion;
      let to_date = moment().format('YYYY-MM-DD');
      if (moment(from_date).isAfter(to_date, 'day')) {
        to_date = moment(from_date).format('YYYY-MM-DD');
      }
      this.setState({
        isSaving: false,
        to_date,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  updateOperationalMaintenanceRecurrenceVersion = () => {
    this.setState({ isSaving: true });

    this.props
      .updateOperationalMaintenanceTemplareRecurrenceVersion(this.props.recurrenceVersionId, {
        to_date: this.state.to_date,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.cancel-interval-confirm-modal.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Field
            view={false}
            label={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.end-interval-at" />
            }
          >
            <Field.Date
              value={this.state.to_date}
              disabledDays={{ before: moment(this.state.to_date).tz(this.props.system.timezone).toDate() }}
              onChangeDate={to_date => {
                this.setState({
                  to_date,
                });
              }}
              onClear={() => {
                this.setState({
                  to_date: null,
                });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.to_date == null}
              loading={this.state.isSaving}
              onClick={this.updateOperationalMaintenanceRecurrenceVersion}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOperationalMaintenanceTemplareRecurrenceVersion:
        SDKReduxOperations.updateOperationalMaintenanceTemplareRecurrenceVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { recurrenceVersionId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    operationalMaintenanceTemplateRecurrenceVersion:
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(state, recurrenceVersionId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveIntervalConfirmModal);
