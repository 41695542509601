import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import Decimal from 'decimal.js';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { FormattedMessage } from 'react-intl';
import { getNewWeekdays, buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import {
  Icon,
  DatePicker,
  Button,
  FormattedNumber,
  Field as GeneralField,
} from 'views/components/Shared/General';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { getEstimatedDueDateFromMeterAsMoment } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { Field } from 'views/components/WorkOrder';
import { EditMeterDueDateModal } from 'views/components/Meter';
import styles from '../style.module.scss';

class DueDate extends Component {
  state = {
    showDueDateDatePicker: false,
    showMeterDueDateModal: false,
  };

  /*
    Helper functions
  */

  selectDate = date => {
    this.props.removeFieldsFromErrors(['date']);
    if (this.props.editingWorkOrder.frequency != null) {
      const newWeekdays = getNewWeekdays(
        buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder),
        date
      );
      this.props.setEditingWorkOrderValues({ date, ...newWeekdays });
    } else {
      this.props.setEditingWorkOrderValues({ date });
    }
  };

  /*
    Render functions
  */

  renderEditMeterDueDateModal = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    const hideExactDate = recurringMaintenanceId || this.props.editingWorkOrderIsRecurring;
    return (
      <EditMeterDueDateModal
        hideExactDate={hideExactDate}
        editingWorkOrder={this.props.editingWorkOrder}
        open={this.state.showMeterDueDateModal}
        onSave={params => {
          this.setState({ showMeterDueDateModal: false });
          this.props.setEditingWorkOrderValues(params);
        }}
        onClose={() => this.setState({ showMeterDueDateModal: false })}
      />
    );
  };

  renderSelectedMeterValue = () => {
    if (this.props.editingWorkOrder.date) {
      return moment(this.props.editingWorkOrder.date).format('LL');
    }
    if (this.props.editingWorkOrder.due_meter_value) {
      return (
        <FormattedNumber
          value={this.props.editingWorkOrder.due_meter_value}
          unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
        />
      );
    }
    return '-';
  };

  renderEstimatedDueDate = () => {
    const estimatedDate = getEstimatedDueDateFromMeterAsMoment({
      meter_value: this.props.meter.value,
      due_meter_value: this.props.editingWorkOrder.due_meter_value,
      average_daily_usage: this.props.meter.average_daily_usage,
      value_last_updated: this.props.meter.value_last_updated,
    });
    if (estimatedDate == null) {
      return null;
    }

    return (
      <GeneralField
        singleRow
        view
        fontSize={11}
        label={<FormattedMessage id="resources.work-order.estimated-meter-due-date" />}
      >
        {estimatedDate.format('LL')}
      </GeneralField>
    );
  };

  renderAverageDailyUsage = () => {
    if (this.props.meter.average_daily_usage) {
      return (
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-average-increase" />}
        >
          <FormattedMessage
            id="screens.work-order.meter-average-increase-value"
            values={{
              value: (
                <FormattedNumber
                  value={this.props.meter.average_daily_usage}
                  unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                />
              ),
            }}
          />
        </GeneralField>
      );
    }
    return null;
  };

  renderTolerance = () => {
    if (new Decimal(this.props.editingWorkOrder.due_meter_tolerance || 0).greaterThan(new Decimal(0))) {
      return (
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-tolerance" />}
        >
          <FormattedNumber
            value={this.props.editingWorkOrder.due_meter_tolerance}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </GeneralField>
      );
    }
    return null;
  };

  renderExtraMeterValues = () => {
    if (this.props.editingWorkOrder.date) {
      return (
        <div className={styles['extra-meter-values']}>
          <GeneralField
            singleRow
            view
            fontSize={11}
            label={<FormattedMessage id="resources.work-order.due-date" />}
          >
            <FormattedNumber
              value={this.props.editingWorkOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </GeneralField>
          <GeneralField
            singleRow
            view
            fontSize={11}
            label={<FormattedMessage id="screens.work-order.meter-current-value" />}
          >
            <FormattedNumber
              value={this.props.meter.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </GeneralField>
        </div>
      );
    }
    return (
      <div className={styles['extra-meter-values']}>
        {this.renderTolerance()}
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-current-value" />}
        >
          <FormattedNumber
            value={this.props.meter.value}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </GeneralField>
        {this.renderAverageDailyUsage()}
        {this.renderEstimatedDueDate()}
      </div>
    );
  };

  renderMeterValue = () => {
    let classNames = [styles['edit-row']];

    return (
      <div>
        <div
          className={classNames.join(' ')}
          onClick={() => {
            this.setState({ showMeterDueDateModal: true });
          }}
        >
          <ConnectedFieldErrorWrapper field="date" position="left">
            {() => (
              <div className={styles['date']}>
                <Field
                  error={this.props.fieldErrors.date}
                  icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
                  label={<FormattedMessage id="resources.work-order.due-date" />}
                  value={this.renderSelectedMeterValue()}
                />
                {this.renderExtraMeterValues()}
              </div>
            )}
          </ConnectedFieldErrorWrapper>
        </div>
      </div>
    );
  };

  renderSelectedDateValue = () => {
    if (this.props.editingWorkOrder.date) {
      return moment(this.props.editingWorkOrder.date).format('LL');
    }
    return '-';
  };

  renderDateValue = () => {
    let canClearDate = true;
    if (this.props.hasProTier === false) {
      canClearDate = false;
    }
    if (this.props.editingWorkOrder.date == null) {
      canClearDate = false;
    }
    let label;
    if (this.props.editingWorkOrder.status === 'archived') {
      label = <FormattedMessage id="screens.work-order.next-work-order-date" />;
    } else {
      label = <FormattedMessage id="resources.work-order.due-date" />;
    }
    return (
      <DatePicker.Day
        position="right"
        open={this.state.showDueDateDatePicker}
        date={this.props.editingWorkOrder.date}
        onClose={() => {
          this.setState({ showDueDateDatePicker: false });
        }}
        onSelectDate={date => {
          this.setState({ showDueDateDatePicker: false });
          this.selectDate(date);
        }}
        trigger={
          <div
            className={styles['edit-row']}
            onClick={() => {
              this.setState(prevState => ({
                showDueDateDatePicker: !prevState.showDueDateDatePicker,
              }));
            }}
          >
            <ConnectedFieldErrorWrapper field="date" position="left">
              {() => (
                <div className={styles['date']}>
                  <Field
                    error={this.props.fieldErrors.date}
                    icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
                    label={label}
                    value={this.renderSelectedDateValue()}
                  />
                </div>
              )}
            </ConnectedFieldErrorWrapper>
            {canClearDate ? (
              <div className={styles['clear-container']}>
                <Button
                  type="icon"
                  iconButtonSize={24}
                  icon={<Icon light size={16} type="times" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.props.setEditingWorkOrderValues({ date: null });
                  }}
                />
              </div>
            ) : null}
          </div>
        }
        footerComponent={
          <DatePicker.Footer
            showOnOccasion
            showClear={this.props.editingWorkOrder.date != null}
            onClear={() => {
              this.setState({ showDueDateDatePicker: false });
              this.props.setEditingWorkOrderValues({ date: null });
            }}
          />
        }
      />
    );
  };

  renderContent() {
    if (this.props.meter) {
      return this.renderMeterValue();
    }
    return this.renderDateValue();
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderEditMeterDueDateModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      setFieldErrors: ErrorOperations.setFieldErrors,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const editingWorkOrder = WorkOrderSelectors.getEditingWorkOrder(state);
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    editingWorkOrder,
    editingWorkOrderIsRecurring: WorkOrderSelectors.editingWorkOrderIsRecurring(state),
    meter,
    meterUnit,
    hasProTier: AuthSelectors.hasProTier(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DueDate));
