import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetcOperationalMaintenanceBreak = breakId =>
  request.get(`operational_maintenance_breaks/${breakId}`);
export const listOperationalMaintenanceBreaks = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenance_breaks?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );
export const updateOperationalMaintenanceBreak = (breakId, data) =>
  request.patch(`operational_maintenance_breaks/${breakId}`, data);
export const createOperationalMaintenanceBreak = (systemId, data) =>
  request.post(`systems/${systemId}/operational_maintenance_breaks`, data);
export const deleteOperationalMaintenanceBreak = breakId =>
  request.delete(`operational_maintenance_breaks/${breakId}`);
