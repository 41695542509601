import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import ActionButtonsContent from './ActionButtonsContent';
import TabsContent from './TabsContent';

class TopBar extends Component {
  renderContent = () => {
    if (this.props.selectedInstanceRowIds.length === 0) {
      return <TabsContent />;
    } else {
      return <ActionButtonsContent />;
    }
  };

  render() {
    if (this.props.checklistInstance.inspection_failed_count === 0) {
      return null;
    }
    if (this.props.isViewOnly) {
      return null;
    }

    return (
      <div className={styles['top-bar-container']}>
        <div className={styles['top-bar']}>{this.renderContent()}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectDeviationTab: ChecklistModalOperations.selectDeviationTab,
      updateChecklistInstance: SDKReduxOperations.updateChecklistInstance,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  return {
    checklistInstance,
    selectedInstanceRowIds: ChecklistModalSelectors.getSelectedInstanceRows(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
