import { API, HelperFunctions } from 'sdk';
import moment from 'moment';
import {
  normalizeRequest,
  normalizeOperationalMaintenance,
  normalizeProductionBoardActivity,
} from 'sdk/Schemas';
import { RequestStatus } from 'sdk/Request';
import { EntityOperations } from 'sdk/State/entities';
import actions from './actions';

const { resetState, setOperationalMaintenancesDate, setProductionBoardActivityDate, setIsRefreshing } =
  actions;

const fetchRequestsForDashboard = (systemId, params) => dispatch => {
  return API.listRequests(systemId, {
    status: `${RequestStatus.AwaitingApproval},${RequestStatus.Active},${RequestStatus.WorkOrderCreated}`,
    ...params,
  }).then(({ data: requests, headers }) => {
    const { totalEntries } = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeRequest(requests);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchRequestsForDashboardSuccess({
        ids: result,
        totalEntries,
      })
    );
  });
};

const fetchOperationalMaintenancesForDashboard = (systemId, attrs, config) => dispatch => {
  let params = {
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    ...attrs,
  };
  return API.listOperationalMaintenances(systemId, params, config).then(
    ({ data: operationalMaintenances }) => {
      const { entities, result } = normalizeOperationalMaintenance(operationalMaintenances);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchOperationalMaintenancesForDashboardSuccess({
          ids: result,
        })
      );
    }
  );
};

const fetchProductionBoardActivitiesForDashboard = (systemId, params, config) => dispatch => {
  return API.listProductionBoardActivities(systemId, params, config).then(
    ({ data: productionBoardActivities }) => {
      const { entities, result } = normalizeProductionBoardActivity(productionBoardActivities);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchProductionBoardActivitiesForDashboardSuccess({
          ids: result,
        })
      );
    }
  );
};

export default {
  setOperationalMaintenancesDate,
  fetchOperationalMaintenancesForDashboard,
  fetchProductionBoardActivitiesForDashboard,
  fetchRequestsForDashboard,
  setProductionBoardActivityDate,
  setIsRefreshing,
  resetState,
};
