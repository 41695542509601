import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Button, Field, Icon, SectionHeader } from 'views/components/Shared/General';
import { SelectMeterModal } from 'views/components/Meter';
import toast from 'react-hot-toast';
import { Type } from 'sdk/ChecklistTemplateRow';
import { ToastMessage } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class ChecklistTemplateRow extends Component {
  state = {
    showSelectMeterModal: false,
    isSavingChecklistOptionsForMeterId: null,
  };

  saveChecklistInstanceOption = ({ type, meter_id, checklist_template_row_id }) => {
    const { checklistInstanceOptions } = this.props;
    const checklistInstanceOption = checklistInstanceOptions.find(
      ({ checklist_template_row_id: ctr_id }) => checklist_template_row_id === ctr_id
    );
    if (checklistInstanceOption) {
      this.updateChecklistInstanceOption(checklistInstanceOption.id, {
        type,
        meter_id,
        checklist_template_row_id,
      });
    } else {
      this.createForOperationalMaintenanceTemplateAsset({ type, meter_id, checklist_template_row_id });
    }
  };

  createForOperationalMaintenanceTemplateAsset = ({ type, meter_id, checklist_template_row_id }) => {
    this.setState({ isSavingChecklistOptionsForMeterId: meter_id });
    this.props
      .createForOperationalMaintenanceTemplateAsset(this.props.operationalMaintenanceTemplateAssetId, {
        meter_id,
        type,
        checklist_template_row_id,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ showSelectMeterModal: false, isSavingChecklistOptionsForMeterId: null });
      })
      .catch(() => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.setState({ showSelectMeterModal: false, isSavingChecklistOptionsForMeterId: null });
      });
  };

  updateChecklistInstanceOption = (
    checklistInstanceOptionId,
    { type, meter_id, checklist_template_row_id }
  ) => {
    this.setState({ isSavingChecklistOptionsForMeterId: meter_id });
    this.props
      .updateChecklistInstanceOption(checklistInstanceOptionId, {
        meter_id,
        type,
        checklist_template_row_id,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ showSelectMeterModal: false, isSavingChecklistOptionsForMeterId: null });
      })
      .catch(() => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.setState({ showSelectMeterModal: false, isSavingChecklistOptionsForMeterId: null });
      });
  };

  renderAddMeterModal = () => {
    const { asset_id } = this.props.operationalMaintenanceTemplateAsset || {};
    return (
      <SelectMeterModal
        assetId={asset_id}
        open={this.state.showSelectMeterModal}
        isSavingMeterId={this.state.isSavingChecklistOptionsForMeterId}
        onClose={() => {
          this.setState({ showSelectMeterModal: false });
        }}
        onSelect={meter_id => {
          this.saveChecklistInstanceOption({
            meter_id,
            type: Type.Meter,
            checklist_template_row_id: this.props.id,
          });
        }}
      />
    );
  };

  renderContent = () => {
    if (this.props.meter) {
      return (
        <Field label={<FormattedMessage id="components.checklist-options-modal.select-meter-button" />}>
          <div className={styles['selected-meter']}>
            <div className={styles['title-container']}>
              <div>{this.props.meter.title}</div>
              <div>
                <TreePath assetId={this.props.meter.asset_id} fullPath />
              </div>
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => this.setState({ showSelectMeterModal: true })}
              />
            </div>
          </div>
        </Field>
      );
    }
    return (
      <Button
        gray
        fullWidth
        label="components.checklist-options-modal.select-meter-button"
        onClick={() => {
          this.setState({ showSelectMeterModal: true });
        }}
      />
    );
  };

  render() {
    return (
      <div className={styles['meter-container']}>
        <SectionHeader paddingHorizontal={30} horizontalBorders>
          {this.props.checklistTemplateRow.title}
        </SectionHeader>
        <div className={styles['meter']}>{this.renderContent()}</div>
        {this.renderAddMeterModal()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createForOperationalMaintenanceTemplateAsset:
        SDKReduxOperations.createForOperationalMaintenanceTemplateAsset,
      updateChecklistInstanceOption: SDKReduxOperations.updateChecklistInstanceOption,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id, operationalMaintenanceTemplateAssetId } = ownProps;

  const checklistTemplateRow = EntitySelectors.getChecklistTemplateRow(state, id);
  let checklistInstanceOptions = [];
  let meter = null;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplateAssetId
  );
  if (operationalMaintenanceTemplateAsset) {
    checklistInstanceOptions = EntitySelectors.getChecklistInstanceOptions(
      state,
      operationalMaintenanceTemplateAsset.checklist_instance_options
    );
  }
  if (checklistInstanceOptions.length > 0) {
    const checklistOptionForChecklistTemplateRow = checklistInstanceOptions.find(
      ({ checklist_template_row_id }) => checklist_template_row_id === id
    );
    if (checklistOptionForChecklistTemplateRow) {
      meter = EntitySelectors.getMeter(state, checklistOptionForChecklistTemplateRow.meter_id);
    }
  }
  return {
    operationalMaintenanceTemplateAsset,
    checklistInstanceOptions,
    checklistTemplateRow,
    meter,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistTemplateRow);
