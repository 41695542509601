import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Field, Icon } from 'views/components/Shared/General';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CardOption } from 'views/scenes/Settings/components';
import { AssigneeDropdown } from 'views/components/Request';
import { FullScreenOverlayModal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { ItemType } from 'sdk/RequestAutomationRule';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import AssetField from './AssetField';
import AssetTypeField from './AssetTypeField';
import RequestTypeField from './RequestTypeField';
import DeleteModal from './DeleteModal';
import SelectedAssignee from './SelectedAssignee';
import styles from './style.module.scss';

class EditRuleOverlayModal extends Component {
  getInitialState = () => ({
    editingRequestAutomationRule: {},
    editingRequestAutomationRuleBeforeEdit: {},
    errors: {
      assetRequired: false,
      assetTypeRequired: false,
      requestTypeRequired: false,
    },
    isSaving: false,
    showDeleteModal: false,
    showDeleteModalForId: null,
    showAssigneeDropdown: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id) {
        this.setState({
          ...this.getInitialState(),
          editingRequestAutomationRule: this.props.requestAutomationRule,
          editingRequestAutomationRuleBeforeEdit: this.props.requestAutomationRule,
        });
      } else {
        this.setState({
          ...this.getInitialState(),
          editingRequestAutomationRule: this.generateEmptyRequestAutomationRule(),
          editingRequestAutomationRuleBeforeEdit: this.generateEmptyRequestAutomationRule(),
        });
      }
    }
  }

  generateEmptyRequestAutomationRule = () => {
    return {
      sort: 1,
      item_type: null,
      assets: [],
      asset_types: [],
      request_types: [],
      assign_user_id: null,
      assign_group_id: null,
    };
  };

  renderWithMaxWidth = children => {
    return <div className={styles['small-centered-content']}>{children}</div>;
  };

  changeValue = data => {
    this.setState({
      editingRequestAutomationRule: {
        ...this.state.editingRequestAutomationRule,
        ...data,
      },
    });
  };

  hasErrors = () => {
    const { item_type, assets, asset_types, request_types } = this.state.editingRequestAutomationRule;
    let errors = {};
    if (item_type === ItemType.Asset && assets.length === 0) {
      errors = {
        ...errors,
        assetRequired: true,
      };
    }
    if (item_type === ItemType.AssetType && asset_types.length === 0) {
      errors = {
        ...errors,
        assetTypeRequired: true,
      };
    }
    if (item_type === ItemType.RequestType && request_types.length === 0) {
      errors = {
        ...errors,
        requestTypeRequired: true,
      };
    }
    this.setState({ errors });
    return Object.values(errors).some(item => item === true);
  };

  save = () => {
    if (this.hasErrors()) {
      return;
    }
    const { item_type, assign_user_id, assign_group_id } = this.state.editingRequestAutomationRule;
    const data = { item_type, assign_user_id, assign_group_id };
    if (this.props.id) {
      this.update(data);
    } else {
      this.create(data);
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createRequestAutomationRule(this.props.system.id, this.state.editingRequestAutomationRule)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.create-success" />
            }
          />
        );
        this.props.onClose();
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });

    this.props
      .updateRequestAutomationRule(this.props.id, this.state.editingRequestAutomationRule)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  renderOptions = () => {
    const { item_type } = this.state.editingRequestAutomationRule;
    return this.renderWithMaxWidth(
      <>
        <CardOption.Container>
          <CardOption
            icon="box-alt"
            inactive={item_type !== ItemType.Asset}
            selected={item_type === ItemType.Asset}
            title={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset.subtitle" />
            }
            onClick={() => {
              this.setState({
                errors: {
                  assetRequired: false,
                  assetTypeRequired: false,
                  requestTypeRequired: false,
                },
              });
              this.changeValue({ item_type: ItemType.Asset });
            }}
          />
          <CardOption
            icon="box-alt"
            selected={item_type === ItemType.AssetType}
            inactive={item_type !== ItemType.AssetType}
            title={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset-type.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset-type.subtitle" />
            }
            onClick={() => {
              this.setState({
                errors: {
                  assetRequired: false,
                  assetTypeRequired: false,
                  requestTypeRequired: false,
                },
              });
              this.changeValue({ item_type: ItemType.AssetType });
            }}
          />
        </CardOption.Container>
        <CardOption.Container>
          <CardOption
            icon="tag"
            selected={item_type === ItemType.RequestType}
            inactive={item_type !== ItemType.RequestType}
            title={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.request-type.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.request-type.subtitle" />
            }
            onClick={() => {
              this.setState({
                errors: {
                  assetRequired: false,
                  assetTypeRequired: false,
                  requestTypeRequired: false,
                },
              });
              this.changeValue({ item_type: ItemType.RequestType });
            }}
          />
        </CardOption.Container>
      </>
    );
  };

  renderAssignee = () => {
    return (
      <div className={styles['options-container']}>
        {this.renderWithMaxWidth(
          <div className={styles['dropdown']}>
            <Field
              view={false}
              label={
                <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.assignee.label" />
              }
            >
              <div
                ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
                onClick={() => {
                  this.setState(prevState => ({
                    showAssigneeDropdown: !prevState.showAssigneeDropdown,
                  }));
                }}
              >
                <Field.Resource
                  value={
                    <SelectedAssignee
                      userId={this.state.editingRequestAutomationRule.assign_user_id}
                      groupId={this.state.editingRequestAutomationRule.assign_group_id}
                    />
                  }
                  clearable={
                    this.state.editingRequestAutomationRule.assign_user_id ||
                    this.state.editingRequestAutomationRule.assign_group_id
                  }
                  angleDown
                  onClear={() => {
                    this.changeValue({
                      assign_group_id: null,
                      assign_user_id: null,
                    });
                  }}
                />
              </div>
              <AssigneeDropdown
                positionToRef={this.assigneeDropdownInlineModalPositioningRef}
                open={this.state.showAssigneeDropdown}
                userId={this.state.editingRequestAutomationRule.assign_user_id}
                groupId={this.state.editingRequestAutomationRule.assign_group_id}
                onSelectUser={id => {
                  this.setState({ showAssigneeDropdown: false });
                  this.changeValue({ assign_group_id: null, assign_user_id: id });
                }}
                onSelectGroup={id => {
                  this.setState({ showAssigneeDropdown: false });
                  this.changeValue({ assign_user_id: null, assign_group_id: id });
                }}
                onClear={id => {
                  this.setState({ showAssigneeDropdown: false });
                  this.changeValue({ assign_user_id: null, assign_group_id: null });
                }}
                onClose={() => this.setState({ showAssigneeDropdown: false })}
              />
            </Field>
          </div>
        )}
      </div>
    );
  };

  renderOptionField = () => {
    const { item_type } = this.state.editingRequestAutomationRule;
    if (item_type === ItemType.Asset) {
      return (
        <>
          <AssetField
            ids={this.state.editingRequestAutomationRule.assets}
            hasError={this.state.errors.assetRequired}
            onAddAsset={id => {
              if (this.state.errors.assetRequired) {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    assetRequired: false,
                  },
                });
              }
              this.changeValue({ assets: [...this.state.editingRequestAutomationRule.assets, id] });
            }}
            onRemoveAsset={id => {
              this.changeValue({
                assets: this.state.editingRequestAutomationRule.assets.filter(assetId => assetId !== id),
              });
            }}
            onClear={id => this.changeValue({ assets: [] })}
          />
          <div className={styles['asset-subtitle']}>
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.asset-subtitle" />
          </div>
        </>
      );
    } else if (item_type === ItemType.AssetType) {
      return (
        <AssetTypeField
          ids={this.state.editingRequestAutomationRule.asset_types}
          hasError={this.state.errors.assetTypeRequired}
          onAddAssetType={id => {
            if (this.state.errors.assetTypeRequired) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  assetTypeRequired: false,
                },
              });
            }
            this.changeValue({ asset_types: [...this.state.editingRequestAutomationRule.asset_types, id] });
          }}
          onRemoveAssetType={id => {
            this.changeValue({
              asset_types: this.state.editingRequestAutomationRule.asset_types.filter(
                assetTypeId => assetTypeId !== id
              ),
            });
          }}
          onClear={id => this.changeValue({ asset_types: [] })}
        />
      );
    } else if (item_type === ItemType.RequestType) {
      return (
        <RequestTypeField
          ids={this.state.editingRequestAutomationRule.request_types}
          hasError={this.state.errors.requestTypeRequired}
          onAddRequestType={id => {
            if (this.state.errors.requestTypeRequired) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  requestTypeRequired: false,
                },
              });
            }
            this.changeValue({
              request_types: [...this.state.editingRequestAutomationRule.request_types, id],
            });
          }}
          onRemoveRequestType={id => {
            this.changeValue({
              request_types: this.state.editingRequestAutomationRule.request_types.filter(
                requestTypeId => requestTypeId !== id
              ),
            });
          }}
          onClear={id => this.changeValue({ request_types: [] })}
        />
      );
    }
  };

  renderSelectedOption = () => {
    const { item_type } = this.state.editingRequestAutomationRule;
    if (item_type == null) return null;
    return this.renderWithMaxWidth(
      <div className={styles['selected-option']}>
        <div className={styles['separator']} />
        {this.renderOptionField()}
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.id) {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.edit-title" />
      );
    }
    return (
      <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.new-title" />
    );
  };

  renderHeader = () => (
    <div className={styles['header']}>
      <div className={styles['title-container']}>
        <div className={styles['title']}>{this.renderTitle()}</div>
        <div className={styles['buttons']}>
          {this.props.id == null ? null : (
            <div className={styles['button']}>
              <Button
                type="icon"
                icon={<Icon regular red type="trash-alt" />}
                iconSize="15"
                onClick={() => this.setState({ showDeleteModal: true, showDeleteModalForId: this.props.id })}
              />
            </div>
          )}
          <div className={styles['button']}>
            <Button type="icon" icon={<Icon light size={22} type="times" />} onClick={this.props.onClose} />
          </div>
        </div>
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.subtitle" />
      </div>
    </div>
  );

  renderBody = () => (
    <div className={styles['body']}>
      <div className={styles['section']}>
        {this.renderWithMaxWidth(
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.rule-section-title" />
        )}
      </div>
      <div className={styles['rules-content']}>
        {this.renderOptions()}
        {this.renderSelectedOption()}
      </div>
      <div className={styles['assignee']}>
        <div className={styles['section']}>
          {this.renderWithMaxWidth(
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.assignee-section-title" />
          )}
        </div>
        <div className={styles['assignee-content']}>{this.renderAssignee()}</div>
      </div>
      {this.renderWithMaxWidth(
        <div className={styles['footer']}>
          <Button
            primary
            label="general.save"
            loading={this.state.isSaving}
            onClick={this.save}
            disabled={this.state.editingRequestAutomationRule.item_type == null}
          />
        </div>
      )}
    </div>
  );

  render() {
    return (
      <>
        <FullScreenOverlayModal open={this.props.open}>
          <div className={styles['container']}>
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        </FullScreenOverlayModal>
        <DeleteModal
          open={this.state.showDeleteModal}
          id={this.state.showDeleteModalForId}
          onClose={() =>
            this.setState({
              showDeleteModal: false,
              showDeleteModalForId: null,
            })
          }
          onDelete={() => {
            this.setState({
              showDeleteModal: false,
              showDeleteModalForId: null,
            });
            this.props.onClose();
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRequestAutomationRule: SDKReduxOperations.createRequestAutomationRule,
      updateRequestAutomationRule: SDKReduxOperations.updateRequestAutomationRule,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    requestAutomationRule: EntitySelectors.getRequestAutomationRule(state, ownProps.id),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditRuleOverlayModal));
