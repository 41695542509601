import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import AnimateHeight from 'react-animate-height';
import { normalizeMeter } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { Button, Field, NewInlineModal, FieldErrorWrapper } from 'views/components/Shared/General';
import { SideModal, Grid } from 'views/components/Shared/Layout';
import AssetContainer from './AssetContainer';
import styles from './style.module.scss';

class CreateMeterModal extends Component {
  getInitialState = () => ({
    title: '',
    dailyUsage: null,
    readingFrequency: null,
    initialValue: null,
    selectedAssetId: null,
    showDropDown: false,
    currentMeterUnit: null,
    closeSideModalOnClickOverlay: true,
    closeSideModalOnClickEscape: true,
    showEmptyTitleError: false,
    showEmptyAssetError: false,
    calculateDailyUsage: true,
    isCreatingMeter: false,
    isCreatingMeterAndCreatingNew: false,
    showErrorForAlreadyUsedAsset: false,
    showErrorForEmptyDailyUsage: false,
    showInitialMeterReadingToHighError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      this.props.meterUnits.map(meterUnit => {
        if (meterUnit.default) {
          this.setState({ currentMeterUnit: meterUnit });
        }
      });
    }
  }

  hasUnsavedChanges = () => {
    let hasUnsavedChanges = false;
    if (
      this.state.title !== '' ||
      this.state.dailyUsage !== null ||
      this.state.readingFrequency !== null ||
      this.state.initialValue !== null ||
      this.state.selectedAssetId !== null ||
      (this.state.currentMeterUnit !== null && !this.state.currentMeterUnit.default) ||
      this.state.calculateDailyUsage !== true
    ) {
      hasUnsavedChanges = true;
    }
    return hasUnsavedChanges;
  };

  isSavable = () => {
    let savable = true;
    if (this.state.title.length === 0) {
      this.setState({ showEmptyTitleError: true });
      savable = false;
    }
    if (this.state.selectedAssetId === null) {
      this.setState({ showEmptyAssetError: true });
      savable = false;
    }
    if (this.state.calculateDailyUsage === false && !this.state.dailyUsage) {
      this.setState({ showErrorForEmptyDailyUsage: true });
      savable = false;
    }
    return savable;
  };

  createMeter = openNew => {
    if (!this.isSavable()) return;
    let params = {
      title: this.state.title,
      reading_frequency: this.state.readingFrequency,
      meter_unit_id: this.state.currentMeterUnit ? this.state.currentMeterUnit.id : null,
      asset_id: this.state.selectedAssetId,
    };
    if (this.state.initialValue) {
      params = {
        ...params,
        meter_readings: [
          {
            date: moment().format('YYYY-MM-DD'),
            value: this.state.initialValue,
          },
        ],
      };
    }

    if (this.state.calculateDailyUsage) {
      params = {
        ...params,
        daily_usage_type: 'automatic',
      };
    } else {
      params = {
        ...params,
        daily_usage_type: 'manual',
        average_daily_usage: this.state.dailyUsage,
      };
    }

    this.setState({
      isCreatingMeter: openNew === false,
      isCreatingMeterAndCreatingNew: openNew === true,
    });
    this.props
      .createMeter(this.props.currentSystem.id, params)
      .then(({ data: meter }) => {
        const { entities } = normalizeMeter(meter);
        this.props.updateEntities(entities);
        if (openNew) {
          this.setState({ isCreatingUserAndCreatingNew: false });
          this.props.onCreatedWithReopen();
        } else {
          this.setState({ isCreatingMeter: false });
          this.props.onCreated(meter.id);
        }
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10011', key: ['meter_readings', 'value'] })) {
          this.setState({
            showInitialMeterReadingToHighError: true,
            isCreatingMeter: false,
            isCreatingMeterAndCreatingNew: false,
          });
        }
        this.setState({ isCreatingMeter: false, isCreatingMeterAndCreatingNew: false });
      });
  };

  renderUnitDropdown = () => {
    return (
      <>
        <div
          className={styles['resource']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState(prevState => ({ showDropDown: !prevState.showDropDown }))}
        >
          <Field.Resource
            angleDown
            value={this.state.currentMeterUnit ? this.state.currentMeterUnit.title : null}
            onClear={() => {
              this.setState({ currentMeterUnit: null });
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.props.meterUnits.map(meterUnit => {
                return (
                  <NewInlineModal.Dropdown.Item
                    key={meterUnit.id}
                    onClick={() => {
                      this.setState({ showDropDown: false, currentMeterUnit: meterUnit });
                    }}
                    selected={this.state.currentMeterUnit && this.state.currentMeterUnit.id === meterUnit.id}
                  >
                    <span>{meterUnit ? meterUnit.title : null}</span>
                  </NewInlineModal.Dropdown.Item>
                );
              })}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderDailyUsageType = () => {
    return (
      <>
        {this.state.currentMeterUnit.abbreviation}
        {'/'}
        <FormattedMessage id="screens.meters.create-modal.daily-usage-unit" />
      </>
    );
  };

  renderDailyUsageField = () => {
    return (
      <AnimateHeight duration={150} height={this.state.calculateDailyUsage ? 0 : 'auto'}>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['usage-field']}>
              <Field.Decimal
                ref={ref => (this.manualInputRef = ref)}
                error={this.state.showErrorForEmptyDailyUsage}
                rightLabel={
                  this.state.currentMeterUnit ? (
                    this.renderDailyUsageType()
                  ) : (
                    <FormattedMessage id="screens.meters.create-modal.per-day" />
                  )
                }
                value={this.state.dailyUsage}
                onChange={dailyUsage => this.setState({ showErrorForEmptyDailyUsage: false, dailyUsage })}
                onBlur={dailyUsage => this.setState({ dailyUsage })}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </AnimateHeight>
    );
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.meter.title" />}>
              <Field.Text
                autoFocus
                error={this.state.showEmptyTitleError}
                value={this.state.title}
                onChange={title => this.setState({ title, showEmptyTitleError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ChooseAssetInlineModal
              trigger={
                <Field label={<FormattedMessage id="resources.work-order.asset" />}>
                  <FieldErrorWrapper
                    position="top"
                    show={this.state.showErrorForAlreadyUsedAsset}
                    errorElement={
                      <FormattedMessage id="screens.meters.create-modal.already-used-asset-error" />
                    }
                  >
                    <>
                      <Field.Resource
                        angleDown
                        error={this.state.showEmptyAssetError || this.state.showErrorForAlreadyUsedAsset}
                        value={
                          this.state.selectedAssetId == null ? null : (
                            <AssetContainer id={this.state.selectedAssetId} />
                          )
                        }
                        onClear={() => {
                          this.setState({ selectedAssetId: null });
                        }}
                      />{' '}
                    </>
                  </FieldErrorWrapper>
                </Field>
              }
              selectedAssetId={this.state.selectedAssetId}
              onSelectAsset={selectedAssetId =>
                this.setState({
                  selectedAssetId,
                  showEmptyAssetError: false,
                  showErrorForAlreadyUsedAsset: false,
                })
              }
              onClear={() => this.setState({ selectedAssetId: null })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="screens.meters.create-modal.initial-value" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showInitialMeterReadingToHighError}
                errorElement={<FormattedMessage id="general.errors.value-to-high" />}
              >
                <Field.Decimal
                  error={this.state.showInitialMeterReadingToHighError}
                  value={this.state.initialValue}
                  onChange={initialValue =>
                    this.setState({ showInitialMeterReadingToHighError: false, initialValue })
                  }
                  onBlur={initialValue => this.setState({ initialValue })}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
          <Grid.Column width={155}>{this.renderUnitDropdown()}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={<FormattedMessage id="resources.meter.reading-frequency" />}
              questionTooltipContent={
                <FormattedMessage id="resources.meter.reading-frequency-description2" />
              }
            >
              <Field.Number
                rightLabel={<FormattedMessage id="resources.meter.reading-frequency-unit" />}
                value={this.state.readingFrequency}
                onChange={readingFrequency => this.setState({ readingFrequency })}
                onBlur={readingFrequency => this.setState({ readingFrequency })}
              />
              <p className={styles['field-subtitle']}>
                <FormattedMessage id="resources.meter.reading-frequency-description1" />
              </p>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <p className={styles['planned-usage-title']}>
          <FormattedMessage id="screens.meters.create-modal.planned-daily-usage-title" />
        </p>
        <p className={styles['planned-daily-usage-description']}>
          <FormattedMessage id="resources.meter.average-daily-usage-description" />
        </p>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['daily-usage-type-container']}>
              <Field.Radio
                checked={this.state.calculateDailyUsage}
                subtitle={
                  <FormattedMessage id="screens.meters.create-modal.automatic-daily-usage-description" />
                }
                onChange={() => this.setState({ calculateDailyUsage: true })}
                label={
                  <span>
                    <FormattedMessage id="screens.meters.create-modal.automatic-daily-usage" />{' '}
                    <span className={styles['automatic-timespan']}>
                      (<FormattedMessage id="resources.meter.average-daily-usage-automatic-timespan" />)
                    </span>
                  </span>
                }
              />
              <Field.Radio
                checked={!this.state.calculateDailyUsage}
                onChange={() => {
                  this.setState({ calculateDailyUsage: false }, () => {
                    this.manualInputRef.focus();
                  });
                }}
                subtitle={
                  <FormattedMessage id="screens.meters.create-modal.manual-daily-usage-description" />
                }
                label={<FormattedMessage id="screens.meters.create-modal.manual-daily-usage" />}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {this.renderDailyUsageField()}
      </Grid>
    );
  };

  render() {
    return (
      <>
        <SideModal
          closeOnEscape={this.state.closeSideModalOnClickEscape}
          closeOnClickOverlay={this.state.closeSideModalOnClickOverlay}
          open={this.props.open}
          onClose={() => {
            if (this.hasUnsavedChanges()) {
              const confirmed = window.confirm(
                this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
              );
              if (confirmed) {
                this.props.onClose();
              }
            } else {
              this.props.onClose();
            }
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createMeter(false)}
                loading={this.state.isCreatingMeter}
              />
              <Button
                label="general.save-and-create-new"
                loading={this.state.isCreatingMeterAndCreatingNew}
                onClick={() => this.createMeter(true)}
              />
              <Button
                label="general.cancel"
                onClick={() => {
                  if (this.hasUnsavedChanges() === true) {
                    const confirmed = window.confirm(
                      this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
                    );
                    if (confirmed) {
                      this.props.onClose();
                    }
                  } else {
                    this.props.onClose();
                  }
                }}
              />
            </Button.Group>
          }
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header
                title={<FormattedMessage id="screens.meters.create-modal.title" />}
                subtitle={<FormattedMessage id="screens.meters.create-modal.subtitle" />}
                onClose={() => {
                  if (this.hasUnsavedChanges() === true) {
                    const confirmed = window.confirm(
                      this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
                    );
                    if (confirmed) {
                      this.props.onClose();
                    }
                  } else {
                    this.props.onClose();
                  }
                }}
              />
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createMeter: SDKReduxOperations.createMeter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    meterUnits: AuthSelectors.getMeterUnits(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateMeterModal));

CreateMeterModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  closeOnClickOverlay: PropTypes.bool,
};

CreateMeterModal.defaultProps = {
  open: false,
  closeOnClickOverlay: true,
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
};
