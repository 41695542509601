import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeOperationalMaintenanceTemplate } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createOperationalMaintenanceTemplate(systemId, params) {
  return dispatch => {
    return API.createOperationalMaintenanceTemplate(systemId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteOperationalMaintenanceTemplate(templateId) {
  return dispatch => {
    return API.deleteOperationalMaintenanceTemplate(templateId)
      .then(res => {
        const payload = { templateId };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
