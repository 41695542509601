import React, { Component } from 'react';
import { Icon } from 'views/components/Shared/General';
import { Circle } from 'react-es6-progressbar.js';
import styles from './style.module.scss';

export default class ProgressCircleBar extends Component {
  render() {
    if (this.props.value === 1) {
      return (
        <div className={styles['completed-container']}>
          <Icon green regular type="check" />
        </div>
      );
    } else {
      return (
        <Circle
          progress={this.props.value}
          containerClassName={styles['progress-container']}
          options={{
            strokeWidth: 6,
            color: '#4C9AC8',
            trailColor: this.props.againstGrayBackground ? '#DDDDDD' : '#EAEAEA',
            trailWidth: 6,
            duration: 400,
            text: {
              value: '0%',
              style: {
                fontSize: '10px',
                color: '#515151',
                position: 'absolute',
                height: '34px',
                width: '34px',
                top: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            },
            step: function (state, circle) {
              var value = Math.round(circle.value() * 100);
              circle.setText(value + '%');
            },
          }}
        />
      );
    }
  }
}
