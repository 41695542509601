import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NewInlineModal } from 'views/components/Shared/General';
import { AssetTitle } from 'views/components/Asset';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';

class AssetDropdownItem extends Component {
  render() {
    return (
      <NewInlineModal.Dropdown.Item
        selected={this.props.selectedAssetId === this.props.asset.id}
        onClick={() => this.props.onClick({ id: this.props.asset.id, title: this.props.asset.title })}
      >
        <div className={styles['asset']}>
          <AssetTitle id={this.props.asset.id} />
        </div>
      </NewInlineModal.Dropdown.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const asset_id = ownProps.asset_id
    ? ownProps.asset_id
    : EntitySelectors.getAssetBookmark(state, ownProps.id).asset_id;
  return {
    asset: EntitySelectors.getAsset(state, asset_id),
  };
}

export default withRouter(connect(mapStateToProps)(AssetDropdownItem));
