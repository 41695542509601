import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import PropTypes from 'prop-types';

const daysInMilliseconds = 86_400_000; // 24 * 60 * 60 * 1000
const hoursInMilliseconds = 3_600_000; // 60 * 60 * 1000
const minutesInMilliseconds = 60_000; // 60 * 1000

// This component is used to display the duration between two dates in a human-readable format for example:
// '1 day 2 hours 3 minutes', or
// '1 hour 2 minutes', or
// '1 minute'
class Duration extends Component {
  render() {
    const from = new Date(this.props.from);
    let to = new Date();
    if (this.props.to) {
      to = new Date(this.props.to);
    }

    const timeDifference = to - from;

    const days = Math.floor(timeDifference / daysInMilliseconds);
    const hours = Math.floor((timeDifference % daysInMilliseconds) / hoursInMilliseconds);
    let minutes = Math.floor((timeDifference % hoursInMilliseconds) / minutesInMilliseconds);

    let spans = [];
    if (days) {
      spans.push(
        <FormattedPlural
          value={days}
          one={<FormattedMessage id="components.duration.day" values={{ days }} />}
          other={<FormattedMessage id="components.duration.days" values={{ days }} />}
        />
      );
    }
    if (hours || days) {
      spans.push(
        <FormattedPlural
          value={hours}
          one={<FormattedMessage id="components.duration.hour" values={{ hours }} />}
          other={<FormattedMessage id="components.duration.hours" values={{ hours }} />}
        />
      );
    }

    spans.push(
      <FormattedPlural
        value={minutes}
        zero={<FormattedMessage id="components.duration.minutes" values={{ minutes }} />}
        one={<FormattedMessage id="components.duration.minute" values={{ minutes }} />}
        other={<FormattedMessage id="components.duration.minutes" values={{ minutes }} />}
      />
    );

    return (
      <span>
        {spans.map((span, index) => (
          <React.Fragment key={index}>
            {index > 0 && ' '}
            {span}
          </React.Fragment>
        ))}
      </span>
    );
  }
}

export default Duration;

Duration.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
};
