/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getEditingWorkOrder = state => {
  const { workOrder } = state;
  return workOrder.editingWorkOrder == null ? null : workOrder.editingWorkOrder;
};

const editingWorkOrderIsRecurring = state => {
  const { workOrder } = state;
  const { editingWorkOrder } = workOrder;
  if (editingWorkOrder) {
    if (editingWorkOrder.frequency) {
      return true;
    }
    if (editingWorkOrder.meter_interval) {
      return true;
    }
  }
  return false;
};

const getEditingWorkOrderAsset = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).asset_id == null
    ? null
    : entities.assetById[getEditingWorkOrder(state).asset_id];
};

const getEditingWorkOrderRecurringMaintenance = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).recurring_maintenance_id == null
    ? null
    : entities.recurringMaintenanceById[getEditingWorkOrder(state).recurring_maintenance_id];
};

const getEditingWorkOrderType = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).work_order_type_id == null
    ? null
    : entities.workOrderTypeById[getEditingWorkOrder(state).work_order_type_id];
};

const getEditingWorkOrderUserAssignee = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).assigned_to_user_id == null
    ? null
    : entities.userById[getEditingWorkOrder(state).assigned_to_user_id];
};

const getEditingWorkOrderGroupAssignee = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).assigned_to_group_id == null
    ? null
    : entities.groupById[getEditingWorkOrder(state).assigned_to_group_id];
};

const getEditingWorkOrderVendorAssignee = state => {
  const { entities } = state;
  return getEditingWorkOrder(state).assigned_to_vendor_id == null
    ? null
    : entities.vendorById[getEditingWorkOrder(state).assigned_to_vendor_id];
};

const getWorkOrderActivityDates = state => {
  return Object.keys(state.workOrder.workOrderActivityData).sort((a, b) => new Date(a) - new Date(b));
};

const getWorkOrderActivitiesForDate = (state, date) => {
  return state.workOrder.workOrderActivityData[date] || [];
};

const getWorkOrderActivites = state => {
  const { entities } = state;
  return state.workOrder.workOrderActivityIds.map(id => entities.workOrderActivityById[id]);
};

const getPreviousWorkOrders = state => {
  const { workOrder, entities } = state;
  return workOrder.previousWorkOrderIds.map(id => entities.workOrderById[id]);
};

const getCreatedByUserForActivity = (state, activity) => {
  const { entities } = state;
  return activity.created_by_user_id == null ? null : entities.userById[activity.created_by_user_id];
};

const getWorkOrderTypeForActivity = (state, activity) => {
  const { entities } = state;
  return activity.work_order_type_id == null ? null : entities.workOrderTypeById[activity.work_order_type_id];
};

const getAssetForActivity = (state, activity) => {
  const { entities } = state;
  return activity.asset_id == null ? null : entities.assetById[activity.asset_id];
};

const getAssignedToUserForActivity = (state, activity) => {
  const { entities } = state;
  return activity.assigned_to_user_id == null ? null : entities.userById[activity.assigned_to_user_id];
};

const getCompletedByUserForActivity = (state, activity) => {
  const { entities } = state;
  return activity.completed_by_user_id == null ? null : entities.userById[activity.completed_by_user_id];
};

const getAssignedToGroupForActivity = (state, activity) => {
  const { entities } = state;
  return activity.assigned_to_group_id == null ? null : entities.groupById[activity.assigned_to_group_id];
};

const getAssignedToVendorForActivity = (state, activity) => {
  const { entities } = state;
  return activity.assigned_to_vendor_id == null ? null : entities.vendorById[activity.assigned_to_vendor_id];
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/
const hasUnsavedChanges = state => state.workOrder.hasUnsavedChanges;
const isFetchingEditingWorkOrder = state => state.workOrder.isFetchingEditingWorkOrder;
const isFetchingPreviousWorkOrders = state => state.workOrder.isFetchingPreviousWorkOrders;
const getWorkOrderListPagination = state => state.workOrder.pagination;

export default {
  getEditingWorkOrder,
  editingWorkOrderIsRecurring,
  getEditingWorkOrderAsset,
  getEditingWorkOrderRecurringMaintenance,
  getEditingWorkOrderType,
  getEditingWorkOrderUserAssignee,
  getEditingWorkOrderGroupAssignee,
  getEditingWorkOrderVendorAssignee,
  getPreviousWorkOrders,
  getWorkOrderListPagination,
  getWorkOrderActivites,
  getWorkOrderActivityDates,
  getWorkOrderActivitiesForDate,
  getWorkOrderTypeForActivity,
  getAssetForActivity,
  getAssignedToUserForActivity,
  getCompletedByUserForActivity,
  getAssignedToGroupForActivity,
  getAssignedToVendorForActivity,
  getCreatedByUserForActivity,
  hasUnsavedChanges,
  isFetchingEditingWorkOrder,
  isFetchingPreviousWorkOrders,
};
