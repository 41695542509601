import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class ChecklistInstanceListItem extends Component {
  renderInspectionFailedCount = () => {
    if (this.props.checklistInstance.inspection_failed_count > 0) {
      let classNames = [styles['count-container'], styles['inspection']];
      return (
        <div className={classNames.join(' ')}>
          <div className={styles['text']}>{this.props.checklistInstance.inspection_failed_count}</div>
          <div>
            <Icon size={12} solid type="info-circle" red />
          </div>
        </div>
      );
    }
    return null;
  };

  renderCommentCount = () => {
    if (this.props.checklistInstance.comment_count > 0) {
      let classNames = [styles['count-container'], styles['comment']];
      return (
        <div className={classNames.join(' ')}>
          <div className={styles['text']}>{this.props.checklistInstance.comment_count}</div>
          <div>
            <Icon size={12} solid type="comment" blue />
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { inspection_failed_count, comment_count } = this.props.checklistInstance;
    if (inspection_failed_count === 0 && comment_count === 0) {
      return null;
    }
    return (
      <div className={styles['counts']}>
        {this.renderCommentCount()}
        {this.renderInspectionFailedCount()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistInstance: EntitySelectors.getChecklistInstance(state, ownProps.checklistInstanceId),
  };
}

export default connect(mapStateToProps)(ChecklistInstanceListItem);
