import types from './types';

const resetSelectedCosts = payload => ({
  type: types.RESET_SELECTED_COSTS,
  payload,
});
const selectCost = payload => ({ type: types.SELECT_COST, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchCosts = () => ({ type: types.FETCH_COSTS });

const fetchCostsSuccess = payload => ({
  type: types.FETCH_COSTS_SUCCESS,
  payload,
});

export default {
  selectFilterType,
  showAppliedFilters,
  showAvailableFilters,
  addFilter,
  removeFilter,
  resetFilter,
  resetSelectedCosts,
  selectPage,
  selectCost,
  selectTotalEntries,
  hideSelectTotalEntries,
  addQueryParameter,
  fetchCosts,
  fetchCostsSuccess,
};
