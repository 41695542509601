import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { MaxUploadSize } from 'sdk/Attachment';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions } from 'sdk';
import { Button, Icon } from 'views/components/Shared/General';
import UploadSmall from 'assets/images/UploadSmall.png';
import styles from './style.module.scss';

class DropZone extends Component {
  state = {
    showSingleFileToBigError: false,
    showToManyFilesError: false,
  };

  renderError = () => {
    const { showSingleFileToBigError, showToManyFilesError } = this.state;
    if (showSingleFileToBigError) {
      return (
        <React.Fragment>
          <div className={styles['error']}>
            <Icon red type="exclamation-circle" />
            <p>
              <FormattedMessage id="components.dropzone.max-size-error" />
            </p>
          </div>
          <Button
            primary
            small
            label="general.close"
            onClick={() => this.setState({ showSingleFileToBigError: false, showToManyFilesError: false })}
          />
        </React.Fragment>
      );
    }
    if (showToManyFilesError) {
      return (
        <React.Fragment>
          <div className={styles['error']}>
            <Icon red type="exclamation-circle" />
            <p>
              <FormattedMessage id="components.dropzone.to-many-files-error" />
            </p>
          </div>
          <Button
            primary
            small
            label="general.close"
            onClick={() => this.setState({ showSingleFileToBigError: false, showToManyFilesError: false })}
          />
        </React.Fragment>
      );
    }
  };

  renderDropFileContent = () => (
    <React.Fragment>
      {this.props.folderId == null ? (
        <FormattedMessage id="components.dropzone.drag-file-text" />
      ) : (
        <FormattedMessage
          id="components.dropzone.drag-file-to-folder-text"
          values={{ folder: this.props.attachmentFolder.title }}
        />
      )}
      <p className={styles['max-size']}>
        <FormattedMessage id="components.dropzone.max-size" />
      </p>
    </React.Fragment>
  );

  render() {
    const { showSingleFileToBigError, showToManyFilesError } = this.state;
    if (!this.props.active) {
      return this.props.children;
    }
    let classNames = [styles['dropzone-wrapper'], this.props.className];
    return (
      <Dropzone
        noClick
        onDrop={files => {
          if (files.length == 1 && files[0].size > MaxUploadSize) {
            this.setState({ showSingleFileToBigError: true });
            return;
          }
          if (files.length > 10) {
            this.setState({ showToManyFilesError: true, showSingleFileToBigError: false });
            return;
          } else {
            this.setState({ showToManyFilesError: false, showSingleFileToBigError: false });
          }
          files
            .filter(({ size }) => size <= MaxUploadSize)
            .forEach(file => {
              this.props.onDrop({
                type: 'file',
                attachment_folder_id: this.props.folderId,
                file,
                mime_type: file.type,
                title: file.name,
                extension: HelperFunctions.getExtension(file.name),
              });
            });
        }}
      >
        {({ getRootProps, isDragActive }) => {
          return (
            <div {...getRootProps()} className={classNames.join(' ')}>
              {isDragActive || showToManyFilesError || showSingleFileToBigError ? (
                <div className={styles['file-overlay-container']}>
                  <div className={styles['file-overlay']} />
                  <div className={styles['content']}>
                    <div className={styles['image']}>
                      <img src={UploadSmall} alt="" />
                    </div>
                    {(showToManyFilesError || showSingleFileToBigError) && !isDragActive
                      ? this.renderError()
                      : this.renderDropFileContent()}
                  </div>
                </div>
              ) : null}
              {this.props.children}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentFolder: EntitySelectors.getAttachmentFolder(state, ownProps.folderId),
  };
}

export default connect(mapStateToProps)(DropZone);

DropZone.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.any,
};
DropZone.defaultProps = {
  active: true,
  className: [],
};
