import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, Icon, ElapsedTime } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { Field } from 'views/components/WorkOrder';
import { WorkOrderCategoryType } from 'sdk/System';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import DueDate from './DueDate';
import Meter from './Meter';
import Recurring from './Recurring';
import styles from './style.module.scss';

class MetaInfoContainer extends Component {
  renderAssigneeFieldIconContainer = () => {
    const userLength = this.props.userAssignees.length;
    const groupLength = this.props.groupAssignees.length;
    const vendorLength = this.props.vendorAssignees.length;

    if (vendorLength > 0) {
      return <Icon type="briefcase" withBackground blue backgroundSize={36} />;
    } else if (groupLength > 0) {
      return <Icon type="users" withBackground blue backgroundSize={36} />;
    } else if (userLength === 1) {
      return (
        <ProfilePicture
          size={34}
          userId={this.props.userAssignees[0].id}
          defaultElement={<Icon type="user" withBackground blue backgroundSize={36} />}
        />
      );
    } else {
      return <Icon type="user" withBackground blue backgroundSize={36} />;
    }
  };

  renderAssignee = () => {
    if (this.props.workOrder.completed_before_registration) {
      return null;
    }
    const userValue = this.props.userAssignees.map(assignee => <UserNameWrapper user={assignee} />);
    const groupValue = this.props.groupAssignees.map(assignee => assignee.title);
    const vendorValue = this.props.vendorAssignees.map(assignee => (
      <Link to={`/vendors/${assignee.id}`}>{assignee.name}</Link>
    ));

    const assigneeValue = [...userValue, ...groupValue, ...vendorValue];

    return (
      <div className={styles['row']}>
        <Field
          icon={this.renderAssigneeFieldIconContainer()}
          label={<FormattedMessage id="resources.work-order.assignee" />}
          value={
            assigneeValue.length === 0 ? (
              <>{'-'}</>
            ) : (
              assigneeValue.map((val, i, arr) => (
                <span>
                  {val}
                  {arr.length - 1 !== i ? ', ' : null}
                </span>
              ))
            )
          }
        />
      </div>
    );
  };

  renderPriority = () => {
    switch (this.props.workOrder.priority) {
      case 'low':
        return (
          <>
            <div className={`${styles['priority']} ${styles['low']}`}>
              <FormattedMessage id="resources.work-order.prio.low" />
            </div>
          </>
        );
      case 'medium':
        return (
          <>
            <div className={`${styles['priority']} ${styles['medium']}`}>
              <FormattedMessage id="resources.work-order.prio.medium" />
            </div>
          </>
        );
      case 'high':
        return (
          <>
            <div className={`${styles['priority']} ${styles['high']}`}>
              <FormattedMessage id="resources.work-order.prio.high" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  renderCategory = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    let className = [styles['category']];
    if (this.props.workOrder.priority) {
      className = [...className, styles['has-priority']];
    }
    switch (this.props.workOrder.category) {
      case WorkOrderCategory.Corrective: {
        return (
          <div className={className.join(' ')}>
            <FormattedMessage id="resources.work-order.categories.corrective" />
          </div>
        );
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <div className={className.join(' ')}>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </div>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <div className={className.join(' ')}>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </div>
        );
      }
      case WorkOrderCategory.Preventive: {
        return (
          <div className={className.join(' ')}>
            <FormattedMessage id="resources.work-order.categories.preventive" />
          </div>
        );
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <div className={className.join(' ')}>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </div>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <div className={className.join(' ')}>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </div>
        );
      }
      case WorkOrderCategory.Improvement: {
        return (
          <div className={className.join(' ')}>
            <FormattedMessage id="resources.work-order.categories.improvement" />
          </div>
        );
      }
      case WorkOrderCategory.Modification: {
        return (
          <div className={className.join(' ')}>
            <FormattedMessage id="resources.work-order.categories.modification" />
          </div>
        );
      }
      default:
        return null;
    }
  };

  renderEstimatedTime = () => {
    if (this.props.workOrder.estimated_minutes) {
      return (
        <div className={`${styles['row']} ${styles['estimated-time']}`}>
          <Field
            icon={<Icon type="clock" withBackground orange backgroundSize={36} />}
            label={<FormattedMessage id="resources.work-order.estimated-time" />}
            value={<ElapsedTime elapsedMinutes={this.props.workOrder.estimated_minutes} />}
          />
        </div>
      );
    }
    return null;
  };

  renderMeter = () => {
    return <Meter workOrder={this.props.workOrder} />;
  };

  renderRecurring = () => {
    return <Recurring workOrder={this.props.workOrder} />;
  };

  renderDueDate = () => {
    if (this.props.workOrder.completed_before_registration) {
      return null;
    }
    return <DueDate workOrder={this.props.workOrder} />;
  };

  renderAssetValue = () => {
    if (this.props.canViewAssets) {
      return (
        <>
          <Link to={`/assets/${this.props.asset.id}`}>
            <AssetTitle id={this.props.asset.id} />
          </Link>
          {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
            <div className={styles['tree-path-container']}>
              <TreePath assetId={this.props.asset.id} />
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <AssetTitle id={this.props.asset.id} />
        {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
          <div className={styles['tree-path-container']}>
            <TreePath assetId={this.props.asset.id} />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <CardGrid.Row>
        <WhiteCard noPadding>
          <div className={styles['meta']}>
            {this.renderCategory()}
            {this.renderPriority()}
            <div>
              {this.renderDueDate()}
              {this.renderAssignee()}
              {this.renderEstimatedTime()}
              {this.props.asset == null ? null : (
                <div className={styles['row']}>
                  <Field
                    icon={<Icon type="box-alt" withBackground backgroundSize={36} />}
                    label={<FormattedMessage id="resources.work-order.asset" />}
                    value={this.renderAssetValue()}
                  />
                </div>
              )}
              {this.props.workOrderType == null ? null : (
                <div className={styles['row']}>
                  <Field
                    icon={<Icon type="tag" withBackground backgroundSize={36} />}
                    label={<FormattedMessage id="resources.work-order.work-order-type" />}
                    value={this.props.workOrderType.title}
                  />
                </div>
              )}
              {this.renderRecurring()}
            </div>
          </div>
        </WhiteCard>
        {this.renderMeter()}
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;
  return {
    asset: EntitySelectors.getAsset(state, workOrder.asset_id),
    workOrderType: EntitySelectors.getWorkOrderType(state, workOrder.work_order_type_id),
    userAssignees: EntitySelectors.getUsers(state, workOrder.assigned_to_users || []),
    groupAssignees: EntitySelectors.getGroups(state, workOrder.assigned_to_groups || []),
    vendorAssignees: EntitySelectors.getVendors(state, workOrder.assigned_to_vendors || []),
    settings: AuthSelectors.getSettings(state),
    canViewAssets: AuthSelectors.canViewAssets(state),
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(MetaInfoContainer)));
