import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Header as LayoutHeader, Modal } from 'views/components/Shared/Layout';
import { Button, Field, Icon } from 'views/components/Shared/General';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import { HelperFunctions } from 'sdk';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingTitle: this.props.workOrder.title,
      staticTitle: this.props.workOrder.title,
    };
  }

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/work-orders',
      search: HelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.work-order.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem to={`/work-orders/${this.props.match.params.id}`}>
          <LayoutHeader.BreadcrumbItem>{this.state.staticTitle}</LayoutHeader.BreadcrumbItem>
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>
          <FormattedMessage id="general.edit" />
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTitleModal = () => {
    return (
      <Modal width={460} isOpen={this.state.showTitleModal}>
        <Modal.Header
          title={<FormattedMessage id="general.change-title" />}
          subtitleTopMargin
          onClose={() =>
            this.setState({ showTitleModal: false, editingTitle: this.props.editingWorkOrder.title })
          }
        />
        <Modal.Content>
          <Field>
            <Field.Text
              autoFocus
              value={this.state.editingTitle}
              onChange={title => {
                this.setState({ editingTitle: title });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              onClick={() => {
                this.props.setEditingWorkOrderValues({ title: this.state.editingTitle });
                this.setState({
                  staticTitle: this.state.editingTitle,
                  showTitleModal: false,
                });
              }}
              disabled={!this.state.editingTitle}
            />
            <Button
              label="general.cancel"
              onClick={() =>
                this.setState({
                  showTitleModal: false,
                  editingTitle: this.props.editingWorkOrder.title,
                })
              }
            />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    return (
      <>
        {this.renderTitleModal()}
        <LayoutHeader
          subtitle={
            recurringMaintenanceId && this.props.recurringMaintenance.status === 'active' ? (
              <FormattedMessage id="screens.work-order.edit-work-orders-future-title" />
            ) : (
              <FormattedMessage id="screens.work-order.edit-title" />
            )
          }
          title={`${this.state.staticTitle}`}
          editTitleButton={
            <Button
              type="icon"
              icon={<Icon regular type="pen" />}
              onClick={() => {
                this.setState({ showTitleModal: true });
              }}
            />
          }
          breadcrumbComponent={this.renderBreadcrumb()}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  return {
    workOrder,
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
    editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
    navigatedTo: WorkOrdersSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
