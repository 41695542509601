import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Decimal } from 'decimal.js';
import { EntitySelectors } from 'sdk/State/entities';
import { List, MoneyWithCurrency } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';

class PurchaseOrderRowListItem extends Component {
  getPurchaseOrderRowSummary = () => {
    const quantity = new Decimal(this.props.purchaseOrderRow.quantity || 0);
    const price = new Decimal(this.props.purchaseOrderRow.price || 0);
    return quantity.times(price);
  };

  renderOrderedQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.unit}
          value={this.props.purchaseOrderRow.quantity}
        />
      );
    }
  };

  renderDeliveredQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.delivered_quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.unit}
          value={this.props.purchaseOrderRow.delivered_quantity}
        />
      );
    }
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.unit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  renderActiveDeliveryListItem = () => {
    return (
      <List.Item small>
        <List.Item.Column width={100}>{this.props.purchaseOrderRow.number || '-'}</List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.purchaseOrderRow.title}
          subtitle={this.renderPackageQuantity()}
        />
        <List.Item.Column width={150} alignRight>
          {this.renderOrderedQuantity()}
        </List.Item.Column>
        <List.Item.Column width={150} alignRight>
          {this.renderDeliveredQuantity()}
        </List.Item.Column>
        <List.Item.Column width={150} alignRight>
          <MoneyWithCurrency
            value={this.getPurchaseOrderRowSummary()}
            currency={this.props.purchaseOrder.currency}
          />
        </List.Item.Column>
      </List.Item>
    );
  };

  renderPurchaseOrderRowListItem = () => {
    return (
      <List.Item small>
        <List.Item.Column width={100}>{this.props.purchaseOrderRow.number || '-'}</List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.purchaseOrderRow.title}
          subtitle={this.renderPackageQuantity()}
        />
        <List.Item.Column width={150} alignRight>
          {this.renderOrderedQuantity()}
        </List.Item.Column>
        <List.Item.Column width={150} alignRight>
          <MoneyWithCurrency
            value={this.getPurchaseOrderRowSummary()}
            currency={this.props.purchaseOrder.currency}
          />
        </List.Item.Column>
      </List.Item>
    );
  };

  render() {
    if (this.props.list === 'awaiting_delivery') {
      return this.renderActiveDeliveryListItem();
    }

    return this.renderPurchaseOrderRowListItem();
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePart: EntitySelectors.getSparePart(state, ownProps.purchaseOrderRow.spare_part_id),
    unit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(PurchaseOrderRowListItem);
