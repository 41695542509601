const DOMAIN_PREFIX = 'newRequest/';

const CANCEL_NEW_REQUEST = `${DOMAIN_PREFIX}cancel_new_request`;
const PREPARE_NEW_REQUEST = `${DOMAIN_PREFIX}prepare_new_request`;
const SET_EDITING_REQUEST_VALUES = `${DOMAIN_PREFIX}set_editing_request_values`;

export default {
  CANCEL_NEW_REQUEST,
  PREPARE_NEW_REQUEST,
  SET_EDITING_REQUEST_VALUES,
};
