import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { List, Button, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import Droppable from 'views/components/General/Droppable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import styles from './style.module.scss';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeSparePartType } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import EditSparePartTypeModal from './EditSparePartTypeModal';
import DeleteSparePartTypeModal from './DeleteSparePartTypeModal';
import DragLayer from './DragLayer';
import DraggableListItem from './DraggableListItem';

class SparePartTypeModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    sparePartTypes: [],
    isDragging: false,
    isDraggingSparePartTypeId: null,
    showEditSparePartTypeModal: false,
    showEditSparePartTypeModalForSparePartTypeId: null,
    showDeleteSparePartTypeModal: false,
    showDeleteSparePartTypeModalForSparePartTypeId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isFetching: true });
      this.fetchSparePartTypes();
    }
  }

  fetchSparePartTypes = () => {
    let params = {
      no_pagination: true,
      spare_part_count: true,
    };

    return API.listSparePartTypes(this.props.currentSystem.id, params)
      .then(({ data: sparePartTypes }) => {
        const { entities, result: sparePartTypeIds } = normalizeSparePartType(sparePartTypes);
        this.props.updateEntities(entities);

        this.setState({
          sparePartTypes,
          sparePartTypeIds,
          isFetching: false,
        });
      })
      .catch(() => {});
  };

  handleOnDragStart = sparePartTypeId => {
    this.setState({ isDragging: true, isDraggingSparePartTypeId: sparePartTypeId });
  };

  handleOnDragEnd = () => {
    this.setState({ isDragging: false, isDraggingSparePartTypeId: null });
  };

  handleOnDrop = (droppedSparePartTypeId, targetSparePartType) => {
    const updatedSparePartTypes = this.state.sparePartTypes.map(spt => {
      let targetParentId = targetSparePartType ? targetSparePartType.id : null;
      return spt.id === droppedSparePartTypeId ? { ...spt, parent_id: targetParentId } : spt;
    });
    toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
    this.setState({ sparePartTypes: updatedSparePartTypes });
    this.props.updateSparePartType(droppedSparePartTypeId, {
      parent_id: targetSparePartType ? targetSparePartType.id : null,
    });
  };

  renderChildren = (sparePartType, parentIds) => {
    const sparePartTypesForSelectdSparePartType = this.state.sparePartTypes
      .filter(spt => {
        return spt.parent_id === sparePartType.id;
      })
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    return (
      <div className={styles['list']}>
        {sparePartTypesForSelectdSparePartType.map(sparePartType =>
          this.renderListItem(sparePartType, [...parentIds, sparePartType.parent_id])
        )}
      </div>
    );
  };

  renderListItem = (sparePartType, parentIds) => {
    return (
      <DraggableListItem
        key={sparePartType.id}
        sparePartType={sparePartType}
        parentId={sparePartType.id}
        parentIds={parentIds}
        expandableChildren={this.renderChildren(sparePartType, parentIds)}
        isDraggingSparePartTypeId={this.state.isDraggingSparePartTypeId}
        sparePartTypes={this.state.sparePartTypes}
        onDragStart={this.handleOnDragStart}
        onDragEnd={this.handleOnDragEnd}
        onDrop={this.handleOnDrop}
        onEdit={id =>
          this.setState({
            showEditSparePartTypeModal: true,
            showEditSparePartTypeModalForSparePartTypeId: id,
          })
        }
      />
    );
  };

  renderTopLevelDroppable = () => {
    if (this.state.isDragging === false) {
      return null;
    }
    let classNames = [styles['top-level-droppable']];
    if (this.state.isHoveringTopLevel) {
      classNames = [...classNames, styles['hovering']];
    }
    return (
      <Droppable
        accept={'spare-part-type'}
        onHover={() => this.setState({ isHoveringTopLevel: true })}
        onHoverEnd={() => this.setState({ isHoveringTopLevel: false })}
        onDrop={() => this.handleOnDrop(this.state.isDraggingSparePartTypeId, null)}
      >
        <div className={classNames.join(' ')}>
          <FormattedMessage id="components.spare-part-type-modal.drop-on-root-level-placeholder" />
        </div>
      </Droppable>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column width={10} />
        <List.Header.Column flex>
          <FormattedMessage id="resources.checklist-template.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderListHeader()}
          <List>
            <DraggableListItem loading />
            <DraggableListItem loading />
          </List>
        </>
      );
    }
    const rootSparePartTypes = this.state.sparePartTypes
      .filter(spt => {
        return spt.parent_id == null;
      })
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    if (rootSparePartTypes.length === 0) {
      return (
        <>
          {this.renderCreateButton()}
          <WhiteCard centerContent>
            <EmptyDataSet
              title={<FormattedMessage id="components.spare-part-type-modal.empty-data-set.title" />}
              subtitle={<FormattedMessage id="components.spare-part-type-modal.empty-data-set.subtitle" />}
              tiny
              inlineModalContainer
            />
          </WhiteCard>
        </>
      );
    }
    return (
      <>
        {this.renderCreateButton()}
        {this.renderListHeader()}
        <div className={styles['list']}>
          {rootSparePartTypes.map((sparePartType, index) => this.renderListItem(sparePartType, []))}
        </div>
      </>
    );
  };

  renderCreateButton = () => {
    return (
      <div className={styles['toolbar']}>
        <Button
          primary
          label="components.spare-part-type-modal.create-button"
          onClick={() =>
            this.setState({
              showEditSparePartTypeModal: true,
              showEditSparePartTypeModalForSparePartTypeId: null,
            })
          }
        />
        {this.renderTopLevelDroppable()}
      </div>
    );
  };

  renderEditSparePartTypeModal = () => {
    return (
      <EditSparePartTypeModal
        open={this.state.showEditSparePartTypeModal}
        id={this.state.showEditSparePartTypeModalForSparePartTypeId}
        onDelete={sparePartTypeId => {
          this.setState({
            showEditSparePartTypeModal: false,
          });
          setTimeout(() => {
            this.setState({
              showDeleteSparePartTypeModalForSparePartTypeId: sparePartTypeId,
              showDeleteSparePartTypeModal: true,
            });
          }, 250);
        }}
        onSave={sparePartType => {
          if (this.state.sparePartTypes.find(({ id }) => id === sparePartType.id) == null) {
            this.setState({
              showEditSparePartTypeModal: false,
              sparePartTypes: [...this.state.sparePartTypes, { ...sparePartType }],
            });
          } else {
            const updatedSparePartTypes = this.state.sparePartTypes.map(spt => {
              return spt.id === sparePartType.id ? { ...spt, ...sparePartType } : spt;
            });
            this.setState({ showEditSparePartTypeModal: false, sparePartTypes: updatedSparePartTypes });
          }
        }}
        onClose={() => this.setState({ showEditSparePartTypeModal: false })}
      />
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteSparePartTypeModal
        open={this.state.showDeleteSparePartTypeModal}
        id={this.state.showDeleteSparePartTypeModalForSparePartTypeId}
        onDelete={id => {
          this.setState({
            sparePartTypes: [...this.state.sparePartTypes.filter(spt => spt.id !== id)],
            showDeleteSparePartTypeModal: false,
          });
        }}
        onClose={() =>
          this.setState({
            showDeleteSparePartTypeModal: false,
          })
        }
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={850} fullHeight>
          <Modal.Header
            title={<FormattedMessage id="components.spare-part-type-modal.title" />}
            subtitle={this.props.subtitle}
            onClose={this.props.onClose}
          />
          <Modal.Content grayBackground>{this.renderList()}</Modal.Content>
          <DragLayer isDraggingSparePartTypeId={this.state.isDraggingSparePartTypeId} />
        </Modal>
        {this.renderEditSparePartTypeModal()}
        {this.renderDeleteModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateSparePartType: SDKReduxOperations.updateSparePartType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartTypeModal);
