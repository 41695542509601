import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { EntitySelectors } from 'sdk/State/entities';
import { Type, generateEmptyChecklistTemplateRow, MeterType } from 'sdk/ChecklistTemplateRow';
import { MenuOperations } from 'state/ducks/menu';
import { SDKReduxOperations } from 'sdk';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { Inspection, Section, Meter } from './Fields';
import styles from './style.module.scss';

const cardSource = {
  beginDrag(props) {
    props.beginDrag(props.id);
    return {
      type: props.checklistTemplateRow.type,
      parent: props.parent,
      id: props.id,
      index: props.index,
    };
  },
  endDrag(props) {
    props.endDrag();
  },
};

function cardCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const dropTarget = {
  drop(props, monitor, component) {
    if (!monitor.isOver()) {
      return;
    }
    const draggingId = monitor.getItem().id;
    const draggingType = monitor.getItem().type;
    const dragIndex = monitor.getItem().index;
    let hoverIndex = props.index;
    const draggedFromSectionId = monitor.getItem().parent;
    const draggedToSectionId = props.parent;
    /*
      Dragged and dropped on same item
    */
    if (dragIndex === hoverIndex && draggedFromSectionId === draggedToSectionId) {
      return;
    }

    const isNew = monitor.getItem().id === 'new';

    /*
      --------
        Calculate mouse position relative to hovered element
      --------
    */

    const hoverBoundingRect = component.dropRef.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    const draggingUpwards = dragIndex > hoverIndex;
    const draggingDownwards = dragIndex < hoverIndex;
    const draggedInSameLevel = draggedFromSectionId === draggedToSectionId;

    /*
      --------
        If dragged parent / child item but below or above its own line.
      --------
    */

    if (
      draggingDownwards &&
      draggedInSameLevel &&
      hoverIndex - dragIndex === 1 &&
      hoverClientY < hoverMiddleY
    ) {
      return;
    }
    if (
      draggingUpwards &&
      draggedInSameLevel &&
      dragIndex - hoverIndex === 1 &&
      hoverClientY > hoverMiddleY
    ) {
      return;
    }

    if (draggingType === Type.Section && props.parent) {
      props.showSectionCantBeDroppedInModal();
      return;
    }

    if (draggedInSameLevel) {
      if (draggingDownwards && hoverClientY < hoverMiddleY) {
        hoverIndex--;
      }
      if (draggingUpwards && hoverClientY > hoverMiddleY) {
        hoverIndex++;
      }
    } else {
      if (hoverClientY < hoverMiddleY) {
        hoverIndex = hoverIndex === 0 ? 0 : hoverIndex--;
      } else if (hoverClientY > hoverMiddleY) {
        hoverIndex++;
      }
    }

    /*
      --------
        If dragged out new field
      --------
    */
    let meterType = MeterType.Option;
    if (props.isCreatingChecklistFromTemplatesLibrary === false) {
      meterType = MeterType.Fixed;
    }
    if (isNew) {
      props.prepareNewFieldForEdit({
        ...generateEmptyChecklistTemplateRow(),
        meter_type: meterType,
        type: monitor.getItem().type,
        id: draggingId,
        sort: hoverIndex + 1,
        parent_row_id: draggedToSectionId,
      });
      if (draggedToSectionId) {
        props.addNewFieldToSection({
          toSectionId: draggedToSectionId,
          id: draggingId,
          index: hoverIndex,
        });
      } else {
        props.addNewFieldToRoot({
          id: draggingId,
          toIndex: hoverIndex,
        });
      }
      return;
    }

    /*
      --------
        If sorted root fields
      --------
    */

    if (draggedFromSectionId == null && draggedToSectionId == null) {
      props.sortFieldOutsideSection({
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: hoverIndex,
      });
      props.updateChecklistTemplateRow(draggingId, { sort: hoverIndex + 1 });
      return;
    }

    /*
      --------
        If sorted fields in section
      --------
    */
    if (draggedFromSectionId === draggedToSectionId) {
      props.sortFieldInSection({
        sectionId: draggedFromSectionId,
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: hoverIndex,
      });
      props.updateChecklistTemplateRow(draggingId, { sort: hoverIndex + 1 });
      return;
    }

    /*
      --------
        If dragged root field into section
      --------
    */

    if (draggedFromSectionId == null && draggedToSectionId) {
      if (monitor.getItem().parent === props.id) {
        return;
      }
      props.moveFieldFromRootToSection({
        sectionId: draggedToSectionId,
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: hoverIndex,
      });
      props.updateChecklistTemplateRow(draggingId, {
        parent_row_id: draggedToSectionId,
        sort: hoverIndex + 1,
      });
      return;
    }

    /*
      --------
        If dragged field from section to root
      --------
    */
    if (draggedFromSectionId && draggedToSectionId == null) {
      props.moveFieldFromSectionToRoot({
        sectionId: draggedFromSectionId,
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: hoverIndex,
      });
      props.updateChecklistTemplateRow(draggingId, { parent_row_id: null, sort: hoverIndex + 1 });
      return;
    }

    /*
      --------
        If dragged fields between sections
      --------
    */
    props.moveFieldsBetweenSections({
      fromSectionId: draggedFromSectionId,
      toSectionId: draggedToSectionId,
      id: monitor.getItem().id,
      fromIndex: dragIndex,
      toIndex: hoverIndex,
    });
    props.updateChecklistTemplateRow(draggingId, { parent_row_id: draggedToSectionId, sort: hoverIndex + 1 });
  },
  hover(props, monitor, component) {
    if (!monitor.isOver({ shallow: true })) {
      return;
    }
    const draggingId = monitor.getItem().id;
    const dragIndex = monitor.getItem().index;
    const draggingType = monitor.getItem().type;
    const hoverIndex = props.index;
    const draggedFromSectionId = monitor.getItem().parent;
    const draggedToSectionId = props.parent;
    if (dragIndex === hoverIndex && draggedFromSectionId === draggedToSectionId) {
      return;
    }
    if (draggingId === props.parent) {
      return;
    }
    const hoverBoundingRect = component.dropRef.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (draggingType === Type.Section && props.parent) {
      return;
    }
    /*
      ------
        Reset hover when between items to prevent flickering
      ------
    */
    if (hoverClientY === hoverBoundingRect.height || hoverClientY <= 0) {
      return;
    }
    if (hoverClientY > hoverMiddleY) {
      component.showBottomBorder();
    } else if (hoverClientY < hoverMiddleY) {
      component.showTopBorder();
    }
  },
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

class ChecklistTemplateRow extends Component {
  state = {
    isOver: false,
  };

  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOver && this.props.isOver) {
      this.setState({ isOver: true, showTopBorder: false, showBottomBorder: false });
    } else if (prevProps.isOver && !this.props.isOver) {
      this.setState({ isOver: false });
    }
  }

  showBottomBorder = () => {
    if (this.state.showBottomBorder) {
      return;
    }
    this.setState({ showBottomBorder: true, showTopBorder: false });
  };

  showTopBorder = () => {
    if (this.state.showTopBorder) {
      return;
    }
    this.setState({ showBottomBorder: false, showTopBorder: true });
  };

  renderInspectionRow = () => {
    let classNames = [styles['row']];
    if (this.props.isOver) {
      if (this.state.showBottomBorder) {
        classNames = [...classNames, styles['show-bottom-border']];
      }
      if (this.state.showTopBorder) {
        classNames = [...classNames, styles['show-top-border']];
      }
    }
    return (
      <div className={classNames.join(' ')} ref={ref => (this.dropRef = ref)}>
        <Inspection {...this.props} />
      </div>
    );
  };

  renderMeterRow = () => {
    let classNames = [styles['row']];
    if (this.props.isOver) {
      if (this.state.showBottomBorder) {
        classNames = [...classNames, styles['show-bottom-border']];
      }
      if (this.state.showTopBorder) {
        classNames = [...classNames, styles['show-top-border']];
      }
    }
    return (
      <div className={classNames.join(' ')} ref={ref => (this.dropRef = ref)}>
        <Meter {...this.props} />
      </div>
    );
  };

  renderSectionRow = () => {
    let classNames = [styles['row']];
    if (this.props.isOver) {
      if (this.state.showBottomBorder) {
        classNames = [...classNames, styles['show-bottom-border']];
      }
      if (this.state.showTopBorder) {
        classNames = [...classNames, styles['show-top-border']];
      }
    }
    return (
      <div className={classNames.join(' ')} ref={ref => (this.dropRef = ref)}>
        <Section {...this.props} />
      </div>
    );
  };

  render() {
    const { type } = this.props;
    if (type === Type.Inspection) {
      return this.renderInspectionRow();
    } else if (type === Type.Meter) {
      return this.renderMeterRow();
    } else if (type === Type.Section) {
      return this.renderSectionRow();
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      sortFieldInSection: ChecklistEditModalOperations.sortFieldInSection,
      sortFieldOutsideSection: ChecklistEditModalOperations.sortFieldOutsideSection,
      moveFieldFromRootToSection: ChecklistEditModalOperations.moveFieldFromRootToSection,
      moveFieldFromSectionToRoot: ChecklistEditModalOperations.moveFieldFromSectionToRoot,
      beginDrag: ChecklistEditModalOperations.beginDrag,
      endDrag: ChecklistEditModalOperations.endDrag,
      moveFieldsBetweenSections: ChecklistEditModalOperations.moveFieldsBetweenSections,
      editField: ChecklistEditModalOperations.editField,
      addNewFieldToSection: ChecklistEditModalOperations.addNewFieldToSection,
      prepareNewFieldForEdit: ChecklistEditModalOperations.prepareNewFieldForEdit,
      addNewFieldToRoot: ChecklistEditModalOperations.addNewFieldToRoot,
      hoverIndex: ChecklistEditModalOperations.hoverIndex,
      cancelEditField: ChecklistEditModalOperations.cancelEditField,
      updateChecklistTemplateRow: SDKReduxOperations.updateChecklistTemplateRow,
      showSectionCantBeDroppedInModal: ChecklistEditModalOperations.showSectionCantBeDroppedInModal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
    draggingChecklistTemplateRow: ChecklistEditModalSelectors.getDraggingChecklistTemplateRow(state),
    editingField: ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state),
    isCreatingChecklistFromTemplatesLibrary:
      ChecklistEditModalSelectors.isCreatingChecklistFromTemplatesLibrary(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  DragSource(
    'checklist',
    cardSource,
    cardCollect
  )(DropTarget('checklist', dropTarget, dropCollect)(ChecklistTemplateRow))
);
