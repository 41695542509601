import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from 'prop-types';

const FullScreenImagePicker = ({ open, images, currentImage, onChangeImage, onClose }) => {
  const getImageSrc = () => {
    let imageSrc = [];
    images.map(
      image =>
        (imageSrc = [...imageSrc, { source: `${process.env.REACT_APP_BROWSER_URL}images/${image.id}` }])
    );
    return imageSrc;
  };

  return (
    <ModalGateway>
      {!open ? null : (
        <Modal
          onClose={e => {
            e.stopPropagation();
            onClose();
          }}
          styles={{
            blanket: (base, state) => ({ ...base, zIndex: 11 }),
            positioner: (base, state) => ({ ...base, zIndex: 11 }),
            dialog: (base, state) => ({ ...base, zIndex: 12 }),
          }}
        >
          <Carousel views={getImageSrc()} currentIndex={currentImage} />
        </Modal>
      )}
    </ModalGateway>
  );
};

export default FullScreenImagePicker;

FullScreenImagePicker.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.array,
  currentImage: PropTypes.number,
  onChangeImage: PropTypes.func,
  onClose: PropTypes.func,
};

FullScreenImagePicker.defaultProps = {
  images: [],
  open: false,
  currentImage: 0,
  onChangeImage: () => null,
  onClose: () => null,
};
