import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import RequestItem from './RequestItem';

class Requests extends Component {
  render() {
    return (
      <>
        <List.Header small background paddingRight={77}>
          <List.Header.Column flex>
            {/* TODO: använd denna translation som är "Rubrik" på svenska, eller skapa en "Titel"? */}
            <FormattedMessage id="resources.request.title" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.requests.map(request => (
            <RequestItem request={request} onRemoveFromRequest={this.props.onRemoveFromRequest} />
          ))}
        </List>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    requests: EntitySelectors.getRequests(state, downtime.requests),
  };
}

export default connect(mapStateToProps)(Requests);
