import types from './types';

const setEditingRequestValues = data => ({
  type: types.SET_EDITING_REQUEST_VALUES,
  payload: data,
});

const prepareNewRequest = payload => ({
  type: types.PREPARE_NEW_REQUEST,
  payload,
});

const cancelNewRequest = payload => ({
  type: types.CANCEL_NEW_REQUEST,
  payload,
});

export default {
  setEditingRequestValues,
  prepareNewRequest,
  cancelNewRequest,
};
