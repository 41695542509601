import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import types from './types';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.QR_TEMPLATE_COLUMN_CREATED: {
      const { qrTemplateId, data: qrTemplateColumn } = action.payload;
      if (state[qrTemplateId]) {
        return {
          ...state,
          [qrTemplateId]: {
            ...state[qrTemplateId],
            qr_template_columns: [...state[qrTemplateId].qr_template_columns, qrTemplateColumn.id],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.QR_TEMPLATE_COLUMN_DELETED: {
      const { qrTemplateColumnId, data } = action.payload;
      const qrTemplateId = data.qr_template_id;
      if (state[qrTemplateId]) {
        return {
          ...state,
          [qrTemplateId]: {
            ...state[qrTemplateId],
            qr_template_columns: state[qrTemplateId].qr_template_columns.filter(
              id => id !== qrTemplateColumnId
            ),
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.qrTemplateById) {
        return HelperFunctions.mergeEntities(state, action.payload.qrTemplateById);
      }
      return state;
    default:
      return state;
  }
};
