import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class DebitToSystemDropdown extends Component {
  renderSelectedSystem = () => {
    if (this.props.system) {
      return this.props.system.name;
    }
    return null;
  };

  render() {
    return (
      <Field.Dropdown
        error={this.props.showRequiredError}
        clearable={false}
        value={this.renderSelectedSystem()}
      >
        {this.props.systems.map(system => (
          <Field.Dropdown.Item
            selected={this.props.system && this.props.system.id === system.id}
            onClick={() => this.props.onChange(system.id)}
          >
            {system.name}
          </Field.Dropdown.Item>
        ))}
      </Field.Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { systemIds, selectedSystemId } = ownProps;
  return {
    system: EntitySelectors.getSystem(state, selectedSystemId),
    systems: EntitySelectors.getSystems(state, systemIds),
  };
}

export default connect(mapStateToProps)(DebitToSystemDropdown);
