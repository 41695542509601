import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Icon, Button } from 'views/components/Shared/General';
import { TreePath, ChooseAssetInlineModal } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

class SelectedAssetContainer extends PureComponent {
  renderSelectedAssetImage = () => {
    if (this.props.asset.images && this.props.asset.images.length > 0) {
      return (
        <div
          className={styles['image-container']}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.asset.images[0]})`,
          }}
        />
      );
    }
    return (
      <div className={styles['image-container']}>
        <Icon type="image" />
      </div>
    );
  };

  render() {
    return (
      <div className={styles['title-field']}>
        {this.renderSelectedAssetImage()}
        <div className={styles['title']}>
          <div>{this.props.asset.title}</div>
          {this.props.asset.tree_path && this.props.asset.tree_path.length > 0 ? (
            <div>
              <TreePath assetId={this.props.asset.id} />
            </div>
          ) : null}
        </div>
        <ChooseAssetInlineModal
          hideCreateButton
          width="300"
          position="right"
          trigger={<Button type="icon" icon={<Icon regular type="pen" />} />}
          selectedAssetId={this.props.id}
          onSelectAsset={assetId => {
            this.props.onChangeAssetId(assetId);
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedAssetContainer);
