import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Icon,
  SectionHeader,
  FileUploadWrapper,
  NewInlineModal,
  ConfirmModal,
  Field,
} from 'views/components/Shared/General';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { AddSparePartModal, EstimatedTimeField } from 'views/components/WorkOrder';
import { SelectChecklistModal, ChecklistTitleModal, ChecklistEditModal } from 'views/components/Checklist';
import { NewAttachmentModal } from 'views/components/Attachment';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import ChecklistAlreadyExistsModal from './ChecklistAlreadyExistsModal';
import { SDKReduxOperations, HelperFunctions } from 'sdk';

const uploadFilesRequest = HelperFunctions.getCancelTokenForRequest();

class RightPanel extends Component {
  state = {
    showChecklistTemplatesModal: false,
    showAddSparePartModal: false,
    isCompletingChecklist: false,
    showChecklistAlreadyExistsModal: false,
    showNewAttachmentModalForType: null,
    showNewChecklistDropdown: false,
    showChecklistTitleModal: false,
    draftTemplateId: null,
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
    isSavingTemplateAsReusable: false,
    showConfirmAssetModal: false,
  };

  componentWillUnmount() {
    uploadFilesRequest.cancel();
  }

  reserveSpareParts = sparePartReservations => {
    this.setState({ showAddSparePartModal: false });
    this.props.setEditingOperationalMaintenanceTemplateVersionValue({
      sparePartReservations: [
        ...sparePartReservations,
        ...this.props.editingOperationalMaintenanceTemplateVersion.sparePartReservations,
      ],
    });
  };

  createDraftChecklistTemplate = title => {
    this.setState({
      showNewChecklistDropdown: false,
      showChecklistTitleModal: false,
      showChecklistEditModal: true,
      showChecklistEditModalForVersionId: null,
    });
    this.props
      .createChecklistTemplate(this.props.currentSystem.id, { title })
      .then(({ data: checklistTemplate }) => {
        this.setState({
          draftTemplateId: checklistTemplate.id,
          showChecklistEditModalForVersionId: checklistTemplate.draft_version.id,
        });
      });
  };

  createDraftAttachment = ({ description, title, extension, file, type, mime_type, link_url }) => {
    const temporaryId = 'editOpTemplate';
    this.props.beginUpload({ temporaryId: 'editOpTemplate', namespace: temporaryId });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: temporaryId,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props.attachmentVersionUploaded(attachment.attachment_version.id).then(() => {
                this.props.completeUpload({
                  id: attachment.id,
                  temporaryId,
                  namespace: temporaryId,
                });
                this.props.setEditingOperationalMaintenanceTemplateVersionValue({
                  attachments: [
                    {
                      attachment_id: attachment.id,
                      attachment_version_id: attachment.attachment_version.id,
                    },
                    ...this.props.editingOperationalMaintenanceTemplateVersion.attachments,
                  ],
                });
              });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: temporaryId });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: temporaryId });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(
          this.props.currentSystem.id,
          {
            description,
            title,
            extension,
            file,
            type,
            mime_type,
            link_url,
          },
          { completion: true }
        )
        .then(({ data: attachment }) => {
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            attachments: [
              { attachment_id: attachment.id, attachment_version_id: attachment.attachment_version.id },
              ...this.props.editingOperationalMaintenanceTemplateVersion.attachments,
            ],
          });
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: temporaryId,
          });
        });
    }
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.props.incrementImageLoaderCount();
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(({ data }) => {
        this.props.setEditingOperationalMaintenanceTemplateVersionValue({
          images: [data, ...this.props.editingOperationalMaintenanceTemplateVersion.images],
        });
        this.props.decrementImageLoaderCount();
      })
      .catch(() => {});
  };

  renderAddSparePartButton = () => {
    return (
      <div className={styles['add-button']} onClick={() => this.setState({ showAddSparePartModal: true })}>
        <Icon regular type="cogs" />
        <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.spare-part" />
      </div>
    );
  };

  renderAddChecklistButton = () => {
    return (
      <>
        <div
          ref={ref => (this.checklistButtonRef = ref)}
          className={styles['add-button']}
          onClick={() => {
            if (this.props.editingOperationalMaintenanceTemplateVersion.checklists.length > 0) {
              this.setState({ showChecklistAlreadyExistsModal: true });
            } else {
              this.setState(prevState => ({
                showNewChecklistDropdown: !prevState.showNewChecklistDropdown,
              }));
            }
          }}
        >
          <Icon regular type="list" />
          <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.checklist" />
        </div>
        <NewInlineModal
          positionToRef={this.checklistButtonRef}
          open={this.state.showNewChecklistDropdown}
          onClose={() => this.setState({ showNewChecklistDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({
                    showNewChecklistDropdown: false,
                    showChecklistTitleModal: true,
                  });
                }}
              >
                <FormattedMessage id="screens.work-order.edit.checklist.dropdown.create" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({
                    showNewChecklistDropdown: false,
                    showChecklistTemplatesModal: true,
                  });
                }}
              >
                <FormattedMessage id="screens.work-order.edit.checklist.dropdown.add" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderAddAssetButton = () => {
    if (this.props.operationalMaintenanceTemplateId) {
      return null;
    }
    return (
      <div className={styles['add-button']} onClick={() => this.setState({ showConfirmAssetModal: true })}>
        <Icon regular type="box" />
        <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.asset" />
      </div>
    );
  };

  renderAddAttachmentButton = () => {
    return (
      <div
        className={styles['add-button']}
        onClick={() => this.setState({ showNewAttachmentModalForType: 'file' })}
      >
        <Icon regular type="file-alt" />
        <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.attachments" />
      </div>
    );
  };

  renderAddImagesButton = () => {
    return (
      <FileUploadWrapper
        image
        accept="image/png, image/jpg, image/jpeg, image/gif"
        onSelectFile={this.addDraftImage}
      >
        <div className={styles['add-button']}>
          <Icon regular type="image" />
          <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.images" />
        </div>
      </FileUploadWrapper>
    );
  };

  renderChecklistAlreadyExistsModal = () => {
    return (
      <ChecklistAlreadyExistsModal
        open={this.state.showChecklistAlreadyExistsModal}
        onClose={() => this.setState({ showChecklistAlreadyExistsModal: false })}
      />
    );
  };

  renderAddButtons = () => {
    return (
      <div className={styles['add-buttons']}>
        {this.renderAddAssetButton()}
        {this.renderAddChecklistButton()}
        {this.renderAddSparePartButton()}
        {this.renderAddAttachmentButton()}
        {this.renderAddImagesButton()}
      </div>
    );
  };

  renderEstimatedTime = () => {
    return (
      <div>
        <Field
          view={false}
          label={<FormattedMessage id="resources.operational-maintenance-template-version.estimated-time" />}
        >
          <EstimatedTimeField
            autoFocus={false}
            estimatedMinutes={this.props.editingOperationalMaintenanceTemplateVersion.estimated_minutes}
            onChange={estimated_minutes =>
              this.props.setEditingOperationalMaintenanceTemplateVersionValue({ estimated_minutes })
            }
          />
        </Field>
      </div>
    );
  };

  renderSettings = () => {
    return <div className={styles['settings']}>{this.renderEstimatedTime()}</div>;
  };

  renderAddSparePartModal = () => {
    return (
      <AddSparePartModal
        reservation
        excludeSparePartIds={this.props.editingOperationalMaintenanceTemplateVersion.sparePartReservations.map(
          ({ spare_part_id }) => spare_part_id
        )}
        onAddSpareParts={addedSpareParts => this.reserveSpareParts(addedSpareParts)}
        open={this.state.showAddSparePartModal}
        onClose={() => this.setState({ showAddSparePartModal: false })}
      />
    );
  };

  renderNewAttachmentModal = () => {
    return (
      <NewAttachmentModal
        isOpen={this.state.showNewAttachmentModalForType != null}
        type={this.state.showNewAttachmentModalForType}
        onClose={() => this.setState({ showNewAttachmentModalForType: null })}
        onCreateNew={params => this.createDraftAttachment(params)}
      />
    );
  };

  renderSelectChecklistTemplateModal = () => {
    return (
      <SelectChecklistModal
        open={this.state.showChecklistTemplatesModal}
        onSelect={checklistTemplate => {
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            checklists: [
              {
                checklist_template_id: checklistTemplate.id,
              },
            ],
          });
          this.setState({ showChecklistTemplatesModal: false });
        }}
        onClose={() => {
          this.setState({ showChecklistTemplatesModal: false });
        }}
      />
    );
  };

  renderChecklistTitleModal = () => (
    <ChecklistTitleModal
      open={this.state.showChecklistTitleModal}
      onSave={this.createDraftChecklistTemplate}
      onClose={() => {
        this.setState({ showChecklistTitleModal: false });
      }}
    />
  );

  renderChecklistEditModal = () => {
    return (
      <ChecklistEditModal
        new
        open={this.state.showChecklistEditModal}
        hideSaveAsReusable
        id={this.state.showChecklistEditModalForVersionId}
        isSaving={this.state.isSavingTemplateAsReusable}
        onSave={() => {
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            checklists: [
              {
                checklist_template_id: this.state.draftTemplateId,
              },
            ],
          });
          this.setState({ showChecklistEditModal: false, showChecklistEditModalForVersionId: null });
        }}
        onClose={() => {
          this.setState({ showChecklistEditModal: false });
        }}
        onAddToLibrarySuccess={() => {
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            checklists: [
              {
                checklist_template_id: this.state.draftTemplateId,
              },
            ],
          });
          this.setState({ showChecklistEditModal: false, showChecklistEditModalForVersionId: null });
        }}
      />
    );
  };

  renderAddAssetConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmAssetModal}
        title={
          <FormattedMessage id="components.edit-operational-maintenance-template.add-asset-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="components.edit-operational-maintenance-template.add-asset-confirm-modal.subtitle" />
        }
        confirmButtonText="general.ok"
        onConfirm={() => {
          this.setState({ showConfirmAssetModal: false });
        }}
        onCancel={() => {
          this.setState({ showConfirmAssetModal: false });
        }}
      />
    );
  };

  renderContent = () => {
    return (
      <>
        <PerfectScrollbar>
          <SectionHeader paddingHorizontal={26} noBorderTop>
            <FormattedMessage id="general.add" />
          </SectionHeader>
          {this.renderAddButtons()}
          <SectionHeader paddingHorizontal={26}>
            <FormattedMessage id="general.settings" />
          </SectionHeader>
          {this.renderSettings()}
        </PerfectScrollbar>
      </>
    );
  };

  render() {
    return (
      <>
        <div className={styles['right-panel']}>{this.renderContent()}</div>
        {this.renderAddSparePartModal()}
        {this.renderNewAttachmentModal()}
        {this.renderSelectChecklistTemplateModal()}
        {this.renderChecklistAlreadyExistsModal()}
        {this.renderChecklistTitleModal()}
        {this.renderChecklistEditModal()}
        {this.renderAddAssetConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createDraftAttachment: SDKReduxOperations.createDraftAttachment,
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      createDraftImage: SDKReduxOperations.createDraftImage,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
      incrementImageLoaderCount: EditOperationalMaintenanceTemplateOperations.incrementImageLoaderCount,
      decrementImageLoaderCount: EditOperationalMaintenanceTemplateOperations.decrementImageLoaderCount,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
