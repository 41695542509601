import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  normalizeChecklistTemplate,
  normalizeChecklistInstance,
  normalizeChecklistTemplateVersion,
} from 'sdk/Schemas';

const fetchChecklistTemplateVersion = id => dispatch => {
  dispatch(actions.fetchChecklistTemplateVersion());
  return API.fetchChecklistTemplateVersion(id).then(res => {
    const { entities } = normalizeChecklistTemplateVersion(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.setChecklistTemplateVersion({
        id,
      })
    );
  });
};

const fetchChecklistTemplate = id => (dispatch, getState) => {
  dispatch(actions.fetchChecklistTemplateVersion());
  return API.fetchChecklistTemplate(id).then(res => {
    const { entities } = normalizeChecklistTemplate(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    let version = res.data.active_version ? res.data.active_version : res.data.draft_version;
    dispatch(
      actions.setChecklistTemplateVersion({
        id: version.id,
      })
    );

    return res.data;
  });
};

const fetchChecklistInstances =
  (systemId, params = {}) =>
  dispatch => {
    return API.listChecklistInstancesForSystem(systemId, { ...params, page_size: 8 }).then(res => {
      const { entities, result } = normalizeChecklistInstance(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchChecklistInstancesSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

const fetchChecklistTemplateVersions = id => dispatch => {
  return API.listChecklistTemplateVersions(id).then(res => {
    const { entities, result } = normalizeChecklistTemplateVersion(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchChecklistTemplateVersionsSuccess({
        versions: result,
      })
    );
    return res.data;
  });
};

export default {
  fetchChecklistInstances,
  fetchChecklistTemplate,
  fetchChecklistTemplateVersion,
  fetchChecklistTemplateVersions,
};
