import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { AttachmentType } from 'sdk/Attachment';
import { Icon } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import styles from './style.module.scss';

class AttachmentSubtitle extends Component {
  getIconForAttachment = () => {
    switch (this.props.attachment.type) {
      case AttachmentType.Asset:
        return 'box';
      case AttachmentType.Vendor:
        return 'briefcase';
      case AttachmentType.WorkOrderRegistration:
      case AttachmentType.WorkOrderCompletion:
        return 'wrench';
      case AttachmentType.RecurringMaintenance:
        return 'repeat';
      case AttachmentType.SparePart:
        return 'cogs';
      default:
        return null;
    }
  };

  renderSingleAsset = () => {
    const { attachment, asset } = this.props;
    if (asset == null) {
      return <FormattedMessage id="screens.files.single-asset" values={{ asset_count: 1 }} />;
    }
    if (asset.tree_path && asset.tree_path.length > 0) {
      return <TreePath onlyShowPath fullPath assetId={attachment.asset_id} />;
    } else {
      return <span>{asset.title}</span>;
    }
  };

  renderSingleAssetWithWorkOrders = () => {
    const { work_order_count } = this.props.attachment;
    return (
      <div className={styles['cross-type-connections']}>
        {this.renderSingleAsset()}
        <FormattedPlural
          value={work_order_count}
          one={<FormattedMessage id="screens.files.single-work-order" values={{ work_order_count }} />}
          other={<FormattedMessage id="screens.files.amount-of-work-orders" values={{ work_order_count }} />}
        />
      </div>
    );
  };

  renderManyAssetWithWorkOrders = () => {
    const { work_order_count, asset_count } = this.props.attachment;
    return (
      <div className={styles['cross-type-connections']}>
        <FormattedPlural
          value={work_order_count}
          one={<FormattedMessage id="screens.files.single-asset" values={{ asset_count }} />}
          other={<FormattedMessage id="screens.files.amount-of-assets" values={{ asset_count }} />}
        />
        <FormattedPlural
          value={work_order_count}
          one={<FormattedMessage id="screens.files.single-work-order" values={{ work_order_count }} />}
          other={<FormattedMessage id="screens.files.amount-of-work-orders" values={{ work_order_count }} />}
        />
      </div>
    );
  };

  renderManyAssetWithoutWorkOrders = () => {
    const { asset_count } = this.props.attachment;
    return (
      <FormattedPlural
        value={asset_count}
        one={<FormattedMessage id="screens.files.single-asset" values={{ asset_count }} />}
        other={<FormattedMessage id="screens.files.amount-of-assets" values={{ asset_count }} />}
      />
    );
  };

  renderAssetSubtitle = () => {
    const { asset_count, work_order_count, asset_id } = this.props.attachment;
    if (asset_count == 1 && work_order_count == 0) {
      return this.renderSingleAsset();
    }
    if (asset_count == 1 && work_order_count > 0) {
      return this.renderSingleAssetWithWorkOrders();
    }
    if (asset_count > 1 && work_order_count == 0) {
      return this.renderManyAssetWithoutWorkOrders();
    }
    if (asset_count > 1 && work_order_count > 0) {
      return this.renderManyAssetWithWorkOrders();
    }
  };

  renderWorkOrderSubtitle = () => {
    const { attachment } = this.props;
    if (attachment.registration_work_order_id) {
      return (
        <span>
          #{this.props.registrationWorkOrder.number} - {this.props.registrationWorkOrder.title}
        </span>
      );
    } else if (attachment.completion_work_order_id) {
      return (
        <span>
          #{this.props.completionWorkOrder.number} - {this.props.completionWorkOrder.title}
        </span>
      );
    }
  };

  renderRecurringMaintenanceSubtitle = () => {
    const { attachment } = this.props;
    return (
      <FormattedPlural
        value={attachment.work_order_count}
        one={
          <FormattedMessage
            id="screens.files.single-work-order"
            values={{ work_order_count: attachment.work_order_count }}
          />
        }
        other={
          <FormattedMessage
            id="screens.files.amount-of-work-orders"
            values={{ work_order_count: attachment.work_order_count }}
          />
        }
      />
    );
  };

  renderVendorSubtitle = () => <span>{this.props.vendor.name}</span>;

  renderManySpareParts = () => {
    const { spare_part_count } = this.props.attachment;
    return (
      <FormattedPlural
        value={spare_part_count}
        one={<FormattedMessage id="screens.files.single-spare-parts" values={{ spare_part_count }} />}
        other={<FormattedMessage id="screens.files.amount-of-spare-parts" values={{ spare_part_count }} />}
      />
    );
  };

  renderSingleSparePart = () => {
    const { sparePart } = this.props;
    if (sparePart == null) {
      return <FormattedMessage id="screens.files.single-spare-part" values={{ spare_part_count: 1 }} />;
    }
    return <Title sparePart={this.props.sparePart} />;
  };

  renderSparePartSubtitle = () => {
    const { spare_part_count } = this.props.attachment;
    if (spare_part_count > 1) {
      return this.renderManySpareParts();
    }
    return this.renderSingleSparePart();
  };

  renderMetaData = () => {
    const { attachment } = this.props;
    switch (attachment.type) {
      case AttachmentType.Asset:
        return this.renderAssetSubtitle();
      case AttachmentType.Vemndor:
        return this.renderVendorSubtitle();
      case AttachmentType.WorkOrderRegistration:
      case AttachmentType.WorkOrderCompletion:
        return this.renderWorkOrderSubtitle();
      case AttachmentType.RecurringMaintenance:
        return this.renderRecurringMaintenanceSubtitle();
      case AttachmentType.SparePart:
        return this.renderSparePartSubtitle();
      default:
        return null;
    }
  };

  render() {
    return (
      <div className={styles['subtitle-container']}>
        <Icon type={this.getIconForAttachment()} />
        {this.renderMetaData()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.attachment.asset_id),
    sparePart: EntitySelectors.getSparePart(state, ownProps.attachment.spare_part_id),
    vendor: EntitySelectors.getVendor(state, ownProps.attachment.vendor_id),
    registrationWorkOrder: EntitySelectors.getWorkOrder(
      state,
      ownProps.attachment.registration_work_order_id
    ),
    completionWorkOrder: EntitySelectors.getWorkOrder(state, ownProps.attachment.completion_work_order_id),
  };
}

export default connect(mapStateToProps, null)(AttachmentSubtitle);
