import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import Header from '../../Header';
import InfoContainer from './InfoContainer';
import MetaInfoContainer from './MetaInfoContainer';
import StatusContainer from './StatusContainer';
import AttachmentsContainer from './AttachmentsContainer';
import AfterRegistered from './AfterRegistered';
import RegisteredTime from './RegisteredTime';
import SpareParts from './SpareParts';
import Reminder from './Reminder';
import Checklist from './Checklist';
import CreatedFrom from './CreatedFrom';
import CreateRelatedResource from './CreateRelatedResource';
import WorkOrderPurchases from './WorkOrderPurchases';
import FollowUpWorkOrder from './FollowUpWorkOrder';
import Downtimes from './Downtimes';

class Information extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>
          <CardGrid>
            <CardGrid.Column>
              <CardGrid.Row>
                <StatusContainer workOrder={this.props.workOrder} />
              </CardGrid.Row>
              <CardGrid.Row>
                <InfoContainer workOrder={this.props.workOrder} />
              </CardGrid.Row>
              <CardGrid.Row>
                <Checklist workOrder={this.props.workOrder} />
              </CardGrid.Row>
              <CardGrid.Row>
                <AttachmentsContainer workOrder={this.props.workOrder} />
              </CardGrid.Row>
              {this.props.settings.work_order_spent_time_activated ||
              this.props.settings.spare_parts_activated ? (
                <CardGrid.Separator />
              ) : null}
              {this.props.settings.work_order_spent_time_activated ? (
                <CardGrid.Row>
                  <RegisteredTime workOrder={this.props.workOrder} />
                </CardGrid.Row>
              ) : null}
              {this.props.settings.spare_parts_activated ? (
                <CardGrid.Row>
                  <SpareParts workOrder={this.props.workOrder} />
                </CardGrid.Row>
              ) : null}
            </CardGrid.Column>
            <CardGrid.Column width={268}>
              <MetaInfoContainer workOrder={this.props.workOrder} />
              <Reminder workOrder={this.props.workOrder} />
              <AfterRegistered workOrder={this.props.workOrder} />
              <CreatedFrom workOrder={this.props.workOrder} />
              <FollowUpWorkOrder workOrder={this.props.workOrder} />
              <Downtimes workOrder={this.props.workOrder} />
              <CreateRelatedResource workOrder={this.props.workOrder} />
              <WorkOrderPurchases workOrder={this.props.workOrder} />
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(Information);
