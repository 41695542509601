import types from './types';
import update from 'immutability-helper';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  loading: false,
  id: null,
  sparePartLocationIds: null,
  sparePartLocationCounts: null,
  showSparePartLocationTab: false,
  hideCompletedCard: false,
  isDeleted: false,
  stocktakingRowIdForSparePart: {},
  spareParts: {
    ids: [],
    paginateFrom: null,
    totalEntries: 0,
    isFullyLoaded: false,
    loading: false,
    loadingMore: false,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;

    case types.INITIATE_WITH_STOCKTAKING_ID:
      return {
        ...INITIAL_STATE,
        loading: true,
        id: action.payload.stocktakingId,
      };

    case types.CLEAR_SPARE_PARTS:
      return {
        ...state,
        spareParts: INITIAL_STATE.spareParts,
      };

    case types.HIDE_COMPLETED_CARD:
      return {
        ...state,
        hideCompletedCard: true,
      };

    case types.SET_SPARE_PART_LOADING:
      return update(state, {
        spareParts: {
          loading: {
            $set: action.payload,
          },
        },
      });

    case types.SET_SPARE_PART_LOADING_MORE:
      return update(state, {
        spareParts: {
          loadingMore: {
            $set: action.payload,
          },
        },
      });

    case types.FETCH_SPARE_PARTS_FOR_STOCKTAKING_SUCCESS: {
      const {
        ids,
        paginateFrom,
        isFullyLoaded,
        totalEntries,
        initialLoad,
        stocktakingRowIdForSparePart,
      } = action.payload;

      return {
        ...state,
        stocktakingRowIdForSparePart: initialLoad
          ? stocktakingRowIdForSparePart
          : { ...state.stocktakingRowIdForSparePart, ...stocktakingRowIdForSparePart },
        spareParts: {
          ...state.spareParts,
          ids: initialLoad ? ids : [...state.spareParts.ids, ...ids],
          paginateFrom,
          isFullyLoaded,
          totalEntries: initialLoad ? totalEntries : state.spareParts.totalEntries,
          loadingMore: false,
          loading: false,
        },
      };
    }

    case SDKReduxTypes.WS_STOCKTAKING_JOIN: {
      const {
        stocktakingId,
        data: { spare_part_locations_count, spare_part_locations },
      } = action.payload;

      if (state.id !== stocktakingId) return state;

      var count = {};
      for (var i = 0; i < spare_part_locations_count.length; i++) {
        count[spare_part_locations_count[i].id] = spare_part_locations_count[i];
      }

      return {
        ...state,
        loading: false,
        sparePartLocationIds: spare_part_locations.map(({ id }) => id),
        sparePartLocationCounts: count,
        showSparePartLocationTab: spare_part_locations.length > 0,
      };
    }

    case SDKReduxTypes.STOCKTAKING_DELETED: {
      if (action.payload.stocktakingId !== state.id) return state;

      return {
        ...state,
        isDeleted: true,
      };
    }

    case SDKReduxTypes.STOCKTAKING_ROW_CREATED: {
      const { data } = action.payload;

      return update(state, {
        stocktakingRowIdForSparePart: {
          [data.spare_part_id]: { $set: data.id },
        },
      });
    }
    case SDKReduxTypes.WS_STOCKTAKING_STOCKTAKING_ROW_CREATED: {
      const data = action.payload;

      return update(state, {
        stocktakingRowIdForSparePart: {
          [data.spare_part_id]: { $set: data.id },
        },
      });
    }

    case SDKReduxTypes.STOCKTAKING_ROW_DELETED: {
      const { stocktakingRowId } = action.payload;

      let sparePartId;
      for (let [key, value] of Object.entries(state.stocktakingRowIdForSparePart)) {
        if (value === stocktakingRowId) sparePartId = key;
      }

      if (sparePartId) {
        return update(state, {
          stocktakingRowIdForSparePart: {
            [sparePartId]: { $set: undefined },
          },
        });
      } else {
        return state;
      }
    }
    case SDKReduxTypes.WS_STOCKTAKING_STOCKTAKING_ROW_DELETED: {
      const data = action.payload;

      let sparePartId;
      for (let [key, value] of Object.entries(state.stocktakingRowIdForSparePart)) {
        if (value === data.id) sparePartId = key;
      }

      if (sparePartId) {
        return update(state, {
          stocktakingRowIdForSparePart: {
            [sparePartId]: { $set: undefined },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.WS_STOCKTAKING_SPARE_PART_LOCATION_COUNT_INCREASED: {
      return update(state, {
        sparePartLocationCounts: {
          [action.payload.id]: {
            completed_count: {
              $set: state.sparePartLocationCounts[action.payload.id].completed_count + 1,
            },
          },
        },
      });
    }

    case SDKReduxTypes.WS_STOCKTAKING_SPARE_PART_LOCATION_COUNT_DECREASED: {
      return update(state, {
        sparePartLocationCounts: {
          [action.payload.id]: {
            completed_count: {
              $set: state.sparePartLocationCounts[action.payload.id].completed_count - 1,
            },
          },
        },
      });
    }

    default:
      return state;
  }
};
