import types from './types';

const resetSelectedRequests = payload => ({ type: types.RESET_SELECTED_REQUESTS, payload });

const selectRequest = payload => ({ type: types.SELECT_REQUEST, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const setLoading = payload => ({ type: types.SET_LOADING, payload });
const initializeView = () => ({ type: types.INITIALIZE_VIEW });

const setGroupBy = payload => ({ type: types.SET_GROUP_BY, payload });

const fetchRequestsForInbox = () => ({ type: types.FETCH_REQUESTS_FOR_INBOX });
const fetchRequestsForInboxSuccess = data => ({
  type: types.FETCH_REQUESTS_FOR_INBOX_SUCCESS,
  payload: data,
});
const fetchMoreRequestsForInbox = () => ({ type: types.FETCH_MORE_REQUESTS_FOR_INBOX });
const fetchMoreRequestsForInboxSuccess = data => ({
  type: types.FETCH_MORE_REQUESTS_FOR_INBOX_SUCCESS,
  payload: data,
});

const fetchRequestsForList = () => ({ type: types.FETCH_REQUESTS_FOR_LIST });
const fetchRequestsForListSuccess = data => ({
  type: types.FETCH_REQUESTS_FOR_LIST_SUCCESS,
  payload: data,
});

const prepareNewRequest = () => ({
  type: types.PREPARE_NEW_REQUEST,
});
const cancelNewRequest = payload => ({
  type: types.CANCEL_NEW_REQUEST,
  payload,
});
const setRequestForView = payload => ({
  type: types.SET_REQUEST_FOR_VIEW,
  payload: payload,
});
const setRequestForEdit = payload => ({
  type: types.SET_REQUEST_FOR_EDIT,
  payload: payload,
});
const setEditingRequestValues = data => ({
  type: types.SET_EDITING_REQUEST_VALUES,
  payload: data,
});

export default {
  resetSelectedRequests,
  selectPage,
  selectRequest,
  selectTotalEntries,
  hideSelectTotalEntries,
  resetFilter,
  addQueryParameter,
  showAppliedFilters,
  showAvailableFilters,
  removeFilter,
  selectFilterType,
  addFilter,
  setLoading,
  setGroupBy,
  cancelNewRequest,
  initializeView,
  setRequestForView,
  setRequestForEdit,
  setEditingRequestValues,
  fetchRequestsForInbox,
  fetchRequestsForInboxSuccess,
  fetchRequestsForList,
  fetchRequestsForListSuccess,
  fetchMoreRequestsForInbox,
  fetchMoreRequestsForInboxSuccess,
  prepareNewRequest,
};
