import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, NewInlineModal, Button } from 'views/components/Shared/General';
import { UserNameWrapper, SelectUserModal } from 'views/components/User';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { normalizeUser } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';

class ChooseUserInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      searchTerm: '',
      isOpen: false,
      users: [],
      showSelectUserModal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      return;
    }
    this.setState({
      isFetching: true,
      isOpen: true,
      searchTerm: '',
    });
    let params = {};
    if (this.props.members) {
      params = { member: true };
    }
    if (this.props.operator) {
      params = { operator: true };
    }
    return API.listUsers(this.props.system.id, { ...params, archived: false, no_pagination: true }).then(
      res => {
        const { entities, result } = normalizeUser(res.data);
        this.props.updateEntities(entities);
        this.setState({
          isFetching: false,
          users: result.map(id => entities.userById[id]),
        });
      }
    );
  };

  selectUser = userId => {
    if (this.props.multiple) {
      if (this.props.selectedUserIds.includes(userId)) {
        this.props.onRemoveUser(userId);
      } else {
        this.props.onAddUser(userId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectUser(userId);
        }
      );
    }
  };

  renderHeader = () => {
    return (
      <NewInlineModal.Header>
        <NewInlineModal.Header.Search
          ref={ref => (this.inlineModalSearchRef = ref)}
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.searchTerm}
          onChange={searchTerm => {
            this.setState({ searchTerm });
          }}
          onClear={() => {
            this.setState({ searchTerm: '' });
          }}
          rightLabel={
            <Button
              type="text"
              fontSize={12}
              primary
              label="general.more-options"
              noUnderline
              onClick={() => this.setState({ isOpen: false, showSelectUserModal: true })}
            />
          }
        />
      </NewInlineModal.Header>
    );
  };

  renderClearItem = () => {
    const { clearable, multiple, selectedUserId } = this.props;
    if (clearable && multiple === false && selectedUserId) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => {
              this.setState({ isOpen: false });
              this.props.onClear();
            }}
          >
            <FormattedMessage id="general.clean" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  renderUser = user => {
    if (this.props.multiple) {
      const selected = this.props.selectedUserIds.includes(user.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={user.id}
          onClick={e => this.selectUser(user.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectUser(user.id);
              }}
            />
          }
        >
          <UserNameWrapper user={user} />
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedUserId === user.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={user.id}
          selected={selected}
          onClick={e => this.selectUser(user.id)}
        >
          <UserNameWrapper user={user} />
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderUsers = () => {
    const { searchTerm } = this.state;
    const filteredUsers = this.state.users.filter(
      user => searchTerm === '' || user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredUsers.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return filteredUsers.map(user => this.renderUser(user));
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          {this.renderClearItem()}
          {this.renderUsers()}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderHeader()}
          {this.renderContent()}
        </NewInlineModal>
        <SelectUserModal
          open={this.state.showSelectUserModal}
          multiple={this.props.multiple}
          members={this.props.members}
          hideArchived={this.props.hideArchived}
          selectUserId={this.props.selectUserId}
          selectedUserIds={this.props.selectedUserIds}
          onAddUser={({ id }) => {
            this.selectUser(id);
          }}
          onRemoveUser={({ id }) => {
            this.selectUser(id);
          }}
          onSelectUser={({ id }) => {
            this.setState({ showSelectUserModal: false });
            this.selectUser(id);
          }}
          onClose={() => this.setState({ showSelectUserModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseUserInlineModal));

ChooseUserInlineModal.propTypes = {
  selectedUserId: PropTypes.string,
  selectedUserIds: PropTypes.array,
  onSelectUser: PropTypes.func,
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  hideArchived: PropTypes.bool,
  members: PropTypes.bool,
};

ChooseUserInlineModal.defaultProps = {
  selectedUserId: null,
  selectedUserIds: [],
  onSelectUser: () => {},
  onAddUser: () => {},
  onRemoveUser: () => {},
  onClear: () => {},
  multiple: false,
  clearable: true,
  hideArchived: false,
  members: false,
};
