import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { WorkOrderSelectors } from 'state/ducks/workOrder';
import moment from 'moment-timezone';
import Activity from './Activity';
import styles from './style.module.scss';

class ActivitesDay extends Component {
  renderLoading = () => (
    <div className={styles['day-container']}>
      <div className={styles['date-column']}>
        <div className={styles['date']}>
          <div style={{ width: 67, height: 30 }}>
            <ContentLoader
              primaryColor="#D27676"
              secondaryColor="#D28E8E"
              viewBox="0 0 67 30"
              preserveAspectRatio="xMinYMin"
            >
              <rect x="0" y="11" rx="2" ry="2" width="67" height="8" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={styles['activities-column']}>
        <Activity loading />
        <Activity loading />
      </div>
    </div>
  );

  render() {
    if (this.props.loading) return this.renderLoading();

    return (
      <div className={styles['day-container']}>
        <div className={styles['date-column']}>
          <div className={styles['date']}>{moment(this.props.date).format('ll')}</div>
        </div>
        <div className={styles['activities-column']}>
          {this.props.workOrderActivities.map(activity => (
            <Activity activityData={activity} />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    workOrderActivities: WorkOrderSelectors.getWorkOrderActivitiesForDate(state, ownProps.date),
  };
}

export default connect(mapStateToProps)(ActivitesDay);
