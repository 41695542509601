import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ViewTextArea, FullScreenImagePicker } from 'views/components/Shared/General';
import { Image } from 'views/components/Image';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class ChecklistInstanceRowComments extends Component {
  state = {
    showingImageDialog: false,
    currentImage: 0,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderComment = () => {
    if (this.props.instanceRow.comment) {
      return <ViewTextArea>{this.props.instanceRow.comment}</ViewTextArea>;
    }
    return null;
  };

  renderCommentAndImages = () => {
    const { images, instanceRow } = this.props;
    let classNames = [styles['comment']];
    if (instanceRow.comment && images.length > 0) {
      classNames = [...classNames, styles['has-images']];
    }
    return (
      <div className={classNames.join(' ')}>
        {this.renderComment()}
        {this.renderImages()}
      </div>
    );
  };

  renderImage = (image, index) => {
    return (
      <Image
        key={image.id}
        editable={false}
        medium
        image={image}
        onClick={() => {
          this.selectImage(index);
        }}
      />
    );
  };

  renderImages = () => {
    if (this.props.images.length === 0) {
      return null;
    }
    return (
      <div className={styles['images']}>
        {this.props.images.map((image, index) => this.renderImage(image, index))}
      </div>
    );
  };

  render() {
    const { instanceRow, images } = this.props;
    if (instanceRow.comment || images.length > 0) {
      return (
        <>
          <div className={styles['extra-data']}>{this.renderCommentAndImages()}</div>
          <FullScreenImagePicker
            currentImage={this.state.currentImage}
            images={this.props.images}
            onChangeImage={index => this.selectImage(index)}
            open={this.state.showingImageDialog}
            onClose={() => {
              this.setState({ showingImageDialog: false });
            }}
          />
        </>
      );
    }
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const { instanceRowId } = ownProps;
  const instanceRow = EntitySelectors.getChecklistInstanceRow(state, instanceRowId);
  return {
    instanceRow,
    images: EntitySelectors.getImages(state, instanceRow.images),
  };
}

export default connect(mapStateToProps)(ChecklistInstanceRowComments);
