import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { Modal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { Info, WorkOrders, Requests } from './Tabs';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const ModalTabs = {
  Info: 'info',
  WorkOrders: 'workOrder',
  Requests: 'requests',
};

class View extends Component {
  getInitialState = () => ({
    selectedTab: ModalTabs.Info,
    deleteInlineModalIsOpen: false,
    isDeleting: false,
    showDeleteWorkOrder: false,
    showRemoveFromWorkOrder: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  deleteDowntime = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteDowntime(this.props.downtime.id)
      .then(({ data: downtime }) => {
        toast(
          <ToastMessage success text={<FormattedMessage id="components.downtime-modal.delete-success" />} />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  renderContent = () => {
    switch (this.state.selectedTab) {
      case ModalTabs.Info:
        return (
          <div className={styles['info-content']}>
            <Info
              {...this.props}
              onEdit={() =>
                this.props.onEdit({
                  to: `${moment().format('YYYY-MM-DD')} ${moment().add(1, 'minute').format('HH:mm')}`,
                })
              }
            />
          </div>
        );
      case ModalTabs.WorkOrders:
        return <WorkOrders {...this.props} onRemoveFromWorkOrder={this.props.onRemoveFromWorkOrder} />;
      case ModalTabs.Requests:
        return <Requests {...this.props} onRemoveFromRequest={this.props.onRemoveFromRequest} />;
      default:
        return null;
    }
  };

  renderIconButtons = () => {
    if (this.props.canEditDowntime) {
      return (
        <>
          <Button type="icon" icon={<Icon regular red type="trash-alt" />} onClick={this.props.onDelete} />
          <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.props.onEdit()} />
        </>
      );
    }
    return null;
  };

  shouldRenderWorkOrders = () => {
    return this.props.workOrders && this.props.workOrders.length > 0;
  };

  shouldRenderRequests = () => {
    return this.props.requests && this.props.requests.length > 0;
  };

  shouldRenderTabs = () => {
    return this.shouldRenderRequests() || this.shouldRenderWorkOrders();
  };

  renderTabs = () => {
    if (!this.shouldRenderTabs()) return null;

    return (
      <Modal.Header.TabBar>
        <Modal.Header.TabBarItem
          onClick={() => this.setState({ selectedTab: ModalTabs.Info })}
          active={this.state.selectedTab === ModalTabs.Info}
        >
          <FormattedMessage id="components.downtime-modal.tabs.info" />
        </Modal.Header.TabBarItem>

        {this.shouldRenderWorkOrders() && (
          <Modal.Header.TabBarItem
            onClick={() => this.setState({ selectedTab: ModalTabs.WorkOrders })}
            active={this.state.selectedTab === ModalTabs.WorkOrders}
          >
            <FormattedMessage id="components.downtime-modal.tabs.work-orders" />
          </Modal.Header.TabBarItem>
        )}

        {this.shouldRenderRequests() && (
          <Modal.Header.TabBarItem
            onClick={() => this.setState({ selectedTab: ModalTabs.Requests })}
            active={this.state.selectedTab === ModalTabs.Requests}
          >
            <FormattedMessage id="components.downtime-modal.tabs.requests" />
          </Modal.Header.TabBarItem>
        )}
      </Modal.Header.TabBar>
    );
  };

  render() {
    return (
      <>
        <Modal.Header
          title={
            <FormattedMessage
              id="components.downtime-modal.title"
              values={{
                date: moment(this.props.downtime.from).format('LLL'),
              }}
            />
          }
          iconButtons={this.renderIconButtons()}
          tabBarComponent={this.renderTabs()}
          subtitle={<TreePath assetId={this.props.downtime.asset_id} fullPath />}
          onClose={this.props.onClose}
        />
        <Modal.Content
          grayBackground={
            this.state.selectedTab !== ModalTabs.Info &&
            !(this.state.selectedTab == ModalTabs.WorkOrders && this.props.workOrders.length == 1)
          }
          hasTabs
        >
          {this.renderContent()}
        </Modal.Content>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteDowntime: SDKReduxOperations.deleteDowntime,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    workOrders: EntitySelectors.getWorkOrders(state, downtime.work_orders),
    requests: EntitySelectors.getWorkOrders(state, downtime.requests),
    asset: EntitySelectors.getAsset(state, downtime.asset_id),
    canEditDowntime: AuthSelectors.canEditDowntime(state, downtime),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    isOperator: AuthSelectors.isOperator(state),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(View));
