import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'views/components/Shared/General';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import WorkOrderItem from './WorkOrderItem';
import PreviewWorkOrderHeader from './PreviewWorkOrderHeader';
import styles from '../../style.module.scss';

class WorkOrders extends Component {
  renderSingleWorkOrder = () => {
    return (
      <div className={styles['info-content']}>
        <PreviewWorkOrderHeader
          workOrderId={this.props.workOrders[0].id}
          onRemoveFromWorkOrder={this.props.onRemoveFromWorkOrder}
        />
        <PreviewWorkOrderContent workOrderId={this.props.workOrders[0].id} />
      </div>
    );
  };

  renderMultipleWorkOrders = () => {
    return (
      <>
        <List.Header small background paddingRight={77}>
          <List.Header.Column flex>
            {/* TODO: använd denna translation som är "Rubrik" på svenska, eller skapa en "Titel"? */}
            <FormattedMessage id="resources.work-order.title" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.workOrders.map(workOrder => (
            <WorkOrderItem workOrder={workOrder} onRemoveFromWorkOrder={this.props.onRemoveFromWorkOrder} />
          ))}
        </List>
      </>
    );
  };

  render() {
    if (this.props.workOrders.length === 1) {
      return this.renderSingleWorkOrder();
    }
    return this.renderMultipleWorkOrders();
  }
}

function mapStateToProps(state, ownProps) {
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    workOrders: EntitySelectors.getWorkOrders(state, downtime.work_orders),
  };
}

export default connect(mapStateToProps)(WorkOrders);
