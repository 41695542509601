import types from './types';

const setPurchaseOrderId = payload => ({
  type: types.SET_PURCHASE_ORDER_ID,
  payload: payload,
});

const fetchDeliveriesSuccess = payload => ({
  type: types.FETCH_DELIVERIES_SUCCESS,
  payload: payload,
});

const fetchWorkOrderPurchasesSuccess = payload => ({
  type: types.FETCH_WORK_ORDER_PURCHASES_SUCCESS,
  payload: payload,
});

const fetchAttachmentsSuccess = payload => ({
  type: types.FETCH_ATTACHMENTS_SUCCESS,
  payload: payload,
});

export default {
  setPurchaseOrderId,
  fetchDeliveriesSuccess,
  fetchWorkOrderPurchasesSuccess,
  fetchAttachmentsSuccess,
};
