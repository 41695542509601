import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'views/components/Shared/General';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { API, HelperFunctions } from 'sdk';
import { normalizeOperationalMaintenance } from 'sdk/Schemas';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { OperationalMaintenanceOperations } from 'state/ducks/operationalMaintenance';
import { AuthSelectors } from 'state/ducks/auth';
import NotFound from './NotFound';
import View from './View';

class OperatorMaintenanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchOperationalMaintenance();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchOperationalMaintenance();
    }
  }

  fetchOperationalMaintenance = () => {
    const { id } = this.props.match.params;
    const { operationalMaintenance } = this.props;
    if (id.slice(0, 2) === 't_') {
      if (operationalMaintenance) {
        this.createOperationalMaintenanceInstanceForOperationalMaintenance();
      } else {
        this.fetchOperationalMaintenanceAndCreateOperationalMaintenanceInstance(id);
      }
    } else {
      this.fetchOperationalMaintenanceInstance(id);
    }
  };

  fetchOperationalMaintenanceInstance = id => {
    this.props
      .fetchOperationalMaintenanceInstance(id)
      .then(() => this.setState({ loading: false }))
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  fetchOperationalMaintenanceAndCreateOperationalMaintenanceInstance = id => {
    API.fetchOperationalMaintenance(id)
      .then(({ data: operationalMaintenance }) => {
        this.props
          .createOperationalMaintenanceInstance(this.props.system.id, {
            operational_maintenance_template_recurrence_id:
              operationalMaintenance.operational_maintenance_template_recurrence_id,
            date: operationalMaintenance.date,
            due_date: operationalMaintenance.date,
            generated_for_date: operationalMaintenance.date,
          })
          .then(operationalMaintenanceInstance => {
            this.props.history.replace({
              pathname: `/operational-maintenances/${operationalMaintenanceInstance.id}`,
            });
          })
          .catch(e => {
            if (HelperFunctions.hasError(e, { code: '10002', key: 'generated_for_date' })) {
              API.fetchOperationalMaintenance(this.props.match.params.id).then(
                ({ data: operationalMaintenance }) => {
                  const { entities } = normalizeOperationalMaintenance(operationalMaintenance);
                  this.props.updateEntities(entities);
                  this.props.history.replace({
                    pathname: `/operational-maintenances/${operationalMaintenance.operational_maintenance_instance_id}`,
                  });
                }
              );
            }
          });
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  createOperationalMaintenanceInstanceForOperationalMaintenance = () => {
    const { operationalMaintenance } = this.props;
    this.props
      .createOperationalMaintenanceInstance(this.props.system.id, {
        operational_maintenance_template_recurrence_id:
          operationalMaintenance.operational_maintenance_template_recurrence_id,
        date: operationalMaintenance.date,
        due_date: operationalMaintenance.date,
        generated_for_date: operationalMaintenance.date,
      })
      .then(operationalMaintenanceInstance => {
        this.props.history.replace({
          pathname: `/operational-maintenances/${operationalMaintenanceInstance.id}`,
        });
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'generated_for_date' })) {
          API.fetchOperationalMaintenance(this.props.match.params.id).then(
            ({ data: operationalMaintenance }) => {
              const { entities } = normalizeOperationalMaintenance(operationalMaintenance);
              this.props.updateEntities(entities);
              this.props.history.replace({
                pathname: `/operational-maintenances/${operationalMaintenance.operational_maintenance_instance_id}`,
              });
            }
          );
        } else {
          this.setState({ notFound: e.response && e.response.status === 404 });
        }
      });
  };

  render() {
    if (this.state.notFound) {
      return (
        <PerfectScrollbar>
          <NotFound />
        </PerfectScrollbar>
      );
    } else if (this.state.loading) {
      return <Loader centerInParent />;
    } else {
      return <View />;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenanceInstance:
        OperationalMaintenanceOperations.fetchOperationalMaintenanceInstance,
      createOperationalMaintenanceInstance:
        OperationalMaintenanceOperations.createOperationalMaintenanceInstance,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const operationalMaintenance = EntitySelectors.getOperationalMaintenance(state, id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(state, id);
  return {
    operationalMaintenance,
    operationalMaintenanceInstance,
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorMaintenanceDetail);
