import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Menu, List, WhiteCard } from 'views/components/Shared/General';
import { AssetMenu } from 'views/components/Asset';
import { API } from 'sdk';
import { normalizeDowntime } from 'sdk/Schemas';
import DowntimeListItem from './DowntimeListItem';
import styles from './style.module.scss';

class SelectDowntimeModal extends Component {
  getInitialState = () => ({
    selectedAssetId: this.props.initialAssetId,
    downtimeIds: [],
    isFetching: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
      this.setState({ isFetching: true }, () => {
        this.fetchDowntimes().then(({ downtimeIds }) => {
          this.setState({ downtimeIds, isFetching: false });
        });
      });
    }

    if (prevState.selectedAssetId !== this.state.selectedAssetId) {
      this.setState({ isFetching: true }, () => {
        this.fetchDowntimes().then(({ downtimeIds }) => {
          this.setState({ downtimeIds, isFetching: false });
        });
      });
    }
  }

  fetchDowntimes = () => {
    if (!this.state.selectedAssetId) {
      return API.listDowntimes(this.props.currentSystem.id, this.props.additionalApiParams).then(res => {
        const { data: downtimes } = res;
        const { entities, result } = normalizeDowntime(downtimes);
        this.props.updateEntities(entities);
        return { downtimeIds: result };
      });
    }

    return API.listDowntimes(this.props.currentSystem.id, {
      asset_including_related_id: this.state.selectedAssetId,
      ...this.props.additionalApiParams,
    }).then(res => {
      const { data: downtimes } = res;
      const { entities, result } = normalizeDowntime(downtimes);
      this.props.updateEntities(entities);
      return { downtimeIds: result };
    });
  };

  renderCreateButton = () => {
    return (
      <Button
        primary
        onClick={this.props.onCreateNew}
        label="components.select-downtime-modal.create-new-downtime-button"
      />
    );
  };

  renderMenuButtons = () => {
    return (
      <>
        <Menu.Separator />
        <Menu.Item
          selected={!this.state.selectedAssetId}
          title={<FormattedMessage id="components.select-downtime-modal.select-from-all-assets" />}
          onClick={() => {
            this.setState({ selectedAssetId: null });
          }}
        />
      </>
    );
  };

  renderMenuContent = () => {
    return (
      <>
        <div>
          <div className={styles['left-panel']}>
            {this.renderCreateButton()}
            {this.renderMenuButtons()}
          </div>
          <AssetMenu
            onSelectAssetId={id => {
              this.setState({ selectedAssetId: id });
            }}
            selectedAssetId={this.state.selectedAssetId}
          />
        </div>
      </>
    );
  };

  renderDowntimes = () => {
    if (this.state.isFetching) {
      return (
        <List>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.downtimeIds.length == 0) {
      return (
        <WhiteCard centerContent noPadding>
          <div className={styles['empty-dataset']}>
            <FormattedMessage id="components.select-downtime-modal.no-downtimes-for-asset" />
          </div>
        </WhiteCard>
      );
    }
    return (
      <List>
        {this.state.downtimeIds.map(id => (
          <DowntimeListItem id={id} key={id} listItemRightComponent={this.props.listItemRightComponent} />
        ))}
      </List>
    );
  };

  renderListHeader = () => {
    if (this.state.isFetching) {
      return (
        <List.Header background>
          <List.Header.Column flex>
            <List.Item.TitleColumn loading />
          </List.Header.Column>
        </List.Header>
      );
    }
    if (!this.state.isFetching && this.state.downtimeIds.length == 0) return null;
    return (
      <List.Header background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-downtime-modal.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderModalView = () => {
    return (
      <Modal.ColumnLayout.Container.Content>
        {this.renderListHeader()}
        {this.renderDowntimes()}
      </Modal.ColumnLayout.Container.Content>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.select-downtime-modal.title" />}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>{this.renderModalView()}</Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectDowntimeModal));
