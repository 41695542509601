import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';

class EditDefaultWorkOrderEmailValues extends Component {
  getInitialState = () => ({
    isSaving: false,
    work_order_email_subject: '',
    work_order_email_message: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    const { work_order_email_message, work_order_email_subject } = this.props.currentSystem;
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        work_order_email_subject,
        work_order_email_message,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  updateSystem = () => {
    this.setState({ isSaving: true });
    const { work_order_email_message, work_order_email_subject } = this.state;
    this.props
      .updateSystem(this.props.currentSystem.id, {
        work_order_email_subject,
        work_order_email_message,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave({ work_order_email_subject, work_order_email_message });
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  renderContent = () => (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.edit-work-order-email-values.subject" />}
            >
              <Field.Text
                value={this.state.work_order_email_subject}
                onChange={work_order_email_subject =>
                  this.setState({
                    work_order_email_subject,
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.edit-work-order-email-values.message" />}
            >
              <Field.Textarea
                value={this.state.work_order_email_message}
                onChange={work_order_email_message =>
                  this.setState({
                    work_order_email_message,
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.edit-work-order-email-values.title" />}
          subtitle={<FormattedMessage id="components.edit-work-order-email-values.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.updateSystem} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditDefaultWorkOrderEmailValues));
