import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, List } from 'views/components/Shared/General';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class UserListItem extends PureComponent {
  render() {
    return (
      <List.Item small light clickable onClick={() => this.props.onToggle(this.props.id)}>
        <List.Item.Column width={20}>
          <Field.Checkbox checked={this.props.selected} onChange={() => this.props.onToggle(this.props.id)} />
        </List.Item.Column>
        <List.Item.Column width={24}>
          <ProfilePicture userId={this.props.id} size={24} />
        </List.Item.Column>
        <List.Item.TitleColumn
          title={<UserNameWrapper user={this.props.user} />}
          subtitle={this.props.user.email}
        />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserListItem);
