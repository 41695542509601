import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../../Header';
import GeneralInformation from './GeneralInformation';
import Images from './Images';
import OrderInformation from './OrderInformation';
import StockInformation from './StockInformation';
import PrimaryVendor from './PrimaryVendor';
import UpgradeToPro from './UpgradeToPro';

class Information extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>
          <CardGrid>
            <CardGrid.Column>
              <CardGrid.Row>
                <GeneralInformation />
              </CardGrid.Row>
              {this.props.hasProTier ? (
                <CardGrid.Row>
                  <OrderInformation />
                </CardGrid.Row>
              ) : null}
              {this.props.hasProTier === false ? (
                <CardGrid.Row>
                  <UpgradeToPro />
                </CardGrid.Row>
              ) : null}
            </CardGrid.Column>
            <CardGrid.Column width={268}>
              <CardGrid.Row>
                <Images />
              </CardGrid.Row>
              {this.props.hasProTier ? (
                <CardGrid.Row>
                  <StockInformation />
                </CardGrid.Row>
              ) : null}
              <PrimaryVendor />
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps, null)(Information);
