import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import moment from 'moment';
import Decimal from 'decimal.js';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import {
  WhiteCard,
  FormattedNumber,
  Field,
  SectionHeader,
  NewInlineModal,
  Button,
  Icon,
} from 'views/components/Shared/General';
import { ChangeMeterValueModal } from 'views/components/Meter';
import { CardGrid } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './styles.module.scss';

class Meter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownInlineModal: false,
    };
  }

  renderCurrentValue = () => {
    return (
      <div className={styles['current-value-container']}>
        <div>
          <FormattedNumber
            value={this.props.meter.value}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            valueSize={28}
            abbreviationSize={16}
          />
        </div>
        {this.props.canEditMeterReadings ? (
          <div className={styles['edit-button']}>
            <Button
              iconButtonSize={28}
              type="icon"
              icon={<Icon regular type="pen" size={12} />}
              onClick={() => this.setState({ showUpdateMeterReadingModal: true })}
            />
          </div>
        ) : null}
      </div>
    );
  };

  renderValueLastUpdated = () => {
    if (this.props.meter.value_last_updated == null) {
      return '-';
    }
    var valueLastUpdated = moment(this.props.meter.value_last_updated);
    var today = moment().startOf('day');
    var difference = today.diff(valueLastUpdated, 'days');
    if (difference === 0) {
      return <FormattedMessage id="calendar.today" />;
    } else if (difference === 1) {
      return <FormattedMessage id="calendar.yesterday" />;
    } else {
      return (
        <FormattedPlural
          value={difference}
          two={
            <FormattedMessage
              id="resources.meter.last-updated-values.two"
              values={{
                days: difference,
              }}
            />
          }
          few={
            <FormattedMessage
              id="resources.meter.last-updated-values.few"
              values={{
                days: difference,
              }}
            />
          }
          many={
            <FormattedMessage
              id="resources.meter.last-updated-values.many"
              values={{
                days: difference,
              }}
            />
          }
          other={
            <FormattedMessage
              id="resources.meter.last-updated-values.other"
              values={{
                days: difference,
              }}
            />
          }
        />
      );
    }
  };

  renderExtraMeterValues = () => {
    return (
      <div className={styles['extra-values']}>
        <div className={styles['extra-value']}>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="resources.meter.value-last-updated" />}
          >
            {this.renderValueLastUpdated()}
          </Field>
        </div>
        <div className={styles['extra-value']}>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="resources.meter.average-daily-usage" />}
          >
            {this.props.meter.average_daily_usage ? (
              <FormattedMessage
                id="resources.meter.meter-average-increase-value"
                values={{
                  value: (
                    <FormattedNumber
                      value={new Decimal(this.props.meter.average_daily_usage).toDecimalPlaces(2).toString()}
                      unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                    />
                  ),
                }}
              />
            ) : (
              <span>-</span>
            )}
          </Field>
        </div>
      </div>
    );
  };

  renderContent = () => {
    return (
      <>
        <SectionHeader noBorderTop paddingHorizontal={26}>
          <div className={styles['section']}>
            <div className={styles['meter-title']}>{this.props.meter.title}</div>
            <div>
              <div ref={ref => (this.inlineModalDropdownPositioningRef = ref)}>
                <div className={styles['header-icon']}>
                  <Button
                    type="icon"
                    iconButtonSize={28}
                    icon={<Icon regular size={14} type="ellipsis-h" />}
                    onClick={() => {
                      this.setState(prevState => ({
                        showDropdownInlineModal: !prevState.showDropdownInlineModal,
                      }));
                    }}
                  />
                </div>
              </div>
              <NewInlineModal
                minWidth={230}
                position="right"
                positionToRef={this.inlineModalDropdownPositioningRef}
                open={this.state.showDropdownInlineModal}
                onClose={() => {
                  this.setState({ showDropdownInlineModal: false });
                }}
              >
                <NewInlineModal.Dropdown>
                  <NewInlineModal.Dropdown.Items>
                    {this.props.canEditMeterReadings ? (
                      <NewInlineModal.Dropdown.Item
                        onClick={() =>
                          this.setState({ showDropdownInlineModal: false, showUpdateMeterReadingModal: true })
                        }
                      >
                        <FormattedMessage id="screens.asset.update-meter-reading" />
                      </NewInlineModal.Dropdown.Item>
                    ) : null}
                    <Link to={`/meters/${this.props.meter.id}`}>
                      <NewInlineModal.Dropdown.Item>
                        <FormattedMessage id="screens.asset.go-to-meter" />
                      </NewInlineModal.Dropdown.Item>
                    </Link>
                  </NewInlineModal.Dropdown.Items>
                </NewInlineModal.Dropdown>
              </NewInlineModal>
            </div>
          </div>
        </SectionHeader>
        <div className={styles['content']}>
          {this.renderCurrentValue()}
          {this.renderExtraMeterValues()}
          {this.props.renderShowAllButton ? this.props.renderShowAllButton : null}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <CardGrid.Row>
          <WhiteCard noPadding>{this.renderContent()}</WhiteCard>
        </CardGrid.Row>
        <ChangeMeterValueModal
          meterId={this.props.meter.id}
          createForMeterId={this.props.meter.id}
          open={this.state.showUpdateMeterReadingModal}
          onClose={() => this.setState({ showUpdateMeterReadingModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  let meter = EntitySelectors.getMeter(state, id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    canEditMeterReadings: AuthSelectors.canEditMeterReadings(state),
  };
}

export default withRouter(connect(mapStateToProps)(Meter));
