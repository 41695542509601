import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Title } from 'views/components/SparePart';

const SparePartContainer = ({ sparePart }) => {
  return <Title sparePart={sparePart} />;
};

function mapStateToProps(state, ownProps) {
  return {
    sparePart: EntitySelectors.getSparePart(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SparePartContainer);
