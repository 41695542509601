import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Pagination, NewSearchField, EmptyDataSet, NewTab } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeMeter } from 'sdk/Schemas';
import MeterItem from './MeterItem';
import SelectedAssetContainer from './SelectedAssetContainer';
import styles from './styles.module.scss';

const PAGE_SIZE = 6;

const TABS = {
  Asset: 'asset',
  All: 'all',
};

class SelectMeterModal extends Component {
  getInitialState = () => ({
    ids: [],
    isFetching: true,
    isSearching: false,
    page: 1,
    totalPages: 1,
    searchValue: '',
    showMetersForAssetId: null,
    tab: TABS.Asset,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(
        {
          ...this.getInitialState(),
          tab: this.props.assetId ? TABS.Asset : TABS.All,
          showMetersForAssetId: this.props.assetId,
        },
        () => {
          this.listMeters({ page_size: PAGE_SIZE }).then(({ totalPages, ids }) => {
            this.setState({
              totalPages,
              ids,
              isFetching: false,
            });
          });
        }
      );
    }
  }

  changePage = page => {
    this.setState({ page, isSearching: true });
    this.listMeters({ page }).then(({ totalPages, ids }) => {
      this.setState({
        totalPages,
        ids,
        isSearching: false,
      });
    });
  };

  listMeters = params => {
    let attrs = {
      page_size: PAGE_SIZE,
      ...params,
    };
    if (this.state.tab === TABS.Asset) {
      attrs = {
        ...attrs,
        asset_id: this.state.showMetersForAssetId,
      };
    }
    return API.listMeters(this.props.system.id, attrs).then(({ data: meters, headers }) => {
      const { entities, result } = normalizeMeter(meters);
      this.props.updateEntities(entities);
      const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
      return {
        totalPages,
        ids: result,
      };
    });
  };

  renderPagination = () => {
    if (this.state.totalPages > 1) {
      return (
        <div className={styles['pagination-container']}>
          <Pagination
            hideOptions
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onSelectPage={page => {
              this.changePage(page);
            }}
          />
        </div>
      );
    }
    return null;
  };

  renderEmptyDataSet = () => {
    if (this.state.tab === TABS.Asset) {
      return (
        <EmptyDataSet
          title={<FormattedMessage id="components.select-meter-modal.empty-data-set-asset.title" />}
          modal
        />
      );
    }
    if (this.state.searchValue.length === 0) {
      return (
        <EmptyDataSet
          title={<FormattedMessage id="components.select-meter-modal.empty-data-set.title" />}
          modal
        />
      );
    }
    return (
      <EmptyDataSet
        title={<FormattedMessage id="general.empty-data-set-search.title" />}
        subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
        modal
      />
    );
  };

  renderMeters = () => {
    if (this.state.isFetching) {
      return (
        <List light>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.isSearching) {
      const amountOfLoaderItems = this.state.ids.length === 0 ? 2 : this.state.ids.length;
      return (
        <>
          <List light>
            {Array(amountOfLoaderItems)
              .fill()
              .map(() => (
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.state.ids.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        <List light>
          {this.state.ids.map(id => (
            <MeterItem
              isSaving={this.props.isSavingMeterId === id}
              showingForAsset={this.state.tab === TABS.Asset}
              id={id}
              key={id}
              onSelect={id => {
                this.props.onSelect(id);
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderSearchField = () => {
    if (this.state.tab === TABS.Asset) {
      return null;
    }
    return (
      <div className={styles['search-field']}>
        <NewSearchField
          autoFocus
          value={this.state.searchValue}
          debounce
          placeholder={this.props.intl.formatMessage({
            id: 'general.search-placeholder',
          })}
          onSearch={value => {
            this.setState({
              searchValue: value,
              isSearching: true,
              page: 1,
            });
          }}
          onDebouncedSearch={value => {
            this.listMeters({ search: value }).then(({ ids, totalPages }) => {
              this.setState({
                totalPages,
                ids,
                isSearching: false,
              });
            });
          }}
          onClear={() => {
            this.setState({ isSearching: true, searchValue: '' });
            this.listMeters({ search: '' }).then(({ ids, totalPages }) => {
              this.setState({
                totalPages,
                ids,
                isSearching: false,
              });
            });
          }}
        />
      </div>
    );
  };

  renderSelectedAsset = () => {
    if (this.state.tab === TABS.Asset) {
      return (
        <SelectedAssetContainer
          id={this.state.showMetersForAssetId}
          onChangeAssetId={assetId => {
            this.setState({ isFetching: true, showMetersForAssetId: assetId }, () => {
              this.listMeters().then(({ ids }) => {
                this.setState({
                  ids,
                  isFetching: false,
                });
              });
            });
          }}
        />
      );
    }
    return null;
  };

  renderTabs = () => {
    if (this.props.assetId) {
      return (
        <div className={styles['tabs-contaienr']}>
          <NewTab.Container>
            <NewTab
              selected={this.state.tab === TABS.Asset}
              onClick={() => {
                this.setState({ isFetching: true, tab: TABS.Asset }, () => {
                  this.listMeters().then(({ totalPages, ids }) => {
                    this.setState({
                      totalPages,
                      ids,
                      isFetching: false,
                    });
                  });
                });
              }}
            >
              <FormattedMessage id="components.select-meter-modal.tabs.asset" />
            </NewTab>
            <NewTab
              selected={this.state.tab === TABS.All}
              onClick={() => {
                this.setState({ isFetching: true, tab: TABS.All }, () => {
                  this.listMeters().then(({ totalPages, ids }) => {
                    this.setState({
                      totalPages,
                      ids,
                      isFetching: false,
                    });
                  });
                });
              }}
            >
              <FormattedMessage id="components.select-meter-modal.tabs.all" />
            </NewTab>
          </NewTab.Container>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    return (
      <>
        {this.renderTabs()}
        {this.renderSelectedAsset()}
        {this.renderSearchField()}
        {this.renderMeters()}
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.select-meter-modal.title" />}
          subtitle={<FormattedMessage id="components.select-meter-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectMeterModal));

SelectMeterModal.propTypes = {
  isSavingMeterId: PropTypes.string,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

SelectMeterModal.defaultProps = {
  isSavingMeterId: null,
  onClose: () => {},
  onSelect: () => {},
};
