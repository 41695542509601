import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { bindActionCreators } from 'redux';
import { HelperFunctions } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ProductionBoardSelectors, ProductionBoardOperations } from 'state/ducks/productionBoard';
import { FormattedMessage } from 'react-intl';
import DaySelection from './DaySelection';
import Activity from './Activity';
import styles from './style.module.scss';

const listRequest = HelperFunctions.getCancelTokenForRequest();

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
    };

    this.fetchDebouncedProductionBoardActivities = debounce(() => {
      this.fetchProductionBoardActivities();
    }, 300);
  }

  componentDidMount = () => {
    this.fetchProductionBoardActivities();
  };

  componentDidUpdate(prevProps) {
    const { checkedInAssetId: prevCheckedInAssetId } = prevProps;
    const { checkedInAssetId } = this.props;
    const changedCheckedInAssetId = prevCheckedInAssetId !== checkedInAssetId;
    const changedDate = prevProps.date !== this.props.date;
    if (changedDate || changedCheckedInAssetId) {
      this.setState({ isFetching: true });
      listRequest.cancel();
      this.fetchDebouncedProductionBoardActivities();
    }
  }

  fetchProductionBoardActivities = () => {
    this.setState({ isFetching: true });
    let params = {
      created_at: this.props.date,
    };
    if (this.props.checkedInAssetId) {
      params = {
        ...params,
        asset_with_tree_children_id: this.props.checkedInAssetId,
      };
    }
    this.props
      .fetchProductionBoardActivitiesForDashboard(
        this.props.system.id,
        params,
        listRequest.getCancelTokenConfig()
      )
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  renderIsFetchingDashBoardEvents = () => {
    return (
      <div className={styles['loader-container']}>
        <Loader tiny />
      </div>
    );
  };

  renderNoActivitiesMessage = () => {
    return (
      <span className={styles['no-activities-message-container-container']}>
        <FormattedMessage id="screens.production-board.overview.activities.empty-dataset" />
      </span>
    );
  };

  renderActivities = () => {
    if (this.state.isFetching || this.props.isRefreshing) {
      return this.renderIsFetchingDashBoardEvents();
    }
    if (this.props.productionBoardActivityIds.length === 0) {
      return this.renderNoActivitiesMessage();
    }
    return (
      <div className={styles['activities']}>
        {this.props.productionBoardActivityIds.map(activityId => (
          <Activity key={activityId} activityId={activityId} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['main-content']}>
          <PerfectScrollbar>
            <div className={styles['activities-container']}>
              <DaySelection />
              {this.renderActivities()}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProductionBoardActivitiesForDashboard:
        ProductionBoardOperations.fetchProductionBoardActivitiesForDashboard,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    productionBoardActivityIds: ProductionBoardSelectors.getProductionBoardActivityIds(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    date: ProductionBoardSelectors.getProductionBoardActivityDate(state),
    isRefreshing: ProductionBoardSelectors.isRefreshing(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activities));
