import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { Button, ElapsedTime } from 'views/components/Shared/General';
import { DowntimeModal } from 'views/components/Downtime';
import moment from 'moment';
import styles from './style.module.scss';

class Activity extends Component {
  state = {
    showOpen: false,
    showDowntimeModal: false,
  };

  getLink() {
    if (this.props.workOrder) {
      return `/work-orders/${this.props.workOrder.id}`;
    } else if (this.props.request) {
      return `/requests/${this.props.request.id}`;
    } else if (this.props.productionBoardActivity.operational_maintenance_instance_id) {
      return `/operational-maintenances/${this.props.productionBoardActivity.operational_maintenance_instance_id}`;
    }
    return null;
  }

  renderActivityType = () => {
    const typeTranslationKey = `screens.production-board.overview.activities.types.${this.props.productionBoardActivity.type}`;
    return <FormattedMessage id={typeTranslationKey} />;
  };

  renderSubtitle = () => {
    switch (this.props.productionBoardActivity.type) {
      case 'request_created':
        return <span>{this.props.request.title}</span>;
      case 'request_archived':
        return <span>{this.props.request.title}</span>;
      case 'request_work_order_created':
        return <span>{this.props.request.title}</span>;
      case 'work_order_completed':
        return (
          <span>
            {this.props.workOrder.number} - {this.props.workOrder.title}
          </span>
        );
      case 'operational_maintenance_instance_completed':
        return <span>{this.props.operationalMaintenanceTemplateVersion.title}</span>;
      case 'downtime_created':
        const fromDate = moment(this.props.downtime.from);
        const toDate = this.props.downtime.to ? moment(this.props.downtime.to) : moment();
        const diffInMinutes = toDate.diff(fromDate, 'minutes');
        return (
          <span>
            <ElapsedTime short elapsedMinutes={diffInMinutes} />
            {this.props.downtime.to ? null : (
              <span>
                <span> - </span>
                <FormattedMessage id="screens.production-board.overview.activities.downtime-active" />
              </span>
            )}
          </span>
        );
      default:
        return null;
    }
  };

  renderTime = () => {
    const formattedTimeString = moment(this.props.productionBoardActivity.created_at)
      .tz(this.props.system.timezone)
      .format('LT');

    return <span>{formattedTimeString}</span>;
  };

  renderOpenButton = () => {
    if (this.props.downtime) {
      return (
        <Button gray label="general.open" small onClick={() => this.setState({ showDowntimeModal: true })} />
      );
    }

    const link = this.getLink();
    return (
      <Link to={link}>
        <Button gray label="general.open" small />
      </Link>
    );
  };

  renderDowntimeModal = () => (
    <DowntimeModal
      open={this.state.showDowntimeModal}
      id={this.props.downtime?.id}
      onClose={() => this.setState({ showDowntimeModal: false })}
    />
  );

  render() {
    return (
      <>
        <div
          className={styles['board-activity-cell-wrapper']}
          onMouseEnter={() => this.setState({ showOpen: true })}
          onMouseLeave={() => this.setState({ showOpen: false })}
        >
          <div className={styles['board-activity-cell']}>
            <div className={styles['content-container']}>
              <div>
                <div className={styles['board-activity-cell-title-container']}>
                  <div className={styles['board-activity-cell-title']}>{this.renderActivityType()}</div>
                  <div className={styles['board-activity-cell-subtitle']}>{this.renderSubtitle()}</div>
                  <div className={styles['border-circle-container']}>
                    <div className={styles['border-circle']} />
                  </div>
                </div>
                <div className={styles['board-activity-cell-time']}>{this.renderTime()}</div>
              </div>
              <div className={styles['button-container']} style={{ opacity: this.state.showOpen ? 1 : 0 }}>
                {this.renderOpenButton()}
              </div>
            </div>
          </div>
        </div>
        {this.renderDowntimeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
  const { activityId } = ownProps;
  const productionBoardActivity = EntitySelectors.getProductionBoardActivity(state, activityId);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    productionBoardActivity.operational_maintenance_instance_id
  );
  let operationalMaintenanceTemplateVersion = null;
  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );
  }
  return {
    productionBoardActivity,
    system: AuthSelectors.getCurrentSystem(state),
    request: EntitySelectors.getRequest(state, productionBoardActivity.request_id),
    workOrder: EntitySelectors.getWorkOrder(state, productionBoardActivity.work_order_id),
    operationalMaintenanceTemplateVersion,
    downtime: EntitySelectors.getDowntime(state, productionBoardActivity.downtime_id),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
