import actions from './actions';
import {
  normalizeOperationalMaintenance,
  normalizeOperationalMaintenanceBreak,
  normalizeOperationalMaintenanceTemplate,
} from 'sdk/Schemas';
import { has } from 'lodash-es';
import EntityOperations from 'sdk/State/entities/operations';
import { API, HelperFunctions } from 'sdk';

const {
  setNavigatedToOperationalMaintenance,
  addQueryParameterForTemplates,
  addQueryParameterForOperationalMaintenances,
  selectOperationalMaintenancesPage,
  selectOperationalMaintenance,
  resetSelectedOperationalMaintenances,
  toggleExpandAsset,
  setOperationalMaintenanceListCountLoading,
  unitializeListCount,
  resetOperationalMaintenanceTemplatesState,
} = actions;

const fetchOperationalMaintenancesForCalendar = (systemId, params, config) => dispatch => {
  return API.listOperationalMaintenances(systemId, params, config).then(
    ({ data: operationalMaintenances }) => {
      if (has(params, 'asset_ids')) {
        let operationalMaintenancesForAssetId = {};
        const assetIds = Object.keys(operationalMaintenances);

        assetIds.forEach(id => {
          const { entities, result } = normalizeOperationalMaintenance(operationalMaintenances[id]);
          operationalMaintenancesForAssetId = {
            ...operationalMaintenancesForAssetId,
            [id]: result,
          };
          dispatch(EntityOperations.updateEntities(entities));
        });
        dispatch(
          actions.fetchOperationalMaintenancesForCalendarSplitByAssetsSuccess({
            operationalMaintenancesForAssetId,
          })
        );
      } else {
        const { entities, result } = normalizeOperationalMaintenance(operationalMaintenances);
        dispatch(EntityOperations.updateEntities(entities));
        dispatch(
          actions.fetchOperationalMaintenancesForCalendarSuccess({
            ids: result,
          })
        );
      }
    }
  );
};

const fetchOperationalMaintenances = (systemId, params, config) => dispatch => {
  return API.listOperationalMaintenances(systemId, params, config).then(
    ({ data: operationalMaintenances }) => {
      const operationalMaintenancesWithIds = operationalMaintenances;
      const { entities, result } = normalizeOperationalMaintenance(operationalMaintenancesWithIds);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchOperationalMaintenancesSuccess({ ids: result }));
    }
  );
};

const fetchOperationalMaintenanceListCount = (systemId, params, config) => dispatch => {
  dispatch(actions.setOperationalMaintenanceListCountLoading());
  return API.fetchOperationalMaintenanceListCount(systemId, params, config).then(({ data }) => {
    dispatch(actions.fetchOperationalMaintenanceListCountSuccess({ data }));
  });
};

const fetchOperationalMaintenanceTemplates = (systemId, params) => dispatch => {
  return API.listOperationalMaintenanceTemplates(systemId, params).then(
    ({ data: operationalMaintenanceTemplates, headers }) => {
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      const { entities, result } = normalizeOperationalMaintenanceTemplate(operationalMaintenanceTemplates);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchOperationalMaintenancesTemplatesSuccess({
          ids: result,
          pagination,
        })
      );
    }
  );
};

const fetchOperationalMaintenanceBreaks = (systemId, params) => dispatch => {
  return API.listOperationalMaintenanceBreaks(systemId, params).then(
    ({ data: operationalMaintenanceBreaks, headers }) => {
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      const { entities, result } = normalizeOperationalMaintenanceBreak(operationalMaintenanceBreaks);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchOperationalMaintenancesBreaksSuccess({
          ids: result,
          pagination,
        })
      );
    }
  );
};

export default {
  toggleExpandAsset,
  fetchOperationalMaintenancesForCalendar,
  fetchOperationalMaintenances,
  fetchOperationalMaintenanceListCount,
  fetchOperationalMaintenanceTemplates,
  fetchOperationalMaintenanceBreaks,
  selectOperationalMaintenancesPage,
  selectOperationalMaintenance,
  resetSelectedOperationalMaintenances,
  addQueryParameterForTemplates,
  setNavigatedToOperationalMaintenance,
  setOperationalMaintenanceListCountLoading,
  addQueryParameterForOperationalMaintenances,
  unitializeListCount,
  resetOperationalMaintenanceTemplatesState,
};
