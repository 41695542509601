import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import {
  Button,
  List,
  EmptyDataSet,
  NewSearchField,
  WhiteCard,
  Loader,
  Pagination,
} from 'views/components/Shared/General';
import ContactPersonSmall from 'assets/images/EmptyDataSet/ContactPersonSmall.png';
import ContactPersonListItem from './ContactPersonListItem';
import ContactPersonModal from './ContactPersonModal';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import styles from './style.module.scss';
import DeleteContactPersonModal from './DeleteContactPersonModal';

const QUICK_FILTERS = ['search'];

class ContactPersons extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      showContactPersonModal: false,
      selectedContactPersonId: null,
      tableLoading: false,
      viewInitialized: false,
      searchTerm: queryParams.q || '',
      queryParams: queryParams,
      showDeleteContactPersonModalForId: null,
      showDeleteContactPersonModal: false,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchContactPersons()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchContactPersons()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchContactPersons = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams, QUICK_FILTERS);

    return this.props
      .fetchContactPersons(this.props.vendor.id, filterParams)
      .then(({ data: contact_persons }) => {
        return contact_persons;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  isFiltering = () => this.state.searchTerm !== '';

  newContactPerson = () => {
    this.setState({ showContactPersonModal: true });
  };

  openContactPerson = id => {
    this.setState({ showContactPersonModal: true, selectedContactPersonId: id });
  };

  renderContactPersonModal = () => (
    <ContactPersonModal
      id={this.state.selectedContactPersonId}
      open={this.state.showContactPersonModal}
      onClose={() => {
        this.setState({ showContactPersonModal: false, selectedContactPersonId: null });
      }}
      onDelete={id =>
        setTimeout(
          () => this.setState({ showDeleteContactPersonModalForId: id, showDeleteContactPersonModal: true }),
          250
        )
      }
    />
  );

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.vendor.contact-persons.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderDeleteContactPersonModal = () => (
    <DeleteContactPersonModal
      id={this.state.showDeleteContactPersonModalForId}
      open={this.state.showDeleteContactPersonModal}
      title={
        <FormattedMessage id="screens.vendor.contact-persons.contact-person-delete-modal.confirm-delete.title" />
      }
      subtitle={
        <FormattedMessage id="screens.vendor.contact-persons.contact-person-delete-modal.confirm-delete.subtitle" />
      }
      onClose={() =>
        this.setState({ showDeleteContactPersonModalForId: null, showDeleteContactPersonModal: false })
      }
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.vendor.contact-persons.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.vendor.contact-persons.empty-data-set.subtitle" />}
        image={ContactPersonSmall}
        button={
          this.props.canEditVendors ? (
            <Button
              small
              primary
              onClick={this.newContactPerson}
              label="screens.vendor.contact-persons.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => (
    <Toolbar
      buttons={
        this.props.canEditVendors ? (
          <Button
            primary
            label="screens.vendor.contact-persons.create-button"
            onClick={this.newContactPerson}
          />
        ) : null
      }
      search={
        <NewSearchField
          againstGrayBackground
          small
          debounce
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.searchTerm}
          onSearch={searchTerm => this.setState({ searchTerm, tableLoading: true })}
          onClear={() => {
            this.setState({ tableLoading: true, searchTerm: '' });
            this.changeQueryParams({
              search: '',
              page: null,
            });
          }}
          onDebouncedSearch={value => {
            this.changeQueryParams({
              search: value,
              page: null,
            });
          }}
        />
      }
    />
  );

  renderEmptySearch = () => {
    if (this.props.contactPersonIds.length > 0 || !this.isFiltering() || this.state.tableLoading) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          image={SearchImage}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.contactPersonIds.length === 0 ? 1 : this.props.contactPersonIds.length)
              .fill()
              .map(() => (
                <ContactPersonListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.contactPersonIds.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.contactPersonIds.map(id => (
            <ContactPersonListItem
              contactPersonId={id}
              onClick={() => {
                this.openContactPerson(id);
              }}
              new={this.props.createdIds.includes(id)}
              onDelete={() =>
                this.setState({ showDeleteContactPersonModalForId: id, showDeleteContactPersonModal: true })
              }
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderContactPersons = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderEmptySearch()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.contactPersonIds.length === 0 && !this.isFiltering() && !this.state.tableLoading) {
      return this.renderEmptyDataset();
    } else {
      return this.renderContactPersons();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderContactPersonModal()}
        {this.renderDeleteContactPersonModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchContactPersons: VendorOperations.fetchContactPersons,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    vendor: VendorSelectors.getVendor(state),
    contactPersonIds: VendorSelectors.getContactPersonIds(state),
    createdIds: VendorSelectors.getContactPersonsCreatedIds(state),
    pagination: VendorSelectors.getContactPersonsPagination(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactPersons)));
