import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  ConfirmDeleteInlineModal,
  FileUploadWrapper,
  Tooltip,
  Icon,
} from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class LogoWhiteCard extends Component {
  state = {
    isSaving: false,
    isDeleting: false,
  };

  getBackgroundImageUrl = () => {
    if (this.props.system.logo_image_id) {
      return `${process.env.REACT_APP_BROWSER_URL}images/${this.props.system.logo_image_id}`;
    }
  };

  createSystemLogo = ({ image, mime_type }) => {
    this.setState({ isSaving: true });
    this.props
      .createImageForSystemLogo(this.props.system.id, { image, mime_type })
      .then(() => {
        this.setState({ isSaving: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.company.logo.create-success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  deleteSystemLogo = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteImage(this.props.system.logo_image_id)
      .then(() => {
        this.setState({ isDeleting: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.company.logo.delete-success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ isDeleting: false });
      });
  };

  render() {
    if (this.props.system.logo_image_id) {
      return (
        <div className={styles['image-container']}>
          <img alt="" className={styles['logo-image']} src={this.getBackgroundImageUrl()} />
          <div className={styles['buttons']}>
            <FileUploadWrapper
              image
              disabled={this.state.isSaving}
              accept="image/png, image/jpg, image/jpeg, image/gif"
              onSelectFile={({ file, mime_type, name }) => this.createSystemLogo({ image: file, mime_type })}
            >
              <Tooltip
                trigger={
                  <div>
                    <Button type="icon" icon={<Icon regular type="pen" />} />
                  </div>
                }
                label={<FormattedMessage id="screens.settings.company.logo.edit-tooltip" />}
              />
            </FileUploadWrapper>
            <ConfirmDeleteInlineModal
              trigger={
                <Tooltip
                  trigger={
                    <div>
                      <Button type="icon" icon={<Icon regular red type="trash-alt" />} />
                    </div>
                  }
                  label={<FormattedMessage id="screens.settings.company.logo.delete-tooltip" />}
                />
              }
              title={<FormattedMessage id="general.delete-confirm.title" />}
              onDelete={this.deleteSystemLogo}
              loading={this.state.isDeleting}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles['logo-container']}>
          <FileUploadWrapper
            image
            disabled={this.state.isSaving}
            accept="image/png, image/jpg, image/jpeg, image/gif"
            onSelectFile={({ file, mime_type, name }) => this.createSystemLogo({ image: file, mime_type })}
          >
            <Tooltip
              trigger={
                <div className={styles['new-image']}>
                  <Icon type="image" />
                </div>
              }
              label={<FormattedMessage id="screens.settings.company.logo.create-tooltip" />}
            />
          </FileUploadWrapper>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createImageForSystemLogo: SDKReduxOperations.createImageForSystemLogo,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LogoWhiteCard));
