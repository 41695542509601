import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Toggle, Field, SectionHeader } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { WorkOrderCategoryType } from 'sdk/System';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class MaintenanceCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavingActivateCategories: false,
      work_order_category_type: this.props.settings.work_order_category_type,
    };
  }

  updateSettings = params => {
    this.props
      .updateSettings(this.props.currentSystem.id, params)
      .then(() => {
        this.setState({ isSavingActivateCategories: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSavingActivateCategories: false });
      });
  };

  renderCategoryTitle = () => {
    if (this.state.work_order_category_type === WorkOrderCategoryType.Detailed) {
      return (
        <SectionHeader paddingHorizontal={35}>
          <FormattedMessage id="screens.settings.work-orders.categories.detailed" />
        </SectionHeader>
      );
    }
    return (
      <SectionHeader paddingHorizontal={35}>
        <FormattedMessage id="screens.settings.work-orders.categories.simple" />
      </SectionHeader>
    );
  };

  renderDetailedCorrectiveCategories = () => {
    return (
      <AnimateHeight
        duration={250}
        height={this.state.work_order_category_type === WorkOrderCategoryType.Detailed ? 'auto' : 0}
      >
        <div className={styles['detailed-categories']}>
          <div className={styles['category']}>
            <FormattedMessage id="resources.work-order.categories.corrective-defered" />
          </div>
          <div className={styles['category']}>
            <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
          </div>
        </div>
      </AnimateHeight>
    );
  };

  renderDetailedPreventiveCategories = () => {
    return (
      <AnimateHeight
        duration={250}
        height={this.state.work_order_category_type === WorkOrderCategoryType.Detailed ? 'auto' : 0}
      >
        <div className={styles['detailed-categories']}>
          <div className={styles['category']}>
            <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
          </div>
          <div className={styles['category']}>
            <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
          </div>
        </div>
      </AnimateHeight>
    );
  };

  renderCategoryContent = () => {
    return (
      <>
        <div className={styles['content']}>
          <div className={[styles['category-container'], styles['corrective']].join(' ')}>
            <div className={styles['category']}>
              <FormattedMessage id="resources.work-order.categories-short.corrective" />
            </div>
            {this.renderDetailedCorrectiveCategories()}
          </div>
          <div className={styles['category-container']}>
            <div className={styles['category']}>
              <FormattedMessage id="resources.work-order.categories-short.preventive" />
            </div>
            {this.renderDetailedPreventiveCategories()}
          </div>
          <div className={[styles['category-container'], styles['improvement']].join(' ')}>
            <div className={styles['category']}>
              <FormattedMessage id="resources.work-order.categories-short.improvement" />
            </div>
          </div>
          <div className={[styles['category-container'], styles['modification']].join(' ')}>
            <div className={styles['category']}>
              <FormattedMessage id="resources.work-order.categories-short.modification" />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderCategory = () => {
    return (
      <>
        <div className={styles['category-wrapper']}>
          {this.renderCategoryTitle()}
          {this.renderCategoryContent()}
        </div>
      </>
    );
  };

  renderRadioButtons() {
    return (
      <>
        <Field.Radio.Group>
          <Field.Radio
            label={<FormattedMessage id="screens.settings.work-orders.categories.simple" />}
            checked={this.state.work_order_category_type === WorkOrderCategoryType.Simple}
            onChange={() => {
              this.setState({ work_order_category_type: WorkOrderCategoryType.Simple }, () => {
                this.updateSettings({
                  work_order_category_type: this.state.work_order_category_type,
                });
              });
            }}
          />

          <Field.Radio
            label={<FormattedMessage id="screens.settings.work-orders.categories.detailed" />}
            checked={this.state.work_order_category_type === WorkOrderCategoryType.Detailed}
            onChange={() => {
              this.setState({ work_order_category_type: WorkOrderCategoryType.Detailed }, () => {
                this.updateSettings({
                  work_order_category_type: this.state.work_order_category_type,
                });
              });
            }}
          />
        </Field.Radio.Group>
      </>
    );
  }

  render() {
    return (
      <>
        <BackButton to="/settings/work-orders" />
        <Section
          title={
            <>
              <FormattedMessage id="screens.settings.work-orders.categories.title" />

              <span className={styles['title-description']}>
                <span> - </span>
                <FormattedMessage id="screens.settings.work-orders.categories.title-description" />
              </span>
            </>
          }
          subtitle={<FormattedMessage id="screens.settings.work-orders.categories.subtitle" />}
          rightComponent={
            <Toggle
              checked={this.state.work_order_category_type !== WorkOrderCategoryType.None}
              loading={this.state.isSavingActivateCategories}
              onClick={() => {
                if (this.state.isSavingActivateCategories) return;
                this.setState({ isSavingActivateCategories: true });
                this.setState(
                  prevState => ({
                    work_order_category_type:
                      prevState.work_order_category_type !== WorkOrderCategoryType.None
                        ? WorkOrderCategoryType.None
                        : WorkOrderCategoryType.Simple,
                  }),
                  () => {
                    this.updateSettings({
                      work_order_category_type: this.state.work_order_category_type,
                    });
                  }
                );
              }}
            />
          }
        />
        <AnimateHeight
          duration={250}
          height={this.state.work_order_category_type !== WorkOrderCategoryType.None ? 'auto' : 0}
        >
          <>
            <Separator />
            {this.renderRadioButtons()}
            {this.renderCategory()}
          </>
        </AnimateHeight>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceCategories);
