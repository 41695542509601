import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class AddButton extends Component {
  renderLockIcon = () => {
    return this.props.inactive ? (
      <div className={styles['lock-container']}>
        <Icon gray type="lock" size={16} />
      </div>
    ) : null;
  };
  render() {
    let classNames = [styles['add-container']];
    if (this.props.inactive) {
      classNames = [...classNames, styles['inactive']];
    }
    return (
      <WhiteCard noPadding clickable>
        <div className={classNames.join(' ')} onClick={this.props.onClick}>
          <div className={styles['icon-container']}>
            <Icon withBackground blue type={this.props.icon} backgroundSize={40} size={18} />
          </div>
          <div className={styles['text-container']}>
            <p className={styles['add-title']}>{this.props.title}</p>
            <p className={styles['add-subtitle']}>{this.props.subtitle}</p>
          </div>
          {this.renderLockIcon()}
        </div>
      </WhiteCard>
    );
  }
}

AddButton.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  icon: PropTypes.node,
};

AddButton.defaultProps = {
  title: '',
  subtitle: '',
  icon: '',
  onClick: () => {},
  inactive: false,
};
