import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, NewTab, NewInlineModal, Tooltip, Icon } from 'views/components/Shared/General';
import { Header, ContentContainer } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { WorkOrderCategoryType } from 'sdk/System';
import { REPORT_TYPES, REPORT_PER_TYPES, REPORT_SPLIT_PER_TYPES } from 'sdk/WorkOrder';
import { ExportType } from 'sdk/Export';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import {
  normalizeWorkOrderType,
  normalizeAsset,
  normalizeUser,
  normalizeGroup,
  normalizeVendor,
} from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import HelperFunctions from 'utilities/HelperFunctions';
import FilterTopBar from './FilterTopBar';
import AdvancedFilter from './AdvancedFilter';
import ListView from './ListView';
import Chart from './Chart';
import SortInlineModal from './SortInlineModal';
import ExportModal from './ExportModal';
import styles from './style.module.scss';

const getReportRequest = SDKHelperFunctions.getCancelTokenForRequest();

class WorkOrders extends Component {
  constructor(props) {
    super(props);

    const yearSystemWasCreated = moment(this.props.currentSystem.created_at).year();
    const yearSinceSystemWasCreated = moment().year() - moment(this.props.currentSystem.created_at).year();
    const systemWasCreatedThisYear = yearSystemWasCreated === moment().year();

    this.state = {
      showDropDown: false,
      isFetching: true,
      hideEditStatistics: true,
      showAdvancedFilter: false,
      showPerIsOpen: false,
      showPerSplitTypeIsOpen: false,
      showPerTypeIsOpen: false,
      type: REPORT_TYPES.Count,
      perType: systemWasCreatedThisYear ? REPORT_PER_TYPES.Month : REPORT_PER_TYPES.Year,
      splitPerType: null,
      sortingOnType: null,
      sortingOnPerSplitType: null,
      sortingOnPerType: systemWasCreatedThisYear ? REPORT_PER_TYPES.Month : REPORT_PER_TYPES.Year,
      yearSystemWasCreated,
      yearSinceSystemWasCreated,
      systemWasCreatedThisYear,
      filters: {
        year: systemWasCreatedThisYear ? yearSystemWasCreated : null,
        from_completed_date: null,
        to_completed_date: null,
      },
      advancedFilters: {
        category: null,
        work_order_type_ids: null,
        priority: null,
        asset_type_ids: null,
        asset_id: null,
        asset_with_tree_children_id: null,
        created_by_user_id: null,
        is_recurring: null,
        completed_before_registration: null,
      },
      data: {},
      workOrderTypes: [],
      assets: [],
      assigneeUsers: [],
      assigneeGroups: [],
      assigneeVendors: [],
      completedByUsers: [],
      showingAssetId: null,
      isShowingChart: true,
      showExportModal: false,
    };
  }

  renderBreadcrumb = () => {
    return (
      <Header.Breadcrumb>
        <Header.BreadcrumbItem to={{ pathname: '/statistics' }}>
          <FormattedMessage id="screens.statistics-dashboard.breadcrumb-root" />
        </Header.BreadcrumbItem>
        <Header.BreadcrumbItem>
          <FormattedMessage id="screens.statistics-work-orders.breadcrumb-root" />
        </Header.BreadcrumbItem>
      </Header.Breadcrumb>
    );
  };

  componentDidMount() {
    const { type, perType } = this.state;
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Statistics);
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.statistics-work-orders.document-title' })
    );
    this.fetchReport({
      show: type,
      per: perType,
    }).then(data => {
      this.setState({
        data: data,
        isFetching: false,
        isShowingChart: true,
      });
    });
  }

  fetchReport = params => {
    return API.getWorkOrderReport(
      this.props.currentSystem.id,
      params,
      getReportRequest.getCancelTokenConfig()
    ).then(({ data }) => {
      return data;
    });
  };

  transformAdvancedFiltersToQueryParams = () => {
    const {
      category,
      work_order_type_ids,
      priority,
      asset_type_ids,
      asset_id,
      asset_with_tree_children_id,
      created_by_user_id,
      completed_before_registration,
      is_recurring,
    } = this.state.advancedFilters;
    let queryParams = {
      category,
      priority,
      created_by_user_id,
      asset_with_tree_children_id,
      asset_id,
      completed_before_registration,
    };
    if (work_order_type_ids) {
      queryParams = {
        ...queryParams,
        work_order_type_id: work_order_type_ids,
      };
    }
    if (asset_type_ids) {
      queryParams = {
        ...queryParams,
        asset: {
          asset_type_id: asset_type_ids,
        },
      };
    }
    if (is_recurring) {
      queryParams = {
        ...queryParams,
        recurring_maintenance_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: true,
        },
      };
    }

    return queryParams;
  };

  transformFiltersToQueryParams = () => {
    const { year, from_completed_date, to_completed_date } = this.state.filters;
    let queryParams = {};
    if (from_completed_date || to_completed_date) {
      queryParams = {
        ...queryParams,
        completed_date: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Between]: `${from_completed_date}...${to_completed_date}`,
        },
      };
    }
    if (year) {
      queryParams = {
        ...queryParams,
        completed_date: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Between]: `${year}-01-01...${year}-12-31`,
        },
      };
    }
    return queryParams;
  };

  selectType = type => {
    if (this.state.type === type) return;
    this.setState({
      type,
      sortingOnType: null,
      sortingOnPerType: this.state.perType,
      isFetching: true,
    });
    const params = {
      show: type,
      per: this.state.perType,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    };
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      params.asset_tree_parent_id = this.state.showingAssetId;
    }
    this.fetchReport(params)
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectPerType = perType => {
    if (this.state.perType === perType) return;
    let splitPerType = this.state.splitPerType;
    if (
      perType === REPORT_PER_TYPES.Priority &&
      this.state.splitPerType === REPORT_SPLIT_PER_TYPES.Priority
    ) {
      splitPerType = null;
    }
    if (
      (this.state.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ||
        this.state.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) &&
      (perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
        perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed)
    ) {
      splitPerType = null;
    }
    this.setState({
      perType,
      sortingOnType: null,
      splitPerType,
      sortingOnPerType: perType,
      isFetching: true,
      showingAssetId: null,
    });
    getReportRequest.cancel();
    if (perType === REPORT_PER_TYPES.WorkOrderType) {
      API.listWorkOrderTypes(this.props.currentSystem.id)
        .then(({ data }) => {
          const { entities, result } = normalizeWorkOrderType(data);
          this.props.updateEntities(entities);
          this.fetchReport({
            show: this.state.type,
            per: perType,
            ...this.transformAdvancedFiltersToQueryParams(),
            ...this.transformFiltersToQueryParams(),
          })
            .then(data => {
              this.setState({
                workOrderTypes: result.map(id => entities.workOrderTypeById[id]),
                data,
                isFetching: false,
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }
    if (perType === REPORT_PER_TYPES.Asset) {
      let attrs = {
        no_pagination: true,
        tree_parent_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
      API.listAssets(this.props.currentSystem.id, attrs)
        .then(({ data }) => {
          const { entities, result } = normalizeAsset(data);
          this.props.updateEntities(entities);
          this.fetchReport({
            show: this.state.type,
            per: perType,
            ...this.transformAdvancedFiltersToQueryParams(),
            ...this.transformFiltersToQueryParams(),
          })
            .then(data => {
              this.setState({
                assets: result.map(id => entities.assetById[id]),
                data,
                isFetching: false,
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }
    if (perType === REPORT_PER_TYPES.Assignee) {
      Promise.all([
        API.listUsers(this.props.selectedSystem.id, { member: true, no_pagination: true }),
        API.listGroups(this.props.selectedSystem.id, { type: 'work_order_assignee', no_pagination: true }),
        API.listVendors(this.props.selectedSystem.id, { assign_work_orders: true, no_pagination: true }),
      ]).then(([usersRes, groupsRes, vendorsRes]) => {
        const { data: users } = usersRes;
        const { data: groups } = groupsRes;
        const { data: vendors } = vendorsRes;
        const { entities: userEntities } = normalizeUser(users);
        const { entities: groupEntities } = normalizeGroup(groups);
        const { entities: vendorEntities } = normalizeVendor(vendors);
        this.props.updateEntities({ ...userEntities, ...groupEntities, ...vendorEntities });
        this.fetchReport({
          show: this.state.type,
          per: perType,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        })
          .then(data => {
            let assigneeUsers = [];
            let assigneeGroups = [];
            let assigneeVendors = [];
            Object.keys(data).forEach(key => {
              if (key.charAt(0) === 'u') {
                const userId = key.substring(2);
                assigneeUsers = [...assigneeUsers, userEntities.userById[userId]];
              } else if (key.charAt(0) === 'g') {
                const groupId = key.substring(2);
                assigneeGroups = [...assigneeGroups, groupEntities.groupById[groupId]];
              } else if (key.charAt(0) === 'v') {
                const vendorId = key.substring(2);
                assigneeVendors = [...assigneeVendors, vendorEntities.vendorById[vendorId]];
              }
            });
            this.setState({
              data,
              assigneeUsers,
              assigneeGroups,
              assigneeVendors,
              isFetching: false,
            });
          })
          .catch(() => {});
      });
      return;
    }
    if (perType === REPORT_PER_TYPES.CompletedBy) {
      API.listUsers(this.props.currentSystem.id, { member: true, no_pagination: true })
        .then(({ data }) => {
          const { entities, result } = normalizeUser(data);
          this.props.updateEntities(entities);
          this.fetchReport({
            show: this.state.type,
            per: perType,
            ...this.transformAdvancedFiltersToQueryParams(),
            ...this.transformFiltersToQueryParams(),
          })
            .then(data => {
              this.setState({
                completedByUsers: result.map(id => entities.userById[id]),
                data,
                isFetching: false,
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }

    this.fetchReport({
      show: this.state.type,
      per: perType,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    })
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectSplitPerType = splitPerType => {
    if (this.state.splitPerType === splitPerType) return;
    if (
      this.state.perType === REPORT_PER_TYPES.Priority &&
      splitPerType === REPORT_SPLIT_PER_TYPES.Priority
    ) {
      return;
    }
    if (
      (splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ||
        splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) &&
      (this.state.perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
        this.state.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed)
    )
      return;
    if (!this.state.isShowingChart) return;
    this.setState({
      splitPerType,
      sortingOnPerSplitType: null,
      sortingOnPerType: this.state.perType,
      isFetching: true,
    });
    let params = {
      show: this.state.type,
      per: this.state.perType,
      split: splitPerType,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    };
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      params.asset_tree_parent_id = this.state.showingAssetId;
    }
    this.fetchReport(params)
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectYear = year => {
    let perType = this.state.perType;
    let sortingOnPerType = this.state.sortingOnPerType;
    if (year && perType === REPORT_PER_TYPES.Year) {
      perType = REPORT_PER_TYPES.Month;
      sortingOnPerType = REPORT_PER_TYPES.Month;
    } else if (year == null && perType === REPORT_PER_TYPES.Month) {
      perType = REPORT_PER_TYPES.Year;
      sortingOnPerType = REPORT_PER_TYPES.Year;
    }
    let filters = { year, from_completed_date: null, to_completed_date: null };
    this.setState(
      {
        perType,
        sortingOnPerType,
        filters,
        isFetching: true,
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: perType,
          split: this.state.splitPerType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        this.fetchReport(reportParams).then(data => {
          this.setState({
            data: data,
            isFetching: false,
            isShowingChart: true,
          });
        });
      }
    );
  };

  selectCustomDate = (from, to) => {
    const filters = { year: null, from_completed_date: from, to_completed_date: to };
    this.setState(
      {
        filters,
        isFetching: true,
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: this.state.perType,
          split: this.state.splitPerType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        this.fetchReport(reportParams).then(data => {
          this.setState({
            data: data,
            isFetching: false,
            isShowingChart: true,
          });
        });
      }
    );
  };

  applyAdvancedFilter = filters => {
    this.setState(
      {
        isFetching: true,
        showAdvancedFilter: false,
        advancedFilters: {
          ...this.state.advancedFilters,
          ...filters,
        },
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: this.state.perType,
          split: this.state.splitPerType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        this.fetchReport(reportParams).then(data => {
          this.setState({
            data: data,
            isFetching: false,
            isShowingChart: true,
          });
        });
      }
    );
  };

  amountOfAppliedAdvancedFilters = () => {
    const {
      category,
      asset_id,
      asset_with_tree_children_id,
      work_order_type_ids,
      priority,
      asset_type_ids,
      created_by_user_id,
      is_recurring,
      completed_before_registration,
    } = this.state.advancedFilters;
    let count = 0;
    if (category) count++;
    if (asset_id || asset_with_tree_children_id) count++;
    if (work_order_type_ids) count++;
    if (priority) count++;
    if (asset_type_ids) count++;
    if (created_by_user_id) count++;
    if (is_recurring) count++;
    if (completed_before_registration) count++;
    return count;
  };

  selectAsset = assetId => {
    if (this.state.showingAssetId === assetId) return;
    this.setState({ showingAssetId: assetId, isFetching: true });
    let attrs = {
      no_pagination: true,
    };
    if (assetId == null) {
      attrs = {
        ...attrs,
        tree_parent_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
    } else {
      attrs = {
        ...attrs,
        tree_parent_id: assetId,
      };
    }
    API.listAssets(this.props.currentSystem.id, attrs).then(({ data }) => {
      const { entities, result } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.fetchReport({
        show: this.state.type,
        per: this.state.perType,
        asset_tree_parent_id: assetId,
        ...this.transformAdvancedFiltersToQueryParams(),
        ...this.transformFiltersToQueryParams(),
      })
        .then(data => {
          this.setState({ assets: result.map(id => entities.assetById[id]), data, isFetching: false });
        })
        .catch(() => {});
    });
  };

  renderSelectedAsset = () => {
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      return (
        <div className={styles['selected-asset-container']}>
          <span className={styles['text']} onClick={() => this.selectAsset(null)}>
            <FormattedMessage id="components.work-orders-overview.all-assets" />
          </span>
          {this.state.showingAssetId ? (
            <>
              <Icon type="arrow-alt-right" />
              <TreePath
                size={12}
                dark
                fullPath
                renderItem={asset => (
                  <span className={styles['text']} onClick={() => this.selectAsset(asset.id)}>
                    {asset.title}
                  </span>
                )}
                assetId={this.state.showingAssetId}
              />
            </>
          ) : null}
          {this.state.assets.length === 0 ? null : (
            <>
              <div
                className={styles['select-asset-dropdown']}
                onClick={() => {
                  this.setState(prevState => ({
                    showDropDown: !prevState.showDropDown,
                  }));
                }}
                ref={ref => (this.inlineModalPositioningRef = ref)}
              >
                <Icon type="arrow-alt-right" />
                <span className={styles['text']}>
                  <FormattedMessage id="components.work-orders-overview.select-assets" />
                </span>
                <Icon type="angle-down" />
              </div>
              <NewInlineModal
                positionToRef={this.inlineModalPositioningRef}
                open={this.state.showDropDown}
                onClose={() => this.setState({ showDropDown: false })}
                minWidth={200}
              >
                <NewInlineModal.Dropdown>
                  <NewInlineModal.Dropdown.Items>
                    {this.state.assets
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map(({ title, id }) => (
                        <NewInlineModal.Dropdown.Item
                          onClick={() => {
                            this.selectAsset(id);
                            this.setState({ showDropDown: false });
                          }}
                        >
                          {title}
                        </NewInlineModal.Dropdown.Item>
                      ))}
                  </NewInlineModal.Dropdown.Items>
                </NewInlineModal.Dropdown>
              </NewInlineModal>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  renderCategorySimple = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          this.state.perType === REPORT_PER_TYPES.WorkOrderCategorySimple ? styles['selected'] : ''
        }`}
        onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderCategorySimple)}
      >
        <FormattedMessage id="components.work-orders-overview.show-per-types.category_simple" />
      </div>
    );
  };

  renderCategoryDetailed = () => {
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.Detailed) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          this.state.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ? styles['selected'] : ''
        }`}
        onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderCategoryDetailed)}
      >
        <FormattedMessage id="components.work-orders-overview.show-per-types.category_detailed" />
      </div>
    );
  };

  renderSplitByWorkOrderCategorySimple = () => {
    const { perType, splitPerType } = this.state;
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ? styles['selected'] : ''
        } ${
          perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
          perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ||
          !this.state.isShowingChart
            ? styles['disabled']
            : ''
        }`}
        onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple)}
      >
        <FormattedMessage id="components.work-orders-overview.show-per-split-types.category_simple" />
      </div>
    );
  };

  renderSplitByWorkOrderCategoryDetailed = () => {
    const { perType, splitPerType } = this.state;
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.Detailed) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed ? styles['selected'] : ''
        } ${
          perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
          perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ||
          !this.state.isShowingChart
            ? styles['disabled']
            : ''
        } `}
        onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed)}
      >
        <FormattedMessage id="components.work-orders-overview.show-per-split-types.category_detailed" />
      </div>
    );
  };

  renderStatistics = () => {
    const {
      showPerIsOpen,
      showPerTypeIsOpen,
      systemWasCreatedThisYear,
      type,
      filters,
      perType,
      isShowingChart,
      showPerSplitTypeIsOpen,
      splitPerType,
    } = this.state;
    const isFilteringOnDate = filters.year || filters.from_completed_date || filters.to_completed_date;
    return (
      <div className={styles['statistics-container']}>
        <div className={styles['edit']}>
          <div className={styles['content']}>
            <div className={`${styles['type-container']} ${showPerIsOpen ? styles['selected'] : ''}`}>
              <div
                className={styles['type']}
                onClick={() => this.setState(prevState => ({ showPerIsOpen: !prevState.showPerIsOpen }))}
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.work-orders-overview.show" />
                  </span>
                  <span className={styles['subtitle']}>
                    <FormattedMessage id={`components.work-orders-overview.show-types.${type}`} />
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${type === REPORT_TYPES.Count ? styles['selected'] : ''}`}
                    onClick={() => this.selectType(REPORT_TYPES.Count)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-types.count" />
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <div className={`${styles['type-container']} ${showPerTypeIsOpen ? styles['selected'] : ''}`}>
              <div
                className={styles['type']}
                onClick={() =>
                  this.setState(prevState => ({
                    showPerTypeIsOpen: !prevState.showPerTypeIsOpen,
                    showPerIsOpen: false,
                  }))
                }
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.work-orders-overview.per" />
                  </span>
                  <span className={styles['subtitle']}>
                    {perType === REPORT_PER_TYPES.Asset ? (
                      <FormattedMessage id="components.work-orders-overview.show-per-types.asset" />
                    ) : (
                      <FormattedMessage id={`components.work-orders-overview.show-per-types.${perType}`} />
                    )}
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerTypeIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Year || perType === REPORT_PER_TYPES.Month
                        ? styles['selected']
                        : ''
                    }`}
                    onClick={() =>
                      this.selectPerType(
                        systemWasCreatedThisYear || perType === REPORT_PER_TYPES.Month || isFilteringOnDate
                          ? REPORT_PER_TYPES.Month
                          : REPORT_PER_TYPES.Year
                      )
                    }
                  >
                    {filters.year || filters.from_completed_date || filters.to_completed_date ? (
                      <FormattedMessage id="components.work-orders-overview.show-per-types.month" />
                    ) : (
                      <FormattedMessage id="components.work-orders-overview.show-per-types.year" />
                    )}
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Asset ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.Asset)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-types.asset" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Assignee ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.Assignee)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-types.assignee" />
                  </div>
                  {this.renderCategorySimple()}
                  {this.renderCategoryDetailed()}
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.WorkOrderType ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderType)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-types.work_order_type" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Priority ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.Priority)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-types.priority" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.CompletedBy ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.CompletedBy)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-types.completed_by_user" />
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <div
              className={`${styles['type-container']} ${showPerSplitTypeIsOpen ? styles['selected'] : ''}`}
            >
              <div
                className={`${styles['type']} ${!this.state.isShowingChart ? styles['disabled'] : ''}`}
                onClick={() => {
                  if (this.state.isShowingChart) {
                    this.setState(prevState => ({
                      showPerSplitTypeIsOpen: !prevState.showPerSplitTypeIsOpen,
                      showPerIsOpen: false,
                      showPerTypeIsOpen: false,
                    }));
                  }
                }}
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.work-orders-overview.split-per" />
                  </span>
                  <span className={styles['subtitle']}>
                    {splitPerType ? (
                      <FormattedMessage
                        id={`components.work-orders-overview.show-per-split-types.${splitPerType}`}
                      />
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerSplitTypeIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${splitPerType == null ? styles['selected'] : ''} ${
                      !this.state.isShowingChart ? styles['disabled'] : ''
                    }`}
                    onClick={() => this.selectSplitPerType(null)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-split-types.none" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      splitPerType === REPORT_SPLIT_PER_TYPES.Priority ? styles['selected'] : ''
                    } ${
                      perType === REPORT_PER_TYPES.Priority || !this.state.isShowingChart
                        ? styles['disabled']
                        : ''
                    }`}
                    onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.Priority)}
                  >
                    <FormattedMessage id="components.work-orders-overview.show-per-split-types.priority" />
                  </div>
                  {this.renderSplitByWorkOrderCategorySimple()}
                  {this.renderSplitByWorkOrderCategoryDetailed()}
                </div>
              </AnimateHeight>
            </div>
          </div>
        </div>
        <div className={styles['show']}>
          <div className={styles['container']}>
            <div className={styles['header']}>
              <div className={styles['title-container']}>
                <div className={styles['title']}>
                  <FormattedMessage id={`components.work-orders-overview.show-types.${type}`} />
                  <span> / </span>
                  {perType === REPORT_PER_TYPES.Asset ? (
                    <FormattedMessage id="components.work-orders-overview.show-per-types.asset" />
                  ) : (
                    <FormattedMessage id={`components.work-orders-overview.show-per-types.${perType}`} />
                  )}
                </div>
                <Button
                  type="text"
                  primary
                  noUnderline
                  fontSize={12}
                  label="general.edit"
                  onClick={() =>
                    this.setState(prevState => ({ hideEditStatistics: !prevState.hideEditStatistics }))
                  }
                />
              </div>
              <div className={styles['change-view']}>
                <NewTab.Container>
                  <NewTab
                    selected={isShowingChart}
                    onClick={() => this.setState({ isShowingChart: true })}
                    small
                  >
                    <Icon size={16} type="chart-bar" />
                  </NewTab>
                  <NewTab
                    selected={!isShowingChart}
                    onClick={() => {
                      if (this.state.splitPerType) {
                        this.selectSplitPerType(null);
                      }
                      this.setState({ isShowingChart: false });
                    }}
                    small
                  >
                    <Icon size={16} type="list" />
                  </NewTab>
                </NewTab.Container>
              </div>
              <div className={styles['sort-container']}>
                <SortInlineModal
                  type={this.state.type}
                  perType={this.state.perType}
                  sortingOnType={this.state.sortingOnType}
                  sortingOnPerType={this.state.sortingOnPerType}
                  sortingOnPerSplitType={this.state.sortingOnPerSplitType}
                  onSortOnType={() =>
                    this.setState({
                      sortingOnType: this.state.type,
                      sortingOnPerType: null,
                      sortingOnPerSplitType: null,
                    })
                  }
                  onSortOnPerType={() =>
                    this.setState({
                      sortingOnType: null,
                      sortingOnPerSplitType: null,
                      sortingOnPerType: this.state.perType,
                    })
                  }
                  onSortOnSplitPerType={splitPerType => {
                    this.setState({
                      sortingOnType: null,
                      sortingOnPerType: null,
                      sortingOnPerSplitType: splitPerType,
                    });
                  }}
                />
              </div>
              <div>
                <Tooltip
                  label={<FormattedMessage id="general.export" />}
                  disabled={this.state.splitPerType != null}
                  trigger={
                    <div>
                      <Button
                        type="icon"
                        disabled={this.state.splitPerType != null}
                        icon={<Icon regular size={14} type="file-export" />}
                        onClick={() => this.setState({ showExportModal: true })}
                      />
                    </div>
                  }
                />
              </div>
            </div>
            {this.renderSelectedAsset()}
            <div className={styles['content']}>
              {this.state.isShowingChart ? (
                <Chart
                  type={this.state.type}
                  filters={this.state.filters}
                  perType={this.state.perType}
                  splitPerType={this.state.splitPerType}
                  isFetching={this.state.isFetching}
                  workOrderTypes={this.state.workOrderTypes}
                  assigneeUsers={this.state.assigneeUsers}
                  assigneeGroups={this.state.assigneeGroups}
                  assigneeVendors={this.state.assigneeVendors}
                  assets={this.state.assets}
                  completedByUsers={this.state.completedByUsers}
                  showingRootAssets={this.state.showingAssetId == null}
                  currency={this.props.currentSystem.currency}
                  yearSystemWasCreated={this.state.yearSystemWasCreated}
                  sortingOnType={this.state.sortingOnType}
                  data={this.state.data}
                  sortingOnPerType={this.state.sortingOnPerType}
                  sortingOnPerSplitType={this.state.sortingOnPerSplitType}
                />
              ) : (
                <ListView
                  type={this.state.type}
                  perType={this.state.perType}
                  sortingOnType={this.state.sortingOnType}
                  sortingOnPerType={this.state.sortingOnPerType}
                  data={this.state.data}
                  workOrderTypes={this.state.workOrderTypes}
                  assigneeUsers={this.state.assigneeUsers}
                  assigneeGroups={this.state.assigneeGroups}
                  assigneeVendors={this.state.assigneeVendors}
                  assets={this.state.assets}
                  completedByUsers={this.state.completedByUsers}
                  showingRootAssets={this.state.showingAssetId == null}
                  isFetching={this.state.isFetching}
                  yearSystemWasCreated={this.state.yearSystemWasCreated}
                  currency={this.props.currentSystem.currency}
                />
              )}
            </div>
          </div>
          <div
            className={styles['toggle']}
            onClick={() =>
              this.setState(prevState => ({ hideEditStatistics: !prevState.hideEditStatistics }))
            }
          >
            <Icon regular type="angle-left" />
          </div>
        </div>
      </div>
    );
  };

  showAdvancedFilter = () => {
    this.setState({ showAdvancedFilter: true });
  };

  renderExportModal = () => {
    let assetParams = {};
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      assetParams.asset_tree_parent_id = this.state.showingAssetId;
    } else {
      assetParams.asset_with_tree_children_id = this.state.showingAssetId;
    }
    return (
      <ExportModal
        open={this.state.showExportModal}
        params={{
          show: this.state.type,
          per: this.state.perType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        }}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header
            maxWidth={960}
            smallTitle
            title={<FormattedMessage id="components.work-orders-overview.title" />}
            breadcrumbComponent={this.renderBreadcrumb()}
          />
          <ContentContainer maxWidth={960}>
            <FilterTopBar
              year={this.state.filters.year}
              fromDate={this.state.filters.from_completed_date}
              toDate={this.state.filters.to_completed_date}
              yearSinceSystemWasCreated={this.state.yearSinceSystemWasCreated}
              yearSystemWasCreated={this.state.yearSystemWasCreated}
              onShowMoreFilters={this.showAdvancedFilter}
              amountOfAppliedAdvancedFilters={this.amountOfAppliedAdvancedFilters()}
              onSelectYear={this.selectYear}
              onSelectDate={this.selectCustomDate}
              onClear={this.selectYear}
            />
            <div
              className={`${styles['wrapper']} ${
                this.state.hideEditStatistics ? styles['hide-edit-statistics'] : ''
              }`}
            >
              {this.renderStatistics()}
            </div>
          </ContentContainer>
        </PerfectScrollbar>
        <AdvancedFilter
          open={this.state.showAdvancedFilter}
          params={this.state.advancedFilters}
          amountOfAppliedAdvancedFilters={this.amountOfAppliedAdvancedFilters()}
          onClose={params => {
            if (isEqual(this.state.advancedFilters, params)) {
              this.setState({
                showAdvancedFilter: false,
              });
            } else {
              this.applyAdvancedFilter(params);
            }
          }}
          onClearFilters={() => {
            this.applyAdvancedFilter({
              work_order_type_ids: null,
              priority: null,
              asset_type_ids: null,
              asset_id: null,
              asset_with_tree_children_id: null,
              created_by_user_id: null,
              is_recurring: null,
              completed_before_registration: null,
            });
          }}
        />
        {this.renderExportModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WorkOrders));
