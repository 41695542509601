import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { List, Pagination } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { EditDeliveryModal } from 'views/components/PurchaseOrder';
import { normalizePurchaseOrderDelivery } from 'sdk/Schemas';
import PurchaseOrdersImage from 'assets/images/EmptyDataSet/PurchaseOrders.png';
import { ListLayout } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import PurchaseOrderDeliveryListItem from './PurchaseOrderDeliveryListItem';
import styles from './style.module.scss';

class PreviousDeliveries extends Component {
  state = {
    isFetching: true,
    showEditDeliveryModal: false,
    showEditDeliveryModalForPurchaseOrderDeliveryId: null,
    purchaseOrderDeliveryIds: [],
    pagination: {
      totalEntries: 0,
      totalPages: 1,
    },
    queryParams: {
      page: 1,
      page_size: 25,
    },
  };

  componentDidMount() {
    this.fetchPurchaseOrderDeliveries().then(({ purchaseOrderDeliveryIds, headers }) => {
      const pagination = SDKHelperFunctions.getPaginationFromHeader(headers);
      this.setState({
        purchaseOrderDeliveryIds,
        isFetching: false,
        pagination,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let changedQueryParams = !isEqual(prevState.queryParams, this.state.queryParams);
    if (changedQueryParams) {
      this.fetchPurchaseOrderDeliveries().then(({ purchaseOrderDeliveryIds, headers }) => {
        const pagination = SDKHelperFunctions.getPaginationFromHeader(headers);
        this.setState({
          purchaseOrderDeliveryIds,
          isFetching: false,
          pagination,
        });
      });
    }
  }

  fetchPurchaseOrderDeliveries = () => {
    return API.listPurchaseOrderDeliveriesForSystem(this.props.currentSystem.id, {
      sort: 'created_at',
      'sort-order': 'desc',
      ...this.state.queryParams,
    }).then(({ data: purchaseOrderDeliveries, headers }) => {
      const { entities, result: purchaseOrderDeliveryIds } =
        normalizePurchaseOrderDelivery(purchaseOrderDeliveries);
      this.props.updateEntities(entities);
      return { purchaseOrderDeliveryIds, headers };
    });
  };

  addQueryParameter = params => {
    this.setState({
      isFetching: true,
      queryParams: {
        ...this.state.queryParams,
        page: 1,
        ...params,
      },
    });
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.previous-deliveries.empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.previous-deliveries.empty-data-set.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={PurchaseOrdersImage} alt="" />
      </div>
    </div>
  );

  renderDeliveries = () => {
    return (
      <>
        {this.renderHeader()}
        <List>
          {this.state.purchaseOrderDeliveryIds.map(purchaseOrderDeliveryId => (
            <PurchaseOrderDeliveryListItem
              key={purchaseOrderDeliveryId}
              purchaseOrderDeliveryId={purchaseOrderDeliveryId}
              onEdit={() => {
                this.setState({
                  showEditDeliveryModal: true,
                  showEditDeliveryModalForPurchaseOrderDeliveryId: purchaseOrderDeliveryId,
                });
              }}
            />
          ))}
        </List>
      </>
    );
  };

  renderEditDeliveryModal = () => (
    <EditDeliveryModal
      open={this.state.showEditDeliveryModal}
      purchaseOrderDeliveryId={this.state.showEditDeliveryModalForPurchaseOrderDeliveryId}
      onDelete={() => {
        this.setState({
          showEditDeliveryModal: false,
          purchaseOrderDeliveryIds: this.state.purchaseOrderDeliveryIds.filter(
            id => id !== this.state.showEditDeliveryModalForPurchaseOrderDeliveryId
          ),
          showEditDeliveryModalForPurchaseOrderDeliveryId: null,
        });
      }}
      onClose={() => {
        this.setState({ showEditDeliveryModal: false });
      }}
    />
  );

  renderHeader = () => {
    return (
      <List.Header background small expandable paddingRight={76}>
        <List.Header.Column flex>
          <FormattedMessage id="resources.purchase-order.resource" />
        </List.Header.Column>
        <List.Header.Column width={150}>
          <FormattedMessage id="resources.purchase-order-delivery.date" />
        </List.Header.Column>
        <List.Header.Column width={150}>
          <FormattedMessage id="resources.purchase-order-delivery.created-by" />
        </List.Header.Column>
        <List.Header.Column width={150} alignRight>
          <FormattedMessage id="screens.previous-deliveries.delivered-articles" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderContent = () => {
    if (this.state.isFetching)
      return (
        <>
          {this.renderHeader()}
          <List>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    if (this.state.purchaseOrderDeliveryIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return this.renderDeliveries();
  };

  renderPagination = () => {
    if (this.state.purchaseOrderDeliveryIds.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.state.queryParams.page}
          totalPages={this.state.pagination.totalPages}
          pageSize={this.state.queryParams.page_size}
          onSelectPage={page => {
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  render() {
    return (
      <>
        <ListLayout.Content.MainContent.Content>
          {this.renderContent()}
        </ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
        {this.renderEditDeliveryModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousDeliveries);
