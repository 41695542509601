import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { Type } from 'sdk/User';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import styles from './style.module.scss';

class UserDeleteModal extends Component {
  state = {
    isDeleting: false,
    checked: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isDeleting: false,
        checked: false,
      });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });
    if (this.props.user.type === Type.System || this.props.openedFromManageOrg) {
      this.deleteUser();
    } else {
      this.deleteSystemAccess();
    }
  };

  deleteUser = () => {
    this.props
      .deleteUser(this.props.user.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.delete-modal.delete-user-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '20013' })) {
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="screens.users.delete-modal.delete-own-user-error" />}
            />
          );
        } else {
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }
        this.setState({ isDeleting: false });
      });
  };

  deleteSystemAccess = () => {
    this.props
      .deleteSystemAccess(this.props.systemAccess.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.delete-modal.delete-system-access-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '20013' })) {
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="screens.users.delete-modal.delete-own-user-error" />}
            />
          );
        } else {
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }
        this.setState({ isDeleting: false });
      });
  };

  renderTitle = () => {
    if (this.props.user.type === Type.System || this.props.openedFromManageOrg) {
      return <FormattedMessage id="screens.users.delete-modal.delete-user-title" />;
    }
    return <FormattedMessage id="screens.users.delete-modal.delete-system-access-title" />;
  };

  renderMessage = () => {
    if (this.props.user.type === Type.System || this.props.openedFromManageOrg) {
      return (
        <FormattedMessage
          id="screens.users.delete-modal.delete-user-message"
          values={{ user: <span className={styles['bold-text']}>{this.props.user.name}</span> }}
        />
      );
    }
    return (
      <FormattedMessage
        id="screens.users.delete-modal.delete-system-access-message"
        values={{ user: <span className={styles['bold-text']}>{this.props.user.name}</span> }}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          <Modal.Header
            ignoreLine
            title={this.renderTitle()}
            subtitle={
              <div className={styles['danger-text']}>
                <p>
                  {this.props.user.name} - {this.props.user.email}
                </p>
              </div>
            }
            onClose={() => this.props.onClose()}
          />
          <Modal.Content>
            <div className={styles['info-box']}>
              <div className={styles['info-text']}>{this.renderMessage()}</div>
            </div>
            <Field.Checkbox
              checked={this.state.checked}
              onChange={value => this.setState({ checked: value })}
              label={<FormattedMessage id="general.i-am-sure" />}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                destructive
                disabled={!this.state.checked}
                loading={this.state.isDeleting}
                label={this.props.user.type === Type.Organisation ? 'general.remove' : 'general.delete'}
                onClick={() => {
                  this.delete();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deleteUser: SDKReduxOperations.deleteUser,
      deleteSystemAccess: SDKReduxOperations.deleteSystemAccess,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const user = EntitySelectors.getUser(state, ownProps.id);
  return {
    user,
    systemAccess: EntitySelectors.getSystemAccess(state, user.system_access),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDeleteModal);

UserDeleteModal.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  openedFromManageOrg: PropTypes.bool,
  onClose: PropTypes.func,
};

UserDeleteModal.defaultProps = {
  open: false,
  openedFromManageOrg: false,
  id: null,
  onClose: () => {},
};
