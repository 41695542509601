import { SDKReduxTypes } from 'sdk';
import { uniq } from 'lodash-es';
import update from 'immutability-helper';
import types from './types';

const INITIAL_STATE = {
  groups: {
    listIsEmpty: false,
    searchTerm: '',
    ids: [],
    createdIds: [],
  },
  workOrderTypes: {
    searchTerm: '',
    ids: [],
    createdIds: [],
  },
  purchaseOrderShippingAddresses: {
    ids: [],
  },
  // TODO: templateFields deprecated /Martin
  templateFields: {
    columns: {
      1: [],
      2: [],
    },
    selectedTemplateField: null,
  },
  assetTypes: {
    searchTerm: '',
    ids: [],
    createdIds: [],
  },
  downtimeReasons: {
    searchTerm: '',
    ids: [],
  },
  requestTypes: {
    searchTerm: '',
    ids: [],
    createdIds: [],
  },
  requestSelectableAssignees: {
    ids: [],
  },
  requestAutomationRules: {
    ids: [],
  },
  requestAutomationGroups: {
    ids: [],
  },
  sparePartTypes: {
    searchTerm: '',
    ids: [],
    createdIds: [],
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_GROUPS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        groups: {
          ...state.groups,
          listIsEmpty: ids.length === 0,
          searchTerm: '',
          ids,
          createdIds: [],
          pagination,
        },
      };
    }
    case types.SEARCH_GROUPS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          searchTerm: action.payload,
        },
      };
    }
    case types.FETCH_TEMPLATE_FIELDS_SUCCESS:
      const { sectionOneIds, sectionTwoIds } = action.payload;
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          selectedTemplateField: null,
          columns: {
            1: sectionOneIds,
            2: sectionTwoIds,
          },
        },
      };
    case types.TEMPLATE_FIELD_SORTED: {
      const { fromIndex, toIndex, templateFieldId, column } = action.payload;
      return update(
        { ...state },
        {
          templateFields: {
            columns: {
              [column]: {
                $splice: [
                  [fromIndex, 1],
                  [toIndex, 0, templateFieldId],
                ],
              },
            },
          },
        }
      );
    }
    case types.TEMPLATE_FIELD_SORTED_BETWEEN_COLUMNS: {
      const { fromIndex, toIndex, templateFieldId, fromColumn, toColumn } = action.payload;
      return update(
        { ...state },
        {
          templateFields: {
            columns: {
              [fromColumn]: { $splice: [[fromIndex, 1]] },
              [toColumn]: {
                $splice: [[toIndex, 0, templateFieldId]],
              },
            },
          },
        }
      );
    }
    case SDKReduxTypes.GROUP_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        groups: {
          ...state.groups,
          listIsEmpty: false,
          searchTerm: '',
          createdIds: [data.id, ...state.groups.createdIds],
        },
        requestAutomationGroups: {
          ...state.requestAutomationGroups,
          ids: [data.id, ...state.requestAutomationGroups.ids],
        },
      };
    }
    case SDKReduxTypes.GROUP_DELETED: {
      const { groupId } = action.payload;

      const createdIds = state.groups.createdIds.filter(id => id !== groupId);
      const ids = state.groups.ids.filter(id => id !== groupId);

      return {
        ...state,
        groups: {
          ...state.groups,
          listIsEmpty: createdIds.length === 0 && ids.length === 0,
          ids,
          createdIds,
        },
        requestAutomationGroups: {
          ...state.requestAutomationGroups,
          ids: state.requestAutomationGroups.ids.filter(id => id !== groupId),
        },
      };
    }
    case types.FETCH_PURCHASE_ORDER_SHIPPING_ADDRESSES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        purchaseOrderShippingAddresses: {
          ...state.purchaseOrderShippingAddresses,
          ids,
        },
      };
    }
    case SDKReduxTypes.PURCHASE_ORDER_SHIPPING_ADDRESS_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        purchaseOrderShippingAddresses: {
          ...state.purchaseOrderShippingAddresses,
          ids: [data.id, ...state.purchaseOrderShippingAddresses.ids],
        },
      };
    }
    case SDKReduxTypes.PURCHASE_ORDER_SHIPPING_ADDRESS_DELETED: {
      const { shippingAddressId } = action.payload;
      return {
        ...state,
        purchaseOrderShippingAddresses: {
          ...state.purchaseOrderShippingAddresses,
          ids: state.purchaseOrderShippingAddresses.ids.filter(id => id !== shippingAddressId),
        },
      };
    }
    case types.FETCH_WORK_ORDER_TYPES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        workOrderTypes: {
          ...state.workOrderTypes,
          ids,
          createdIds: [],
          searchTerm: '',
        },
      };
    }
    case types.SEARCH_WORK_ORDER_TYPES: {
      return {
        ...state,
        workOrderTypes: {
          ...state.workOrderTypes,
          searchTerm: action.payload,
        },
      };
    }
    case SDKReduxTypes.WORK_ORDER_TYPE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        workOrderTypes: {
          ...state.workOrderTypes,
          searchTerm: '',
          createdIds: [data.id, ...state.workOrderTypes.createdIds],
        },
      };
    }
    case SDKReduxTypes.WORK_ORDER_TYPE_DELETED: {
      const { workOrderTypeId } = action.payload;

      const createdIds = state.workOrderTypes.createdIds.filter(id => id !== workOrderTypeId);
      const ids = state.workOrderTypes.ids.filter(id => id !== workOrderTypeId);

      return {
        ...state,
        workOrderTypes: {
          ...state.workOrderTypes,
          ids,
          createdIds,
        },
      };
    }
    case types.FETCH_DOWN_TIME_REASONS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        downtimeReasons: {
          ...state.downtimeReasons,
          ids,
          searchTerm: '',
        },
      };
    }
    case SDKReduxTypes.DOWNTIME_REASON_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        downtimeReasons: {
          ...state.downtimeReasons,
          searchTerm: '',
          ids: [data.id, ...state.downtimeReasons.ids],
        },
      };
    }
    case SDKReduxTypes.DOWNTIME_REASON_DELETED: {
      const { downtimeReasonId } = action.payload;

      const ids = state.downtimeReasons.ids.filter(id => id !== downtimeReasonId);

      return {
        ...state,
        downtimeReasons: {
          ...state.downtimeReasons,
          ids,
        },
      };
    }
    case types.FETCH_ASSET_TYPES_SUCCESS: {
      return {
        ...state,
        assetTypes: {
          ...state.assetTypes,
          ids: action.payload,
          createdIds: [],
          searchTerm: '',
        },
      };
    }
    case types.SEARCH_ASSET_TYPES: {
      return {
        ...state,
        assetTypes: {
          ...state.assetTypes,
          searchTerm: action.payload,
        },
      };
    }
    case SDKReduxTypes.ASSET_TYPE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        assetTypes: {
          ...state.assetTypes,
          searchTerm: '',
          createdIds: [data.id, ...state.assetTypes.createdIds],
        },
      };
    }
    case SDKReduxTypes.ASSET_TYPE_DELETED: {
      const { assetTypeId } = action.payload;

      const createdIds = state.assetTypes.createdIds.filter(id => id !== assetTypeId);
      const ids = state.assetTypes.ids.filter(id => id !== assetTypeId);

      return {
        ...state,
        assetTypes: {
          ...state.assetTypes,
          ids,
          createdIds,
        },
      };
    }
    case types.FETCH_REQUEST_TYPES_SUCCESS: {
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          ids: action.payload,
          createdIds: [],
          searchTerm: '',
        },
      };
    }
    case types.FETCH_REQUEST_SELECTABLE_ASSIGNEES_SUCCESS: {
      return {
        ...state,
        requestSelectableAssignees: {
          ...state.requestSelectableAssignees,
          ids: action.payload,
        },
      };
    }
    case types.SEARCH_REQUEST_TYPES: {
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          searchTerm: action.payload,
        },
      };
    }
    case SDKReduxTypes.REQUEST_TYPE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          searchTerm: '',
          createdIds: [data.id, ...state.requestTypes.createdIds],
        },
      };
    }
    case SDKReduxTypes.REQUEST_TYPE_DELETED: {
      const { requestTypeId } = action.payload;

      const createdIds = state.requestTypes.createdIds.filter(id => id !== requestTypeId);
      const ids = state.requestTypes.ids.filter(id => id !== requestTypeId);

      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          ids,
          createdIds,
        },
      };
    }

    case SDKReduxTypes.REQUEST_SELECTABLE_ASSIGNEE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        requestSelectableAssignees: {
          ...state.requestSelectableAssignees,
          ids: [data.id, ...state.requestSelectableAssignees.ids],
        },
      };
    }
    case SDKReduxTypes.REQUEST_SELECTABLE_ASSIGNEE_DELETED: {
      const { requestSelectableAssigneeId } = action.payload;

      const ids = state.requestSelectableAssignees.ids.filter(id => id !== requestSelectableAssigneeId);

      return {
        ...state,
        requestSelectableAssignees: {
          ...state.requestSelectableAssignees,
          ids,
        },
      };
    }
    case SDKReduxTypes.REQUEST_AUTOMATION_RULE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        requestAutomationRules: {
          ...state.requestAutomationRules,
          ids: [data.id, ...state.requestAutomationRules.ids],
        },
      };
    }
    case types.REQUEST_AUTOMATION_RULE_SORTED: {
      const { fromIndex, toIndex, requestAutomationRuleId } = action.payload;
      return update(
        { ...state },
        {
          requestAutomationRules: {
            ids: {
              $splice: [
                [fromIndex, 1],
                [toIndex, 0, requestAutomationRuleId],
              ],
            },
          },
        }
      );
    }
    case SDKReduxTypes.REQUEST_AUTOMATION_RULE_DELETED: {
      const { requestAutomationRuleId } = action.payload;

      const ids = state.requestAutomationRules.ids.filter(id => id !== requestAutomationRuleId);

      return {
        ...state,
        requestAutomationRules: {
          ...state.requestAutomationRules,
          ids,
        },
      };
    }
    case types.FETCH_REQUEST_AUTOMATION_RULES_SUCCESS: {
      return {
        ...state,
        requestAutomationRules: {
          ...state.requestAutomationRules,
          ids: action.payload,
        },
      };
    }
    case types.FETCH_REQUEST_AUTOMATION_GROUPS_SUCCESS: {
      return {
        ...state,
        requestAutomationGroups: {
          ...state.requestAutomationGroups,
          ids: action.payload,
        },
      };
    }
    case types.FETCH_SPARE_PART_TYPES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        sparePartTypes: {
          ...state.sparePartTypes,
          ids,
          createdIds: [],
          searchTerm: '',
        },
      };
    }
    case types.SEARCH_SPARE_PART_TYPES: {
      return {
        ...state,
        sparePartTypes: {
          ...state.sparePartTypes,
          searchTerm: action.payload,
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_TYPE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        sparePartTypes: {
          ...state.sparePartTypes,
          searchTerm: '',
          createdIds: [data.id, ...state.sparePartTypes.createdIds],
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_TYPE_DELETED: {
      const { sparePartTypeId } = action.payload;

      const createdIds = state.sparePartTypes.createdIds.filter(id => id !== sparePartTypeId);
      const ids = state.sparePartTypes.ids.filter(id => id !== sparePartTypeId);

      return {
        ...state,
        sparePartTypes: {
          ...state.sparePartTypes,
          ids,
          createdIds,
        },
      };
    }
    case types.SELECT_TEMPLATE_FIELD: {
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          selectedTemplateField: action.payload,
        },
      };
    }
    case types.UNSELECT_TEMPLATE_FIELD: {
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          selectedTemplateField: null,
        },
      };
    }
    case types.PREPARE_NEW_FIELD: {
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          selectedTemplateField: action.payload,
        },
      };
    }
    // TODO: templateFields deprecated /Martin
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_UPDATED: {
      const { column, sort, id } = action.payload;
      let templateFieldsWithClearedTemplateField = update(state, {
        templateFields: {
          columns: {
            1: arr => arr.filter(lop => lop !== id),
            2: arr => arr.filter(lop => lop !== id),
          },
        },
      });
      return update(templateFieldsWithClearedTemplateField, {
        templateFields: {
          columns: {
            [column]: {
              $splice: [[sort - 1, 0, id]],
            },
          },
        },
      });
    }
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED:
    case SDKReduxTypes.TEMPLATE_FIELD_CREATED: {
      let id;
      let column;
      let data;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_CREATED) {
        id = action.payload.data.id;
        column = action.payload.data.column;
        data = action.payload.data;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED) {
        id = action.payload.id;
        column = action.payload.column;
        data = action.payload;
      }
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          columns: {
            ...state.templateFields.columns,
            [column]: uniq([...state.templateFields.columns[column], id]),
          },
          selectedTemplateField: data,
        },
      };
    }
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_DELETED:
    case SDKReduxTypes.TEMPLATE_FIELD_DELETED: {
      let templateFieldId;
      let selectedTemplateField = state.templateFields.selectedTemplateField;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_DELETED) {
        templateFieldId = action.payload.templateFieldId;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_DELETED) {
        templateFieldId = action.payload;
      }
      if (
        state.templateFields.selectedTemplateField &&
        state.templateFields.selectedTemplateField.id === templateFieldId
      ) {
        selectedTemplateField = null;
      }

      return update(state, {
        templateFields: {
          selectedTemplateField: { $set: selectedTemplateField },
          columns: {
            1: arr => arr.filter(id => id !== templateFieldId),
            2: arr => arr.filter(id => id !== templateFieldId),
          },
        },
      });
    }
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED:
    case SDKReduxTypes.TEMPLATE_FIELD_ITEM_CREATED: {
      let templateFieldItemId;
      let templateFieldId;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_ITEM_CREATED) {
        templateFieldItemId = action.payload.data.id;
        templateFieldId = action.payload.data.template_field_id;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_CREATED) {
        templateFieldItemId = action.payload.id;
        templateFieldId = action.payload.template_field_id;
      }
      if (
        state.templateFields.selectedTemplateField &&
        state.templateFields.selectedTemplateField.id === templateFieldId
      ) {
        return {
          ...state,
          templateFields: {
            ...state.templateFields,
            selectedTemplateField: {
              ...state.templateFields.selectedTemplateField,
              template_field_items: uniq([
                templateFieldItemId,
                ...state.templateFields.selectedTemplateField.template_field_items,
              ]),
            },
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED:
    case SDKReduxTypes.TEMPLATE_FIELD_ITEM_DELETED: {
      let templateFieldItemId;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_ITEM_DELETED) {
        templateFieldItemId = action.payload.templateFieldItemId;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_ITEM_DELETED) {
        templateFieldItemId = action.payload;
      }
      if (state.templateFields.selectedTemplateField == null) return state;
      return {
        ...state,
        templateFields: {
          ...state.templateFields,
          selectedTemplateField: {
            ...state.templateFields.selectedTemplateField,
            template_field_items: state.templateFields.selectedTemplateField.template_field_items.filter(
              id => id !== templateFieldItemId
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};
