import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { List, Icon, Button, NewInlineModal, ConfirmModal } from 'views/components/Shared/General';
import { normalizeChecklistTemplate } from 'sdk/Schemas';
import {
  ChecklistEditModal,
  PreviewChecklistTemplateModal,
  CantEditReusableChecklistModal,
} from 'views/components/Checklist';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import { SDKReduxOperations, API } from 'sdk';

class ChecklistTemplateLinkListItem extends Component {
  inlineModalPositioningRef = null;

  state = {
    dropdownOpen: false,
    showConfirmRemoveChecklistModal: false,
    showCantEditReusableChecklistModal: false,
    showCantEditReusableChecklistModalForChecklistId: null,
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
    showPreviewChecklistModal: false,
    showPreviewChecklistModalForTemplateId: null,
    isRevertingChangesForChecklistTemplateVersion: false,
    isEditingDraftChecklistTemplateVersion: false,
  };

  editChecklistTemplate = () => {
    if (this.props.checklistTemplate.reusable) {
      this.setState({
        dropdownOpen: false,
        showCantEditReusableChecklistModal: true,
        showCantEditReusableChecklistModalForChecklistId: this.props.checklistTemplate.id,
      });
      return;
    }
    if (this.props.checklistTemplate.draft_version_id) {
      this.setState({
        dropdownOpen: false,
        showChecklistEditModal: true,
        showChecklistEditModalForVersionId: this.props.checklistTemplate.draft_version_id,
        isEditingDraftChecklistTemplateVersion: true,
      });
    } else {
      this.createNewChecklistTemplateVersion(this.props.checklistTemplate);
    }
  };

  closeChecklistEditModal = () => {
    if (this.state.isEditingDraftChecklistTemplateVersion === false) {
      this.deleteChecklistTemplateVersion();
    } else {
      this.setState({ showChecklistEditModal: false });
    }
  };

  activateChecklistTemplateVersion = () => {
    this.setState({ isActivatingVersion: true });
    this.props
      .updateChecklistTemplateVersion(this.state.showChecklistEditModalForVersionId, {
        status: Status.Active,
      })
      .then(() => {
        /*
          Refetch checklistTemplate to update this.props.checklistTempalte.draft_version_id
          which is used when editing
        */
        API.fetchChecklistTemplate(this.props.checklistTemplate.id).then(({ data }) => {
          const { entities } = normalizeChecklistTemplate(data);
          this.props.updateEntities(entities);

          this.setState({ showChecklistEditModal: false, isActivatingVersion: false });
        });
      });
  };

  createNewChecklistTemplateVersion = checklistTemplate => {
    const { id, title } = checklistTemplate;
    this.setState({
      dropdownOpen: false,
      showChecklistEditModal: true,
      showChecklistEditModalForVersionId: null,
      isEditingDraftChecklistTemplateVersion: false,
    });
    this.props
      .createChecklistTemplateVersionForChecklistTemplate(id, {
        title,
      })
      .then(({ data: checklistTemplateVersion }) => {
        this.setState({
          showChecklistEditModalForVersionId: checklistTemplateVersion.id,
        });
      });
  };

  deleteChecklistTemplateVersion = () => {
    this.setState({ isRevertingChangesForChecklistTemplateVersion: true });
    this.props.deleteChecklistTemplateVersion(this.state.showChecklistEditModalForVersionId).then(() => {
      /*
          Refetch checklistTemplate to update this.props.checklistTempalte.draft_version_id
          which is used when editing
        */
      API.fetchChecklistTemplate(this.props.checklistTemplate.id).then(({ data }) => {
        const { entities } = normalizeChecklistTemplate(data);
        this.props.updateEntities(entities);
        this.setState({
          showChecklistEditModal: false,
          isRevertingChangesForChecklistTemplateVersion: false,
        });
      });
    });
  };

  previewChecklistTemplate = () => {
    this.setState({
      showPreviewChecklistModal: true,
      showPreviewChecklistModalForTemplateId: this.props.checklistTemplate.id,
    });
  };

  renderMoreOptionsColumn = () => {
    return (
      <List.Item.Column alignRight>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={150}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item onClick={this.editChecklistTemplate}>
                <FormattedMessage id="general.edit" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={() => {
                  this.setState({ dropdownOpen: false, showConfirmRemoveChecklistModal: true });
                }}
              >
                <FormattedMessage id="general.remove" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </List.Item.Column>
    );
  };

  renderRemoveChecklistConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmRemoveChecklistModal}
        title={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-checklist-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-checklist-confirm-modal.subtitle" />
        }
        destructive
        confirmButtonText="general.remove"
        onConfirm={() => {
          this.setState({ showConfirmRemoveChecklistModal: true });
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            checklists: [...this.props.editingOperationalMaintenanceTemplateVersion.checklists].filter(
              ({ checklist_template_id: loopedId }) =>
                loopedId !== this.props.checklistTemplateLink.checklist_template_id
            ),
          });
        }}
        onCancel={() => {
          this.setState({ showConfirmRemoveChecklistModal: false });
        }}
      />
    );
  };

  renderCantEditReusableChecklistModal = () => {
    return (
      <CantEditReusableChecklistModal
        open={this.state.showCantEditReusableChecklistModal}
        id={this.state.showCantEditReusableChecklistModalForChecklistId}
        onClose={() => {
          this.setState({ showCantEditReusableChecklistModal: false });
        }}
      />
    );
  };

  renderPreviewChecklistTemplateModal = () => {
    return (
      <PreviewChecklistTemplateModal
        open={this.state.showPreviewChecklistModal}
        id={this.state.showPreviewChecklistModalForTemplateId}
        onClose={() => this.setState({ showPreviewChecklistModal: false })}
        hideFooter
      />
    );
  };

  renderEditChecklistModal = () => {
    const { checklistTemplate } = this.props;
    const { isEditingDraftChecklistTemplateVersion } = this.state;
    let showAbortChangesButton = false;
    if (checklistTemplate.draft === false && isEditingDraftChecklistTemplateVersion) {
      showAbortChangesButton = true;
    }
    return (
      <ChecklistEditModal
        open={this.state.showChecklistEditModal}
        id={this.state.showChecklistEditModalForVersionId}
        hideSaveAsReusable
        checklistTemplateLinkId={this.props.checklistTemplateLink.id}
        showAbortChangesButton={showAbortChangesButton}
        isSaving={this.state.isActivatingVersion}
        isRevertingChanges={this.state.isRevertingChangesForChecklistTemplateVersion}
        onSave={this.activateChecklistTemplateVersion}
        onRevertChanges={this.deleteChecklistTemplateVersion}
        onAddToLibrarySuccess={() => {
          this.setState({
            showChecklistEditModal: false,
          });
        }}
        onClose={this.closeChecklistEditModal}
      />
    );
  };

  render() {
    return (
      <>
        <List.Item>
          <List.Item.Column>
            <Icon type="list" withBackground backgroundSize={34} />
          </List.Item.Column>
          <List.Item.TitleColumn title={this.props.checklistTemplate.title} />
          <List.Item.Column alignRight>
            <Button small onClick={this.props.onClickPreview} gray label="general.preview" />
          </List.Item.Column>
          {this.renderMoreOptionsColumn()}
        </List.Item>
        {this.renderRemoveChecklistConfirmModal()}
        {this.renderCantEditReusableChecklistModal()}
        {this.renderPreviewChecklistTemplateModal()}
        {this.renderEditChecklistModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { checklistTemplateLink } = ownProps;

  return {
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLink.checklist_template_id
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
      deleteChecklistTemplateVersion: SDKReduxOperations.deleteChecklistTemplateVersion,
      updateChecklistTemplateVersion: SDKReduxOperations.updateChecklistTemplateVersion,
      createChecklistTemplateVersionForChecklistTemplate:
        SDKReduxOperations.createChecklistTemplateVersionForChecklistTemplate,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistTemplateLinkListItem);
