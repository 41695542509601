import React from 'react';
import { connect } from 'react-redux';
import { RowType } from 'sdk/QRTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';

function QrTemplateRowTitle(props) {
  switch (props.qrTemplateRow.row_type) {
    case RowType.SparePartTitle:
      return <FormattedMessage id="resources.spare-part.title" />;
    case RowType.SparePartArticleNumber:
      return <FormattedMessage id="resources.spare-part.article-number" />;
    case RowType.SparePartDescription:
      return <FormattedMessage id="resources.spare-part.description" />;
    case RowType.SparePartLocation:
      return <FormattedMessage id="resources.spare-part.location" />;
    case RowType.SparePartType:
      return <FormattedMessage id="resources.spare-part.category" />;
    case RowType.SparePartUnit:
      return <FormattedMessage id="resources.spare-part.unit" />;
    case RowType.AssetTitle:
      return <FormattedMessage id="resources.asset.title" />;
    case RowType.AssetNumber:
      return <FormattedMessage id="resources.asset.number" />;
    case RowType.AssetType:
      return <FormattedMessage id="resources.asset.type" />;
    case RowType.Qr:
      return <FormattedMessage id="components.print-qr-codes.qr-code" />;
    case RowType.TemplateField:
      return props.templateField.title;
    default:
      return null;
  }
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateRowId } = ownProps;

  let qrTemplateRow = EntitySelectors.getQrTemplateRow(state, qrTemplateRowId);
  let templateField = null;
  if (qrTemplateRow) {
    templateField = EntitySelectors.getTemplateField(state, qrTemplateRow.template_field_id);
  }
  return {
    qrTemplateRow,
    templateField,
  };
}

export default connect(mapStateToProps)(QrTemplateRowTitle);
