import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { SparePartSelectors } from 'state/ducks/sparePart';
import { SparePartsSelectors } from 'state/ducks/spareParts';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import Menu from './Menu';
import EditSparePartModal from './EditSparePartModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showEditSparePartModal: false,
  };

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/spare-parts',
      search: HelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.spare-part.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>{this.props.sparePart.title}</LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTabs = () => {
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/spare-parts/${id}`}
          active={matchPath(this.props.location.pathname, { path: '/spare-parts/:id/', exact: true }) != null}
        >
          <FormattedMessage id="screens.spare-part.tabs.information" />
        </LayoutHeader.TabBarItem>
        {this.props.canViewAssets ? (
          <LayoutHeader.TabBarItem
            to={`/spare-parts/${id}/assets`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/spare-parts/:id/assets/',
              }) != null
            }
          >
            <FormattedMessage id="screens.spare-part.tabs.assets" />
          </LayoutHeader.TabBarItem>
        ) : null}

        {this.props.canViewVendors ? (
          <LayoutHeader.TabBarItem
            to={`/spare-parts/${id}/vendors`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/spare-parts/:id/vendors/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.spare-part.tabs.vendors" />
          </LayoutHeader.TabBarItem>
        ) : null}
        <LayoutHeader.TabBarItem
          to={`/spare-parts/${id}/withdrawals`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/spare-parts/:id/withdrawals/',
              exact: true,
            }) != null
          }
        >
          <FormattedMessage id="screens.spare-part.tabs.withdrawals" />
        </LayoutHeader.TabBarItem>
        {this.props.canViewPurchaseOrders ? (
          <LayoutHeader.TabBarItem
            to={`/spare-parts/${id}/purchase-orders`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/spare-parts/:id/purchase-orders/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.spare-part.tabs.purchase-orders" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.isOperator === false ? (
          <LayoutHeader.TabBarItem
            to={`/spare-parts/${id}/files`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/spare-parts/:id/files/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.spare-part.tabs.files" />
          </LayoutHeader.TabBarItem>
        ) : null}
      </LayoutHeader.TabBar>
    );
  };

  renderDescription = () => {
    return <div className={styles['description']}>{this.props.sparePart.description}</div>;
  };

  renderEditButton = () => {
    if (this.props.canEditSpareParts) {
      return (
        <Button
          type="icon"
          icon={<Icon regular type="pen" />}
          onClick={() => this.setState({ showEditSparePartModal: true })}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <LayoutHeader
          title={this.props.sparePart.title}
          titleHoverText={this.props.sparePart.title}
          subtitle={`#${this.props.sparePart.article_number}`}
          breadcrumbComponent={this.renderBreadcrumb()}
          tabBarComponent={this.renderTabs()}
          editTitleButton={this.renderEditButton()}
          buttonsComponent={<Menu />}
        />
        <EditSparePartModal
          sparePart={this.props.sparePart}
          open={this.state.showEditSparePartModal}
          onClose={() => this.setState({ showEditSparePartModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sparePart: SparePartSelectors.getSparePart(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    navigatedTo: SparePartsSelectors.getNavigatedTo(state),
    canViewAssets: AuthSelectors.canViewAssets(state),
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
