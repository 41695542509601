import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { AssetTypeInlineModal } from 'views/components/Asset';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from '../style.module.scss';

class AssetTypeField extends Component {
  renderSelectedAssetTypes = () => {
    if (this.props.assetTypes.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.props.assetTypes.reduce(
          (accu, assetType) =>
            accu === null
              ? [<div className={styles['selected-item']}>{assetType.title}</div>]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>{assetType.title}</div>,
                ],
          null
        )}
      </div>
    );
  };

  render() {
    return (
      <Field
        view={false}
        label={
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset-type.title" />
        }
      >
        <AssetTypeInlineModal
          multiple
          trigger={
            <Field.Resource
              angleDown
              error={this.props.hasError}
              value={this.renderSelectedAssetTypes()}
              onClear={() => this.props.onClear()}
            />
          }
          selectedAssetTypeIds={this.props.ids}
          onAddAssetType={id => this.props.onAddAssetType(id)}
          onRemoveAssetType={id => this.props.onRemoveAssetType(id)}
          onClearAssetType={() => this.props.onClear()}
        />
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTypes: EntitySelectors.getAssetTypes(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(AssetTypeField);
