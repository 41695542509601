import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';
import styles from './style.module.scss';

export default class Text extends Component {
  inputRef = null;

  state = {
    focused: false,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.inputRef.focus();
    } else if (this.props.autoSelect) {
      this.inputRef.select();
    }
  }

  renderIcon = () => {
    if (!this.props.icon) return null;
    return <div className={styles['icon-container']}>{this.props.icon}</div>;
  };

  renderRightLabel = () => {
    if (!this.props.rightLabel) return null;
    return (
      <div
        className={[styles['label-container'], styles['right']].join(' ')}
        onClick={() => this.inputRef.focus()}
      >
        {this.props.rightLabel}
      </div>
    );
  };

  renderLeftLabel = () => {
    if (!this.props.leftLabel) return null;
    return (
      <div
        className={[styles['label-container'], styles['left']].join(' ')}
        onClick={() => this.inputRef.focus()}
      >
        {this.props.leftLabel}
      </div>
    );
  };

  focus = () => {
    this.inputRef.focus();
  };

  select = () => {
    this.inputRef.select();
  };

  render() {
    let additionalStyles = {};
    let classNames = [styles['input-container']];
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    if (this.state.focused) {
      classNames = [...classNames, styles['focused']];
    }
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    if (this.props.alignRight) {
      classNames = [...classNames, styles['align-right']];
    }
    if (this.props.rightLabel) {
      classNames = [...classNames, styles['has-right-label']];
    }
    if (this.props.leftLabel) {
      classNames = [...classNames, styles['has-right-label']];
    }
    if (this.props.alignRight) {
      classNames = [...classNames, styles['align-right']];
    }

    if (this.props.padding) {
      additionalStyles = { ...styles, padding: this.props.padding };
    }

    return (
      <div className={classNames.join(' ')}>
        {this.renderLeftLabel()}
        {this.renderIcon()}
        <input
          {...omit(this.props, ['rightLabel', 'leftLabel', 'icon', 'alignRight', 'error'])}
          ref={ref => {
            this.inputRef = ref;
          }}
          value={this.props.value || ''}
          placeholder={this.props.placeholder}
          type={this.props.type}
          onChange={e =>
            this.props.onChange(this.props.type === 'number' ? parseInt(e.target.value) : e.target.value)
          }
          onBlur={e => {
            this.setState({ focused: false });
            this.props.onBlur(this.props.type === 'number' ? parseInt(e.target.value) : e.target.value);
          }}
          onFocus={e => {
            this.setState({ focused: true });
            this.props.onFocus();
          }}
          className={styles['input']}
          style={additionalStyles}
        />
        {this.renderRightLabel()}
      </div>
    );
  }
}

Text.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  icon: PropTypes.string,
  type: PropTypes.string,
  alignRight: PropTypes.bool,
  leftLabel: PropTypes.node,
  rightLabel: PropTypes.node,
  padding: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
Text.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  alignRight: false,
  padding: null,
  type: 'text',
  disabled: false,
  placeholder: null,
};
