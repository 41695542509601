import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button, InlineModal, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class GroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      hasUnsavedChanges: false,
      showDeleteInlineModal: false,
      confirmDelete: false,
      editingGroup: {},
      editingGroupBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        isDeleting: false,
        hasUnsavedChanges: false,
        showDeleteInlineModal: false,
        confirmDelete: false,
      });

      const { group } = this.props;
      if (group) {
        const editingGroup = { title: group.title };
        this.setState({
          editingGroup,
          editingGroupBeforeEdit: editingGroup,
        });
      } else {
        const editingGroup = {
          title: '',
        };
        this.setState({
          editingGroup,
          editingWorkOrderBeforeEdit: editingGroup,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createGroup(this.props.system.id, {
        ...this.state.editingGroup,
        type: this.props.type,
      })
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.work-orders.groups.create-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updateGroup(this.props.id, this.state.editingGroup)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteGroup(this.props.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.work-orders.groups.delete-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  setValue = values => {
    const editingGroup = {
      ...this.state.editingGroup,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingGroupBeforeEdit, editingGroup);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingGroup, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderDeleteContent = () => {
    if (this.props.group == null) return null;
    return (
      <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.delete}>
        <FormattedMessage id="general.delete-confirm.subtitle" />
      </InlineModal.Delete>
    );
  };

  renderDeleteInlineModal = () => {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>
          <Button
            type="icon"
            icon={<Icon regular red type="trash-alt" />}
            onClick={() =>
              this.setState(prevState => ({
                confirmDelete: false,
                showDeleteInlineModal: !prevState.showDeleteInlineModal,
              }))
            }
          />
        </div>
        <InlineModal
          open={this.state.showDeleteInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showDeleteInlineModal: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showDeleteInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.work-orders.groups.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.work-orders.groups.new-title" />
            )
          }
          iconButtons={this.props.id == null ? null : this.renderDeleteInlineModal()}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Field view={false} label={<FormattedMessage id="resources.cost-other-category.title" />}>
            <Field.Text
              autoFocus
              error={this.state.showTitleError}
              value={this.state.editingGroup.title}
              onKeyPress={this.handleKeyPress}
              onChange={title => {
                this.setValue({ title });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.editingGroup.title}
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createGroup: SDKReduxOperations.createGroup,
      updateGroup: SDKReduxOperations.updateGroup,
      deleteGroup: SDKReduxOperations.deleteGroup,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    group: EntitySelectors.getGroup(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GroupModal));

GroupModal.propTypes = {
  type: PropTypes.oneOf(['request_assignee', 'work_order_assignee']),
  id: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

GroupModal.defaultProps = {
  type: null,
  open: false,
  onClose: () => {},
};
