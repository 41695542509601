import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { CardGrid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import PurchaseItem from './PurchaseItem';
import styles from './style.module.scss';

class WorkOrderPurchases extends Component {
  render() {
    if (!this.props.workOrderPurchases || this.props.workOrderPurchases.length === 0) return null;

    return (
      <>
        <CardGrid.Separator />
        <CardGrid.Row>
          <div>
            <div className={styles['title']}>
              <FormattedMessage id="screens.work-order.purchases.title" />
            </div>
            <div>
              {this.props.workOrderPurchases.map(purchase => (
                <PurchaseItem workOrderPurchaseId={purchase.id} key={purchase.id} />
              ))}
            </div>
          </div>
        </CardGrid.Row>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;

  const workOrderPurchases = EntitySelectors.getWorkOrderPurchases(state, workOrder.work_order_purchases);

  return {
    workOrderPurchases,
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(WorkOrderPurchases)));
