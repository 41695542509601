import React, { Component } from 'react';
import styles from './style.module.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { EntityOperations } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import { API } from 'sdk';
import TopBar from './TopBar';
import Rows from './Rows';

class Deviations extends Component {
  state = {
    isFetching: true,
  };

  componentDidMount() {
    API.fetchChecklistInstance(this.props.checklistInstance.id).then(({ data: checklistInstance }) => {
      const { entities } = normalizeChecklistInstance(checklistInstance);
      this.props.updateEntities(entities);
      this.setState({ isFetching: false });
    });
  }

  render() {
    if (this.state.isFetching) {
      return (
        <div className={styles['deviations']}>
          <div className={styles['rows-container']}>
            <div className={styles['rows']}>
              <List>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              </List>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['deviations']}>
        <TopBar />
        <div className={styles['rows-container']}>
          <div className={styles['rows']}>
            <Rows />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    checklistInstance: ChecklistModalSelectors.getChecklistInstance(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Deviations);
