import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { Datapill } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartReservationListItem extends Component {
  renderQuantityDataPill = () => {
    return (
      <Datapill
        value={
          <SparePartUnitWithValue
            value={this.props.sparePartReservation.quantity.toString()}
            sparePartUnit={this.props.sparePartUnit}
          />
        }
      />
    );
  };
  renderDataPills = () => {
    return (
      <div className={styles['datapills-container']}>
        <Datapill.List>{this.renderQuantityDataPill()}</Datapill.List>
      </div>
    );
  };

  render() {
    return (
      <div className={styles['reservation']}>
        <div className={styles['card']}>
          <div>
            <div>
              <Title sparePart={this.props.sparePart} />
            </div>
            <div>{this.renderDataPills()}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const sparePartReservation = EntitySelectors.getSparePartReservation(state, id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id);
  return {
    sparePartReservation,
    sparePart: EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(SparePartReservationListItem);
