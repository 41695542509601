import React from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { List, Icon, ViewTextArea } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SparePartStockStockActivityType } from 'sdk/SparePartStockActivity';
import SparePartStockActivitySubtitle from './SparePartStockActivitySubtitle';
import styles from './styles.module.scss';

const SparePartStockActivity = ({
  sparePartStockActivity,
  createdByUser,
  sparePartUnit,
  sparePartWithdrawal,
  sparePartStockAdjustment,
}) => {
  const renderQuantity = () => {
    if (sparePartStockActivity.type === SparePartStockStockActivityType.Deactivated) {
      return <FormattedMessage id="components.stock-history-modal.deactivated-text" />;
    }
    if (sparePartStockActivity.type === SparePartStockStockActivityType.Activated) {
      return (
        <span className={styles['plus']}>
          <SparePartUnitWithValue
            sparePartUnit={sparePartUnit}
            value={`+${Math.abs(sparePartStockActivity.quantity)}`}
          />
        </span>
      );
    }
    if (new Decimal(sparePartStockActivity.difference || 0).greaterThanOrEqualTo(new Decimal(0))) {
      return (
        <span className={styles['plus']}>
          <SparePartUnitWithValue
            sparePartUnit={sparePartUnit}
            value={`+${Math.abs(sparePartStockActivity.difference)}`}
          />
        </span>
      );
    }
    return (
      <span className={styles['minus']}>
        <SparePartUnitWithValue
          sparePartUnit={sparePartUnit}
          value={`-${Math.abs(sparePartStockActivity.difference)}`}
        />
      </span>
    );
  };

  const renderIcon = () => {
    const { type } = sparePartStockActivity;
    if (type === SparePartStockStockActivityType.Delivery) {
      return <Icon regular type="truck" />;
    }
    if (type === SparePartStockStockActivityType.Withdrawal && sparePartWithdrawal.work_order_id) {
      return <Icon regular type="wrench" />;
    }
    if (type === SparePartStockStockActivityType.Stocktaking) {
      return <Icon regular type="layer-group" />;
    }
    return <Icon regular type="cogs" />;
  };

  const renderComment = () => {
    if (sparePartWithdrawal && sparePartWithdrawal.comment && sparePartWithdrawal.comment.length) {
      return (
        <div className={styles['comment']}>
          <ViewTextArea>{sparePartWithdrawal.comment}</ViewTextArea>
        </div>
      );
    }
    if (
      sparePartStockAdjustment &&
      sparePartStockAdjustment.comment &&
      sparePartStockAdjustment.comment.length
    ) {
      return (
        <div className={styles['comment']}>
          <ViewTextArea>{sparePartStockAdjustment.comment}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <List.Item dataPillsComponent={renderComment()}>
        <List.Item.Column width={30}>{renderIcon()}</List.Item.Column>
        <List.Item.TitleColumn
          flex
          title={renderQuantity()}
          subtitle={
            <div>
              <SparePartStockActivitySubtitle
                sparePartStockActivity={sparePartStockActivity}
                key={sparePartStockActivity.id}
              />
            </div>
          }
        />
        <List.Item.Column width={200}>
          <div className={styles['created-by']}>
            <div className={styles['profile-picture-container']}>
              <ProfilePicture size={22} userId={sparePartStockActivity.created_by_user_id} />
            </div>
            <div className={styles['info']}>
              <div className={styles['date']}>{moment(sparePartStockActivity.created_at).format('LL')}</div>
              <div className={styles['user']}>
                <FormattedMessage
                  id="general.created-by"
                  values={{ user: <UserNameWrapper user={createdByUser} /> }}
                />
              </div>
            </div>
          </div>
        </List.Item.Column>
      </List.Item>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePart.spare_part_unit_id),
    createdByUser: EntitySelectors.getUser(state, ownProps.sparePartStockActivity.created_by_user_id),
    sparePartWithdrawal: EntitySelectors.getSparePartWithdrawal(
      state,
      ownProps.sparePartStockActivity.spare_part_withdrawal_id
    ),
    sparePartStockAdjustment: EntitySelectors.getSparePartStockAdjustment(
      state,
      ownProps.sparePartStockActivity.spare_part_stock_adjustment_id
    ),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(SparePartStockActivity);
