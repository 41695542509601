import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { SideModal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { RequestTypeInlineModal } from 'views/components/Request';
import { AssetTypeInlineModal, ChooseAssetInlineModal } from 'views/components/Asset';
import { ChooseUserInlineModal } from 'views/components/User';
import RequestTypeContainer from './RequestTypeContainer';
import AssetTypeContainer from './AssetTypeContainer';
import AssetContainer from './AssetContainer';
import UserContainer from './UserContainer';
import styles from './style.module.scss';

export default class AdvancedFilter extends Component {
  getInitialFilters = () => ({
    request_type_ids: null,
    priority: null,
    asset_type_ids: null,
    asset_id: null,
    asset_with_tree_children_id: null,
    created_by_user_id: null,
  });

  constructor(props) {
    super(props);

    this.state = this.getInitialFilters();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        request_type_ids,
        priority,
        asset_type_ids,
        asset_id,
        asset_with_tree_children_id,
        created_by_user_id,
      } = this.props.params;
      this.setState({
        asset_id,
        asset_with_tree_children_id,
        request_type_ids,
        priority,
        asset_type_ids,
        created_by_user_id,
      });
    }
  }

  renderSelectedPriority = () => {
    switch (this.state.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderAssetFilterRow = () => {
    const assetId = this.state.asset_id || this.state.asset_with_tree_children_id;
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.requests-overview.filters.asset" />}>
            <ChooseAssetInlineModal
              hideCreateButton
              width="300"
              trigger={
                <Field.Resource
                  angleDown
                  value={assetId == null ? null : <AssetContainer id={assetId} />}
                  onClear={() => this.setState({ asset_id: null, asset_with_tree_children_id: null })}
                />
              }
              selectedAssetId={assetId}
              onSelectAsset={id => {
                this.setState({ asset_with_tree_children_id: id });
              }}
              onClear={() => this.setState({ asset_id: null, asset_with_tree_children_id: null })}
            />
          </Field>
          {this.state.asset_id || this.state.asset_with_tree_children_id ? (
            <div className={styles['checkbox']}>
              <Field.Checkbox
                checked={this.state.asset_with_tree_children_id != null}
                label={
                  <FormattedMessage id="components.requests-overview.filters.include-underlying-assets-label" />
                }
                onChange={() => {
                  if (this.state.asset_with_tree_children_id) {
                    this.setState({
                      asset_id: this.state.asset_with_tree_children_id,
                      asset_with_tree_children_id: null,
                    });
                  } else {
                    this.setState({ asset_id: null, asset_with_tree_children_id: this.state.asset_id });
                  }
                }}
              />
            </div>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        width={485}
        footerComponent={
          <Button.Group>
            <Button
              primary
              onClick={() => this.props.onClose(this.state)}
              label="components.requests-overview.filter-modal-title"
            />
            <Button
              translate={false}
              disabled={this.props.amountOfAppliedAdvancedFilters === 0}
              label={
                <div className={styles['number-container']}>
                  <FormattedMessage id="general.clean" />
                  {this.props.amountOfAppliedAdvancedFilters === 0 ? null : (
                    <div className={styles['number']}>{this.props.amountOfAppliedAdvancedFilters}</div>
                  )}
                </div>
              }
              onClick={() => {
                this.setState(this.getInitialFilters(), () => this.props.onClose(this.state));
              }}
            />
          </Button.Group>
        }
        onClose={() => this.props.onClose(this.state)}
      >
        <SideModal.Container>
          <SideModal.Container.Content>
            <SideModal.Header
              title={<FormattedMessage id="components.requests-overview.filter-modal-title" />}
              subtitle={<FormattedMessage id="components.requests-overview.filter-modal-subtitle" />}
              onClose={() => this.props.onClose(this.state)}
            />
            <Grid>
              {this.renderAssetFilterRow()}
              <Grid.Row>
                <Grid.Column>
                  <Field label={<FormattedMessage id="components.requests-overview.filters.asset-type" />}>
                    <AssetTypeInlineModal
                      editable={false}
                      trigger={
                        <Field.Resource
                          angleDown
                          value={
                            this.state.asset_type_ids == null ? null : (
                              <AssetTypeContainer id={this.state.asset_type_ids} />
                            )
                          }
                          onClear={() => this.setState({ asset_type_ids: null })}
                        />
                      }
                      selectedAssetTypeId={this.state.asset_type_ids}
                      onSelectAssetType={id => this.setState({ asset_type_ids: id })}
                      onClearAssetType={() => this.setState({ asset_type_ids: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field label={<FormattedMessage id="components.requests-overview.filters.request-type" />}>
                    <RequestTypeInlineModal
                      editable={false}
                      trigger={
                        <Field.Resource
                          angleDown
                          value={
                            this.state.request_type_ids == null ? null : (
                              <RequestTypeContainer id={this.state.request_type_ids} />
                            )
                          }
                          onClear={() => this.setState({ request_type_ids: null })}
                        />
                      }
                      selectedRequestTypeId={this.state.request_type_ids}
                      onSelectRequestType={id => this.setState({ request_type_ids: id })}
                      onClearRequestType={() => this.setState({ request_type_ids: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field label={<FormattedMessage id="components.requests-overview.filters.priority" />}>
                    <ChoosePriorityInlineModal
                      trigger={
                        <Field.Resource
                          angleDown
                          value={this.renderSelectedPriority()}
                          onClear={() => this.setState({ priority: null })}
                        />
                      }
                      selectedPriority={this.state.priority}
                      onSelectPriority={priority => this.setState({ priority: priority })}
                      onClear={() => this.setState({ priority: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    label={<FormattedMessage id="components.requests-overview.filters.created-by-user" />}
                  >
                    <ChooseUserInlineModal
                      trigger={
                        <Field.Resource
                          angleDown
                          value={
                            this.state.created_by_user_id == null ? null : (
                              <UserContainer id={this.state.created_by_user_id} />
                            )
                          }
                          onClear={() => this.setState({ created_by_user_id: null })}
                        />
                      }
                      selectedUserId={this.state.created_by_user_id}
                      onSelectUser={id => {
                        this.setState({ created_by_user_id: id });
                      }}
                      onClear={() => this.setState({ created_by_user_id: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </SideModal.Container.Content>
        </SideModal.Container>
      </SideModal>
    );
  }
}
