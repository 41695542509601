const DOMAIN_PREFIX = 'meters/';

const SET_NAVIGATED_TO = `${DOMAIN_PREFIX}set_navigated_to`;
const ADD_QUERY_PARAMETER = `${DOMAIN_PREFIX}add_query_parameter`;
const FETCH_METERS_SUCCESS = `${DOMAIN_PREFIX}fetch_meters_success`;
const RESET_SELECTED_METERS = `${DOMAIN_PREFIX}reset_selected_meters`;
const SELECT_PAGE = `${DOMAIN_PREFIX}select_page`;
const SELECT_METER = `${DOMAIN_PREFIX}select_meter`;
const HIDE_SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}hide_select_total_entries`;
const SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}select_total_entries`;

export default {
  DOMAIN_PREFIX,
  SET_NAVIGATED_TO,
  FETCH_METERS_SUCCESS,
  ADD_QUERY_PARAMETER,
  SELECT_PAGE,
  RESET_SELECTED_METERS,
  SELECT_METER,
  HIDE_SELECT_TOTAL_ENTRIES,
  SELECT_TOTAL_ENTRIES,
};
