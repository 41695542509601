import React, { Component } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  LoginDetail,
  SSO,
  ForgotPassword,
  ResetPassword,
  LoginUserForGroup,
  AcceptInvite,
  DemoExpired,
  VerifyEmail,
  RedirectToApp,
} from 'views/scenes/Login';
import { AuthSelectors } from 'state/ducks/auth';
import HelperFunctions from 'utilities/HelperFunctions';
import PrivateApp from './PrivateApp';

class Routes extends Component {
  renderAuthenticatedUser = () => {
    if (
      HelperFunctions.getMobileOperatingSystem() === 'ios' ||
      HelperFunctions.getMobileOperatingSystem() === 'android'
    ) {
      return <RedirectToApp />;
    }
    if (this.props.organisation.demo && this.props.organisation.demo_expiration_date) {
      if (moment(this.props.organisation.demo_expiration_date).isBefore()) return <DemoExpired />;
    }
    return <PrivateApp />;
  };

  renderUnauthenticatedUser = () => {
    if (
      HelperFunctions.getMobileOperatingSystem() === 'ios' ||
      HelperFunctions.getMobileOperatingSystem() === 'android'
    ) {
      return <RedirectToApp />;
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  };

  renderPrivateApp = () => {
    if (this.props.isUserAuthenticated) {
      return this.renderAuthenticatedUser();
    }
    if (this.props.isGroupAuthenticated) {
      return <LoginUserForGroup />;
    }
    return this.renderUnauthenticatedUser();
  };

  render() {
    let basename = null;
    if (this.props.currentSystem) {
      basename = this.props.currentSystem.id;
    }
    return (
      <BrowserRouter basename={basename} key={basename}>
        <Switch>
          <Route exact path="/login" component={LoginDetail} />
          <Route path="/login/sso" component={SSO} />
          <Route path="/invite/:token" component={AcceptInvite} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/verify-email/:token" component={VerifyEmail} />
          <Route path="/reset-password/:ticket" component={ResetPassword} />
          {this.renderPrivateApp()}
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
  };
}

export default connect(mapStateToProps)(Routes);
