import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Field } from 'views/components/Shared/General';
import styles from './style.module.scss';

class QuantityInputField extends Component {
  focus = () => {
    this.inputRef.focus();
  };

  render() {
    const { sparePartVendor, sparePartUnit } = this.props;
    let rightInputLabel = null;
    if (sparePartVendor && sparePartVendor.package_quantity) {
      rightInputLabel = <FormattedMessage id="resources.spare-part-vendor.package-unit" />;
    } else {
      rightInputLabel = sparePartUnit == null ? null : sparePartUnit.abbreviation;
    }
    return (
      <List.Item.Column width={100} alignRight>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
          className={styles['amount-container']}
        >
          <Field.Decimal
            alignRight
            ref={ref => (this.inputRef = ref)}
            disabled={this.props.disabled}
            padding={this.props.sparePartUnit ? '0px 5px' : '0px 16px'}
            rightLabel={rightInputLabel}
            value={this.props.value}
            onChange={value => this.props.onChange(value)}
            onBlur={value => this.props.onBlur(value)}
          />
        </div>
      </List.Item.Column>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  return {
    sparePart,
    sparePartVendor: EntitySelectors.getSparePartVendor(state, ownProps.sparePartVendorId),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(QuantityInputField);
