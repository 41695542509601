import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderCategoryType } from 'sdk/System';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import HelperFunctions from 'utilities/HelperFunctions';
import {
  Field,
  Button,
  DatePicker,
  InlineModal,
  FullScreenImagePicker,
  Banner,
  FileUploadWrapper,
  Icon,
  Tooltip,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { Image, Loader } from 'views/components/Image';
import {
  AttachmentItem,
  NewAttachmentModal,
  AttachmentOptionsInlineModal,
} from 'views/components/Attachment';
import { SDKReduxOperations } from 'sdk';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { WorkOrderTypeInlineModal, SpentTimeModal, CategoryInlineModal } from 'views/components/WorkOrder';
import { UserNameWrapper } from 'views/components/User';
import { ChooseUserInlineModal } from 'views/components/User';
import WorkOrderSpentTimeItem from './WorkOrderSpentTimeItem';
import styles from '../style.module.scss';

class CompleteWorkOrderContent extends Component {
  constructor(props) {
    super(props);

    const { editingWorkOrder } = props;
    this.state = {
      showNewFileInlineModalOptions: false,
      showNewAttachmentModalForType: null,
      title: editingWorkOrder.title || '',
      completed_comment: editingWorkOrder.completed_comment,
      deletingImageIds: {},
      uploadingCount: 0,
      currentImage: 0,
      showingImageDialog: false,
      showWorkOrderSpentTimeModal: false,
      showEditWorkOrderSpentTimeModalForWorkOrderSpentTime: null,
    };
  }

  changeTitle = value => {
    if (value.length > 0 && this.props.fieldErrors.title) {
      this.props.removeFieldsFromErrors(['title']);
    }
    this.setState({ title: value });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  isCompletedBeforeRegistration = () => this.props.editingWorkOrder.completed_before_registration;

  renderCompletionAttachmentsContainer = () => {
    const { completionAttachments, uploadingAttachments } = this.props;
    return (
      <Field label={<FormattedMessage id="resources.work-order.files" />}>
        {completionAttachments.length === 0 && uploadingAttachments.length === 0 ? null : (
          <AttachmentItem.Container>
            {uploadingAttachments.map(attachment => (
              <AttachmentItem namespace={this.props.editingWorkOrder.id} uploading attachment={attachment} />
            ))}
            {completionAttachments.map(attachment => {
              return (
                <AttachmentItem
                  key={attachment.id}
                  attachment={attachment}
                  buttons={
                    <AttachmentOptionsInlineModal
                      type="workOrder"
                      attachment={attachment}
                      isDeletingAttachment={this.props.isDeletingAttachment}
                      onDeleteAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                      trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                    />
                  }
                  onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
                />
              );
            })}
          </AttachmentItem.Container>
        )}
        <div
          className={styles['add-button-container']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() =>
            this.setState(prevState => ({
              showNewFileInlineModalOptions: !prevState.showNewFileInlineModalOptions,
            }))
          }
        >
          <Button type="text" label="general.add" primary noUnderline />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showNewFileInlineModalOptions}
          onClose={() => this.setState({ showNewFileInlineModalOptions: false })}
        >
          <React.Fragment>
            <InlineModal.Body width={250} dropdown>
              <InlineModal.ListItem
                icon="upload"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'file',
                  });
                }}
              />
              <InlineModal.ListItem
                icon="link"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'link',
                  });
                }}
              />
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </Field>
    );
  };

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount == 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader />
    );
    return (
      <React.Fragment>
        {this.props.images.map((image, index) => {
          return (
            <Image
              key={image.id}
              medium
              isDeleting={this.state.deletingImageIds[image.id] != null}
              editable
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
              onDelete={() => {
                this.deleteImage(image);
              }}
            />
          );
        })}
        {loaderElements}
      </React.Fragment>
    );
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addDraftImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderSpentTimesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order-spent-time.resource" />}>
        <WorkOrderSpentTimeItem.Container>
          {this.props.editingWorkOrder.work_order_spent_times.map(workOrderSpentTime => {
            return (
              <WorkOrderSpentTimeItem
                workOrderSpentTime={workOrderSpentTime}
                onEdit={() => {
                  this.setState({
                    showWorkOrderSpentTimeModal: true,
                    showEditWorkOrderSpentTimeModalForWorkOrderSpentTime: workOrderSpentTime,
                  });
                }}
                onDelete={() => {
                  this.props.onChangeValue({
                    work_order_spent_times: this.props.editingWorkOrder.work_order_spent_times.filter(
                      ({ id }) => id !== workOrderSpentTime.id
                    ),
                  });
                }}
              />
            );
          })}
        </WorkOrderSpentTimeItem.Container>
        <Button
          type="text"
          primary
          label="general.add"
          noUnderline
          onClick={() =>
            this.setState({
              showWorkOrderSpentTimeModal: true,
              showEditWorkOrderSpentTimeModalForWorkOrderSpentTime: null,
            })
          }
        />
      </Field>
    );
  };

  renderSelectedCategory = () => {
    switch (this.props.editingWorkOrder.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderCategory = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.work-order.category" />}>
            <CategoryInlineModal
              trigger={
                <Field.Resource
                  angleDown
                  error={this.props.fieldErrors.category}
                  value={this.renderSelectedCategory()}
                  onClear={() => this.props.onChangeValue({ category: null })}
                />
              }
              selectedCategory={this.props.editingWorkOrder.category}
              onSelect={category => {
                this.props.removeFieldsFromErrors(['category']);
                this.props.onChangeValue({ category });
              }}
              onClear={e => this.props.onChangeValue({ category: null })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderWorkOrderSpentTimeError = () => {
    if (this.isCompletedBeforeRegistration() && this.props.fieldErrors.work_order_spent_times) {
      return (
        <div className={styles['spent-time-error-container']}>
          <Banner red>
            <FormattedMessage id="screens.work-order.completion-modal.errors.no-registered-time" />
          </Banner>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {this.renderWorkOrderSpentTimeError()}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.title" />}>
                <Field.Text
                  autoFocus
                  error={this.props.fieldErrors.title}
                  rightLabel={
                    <div className={styles['max-characters-container']}>
                      <span className={styles['max-characters']}>
                        <FormattedMessage
                          id="general.characters-left"
                          values={{ amount: 35 - this.state.title.length }}
                        />
                      </span>
                      <Tooltip
                        trigger={
                          <div className={styles['title-popup-container']}>
                            <Icon regular type="question-circle" />
                          </div>
                        }
                        label={<FormattedMessage id="general.characters-left-popup" />}
                      />
                    </div>
                  }
                  value={this.state.title}
                  onChange={value => this.changeTitle(value)}
                  onBlur={() => this.props.onChangeValue({ title: this.state.title })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.completed-comment" />}>
                <Field.Textarea
                  value={this.state.completed_comment}
                  minRows={3}
                  onChange={value => this.setState({ completed_comment: value })}
                  onBlur={() => this.props.onChangeValue({ completed_comment: this.state.completed_comment })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.completed-at" />}>
                <ConnectedFieldErrorWrapper field="completed_date" position="top">
                  {hasError => (
                    <Field.Date
                      error={hasError}
                      value={this.props.editingWorkOrder.completed_date}
                      onChangeDate={completed_date => {
                        if (completed_date && hasError) {
                          this.props.removeFieldsFromErrors(['completed_date']);
                        }
                        this.props.onChangeValue({ completed_date, date: completed_date });
                      }}
                      footerComponent={
                        <DatePicker.Footer
                          showClear={this.props.editingWorkOrder.completed_date != null}
                          onClear={() => {
                            this.props.onChangeValue({ completed_date: null });
                          }}
                        />
                      }
                    />
                  )}
                </ConnectedFieldErrorWrapper>
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.completed-by" />}>
                <ChooseUserInlineModal
                  hideArchived
                  members
                  trigger={
                    <Field.Resource
                      angleDown
                      clearable={false}
                      value={<UserNameWrapper user={this.props.completedByUser} />}
                    />
                  }
                  selectedUserId={this.props.editingWorkOrder.completed_by_user_id}
                  onSelectUser={id => this.props.onChangeValue({ completed_by_user_id: id })}
                  clearable={false}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <ChooseAssetInlineModal
                trigger={
                  <Field label={<FormattedMessage id="resources.work-order.asset" />}>
                    <Field.Resource
                      angleDown
                      error={this.props.fieldErrors.asset_id}
                      value={
                        this.props.asset == null ? null : (
                          <>
                            <AssetTitle id={this.props.asset.id} />
                            {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
                              <div className={styles['tree-path-container']}>
                                <TreePath assetId={this.props.asset.id} />
                              </div>
                            )}
                          </>
                        )
                      }
                      onClear={() => {
                        this.props.onChangeValue({
                          asset_id: null,
                        });
                      }}
                    />
                  </Field>
                }
                selectedAssetId={this.props.editingWorkOrder.asset_id}
                onSelectAsset={assetId => {
                  if (this.props.fieldErrors.asset_id) {
                    this.props.removeFieldsFromErrors(['asset_id']);
                  }
                  this.props.onChangeValue({ asset_id: assetId });
                }}
                onClear={e => this.props.onChangeValue({ asset_id: null })}
              />
            </Grid.Column>
          </Grid.Row>
          {this.renderCategory()}
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.work-order-type" />}>
                <WorkOrderTypeInlineModal
                  trigger={
                    <Field.Resource
                      angleDown
                      error={this.props.fieldErrors.work_order_type_id}
                      value={this.props.workOrderType == null ? null : this.props.workOrderType.title}
                      onClear={() => this.props.onChangeValue({ work_order_type_id: null })}
                    />
                  }
                  selectedWorkOrderTypeId={
                    this.props.workOrderType == null ? null : this.props.workOrderType.id
                  }
                  onSelectWorkOrderType={id => {
                    if (this.props.fieldErrors.work_order_type_id) {
                      this.props.removeFieldsFromErrors(['work_order_type_id']);
                    }
                    this.props.onChangeValue({ work_order_type_id: id });
                  }}
                  onClear={() => this.props.onChangeValue({ work_order_type_id: null })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column md={6}>{this.renderCompletionAttachmentsContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderImagesContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderSpentTimesContainer()}</Grid.Column>
          </Grid.Row>
        </Grid>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <NewAttachmentModal
          isOpen={this.state.showNewAttachmentModalForType != null}
          type={this.state.showNewAttachmentModalForType}
          onClose={() => this.setState({ showNewAttachmentModalForType: null })}
          onCreateNew={params => this.props.onCreateAttachment(params)}
        />
        <SpentTimeModal
          clientWorkOrderSpentTime={this.state.showEditWorkOrderSpentTimeModalForWorkOrderSpentTime}
          open={this.state.showWorkOrderSpentTimeModal}
          onSaved={data => {
            if (this.props.fieldErrors.work_order_spent_times) {
              this.props.removeFieldsFromErrors(['work_order_spent_times']);
            }
            if (this.props.editingWorkOrder.work_order_spent_times.find(({ id }) => data.id === id) == null) {
              this.setState({
                showWorkOrderSpentTimeModal: false,
              });
              this.props.onChangeValue({
                work_order_spent_times: [
                  ...this.props.editingWorkOrder.work_order_spent_times,
                  { id: uuid.v4(), ...data },
                ],
              });
            } else {
              this.setState({
                showWorkOrderSpentTimeModal: false,
              });
              this.props.onChangeValue({
                work_order_spent_times: this.props.editingWorkOrder.work_order_spent_times.map(wosp => {
                  if (wosp.id === data.id) {
                    return data;
                  }
                  return wosp;
                }),
              });
            }
          }}
          onDelete={() => {
            this.setState({
              showWorkOrderSpentTimeModal: false,
            });
            this.props.onChangeValue({
              work_order_spent_times: this.props.editingWorkOrder.work_order_spent_times.filter(
                ({ id }) => id !== this.state.showEditWorkOrderSpentTimeModalForWorkOrderSpentTime.id
              ),
            });
          }}
          onClose={() => {
            this.setState({ showWorkOrderSpentTimeModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createDraftImage: SDKReduxOperations.createDraftImage,
      deleteImage: SDKReduxOperations.deleteImage,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    isAdmin: AuthSelectors.isAdmin(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    asset: EntitySelectors.getAsset(state, ownProps.editingWorkOrder.asset_id),
    workOrderType: EntitySelectors.getWorkOrderType(state, ownProps.editingWorkOrder.work_order_type_id),
    completionAttachments: EntitySelectors.getAttachments(
      state,
      ownProps.editingWorkOrder.completion_attachments
    ),
    completedByUser: EntitySelectors.getUser(state, ownProps.editingWorkOrder.completed_by_user_id),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(
      state,
      ownProps.editingWorkOrder.id
    ),
    images: EntitySelectors.getImages(state, ownProps.editingWorkOrder.completion_images),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CompleteWorkOrderContent));
