import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { ChooseVendorInlineModal } from 'views/components/Vendor';
import { EntitySelectors } from 'sdk/State/entities';

const VendorField = ({ vendor, id, onChange }) => (
  <Grid.Row>
    <Grid.Column>
      <Field view={false} label={<FormattedMessage id="resources.cost.vendor" />}>
        <ChooseVendorInlineModal
          defaultQueryParams={{ assign_work_orders: true }}
          trigger={
            <Field.Resource value={vendor ? vendor.name : null} angleDown onClear={() => onChange(null)} />
          }
          selectedVendorId={id}
          onSelectVendor={onChange}
          clearable={false}
        />
      </Field>
    </Grid.Column>
  </Grid.Row>
);

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorField);
