import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import ChecklistTemplateRowContainer from '../ChecklistTemplateRowContainer';
import { EmptyDataSet, WhiteCard, Button, ConfirmModal } from 'views/components/Shared/General';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { API } from 'sdk';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import CompleteWorkOrder from './CompleteWorkOrder';
import styles from './style.module.scss';

class Values extends Component {
  state = {
    isUpdatingChecklistVersion: false,
    showUpdateChecklistVerisonConfirmModal: false,
  };

  updateChecklistInstance = () => {
    const templateId = this.props.checklistInstance.checklist_template_id;
    const workOrderId = this.props.checklistInstance.work_order_id;
    this.setState({ isUpdatingChecklistVersion: true });
    API.deleteChecklistInstance(this.props.checklistInstance.id).then(() => {
      API.createChecklistInstanceForTemplate(templateId, { work_order_id: workOrderId }).then(() => {
        this.props.onUpdateVersion();
      });
    });
  };

  renderRows = () => {
    if (this.props.rootRowIds.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.subtitle" />}
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }
    return this.props.rootRowIds.map((id, index) => {
      return (
        <ChecklistTemplateRowContainer
          id={id}
          prevTemplateRowId={this.props.rootRowIds[index - 1]}
          nextTemplateRowId={this.props.rootRowIds[index + 1]}
        />
      );
    });
  };

  renderUpdateChecklistModal = () => (
    <ConfirmModal
      open={this.state.showUpdateChecklistVerisonConfirmModal}
      title={<FormattedMessage id="components.checklist-instance-modal.update-version-confirm-modal.title" />}
      message={
        <FormattedMessage id="components.checklist-instance-modal.update-version-confirm-modal.description" />
      }
      confirmButtonText="components.checklist-instance-modal.update-version-confirm-modal.button"
      confirmIsLoading={this.state.isUpdatingChecklistVersion}
      onConfirm={() => {
        if (this.state.isUpdatingChecklistVersion) {
          return;
        }
        this.updateChecklistInstance();
      }}
      onCancel={() => {
        this.setState({ showUpdateChecklistVerisonConfirmModal: false });
      }}
    />
  );

  renderOutdatedVersionBanner = () => {
    const { checklistTemplateVersion, openedFromDeviations, isEditable } = this.props;
    if (openedFromDeviations || isEditable === false) {
      return null;
    }
    if (checklistTemplateVersion && checklistTemplateVersion.status === Status.Archived) {
      let classNames = [styles['banner'], styles['outdated']];
      return (
        <>
          <div className={classNames.join(' ')}>
            <div className={styles['title']}>
              <FormattedMessage
                id="components.checklist-instance-modal.old-version-text"
                values={{
                  time: moment(this.props.checklistInstance.created_at).format('LL'),
                }}
              />
            </div>
            <div>
              <Button
                type="text"
                label="components.checklist-instance-modal.update-version"
                onClick={() => this.setState({ showUpdateChecklistVerisonConfirmModal: true })}
              />
            </div>
          </div>
          <div className={styles['separator']} />
        </>
      );
    }
    return null;
  };

  renderCompleteWorkOrderModal = () => {
    const { checklistInstance } = this.props;
    if (checklistInstance && checklistInstance.work_order_id) {
      return <CompleteWorkOrder />;
    }
    return null;
  };

  render() {
    return (
      <>
        {this.renderOutdatedVersionBanner()}
        {this.renderRows()}
        {this.renderUpdateChecklistModal()}
        {this.renderCompleteWorkOrderModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  const checklistTemplate = ChecklistModalSelectors.getChecklistTemplate(state);
  let checklistTemplateVersion = null;
  if (checklistInstance) {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
  } else {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version
    );
  }
  return {
    checklistInstance,
    checklistTemplateVersion,
    rootRowIds: ChecklistModalSelectors.getRootTemplateFieldIds(state),
    isEditable: ChecklistModalSelectors.isEditable(state),
  };
}

export default connect(mapStateToProps)(Values);
