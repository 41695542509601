import types from './types';

const resetSelectedUsers = payload => ({ type: types.RESET_SELECTED_USERS, payload });

const selectUser = payload => ({ type: types.SELECT_USER, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const fetchUsersSuccess = payload => ({
  type: types.FETCH_USERS_SUCCESS,
  payload,
});

const setAmountOfPayingUsers = payload => ({
  type: types.SET_AMOUNT_OF_PAYING_USERS,
  payload,
});

const fetchPermissionProfilesSuccess = payload => ({
  type: types.FETCH_PERMISSION_PROFILES_SUCCESS,
  payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

export default {
  setAmountOfPayingUsers,
  fetchUsersSuccess,
  fetchPermissionProfilesSuccess,
  addQueryParameter,
  resetSelectedUsers,
  selectUser,
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
};
