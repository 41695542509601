import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { ChecklistTemplateRow } from 'views/components/Checklist';
import { EntitySelectors } from 'sdk/State/entities';
import Row from '../';

class Section extends Component {
  render() {
    return (
      <ChecklistTemplateRow.Section
        id={this.props.id}
        childIds={this.props.childIds}
        prevTemplateRowId={this.props.prevTemplateRowId}
        nextTemplateRowId={this.props.nextTemplateRowId}
      >
        {templateRows => templateRows.map(templateRow => <Row id={templateRow.id} key={templateRow.id} />)}
      </ChecklistTemplateRow.Section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
    childIds: ChecklistModalSelectors.getChildTemplateIdsForRow(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(Section);
