import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List, ConfirmModal } from 'views/components/Shared/General';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeSparePartVendor } from 'sdk/Schemas';
import SparePartVendorDeleteListItem from './SparePartVendorDeleteListItem';
import styles from './styles.module.scss';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';

class MultipleSparePartVendorsDeleteModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    isDeleting: false,
    dropdownOpen: false,
    showMakePrimaryVendorModal: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      API.listSparePartVendors(this.props.currentSystem.id, {
        no_pagination: true,
        spare_part_id: this.props.sparePartId,
        vendor_id: this.props.vendorId,
      }).then(({ data }) => {
        const { entities, result } = normalizeSparePartVendor(data);
        this.props.updateEntities(entities);
        this.setState({ sparePartVendorIds: result, isFetching: false });
      });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });
    Promise.all(this.state.sparePartVendorIds.map(id => this.props.deleteSparePartVendor(id)))
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.vendor.spare-parts.delete-success" />} />
        );
        this.props.onClose();
        this.setState({ showConfirmDeleteModal: false, isDeleting: false });
      })
      .catch(error => {
        this.setState({ isDeleting: false });
        toast(<ToastMessage error text={`Error occurred during deletion: ${error}`} />);
      });
  };

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.props.open}
        title={this.props.title}
        message={this.props.subtitle}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => this.delete()}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          this.props.onClose();
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deleteSparePartVendor: SDKReduxOperations.deleteSparePartVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSparePartVendorsDeleteModal);

MultipleSparePartVendorsDeleteModal.propTypes = {
  open: PropTypes.bool,
  vendorId: PropTypes.string,
  sparePartId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
};

MultipleSparePartVendorsDeleteModal.defaultProps = {
  open: false,
  vendorId: null,
  sparePartId: null,
  title: '',
  subtitle: '',
  onClose: () => {},
};
