import React, { Component } from 'react';
import moment from 'moment';
import { ElapsedTime } from 'views/components/Shared/General';

export default class DowntimeTimer extends Component {
  state = {
    milliSecondsSinceStarted: null,
  };

  componentDidMount() {
    this.setState({ milliSecondsSinceStarted: this.getTotalDownTimeFromStartDateTime(this.props.downtime) });
    this.interval = setInterval(
      () =>
        this.setState({
          milliSecondsSinceStarted: this.getTotalDownTimeFromStartDateTime(this.props.downtime),
        }),
      1000
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.downtime.from !== this.props.downtime.from) {
      this.setState({
        milliSecondsSinceStarted: this.getTotalDownTimeFromStartDateTime(this.props.downtime),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTotalDownTimeFromStartDateTime = downtime => {
    const ms = moment().diff(moment(downtime.from));
    const d = moment.duration(ms);
    const durationHours = d.asHours();
    const durationMinutes = d.asMinutes();

    if (durationHours > 24) {
      return <ElapsedTime elapsedMinutes={Math.floor(durationMinutes)} displayDays />;
    }
    return Math.floor(durationHours) + moment.utc(ms).format(':mm:ss');
  };

  render() {
    return this.state.milliSecondsSinceStarted;
  }
}
