import moment from 'moment';

export const NotEditableKeys = [
  'checklist_template_links',
  'registration_attachments',
  'images',
  'registration_images',
  'spare_part_reservations',
];

export function getWeekdayOfMonth(date) {
  return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][moment(date).day()];
}

export function getDayOfMonth(date) {
  return moment(date).date();
}

export function getWeekOfMonth(date) {
  const dayOfMonth = getDayOfMonth(date);
  if (dayOfMonth > 28) {
    return -1;
  } else {
    return Math.ceil(dayOfMonth / 7);
  }
}

export function getMonthOfYear(date) {
  return moment(date).month();
}

export function getColorForStatus(status) {
  switch (status) {
    case 'active':
      return '#2BAF2D';
    default:
      return '#9F9F9F';
  }
}

export function getTranslationForStatus(status) {
  switch (status) {
    case 'created':
      return 'resources.work-order.statuses.created';
    case 'draft':
      return 'resources.work-order.statuses.draft';
    case 'active':
      return 'resources.work-order.statuses.active';
  }
}

export function getNewWeekdays(recurringMaintenance, newStartDate) {
  const oldWeekday = getWeekdayOfMonth(recurringMaintenance.start_date);
  const newWeekday = getWeekdayOfMonth(newStartDate);
  const { mon, tue, wed, thu, fri, sat, sun } = recurringMaintenance;
  const count = [mon, tue, wed, thu, fri, sat, sun].filter(b => b).length;

  if (count <= 1) {
    return {
      mon: newWeekday === 'mon',
      tue: newWeekday === 'tue',
      wed: newWeekday === 'wed',
      thu: newWeekday === 'thu',
      fri: newWeekday === 'fri',
      sat: newWeekday === 'sat',
      sun: newWeekday === 'sun',
    };
  } else if (recurringMaintenance[oldWeekday] && !recurringMaintenance[newWeekday]) {
    return {
      mon,
      tue,
      wed,
      thu,
      fri,
      sat,
      sun,
      [oldWeekday]: false,
      [newWeekday]: true,
    };
  } else {
    return {};
  }
}

export function buildRecurringMaintenanceFromEditingWorkOrder(editingWorkOrder) {
  const {
    due_meter_value: meter_start_value,
    date: start_date,
    registration_images: images,
    month_type,
    ...workOrderWithoutDate
  } = editingWorkOrder;
  if (editingWorkOrder.meter_id) {
    return {
      ...workOrderWithoutDate,
      images,
      meter_start_value,
      month_type: month_type || 'exact_day',
    };
  } else {
    return {
      ...workOrderWithoutDate,
      images,
      start_date,
      month_type: month_type || 'exact_day',
    };
  }
}
