import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { DragSource } from 'react-dnd';
import { bindActionCreators } from 'redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getEstimatedDeliveryDateText, EstimatedDeliveryMode } from 'sdk/PurchaseOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { ToolbarListType } from 'views/scenes/Planning/Calendar/Calendar/utils';
import { CalendarOperations } from 'state/ducks/calendar';
import { Type } from 'views/scenes/Planning/Calendar/Calendar/utils';
import styles from './style.module.scss';

const cardSource = {
  beginDrag(props) {
    const { id, assigned_to_users, assigned_to_groups, assigned_to_vendors, due_date } = props.workOrder;
    props.beginDragFromToolbox({
      workOrderId: id,
    });
    return {
      fromToolboxList: ToolbarListType.AwaitingDelivery,
      workOrderId: id,
      date: due_date,
      instanceId: id,
      users: assigned_to_users,
      groups: assigned_to_groups,
      vendors: assigned_to_vendors,
    };
  },
  endDrag(props, monitor, component) {
    props.endDragFromToolbox();
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

class WorkOrderListItem extends PureComponent {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  renderSubtitle = () => {
    const estimatedDate = getEstimatedDeliveryDateText(this.props.purchaseOrder);
    if (estimatedDate == null) {
      return <span>-</span>;
    }
    return (
      <FormattedMessage
        id="screens.calendar.toolbox.lists.awaiting-delivery.expected-date"
        values={{
          date: estimatedDate,
        }}
      />
    );
  };

  render() {
    return this.props.connectDragSource(
      <div
        onClick={e => {
          e.stopPropagation();
          const { top, left, width } = this.workOrderListItemRef.getBoundingClientRect();
          const workOrderId = this.props.workOrder.id;
          this.props.showPreviewWorkOrderOverlay({ top, left, width, workOrderId });
        }}
        className={`overlay-trigger ${styles['work-order-list-item']}`}
        ref={ref => (this.workOrderListItemRef = ref)}
      >
        <div className={styles['title-container']}>
          <div className={styles['title']}>{this.props.workOrder.title}</div>
          <div className={styles['number']}>#{this.props.workOrder.number}</div>
        </div>
        <div className={styles['subtitle']}>{this.renderSubtitle()}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showPreviewWorkOrderOverlay: CalendarOperations.showPreviewWorkOrderOverlay,
      beginDragFromToolbox: CalendarOperations.beginDragFromToolbox,
      endDragFromToolbox: CalendarOperations.endDragFromToolbox,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.id);
  const workOrderPurchases = EntitySelectors.getWorkOrderPurchases(state, workOrder.work_order_purchases);
  const purchaseOrdersSortedByLastDeliveryDate = EntitySelectors.getPurchaseOrders(
    state,
    workOrderPurchases.map(({ purchase_order_id }) => purchase_order_id)
  ).sort((a, b) => {
    let aDateToCompare = null;
    let bDateToCompare = null;
    if (a.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
      aDateToCompare = a.estimated_delivery_date;
    } else {
      aDateToCompare = a.estimated_delivery_to_row_date;
    }
    if (b.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
      bDateToCompare = b.estimated_delivery_date;
    } else {
      bDateToCompare = b.estimated_delivery_to_row_date;
    }
    if (aDateToCompare == null) {
      return 1;
    } else if (bDateToCompare == null) {
      return -1;
    }
    var dateA = new Date(aDateToCompare);
    var dateB = new Date(bDateToCompare);
    return dateB - dateA;
  });
  const purchaseOrderWithTheLastDeliveryDate = purchaseOrdersSortedByLastDeliveryDate[0];

  return {
    workOrder,
    purchaseOrder: purchaseOrderWithTheLastDeliveryDate,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragSource(Type.Calendar, cardSource, collect)(WorkOrderListItem));
