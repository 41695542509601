import types from './types';

/*
  Detail
*/
const resetState = () => ({
  type: types.RESET_STATE,
});

const fetchWorkOrder = payload => ({
  type: types.FETCH_WORK_ORDER,
  payload: payload,
});

const resetEditingWorkOrder = () => ({
  type: types.RESET_EDITING_WORK_ORDER,
});

const setWorkOrderForEdit = payload => ({
  type: types.SET_WORK_ORDER_FOR_EDIT,
  payload: payload,
});

const setEditingWorkOrderValues = data => ({
  type: types.SET_EDITING_WORK_ORDER_VALUES,
  payload: data,
});

/*
  History
*/
const fetchPreviousWorkOrders = () => ({ type: types.FETCH_PREVIOUS_WORK_ORDERS });
const fetchPreviousWorkOrdersSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_PREVIOUS_WORK_ORDERS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

/*
  Activities
*/
const fetchWorkOrderActivitiesSuccess = data => ({
  type: types.FETCH_WORK_ORDER_ACTIVITIES_SUCCESS,
  payload: data,
});

export default {
  resetState,
  fetchWorkOrder,
  resetEditingWorkOrder,
  setEditingWorkOrderValues,
  setWorkOrderForEdit,
  fetchPreviousWorkOrders,
  fetchPreviousWorkOrdersSuccess,
  fetchWorkOrderActivitiesSuccess,
};
