import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const SelectedAssetTypeContainer = ({ assetType }) => <span>{assetType.title}</span>;

function mapStateToProps(state, ownProps) {
  return {
    assetType: EntitySelectors.getAssetType(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedAssetTypeContainer);
