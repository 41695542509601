import React from 'react';

const Address = ({ ignoreName, name, address1, address2, zip, city, country }) => {
  return (
    <React.Fragment>
      {ignoreName ? null : <p>{name}</p>}
      <p>{address1}</p>
      <p>{address2}</p>
      <p>
        <span>{zip}</span>
        {city ? <span> {city}</span> : null}
      </p>
      <p>{country}</p>
    </React.Fragment>
  );
};

export default Address;
