import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const RightContent = props => {
  return (
    <div className={styles['right-content']}>{props.children}</div>
  );
};

export default RightContent;

RightContent.propTypes = {
  children: PropTypes.node,
};

RightContent.defaultProps = {
  children: null,
};
