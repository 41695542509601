import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CardGrid } from 'views/components/Shared/Layout';
import { SectionHeader } from 'views/components/Shared/General';
import Attachment from './Attachment';
import UploadingAttachment from './UploadingAttachment';
import { EditOperationalMaintenanceTemplateSelectors } from 'state/ducks/editOperationalMaintenanceTemplate';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';

class AttachmentsContainer extends Component {
  render() {
    const { uploadingAttachments, attachments } = this.props;
    if (uploadingAttachments.length === 0 && attachments.length === 0) return null;
    return (
      <CardGrid.Row>
        <SectionHeader paddingHorizontal={30} horizontalBorders>
          <FormattedMessage id="resources.operational-maintenance-template-version.attachments" />
        </SectionHeader>
        {this.props.uploadingAttachments.map(uploadingAttachment => (
          <UploadingAttachment uploadingAttachment={uploadingAttachment} />
        ))}
        {attachments.map(attachment => {
          return <Attachment attachment={attachment} key={attachment.attachment_id} />;
        })}
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    attachments: EditOperationalMaintenanceTemplateSelectors.getAttachments(state),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, 'editOpTemplate'),
  };
}

export default connect(mapStateToProps)(AttachmentsContainer);
