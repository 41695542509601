import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Decimal } from 'decimal.js';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { ContentContainer } from 'views/components/Shared/Layout';
import { FormattedNumber, Icon, Button } from 'views/components/Shared/General';
import { ChangeMeterValueModal } from 'views/components/Meter';
import Header from '../../Header';
import { ChangeFrequencyModal, ChangeDailyUsageModal } from '../components';
import Graph from './Graph';
import styles from './style.module.scss';

class Overview extends Component {
  state = {
    showChangeFrequencyModal: false,
    showChangeMeterValueModal: false,
    isLoadingValue: false,
  };

  formatDailyUsage = () => {
    if (!this.props.meter.average_daily_usage) return ' - ';
    let roundedUsagePerDay = new Decimal(this.props.meter.average_daily_usage).toDecimalPlaces(2).toString();
    if (this.props.meterUnit) {
      return (
        <p className={styles['highlighted-text']}>
          {roundedUsagePerDay} {this.props.meterUnit.abbreviation} /{' '}
          <FormattedMessage id="screens.meter.tabs.overview.day" />
        </p>
      );
    } else {
      return (
        <p className={styles['highlighted-text']}>
          {roundedUsagePerDay} <FormattedMessage id="screens.meter.tabs.overview.per-day" />
        </p>
      );
    }
  };

  formatReadingFrequency = () => {
    if (this.props.meter.reading_frequency === 1) {
      return <FormattedMessage id="screens.meter.tabs.overview.update-frequency.one" />;
    } else if (this.props.meter.reading_frequency === 2) {
      return <FormattedMessage id="screens.meter.tabs.overview.update-frequency.two" />;
    } else {
      let readingFrequency = null;
      if (this.props.language === 'sv') {
        readingFrequency = SDKHelperFunctions.getOrdinalSuffix(
          this.props.intl,
          this.props.meter.reading_frequency
        );
      } else {
        readingFrequency = this.props.meter.reading_frequency;
      }
      if (this.props.meter.reading_frequency) {
        return (
          <FormattedPlural
            value={this.props.meter.reading_frequency}
            few={
              <FormattedMessage
                id="screens.meter.tabs.overview.update-frequency.few"
                values={{
                  days: readingFrequency,
                }}
              />
            }
            many={
              <FormattedMessage
                id="screens.meter.tabs.overview.update-frequency.many"
                values={{
                  days: readingFrequency,
                }}
              />
            }
            other={
              <FormattedMessage
                id="screens.meter.tabs.overview.update-frequency.other"
                values={{
                  days: readingFrequency,
                }}
              />
            }
          />
        );
      } else return '-';
    }
  };

  renderLoader = () => {
    return (
      <div style={{ width: 200, height: 83 }}>
        <ContentLoader viewBox="0 0 200 83">
          <rect x="50" y="10" rx="2" ry="2" width="100" height="22" />
          <rect x="0" y="48" rx="2" ry="2" width="200" height="12" />
          <rect x="15" y="68" rx="2" ry="2" width="170" height="12" />
        </ContentLoader>
      </div>
    );
  };

  renderValueLastUpdated = () => {
    if (this.props.meter.value_last_updated == null) {
      return '-';
    }
    var valueLastUpdated = moment(this.props.meter.value_last_updated);
    var today = moment().startOf('day');
    var difference = today.diff(valueLastUpdated, 'days');
    if (difference === 0) {
      return <FormattedMessage id="calendar.today" />;
    } else if (difference === 1) {
      return <FormattedMessage id="calendar.yesterday" />;
    } else {
      return (
        <FormattedPlural
          value={difference}
          two={
            <FormattedMessage
              id="resources.meter.last-updated-values.two"
              values={{
                days: difference,
              }}
            />
          }
          few={
            <FormattedMessage
              id="resources.meter.last-updated-values.few"
              values={{
                days: difference,
              }}
            />
          }
          many={
            <FormattedMessage
              id="resources.meter.last-updated-values.many"
              values={{
                days: difference,
              }}
            />
          }
          other={
            <FormattedMessage
              id="resources.meter.last-updated-values.other"
              values={{
                days: difference,
              }}
            />
          }
        />
      );
    }
  };

  renderReadingValue = () => {
    return (
      <>
        <div className={styles['section-header']}>
          <FormattedMessage id="resources.meter.value" />
        </div>

        <div className={styles['reading']}>
          {this.state.isLoadingValue ? (
            this.renderLoader()
          ) : (
            <>
              <div className={styles['current-value']}>
                <FormattedNumber
                  value={this.props.meter.value}
                  unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                  valueSize={28}
                  abbreviationSize={16}
                />
              </div>
              <p className={styles['reading-subtitle']}>
                <FormattedMessage id="screens.meter.tabs.overview.last-update" />
                <span className={styles['highlighted-text']}>: {this.renderValueLastUpdated()}</span>
              </p>
              <div className={styles['reading-subtitle']}>
                <p>
                  <FormattedMessage id="screens.meter.tabs.overview.average-usage" />
                  {': '}
                  {this.formatDailyUsage()}
                </p>
                {this.props.canEditMeters ? (
                  <span className={styles['edit-icon']}>
                    <div style={{ position: 'relative', width: 0, height: 0 }}>
                      <div style={{ position: 'absolute', top: -12 }}>
                        <Button
                          type="icon"
                          iconButtonSize={24}
                          icon={<Icon regular type="pen" size={10} />}
                          onClick={() => this.setState({ showChangeDailyUsageModal: true })}
                        />
                      </div>
                    </div>
                  </span>
                ) : null}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  /*
  --------
  Modals
  --------
  */

  renderUpdateFrequencyModal = () => {
    return (
      <ChangeFrequencyModal
        id={this.props.meter.id}
        open={this.state.showChangeFrequencyModal}
        onClose={() => this.setState({ showChangeFrequencyModal: false })}
      />
    );
  };

  renderUpdateMeterValueModal = () => {
    return (
      <ChangeMeterValueModal
        meterId={this.props.meter.id}
        createForMeterId={this.props.meter.id}
        open={this.state.showChangeMeterValueModal}
        onClose={() => this.setState({ showChangeMeterValueModal: false })}
      />
    );
  };

  renderUpdateDailyUsageModal = () => {
    return (
      <ChangeDailyUsageModal
        id={this.props.meter.id}
        open={this.state.showChangeDailyUsageModal}
        onClose={() => this.setState({ showChangeDailyUsageModal: false })}
      />
    );
  };

  renderUpdateButton = () => {
    return (
      <div
        className={styles['update-reading']}
        onClick={() => this.setState({ showChangeMeterValueModal: true })}
      >
        <span className={styles['icon']}>
          <Icon blue withBackground backgroundSize={34} type="tachometer-alt-slow" />
        </span>
        <div className={styles['text']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.meter.tabs.overview.update-title" />
          </p>
          <p className={styles['subtitle']}>
            <FormattedMessage id="screens.meter.tabs.overview.update-subtitle" />
          </p>
        </div>
      </div>
    );
  };
  renderSettings = () => {
    let classNames = [styles['settings']];
    if (this.props.canEditMeters) {
      classNames = [...classNames, styles['clickable']];
    }
    return (
      <>
        <div className={styles['settings-header']}>
          <FormattedMessage id="screens.meter.tabs.overview.settings-header" />
        </div>
        <div
          className={classNames.join(' ')}
          onClick={() => {
            if (this.props.canEditMeters) {
              this.setState({ showChangeFrequencyModal: true });
            }
          }}
        >
          <Icon type="clock" orange withBackground backgroundSize={34} />
          <div className={styles['text']}>
            <p className={styles['title']}>
              <FormattedMessage id="screens.meter.tabs.overview.settings-title" />
            </p>
            <p className={styles['subtitle']}>{this.formatReadingFrequency()}</p>
          </div>
        </div>
      </>
    );
  };

  renderGraphContainer = () => {
    return (
      <div className={styles['graph-container']}>
        <Graph />
      </div>
    );
  };
  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer maxWidth={960}>
            <div className={styles['content']}>
              <div className={styles['left-content']}>
                {this.renderReadingValue()}{' '}
                {this.props.canEditMeterReadings ? this.renderUpdateButton() : null}{' '}
                <div className={styles['separator']} />
                {this.renderSettings()}
              </div>
              {this.renderGraphContainer()}
            </div>
          </ContentContainer>
        </PerfectScrollbar>
        {this.renderUpdateFrequencyModal()}
        {this.renderUpdateDailyUsageModal()}
        {this.renderUpdateMeterValueModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.match.params.id);
  return {
    system: AuthSelectors.getCurrentSystem(state),
    language: AuthSelectors.getLanguage(state),
    meter,
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
    canEditMeterReadings: AuthSelectors.canEditMeterReadings(state),
    canEditMeters: AuthSelectors.canEditMeters(state),
  };
}
export default withRouter(injectIntl(connect(mapStateToProps)(Overview)));
