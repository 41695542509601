import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { UserType } from 'sdk/SystemAccess';
import {
  List,
  Icon,
  Button,
  NewInlineModal,
  Tooltip,
  ConfirmModal,
  Datapill,
  Banner,
} from 'views/components/Shared/General';
import { AssetTitle, NewSelectAssetModal, TreePath } from 'views/components/Asset';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Type } from 'sdk/User';
import UserInfoModal from '../components/UserInfoModal';
import UserDeleteModal from '../components/UserDeleteModal';
import UserLoginModal from '../components/UserLoginModal';
import UserPermissionModal from '../components/UserPermissionModal';
import UserResetPasswordModal from '../components/UserResetPasswordModal';
import UserPinModal from '../components/UserPinModal';
import EditPinModal from '../components/EditPinModal';
import ResendInviteModal from '../components/ResendInviteModal';
import UserArchiveModal from '../components/UserArchiveModal';
import CantEditOrgAdminPermissionModal from '../components/CantEditOrgAdminPermissionModal';
import styles from './style.module.scss';

class UserListItem extends Component {
  state = {
    dropdownOpen: false,
    showUserInfoModal: false,
    showUserDeleteModal: false,
    showUserLoginModal: false,
    showUserPinModal: false,
    showUserPermissionModal: false,
    showResetPasswordModal: false,
    showResendInviteModal: false,
    showEditPinModal: false,
    isHoveringListItem: false,
    showSelectAssetModal: false,
    isCreatingAssetOperatorForAssetId: null,
    isRemovingAssetCheckin: false,
    showRemoveAssetFromOperatorModal: false,
    showCantEditOrgAdminPermissionModal: false,
    showArchiveUserModal: false,
    showRestoreUserFromArchiveModal: false,
    isRestoringUserFromArchive: false,
  };

  renderPermission = () => {
    const { organisation, systemAccess, user, permissionProfile, settings } = this.props;

    if (user.organisation_admin && organisation.multiple_systems) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.org-admin" />
          </span>
        </List.Item.Column>
      );
    }
    if (systemAccess.user_type === UserType.SystemAdmin) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.admin" />
          </span>
        </List.Item.Column>
      );
    } else if (systemAccess.user_type === UserType.Operator) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.operator" />
          </span>
        </List.Item.Column>
      );
    } else if (systemAccess.user_type === UserType.ProductionSupervisor) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.production-supervisor" />
          </span>
        </List.Item.Column>
      );
    } else if (systemAccess.user_type === UserType.ViewOnly) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.view-only" />
          </span>
        </List.Item.Column>
      );
    } else if (systemAccess.user_type === UserType.WorkRequester) {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>
            <FormattedMessage id="resources.user.work-requester" />
          </span>
        </List.Item.Column>
      );
    } else {
      return (
        <List.Item.Column width={242}>
          <span className={styles['permissions']}>{permissionProfile ? permissionProfile.title : null}</span>
        </List.Item.Column>
      );
    }
  };

  renderSubtitle = () => {
    const { user } = this.props;
    if (user.email) {
      return user.email;
    }
    return null;
  };

  renderUserInfoModal = () => {
    return (
      <UserInfoModal
        id={this.props.user.id}
        open={this.state.showUserInfoModal}
        onClose={() => this.setState({ showUserInfoModal: false })}
      />
    );
  };

  renderUserDeleteModal = () => {
    return (
      <UserDeleteModal
        id={this.props.user.id}
        open={this.state.showUserDeleteModal}
        onClose={() => this.setState({ showUserDeleteModal: false })}
      />
    );
  };

  renderUserArchiveModal = () => {
    return (
      <UserArchiveModal
        id={this.props.user.id}
        open={this.state.showArchiveUserModal}
        onClose={() => this.setState({ showArchiveUserModal: false })}
      />
    );
  };

  renderUserPermissionModal = () => {
    return (
      <UserPermissionModal
        id={this.props.user.id}
        open={this.state.showUserPermissionModal}
        onClose={() => this.setState({ showUserPermissionModal: false })}
      />
    );
  };

  renderEditPinModal = () => {
    return (
      <EditPinModal
        id={this.props.user.id}
        open={this.state.showEditPinModal}
        onCloseWithoutPin={() => {
          this.setState({ showEditPinModal: false });
        }}
        onClose={() => {
          this.setState({ showEditPinModal: false });
        }}
      />
    );
  };

  renderUserPinModal = () => {
    return (
      <UserPinModal
        id={this.props.user.id}
        open={this.state.showUserPinModal}
        openEditPin={() => {
          this.setState({ showUserPinModal: false });
          setTimeout(() => {
            this.setState({ showEditPinModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showUserPinModal: false })}
      />
    );
  };

  renderUserLoginModal = () => {
    return (
      <UserLoginModal
        id={this.props.user.id}
        open={this.state.showUserLoginModal}
        onResetPassword={() => {
          this.setState({ showUserLoginModal: false });
          setTimeout(() => {
            this.setState({ showResetPasswordModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showUserLoginModal: false })}
      />
    );
  };

  renderResetPasswordModal = () => {
    return (
      <UserResetPasswordModal
        id={this.props.user.id}
        open={this.state.showResetPasswordModal}
        onClose={() => {
          this.setState({ showResetPasswordModal: false });
        }}
      />
    );
  };

  renderResendInviteModal = () => {
    return (
      <ResendInviteModal
        id={this.props.user.id}
        open={this.state.showResendInviteModal}
        onClose={() => {
          this.setState({ showResendInviteModal: false });
        }}
      />
    );
  };

  renderCantEditOrgAdminPermissionModal = () => {
    return (
      <CantEditOrgAdminPermissionModal
        open={this.state.showCantEditOrgAdminPermissionModal}
        onClose={() => {
          this.setState({ showCantEditOrgAdminPermissionModal: false });
        }}
      />
    );
  };

  renderListItemRightComponent = () => {
    const { user, settings, systemAccess, currentUser, organisationSettings } = this.props;
    if (user.invited) {
      return (
        <Tooltip
          label={<FormattedMessage id="screens.users.invited-tooltip" />}
          trigger={
            <div>
              <Icon regular type="envelope" />
            </div>
          }
        />
      );
    }
    if (organisationSettings.sso_oidc_activated === false) {
      if (user.login_credential == null && !user.scim) {
        if (currentUser.organisation_admin === false && user.organisation_admin === true) {
          return null;
        }
        if (systemAccess.user_type === UserType.Operator) {
          return null;
        }
        return (
          <Tooltip
            label={<FormattedMessage id="screens.users.no-login-tooltip" />}
            trigger={
              <div>
                <Icon regular type="exclamation-circle" red />
              </div>
            }
          />
        );
      }
    }

    return null;
  };

  renderSCIMStatus = () => {
    if (this.props.user.scim) {
      return (
        <div className={styles['scim-banner-text']}>
          <FormattedMessage id="screens.settings.user.general.created-from-idp" />
        </div>
      );
    }
    return null;
  };

  renderInlineModalListItemRightcomponent = () => {
    const { systemAccess, user, organisationSettings } = this.props;
    if (organisationSettings.sso_oidc_activated === false) {
      if (user.login_credential == null && systemAccess.user_type !== UserType.Operator) {
        return <Icon regular type="exclamation-circle" red />;
      }
    }

    return null;
  };

  renderInvitedInlineModalListItem = () => {
    if (this.props.user.invited) {
      return (
        <NewInlineModal.Dropdown.Item
          leftComponent={
            <span>
              <FormattedMessage id="screens.users.action-dropdown.send-invite-again" />
            </span>
          }
          onClick={() => {
            this.setState({ isHoveringListItem: false, dropdownOpen: false, showResendInviteModal: true });
          }}
        />
      );
    }
    return null;
  };

  renderChangePermissionDropdownItem = () => {
    if (this.props.isAdmin) {
      return (
        <NewInlineModal.Dropdown.Item
          leftComponent={
            <span>
              <FormattedMessage id="screens.users.action-dropdown.change-rights" />
            </span>
          }
          onClick={() => {
            const { user, organisation } = this.props;

            const cantEdit = user.organisation_admin === true && organisation.multiple_systems == true;

            this.setState({
              isHoveringListItem: false,
              dropdownOpen: false,
              showUserPermissionModal: !cantEdit,
              showCantEditOrgAdminPermissionModal: cantEdit,
            });
          }}
        />
      );
    }
    return null;
  };

  renderDropdown = () => {
    const { user } = this.props;
    return (
      <NewInlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.dropdownOpen}
        minWidth={265}
        position="right"
        onClose={() => this.setState({ dropdownOpen: false })}
      >
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item
              leftComponent={<FormattedMessage id="screens.users.action-dropdown.change-info" />}
              onClick={() => {
                this.setState({ isHoveringListItem: false, dropdownOpen: false, showUserInfoModal: true });
              }}
            />
            {this.renderChangePermissionDropdownItem()}
            <NewInlineModal.Dropdown.Item
              leftComponent={
                <span>
                  <FormattedMessage id="screens.users.action-dropdown.change-login" />
                </span>
              }
              rightComponent={this.renderInlineModalListItemRightcomponent()}
              onClick={() => {
                this.setState({ isHoveringListItem: false, dropdownOpen: false, showUserLoginModal: true });
              }}
            />
            {this.renderInvitedInlineModalListItem()}
            {this.props.systemAccess.user_type === UserType.Operator ? (
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <span>
                    <FormattedMessage id="screens.users.action-dropdown.change-pin" />
                  </span>
                }
                onClick={() => {
                  if (this.props.user.pin_code_required) {
                    this.setState({ isHoveringListItem: false, dropdownOpen: false, showUserPinModal: true });
                  } else {
                    this.setState({ isHoveringListItem: false, dropdownOpen: false, showEditPinModal: true });
                  }
                }}
              />
            ) : null}
            <NewInlineModal.Dropdown.Separator />
            {this.renderArchiveAndDeleteUserOptions()}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </NewInlineModal>
    );
  };

  renderArchiveAndDeleteUserOptions = () => {
    const { user, currentUser } = this.props;
    if (user.type === Type.Organisation && currentUser.type === Type.System) {
      return (
        <NewInlineModal.Dropdown.Item
          destructive
          leftComponent={<FormattedMessage id="screens.users.action-dropdown.remove-user" />}
          onClick={() => {
            this.setState({
              isHoveringListItem: false,
              dropdownOpen: false,
              showUserDeleteModal: true,
            });
          }}
        />
      );
    }
    if (user.archived) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            leftComponent={<FormattedMessage id="screens.users.action-dropdown.restore-user" />}
            onClick={() => {
              this.setState({
                isHoveringListItem: false,
                dropdownOpen: false,
                showRestoreUserFromArchiveModal: true,
              });
            }}
          />
          <NewInlineModal.Dropdown.Item
            destructive
            leftComponent={
              <span className={styles['danger-text']}>
                <FormattedMessage id="screens.users.action-dropdown.delete-user" />
              </span>
            }
            onClick={() => {
              this.setState({
                isHoveringListItem: false,
                dropdownOpen: false,
                showUserDeleteModal: true,
              });
            }}
          />
        </>
      );
    }
    return (
      <NewInlineModal.Dropdown.Item
        destructive
        leftComponent={
          <span className={styles['danger-text']}>
            <FormattedMessage id="screens.users.action-dropdown.archive-user" />
          </span>
        }
        onClick={() => {
          this.setState({
            isHoveringListItem: false,
            dropdownOpen: false,
            showArchiveUserModal: true,
          });
        }}
      />
    );
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        additionalApiParams={{
          exists_for_operator_user: this.props.user.id,
        }}
        title={<FormattedMessage id="screens.users.select-asset-modal.title" />}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={asset => {
          if (asset.exists_for_operator_user === true) {
            return (
              <div className={styles['selected-asset-button']}>
                <FormattedMessage id="general.already-added" />
              </div>
            );
          }
          return (
            <Button
              gray
              small
              label="general.choose"
              loading={this.state.isCreatingAssetOperatorForAssetId === asset.id}
              onClick={e => {
                e.stopPropagation();
                this.setState({ isCreatingAssetOperatorForAssetId: asset.id });
                this.props
                  .createAssetOperator(this.props.system.id, {
                    asset_id: asset.id,
                    user_id: this.props.user.id,
                  })
                  .then(res => {
                    toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
                    this.setState({ isCreatingAssetOperatorForAssetId: null, showSelectAssetModal: false });
                  })
                  .catch(e => {});
              }}
            />
          );
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  renderRemoveAsseFromOperatorModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showRemoveAssetFromOperatorModal}
      title={<FormattedMessage id="screens.users.operators.remove-asset-modal.title" />}
      message={<FormattedMessage id="screens.users.operators.remove-asset-modal.subtitle" />}
      confirmButtonText="general.remove"
      confirmIsLoading={this.state.isRemovingAssetCheckin}
      onConfirm={() => {
        this.setState({ isRemovingAssetCheckin: true });
        this.props
          .deleteAssetOperator(this.state.showRemoveAssetFromOperatorModalForAssetOperatorId)
          .then(res => {
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
            this.setState({ isRemovingAssetCheckin: null, showRemoveAssetFromOperatorModal: false });
          })
          .catch(e => {});
      }}
      onCancel={() => {
        this.setState({ showRemoveAssetFromOperatorModal: null });
      }}
    />
  );

  renderRestoreUserFromArchiveModal = () => (
    <ConfirmModal
      open={this.state.showRestoreUserFromArchiveModal}
      title={<FormattedMessage id="screens.users.restore-user-modal.title" />}
      message={<FormattedMessage id="screens.users.restore-user-modal.subtitle" />}
      confirmButtonText="screens.users.restore-user-modal.button"
      confirmIsLoading={this.state.isRestoringUserFromArchive}
      onConfirm={() => {
        this.setState({ isRestoringUserFromArchive: true });
        this.props
          .updateUser(this.props.user.id, { archived: false })
          .then(res => {
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
            this.setState({ isRestoringUserFromArchive: false, showRestoreUserFromArchiveModal: false });
          })
          .catch(e => {});
      }}
      onCancel={() => {
        this.setState({ showRestoreUserFromArchiveModal: false });
      }}
    />
  );

  renderDropdownButton = () => {
    const { currentUser, user } = this.props;
    if (currentUser.organisation_admin === false && user.organisation_admin === true) {
      return (
        <Tooltip
          label={<FormattedMessage id="screens.users.cant-edit-org-admin" />}
          trigger={<Button disabled caret gray small label="screens.users.action-button" />}
        />
      );
    }
    if (currentUser.id === user.id) {
      return (
        <Tooltip
          label={<FormattedMessage id="screens.users.cant-edit-own-user" />}
          trigger={<Button disabled caret gray small label="screens.users.action-button" />}
        />
      );
    }
    return (
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))}
      >
        <Button caret gray small label="screens.users.action-button" />
      </div>
    );
  };

  renderTitle = () => {
    const { user } = this.props;
    if (user.archived === true) {
      return <span className={styles['archived']}>{this.props.user.name}</span>;
    }
    return <span>{this.props.user.name}</span>;
  };

  renderAssetOperators = () => {
    if (this.props.showingOperators) {
      return (
        <div className={styles['operator-for-assets-container']}>
          <div className={styles['label']}>
            <FormattedMessage id="screens.users.assets-for-operator" />
            <span>: </span>
          </div>
          <div>
            <Datapill.List>
              {this.props.assetOperators.map(({ id, asset_id }) => (
                <Datapill
                  value={
                    <div className={styles['remove-asset-button']}>
                      <div>
                        <AssetTitle id={asset_id} />
                      </div>
                      <div
                        className={styles['icon-container']}
                        onClick={() =>
                          this.setState({
                            showRemoveAssetFromOperatorModal: true,
                            showRemoveAssetFromOperatorModalForAssetOperatorId: id,
                          })
                        }
                      >
                        <Icon regular type="times" size={12} />
                      </div>
                    </div>
                  }
                />
              ))}
              <Datapill
                gray
                clickable
                onClick={() => this.setState({ showSelectAssetModal: true })}
                value={
                  <div className={styles['add-asset-button']}>
                    <div className={styles['icon-container']}>
                      <Icon regular type="plus" size={12} />
                    </div>
                    <div>
                      <FormattedMessage id="screens.users.add-asset" />
                    </div>
                  </div>
                }
              />
            </Datapill.List>
          </div>
        </div>
      );
    }
    return null;
  };

  renderOperatorIcons = () => {
    if (this.props.showingOperators) {
      return (
        <List.Item.Column alignRight>
          <span className={styles['status-items']}>
            <Icon
              type="user"
              withBackground
              backgroundSize={20}
              size={10}
              blue={this.props.user.login_credential}
            />
            <Icon
              type="lock"
              withBackground
              backgroundSize={20}
              size={10}
              red={this.props.user.pin_code_required}
            />
          </span>
        </List.Item.Column>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox={this.props.isAdmin}>
          <List.Item.TitleColumn loading />
          {this.props.showingOperators === false ? (
            <List.Item.Column width={350}>
              <div style={{ width: 100, height: 10 }}>
                <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
                  <rect x="0" y="0" rx="3" ry="3" width="100" height="8" />
                </ContentLoader>
              </div>
            </List.Item.Column>
          ) : null}
        </List.Item>
      );
    } else {
      return (
        <>
          <List.Item
            checked={this.props.checked}
            checkboxDisabled={this.props.checkboxDisabled}
            checkbox={this.props.isAdmin}
            onCheck={this.props.onCheck}
            dataPillsComponent={this.renderAssetOperators()}
            small
            onMouseEnter={() => {
              this.setState({ isHoveringListItem: true });
            }}
            onMouseLeave={() => this.setState({ isHoveringListItem: false })}
          >
            <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />
            {this.renderSCIMStatus()}
            {this.renderListItemRightComponent()}
            {this.renderOperatorIcons()}
            {this.renderPermission()}

            <List.Item.Column
              alignRight
              width={108}
              hide={this.state.isHoveringListItem === false && this.state.dropdownOpen === false}
            >
              {this.renderDropdownButton()}
              {this.renderDropdown()}
            </List.Item.Column>
          </List.Item>

          {this.renderUserInfoModal()}
          {this.renderUserDeleteModal()}
          {this.renderUserArchiveModal()}
          {this.renderUserPermissionModal()}
          {this.renderUserPinModal()}
          {this.renderUserLoginModal()}
          {this.renderResetPasswordModal()}
          {this.renderEditPinModal()}
          {this.renderResendInviteModal()}
          {this.renderSelectAssetModal()}
          {this.renderRemoveAsseFromOperatorModal()}
          {this.renderCantEditOrgAdminPermissionModal()}
          {this.renderRestoreUserFromArchiveModal()}
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettings: SDKReduxOperations.updateUserSettings,
      updateUser: SDKReduxOperations.updateUser,
      createAssetOperator: SDKReduxOperations.createAssetOperator,
      deleteAssetOperator: SDKReduxOperations.deleteAssetOperator,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading)
    return {
      isAdmin: AuthSelectors.isAdmin(state),
    };
  let permissionProfile = null;
  let assetOperators = [];
  const user = EntitySelectors.getUser(state, ownProps.id);
  const systemAccess = EntitySelectors.getSystemAccess(state, user.system_access);
  if (systemAccess) {
    permissionProfile = EntitySelectors.getPermissionProfile(state, systemAccess.permission_profile_id);
  }
  if (user.asset_operators) {
    assetOperators = EntitySelectors.getAssetOperators(state, user.asset_operators);
  }
  return {
    user,
    assetOperators,
    systemAccess,
    permissionProfile,
    isAdmin: AuthSelectors.isAdmin(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
    settings: AuthSelectors.getSettings(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListItem);
