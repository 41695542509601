import React from 'react';
import { connect } from 'react-redux';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { EntitySelectors } from 'sdk/State/entities';
import QrTemplateRow from './QrTemplateRow';
import styles from './style.module.scss';

function QrTemplateColumn(props) {
  const { setNodeRef } = useDroppable({
    id: props.id,
  });

  function getJustifyContentFromVerticalAlign() {
    switch (props.qrTemplateColumn.vertical_align) {
      case 'top':
        return 'flex-start';
      case 'bottom':
        return 'flex-end';
      default:
        return 'center';
    }
  }

  function getStylesFromQrTemplateColumn() {
    const widthInPercentage = props.width == null ? props.qrTemplateColumn.width : props.width;
    let newWidth = (widthInPercentage / 100) * props.qrTemplate.width;
    return {
      width: `${newWidth}mm`,
      maxWidth: `${newWidth}mm`,
      display: newWidth === 0 ? 'none' : 'block',
    };
  }

  function getPositioningStylesForPadding() {
    const { padding_bottom, padding_top, padding_left, padding_right } = props.qrTemplateColumn;
    return {
      position: 'absolute',
      top: `${padding_top}mm`,
      bottom: `${padding_bottom}mm`,
      left: `${padding_left}mm`,
      right: `${padding_right}mm`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: getJustifyContentFromVerticalAlign(),
    };
  }

  function renderRows() {
    return props.qrTemplateRows.map(qrTemplateRow => {
      const widthInPercentage = props.width == null ? props.qrTemplateColumn.width : props.width;
      let newWidth = (widthInPercentage / 100) * props.qrTemplate.width;
      if (qrTemplateRow.new) {
        return <QrTemplateRow placeholder columnWidth={newWidth} />;
      } else if (qrTemplateRow.loading) {
        return <QrTemplateRow loading columnWidth={newWidth} />;
      }

      return (
        <QrTemplateRow
          preview={props.preview}
          id={qrTemplateRow.id}
          key={qrTemplateRow.id}
          selectedTemplateRowId={props.selectedTemplateRowId}
          onSelect={id => props.onSelectRow(id)}
        />
      );
    });
  }

  function disableSortingStrategy() {
    return null;
  }

  let classNames = [styles['column']];
  if (props.selectedTemplateColumnId === props.id) {
    classNames = [...classNames, styles['selected']];
  }
  if (props.preview !== true) {
    classNames = [...classNames, styles['editing']];
    return (
      <SortableContext id={props.id} items={props.qrTemplateRows} strategy={disableSortingStrategy}>
        <div
          className={classNames.join(' ')}
          style={getStylesFromQrTemplateColumn()}
          title={props.id}
          onClick={() => props.onSelect(props.id)}
        >
          <div ref={setNodeRef} style={getPositioningStylesForPadding()}>
            {renderRows()}
          </div>
        </div>
      </SortableContext>
    );
  }
  return (
    <div className={classNames.join(' ')} style={getStylesFromQrTemplateColumn()}>
      <div style={getPositioningStylesForPadding()}>{renderRows()}</div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { id, rowIds } = ownProps;
  const qrTemplateColumn = EntitySelectors.getQrTemplateColumn(state, id);
  const qrTemplate = EntitySelectors.getQrTemplate(state, qrTemplateColumn.qr_template_id);
  return {
    qrTemplate,
    qrTemplateColumn,
    qrTemplateRows: rowIds.map(id => {
      if (state.entities.qrTemplateRowById[id] == null) {
        return {
          id,
          new: id !== 'loading',
          loading: id === 'loading',
        };
      }
      return state.entities.qrTemplateRowById[id];
    }),
  };
}

export default connect(mapStateToProps)(QrTemplateColumn);
