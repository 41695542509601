import { API } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistTemplateVersion } from 'sdk/Schemas';

const beginDrag = actions.beginDrag;
const endDrag = actions.endDrag;
const setSaveAsReusable = actions.setSaveAsReusable;
const setIsCreatingChecklistFromTemplatesLibrary = actions.setIsCreatingChecklistFromTemplatesLibrary;

const showSectionCantBeDroppedInModal = actions.showSectionCantBeDroppedInModal;
const hideSectionCantBeDroppedInModal = actions.hideSectionCantBeDroppedInModal;

const sortFieldInSection = actions.sortFieldInSection;
const sortFieldOutsideSection = actions.sortFieldOutsideSection;

const moveFieldFromRootToSection = actions.moveFieldFromRootToSection;
const moveFieldFromSectionToRoot = actions.moveFieldFromSectionToRoot;
const moveFieldsBetweenSections = actions.moveFieldsBetweenSections;

const addNewFieldToRoot = actions.addNewFieldToRoot;
const addNewFieldToSection = actions.addNewFieldToSection;

const prepareNewField = actions.prepareNewField;
const prepareNewFieldForEdit = actions.prepareNewFieldForEdit;

const editField = actions.editField;
const updateEditingField = actions.updateEditingField;
const cancelEditField = actions.cancelEditField;

const fetchChecklistTemplateVersion = id => dispatch => {
  dispatch(actions.fetchChecklistTemplateVersion());
  return API.fetchChecklistTemplateVersion(id)
    .then(res => {
      const { entities } = normalizeChecklistTemplateVersion(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      let rootFieldIds = [];
      let childIds = {};
      res.data.checklist_template_rows
        .sort((a, b) => {
          if (a.parent_row_id && b.parent_row_id == null) {
            return 1;
          } else if (a.parent_row_id == null && b.parent_row_id) {
            return -1;
          }
          return a.sort - b.sort;
        })
        .forEach(checklistTemplateRow => {
          if (checklistTemplateRow.parent_row_id == null) {
            rootFieldIds = [...rootFieldIds, checklistTemplateRow.id];
            childIds = {
              ...childIds,
              [checklistTemplateRow.id]: [],
            };
          } else {
            let parentId = checklistTemplateRow.parent_row_id;
            let id = checklistTemplateRow.id;
            if (childIds[parentId] == null) {
              childIds = {
                ...childIds,
                [parentId]: [id],
              };
            } else {
              childIds = {
                ...childIds,
                [parentId]: [...childIds[parentId], id],
              };
            }
          }
        });
      dispatch(
        actions.setEditingChecklistTemplateVersion({
          id,
          childIds,
          rootFieldIds,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

export default {
  setIsCreatingChecklistFromTemplatesLibrary,
  updateEditingField,
  setSaveAsReusable,
  prepareNewFieldForEdit,
  cancelEditField,
  addNewFieldToRoot,
  fetchChecklistTemplateVersion,
  sortFieldInSection,
  sortFieldOutsideSection,
  moveFieldFromRootToSection,
  beginDrag,
  endDrag,
  prepareNewField,
  moveFieldFromSectionToRoot,
  moveFieldsBetweenSections,
  editField,
  addNewFieldToSection,
  showSectionCantBeDroppedInModal,
  hideSectionCantBeDroppedInModal,
};
