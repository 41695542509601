import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragSource } from 'react-dnd';
import { isEqual } from 'lodash-es';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WorkOrderStatus, getMinutesFromElapsedMinutes, getHoursFromElapsedMinutes } from 'sdk/WorkOrder';
import { Icon } from 'views/components/Shared/General';
import { ProfilePicture } from 'views/components/User';
import { VendorAvatar } from 'views/components/Vendor';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const cardSource = {
  canDrag({ canAdministrateWorkOrders, workOrder, instance, intl }) {
    if (!canAdministrateWorkOrders) {
      return;
    }
    if (workOrder && workOrder.status === WorkOrderStatus.Completed) {
      toast(
        <ToastMessage
          error
          text={<FormattedMessage id="screens.calendar.errors.cant-drag-completed-work-order" />}
        />
      );
      return false;
    }
    return true;
  },
  beginDrag(props) {
    const { date, instance } = props;
    const {
      id,
      recurring_maintenance_date: recurringMaintenanceDate,
      work_order: workOrderId,
      recurring_maintenance: recurringMaintenanceId,
    } = instance;
    if (workOrderId) {
      props.beginDrag({
        workOrderId,
      });
      return {
        date,
        instanceId: id,
        workOrderId,
      };
    } else {
      props.beginDrag({
        recurringMaintenanceId,
      });
      return {
        date,
        instanceId: id,
        recurringMaintenanceDate,
        recurringMaintenanceId,
      };
    }
  },
  endDrag(props, monitor, component) {
    props.endDrag();
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class WorkOrder extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.draggingWorkOrderId, nextProps.draggingWorkOrderId) ||
      !isEqual(this.props.isDragging, nextProps.isDragging) ||
      !isEqual(this.props.instance, nextProps.instance)
    );
  }

  getClassNames = () => {
    let classNames = ['overlay-trigger', styles['work-order']];
    if (this.props.instance.type === 'work_order') {
      if (this.props.workOrder.status === WorkOrderStatus.Completed) {
        classNames = [...classNames, styles['completed']];
      } else if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
        classNames = [...classNames, styles['in-progress']];
      } else if (this.props.workOrder.status === WorkOrderStatus.Paused) {
        classNames = [...classNames, styles['paused']];
      }
    }
    return classNames;
  };

  renderGroup = group => {
    return (
      <div className={styles['group']}>
        {group.title
          .trim()
          .split(' ')
          .slice(0, 1)
          .map(v => v.charAt(0))
          .join('')}
      </div>
    );
  };

  renderContent = () => {
    let assignees = [];
    assignees = [
      ...assignees,
      ...this.props.assignedToVendors.map(vendor => (
        <div className={styles['assignee']}>
          <VendorAvatar vendor={vendor} size={14} fontSize={9} />
        </div>
      )),
    ];
    assignees = [
      ...assignees,
      ...this.props.assignedToGroups.map(group => (
        <div className={styles['assignee']}>{this.renderGroup(group)}</div>
      )),
    ];
    assignees = [
      ...assignees,
      ...this.props.assignedToUsers.map(user => (
        <div className={styles['assignee']}>
          <ProfilePicture userId={user.id} size={14} fontSize={9} singleInitital />
        </div>
      )),
    ];
    if (this.props.instance.type === 'work_order') {
      return (
        <>
          <div className={styles['title-container']}>
            {this.props.workOrder.recurring_maintenance_id ? <Icon regular type="repeat" /> : null}
            <div className={styles['title']}>{this.props.workOrder.title}</div>
            <div className={styles['estimated-time']}>{this.renderEstimatedTime()}</div>
          </div>
          {assignees.length === 0 ? (
            '-'
          ) : (
            <div className={styles['assignees']}>
              {assignees.slice(0, 5)}
              {assignees.length > 5 ? (
                <span className={styles['additional-assignees']}>+{assignees.length - 5}</span>
              ) : null}
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div className={styles['title-container']}>
          <Icon regular type="repeat" />
          <div className={styles['title']}>{this.props.recurringMaintenance.title}</div>
          <div className={styles['estimated-time']}>{this.renderEstimatedTime()}</div>
        </div>
        {assignees.length === 0 ? (
          '-'
        ) : (
          <div className={styles['assignees']}>
            {assignees.slice(0, 5)}
            {assignees.length > 5 ? (
              <span className={styles['additional-assignees']}>+{assignees.length - 5}</span>
            ) : null}
          </div>
        )}
      </>
    );
  };

  renderEstimatedTime = () => {
    let estimatedHrs;
    let estimatedMin;
    if (this.props.instance.type === 'work_order') {
      estimatedHrs = getHoursFromElapsedMinutes(this.props.workOrder.estimated_minutes);
      estimatedMin = getMinutesFromElapsedMinutes(this.props.workOrder.estimated_minutes);
    } else {
      estimatedHrs = getHoursFromElapsedMinutes(this.props.recurringMaintenance.estimated_minutes);
      estimatedMin = getMinutesFromElapsedMinutes(this.props.recurringMaintenance.estimated_minutes);
    }
    if (estimatedHrs === 0 && estimatedMin === 0) return null;
    return (
      <span>
        {estimatedHrs > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-hrs" values={{ value: estimatedHrs }} />
        ) : null}
        <span> </span>
        {estimatedMin > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-min" values={{ value: estimatedMin }} />
        ) : null}
      </span>
    );
  };

  render() {
    const { draggingWorkOrderId, workOrder } = this.props;
    let currentInstanceIsBeingDragged = false;
    if (draggingWorkOrderId && workOrder && workOrder.id === draggingWorkOrderId) {
      currentInstanceIsBeingDragged = true;
    }
    if (this.props.isDragging) {
      currentInstanceIsBeingDragged = true;
    }
    return this.props.connectDragSource(
      <div ref={ref => (this.positionToRef = ref)}>
        <div
          className={`${this.getClassNames().join(' ')} ${
            currentInstanceIsBeingDragged ? styles['is-dragging'] : ''
          }`}
          onClick={e => {
            e.stopPropagation();
            const { top, left, width } = this.positionToRef.getBoundingClientRect();
            const { id: instanceId, date: dateForClickedInstance } = this.props.instance;
            const { date: showingPreviewWorkOrdersForDate } = this.props.previewMoreWorkOrdersOverlayData;
            if (dateForClickedInstance !== showingPreviewWorkOrdersForDate) {
              this.props.hideMoreWorkOrdersPreviewOverlay();
            }
            this.props.showPreviewOverlay({ top, left: left - 6, width: width + 12, instanceId });
          }}
        >
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      beginDrag: AssetOperations.beginDrag,
      endDrag: AssetOperations.endDrag,
      showPreviewOverlay: AssetOperations.showPreviewOverlay,
      hideMoreWorkOrdersPreviewOverlay: AssetOperations.hideMoreWorkOrdersPreviewOverlay,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.instance.type === 'work_order') {
    return {
      workOrder: EntitySelectors.getWorkOrder(state, ownProps.instance.work_order),
      assignedToUsers: EntitySelectors.getUsers(state, ownProps.instance.users),
      assignedToGroups: EntitySelectors.getGroups(state, ownProps.instance.groups),
      assignedToVendors: EntitySelectors.getVendors(state, ownProps.instance.vendors),
      canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
      previewMoreWorkOrdersOverlayData: AssetSelectors.getPreviewMoreWorkOrdersOverlayData(state),
    };
  }
  return {
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(
      state,
      ownProps.instance.recurring_maintenance
    ),
    assignedToUsers: EntitySelectors.getUsers(state, ownProps.instance.users),
    assignedToGroups: EntitySelectors.getGroups(state, ownProps.instance.groups),
    assignedToVendors: EntitySelectors.getVendors(state, ownProps.instance.vendors),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    previewMoreWorkOrdersOverlayData: AssetSelectors.getPreviewMoreWorkOrdersOverlayData(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DragSource('month', cardSource, collect)(WorkOrder))
);
