import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class Attachments extends Component {
  renderImages = () => {
    if (this.props.images.length > 0) {
      let classNames = [styles['images']];
      if (this.props.clickable) {
        classNames = [...classNames, styles['clickable']];
      }
      if (this.props.imagesIsSelected) {
        classNames = [...classNames, styles['selected']];
      }

      return (
        <div
          className={classNames.join(' ')}
          onClick={() => {
            if (this.props.clickable) {
              this.props.onClickImages();
            }
          }}
        >
          <div className={styles['icon']}>
            <Icon size={12} type="image" />
          </div>
          <div className={styles['count']}>{this.props.images.length}</div>
        </div>
      );
    }
    return null;
  };

  renderAttachments = () => {
    if (this.props.attachments.length > 0) {
      let classNames = [styles['attachments']];
      if (this.props.clickable) {
        classNames = [...classNames, styles['clickable']];
      }
      if (this.props.attachmentsIsSelected) {
        classNames = [...classNames, styles['selected']];
      }
      return (
        <div
          className={classNames.join(' ')}
          onClick={() => {
            if (this.props.clickable) {
              this.props.onClickAttachments();
            }
          }}
        >
          <div className={styles['icon']}>
            <Icon size={12} type="file-alt" />
          </div>
          <div className={styles['count']}>{this.props.attachments.length}</div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { images, attachments } = this.props;
    if (images.length > 0 || attachments.length > 0) {
      return (
        <div className={styles['container']}>
          {this.renderImages()}
          {this.renderAttachments()}
        </div>
      );
    }
    return null;
  }
}

Attachments.propTypes = {
  clickable: PropTypes.bool,
  imagesIsSelected: PropTypes.bool,
  attachmentsIsSelected: PropTypes.bool,
  attachments: PropTypes.array,
  images: PropTypes.array,
  onClickImages: PropTypes.func,
  onClickAttachments: PropTypes.func,
};

Attachments.defaultProps = {
  clickable: false,
  imagesIsSelected: false,
  attachmentsIsSelected: false,
  attachments: [],
  images: [],
  onClickImages: () => {},
  onClickAttachments: () => {},
};
