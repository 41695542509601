import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions } from 'sdk';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, Pagination, WhiteCard } from 'views/components/Shared/General';
import { NewSelectAssetModal, NewAssetModal } from 'views/components/Asset';
import { Loader } from 'views/components/Shared/General';
import AssetSmall from 'assets/images/EmptyDataSet/AssetSmall.png';
import AssetVendorListItem from './AssetVendorListItem';
import AssetVendorModal from './AssetVendorModal';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import styles from './style.module.scss';
import { DeleteAssetVendorModal } from 'views/components/AssetVendor';

class AssetVendors extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      showAssetVendorModal: false,
      tableLoading: false,
      showAddAssetVendorModal: false,
      showCreateAssetModal: false,
      selectedAssetVendorId: null,
      assetId: null,
      viewInitialized: false,
      queryParams: queryParams,
      showDeleteAssetVendorModalForId: null,
      showDeleteAssetVendorModal: false,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchAssetVendors()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchAssetVendors()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchAssetVendors = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    return this.props
      .fetchAssetVendors(this.props.currentSystem.id, this.props.match.params.id, {
        ...params,
        ...filterParams,
      })
      .then(({ data: asset_vendors }) => {
        return asset_vendors;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  newAssetVendor = () => {
    this.setState({ showAssetVendorModal: true });
  };

  renderFetchMoreLoader = () => {
    if (!this.state.isLoadingMore) return null;

    return (
      <div className={styles['fetch-more-loader-container']}>
        <Loader small />
      </div>
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.vendor.assets.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.vendor.assets.empty-data-set.subtitle" />}
        image={AssetSmall}
        button={
          this.props.canEditVendors && this.props.canEditAssets ? (
            <Button
              small
              primary
              onClick={this.newAssetVendor}
              label="screens.vendor.assets.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => (
    <Toolbar
      buttons={
        this.props.canEditVendors && this.props.canEditAssets ? (
          <Button primary label="screens.vendor.assets.create-button" onClick={this.newAssetVendor} />
        ) : null
      }
    />
  );

  renderEmptySearch = () => {
    if (this.props.assetVendorIds.length > 0 || this.state.tableLoading) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          image={SearchImage}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.vendor.assets.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderAssetVendorModal = () => (
    <AssetVendorModal
      open={this.state.showAddAssetVendorModal}
      assetId={this.state.assetId}
      vendorId={this.props.match.params.id}
      selectedAssetVendorId={this.state.selectedAssetVendorId}
      onDelete={id =>
        setTimeout(
          () => this.setState({ showDeleteAssetVendorModalForId: id, showDeleteAssetVendorModal: true }),
          250
        )
      }
      onClose={status => {
        if (status === 'edit') {
          this.setState({
            showAddAssetVendorModal: false,
          });
          setTimeout(() => this.setState({ selectedAssetVendorId: null }), 100);
          return;
        } else
          this.setState({
            showAddAssetVendorModal: false,
          });
        setTimeout(() => {
          this.setState({ showAssetVendorModal: true, selectedAssetVendorId: null });
        }, 100);
        return;
      }}
    />
  );

  renderAssetVendorDeleteModal = () => (
    <DeleteAssetVendorModal
      open={this.state.showDeleteAssetVendorModal}
      id={this.state.showDeleteAssetVendorModalForId}
      title={<FormattedMessage id="screens.vendor.assets.asset-vendor-modal.confirm-delete.title" />}
      subtitle={<FormattedMessage id="screens.vendor.assets.asset-vendor-modal.confirm-delete.subtitle" />}
      onClose={() => this.setState({ showDeleteAssetVendorModal: false })}
    />
  );

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        open={this.state.showAssetVendorModal}
        listItemRightComponent={asset => {
          if (asset.exists_for_vendor) {
            return this.renderAlreadyAdded();
          } else return this.renderAddAssetButton(asset.id);
        }}
        additionalApiParams={{ exists_for_vendor: this.props.match.params.id }}
        onClose={() => this.setState({ showAssetVendorModal: false })}
        subtitle={
          <FormattedMessage
            id="screens.vendor.assets.asset-vendor-modal.vendor-name"
            values={{ vendor: <span className={styles['highlighted-text']}>{this.props.vendor.name}</span> }}
          />
        }
        onCreateAsset={() => {
          this.setState({ showAssetVendorModal: false });
          setTimeout(() => {
            this.setState({ showCreateAssetModal: true });
          }, 100);
        }}
      />
    );
  };

  renderCreateAssetModal = () => {
    return (
      <NewAssetModal
        createForVendorId={this.props.match.params.id}
        open={this.state.showCreateAssetModal}
        onClose={() => {
          this.setState({ showCreateAssetModal: false });
          setTimeout(() => {
            this.setState({ showAssetVendorModal: true });
          }, 100);
        }}
        onCreated={asset => {
          this.setState({ showCreateAssetModal: false }, () => {
            setTimeout(() => {
              this.setState({ showAssetVendorModal: true });
            }, 350);
          });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showCreateAssetModal: false }, () => {
            setTimeout(() => {
              this.setState({ showCreateAssetModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-asset']}>
        <FormattedMessage id="general.added" />
      </div>
    );
  };

  renderAddAssetButton = assetId => {
    return (
      <Button
        gray
        small
        label="general.add"
        onClick={() => {
          this.setState({ showAssetVendorModal: false });
          setTimeout(() => {
            this.setState({
              showAddAssetVendorModal: true,
              assetId,
              selectedAssetVendorId: null,
            });
          }, 100);
        }}
      />
    );
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.assetVendorIds.length === 0 ? 1 : this.props.assetVendorIds.length)
              .fill()
              .map(() => (
                <AssetVendorListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.assetVendorIds.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.assetVendorIds.map(id => (
            <AssetVendorListItem
              assetVendorId={id}
              onClick={() => {
                this.setState({ showAddAssetVendorModal: true, selectedAssetVendorId: id });
              }}
              onDelete={() =>
                this.setState({ showDeleteAssetVendorModalForId: id, showDeleteAssetVendorModal: true })
              }
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderAssetVendors = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderEmptySearch()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.assetVendorIds.length === 0 && !this.state.tableLoading) {
      return this.renderEmptyDataset();
    } else {
      return this.renderAssetVendors();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectAssetModal()}
        {this.renderAssetVendorModal()}
        {this.renderCreateAssetModal()}
        {this.renderAssetVendorDeleteModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAssetVendors: VendorOperations.fetchAssetVendors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: VendorSelectors.getVendor(state),
    assetVendorIds: VendorSelectors.getAssetVendorIds(state),
    asset: EntitySelectors.getAsset(state, ownProps.match.params.id),
    createdIds: VendorSelectors.getAssetVendorsCreatedIds(state),
    pagination: VendorSelectors.getAssetVendorsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetVendors));
