import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import HelperFunctions from 'utilities/HelperFunctions';
import { Button, Banner } from 'views/components/Shared/General';
import { Input, Button as LoginButton, Layout, RedirectAuthedUserToPrivateApp } from 'views/components/Login';
import styles from './style.module.scss';
import LogoSvg from 'assets/images/logo.svg';

class LoginDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: process.env.REACT_APP_USERNAME || '',
      password: process.env.REACT_APP_PASSWORD || '',
      showLoginError: false,
      isLoggingIn: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Login');
  }

  login = () => {
    this.setState({ isLoggingIn: true });
    const { email, password } = this.state;
    this.props
      .signIn({
        email,
        password,
      })
      .catch(() => {
        this.setState({ showLoginError: true, isLoggingIn: false });
      });
  };

  handleKeyPress = event => {
    const { password, email } = this.state;
    if (event.key === 'Enter' && email && password) {
      this.login();
    }
  };

  render() {
    if (this.props.isUserAuthenticated || this.props.isGroupAuthenticated) {
      return <RedirectAuthedUserToPrivateApp />;
    }
    return (
      <Layout>
        <div className={styles['title-container']}>
          <div className={styles['title']}>Welcome to Mainter</div>
          <div className={styles['subtitle']}>Login with your credentials</div>
        </div>
        <AnimateHeight duration={250} height={this.state.showLoginError ? 'auto' : 0}>
          <div className={styles['error-container']}>
            The username or password is not correct
          </div>
        </AnimateHeight>
        <div className={styles['input-container']}>
          <Input
            type="email"
            autoFocus
            placeholder="Email"
            onKeyPress={this.handleKeyPress}
            value={this.state.email}
            onChange={email => {
              this.setState({ email });
            }}
          />
          <Input
            type="password"
            placeholder="Password"
            onKeyPress={this.handleKeyPress}
            value={this.state.password}
            onChange={password => {
              if (this.state.showLoginError) {
                this.setState({ showLoginError: false });
              }
              this.setState({ password });
            }}
          />
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Login"
            translate={false}
            primary
            disabled={this.state.email.length === 0 || this.state.password.length === 0}
            loading={this.state.isLoggingIn}
            onClick={this.login}
          />
        </div>
        <div className={styles['separator']} />
        <div className={styles['additional-buttons']} >
          <Link to="/login/sso">
            <Button
              gray
              label="Login with SSO"
              translate={false}
            />
          </Link>
          <Link to="/forgot-password">
            <Button
              gray
              label="Forgot my password"
              translate={false}
            />
          </Link>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signIn: AuthOperations.signIn }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginDetail);
