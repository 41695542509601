import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import {
  Button,
  ViewTextArea,
  WhiteCard,
  List,
  FullScreenImagePicker,
  Icon,
  ConfirmModal,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { AttachmentListItem } from 'views/components/Attachment';
import { DowntimeModal } from 'views/components/Downtime';
import { Image } from 'views/components/Image';
import { StatusButtons, CompleteWorkOrderModal } from 'views/components/WorkOrder';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import PauseModal from './PauseModal';
import styles from './style.module.scss';

class StatusContainer extends Component {
  state = {
    isChangingStatus: false,
    changingToStatus: null,
    showCompletionModal: false,
    showConfirmManageActiveDowntime: false,
    showDowntimeModal: false,
    showDowntimeModalForDowntimeId: null,
    showPauseModal: false,
    showCompletedOptionsInlineModal: false,
    currentImage: 0,
    showingImageDialog: false,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderStatusAdditionalInfo = () => {
    switch (this.props.workOrder.status) {
      case WorkOrderStatus.Paused: {
        if (!this.props.workOrder.paused_comment) return null;
        return (
          <div className={styles['additional-info']}>
            <div className={styles['comment']}>
              <ViewTextArea>{this.props.workOrder.paused_comment}</ViewTextArea>
            </div>
          </div>
        );
      }
      case WorkOrderStatus.Completed: {
        const hasComment = this.props.workOrder.completed_comment != null;
        const hasNextComment = this.props.workOrder.completed_comment != null;
        const hasImages = this.props.completionImages && this.props.completionImages.length > 0;

        if (!hasComment && !hasNextComment && !hasImages) return null;

        return (
          <React.Fragment>
            <div className={styles['additional-info']}>
              <Grid>
                {!this.props.workOrder.completed_comment ? null : (
                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles['comment']}>
                        <ViewTextArea>{this.props.workOrder.completed_comment}</ViewTextArea>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {!this.props.workOrder.comment_for_next_work_order ? null : (
                  <Grid.Row>
                    <Grid.Column>
                      <p className={styles['comment-title']}>
                        <FormattedMessage id="resources.work-order.comment-for-next-work-order" />
                      </p>
                      <div className={styles['comment']}>
                        <ViewTextArea>{this.props.workOrder.comment_for_next_work_order}</ViewTextArea>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {this.renderImages()}
              </Grid>
            </div>
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  };

  changeStatus = status => {
    if (status === this.props.workOrder.status || this.state.isChangingStatus) return;
    this.updateWorkOrder(status);
  };

  updateWorkOrder = status => {
    this.setState({
      isChangingStatus: true,
      changingToStatus: status,
    });
    this.props
      .updateWorkOrder(this.props.workOrder.id, { status })
      .then(() => {
        this.setState({
          isChangingStatus: false,
          changingToStatus: null,
        });
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  renderStatusButtons = () => {
    if (this.props.workOrder.status === WorkOrderStatus.Completed) return null;

    return (
      <div className={styles['status-buttons']}>
        <StatusButtons
          loadingStatus={this.state.changingToStatus}
          selectedStatus={this.props.workOrder.status}
          disabled={this.props.canCarryOutWorkOrders === false}
          onStatusChanged={status => {
            switch (status) {
              case WorkOrderStatus.NotStarted:
                this.changeStatus(WorkOrderStatus.NotStarted);
                return;
              case WorkOrderStatus.InProgress:
                this.changeStatus(WorkOrderStatus.InProgress);
                return;
              case WorkOrderStatus.Paused:
                this.setState({ showPauseModal: true });
                return;
              case WorkOrderStatus.Completed:
                this.setState({ showCompletionModal: true });
                return;
              default:
                return;
            }
          }}
        />
      </div>
    );
  };

  renderCompletedAttachments = () => {
    if (
      this.props.workOrder.status !== WorkOrderStatus.Completed ||
      this.props.completionAttachments.length === 0
    )
      return null;

    return (
      <div className={styles['completed-attachments']}>
        <List>
          {this.props.completionAttachments
            .filter(attachment => attachment && attachment.type !== 'asset')
            .map(attachment => (
              <AttachmentListItem
                hideCreatedAt
                attachment={attachment}
                onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
              />
            ))}
        </List>
      </div>
    );
  };

  renderImages = () => {
    if (this.props.completionImages == null) {
      return null;
    }
    return this.props.completionImages.length === 0 ? null : (
      <Grid.Row>
        <Grid.Column>
          <div className={styles['images-container']}>
            {this.props.completionImages.map((image, index) => {
              return (
                <Image
                  medium
                  loading={false}
                  editable={false}
                  image={image}
                  onClick={() => {
                    this.selectImage(index);
                  }}
                />
              );
            })}
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderInProgressStatus = () => {
    return (
      <div className={[styles['status-title-container'], styles['in-progress']].join(' ')}>
        <div className={styles['icon-container']}>
          <Icon orange type="wrench" />
        </div>
        <div className={styles['info-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="resources.work-order.statuses.in-progress" />
          </p>
          <p className={styles['subtitle']}>
            <FormattedMessage
              id="general.timeago-by-user"
              values={{
                timeAgo: moment(this.props.workOrder.in_progress_at).fromNow(),
                user: <UserNameWrapper user={this.props.inProgressByUser} />,
              }}
            />
          </p>
        </div>
      </div>
    );
  };

  renderPausedStatus = () => {
    return (
      <div className={[styles['status-title-container'], styles['paused']].join(' ')}>
        <div className={styles['icon-container']}>
          <Icon type="pause" />
        </div>
        <div className={styles['info-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="resources.work-order.statuses.paused" />
          </p>
          <p className={styles['subtitle']}>
            <FormattedMessage
              id="general.date-by-user"
              values={{
                date: moment(this.props.workOrder.paused_at).format('LL'),
                user: <UserNameWrapper user={this.props.pausedByUser} />,
              }}
            />
          </p>
        </div>
        <div>
          {this.props.canCarryOutWorkOrders ? (
            <Button small label="general.edit" onClick={() => this.setState({ showPauseModal: true })} />
          ) : null}
        </div>
      </div>
    );
  };

  renderButtons = () => {
    if (!this.props.canCarryOutWorkOrders) {
      return null;
    }
    return (
      <Button.Group>
        <Button
          primary
          small
          label="general.edit"
          onClick={() =>
            this.setState({
              showCompletedOptionsInlineModal: false,
              showCompletionModal: true,
            })
          }
        />
        {!this.props.workOrder.completed_before_registration ? (
          <Button
            small
            loading={
              this.state.isChangingStatus && this.state.changingToStatus === WorkOrderStatus.InProgress
            }
            label="screens.work-order.resume"
            onClick={() => {
              this.setState({ showCompletedOptionsInlineModal: false });
              this.changeStatus(WorkOrderStatus.InProgress);
            }}
          />
        ) : null}
      </Button.Group>
    );
  };

  renderCompletedStatus = () => {
    return (
      <div className={`${styles['status-title-container']} ${styles['completed']}`}>
        <div className={styles['icon-container']}>
          <Icon regular green type="check" />
        </div>
        <div className={styles['info-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="resources.work-order.statuses.completed" />
          </p>
          <p className={styles['subtitle']}>
            <FormattedMessage
              id="general.date-by-user"
              values={{
                date: moment(this.props.workOrder.completed_date).format('LL'),
                user: <UserNameWrapper user={this.props.completedByUser} />,
              }}
            />
          </p>
        </div>
        {this.renderButtons()}
      </div>
    );
  };

  renderStatusTitle = () => {
    switch (this.props.workOrder.status) {
      case WorkOrderStatus.Completed:
        return this.renderCompletedStatus();
      case WorkOrderStatus.Paused:
        return this.renderPausedStatus();
      case WorkOrderStatus.InProgress:
        return this.renderInProgressStatus();
      default:
        return null;
    }
  };

  renderStatusInformation = () => {
    if (this.props.workOrder.status === WorkOrderStatus.NotStarted) return;
    return (
      <div className={styles['status-information']}>
        {this.renderStatusTitle()}
        {this.renderStatusAdditionalInfo()}
      </div>
    );
  };

  renderDowntimeModal = () => {
    return (
      <DowntimeModal
        open={this.state.showDowntimeModal}
        id={this.state.showDowntimeModalForDowntimeId}
        onClose={() => this.setState({ showDowntimeModalForDowntimeId: null, showDowntimeModal: false })}
      />
    );
  };

  renderConfirmCompleteWithActiveDowntime = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmManageActiveDowntime}
        title={<FormattedMessage id="screens.work-order.navigate-to-active-downtime-confirm.title" />}
        message={<FormattedMessage id="screens.work-order.navigate-to-active-downtime-confirm.subtitle" />}
        confirmButtonText="screens.work-order.navigate-to-active-downtime-confirm.manage"
        cancelButtonText="screens.work-order.navigate-to-active-downtime-confirm.close"
        onConfirm={() => {
          this.setState({ showDowntimeModal: true, showConfirmManageActiveDowntime: false });
        }}
        onCancel={() =>
          this.setState({
            showDowntimeModalForDowntimeId: null,
            showDowntimeModal: false,
            showConfirmManageActiveDowntime: false,
          })
        }
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <WhiteCard noPadding>
          <div className={styles['status-container']}>
            {this.renderStatusButtons()}
            {this.renderStatusInformation()}
          </div>
        </WhiteCard>
        {this.renderCompletedAttachments()}
        <PauseModal
          open={this.state.showPauseModal}
          workOrder={this.props.workOrder}
          onClose={() => this.setState({ showPauseModal: false })}
        />
        <CompleteWorkOrderModal
          open={this.state.showCompletionModal}
          recurringMaintenance={this.props.recurringMaintenance}
          workOrder={this.props.workOrder}
          onClose={() => this.setState({ showCompletionModal: false })}
          onCompleteWithActiveDowntimeId={id => {
            this.setState({
              showCompletionModal: false,
              showDowntimeModalForDowntimeId: id,
            });
            setTimeout(() => {
              this.setState({ showConfirmManageActiveDowntime: true });
            }, 250);
          }}
        />
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.completionImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        {this.renderConfirmCompleteWithActiveDowntime()}
        {this.renderDowntimeModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkOrder: SDKReduxOperations.updateWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;
  return {
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state, workOrder),
    inProgressByUser: EntitySelectors.getUser(state, workOrder.in_progress_by_user_id),
    pausedByUser: EntitySelectors.getUser(state, workOrder.paused_by_user_id),
    completedByUser: EntitySelectors.getUser(state, workOrder.completed_by_user_id),
    currentUser: AuthSelectors.getCurrentUser(state),
    completionAttachments: EntitySelectors.getAttachments(state, workOrder.completion_attachments),
    completionImages: EntitySelectors.getImages(state, ownProps.workOrder.completion_images),
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusContainer);
