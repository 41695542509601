import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual, debounce } from 'lodash-es';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import {
  Button,
  List,
  Pagination,
  FilterButton,
  NewInlineModal,
  Menu,
  Icon,
} from 'views/components/Shared/General';
import { PrintQRCodeModal } from 'views/components/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { NewSparePartModal } from 'views/components/SparePart';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { SparePartsSelectors, SparePartsOperations } from 'state/ducks/spareParts';
import { AuthSelectors } from 'state/ducks/auth';
import SparePartsImage from 'assets/images/EmptyDataSet/SpareParts.png';
import SparePartListItem from './SparePartListItem';
import FilterModal from './FilterModal';
import MassEditModal from './MassEditModal';
import ExportModal from './ExportModal';
import PrintModal from './PrintModal';
import { SparePartLocationMenu, SparePartTypeMenu, AssetMenu } from './Menus';
import styles from './style.module.scss';
import SortingButton from 'views/components/Shared/General/SortingButton';

const listSparePartsRequest = SDKHelperFunctions.getCancelTokenForRequest();

export const MenuType = {
  SparePartType: 'spare_part_type',
  SparePartLocation: 'spare_part_location',
  Asset: 'asset',
};

class SparePartList extends Component {
  constructor(props) {
    super(props);
    const { asset_with_tree_children_id, spare_part_location_with_children_id } = queryString.parse(
      props.location.search
    );
    let showMenu = localStorage.getItem('listMenu') || MenuType.SparePartType;
    if (asset_with_tree_children_id) {
      showMenu = MenuType.Asset;
    } else if (spare_part_location_with_children_id) {
      showMenu = MenuType.SparePartLocation;
    }
    this.state = {
      showNewModal: false,
      isViewLoading: true,
      isSearching: false,
      searchTerm: props.queryParameters.search || '',
      showFilterModal: false,
      showPrintQrCodeModal: false,
      lowStockQuantityCount: 0,
      showLowStockQuantityAlert: true,
      showMassEditModal: false,
      showExportModal: false,
      showPrintModal: false,
      showMoreOptionsForMultipleOptions: false,
      showMenu,
    };
    this.fetchDebouncedSpareParts = debounce(params => {
      this.fetchSpareParts(params);
    }, 300);
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.spare-parts.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.SpareParts);
    this.props.resetSelectedSpareParts();
    this.fetchDebouncedSpareParts();
    if (this.props.hasProTier) {
      this.loadLoadLowStockQuantityCount();
    }
  }

  componentDidUpdate(prevProps) {
    const { showFilterModal } = this.state;
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    const changedUrlParams = !isEqual(
      queryString.parse(prevProps.location.search),
      queryString.parse(this.props.location.search)
    );
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchDebouncedSpareParts();
      return;
    }
    if (changedUrlParams || changedQueryParams || (changedAdvancedFilters && showFilterModal === false)) {
      this.setState({ isSearching: true });
      this.props.resetSelectedSpareParts();
      this.fetchDebouncedSpareParts();
    }
  }

  fetchSpareParts = params => {
    listSparePartsRequest.cancel();
    const {
      spare_part_type_with_children_id,
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
    } = queryString.parse(this.props.location.search);
    let attrs = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...params,
    };
    if (spare_part_type_with_children_id) {
      attrs = {
        ...attrs,
        spare_part_type_with_children_id,
      };
    }
    if (spare_part_location_with_children_id) {
      attrs = {
        ...attrs,
        spare_part_location_with_children_id,
      };
    }

    if (asset_with_tree_children_id) {
      attrs = {
        ...attrs,
        spare_part_assets: {
          asset_with_tree_children_id,
        },
      };
    }

    return this.props
      .fetchSpareParts(this.props.selectedSystem.id, attrs, listSparePartsRequest.getCancelTokenConfig())
      .then(spareParts => {
        this.setState({ isSearching: false, isViewLoading: false });
        return spareParts;
      })
      .catch(error => {});
  };

  getSortOptions = () => {
    let sortOptions = [
      { label: <FormattedMessage id="resources.spare-part.title" />, sort: 'title' },
      { label: <FormattedMessage id="resources.spare-part.article-number" />, sort: 'article_number' },
      { label: <FormattedMessage id="resources.spare-part.category" />, sort: 'spare_part_type_title' },
    ];
    if (this.props.hasProTier) {
      sortOptions = [
        ...sortOptions,
        {
          label: <FormattedMessage id="resources.spare-part.in-stock" />,
          sort: 'stock_quantity',
        },
      ];
    }
    sortOptions = [
      ...sortOptions,
      { label: <FormattedMessage id="resources.spare-part.created-at" />, sort: 'created_at' },
    ];
    return sortOptions;
  };

  loadLoadLowStockQuantityCount = () => {
    API.fetchSparePartLowStockQuantityCount(this.props.selectedSystem.id).then(({ data: { count } }) => {
      this.setState({ lowStockQuantityCount: count });
    });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  setNavigatedTo = () => {
    const {
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        asset_with_tree_children_id,
        spare_part_location_with_children_id,
        spare_part_type_with_children_id,
      },
    });
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.search?.length > 0;
  };

  buildFilterParamsForQrCodeModal = () => {
    const {
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    } = queryString.parse(this.props.location.search);
    if (this.props.totalEntriesIsSelected) {
      return {
        ...{
          asset_with_tree_children_id,
          spare_part_location_with_children_id,
          spare_part_type_with_children_id,
        },
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.selectedSparePartIds).toString(),
      },
    };
  };

  renderNewSparePartModal = () => (
    <NewSparePartModal
      open={this.state.showNewModal}
      onClose={() => {
        this.setState({ showNewModal: false });
      }}
      onCreated={sparePart => {
        this.props.resetSelectedSpareParts();
        this.setState(
          {
            showNewModal: false,
          },
          () => {
            setTimeout(() => {
              this.props.history.push(`/spare-parts/${sparePart.id}`);
            }, 350);
          }
        );
      }}
      onCreatedWithReopen={sparePart => {
        this.props.resetSelectedSpareParts();
        this.setState(
          {
            showNewModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ showNewModal: true });
            }, 350);
          }
        );
      }}
    />
  );

  renderToolbar = () => (
    <ListLayout.Content.MainContent.FilterBar>
      <ListLayout.Content.MainContent.FilterBar.RightContent>
        <FilterButton.Group>
          <SortingButton
            searchRelevanceSearchTerm={this.state.searchTerm}
            sortOptions={this.getSortOptions()}
            sort={this.props.queryParameters.sort}
            sortOrder={this.props.queryParameters['sort-order']}
            onSort={params => this.addQueryParameter(params)}
          />
          {this.props.isFiltering ? (
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="general.clean" />}
              onClick={() => {
                this.setState({ isSearching: true });
                this.props.resetFilter();
              }}
            />
          ) : null}
          <FilterButton
            clearable={false}
            caret={false}
            filtered={this.props.isFiltering}
            label={<FormattedMessage id="screens.spare-parts.filter" />}
            onClick={() => this.setState({ showFilterModal: true })}
          />
        </FilterButton.Group>
      </ListLayout.Content.MainContent.FilterBar.RightContent>
    </ListLayout.Content.MainContent.FilterBar>
  );

  renderPagination = () => {
    if (this.props.spareParts.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isSearching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.spareParts.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedSpareParts()}
        />
      );
    }
    return null;
  };

  renderMoreOptionsForMultipleOptions = () => {
    if (this.props.canEditSpareParts) {
      return (
        <>
          <List.Header.MultipleOptions.Button
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() =>
              this.setState(prevState => ({
                showMoreOptionsForMultipleOptions: !prevState.showMoreOptionsForMultipleOptions,
              }))
            }
            caret
            label={<FormattedMessage id="screens.spare-parts.header-buttons.more-options" />}
          />
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.showMoreOptionsForMultipleOptions}
            position="right"
            onClose={() => this.setState({ showMoreOptionsForMultipleOptions: false })}
            minWidth={200}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({ showMoreOptionsForMultipleOptions: false, showPrintQrCodeModal: true });
                  }}
                >
                  <FormattedMessage id="screens.spare-parts.header-buttons.print-qr-codes" />
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showMoreOptionsForMultipleOptions: false,
                      showExportModal: true,
                    });
                  }}
                >
                  <FormattedMessage id="screens.spare-parts.header-buttons.export" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderListHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected } = this.props;
    const { isSearching } = this.state;
    return (
      <List.Header
        small
        background
        expandable
        checkbox
        checked={isSearching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isSearching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedSpareParts();
          } else {
            this.props.selectPage();
          }
        }}
        showMultipleOptions={this.props.selectedSparePartCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.isSearching}
            count={this.props.selectedSparePartCount}
            buttons={
              <>
                {this.props.canEditSpareParts ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.spare-parts.header-buttons.edit" />}
                    onClick={() => this.setState({ showMassEditModal: true })}
                  />
                ) : null}
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="general.print" />}
                  onClick={() =>
                    this.setState({
                      showPrintModal: true,
                    })
                  }
                />
                {this.renderMoreOptionsForMultipleOptions()}
              </>
            }
          />
        }
      >
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <>
        <List.Header.Column width={30} />
        <List.Header.Column width={70}>
          <FormattedMessage id="resources.spare-part.article-number-short" />
        </List.Header.Column>
        <List.Header.Column flex>
          <FormattedMessage id="resources.spare-part.title" />
        </List.Header.Column>
        <List.Header.Column width={200}>
          <FormattedMessage id="resources.spare-part.category" />
        </List.Header.Column>
        {this.props.hasProTier ? (
          <List.Header.Column width={150}>
            <FormattedMessage id="resources.spare-part.in-stock" />
          </List.Header.Column>
        ) : null}
      </>
    );
  };

  renderSparePartsList = () => (
    <>
      {this.renderListHeader()}
      <List>
        {this.renderSelectTotalEntries()}
        {this.props.spareParts.map(sparePart => {
          let checked = false;
          if (this.props.selectedSparePartIds[sparePart.id] === true) {
            checked = true;
          }
          if (this.props.totalEntriesIsSelected) {
            checked = true;
          }
          return (
            <SparePartListItem
              key={sparePart.id}
              sparePart={sparePart}
              checked={checked}
              checkboxDisabled={this.props.totalEntriesIsSelected}
              onClick={() => this.setNavigatedTo()}
              onCheck={e => {
                this.props.selectSparePart(sparePart.id);
              }}
            />
          );
        })}
      </List>
    </>
  );

  renderEmptyDataSetTitle = () => {
    const {
      spare_part_type_with_children_id,
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
    } = queryString.parse(this.props.location.search);
    if (spare_part_type_with_children_id) {
      return <FormattedMessage id="screens.spare-parts.empty-data-set-type-title" />;
    }
    if (asset_with_tree_children_id) {
      return <FormattedMessage id="screens.spare-parts.empty-data-set-asset-title" />;
    }
    if (spare_part_location_with_children_id) {
      return <FormattedMessage id="screens.spare-parts.empty-data-set-location-title" />;
    }
    return <FormattedMessage id="screens.spare-parts.empty-data-set-title" />;
  };

  renderSparePartsEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.spare-parts.empty-data-set-subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={SparePartsImage} alt="" />
      </div>
    </div>
  );

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={SparePartsImage} alt="" />
      </div>
    </div>
  );

  renderList = () => {
    if (this.state.isSearching) {
      const amountOfSpareParts = this.props.spareParts.length === 0 ? 1 : this.props.spareParts.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfSpareParts)
              .fill()
              .map(() => (
                <SparePartListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.spareParts.length === 0) {
      if (this.isFiltering()) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderSparePartsEmptyDataSet();
    }
    return this.renderSparePartsList();
  };

  renderShouldOrderContainer = () => {
    const { isViewOnly, hasProTier, canEditSpareParts, canEditPurchaseOrders } = this.props;
    const { lowStockQuantityCount } = this.state;
    const hasCorrectPermissions = isViewOnly || (canEditSpareParts && canEditPurchaseOrders);
    if (hasProTier && lowStockQuantityCount > 0 && hasCorrectPermissions) {
      const count = this.state.lowStockQuantityCount;

      return (
        <AnimateHeight
          duration={250}
          height={this.state.showLowStockQuantityAlert ? 'auto' : 0}
          style={{ flex: 'auto' }}
        >
          <div className={styles['should-order-container']}>
            <Link to="/spare-parts/should-order">
              <div className={styles['alert-container']}>
                <div className={styles['icon']}>
                  <Icon type="exclamation-triangle" />
                </div>
                <div className={styles['title']}>
                  <FormattedPlural
                    value={count}
                    zero={<FormattedMessage id="screens.spare-parts.should-order.zero" />}
                    one={<FormattedMessage id="screens.spare-parts.should-order.one" values={{ count }} />}
                    two={<FormattedMessage id="screens.spare-parts.should-order.two" values={{ count }} />}
                    few={<FormattedMessage id="screens.spare-parts.should-order.few" values={{ count }} />}
                    many={<FormattedMessage id="screens.spare-parts.should-order.many" values={{ count }} />}
                    other={
                      <FormattedMessage id="screens.spare-parts.should-order.other" values={{ count }} />
                    }
                  />
                  <span> </span>
                  <span className={styles['click-here']}>
                    <FormattedMessage id="general.click-here" />
                  </span>
                </div>
                <div className={styles['close-icon']}>
                  <Button
                    type="icon"
                    icon={<Icon light size={16} type="times" />}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ showLowStockQuantityAlert: false });
                    }}
                  />
                </div>
              </div>
            </Link>
          </div>
        </AnimateHeight>
      );
    }
  };

  renderMassEditModal = () => {
    return (
      <MassEditModal
        open={this.state.showMassEditModal}
        onSave={() => {
          this.props.resetSelectedSpareParts();
          this.setState({ isSearching: true, showMassEditModal: false });
          this.fetchDebouncedSpareParts();
        }}
        onClose={() => {
          this.setState({ showMassEditModal: false });
        }}
      />
    );
  };

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderPrintModal = () => {
    return (
      <PrintModal
        open={this.state.showPrintModal}
        onClose={() => {
          this.setState({ showPrintModal: false });
        }}
      />
    );
  };

  renderPrintQrCodeModal = () => {
    return (
      <PrintQRCodeModal
        templateType="sparePart"
        open={this.state.showPrintQrCodeModal}
        amountOfQrCodes={this.props.selectedSparePartCount}
        params={this.buildFilterParamsForQrCodeModal()}
        onClose={() => this.setState({ showPrintQrCodeModal: false })}
      />
    );
  };

  renderMainContent = () => {
    if (this.state.isViewLoading) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderListHeader()}
            <List>
              <SparePartListItem loading />
              <SparePartListItem loading />
            </List>
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>
          {this.renderShouldOrderContainer()}
          {this.renderList()}
        </ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.spare-parts.title" />}
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({ id: 'screens.spare-parts.search-placeholder' })}
        totalEntries={
          <FormattedMessage
            id="screens.spare-parts.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        onSearch={searchTerm => {
          this.setState({ searchTerm, isSearching: true });
          this.addQueryParameter({ search: searchTerm });
        }}
        onClearSearch={() => {
          this.setState({ isSearching: true, searchTerm: '' });
          this.addQueryParameter({ search: '' });
        }}
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.canEditSpareParts) {
      return (
        <>
          <Button
            fullWidth
            primary
            label="screens.spare-parts.create-button"
            onClick={() => {
              this.setState({ showNewModal: true });
            }}
          />
          <Menu.Separator />
        </>
      );
    }
    return null;
  };

  renderMenu = () => {
    if (this.state.showMenu === MenuType.SparePartType) {
      return <SparePartTypeMenu onChangeMenu={menu => this.setState({ showMenu: menu })} />;
    }
    if (this.state.showMenu === MenuType.Asset) {
      return <AssetMenu onChangeMenu={menu => this.setState({ showMenu: menu })} />;
    }
    if (this.state.showMenu === MenuType.SparePartLocation) {
      return <SparePartLocationMenu onChangeMenu={menu => this.setState({ showMenu: menu })} />;
    }
    return null;
  };

  renderLeftMenu = () => {
    const {
      spare_part_type_with_children_id,
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
    } = queryString.parse(this.props.location.search);
    let allSparePartsAreSelected = true;
    if (
      spare_part_type_with_children_id ||
      asset_with_tree_children_id ||
      spare_part_location_with_children_id
    ) {
      allSparePartsAreSelected = false;
    }
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-items']}>
            <ListLayout.Content.Menu.Content>
              {this.renderCreateButton()}
              <Menu.Item
                linkTo="/spare-parts"
                title={<FormattedMessage id="screens.spare-parts.left-panel.all-spare-parts" />}
                selected={allSparePartsAreSelected}
              />
            </ListLayout.Content.Menu.Content>
          </div>
          <div className={styles['more-items-container']}>{this.renderMenu()}</div>
        </div>
      </ListLayout.Content.Menu>
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.addQueryParameter({ page: 1 });
            this.props.resetSelectedSpareParts();
            this.setState({ isSearching: true });
            this.fetchDebouncedSpareParts(params);
            this.setState({ showFilterModal: false });
          }}
        />
        {this.renderNewSparePartModal()}
        {this.renderMassEditModal()}
        {this.renderExportModal()}
        {this.renderPrintModal()}
        {this.renderPrintQrCodeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSpareParts: SparePartsOperations.fetchSpareParts,
      selectMenuItem: MenuOperations.selectItem,
      addQueryParameter: SparePartsOperations.addQueryParameter,
      resetFilter: SparePartsOperations.resetFilter,
      resetSelectedSpareParts: SparePartsOperations.resetSelectedSpareParts,
      selectSparePart: SparePartsOperations.selectSparePart,
      selectTotalEntries: SparePartsOperations.selectTotalEntries,
      hideSelectTotalEntries: SparePartsOperations.hideSelectTotalEntries,
      selectPage: SparePartsOperations.selectPage,
      setNavigatedTo: SparePartsOperations.setNavigatedTo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    spareParts: SparePartsSelectors.getSpareParts(state),
    pagination: SparePartsSelectors.getSparePartsPagination(state),
    hasProTier: AuthSelectors.hasProTier(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    queryParameters: SparePartsSelectors.getQueryParameters(state),
    filters: SparePartsSelectors.getFilters(state),
    isFiltering: SparePartsSelectors.isFiltering(state),
    filtersAsQueryParams: SparePartsSelectors.getFiltersAsQueryParams(state),
    selectedSparePartIds: SparePartsSelectors.getSelectedSparePartIds(state),
    selectedSparePartCount: SparePartsSelectors.getSelectedSparePartCount(state),
    totalEntriesIsSelected: SparePartsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: SparePartsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: SparePartsSelectors.getPageIsSelected(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartList)));
