import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Field, Icon, SectionHeader } from 'views/components/Shared/General';
import { SelectMeterModal } from 'views/components/Meter';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class Meter extends Component {
  state = {
    showSelectMeterModal: false,
  };

  renderAddMeterModal = () => {
    return (
      <SelectMeterModal
        assetId={this.props.assetId}
        open={this.state.showSelectMeterModal}
        onClose={() => {
          this.setState({ showSelectMeterModal: false });
        }}
        onSelect={meter_id => {
          this.setState({ showSelectMeterModal: false });
          this.props.onSelectMeterForChecklistTemplateRow({
            meter_id,
            checklist_template_row_id: this.props.id,
          });
        }}
      />
    );
  };

  renderContent = () => {
    if (this.props.meter) {
      return (
        <Field label={<FormattedMessage id="components.checklist-options-modal.select-meter-button" />}>
          <div className={styles['selected-meter']}>
            <div className={styles['title-container']}>
              <div>{this.props.meter.title}</div>
              <div>
                <TreePath assetId={this.props.meter.asset_id} fullPath />
              </div>
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => this.setState({ showSelectMeterModal: true })}
              />
            </div>
          </div>
        </Field>
      );
    }
    return (
      <Button
        gray
        fullWidth
        label="components.checklist-options-modal.select-meter-button"
        onClick={() => {
          this.setState({ showSelectMeterModal: true });
        }}
      />
    );
  };

  render() {
    return (
      <div className={styles['meter-container']}>
        <SectionHeader paddingHorizontal={30} horizontalBorders>
          {this.props.checklistTemplateRow.title}
        </SectionHeader>
        <div className={styles['meter']}>{this.renderContent()}</div>
        {this.renderAddMeterModal()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id, meterIdsForChecklistTemplateIds } = ownProps;
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, id),
    meter: EntitySelectors.getMeter(state, meterIdsForChecklistTemplateIds[id]),
  };
}
export default connect(mapStateToProps)(Meter);
