import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';
import Tab from './Tab';

export default class Tabs extends Component {
  render() {
    return <div className={styles['container']}>{this.props.children}</div>;
  }
}

Tabs.Tab = Tab;
