const DOMAIN_PREFIX = 'operationalMaintenances/';

const TOGGLE_EXPAND_ASSET = `${DOMAIN_PREFIX}TOGGLE_EXPAND_ASSET`;
const FETCH_OPERATIONAL_MAINTENANCES_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_SUCCESS`;
const FETCH_OPERATIONAL_MAINTENANCES_LIST_COUNT_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_LIST_COUNT_SUCCESS`;
const SET_OPERATIONAL_MAINTENANCES_LIST_COUNT_LOADING = `${DOMAIN_PREFIX}SET_OPERATIONAL_MAINTENANCES_LIST_COUNT_LOADING`;
const FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SUCCESS`;
const FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SPLIT_BY_ASSETS_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SPLIT_BY_ASSETS_SUCCESS`;
const FETCH_OPERATIONAL_MAINTENANCES_BREAKS_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_BREAKS_SUCCESS`;
const SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN = `${DOMAIN_PREFIX}SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN`;
const SELECT_ASSET_ID_FOR_ASSET_DROPDOWN = `${DOMAIN_PREFIX}SELECT_ASSET_ID_FOR_ASSET_DROPDOWN`;
const SELECT_OPERATIONAL_MAINTENANCES_PAGE = `${DOMAIN_PREFIX}SELECT_OPERATIONAL_MAINTENANCES_PAGE`;
const SELECT_OPERATIONAL_MAINTENANCE = `${DOMAIN_PREFIX}SELECT_OPERATIONAL_MAINTENANCE`;
const RESET_SELECTED_OPERATIONAL_MAINTENANCES = `${DOMAIN_PREFIX}RESET_SELECTED_OPERATIONAL_MAINTENANCES`;
const FETCH_OPERATIONAL_MAINTENANCES_TEMPLATES_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_TEMPLATES_SUCCESS`;
const RESET_MAINTENANCES_TEMPLATES_STATE = `${DOMAIN_PREFIX}RESET_OPERATIONAL_MAINTENANCE_TEMPLATES_STATE`;
const ADD_QUERY_PARAMETER_FOR_TEMPLATES = `${DOMAIN_PREFIX}ADD_QUERY_PARAMETER_FOR_TEMPLATES`;
const ADD_QUERY_PARAMETER_FOR_OPERATIONAL_MAINTENANCES = `${DOMAIN_PREFIX}ADD_QUERY_PARAMETER_FOR_OPERATIONAL_MAINTENANCES`;
const SET_NAVIGATED_TO_OPERATIONAL_MAINTENANCE = `${DOMAIN_PREFIX}SET_NAVIGATED_TO_OPERATIONAL_MAINTENANCE`;
const UNITIALIZE_LIST_COUNT = `${DOMAIN_PREFIX}UNITIALIZE_LIST_COUNT`;

export default {
  FETCH_OPERATIONAL_MAINTENANCES_SUCCESS,
  FETCH_OPERATIONAL_MAINTENANCES_LIST_COUNT_SUCCESS,
  SET_OPERATIONAL_MAINTENANCES_LIST_COUNT_LOADING,
  FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SUCCESS,
  FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SPLIT_BY_ASSETS_SUCCESS,
  FETCH_OPERATIONAL_MAINTENANCES_TEMPLATES_SUCCESS,
  FETCH_OPERATIONAL_MAINTENANCES_BREAKS_SUCCESS,
  TOGGLE_EXPAND_ASSET,
  SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN,
  SELECT_ASSET_ID_FOR_ASSET_DROPDOWN,
  SELECT_OPERATIONAL_MAINTENANCES_PAGE,
  SELECT_OPERATIONAL_MAINTENANCE,
  RESET_SELECTED_OPERATIONAL_MAINTENANCES,
  ADD_QUERY_PARAMETER_FOR_TEMPLATES,
  ADD_QUERY_PARAMETER_FOR_OPERATIONAL_MAINTENANCES,
  SET_NAVIGATED_TO_OPERATIONAL_MAINTENANCE,
  UNITIALIZE_LIST_COUNT,
  RESET_MAINTENANCES_TEMPLATES_STATE,
};
