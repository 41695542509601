import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button, InlineModal, Icon } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class EditShippingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      hasUnsavedChanges: false,
      showDeleteInlineModal: false,
      editingShippingAddress: {},
      editingShippingAddressBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        isDeleting: false,
        hasUnsavedChanges: false,
        showDeleteInlineModal: false,
      });

      const { shippingAddress } = this.props;
      if (shippingAddress) {
        const editingShippingAddress = {
          name: shippingAddress.name,
          address1: shippingAddress.address1,
          address2: shippingAddress.address2,
          city: shippingAddress.city,
          zip: shippingAddress.zip,
          country: shippingAddress.country,
          default: shippingAddress.default,
        };
        this.setState({
          editingShippingAddress,
          editingShippingAddressBeforeEdit: editingShippingAddress,
        });
      } else {
        const editingShippingAddress = {
          name: '',
          address1: '',
          address2: '',
          city: '',
          zip: '',
          country: '',
          default: false,
        };
        this.setState({
          editingShippingAddress,
          editingWorkOrderBeforeEdit: editingShippingAddress,
          noNameError: false,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  invalidateShippingAddress = () => {
    let noNameError = !this.state.editingShippingAddress.name;
    this.setState({ noNameError });

    return noNameError;
  };

  save = () => {
    if (this.state.isSaving) return;
    if (this.invalidateShippingAddress() === true) {
      return;
    }

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createShippingAddress(this.props.system.id, this.state.editingShippingAddress)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.create-success" />}
          />
        );
        this.props.onClose();
        window.onbeforeunload = undefined;
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updateShippingAddress(this.props.id, this.state.editingShippingAddress)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props.deleteShippingAddress(this.props.id).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.delete-success" />}
        />
      );
      this.props.onClose();
    });
  };

  setValue = values => {
    const editingShippingAddress = {
      ...this.state.editingShippingAddress,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingShippingAddressBeforeEdit, editingShippingAddress);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingShippingAddress, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderDeleteContent = () => {
    if (this.props.id == null) return null;
    return (
      <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.delete}>
        <FormattedMessage id="general.delete-confirm.subtitle" />
      </InlineModal.Delete>
    );
  };

  renderDeleteInlineModal = () => {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>
          <Button
            type="icon"
            icon={<Icon regular red type="trash-alt" />}
            onClick={() =>
              this.setState(prevState => ({
                confirmDelete: false,
                showDeleteInlineModal: !prevState.showDeleteInlineModal,
              }))
            }
          />
        </div>
        <InlineModal
          open={this.state.showDeleteInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showDeleteInlineModal: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showDeleteInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderShippingAddress = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field
            view={false}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.name" />}
          >
            <Field.Text
              autoFocus
              value={this.state.editingShippingAddress.name}
              onKeyPress={this.handleKeyPress}
              onChange={name => {
                this.setValue({ name });
              }}
              error={this.state.noNameError}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            view={false}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.address1" />}
          >
            <Field.Text
              value={this.state.editingShippingAddress.address1}
              onKeyPress={this.handleKeyPress}
              onChange={address1 => {
                this.setValue({ address1 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            view={false}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.address2" />}
          >
            <Field.Text
              value={this.state.editingShippingAddress.address2}
              onChange={address2 => {
                this.setValue({ address2 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column md={4}>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-shipping-address.zip" />}>
            <Field.Text
              value={this.state.editingShippingAddress.zip}
              onChange={zip => {
                this.setValue({ zip });
              }}
            />
          </Field>
        </Grid.Column>
        <Grid.Column md={8}>
          <Field
            view={false}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.city" />}
          >
            <Field.Text
              value={this.state.editingShippingAddress.city}
              onChange={city => {
                this.setValue({ city });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field
            view={false}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.country" />}
          >
            <Field.Text
              value={this.state.editingShippingAddress.country}
              onChange={country => {
                this.setValue({ country });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.editingShippingAddress.default}
            onChange={value => {
              this.setValue({ default: value });
            }}
            label={<FormattedMessage id="resources.purchase-order-shipping-address.default" />}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.new-title" />
            )
          }
          iconButtons={this.props.id == null ? null : this.renderDeleteInlineModal()}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Grid>{this.renderShippingAddress()}</Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createShippingAddress: SDKReduxOperations.createPurchaseOrderShippingAddress,
      updateShippingAddress: SDKReduxOperations.updatePurchaseOrderShippingAddress,
      deleteShippingAddress: SDKReduxOperations.deletePurchaseOrderShippingAddress,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    shippingAddress: EntitySelectors.getPurchaseOrderShippingAddress(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditShippingAddressModal));
