import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Field = ({ icon, label, subtitle, value, small, error }) => {
  let classNames = [styles['icon-container']];

  const renderSubtitle = () => {
    if (subtitle) {
      return <div className={styles['subtitle']}>{subtitle}</div>;
    }
    return null;
  };
  const fieldClassNames = () => {
    let classNames = [styles['field']];
    if (small) {
      classNames = [...classNames, styles['small']];
    }
    if (error) {
      classNames = [...classNames, styles['error']];
    }
    return classNames;
  };
  return (
    <div className={fieldClassNames().join(' ')}>
      <div className={classNames.join(' ')}>{icon}</div>
      <div className={styles['content-container']}>
        <div className={styles['label']}>{label}</div>
        <div className={styles['value']}>{value}</div>
        {renderSubtitle()}
      </div>
    </div>
  );
};

export default Field;

Field.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.element,
  value: PropTypes.element,
  small: PropTypes.bool,
};

Field.defaultProps = {
  icon: null,
  label: null,
  value: null,
  small: false,
};
