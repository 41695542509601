import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { PathItem, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartLocationPath extends Component {
  buildSparePartLocationForSparePartLocationId = sparePartLocationId => {
    const sparePartLocation = this.props.allSparePartLocations.find(
      sparePartLocation => sparePartLocationId === sparePartLocation.id
    );
    if (sparePartLocation == null) return [];
    let sparePartLocations = [sparePartLocation];
    this.props.allSparePartLocations.forEach(loopedSparePartLocation => {
      if (sparePartLocation.spare_part_location_parent_id === loopedSparePartLocation.id) {
        sparePartLocations = [
          ...this.buildSparePartLocationForSparePartLocationId(loopedSparePartLocation.id),
          ...sparePartLocations,
        ];
      }
    });
    return sparePartLocations;
  };
  render() {
    const parentSparePartLocationId = this.props.parentSparePartLocationId;
    const sparePartLocationPath =
      this.buildSparePartLocationForSparePartLocationId(parentSparePartLocationId);
    return (
      <div className={styles['spare-part-location-path']}>
        <PathItem
          active={parentSparePartLocationId === 'root'}
          clickable={parentSparePartLocationId !== 'root'}
          onClick={() => this.props.onNavigate('root')}
        >
          <FormattedMessage id="screens.settings.spare-parts.storage.root-title" />
        </PathItem>
        {parentSparePartLocationId === 'root' ? null : (
          <React.Fragment>
            {sparePartLocationPath.map((sparePartLocation, index) => {
              if (index !== sparePartLocationPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem clickable onClick={() => this.props.onNavigate(sparePartLocation.id)}>
                      {sparePartLocation.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem active>{sparePartLocation.title}</PathItem>
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocations: EntitySelectors.getSparePartLocationsForLocation(
      state,
      ownProps.parentSparePartLocationId
    ),
    allSparePartLocations: EntitySelectors.getSparePartLocations(state),
  };
}

export default connect(mapStateToProps)(SparePartLocationPath);
