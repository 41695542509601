import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';

class QrTemplateColumnWidthSlider extends Component {
  state = {
    columnIdsToSave: {},
  };
  updateColumnWidth = (id, width) => {
    if (this.props.qrTemplateColumns.length === 1) {
      return;
    }
    const index = this.props.qrTemplateColumns.findIndex(({ id: loopedId }) => loopedId === id);
    let idForNextColumn = null;
    if (index === this.props.qrTemplateColumns.length - 1) {
      idForNextColumn = this.props.qrTemplateColumns[index - 1].id;
    } else {
      idForNextColumn = this.props.qrTemplateColumns[index + 1].id;
    }
    const widthAffectingTheNextColumn = this.props.widthsForColumns[id] - width;
    const newWidthForTheNextColumn =
      this.props.widthsForColumns[idForNextColumn] + widthAffectingTheNextColumn;
    if (newWidthForTheNextColumn < 0 || newWidthForTheNextColumn > 100 || width < 0 || width > 100) {
      return;
    }
    this.setState({
      columnIdsToSave: {
        [id]: width,
        [idForNextColumn]: newWidthForTheNextColumn,
      },
    });
    this.props.onChangeColumnWidths({
      [id]: width,
      [idForNextColumn]: newWidthForTheNextColumn,
    });
  };

  renderColumns = () => {
    return this.props.qrTemplateColumns
      .filter(({ id }) => {
        return this.props.qrTemplateColumnId ? id === this.props.qrTemplateColumnId : true;
      })
      .map(({ id }, i) => {
        return (
          <div className={styles['slider-container']}>
            <div className={styles['column']}>
              <span>
                <FormattedMessage id="components.print-qr-codes.edit-template-tab.column" />
                <span> </span>
                <span>{i + 1}</span>
              </span>
            </div>
            <div className={styles['slider']}>
              <Slider
                trackStyle={{ backgroundColor: 'lightblue', height: '6px' }}
                railStyle={{ backgroundColor: 'lightgray', height: '6px' }}
                handleStyle={{
                  backgroundColor: '#4c9ac8',
                  borderColor: '#4c9ac8',
                  opacity: 1,
                }}
                value={this.props.widthsForColumns[id]}
                onChange={width => {
                  this.updateColumnWidth(id, width);
                }}
                onAfterChange={() => {
                  if (Object.keys(this.state.columnIdsToSave).length === 0) {
                    return;
                  }
                  this.props.onShowSaveContainer();

                  let promises = Object.keys(this.state.columnIdsToSave).map(id => {
                    return this.props.updateQrTemplateColumn(id, {
                      width: this.state.columnIdsToSave[id],
                    });
                  });
                  Promise.all(promises).then(() => {
                    this.setState({ columnIdsToSave: {} });
                    this.props.onHideSaveContainer();
                  });
                }}
              />
            </div>
            <div>
              <span>{this.props.widthsForColumns[id]}</span>
              <span>%</span>
            </div>
          </div>
        );
      });
  };

  render() {
    return <>{this.renderColumns()}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplateColumn: SDKReduxOperations.updateQrTemplateColumn,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateId, qrTemplateColumnId } = ownProps;
  const qrTemplate = EntitySelectors.getQrTemplate(state, qrTemplateId);
  return {
    qrTemplate,
    qrTemplateColumns: EntitySelectors.getQrTemplateColumns(state, qrTemplate.qr_template_columns),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrTemplateColumnWidthSlider);
