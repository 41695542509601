import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { RequestsSelectors } from 'state/ducks/requests';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class SelectedAssignee extends Component {
  render() {
    const { assigned_to_me, assigned_to_user_id, assigned_to_group_id } = this.props.queryParameters;
    const { exists: userExists } = assigned_to_user_id || {};
    const { exists: groupExists } = assigned_to_group_id || {};
    const { user, group } = this.props;
    if (assigned_to_me === true) {
      return (
        <FormattedMessage id="screens.requests.filter-assignee-dropdown.selected-assignee.assigned-to-me" />
      );
    }
    if (userExists === false && groupExists === false) {
      return (
        <FormattedMessage id="screens.requests.filter-assignee-dropdown.selected-assignee.no-assignee" />
      );
    }
    if (user) {
      return <UserNameWrapper user={this.props.user} />;
    }
    if (group) {
      return this.props.group.title;
    }
    return <FormattedMessage id="screens.requests.filter-assignee-dropdown.selected-assignee.show-all" />;
  }
}

function mapStateToProps(state, ownProps) {
  const queryParameters = RequestsSelectors.getQueryParameters(state);
  const { assigned_to_group_id, assigned_to_user_id } = queryParameters;
  return {
    user: EntitySelectors.getUser(state, assigned_to_user_id),
    group: EntitySelectors.getGroup(state, assigned_to_group_id),
    queryParameters,
  };
}

export default withRouter(connect(mapStateToProps)(SelectedAssignee));
