/*
  --------------------
    DATA SELECTORS
  --------------------
*/

/*
  Overview Tab
*/

const getOverviewOperationalMaintenanceIds = state => {
  const { productionBoard } = state;
  return productionBoard.operationalMaintenanceIds;
};

const getOpenRequestIds = state => {
  const { productionBoard } = state;
  return productionBoard.openRequestIds;
};
const getOpenRequestsCount = state => {
  const { productionBoard } = state;
  return productionBoard.openRequestsCount;
};

const getProductionBoardActivityIds = state => {
  const { productionBoard } = state;
  return productionBoard.productionBoardActivityIds;
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const getProductionBoardActivityDate = state => {
  const { productionBoard } = state;
  return productionBoard.productionBoardActivityDate;
};

const getOperationalMaintenancesDate = state => {
  const { productionBoard } = state;
  return productionBoard.operationalMaintenancesDate;
};

const isRefreshing = state => {
  const { productionBoard } = state;
  return productionBoard.isRefreshing;
};

export default {
  getOpenRequestIds,
  getOpenRequestsCount,
  getOverviewOperationalMaintenanceIds,
  getProductionBoardActivityIds,
  getOperationalMaintenancesDate,
  getProductionBoardActivityDate,
  isRefreshing,
};
