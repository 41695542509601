import React from 'react';
import {
  KeyboardSensor,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export default function DndContextContainer(props) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <DndContext
      autoScroll={{ layoutShiftCompensation: false }}
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={props.onDragStart}
      onDragOver={props.onDragOver}
      onDragEnd={props.onDragEnd}
      onDragCancel={props.onDragCancel}
    >
      {props.children}
    </DndContext>
  );
}
