import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, Button, Icon } from 'views/components/Shared/General';
import SparePartMicro from 'assets/images/EmptyDataSet/SparePartMicro.png';
import { UpgradeToProIcon } from 'views/components/Shared/General';
import styles from './styles.module.scss';

export default class UpgradeToPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideUpgradeCard: localStorage.getItem('settings_spare_part_hide_upgrade_card'),
    };
  }

  hideUpgradeCard = () => {
    this.setState({ hideUpgradeCard: true });
    localStorage.setItem('settings_spare_part_hide_upgrade_card', true);
  };

  render() {
    return (
      <AnimateHeight duration={150} height={this.state.hideUpgradeCard ? 0 : 'auto'}>
        <WhiteCard
          padding="38px 50px"
          headerButtons={
            <div className={styles['close-container']}>
              <div className={styles['badge']}>
                <UpgradeToProIcon />
              </div>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={this.hideUpgradeCard}
              />
            </div>
          }
        >
          <div className={styles['basic-container']}>
            <div className={styles['image-container']}>
              <img src={SparePartMicro} alt="" />
            </div>
            <div className={styles['content']}>
              <p className={styles['title']}>
                <FormattedMessage id="screens.spare-part.info.order-information.basic-empty-data-set.title" />
              </p>
              <p className={styles['subtitle']}>
                <FormattedMessage id="screens.spare-part.info.order-information.basic-empty-data-set.subtitle" />
              </p>
              <div className={styles['upgrade-buttons-container']}>
                <Button small upgradeToPro label="general.upgrade-to-pro" />
              </div>
            </div>
          </div>
        </WhiteCard>
      </AnimateHeight>
    );
  }
}
