import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import AnimateHeight from 'react-animate-height';
import { AuthSelectors } from 'state/ducks/auth';
import { StocktakingOperations, StocktakingSelectors } from 'state/ducks/stocktaking';
import { Button, ViewTextArea, WhiteCard, Icon } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';

class CompletedWhiteCard extends Component {
  renderComment = () => {
    if (!this.props.stocktaking.completed_comment) return null;
    return (
      <div className={styles['comment-container']}>
        <ViewTextArea>{this.props.stocktaking.completed_comment}</ViewTextArea>
      </div>
    );
  };

  render() {
    return (
      <AnimateHeight
        duration={250}
        height={this.props.isCompletedCardHidden ? 0 : 'auto'}
        style={{ flex: 'auto' }}
      >
        <div className={styles['white-card-container']}>
          <WhiteCard
            noPadding
            headerButtons={
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={() => {
                  this.props.hideCompletedCard();
                }}
              />
            }
          >
            <div className={styles['container']}>
              <div className={styles['icon-container']}>
                <Icon green regular type="check" />
              </div>
              <div>
                <div className={styles['title-container']}>
                  <p className={styles['title']}>
                    <FormattedMessage id="screens.stocktaking-detail.completed-title" />
                  </p>
                  <p className={styles['subtitle']}>
                    <FormattedMessage
                      id="general.date-by-user"
                      values={{
                        date: moment(this.props.stocktaking.completed_at).format('LLL'),
                        user: <UserNameWrapper user={this.props.completedByUser} />,
                      }}
                    />
                  </p>
                </div>
                {this.renderComment()}
              </div>
            </div>
          </WhiteCard>
        </div>
      </AnimateHeight>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideCompletedCard: StocktakingOperations.hideCompletedCard,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const stocktaking = EntitySelectors.getStocktaking(state, ownProps.match.params.id);

  return {
    stocktaking,
    completedByUser: EntitySelectors.getUser(state, stocktaking.completed_by_user_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isCompletedCardHidden: StocktakingSelectors.getHideCompletedCard(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompletedWhiteCard));
