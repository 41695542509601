import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { Button } from 'views/components/Shared/General';
import { ChecklistTemplateOperations } from 'state/ducks/checklistTemplate';
import styles from './style.module.scss';

class InfoText extends Component {
  renderShowDraftText = () => {
    const { checklistTemplateVersion } = this.props;
    return (
      <div className={styles['text-container']}>
        <div className={styles['text']}>
          <FormattedMessage
            id="screens.checklist.unsaved-changes-text"
            values={{ date: moment(checklistTemplateVersion.created_at).format('LL') }}
          />
        </div>
        <div>
          <Button
            type="text"
            label="screens.checklist.show-changes"
            onClick={this.props.onEditDraftTemplateVersion}
          />
        </div>
      </div>
    );
  };

  renderIsArchivedText = () => {
    const { checklistTemplate } = this.props;
    return (
      <div className={styles['text-container']}>
        <div className={styles['text']}>
          <FormattedMessage id="screens.checklist.showing-old-version" />
        </div>
        <div>
          <Button
            label={'screens.checklist.show-current-version'}
            type="text"
            onClick={() => {
              this.props.fetchChecklistTemplateVersion(checklistTemplate.active_version);
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const { checklistTemplateVersion } = this.props;
    if (checklistTemplateVersion.status === Status.Archived) {
      return this.renderIsArchivedText();
    }
    if (checklistTemplateVersion.status === Status.Draft) {
      return this.renderShowDraftText();
    }

    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChecklistTemplateVersion: ChecklistTemplateOperations.fetchChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
    state,
    ownProps.templateVersionId
  );
  return {
    checklistTemplateVersion,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoText));
