import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class ListedRequestTypeTitles extends Component {
  render() {
    if (this.props.requestTypes.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.props.requestTypes.reduce(
          (accu, requestType) =>
            accu === null
              ? [
                  <div className={styles['selected-item']}>
                    {requestType ? requestType.title : 'not existing cus of socket'}
                  </div>,
                ]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>
                    {requestType ? requestType.title : 'not existing cus of socket'}
                  </div>,
                ],
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    requestTypes: EntitySelectors.getRequestTypes(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(ListedRequestTypeTitles);
