import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InlineModal } from 'views/components/Shared/General';
import Delete from './Delete';
import MoveSparePartLocation from './MoveSparePartLocation';

const DROPDOWN_OPTIONS = {
  DropdownItems: 'dropdownItems',
  DeleteSparePartLocation: 'deleteSparePartLocation',
  MoveSparePartLocation: 'moveSparePartLocation',
};

class SparePartLocationsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: DROPDOWN_OPTIONS.DropdownItems,
    };
  }

  renderOptions = () => (
    <InlineModal.Body width={250} dropdown>
      <InlineModal.ListItem
        icon="pen"
        iconThickness="regular"
        title={<FormattedMessage id="components.move-spare-part-location-inline-modal.change-name" />}
        onClick={() => {
          this.setState({ isOpen: false });
          this.props.onEditSparePartLocation(this.props.sparePartLocation);
        }}
      />
      <InlineModal.ListItem
        icon="arrow-to-right"
        iconThickness="regular"
        title={
          <FormattedMessage id="components.move-spare-part-location-inline-modal.move-spare-part-location.title" />
        }
        onClick={() => {
          this.setState({ selectedOption: DROPDOWN_OPTIONS.MoveSparePartLocation });
        }}
      />
      <InlineModal.Separator />
      <InlineModal.ListItem
        icon="trash-alt"
        iconThickness="regular"
        iconColor="#C55050"
        destructive
        title={<FormattedMessage id="general.delete" />}
        onClick={() => {
          this.setState({
            selectedOption: DROPDOWN_OPTIONS.DeleteSparePartLocation,
          });
        }}
      />
    </InlineModal.Body>
  );

  renderDelete = () => (
    <Delete
      sparePartLocation={this.props.sparePartLocation}
      onDeleteSparePartLocation={() => this.setState({ isOpen: false })}
      onClose={() => this.setState({ isOpen: false })}
    />
  );

  renderMoveSparePartLocation = () => {
    return (
      <MoveSparePartLocation
        sparePartLocation={this.props.sparePartLocation}
        loading={this.state.isChangingFolder}
        onClose={() => this.setState({ isOpen: false })}
        onSizeWasChanged={() => this.inlineModalRef.setInlineModalHorizontalPositions()}
      />
    );
  };

  renderContent = () => {
    switch (this.state.selectedOption) {
      case DROPDOWN_OPTIONS.DropdownItems:
        return this.renderOptions();
      case DROPDOWN_OPTIONS.DeleteSparePartLocation:
        return this.renderDelete();
      case DROPDOWN_OPTIONS.MoveSparePartLocation:
        return this.renderMoveSparePartLocation();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.stopPropagation();
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              selectedOption: DROPDOWN_OPTIONS.DropdownItems,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          ref={ref => (this.inlineModalRef = ref)}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          <React.Fragment>{this.renderContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}

export default injectIntl(SparePartLocationsInlineModal);
