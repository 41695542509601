import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash-es';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { ChecklistTemplateOperations, ChecklistTemplateSelectors } from 'state/ducks/checklistTemplate';
import { MenuOperations } from 'state/ducks/menu';
import { MenuUtils } from 'state/ducks/menu';
import { List, Pagination, WhiteCard, EmptyDataSet, FilterButton } from 'views/components/Shared/General';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import queryString from 'query-string';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import ChecklistInstanceListItem from './ChecklistInstanceListItem';
import { ContentContainer } from 'views/components/Shared/Layout';
import { ChecklistModal } from 'views/components/Checklist';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class ChecklistInstance extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      notFound: false,
      isLoading: false,
      isFetchingDataForView: false,
      queryParams,
      showChecklistInstanceModal: false,
      showChecklistInstanceModalForId: null,
    };
  }
  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Checklists);
    this.setState({ isFetchingDataForView: true });
    this.fetchChecklistInstances()
      .then(() => {
        this.setState({ isFetchingDataForView: false });
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);

    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ isLoading: true });
        this.fetchChecklistInstances()
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(e => {
            this.setState({ notFound: e.response && e.response.status === 404 });
          });
      });
    }
  }

  getParamsFromQueryParams = () => {
    const { page, asset_with_tree_children_id } = this.state.queryParams;
    let queryParams = { page, checklist_template_id: this.props.match.params.id };
    if (asset_with_tree_children_id) {
      queryParams = {
        ...queryParams,
        work_order: {
          asset_with_tree_children_id,
        },
      };
    }
    return queryParams;
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        page: null,
        ...obj,
      })}`
    );
  };

  fetchChecklistInstances = () => {
    return this.props.fetchChecklistInstances(this.props.system.id, this.getParamsFromQueryParams());
  };

  fetchChecklistInstance = () => {
    API.fetchChecklistInstance(this.state.showChecklistInstanceModalForId).then(
      ({ data: checklistInstance }) => {
        const { entities } = normalizeChecklistInstance(checklistInstance);
        this.props.updateEntities(entities);
      }
    );
  };

  renderInitialLoaders = () => {
    return (
      <List>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.checklist.instances.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderEmptyList = () => {
    if (this.state.queryParams.asset_with_tree_children_id) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="screens.checklist.instances.empty-data-set.title" />}
          subtitle={<FormattedMessage id="screens.checklist.instances.empty-data-set.subtitle" />}
          tiny
          listContainer
        />
      </WhiteCard>
    );
  };

  renderInstances = () => {
    return this.props.checklistInstanceIds.map(id => {
      return (
        <ChecklistInstanceListItem
          id={id}
          onClick={() =>
            this.setState({
              showChecklistInstanceModal: true,
              showChecklistInstanceModalForId: id,
            })
          }
        />
      );
    });
  };

  renderLoaders = () => {
    let amountOfItems = 2;
    if (this.props.checklistInstanceIds.length > 2) {
      amountOfItems = this.props.checklistInstanceIds.length;
    }
    return Array(amountOfItems)
      .fill()
      .map(() => {
        return (
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        );
      });
  };

  renderTitle = () => {
    return (
      <List.Header background>
        <List.Header.Column flex>{this.props.checklistTemplate.title}</List.Header.Column>
      </List.Header>
    );
  };

  renderTopBar = () => {
    return (
      <div className={styles['toolbar']}>
        <ChooseAssetInlineModal
          width="300"
          trigger={
            <FilterButton
              label={<FormattedMessage id="resources.asset.resource" />}
              filtered={this.state.queryParams.asset_with_tree_children_id != null}
              onClear={e => {
                e.stopPropagation();
                this.changeQueryParams({ asset_with_tree_children_id: null });
              }}
            />
          }
          selectedAssetId={this.state.queryParams.asset_with_tree_children_id}
          onSelectAsset={assetId => {
            this.changeQueryParams({ asset_with_tree_children_id: assetId });
          }}
          onClear={() => {
            this.changeQueryParams({ asset_with_tree_children_id: null });
          }}
        />
      </div>
    );
  };

  renderContent = () => {
    if (this.state.isFetchingDataForView) {
      return (
        <>
          {this.renderTitle()}
          {this.renderInitialLoaders()}
        </>
      );
    }
    if (this.state.isLoading) {
      return (
        <>
          {this.renderTitle()}
          <List>{this.renderLoaders()}</List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.props.checklistInstanceIds.length === 0) {
      return this.renderEmptyList();
    }
    return (
      <>
        {this.renderTitle()}
        <List>{this.renderInstances()}</List>
        {this.renderPagination()}
      </>
    );
  };

  render() {
    return (
      <>
        <ContentContainer>
          {this.renderTopBar()}
          {this.renderContent()}
        </ContentContainer>
        <ChecklistModal
          open={this.state.showChecklistInstanceModal}
          checklistInstanceId={this.state.showChecklistInstanceModalForId}
          editable={false}
          onClose={() => {
            this.setState({ showChecklistInstanceModal: false });
            this.fetchChecklistInstance();
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChecklistInstances: ChecklistTemplateOperations.fetchChecklistInstances,
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateId = ownProps.match.params.id;
  const checklistInstanceIds = ChecklistTemplateSelectors.getChecklistInstances(state);
  return {
    system: AuthSelectors.getCurrentSystem(state),
    pagination: ChecklistTemplateSelectors.getChecklistInstancePagination(state),
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, checklistTemplateId),
    checklistInstanceIds,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChecklistInstance)));
