import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SectionHeader } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { EditOperationalMaintenanceTemplateSelectors } from 'state/ducks/editOperationalMaintenanceTemplate';
import SparePartReservationListItem from './SparePartReservationListItem';

class ReservedSpareParts extends Component {
  render() {
    if (this.props.sparePartReservations.length === 0) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <SectionHeader paddingHorizontal={30} horizontalBorders>
            <FormattedMessage id="resources.operational-maintenance-template-version.reserved-spare-parts" />
          </SectionHeader>
          {this.props.sparePartReservations.map(sparePartReservation => (
            <SparePartReservationListItem
              sparePartReservation={sparePartReservation}
              key={sparePartReservation.spare_part_id}
            />
          ))}
        </CardGrid.Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sparePartReservations: EditOperationalMaintenanceTemplateSelectors.getReservedSpareParts(state),
  };
}

export default connect(mapStateToProps)(ReservedSpareParts);
