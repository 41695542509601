import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class SelectAssetButton extends PureComponent {
  render() {
    if (this.props.asset) {
      return (
        <div className={styles['button']} onClick={this.props.onClick}>
          <FormattedMessage id="general.choose-value" values={{ value: this.props.asset.title }} />
        </div>
      );
    }
    return (
      <div className={`${styles['button']} ${styles['disabled']}`}>
        <FormattedMessage id="general.choose" />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps)(SelectAssetButton);
