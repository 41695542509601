import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import UserContainer from './UserContainer';
import VendorContainer from './VendorContainer';
import GroupContainer from './GroupContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Assignee extends Component {
  renderSelectedValue = () => {
    const comparator = Object.keys(this.props.filters)[0];
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }

    const value = this.props.filters[comparator];
    const { userIds, groupIds, vendorIds } = value;
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={[
          ...userIds.map(id => ({ id, type: 'user' })),
          ...vendorIds.map(id => ({ id, type: 'vendor' })),
          ...groupIds.map(id => ({ id, type: 'group' })),
        ]}
        renderItem={({ type, id }) => {
          if (type === 'user') {
            return <UserContainer id={id} />;
          }
          if (type === 'group') {
            return <GroupContainer id={id} />;
          }
          return <VendorContainer id={id} />;
        }}
      />
    );
  };

  render() {
    const comparator = Object.keys(this.props.filters)[0];
    const hasValue = this.props.filters[comparator];
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.work-order.assignee" />}
        comparator={comparator}
        hasValue={hasValue}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Assignee,
            data: {
              [FILTER_TYPES.Assignee]: {
                [HelperFunctions.FILTER_COMPARABLES.Any]: {
                  userIds: [],
                  groupIds: [],
                  vendorIds: [],
                },
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filters: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Assignee),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignee);
