import React, { Component } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { AuthSelectors } from 'state/ducks/auth';
import moment from 'moment-timezone';
import Header from '../../Header';
import ActivitiesDay from './ActivitiesDay';
import styles from './style.module.scss';

class Activites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.fetchWorkOrderActivities(this.props.match.params.id, this.props.currentSystem).then(() => {
      this.setState({ loading: false });
    });
  }

  renderLoading = () => {
    return <ActivitiesDay loading />;
  };

  renderDots = () => (
    <div className={styles['dots-container']}>
      <div className={styles['dot']} />
      <div className={styles['dot']} />
      <div className={styles['dot']} />
    </div>
  );

  renderContent() {
    if (this.state.loading) return this.renderLoading();
    return this.props.dates.map((date, i, array) => {
      let diff = 0;
      if (array[i - 1] != null) {
        diff = moment(date).diff(moment(array[i - 1]), 'days');
      }

      return (
        <>
          {diff >= 14 ? this.renderDots() : null}
          <ActivitiesDay date={date} />
        </>
      );
    });
  }

  render = () => {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>
          <div className={styles['main']}>{this.renderContent()}</div>
        </ContentContainer>
      </PerfectScrollbar>
    );
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrderActivities: WorkOrderOperations.fetchWorkOrderActivities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    dates: WorkOrderSelectors.getWorkOrderActivityDates(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Activites);
