import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WhiteCard, Button, Tooltip, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { Grid } from 'views/components/Shared/Layout';
import ContentLoader from 'react-content-loader';
import { TemplateField } from 'views/components/TemplateField';
import AssetTypeField from './AssetTypeField';
import ParentAssetField from './ParentAssetField';
import styles from './styles.module.scss';

class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingAsset: {},
      editingAssetBeforeEdit: {},
      editingAssetFields: {},
      editingAssetFieldsBeforeEdit: {},
    };
  }

  edit = () => {
    const editingAssetFields = this.buildEditingAssetFields();
    const editingAsset = {
      asset_type_id: this.props.asset.asset_type_id,
      tree_parent_id: this.props.asset.tree_parent_id,
    };

    this.setState({
      editing: true,
      editingAsset: editingAsset,
      editingAssetBeforeEdit: editingAsset,
      editingAssetFields: editingAssetFields,
      editingAssetFieldsBeforeEdit: editingAssetFields,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });

    let params = {
      ...this.getModifiedAssetData(),
      asset_fields: this.getModifiedAssetFieldsData(),
    };
    const changedTreeParent = this.props.asset.tree_parent_id !== this.state.editingAsset.tree_parent_id;
    if (changedTreeParent) {
      params = {
        ...params,
        tree_sort: null,
      };
    }
    this.props
      .updateAsset(this.props.asset.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="screens.asset.info.update-success" />} />);
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '30002', key: 'tree_parent_id' })) {
          this.setState({ isSaving: false });
          toast(<ToastMessage error text={<FormattedMessage id="screens.asset.info.errors.tree-child" />} />);
        }
        this.setState({ isSaving: false });
      });
  };

  getModifiedAssetData = () => {
    const asset = this.state.editingAsset;
    const assetBeforeEdit = this.state.editingAssetBeforeEdit;

    return Object.keys(asset)
      .filter(key => asset[key] !== assetBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: asset[key],
        }),
        {}
      );
  };

  getModifiedAssetFieldsData = () => {
    const assetFields = this.state.editingAssetFields;
    const assetFieldsBeforeEdit = this.state.editingAssetFieldsBeforeEdit;

    return Object.keys(assetFields)
      .filter(key => assetFields[key] !== assetFieldsBeforeEdit[key])
      .map(key => ({
        template_field_id: key,
        value: assetFields[key],
      }));
  };

  buildEditingAssetFields = () => {
    return this.props.templateFields.reduce((acc, templateField) => {
      const assetField = this.props.assetFields.find(
        assetField => assetField.template_field_id === templateField.id
      );
      //assetField can be null if a new templatefield is created through the socket

      return {
        ...acc,
        [templateField.id]: assetField == null ? '' : assetField.value,
      };
    }, {});
  };

  setAssetValue = obj => {
    const newEditingAsset = {
      ...this.state.editingAsset,
      ...obj,
    };

    this.setState({
      editingAsset: newEditingAsset,
    });
  };

  setAssetFieldValue = (templateField, value) => {
    const newEditingAssetFields = {
      ...this.state.editingAssetFields,
      [templateField.id]: value,
    };

    this.setState({
      editingAssetFields: newEditingAssetFields,
    });
  };

  getAssetFieldValueForTemplateField = templateField => {
    if (this.state.editing) {
      return this.state.editingAssetFields[templateField.id];
    } else {
      const assetField = this.props.assetFields.find(
        assetField => assetField.template_field_id === templateField.id
      );
      //assetField can be null if a new templatefield is created through the socket
      return assetField == null ? '' : assetField.value;
    }
  };

  isLoaded = () =>
    this.props.asset != null && this.props.assetFields != null && this.props.templateFields != null;

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="12" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="28" rx="2" ry="2" width="70" height="6" />
      </ContentLoader>
    </div>
  );

  renderTemplateField = templateField => (
    <TemplateField
      templateField={templateField}
      editing={this.state.editing}
      value={this.getAssetFieldValueForTemplateField(templateField)}
      onValueChange={value => {
        this.setAssetFieldValue(templateField, value);
      }}
    />
  );

  getFieldsInColumn = (column, templateFields) => {
    return templateFields
      .filter(templateField => templateField.column === column)
      .sort((a, b) => parseInt(a.sort) - parseInt(b.sort));
  };

  renderTemplateFields = () => {
    if (this.props.templateFields.length === 0) return null;
    const { asset, templateFields } = this.props;
    const { editingAsset } = this.state;
    let assetTypeId = asset.asset_type_id;
    if (this.state.editing) {
      assetTypeId = editingAsset.asset_type_id;
    }

    const generalFields = templateFields.filter(templateField => templateField.asset_type_id === null);
    let templateFieldsForAssetType = [];
    if (assetTypeId !== null) {
      templateFieldsForAssetType = templateFields.filter(
        templateField => templateField.asset_type_id === assetTypeId
      );
    }

    const generalCol1 = this.getFieldsInColumn(1, generalFields);
    const generalCol2 = this.getFieldsInColumn(2, generalFields);

    let generalRows = [];
    for (var i = 0; i < Math.max(generalCol1.length, generalCol2.length); i++) {
      generalRows = [
        ...generalRows,
        <Grid.Row key={i}>
          <Grid.Column>{generalCol1[i] ? this.renderTemplateField(generalCol1[i]) : null}</Grid.Column>
          <Grid.Column>{generalCol2[i] ? this.renderTemplateField(generalCol2[i]) : null}</Grid.Column>
        </Grid.Row>,
      ];
    }
    if (!templateFieldsForAssetType.length) {
      return (
        <React.Fragment>
          <Grid>
            <Grid.Separator />
            {generalRows}
          </Grid>
        </React.Fragment>
      );
    }

    let assetTypeRows = [];
    if (templateFieldsForAssetType.length > 0) {
      const assetTypeCol1 = this.getFieldsInColumn(1, templateFieldsForAssetType);
      const assetTypeCol2 = this.getFieldsInColumn(2, templateFieldsForAssetType);
      for (var j = 0; j < Math.max(assetTypeCol1.length, assetTypeCol2.length); j++) {
        assetTypeRows = [
          ...assetTypeRows,
          <Grid.Row key={[i, 'for_asset_type'].join('_')}>
            <Grid.Column>{assetTypeCol1[j] ? this.renderTemplateField(assetTypeCol1[j]) : null}</Grid.Column>
            <Grid.Column>{assetTypeCol2[j] ? this.renderTemplateField(assetTypeCol2[j]) : null}</Grid.Column>
          </Grid.Row>,
        ];
      }
    }

    return (
      <React.Fragment>
        {generalRows.length && (
          <Grid>
            <Grid.Separator />
            {generalRows}
          </Grid>
        )}
        <Grid>
          <Grid.Separator />
          {assetTypeRows}
        </Grid>
      </React.Fragment>
    );
  };

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditAssets) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return this.renderContentLoader();

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column xs={6}>
              <AssetTypeField
                editing={this.state.editing}
                value={
                  this.state.editing ? this.state.editingAsset.asset_type_id : this.props.asset.asset_type_id
                }
                onChange={assetTypeId => {
                  this.setAssetValue({ asset_type_id: assetTypeId });
                }}
              />
            </Grid.Column>
            <Grid.Column xs={6}>
              <ParentAssetField
                editing={this.state.editing}
                value={
                  this.state.editing
                    ? this.state.editingAsset.tree_parent_id
                    : this.props.asset.tree_parent_id
                }
                onChange={tree_parent_id => {
                  this.setAssetValue({ tree_parent_id });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderTemplateFields()}
      </React.Fragment>
    );
  };

  render() {
    return (
      <WhiteCard
        title={<FormattedMessage id="screens.asset.info.field-title" />}
        headerButtons={this.renderHeaderButtons()}
        footer={this.renderFooter()}
      >
        {this.renderContent()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  const asset = EntitySelectors.getAsset(state, assetId);

  return {
    asset,
    templateFields: AuthSelectors.getAssetTemplateFields(state),
    assetFields: EntitySelectors.getAssetFields(state, asset.asset_fields),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GeneralInformation)));
