import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { generateEmptyChecklistTemplateRow, MeterType } from 'sdk/ChecklistTemplateRow';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import styles from './style.module.scss';

const dropTarget = {
  drop(props, monitor, component) {
    const isNew = monitor.getItem().id === 'new';
    const draggingId = monitor.getItem().id;
    const draggedFromSectionId = monitor.getItem().parent;
    const dragIndex = monitor.getItem().index;

    let meterType = MeterType.Option;
    if (props.isCreatingChecklistFromTemplatesLibrary === false) {
      meterType = MeterType.Fixed;
    }

    if (isNew) {
      props.prepareNewFieldForEdit({
        ...generateEmptyChecklistTemplateRow(),
        meter_type: meterType,
        type: monitor.getItem().type,
        id: monitor.getItem().id,
        sort: props.index + 1,
      });
      props.addNewFieldToRoot({
        id: monitor.getItem().id,
        toIndex: props.index,
      });

      return;
    }

    if (draggedFromSectionId) {
      props.moveFieldFromSectionToRoot({
        sectionId: draggedFromSectionId,
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: props.index,
      });
    } else {
      props.sortFieldOutsideSection({
        id: draggingId,
        fromIndex: dragIndex,
        toIndex: props.index,
      });
    }
    props.updateChecklistTemplateRow(draggingId, { parent_row_id: null, sort: props.index + 1 });
  },
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

class FirstRowPlaceHolderDropzone extends Component {
  render() {
    let classNames = [styles['rows-placeholder']];
    if (this.props.isOver) {
      classNames = [...classNames, styles['is-over']];
    }
    if (this.props.fullHeight) {
      classNames = [...classNames, styles['full-height']];
    }
    if (this.props.children) {
      return this.props.connectDropTarget(<div className={classNames.join(' ')}>{this.props.children}</div>);
    }
    return this.props.connectDropTarget(<div className={classNames.join(' ')} />);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewFieldToRoot: ChecklistEditModalOperations.addNewFieldToRoot,
      prepareNewFieldForEdit: ChecklistEditModalOperations.prepareNewFieldForEdit,
      sortFieldOutsideSection: ChecklistEditModalOperations.sortFieldOutsideSection,
      moveFieldFromSectionToRoot: ChecklistEditModalOperations.moveFieldFromSectionToRoot,
      updateChecklistTemplateRow: SDKReduxOperations.updateChecklistTemplateRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    isCreatingChecklistFromTemplatesLibrary:
      ChecklistEditModalSelectors.isCreatingChecklistFromTemplatesLibrary(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropTarget('checklist', dropTarget, dropCollect)(FirstRowPlaceHolderDropzone));
