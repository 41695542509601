import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TemplateFieldType } from 'sdk/TemplateField';
import { Field, Icon } from 'views/components/Shared/General';
import { SettingsOperations } from 'state/ducks/settings';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

function DraggingField(props) {
  switch (props.templateField.type) {
    case TemplateFieldType.Text: {
      return (
        <div key={props.templateField.id} className={styles['holder']}>
          <Field label={props.templateField.title} view={false}>
            <Field.Text />
          </Field>
        </div>
      );
    }
    case TemplateFieldType.TextArea: {
      return (
        <div key={props.templateField.id} className={styles['holder']}>
          <Field label={props.templateField.title} view={false}>
            <Field.Textarea />
          </Field>
        </div>
      );
    }
    case TemplateFieldType.ValueList: {
      return (
        <div key={props.templateField.id} className={styles['holder']}>
          <Field label={props.templateField.title} view={false}>
            <Field.Dropdown />
          </Field>
        </div>
      );
    }
    case TemplateFieldType.Date: {
      return (
        <div key={props.templateField.id} className={styles['holder']}>
          <Field label={props.templateField.title} view={false}>
            <Field.Date icon={<Icon regular type="calendar-alt" />} />
          </Field>
        </div>
      );
    }
    default:
      return null;
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectTemplateField: SettingsOperations.selectTemplateField,
      unselectTemplateField: SettingsOperations.unselectTemplateField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    templateField: EntitySelectors.getTemplateField(state, id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggingField);
