import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Channels } from 'sdk';
import { Button, PathItem, WhiteCard, Icon } from 'views/components/Shared/General';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import {
  FolderOptionsInlineModal,
  AttachmentFolder,
  AttachmentFolderModal,
} from 'views/components/Attachment';
import { HelperFunctions } from 'sdk';
import AMServiceLogo from 'assets/images/Logo.png';
import AttachmentFolders_Eng from 'assets/images/ProScreenshots/AttachmentFolders (Eng).png';
import AttachmentFolders_Swe from 'assets/images/ProScreenshots/AttachmentFolders (Swe).png';
import styles from './style.module.scss';

class AttachmentFolders extends Component {
  constructor(props) {
    super(props);
    this.folderChannel = null;
    this.state = {
      attachmentCountPerFolderId: {},
      showAttachmentFolderModal: false,
      editingAttachmentFolder: null,
    };
  }

  componentDidMount() {
    if (this.props.hasProTier === false) return;
    this.folderChannel = this.props.joinAttachmentFoldersChannel(this.props.currentSystem.id);
  }

  componentDidUpdate(prevProps) {
    const attachmentFolders = this.props.attachmentFolders;
    const prevAttachmentFolders = prevProps.attachmentFolders;
    if (!attachmentFolders.every(e => prevAttachmentFolders.includes(e))) {
      this.fetchCountForFolders();
    }
  }

  componentWillUnmount() {
    if (this.props.hasProTier === false) return;
    this.folderChannel.leave();
  }

  fetchCountForFolders = () => {
    const attrs = {
      attachment_folder_ids: this.props.attachmentFolders.map(folder => folder.id).join(),
    };

    this.folderChannel.push('get_attachment_count:all', attrs).receive('ok', payload => {
      this.setState({ attachmentCountPerFolderId: payload.count });
    });
  };

  buildFolderPathForFolderId = folderId => {
    const attachmentFolder = this.props.allAttachmentFolders.find(
      attachmentFolder => folderId === attachmentFolder.id
    );
    if (attachmentFolder == null) return [];
    let attachmentFolders = [attachmentFolder];
    this.props.allAttachmentFolders.forEach(loopedAttachmentFolder => {
      if (attachmentFolder.attachment_folder_parent_id === loopedAttachmentFolder.id) {
        attachmentFolders = [
          ...this.buildFolderPathForFolderId(loopedAttachmentFolder.id),
          ...attachmentFolders,
        ];
      }
    });
    return attachmentFolders;
  };

  renderFolderPathTitle = () => {
    const folderId = queryString.parse(this.props.location.search).folder_id;
    const folderPath = this.buildFolderPathForFolderId(folderId);
    return (
      <div className={styles['folder-path']}>
        <PathItem
          active={folderId == null}
          clickable={folderId != null}
          onClick={() => this.props.history.push(`/settings/assets/attachment-folders`)}
        >
          <FormattedMessage id="screens.settings.assets.attachment-folders.root-title" />
        </PathItem>
        {folderId == null ? null : (
          <React.Fragment>
            {folderPath.map((attachmentFolder, index) => {
              if (index !== folderPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem
                      clickable
                      onClick={() =>
                        this.props.history.push(
                          `?${HelperFunctions.convertObjToQueryParameters({
                            folder_id: attachmentFolder.id,
                          })}`
                        )
                      }
                    >
                      {attachmentFolder.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <FolderOptionsInlineModal
                      attachmentFolder={attachmentFolder}
                      trigger={
                        <PathItem active clickable caret>
                          {attachmentFolder.title}
                        </PathItem>
                      }
                      onEditAttachmentFolder={attachmentFolder => {
                        this.setState({
                          showAttachmentFolderModal: true,
                          editingAttachmentFolder: attachmentFolder,
                        });
                      }}
                    />
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderContent = () => (
    <React.Fragment>
      <div className={styles['title-container']}>{this.renderFolderPathTitle()}</div>
      <div className={styles['folders-container']}>
        <div className={styles['folders']}>
          {this.props.attachmentFolders.map(attachmentFolder => (
            <AttachmentFolder
              attachmentFolder={attachmentFolder}
              attachmentCount={this.state.attachmentCountPerFolderId[attachmentFolder.id]}
              onClick={() =>
                this.props.history.push(
                  `?${HelperFunctions.convertObjToQueryParameters({
                    folder_id: attachmentFolder.id,
                  })}`
                )
              }
              button={
                <FolderOptionsInlineModal
                  attachmentFolder={attachmentFolder}
                  onEditAttachmentFolder={attachmentFolder => {
                    this.setState({
                      showAttachmentFolderModal: true,
                      editingAttachmentFolder: attachmentFolder,
                    });
                  }}
                  trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                />
              }
            />
          ))}
          <div
            className={`${styles['folder']} ${styles['new']}`}
            onClick={() => {
              this.setState({ showAttachmentFolderModal: true });
            }}
          >
            <div className={styles['content-container']}>
              <Icon type="plus" />
              <div>
                <p className={styles['folder-title']}>
                  <FormattedMessage id="screens.settings.assets.attachment-folders.new-folder" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AttachmentFolderModal
        open={this.state.showAttachmentFolderModal}
        parentAttachmentFolderId={queryString.parse(this.props.location.search).folder_id}
        attachmentFolder={this.state.editingAttachmentFolder}
        onClose={() => {
          this.setState({ showAttachmentFolderModal: false, editingAttachmentFolder: null });
        }}
      />
    </React.Fragment>
  );

  renderUpgradeView = () => {
    return (
      <WhiteCard centerContent padding="60px 220px 50px">
        <div className={styles['upgrade-title-container']}>
          <img src={AMServiceLogo} />
          <p>
            <FormattedMessage id="screens.settings.assets.attachment-folders.upgrade-to-pro.title" />
          </p>
        </div>
        <p className={styles['upgrade-subtitle']}>
          <FormattedMessage id="screens.settings.assets.attachment-folders.upgrade-to-pro.subtitle" />
        </p>
        <img
          className={styles['upgrade-screenshot']}
          src={this.props.lang === 'sv' ? AttachmentFolders_Swe : AttachmentFolders_Eng}
        />
        <div className={styles['upgrade-buttons-container']}>
          <Button upgradeToPro label="general.upgrade-to-pro" />
        </div>
      </WhiteCard>
    );
  };

  render() {
    return (
      <>
        <BackButton to="/settings/assets" />
        <Section
          title={<FormattedMessage id="screens.settings.assets.attachment-folders.title" />}
          subtitle={<FormattedMessage id="screens.settings.assets.attachment-folders.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      joinAttachmentFoldersChannel: Channels.AttachmentFoldersChannel.join,
      leaveAttachmentFoldersChannel: Channels.AttachmentFoldersChannel.leave,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const folderId = queryString.parse(ownProps.location.search).folder_id;
  return {
    attachmentFolders: EntitySelectors.getAttachmentFoldersForFolder(state, folderId),
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    lang: AuthSelectors.getLanguage(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AttachmentFolders)));
