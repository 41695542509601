import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class Dropdown extends Component {
  renderProIcon = button => {
    if (button.proFeature === true && this.props.hasProTier === false) {
      return (
        <div className={styles['locked-icon-container']}>
          <Icon type="lock-alt" size={12} />
        </div>
      );
    }
    return null;
  };

  renderSubDropdown = () => {
    if (this.props.subButtons.length === 0) {
      return null;
    }
    return (
      <div className={styles['sub-dropdown']}>
        {this.props.subButtons.map(button => (
          <Link
            to={button.link}
            className={styles['sub-dropdown-item']}
            onClick={e => {
              if (typeof button.onClick === 'function') {
                button.onClick();
              }
              e.stopPropagation();
              this.props.onClose();
            }}
          >
            {button.title}
            {this.renderProIcon(button)}
          </Link>
        ))}
      </div>
    );
  };

  renderMainDropdown = () => {
    if (this.props.mainButtons.length === 0) {
      return null;
    }
    return (
      <div className={styles['dropdown']}>
        <div className={styles['dropdown-items']}>
          {this.props.mainButtons.map(button => (
            <Link
              to={button.link}
              className={styles['dropdown-item']}
              onClick={e => {
                button.onClick();
                e.stopPropagation();
                this.props.onClose();
              }}
            >
              <div className={styles['icon-container']}>
                <Icon type={button.icon} size={16} />
                {this.renderProIcon(button)}
              </div>
              <div className={styles['title-container']}>
                <div className={styles['title']}>
                  {button.title}
                  <div className={styles['hover-icon-container']}>
                    <Icon type="arrow-right" size={12} />
                  </div>
                </div>
                <div className={styles['subtitle']}>{button.subtitle}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  render() {
    let classNames = [styles['dropdown-container']];
    if (this.props.position === 'right') {
      classNames = [...classNames, styles['right']];
    } else {
      classNames = [...classNames, styles['left']];
    }
    return (
      <div className={classNames.join(' ')}>
        {this.renderMainDropdown()}
        {this.renderSubDropdown()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(Dropdown);

Dropdown.propTypes = {
  mainButtons: PropTypes.array,
  subButtons: PropTypes.array,
  proFeature: PropTypes.bool,
  renderSubItem: PropTypes.func,
};

Dropdown.defaultProps = {
  mainButtons: [],
  subButtons: [],
  proFeature: false,
  renderSubItem: null,
};
