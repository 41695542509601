import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const ParentFolderTitleContainer = ({ parentFolderId, attachmentFolder, rootFolderTitle }) => {
  return parentFolderId == null ? rootFolderTitle : <span>{attachmentFolder.title}</span>;
};

function mapStateToProps(state, ownProps) {
  return {
    attachmentFolder: EntitySelectors.getAttachmentFolder(state, ownProps.parentFolderId),
  };
}
export default connect(mapStateToProps)(ParentFolderTitleContainer);
