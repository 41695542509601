import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { ChecklistTemplateRow } from 'views/components/Checklist';

class Inspection extends Component {
  renderCheckbox = () => {
    const { deviationTab, selectedMenuItem, isViewOnly } = this.props;
    return isViewOnly === false && selectedMenuItem === 'deviations' && deviationTab === 'require_action';
  };

  isEditable = () => {
    const { isEditable, selectedMenuItem, isViewOnly } = this.props;
    return isViewOnly === false && isEditable === true && selectedMenuItem !== 'deviations';
  };

  render() {
    const { instanceRow, selectedMenuItem, deviationTab } = this.props;
    const instanceRowId = instanceRow ? instanceRow.id : null;
    return (
      <ChecklistTemplateRow.Inspection
        id={this.props.id}
        instanceRowId={instanceRowId}
        editable={this.isEditable()}
        checkbox={this.renderCheckbox()}
        deviation={selectedMenuItem === 'deviations' && deviationTab === 'all'}
        checked={this.props.isChecklistInstanceRowSelected}
        onCheck={value => {
          if (value) {
            this.props.addDeviationRow(instanceRowId);
          } else {
            this.props.removeDeviationRow(instanceRowId);
          }
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addDeviationRow: ChecklistModalOperations.addDeviationRow,
      removeDeviationRow: ChecklistModalOperations.removeDeviationRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const instanceRow = ChecklistModalSelectors.getInstanceRowForTemplateRowId(state, ownProps.id);
  let isChecklistInstanceRowSelected = false;
  if (instanceRow) {
    isChecklistInstanceRowSelected = ChecklistModalSelectors.isChecklistInstanceRowSelected(
      state,
      instanceRow.id
    );
  }
  return {
    instanceRow,
    isChecklistInstanceRowSelected,
    isEditable: ChecklistModalSelectors.isEditable(state),
    selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
    deviationTab: ChecklistModalSelectors.getSelectedDeviationTab(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
