import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { mutateTree } from '@atlaskit/tree/';
import { buildAtlaskitTree } from 'sdk/Asset';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, Loader, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { Tree } from 'views/components/Asset';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import styles from './style.module.scss';

class AssetDuplicateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isSaving: false,
      tree: [],
    };
  }

  componentDidMount() {
    API.fetchTree(this.props.selectedSystem.id, { tree_children_for_asset_id: this.props.asset.id }).then(
      res => {
        this.setState({
          loading: false,
          tree: buildAtlaskitTree(res.data, { rootId: this.props.asset.id }),
        });
      }
    );
  }

  save = () => {
    this.setState({ isSaving: true });
    this.props
      .duplicateAsset(this.props.asset.id)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="components.asset-duplicate.duplicate-success"
                values={{ title: this.props.asset.title }}
              />
            }
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
        //TODO: Show general error på dessa
        throw e;
      });
  };

  expandTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: true }),
    });
  };

  collapseTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: false }),
    });
  };

  renderTree = () => (
    <div className={`${styles['tree-container']}`}>
      <Tree
        tree={this.state.tree}
        onExpand={this.expandTree}
        onCollapse={this.collapseTree}
        clickable={false}
        isNestingEnabled
      />
    </div>
  );

  renderDuplicateInformation = () => (
    <div className={styles['duplicate-info-container']}>
      <Field label={<FormattedMessage id="components.asset-duplicate.duplicate-info.title" />}>
        <div className={styles['duplicate-info']}>
          <div>
            <Icon regular type="info-circle" />
            <FormattedMessage id="components.asset-duplicate.duplicate-info.info" />
          </div>
          <div>
            <Icon regular type="cogs" />
            <FormattedMessage id="components.asset-duplicate.duplicate-info.spare-parts" />
          </div>
          <div>
            <Icon regular type="briefcase" />
            <FormattedMessage id="components.asset-duplicate.duplicate-info.vendors" />
          </div>
          <div>
            <Icon regular type="file-alt" />
            <FormattedMessage id="components.asset-duplicate.duplicate-info.attachments" />
          </div>
        </div>
      </Field>
    </div>
  );

  render() {
    return (
      <Modal isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="components.asset-duplicate.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          {this.state.loading ? (
            <div className={styles['loader']}>
              <Loader small />
            </div>
          ) : (
            <React.Fragment>
              {this.renderTree()}
              {this.renderDuplicateInformation()}
            </React.Fragment>
          )}
        </Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button primary label="general.duplicate" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      duplicateAsset: SDKReduxOperations.duplicateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
  };
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetDuplicateModal));
