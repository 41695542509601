import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import ChecklistTemplateRows from './ChecklistTemplateRows';
import DragHandle from '../../DragHandle';
import styles from './style.module.scss';

class Section extends Component {
  renderNewSectionRow = () => {
    let classNames = [styles['section'], styles['new'], styles['editing']];
    return (
      <div className={styles['container']}>
        <div className={classNames.join(' ')}>
          <DragHandle />
          <div className={styles['placeholder']}>
            <FormattedMessage id="components.edit-checklist-modal.new-section-placeholder" />
          </div>
        </div>
        <ChecklistTemplateRows id={this.props.id} />
      </div>
    );
  };

  renderSectionRow = () => {
    const { draggingChecklistTemplateRow, editingField, checklistTemplateRow } = this.props;
    let isDraggingCurrentSection =
      draggingChecklistTemplateRow && draggingChecklistTemplateRow.id === this.props.checklistTemplateRow.id;

    let classNames = [styles['section']];
    if (editingField && editingField.id === checklistTemplateRow.id) {
      classNames = [...classNames, styles['editing']];
    }

    return this.props.connectDragSource(
      this.props.connectDropTarget(
        <div className={`${styles['container']} ${isDraggingCurrentSection ? styles['dragging'] : ''}`}>
          <div
            className={classNames.join(' ')}
            onClick={() => {
              if (editingField && editingField.id === checklistTemplateRow.id) {
                this.props.cancelEditField();
              } else {
                this.props.editField(this.props.checklistTemplateRow);
              }
            }}
          >
            <DragHandle />
            <div className={styles['title-container']}>{this.props.checklistTemplateRow.title}</div>
          </div>
          <ChecklistTemplateRows id={this.props.id} />
        </div>
      )
    );
  };

  render() {
    if (this.props.id === 'new') {
      return this.renderNewSectionRow();
    }
    return this.renderSectionRow();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editField: ChecklistEditModalOperations.editField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
    draggingChecklistTemplateRow: ChecklistEditModalSelectors.getDraggingChecklistTemplateRow(state),
    editingField: ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
