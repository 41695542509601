import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class NewQrTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  getInititalState = () => ({
    title: '',
    width: '',
    height: '',
    isSaving: false,
    showTitleError: false,
    showWidthError: false,
    showHeightError: false,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInititalState() });
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="components.print-qr-codes.edit-title-modal.title-new" />}
          onClose={() => {
            this.props.onClose();
          }}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.qr-template.title" />}>
                  <Field.Text
                    autoFocus
                    value={this.state.title}
                    error={this.state.showTitleError}
                    onChange={title => this.setState({ title, showTitleError: false })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column md={6}>
                <Field label={<FormattedMessage id="resources.qr-template.width" />}>
                  <Field.Decimal
                    decimalPlaces={2}
                    value={this.state.width}
                    error={this.state.showWidthError}
                    onChange={width =>
                      this.setState({ width, showWidthError: width >= 1 ? false : this.state.showWidthError })
                    }
                    onBlur={width =>
                      this.setState({ width, showWidthError: width >= 1 ? false : this.state.showWidthError })
                    }
                    rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                  />
                </Field>
              </Grid.Column>
              <Grid.Column md={6}>
                <Field label={<FormattedMessage id="resources.qr-template.height" />}>
                  <Field.Decimal
                    decimalPlaces={2}
                    value={this.state.height}
                    error={this.state.showHeightError}
                    onChange={height =>
                      this.setState({
                        height,
                        showHeightError: height >= 1 ? false : this.state.showHeightError,
                      })
                    }
                    onBlur={height =>
                      this.setState({
                        height,
                        showHeightError: height >= 1 ? false : this.state.showHeightError,
                      })
                    }
                    rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              label="general.save"
              onClick={() => {
                let errors = {};
                if (this.state.title.length === 0) {
                  errors = { ...errors, showTitleError: true };
                }
                if (this.state.width <= 0) {
                  errors = { ...errors, showWidthError: true };
                }
                if (this.state.height <= 0) {
                  errors = { ...errors, showHeightError: true };
                }
                if (Object.keys(errors).length > 0) {
                  this.setState({
                    ...errors,
                  });
                  return;
                }

                this.setState({ isSaving: true });
                this.props
                  .createQrTemplate(this.props.system.id, {
                    title: this.state.title,
                    width: this.state.width,
                    height: this.state.height,
                    template_type: this.props.templateType === 'sparePart' ? 'spare_part' : 'asset',
                    qr_template_columns: [{ width: 100 }],
                  })
                  .then(({ data: qrTemplate }) => {
                    this.props.onSave(qrTemplate.id);
                  })
                  .catch(() => {
                    this.props.onClose();
                  });
              }}
            />
            <Button
              label="general.cancel"
              onClick={() => {
                this.props.onClose();
              }}
            />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createQrTemplate: SDKReduxOperations.createQrTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewQrTemplateModal);
