import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, SectionHeader } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { PreviewRequestModal } from 'views/components/Request';
import { PreviewWorkOrderModal } from 'views/components/WorkOrder';
import SelectFollowUpModal from './SelectFollowUpModal';
import styles from './styles.module.scss';

class CreatedFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestModal: false,
      showRequestModalForId: null,
      showPreviewWorkOrderModal: false,
      showSelectFollowupModal: false,
      showPreviewWorkOrderModalForId: null,
      openedFromSelectFollowUpModal: false,
    };
  }

  renderAmountOfAdditionalFollowups = () => {
    const amountOfAdditionalFollowUps = this.props.requests.length + this.props.workOrders.length;
    if (amountOfAdditionalFollowUps > 1) {
      return <div className={styles['additional-follow-ups']}>+{amountOfAdditionalFollowUps - 1}</div>;
    }
  };

  renderPreviewWorkOrderModal = () => {
    return (
      <PreviewWorkOrderModal
        open={this.state.showPreviewWorkOrderModal}
        id={this.state.showPreviewWorkOrderModalForId}
        onClose={() => {
          if (this.state.openedFromSelectFollowUpModal) {
            this.setState({ showPreviewWorkOrderModal: false });
            setTimeout(() => {
              this.setState({
                showSelectFollowupModal: true,
              });
            }, 250);
          } else {
            this.setState({ showPreviewWorkOrderModal: false });
          }
        }}
      />
    );
  };

  renderRequestModal = () => {
    return (
      <PreviewRequestModal
        open={this.state.showRequestModal}
        id={this.state.showRequestModalForId}
        onClose={() => {
          if (this.state.openedFromSelectFollowUpModal) {
            this.setState({ showRequestModal: false });
            setTimeout(() => {
              this.setState({
                showSelectFollowupModal: true,
              });
            }, 250);
          } else {
            this.setState({ showRequestModal: false });
          }
        }}
      />
    );
  };

  renderSelectFollowUpModal = () => {
    return (
      <SelectFollowUpModal
        open={this.state.showSelectFollowupModal}
        requests={this.props.requests}
        workOrders={this.props.workOrders}
        onSelectWorkOrder={id => {
          this.setState({ showSelectFollowupModal: false });
          setTimeout(() => {
            this.setState({
              showPreviewWorkOrderModal: true,
              showPreviewWorkOrderModalForId: id,
              openedFromSelectFollowUpModal: true,
            });
          }, 250);
        }}
        onSelectRequest={id => {
          this.setState({ showSelectFollowupModal: false });
          setTimeout(() => {
            this.setState({
              showRequestModal: true,
              showRequestModalForId: id,
              openedFromSelectFollowUpModal: true,
            });
          }, 250);
        }}
        onClose={() => {
          this.setState({ showSelectFollowupModal: false, openedFromSelectFollowUpModal: false });
        }}
      />
    );
  };

  renderContent = () => {
    const amountOfFollowUps = this.props.requests.length + this.props.workOrders.length;
    if (this.props.workOrders.length > 0) {
      const workOrder = this.props.workOrders[0];
      return (
        <>
          <div
            className={styles['content-container']}
            onClick={() => {
              if (amountOfFollowUps > 1) {
                this.setState({
                  showSelectFollowupModal: true,
                });
              } else {
                this.setState({
                  showPreviewWorkOrderModal: true,
                  showPreviewWorkOrderModalForId: workOrder.id,
                });
              }
            }}
          >
            <div className={styles['content']}>
              <div className={styles['value-container']}>
                <div className={styles['label']}>
                  <span>
                    <FormattedMessage id="resources.work-order.resource" />
                  </span>
                  <span> </span>
                  <span>#{workOrder.number}</span>
                </div>
                <div className={styles['value']}>{workOrder.title}</div>
              </div>
              {this.renderAmountOfAdditionalFollowups()}
            </div>
          </div>
        </>
      );
    }
    if (this.props.requests.length > 0) {
      const request = this.props.requests[0];
      return (
        <>
          <div
            className={styles['content-container']}
            onClick={() => {
              if (amountOfFollowUps > 1) {
                this.setState({
                  showSelectFollowupModal: true,
                });
              } else {
                this.setState({
                  showRequestModal: true,
                  showRequestModalForId: request.id,
                });
              }
            }}
          >
            <div className={styles['content']}>
              <div className={styles['value-container']}>
                <div className={styles['label']}>
                  <span>
                    <FormattedMessage id="resources.request.resource" />
                  </span>
                  <span> </span>
                  <span>#{request.number}</span>
                </div>
                <div className={styles['value']}>{request.title}</div>
              </div>
              {this.renderAmountOfAdditionalFollowups()}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  render() {
    const { workOrders, requests } = this.props;
    if ((workOrders && workOrders.length > 0) || (requests && requests.length > 0)) {
      return (
        <>
          <CardGrid.Row>
            <WhiteCard noPadding>
              <SectionHeader noBorderTop paddingHorizontal={25}>
                <div className={styles['section']}>
                  <FormattedMessage id="screens.work-order.follow-up.title" />
                </div>
              </SectionHeader>
              {this.renderContent()}
            </WhiteCard>
          </CardGrid.Row>
          {this.renderSelectFollowUpModal()}
          {this.renderPreviewWorkOrderModal()}
          {this.renderRequestModal()}
        </>
      );
    }
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;
  return {
    workOrders: EntitySelectors.getWorkOrders(state, workOrder.created_work_orders),
    requests: EntitySelectors.getRequests(state, workOrder.created_requests),
  };
}

export default withRouter(connect(mapStateToProps)(CreatedFrom));
