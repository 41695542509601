const DOMAIN_PREFIX = 'calendar/';

const SET_ASSIGNEES = `${DOMAIN_PREFIX}set_assignees`;
const FETCH_DATA_BETWEEN_DATES_SUCCESS = `${DOMAIN_PREFIX}fetch_data_between_dates_success`;
const ADD_ASSIGNEES_FOR_INSTANCE = `${DOMAIN_PREFIX}add_assignees_for_instance`;
const REMOVE_ASSIGNEES_FROM_INSTANCE = `${DOMAIN_PREFIX}remove_assignees_from_instance`;
const HOVER_CALENDAR_INSTANCE = `${DOMAIN_PREFIX}hover_calendar_instance`;
const UPDATE_INSTANCE = `${DOMAIN_PREFIX}update_instance`;
const SET_COUNTS = `${DOMAIN_PREFIX}set_counts`;
const SHOW_WORK_ORDER_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}show_preview_overlay`;
const HIDE_WORK_ORDER_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}hide_preview_overlay`;
const SHOW_REQUEST_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}show_request_preview_overlay`;
const HIDE_REQUEST_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}hide_request_preview_overlay`;
const BEGIN_DRAG = `${DOMAIN_PREFIX}being_drag`;
const END_DRAG = `${DOMAIN_PREFIX}end_drag`;
const BEGIN_DRAG_FROM_TOOLBOX = `${DOMAIN_PREFIX}being_drag_from_toolbox`;
const END_DRAG_FROM_TOOLBOX = `${DOMAIN_PREFIX}end_drag_from_toolbox`;
const SHOW_LIST_BAR = `${DOMAIN_PREFIX}show_list_bar`;
const HIDE_LIST_BAR = `${DOMAIN_PREFIX}hide_list_bar`;
const ADD_INSTANCES_FOR_CALENDAR = `${DOMAIN_PREFIX}add_instances_for_calendar`;
const REMOVE_INSTANCES_FROM_CALENDAR = `${DOMAIN_PREFIX}remove_instances_from_calendar`;
const FETCH_WORK_ORDERS_TO_PLAN_SUCCESS = `${DOMAIN_PREFIX}fetch_work_orders_to_plan_success`;
const FETCH_METER_BASED_WORK_ORDERS_TO_PLAN_SUCCESS = `${DOMAIN_PREFIX}fetch_meter_based_work_orders_to_plan_success`;
const FETCH_WORK_ORDERS_OVERDUE_SUCCESS = `${DOMAIN_PREFIX}fetch_work_orders_overdue_success`;
const FETCH_WORK_ORDERS_AWAITING_DELIVERY_SUCCESS = `${DOMAIN_PREFIX}fetch_work_orders_awaiting_delivery_success`;
const FETCH_REQUESTS_SUCCESS = `${DOMAIN_PREFIX}fetch_requests_success`;

export default {
  SHOW_LIST_BAR,
  ADD_INSTANCES_FOR_CALENDAR,
  REMOVE_INSTANCES_FROM_CALENDAR,
  HIDE_LIST_BAR,
  ADD_ASSIGNEES_FOR_INSTANCE,
  REMOVE_ASSIGNEES_FROM_INSTANCE,
  BEGIN_DRAG,
  END_DRAG,
  SHOW_WORK_ORDER_PREVIEW_OVERLAY,
  HIDE_WORK_ORDER_PREVIEW_OVERLAY,
  SHOW_REQUEST_PREVIEW_OVERLAY,
  HIDE_REQUEST_PREVIEW_OVERLAY,
  SET_ASSIGNEES,
  FETCH_DATA_BETWEEN_DATES_SUCCESS,
  HOVER_CALENDAR_INSTANCE,
  UPDATE_INSTANCE,
  FETCH_REQUESTS_SUCCESS,
  FETCH_METER_BASED_WORK_ORDERS_TO_PLAN_SUCCESS,
  FETCH_WORK_ORDERS_TO_PLAN_SUCCESS,
  FETCH_WORK_ORDERS_OVERDUE_SUCCESS,
  FETCH_WORK_ORDERS_AWAITING_DELIVERY_SUCCESS,
  SET_COUNTS,
  BEGIN_DRAG_FROM_TOOLBOX,
  END_DRAG_FROM_TOOLBOX,
};
