import types from './types';
import update from 'immutability-helper';
import { mergeWith } from 'lodash-es';
import { MenuTypes } from 'state/ducks/menu';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  pagination: {
    totalEntries: 0,
    totalPages: 1,
  },
  queryParams: {
    page: 1,
    page_size: 25,
  },
  selectedDowntimes: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesIsSelected: false,
    ids: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }

    case types.FETCH_DOWNTIMES_SUCCESS: {
      const { ids, pagination } = action.payload;
      let allRowsAreSelected;
      if (state.selectedDowntimes.totalEntriesIsSelected) {
        allRowsAreSelected = true;
      } else {
        allRowsAreSelected = ids.length > 0 && ids.every(id => state.selectedDowntimes.ids[id]);
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        selectedDowntimes: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    }

    case SDKReduxTypes.DOWNTIME_CREATED: {
      const { data } = action.payload;
      return { ...state, ids: [data.id, ...state.ids] };
    }
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { downtimeId } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(id => id !== downtimeId),
      };
    }

    case types.SELECT_PAGE: {
      if (state.selectedDowntimes.pageIsSelected) {
        return update(state, {
          selectedDowntimes: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedDowntimes.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesIsSelected: {
              $set: false,
            },
          },
        });
      }

      return update(state, {
        selectedDowntimes: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedDowntimes.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedDowntimes.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedDowntimes.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_DOWNTIME: {
      const downtimeId = action.payload;
      if (state.selectedDowntimes.ids[downtimeId]) {
        return update(state, {
          selectedDowntimes: {
            ids: {
              $unset: [downtimeId],
            },
            totalEntriesIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== downtimeId)
          .every(id => state.selectedDowntimes.ids[id] === true);
        return update(state, {
          selectedDowntimes: {
            ids: {
              [downtimeId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }

    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedDowntimes: {
          totalEntriesIsSelected: {
            $set: true,
          },
        },
      });
    }

    case types.RESET_SELECTED_DOWNTMES: {
      return update(state, {
        selectedDowntimes: {
          $set: {
            ...INITIAL_STATE.selectedDowntimes,
          },
        },
      });
    }

    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedDowntimes: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesIsSelected: {
            $set: false,
          },
        },
      });
    }

    default:
      return state;
  }
};
