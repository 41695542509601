import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import ApplyFilter from './ApplyFilter';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from './AppliedFilters';

export const FILTER_TYPES = {
  RequestTitle: 'title',
  RequestDescription: 'description',
  Status: 'status',
  Asset: 'asset_id',
  AssetWithTreeChildren: 'asset_with_tree_children_id',
  AssetType: 'asset_type_id',
  RequestType: 'request_type_id',
  Priority: 'priority',
  CreatedBy: 'created_by_user_id',
  CreatedAt: 'created_at',
  ArchivedBy: 'archived_by_user_id',
  ApprovedByProductionSupervisor: 'approved_by_production_supervisor_user_id',
  ArchivedComment: 'archived_comment',
  WorkOrderCompletedDate: 'work_order_completed_date',
};

class FilterModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open) {
      this.props.selectFilterType(null);
      if (this.props.isFiltering) {
        this.props.showAppliedFilters();
      } else {
        this.props.showAvailableFilters();
      }
    }
  }

  /*
    Helper functions
  */

  close = params => {
    this.props.onClose(params);
  };

  /*
    Rener functions
  */

  renderContent = () => {
    if (this.props.selectedFilterType) {
      return <ApplyFilter />;
    }
    if (this.props.showingAppliedFilters) {
      return <AppliedFilters onClose={() => this.close()} />;
    }
    return <AvailableFilters onClose={() => this.close()} />;
  };

  renderFooter = () => {
    if (this.props.showingAppliedFilters) {
      return (
        <Button.Group>
          <Button
            primary
            onClick={() => {
              this.close(this.props.filtersAsQueryParams);
            }}
            label="screens.requests.filters.advanced.title"
          />
          <Button
            translate={false}
            label={<FormattedMessage id="general.clean" />}
            onClick={() => {
              this.props.resetFilter();
              this.close();
            }}
          />
        </Button.Group>
      );
    }
    return null;
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        width={485}
        footerComponent={this.renderFooter()}
        onClose={() => this.close(this.props.filtersAsQueryParams)}
      >
        <SideModal.Container>{this.renderContent()}</SideModal.Container>
      </SideModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: RequestsOperations.selectFilterType,
      resetFilter: RequestsOperations.resetFilter,
      showAppliedFilters: RequestsOperations.showAppliedFilters,
      showAvailableFilters: RequestsOperations.showAvailableFilters,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filtersAsQueryParams: RequestsSelectors.getFiltersAsQueryParams(state),
    selectedFilterType: RequestsSelectors.getSelectedFilterType(state),
    showingAppliedFilters: RequestsSelectors.showingAppliedFilters(state),
    filters: RequestsSelectors.getFilters(state),
    isFiltering: RequestsSelectors.isFiltering(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
