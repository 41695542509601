import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import * as moment from 'moment';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldErrorWrapper, Button } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import AssetField from './AssetField';
import ReasonField from './ReasonField';

class Edit extends Component {
  generateNewDowntime = props => ({
    asset_id: props.assetId,
    from: `${moment().format('YYYY-MM-DD')} ${moment().format('HH:mm')}`,
    to: `${moment().format('YYYY-MM-DD')} ${moment().add(1, 'minute').format('HH:mm')}`,
    ...props.defaultParams,
  });

  constructor(props) {
    super(props);

    this.state = {
      showCostPerHourModal: false,
      showTotalCostModal: false,
      showFromDateRequiredError: false,
      showToDateRequiredError: false,
      showFromDateLargerThanToDateError: false,
      showToDateSmallerThanFromDateError: false,
      showAssetHasActiveDowntimeError: false,
      hasUnsavedChanges: false,
      downtime: props.new ? this.generateNewDowntime(props) : { ...props.downtime, ...props.defaultParams },
      downtimeBeforeEdit: props.new ? this.generateNewDowntime(props) : { ...props.downtime },
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  cancelEdit = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onCancel();
      }
    } else {
      this.props.onCancel();
    }
  };

  setDowntimeValue = values => {
    const downtime = { ...this.state.downtime, ...values };

    const hasUnsavedChanges = !isEqual(this.state.downtimeBeforeEdit, downtime);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      downtime,
      hasUnsavedChanges,
    });
  };

  getEditingChanges = () => {
    let data = {};
    const downtime = this.state.downtime;
    const diffProps = Object.keys(downtime).filter(k => downtime[k] !== this.state.downtimeBeforeEdit[k]);
    diffProps.forEach(key => {
      if (key === 'from' || key === 'to') {
        data[key] = moment(downtime[key], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm');
      } else {
        data[key] = downtime[key];
      }
    });
    return data;
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  updateDowntime = () => {
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .updateDowntime(this.props.downtime.id, this.getEditingChanges(), {
        asset_id: this.props.asset ? this.props.asset.id : null,
      })
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="components.downtime-modal.update-success" />} />
        );
        this.props.onCancel();
      })
      .catch(e => {
        this.setState({ isSaving: false });
        if (HelperFunctions.hasError(e, { code: '30009', key: 'asset_id' })) {
          this.setState({ showAssetHasActiveDowntimeError: true });
        }
      });
  };

  createDowntime = () => {
    let params = {
      from: moment(this.state.downtime.from, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm'),
      downtime_reason_id: this.state.downtime.downtime_reason_id,
      asset_id: this.state.downtime.asset_id,
    };

    if (this.props.isCreatingActiveDowntime !== true) {
      params = {
        ...params,
        to: moment(this.state.downtime.to, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm'),
      };
    }
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .createDowntime(
        this.props.currentSystem.id,
        {
          ...params,
          ...this.props.defaultParams,
        },
        { work_order_id: this.props.openedFromWorkOrderId }
      )
      .then(({ data: downtime }) => {
        toast(
          <ToastMessage success text={<FormattedMessage id="components.downtime-modal.create-success" />} />
        );
        this.props.onCancel();
      })
      .catch(e => {
        this.setState({ isSaving: false });
        if (HelperFunctions.hasError(e, { code: '30009', key: 'asset_id' })) {
          this.setState({ showAssetHasActiveDowntimeError: true });
        }
      });
  };

  save = () => {
    if (this.props.new) {
      this.createDowntime();
    } else {
      this.updateDowntime();
    }
  };

  renderTimeFields = () => {
    if (this.props.isCreatingActiveDowntime) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.downtime.started-at" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
                errorElement={
                  this.state.showFromDateRequiredError ? (
                    <FormattedMessage id="general.errors.is-required" />
                  ) : (
                    <FormattedMessage id="components.downtime-modal.errors.from-date-in-future" />
                  )
                }
              >
                <Field.DateTime
                  error={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
                  onChangeDate={date => {
                    if (!date) {
                      this.setState({ showFromDateRequiredError: true });
                    } else {
                      if (this.state.showFromDateRequiredError) {
                        this.setState({
                          showFromDateRequiredError: false,
                        });
                      }
                      const newFromDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(
                        this.state.downtime.from
                      ).format('HH:mm')}`;
                      if (moment(newFromDateTimeString).isAfter(moment(this.state.downtime.to))) {
                        this.setState({ showFromDateLargerThanToDateError: true });
                      } else if (this.state.showFromDateLargerThanToDateError) {
                        this.setState({
                          showFromDateLargerThanToDateError: false,
                          showToDateSmallerThanFromDateError: false,
                        });
                      }
                      this.setDowntimeValue({
                        from: newFromDateTimeString,
                      });
                    }
                  }}
                  onChangeTime={time => {
                    if (!time) {
                      this.setState({
                        showFromDateRequiredError: true,
                      });
                    } else {
                      if (this.state.showFromDateRequiredError) {
                        this.setState({
                          showFromDateRequiredError: false,
                        });
                      }
                      const newFromDateTimeString = `${moment(this.state.downtime.from).format(
                        'YYYY-MM-DD'
                      )} ${moment(time, 'HH:mm').format('HH:mm')}`;
                      if (moment(newFromDateTimeString).isAfter(moment(this.state.downtime.to))) {
                        this.setState({ showFromDateLargerThanToDateError: true });
                      } else if (this.state.showFromDateLargerThanToDateError) {
                        this.setState({
                          showFromDateLargerThanToDateError: false,
                          showToDateSmallerThanFromDateError: false,
                        });
                      }
                      this.setDowntimeValue({
                        from: newFromDateTimeString,
                      });
                    }
                  }}
                  date={moment(this.state.downtime.from).format('YYYY-MM-DD')}
                  time={moment(this.state.downtime.from).format('HH:mm')}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return (
      <Grid.Row>
        <Grid.Column md={6}>
          <Field label={<FormattedMessage id="resources.downtime.started-at" />}>
            <FieldErrorWrapper
              position="top"
              show={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
              errorElement={
                this.state.showFromDateRequiredError ? (
                  <FormattedMessage id="general.errors.is-required" />
                ) : (
                  <FormattedMessage id="components.downtime-modal.errors.from-date-larger-than-to-date" />
                )
              }
            >
              <Field.DateTime
                error={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
                onChangeDate={date => {
                  if (!date) {
                    this.setState({ showFromDateRequiredError: true });
                  } else {
                    if (this.state.showFromDateRequiredError) {
                      this.setState({
                        showFromDateRequiredError: false,
                      });
                    }
                    const newFromDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(
                      this.state.downtime.from
                    ).format('HH:mm')}`;
                    const toMoment =
                      this.state.downtime.to == null ? moment() : moment(this.state.downtime.to);
                    if (moment(newFromDateTimeString).isAfter(toMoment)) {
                      this.setState({ showFromDateLargerThanToDateError: true });
                    } else if (this.state.showFromDateLargerThanToDateError) {
                      this.setState({
                        showFromDateLargerThanToDateError: false,
                        showToDateSmallerThanFromDateError: false,
                      });
                    }
                    this.setDowntimeValue({
                      from: newFromDateTimeString,
                    });
                  }
                }}
                onChangeTime={time => {
                  if (!time) {
                    this.setState({
                      showFromDateRequiredError: true,
                    });
                  } else {
                    if (this.state.showFromDateRequiredError) {
                      this.setState({
                        showFromDateRequiredError: false,
                      });
                    }
                    const newFromDateTimeString = `${moment(this.state.downtime.from).format(
                      'YYYY-MM-DD'
                    )} ${moment(time, 'HH:mm').format('HH:mm')}`;

                    const toMoment =
                      this.state.downtime.to == null ? moment() : moment(this.state.downtime.to);
                    if (moment(newFromDateTimeString).isAfter(toMoment)) {
                      this.setState({ showFromDateLargerThanToDateError: true });
                    } else if (this.state.showFromDateLargerThanToDateError) {
                      this.setState({
                        showFromDateLargerThanToDateError: false,
                        showToDateSmallerThanFromDateError: false,
                      });
                    }
                    this.setDowntimeValue({
                      from: newFromDateTimeString,
                    });
                  }
                }}
                date={moment(this.state.downtime.from).format('YYYY-MM-DD')}
                time={moment(this.state.downtime.from).format('HH:mm')}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
        <Grid.Column md={6}>
          <Field label={<FormattedMessage id="resources.downtime.ended-at" />}>
            <FieldErrorWrapper
              position="top"
              show={this.state.showToDateRequiredError || this.state.showToDateSmallerThanFromDateError}
              errorElement={
                this.state.showToDateRequiredError ? (
                  <FormattedMessage id="general.errors.is-required" />
                ) : (
                  <FormattedMessage id="components.downtime-modal.errors.to-date-smaller-than-from-date" />
                )
              }
            >
              <Field.DateTime
                error={this.state.showToDateRequiredError || this.state.showToDateSmallerThanFromDateError}
                onChangeDate={date => {
                  if (!date) {
                    this.setState({ showToDateRequiredError: true });
                  } else {
                    if (this.state.showToDateRequiredError) {
                      this.setState({
                        showToDateRequiredError: false,
                      });
                    }
                    let newToDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(
                      this.state.downtime.to
                    ).format('HH:mm')}`;
                    if (moment(newToDateTimeString).isValid() === false) {
                      newToDateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment().format(
                        'HH:mm'
                      )}`;
                    }

                    if (moment(newToDateTimeString).isBefore(moment(this.state.downtime.from))) {
                      this.setState({ showToDateSmallerThanFromDateError: true });
                    } else if (this.state.showToDateSmallerThanFromDateError) {
                      this.setState({
                        showToDateSmallerThanFromDateError: false,
                        showFromDateLargerThanToDateError: false,
                      });
                    }
                    this.setDowntimeValue({
                      to: newToDateTimeString,
                    });
                  }
                }}
                onChangeTime={time => {
                  if (!time) {
                    this.setState({ showToDateRequiredError: true });
                  } else {
                    if (this.state.showToDateRequiredError) {
                      this.setState({
                        showToDateRequiredError: false,
                      });
                    }
                    let newToDateTimeString = `${moment(this.state.downtime.to).format(
                      'YYYY-MM-DD'
                    )} ${moment(time, 'HH:mm').format('HH:mm')}`;
                    if (moment(newToDateTimeString).isValid() === false) {
                      newToDateTimeString = `${moment().format('YYYY-MM-DD')} ${moment(time, 'HH:mm').format(
                        'HH:mm'
                      )}`;
                    }
                    if (moment(newToDateTimeString).isBefore(moment(this.state.downtime.from))) {
                      this.setState({ showToDateSmallerThanFromDateError: true });
                    } else if (this.state.showToDateSmallerThanFromDateError) {
                      this.setState({
                        showToDateSmallerThanFromDateError: false,
                        showFromDateLargerThanToDateError: false,
                      });
                    }

                    this.setDowntimeValue({
                      to: newToDateTimeString,
                    });
                  }
                }}
                date={moment(this.state.downtime.to).format('YYYY-MM-DD')}
                time={moment(this.state.downtime.to).format('HH:mm')}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderInfo = () => (
    <>
      <div>
        <Grid>
          {this.renderTimeFields()}
          <Grid.Row>
            <Grid.Column>
              <AssetField
                error={this.state.showAssetHasActiveDowntimeError}
                defaultTreeParentId={this.props.defaultTreeParentId}
                value={this.state.downtime.asset_id}
                onChange={assetId => {
                  this.setState({ showAssetHasActiveDowntimeError: false });
                  this.setDowntimeValue({ asset_id: assetId });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ReasonField
                value={this.state.downtime.downtime_reason_id}
                onChange={downtime_reason_id => this.setDowntimeValue({ downtime_reason_id })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );

  render() {
    return (
      <>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={
            this.props.new ? (
              <FormattedMessage id="components.downtime-modal.new-title" />
            ) : (
              <FormattedMessage id="components.downtime-modal.edit-title" />
            )
          }
          subtitle={this.props.new ? null : moment(this.props.downtime.from).format('LLL')}
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderInfo()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={
                this.state.showFromDateRequiredError ||
                this.state.showToDateRequiredError ||
                this.state.showFromDateLargerThanToDateError ||
                this.state.showToDateSmallerThanFromDateError ||
                !this.state.downtime.asset_id
              }
              loading={this.state.isSaving}
              onClick={this.save}
              label="general.save"
            />
            <Button label="general.cancel" onClick={this.cancelEdit} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDowntime: SDKReduxOperations.updateDowntime,
      createDowntime: SDKReduxOperations.createDowntime,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.new)
    return {
      currentSystem: AuthSelectors.getCurrentSystem(state),
      canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    };
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    asset: EntitySelectors.getAsset(state, downtime.asset_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit));
