import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, FieldErrorWrapper } from 'views/components/Shared/General';
import { Input, Button as LoginButton, Layout } from 'views/components/Login';
import styles from './style.module.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      password: '',
      confirmPassword: '',
      isResettingPasword: false,
      showPasswordHasBeenResetted: false,
      ticketHasExpired: false,
      showPasswordNotMatchingError: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Reset Password');
    const ticket = this.props.match.params.ticket;
    API.validateResetPassword(ticket)
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {
        this.setState({ ticketHasExpired: true, isFetching: false });
      });
  }

  resetPassword = () => {
    const { password } = this.state;
    const token = this.props.match.params.ticket;
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        showPasswordNotMatchingError: true,
      });
      return;
    }
    this.setState({ isResettingPasword: true });
    API.resetPassword({ password, token })
      .then(() => {
        this.setState({ isResettingPasword: false, showPasswordHasBeenResetted: true });
      })
      .catch(() => {
        this.setState({ isResettingPasword: false });
      });
  };

  renderExpiredTicket = () => (
    <>
      <div className={styles['text']}>Expired ticket</div>
      <div className={styles['subtitle']}>
        This ticket has expired, go to forgot password to request a new password
      </div>
      <div className={styles['button-container']}>
        <Link to="/forgot-password">
          <LoginButton label="Go to Forgot Password" />
        </Link>
      </div>
    </>
  );

  renderPasswordHasBeenReset = () => (
    <>
      <div className={styles['text']}>Your password has been resetted</div>
      <div className={styles['subtitle']}>Your password has been successfully changed</div>
      <div className={styles['button-container']}>
        <LoginButton
          label="Go back to sign in"
          onClick={() => this.props.history.push('/login')}
        />
      </div>
    </>
  );

  renderResetPasssword = () => {
    if (this.state.showPasswordHasBeenResetted) {
      return this.renderPasswordHasBeenReset();
    }
    return (
      <>
        <div className={styles['text']}>Reset password</div>
        <div className={styles['subtitle']}>Reset your password and login to Mainter</div>
        <div className={styles['input-container']}>
          <Input
            type="password"
            autocomplete="new-password"
            autoFocus
            placeholder="New password"
            fluid
            value={this.state.password}
            onChange={password => {
              this.setState({ password });
            }}
          />
          <FieldErrorWrapper
            show={this.state.showPasswordNotMatchingError}
            errorElement={<span>Passwords are not matching</span>}
          >
            <Input
              type="password"
              autocomplete="new-password"
              placeholder="Confirm password"
              error={this.state.showPasswordNotMatchingError}
              value={this.state.confirmPassword}
              onChange={confirmPassword => {
                this.setState({
                  showPasswordNotMatchingError: false,
                });
                this.setState({ confirmPassword });
              }}
            />
          </FieldErrorWrapper>
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Reset Password"
            disabled={!this.state.password || !this.state.confirmPassword}
            loading={this.state.isResettingPasword}
            onClick={this.resetPassword}
          />
        </div>
      </>
    );
  };

  renderContent = () => {
    if (this.state.ticketHasExpired) {
      return this.renderExpiredTicket();
    }
    return this.renderResetPasssword();
  };

  renderSubtitle = () => {
    if (this.state.ticketHasExpired) {
      return 'This ticket has expired, go to forgot password to request a new password';
    }
    return 'Reset your password and login to Mainter';
  };

  render() {
    if (this.state.isFetching) {
      return null;
    }
    return (
      <Layout>
        {this.renderContent()}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(ResetPassword);
