import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash-es';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import {
  OperationalMaintenancesSelectors,
  OperationalMaintenancesOperations,
  OperationalMaintenancesUtils,
} from 'state/ducks/operationalMaintenances';
import {
  BookmarkedAssetsDropdownSelectors,
  BookmarkedAssetsDropdownOperations,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { Header, LeftPanel } from 'views/scenes/OperationalMaintenances/components';
import { List, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import styles from './style.module.scss';
import TemplateListItem from './TemplateListItem';

class Templates extends Component {
  getInitialState = () => ({
    isFetching: true,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.fetchDebouncedOperationalMaintenanceTemplates = debounce(() => {
      this.fetchOperationalMaintenanceTemplates();
    }, 300);
  }

  componentDidMount() {
    this.props.fetchBookmarkedAssets(this.props.system.id).then(() => {
      this.fetchDebouncedOperationalMaintenanceTemplates();
    });
  }

  componentDidUpdate(prevProps) {
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedBookmarkedAssets = prevProps.bookmarkedAssets.length !== this.props.bookmarkedAssets.length;
    const changedDropdownTypeForAssetDropdown =
      prevProps.dropdownTypeForAssetDropdown !== this.props.dropdownTypeForAssetDropdown;
    const changedAssetIdForAssetDropdown =
      prevProps.assetIdForAssetDropdown !== this.props.assetIdForAssetDropdown;
    if (
      changedQueryParams ||
      changedBookmarkedAssets ||
      changedDropdownTypeForAssetDropdown ||
      changedAssetIdForAssetDropdown
    ) {
      this.setState({ isFetching: true });
      this.fetchDebouncedOperationalMaintenanceTemplates();
    }
  }

  componentWillUnmount() {
    this.fetchDebouncedOperationalMaintenanceTemplates.cancel();
  }

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  getParams = () => {
    const { dropdownTypeForAssetDropdown, assetIdForAssetDropdown, bookmarkedAssets } = this.props;
    let params = {
      asset_count: true,
      ...this.props.queryParameters,
    };
    if (dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
      params = {
        ...params,
        asset_with_tree_children_id: bookmarkedAssets.map(({ asset_id }) => asset_id).join(','),
      };
    } else if (assetIdForAssetDropdown) {
      params = {
        ...params,
        asset_with_tree_children_id: assetIdForAssetDropdown,
      };
    }
    return params;
  };

  fetchOperationalMaintenanceTemplates = () => {
    this.props.fetchOperationalMaintenanceTemplates(this.props.system.id, this.getParams()).then(() => {
      this.setState({ isFetching: false });
    });
  };

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.operational-maintenances.templates.empty-data-set.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.operational-maintenances.templates.empty-data-set.subtitle" />
        </div>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderBookmarkedAssetsEmptyDataset = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={
            <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.subtitle" />
          }
          tiny
          listContainer
        />
      </WhiteCard>
    );
  };

  renderHeader = () => {
    return (
      <List.Header small background>
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <List.Header.Column flex>
        <FormattedMessage id="resources.operational-maintenance-template.title" />
      </List.Header.Column>
    );
  };

  renderList = () => {
    const { dropdownTypeForAssetDropdown, bookmarkedAssets, templates } = this.props;
    if (this.state.isFetching) {
      return (
        <>
          {this.renderHeader()}
          <List>
            <TemplateListItem loading />
            <TemplateListItem loading />
          </List>
        </>
      );
    }
    if (
      dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets &&
      bookmarkedAssets.length === 0
    ) {
      return this.renderBookmarkedAssetsEmptyDataset();
    }
    if (templates.length === 0) {
      return this.renderEmptyDataset();
    }
    return (
      <>
        {this.renderHeader()}
        <List>
          {this.props.templates.map(({ id }) => {
            return <TemplateListItem key={id} id={id} />;
          })}
        </List>
      </>
    );
  };

  renderContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderPagination = () => {
    if (this.props.templates.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  render() {
    return (
      <ListLayout>
        <Header />
        <ListLayout.Content>
          <LeftPanel />
          {this.renderContent()}
        </ListLayout.Content>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenanceTemplates:
        OperationalMaintenancesOperations.fetchOperationalMaintenanceTemplates,
      fetchBookmarkedAssets: BookmarkedAssetsDropdownOperations.fetchBookmarkedAssets,
      addQueryParameter: OperationalMaintenancesOperations.addQueryParameterForTemplates,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    templates: OperationalMaintenancesSelectors.getOperationalMaintenanceTemplates(state),
    pagination: OperationalMaintenancesSelectors.getOperationalMaintenanceTemplatesPagination(state),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    assetIdForAssetDropdown: BookmarkedAssetsDropdownSelectors.getAssetId(state),
    queryParameters: OperationalMaintenancesSelectors.getQueryParametersForTemplates(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Templates));
