import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const resetSelectedMeters = payload => ({ type: types.RESET_SELECTED_METERS, payload });

const selectMeter = payload => ({ type: types.SELECT_METER, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});
const fetchMetersSuccess = data => ({
  type: types.FETCH_METERS_SUCCESS,
  payload: data,
});
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });

export default {
  setNavigatedTo,
  fetchMetersSuccess,
  addQueryParameter,
  selectPage,
  resetSelectedMeters,
  selectMeter,
  hideSelectTotalEntries,
  selectTotalEntries,
};
