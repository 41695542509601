import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button, InlineModal, List, Tooltip, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

class SparePartReservationListItem extends Component {
  state = {
    dropdownOpen: false,
    showNotGeneratedModal: false,
  };

  renderNotGeneratedModal = () => (
    <Modal isOpen={this.state.showNotGeneratedModal} width={500}>
      <Modal.Header
        ignoreLine
        title={<FormattedMessage id="components.reserved-modal.not-generated.title" />}
        subtitle={<FormattedMessage id="components.reserved-modal.not-generated.subtitle" />}
        subtitleTopMargin
        onClose={() => this.setState({ showNotGeneratedModal: false })}
      />
      <Modal.Footer ignoreLine>
        <Button
          primary
          label="general.close"
          onClick={() => this.setState({ showNotGeneratedModal: false })}
        />
      </Modal.Footer>
    </Modal>
  );

  renderRecurringIcon = () => {
    if (
      this.props.recurringMaintenance ||
      (this.props.workOrder && this.props.workOrder.recurring_maintenance_id != null)
    ) {
      return (
        <Tooltip
          trigger={<Icon regular type="repeat" />}
          label={<FormattedMessage id="components.reserved-modal.recurring-work-order" />}
        />
      );
    }
  };

  renderSubtitle = () => {
    if (this.props.workOrder) {
      return (
        <span className={styles['subtitle']}>
          {this.props.workOrder.title} {this.renderRecurringIcon()}
        </span>
      );
    } else if (this.props.recurringMaintenance) {
      return (
        <span className={styles['subtitle']}>
          {this.props.recurringMaintenance.title} {this.renderRecurringIcon()}
        </span>
      );
    }
  };

  renderGoToWorkOrderItem = () => {
    if (this.props.workOrder) {
      return (
        <Link to={`/work-orders/${this.props.workOrder.id}`}>
          <InlineModal.ListItem
            icon="arrow-alt-right"
            iconThickness="regular"
            title={<FormattedMessage id="general.navigate-to.work-order" />}
          />
        </Link>
      );
    } else {
      return (
        <InlineModal.ListItem
          onClick={() => this.setState({ showNotGeneratedModal: true, dropdownOpen: false })}
          icon="arrow-alt-right"
          iconThickness="regular"
          title={<FormattedMessage id="general.navigate-to.work-order" />}
        />
      );
    }
  };

  renderItemButtons = () => (
    <>
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
          }));
        }}
      >
        <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
      </div>
      <InlineModal
        open={this.state.dropdownOpen}
        positionToRef={this.inlineModalPositioningRef}
        onClose={() => this.setState({ dropdownOpen: false })}
        position="right"
      >
        <InlineModal.Body width={250} dropdown>
          {this.renderGoToWorkOrderItem()}
        </InlineModal.Body>
      </InlineModal>
    </>
  );

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.IconColumn icon={<Icon type="wrench" />} loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <React.Fragment>
          <List.Item iconButtons={this.renderItemButtons()}>
            <List.Item.IconColumn icon={<Icon regular type="wrench" />} />
            <List.Item.TitleColumn
              flex
              title={moment(this.props.date).format('LL')}
              subtitle={this.renderSubtitle()}
            />
            <List.Item.Column width={100} alignRight borderRight>
              <SparePartUnitWithValue
                sparePartUnit={this.props.sparePartUnit}
                value={
                  this.props.sparePartReservation.quantity -
                  this.props.sparePartReservation.withdrawed_quantity
                }
              />
            </List.Item.Column>
          </List.Item>
          {this.renderNotGeneratedModal()}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const sparePartReservation = EntitySelectors.getSparePartReservation(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id);
  return {
    sparePartReservation,
    sparePart,
    workOrder: EntitySelectors.getWorkOrder(state, sparePartReservation.work_order_id),
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(
      state,
      sparePartReservation.recurring_maintenance_id
    ),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(SparePartReservationListItem);
