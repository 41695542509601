import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { chunk, isEqual } from 'lodash-es';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { WorkOrderStatus, WorkOrderCategory } from 'sdk/WorkOrder';
import { WorkOrderCategoryType } from 'sdk/System';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { Field, ChooseAssigneeInlineModal, EstimatedTimeInlineModal } from 'views/components/WorkOrder';
import {
  Icon,
  Button,
  DatePicker,
  Loader,
  SectionHeader,
  FormattedNumber,
  ElapsedTime,
} from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import SelectedAssigneesValue from './SelectedAssigneesValue';
import ChecklistInstanceListItem from './ChecklistInstanceListItem';
import ChecklistTemplateLinkListItem from './ChecklistTemplateLinkListItem';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class PreviewWorkOrderContent extends Component {
  getInitialState = props => {
    if (props.workOrderId) {
      return {
        showDatePicker: false,
        showEditEstimatedTimeErrorModal: false,
        due_date: props.workOrder.due_date,
        editing_estimated_minutes: props.workOrder.estimated_minutes,
        estimated_minutes: props.workOrder.estimated_minutes,
        editing_assigned_to_users: props.workOrder.assigned_to_users || [],
        editing_assigned_to_groups: props.workOrder.assigned_to_groups || [],
        editing_assigned_to_vendors: props.workOrder.assigned_to_vendors || [],
        assigned_to_users: props.workOrder.assigned_to_users,
        assigned_to_groups: props.workOrder.assigned_to_groups,
        assigned_to_vendors: props.workOrder.assigned_to_vendors,
        errors: {
          assignee: false,
          estimated_minutes: false,
          due_date: false,
        },
      };
    }
    return {
      showDatePicker: false,
      showEditEstimatedTimeErrorModal: false,
      due_date: props.date,
      editing_estimated_minutes: props.recurringMaintenance.estimated_minutes,
      estimated_minutes: props.recurringMaintenance.estimated_minutes,
      editing_assigned_to_users: props.userIds || [],
      editing_assigned_to_groups: props.groupIds || [],
      editing_assigned_to_vendors: props.vendorIds || [],
      assigned_to_users: props.userIds,
      assigned_to_groups: props.groupIds,
      assigned_to_vendors: props.vendorIds,
      errors: {
        assignee: false,
        estimated_minutes: false,
        due_date: false,
      },
    };
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.workOrderId !== this.props.workOrderId ||
      prevProps.recurringMaintenanceId !== this.props.recurringMaintenanceId
    ) {
      this.setState(this.getInitialState(this.props));
    }
  }

  renderAssigneeFieldIconContainer = () => {
    const userLength = this.props.userAssignees.length;
    const groupLength = this.props.groupAssignees.length;
    const vendorLength = this.props.vendorAssignees.length;

    if (vendorLength > 0) {
      return <Icon type="briefcase" withBackground blue backgroundSize={30} />;
    } else if (groupLength > 0) {
      return <Icon type="users" withBackground blue backgroundSize={30} />;
    } else if (userLength === 1) {
      return (
        <ProfilePicture
          size={30}
          userId={this.props.userAssignees[0].id}
          defaultElement={<Icon type="user" withBackground blue backgroundSize={30} />}
        />
      );
    } else {
      return <Icon type="user" withBackground blue backgroundSize={30} />;
    }
  };

  renderAssigneeField = () => {
    const { editing_assigned_to_users, editing_assigned_to_groups, editing_assigned_to_vendors } = this.state;
    let editable = this.props.editable;
    if (this.props.workOrder && this.props.workOrder.status === WorkOrderStatus.Completed) {
      editable = false;
    }
    if (editable) {
      return (
        <>
          <ChooseAssigneeInlineModal
            hideArchivedUsers
            multiple
            trigger={
              <div className={`${styles['field']} ${styles['editable']} ${styles['assignee']}`}>
                <Field
                  small
                  error={this.state.errors.assignee}
                  icon={this.renderAssigneeFieldIconContainer()}
                  label={<FormattedMessage id="resources.work-order.assignee" />}
                  value={
                    <SelectedAssigneesValue
                      userIds={editing_assigned_to_users}
                      groupIds={editing_assigned_to_groups}
                      vendorIds={editing_assigned_to_vendors}
                    />
                  }
                />
                {this.props.isSavingAssignees ? (
                  <div className={styles['loader-container']}>
                    <Loader tiny />
                  </div>
                ) : null}
              </div>
            }
            selectedUserIds={this.state.editing_assigned_to_users}
            selectedGroupIds={this.state.editing_assigned_to_groups}
            selectedVendorIds={this.state.editing_assigned_to_vendors}
            onAddUser={({ id }) => {
              this.setState({
                errors: { ...this.state.errors, assignee: false },
                editing_assigned_to_users: [...this.state.editing_assigned_to_users, id],
              });
            }}
            onRemoveUser={({ id }) => {
              this.setState({
                editing_assigned_to_users: this.state.editing_assigned_to_users.filter(
                  userId => userId !== id
                ),
              });
            }}
            onAddGroup={({ id }) => {
              this.setState({
                errors: { ...this.state.errors, assignee: false },
                editing_assigned_to_groups: [...this.state.editing_assigned_to_groups, id],
              });
            }}
            onRemoveGroup={({ id }) => {
              this.setState({
                editing_assigned_to_groups: this.state.editing_assigned_to_groups.filter(
                  groupId => groupId !== id
                ),
              });
            }}
            onAddVendor={({ id }) => {
              this.setState({
                errors: { ...this.state.errors, assignee: false },
                editing_assigned_to_vendors: [...this.state.editing_assigned_to_vendors, id],
              });
            }}
            onRemoveVendor={({ id }) => {
              this.setState({
                editing_assigned_to_vendors: this.state.editing_assigned_to_vendors.filter(
                  vendorId => vendorId !== id
                ),
              });
            }}
            onClose={() => {
              const { work_order_assignee_required } = this.props.settings;

              const { editing_assigned_to_users, editing_assigned_to_groups, editing_assigned_to_vendors } =
                this.state;
              const { assigned_to_users, assigned_to_groups, assigned_to_vendors } = this.state;
              if (
                work_order_assignee_required &&
                editing_assigned_to_users.length === 0 &&
                editing_assigned_to_groups.length === 0 &&
                editing_assigned_to_vendors.length === 0
              ) {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    assignee: true,
                  },
                });
                return;
              }
              if (
                !isEqual(editing_assigned_to_users, assigned_to_users) ||
                !isEqual(editing_assigned_to_groups, assigned_to_groups) ||
                !isEqual(editing_assigned_to_vendors, assigned_to_vendors)
              ) {
                this.setState(
                  {
                    assigned_to_users: editing_assigned_to_users,
                    assigned_to_groups: editing_assigned_to_groups,
                    assigned_to_vendors: editing_assigned_to_vendors,
                  },
                  () => {
                    const { assigned_to_users, assigned_to_groups, assigned_to_vendors } = this.state;
                    this.props.onSaveAssignees({
                      assigned_to_users,
                      assigned_to_groups,
                      assigned_to_vendors,
                    });
                  }
                );
              }
            }}
          />
        </>
      );
    }
    return (
      <div className={`${styles['field']} ${styles['assignee']}`}>
        <Field
          small
          icon={this.renderAssigneeFieldIconContainer()}
          label={<FormattedMessage id="resources.work-order.assignee" />}
          value={
            <SelectedAssigneesValue
              userIds={editing_assigned_to_users}
              groupIds={editing_assigned_to_groups}
              vendorIds={editing_assigned_to_vendors}
            />
          }
        />
      </div>
    );
  };

  renderRecurringMessage = () => {
    if (this.props.recurringMaintenance == null) return null;
    if (this.props.recurringMaintenance.status === 'active') {
      return buildIntervalMessage(this.props.recurringMaintenance, this.props.intl);
    } else {
      return <FormattedMessage id="resources.work-order.interval.canceled" />;
    }
  };

  renderMeterDueValue = () => {
    if (this.state.due_date) {
      return (
        <div className={styles['meter-value']}>
          <div>
            <span>{moment(this.state.due_date).format('LL')}</span>
          </div>
          <div className={`${styles['subtitle']} ${styles['small']}`}>
            <span>
              <FormattedNumber
                value={this.props.workOrder.due_meter_value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            </span>
            <span> </span>
            <span>
              (
              <FormattedMessage id="general.now" />
              <span> </span>
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
              )
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['meter-value']}>
        <div>
          <span>
            <FormattedNumber
              value={this.props.workOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
            <span> </span>
            <span className={styles['subtitle']}>
              (
              <FormattedMessage id="general.now" />
              <span> </span>
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
              )
            </span>
          </span>
        </div>
        {this.props.workOrder.estimated_meter_due_date ? (
          <div>
            <span className={`${styles['subtitle']} ${styles['small']}`}>
              <FormattedMessage id="resources.work-order.estimated-meter-due-date-short" />
              <span>: </span>
              <span>{moment(this.props.workOrder.estimated_meter_due_date).format('LL')}</span>
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  renderSelectedDate = () => {
    if (this.props.meter) {
      return this.renderMeterDueValue();
    }
    if (this.state.due_date) {
      return moment(this.state.due_date).format('LL');
    }
    return '-';
  };

  renderDueDateField = () => {
    let editable = this.props.editable;
    if (this.props.workOrder && this.props.workOrder.status === WorkOrderStatus.Completed) {
      editable = false;
    }
    if (editable) {
      return (
        <div>
          <DatePicker.Day
            open={this.state.showDatePicker}
            date={this.state.due_date}
            onSelectDate={due_date => {
              this.setState({
                due_date,
                errors: { ...this.state.errors, due_date: false },
                showDatePicker: false,
              });
              this.props.onSaveDate(due_date);
            }}
            onClose={() => {
              this.setState({ showDatePicker: false });
            }}
            trigger={
              <div
                className={`${styles['field']} ${styles['editable']} ${styles['date']}`}
                onClick={() => {
                  this.setState(prevState => ({
                    showDatePicker: !prevState.showDatePicker,
                  }));
                }}
              >
                <Field
                  small
                  error={this.state.errors.due_date}
                  icon={<Icon type="calendar-alt" withBackground blue backgroundSize={30} />}
                  label={<FormattedMessage id="resources.work-order.due-date" />}
                  value={this.renderSelectedDate()}
                />
                {this.props.isSavingDueDate ? (
                  <div className={styles['loader-container']}>
                    <Loader tiny />
                  </div>
                ) : null}
              </div>
            }
            footerComponent={
              this.props.recurringMaintenanceId ? null : (
                <DatePicker.Footer
                  showClear={this.state.due_date != null}
                  onClear={() => {
                    const { work_order_due_date_required } = this.props.settings;
                    this.setState({ due_date: null, showDatePicker: false });
                    if (work_order_due_date_required) {
                      this.setState({
                        errors: {
                          ...this.state.errors,
                          due_date: true,
                        },
                      });
                    } else {
                      this.props.onSaveDate(null);
                    }
                  }}
                />
              )
            }
          />
        </div>
      );
    }
    return (
      <div className={`${styles['field']} ${styles['date']}`}>
        <Field
          small
          icon={<Icon type="calendar-alt" withBackground blue backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.due-date" />}
          value={this.renderSelectedDate()}
        />
      </div>
    );
  };

  renderEstimatedTimefield = () => {
    let editable = this.props.editable;
    if (this.props.recurringMaintenanceId) {
      return (
        <div
          className={`${styles['field']} ${styles['editable']} ${styles['estimated-time']}`}
          onClick={() => this.setState({ showEditEstimatedTimeErrorModal: true })}
        >
          <Field
            small
            icon={<Icon type="clock" withBackground orange backgroundSize={30} />}
            label={<FormattedMessage id="resources.work-order.estimated-time" />}
            value={
              this.state.estimated_minutes == null ? (
                '-'
              ) : (
                <ElapsedTime elapsedMinutes={this.state.estimated_minutes} />
              )
            }
          />
        </div>
      );
    }
    if (this.props.workOrder && this.props.workOrder.status === WorkOrderStatus.Completed) {
      editable = false;
    }
    if (editable) {
      let estimatedMinutesBeforeEdit = null;
      if (this.props.workOrderId) {
        estimatedMinutesBeforeEdit = this.props.workOrder.estimated_minutes;
      } else {
        estimatedMinutesBeforeEdit = this.props.recurringMaintenance.estimated_minutes;
      }
      return (
        <div>
          <EstimatedTimeInlineModal
            showSaveButton
            estimatedMinutes={this.state.estimated_minutes}
            onClose={() => {
              this.setState({ estimated_minutes: this.state.editing_estimated_minutes }, () => {
                if (
                  estimatedMinutesBeforeEdit !== this.state.estimated_minutes &&
                  this.state.errors.estimated_minutes !== true
                ) {
                  this.props.onSaveEstimatedTime(this.state.estimated_minutes);
                }
              });
            }}
            onChange={editing_estimated_minutes => {
              const { work_order_estimated_time_required } = this.props.settings;
              this.setState({
                editing_estimated_minutes,
                errors: {
                  ...this.state.errors,
                  estimated_minutes: work_order_estimated_time_required && editing_estimated_minutes == null,
                },
              });
            }}
            trigger={
              <div className={`${styles['field']} ${styles['editable']} ${styles['estimated-time']}`}>
                <Field
                  small
                  error={this.state.errors.estimated_minutes}
                  icon={<Icon type="clock" withBackground orange backgroundSize={30} />}
                  label={<FormattedMessage id="resources.work-order.estimated-time" />}
                  value={
                    this.state.estimated_minutes == null ? (
                      '-'
                    ) : (
                      <ElapsedTime elapsedMinutes={this.state.estimated_minutes} />
                    )
                  }
                />
                {this.props.isSavingEstimatedTime ? (
                  <div className={styles['loader-container']}>
                    <Loader tiny />
                  </div>
                ) : null}
              </div>
            }
          />
        </div>
      );
    }
    return (
      <div className={`${styles['field']} ${styles['estimated-time']}`}>
        <Field
          small
          icon={<Icon type="clock" withBackground orange backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.estimated-time" />}
          value={
            this.state.estimated_minutes == null ? (
              '-'
            ) : (
              <ElapsedTime elapsedMinutes={this.state.estimated_minutes} />
            )
          }
        />
      </div>
    );
  };

  renderAssetField = () => {
    const { asset } = this.props;
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="box-alt" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.asset" />}
          value={
            <>
              <AssetTitle id={asset.id} />
              <TreePath assetId={asset.id} />
            </>
          }
        />
      </div>
    );
  };

  renderSelectedCategory = () => {
    let category = null;
    if (this.props.workOrderId) {
      category = this.props.workOrder.category;
    } else {
      category = this.props.recurringMaintenance.category;
    }
    switch (category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderCategoryField = () => {
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="wrench" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.category" />}
          value={this.renderSelectedCategory()}
        />
      </div>
    );
  };

  renderWorkOrderTypeField = () => {
    const { workOrderType } = this.props;
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="wrench" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.work-order-type" />}
          value={workOrderType.title}
        />
      </div>
    );
  };

  renderRecurringField = () => {
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="repeat" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.work-order.recurring" />}
          value={this.renderRecurringMessage()}
        />
      </div>
    );
  };

  getWorkOrderFields = () => {
    const { workOrder, workOrderType, asset } = this.props;
    let children = [];
    children = [...children, this.renderAssigneeField()];
    children = [...children, this.renderDueDateField()];
    children = [...children, this.renderEstimatedTimefield()];
    if (asset) {
      children = [...children, this.renderAssetField()];
    }
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (workOrder.category) {
        children = [...children, this.renderCategoryField()];
      }
    }
    if (workOrderType) {
      children = [...children, this.renderWorkOrderTypeField()];
    }
    if (workOrder.recurring_maintenance_id) {
      children = [...children, this.renderRecurringField()];
    }
    return children;
  };

  getRecurringMaintenanceFields = () => {
    const { recurringMaintenance, workOrderType, asset } = this.props;
    let children = [];
    children = [...children, this.renderAssigneeField()];
    children = [...children, this.renderDueDateField()];
    children = [...children, this.renderEstimatedTimefield()];
    if (asset) {
      children = [...children, this.renderAssetField()];
    }
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (recurringMaintenance.category) {
        children = [...children, this.renderCategoryField()];
      }
    }
    if (workOrderType) {
      children = [...children, this.renderWorkOrderTypeField()];
    }
    children = [...children, this.renderRecurringField()];
    return children;
  };

  renderFields = () => {
    let fields = [];
    if (this.props.workOrderId) {
      fields = this.getWorkOrderFields();
    } else {
      fields = this.getRecurringMaintenanceFields();
    }
    if (fields.length === 0) return null;
    const fieldRows = chunk(fields, 2);
    return (
      <>
        <div className={styles['fields']}>
          {fieldRows.map(fieldRow => (
            <div className={styles['field-row']}>{fieldRow.map(field => field)}</div>
          ))}
        </div>
      </>
    );
  };

  renderStatus = () => {
    if (this.props.recurringMaintenanceId) return null;
    const { status } = this.props.workOrder;
    switch (status) {
      case WorkOrderStatus.InProgress: {
        return (
          <>
            <div className={`${styles['status-title-container']} ${styles['in-progress']}`}>
              <div className={styles['icon-container']}>
                <Icon type="clock" withBackground orange backgroundSize={30} size={14} />
              </div>
              <div className={styles['info-container']}>
                <div className={styles['title']}>
                  <p className={styles['status-text']}>
                    <FormattedMessage id="resources.work-order.statuses.in-progress" />
                  </p>
                  <p className={styles['by-user']}>
                    <FormattedMessage
                      id="general.timeago-by-user"
                      values={{
                        timeAgo: moment(this.props.workOrder.in_progress_at).fromNow(),
                        user: <UserNameWrapper user={this.props.inProgressByUser} />,
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className={styles['separator']} />
          </>
        );
      }
      case WorkOrderStatus.Paused: {
        return (
          <>
            <div className={`${styles['status-title-container']} ${styles['paused']}`}>
              <div className={styles['icon-container']}>
                <Icon type="clock" withBackground backgroundSize={30} size={14} />
              </div>
              <div className={styles['info-container']}>
                <div className={styles['title']}>
                  <p className={styles['status-text']}>
                    <FormattedMessage id="resources.work-order.statuses.paused" />
                  </p>
                  <p className={styles['by-user']}>
                    <FormattedMessage
                      id="general.timeago-by-user"
                      values={{
                        timeAgo: moment(this.props.workOrder.paused_at).fromNow(),
                        user: <UserNameWrapper user={this.props.pausedByUser} />,
                      }}
                    />
                  </p>
                </div>
                {this.props.workOrder.paused_comment ? (
                  <div className={styles['comment']}>{this.props.workOrder.paused_comment}</div>
                ) : null}
              </div>
            </div>
            <div className={styles['separator']} />
          </>
        );
      }
      case WorkOrderStatus.Completed: {
        return (
          <>
            <div className={`${styles['status-title-container']} ${styles['completed']}`}>
              <div className={styles['icon-container']}>
                <Icon type="check" regular withBackground green backgroundSize={30} size={16} />
              </div>
              <div className={styles['info-container']}>
                <div className={styles['title']}>
                  <p className={styles['status-text']}>
                    {this.props.workOrder.completed_before_registration ? (
                      <FormattedMessage id="resources.work-order.completed-before-registration" />
                    ) : (
                      <FormattedMessage id="resources.work-order.statuses.completed" />
                    )}
                  </p>
                  <p className={styles['by-user']}>
                    <FormattedMessage
                      id="general.date-by-user"
                      values={{
                        date: moment(this.props.workOrder.completed_date).format('LL'),
                        user: <UserNameWrapper user={this.props.completedByUser} />,
                      }}
                    />
                  </p>
                </div>
                {this.props.workOrder.completed_comment ? (
                  <div className={styles['comment']}>{this.props.workOrder.completed_comment}</div>
                ) : null}
              </div>
            </div>
            <div className={styles['separator']} />
          </>
        );
      }
      default:
        return null;
    }
  };

  renderPriority = () => {
    let priority = null;
    if (this.props.workOrderId) {
      priority = this.props.workOrder.priority;
    } else {
      priority = this.props.recurringMaintenance.priority;
    }
    switch (priority) {
      case 'low':
        return (
          <>
            <div className={`${styles['priority']} ${styles['low']}`}>
              <FormattedMessage id="resources.work-order.prio.low" />
            </div>
          </>
        );
      case 'medium':
        return (
          <>
            <div className={`${styles['priority']} ${styles['medium']}`}>
              <FormattedMessage id="resources.work-order.prio.medium" />
            </div>
          </>
        );
      case 'high':
        return (
          <>
            <div className={`${styles['priority']} ${styles['high']}`}>
              <FormattedMessage id="resources.work-order.prio.high" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  renderDescription = () => {
    let description;
    if (this.props.workOrderId) {
      description = this.props.workOrder.description;
    } else {
      description = this.props.recurringMaintenance.description;
    }

    let priority = null;
    if (this.props.workOrderId) {
      priority = this.props.workOrder.priority;
    } else {
      priority = this.props.recurringMaintenance.priority;
    }

    if (description == null && priority == null) {
      return null;
    }
    return (
      <>
        <div className={styles['description-container']}>
          <div>{description}</div>
          {this.renderPriority()}
        </div>
        <div className={styles['separator']} />
      </>
    );
  };

  renderEditEstimatedTimeOnRecurringModal = () => (
    <Modal isOpen={this.state.showEditEstimatedTimeErrorModal} width={400}>
      <Modal.Header
        ignoreLine
        title={
          <FormattedMessage id="components.preview-work-order.cant-edit-estimated-time-on-recurring-modal.title" />
        }
        subtitle={
          <FormattedMessage id="components.preview-work-order.cant-edit-estimated-time-on-recurring-modal.subtitle" />
        }
        onClose={() => this.setState({ showEditEstimatedTimeErrorModal: false })}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            label="general.ok"
            onClick={() => this.setState({ showEditEstimatedTimeErrorModal: false })}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderChecklist = () => {
    if (this.props.checklistInstances && this.props.checklistInstances.length === 1) {
      return (
        <div className={styles['checklist-container']}>
          <SectionHeader paddingHorizontal={20} horizontalBorders>
            <FormattedMessage id="components.preview-work-order.checklist" />
          </SectionHeader>
          <div className={styles['list-item']}>
            <ChecklistInstanceListItem id={this.props.checklistInstances[0].id} />
          </div>
        </div>
      );
    }
    if (this.props.checklistTemplateLinks && this.props.checklistTemplateLinks.length === 1) {
      return (
        <div className={styles['checklist-container']}>
          <SectionHeader paddingHorizontal={20} horizontalBorders>
            <FormattedMessage id="components.preview-work-order.checklist" />
          </SectionHeader>
          <div className={styles['list-item']}>
            <ChecklistTemplateLinkListItem id={this.props.checklistTemplateLinks[0].id} />
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        {this.renderStatus()}
        {this.renderDescription()}
        {this.renderFields()}
        {this.renderChecklist()}
        {this.renderEditEstimatedTimeOnRecurringModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.workOrderId) {
    const workOrder = EntitySelectors.getWorkOrder(state, ownProps.workOrderId);
    let meter = EntitySelectors.getMeter(state, workOrder.meter_id);
    let meterUnit = null;
    if (meter) {
      meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
    }
    return {
      workOrder,
      meter,
      meterUnit,
      inProgressByUser: EntitySelectors.getUser(state, workOrder.in_progress_by_user_id),
      pausedByUser: EntitySelectors.getUser(state, workOrder.paused_by_user_id),
      completedByUser: EntitySelectors.getUser(state, workOrder.completed_by_user_id),
      userAssignees: EntitySelectors.getUsers(state, workOrder.assigned_to_users) || [],
      groupAssignees: EntitySelectors.getGroups(state, workOrder.assigned_to_groups) || [],
      vendorAssignees: EntitySelectors.getVendors(state, workOrder.assigned_to_vendors) || [],
      recurringMaintenance: EntitySelectors.getRecurringMaintenance(
        state,
        workOrder.recurring_maintenance_id
      ),
      asset: EntitySelectors.getAsset(state, workOrder.asset_id),
      workOrderType: EntitySelectors.getWorkOrderType(state, workOrder.work_order_type_id),
      checklistInstances: EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        workOrder.checklist_template_links
      ),
      settings: AuthSelectors.getSettings(state),
    };
  } else {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(
      state,
      ownProps.recurringMaintenanceId
    );
    return {
      recurringMaintenance,
      userAssignees: EntitySelectors.getUsers(state, ownProps.userIds) || [],
      groupAssignees: EntitySelectors.getGroups(state, ownProps.groupIds) || [],
      vendorAssignees: EntitySelectors.getVendors(state, ownProps.vendorIds) || [],
      asset: EntitySelectors.getAsset(state, recurringMaintenance.asset_id),
      workOrderType: EntitySelectors.getWorkOrderType(state, recurringMaintenance.work_order_type_id),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        recurringMaintenance.checklist_template_links
      ),
      settings: AuthSelectors.getSettings(state),
    };
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(PreviewWorkOrderContent)));

PreviewWorkOrderContent.propTypes = {
  workOrderId: PropTypes.string,
  recurringMaintenanceId: PropTypes.string,
  date: PropTypes.string,
  userIds: PropTypes.array,
  groupIds: PropTypes.array,
  vendorIds: PropTypes.array,
  isSavingDueDate: PropTypes.bool,
  isSavingEstimatedTime: PropTypes.bool,
  isSavingAssignees: PropTypes.bool,
  editable: PropTypes.bool,
  onSaveAssignees: PropTypes.func,
  onSaveDate: PropTypes.func,
  onSaveEstimatedTime: PropTypes.func,
  onClose: PropTypes.func,
};

PreviewWorkOrderContent.defaultProps = {
  workOrderId: null,
  recurringMaintenanceId: null,
  date: null,
  userIds: [],
  groupIds: [],
  vendorIds: [],
  editable: false,
  isSavingDueDate: false,
  isSavingEstimatedTime: false,
  isSavingAssignees: false,
  onSaveAssignees: () => {},
  onSaveDate: () => {},
  onSaveEstimatedTime: () => {},
  onClose: () => {},
};
