export default {
  ADD_SPARE_PART: 'ShouldOrderSpareParts/add_spare_part',
  ADD_MULTIPLE_SPARE_PARTS: 'ShouldOrderSpareParts/add_multiple_spare_parts',
  REMOVE_MULTIPLE_SPARE_PARTS: 'ShouldOrderSpareParts/remove_multiple_spare_parts',
  REMOVE_SPARE_PART: 'ShouldOrderSpareParts/remove_spare_part',
  UPDATE_SPARE_PART: 'ShouldOrderSpareParts/update_spare_part',
  SET_VENDOR: 'ShouldOrderSpareParts/set_vendor',
  CANCEL_PURCHASE_ORDER: 'ShouldOrderSpareParts/cancel_purchase_order',
  RESET_STATE: 'ShouldOrderSpareParts/reset_state',
};
