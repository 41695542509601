import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';

class RelatedFilesPopupContent extends Component {
  renderPath = () => this.props.assetTreePath.map(asset => asset.title).join(', ');

  render() {
    if (this.props.showRelated) {
      if (this.props.assetTreePath.length === 0) {
        return <FormattedMessage id="components.asset-attachment-content.hide-related-tooltip.zero" />;
      } else {
        return (
          <FormattedMessage
            id="components.asset-attachment-content.hide-related-tooltip.other"
            values={{ path: this.renderPath() }}
          />
        );
      }
    }
    if (this.props.assetTreePath.length === 0) {
      return <FormattedMessage id="components.asset-attachment-content.show-related-tooltip.zero" />;
    } else {
      return (
        <FormattedMessage
          id="components.asset-attachment-content.show-related-tooltip.other"
          values={{ path: this.renderPath() }}
        />
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTreePath: EntitySelectors.getTreePathForAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps)(RelatedFilesPopupContent);
