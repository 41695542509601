import React, { Component } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { ConfirmModal } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';

class DeleteSparePartWithdrawalModal extends Component {
  getInitialState = () => ({
    isDeletingSparePartWithdrawal: false,
  });

  state = this.getInitialState();

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  deleteSparePartWithdrawal = () => {
    this.setState({ isDeletingSparePartWithdrawal: true });
    this.props.deleteSparePartWithdrawal(this.props.id).then(() => {
      this.props.onDelete();
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.withdrawals.delete-withdrawal-modal.delete-success" />}
        />
      );
    });
  };

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.props.open}
        title={<FormattedMessage id="screens.withdrawals.delete-withdrawal-modal.title" />}
        message={<FormattedMessage id="screens.withdrawals.delete-withdrawal-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingSparePartWithdrawal}
        onConfirm={() => {
          this.deleteSparePartWithdrawal();
        }}
        onCancel={this.props.onClose}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePartWithdrawal: SDKReduxOperations.deleteSparePartWithdrawal,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DeleteSparePartWithdrawalModal);
