import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'views/components/Shared/General';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthSelectors } from 'state/ducks/auth';
import AtlaskitTree from '@atlaskit/tree/';
import styles from './style.module.scss';
import NewAssetItem from './NewAssetItem';

class Tree extends Component {
  hasChildren = () =>
    this.props.tree.items[this.props.tree.rootId].children.some(
      child => this.props.tree.items[child].hasChildren
    );

  renderTreeItemContent = ({ item, onExpand, onCollapse, provided }) => {
    return this.renderTreeItemNodeContent(item, onExpand, onCollapse);
  };

  renderTreeItem = ({ item, onExpand, onCollapse, provided }) => {
    const style = {};
    if (provided.draggableProps.style.opacity === 0.7) {
      style.transform = 'scale(0.95)';
    }

    const draggableProps = provided.draggableProps;
    const dragHandleProps = provided.dragHandleProps;

    return (
      <div
        ref={provided.innerRef}
        {...draggableProps}
        {...dragHandleProps}
        className={styles['draggable-container']}
      >
        <div style={style} className={styles['item-container']}>
          {this.renderIcon(item, onExpand, onCollapse)}
          <div className={`${styles['item']} ${item.data.newlyAdded ? styles['newly-added'] : ''}`}>
            {this.renderTreeItemContent({ item, onExpand, onCollapse, provided })}
          </div>
        </div>
      </div>
    );
  };

  renderTreeItemNodeContent = (item, onExpand, onCollapse) => {
    if (item.data.isPreparing) {
      return (
        <NewAssetItem
          isCreatingAsset={this.props.isCreatingAsset}
          onSave={title => this.props.onSave({ title, tree_parent_id: item.preparingForParentId })}
          onCancel={this.props.onCancelPrepareNewAsset}
        />
      );
    }

    const content = (
      <div className={styles['title-container']}>
        <React.Fragment>
          <div className={styles['title']}>{item.data ? item.data.asset.title : ''}</div>
          {this.props.settings.asset_number_activated ? (
            <div className={styles['subtitle']}>
              <span>#</span>
              <span>{item.data ? item.data.asset.number : ''}</span>
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );

    return this.props.renderNode ? (
      this.props.renderNode(item)
    ) : (
      <React.Fragment>
        <div
          className={`${styles['item-data']} ${this.props.link ? styles['link'] : ''} ${
            this.props.clickable ? styles['clickable'] : ''
          }`}
          onClick={() => this.props.onSelect(item)}
        >
          {this.props.link ? <Link to={`/assets/${item.id}`}>{content}</Link> : content}
          {!item.data.isSelected ? null : <Icon regular green type="check" />}
        </div>
        <div className={styles['buttons-container']}>{this.renderButtons(item)}</div>
      </React.Fragment>
    );
  };

  renderIcon = (item, onExpand, onCollapse) => {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <span className={styles['expand-btn']} onClick={() => onCollapse(item.id)}>
          <Icon regular type="minus" />
        </span>
      ) : (
        <span className={styles['expand-btn']} onClick={() => onExpand(item.id)}>
          <Icon regular type="plus" />
        </span>
      );
    }
    return <span className={styles['expand-empty']}></span>;
  };

  renderButtons = item => {
    return this.props.itemButtons == null ? null : this.props.itemButtons(item);
  };

  render() {
    return (
      <div className={`${styles['tree']} ${!this.hasChildren() ? styles['is-empty'] : ''}`}>
        <AtlaskitTree {...this.props} renderItem={this.renderTreeItem} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(Tree);

Tree.propTypes = {
  tree: PropTypes.object.isRequired,
  onExpand: PropTypes.func,
  itemButtons: PropTypes.element,
  onCollapse: PropTypes.func,
  onSelect: PropTypes.func,
  onDragEnd: PropTypes.func,
  renderNode: PropTypes.func,
  clickable: PropTypes.bool,
  isDragEnabled: PropTypes.bool,
  isNestingEnabled: PropTypes.bool,
  link: PropTypes.bool,
};

Tree.defaultProps = {
  onExpand: () => {},
  onCollapse: () => {},
  onDragEnd: () => {},
  onSelect: () => {},
  clickable: true,
  isDragEnabled: false,
  isNestingEnabled: false,
};
