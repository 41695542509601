export const Type = {
  Inspection: 'inspection',
  Section: 'section',
};

export const InspectionValue = {
  Ok: 'ok',
  Failed: 'failed',
  NoAnswer: 'no_answer',
};
