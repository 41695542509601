import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { CardGrid } from 'views/components/Shared/Layout';
import { ViewTextArea, WhiteCard, FullScreenImagePicker } from 'views/components/Shared/General';
import { Image } from 'views/components/Image';
import styles from './style.module.scss';

class DescriptionContainer extends Component {
  state = {
    currentImage: 0,
    showingImageDialog: false,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderImages = () => {
    let className = [styles['images']];
    if (this.props.description) {
      className = [...className, styles['has-description']];
    }
    if (this.props.images.length > 0) {
      return (
        <div className={styles['images']}>
          {this.props.images.map((image, index) => {
            return (
              <div className={styles['image']}>
                <Image
                  small
                  loading={false}
                  circle
                  editable={false}
                  image={image}
                  onClick={() => {
                    this.selectImage(index);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };

  renderFullScreenImagePicker = () => {
    return (
      <FullScreenImagePicker
        currentImage={this.state.currentImage}
        images={this.props.images}
        onChangeImage={index => this.selectImage(index)}
        open={this.state.showingImageDialog}
        onClose={() => this.setState({ showingImageDialog: false })}
      />
    );
  };

  renderDescription = () => {
    if (this.props.operationalMaintenanceTemplateVersion.description) {
      return (
        <div className={styles['title-container']}>
          <ViewTextArea>{this.props.operationalMaintenanceTemplateVersion.description}</ViewTextArea>
        </div>
      );
    }
  };

  render() {
    const { operationalMaintenanceTemplateVersion, images } = this.props;
    if (images.length === 0 && operationalMaintenanceTemplateVersion.description == null) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <WhiteCard padding="30px">
            {this.renderDescription()}
            {this.renderImages()}
          </WhiteCard>
        </CardGrid.Row>

        {this.renderFullScreenImagePicker()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const { operational_maintenance_template_version_id } = operationalMaintenanceInstance;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operational_maintenance_template_version_id
  );
  return {
    operationalMaintenanceTemplateVersion,
    images: EntitySelectors.getImages(state, operationalMaintenanceTemplateVersion.images),
  };
}

export default connect(mapStateToProps)(DescriptionContainer);
