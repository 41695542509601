import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { isEqual, debounce } from 'lodash-es';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { matchPath } from 'react-router';
import {
  OperationalMaintenancesSelectors,
  OperationalMaintenancesOperations,
  OperationalMaintenancesUtils,
} from 'state/ducks/operationalMaintenances';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { DatePicker, Button, Menu } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { EditOperationalMaintenanceModal } from 'views/components/OperationalMaintenance';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

export const LIST_TYPES = {
  Today: 'today',
  ThisWeek: 'this_week',
  ThisMonth: 'this_month',
  Custom: 'custom',
};

const request = HelperFunctions.getCancelTokenForRequest();

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditOperationalMaintenanceTemplateModal: false,
      showDatePicker: false,
    };
    this.fetchDebouncedOperationalMaintenanceListCount = debounce(() => {
      this.fetchOperationalMaintenanceListCount();
    }, 300);
  }

  componentDidMount() {
    if (this.props.isCountInitialized === false) {
      this.props.setOperationalMaintenanceListCountLoading();
      this.fetchDebouncedOperationalMaintenanceListCount();
    }
  }

  componentDidUpdate(prevProps) {
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedBookmarkedAssets = prevProps.bookmarkedAssets.length !== this.props.bookmarkedAssets.length;
    const changedCheckedinAssetId = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    const changedDropdownTypeForAssetDropdown =
      prevProps.dropdownTypeForAssetDropdown !== this.props.dropdownTypeForAssetDropdown;
    const changedAssetIdForAssetDropdown =
      prevProps.assetIdForAssetDropdown !== this.props.assetIdForAssetDropdown;

    if (
      changedQueryParams ||
      changedBookmarkedAssets ||
      changedDropdownTypeForAssetDropdown ||
      changedAssetIdForAssetDropdown ||
      changedCheckedinAssetId
    ) {
      request.cancel();
      this.props.setOperationalMaintenanceListCountLoading();
      this.fetchDebouncedOperationalMaintenanceListCount();
    }
  }

  getParams = () => {
    let params = {};
    if (this.props.isOperator) {
      if (this.props.checkedInAssetId) {
        params = {
          ...params,
          asset_ids: this.props.checkedInAssetId,
        };
      }
    } else {
      if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
        params = {
          ...params,
          asset_ids: this.props.bookmarkedAssets.map(({ asset_id }) => asset_id).join(','),
        };
      } else if (this.props.assetIdForAssetDropdown) {
        params = {
          ...params,
          asset_ids: this.props.assetIdForAssetDropdown,
        };
      }
    }

    return params;
  };

  getQueryParametes = () => {
    let params = {};
    if (this.props.queryParameters.status.length > 0) {
      params = {
        status: this.props.queryParameters.status.join(','),
      };
    }
    return params;
  };

  fetchOperationalMaintenanceListCount = () => {
    const params = {
      ...this.getParams(),
      ...this.getQueryParametes(),
    };

    this.props
      .fetchOperationalMaintenanceListCount(this.props.system.id, params, request.getCancelTokenConfig())
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  getLinkForParams = params => {
    return `/operational-maintenances?${HelperFunctions.convertObjToQueryParameters(params)}`;
  };

  renderCreateButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <>
            <Button
              fullWidth
              primary
              label="screens.operational-maintenances.list.create-button"
              onClick={() => this.setState({ showEditOperationalMaintenanceTemplateModal: true })}
            />
            <Menu.Separator />
          </>
        </>
      );
    }
    return null;
  };

  renderEditOperationalMaintenanceModal = () => {
    return (
      <EditOperationalMaintenanceModal
        open={this.state.showEditOperationalMaintenanceTemplateModal}
        onCreated={templateId => {
          this.props.resetOperationalMaintenanceTemplatesState();
          this.props.history.push(`/operational-maintenances/templates/${templateId}`);
        }}
        onClose={() => this.setState({ showEditOperationalMaintenanceTemplateModal: false })}
      />
    );
  };

  renderCustomDateValue = () => {
    const { list, from_date, to_date } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Custom) {
      return (
        <>
          <span>{moment(from_date).format('ll')}</span>
          <span> - </span>
          <span>{moment(to_date).format('ll')}</span>
        </>
      );
    }
    return (
      <span className={styles['custom-date']}>
        <FormattedMessage id="screens.operational-maintenances.list.custom-date" />
      </span>
    );
  };

  renderAdministrateMenuItems = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <Menu.Separator />
          <Menu.Item
            title={<FormattedMessage id="screens.operational-maintenances.list.templates" />}
            linkTo={`/operational-maintenances/templates`}
            onClick={this.props.resetOperationalMaintenanceTemplatesState}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/operational-maintenances/templates',
              }) != null
            }
          />
          <Menu.Item
            title={<FormattedMessage id="screens.operational-maintenances.list.breaks" />}
            linkTo={`/operational-maintenances/breaks`}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/operational-maintenances/breaks',
              }) != null
            }
          />
        </>
      );
    }
    return null;
  };

  render = () => {
    const { list, from_date, to_date } = queryString.parse(this.props.location.search);
    return (
      <>
        <ListLayout.Content.Menu>
          <div className={styles['left-panel-container']}>
            <PerfectScrollbar>
              <ListLayout.Content.Menu.Content>
                {this.renderCreateButton()}
                <Menu.Item
                  title={<FormattedMessage id="screens.operational-maintenances.list.today" />}
                  selected={list === LIST_TYPES.Today}
                  linkTo={this.getLinkForParams({ list: LIST_TYPES.Today })}
                  numberLoading={this.props.listCountLoading}
                  number={this.props.listCount ? this.props.listCount.day : null}
                />
                <Menu.Item
                  title={
                    <div>
                      <FormattedMessage id="screens.operational-maintenances.list.this-week" />
                      <span className={styles['week']}>
                        <span> - </span>
                        <FormattedMessage
                          id="screens.operational-maintenances.list.week-number"
                          values={{ week: moment().format('W') }}
                        />
                      </span>
                    </div>
                  }
                  linkTo={this.getLinkForParams({ list: LIST_TYPES.ThisWeek })}
                  selected={list === LIST_TYPES.ThisWeek}
                  numberLoading={this.props.listCountLoading}
                  number={this.props.listCount ? this.props.listCount.week : null}
                />
                <Menu.Item
                  title={
                    <div>
                      <span>{moment().format('MMMM')}</span>
                    </div>
                  }
                  linkTo={this.getLinkForParams({ list: LIST_TYPES.ThisMonth })}
                  selected={list === LIST_TYPES.ThisMonth}
                  numberLoading={this.props.listCountLoading}
                  number={this.props.listCount ? this.props.listCount.month : null}
                />
                <DatePicker.Range
                  open={this.state.showDatePicker}
                  onClose={() => this.setState({ showDatePicker: false })}
                  trigger={
                    <Menu.Item
                      title={this.renderCustomDateValue()}
                      onClick={() => {
                        this.setState(prevState => ({
                          showDatePicker: !prevState.showDatePicker,
                        }));
                      }}
                      selected={list === LIST_TYPES.Custom}
                    />
                  }
                  onSelectDate={({ from, to }) => {
                    this.setState({ showDatePicker: false });
                    this.props.history.push(
                      this.getLinkForParams({ list: LIST_TYPES.Custom, from_date: from, to_date: to })
                    );
                  }}
                  fromDate={from_date}
                  toDate={to_date}
                />
                {this.renderAdministrateMenuItems()}
              </ListLayout.Content.Menu.Content>
            </PerfectScrollbar>
          </div>
        </ListLayout.Content.Menu>
        {this.renderEditOperationalMaintenanceModal()}
      </>
    );
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOperationalMaintenanceListCountLoading:
        OperationalMaintenancesOperations.setOperationalMaintenanceListCountLoading,
      fetchOperationalMaintenanceListCount:
        OperationalMaintenancesOperations.fetchOperationalMaintenanceListCount,
      resetOperationalMaintenanceTemplatesState:
        OperationalMaintenancesOperations.resetOperationalMaintenanceTemplatesState,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    asset: EntitySelectors.getAsset(state, id),
    listCountLoading: OperationalMaintenancesSelectors.getOperationalMaintenanceListCountLoading(state),
    listCount: OperationalMaintenancesSelectors.getOperationalMaintenanceListCount(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    isOperator: AuthSelectors.isOperator(state),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    assetIdForAssetDropdown: BookmarkedAssetsDropdownSelectors.getAssetId(state),
    queryParameters: OperationalMaintenancesSelectors.getQueryParametersForOperationalMaintenances(state),
    isCountInitialized: OperationalMaintenancesSelectors.isCountInitialized(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftPanel));
