import React, { Component } from 'react';
import styles from './style.module.scss';
import { Button, Field } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';

class OperatorSparePartModal extends Component {
  state = {
    value: this.props.settings.operator_permission_spare_parts,
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.open && this.props.open) {
      this.setState({ value: this.props.settings.operator_permission_spare_parts });
    }
  };
  render() {
    return (
      <Modal isOpen={this.props.open} width={570}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.subtitle" />
          }
          subtitleTopMargin
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>
          <Field.Radio.Group>
            <Field.Radio
              label={
                <div className={styles['radio-title']}>
                  <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.view-title" />
                </div>
              }
              subtitle={
                <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.view-subtitle" />
              }
              checked={this.state.value === 'view'}
              onChange={() => this.setState({ value: 'view' })}
            />
            <Field.Radio
              label={
                <div className={styles['radio-title']}>
                  <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.create-title" />
                </div>
              }
              subtitle={
                <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.create-subtitle" />
              }
              checked={this.state.value === 'create_withdrawals'}
              onChange={() => this.setState({ value: 'create_withdrawals' })}
            />
            <Field.Radio
              label={
                <div className={styles['radio-title']}>
                  <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.make-adjustments-title" />
                </div>
              }
              subtitle={
                <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.modal.make-adjustments-subtitle" />
              }
              checked={this.state.value === 'create_withdrawals_and_stock_adjustment'}
              onChange={() => this.setState({ value: 'create_withdrawals_and_stock_adjustment' })}
            />
          </Field.Radio.Group>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.props.loading}
              onClick={() => this.props.onSave({ operator_permission_spare_parts: this.state.value })}
              label="general.save"
            />
            <Button label="general.cancel" onClick={() => this.props.onClose()} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OperatorSparePartModal;
