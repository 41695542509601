import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as moment from 'moment';
import { NewInlineModal } from 'views/components/Shared/General';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';

class WorkOrderRecurringDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalIsSet: false,
      isOpen: false,
      editingWorkOrder: null,
      interval: null,
      startDate: null,
      frequency: null,
      monthType: null,
      generateFromCompletedDate: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
    };
  }

  save = data => {
    this.setState({ isOpen: false });
    this.props.onSave(this.buildRecurringMaintenance(data));
  };

  reset = () => {
    this.setState({ isOpen: false });
    this.props.onClear();
  };

  resetStateToEditingWorkOrder = () => {
    this.setState({
      intervalIsSet: this.state.editingWorkOrder.frequency != null,
      startDate: this.state.editingWorkOrder.start_date,
      interval: this.state.editingWorkOrder.interval,
      frequency: this.state.editingWorkOrder.frequency,
      monthType: this.state.editingWorkOrder.month_type,
      generateFromCompletedDate: this.state.editingWorkOrder.generate_from_completed_date,
      mon: this.state.editingWorkOrder.mon,
      tue: this.state.editingWorkOrder.tue,
      wed: this.state.editingWorkOrder.wed,
      thu: this.state.editingWorkOrder.thu,
      fri: this.state.editingWorkOrder.fri,
      sat: this.state.editingWorkOrder.sat,
      sun: this.state.editingWorkOrder.sun,
    });
  };

  buildRecurringMaintenance = data => ({
    start_date: this.state.startDate,
    interval: data.interval,
    frequency: data.frequency,
    month_type: data.monthType,
    generate_from_completed_date: data.generateFromCompletedDate,
    mon: data.mon,
    tue: data.tue,
    wed: data.wed,
    thu: data.thu,
    fri: data.fri,
    sat: data.sat,
    sun: data.sun,
  });

  compareIntervalPreset = data => Object.keys(data).every(key => data[key] === this.state[key]);

  getDailyPresetData = () => ({
    interval: 1,
    frequency: 'daily',
    generateFromCompletedDate: false,
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
    sun: false,
  });

  getWeeklyPresetData = startDate => {
    const momentDate = startDate != null ? moment(startDate) : null;
    return {
      interval: 1,
      frequency: 'weekly',
      generateFromCompletedDate: false,
      mon: momentDate != null && momentDate.day() === 1,
      tue: momentDate != null && momentDate.day() === 2,
      wed: momentDate != null && momentDate.day() === 3,
      thu: momentDate != null && momentDate.day() === 4,
      fri: momentDate != null && momentDate.day() === 5,
      sat: momentDate != null && momentDate.day() === 6,
      sun: momentDate != null && momentDate.day() === 0,
    };
  };

  getMonthlyPresetData = () => ({
    interval: 1,
    frequency: 'monthly',
    monthType: 'day_of_week',
    generateFromCompletedDate: false,
  });

  getYearlyPresetData = () => ({
    interval: 1,
    frequency: 'yearly',
    monthType: 'exact_day',
    generateFromCompletedDate: false,
  });

  renderPresetOptions = () => {
    const dailyPresetData = this.getDailyPresetData();
    const weekyPresetData = this.getWeeklyPresetData(this.state.startDate);
    const monthlyPresetData = this.getMonthlyPresetData();
    const yearlyPresetData = this.getYearlyPresetData();
    let selectedPreset;
    if (this.compareIntervalPreset(dailyPresetData)) {
      selectedPreset = 'daily';
    } else if (this.compareIntervalPreset(weekyPresetData)) {
      selectedPreset = 'weekly';
    } else if (this.compareIntervalPreset(monthlyPresetData)) {
      selectedPreset = 'monthly';
    } else if (this.compareIntervalPreset(yearlyPresetData)) {
      selectedPreset = 'yearly';
    }

    return (
      <React.Fragment>
        {this.props.hideClearOption ? null : (
          <React.Fragment>
            <NewInlineModal.Dropdown.Item onClick={() => this.reset()} selected={!this.state.intervalIsSet}>
              <FormattedMessage id="components.interval-options-inline-modal.reset" />
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Separator />
          </React.Fragment>
        )}
        <NewInlineModal.Dropdown.Item
          selected={selectedPreset === 'daily'}
          onClick={() => {
            if (selectedPreset === 'daily' && !this.props.hideClearOption) {
              this.reset();
            } else {
              this.save(dailyPresetData);
            }
          }}
        >
          {buildIntervalMessage(
            {
              ...this.buildRecurringMaintenance(dailyPresetData),
              start_date: this.state.startDate,
            },
            this.props.intl
          )}
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          selected={selectedPreset === 'weekly'}
          onClick={() => {
            if (selectedPreset === 'weekly' && !this.props.hideClearOption) {
              this.reset();
            } else {
              this.save(weekyPresetData);
            }
          }}
        >
          {buildIntervalMessage(
            {
              ...this.buildRecurringMaintenance(weekyPresetData),
              start_date: this.state.startDate,
            },
            this.props.intl
          )}
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          selected={selectedPreset === 'monthly'}
          onClick={() => {
            if (selectedPreset === 'monthly' && !this.props.hideClearOption) {
              this.reset();
            } else {
              this.save(monthlyPresetData);
            }
          }}
        >
          {buildIntervalMessage(
            {
              ...this.buildRecurringMaintenance(monthlyPresetData),
              start_date: this.state.startDate,
            },
            this.props.intl
          )}
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          selected={selectedPreset === 'yearly'}
          onClick={() => {
            if (selectedPreset === 'yearly' && !this.props.hideClearOption) {
              this.reset();
            } else {
              this.save(yearlyPresetData);
            }
          }}
        >
          {buildIntervalMessage(
            {
              ...this.buildRecurringMaintenance(yearlyPresetData),
              start_date: this.state.startDate,
            },
            this.props.intl
          )}
        </NewInlineModal.Dropdown.Item>
        {selectedPreset == null && this.state.intervalIsSet ? (
          <NewInlineModal.Dropdown.Item
            selected={true}
            onClick={() => {
              if (!this.props.hideClearOption) {
                this.reset();
              }
              this.setState({ isOpen: false });
            }}
          >
            {buildIntervalMessage(this.buildRecurringMaintenance(this.state), this.props.intl)}
          </NewInlineModal.Dropdown.Item>
        ) : null}
        <NewInlineModal.Dropdown.Separator />
        <NewInlineModal.Dropdown.Item
          onClick={() =>
            this.setState({ isOpen: false }, () => {
              this.props.onShowCustomOptions();
            })
          }
        >
          <FormattedMessage id="resources.work-order.interval.custom" />
        </NewInlineModal.Dropdown.Item>
      </React.Fragment>
    );
  };

  onOpen = () => {
    this.setState(
      {
        isOpen: true,
        editingWorkOrder: this.props.editingWorkOrder,
      },
      () => {
        this.resetStateToEditingWorkOrder();
      }
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderPresetOptions()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

export default injectIntl(WorkOrderRecurringDropdown);
