import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import { ToastMessage, ListLayout } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, List, Pagination, FilterButton } from 'views/components/Shared/General';
import {
  SparePartWithdrawalModal,
  DeleteSparePartWithdrawalModal,
} from 'views/components/SparePartWithdrawal';
import { HelperFunctions as SDKHelperFunctions, SDKReduxOperations } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import SparePartImage from 'assets/images/EmptyDataSet/SpareParts.png';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import {
  SparePartWithdrawalsSelectors,
  SparePartWithdrawalsOperations,
} from 'state/ducks/sparePartWithdrawals';
import FilterModal from './FilterModal';
import ExportModal from './ExportModal';
import styles from './style.module.scss';
import SparePartWithdrawalListItem from 'views/components/SparePartWithdrawal/SparePartWithdrawalListItem';

const listSparePartWithdrawalsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class SparePartWithdrawals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isViewLoading: true,
      isSearching: false,
      showExportModal: false,
      showFilterModal: false,
      showDeleteSparePartWithdrawalModal: false,
      showDeleteSparePartWithdrawalModalForId: null,
      isDeletingSparePartWithdrawal: false,
      showSparePartWithdrawalModal: false,
      showSparePartWithdrawalModalForId: null,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.withdrawals.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.SparePartWithdrawals);
    this.props.resetSelectedSparePartWithdrawals();
    this.fetchSparePartWithdrawals();
  }

  componentDidUpdate(prevProps) {
    const { showFilterModal } = this.state;
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchSparePartWithdrawals();
      return;
    }
    if (changedQueryParams || (changedAdvancedFilters && showFilterModal === false)) {
      this.setState({ isSearching: true });
      this.props.resetSelectedSparePartWithdrawals();
      this.fetchSparePartWithdrawals();
    }
  }

  fetchSparePartWithdrawals = (params = {}) => {
    listSparePartWithdrawalsRequest.cancel();

    const attrs = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...params,
    };

    return this.props
      .fetchSparePartWithdrawals(
        this.props.selectedSystem.id,
        attrs,
        listSparePartWithdrawalsRequest.getCancelTokenConfig()
      )
      .then(sparePartWithdrawals => {
        this.setState({ isViewLoading: false, isSearching: false });
        return sparePartWithdrawals;
      });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  deleteSparePartWithdrawal = id => {
    this.setState({ isDeletingSparePartWithdrawal: true });
    this.props.deleteSparePartWithdrawal(id).then(() => {
      this.setState({
        isDeletingSparePartWithdrawal: false,
        showDeleteSparePartWithdrawalModal: false,
        showDeleteSparePartWithdrawalModalForId: null,
      });
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.withdrawals.delete-withdrawal-modal.delete-success" />}
        />
      );
    });
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={SparePartImage} alt="" />
      </div>
    </div>
  );

  renderSparePartWithdrawalsEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.withdrawals.empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.withdrawals.empty-data-set.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={SparePartImage} alt="" />
      </div>
    </div>
  );

  renderDeleteModal = () => {
    return (
      <DeleteSparePartWithdrawalModal
        open={this.state.showDeleteSparePartWithdrawalModal}
        id={this.state.showDeleteSparePartWithdrawalModalForId}
        onDelete={() => {
          this.setState({ showDeleteSparePartWithdrawalModal: false });
        }}
        onClose={() => {
          this.setState({ showDeleteSparePartWithdrawalModal: false });
        }}
      />
    );
  };

  renderPagination = () => {
    if (this.props.sparePartWithdrawals.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isSearching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.sparePartWithdrawals.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedSparePartWithdrawals()}
        />
      );
    }
    return null;
  };

  renderListHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected, selectedSparePartWithdrawalCount } = this.props;
    const { isSearching } = this.state;
    return (
      <List.Header
        small
        checkbox
        background
        paddingRight={this.props.canCreateWithdrawals ? 75 : null}
        checked={isSearching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isSearching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedSparePartWithdrawals();
          } else {
            this.props.selectPage();
          }
        }}
        showMultipleOptions={selectedSparePartWithdrawalCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.isSearching}
            count={this.props.selectedSparePartWithdrawalCount}
            buttons={
              <List.Header.MultipleOptions.Button
                label={<FormattedMessage id="screens.withdrawals.header-buttons.export" />}
                onClick={() => this.setState({ showExportModal: true })}
              />
            }
          />
        }
      >
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.spare-part.resource" />
        </List.Header.Column>
      </>
    );
  };

  renderList = () => {
    if (this.state.isSearching) {
      const amountOfSparePartWithdrawals =
        this.props.sparePartWithdrawals.length === 0 ? 2 : this.props.sparePartWithdrawals.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfSparePartWithdrawals)
              .fill()
              .map(() => (
                <SparePartWithdrawalListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.sparePartWithdrawals.length === 0) {
      if (this.props.isFiltering) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderSparePartWithdrawalsEmptyDataset();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.sparePartWithdrawals.map(({ id }) => {
            let checked = false;
            if (this.props.selectedSparePartWithdrawalIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <SparePartWithdrawalListItem
                key={id}
                id={id}
                checkbox
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={e => {
                  this.props.selectSparePartWithdrawal(id);
                }}
                onDelete={id => {
                  this.setState({
                    showDeleteSparePartWithdrawalModal: true,
                    showDeleteSparePartWithdrawalModalForId: id,
                  });
                }}
                onClick={() => {
                  this.setState({
                    showSparePartWithdrawalModal: true,
                    showSparePartWithdrawalModalForId: id,
                  });
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.withdrawals.title" />}
        totalEntries={
          <FormattedMessage
            id="screens.withdrawals.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
      />
    );
  };

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderLeftMenu = () => {
    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <ListLayout.Content.Menu.Content>
            <Menu.Item
              linkTo="/withdrawals"
              title={<FormattedMessage id="screens.withdrawals.title" />}
              selected
            />
          </ListLayout.Content.Menu.Content>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  };

  renderToolbar = () => (
    <ListLayout.Content.MainContent.FilterBar>
      <ListLayout.Content.MainContent.FilterBar.RightContent>
        <FilterButton.Group>
          {this.props.isFiltering ? (
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="general.clean" />}
              onClick={() => {
                this.setState({ isSearching: true });
                this.props.resetFilter();
              }}
            />
          ) : null}
          <FilterButton
            clearable={false}
            caret={false}
            filtered={this.props.isFiltering}
            label={<FormattedMessage id="screens.spare-parts.filter" />}
            onClick={() => this.setState({ showFilterModal: true })}
          />
        </FilterButton.Group>
      </ListLayout.Content.MainContent.FilterBar.RightContent>
    </ListLayout.Content.MainContent.FilterBar>
  );

  renderMainContent = () => {
    if (this.state.isViewLoading) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderListHeader()}
            <List>
              <SparePartWithdrawalListItem loading />
              <SparePartWithdrawalListItem loading />
            </List>
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderSparePartWithdrawalModal = () => {
    return (
      <SparePartWithdrawalModal
        open={this.state.showSparePartWithdrawalModal}
        id={this.state.showSparePartWithdrawalModalForId}
        onClose={() => this.setState({ showSparePartWithdrawalModal: false })}
        onDelete={id => {
          this.setState({ showSparePartWithdrawalModal: false });
          setTimeout(() => {
            this.setState({
              showDeleteSparePartWithdrawalModal: true,
              showDeleteSparePartWithdrawalModalForId: id,
            });
          }, 250);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderExportModal()}
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.addQueryParameter({ page: 1 });
            this.props.resetSelectedSparePartWithdrawals();
            this.setState({ isSearching: true });
            this.fetchSparePartWithdrawals(params);
            this.setState({ showFilterModal: false });
          }}
        />
        {this.renderDeleteModal()}
        {this.renderSparePartWithdrawalModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartWithdrawals: SparePartWithdrawalsOperations.fetchSparePartWithdrawals,
      addQueryParameter: SparePartWithdrawalsOperations.addQueryParameter,
      selectMenuItem: MenuOperations.selectItem,
      resetSelectedSparePartWithdrawals: SparePartWithdrawalsOperations.resetSelectedSparePartWithdrawals,
      selectSparePartWithdrawal: SparePartWithdrawalsOperations.selectSparePartWithdrawal,
      selectTotalEntries: SparePartWithdrawalsOperations.selectTotalEntries,
      hideSelectTotalEntries: SparePartWithdrawalsOperations.hideSelectTotalEntries,
      selectPage: SparePartWithdrawalsOperations.selectPage,
      resetFilter: SparePartWithdrawalsOperations.resetFilter,
      deleteSparePartWithdrawal: SDKReduxOperations.deleteSparePartWithdrawal,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    sparePartWithdrawals: SparePartWithdrawalsSelectors.getSparePartWithdrawals(state),
    queryParameters: SparePartWithdrawalsSelectors.getQueryParameters(state),
    filtersAsQueryParams: SparePartWithdrawalsSelectors.getFiltersAsQueryParams(state),
    filters: SparePartWithdrawalsSelectors.getFilters(state),
    pagination: SparePartWithdrawalsSelectors.getSparePartWithdrawalsPagination(state),
    selectedSparePartWithdrawalIds: SparePartWithdrawalsSelectors.getSelectedSparePartWithdrawalIds(state),
    selectedSparePartWithdrawalCount:
      SparePartWithdrawalsSelectors.getSelectedSparePartWithdrawalCount(state),
    totalEntriesIsSelected: SparePartWithdrawalsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: SparePartWithdrawalsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: SparePartWithdrawalsSelectors.getPageIsSelected(state),
    isFiltering: SparePartWithdrawalsSelectors.isFiltering(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartWithdrawals)));
