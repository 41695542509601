import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class EditAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingVendor: {},
      editingVendorBeforeEdit: {},
      isSaving: false,
      hasUnsavedChanges: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const editingVendor = {
        address1: this.props.vendor.address1,
        address2: this.props.vendor.address2,
        zip: this.props.vendor.zip,
        city: this.props.vendor.city,
        country: this.props.vendor.country,
      };
      this.setState({
        isSaving: false,
        editingVendor,
        editingVendorBeforeEdit: editingVendor,
        hasUnsavedChanges: false,
      });
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  setVendorValue = obj => {
    const editingVendor = {
      ...this.state.editingVendor,
      ...obj,
    };

    const hasUnsavedChanges = !isEqual(editingVendor, this.state.editingVendorBeforeEdit);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      editingVendor,
      hasUnsavedChanges,
    });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  getModifiedVendorData = () => {
    const vendor = this.state.editingVendor;
    const vendorBeforeEdit = this.state.editingVendorBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== vendorBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  save = () => {
    this.setState({ isSaving: true });

    const params = this.getModifiedVendorData();

    this.props
      .updateVendor(this.props.vendor.id, params)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.vendor.address1" />}>
            <Field.Text
              autoFocus
              value={this.state.editingVendor.address1}
              onChange={address1 => {
                this.setVendorValue({ address1 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.vendor.address2" />}>
            <Field.Text
              value={this.state.editingVendor.address2}
              onChange={address2 => {
                this.setVendorValue({ address2 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column md={4}>
          <Field view={false} label={<FormattedMessage id="resources.vendor.zip" />}>
            <Field.Text
              value={this.state.editingVendor.zip}
              onChange={zip => {
                this.setVendorValue({ zip });
              }}
            />
          </Field>
        </Grid.Column>
        <Grid.Column md={8}>
          <Field view={false} label={<FormattedMessage id="resources.vendor.city" />}>
            <Field.Text
              value={this.state.editingVendor.city}
              onChange={city => {
                this.setVendorValue({ city });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.vendor.country" />}>
            <Field.Text
              value={this.state.editingVendor.country}
              onChange={country => {
                this.setVendorValue({ country });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.vendor.information.address.edit-address-title" />}
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.close" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(EditAddressModal));
