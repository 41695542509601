import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';

class EditNameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      name: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        name: this.props.currentUser.name,
      });
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.name) {
      this.save();
    }
  };

  save = () => {
    const { name } = this.state;
    this.setState({ isSaving: true });
    this.props
      .updateMe({ name })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.user.general.edit-name-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content ignoreLine hasFooter>
          <Field view={false} label={<FormattedMessage id="resources.user.name" />}>
            <Field.Text
              autoFocus
              value={this.state.name}
              onChange={name => {
                this.setState({ name });
              }}
              onKeyPress={this.handleKeyPress}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={!this.state.name}
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMe: SDKReduxOperations.updateMe,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditNameModal));
