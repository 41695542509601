import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, List } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';

class SelectedSparePart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.quantity,
    };
  }

  render() {
    return (
      <List.Item>
        <List.Item.ImageColumn
          imageId={this.props.sparePart.images.length === 0 ? null : this.props.sparePart.images[0]}
        />
        <List.Item.TitleColumn title={<Title sparePart={this.props.sparePart} />} />
        <List.Item.Column width={90}>
          <Field.Decimal
            alignRight
            autoFocus
            rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
            value={this.state.quantity}
            onChange={quantity => {
              this.setState({ quantity });
            }}
            onBlur={quantity => this.props.onChangeQuantity(quantity)}
          />
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  return {
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(SelectedSparePart);
