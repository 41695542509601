import React from 'react';
import PropTypes from 'prop-types';
import { TreePath } from 'views/components/Asset';
import { List } from 'views/components/Shared/General';
import styles from './style.module.scss';

const TitleColumn = ({ workOrder, subtitle }) => {
  const renderTitle = () => {
    return (
      <div className={styles['title-container']}>
        <div className={styles['title']}>{workOrder.title}</div>
        <div className={styles['number']}> - #{workOrder.number}</div>
      </div>
    );
  };

  const renderSubtitle = () => {
    if (subtitle) {
      return subtitle;
    }
    if (workOrder.asset_id) {
      return <TreePath assetId={workOrder.asset_id} fullPath />;
    }
    return null;
  };

  return <List.Item.TitleColumn title={renderTitle()} subtitle={renderSubtitle()} />;
};

export default TitleColumn;

TitleColumn.propTypes = {
  workOrder: PropTypes.object,
  subtitle: PropTypes.element,
};
TitleColumn.defaultProps = {
  subtitle: null,
};
