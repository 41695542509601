import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import AssetTypeContainer from './AssetTypeContainer';
import { FILTER_TYPES } from 'views/scenes/Assets/TreeAndList/List/FilterModal';

class AssetType extends Component {
  hasFiltersApplied = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return value.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <AssetTypeContainer id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    if (this.hasFiltersApplied()) {
      return (
        <SideModal.Container.Filter.AppliedFilters.Filter.Resource
          title={<FormattedMessage id="resources.asset.type" />}
          comparator={comparator}
          hasValue={value}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.AssetType,
              data: {
                [FILTER_TYPES.AssetType]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
              },
            })
          }
        >
          {this.renderSelectedValue()}
        </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: AssetsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: AssetsSelectors.getFiltersForKey(state, FILTER_TYPES.AssetType),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetType);
