import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mutateTree } from '@atlaskit/tree/';
import { buildAtlaskitTree } from 'sdk/Asset';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { Button, Field, List, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Tree } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import styles from './style.module.scss';

class AssetDeleteModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    showIsDeletingAssetModal: false,
    isDeleting: false,
    totalAssets: 0,
    totalWorkOrders: 0,
    totalRequests: 0,
    totalFiles: 0,
    excludeUnderlyingAssets: false,
    includeUnderlyingAssets: false,
    showConfirmDeleteModal: false,
    showSelectDeletionTypeModal: false,
    confirmDeletion: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showSelectDeletionTypeModal: true,
      });
      this.fetchAssetCount();
    } else if (!prevState.showConfirmDeleteModal && this.state.showConfirmDeleteModal) {
      this.fetchCounts();
    } else if (prevProps.open && !this.props.open) {
      this.setState({
        showSelectDeletionTypeModal: false,
        showConfirmDeleteModal: false,
      });
    }
  }

  fetchAssetCount = () => {
    API.fetchTree(this.props.selectedSystem.id, { tree_children_for_asset_id: this.props.asset.id }).then(
      res => {
        this.setState({
          isFetching: false,
          totalAssets: res.data.length,
          tree: buildAtlaskitTree(res.data, { rootId: this.props.asset.id }),
        });
      }
    );
  };

  fetchCounts = () => {
    this.setState({ isFetchingCounts: true });
    let params = {};
    if (this.state.includeUnderlyingAssets) {
      params.asset_with_tree_children_id = this.props.asset.id;
    } else if (this.state.excludeUnderlyingAssets) {
      params.asset_id = this.props.asset.id;
    }
    Promise.all([
      API.listWorkOrders(this.props.selectedSystem.id, params),
      API.listRequests(this.props.selectedSystem.id, params),
      API.listAttachments(this.props.selectedSystem.id, params),
    ]).then(([workOrdersRes, requestsRes, filesRes]) => {
      this.setState({
        totalWorkOrders: HelperFunctions.getPaginationFromHeader(workOrdersRes.headers).totalEntries,
        totalRequests: HelperFunctions.getPaginationFromHeader(requestsRes.headers).totalEntries,
        totalFiles: HelperFunctions.getPaginationFromHeader(filesRes.headers).totalEntries,
        isFetchingCounts: false,
      });
    });
  };

  deleteAsset = () => {
    if (this.state.isDeleting) return;
    this.setState({ isDeleting: true });

    this.props
      .deleteAsset(this.props.asset.id, {
        tree_children_mode: this.state.includeUnderlyingAssets ? 'delete' : 'move_to_parent',
      })
      .then(res => {
        if (this.props.onDelete) this.props.onDelete();
        this.props.onClose();
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="components.asset-delete.delete-success"
                values={{ title: this.props.asset.title }}
              />
            }
          />
        );
      })
      .catch(e => {
        this.setState({ isDeleting: false, showIsDeletingAssetModal: false });
        throw e;
      });
  };

  expandTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: true }),
    });
  };

  collapseTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: false }),
    });
  };

  closeModal = () => {
    if (this.state.isDeleting) {
      this.setState({ showIsDeletingAssetModal: true });
      return;
    }
    this.props.onClose();
  };

  renderDeleteButtonLabel = () => {
    if (this.state.includeUnderlyingAssets) {
      return (
        <FormattedPlural
          value={this.state.totalAssets}
          one={
            <FormattedMessage
              id="components.asset-delete.button-delete-single"
              values={{ num: this.state.totalAssets }}
            />
          }
          other={
            <FormattedMessage
              id="components.asset-delete.button-delete"
              values={{ num: this.state.totalAssets }}
            />
          }
        />
      );
    }
    return <FormattedMessage id="general.delete" />;
  };

  renderIsDeletingModal = () => (
    <Modal isOpen={this.state.showIsDeletingAssetModal} width={380}>
      <Modal.Header
        title={<FormattedMessage id="components.asset-delete.cant-close-while-deleting-modal.title" />}
        subtitle={<FormattedMessage id="components.asset-delete.cant-close-while-deleting-modal.subtitle" />}
        onClose={() => this.setState({ showIsDeletingAssetModal: false })}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            label="general.ok"
            onClick={() => {
              this.setState({ showIsDeletingAssetModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderSelectModal = () => (
    <Modal isOpen={this.state.showSelectDeletionTypeModal}>
      <Modal.Header
        title={<FormattedMessage id="components.asset-delete.title" />}
        subtitle={<FormattedMessage id="components.asset-delete.subtitle" />}
        onClose={this.props.onClose}
        ignoreLine
      />
      {this.state.isFetching ? (
        <Modal.Loader />
      ) : (
        <React.Fragment>
          <Modal.Content noPadding>
            <React.Fragment>
              <div className={styles['tree-container']}>
                <Tree
                  tree={this.state.tree}
                  onExpand={this.expandTree}
                  onCollapse={this.collapseTree}
                  clickable={false}
                  isNestingEnabled
                />
              </div>
              <div className={styles['checkbox-container']}>
                <div className={styles['warning']}>
                  <FormattedMessage id="components.asset-delete.warning-message" />
                </div>
                <Field.Checkbox.Group>
                  <Field.Checkbox
                    checked={this.state.excludeUnderlyingAssets}
                    radio
                    onChange={value =>
                      this.setState({ excludeUnderlyingAssets: true, includeUnderlyingAssets: false })
                    }
                    label={
                      <FormattedMessage
                        id="components.asset-delete.exclude-underlying-assets-text"
                        values={{ asset: this.props.asset.title }}
                      />
                    }
                  />
                  <Field.Checkbox
                    checked={this.state.includeUnderlyingAssets}
                    radio
                    onChange={value => {
                      if (this.state.totalAssets === 1) return;
                      this.setState({ excludeUnderlyingAssets: false, includeUnderlyingAssets: true });
                    }}
                    label={
                      <div>
                        <span className={this.state.totalAssets === 1 ? styles['no-underlying-assets'] : ''}>
                          <FormattedMessage id="components.asset-delete.include-underlying-assets-text" />
                        </span>
                        <span className={styles['subtitle']}>
                          <span> - </span>
                          {this.state.totalAssets === 1 ? (
                            <FormattedMessage id="components.asset-delete.no-underlying-assets" />
                          ) : (
                            <>
                              <FormattedMessage
                                id="components.asset-delete.include-underlying-assets-value"
                                values={{ amount: this.state.totalAssets }}
                              />
                              <span className={styles['warning-icon']}>
                                <Icon regular red type="exclamation-triangle" />
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    }
                  />
                </Field.Checkbox.Group>
              </div>
            </React.Fragment>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                destructive
                primary
                label={this.renderDeleteButtonLabel()}
                loading={this.state.isDeleting}
                disabled={!this.state.includeUnderlyingAssets && !this.state.excludeUnderlyingAssets}
                onClick={() => {
                  if (!this.state.includeUnderlyingAssets && !this.state.excludeUnderlyingAssets) return null;

                  this.setState({ showSelectDeletionTypeModal: false });
                  setTimeout(() => {
                    this.setState({ showConfirmDeleteModal: true });
                  }, 200);
                }}
                translate={false}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </React.Fragment>
      )}
    </Modal>
  );

  renderConfirmModal = () => (
    <Modal width={480} isOpen={this.state.showConfirmDeleteModal}>
      <Modal.Header
        title={<FormattedMessage id="components.asset-delete.confirm-title" />}
        onClose={this.closeModal}
        ignoreLine
      />
      {this.state.isFetchingCounts ? (
        <Modal.Loader />
      ) : (
        <>
          <Modal.Content>
            <div className={styles['message']}>
              <FormattedMessage id="components.asset-delete.confirm-subtitle" />
              {this.state.includeUnderlyingAssets ? (
                <div className={styles['warning-message']}>
                  <Icon regular red type="exclamation-triangle" />
                  <FormattedMessage id="components.asset-delete.confirm-warning" />
                </div>
              ) : null}
            </div>
            <div className={styles['list-container']}>
              <List light small>
                <List.Item>
                  <List.Item.TitleColumn
                    title={<FormattedMessage id="components.asset-delete.delete-items.assets" />}
                  />
                  <List.Item.Column alignRight>
                    <span
                      className={this.state.totalAssets > 0 ? styles['warning-text'] : styles['empty-text']}
                    >
                      <FormattedMessage
                        id="components.asset-delete.delete-items.asset-amount"
                        values={{ amount: this.state.includeUnderlyingAssets ? this.state.totalAssets : 1 }}
                      />
                    </span>
                  </List.Item.Column>
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn
                    title={<FormattedMessage id="components.asset-delete.delete-items.work-orders" />}
                  />
                  <List.Item.Column alignRight>
                    <span
                      className={
                        this.state.totalWorkOrders > 0 ? styles['warning-text'] : styles['empty-text']
                      }
                    >
                      <FormattedMessage
                        id="components.asset-delete.delete-items.work-order-amount"
                        values={{ amount: this.state.totalWorkOrders }}
                      />
                    </span>
                  </List.Item.Column>
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn
                    title={<FormattedMessage id="components.asset-delete.delete-items.requests" />}
                  />
                  <List.Item.Column alignRight>
                    <span
                      className={this.state.totalRequests > 0 ? styles['warning-text'] : styles['empty-text']}
                    >
                      <FormattedMessage
                        id="components.asset-delete.delete-items.request-amount"
                        values={{ amount: this.state.totalRequests }}
                      />
                    </span>
                  </List.Item.Column>
                </List.Item>
                <List.Item>
                  <List.Item.TitleColumn
                    title={<FormattedMessage id="components.asset-delete.delete-items.attachments" />}
                  />
                  <List.Item.Column alignRight>
                    <span
                      className={this.state.totalFiles > 0 ? styles['warning-text'] : styles['empty-text']}
                    >
                      <FormattedMessage
                        id="components.asset-delete.delete-items.attachment-amount"
                        values={{ amount: this.state.totalFiles }}
                      />
                    </span>
                  </List.Item.Column>
                </List.Item>
              </List>
            </div>
            <Field.Checkbox
              checked={this.state.confirmDeletion}
              onChange={value => this.setState({ confirmDeletion: value })}
              label={<FormattedMessage id="components.asset-delete.confirm-checkbox-text" />}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                destructive
                disabled={!this.state.confirmDeletion}
                primary
                label={this.renderDeleteButtonLabel()}
                loading={this.state.isDeleting}
                onClick={this.deleteAsset}
                translate={false}
              />
              <Button label="general.cancel" onClick={this.closeModal} />
            </Button.Group>
          </Modal.Footer>
        </>
      )}
      {this.renderIsDeletingModal()}
    </Modal>
  );

  render() {
    return (
      <>
        {this.renderConfirmModal()}
        {this.renderSelectModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAsset: SDKReduxOperations.deleteAsset,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetDeleteModal));
