import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const resetSelectedWorkOrders = payload => ({ type: types.RESET_SELECTED_WORK_ORDERS, payload });

const selectWorkOrder = payload => ({ type: types.SELECT_WORK_ORDER, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const setLoading = payload => ({ type: types.SET_LOADING, payload });

const fetchWorkOrders = payload => ({ type: types.FETCH_WORK_ORDERS, payload });
const fetchWorkOrdersSuccess = ({ ids, entities, pagination }) => ({
  type: types.FETCH_WORK_ORDERS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

const fetchWorkOrderListCounts = payload => ({
  type: types.FETCH_WORK_ORDER_COUNTS,
});
const fetchWorkOrderListCountsSuccess = payload => ({
  type: types.FETCH_WORK_ORDER_COUNTS_SUCCESS,
  payload,
});

const beginDrag = payload => ({
  type: types.BEGIN_DRAG,
  payload: payload,
});

const dropCalendarInstance = payload => ({
  type: types.DROP_CALENDAR_INSTANCE,
  payload: payload,
});

const endDrag = payload => ({
  type: types.END_DRAG,
  payload: payload,
});

const showPreviewOverlay = payload => ({
  type: types.SHOW_PREVIEW_OVERLAY,
  payload: payload,
});

const hidePreviewOverlay = () => ({
  type: types.HIDE_PREVIEW_OVERLAY,
});

const showMoreWorkOrdersPreviewOverlay = payload => ({
  type: types.SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
  payload: payload,
});

const hideMoreWorkOrdersPreviewOverlay = () => ({
  type: types.HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
});

const fetchDataBetweenDates = payload => ({
  type: types.FETCH_DATA_BETWEEN_DATES,
  payload,
});
const fetchDataBetweenDatesSuccess = payload => ({
  type: types.FETCH_DATA_BETWEEN_DATES_SUCCESS,
  payload: payload,
});

export default {
  showAppliedFilters,
  setNavigatedTo,
  hideSelectTotalEntries,
  selectTotalEntries,
  resetSelectedWorkOrders,
  selectPage,
  selectWorkOrder,
  resetFilter,
  addQueryParameter,
  showAvailableFilters,
  removeFilter,
  selectFilterType,
  addFilter,
  beginDrag,
  dropCalendarInstance,
  endDrag,
  setLoading,
  fetchDataBetweenDates,
  fetchDataBetweenDatesSuccess,
  fetchWorkOrders,
  fetchWorkOrdersSuccess,
  showPreviewOverlay,
  hidePreviewOverlay,
  showMoreWorkOrdersPreviewOverlay,
  hideMoreWorkOrdersPreviewOverlay,
  fetchWorkOrderListCounts,
  fetchWorkOrderListCountsSuccess,
};
