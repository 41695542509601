import types from './types';
import { isEqual } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  editingWorkOrder: null,
  editingWorkOrderBeforeEdit: null,
  hasUnsavedChanges: false,
  isFetchingPreviousWorkOrders: false,
  previousWorkOrderIds: [],
  workOrderActivityData: {},
  pagination: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;

    case types.FETCH_WORK_ORDER: {
      return {
        ...state,
        id: action.payload,
        editingWorkOrder: null,
        editingWorkOrderBeforeEdit: null,
        hasUnsavedChanges: false,
      };
    }
    case types.RESET_EDITING_WORK_ORDER: {
      return {
        ...state,
        editingWorkOrder: null,
        editingWorkOrderBeforeEdit: null,
        hasUnsavedChanges: false,
      };
    }
    case types.SET_WORK_ORDER_FOR_EDIT: {
      const { assigned_to_user, assigned_to_group, assigned_to_vendor, ...editingWorkOrder } = action.payload;

      return {
        ...state,
        id: editingWorkOrder.id,
        editingWorkOrder,
        editingWorkOrderBeforeEdit: editingWorkOrder,
        hasUnsavedChanges: false,
      };
    }
    case types.SET_EDITING_WORK_ORDER_VALUES: {
      const editingWorkOrder = {
        ...state.editingWorkOrder,
        ...action.payload,
      };

      return {
        ...state,
        editingWorkOrder,
        hasUnsavedChanges: !isEqual(editingWorkOrder, state.editingWorkOrderBeforeEdit),
      };
    }
    case types.FETCH_PREVIOUS_WORK_ORDERS:
      return {
        ...state,
        isFetchingPreviousWorkOrders: true,
      };
    case types.FETCH_PREVIOUS_WORK_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        previousWorkOrderIds: ids,
        pagination,
        isFetchingPreviousWorkOrders: false,
      };
    }
    case types.FETCH_WORK_ORDER_ACTIVITIES_SUCCESS: {
      const { dateData } = action.payload;
      return {
        ...state,
        workOrderActivityData: dateData,
      };
    }

    case SDKReduxTypes.RECURRING_MAINTENANCE_CREATED: {
      const { params } = action.payload;
      if (state.id === params.converted_from_work_order_id) {
        return {
          ...state,
          hasUnsavedChanges: false,
        };
      }
      return state;
    }
    case SDKReduxTypes.RECURRING_MAINTENANCE_ARCHIVED: {
      return {
        ...state,
        editingWorkOrder: null,
        editingWorkOrderBeforeEdit: null,
        hasUnsavedChanges: false,
      };
    }
    case SDKReduxTypes.WORK_ORDER_UPDATED: {
      const { workOrderId } = action.payload;
      if (state.id === workOrderId) {
        return {
          ...state,
          hasUnsavedChanges: false,
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.RECURRING_MAINTENANCE_UPDATED: {
      const { recurringMaintenanceId } = action.payload;
      if (state.id === recurringMaintenanceId) {
        return {
          ...state,
          hasUnsavedChanges: false,
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.WORK_ORDER_TYPE_DELETED: {
      const { workOrderTypeId: deletedId } = action.payload;
      const { editingWorkOrder } = state;

      if (!editingWorkOrder) return state;

      return {
        ...state,
        editingWorkOrder: {
          ...editingWorkOrder,
          work_order_type_id:
            editingWorkOrder.work_order_type_id === deletedId ? null : editingWorkOrder.work_order_type_id,
        },
      };
    }
    default:
      return state;
  }
};
