import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Decimal } from 'decimal.js';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import { HelperFunctions } from 'sdk';
import { MoneyWithCurrency, EmptyDataSet } from 'views/components/Shared/General';
import { REPORT_PER_TYPES } from 'sdk/Cost';
import styles from './style.module.scss';

class ListView extends PureComponent {
  state = {
    isBuildingDataStructure: true,
  };

  componentDidMount() {
    const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
    const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
    if (this.props.isFetching) {
      return;
    }
    if (showingWorkOrderTypes) {
      this.buildWorkOrderTypesDataStructure();
    } else if (showingAssets) {
      this.buildAssetsDataStructure();
    } else {
      this.setState({ isBuildingDataStructure: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.perType !== this.props.perType) {
      this.setState({ isBuildingDataStructure: true });
    } else if (!prevProps.isFetching && this.props.isFetching) {
      this.setState({ isBuildingDataStructure: true });
    } else {
      const finishedLoading = prevProps.isFetching && !this.props.isFetching;
      const changedSortOrder = prevProps.sortingOnPerType !== this.props.sortingOnPerType;
      if (finishedLoading || (!this.props.isFetching && changedSortOrder)) {
        const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
        const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
        if (showingWorkOrderTypes) {
          this.buildWorkOrderTypesDataStructure();
        } else if (showingAssets) {
          this.buildAssetsDataStructure();
        } else {
          this.setState({ isBuildingDataStructure: false });
        }
      }
    }
  }

  buildAssetsDataStructure = () => {
    let assets = this.props.assets.map(({ title, id }) => ({
      title,
      cost: new Decimal(this.props.data[id] || 0),
      id,
    }));
    if (this.props.sortingOnType === this.props.type) {
      assets = assets.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      assets = assets.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      assets,
      isBuildingDataStructure: false,
    });
  };

  buildWorkOrderTypesDataStructure = () => {
    let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
      title,
      cost: new Decimal(this.props.data[id] || 0),
    }));
    if (this.props.sortingOnType === this.props.type) {
      workOrderTypes = workOrderTypes.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      workOrderTypes,
      isBuildingDataStructure: false,
    });
  };

  renderPriorityList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const items = [
        { priority: 'high', cost: new Decimal(this.props.data['high'] || 0) },
        { priority: 'medium', cost: new Decimal(this.props.data['medium'] || 0) },
        { priority: 'low', cost: new Decimal(this.props.data['low'] || 0) },
      ];
      items.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      return items.map(({ priority, cost }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <FormattedMessage id={`resources.work-order.prio.${priority}`} />
          </div>
          <div className={styles['value']}>
            <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.high" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['high'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.medium" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['medium'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.low" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['low'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderInternalExternalList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const items = [
        { external: false, cost: new Decimal(this.props.data['false'] || 0) },
        { external: true, cost: new Decimal(this.props.data['true'] || 0) },
      ];
      items.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      return items.map(({ external, cost }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            {external ? (
              <FormattedMessage id="components.costs-overview.external" />
            ) : (
              <FormattedMessage id="components.costs-overview.internal" />
            )}
          </div>
          <div className={styles['value']}>
            <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="components.costs-overview.internal" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['false'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="components.costs-overview.external" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['true'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderWorkOrderTypeList = () => {
    if (this.props.workOrderTypes.length === 0)
      return (
        <div className={styles['list-item-empty-data-set']}>
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.costs-overview.no-work-order-types-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.costs-overview.no-work-order-types-empty-data-set.subtitle" />
            }
          />
        </div>
      );
    return this.state.workOrderTypes.map(({ title, cost }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{title}</span>
        </div>
        <div className={styles['value']}>
          <MoneyWithCurrency currency={this.props.currency} value={cost} fractionSize={0} />
        </div>
      </div>
    ));
  };

  renderAssetList = () => {
    if (this.props.assets.length === 0) {
      if (this.props.showingRootAssets) {
        return (
          <div className={styles['list-item-empty-data-set']}>
            <EmptyDataSet
              modal
              title={<FormattedMessage id="components.costs-overview.no-assets-empty-data-set.title" />}
              subtitle={<FormattedMessage id="components.costs-overview.no-assets-empty-data-set.subtitle" />}
            />
          </div>
        );
      } else {
        return (
          <div className={styles['list-item-empty-data-set']}>
            <EmptyDataSet
              modal
              title={
                <FormattedMessage id="components.costs-overview.no-children-assets-empty-data-set.title" />
              }
              subtitle={
                <FormattedMessage id="components.costs-overview.no-children-assets-empty-data-set.subtitle" />
              }
            />
          </div>
        );
      }
    }
    return this.state.assets.map(({ title, cost, id }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{title}</span>
        </div>
        <div className={styles['value']}>
          <MoneyWithCurrency currency={this.props.currency} value={cost} fractionSize={0} />
        </div>
      </div>
    ));
  };

  renderYearList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    const currentYear = moment().year();
    let amountOfYearsSinceSystemWasCreated = currentYear - this.props.yearSystemWasCreated;
    let items = []; // [{ year, cost: Decimal }]
    for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
      const year = currentYear - i;
      items.push({ year, cost: new Decimal(this.props.data[year] || 0) });
    }
    if (sortingOnType === type) {
      items.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
    } else if (sortingOnPerType === perType) {
      items.sort((a, b) => a.year - b.year);
    }
    return items.map(({ year, cost }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>{year}</div>
        <div className={styles['value']}>
          <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
        </div>
      </div>
    ));
  };

  renderMonthsList = () => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({
        monthNum: i,
        month: HelperFunctions.capitalizeFirstLetter(
          moment()
            .month(i - 1)
            .format('MMMM')
        ),
        cost: new Decimal(this.props.data[i] || 0),
      });
    }
    if (this.props.sortingOnType === this.props.type) {
      months.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      months.sort((a, b) => a.monthNum - b.monthNum);
    }
    return months.map(({ month, cost }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>{month}</div>
        <div className={styles['value']}>
          <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
        </div>
      </div>
    ));
  };

  renderLoadingList = () => (
    <>
      <div className={styles['item']}>
        <div className={styles['label']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
        <div className={styles['value']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={styles['item']}>
        <div className={styles['label']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
        <div className={styles['value']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
      </div>
    </>
  );

  renderCategoriesSimpleList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const categories = [
        {
          category: 'corrective',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          cost: new Decimal(this.props.data['corrective'] || 0),
        },
        {
          category: 'preventive',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          cost: new Decimal(this.props.data['preventive'] || 0),
        },
        {
          category: 'improvement',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
          cost: new Decimal(this.props.data['improvement'] || 0),
        },
        {
          category: 'modification',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
          cost: new Decimal(this.props.data['modification'] || 0),
        },
      ];
      categories.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      return categories.map(({ cost, label }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <span>{label}</span>
          </div>
          <div className={styles['value']}>
            <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['corrective'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['preventive'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.improvement" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['improvement'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.modification" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['modification'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderCategoriesDetailedList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const categories = [
        {
          category: 'corrective_immediate',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-immediate',
          })}`,
          cost: new Decimal(this.props.data['corrective_immediate'] || 0),
        },
        {
          category: 'corrective_defered',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-defered',
          })}`,
          cost: new Decimal(this.props.data['corrective_defered'] || 0),
        },
        {
          category: 'preventive_condition_based',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-condition-based',
          })}`,
          cost: new Decimal(this.props.data['preventive_condition_based'] || 0),
        },
        {
          category: 'preventive_predetermined',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-predetermined',
          })}`,
          cost: new Decimal(this.props.data['preventive_predetermined'] || 0),
        },
      ];
      categories.sort((a, b) => (b.cost.greaterThan(a.cost) ? 1 : -1));
      return categories.map(({ cost, label }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <span>{label}</span>
          </div>
          <div className={styles['value']}>
            <MoneyWithCurrency currency={this.props.currency} value={cost.toString()} fractionSize={0} />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['corrective_immediate'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['corrective_defered'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['preventive_condition_based'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
            <div className={styles['value']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.data['preventive_predetermined'] || 0}
                fractionSize={0}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderList = () => {
    if (this.props.isFetching || this.state.isBuildingDataStructure) return this.renderLoadingList();
    switch (this.props.perType) {
      case REPORT_PER_TYPES.Year: {
        return this.renderYearList();
      }
      case REPORT_PER_TYPES.Month: {
        return this.renderMonthsList();
      }
      case REPORT_PER_TYPES.Priority: {
        return this.renderPriorityList();
      }
      case REPORT_PER_TYPES.External: {
        return this.renderInternalExternalList();
      }
      case REPORT_PER_TYPES.WorkOrderType: {
        return this.renderWorkOrderTypeList();
      }
      case REPORT_PER_TYPES.Asset: {
        return this.renderAssetList();
      }
      case REPORT_PER_TYPES.WorkOrderCategorySimple: {
        return this.renderCategoriesSimpleList();
      }
      case REPORT_PER_TYPES.WorkOrderCategoryDetailed: {
        return this.renderCategoriesDetailedList();
      }

      default:
        return null;
    }
  };

  renderHeader = () => {
    if (this.props.perType === REPORT_PER_TYPES.WorkOrderType && this.props.workOrderTypes.length === 0)
      return null;
    if (this.props.perType === REPORT_PER_TYPES.Asset && this.props.assets.length === 0) return null;
    return (
      <div className={styles['header']}>
        <div className={styles['label']}>
          <FormattedMessage id={`components.costs-overview.show-per-types.${this.props.perType}`} />
        </div>
        <div className={styles['value']}>
          <FormattedMessage id={`components.costs-overview.show-types.${this.props.type}`} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles['list']}>
        {this.renderHeader()}
        {this.renderList()}
      </div>
    );
  }
}

export default injectIntl(ListView);
