import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button, InlineModal, Icon } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class EditMeterUnitModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      hasUnsavedChanges: false,
      showDeleteInlineModal: false,
      showUsedError: false,
      editingMeterUnit: {},
      editingMeterUnitBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        isDeleting: false,
        hasUnsavedChanges: false,
        showDeleteInlineModal: false,
        showUsedError: false,
      });

      const { meterUnit } = this.props;
      if (meterUnit) {
        const editingMeterUnit = {
          title: meterUnit.title,
          abbreviation: meterUnit.abbreviation,
          default: meterUnit.default,
        };
        this.setState({
          editingMeterUnit,
          editingMeterUnitBeforeEdit: editingMeterUnit,
        });
      } else {
        const editingMeterUnit = {
          title: '',
          abbreviation: '',
          default: false,
        };
        this.setState({
          editingMeterUnit,
          editingWorkOrderBeforeEdit: editingMeterUnit,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createMeterUnit(this.props.system.id, this.state.editingMeterUnit)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.meters.units.create-success" />}
          />
        );
        this.props.onCreate(data.id);
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updateMeterUnit(this.props.id, this.state.editingMeterUnit)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteMeterUnit(this.props.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.meters.units.delete-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '30010' })) {
          this.setState({ showUsedError: true, isDeleting: false });
        }
      });
  };

  setValue = values => {
    const editingMeterUnit = {
      ...this.state.editingMeterUnit,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingMeterUnitBeforeEdit, editingMeterUnit);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingMeterUnit, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderDeleteContent = () => {
    if (this.props.id == null) return null;
    if (this.state.showUsedError) {
      return (
        <div className={styles['already-used-error']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.settings.meters.units.delete-used-error.title" />
          </div>
          <div className={styles['message']}>
            <FormattedMessage id="screens.settings.meters.units.delete-used-error.message" />
          </div>
        </div>
      );
    }
    return (
      <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.delete}>
        <FormattedMessage id="general.delete-confirm.subtitle" />
      </InlineModal.Delete>
    );
  };

  renderDeleteInlineModal = () => {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>
          <Button
            type="icon"
            icon={<Icon regular red type="trash-alt" />}
            onClick={() =>
              this.setState(prevState => ({
                confirmDelete: false,
                showUsedError: false,
                showDeleteInlineModal: !prevState.showDeleteInlineModal,
              }))
            }
          />
        </div>
        <InlineModal
          open={this.state.showDeleteInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showDeleteInlineModal: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showDeleteInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.meters.units.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.meters.units.new-title" />
            )
          }
          iconButtons={this.props.id == null ? null : this.renderDeleteInlineModal()}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label={<FormattedMessage id="resources.spare-part-unit.title" />}>
                  <Field.Text
                    autoFocus
                    value={this.state.editingMeterUnit.title}
                    onChange={title => {
                      this.setValue({ title });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label={<FormattedMessage id="resources.spare-part-unit.abbreviation" />}>
                  <Field.Text
                    onKeyPress={this.handleKeyPress}
                    value={this.state.editingMeterUnit.abbreviation}
                    onChange={abbreviation => {
                      this.setValue({ abbreviation });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field.Checkbox
                  label={this.props.intl.formatMessage({
                    id: 'resources.spare-part-unit.default',
                  })}
                  checked={this.state.editingMeterUnit.default}
                  onChange={value => {
                    this.setValue({ default: value });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.editingMeterUnit.title || !this.state.editingMeterUnit.abbreviation}
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createMeterUnit: SDKReduxOperations.createMeterUnit,
      updateMeterUnit: SDKReduxOperations.updateMeterUnit,
      deleteMeterUnit: SDKReduxOperations.deleteMeterUnit,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    meterUnit: EntitySelectors.getMeterUnit(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditMeterUnitModal));
