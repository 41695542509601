import React, { Component } from 'react';
import { Button, Field, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { injectIntl } from 'react-intl';

class NewAssetItem extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.props.onCancel();
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.value && !this.props.isCreatingAsset) {
      this.props.onSave(this.state.value);
    }
  };

  render() {
    return (
      <div className={styles['new-item']} ref={ref => (this.isPreparingContainerRef = ref)}>
        <Field.Text
          disabled={this.props.isCreatingAsset}
          placeholder={this.props.intl.formatMessage({
            id: 'screens.assets.tree.is-preparing-asset-placeholder',
          })}
          autoFocus
          onChange={value => this.setState({ value })}
          onKeyPress={this.handleKeyPress}
        />
        <Button
          primary
          label="general.save"
          loading={this.props.isCreatingAsset === true}
          small
          disabled={this.state.value.length === 0}
          onClick={() => {
            if (this.props.isCreatingAsset) {
              return;
            }
            this.props.onSave(this.state.value);
          }}
        />
        <Button
          type="icon"
          icon={<Icon light size={18} type="times" />}
          small
          onClick={this.props.onCancel}
        />
      </div>
    );
  }
}

export default injectIntl(NewAssetItem);
