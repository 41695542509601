import React, { Component } from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { List, ViewTextArea, Datapill, FormattedNumber } from 'views/components/Shared/General';
import {
  AssigneeColumn,
  DueDateColumn,
  StatusColumn,
  CategoryText,
  PriorityText,
} from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import { LIST_TYPES } from 'views/scenes/WorkOrders/WorkOrderList';
import PurchaseOrderColumn from './PurchaseOrderColumn';
import ExpectedDeliveryDateColumn from './ExpectedDeliveryDateColumn';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { WorkOrderCategoryType } from 'sdk/System';
import styles from './style.module.scss';

class WorkOrderListItem extends Component {
  renderSubtitle = () => {
    if (this.props.workOrder.asset_id) {
      return <TreePath assetId={this.props.workOrder.asset_id} fullPath />;
    }
    return null;
  };

  renderListItemColumns = () => {
    switch (this.props.list) {
      case LIST_TYPES.Completed: {
        return (
          <>
            <List.Item.TitleColumn title={this.props.workOrder.title} subtitle={this.renderSubtitle()} />
            <List.Item.Column width={140}>
              {moment(this.props.workOrder.completed_date).format('LL')}
            </List.Item.Column>
            <List.Item.Column width={72}>
              <AssigneeColumn
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            </List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn icon workOrder={this.props.workOrder} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.Today: {
        return (
          <>
            <List.Item.TitleColumn title={this.props.workOrder.title} subtitle={this.renderSubtitle()} />
            <List.Item.Column width={180}>
              <DueDateColumn today workOrder={this.props.workOrder} />
            </List.Item.Column>
            <List.Item.Column width={72}>
              <AssigneeColumn
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            </List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn icon workOrder={this.props.workOrder} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.ThisWeek: {
        return (
          <>
            <List.Item.TitleColumn title={this.props.workOrder.title} subtitle={this.renderSubtitle()} />
            <List.Item.Column width={180}>
              <DueDateColumn weekDay workOrder={this.props.workOrder} />
            </List.Item.Column>
            <List.Item.Column width={72}>
              <AssigneeColumn
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            </List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn icon workOrder={this.props.workOrder} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.AwaitingSpareParts: {
        return (
          <>
            <List.Item.TitleColumn title={this.props.workOrder.title} subtitle={this.renderSubtitle()} />
            <List.Item.Column width={180}>
              <PurchaseOrderColumn workOrder={this.props.workOrder} />
            </List.Item.Column>
            <List.Item.Column width={180} alignRight>
              <ExpectedDeliveryDateColumn workOrder={this.props.workOrder} />
            </List.Item.Column>
          </>
        );
      }
      default: {
        return (
          <>
            <List.Item.TitleColumn title={this.props.workOrder.title} subtitle={this.renderSubtitle()} />
            <List.Item.Column width={180}>
              <DueDateColumn workOrder={this.props.workOrder} />
            </List.Item.Column>
            <List.Item.Column width={72}>
              <AssigneeColumn
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            </List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn icon workOrder={this.props.workOrder} />
              </div>
            </List.Item.Column>
          </>
        );
      }
    }
  };

  renderExpandableComponentText = () => {
    if (this.props.workOrder.completed_before_registration) {
      return this.props.workOrder.completed_comment || '-';
    }
    return this.props.workOrder.description || '-';
  };

  renderExpandableComponent = () => {
    return (
      <div className={styles['expandable-container']}>
        <ViewTextArea>{this.renderExpandableComponentText()}</ViewTextArea>
      </div>
    );
  };

  renderCategoryDataPill = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    if (this.props.workOrder.category == null) {
      return null;
    }
    return <Datapill value={<CategoryText category={this.props.workOrder.category} />} />;
  };

  renderPriorityDataPill = () => {
    if (this.props.workOrder.priority) {
      return <Datapill value={<PriorityText priority={this.props.workOrder.priority} />} />;
    }
    return null;
  };

  renderWorkOrderTypeDataPill = () => {
    if (this.props.workOrderType) {
      return <Datapill value={this.props.workOrderType.title} />;
    }
    return null;
  };

  renderRecurringDataPill = () => {
    const { recurringMaintenance } = this.props;

    if (!recurringMaintenance) return null;
    if (recurringMaintenance.status === 'archived') return null;
    if (recurringMaintenance.meter_id) {
      return (
        <Datapill
          icon="repeat"
          value={
            <FormattedNumber
              value={this.props.recurringMaintenance.meter_interval}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          }
        />
      );
    }
    return (
      <Datapill
        icon="repeat"
        value={buildIntervalMessage(this.props.recurringMaintenance, this.props.intl)}
      />
    );
  };

  renderWorkOrderNumberDataPill = () => {
    return <Datapill value={`#${this.props.workOrder.number}`} />;
  };

  renderAssetNumberDataPill = () => {
    if (this.props.settings.asset_number_activated && this.props.asset) {
      return (
        <Datapill
          label={<FormattedMessage id="resources.asset.number-long" />}
          value={`#${this.props.asset.number}`}
        />
      );
    }
    return null;
  };

  renderChecklistFilledRowsCountDatapill = () => {
    if (this.props.checklistTemplateVersion) {
      let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;
      let filledRowsCount = 0;
      if (this.props.checklistInstance) {
        filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
      }
      return (
        <Datapill
          icon="list"
          value={
            <span>
              <span>{filledRowsCount}</span>
              <span> / </span>
              <span>{amountOfRows}</span>
            </span>
          }
        />
      );
    }
    return null;
  };

  renderDataPills = () => {
    return (
      <Datapill.List>
        {this.renderWorkOrderNumberDataPill()}
        {this.renderCategoryDataPill()}
        {this.renderRecurringDataPill()}
        {this.renderWorkOrderTypeDataPill()}
        {this.renderPriorityDataPill()}
        {this.renderChecklistFilledRowsCountDatapill()}
        {this.renderAssetNumberDataPill()}
      </Datapill.List>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox expandable>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          small
          clickable
          expandable
          expandedComponent={this.renderExpandableComponent()}
          checked={this.props.checked}
          checkboxDisabled={this.props.checkboxDisabled}
          checkbox
          dataPillsComponent={this.renderDataPills()}
          onCheck={this.props.onCheck}
          onClick={this.props.onClick}
          linkTo={{
            pathname: `/work-orders/${this.props.workOrder.id}`,
          }}
        >
          {this.renderListItemColumns()}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.id);
  let meter = EntitySelectors.getMeter(state, workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  let checklistInstances = EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances);
  let checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    workOrder.checklist_template_links
  );
  let checklistInstance = null;
  let checklistTemplateLink = null;
  let checklistTemplateVersion = null;
  let checklistTemplate = null;
  if (checklistInstances && checklistInstances.length === 1) {
    checklistInstance = checklistInstances[0];
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
  }
  if (checklistTemplateLinks && checklistTemplateLinks.length === 1) {
    checklistTemplateLink = checklistTemplateLinks[0];
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLink.checklist_template_id
    );
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version_id
    );
  }
  return {
    workOrder,
    meter,
    meterUnit,
    checklistInstance,
    checklistTemplateVersion,
    workOrderType: EntitySelectors.getWorkOrderType(state, workOrder.work_order_type_id),
    asset: EntitySelectors.getAsset(state, workOrder.asset_id),
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps)(WorkOrderListItem));
