import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, WhiteCard, Pagination } from 'views/components/Shared/General';
import { SelectSparePartModal, NewSparePartModal } from 'views/components/SparePart';
import { MultipleSparePartVendorsModal, DeleteSparePartVendorModal } from 'views/components/SparePartVendor';
import { Loader } from 'views/components/Shared/General';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import SparePartListItem from './SparePartListItem';
import SparePartVendorModal from './SparePartVendorModal';
import styles from './style.module.scss';
import MultipleSparePartVendorsDeleteModal from 'views/components/SparePartVendor/MultipleSparePartVendorsDeleteModal';

class SpareParts extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      showSelectSparePartModal: false,
      isFetching: false,
      viewInitialized: false,
      queryParams: queryParams,
      showCreateNewSparePartModal: false,
      showSparePartVendorModal: false,
      showSparePartVendorModalForSparePartVendorId: null,
      createSparePartVendorForSparePartId: null,
      showMultipleSparePartVendorsModal: false,
      showMultipleSparePartVendorsModalForSparePartId: null,
      openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
      openedSparePartVendorModalFromSelectSparePartModal: false,
      isAddingAlternativeArticle: false,
      showDeleteSparePartVendorModal: false,
      showDeleteSparePartVendorModalForId: null,
      showDeleteMultipleSparePartVendorsModal: false,
      showDeleteMultipleSparePartVendorsModalForSparePartId: null,
    };
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    this.fetchSpareParts()
      .then(() => {
        this.setState({ viewInitialized: true, isFetching: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, isFetching: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ isFetching: true });
        this.fetchSpareParts()
          .then(() => {
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          });
      });
    }
  }

  delete = sparePartVendors => {
    if (sparePartVendors.length === 1) {
      this.setState({
        showDeleteSparePartVendorModalForId: sparePartVendors[0].id,
        showDeleteSparePartVendorModal: true,
      });
    } else {
      this.setState({
        showDeleteMultipleSparePartVendorsModal: true,
        showDeleteMultipleSparePartVendorsModalForSparePartId: sparePartVendors[0].spare_part_id,
      });
    }
  };

  fetchSpareParts = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    return this.props.fetchSpareParts(this.props.currentSystem.id, this.props.match.params.id, {
      ...params,
      ...filterParams,
    });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  renderSparePartVendorModal = () => (
    <>
      <SparePartVendorModal
        open={this.state.showSparePartVendorModal}
        createForVendorId={this.props.match.params.id}
        createForSparePartId={this.state.createSparePartVendorForSparePartId}
        isAddingArticleForExistingVendor={this.state.isAddingAlternativeArticle}
        openedFromMultipleSparePartVendorsModal={
          this.state.openedSparePartVendorModalFromMultipleSparePartVendorsModal
        }
        id={this.state.showSparePartVendorModalForSparePartVendorId}
        onDelete={sparePartVendorId =>
          setTimeout(
            () =>
              this.setState({
                showDeleteSparePartVendorModal: true,
                showDeleteSparePartVendorModalForId: sparePartVendorId,
              }),
            250
          )
        }
        onAddArticle={sparePartId => {
          this.setState({ showSparePartVendorModal: false });
          setTimeout(() => {
            this.setState({
              showSparePartVendorModal: true,
              isAddingAlternativeArticle: true,
              openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
              createSparePartVendorForSparePartId: sparePartId,
              showSparePartVendorModalForSparePartVendorId: null,
            });
          }, 250);
        }}
        onClose={isDeleting => {
          if (this.state.openedSparePartVendorModalFromMultipleSparePartVendorsModal) {
            this.setState({
              showSparePartVendorModal: false,
            });
            if (!isDeleting) {
              setTimeout(
                () =>
                  this.setState({
                    showMultipleSparePartVendorsModal: true,
                  }),
                250
              );
            }
          } else if (this.state.openedSparePartVendorModalFromSelectSparePartModal) {
            this.setState({
              showSparePartVendorModal: false,
            });
            setTimeout(() => {
              this.setState({
                showSelectSparePartModal: true,
                showSparePartVendorModalForSparePartVendorId: null,
                openedSparePartVendorModalFromSelectSparePartModal: false,
              });
            }, 250);
          } else {
            this.setState({
              showSparePartVendorModal: false,
            });
          }
        }}
      />
      <DeleteSparePartVendorModal
        open={this.state.showDeleteSparePartVendorModal}
        id={this.state.showDeleteSparePartVendorModalForId}
        title={
          <FormattedMessage id="screens.vendor.spare-parts.vendor-spare-part-modal.confirm-delete.title" />
        }
        subtitle={
          <FormattedMessage id="screens.vendor.spare-parts.vendor-spare-part-modal.confirm-delete.subtitle" />
        }
        onClose={() =>
          this.setState({ showDeleteSparePartVendorModal: false, showDeleteSparePartVendorModalForId: null })
        }
      />
    </>
  );

  renderMultipleSparePartVendorsModal = () => {
    return (
      <MultipleSparePartVendorsModal
        vendorId={this.props.match.params.id}
        sparePartId={this.state.showMultipleSparePartVendorsModalForSparePartId}
        open={this.state.showMultipleSparePartVendorsModal}
        onDeleteAll={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(
            () =>
              this.setState({
                showDeleteMultipleSparePartVendorsModal: true,
                showDeleteMultipleSparePartVendorsDeleteModalForSparePartId:
                  this.state.showMultipleSparePartVendorsModalForSparePartId,
              }),
            250
          );
        }}
        onDelete={sparePartVendor =>
          setTimeout(
            () =>
              this.setState({
                showDeleteSparePartVendorModal: true,
                showDeleteSparePartVendorModalForId: sparePartVendor.id,
              }),
            250
          )
        }
        onAdd={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(() => {
            this.setState({
              openedSparePartVendorModalFromMultipleSparePartVendorsModal: true,
              showSparePartVendorModal: true,
              isAddingAlternativeArticle: true,
              createSparePartVendorForSparePartId: this.state.showMultipleSparePartVendorsModalForSparePartId,
              showSparePartVendorModalForSparePartVendorId: null,
            });
          }, 250);
        }}
        onSelectSparePartVendor={({ id }) => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(
            () =>
              this.setState({
                openedSparePartVendorModalFromMultipleSparePartVendorsModal: true,
                showSparePartVendorModal: true,
                isAddingAlternativeArticle: false,
                showSparePartVendorModalForSparePartVendorId: id,
              }),
            250
          );
        }}
        onClose={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
        }}
      />
    );
  };

  renderMultipleSparePartVendorsDeleteModal = () => {
    return (
      <MultipleSparePartVendorsDeleteModal
        vendorId={this.props.match.params.id}
        sparePartId={this.state.showDeleteMultipleSparePartVendorsModalForSparePartId}
        open={this.state.showDeleteMultipleSparePartVendorsModal}
        title={
          <FormattedMessage id="screens.vendor.spare-parts.vendor-spare-part-modal.confirm-delete.title-multiple" />
        }
        subtitle={
          <FormattedMessage id="screens.vendor.spare-parts.vendor-spare-part-modal.confirm-delete.subtitle" />
        }
        onClose={() => {
          this.setState({ showDeleteMultipleSparePartVendorsModal: false });
        }}
      />
    );
  };

  renderSelectSparePartModal = () => {
    return (
      <SelectSparePartModal
        open={this.state.showSelectSparePartModal}
        onCreateNew={() => {
          this.setState({ showSelectSparePartModal: false });
          setTimeout(() => {
            this.setState({ showCreateNewSparePartModal: true });
          }, 250);
        }}
        listItemRightComponent={sparePart => {
          return this.renderAddSparePartButton(sparePart);
        }}
        additionalApiParams={{
          spare_part_vendors_for_vendor: this.props.match.params.id,
        }}
        onClose={() => this.setState({ showSelectSparePartModal: false })}
        subtitle={
          <FormattedMessage
            id="screens.vendor.spare-parts.spare-part-vendor-modal.vendor-name"
            values={{ vendor: <span className={styles['highlight-text']}>{this.props.vendor.name}</span> }}
          />
        }
      />
    );
  };

  renderAddSparePartButton = sparePart => {
    let sparePartVendors = [];
    if (sparePart.spare_part_vendors_for_vendor) {
      sparePartVendors = sparePart.spare_part_vendors_for_vendor;
    }
    return (
      <>
        {sparePartVendors.length === 0 ? null : (
          <span className={styles['added-amount-of-times-label']}>
            <FormattedPlural
              value={sparePartVendors.length}
              one={
                <FormattedMessage
                  id="general.added-amount-of-times.one"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              two={
                <FormattedMessage
                  id="general.added-amount-of-times.two"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              few={
                <FormattedMessage
                  id="general.added-amount-of-times.few"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              many={
                <FormattedMessage
                  id="general.added-amount-of-times.many"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              other={
                <FormattedMessage
                  id="general.added-amount-of-times.other"
                  values={{ amount: sparePartVendors.length }}
                />
              }
            />
          </span>
        )}
        <Button
          gray
          small
          label="general.add"
          onClick={() => {
            this.setState({ showSelectSparePartModal: false });
            setTimeout(() => {
              this.setState({
                showSparePartVendorModal: true,
                isAddingAlternativeArticle: false,
                createSparePartVendorForSparePartId: sparePart.id,
                showSparePartVendorModalForSparePartVendorId: null,
                openedSparePartVendorModalFromSelectSparePartModal: true,
              });
            }, 250);
          }}
        />
      </>
    );
  };

  renderNewSparePartModal = () => (
    <NewSparePartModal
      open={this.state.showCreateNewSparePartModal}
      createForVendorId={this.props.match.params.id}
      defaultParams={{
        primary_vendor_id: this.props.match.params.id,
      }}
      onClose={() => {
        this.setState({ showCreateNewSparePartModal: false });
        setTimeout(() => {
          this.setState({ showSelectSparePartModal: true });
        }, 250);
      }}
      onCreated={() => {
        this.setState({ showCreateNewSparePartModal: false });
        setTimeout(() => {
          this.setState({ showSelectSparePartModal: true });
        }, 250);
      }}
      onCreatedWithReopen={() => {
        this.setState(
          {
            showCreateNewSparePartModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ showCreateNewSparePartModal: true });
            }, 350);
          }
        );
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.vendor.spare-parts.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.vendor.spare-parts.empty-data-set.subtitle" />}
        image={SparePartSmall}
        button={
          this.props.canEditSpareParts && this.props.canEditVendors ? (
            <Button
              small
              primary
              onClick={() => this.setState({ showSelectSparePartModal: true })}
              label="screens.vendor.spare-parts.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditSpareParts && this.props.canEditVendors) {
      return (
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.vendor.spare-parts.create-button"
              onClick={() =>
                this.setState({
                  openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                  showSelectSparePartModal: true,
                })
              }
            />
          }
        />
      );
    }
    return null;
  };

  renderHeader = () => (
    <List.Header expandable background paddingRight={115}>
      <List.Header.Column flex>
        <FormattedMessage id="resources.spare-part-vendor.spare-part" />
      </List.Header.Column>
      <List.Header.Column width={150} alignRight>
        <FormattedMessage id="resources.spare-part-vendor.price" />
      </List.Header.Column>
    </List.Header>
  );

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.vendor.spare-parts.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderHeader()}
          <List>
            {Array(this.props.sparePartIds.length === 0 ? 1 : this.props.sparePartIds.length)
              .fill()
              .map(() => (
                <SparePartListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.props.sparePartIds.length === 0) return null;

    return (
      <>
        {this.renderHeader()}
        <List>
          {this.props.sparePartIds.map(id => (
            <SparePartListItem
              id={id}
              onDelete={sparePartVendors => this.delete(sparePartVendors)}
              onClick={sparePartVendors => {
                if (sparePartVendors.length === 1) {
                  this.setState({
                    openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                    showSparePartVendorModal: true,
                    isAddingAlternativeArticle: false,
                    showSparePartVendorModalForSparePartVendorId: sparePartVendors[0].id,
                  });
                } else {
                  this.setState({
                    showMultipleSparePartVendorsModal: true,
                    isAddingAlternativeArticle: false,
                    showMultipleSparePartVendorsModalForSparePartId: sparePartVendors[0].spare_part_id,
                  });
                }
              }}
              onAddArticle={() => {
                this.setState({
                  showSparePartVendorModal: true,
                  isAddingAlternativeArticle: true,
                  openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                  createSparePartVendorForSparePartId: id,
                  showSparePartVendorModalForSparePartVendorId: null,
                });
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderSparePartVendors = () => {
    return (
      <>
        {this.renderToolbar()}
        {this.renderList()}
      </>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.sparePartIds.length === 0 && !this.state.isFetching) {
      return this.renderEmptyDataset();
    } else {
      return this.renderSparePartVendors();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectSparePartModal()}
        {this.renderSparePartVendorModal()}
        {this.renderNewSparePartModal()}
        {this.renderMultipleSparePartVendorsModal()}
        {this.renderMultipleSparePartVendorsDeleteModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSpareParts: VendorOperations.fetchSpareParts,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartIds: VendorSelectors.getSparePartIds(state),
    vendor: EntitySelectors.getVendor(state, ownProps.match.params.id),
    pagination: VendorSelectors.getSparePartsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpareParts));
