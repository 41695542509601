import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { normalizeMeter } from 'sdk/Schemas';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Field, Button, Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ChangeFrequencyModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    readingFrequency: this.props.meter.reading_frequency || null,
    showEmptyFrequencyError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
    }
  }
  updateFrequency = () => {
    if (this.state.readingFrequency === null) {
      this.setState({ showEmptyFrequencyError: true });
      return;
    }
    const params = {
      reading_frequency: this.state.readingFrequency,
    };
    this.setState({ isSaving: true });
    API.updateMeter(this.props.id, params)
      .then(({ data: meter }) => {
        const { entities } = normalizeMeter(meter);
        this.props.updateEntities(entities);
        this.setState({ isSaving: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field.Number
              rightLabel={<FormattedMessage id="resources.meter.reading-frequency-unit" />}
              error={this.state.showEmptyFrequencyError}
              autoFocus
              value={this.state.readingFrequency}
              onBlur={readingFrequency => this.setState({ readingFrequency })}
            />
            <p className={styles['field-subtitle']}>
              <FormattedMessage id="resources.meter.reading-frequency-description2" />
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.props.meter) return <Loader />;
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.meter.modals.change-frequency.title" />}
          subtitle={<FormattedMessage id="resources.meter.reading-frequency-description1" />}
          subtitleTopMargin
          subtitleMaxWidth="none"
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.updateFrequency();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    meter: EntitySelectors.getMeter(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeFrequencyModal);
