import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { PathItem, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions } from 'sdk';
import { StocktakingSelectors } from 'state/ducks/stocktaking';
import SparePartLocationItem from './SparePartLocationItem';
import styles from './style.module.scss';

class SparePartLocations extends Component {
  navigateToLocation = id => {
    const queryParams = queryString.parse(this.props.location.search);

    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        parentId: id,
      })}`
    );
  };

  renderPath = () => {
    const parentId = queryString.parse(this.props.location.search).parentId;
    const sparePartLocationPath = this.props.sparePartLocationPath;

    return (
      <div className={styles['path-container']}>
        <PathItem
          active={parentId == null}
          clickable={parentId != null}
          onClick={() => {
            this.navigateToLocation(null);
          }}
        >
          <FormattedMessage id="screens.stocktaking-detail.locations.path-root" />
        </PathItem>
        {parentId == null ? null : (
          <React.Fragment>
            {this.props.sparePartLocationPath.map((sparePartLocation, index) => {
              if (index !== sparePartLocationPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem clickable onClick={() => this.navigateToLocation(sparePartLocation.id)}>
                      {sparePartLocation.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem active>{sparePartLocation.title}</PathItem>
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderLocationChildren = () => {
    if (this.props.sparePartLocations.length === 0) return null;
    return (
      <div className={styles['locations-container']}>
        {this.props.sparePartLocations
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map(sparePartLocation => (
            <SparePartLocationItem
              key={sparePartLocation.id}
              sparePartLocationId={sparePartLocation.id}
              onClick={() => {
                this.navigateToLocation(sparePartLocation.id);
              }}
            />
          ))}
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.renderPath()}
        {this.renderLocationChildren()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const queryParams = queryString.parse(ownProps.location.search);
  const parentId = queryParams.parentId || null;

  return {
    sparePartLocationPath: EntitySelectors.getSparePartLocationPathForSparePartLocationId(state, parentId),
    sparePartLocations: StocktakingSelectors.getSparePartLocationsForParentId(state, parentId),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartLocations));
