import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listOperationalMaintenanceInstances = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenance_instances?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );

export const createOperationalMaintenanceInstance = (systemId, data) =>
  request.post(`systems/${systemId}/operational_maintenance_instances`, data);
export const fetchOperationalMaintenanceInstance = instanceId =>
  request.get(`operational_maintenance_instances/${instanceId}`);
export const updateOperationalMaintenanceInstance = (operationalMaintenanceInstanceId, params, config) =>
  request.patch(`operational_maintenance_instances/${operationalMaintenanceInstanceId}`, params, config);
