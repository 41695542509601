import actions from './actions';

const addSparePart = actions.addSparePart;
const removeSparePart = actions.removeSparePart;
const updateSparePart = actions.updateSparePart;
const addMultipleSpareParts = actions.addMultipleSpareParts;
const removeMultipleSpareParts = actions.removeMultipleSpareParts;
const setVendor = actions.setVendor;
const resetState = actions.resetState;
const cancelPurchaseOrder = actions.cancelPurchaseOrder;

export default {
  removeMultipleSpareParts,
  addMultipleSpareParts,
  addSparePart,
  setVendor,
  removeSparePart,
  updateSparePart,
  resetState,
  cancelPurchaseOrder,
};
