import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import UserImage from 'assets/images/EmptyDataSet/UserTiny.png';
import { Section } from 'views/scenes/Settings/components';
import toast from 'react-hot-toast';
import {
  Button,
  FileUploadWrapper,
  ConfirmDeleteInlineModal,
  Icon,
  Tooltip,
  Loader,
} from 'views/components/Shared/General';
import EditNameModal from './EditNameModal';
import styles from './style.module.scss';
import { ToastMessage } from 'views/components/Shared/Layout';
class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditNameModal: false,
      isDeletingProfilePicture: false,
      isChangingProfilePicture: false,
    };
  }

  changeProfilePicture = ({ image, mime_type }) => {
    this.setState({ isChangingProfilePicture: true });
    this.props
      .updateProfilePicture(this.props.currentUser.id, { image, mime_type })
      .then(() => {
        this.setState({ isChangingProfilePicture: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isChangingProfilePicture: false });
      });
  };

  deleteProfilePicture = () => {
    this.setState({ isDeletingProfilePicture: true });
    this.props
      .deleteProfilePicture(this.props.currentUser.id, this.props.currentUser.profile_picture_image_id)
      .then(() => {
        this.setState({ isDeletingProfilePicture: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isDeletingProfilePicture: false });
      });
  };

  renderImage = () => {
    if (this.props.currentUser.profile_picture_image_id) {
      return (
        <div
          className={styles['image']}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.currentUser.profile_picture_image_id})`,
          }}
        >
          <div className={`${styles['loader']} ${this.state.isChangingProfilePicture ? styles['show'] : ''}`}>
            <Loader tiny />
          </div>
          <div className={styles['actions']}>
            <FileUploadWrapper
              image
              accept="image/png, image/jpg, image/jpeg, image/gif"
              onSelectFile={({ file, mime_type, name }) =>
                this.changeProfilePicture({ image: file, mime_type })
              }
            >
              <Button type="icon" icon={<Icon regular type="pen" />} />
            </FileUploadWrapper>
            <ConfirmDeleteInlineModal
              trigger={<Button type="icon" destructive icon={<Icon regular red type="trash-alt" />} />}
              title={<FormattedMessage id="general.delete-confirm.title" />}
              onDelete={this.deleteProfilePicture}
              loading={this.state.isDeletingProfilePicture}
            />
          </div>
        </div>
      );
    }
    return (
      <Tooltip
        trigger={
          <div>
            <FileUploadWrapper
              image
              accept="image/png, image/jpg, image/jpeg, image/gif"
              onSelectFile={({ file, mime_type, name }) =>
                this.changeProfilePicture({ image: file, mime_type })
              }
            >
              <div
                className={`${styles['image']} ${styles['clickable']}`}
                style={{ backgroundImage: `url(${UserImage})` }}
              />
            </FileUploadWrapper>
          </div>
        }
        label={<FormattedMessage id="screens.settings.user.general.add-image-tooltip" />}
      />
    );
  };

  render() {
    const { scim } = this.props.currentUser;
    return (
      <>
        <Section header title={<FormattedMessage id="screens.settings.user.general.title" />} />
        <div className={styles['contact-information']}>
          {this.renderImage()}
          <div className={styles['info']}>
            <p className={styles['title']}>{this.props.currentUser.name}</p>
            <p className={styles['subtitle']}>{this.props.currentUser.email}</p>
          </div>
          {!scim ? (
            <div className={styles['edit']}>
              <Tooltip
                trigger={
                  <div>
                    <Button
                      type="icon"
                      icon={<Icon regular type="pen" />}
                      onClick={() => this.setState({ showEditNameModal: true })}
                    />
                  </div>
                }
                label={<FormattedMessage id="screens.settings.user.general.edit-name-tooltip" />}
              />
            </div>
          ) : null}
        </div>
        <EditNameModal
          open={this.state.showEditNameModal}
          onClose={() => this.setState({ showEditNameModal: false })}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProfilePicture: SDKReduxOperations.updateProfilePicture,
      deleteProfilePicture: SDKReduxOperations.deleteProfilePicture,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(General));
