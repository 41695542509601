import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/Shared/General';

const UnitField = ({ value, onChange, onClear, sparePartUnits, sparePartUnit }) => {
  return (
    <Field view={false}>
      <Field.Dropdown direction="left" value={sparePartUnit ? sparePartUnit.title : ''} onClear={onClear}>
        {sparePartUnits.map(sparePartUnit => (
          <Field.Dropdown.Item
            key={sparePartUnit.id}
            selected={value === sparePartUnit.id}
            onClick={() => onChange(sparePartUnit.id)}
          >
            {sparePartUnit.title}
          </Field.Dropdown.Item>
        ))}
      </Field.Dropdown>
    </Field>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(UnitField);
