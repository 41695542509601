import React from 'react';
import LogoWithTextSvg from 'assets/images/logo-with-text.svg';
import styles from '../style.module.scss';

export default ({ subtitle, buttons, title }) => {
  const renderSubtitle = () => {
    if (subtitle) {
      return <div className={styles['sign-in-text']}>{subtitle}</div>;
    }
    return null;
  };

  const renderTitle = () => {
    if (title) {
      return <div className={styles['welcome-text']}>{title}</div>;
    }
    return <div className={styles['welcome-text']}>Welcome to Mainter</div>;
  };

  const renderTexts = () => {
    return (
      <div className={styles['text-container']}>
        <div className={styles['logo']}>{<img src={LogoWithTextSvg} alt="logo and name" />}</div>
        {renderTitle()}
        {renderSubtitle()}
      </div>
    );
  };

  const renderButtons = () => {
    if (buttons) {
      return <div className={styles['buttons']}>{buttons}</div>;
    }
    return null;
  };
  return (
    <div className={styles['left-container']}>
      <div className={styles['content']}>
        {renderTexts()}
        {renderButtons()}
      </div>
    </div>
  );
};
