import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, API } from 'sdk';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { normalizeUser } from 'sdk/Schemas';
import { Field, Button } from 'views/components/Shared/General';
import { OrganisationOperations } from 'state/ducks/organisation';
import PermissionsDropdown from './PermissionsDropdown';
import DebitToSystemDropdown from './DebitToSystemDropdown';
import styles from './style.module.scss';

class UserPermissionModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    organisation_admin: false,
    systemAccesses: {}, // { [system_id]: data },
    enterprise_billing_system_id: null,
    showSystemAccessMissingPermissionError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const systemAccesses = this.props.systemAccesses.reduce((acc, systemAccess) => {
        const { system_id, user_type, permission_profile_id } = systemAccess;
        return {
          ...acc,
          [system_id]: {
            user_type,
            permission_profile_id,
          },
        };
      }, {});
      this.setState({
        ...this.getInitialState(),
        systemAccesses,
        organisation_admin: this.props.user.organisation_admin,
        enterprise_billing_system_id: this.props.user.enterprise_billing_system_id,
      });
    }
  }

  fetchAndUpdateUserEntities = () => {
    return API.fetchUser(this.props.user.id, { system_accesses: true }).then(({ data: user }) => {
      const { entities } = normalizeUser(user);
      this.props.updateEntities(entities);
    });
  };

  isSaveable = () => {
    let isSaveable = true;
    Object.keys(this.state.systemAccesses).forEach(systemId => {
      if (Object.keys(this.state.systemAccesses[systemId]).length === 0) {
        this.setState({ showSystemAccessMissingPermissionError: true });
        isSaveable = false;
      }
    });
    return isSaveable;
  };

  getCrudSystemAccessRequests = () => {
    const previousSystemIds = this.props.systemAccesses.map(({ system_id }) => system_id);
    const updateSystemIds = Object.keys(this.state.systemAccesses)
      .filter(id => previousSystemIds.includes(id))
      .filter(id => {
        const { user_type: newUserType, permission_profile_id: newPerId } = this.state.systemAccesses[id];
        const { user_type: oldUserType, permission_profile_id: oldPerId } = this.props.systemAccesses.find(
          ({ system_id }) => system_id === id
        );
        return !isEqual(
          { user_type: newUserType, permission_profile_id: newPerId },
          { user_type: oldUserType, permission_profile_id: oldPerId }
        );
      });
    const deleteRequests = previousSystemIds
      .filter(id => this.state.systemAccesses[id] == null)
      .map(systemId => {
        const { id } = this.props.systemAccesses.find(({ system_id }) => system_id === systemId);
        return this.props.deleteSystemAccess(id);
      });

    const updateRequests = updateSystemIds.map(systemId => {
      const { id } = this.props.systemAccesses.find(({ system_id }) => system_id === systemId);
      const { user_type, permission_profile_id } = this.state.systemAccesses[systemId];
      return this.props.updateSystemAccess(id, {
        user_type,
        permission_profile_id,
      });
    });
    const createRequests = Object.keys(this.state.systemAccesses)
      .filter(id => previousSystemIds.includes(id) === false)
      .map(systemId => {
        const { user_type, permission_profile_id } = this.state.systemAccesses[systemId];
        return this.props.createSystemAccess(this.props.organisation.id, {
          user_type,
          permission_profile_id,
          system_id: systemId,
          user_id: this.props.user.id,
        });
      });
    return [...deleteRequests, ...updateRequests, ...createRequests];
  };

  save = () => {
    if (this.isSaveable() === false) {
      return;
    }
    this.setState({ isSaving: true });
    if (this.state.organisation_admin) {
      this.props
        .updateUser(this.props.user.id, {
          organisation_admin: this.state.organisation_admin,
          enterprise_billing_system_id: this.state.enterprise_billing_system_id,
        })
        .then(() => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          this.props.onClose();
        });
    } else if (this.props.user.organisation_admin !== this.state.organisation_admin) {
      this.props
        .updateUser(this.props.user.id, {
          organisation_admin: this.state.organisation_admin,
        })
        .then(() => {
          let enterprise_billing_system_id = null;
          const hasAccessToSystemIds = Object.keys(this.state.systemAccesses);
          if (hasAccessToSystemIds.length === 1) {
            enterprise_billing_system_id = hasAccessToSystemIds[0];
          } else {
            enterprise_billing_system_id = this.state.enterprise_billing_system_id;
          }
          const updateDebitSystemRequest = this.props.updateUser(this.props.user.id, {
            organisation_admin: this.state.organisation_admin,
            enterprise_billing_system_id,
          });

          Promise.all([updateDebitSystemRequest, ...this.getCrudSystemAccessRequests()])
            .then(() => {
              return this.props.fetchAndSetAmountOfPayingUsers(this.props.organisation.id);
            })
            .then(() => {
              return this.fetchAndUpdateUserEntities();
            })
            .then(() => {
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.props.onClose();
            });
        });
    } else {
      let enterprise_billing_system_id = null;
      const hasAccessToSystemIds = Object.keys(this.state.systemAccesses);
      if (hasAccessToSystemIds.length === 1) {
        enterprise_billing_system_id = hasAccessToSystemIds[0];
      } else {
        enterprise_billing_system_id = this.state.enterprise_billing_system_id;
      }
      const updateDebitSystemRequest = this.props.updateUser(this.props.user.id, {
        organisation_admin: this.state.organisation_admin,
        enterprise_billing_system_id,
      });

      Promise.all([updateDebitSystemRequest, ...this.getCrudSystemAccessRequests()])
        .then(() => {
          return this.props.fetchAndSetAmountOfPayingUsers(this.props.organisation.id);
        })
        .then(() => {
          return this.fetchAndUpdateUserEntities();
        })
        .then(() => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          this.props.onClose();
        });
    }
  };

  selectSystem = system_id => {
    if (this.state.systemAccesses[system_id]) {
      const { [system_id]: _, ...systemAccesses } = this.state.systemAccesses;
      this.setState({
        systemAccesses,
      });
    } else {
      this.setState({
        systemAccesses: {
          ...this.state.systemAccesses,
          [system_id]: {},
        },
      });
    }
  };

  renderDebitToSpecificSystemField = () => {
    if (this.props.organisationSettings.enterprise_split_billing === false) {
      return null;
    }
    const { organisation_admin, systemAccesses } = this.state;
    let systemIds = [];
    if (this.state.organisation_admin) {
      systemIds = this.props.systems.map(({ id }) => id);
    } else {
      systemIds = Object.keys(this.state.systemAccesses);
    }
    return (
      <AnimateHeight
        duration={250}
        height={organisation_admin || Object.keys(systemAccesses).length > 1 ? 'auto' : 0}
      >
        <DebitToSystemDropdown
          systemIds={systemIds}
          selectedSystemId={this.state.enterprise_billing_system_id}
          onChange={enterprise_billing_system_id => {
            this.setState({ enterprise_billing_system_id });
          }}
        />
        <div className={styles['separator']} />
      </AnimateHeight>
    );
  };

  renderContent = () => {
    let classNames = [];
    if (this.state.organisation_admin) {
      classNames = [...classNames, styles['inactive']];
    }
    return (
      <>
        <div className={styles['org-admin']}>
          <Field.Checkbox
            checked={this.state.organisation_admin === true}
            onChange={value =>
              this.setState(prevState => ({
                organisation_admin: !prevState.organisation_admin,
              }))
            }
            label={
              <>
                <div>
                  <FormattedMessage id="screens.organisation.user-permission-modal.org-admin-field" />
                </div>
                <div className={styles['subtitle']}>
                  <FormattedMessage id="screens.organisation.user-permission-modal.org-admin-subtitle" />
                </div>
              </>
            }
          />
          <div className={styles['separator']} />
        </div>
        {this.renderDebitToSpecificSystemField()}
        <div className={classNames.join(' ')}>
          {this.props.systems.map(system => {
            return (
              <div className={styles['permission-container']}>
                <Field.Checkbox
                  checked={this.state.systemAccesses[system.id] != null}
                  onChange={value => this.selectSystem(system.id)}
                  label={system.name}
                />

                <AnimateHeight
                  duration={250}
                  height={this.state.systemAccesses[system.id] != null ? 'auto' : 0}
                >
                  <div className={styles['permission']}>
                    <Field
                      view={false}
                      label={
                        <span>
                          <FormattedMessage id="resources.user.permission" />
                          <span className={styles['subtitle']}> - {system.name}</span>
                        </span>
                      }
                    >
                      <PermissionsDropdown
                        systemId={system.id}
                        permission={this.state.systemAccesses[system.id]}
                        showPermissionIsRequiredError={this.state.showSystemAccessMissingPermissionError}
                        onChange={data => {
                          this.setState({
                            systemAccesses: {
                              ...this.state.systemAccesses,
                              [system.id]: data,
                            },
                          });
                        }}
                      />
                    </Field>
                  </div>
                </AnimateHeight>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.users.action-modal.rights-title" />}
            onClose={() => this.props.onClose()}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button primary loading={this.state.isSaving} label="general.save" onClick={this.save} />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateSystemAccess: SDKReduxOperations.updateSystemAccess,
      createSystemAccess: SDKReduxOperations.createSystemAccess,
      deleteSystemAccess: SDKReduxOperations.deleteSystemAccess,
      updateUser: SDKReduxOperations.updateUser,
      fetchAndSetAmountOfPayingUsers: OrganisationOperations.fetchAndSetAmountOfPayingUsers,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const user = EntitySelectors.getUser(state, ownProps.id);
  return {
    user,
    systemAccesses: EntitySelectors.getSystemAccesses(state, user.system_accesses),
    systems: AuthSelectors.getSystems(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionModal);
