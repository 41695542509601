import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import {
  WhiteCard,
  Button,
  Field,
  ViewTextArea,
  FieldErrorWrapper,
  ConfirmModal,
  DatePicker,
  Tooltip,
  Icon,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { PurchaseOrderStatus, EstimatedDeliveryMode, getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { DeliveryMethodDropdown, PaymentTermDropdown } from 'views/components/PurchaseOrder';
import MultipleEstimatedDeliveryDateModal from './MultipleEstimatedDeliveryDateModal';

class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingPurchaseOrder: {},
      editingPurchaseOrderBeforeEdit: {},
      showOrderedDateIsRequired: false,
      showMultipleEstimatedDeliveryDateModal: false,
      showConfirmDisableMultipleEstimatedDeliveryDateModal: false,
      showConfirmActivateMultipleEstimatedDeliveryDateModal: false,
      isActivatingPartialDelivery: false,
    };
  }

  edit = () => {
    const editingPurchaseOrder = {
      ordered_date: this.props.purchaseOrder.ordered_date,
      payment_terms: this.props.purchaseOrder.payment_terms,
      delivery_method: this.props.purchaseOrder.delivery_method,
      estimated_delivery_date: this.props.purchaseOrder.estimated_delivery_date,
      purchase_order_delivery_method_id: this.props.purchaseOrder.purchase_order_delivery_method_id,
      purchase_order_payment_term_id: this.props.purchaseOrder.purchase_order_payment_term_id,
      internal_comment: this.props.purchaseOrder.internal_comment,
      external_comment: this.props.purchaseOrder.external_comment,
    };

    this.setState({
      editing: true,
      editingPurchaseOrder: editingPurchaseOrder,
      editingPurchaseOrderBeforeEdit: editingPurchaseOrder,
      showOrderedDateIsRequired: false,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });

    const params = this.getModifiedPurchaseOrderData();

    this.props
      .updatePurchaseOrder(this.props.purchaseOrder.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedPurchaseOrderData = () => {
    const vendor = this.state.editingPurchaseOrder;
    const purchaseOrderBeforeEdit = this.state.editingPurchaseOrderBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== purchaseOrderBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  setPurchaseOrderValue = obj => {
    const neweditingPurchaseOrder = {
      ...this.state.editingPurchaseOrder,
      ...obj,
    };

    this.setState({
      editingPurchaseOrder: neweditingPurchaseOrder,
    });
  };

  isLoaded = () => this.props.purchaseOrder != null;

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditPurchaseOrders) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderOrderedDateField = () => {
    let children;
    if (this.state.editing) {
      children = (
        <FieldErrorWrapper
          show={this.state.showOrderedDateIsRequired}
          errorElement={<FormattedMessage id="general.errors.is-required" />}
        >
          <Field.Date
            disabled={this.props.purchaseOrder.status === PurchaseOrderStatus.Draft}
            value={this.state.editingPurchaseOrder.ordered_date}
            error={this.state.showOrderedDateIsRequired}
            onChangeDate={ordered_date => {
              if (!ordered_date) {
                this.setPurchaseOrderValue({ ordered_date });
                this.setState({ showOrderedDateIsRequired: true });
              } else {
                this.setPurchaseOrderValue({ ordered_date });
                this.setState({ showOrderedDateIsRequired: false });
              }
            }}
            footerComponent={
              <DatePicker.Footer
                showClear={this.state.editingPurchaseOrder.ordered_date != null}
                onClear={() => {
                  this.setPurchaseOrderValue({ ordered_date: null });
                  this.setState({ showOrderedDateIsRequired: true });
                }}
              />
            }
          />
        </FieldErrorWrapper>
      );
    } else if (this.props.purchaseOrder.ordered_date == null) {
      children = '-';
    } else {
      children = moment(this.props.purchaseOrder.ordered_date).format('LL');
    }
    return (
      <Field
        disabled={this.props.purchaseOrder.status === PurchaseOrderStatus.Draft}
        view={!this.state.editing}
        label={<FormattedMessage id="resources.purchase-order.ordered-date" />}
        questionTooltipContent={
          this.props.purchaseOrder.status === PurchaseOrderStatus.Draft && this.state.editing ? (
            <FormattedMessage id="screens.purchase-order.ordered-date-question-tooltip-title" />
          ) : null
        }
      >
        {children}
      </Field>
    );
  };

  renderPaymentTermField = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.purchase-order.payment-terms" />}>
          <PaymentTermDropdown
            editing
            value={this.state.editingPurchaseOrder.purchase_order_payment_term_id}
            onChange={purchase_order_payment_term_id => {
              this.setPurchaseOrderValue({ purchase_order_payment_term_id });
            }}
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.purchase-order.payment-terms" />}>
        {this.props.purchaseOrderPaymentTerm?.title || '-'}
      </Field>
    );
  };

  renderDeliveryMethodField = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.purchase-order.delivery-method" />}>
          <DeliveryMethodDropdown
            editing
            value={this.state.editingPurchaseOrder.purchase_order_delivery_method_id}
            onChange={purchase_order_delivery_method_id => {
              this.setPurchaseOrderValue({ purchase_order_delivery_method_id });
            }}
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.purchase-order.delivery-method" />}>
        {this.props.purchaseOrderDeliveryMethod?.title || '-'}
      </Field>
    );
  };

  renderAddPartialDeliveryButton = () => {
    if (this.state.editing) {
      if (this.props.purchaseOrder.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
        return (
          <Button
            type="text"
            primary
            label="screens.purchase-order.add-partial-delivery"
            noUnderline
            onClick={() => this.setState({ showConfirmActivateMultipleEstimatedDeliveryDateModal: true })}
          />
        );
      }
      return (
        <Button
          type="text"
          primary
          label="screens.purchase-order.manage-partial-delivery"
          noUnderline
          onClick={() => this.setState({ showMultipleEstimatedDeliveryDateModal: true })}
        />
      );
    }
    return null;
  };

  renderExpectedDeliveryDateField = () => {
    let children;
    if (this.state.editing) {
      const { estimated_delivery_from_row_date, estimated_delivery_to_row_date, estimated_delivery_mode } =
        this.props.purchaseOrder;
      let value = null;
      if (estimated_delivery_mode === EstimatedDeliveryMode.Multiple) {
        if (estimated_delivery_from_row_date && estimated_delivery_to_row_date) {
          value = getEstimatedDeliveryDateText(this.props.purchaseOrder);
        }
        children = (
          <Field.Resource
            disabled
            onClick={() => this.setState({ showMultipleEstimatedDeliveryDateModal: true })}
            value={value}
            clearable={false}
          />
        );
      } else {
        children = (
          <Field.Date
            value={this.state.editingPurchaseOrder.estimated_delivery_date}
            onChangeDate={estimated_delivery_date => {
              this.setPurchaseOrderValue({ estimated_delivery_date });
            }}
            footerComponent={
              <DatePicker.Footer
                showClear={this.state.editingPurchaseOrder.estimated_delivery_date != null}
                onClear={() => {
                  this.setPurchaseOrderValue({ estimated_delivery_date: null });
                }}
              />
            }
          />
        );
      }
    } else {
      children = getEstimatedDeliveryDateText(this.props.purchaseOrder) || '-';
    }
    return (
      <>
        <Field
          view={!this.state.editing}
          label={<FormattedMessage id="resources.purchase-order.expected-delivery-date" />}
        >
          {children}
        </Field>
        {this.renderAddPartialDeliveryButton()}
      </>
    );
  };

  renderInternalCommentField = () => {
    return (
      <Field
        view={!this.state.editing}
        label={<FormattedMessage id="resources.purchase-order.internal-comment" />}
      >
        {this.state.editing ? (
          <Field.Textarea
            value={this.state.editingPurchaseOrder.internal_comment}
            onChange={internal_comment => this.setPurchaseOrderValue({ internal_comment })}
          />
        ) : (
          <ViewTextArea>{this.props.purchaseOrder.internal_comment || '-'}</ViewTextArea>
        )}
      </Field>
    );
  };

  renderExternalCommentField = () => {
    return (
      <Field
        view={!this.state.editing}
        label={<FormattedMessage id="resources.purchase-order.external-comment" />}
      >
        {this.state.editing ? (
          <Field.Textarea
            value={this.state.editingPurchaseOrder.external_comment}
            onChange={external_comment => this.setPurchaseOrderValue({ external_comment })}
          />
        ) : (
          <ViewTextArea>{this.props.purchaseOrder.external_comment || '-'}</ViewTextArea>
        )}
      </Field>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return null;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column md={4}>{this.renderOrderedDateField()}</Grid.Column>
            <Grid.Column md={4}>{this.renderPaymentTermField()}</Grid.Column>
            <Grid.Column md={4}>{this.renderDeliveryMethodField()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={4}>{this.renderExpectedDeliveryDateField()}</Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column md={6}>{this.renderInternalCommentField()}</Grid.Column>
            <Grid.Column md={6}>{this.renderExternalCommentField()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  };

  renderMultipleEstimatedDeliveryDateModal = () => {
    return (
      <MultipleEstimatedDeliveryDateModal
        purchaseOrder={this.props.purchaseOrder}
        open={this.state.showMultipleEstimatedDeliveryDateModal}
        onDisablePartialDelivery={() => {
          this.setState({ showMultipleEstimatedDeliveryDateModal: false });
          setTimeout(() => {
            this.setState({ showConfirmDisableMultipleEstimatedDeliveryDateModal: true });
          }, 250);
        }}
        onClose={() => this.setState({ showMultipleEstimatedDeliveryDateModal: false })}
      />
    );
  };

  renderDisablePartialDeliveryConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmDisableMultipleEstimatedDeliveryDateModal}
        title={<FormattedMessage id="components.disable-partial-delivery-modal.title" />}
        message={<FormattedMessage id="components.disable-partial-delivery-modal.message" />}
        confirmIsLoading={this.state.isDisablingPartialDelivery}
        confirmButtonText="general.deactivate"
        onConfirm={() => {
          this.setState({ isDisablingPartialDelivery: true });
          this.props
            .updatePurchaseOrder(this.props.purchaseOrder.id, {
              estimated_delivery_mode: EstimatedDeliveryMode.Single,
            })
            .then(() => {
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.setState({
                isDisablingPartialDelivery: false,
                showConfirmDisableMultipleEstimatedDeliveryDateModal: false,
              });
            });
        }}
        onCancel={() => {
          this.setState({ showConfirmDisableMultipleEstimatedDeliveryDateModal: false });
        }}
      />
    );
  };

  renderActivatePartialDeliveryConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmActivateMultipleEstimatedDeliveryDateModal}
        title={<FormattedMessage id="components.activate-partial-delivery-modal.title" />}
        message={<FormattedMessage id="components.activate-partial-delivery-modal.message" />}
        confirmIsLoading={this.state.isActivatingPartialDelivery}
        confirmButtonText="general.activate"
        onConfirm={() => {
          this.setState({ isActivatingPartialDelivery: true });
          this.props
            .updatePurchaseOrder(this.props.purchaseOrder.id, {
              estimated_delivery_mode: EstimatedDeliveryMode.Multiple,
            })
            .then(() => {
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.setState({
                isActivatingPartialDelivery: false,
                showConfirmActivateMultipleEstimatedDeliveryDateModal: false,
                showMultipleEstimatedDeliveryDateModal: true,
              });
            });
        }}
        onCancel={() => {
          this.setState({ showConfirmActivateMultipleEstimatedDeliveryDateModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <WhiteCard
          title={<FormattedMessage id="screens.purchase-order.info.general-information.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
        {this.renderMultipleEstimatedDeliveryDateModal()}
        {this.renderDisablePartialDeliveryConfirmModal()}
        {this.renderActivatePartialDeliveryConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderId);
  const purchaseOrderDeliveryMethod = EntitySelectors.getPurchaseOrderDeliveryMethod(
    state,
    purchaseOrder.purchase_order_delivery_method_id
  );
  const purchaseOrderPaymentTerm = EntitySelectors.getPurchaseOrderPaymentTerm(
    state,
    purchaseOrder.purchase_order_payment_term_id
  );
  return {
    purchaseOrder,
    purchaseOrderDeliveryMethod,
    purchaseOrderPaymentTerm,
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GeneralInformation)));
