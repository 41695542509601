import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { List, MoneyWithCurrency } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';

class SparePartVendorDeleteListItem extends Component {
  renderPrice = () => {
    if (this.props.sparePartVendor.purchase_price) {
      return (
        <List.Item.Column alignRight>
          <MoneyWithCurrency
            value={this.props.sparePartVendor.purchase_price}
            currency={this.props.vendor.purchase_order_currency}
          />
        </List.Item.Column>
      );
    }
    return null;
  };

  renderPackageQuantity = () => {
    if (this.props.sparePartVendor.package_quantity) {
      return (
        <>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.sparePartVendor.package_quantity}
          />
        </>
      );
    }
    return null;
  };

  renderTitle = () => {
    const title = this.props.sparePartVendor.title || this.props.sparePart.title;
    if (this.props.sparePartVendor.number) {
      return (
        <span>
          <span>#{this.props.sparePartVendor.number}</span>
          <span> - </span>
          <span>{title}</span>
        </span>
      );
    }
    return title;
  };

  render() {
    return (
      <List.Item small>
        <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderPackageQuantity()} />
        {this.renderPrice()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartVendor = EntitySelectors.getSparePartVendor(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartVendor.spare_part_id);
  return {
    sparePartVendor,
    sparePart,
    vendor: EntitySelectors.getVendor(state, sparePartVendor.vendor_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(SparePartVendorDeleteListItem);
