import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class OrderPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  orderPurchaseOrder = () => {
    this.setState({ isSaving: true });

    this.props
      .updatePurchaseOrder(this.props.purchaseOrder.id, { status: PurchaseOrderStatus.Ordered })
      .then(() => {
        this.props.onOrdered();
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={430}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.order-purchase-order-modal.title" />}
          subtitle={<FormattedMessage id="components.order-purchase-order-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="components.order-purchase-order-modal.order-button"
              loading={this.state.isSaving}
              onClick={this.orderPurchaseOrder}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(OrderPurchaseOrder);
