import actions from './actions';

const prepareNewRequest = actions.prepareNewRequest;
const cancelNewRequest = actions.cancelNewRequest;
const setEditingRequestValues = actions.setEditingRequestValues;

export default {
  prepareNewRequest,
  cancelNewRequest,
  setEditingRequestValues,
};
