import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, API } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  List,
  ConfirmDeleteInlineModal,
  WhiteCard,
  EmptyDataSet,
  ViewTextArea,
  Field,
  Icon,
} from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { normalizePurchaseOrder, normalizePurchaseOrderDelivery } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import PurchaseOrderDeliveryRow from './PurchaseOrderDeliveryRow';
import VendorField from './VendorField';
import ContactPersonField from './ContactPersonField';
import styles from './style.module.scss';

const ModalTabs = {
  Articles: 'articles',
  PurchaseOrder: 'purchaseOrder',
};

class EditDeliveryModal extends Component {
  getInititalState = () => ({
    isDeleting: false,
    selectedTab: ModalTabs.Articles,
    isFetching: false,
  });
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInititalState(),
        isFetching: true,
      });
      this.fetchPurchaseOrderDelivery().then(() => {
        this.setState({ isFetching: false });
      });
    }
  }

  fetchPurchaseOrderDelivery = () => {
    return API.fetchPurchaseOrderDelivery(this.props.purchaseOrderDeliveryId).then(
      ({ data: purchaseOrderDelivery }) => {
        const { entities } = normalizePurchaseOrderDelivery(purchaseOrderDelivery);
        this.props.updateEntities(entities);
      }
    );
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props.deletePurchaseOrderDelivery(this.props.purchaseOrderDeliveryId).then(() => {
      API.fetchPurchaseOrder(this.props.purchaseOrder.id).then(({ data: purchaseOrder }) => {
        const { entities } = normalizePurchaseOrder(purchaseOrder);
        this.props.updateEntities(entities);

        this.setState({ isDeleting: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.edit-delivery-modal.delete-success" />}
          />
        );
        this.props.onDelete();
      });
    });
  };

  renderNoArticlesEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="components.edit-delivery-modal.empty-data-set.title" />}
        subtitle={<FormattedMessage id="components.edit-delivery-modal.empty-data-set.subtitle" />}
        small
      />
    </WhiteCard>
  );

  renderArticles = () => {
    if (this.props.purchaseOrderDeliveryRows == null) return null;
    if (this.props.purchaseOrderDeliveryRows.length === 0) {
      return this.renderNoArticlesEmptyDataset();
    }
    return (
      <List>
        {this.props.purchaseOrderDeliveryRows.map(purchaseOrderDeliveryRow => (
          <PurchaseOrderDeliveryRow
            key={purchaseOrderDeliveryRow.id}
            purchaseOrderDeliveryRow={purchaseOrderDeliveryRow}
          />
        ))}
      </List>
    );
  };

  renderPurchaseOrderVendorInformation = () => (
    <WhiteCard
      title={<FormattedMessage id="screens.purchase-order.info.vendor.title" />}
      collapsable
      defaultOpen={false}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field view label={<FormattedMessage id="resources.purchase-order.vendor" />}>
              <VendorField value={this.props.purchaseOrder.vendor_id} />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view label={<FormattedMessage id="resources.purchase-order.your-ref" />}>
              <ContactPersonField value={this.props.purchaseOrder.vendor_reference_contact_person_id} />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </WhiteCard>
  );

  renderPurchaseOrderGeneralInformation = () => (
    <WhiteCard
      title={<FormattedMessage id="screens.purchase-order.info.general-information.title" />}
      collapsable
      defaultOpen={false}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.ordered-date" />}>
              {moment(this.props.purchaseOrder.ordered_date).format('LL')}
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.expected-delivery-date" />}>
              {getEstimatedDeliveryDateText(this.props.purchaseOrder)}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.internal-comment" />}>
              {this.props.purchaseOrder.internal_comment ? (
                <ViewTextArea>{this.props.purchaseOrder.internal_comment}</ViewTextArea>
              ) : (
                '-'
              )}
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.external-comment" />}>
              {this.props.purchaseOrder.external_comment ? (
                <ViewTextArea>{this.props.purchaseOrder.external_comment}</ViewTextArea>
              ) : (
                '-'
              )}
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </WhiteCard>
  );

  renderPurchaseOrderInformation = () => (
    <>
      <div className={styles['white-card-container']}>{this.renderPurchaseOrderGeneralInformation()}</div>
      {this.renderPurchaseOrderVendorInformation()}
    </>
  );

  renderContent = () => {
    if (this.props.open) {
      if (this.state.selectedTab === ModalTabs.Articles) {
        return this.renderArticles();
      }
      return this.renderPurchaseOrderInformation();
    }
    return null;
  };

  renderTabs = () => (
    <Modal.Header.TabBar>
      <Modal.Header.TabBarItem
        onClick={() => this.setState({ selectedTab: ModalTabs.Articles })}
        active={this.state.selectedTab === ModalTabs.Articles}
      >
        <FormattedMessage id="components.create-delivery-modal.tabs.articles" />
      </Modal.Header.TabBarItem>

      <Modal.Header.TabBarItem
        onClick={() => this.setState({ selectedTab: ModalTabs.PurchaseOrder })}
        active={this.state.selectedTab === ModalTabs.PurchaseOrder}
      >
        <FormattedMessage id="components.create-delivery-modal.tabs.purchase-order" />
      </Modal.Header.TabBarItem>
    </Modal.Header.TabBar>
  );

  render() {
    if (this.state.isFetching) {
      return (
        <Modal isOpen={this.props.open} width={780}>
          <Modal.Content loading />
        </Modal>
      );
    }
    return (
      <Modal isOpen={this.props.open} width={780}>
        <Modal.Header
          title={
            this.props.purchaseOrder && this.props.vendor ? (
              <FormattedMessage
                id="screens.previous-deliveries.purchase-order-title"
                values={{ number: this.props.purchaseOrder.number, vendor: this.props.vendor.name }}
              />
            ) : null
          }
          tabBarComponent={this.renderTabs()}
          subtitle={
            this.props.purchaseOrderDelivery == null ? null : (
              <FormattedMessage
                id="components.edit-delivery-modal.subtitle"
                values={{
                  date: moment(this.props.purchaseOrderDelivery.created_at).format('LLL'),
                  user: <UserNameWrapper user={this.props.createdByUser} />,
                }}
              />
            )
          }
          iconButtons={
            <ConfirmDeleteInlineModal
              width="300px"
              position="right"
              trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
              title={<FormattedMessage id="components.edit-delivery-modal.delete-confirm.title" />}
              subtitle={<FormattedMessage id="components.edit-delivery-modal.delete-confirm.subtitle" />}
              buttonLabel={'general.delete'}
              onDelete={this.delete}
              loading={this.state.isDeleting}
            />
          }
          onClose={this.props.onClose}
          ignoreLine
        />
        <Modal.Content grayBackground hasTabs>
          {this.renderContent()}
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePurchaseOrderDelivery: SDKReduxOperations.deletePurchaseOrderDelivery,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (!ownProps.open) return {};
  const purchaseOrderDelivery = EntitySelectors.getPurchaseOrderDelivery(
    state,
    ownProps.purchaseOrderDeliveryId
  );
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderDelivery.purchase_order_id);
  return {
    purchaseOrderDelivery,
    purchaseOrder,
    createdByUser: EntitySelectors.getUser(state, purchaseOrderDelivery.created_by_user_id),
    purchaseOrderDeliveryRows: EntitySelectors.getPurchaseOrderDeliveryRows(
      state,
      purchaseOrderDelivery.purchase_order_delivery_rows
    ),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditDeliveryModal));
