import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { MenuUtils, MenuOperations, MenuSelectors } from 'state/ducks/menu';
import ContentLoader from 'react-content-loader';
import { EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import HeaderLogo from 'assets/images/HeaderLogo.png';
import { AuthSelectors, AuthOperations } from 'state/ducks/auth';
import { NotificationsSelectors } from 'state/ducks/notifications';
import { Icon, NewInlineModal } from 'views/components/Shared/General';
import { FeedbackModal } from 'views/components/General';
import NewsLetterModal from '../../../../../routes/PrivateApp/NewsLetterModal';
import MainterLogoMini from 'assets/images/logo-mini.png';
import MenuButton from './MenuButton';
import NotificationMenu from './NotificationMenu';
import ChangeUserMenu from './ChangeUserMenu';
import OperatorMenu from './OperatorMenu';
import OperatorAssetButton from './OperatorMenu/OperatorAssetButton';
import styles from './style.module.scss';

class Menu extends Component {
  state = {
    showNotificationList: false,
    showFeedbackModal: false,
    showChangeUserMenu: false,
    showMaintenanceDropdown: false,
    showAssetDropdown: false,
    showSparePartsDropdown: false,
    showMoreOptionsDropdown: false,
    showUserDropdown: false,
    showSystemDropdown: false,
    showNewsLetterModal: false,
  };

  componentDidUpdate(prevProps) {
    const previousUrl = `${prevProps.location.pathname}${prevProps.location.search}`;
    const currentUrl = `${this.props.location.pathname}${this.props.location.search}`;
    if (this.state.showNotificationList && previousUrl !== currentUrl) {
      this.setState({ showNotificationList: false });
    }
  }

  dropdownIsOpen = () => {
    const {
      showMaintenanceDropdown,
      showAssetDropdown,
      showSparePartsDropdown,
      showMoreOptionsDropdown,
      showUserDropdown,
      showSystemDropdown,
    } = this.state;
    return (
      showMaintenanceDropdown ||
      showAssetDropdown ||
      showSparePartsDropdown ||
      showMoreOptionsDropdown ||
      showUserDropdown ||
      showSystemDropdown
    );
  };

  openDropdown = dropdown => {
    const newState = {
      showMaintenanceDropdown: false,
      showAssetDropdown: false,
      showSparePartsDropdown: false,
      showMoreOptionsDropdown: false,
      showUserDropdown: false,
      showSystemDropdown: false,
      [dropdown]: true,
    };
    this.setState(newState);
  };

  showFeedbackAlert = () => {
    if (process.env.REACT_APP_IS_GATHERING_FEEDBACK !== 'true') {
      return false;
    }
    if (this.props.isAdmin === false) {
      return false;
    }
    return true;
  };

  toggleNotificationList = () => {
    if (this.state.showChangeUserMenu) {
      this.setState({ showChangeUserMenu: false });
      setTimeout(() => {
        this.setState({ showNotificationList: true });
        this.props.resetNotificationBadge(this.props.system.id);
      }, 200);
    } else {
      this.setState({ showNotificationList: !this.state.showNotificationList });
      this.props.resetNotificationBadge(this.props.system.id);
    }
  };

  renderNotificationButton = () => {
    let classNames = [styles['icon-button'], styles['notification']];
    return (
      <div onClick={() => this.toggleNotificationList()} className={classNames.join(' ')}>
        <Icon type="bell" size={14} color={this.state.showNotificationList ? '#72BBE6' : '#fff'} />
        {this.props.notificationBadgeCount > 0 ? (
          <div className={styles['notification-badge']}>{this.props.notificationBadgeCount}</div>
        ) : null}
      </div>
    );
  };

  renderSettingsButton = () => {
    let classNames = [styles['icon-button']];
    const selected = this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Settings;
    if (selected) {
      classNames = [...classNames, styles['selected']];
    }
    return (
      <Link to="/settings/user" className={classNames.join(' ')}>
        <div>
          <Icon type="cog" size={14} color={selected ? '#72bbe6' : '#FFF'} />
        </div>
      </Link>
    );
  };

  renderIconButtons = () => {
    return (
      <div className={styles['icon-buttons']}>
        {this.renderSettingsButton()}
        {this.renderNotificationButton()}
      </div>
    );
  };

  renderChangeUserMenu = () => (
    <ChangeUserMenu
      open={this.state.showChangeUserMenu}
      userName={this.props.currentUser.name}
      onClose={() => this.setState({ showChangeUserMenu: false })}
    />
  );
  renderNotificationMenu = () => (
    <NotificationMenu
      open={this.state.showNotificationList}
      onClose={() => this.setState({ showNotificationList: false })}
    />
  );

  renderLoader = () => {
    return (
      <ContentLoader
        primaryColor="#ffffff25"
        secondaryColor="#ffffff17"
        height={2}
        width={15}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="0.5" rx="0.5" ry="0.5" width="15" height="1.5" />
      </ContentLoader>
    );
  };

  renderMaintenanceDropdown = () => {
    const { canViewOperationalMaintenances } = this.props;
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    let mainButtons = [
      {
        link: {
          pathname: `/work-orders`,
          search: 'list=upcoming',
        },
        title: <FormattedMessage id="menu.main.maintenance.work-orders.title" />,
        subtitle: <FormattedMessage id="menu.main.maintenance.work-orders.subtitle" />,
        icon: 'wrench',
        onClick: () => {
          if (this.props.location.pathname !== '/work-orders') {
            this.props.resetListState();
          }
        },
      },
    ];
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    if (operationalMaintenanceIsActivated && canViewOperationalMaintenances) {
      mainButtons = [
        ...mainButtons,
        {
          link: {
            pathname: `/operational-maintenances`,
            search: `list=${localStorage.getItem('operationalMaintenancesView') || 'calendar'}`,
          },
          title: <FormattedMessage id="menu.main.maintenance.operational-maintenances.title" />,
          subtitle: <FormattedMessage id="menu.main.maintenance.operational-maintenances.subtitle" />,
          icon: 'clipboard-list',
          onClick: () => {
            if (this.props.location.pathname !== '/operational-maintenances') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    mainButtons = [
      ...mainButtons,
      {
        link:
          this.props.canAdministrateRequests || this.props.isViewOnly
            ? {
                pathname: `/requests`,
                search: 'list=to_plan',
              }
            : {
                pathname: `/requests`,
                search: 'list=my_created',
              },
        title: <FormattedMessage id="menu.main.maintenance.requests.title" />,
        subtitle: <FormattedMessage id="menu.main.maintenance.requests.subtitle" />,
        icon: 'exclamation-circle',
        onClick: () => {
          if (this.props.location.pathname !== '/requests') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (this.props.canAdministrateWorkOrders || this.props.isViewOnly) {
      mainButtons = [
        ...mainButtons,
        {
          link: '/checklists',
          title: <FormattedMessage id="menu.main.maintenance.checklists.title" />,
          subtitle: <FormattedMessage id="menu.main.maintenance.checklists.subtitle" />,
          icon: 'list',
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/checklists') {
              this.props.resetListState();
            }
          },
        },
      ];
    }

    let subButtons = [];
    if (this.props.canAdministrateWorkOrders) {
      subButtons = [
        ...subButtons,
        {
          link: '/calendar',
          title: <FormattedMessage id="menu.main.maintenance.calendar.title" />,
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/calendar') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    if (this.props.canViewFullCosts && this.props.hasProTier) {
      subButtons = [
        ...subButtons,
        {
          link: '/costs',
          title: <FormattedMessage id="menu.main.maintenance.costs.title" />,
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/costs') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    if (this.props.canViewFullCosts && this.props.hasProTier) {
      subButtons = [
        ...subButtons,
        {
          link: '/statistics',
          title: <FormattedMessage id="menu.main.maintenance.statistics.title" />,
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/statistics') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    if (this.props.settings.spare_parts_activated === false) {
      subButtons = [
        ...subButtons,
        {
          link: '/vendors',
          title: <FormattedMessage id="menu.main.spare-parts.vendors.title" />,
          onClick: () => {
            if (this.props.location.pathname !== '/vendors') {
              this.props.resetListState();
            }
          },
        },
      ];
    }

    if (this.state.showMaintenanceDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={mainButtons}
          subButtons={subButtons}
          onClose={() => this.setState({ showMaintenanceDropdown: false })}
        />
      );
    }
    return null;
  };

  renderMaintenanceDropdownForProductionSupervisor = () => {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;

    let mainButtons = [
      {
        link: {
          pathname: `/work-orders`,
          search: 'list=upcoming',
        },
        title: <FormattedMessage id="menu.main.maintenance.work-orders.title" />,
        subtitle: <FormattedMessage id="menu.main.maintenance.work-orders.subtitle" />,
        icon: 'wrench',
        onClick: () => {
          if (this.props.location.pathname !== '/work-orders') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (operationalMaintenanceIsActivated) {
      mainButtons = [
        ...mainButtons,
        {
          link: {
            pathname: `/operational-maintenances`,
            search: `list=${localStorage.getItem('operationalMaintenancesView') || 'calendar'}`,
          },
          title: <FormattedMessage id="menu.main.maintenance.operational-maintenances.title" />,
          subtitle: <FormattedMessage id="menu.main.maintenance.operational-maintenances.subtitle" />,
          icon: 'clipboard-list',
          onClick: () => {
            if (this.props.location.pathname !== '/operational-maintenances') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    mainButtons = [
      ...mainButtons,
      {
        link: {
          pathname: `/requests`,
          search: 'list=open',
        },
        title: <FormattedMessage id="menu.main.maintenance.requests.title" />,
        subtitle: <FormattedMessage id="menu.main.maintenance.requests.subtitle" />,
        icon: 'exclamation-circle',
        onClick: () => {
          if (this.props.location.pathname !== '/requests') {
            this.props.resetListState();
          }
        },
      },
    ];
    mainButtons = [
      ...mainButtons,
      {
        link: '/checklists',
        title: <FormattedMessage id="menu.main.maintenance.checklists.title" />,
        subtitle: <FormattedMessage id="menu.main.maintenance.checklists.subtitle" />,
        icon: 'list',
        proFeature: true,
        onClick: () => {
          if (this.props.location.pathname !== '/checklists') {
            this.props.resetListState();
          }
        },
      },
    ];

    if (this.state.showMaintenanceDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={mainButtons}
          onClose={() => this.setState({ showMaintenanceDropdown: false })}
        />
      );
    }
    return null;
  };

  renderAssetDropdown = () => {
    let mainButtons = [
      {
        link: '/assets?list=tree',
        title: <FormattedMessage id="menu.main.assets.assets.title" />,
        subtitle: <FormattedMessage id="menu.main.assets.assets.subtitle" />,
        icon: 'box-alt',
        onClick: () => {
          if (this.props.location.pathname !== '/assets') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (this.props.canViewMeters && this.props.settings.meters_activated) {
      mainButtons = [
        ...mainButtons,
        {
          link: '/meters',
          title: <FormattedMessage id="menu.main.assets.meters.title" />,
          subtitle: <FormattedMessage id="menu.main.assets.meters.subtitle" />,
          icon: 'tachometer-alt-slow',
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/meters') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    let subButtons = [];
    if (this.props.settings.downtime_activated && this.props.canViewDowntimes) {
      subButtons = [
        ...subButtons,
        {
          link: '/downtimes',
          title: <FormattedMessage id="menu.main.assets.downtimes.title" />,
          onClick: () => {
            if (this.props.location.pathname !== '/downtimes') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    if (this.state.showAssetDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={mainButtons}
          subButtons={subButtons}
          onClose={() => this.setState({ showAssetDropdown: false })}
        />
      );
    }
    return null;
  };

  renderSparePartsDropdown = () => {
    let mainButtons = [
      {
        link: '/spare-parts',
        title: <FormattedMessage id="menu.main.spare-parts.spare-parts.title" />,
        subtitle: <FormattedMessage id="menu.main.spare-parts.spare-parts.subtitle" />,
        icon: 'cogs',
        onClick: () => {
          if (this.props.location.pathname !== '/spare-parts') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (this.props.canViewPurchaseOrders) {
      mainButtons = [
        ...mainButtons,
        {
          link: '/purchase-orders?list=draft',
          title: <FormattedMessage id="menu.main.spare-parts.purchase-orders.title" />,
          subtitle: <FormattedMessage id="menu.main.spare-parts.purchase-orders.subtitle" />,
          icon: 'file-invoice-dollar',
          onClick: () => {
            if (this.props.location.pathname !== '/purchase-orders') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    let subButtons = [
      {
        link: '/withdrawals',
        title: <FormattedMessage id="menu.main.spare-parts.withdrawals.title" />,
        onClick: () => {
          if (this.props.location.pathname !== '/withdrawals') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (this.props.canViewVendors) {
      subButtons = [
        ...subButtons,
        {
          link: '/vendors',
          title: <FormattedMessage id="menu.main.spare-parts.vendors.title" />,
          onClick: () => {
            if (this.props.location.pathname !== '/vendors') {
              this.props.resetListState();
            }
          },
        },
      ];
    }
    if (this.props.hasProTier && this.props.canViewSpareParts) {
      subButtons = [
        ...subButtons,
        {
          link: '/stocktaking',
          title: <FormattedMessage id="menu.main.spare-parts.stocktaking.title" />,
          proFeature: true,
          onClick: () => {
            if (this.props.location.pathname !== '/stocktaking') {
              this.props.resetListState();
            }
          },
        },
      ];
    }

    if (this.state.showSparePartsDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={mainButtons}
          subButtons={subButtons}
          onClose={() => this.setState({ showSparePartsDropdown: false })}
        />
      );
    }
    return null;
  };

  renderSparePartsDropdownForProductionSupervisor = () => {
    let mainButtons = [
      {
        link: '/spare-parts',
        title: <FormattedMessage id="menu.main.spare-parts.spare-parts.title" />,
        subtitle: <FormattedMessage id="menu.main.spare-parts.spare-parts.subtitle" />,
        icon: 'cogs',
        onClick: () => {
          if (this.props.location.pathname !== '/spare-parts') {
            this.props.resetListState();
          }
        },
      },
    ];
    mainButtons = [
      ...mainButtons,
      {
        link: '/withdrawals',
        icon: 'cogs',
        title: <FormattedMessage id="menu.main.spare-parts.withdrawals.title" />,
        subtitle: <FormattedMessage id="menu.main.spare-parts.withdrawals.subtitle" />,
        onClick: () => {
          if (this.props.location.pathname !== '/withdrawals') {
            this.props.resetListState();
          }
        },
      },
    ];
    if (this.state.showSparePartsDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={mainButtons}
          onClose={() => this.setState({ showSparePartsDropdown: false })}
        />
      );
    }
    return null;
  };

  renderMoreOptionsDropdown = () => {
    if (this.state.showMoreOptionsDropdown) {
      return (
        <MenuButton.Dropdown
          mainButtons={[
            {
              link: '/files',
              title: <FormattedMessage id="menu.main.more-options.files.title" />,
              subtitle: <FormattedMessage id="menu.main.more-options.files.subtitle" />,
              icon: 'file-alt',
              onClick: () => {
                if (this.props.location.pathname !== '/files') {
                  this.props.resetListState();
                }
              },
            },
          ]}
          onClose={() => this.setState({ showMoreOptionsDropdown: false })}
        />
      );
    }
    return null;
  };

  isMaintenanceDropdownSelected = () => {
    const options = [
      MenuUtils.MENU_ITEM_TYPE.WorkOrders,
      MenuUtils.MENU_ITEM_TYPE.OperationalMaintenances,
      MenuUtils.MENU_ITEM_TYPE.Requests,
      MenuUtils.MENU_ITEM_TYPE.Calendar,
      MenuUtils.MENU_ITEM_TYPE.Checklists,
      MenuUtils.MENU_ITEM_TYPE.Statistics,
      MenuUtils.MENU_ITEM_TYPE.Costs,
    ];
    return options.includes(this.props.selectedItem);
  };

  renderMaintenanceMenu = () => {
    return (
      <MenuButton
        selected={this.isMaintenanceDropdownSelected()}
        dropdown
        title={<FormattedMessage id="menu.main.maintenance.title" />}
        onClick={() =>
          this.setState(prevState => ({
            showMaintenanceDropdown: !prevState.showMaintenanceDropdown,
          }))
        }
        onCloseDropdown={() => this.setState({ showMaintenanceDropdown: false })}
        onMouseEnter={() => {
          if (this.dropdownIsOpen()) {
            this.openDropdown('showMaintenanceDropdown');
          }
        }}
      >
        {this.renderMaintenanceDropdown()}
      </MenuButton>
    );
  };

  renderMaintenanceMenuForProductionSupervisor = () => {
    return (
      <MenuButton
        selected={this.isMaintenanceDropdownSelected()}
        dropdown
        title={<FormattedMessage id="menu.main.maintenance.title" />}
        onClick={() =>
          this.setState(prevState => ({
            showMaintenanceDropdown: !prevState.showMaintenanceDropdown,
          }))
        }
        onCloseDropdown={() => this.setState({ showMaintenanceDropdown: false })}
        onMouseEnter={() => {
          if (this.dropdownIsOpen()) {
            this.openDropdown('showMaintenanceDropdown');
          }
        }}
      >
        {this.renderMaintenanceDropdownForProductionSupervisor()}
      </MenuButton>
    );
  };

  isAssetsDropdownSelected = () => {
    const options = [MenuUtils.MENU_ITEM_TYPE.Asset, MenuUtils.MENU_ITEM_TYPE.Meters];
    return options.includes(this.props.selectedItem);
  };

  renderAssetsMenu = () => {
    return (
      <MenuButton
        dropdown
        selected={this.isAssetsDropdownSelected()}
        title={<FormattedMessage id="menu.main.assets.title" />}
        onClick={() =>
          this.setState(prevState => ({
            showAssetDropdown: !prevState.showAssetDropdown,
          }))
        }
        onCloseDropdown={() => this.setState({ showAssetDropdown: false })}
        onMouseEnter={() => {
          if (this.dropdownIsOpen()) {
            this.openDropdown('showAssetDropdown');
          }
        }}
      >
        {this.renderAssetDropdown()}
      </MenuButton>
    );
  };

  isSparePartsDropdownSelected = () => {
    const options = [
      MenuUtils.MENU_ITEM_TYPE.SpareParts,
      MenuUtils.MENU_ITEM_TYPE.SparePartWithdrawals,
      MenuUtils.MENU_ITEM_TYPE.Stocktaking,
      MenuUtils.MENU_ITEM_TYPE.Vendor,
      MenuUtils.MENU_ITEM_TYPE.PurchaseOrders,
    ];
    return options.includes(this.props.selectedItem);
  };

  renderSparePartsMenu = () => {
    if (this.props.settings.spare_parts_activated === false) {
      return null;
    }
    return (
      <MenuButton
        dropdown
        selected={this.isSparePartsDropdownSelected()}
        title={<FormattedMessage id="menu.main.spare-parts.title" />}
        onClick={() =>
          this.setState(prevState => ({
            showSparePartsDropdown: !prevState.showSparePartsDropdown,
          }))
        }
        onCloseDropdown={() => this.setState({ showSparePartsDropdown: false })}
        onMouseEnter={() => {
          if (this.dropdownIsOpen()) {
            this.openDropdown('showSparePartsDropdown');
          }
        }}
      >
        {this.renderSparePartsDropdown()}
      </MenuButton>
    );
  };

  renderSparePartsMenuForProductionSupervisor = () => {
    if (this.props.settings.spare_parts_activated === false) {
      return null;
    }
    return (
      <MenuButton
        dropdown
        selected={this.isSparePartsDropdownSelected()}
        title={<FormattedMessage id="menu.main.spare-parts.title" />}
        onClick={() =>
          this.setState(prevState => ({
            showSparePartsDropdown: !prevState.showSparePartsDropdown,
          }))
        }
        onCloseDropdown={() => this.setState({ showSparePartsDropdown: false })}
        onMouseEnter={() => {
          if (this.dropdownIsOpen()) {
            this.openDropdown('showSparePartsDropdown');
          }
        }}
      >
        {this.renderSparePartsDropdownForProductionSupervisor()}
      </MenuButton>
    );
  };

  renderAssetMenuForProductionSupervisor = () => {
    if (this.props.settings.downtime_activated) {
      return this.renderAssetsMenu();
    }
    return (
      <MenuButton
        selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Asset}
        title={<FormattedMessage id="menu.main.assets.assets.title" />}
        linkTo={{
          pathname: `/assets`,
          search: 'list=tree',
        }}
        onClick={() => {
          if (this.props.location.pathname !== '/assets') {
            this.props.resetListState();
          }
        }}
      />
    );
  };

  isMoreOptionsDropdownSelected = () => {
    const options = [MenuUtils.MENU_ITEM_TYPE.Files];
    return options.includes(this.props.selectedItem);
  };

  renderMoreOptionsMenu = () => {
    const { isViewOnly, canEditAssets, canEditVendors, canAdministrateWorkOrders } = this.props;
    if (isViewOnly || canEditAssets || canEditVendors || canAdministrateWorkOrders) {
      return (
        <MenuButton
          dropdown
          selected={this.isMoreOptionsDropdownSelected()}
          title={<FormattedMessage id="menu.main.more-options.title" />}
          onClick={() =>
            this.setState(prevState => ({
              showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
            }))
          }
          onCloseDropdown={() => this.setState({ showMoreOptionsDropdown: false })}
          onMouseEnter={() => {
            if (this.dropdownIsOpen()) {
              this.openDropdown('showMoreOptionsDropdown');
            }
          }}
        >
          {this.renderMoreOptionsDropdown()}
        </MenuButton>
      );
    }
    return null;
  };

  renderLeftMenu = () => {
    let menuComponent = null;
    if (this.props.isProductionSupervisor) {
      menuComponent = this.renderProductionSupervisorMenu();
    } else if (this.props.isWorkRequester) {
      menuComponent = (
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Requests}
          title={<FormattedMessage id="menu.main.maintenance.requests.title" />}
          linkTo={{
            pathname: '/requests',
            search: 'list=my_created',
          }}
        />
      );
    } else if (this.props.isOperator) {
      menuComponent = <OperatorMenu />;
    } else {
      menuComponent = this.renderMemberMenu();
    }
    return (
      <div className={styles['left-menu']}>
        <div className={styles['separator']} />
        {menuComponent}
      </div>
    );
  };

  renderSystemsMenuButtonTitle = () => {
    if (
      matchPath(this.props.location.pathname, {
        path: '/organisation',
      }) != null
    ) {
      return <FormattedMessage id="menu.manage-organisation" />;
    }
    return <span>{this.props.system.name}</span>;
  };

  renderSystems = () => {
    if (this.props.systems.length > 1) {
      return (
        <>
          <div
            ref={ref => (this.systemDropdownRef = ref)}
            className={styles['menu-button-inline-modal-container']}
          >
            <MenuButton
              title={this.renderSystemsMenuButtonTitle()}
              onClick={() =>
                this.setState(prevState => ({
                  showSystemDropdown: !prevState.showSystemDropdown,
                }))
              }
              onCloseDropdown={() => this.setState({ showSystemDropdown: false })}
              onMouseEnter={() => {
                if (this.dropdownIsOpen()) {
                  this.openDropdown('showSystemDropdown');
                }
              }}
            />
          </div>
          <NewInlineModal
            positionToRef={this.systemDropdownRef}
            open={this.state.showSystemDropdown}
            minWidth={260}
            position="right"
            onClose={() => {
              this.setState({ showSystemDropdown: false });
            }}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                {this.props.systems.map(system => (
                  <a href={`/${system.id}`}>
                    <NewInlineModal.Dropdown.Item
                      rightComponent={
                        this.props.system.id === system.id ? (
                          <Icon green type="check" light size={14} />
                        ) : null
                      }
                    >
                      {system.name}
                    </NewInlineModal.Dropdown.Item>
                  </a>
                ))}
                {this.props.currentUser.organisation_admin &&
                this.props.organisation.multiple_systems === true ? (
                  <>
                    <NewInlineModal.Dropdown.Separator />
                    <Link to="/organisation">
                      <NewInlineModal.Dropdown.Item
                        rightComponent={<Icon type="cog" regular size={14} />}
                        onClick={() => this.setState({ showSystemDropdown: false })}
                      >
                        <FormattedMessage id="menu.manage-organisation" />
                      </NewInlineModal.Dropdown.Item>
                    </Link>
                  </>
                ) : null}
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderUser = () => {
    if (this.props.isGroupAuthenticated) {
      return (
        <>
          <div
            ref={ref => (this.userDropdownRef = ref)}
            className={styles['menu-button-inline-modal-container']}
          >
            <MenuButton
              title={this.props.currentUser.name}
              onClick={() =>
                this.setState(prevState => ({
                  showUserDropdown: !prevState.showUserDropdown,
                }))
              }
              onCloseDropdown={() => this.setState({ showUserDropdown: false })}
              onMouseEnter={() => {
                if (this.dropdownIsOpen()) {
                  this.openDropdown('showUserDropdown');
                }
              }}
            />
          </div>
          <NewInlineModal
            positionToRef={this.userDropdownRef}
            open={this.state.showUserDropdown}
            minWidth={260}
            position="right"
            onClose={() => {
              this.setState({ showUserDropdown: false });
            }}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={e => this.setState({ showUserDropdown: false, showChangeUserMenu: true })}
                >
                  <FormattedMessage id="menu.main.user-dropdown.change-user" />
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Separator />
                <Link to="/settings/user">
                  <NewInlineModal.Dropdown.Item onClick={e => this.setState({ showUserDropdown: false })}>
                    <FormattedMessage id="menu.main.user-dropdown.my-profile" />
                  </NewInlineModal.Dropdown.Item>
                </Link>
                <a href="mailto:support@mainter.com">
                  <NewInlineModal.Dropdown.Item onClick={e => this.setState({ showUserDropdown: false })}>
                    <FormattedMessage id="menu.main.user-dropdown.support" />
                  </NewInlineModal.Dropdown.Item>
                </a>
                <NewInlineModal.Dropdown.Item onClick={this.props.unauthenticateUser}>
                  <FormattedMessage id="menu.main.user-dropdown.log-out" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
              <div className={styles['version-number']}>
                <FormattedMessage id="general.version" />
                <span>: </span>
                {process.env.REACT_APP_VERSION}
              </div>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return (
      <>
        <div
          ref={ref => (this.userDropdownRef = ref)}
          className={styles['menu-button-inline-modal-container']}
        >
          <MenuButton
            title={this.props.currentUser.name}
            onClick={() =>
              this.setState(prevState => ({
                showUserDropdown: !prevState.showUserDropdown,
              }))
            }
            onCloseDropdown={() => this.setState({ showUserDropdown: false })}
            onMouseEnter={() => {
              if (this.dropdownIsOpen()) {
                this.openDropdown('showUserDropdown');
              }
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.userDropdownRef}
          open={this.state.showUserDropdown}
          minWidth={260}
          position="right"
          onClose={() => {
            this.setState({ showUserDropdown: false });
          }}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <Link to="/settings/user">
                <NewInlineModal.Dropdown.Item onClick={e => this.setState({ showUserDropdown: false })}>
                  <FormattedMessage id="menu.main.user-dropdown.my-profile" />
                </NewInlineModal.Dropdown.Item>
              </Link>
              <a href="mailto:support@mainter.com">
                <NewInlineModal.Dropdown.Item onClick={e => this.setState({ showUserDropdown: false })}>
                  <FormattedMessage id="menu.main.user-dropdown.support" />
                </NewInlineModal.Dropdown.Item>
              </a>

              <NewInlineModal.Dropdown.Item onClick={this.props.unauthenticateUser}>
                <FormattedMessage id="menu.main.user-dropdown.log-out" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
            <div className={styles['version-number']}>
              <FormattedMessage id="general.version" />
              <span>: </span>
              {process.env.REACT_APP_VERSION}
            </div>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderDemoRemainingDays = () => {
    if (!this.props.organisation.demo) return null;
    if (!this.props.organisation.demo_expiration_date) return null;
    if (!this.props.isAdmin) return null;

    const daysUntilExpirationDate =
      moment(this.props.organisation.demo_expiration_date).diff(moment(), 'days') + 1;

    return (
      <>
        <div className={styles['demo-expired']}>
          <FormattedPlural
            value={daysUntilExpirationDate}
            one={
              <FormattedMessage id="menu.demo-expires-text.one" values={{ days: daysUntilExpirationDate }} />
            }
            two={
              <FormattedMessage id="menu.demo-expires-text.two" values={{ days: daysUntilExpirationDate }} />
            }
            few={
              <FormattedMessage id="menu.demo-expires-text.few" values={{ days: daysUntilExpirationDate }} />
            }
            many={
              <FormattedMessage id="menu.demo-expires-text.many" values={{ days: daysUntilExpirationDate }} />
            }
            other={
              <FormattedMessage
                id="menu.demo-expires-text.other"
                values={{ days: daysUntilExpirationDate }}
              />
            }
          />
        </div>
        <div className={styles['separator']} />
      </>
    );
  };

  renderProductNameChange = () => {
    if (this.props.isOperator) return null;

    return (
      <div
        className={styles['product-name-change']}
        onClick={() => this.setState({ showNewsLetterModal: true })}
      >
        <div className={styles['image-container']}>
          <img src={MainterLogoMini} style={{ width: '100%' }} />
        </div>
        <div>
          <FormattedMessage id="menu.product-name-change" />
        </div>
        <div className={styles['icon-container']}>
          <Icon type="comment-alt-exclamation" size={18} color="#FFF" />
        </div>
      </div>
    );
  };

  renderOperatorAsset = () => {
    if (!this.props.isOperator) return null;
    return <OperatorAssetButton />;
  };

  renderRightMenu = () => {
    return (
      <>
        <div className={styles['right-menu']}>
          {this.renderProductNameChange()}
          {this.renderOperatorAsset()}
          {this.renderSystems()}
          {this.renderDemoRemainingDays()}
          {this.renderUser()}
          {this.renderIconButtons()}
        </div>
      </>
    );
  };

  renderMemberMenu = () => {
    return (
      <>
        {this.renderMaintenanceMenu()}
        {this.renderAssetsMenu()}
        {this.renderSparePartsMenu()}
        {this.renderMoreOptionsMenu()}
      </>
    );
  };

  renderProductionSupervisorMenu = () => {
    return (
      <>
        {this.renderMaintenanceMenuForProductionSupervisor()}
        {this.renderAssetMenuForProductionSupervisor()}
        {this.renderSparePartsMenuForProductionSupervisor()}
      </>
    );
  };

  goToStartPage = () => {
    if (this.props.isOperator) {
      this.props.history.push(`/production-board`);
    } else if (this.props.canViewWorkOrders) {
      this.props.history.push('/work-orders?list=upcoming');
    } else {
      this.props.history.push('/requests?list=my_created');
    }
  };

  render() {
    return (
      <>
        <div className={styles['menu']}>
          <div className={styles['left-container']}>
            <div className={styles['logo-container']} onClick={() => this.goToStartPage()}>
              <div className={styles['logo']}>
                <img src={HeaderLogo} alt="logo" />
              </div>
            </div>
          </div>
          {this.renderLeftMenu()}
          {this.renderRightMenu()}
        </div>
        {this.renderNotificationMenu()}
        {this.renderChangeUserMenu()}
        <FeedbackModal
          open={!!this.state.showFeedbackModal}
          onClose={() => this.setState({ showFeedbackModal: false })}
        />
        <NewsLetterModal
          open={this.state.showNewsLetterModal}
          language={this.props.currentUser.language}
          onClose={() => this.setState({ showNewsLetterModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unauthenticateUser: AuthOperations.unauthenticateUser,
      resetNotificationBadge: SDKReduxOperations.resetNotificationBadge,
      updateEntities: EntityOperations.updateEntities,
      resetListState: MenuOperations.resetListState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    isAdmin: AuthSelectors.isAdmin(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    isOperator: AuthSelectors.isOperator(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
    canViewMeters: AuthSelectors.canViewMeters(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canViewWorkOrders: AuthSelectors.canViewWorkOrders(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    canViewSpareParts: AuthSelectors.canViewSpareParts(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
    canViewFullCosts: AuthSelectors.canViewFullCosts(state),
    canViewOperationalMaintenances: AuthSelectors.canViewOperationalMaintenances(state),
    notificationBadgeCount: NotificationsSelectors.getBadgeCount(state),
    hasProTier: AuthSelectors.hasProTier(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
    system: AuthSelectors.getCurrentSystem(state),
    systems: AuthSelectors.getSystems(state),
    settings: AuthSelectors.getSettings(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    isWorkRequester: AuthSelectors.isWorkRequester(state),
    canViewDowntimes: AuthSelectors.canViewDowntimes(state),
    selectedItem: MenuSelectors.getSelectedItem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
