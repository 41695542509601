import types from './types';

const setAssetId = id => ({
  type: types.SET_ASSET_ID,
  payload: id,
});

const setFolderId = id => ({
  type: types.SET_FOLDER_ID,
  payload: id,
});

const fetchListAttachmentsBegin = payload => ({
  type: types.FETCH_LIST_ATTACHMENTS_BEGIN,
  payload,
});

const fetchListAttachmentsSuccess = ids => ({
  type: types.FETCH_LIST_ATTACHMENTS_SUCCESS,
  payload: ids,
});

const fetchMoreListAttachmentsSuccess = ids => ({
  type: types.FETCH_MORE_LIST_ATTACHMENTS_SUCCESS,
  payload: ids,
});

const fetchSearchAttachmentsSuccess = ids => ({
  type: types.FETCH_SEARCH_ATTACHMENTS_SUCCESS,
  payload: ids,
});

const fetchMoreSearchAttachmentsSuccess = ids => ({
  type: types.FETCH_MORE_SEARCH_ATTACHMENTS_SUCCESS,
  payload: ids,
});

export default {
  fetchMoreListAttachmentsSuccess,
  fetchMoreSearchAttachmentsSuccess,
  setAssetId,
  setFolderId,
  fetchListAttachmentsBegin,
  fetchListAttachmentsSuccess,
  fetchSearchAttachmentsSuccess,
};
