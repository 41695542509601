import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { normalizeSparePart } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { Button, List } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { DeprecatedSelectSparePartModal } from 'views/components/SparePart';
import { AuthSelectors } from 'state/ducks/auth';
import { API, SDKReduxOperations } from 'sdk';
import SparePartListItem from './SparePartListItem';
import styles from './style.module.scss';

class RelatedSparePartsModal extends Component {
  state = {
    isFetching: true,
    sparePartIds: [],
    createdSparePartIds: [],
    showAddMoreSparePartsContent: false,
    isAddingSpareParts: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        isFetching: true,
        sparePartIds: [],
        createdSparePartIds: [],
        showAddMoreSparePartsContent: false,
        isAddingSpareParts: false,
      });

      API.listSpareParts(this.props.currentSystem.id, {
        no_pagination: true,
        attachment_id: this.props.attachment.id,
      }).then(res => {
        const { data: spareParts } = res;
        const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
        this.props.updateEntities(entities);
        this.setState({
          isFetching: false,
          sparePartIds,
        });
      });
    }
  }

  addMultipleSparePartsToAttachment = sparePartIds => {
    this.setState({ isAddingSpareParts: true });
    this.props
      .addMultipleSparePartsToAttachment(this.props.attachment.id, {
        spare_part_ids: sparePartIds.join(),
      })
      .then(({ data: assets }) => {
        const { entities, result } = normalizeSparePart(assets);
        this.props.updateEntities(entities);
        this.setState({
          isAddingSpareParts: false,
          showAddMoreSparePartsContent: false,
          createdSparePartIds: [...result, ...this.state.createdSparePartIds],
        });
        if (result.length === 1) {
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="components.file-related-spare-parts.add-spare-part-success" />}
            />
          );
        } else {
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="components.file-related-spare-parts.add-spare-parts-success" />}
            />
          );
        }
      });
  };

  deleteSparePartFromAttachment = sparePartId => {
    this.setState({ isDeleting: true });
    this.props
      .removeAttachmentFromSparePart(sparePartId, this.props.attachment.id)
      .then(() => {
        this.setState({
          isDeleting: false,
          sparePartIds: this.state.sparePartIds.filter(
            loopedSparePartId => loopedSparePartId !== sparePartId
          ),
          createdSparePartIds: this.state.createdSparePartIds.filter(
            loopedSparePartId => loopedSparePartId !== sparePartId
          ),
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.file-related-spare-parts.delete-success" />}
          />
        );
      })
      .catch(e => {
        this.setState({ isDeleting: false, isOpen: false });
      });
  };

  renderSelectSparePartsModal = () => (
    <DeprecatedSelectSparePartModal
      isOpen={this.state.showAddMoreSparePartsContent}
      inactiveSparePartIds={[...this.state.sparePartIds, ...this.state.createdSparePartIds]}
      onClose={() => this.setState({ showAddMoreSparePartsContent: false })}
      loading={this.state.isAddingSpareParts}
      onSelectSparePartIds={ids => {
        if (ids.length === 0) {
          this.setState({ showAddMoreSparePartsContent: false });
        } else {
          const filteredIds = ids.filter(id => !this.state.sparePartIds.includes(id));
          this.addMultipleSparePartsToAttachment(filteredIds);
        }
      }}
    />
  );

  renderToolbar = () => {
    if (this.props.isViewOnly === false) {
      return (
        <div className={styles['toolbar']}>
          <Button
            small
            primary
            onClick={() => this.setState({ showAddMoreSparePartsContent: true })}
            label="components.file-related-spare-parts.add-spare-parts"
          />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} width={600}>
          <Modal.Header
            title={<FormattedMessage id="components.file-related-spare-parts.title" />}
            subtitle={<FormattedMessage id="components.file-related-spare-parts.subtitle" />}
            onClose={this.props.onClose}
          />
          {this.state.isFetching ? (
            <Modal.Content loading />
          ) : (
            <Modal.Content>
              {this.renderToolbar()}
              <List light>
                {this.state.createdSparePartIds.map(sparePartId => (
                  <SparePartListItem
                    created
                    key={sparePartId}
                    id={sparePartId}
                    isDeleting={this.state.isDeleting}
                    onDelete={() => this.deleteSparePartFromAttachment(sparePartId)}
                  />
                ))}
                {this.state.sparePartIds.map(sparePartId => (
                  <SparePartListItem
                    key={sparePartId}
                    id={sparePartId}
                    isDeleting={this.state.isDeleting}
                    onDelete={() => this.deleteSparePartFromAttachment(sparePartId)}
                  />
                ))}
              </List>
            </Modal.Content>
          )}
        </Modal>
        {this.renderSelectSparePartsModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAttachmentFromSparePart: SDKReduxOperations.removeAttachmentFromSparePart,
      addMultipleSparePartsToAttachment: SDKReduxOperations.addMultipleSparePartsToAttachment,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RelatedSparePartsModal));
