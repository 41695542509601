import React, { Component } from 'react';
import styles from './style.module.scss';
import { Datapill } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';

class SectionChild extends Component {
  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['left-side']}>
          <div className={styles['title']}>{this.props.title}</div>
          <div className={styles['subtitle']}>{this.props.subtitle}</div>
          {this.props.datapills ? (
            <div className={styles['datapill-container']}>
              <div className={styles['datapill-title']}>
                <FormattedMessage id="screens.settings.production-boards.data-pill.operator-auth" />:
              </div>
              &nbsp;
              <Datapill.List>{this.props.datapills}</Datapill.List>
            </div>
          ) : null}
        </div>
        <div className={styles['right-side']}>{this.props.rightComponent}</div>
      </div>
    );
  }
}

export default SectionChild;
