import React from 'react';
import { connect } from 'react-redux';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

const SelectedAssignees = ({ users, vendors, groups }) => {
  const getAssignees = [
    ...vendors.map(assignee => (assignee ? assignee.name : null)),
    ...groups.map(assignee => (assignee ? assignee.title : null)),
    ...users.map(assignee => <UserNameWrapper user={assignee} />),
  ];

  return getAssignees.reduce(
    (accu, assignee) => (accu === null ? [assignee] : [...accu, ', ', assignee]),
    null
  );
};

function mapStateToProps(state, ownProps) {
  return {
    users: EntitySelectors.getUsers(state, ownProps.userIds),
    groups: EntitySelectors.getGroups(state, ownProps.groupIds),
    vendors: EntitySelectors.getVendors(state, ownProps.vendorIds),
  };
}

export default connect(mapStateToProps)(SelectedAssignees);
