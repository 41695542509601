const DOMAIN_PREFIX = 'operationalMaintenanceTemplate/';

const SET_ACTIVE_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION = `${DOMAIN_PREFIX}SET_ACTIVE_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION`;
const SET_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION = `${DOMAIN_PREFIX}SET_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION`;
const FETCH_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_SUCCESS = `${DOMAIN_PREFIX}FETCH_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_SUCCESS`;

export default {
  SET_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
  FETCH_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_SUCCESS,
  SET_ACTIVE_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
};
