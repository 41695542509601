import React from 'react';
import styles from './style.module.scss';
import Editor from './Editor';

export default props => (
  <div
    className={`
    ${styles['field-editor']}
    ${props.isSortingField ? styles['is-sorting-field'] : ''}
  `}
  >
    <Editor
      isCreatingFirstField={props.isCreatingFirstField}
      isSortingField={props.isDragging}
      templateType={props.templateType}
    />
  </div>
);
