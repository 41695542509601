import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { normalizeSparePartAsset } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { Title } from 'views/components/SparePart';
import { Loader, Field, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartAssetModal extends Component {
  state = {
    isSaving: false,
    isDeleting: false,
    showConfirmDeleteModal: false,
    assetParams: {
      quantity: '',
      comment: '',
    },
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.selectedSparePartAssetId !== null) {
        this.setState({
          assetParams: {
            quantity: this.props.sparePartAsset.quantity,
            comment: this.props.sparePartAsset.comment,
          },
        });
      } else {
        this.setState({
          assetParams: {},
        });
      }
    }
  }
  isLoaded = () =>
    !this.props.loading &&
    this.props.sparePart &&
    (this.props.selectedSparePartAssetId || this.props.sparePartId);
  close = () => {
    if (this.props.selectedSparePartAssetId !== null) {
      this.props.onClose('edit');
    } else {
      this.props.onClose();
    }
  };

  delete = () => {
    this.setState({ showConfirmDeleteModal: false });
    this.props.onDelete(this.props.sparePartAsset.id);
    this.props.onClose('delete');
  };
  update = () => {
    this.setState({ isSaving: true });
    const params = {
      ...this.state.assetParams,
      asset_id: this.props.asset.id,
      spare_part_id: this.props.sparePart.id,
    };
    this.props.updateSparePartAsset(this.props.sparePartAsset.id, params).then(res => {
      const { data } = res;
      const { entities } = normalizeSparePartAsset(data);
      this.props.updateEntities(entities);
      toast(
        <ToastMessage success text={<FormattedMessage id="screens.spare-part.assets.update-success" />} />
      );
      this.close();
      this.setState({ isSaving: false });
    });
  };
  create = () => {
    this.setState({
      isSaving: true,
    });
    const params = {
      ...this.state.assetParams,
      asset_id: this.props.assetId,
      spare_part_id: this.props.sparePartId,
    };
    this.props
      .createSparePartAsset(this.props.currentSystem.id, params)
      .then(res => {
        const { data } = res;
        const { entities } = normalizeSparePartAsset(data);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.spare-part.assets.create-success" />} />
        );
        this.close();
        this.setState({ isSaving: false });
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.quantity" />}>
              <Field.Decimal
                rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
                disabled={!this.props.canEditSpareParts && !this.props.canEditAssets}
                value={this.state.assetParams.quantity}
                onChange={quantity => this.setState({ assetParams: { ...this.state.assetParams, quantity } })}
                onBlur={quantity => this.setState({ assetParams: { ...this.state.assetParams, quantity } })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.comment" />}>
              <Field.Textarea
                disabled={!this.props.canEditSpareParts && !this.props.canEditAssets}
                value={this.state.assetParams.comment}
                onChange={comment => this.setState({ assetParams: { ...this.state.assetParams, comment } })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.isLoaded) return <Loader />;
    else
      return (
        <>
          <Modal.Header
            ignoreLine
            title={
              this.props.canEditSpareParts && this.props.canEditAssets ? (
                this.props.selectedSparePartAssetId !== null ? (
                  <FormattedMessage id="screens.spare-part.assets.spare-part-asset-modal.edit-title" />
                ) : (
                  <FormattedMessage id="screens.spare-part.assets.spare-part-asset-modal.title" />
                )
              ) : (
                <FormattedMessage id="screens.spare-part.assets.spare-part-asset-modal.view-title" />
              )
            }
            subtitle={
              <FormattedMessage
                id="screens.spare-part.assets.spare-part-asset-modal.spare-part-name"
                values={{
                  sparePart: (
                    <span className={styles['highlighted-text']}>
                      <Title inline sparePart={this.props.sparePart} />
                    </span>
                  ),
                }}
              />
            }
            onClose={() => this.close()}
          />
          <Modal.Content>
            {this.renderTitleField()}
            {this.renderFields()}
          </Modal.Content>
        </>
      );
  };
  renderImage = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: this.props.size, height: this.props.size }}>
          <ContentLoader viewBox={`0 0 ${this.props.size} ${this.props.size}`} preserveAspectRatio="xMinYMin">
            <circle cx={this.props.size / 2} cy={this.props.size / 2} r={this.props.size / 2} />
          </ContentLoader>
        </div>
      );
    }
    if (this.props.asset.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.asset.images[0]})`,
        }}
      />
    );
  };

  renderTitleField = () => {
    if (this.props.asset == null) {
      return null;
    }
    return (
      <div className={styles['title-field']}>
        {this.renderImage()}
        <div className={styles['title']}>
          <AssetTitle id={this.props.asset.id} />
          <TreePath assetId={this.props.asset.id} />
        </div>
      </div>
    );
  };

  render() {
    if (!this.isLoaded()) return <Loader />;
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          {this.props.canEditSpareParts && this.props.canEditAssets ? (
            <Modal.Footer>
              <div className={styles['button-group']}>
                <Button.Group>
                  <Button
                    primary
                    loading={this.state.isSaving}
                    label="general.save"
                    onClick={() => {
                      this.props.selectedSparePartAssetId ? this.update() : this.create();
                    }}
                  />
                  <Button label="general.cancel" onClick={() => this.close()} />
                </Button.Group>
                {this.props.selectedSparePartAssetId ? (
                  <Button primary destructive label="general.delete" onClick={() => this.delete()} />
                ) : null}
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePartAsset: SDKReduxOperations.deleteSparePartAsset,
      updateSparePartAsset: SDKReduxOperations.updateSparePartAsset,
      createSparePartAsset: SDKReduxOperations.createSparePartAsset,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  var asset = null;
  var sparePartAsset = null;
  var sparePart = null;
  var sparePartUnit = null;
  if (ownProps.selectedSparePartAssetId !== null) {
    sparePartAsset = EntitySelectors.getSparePartAsset(state, ownProps.selectedSparePartAssetId);
    asset = EntitySelectors.getAsset(state, sparePartAsset.asset_id);
    sparePart = EntitySelectors.getSparePart(state, sparePartAsset.spare_part_id);
  } else {
    asset = EntitySelectors.getAsset(state, ownProps.assetId);
    sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  }
  if (sparePart != null)
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  return {
    asset,
    sparePart,
    sparePartUnit,
    sparePartAsset,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartAssetModal);
