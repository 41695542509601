import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, NewSearchField, Tooltip, Icon } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { Section, Separator, BackButton } from 'views/scenes/Settings/components';
import { EditGroupModal, MembersModal } from 'views/components/Group';
import { AuthSelectors } from 'state/ducks/auth';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewInitialized: false,
      showGroupModal: false,
      showGroupModalForId: null,
      showMembersModal: false,
      showMembersModalForGroupId: null,
      searchTerm: '',
    };
  }

  componentDidMount() {
    this.props.fetchGroups(this.props.selectedSystem.id, { type: 'work_order_assignee' }).then(() => {
      this.setState({ viewInitialized: true });
    });
  }

  listIsEmpty = () =>
    this.props.groups.length === 0 &&
    this.props.createdGroups.length === 0 &&
    this.state.searchTerm.length === 0;

  renderSearchedEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      modal
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.work-orders.groups.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() => this.setState({ showGroupModal: true, showGroupModalForId: null })}
          label="screens.settings.work-orders.groups.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order-type.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <React.Fragment>
      <Toolbar
        buttons={
          <Button
            primary
            onClick={() => this.setState({ showGroupModal: true, showGroupModalForId: null })}
            label="screens.settings.work-orders.groups.add-button"
          />
        }
        search={
          <NewSearchField
            value={this.state.searchTerm}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            onSearch={searchTerm => this.setState({ searchTerm })}
          />
        }
      />
      {this.renderHeader()}
      <List small>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderGroups = () => {
    const filteredGroups = this.props.groups.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const filteredCreatedGroups = this.props.createdGroups.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const foundNoSearchResult = filteredCreatedGroups.length === 0 && filteredGroups.length === 0;
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() => this.setState({ showGroupModal: true, showGroupModalForId: null })}
              label="screens.settings.work-orders.groups.add-button"
            />
          }
          search={
            <NewSearchField
              value={this.state.searchTerm}
              placeholder={this.props.intl.formatMessage({
                id: 'general.search-placeholder',
              })}
              onSearch={searchTerm => {
                this.setState({ searchTerm });
              }}
              onClear={() => this.setState({ searchTerm: '' })}
            />
          }
        />
        {foundNoSearchResult ? (
          this.renderSearchedEmptyDataset()
        ) : (
          <>
            {this.renderHeader()}
            <List small>
              {filteredCreatedGroups.map(group => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showGroupModal: true,
                      showGroupModalForId: group.id,
                    })
                  }
                >
                  <List.Item.TitleColumn
                    title={group.title}
                    subtitle={
                      <FormattedMessage
                        id="screens.settings.work-orders.groups.members-count"
                        values={{ amount: group.user_count }}
                      />
                    }
                  />
                  <List.Item.Column alignRight>
                    <Tooltip
                      trigger={
                        <div>
                          <Button
                            type="icon"
                            icon={<Icon regular type="user" />}
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({
                                showMembersModal: true,
                                showMembersModalForGroupId: group.id,
                              });
                            }}
                          />
                        </div>
                      }
                      label={
                        <FormattedMessage id="screens.settings.work-orders.groups.manage-members-tooltip" />
                      }
                    />
                  </List.Item.Column>
                </List.Item>
              ))}
              {filteredGroups.map(group => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showGroupModal: true,
                      showGroupModalForId: group.id,
                    })
                  }
                >
                  <List.Item.TitleColumn
                    title={group.title}
                    subtitle={
                      <FormattedMessage
                        id="screens.settings.work-orders.groups.members-count"
                        values={{ amount: group.user_count }}
                      />
                    }
                  />
                  <List.Item.Column alignRight>
                    <Tooltip
                      trigger={
                        <div>
                          <Button
                            type="icon"
                            icon={<Icon regular type="user" />}
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({
                                showMembersModal: true,
                                showMembersModalForGroupId: group.id,
                              });
                            }}
                          />
                        </div>
                      }
                      label={
                        <FormattedMessage id="screens.settings.work-orders.groups.manage-members-tooltip" />
                      }
                    />
                  </List.Item.Column>
                </List.Item>
              ))}
            </List>
          </>
        )}
      </>
    );
  };

  renderContent() {
    if (!this.state.viewInitialized) {
      return this.renderLoader();
    } else if (this.listIsEmpty()) {
      return this.renderEmptyDataset();
    } else {
      return this.renderGroups();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/work-orders" />
        <Section
          title={<FormattedMessage id="screens.settings.work-orders.groups.title" />}
          subtitle={<FormattedMessage id="screens.settings.work-orders.groups.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditGroupModal
          open={this.state.showGroupModal}
          type="work_order_assignee"
          id={this.state.showGroupModalForId}
          onClose={() => this.setState({ showGroupModal: false })}
        />
        <MembersModal
          open={this.state.showMembersModal}
          groupId={this.state.showMembersModalForGroupId}
          onClose={() => this.setState({ showMembersModal: false })}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchGroups: SettingsOperations.fetchGroups,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    createdGroups: SettingsSelectors.getCreatedGroups(state),
    groups: SettingsSelectors.getGroups(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Groups));
