import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import {
  SparePartWithdrawalsSelectors,
  SparePartWithdrawalsOperations,
} from 'state/ducks/sparePartWithdrawals';
import { FILTER_TYPES } from 'views/scenes/SparePartWithdrawals/FilterModal';
import SparePartContainer from './SparePartContainer';

class SparePart extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter.id;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <SparePartContainer id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter.id;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="screens.withdrawals.filters.advanced.spare-parts.spare-part" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.SparePart,
            data: {
              spare_part: {
                ...this.props.filter,
                id: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: SparePartWithdrawalsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: SparePartWithdrawalsSelectors.getFiltersForKey(state, 'spare_part'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePart);
