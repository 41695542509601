import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const showSettings = (systemId, params, config = null) =>
  request.get(`systems/${systemId}/settings?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const updateSettings = (systemId, data, config = null) =>
  request.patch(`systems/${systemId}/settings`, data, config);

export const showSystemSettingsAsAdmin = systemId => request.get(`admin/systems/${systemId}/settings`);
export const updateSystemSettingsAsAdmin = (systemId, data, config = null) => request.patch(`admin/systems/${systemId}/settings`, data, config);

export const showOidcWellKnown = (organisationId, params, config = null) =>
  request.get(`organisations/${organisationId}/oidc_well_known?${HelperFunctions.convertObjToQueryParameters(params)}`, config);