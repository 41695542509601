import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const ParentLocationTitleContainer = ({ sparePartLocation, rootLocationTitle }) => {
  if (sparePartLocation) {
    return <span>{sparePartLocation.title}</span>;
  }
  return <FormattedMessage id="components.move-spare-part-location-inline-modal.move-spare-part-location.title" />;
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocation: EntitySelectors.getSparePartLocation(state, ownProps.parentSparePartLocationId),
  };
}
export default connect(mapStateToProps)(ParentLocationTitleContainer);
