import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const updateChecklistInstanceOption = (checklistInstanceOptionId, data) =>
  request.patch(`checklist_instance_options/${checklistInstanceOptionId}`, data);

export const createForChecklistInstance = (checklistInstanceId, data) =>
  request.post(`checklist_instances/${checklistInstanceId}/checklist_instance_options`, data);
export const createForOperationalMaintenanceTemplateAsset = (templateAssetId, data) =>
  request.post(`operational_maintenance_template_assets/${templateAssetId}/checklist_instance_options`, data);
