import React, { Component } from 'react';
import { connect } from 'react-redux';
import Decimal from 'decimal.js';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Icon, FormattedNumber, Field as GeneralField } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/WorkOrder';
import styles from '../style.module.scss';

class DueDate extends Component {
  /*
    Render functions
  */

  renderSelectedMeterValue = () => {
    if (this.props.workOrder.due_date) {
      return moment(this.props.workOrder.due_date).format('LL');
    }
    if (this.props.workOrder.due_meter_value) {
      return (
        <FormattedNumber
          value={this.props.workOrder.due_meter_value}
          unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
        />
      );
    }
    return '-';
  };

  renderTolerance = () => {
    if (new Decimal(this.props.workOrder.due_meter_tolerance || 0).greaterThan(new Decimal(0))) {
      return (
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-tolerance" />}
        >
          <FormattedNumber
            value={this.props.workOrder.due_meter_tolerance}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </GeneralField>
      );
    }
    return null;
  };

  renderAverageUsage = () => {
    if (this.props.meter.average_daily_usage) {
      return (
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-average-increase" />}
        >
          {this.props.meter.average_daily_usage ? (
            <FormattedMessage
              id="screens.work-order.meter-average-increase-value"
              values={{
                value: (
                  <FormattedNumber
                    value={this.props.meter.average_daily_usage}
                    unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          ) : (
            <span>-</span>
          )}
        </GeneralField>
      );
    }
    return null;
  };

  renderEstimatedDate = () => {
    if (this.props.workOrder.estimated_meter_due_date) {
      return (
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="resources.work-order.estimated-meter-due-date" />}
        >
          {this.props.workOrder.estimated_meter_due_date
            ? moment(this.props.workOrder.estimated_meter_due_date).format('LL')
            : '-'}
        </GeneralField>
      );
    }
    return null;
  };

  renderExtraMeterValues = () => {
    if (this.props.workOrder.due_date) {
      return (
        <div className={styles['extra-meter-values']}>
          <GeneralField
            singleRow
            view
            fontSize={11}
            label={<FormattedMessage id="resources.work-order.due-date" />}
          >
            <FormattedNumber
              value={this.props.workOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </GeneralField>
          <GeneralField
            singleRow
            view
            fontSize={11}
            label={<FormattedMessage id="screens.work-order.meter-current-value" />}
          >
            <FormattedNumber
              value={this.props.meter.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </GeneralField>
          <div className={styles['separator']} />
        </div>
      );
    }
    return (
      <div className={styles['extra-meter-values']}>
        {this.renderTolerance()}
        <GeneralField
          singleRow
          view
          fontSize={11}
          label={<FormattedMessage id="screens.work-order.meter-current-value" />}
        >
          <FormattedNumber
            value={this.props.meter.value}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </GeneralField>
        {this.renderAverageUsage()}
        {this.renderEstimatedDate()}
        <div className={styles['separator']} />
      </div>
    );
  };

  renderMeterValue = () => {
    return (
      <>
        <Field
          icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
          label={<FormattedMessage id="resources.work-order.due-date" />}
          value={this.renderSelectedMeterValue()}
        />
        {this.renderExtraMeterValues()}
      </>
    );
  };

  renderSelectedDateValue = () => {
    if (this.props.workOrder.on_occasion) {
      return <FormattedMessage id="resources.work-order.on-occasion" />;
    }
    if (this.props.workOrder.due_date) {
      return <>{moment(this.props.workOrder.due_date).format('LL')}</>;
    }
    return <>{'-'}</>;
  };

  renderDateValue = () => {
    return (
      <Field
        icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
        label={<FormattedMessage id="resources.work-order.due-date" />}
        value={this.renderSelectedDateValue()}
      />
    );
  };

  renderContent() {
    if (this.props.meter) {
      return this.renderMeterValue();
    }
    return this.renderDateValue();
  }

  render() {
    return <div className={`${styles['row']} ${styles['date']}`}>{this.renderContent()}</div>;
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
  };
}

export default connect(mapStateToProps)(DueDate);
