import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { Icon } from '../';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import List from './List';

class Datapill extends Component {
  renderLoader = () => (
    <div style={{ 
      width: 70, 
      height: 23,
      marginBottom: 5 }}
    >
      <ContentLoader 
        viewBox="0 0 70 23" 
        width={70}
        height={23}
        preserveAspectRatio="xMinYMin"
        primaryColor="#00000014"
        secondaryColor="#0000000A"
      >
        <rect x="0" y="0" rx="12" ry="12" width="70" height="23" />
      </ContentLoader>
    </div>
  )

  renderLabel = () => {
    if (this.props.label) {
      return (
        <div className={styles['label']}>
          <span>{this.props.label}</span>
          <span>:</span>
          <span>&nbsp;</span>
        </div>
      );
    }
    return null;
  };

  renderValue = () => {
    return <div className={styles['value']}>{this.props.value}</div>;
  };

  renderIcon = () => {
    if (this.props.icon) {
      return (
        <div className={styles['icon']}>
          <Icon regular size={11} type={this.props.icon} />
        </div>
      );
    }
    return null;
  };

  render() {
    if(this.props.loading) return this.renderLoader();

    let classNames = [styles['data-pill']];
    if (this.props.clickable) classNames = [...classNames, styles['clickable']];
    if (this.props.red) classNames = [...classNames, styles['red']];
    if (this.props.gray) classNames = [...classNames, styles['gray']];

    return (
      <div className={classNames.join(' ')} onClick={this.props.clickable ? this.props.onClick : undefined}>
        {this.renderIcon()}
        {this.renderLabel()}
        {this.renderValue()}
      </div>
    );
  }
}

Datapill.List = List;
export default Datapill;

Datapill.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.node.isRequired,
  red: PropTypes.bool,
  gray: PropTypes.bool,
  loading: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
};

Datapill.defaultProps = {
  icon: null,
  label: null,
  value: null,
  red: false,
  gray: false,
  loading: false,
  clickable: false,
  onClick: () => {},
};
