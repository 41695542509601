import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ChecklistListItem extends Component {
  render() {
    return (
      <List.Item
        clickable
        small
        onClick={this.props.onClick}
        linkTo={`/checklists/${this.props.id}`}
        new={this.props.new}
      >
        <List.Item.TitleColumn title={this.props.checklistTemplate.title} />
        <List.Item.Column alignRight lightTextColor>
          {this.props.checklistTemplate.instances_count === 0 ? (
            <FormattedMessage id="screens.checklists.filled-checklist-empty" />
          ) : (
            <FormattedPlural
              value={this.props.checklistTemplate.instances_count}
              one={
                <FormattedMessage
                  id="screens.checklists.filled-amount-of-times.one"
                  values={{
                    amount: (
                      <span className={styles['filled']}>{this.props.checklistTemplate.instances_count}</span>
                    ),
                  }}
                />
              }
              other={
                <FormattedMessage
                  id="screens.checklists.filled-amount-of-times.other"
                  values={{
                    amount: (
                      <span className={styles['filled']}>{this.props.checklistTemplate.instances_count}</span>
                    ),
                  }}
                />
              }
            />
          )}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, ownProps.id),
  };
}

export default withRouter(connect(mapStateToProps)(ChecklistListItem));
