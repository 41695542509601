import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { CardGrid } from 'views/components/Shared/Layout';
import { connect } from 'react-redux';
import { WhiteCard, SectionHeader, ElapsedTime } from 'views/components/Shared/General';
import { DowntimeTimer, DowntimeModal } from 'views/components/Downtime';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class Downtimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDowntimeModalForId: null,
      showDowntimeModal: false,
      showNewDowntimeModal: false,
      showSelectDowntimeModal: false,
    };
  }

  getTotalStopTimeAsMinutes = downtime => {
    const fromDate = moment(downtime.from);
    const toDate = moment(downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  renderOtherAsset = downtime => {
    const isSameAsset = this.props.workOrder.asset_id == downtime.asset_id;
    if (!downtime.asset_id) return null;
    if (isSameAsset) {
      return (
        <div className={styles['asset-path-container']}>
          <AssetTitle id={downtime.asset_id} />
        </div>
      );
    }

    return (
      <div className={styles['asset-path-container']}>
        <TreePath assetId={downtime.asset_id} fullPath />
      </div>
    );
  };

  renderDowntime = downtime => {
    if (downtime.to == null) {
      return (
        <div>
          <div className={styles['timer-label']}>
            <FormattedMessage id="screens.work-order.downtime.active-downtime-label" />
          </div>
          <div className={styles['subtitle']}>
            <DowntimeTimer downtime={downtime} />
            {this.renderOtherAsset(downtime)}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={styles['missed-operating-minutes']}>
          <ElapsedTime elapsedMinutes={this.getTotalStopTimeAsMinutes(downtime)} />
        </div>
        <div>
          <div className={styles['subtitle']}>
            {moment(downtime.created_at).format('LL')}
            {this.renderOtherAsset(downtime)}
          </div>
        </div>
      </div>
    );
  };

  renderDowntimes = () => {
    if (!this.props.downtimes || this.props.downtimes.length == 0) {
      return <div className={styles['content-container']}></div>;
    }

    return (
      <>
        {this.props.downtimes.map(downtime => (
          <div
            key={downtime.id}
            className={styles['content-container']}
            onClick={() => this.setState({ showDowntimeModalForId: downtime.id, showDowntimeModal: true })}
          >
            {this.renderDowntime(downtime)}
          </div>
        ))}
      </>
    );
  };

  render() {
    if (!this.props.downtimes || this.props.downtimes.length == 0) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <WhiteCard noPadding>
            <SectionHeader noBorderTop paddingHorizontal={26}>
              <div className={styles['section']}>
                <FormattedMessage id="screens.work-order.downtime.title" />
              </div>
            </SectionHeader>
            <div className={styles['content']}>{this.renderDowntimes()}</div>
          </WhiteCard>
        </CardGrid.Row>
        <DowntimeModal
          open={this.state.showDowntimeModal}
          id={this.state.showDowntimeModalForId}
          openedFromWorkOrderId={this.props.match.params.id}
          onClose={() => {
            this.setState({ showDowntimeModal: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;
  return {
    downtimes: EntitySelectors.getDowntimes(state, workOrder.downtimes),
  };
}

export default withRouter(connect(mapStateToProps)(Downtimes));
