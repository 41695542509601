import React, { Component } from 'react';
import SparePartListItem from './SparePartListItem';

class SparePartList extends Component {
  render() {
    return this.props.ids.map(sparePartId => {
      return (
        <SparePartListItem
          listItemRightComponent={this.props.listItemRightComponent}
          loading={this.props.loading}
          id={sparePartId}
        />
      );
    });
  }
}

export default SparePartList;
