import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';

class EditTitleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      title: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        title: this.props.checklistTemplateVersion.title || '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    const { title } = this.state;
    this.setState({ isSaving: true });
    this.props.updateChecklistTemplateVersion(this.props.checklistTemplateVersion.id, { title }).then(() => {
      this.props.onClose();
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.title) {
      this.save();
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.edit-checklist-title-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Field view={false} label={<FormattedMessage id="resources.checklist-template.title" />}>
            <Field.Text
              autoFocus
              value={this.state.title}
              onKeyPress={this.handleKeyPress}
              onChange={title => {
                this.setState({ title });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={this.state.title.length === 0}
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateChecklistTemplateVersion: SDKReduxOperations.updateChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateVersion: ChecklistEditModalSelectors.getChecklistTemplateVersion(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditTitleModal));
