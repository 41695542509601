import React from 'react';
import { connect } from 'react-redux';
import { EstimatedDeliveryMode, getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { EntitySelectors } from 'sdk/State/entities';

const ExpectedDeliveryDateColumn = ({ purchaseOrders }) => {
  const getPurchaseOrderWithLastDeliveryDate = purchaseOrders.sort((a, b) => {
    let aDateToCompare = null;
    let bDateToCompare = null;
    if (a.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
      aDateToCompare = a.estimated_delivery_date;
    } else {
      aDateToCompare = a.estimated_delivery_to_row_date;
    }
    if (b.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
      bDateToCompare = b.estimated_delivery_date;
    } else {
      bDateToCompare = b.estimated_delivery_to_row_date;
    }
    if (aDateToCompare == null) {
      return 1;
    } else if (bDateToCompare == null) {
      return -1;
    }
    var dateA = new Date(aDateToCompare);
    var dateB = new Date(bDateToCompare);
    return dateB - dateA;
  })[0];

  if (purchaseOrders.length === 0) {
    return <span>-</span>;
  }
  return getEstimatedDeliveryDateText(getPurchaseOrderWithLastDeliveryDate);
};

function mapStateToProps(state, ownProps) {
  const workOrderPurchases = EntitySelectors.getWorkOrderPurchases(
    state,
    ownProps.workOrder.work_order_purchases.map(id => id)
  );
  const purchaseOrderIds = workOrderPurchases.map(({ purchase_order_id }) => purchase_order_id);
  return {
    purchaseOrders: EntitySelectors.getPurchaseOrders(state, purchaseOrderIds),
  };
}

export default connect(mapStateToProps)(ExpectedDeliveryDateColumn);
