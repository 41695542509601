import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class ArchiveRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archived_comment: '',
      isSaving: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        archived_comment: '',
        isSaving: false,
      });
    }
  }

  archive = () => {
    const { archived_comment } = this.state;
    this.setState({ isSaving: true });
    this.props
      .archiveRequest(this.props.request.id, { archived_comment })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.archive-request-modal.archive-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {});
  };

  renderContent = () => (
    <div>
      <Field
        view={false}
        label={<FormattedMessage id="components.archive-request-modal.comment-to-creator" />}
      >
        <Field.Textarea
          autoFocus
          value={this.state.archived_comment}
          onChange={archived_comment => {
            this.setState({ archived_comment });
          }}
        />
      </Field>
    </div>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.archive-request-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.archive} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    request: EntitySelectors.getRequest(state, ownProps.id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      archiveRequest: SDKReduxOperations.archiveRequest,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ArchiveRequestModal));
