import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeOperationalMaintenanceTemplateAsset } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createTemplateAssetForTemplate(templateId, params) {
  return dispatch => {
    return API.createTemplateAssetForTemplate(templateId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceTemplateAsset(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_ASSSET_CREATED_FOR_TEMPLATE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteOperationalMaintenanceTemplateAsset(templateAssetId) {
  return (dispatch, getState) => {
    const templateAsset = getState().entities.operationalMaintenanceTemplateAssetById[templateAssetId];
    return API.deleteOperationalMaintenanceTemplateAsset(templateAssetId)
      .then(res => {
        const payload = { templateAssetId, data: templateAsset };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_ASSSET_DELETED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
