import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Button, List, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { getIconClassNameString, AttachmentVersionStatus } from 'sdk/AttachmentVersion';
import { API } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import OptionsInlineModal from './OptionsInlineModal';
import styles from './style.module.scss';

export default class HistoryModal extends Component {
  state = {
    isFetching: true,
    oldAttachmentVersions: [],
    activeAttachmentVersion: null,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        isFetching: true,
        oldAttachmentVersions: [],
        activeAttachmentVersion: null,
      });

      API.listAttachmentVersionsForAttachment(this.props.attachment.id, {
        no_pagination: true,
      }).then(res => {
        const { data: attachmentVersions } = res;
        this.setState({
          isFetching: false,
          oldAttachmentVersions: attachmentVersions.filter(
            attachmentVersion => attachmentVersion.status === AttachmentVersionStatus.History
          ),
          activeAttachmentVersion: attachmentVersions.find(
            attachmentVersion => attachmentVersion.status === AttachmentVersionStatus.Active
          ),
        });
      });
    }
  }

  renderAttachmentVersion = attachmentVersion => (
    <List.Item>
      <List.Item.IconColumn icon={<Icon type={getIconClassNameString(attachmentVersion)} />} />
      <List.Item.Column width={260}>
        <div className={styles['content']}>
          <p className={styles['version-title']}>
            <FormattedMessage
              id="components.file-history.version"
              values={{ version: attachmentVersion.version_number }}
            />
          </p>
          <p className={styles['version-subtitle']}>{attachmentVersion.title}</p>
        </div>
      </List.Item.Column>

      <List.Item.Column alignRight>
        <div className={styles['created-by']}>
          <div className={styles['image']}>
            <ProfilePicture userId={attachmentVersion.created_by_user_id} />
          </div>
          <div className={styles['user']}>
            <p className={styles['time-ago']}>
              <FormattedMessage
                id="general.created-at"
                values={{ timeAgo: moment(attachmentVersion.created_at).fromNow() }}
              />
            </p>
            <p className={styles['name']}>
              <FormattedMessage
                id="general.created-by"
                values={{ user: <UserNameWrapper user={attachmentVersion.created_by_user} /> }}
              />
            </p>
          </div>
        </div>
      </List.Item.Column>
      <List.Item.Column>
        <div className={styles['options']}>
          {attachmentVersion.status === AttachmentVersionStatus.Active ? (
            <Button
              type="icon"
              icon={<Icon regular type="download" />}
              onClick={() => HelperFunctions.openAttachment(attachmentVersion)}
            />
          ) : (
            <OptionsInlineModal
              attachmentVersion={attachmentVersion}
              onDelete={attachmentVersion => {
                this.setState({
                  oldAttachmentVersions: this.state.oldAttachmentVersions.filter(
                    loopedAttachmentVersion => loopedAttachmentVersion.id !== attachmentVersion.id
                  ),
                });
              }}
              trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
            />
          )}
        </div>
      </List.Item.Column>
    </List.Item>
  );

  renderActiveVersions = () => {
    return (
      <div className={styles['active-container']}>
        <p className={styles['title']}>
          <FormattedMessage id="components.file-history.active-version" />
        </p>
        <List light>{this.renderAttachmentVersion(this.state.activeAttachmentVersion)}</List>
      </div>
    );
  };

  renderOldVersions = () => {
    if (this.state.oldAttachmentVersions.length === 0) return null;
    return (
      <div className={styles['history-container']}>
        <p className={styles['title']}>
          <FormattedMessage id="components.file-history.older-versions" />
        </p>
        <List light>
          {this.state.oldAttachmentVersions.map(attachmentVersion =>
            this.renderAttachmentVersion(attachmentVersion)
          )}
        </List>
      </div>
    );
  };

  renderVersions = () => (
    <React.Fragment>
      {this.renderActiveVersions()}
      {this.renderOldVersions()}
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.isOpen} width={700}>
        <Modal.Header
          title={<FormattedMessage id="components.file-history.title" />}
          onClose={this.props.onClose}
        />
        {this.state.isFetching ? (
          <Modal.Loader noHeader />
        ) : (
          <Modal.Content>{this.renderVersions()}</Modal.Content>
        )}
      </Modal>
    );
  }
}
