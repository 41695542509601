/*
  --------------------
    DATA SELECTORS
  --------------------
*/
const getAsset = state => {
  const { asset, entities } = state;
  if (!asset.id) return null;
  return entities.assetById[asset.id];
};

const getWorkOrders = state => {
  const { entities, asset } = state;
  return asset.workOrders.ids.map(id => entities.workOrderById[id]);
};
const getWorkOrderListPagination = state => state.asset.workOrders.pagination;

const getInstancesForDate = (state, date) => {
  const { asset } = state;
  if (asset.workOrders.calendar[date] == null) {
    return [];
  }
  return asset.workOrders.calendar[date].map(id => asset.workOrders.instanceById[id]);
};

const getPreviewOverlayData = state => {
  const { asset } = state;
  return asset.workOrders.previewOverlay;
};

const getPreviewMoreWorkOrdersOverlayData = state => {
  const { asset } = state;
  return asset.workOrders.showPreviewMoreWorkOrdersOverlay;
};

const getDraggingWorkOrderId = state => {
  const { asset } = state;
  return asset.workOrders.isDraggingWorkOrderId;
};
const getDraggingRecurringMaintenanceId = state => {
  const { asset } = state;
  return asset.workOrders.isDraggingRecurringMaintenanceId;
};

const getInstance = (state, id) => {
  const { asset } = state;
  return asset.workOrders.instanceById[id];
};

const getAssetVendorIds = state => state.asset.assetVendors.ids;
const getAssetVendorsPagination = state => state.asset.assetVendors.pagination;

const getSparePartAssetIds = state => {
  const { asset } = state;
  return asset.sparePartAssets.ids;
};

const getSparePartAssetsPagination = state => state.asset.sparePartAssets.pagination;

const getDowntimeIds = state => {
  const { asset } = state;
  return asset.downtimes.ids;
};
const getDowntimesPagination = state => state.asset.downtimes.pagination;

/*
  --------------------
    UI SELECTORS
  --------------------
*/

export default {
  getInstancesForDate,
  getPreviewOverlayData,
  getPreviewMoreWorkOrdersOverlayData,
  getDraggingWorkOrderId,
  getDraggingRecurringMaintenanceId,
  getInstance,
  getAsset,
  getDowntimeIds,
  getDowntimesPagination,
  getWorkOrders,
  getWorkOrderListPagination,
  getAssetVendorIds,
  getAssetVendorsPagination,
  getSparePartAssetIds,
  getSparePartAssetsPagination,
};
