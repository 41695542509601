import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { ContentContainer } from 'views/components/Shared/Layout';
import { Button, NewTab } from 'views/components/Shared/General';
import { CreateDeliveryModal } from 'views/components/PurchaseOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import Deliveries from './Deliveries';
import Articles from './Articles';
import styles from './style.module.scss';

const LIST_TYPES = {
  Articles: 'articles',
  Deliveries: 'deliveries',
};

class DeliveriesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateDeliveryModal: false,
    };
  }

  renderCreateDeliveryButton = () => {
    if (this.props.purchaseOrder.status === PurchaseOrderStatus.Draft) {
      return null;
    }
    if (this.props.canCreateWithdrawals == false) {
      return null;
    }
    return (
      <div>
        <Button
          primary
          label="screens.purchase-order.deliveries.empty-data-set.button"
          onClick={() => this.setState({ showCreateDeliveryModal: true })}
        />
      </div>
    );
  };

  renderToolbar = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <div className={styles['toolbar']}>
        <div className={styles['left-content']}>
          <NewTab.Container>
            <NewTab
              selected={list === LIST_TYPES.Articles}
              linkTo={{
                search: `list=${LIST_TYPES.Articles}`,
              }}
            >
              <FormattedMessage id="screens.purchase-order.deliveries.tabs.articles" />
            </NewTab>
            <NewTab
              selected={list === LIST_TYPES.Deliveries}
              linkTo={{
                search: `list=${LIST_TYPES.Deliveries}`,
              }}
            >
              <FormattedMessage id="screens.purchase-order.deliveries.tabs.deliveries" />
            </NewTab>
          </NewTab.Container>
        </div>
        {this.renderCreateDeliveryButton()}
      </div>
    );
  };

  renderCreateDeliveryModal = () => (
    <CreateDeliveryModal
      open={this.state.showCreateDeliveryModal}
      purchaseOrder={this.props.purchaseOrder}
      onSave={() => {
        this.setState({
          showCreateDeliveryModal: false,
        });
      }}
      onClose={() => {
        this.setState({ showCreateDeliveryModal: false });
      }}
    />
  );

  renderView = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.Deliveries:
        return <Deliveries />;
      case LIST_TYPES.Articles:
        return <Articles />;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>
            {this.renderToolbar()}
            {this.renderView()}
          </ContentContainer>
        </PerfectScrollbar>
        {this.renderCreateDeliveryModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrder: EntitySelectors.getPurchaseOrder(state, ownProps.match.params.id),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps)(DeliveriesContainer);
