import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import styles from './style.module.scss';

class EditLatePurchaseOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchase_order_late_after_days: '',
      purchase_order_late_notification: false,
      isSaving: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { purchase_order_late_after_days } = this.props.currentSystem;
      this.setState({
        isSaving: false,
        purchase_order_late_after_days,
      });
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    this.setState({ isSaving: true });
    const { purchase_order_late_after_days } = this.state;
    this.props
      .updateSystem(this.props.currentSystem.id, {
        purchase_order_late_after_days: purchase_order_late_after_days || 0,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className={styles['field-container']}>
            <Field.Number
              autoFocus
              value={this.state.purchase_order_late_after_days}
              rightLabel={
                <FormattedMessage id="screens.settings.purchase-orders.late-delivery.amount-of-days-input-label" />
              }
              onChange={purchase_order_late_after_days => {
                this.setState({ purchase_order_late_after_days });
              }}
              onBlur={purchase_order_late_after_days => {
                this.setState({ purchase_order_late_after_days });
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.purchase-orders.late-delivery.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.late-delivery.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditLatePurchaseOrderModal));
