import React from 'react';
import PropTypes from 'prop-types';
import { NewSearchField } from 'views/components/Shared/General';
import styles from './style.module.scss';

const Header = props => {
  const renderSearch = () => {
    if (props.searchable) {
      return (
        <div className={styles['search-field']}>
          <NewSearchField
            value={props.searchValue}
            placeholder={props.searchPlaceHolder}
            debounce
            onSearch={value => {
              props.onSearch(value);
            }}
            onDebouncedSearch={value => {
              props.onDebouncedSearch(value);
            }}
            onClear={() => {
              props.onClearSearch();
            }}
          />
        </div>
      );
    }
    return null;
  };

  const renderTotalEntries = () => {
    if (props.totalEntries) {
      return <div className={styles['total-entries']}>{props.totalEntries}</div>;
    }
    return null;
  };

  return (
    <div className={styles['header']}>
      <div className={styles['left-container']}>
        <div className={styles['title']}>{props.title}</div>
        {props.leftContainer}
      </div>
      <div className={styles['right-container']}>
        {renderTotalEntries()}
        {renderSearch()}
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.node,
  totalEntries: PropTypes.node,
  searchable: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  onDebouncedSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
  searchPlaceHolder: PropTypes.string,
};

Header.defaultProps = {
  title: null,
  totalEntries: null,
  searchable: false,
  searchValue: '',
  onSearch: () => {},
  onDebouncedSearch: () => {},
  onClearSearch: () => {},
  searchPlaceHolder: null,
};
