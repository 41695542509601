import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { PurchaseOrderStatus, getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { List, MoneyWithCurrency, Datapill } from 'views/components/Shared/General';
import { ListItemStatus } from 'views/components/PurchaseOrder';

const PurchaseOrderListItem = ({ vendor, purchaseOrder, loading, ourReferenceUser }) => {
  const renderOurReferenceDatapill = () => {
    return (
      <Datapill
        value={ourReferenceUser ? ourReferenceUser.name : '-'}
        label={<FormattedMessage id="resources.purchase-order.our-ref" />}
      />
    );
  };

  const renderDateDatapill = () => {
    if (purchaseOrder.status === PurchaseOrderStatus.Draft) {
      return (
        <Datapill
          value={moment(purchaseOrder.created_at).format('LL')}
          label={<FormattedMessage id="resources.purchase-order.created-at" />}
        />
      );
    }
    if (purchaseOrder.status === PurchaseOrderStatus.Ordered) {
      return (
        <Datapill
          value={moment(purchaseOrder.ordered_date).format('LL')}
          label={<FormattedMessage id="resources.purchase-order.ordered-date-short" />}
        />
      );
    }
  };

  const renderEstimatedDateDatapill = () => {
    if (purchaseOrder.status === PurchaseOrderStatus.Ordered) {
      const estimatedDate = getEstimatedDeliveryDateText(purchaseOrder);
      if (estimatedDate) {
        return (
          <Datapill
            value={estimatedDate}
            label={<FormattedMessage id="resources.purchase-order.expected-delivery-date-short" />}
          />
        );
      }
    }
    return null;
  };

  const renderDataPills = () => {
    return (
      <Datapill.List>
        {renderOurReferenceDatapill()}
        {renderDateDatapill()}
        {renderEstimatedDateDatapill()}
      </Datapill.List>
    );
  };
  if (loading) {
    return (
      <List.Item>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }
  return (
    <List.Item
      dataPillsComponent={renderDataPills()}
      linkTo={{
        pathname: `/purchase-orders/${purchaseOrder.id}`,
      }}
    >
      <List.Item.TitleColumn title={vendor.name} subtitle={`#${purchaseOrder.number}`} />
      <List.Item.Column width={150}>{<ListItemStatus purchaseOrder={purchaseOrder} />}</List.Item.Column>
      <List.Item.Column width={150} alignRight>
        <MoneyWithCurrency currency={purchaseOrder.currency} value={purchaseOrder.total} />
      </List.Item.Column>
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    ourReferenceUser: EntitySelectors.getUser(state, ownProps.purchaseOrder.our_reference_user_id),
  };
}

export default connect(mapStateToProps)(PurchaseOrderListItem);
