import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Decimal from 'decimal.js';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { ExportType } from 'sdk/Export';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { InlineModal, Button, Icon, FormattedNumber } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import SendEmailModal from '../SendEmailModal';
import PrintModal from 'views/scenes/WorkOrders/WorkOrderList/PrintModal';
import SelectVendorModal from '../SelectVendorModal';
import DeleteWorkOrder from './DeleteWorkOrder';
import styles from './style.module.scss';

class Menu extends Component {
  state = {
    isOpen: false,
    showDeleteWorkOrder: false,
    dropdownMode: 'list',
    isDeleting: false,
    showSendEmailModal: false,
    showEditWorkOrderEmailDefaultValuesModal: false,
    showPrintWorkOrderModal: false,
    showSelectVendorModal: false,
    showSendEmailModalForVendorId: null,
  };

  deleteWorkOrder = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteWorkOrder(this.props.match.params.id)
      .then(() => {
        const { search } = this.props.navigatedTo;
        this.props.history.push({
          pathname: '/work-orders',
          search: HelperFunctions.convertObjToQueryParameters(search),
        });
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  hasActiveRecurringMaintenance = () => {
    return this.props.recurringMaintenance && this.props.recurringMaintenance.status === 'active';
  };

  renderDeleteWorkOrderListItemSubtitle = () => {
    const { due_date, due_meter_value, completed_date, status } = this.props.workOrder;
    if (status === WorkOrderStatus.Completed) {
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.option.subtitle-completed"
          values={{
            number: this.props.workOrder.number,
            date: moment(completed_date).format('LL'),
          }}
        />
      );
    }
    if (due_date) {
      return (
        <FormattedMessage
          id={
            'screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.option.subtitle-active'
          }
          values={{
            number: this.props.workOrder.number,
            date: moment(due_date).format('LL'),
          }}
        />
      );
    }
    if (due_meter_value) {
      const { meterUnit } = this.props;
      let meterValue = new Decimal(due_meter_value).toDecimalPlaces(2).toString();
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.option.subtitle-active-meter"
          values={{
            number: this.props.workOrder.number,
            value: <FormattedNumber value={meterValue} unit={meterUnit ? meterUnit.abbreviation : null} />,
          }}
        />
      );
    }
  };

  renderDeleteWorkOrderSubtitle = () => {
    const { due_date, due_meter_value, completed_date, status } = this.props.workOrder;
    if (status === WorkOrderStatus.Completed) {
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.subtitle-completed"
          values={{ date: moment(completed_date).format('LL') }}
        />
      );
    }
    if (due_date) {
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.subtitle-active"
          values={{ date: moment(this.props.workOrder.due_date).format('LL') }}
        />
      );
    }
    if (due_meter_value) {
      const { meterUnit } = this.props;
      let meterValue = new Decimal(due_meter_value).toDecimalPlaces(2).toString();
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.subtitle-active-meter"
          values={{
            number: this.props.workOrder.number,
            value: <FormattedNumber value={meterValue} unit={meterUnit ? meterUnit.abbreviation : null} />,
          }}
        />
      );
    }
  };

  renderOptions = () => {
    let inlineModalOptions = [];
    if (
      this.props.canAdministrateWorkOrders &&
      this.props.canViewVendors &&
      this.props.workOrder.assigned_to_vendors &&
      this.props.workOrder.assigned_to_vendors.length > 0
    ) {
      inlineModalOptions = [
        ...inlineModalOptions,
        <InlineModal.ListItem
          key={1}
          icon="envelope"
          iconThickness="regular"
          title={<FormattedMessage id="screens.work-order.send-to-vendor" />}
          onClick={() => {
            if (this.props.workOrder.assigned_to_vendors.length === 1) {
              this.setState({
                showSendEmailModal: true,
                isOpen: false,
                showSendEmailModalForVendorId: this.props.workOrder.assigned_to_vendors,
              });
            } else {
              this.setState({ isOpen: false, showSelectVendorModal: true });
            }
          }}
        />,
      ];
    }
    inlineModalOptions = [
      ...inlineModalOptions,
      <InlineModal.ListItem
        key={2}
        icon="print"
        iconThickness="regular"
        title={<FormattedMessage id="screens.work-order.print" />}
        onClick={() => {
          this.setState({ isOpen: false, showPrintWorkOrderModal: true });
        }}
      />,
    ];
    if (this.hasActiveRecurringMaintenance() && this.props.canAdministrateWorkOrders) {
      inlineModalOptions = [
        ...inlineModalOptions,
        <>
          <InlineModal.Separator key={3} />
          <InlineModal.ListItem
            key={4}
            icon="repeat"
            iconThickness="regular"
            title={
              <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.option.title" />
            }
            subtitle={
              <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.option.subtitle" />
            }
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.onCancelRepeat();
            }}
          />
          <InlineModal.ListItem
            key={5}
            icon="trash-alt"
            iconThickness="regular"
            iconColor="#C55050"
            destructive
            title={<FormattedMessage id="general.delete" />}
            subtitle={this.renderDeleteWorkOrderListItemSubtitle()}
            onClick={() => this.setState({ dropdownMode: 'delete' })}
          />
        </>,
      ];
    } else {
      if (
        this.props.canAdministrateWorkOrders ||
        (this.props.canCarryOutWorkOrders &&
          this.props.workOrder.created_by_user_id === this.props.currentUser.id)
      ) {
        inlineModalOptions = [
          ...inlineModalOptions,
          <InlineModal.ListItem
            key={6}
            icon="trash-alt"
            iconThickness="regular"
            iconColor="#C55050"
            destructive
            title={<FormattedMessage id="general.delete" />}
            onClick={() => {
              this.setState({ dropdownMode: 'delete' });
            }}
          />,
        ];
      }
    }

    return inlineModalOptions;
  };

  renderDeleteWorkOrder = () => {
    if (this.hasActiveRecurringMaintenance()) {
      return (
        <>
          <InlineModal.Header
            title={
              <FormattedMessage
                id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.title"
                values={{ number: this.props.workOrder.number }}
              />
            }
            subtitle={this.renderDeleteWorkOrderSubtitle()}
            onClose={() => this.setState({ isOpen: false })}
          />
          <InlineModal.Body width={340}>
            <DeleteWorkOrder
              workOrder={this.props.workOrder}
              recurringMaintenance={this.props.recurringMaintenance}
              onDeleteWorkOrder={() => {
                const { search } = this.props.navigatedTo;
                this.props.history.push({
                  pathname: '/work-orders',
                  search: HelperFunctions.convertObjToQueryParameters(search),
                });
              }}
            />
          </InlineModal.Body>
        </>
      );
    }
    return (
      <>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="screens.work-order.delete-confirm.title" />}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete
            loading={this.state.isDeleting}
            onDelete={() => {
              this.deleteWorkOrder();
            }}
          >
            <FormattedMessage id="screens.work-order.delete-confirm.subtitle" />
          </InlineModal.Delete>
        </InlineModal.Body>
      </>
    );
  };

  renderInlineModalContent = () => {
    switch (this.state.dropdownMode) {
      case 'list':
        return (
          <div className={styles['dropdown']}>
            <InlineModal.Body width={250} dropdown>
              <>{this.renderOptions()}</>
            </InlineModal.Body>
          </div>
        );
      case 'delete':
        return this.renderDeleteWorkOrder();

      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              dropdownMode: 'list',
            }));
          }}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.isOpen}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          {this.renderInlineModalContent()}
        </InlineModal>
        <SendEmailModal
          open={this.state.showSendEmailModal}
          workOrder={this.props.workOrder}
          vendorId={this.state.showSendEmailModalForVendorId}
          onClose={() => {
            this.setState({ showSendEmailModal: false, showSendEmailModalForVendorId: null });
          }}
        />
        <PrintModal
          open={this.state.showPrintWorkOrderModal}
          detailOnly
          singleWorkOrderId={this.props.match.params.id}
          exportType={ExportType.WorkOrdersDetailsPdf}
          onClose={() => this.setState({ showPrintWorkOrderModal: false })}
        />
        <SelectVendorModal
          open={this.state.showSelectVendorModal}
          vendorIds={this.props.workOrder.assigned_to_vendors}
          onSelectVendor={({ id }) => {
            this.setState({ showSelectVendorModal: false, showSendEmailModalForVendorId: id });
            setTimeout(() => {
              this.setState({ showSendEmailModal: true });
            }, 250);
          }}
          onClose={() => this.setState({ showSelectVendorModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteWorkOrder: SDKReduxOperations.deleteWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  let meter = EntitySelectors.getMeter(state, workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    workOrder,
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
    canEditWorkOrder: AuthSelectors.canEditWorkOrder(state, workOrder),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasProTier: AuthSelectors.hasProTier(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    navigatedTo: WorkOrdersSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
