import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InlineModal, WarningMessage, Button, Field, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class Delete extends Component {
  state = {
    showRemoveConnectionConfirm: false,
    showConfirm: false,
    confirmInput: '',
  };

  renderManyAssetsAndManyWorkOrders = () => {
    const { asset_count, work_order_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-other-and-work-orders-count-other-files-context"
          values={{ asset_count, work_order_count }}
        />
      );
    } else {
      return (
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-other-and-work-orders-count-other"
            values={{ asset_count: asset_count - 1, work_order_count }}
          />
        </WarningMessage>
      );
    }
  };

  renderSingleAssetAndManyWorkOrders = () => {
    const { asset_count, work_order_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-one-and-work-orders-count-other-files-context"
          values={{ asset_count, work_order_count }}
        />
      );
    } else {
      return (
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.work-orders-files-context-count-other"
            values={{ work_order_count }}
          />
        </WarningMessage>
      );
    }
  };

  renderManyAssetsAndSingleWorkOrder = () => {
    const { asset_count, work_order_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-other-and-work-orders-count-one-files-context"
          values={{ asset_count, work_order_count }}
        />
      );
    } else {
      return (
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-other-and-work-orders-count-one"
            values={{ asset_count: asset_count - 1, work_order_count }}
          />
        </WarningMessage>
      );
    }
  };

  renderSingleAsset = () => {
    const { asset_count } = this.props.attachment;
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-files-context-count-one"
        values={{ asset_count }}
      />
    );
  };

  renderManyAssets = () => {
    const { asset_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-files-context-count-other"
          values={{ asset_count }}
        />
      );
    } else {
      return (
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.assets-count-other"
            values={{ asset_count: asset_count - 1 }}
          />
        </WarningMessage>
      );
    }
  };

  renderSingleSparePart = () => {
    const { spare_part_count } = this.props.attachment;
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.spare-parts-files-context-count-one"
        values={{ spare_part_count }}
      />
    );
  };

  renderManySpareParts = () => {
    const { spare_part_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.spare-parts-files-context-count-other"
          values={{ spare_part_count }}
        />
      );
    } else {
      return (
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.spare-parts-count-other"
            values={{ spare_part_count: spare_part_count - 1 }}
          />
        </WarningMessage>
      );
    }
  };

  renderSingleWorkOrder = () => {
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.work-orders-files-context-count-one"
        values={{ work_order_count: 1 }}
      />
    );
  };

  renderManyWorkOrders = () => {
    const { work_order_count } = this.props.attachment;
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.work-orders-files-context-count-other"
        values={{ work_order_count }}
      />
    );
  };

  renderSingleVendor = () => {
    return (
      <FormattedMessage id="components.attachment-options-inline-modal.options.delete.confirm-description-texts.vendor-count-one" />
    );
  };

  renderDescriptionFromFilesContext = () => {
    const {
      asset_count,
      work_order_count,
      vendor_id,
      spare_part_id,
      spare_part_count,
      registration_work_order_id,
      completion_work_order_id,
    } = this.props.attachment;
    if (asset_count > 1 && work_order_count > 1) return this.renderManyAssetsAndManyWorkOrders();
    if (asset_count === 1 && work_order_count >= 1) return this.renderSingleAssetAndManyWorkOrders();
    if (asset_count > 1 && work_order_count === 1) return this.renderManyAssetsAndSingleWorkOrder();
    if (asset_count === 1) return this.renderSingleAsset();
    if (asset_count > 1) return this.renderManyAssets();
    if (registration_work_order_id || completion_work_order_id) return this.renderSingleWorkOrder();
    if (work_order_count > 1) return this.renderManyWorkOrders();
    if (vendor_id) return this.renderSingleVendor();
    if (spare_part_id) return this.renderSingleSparePart();
    if (spare_part_count > 1) return this.renderManySpareParts();
  };

  renderDescriptionFromItsOwnContext = () => {
    const {
      asset_count,
      spare_part_id,
      spare_part_count,
      work_order_count,
      registration_work_order_id,
      completion_work_order_id,
    } = this.props.attachment;
    if (asset_count > 1 && work_order_count > 1) return this.renderManyAssetsAndManyWorkOrders();
    if (asset_count === 1 && work_order_count >= 1) return this.renderSingleAssetAndManyWorkOrders();
    if (asset_count > 1 && work_order_count === 1) return this.renderManyAssetsAndSingleWorkOrder();
    if (asset_count === 1)
      return <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />;
    if (asset_count > 1) return this.renderManyAssets();
    if (registration_work_order_id || completion_work_order_id)
      return <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />;
    if (work_order_count > 1)
      return <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />;
    if (spare_part_id)
      return <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />;
    if (spare_part_count > 1) return this.renderManySpareParts();
    return <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />;
  };

  renderDeleteInformation = () => {
    const { asset_count, work_order_count, spare_part_count } = this.props.attachment;
    if (this.state.showRemoveConnectionConfirm) {
      if (this.props.context === 'asset') {
        return (
          <InlineModal.Delete
            loading={this.props.isRemovingAttachment}
            onDelete={this.props.onRemovedFromAsset}
            label="general.remove"
          >
            <FormattedMessage
              id="screens.asset.attachments.remove-connection-from-asset-description"
              values={{ asset: this.props.assetToRemoveFromAttachment.title }}
            />
          </InlineModal.Delete>
        );
      } else if (this.props.context === 'sparePart') {
        return (
          <InlineModal.Delete
            loading={this.props.isRemovingAttachment}
            onDelete={this.props.onRemovedFromSparePart}
            label="general.remove"
          >
            <FormattedMessage
              id="screens.spare-part.attachments.remove-connection-from-spare-part-description"
              values={{ sparePart: this.props.sparePartToRemoveFromAttachment.title }}
            />
          </InlineModal.Delete>
        );
      }
    }
    if (this.props.context === 'files') {
      return (
        <div className={styles['delete-wrapper']}>
          <WarningMessage hideIcon type="destructive">
            {this.props.attachmentVersion.version_number === 1 ? null : (
              <p className={styles['versions']}>
                <FormattedMessage id="components.attachment-options-inline-modal.options.delete.version-description" />
              </p>
            )}
            {this.renderDescriptionFromFilesContext()}
          </WarningMessage>
          <Field.Text
            placeholder={this.props.intl.formatMessage({ id: 'general.delete' })}
            value={this.state.confirmInput}
            onChange={value => {
              this.setState({ confirmInput: value });
            }}
          />
          <div className={styles['confirm-button-container']}>
            <Button
              primary
              destructive
              disabled={
                this.state.confirmInput.toLowerCase() !==
                this.props.intl.formatMessage({ id: 'general.delete' }).toLowerCase()
              }
              label="general.delete"
              onClick={this.props.onDeleteAttachment}
              loading={this.props.isDeletingAttachment}
            />
          </div>
        </div>
      );
    } else if (this.props.context === 'asset') {
      if (work_order_count >= 1 || asset_count > 1) {
        return (
          <React.Fragment>
            <InlineModal.ListItem
              icon={<Icon light size={15} type="times" />}
              title={
                <FormattedMessage
                  id="screens.asset.attachments.remove-connection-from-asset"
                  values={{ asset: this.props.assetToRemoveFromAttachment.title }}
                />
              }
              onClick={() => this.setState({ showRemoveConnectionConfirm: true })}
            />
            <InlineModal.ListItem
              icon={<Icon regular type="trash-alt" />}
              destructive
              title={<FormattedMessage id="screens.asset.attachments.remove-file-and-its-connections" />}
              onClick={() => {
                this.setState({ showConfirm: true });
              }}
            />
          </React.Fragment>
        );
      }
    } else if (this.props.context === 'sparePart') {
      if (spare_part_count > 1) {
        return (
          <React.Fragment>
            <InlineModal.ListItem
              icon={<Icon light size={15} type="times" />}
              title={
                <FormattedMessage id="screens.spare-part.attachments.remove-connection-from-spare-part" />
              }
              onClick={() => this.setState({ showRemoveConnectionConfirm: true })}
            />
            <InlineModal.ListItem
              icon={<Icon regular type="trash-alt" />}
              destructive
              title={<FormattedMessage id="screens.asset.attachments.remove-file-and-its-connections" />}
              onClick={() => {
                this.setState({ showConfirm: true });
              }}
            />
          </React.Fragment>
        );
      }
    }
    return (
      <InlineModal.Delete loading={this.props.isDeletingAttachment} onDelete={this.props.onDeleteAttachment}>
        <FormattedMessage id="components.attachment-options-inline-modal.options.delete.description" />
      </InlineModal.Delete>
    );
  };

  render() {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="components.attachment-options-inline-modal.options.delete.title" />}
          onClose={this.props.onClose}
        />
        <InlineModal.Body width={300}>
          {this.state.showConfirm ? (
            <div className={styles['delete-wrapper']}>
              {this.renderDescriptionFromItsOwnContext()}
              <Field.Text
                placeholder={this.props.intl.formatMessage({ id: 'general.delete' })}
                value={this.state.confirmInput}
                onChange={value => {
                  this.setState({ confirmInput: value });
                }}
              />
              <div className={styles['confirm-button-container']}>
                <Button
                  primary
                  destructive
                  disabled={
                    this.state.confirmInput.toLowerCase() !==
                    this.props.intl.formatMessage({ id: 'general.delete' }).toLowerCase()
                  }
                  label="general.delete"
                  onClick={this.props.onDeleteAttachment}
                  loading={this.props.isDeletingAttachment}
                />
              </div>
            </div>
          ) : (
            this.renderDeleteInformation()
          )}
        </InlineModal.Body>
      </React.Fragment>
    );
  }
}

export default injectIntl(Delete);
