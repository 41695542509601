import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { ContentLoader } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

const AssetTitle = props => {
  const classNames = () => {
    let classNames = [];
    if (props.noSubtitleStyle !== true) {
      classNames = [...classNames, styles['subtitle']];
    }
    if (props.numberClassName) {
      classNames = [...classNames, props.numberClassName];
    }
    return classNames;
  };
  if (props.asset == null) {
    return <ContentLoader width={80} height={20} dark />;
  }
  if (props.settings.asset_number_activated) {
    return (
      <span title={`${props.asset.title} - #${props.asset.number}`}>
        <span>{props.asset.title}</span>
        <span className={classNames().join(' ')}>
          <span> - </span>
          <span>#{props.asset.number}</span>
        </span>
      </span>
    );
  }
  return <span title={props.asset.title}>{props.asset.title}</span>;
};

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    asset: EntitySelectors.getAsset(state, id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(AssetTitle);
