import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Type } from 'sdk/ChecklistTemplateRow';
import Section from './Section';
import Inspection from './Inspection';
import Meter from './Meter';

class ChecklistTemplateRowContainer extends Component {
  render() {
    switch (this.props.checklistTemplateRow.type) {
      case Type.Inspection:
        return <Inspection id={this.props.id} />;
      case Type.Meter:
        return <Meter id={this.props.id} />;
      case Type.Section:
        return (
          <Section
            id={this.props.id}
            prevTemplateRowId={this.props.prevTemplateRowId}
            nextTemplateRowId={this.props.nextTemplateRowId}
          />
        );
      default:
        return null;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateRowContainer);
