import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetTitle } from 'views/components/Asset';
import { Banner } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SettingsForAsset extends Component {
  render() {
    return (
      <>
        <Banner orange>
          <FormattedMessage id="screens.operational-maintenance-template.checklist-settings-modal.settings-for-asset" />
          <span> </span>
          <span>
            <AssetTitle id={this.props.asset.id} />
          </span>
        </Banner>
        <div className={styles['separator']} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { templateAssetId } = ownProps;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    templateAssetId
  );

  return {
    asset: EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id),
  };
}

export default connect(mapStateToProps)(SettingsForAsset);
