import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Decimal } from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List, FormattedNumber } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SystemListItem extends Component {

  getDefaultPrice = () => {
    const defaultDiscount = this.props.discounts.find(discount => discount.expiration_date == null);

    if (defaultDiscount) {
      return defaultDiscount.price_full;
    } else {
      return this.props.hasProTier ? 699 : 299;
    }
  }

  renderFormattedPrice = price => {
    return new Decimal(price)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  renderPrice = () => {
    const pricePerUser = this.getDefaultPrice();

    let priceClassNames = [styles['price']];
    const discountsWithExpirationDate = this.props.discounts.filter(discount => discount.expiration_date != null);

    if (discountsWithExpirationDate.length > 0) {
      priceClassNames = [...priceClassNames, styles['has-discount']]
    }

    return (
      <div>
        <div>
          <span className={priceClassNames.join(' ')}>
            <FormattedNumber value={this.props.system.billable_user_count * pricePerUser} />
          </span>
          <span> </span>
          <span className={styles['per-month']}>
            <FormattedMessage id="screens.organisation.billing-information-modal.cost-per-month" />
          </span>
        </div>
        {discountsWithExpirationDate.map(({ expiration_date, price_full }) => (
          <div className={styles['discount-row']}>
            <FormattedMessage
              id="screens.users.discount-text"
              values={{
                date: moment(expiration_date).format('LL'),
                amount: (
                  <span className={styles['discount-row-price-text']}>
                    {this.renderFormattedPrice(this.props.system.billable_user_count * price_full)}
                  </span>
                ),
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <List.Item small>
        <List.Item.TitleColumn
          title={this.props.system.name}
          subtitle={
            <FormattedMessage
              id="screens.organisation.billing-information-modal.amount-of-users"
              values={{ count: this.props.system.billable_user_count }}
            />
          }
        />
        <List.Item.Column alignRight>
          {this.renderPrice()}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    system: EntitySelectors.getSystem(state, ownProps.id),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(SystemListItem);
