import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listQRTemplateRows = (qrTemplateColId, params) =>
  request.get(`/qr_templates_columns/${qrTemplateColId}/qr_template_rows`);

export const fetchQrTemplateColumn = qrTemplateRow => request.get(`/qr_template_rows/${qrTemplateRow}`);

export const createQrTemplateRow = (qrTemplateId, data) =>
  request.post(`/qr_template/${qrTemplateId}/qr_template_rows`, data);

export const updateQrTemplateRow = (qrTemplateRowId, data, config = null) =>
  request.patch(`/qr_template_rows/${qrTemplateRowId}`, data, config);

export const deleteQrTemplateRow = qrTemplateRowId => request.delete(`/qr_template_rows/${qrTemplateRowId}`);
