import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { CardGrid } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import { WhiteCard, Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import ChangeCurrencyModal from '../../../Header/Menu/ChangeCurrencyModal';
import styles from './styles.module.scss';

const ChangeCurrencyWarning = ({ purchaseOrder, vendor, currentSystem }) => {
  const [showChangeCurrencyModal, setShowChangeCurrencyModal] = useState(false);

  const renderCurrency = () => {
    const currency = vendor.purchase_order_currency;

    return <FormattedMessage id={`currencies.${currency}`} />;
  };

  if (
    vendor == null ||
    vendor.purchase_order_currency === purchaseOrder.currency ||
    purchaseOrder.status === PurchaseOrderStatus.Ordered
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <CardGrid.Row>
        <WhiteCard noPadding>
          <div className={styles['currency-container']}>
            <div className={styles['currency-icon']}>
              <Icon regular type="dollar-sign" />
            </div>
            <div className={styles['currency-content']}>
              <p className={styles['title']}>
                <FormattedMessage
                  id="screens.purchase-order.vendor-has-different-currency-title"
                  values={{
                    vendor: vendor.name,
                    currency: renderCurrency(),
                  }}
                />
              </p>
            </div>
            <Button.Group>
              <Button
                primary
                small
                label="screens.purchase-order.vendor-has-different-currency-button"
                onClick={() => setShowChangeCurrencyModal(true)}
              />
            </Button.Group>
          </div>
        </WhiteCard>
      </CardGrid.Row>
      <ChangeCurrencyModal
        forceCurrency
        currency={vendor.purchase_order_currency}
        open={showChangeCurrencyModal}
        purchaseOrder={purchaseOrder}
        onClose={() => {
          setShowChangeCurrencyModal(false);
        }}
      />
    </React.Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderId);
  return {
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps)(ChangeCurrencyWarning));
