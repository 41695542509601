import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartsOperations, SparePartsSelectors } from 'state/ducks/spareParts';
import Text from './Text';
import Date from './Date';
import Dropdown from './Dropdown';
import { FILTER_TYPES } from 'views/scenes/SpareParts/SparePartList/FilterModal';

class SparePartField extends Component {
  render() {
    const { type } = this.props.templateField;
    if (type === 'text' || type === 'textarea') {
      return <Text templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />;
    } else if (type === 'valuelist') {
      return (
        <Dropdown templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />
      );
    } else if (type === 'date') {
      return <Date templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />;
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: SparePartsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    filter: SparePartsSelectors.getFiltersForKey(state, FILTER_TYPES.SparePartField),
    templateField: EntitySelectors.getTemplateField(state, ownProps.templateFieldId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartField);
