import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, HelperFunctions } from 'sdk';
import { normalizeSparePart } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { ChooseSparePartLocationInlineModal } from 'views/components/SparePartLocation';
import {
  Button,
  List,
  WhiteCard,
  EmptyDataSet,
  Pagination,
  FilterButton,
  Menu,
  SectionHeader,
} from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import styles from './style.module.scss';
import SparePartListItem from './SparePartListItem';

const sparePartsRequest = HelperFunctions.getCancelTokenForRequest();

const PAGE_SIZE = 8;

export const TABS = {
  VendorShouldOrder: 'VendorShouldOrder',
  VendorAll: 'VendorAll',
  All: 'All',
};

class SelectSparePartsModal extends Component {
  getInitialState = () => ({
    isEmpty: false,
    selectedTab: TABS.VendorShouldOrder,
    isFetching: true,
    isSearching: false,
    searchValue: '',
    isSaving: false,
    totalPages: 1,
    currentPage: 1,
    sparePartIds: [],
    addedSparePartIds: {},
    filters: {
      spare_part_location_with_children_id: null,
    },
  });

  /*
    addedSparePartIds: { [sparePartId]: true,  }
  */

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let addedSparePartIds = {};
      this.props.purchaseOrderRows.forEach(por => {
        const { spare_part_id } = por;
        if (spare_part_id) {
          addedSparePartIds = {
            ...addedSparePartIds,
            [spare_part_id]: true,
          };
        }
      });
      if (this.props.preserveState) {
        this.setState({ addedSparePartIds, isFetching: true });
        this.listSpareParts(this.getDefaultParamsForTab(this.state.selectedTab))
          .then(({ sparePartIds }) => {
            this.setState({ sparePartIds, isFetching: false, isEmpty: sparePartIds.length === 0 });
          })
          .catch(() => {});
      } else {
        this.setState({ ...this.getInitialState(), addedSparePartIds }, () => {
          this.listSpareParts(this.getDefaultParamsForTab(this.state.selectedTab))
            .then(({ sparePartIds }) => {
              this.setState({ sparePartIds, isFetching: false, isEmpty: sparePartIds.length === 0 });
            })
            .catch(() => {});
        });
      }
    }
  }

  /*
    Requests
  */

  listSpareParts = params => {
    sparePartsRequest.cancel();
    let attrs = {
      page_size: PAGE_SIZE,
      ...params,
    };

    return API.listSpareParts(
      this.props.currentSystem.id,
      attrs,
      sparePartsRequest.getCancelTokenConfig()
    ).then(({ data: spareParts, headers }) => {
      const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
      const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
      this.props.updateEntities(entities);
      return { sparePartIds, totalPages };
    });
  };

  /*
    Helper functions
  */

  getDefaultParamsForTab = tab => {
    const { spare_part_location_with_children_id } = this.state.filters;
    if (tab === TABS.VendorShouldOrder) {
      let params = {
        spare_part_vendors: { vendor_id: this.props.vendor.id },
        spare_part_vendors_for_vendor: this.props.vendor.id,
        stock_quantity: {
          low: true,
        },
        no_pagination: true,
        spare_part_location_with_children_id,
      };
      if (this.state.searchValue?.length > 0) {
        params = {
          ...params,
          search_for_vendor: `${this.props.vendor.id}:${this.state.searchValue}`,
        };
      }
      return params;
    } else if (tab === TABS.VendorAll) {
      let params = {
        spare_part_vendors: { vendor_id: this.props.vendor.id },
        spare_part_vendors_for_vendor: this.props.vendor.id,
        no_pagination: true,
        spare_part_location_with_children_id,
      };
      if (this.state.searchValue?.length > 0) {
        params = {
          ...params,
          search_for_vendor: `${this.props.vendor.id}:${this.state.searchValue}`,
        };
      }
      return params;
    }
    let params = {
      page_size: PAGE_SIZE,
      page: this.state.currentPage,
      spare_part_location_with_children_id,
    };
    if (this.state.searchValue?.length > 0) {
      params = {
        ...params,
        search: this.state.searchValue,
        sort: 'search_relevance',
      };
    }
    return params;
  };

  selectTab = tab => {
    if (this.state.selectedTab === tab) {
      return;
    }
    this.setState({ sparePartIds: [], selectedTab: tab, isFetching: true });
    if (tab === TABS.VendorShouldOrder) {
      this.setState({ totalPages: 1 });
      this.listSpareParts(this.getDefaultParamsForTab(tab))
        .then(({ sparePartIds }) => {
          this.setState({ sparePartIds, isFetching: false, isEmpty: sparePartIds.length === 0 });
        })
        .catch(() => {});
    } else if (tab === TABS.VendorAll) {
      this.setState({ totalPages: 1 });
      this.listSpareParts(this.getDefaultParamsForTab(tab))
        .then(({ sparePartIds }) => {
          this.setState({ sparePartIds, isFetching: false, isEmpty: sparePartIds.length === 0 });
        })
        .catch(() => {});
    } else {
      this.listSpareParts(this.getDefaultParamsForTab(tab))
        .then(({ sparePartIds, totalPages }) => {
          this.setState({ sparePartIds, totalPages, isFetching: false, isEmpty: sparePartIds.length === 0 });
        })
        .catch(() => {});
    }
  };

  /*
    Render functions
  */

  renderSparePartsEmptyDataset = () => {
    const { isFetching, isSearching, isEmpty, searchValue, filters } = this.state;
    const { spare_part_location_with_children_id } = filters;
    if (
      isFetching === false &&
      isSearching === false &&
      isEmpty === true &&
      searchValue.length === 0 &&
      spare_part_location_with_children_id == null
    ) {
      if (this.state.selectedTab === TABS.VendorShouldOrder) {
        return (
          <WhiteCard centerContent>
            <EmptyDataSet
              title={
                <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order-vendor-spare-parts-empty-data-set.title" />
              }
              subtitle={
                <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order-vendor-spare-parts-empty-data-set.subtitle" />
              }
              tiny
              listContainer
            />
          </WhiteCard>
        );
      } else if (this.state.selectedTab === TABS.VendorAll) {
        return (
          <WhiteCard centerContent>
            <EmptyDataSet
              title={
                <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-all-spare-parts-empty-data-set.title" />
              }
              subtitle={
                <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-all-spare-parts-empty-data-set.subtitle" />
              }
              tiny
              listContainer
            />
          </WhiteCard>
        );
      }
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.spare-parts-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.spare-parts-empty-data-set.subtitle" />
            }
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }
    return null;
  };

  renderSearchedEmptyDataset = () => {
    const { isFetching, isSearching, sparePartIds, searchValue, filters } = this.state;
    const { spare_part_location_with_children_id } = filters;
    if (
      isFetching === false &&
      isSearching === false &&
      sparePartIds.length === 0 &&
      (searchValue.length > 0 || spare_part_location_with_children_id != null)
    ) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchImage}
            tiny
            horizontal
            listContainer
          />
        </WhiteCard>
      );
    }
    return null;
  };

  renderSparePartListItemLoader = () => (
    <List.Item expandable loading>
      <List.Item.ImageColumn loading />
      <List.Item.TitleColumn loading />
    </List.Item>
  );

  renderAddAllButton = () => {
    const { selectedTab, isEmpty } = this.state;
    if (isEmpty === false && selectedTab === TABS.VendorShouldOrder) {
      return (
        <div className={styles['select-all-button-container']}>
          <Button
            primary
            disabled={this.state.isSearching || this.state.isFetching}
            small
            label="screens.purchase-order.info.articles.select-spare-part-modal.add-all-button"
            onClick={() =>
              this.props.onAddAllShouldOrder({
                spare_part_location_with_children_id: this.state.filters.spare_part_location_with_children_id,
                search: this.state.searchValue,
              })
            }
          />
        </div>
      );
    }
    return null;
  };

  renderLoader = () => {
    const { isFetching, isSearching } = this.state;
    if (isFetching || isSearching) {
      return (
        <List>
          {Array(this.state.sparePartIds.length === 0 ? 2 : this.state.sparePartIds.length)
            .fill()
            .map(() => this.renderSparePartListItemLoader())}
        </List>
      );
    }
    return null;
  };

  renderList = () => {
    const { isFetching, isSearching, sparePartIds } = this.state;
    if (isFetching === false && isSearching === false && sparePartIds.length > 0) {
      return (
        <List>
          {sparePartIds.map(id => (
            <SparePartListItem
              key={id}
              tab={this.state.selectedTab}
              selected={this.state.addedSparePartIds[id] === true}
              vendor={this.props.vendor}
              id={id}
              onAdd={purchaseOrderRow => this.props.onAdd(purchaseOrderRow)}
            />
          ))}
        </List>
      );
    }
    return null;
  };

  renderSpareParts = () => {
    return (
      <>
        {this.renderSparePartsEmptyDataset()}
        {this.renderAddAllButton()}
        {this.renderLoader()}
        {this.renderSearchedEmptyDataset()}
        {this.renderList()}
      </>
    );
  };

  renderMenuContent = () => {
    return (
      <div>
        <SectionHeader noBorderTop>{this.props.vendor.name}</SectionHeader>
        <div className={styles['left-panel']}>
          <Menu.Item
            selected={this.state.selectedTab === TABS.VendorShouldOrder}
            title={
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order-spare-parts" />
            }
            onClick={() => {
              this.selectTab(TABS.VendorShouldOrder);
            }}
          />
          <Menu.Item
            selected={this.state.selectedTab === TABS.VendorAll}
            title={
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.all-spare-parts" />
            }
            onClick={() => {
              this.selectTab(TABS.VendorAll);
            }}
          />
        </div>
        <SectionHeader>
          <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.all-spare-parts-header" />
        </SectionHeader>
        <div className={styles['left-panel']}>
          <Menu.Item
            selected={this.state.selectedTab === TABS.All}
            title={
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.all-spare-parts" />
            }
            onClick={() => {
              this.selectTab(TABS.All);
            }}
          />
        </div>
      </div>
    );
  };

  renderPagination = () => {
    if (this.state.selectedTab !== TABS.All) {
      return null;
    }
    if (this.state.totalPages <= 1) {
      return null;
    }
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isFetching}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.setState({ isSearching: true, currentPage: page }, () => {
              this.listSpareParts({
                ...this.getDefaultParamsForTab(this.state.selectedTab),
                page: this.state.currentPage,
              })
                .then(({ sparePartIds }) => {
                  this.setState({ sparePartIds, isSearching: false });
                })
                .catch(() => {});
            });
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };

  addFilters = params => {
    this.setState(
      {
        isSearching: true,
        currentPage: 1,
        filters: {
          ...this.state.filters,
          ...params,
        },
      },
      () =>
        this.listSpareParts(this.getDefaultParamsForTab(this.state.selectedTab))
          .then(({ sparePartIds, totalPages }) => {
            this.setState({ isSearching: false, sparePartIds, totalPages });
          })
          .catch(() => {})
    );
  };

  renderChooseSparePartLocationInlineModal = () => {
    return (
      <ChooseSparePartLocationInlineModal
        trigger={
          <FilterButton
            label={<FormattedMessage id="resources.spare-part.location" />}
            filtered={this.state.filters.spare_part_location_with_children_id ? true : false}
            onClear={e => {
              e.stopPropagation();
              this.addFilters({
                spare_part_location_with_children_id: null,
              });
            }}
          />
        }
        selectedSparePartLocationId={this.state.filters.spare_part_location_with_children_id}
        onSelectSparePartLocation={id => this.addFilters({ spare_part_location_with_children_id: id })}
        onClear={() => this.setState({ spare_part_location_with_children_id: null })}
      />
    );
  };

  renderToolbar = () => (
    <div className={styles['toolbar']}>
      <div className={styles['filter-container']}>
        <FilterButton.Group>{this.renderChooseSparePartLocationInlineModal()}</FilterButton.Group>
      </div>
    </div>
  );

  renderContent = () => {
    return (
      <>
        {this.renderToolbar()}
        <Modal.ColumnLayout.Container.Content>{this.renderSpareParts()}</Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.title" />}
          subtitle={
            <>
              <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.subtitle" />
              <span> #{this.props.purchaseOrder.number}</span>
            </>
          }
          onClose={this.props.onClose}
          searchable
          debounce
          value={this.state.searchValue}
          onSearch={value => {
            this.setState({
              searchValue: value,
              isSearching: true,
              currentPage: 1,
            });
          }}
          onDebouncedSearch={value => {
            this.listSpareParts({ ...this.getDefaultParamsForTab(this.state.selectedTab) })
              .then(({ sparePartIds, totalPages }) => {
                this.setState({ isSearching: false, sparePartIds, totalPages });
              })
              .catch(() => {});
          }}
          onClear={() => {
            this.setState({ isSearching: true, searchValue: '', currentPage: 1 }, () => {
              this.listSpareParts({ ...this.getDefaultParamsForTab(this.state.selectedTab) })
                .then(({ sparePartIds, totalPages }) => {
                  this.setState({ isSearching: false, sparePartIds, totalPages });
                })
                .catch(() => {});
            });
          }}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>{this.renderContent()}</Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectSparePartsModal));
