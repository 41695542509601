import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { SectionHeader } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import SparePartReservationListItem from './SparePartReservationListItem';

class ReservedSpareParts extends Component {
  render() {
    if (this.props.sparePartReservations.length === 0) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <SectionHeader paddingHorizontal={30} horizontalBorders>
            <FormattedMessage id="screens.operational-maintenance.reserved-spare-parts.title" />
          </SectionHeader>
          {this.props.sparePartReservations.map(({ id }) => (
            <SparePartReservationListItem id={id} />
          ))}
        </CardGrid.Row>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateVersion } = ownProps;
  return {
    sparePartReservations:
      EntitySelectors.getSparePartReservations(
        state,
        operationalMaintenanceTemplateVersion.spare_part_reservations
      ) || [],
  };
}

export default connect(mapStateToProps)(ReservedSpareParts);
