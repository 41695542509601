import types from './types';
import update from 'immutability-helper';

/*
  Structure of purchaseOrderRowsBySparePartId
  {
    [sparePartId]: {
      spare_part_id: <string>,
      spare_part_vendor_id: <string>,
      quantity: <string>
    }
 }
*/

const INITIAL_STATE = {
  selectedVendorId: null,
  purchaseOrderRowsBySparePartId: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.CANCEL_PURCHASE_ORDER: {
      return {
        ...state,
        selectedVendorId: null,
        purchaseOrderRowsBySparePartId: {},
      };
    }
    case types.SET_VENDOR: {
      return {
        ...state,
        selectedVendorId: action.payload,
      };
    }
    case types.ADD_SPARE_PART: {
      return update(state, {
        purchaseOrderRowsBySparePartId: {
          [action.payload.spare_part_id]: {
            $set: {
              ...action.payload,
            },
          },
        },
      });
    }
    case types.ADD_MULTIPLE_SPARE_PARTS: {
      return update(state, {
        purchaseOrderRowsBySparePartId: {
          $merge: action.payload,
        },
      });
    }
    case types.REMOVE_MULTIPLE_SPARE_PARTS: {
      return update(state, {
        purchaseOrderRowsBySparePartId: {
          $set: {},
        },
      });
    }
    case types.REMOVE_SPARE_PART: {
      return update(state, {
        purchaseOrderRowsBySparePartId: {
          $unset: [action.payload],
        },
      });
    }
    case types.UPDATE_SPARE_PART: {
      const { sparePartId, data } = action.payload;
      return update(state, {
        purchaseOrderRowsBySparePartId: {
          [sparePartId]: {
            $merge: data,
          },
        },
      });
    }

    default:
      return state;
  }
};
