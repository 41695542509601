import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const VendorAvatar = ({ vendor, size, fontSize }) => {
  const getInitials = () => {
    return vendor.name
      .trim()
      .split(' ')
      .slice(0, 1)
      .map(v => v.charAt(0))
      .join('');
  };
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: size / 2,
  };

  const textStyle = {
    fontSize,
  };

  return (
    <div className={styles['initials-container']} style={style}>
      <div className={styles['initials']} style={textStyle}>
        {getInitials()}
      </div>
    </div>
  );
};

export default VendorAvatar;

VendorAvatar.propTypes = {
  vendor: PropTypes.object,
  size: PropTypes.number,
  fontSize: PropTypes.string,
};

VendorAvatar.defaultProps = {
  size: 22,
  fontSize: 11,
};
