import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { normalizeWorkOrder } from 'sdk/Schemas';
import { FormattedMessage } from 'react-intl';
import { Button, Field } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { Grid } from 'views/components/Shared/Layout';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';

class PauseModal extends Component {
  state = {
    isSaving: false,
    paused_comment: '',
  };

  shouldComponentUpdate(prevProps) {
    if (!this.props.open && !prevProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { workOrder } = this.props;
      this.setState({
        isSaving: false,
        paused_comment: workOrder.paused_comment ? workOrder.paused_comment : '',
      });
    }
  }

  save = () => {
    if (this.state.isSaving) return;
    this.setState({
      isSaving: true,
    });
    API.updateWorkOrder(this.props.workOrder.id, {
      paused_comment: this.state.paused_comment,
      status: WorkOrderStatus.Paused,
    })
      .then(res => {
        const { entities } = normalizeWorkOrder(res.data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  getPrimaryButtonLabel = () => {
    if (this.props.workOrder.status === WorkOrderStatus.Paused) {
      return 'general.save';
    }
    return 'screens.work-order.pause-modal.button';
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={600}>
          <Modal.Header
            title={<FormattedMessage id="screens.work-order.pause-modal.title" />}
            subtitle={`#${this.props.workOrder.number} - ${this.props.workOrder.title}`}
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Field label={<FormattedMessage id="resources.work-order.comment" />}>
              <Field.Textarea
                autoFocus
                minRows={3}
                type="text"
                value={this.state.paused_comment}
                onChange={value => this.setState({ paused_comment: value })}
              />
            </Field>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                onClick={this.save}
                label={this.getPrimaryButtonLabel()}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(PauseModal);
