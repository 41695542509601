import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { normalizeMeterReading, normalizeMeter } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { SDKReduxOperations, HelperFunctions, API } from 'sdk';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import {
  Field,
  Button,
  DatePicker,
  InlineModal,
  FieldErrorWrapper,
  Icon,
} from 'views/components/Shared/General';
import styles from './style.module.scss';

class ChangeMeterValueModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    value: null,
    showEmptyValueError: false,
    date: moment().format('YYYY-MM-DD'),
    showDatePicker: false,
    showErrorForValueSize: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { meterReading } = this.props;
      this.setState({
        ...this.getInitialState(),
        value: meterReading == null ? null : meterReading.value,
        date: meterReading == null ? moment().format('YYYY-MM-DD') : meterReading.date,
      });
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.save();
    }
  };

  createMeterReadingForMeter = () => {
    const params = {
      date: this.state.date,
      value: this.state.value,
    };
    this.setState({ isSaving: true });
    this.props
      .createMeterReading(this.props.createForMeterId, params)
      .then(({ data: meterReading }) => {
        const { entities: meterReadingEntities } = normalizeMeterReading(meterReading);
        API.fetchMeter(this.props.createForMeterId).then(({ data: meter }) => {
          const { entities: meterEntities } = normalizeMeter(meter);
          this.props.updateEntities({ ...meterReadingEntities, ...meterEntities });
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.meter.modals.change-value.success-toast" />}
            />
          );
          this.props.onClose();
        });
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10011', key: 'value' })) {
          this.setState({ showErrorForValueSize: true });
        }
        this.setState({ isSaving: false });
      });
  };

  createMeterReadingForChecklistInstanceRow = () => {
    const params = {
      date: this.state.date,
      value: this.state.value,
    };
    this.setState({ isSaving: true });
    this.props
      .createMeterReadingForChecklistInstanceRow(this.props.createForChecklistInstanceRowId, params)
      .then(({ data: meterReading }) => {
        const { entities: meterReadingEntities } = normalizeMeterReading(meterReading);
        this.props.updateEntities(meterReadingEntities);
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10011', key: 'value' })) {
          this.setState({ showErrorForValueSize: true });
        }
        this.setState({ isSaving: false });
      });
  };

  updateMeterReading = () => {
    const params = {
      value: this.state.value,
      date: this.state.date,
    };
    this.setState({ isSaving: true });
    API.updateMeterReading(this.props.updateForMeterReadingId, params)
      .then(({ data: meterReading }) => {
        const { entities } = normalizeMeterReading(meterReading);
        this.props.updateEntities(entities);
        this.props.onUpdateMeterReading();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10011', key: 'value' })) {
          this.setState({ showErrorForValueSize: true });
        }
        this.setState({ isSaving: false });
      });
  };

  save = () => {
    if (this.state.value === null || this.state.value === '') {
      this.setState({ showEmptyValueError: true });
      return;
    }
    if (this.props.updateForMeterReadingId) {
      this.updateMeterReading();
    } else if (this.props.createForMeterId) {
      this.createMeterReadingForMeter();
    } else if (this.props.createForChecklistInstanceRowId) {
      this.createMeterReadingForChecklistInstanceRow();
    }
  };

  renderDateField = () => {
    return (
      <InlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.showDatePicker}
        onClose={() => this.setState({ showDatePicker: false })}
      >
        <InlineModal.Body width={380} paddingTop>
          <DatePicker.Day
            date={this.state.date}
            onSelectDate={value => {
              this.setState({
                date: moment(value).format('YYYY-MM-DD'),
                showDatePicker: false,
              });
            }}
            inline
            disabledDays={{ after: new Date() }}
          />
        </InlineModal.Body>
      </InlineModal>
    );
  };

  renderDate = () => {
    return (
      <div className={styles['date-field']} ref={ref => (this.inlineModalPositioningRef = ref)}>
        <FormattedMessage id="screens.meter.modals.change-value.reading-date" />
        {': '}
        <span className={styles['highlighted-text']}>{moment(this.state.date).format('LL')}</span>
        {' - '}
        <Button
          type="text"
          primary
          label="general.change"
          fontSize={12}
          onClick={() => this.setState({ showDatePicker: true })}
        />
      </div>
    );
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <FieldErrorWrapper
              show={this.state.showErrorForValueSize}
              errorElement={<FormattedMessage id="screens.meter.modals.change-value.size-error" />}
            >
              <Field.Decimal
                value={this.state.value}
                autoFocus
                onKeyPress={this.handleKeyPress}
                error={this.state.showEmptyValueError || this.state.showErrorForValueSize}
                rightLabel={this.props.meterUnit == null ? null : this.props.meterUnit.abbreviation}
                onChange={value =>
                  this.setState({ value, showEmptyValueError: false, showErrorForValueSize: false })
                }
                onBlur={value => this.setState({ value })}
              />
            </FieldErrorWrapper>
            {this.renderDate()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderIconButtons = () => {
    if (this.props.updateForMeterReadingId) {
      return (
        <Button type="icon" onClick={this.props.confirmDelete} icon={<Icon regular type="trash-alt" red />} />
      );
    }
    return null;
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.meter.modals.change-value.title" />}
          iconButtons={this.renderIconButtons()}
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.save();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderDateField()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createMeterReading: SDKReduxOperations.createMeterReading,
      createMeterReadingForChecklistInstanceRow: SDKReduxOperations.createMeterReadingForChecklistInstanceRow,
      updateMeterReading: SDKReduxOperations.updateMeterReading,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { meterId, updateForMeterReadingId } = ownProps;
  const meter = EntitySelectors.getMeter(state, meterId);
  return {
    meter,
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
    meterReading: EntitySelectors.getMeterReading(state, updateForMeterReadingId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMeterValueModal);

ChangeMeterValueModal.propTypes = {
  open: PropTypes.bool,
  meterId: PropTypes.string.isRequired,
  updateForMeterReadingId: PropTypes.string,
  createForMeterId: PropTypes.string,
  createForChecklistInstanceRowId: PropTypes.string,
  onUpdateMeterReading: PropTypes.func,
};

ChangeMeterValueModal.defaultProps = {
  open: false,
  meterId: null,
  updateForMeterReadingId: null,
  createForMeterId: null,
  createForChecklistInstanceRowId: null,
  onUpdateMeterReading: () => {},
};
