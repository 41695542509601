import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { List, InlineModal, Button, Icon, ViewTextArea } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { Link } from 'react-router-dom';
import { TreePath, AssetTitle } from 'views/components/Asset';
import styles from '../style.module.scss';

class SparePartAssetListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  delete = () => {
    this.props.onDelete(this.props.sparePartAsset.id);
    this.setState({ dropdownOpen: false });
  };

  renderTitle = () => <AssetTitle id={this.props.asset.id} />;

  renderComment = () => {
    if (this.props.sparePartAsset.comment) {
      return (
        <div className={styles.comment}>
          <ViewTextArea>{this.props.sparePartAsset.comment}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          clickable
          onClick={this.props.onClick}
          new={this.props.new}
          dataPillsComponent={this.renderComment()}
          iconButtons={
            <>
              <div
                ref={ref => (this.inlineModalPositioningRef = ref)}
                onClick={() => {
                  this.setState(prevState => ({
                    dropdownOpen: !prevState.dropdownOpen,
                    showDeleteContent: false,
                  }));
                }}
              >
                <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
              </div>
              <InlineModal
                open={this.state.dropdownOpen}
                positionToRef={this.inlineModalPositioningRef}
                onClose={() => this.setState({ dropdownOpen: false })}
                position="right"
              >
                <InlineModal.Body width={250} dropdown>
                  <Link to={`/assets/${this.props.asset.id}`}>
                    <InlineModal.ListItem
                      icon="arrow-alt-right"
                      iconThickness="regular"
                      title={<FormattedMessage id="screens.spare-part.assets.go-to-asset" />}
                    />
                  </Link>
                  {this.props.canEditAssets && this.props.canEditSpareParts ? (
                    <InlineModal.ListItem
                      icon="trash-alt"
                      iconThickness="regular"
                      destructive
                      title={<FormattedMessage id="general.delete" />}
                      onClick={() => this.delete()}
                    />
                  ) : null}
                </InlineModal.Body>
              </InlineModal>
            </>
          }
        >
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            title={this.renderTitle()}
            subtitle={<TreePath size={12} dark onlyShowPath assetId={this.props.asset.id} />}
          />
          {this.props.sparePartAsset.quantity ? (
            <List.Item.Column alignRight>
              <SparePartUnitWithValue
                sparePartUnit={this.props.sparePartUnit}
                value={this.props.sparePartAsset.quantity}
              />
            </List.Item.Column>
          ) : null}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (!ownProps.sparePartAssetId) return {};

  const sparePartAsset = EntitySelectors.getSparePartAsset(state, ownProps.sparePartAssetId);
  const asset = EntitySelectors.getAsset(state, sparePartAsset.asset_id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartAsset.spare_part_id);
  return {
    sparePartAsset,
    asset,
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    images: EntitySelectors.getImages(state, asset.images),
    settings: AuthSelectors.getSettings(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default connect(mapStateToProps)(SparePartAssetListItem);

SparePartAssetListItem.propTypes = {
  onClick: PropTypes.func,
  sparePartAsset: PropTypes.object,
  loading: PropTypes.bool,
  new: PropTypes.bool,
  buttons: PropTypes.node,
};
SparePartAssetListItem.defaultProps = {
  onClick: () => {},
  loading: false,
  new: false,
};
