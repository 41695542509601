import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { API, SDKReduxOperations, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { NewInlineModal, Button } from 'views/components/Shared/General';
import { DowntimeModal, SelectDowntimeModal, NewDowntimeModal } from 'views/components/Downtime';
import { normalizeDowntime } from 'sdk/Schemas';
import AddDowntimeListItem from './AddDowntimeListItem';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import styles from './styles.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class AddDowntimeInlineModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    downtimeIds: [],
    showSelectDowntimeModal: false,
    isAddingDowntimeIdToWorkOrder: null,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
      isOpen: false,
      showNewDowntimeModal: false,
      showDowntimeModal: false,
      isCreatingActiveDowntime: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      if (this.props.workOrder.asset_id) {
        this.setState({ ...this.getInitialState() }, () => {
          this.fetchDowntimes()
            .then(({ downtimeIds }) => {
              this.setState({ downtimeIds, isFetching: false });
            })
            .catch(e => {
              this.setState({ isFetching: false });
            });
        });
      } else {
        this.setState({ ...this.getInitialState(), isFetching: false });
      }
    }
  }

  fetchDowntimes = () => {
    return API.listDowntimes(this.props.currentSystem.id, {
      to: {
        [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
      },
      asset_including_related_id: this.props.workOrder.asset_id,
    }).then(res => {
      const { data: downtimes } = res;
      const { entities, result } = normalizeDowntime(downtimes);
      this.props.updateEntities(entities);
      return { downtimeIds: result.filter(id => !this.props.workOrder.downtimes.includes(id)) };
    });
  };

  renderNewDowntimeModal = () => {
    return (
      <NewDowntimeModal
        open={this.state.showNewDowntimeModal}
        defaultParams={{ work_orders: [this.props.workOrder.id] }}
        assetId={this.props.workOrder.asset_id}
        onClose={() => this.setState({ showNewDowntimeModal: false })}
        onCreateCompletedDowntime={() => {
          this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: false });
          setTimeout(() => {
            this.setState({ showDowntimeModal: true });
          }, 200);
        }}
        onCreateActiveDowntime={() => {
          this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: true });
          setTimeout(() => {
            this.setState({ showDowntimeModal: true, showDowntimeModalForId: null });
          }, 200);
        }}
      />
    );
  };

  renderDowntimeModal = () => {
    return (
      <DowntimeModal
        open={this.state.showDowntimeModal}
        assetId={this.props.workOrder.asset_id}
        isCreatingActiveDowntime={this.state.isCreatingActiveDowntime}
        defaultParams={{ work_orders: [this.props.workOrder.id] }}
        openedFromWorkOrderId={this.props.workOrder.id}
        onClose={() => {
          this.setState({ showDowntimeModal: false });
        }}
      />
    );
  };

  addDowntimeToWorkOrder = downtimeId => {
    this.setState({ isAddingDowntimeIdToWorkOrder: downtimeId });
    this.props.addDowntimeToWorkOrder(downtimeId, this.props.workOrder.id).then(() => {
      this.setState({ isOpen: false, isAddingDowntimeIdToWorkOrder: null, showSelectDowntimeModal: false });
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.work-order.downtime.connected-success" />}
        />
      );
    });
  };

  renderDowntimes = () => {
    if (this.state.downtimeIds.length == 0) {
      return null;
    }

    return (
      <>
        {this.state.downtimeIds.map(id => (
          <NewInlineModal.Dropdown.Item clickable onClick={() => this.addDowntimeToWorkOrder(id)}>
            <AddDowntimeListItem
              loading={this.state.isAddingDowntimeIdToWorkOrder == id}
              assetId={this.props.workOrder.asset_id}
              id={id}
              key={id}
            />
          </NewInlineModal.Dropdown.Item>
        ))}
        <NewInlineModal.Dropdown.Separator />
      </>
    );
  };

  renderSelectDowntimeModal = () => {
    return (
      <SelectDowntimeModal
        open={this.state.showSelectDowntimeModal}
        initialAssetId={this.props.workOrder.asset_id}
        onClose={() => this.setState({ showSelectDowntimeModal: false })}
        onCreateNew={() => this.setState({ showSelectDowntimeModal: false, showNewDowntimeModal: true })}
        listItemRightComponent={downtime => {
          if (downtime.exists_for_work_order) {
            return (
              <div className={styles['already-added-downtime-container']}>
                <FormattedMessage id="components.select-downtime-modal.downtime-list-item.already-added" />
              </div>
            );
          }
          return (
            <Button
              gray
              loading={this.state.isAddingDowntimeIdToWorkOrder == downtime.id}
              label="components.select-downtime-modal.downtime-list-item.add-button"
              onClick={() => {
                this.addDowntimeToWorkOrder(downtime.id);
              }}
            />
          );
        }}
        additionalApiParams={{ exists_for_work_order: this.props.match.params.id }}
      />
    );
  };

  renderDropdownItems = () => {
    if (this.state.isFetching) {
      return (
        <>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </>
      );
    }
    return (
      <>
        <NewInlineModal.Dropdown.Item
          clickable
          onClick={() =>
            this.setState({
              isOpen: false,
              showNewDowntimeModal: true,
            })
          }
          children={<FormattedMessage id="screens.work-order.downtime.create-new" />}
        />
        <NewInlineModal.Dropdown.Item
          clickable
          onClick={() => {
            this.setState({
              isOpen: false,
              showSelectDowntimeModal: true,
            });
          }}
          children={<FormattedMessage id="screens.work-order.downtime.connect-existing" />}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          open={this.state.isOpen}
          positionToRef={this.inlineModalPositioningRef}
          onClose={e => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.renderDowntimes()}
              {this.renderDropdownItems()}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
        {this.renderNewDowntimeModal()}
        {this.renderDowntimeModal()}
        {this.renderSelectDowntimeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addDowntimeToWorkOrder: SDKReduxOperations.addDowntimeToWorkOrder,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDowntimeInlineModal));
