import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class PausedComment extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {value}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  render() {
    const { value, comparator } = this.props.filter;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Text
          title={<FormattedMessage id="screens.work-orders.filters.advanced.work-order.paused-comment" />}
          comparator={comparator}
          hasValue={value}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.PausedComment,
              data: {
                [FILTER_TYPES.PausedComment]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Search,
                  value: '',
                },
              },
            })
          }
        >
          {this.renderSelectedValue()}
        </SideModal.Container.Filter.AppliedFilters.Filter.Text>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.PausedComment),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PausedComment);
