import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { API } from 'sdk';
import { SparePartsSelectors } from 'state/ducks/spareParts';
import styles from './styles.module.scss';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.checkedSparePartCount >= maxQuantityAllowedForType(ExportType.SparePartsXslx),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    const {
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    } = queryString.parse(this.props.location.search);
    if (this.props.totalEntriesIsSelected) {
      let params = {};
      if (asset_with_tree_children_id) {
        params = {
          ...params,
          spare_part_assets: {
            asset_with_tree_children_id: asset_with_tree_children_id,
          },
        };
      }
      if (spare_part_location_with_children_id) {
        params = {
          ...params,
          spare_part_location_with_children_id,
        };
      }
      if (spare_part_type_with_children_id) {
        params = {
          ...params,
          spare_part_type_with_children_id,
        };
      }
      return {
        ...params,
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.checkedSparePartIds).toString(),
      },
    };
  };

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.buildFilterParams(),
      type: ExportType.SparePartsXslx,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedSparePartCount}
        one={<FormattedMessage id="components.export-modal.total-spare-parts.one" />}
        two={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.two"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.few"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.many"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.other"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
      />
    );
  };

  renderContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            {<FormattedMessage id="components.export-modal.excel-quantity-exceeded" />}
          </div>
        </Modal.Content>
      );
    }
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.showQuantityExceeded) {
      return null;
    }
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.export-modal.title-xslx" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: SparePartsSelectors.getQueryParameters(state),
    filtersAsQueryParams: SparePartsSelectors.getFiltersAsQueryParams(state),
    checkedSparePartIds: SparePartsSelectors.getSelectedSparePartIds(state),
    checkedSparePartCount: SparePartsSelectors.getSelectedSparePartCount(state),
    totalEntriesIsSelected: SparePartsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default withRouter(connect(mapStateToProps)(ExportModal));
