import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Icon, Loader } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { InspectionValue } from 'sdk/ChecklistInstanceRow';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class InspectionOptions extends Component {
  constructor(props) {
    super(props);
    let value = null;
    if (this.props.instanceRow) {
      value = props.instanceRow.value;
    }
    this.state = {
      value,
      isSavingInspectionValue: null,
    };
  }

  componentDidMount() {
    this.debouncedUpdateValue = debounce(value => {
      this.props
        .updateChecklistInstanceRow(this.props.instanceRow.id, {
          value,
        })
        .then(() => {
          this.setState({ isSavingInspectionValue: null });
        })
        .catch(e => {
          this.setState({ isSavingInspectionValue: null });
          toast(
            <ToastMessage
              error
              text={
                <FormattedMessage id="components.checklist-instance-modal.errors.value-could-not-be-saved" />
              }
            />
          );
        });
    }, 250);
  }

  updateValue = value => {
    if (this.props.editable === false) {
      return;
    }
    this.setState(
      prevState => ({
        isSavingInspectionValue: value,
        value: prevState.value === value ? null : value,
      }),
      () => {
        this.debouncedUpdateValue(this.state.value);
      }
    );
  };

  renderOkValue = () => {
    let classNames = [styles['circle-button']];
    if (this.state.value === InspectionValue.Ok) {
      classNames = [...classNames, styles['checked']];
    }
    if (this.props.editable) {
      return (
        <div className={classNames.join(' ')} onClick={() => this.updateValue(InspectionValue.Ok)}>
          {this.state.isSavingInspectionValue === InspectionValue.Ok ? (
            <Loader tiny />
          ) : (
            <Icon type="check" light size={14} />
          )}
        </div>
      );
    }
    return (
      <div className={classNames.join(' ')}>
        <Icon type="check" light size={14} />
      </div>
    );
  };

  renderFailedValue = () => {
    let classNames = [styles['circle-button']];
    if (this.state.value === InspectionValue.Failed) {
      classNames = [...classNames, styles['failed']];
    }
    if (this.props.editable) {
      return (
        <div className={classNames.join(' ')} onClick={() => this.updateValue(InspectionValue.Failed)}>
          {this.state.isSavingInspectionValue === InspectionValue.Failed ? (
            <Loader tiny />
          ) : (
            <Icon type="times" light size={14} />
          )}
        </div>
      );
    }
    return (
      <div className={classNames.join(' ')}>
        <Icon type="times" light size={14} />
      </div>
    );
  };

  renderNoAnswerValue = () => {
    let classNames = [styles['circle-button']];
    if (this.state.value === InspectionValue.NoAnswer) {
      classNames = [...classNames, styles['no-answer']];
    }
    if (this.props.editable) {
      return (
        <div className={classNames.join(' ')} onClick={() => this.updateValue(InspectionValue.NoAnswer)}>
          {this.state.isSavingInspectionValue === InspectionValue.NoAnswer ? (
            <Loader tiny />
          ) : (
            <FormattedMessage id="resources.checklist-template-row.inspection-no-answer" />
          )}
        </div>
      );
    }
    return (
      <div className={classNames.join(' ')}>
        <FormattedMessage id="resources.checklist-template-row.inspection-no-answer" />
      </div>
    );
  };

  render() {
    let classNames = [styles['inspection-buttons']];
    if (this.props.editable === false) {
      classNames = [...classNames, styles['not-editable']];
    }
    return (
      <div className={classNames.join(' ')}>
        <div className={styles['action-buttons']}>
          {this.renderOkValue()}
          {this.renderFailedValue()}
          {this.renderNoAnswerValue()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateChecklistInstanceRow: SDKReduxOperations.updateChecklistInstanceRow,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { instanceRowId } = ownProps;
  return {
    instanceRow: EntitySelectors.getChecklistInstanceRow(state, instanceRowId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionOptions);

InspectionOptions.propTypes = {
  editable: PropTypes.bool,
  instanceRow: PropTypes.object,
};

InspectionOptions.defaultProps = {
  editable: true,
  instanceRow: null,
};
