import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import SparePartContainer from './SparePartContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class UsedSparePart extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter.spare_part_id;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <SparePartContainer id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter.spare_part_id;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={
          <FormattedMessage id="screens.work-orders.filters.advanced.used-spare-parts.used-spare-part" />
        }
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.UsedSparePart,
            data: {
              spare_part_withdrawal: {
                ...this.props.filter,
                spare_part_id: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'spare_part_withdrawal'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsedSparePart);
