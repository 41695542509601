import HelperFunctions from 'utilities/HelperFunctions';

const getNavigatedTo = state => {
  const { assets } = state;
  return assets.navigatedTo;
};

const getAssets = state => {
  const { assets, entities } = state;
  return assets.ids.map(id => entities.assetById[id]);
};

const getAssetListPagination = state => state.assets.pagination;
const isFetchingTree = state => state.assets.treeIsFetching;

const getTreeData = state => state.assets.tree;
const isEmpty = state => {
  const { tree } = state.assets;
  return tree.items[tree.rootId].children.length === 0;
};

/*
  Filter
*/

const getQueryParameters = (state, key) => {
  const { assets } = state;
  return assets.queryParams;
};

const getFilters = (state, key) => {
  const { assets } = state;
  return assets.filters;
};

const getFiltersAsQueryParams = (state, key) => {
  const { assets } = state;
  return HelperFunctions.buildFilter(assets.filters);
};

const getAppliedFilters = (state, key) => {
  const { assets } = state;
  return assets.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { assets } = state;
  return assets.filters[key];
};

const isFiltering = (state, key) => {
  const { assets } = state;
  return assets.appliedFilters.length > 0;
};

const showingAppliedFilters = (state, key) => {
  const { assets } = state;
  return assets.showingAppliedFilters;
};

const getSelectedFilterType = (state, key) => {
  const { assets } = state;
  return assets.showApplyFilterForField;
};

/*
  MassEdit
*/

const getSelectedAssetIds = state => {
  return state.assets.selectedAssets.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.assets.selectedAssets.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.assets.selectedAssets.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.assets.selectedAssets.pageIsSelected;
};

const getSelectedAssetCount = state => {
  if (state.assets.selectedAssets.totalEntriesAreSelected) {
    return state.assets.pagination.totalEntries;
  }
  return Object.keys(state.assets.selectedAssets.ids).length;
};

export default {
  getNavigatedTo,
  getFiltersAsQueryParams,
  getSelectedAssetIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedAssetCount,
  getAssets,
  getFilters,
  getAssetListPagination,
  isFetchingTree,
  getTreeData,
  isEmpty,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
  getQueryParameters,
  getFiltersForKey,
  getAppliedFilters,
};
