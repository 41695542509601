import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Decimal } from 'decimal.js';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { normalizePurchaseOrder } from 'sdk/Schemas';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { EstimatedDeliveryMode } from 'sdk/PurchaseOrder';
import { ToastMessage } from 'views/components/Shared/Layout';
import { List, MoneyWithCurrency, Tooltip, Icon, Datapill } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { SparePartUnitWithValue, Title, SparePartListItemExpandable } from 'views/components/SparePart';
import MoreOptionsInlineModal from './MoreOptionsInlineModal';
import styles from './style.module.scss';

class PurchaseOrderRowListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  deletePurchaseOrderRow = () => {
    this.setState({ isDeleting: true });

    this.props
      .deletePurchaseOrderRow(this.props.purchaseOrderRow.id)
      .then(() => {
        API.fetchPurchaseOrder(this.props.purchaseOrderRow.purchase_order_id).then(
          ({ data: purchaseOrder }) => {
            const { entities } = normalizePurchaseOrder(purchaseOrder);
            this.props.updateEntities(entities);
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.purchase-order.info.articles.article-has-been-deleted-success" />
                }
              />
            );
          }
        );
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  renderExpandedComponent = () => {
    if (this.props.sparePart == null) {
      return (
        <div className={styles['empty-row-text']}>
          <FormattedMessage id="screens.purchase-order.info.articles.spare-part-not-yet-created-info-text" />
        </div>
      );
    }

    return <SparePartListItemExpandable id={this.props.sparePart.id} />;
  };

  getPurchaseOrderRowSummary = purchaseOrderRow => {
    const quantity = new Decimal(purchaseOrderRow.quantity || 0);
    const price = new Decimal(purchaseOrderRow.price || 0);
    return quantity.times(price);
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.unit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  renderQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.unit}
          value={this.props.purchaseOrderRow.quantity}
        />
      );
    }
  };

  renderSparePartConnectionIcon = () => {
    if (this.props.sparePart) {
      return (
        <List.Item.Column alignRight>
          <Tooltip
            label={<Title sparePart={this.props.sparePart} />}
            trigger={<Icon type="cogs" withBackground blue size={11} backgroundSize={22} />}
          />
        </List.Item.Column>
      );
    }
    return (
      <List.Item.Column alignRight>
        <Tooltip
          label={
            <FormattedMessage id="screens.purchase-order.info.articles.spare-part-not-yet-created-info-text" />
          }
          trigger={<Icon type="cogs" withBackground size={11} backgroundSize={22} />}
        />
      </List.Item.Column>
    );
  };

  renderEstimatedDeliveryDateDataPill = () => {
    if (this.props.purchaseOrder.estimated_delivery_mode === EstimatedDeliveryMode.Multiple) {
      if (this.props.purchaseOrderRow.estimated_delivery_date) {
        return (
          <Datapill
            value={moment(this.props.purchaseOrderRow.estimated_delivery_date).format('LL')}
            label={<FormattedMessage id="resources.purchase-order.expected-delivery-date" />}
          />
        );
      }
    }

    return null;
  };

  render() {
    return (
      <List.Item
        new={this.props.new}
        clickable={this.props.canCreateWithdrawals}
        onClick={this.props.onEdit}
        expandable
        dataPillsComponent={this.renderEstimatedDeliveryDateDataPill()}
        expandedComponent={this.renderExpandedComponent()}
        iconButtons={
          this.props.canEditPurchaseOrders ? (
            <MoreOptionsInlineModal
              purchaseOrderRow={this.props.purchaseOrderRow}
              onNavigateToSparePart={() =>
                this.props.history.push(`/spare-parts/${this.props.purchaseOrderRow.spare_part_id}`)
              }
              onCreateSparePart={this.props.onCreateSparePart}
              isDeleting={this.state.isDeleting}
              onEdit={this.props.onEdit}
              onDelete={this.deletePurchaseOrderRow}
            />
          ) : null
        }
      >
        <List.Item.Column width={100}>{this.props.purchaseOrderRow.number || '-'}</List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.purchaseOrderRow.title}
          subtitle={this.renderPackageQuantity()}
        />
        <List.Item.Column alignRight width={100}>
          {this.renderQuantity()}
        </List.Item.Column>
        <List.Item.Column alignRight width={120}>
          <MoneyWithCurrency
            value={this.props.purchaseOrderRow.price}
            currency={this.props.purchaseOrder.currency}
          />
        </List.Item.Column>

        <List.Item.Column alignRight width={120}>
          <MoneyWithCurrency
            value={this.getPurchaseOrderRowSummary(this.props.purchaseOrderRow)}
            currency={this.props.purchaseOrder.currency}
          />
        </List.Item.Column>
        {this.renderSparePartConnectionIcon()}
      </List.Item>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePurchaseOrderRow: SDKReduxOperations.deletePurchaseOrderRow,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePart: EntitySelectors.getSparePart(state, ownProps.purchaseOrderRow.spare_part_id),
    unit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit_id),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderRowListItem)));
