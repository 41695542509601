import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Icon, Button, Tooltip } from 'views/components/Shared/General';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { buildReduxChecklistRowDataWithInstanceRows } from 'sdk/ChecklistTemplate';
import { normalizeChecklistDeviationAction } from 'sdk/Schemas';
import { API } from 'sdk';
import ChecklistTemplateRows from './ChecklistTemplateRows';
import styles from './style.module.scss';

class ChecklistDeviationActionModal extends Component {
  getInitialState = () => ({
    isFetching: false,
    tab: 'checklistRows',
    rootRowIds: [],
    childRowIds: [],
    instanceRowForTemplateRowIds: {},
  });

  state = this.getInitialState();

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isFetching: true });
      API.fetchChecklistDeviationAction(this.props.id).then(({ data: checklistDeviationAction }) => {
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows:
              checklistDeviationAction.checklist_instance.checklist_template_version.checklist_template_rows,
            checklist_instance_rows: checklistDeviationAction.checklist_instance_rows,
          });

        const { entities } = normalizeChecklistDeviationAction(checklistDeviationAction);
        this.props.updateEntities(entities);

        this.setState({
          isFetching: false,
          rootRowIds,
          childRowIds,
          instanceRowForTemplateRowIds,
        });
      });
    }
  }

  renderSubtitle = () => {
    if (this.props.workOrder?.asset_id) {
      return <TreePath size={13} fullPath assetId={this.props.workOrder.asset_id} />;
    } else if (this.props.operationalMaintenanceInstance?.asset_id) {
      return <TreePath size={13} fullPath assetId={this.props.operationalMaintenanceInstance.asset_id} />;
    }

    return null;
  };

  renderTabs = () => {
    if (!this.props.workOrder) return null;

    return (
      <Modal.Header.TabBar>
        <Modal.Header.TabBarItem
          onClick={() => this.setState({ tab: 'checklistRows' })}
          active={this.state.tab === 'checklistRows'}
        >
          <FormattedMessage id="components.checklist-deviation-modal.tabs.checklist" />
        </Modal.Header.TabBarItem>

        <Modal.Header.TabBarItem
          onClick={() => this.setState({ tab: 'workOrder' })}
          active={this.state.tab === 'workOrder'}
        >
          <FormattedMessage id="components.checklist-deviation-modal.tabs.work-order" />
        </Modal.Header.TabBarItem>
      </Modal.Header.TabBar>
    );
  };

  renderModalHeaderIconButtons = () => {
    if (this.props.isProductionSupervisor) {
      return null;
    }
    if (this.props.workOrder) {
      return (
        <Tooltip
          trigger={
            <Link to={`/work-orders/${this.props.workOrder.id}`}>
              <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
            </Link>
          }
          label={<FormattedMessage id="general.navigate-to.work-order" />}
        />
      );
    }
  };

  renderModalHeader = () => {
    if (this.state.isFetching) return null;
    return (
      <Modal.Header
        ignoreLine
        title={this.props.checklistTemplateVersion?.title}
        subtitle={this.renderSubtitle()}
        iconButtons={this.renderModalHeaderIconButtons()}
        tabBarComponent={this.renderTabs()}
        onClose={this.props.onClose}
      />
    );
  };

  renderCreatedFromText = () => {
    if (this.props.checklistDeviationAction.work_order_id) {
      return <FormattedMessage id="components.checklist-deviation-modal.work-created-from-checklist-text" />;
    }
    if (this.props.checklistDeviationAction.request_id) {
      return <FormattedMessage id="components.checklist-deviation-modal.request-from-checklist-text" />;
    }
    return null;
  };

  renderChecklistFilledInOnText = () => {
    if (this.props.workOrder) {
      return (
        <span>
          <FormattedMessage id="components.checklist-deviation-modal.checklist-filled-in-on-work-order" />
          <span> </span>
          <span>#{this.props.workOrder.number}</span>
          <span> - </span>
          <span>{this.props.workOrder.title}</span>
        </span>
      );
    } else if (this.props.operationalMaintenanceInstance) {
      return (
        <span>
          <FormattedMessage id="components.checklist-deviation-modal.checklist-filled-in-on-operational-maintenance" />
          :<span> {this.props.operationalMaintenanceTemplateVersion.title}</span>
        </span>
      );
    }
  };

  renderTextContainer = () => {
    return (
      <div className={styles['text-container']}>
        <div className={styles['created-from-text']}>{this.renderCreatedFromText()}</div>
        <div>{this.renderChecklistFilledInOnText()}</div>
      </div>
    );
  };

  renderContent = () => {
    if (this.state.isFetching || this.props.checklistDeviationAction == null) {
      return <Modal.Content loading />;
    }
    if (this.state.tab === 'workOrder') {
      return (
        <Modal.Content noHeader>
          <PreviewWorkOrderContent workOrderId={this.props.workOrder.id} />
        </Modal.Content>
      );
    }
    return (
      <Modal.Content grayBackground hasTabs={this.props.workOrder != null}>
        {this.renderTextContainer()}
        <ChecklistTemplateRows
          rootRowIds={this.state.rootRowIds}
          childRowIds={this.state.childRowIds}
          instanceRowForTemplateRowIds={this.state.instanceRowForTemplateRowIds}
        />
      </Modal.Content>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={700}>
        {this.renderModalHeader()}
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const checklistDeviationAction = EntitySelectors.getChecklistDeviationAction(state, id);
  if (checklistDeviationAction) {
    const checklistInstance = EntitySelectors.getChecklistInstance(
      state,
      checklistDeviationAction.checklist_instance_id
    );

    let workOrder;
    let operationalMaintenanceInstance;
    let operationalMaintenanceTemplateVersion;

    if (checklistInstance.work_order_id) {
      workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
    } else if (checklistInstance.operational_maintenance_instance_id) {
      operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
        state,
        checklistInstance.operational_maintenance_instance_id
      );

      if (operationalMaintenanceInstance) {
        operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
          state,
          operationalMaintenanceInstance.operational_maintenance_template_version_id
        );
      }
    }

    return {
      checklistDeviationAction,
      workOrder,
      operationalMaintenanceInstance,
      operationalMaintenanceTemplateVersion,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version_id
      ),
      system: AuthSelectors.getCurrentSystem(state),
      isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    };
  }
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDeviationActionModal);
