import React from 'react';
import moment from 'moment';
import styles from './style.module.scss';

export default ({ firstDateOfMonth, date, dateSummary, onGoToDate }) => {
  const isDateInAnotherMonth = () => {
    return !moment(firstDateOfMonth).isSame(date.isoWeekday(date.isoWeekday()).format('YYYY-MM-DD'), 'month');
  };

  const getClassNames = () => {
    let classNames = [styles['day-container']];
    if (moment(date).isSame(moment(), 'day')) {
      classNames = [...classNames, styles['today']];
    }
    if (isDateInAnotherMonth()) {
      classNames = [...classNames, styles['inactive']];
    }
    if (dateSummary) {
      if (dateSummary === 'completed') {
        classNames = [...classNames, styles['completed']];
      } else if (dateSummary === 'active') {
        if (moment(date).isBefore(moment())) {
          classNames = [...classNames, styles['overdue']];
        } else {
          classNames = [...classNames, styles['active']];
        }
      }
    }
    return classNames;
  };

  return (
    <div className={getClassNames().join(' ')} onClick={() => onGoToDate(date.format('YYYY-MM-DD'))}>
      <div className={styles['day']}>{date.format('D')}</div>
    </div>
  );
};
