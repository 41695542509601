import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Button } from 'views/components/Shared/General';
import { ChecklistVersionForm } from 'views/components/Checklist';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { normalizeChecklistTemplate } from 'sdk/Schemas';

class PreviewChecklistTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      templateVersionId: null,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      API.fetchChecklistTemplate(this.props.id).then(({ data: checklistTemplate }) => {
        const { entities } = normalizeChecklistTemplate(checklistTemplate);
        this.props.updateEntities(entities);
        const { active_version_id, draft_version_id } = checklistTemplate;
        let templateVersionId = active_version_id || draft_version_id;
        this.setState({ templateVersionId, isFetching: false });
      });
    }
  }
  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <List>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    return <ChecklistVersionForm id={this.state.templateVersionId} />;
  };

  renderFooter = () => {
    if (this.props.hideFooter) {
      return null;
    }
    return (
      <Modal.Footer container>
        <Button.Group>
          <Button
            primary
            label="components.preview-checklist-modal.add-to-work-order"
            loading={this.state.isSaving}
            onClick={() => {
              this.props.onSelect(this.props.checklistTemplate);
            }}
          />
          <Button label="general.close" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    );
  };

  render() {
    return (
      <Modal fullHeight isOpen={this.props.open} width={750}>
        <Modal.Header
          ignoreLine
          title={this.props.checklistTemplate && this.props.checklistTemplate.title}
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>{this.renderContent()}</Modal.Content>
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PreviewChecklistTemplateModal));

PreviewChecklistTemplateModal.propTypes = {
  open: PropTypes.bool,
  hideFooter: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

PreviewChecklistTemplateModal.defaultProps = {
  open: false,
  hideFooter: false,
  onSelect: () => {},
  onClose: () => {},
};
