import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer } from 'views/components/Shared/Layout';
import {
  Button,
  List,
  NewTab,
  EmptyDataSet,
  WhiteCard,
  Field,
  Pagination,
  Icon,
} from 'views/components/Shared/General';
import { NewWorkOrderModal } from 'views/components/WorkOrder';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import WorkOrderSmall from 'assets/images/EmptyDataSet/WorkOrderSmall.png';
import Calendar from './Calendar';
import styles from './style.module.scss';
import WorkOrderListItem from './WorkOrderListItem';

const FILTERS = ['page'];
const PAGE_SIZE = 8;

class WorkOrders extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    const { date } = queryParams;
    this.state = {
      isFetching: true,
      queryParams: queryParams,
      showNewWorkOrderModal: false,
      includeUnderlyingAssets: true,
      calendarFromDate: this.getStartDateFromDate(date),
      calendarEndDate: this.getEndDateFromDate(date),
      calendarFirstDateOfMonth: this.getFirstDateOfMonth(date),
      defaultNewWorkOrderParams: {},
    };
  }

  componentDidMount() {
    const { list } = this.state.queryParams;
    if (list === 'calendar') {
      const { calendarFromDate, calendarEndDate } = this.state;
      this.fetchInstancesForDates(calendarFromDate, calendarEndDate)
        .then(() => {
          this.setState({ isFetching: false });
        })
        .catch(() => {
          this.setState({ isFetching: false });
        });
    } else {
      this.fetchWorkOrders()
        .then(() => {
          this.setState({ isFetching: false });
        })
        .catch(() => {
          this.setState({ isFetching: false });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams, isFetching: true }, () => {
        const { list, date } = queryParams;
        if (list === 'calendar') {
          const calendarFromDate = this.getStartDateFromDate(date);
          const calendarEndDate = this.getEndDateFromDate(date);
          const calendarFirstDateOfMonth = this.getFirstDateOfMonth(date);
          this.setState({
            calendarFromDate,
            calendarEndDate,
            calendarFirstDateOfMonth,
          });
          this.fetchInstancesForDates(calendarFromDate, calendarEndDate)
            .then(() => {
              this.setState({ isFetching: false });
            })
            .catch(() => {
              this.setState({ isFetching: false });
            });
        } else {
          this.fetchWorkOrders()
            .then(() => {
              this.setState({ isFetching: false });
            })
            .catch(() => {
              this.setState({ isFetching: false });
            });
        }
      });
    }
  }

  goToPreviousMonth = () => {
    const { date: urlDate } = queryString.parse(this.props.location.search);
    let date = urlDate == null ? moment() : moment(urlDate);
    const newDate = date.subtract(1, 'months').format('YYYY-MM-DD');
    this.changeQueryParams({ date: newDate });
  };

  goToNextMonth = () => {
    const { date: urlDate } = queryString.parse(this.props.location.search);
    let date = urlDate == null ? moment() : moment(urlDate);
    const newDate = date.add(1, 'months').format('YYYY-MM-DD');
    this.changeQueryParams({ date: newDate });
  };

  getStartDateFromDate = date => {
    if (date == null) {
      return moment().startOf('month').isoWeekday(1).format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').isoWeekday(1).format('YYYY-MM-DD');
  };

  getEndDateFromDate = date => {
    if (date == null) {
      return moment().endOf('month').isoWeekday(7).format('YYYY-MM-DD');
    }
    return moment(date).endOf('month').isoWeekday(7).format('YYYY-MM-DD');
  };

  getFirstDateOfMonth = date => {
    if (date == null) {
      return moment().startOf('month').format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').format('YYYY-MM-DD');
  };

  fetchInstancesForDates = (fromDate, toDate, options) => {
    let attrs = {};
    if (this.state.includeUnderlyingAssets) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id: this.props.match.params.id,
      };
    } else {
      attrs = {
        ...attrs,
        asset_id: this.props.match.params.id,
      };
    }
    return this.props.fetchInstancesForDates(
      this.props.currentSystem.id,
      {
        from: fromDate,
        to: toDate,
        ...attrs,
      },
      options
    );
  };

  fetchWorkOrders = (params = {}) => {
    const { list, page } = this.state.queryParams;

    let attrs = {
      page,
      page_size: PAGE_SIZE,
      ...params,
    };
    if (list === 'completed') {
      attrs = {
        ...attrs,
        status: WorkOrderStatus.Completed,
        sort: 'completed_date',
        'sort-order': 'desc',
      };
    } else {
      attrs = {
        ...attrs,
        list: 'upcoming',
        sort: 'due_date',
        'sort-order': 'asc',
      };
    }
    if (this.state.includeUnderlyingAssets) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id: this.props.match.params.id,
      };
    } else {
      attrs = {
        ...attrs,
        asset_id: this.props.match.params.id,
      };
    }

    return this.props.fetchWorkOrders(this.props.currentSystem.id, attrs).then(({ data: workOrders }) => {
      return workOrders;
    });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  createWorkOrder = params => {
    this.setState({ newWorkOrderParams: params, showNewWorkOrderModal: true });
  };

  isFiltering = () => FILTERS.some(key => this.state.queryParams[key] != null);

  renderNewWorkOrderModal = () => {
    const { list } = this.state.queryParams;
    return (
      <NewWorkOrderModal
        defaultParams={{
          asset_id: this.props.match.params.id,
          ...this.state.defaultNewWorkOrderParams,
        }}
        open={this.state.showNewWorkOrderModal}
        onClose={() => {
          this.setState({ showNewWorkOrderModal: false });
        }}
        onCreated={workOrder => {
          this.setState({ showNewWorkOrderModal: false }, () => {
            setTimeout(() => {
              this.props.history.push(`/work-orders/${workOrder.id}`);
            }, 350);
          });
        }}
        onCreatedWithReopen={workOrder => {
          if (workOrder.recurring_maintenance_id && list === 'calendar') {
            const { calendarFromDate, calendarEndDate } = this.state;
            this.fetchInstancesForDates(
              calendarFromDate,
              calendarEndDate,
              {
                recurring_maintenance_id: workOrder.recurring_maintenance_id,
              },
              { resetCalendar: false }
            );
          }
          this.setState({ showNewWorkOrderModal: false }, () => {
            setTimeout(() => {
              this.setState({ showNewWorkOrderModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="screens.asset.work-orders.empty-data-set.title" />}
          subtitle={<FormattedMessage id="screens.asset.work-orders.empty-data-set.subtitle" />}
          image={WorkOrderSmall}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderCheckboxContainer = () => {
    const { list } = this.state.queryParams;
    const { date: dateUrlParam } = queryString.parse(this.props.location.search);
    const date = dateUrlParam == null ? moment() : moment(dateUrlParam);
    return (
      <div className={styles['checkbox-container']}>
        {list === 'calendar' ? (
          <div className={styles['month-navigation']}>
            <div className={styles['navigation-container']}>
              <Button
                type="icon"
                icon={<Icon regular size={20} type="angle-left" />}
                onClick={this.goToPreviousMonth}
              />
              <Button
                type="icon"
                icon={<Icon regular size={20} type="angle-right" />}
                onClick={this.goToNextMonth}
              />
            </div>
            <div className={styles['month']}>{`${date.format('MMMM')} ${date.format('YYYY')}`}</div>
          </div>
        ) : null}
        <div className={styles['checkbox']}>
          <Field.Checkbox
            checked={this.state.includeUnderlyingAssets}
            label={<FormattedMessage id="screens.asset.work-orders.include-underlying-assets" />}
            onChange={() => {
              this.setState(
                prevState => ({
                  isFetching: true,
                  includeUnderlyingAssets: !prevState.includeUnderlyingAssets,
                }),
                () => {
                  const { calendarFromDate, calendarEndDate } = this.state;
                  if (list === 'calendar') {
                    this.fetchInstancesForDates(calendarFromDate, calendarEndDate)
                      .then(() => {
                        this.setState({ isFetching: false });
                      })
                      .catch(() => {
                        this.setState({ isFetching: false });
                      });
                  } else {
                    this.fetchWorkOrders()
                      .then(() => {
                        this.setState({ isFetching: false });
                      })
                      .catch(() => {
                        this.setState({ isFetching: false });
                      });
                  }
                }
              );
            }}
          />
        </div>
      </div>
    );
  };

  renderCreateButton = () => {
    if (this.props.canCarryOutWorkOrders) {
      return (
        <div>
          <Button
            small
            primary
            onClick={() => this.setState({ showNewWorkOrderModal: true, defaultNewWorkOrderParams: {} })}
            label="screens.asset.work-orders.create-button"
          />
        </div>
      );
    }
    return null;
  };

  renderToolbar = () => {
    const { list } = this.state.queryParams;
    return (
      <div className={styles['toolbar']}>
        <div className={styles['left-content']}>
          <NewTab.Container>
            <NewTab
              selected={list == null}
              linkTo={{
                search: null,
              }}
            >
              <FormattedMessage id="screens.asset.work-orders.tabs.upcoming" />
            </NewTab>
            <NewTab
              selected={list === 'completed'}
              linkTo={{
                search: 'list=completed',
              }}
            >
              <FormattedMessage id="screens.asset.work-orders.tabs.completed" />
            </NewTab>
            <NewTab
              selected={list === 'calendar'}
              linkTo={{
                search: 'list=calendar',
              }}
            >
              <FormattedMessage id="screens.asset.work-orders.tabs.calendar" />
            </NewTab>
          </NewTab.Container>
        </div>
        {this.renderCreateButton()}
      </div>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= PAGE_SIZE) return null;
    return (
      <Pagination
        totalEntriesComponent={
          <FormattedMessage
            id="screens.work-orders.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        hideOptions
        currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
        totalPages={this.props.pagination.totalPages}
        onSelectPage={page => {
          this.changeQueryParams({ page });
        }}
        style={{ justifyContent: 'flex-end', marginTop: 10 }}
      />
    );
  };

  renderHeader = () => {
    const { list } = this.state.queryParams;
    if (list === 'completed') {
      return (
        <List.Header small background expandable>
          <List.Header.Column flex>
            <FormattedMessage id="resources.work-order.title" />
          </List.Header.Column>
          <List.Header.Column width={140}>
            <FormattedMessage id="resources.work-order.completed-at" />
          </List.Header.Column>
          <List.Header.Column width={72}>
            <FormattedMessage id="resources.work-order.assignee" />
          </List.Header.Column>
          <List.Header.Column width={45} />
        </List.Header>
      );
    }
    return (
      <List.Header small background expandable>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order.title" />
        </List.Header.Column>
        <List.Header.Column width={140}>
          <FormattedMessage id="resources.work-order.due-date" />
        </List.Header.Column>
        <List.Header.Column width={72}>
          <FormattedMessage id="resources.work-order.assignee" />
        </List.Header.Column>
        <List.Header.Column width={45} alignRight />
      </List.Header>
    );
  };

  renderContent = () => {
    const { list } = this.state.queryParams;
    if (list === 'calendar') {
      return (
        <>
          {this.renderToolbar()}
          {this.renderCheckboxContainer()}
          <div className={styles['calendar-container']}>
            <Calendar
              loading={this.state.isFetching}
              fromDate={this.state.calendarFromDate}
              endDate={this.state.calendarEndDate}
              firstDateOfMonth={this.state.calendarFirstDateOfMonth}
              onCreateWorkOrder={params =>
                this.setState({ showNewWorkOrderModal: true, defaultNewWorkOrderParams: params })
              }
            />
          </div>
        </>
      );
    }
    return this.renderList();
  };

  renderList = () => {
    const { list } = this.state.queryParams;
    if (this.state.isFetching) {
      if (this.isFiltering()) {
        const amountOfWorkOrders = this.props.workOrders.length === 0 ? 2 : this.props.workOrders.length;
        return (
          <>
            {this.renderToolbar()}
            {this.renderCheckboxContainer()}
            {this.renderHeader()}
            <List small>
              {Array(amountOfWorkOrders)
                .fill()
                .map(() => (
                  <List.Item>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
            {this.renderPagination()}
          </>
        );
      }
      return (
        <>
          {this.renderToolbar()}
          {this.renderCheckboxContainer()}
          {this.renderHeader()}
          <List small>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.props.workOrders.length === 0)
      return (
        <>
          {this.renderToolbar()}
          {this.renderCheckboxContainer()}
          {this.renderEmptyDataSet()}
        </>
      );
    return (
      <>
        {this.renderToolbar()}
        {this.renderCheckboxContainer()}
        {this.renderHeader()}
        <List small>
          {this.props.workOrders.map(workOrder => {
            return <WorkOrderListItem list={list} key={workOrder.id} id={workOrder.id} />;
          })}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderContent()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderNewWorkOrderModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrders: AssetOperations.fetchWorkOrders,
      fetchInstancesForDates: AssetOperations.fetchInstancesForDates,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    workOrders: AssetSelectors.getWorkOrders(state),
    pagination: AssetSelectors.getWorkOrderListPagination(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrders)));
