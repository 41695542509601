import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class CompletedAt extends Component {
  render() {
    const { comparator, value } = this.props.filter[FILTER_TYPES.CompletedAt];
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Date
        title={<FormattedMessage id="resources.work-order.completed-at" />}
        comparator={comparator}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.CompletedAt,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.CompletedAt]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                  value: null,
                },
              },
            },
          })
        }
      >
        <SideModal.Container.Filter.AppliedFilters.Value>
          {value}
        </SideModal.Container.Filter.AppliedFilters.Value>
      </SideModal.Container.Filter.AppliedFilters.Filter.Date>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedAt);
