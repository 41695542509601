const isDeleted = state => state.checklistTemplate.isDeleted;
const getTemplateVersion = state =>
  state.entities.checklistTemplateVersionById[state.checklistTemplate.versionId];
const getChecklistInstances = state => state.checklistTemplate.checklistInstances.ids;
const getChecklistInstancePagination = state => state.checklistTemplate.pagination;
const getChecklistTemplateVersions = state => state.checklistTemplate.versions;

const isFetchingVersion = state => state.checklistTemplate.isFetchingVersion;

export default {
  getTemplateVersion,
  isDeleted,
  getChecklistInstances,
  getChecklistInstancePagination,
  isFetchingVersion,
  getChecklistTemplateVersions,
};
