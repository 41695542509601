import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual, debounce } from 'lodash-es';
import { withRouter } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Menu, List, Pagination, SortingButton, FilterButton } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { NewVendorModal } from 'views/components/Vendor';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import VendorsImage from 'assets/images/EmptyDataSet/Vendors.png';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { VendorsSelectors, VendorsOperations } from 'state/ducks/vendors';
import { AuthSelectors } from 'state/ducks/auth';
import VendorListItem from './VendorListItem';
import ExportModal from './ExportModal';
import FilterModal from './FilterModal';
import styles from './style.module.scss';

const listVendorsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class Vendors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
      isFetching: true,
      searchTerm: props.queryParameters.search || '',
      showExportModal: false,
    };
    this.fetchDebouncedVendors = debounce(params => {
      this.fetchVendors(params);
    }, 300);
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.vendors.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Vendor);
    this.props.resetSelectedVendors();
    this.fetchDebouncedVendors();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showFilterModal } = this.state;
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchDebouncedVendors();
      return;
    }

    if (changedQueryParams || (changedAdvancedFilters && showFilterModal === false)) {
      this.props.resetSelectedVendors();
      this.fetchDebouncedVendors();
    }
  }

  fetchVendors = (params = {}) => {
    listVendorsRequest.cancel();
    const attrs = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...params,
    };

    return this.props
      .fetchVendors(this.props.selectedSystem.id, attrs, listVendorsRequest.getCancelTokenConfig())
      .then(vendors => {
        this.setState({ isFetching: false });
        return vendors;
      });
  };

  createVendor = () => {
    this.setState({ showNewModal: true });
  };

  openVendor = vendor => {
    this.props.history.push(`/vendors/${vendor.id}`);
  };

  renderNewVendorModal = () => (
    <NewVendorModal
      open={this.state.showNewModal}
      onClose={() => {
        this.setState({ showNewModal: false });
      }}
      onCreated={vendor => {
        this.props.resetSelectedVendors();
        this.setState({ showNewModal: false }, () => {
          setTimeout(() => {
            this.openVendor(vendor);
          }, 350);
        });
      }}
      onCreatedWithReopen={() => {
        this.props.resetSelectedVendors();
        this.setState({ showNewModal: false }, () => {
          setTimeout(() => {
            this.setState({ showNewModal: true });
          }, 400);
        });
      }}
    />
  );

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.search?.length > 0;
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={VendorsImage} alt="" />
      </div>
    </div>
  );

  renderVendorsEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.vendors.empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.vendors.empty-data-set.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={VendorsImage} alt="" />
      </div>
    </div>
  );

  renderPagination = () => {
    if (this.props.vendors.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isFetching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.vendors.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedVendors()}
        />
      );
    }
    return null;
  };

  renderListHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected } = this.props;
    const { isFetching } = this.state;
    return (
      <List.Header
        small
        checkbox={this.props.canEditVendors}
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedVendors();
          } else {
            this.props.selectPage();
          }
        }}
        background
        showMultipleOptions={this.props.selectedVendorCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.state.isFetching}
            count={this.props.selectedVendorCount}
            buttons={
              <List.Header.MultipleOptions.Button
                label={<FormattedMessage id="screens.vendors.header-buttons.export" />}
                onClick={() => this.setState({ showExportModal: true })}
              />
            }
          />
        }
      >
        <List.Header.Column flex>
          <FormattedMessage id="resources.vendor.name" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      const amountOfVendors = this.props.vendors.length === 0 ? 2 : this.props.vendors.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfVendors)
              .fill()
              .map(() => (
                <VendorListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.vendors.length === 0) {
      if (this.isFiltering()) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderVendorsEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.vendors.map(({ id }) => {
            let checked = false;
            if (this.props.selectedVendorIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <VendorListItem
                key={id}
                id={id}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={e => {
                  this.props.selectVendor(id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.vendors.title" />}
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({ id: 'screens.vendors.search-placeholder' })}
        totalEntries={
          <FormattedMessage
            id="screens.vendors.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        onSearch={searchTerm => this.setState({ searchTerm, isFetching: true })}
        onClearSearch={() => {
          this.setState({ isFetching: true, searchTerm: '' });
          this.addQueryParameter({ search: '' });
        }}
        onDebouncedSearch={value => {
          this.addQueryParameter({ search: value });
        }}
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.canEditVendors) {
      return (
        <>
          <Button
            fullWidth
            primary
            label="screens.vendors.create-button"
            onClick={() => {
              this.setState({ showNewModal: true });
            }}
          />
          <Menu.Separator />
        </>
      );
    }
    return null;
  };

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderLeftMenu = () => {
    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <ListLayout.Content.Menu.Content>
            {this.renderCreateButton()}
            <Menu.Item linkTo="/vendors" title={<FormattedMessage id="screens.vendors.title" />} selected />
          </ListLayout.Content.Menu.Content>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  };

  renderToolbar = () => (
    <ListLayout.Content.MainContent.FilterBar>
      <ListLayout.Content.MainContent.FilterBar.RightContent>
        <FilterButton.Group>
          {this.props.isFiltering ? (
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="general.clean" />}
              onClick={() => {
                this.setState({ isFetching: true });
                this.props.resetFilter();
              }}
            />
          ) : null}
          <FilterButton
            clearable={false}
            caret={false}
            filtered={this.props.isFiltering}
            label={<FormattedMessage id="screens.vendors.filter" />}
            onClick={() => this.setState({ showFilterModal: true })}
          />
        </FilterButton.Group>
      </ListLayout.Content.MainContent.FilterBar.RightContent>
    </ListLayout.Content.MainContent.FilterBar>
  );

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderFilterModal = () => {
    return (
      <FilterModal
        open={this.state.showFilterModal}
        onClose={params => {
          this.addQueryParameter({ page: 1 });
          this.props.resetSelectedVendors();
          this.setState({ isFetching: true });
          this.fetchDebouncedVendors(params);
          this.setState({ showFilterModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderNewVendorModal()}
        {this.renderExportModal()}
        {this.renderFilterModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchVendors: VendorsOperations.fetchVendors,
      addQueryParameter: VendorsOperations.addQueryParameter,
      selectMenuItem: MenuOperations.selectItem,
      resetSelectedVendors: VendorsOperations.resetSelectedVendors,
      selectVendor: VendorsOperations.selectVendor,
      selectTotalEntries: VendorsOperations.selectTotalEntries,
      hideSelectTotalEntries: VendorsOperations.hideSelectTotalEntries,
      selectPage: VendorsOperations.selectPage,
      resetFilter: VendorsOperations.resetFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    vendors: VendorsSelectors.getVendors(state),
    queryParameters: VendorsSelectors.getQueryParameters(state),
    pagination: VendorsSelectors.getVendorsPagination(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    selectedVendorIds: VendorsSelectors.getSelectedVendorIds(state),
    selectedVendorCount: VendorsSelectors.getSelectedVendorCount(state),
    totalEntriesIsSelected: VendorsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: VendorsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: VendorsSelectors.getPageIsSelected(state),
    filters: VendorsSelectors.getFilters(state),
    isFiltering: VendorsSelectors.isFiltering(state),
    filtersAsQueryParams: VendorsSelectors.getFiltersAsQueryParams(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendors)));
