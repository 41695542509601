import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ToolbarListType } from 'views/scenes/Planning/Calendar/Calendar/utils';
import { CalendarOperations } from 'state/ducks/calendar';
import { API, SDKReduxOperations } from 'sdk';
import Day from './Day';
import ConfirmPlanAwaitingDeliveryModal from './ConfirmPlanAwaitingDeliveryModal';

class DroppableDay extends Component {
  state = {
    showConfirmPlanAwaitingDeliveryModal: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.date, nextProps.date) ||
      !isEqual(
        this.state.showConfirmPlanAwaitingDeliveryModal,
        nextState.showConfirmPlanAwaitingDeliveryModal
      )
    );
  }

  handleDragFromManageList = ({
    toDate,
    toGroupId,
    toUserId,
    toVendorId,
    fromDate,
    fromGroupId,
    fromUserId,
    fromVendorId,
    workOrderId,
    recurringMaintenanceId,
    recurringMaintenanceDate,
    users,
    groups,
    vendors,
    instanceId,
    fromToolboxList,
  }) => {
    this.removeInstancesFromCalendar({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
    });

    let draggedToAlreadyAssignedTechnician =
      (toUserId && users.includes(toUserId)) ||
      (toGroupId && groups.includes(toGroupId)) ||
      (toVendorId && vendors.includes(toVendorId));
    if (draggedToAlreadyAssignedTechnician) {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users,
        groups,
        vendors,
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: users,
        assigned_to_groups: groups,
        assigned_to_vendors: vendors,
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    } else {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users: [toUserId].filter(val => val),
        groups: [toGroupId].filter(val => val),
        vendors: [toVendorId].filter(val => val),
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: [toUserId].filter(val => val),
        assigned_to_groups: [toGroupId].filter(val => val),
        assigned_to_vendors: [toVendorId].filter(val => val),
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    }
  };

  handleDragFromOverdueList = ({
    toDate,
    toGroupId,
    toUserId,
    toVendorId,
    fromDate,
    fromGroupId,
    fromUserId,
    fromVendorId,
    workOrderId,
    recurringMaintenanceId,
    recurringMaintenanceDate,
    users,
    groups,
    vendors,
    instanceId,
    fromToolboxList,
  }) => {
    this.removeInstancesFromCalendar({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
    });
    let draggedToAlreadyAssignedTechnician =
      (toUserId && users.includes(toUserId)) ||
      (toGroupId && groups.includes(toGroupId)) ||
      (toVendorId && vendors.includes(toVendorId));
    if (draggedToAlreadyAssignedTechnician) {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users,
        groups,
        vendors,
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: users,
        assigned_to_groups: groups,
        assigned_to_vendors: vendors,
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    } else {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users: [toUserId].filter(val => val),
        groups: [toGroupId].filter(val => val),
        vendors: [toVendorId].filter(val => val),
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: [toUserId].filter(val => val),
        assigned_to_groups: [toGroupId].filter(val => val),
        assigned_to_vendors: [toVendorId].filter(val => val),
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    }
  };

  handleDragFromAwaitingDeliveryList = () => {
    const {
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
    } = this.state.dragDataForAwaitingDeliveryModal;
    this.removeInstancesFromCalendar({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
    });
    let draggedToAlreadyAssignedTechnician =
      (toUserId && users.includes(toUserId)) ||
      (toGroupId && groups.includes(toGroupId)) ||
      (toVendorId && vendors.includes(toVendorId));
    if (draggedToAlreadyAssignedTechnician) {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users,
        groups,
        vendors,
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: users,
        assigned_to_groups: groups,
        assigned_to_vendors: vendors,
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    } else {
      this.addInstancesForCalendar({
        toDate,
        toGroupId,
        toUserId,
        toVendorId,
        fromDate,
        fromGroupId,
        fromUserId,
        fromVendorId,
        workOrderId,
        recurringMaintenanceId,
        recurringMaintenanceDate,
        users: [toUserId].filter(val => val),
        groups: [toGroupId].filter(val => val),
        vendors: [toVendorId].filter(val => val),
        instanceId,
        fromToolboxList,
      });
      const data = {
        due_date: toDate,
        assigned_to_users: [toUserId].filter(val => val),
        assigned_to_groups: [toGroupId].filter(val => val),
        assigned_to_vendors: [toVendorId].filter(val => val),
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    }
  };

  removeInstancesFromCalendar = ({ fromDate, users, groups, vendors, instanceId }) => {
    let removalUpdates = [];
    users.forEach(userId => {
      removalUpdates = [
        ...removalUpdates,
        {
          userId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    groups.forEach(groupId => {
      removalUpdates = [
        ...removalUpdates,
        {
          groupId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    vendors.forEach(vendorId => {
      removalUpdates = [
        ...removalUpdates,
        {
          vendorId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    this.props.removeInstancesFromCalendar(removalUpdates);
  };

  addInstancesForCalendar = ({ toDate, users, groups, vendors, instanceId }) => {
    let addUpdates = [];

    users.forEach(userId => {
      addUpdates = [
        ...addUpdates,
        {
          userId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    groups.forEach(groupId => {
      addUpdates = [
        ...addUpdates,
        {
          groupId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    vendors.forEach(vendorId => {
      addUpdates = [
        ...addUpdates,
        {
          vendorId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    this.props.addInstancesForCalendar(addUpdates);
  };

  handleDragWithinCalendar = ({
    toDate,
    toGroupId,
    toUserId,
    toVendorId,
    fromDate,
    fromGroupId,
    fromUserId,
    fromVendorId,
    workOrderId,
    recurringMaintenanceId,
    recurringMaintenanceDate,
    users,
    groups,
    vendors,
    instanceId,
    fromToolboxList,
  }) => {
    const newUserIds = [...users.filter(id => id !== fromUserId), toUserId].filter(val => val);
    const newGroupIds = [...groups.filter(id => id !== fromGroupId), toGroupId].filter(val => val);
    const newVendorIds = [...vendors.filter(id => id !== fromVendorId), toVendorId].filter(val => val);
    this.removeInstancesFromCalendar({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
    });
    this.addInstancesForCalendar({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users: newUserIds,
      groups: newGroupIds,
      vendors: newVendorIds,
      instanceId,
      fromToolboxList,
    });

    if (workOrderId) {
      const data = {
        due_date: toDate,
        assigned_to_users: newUserIds,
        assigned_to_groups: newGroupIds,
        assigned_to_vendors: newVendorIds,
      };
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      this.props.updateWorkOrder(workOrderId, data).catch(e => {});
    } else {
      let data = {};
      const hasDueDateChange = fromDate !== toDate;
      let hasAssigneeChange = false;
      if (!isEqual(users.sort(), newUserIds.sort())) {
        hasAssigneeChange = true;
      }
      if (!isEqual(groups.sort(), newGroupIds.sort())) {
        hasAssigneeChange = true;
      }
      if (!isEqual(vendors.sort(), newVendorIds.sort())) {
        hasAssigneeChange = true;
      }
      if (hasDueDateChange) {
        data = {
          ...data,
          due_date: toDate,
          has_due_date_change: true,
        };
      }
      if (hasAssigneeChange) {
        data = {
          ...data,
          assigned_to_users: newUserIds,
          assigned_to_groups: newGroupIds,
          assigned_to_vendors: newVendorIds,
          has_assignee_change: true,
        };
      }

      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      API.updateCalendarInstance(recurringMaintenanceId, recurringMaintenanceDate, data).catch(e => {});
    }
  };

  createWorkOrderFromRequest = ({ id, registration_images, asset_id, priority, description }) => {
    this.props.onCreateWorkOrderForRequest(id, {
      date: this.props.date,
      assigned_to_users: this.props.userId == null ? [] : [this.props.userId],
      assigned_to_groups: this.props.groupId == null ? [] : [this.props.groupId],
      assigned_to_vendors: this.props.vendorId == null ? [] : [this.props.vendorId],
      completed_by_user_id: this.props.userId,
      completed_date: this.props.date,
      registration_images,
      asset_id,
      priority,
      description,
    });
  };

  reassignedToAlreadyAssignee = ({
    users,
    groups,
    vendors,
    fromUserId,
    toUserId,
    fromGroupId,
    toGroupId,
    fromVendorId,
    toVendorId,
  }) => {
    if (toUserId && toUserId !== fromUserId) {
      if (users.includes(toUserId)) {
        return true;
      }
    } else if (toGroupId && toGroupId !== fromGroupId) {
      if (groups.includes(toGroupId)) {
        return true;
      }
    } else if (toVendorId && toVendorId !== fromVendorId) {
      if (vendors.includes(toVendorId)) {
        return true;
      }
    }
    return false;
  };

  draggedAndDroppedInSameArea = dragData => {
    const { fromDate, fromUserId, fromGroupId, fromVendorId, toDate, toUserId, toGroupId, toVendorId } =
      dragData;
    return isEqual(
      {
        date: toDate,
        userId: toUserId,
        groupId: toGroupId,
        vendorId: toVendorId,
      },
      {
        date: fromDate,
        userId: fromUserId,
        groupId: fromGroupId,
        vendorId: fromVendorId,
      }
    );
  };

  onDrop = dragData => {
    if (this.draggedAndDroppedInSameArea(dragData)) {
      return;
    }
    const { fromToolboxList } = dragData;
    if (fromToolboxList === ToolbarListType.Manage) {
      this.handleDragFromManageList(dragData);
    } else if (fromToolboxList === ToolbarListType.Overdue) {
      this.handleDragFromOverdueList(dragData);
    } else if (fromToolboxList === ToolbarListType.AwaitingDelivery) {
      this.setState({
        showConfirmPlanAwaitingDeliveryModal: true,
        dragDataForAwaitingDeliveryModal: dragData,
      });
    } else if (fromToolboxList === ToolbarListType.Requests) {
      this.createWorkOrderFromRequest(dragData.requestData);
    } else {
      /*
        If dragged from one day to another in the calendar
      */
      if (this.reassignedToAlreadyAssignee(dragData)) {
        toast(
          <ToastMessage error text={<FormattedMessage id="screens.calendar.errors.drag-to-same-asignee" />} />
        );
        return;
      }

      this.handleDragWithinCalendar(dragData);
    }
  };

  render() {
    return (
      <>
        <Day {...this.props} onDrop={data => this.onDrop(data)} />
        <ConfirmPlanAwaitingDeliveryModal
          open={this.state.showConfirmPlanAwaitingDeliveryModal}
          onPlan={() => {
            this.handleDragFromAwaitingDeliveryList();
            this.setState({
              showConfirmPlanAwaitingDeliveryModal: false,
              dragDataForAwaitingDeliveryModal: {},
            });
          }}
          onClose={() => {
            this.setState({
              showConfirmPlanAwaitingDeliveryModal: false,
              dragDataForAwaitingDeliveryModal: {},
            });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkOrder: SDKReduxOperations.updateWorkOrder,
      addInstancesForCalendar: CalendarOperations.addInstancesForCalendar,
      removeInstancesFromCalendar: CalendarOperations.removeInstancesFromCalendar,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(DroppableDay));
