import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Button } from 'views/components/Shared/General';
import { MeterType } from 'sdk/ChecklistTemplateRow';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API } from 'sdk';
import { normalizeChecklistTemplateVersion } from 'sdk/Schemas';
import ChecklistTemplateRow from './ChecklistTemplateRow';
import styles from './styles.module.scss';

class ChecklistOptionsModal extends Component {
  getInitialState = () => ({
    isFetching: false,
    checklistTemplateRowIds: [],
    meterIdsForChecklistTemplateIds: {},
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { checklistInstanceOptions } = this.props;
      let meterIdsForChecklistTemplateIds = {};
      if (checklistInstanceOptions && checklistInstanceOptions.length > 0) {
        checklistInstanceOptions.forEach(option => {
          const { checklist_template_row_id, meter_id } = option;
          meterIdsForChecklistTemplateIds = {
            ...meterIdsForChecklistTemplateIds,
            [checklist_template_row_id]: meter_id,
          };
        });
      }
      this.setState({ ...this.getInitialState(), meterIdsForChecklistTemplateIds, isFetching: true });
      API.fetchChecklistTemplateVersion(this.props.checklistTemplate.active_version_id).then(
        ({ data: checklistTemplateVersion }) => {
          const { entities } = normalizeChecklistTemplateVersion(checklistTemplateVersion);
          this.props.updateEntities(entities);
          this.setState({
            isFetching: false,
            checklistTemplateRowIds: checklistTemplateVersion.checklist_template_rows
              .filter(({ meter_type }) => {
                return meter_type === MeterType.Option;
              })
              .map(({ id }) => id),
          });
        }
      );
    }
  }

  save = () => {
    this.props.onSave({
      checklist_instance_options: Object.keys(this.state.meterIdsForChecklistTemplateIds).map(key => {
        const checklist_template_row_id = key;
        const meter_id = this.state.meterIdsForChecklistTemplateIds[key];
        const checklistOptions = this.props.checklistInstanceOptions || [];
        const checklistInstanceOption = checklistOptions.find(option => {
          return option.checklist_template_row_id === checklist_template_row_id;
        });
        if (checklistInstanceOption) {
          return {
            id: checklistInstanceOption.id,
            meter_id,
            checklist_template_row_id,
            type: 'meter',
          };
        }
        return {
          meter_id,
          checklist_template_row_id,
          type: 'meter',
        };
      }),
    });
  };

  allChecklistTemplateRowsHasSelectedMeter = () => {
    const { meterIdsForChecklistTemplateIds, checklistTemplateRowIds } = this.state;
    return Object.keys(meterIdsForChecklistTemplateIds).length !== checklistTemplateRowIds.length;
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <List>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    return (
      <div className={styles['rows-container']}>
        {this.state.checklistTemplateRowIds.map(id => (
          <ChecklistTemplateRow
            id={id}
            assetId={this.props.assetId}
            meterIdsForChecklistTemplateIds={this.state.meterIdsForChecklistTemplateIds}
            onSelectMeterForChecklistTemplateRow={({ meter_id, checklist_template_row_id }) => {
              this.setState({
                meterIdsForChecklistTemplateIds: {
                  ...this.state.meterIdsForChecklistTemplateIds,
                  [checklist_template_row_id]: meter_id,
                },
              });
            }}
          />
        ))}
      </div>
    );
  };

  renderFooter = () => {
    return (
      <Modal.Footer container>
        <Button.Group>
          <Button
            disabled={this.state.isFetching || this.allChecklistTemplateRowsHasSelectedMeter()}
            loading={this.props.isSaving}
            primary
            label="general.save"
            onClick={() => this.save()}
          />

          <Button label="general.cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.checklist-options-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>
          <div className={styles['settings-text']}>
            <FormattedMessage id="components.checklist-options-modal.settings-text" />
          </div>
          {this.renderContent()}
        </Modal.Content>
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { checklistTemplateId, checklistInstanceId, checklistInstanceOptionIds } = ownProps;
  const checklistInstance = EntitySelectors.getChecklistInstance(state, checklistInstanceId);
  let checklistTemplate = EntitySelectors.getChecklistTemplate(state, checklistTemplateId);
  if (checklistInstance) {
    const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    );
  }
  return {
    checklistTemplate,
    checklistInstance,
    checklistInstanceOptions: EntitySelectors.getChecklistInstanceOptions(state, checklistInstanceOptionIds),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistOptionsModal);

ChecklistOptionsModal.propTypes = {
  open: PropTypes.bool,
  isSaving: PropTypes.bool,
  assetId: PropTypes.string,
  checklistTemplateId: PropTypes.string,
  checklistInstanceId: PropTypes.string,
  checklistInstanceOptionIds: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ChecklistOptionsModal.defaultProps = {
  open: false,
  isSaving: false,
  assetId: null,
  checklistTemplateId: null,
  checklistInstanceId: null,
  checklistInstanceOptionIds: [],
  onClose: () => {},
  onSave: () => {},
};
