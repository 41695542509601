import types from './types';

const setChecklistTemplateVersion = payload => ({
  type: types.SET_CHECKLIST_TEMPLATE_VERSION,
  payload: payload,
});

const fetchChecklistTemplateVersion = payload => ({
  type: types.FETCH_CHECKLIST_TEMPLATE_VERSION,
  payload: payload,
});

const fetchChecklistInstancesSuccess = payload => ({
  type: types.FETCH_CHECKLIST_INSTANCES_SUCCESS,
  payload: payload,
});

const fetchChecklistTemplateVersionsSuccess = payload => ({
  type: types.FETCH_CHECKLIST_VERSIONS_SUCCESS,
  payload: payload,
});

export default {
  setChecklistTemplateVersion,
  fetchChecklistTemplateVersion,
  fetchChecklistInstancesSuccess,
  fetchChecklistTemplateVersionsSuccess,
};
