import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class DeleteDowntimeReasonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      confirmDelete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isDeleting: false,
        confirmDelete: false,
      });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteDowntimeReason(this.props.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.downtime-reasons.delete-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  renderSubtitle = () => {
    if (this.props.assetType) {
      const { downtime_count: count } = this.props.assetType;
      if (count === 0) {
        return <FormattedMessage id="screens.settings.assets.downtime-reasons.never-used" />;
      }

      return (
        <FormattedPlural
          value={count}
          one={<FormattedMessage id="screens.settings.assets.downtime-reasons.used-amount.one" />}
          two={
            <FormattedMessage
              id="screens.settings.assets.downtime-reasons.used-amount.two"
              values={{ count }}
            />
          }
          few={
            <FormattedMessage
              id="screens.settings.assets.downtime-reasons.used-amount.few"
              values={{ count }}
            />
          }
          many={
            <FormattedMessage
              id="screens.settings.assets.downtime-reasons.used-amount.many"
              values={{ count }}
            />
          }
          other={
            <FormattedMessage
              id="screens.settings.assets.downtime-reasons.used-amount.other"
              values={{ count }}
            />
          }
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal width={480} isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="screens.settings.assets.downtime-reasons.delete-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
          ignoreLine
        />
        <Modal.Content>
          <div className={styles['message']}>
            <FormattedMessage id="screens.settings.assets.downtime-reasons.delete-modal.confirm-message" />
          </div>
          <Field.Checkbox
            checked={this.state.confirmDelete}
            onChange={value => this.setState({ confirmDelete: value })}
            label={
              <FormattedMessage id="screens.settings.assets.downtime-reasons.delete-modal.confirm-checkbox-text" />
            }
          />
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              destructive
              disabled={!this.state.confirmDelete}
              primary
              label={<FormattedMessage id="general.delete" />}
              loading={this.state.isDeleting}
              onClick={this.delete}
              translate={false}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteDowntimeReason: SDKReduxOperations.deleteDowntimeReason,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    downtimeReason: EntitySelectors.getDowntimeReason(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDowntimeReasonModal);
