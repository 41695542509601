/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getEditingRequest = state => {
  const { newRequest } = state;
  return newRequest.editingRequest;
};
const getEditingRequestBeforeEdit = state => {
  const { newRequest } = state;
  return newRequest.editingRequestBeforeEdit;
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

export default {
  getEditingRequest,
  getEditingRequestBeforeEdit,
};
