import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

const SparePartLocationPath = props => {
  return (
    <div className={`${styles['spare-part-location-path']}`}>
      {props.sparePartLocationPath.map((spl, i) => {
        if (props.fullPath === false && spl.id === props.sparePartLocationId) {
          return null;
        }
        return (
          <>
            {i === 0 ? null : <Icon type="arrow-alt-right" />}
            <span>{spl.title}</span>
          </>
        );
      })}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocationPath: EntitySelectors.getSparePartLocationPathForSparePartLocationId(
      state,
      ownProps.sparePartLocationId
    ),
  };
}

export default connect(mapStateToProps)(SparePartLocationPath);

SparePartLocationPath.propTypes = {
  sparePartLocationId: PropTypes.string,
  fullPath: PropTypes.bool,
};
SparePartLocationPath.defaultProps = {
  sparePartLocationId: null,
  fullPath: true,
};
