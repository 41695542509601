import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { RequestTypeInlineModal } from 'views/components/Request';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from '../style.module.scss';

class RequestTypeField extends Component {
  renderSelectedRequestTypes = () => {
    if (this.props.requestTypes.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.props.requestTypes.reduce(
          (accu, requestType) =>
            accu === null
              ? [<div className={styles['selected-item']}>{requestType.title}</div>]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>{requestType.title}</div>,
                ],
          null
        )}
      </div>
    );
  };

  render() {
    return (
      <Field
        view={false}
        label={
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.request-type.title" />
        }
      >
        <RequestTypeInlineModal
          multiple
          trigger={
            <Field.Resource
              angleDown
              error={this.props.hasError}
              value={this.renderSelectedRequestTypes()}
              onClear={() => this.props.onClear()}
            />
          }
          selectedRequestTypeIds={this.props.ids}
          onAddRequestType={id => this.props.onAddRequestType(id)}
          onRemoveRequestType={id => this.props.onRemoveRequestType(id)}
          onClearRequestType={() => this.props.onClear()}
        />
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    requestTypes: EntitySelectors.getRequestTypes(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(RequestTypeField);
