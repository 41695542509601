import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, NewSearchField } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditAssetTypeModal from './EditAssetTypeModal';
import DeleteAssetTypeModal from './DeleteAssetTypeModal';
import styles from './style.module.scss';

class Types extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewInitialized: false,
      showAssetTypeModal: false,
      showAssetTypeModalForId: null,
      searchTerm: '',
    };
  }

  componentDidMount() {
    this.props.fetchAssetTypes(this.props.selectedSystem.id, { asset_count: true }).then(() => {
      this.setState({ viewInitialized: true });
    });
  }

  listIsEmpty = () =>
    this.props.assetTypes.length === 0 &&
    this.props.createdAssetTypes.length === 0 &&
    this.state.searchTerm.length === 0;

  renderSearchedEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      modal
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.assets.types.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() => this.setState({ showAssetTypeModal: true, showAssetTypeModalForId: null })}
          label="screens.settings.assets.types.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header background small>
        <List.Header.Column flex>
          <FormattedMessage id="resources.asset-type.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <React.Fragment>
      <Toolbar
        buttons={
          <Button
            primary
            onClick={() => this.setState({ showAssetTypeModal: true, showAssetTypeModalForId: null })}
            label="screens.settings.assets.types.add-button"
          />
        }
        search={
          <NewSearchField
            value={this.state.searchTerm}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            onSearch={searchTerm => this.setState({ searchTerm })}
          />
        }
      />
      {this.renderHeader()}
      <List>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderRightColumn = assetType => {
    const { asset_count: count } = assetType;
    if (count === 0) {
      return (
        <span className={`${styles['asset-count']} ${styles['never-used']}`}>
          <FormattedMessage id="screens.settings.assets.types.never-used" />
        </span>
      );
    }
    return (
      <List.Item.Column alignRight>
        <span className={styles['asset-count']}>
          <FormattedPlural
            value={count}
            one={<FormattedMessage id="screens.settings.assets.types.used-amount.one" />}
            two={<FormattedMessage id="screens.settings.assets.types.used-amount.two" values={{ count }} />}
            few={<FormattedMessage id="screens.settings.assets.types.used-amount.few" values={{ count }} />}
            many={<FormattedMessage id="screens.settings.assets.types.used-amount.many" values={{ count }} />}
            other={
              <FormattedMessage id="screens.settings.assets.types.used-amount.other" values={{ count }} />
            }
          />
        </span>
      </List.Item.Column>
    );
  };

  renderAssetTypes = () => {
    const filteredAssetTypes = this.props.assetTypes.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const filteredCreatedAssetTypes = this.props.createdAssetTypes.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const foundNoSearchResult = filteredCreatedAssetTypes.length === 0 && filteredAssetTypes.length === 0;
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() => this.setState({ showAssetTypeModal: true, showAssetTypeModalForId: null })}
              label="screens.settings.assets.types.add-button"
            />
          }
          search={
            <NewSearchField
              value={this.state.searchTerm}
              placeholder={this.props.intl.formatMessage({
                id: 'general.search-placeholder',
              })}
              onSearch={searchTerm => {
                this.setState({ searchTerm });
              }}
              onClear={() => this.setState({ searchTerm: '' })}
            />
          }
        />
        {foundNoSearchResult ? (
          this.renderSearchedEmptyDataset()
        ) : (
          <>
            {this.renderHeader()}
            <List small>
              {filteredCreatedAssetTypes.map(assetType => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showAssetTypeModal: true,
                      showAssetTypeModalForId: assetType.id,
                    })
                  }
                >
                  <List.Item.TitleColumn title={assetType.title} />
                  {this.renderRightColumn(assetType)}
                </List.Item>
              ))}
              {filteredAssetTypes.map(assetType => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showAssetTypeModal: true,
                      showAssetTypeModalForId: assetType.id,
                    })
                  }
                >
                  <List.Item.TitleColumn title={assetType.title} />
                  {this.renderRightColumn(assetType)}
                </List.Item>
              ))}
            </List>
          </>
        )}
      </>
    );
  };

  renderContent() {
    if (!this.state.viewInitialized) {
      return this.renderLoader();
    } else if (this.listIsEmpty()) {
      return this.renderEmptyDataset();
    } else {
      return this.renderAssetTypes();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/assets" />
        <Section
          title={<FormattedMessage id="screens.settings.assets.types.title" />}
          subtitle={<FormattedMessage id="screens.settings.assets.types.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditAssetTypeModal
          open={this.state.showAssetTypeModal}
          id={this.state.showAssetTypeModalForId}
          onDelete={assetTypeId => {
            this.setState({
              showAssetTypeModal: false,
            });
            setTimeout(() => {
              this.setState({
                showDeleteAssetTypeModalForAssetTypeId: assetTypeId,
                showDeleteAssetTypeModal: true,
              });
            }, 250);
          }}
          onClose={() => this.setState({ showAssetTypeModal: false })}
        />
        <DeleteAssetTypeModal
          open={this.state.showDeleteAssetTypeModal}
          id={this.state.showDeleteAssetTypeModalForAssetTypeId}
          onClose={() =>
            this.setState({
              showDeleteAssetTypeModal: false,
            })
          }
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAssetTypes: SettingsOperations.fetchAssetTypes,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    createdAssetTypes: SettingsSelectors.getCreatedAssetTypes(state),
    assetTypes: SettingsSelectors.getAssetTypes(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Types));
