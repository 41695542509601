const DOMAIN_PREFIX = 'meter/';

const FETCH_METER_FOR_VIEW = `${DOMAIN_PREFIX}fetch_meter_for_view`;
const FETCH_METER_FOR_VIEW_SUCCESS = `${DOMAIN_PREFIX}fetch_meter_for_view_success`;
const LIST_METER_READINGS_SUCCESS = `${DOMAIN_PREFIX}list_meter_readings_success`;

export default {
  FETCH_METER_FOR_VIEW,
  FETCH_METER_FOR_VIEW_SUCCESS,
  LIST_METER_READINGS_SUCCESS,
};
