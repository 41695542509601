import actions from './actions';
import { normalizeAttachment } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API, HelperFunctions } from 'sdk';

const setFolderId = actions.setFolderId;
const setAssetId = actions.setAssetId;

const fetchListAttachments =
  (assetId, params = {}) =>
  dispatch => {
    dispatch(
      actions.fetchListAttachmentsBegin({
        initialLoad: params.paginate_from == null,
      })
    );

    return API.listAttachmentsForAsset(assetId, { ...params, page_size: 20 }).then(res => {
      const { entities, result } = normalizeAttachment(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      if (params.paginate_from) {
        dispatch(
          actions.fetchMoreListAttachmentsSuccess({
            ids: result,
            paginateFrom: res.headers['paginate-from-token'],
            isFullyLoaded: res.data.length < 20,
          })
        );
      } else {
        dispatch(
          actions.fetchListAttachmentsSuccess({
            ids: result,
            paginateFrom: res.headers['paginate-from-token'],
            totalEntries: pagination.totalEntries,
            isFullyLoaded: res.data.length < 20,
          })
        );
      }

      return res.data;
    });
  };

const fetchSearchAttachments =
  (assetId, params = {}) =>
  dispatch => {
    return API.listAttachmentsForAsset(assetId, { ...params, page_size: 20 }).then(res => {
      const { entities, result } = normalizeAttachment(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      if (params.paginate_from) {
        dispatch(
          actions.fetchMoreSearchAttachmentsSuccess({
            ids: result,
            paginateFrom: res.headers['paginate-from-token'],
            isFullyLoaded: res.data.length < 20,
          })
        );
      } else {
        dispatch(
          actions.fetchSearchAttachmentsSuccess({
            ids: result,
            paginateFrom: res.headers['paginate-from-token'],
            totalEntries: pagination.totalEntries,
            isFullyLoaded: res.data.length < 20,
          })
        );
      }

      return res.data;
    });
  };

export default {
  setAssetId,
  setFolderId,
  fetchListAttachments,
  fetchSearchAttachments,
};
