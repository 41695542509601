import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { WorkOrderCategoryType } from 'sdk/System';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import {
  WorkOrderTypeInlineModal,
  ChoosePriorityInlineModal,
  CategoryInlineModal,
} from 'views/components/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { AssetTypeInlineModal, ChooseAssetInlineModal } from 'views/components/Asset';
import WorkOrderTypeContainer from './WorkOrderTypeContainer';
import AssetTypeContainer from './AssetTypeContainer';
import AssigneeContainer from './AssigneeContainer';
import AssetContainer from './AssetContainer';
import styles from './style.module.scss';

class AdvancedFilter extends Component {
  getInitialFilters = () => ({
    category: null,
    asset_id: null,
    asset_with_tree_children_id: null,
    work_order_type_ids: null,
    work_order_priorities: null,
    user_id: null,
    vendor_id: null,
    asset_type_ids: null,
    work_order_is_recurring: null,
    work_order_completed_before_registration: null,
  });

  constructor(props) {
    super(props);

    this.state = this.getInitialFilters();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        category,
        work_order_type_ids,
        work_order_priorities,
        asset_type_ids,
        user_id,
        vendor_id,
        asset_id,
        asset_with_tree_children_id,
        work_order_is_recurring,
        work_order_completed_before_registration,
      } = this.props.params;
      this.setState({
        category,
        work_order_type_ids,
        work_order_priorities,
        asset_type_ids,
        user_id,
        vendor_id,
        asset_id,
        asset_with_tree_children_id,
        work_order_is_recurring,
        work_order_completed_before_registration,
      });
    }
  }

  renderSelectedPriority = () => {
    switch (this.state.work_order_priorities) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderSelectedCategory = () => {
    switch (this.state.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderRecurringAndCompletedBeforeRegistation = () => {
    return (
      <>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              label={this.props.intl.formatMessage({
                id: 'components.reported-time-overview.filters.recurring',
              })}
              checked={this.state.work_order_is_recurring != null}
              onChange={work_order_is_recurring => {
                this.setState({
                  work_order_is_recurring: work_order_is_recurring ? work_order_is_recurring : null,
                  work_order_completed_before_registration: null,
                });
              }}
            />
            <div className={styles['checkbox']}>
              <Field.Checkbox
                label={this.props.intl.formatMessage({
                  id: 'components.reported-time-overview.filters.completed-before-registration',
                })}
                checked={this.state.work_order_completed_before_registration != null}
                onChange={work_order_completed_before_registration => {
                  this.setState({
                    work_order_is_recurring: null,
                    work_order_completed_before_registration: work_order_completed_before_registration
                      ? work_order_completed_before_registration
                      : null,
                  });
                }}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  renderWorkOrderCategory = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.work-order.category" />}>
            <CategoryInlineModal
              trigger={
                <Field.Resource
                  angleDown
                  value={this.renderSelectedCategory()}
                  onClear={() => this.setState({ category: null })}
                />
              }
              selectedCategory={this.state.category}
              onSelect={category => this.setState({ category })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderAssetFilterRow = () => {
    const assetId = this.state.asset_id || this.state.asset_with_tree_children_id;
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.requests-overview.filters.asset" />}>
            <ChooseAssetInlineModal
              hideCreateButton
              width="300"
              trigger={
                <Field.Resource
                  angleDown
                  value={assetId == null ? null : <AssetContainer id={assetId} />}
                  onClear={() => this.setState({ asset_id: null, asset_with_tree_children_id: null })}
                />
              }
              selectedAssetId={assetId}
              onSelectAsset={id => {
                this.setState({ asset_with_tree_children_id: id });
              }}
              onClear={() => this.setState({ asset_id: null, asset_with_tree_children_id: null })}
            />
          </Field>
          {this.state.asset_id || this.state.asset_with_tree_children_id ? (
            <div className={styles['checkbox']}>
              <Field.Checkbox
                checked={this.state.asset_with_tree_children_id != null}
                label={
                  <FormattedMessage id="components.requests-overview.filters.include-underlying-assets-label" />
                }
                onChange={() => {
                  if (this.state.asset_with_tree_children_id) {
                    this.setState({
                      asset_id: this.state.asset_with_tree_children_id,
                      asset_with_tree_children_id: null,
                    });
                  } else {
                    this.setState({ asset_id: null, asset_with_tree_children_id: this.state.asset_id });
                  }
                }}
              />
            </div>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        width={485}
        footerComponent={
          <Button.Group>
            <Button
              primary
              onClick={() => this.props.onClose(this.state)}
              label="components.reported-time-overview.filter-modal-title"
            />
            <Button
              translate={false}
              disabled={this.props.amountOfAppliedAdvancedFilters === 0}
              label={
                <div className={styles['number-container']}>
                  <FormattedMessage id="general.clean" />
                  {this.props.amountOfAppliedAdvancedFilters === 0 ? null : (
                    <div className={styles['number']}>{this.props.amountOfAppliedAdvancedFilters}</div>
                  )}
                </div>
              }
              onClick={() => {
                this.setState(this.getInitialFilters(), () => this.props.onClose(this.state));
              }}
            />
          </Button.Group>
        }
        onClose={() => this.props.onClose(this.state)}
      >
        <SideModal.Container>
          <SideModal.Container.Content>
            <SideModal.Header
              title={<FormattedMessage id="components.reported-time-overview.filter-modal-title" />}
              subtitle={<FormattedMessage id="components.reported-time-overview.filter-modal-subtitle" />}
              onClose={() => this.props.onClose(this.state)}
            />
            <Grid>
              {this.renderAssetFilterRow()}
              <Grid.Row>
                <Grid.Column>
                  <Field
                    label={<FormattedMessage id="components.reported-time-overview.filters.asset-type" />}
                  >
                    <AssetTypeInlineModal
                      editable={false}
                      trigger={
                        <Field.Resource
                          angleDown
                          value={
                            this.state.asset_type_ids == null ? null : (
                              <AssetTypeContainer id={this.state.asset_type_ids} />
                            )
                          }
                          onClear={() => this.setState({ asset_type_ids: null })}
                        />
                      }
                      selectedAssetTypeId={this.state.asset_type_ids}
                      onSelectAssetType={id => this.setState({ asset_type_ids: id })}
                      onClearAssetType={() => this.setState({ asset_type_ids: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>

              {this.renderWorkOrderCategory()}

              <Grid.Row>
                <Grid.Column>
                  <Field
                    label={
                      <FormattedMessage id="components.reported-time-overview.filters.work-order-type" />
                    }
                  >
                    <WorkOrderTypeInlineModal
                      editable={false}
                      trigger={
                        <Field.Resource
                          angleDown
                          value={
                            this.state.work_order_type_ids == null ? null : (
                              <WorkOrderTypeContainer id={this.state.work_order_type_ids} />
                            )
                          }
                          onClear={() => this.setState({ work_order_type_ids: null })}
                        />
                      }
                      selectedWorkOrderTypeId={this.state.work_order_type_ids}
                      onSelectWorkOrderType={id => this.setState({ work_order_type_ids: id })}
                      onClear={() => this.setState({ work_order_type_ids: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field label={<FormattedMessage id="components.reported-time-overview.filters.priority" />}>
                    <ChoosePriorityInlineModal
                      trigger={
                        <Field.Resource
                          angleDown
                          value={this.renderSelectedPriority()}
                          onClear={() => this.setState({ work_order_priorities: null })}
                        />
                      }
                      selectedPriority={this.state.work_order_priorities}
                      onSelectPriority={priority => this.setState({ work_order_priorities: priority })}
                      onClear={() => this.setState({ work_order_priorities: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    label={<FormattedMessage id="components.reported-time-overview.filters.registered-by" />}
                  >
                    <AssigneeContainer
                      userId={this.state.user_id}
                      vendorId={this.state.vendor_id}
                      onAddUser={user => this.setState({ user_id: user.id, vendor_id: null })}
                      onRemoveUser={user => this.setState({ user_id: null, vendor_id: null })}
                      onAddVendor={vendor => this.setState({ user_id: null, vendor_id: vendor.id })}
                      onRemoveVendor={vendor => this.setState({ user_id: null, vendor_id: null })}
                      onClear={() => this.setState({ user_id: null, vendor_id: null })}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              {this.renderRecurringAndCompletedBeforeRegistation()}
            </Grid>
          </SideModal.Container.Content>
        </SideModal.Container>
      </SideModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps)(AdvancedFilter));
