import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { CardGrid } from 'views/components/Shared/Layout';
import { SectionHeader } from 'views/components/Shared/General';
import Attachment from './Attachment';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class AttachmentsContainer extends Component {
  render() {
    const { attachments } = this.props;
    if (attachments.length === 0) return null;
    return (
      <CardGrid.Row>
        <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
          <FormattedMessage id="screens.operational-maintenance.attachments.title" />
        </SectionHeader>
        <div className={styles['cards']}>
          {attachments.map(attachment => {
            return <Attachment attachment={attachment} />;
          })}
        </div>
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const { operational_maintenance_template_version_id } = operationalMaintenanceInstance;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operational_maintenance_template_version_id
  );
  return {
    attachments: EntitySelectors.getAttachments(state, operationalMaintenanceTemplateVersion.attachments),
  };
}

export default connect(mapStateToProps)(AttachmentsContainer);
