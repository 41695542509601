import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { EditOperationalMaintenanceModal } from 'views/components/OperationalMaintenance';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import DeleteModal from './DeleteModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showEditOperationalMaintenanceTemplateModal: false,
    showEditOperationalMaintenanceTemplateModalForId: null,
    showDropdown: false,
    showDeleteModal: false,
  };

  getLinkForBreadCrumb = () => {
    return {
      pathname: '/operational-maintenances/templates',
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.work-order.operational-maintenance-breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>
          {this.props.operationalMaintenanceTemplateVersion.title}
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderCreateButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <div className={styles['button']}>
          <Button.Group>
            <Button
              gray
              label="screens.operational-maintenance-template.edit-template"
              onClick={() => {
                this.setState({
                  showEditOperationalMaintenanceTemplateModal: true,
                  showEditOperationalMaintenanceTemplateModalForId: this.props.match.params.id,
                });
              }}
            />
            <div ref={ref => (this.inlineModalPositioningRef = ref)}>
              <Button
                gray
                icon={<Icon regular size={16} type="ellipsis-h" />}
                onClick={() =>
                  this.setState(prevState => ({
                    showDropdown: !prevState.showDropdown,
                  }))
                }
              />
            </div>
            <NewInlineModal
              minWidth={250}
              positionToRef={this.inlineModalPositioningRef}
              open={this.state.showDropdown}
              onClose={() => this.setState({ showDropdown: false })}
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={e => this.setState({ showDeleteModal: true, showDropdown: false })}
                  >
                    <FormattedMessage id="general.delete" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </Button.Group>
        </div>
      );
    }
    return null;
  };

  renderTitle = () => {
    return (
      <div className={styles['header-title']}>
        <div className={styles['title']}>{this.props.operationalMaintenanceTemplateVersion.title}</div>
        {this.renderCreateButton()}
      </div>
    );
  };

  renderEditOperationalMaintenanceModal = () => {
    return (
      <EditOperationalMaintenanceModal
        open={this.state.showEditOperationalMaintenanceTemplateModal}
        operationalMaintenanceTemplateId={this.state.showEditOperationalMaintenanceTemplateModalForId}
        onCreated={() => {
          this.props.onCreatedNewVersion();
          this.setState({ showEditOperationalMaintenanceTemplateModal: false });
        }}
        onClose={() => this.setState({ showEditOperationalMaintenanceTemplateModal: false })}
      />
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteModal
        open={this.state.showDeleteModal}
        id={this.props.match.params.id}
        onDelete={() => {
          this.props.history.push(this.getLinkForBreadCrumb());
        }}
        onClose={() => this.setState({ showDeleteModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <LayoutHeader
          maxWidth={1055}
          title={this.renderTitle()}
          titleHoverText={this.props.operationalMaintenanceTemplateVersion.title}
          breadcrumbComponent={this.renderBreadcrumb()}
        />
        {this.renderEditOperationalMaintenanceModal()}
        {this.renderDeleteModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    operationalMaintenanceTemplateVersion:
      OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
