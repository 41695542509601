import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API } from 'sdk';
import { normalizeWorkOrderType } from 'sdk/Schemas';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class WorkOrderTypeInlineModal extends Component {
  getInititalState = () => ({
    isEmpty: false,
    isFetching: true,
    isOpen: false,
    workOrderTypes: [],
    searchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      return;
    }
    this.fetchWorkOrderTypes();
  };

  fetchWorkOrderTypes = () => {
    this.setState({ ...this.getInititalState(), isOpen: true, isFetching: true });
    API.listWorkOrderTypes(this.props.system.id, { no_pagination: true }).then(({ data: workOrderTypes }) => {
      const { entities, result } = normalizeWorkOrderType(workOrderTypes);
      this.props.updateEntities(entities);
      this.setState(
        {
          isEmpty: workOrderTypes.length === 0,
          isFetching: false,
          workOrderTypes: result.map(id => entities.workOrderTypeById[id]),
        },
        () => {
          if (this.inlineModalSearchRef) {
            this.inlineModalSearchRef.focus();
          }
        }
      );
    });
  };

  selectWorkOrderType = workOrderTypeId => {
    if (this.props.multiple) {
      if (this.props.selectedWorkOrderTypeIds.includes(workOrderTypeId)) {
        this.props.onRemoveWorkOrderType(workOrderTypeId);
      } else {
        this.props.onAddWorkOrderType(workOrderTypeId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectWorkOrderType(workOrderTypeId);
        }
      );
    }
  };

  renderWorkOrderTypeItem = workOrderType => {
    if (this.props.multiple) {
      const selected = this.props.selectedWorkOrderTypeIds.includes(workOrderType.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={workOrderType.id}
          onClick={e => this.selectWorkOrderType(workOrderType.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectWorkOrderType(workOrderType.id);
              }}
            />
          }
        >
          {workOrderType.title}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedWorkOrderTypeId === workOrderType.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={workOrderType.id}
          selected={selected}
          onClick={e => this.selectWorkOrderType(workOrderType.id)}
        >
          <span>{workOrderType.title}</span>
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderClearItem = () => {
    const { clearable, multiple, selectedWorkOrderTypeId } = this.props;
    if (clearable && multiple === false && selectedWorkOrderTypeId) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => {
              this.setState({ isOpen: false });
              this.props.onClear();
            }}
          >
            <FormattedMessage id="general.clean" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  renderWorkOrderTypes = () => {
    const { searchTerm } = this.state;
    const filteredWorkOrderTypes = this.state.workOrderTypes.filter(
      wot => searchTerm === '' || wot.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredWorkOrderTypes.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return filteredWorkOrderTypes.map(workOrderType => this.renderWorkOrderTypeItem(workOrderType));
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    if (this.state.isEmpty) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.work-order-type-inline-modal.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          {this.renderClearItem()}
          {this.renderWorkOrderTypes()}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  renderHeader = () => {
    if (this.state.workOrderTypes.length > 10) {
      return (
        <NewInlineModal.Header>
          <NewInlineModal.Header.Search
            ref={ref => (this.inlineModalSearchRef = ref)}
            placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
            value={this.state.searchTerm}
            onChange={searchTerm => {
              this.setState({ searchTerm });
            }}
            onClear={() => {
              this.setState({ searchTerm: '' });
            }}
          />
        </NewInlineModal.Header>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderHeader()}
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WorkOrderTypeInlineModal));

WorkOrderTypeInlineModal.propTypes = {
  selectedWorkOrderTypeId: PropTypes.string,
  selectedWorkOrderTypeIds: PropTypes.array,
  onSelectWorkOrderType: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
};

WorkOrderTypeInlineModal.defaultProps = {
  selectedWorkOrderTypeId: null,
  selectedWorkOrderTypeIds: [],
  onSelectWorkOrderType: () => {},
  onAddWorkOrderType: () => {},
  onRemoveWorkOrderType: () => {},
  onClear: () => {},
  multiple: false,
  clearable: true,
};
