import { BookmarkedAssetsDropdownUtils } from 'state/ducks/bookmarkedAssetsDropdown';
/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getDropdownType = state => {
  const { bookmarkedAssetsDropdown } = state;
  return bookmarkedAssetsDropdown.assetDropdown.selectedType;
};

const getAssetId = state => {
  const { bookmarkedAssetsDropdown } = state;
  return bookmarkedAssetsDropdown.assetDropdown.assetId;
};

const getBookmarkedAssets = state => {
  const { bookmarkedAssetsDropdown, entities } = state;
  return bookmarkedAssetsDropdown.bookmarkedAssetIds.map(id => entities.assetBookmarkById[id]);
};

const getParams = state => {
  const { bookmarkedAssetsDropdown, entities } = state;
  const { assetBookmarkById } = entities;
  if (bookmarkedAssetsDropdown.assetDropdown.assetId) {
    return bookmarkedAssetsDropdown.assetDropdown.assetId;
  }
  if (
    bookmarkedAssetsDropdown.assetDropdown.selectedType === BookmarkedAssetsDropdownUtils.MenuItem.MyAssets
  ) {
    return bookmarkedAssetsDropdown.bookmarkedAssetIds
      .map(id => {
        const { asset_id } = assetBookmarkById[id];
        return asset_id;
      })
      .join(',');
  }
  return null;
};

export default {
  getBookmarkedAssets,
  getDropdownType,
  getAssetId,
  getParams,
};
