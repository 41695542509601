import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Icon, MoneyWithCurrency } from 'views/components/Shared/General';
import styles from './style.module.scss';
import Decimal from 'decimal.js';
import { AuthSelectors } from 'state/ducks/auth';
import { connect } from 'react-redux';

class PriceField extends Component {
  renderLabel = () => {
    return (
      <>
        <FormattedMessage id="resources.purchase-order-row.price" />
        <span className={styles['price-currency-button']} onClick={this.props.onClickCurrency}>
          <FormattedMessage id="components.change-purchase-order-currency-modal.title" />
        </span>
      </>
    );
  };

  renderConvertionRateAndLocalCurrency = () => {
    const { price, currency, exchangeRate } = this.props;
    const { currency: systemCurrency } = this.props.currentSystem;

    if (currency !== systemCurrency) {
      let localValue;
      try {
        localValue = new Decimal(price).times(new Decimal(exchangeRate)).toDecimalPlaces(2).toString();
      } catch (e) {
        localValue = 0;
      }

      return (
        <div className={styles['convertion-rate-description-container']}>
          <div className={styles['convertion-rate']}>
            <Field
              fontSize={12}
              view
              singleRow
              label={<FormattedMessage id="resources.spare-part.currency-conversion-rate" />}
            >
              {new Decimal(exchangeRate || 0).toDecimalPlaces(4).toString()}
            </Field>
            <div className={styles['icon-button']} onClick={this.props.onClickCurrency}>
              <Icon regular size={11} type="pen" />
            </div>
          </div>
          <div className={styles['system-currency']}>
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <Field
        view={false}
        label={this.renderLabel()}
        description={this.renderConvertionRateAndLocalCurrency()}
      >
        <Field.Money
          currency={this.props.currency}
          value={this.props.price}
          onChange={value => this.props.onChange(value)}
          onBlur={value => this.props.onBlur(value)}
        />
      </Field>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(PriceField);
