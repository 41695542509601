import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Pagination } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';
import { normalizeSparePartStockActivity } from 'sdk/Schemas';
import SparePartStockActivity from './SparePartStockActivity';
import styles from './styles.module.scss';

class StockActivitiesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sparePartStockActivites: [],
      isFetching: false,
      isChangingPage: false,
      totalPages: 1,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      this.listSparePartStockActivities({ page_size: 5 }).then(({ totalPages, sparePartStockActivites }) => {
        this.setState({
          totalPages,
          sparePartStockActivites,
          isFetching: false,
        });
      });
    }
  }

  changePage = page => {
    this.setState({ page, isChangingPage: true });
    this.listSparePartStockActivities({ page_size: 5, page }).then(
      ({ totalPages, sparePartStockActivites }) => {
        this.setState({
          totalPages,
          sparePartStockActivites,
          isChangingPage: false,
        });
      }
    );
  };

  listSparePartStockActivities = params => {
    return API.listSparePartStockActivities(this.props.sparePart.id, params).then(
      ({ data: sparePartStockActivities, headers }) => {
        const { entities, result } = normalizeSparePartStockActivity(sparePartStockActivities);
        this.props.updateEntities(entities);
        const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
        return {
          totalPages,
          sparePartStockActivites: result.map(id => entities.sparePartStockActivityById[id]),
        };
      }
    );
  };

  renderSparePartStockActivities = () => (
    <React.Fragment>
      {this.state.isChangingPage ? (
        <List light>
          {Array(this.state.sparePartStockActivites.length)
            .fill()
            .map(() => (
              <List.Item>
                <List.Item.ImageColumn loading />
                <List.Item.TitleColumn loading />
              </List.Item>
            ))}
        </List>
      ) : (
        <List light>
          {this.state.sparePartStockActivites.map(sparePartStockActivity => (
            <SparePartStockActivity
              sparePart={this.props.sparePart}
              sparePartStockActivity={sparePartStockActivity}
              key={sparePartStockActivity.id}
            />
          ))}
        </List>
      )}
      {this.state.totalPages > 1 ? (
        <div className={styles['pagination-container']}>
          <Pagination
            hideOptions
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onSelectPage={page => {
              this.changePage(page);
            }}
          />
        </div>
      ) : null}
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={750}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.stock-history-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content loading={this.state.isFetching}>{this.renderSparePartStockActivities()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(StockActivitiesModal);
