import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { EntitySelectors } from 'sdk/State/entities';
import { List, SectionHeader } from 'views/components/Shared/General';
import { AttachmentListItem } from 'views/components/Attachment';

class AttachmentsContainer extends Component {
  render() {
    const { registrationAttachments } = this.props;
    if (registrationAttachments.length === 0) return null;
    return (
      <>
        <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
          <FormattedMessage id="screens.work-order.edit.attachment-title" />
        </SectionHeader>
        <List>
          {registrationAttachments.map(attachment => {
            return (
              <AttachmentListItem
                hideFolder
                attachment={attachment}
                type="workOrder"
                hideCreatedAt
                onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
              />
            );
          })}
        </List>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    registrationAttachments: EntitySelectors.getAttachments(
      state,
      ownProps.workOrder.registration_attachments
    ),
  };
}

export default connect(mapStateToProps)(AttachmentsContainer);
