import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.operationalMaintenanceTemplateAssetById) {
        return HelperFunctions.mergeEntities(state, action.payload.operationalMaintenanceTemplateAssetById);
      }

      return state;
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_TEMPLATE_RECURRENCE_CREATED_FOR_TEMPLATE_ASSET: {
      const { templateAssetId, params, data } = action.payload;

      if (state[templateAssetId]) {
        if (state[templateAssetId].operational_maintenance_template_recurrences) {
          return {
            ...state,
            [templateAssetId]: {
              ...state[templateAssetId],
              operational_maintenance_template_recurrences: [
                data.id,
                ...state[templateAssetId].operational_maintenance_template_recurrences,
              ],
            },
          };
        } else {
          return {
            ...state,
            [templateAssetId]: {
              ...state[templateAssetId],
              operational_maintenance_template_recurrences: [data.id],
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_TEMPLATE_RECURRENCE_DELETED: {
      const { recurrenceId, meta } = action.payload;
      const { operational_maintenance_template_asset_id } = meta;
      if (state[operational_maintenance_template_asset_id]) {
        if (state[operational_maintenance_template_asset_id].operational_maintenance_template_recurrences) {
          return {
            ...state,
            [operational_maintenance_template_asset_id]: {
              ...state[operational_maintenance_template_asset_id],
              operational_maintenance_template_recurrences: state[
                operational_maintenance_template_asset_id
              ].operational_maintenance_template_recurrences.filter(id => id !== recurrenceId),
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.CHECKLIST_INSTANCE_OPTION_CREATED_TEMPLATE_ASSET: {
      const { templateAssetId, data } = action.payload;
      if (state[templateAssetId]) {
        if (state[templateAssetId].checklist_instance_options) {
          return {
            ...state,
            [templateAssetId]: {
              ...state[templateAssetId],
              checklist_instance_options: [data.id, ...state[templateAssetId].checklist_instance_options],
            },
          };
        } else {
          return {
            ...state,
            [templateAssetId]: {
              ...state[templateAssetId],
              checklist_instance_options: [data.id],
            },
          };
        }
      }
      return state;
    }

    default:
      return state;
  }
};
