import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const LeftContent = props => {
  return (
    <div className={styles['left-content']}>{props.children}</div>
  );
};

export default LeftContent;

LeftContent.propTypes = {
  children: PropTypes.node,
};

LeftContent.defaultProps = {
  children: null,
};
