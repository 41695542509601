import { UserType } from 'sdk/SystemAccess';
import { SparePartWithdrawalType } from 'sdk/SparePartWithdrawal';
import { SystemTier } from 'sdk/System';
/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getCurrentOrganisation = state => {
  const { entities, auth } = state;
  return entities.organisationById[auth.organisationId];
};

const getCurrentSystem = state => {
  const { entities, auth } = state;
  return entities.systemById[auth.systemId];
};

const getSystems = state => {
  const { entities, auth } = state;
  return auth.systemIds.map(id => entities.systemById[id]);
};

const hasEnterpriseTier = state => {
  const organisation = getCurrentOrganisation(state);
  if (organisation) {
    if (organisation.tier === SystemTier.Enterprise) {
      return true;
    }
    return false;
  }
};

const hasProTier = state => {
  const organisation = getCurrentOrganisation(state);
  if (organisation) {
    if (organisation.tier === SystemTier.Enterprise) {
      return true;
    }
    if (organisation.tier === SystemTier.Pro) {
      return true;
    }
    return false;
  }
};

const getCurrentUser = state => {
  const { entities, auth } = state;
  return entities.userById[auth.userId];
};

const getLanguage = state => {
  return getCurrentUser(state) == null ? 'sv' : getCurrentUser(state).language;
};

const getSettings = state => {
  const { auth } = state;
  return auth.settings;
};

const getCurrentUserSettings = state => {
  const { auth } = state;
  return auth.userSettings;
};

const getOrganisationSettings = state => {
  const { auth } = state;
  return auth.organisationSettings;
};

const getPurchaseOrderDeliveryMethods = state => {
  const { entities, auth } = state;
  return auth.purchaseOrderDeliveryMethodIds
    .map(id => entities.purchaseOrderDeliveryMethodById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getPurchaseOrderPaymentTerms = state => {
  const { entities, auth } = state;
  return auth.purchaseOrderPaymentTermIds
    .map(id => entities.purchaseOrderPaymentTermById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getSparePartUnits = state => {
  const { entities, auth } = state;
  return auth.sparePartUnitIds
    .map(id => entities.sparePartUnitById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getMeterUnits = state => {
  const { entities, auth } = state;
  return auth.meterUnitIds
    .map(id => entities.meterUnitById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getOtherCostCategories = state => {
  const { entities, auth } = state;
  return auth.costOtherCategoryIds
    .map(id => entities.costOtherCategoryById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getWorkOrderSpentTimeCategories = state => {
  const { entities, auth } = state;
  return auth.workOrderSpentTimeCategoryIds
    .map(id => entities.workOrderSpentTimeCategoryById[id])
    .sort((a, b) => a.title.localeCompare(b.title));
};

const getLaborTariffs = state => {
  const { entities, auth } = state;
  return auth.laborTariffIds
    .map(id => entities.laborTariffById[id])
    .sort((a, b) => (a.description || '').localeCompare(b.description || ''));
};

const getAssetTemplateFields = state => {
  const { entities, auth } = state;
  return auth.templateFieldIds
    .map(id => entities.templateFieldById[id])
    .filter(({ template_type }) => template_type === 'asset');
};

const getAssetTypeTemplateFields = (state, assetTypeId) => {
  return getAssetTemplateFields(state).filter(
    ({ asset_type_id }) => asset_type_id === assetTypeId || asset_type_id == null
  );
};

const getSparePartTemplateFields = state => {
  const { entities, auth } = state;
  return auth.templateFieldIds
    .map(id => entities.templateFieldById[id])
    .filter(({ template_type }) => template_type === 'spare_part');
};

/*
  --------------------
    PERMISSION SELECTORS
  --------------------
*/

const getCurrentSystemAccess = state => {
  const { entities } = state;
  const systemAccessId = getCurrentSystem(state).system_access;
  return entities.systemAccessById[systemAccessId];
};

// General -------------------------------------------------------------

const isAdmin = state => getCurrentSystemAccess(state).user_type === UserType.SystemAdmin;
const isOperator = state => getCurrentSystemAccess(state).user_type === UserType.Operator;
const isWorkRequester = state => getCurrentSystemAccess(state).user_type === UserType.WorkRequester;
const isProductionSupervisor = state => {
  return getCurrentSystemAccess(state).user_type === UserType.ProductionSupervisor;
};
const isViewOnly = state => {
  return getCurrentSystemAccess(state).user_type === UserType.ViewOnly;
};

const getPermissionProfile = state => {
  const { entities } = state;
  const permissionProfileId = getCurrentSystemAccess(state).permission_profile_id;
  return entities.permissionProfileById[permissionProfileId];
};

// Asset -------------------------------------------------------------

const canViewAssets = state => {
  if (isOperator(state)) {
    return false;
  }
  if (isWorkRequester(state)) {
    return false;
  }
  return true;
};
const canEditAssets = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.asset === 'edit';
  }
  return false;
};

// Costs -------------------------------------------------------------

const canViewWorkOrderCosts = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.cost === 'full' || permissionProfile.cost === 'work_order';
  }
  return false;
};

const canViewFullCosts = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.cost === 'full';
  }
  return false;
};

// Downtimes -------------------------------------------------------------

const canViewDowntimes = state => {
  if (isWorkRequester(state)) {
    return false;
  }
  return true;
};

const canEditDowntimes = state => {
  if (isViewOnly(state)) {
    return false;
  }
  if (isWorkRequester(state)) {
    return false;
  }
  if (isOperator(state)) {
    const { operator_permission_downtimes } = getSettings(state);
    return operator_permission_downtimes === 'edit';
  }
  return true;
};

const canEditDowntime = (state, downtime) => {
  if (isWorkRequester(state)) {
    return false;
  }
  if (isViewOnly(state)) {
    return false;
  }
  if (isOperator(state)) {
    const { operator_permission_downtimes } = getSettings(state);
    if (
      operator_permission_downtimes === 'edit' &&
      downtime.created_by_user_id === getCurrentUser(state).id
    ) {
      return true;
    }
    return false;
  }
  return true;
};

// Vendor -------------------------------------------------------------

const canViewVendors = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.vendor === 'view' || permissionProfile.vendor === 'edit';
  }
  return false;
};
const canEditVendors = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.vendor === 'edit';
  }
  return false;
};

// Work Order -------------------------------------------------------------

const canViewWorkOrders = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state)) {
    return true;
  }
  if (isOperator(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.work_order === 'carry_out' || permissionProfile.work_order === 'administrate';
  }
  return false;
};

const canCarryOutWorkOrders = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.work_order === 'carry_out' || permissionProfile.work_order === 'administrate';
  }
  return false;
};

const canAdministrateWorkOrders = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.work_order === 'administrate';
  }
  return false;
};

const canEditWorkOrder = (state, workOrder) => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.work_order === 'administrate') {
      return true;
    }
    if (permissionProfile.work_order === 'carry_out') {
      return (
        workOrder &&
        workOrder.completed_before_registration &&
        workOrder.created_by_user_id === getCurrentUser(state).id
      );
    }
  }
  return false;
};

// Request -------------------------------------------------------------

const canViewRequests = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.request === 'create' || permissionProfile.request === 'administrate';
  }
  return false;
};

const canCreateRequests = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state)) {
    const { production_supervisor_permission_requests } = getSettings(state);
    return production_supervisor_permission_requests === 'create';
  }
  if (isOperator(state)) {
    const { operator_permission_requests } = getSettings(state);
    return operator_permission_requests === 'create';
  }
  if (isWorkRequester(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.request === 'administrate' || permissionProfile.request === 'create') {
      return true;
    }
  }
  return false;
};

const canAdministrateRequests = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.request === 'administrate') {
      return true;
    }
  }
  return false;
};

const canEditRequest = (state, request) => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.request === 'administrate') {
      return true;
    }
    if (permissionProfile.request === 'create' || isOperator(state)) {
      return request.created_by_user_id === getCurrentUser(state).id;
    }
  }
  return false;
};

// Meter -------------------------------------------------------------

const canViewMeters = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.meter === 'edit') {
      return true;
    }
    if (permissionProfile.meter === 'edit_reading') {
      return true;
    }
  }
  return false;
};

const canEditMeterReadings = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.meter === 'edit') {
      return true;
    }
    if (permissionProfile.meter === 'edit_reading') {
      return true;
    }
  }
  return false;
};

const canEditMeters = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.meter === 'edit';
  }
  return false;
};

// Spare part -------------------------------------------------------------

const canViewSpareParts = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  if (isProductionSupervisor(state)) {
    return true;
  }
  if (isOperator(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.spare_part === 'stock_adjustments') {
      return true;
    }
    if (permissionProfile.spare_part === 'edit') {
      return true;
    }
  }
  return false;
};

const canCreateWithdrawals = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state)) {
    const { production_supervisor_permission_spare_parts } = getSettings(state);
    return (
      production_supervisor_permission_spare_parts === 'create_withdrawals' ||
      production_supervisor_permission_spare_parts === 'create_withdrawals_and_stock_adjustment'
    );
  }
  if (isOperator(state)) {
    const { operator_permission_spare_parts } = getSettings(state);
    return (
      operator_permission_spare_parts === 'create_withdrawals' ||
      operator_permission_spare_parts === 'create_withdrawals_and_stock_adjustment'
    );
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.spare_part === 'stock_adjustments') {
      return true;
    }
    if (permissionProfile.spare_part === 'edit') {
      return true;
    }
  }
  return false;
};

// TODO: 'create_withdrawals_and_stock_adjustment' is a placeholder, check so it corresponds to backend
// when backend is ready /Martin
const canAdjustStockOnSpareParts = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state)) {
    const { production_supervisor_permission_spare_parts } = getSettings(state);
    return production_supervisor_permission_spare_parts === 'create_withdrawals_and_stock_adjustment';
  }
  if (isOperator(state)) {
    const { operator_permission_spare_parts } = getSettings(state);
    return operator_permission_spare_parts === 'create_withdrawals_and_stock_adjustment';
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.spare_part === 'stock_adjustments') {
      return true;
    }
    if (permissionProfile.spare_part === 'edit') {
      return true;
    }
  }
  return false;
};

const canEditSpareParts = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    return permissionProfile.spare_part === 'edit';
  }
  return false;
};

// Spare part withdrawal -------------------------------------------------------------

const canEditSparePartWithdrawal = (state, sparePartWithdrawal) => {
  const permissionProfile = getPermissionProfile(state);
  const currentUser = getCurrentUser(state);

  // --- Admin ---
  if (isAdmin(state)) {
    return true;
  }

  // --- SparePart: Edit ---
  if (permissionProfile && permissionProfile.spare_part === 'edit') {
    return true;
  }

  // --- SparePart: Stock adjustment ---
  if (permissionProfile && permissionProfile.spare_part === 'stock_adjustments') {
    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.WorkOrder &&
      sparePartWithdrawal.work_order_id != null
    ) {
      const workOrder = state.entities.workOrderById[sparePartWithdrawal.work_order_id];
      // TODO: Implement this: return canCarryOutWorkOrder(state, workOrder);
      return canCarryOutWorkOrders(state);
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.OperationalMaintenanceInstance &&
      sparePartWithdrawal.operational_maintenance_instance_id != null
    ) {
      return canCarryOutOperationalMaintenances(state);
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.Other &&
      sparePartWithdrawal.created_by_user_id === currentUser.id
    ) {
      return true;
    }

    return false;
  }

  // --- Production Supervisor ---
  if (isProductionSupervisor(state)) {
    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.WorkOrder &&
      sparePartWithdrawal.work_order_id != null
    ) {
      const workOrder = state.entities.workOrderById[sparePartWithdrawal.work_order_id];
      // TODO: Implement this: return canCarryOutWorkOrder(state, workOrder);
      return canCarryOutWorkOrders(state);
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.OperationalMaintenanceInstance &&
      sparePartWithdrawal.operational_maintenance_instance_id != null
    ) {
      const { production_supervisor_permission_spare_parts } = getSettings(state);
      return production_supervisor_permission_spare_parts === 'create_withdrawals';
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.Other &&
      sparePartWithdrawal.created_by_user_id === currentUser.id
    ) {
      return true;
    }

    return false;
  }

  // --- Operator ---
  if (isOperator(state)) {
    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.WorkOrder &&
      sparePartWithdrawal.work_order_id != null
    ) {
      const workOrder = state.entities.workOrderById[sparePartWithdrawal.work_order_id];
      // TODO: Implement this: return canCarryOutWorkOrder(state, workOrder);
      return canCarryOutWorkOrders(state);
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.OperationalMaintenanceInstance &&
      sparePartWithdrawal.operational_maintenance_instance_id != null
    ) {
      const { operator_permission_spare_parts } = getSettings(state);
      return operator_permission_spare_parts === 'create_withdrawals';
    }

    if (
      sparePartWithdrawal.type === SparePartWithdrawalType.Other &&
      sparePartWithdrawal.created_by_user_id === currentUser.id
    ) {
      return true;
    }

    return false;
  }

  return false;
};

// Purchase order -------------------------------------------------------------

const canViewPurchaseOrders = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.purchase_order === 'register_deliveries') {
      return true;
    }
    if (permissionProfile.purchase_order === 'edit') {
      return true;
    }
  }
  return false;
};

const canRegisterDeliveries = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.purchase_order === 'register_deliveries') {
      return true;
    }
    if (permissionProfile.purchase_order === 'edit') {
      return true;
    }
  }
  return false;
};

const canEditPurchaseOrders = state => {
  if (isAdmin(state)) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.purchase_order === 'edit') {
      return true;
    }
  }
  return false;
};

// Operational Maintenance -------------------------------------------------------------

const canViewOperationalMaintenances = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isViewOnly(state)) {
    return true;
  }
  if (isProductionSupervisor(state) === true) {
    return true;
  }
  if (isOperator(state) === true) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.operational_maintenance === 'view') {
      return true;
    }
    if (permissionProfile.operational_maintenance === 'carry_out') {
      return true;
    }
    if (permissionProfile.operational_maintenance === 'administrate') {
      return true;
    }
  }
  return false;
};

const canCarryOutOperationalMaintenances = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state) === true) {
    return true;
  }
  if (isOperator(state) === true) {
    return true;
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.operational_maintenance === 'carry_out') {
      return true;
    }
    if (permissionProfile.operational_maintenance === 'administrate') {
      return true;
    }
  }
  return false;
};

const canAdministrateOperationalMaintenances = state => {
  if (isAdmin(state)) {
    return true;
  }
  if (isProductionSupervisor(state) === true) {
    const { production_supervisor_permission_operational_maintenances } = getSettings(state);
    return production_supervisor_permission_operational_maintenances === 'administrate';
  }
  const permissionProfile = getPermissionProfile(state);
  if (permissionProfile) {
    if (permissionProfile.operational_maintenance === 'administrate') {
      return true;
    }
  }
  return false;
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/
const hasDeprecatedVersion = state => state.auth.hasDeprecatedVersion;
const isGroupAuthenticated = state => state.auth.groupIsAuthenticated;
const isUserAuthenticated = state => state.auth.userIsAuthenticated;
const isAuthenticating = state => state.auth.isAuthenticating;
const isPreparingApp = state => state.auth.isPreparingApp;

export default {
  getCurrentOrganisation,
  getCurrentSystem,
  getCurrentUser,
  getLanguage,
  hasDeprecatedVersion,
  getOtherCostCategories,
  getPurchaseOrderDeliveryMethods,
  getPurchaseOrderPaymentTerms,
  getSparePartUnits,
  getLaborTariffs,
  getAssetTemplateFields,
  getAssetTypeTemplateFields,
  getSparePartTemplateFields,
  getWorkOrderSpentTimeCategories,
  isUserAuthenticated,
  isAuthenticating,
  isGroupAuthenticated,
  isAdmin,
  isOperator,
  isProductionSupervisor,
  isViewOnly,
  canViewAssets,
  canEditAssets,
  canViewWorkOrderCosts,
  canViewFullCosts,
  canEditVendors,
  canViewVendors,
  canCarryOutWorkOrders,
  canAdministrateWorkOrders,
  canEditWorkOrder,
  canAdministrateRequests,
  canEditRequest,
  isPreparingApp,
  canCreateWithdrawals,
  canAdjustStockOnSpareParts,
  canViewSpareParts,
  canEditSpareParts,
  canEditSparePartWithdrawal,
  canViewPurchaseOrders,
  canEditPurchaseOrders,
  canRegisterDeliveries,
  canViewMeters,
  canEditMeterReadings,
  canEditMeters,
  canViewWorkOrders,
  canViewRequests,
  canCreateRequests,
  canViewDowntimes,
  canEditDowntimes,
  canEditDowntime,
  canViewOperationalMaintenances,
  canCarryOutOperationalMaintenances,
  canAdministrateOperationalMaintenances,
  getMeterUnits,
  getSettings,
  getCurrentUserSettings,
  getOrganisationSettings,
  getCurrentSystemAccess,
  getSystems,
  hasEnterpriseTier,
  hasProTier,
  isWorkRequester,
};
