import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Section = ({ title, subtitle, children, rightComponent, header }) => (
  <div className={styles['section-container']}>
    <div className={styles['section']}>
      <div className={styles['content']}>
        <div
          className={`${styles['title']} ${header ? styles['header'] : ''} ${
            subtitle ? styles['has-subtitle'] : ''
          }`}
        >
          {title}
        </div>
        {subtitle ? <div className={styles['subtitle']}>{subtitle}</div> : null}
      </div>
      {rightComponent ? <div>{rightComponent}</div> : null}
    </div>
    {children ? <div className={styles['children']}>{children}</div> : null}
  </div>
);

export default Section;

Section.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  rightComponent: PropTypes.element,
  header: PropTypes.bool,
};

Section.defaultProps = {
  header: false,
  title: null,
  subtitle: null,
  rightComponent: null,
};
