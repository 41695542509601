import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { RequestStatus } from 'sdk/Request';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { FILTER_TYPES } from 'views/scenes/Requests/FilterModal';

class Status extends Component {
  renderStatus = status => {
    switch (status) {
      case RequestStatus.Active:
        return <FormattedMessage id="resources.request.statuses.active" />;
      case RequestStatus.AwaitingApproval:
        return <FormattedMessage id="resources.request.statuses.awaiting-production-supervisor-approval" />;
      case RequestStatus.WorkOrderCreated:
        return <FormattedMessage id="resources.request.statuses.work-order-created" />;
      case RequestStatus.Completed:
        return <FormattedMessage id="resources.request.statuses.completed" />;
      case RequestStatus.Archived:
        return <FormattedMessage id="resources.request.statuses.archived" />;
      default:
        return null;
    }
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.request.status" />}
        comparator={comparator}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Status,
            data: {
              [FILTER_TYPES.Status]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                value: [],
              },
            },
          })
        }
      >
        <SideModal.Container.Filter.AppliedFilters.Values
          data={value}
          renderItem={status => this.renderStatus(status)}
        />
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: RequestsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: RequestsSelectors.getFiltersForKey(state, FILTER_TYPES.Status),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);
