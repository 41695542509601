import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as moment from 'moment';
import {
  Button,
  DatePicker,
  Field,
  NewInlineModal,
  Icon,
} from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { ErrorOperations } from 'state/ducks/error';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { getNewWeekdays, getWeekdayOfMonth } from 'sdk/RecurringMaintenance';
import styles from './style.module.scss';

class WorkOrderCustomIntervalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      showDropDown: false,
    };
  }

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        showDatePicker: false,
        ...this.getStateFromEditingWorkOrder(this.props.editingWorkOrder),
      });
    }
  }

  componentWillUnmount() {
    this.props.removeFieldsFromErrors(['startDate']);
  }

  getStateFromEditingWorkOrder = editingWorkOrder => {
    let days;
    if (!editingWorkOrder.start_date && editingWorkOrder.frequency !== 'daily') {
      const dayOfWeek = moment().day();
      days = {
        mon: dayOfWeek === 1,
        tue: dayOfWeek === 2,
        wed: dayOfWeek === 3,
        thu: dayOfWeek === 4,
        fri: dayOfWeek === 5,
        sat: dayOfWeek === 6,
        sun: dayOfWeek === 0,
      };
    } else if (editingWorkOrder.frequency == null) {
      const dayOfWeek = moment(editingWorkOrder.start_date).day();
      days = {
        mon: dayOfWeek === 1,
        tue: dayOfWeek === 2,
        wed: dayOfWeek === 3,
        thu: dayOfWeek === 4,
        fri: dayOfWeek === 5,
        sat: dayOfWeek === 6,
        sun: dayOfWeek === 0,
      };
    } else {
      days = {
        mon: editingWorkOrder.mon,
        tue: editingWorkOrder.tue,
        wed: editingWorkOrder.wed,
        thu: editingWorkOrder.thu,
        fri: editingWorkOrder.fri,
        sat: editingWorkOrder.sat,
        sun: editingWorkOrder.sun,
      };
    }
    return {
      startDate: editingWorkOrder.start_date || moment().format('YYYY-MM-DD'),
      interval: editingWorkOrder.interval || 1,
      intervalInputValue: editingWorkOrder.interval || 1,
      frequency: editingWorkOrder.frequency || 'weekly',
      monthType: editingWorkOrder.month_type || 'day_of_week',
      generateFromCompletedDate: editingWorkOrder.generate_from_completed_date || false,
      ...days,
    };
  };

  save = () => {
    this.props.removeFieldsFromErrors(['startDate']);
    if (this.validateStartDate()) {
      this.props.onSave(this.buildRecurringMaintenance(this.state));
      this.props.onClose();
    }
  };

  validateStartDate = () => {
    const { frequency, generateFromCompletedDate } = this.state;
    if ((frequency === 'daily' || frequency === 'weekly') && !generateFromCompletedDate) {
      const { startDate, mon, tue, wed, thu, fri, sat, sun } = this.state;
      const weekday = moment(startDate).day();
      if (
        (weekday === 0 && !sun) ||
        (weekday === 1 && !mon) ||
        (weekday === 2 && !tue) ||
        (weekday === 3 && !wed) ||
        (weekday === 4 && !thu) ||
        (weekday === 5 && !fri) ||
        (weekday === 6 && !sat)
      ) {
        this.props.setFieldErrors({
          startDate: 'components.interval-options-inline-modal.weekday-error',
        });
        return false;
      }
    }
    return true;
  };

  setStartDate = date => {
    this.props.removeFieldsFromErrors(['startDate']);
    const recurringMaintenance = this.buildRecurringMaintenance(this.state);
    const newWeekdays = getNewWeekdays(recurringMaintenance, date);

    this.setState({ showDatePicker: false, startDate: date, ...newWeekdays });
  };

  resetWeekdays = newFrequency => {
    switch (newFrequency) {
      case 'daily':
        if (this.state.frequency !== 'daily') {
          return {
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: false,
            sun: false,
          };
        }
        break;
      case 'weekly': {
        if (this.state.frequency !== 'weekly') {
          const dayOfWeek = new Date(this.state.startDate).getDay();
          return {
            mon: dayOfWeek === 1,
            tue: dayOfWeek === 2,
            wed: dayOfWeek === 3,
            thu: dayOfWeek === 4,
            fri: dayOfWeek === 5,
            sat: dayOfWeek === 6,
            sun: dayOfWeek === 0,
          };
        }
        break;
      }
      default:
        break;
    }
    return {};
  };

  buildRecurringMaintenance = data => ({
    start_date: this.state.startDate,
    interval: data.interval >= 1 ? data.interval : 1,
    frequency: data.frequency,
    month_type: data.monthType,
    generate_from_completed_date: data.generateFromCompletedDate,
    mon: data.mon,
    tue: data.tue,
    wed: data.wed,
    thu: data.thu,
    fri: data.fri,
    sat: data.sat,
    sun: data.sun,
  });

  selectDay = day => {
    const { mon, tue, wed, thu, fri, sat, sun } = this.state;
    const count = [mon, tue, wed, thu, fri, sat, sun].filter(b => b).length;
    if (count > 1 || !this.state[day]) {
      const weekday = getWeekdayOfMonth(this.state.startDate);
      let startDate = this.state.startDate;
      if (day === weekday) {
        const weekday = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].findIndex(
          el => this.state[el] && el !== day
        );

        startDate = moment(this.state.startDate).weekday(weekday).format('YYYY-MM-DD');
      }

      this.setState({
        [day]: !this.state[day],
        startDate: startDate,
      });
    }
  };

  isNumber = value => {
    const numberRegex = /^[0-9\b]+$/;
    return value === '' || numberRegex.test(value);
  };

  renderWeekDays = () => (
    <div className={styles['weekdays']}>
      {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => (
        <div
          key={i}
          className={`${styles['day']} ${this.state[day] === true ? styles['selected'] : ''}`}
          onClick={() => this.selectDay(day)}
        >
          <FormattedMessage id={`components.interval-options-inline-modal.days.${day}`} />
        </div>
      ))}
    </div>
  );

  renderTypeOfMonthOptions = () => {
    if (this.state.startDate == null) {
      return null;
    } else {
      const dayOfWeekMessage = buildIntervalMessage(
        {
          frequency: this.state.frequency,
          interval: this.state.interval,
          month_type: 'day_of_week',
          start_date: this.state.startDate,
        },
        this.props.intl
      );

      const exactDayMessage = buildIntervalMessage(
        {
          frequency: this.state.frequency,
          interval: this.state.interval,
          month_type: 'exact_day',
          start_date: this.state.startDate,
        },
        this.props.intl
      );

      return (
        <Field.Checkbox.Group>
          <Field.Checkbox
            radio
            label={dayOfWeekMessage}
            checked={this.state.monthType === 'day_of_week'}
            onChange={() => this.setState({ monthType: 'day_of_week' })}
          />
          <Field.Checkbox
            radio
            label={exactDayMessage}
            checked={this.state.monthType === 'exact_day'}
            onChange={() => this.setState({ monthType: 'exact_day' })}
          />
        </Field.Checkbox.Group>
      );
    }
  };

  renderOptions = () => (
    <div className={styles['options-container']}>
      {(() => {
        switch (this.state.frequency) {
          case 'daily':
            return this.renderWeekDays();
          case 'weekly':
            return this.renderWeekDays();
          case 'monthly':
            return this.renderTypeOfMonthOptions();
          case 'yearly':
            return this.renderTypeOfMonthOptions();
          default:
            return null;
        }
      })()}
    </div>
  );

  renderStartDate = () => {
    const momentDate = moment(this.state.startDate);

    return (
      <div className={styles['start-date-container']}>
        <ConnectedFieldErrorWrapper field="startDate">
          {hasError => (
            <Field edit label={<FormattedMessage id="resources.work-order.due-date" />} error={hasError}>
              <DatePicker.Day
                open={this.state.showDatePicker}
                date={this.state.startDate}
                onSelectDate={this.setStartDate}
                onClose={() => {
                  this.setState({ showDatePicker: false });
                }}
                trigger={
                  <div
                    onClick={() => {
                      this.setState(prevState => ({
                        showDatePicker: !prevState.showDatePicker,
                      }));
                    }}
                  >
                    <Field.Resource
                      clearable={false}
                      icon={<Icon regular type="calendar-alt" />}
                      value={
                        this.state.startDate == null
                          ? null
                          : `${momentDate.format('LL')} (${momentDate.format('dddd')})`
                      }
                    />
                  </div>
                }
              />
            </Field>
          )}
        </ConnectedFieldErrorWrapper>
      </div>
    );
  };

  renderGenerateFromCompletedDate = () => {
    return (
      <div className={styles['setting-container']}>
        <div>
          <p className={styles['title']}>
            <FormattedMessage id="components.interval-options-inline-modal.generate-from-completed-date.title" />
          </p>
          <p className={styles['description']}>
            <FormattedMessage id="components.interval-options-inline-modal.generate-from-completed-date.subtitle" />
          </p>
        </div>
        <div className={styles['check']}>
          <Field.Checkbox
            toggle
            checked={this.state.generateFromCompletedDate}
            onChange={checked => {
              this.setState({ generateFromCompletedDate: checked });
            }}
          />
        </div>
      </div>
    );
  };

  renderRecurringMessage = () => {
    const recurringMaintenance = this.buildRecurringMaintenance(this.state);
    return (
      <div className={styles['interval-message']}>
        {buildIntervalMessage(recurringMaintenance, this.props.intl)}
      </div>
    );
  };

  renderFrequency = () => {
    switch (this.state.frequency) {
      case 'daily':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.daily" />;
      case 'weekly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.weekly" />;
      case 'monthly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.monthly" />;
      case 'yearly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.yearly" />;
      default:
        return null;
    }
  };

  renderSelectInterval = () => {
    return (
      <div className={styles['custom-interval']}>
        {this.state.frequency === 'daily' && this.state.generateFromCompletedDate === false ? (
          <span className={styles['daily-label']}>
            <FormattedMessage id="components.interval-options-inline-modal.interval-label-daily" />
          </span>
        ) : (
          <React.Fragment>
            <FormattedMessage id="components.interval-options-inline-modal.interval-label" />
            <div className={styles['interval']}>
              <Field.Number
                value={this.state.intervalInputValue}
                min={1}
                onChange={value => {
                  if (this.isNumber(value)) {
                    this.setState({
                      interval: Number(value),
                      intervalInputValue: value,
                    });
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
        {
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
            className={styles['frequency-text']}
          >
            <span style={{ marginRight: 5 }}>{this.renderFrequency()}</span>
            <Icon type="angle-down" />
          </div>
        }
        <NewInlineModal
          minWidth={100}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'daily'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'daily',
                    ...this.resetWeekdays('daily'),
                  })
                }
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.daily" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'weekly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'weekly',
                    ...this.resetWeekdays('weekly'),
                  })
                }
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.weekly" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'monthly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'monthly',
                    ...this.resetWeekdays('monthly'),
                  })
                }
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.monthly" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'yearly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'yearly',
                    ...this.resetWeekdays('yearly'),
                  })
                }
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.yearly" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={450}>
        <Modal.Header
          title={<FormattedMessage id="components.interval-options-inline-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          <div className={styles['content']}>
            <Grid>
              {this.renderSelectInterval()}
              {!this.state.generateFromCompletedDate ? this.renderOptions() : null}
              {this.renderStartDate()}
              <Grid.Separator />
              {this.renderGenerateFromCompletedDate()}
              {this.renderRecurringMessage()}
            </Grid>
          </div>
        </Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button small primary loading={this.state.isSaving} onClick={this.save} label="general.save" />
            <Button small label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setFieldErrors: ErrorOperations.setFieldErrors,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(WorkOrderCustomIntervalModal));
