import actions from './actions';
import types from './types';

export function updateOperationalMaintenanceId({ oldId, newId }) {
  return dispatch => {
    dispatch({ type: types.UPDATE_ID, payload: { newId, oldId } });
  };
}

export default {
  updateOperationalMaintenanceId,
};
