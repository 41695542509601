import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations, API } from 'sdk';
import { normalizeContactPerson } from 'sdk/Schemas';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Address } from 'views/components/General';
import { WhiteCard, Button, Field, Tooltip, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { Grid } from 'views/components/Shared/Layout';
import VendorField from './VendorField';
import ContactPersonField from './ContactPersonField';
import EditAddressModal from './EditAddressModal';
import styles from './styles.module.scss';

class Vendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPersonIds: [],
      editing: false,
      isSaving: false,
      editingPurchaseOrder: {},
      editingPurchaseOrderBeforeEdit: {},
      showEditAddressModal: false,
    };
  }

  edit = () => {
    const editingPurchaseOrder = {
      vendor_address1: this.props.purchaseOrder.vendor_address1,
      vendor_address2: this.props.purchaseOrder.vendor_address2,
      vendor_zip: this.props.purchaseOrder.vendor_zip,
      vendor_city: this.props.purchaseOrder.vendor_city,
      vendor_country: this.props.purchaseOrder.vendor_country,
      vendor_id: this.props.purchaseOrder.vendor_id,
      vendor_reference_contact_person_id: this.props.purchaseOrder.vendor_reference_contact_person_id,
      vendor_reference_name: this.props.purchaseOrder.vendor_reference_name,
      vendor_reference_email: this.props.purchaseOrder.vendor_reference_email,
      vendor_reference_phone: this.props.purchaseOrder.vendor_reference_phone,
    };

    this.setState({
      editing: true,
      editingPurchaseOrder: editingPurchaseOrder,
      editingPurchaseOrderBeforeEdit: editingPurchaseOrder,
    });
  };

  componentDidMount() {
    API.listContactPersonsForVendor(this.props.purchaseOrder.vendor_id).then(({ data: contactPersons }) => {
      const { entities, result: contactPersonIds } = normalizeContactPerson(contactPersons);
      this.setState({ contactPersonIds });
      this.props.updateEntities(entities);
    });
  }

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });

    const params = this.getModifiedPurchaseOrderData();

    this.props
      .updatePurchaseOrder(this.props.purchaseOrder.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedPurchaseOrderData = () => {
    const vendor = this.state.editingPurchaseOrder;
    const purchaseOrderBeforeEdit = this.state.editingPurchaseOrderBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== purchaseOrderBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  setPurchaseOrderValue = obj => {
    const neweditingPurchaseOrder = {
      ...this.state.editingPurchaseOrder,
      ...obj,
    };

    this.setState({
      editingPurchaseOrder: neweditingPurchaseOrder,
    });
  };

  isLoaded = () => this.props.purchaseOrder != null;

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditPurchaseOrders) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  hasNoAddress = () => {
    const { vendor_address1, vendor_address2, vendor_zip, vendor_city, vendor_country } =
      this.props.purchaseOrder;
    return !vendor_address1 && !vendor_address2 && !vendor_zip && !vendor_city && !vendor_country;
  };

  renderAddressData = obj => {
    return (
      <React.Fragment>
        {obj.name ? <p>{obj.name}</p> : null}
        {obj.address1 ? <p>{obj.address1}</p> : null}
        {obj.address2 ? <p>{obj.address2}</p> : null}
        {obj.zip || obj.city ? (
          <p>
            {obj.zip ? <span>{obj.zip} </span> : null}
            {obj.city ? <span>{obj.city}</span> : null}
          </p>
        ) : null}
        {obj.country ? <p>{obj.country}</p> : null}
      </React.Fragment>
    );
  };

  renderViewAdress = () => {
    if (this.hasNoAddress()) return '-';
    return (
      <div className={`${styles['adress']} ${this.state.editing ? styles['edit'] : ''}`}>
        <Address
          ignoreName
          address1={this.props.purchaseOrder.vendor_address1}
          address2={this.props.purchaseOrder.vendor_address2}
          zip={this.props.purchaseOrder.vendor_zip}
          city={this.props.purchaseOrder.vendor_city}
          country={this.props.purchaseOrder.vendor_country}
        />
      </div>
    );
  };

  renderEditingAdress = () => (
    <>
      <div
        className={`${styles['adress']} ${this.state.editing ? styles['edit'] : ''}`}
        onClick={() => {
          this.setState(prevState => ({
            showEditAddressModal: true,
            editingAddress: {
              address1: this.state.editingPurchaseOrder.vendor_address1,
              address2: this.state.editingPurchaseOrder.vendor_address2,
              zip: this.state.editingPurchaseOrder.vendor_zip,
              city: this.state.editingPurchaseOrder.vendor_city,
              country: this.state.editingPurchaseOrder.vendor_country,
            },
          }));
        }}
      >
        <Address
          ignoreName
          address1={this.props.purchaseOrder.vendor_address1}
          address2={this.props.purchaseOrder.vendor_address2}
          zip={this.props.purchaseOrder.vendor_zip}
          city={this.props.purchaseOrder.vendor_city}
          country={this.props.purchaseOrder.vendor_country}
        />
      </div>
    </>
  );

  renderReference = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.purchase-order.your-ref" />}>
          <ContactPersonField
            contactPersonIds={this.state.contactPersonIds}
            edit
            vendorId={this.props.purchaseOrder.vendor_id}
            value={this.state.editingPurchaseOrder.vendor_reference_contact_person_id}
            onChange={vendor_reference_contact_person_id =>
              this.setPurchaseOrderValue({ vendor_reference_contact_person_id })
            }
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.purchase-order.your-ref" />}>
        <ContactPersonField value={this.props.purchaseOrder.vendor_reference_contact_person_id} />
      </Field>
    );
  };

  renderAddress = () => {
    return (
      <Field view={!this.state.editing} label={<FormattedMessage id="resources.purchase-order.address" />}>
        {this.state.editing ? (
          <Field.Resource clearable={false} value={this.renderEditingAdress()} />
        ) : (
          this.renderViewAdress()
        )}
      </Field>
    );
  };

  renderVendor = () => {
    return (
      <VendorField
        edit={this.state.editing}
        value={this.props.purchaseOrder.vendor_id}
        onChange={vendor_id => this.setPurchaseOrderValue({ vendor_id })}
      />
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return null;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>{this.renderVendor()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderReference()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderAddress()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <>
        <WhiteCard
          fullHeight
          title={<FormattedMessage id="screens.purchase-order.info.vendor.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
        <EditAddressModal
          adress={this.state.editingAddress}
          title={<FormattedMessage id="screens.purchase-order.info.vendor.edit-address-modal-title" />}
          open={this.state.showEditAddressModal}
          onSave={adress => {
            this.setPurchaseOrderValue({
              vendor_address1: adress.address1,
              vendor_address2: adress.address2,
              vendor_zip: adress.zip,
              vendor_city: adress.city,
              vendor_country: adress.country,
            });
            this.setState({
              showEditAddressModal: false,
              editingAddress: {},
            });
          }}
          onClose={() => {
            this.setState({ showEditAddressModal: false, editingAddress: {} });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  return {
    purchaseOrder: EntitySelectors.getPurchaseOrder(state, purchaseOrderId),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Vendor)));
