import actions from './actions';

const setResourceItemsSelectedTab = actions.setResourceItemsSelectedTab;
const incrementImageLoaderCount = actions.incrementImageLoaderCount;
const decrementImageLoaderCount = actions.decrementImageLoaderCount;
const resetImageLoaderCount = actions.resetImageLoaderCount;
const showAddReusableChecklistLoader = actions.showAddReusableChecklistLoader;
const hideAddReusableChecklistLoader = actions.hideAddReusableChecklistLoader;

export default {
  setResourceItemsSelectedTab,
  incrementImageLoaderCount,
  decrementImageLoaderCount,
  resetImageLoaderCount,
  showAddReusableChecklistLoader,
  hideAddReusableChecklistLoader,
};
