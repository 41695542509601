import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage, Grid } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { normalizeSparePartType } from 'sdk/Schemas';
import { Field, Button, Icon } from 'views/components/Shared/General';
import { SparePartTypeInlineModal, SparePartTypePath } from 'views/components/SparePart';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class EditSparePartTypeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isSaving: false,
      hasUnsavedChanges: false,
      editingSparePartType: {},
      editingSparePartTypeBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        hasUnsavedChanges: false,
      });

      const { sparePartType } = this.props;
      if (sparePartType) {
        this.fetchAndSetEditingSparePartType();
      } else {
        const editingSparePartType = {
          title: '',
          parent_id: null,
        };
        this.setState({
          editingSparePartType,
          editingWorkOrderBeforeEdit: editingSparePartType,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  fetchAndSetEditingSparePartType = () => {
    this.setState({ isFetching: true });
    API.fetchSparePartType(this.props.id).then(({ data: sparePartType }) => {
      const { entities } = normalizeSparePartType(sparePartType);
      this.props.updateEntities(entities);
      const editingSparePartType = {
        title: this.props.sparePartType.title,
        parent_id: this.props.sparePartType.parent_id,
      };
      this.setState({
        isFetching: false,
        editingSparePartType,
        editingSparePartTypeBeforeEdit: editingSparePartType,
      });
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.editingSparePartType.title) {
      this.save();
    }
  };

  save = () => {
    if (this.state.isSaving) return;

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createSparePartType(this.props.system.id, this.state.editingSparePartType)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.spare-parts.types.create-success" />}
          />
        );
        this.props.onSave(data);
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updateSparePartType(this.props.id, this.state.editingSparePartType)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave(data);
      })
      .catch(e => {});
  };

  setValue = values => {
    const editingSparePartType = {
      ...this.state.editingSparePartType,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingSparePartTypeBeforeEdit, editingSparePartType);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingSparePartType, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderSubtitle = () => {
    if (this.props.sparePartType) {
      const { spare_part_count: count } = this.props.sparePartType;
      if (count === 0) {
        return <FormattedMessage id="screens.settings.spare-parts.types.never-used" />;
      }

      return (
        <FormattedPlural
          value={count}
          one={<FormattedMessage id="screens.settings.spare-parts.types.used-amount.one" />}
          two={
            <FormattedMessage id="screens.settings.spare-parts.types.used-amount.two" values={{ count }} />
          }
          few={
            <FormattedMessage id="screens.settings.spare-parts.types.used-amount.few" values={{ count }} />
          }
          many={
            <FormattedMessage id="screens.settings.spare-parts.types.used-amount.many" values={{ count }} />
          }
          other={
            <FormattedMessage id="screens.settings.spare-parts.types.used-amount.other" values={{ count }} />
          }
        />
      );
    }
    return null;
  };

  renderModalContent = () => {
    if (this.state.isFetching) {
      return (
        <>
          <Modal.Content loading />
        </>
      );
    }
    return (
      <>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.spare-parts.types.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.spare-parts.types.new-title" />
            )
          }
          subtitle={this.renderSubtitle()}
          iconButtons={
            this.props.id == null ? null : (
              <Button
                type="icon"
                icon={<Icon regular red type="trash-alt" />}
                onClick={() => this.props.onDelete(this.props.id)}
              />
            )
          }
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label={<FormattedMessage id="resources.asset-type.title" />}>
                  <Field.Text
                    autoFocus
                    error={this.state.showTitleError}
                    value={this.state.editingSparePartType.title}
                    onKeyPress={this.handleKeyPress}
                    onChange={title => {
                      this.setValue({ title });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <SparePartTypeInlineModal
                  trigger={
                    <Field
                      view={false}
                      label={<FormattedMessage id="components.spare-part-type-modal.parent" />}
                    >
                      <Field.Resource
                        value={
                          this.state.editingSparePartType.parent_id ? (
                            <SparePartTypePath
                              fullPath
                              sparePartTypeId={this.state.editingSparePartType.parent_id}
                            />
                          ) : null
                        }
                        angleDown
                        onClear={() => this.setValue({ parent_id: null })}
                      />
                    </Field>
                  }
                  inactivateSparePartTypeId={this.props.id}
                  selectedSparePartTypeId={this.state.editingSparePartType.parent_id}
                  onSelectSparePartType={id => this.setValue({ parent_id: id })}
                  onClear={() => this.setValue({ parent_id: null })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.editingSparePartType.title}
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartType: SDKReduxOperations.createSparePartType,
      updateSparePartType: SDKReduxOperations.updateSparePartType,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditSparePartTypeModal));
