import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class CreateSystemModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={500}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.organisation.create-system-modal.title" />}
            onClose={() => this.props.onClose()}
          />
          <Modal.Content>
            <div className={styles['message-container']}>
              <FormattedMessage id="screens.organisation.create-system-modal.message" />
            </div>
            <div className={styles['contact']}>
              <a href="mailto:support@mainter.com">
                <Button primary label="screens.organisation.create-system-modal.button" />
              </a>
              <div className={styles['email']}>support@mainter.com</div>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}
