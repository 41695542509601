import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Field, List, NewTab, Pagination, EmptyDataSet } from 'views/components/Shared/General';
import { API, HelperFunctions } from 'sdk';
import { normalizeUser, normalizeGroup, normalizeVendor } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { CalendarOperations } from 'state/ducks/calendar';
import UserListItem from './UserListItem';
import GroupListItem from './GroupListItem';
import VendorListItem from './VendorListItem';
import { UnsavedChangesBottomBar } from '../components';
import styles from './style.module.scss';

const TABS = {
  User: 'user',
  Group: 'group',
  Vendor: 'vendor',
};
const VENDORS_PAGE_SIZE = 5;

class ManageAssignees extends Component {
  getInitialState = () => ({
    isSaving: false,
    isFetching: true,
    selectedTab: null,
    hasUnsavedChanges: false,
    userIds: [],
    groupIds: [],
    vendorIds: [],
    calendar_assignees_exclude_users: [],
    calendar_assignees_exclude_groups: [],
    calendar_assignees_vendors: [],
    edited_calendar_assignees_exclude_users: [],
    edited_calendar_assignees_exclude_groups: [],
    edited_calendar_assignees_vendors: [],
    vendorPagination: {
      currentPage: 0,
      totalEntries: 0,
      totalPages: 0,
    },
  });

  state = this.getInitialState();

  componentDidMount() {
    this.selectTab(TABS.User);
  }

  save = () => {
    this.setState({ isSaving: true });
    let params = {};
    const {
      edited_calendar_assignees_exclude_users,
      edited_calendar_assignees_exclude_groups,
      edited_calendar_assignees_vendors,
      selectedTab,
    } = this.state;
    if (selectedTab === TABS.User) {
      params = {
        calendar_assignees_exclude_users: edited_calendar_assignees_exclude_users,
      };
    } else if (selectedTab === TABS.Group) {
      params = {
        calendar_assignees_exclude_groups: edited_calendar_assignees_exclude_groups,
      };
    } else if (selectedTab === TABS.Vendor) {
      params = {
        calendar_assignees_vendors: edited_calendar_assignees_vendors,
      };
    }
    API.updateUserSettings(this.props.system.id, this.props.currentUser.id, params)
      .then(() => {
        this.fetchAssigneesAndCalendar();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  fetchAssigneesAndCalendar = () => {
    const { date: dateInUrl } = queryString.parse(this.props.location.search);
    let date = dateInUrl || moment().format('YYYY-MM-DD');
    const fromDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
    const toDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
    this.props.fetchAssignees(this.props.system.id).then(() => {
      this.props
        .fetchInstancesForDates(this.props.system.id, {
          from: fromDate,
          to: toDate,
        })
        .then(() => {
          toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
          this.setState({
            isSaving: false,
            hasUnsavedChanges: false,
            calendar_assignees_exclude_users: this.state.edited_calendar_assignees_exclude_users,
            calendar_assignees_exclude_groups: this.state.edited_calendar_assignees_exclude_groups,
            calendar_assignees_vendors: this.state.edited_calendar_assignees_vendors,
          });
        });
    });
  };

  selectTab = tab => {
    if (this.state.selectedTab === tab) return;
    this.setState({ ...this.getInitialState(), selectedTab: tab });
    if (tab === TABS.User) {
      Promise.all([
        API.showUserSettings(this.props.system.id, this.props.currentUser.id, {
          keys: 'calendar_assignees_exclude_users',
        }),
        API.listUsers(this.props.system.id, { archived: false, member: true, no_pagination: true }),
      ]).then(([excludedUsers, usersRes]) => {
        const {
          data: { calendar_assignees_exclude_users },
        } = excludedUsers;
        const { data: users } = usersRes;
        const { result: userIds, entities } = normalizeUser(users);
        this.props.updateEntities(entities);
        this.setState({
          userIds,
          calendar_assignees_exclude_users,
          edited_calendar_assignees_exclude_users: calendar_assignees_exclude_users,
          isFetching: false,
        });
      });
    } else if (tab === TABS.Group) {
      Promise.all([
        API.showUserSettings(this.props.system.id, this.props.currentUser.id, {
          keys: 'calendar_assignees_exclude_groups',
        }),
        API.listGroups(this.props.system.id, { no_pagination: true, type: 'work_order_assignee' }),
      ]).then(([excludedGroups, groupsRes]) => {
        const {
          data: { calendar_assignees_exclude_groups },
        } = excludedGroups;
        const { data: groups } = groupsRes;
        const { result: groupIds, entities } = normalizeGroup(groups);
        this.props.updateEntities(entities);
        this.setState({
          groupIds,
          calendar_assignees_exclude_groups,
          edited_calendar_assignees_exclude_groups: calendar_assignees_exclude_groups,
          isFetching: false,
        });
      });
    } else if (tab === TABS.Vendor) {
      Promise.all([
        API.showUserSettings(this.props.system.id, this.props.currentUser.id, {
          keys: 'calendar_assignees_vendors',
        }),
        API.listVendors(this.props.system.id, { page_size: VENDORS_PAGE_SIZE }),
      ]).then(([includedVendors, vendorsRes]) => {
        const {
          data: { calendar_assignees_vendors },
        } = includedVendors;
        const { data: vendors, headers } = vendorsRes;
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        const { result: vendorIds, entities } = normalizeVendor(vendors);
        this.props.updateEntities(entities);
        this.setState({
          vendorIds,
          calendar_assignees_vendors,
          edited_calendar_assignees_vendors: calendar_assignees_vendors,
          isFetching: false,
          vendorPagination: pagination,
        });
      });
    }
  };

  toggleUser = id => {
    if (this.state.edited_calendar_assignees_exclude_users.includes(id)) {
      this.setState(
        {
          edited_calendar_assignees_exclude_users: this.state.edited_calendar_assignees_exclude_users.filter(
            userId => userId !== id
          ),
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_exclude_users,
              this.state.edited_calendar_assignees_exclude_users
            ),
          });
        }
      );
    } else {
      this.setState(
        {
          edited_calendar_assignees_exclude_users: [
            ...this.state.edited_calendar_assignees_exclude_users,
            id,
          ],
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_exclude_users,
              this.state.edited_calendar_assignees_exclude_users
            ),
          });
        }
      );
    }
  };

  toggleGroup = id => {
    if (this.state.edited_calendar_assignees_exclude_groups.includes(id)) {
      this.setState(
        {
          edited_calendar_assignees_exclude_groups:
            this.state.edited_calendar_assignees_exclude_groups.filter(groupId => groupId !== id),
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_exclude_groups,
              this.state.edited_calendar_assignees_exclude_groups
            ),
          });
        }
      );
    } else {
      this.setState(
        {
          edited_calendar_assignees_exclude_groups: [
            ...this.state.edited_calendar_assignees_exclude_groups,
            id,
          ],
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_exclude_groups,
              this.state.edited_calendar_assignees_exclude_groups
            ),
          });
        }
      );
    }
  };

  toggleVendor = id => {
    if (this.state.edited_calendar_assignees_vendors.includes(id)) {
      this.setState(
        {
          edited_calendar_assignees_vendors: this.state.edited_calendar_assignees_vendors.filter(
            vendorId => vendorId !== id
          ),
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_vendors,
              this.state.edited_calendar_assignees_vendors
            ),
          });
        }
      );
    } else {
      this.setState(
        {
          edited_calendar_assignees_vendors: [...this.state.edited_calendar_assignees_vendors, id],
        },
        () => {
          this.setState({
            hasUnsavedChanges: !isEqual(
              this.state.calendar_assignees_vendors,
              this.state.edited_calendar_assignees_vendors
            ),
          });
        }
      );
    }
  };

  renderGroups = () => {
    if (this.state.isFetching) {
      return (
        <>
          <div className={styles['subtitle']}>
            <FormattedMessage id="components.calendar-settings-modal.hide-groups-subtitle" />
          </div>
          <div className={styles['list-container']}>
            <List light small>
              <List.Item>
                <List.Item.Column width={20}>
                  <Field.Checkbox disabled />
                </List.Item.Column>
                <List.Item.TitleColumn loading />
              </List.Item>
              <List.Item>
                <List.Item.Column width={20}>
                  <Field.Checkbox disabled />
                </List.Item.Column>
                <List.Item.TitleColumn loading />
              </List.Item>
            </List>
          </div>
        </>
      );
    } else if (this.state.groupIds.length === 0) {
      return (
        <>
          <div className={styles['subtitle']}>
            <FormattedMessage id="components.calendar-settings-modal.hide-groups-subtitle" />
          </div>
          <div className={styles['list-container']}>
            <EmptyDataSet
              title={<FormattedMessage id="components.calendar-settings-modal.groups-empty-data-set.title" />}
              modal
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles['subtitle']}>
          <FormattedMessage id="components.calendar-settings-modal.hide-groups-subtitle" />
        </div>
        <div className={styles['list-container']}>
          <List light small>
            {this.state.groupIds.map(id => (
              <GroupListItem
                id={id}
                selected={!this.state.edited_calendar_assignees_exclude_groups.includes(id)}
                onToggle={this.toggleGroup}
              />
            ))}
          </List>
        </div>
      </>
    );
  };

  renderVendors = () => {
    if (this.state.isFetching) {
      return (
        <>
          <div className={styles['subtitle']}>
            <FormattedMessage id="components.calendar-settings-modal.show-vendors-subtitle" />
          </div>
          <div className={styles['list-container']}>
            <List light small>
              {Array(this.state.vendorIds.length === 0 ? 2 : this.state.vendorIds.length)
                .fill()
                .map(() => (
                  <List.Item>
                    <List.Item.Column width={20}>
                      <Field.Checkbox disabled />
                    </List.Item.Column>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
            {this.renderVendorPagination()}
          </div>
        </>
      );
    } else if (this.state.vendorIds.length === 0) {
      return (
        <>
          <div className={styles['subtitle']}>
            <FormattedMessage id="components.calendar-settings-modal.show-vendors-subtitle" />
          </div>
          <div className={styles['list-container']}>
            <EmptyDataSet
              title={
                <FormattedMessage id="components.calendar-settings-modal.vendors-empty-data-set.title" />
              }
              modal
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles['subtitle']}>
          <FormattedMessage id="components.calendar-settings-modal.show-vendors-subtitle" />
        </div>
        <div className={styles['list-container']}>
          <List light small>
            {this.state.vendorIds.map(id => (
              <VendorListItem
                id={id}
                selected={this.state.edited_calendar_assignees_vendors.includes(id)}
                onToggle={this.toggleVendor}
              />
            ))}
          </List>
          {this.renderVendorPagination()}
        </div>
      </>
    );
  };

  renderUsers = () => {
    return (
      <>
        <div className={styles['subtitle']}>
          <FormattedMessage id="components.calendar-settings-modal.hide-users-subtitle" />
        </div>
        <div className={styles['list-container']}>
          <List light small>
            {this.state.isFetching ? (
              <>
                <List.Item light small>
                  <List.Item.Column width={20}>
                    <Field.Checkbox disabled />
                  </List.Item.Column>
                  <List.Item.ImageColumn loading size={24} />
                  <List.Item.TitleColumn loading />
                </List.Item>
                <List.Item light small>
                  <List.Item.Column width={20}>
                    <Field.Checkbox disabled />
                  </List.Item.Column>
                  <List.Item.ImageColumn loading size={24} />
                  <List.Item.TitleColumn loading />
                </List.Item>
              </>
            ) : (
              this.state.userIds.map(id => (
                <UserListItem
                  id={id}
                  selected={!this.state.edited_calendar_assignees_exclude_users.includes(id)}
                  onToggle={this.toggleUser}
                />
              ))
            )}
          </List>
        </div>
      </>
    );
  };

  renderContent = () => {
    if (this.state.selectedTab === TABS.User) {
      return this.renderUsers();
    } else if (this.state.selectedTab === TABS.Group) {
      return this.renderGroups();
    } else if (this.state.selectedTab === TABS.Vendor) {
      return this.renderVendors();
    }
  };

  renderVendorPagination = () => {
    if (this.state.selectedTab === TABS.Vendor && this.state.vendorPagination.totalPages > 1) {
      return (
        <Pagination
          style={{ justifyContent: 'flex-end', marginTop: 15 }}
          currentPage={
            this.state.vendorPagination.currentPage ? Number(this.state.vendorPagination.currentPage) : 1
          }
          hideOptions
          totalPages={this.state.vendorPagination.totalPages}
          onSelectPage={page => {
            this.setState(
              { isFetching: true, vendorPagination: { ...this.state.vendorPagination, currentPage: page } },
              () => {
                API.listVendors(this.props.system.id, { page_size: VENDORS_PAGE_SIZE, page }).then(
                  ({ data: vendors, headers }) => {
                    const pagination = HelperFunctions.getPaginationFromHeader(headers);
                    const { result: vendorIds, entities } = normalizeVendor(vendors);
                    this.props.updateEntities(entities);
                    this.setState({
                      vendorIds,
                      isFetching: false,
                      vendorPagination: pagination,
                    });
                  }
                );
              }
            );
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <div className={styles['content']}>
            <div className={styles['title']}>
              <FormattedMessage id="components.calendar-settings-modal.show-assignees" />
            </div>
            <div className={styles['tabs']}>
              <NewTab.Container>
                <NewTab
                  selected={this.state.selectedTab === TABS.User}
                  onClick={() => this.selectTab(TABS.User)}
                >
                  <FormattedMessage id="components.calendar-settings-modal.hide-users" />
                </NewTab>
                <NewTab
                  selected={this.state.selectedTab === TABS.Group}
                  onClick={() => this.selectTab(TABS.Group)}
                >
                  <FormattedMessage id="components.calendar-settings-modal.hide-groups" />
                </NewTab>
                <NewTab
                  selected={this.state.selectedTab === TABS.Vendor}
                  onClick={() => this.selectTab(TABS.Vendor)}
                >
                  <FormattedMessage id="components.calendar-settings-modal.show-vendors" />
                </NewTab>
              </NewTab.Container>
            </div>
            {this.renderContent()}
          </div>
        </PerfectScrollbar>
        <UnsavedChangesBottomBar
          show={this.state.hasUnsavedChanges}
          loading={this.state.isSaving}
          onSave={this.save}
          onCancel={() => this.setState({ hasUnsavedChanges: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      fetchAssignees: CalendarOperations.fetchAssignees,
      fetchInstancesForDates: CalendarOperations.fetchInstancesForDates,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageAssignees)));
