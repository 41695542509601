import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { UserNameWrapper } from 'views/components/User';
import { Icon, Tooltip } from 'views/components/Shared/General';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

const StatusColumn = props => {
  const renderStatus = () => {
    switch (props.workOrder.status) {
      case WorkOrderStatus.NotStarted:
        return (
          <div className={`${styles['status']} ${styles['not-started']}`}>
            <FormattedMessage id="resources.work-order.statuses.not-started" />
          </div>
        );
      case WorkOrderStatus.InProgress:
        return (
          <div className={`${styles['status']} ${styles['in-progress']}`}>
            <Icon type="wrench" orange />
            <FormattedMessage id="resources.work-order.statuses.in-progress" />
          </div>
        );
      case WorkOrderStatus.Paused:
        return (
          <div className={styles['status']}>
            <Icon type="pause" />
            <FormattedMessage id="resources.work-order.statuses.paused" />
          </div>
        );
      case WorkOrderStatus.Completed:
        return (
          <div className={`${styles['status']} ${styles['completed']}`}>
            <Icon type="check" green />
            <FormattedMessage id="resources.work-order.statuses.completed" />
          </div>
        );
      default:
        return null;
    }
  };

  const renderByAssignee = () => {
    switch (props.workOrder.status) {
      case WorkOrderStatus.InProgress:
        return (
          <div className={styles['user']}>
            <FormattedMessage
              id="screens.work-orders.status-by-user"
              values={{ user: <UserNameWrapper user={props.inProgressByUser} /> }}
            />
          </div>
        );
      case WorkOrderStatus.Paused:
        return (
          <div className={styles['user']}>
            <FormattedMessage
              id="screens.work-orders.status-by-user"
              values={{ user: <UserNameWrapper user={props.pausedByUser} /> }}
            />
          </div>
        );
      case WorkOrderStatus.Completed:
        return (
          <div className={styles['user']}>
            <FormattedMessage
              id="screens.work-orders.status-by-user"
              values={{ user: <UserNameWrapper user={props.completedByUser} /> }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderIcon = () => {
    switch (props.workOrder.status) {
      case WorkOrderStatus.NotStarted:
        return (
          <Tooltip
            trigger={
              <div className={[styles['icon'], styles['not-started']].join(' ')}>
                <Icon regular type="check" color="#dbdbdb" />
              </div>
            }
            label={<FormattedMessage id="resources.work-order.statuses.not-started" />}
          />
        );
      case WorkOrderStatus.InProgress:
        return (
          <Tooltip
            trigger={
              <div className={[styles['icon'], styles['in-progress']].join(' ')}>
                <Icon type="wrench" orange />
              </div>
            }
            label={
              <FormattedMessage
                id="resources.work-order.statuses.in-progress-full"
                values={{ user: <UserNameWrapper user={props.inProgressByUser} /> }}
              />
            }
          />
        );
      case WorkOrderStatus.Paused:
        return (
          <Tooltip
            trigger={
              <div className={[styles['icon'], styles['paused']].join(' ')}>
                <Icon type="pause" />
              </div>
            }
            label={
              <FormattedMessage
                id="resources.work-order.statuses.paused-full"
                values={{ user: <UserNameWrapper user={props.pausedByUser} /> }}
              />
            }
          />
        );
      case WorkOrderStatus.Completed:
        return (
          <Tooltip
            trigger={
              <div className={[styles['icon'], styles['completed']].join(' ')}>
                <Icon regular type="check" green />
              </div>
            }
            label={
              <FormattedMessage
                id="resources.work-order.statuses.completed-full"
                values={{ user: <UserNameWrapper user={props.completedByUser} /> }}
              />
            }
          />
        );
      default:
        return null;
    }
  };

  if (props.icon) {
    return renderIcon();
  } else {
    return (
      <div className={styles['work-order-status']}>
        {renderStatus()}
        {renderByAssignee()}
      </div>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    pausedByUser: EntitySelectors.getUser(state, ownProps.workOrder.paused_by_user_id),
    inProgressByUser: EntitySelectors.getUser(state, ownProps.workOrder.in_progress_by_user_id),
    completedByUser: EntitySelectors.getUser(state, ownProps.workOrder.completed_by_user_id),
  };
}

export default connect(mapStateToProps)(StatusColumn);
