import { Type } from 'sdk/ChecklistTemplateRow';

const getRootTemplateFieldIds = state => {
  const { entities, operationalMaintenance } = state;
  return operationalMaintenance.templateFieldIds.filter(id => {
    return entities.checklistTemplateRowById[id];
  });
};

const getChildTemplateIdsForRow = (state, id) => {
  const { operationalMaintenance } = state;
  return operationalMaintenance.childIds[id];
};

const getInstanceRowForTemplateRowId = (state, templateRowId) => {
  const { operationalMaintenance, entities } = state;
  let instanceRowId = operationalMaintenance.instanceRowForTemplateRowIds[templateRowId];
  return entities.checklistInstanceRowById[instanceRowId];
};

const getChecklistInstanceOptionsForTemplateRowId = (state, templateRowId) => {
  const { operationalMaintenance, entities } = state;
  let checklistInstanceOptionIds =
    operationalMaintenance.instanceOptionIdsForTemplateRowIds[templateRowId] || [];
  return checklistInstanceOptionIds.map(id => entities.checklistInstanceOptionById[id]);
};

const getAllRowsHasBeenFilledIn = state => {
  const { operationalMaintenance, entities } = state;
  const checklistInstanceRowIds = Object.values(operationalMaintenance.instanceRowForTemplateRowIds);
  return (
    checklistInstanceRowIds.length ===
    checklistInstanceRowIds
      .map(id => {
        return entities.checklistInstanceRowById[id];
      })
      .filter(instanceRow => {
        if (instanceRow.template_row_type === Type.Inspection) {
          return instanceRow.value != null;
        } else if (instanceRow.template_row_type === Type.Meter) {
          return instanceRow.meter_reading != null;
        }
      }).length
  );
};

export default {
  getRootTemplateFieldIds,
  getChildTemplateIdsForRow,
  getInstanceRowForTemplateRowId,
  getChecklistInstanceOptionsForTemplateRowId,
  getAllRowsHasBeenFilledIn,
};
