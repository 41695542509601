import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { List, Field, Icon } from 'views/components/Shared/General';
import { Section } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import styles from '../style.module.scss';

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkOrderNotifications: false,
      showRequestNotifications: false,
      showSparePartNotifications: false,
      showPurchaseOrderNotifications: false,
      showMetersNotifications: false,
      showChecklistNotifications: false,
      showOperationalMaintenancesNotifications: false,
    };
  }

  renderWorkOrderNotification = () => {
    const { spare_parts_activated } = this.props.settings;
    if (!this.props.canCarryOutWorkOrders) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showWorkOrderNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showWorkOrderNotifications: !prevState.showWorkOrderNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.work-order.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showWorkOrderNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_work_order_assigned_desktop:
                    !this.props.editingUserSettings.notification_work_order_assigned_desktop,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_assigned_desktop}
                  onChange={() =>
                    this.props.onChange({
                      notification_work_order_assigned_desktop:
                        !this.props.editingUserSettings.notification_work_order_assigned_desktop,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.assigned.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.assigned.subtitle" />
                }
              />
            </List.Item>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_work_order_reminder_desktop:
                    !this.props.editingUserSettings.notification_work_order_reminder_desktop,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_work_order_reminder_desktop}
                  onChange={() =>
                    this.props.onChange({
                      notification_work_order_reminder_desktop:
                        !this.props.editingUserSettings.notification_work_order_reminder_desktop,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.reminder.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.work-order.reminder.subtitle" />
                }
              />
            </List.Item>
            {this.props.hasProTier && spare_parts_activated ? (
              <List.Item
                clickable
                onClick={() => {
                  this.props.onChange({
                    notification_work_order_purchases_delivered_desktop:
                      !this.props.editingUserSettings.notification_work_order_purchases_delivered_desktop,
                  });
                }}
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={
                      this.props.editingUserSettings.notification_work_order_purchases_delivered_desktop
                    }
                    onChange={() => {
                      this.props.onChange({
                        notification_work_order_purchases_delivered_desktop:
                          !this.props.editingUserSettings.notification_work_order_purchases_delivered_desktop,
                      });
                    }}
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.work-order.fully-delivered.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.work-order.fully-delivered.subtitle" />
                  }
                />
              </List.Item>
            ) : null}
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderMetersNotifications = () => {
    const { meters_activated } = this.props.settings;
    if (this.props.canViewMeters === false) return null;
    if (meters_activated !== true) return null;

    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showMetersNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showMetersNotifications: !prevState.showMetersNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.meters.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showMetersNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() => {
                this.props.onChange({
                  notification_meter_due_desktop:
                    !this.props.editingUserSettings.notification_meter_due_desktop,
                });
              }}
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_meter_due_desktop}
                  onChange={() => {
                    this.props.onChange({
                      notification_meter_due_desktop:
                        !this.props.editingUserSettings.notification_meter_due_desktop,
                    });
                  }}
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.meters.daily-digest.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.meters.daily-digest.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderRequestNotification = () => {
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showRequestNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({ showRequestNotifications: !prevState.showRequestNotifications }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.request.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showRequestNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <>
            <div className={styles['notifications-list-section']}>
              <List.Header small background>
                <List.Header.Column width={20}>
                  <Icon regular size={16} type="envelope" />
                </List.Header.Column>
                <List.Header.Column flex>
                  <FormattedMessage id="screens.settings.notifications.types.request.manage.title" />
                </List.Header.Column>
              </List.Header>
              <List small>
                {this.props.canAdministrateRequests ? (
                  <List.Item
                    clickable
                    onClick={() =>
                      this.props.onChange({
                        notification_request_created_desktop:
                          !this.props.editingUserSettings.notification_request_created_desktop,
                      })
                    }
                  >
                    <List.Item.Column width={20}>
                      <Field.Checkbox
                        checked={this.props.editingUserSettings.notification_request_created_desktop}
                        onChange={() =>
                          this.props.onChange({
                            notification_request_created_desktop:
                              !this.props.editingUserSettings.notification_request_created_desktop,
                          })
                        }
                      />
                    </List.Item.Column>
                    <List.Item.TitleColumn
                      title={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.new.title" />
                      }
                      subtitle={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.new.subtitle" />
                      }
                    />
                  </List.Item>
                ) : null}
                {this.props.settings.production_board_activated &&
                this.props.settings.request_production_supervisor_approval_activated ? (
                  <List.Item
                    clickable
                    onClick={() =>
                      this.props.onChange({
                        notification_request_awaiting_production_supervisor_approval_desktop:
                          !this.props.editingUserSettings
                            .notification_request_awaiting_production_supervisor_approval_desktop,
                      })
                    }
                  >
                    <List.Item.Column width={20}>
                      <Field.Checkbox
                        checked={
                          this.props.editingUserSettings
                            .notification_request_awaiting_production_supervisor_approval_desktop
                        }
                        onChange={() =>
                          this.props.onChange({
                            notification_request_awaiting_production_supervisor_approval_desktop:
                              !this.props.editingUserSettings
                                .notification_request_awaiting_production_supervisor_approval_desktop,
                          })
                        }
                      />
                    </List.Item.Column>
                    <List.Item.TitleColumn
                      title={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.supervisor-approval.title" />
                      }
                      subtitle={
                        <FormattedMessage id="screens.settings.notifications.types.request.manage.supervisor-approval.subtitle" />
                      }
                    />
                  </List.Item>
                ) : null}
              </List>
            </div>
            <List.Header small background>
              <List.Header.Column width={20}>
                <Icon regular size={16} type="envelope" />
              </List.Header.Column>
              <List.Header.Column flex>
                <FormattedMessage id="screens.settings.notifications.types.request.created-by.title" />
              </List.Header.Column>
            </List.Header>
            <List small>
              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_archived_desktop:
                      !this.props.editingUserSettings.notification_request_archived_desktop,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_archived_desktop}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_archived_desktop:
                          !this.props.editingUserSettings.notification_request_archived_desktop,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.archived.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.archived.subtitle" />
                  }
                />
              </List.Item>
              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_created_desktop:
                      !this.props.editingUserSettings.notification_request_work_order_created_desktop,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_created_desktop}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_created_desktop:
                          !this.props.editingUserSettings.notification_request_work_order_created_desktop,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-created.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-created.subtitle" />
                  }
                />
              </List.Item>

              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_paused_desktop:
                      !this.props.editingUserSettings.notification_request_work_order_paused_desktop,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_paused_desktop}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_paused_desktop:
                          !this.props.editingUserSettings.notification_request_work_order_paused_desktop,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-paused.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-paused.subtitle" />
                  }
                />
              </List.Item>

              <List.Item
                clickable
                onClick={() =>
                  this.props.onChange({
                    notification_request_work_order_completed_desktop:
                      !this.props.editingUserSettings.notification_request_work_order_completed_desktop,
                  })
                }
              >
                <List.Item.Column width={20}>
                  <Field.Checkbox
                    checked={this.props.editingUserSettings.notification_request_work_order_completed_desktop}
                    onChange={() =>
                      this.props.onChange({
                        notification_request_work_order_completed_desktop:
                          !this.props.editingUserSettings.notification_request_work_order_completed_desktop,
                      })
                    }
                  />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-completed.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.notifications.types.request.created-by.work-order-completed.subtitle" />
                  }
                />
              </List.Item>
            </List>
          </>
        </AnimateHeight>
      </>
    );
  };

  renderSparePartNotification = () => {
    if (!this.props.canEditSpareParts) return null;
    if (this.props.settings.spare_parts_activated === false) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showSparePartNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showSparePartNotifications: !prevState.showSparePartNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.spare-part.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showSparePartNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_spare_part_low_stock_quantity_desktop:
                    !this.props.editingUserSettings.notification_spare_part_low_stock_quantity_desktop,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_spare_part_low_stock_quantity_desktop}
                  onChange={() =>
                    this.props.onChange({
                      notification_spare_part_low_stock_quantity_desktop:
                        !this.props.editingUserSettings.notification_spare_part_low_stock_quantity_desktop,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.spare-part.low-stock.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.spare-part.low-stock.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderChecklistNotification = () => {
    if (!this.props.canAdministrateWorkOrders && !this.props.isProductionSupervisor) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showChecklistNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showChecklistNotifications: !prevState.showChecklistNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.checklist.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showChecklistNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_checklist_instance_deviation_assignee_desktop:
                    !this.props.editingUserSettings
                      .notification_checklist_instance_deviation_assignee_desktop,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={
                    this.props.editingUserSettings.notification_checklist_instance_deviation_assignee_desktop
                  }
                  onChange={() =>
                    this.props.onChange({
                      notification_checklist_instance_deviation_assignee_desktop:
                        !this.props.editingUserSettings
                          .notification_checklist_instance_deviation_assignee_desktop,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.checklist.deviation-assigned.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.checklist.deviation-assigned.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  renderPurchaseOrderNotification = () => {
    if (!this.props.canEditPurchaseOrders) return null;
    if (this.props.settings.spare_parts_activated === false) return null;
    return (
      <>
        <div
          className={`${styles['notification-section']} ${
            this.state.showPurchaseOrderNotifications ? styles['opened'] : ''
          }`}
          onClick={() =>
            this.setState(prevState => ({
              showPurchaseOrderNotifications: !prevState.showPurchaseOrderNotifications,
            }))
          }
        >
          <Icon light type="angle-right" />
          <FormattedMessage id="screens.settings.notifications.types.purchase-order.title" />
        </div>
        <AnimateHeight
          duration={250}
          height={this.state.showPurchaseOrderNotifications ? 'auto' : 0}
          contentClassName={styles['notifications-list']}
        >
          <List.Header small background>
            <List.Header.Column width={20}>
              <Icon regular size={16} type="envelope" />
            </List.Header.Column>
            <List.Header.Column flex>
              <FormattedMessage id="screens.settings.notifications.title" />
            </List.Header.Column>
          </List.Header>
          <List small>
            <List.Item
              clickable
              onClick={() =>
                this.props.onChange({
                  notification_late_purchase_order_desktop:
                    !this.props.editingUserSettings.notification_late_purchase_order_desktop,
                })
              }
            >
              <List.Item.Column width={20}>
                <Field.Checkbox
                  checked={this.props.editingUserSettings.notification_late_purchase_order_desktop}
                  onChange={() =>
                    this.props.onChange({
                      notification_late_purchase_order_desktop:
                        !this.props.editingUserSettings.notification_late_purchase_order_desktop,
                    })
                  }
                />
              </List.Item.Column>
              <List.Item.TitleColumn
                title={
                  <FormattedMessage id="screens.settings.notifications.types.purchase-order.low-stock.title" />
                }
                subtitle={
                  <FormattedMessage id="screens.settings.notifications.types.purchase-order.low-stock.subtitle" />
                }
              />
            </List.Item>
          </List>
        </AnimateHeight>
      </>
    );
  };

  render() {
    return (
      <>
        <Section
          title={<FormattedMessage id="screens.settings.notifications.desktop.notifications-title" />}
          subtitle={<FormattedMessage id="screens.settings.notifications.desktop.notifications-subtitle" />}
        />
        <div className={styles['notifications-container']}>
          {this.renderWorkOrderNotification()}
          {this.renderMetersNotifications()}
          {this.renderRequestNotification()}
          {this.renderChecklistNotification()}
          {this.renderSparePartNotification()}
          {this.renderPurchaseOrderNotification()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canViewMeters: AuthSelectors.canViewMeters(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    canViewOperationalMaintenances: AuthSelectors.canViewOperationalMaintenances(state),
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default connect(mapStateToProps)(Desktop);
