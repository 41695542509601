import React, { Component } from 'react';
import { API } from 'sdk';
import { Link } from 'react-router-dom';
import HelperFunctions from 'utilities/HelperFunctions';
import styles from './style.module.scss';
import { Button } from 'views/components/Shared/General';
import { Input, Button as LoginButton, Layout, BackButton } from 'views/components/Login';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      isResettingPasword: false,
      showForgotPasswordConfirmation: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Forgot Password');
  }

  resetPassword = () => {
    this.setState({ isResettingPasword: true });
    API.requestResetPassword(this.state.username)
      .then(() => {
        this.setState({ isResettingPasword: false, showForgotPasswordConfirmation: true });
      })
      .catch(() => {
        this.setState({ isResettingPasword: false });
      });
  };

  renderResetPasswordConfirmation = () => (
    <>
      <div className={styles['text']}>Password has been reset</div>
      <div className={styles['subtitle']}>
        <div>Instructions for resetting your password has been sent to {this.state.username}</div>
        <div>You will receive this email within a few minutes. Make sure you also check your spam folder</div>
      </div>
      <div className={styles['button-container']}>
        <LoginButton
          label="Go back to sign in"
          onClick={() => this.props.history.push('/login')}
        />
      </div>
    </>
  );

  renderForgotPassword = () => {
    return (
      <>
        <div className={styles['text']}>I forgot my password</div>
        <div className={styles['subtitle']}>
          An email will be sent with instructions on how you can reset your password
        </div>
        <div className={styles['input-container']}>
          <Input
            type="email"
            translate={false}
            autoFocus
            placeholder="Email"
            onKeyPress={this.handleKeyPress}
            value={this.state.username}
            onChange={username => {
              this.setState({ username });
            }}
          />
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Send me instructions"
            primary
            fullWidth
            disabled={!this.state.username}
            loading={this.state.isResettingPasword}
            onClick={this.resetPassword}
          />
        </div>
        <div className={styles['separator']} />
        <div className={styles['additional-buttons']}>
          <Link to="/login">
            <Button
              gray
              label="Back to login"
              translate={false}
            />
          </Link>
        </div>
      </>
    );
  };

  renderContent = () => {
    if (this.state.showForgotPasswordConfirmation) {
      return this.renderResetPasswordConfirmation();
    }
    return this.renderForgotPassword();
  };

  render() {
    return (
      <Layout>
        {this.renderContent()}
      </Layout>
    );
  }
}
