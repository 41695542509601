import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { ExportType } from 'sdk/Export';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { AssetDeleteModal, ArchiveAssetModal, UnarchiveAssetModal } from 'views/components/Asset';
import { NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { PrintQRCodeModal } from 'views/components/General';
import { AssetsSelectors } from 'state/ducks/assets';
import PrintModal from 'views/scenes/Assets/TreeAndList/List/PrintModal';

class Menu extends Component {
  state = {
    dropdownOpen: false,
    showDeleteModal: false,
    showPrintQrCodeModal: false,
    showPrintAssetModal: false,
    showArchiveAssetModal: false,
    showUnarchiveAssetModal: false,
  };

  renderDeleteAssetModal = () => (
    <AssetDeleteModal
      open={this.state.showDeleteModal}
      onDelete={() => {
        const { search } = this.props.navigatedTo;
        this.props.history.push({
          pathname: '/assets',
          search: HelperFunctions.convertObjToQueryParameters(search),
        });
      }}
      onClose={() => {
        this.setState({ showDeleteModal: false });
      }}
      asset={this.props.asset}
    />
  );

  renderArchiveDropdownItem = () => {
    if (this.props.canEditAssets) {
      if (this.props.asset.archived) {
        return (
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.setState({ showUnarchiveAssetModal: true, dropdownOpen: false });
            }}
          >
            <FormattedMessage id="screens.asset.unarchive" />
          </NewInlineModal.Dropdown.Item>
        );
      }
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ showArchiveAssetModal: true, dropdownOpen: false });
          }}
        >
          <FormattedMessage id="screens.asset.archive" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderDeleteDropdownItem = () => {
    if (this.props.canEditAssets) {
      return (
        <NewInlineModal.Dropdown.Item
          destructive
          onClick={() => {
            this.setState({ showDeleteModal: true, dropdownOpen: false });
          }}
        >
          <FormattedMessage id="general.delete" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderPrintQrCodeDropdownItem = () => {
    if (this.props.canEditAssets) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ showPrintQrCodeModal: true, dropdownOpen: false });
          }}
        >
          <FormattedMessage id="screens.asset.print-qr-code" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
          ref={ref => (this.inlineModalPositioningRef = ref)}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={250}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showPrintAssetModal: true, dropdownOpen: false });
                }}
              >
                <FormattedMessage id="screens.asset.print-asset" />
              </NewInlineModal.Dropdown.Item>
              {this.renderPrintQrCodeDropdownItem()}
              {this.props.canEditAssets ? <NewInlineModal.Dropdown.Separator /> : null}
              {this.renderArchiveDropdownItem()}
              {this.renderDeleteDropdownItem()}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
        {this.renderDeleteAssetModal()}
        <PrintQRCodeModal
          templateType="asset"
          open={this.state.showPrintQrCodeModal}
          assetId={this.props.match.params.id}
          onClose={() => this.setState({ showPrintQrCodeModal: false })}
        />
        <PrintModal
          open={this.state.showPrintAssetModal}
          singleAssetId={this.props.match.params.id}
          exportType={ExportType.AssetsDetailPdf}
          onClose={() => this.setState({ showPrintAssetModal: false })}
        />
        <ArchiveAssetModal
          open={this.state.showArchiveAssetModal}
          asset={this.props.asset}
          onSave={() => this.setState({ showArchiveAssetModal: false })}
          onClose={() => this.setState({ showArchiveAssetModal: false })}
        />
        <UnarchiveAssetModal
          open={this.state.showUnarchiveAssetModal}
          asset={this.props.asset}
          onSave={() => this.setState({ showUnarchiveAssetModal: false })}
          onClose={() => this.setState({ showUnarchiveAssetModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAsset: SDKReduxOperations.deleteAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.match.params.id),
    canEditAssets: AuthSelectors.canEditAssets(state),
    navigatedTo: AssetsSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
