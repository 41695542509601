import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import { FormattedMessage } from 'react-intl';
import { Button, WarningMessage } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      showHasDeliveriesError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isDeleting: false,
        showHasDeliveriesError: false,
      });
    }
  }

  delete = () => {
    if (this.state.isDeleting || this.state.showHasDeliveriesError) return;
    this.setState({ isDeleting: true });
    this.props
      .deletePurchaseOrder(this.props.purchaseOrder.id)
      .then(() => {
        this.props.onDelete();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '110005' })) {
          this.setState({ showHasDeliveriesError: true, isDeleting: false });
        } else {
          this.setState({ isDeleting: false });
        }
      });
  };

  renderSubtitle = () => {
    const hasToCancelPurchaseOrder =
      this.props.purchaseOrder.status === PurchaseOrderStatus.Ordered && !this.props.purchaseOrder.canceled;
    if (hasToCancelPurchaseOrder) {
      return null;
    } else {
      return <FormattedMessage id="screens.purchase-order.delete-modal.subtitle" />;
    }
  };

  renderContent = () => {
    const hasToCancelPurchaseOrder =
      this.props.purchaseOrder.status === PurchaseOrderStatus.Ordered && !this.props.purchaseOrder.canceled;
    if (hasToCancelPurchaseOrder) {
      return (
        <Modal.Content>
          <WarningMessage type="warning">
            <FormattedMessage id="screens.purchase-order.delete-modal.must-be-canceled" />
          </WarningMessage>
        </Modal.Content>
      );
    }

    return (
      <React.Fragment>
        {this.state.showHasDeliveriesError ? (
          <Modal.Content>
            <WarningMessage type="destructive">
              <FormattedMessage id="screens.purchase-order.delete-modal.delete-error" />
            </WarningMessage>
          </Modal.Content>
        ) : null}
        <Modal.Footer>
          <Button.Group>
            <Button
              destructive
              primary
              label="general.delete"
              loading={this.state.isDeleting}
              onClick={this.delete}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="screens.purchase-order.delete-modal.title" />}
          subtitle={this.renderSubtitle()}
          subtitleTopMargin
          onClose={this.props.onClose}
          ignoreLine
        />
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deletePurchaseOrder: SDKReduxOperations.deletePurchaseOrder,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DeleteModal);
