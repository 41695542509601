import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import axios from 'axios';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { NewWorkOrderSelectors, NewWorkOrderOperations } from 'state/ducks/newWorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations } from 'state/ducks/error';
import { Button } from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { UploadAttachmentErrorModal } from 'views/components/Attachment';
import { SideModal } from 'views/components/Shared/Layout';
import { buildWorkOrderFromEditingWorkOrder } from 'sdk/WorkOrder';
import { buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import PlanWorkOrderContent from './PlanWorkOrderContent';

const uploadFilesRequest = HelperFunctions.getCancelTokenForRequest();

class NewOperationalMaintenanceModal extends Component {
  getInitialState = () => ({
    isCreatingWorkOrder: false,
    isCreatingWorkOrderAndCreatingNew: false,
    closeSideModalOnClickOverlay: true,
    closeSideModalOnClickEscape: true,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(
        {
          ...this.getInitialState(),
        },
        () => {
          this.props.prepareNewWorkOrder();
        }
      );
    } else if (prevProps.open && !this.props.open) {
      this.props.resetNewWorkOrderState();
      this.props.removeFieldsFromErrors(['completed_date', 'title', 'date', 'asset', 'interval']);
    }
  }

  close = () => {
    uploadFilesRequest.cancel();
    this.props.onClose();
  };

  isRecurringMaintenance = () => {
    return this.props.editingWorkOrder.frequency != null;
  };

  isSavable = () => {
    let error = {};
    if (this.props.editingWorkOrder.title.length === 0) {
      error = { ...error, title: 'general.errors.is-required' };
    }
    if (this.props.editingWorkOrder.date == null) {
      error = { ...error, date: 'general.errors.is-required' };
    }
    if (this.props.editingWorkOrder.asset_id == null) {
      error = { ...error, asset: 'general.errors.is-required' };
    }
    if (this.isRecurringMaintenance() === false) {
      error = { ...error, interval: 'general.errors.is-required' };
    }
    if (this.isRecurringMaintenance()) {
      const dueDateIsBeforeToday = moment(this.props.editingWorkOrder.date).isBefore(
        moment().format('YYYY-MM-DD'),
        'day'
      );
      if (dueDateIsBeforeToday) {
        error = {
          ...error,
          date: 'screens.work-order.errors.selected-date-in-past',
        };
      }
    }

    if (Object.keys(error).length > 0) {
      this.props.setFieldErrors(error);
      return false;
    }
    return true;
  };

  createWorkOrder = createNew => {
    if (this.state.isCreatingWorkOrder) return;
    if (!this.isSavable()) {
      return;
    }

    this.setState({
      isCreatingWorkOrder: createNew === false,
      isCreatingWorkOrderAndCreatingNew: createNew === true,
    });

    if (this.isRecurringMaintenance()) {
      const params = buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder);

      this.props
        .createRecurringMaintenance(this.props.currentSystem.id, { ...params, operational_maintenance: true })
        .then(({ data: recurringMaintenance }) => {
          toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.create-success" />} />);
          if (createNew) {
            this.props.onCreatedWithReopen(recurringMaintenance.generated_work_order);
          } else {
            this.props.onCreated(recurringMaintenance.generated_work_order);
          }
        })
        .catch(e => {
          //TODO: Replace with generic error message
        });
    } else {
      const params = buildWorkOrderFromEditingWorkOrder(this.props.editingWorkOrder);

      this.props
        .createWorkOrder(this.props.currentSystem.id, { ...params, operational_maintenance: true })
        .then(({ data: workOrder }) => {
          if (workOrder.completed_before_registration) {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.work-order.create-completed-before-registration-success" />
                }
              />
            );
          } else {
            toast(
              <ToastMessage success text={<FormattedMessage id="screens.work-order.create-success" />} />
            );
          }
          if (createNew) {
            this.props.onCreatedWithReopen(workOrder);
          } else {
            this.props.onCreated(workOrder);
          }
        })
        .catch(e => {
          //TODO: Replace with generic error message
        });
    }
  };

  createAttachment = ({ description, title, extension, file, type, mime_type, link_url }) => {
    this.createDraftRegistrationAttachment({
      description,
      title,
      extension,
      file,
      type,
      mime_type,
      link_url,
    });
  };

  createDraftRegistrationAttachment = ({
    description,
    title,
    extension,
    file,
    type,
    mime_type,
    link_url,
  }) => {
    const temporaryId = uuid.v4();
    const workOrderId = this.props.editingWorkOrder.id;
    this.props.beginUpload({ temporaryId, namespace: this.props.editingWorkOrder.id });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: workOrderId,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  workOrderId: this.props.editingWorkOrder.id,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.editingWorkOrder.id,
                  });
                });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: this.props.editingWorkOrder.id,
          });
        });
    }
  };

  deleteAttachment = attachment => {
    this.props.setEditingWorkOrderValues({
      registration_attachments: this.props.editingWorkOrder.registration_attachments.filter(
        registrationAttachmentId => registrationAttachmentId !== attachment.id
      ),
    });
  };

  renderContent = () => {
    if (this.props.editingWorkOrder == null) {
      return null;
    }
    return (
      <>
        <PlanWorkOrderContent
          onShowChecklistModal={() =>
            this.setState({
              closeSideModalOnClickOverlay: false,
              closeSideModalOnClickEscape: false,
            })
          }
          onHideChecklistModal={() =>
            this.setState({
              closeSideModalOnClickOverlay: true,
              closeSideModalOnClickEscape: true,
            })
          }
          editingWorkOrder={this.props.editingWorkOrder}
          onChangeValue={params => this.props.setEditingWorkOrderValues(params)}
          onCreateAttachment={params => this.createDraftRegistrationAttachment(params)}
          onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
        />

        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal
          closeOnEscape={this.state.closeSideModalOnClickEscape}
          closeOnClickOverlay={this.state.closeSideModalOnClickOverlay}
          open={this.props.open}
          onClose={() => {
            if (this.props.hasUnsavedChanges) {
              const confirmed = window.confirm(
                this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
              );
              if (confirmed) {
                this.close();
              }
            } else {
              this.close();
            }
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createWorkOrder(false)}
                loading={this.state.isCreatingWorkOrder}
              />
              <Button
                label="general.save-and-create-new"
                loading={this.state.isCreatingWorkOrderAndCreatingNew}
                onClick={() => this.createWorkOrder(true)}
              />
              <Button label="general.cancel" onClick={this.close} />
            </Button.Group>
          }
        >
          <DropZone onDrop={data => this.createAttachment(data)}>
            <SideModal.Container>
              <SideModal.Container.Content>
                <SideModal.Header
                  title={<FormattedMessage id="components.new-operational-maintenance-modal.title" />}
                  onClose={this.close}
                />
                {this.renderContent()}
              </SideModal.Container.Content>
            </SideModal.Container>
          </DropZone>
        </SideModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createWorkOrder: SDKReduxOperations.createWorkOrder,
      createRecurringMaintenance: SDKReduxOperations.createRecurringMaintenance,
      setFieldErrors: ErrorOperations.setFieldErrors,
      prepareNewWorkOrder: NewWorkOrderOperations.prepareNewWorkOrder,
      setEditingWorkOrderValues: NewWorkOrderOperations.setEditingWorkOrderValues,
      resetNewWorkOrderState: NewWorkOrderOperations.resetState,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createDraftAttachment: SDKReduxOperations.createDraftAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasUnsavedChanges: NewWorkOrderSelectors.hasUnsavedChanges(state),
    editingWorkOrder: NewWorkOrderSelectors.getEditingWorkOrder(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewOperationalMaintenanceModal));

NewOperationalMaintenanceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  closeOnClickOverlay: PropTypes.bool,
};

NewOperationalMaintenanceModal.defaultProps = {
  open: false,
  closeOnClickOverlay: true,
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
};
