const DOMAIN_PREFIX = 'downtimes/';

const ADD_QUERY_PARAMETER = `${DOMAIN_PREFIX}add_query_parameter`;
const FETCH_DOWNTIMES_SUCCESS = `${DOMAIN_PREFIX}fetch_downtimes_success`;
const RESET_SELECTED_DOWNTMES = `${DOMAIN_PREFIX}reset_selected_downtimes`;
const SELECT_DOWNTIME = `${DOMAIN_PREFIX}select_downtime`;
const SELECT_PAGE = `${DOMAIN_PREFIX}select_page`;
const HIDE_SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}hide_select_total_entries`;
const SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}select_total_entries`;

export default {
  DOMAIN_PREFIX,
  FETCH_DOWNTIMES_SUCCESS,
  ADD_QUERY_PARAMETER,
  SELECT_PAGE,
  SELECT_DOWNTIME,
  RESET_SELECTED_DOWNTMES,
  HIDE_SELECT_TOTAL_ENTRIES,
  SELECT_TOTAL_ENTRIES,
};
