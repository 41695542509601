import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { OperationalMaintenanceSelectors } from 'state/ducks/operationalMaintenance';
import { ChecklistTemplateRow } from 'views/components/Checklist';

class Inspection extends Component {
  render() {
    const { instanceRow } = this.props;
    return (
      <ChecklistTemplateRow.Inspection
        id={this.props.id}
        instanceRowId={instanceRow.id}
        key={instanceRow.id}
        editable={this.props.canCarryOutOperationalMaintenances}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    instanceRow: OperationalMaintenanceSelectors.getInstanceRowForTemplateRowId(state, ownProps.id),
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
  };
}

export default connect(mapStateToProps)(Inspection);
