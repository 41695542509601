import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { Icon, Field } from 'views/components/Shared/General';
import styles from './style.module.scss';
import Column from './Column';
import IconColumn from './IconColumn';
import TitleColumn from './TitleColumn';
import ImageColumn from './ImageColumn';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  getPaddingLeft = () => {
    if (this.props.expandable && !this.props.checkbox) return 0;
    let defaultPadding = this.props.light ? 20 : 30;
    return this.props.paddingLeft || defaultPadding;
  };

  getPaddingRight = () => {
    let defaultPadding = this.props.light ? 20 : 30;
    return this.props.paddingRight || defaultPadding;
  };

  renderExpandableButton = () => {
    if (!this.props.expandable) return;

    let classNames = [styles['expandable-button']];
    if (this.state.expanded) classNames = [...classNames, styles['expanded']];
    if (this.props.checkbox) classNames = [...classNames, styles['has-checkbox']];
    if (this.props.loading) classNames = [...classNames, styles['loading']];

    const width = this.props.paddingLeft ? this.props.paddingLeft : 50;
    const style = {
      width,
      minWidth: width,
      maxWidth: width,
      alignItems: this.props.alignItems ? this.props.alignItems : null,
    };

    return (
      <div
        style={style}
        className={classNames.join(' ')}
        onClick={e => {
          if (this.props.loading) return null;
          e.stopPropagation();
          e.preventDefault();
          this.setState(prevState => ({
            expanded: !prevState.expanded,
          }));
        }}
      >
        <div className={styles['hover-container']}>
          <Icon size={24} light type="angle-right" />
        </div>
      </div>
    );
  };

  renderExpandedComponent = () => {
    if (this.props.expandable === false) return null;
    if (this.props.expandedComponent == null) return null;

    let style = {
      paddingRight: this.getPaddingRight(),
    };

    if (this.props.paddingLeft !== undefined) {
      style = {
        ...style,
        paddingLeft: this.props.paddingLeft,
      };
    } else if (!this.props.checkbox && this.props.expandable) {
      style = {
        ...style,
        paddingLeft: 50,
      };
    } else {
      style = {
        ...style,
        paddingLeft: 30,
      };
    }

    return (
      <AnimateHeight duration={250} height={this.state.expanded ? 'auto' : 0}>
        <div className={styles['expanded-container']} style={style}>
          {this.props.expandedComponent}
        </div>
      </AnimateHeight>
    );
  };

  renderOuterExpandedComponent = () => {
    if (this.props.expandable === false) return null;
    if (this.props.outerExpandedComponent == null) return null;
    return (
      <AnimateHeight
        duration={250}
        height={this.state.expanded ? 'auto' : 0}
        contentClassName={styles['outer-expand']}
      >
        <div className={styles['outer-expanded-container']}>{this.props.outerExpandedComponent}</div>
      </AnimateHeight>
    );
  };

  renderIconContent = () => {
    if (this.props.icon == null) return null;
    return <div className={styles['icon-container']}>{this.props.icon}</div>;
  };

  renderButtonsContent = () => {
    if (this.props.iconButtons) {
      return (
        <div className={styles['buttons-container']} onClick={e => e.stopPropagation()}>
          {this.props.iconButtons}
        </div>
      );
    }
    return null;
  };

  renderDisclosureIndiciatorContent = () => {
    if (!this.props.disclosureIndicator) return null;

    const style = {
      marginRight: -8,
      marginLeft: 22,
    };

    return (
      <div style={style} className={styles['disclosure-indicator-container']}>
        <Icon light type="angle-right" />
      </div>
    );
  };

  renderCheckbox = () => {
    if (this.props.checkbox) {
      let classNames = [styles['checkbox-container']];
      if (this.props.checkboxDisabled) {
        classNames = [...classNames, styles['disabled']];
      }
      return (
        <div
          className={classNames.join(' ')}
          style={{ paddingLeft: this.getPaddingLeft() }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (this.props.checkboxDisabled === false) {
              this.props.onCheck();
            }
          }}
        >
          <Field.Checkbox
            disabled={this.props.checkboxDisabled}
            checked={this.props.checked}
            onChange={this.props.onCheck}
          />
        </div>
      );
    }

    return null;
  };

  renderContent = () => {
    let classNames = [styles['content-container']];
    if (this.props.clickable || this.props.linkTo) classNames = [...classNames, styles['clickable']];
    if (this.props.loading) classNames = [...classNames, styles['loading']];
    if (this.props.small) classNames = [...classNames, styles['small']];
    let style = {
      paddingRight: this.getPaddingRight(),
    };
    if (this.props.checkbox === false) {
      style = {
        ...style,
        paddingLeft: this.getPaddingLeft(),
      };
    }

    const content = (
      <div
        className={classNames.join(' ')}
        style={style}
        onClick={() => (this.props.clickable ? this.props.onClick() : null)}
      >
        <div className={styles['content']}>
          {this.renderCheckbox()}
          {this.renderExpandableButton()}
          <div
            className={styles['columns']}
            style={{ alignItems: this.props.alignItems ? this.props.alignItems : 'center' }}
          >
            {this.props.children}
          </div>
          {this.renderButtonsContent()}
          {this.renderDisclosureIndiciatorContent()}
        </div>
        {this.renderDataPillsComponent()}
      </div>
    );

    if (this.props.linkTo) {
      return (
        <Link className={styles['link']} to={this.props.linkTo}>
          {content}
        </Link>
      );
    }
    return content;
  };

  renderDataPillsComponent = () => {
    if (this.props.dataPillsComponent == null) {
      return null;
    }
    let classNames = [styles['datapills']];
    if (this.props.checkbox) classNames = [...classNames, styles['checkbox']];
    if (this.props.expandable) classNames = [...classNames, styles['expandable']];
    return <div className={classNames.join(' ')}>{this.props.dataPillsComponent}</div>;
  };

  render() {
    let classNames = [styles['item']];
    if (this.props.expandable) classNames = [...classNames, styles['expandable']];
    if (this.props.disabled) classNames = [...classNames, styles['disabled']];
    if (this.props.isDragging) classNames = [...classNames, styles['is-dragging']];
    return (
      <div className={styles['item-container']}>
        <div
          className={classNames.join(' ')}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          {this.renderContent()}
          {this.renderExpandedComponent()}
        </div>
        {this.renderOuterExpandedComponent()}
      </div>
    );
  }
}

Item.Column = Column;
Item.TitleColumn = TitleColumn;
Item.ImageColumn = ImageColumn;
Item.IconColumn = IconColumn;

export default Item;

Item.propTypes = {
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  checkbox: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  checked: PropTypes.bool,
  clickable: PropTypes.bool,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  iconButtons: PropTypes.node,
  expandedComponent: PropTypes.node,
  disclosureIndicator: PropTypes.bool,
  small: PropTypes.bool,
  icon: PropTypes.string,
  imageSrc: PropTypes.string,
  linkTo: PropTypes.any,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  isDragging: PropTypes.bool,
  dataPillsComponent: PropTypes.node,
};
Item.defaultProps = {
  onClick: () => {},
  onCheck: () => {},
  clickable: false,
  checkbox: false,
  checkboxDisabled: false,
  checked: false,
  small: false,
  expandable: false,
  disabled: false,
  iconButtons: null,
  expandedComponent: null,
  disclosureIndicator: false,
  linkTo: null,
  icon: null,
  imageSrc: null,
  isDragging: false,
  dataPillsComponent: null,
};
