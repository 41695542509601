import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Button, Tooltip, NewInlineModal } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { Title } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class PreviewWorkOrderHeader extends Component {
  state = {
    dropdownOpen: false,
  };
  render() {
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']} title={this.props.workOrder.title}>
          <div className={styles['title']}>
            {/*<Title workOrder={this.props.workOrder} />TODO */} {this.props.workOrder.title}
          </div>
          <div className={styles['buttons-container']}>
            <div
              ref={ref => (this.inlineModalPositioningRef = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  dropdownOpen: !prevState.dropdownOpen,
                }));
              }}
            >
              <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
            </div>
            <NewInlineModal
              minWidth={250}
              position="right"
              positionToRef={this.inlineModalPositioningRef}
              open={this.state.dropdownOpen}
              onClose={() => this.setState({ dropdownOpen: false })}
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ dropdownOpen: false });
                      this.props.onRemoveFromWorkOrder(this.props.workOrderId);
                    }}
                  >
                    <FormattedMessage id="components.downtime-modal.remove-from-downtime" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
            <Tooltip
              trigger={
                <Link
                  to={{
                    pathname: `/work-orders/${this.props.workOrder.id}`,
                  }}
                >
                  <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.work-order" />}
            />
          </div>
        </div>
        {this.props.asset == null ? null : <TreePath size={13} fullPath assetId={this.props.asset.id} />}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.workOrderId);
  return {
    workOrder,
    asset: EntitySelectors.getAsset(state, workOrder.asset_id),
  };
}

export default connect(mapStateToProps)(PreviewWorkOrderHeader);
