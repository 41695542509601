import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { RequestsOperations, RequestsSelectors } from 'state/ducks/requests';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Pagination, FilterButton, Field, SortingButton } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { LIST_TYPES, getParamsForList } from 'sdk/Request';
import styles from './style.module.scss';
import RequestImage from 'assets/images/EmptyDataSet/Requests.png';
import RequestListItem from './RequestListItem';
import DetailContainer from '../DetailContainer';
import MassEditModal from './MassEditModal';
import ExportModal from './ExportModal';
import PrintModal from './PrintModal';
import AssigneeFilterDropdown from '../AssigneeFilterDropdown';
import ApproveForAssetsDropdown from '../ApproveForAssetsDropdown';

class ListView extends Component {
  state = {
    showMassEditModal: false,
    showExportModal: false,
    showPrintModal: false,
  };

  componentDidMount() {
    const { list } = queryString.parse(this.props.location.search);
    const sort = getParamsForList(list, this.props.currentUser.id)['sort'];
    const sortOrder = getParamsForList(list, this.props.currentUser.id)['sort-order'];
    if (this.props.queryParameters?.sort == null) {
      this.addQueryParameter({ sort, 'sort-order': sortOrder });
    }
  }

  componentDidUpdate(prevProps) {
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list: newList } = queryString.parse(this.props.location.search);
    const changedList = oldList !== newList;
    if (changedList) {
      const sort = getParamsForList(newList, this.props.currentUser.id)['sort'];
      const sortOrder = getParamsForList(newList, this.props.currentUser.id)['sort-order'];
      this.addQueryParameter({ sort, 'sort-order': sortOrder });
    }
  }

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  getSortOptions = () => {
    const { list } = queryString.parse(this.props.location.search);

    let sortOptions = [];
    if (list === LIST_TYPES.WorkOrderCreated) {
      sortOptions = [
        ...sortOptions,
        {
          label: <FormattedMessage id="resources.work-order.due-date" />,
          sort: 'work_order_due_date',
        },
      ];
    } else if (list === LIST_TYPES.WorkOrderCompleted) {
      sortOptions = [
        ...sortOptions,
        {
          label: <FormattedMessage id="resources.work-order.completed-at" />,
          sort: 'work_order_completed_date',
        },
      ];
    } else if (list === LIST_TYPES.Archive) {
      sortOptions = [
        ...sortOptions,
        {
          label: <FormattedMessage id="resources.request.archived-at" />,
          sort: 'archived_at',
        },
      ];
    }
    sortOptions = [
      ...sortOptions,
      {
        label: <FormattedMessage id="resources.request.created-at" />,
        sort: 'created_at',
      },
      {
        label: <FormattedMessage id="resources.request.title" />,
        sort: 'title',
      },
      { label: <FormattedMessage id="resources.request.priority" />, sort: 'priority' },
      {
        label: <FormattedMessage id="resources.request.request-type" />,
        sort: 'request_type_title',
      },
    ];
    return sortOptions;
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.search?.length > 0;
  };

  renderCheckAllCheckbox = () => {
    const { totalEntriesIsSelected, pageIsSelected, isFetching } = this.props;
    return (
      <Field.Checkbox
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onChange={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedRequests();
          } else {
            this.props.selectPage();
          }
        }}
      />
    );
  };

  renderHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected, isFetching } = this.props;
    return (
      <List.Header
        small
        background
        showMultipleOptions={this.props.selectedRequestsCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.isFetching}
            count={this.props.selectedRequestsCount}
            checkbox={this.renderCheckAllCheckbox()}
            buttons={
              <>
                {this.props.canAdministrateRequests ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.requests.header-buttons.edit" />}
                    onClick={() => this.setState({ showMassEditModal: true })}
                  />
                ) : null}

                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="general.print" />}
                  onClick={() =>
                    this.setState({
                      showPrintModal: true,
                    })
                  }
                />
                {this.props.canAdministrateRequests ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.requests.header-buttons.export" />}
                    onClick={() =>
                      this.setState({
                        showExportModal: true,
                      })
                    }
                  />
                ) : null}
              </>
            }
          />
        }
        checkbox
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedRequests();
          } else {
            this.props.selectPage();
          }
        }}
      >
        {this.renderHeaderColumns()}
      </List.Header>
    );
  };

  renderHeaderColumns = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.ToPlan: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="resources.request.priority" />
            </List.Header.Column>
            <List.Header.Column width={205}>
              <FormattedMessage id="resources.request.request-type" />
            </List.Header.Column>
          </>
        );
      }
      case LIST_TYPES.WorkOrderCreated: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.planned-to" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.assignee" />
            </List.Header.Column>
            <List.Header.Column width={45} />
          </>
        );
      }
      case LIST_TYPES.WorkOrderCompleted: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.completed-date" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.completed-by" />
            </List.Header.Column>
            <List.Header.Column width={45} />
          </>
        );
      }
      case LIST_TYPES.Archive: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.archived-at" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="screens.requests.list-header-columns.archived-by" />
            </List.Header.Column>
            <List.Header.Column width={45} />
          </>
        );
      }
      default: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="resources.request.priority" />
            </List.Header.Column>
            <List.Header.Column width={150}>
              <FormattedMessage id="resources.request.request-type" />
            </List.Header.Column>
            <List.Header.Column width={45} />
          </>
        );
      }
    }
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.props.isFetching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.requests.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedRequests()}
        />
      );
    }
    return null;
  };

  renderEmptyDataSetTitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.ToPlan: {
        return <FormattedMessage id="screens.requests.empty-data-sets.to-plan.title" />;
      }
      case LIST_TYPES.WorkOrderCreated: {
        return <FormattedMessage id="screens.requests.empty-data-sets.work-order-created.title" />;
      }
      case LIST_TYPES.WorkOrderCompleted: {
        return <FormattedMessage id="screens.requests.empty-data-sets.work-order-completed.title" />;
      }
      case LIST_TYPES.Archive: {
        return <FormattedMessage id="screens.requests.empty-data-sets.archived.title" />;
      }
      case LIST_TYPES.MyCreated: {
        return <FormattedMessage id="screens.requests.empty-data-sets.my-created.title" />;
      }
      case LIST_TYPES.CreatedForAsset: {
        return <FormattedMessage id="screens.requests.empty-data-sets.created-for-asset.title" />;
      }
      default:
        return <FormattedMessage id="screens.requests.empty-data-sets.all.title" />;
    }
  };

  renderEmptyDataSetSubtitle = () => {
    return <FormattedMessage id="screens.requests.empty-data-sets.subtitle" />;
  };

  renderEmptyDataSet = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
        <div className={styles['subtitle']}>{this.renderEmptyDataSetSubtitle()}</div>
        <div className={styles['image-container']}>
          <img src={RequestImage} alt="" />
        </div>
      </div>
    );
  };

  renderEmptyDataSetWhenFiltering = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={RequestImage} alt="" />
      </div>
    </div>
  );

  renderPagination = () => {
    if (this.props.requests.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderList = () => {
    if (this.props.isFetching) {
      const amountOfRequests = this.props.requests.length === 0 ? 2 : this.props.requests.length;
      return (
        <>
          {this.renderHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfRequests)
              .fill()
              .map(() => (
                <RequestListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.requests.length === 0) {
      if (this.isFiltering()) {
        return this.renderEmptyDataSetWhenFiltering();
      }
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.requests.map(request => {
            let checked = false;
            if (this.props.selectedRequestIds[request.id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <RequestListItem
                key={request.id}
                id={request.id}
                list={queryString.parse(this.props.location.search).list}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={e => {
                  this.props.selectRequest(request.id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderFilterBarLeftSideContent = () => {
    const { list } = queryString.parse(this.props.location.search);
    let showAssigneeDropdown = true;
    if (this.props.hasProTier === false) {
      showAssigneeDropdown = false;
    }
    if (this.props.canAdministrateRequests === false && this.props.isViewOnly === false) {
      showAssigneeDropdown = false;
    }
    if (this.props.settings.request_multiple_assignees_active === false) {
      showAssigneeDropdown = false;
    }
    if (list === LIST_TYPES.ToApprove) {
      showAssigneeDropdown = false;
    }
    if (showAssigneeDropdown) {
      return <AssigneeFilterDropdown onFilter={filters => this.addQueryParameter({ ...filters })} />;
    }
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    if (list === LIST_TYPES.ToApprove && has_asset_production_supervisors) {
      return (
        <ApproveForAssetsDropdown
          onFilter={filters => {
            this.props.addQueryParameter({ page: null, ...filters });
          }}
        />
      );
    }
    return null;
  };

  renderFilterBar = () => {
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.FilterBar.LeftContent>
          {this.renderFilterBarLeftSideContent()}
        </ListLayout.Content.MainContent.FilterBar.LeftContent>
        <ListLayout.Content.MainContent.FilterBar.RightContent>
          <FilterButton.Group>
            <SortingButton
              sortOptions={this.getSortOptions()}
              sort={this.props.queryParameters.sort}
              sortOrder={this.props.queryParameters['sort-order']}
              onSort={sort => this.addQueryParameter(sort)}
            />
            {this.props.isFiltering ? (
              <FilterButton
                clearable={false}
                caret={false}
                label={<FormattedMessage id="general.clean" />}
                onClick={() => {
                  this.props.resetFilter();
                  this.props.setLoading();
                }}
              />
            ) : null}
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="screens.work-orders.filter" />}
              filtered={this.props.isFiltering}
              onClick={this.props.onShowFilter}
            />
          </FilterButton.Group>
        </ListLayout.Content.MainContent.FilterBar.RightContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderMassEditModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <MassEditModal
        open={this.state.showMassEditModal}
        listParams={getParamsForList(list, this.props.currentUser.id)}
        onSave={() => {
          this.props.onMassUpdate();
          this.setState({ showMassEditModal: false });
        }}
        onClose={() => {
          this.setState({ showMassEditModal: false });
        }}
      />
    );
  };

  renderExportModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ExportModal
        open={this.state.showExportModal}
        listParams={getParamsForList(list, this.props.currentUser.id)}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderPrintModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <PrintModal
        open={this.state.showPrintModal}
        listParams={getParamsForList(list, this.props.currentUser.id)}
        onClose={() => {
          this.setState({ showPrintModal: false });
        }}
      />
    );
  };

  renderListView = () => {
    return (
      <ListLayout.Content.MainContent>
        {this.renderFilterBar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderDetailView = () => {
    return <DetailContainer location={this.props.location} showClose />;
  };

  renderContent = () => {
    const { request_id } = queryString.parse(this.props.location.search);
    if (request_id || this.props.isNewRequest) {
      return this.renderDetailView();
    }
    return this.renderListView();
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderMassEditModal()}
        {this.renderExportModal()}
        {this.renderPrintModal()}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    requests: RequestsSelectors.getRequests(state),
    pagination: RequestsSelectors.getPagination(state),
    isFetching: RequestsSelectors.isFetching(state),
    isNewRequest: RequestsSelectors.isNewRequest(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    settings: AuthSelectors.getSettings(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasProTier: AuthSelectors.hasProTier(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    filters: RequestsSelectors.getFilters(state),
    queryParameters: RequestsSelectors.getQueryParameters(state),
    isFiltering: RequestsSelectors.isFiltering(state),
    selectedRequestIds: RequestsSelectors.getSelectedRequestIds(state),
    selectedRequestsCount: RequestsSelectors.getSelectedRequestCount(state),
    totalEntriesIsSelected: RequestsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: RequestsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: RequestsSelectors.getPageIsSelected(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoading: RequestsOperations.setLoading,
      resetFilter: RequestsOperations.resetFilter,
      addQueryParameter: RequestsOperations.addQueryParameter,
      fetchRequestsForList: RequestsOperations.fetchRequestsForList,
      selectRequest: RequestsOperations.selectRequest,
      selectPage: RequestsOperations.selectPage,
      resetSelectedRequests: RequestsOperations.resetSelectedRequests,
      selectTotalEntries: RequestsOperations.selectTotalEntries,
      hideSelectTotalEntries: RequestsOperations.hideSelectTotalEntries,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListView));
