import HelperFunctions from '../utilities/HelperFunctions';
import { getWeekdayOfMonth, getDayOfMonth, getWeekOfMonth, getMonthOfYear } from 'sdk/RecurringMaintenance';

export function buildIntervalMessage(recurringMaintenance, intl) {
  const { frequency, generate_from_completed_date } = recurringMaintenance;
  if (generate_from_completed_date) {
    return buildFromCompletedDateMessage(recurringMaintenance, intl);
  } else {
    switch (frequency) {
      case 'daily':
        return buildDailyMessage(recurringMaintenance, intl);
      case 'weekly':
        return buildWeeklyMessage(recurringMaintenance, intl);
      case 'monthly':
        return buildMonthlyMessage(recurringMaintenance, intl);
      case 'yearly':
        return buildYearlyMessage(recurringMaintenance, intl);

      default:
        return '';
    }
  }
}

function getDayFromIndex(i) {
  return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'][i];
}
function getDayString(day, intl) {
  return intl.formatMessage({
    id: `resources.work-order.interval.messages.days.${day}`,
  });
}
function getDaysString(days, intl) {
  const and = intl.formatMessage({
    id: 'resources.work-order.interval.messages.and',
  });
  return [days.slice(0, -1).join(', '), days.slice(-1)[0]].join(days.length < 2 ? '' : ` ${and} `);
}

function buildDailyMessage(recurringMaintenance, intl) {
  const { mon, tue, wed, thu, fri, sat, sun } = recurringMaintenance;
  const count = [mon, tue, wed, thu, fri, sat, sun].filter(b => b).length;

  if (count === 7) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.daily.every',
    });
  } else if (count === 6) {
    const day = getDayFromIndex([mon, tue, wed, thu, fri, sat, sun].findIndex(b => !b));

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.daily.every-except' },
      { day: getDayString(day, intl) }
    );
  } else if (mon && tue && wed && thu && fri && !sat && !sun) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.daily.weekdays',
    });
  } else if (count === 4 && !sat && !sun) {
    const day = getDayFromIndex([mon, tue, wed, thu, fri].findIndex(b => !b));

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.daily.weekdays-except' },
      { day: getDayString(day, intl) }
    );
  }

  const days = [mon, tue, wed, thu, fri, sat, sun]
    .reduce((a, e, i) => (e ? a.concat(i) : a), [])
    .map(getDayFromIndex)
    .map(day => getDayString(day, intl));

  return intl.formatMessage(
    { id: 'resources.work-order.interval.messages.daily.specific' },
    { days: getDaysString(days, intl) }
  );
}

function buildWeeklyMessage(recurringMaintenance, intl) {
  const { interval, mon, tue, wed, thu, fri, sat, sun } = recurringMaintenance;
  const count = [mon, tue, wed, thu, fri, sat, sun].filter(b => b).length;
  const days = [mon, tue, wed, thu, fri, sat, sun]
    .reduce((a, e, i) => (e ? a.concat(i) : a), [])
    .map(getDayFromIndex)
    .map(day => getDayString(day, intl));

  if (interval === 1) {
    if (count === 0) {
      return intl.formatMessage({
        id: 'resources.work-order.interval.messages.weekly.every',
      });
    } else if (count <= 3) {
      return intl.formatMessage(
        { id: 'resources.work-order.interval.messages.weekly.every-with-days' },
        { days: getDaysString(days, intl) }
      );
    } else {
      return buildDailyMessage(recurringMaintenance, intl);
    }
  } else if (interval === 2) {
    if (count === 0) {
      return intl.formatMessage({
        id: 'resources.work-order.interval.messages.weekly.every-other',
      });
    } else {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.weekly.every-other-with-days',
        },
        { days: getDaysString(days, intl) }
      );
    }
  } else {
    if (count === 0) {
      return intl.formatMessage(
        { id: 'resources.work-order.interval.messages.weekly.rest' },
        { num: HelperFunctions.getOrdinalSuffix(intl, interval) }
      );
    } else {
      return intl.formatMessage(
        { id: 'resources.work-order.interval.messages.weekly.rest-with-days' },
        {
          num: HelperFunctions.getOrdinalSuffix(intl, interval),
          days: getDaysString(days, intl),
        }
      );
    }
  }
}

function buildMonthlyMessage(recurringMaintenance, intl) {
  const { interval, month_type, start_date } = recurringMaintenance;

  if (start_date == null) {
    return buildNoDateMonthlyMessage(recurringMaintenance, intl);
  }

  const weekdayOfMonth = getWeekdayOfMonth(start_date);
  const weekOfMonth = getWeekOfMonth(start_date);
  const dayOfMonth = getDayOfMonth(start_date);

  if (month_type === 'day_of_week') {
    let num;
    switch (weekOfMonth) {
      case 1:
        num = 'first';
        break;
      case 2:
        num = 'second';
        break;
      case 3:
        num = 'third';
        break;
      case 4:
        num = 'fourth';
        break;
      case -1:
        num = 'last';
        break;
      default:
        break;
    }

    const props = {
      week: intl.formatMessage({
        id: `resources.work-order.interval.messages.monthly.${num}`,
      }),
      day: intl.formatMessage({
        id: `resources.work-order.interval.messages.monthly.${weekdayOfMonth}`,
      }),
    };

    if (interval === 1) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.monthly.exact-day-every',
        },
        props
      );
    } else if (interval === 2) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.monthly.exact-day-every-other',
        },
        props
      );
    }

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.monthly.exact-day-rest' },
      {
        ...props,
        num: HelperFunctions.getOrdinalSuffix(intl, interval),
      }
    );
  } else if (month_type === 'exact_day') {
    if (interval === 1) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.monthly.day-of-week-every',
        },
        { day: HelperFunctions.getOrdinalSuffix(intl, dayOfMonth) }
      );
    } else if (interval === 2) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.monthly.day-of-week-every-other',
        },
        { day: HelperFunctions.getOrdinalSuffix(intl, dayOfMonth) }
      );
    }

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.monthly.day-of-week-rest' },
      {
        num: HelperFunctions.getOrdinalSuffix(intl, interval),
        day: HelperFunctions.getOrdinalSuffix(intl, dayOfMonth),
      }
    );
  }
  return null;
}

function buildNoDateMonthlyMessage(recurringMaintenance, intl) {
  const { interval } = recurringMaintenance;

  if (interval === 1) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.monthly.interval-one-short',
    });
  } else if (interval === 2) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.monthly.interval-one-short',
    });
  }

  return intl.formatMessage(
    {
      id: 'resources.work-order.interval.messages.monthly.interval-multiple-short',
    },
    {
      num: HelperFunctions.getOrdinalSuffix(intl, interval),
    }
  );
}

function buildYearlyMessage(recurringMaintenance, intl) {
  const { interval, month_type, start_date } = recurringMaintenance;

  if (start_date == null) {
    return buildNoDateYearlyMessage(recurringMaintenance, intl);
  }

  const weekdayOfMonth = getWeekdayOfMonth(start_date);
  const weekOfMonth = getWeekOfMonth(start_date);
  const dayOfMonth = getDayOfMonth(start_date);
  const monthOfYear = getMonthOfYear(start_date);

  const monthKey = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'][
    monthOfYear
  ];

  if (month_type === 'day_of_week') {
    let num;
    switch (weekOfMonth) {
      case 1:
        num = 'first';
        break;
      case 2:
        num = 'second';
        break;
      case 3:
        num = 'third';
        break;
      case 4:
        num = 'fourth';
        break;
      case -1:
        num = 'last';
        break;
      default:
        break;
    }

    const props = {
      week: intl.formatMessage({
        id: `resources.work-order.interval.messages.monthly.${num}`,
      }),
      day: intl.formatMessage({
        id: `resources.work-order.interval.messages.monthly.${weekdayOfMonth}`,
      }),
      month: intl
        .formatMessage({
          id: `resources.work-order.interval.messages.yearly.${monthKey}`,
        })
        .toLowerCase(),
    };

    if (interval === 1) {
      return intl.formatMessage(
        { id: 'resources.work-order.interval.messages.yearly.exact-day-every' },
        props
      );
    } else if (interval === 2) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.yearly.exact-day-every-other',
        },
        props
      );
    }

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.yearly.exact-day-rest' },
      {
        ...props,
        num: HelperFunctions.getOrdinalSuffix(intl, interval),
      }
    );
  } else if (month_type === 'exact_day') {
    const props = {
      day: dayOfMonth,
      month: intl
        .formatMessage({
          id: `resources.work-order.interval.messages.yearly.${monthKey}`,
        })
        .toLowerCase(),
    };

    if (interval === 1) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.yearly.day-of-week-every',
        },
        props
      );
    } else if (interval === 2) {
      return intl.formatMessage(
        {
          id: 'resources.work-order.interval.messages.yearly.day-of-week-every-other',
        },
        props
      );
    }

    return intl.formatMessage(
      { id: 'resources.work-order.interval.messages.yearly.day-of-week-rest' },
      {
        ...props,
        num: HelperFunctions.getOrdinalSuffix(intl, interval),
      }
    );
  }
  return null;
}

function buildNoDateYearlyMessage(recurringMaintenance, intl) {
  const { interval } = recurringMaintenance;

  if (interval === 1) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.yearly.interval-one-short',
    });
  } else if (interval === 2) {
    return intl.formatMessage({
      id: 'resources.work-order.interval.messages.yearly.interval-one-short',
    });
  }

  return intl.formatMessage(
    {
      id: 'resources.work-order.interval.messages.yearly.interval-multiple-short',
    },
    {
      num: HelperFunctions.getOrdinalSuffix(intl, interval),
    }
  );
}

function buildFromCompletedDateMessage(recurringMaintenance, intl) {
  const { interval, frequency } = recurringMaintenance;

  let messageId;

  switch (frequency) {
    case 'daily':
      messageId = interval > 1 ? 'days' : 'day';
      break;
    case 'weekly':
      messageId = interval > 1 ? 'weeks' : 'week';
      break;
    case 'monthly':
      messageId = interval > 1 ? 'months' : 'month';
      break;
    case 'yearly':
      messageId = interval > 1 ? 'years' : 'year';
      break;

    default:
      break;
  }

  return intl.formatMessage(
    {
      id: `resources.work-order.interval.messages.from-completed.${messageId}`,
    },
    {
      num: interval,
    }
  );
}
