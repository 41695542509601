import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, InlineModal, Tooltip, Icon } from 'views/components/Shared/General';
import { SettingsSelectors } from 'state/ducks/settings';
import styles from './style.module.scss';

class SparePartLocations extends Component {
  renderButtons = sparePartLocation => {
    if (sparePartLocation.id === this.props.disableSelectForSparePartLocationId) {
      return (
        <Tooltip
          label={
            <FormattedMessage id="components.move-spare-part-location-inline-modal.move-spare-part-location.cant-move-to-own-location" />
          }
          trigger={
            <div className={styles['question-icon']}>
              <Icon regular type="question-circle" />
            </div>
          }
        />
      );
    } else {
      return (
        <Button
          type="icon"
          icon={<Icon regular size={18} type="angle-right" />}
          small
          onClick={() => {
            this.props.showChildrenForSparePartLocation(sparePartLocation);
          }}
        />
      );
    }
  };

  render() {
    if (this.props.sparePartLocations.length === 0) {
      return (
        <div className={styles['empty']}>
          <FormattedMessage id="components.move-spare-part-location-inline-modal.move-spare-part-location.empty" />
        </div>
      );
    }
    return (
      <>
        {this.props.sparePartLocations
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map(sparePartLocation => (
            <InlineModal.ListItem
              key={sparePartLocation.id}
              icon="warehouse-alt"
              fontSize={12}
              title={sparePartLocation.title}
              disabled={sparePartLocation.id === this.props.disableSelectForSparePartLocationId}
              selected={sparePartLocation.id === this.props.selectedSparePartLocationId}
              onClick={() => {
                if (sparePartLocation.id !== this.props.disableSelectForSparePartLocationId) {
                  this.props.onSelectSparePartLocation(sparePartLocation);
                }
              }}
              buttons={this.renderButtons(sparePartLocation)}
            />
          ))}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocations: SettingsSelectors.getSparePartLocationsForSparePartLocation(
      state,
      ownProps.showSparePartLocationsForParentId
    ),
  };
}

export default connect(mapStateToProps)(SparePartLocations);
