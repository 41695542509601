import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { getShouldOrderQuantity } from 'sdk/SparePart';
import {
  ShouldOrderSparePartsOperations,
  ShouldOrderSparePartSelectors,
} from 'state/ducks/shouldOrderSpareParts';
import { List, Field, Icon, MoneyWithCurrency, Button, Tooltip } from 'views/components/Shared/General';
import { SparePartUnitWithValue, SparePartListItemExpandable, Title } from 'views/components/SparePart';
import { MultipleSparePartVendorsModal } from 'views/components/SparePartVendor';
import SelectedSparePartVendorItem from './SelectedSparePartVendorItem';
import QuantityInputField from './QuantityInputField';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class SparePartListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isSaving: false,
      showSelectSparePartVendorModal: false,
      sparePartVendorId: null,
    };
  }

  componentDidMount() {
    if (this.props.purchaseOrderRow) {
      const { quantity, spare_part_vendor_id } = this.props.purchaseOrderRow;
      this.setState({
        value: quantity,
        isSaving: false,
        showSelectSparePartVendorModal: false,
        sparePartVendorId: spare_part_vendor_id,
      });
    } else {
      const { sparePartVendors } = this.props;
      if (sparePartVendors && sparePartVendors.length === 1) {
        const sparePartVendor = sparePartVendors[0];
        let quantity = '';
        if (sparePartVendor.package_quantity == null) {
          quantity = getShouldOrderQuantity(this.props.sparePart) || '';
        }
        this.setState({
          value: quantity,
          isSaving: false,
          showSelectSparePartVendorModal: false,
          sparePartVendorId: sparePartVendor.id,
        });
      }
    }
  }

  getPurchaseOrderRow = () => {
    if (this.state.sparePartVendorId) {
      return {
        spare_part_id: this.props.sparePartId,
        spare_part_vendor_id: this.state.sparePartVendorId,
        quantity: this.state.value,
      };
    }
  };

  renderSubtitle = () => {
    const { stock_quantity, maximum_quantity } = this.props.sparePart;
    if (maximum_quantity == null) {
      return (
        <Field fontSize={12} view label={<FormattedMessage id="resources.spare-part.in-stock" />} singleRow>
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={stock_quantity} />
        </Field>
      );
    }
    return (
      <div className={styles['should-order-container']}>
        <Field
          fontSize={12}
          view
          label={
            <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order" />
          }
          singleRow
        >
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={getShouldOrderQuantity(this.props.sparePart)}
          />
        </Field>
      </div>
    );
  };

  renderTitle = () => {
    return (
      <Link className={styles['link']} to={`/spare-parts/${this.props.sparePart.id}`}>
        <Title sparePart={this.props.sparePart} />
      </Link>
    );
  };

  renderPackageDropown = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    const { spare_part_vendors_for_vendor } = this.props.sparePart;
    if (vendor_id && spare_part_vendors_for_vendor && spare_part_vendors_for_vendor.length > 1) {
      if (this.state.sparePartVendorId) {
        return (
          <div
            className={styles['select-dropdown']}
            onClick={() => this.setState({ showSelectSparePartVendorModal: true })}
          >
            <SelectedSparePartVendorItem id={this.state.sparePartVendorId} />
          </div>
        );
      }
      return (
        <List.Item.Column alignRight>
          <div
            className={styles['select-dropdown']}
            onClick={() => this.setState({ showSelectSparePartVendorModal: true })}
          >
            <div>
              <FormattedMessage id="screens.should-order-spare-parts.select-alternative" />
            </div>
            <div className={styles['icon-container']}>
              <Icon type="angle-down" regular />
            </div>
          </div>
        </List.Item.Column>
      );
    }
    return null;
  };

  renderMultipleSparePartVendorsModal = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    return (
      <MultipleSparePartVendorsModal
        select
        open={this.state.showSelectSparePartVendorModal}
        vendorId={vendor_id}
        sparePartId={this.props.sparePartId}
        onSelectSparePartVendor={({ id }) => {
          this.props.addSparePart({
            spare_part_id: this.props.sparePartId,
            spare_part_vendor_id: id,
            quantity: 0,
          });
          this.setState({ sparePartVendorId: id, showSelectSparePartVendorModal: false }, () => {
            this.quantityInputRef.focus();
          });
        }}
        onClose={() => this.setState({ showSelectSparePartVendorModal: false })}
      />
    );
  };

  renderQuantityField = () => {
    const { vendor_id, show_selected } = queryString.parse(this.props.location.search);
    const disabled = show_selected == null && vendor_id && this.state.sparePartVendorId == null;
    return (
      <QuantityInputField
        ref={ref => (this.quantityInputRef = ref)}
        sparePartId={this.props.sparePartId}
        sparePartVendorId={this.state.sparePartVendorId}
        disabled={disabled}
        value={this.state.value}
        onChange={value => {
          this.setState({ value });
        }}
        onBlur={value => {
          if (this.props.purchaseOrderRow) {
            this.props.updateSparePart({
              sparePartId: this.props.sparePartId,
              data: { quantity: value },
            });
          } else {
            this.props.addSparePart({
              spare_part_id: this.props.sparePartId,
              spare_part_vendor_id: this.state.sparePartVendorId,
              quantity: this.state.value,
            });
          }
        }}
      />
    );
  };

  renderCheckbox = () => {
    const { vendor_id, show_selected } = queryString.parse(this.props.location.search);
    const { selectedVendor } = this.props;
    if (show_selected == null && selectedVendor) {
      const disabled = show_selected == null && vendor_id && this.state.sparePartVendorId == null;
      return (
        <List.Item.Column borderLeft width={37}>
          <div className={styles['checkbox-container']}>
            <span>
              <Field.Checkbox
                disabled={disabled}
                checked={this.props.purchaseOrderRow != null}
                onChange={() => {
                  if (this.props.selectedVendor == null) {
                    this.props.onSelectVendor();
                    return;
                  }
                  if (this.props.purchaseOrderRow) {
                    this.props.removeSparePart(this.props.sparePartId);
                  } else {
                    this.props.addSparePart({
                      spare_part_id: this.props.sparePartId,
                      spare_part_vendor_id: this.state.sparePartVendorId,
                      quantity: this.state.value,
                    });
                  }
                }}
              />
            </span>
          </div>
        </List.Item.Column>
      );
    }
    return null;
  };

  renderPurchasePrice = () => {
    const { vendor, sparePartVendors } = this.props;
    if (vendor && sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      return (
        <List.Item.Column>
          {sparePartVendor.purchase_price ? (
            <MoneyWithCurrency
              currency={vendor.purchase_order_currency}
              value={sparePartVendor.purchase_price}
            />
          ) : null}
        </List.Item.Column>
      );
    }
  };

  renderRightContainer = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    const { canEditPurchaseOrders, canEditSpareParts } = this.props;
    if (this.props.selectedVendor) {
      return (
        <>
          {this.renderNotPrimaryWarningTooltip()}
          {this.renderPackageQuantityTooltip()}
          {this.renderPackageDropown()}
          {this.renderPurchasePrice()}
          {this.renderQuantityField()}
        </>
      );
    }
    if (vendor_id == null && canEditPurchaseOrders && canEditSpareParts) {
      return (
        <Button
          gray
          small
          label="screens.should-order-spare-parts.start-purchase-order"
          onClick={this.props.onStartPurchaseOrder}
        />
      );
    }
    if (vendor_id) {
      return this.renderNotPrimaryWarningTooltip();
    }
    return null;
  };

  renderIconButtons = () => {
    const { show_selected } = queryString.parse(this.props.location.search);
    if (show_selected) {
      return (
        <Button
          type="icon"
          icon={<Icon regular red type="trash-alt" />}
          onClick={() => this.props.removeSparePart(this.props.sparePart.id)}
        />
      );
    }
    return null;
  };

  renderNotPrimaryWarningTooltip = () => {
    const { primary } = queryString.parse(this.props.location.search);
    const { sparePart, vendor } = this.props;
    const { primary_vendor_id } = sparePart;
    if (vendor) {
      if (primary_vendor_id && primary === 'false' && primary_vendor_id !== vendor.id) {
        return (
          <Tooltip
            label={<FormattedMessage id="screens.should-order-spare-parts.not-primary" />}
            trigger={<Icon size={14} regular type="info-circle" red />}
          />
        );
      }
    }

    return null;
  };

  renderPackageQuantityTooltip = () => {
    const { vendor, sparePartVendors } = this.props;
    if (vendor && sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        return (
          <List.Item.Column alignRight>
            <Tooltip
              label={
                <>
                  <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                  <span>: </span>
                  <SparePartUnitWithValue
                    sparePartUnit={this.props.sparePartUnit}
                    value={sparePartVendor.package_quantity}
                  />
                </>
              }
              trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  render() {
    return (
      <>
        <List.Item
          expandable
          expandedComponent={<SparePartListItemExpandable id={this.props.sparePart.id} />}
          iconButtons={this.renderIconButtons()}
        >
          {this.renderCheckbox()}
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />
          {this.props.children}
          {this.renderRightContainer()}
        </List.Item>
        {this.renderMultipleSparePartVendorsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: ShouldOrderSparePartsOperations.updateSparePart,
      addSparePart: ShouldOrderSparePartsOperations.addSparePart,
      removeSparePart: ShouldOrderSparePartsOperations.removeSparePart,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  const { vendor_id } = queryString.parse(ownProps.location.search);
  return {
    sparePart,
    vendor: EntitySelectors.getVendor(state, vendor_id),
    images: EntitySelectors.getImages(state, sparePart.images),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    purchaseOrderRow: ShouldOrderSparePartSelectors.getPurchaseOrderRowForSparePartId(
      state,
      ownProps.sparePartId
    ),
    sparePartVendors: EntitySelectors.getSparePartVendors(state, sparePart.spare_part_vendors_for_vendor),
    selectedVendor: ShouldOrderSparePartSelectors.getSelectedVendor(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

const withRouterAndRef = Wrapped => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ));
  const WithRouterAndRef = React.forwardRef((props, ref) => <WithRouter {...props} forwardRef={ref} />);
  return WithRouterAndRef;
};

export default withRouterAndRef(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SparePartListItem)
);
