import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { DowntimeTimer, DowntimeModal } from 'views/components/Downtime';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class ActiveDowntime extends Component {
  state = {
    showDowntimeModal: false,
    showDowntimeModalForDowntimeId: null,
  };

  renderDowntime = () => {
    if (this.props.downtime == null || (this.props.downtime.to != null && this.props.downtime.from != null))
      return null;
    return (
      <div
        className={styles['alert']}
        onClick={() =>
          this.setState({ showDowntimeModal: true, showDowntimeModalForDowntimeId: this.props.downtime.id })
        }
      >
        <div className={styles['alert-content']}>
          <div>
            <FormattedMessage id="screens.asset.active-downtime" />
            <span className={styles['asset']}>{this.props.asset ? this.props.asset.title : null}</span>
          </div>
          <div>
            <DowntimeTimer downtime={this.props.downtime} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderDowntime()}
        <DowntimeModal
          open={this.state.showDowntimeModal}
          id={this.state.showDowntimeModalForDowntimeId}
          assetId={this.props.asset ? this.props.asset.id : null}
          onClose={() => this.setState({ showDowntimeModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  const asset = EntitySelectors.getAsset(state, assetId);
  const downtime = EntitySelectors.getDowntime(state, asset.active_downtime);
  return {
    downtime,
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(ActiveDowntime));
