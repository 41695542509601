import actions from './actions';

const beginUpload = actions.beginUpload;
const cancelUpload = actions.cancelUpload;
const updateUpload = actions.updateUpload;
const completeUpload = actions.completeUpload;

export default {
  beginUpload,
  cancelUpload,
  updateUpload,
  completeUpload,
};
