import React from 'react';
import { connect } from 'react-redux';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';

const UploadingAttachmentContent = ({ uploadProgress }) => {
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const isCalculatingSize = uploadProgress.loaded == 0 && uploadProgress.total == 0;

  const getProgressValueWidthValue = () => {
    if (isCalculatingSize) return 5;
    return Math.round((uploadProgress.loaded * 100) / uploadProgress.total);
  };

  const getProgressValueText = () => {
    if (isCalculatingSize) return <FormattedMessage id="general.uploading" />;
    return (
      <React.Fragment>
        <span>{formatBytes(uploadProgress.loaded)}</span> / <span>{formatBytes(uploadProgress.total)}</span>
      </React.Fragment>
    );
  };

  return (
    <div className={styles['item']}>
      <p className={styles['progress-text']}>{getProgressValueText()}</p>
      <div className={styles['progress-bar-container']}>
        <div className={styles['progress-bar']} style={{ width: `${getProgressValueWidthValue()}%` }} />
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    uploadProgress: UploadProgressSelectors.getUploadProgressForAttachment(
      state,
      ownProps.namespace,
      ownProps.uploadingAttachment.id
    ),
  };
}

export default connect(mapStateToProps)(UploadingAttachmentContent);
