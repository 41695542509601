import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { UserType } from 'sdk/SystemAccess';
import { normalizeUser } from 'sdk/Schemas';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, NewInlineModal } from 'views/components/Shared/General';
import { UsersOperations } from 'state/ducks/users';
import styles from './style.module.scss';

export default class CantEditOrgAdminPermissionModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.users.cant-edit-org-admin-permission-modal.title" />}
            subtitle={<FormattedMessage id="screens.users.cant-edit-org-admin-permission-modal.subtitle" />}
            onClose={() => this.props.onClose()}
          />
          <Modal.Footer>
            <Button.Group>
              <Link to="/organisation">
                <Button primary label="screens.users.cant-edit-org-admin-permission-modal.button" />
              </Link>
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
