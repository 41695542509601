import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Content from './Content';
import styles from '../style.module.scss';

const RightContainer = ({ children, ignoreScroll }) => {
  if (ignoreScroll) {
    return <div className={styles['right-container']}>{children}</div>;
  } else {
    return (
      <div className={styles['right-container']}>
        <PerfectScrollbar>
          <div className={styles['right-content']}>{children}</div>
        </PerfectScrollbar>
      </div>
    );
  }
};

RightContainer.Content = Content;
export default RightContainer;
