import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { Tooltip, Icon, FormattedNumber } from 'views/components/Shared/General';
import styles from './style.module.scss';

class IconsColumn extends Component {
  renderRecurringIcon = () => {
    const { recurringMaintenance } = this.props;

    if (!recurringMaintenance) return null;
    if (recurringMaintenance.status === 'archived') return null;
    if (recurringMaintenance.meter_id) {
      return (
        <Tooltip
          trigger={<Icon regular type="repeat" />}
          label={
            <FormattedMessage
              id="resources.work-order.meter-interval"
              values={{
                value: (
                  <FormattedNumber
                    value={this.props.recurringMaintenance.meter_interval}
                    unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          }
        />
      );
    }
    return (
      <Tooltip
        trigger={<Icon regular type="repeat" />}
        label={<div>{buildIntervalMessage(this.props.recurringMaintenance, this.props.intl)}</div>}
      />
    );
  };

  renderPriorityIcon = () => {
    const { workOrder } = this.props;

    if (!workOrder.priority) return null;

    let label;
    let colorStyle;

    switch (workOrder.priority) {
      case 'low':
        label = 'resources.work-order.prio.low';
        colorStyle = styles['low'];
        break;
      case 'medium':
        label = 'resources.work-order.prio.medium';
        colorStyle = styles['medium'];
        break;
      case 'high':
        label = 'resources.work-order.prio.high';
        colorStyle = styles['high'];
        break;
      default:
        break;
    }

    return (
      <Tooltip
        trigger={
          <span className={colorStyle}>
            <Icon
              regular
              color={workOrder.priority === 'low' ? '#838383' : null}
              orange={workOrder.priority === 'medium'}
              red={workOrder.priority === 'high'}
              type="exclamation-circle"
            />
          </span>
        }
        label={this.props.intl.formatMessage({ id: label })}
      />
    );
  };

  render() {
    return (
      <div className={styles['icons']}>
        {this.renderRecurringIcon()}
        {this.renderPriorityIcon()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { recurring_maintenance_id } = ownProps.workOrder;
  let meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }

  return {
    meter,
    meterUnit,
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, recurring_maintenance_id),
  };
}

export default injectIntl(connect(mapStateToProps)(IconsColumn));
