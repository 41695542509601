import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { IntervalModal } from 'views/components/OperationalMaintenance';
import { Button, SectionHeader } from 'views/components/Shared/General';
import { NewSelectAssetModal } from 'views/components/Asset';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import TemplateAssetListItem from './TemplateAssetListItem';
import styles from './style.module.scss';

class Assets extends Component {
  state = {
    showSelectAssetModal: false,
    showIntervalModal: false,
    isCreatingRecurrenceVersions: false,
    selectedAssetIds: [],
  };

  createTemplateAssetsWithRecurringVersions = intervalData => {
    this.setState({ isCreatingRecurrenceVersions: true });
    let successes = 0;
    let failures = 0;
    return Promise.all(
      this.state.selectedAssetIds.map(id =>
        this.props
          .createTemplateAssetForTemplate(this.props.operationalMaintenanceTemplate.id, {
            asset_id: id,
            operational_maintenance_template_recurrences: [
              {
                operational_maintenance_template_recurrence_versions: [intervalData],
              },
            ],
          })
          .then(() => {
            successes = ++successes;
          })
          .catch(e => {
            failures = ++failures;
          })
      )
    ).then(() => {
      this.setState({ isCreatingRecurrenceVersions: false, showIntervalModal: false });
      if (successes > 0) {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.save-success" />
            }
          />
        );
      } else {
        toast(
          <ToastMessage
            error
            text={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.save-error" />
            }
          />
        );
      }
    });
  };

  renderAssetDescription = () => {
    return (
      <div className={styles['asset-description']}>
        <FormattedMessage id="screens.operational-maintenance-template.settings.asset.description" />
      </div>
    );
  };

  renderAddAssetButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <div className={styles['add-asset-button-container']}>
          <Button
            gray
            label="screens.operational-maintenance-template.settings.asset.add-asset"
            onClick={() => this.setState({ showSelectAssetModal: true })}
          />
        </div>
      );
    }
    return null;
  };

  renderAssets = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={28}>
          <span>
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.section-title" />
            <span> - </span>
            <span className={styles['amount-of-assets']}>
              <FormattedMessage
                id="general.amount"
                values={{ amount: this.props.operationalMaintenanceTemplateAssets.length }}
              />
            </span>
          </span>
        </SectionHeader>
        {this.props.operationalMaintenanceTemplateAssets.map(({ id }) => (
          <TemplateAssetListItem
            id={id}
            key={id}
            onShowSettingsForTemplateAssetId={id => this.props.onShowSettingsForTemplateAssetId(id)}
          />
        ))}
      </>
    );
  };

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-asset']}>
        <FormattedMessage id="general.already-added" />
      </div>
    );
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        multiple
        alreadySelectedForMultiple={asset => {
          if (asset.exists_for_operational_maintenance_template) {
            return true;
          }
          return false;
        }}
        additionalApiParams={{
          exists_for_operational_maintenance_template: this.props.match.params.id,
        }}
        listItemRightComponent={asset => {
          if (asset.exists_for_operational_maintenance_template) {
            return this.renderAlreadyAdded();
          }
          return null;
        }}
        open={this.state.showSelectAssetModal}
        onSelectAssets={assetIds => {
          this.setState({ selectedAssetIds: assetIds, showSelectAssetModal: false });
          setTimeout(() => {
            this.setState({ showIntervalModal: true });
          }, 250);
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  renderIntervalModal = () => {
    return (
      <IntervalModal
        open={this.state.showIntervalModal}
        isSaving={this.state.isCreatingRecurrenceVersions}
        onSave={this.createTemplateAssetsWithRecurringVersions}
        onClose={() => this.setState({ showIntervalModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderAssetDescription()}
        {this.renderAddAssetButton()}
        {this.renderAssets()}
        {this.renderSelectAssetModal()}
        {this.renderIntervalModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createTemplateAssetForTemplate: SDKReduxOperations.createTemplateAssetForTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const operationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    operationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  return {
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplate,
    operationalMaintenanceTemplateAssets: EntitySelectors.getOperationalMaintenanceTemplateAssets(
      state,
      operationalMaintenanceTemplate.operational_maintenance_template_assets
    ),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));
