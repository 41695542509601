import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { PurchaseOrdersSelectors } from 'state/ducks/purchaseOrders';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    const keys = this.props.selectedFilterType.split('.');
    switch (keys[0]) {
      case FILTER_TYPES.Status: {
        return <Filter.Status />;
      }
      case FILTER_TYPES.Vendor: {
        return <Filter.Vendor />;
      }
      case FILTER_TYPES.Late: {
        return <Filter.Late />;
      }
      case FILTER_TYPES.SparePartLocation: {
        return <Filter.SparePartLocation />;
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFilterType: PurchaseOrdersSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
