import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { getIconClassNameString, getIconColor } from 'sdk/AttachmentVersion';
import { UploadingAttachmentContent } from '../';
import { Icon } from 'views/components/Shared/General';
import Container from './Container';
import styles from './style.module.scss';

class AttachmentItem extends Component {
  renderTitle = () => <div className={styles['title']}>{this.props.attachmentVersion.title}</div>;

  renderSubtitle = () => {
    return this.props.subtitle == null ? null : (
      <div className={styles['subtitle']}>{this.props.subtitle}</div>
    );
  };

  renderButtons = () => {
    if (this.props.buttons) {
      return <div className={styles['button-container']}>{this.props.buttons}</div>;
    }
  };

  render() {
    if (this.props.uploading) {
      return (
        <div className={styles['attachment-item']}>
          <UploadingAttachmentContent
            uploadingAttachment={this.props.attachment}
            namespace={this.props.namespace}
          />
        </div>
      );
    } else {
      return (
        <div className={styles['attachment-item']}>
          <div className={styles['type']}>
            <Icon
              type={getIconClassNameString(this.props.attachmentVersion)}
              color={getIconColor(this.props.attachmentVersion)}
            />
          </div>
          <div className={styles['title-container']}>
            {this.renderTitle()}
            {this.renderSubtitle()}
          </div>
          {this.renderButtons()}
        </div>
      );
    }
  }
}

AttachmentItem.Container = Container;

function mapStateToProps(state, ownProps) {
  if (ownProps.uploading) return {};

  const attachmentVersion = EntitySelectors.getAttachmentVersion(
    state,
    ownProps.attachment.attachment_version
  );
  return {
    attachmentVersion,
  };
}

export default connect(mapStateToProps, null)(AttachmentItem);
