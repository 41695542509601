export const CostType = {
  Labor: 'labor',
  Part: 'part',
  DownTime: 'downtime',
  Other: 'other',
};

export const REPORT_TYPES = {
  Total: 'total',
};
export const REPORT_PER_TYPES = {
  Year: 'year',
  Month: 'month',
  Asset: 'asset',
  User: 'user',
  Vendor: 'vendor',
  Priority: 'work_order_priority',
  WorkOrderType: 'work_order_type',
  WorkOrderSpentTimeCategory: 'work_order_spent_time_category',
  WorkOrderCategorySimple: 'work_order_category_simple',
  WorkOrderCategoryDetailed: 'work_order_category_detailed',
  External: 'external',
};
export const REPORT_SPLIT_PER_TYPES = {
  External: 'external',
  WorkOrderCategorySimple: 'work_order_category_simple',
  WorkOrderCategoryDetailed: 'work_order_category_detailed',
};
