import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit, isEqual } from 'lodash-es';
import { mutateTree } from '@atlaskit/tree/';
import { buildAtlaskitTree, updateTreeAssetIds } from 'sdk/Asset';
import { Loader } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { Tree } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import styles from './style.module.scss';

class SelectAssetsForQRCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tree: [],
      data: [],
      selectedAssetIds: {},
      expandedIds: {},
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        loading: true,
        tree: [],
        data: [],
        selectedAssetIds: {},
        expandedIds: {},
      });
      API.fetchTree(this.props.selectedSystem.id, {
        tree_children_for_asset_id: this.props.showChildrenForAssetId,
        archived: false,
      }).then(res => {
        this.setState({
          loading: false,
          data: res.data,
          tree: buildAtlaskitTree(res.data, { rootId: this.props.showChildrenForAssetId }),
        });
      });
    }
  }

  expandTree = assetId => {
    let expandedIds = { ...this.state.expandedIds };
    expandedIds[assetId] = true;
    this.setState(
      {
        expandedIds,
      },
      () => {
        this.setState({
          tree: mutateTree(this.state.tree, assetId, {
            rootId: this.props.showChildrenForAssetId,
            isExpanded: true,
          }),
        });
      }
    );
  };

  collapseTree = assetId => {
    let expandedIds = omit(this.state.expandedIds, assetId);
    this.setState(
      {
        expandedIds,
      },
      () => {
        this.setState({
          tree: mutateTree(this.state.tree, assetId, {
            rootId: this.props.showChildrenForAssetId,
            isExpanded: false,
          }),
        });
      }
    );
  };

  renderTreeNode = item => {
    const { asset } = item.data;
    const selected = this.state.selectedAssetIds[asset.id] === true;
    return (
      <div
        className={styles['item-node']}
        onClick={() => {
          if (this.state.selectedAssetIds[asset.id]) {
            let newSelectedAssetIds = omit(this.state.selectedAssetIds, asset.id);
            this.setState({
              selectedAssetIds: newSelectedAssetIds,
              tree: updateTreeAssetIds(this.state.tree, [asset.id]),
            });
          } else {
            let createdId = {};
            createdId[asset.id] = true;
            this.setState({
              selectedAssetIds: { ...this.state.selectedAssetIds, ...createdId },
              tree: updateTreeAssetIds(this.state.tree, [asset.id]),
            });
          }
        }}
      >
        <div className={styles['title-container']}>
          <p className={styles['title']}>{asset.title}</p>
          {this.props.settings.asset_number_activated ? (
            <p className={styles['subtitle']}>#{asset.number}</p>
          ) : null}
        </div>
        {selected ? <Icon regular green type="check" /> : null}
      </div>
    );
  };

  renderTree = () => (
    <div className={`${styles['tree-container']}`}>
      <Tree
        tree={this.state.tree}
        onExpand={this.expandTree}
        onCollapse={this.collapseTree}
        renderNode={item => this.renderTreeNode(item)}
        isDragEnabled={false}
        isNestingEnabled
      />
    </div>
  );

  render() {
    return (
      <Modal isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="components.select-assets-qr-codes.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          {this.state.loading ? (
            <div className={styles['loader']}>
              <Loader small />
            </div>
          ) : (
            <React.Fragment>{this.renderTree()}</React.Fragment>
          )}
        </Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button
              primary
              disabled={Object.keys(this.state.selectedAssetIds).length === 0}
              label="components.select-assets-qr-codes.print-button"
              loading={this.state.isSaving}
              onClick={() => {
                this.props.onPrintQrCodes(Object.keys(this.state.selectedAssetIds));
              }}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}
export default connect(mapStateToProps)(SelectAssetsForQRCodeModal);
