import React, { Component } from 'react';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../../Header';
import StatusBar from './StatusBar';
import GeneralInformation from './GeneralInformation';
import Vendor from './Vendor';
import OurInfo from './OurInfo';
import PurchaseOrderRows from './PurchaseOrderRows';
import ChangeCurrencyWarning from './ChangeCurrencyWarning';

export default class Information extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer minWidth={900}>
          <CardGrid>
            <CardGrid.Column>
              <ChangeCurrencyWarning />
              <CardGrid.Row>
                <StatusBar />
              </CardGrid.Row>
              <CardGrid.Row>
                <GeneralInformation />
              </CardGrid.Row>
              <CardGrid.Row>
                <CardGrid>
                  <CardGrid.Column width={471}>
                    <CardGrid.Row fullHeight>
                      <Vendor />
                    </CardGrid.Row>
                  </CardGrid.Column>
                  <CardGrid.Column width={471}>
                    <CardGrid.Row fullHeight>
                      <OurInfo />
                    </CardGrid.Row>
                  </CardGrid.Column>
                </CardGrid>
              </CardGrid.Row>
              <CardGrid.Row>
                <PurchaseOrderRows />
              </CardGrid.Row>
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}
