import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { injectIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import { bindActionCreators } from 'redux';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistTemplateOperations } from 'state/ducks/checklistTemplate';
import moment from 'moment';
import styles from './style.module.scss';

class OldVersionListItem extends Component {
  renderFilledTitle = () =>
    this.props.checklistVersion.instances_count === 0 ? (
      <FormattedMessage id="screens.checklists.filled-checklist-empty" />
    ) : (
      <FormattedPlural
        value={this.props.checklistVersion.instances_count}
        one={
          <FormattedMessage
            id="screens.checklists.filled-amount-of-times.one"
            values={{
              amount: <span className={styles['filled']}>{this.props.checklistVersion.instances_count}</span>,
            }}
          />
        }
        other={
          <FormattedMessage
            id="screens.checklists.filled-amount-of-times.other"
            values={{
              amount: <span className={styles['filled']}>{this.props.checklistVersion.instances_count}</span>,
            }}
          />
        }
      />
    );
  renderItem = () => (
    <>
      <List.Item
        flex
        clickable
        onClick={() => {
          this.props.fetchChecklistTemplateVersion(this.props.id);
          this.props.onClose();
          if (matchPath(this.props.location.pathname, { path: '/checklists/:id', exact: true }) == null) {
            this.props.history.push(`/checklists/${this.props.match.params.id}`);
          }
        }}
      >
        <div className={styles['text-container']}>
          <div className={styles['title']}>
            {moment(this.props.checklistVersion.created_at).format('LLL')}
          </div>
          <div className={styles['subtitle']}>
            {this.props.checklistVersion.status === 'active' ? (
              <span className={styles['green']}>
                <FormattedMessage id="screens.checklist.dropdown.old-version-modal.active" />
              </span>
            ) : (
              this.renderFilledTitle()
            )}
          </div>
        </div>
      </List.Item>
    </>
  );

  render() {
    return this.renderItem();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChecklistTemplateVersion: ChecklistTemplateOperations.fetchChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistVersion = EntitySelectors.getChecklistTemplateVersion(state, ownProps.id);
  return {
    checklistVersion,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OldVersionListItem)));
