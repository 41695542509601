import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, NewSearchField } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditDowntimeReasonModal from './EditDowntimeReasonModal';
import DeleteDowntimeReasonModal from './DeleteDowntimeReasonModal';
import styles from './style.module.scss';

class DowntimeReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewInitialized: false,
      showDowntimeReasonModal: false,
      showDowntimeReasonModalForId: null,
      showDeleteDowntimeReasonModal: false,
      showDeleteDowntimeReasonForDowntimeReasonId: null,
      searchTerm: '',
    };
  }

  componentDidMount() {
    this.props.fetchDowntimeReasons(this.props.selectedSystem.id, { downtime_count: true }).then(() => {
      this.setState({ viewInitialized: true });
    });
  }

  listIsEmpty = () => this.props.downtimeReasons.length === 0 && this.state.searchTerm.length === 0;

  renderSearchedEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      modal
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.assets.downtime-reasons.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() => this.setState({ showDowntimeReasonModal: true, showDowntimeReasonModalForId: null })}
          label="screens.settings.assets.downtime-reasons.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header background small>
        <List.Header.Column flex>
          <FormattedMessage id="resources.asset-type.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <React.Fragment>
      <Toolbar
        buttons={
          <Button
            primary
            onClick={() =>
              this.setState({ showDowntimeReasonModal: true, showDowntimeReasonModalForId: null })
            }
            label="screens.settings.assets.downtime-reasons.add-button"
          />
        }
        search={
          <NewSearchField
            value={this.state.searchTerm}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            onSearch={searchTerm => this.setState({ searchTerm })}
          />
        }
      />
      {this.renderHeader()}
      <List>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderRightColumn = downtimeReason => {
    const { downtime_count: count } = downtimeReason;
    if (count === 0) {
      return (
        <span className={`${styles['asset-count']} ${styles['never-used']}`}>
          <FormattedMessage id="screens.settings.assets.downtime-reasons.never-used" />
        </span>
      );
    }
    return (
      <List.Item.Column alignRight>
        <span className={styles['asset-count']}>
          <FormattedPlural
            value={count}
            one={<FormattedMessage id="screens.settings.assets.downtime-reasons.used-amount.one" />}
            two={
              <FormattedMessage
                id="screens.settings.assets.downtime-reasons.used-amount.two"
                values={{ count }}
              />
            }
            few={
              <FormattedMessage
                id="screens.settings.assets.downtime-reasons.used-amount.few"
                values={{ count }}
              />
            }
            many={
              <FormattedMessage
                id="screens.settings.assets.downtime-reasons.used-amount.many"
                values={{ count }}
              />
            }
            other={
              <FormattedMessage
                id="screens.settings.assets.downtime-reasons.used-amount.other"
                values={{ count }}
              />
            }
          />
        </span>
      </List.Item.Column>
    );
  };

  renderDowntimeReasons = () => {
    const filteredDowntimeReasons = this.props.downtimeReasons.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const foundNoSearchResult = filteredDowntimeReasons.length === 0;
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({ showDowntimeReasonModal: true, showDowntimeReasonModalForId: null })
              }
              label="screens.settings.assets.downtime-reasons.add-button"
            />
          }
          search={
            <NewSearchField
              value={this.state.searchTerm}
              placeholder={this.props.intl.formatMessage({
                id: 'general.search-placeholder',
              })}
              onSearch={searchTerm => {
                this.setState({ searchTerm });
              }}
              onClear={() => this.setState({ searchTerm: '' })}
            />
          }
        />
        {foundNoSearchResult ? (
          this.renderSearchedEmptyDataset()
        ) : (
          <>
            {this.renderHeader()}
            <List small>
              {filteredDowntimeReasons.map(downtimeReason => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showDowntimeReasonModal: true,
                      showDowntimeReasonModalForId: downtimeReason.id,
                    })
                  }
                >
                  <List.Item.TitleColumn title={downtimeReason.title} />
                  {this.renderRightColumn(downtimeReason)}
                </List.Item>
              ))}
            </List>
          </>
        )}
      </>
    );
  };

  renderContent() {
    if (!this.state.viewInitialized) {
      return this.renderLoader();
    } else if (this.listIsEmpty()) {
      return this.renderEmptyDataset();
    } else {
      return this.renderDowntimeReasons();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/assets" />
        <Section
          title={<FormattedMessage id="screens.settings.assets.downtime-reasons.title" />}
          subtitle={<FormattedMessage id="screens.settings.assets.downtime-reasons.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditDowntimeReasonModal
          open={this.state.showDowntimeReasonModal}
          id={this.state.showDowntimeReasonModalForId}
          onDelete={downtimeReasonId => {
            this.setState({
              showDowntimeReasonModal: false,
            });
            setTimeout(() => {
              this.setState({
                showDeleteDowntimeReasonForDowntimeReasonId: downtimeReasonId,
                showDeleteDowntimeReasonModal: true,
              });
            }, 250);
          }}
          onClose={() => this.setState({ showDowntimeReasonModal: false })}
        />
        <DeleteDowntimeReasonModal
          open={this.state.showDeleteDowntimeReasonModal}
          id={this.state.showDeleteDowntimeReasonForDowntimeReasonId}
          onClose={() =>
            this.setState({
              showDeleteDowntimeReasonModal: false,
            })
          }
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDowntimeReasons: SettingsOperations.fetchDowntimeReasons,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    downtimeReasons: SettingsSelectors.getDowntimeReasons(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DowntimeReasons));
