import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Icon } from 'views/components/Shared/General';
import { FailedInspectionCount } from 'views/components/Checklist';
import { ChecklistProgress } from 'views/components/WorkOrder';

class ChecklistInstanceListItem extends Component {
  renderFailedInspectionCount = () => {
    if (this.props.checklistInstance && this.props.checklistInstance.inspection_failed_count > 0) {
      return (
        <List.Item.Column alignRight>
          <FailedInspectionCount checklistInstanceId={this.props.checklistInstance.id} />
        </List.Item.Column>
      );
    }
    return null;
  };

  renderProgressBar = () => {
    return (
      <List.Item.Column alignRight>
        <ChecklistProgress workOrderId={this.props.checklistInstance.work_order_id} />
      </List.Item.Column>
    );
  };

  render() {
    return (
      <List.Item clickable onClick={this.props.onClick}>
        <List.Item.Column>
          <Icon type="list" withBackground backgroundSize={34} />
        </List.Item.Column>
        <List.Item.TitleColumn title={this.props.checklistTemplateVersion.title} />
        {this.renderProgressBar()}
        {this.renderFailedInspectionCount()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
    state,
    ownProps.checklistInstance.checklist_template_version_id
  );
  return {
    checklistTemplateVersion,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    ),
  };
}

export default connect(mapStateToProps)(ChecklistInstanceListItem);
