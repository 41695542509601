import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { ShouldOrderSparePartSelectors } from 'state/ducks/shouldOrderSpareParts';
import { Modal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { Button } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class CreatePurchaseOrderForVendorModal extends Component {
  state = {
    isSaving: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isSaving: false });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  createPurchaseOrder = () => {
    const purchaseOrderRows = this.props.purchaseOrderRows.map(por => {
      const { spare_part_vendor_id } = por;
      if (spare_part_vendor_id) {
        return omit(por, 'spare_part_id');
      }
      return omit(por, 'spare_part_vendor_id');
    });
    this.setState({ isSaving: true });
    this.props
      .createPurchaseOrder(this.props.system.id, {
        vendor_id: this.props.selectedVendor.id,
        purchase_order_rows: purchaseOrderRows,
      })
      .then(({ data: purchaseOrder }) => {
        this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
      });
  };

  renderMessage = () => {
    if (this.props.selectedVendor) {
      return (
        <FormattedMessage
          id="screens.should-order-spare-parts.start-purchase-order-for-vendor-modal.text"
          values={{
            vendor: <span className={styles['vendor']}>{this.props.selectedVendor.name}</span>,
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={455}>
        <Modal.Header
          ignoreLine
          title={
            <FormattedMessage id="screens.should-order-spare-parts.create-purchase-order-for-vendor-modal.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderMessage()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.createPurchaseOrder}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPurchaseOrder: SDKReduxOperations.createPurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    selectedVendor: ShouldOrderSparePartSelectors.getSelectedVendor(state),
    purchaseOrderRows: ShouldOrderSparePartSelectors.getPurchaseOrderRows(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrderForVendorModal));
