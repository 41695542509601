import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API } from 'sdk';
import { normalizeSparePartType } from 'sdk/Schemas';
import { NewInlineModal, Field, EmptyDataSet } from 'views/components/Shared/General';
import { SparePartTypePath } from 'views/components/SparePart';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class SparePartTypeInlineModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    isOpen: false,
    searchTerm: '',
    sparePartTypes: [],
    noSparePartTypesHasChildren: false,
    expandedSparePartTypes: {} /* { id: true } */,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState(
        {
          ...this.getInitialState(),
          isOpen: true,
        },
        () => {
          this.fetchSparePartTypes().then(sparePartTypes => {
            this.setState({
              noSparePartTypesHasChildren: sparePartTypes.find(({ parent_id }) => parent_id != null) == null,
              sparePartTypes,
              isFetching: false,
            });
          });
        }
      );
    }
  };

  fetchSparePartTypes = () => {
    let params = {
      no_pagination: true,
    };

    return API.listSparePartTypes(this.props.currentSystem.id, params)
      .then(({ data: sparePartTypes }) => {
        const { entities, result } = normalizeSparePartType(sparePartTypes);
        this.props.updateEntities(entities);
        return result.map(id => entities.sparePartTypeById[id]);
      })
      .catch(() => {});
  };

  selectSparePartType = sparePartTypeId => {
    if (this.props.multiple) {
      if (this.props.selectedSparePartTypeIds.includes(sparePartTypeId)) {
        this.props.onRemoveSparePartType(sparePartTypeId);
      } else {
        this.props.onAddSparePartType(sparePartTypeId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectSparePartType(sparePartTypeId);
        }
      );
    }
  };

  expandSparePartType = id => {
    if (this.state.expandedSparePartTypes[id]) {
      this.setState({
        expandedSparePartTypes: {
          ...this.state.expandedSparePartTypes,
          [id]: null,
        },
      });
    } else {
      this.setState({
        expandedSparePartTypes: {
          ...this.state.expandedSparePartTypes,
          [id]: true,
        },
      });
    }
  };

  renderSearchEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="components.spare-part-type-inline-modal.empty-data-set.title" />}
      subtitle={<FormattedMessage id="components.spare-part-type-inline-modal.empty-data-set.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderSearchField = () => (
    <NewInlineModal.Header.Search
      ref={ref => (this.inlineModalSearchRef = ref)}
      placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
      value={this.state.searchTerm}
      onChange={searchTerm => this.setState({ searchTerm })}
      onClear={() => this.setState({ searchTerm: '' })}
    />
  );

  renderClearItem = () => {
    const { clearable, multiple, selectedSparePartTypeId } = this.props;
    if (clearable && multiple === false && selectedSparePartTypeId) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => {
              this.setState({ isOpen: false });
              this.props.onClear();
            }}
          >
            <FormattedMessage id="general.clean" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  sparePartTypeHasChildren = spt => {
    return this.state.sparePartTypes.find(({ parent_id }) => parent_id === spt.id) != null;
  };

  renderListItemChildren = (spt, depth) => {
    return this.state.sparePartTypes
      .filter(({ parent_id }) => parent_id === spt.id)
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map(sparePartType => {
        return this.renderTreeListItem(sparePartType, depth);
      });
  };

  renderTreeListItem = (sparePartType, depth = 1) => {
    let selected = false;
    if (this.props.multiple) {
      selected = this.props.selectedSparePartTypeIds.includes(sparePartType.id);
    } else {
      selected = this.props.selectedSparePartTypeId === sparePartType.id;
    }
    return (
      <NewInlineModal.Dropdown.Item
        expandable={this.state.noSparePartTypesHasChildren === false}
        expandableDepth={depth}
        disabled={this.props.inactivateSparePartTypeId === sparePartType.id}
        hasExpandableChildren={this.sparePartTypeHasChildren(sparePartType)}
        isExpanded={this.state.expandedSparePartTypes[sparePartType.id] === true}
        onExpand={() => this.expandSparePartType(sparePartType.id)}
        expandableComponent={this.renderListItemChildren(sparePartType, depth + 1)}
        selected={selected}
        key={sparePartType.id}
        onClick={e => this.selectSparePartType(sparePartType.id)}
        leftComponent={
          this.props.multiple ? (
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectSparePartType(sparePartType.id);
              }}
            />
          ) : null
        }
      >
        {sparePartType.title}
      </NewInlineModal.Dropdown.Item>
    );
  };

  renderSearchedListItem = sparePartType => {
    let selected = false;
    if (this.props.multiple) {
      selected = this.props.selectedSparePartTypeIds.includes(sparePartType.id);
    } else {
      selected = this.props.selectedSparePartTypeId === sparePartType.id;
    }
    return (
      <NewInlineModal.Dropdown.Item
        key={sparePartType.id}
        onClick={e => this.selectSparePartType(sparePartType.id)}
        selected={selected}
        leftComponent={
          this.props.multiple ? (
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectSparePartType(sparePartType.id);
              }}
            />
          ) : null
        }
      >
        <div className={styles['item-with-path']}>
          <div>{sparePartType.title}</div>
          <div className={styles['subtitle']}>
            <SparePartTypePath sparePartTypeId={sparePartType.id} />
          </div>
        </div>
      </NewInlineModal.Dropdown.Item>
    );
  };

  renderList = () => {
    if (this.state.sparePartTypes.length === 0) {
      return this.renderEmptyDataset();
    }

    if (this.state.searchTerm.length > 0) {
      const filteredSparePartTypes = this.state.sparePartTypes.filter(
        spt =>
          this.state.searchTerm === '' ||
          spt.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
      );
      if (filteredSparePartTypes.length === 0) {
        return this.renderSearchEmptyDataset();
      }
      return filteredSparePartTypes
        .sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map(sparePartType => this.renderSearchedListItem(sparePartType));
    }

    const rootSparePartTypes = this.state.sparePartTypes
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .filter(spt => spt.parent_id == null);
    return rootSparePartTypes.map(sparePartType => {
      return this.renderTreeListItem(sparePartType);
    });
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          {this.renderSearchField()}
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    return (
      <>
        <NewInlineModal.Dropdown>
          {this.renderSearchField()}
          <NewInlineModal.Dropdown.Items>
            {this.renderClearItem()}
            {this.renderList()}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          minWidth={this.props.minWidth}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartTypeInlineModal));

SparePartTypeInlineModal.propTypes = {
  selectedSparePartTypeId: PropTypes.string,
  selectedSparePartTypeIds: PropTypes.array,
  onSelectSparePartType: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  minWidth: PropTypes.number,
  clearable: PropTypes.bool,
};

SparePartTypeInlineModal.defaultProps = {
  selectedSparePartTypeId: null,
  selectedSparePartTypeIds: [],
  onSelectSparePartType: () => {},
  onAddSparePartType: () => {},
  onRemoveSparePartTyp: () => {},
  onClear: () => {},
  multiple: false,
  minWidth: null,
  clearable: false,
};
