import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { FormattedMessage } from 'react-intl';
import { Button, Field } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import ArchiveItem from './ArchiveItem';
import { AssetTitle } from '..';
import styles from './style.module.scss';

class ArchiveAssetModal extends Component {
  getInitialState = () => ({
    isFetchingWorkOrdersCount: true,
    isFetchingOperationalMaintenancesCount: true,
    totalPlannedWorkOrders: 0,
    totalOperationalMaintenanceTemplates: 0,
    confirmArchive: false,
    isArchiving: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
      this.setState({ isFetchingWorkOrdersCount: true, isFetchingOperationalMaintenancesCount: true });
      this.fetchWorkOrdersCount();
      this.fetchOperationalMaintenancesCount();
    }
  }

  fetchWorkOrdersCount = () => {
    API.listWorkOrders(this.props.selectedSystem.id, {
      asset_with_tree_children_id: this.props.asset.id,
      status: `${WorkOrderStatus.NotStarted},${WorkOrderStatus.Paused},${WorkOrderStatus.InProgress}`,
    }).then(({ headers }) => {
      this.setState({
        totalPlannedWorkOrders: HelperFunctions.getPaginationFromHeader(headers).totalEntries,
        isFetchingWorkOrdersCount: false,
      });
    });
  };

  fetchOperationalMaintenancesCount = () => {
    API.listOperationalMaintenanceTemplates(this.props.selectedSystem.id, {
      active_recurrence_for_asset_with_tree_children_id: this.props.asset.id,
    }).then(({ headers }) => {
      this.setState({
        totalOperationalMaintenanceTemplates: HelperFunctions.getPaginationFromHeader(headers).totalEntries,
        isFetchingOperationalMaintenancesCount: false,
      });
    });
  };

  archiveAsset = () => {
    this.setState({ isArchiving: true });
    this.props
      .updateAsset(this.props.asset.id, {
        archived: true,
      })
      .then(res => {
        this.props.onSave();
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isArchiving: false });
      });
  };

  renderContent = () => {
    return (
      <div className={styles['content-container']}>
        <ArchiveItem
          loading={this.state.isFetchingWorkOrdersCount}
          label={<FormattedMessage id="components.archive-asset-modal.planned-work-orders-label" />}
          title={<FormattedMessage id="components.archive-asset-modal.planned-work-orders" />}
          number={this.state.totalPlannedWorkOrders}
        />
        <ArchiveItem
          loading={this.state.isFetchingOperationalMaintenancesCount}
          label={<FormattedMessage id="components.archive-asset-modal.operational-maintenances-label" />}
          title={<FormattedMessage id="components.archive-asset-modal.operational-maintenances" />}
          number={this.state.totalOperationalMaintenanceTemplates}
        />
      </div>
    );
  };

  render() {
    if (!this.props.asset) return null;
    return (
      <Modal width={580} isOpen={this.props.open}>
        <Modal.Header
          title={
            <span className={styles['warning-text']}>
              <FormattedMessage id="components.archive-asset-modal.title" />
            </span>
          }
          subtitle={<AssetTitle id={this.props.asset.id} />}
          onClose={this.props.onClose}
          ignoreLine
        />
        {this.renderContent()}
        <Modal.Footer container>
          <div className={styles['confirm-container']}>
            <Field.Checkbox
              checked={this.state.confirmArchive}
              onChange={value => this.setState({ confirmArchive: value })}
              label={<FormattedMessage id="components.asset-delete.confirm-title" />}
            />
          </div>
          <div className={styles['buttons-container']}>
            <Button.Group>
              <Button
                disabled={!this.state.confirmArchive}
                destructive
                loading={this.state.isArchiving}
                primary
                label="components.archive-asset-modal.button"
                onClick={this.archiveAsset}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveAssetModal);
