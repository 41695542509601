import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import AnimateHeight from 'react-animate-height';
import moment from 'moment';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import { Button, Icon, NewInlineModal, ConfirmModal } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import Day from './Day';
import {
  OperationalMaintenancesSelectors,
  OperationalMaintenancesOperations,
} from 'state/ducks/operationalMaintenances';
import {
  BookmarkedAssetsDropdownUtils,
  BookmarkedAssetsDropdownSelectors,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { SDKReduxOperations } from 'sdk';

class Asset extends Component {
  state = {
    dropdownOpen: false,
    showConfirmRemoveBookmarkModal: false,
    isRemovingAssetFromBookmarks: false,
  };

  renderRemoveAssetFromBookmarkModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmRemoveBookmarkModal}
        title={
          <FormattedMessage id="screens.operational-maintenances.calendar.remove-from-bookmark-modal.title" />
        }
        message={
          <FormattedMessage id="screens.operational-maintenances.calendar.remove-from-bookmark-modal.subtitle" />
        }
        destructive
        confirmButtonText="general.remove"
        confirmIsLoading={this.state.isRemovingAssetFromBookmarks}
        onConfirm={() => {
          this.setState({ isRemovingAssetFromBookmarks: true });
          this.props.deleteAssetBookmark(this.props.bookmarkedAssetId).then(() => {
            this.setState({ showConfirmRemoveBookmarkModal: false, isRemovingAssetFromBookmarks: false });
          });
        }}
        onCancel={() => {
          this.setState({ showConfirmRemoveBookmarkModal: false });
        }}
      />
    );
  };

  renderMoreOptions = () => {
    if (this.props.dropdownTypeForAssetDropdown === BookmarkedAssetsDropdownUtils.MenuItem.MyAssets) {
      return (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.dropdownOpen}
            minWidth={150}
            position="right"
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  destructive
                  onClick={() => {
                    this.setState({ dropdownOpen: false, showConfirmRemoveBookmarkModal: true });
                  }}
                >
                  <FormattedMessage id="general.remove" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderAsset = () => {
    if (this.props.asset && this.props.shrinkable) {
      return (
        <div className={styles['asset-container']}>
          <div className={styles['asset-view']}>
            <div className={this.props.isShrinked === true ? styles['button-not-expanded'] : null}>
              <Button
                type="icon"
                icon={<Icon regular type="angle-down" />}
                onClick={() => this.props.toggleExpandAsset(this.props.asset.id)}
              />
            </div>
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.props.asset.title}</div>
              <div className={styles['subtitle']}>
                <TreePath assetId={this.props.asset.id} />
              </div>
            </div>
            {this.renderMoreOptions()}
          </div>
        </div>
      );
    }

    if (this.props.asset) {
      return (
        <div className={styles['asset-container']}>
          <div className={styles['asset-view']}>
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.props.asset.title}</div>
            </div>
            {this.renderMoreOptions()}
          </div>
        </div>
      );
    }

    return (
      <div className={styles['asset-container']}>
        <div className={styles['asset-view']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.operational-maintenances.calendar.all-assets" />
            </div>
          </div>
          {this.renderMoreOptions()}
        </div>
      </div>
    );
  };

  render() {
    let classNames = [styles['days']];
    if (this.props.isShrinked === true) {
      classNames = [...classNames, styles['not-expanded']];
    }
    const monday = moment(this.props.date).isoWeekday(1);
    const tuesday = moment(this.props.date).isoWeekday(2);
    const wednesday = moment(this.props.date).isoWeekday(3);
    const thursday = moment(this.props.date).isoWeekday(4);
    const friday = moment(this.props.date).isoWeekday(5);
    const saturday = moment(this.props.date).isoWeekday(6);
    const sunday = moment(this.props.date).isoWeekday(7);
    const assetId = this.props.asset ? this.props.asset.id : null;
    return (
      <>
        <div className={classNames.join(' ')}>
          {this.renderAsset()}
          <AnimateHeight
            duration={250}
            height={this.props.isShrinked === false ? 'auto' : 0}
            contentClassName={styles['days-content']}
          >
            <Day date={monday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={tuesday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={wednesday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={thursday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={friday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={saturday.format('YYYY-MM-DD')} assetId={assetId} />
            <Day date={sunday.format('YYYY-MM-DD')} assetId={assetId} />
          </AnimateHeight>
        </div>
        {this.renderRemoveAssetFromBookmarkModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleExpandAsset: OperationalMaintenancesOperations.toggleExpandAsset,
      createAssetBookmark: SDKReduxOperations.createAssetBookmark,
      deleteAssetBookmark: SDKReduxOperations.deleteAssetBookmark,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { bookmarkedAssetId, assetId: assetIdFromProps } = ownProps;

  let assetId = null;
  let asset = null;
  if (bookmarkedAssetId) {
    const { asset_id } = EntitySelectors.getAssetBookmark(state, bookmarkedAssetId);
    asset = EntitySelectors.getAsset(state, asset_id);
    assetId = asset_id;
  } else if (assetIdFromProps) {
    asset = EntitySelectors.getAsset(state, assetIdFromProps);
    assetId = assetIdFromProps;
  }

  return {
    asset,
    system: AuthSelectors.getCurrentSystem(state),
    isShrinked: OperationalMaintenancesSelectors.getShrinkedForAssetId(state, assetId),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Asset));
