import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Field, Button, Icon } from 'views/components/Shared/General';
import { TreePath, AssetTitle } from 'views/components/Asset';
import styles from './style.module.scss';

class AssetListItem extends Component {
  renderMultipleRightContainer = () => {
    const hasChildren = this.props.treeItem && this.props.asset.tree_has_children;
    const hasRightComponent =
      typeof this.props.listItemRightComponent === 'function' &&
      this.props.listItemRightComponent(this.props.asset);
    if (hasChildren && hasRightComponent) {
      return (
        <>
          <List.Item.Column alignRight>
            <div className={styles['multiple-right-container']}>
              {this.props.listItemRightComponent(this.props.asset)}
              <div className={styles['separator']} />
            </div>
          </List.Item.Column>
          <List.Item.Column alignRight>
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-right" />}
              small
              onClick={e => {
                e.stopPropagation();
                this.props.onClick();
              }}
            />
          </List.Item.Column>
        </>
      );
    }
    if (hasChildren) {
      return (
        <>
          <List.Item.Column alignRight>
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-right" />}
              small
              onClick={e => {
                e.stopPropagation();
                this.props.onClick();
              }}
            />
          </List.Item.Column>
        </>
      );
    }
    if (hasRightComponent) {
      return (
        <>
          <List.Item.Column alignRight>
            {this.props.listItemRightComponent(this.props.asset)}
          </List.Item.Column>
        </>
      );
    }
    return null;
  };
  renderMultipleListitem = () => {
    if (this.props.loading) {
      return (
        <List.Item disclosureIndicator={this.props.treeItem}>
          <List.Item.Column width={30}>
            <Field.Checkbox />
          </List.Item.Column>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return (
      <List.Item
        onClick={() => this.props.onSelectMultiple(this.props.asset.id)}
        clickable={this.props.alreadySelectedForMultiple(this.props.asset) !== true}
        paddingRight={this.props.treeItem && this.props.asset.tree_has_children === false ? 55 : null}
      >
        <List.Item.Column width={30}>
          <Field.Checkbox
            disabled={this.props.alreadySelectedForMultiple(this.props.asset)}
            checked={
              this.props.selectedAssetIds[this.props.id] ||
              this.props.alreadySelectedForMultiple(this.props.asset)
            }
            onChange={() => {
              this.props.onSelectMultiple(this.props.asset.id);
            }}
          />
        </List.Item.Column>
        <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
        <List.Item.TitleColumn
          title={<AssetTitle id={this.props.asset.id} />}
          subtitle={<TreePath onlyShowPath assetId={this.props.asset.id} />}
        />
        {this.renderMultipleRightContainer()}
      </List.Item>
    );
  };

  renderListItem = () => {
    if (this.props.loading) {
      return (
        <List.Item disclosureIndicator={this.props.treeItem}>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          disclosureIndicator={this.props.treeItem && this.props.asset.tree_has_children}
          clickable={this.props.treeItem && this.props.asset.tree_has_children}
          onClick={this.props.onClick}
          paddingRight={this.props.treeItem && this.props.asset.tree_has_children === false ? 55 : null}
        >
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            title={<AssetTitle id={this.props.asset.id} />}
            subtitle={<TreePath onlyShowPath assetId={this.props.asset.id} />}
          />
          <List.Item.Column alignRight>
            {this.props.listItemRightComponent(this.props.asset)}
          </List.Item.Column>
        </List.Item>
      );
    }
  };

  render() {
    if (this.props.multiple) {
      return this.renderMultipleListitem();
    }
    return this.renderListItem();
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const asset = EntitySelectors.getAsset(state, ownProps.id);
  return {
    asset,
    assetType: EntitySelectors.getAssetType(state, asset.asset_type_id),
    images: EntitySelectors.getImages(state, asset.images),
  };
}

export default connect(mapStateToProps)(AssetListItem);
