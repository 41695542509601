import { RRule, RRuleSet } from 'rrule';

export function buildRRule(recurringMaintenance, additionalData) {
  switch (recurringMaintenance.frequency) {
    case 'daily':
      return buildDailyRRule(recurringMaintenance, additionalData);
    case 'weekly':
      return buildWeeklyRRule(recurringMaintenance, additionalData);
    case 'monthly':
      return buildMonthlyRRule(recurringMaintenance, additionalData);
    case 'yearly':
      return buildYearlyRRule(recurringMaintenance, additionalData);
    default:
      return null;
  }
}

function buildDailyRRule(recurringMaintenance, additionalData) {
  const rruleSet = new RRuleSet();
  rruleSet.rrule(
    new RRule({
      ...additionalData,
      freq: RRule.DAILY,
      byweekday: getByWeekday(recurringMaintenance),
      dtstart: new Date(recurringMaintenance.next_work_order_date),
    })
  );
  rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

  return rruleSet;
}

function buildWeeklyRRule(recurringMaintenance, additionalData) {
  const rruleSet = new RRuleSet();
  rruleSet.rrule(
    new RRule({
      ...additionalData,
      freq: RRule.WEEKLY,
      interval: recurringMaintenance.interval,
      byweekday: getByWeekday(recurringMaintenance),
      dtstart: new Date(recurringMaintenance.next_work_order_date),
    })
  );
  rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

  return rruleSet;
}

function buildMonthlyRRule(recurringMaintenance, additionalData) {
  const { month_type } = recurringMaintenance;

  const rruleSet = new RRuleSet();
  switch (month_type) {
    case 'exact_day':
      rruleSet.rrule(
        new RRule({
          ...additionalData,
          freq: RRule.MONTHLY,
          interval: recurringMaintenance.interval,
          bymonthday: getByMonthDay(recurringMaintenance),
          bysetpos: getBySetPos(recurringMaintenance),
          dtstart: new Date(recurringMaintenance.next_work_order_date),
        })
      );
      rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

      return rruleSet;
    case 'day_of_week':
      rruleSet.rrule(
        new RRule({
          ...additionalData,
          freq: RRule.MONTHLY,
          interval: recurringMaintenance.interval,
          byweekday: getMonthlyWeekday(recurringMaintenance),
          bysetpos: recurringMaintenance.week_of_month,
          dtstart: new Date(recurringMaintenance.next_work_order_date),
        })
      );
      rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

      return rruleSet;
    default:
      return null;
  }
}

function buildYearlyRRule(recurringMaintenance, additionalData) {
  const { month_type } = recurringMaintenance;

  const rruleSet = new RRuleSet();

  switch (month_type) {
    case 'exact_day':
      rruleSet.rrule(
        new RRule({
          ...additionalData,
          freq: RRule.YEARLY,
          interval: recurringMaintenance.interval,
          bymonth: recurringMaintenance.month_of_year,
          bymonthday: getByMonthDay(recurringMaintenance),
          bysetpos: getBySetPos(recurringMaintenance),
          dtstart: new Date(recurringMaintenance.next_work_order_date),
        })
      );
      rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

      return rruleSet;
    case 'day_of_week':
      rruleSet.rrule(
        new RRule({
          ...additionalData,
          freq: RRule.YEARLY,
          interval: recurringMaintenance.interval,
          bymonth: recurringMaintenance.month_of_year,
          byweekday: getMonthlyWeekday(recurringMaintenance),
          bysetpos: recurringMaintenance.week_of_month,
          dtstart: new Date(recurringMaintenance.next_work_order_date),
        })
      );
      rruleSet.exdate(new Date(recurringMaintenance.next_work_order_date));

      return rruleSet;
    default:
      return null;
  }
}

function getByWeekday(recurringMaintenance) {
  let weekdays = [];
  if (recurringMaintenance.mon) weekdays = [...weekdays, RRule.MO];
  if (recurringMaintenance.tue) weekdays = [...weekdays, RRule.TU];
  if (recurringMaintenance.wed) weekdays = [...weekdays, RRule.WE];
  if (recurringMaintenance.thu) weekdays = [...weekdays, RRule.TH];
  if (recurringMaintenance.fri) weekdays = [...weekdays, RRule.FR];
  if (recurringMaintenance.sat) weekdays = [...weekdays, RRule.SA];
  if (recurringMaintenance.sun) weekdays = [...weekdays, RRule.SU];
  return weekdays;
}

function getMonthlyWeekday(recurringMaintenance) {
  let weekdays = {
    mon: RRule.MO,
    tue: RRule.TU,
    wed: RRule.WE,
    thu: RRule.TH,
    fri: RRule.FR,
    sat: RRule.SA,
    sun: RRule.SU,
  };
  return weekdays[recurringMaintenance.weekday_of_month];
}

function getByMonthDay(recurringMaintenance) {
  const { day_of_month } = recurringMaintenance;
  switch (day_of_month) {
    case 29:
      return [28, 29];
    case 30:
      return [28, 29, 30];
    case 31:
      return [28, 29, 30, 31];
    default:
      return day_of_month;
  }
}

function getBySetPos(recurringMaintenance) {
  const { day_of_month } = recurringMaintenance;
  if ([29, 30, 31].includes(day_of_month)) {
    return -1;
  } else {
    return undefined;
  }
}
