import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OperationalMaintenancesSelectors } from 'state/ducks/operationalMaintenances';
import styles from './style.module.scss';
import Instance from './Instance';

class Day extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.date !== this.props.date) {
      return false;
    }
    return true;
  }

  getInstancesForDate = () => {
    return this.props.operationalMaintenances.filter(operationalMaintenance => {
      const { date } = operationalMaintenance;
      return date === this.props.date;
    });
  };

  render() {
    let classNames = [styles['day']];
    const instances = this.getInstancesForDate();
    if (instances.length > 0) {
      classNames = [...classNames, styles['has-instances']];
    }
    return (
      <div className={classNames.join(' ')}>
        {instances.map(({ id }) => (
          <Instance id={id} assetId={this.props.assetId} />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.assetId) {
    return {
      operationalMaintenances:
        OperationalMaintenancesSelectors.getOperationalMaintenancesForCalendarForAssetId(
          state,
          ownProps.assetId
        ),
    };
  } else {
    return {
      operationalMaintenances: OperationalMaintenancesSelectors.getOperationalMaintenancesForCalendar(state),
    };
  }
}

export default connect(mapStateToProps)(Day);
