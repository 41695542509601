import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { List, Button, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { EstimatedDeliveryMode } from 'sdk/PurchaseOrder';
import { Modal } from 'views/components/Shared/Layout';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import styles from './style.module.scss';
import PurchaseOrderRowListItem from './PurchaseOrderRowListItem';

class MultipleEstimatedDeliveryDateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  renderPurchaseOrderRows = () => {
    if (this.props.purchaseOrderRows.length == 0) {
      return (
        <WhiteCard noPadding>
          <EmptyDataSet
            title={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.title" />}
            subtitle={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.subtitle" />}
            image={SparePartSmall}
            tiny
            horizontal
            listContainer
          />
        </WhiteCard>
      );
    }
    return (
      <>
        <List.Header background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-row.article" />
          </List.Header.Column>
          <List.Header.Column width={100}>
            <FormattedMessage id="resources.purchase-order-row.quantity" />
          </List.Header.Column>
          <List.Header.Column alignRight width={250}>
            <FormattedMessage id="resources.purchase-order.expected-delivery-date" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.purchaseOrderRows.map(purchaseOrderRow => (
            <PurchaseOrderRowListItem key={purchaseOrderRow.id} purchaseOrderRow={purchaseOrderRow} />
          ))}
        </List>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={900}>
        <Modal.Header
          title={
            <>
              <div>
                <FormattedMessage id="components.partial-delivery-modal.title" />
              </div>
              {this.props.purchaseOrder.estimated_delivery_mode === EstimatedDeliveryMode.Multiple ? (
                <div className={styles['disable-partial-delivery-button-container']}>
                  <Button
                    gray
                    label="components.partial-delivery-modal.disable-partial-delivery"
                    onClick={this.props.onDisablePartialDelivery}
                  />
                </div>
              ) : null}
            </>
          }
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>{this.renderPurchaseOrderRows()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPurchaseOrderDelivery: SDKReduxOperations.createPurchaseOrderDelivery,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrderRows:
      EntitySelectors.getPurchaseOrderRows(state, ownProps.purchaseOrder.purchase_order_rows) || [],
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleEstimatedDeliveryDateModal);
