import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { CompleteWorkOrderModal } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderStatus } from 'sdk/WorkOrder';

class CompleteWorkOrder extends Component {
  state = {
    showCompleteWorkOrderModal: false,
  };

  componentDidUpdate(prevProps) {
    const allRowsHasBeenFilledIn = !prevProps.allRowsHasBeenFilledIn && this.props.allRowsHasBeenFilledIn;
    if (this.props.workOrder.status !== WorkOrderStatus.Completed && allRowsHasBeenFilledIn) {
      this.setState({ showCompleteWorkOrderModal: true });
    }
  }

  render() {
    return (
      <CompleteWorkOrderModal
        openedFromChecklist
        open={this.state.showCompleteWorkOrderModal}
        workOrder={this.props.workOrder}
        recurringMaintenance={this.props.recurringMaintenance}
        onClose={() => this.setState({ showCompleteWorkOrderModal: false })}
      />
    );
  }
}

function mapStateToProps(state) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  const workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
  return {
    checklistInstance,
    workOrder,
    recurringMaintenance: EntitySelectors.getWorkOrder(state, workOrder.recurring_maintenance_id),
    allRowsHasBeenFilledIn: ChecklistModalSelectors.getAllRowsHasBeenFilledIn(state),
  };
}

export default connect(mapStateToProps)(CompleteWorkOrder);
