import React, { Component } from 'react';
import AssetListItem from './AssetListItem';

class AssetList extends Component {
  render() {
    return this.props.ids.map(assetId => {
      return (
        <AssetListItem
          listItemRightComponent={this.props.listItemRightComponent}
          alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
          loading={this.props.loading}
          id={assetId}
          multiple={this.props.multiple}
          selectedAssetIds={this.props.selectedAssetIds}
          onSelectMultiple={this.props.onSelectMultiple}
        />
      );
    });
  }
}

export default AssetList;
