import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { VendorSelectors } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import Menu from './Menu';
import EditNameModal from './EditNameModal';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      showEditNameModal: false,
    };
  }

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem
          to={{
            pathname: '/vendors',
          }}
        >
          <FormattedMessage id="screens.vendor.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>{this.props.vendor.name}</LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTabs = () => {
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/vendors/${id}`}
          active={matchPath(this.props.location.pathname, { path: '/vendors/:id/', exact: true }) != null}
        >
          <FormattedMessage id="screens.vendor.tabs.information" />
        </LayoutHeader.TabBarItem>
        <LayoutHeader.TabBarItem
          to={`/vendors/${id}/contact-persons`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/vendors/:id/contact-persons/',
            }) != null
          }
        >
          <FormattedMessage id="screens.vendor.tabs.contact-persons" />
        </LayoutHeader.TabBarItem>
        <LayoutHeader.TabBarItem
          to={`/vendors/${id}/assets`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/vendors/:id/assets/',
              exact: true,
            }) != null
          }
        >
          <FormattedMessage id="screens.vendor.tabs.assets" />
        </LayoutHeader.TabBarItem>
        {this.props.vendor.create_purchase_orders && this.props.settings.spare_parts_activated ? (
          <LayoutHeader.TabBarItem
            to={`/vendors/${id}/spare-parts`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/vendors/:id/spare-parts/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.vendor.tabs.spare-parts" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.vendor.assign_work_orders ? (
          <LayoutHeader.TabBarItem
            to={`/vendors/${id}/work-orders`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/vendors/:id/work-orders/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.vendor.tabs.work-orders" />
          </LayoutHeader.TabBarItem>
        ) : null}
        {this.props.vendor.create_purchase_orders &&
        this.props.canViewPurchaseOrders &&
        this.props.settings.spare_parts_activated ? (
          <LayoutHeader.TabBarItem
            to={`/vendors/${id}/purchase-orders`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/vendors/:id/purchase-orders/',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.vendor.tabs.purchase-orders" />
          </LayoutHeader.TabBarItem>
        ) : null}
        <LayoutHeader.TabBarItem
          to={`/vendors/${id}/files`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/vendors/:id/files/',
              exact: true,
            }) != null
          }
        >
          <FormattedMessage id="screens.vendor.tabs.attachments" />
        </LayoutHeader.TabBarItem>
      </LayoutHeader.TabBar>
    );
  };

  render() {
    return (
      <>
        <LayoutHeader
          title={this.props.vendor.name}
          titleHoverText={this.props.vendor.name}
          breadcrumbComponent={this.renderBreadcrumb()}
          tabBarComponent={this.renderTabs()}
          editTitleButton={
            !this.props.canEditVendors ? null : (
              <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => this.setState({ showEditNameModal: true })}
              />
            )
          }
          buttonsComponent={!this.props.canEditVendors ? null : <Menu />}
        />
        <EditNameModal
          open={this.state.showEditNameModal}
          vendor={this.props.vendor}
          onClose={() => this.setState({ showEditNameModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendor: VendorSelectors.getVendor(state),
    settings: AuthSelectors.getSettings(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
