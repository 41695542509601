import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, MoneyWithCurrency } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class CostSummary extends PureComponent {
  state = {
    showListCosts: false,
  };
  render() {
    return (
      <>
        <div className={styles['container']}>
          <div className={`${styles['cost']} ${styles['total']}`}>
            <p className={`${styles['money']} ${styles['total']}`}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.totalCost}
                fractionSize={0}
                currencyFontSize={16}
              />
            </p>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.costs.total-cost" />
            </p>
          </div>
          <div className={styles['cost']}>
            <p className={styles['money']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.laborCost}
                fractionSize={0}
                currencyFontSize={16}
              />
            </p>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.costs.labor-cost" />
            </p>
          </div>
          <div className={styles['cost']}>
            <p className={styles['money']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.partCost}
                fractionSize={0}
                currencyFontSize={16}
              />
            </p>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.costs.part-cost" />
            </p>
          </div>
          {this.props.downtimeActivated ? (
            <div className={styles['cost']}>
              <p className={styles['money']}>
                <MoneyWithCurrency
                  currency={this.props.currency}
                  value={this.props.downtimeCost}
                  fractionSize={0}
                  currencyFontSize={16}
                />
              </p>
              <p className={styles['title']}>
                <FormattedMessage id="screens.work-order.costs.downtime-cost" />
              </p>
            </div>
          ) : null}
          <div className={styles['cost']}>
            <p className={styles['money']}>
              <MoneyWithCurrency
                currency={this.props.currency}
                value={this.props.otherCost}
                fractionSize={0}
                currencyFontSize={16}
              />
            </p>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.costs.other-cost" />
            </p>
          </div>
        </div>
      </>
    );
  }
}
