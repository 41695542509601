import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { UserNameWrapper } from 'views/components/User';
import { Icon, Button, Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ProductionSupervisorAssetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
      isHoveringIcon: false,
    };
  }

  deleteAssetProductionSupervisor = () => {
    this.setState({ isDeleting: true });
    this.props.deleteAssetProductionSupervisor(this.props.id).then(() => {
      this.props.onDelete(this.props.user.id);
    });
  };

  render() {
    return (
      <div className={styles['item']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <UserNameWrapper user={this.props.user} />
          </div>
        </div>
        <div className={styles['icon-container']}>
          {this.state.isDeleting ? (
            <Loader tiny centerInParent />
          ) : (
            <Button
              type="icon"
              icon={<Icon regular red type="trash-alt" />}
              onClick={() => this.deleteAssetProductionSupervisor()}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAssetProductionSupervisor: SDKReduxOperations.deleteAssetProductionSupervisor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const { user_id } = EntitySelectors.getAssetProductionSupervisor(state, id);
  return {
    user: EntitySelectors.getUser(state, user_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionSupervisorAssetItem);
