import { API, HelperFunctions } from 'sdk';
import { normalizeVendor } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import actions from './actions';

const resetSelectedVendors = actions.resetSelectedVendors;
const selectVendor = actions.selectVendor;
const selectPage = actions.selectPage;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;

const fetchVendors = (systemId, params, config) => dispatch => {
  dispatch(actions.fetchVendors());
  return API.listVendors(systemId, params, config).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeVendor(res.data);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchVendorsSuccess({ ids: result, pagination }));
    return res.data;
  });
};

export default {
  resetSelectedVendors,
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
  selectVendor,
  addQueryParameter,
  fetchVendors,
  resetFilter,
  removeFilter,
  addFilter,
  showAppliedFilters,
  showAvailableFilters,
  selectFilterType,
};
