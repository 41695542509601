import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown as fasAngleDown,
  faArchive as fasArchive,
  faArrowAltDown as fasArrowAltDown,
  faArrowAltLeft as fasArrowAltLeft,
  faArrowAltRight as fasArrowAltRight,
  faArrowDown as fasArrowDown,
  faArrowRight as fasArrowRight,
  faArrowUp as fasArrowUp,
  faBell as fasBell,
  faBox as fasBox,
  faBoxAlt as fasBoxAlt,
  faBoxOpen as fasBoxOpen,
  faBriefcase as fasBriefcase,
  faBrowser as fasBrowser,
  faCalendarAlt as fasCalendarAlt,
  faCaretDown as fasCaretDown,
  faChartBar as fasChartBar,
  faCheck as fasCheck,
  faClipboardList as fasClipboardList,
  faClock as fasClock,
  faCoffee as fasCoffee,
  faCog as fasCog,
  faCogs as fasCogs,
  faComment as fasComment,
  faCommentAltExclamation as fasCommentAltExclamation,
  faEllipsisH as fasEllipsisH,
  faEnvelope as fasEnvelope,
  faExclamation as fasExclamation,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExternalLink as fasExternalLink,
  faEye as fasEye,
  faFileAlt as fasFileAlt,
  faFileArchive as fasFileArchive,
  faFileAudio as fasFileAudio,
  faFileCheck as fasFileCheck,
  faFileCode as fasFileCode,
  faFileExcel as fasFileExcel,
  faFileExport as fasFileExport,
  faFileImage as fasFileImage,
  faFileInvoiceDollar as fasFileInvoiceDollar,
  faFilePdf as fasFilePdf,
  faFilePlus as fasFilePlus,
  faFilePowerpoint as fasFilePowerpoint,
  faFileVideo as fasFileVideo,
  faFileWord as fasFileWord,
  faFilter as fasFilter,
  faFolder as fasFolder,
  faHistory as fasHistory,
  faHomeAlt as fasHomeAlt,
  faImage as fasImage,
  faInfoCircle as fasInfoCircle,
  faLayerGroup as fasLayerGroup,
  faLink as fasLink,
  faList as fasList,
  faListAlt as fasListAlt,
  faListUl as fasListUl,
  faLock as fasLock,
  faLockAlt as fasLockAlt,
  faLockOpenAlt as fasLockOpenAlt,
  faMobileAlt as fasMobileAlt,
  faPause as fasPause,
  faPhone as fasPhone,
  faPlus as fasPlus,
  faQrcode as fasQrcode,
  faRepeat as fasRepeat,
  faSort as fasSort,
  faSortAlphaDown as fasSortAlphaDown,
  faStar as fasStar,
  faTachometerAltSlow as fasTachometerAltSlow,
  faTag as fasTag,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faTruck as fasTruck,
  faUser as fasUser,
  faUserAlt as fasUserAlt,
  faUsers as fasUsers,
  faWarehouse as fasWarehouse,
  faWarehouseAlt as fasWarehouseAlt,
  faWrench as fasWrench,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCoffee as farCoffee,
  faCheck as farCheck,
  faCalendarAlt as farCalendarAlt,
  faExclamationCircle as farExclamationCircle,
  faRepeat as farRepeat,
  faDollarSign as farDollarSign,
  faQuestionCircle as farQuestionCircle,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faAlignLeft as farAlignLeft,
  faAlignCenter as farAlignCenter,
  faAlignRight as farAlignRight,
  faAngleRight as farAngleRight,
  faBold as farBold,
  faItalic as farItalic,
  faAngleDown as farAngleDown,
  faAngleLeft as farAngleLeft,
  faComment as farComment,
  faTruck as farTruck,
  faWrench as farWrench,
  faFolder as farFolder,
  faCogs as farCogs,
  faLayerGroup as farLayerGroup,
  faBox as farBox,
  faStar as farStar,
  faSync as farSync,
  faBoxAlt as farBoxAlt,
  faFileAlt as farFileAlt,
  faFileExport as farFileExport,
  faLockOpenAlt as farLockOpenAlt,
  faUserLock as farUserLock,
  faUser as farUser,
  faTrashAlt as farTrashAlt,
  faBan as farBan,
  faPrint as farPrint,
  faPlusCircle as farPlusCircle,
  faClock as farClock,
  faMinus as farMinus,
  faInfoCircle as farInfoCircle,
  faEllipsisH as farEllipsisH,
  faSearch as farSearch,
  faStrikethrough as farStrikethrough,
  faAngleDoubleLeft as farAngleDoubleLeft,
  faAngleDoubleRight as farAngleDoubleRight,
  faBriefcase as farBriefcase,
  faExclamationTriangle as farExclamationTriangle,
  faUpload as farUpload,
  faLink as farLink,
  faPen as farPen,
  faUnderline as farUnderline,
  faFileImage as farFileImage,
  faDownload as farDownload,
  faExternalLink as farExternalLink,
  faHistory as farHistory,
  faList as farList,
  faListAlt as farListAlt,
  faArrowRight as farArrowRight,
  faArrowToRight as farArrowToRight,
  faArrowAltRight as farArrowAltRight,
  faArrowAltDown as farArrowAltDown,
  faArrowAltLeft as farArrowAltLeft,
  faImage as farImage,
  faRedoAlt as farRedoAlt,
  faPlus as farPlus,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faCalendarDay as farCalendarDay,
  faExpand as farExpand,
  faChartBar as farChartBar,
  faQrcode as farQrcode,
  faCog as farCog,
  faInbox as farInbox,
  faArrowCircleRight as farArrowCircleRight,
  faFileCheck as farFileCheck,
  faFilter as farFilter,
  faBrowser as farBrowser,
  faClone as farClone,
  faLayerMinus as farLayerMinus,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faPause as farPause,
  faArchive as farArchive,
  faUsers as farUsers,
  faGlobeEurope as farGlobeEurope,
  faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCoffee as falCoffee,
  faCheck as falCheck,
  faExclamation as falExclamation,
  faAngleRight as falAngleRight,
  faEllipsisH as falEllipsisH,
  faTimes as falTimes,
  faCog as falCogs,
  faComment as falComment,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleLeft as falAngleLeft,
} from '@fortawesome/pro-light-svg-icons';

const init = () => {
  library.add(
    fasAngleDown,
    fasArchive,
    fasArrowAltDown,
    fasArrowAltLeft,
    fasArrowAltRight,
    fasArrowDown,
    fasArrowRight,
    fasArrowUp,
    fasBell,
    fasBox,
    fasBoxAlt,
    fasBoxOpen,
    fasBriefcase,
    fasBrowser,
    fasCalendarAlt,
    fasCaretDown,
    fasChartBar,
    fasCheck,
    fasClipboardList,
    fasClock,
    fasCoffee,
    fasCog,
    fasCogs,
    fasComment,
    fasCommentAltExclamation,
    fasEllipsisH,
    fasEnvelope,
    fasExclamation,
    fasExclamationCircle,
    fasExclamationTriangle,
    fasExternalLink,
    fasEye,
    fasFileAlt,
    fasFileArchive,
    fasFileAudio,
    fasFileCheck,
    fasFileCode,
    fasFileExcel,
    fasFileExport,
    fasFileImage,
    fasFileInvoiceDollar,
    fasFilePdf,
    fasFilePlus,
    fasFilePowerpoint,
    fasFileVideo,
    fasFileWord,
    fasFilter,
    fasFolder,
    fasHistory,
    fasHomeAlt,
    fasImage,
    fasInfoCircle,
    fasLayerGroup,
    fasLink,
    fasList,
    fasListAlt,
    fasListUl,
    fasLock,
    fasLockAlt,
    fasLockOpenAlt,
    fasMobileAlt,
    fasPause,
    fasPhone,
    fasPlus,
    fasQrcode,
    fasRepeat,
    fasSort,
    fasSortAlphaDown,
    fasStar,
    fasTachometerAltSlow,
    fasTag,
    fasTimes,
    fasTrashAlt,
    fasTruck,
    fasUser,
    fasUserAlt,
    fasUsers,
    fasWarehouse,
    fasWarehouseAlt,
    fasWrench
  );

  library.add(
    farAngleDoubleLeft,
    farAngleDoubleRight,
    farAngleDown,
    farAngleLeft,
    farAngleRight,
    farArchive,
    farArrowAltDown,
    farArrowAltLeft,
    farArrowAltRight,
    farArrowCircleRight,
    farArrowDown,
    farArrowRight,
    farArrowToRight,
    farArrowUp,
    farBan,
    farBox,
    farBoxAlt,
    farBriefcase,
    farBrowser,
    farCalendarAlt,
    farCalendarDay,
    farChartBar,
    farCheck,
    farClock,
    farClone,
    farCoffee,
    farAlignLeft,
    farAlignCenter,
    farAlignRight,
    farItalic,
    farBold,
    farCog,
    farCogs,
    farComment,
    farDollarSign,
    farDownload,
    farEllipsisH,
    farEnvelope,
    farExclamationCircle,
    farExclamationTriangle,
    farStrikethrough,
    farTimes,
    farUnderline,
    farExpand,
    farExternalLink,
    farFileAlt,
    farFileCheck,
    farFileExport,
    farFileImage,
    farFileInvoiceDollar,
    farFilter,
    farFolder,
    farGlobeEurope,
    farHistory,
    farImage,
    farInbox,
    farInfoCircle,
    farLayerGroup,
    farLayerMinus,
    farLink,
    farList,
    farListAlt,
    farLockOpenAlt,
    farMinus,
    farPause,
    farPen,
    farPhone,
    farPlus,
    farPlusCircle,
    farPrint,
    farQrcode,
    farQuestionCircle,
    farRedoAlt,
    farRepeat,
    farSearch,
    farStar,
    farSync,
    farTimes,
    farTrashAlt,
    farTruck,
    farUpload,
    farUser,
    farUserLock,
    farUsers,
    farWrench
  );

  library.add(
    falAngleDown,
    falAngleLeft,
    falAngleRight,
    falAngleUp,
    falCheck,
    falCoffee,
    falCogs,
    falComment,
    falEllipsisH,
    falExclamation,
    falTimes
  );
};

export default {
  init,
};
