import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from 'views/components/Shared/General';
import { FeedbackModal } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

class CustomerFeedback extends Component {
  state = {
    showAlert: true,
    showAlertAfterAnimation: true,
    showFeedbackModal: false,
  };

  componentDidMount() {
    let showAlert = false;
    if (this.showFeedbackAlert()) {
      showAlert = true;
    }
    this.setState({ showAlert, showAlertAfterAnimation: showAlert });
  }

  showFeedbackAlert = props => {
    const { never_show_feedback, has_seen_latest_feedback } = this.props.user;
    if (never_show_feedback) {
      return false;
    }
    if (has_seen_latest_feedback) {
      return false;
    }
    if (process.env.REACT_APP_IS_GATHERING_FEEDBACK !== 'true') {
      return false;
    }
    if (this.props.isAdmin === false) {
      return false;
    }
    return true;
  };

  render() {
    if (this.state.showAlertAfterAnimation) {
      let alertClassNames = [parentStyles['alert'], styles['alert'], styles['clickable']];
      return (
        <>
          <AnimateHeight
            duration={250}
            height={this.state.showAlert ? 'auto' : 0}
            onAnimationEnd={() =>
              this.setState({
                showAlertAfterAnimation: false,
              })
            }
            contentClassName={alertClassNames.join(' ')}
            onClick={() => this.setState({ showFeedbackModal: true })}
          >
            <div className={styles['title']}>
              <span>Frågeformulär om mätarbaserat underhåll – Vi behöver din hjälp!</span>
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light white type="times" size={16} />}
                iconColor="#FFF"
                onClick={e => {
                  e.stopPropagation();
                  this.props.updateMe({ has_seen_latest_feedback: true });
                  this.setState({ showAlert: false });
                }}
              />
            </div>
          </AnimateHeight>
          <FeedbackModal
            open={!!this.state.showFeedbackModal}
            onClose={() => this.setState({ showFeedbackModal: false })}
          />
        </>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateMe: SDKReduxOperations.updateMe }, dispatch);
}

function mapStateToProps(state) {
  return {
    user: AuthSelectors.getCurrentUser(state),
    isAdmin: AuthSelectors.isAdmin(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback);
