import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WhiteCard, Button, Field, Tooltip, Icon } from 'views/components/Shared/General';
import { PurchaseOrderSelectors } from 'state/ducks/purchaseOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { Grid } from 'views/components/Shared/Layout';
import UserField from './UserField';
import ChooseAddressModal from './ChooseAddressModal';
import EditAddressModal from './EditAddressModal';
import { Address } from 'views/components/General';
import styles from './styles.module.scss';

class OurInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingPurchaseOrder: {},
      editingPurchaseOrderBeforeEdit: {},
      showChooseBillingAddressModal: false,
      showChooseShippingAddressModal: false,
      showEditBillingAddressModal: false,
      editingAddress: {},
    };
  }

  edit = () => {
    const { our_reference_name } = this.props.purchaseOrder;

    const editingPurchaseOrder = {
      shipping_name: this.props.purchaseOrder.shipping_name,
      shipping_address1: this.props.purchaseOrder.shipping_address1,
      shipping_address2: this.props.purchaseOrder.shipping_address2,
      shipping_zip: this.props.purchaseOrder.shipping_zip,
      shipping_city: this.props.purchaseOrder.shipping_city,
      shipping_country: this.props.purchaseOrder.shipping_country,
      billing_name: this.props.purchaseOrder.billing_name,
      billing_address1: this.props.purchaseOrder.billing_address1,
      billing_address2: this.props.purchaseOrder.billing_address2,
      billing_zip: this.props.purchaseOrder.billing_zip,
      billing_city: this.props.purchaseOrder.billing_city,
      billing_country: this.props.purchaseOrder.billing_country,
      billing_email: this.props.purchaseOrder.billing_email,
      shipping_id: this.props.purchaseOrder.shipping_id,
      our_reference_user_id: this.props.purchaseOrder.our_reference_user_id,
      our_reference_name: this.props.purchaseOrder.our_reference_name,
      our_reference_email: this.props.purchaseOrder.our_reference_email,
      our_reference_phone: this.props.purchaseOrder.our_reference_phone,
    };

    this.setState({
      editing: true,
      editingPurchaseOrder: editingPurchaseOrder,
      editingPurchaseOrderBeforeEdit: editingPurchaseOrder,
      showRefFreeText: our_reference_name,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });
    const { our_reference_name, our_reference_user_id } = this.state.editingPurchaseOrder;

    let params = this.getModifiedPurchaseOrderData();
    if (this.state.showRefFreeText && our_reference_name) {
      params = {
        ...params,
        our_reference_user_id: null,
      };
    } else if (!this.state.showRefFreeText && our_reference_user_id) {
      params = {
        ...params,
        our_reference_name: null,
        our_reference_phone: null,
        our_reference_email: null,
      };
    }

    this.props
      .updatePurchaseOrder(this.props.purchaseOrder.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedPurchaseOrderData = () => {
    const vendor = this.state.editingPurchaseOrder;
    const purchaseOrderBeforeEdit = this.state.editingPurchaseOrderBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== purchaseOrderBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  setPurchaseOrderValue = obj => {
    const neweditingPurchaseOrder = {
      ...this.state.editingPurchaseOrder,
      ...obj,
    };

    this.setState({
      editingPurchaseOrder: neweditingPurchaseOrder,
    });
  };

  isLoaded = () => this.props.purchaseOrder != null;

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditPurchaseOrders) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  hasNoShippingAddress = () => {
    const {
      shipping_name,
      shipping_address1,
      shipping_address2,
      shipping_zip,
      shipping_city,
      shipping_country,
    } = this.props.purchaseOrder;
    return (
      !shipping_name &&
      !shipping_address1 &&
      !shipping_address2 &&
      !shipping_zip &&
      !shipping_city &&
      !shipping_country
    );
  };

  hasNoBillingAddress = () => {
    const { billing_name, billing_address1, billing_address2, billing_zip, billing_city, billing_ountry } =
      this.props.purchaseOrder;
    return (
      !billing_name &&
      !billing_address1 &&
      !billing_address2 &&
      !billing_zip &&
      !billing_city &&
      !billing_ountry
    );
  };

  renderInvoiceEmail = () => {
    if (this.props.purchaseOrder.billing_email) {
      return (
        <div className={styles['billing-email']}>
          <Field view label={<FormattedMessage id="resources.purchase-order.billing-email" />}>
            {this.props.purchaseOrder.billing_email}
          </Field>
        </div>
      );
    }
    return null;
  };

  renderViewShippingAdress = () => {
    if (this.hasNoShippingAddress()) return '-';

    return (
      <div className={styles['adress']}>
        <Address
          name={this.props.purchaseOrder.shipping_name}
          address1={this.props.purchaseOrder.shipping_address1}
          address2={this.props.purchaseOrder.shipping_address2}
          zip={this.props.purchaseOrder.shipping_zip}
          city={this.props.purchaseOrder.shipping_city}
          country={this.props.purchaseOrder.shipping_country}
        />
      </div>
    );
  };

  renderViewBillingAdress = () => {
    if (this.hasNoBillingAddress()) return '-';
    return (
      <>
        <div className={styles['adress']}>
          {this.hasNoBillingAddress() ? (
            <Address
              name={this.props.purchaseOrder.billing_name}
              address1={this.props.purchaseOrder.billing_address1}
              address2={this.props.purchaseOrder.billing_address2}
              zip={this.props.purchaseOrder.billing_zip}
              city={this.props.purchaseOrder.billing_city}
              country={this.props.purchaseOrder.billing_country}
            />
          ) : (
            <Address
              name={this.props.purchaseOrder.billing_name}
              address1={this.props.purchaseOrder.billing_address1}
              address2={this.props.purchaseOrder.billing_address2}
              zip={this.props.purchaseOrder.billing_zip}
              city={this.props.purchaseOrder.billing_city}
              country={this.props.purchaseOrder.billing_country}
            />
          )}
        </div>
      </>
    );
  };

  renderEditingShippingAdress = () => (
    <>
      <div
        onClick={() => {
          this.setState(prevState => ({
            showChooseShippingAddressModal: true,
            editingAddress: {
              name: this.state.editingPurchaseOrder.shipping_name,
              address1: this.state.editingPurchaseOrder.shipping_address1,
              address2: this.state.editingPurchaseOrder.shipping_address2,
              zip: this.state.editingPurchaseOrder.shipping_zip,
              city: this.state.editingPurchaseOrder.shipping_city,
              country: this.state.editingPurchaseOrder.shipping_country,
            },
          }));
        }}
      >
        <Field.Resource
          clearable={false}
          value={
            <div className={`${styles['adress']} ${this.state.editing ? styles['edit'] : ''}`}>
              <Address
                name={this.state.editingPurchaseOrder.shipping_name}
                address1={this.state.editingPurchaseOrder.shipping_address1}
                address2={this.state.editingPurchaseOrder.shipping_address2}
                zip={this.state.editingPurchaseOrder.shipping_zip}
                city={this.state.editingPurchaseOrder.shipping_city}
                country={this.state.editingPurchaseOrder.shipping_country}
              />
            </div>
          }
        />
      </div>
    </>
  );

  renderEditingBillingAddress = () => (
    <>
      <div
        onClick={() => {
          this.setState(prevState => ({
            showEditBillingAddressModal: true,
            editingAddress: {
              name: this.state.editingPurchaseOrder.billing_name,
              address1: this.state.editingPurchaseOrder.billing_address1,
              address2: this.state.editingPurchaseOrder.billing_address2,
              zip: this.state.editingPurchaseOrder.billing_zip,
              city: this.state.editingPurchaseOrder.billing_city,
              country: this.state.editingPurchaseOrder.billing_country,
            },
          }));
        }}
      >
        <Field.Resource
          clearable={false}
          value={
            <div className={`${styles['adress']} ${this.state.editing ? styles['edit'] : ''}`}>
              <Address
                name={this.state.editingPurchaseOrder.billing_name}
                address1={this.state.editingPurchaseOrder.billing_address1}
                address2={this.state.editingPurchaseOrder.billing_address2}
                zip={this.state.editingPurchaseOrder.billing_zip}
                city={this.state.editingPurchaseOrder.billing_city}
                country={this.state.editingPurchaseOrder.billing_country}
              />
            </div>
          }
        />
      </div>
    </>
  );

  renderRefInformation = () => {
    if (this.state.editing) {
      if (this.state.showRefFreeText) {
        return (
          <Field
            view={false}
            label={
              <div className={styles['ref-container']}>
                <p className={styles['label']}>
                  <FormattedMessage id="resources.purchase-order.our-ref" />
                </p>
                <p className={styles['button']} onClick={() => this.setState({ showRefFreeText: false })}>
                  <FormattedMessage id="screens.purchase-order.info.our-information.change-to-user" />
                </p>
              </div>
            }
          >
            <div className={styles['free-text-container']}>
              <Field.Text
                placeholder={this.props.intl.formatMessage({ id: 'resources.purchase-order.our-ref' })}
                value={this.state.editingPurchaseOrder.our_reference_name}
                onChange={our_reference_name => {
                  this.setPurchaseOrderValue({ our_reference_name });
                }}
              />
            </div>
            <div className={styles['free-text-container']}>
              <Field.Text
                icon={<Icon type="envelope" />}
                type="email"
                value={this.state.editingPurchaseOrder.our_reference_email}
                onChange={our_reference_email => {
                  this.setPurchaseOrderValue({ our_reference_email });
                }}
              />
            </div>
            <Field.Text
              icon={<Icon type="phone" />}
              value={this.state.editingPurchaseOrder.our_reference_phone}
              onChange={our_reference_phone => {
                this.setPurchaseOrderValue({ our_reference_phone });
              }}
            />
          </Field>
        );
      }
      return (
        <Field
          view={!this.state.editing}
          label={
            <div className={styles['ref-container']}>
              <p className={styles['label']}>
                <FormattedMessage id="resources.purchase-order.our-ref" />
              </p>
              <p className={styles['button']} onClick={() => this.setState({ showRefFreeText: true })}>
                <FormattedMessage id="screens.purchase-order.info.our-information.change-to-free-text" />
              </p>
            </div>
          }
        >
          <UserField
            edit
            value={this.state.editingPurchaseOrder.our_reference_user_id}
            onChange={our_reference_user_id => this.setPurchaseOrderValue({ our_reference_user_id })}
          />
        </Field>
      );
    }
    if (this.props.purchaseOrder.our_reference_user_id) {
      return (
        <Field view label={<FormattedMessage id="resources.purchase-order.our-ref" />}>
          <UserField value={this.props.purchaseOrder.our_reference_user_id} />
        </Field>
      );
    }
    if (this.props.purchaseOrder.our_reference_name) {
      return (
        <Field view label={<FormattedMessage id="resources.purchase-order.our-ref" />}>
          <div className={styles['user']}>
            <p>{this.props.purchaseOrder.our_reference_name}</p>
            <div className={styles['icons']}>
              <div className={styles['info-container']}>
                {this.props.purchaseOrder.our_reference_phone ? (
                  <Tooltip
                    trigger={<Icon regular type="phone" />}
                    label={this.props.purchaseOrder.our_reference_phone}
                  />
                ) : (
                  <Icon regular type="phone" disabled />
                )}
              </div>
              <div className={styles['info-container']}>
                {this.props.purchaseOrder.our_reference_email ? (
                  <Tooltip
                    trigger={<Icon regular type="envelope" />}
                    label={this.props.purchaseOrder.our_reference_email}
                  />
                ) : (
                  <Icon regular type="envelope" disabled />
                )}
              </div>
            </div>
          </div>
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.purchase-order.our-ref" />}>
        -
      </Field>
    );
  };

  renderAddress = () => {
    if (this.state.editing) {
      return (
        <React.Fragment>
          <Grid.Row>
            <Grid.Column>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.purchase-order.shipping-address" />}
              >
                {this.renderEditingShippingAdress()}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.purchase-order.billing-address" />}
              >
                {this.renderEditingBillingAddress()}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.purchase-order.billing-email" />}
              >
                <Field.Text
                  value={this.state.editingPurchaseOrder.billing_email}
                  onChange={billing_email => {
                    this.setPurchaseOrderValue({ billing_email });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    } else {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field
              view={!this.state.editing}
              label={<FormattedMessage id="resources.purchase-order.shipping-address" />}
            >
              {this.renderViewShippingAdress()}
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              view={!this.state.editing}
              label={<FormattedMessage id="resources.purchase-order.billing-address" />}
            >
              {this.renderViewBillingAdress()}
              {this.renderInvoiceEmail()}
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
  };

  renderContent = () => {
    if (!this.isLoaded()) return null;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>{this.renderRefInformation()}</Grid.Column>
          </Grid.Row>
          {this.renderAddress()}
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <>
        <WhiteCard
          fullHeight
          title={<FormattedMessage id="screens.purchase-order.info.our-information.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
        <ChooseAddressModal
          address={this.state.editingAddress}
          open={this.state.showChooseShippingAddressModal}
          onSave={address => {
            this.setPurchaseOrderValue({
              shipping_name: address.name,
              shipping_address1: address.address1,
              shipping_address2: address.address2,
              shipping_zip: address.zip,
              shipping_city: address.city,
              shipping_country: address.country,
            });
            this.setState({
              showChooseShippingAddressModal: false,
              editingAddress: {},
            });
          }}
          onClose={() => {
            this.setState({ showChooseShippingAddressModal: false });
          }}
        />
        <EditAddressModal
          adress={this.state.editingAddress}
          title={
            <FormattedMessage id="screens.purchase-order.info.our-information.edit-billing-address-modal-title" />
          }
          open={this.state.showEditBillingAddressModal}
          onSave={address => {
            this.setPurchaseOrderValue({
              billing_name: address.name,
              billing_address1: address.address1,
              billing_address2: address.address2,
              billing_zip: address.zip,
              billing_city: address.city,
              billing_country: address.country,
            });
            this.setState({
              showEditBillingAddressModal: false,
              editingAddress: {},
            });
          }}
          onClose={() => {
            this.setState({
              showEditBillingAddressModal: false,
              editingAddress: {},
            });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    purchaseOrder: PurchaseOrderSelectors.getPurchaseOrder(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OurInformation)));
