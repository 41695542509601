export const Type = {
  Calendar: 'calendar',
};

export const ToolbarListType = {
  Manage: 'manage',
  Overdue: 'overdue',
  Requests: 'requests',
  AwaitingDelivery: 'awaitingDelivery',
};
