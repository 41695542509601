import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { EntitySelectors } from 'sdk/State/entities';
import TypeItem from './TypeItem';
import { Button, Menu, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetTypes extends Component {
  state = {
    showSearchField: false,
    searchValue: '',
    searchedIds: [],
    showAllItems: false,
    selectedType: null,
  };

  componentDidMount() {
    this.filterTypes();
  }
  onChange = value => {
    this.setState({ searchValue: value }, () => this.filterTypes());
  };

  filterTypes = () => {
    const searchedIds = this.props.assetTypes
      .filter(assetType => {
        return assetType.title.toUpperCase().includes(this.state.searchValue.toUpperCase());
      })
      .map(assetType => assetType.id);
    this.setState({ searchedIds });
  };

  renderEmptyDataSet = () => {
    return (
      <div className={styles['empty-title']}>
        <FormattedMessage id="components.select-asset-modal.menu.empty-search" />
      </div>
    );
  };

  renderItems = () => {
    if (this.state.searchValue !== '') {
      if (this.state.searchedIds.length === 0) {
        return this.renderEmptyDataSet();
      }
      return this.state.searchedIds.map(typeId => (
        <TypeItem
          id={typeId}
          selectedType={this.state.selectedType}
          activeItem={this.props.activeItem}
          onClick={() => {
            this.props.onItemClick(typeId);
            this.setState({ selectedType: typeId });
          }}
        />
      ));
    }
    let idsToRender;
    if (this.state.showAllItems) {
      idsToRender = this.props.ids;
    } else {
      idsToRender = this.props.ids.slice(0, 10);
    }
    return (
      <>
        {idsToRender.map(typeId => (
          <TypeItem
            id={typeId}
            selectedType={this.state.selectedType}
            activeItem={this.props.activeItem}
            onClick={() => {
              this.props.onItemClick(typeId);
              this.setState({ selectedType: typeId });
            }}
          />
        ))}
        {!this.state.showAllItems && this.props.assetTypes.length > 10 ? (
          <Menu.Item
            blueText
            title={<FormattedMessage id="general.show-more" />}
            onClick={() => {
              this.setState({ showAllItems: true });
            }}
          />
        ) : null}
      </>
    );
  };

  renderSearchField = () => {
    let classNames = [styles['search-container']];
    if (this.state.isFocused) classNames = [...classNames, styles['focused']];
    if (this.state.searchValue !== '') classNames = [...classNames, styles['has-value']];

    return (
      <div className={styles['border-positioner']}>
        <AnimateHeight duration={250} height={this.state.showSearchField ? 'auto' : 0}>
          <div className={classNames.join(' ')}>
            <div className={styles['input-container']}>
              <div className={styles['input-logo']}>
                <Icon regular type="search" />
              </div>
              <input
                ref={ref => (this.inputRef = ref)}
                className={styles['input']}
                placeholder={this.props.intl.formatMessage({
                  id: 'general.search-placeholder',
                })}
                autoComplete="off"
                onFocus={() => this.setState({ isFocused: true })}
                onBlur={() => {
                  this.setState({ isFocused: false });
                }}
                value={this.state.searchValue}
                name="search"
                onChange={e => this.onChange(e.target.value)}
              />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles['search-header']}>
          <p className={styles['search-title']}>
            <FormattedMessage id="components.select-asset-modal.menu.type-title" />
          </p>
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={() => {
              this.setState({ showSearchField: !this.state.showSearchField, searchValue: '' }, () => {
                if (this.state.showSearchField) this.inputRef.focus();
              });
            }}
          />
        </div>
        {this.renderSearchField()}
        <div className={styles['item-container']}>{this.renderItems()}</div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTypes: EntitySelectors.getAssetTypes(state, ownProps.ids),
  };
}

export default injectIntl(connect(mapStateToProps)(AssetTypes));
