import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { CardGrid } from 'views/components/Shared/Layout';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import styles from './style.module.scss';

class DescriptionContainer extends Component {
  constructor(props) {
    super(props);
    const { description, title } = props.editingOperationalMaintenanceTemplateVersion;
    this.state = {
      description,
      title,
    };
  }

  render() {
    let className = [styles['white-card']];
    let titleClassNames = [styles['title']];
    if (this.props.errors.title === true) {
      titleClassNames = [...titleClassNames, styles['has-error']];
    }
    return (
      <CardGrid.Row>
        <CardGrid.Column>
          <div className={className.join(' ')}>
            <div>
              <input
                autoFocus
                className={titleClassNames.join(' ')}
                placeholder={this.props.intl.formatMessage({
                  id: 'components.edit-operational-maintenance-template.add-title',
                })}
                value={this.state.title}
                onChange={e => {
                  this.setState({ title: e.target.value });
                  if (this.props.errors.title && e.target.value) {
                    this.props.removeErrors('title');
                  }
                }}
                onBlur={() =>
                  this.props.setEditingOperationalMaintenanceTemplateVersionValue({
                    title: this.state.title,
                  })
                }
              />
            </div>
            <div className={styles['separator']} />
            <TextareaAutosize
              minRows={3}
              className={styles['description']}
              placeholder={this.props.intl.formatMessage({
                id: 'components.edit-operational-maintenance-template.add-description',
              })}
              value={this.state.description}
              onChange={e => {
                this.setState({ description: e.target.value });
              }}
              onBlur={() =>
                this.props.setEditingOperationalMaintenanceTemplateVersionValue({
                  description: this.state.description,
                })
              }
            />
          </div>
        </CardGrid.Column>
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
    errors: EditOperationalMaintenanceTemplateSelectors.getErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
      removeErrors: EditOperationalMaintenanceTemplateOperations.removeErrors,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DescriptionContainer));
