import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, FieldErrorWrapper } from 'views/components/Shared/General';
import { TreePath, ChooseAssetInlineModal } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';

const AssetField = ({ asset, value, onChange }) => (
  <Field view={false} label={<FormattedMessage id="resources.asset.resource" />}>
    <ChooseAssetInlineModal
      width="300"
      clearable={false}
      trigger={
        <FieldErrorWrapper position="top">
          <Field.Resource
            clearable={false}
            angleDown
            value={
              asset == null ? null : (
                <React.Fragment>
                  {asset.title}
                  {asset.tree_path.length === 0 ? null : <TreePath assetId={asset.id} />}
                </React.Fragment>
              )
            }
          />
        </FieldErrorWrapper>
      }
      selectedAssetId={value}
      onSelectAsset={assetId => onChange(assetId)}
    />
  </Field>
);

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(AssetField);
