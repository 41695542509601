import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Button, NewInlineModal, Icon, Datapill } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { UserNameWrapper } from 'views/components/User';

class SparePartWithdrawalListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  renderSubtitle = () => {
    if (this.props.sparePart) {
      return (
        <>
          <span>#</span>
          <span>{this.props.sparePart.article_number}</span>
        </>
      );
    }
    return null;
  };

  renderDropDownItems = () => {
    let dropDownItems = [];
    if (this.props.workOrder) {
      dropDownItems.push(
        <Link to={`/work-orders/${this.props.workOrder.id}`}>
          <NewInlineModal.Dropdown.Item>
            <FormattedMessage id={'general.navigate-to.work-order'} />
          </NewInlineModal.Dropdown.Item>
        </Link>
      );
    }
    if (this.props.sparePart) {
      dropDownItems.push(
        <Link to={`/spare-parts/${this.props.sparePart.id}`}>
          <NewInlineModal.Dropdown.Item>
            <FormattedMessage id={'general.navigate-to.spare-part'} />
          </NewInlineModal.Dropdown.Item>
        </Link>
      );
    }
    if (dropDownItems.length > 0) {
      dropDownItems.push(<NewInlineModal.Dropdown.Separator />);
    }
    dropDownItems.push(
      <NewInlineModal.Dropdown.Item
        destructive
        onClick={() => {
          this.setState({ dropdownOpen: false });
          this.props.onDelete(this.props.id);
        }}
      >
        <FormattedMessage id="general.delete" />
      </NewInlineModal.Dropdown.Item>
    );
    return dropDownItems;
  };

  renderIconButtons = () => {
    if (this.props.canCreateWithdrawals) {
      return (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            minWidth={250}
            position="right"
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.dropdownOpen}
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>{this.renderDropDownItems()}</NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.sparePart) {
      return this.props.sparePart.title;
    }
    return this.props.sparePartWithdrawal.title;
  };

  renderQuantityDatapill = () => {
    return (
      <Datapill
        value={
          <span>
            {this.props.sparePartUnit ? null : (
              <span>
                <FormattedMessage id="resources.spare-part-withdrawal.quantity" />:{' '}
              </span>
            )}
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePartWithdrawal.quantity}
            />
          </span>
        }
      />
    );
  };

  renderWithDrawalType = () => {
    if (this.props.workOrder) {
      return (
        <Datapill
          value={
            <span>
              <FormattedMessage id="resources.work-order.resource" />
              <span>: #</span>
              <span>{this.props.workOrder.number}</span>
              <span> - </span>
              <span>{this.props.workOrder.title}</span>
            </span>
          }
        />
      );
    }
    if (this.props.operationalMaintenanceTemplateVersion) {
      return (
        <Datapill
          small
          value={
            <span>
              <FormattedMessage id="resources.operational-maintenance.resource" />
              {': '}
              {this.props.operationalMaintenanceTemplateVersion.title}
            </span>
          }
        />
      );
    }
  };

  renderDataPills = () => {
    return (
      <Datapill.List>
        {this.renderQuantityDatapill()}
        {this.renderWithDrawalType()}
      </Datapill.List>
    );
  };

  renderLoading = () => {
    return (
      <List.Item checkbox checkboxDisabled>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  };

  renderCreatedInfoColumn = () => {
    return (
      <List.Item.Column>
        <span>
          {' '}
          <FormattedMessage
            id="general.date-by-user"
            values={{
              user: <UserNameWrapper user={this.props.createdByUser} />,
              date: moment(this.props.sparePartWithdrawal.created_at).format('LLL'),
            }}
          />{' '}
        </span>
      </List.Item.Column>
    );
  };

  renderSparePartInfoColumn = () => {
    return <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />;
  };

  renderListItemColumns = () => {
    return (
      <>
        {this.renderSparePartInfoColumn()}
        {this.renderCreatedInfoColumn()}
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoading();
    }
    return (
      <List.Item
        small
        clickable
        dataPillsComponent={this.renderDataPills()}
        onClick={() => this.props.onClick()}
        iconButtons={this.renderIconButtons()}
        onCheck={this.props.onCheck}
        checkbox={this.props.checkbox}
        checked={this.props.checked}
        checkboxDisabled={this.props.checkboxDisabled}
      >
        {this.renderListItemColumns()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
  let operationalMaintenanceTemplateVersion = null;
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    sparePartWithdrawal.operational_maintenance_instance_id
  );
  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );
  }
  let sparePartUnit = null;
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  } else {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }
  return {
    sparePartWithdrawal,
    sparePart,
    sparePartUnit,
    workOrder: EntitySelectors.getWorkOrder(state, sparePartWithdrawal.work_order_id),
    operationalMaintenanceTemplateVersion,
    createdByUser: EntitySelectors.getUser(state, sparePartWithdrawal.created_by_user_id),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps)(SparePartWithdrawalListItem);
