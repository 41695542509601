import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Type } from 'sdk/ChecklistTemplateRow';
import SectionContainer from './SectionContainer';
import { ChecklistTemplateRow } from 'views/components/Checklist';

class Row extends Component {
  render() {
    const instanceRowId = this.props.instanceRowForTemplateRowIds[this.props.id];
    switch (this.props.checklistTemplateRow.type) {
      case Type.Inspection:
        return (
          <ChecklistTemplateRow.Inspection
            id={this.props.id}
            instanceRowId={instanceRowId}
            editable={false}
          />
        );
      case Type.Meter:
        return (
          <ChecklistTemplateRow.Meter id={this.props.id} instanceRowId={instanceRowId} editable={false} />
        );
      case Type.Section:
        return (
          <SectionContainer
            editable={false}
            id={this.props.id}
            childRowIds={this.props.childRowIds}
            instanceRowForTemplateRowIds={this.props.instanceRowForTemplateRowIds}
            prevTemplateRowId={this.props.prevTemplateRowId}
            nextTemplateRowId={this.props.nextTemplateRowId}
          />
        );
      default:
        return null;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(Row);
