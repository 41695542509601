export default {
  RESET_SELECTED_COSTS: 'costs/RESET_SELECTED_COSTS',
  SELECT_PAGE: 'costs/select_page',
  SELECT_COST: 'costs/select_vendor',
  SELECT_TOTAL_ENTRIES: 'costs/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'costs/hide_select_total_entries',
  FETCH_COSTS: 'costs/fetch_spare_part_withdrawals',
  FETCH_COSTS_SUCCESS: 'costs/fetch_spare_part_withdrawals_success',
  ADD_QUERY_PARAMETER: 'costs/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'costs/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'costs/show_available_filters',
  SELECT_FILTER_TYPE: 'costs/select_filter_type',
  ADD_FILTER: 'costs/add_filter',
  REMOVE_FILTER: 'costs/remove_filter',
  RESET_FILTER: 'costs/reset_filter',
};
