import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ChecklistTemplateSelectors, ChecklistTemplateOperations } from 'state/ducks/checklistTemplate';
import { EntitySelectors } from 'sdk/State/entities';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { ContentContainer } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { ChecklistVersionForm, ChecklistEditModal } from 'views/components/Checklist';
import InfoText from './InfoText';

class ChecklistFormContainer extends Component {
  state = {
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
  };

  renderInfoText = () => {
    const { checklistTemplateVersion, checklistTemplate } = this.props;
    if (checklistTemplateVersion.status === Status.Archived) {
      return (
        <InfoText
          templateVersionId={checklistTemplateVersion.id}
          onEditDraftTemplateVersion={this.props.onEditDraftTemplateVersion}
        />
      );
    }
    if (checklistTemplate.draft_version && checklistTemplateVersion.status !== Status.Draft) {
      return (
        <InfoText
          templateVersionId={checklistTemplate.draft_version}
          onEditDraftTemplateVersion={this.props.onEditDraftTemplateVersion}
        />
      );
    }

    return null;
  };

  renderContent = () => {
    if (this.props.isFetchingVersion) {
      return (
        <List>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    return (
      <>
        {this.renderInfoText()}
        <ChecklistVersionForm id={this.props.checklistTemplateVersion.id} />
      </>
    );
  };

  render() {
    return (
      <>
        <ContentContainer>{this.renderContent()}</ContentContainer>
        <ChecklistEditModal
          open={this.state.showChecklistEditModal}
          id={this.state.showChecklistEditModalForVersionId}
          onClose={() => {
            this.setState({ showChecklistEditModal: false, showChecklistEditModalForVersionId: null });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChecklistTemplateVersion: ChecklistTemplateOperations.fetchChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateId = ownProps.match.params.id;
  const checklistTemplate = EntitySelectors.getChecklistTemplate(state, checklistTemplateId);
  return {
    isFetchingVersion: ChecklistTemplateSelectors.isFetchingVersion(state),
    checklistTemplate,
    checklistTemplateVersion: ChecklistTemplateSelectors.getTemplateVersion(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistFormContainer));
