const getSparePart = state => {
  const { sparePart, entities } = state;
  if (!sparePart.id) return null;
  return entities.sparePartById[sparePart.id];
};

const getAttachments = state => {
  const { entities, sparePart } = state;
  return sparePart.attachments.ids.map(id => entities.attachmentById[id]);
};

const getAttachmentsCreatedIds = state => state.sparePart.attachments.createdIds;
const getAttachmentsPagination = state => state.sparePart.attachments.pagination;

const getSparePartAssetIds = state => {
  const { sparePart } = state;
  return sparePart.sparePartAssets.ids;
};

const getSparePartAssetsPagination = state => state.sparePart.sparePartAssets.pagination;

const getVendorIds = state => {
  const { sparePart } = state;
  return sparePart.vendors.ids;
};

const getPrimaryVendorId = state => {
  const { sparePart } = state;
  return sparePart.vendors.primaryVendorId;
};

const getSparePartVendorIdsForVendor = (state, vendorId) => {
  const { sparePart } = state;
  return sparePart.vendors.sparePartVendorIdsByVendorId[vendorId];
};

const getVendorsPagination = state => state.sparePart.vendors.pagination;

const getPurchaseOrders = state => {
  const { entities, sparePart } = state;
  return sparePart.purchaseOrders.ids.map(id => entities.purchaseOrderById[id]);
};

const getPurchaseOrdersPagination = state => state.sparePart.purchaseOrders.pagination;

const getSparePartWithdrawals = state => {
  const { entities, sparePart } = state;
  return sparePart.withdrawals.ids.map(id => entities.sparePartWithdrawalById[id]);
};

const spareParePartWithdrawalPagination = state => state.sparePart.withdrawals.pagination;

export default {
  getSparePart,
  getSparePartVendorIdsForVendor,
  getAttachments,
  getAttachmentsCreatedIds,
  getAttachmentsPagination,
  getSparePartAssetIds,
  getSparePartAssetsPagination,
  getVendorIds,
  getVendorsPagination,
  getPurchaseOrders,
  getPurchaseOrdersPagination,
  getPrimaryVendorId,
  getSparePartWithdrawals,
  spareParePartWithdrawalPagination,
};
