import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Route, Switch, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import AnimateHeight from 'react-animate-height';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, UpgradeToProIcon, Toggle, ConfirmModal, WhiteCard } from 'views/components/Shared/General';
import { Section, Option, ManageTemplateFieldsModal } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import Types from './Types';
import AttachmentFolders from './AttachmentFolders';
import DowntimeReasons from './DowntimeReasons';
import styles from './style.module.scss';

class Asset extends Component {
  state = {
    showActivateDowntimeModal: false,
    isSavingActivateDowntimeModal: false,
    showDeactivateDowntimeModal: false,
    isSavingDeactivateDowntimeModal: false,
    showActivateAssetNumberModal: false,
    isSavingActivateAssetNumberModal: false,
    showDeactivateAssetNumberModal: false,
    isSavingDeactivateAssetNumberModal: false,
    showManageTemplateFieldsModal: false,
    removeDowntimeCategoriesFromDom: false,
  };

  updateSettings = params => {
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          showActivateDowntimeModal: false,
          showDeactivateDowntimeModal: false,
          showActivateAssetNumberModal: false,
          showDeactivateAssetNumberModal: false,
          isSavingDeactivateAssetNumberModal: false,
          isSavingActivateAssetNumberModal: false,
          isSavingDeactivateDowntimeModal: false,
          isSavingActivateDowntimeModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          showActivateDowntimeModal: false,
          showDeactivateDowntimeModal: false,
          showActivateAssetNumberModal: false,
          showDeactivateAssetNumberModal: false,
          isSavingDeactivateAssetNumberModal: false,
          isSavingActivateAssetNumberModal: false,
          isSavingDeactivateDowntimeModal: false,
          isSavingActivateDowntimeModal: false,
        });
      });
  };

  renderActivateDowntimeModal = () => (
    <ConfirmModal
      open={this.state.showActivateDowntimeModal}
      title={<FormattedMessage id="screens.settings.assets.downtime.activate-modal.title" />}
      message={<FormattedMessage id="screens.settings.assets.downtime.activate-modal.description" />}
      confirmButtonText="screens.settings.assets.downtime.activate-modal.button"
      confirmIsLoading={this.state.isSavingActivateDowntimeModal}
      onConfirm={() => {
        if (this.state.isSavingActivateDowntimeModal) {
          return;
        }
        this.setState({
          removeDowntimeCategoriesFromDom: false,
          isSavingActivateDowntimeModal: true,
        });
        this.updateSettings({ downtime_activated: true });
      }}
      onCancel={() => {
        this.setState({ showActivateDowntimeModal: false });
      }}
    />
  );

  renderDeactivateConfirmModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeactivateDowntimeModal}
      title={<FormattedMessage id="screens.settings.assets.downtime.deactivate-modal.title" />}
      message={<FormattedMessage id="screens.settings.assets.downtime.deactivate-modal.description" />}
      confirmButtonText="screens.settings.assets.downtime.deactivate-modal.button"
      confirmIsLoading={this.state.isSavingDeactivateDowntimeModal}
      onConfirm={() => {
        if (this.state.isSavingDeactivateDowntimeModal) {
          return;
        }
        this.setState({ isSavingDeactivateDowntimeModal: true });
        this.updateSettings({ downtime_activated: false });
      }}
      onCancel={() => {
        this.setState({ showDeactivateDowntimeModal: false });
      }}
    />
  );

  renderActivateAssetNumberModal = () => (
    <ConfirmModal
      open={this.state.showActivateAssetNumberModal}
      title={<FormattedMessage id="screens.settings.assets.asset-number.activate-modal-title" />}
      message={<FormattedMessage id="screens.settings.assets.asset-number.subtitle" />}
      confirmButtonText="general.activate"
      confirmIsLoading={this.state.isSavingActivateAssetNumberModal}
      onConfirm={() => {
        if (this.state.isSavingActivateAssetNumberModal) {
          return;
        }
        this.setState({ isSavingActivateAssetNumberModal: true });
        this.updateSettings({ asset_number_activated: true });
      }}
      onCancel={() => {
        this.setState({ showActivateAssetNumberModal: false });
      }}
    />
  );

  renderDeactivateAssetNumberModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeactivateAssetNumberModal}
      title={<FormattedMessage id="screens.settings.assets.asset-number.deactivate-modal-title" />}
      message={<FormattedMessage id="screens.settings.assets.asset-number.subtitle" />}
      confirmButtonText="general.deactivate"
      confirmIsLoading={this.state.isSavingDeactivateAssetNumberModal}
      onConfirm={() => {
        if (this.state.isSavingDeactivateAssetNumberModal) {
          return;
        }
        this.setState({ isSavingDeactivateAssetNumberModal: true });
        this.updateSettings({ asset_number_activated: false });
      }}
      onCancel={() => {
        this.setState({ showDeactivateAssetNumberModal: false });
      }}
    />
  );

  renderManageTemplateFieldsModal = () => (
    <ManageTemplateFieldsModal
      open={this.state.showManageTemplateFieldsModal}
      onClose={() => this.setState({ showManageTemplateFieldsModal: false })}
      templateType="asset"
    />
  );

  renderDowntimeCategories = () => {
    if (this.state.removeDowntimeCategoriesFromDom === true) {
      return null;
    }
    return (
      <AnimateHeight
        duration={150}
        height={this.props.settings.downtime_activated ? 'auto' : 0}
        onAnimationEnd={() => {
          if (this.props.settings.downtime_activated === false) {
            this.setState({ removeDowntimeCategoriesFromDom: true });
          }
        }}
      >
        <Option
          title={<FormattedMessage id="screens.settings.assets.downtime-reasons.title" />}
          subtitle={<FormattedMessage id="screens.settings.assets.downtime-reasons.subtitle" />}
          rightContainer={
            this.props.hasProTier === false ? (
              <div className={styles['pro-badge-container']}>
                <div className={styles['badge']}>
                  <UpgradeToProIcon />
                </div>
                <Toggle disabled checked={false} />
              </div>
            ) : (
              <Link to="/settings/assets/downtime-reasons">
                <Button primary label="general.manage" />
              </Link>
            )
          }
        />
      </AnimateHeight>
    );
  };

  renderRootSettings = () => (
    <>
      <Section
        header
        title={<FormattedMessage id="screens.settings.assets.title" />}
        subtitle={<FormattedMessage id="screens.settings.assets.subtitle" />}
      />
      <div className={styles['options']}>
        <Option.Container>
          <Option
            title={<FormattedMessage id="screens.settings.assets.types.title" />}
            subtitle={<FormattedMessage id="screens.settings.assets.types.subtitle" />}
            rightContainer={
              <Link to="/settings/assets/types">
                <Button primary label="general.manage" />
              </Link>
            }
          />
          <Option
            title={<FormattedMessage id="screens.settings.assets.template-fields.title" />}
            subtitle={<FormattedMessage id="screens.settings.assets.template-fields.subtitle" />}
            rightContainer={
              <Button
                primary
                label="general.manage"
                onClick={() => this.setState({ showManageTemplateFieldsModal: true })}
              />
            }
          />
          <Option
            title={<FormattedMessage id="screens.settings.assets.attachment-folders.title" />}
            subtitle={<FormattedMessage id="screens.settings.assets.attachment-folders.subtitle" />}
            rightContainer={
              this.props.hasProTier === false ? (
                <div className={styles['pro-badge-container']}>
                  <div className={styles['badge']}>
                    <UpgradeToProIcon />
                  </div>
                  <Button disabled primary label="general.manage" />
                </div>
              ) : (
                <Link to="/settings/assets/attachment-folders">
                  <Button primary label="general.manage" />
                </Link>
              )
            }
          />
          <Option
            title={<FormattedMessage id="screens.settings.assets.downtime.title" />}
            subtitle={<FormattedMessage id="screens.settings.assets.downtime.subtitle" />}
            rightContainer={
              this.props.hasProTier === false ? (
                <div className={styles['pro-badge-container']}>
                  <div className={styles['badge']}>
                    <UpgradeToProIcon />
                  </div>
                  <Toggle disabled checked={false} />
                </div>
              ) : (
                <Toggle
                  checked={this.props.settings.downtime_activated}
                  onClick={() => {
                    if (this.props.settings.downtime_activated) {
                      this.setState({ showDeactivateDowntimeModal: true });
                    } else {
                      this.setState({ showActivateDowntimeModal: true });
                    }
                  }}
                />
              )
            }
          />

          {this.renderDowntimeCategories()}
          <Option
            title={<FormattedMessage id="screens.settings.assets.asset-number.title" />}
            subtitle={<FormattedMessage id="screens.settings.assets.asset-number.subtitle" />}
            rightContainer={
              <Toggle
                checked={this.props.settings.asset_number_activated}
                onClick={() => {
                  if (this.props.settings.asset_number_activated) {
                    this.setState({ showDeactivateAssetNumberModal: true });
                  } else {
                    this.setState({ showActivateAssetNumberModal: true });
                  }
                }}
              />
            }
          />
        </Option.Container>
      </div>
    </>
  );

  render() {
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/assets" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/assets/types" component={Types} />
          <Route exact path="/settings/assets/downtime-reasons" component={DowntimeReasons} />
          {this.props.hasProTier === false ? null : (
            <Route exact path="/settings/assets/attachment-folders" component={AttachmentFolders} />
          )}
        </Switch>
        {this.renderActivateDowntimeModal()}
        {this.renderDeactivateConfirmModal()}
        {this.renderActivateAssetNumberModal()}
        {this.renderDeactivateAssetNumberModal()}
        {this.renderManageTemplateFieldsModal()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Asset));
