import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { PurchaseOrdersSelectors, PurchaseOrdersOperations } from 'state/ducks/purchaseOrders';
import Filter from './Filter';
import { FILTER_TYPES } from '../';

class AppliedFilters extends Component {
  renderAppliedFilters = () => {
    return this.props.filters.map(key => {
      const keys = key.split('.');
      switch (keys[0]) {
        case FILTER_TYPES.Vendor: {
          return <Filter.Vendor />;
        }
        case FILTER_TYPES.Late: {
          return <Filter.Late />;
        }
        case FILTER_TYPES.Status: {
          return <Filter.Status />;
        }
        case FILTER_TYPES.SparePartLocation: {
          return <Filter.SparePartLocation />;
        }
        default:
          return null;
      }
    });
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          noPaddingBottom
          title={<FormattedMessage id="screens.purchase-orders.filters.advanced.title" />}
          onClose={() => this.props.onClose()}
        />
        <SideModal.Container.Filter>
          <SideModal.Container.Filter.Detail>
            <SideModal.Container.Filter.AppliedFilters onAddFilter={() => this.props.showAvailableFilters()}>
              {this.renderAppliedFilters()}
            </SideModal.Container.Filter.AppliedFilters>
          </SideModal.Container.Filter.Detail>
        </SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAvailableFilters: PurchaseOrdersOperations.showAvailableFilters,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filters: PurchaseOrdersSelectors.getAppliedFilters(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFilters);
