import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { ErrorOperations } from 'state/ducks/error';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { FormattedMessage } from 'react-intl';
import { Icon, Button, FormattedNumber } from 'views/components/Shared/General';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { EntitySelectors } from 'sdk/State/entities';
import { buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import {
  WorkOrderRecurringDropdown,
  WorkOrderCustomInterval,
  RecurringMeterModal,
  Field,
} from 'views/components/WorkOrder';
import styles from '../style.module.scss';

class Recurring extends Component {
  state = {
    showCustomIntervalModal: false,
    showRecurringMeterModal: false,
  };

  /*
    Helper functions
  */

  saveRecurringInformation = intervalData => {
    let clientErrorsToRemove = ['frequency'];
    if (intervalData.start_date) {
      const startDateIsBeforeToday = moment(intervalData.start_date).isBefore(
        moment().format('YYYY-MM-DD'),
        'day'
      );
      if (!startDateIsBeforeToday) {
        clientErrorsToRemove = [...clientErrorsToRemove, 'date'];
      }
    }
    const { start_date, ...params } = intervalData;
    this.props.setEditingWorkOrderValues({ ...params, date: intervalData.start_date });
    this.props.removeFieldsFromErrors(clientErrorsToRemove);
  };

  clearDateInterval = () => {
    this.props.setEditingWorkOrderValues({
      frequency: null,
      interval: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
      month_type: null,
      generate_from_completed_date: false,
    });
  };

  clearMeterInterval = () => {
    this.props.setEditingWorkOrderValues({
      meter_interval: null,
    });
  };

  isEditingSingleWorkOrderThatIsRecurring = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    return this.props.editingRecurringMaintenance && recurringMaintenanceId == null;
  };

  /*
    Render functions
  */

  renderRecurringMessage = () => {
    if (this.props.editingWorkOrder.frequency == null) {
      return '-';
    }
    return buildIntervalMessage(
      buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder),
      this.props.intl
    );
  };

  renderRecurringDropdown = () => {
    return (
      <WorkOrderRecurringDropdown
        editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
        onSave={this.saveRecurringInformation}
        onClear={this.clearDateInterval}
        onShowCustomOptions={() => this.setState({ showCustomIntervalModal: true })}
        trigger={
          <div className={styles['edit-row']}>
            <ConnectedFieldErrorWrapper field="frequency">
              {() => (
                <>
                  <Field
                    icon={<Icon regular type="repeat" withBackground backgroundSize={36} />}
                    label={<FormattedMessage id="resources.work-order.recurring" />}
                    value={this.renderRecurringMessage()}
                  />
                </>
              )}
            </ConnectedFieldErrorWrapper>
            {this.props.editingWorkOrder.frequency == null ? null : (
              <div className={styles['clear-container']}>
                <Button
                  type="icon"
                  iconButtonSize={24}
                  icon={<Icon light size={16} type="times" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.clearDateInterval();
                  }}
                />
              </div>
            )}
          </div>
        }
      />
    );
  };

  renderRecurringMeterMessage = () => {
    if (this.props.editingWorkOrder.meter_interval) {
      return (
        <FormattedMessage
          id="resources.work-order.meter-interval"
          values={{
            value: (
              <FormattedNumber
                value={this.props.editingWorkOrder.meter_interval}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            ),
          }}
        />
      );
    }
    return <span>-</span>;
  };

  renderRecurringMeter = () => {
    return (
      <div>
        <div className={styles['edit-row']} onClick={() => this.setState({ showRecurringMeterModal: true })}>
          <ConnectedFieldErrorWrapper field="frequency">
            {() => (
              <>
                <Field
                  icon={<Icon regular type="repeat" withBackground backgroundSize={36} />}
                  label={<FormattedMessage id="resources.work-order.recurring" />}
                  value={this.renderRecurringMeterMessage()}
                />
              </>
            )}
          </ConnectedFieldErrorWrapper>
          {this.props.editingWorkOrder.meter_interval == null ? null : (
            <div className={styles['clear-container']}>
              <Button
                type="icon"
                iconButtonSize={24}
                icon={<Icon light size={16} type="times" />}
                onClick={e => {
                  e.stopPropagation();
                  this.clearMeterInterval();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  renderContent = () => {
    if (this.props.meter) {
      return this.renderRecurringMeter();
    }
    return this.renderRecurringDropdown();
  };

  render() {
    if (this.isEditingSingleWorkOrderThatIsRecurring()) {
      return null;
    }
    return (
      <>
        {this.renderContent()}
        <WorkOrderCustomInterval
          open={this.state.showCustomIntervalModal}
          editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
          onSave={this.saveRecurringInformation}
          onClose={() => this.setState({ showCustomIntervalModal: false })}
        />
        <RecurringMeterModal
          editingWorkOrder={this.props.editingWorkOrder}
          open={this.state.showRecurringMeterModal}
          onSave={params => {
            this.props.removeFieldsFromErrors(['frequency']);
            this.props.setEditingWorkOrderValues(params);
            this.setState({ showRecurringMeterModal: false });
          }}
          onClose={() => this.setState({ showRecurringMeterModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const editingWorkOrder = WorkOrderSelectors.getEditingWorkOrder(state);
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    editingWorkOrder,
    editingRecurringMaintenance: WorkOrderSelectors.getEditingWorkOrderRecurringMaintenance(state),
    meter,
    meterUnit,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Recurring)));
