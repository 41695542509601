import React, { Component } from 'react';
import { FullScreenImagePicker, List } from 'views/components/Shared/General';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Image, Loader } from 'views/components/Image';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WorkOrderEditSelectors } from 'state/ducks/workOrderEdit';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import { SDKReduxOperations } from 'sdk';
import { withRouter } from 'react-router';

class ItemTabBar extends Component {
  state = {
    currentImage: 0,
    showingImageDialog: false,
    deletingImageIds: {},
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.delete-image-success" />} />);
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  render = () => {
    return (
      <>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.registrationImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <List.Item>
          <div className={styles['images']}>
            {Array(this.props.imageLoaderCount)
              .fill()
              .map(() => (
                <Loader small />
              ))}
            {this.props.registrationImages.map((image, index) => {
              return (
                <Image
                  key={image.id}
                  small
                  circle
                  isDeleting={this.state.deletingImageIds[image.id] != null}
                  editable
                  image={image}
                  onClick={() => {
                    this.selectImage(index);
                  }}
                  onDelete={() => {
                    this.deleteImage(image);
                  }}
                />
              );
            })}
          </div>
        </List.Item>
      </>
    );
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderId = ownProps.match.params.id;

  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      imageLoaderCount: WorkOrderEditSelectors.getImageLoaderCount(state),
      registrationImages: EntitySelectors.getImages(state, recurringMaintenance.images),
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      imageLoaderCount: WorkOrderEditSelectors.getImageLoaderCount(state),
      registrationImages: EntitySelectors.getImages(state, workOrder.registration_images),
    };
  }
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ItemTabBar)));
