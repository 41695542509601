import actions from './actions';
import { normalizeUser, normalizePermissionProfile } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';

const {
  addQueryParameter,
  selectUser,
  resetSelectedUsers,
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
} = actions;

const fetchUsers = (systemId, params, config) => dispatch => {
  return API.listUsers(systemId, params, config)
    .then(res => {
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      const { totalEntries } = pagination;
      const { entities, result } = normalizeUser(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchUsersSuccess({
          ids: result,
          pagination,
          totalEntries,
        })
      );

      return res;
    })
    .catch(e => {
      throw e;
    });
};

const fetchAndSetAmountOfPayingUsers = systemId => dispatch => {
  return API.listUsers(systemId, { member: true, archived: false }).then(({ headers }) => {
    const { totalEntries } = HelperFunctions.getPaginationFromHeader(headers);
    dispatch(actions.setAmountOfPayingUsers(totalEntries));
  });
};

const fetchPermissionProfiles = (systemId, params) => dispatch => {
  return API.listPermissionProfiles(systemId, params)
    .then(res => {
      const { entities, result } = normalizePermissionProfile(res.data);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchPermissionProfilesSuccess({
          ids: result,
          totalEntries: res.data.length,
        })
      );
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

export default {
  fetchAndSetAmountOfPayingUsers,
  addQueryParameter,
  fetchUsers,
  fetchPermissionProfiles,
  selectUser,
  resetSelectedUsers,
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
};
