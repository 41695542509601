import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as sdkReducers from 'sdk/State';
import * as reducers from './ducks';
import { AuthTypes } from './ducks/auth';
import { includeCurrentUserInfo } from './middlewares';

const appReducer = combineReducers({ ...reducers, ...sdkReducers });

const rootReducer = (state, action) => {
  let oldState = { ...state };
  if (action.type === AuthTypes.UNAUTHENTICATE_USER) {
    state = undefined;
    const defaultState = appReducer(state, action);
    return {
      ...defaultState,
      auth: {
        ...defaultState.auth,
        groupIsAuthenticated: oldState.auth.groupIsAuthenticated,
      },
    };
  }
  return appReducer(state, action);
};

export default createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk, includeCurrentUserInfo),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
);
