import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import InfiniteScroll from 'react-infinite-scroller';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { withRouter } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { EntityOperations } from 'sdk/State/entities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { TreePath } from 'views/components/Asset';
import { normalizeAsset } from 'sdk/Schemas';
import { Menu, Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import BackButton from './BackButton';
import AssetMenuItem from './AssetMenuItem';
import AssetSearchTitle from './AssetSearchTitle';
import { MenuType } from 'views/scenes/SpareParts/SparePartList';
import styles from './style.module.scss';

const listSearchedAssetsRequest = SDKHelperFunctions.getCancelTokenForRequest();
const PAGE_SIZE_FOR_SEARCHED_ASSETS = 20;

class AssetMenu extends Component {
  animationDirection = 'forward';

  state = {
    isFetching: true,
    showSearchField: false,
    treeAssetIds: [],
    treeParentId: 'root',
    searchValue: '',
    isFocused: false,
    searchedAssetIds: [],
    canFetchMoreSearchedAssets: false,
    isFetchingMoreSearchedAssets: false,
    paginateFromToken: null,
    showMoreItemsDropdownOptions: false,
  };

  constructor(props) {
    super(props);
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.searchAssets();
    }, 300);
  }

  componentDidMount() {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    if (asset_with_tree_children_id) {
      this.fetchSavedAsset(asset_with_tree_children_id);
    } else {
      this.fetchTreeAssets();
    }
  }

  /*
    Requests
  */

  fetchTreeAssets = params => {
    params = {
      ...params,
      archived: false,
      no_pagination: true,
      sort: 'tree_sort',
    };

    if (this.state.treeParentId !== 'root') {
      params = {
        ...params,
        tree_parent_id: this.state.treeParentId,
      };
    } else {
      params = {
        ...params,
        tree_parent_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
    }

    API.listAssets(this.props.currentSystem.id, params).then(res => {
      const { data } = res;
      const { entities, result } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.setState({
        isFetching: false,
        isFetchingTree: false,
        treeAssetIds: result,
      });
    });
  };

  fetchSavedAsset = assetId => {
    API.fetchAsset(assetId).then(res => {
      const { data } = res;
      const { entities } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.setState({ treeParentId: data.tree_parent_id ? data.tree_parent_id : 'root' }, () => {
        this.fetchTreeAssets();
      });
    });
  };

  searchAssets = () => {
    listSearchedAssetsRequest.cancel();
    let params = {
      search: this.state.searchValue,
      archived: false,
      page_size: PAGE_SIZE_FOR_SEARCHED_ASSETS,
      sort: 'title',
    };
    API.listAssets(this.props.currentSystem.id, params, listSearchedAssetsRequest.getCancelTokenConfig())
      .then(res => {
        const { data } = res;
        const { entities, result: searchedAssetIds } = normalizeAsset(data);
        const { paginateFrom } = SDKHelperFunctions.getPaginationFromHeader(res.headers);
        this.props.updateEntities(entities);
        this.setState({
          isFetching: false,
          searchedAssetIds,
          paginateFromToken: paginateFrom,
          canFetchMoreSearchedAssets: searchedAssetIds.length >= PAGE_SIZE_FOR_SEARCHED_ASSETS,
        });
      })
      .catch(() => {});
  };

  fetchMoreSearchedAssets = () => {
    if (this.state.canFetchMoreSearchedAssets === false) {
      return;
    }
    if (this.state.isFetchingMoreSearchedAssets) {
      return;
    }
    this.setState({ isFetchingMoreSearchedAssets: true });
    let params = {
      search: this.state.searchValue,
      archived: false,
      page_size: PAGE_SIZE_FOR_SEARCHED_ASSETS,
      sort: 'title',
      paginate_from: this.state.paginateFromToken,
    };
    API.listAssets(this.props.currentSystem.id, params).then(res => {
      const { data } = res;
      const { entities, result: searchedAssetIds } = normalizeAsset(data);
      const { paginateFrom } = SDKHelperFunctions.getPaginationFromHeader(res.headers);
      this.props.updateEntities(entities);
      this.setState({
        searchedAssetIds: [...this.state.searchedAssetIds, ...searchedAssetIds],
        paginateFromToken: paginateFrom,
        canFetchMoreSearchedAssets: searchedAssetIds.length >= PAGE_SIZE_FOR_SEARCHED_ASSETS,
        isFetchingMoreSearchedAssets: false,
      });
    });
  };

  /*
    Helepr Functions
  */

  toggleSearch = () => {
    this.setState(
      prevState => ({
        showSearchField: !prevState.showSearchField,
        searchValue: '',
      }),
      () => {
        if (this.state.showSearchField) {
          this.inputRef.focus();
          this.setState({
            searchedAssetIds: [],
            canFetchMoreSearchedAssets: false,
            isFetchingMoreSearchedAssets: false,
            paginateFromToken: null,
          });
        }
      }
    );
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...obj,
      })}`
    );
  };

  /*
    Render functions
  */

  renderSearchField = () => {
    let classNames = [styles['search-container']];

    return (
      <div className={styles['border-positioner']}>
        <AnimateHeight duration={250} height={this.state.showSearchField ? 'auto' : 0}>
          <div className={classNames.join(' ')}>
            <div className={styles['input-container']}>
              <div className={styles['input-logo']}>
                <Icon regular type="search" />
              </div>
              <input
                ref={ref => (this.inputRef = ref)}
                className={styles['input']}
                placeholder={this.props.intl.formatMessage({
                  id: 'general.search-placeholder',
                })}
                autoComplete="off"
                onFocus={() => this.setState({ isFocused: true })}
                onBlur={() => {
                  this.setState({ isFocused: false });
                }}
                value={this.state.searchValue}
                name="search"
                onChange={e => {
                  this.setState({ isFetching: true, searchValue: e.target.value });
                  this.debouncedSearchValueWasChanged(e.target.value);
                }}
              />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  };

  renderSearchTitle = () => {
    if (this.state.showSearchField) {
      return <FormattedMessage id="screens.meters.left-panel.all-assets" />;
    } else if (this.state.treeParentId === 'root' && !this.state.showSearchField) {
      return <FormattedMessage id="resources.asset.resource" />;
    } else {
      return <AssetSearchTitle assetId={this.state.treeParentId} />;
    }
  };

  renderSearchHeaderDropdown = () => {
    return (
      <>
        <div
          className={styles['search-title']}
          onClick={() =>
            this.setState(prevState => ({
              showMoreItemsDropdownOptions: !prevState.showMoreItemsDropdownOptions,
            }))
          }
        >
          <div className={styles['title']}>{this.renderSearchTitle()}</div>
          <div className={styles['icon']}>
            <Icon size={12} solid type="caret-down" />
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.searchHeaderDropdownRef}
          open={this.state.showMoreItemsDropdownOptions}
          position="right"
          onClose={() => this.setState({ showMoreItemsDropdownOptions: false })}
          minWidth={200}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                selected
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.Asset);
                  localStorage.setItem('listMenu', MenuType.Asset);
                }}
              >
                <FormattedMessage id="resources.asset.resource" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartLocation);
                  localStorage.setItem('listMenu', MenuType.SparePartLocation);
                }}
              >
                <FormattedMessage id="resources.spare-part.location" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartType);
                  localStorage.setItem('listMenu', MenuType.SparePartType);
                }}
              >
                <FormattedMessage id="resources.spare-part.category" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderSearch() {
    return (
      <div className={styles['search-header-container']}>
        <div className={styles['search-header']} ref={ref => (this.searchHeaderDropdownRef = ref)}>
          {this.renderSearchHeaderDropdown()}
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={this.toggleSearch}
          />
        </div>
        {this.renderSearchField()}
      </div>
    );
  }

  renderBackButton = () => {
    if (this.state.treeParentId === 'root') return null;
    return (
      <BackButton
        onNavigate={assetId => {
          this.animationDirection = 'backward';
          this.setState({ isFetchingTree: true, treeParentId: assetId }, () => {
            this.fetchTreeAssets();
          });
        }}
        parentAssetId={this.state.treeParentId}
      />
    );
  };

  renderTreeItems = () => {
    if (this.state.isFetchingTree) {
      return (
        <>
          <Menu.Item loading />
          <Menu.Item loading />
        </>
      );
    }
    return this.state.treeAssetIds.map(id => {
      return (
        <AssetMenuItem
          subtitle={this.state.searchValue ? <TreePath assetId={id} /> : null}
          id={id}
          onClick={assetId => {
            this.animationDirection = 'forward';
            this.setState({ isFetchingTree: true, treeParentId: id }, () => {
              this.fetchTreeAssets();
            });
          }}
          onSelect={assetId => {
            this.changeQueryParams({ asset_with_tree_children_id: assetId, list: null });
          }}
        />
      );
    });
  };

  renderTree = () => {
    let classNames = [];
    if (this.animationDirection === 'forward') {
      classNames = [...classNames, styles['animate-forward']];
    } else {
      classNames = [...classNames, styles['animate-backward']];
    }

    return (
      <div className={classNames.join(' ')}>
        <SwitchTransition>
          <CSSTransition
            key={this.state.treeParentId}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            onEntering={() => this.scrollBarRef.updateScroll()}
            classNames={{
              enter: styles['fade-enter'],
              enterActive: styles['fade-enter-active'],
              exit: styles['fade-exit'],
              exitActive: styles['fade-exit-active'],
            }}
          >
            <div className={styles['items']}>
              {this.renderBackButton()}
              {this.renderTreeItems()}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    );
  };

  renderSearchedAssets = () => {
    if (this.state.searchValue.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="screens.spare-parts.left-panel.asset.search-text" />
        </span>
      );
    }

    if (this.state.searchedAssetIds.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </span>
      );
    }
    return (
      <InfiniteScroll
        loadMore={this.fetchMoreSearchedAssets}
        style={{
          flex: 1,
          overflow: 'hidden',
        }}
        hasMore={this.state.canFetchMoreSearchedAssets}
        useWindow={false}
        initialLoad={false}
        threshold={100}
      >
        <div className={styles['items']}>
          {this.state.searchedAssetIds.map(id => {
            return (
              <AssetMenuItem
                subtitle={<TreePath assetId={id} />}
                searchedAsset
                id={id}
                onSelect={assetId => {
                  this.changeQueryParams({ asset_with_tree_children_id: assetId, list: null });
                }}
              />
            );
          })}
          {this.state.isFetchingMoreSearchedAssets ? (
            <>
              <Menu.Item loading />
              <Menu.Item loading />
            </>
          ) : null}
        </div>
      </InfiniteScroll>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['items']}>
          <Menu.Item loading />
          <Menu.Item loading />
        </div>
      );
    }
    if (this.state.treeAssetIds.length === 0 && this.state.searchedAssetIds.length === 0) {
      return (
        <div className={styles['items']}>
          <span className={styles['search-text']}>
            <FormattedMessage id="screens.spare-parts.left-panel.asset.empty-data-set.title" />
          </span>
        </div>
      );
    }
    if (this.state.showSearchField) {
      return this.renderSearchedAssets();
    }
    return this.renderTree();
  };

  render() {
    return (
      <>
        {this.renderSearch()}
        <PerfectScrollbar
          ref={ref => {
            this.scrollBarRef = ref;
          }}
        >
          {this.renderContent()}
        </PerfectScrollbar>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetMenu)));
