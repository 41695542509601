/*
  --------------------
    DATA SELECTORS
  --------------------
*/
const getChecklistTemplateVersion = state => {
  const { checklistEditModal, entities } = state;
  return entities.checklistTemplateVersionById[checklistEditModal.id];
};

const getRootFields = state => {
  const { checklistEditModal, entities } = state;
  return checklistEditModal.rootFieldIds.map(id => {
    if (id === 'new') {
      return checklistEditModal.editingField;
    }
    return entities.checklistTemplateRowById[id];
  });
};

const getChildFieldsForField = (state, id) => {
  const { checklistEditModal, entities } = state;
  return checklistEditModal.childIds[id].map(id => {
    if (id === 'new') {
      return checklistEditModal.editingField;
    }
    return entities.checklistTemplateRowById[id];
  });
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const getDraggingChecklistTemplateRow = state => {
  const { checklistEditModal, entities } = state;
  if (checklistEditModal.draggingChecklistTemplateRowId == null) {
    return null;
  }
  return entities.checklistTemplateRowById[checklistEditModal.draggingChecklistTemplateRowId];
};

const getEditingChecklistTemplateRow = state => {
  const { checklistEditModal } = state;
  return checklistEditModal.editingField;
};

const getNewDraggingFieldType = state => {
  const { checklistEditModal } = state;
  return checklistEditModal.isDraggingNewFieldType;
};

const showSectionCantBeDroppedInModal = state => {
  const { checklistEditModal } = state;
  return checklistEditModal.showSectionCantBeDroppedInModal;
};

const saveAsReusable = state => state.checklistEditModal.saveAsReusable;
const isCreatingChecklistFromTemplatesLibrary = state =>
  state.checklistEditModal.isCreatingChecklistFromTemplatesLibrary;
const showEditTitleModal = state =>
  state.checklistEditModal.showEditTitleModal;

export default {
  isCreatingChecklistFromTemplatesLibrary,
  showSectionCantBeDroppedInModal,
  getChecklistTemplateVersion,
  getRootFields,
  getChildFieldsForField,
  getDraggingChecklistTemplateRow,
  getEditingChecklistTemplateRow,
  getNewDraggingFieldType,
  saveAsReusable,
};
