import React from 'react';
import { Field, DatePicker, Icon } from 'views/components/Shared/General';

export default props => {
  return (
    <Field.Date
      value={props.value}
      onChangeDate={date => {
        props.onChange(date);
      }}
      icon={<Icon regular type="calendar-alt" />}
      footerComponent={
        <DatePicker.Footer
          showClear={props.value != null}
          onClear={() => {
            props.onClear();
          }}
        />
      }
    />
  );
};
