import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeMeter, normalizeMeterReading } from 'sdk/Schemas';

const fetchMeter = id => dispatch => {
  dispatch(actions.fetchMeterForView(id));
  return API.fetchMeter(id).then(res => {
    const { entities, result } = normalizeMeter(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchMeterForViewSuccess(result));

    return res.data;
  });
};

const listMeterReadings = (id, params) => dispatch => {
  return API.listMeterReadings(id, params).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeMeterReading(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.listMeterReadingsSuccess({ ids: result, pagination }));
    return result;
  });
};

export default {
  fetchMeter,
  listMeterReadings,
};
