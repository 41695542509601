import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  paginateFrom: null,
  totalEntries: 0,
  isFullyLoaded: true,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_ATTACHMENTS_SUCCESS:
      const { ids, paginateFrom, isFullyLoaded, totalEntries, initialLoad } = action.payload;
      return {
        ...state,
        ids: initialLoad ? ids : [...state.ids, ...ids],
        paginateFrom,
        isFullyLoaded,
        totalEntries: initialLoad ? totalEntries : state.totalEntries,
      };
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(id => id != attachmentId),
        totalEntries: state.totalEntries - 1,
      };
    }
    default:
      return state;
  }
};
