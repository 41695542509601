import React, { Component } from 'react';
import * as moment from 'moment';
import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { API, SDKReduxOperations } from 'sdk';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { InlineModal, Button, Loader, Field, FormattedNumber } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';

class CancelRepeat extends Component {
  state = {
    isFetching: true,
    isChangingPage: false,
    workOrders: [],
    checkedWorkOrderIds: [],
    isArchivingRecurringMaintenance: false,
    pagination: null,
  };

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.fetchWorkOrders();
      this.debouncedPagination = debounce(page => {
        this.fetchWorkOrders({ page });
      }, 400);
    }
  }

  fetchWorkOrders = params => {
    API.listWorkOrders(this.props.currentSystem.id, {
      ...params,
      recurring_maintenance_id: this.props.recurringMaintenance.id,
      page_size: 5,
      status: `${WorkOrderStatus.NotStarted},${WorkOrderStatus.InProgress},${WorkOrderStatus.Paused}`,
    })
      .then(res => {
        const { headers } = res;
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        this.setState({ pagination, workOrders: res.data, isFetching: false, isChangingPage: false });
      })
      .catch(() => {});
  };

  selectPage = page => {
    this.setState({ currentPage: page, isChangingPage: true });
    this.debouncedPagination(page);
  };

  archiveRecurringMaintenance = () => {
    this.setState({ isArchivingRecurringMaintenance: true });
    this.props
      .archiveRecurringMaintenance(this.props.recurringMaintenance.id, {
        delete_work_order_ids: this.state.checkedWorkOrderIds.join(','),
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.asset.info.update-success" />} />);
        if (typeof this.props.onArchiveRecurringMaintenance === 'function') {
          this.props.onArchiveRecurringMaintenance(this.state.checkedWorkOrderIds);
        }
      })
      .catch(() => {
        this.setState({ isArchivingRecurringMaintenance: false });
      });
  };

  renderLoader = () => (
    <div className={styles['loader-container']}>
      <Loader tiny />
    </div>
  );

  renderWorkOrderStatus = workOrder => {
    if (workOrder.id === this.props.workOrder.id) {
      return (
        <span className={styles['current-work-order']}>
          <FormattedMessage id="screens.work-order.current-work-order" />
        </span>
      );
    }
    if (workOrder.status === WorkOrderStatus.InProgress) {
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.working-on-by-user"
          values={{ user: <UserNameWrapper user={this.props.inProgressByUser} /> }}
        />
      );
    }
    if (workOrder.status === WorkOrderStatus.Paused) {
      return (
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.paused-by-user"
          values={{ user: <UserNameWrapper user={this.props.pausedByUser} /> }}
        />
      );
    }
    return (
      <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.not-started" />
    );
  };

  renderWorkOrderDate = workOrder => {
    const { due_date, due_meter_value } = workOrder;
    const { meterUnit } = this.props;
    if (due_date) {
      return moment(workOrder.due_date).format('LL');
    }
    if (due_meter_value) {
      let meterValue = new Decimal(due_meter_value).toDecimalPlaces(2).toString();
      return <FormattedNumber value={meterValue} unit={meterUnit ? meterUnit.abbreviation : null} />;
    }
    return (
      <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.no-due-date" />
    );
  };

  renderItem = workOrder => (
    <InlineModal.ListItem
      key={workOrder.id}
      icon="trash-alt"
      iconThickness="regular"
      title={this.renderWorkOrderDate(workOrder)}
      subtitle={this.renderWorkOrderStatus(workOrder)}
      onClick={() =>
        this.setState(prevState => ({
          checkedWorkOrderIds:
            prevState.checkedWorkOrderIds.find(id => id === workOrder.id) == null
              ? [...prevState.checkedWorkOrderIds, workOrder.id]
              : prevState.checkedWorkOrderIds.filter(id => id !== workOrder.id),
        }))
      }
      rightContainer={
        <Field.Checkbox checked={this.state.checkedWorkOrderIds.find(id => id === workOrder.id) != null} />
      }
    />
  );

  renderTable = () => (
    <div
      className={`${styles['active-work-orders-container']} ${
        this.state.pagination.totalPages > 1 ? styles['has-pagination'] : ''
      }`}
    >
      <p className={styles['text']}>
        <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.delete-active-work-orders-text" />
      </p>
      <div className={styles['work-orders-container']}>
        <div className={styles['active-work-orders']}>
          <InlineModal.Loader loading={this.state.isChangingPage}>
            {this.state.workOrders.map(workOrder => this.renderItem(workOrder))}
          </InlineModal.Loader>
        </div>
        {this.state.pagination.totalPages <= 1 ? null : (
          <InlineModal.Pagination
            totalPages={this.state.pagination.totalPages}
            currentPage={this.state.pagination.currentPage}
            onSelectPage={page => this.selectPage(page)}
          />
        )}
      </div>
      {this.state.checkedWorkOrderIds.length === 0 ? null : (
        <p className={styles['remove-marked-work-orders']}>
          <FormattedPlural
            value={this.state.checkedWorkOrderIds.length}
            one={
              <FormattedMessage
                id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.delete-work-order"
                values={{ amount: this.state.checkedWorkOrderIds.length }}
              />
            }
            other={
              <FormattedMessage
                id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.delete-marked-work-orders"
                values={{ amount: this.state.checkedWorkOrderIds.length }}
              />
            }
          />
        </p>
      )}
    </div>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={450}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          <div className={styles['cancel-repeat']}>
            <div className={styles['info']}>
              <p>
                <FormattedMessage id="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.info-text" />
              </p>
            </div>
            {this.state.isFetching ? this.renderLoader() : this.renderTable()}
          </div>
        </Modal.Content>
        <Modal.Footer container>
          <Button
            primary
            small
            loading={this.state.isArchivingRecurringMaintenance}
            onClick={this.archiveRecurringMaintenance}
            label="screens.work-order.recurring-maintenance-options-inline-modal.cancel-repeat.button-label"
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    inProgressByUser: EntitySelectors.getUser(state, ownProps.workOrder.in_progress_by_user_id),
    pausedByUser: EntitySelectors.getUser(state, ownProps.workOrder.paused_by_user_id),
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(
      state,
      ownProps.workOrder.recurring_maintenance
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      archiveRecurringMaintenance: SDKReduxOperations.archiveRecurringMaintenance,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CancelRepeat));
