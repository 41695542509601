import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Route, Switch, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import AnimateHeight from 'react-animate-height';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Toggle, WhiteCard } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Section, Option, Separator } from 'views/scenes/Settings/components';
import CostOtherCategories from './CostOtherCategories';
import SpentTimeCategories from 'views/scenes/Settings/WorkOrder/SpentTimeCategories';
import AMServiceLogo from 'assets/images/Logo.png';
import Costs_Eng from 'assets/images/ProScreenshots/Costs (Eng).png';
import Costs_Swe from 'assets/images/ProScreenshots/Costs (Swe).png';
import EditLaborCostModal from './EditLaborCostModal';
import styles from './style.module.scss';

class Costs extends Component {
  state = {
    showActivateModal: false,
    showDeactivateModal: false,
    isActivatingCosts: false,
    showEditLaborCostModal: false,
    isSavingActivateCosts: false,
  };

  updateSystem = params => {
    if (this.state.isSavingActivateCosts) return;
    this.setState({ isSavingActivateCosts: true });
    this.props
      .updateSystem(this.props.system.id, params)
      .then(() => {
        this.setState({ isSavingActivateCosts: false, showActivateModal: false, showDeactivateModal: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSavingActivateCosts: false, showActivateModal: false, showDeactivateModal: false });
      });
  };

  renderActivateConfirmModal = () => (
    <Modal isOpen={this.state.showActivateModal} width={440}>
      <Modal.Header
        title={<FormattedMessage id="screens.settings.costs.activate.title" />}
        subtitle={<FormattedMessage id="screens.settings.costs.activate.description" />}
        subtitleTopMargin
        onClose={() => {
          this.setState({ showActivateModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            loading={this.state.isSavingActivateCosts}
            onClick={() => {
              this.updateSystem({ costs_activated: true });
            }}
            label="screens.settings.costs.activate.activate-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showActivateModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderDeactivateConfirmModal = () => (
    <Modal isOpen={this.state.showDeactivateModal} width={440}>
      <Modal.Header
        title={<FormattedMessage id="screens.settings.costs.activate.deactivate-confirm.title" />}
        subtitle={<FormattedMessage id="screens.settings.costs.activate.deactivate-confirm.message" />}
        subtitleTopMargin
        onClose={() => {
          this.setState({ showDeactivateModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            destructive
            loading={this.state.isSavingActivateCosts}
            onClick={() => {
              this.updateSystem({ costs_activated: false });
            }}
            label="screens.settings.costs.activate.deactivate-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showDeactivateModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderEditLaborCostModal = () => (
    <EditLaborCostModal
      open={this.state.showEditLaborCostModal}
      onClose={() => {
        this.setState({ showEditLaborCostModal: false });
      }}
    />
  );

  renderSpentTimeCategoriesTitle = () => {
    if (this.props.system.work_order_spent_time_category_activated === true) {
      return <FormattedMessage id="screens.settings.work-orders.spent-time-categories.title" />;
    }
    return (
      <>
        <FormattedMessage id="screens.settings.work-orders.spent-time-categories.title" />
        <span className={styles['not-activated']}>
          <span> - </span>
          <FormattedMessage id="screens.settings.work-orders.spent-time-categories.not-activated" />
        </span>
      </>
    );
  };

  renderLaborCostTitle = () => {
    if (this.props.settings.labor_cost_per_hour) {
      return <FormattedMessage id="screens.settings.costs.labor-cost.title" />;
    }
    return <FormattedMessage id="screens.settings.costs.labor-cost.title" />;
  };

  renderRootSettings = () => (
    <>
      <Section
        header
        title={<FormattedMessage id="screens.settings.costs.title" />}
        subtitle={<FormattedMessage id="screens.settings.costs.description" />}
        rightComponent={
          <Toggle
            checked={this.props.system.costs_activated}
            loading={this.state.isActivatingCosts}
            onClick={() => {
              if (this.props.system.costs_activated) {
                this.setState({ showDeactivateModal: true });
              } else {
                this.setState({ showActivateModal: true });
              }
            }}
          />
        }
      />
      <AnimateHeight duration={250} height={this.props.system.costs_activated ? 'auto' : 0}>
        <>
          <Separator />
          <div className={styles['options']}>
            <Option.Container>
              <Option
                title={this.renderLaborCostTitle()}
                subtitle={<FormattedMessage id="screens.settings.costs.labor-cost.subtitle" />}
                rightContainer={
                  <>
                    <Button
                      primary
                      label="general.manage"
                      onClick={() => this.setState({ showEditLaborCostModal: true })}
                    />
                  </>
                }
              />
              <Option
                title={<FormattedMessage id="screens.settings.costs.cost-other-categories.title" />}
                subtitle={<FormattedMessage id="screens.settings.costs.cost-other-categories.subtitle" />}
                rightContainer={
                  <>
                    <Link to="/settings/costs/other-categories">
                      <Button primary label="general.manage" />
                    </Link>
                  </>
                }
              />
              {this.props.hasProTier ? (
                <Option
                  title={this.renderSpentTimeCategoriesTitle()}
                  subtitle={
                    <FormattedMessage id="screens.settings.work-orders.spent-time-categories.subtitle" />
                  }
                  rightContainer={
                    <Link to="/settings/costs/spent-time-categories">
                      <Button primary label="general.manage" />
                    </Link>
                  }
                />
              ) : null}
            </Option.Container>
          </div>
        </>
      </AnimateHeight>
    </>
  );

  renderUpgradeToPro = () => (
    <WhiteCard>
      <div className={styles['upgrade-container']}>
        <div className={styles['upgrade-title-container']}>
          <img src={AMServiceLogo} alt="logo" />
          <p>
            <FormattedMessage id="screens.settings.costs.upgrade-to-pro.title" />
          </p>
        </div>
        <p className={styles['upgrade-subtitle']}>
          <FormattedMessage id="screens.settings.costs.upgrade-to-pro.description" />
        </p>
        <img
          className={styles['upgrade-screenshot']}
          alt="screenshot"
          src={this.props.language === 'sv' ? Costs_Swe : Costs_Eng}
        />
        <div className={styles['upgrade-buttons-container']}>
          <Button upgradeToPro label="general.upgrade-to-pro" />
        </div>
      </div>
    </WhiteCard>
  );

  render() {
    if (!this.props.hasProTier) {
      return this.renderUpgradeToPro();
    }
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/costs" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/costs/other-categories" component={CostOtherCategories} />
          {this.props.hasProTier ? (
            <Route
              exact
              path="/settings/costs/spent-time-categories"
              render={() => <SpentTimeCategories fromCosts />}
            />
          ) : null}
        </Switch>
        {this.renderActivateConfirmModal()}
        {this.renderDeactivateConfirmModal()}
        {this.renderEditLaborCostModal()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Costs));
