import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, DatePicker } from 'views/components/Shared/General';
import Dropdown from './Dropdown';

class EditSparePartField extends Component {
  renderFieldContent = () => {
    const { type } = this.props.templateField;
    if (type === 'text') {
      return (
        <Field.Text
          value={this.props.value}
          onChange={value => {
            this.props.onChange(value);
          }}
        />
      );
    }
    if (type === 'textarea') {
      return (
        <Field.Textarea
          value={this.props.value}
          onChange={value => {
            this.props.onChange(value);
          }}
        />
      );
    }
    if (type === 'date') {
      return (
        <Field.Date
          value={this.props.value}
          onChangeDate={date => {
            this.props.onChange(date);
          }}
          footerComponent={
            <DatePicker.Footer
              onClear={() => {
                this.props.onClear();
              }}
            />
          }
        />
      );
    }
    if (type === 'valuelist') {
      return (
        <Dropdown
          templateField={this.props.templateField}
          value={this.props.value}
          onChange={value => this.props.onChange(value)}
          onClear={() => this.props.onClear()}
        />
      );
    }
  };

  render() {
    return (
      <Field view={false} label={this.props.templateField.title}>
        {this.renderFieldContent()}
      </Field>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    templateField: EntitySelectors.getTemplateField(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(EditSparePartField);
