import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import {
  FullScreenImagePicker,
  Button,
  Field,
  InlineModal,
  Icon,
  FileUploadWrapper,
  NewInlineModal,
  Tooltip,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { TreePath } from 'views/components/Asset';
import {
  NewAttachmentModal,
  AttachmentOptionsInlineModal,
  AttachmentItem,
} from 'views/components/Attachment';
import {
  ChecklistEditModal,
  ChecklistTitleModal,
  SelectChecklistModal,
  ChecklistOptionsModal,
} from 'views/components/Checklist';
import { SelectAssetAttachmentModal, ChooseAssetInlineModal, AssetTitle } from 'views/components/Asset';
import { Image, Loader } from 'views/components/Image';
import { getNewWeekdays } from 'sdk/RecurringMaintenance';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, API } from 'sdk';
import { AttachmentType } from 'sdk/Attachment';
import HelperFunctions from 'utilities/HelperFunctions';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import ChooseDueDateInlineModal from './ChooseDueDateInlineModal';
import AssetAttachmentOptionsInlineModal from './AssetAttachmentOptionsInlineModal';
import RecurringContainer from './RecurringContainer';
import ChecklistTemplateContainer from './ChecklistTemplateContainer';
import CantEditReusableChecklistModal from './CantEditReusableChecklistModal';
import styles from '../style.module.scss';

class PlanWorkOrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      showingImageDialog: false,
      title: this.props.editingWorkOrder.title || '',
      description: this.props.editingWorkOrder.description || '',
      showAssetAttachmentContent: false,
      showNewAttachmentModalForType: null,
      showNewFileInlineModalOptions: false,
      deletingImageIds: {},
      uploadingCount: 0,
      draftTemplateId: null,
      showChecklistTitleModal: false,
      showChecklistEditModal: false,
      showChecklistEditModalForVersionId: null,
      showNewChecklistDropdown: false,
      showSelectChecklistTemplateModal: false,
      showChecklistDropdown: false,
      showDeleteContentForChecklistDropdown: false,
      showCantEditReusableChecklistModal: false,
      showChecklistOptionsModal: false,
      showChecklistOptionsModalForChecklistTemplateId: null,
    };
  }

  isRecurringMaintenance = () => this.props.editingWorkOrder.frequency != null;

  addDraftImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  deleteImage = image => {
    this.props.onChangeValue({
      registration_images: this.props.editingWorkOrder.registration_images.filter(
        imageId => image.id !== imageId
      ),
      completion_images: this.props.editingWorkOrder.completion_images.filter(
        imageId => image.id !== imageId
      ),
    });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  addAttachmentFromAsset = attachmentId => {
    this.props.onChangeValue({
      registration_attachments: [attachmentId, ...this.props.editingWorkOrder.registration_attachments],
    });
  };

  changeTitle = value => {
    if (value.length > 0 && this.props.fieldErrors.title) {
      this.props.removeFieldsFromErrors(['title']);
    }
    this.setState({ title: value });
  };

  selectDate = date => {
    this.props.removeFieldsFromErrors(['date']);
    if (this.isRecurringMaintenance()) {
      const newWeekdays = getNewWeekdays(this.props.editingWorkOrder, date);
      this.props.onChangeValue({ date, completed_date: date, on_occasion: false, ...newWeekdays });
    } else {
      this.props.onChangeValue({ date, completed_date: date, on_occasion: false });
    }
  };

  createDraftChecklistTemplate = title => {
    this.setState({
      draftTemplateId: null,
      showChecklistTitleModal: false,
      showChecklistEditModalForVersionId: null,
      showNewChecklistDropdown: false,
      showChecklistEditModal: true,
    });
    this.props.onShowChecklistModal();
    this.props
      .createChecklistTemplate(this.props.currentSystem.id, { title })
      .then(({ data: checklistTemplate }) => {
        this.setState({
          draftTemplateId: checklistTemplate.id,
          showChecklistEditModalForVersionId: checklistTemplate.draft_version.id,
        });
      });
  };

  editChecklistTemplate = () => {
    if (this.state.draftTemplateId) {
      this.setState({
        showChecklistDropdown: false,
        showChecklistEditModal: true,
        showChecklistEditModalForVersionId: null,
      });
      API.fetchChecklistTemplate(this.state.draftTemplateId).then(({ data: checklistTemplate }) => {
        const { draft_version } = checklistTemplate;
        this.setState({ showChecklistEditModalForVersionId: draft_version.id });
      });
    } else {
      this.setState({ showChecklistDropdown: false, showCantEditReusableChecklistModal: true });
    }
  };

  saveChecklistModal = () => {
    this.props.onHideChecklistModal();
    if (this.props.editingWorkOrder.checklist_template_links.length === 0) {
      this.props.onChangeValue({
        checklist_template_links: [{ checklist_template_id: this.state.draftTemplateId }],
      });
      this.setState({ showChecklistEditModal: false });
    } else {
      this.setState({ showChecklistEditModal: false });
    }
  };

  /*
    Render functions
  */

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader />
    );
    return (
      <React.Fragment>
        {this.props.images.map((image, index) => {
          return (
            <Image
              key={image.id}
              medium
              isDeleting={this.state.deletingImageIds[image.id] != null}
              editable
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
              onDelete={() => {
                this.deleteImage(image);
              }}
            />
          );
        })}
        {loaderElements}
      </React.Fragment>
    );
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addDraftImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderRegistrationAttachmentsContainer = () => {
    const { registrationAttachments, uploadingAttachments, asset } = this.props;
    return (
      <Field label={<FormattedMessage id="resources.work-order.files" />}>
        {registrationAttachments.length === 0 && uploadingAttachments.length === 0 ? null : (
          <AttachmentItem.Container>
            {uploadingAttachments.map(attachment => (
              <AttachmentItem uploading namespace={this.props.editingWorkOrder.id} attachment={attachment} />
            ))}
            {registrationAttachments.map(attachment => {
              if (attachment.type === AttachmentType.Asset) {
                return (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    subtitle={
                      <div className={styles['subtitle-container']}>
                        <Icon type="box" />
                        <p>
                          <FormattedMessage id="screens.work-order.file-from-asset" />
                        </p>
                      </div>
                    }
                    buttons={
                      <AssetAttachmentOptionsInlineModal
                        attachment={attachment}
                        onRemoveAssetAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                        trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                      />
                    }
                    onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
                  />
                );
              } else {
                return (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    buttons={
                      <AttachmentOptionsInlineModal
                        context={this.isRecurringMaintenance() ? 'recurringMaintenance' : 'workOrder'}
                        attachment={attachment}
                        isDeletingAttachment={this.state.isDeletingAttachment}
                        onDeleteAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                        trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                      />
                    }
                    onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
                  />
                );
              }
            })}
          </AttachmentItem.Container>
        )}
        <div
          className={styles['add-button-container']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() =>
            this.setState(prevState => ({
              showNewFileInlineModalOptions: !prevState.showNewFileInlineModalOptions,
            }))
          }
        >
          <Button type="text" label="general.add" primary noUnderline />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showNewFileInlineModalOptions}
          onClose={() => this.setState({ showNewFileInlineModalOptions: false })}
        >
          <React.Fragment>
            <InlineModal.Body width={250} dropdown>
              <InlineModal.ListItem
                icon="upload"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'file',
                  });
                }}
              />
              <InlineModal.ListItem
                icon="link"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'link',
                  });
                }}
              />
              {asset == null ? null : (
                <InlineModal.ListItem
                  icon="box"
                  iconThickness="regular"
                  title={
                    <FormattedMessage
                      id="screens.asset.attachments.choose-from-asset"
                      values={{ asset: asset.title }}
                    />
                  }
                  onClick={() => {
                    this.setState({
                      showNewFileInlineModalOptions: false,
                      showAssetAttachmentContent: true,
                    });
                  }}
                />
              )}
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </Field>
    );
  };

  renderInlineModalBodyContent = () => {
    if (this.state.showDeleteContentForChecklistDropdown) {
      return (
        <>
          <InlineModal.Header
            width={300}
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showChecklistDropdown: false });
            }}
          />
          <InlineModal.Body width={300}>
            <InlineModal.Delete
              onDelete={() => {
                this.props.onChangeValue({ checklist_template_links: [] });
                this.setState({ showChecklistDropdown: false });
              }}
            />
          </InlineModal.Body>
        </>
      );
    }
    return (
      <InlineModal.Body dropdown width={250}>
        <InlineModal.ListItem
          icon="pen"
          iconThickness="regular"
          title={<FormattedMessage id="general.edit" />}
          onClick={this.editChecklistTemplate}
        />
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          destructive
          title={<FormattedMessage id="general.delete" />}
          onClick={() => {
            this.setState({ showDeleteContentForChecklistDropdown: true });
          }}
        />
      </InlineModal.Body>
    );
  };

  renderChecklistContainer = () => {
    if (this.props.hasProTier === false) {
      return null;
    }
    if (this.props.editingWorkOrder.checklist_template_links.length === 0) {
      return (
        <>
          <Field label={<FormattedMessage id="components.new-operational-maintenance-modal.checklist" />}>
            <div ref={ref => (this.checklistButtonRef = ref)}>
              <Button
                type="text"
                primary
                label="general.add"
                noUnderline
                onClick={() => {
                  this.setState(prevState => ({
                    showNewChecklistDropdown: !prevState.showNewChecklistDropdown,
                    showDeleteContentForChecklistDropdown: false,
                  }));
                }}
              />
            </div>
          </Field>
          <NewInlineModal
            positionToRef={this.checklistButtonRef}
            open={this.state.showNewChecklistDropdown}
            onClose={() => this.setState({ showNewChecklistDropdown: false })}
          >
            <React.Fragment>
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showNewChecklistDropdown: false,
                        showChecklistTitleModal: true,
                      });
                    }}
                  >
                    <FormattedMessage id="screens.work-order.edit.checklist.dropdown.create" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showNewChecklistDropdown: false,
                        showSelectChecklistTemplateModal: true,
                      });
                    }}
                  >
                    <FormattedMessage id="screens.work-order.edit.checklist.dropdown.add" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </React.Fragment>
          </NewInlineModal>
        </>
      );
    }
    return (
      <>
        <Field label={<FormattedMessage id="components.new-operational-maintenance-modal.checklist" />}>
          <div className={styles['related-resource']}>
            <div className={styles['title']}>
              <ChecklistTemplateContainer
                id={this.props.editingWorkOrder.checklist_template_links[0].checklist_template_id}
              />
            </div>
            <div
              ref={ref => (this.checklistInlineModalDropdown = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  showChecklistDropdown: !prevState.showChecklistDropdown,
                  showDeleteContentForChecklistDropdown: false,
                }));
              }}
            >
              <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
            </div>
          </div>
        </Field>
        <InlineModal
          open={this.state.showChecklistDropdown}
          positionToRef={this.checklistInlineModalDropdown}
          onClose={() => this.setState({ showChecklistDropdown: false })}
          position="right"
        >
          {this.renderInlineModalBodyContent()}
        </InlineModal>
      </>
    );
  };

  renderChecklistTitleModal = () => (
    <ChecklistTitleModal
      open={this.state.showChecklistTitleModal}
      onSave={this.createDraftChecklistTemplate}
      onClose={() => {
        this.setState({ showChecklistTitleModal: false });
      }}
    />
  );

  renderChecklistEditModal = () => (
    <ChecklistEditModal
      new
      open={this.state.showChecklistEditModal}
      id={this.state.showChecklistEditModalForVersionId}
      showUnsavedChanges={false}
      onSave={this.saveChecklistModal}
      onAddToLibrarySuccess={() => {
        this.props.onHideChecklistModal();
        this.props.onChangeValue({
          checklist_template_links: [{ checklist_template_id: this.state.draftTemplateId }],
        });
        this.setState({ showChecklistEditModal: false, draftTemplateId: null });
      }}
      onClose={() => {
        this.props.onHideChecklistModal();
        this.setState({ showChecklistEditModal: false });
      }}
    />
  );

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.title" />}>
                <ConnectedFieldErrorWrapper field="title" position="top">
                  {hasError => (
                    <Field.Text
                      autoFocus
                      error={hasError}
                      rightLabel={
                        <div className={styles['max-characters-container']}>
                          <span className={styles['max-characters']}>
                            <FormattedMessage
                              id="general.characters-left"
                              values={{ amount: 35 - this.state.title.length }}
                            />
                          </span>
                          <Tooltip
                            trigger={
                              <div className={styles['title-popup-container']}>
                                <Icon regular type="question-circle" />
                              </div>
                            }
                            label={<FormattedMessage id="general.characters-left-popup" />}
                          />
                        </div>
                      }
                      value={this.state.title}
                      onChange={value => this.changeTitle(value)}
                      onBlur={() => this.props.onChangeValue({ title: this.state.title })}
                    />
                  )}
                </ConnectedFieldErrorWrapper>
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.description" />}>
                <Field.Textarea
                  value={this.state.description}
                  onChange={value => this.setState({ description: value })}
                  onBlur={() => this.props.onChangeValue({ description: this.state.description })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.asset" />}>
                <ConnectedFieldErrorWrapper field="asset" position="top">
                  {hasError => (
                    <ChooseAssetInlineModal
                      trigger={
                        <Field.Resource
                          angleDown
                          error={hasError}
                          value={
                            this.props.asset == null ? null : (
                              <>
                                <AssetTitle id={this.props.asset.id} />
                                {this.props.asset.tree_path.length === 0 ? null : (
                                  <div className={styles['tree-path-container']}>
                                    <TreePath assetId={this.props.asset.id} />
                                  </div>
                                )}
                              </>
                            )
                          }
                          onClear={() => this.props.onChangeValue({ asset_id: null })}
                        />
                      }
                      selectedAssetId={this.props.editingWorkOrder.asset_id}
                      onSelectAsset={assetId => {
                        if (this.props.fieldErrors.asset) {
                          this.props.removeFieldsFromErrors(['asset']);
                        }
                        this.props.onChangeValue({ asset_id: assetId });
                      }}
                      onClear={e => this.props.onChangeValue({ asset_id: null })}
                    />
                  )}
                </ConnectedFieldErrorWrapper>
              </Field>
            </Grid.Column>
          </Grid.Row>
          <ChooseDueDateInlineModal
            editingWorkOrder={this.props.editingWorkOrder}
            isNewWorkOrder
            onSelectDate={date => this.selectDate(date)}
            onChangeEstimatedMinutes={estimated_minutes => this.props.onChangeValue(estimated_minutes)}
          />
          <RecurringContainer
            editingWorkOrder={this.props.editingWorkOrder}
            onChangeValue={intervalData => {
              this.props.onChangeValue(intervalData);
              if (intervalData.start_date) {
                this.props.removeFieldsFromErrors(['date']);
              }
            }}
          />

          <Grid.Separator />
          <Grid.Row>
            <Grid.Column md={6}>{this.renderRegistrationAttachmentsContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderImagesContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderChecklistContainer()}</Grid.Column>
          </Grid.Row>
        </Grid>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <SelectAssetAttachmentModal
          isOpen={this.state.showAssetAttachmentContent}
          onClose={() => this.setState({ showAssetAttachmentContent: false })}
          onSelectAttachment={attachment => {
            this.addAttachmentFromAsset(attachment.id);
            this.setState({ showAssetAttachmentContent: false });
          }}
          assetId={this.props.editingWorkOrder.asset_id}
        />
        <NewAttachmentModal
          isOpen={this.state.showNewAttachmentModalForType != null}
          type={this.state.showNewAttachmentModalForType}
          onClose={() => this.setState({ showNewAttachmentModalForType: null })}
          onCreateNew={params => this.props.onCreateAttachment(params)}
        />
        {this.renderChecklistEditModal()}
        {this.renderChecklistTitleModal()}
        <SelectChecklistModal
          open={this.state.showSelectChecklistTemplateModal}
          onSelect={checklistTemplate => {
            if (checklistTemplate.require_options) {
              this.setState({ showSelectChecklistTemplateModal: false });
              setTimeout(() => {
                this.setState({
                  showChecklistOptionsModal: true,
                  showChecklistOptionsModalForChecklistTemplateId: checklistTemplate.id,
                });
              }, 200);
            } else {
              this.props.onChangeValue({
                checklist_template_links: [{ checklist_template_id: checklistTemplate.id }],
              });
              this.setState({ draftTemplateId: null, showSelectChecklistTemplateModal: false });
            }
          }}
          onClose={() => {
            this.setState({ showSelectChecklistTemplateModal: false });
          }}
        />
        <ChecklistOptionsModal
          assetId={this.props.editingWorkOrder.asset_id}
          checklistTemplateId={this.state.showChecklistOptionsModalForChecklistTemplateId}
          open={this.state.showChecklistOptionsModal}
          onSave={params => {
            this.setState({ showChecklistOptionsModal: false });
            this.props.onChangeValue({
              checklist_template_links: [
                {
                  checklist_template_id: this.state.showChecklistOptionsModalForChecklistTemplateId,
                  ...params,
                },
              ],
            });
          }}
          onClose={() => this.setState({ showChecklistOptionsModal: false })}
        />
        <CantEditReusableChecklistModal
          open={this.state.showCantEditReusableChecklistModal}
          onClose={() => this.setState({ showCantEditReusableChecklistModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createDraftImage: SDKReduxOperations.createDraftImage,
      deleteImage: SDKReduxOperations.deleteImage,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    isAdmin: AuthSelectors.isAdmin(state),
    hasProTier: AuthSelectors.hasProTier(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    asset: EntitySelectors.getAsset(state, ownProps.editingWorkOrder.asset_id),
    workOrderType: EntitySelectors.getWorkOrderType(state, ownProps.editingWorkOrder.work_order_type_id),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(
      state,
      ownProps.editingWorkOrder.id
    ),
    registrationAttachments: EntitySelectors.getAttachments(
      state,
      ownProps.editingWorkOrder.registration_attachments
    ),
    images: EntitySelectors.getImages(state, ownProps.editingWorkOrder.registration_images),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlanWorkOrderContent)));
