import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Button, NewInlineModal, Icon } from 'views/components/Shared/General';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { MakeVendorPrimaryModal, RemoveVendorAsPrimaryModal } from 'views/components/SparePartVendor';
import { normalizeSparePartVendor } from 'sdk/Schemas';
import SparePartVendorListItem from './SparePartVendorListItem';
import styles from './styles.module.scss';

class MultipleSparePartVendorsModal extends Component {
  getInitialState = () => ({
    sparePartVendorIds: [],
    isFetching: true,
    dropdownOpen: false,
    showMakePrimaryVendorModal: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      API.listSparePartVendors(this.props.currentSystem.id, {
        no_pagination: true,
        spare_part_id: this.props.sparePartId,
        vendor_id: this.props.vendorId,
      }).then(({ data }) => {
        const { entities, result } = normalizeSparePartVendor(data);
        this.props.updateEntities(entities);
        this.setState({ sparePartVendorIds: result, isFetching: false });
      });
    }
  }

  renderButton = () => {
    if (this.props.select) {
      return null;
    }
    return (
      <div className={styles['button-container']}>
        <Button primary label="components.spare-part-vendors-modal.add-button" onClick={this.props.onAdd} />
      </div>
    );
  };

  renderSparePartVendors = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderButton()}
          <List light small>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    return (
      <>
        {this.renderButton()}
        <List light>
          {this.state.sparePartVendorIds.map(id => (
            <SparePartVendorListItem
              id={id}
              onClick={sparePartVendor => this.props.onSelectSparePartVendor(sparePartVendor)}
              onDelete={sparePartVendor => {
                this.props.onDelete(sparePartVendor);
                this.props.onClose();
              }}
            />
          ))}
        </List>
      </>
    );
  };

  renderSubtitle = () => {
    if (this.props.vendor) {
      return this.props.vendor.name;
    }
    return null;
  };

  renderIconButtons = () => {
    const { vendor, sparePart, select } = this.props;
    if (select === false && sparePart && vendor) {
      return (
        <>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.dropdownOpen}
            minWidth={235}
            position="right"
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                {sparePart.primary_vendor_id === vendor.id ? (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showRemoveVendorAsPrimaryModal: true, dropdownOpen: false });
                    }}
                  >
                    <FormattedMessage id="components.spare-part-vendors-modal.remove-primary" />
                  </NewInlineModal.Dropdown.Item>
                ) : (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showMakePrimaryVendorModal: true, dropdownOpen: false });
                    }}
                  >
                    <FormattedMessage id="components.spare-part-vendors-modal.make-primary" />
                  </NewInlineModal.Dropdown.Item>
                )}
                <NewInlineModal.Dropdown.Item
                  destructive
                  onClick={() => {
                    this.props.onDeleteAll();
                    this.setState({ dropdownOpen: false });
                  }}
                >
                  <FormattedMessage id="general.delete" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={600}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="components.spare-part-vendors-modal.title" />}
            subtitle={this.renderSubtitle()}
            onClose={this.props.onClose}
            iconButtons={this.renderIconButtons()}
          />
          <Modal.Content>{this.renderSparePartVendors()}</Modal.Content>
          <MakeVendorPrimaryModal
            open={this.state.showMakePrimaryVendorModal}
            sparePartId={this.props.sparePartId}
            vendorId={this.props.vendorId}
            onClose={() => {
              this.setState({ showMakePrimaryVendorModal: false });
            }}
          />
          <RemoveVendorAsPrimaryModal
            open={this.state.showRemoveVendorAsPrimaryModal}
            sparePartId={this.props.sparePartId}
            vendorId={this.props.vendorId}
            onClose={() => {
              this.setState({ showRemoveVendorAsPrimaryModal: false });
            }}
          />
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.sparePartId),
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleSparePartVendorsModal);

MultipleSparePartVendorsModal.propTypes = {
  open: PropTypes.bool,
  vendorId: PropTypes.string,
  sparePartId: PropTypes.string,
  onSelectSparePartVendor: PropTypes.func,
  onClose: PropTypes.func,
  select: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteAll: PropTypes.func,
};

MultipleSparePartVendorsModal.defaultProps = {
  open: false,
  vendorId: null,
  sparePartId: null,
  onSelectSparePartVendor: () => {},
  onClose: () => {},
  select: false,
  onDelete: () => {},
  onDeleteAll: () => {},
};
