import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { InlineModal, Button, FileUploadWrapper, Field } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import { AttachmentType } from 'sdk/Attachment';

class EditInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingActiveWorkOrderCount: true,
      activeWorkOrdersCount: 0,
      link_url: props.attachmentVersion.link_url || '',
      attachmentVersion: props.attachmentVersion,
      isSaving: false,
      attachmentToUpload: null,
    };
  }

  componentDidMount() {
    const { type: attachmentType } = this.props.attachment;
    if (
      attachmentType == AttachmentType.WorkOrderRegistration ||
      attachmentType == AttachmentType.WorkOrderCompletion
    ) {
      return;
    }
    API.listWorkOrders(this.props.currentSystem.id, {
      active: true,
      registration_attachment_id: this.props.attachmentVersion.attachment_id,
    }).then(res => {
      const { headers } = res;
      const { totalEntries: activeWorkOrdersCount } = HelperFunctions.getPaginationFromHeader(headers);
      this.setState({ isFetchingActiveWorkOrderCount: false, activeWorkOrdersCount });
    });
  }

  renderSingleAssetAndSingleWorkOrder = () => {
    const { asset_count } = this.props.attachment;
    const { activeWorkOrdersCount } = this.state;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-one-and-work-orders-count-one-files-context"
          values={{ asset_count, work_order_count: activeWorkOrdersCount }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.work-orders-count-one"
          values={{ work_order_count: activeWorkOrdersCount }}
        />
      );
    }
  };

  renderManyAssetsAndManyWorkOrders = () => {
    const { asset_count } = this.props.attachment;
    const { activeWorkOrdersCount } = this.state;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-other-and-work-orders-count-other-files-context"
          values={{ asset_count, work_order_count: activeWorkOrdersCount }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-other-and-work-orders-count-other"
          values={{ asset_count: asset_count - 1, work_order_count: activeWorkOrdersCount }}
        />
      );
    }
  };

  renderSingleAssetAndManyWorkOrders = () => {
    const { asset_count } = this.props.attachment;
    const { activeWorkOrdersCount } = this.state;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-one-and-work-orders-count-other-files-context"
          values={{ asset_count, work_order_count: activeWorkOrdersCount }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.active-work-orders-files-context-count-other"
          values={{ work_order_count: activeWorkOrdersCount }}
        />
      );
    }
  };

  renderManyAssetsAndSingleWorkOrder = () => {
    const { asset_count } = this.props.attachment;
    const { activeWorkOrdersCount } = this.state;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-other-and-work-orders-count-one-files-context"
          values={{ asset_count, work_order_count: activeWorkOrdersCount }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-other-and-work-orders-count-one"
          values={{ asset_count: asset_count - 1, work_order_count: activeWorkOrdersCount }}
        />
      );
    }
  };

  renderSingleAsset = () => {
    const { asset_count } = this.props.attachment;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-files-context-count-one"
          values={{ asset_count }}
        />
      );
    } else {
      return null;
    }
  };

  renderManySpareParts = () => {
    const { spare_part_count } = this.props.attachment;
    if (this.props.context === 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.spare-parts-files-context-count-other"
          values={{ spare_part_count }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.spare-parts-count-other"
          values={{ spare_part_count: spare_part_count - 1 }}
        />
      );
    }
  };

  renderSingleSparePart = () => {
    const { spare_part_count } = this.props.attachment;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.spare-parts-files-context-count-one"
          values={{ spare_part_count }}
        />
      );
    } else {
      return null;
    }
  };

  renderManyAssets = () => {
    const { asset_count } = this.props.attachment;
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-files-context-count-other"
          values={{ asset_count }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.assets-count-other"
          values={{ asset_count: asset_count - 1 }}
        />
      );
    }
  };

  renderSingleWorkOrder = () => {
    if (this.props.context == 'files') {
      return (
        <FormattedMessage
          id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.work-orders-files-context-count-one"
          values={{ work_order_count: 1 }}
        />
      );
    } else {
      return null;
    }
  };

  renderSingleActiveWorkOrder = () => {
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.active-work-orders-files-context-count-one"
        values={{ work_order_count: 1 }}
      />
    );
  };

  renderManyWorkOrders = () => {
    const { activeWorkOrdersCount } = this.state;
    return (
      <FormattedMessage
        id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.work-orders-files-context-count-other"
        values={{ work_order_count: activeWorkOrdersCount }}
      />
    );
  };

  renderSingleVendor = () => {
    return (
      <FormattedMessage id="components.attachment-options-inline-modal.options.edit.confirm-description-texts.vendor-count-one" />
    );
  };

  renderDescriptionText = () => {
    const {
      asset_count,
      vendor_id,
      spare_part_id,
      spare_part_count,
      registration_work_order_id,
      completion_work_order_id,
    } = this.props.attachment;
    const { activeWorkOrdersCount } = this.state;

    const manyAssetsAndManyWorkOrders = asset_count > 1 && activeWorkOrdersCount > 1;
    const singleAssetAndManyWorkOrders = asset_count === 1 && activeWorkOrdersCount > 1;
    const manyAssetsAndSingleWorkOrder = asset_count > 1 && activeWorkOrdersCount === 1;
    const singleAssetAndSingleWorkOrder = asset_count === 1 && activeWorkOrdersCount === 1;
    const manyWorkOrders = activeWorkOrdersCount > 1;
    const manyAssets = asset_count > 1;
    const singleAsset = asset_count === 1;
    const singleActiveWorkOrder = activeWorkOrdersCount === 1;
    const singleWorkOrder = registration_work_order_id || completion_work_order_id;
    const singleVendor = vendor_id;
    const singleSparePart = spare_part_id;
    const manySpareParts = spare_part_count > 1;

    if (manyAssetsAndManyWorkOrders) {
      return this.renderManyAssetsAndManyWorkOrders();
    }
    if (singleAssetAndManyWorkOrders) {
      return this.renderSingleAssetAndManyWorkOrders();
    }
    if (manyAssetsAndSingleWorkOrder) {
      return this.renderManyAssetsAndSingleWorkOrder();
    }
    if (singleAssetAndSingleWorkOrder) {
      return this.renderSingleAssetAndSingleWorkOrder();
    }
    if (manyAssets) {
      return this.renderManyAssets();
    }
    if (singleAsset) {
      return this.renderSingleAsset();
    }
    if (manyWorkOrders) {
      return this.renderManyWorkOrders();
    }
    if (singleActiveWorkOrder) {
      return this.renderSingleActiveWorkOrder();
    }
    if (singleWorkOrder) {
      return this.renderSingleWorkOrder();
    }
    if (singleVendor) {
      return this.renderSingleVendor();
    }
    if (singleSparePart) {
      return this.renderSingleSparePart();
    }
    if (manySpareParts) {
      return this.renderManySpareParts();
    }
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.isSaveable()) {
      this.save();
    }
  };

  updateAttachmentVersion = () => {
    this.setState({ isSaving: true });
    this.props
      .updateAttachmentVersion(this.state.attachmentVersion.id, {
        title: this.state.attachmentVersion.title,
        description: this.state.attachmentVersion.description,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="components.attachment-options-inline-modal.options.edit.save-success" />
            }
          />
        );
        this.setState({ isSaving: false });
        this.props.onClose();
      });
  };

  createNewVersion = () => {
    const { type: attachmentType } = this.props.attachment;
    const { file, mime_type, extension } = this.state.attachmentToUpload || {};
    const { id } = this.props.attachmentVersion;
    this.setState({ isSaving: true });
    this.props
      .createAttachmentVersionForAttachment(this.props.attachmentVersion.attachment_id, {
        title: this.state.attachmentVersion.title,
        description: this.state.attachmentVersion.description,
        ...(this.props.attachmentVersion.type === 'file'
          ? {
              type: 'file',
              file,
              extension,
              mime_type,
            }
          : {
              type: 'link',
              link_url: this.state.link_url,
            }),
      })
      .then(() => {
        if (
          attachmentType === AttachmentType.WorkOrderRegistration ||
          attachmentType === AttachmentType.WorkOrderCompletion ||
          attachmentType === AttachmentType.Draft
        ) {
          this.props.deleteAttachmentVersion(id).then(() => {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="components.attachment-options-inline-modal.options.edit.save-success" />
                }
              />
            );
            this.setState({ isSaving: false });
            this.props.onClose();
          });
        } else {
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="components.attachment-options-inline-modal.options.edit.save-success" />
              }
            />
          );
          this.setState({ isSaving: false });
          this.props.onClose();
        }
      });
  };

  save = () => {
    if (this.props.attachmentVersion.type === 'file' && this.state.attachmentToUpload != null) {
      this.createNewVersion();
      return;
    }
    if (
      this.props.attachmentVersion.type === 'link' &&
      this.state.link_url !== this.props.attachmentVersion.link_url
    ) {
      this.createNewVersion();
      return;
    }
    this.updateAttachmentVersion();
  };

  renderChangeFile = () => {
    const { type: attachmentVersionType } = this.props.attachmentVersion;
    if (attachmentVersionType === 'file') {
      return (
        <InlineModal.Field>
          <FileUploadWrapper
            onSelectFile={({ file, mime_type, name, extension }) =>
              this.setState({ attachmentToUpload: { file, mime_type, name, extension } })
            }
          >
            <Button
              fullWidth
              primary
              label={
                this.state.attachmentToUpload == null
                  ? 'components.attachment-options-inline-modal.options.change-file.choose-file'
                  : this.state.attachmentToUpload.name
              }
              translate={this.state.attachmentToUpload == null}
            />
          </FileUploadWrapper>
        </InlineModal.Field>
      );
    } else {
      return (
        <React.Fragment>
          <InlineModal.Field label="resources.attachment.hyperlink">
            <Field.Text
              value={this.state.link_url}
              onChange={value =>
                this.setState({
                  link_url: value,
                })
              }
              onKeyPress={this.handleKeyPress}
            />
          </InlineModal.Field>
        </React.Fragment>
      );
    }
  };

  isSaveable = () => {
    if (this.props.attachmentVersion.type === 'file') {
      if (this.state.attachmentVersion.title) return true;
    }
    if (this.props.attachmentVersion.type === 'link') {
      if (this.state.attachmentVersion.title && this.state.link_url) return true;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <InlineModal.Header title={<FormattedMessage id="general.edit" />} onClose={this.props.onClose} />
        <InlineModal.Body width={300}>
          <InlineModal.Field label="resources.attachment.name">
            <Field.Text
              autoFocus
              value={this.state.attachmentVersion.title}
              onChange={value =>
                this.setState({
                  attachmentVersion: {
                    ...this.state.attachmentVersion,
                    title: value,
                  },
                })
              }
              onKeyPress={this.handleKeyPress}
            />
          </InlineModal.Field>
          <InlineModal.Field label="resources.attachment.description">
            <Field.Textarea
              value={this.state.attachmentVersion.description}
              onChange={description =>
                this.setState({
                  attachmentVersion: {
                    ...this.state.attachmentVersion,
                    description,
                  },
                })
              }
            ></Field.Textarea>
          </InlineModal.Field>
          {this.renderChangeFile()}
          <p className={styles['warning-info']}>{this.renderDescriptionText()}</p>
          <InlineModal.Separator />
          <Button
            primary
            small
            label="general.save"
            loading={this.state.isSaving}
            onClick={this.save}
            disabled={!this.isSaveable()}
          />
        </InlineModal.Body>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAttachmentVersionForAttachment: SDKReduxOperations.createAttachmentVersionForAttachment,
      updateAttachmentVersion: SDKReduxOperations.updateAttachmentVersion,
      deleteAttachmentVersion: SDKReduxOperations.deleteAttachmentVersion,
    },
    dispatch
  );
}

function mapStatetoProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStatetoProps, mapDispatchToProps)(EditInfo));
