import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { UserType } from 'sdk/SystemAccess';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { List, Icon, Button, NewInlineModal, ConfirmModal, Tooltip } from 'views/components/Shared/General';
import UserInfoModal from 'views/scenes/Users/Users/components/UserInfoModal';
import UserDeleteModal from 'views/scenes/Users/Users/components/UserDeleteModal';
import UserLoginModal from 'views/scenes/Users/Users/components/UserLoginModal';
import UserPermissionModal from 'views/scenes/Organisation/Users/components/UserPermissionModal';
import UserResetPasswordModal from 'views/scenes/Users/Users/components/UserResetPasswordModal';
import UserPinModal from 'views/scenes/Users/Users/components/UserPinModal';
import EditPinModal from 'views/scenes/Users/Users/components/EditPinModal';
import UserArchiveModal from 'views/scenes/Users/Users/components/UserArchiveModal';
import ResendInviteModal from 'views/scenes/Users/Users/components/ResendInviteModal';
import styles from './style.module.scss';

class UserListItem extends Component {
  state = {
    dropdownOpen: false,
    showUserInfoModal: false,
    showUserDeleteModal: false,
    showUserLoginModal: false,
    showUserPinModal: false,
    showUserPermissionModal: false,
    showResetPasswordModal: false,
    showResendInviteModal: false,
    showEditPinModal: false,
    isHoveringListItem: false,
    showArchiveUserModal: false,
    showRestoreUserFromArchiveModal: false,
    isRestoringUserFromArchive: false,
  };

  renderSystem = () => {
    const { systemAccesses, system, systems, user } = this.props;
    if (user.organisation_admin || systemAccesses.length === systems.length) {
      return (
        <span className={styles['subtitle']}>
          <FormattedMessage id="screens.organisation.all-systems" />
        </span>
      );
    }
    if (systemAccesses.length === 1) {
      return <span className={styles['subtitle']}>{system.name}</span>;
    }
    return (
      <span className={styles['subtitle']}>
        <FormattedMessage
          id="screens.organisation.amount-of-systems"
          values={{ count: systemAccesses.length }}
        />
      </span>
    );
  };

  renderPermission = () => {
    const { system } = queryString.parse(this.props.location.search);
    const { systemAccesses, systemAccessForSystem, user } = this.props;
    let content = null;
    if (user.organisation_admin) {
      content = <FormattedMessage id="resources.user.org-admin" />;
    } else if (systemAccesses.length === 0 || (systemAccesses.length > 1 && system == null)) {
      content = '-';
    } else {
      const systemAccess = systemAccessForSystem || systemAccesses[0];
      if (systemAccess.user_type === UserType.PermissionProfile && this.props.permissionProfile) {
        content = this.props.permissionProfile.title;
      } else if (systemAccess.user_type === UserType.SystemAdmin) {
        content = <FormattedMessage id="resources.user.admin" />;
      } else if (systemAccess.user_type === UserType.Operator) {
        content = <FormattedMessage id="resources.user.operator" />;
      } else if (systemAccess.user_type === UserType.ProductionSupervisor) {
        content = <FormattedMessage id="resources.user.production-supervisor" />;
      } else if (systemAccess.user_type === UserType.ViewOnly) {
        content = <FormattedMessage id="resources.user.view-only" />;
      } else if (systemAccess.user_type === UserType.WorkRequester) {
        content = <FormattedMessage id="resources.user.work-requester" />;
      }
    }
    return <span className={styles['subtitle']}>{content}</span>;
  };

  renderColumns = () => {
    const { system } = queryString.parse(this.props.location.search);

    if (system == null) {
      return (
        <>
          <List.Item.Column width={250}>{this.renderSystem()}</List.Item.Column>
          <List.Item.Column width={250}>{this.renderPermission()}</List.Item.Column>
        </>
      );
    }
    return <List.Item.Column width={250}>{this.renderPermission()}</List.Item.Column>;
  };

  renderUserInfoModal = () => {
    return (
      <UserInfoModal
        id={this.props.user.id}
        open={this.state.showUserInfoModal}
        onClose={() => this.setState({ showUserInfoModal: false })}
      />
    );
  };

  renderUserDeleteModal = () => {
    return (
      <UserDeleteModal
        id={this.props.user.id}
        openedFromManageOrg
        open={this.state.showUserDeleteModal}
        onClose={() => this.setState({ showUserDeleteModal: false })}
      />
    );
  };

  renderUserArchiveModal = () => {
    return (
      <UserArchiveModal
        id={this.props.user.id}
        open={this.state.showArchiveUserModal}
        onClose={() => this.setState({ showArchiveUserModal: false })}
      />
    );
  };

  renderUserPermissionModal = () => {
    return (
      <UserPermissionModal
        id={this.props.user.id}
        open={this.state.showUserPermissionModal}
        onClose={() => this.setState({ showUserPermissionModal: false })}
      />
    );
  };

  renderEditPinModal = () => {
    return (
      <EditPinModal
        id={this.props.user.id}
        open={this.state.showEditPinModal}
        onCloseWithoutPin={() => {
          this.setState({ showEditPinModal: false });
        }}
        onClose={() => {
          this.setState({ showEditPinModal: false });
        }}
      />
    );
  };

  renderUserPinModal = () => {
    return (
      <UserPinModal
        id={this.props.user.id}
        open={this.state.showUserPinModal}
        openEditPin={() => {
          this.setState({ showUserPinModal: false });
          setTimeout(() => {
            this.setState({ showEditPinModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showUserPinModal: false })}
      />
    );
  };

  renderUserLoginModal = () => {
    return (
      <UserLoginModal
        id={this.props.user.id}
        open={this.state.showUserLoginModal}
        onResetPassword={() => {
          this.setState({ showUserLoginModal: false });
          setTimeout(() => {
            this.setState({ showResetPasswordModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showUserLoginModal: false })}
      />
    );
  };

  renderResetPasswordModal = () => {
    return (
      <UserResetPasswordModal
        id={this.props.user.id}
        open={this.state.showResetPasswordModal}
        onClose={() => {
          this.setState({ showResetPasswordModal: false });
        }}
      />
    );
  };

  renderResendInviteModal = () => {
    return (
      <ResendInviteModal
        id={this.props.user.id}
        open={this.state.showResendInviteModal}
        onClose={() => {
          this.setState({ showResendInviteModal: false });
        }}
      />
    );
  };

  renderUserPermissionModal = () => {
    return (
      <UserPermissionModal
        id={this.props.user.id}
        open={this.state.showUserPermissionModal}
        onClose={() => this.setState({ showUserPermissionModal: false })}
      />
    );
  };

  renderRestoreUserFromArchiveModal = () => (
    <ConfirmModal
      open={this.state.showRestoreUserFromArchiveModal}
      title={<FormattedMessage id="screens.users.restore-user-modal.title" />}
      message={<FormattedMessage id="screens.users.restore-user-modal.subtitle" />}
      confirmButtonText="screens.users.restore-user-modal.button"
      confirmIsLoading={this.state.isRestoringUserFromArchive}
      onConfirm={() => {
        this.setState({ isRestoringUserFromArchive: true });
        this.props
          .updateUser(this.props.user.id, { archived: false })
          .then(res => {
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
            this.setState({ isRestoringUserFromArchive: false, showRestoreUserFromArchiveModal: false });
          })
          .catch(e => {});
      }}
      onCancel={() => {
        this.setState({ showRestoreUserFromArchiveModal: false });
      }}
    />
  );

  renderInvitedInlineModalListItem = () => {
    if (this.props.user.invited) {
      return (
        <NewInlineModal.Dropdown.Item
          leftComponent={
            <span>
              <FormattedMessage id="screens.users.action-dropdown.send-invite-again" />
            </span>
          }
          onClick={() => {
            this.setState({ isHoveringListItem: false, dropdownOpen: false, showResendInviteModal: true });
          }}
        />
      );
    }
    return null;
  };

  renderInlineModalListItemRightcomponent = () => {
    const { user, organisationSettings } = this.props;
    if (organisationSettings.sso_oidc_activated === false) {
      if (user.login_credential == null) {
        return <Icon regular type="exclamation-circle" red />;
      }
    }
    return null;
  };

  renderArchiveAndDeleteUserOptions = () => {
    const { user } = this.props;
    if (user.archived) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            leftComponent={<FormattedMessage id="screens.users.action-dropdown.restore-user" />}
            onClick={() => {
              this.setState({
                isHoveringListItem: false,
                dropdownOpen: false,
                showRestoreUserFromArchiveModal: true,
              });
            }}
          />
          <NewInlineModal.Dropdown.Item
            destructive
            leftComponent={
              <span className={styles['danger-text']}>
                <FormattedMessage id="screens.users.action-dropdown.delete-user" />
              </span>
            }
            onClick={() => {
              this.setState({
                isHoveringListItem: false,
                dropdownOpen: false,
                showUserDeleteModal: true,
              });
            }}
          />
        </>
      );
    }
    return (
      <NewInlineModal.Dropdown.Item
        destructive
        leftComponent={
          <span className={styles['danger-text']}>
            <FormattedMessage id="screens.users.action-dropdown.archive-user" />
          </span>
        }
        onClick={() => {
          this.setState({
            isHoveringListItem: false,
            dropdownOpen: false,
            showArchiveUserModal: true,
          });
        }}
      />
    );
  };

  renderDropdown = () => {
    return (
      <NewInlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.dropdownOpen}
        minWidth={265}
        position="right"
        onClose={() => this.setState({ dropdownOpen: false })}
      >
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item
              leftComponent={<FormattedMessage id="screens.users.action-dropdown.change-info" />}
              onClick={() => {
                this.setState({ isHoveringListItem: false, dropdownOpen: false, showUserInfoModal: true });
              }}
            />
            <NewInlineModal.Dropdown.Item
              leftComponent={
                <span>
                  <FormattedMessage id="screens.users.action-dropdown.change-rights" />
                </span>
              }
              onClick={() => {
                this.setState({
                  isHoveringListItem: false,
                  dropdownOpen: false,
                  showUserPermissionModal: true,
                });
              }}
            />
            <NewInlineModal.Dropdown.Item
              leftComponent={
                <span>
                  <FormattedMessage id="screens.users.action-dropdown.change-login" />
                </span>
              }
              rightComponent={this.renderInlineModalListItemRightcomponent()}
              onClick={() => {
                this.setState({ isHoveringListItem: false, dropdownOpen: false, showUserLoginModal: true });
              }}
            />
            {this.renderInvitedInlineModalListItem()}
            {this.props.systemAccesses.find(({ user_type }) => user_type === UserType.Operator) ? (
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <span>
                    <FormattedMessage id="screens.users.action-dropdown.change-pin" />
                  </span>
                }
                onClick={() => {
                  if (this.props.user.pin_code_required) {
                    this.setState({
                      isHoveringListItem: false,
                      dropdownOpen: false,
                      showUserPinModal: true,
                    });
                  } else {
                    this.setState({
                      isHoveringListItem: false,
                      dropdownOpen: false,
                      showEditPinModal: true,
                    });
                  }
                }}
              />
            ) : null}
            <NewInlineModal.Dropdown.Separator />
            {this.renderArchiveAndDeleteUserOptions()}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </NewInlineModal>
    );
  };

  renderDropdownButton = () => {
    const { currentUser, user } = this.props;
    if (currentUser.id === user.id) {
      return (
        <List.Item.Column
          alignRight
          width={108}
          hide={this.state.isHoveringListItem === false && this.state.dropdownOpen === false}
        >
          <Tooltip
            label={<FormattedMessage id="screens.users.cant-edit-own-user" />}
            trigger={<Button disabled caret gray small label="screens.users.action-button" />}
          />
        </List.Item.Column>
      );
    }
    return (
      <List.Item.Column
        alignRight
        width={108}
        hide={this.state.isHoveringListItem === false && this.state.dropdownOpen === false}
      >
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))}
        >
          <Button caret gray label="screens.users.action-button" />
        </div>
        {this.renderDropdown()}
      </List.Item.Column>
    );
  };

  renderTitle = () => {
    const { user } = this.props;
    if (user.archived === true) {
      return <span className={styles['archived']}>{this.props.user.name}</span>;
    }
    return <span>{this.props.user.name}</span>;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <>
          <List.Item
            small
            onMouseEnter={() => {
              this.setState({ isHoveringListItem: true });
            }}
            onMouseLeave={() => this.setState({ isHoveringListItem: false })}
          >
            <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.props.user.email} />

            {this.renderColumns()}
            {this.renderDropdownButton()}
          </List.Item>
          {this.renderUserInfoModal()}
          {this.renderUserDeleteModal()}
          {this.renderUserArchiveModal()}
          {this.renderUserPinModal()}
          {this.renderUserLoginModal()}
          {this.renderResetPasswordModal()}
          {this.renderEditPinModal()}
          {this.renderResendInviteModal()}
          {this.renderUserPermissionModal()}
          {this.renderRestoreUserFromArchiveModal()}
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { system: systemId } = queryString.parse(ownProps.location.search);
  if (ownProps.loading) return {};
  let permissionProfile = null;
  let system = null;
  let systemAccessForSystem = null;
  const user = EntitySelectors.getUser(state, ownProps.id);
  const systemAccesses = EntitySelectors.getSystemAccesses(state, user.system_accesses);

  if (systemAccesses.length === 1) {
    permissionProfile = EntitySelectors.getPermissionProfile(state, systemAccesses[0].permission_profile_id);
    system = EntitySelectors.getSystem(state, systemAccesses[0].system_id);
  }
  if (systemId) {
    systemAccessForSystem = systemAccesses.find(({ system_id }) => system_id === systemId);
    if (systemAccessForSystem) {
      permissionProfile = EntitySelectors.getPermissionProfile(
        state,
        systemAccessForSystem.permission_profile_id
      );
    }
  }
  return {
    user,
    systemAccesses,
    systemAccessForSystem,
    permissionProfile,
    system,
    settings: AuthSelectors.getSettings(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    systems: AuthSelectors.getSystems(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListItem));
