import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Tooltip, NewInlineModal, Icon, Datapill } from 'views/components/Shared/General';
import { AssetTitle } from 'views/components/Asset';
import styles from './style.module.scss';

class Item extends Component {
  state = {
    value: '',
    expanded: false,
    isCreatingAsset: false,
    showDropDown: false,
  };

  renderIcon = item => {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <span className={styles['expand-btn']} onClick={this.collapse}>
          <Icon regular type="minus" />
        </span>
      ) : (
        <span className={styles['expand-btn']} onClick={this.expand}>
          <Icon regular type="plus" />
        </span>
      );
    }
    return <span className={styles['expand-empty']}></span>;
  };

  expandNode = () => {
    this.setState({ expanded: true });
  };

  cancelEdit = () => {
    this.setState({ expanded: false });
  };

  duplicate = () => {
    this.props.onDuplicate(this.props.asset);
  };

  delete = () => {
    this.props.onDelete(this.props.asset);
  };

  archive = () => {
    this.props.onArchive(this.props.asset);
  };

  unarchive = () => {
    this.props.onUnarchive(this.props.asset);
  };

  collapse = () => {
    this.props.onCollapse(this.props.item.id);
  };

  expand = () => {
    this.props.onExpand(this.props.item.id);
  };

  printQrcodes = () => {
    this.props.onPrintQRCodes(this.props.asset);
  };

  renderArchivedDataPill = () => {
    if (this.props.asset.archived) {
      return (
        <div className={styles['archived-container']}>
          <Datapill value={<FormattedMessage id="resources.asset.archived" />} gray />
        </div>
      );
    }
    return null;
  };

  render() {
    const style = {};
    if (this.props.provided.draggableProps.style.opacity === 0.7) {
      style.transform = 'scale(0.95)';
    }
    let itemClassNames = [styles['item-data']];
    if (this.props.asset.archived) {
      itemClassNames = [...itemClassNames, styles['archived']];
    }
    return (
      <div
        ref={this.props.provided.innerRef}
        {...this.props.provided.draggableProps}
        {...this.props.provided.dragHandleProps}
        className={`${styles['draggable-container']} ${this.state.expanded ? styles['expanded'] : ''}`}
      >
        <div ref={ref => (this.itemContainerRef = ref)}>
          <div className={styles['item-container']} style={style}>
            {this.renderIcon(this.props.item, this.props.onExpand, this.props.onCollapse)}
            <div className={styles['item']}>
              <div className={itemClassNames.join(' ')}>
                <Link
                  onClick={() => {
                    this.props.onItemClicked(this.props.item.id);
                  }}
                  to={`/assets/${this.props.item.id}`}
                >
                  <div className={styles['title-container']}>
                    <AssetTitle id={this.props.asset.id} />
                  </div>
                </Link>
                {this.renderArchivedDataPill()}
              </div>

              {this.props.canEditAssets ? (
                <div className={styles['buttons-wrapper']}>
                  <Tooltip
                    label={<FormattedMessage id="screens.assets.tree.create-child-node" />}
                    trigger={
                      <div className={styles['button']}>
                        <Button
                          type="icon"
                          icon={<Icon regular type="plus" />}
                          label="screens.assets.tree.create-button"
                          onClick={() => this.props.onCreateAssetForAsset(this.props.item.id)}
                        />
                      </div>
                    }
                  />
                  <div
                    className={styles['button']}
                    ref={ref => (this.inlineModalPositioningRef = ref)}
                    onClick={() => this.setState({ showDropDown: true })}
                  >
                    <Button
                      type="icon"
                      icon={<Icon regular type="ellipsis-h" />}
                      label="screens.assets.tree.create-button"
                    />
                  </div>
                  <NewInlineModal
                    minWidth={220}
                    positionToRef={this.inlineModalPositioningRef}
                    open={this.state.showDropDown}
                    onClose={() => this.setState({ showDropDown: false })}
                  >
                    <NewInlineModal.Dropdown>
                      <NewInlineModal.Dropdown.Items>
                        <NewInlineModal.Dropdown.Item
                          onClick={() => {
                            this.printQrcodes();
                            this.setState({ showDropDown: false });
                          }}
                        >
                          <FormattedMessage id="screens.assets.tree.print-qr-code" />
                        </NewInlineModal.Dropdown.Item>
                        <NewInlineModal.Dropdown.Item
                          onClick={() => {
                            this.duplicate();
                            this.setState({ showDropDown: false });
                          }}
                        >
                          <FormattedMessage id="screens.assets.tree.duplicate" />
                        </NewInlineModal.Dropdown.Item>
                        <NewInlineModal.Dropdown.Separator />
                        {this.props.asset.archived ? (
                          <NewInlineModal.Dropdown.Item
                            onClick={() => {
                              this.unarchive();
                              this.setState({ showDropDown: false });
                            }}
                          >
                            <FormattedMessage id="screens.assets.tree.unarchive" />
                          </NewInlineModal.Dropdown.Item>
                        ) : (
                          <NewInlineModal.Dropdown.Item
                            onClick={() => {
                              this.archive();
                              this.setState({ showDropDown: false });
                            }}
                          >
                            <FormattedMessage id="screens.assets.tree.archive" />
                          </NewInlineModal.Dropdown.Item>
                        )}
                        <NewInlineModal.Dropdown.Item
                          destructive
                          onClick={() => {
                            this.delete();
                            this.setState({ showDropDown: false });
                          }}
                        >
                          <FormattedMessage id="general.delete" />
                        </NewInlineModal.Dropdown.Item>
                      </NewInlineModal.Dropdown.Items>
                    </NewInlineModal.Dropdown>
                  </NewInlineModal>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.item.id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default withRouter(connect(mapStateToProps)(Item));
