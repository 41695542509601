import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'views/components/Shared/Layout';
import { EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingOperations, StocktakingSelectors } from 'state/ducks/stocktaking';
import SparePartList from './SparePartList';
import Header from '../../Header';
import CompletedWhiteCard from '../../Components/CompletedWhiteCard';
import StocktakingTiny from 'assets/images/EmptyDataSet/StocktakingTiny.png';

class Locations extends Component {
  componentDidMount() {
    this.fetchSpareParts();
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (oldQueryParams.parentId !== queryParams.parentId) {
      this.fetchSpareParts();
    }

    if (!prevProps.stocktaking.completed && this.props.stocktaking.completed) {
      this.fetchSpareParts();
    }
  }

  fetchMoreSpareParts = () => {
    if (this.props.isFullyLoaded || this.props.loadingMore) return;

    this.fetchSpareParts({ paginate_from: this.props.paginateFrom });
  };

  fetchSpareParts = (params = {}) => {
    const stocktakingId = this.props.match.params.id;
    let filterParams = {
      ...params,
      spare_part_location_id: {
        [HelperFunctions.FILTER_COMPARABLES.Exists]: false,
      },
    };

    if (this.props.stocktaking.completed) {
      filterParams = { ...filterParams, has_stocktaking_row: true };
    }

    return this.props
      .fetchSparePartsForStocktaking(stocktakingId, filterParams)
      .then(({ data: spareParts }) => {
        return spareParts;
      });
  };

  renderEmptyDatasetTitle = () => {
    const { showSparePartLocationTab, stocktaking } = this.props;
    if (showSparePartLocationTab && stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.no-location.empty-dataset-completed.title" />;
    } else if (showSparePartLocationTab && !stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.no-location.empty-dataset.title" />;
    } else if (!showSparePartLocationTab && stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.empty-dataset-completed.title" />;
    } else if (!showSparePartLocationTab && !stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.empty-dataset.title" />;
    }
  };

  renderEmptyDatasetSubtitle = () => {
    const { showSparePartLocationTab, stocktaking } = this.props;
    if (showSparePartLocationTab && stocktaking.completed) {
      return (
        <FormattedMessage id="screens.stocktaking-detail.no-location.empty-dataset-completed.subtitle" />
      );
    } else if (showSparePartLocationTab && !stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.no-location.empty-dataset.subtitle" />;
    } else if (!showSparePartLocationTab && stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.empty-dataset-completed.subtitle" />;
    } else if (!showSparePartLocationTab && !stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.empty-dataset.subtitle" />;
    }
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={this.renderEmptyDatasetTitle()}
        subtitle={this.renderEmptyDatasetSubtitle()}
        image={StocktakingTiny}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderContent = () => {
    if (!this.props.loading && this.props.sparePartIds.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return <SparePartList />;
    }
  };

  render() {
    return (
      <PerfectScrollbar>
        <InfiniteScroll
          loadMore={this.fetchMoreSpareParts}
          hasMore={!this.props.isFullyLoaded}
          useWindow={false}
          initialLoad={false}
          threshold={350}
        >
          <Header />
          <ContentContainer key={this.props.match.params.id}>
            {this.props.stocktaking.completed ? <CompletedWhiteCard /> : null}
            {this.renderContent()}
          </ContentContainer>
        </InfiniteScroll>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartsForStocktaking: StocktakingOperations.fetchSparePartsForStocktaking,
      clearSpareParts: StocktakingOperations.clearSpareParts,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartIds: StocktakingSelectors.getSparePartIds(state),
    isFullyLoaded: StocktakingSelectors.getSparePartsIsFullyLoaded(state),
    loading: StocktakingSelectors.getSparePartsLoading(state),
    loadingMore: StocktakingSelectors.getSparePartsLoadingMore(state),
    paginateFrom: StocktakingSelectors.getSparePartsPaginateFrom(state),
    showSparePartLocationTab: StocktakingSelectors.getShowSparePartLocationTab(state),
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));
