import types from './types';

const unitializeListCount = payload => ({
  type: types.UNITIALIZE_LIST_COUNT,
  payload: payload,
});

const resetOperationalMaintenanceTemplatesState = payload => ({
  type: types.RESET_MAINTENANCES_TEMPLATES_STATE,
  payload: payload,
});

const addQueryParameterForTemplates = payload => ({
  type: types.ADD_QUERY_PARAMETER_FOR_TEMPLATES,
  payload: payload,
});

const addQueryParameterForOperationalMaintenances = payload => ({
  type: types.ADD_QUERY_PARAMETER_FOR_OPERATIONAL_MAINTENANCES,
  payload: payload,
});

const setNavigatedToOperationalMaintenance = payload => ({
  type: types.SET_NAVIGATED_TO_OPERATIONAL_MAINTENANCE,
  payload: payload,
});

const selectOperationalMaintenancesPage = payload => ({
  type: types.SELECT_OPERATIONAL_MAINTENANCES_PAGE,
  payload: payload,
});

const selectOperationalMaintenance = payload => ({
  type: types.SELECT_OPERATIONAL_MAINTENANCE,
  payload: payload,
});

const resetSelectedOperationalMaintenances = payload => ({
  type: types.RESET_SELECTED_OPERATIONAL_MAINTENANCES,
  payload: payload,
});

const toggleExpandAsset = payload => ({
  type: types.TOGGLE_EXPAND_ASSET,
  payload: payload,
});

const fetchOperationalMaintenancesSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_SUCCESS,
  payload: payload,
});

const fetchOperationalMaintenanceListCountSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_LIST_COUNT_SUCCESS,
  payload: payload,
});

const setOperationalMaintenanceListCountLoading = payload => ({
  type: types.SET_OPERATIONAL_MAINTENANCES_LIST_COUNT_LOADING,
  payload: payload,
});

const fetchOperationalMaintenancesForCalendarSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SUCCESS,
  payload: payload,
});

const fetchOperationalMaintenancesForCalendarSplitByAssetsSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SPLIT_BY_ASSETS_SUCCESS,
  payload: payload,
});

const fetchOperationalMaintenancesTemplatesSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_TEMPLATES_SUCCESS,
  payload: payload,
});

const fetchOperationalMaintenancesBreaksSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_BREAKS_SUCCESS,
  payload: payload,
});

export default {
  fetchOperationalMaintenancesSuccess,
  fetchOperationalMaintenanceListCountSuccess,
  setOperationalMaintenanceListCountLoading,
  fetchOperationalMaintenancesForCalendarSplitByAssetsSuccess,
  fetchOperationalMaintenancesTemplatesSuccess,
  fetchOperationalMaintenancesBreaksSuccess,
  toggleExpandAsset,
  fetchOperationalMaintenancesForCalendarSuccess,
  selectOperationalMaintenancesPage,
  selectOperationalMaintenance,
  resetSelectedOperationalMaintenances,
  addQueryParameterForTemplates,
  setNavigatedToOperationalMaintenance,
  addQueryParameterForOperationalMaintenances,
  unitializeListCount,
  resetOperationalMaintenanceTemplatesState,
};
