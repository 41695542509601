import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Request extends Component {
  render() {
    const { comparator, value } = this.props.filter.requests?.request_id;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.request.resource" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Request,
            data: {
              work_order: {
                ...this.props.filter,
                requests: {
                  ...this.props.filter.requests,
                  request_id: {
                    comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
                    value: null,
                  },
                },
              },
            },
          })
        }
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);
