import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, Tooltip, Loader, Icon } from 'views/components/Shared/General';
import { request } from 'sdk/utilities/Axios';
import styles from './style.module.scss';

class ChangeCurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingRate: false,
      selectedCurrency: this.props.currency,
      exchangeRate: this.props.exchangeRate || '',
      currencies: HelperFunctions.getAvailableCurrencies(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        selectedCurrency: this.props.currency,
        exchangeRate: this.props.exchangeRate || '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    this.props.onSave({
      exchangeRate: this.state.exchangeRate,
      currency: this.state.selectedCurrency,
    });
  };

  fetchCurrencyRate = currency => {
    this.setState({ exchangeRate: '', isFetchingRate: true, selectedCurrency: currency });
    request
      .get(
        `systems/${this.props.currentSystem.id}/currency_exchange_rate?from=${currency}&to=${this.props.currentSystem.currency}`
      )
      .then(({ data }) => {
        this.setState({ isFetchingRate: false, exchangeRate: data.rate });
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10003' })) {
          this.setState({ isFetchingRate: false });
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="components.change-purchase-order-currency-modal.error-toast" />}
            />
          );
        }
      });
  };

  selectCurrency = currency => {
    if (currency !== this.state.selectedCurrency) {
      this.fetchCurrencyRate(currency);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={475}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="components.change-purchase-order-currency-modal.title" />}
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <div className={styles['dropdown']}>
                    <Field
                      label={
                        <FormattedMessage id="components.change-purchase-order-currency-modal.currency-label" />
                      }
                    >
                      <Field.Currency value={this.state.selectedCurrency} onChange={this.selectCurrency} />
                    </Field>
                  </div>
                </Grid.Column>
              </Grid.Row>
              {this.props.currentSystem.currency === this.state.selectedCurrency ? null : (
                <Grid.Row>
                  <Grid.Column>
                    <Field
                      view={false}
                      label={
                        <div className={styles['conversion-rate-field']}>
                          <p className={styles['label']}>
                            <FormattedMessage
                              id="components.change-purchase-order-currency-modal.conversion-rate-label"
                              values={{ currency: this.props.currentSystem.currency }}
                            />
                          </p>
                        </div>
                      }
                    >
                      <div className={styles['conversion-rate-container']}>
                        <div className={styles['field']}>
                          <Field.Decimal
                            disabled={this.state.isFetchingRate}
                            value={this.state.exchangeRate}
                            rightLabel={this.state.isFetchingRate ? <Loader tiny /> : null}
                            onBlur={value => this.setState({ exchangeRate: value })}
                          />
                        </div>
                        <div>
                          <Tooltip
                            trigger={
                              <Button
                                icon={<Icon regular type="redo-alt" />}
                                onClick={() => this.fetchCurrencyRate(this.state.selectedCurrency)}
                              />
                            }
                            label={
                              <FormattedMessage id="components.change-purchase-order-currency-modal.rate-tooltip" />
                            }
                          />
                        </div>
                      </div>
                      <p className={styles['conversion-rate-subtitle']}>
                        <span>
                          1 <FormattedMessage id={`currencies.${this.state.selectedCurrency}`} />
                        </span>
                        <span> = </span>
                        <span>
                          {this.state.exchangeRate || 0}{' '}
                          <FormattedMessage id={`currencies.${this.props.currentSystem.currency}`} />
                        </span>
                      </p>
                    </Field>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={
                  this.props.currentSystem.currency !== this.state.selectedCurrency &&
                  !this.state.exchangeRate
                }
                label="general.save"
                onClick={this.save}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(ChangeCurrencyModal);
