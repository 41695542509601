import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon, FormattedNumber, WhiteCard } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import styles from '../style.module.scss';

class Meter extends Component {
  renderMeterContent = () => {
    return (
      <WhiteCard noPadding clickable={this.props.canViewMeters}>
        <div className={styles['meter']}>
          <Field
            icon={<Icon type="tachometer-alt-slow" withBackground blue backgroundSize={36} />}
            label={<FormattedMessage id="resources.work-order.meter" />}
            value={this.props.meter.title}
            subtitle={
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            }
          />
        </div>
      </WhiteCard>
    );
  };
  render() {
    if (this.props.meter == null) {
      return null;
    }
    if (this.props.canViewMeters) {
      return (
        <div className={styles['meter-container']}>
          <Link to={`/meters/${this.props.meter.id}`}>{this.renderMeterContent()}</Link>
        </div>
      );
    }
    return <div className={styles['meter-container']}>{this.renderMeterContent()}</div>;
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    canViewMeters: AuthSelectors.canViewMeters(state),
  };
}

export default connect(mapStateToProps)(Meter);
