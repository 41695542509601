import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listOperationalMaintenanceTemplates = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenance_templates?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );
export const fetchOperationalMaintenanceTemplate = operationalMaintenanceTemplateId =>
  request.get(`operational_maintenance_templates/${operationalMaintenanceTemplateId}`);
export const createOperationalMaintenanceTemplate = (systemId, data) =>
  request.post(`systems/${systemId}/operational_maintenance_templates`, data);
export const deleteOperationalMaintenanceTemplate = templateId =>
  request.delete(`operational_maintenance_templates/${templateId}`);
