import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, EmptyDataSet, Button } from 'views/components/Shared/General';
import SparePartUnitTiny from 'assets/images/EmptyDataSet/SparePartUnitTiny.png';
import styles from './styles.module.scss';

class SparePartTypeField extends Component {
  renderEditingField = () => (
    <Field.Dropdown
      clearable
      value={this.props.sparePartUnit ? this.props.sparePartUnit.title : ''}
      onClear={() => this.props.onChange(null)}
    >
      {this.renderStockUnitItems()}
    </Field.Dropdown>
  );

  renderStockUnitItems = () => {
    if (this.props.sparePartUnits.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.subtitle" />
            }
            button={
              <Button
                primary
                label="screens.spare-part.info.general-information.unit-empty-data-set.button"
                small
                onClick={() => this.props.history.push('/settings/spare-parts/units')}
              />
            }
            image={SparePartUnitTiny}
            tiny
          />
        </div>
      );
    }
    return this.props.sparePartUnits.map(sparePartUnit => (
      <Field.Dropdown.Item
        key={sparePartUnit.id}
        selected={this.props.value === sparePartUnit.id}
        onClick={() => this.props.onChange(sparePartUnit.id)}
      >
        {sparePartUnit.title}
      </Field.Dropdown.Item>
    ));
  };

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return this.props.sparePartUnit ? this.props.sparePartUnit.title : '-';
    }
  };

  render() {
    return (
      <Field view={!this.props.editing} label={<FormattedMessage id="resources.spare-part.unit" />}>
        {this.renderValue()}
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.value),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartTypeField));

SparePartTypeField.propTypes = {
  value: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

SparePartTypeField.defaultProps = {
  editing: false,
};
