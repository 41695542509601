import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { PurchaseOrdersSelectors, PurchaseOrdersOperations } from 'state/ducks/purchaseOrders';
import { FILTER_TYPES } from 'views/scenes/PurchaseOrders/PurchaseOrderList/PurchaseOrders/FilterModal';

class Late extends Component {
  render() {
    const { value } = this.props.filter;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Boolean
          title={<FormattedMessage id="screens.purchase-orders.filters.advanced.purchase-order.late" />}
          value={value}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.Late,
              data: {
                [FILTER_TYPES.Late]: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                  value: null,
                },
              },
            })
          }
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: PurchaseOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: PurchaseOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Late),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Late);
