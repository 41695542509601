import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Title extends Component {
  render() {
    const { title: value } = this.props.filter;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Text
          title={<FormattedMessage id="resources.work-order.title" />}
          comparator={HelperFunctions.FILTER_COMPARABLES.Search}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.WorkOrderTitle,
              data: {
                work_order: {
                  ...this.props.filter,
                  [FILTER_TYPES.WorkOrderTitle]: '',
                },
              },
            })
          }
        >
          <SideModal.Container.Filter.AppliedFilters.Value>
            {value}
          </SideModal.Container.Filter.AppliedFilters.Value>
        </SideModal.Container.Filter.AppliedFilters.Filter.Text>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Title);
