import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List, InlineModal, Button, Icon } from 'views/components/Shared/General';
import { Link } from 'react-router-dom';

class AssetVendorListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          clickable
          onClick={this.props.onClick}
          new={this.props.new}
          iconButtons={
            <>
              <div
                ref={ref => (this.inlineModalPositioningRef = ref)}
                onClick={() => {
                  this.setState(prevState => ({
                    dropdownOpen: !prevState.dropdownOpen,
                  }));
                }}
              >
                <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
              </div>
              <InlineModal
                positionToRef={this.inlineModalPositioningRef}
                open={this.state.dropdownOpen}
                onClose={() => this.setState({ dropdownOpen: false })}
                position="right"
              >
                <InlineModal.Body width={250} dropdown>
                  <Link to={`/vendors/${this.props.vendor.id}`}>
                    <InlineModal.ListItem
                      icon="arrow-alt-right"
                      iconThickness="regular"
                      title={<FormattedMessage id="screens.asset.vendors.go-to-vendor" />}
                    />
                  </Link>
                  <InlineModal.ListItem
                    icon="trash-alt"
                    iconThickness="regular"
                    destructive
                    title={<FormattedMessage id="general.delete" />}
                    onClick={() => {
                      this.props.onDelete();
                      this.setState({ dropdownOpen: false });
                    }}
                  />
                </InlineModal.Body>
              </InlineModal>
            </>
          }
        >
          <List.Item.TitleColumn title={this.props.vendor.name} subtitle={this.props.assetVendor.comment} />
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (!ownProps.assetVendorId) return {};

  const assetVendor = EntitySelectors.getAssetVendor(state, ownProps.assetVendorId);
  return {
    assetVendor,
    vendor: EntitySelectors.getVendor(state, assetVendor.vendor_id),
  };
}

export default connect(mapStateToProps)(AssetVendorListItem);

AssetVendorListItem.propTypes = {
  onClick: PropTypes.func,
  assetVendor: PropTypes.object,
  loading: PropTypes.bool,
  new: PropTypes.bool,
  buttons: PropTypes.node,
};
AssetVendorListItem.defaultProps = {
  onClick: () => {},
  loading: false,
  new: false,
};
