import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import uuid from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { DragOverlay } from '@dnd-kit/core';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { NewTab, Button, Icon, Loader } from 'views/components/Shared/General';
import QrTemplateEditor from '../QrTemplateEditor';
import { RowType } from 'sdk/QRTemplateRow';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import DndContextContainer from './DndContextContainer';
import NewField from './NewField';
import NewFieldDraggableContainer from './NewFieldDraggableContainer';
import QRTemplateRow from '../QrTemplateEditor/QrTemplateColumn/QrTemplateRow';
import { QrTemplateSettings, QrTemplateRowSettings, QrTemplateColumnSettings } from './Tabs';
import { SDKReduxOperations } from 'sdk';
import EditTitleModal from './EditTitleModal';
import TestPrintQrCodeModal from './TestPrintQrCodeModal';
import { AuthSelectors } from 'state/ducks/auth';

const Tabs = {
  Fields: 'fields',
  Settings: 'settings',
  QrTemplateRowSettings: 'qrTemplateRowSettings',
  QrTemplateColumnSettings: 'QrTemplateColumnSettings',
};

class EditQrTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
    this.debouncedOnHideSaveContainer = debounce(() => {
      setTimeout(() => {
        this.setState({ showSaveContainer: false });
      }, 1250);
    }, 600);
  }

  getInititalState = () => ({
    selectedTab: Tabs.Fields,
    showQrTemplateRowSettingsForQrTemplateRowId: null,
    showQrTemplateColumnSettingsForQrTemplateColumnId: null,
    showTestPrintQrCodeModal: false,
    columns: [],
    rows: {},
    draggableSettingsForColumns: {},
    widthsForColumns: {},
    draggingQrTemplateRowId: null,
    draggingNewQrTemplateRowType: null,
    draggingNewQrTemplateTitle: null,
    showSaveContainer: false,
    isSaving: false,
    isCreatingNewRowForColumnId: null,
    isCreatingNewRowForIndexId: null,
    isLoadingNewRowForColumnId: null,
    isLoadingNewRowForIndexId: null,
    draggableFields: this.generateNewFieldsWithUniqIds(),
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let widthsForColumns = {};
      let rows = {};
      let draggableSettingsForColumns = {};
      this.props.qrTemplateColumns.forEach(column => {
        const { id, qr_template_rows, vertical_align, width } = column;
        widthsForColumns = {
          ...widthsForColumns,
          [id]: width,
        };
        rows = {
          ...rows,
          [id]: qr_template_rows,
        };
        draggableSettingsForColumns = {
          ...draggableSettingsForColumns,
          [id]: {
            verticalAlign: vertical_align,
          },
        };
      });
      this.setState({
        ...this.getInititalState(),
        columns: this.props.qrTemplateColumns.map(({ id }) => id),
        rows,
        draggableSettingsForColumns,
        widthsForColumns,
      });
    }
  }

  getQrTemplateRowFieldsForAsset = () => {
    let fields = [
      {
        key: uuid.v4(),
        row_type: RowType.Qr,
      },
      {
        key: uuid.v4(),
        row_type: RowType.AssetTitle,
      },
    ];
    if (this.props.settings.asset_number_activated) {
      fields = [
        ...fields,
        {
          key: uuid.v4(),
          row_type: RowType.AssetNumber,
        },
      ];
    }
    fields = [
      ...fields,
      {
        key: uuid.v4(),
        row_type: RowType.AssetType,
      },
      ...this.props.assetTemplateFields?.map(({ title, id }) => {
        return {
          key: uuid.v4(),
          id,
          row_type: RowType.TemplateField,
          template_field_id: id,
          title,
        };
      }),
    ];
    return fields;
  };

  getQrTemplateRowFieldsForSparePart = () => {
    return [
      {
        key: uuid.v4(),
        row_type: RowType.Qr,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartTitle,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartArticleNumber,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartDescription,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartLocation,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartType,
      },
      {
        key: uuid.v4(),
        row_type: RowType.SparePartUnit,
      },
      ...this.props.sparePartTemplateFields?.map(({ title, id }) => {
        return {
          key: uuid.v4(),
          id,
          row_type: RowType.TemplateField,
          template_field_id: id,
          title,
        };
      }),
    ];
  };

  generateNewFieldsWithUniqIds = () => {
    if (this.props.templateType === 'asset') {
      return this.getQrTemplateRowFieldsForAsset();
    }
    return this.getQrTemplateRowFieldsForSparePart();
  };

  onDragStart = event => {
    const { active } = event;
    const { id } = active;
    const { containerId } = active?.data?.current?.sortable;

    const { row_type, title } = active?.data?.current;
    if (containerId === 'Sortable') {
      this.setState({
        draggingQrTemplateRowId: null,
        draggingNewQrTemplateRowType: row_type,
        draggingNewQrTemplateTitle: title,
      });
    } else {
      this.setState({ draggingQrTemplateRowId: id, draggingNewQrTemplateRowType: null });
    }
  };

  onDragOver = event => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;
    const activeContainer = this.findContainer(id);
    const overContainer = this.findContainer(overId);
    let activeIndex = null;
    let overIndex = null;
    let activeItems = [];
    let overItems = [];

    if (!overContainer) {
      return;
    }
    if (activeContainer) {
      activeItems = this.state.rows[activeContainer];
      activeIndex = activeItems.indexOf(id);
    }
    overItems = this.state.rows[overContainer];
    overIndex = overItems.indexOf(overId);
    let newIndex;
    if (this.state.columns.includes(overId)) {
      if (this.state.draggableSettingsForColumns[overContainer].verticalAlign === 'bottom') {
        newIndex = 0;
      } else {
        newIndex = overItems.length;
      }
    } else {
      const isBelowLastItem =
        over &&
        overIndex === overItems.length - 1 &&
        active.rect?.current?.translated?.top > over.rect.top + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    }
    const isDraggingNewField = activeContainer == null;
    if (isDraggingNewField) {
      const { row_type, template_field_id } = active?.data?.current;
      this.onDragNewFieldToColumn({
        id,
        toColumn: overContainer,
        toIndex: newIndex,
        row_type,
        template_field_id,
      });
      return;
    }
    if (activeContainer == overContainer && overIndex !== -1 && id !== overId) {
      this.onDropInSameColumn({
        id,
        fromIndex: activeIndex,
        toIndex: newIndex,
        toColumn: overContainer,
      });
    } else if (activeContainer != overContainer) {
      if (newIndex > this.state.rows[overContainer].length) {
        newIndex = this.state.rows[overContainer].length;
      }
      this.onDragBetweenColumns({
        id,
        fromIndex: activeIndex,
        toIndex: newIndex,
        fromColumn: activeContainer,
        toColumn: overContainer,
      });
    }
  };

  onDragEnd = dragData => {
    const { active } = dragData;
    const { id } = active;
    const activeContainer = this.findContainer(id);
    const isOverColumn = activeContainer != null;
    if (isOverColumn === false) {
      return;
    }
    if (this.state.draggingNewQrTemplateRowType) {
      const params = {
        row_type: this.state.isCreatingNewRowForRowType,
        template_field_id: this.state.isCreatingNewRowForTemplateFieldId,
        sort: this.state.isCreatingNewRowForIndexId + 1,
        qr_template_column_id: this.state.isCreatingNewRowForColumnId,
        font_size: 14,
        qr_code_size: 40,
        padding_top: 0,
        padding_bottom: 0,
        padding_left: 0,
        padding_right: 0,
      };
      let column = this.state.isCreatingNewRowForColumnId;
      let index = this.state.isCreatingNewRowForIndexId;
      this.setState(
        update(this.state, {
          draggingQrTemplateRowId: {
            $set: null,
          },
          isCreatingNewRowForColumnId: {
            $set: null,
          },
          isCreatingNewRowForIndexId: {
            $set: null,
          },
          isCreatingNewRowForRowType: {
            $set: null,
          },
          isCreatingNewRowForTemplateFieldId: {
            $set: null,
          },
          isLoadingNewRowForColumnId: {
            $set: column,
          },
          isLoadingNewRowForIndexId: {
            $set: index,
          },
          draggingNewQrTemplateRowType: {
            $set: null,
          },
          draggingNewQrTemplateTitle: {
            $set: null,
          },
          draggableFields: {
            $set: this.generateNewFieldsWithUniqIds(),
          },
          rows: {
            [column]: {
              $splice: [[index, 1, 'loading']],
            },
          },
        }),
        () => {
          column = this.state.isLoadingNewRowForColumnId;
          index = this.state.isLoadingNewRowForIndexId;

          this.props.createQrTemplateRow(this.props.qrTemplate.id, params).then(({ data }) => {
            this.setState(
              update(this.state, {
                isLoadingNewRowForColumnId: {
                  $set: null,
                },
                isLoadingNewRowForColumnId: {
                  $set: null,
                },
                rows: {
                  [column]: {
                    $splice: [[index, 1, data.id]],
                  },
                },
              })
            );
          });
        }
      );
    }
  };

  findContainer = id => {
    if (this.state.columns.find(loopedId => loopedId == id) != null) {
      return id;
    }

    return this.state.columns.find(key => this.state.rows[key].includes(id));
  };

  onDragNewFieldToColumn = data => {
    const { id, toIndex, toColumn, row_type, template_field_id } = data;
    this.setState(
      update(this.state, {
        isCreatingNewRowForColumnId: {
          $set: toColumn,
        },
        isCreatingNewRowForIndexId: {
          $set: toIndex,
        },
        isCreatingNewRowForRowType: {
          $set: row_type,
        },
        isCreatingNewRowForTemplateFieldId: {
          $set: template_field_id,
        },
        rows: {
          [toColumn]: {
            $splice: [[toIndex, 0, id]],
          },
        },
      })
    );
  };

  onDragBetweenColumns = data => {
    const { id, fromIndex, toIndex, toColumn, fromColumn } = data;

    this.setState(
      update(this.state, {
        isCreatingNewRowForIndexId: {
          $set: toIndex,
        },
        isCreatingNewRowForColumnId: {
          $set: toColumn,
        },
        rows: {
          [fromColumn]: { $splice: [[fromIndex, 1]] },
          [toColumn]: {
            $splice: [[toIndex, 0, id]],
          },
        },
      })
    );
    if (this.state.draggingQrTemplateRowId) {
      this.props.updateQrTemplateRow(id, {
        qr_template_column_id: toColumn,
        sort: toIndex + 1,
      });
    }
  };

  onDropInSameColumn = data => {
    const { id, fromIndex, toIndex, toColumn } = data;
    this.setState(
      update(this.state, {
        isCreatingNewRowForIndexId: {
          $set: toIndex,
        },
        isCreatingNewRowForColumnId: {
          $set: toColumn,
        },
        rows: {
          [toColumn]: {
            $splice: [
              [fromIndex, 1],
              [toIndex, 0, id],
            ],
          },
        },
      })
    );
    if (this.state.draggingQrTemplateRowId) {
      this.props.updateQrTemplateRow(id, {
        sort: toIndex + 1,
      });
    }
  };

  createAndUpdateNeighboringQrTemplateColumn = () => {
    const { id, width } = this.props.qrTemplateColumns.map(column => column)[
      this.props.qrTemplateColumns.length - 1
    ];
    Promise.all([
      this.props.updateQrTemplateColumn(id, { width: Math.ceil(width / 2) }),
      this.props.createQrTemplateColumn(this.props.qrTemplateId, {
        width: Math.floor(width / 2),
        padding_bottom: 0,
        padding_top: 0,
        padding_left: 0,
        padding_right: 0,
      }),
    ]).then(([{ data: updatedQrColumn }, { data: createdQrColumn }]) => {
      this.setState({
        widthsForColumns: {
          ...this.state.widthsForColumns,
          [updatedQrColumn.id]: updatedQrColumn.width,
          [createdQrColumn.id]: createdQrColumn.width,
          id: Math.ceil(width / 2),
        },
        draggableSettingsForColumns: {
          ...this.state.draggableSettingsForColumns,
          [createdQrColumn.id]: {
            verticalAlign: createdQrColumn.vertical_align,
          },
        },
        columns: [...this.state.columns, createdQrColumn.id],
        rows: {
          ...this.state.rows,
          [createdQrColumn.id]: [],
        },
        isAddingColumn: false,
      });
    });
  };

  createQrTemplateColumn = () => {
    this.props
      .createQrTemplateColumn(this.props.qrTemplateId, {
        width: 100,
        padding_bottom: 0,
        padding_top: 0,
        padding_left: 0,
        padding_right: 0,
      })
      .then(({ data }) => {
        this.setState({
          isAddingColumn: false,
          widthsForColumns: {
            ...this.state.widthsForColumns,
            [data.id]: data.width,
          },
          draggableSettingsForColumns: {
            ...this.state.draggableSettingsForColumns,
            [data.id]: {
              verticalAlign: data.vertical_align,
            },
          },
          columns: [...this.state.columns, data.id],
          rows: {
            ...this.state.rows,
            [data.id]: [],
          },
        });
      });
  };

  renderEditingQrTemplate = () => {
    return (
      <QrTemplateEditor
        qrTemplateId={this.props.qrTemplateId}
        selectedTemplateColumnId={this.state.showQrTemplateColumnSettingsForQrTemplateColumnId}
        selectedTemplateRowId={this.state.showQrTemplateRowSettingsForQrTemplateRowId}
        columns={this.state.columns}
        rows={this.state.rows}
        widthsForColumns={this.state.widthsForColumns}
        onSelectRow={id => {
          if (this.state.showQrTemplateRowSettingsForQrTemplateRowId === id) {
            this.setState({
              selectedTab: Tabs.Fields,
              showQrTemplateRowSettingsForQrTemplateRowId: null,
              showQrTemplateRowSettingsForQrTemplateRowId: null,
            });
          } else {
            this.setState({
              selectedTab: Tabs.QrTemplateRowSettings,
              showQrTemplateRowSettingsForQrTemplateRowId: id,
              showQrTemplateColumnSettingsForQrTemplateColumnId: null,
            });
          }
        }}
        onSelectColumn={id => {
          if (this.state.showQrTemplateColumnSettingsForQrTemplateColumnId === id) {
            this.setState({
              selectedTab: Tabs.Fields,
              showQrTemplateColumnSettingsForQrTemplateColumnId: null,
              showQrTemplateRowSettingsForQrTemplateRowId: null,
            });
          } else {
            this.setState({
              selectedTab: Tabs.QrTemplateColumnSettings,
              showQrTemplateColumnSettingsForQrTemplateColumnId: id,
              showQrTemplateRowSettingsForQrTemplateRowId: null,
            });
          }
        }}
      />
    );
  };

  renderTabs = () => {
    if ([Tabs.Fields, Tabs.Settings].includes(this.state.selectedTab)) {
      return (
        <div className={styles['tabs-container']}>
          <NewTab.Container>
            <NewTab
              selected={this.state.selectedTab === Tabs.Fields}
              onClick={() => this.setState({ selectedTab: Tabs.Fields })}
            >
              <FormattedMessage id="components.print-qr-codes.tabs.add-fields" />
            </NewTab>
            <NewTab
              selected={this.state.selectedTab === Tabs.Settings}
              onClick={() => this.setState({ selectedTab: Tabs.Settings })}
            >
              <FormattedMessage id="components.print-qr-codes.tabs.settings" />
            </NewTab>
          </NewTab.Container>
        </div>
      );
    }
    return null;
  };

  renderFieldsContent = () => {
    return (
      <>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="components.print-qr-codes.fields-tab.fields-to-add" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="components.print-qr-codes.fields-tab.drag-and-drop" />
          </div>
        </div>
        {this.state.draggableFields.map(({ key, row_type, title, template_field_id }) => (
          <NewFieldDraggableContainer
            title={title}
            rowType={row_type}
            templateFieldId={template_field_id}
            key={key}
            id={key}
          >
            <NewField title={title} rowType={row_type} key={key} id={key} />
          </NewFieldDraggableContainer>
        ))}
      </>
    );
  };

  renderTabsContent = () => {
    switch (this.state.selectedTab) {
      case Tabs.Fields: {
        return this.renderFieldsContent();
      }
      case Tabs.Settings: {
        return (
          <QrTemplateSettings
            qrTemplateId={this.props.qrTemplateId}
            widthsForColumns={this.state.widthsForColumns}
            onShowSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: true });
            }}
            onHideSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: false });
              this.debouncedOnHideSaveContainer();
            }}
            onChangeColumnWidths={params => {
              this.setState({
                widthsForColumns: {
                  ...this.state.widthsForColumns,
                  ...params,
                },
              });
            }}
          />
        );
      }
      case Tabs.QrTemplateRowSettings: {
        return (
          <QrTemplateRowSettings
            qrTemplateRowId={this.state.showQrTemplateRowSettingsForQrTemplateRowId}
            onShowSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: true });
            }}
            onHideSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: false });
              this.debouncedOnHideSaveContainer();
            }}
            onDeleteRow={(columnId, rowId) => {
              this.setState({
                rows: {
                  ...this.state.rows,
                  [columnId]: this.state.rows[columnId].filter(id => id !== rowId),
                },
              });
            }}
            onClose={() => {
              this.setState({
                selectedTab: Tabs.Fields,
                showQrTemplateRowSettingsForQrTemplateRowId: null,
              });
            }}
          />
        );
      }
      case Tabs.QrTemplateColumnSettings: {
        return (
          <QrTemplateColumnSettings
            qrTemplateColumnId={this.state.showQrTemplateColumnSettingsForQrTemplateColumnId}
            widthsForColumns={this.state.widthsForColumns}
            onClose={() =>
              this.setState({
                selectedTab: Tabs.Fields,
                showQrTemplateColumnSettingsForQrTemplateColumnId: null,
              })
            }
            onShowSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: true });
            }}
            onHideSaveContainer={() => {
              this.setState({ showSaveContainer: true, isSaving: false });
              this.debouncedOnHideSaveContainer();
            }}
            onChangeColumnVerticalAlign={(columnId, verticalAlign) => {
              this.setState({
                draggableSettingsForColumns: {
                  ...this.state.draggableSettingsForColumns,
                  [columnId]: {
                    verticalAlign,
                  },
                },
              });
            }}
            onChangeColumnWidths={params => {
              this.setState({
                widthsForColumns: {
                  ...this.state.widthsForColumns,
                  ...params,
                },
              });
            }}
            onDeleteQrTemplateColumn={columnId => {
              this.setState({
                columns: this.state.columns.filter(id => id !== columnId),
              });
            }}
          />
        );
      }

      default:
        return null;
    }
  };

  renderDraggingItem() {
    if (this.state.draggingQrTemplateRowId) {
      return <QRTemplateRow preview={false} id={this.state.draggingQrTemplateRowId} />;
    }
    if (this.state.draggingNewQrTemplateRowType) {
      return (
        <NewField
          rowType={this.state.draggingNewQrTemplateRowType}
          title={this.state.draggingNewQrTemplateTitle}
        />
      );
    }
    return null;
  }

  renderAddColumnButton = () => {
    return (
      <div className={styles['add-button-container']}>
        <Button
          gray
          loading={this.state.isAddingColumn}
          label="components.print-qr-codes.fields-tab.add-column"
          onClick={() => {
            this.setState({ isAddingColumn: true });
            if (this.props.qrTemplateColumns.length > 0) {
              this.createAndUpdateNeighboringQrTemplateColumn();
            } else {
              this.createQrTemplateColumn();
            }
          }}
        />
      </div>
    );
  };

  renderSaveContainerContent = () => {
    if (this.state.isSaving) {
      return (
        <div className={styles['changes-saved-container']}>
          <div className={styles['text']}>
            <FormattedMessage id="components.print-qr-codes.is-saving" />
          </div>
          <div>
            <Loader tiny inline />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['changes-saved-container']}>
        <div className={styles['text']}>
          <FormattedMessage id="components.print-qr-codes.save-success" />
        </div>
        <div>
          <Icon regular green size={14} type="check" />
        </div>
      </div>
    );
  };

  renderSaveContainer = () => {
    let classNames = [styles['save-container']];
    if (this.state.showSaveContainer) {
      classNames = [...classNames, styles['show']];
    }
    if (this.state.isSaving === false) {
      classNames = [...classNames, styles['success']];
    }
    return <div className={classNames.join(' ')}>{this.renderSaveContainerContent()}</div>;
  };

  renderContent = () => {
    return (
      <DndContextContainer
        onDragStart={this.onDragStart}
        onDragOver={this.onDragOver}
        onDragEnd={this.onDragEnd}
        onDragCancel={() => {
          this.setState({
            draggingQrTemplateRowId: null,
            draggingNewQrTemplateRowType: null,
            draggingNewQrTemplateTitle: null,
          });
        }}
      >
        <div className={styles['container']}>
          <div className={styles['left-view']}>
            {this.renderEditingQrTemplate()}
            {this.renderAddColumnButton()}
          </div>
          <div className={styles['right-view-container']}>
            <PerfectScrollbar>
              <div className={styles['right-view']}>
                {this.renderTabs()}
                {this.renderTabsContent()}
              </div>
            </PerfectScrollbar>
            {this.renderSaveContainer()}
          </div>
        </div>
        <DragOverlay
          style={{
            marginLeft: -(window.innerWidth - 1200) / 2,
            marginTop: -50,
          }}
        >
          {this.renderDraggingItem()}
        </DragOverlay>
      </DndContextContainer>
    );
  };

  renderEditTitleModal = () => {
    return (
      <EditTitleModal
        open={this.state.showTitleModal}
        qrTemplateId={this.props.qrTemplateId}
        onClose={() => this.setState({ showTitleModal: false })}
      />
    );
  };

  renderTestPrintQrCodeModal = () => {
    return (
      <TestPrintQrCodeModal
        open={this.state.showTestPrintQrCodeModal}
        printQrCodeParams={{
          ...this.props.printQrCodeParams,
          params: {
            ...this.props.printQrCodeParams.params,
            qr_template_id: this.props.qrTemplateId,
          },
        }}
        onClose={() => this.setState({ showTestPrintQrCodeModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={1200} fullHeight>
          <Modal.Header
            ignoreLine
            title={
              <div className={styles['modal-title-container']}>
                <div className={styles['left']}>
                  <div className={styles['modal-title']}>{this.props.qrTemplate?.title}</div>
                  <div>
                    <Button
                      type="icon"
                      icon={<Icon regular type="pen" />}
                      onClick={() => this.setState({ showTitleModal: true })}
                    />
                  </div>
                </div>
                <div className={styles['right']}>
                  <Button
                    gray
                    label="components.print-qr-codes.test-print-pdf"
                    onClick={() => this.setState({ showTestPrintQrCodeModal: true })}
                  />
                </div>
              </div>
            }
            onClose={this.props.onClose}
          />
          <Modal.Content noPadding>{this.renderContent()}</Modal.Content>
        </Modal>
        {this.renderEditTitleModal()}
        {this.renderTestPrintQrCodeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplateRow: SDKReduxOperations.updateQrTemplateRow,
      createQrTemplateRow: SDKReduxOperations.createQrTemplateRow,
      createQrTemplateColumn: SDKReduxOperations.createQrTemplateColumn,
      updateQrTemplateColumn: SDKReduxOperations.updateQrTemplateColumn,
      updateQrTemplate: SDKReduxOperations.updateQrTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateId } = ownProps;
  const qrTemplate = EntitySelectors.getQrTemplate(state, qrTemplateId);
  let qrTemplateColumns = [];
  if (qrTemplate) {
    qrTemplateColumns = EntitySelectors.getQrTemplateColumns(state, qrTemplate.qr_template_columns);
  }

  return {
    qrTemplate: EntitySelectors.getQrTemplate(state, qrTemplateId),
    qrTemplateColumns,
    assetTemplateFields: AuthSelectors.getAssetTemplateFields(state),
    sparePartTemplateFields: AuthSelectors.getSparePartTemplateFields(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditQrTemplateModal);
