import React, { Component } from 'react';
import { PreviewOverlay } from 'views/components/Shared/General';
import { PreviewRequestContent } from 'views/components/Request';
import PreviewRequestHeader from './PreviewRequestHeader';
import styles from './style.module.scss';

export default class PreviewRequestOverlay extends Component {
  render() {
    return (
      <PreviewOverlay
        open={this.props.open}
        top={this.props.top}
        left={this.props.left}
        extraWidth={this.props.extraWidth}
        width={600}
        onClose={this.props.onClose}
      >
        <div className={styles['preview-request']}>
          <PreviewRequestHeader id={this.props.id} onClose={this.props.onClose} />
          <PreviewRequestContent
            editable={this.props.editable}
            id={this.props.id}
            onClose={this.props.onClose}
            onCreateWorkOrder={params => this.props.onCreateWorkOrder(params)}
          />
        </div>
      </PreviewOverlay>
    );
  }
}
