import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeRequest } from 'sdk/Schemas';
import {
  groupRequestsByWorkOrderDueDate,
  groupRequestsByWorkOrderCompletedDate,
  groupRequestsByArchivedDate,
  groupRequestsByCreatedDate,
} from 'sdk/Request';
import { API, HelperFunctions } from 'sdk';
import { LIST_TYPES } from 'sdk/Request';

const selectPage = actions.selectPage;
const resetSelectedRequests = actions.resetSelectedRequests;
const selectRequest = actions.selectRequest;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;
const initializeView = actions.initializeView;
const setLoading = actions.setLoading;
const setGroupBy = actions.setGroupBy;

const prepareNewRequest = actions.prepareNewRequest;
const cancelNewRequest = actions.cancelNewRequest;

const setRequestForEdit = actions.setRequestForEdit;
const setRequestForView = actions.setRequestForView;

const setEditingRequestValues = actions.setEditingRequestValues;

const fetchRequest = requestId => dispatch => {
  return API.fetchRequest(requestId).then(res => {
    const { entities } = normalizeRequest(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.setRequestForView(requestId));
    return res.data;
  });
};

const fetchRequestsForList = (systemId, list, params, config) => dispatch => {
  dispatch(actions.fetchRequestsForList());
  return API.listRequests(systemId, params, config)
    .then(res => {
      const requests = res.data;
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      const { entities, result } = normalizeRequest(requests);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchRequestsForListSuccess({
          ids: result,
          pagination,
        })
      );
    })
    .catch(e => {});
};

const fetchRequestsForInbox = (systemId, list, params, config) => dispatch => {
  dispatch(actions.fetchRequestsForInbox());
  return API.listRequests(systemId, { ...params, page_size: 20 }, config)
    .then(res => {
      const requests = res.data;
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      const amountOfRequests = requests.length;
      const { entities, result } = normalizeRequest(requests);
      dispatch(EntityOperations.updateEntities(entities));
      let inboxSections = [];
      switch (list) {
        case LIST_TYPES.WorkOrderCreated: {
          inboxSections = groupRequestsByWorkOrderDueDate(result, entities);
          break;
        }
        case LIST_TYPES.WorkOrderCompleted: {
          inboxSections = groupRequestsByWorkOrderCompletedDate(result, entities);
          break;
        }
        case LIST_TYPES.Archive: {
          inboxSections = groupRequestsByArchivedDate(result, entities);
          break;
        }
        case LIST_TYPES.MyCreated: {
          inboxSections = groupRequestsByCreatedDate(result, entities);
          break;
        }
        case LIST_TYPES.CreatedForAsset: {
          inboxSections = groupRequestsByCreatedDate(result, entities);
          break;
        }
        default: {
          inboxSections = groupRequestsByCreatedDate(result, entities);
          break;
        }
      }

      dispatch(
        actions.fetchRequestsForInboxSuccess({
          pagination,
          inboxSections,
          amountOfRequests,
        })
      );
    })
    .catch(e => {});
};

const fetchMoreRequestsForInbox = (systemId, params, list) => dispatch => {
  dispatch(actions.fetchMoreRequestsForInbox());
  return API.listRequests(systemId, { ...params, page_size: 20 })
    .then(res => {
      const requests = res.data;
      const { paginateFrom } = HelperFunctions.getPaginationFromHeader(res.headers);
      const amountOfRequests = requests.length;
      const { entities, result } = normalizeRequest(requests);

      dispatch(EntityOperations.updateEntities(entities));
      let inboxSections = [];
      switch (list) {
        case LIST_TYPES.WorkOrderCreated: {
          inboxSections = groupRequestsByWorkOrderDueDate(result, entities);
          break;
        }
        case LIST_TYPES.WorkOrderCompleted: {
          inboxSections = groupRequestsByWorkOrderCompletedDate(result, entities);
          break;
        }
        case LIST_TYPES.Archive: {
          inboxSections = groupRequestsByArchivedDate(result, entities);
          break;
        }
        case LIST_TYPES.MyCreated: {
          inboxSections = groupRequestsByCreatedDate(result, entities);
          break;
        }
        default: {
          inboxSections = groupRequestsByCreatedDate(result, entities);
          break;
        }
      }
      dispatch(
        actions.fetchMoreRequestsForInboxSuccess({
          inboxSections,
          amountOfRequests,
          paginateFrom,
        })
      );
    })
    .catch(e => {});
};

export default {
  resetSelectedRequests,
  selectPage,
  selectRequest,
  selectTotalEntries,
  hideSelectTotalEntries,
  addQueryParameter,
  resetFilter,
  showAppliedFilters,
  showAvailableFilters,
  selectFilterType,
  addFilter,
  removeFilter,
  setLoading,
  setGroupBy,
  cancelNewRequest,
  initializeView,
  setRequestForView,
  setRequestForEdit,
  fetchRequest,
  fetchRequestsForInbox,
  fetchRequestsForList,
  fetchMoreRequestsForInbox,
  prepareNewRequest,
  setEditingRequestValues,
};
