import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { Menu, Button, Icon } from 'views/components/Shared/General';
import { AssetTitle } from 'views/components/Asset';
import styles from './style.module.scss';

class AssetMenuItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <>
          <Menu.Item loading />
        </>
      );
    } else {
      return (
        <Menu.Item
          title={<AssetTitle id={this.props.asset.id} />}
          onClick={() => this.props.onSelect(this.props.asset.id)}
          onDoubleClick={this.props.asset.tree_has_children ? this.props.onClick : null}
          selected={this.props.selected}
          subtitle={this.props.subtitle}
          rightComponent={
            this.props.asset.tree_has_children && !this.props.searchedAsset ? (
              <div className={styles['menu-button']}>
                <Button
                  type="icon"
                  iconButtonSize={28}
                  icon={<Icon regular type="angle-right" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.props.onClick();
                  }}
                />
              </div>
            ) : null
          }
        />
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const asset = EntitySelectors.getAsset(state, ownProps.id);
  return {
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(AssetMenuItem));
