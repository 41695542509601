import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { InlineModal, Button, WarningMessage, Icon } from 'views/components/Shared/General';

class Menu extends Component {
  state = {
    dropdownOpen: false,
    dropdownMode: 'list',
    isDeleting: false,
    showHasPurchaseOrdersError: false,
  };

  deleteVendor = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteVendor(this.props.match.params.id)
      .then(() => {
        this.props.history.push('/vendors');
      })
      .catch(e => {
        this.setState({ isDeleting: false });
        if (HelperFunctions.hasError(e, { code: '40002' })) {
          this.setState({ showHasPurchaseOrdersError: true });
        }
      });
  };

  renderContent = () => {
    if (this.state.showHasPurchaseOrdersError) {
      return (
        <WarningMessage type="destructive">
          <FormattedMessage id="screens.vendor.delete-confirm.has-purchase-orders-error" />
        </WarningMessage>
      );
    }
    return (
      <InlineModal.Delete
        loading={this.state.isDeleting}
        onDelete={() => {
          this.deleteVendor();
        }}
      >
        <FormattedMessage id="screens.vendor.delete-confirm.message" />
      </InlineModal.Delete>
    );
  };

  renderInlineModalContent = () => {
    switch (this.state.dropdownMode) {
      case 'list':
        return (
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              icon="trash-alt"
              iconThickness="regular"
              iconColor="#C55050"
              destructive
              title={<FormattedMessage id="general.delete" />}
              onClick={() => {
                this.setState({ dropdownMode: 'delete' });
              }}
            />
          </InlineModal.Body>
        );
      case 'delete':
        return (
          <React.Fragment>
            <InlineModal.Header
              width={300}
              title={<FormattedMessage id="screens.vendor.delete-confirm.title" />}
              onClose={() => {
                this.setState({ dropdownOpen: false });
              }}
            />
            <InlineModal.Body width={300}>{this.renderContent()}</InlineModal.Body>
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
              dropdownMode: 'list',
              showHasPurchaseOrdersError: false,
            }));
          }}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          {this.renderInlineModalContent()}
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteVendor: SDKReduxOperations.deleteVendor,
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(Menu));
