import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { Icon, Button, Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

class BookmarkedAssetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRemovingAssetFromBookmarks: false,
      isHoveringIcon: false,
    };
  }

  deleteAssetBookmark = () => {
    this.setState({ isRemovingAssetFromBookmarks: true });
    this.props.deleteAssetBookmark(this.props.id).then(() => {
      this.props.onRemoveFromBookmark({
        assetId: this.props.asset.id,
        assetBookmarkId: this.props.id,
      });
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
    });
  };

  render() {
    return (
      <div className={styles['item']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <AssetTitle id={this.props.asset.id} />
          </div>
          <div>
            <TreePath assetId={this.props.asset.id} />
          </div>
        </div>
        <div className={styles['icon-container']}>
          {this.state.isRemovingAssetFromBookmarks ? (
            <Loader tiny centerInParent />
          ) : (
            <Button
              type="icon"
              icon={<Icon regular red type="trash-alt" />}
              onClick={() => this.deleteAssetBookmark()}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAssetBookmark: SDKReduxOperations.deleteAssetBookmark,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const { asset_id } = EntitySelectors.getAssetBookmark(state, id);
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    asset: EntitySelectors.getAsset(state, asset_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkedAssetItem);
