import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { AttachmentFolder } from 'views/components/Attachment';
import styles from './style.module.scss';

class AttachmentFolders extends Component {
  render() {
    if (this.props.attachmentFolders.length === 0) return null;
    return (
      <div className={`${styles['folders-container']}`}>
        <p className={styles['title']}>
          <FormattedMessage id="components.asset-attachment-content.folders-title" />
        </p>
        <div className={styles['folders']}>
          {this.props.attachmentFolders.map(attachmentFolder => (
            <AttachmentFolder
              attachmentFolder={attachmentFolder}
              attachmentCount={this.props.attachmentCountPerFolderId[attachmentFolder.id] || 0}
              onClick={() => this.props.onSelect(attachmentFolder)}
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentFolders: EntitySelectors.getAttachmentFoldersForFolder(state, ownProps.parentFolderId),
  };
}

export default connect(mapStateToProps)(AttachmentFolders);
