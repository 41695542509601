import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { List, Button, Icon, Tooltip } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class RequestSelectableAssigneeListItem extends Component {
  renderUserListItem = () => {
    return (
      <List.Item small clickable onClick={() => this.props.onSelect()}>
        <List.Item.Column width={30}>
          <ProfilePicture
            size={30}
            userId={this.props.user.id}
            defaultElement={
              <div className={styles['icon-container']}>
                <Icon type="user" />
              </div>
            }
          />
        </List.Item.Column>
        <List.Item.TitleColumn
          title={<UserNameWrapper user={this.props.user} />}
          subtitle={this.props.requestSelectableAssignee.comment}
        />
      </List.Item>
    );
  };

  renderGroupListItem = () => {
    return (
      <List.Item small clickable onClick={() => this.props.onSelect()}>
        <List.Item.Column width={30}>
          <div className={styles['icon-container']}>
            <Icon type="users" />
          </div>
        </List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.group.title}
          subtitle={this.props.requestSelectableAssignee.comment}
        />
        <List.Item.Column alignRight>
          <Button.Group>
            <Tooltip
              trigger={
                <div>
                  <Button
                    type="icon"
                    icon={<Icon regular type="user" />}
                    onClick={e => {
                      e.stopPropagation();
                      this.props.onManageMembers();
                    }}
                  />
                </div>
              }
              label={
                <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.manage-members" />
              }
            />
          </Button.Group>
        </List.Item.Column>
      </List.Item>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    if (this.props.user) {
      return this.renderUserListItem();
    }
    return this.renderGroupListItem();
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const { user_id, group_id } = ownProps.requestSelectableAssignee;
  if (user_id) {
    return {
      user: EntitySelectors.getUser(state, user_id),
    };
  }
  return {
    group: EntitySelectors.getGroup(state, group_id),
  };
}

export default connect(mapStateToProps)(RequestSelectableAssigneeListItem);
