import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class CompletedAt extends Component {
  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Date
        title={<FormattedMessage id="resources.work-order.completed-at" />}
        comparator={comparator}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.CompletedAt,
            data: {
              [FILTER_TYPES.CompletedAt]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                value: null,
              },
            },
          })
        }
      >
        <SideModal.Container.Filter.AppliedFilters.Value>
          {value}
        </SideModal.Container.Filter.AppliedFilters.Value>
      </SideModal.Container.Filter.AppliedFilters.Filter.Date>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.CompletedAt),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedAt);
