import { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

class TemplateFieldItemTitle extends Component {
  render() {
    return this.props.templateFieldItem ? this.props.templateFieldItem.title : null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    templateFieldItem: EntitySelectors.getTemplateFieldItem(state, ownProps.templateFieldItemId),
  };
}

export default connect(mapStateToProps)(TemplateFieldItemTitle);
