import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, InlineModal, Icon, ViewTextArea, Tooltip } from 'views/components/Shared/General';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingSelectors } from 'state/ducks/stocktaking';
import CompleteModal from '../Components/CompleteModal';
import DeleteModal from '../Components/DeleteModal';
import EditCommentModal from './EditCommentModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showCompleteModal: false,
    showDeleteModal: false,
    showDropdownMenu: false,
    queryParams: queryString.parse(this.props.location.search),
    showDescription: false,
    showEditCommentModal: false,
  };

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams });
    }
  }

  contentToDisplay = () => (this.state.queryParams.list === 'no_location' ? 'no_location' : 'locations');

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to="/stocktaking">
          <FormattedMessage id="screens.stocktaking.title" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>{this.renderTitle()}</LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTitle = () => {
    if (this.props.stocktaking && this.props.stocktaking.completed) {
      return <FormattedMessage id="screens.stocktaking-detail.title-completed" />;
    } else {
      return <FormattedMessage id="screens.stocktaking-detail.title" />;
    }
  };

  renderDropdownMenu = button => (
    <>
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            showDropdownMenu: !prevState.showDropdownMenu,
          }));
        }}
      >
        {button}
      </div>
      <InlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.showDropdownMenu}
        onClose={() => this.setState({ showDropdownMenu: false })}
        position="right"
      >
        <InlineModal.Body width={250} dropdown>
          <InlineModal.ListItem
            icon="pen"
            iconThickness="regular"
            title={<FormattedMessage id="screens.stocktaking-detail.change-description" />}
            onClick={() => {
              this.setState({
                showDropdownMenu: false,
                showEditCommentModal: true,
              });
            }}
          />
          <InlineModal.ListItem
            icon="trash-alt"
            iconThickness="regular"
            iconColor="#C55050"
            destructive
            title={<FormattedMessage id="general.delete" />}
            onClick={() => {
              this.setState({
                showDropdownMenu: false,
                showDeleteModal: true,
              });
            }}
          />
        </InlineModal.Body>
      </InlineModal>
    </>
  );

  renderCompleteButton = () => {
    if (this.props.stocktaking && this.props.stocktaking.completed) return null;
    return (
      <Button
        small
        label="screens.stocktaking-detail.complete-button"
        onClick={() => {
          this.setState({ showCompleteModal: true });
        }}
      />
    );
  };

  renderButtons = () => {
    if (this.props.canCreateWithdrawals) {
      return (
        <Button.Group>
          {this.renderCompleteButton()}
          {this.renderDropdownMenu(<Button small icon={<Icon regular size={16} type="ellipsis-h" />} />)}
        </Button.Group>
      );
    }
    return null;
  };

  renderTabs = () => {
    if (this.props.loading) return null;
    if (!this.props.showSparePartLocationTab) return null;
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/stocktaking/${id}?list=locations`}
          active={this.contentToDisplay() === 'locations'}
        >
          <FormattedMessage id="screens.stocktaking-detail.tabs.locations" />
        </LayoutHeader.TabBarItem>
        <LayoutHeader.TabBarItem
          to={`/stocktaking/${id}?list=no_location`}
          active={this.contentToDisplay() === 'no_location'}
        >
          <FormattedMessage id="screens.stocktaking-detail.tabs.no-location" />
        </LayoutHeader.TabBarItem>
      </LayoutHeader.TabBar>
    );
  };

  renderCompleteModal = () => (
    <CompleteModal
      open={this.state.showCompleteModal}
      onClose={() => {
        this.setState({ showCompleteModal: false });
      }}
    />
  );

  renderDeleteModal = () => (
    <DeleteModal
      open={this.state.showDeleteModal}
      onClose={() => {
        this.setState({ showDeleteModal: false });
      }}
    />
  );

  renderTitleContainer = () => {
    return (
      <>
        <div className={styles['title-container']}>
          <div>{this.renderTitle()}</div>
          {this.props.stocktaking && this.props.stocktaking.description ? (
            <div className={styles['button-container']}>
              <Tooltip
                label={<FormattedMessage id="screens.stocktaking-detail.show-description-tooltip" />}
                trigger={
                  <div>
                    <Button
                      type="icon"
                      icon={<Icon regular size={14} type="info-circle" />}
                      onClick={() =>
                        this.setState(prevState => ({
                          showDescription: !prevState.showDescription,
                        }))
                      }
                    />
                  </div>
                }
              />
            </div>
          ) : null}
        </div>
        <AnimateHeight duration={250} height={this.state.showDescription ? 'auto' : 0}>
          <div className={styles['description']}>
            <ViewTextArea>{this.props.stocktaking && this.props.stocktaking.description}</ViewTextArea>
          </div>
        </AnimateHeight>
      </>
    );
  };

  renderEditCommentModal = () => {
    return (
      <EditCommentModal
        stocktakingId={this.props.match.params.id}
        open={this.state.showEditCommentModal}
        onClose={() => this.setState({ showEditCommentModal: false })}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <LayoutHeader
          smallTitle
          title={this.renderTitleContainer()}
          buttonsComponent={this.renderButtons()}
          breadcrumbComponent={this.renderBreadcrumb()}
          tabBarComponent={this.renderTabs()}
        />
        {this.renderCompleteModal()}
        {this.renderDeleteModal()}
        {this.renderEditCommentModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
    showSparePartLocationTab: StocktakingSelectors.getShowSparePartLocationTab(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
