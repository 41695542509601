import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon } from 'views/components/Shared/General';
import { RequestStatus } from 'sdk/Request';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class StatusColumn extends Component {
  renderWorkOrderPlannedStatusIcon = () => {
    if (moment(this.props.workOrder.due_date).isBefore(moment(), 'day')) {
      return (
        <div className={[styles['icon'], styles['work-order-due']].join(' ')}>
          <Icon type="wrench" />
        </div>
      );
    }
    return (
      <div className={[styles['icon']].join(' ')}>
        <Icon type="wrench" />
      </div>
    );
  };

  renderWorkOrderInProgressStatusIcon = () => {
    return (
      <div className={[styles['icon'], styles['work-order-in-progress']].join(' ')}>
        <Icon type="wrench" />
      </div>
    );
  };

  renderWorkOrderPausedStatusIcon = () => {
    return (
      <div className={[styles['icon'], styles['work-order-paused']].join(' ')}>
        <Icon type="pause" />
      </div>
    );
  };

  renderWorkOrderCompletedStatusIcon = () => {
    return (
      <div className={[styles['icon'], styles['work-order-completed']].join(' ')}>
        <Icon regular type="check" />
      </div>
    );
  };

  renderWorkOrderStatusIcon = () => {
    if (this.props.workOrder.status === WorkOrderStatus.NotStarted) {
      return this.renderWorkOrderPlannedStatusIcon();
    }
    if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
      return this.renderWorkOrderInProgressStatusIcon();
    }
    if (this.props.workOrder.status === WorkOrderStatus.Paused) {
      return this.renderWorkOrderPausedStatusIcon();
    }
    if (this.props.workOrder.status === WorkOrderStatus.Completed) {
      return this.renderWorkOrderCompletedStatusIcon();
    }
  };

  render() {
    const { request_production_supervisor_approval_activated } = this.props.settings;
    const archived = this.props.request.status === RequestStatus.Archived;
    const awaitingApproval = this.props.request.status === RequestStatus.AwaitingApproval;
    if (archived) {
      return (
        <div className={[styles['icon'], styles['archived']].join(' ')}>
          <Icon type="archive" />
        </div>
      );
    }
    if (request_production_supervisor_approval_activated && awaitingApproval) {
      return (
        <div className={[styles['icon'], styles['awaiting-approval']].join(' ')}>
          <Icon type="user" />
        </div>
      );
    }
    if (this.props.workOrder) {
      return this.renderWorkOrderStatusIcon();
    }
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const request = EntitySelectors.getRequest(state, ownProps.requestId);
  return {
    request,
    workOrder: EntitySelectors.getWorkOrder(state, request.work_order_id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(StatusColumn);
