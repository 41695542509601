import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  newIds: [],
  earlierIds: [],
  paginateFrom: null,
  isFullyLoaded: false,
  totalEntries: null,
  badgeCount: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS_SUCCESS: {
      const { newIds, earlierIds, paginateFrom, isFullyLoaded, totalEntries, initialLoad } = action.payload;

      return {
        ...state,
        newIds: initialLoad ? newIds : [...state.newIds, ...newIds],
        earlierIds: initialLoad ? earlierIds : [...state.earlierIds, ...earlierIds],
        paginateFrom,
        isFullyLoaded,
        totalEntries: initialLoad ? totalEntries : state.totalEntries,
      };
    }
    case SDKReduxTypes.WS_NOTIFICATIONS_JOINED: {
      const { badge_count } = action.payload;
      return {
        ...state,
        badgeCount: badge_count,
      };
    }
    case SDKReduxTypes.WS_NOTIFICATIONS_BADGE_COUNT_UPDATED: {
      return {
        ...state,
        badgeCount: action.payload.badge_count,
      };
    }
    case SDKReduxTypes.WS_NOTIFICATIONS_NOTIFICATION_CREATED: {
      return {
        ...state,
        newIds: [action.payload.notification.id, ...state.newIds],
      };
    }

    default:
      return state;
  }
};
