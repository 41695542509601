import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import styles from './styles.module.scss';
import Meter from './Meter';

class Meters extends Component {
  state = {
    showAllMeters: false,
  };

  renderShowAllButton = () => {
    return (
      <div className={styles['show-all-button-container']}>
        <Button
          type="text"
          primary
          onClick={() => this.setState({ showAllMeters: true })}
          translate={false}
          label={
            <FormattedMessage
              id="screens.asset.info.meters.show-all-meters"
              values={{ amount: this.props.meters.length - 1 }}
            />
          }
          noUnderline
        />
      </div>
    );
  };

  renderMeters = () => {
    if (this.props.meters.length > 1 && this.state.showAllMeters === false) {
      return <Meter id={this.props.meters[0].id} renderShowAllButton={this.renderShowAllButton()} />;
    }
    return this.props.meters.map(({ id }) => <Meter id={id} />);
  };

  render() {
    if (this.props.meters && this.props.meters.length > 0) {
      return this.renderMeters();
    }
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  const asset = EntitySelectors.getAsset(state, assetId);
  return {
    meters: EntitySelectors.getMeters(state, asset.meters),
  };
}

export default withRouter(connect(mapStateToProps)(Meters));
