import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, WhiteCard, Pagination } from 'views/components/Shared/General';
import { DowntimeModal, NewDowntimeModal } from 'views/components/Downtime';
import { AssetOperations, AssetSelectors } from 'state/ducks/asset';
import { AuthSelectors } from 'state/ducks/auth';
import { Loader } from 'views/components/Shared/General';
import DowntimeItem from './DowntimeItem';
import Header from '../../Header';

const PAGE_SIZE = 8;

class Downtimes extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      viewInitialized: false,
      queryParams: queryParams,
      showNewDowntimeModal: false,
      showDowntimeModal: false,
      showDowntimeModalForId: null,
      isCreatingActiveDowntime: false,
    };
  }

  componentDidMount() {
    this.fetchDowntimes()
      .then(() => {
        this.setState({ viewInitialized: true });
      })
      .catch(() => {
        this.setState({ viewInitialized: true });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ isSearching: true });
        this.fetchDowntimes()
          .then(() => {
            this.setState({ isSearching: false });
          })
          .catch(() => {
            this.setState({ isSearching: false });
          });
      });
    }
  }

  fetchDowntimes = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);
    const assetId = this.props.match.params.id;

    return this.props.fetchDowntimes(this.props.currentSystem.id, assetId, {
      ...params,
      ...filterParams,
      sort: 'from',
      'sort-order': 'desc',
      page_size: PAGE_SIZE,
    });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  renderDowntimeModal = () => (
    <DowntimeModal
      id={this.state.showDowntimeModalForId}
      assetId={this.props.match.params.id}
      isCreatingActiveDowntime={this.state.isCreatingActiveDowntime}
      open={this.state.showDowntimeModal}
      onClose={() => {
        this.setState({ showDowntimeModal: false });
      }}
    />
  );

  renderNewDowntimeModal = () => (
    <NewDowntimeModal
      open={this.state.showNewDowntimeModal}
      assetId={this.props.match.params.id}
      onClose={() => {
        this.setState({ showNewDowntimeModal: false });
      }}
      onCreateCompletedDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: false });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true, showDowntimeModalForId: null });
        }, 200);
      }}
      onCreateActiveDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: true });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true, showDowntimeModalForId: null });
        }, 200);
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard padding="50px">
      <EmptyDataSet
        title={<FormattedMessage id="screens.asset.downtimes.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.asset.downtimes.empty-data-set.subtitle" />}
        button={
          this.props.isViewOnly ? null : (
            <Button
              primary
              onClick={() => this.setState({ showNewDowntimeModal: true })}
              label="screens.asset.downtimes.empty-data-set.button"
            />
          )
        }
      />
    </WhiteCard>
  );

  renderList = () => {
    if (this.state.isSearching) {
      const amountOfDowntimes = this.props.downtimeIds.length === 0 ? 2 : this.props.downtimeIds.length;
      return Array(amountOfDowntimes)
        .fill()
        .map(() => <DowntimeItem loading />);
    }
    return this.props.downtimeIds.map(id => (
      <DowntimeItem
        id={id}
        key={id}
        onClick={() => {
          this.setState({ showDowntimeModal: true, showDowntimeModalForId: id });
        }}
      />
    ));
  };

  renderDowntimes = () => (
    <>
      <Toolbar
        buttons={
          this.props.isViewOnly ? null : (
            <Button
              primary
              label="screens.asset.downtimes.create-button"
              onClick={() => this.setState({ showNewDowntimeModal: true })}
            />
          )
        }
      />
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.downtime.started-at" />
        </List.Header.Column>
      </List.Header>
      <List>{this.renderList()}</List>
      {this.props.pagination.totalEntries > PAGE_SIZE ? (
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page}
          style={{ marginTop: 10, justifyContent: 'flex-end' }}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      ) : null}
    </>
  );

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.downtimeIds.length === 0 && !this.state.isSearching) {
      return this.renderEmptyDataset();
    } else {
      return this.renderDowntimes();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderDowntimeModal()}
        {this.renderNewDowntimeModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDowntimes: AssetOperations.fetchDowntimes,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    downtimeIds: AssetSelectors.getDowntimeIds(state),
    pagination: AssetSelectors.getDowntimesPagination(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Downtimes)));
