import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';

const PurchaseOrderColumn = ({ purchaseOrders }) => {
  if (purchaseOrders.length === 0) {
    return '-';
  }
  if (purchaseOrders.length > 1) {
    return (
      <FormattedPlural
        value={purchaseOrders.length}
        two={
          <FormattedMessage
            id="screens.work-orders.amount-of-purchase-orders.two"
            values={{ amount: purchaseOrders.length }}
          />
        }
        few={
          <FormattedMessage
            id="screens.work-orders.amount-of-purchase-orders.few"
            values={{ amount: purchaseOrders.length }}
          />
        }
        many={
          <FormattedMessage
            id="screens.work-orders.amount-of-purchase-orders.many"
            values={{ amount: purchaseOrders.length }}
          />
        }
        other={
          <FormattedMessage
            id="screens.work-orders.amount-of-purchase-orders.other"
            values={{ amount: purchaseOrders.length }}
          />
        }
      />
    );
  }
  return <span>#{purchaseOrders[0].number}</span>;
};

function mapStateToProps(state, ownProps) {
  const workOrderPurchases = EntitySelectors.getWorkOrderPurchases(
    state,
    ownProps.workOrder.work_order_purchases.map(id => id)
  );
  const purchaseOrderIds = workOrderPurchases.map(({ purchase_order_id }) => purchase_order_id);
  return {
    purchaseOrders: EntitySelectors.getPurchaseOrders(state, purchaseOrderIds),
  };
}

export default connect(mapStateToProps)(PurchaseOrderColumn);
