import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class SelectedAssignee extends Component {
  render() {
    if (this.props.user) {
      return <UserNameWrapper user={this.props.user} />;
    }
    if (this.props.group) {
      return this.props.group.title;
    }
    return (
      <span className={styles['no-value']}>
        <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.no-receiver-assignee" />
      </span>
    );
  }
}
function mapStateToProps(state, ownProps) {
  const { userId, groupId } = ownProps;
  return {
    user: EntitySelectors.getUser(state, userId),
    group: EntitySelectors.getGroup(state, groupId),
  };
}

export default connect(mapStateToProps)(SelectedAssignee);
