const DOMAIN_PREFIX = 'checklistEditModal/';

const FETCH_CHECKLIST_TEMPLATE_VERSION = `${DOMAIN_PREFIX}fetch_checkist_template_version`;
const SET_EDITING_CHECKLIST_TEMPLATE_VERSION = `${DOMAIN_PREFIX}set_editing_checklist_template_version`;
const SORT_FIELD_IN_SECTION = `${DOMAIN_PREFIX}sort_field_in_section`;
const SORT_FIELD_OUTSIDE_SECTION = `${DOMAIN_PREFIX}sort_field_outside_section`;
const MOVE_FIELD_FROM_ROOT_TO_SECTION = `${DOMAIN_PREFIX}move_field_from_root_to_section`;
const BEGIN_DRAG = `${DOMAIN_PREFIX}begin_drag`;
const END_DRAG = `${DOMAIN_PREFIX}end_drag`;
const PREPARE_NEW_FIELD = `${DOMAIN_PREFIX}prepare_new_field`;
const PREPARE_NEW_FIELD_FOR_EDIT = `${DOMAIN_PREFIX}prepare_new_field_for_edit`;
const MOVE_FIELD_FROM_SECTION_TO_ROOT = `${DOMAIN_PREFIX}move_field_from_section_to_root`;
const MOVE_FIELDS_BETWEEN_SECTIONS = `${DOMAIN_PREFIX}move_fields_between_sections`;
const EDIT_FIELD = `${DOMAIN_PREFIX}edit_field`;
const UPDATE_EDITING_FIELD = `${DOMAIN_PREFIX}update_editing_field`;
const CANCEL_EDIT_FIELD = `${DOMAIN_PREFIX}cancel_edit_field`;
const ADD_NEW_FIELD_TO_ROOT = `${DOMAIN_PREFIX}add_new_field_to_root`;
const ADD_NEW_FIELD_TO_SECTION = `${DOMAIN_PREFIX}add_new_field_to_section`;
const SHOW_SECTION_CANT_BE_DROPPED_IN_MODAL = `${DOMAIN_PREFIX}show_section_cant_be_dropped_in_modal`;
const HIDE_SECTION_CANT_BE_DROPPED_IN_MODAL = `${DOMAIN_PREFIX}hide_section_cant_be_dropped_in_modal`;
const SET_SAVE_AS_REUSABLE = `${DOMAIN_PREFIX}set_save_as_reusable`;
const SET_IS_CREATING_CHECKLIST_FROM_LIBRARY = `${DOMAIN_PREFIX}set_is_creating_checklist_from_library`;

export default {
  SET_IS_CREATING_CHECKLIST_FROM_LIBRARY,
  FETCH_CHECKLIST_TEMPLATE_VERSION,
  SET_EDITING_CHECKLIST_TEMPLATE_VERSION,
  SORT_FIELD_IN_SECTION,
  PREPARE_NEW_FIELD_FOR_EDIT,
  MOVE_FIELD_FROM_ROOT_TO_SECTION,
  SORT_FIELD_OUTSIDE_SECTION,
  BEGIN_DRAG,
  END_DRAG,
  PREPARE_NEW_FIELD,
  MOVE_FIELD_FROM_SECTION_TO_ROOT,
  MOVE_FIELDS_BETWEEN_SECTIONS,
  EDIT_FIELD,
  UPDATE_EDITING_FIELD,
  CANCEL_EDIT_FIELD,
  ADD_NEW_FIELD_TO_ROOT,
  ADD_NEW_FIELD_TO_SECTION,
  SHOW_SECTION_CANT_BE_DROPPED_IN_MODAL,
  HIDE_SECTION_CANT_BE_DROPPED_IN_MODAL,
  SET_SAVE_AS_REUSABLE,
};
