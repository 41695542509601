import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isDeleting: false,
      });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });

    this.props
      .deleteRequestAutomationRule(this.props.id)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.delete-success" />
            }
          />
        );
        this.props.onDelete();
      })
      .catch(e => {});
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={400}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.delete-confirm.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.delete-confirm.subtitle" />
          }
          onClose={this.props.onClose}
          ignoreLine
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              destructive
              primary
              label="general.delete"
              loading={this.state.isDeleting}
              onClick={this.delete}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteRequestAutomationRule: SDKReduxOperations.deleteRequestAutomationRule,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(DeleteModal));
