import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import AssetContainer from './AssetContainer';
import { FILTER_TYPES } from 'views/scenes/Assets/TreeAndList/List/FilterModal';

class TreeParent extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        comparator,
        ids: [],
        hasValue: value,
        initialFilter: props.filter,
      };
    } else {
      this.state = {
        comparator,
        ids: value,
        hasValue: false,
        initialFilter: props.filter,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.TreeParent,
            data: {
              [FILTER_TYPES.TreeParent]: {
                comparator: this.state.comparator,
                value: this.state.hasValue,
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.TreeParent,
            data: {
              [FILTER_TYPES.TreeParent]: {
                comparator: this.state.comparator,
                value: this.state.ids,
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return this.state.ids.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedAssets = () => {
    if (this.state.ids.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.ids}
        renderItem={id => <AssetContainer id={id} />}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChooseAssetInlineModal
        hideCreateButton
        clearable={false}
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedAssets()}
            onClear={() => this.setState({ ids: [] })}
          />
        }
        selectedAssetIds={this.state.ids}
        onAddAsset={id => {
          this.setState({
            ids: [...this.state.ids, id],
          });
        }}
        onRemoveAsset={id => {
          this.setState({
            ids: this.state.ids.filter(item => item !== id),
          });
        }}
        onClear={() => this.setState({ ids: [] })}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.TreeParent,
                data: {
                  [FILTER_TYPES.TreeParent]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.TreeParent,
                data: {
                  [FILTER_TYPES.TreeParent]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="screens.assets.filters.advanced.asset.belongs-to" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: AssetsOperations.removeFilter,
      addFilter: AssetsOperations.addFilter,
      showAppliedFilters: AssetsOperations.showAppliedFilters,
      selectFilterType: AssetsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: AssetsSelectors.getFiltersForKey(state, FILTER_TYPES.TreeParent),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeParent);

TreeParent.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

TreeParent.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: [],
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: [],
  },
};
