import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { ChooseSparePartLocationInlineModal } from 'views/components/SparePartLocation';
import {
  SparePartWithdrawalsSelectors,
  SparePartWithdrawalsOperations,
} from 'state/ducks/sparePartWithdrawals';
import { FILTER_TYPES } from 'views/scenes/SparePartWithdrawals/FilterModal';
import SparePartLocationContainer from './SparePartLocationContainer';

class SparePartLocation extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } =
      props.filter.spare_part_location_with_children_id ||
      props.initialFilter.spare_part_location_with_children_id;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        comparator,
        ids: [],
        hasValue: value,
        initialFilter: {
          spare_part_location_with_children_id: {
            ...(props.filter.spare_part_location_with_children_id ||
              props.initialFilter.spare_part_location_with_children_id),
          },
        },
      };
    } else {
      this.state = {
        comparator,
        ids: value,
        hasValue: false,
        initialFilter: {
          spare_part_location_with_children_id: {
            ...(props.filter.spare_part_location_with_children_id ||
              props.initialFilter.spare_part_location_with_children_id),
          },
        },
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.SparePartLocation,
            data: {
              spare_part: {
                ...this.props.filter,
                spare_part_location_with_children_id: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.SparePartLocation,
            data: {
              spare_part: {
                ...this.props.filter,
                spare_part_location_with_children_id: {
                  comparator: this.state.comparator,
                  value: this.state.ids,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return this.state.ids.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedSparePartLocations = () => {
    if (this.state.ids.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.ids}
        renderItem={id => <SparePartLocationContainer id={id} />}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChooseSparePartLocationInlineModal
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedSparePartLocations()}
            onClear={() => this.setState({ ids: [] })}
          />
        }
        selectedSparePartLocationIds={this.state.ids}
        onAddSparePartLocation={id => {
          this.setState({
            ids: [...this.state.ids, id],
          });
        }}
        onRemoveSparePartLocation={id => {
          this.setState({
            ids: this.state.ids.filter(item => item !== id),
          });
        }}
        onClear={() => this.setState({ ids: [] })}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.SparePartLocation,
                data: {
                  spare_part: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.SparePartLocation,
                data: {
                  spare_part: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="screens.withdrawals.filters.advanced.spare-parts.location" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: SparePartWithdrawalsOperations.removeFilter,
      addFilter: SparePartWithdrawalsOperations.addFilter,
      showAppliedFilters: SparePartWithdrawalsOperations.showAppliedFilters,
      selectFilterType: SparePartWithdrawalsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: SparePartWithdrawalsSelectors.getFiltersForKey(state, 'spare_part'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartLocation);

SparePartLocation.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

SparePartLocation.defaultProps = {
  filter: {
    spare_part_location_with_children_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
  initialFilter: {
    spare_part_location_with_children_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
};
