import types from './types';
import update from 'immutability-helper';
import { uniq } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  contactPersons: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  attachments: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  assetVendors: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  spareParts: {
    ids: [],
    sparePartVendorIdsBySparePartId: {},
    pagination: {},
  },
  purchaseOrders: {
    ids: [],
    pagination: {},
  },
  workOrders: {
    ids: [],
    pagination: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;

    case types.SET_VENDOR_ID: {
      return {
        ...INITIAL_STATE,
        id: action.payload,
      };
    }

    case types.FETCH_CONTACT_PERSONS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        contactPersons: {
          ...state.contactPersons,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case types.FETCH_ASSET_VENDORS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        assetVendors: {
          ...state.assetVendors,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case types.FETCH_ATTACHMENTS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case types.FETCH_PURCHASE_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          ids,
          pagination,
        },
      };
    }

    case types.FETCH_WORK_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case SDKReduxTypes.ASSET_CREATED: {
      const { data } = action.payload;
      if (data.asset_vendors) {
        return update(state, {
          assetVendors: {
            ids: { $unshift: [data.asset_vendors[0].id] },
            createdIds: { $unshift: [data.asset_vendors[0].id] },
          },
        });
      } else return state;
    }

    case SDKReduxTypes.CONTACT_PERSON_CREATED: {
      const { data } = action.payload;
      if (data.vendor_id === state.id) {
        return update(state, {
          contactPersons: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.CONTACT_PERSON_DELETED: {
      const { contactPersonId } = action.payload;
      const { ids, createdIds } = state.contactPersons;

      return update(state, {
        contactPersons: {
          ids: { $set: ids.filter(id => id !== contactPersonId) },
          createdIds: { $set: createdIds.filter(id => id !== contactPersonId) },
        },
      });
    }

    case SDKReduxTypes.ASSET_VENDOR_CREATED: {
      const { data } = action.payload;
      if (data.vendor_id === state.id) {
        return update(state, {
          assetVendors: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.ASSET_VENDOR_DELETED: {
      const { assetVendorId } = action.payload;
      return {
        ...state,
        assetVendors: {
          ...state.assetVendors,
          ids: state.assetVendors.ids.filter(id => id !== assetVendorId),
          createdIds: state.assetVendors.createdIds.filter(id => id !== assetVendorId),
        },
      };
    }

    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { vendorId } = meta;
      if (state.id === vendorId) {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.attachment_id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_VENDOR: {
      const { data, params } = action.payload;
      if (params.type === 'link') {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids: state.attachments.ids.filter(id => id !== attachmentId),
        },
      };
    }
    case types.FETCH_SPARE_PARTS_SUCCESS: {
      const { ids, sparePartVendorIdsBySparePartId, pagination } = action.payload;
      return {
        ...state,
        spareParts: {
          ...state.spareParts,
          sparePartVendorIdsBySparePartId,
          ids,
          pagination,
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_CREATED: {
      const { data } = action.payload;
      if (data.spare_part_vendors) {
        return update(state, {
          spareParts: {
            ids: {
              $set: [data.id, ...state.spareParts.ids],
            },
            sparePartVendorIdsBySparePartId: {
              [data.id]: {
                $set: [data.spare_part_vendors[0].id],
              },
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_VENDOR_DELETED: {
      const { sparePartVendorId, data } = action.payload;
      const { spare_part_id } = data;
      const sparePartVendorIdsForVendorId = state.spareParts.sparePartVendorIdsBySparePartId[spare_part_id];
      if (sparePartVendorIdsForVendorId) {
        if (sparePartVendorIdsForVendorId.length === 1) {
          return update(state, {
            spareParts: {
              ids: {
                $set: state.spareParts.ids.filter(id => id !== spare_part_id),
              },
              sparePartVendorIdsBySparePartId: {
                $unset: [spare_part_id],
              },
            },
          });
        }
        return update(state, {
          spareParts: {
            sparePartVendorIdsBySparePartId: {
              [spare_part_id]: {
                $apply: vendorId => {
                  return vendorId.filter(id => id !== sparePartVendorId);
                },
              },
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_VENDOR_CREATED: {
      const { data } = action.payload;
      const { spare_part_id } = data;
      return update(state, {
        spareParts: {
          ids: {
            $set: uniq([spare_part_id, ...state.spareParts.ids]),
          },
          sparePartVendorIdsBySparePartId: {
            [spare_part_id]: {
              $apply: sparePartId => {
                if (sparePartId) {
                  return update(sparePartId, {
                    $push: [data.id],
                  });
                }
                return [data.id];
              },
            },
          },
        },
      });
    }
    case SDKReduxTypes.WORK_ORDER_CREATED:
    case SDKReduxTypes.RECURRING_MAINTENANCE_CREATED: {
      let workOrder;
      if (action.type === SDKReduxTypes.WORK_ORDER_CREATED) {
        workOrder = action.payload.data;
      } else {
        const { data: recurringMaintenance } = action.payload;
        workOrder = recurringMaintenance.generated_work_order;
      }
      if (workOrder.assigned_to_vendors.find(({ id }) => id === state.id)) {
        return update(state, {
          workOrders: {
            ids: { $unshift: [workOrder.id] },
            pagination: {
              totalEntries: { $apply: v => v + 1 },
            },
          },
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
