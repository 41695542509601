import types from './types';

const INITIAL_STATE = {
  instanceId: null,
  templateId: null,
  instanceRowForTemplateRowIds: {},
  instanceOptionIdsForTemplateRowIds: {},
  templateFieldIds: [],
  childIds: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;
    case types.SET_CHECKLIST_INSTANCE: {
      const {
        instanceId,
        childIds,
        templateFieldIds,
        instanceRowForTemplateRowIds,
        instanceOptionIdsForTemplateRowIds,
      } = action.payload;
      return {
        ...state,
        templateId: null,
        isFetching: false,
        instanceId,
        childIds,
        templateFieldIds,
        instanceRowForTemplateRowIds,
        instanceOptionIdsForTemplateRowIds,
      };
    }
    default:
      return state;
  }
};
