import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import toast from 'react-hot-toast';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { NewRequestSelectors, NewRequestOperations } from 'state/ducks/newRequest';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { RequestReceiverType } from 'sdk/Settings';
import { ToastMessage } from 'views/components/Shared/Layout';
import {
  FullScreenImagePicker,
  Button,
  Field,
  FileUploadWrapper,
  Icon,
  Tooltip,
} from 'views/components/Shared/General';
import { Image, Loader } from 'views/components/Image';
import { Grid, SideModal } from 'views/components/Shared/Layout';
import { ChooseAssetInlineModal, TreePath } from 'views/components/Asset';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { RequestTypeInlineModal, ChooseAssigneeModal } from 'views/components/Request';
import { SDKReduxOperations } from 'sdk';
import CreateFromSingleChecklistInstanceRowTitle from './CreateFromSingleChecklistInstanceRowTitle';
import DowntimeOptionsDrawer from 'views/scenes/Requests/DetailContainer/Edit/DowntimeOptionsDrawer';
import styles from './style.module.scss';

class NewRequestModal extends Component {
  getInitialState = () => ({
    showChooseAssigneeModal: false,
    showChooseAssigneeModalFromCreateAndCreateNew: false,
    showDowntimeDrawer: false,
    isCreatingRequest: false,
    isCreatingRequestAndCreatingNew: false,
    currentImage: 0,
    showingImageDialog: false,
    deletingImageIds: {},
    uploadingCount: 0,
    errors: {
      titleRequired: false,
      descriptionRequired: false,
      assetRequired: false,
      typeRequired: false,
      priorityRequired: false,
      showAnotherAssetRequiredError: false,
      showFromDateInFutureError: false,
      showFromDateRequiredError: false,
    },
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
      let defaultParams = {
        ...this.props.defaultParams,
      };
      if (this.props.forWorkOrder) {
        defaultParams = {
          ...defaultParams,
          created_from_work_order_id: this.props.forWorkOrderId,
        };
      }
      this.props.prepareNewRequest(defaultParams);
    } else if (prevProps.open && !this.props.open) {
      this.props.cancelNewRequest();
    }
  }

  close = () => {
    this.props.onClose();
  };

  hasErrors = () => {
    const {
      request_description_required,
      request_asset_required,
      request_priority_required,
      request_type_required,
    } = this.props.currentSystem;
    const { title, description, asset_id, priority, request_type_id } = this.props.editingRequest;
    let errors = {};
    if (!title) {
      errors = {
        ...errors,
        titleRequired: true,
      };
    }
    if (request_description_required && !description) {
      errors = {
        ...errors,
        descriptionRequired: true,
      };
    }
    if (request_asset_required && !asset_id) {
      errors = {
        ...errors,
        assetRequired: true,
      };
    }
    if (request_priority_required && !priority) {
      errors = {
        ...errors,
        priorityRequired: true,
      };
    }
    if (request_type_required && !request_type_id) {
      errors = {
        ...errors,
        typeRequired: true,
      };
    }
    if (this.props.editingRequest.downtime) {
      if (this.props.editingRequest.downtime.asset_id == null) {
        errors = {
          ...errors,
          showAnotherAssetRequiredError: true,
        };
      }
      if (this.props.editingRequest.downtime.from == null) {
        errors = {
          ...errors,
          showFromDateRequiredError: true,
        };
      } else if (moment(this.props.editingRequest.downtime.from).isAfter(moment())) {
        errors = {
          ...errors,
          showFromDateInFutureError: true,
        };
      }
    }
    this.setState({ errors });
    return Object.values(errors).some(item => item === true);
  };

  createRequest = createNew => {
    if (this.state.isCreatingRequest) return;

    if (this.hasErrors()) {
      return;
    }
    let request = { ...this.props.editingRequest };
    if (request.downtime) {
      request = {
        ...request,
        downtime: {
          ...request.downtime,
          from: moment(request.downtime.from, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm'),
        },
      };
    }

    const { request_multiple_assignees_active, request_multiple_assignees_type } = this.props.settings;
    if (
      this.props.hasProTier &&
      request_multiple_assignees_active &&
      request_multiple_assignees_type === RequestReceiverType.SelectedByCreator
    ) {
      this.setState({
        showChooseAssigneeModal: true,
        showChooseAssigneeModalFromCreateAndCreateNew: createNew,
      });
      return;
    }

    this.setState({
      isCreatingRequest: createNew === false,
      isCreatingRequestAndCreatingNew: createNew === true,
    });

    this.props
      .createRequest(this.props.currentSystem.id, request)
      .then(({ data: request }) => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.create-success" />} />);

        if (createNew) {
          this.props.onCreatedWithReopen(request);
        } else {
          this.props.onCreated(request);
        }
      })
      .catch(e => {
        if (e?.response?.data?.errors?.downtime?.asset_id[0]?.code === '30009') {
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="screens.request.errors.asset-already-has-active-downtime" />}
            />
          );
        } else {
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }
        this.setState({
          isCreatingRequest: false,
          isCreatingRequestAndCreatingNew: false,
        });
      });
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.delete-image-success" />} />);
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderForChecklist = () => {
    if (this.props.forChecklistInstanceRowIds.length === 0) return null;
    if (this.props.forChecklistInstanceRowIds.length === 1) {
      return (
        <>
          <div className={styles['create-from-resource-container']}>
            <div className={styles['create-from-resource']}>
              <div className={styles['title']}>
                <CreateFromSingleChecklistInstanceRowTitle
                  checklistInstanceRowId={this.props.forChecklistInstanceRowIds[0]}
                />
              </div>
            </div>
          </div>
          <div className={styles['divider']} />
        </>
      );
    }

    return (
      <>
        <div className={styles['create-from-resource-container']}>
          <div className={styles['create-from-resource']}>
            <div className={styles['title']}>
              <FormattedPlural
                value={this.props.forChecklistInstanceRowIds.length}
                one={
                  <FormattedMessage id="components.new-request-modal.amount-of-checklist-instance-rows.one" />
                }
                two={
                  <FormattedMessage
                    id="components.new-request-modal.amount-of-checklist-instance-rows.two"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                few={
                  <FormattedMessage
                    id="components.new-request-modal.amount-of-checklist-instance-rows.few"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                many={
                  <FormattedMessage
                    id="components.new-request-modal.amount-of-checklist-instance-rows.many"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                other={
                  <FormattedMessage
                    id="components.new-request-modal.amount-of-checklist-instance-rows.other"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className={styles['divider']} />
      </>
    );
  };

  renderForWorkOrder = () => {
    if (!this.props.forWorkOrder) return null;

    return (
      <>
        <div className={styles['create-from-resource-container']}>
          <div className={styles['create-from-resource']}>
            <div className={styles['title']}>{this.props.forWorkOrder.title}</div>
            {this.props.forWorkOrder.asset_id ? (
              <div className={styles['subtitle']}>
                <TreePath assetId={this.props.forWorkOrder.asset_id} fullPath />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles['divider']} />
      </>
    );
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addDraftImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader medium />
    );
    return (
      <>
        {this.props.images.map((image, index) => (
          <Image
            key={image.id}
            medium
            isDeleting={this.state.deletingImageIds[image.id] != null}
            image={image}
            editable
            onClick={() => {
              this.selectImage(0);
            }}
            onDelete={() => {
              this.deleteImage(image);
            }}
          />
        ))}
        {loaderElements}
      </>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.request.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderSelectedPriority = () => {
    switch (this.props.editingRequest.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderCreateDowntimeCheckbox = () => {
    const { editingRequest, settings, canEditDowntimes } = this.props;
    if (editingRequest.asset_id && settings.downtime_activated && canEditDowntimes) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              label={<FormattedMessage id="screens.request.has-downtime-checkbox" />}
              checked={this.state.showDowntimeDrawer}
              onChange={value => {
                this.setState({ showDowntimeDrawer: value });
              }}
            />
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderContent = () => {
    const { editingRequest } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.request.title" />}>
              <Field.Text
                error={this.state.errors.titleRequired}
                autoFocus
                value={editingRequest.title}
                rightLabel={
                  <div className={styles['max-characters-container']}>
                    <span className={styles['max-characters']}>
                      {editingRequest.title ? (
                        <FormattedMessage
                          id="general.characters-left"
                          values={{ amount: 35 - editingRequest.title.length }}
                        />
                      ) : null}
                    </span>
                    <Tooltip
                      trigger={
                        <div className={styles['title-popup-container']}>
                          <Icon regular type="question-circle" />
                        </div>
                      }
                      label={<FormattedMessage id="general.characters-left-popup" />}
                    />
                  </div>
                }
                ref={ref => (this.titleRef = ref)}
                onChange={title => {
                  if (this.state.errors.titleRequired && title) {
                    this.setState({ errors: { ...this.state.errors, titleRequired: false } });
                  }
                  this.props.setEditingRequestValues({ title });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.request.description" />}>
              <Field.Textarea
                error={this.state.errors.descriptionRequired}
                minRows={3}
                value={editingRequest.description}
                onChange={description => {
                  if (this.state.errors.descriptionRequired && description) {
                    this.setState({ errors: { ...this.state.errors, descriptionRequired: false } });
                  }
                  this.props.setEditingRequestValues({ description });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.request.asset" />}>
              <ChooseAssetInlineModal
                defaultTreeParentId={this.props.defaultTreeParentIdForAsset}
                trigger={
                  <Field.Resource
                    angle
                    error={this.state.errors.assetRequired}
                    value={
                      this.props.asset == null ? null : (
                        <React.Fragment>
                          {this.props.asset.title}
                          {this.props.asset.tree_path.length === 0 ? null : (
                            <div className={styles['tree-path-container']}>
                              <TreePath assetId={this.props.asset.id} />
                            </div>
                          )}
                        </React.Fragment>
                      )
                    }
                    onClear={() => {
                      this.setState({
                        showDowntimeDrawer: false,
                      });
                      this.props.setEditingRequestValues({
                        asset_id: null,
                      });
                    }}
                  />
                }
                selectedAssetId={this.props.editingRequest.asset_id}
                onSelectAsset={assetId => {
                  if (this.state.errors.assetRequired) {
                    this.setState({ errors: { ...this.state.errors, assetRequired: false } });
                  }
                  this.props.setEditingRequestValues({
                    asset_id: assetId,
                  });
                }}
                onClear={e => this.props.setEditingRequestValues({ asset_id: null })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderCreateDowntimeCheckbox()}
        <DowntimeOptionsDrawer
          open={this.state.showDowntimeDrawer}
          editingDowntime={this.props.editingRequest.downtime}
          assetId={this.props.editingRequest.asset_id}
          onChange={params => {
            this.props.setEditingRequestValues(params);
          }}
          onClearErrors={errors => {
            this.setState({
              errors: {
                ...this.state.errors,
                ...errors,
              },
            });
          }}
          showAnotherAssetRequiredError={this.state.errors.showAnotherAssetRequiredError}
          showFromDateRequiredError={this.state.errors.showFromDateRequiredError}
          showFromDateInFutureError={this.state.errors.showFromDateInFutureError}
        />
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.request.request-type" />}>
              <RequestTypeInlineModal
                trigger={
                  <Field.Resource
                    error={this.state.errors.typeRequired}
                    value={this.props.requestType == null ? null : this.props.requestType.title}
                    onClear={() => this.props.setEditingRequestValues({ request_type_id: null })}
                  />
                }
                selectedAssetId={this.props.editingRequest.request_type_id}
                onSelectRequestType={requestTypeId => {
                  if (this.state.errors.typeRequired) {
                    this.setState({ errors: { ...this.state.errors, typeRequired: false } });
                  }
                  this.props.setEditingRequestValues({ request_type_id: requestTypeId });
                }}
                onClearRequestType={() => this.props.setEditingRequestValues({ request_type_id: null })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.request.priority" />}>
              <ChoosePriorityInlineModal
                trigger={
                  <Field.Resource
                    error={this.state.errors.priorityRequired}
                    value={this.renderSelectedPriority()}
                    onClear={() => this.props.setEditingRequestValues({ priority: null })}
                  />
                }
                selectedPriority={this.props.editingRequest.priority}
                onSelectPriority={priority => {
                  if (this.state.errors.priorityRequired) {
                    this.setState({ errors: { ...this.state.errors, priorityRequired: false } });
                  }
                  this.props.setEditingRequestValues({ priority });
                }}
                onClear={() => this.props.setEditingRequestValues({ priority: null })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.renderImagesContainer()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderTitle = () => {
    if (this.props.forChecklistInstanceRowIds.length > 0) {
      return <FormattedMessage id="components.new-request-modal.from-checklist-title" />;
    }
    return <FormattedMessage id="components.new-request-modal.title" />;
  };

  renderSaveAndCreateNewButton = () => {
    if (this.props.forChecklistInstanceRowIds.length > 0) {
      return null;
    }
    return (
      <Button
        label="general.save-and-create-new"
        loading={this.state.isCreatingRequestAndCreatingNew}
        onClick={() => {
          this.createRequest(true);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal
          open={this.props.open}
          onClose={() => {
            const { editingRequest, editingRequestBeforeEdit } = this.props;
            const hasUnsavedChanges = !isEqual(editingRequest, editingRequestBeforeEdit);
            if (hasUnsavedChanges) {
              const confirmed = window.confirm(
                this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
              );
              if (confirmed) {
                this.close();
              }
            } else {
              this.close();
            }
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createRequest(false)}
                loading={this.state.isCreatingRequest}
              />
              {this.renderSaveAndCreateNewButton()}
              <Button label="general.cancel" onClick={this.close} />
            </Button.Group>
          }
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header title={this.renderTitle()} onClose={this.close} />
              {this.renderForChecklist()}
              {this.renderForWorkOrder()}
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <ChooseAssigneeModal
          open={this.state.showChooseAssigneeModal}
          request={this.props.editingRequest}
          onClose={() => this.setState({ showChooseAssigneeModal: false })}
          onCreated={request => {
            this.setState({ showChooseAssigneeModal: false });
            if (this.state.showChooseAssigneeModalFromCreateAndCreateNew) {
              this.props.onCreatedWithReopen(request);
            } else {
              this.props.onCreated(request);
            }
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRequest: SDKReduxOperations.createRequest,
      prepareNewRequest: NewRequestOperations.prepareNewRequest,
      setEditingRequestValues: NewRequestOperations.setEditingRequestValues,
      cancelNewRequest: NewRequestOperations.cancelNewRequest,
      createDraftImage: SDKReduxOperations.createDraftImage,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const editingRequest = NewRequestSelectors.getEditingRequest(state);
  return {
    editingRequest,
    editingRequestBeforeEdit: NewRequestSelectors.getEditingRequestBeforeEdit(state),
    requestType: EntitySelectors.getRequestType(state, editingRequest.request_type_id),
    asset: EntitySelectors.getAsset(state, editingRequest.asset_id),
    createdByUser: EntitySelectors.getUser(state, editingRequest.created_by_user_id),
    images: EntitySelectors.getImages(state, editingRequest.images) || [],
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    canEditDowntimes: AuthSelectors.canEditDowntimes(state),
    forWorkOrder: EntitySelectors.getWorkOrder(state, ownProps.forWorkOrderId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewRequestModal));

NewRequestModal.propTypes = {
  open: PropTypes.bool,
  forChecklistInstanceRowIds: PropTypes.array,
  defaultParams: PropTypes.object,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  defaultTreeParentIdForAsset: PropTypes.string,
};

NewRequestModal.defaultProps = {
  open: false,
  forChecklistInstanceRowIds: [],
  defaultParams: {},
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
  defaultTreeParentIdForAsset: null,
};
