import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import { Button, List } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AddConfirmModal extends Component {
  renderContent = () => {
    if (!this.props.workOrder) return null;

    return (
      <List light>
        <List.Item light>
          <List.Item.TitleColumn
            title={
              <span>
                <span className={styles['number']}>#{this.props.workOrder.number} - </span>
                {this.props.workOrder.title}
              </span>
            }
            subtitle={
              this.props.workOrder.asset_id != null ? (
                <TreePath assetId={this.props.workOrder.asset_id} />
              ) : (
                '-'
              )
            }
          />
        </List.Item>
      </List>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="screens.requests.add-to-existing-work-order-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.choose"
              loading={this.props.isSaving}
              onClick={this.props.onSave}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    workOrder: EntitySelectors.getWorkOrder(state, ownProps.workOrderId),
  };
}

export default connect(mapStateToProps)(AddConfirmModal);
