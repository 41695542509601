import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { ContentContainer } from 'views/components/Shared/Layout';
import Meters from 'assets/images/EmptyDataSet/Meters.png';

class NotFound extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <ContentContainer>
          <WhiteCard centerContent padding="60px">
            <EmptyDataSet
              title={<FormattedMessage id="screens.meter.not-found.title" />}
              subtitle={<FormattedMessage id="screens.meter.not-found.subtitle" />}
              image={Meters}
              small
            />
          </WhiteCard>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}

export default NotFound;
