import { mutateTree, moveItemOnTree } from '@atlaskit/tree/';
import { uniq, mergeWith } from 'lodash-es';
import { buildAtlaskitTree, buildAtlaskitTreeNode, removeNodeFromAtlaskitTree } from 'sdk/Asset';
import types from './types';
import update from 'immutability-helper';
import { MenuTypes } from 'state/ducks/menu';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  tree: buildAtlaskitTree(),
  navigatedTo: {
    search: {},
  },
  pagination: {
    totalEntries: 0,
    totalPages: 1,
  },
  selectedAssets: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesAreSelected: false,
    ids: {},
  },
  queryParams: {
    page: 1,
    page_size: 25,
    sort: 'title',
    'sort-order': 'asc',
  },
  appliedFilters: [],
  filters: {},
  treeIsFetching: true,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_NAVIGATED_TO: {
      const { search } = action.payload;
      return update(state, {
        navigatedTo: {
          search: {
            $set: search,
          },
        },
      });
    }
    case types.SELECT_FILTER_TYPE: {
      return update(state, {
        showApplyFilterForField: {
          $set: action.payload,
        },
      });
    }
    case types.ADD_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        appliedFilters: {
          $apply: appliedFilters => uniq([...appliedFilters, key]),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.REMOVE_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        showingAppliedFilters: {
          $set: state.appliedFilters.filter(id => id !== key).length > 0,
        },
        showApplyFilterForField: {
          $set: null,
        },
        appliedFilters: {
          $apply: appliedFilters => appliedFilters.filter(id => id !== key),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.SHOW_APPLIED_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: true,
        showApplyFilterForField: null,
      };
    }
    case types.RESET_FILTER: {
      return {
        ...state,
        isFiltering: false,
        showingAppliedFilters: false,
        appliedFilters: [],
        filters: INITIAL_STATE.filters,
      };
    }
    case types.SHOW_AVAILABLE_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: false,
      };
    }
    /*
      MultiAction
    */
    case types.SELECT_PAGE: {
      if (state.selectedAssets.pageIsSelected) {
        return update(state, {
          selectedAssets: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedAssets.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedAssets: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedAssets.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedAssets.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedAssets.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_ASSET: {
      const assetId = action.payload;
      if (state.selectedAssets.ids[assetId]) {
        return update(state, {
          selectedAssets: {
            ids: {
              $unset: [assetId],
            },
            totalEntriesAreSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== assetId)
          .every(id => state.selectedAssets.ids[id] === true);
        return update(state, {
          selectedAssets: {
            ids: {
              [assetId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedAssets: {
          totalEntriesAreSelected: {
            $set: true,
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedAssets: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesAreSelected: {
            $set: false,
          },
        },
      });
    }
    case types.RESET_SELECTED_ASSETS: {
      return update(state, {
        selectedAssets: {
          $set: {
            ...INITIAL_STATE.selectedAssets,
          },
        },
      });
    }

    case types.FETCH_INITIAL_ASSETS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case types.FETCH_ASSETS_SUCCESS: {
      const { ids, pagination } = action.payload;
      let allRowsAreSelected = ids.every(id => state.selectedAssets.ids[id] === true);
      if (state.selectedAssets.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        selectedAssets: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    }

    case types.FETCH_TREE:
      return {
        ...state,
        treeIsFetching: true,
      };
    case types.FETCH_TREE_SUCCESS: {
      const { tree, totalEntries } = action.payload;
      return {
        ...state,
        treeIsFetching: false,
        tree,
        pagination: {
          ...state.pagination,
          totalEntries,
        },
      };
    }
    case types.EXPAND_TREE:
      return {
        ...state,
        tree: mutateTree(state.tree, action.payload, { isExpanded: true }),
      };
    case types.COLLAPSE_TREE:
      return {
        ...state,
        tree: mutateTree(state.tree, action.payload, { isExpanded: false }),
      };
    case types.MOVE_TREE:
      return {
        ...state,
        tree: moveItemOnTree(state.tree, action.payload.source, action.payload.destination),
      };

    case SDKReduxTypes.ASSET_CREATED: {
      const { data } = action.payload;

      if (state.tree) {
        const items = { ...state.tree.items };
        const parentId = data.tree_parent_id || '1';

        const newTree = {
          ...state.tree,
          items: {
            ...items,
            [data.id]: buildAtlaskitTreeNode(data, []),
            [parentId]: {
              ...items[parentId],
              hasChildren: true,
              children: [data.id, ...items[parentId].children.filter(id => id !== parentId)],
            },
          },
        };

        return update(state, {
          ids: { $unshift: [data.id] },
          tree: { $set: mutateTree(newTree, parentId, { isExpanded: true }) },
          pagination: {
            totalEntries: v => v + 1,
          },
        });
      } else {
        return update(state, {
          ids: { $unshift: [data.id] },
          pagination: {
            totalEntries: v => v + 1,
          },
        });
      }
    }

    case SDKReduxTypes.ASSET_DELETED: {
      const { assetId, params } = action.payload;
      const { tree_children_mode } = params;
      if (state.tree != null) {
        return {
          ...state,
          tree: removeNodeFromAtlaskitTree(state.tree, assetId, { tree_children_mode }),
        };
      } else {
        return state;
      }
    }

    case SDKReduxTypes.ASSET_DUPLICATED: {
      const { data, assetId } = action.payload;

      const parentId = data.tree_parent_id || '1';

      let duplicatedChildren;
      if (data.duplicated_children) {
        duplicatedChildren = data.duplicated_children;
      } else {
        duplicatedChildren = [];
      }

      const newItemsForTree = duplicatedChildren.reduce(
        (acc, child) => ({ ...acc, [child.id]: buildAtlaskitTreeNode(child, duplicatedChildren) }),
        {}
      );

      const newChildrenForParent = state.tree.items[parentId].children.reduce((acc, childId) => {
        if (childId === assetId) {
          return [...acc, childId, data.id];
        } else {
          return [...acc, childId];
        }
      }, []);

      return {
        ...state,
        tree: {
          ...state.tree,
          items: {
            ...state.tree.items,
            ...newItemsForTree,
            [data.id]: buildAtlaskitTreeNode(data, duplicatedChildren),
            [parentId]: {
              ...state.tree.items[parentId],
              children: newChildrenForParent,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
