import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { SparePartTypeInlineModal, SparePartTypePath } from 'views/components/SparePart';

class SparePartTypeField extends Component {
  renderSelectedSparePartType = () => {
    return <SparePartTypePath fullPath sparePartTypeId={this.props.sparePartType.id} />;
  };

  renderEditingField = () => (
    <SparePartTypeInlineModal
      trigger={
        <Field.Resource
          value={this.props.sparePartType ? this.renderSelectedSparePartType() : null}
          angleDown
          onClear={() => this.props.onChange(null)}
        />
      }
      selectedSparePartTypeId={this.props.value}
      onSelectSparePartType={id => this.props.onChange(id)}
      onClear={() => this.props.onChange(null)}
    />
  );

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return this.props.sparePartType ? this.renderSelectedSparePartType() : '-';
    }
  };

  render() {
    return (
      <Field view={!this.props.editing} label={<FormattedMessage id="resources.spare-part.category" />}>
        {this.renderValue()}
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(SparePartTypeField);

SparePartTypeField.propTypes = {
  value: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

SparePartTypeField.defaultProps = {
  editing: false,
};
