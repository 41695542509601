import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NewTab } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { OrganisationOperations, OrganisationSelectors } from 'state/ducks/organisation';
import HelperFunctions from 'utilities/HelperFunctions';
import Users from './Users';
import Systems from './Systems';
import styles from './style.module.scss';

class Organisation extends Component {
  state = {
    searchTerm: '',
  };

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.organisation.document-title' })
    );
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.organisation.title" />
            </div>
            <div className={styles['separator']} />
            <div>
              <NewTab.Container>
                <NewTab
                  selected={
                    matchPath(this.props.location.pathname, {
                      path: '/organisation/users',
                    }) != null
                  }
                  linkTo="/organisation/users"
                >
                  <FormattedMessage id="screens.organisation.tabs.users" />
                </NewTab>
                <NewTab
                  selected={
                    matchPath(this.props.location.pathname, {
                      path: '/organisation/systems',
                    }) != null
                  }
                  linkTo="/organisation/systems"
                >
                  <FormattedMessage id="screens.organisation.tabs.systems" />
                </NewTab>
              </NewTab.Container>
            </div>
          </div>
        }
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({ id: 'general.search' })}
        totalEntries={
          <FormattedMessage
            id="screens.organisation.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        onSearch={searchTerm => this.setState({ searchTerm, isFetching: true })}
        onClearSearch={() => {
          this.setState({ isFetching: true, searchTerm: '' });
        }}
      />
    );
  };

  render() {
    return (
      <ListLayout>
        {this.renderHeader()}
        <ListLayout.Content>
          <Switch>
            <Route
              exact
              path="/organisation"
              render={() => (
                <Redirect
                  to={{
                    pathname: '/organisation/users',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/organisation/users"
              render={props => <Users {...props} searchTerm={this.state.searchTerm} />}
            />
            <Route
              exact
              path="/organisation/systems"
              render={props => <Systems {...props} searchTerm={this.state.searchTerm} />}
            />
          </Switch>
        </ListLayout.Content>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetState: OrganisationOperations.resetState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    pagination: OrganisationSelectors.getPagination(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Organisation)));
