const DOMAIN_PREFIX = 'sparePart/';

const SET_SPARE_PART_ID = `${DOMAIN_PREFIX}set_spare_part_id`;

const FETCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_attachments_success`;
const FETCH_SPARE_PART_ASSETS_SUCCESS = `${DOMAIN_PREFIX}fetch_spare_part_assets_success`;
const FETCH_VENDORS_SUCCESS = `${DOMAIN_PREFIX}fetch_vendors_success`;
const CLEAR_PRIMARY_VENDOR = `${DOMAIN_PREFIX}clear_primary_vendor`;
const FETCH_PRIMARY_VENDOR_SUCCESS = `${DOMAIN_PREFIX}fetch_primary_vendor_success`;
const FETCH_PURCHASE_ORDERS_SUCCESS = `${DOMAIN_PREFIX}fetch_purchase_orders_success`;
const FETCH_WITHDRAWALS_SUCCESS = `${DOMAIN_PREFIX}fetch_withdrawals_success`;

export default {
  SET_SPARE_PART_ID,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_SPARE_PART_ASSETS_SUCCESS,
  FETCH_VENDORS_SUCCESS,
  FETCH_PRIMARY_VENDOR_SUCCESS,
  CLEAR_PRIMARY_VENDOR,
  FETCH_PURCHASE_ORDERS_SUCCESS,
  FETCH_WITHDRAWALS_SUCCESS,
};
