import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { CategoryText, PriorityText } from 'views/components/WorkOrder';
import { DragSource } from 'react-dnd';
import {
  WorkOrderStatus,
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  WorkOrderCategory,
} from 'sdk/WorkOrder';
import { CalendarOperations, CalendarSelectors } from 'state/ducks/calendar';
import { Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { Type } from 'views/scenes/Planning/Calendar/Calendar/utils';
import styles from './style.module.scss';

const cardSource = {
  canDrag({ workOrder, instance, intl }) {
    if (workOrder && workOrder.status === WorkOrderStatus.Completed) {
      toast(
        <ToastMessage
          error
          text={<FormattedMessage id="screens.calendar.errors.cant-drag-completed-work-order" />}
        />
      );
      return false;
    }
    if (instance.loading) {
      return false;
    }
    return true;
  },
  beginDrag(props) {
    const { date, userId, groupId, vendorId, instance } = props;
    const {
      users,
      groups,
      vendors,
      id,
      recurring_maintenance_date: recurringMaintenanceDate,
      work_order: workOrderId,
      recurring_maintenance: recurringMaintenanceId,
    } = instance;
    if (workOrderId) {
      props.beginDrag({
        workOrderId,
      });
      return {
        userId,
        groupId,
        vendorId,
        date,
        users,
        groups,
        vendors,
        instanceId: id,
        workOrderId,
        fromToolboxList: null,
      };
    } else {
      props.beginDrag({
        recurringMaintenanceId,
      });
      return {
        userId,
        groupId,
        vendorId,
        date,
        recurringMaintenanceDate,
        users,
        groups,
        vendors,
        instanceId: id,
        recurringMaintenanceId,
        fromToolboxList: null,
      };
    }
  },
  endDrag(props, monitor, component) {
    props.endDrag();
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class WorkOrder extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage(), { captureDraggingState: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.instance, nextProps.instance) ||
      !isEqual(this.props.workOrder, nextProps.workOrder) ||
      !isEqual(this.props.recurringMaintenance, nextProps.recurringMaintenance) ||
      isEqual(this.props.instance.id, nextProps.hoveringInstanceId) ||
      isEqual(this.props.instance.id, this.props.hoveringInstanceId) ||
      !isEqual(this.props.draggingWorkOrderId, nextProps.draggingWorkOrderId) ||
      !isEqual(this.props.isDragging, nextProps.isDragging)
    );
  }

  getClassNames = () => {
    let classNames = ['overlay-trigger', styles['work-order']];
    if (this.props.instance.type === 'work_order') {
      if (this.props.workOrder.status === WorkOrderStatus.Completed) {
        classNames = [...classNames, styles['completed']];
      } else if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
        classNames = [...classNames, styles['in-progress']];
      } else if (this.props.workOrder.status === WorkOrderStatus.Paused) {
        classNames = [...classNames, styles['paused']];
      }
    }
    if (this.props.instance.id === this.props.hoveringInstanceId) {
      classNames = [...classNames, styles['hovered']];
    }
    if (this.props.instance.loading) {
      classNames = [...classNames, styles['loading']];
    }
    return classNames;
  };

  hasCategory = () => {
    if (this.props.instance.type === 'work_order') {
      return this.props.workOrder?.category != null;
    }
    return this.props.recurringMaintenance?.category != null;
  };

  hasPriority = () => {
    if (this.props.instance.type === 'work_order') {
      return this.props.workOrder?.priority != null;
    }
    return this.props.recurringMaintenance?.priority != null;
  };

  renderPriorityText = () => {
    let priority = null;
    if (this.props.instance.type === 'work_order') {
      priority = this.props.workOrder?.priority;
    } else {
      priority = this.props.recurringMaintenance?.priority;
    }
    switch (priority) {
      case 'high': {
        return <FormattedMessage id="screens.calendar.priority.high" />;
      }
      case 'medium': {
        return <FormattedMessage id="screens.calendar.priority.medium" />;
      }
      case 'low': {
        return <FormattedMessage id="screens.calendar.priority.low" />;
      }

      default:
        return null;
    }
  };

  renderCategoryText = () => {
    let category = null;
    if (this.props.instance.type === 'work_order') {
      category = this.props.workOrder?.category;
    } else {
      category = this.props.recurringMaintenance?.category;
    }
    switch (category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="screens.calendar.category.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return <FormattedMessage id="screens.calendar.category.corrective-immediate" />;
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return <FormattedMessage id="screens.calendar.category.corrective-defered" />;
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="screens.calendar.category.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return <FormattedMessage id="screens.calendar.category.preventive-condition-based" />;
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return <FormattedMessage id="screens.calendar.category.preventive-predetermined" />;
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="screens.calendar.category.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="screens.calendar.category.modification" />;
      }
      default:
        return null;
    }
  };

  renderPriority = () => {
    if (this.hasPriority() == false) {
      return null;
    }

    return <div className={styles['extra-data']}>{this.renderPriorityText()}</div>;
  };

  renderCategory = () => {
    if (this.hasCategory() == false) {
      return null;
    }
    return <div className={styles['extra-data']}>{this.renderCategoryText()}</div>;
  };

  renderExtraData = () => {
    if (this.hasCategory() === false && this.hasPriority() === false) {
      return null;
    }
    return (
      <div className={styles['extra-data-container']}>
        {this.renderPriority()}
        {this.renderCategory()}
      </div>
    );
  };

  renderAsset = () => {
    if (this.props.asset) {
      return <div className={styles['asset']}>{this.props.asset?.title}</div>;
    }
    return null;
  };

  renderContent = () => {
    if (this.props.instance.type === 'work_order') {
      return (
        <div>
          <div className={styles['title-container']}>
            {this.props.workOrder.recurring_maintenance_id ? <Icon regular type="repeat" /> : null}
            <div className={styles['title']}>{this.props.workOrder.title}</div>
            <div className={styles['estimated-time']}>{this.renderEstimatedTime()}</div>
          </div>
          {this.renderAsset()}
          {this.renderExtraData()}
        </div>
      );
    }
    return (
      <div>
        <div className={styles['title-container']}>
          <Icon regular type="repeat" />
          <div className={styles['title']}>{this.props.recurringMaintenance.title}</div>
          <div className={styles['estimated-time']}>{this.renderEstimatedTime()}</div>
        </div>
        {this.renderAsset()}
        {this.renderExtraData()}
      </div>
    );
  };

  renderEstimatedTime = () => {
    let estimatedHrs;
    let estimatedMin;
    if (this.props.instance.type === 'work_order') {
      estimatedHrs = getHoursFromElapsedMinutes(this.props.workOrder.estimated_minutes);
      estimatedMin = getMinutesFromElapsedMinutes(this.props.workOrder.estimated_minutes);
    } else {
      estimatedHrs = getHoursFromElapsedMinutes(this.props.recurringMaintenance.estimated_minutes);
      estimatedMin = getMinutesFromElapsedMinutes(this.props.recurringMaintenance.estimated_minutes);
    }
    if (estimatedHrs === 0 && estimatedMin === 0) return null;
    return (
      <span>
        {estimatedHrs > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-hrs" values={{ value: estimatedHrs }} />
        ) : null}
        <span> </span>
        {estimatedMin > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-min" values={{ value: estimatedMin }} />
        ) : null}
      </span>
    );
  };

  render() {
    const { draggingWorkOrderId, workOrder } = this.props;
    let currentInstanceIsBeingDragged = false;
    if (draggingWorkOrderId && workOrder && workOrder.id === draggingWorkOrderId) {
      currentInstanceIsBeingDragged = true;
    }
    if (this.props.isDragging) {
      currentInstanceIsBeingDragged = true;
    }
    return this.props.connectDragSource(
      <div ref={ref => (this.positionToRef = ref)} style={{ overflow: 'hidden' }}>
        <div
          className={`${this.getClassNames().join(' ')} ${
            currentInstanceIsBeingDragged ? styles['is-dragging'] : ''
          }`}
          onClick={e => {
            e.stopPropagation();
            const { top, left, width } = this.positionToRef.getBoundingClientRect();
            const instanceId = this.props.instance.id;
            this.props.showPreviewWorkOrderOverlay({ top, left: left - 6, width: width + 12, instanceId });
          }}
          onMouseEnter={() => this.props.hoverCalendarInstance(this.props.instance.id)}
          onMouseLeave={() => this.props.hoverCalendarInstance(null)}
        >
          {this.renderContent()}
          {this.props.instance.loading ? (
            <div className={styles['loader']}>
              <Loader tiny />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hoverCalendarInstance: CalendarOperations.hoverCalendarInstance,
      showPreviewWorkOrderOverlay: CalendarOperations.showPreviewWorkOrderOverlay,
      beginDrag: CalendarOperations.beginDrag,
      endDrag: CalendarOperations.endDrag,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.instance.type === 'work_order') {
    const workOrder = EntitySelectors.getWorkOrder(state, ownProps.instance.work_order);
    return {
      workOrder,
      asset: EntitySelectors.getAsset(state, workOrder?.asset_id),
      hoveringInstanceId: CalendarSelectors.getHoveringInstanceId(state),
      draggingWorkOrderId: CalendarSelectors.getDraggingWorkOrderId(state),
    };
  }
  const recurringMaintenance = EntitySelectors.getRecurringMaintenance(
    state,
    ownProps.instance.recurring_maintenance
  );
  return {
    recurringMaintenance,
    asset: EntitySelectors.getAsset(state, recurringMaintenance?.asset_id),
    hoveringInstanceId: CalendarSelectors.getHoveringInstanceId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DragSource(Type.Calendar, cardSource, collect)(WorkOrder))
);
