import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';
import { bindActionCreators } from 'redux';
import { normalizeLoginCredential } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Field, Button, FieldErrorWrapper } from 'views/components/Shared/General';

class ActivateLoginModal extends Component {
  getInitialState = () => ({
    isActivating: false,
    email: '',
    password: '',
    confirmPassword: '',
    showPasswordsNotMatchingError: false,
    showPasswordEmptyError: false,
    showEmailEmptyError: false,
    showAlreadyExistingEmailError: false,
    showInvalidEmailError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  activate = () => {
    this.setState({ showAlreadyExistingEmailError: false });
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ showPasswordsNotMatchingError: true });
      return;
    }
    if (this.state.password === '' && this.state.email === '') {
      this.setState({ showPasswordEmptyError: true, showEmailEmptyError: true });
      return;
    }
    if (this.state.password === '') {
      this.setState({ showPasswordEmptyError: true });
      return;
    }
    if (this.state.email === '') {
      this.setState({ showEmailEmptyError: true });
      return;
    }
    const params = {
      username: this.state.email,
      password: this.state.password,
    };
    this.setState({ isActivating: true });
    API.createLoginCredentialForGroup(this.props.currentSystem.id, params)
      .then(({ data: loginCredential }) => {
        const { entities } = normalizeLoginCredential(loginCredential);
        this.props.updateEntities(entities);
        this.setState({ isActivating: false });
        this.props.onActivated(loginCredential);
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'username' })) {
          this.setState({ showAlreadyExistingEmailError: true, isActivating: false });
        } else if (HelperFunctions.hasError(e, { code: '10018', key: 'username' })) {
          this.setState({ showInvalidEmailError: true, isActivating: false });
        } else {
          this.setState({ isActivating: false });
        }
      });
  };

  renderEmailErrorLabel = () => {
    if (this.state.showAlreadyExistingEmailError) {
      return <FormattedMessage id="screens.users.group-logins.modal.email-in-use" />;
    }
    if (this.state.showInvalidEmailError) {
      return <FormattedMessage id="general.errors.invalid-email" />;
    }
    return null;
  };

  renderFields = () => {
    const { showAlreadyExistingEmailError, showInvalidEmailError, showEmailEmptyError } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.username" />}>
              <FieldErrorWrapper
                show={showAlreadyExistingEmailError || showInvalidEmailError}
                errorElement={this.renderEmailErrorLabel()}
              >
                <Field.Text
                  autoFocus
                  error={showEmailEmptyError || showAlreadyExistingEmailError || showInvalidEmailError}
                  value={this.state.email}
                  onChange={email =>
                    this.setState({
                      email,
                      showEmailEmptyError: false,
                      showAlreadyExistingEmailError: false,
                      showInvalidEmailError: false,
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
              <Field.Text
                type="password"
                autocomplete="new-password"
                error={this.state.showPasswordEmptyError}
                value={this.state.password}
                onChange={password => this.setState({ password, showPasswordEmptyError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="screens.users.action-modal.confirm-password" />}>
              <FieldErrorWrapper
                show={this.state.showPasswordsNotMatchingError}
                errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
              >
                <Field.Text
                  type="password"
                  autocomplete="new-password"
                  error={this.state.showPasswordsNotMatchingError || this.state.showPasswordEmptyError}
                  value={this.state.confirmPassword}
                  onChange={confirmPassword => {
                    if (this.state.showPasswordsNotMatchingError) {
                      this.setState({ showPasswordsNotMatchingError: false });
                    }
                    this.setState({ confirmPassword });
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.group-logins.modal.login-details" />}
          subtitle={<FormattedMessage id="screens.users.group-logins.modal.activate-subtitle" />}
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isActivating}
                label="general.activate"
                onClick={() => {
                  this.activate();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateLoginModal);
