const isFetchingOperationalMaintenanceVersion = state => {
  const { operationalMaintenanceTemplate } = state;
  return operationalMaintenanceTemplate.isFetchingOperationalMaintenanceVersion;
};

const getCurrentOperationalMaintenanceTemplateVersion = state => {
  const { entities, operationalMaintenanceTemplate } = state;
  const { currentOperationalMaintenanceTemplateVersionId } = operationalMaintenanceTemplate;
  return entities.operationalMaintenanceTemplateVersionById[currentOperationalMaintenanceTemplateVersionId];
};

const getActiveOperationalMaintenanceTemplateVersion = state => {
  const { entities, operationalMaintenanceTemplate } = state;
  const { activeOperationalMaintenanceTemplateVersionId } = operationalMaintenanceTemplate;
  return entities.operationalMaintenanceTemplateVersionById[activeOperationalMaintenanceTemplateVersionId];
};

export default {
  isFetchingOperationalMaintenanceVersion,
  getCurrentOperationalMaintenanceTemplateVersion,
  getActiveOperationalMaintenanceTemplateVersion,
};
