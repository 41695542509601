import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ to }) => (
  <Link to={to}>
    <div className={styles['go-back']}>
      <Icon type="arrow-alt-left" />
      <FormattedMessage id="general.back" />
    </div>
  </Link>
);
