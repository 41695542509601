import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { VendorAvatar } from 'views/components/Vendor';
import { ChooseAssigneeInlineModal } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';

class RegisteredByContainer extends Component {
  renderAssignee = () => {
    if (this.props.vendorId) {
      return (
        <Field.Resource
          leftComponent={<VendorAvatar vendor={this.props.vendor} size={20} fontSize={10} />}
          value={this.props.vendor.name}
          clearable={false}
          clickable
        />
      );
    }
    return (
      <Field.Resource
        leftComponent={<ProfilePicture userId={this.props.userId} size={20} fontSize={10} />}
        value={<UserNameWrapper user={this.props.user} />}
        clearable={false}
        clickable
      />
    );
  };
  render() {
    return (
      <Field view={false} label={<FormattedMessage id="resources.work-order-spent-time.performed-by" />}>
        <ChooseAssigneeInlineModal
          hideArchivedUsers
          trigger={this.renderAssignee()}
          clearable={false}
          hideGroups
          selectedUserId={this.props.userId}
          selectedVendorId={this.props.vendorId}
          onSelectUser={user => this.props.onChangeUser(user)}
          onSelectVendor={vendor => this.props.onChangeVendor(vendor)}
        />
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.userId),
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

export default connect(mapStateToProps)(RegisteredByContainer);
