import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { List, ElapsedTime } from 'views/components/Shared/General';
import { DowntimeTimer } from 'views/components/Downtime';
import styles from './style.module.scss';

const DowntimeListItem = ({ loading, downtime, onClick }) => {
  const getTotalStopTimeAsMinutes = () => {
    const fromDate = moment(downtime.from);
    const toDate = moment(downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  const renderSubtitle = () => {
    if (downtime.to == null) {
      return (
        <span className={styles['active']}>
          <FormattedMessage
            id="screens.asset.downtimes.downtime-is-active"
            values={{
              timer: <DowntimeTimer downtime={downtime} />,
            }}
          />
        </span>
      );
    }
    return <ElapsedTime elapsedMinutes={getTotalStopTimeAsMinutes()} />;
  };
  if (loading) {
    return (
      <List.Item>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }
  return (
    <List.Item clickable onClick={onClick}>
      <List.Item.TitleColumn title={moment(downtime.from).format('LLL')} subtitle={renderSubtitle()} />
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    downtime: EntitySelectors.getDowntime(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(DowntimeListItem);
