import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetTitle, TreePath } from 'views/components/Asset';
import { Icon } from 'views/components/Shared/General';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import styles from './style.module.scss';

class TemplateAssetListItem extends Component {
  renderChecklistSettingsWarningIcon = () => {
    const optionsOnChecklist = this.props.checklistTemplateRows.filter(
      ({ require_option }) => require_option === true
    ).length;
    const optionsOnTemplateAsset = this.props.checklistInstanceOptions.length;
    if (optionsOnChecklist > optionsOnTemplateAsset) {
      return (
        <div className={styles['warning-icon-container']}>
          <Icon solid red type="exclamation-triangle" size={12} />
        </div>
      );
    }
    return null;
  };

  render() {
    let classNames = [styles['item']];
    if (this.props.selected) {
      classNames = [...classNames, styles['selected']];
    }
    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          this.props.onClick();
        }}
      >
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <AssetTitle id={this.props.asset.id} />
          </div>
          <div>
            <TreePath assetId={this.props.asset.id} />
          </div>
        </div>
        {this.renderChecklistSettingsWarningIcon()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    id
  );
  const activeOperationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  let checklistTemplateRows = [];
  let checklistTemplateLinks = [];
  let checklistTemplate = null;
  let checklistTemplateVersion = null;
  checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    activeOperationalMaintenanceTemplateVersion.checklist_template_links
  );
  if (checklistTemplateLinks.length === 1) {
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLinks[0].checklist_template_id
    );
  }
  if (checklistTemplate) {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version
    );
  }
  if (checklistTemplateVersion) {
    checklistTemplateRows = EntitySelectors.getChecklistTemplateRows(
      state,
      checklistTemplateVersion.checklist_template_rows
    );
  }

  return {
    checklistTemplate,
    checklistTemplateRows,
    asset: EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id),
    checklistInstanceOptions: EntitySelectors.getChecklistInstanceOptions(
      state,
      operationalMaintenanceTemplateAsset.checklist_instance_options
    ),
  };
}

export default connect(mapStateToProps)(TemplateAssetListItem);
