import React, { Component } from 'react';
import { ContentContainer } from 'views/components/Shared/Layout';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import { MeterOperations, MeterSelectors } from 'state/ducks/meter';
import { connect } from 'react-redux';
import { List, Pagination } from 'views/components/Shared/General';
import MeterImage from 'assets/images/EmptyDataSet/Meters.png';
import Header from '../../Header';
import MeterReadingListItem from './MeterReadingListItem';
import styles from './style.module.scss';

class History extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      isFetchingInititalMeterReadings: false,
      isSearchingMeterReadings: false,
      queryParams,
    };
  }
  componentDidMount() {
    this.setState({ isFetchingInititalMeterReadings: true });
    this.listMeterReadings();
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams, isSearchingMeterReadings: true }, () => {
        this.listMeterReadings();
      });
    }
  }

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  listMeterReadings = () => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);
    this.setState({ isLoadingReadings: true });
    this.props
      .listMeterReadings(this.props.match.params.id, {
        page_size: 8,
        ...filterParams,
      })
      .then(res => {
        this.setState({ isSearchingMeterReadings: false, isFetchingInititalMeterReadings: false });
      });
  };

  renderPagination = () => {
    if (this.state.isFetchingInititalMeterReadings) {
      return null;
    }
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.meter.tabs.history.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderEmptyDataSet = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>{<FormattedMessage id="screens.meter.empty-data-sets.title" />}</div>
        <div className={styles['subtitle']}>
          {<FormattedMessage id="screens.meter.empty-data-sets.subtitle" />}
        </div>
        <div className={styles['image-container']}>
          <img src={MeterImage} alt="" />
        </div>
      </div>
    );
  };

  renderListContent = () => {
    if (this.state.isFetchingInititalMeterReadings) {
      return (
        <>
          <MeterReadingListItem loading />
          <MeterReadingListItem loading />
        </>
      );
    }
    if (this.state.isSearchingMeterReadings) {
      return this.props.meterReadingIds.map(() => {
        return <MeterReadingListItem loading />;
      });
    }
    return this.props.meterReadingIds.map(meterReadingId => {
      return <MeterReadingListItem key={meterReadingId} id={meterReadingId} />;
    });
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.meter.date" />
        </List.Header.Column>
        <List.Header.Column width={200}>
          <FormattedMessage id="resources.meter.user" />
        </List.Header.Column>
        <List.Header.Column alignRight width={200}>
          <FormattedMessage id="resources.meter.value" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.renderListHeader()}
        <List>{this.renderListContent()}</List>
        {this.renderPagination()}
      </>
    );
  };
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer maxWidth={960}>
          {this.props.meterReadingIds.length === 0 && !this.state.isLoadingReadings
            ? this.renderEmptyDataSet()
            : this.renderContent()}
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listMeterReadings: MeterOperations.listMeterReadings,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    meterReadingIds: MeterSelectors.getMeterReadings(state),
    pagination: MeterSelectors.getMeterReadingsPagination(state),
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(History));
