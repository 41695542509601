import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListLayout } from 'views/components/Shared/Layout';
import { injectIntl, FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { List, Icon, PathItem } from 'views/components/Shared/General';
import { API, Channels } from 'sdk';
import { normalizeAttachment } from 'sdk/Schemas';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import { SortInlineModal } from 'views/components/Attachment';
import { AttachmentListItem } from 'views/components/Attachment';
import { MenuOperations } from 'state/ducks/menu';
import { MenuUtils } from 'state/ducks/menu';
import styles from './style.module.scss';
import AttachmentFolders from 'views/components/Asset/AssetAttachmentContent/AttachmentFolders';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import AttachmentListItemContainer from './AttachmentListItemContainer';

const PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA = 350;
const PAGE_SIZE = 20;

class FilesForAsset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentCount: [],
      isFetching: true,
      isSearching: false,
      isFilteringFiles: false,
      isFetchingMoreFiles: false,
      canFetchMoreData: false,
      paginateFrom: null,
      searchValue: '',
      showSortInlineModal: false,
      attachmentIds: [],
      attachmentCountPerFolderId: {},
      folderId: null,
      sort: 'title',
      'sort-order': 'asc',
    };
  }

  folderChannel = null;

  componentDidMount() {
    this.loadInitialAttachments();
    this.folderChannel = this.props.joinAttachmentFoldersChannel(this.props.currentSystem.id);
    this.fetchCountForFolders();
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.files.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Files);
  }

  componentDidUpdate(prevProps) {
    const { checkedInAssetId: prevCheckedInAssetId } = prevProps;
    const { checkedInAssetId } = this.props;
    const changedCheckedInAsset = prevCheckedInAssetId !== checkedInAssetId;
    if (checkedInAssetId && changedCheckedInAsset) {
      this.setState({ isFetching: true });
      this.fetchCountForFolders();
      this.fetchAttachments();
    }
  }

  fetchCountForFolders = () => {
    const attrs = {
      attachment_folder_ids: this.props.allAttachmentFolders.map(folder => folder.id).join(),
      include_related_assets: true,
    };
    this.folderChannel
      .push(`get_attachment_count:${this.props.checkedInAssetId}`, attrs)
      .receive('ok', payload => {
        const everyFolderIsEmpty = Object.keys(payload.count).every(key => payload.count[key] === 0);
        this.setState({ everyFolderIsEmpty, attachmentCountPerFolderId: payload.count });
      });
  };

  loadInitialAttachments = () => {
    if (this.props.checkedInAssetId == null) {
      this.setState({ isFetching: false });
    }
    this.setState({ isFetching: true });
    this.fetchAttachments().then(() => {
      this.setState({ isFetching: false });
    });
  };

  fetchAttachments = () => {
    return API.listAttachmentsForAsset(this.props.checkedInAssetId, this.getParams())
      .then(({ data, headers }) => {
        const { paginateFrom, totalEntries } = SDKHelperFunctions.getPaginationFromHeader(headers);
        const { entities, result } = normalizeAttachment(data);
        this.props.updateEntities(entities);
        this.setState({
          isFetching: false,
          isSearching: false,
          isFilteringFiles: false,
          attachmentIds: result,
          totalEntries,
          paginateFrom,
          canFetchMoreData: data.length >= 20,
        });
      })
      .catch(e => {});
  };

  fetchMoreAttachments = () => {
    if (this.state.isFetchingMoreFiles || !this.state.canFetchMoreData) return;
    this.setState({ isFetchingMoreFiles: true });

    return API.listAttachmentsForAsset(this.props.checkedInAssetId, {
      ...this.getParams(),
      paginate_from: this.state.paginateFrom,
    })
      .then(({ data, headers }) => {
        const { paginateFrom, totalEntries } = SDKHelperFunctions.getPaginationFromHeader(headers);
        const { entities, result } = normalizeAttachment(data);
        this.props.updateEntities(entities);
        this.setState({
          isFetchingMoreFiles: false,
          attachmentIds: [...this.state.attachmentIds, ...result],
          totalEntries,
          paginateFrom,
          canFetchMoreData: data.length >= 20,
        });
      })
      .catch(e => {});
  };

  getParams = () => {
    let params = {
      page_size: PAGE_SIZE,
      sort: this.state.sort,
      'sort-order': this.state['sort-order'],
      include_related_assets: true,
    };
    if (this.state.searchValue.length > 0) {
      params = {
        ...params,
        search: this.state.searchValue,
      };
    } else {
      if (this.state.folderId) {
        params = {
          ...params,
          attachment_folder_id: this.state.folderId,
        };
      } else {
        params = {
          ...params,
          attachment_folder_id: {
            [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
          },
        };
      }
    }

    return params;
  };

  buildFolderPathForFolderId = folderId => {
    const attachmentFolder = this.props.allAttachmentFolders.find(
      attachmentFolder => folderId === attachmentFolder.id
    );
    if (attachmentFolder == null) return [];
    let attachmentFolders = [attachmentFolder];
    this.props.allAttachmentFolders.forEach(loopedAttachmentFolder => {
      if (attachmentFolder.attachment_folder_parent_id === loopedAttachmentFolder.id) {
        attachmentFolders = [
          ...this.buildFolderPathForFolderId(loopedAttachmentFolder.id),
          ...attachmentFolders,
        ];
      }
    });
    return attachmentFolders;
  };

  renderCurrentAssetForFolderPathTitle = () => {
    if (this.props.checkedInAsset) {
      return this.props.checkedInAsset.title;
    }
    return <FormattedMessage id="general.yes" />;
  };

  renderFolderPathTitle = () => {
    const { folderId } = this.state;
    const folderPath = this.buildFolderPathForFolderId(folderId);
    return (
      <div className={styles['folder-path']}>
        <PathItem
          active={folderId == null}
          clickable={folderId != null}
          onClick={() => {
            this.setState({ folderId: null, isFilteringFiles: true }, () => this.fetchAttachments());
          }}
        >
          {this.renderCurrentAssetForFolderPathTitle()}
        </PathItem>
        {folderId == null ? null : (
          <React.Fragment>
            {folderPath.map((attachmentFolder, index) => {
              if (index !== folderPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem
                      clickable
                      onClick={() => {
                        this.setState({ folderId: attachmentFolder.id, isFilteringFiles: true }, () =>
                          this.fetchAttachments()
                        );
                      }}
                    >
                      {attachmentFolder.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem active>{attachmentFolder.title}</PathItem>
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  fetchMoreLoader = () => (
    <>
      <AttachmentListItem loading />
      <AttachmentListItem loading />
    </>
  );

  renderEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.files.empty-data-set-operator.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.files.empty-data-set-operator.subtitle" />
      </div>
    </div>
  );

  renderNoCheckedInAssetEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.files.no-checked-in-asset-empty-data-set.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.files.no-checked-in-asset-empty-data-set.subtitle" />
        </div>
      </div>
    );
  };

  renderFileList = () => {
    if (this.state.isFilteringFiles) {
      return (
        <List>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.attachmentIds.length === 0) {
      if (this.state.searchValue.length > 0 || this.state.folderId) {
        return (
          <div className={styles['list-container']}>
            <div className={styles['empty-data-set-container']}>
              <div className={styles['title']}>
                <FormattedMessage id="general.empty-data-set-search.title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="general.empty-data-set-search.subtitle" />
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <List>
        {this.state.attachmentIds.map(id => (
          <AttachmentListItemContainer key={id} id={id} />
        ))}
        {this.state.isFetchingMoreFiles ? this.fetchMoreLoader() : null}
      </List>
    );
  };

  renderFiles = () => {
    return (
      <>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.files.all-files-title" />
            </div>
          </div>
          <SortInlineModal
            onSort={(sort, sortOrder) => {
              this.setState({ isFilteringFiles: true, sort, 'sort-order': sortOrder }, () =>
                this.fetchAttachments()
              );
            }}
            sort={this.state.sort}
          />
        </div>
        <div className={styles['list-container']}>{this.renderFileList()}</div>
      </>
    );
  };

  renderFolders = () => (
    <AttachmentFolders
      attachmentCountPerFolderId={this.state.attachmentCountPerFolderId}
      assetId={this.props.checkedInAssetId}
      parentFolderId={this.state.folderId}
      onSelect={attachmentFolder => {
        this.setState({ isFilteringFiles: true, folderId: attachmentFolder.id }, () =>
          this.fetchAttachments()
        );
      }}
    />
  );

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <List>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.props.checkedInAssetId == null) {
      return this.renderNoCheckedInAssetEmptyDataset();
    }
    if (this.state.isSearching) {
      return (
        <List>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }

    if (
      this.state.attachmentIds.length === 0 &&
      this.state.folderId == null &&
      this.state.searchValue.length === 0 &&
      this.state.isFilteringFiles === false
    ) {
      return this.renderEmptyDataset();
    }
    if (this.state.searchValue.length > 0) {
      return this.renderFileList();
    }
    return (
      <>
        {this.renderFolderPathTitle()}
        {this.renderFolders()}
        {this.renderFiles()}
      </>
    );
  };

  render() {
    return (
      <ListLayout>
        <ListLayout.Header
          title={<FormattedMessage id="screens.files.title" />}
          searchable
          debounce
          searchValue={this.state.searchValue}
          searchPlaceHolder={this.props.intl.formatMessage({
            id: 'screens.files.search-field-placeholder',
          })}
          onSearch={searchValue => {
            this.setState({ searchValue, folderId: null, isSearching: true });
          }}
          onDebouncedSearch={searchValue => {
            this.setState({ searchValue, isSearching: true }, () => {
              this.fetchAttachments();
            });
          }}
          onClearSearch={() => {
            this.setState({ searchValue: '', folderId: null, isSearching: true }, () => {
              this.fetchAttachments();
            });
          }}
        />
        <PerfectScrollbar>
          <InfiniteScroll
            loadMore={this.fetchMoreAttachments}
            hasMore={this.state.canFetchMoreData}
            useWindow={false}
            initialLoad={false}
            threshold={PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA}
          >
            <ListLayout.Content>
              <ListLayout.Content.MainContent>
                <ListLayout.Content.MainContent.Content>
                  {this.renderContent()}
                </ListLayout.Content.MainContent.Content>
              </ListLayout.Content.MainContent>
            </ListLayout.Content>
          </InfiniteScroll>
        </PerfectScrollbar>
      </ListLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
      joinAttachmentFoldersChannel: Channels.AttachmentFoldersChannel.join,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    checkedInAsset: OperatorCheckedInAssetSelectors.getAsset(state),
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FilesForAsset));
