import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { AssetTypeInlineModal } from 'views/components/Asset';

class AssetTypeField extends Component {
  renderEditingField = () => (
    <AssetTypeInlineModal
      trigger={
        <Field.Resource
          value={this.props.assetType ? this.props.assetType.title : null}
          angleDown
          onClear={() => this.props.onChange(null)}
        />
      }
      selectedAssetTypeId={this.props.value}
      onSelectAssetType={assetTypeId => this.props.onChange(assetTypeId)}
      onClearAssetType={() => this.props.onChange(null)}
    />
  );

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return this.props.assetType ? this.props.assetType.title : '-';
    }
  };

  render() {
    return (
      <Field view={!this.props.editing} label={<FormattedMessage id="resources.asset.type" />}>
        {this.renderValue()}
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetType: EntitySelectors.getAssetType(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(AssetTypeField);

AssetTypeField.propTypes = {
  value: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

AssetTypeField.defaultProps = {
  editing: false,
};
