import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Decimal } from 'decimal.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';
import { Button, Menu } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import PermissionProfiles from './PermissionProfiles';
import { API } from 'sdk';
import { UsersOperations, UsersSelectors } from 'state/ducks/users';
import { AuthSelectors } from 'state/ducks/auth';
import Users from './Users';
import OperatorLogin from './OperatorLogin';
import CreateUserModal from './Users/components/CreateUserModal';
import styles from './style.module.scss';

export const MENU_ITEMS = {
  Members: 'members',
  ProductionSupervisor: 'production_supervisor',
  Operator: 'operator',
  WorkRequester: 'work_requester',
  PermissionProfiles: 'permission_profiles',
  OperatorLogin: 'operator_login',
  Archived: 'archived',
  ViewOnly: 'view_only',
};

class UsersContainer extends Component {
  constructor(props) {
    super(props);
    let fullPricePerUser = 299;
    if (props.hasProTier) {
      fullPricePerUser = 699;
    }
    this.state = {
      showCreateUserModal: false,
      searchTerm: '',
      discounts: [],
      fullPricePerUser,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.users.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Settings);
    const { type } = queryString.parse(this.props.location.search);
    if (type == null) {
      let search = 'type=members';
      if (this.props.isProductionSupervisor) {
        search = 'type=operator';
      }
      this.props.history.replace({ pathname: '/users', search });
    }

    // API.listBillingDiscounts(this.props.organisation.id).then(({ data: discounts }) => {
    //   const discountWithoutExpirationDate = discounts.find(
    //     ({ expiration_date, tier }) => expiration_date == null && tier === this.props.organisation.tier
    //   );
    //   if (discountWithoutExpirationDate) {
    //     this.setState({
    //       fullPricePerUser: discountWithoutExpirationDate.price_full,
    //     });
    //   } else {
    //     this.setState({
    //       discounts: discounts.filter(({ expiration_date, tier }) => {
    //         const hasNotExpired = moment(expiration_date).diff(moment(), 'days') >= 0;
    //         const isSameTierAsOrganisation = tier === this.props.organisation.tier;
    //         return hasNotExpired && isSameTierAsOrganisation;
    //       }),
    //     });
    //   }
    // });
    this.props.fetchAndSetAmountOfPayingUsers(this.props.system.id);
  }

  componentDidUpdate(prevProps) {
    const { type: prevType } = queryString.parse(prevProps.location.search);
    const { type } = queryString.parse(this.props.location.search);
    const userMenuItems = [MENU_ITEMS.Members, MENU_ITEMS.Operator, MENU_ITEMS.ProductionSupervisor];
    if (userMenuItems.includes(prevType) && !userMenuItems.includes(type)) {
      this.setState({ searchTerm: '' });
    }
  }

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  renderTotalEntries = () => {
    const { type } = queryString.parse(this.props.location.search);
    const userMenuItems = [MENU_ITEMS.Members, MENU_ITEMS.Operator, MENU_ITEMS.ProductionSupervisor];
    if (userMenuItems.includes(type)) {
      return (
        <FormattedMessage
          id="screens.users.total-entries-users"
          values={{
            amount: this.props.totalEntries,
          }}
        />
      );
    }
    if (type === MENU_ITEMS.PermissionProfiles) {
      return (
        <FormattedMessage
          id="screens.users.total-entries-permission-profiles"
          values={{
            amount: this.props.totalEntries,
          }}
        />
      );
    }
    return null;
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.users.title" />
            </div>
            <div>
              <Link to="/settings/users">
                <Button gray label="screens.users.go-back-to-settings" />
              </Link>
            </div>
          </div>
        }
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({ id: 'general.search' })}
        totalEntries={this.renderTotalEntries()}
        onSearch={searchTerm => this.setState({ searchTerm })}
        onClearSearch={() => {
          this.setState({ searchTerm: '' });
        }}
      />
    );
  };

  renderCreateButton = () => {
    return (
      <>
        <Button
          fullWidth
          primary
          label="screens.users.create-button"
          onClick={() => {
            this.setState({ showCreateUserModal: true });
          }}
        />
      </>
    );
  };

  renderCreateUserModal = () => {
    const { type } = queryString.parse(this.props.location.search);
    let userType = null;
    if (type === MENU_ITEMS.Members) {
      userType = 'member';
    } else if (type === MENU_ITEMS.ProductionSupervisor) {
      userType = 'productionSupervisor';
    } else if (type === MENU_ITEMS.ViewOnly) {
      userType = 'viewOnly';
    } else if (type === MENU_ITEMS.WorkRequester) {
      userType = 'workRequester';
    } else if (type === MENU_ITEMS.Operator) {
      userType = 'operator';
    } else {
      userType = 'member';
    }
    if (this.props.isProductionSupervisor) {
      userType = 'operator';
    }
    return (
      <CreateUserModal
        open={this.state.showCreateUserModal}
        userType={userType}
        onCreated={() => {
          this.setState({ showCreateUserModal: false });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showCreateUserModal: false });
          setTimeout(() => {
            this.setState({ showCreateUserModal: true });
          }, 200);
        }}
        onClose={() => this.setState({ showCreateUserModal: false })}
      />
    );
  };

  renderFormattedPrice = price => {
    return new Decimal(price)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  renderDiscounts = () => {
    return null;

    if (this.props.organisation.demo) return null;
    if (this.props.organisation.multiple_systems) return null;
    const { billable_users_cap } = this.props.organisation;
    const hasDiscounts = this.state.discounts.length > 0;
    let amountOfPayingMembersIncludingUserCap = this.props.amountOfPayingMembers;
    if (billable_users_cap && billable_users_cap < this.props.amountOfPayingMembers) {
      amountOfPayingMembersIncludingUserCap = billable_users_cap;
    }
    return (
      <div className={styles['discount-container']}>
        <div className={styles['discount']}>
          {this.state.isFetchingDataForView ? (
            <div style={{ height: '25px', width: '100%' }}>
              <ContentLoader
                speed={2}
                width={60}
                height={10}
                viewBox="0 0 60 10"
                primaryColor="#b9b9b9"
                secondaryColor="#9c9c9c"
              >
                <rect x="1" y="1" rx="3" ry="3" width="60" height="6" />
              </ContentLoader>
            </div>
          ) : (
            <div className={styles['price']}>
              <span className={`${hasDiscounts ? styles['has-discounts'] : ''}`}>
                {this.renderFormattedPrice(
                  this.state.fullPricePerUser * amountOfPayingMembersIncludingUserCap
                )}
              </span>
              <span className={styles['per-month']}>
                <span> </span>
                <FormattedMessage id="screens.users.sek-per-month" />
              </span>
            </div>
          )}
          {this.state.discounts.map(({ expiration_date, price_full }) => (
            <div className={styles['discount-row']}>
              <FormattedMessage
                id="screens.users.discount-text"
                values={{
                  date: moment(expiration_date).format('LL'),
                  amount: (
                    <span className={styles['discount-row-price-text']}>
                      {this.renderFormattedPrice(price_full * amountOfPayingMembersIncludingUserCap)}
                    </span>
                  ),
                }}
              />
            </div>
          ))}
          <div className={styles['amount-of-users']}>
            <div className={styles['separator']} />
            <FormattedMessage
              id="screens.users.amount-of-users"
              values={{ count: this.props.amountOfPayingMembers }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderLeftMenu = () => {
    const { type } = queryString.parse(this.props.location.search);
    if (this.props.isProductionSupervisor) {
      return (
        <ListLayout.Content.Menu>
          <div className={styles['left-panel-container']}>
            <PerfectScrollbar>
              <div className={styles['list-buttons']}>
                <ListLayout.Content.Menu.Content>
                  {this.renderCreateButton()}
                  <Menu.Separator />
                  <Menu.Item
                    linkTo={{
                      pathname: '/users',
                      search: `type=${MENU_ITEMS.Operator}`,
                    }}
                    title={<FormattedMessage id="screens.users.operators.title" />}
                    selected={type == MENU_ITEMS.Operator}
                  />
                </ListLayout.Content.Menu.Content>
              </div>
            </PerfectScrollbar>
          </div>
        </ListLayout.Content.Menu>
      );
    }
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <PerfectScrollbar>
            <div className={styles['list-buttons']}>
              <ListLayout.Content.Menu.Content>
                {this.renderCreateButton()}
                <Menu.Separator />
                <Menu.Item
                  linkTo={{
                    pathname: '/users',
                    search: `type=${MENU_ITEMS.Members}`,
                  }}
                  title={<FormattedMessage id="screens.users.members.title" />}
                  selected={type == MENU_ITEMS.Members}
                />
                {this.props.settings.production_board_activated ? (
                  <Menu.Item
                    linkTo={{
                      pathname: '/users',
                      search: `type=${MENU_ITEMS.ProductionSupervisor}`,
                    }}
                    title={<FormattedMessage id="screens.users.production-supervisors.title" />}
                    selected={type == MENU_ITEMS.ProductionSupervisor}
                  />
                ) : null}
                {this.props.settings.production_board_activated ? (
                  <Menu.Item
                    linkTo={{
                      pathname: '/users',
                      search: `type=${MENU_ITEMS.Operator}`,
                    }}
                    title={<FormattedMessage id="screens.users.operators.title" />}
                    selected={type == MENU_ITEMS.Operator}
                  />
                ) : null}
                <Menu.Item
                  linkTo={{
                    pathname: '/users',
                    search: `type=${MENU_ITEMS.WorkRequester}`,
                  }}
                  title={<FormattedMessage id="screens.users.work-requesters.title" />}
                  selected={type == MENU_ITEMS.WorkRequester}
                />
                <Menu.Item
                  linkTo={{
                    pathname: '/users',
                    search: `type=${MENU_ITEMS.ViewOnly}`,
                  }}
                  title={<FormattedMessage id="screens.users.view-only.title" />}
                  selected={type == MENU_ITEMS.ViewOnly}
                />
                <Menu.Item
                  linkTo={{
                    pathname: '/users',
                    search: `type=${MENU_ITEMS.Archived}`,
                  }}
                  red
                  title={<FormattedMessage id="screens.users.archived.title" />}
                  selected={type == MENU_ITEMS.Archived}
                />
                <Menu.Separator />
                <Menu.Item
                  linkTo={{
                    pathname: '/users',
                    search: `type=${MENU_ITEMS.PermissionProfiles}`,
                  }}
                  title={<FormattedMessage id="screens.users.permission-profiles.title" />}
                  selected={type == MENU_ITEMS.PermissionProfiles}
                />
                {this.props.settings.production_board_activated ? (
                  <Menu.Item
                    linkTo={{
                      pathname: '/users',
                      search: `type=${MENU_ITEMS.OperatorLogin}`,
                    }}
                    title={<FormattedMessage id="screens.users.operator-login" />}
                    selected={type == MENU_ITEMS.OperatorLogin}
                  />
                ) : null}
              </ListLayout.Content.Menu.Content>
            </div>
          </PerfectScrollbar>
          {this.renderDiscounts()}
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    const { type } = queryString.parse(this.props.location.search);
    if (
      type == null ||
      [
        MENU_ITEMS.Archived,
        MENU_ITEMS.Members,
        MENU_ITEMS.Operator,
        MENU_ITEMS.WorkRequester,
        MENU_ITEMS.ProductionSupervisor,
        MENU_ITEMS.ViewOnly,
      ].includes(type)
    ) {
      return <Users searchTerm={this.state.searchTerm} />;
    }
    if (type === MENU_ITEMS.PermissionProfiles) {
      return <PermissionProfiles searchTerm={this.state.searchTerm} />;
    }
    if (type === MENU_ITEMS.OperatorLogin) {
      return <OperatorLogin />;
    }
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderCreateUserModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      setAmountOfPayingUsers: UsersOperations.setAmountOfPayingUsers,
      fetchAndSetAmountOfPayingUsers: UsersOperations.fetchAndSetAmountOfPayingUsers,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    totalEntries: UsersSelectors.getTotalEntries(state),
    settings: AuthSelectors.getSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
    amountOfPayingMembers: UsersSelectors.getAmountOfPayingMembers(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersContainer)));
