import React from 'react';
import styles from './style.module.scss';

const Title = ({ inline = false, workOrder }) => {
  if (inline) {
    return (
      <>
        <span className={styles['title']}>{workOrder.title}</span>
        <span className={styles['number']}>
          <span>&nbsp;-&nbsp;#</span>
          <span>{workOrder.number}</span>
        </span>
      </>
    );
  }
  return (
    <div className={styles['title-container']}>
      <div className={styles['title']}>{workOrder.title}</div>
      <div className={styles['number']}>
        <span>&nbsp;-&nbsp;#</span>
        <span>{workOrder.number}</span>
      </div>
    </div>
  );
};

export default Title;
