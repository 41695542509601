import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field, DatePicker, Banner } from 'views/components/Shared/General';
import {
  WorkOrderTypeInlineModal,
  ChoosePriorityInlineModal,
  ChooseAssigneeInlineModal,
  SelectedAssignees,
  CategoryInlineModal,
} from 'views/components/WorkOrder';
import {
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  getElapsedMinutesFromHourAndMinutes,
  WorkOrderCategory,
  WorkOrderStatus,
} from 'sdk/WorkOrder';
import { ChooseUserInlineModal } from 'views/components/User';
import { WorkOrderCategoryType } from 'sdk/System';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import WorkOrderTypeContainer from './WorkOrderTypeContainer';
import AssetContainer from './AssetContainer';
import CompletedByUserContainer from './CompletedByUserContainer';
import styles from './styles.module.scss';

const BATCH_UPDATABLE_FIELDS = {
  WorkOrderType: 'work_order_type_id',
  Priority: 'priority',
  DueDate: 'due_date',
  Asset: 'aset_id',
  EstimatedMinutes: 'estimated_minutes',
  Assignee: 'assigned_to',
  Category: 'category',
  Status: 'status',
};

class MassEditModal extends Component {
  getUpdatableFields = () => {
    let updatableFields = [];
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      updatableFields = [
        ...updatableFields,
        {
          title: this.props.intl.formatMessage({ id: 'resources.work-order.category' }),
          key: BATCH_UPDATABLE_FIELDS.Category,
        },
      ];
    }
    updatableFields = [
      ...updatableFields,
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.work-order-type' }),
        key: BATCH_UPDATABLE_FIELDS.WorkOrderType,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.due-date' }),
        key: BATCH_UPDATABLE_FIELDS.DueDate,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.priority' }),
        key: BATCH_UPDATABLE_FIELDS.Priority,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.assignee' }),
        key: BATCH_UPDATABLE_FIELDS.Assignee,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.estimated-time' }),
        key: BATCH_UPDATABLE_FIELDS.EstimatedMinutes,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.work-order.status' }),
        key: BATCH_UPDATABLE_FIELDS.Status,
      },
    ];
    return updatableFields.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  getInitialState = () => ({
    isSaving: false,
    confirmMassEdit: false,
    showEditForField: null,
    work_order_type_id: null,
    priority: null,
    due_date: null,
    asset_id: null,
    category: null,
    assigned_to_users: [],
    assigned_to_groups: [],
    assigned_to_vendors: [],
    estimated_minutes: null,
    showQuantityExceeded: false,
    includeFutureWorkOrders: false,
    status: null,
    paused_comment: '',
    completed_comment: '',
    completed_date: moment().format('YYYY-MM-DD'),
    completed_by_user_id: this.props.currentUser.id,
    showCompletedDateRequiredError: false,
    showSpentTimeRequiredError: false,
    showCompletedChecklistRequiredError: false,
    updatableFields: this.getUpdatableFields(),
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded: this.props.checkedWorkOrderCount >= 5000,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        filters: {
          ...this.props.filtersAsQueryParams,
          ...this.props.queryParameters,
          ...this.props.listParams,
        },
      };
    }
    return {
      filters: {
        id: {
          any: Object.keys(this.props.checkedWorkOrderIds).toString(),
        },
      },
    };
  };

  buildDataParams = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Category:
        return {
          update: {
            category: this.state.category,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.WorkOrderType:
        return {
          update: {
            work_order_type_id: this.state.work_order_type_id,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Priority:
        return {
          update: {
            priority: this.state.priority,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.DueDate:
        return {
          update: {
            due_date: this.state.due_date,
            include_recurring_maintenances: false,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Asset:
        return {
          update: {
            asset_id: this.state.asset_id,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Assignee:
        return {
          update: {
            assigned_to_users: this.state.assigned_to_users,
            assigned_to_groups: this.state.assigned_to_groups,
            assigned_to_vendors: this.state.assigned_to_vendors,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.EstimatedMinutes:
        return {
          update: {
            estimated_minutes: this.state.estimated_minutes,
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Status: {
        if (this.state.status === WorkOrderStatus.Completed) {
          return {
            update: {
              status: this.state.status,
              completed_date: this.state.completed_date,
              completed_by_user_id: this.state.completed_by_user_id,
              completed_comment: this.state.completed_comment,
            },
          };
        } else if (this.state.status === WorkOrderStatus.Paused) {
          return {
            update: {
              status: this.state.status,
              paused_comment: this.state.paused_comment,
            },
          };
        }
        return {
          update: {
            status: this.state.status,
          },
        };
      }

      default:
        return {
          update: {
            include_recurring_maintenances: this.state.includeFutureWorkOrders,
          },
        };
    }
  };

  hasError = () => {
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status === WorkOrderStatus.Completed && !this.state.completed_date) {
        this.setState({ showCompletedDateRequiredError: true });
        return true;
      }
    }
    return false;
  };

  batchUpdate = () => {
    if (this.hasError()) {
      return;
    }
    this.setState({ isSaving: true });
    API.batchUpdateWorkOrders(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildDataParams(),
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false, showQuantityExceeded: true });
        }
        if (HelperFunctions.hasError(e, { code: '60018' })) {
          this.setState({ isSaving: false, showSpentTimeRequiredError: true });
        }
        if (HelperFunctions.hasError(e, { code: '60019' })) {
          this.setState({ isSaving: false, showCompletedChecklistRequiredError: true });
        }
      });
  };

  selectFieldToUpdate = params => {
    this.setState({
      ...this.getInitialState(),
      ...params,
    });
  };

  renderSelectedField = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Category:
        return <FormattedMessage id="resources.work-order.category" />;
      case BATCH_UPDATABLE_FIELDS.WorkOrderType:
        return <FormattedMessage id="resources.work-order.work-order-type" />;
      case BATCH_UPDATABLE_FIELDS.Asset:
        return <FormattedMessage id="resources.work-order.asset" />;
      case BATCH_UPDATABLE_FIELDS.DueDate:
        return <FormattedMessage id="resources.work-order.due-date" />;
      case BATCH_UPDATABLE_FIELDS.Priority:
        return <FormattedMessage id="resources.work-order.priority" />;
      case BATCH_UPDATABLE_FIELDS.Assignee:
        return <FormattedMessage id="resources.work-order.assignee" />;
      case BATCH_UPDATABLE_FIELDS.EstimatedMinutes:
        return <FormattedMessage id="resources.work-order.estimated-time" />;
      case BATCH_UPDATABLE_FIELDS.Status:
        return <FormattedMessage id="resources.work-order.status" />;
      default:
        return null;
    }
  };

  renderSelectedWorkOrderType = () => {
    if (this.state.work_order_type_id) {
      return <WorkOrderTypeContainer id={this.state.work_order_type_id} />;
    }
    return null;
  };

  renderEditCategory = () => {
    return (
      <CategoryInlineModal
        trigger={
          <Field view={false} label={<FormattedMessage id="resources.work-order.category" />}>
            <Field.Resource
              angleDown
              value={this.renderSelectedCategory()}
              onClear={() => this.setState({ category: null })}
            />
          </Field>
        }
        selectedCategory={this.state.category}
        onSelect={id => this.setState({ category: id })}
      />
    );
  };

  renderSelectedCategory = () => {
    switch (this.state.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderEditWorkOrderType = () => {
    return (
      <WorkOrderTypeInlineModal
        trigger={
          <Field view={false} label={<FormattedMessage id="resources.work-order.work-order-type" />}>
            <Field.Resource
              angleDown
              value={this.renderSelectedWorkOrderType()}
              onClear={() => this.setState({ work_order_type_id: null })}
            />
          </Field>
        }
        selectedWorkOrderTypeId={this.state.work_order_type_id}
        onSelectWorkOrderType={id => this.setState({ work_order_type_id: id })}
        onClear={() => this.setState({ work_order_type_id: null })}
      />
    );
  };

  renderSelectedPriority = () => {
    switch (this.state.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderEditPriority = () => {
    return (
      <ChoosePriorityInlineModal
        trigger={
          <Field view={false} label={<FormattedMessage id="resources.work-order.priority" />}>
            <Field.Resource
              angleDown
              value={this.renderSelectedPriority()}
              onClear={() => this.setState({ priority: null })}
            />
          </Field>
        }
        selectedPriority={this.state.priority}
        onSelectPriority={priority => this.setState({ priority: priority })}
        onClear={() => this.setState({ priority: null })}
      />
    );
  };

  renderEditDueDate = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.work-order.due-date" />}>
        <Field.Date
          onChangeDate={date => {
            this.setState({ due_date: date });
          }}
          value={this.state.due_date}
          footerComponent={
            <DatePicker.Footer
              showClear={this.state.due_date != null}
              onClear={() => this.setState({ due_date: null })}
            />
          }
        />
      </Field>
    );
  };

  renderSelectedAsset = () => {
    if (this.state.asset_id) {
      return <AssetContainer id={this.state.asset_id} />;
    }
    return null;
  };

  renderEditAsset = () => {
    return (
      <ChooseAssetInlineModal
        hideCreateButton
        trigger={
          <Field view={false} label={<FormattedMessage id="resources.work-order.asset" />}>
            <Field.Resource
              angleDown
              value={this.renderSelectedAsset()}
              onClear={() => this.setState({ asset_id: null })}
            />
          </Field>
        }
        selectedAssetId={this.state.asset_id}
        onSelectAsset={assetId => {
          this.setState({ asset_id: assetId });
        }}
        onClear={e => this.setState({ asset_id: null })}
      />
    );
  };

  renderSelectedAssignees = () => {
    const { assigned_to_users, assigned_to_groups, assigned_to_vendors } = this.state;
    const hasNoAssignees =
      assigned_to_users.length === 0 && assigned_to_groups.length === 0 && assigned_to_vendors.length === 0;
    if (hasNoAssignees) {
      return null;
    }
    return (
      <SelectedAssignees
        userIds={assigned_to_users}
        groupIds={assigned_to_groups}
        vendorIds={assigned_to_vendors}
      />
    );
  };

  renderEditAssignee = () => {
    const { assigned_to_users, assigned_to_groups, assigned_to_vendors } = this.state;
    return (
      <Field label={<FormattedMessage id="resources.work-order.assignee" />}>
        <ChooseAssigneeInlineModal
          hideArchivedUsers
          multiple
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedAssignees()}
              onClear={() => {
                this.setState({
                  assigned_to_users: [],
                  assigned_to_groups: [],
                  assigned_to_vendors: [],
                });
              }}
            />
          }
          selectedUserIds={assigned_to_users}
          selectedGroupIds={assigned_to_groups}
          selectedVendorIds={assigned_to_vendors}
          onAddUser={user =>
            this.setState({
              assigned_to_users: [...assigned_to_users, user.id],
            })
          }
          onRemoveUser={user =>
            this.setState({
              assigned_to_users: assigned_to_users.filter(id => id !== user.id),
            })
          }
          onAddGroup={group =>
            this.setState({
              assigned_to_groups: [...assigned_to_groups, group.id],
            })
          }
          onRemoveGroup={group =>
            this.setState({
              assigned_to_groups: assigned_to_groups.filter(id => id !== group.id),
            })
          }
          onAddVendor={vendor =>
            this.setState({
              assigned_to_vendors: [...assigned_to_vendors, vendor.id],
            })
          }
          onRemoveVendor={vendor =>
            this.setState({
              assigned_to_vendors: assigned_to_vendors.filter(id => id !== vendor.id),
            })
          }
        />
      </Field>
    );
  };

  renderEditEstimatedMinutes = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.estimated-time" />}>
        <div className={styles['elapsed-time-container']}>
          <Field.Number
            value={getHoursFromElapsedMinutes(this.state.estimated_minutes)}
            onBlur={hours => {
              this.setState({
                estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                  hours,
                  getMinutesFromElapsedMinutes(this.state.estimated_minutes)
                ),
              });
            }}
            rightLabel={<FormattedMessage id="resources.work-order.estimated-time-hours-short" />}
          />
          <Field.Number
            value={getMinutesFromElapsedMinutes(this.state.estimated_minutes)}
            onBlur={minutes => {
              this.setState({
                estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                  getHoursFromElapsedMinutes(this.state.estimated_minutes),
                  minutes
                ),
              });
            }}
            rightLabel={<FormattedMessage id="resources.work-order.estimated-time-minutes-short" />}
          />
        </div>
      </Field>
    );
  };

  renderAdditionalFieldsForEditingField = () => {
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status === WorkOrderStatus.Paused) {
        return (
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.paused-comment" />}>
                <Field.Textarea
                  value={this.state.paused_comment}
                  onChange={paused_comment =>
                    this.setState({
                      paused_comment,
                    })
                  }
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        );
      }
      if (this.state.status === WorkOrderStatus.Completed) {
        return (
          <>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-at" />}>
                  <Field.Date
                    error={this.state.showCompletedDateRequiredError}
                    value={this.state.completed_date}
                    onChangeDate={completed_date => {
                      this.setState({ completed_date, showCompletedDateRequiredError: false });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-by" />}>
                  <ChooseUserInlineModal
                    members
                    trigger={
                      <Field.Resource
                        clearable={false}
                        value={<CompletedByUserContainer id={this.state.completed_by_user_id} />}
                        onChange={completed_by_user_id => this.setState({ completed_by_user_id })}
                        onClear={() => this.setState({ completed_by_user_id: null })}
                      />
                    }
                    onOpen={() => this.setState({ userInlineModalIsOpen: true })}
                    onClose={() => this.setState({ userInlineModalIsOpen: false })}
                    selectedUserId={this.state.completed_by_user_id}
                    onSelectUser={completed_by_user_id => this.setState({ completed_by_user_id })}
                    clearable={false}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-comment" />}>
                  <Field.Textarea
                    value={this.state.completed_comment}
                    onChange={completed_comment => this.setState({ completed_comment })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
          </>
        );
      }
    }

    return null;
  };

  renderSelectedStatus = () => {
    switch (this.state.status) {
      case WorkOrderStatus.NotStarted: {
        return <FormattedMessage id="resources.work-order.statuses.not-started" />;
      }
      case WorkOrderStatus.InProgress: {
        return <FormattedMessage id="resources.work-order.statuses.in-progress" />;
      }
      case WorkOrderStatus.Paused: {
        return <FormattedMessage id="resources.work-order.statuses.paused" />;
      }
      case WorkOrderStatus.Completed: {
        return <FormattedMessage id="resources.work-order.statuses.completed" />;
      }
      default:
        return null;
    }
  };

  renderEditStatus = () => {
    return (
      <>
        <Field view={false} label={<FormattedMessage id="resources.work-order.status" />}>
          <Field.Dropdown clearable={false} value={this.renderSelectedStatus()}>
            <Field.Dropdown.Item
              selected={this.state.status === WorkOrderStatus.NotStarted}
              onClick={() => this.setState({ status: WorkOrderStatus.NotStarted })}
            >
              <FormattedMessage id="resources.work-order.statuses.not-started" />
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={this.state.status === WorkOrderStatus.InProgress}
              onClick={() => this.setState({ status: WorkOrderStatus.InProgress })}
            >
              <FormattedMessage id="resources.work-order.statuses.in-progress" />
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={this.state.status === WorkOrderStatus.Paused}
              onClick={() => this.setState({ status: WorkOrderStatus.Paused })}
            >
              <FormattedMessage id="resources.work-order.statuses.paused" />
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={this.state.status === WorkOrderStatus.Completed}
              onClick={() => this.setState({ status: WorkOrderStatus.Completed })}
            >
              <FormattedMessage id="resources.work-order.statuses.completed" />
            </Field.Dropdown.Item>
          </Field.Dropdown>
        </Field>
      </>
    );
  };

  renderBatchUpdateCheckbox = () => {
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.DueDate) {
      return null;
    }
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      return null;
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.includeFutureWorkOrders}
              onChange={() =>
                this.setState(prevState => ({
                  includeFutureWorkOrders: !prevState.includeFutureWorkOrders,
                }))
              }
              label={<FormattedMessage id="components.mass-edit-modal.include-future-work-orders" />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderEditableField = () => {
    let content = null;
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Category:
        content = this.renderEditCategory();
        break;
      case BATCH_UPDATABLE_FIELDS.WorkOrderType:
        content = this.renderEditWorkOrderType();
        break;
      case BATCH_UPDATABLE_FIELDS.Priority:
        content = this.renderEditPriority();
        break;
      case BATCH_UPDATABLE_FIELDS.DueDate:
        content = this.renderEditDueDate();
        break;
      case BATCH_UPDATABLE_FIELDS.Asset:
        content = this.renderEditAsset();
        break;
      case BATCH_UPDATABLE_FIELDS.Assignee:
        content = this.renderEditAssignee();
        break;
      case BATCH_UPDATABLE_FIELDS.EstimatedMinutes:
        content = this.renderEditEstimatedMinutes();
        break;
      case BATCH_UPDATABLE_FIELDS.Status:
        content = this.renderEditStatus();
        break;
      default:
        return null;
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>{content}</Grid.Column>
        </Grid.Row>
        {this.renderAdditionalFieldsForEditingField()}
        {this.renderBatchUpdateCheckbox()}
      </>
    );
  };

  renderCategoryDropdownItem = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <Field.Dropdown.Item
        selected={this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Category}
        onClick={() => this.selectFieldToUpdate({ showEditForField: BATCH_UPDATABLE_FIELDS.Category })}
      >
        <FormattedMessage id="resources.work-order.category" />
      </Field.Dropdown.Item>
    );
  };

  renderErrorBanners = () => {
    let banners = [];
    if (this.state.showCompletedChecklistRequiredError) {
      banners.push(
        <Banner
          red
          key="completedChecklistRequired"
          closeable
          onClose={() => this.setState({ showCompletedChecklistRequiredError: false })}
        >
          <FormattedMessage id="components.mass-edit-modal.errors.checklist-required" />
        </Banner>
      );
    }
    if (this.state.showSpentTimeRequiredError) {
      banners.push(
        <Banner
          red
          key="spentTimeRequired"
          closeable
          onClose={() => this.setState({ showSpentTimeRequiredError: false })}
        >
          <FormattedMessage id="components.mass-edit-modal.errors.spent-time-required" />
        </Banner>
      );
    }
    if (!banners.length) return null;
    return (
      <Grid.Row>
        <Grid.Column>
          <Banner.List>{banners}</Banner.List>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderContent = () => {
    return (
      <Grid>
        {this.renderErrorBanners()}
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.mass-edit-modal.select-field-label" />}
            >
              <Field.Dropdown clearable={false} value={this.renderSelectedField()}>
                {this.state.updatableFields.map(({ title, key }) => (
                  <Field.Dropdown.Item
                    selected={this.state.showEditForField === key}
                    onClick={() => this.selectFieldToUpdate({ showEditForField: key })}
                  >
                    <span>{title}</span>
                  </Field.Dropdown.Item>
                ))}
              </Field.Dropdown>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderEditableField()}
        {this.renderConfirmCheckbox()}
      </Grid>
    );
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedWorkOrderCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-work-orders.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.two"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.few"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.many"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.other"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
      />
    );
  };

  renderConfirmCheckbox = () => {
    if (this.state.showEditForField == null) {
      return null;
    }
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status == null) {
        return null;
      }
    }

    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.confirmMassEdit}
            label={
              <FormattedMessage
                id="components.mass-edit-modal.i-am-sure"
                values={{
                  count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                }}
              />
            }
            onChange={() => {
              this.setState(prevState => ({
                confirmMassEdit: !prevState.confirmMassEdit,
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderModalContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            <FormattedMessage id="components.mass-edit-modal.quantity-exceeded" />
          </div>
        </Modal.Content>
      );
    }
    return (
      <>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.confirmMassEdit === false}
              loading={this.state.isSaving}
              onClick={this.batchUpdate}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.mass-edit-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    settings: AuthSelectors.getSettings(state),
    filters: WorkOrdersSelectors.getFilters(state),
    queryParameters: WorkOrdersSelectors.getQueryParameters(state),
    filtersAsQueryParams: WorkOrdersSelectors.getFiltersAsQueryParams(state),
    checkedWorkOrderIds: WorkOrdersSelectors.getSelectedWorkOrderIds(state),
    checkedWorkOrderCount: WorkOrdersSelectors.getSelectedWorkOrdersCount(state),
    totalEntriesIsSelected: WorkOrdersSelectors.getTotalEntriesIsSelected(state),
  };
}

export default injectIntl(connect(mapStateToProps)(MassEditModal));
