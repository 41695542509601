import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import { Loader } from 'views/components/Shared/General';
import { injectIntl } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { StocktakingOperations, StocktakingSelectors } from 'state/ducks/stocktaking';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { Channels } from 'sdk';
import { Locations, NoLocation } from './Tabs';
import Header from './Header';
import Deleted from './Deleted';
import NotFound from './NotFound';
import styles from './style.module.scss';

class StocktakingDetail extends Component {
  channel = null;

  state = {
    notFound: false,
    loading: true,
    queryParams: queryString.parse(this.props.location.search),
  };

  componentDidMount() {
    this.props.initiateWithStocktakingId(this.props.match.params.id);

    this.setDocumentTitle();
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Stocktaking);
    this.joinStocktakingChannel();
  }

  componentWillUnmount() {
    if (this.channel) {
      this.channel.leave();
    }
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams });
    }

    const receivedStocktaking = !prevProps.stocktaking && this.props.stocktaking;
    const stocktakingCompleted =
      prevProps.stocktaking &&
      this.props.stocktaking &&
      prevProps.stocktaking.completed !== this.props.stocktaking.completed;
    if (receivedStocktaking || stocktakingCompleted) {
      this.setDocumentTitle();
    }
  }

  joinStocktakingChannel = () => {
    this.setState({ loading: true });
    this.props
      .joinStocktakingChannel(this.props.match.params.id)
      .then(({ channel }) => {
        this.setState({ loading: false });
        this.channel = channel;
      })
      .catch(({ reason, channel }) => {
        this.channel = channel;
        if (reason === 'unauthorized') {
          this.setState({ loading: false, notFound: true });
        }
      });
  };

  setDocumentTitle = () => {
    if (this.props.stocktaking && this.props.stocktaking.completed) {
      HelperFunctions.setDocumentTitle(
        this.props.intl.formatMessage({ id: 'screens.stocktaking-detail.title-completed' })
      );
    } else {
      HelperFunctions.setDocumentTitle(
        this.props.intl.formatMessage({ id: 'screens.stocktaking-detail.title' })
      );
    }
  };

  contentToDisplay = () =>
    this.state.queryParams.list === 'no_location' || !this.props.showSparePartLocationTab
      ? 'no_location'
      : 'locations';

  renderLoadingView = () => (
    <React.Fragment>
      <Header loading />
      <div className={styles['loader']}>
        <Loader />
      </div>
    </React.Fragment>
  );

  render() {
    if (this.state.loading) return this.renderLoadingView();
    if (this.state.notFound) return <NotFound />;
    if (this.props.isDeleted) return <Deleted />;
    if (this.contentToDisplay() === 'locations') return <Locations />;
    if (this.contentToDisplay() === 'no_location') return <NoLocation />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      joinStocktakingChannel: Channels.StocktakingChannel.join,
      initiateWithStocktakingId: StocktakingOperations.initiateWithStocktakingId,
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    stocktakingId: StocktakingSelectors.getStocktakingId(state),
    showSparePartLocationTab: StocktakingSelectors.getShowSparePartLocationTab(state),
    isDeleted: StocktakingSelectors.getIsDeleted(state),

    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(StocktakingDetail)));
