import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { WorkOrderCategoryType } from 'sdk/System';
import { NewInlineModal } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class CategoryInlineModal extends Component {
  getInitialState = () => ({
    isOpen: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({
        ...this.getInitialState(),
        isOpen: true,
      });
    }
  };

  selectCategory = category => {
    this.setState(
      {
        isOpen: false,
      },
      () => {
        this.props.onSelect(category);
      }
    );
  };

  renderReactiveDropdownItems = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
      return (
        <div className={styles['dropdown-items']}>
          <NewInlineModal.Dropdown.Item
            selected={this.props.selectedCategory === WorkOrderCategory.CorrectiveImmediate}
            onClick={e => this.selectCategory(WorkOrderCategory.CorrectiveImmediate)}
          >
            <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            selected={this.props.selectedCategory === WorkOrderCategory.CorrectiveDefered}
            onClick={e => this.selectCategory(WorkOrderCategory.CorrectiveDefered)}
          >
            <FormattedMessage id="resources.work-order.categories.corrective-defered" />
          </NewInlineModal.Dropdown.Item>
        </div>
      );
    }
    return null;
  };

  renderPreventiveDropdownItems = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
      return (
        <div className={styles['dropdown-items']}>
          <NewInlineModal.Dropdown.Item
            selected={this.props.selectedCategory === WorkOrderCategory.PreventiveConditionBased}
            onClick={e => this.selectCategory(WorkOrderCategory.PreventiveConditionBased)}
          >
            <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            selected={this.props.selectedCategory === WorkOrderCategory.PreventivePredetermined}
            onClick={e => this.selectCategory(WorkOrderCategory.PreventivePredetermined)}
          >
            <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
          </NewInlineModal.Dropdown.Item>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    return (
      <>
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item
              selected={this.props.selectedCategory === WorkOrderCategory.Corrective}
              onClick={e => this.selectCategory(WorkOrderCategory.Corrective)}
            >
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </NewInlineModal.Dropdown.Item>
            {this.renderReactiveDropdownItems()}
            <NewInlineModal.Dropdown.Item
              selected={this.props.selectedCategory === WorkOrderCategory.Preventive}
              onClick={e => this.selectCategory(WorkOrderCategory.Preventive)}
            >
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </NewInlineModal.Dropdown.Item>
            {this.renderPreventiveDropdownItems()}
            <NewInlineModal.Dropdown.Item
              selected={this.props.selectedCategory === WorkOrderCategory.Improvement}
              onClick={e => this.selectCategory(WorkOrderCategory.Improvement)}
            >
              <FormattedMessage id="resources.work-order.categories.improvement" />
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Item
              selected={this.props.selectedCategory === WorkOrderCategory.Modification}
              onClick={e => this.selectCategory(WorkOrderCategory.Modification)}
            >
              <FormattedMessage id="resources.work-order.categories.modification" />
            </NewInlineModal.Dropdown.Item>
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(CategoryInlineModal);

CategoryInlineModal.propTypes = {
  selectedCategory: PropTypes.string,
  onSelect: PropTypes.func,
};

CategoryInlineModal.defaultProps = {
  selectedCategory: null,
  onSelect: () => {},
};
