import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Section } from 'views/scenes/Settings/components';
import { Button } from 'views/components/Shared/General';
import ChangePasswordModal from './ChangePasswordModal';

export default props => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  return (
    <>
      <Section
        title={<FormattedMessage id="screens.settings.user.change-password.title" />}
        subtitle={<FormattedMessage id="screens.settings.user.change-password.subtitle" />}
      >
        <Button
          primary
          onClick={() => setShowChangePasswordModal(true)}
          label="screens.settings.user.change-password.button-title"
        />
      </Section>
      <ChangePasswordModal open={showChangePasswordModal} onClose={() => setShowChangePasswordModal(false)} />
    </>
  );
};
