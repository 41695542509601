import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as moment from 'moment';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, FieldErrorWrapper, NewInlineModal, Icon } from 'views/components/Shared/General';
import { getWeekdayOfMonth, getNewWeekdays } from 'sdk/RecurringMaintenance';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Banner } from 'views/components/Shared/General';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import styles from './style.module.scss';

class IntervalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      showFromDateRequiredError: false,
      showDifferentFromDate: false,
    };
  }

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getStateFromIntervalData(),
        showDropDown: false,
        showFromDateRequiredError: false,
        showDifferentFromDate: false,
      });
    }
  }

  getStateFromIntervalData = () => {
    const {
      from_date,
      to_date,
      interval,
      frequency,
      month_type,
      time_of_day,
      mon,
      tue,
      wed,
      thu,
      fri,
      sat,
      sun,
    } = this.props.intervalData || {};
    const dayOfWeek = moment().day();
    let days = {
      mon: mon || false,
      tue: tue || false,
      wed: wed || false,
      thu: thu || false,
      fri: fri || false,
      sat: sat || false,
      sun: sun || false,
    };
    if (this.props.intervalData == null) {
      days = {
        mon: dayOfWeek === 1,
        tue: dayOfWeek === 2,
        wed: dayOfWeek === 3,
        thu: dayOfWeek === 4,
        fri: dayOfWeek === 5,
        sat: dayOfWeek === 6,
        sun: dayOfWeek === 0,
      };
    }
    return {
      startDate: from_date || moment().format('YYYY-MM-DD'),
      fromDate: from_date || moment().format('YYYY-MM-DD'),
      endDate: to_date || null,
      interval: interval || 1,
      intervalInputValue: interval || 1,
      frequency: frequency || 'weekly',
      monthType: month_type || 'day_of_week',
      time_of_day: time_of_day || null,
      ...days,
    };
  };

  hasErrors = () => {
    let hasError = false;
    if (this.state.fromDate == null) {
      this.setState({ showFromDateRequiredError: true });
      hasError = true;
    }
    if (this.state.startDate == null) {
      this.setState({ showStartDateRequiredError: true });
      hasError = true;
    }
    if (this.state.endDate && moment(this.state.endDate).isBefore(moment())) {
      this.setState({ showToDateSmallerThanFromDateError: true });
      hasError = true;
    }
    return hasError;
  };

  save = () => {
    if (this.hasErrors()) {
      return;
    }
    this.props.onSave(this.buildIntervalData());
  };

  resetWeekdays = newFrequency => {
    switch (newFrequency) {
      case 'daily':
        if (this.state.frequency !== 'daily') {
          return {
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: false,
            sun: false,
          };
        }
        break;
      case 'weekly': {
        if (this.state.frequency !== 'weekly') {
          const dayOfWeek = new Date(this.state.startDate).getDay();
          return {
            mon: dayOfWeek === 1,
            tue: dayOfWeek === 2,
            wed: dayOfWeek === 3,
            thu: dayOfWeek === 4,
            fri: dayOfWeek === 5,
            sat: dayOfWeek === 6,
            sun: dayOfWeek === 0,
          };
        }
        break;
      }
      default:
        break;
    }
    return {};
  };

  buildIntervalData = () => ({
    start_date: this.state.startDate,
    from_date: this.state.fromDate,
    to_date: this.state.endDate,
    interval: this.state.interval >= 1 ? this.state.interval : 1,
    frequency: this.state.frequency,
    month_type: this.state.monthType,
    time_of_day: this.state.time_of_day,
    mon: this.state.mon,
    tue: this.state.tue,
    wed: this.state.wed,
    thu: this.state.thu,
    fri: this.state.fri,
    sat: this.state.sat,
    sun: this.state.sun,
  });

  selectDay = day => {
    const { mon, tue, wed, thu, fri, sat, sun } = this.state;
    const count = [mon, tue, wed, thu, fri, sat, sun].filter(b => b).length;
    if (count > 1 || !this.state[day]) {
      const weekday = getWeekdayOfMonth(this.state.startDate);
      let startDate = this.state.startDate;
      if (day === weekday) {
        const weekday = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].findIndex(
          el => this.state[el] && el !== day
        );

        startDate = moment(this.state.startDate).weekday(weekday).format('YYYY-MM-DD');
      }

      this.setState({
        [day]: !this.state[day],
        startDate: startDate,
      });
    }
  };

  buildRecurringMaintenance = data => ({
    start_date: this.state.startDate,
    interval: data.interval >= 1 ? data.interval : 1,
    frequency: data.frequency,
    month_type: data.monthType,
    mon: data.mon,
    tue: data.tue,
    wed: data.wed,
    thu: data.thu,
    fri: data.fri,
    sat: data.sat,
    sun: data.sun,
  });

  setStartDate = startDate => {
    const newWeekdays = getNewWeekdays(this.buildRecurringMaintenance(this.state), startDate);

    if (this.state.showDifferentFromDate) {
      this.setState({
        startDate,
        showStartDateRequiredError: false,
        ...newWeekdays,
      });
    } else {
      this.setState({
        startDate,
        fromDate: startDate,
        showStartDateRequiredError: false,
        ...newWeekdays,
      });
    }
  };

  isNumber = value => {
    const numberRegex = /^[0-9\b]+$/;
    return value === '' || numberRegex.test(value);
  };

  renderWeekDays = () => (
    <div className={styles['weekdays']}>
      {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => (
        <div
          key={i}
          className={`${styles['day']} ${this.state[day] === true ? styles['selected'] : ''}`}
          onClick={() => this.selectDay(day)}
        >
          <FormattedMessage
            id={`components.operational-maintenance-interval-options-inline-modal.days.${day}`}
          />
        </div>
      ))}
    </div>
  );

  renderTypeOfMonthOptions = () => {
    if (this.state.startDate == null) {
      return null;
    } else {
      const dayOfWeekMessage = buildIntervalMessage(
        {
          frequency: this.state.frequency,
          interval: this.state.interval,
          month_type: 'day_of_week',
          start_date: this.state.startDate,
        },
        this.props.intl
      );

      const exactDayMessage = buildIntervalMessage(
        {
          frequency: this.state.frequency,
          interval: this.state.interval,
          month_type: 'exact_day',
          start_date: this.state.startDate,
        },
        this.props.intl
      );

      return (
        <Field.Checkbox.Group>
          <Field.Checkbox
            radio
            label={dayOfWeekMessage}
            checked={this.state.monthType === 'day_of_week'}
            onChange={() => this.setState({ monthType: 'day_of_week' })}
          />
          <Field.Checkbox
            radio
            label={exactDayMessage}
            checked={this.state.monthType === 'exact_day'}
            onChange={() => this.setState({ monthType: 'exact_day' })}
          />
        </Field.Checkbox.Group>
      );
    }
  };

  renderOptions = () => (
    <div className={styles['options-container']}>
      {(() => {
        switch (this.state.frequency) {
          case 'daily':
            return this.renderWeekDays();
          case 'weekly':
            return this.renderWeekDays();
          case 'monthly':
            return this.renderTypeOfMonthOptions();
          case 'yearly':
            return this.renderTypeOfMonthOptions();
          default:
            return null;
        }
      })()}
    </div>
  );

  renderStartDate = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field
            label={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.start-date" />
            }
            description={
              this.state.showDifferentFromDate == false ? (
                <Button
                  type="text"
                  label="screens.operational-maintenance-template.settings.asset.from-date-button"
                  primary
                  fontSize={12}
                  noUnderline
                  onClick={() => {
                    this.setState({ showDifferentFromDate: true });
                  }}
                />
              ) : (
                <FormattedMessage id="screens.operational-maintenance-template.settings.asset.start-date-description" />
              )
            }
          >
            <Field.Date
              value={this.state.startDate}
              disabledDays={{ before: moment().tz(this.props.system.timezone).toDate() }}
              error={this.state.showStartDateRequiredError}
              onChangeDate={startDate => {
                this.setStartDate(startDate);
              }}
              onClear={() => {
                if (this.state.showDifferentFromDate) {
                  this.setState({
                    startDate: null,
                  });
                } else {
                  this.setState({
                    startDate: null,
                    fromDate: null,
                  });
                }
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderFromDate = () => {
    if (!this.state.showDifferentFromDate) return null;

    return (
      <Grid.Row>
        <Grid.Column>
          <Field
            label={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.from-date" />
            }
            description={
              this.state.showDifferentFromDate == true ? (
                <FormattedMessage id="screens.operational-maintenance-template.settings.asset.from-date-description" />
              ) : null
            }
          >
            <Field.Date
              value={this.state.fromDate}
              disabledDays={{ after: new Date(this.state.startDate) }}
              error={this.state.showFromDateRequiredError}
              onChangeDate={fromDate => {
                this.setState({
                  fromDate,
                  showFromDateRequiredError: false,
                });
              }}
              onClear={() => {
                this.setState({ fromDate: null });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderEndDate = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field
            label={<FormattedMessage id="screens.operational-maintenance-template.settings.asset.to-date" />}
          >
            <FieldErrorWrapper
              position="top"
              show={this.state.showToDateSmallerThanFromDateError}
              errorElement={
                <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.errors.to-date-larger-than-from-date" />
              }
            >
              <Field.Date
                value={this.state.endDate}
                disabledDays={{ before: new Date(this.state.fromDate) }}
                error={this.state.showToDateSmallerThanFromDateError}
                onChangeDate={endDate => {
                  if (this.state.showToDateSmallerThanFromDateError) {
                    this.setState({ showToDateSmallerThanFromDateError: false });
                  }
                  this.setState({
                    endDate,
                  });
                }}
                onClear={() => {
                  if (this.state.showToDateSmallerThanFromDateError) {
                    this.setState({ showToDateSmallerThanFromDateError: false });
                  }
                  this.setState({
                    endDate: null,
                  });
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderTime = () => {
    return (
      <div className={styles['time-of-day-field']}>
        <Field label={<FormattedMessage id="screens.operational-maintenance-template.settings.asset.time" />}>
          <Field.Time
            value={this.state.time_of_day}
            onChangeTime={time_of_day => {
              this.setState({
                time_of_day,
              });
            }}
            onClear={() => {
              this.setState({
                time_of_day: null,
              });
            }}
          />
        </Field>
      </div>
    );
  };

  renderRecurringMessage = () => {
    const intervalData = this.buildIntervalData();
    return (
      <Banner orange>
        {buildIntervalMessage(intervalData, this.props.intl)}
        {this.renderTimeOfDay()}
      </Banner>
    );
  };

  renderTimeOfDay = () => {
    const intervalData = this.buildIntervalData();
    if (intervalData.time_of_day) {
      return (
        <>
          <span>, </span>
          <span>{moment(intervalData.time_of_day, 'hh:mm').format('LT')}</span>
        </>
      );
    }
    return null;
  };

  renderFrequency = () => {
    switch (this.state.frequency) {
      case 'daily':
        return (
          <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.daily" />
        );
      case 'weekly':
        return (
          <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.weekly" />
        );
      case 'monthly':
        return (
          <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.monthly" />
        );
      case 'yearly':
        return (
          <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.yearly" />
        );
      default:
        return null;
    }
  };

  renderSelectInterval = () => {
    return (
      <div className={styles['custom-interval']}>
        {this.state.frequency === 'daily' ? (
          <span className={styles['daily-label']}>
            <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-label-daily" />
          </span>
        ) : (
          <React.Fragment>
            <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-label" />
            <div className={styles['interval']}>
              <Field.Number
                value={this.state.intervalInputValue}
                min={1}
                onChange={value => {
                  if (this.isNumber(value)) {
                    this.setState({
                      interval: Number(value),
                      intervalInputValue: value,
                    });
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
        {
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
            className={styles['frequency-text']}
          >
            <span style={{ marginRight: 5 }}>{this.renderFrequency()}</span>
            <Icon type="angle-down" />
          </div>
        }
        <NewInlineModal
          minWidth={100}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'daily'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'daily',
                    ...this.resetWeekdays('daily'),
                  })
                }
              >
                <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.daily" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'weekly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'weekly',
                    ...this.resetWeekdays('weekly'),
                  })
                }
              >
                <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.weekly" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'monthly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'monthly',
                    ...this.resetWeekdays('monthly'),
                  })
                }
              >
                <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.monthly" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={this.state.frequency === 'yearly'}
                onClick={() =>
                  this.setState({
                    showDropDown: false,
                    frequency: 'yearly',
                    ...this.resetWeekdays('yearly'),
                  })
                }
              >
                <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.interval-options.yearly" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.intervalData == null) {
      return (
        <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.new-title" />
      );
    }
    return (
      <FormattedMessage id="components.operational-maintenance-interval-options-inline-modal.edit-title" />
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header title={this.renderTitle()} onClose={this.props.onClose} />
        <Modal.Content>
          <Grid>
            {this.renderSelectInterval()}
            {this.renderOptions()}
            {this.renderTime()}
            <Grid.Separator />
            {this.renderStartDate()}
            {this.renderFromDate()}
            {this.renderEndDate()}
            <Grid.Separator />
            {this.renderRecurringMessage()}
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary loading={this.props.isSaving} onClick={this.save} label="general.save" />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps)(IntervalModal));
