import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions, SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, Loader, List } from 'views/components/Shared/General';
import styles from './style.module.scss';

class DeleteMeterModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    hasConfirmed: false,
    isFetchingWorkOrders: true,
    plannedWorkOrderCount: 0,
    completedWorkOrderCount: 0,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
      Promise.all([
        API.listWorkOrders(this.props.currentSystem.id, {
          meter_id: this.props.id,
          page_size: 1,
          completed: true,
        }),
        API.listWorkOrders(this.props.currentSystem.id, {
          meter_id: this.props.id,
          page_size: 1,
          active: true,
        }),
      ]).then(([completedWorkOrderResponse, plannedWorkOrderResponse]) => {
        this.setState({
          plannedWorkOrderCount: HelperFunctions.getPaginationFromHeader(plannedWorkOrderResponse.headers)
            .totalEntries,
          completedWorkOrderCount: HelperFunctions.getPaginationFromHeader(completedWorkOrderResponse.headers)
            .totalEntries,
          isFetchingWorkOrders: false,
        });
      });
    }
  }
  deleteMeter = () => {
    this.setState({ isSaving: true });
    this.props
      .deleteMeter(this.props.meter.id)
      .then(({ data: meter }) => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.meter.delete-success" />} />);
        this.setState({ isSaving: false });
        this.props.onDeleted();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List light small>
              <List.Item>
                <List.Item.TitleColumn title={<FormattedMessage id="resources.meter.resource" />} />
                <List.Item.Column alignRight>
                  <span className={styles['warning-text']}>
                    1 <FormattedMessage id="screens.meter.modals.delete-meter.meter-count" />
                  </span>
                </List.Item.Column>
              </List.Item>
              <List.Item>
                <List.Item.TitleColumn
                  loading={this.state.isFetchingWorkOrders}
                  title={<FormattedMessage id="screens.meter.modals.delete-meter.planned-work-orders" />}
                />
                <List.Item.Column alignRight>
                  {this.state.isFetchingWorkOrders ? null : (
                    <span
                      className={
                        this.state.plannedWorkOrderCount > 0 ? styles['warning-text'] : styles['empty-text']
                      }
                    >
                      <FormattedPlural
                        value={this.state.workOrderCount}
                        zero={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.zero" />
                        }
                        one={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.one" />
                        }
                        two={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.two" />
                        }
                        few={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.few"
                            values={{ amount: this.state.plannedWorkOrderCount }}
                          />
                        }
                        many={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.many"
                            values={{ amount: this.state.plannedWorkOrderCount }}
                          />
                        }
                        other={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.other"
                            values={{ amount: this.state.plannedWorkOrderCount }}
                          />
                        }
                      />
                    </span>
                  )}
                </List.Item.Column>
              </List.Item>
              <List.Item>
                <List.Item.TitleColumn
                  loading={this.state.isFetchingWorkOrders}
                  title={<FormattedMessage id="screens.meter.modals.delete-meter.completed-work-orders" />}
                />
                <List.Item.Column alignRight>
                  {this.state.isFetchingWorkOrders ? null : (
                    <span
                      className={
                        this.state.completedWorkOrderCount > 0 ? styles['warning-text'] : styles['empty-text']
                      }
                    >
                      <FormattedPlural
                        value={this.state.workOrderCount}
                        zero={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.zero" />
                        }
                        one={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.one" />
                        }
                        two={
                          <FormattedMessage id="screens.meter.modals.delete-meter.work-order-amount.two" />
                        }
                        few={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.few"
                            values={{ amount: this.state.completedWorkOrderCount }}
                          />
                        }
                        many={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.many"
                            values={{ amount: this.state.completedWorkOrderCount }}
                          />
                        }
                        other={
                          <FormattedMessage
                            id="screens.meter.modals.delete-meter.work-order-amount.other"
                            values={{ amount: this.state.completedWorkOrderCount }}
                          />
                        }
                      />
                    </span>
                  )}
                </List.Item.Column>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.hasConfirmed}
              onChange={value => this.setState({ hasConfirmed: value })}
              label={<FormattedMessage id="screens.meter.modals.delete-meter.delete-confirm" />}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.props.meter || !this.props.asset) return <Loader />;
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.meter.modals.delete-meter.title" />}
          subtitle={
            <span>
              {this.props.meter.title} - {this.props.asset.title}
            </span>
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={!this.state.hasConfirmed}
                destructive
                loading={this.state.isSaving}
                label="general.delete"
                onClick={() => {
                  this.deleteMeter();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deleteMeter: SDKReduxOperations.deleteMeter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.id);
  return {
    meter,
    asset: EntitySelectors.getAsset(state, meter.asset_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMeterModal);
