import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Icon } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { TreePath } from 'views/components/Asset';
import queryString from 'query-string';
import { ChecklistInstanceAssigneeDropdown } from 'views/components/Checklist';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class ChecklistInstanceListItem extends Component {
  state = {
    showAssigneeDropdown: false,
    isChangingAssignedForUserIds: [],
    isClearing: false,
  };

  getNewAssignedToUserIds = userId => {
    const alreadyAssigned = this.props.checklistInstance.deviation_assigned_to_users;
    if (alreadyAssigned.includes(userId)) {
      return alreadyAssigned.filter(id => id !== userId);
    } else {
      return [...alreadyAssigned, userId];
    }
  };

  handleUserClick = id => {
    const newAssignedToUserIds = this.getNewAssignedToUserIds(id);
    this.setState({ isChangingAssignedForUserIds: [...this.state.isChangingAssignedForUserIds, id] });
    this.updateChecklistInstance({ deviation_assigned_to_users: newAssignedToUserIds }).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({
        isChangingAssignedForUserIds: this.state.isChangingAssignedForUserIds.filter(userId => userId !== id),
        isClearing: false,
      });
    });
  };

  updateChecklistInstance = data => {
    return this.props.updateChecklistInstance(this.props.checklistInstance.id, data);
  };

  renderNotAssigned = () => (
    <div
      onClick={e => {
        e.preventDefault();
        this.setState(prevState => ({
          showAssigneeDropdown: !prevState.showAssigneeDropdown,
        }));
      }}
      className={`${styles['assignee']} ${styles['no-assignee']}`}
      ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
    >
      <div className={styles['name']}>
        <FormattedMessage id="screens.checklists.not-assigned" />
      </div>
      <div>
        <Icon size={10} solid type="caret-down" />
      </div>
    </div>
  );

  renderAssigneesText = () => {
    if (this.props.assignedToUsers.length === 1) {
      return this.props.assignedToUsers[0].name;
    }
    return (
      <FormattedPlural
        value={this.props.checklistInstance.deviation_assigned_to_users.length}
        one={
          <FormattedMessage
            id="screens.checklists.amount-of-users.one"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        two={
          <FormattedMessage
            id="screens.checklists.amount-of-users.two"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        few={
          <FormattedMessage
            id="screens.checklists.amount-of-users.few"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        many={
          <FormattedMessage
            id="screens.checklists.amount-of-users.many"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        other={
          <FormattedMessage
            id="screens.checklists.amount-of-users.other"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
      />
    );
  };

  renderAssignee = () => {
    if (!this.props.checklistInstance.deviation_assigned_to_users.length) {
      return this.renderNotAssigned();
    }

    if (this.props.isViewOnly) {
      return <div className={styles['name']}>{this.renderAssigneesText()}</div>;
    }
    return (
      <div
        onClick={e => {
          e.preventDefault();
          this.setState(prevState => ({
            showAssigneeDropdown: !prevState.showAssigneeDropdown,
          }));
        }}
        className={styles['assignee']}
        ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
      >
        <div className={styles['name']}>{this.renderAssigneesText()}</div>
        <div>
          <Icon size={10} solid type="caret-down" />
        </div>
      </div>
    );
  };

  renderAmountOfDeviations = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'active') {
      return (
        <span className={styles['deviations']}>
          <FormattedPlural
            value={this.props.checklistInstance.deviation_require_action_count}
            one={
              <FormattedMessage
                id="screens.checklists.amount-of-deviations.one"
                values={{ amount: this.props.checklistInstance.deviation_require_action_count }}
              />
            }
            two={
              <FormattedMessage
                id="screens.checklists.amount-of-deviations.two"
                values={{ amount: this.props.checklistInstance.deviation_require_action_count }}
              />
            }
            few={
              <FormattedMessage
                id="screens.checklists.amount-of-deviations.few"
                values={{ amount: this.props.checklistInstance.deviation_require_action_count }}
              />
            }
            many={
              <FormattedMessage
                id="screens.checklists.amount-of-deviations.many"
                values={{ amount: this.props.checklistInstance.deviation_require_action_count }}
              />
            }
            other={
              <FormattedMessage
                id="screens.checklists.amount-of-deviations.other"
                values={{ amount: this.props.checklistInstance.deviation_require_action_count }}
              />
            }
          />
        </span>
      );
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.asset) {
      return <TreePath fullPath assetId={this.props.asset.id} />;
    }
    return null;
  };

  renderCompletedDate = () => {
    const { workOrder, operationalMaintenanceInstance } = this.props;
    if (workOrder) {
      return workOrder.completed_date ? moment(workOrder.completed_date).format('LL') : '-';
    }
    return operationalMaintenanceInstance.completed_date
      ? moment(operationalMaintenanceInstance.completed_date).format('LL')
      : '-';
  };

  render() {
    return (
      <>
        <List.Item
          clickable
          small
          onClick={() => this.props.onClick()}
          linkTo={{
            pathname: `/deviations/${this.props.checklistInstance.id}`,
          }}
        >
          <List.Item.TitleColumn
            title={this.props.checklistTemplateVersion.title}
            subtitle={this.renderSubtitle()}
          />
          <List.Item.Column width={250}>{this.renderCompletedDate()}</List.Item.Column>
          <List.Item.Column width={200}>{this.renderAssignee()}</List.Item.Column>
          <List.Item.Column alignRight width={150}>
            {this.renderAmountOfDeviations()}
          </List.Item.Column>
        </List.Item>
        <ChecklistInstanceAssigneeDropdown
          position="left"
          positionToRef={this.assigneeDropdownInlineModalPositioningRef}
          open={this.state.showAssigneeDropdown && !this.props.isViewOnly}
          userIds={this.props.checklistInstance.deviation_assigned_to_users}
          isLoadingUserIds={this.state.isChangingAssignedForUserIds}
          isClearing={this.state.isClearing}
          onClose={() => this.setState({ showAssigneeDropdown: false })}
          onSelectUser={id => {
            this.handleUserClick(id);
          }}
          onClear={() => {
            this.setState({
              isChangingAssignedForUserIds: [],
              isClearing: true,
            });
            this.updateChecklistInstance({ deviation_assigned_to_users: null }).then(() => {
              this.setState({ isClearing: false });
            });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateChecklistInstance: SDKReduxOperations.updateChecklistInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistInstance = EntitySelectors.getChecklistInstance(state, ownProps.id);
  const workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    checklistInstance.operational_maintenance_instance_id
  );
  let asset = null;
  if (workOrder) {
    asset = EntitySelectors.getAsset(state, workOrder.asset_id);
  } else {
    asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
  }
  return {
    checklistInstance,
    checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    ),
    asset,
    workOrder,
    operationalMaintenanceInstance,
    assignedToUsers: EntitySelectors.getUsers(state, checklistInstance.deviation_assigned_to_users),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistInstanceListItem));
