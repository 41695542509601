import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { CalendarSelectors, CalendarOperations } from 'state/ducks/calendar';
import { API } from 'sdk';
import { ToolbarListType } from 'views/scenes/Planning/Calendar/Calendar/utils';
import styles from './style.module.scss';
import ListBar from './ListBar';
import Calendar from './Calendar';

class SideBar extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      API.showCalendarCount(this.props.currentSystem.id).then(({ data: counts }) => {
        this.props.setCounts(counts);
      });
    }
  }

  goToDate = date => {
    this.props.onGoToDate(date);
  };

  getTotalCount = () => {
    const { amountToPlan, amountOverdue, amountOfRequests, amountAwaitingSpareParts } = this.props;
    if (this.props.settings.spare_parts_activated)
      return amountToPlan + amountOverdue + amountOfRequests + amountAwaitingSpareParts;
    else return amountToPlan + amountOverdue + amountOfRequests;
  };

  render() {
    const { toolboxListType, isShowingList } = this.props;
    return (
      <>
        <div className={`${styles['sidebar']} ${this.props.open ? styles['show'] : ''}`}>
          <PerfectScrollbar>
            <div className={styles['sidebar-content']}>
              <Calendar date={this.props.date} onGoToDate={this.goToDate} />
              <div className={styles['lists-container']}>
                <div className={styles['header']}>
                  <div className={styles['title-container']}>
                    <div className={styles['title']}>
                      <FormattedMessage id="screens.calendar.toolbox.backlog-title" />
                    </div>
                    <div className={styles['backlog-number-container']}>
                      {this.getTotalCount() === 0 ? null : (
                        <div className={styles['number']}>{this.getTotalCount()}</div>
                      )}
                    </div>
                  </div>
                  <div className={styles['subtitle']}>
                    <FormattedMessage id="screens.calendar.toolbox.backlog-subtitle" />
                  </div>
                </div>
                <div className={styles['lists']}>
                  <div
                    className={`${styles['list']} ${styles['manage']} ${
                      toolboxListType === ToolbarListType.Manage ? styles['selected'] : ''
                    }`}
                    onClick={() => {
                      if (isShowingList && toolboxListType === ToolbarListType.Manage) {
                        this.props.hideListBar();
                      } else {
                        this.props.showListBar(ToolbarListType.Manage);
                      }
                    }}
                  >
                    <div className={styles['icon-container']}>
                      <Icon regular blue type="wrench" />
                    </div>
                    <div className={styles['title']}>
                      <FormattedMessage id="screens.calendar.toolbox.lists.manage.title" />
                    </div>
                    <div className={styles['number']}>
                      <span>{this.props.amountToPlan}</span>
                    </div>
                  </div>
                  <div
                    className={`${styles['list']} ${styles['overdue']} ${
                      toolboxListType === ToolbarListType.Overdue ? styles['selected'] : ''
                    }`}
                    onClick={() => {
                      if (isShowingList && toolboxListType === ToolbarListType.Overdue) {
                        this.props.hideListBar();
                      } else {
                        this.props.showListBar(ToolbarListType.Overdue);
                      }
                    }}
                  >
                    <div className={styles['icon-container']}>
                      <Icon regular red type="clock" />
                    </div>
                    <div className={styles['title']}>
                      <FormattedMessage id="screens.calendar.toolbox.lists.overdue.title" />
                    </div>
                    <div className={styles['number']}>
                      <span>{this.props.amountOverdue}</span>
                    </div>
                  </div>
                  <div
                    className={`${styles['list']} ${styles['requests']}  ${
                      toolboxListType === ToolbarListType.Requests ? styles['selected'] : ''
                    }`}
                    onClick={() => {
                      if (isShowingList && toolboxListType === ToolbarListType.Requests) {
                        this.props.hideListBar();
                      } else {
                        this.props.showListBar(ToolbarListType.Requests);
                      }
                    }}
                  >
                    <div className={styles['icon-container']}>
                      <Icon regular orange type="exclamation-circle" />
                    </div>
                    <div className={styles['title']}>
                      <FormattedMessage id="screens.calendar.toolbox.lists.requests.title" />
                    </div>
                    <div className={styles['number']}>
                      <span>{this.props.amountOfRequests}</span>
                    </div>
                  </div>
                  {this.props.settings.spare_parts_activated ? (
                    <div
                      className={`${styles['list']} ${styles['awaiting-spare-parts']} ${
                        toolboxListType === ToolbarListType.AwaitingDelivery ? styles['selected'] : ''
                      }`}
                      onClick={() => {
                        if (isShowingList && toolboxListType === ToolbarListType.AwaitingDelivery) {
                          this.props.hideListBar();
                        } else {
                          this.props.showListBar(ToolbarListType.AwaitingDelivery);
                        }
                      }}
                    >
                      <>
                        <div className={styles['icon-container']}>
                          <Icon regular type="cogs" />
                        </div>
                        <div className={styles['title']}>
                          <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.title" />
                        </div>
                      </>

                      <div className={styles['number']}>
                        <span>{this.props.amountAwaitingSpareParts}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
        <ListBar />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCounts: CalendarOperations.setCounts,
      showListBar: CalendarOperations.showListBar,
      hideListBar: CalendarOperations.hideListBar,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    amountToPlan: CalendarSelectors.getAmountToPlan(state),
    amountOverdue: CalendarSelectors.getAmountOverdue(state),
    settings: AuthSelectors.getSettings(state),
    amountOfRequests: CalendarSelectors.getAmountOfRequests(state),
    amountAwaitingSpareParts: CalendarSelectors.getAmountAwaitingSpareParts(state),
    toolboxListType: CalendarSelectors.getToolboxListType(state),
    isShowingList: CalendarSelectors.isShowingList(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SideBar);
