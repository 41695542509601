import React from 'react';
import { connect } from 'react-redux';
import { ProfilePicture } from 'views/components/User';
import { SelectedAssignees } from 'views/components/WorkOrder';
import { VendorAvatar } from 'views/components/Vendor';
import { EntitySelectors } from 'sdk/State/entities';
import { Tooltip } from 'views/components/Shared/General';
import styles from './style.module.scss';

const AssigneeColumn = ({ vendorIds, userIds, groupIds, vendors, groups, users }) => {
  const vendorAssignees = vendors.map(vendor => (
    <div className={styles['assignee']} key={vendor.id}>
      <VendorAvatar vendor={vendor} size={20} fontSize={10} />
    </div>
  ));

  const groupAssignees = groups.map(group => (
    <div className={styles['assignee']} key={group.id}>
      <div className={styles['group']}>
        {group.title
          .trim()
          .split(' ')
          .slice(0, 1)
          .map(v => v.charAt(0))
          .join('')}
      </div>
    </div>
  ));

  const userAssignees = users.map(user => (
    <div className={styles['assignee']} key={user.id}>
      <ProfilePicture userId={user.id} size={20} fontSize={10} />
    </div>
  ));

  const assignees = [...vendorAssignees, ...groupAssignees, ...userAssignees];

  if (assignees.length === 0) {
    return <div className={styles['empty']}>-</div>;
  }

  return (
    <Tooltip
      trigger={
        <div className={styles['assignees']}>
          <div className={styles['avatars']}>{assignees.slice(0, 2).reverse()}</div>
          {assignees.length > 2 ? (
            <div className={styles['additional-assignees']}>+{assignees.length - 2}</div>
          ) : null}
        </div>
      }
      label={<SelectedAssignees vendorIds={vendorIds} userIds={userIds} groupIds={groupIds} />}
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    users: EntitySelectors.getUsers(state, ownProps.userIds),
    groups: EntitySelectors.getGroups(state, ownProps.groupIds),
    vendors: EntitySelectors.getVendors(state, ownProps.vendorIds),
  };
}

export default connect(mapStateToProps)(AssigneeColumn);
