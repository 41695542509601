import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import { Header, ContentContainer, Modal } from 'views/components/Shared/Layout';
import { Button, List, WhiteCard, Field } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import StocktakingTiny from 'assets/images/EmptyDataSet/StocktakingTiny.png';
import { EntityOperations } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeStocktaking } from 'sdk/Schemas';
import ActiveStocktakingListItem from './ActiveStocktakingListItem';
import HistoryStocktakingListItem from './HistoryStocktakingListItem';
import styles from './style.module.scss';

class Stocktaking extends Component {
  state = {
    isViewLoading: false,
    isCreating: false,
    activeStocktakingIds: null,
    historyStocktakingIds: null,
    showCreateConfirmModal: false,
    description: '',
  };

  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.stocktaking.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Stocktaking);

    this.loadStocktakings();
  }

  loadStocktakings = () => {
    this.setState({ isViewLoading: true });
    this.fetchStocktakings().then(stocktakingIds => {
      this.setState({ isViewLoading: false });
    });
  };

  fetchStocktakings = () => {
    const params = { no_pagination: true };
    return API.listStocktakings(this.props.currentSystem.id, params).then(({ data: stocktakings }) => {
      const { entities } = normalizeStocktaking(stocktakings);
      this.props.updateEntities(entities);

      const activeStocktakingIds = stocktakings
        .filter(stocktaking => !stocktaking.completed)
        .map(stocktaking => stocktaking.id);

      const historyStocktakingIds = stocktakings
        .filter(stocktaking => stocktaking.completed)
        .map(stocktaking => stocktaking.id);

      this.setState({
        activeStocktakingIds,
        historyStocktakingIds,
      });
    });
  };

  createStocktaking = () => {
    this.setState({ isCreating: true });
    this.props
      .createStocktaking(this.props.currentSystem.id, { description: this.state.description })
      .then(({ data: stocktaking }) => {
        this.setState({ isCreating: false });
        this.props.history.push(`/stocktaking/${stocktaking.id}`);
      })
      .catch(e => {
        this.setState({ isCreating: false });
      });
  };

  renderCreateConfirmModal = () => (
    <Modal isOpen={this.state.showCreateConfirmModal} width={500}>
      <Modal.Header
        ignoreLine
        title={<FormattedMessage id="screens.stocktaking.create-confirm.title" />}
        subtitleTopMargin
        onClose={() => this.setState({ showCreateConfirmModal: false })}
      />
      <Modal.Content>
        <Field
          view={false}
          label={
            <>
              <FormattedMessage id="resources.stocktaking.description" />
              <span className={styles['subtitle']}>
                <span> - </span>
                <FormattedMessage id="general.optional" />
              </span>
            </>
          }
        >
          <Field.Textarea
            autoFocus
            value={this.state.description}
            onChange={description => {
              this.setState({ description });
            }}
          />
        </Field>
      </Modal.Content>
      <Modal.Footer ignoreLine>
        <Button.Group>
          <Button
            primary
            label="screens.stocktaking.create-confirm.button"
            loading={this.state.isCreating}
            onClick={() => this.createStocktaking()}
          />
          <Button label="general.cancel" onClick={() => this.setState({ showCreateConfirmModal: false })} />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderWelcomeCard = () => (
    <div className={styles['welcome-container']}>
      <WhiteCard>
        <div className={styles['content']}>
          <img src={StocktakingTiny} className={styles['image']} alt="" />
          <div className={styles['text-container']}>
            <p className={styles['title']}>
              <FormattedMessage id="screens.stocktaking.welcome-card.title" />
            </p>
            <p className={styles['text']}>
              <FormattedMessage id="screens.stocktaking.welcome-card.text" />
            </p>
            {this.props.canCreateWithdrawals ? (
              <div className={styles['buttons']}>
                <Button
                  primary
                  label="screens.stocktaking.welcome-card.create-button"
                  onClick={() => this.setState({ showCreateConfirmModal: true, description: '' })}
                  loading={this.state.isCreating && !this.state.showCreateConfirmModal}
                />
              </div>
            ) : null}
          </div>
        </div>
      </WhiteCard>
    </div>
  );

  renderActiveStocktakings = () => {
    if (!this.state.activeStocktakingIds || this.state.activeStocktakingIds.length === 0) return null;

    return (
      <div className={styles['list']}>
        <p className={styles['title']}>
          <FormattedMessage id="screens.stocktaking.active.title" />
        </p>
        <List>
          {this.state.activeStocktakingIds.map(stocktakingId => (
            <ActiveStocktakingListItem stocktakingId={stocktakingId} />
          ))}
        </List>
        {this.state.historyStocktakingIds.length > 0 ? <div className={styles['active-separator']} /> : null}
      </div>
    );
  };

  renderLoader = () => {
    if (!this.state.isViewLoading) return null;
    return (
      <div className={styles['loader']}>
        <Loader large />
      </div>
    );
  };

  renderHistoryStocktakings = () => {
    if (!this.state.historyStocktakingIds || this.state.historyStocktakingIds.length === 0) return null;

    return (
      <div className={styles['list']}>
        <p className={styles['title']}>
          <FormattedMessage id="screens.stocktaking.history.title" />
        </p>
        <List>
          {this.state.historyStocktakingIds.map(stocktakingId => (
            <HistoryStocktakingListItem stocktakingId={stocktakingId} />
          ))}
        </List>
      </div>
    );
  };

  renderContent = () => {
    return (
      <React.Fragment>
        {this.renderWelcomeCard()}
        {this.renderLoader()}
        {this.renderActiveStocktakings()}
        {this.renderHistoryStocktakings()}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header smallTitle title={<FormattedMessage id="screens.stocktaking.title" />} />
          <ContentContainer>{this.renderContent()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderCreateConfirmModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
      createStocktaking: SDKReduxOperations.createStocktaking,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Stocktaking)));
