import types from './types';

const setVendorId = payload => ({
  type: types.SET_VENDOR_ID,
  payload: payload,
});

const fetchContactPersonsSuccess = payload => ({
  type: types.FETCH_CONTACT_PERSONS_SUCCESS,
  payload: payload,
});

const fetchAttachmentsSuccess = payload => ({
  type: types.FETCH_ATTACHMENTS_SUCCESS,
  payload: payload,
});

const fetchAssetVendorsSuccess = payload => ({
  type: types.FETCH_ASSET_VENDORS_SUCCESS,
  payload: payload,
});

const fetchSparePartsSuccess = payload => ({
  type: types.FETCH_SPARE_PARTS_SUCCESS,
  payload: payload,
});

const fetchPurchaseOrdersSuccess = payload => ({
  type: types.FETCH_PURCHASE_ORDERS_SUCCESS,
  payload: payload,
});

const fetchWorkOrdersSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_WORK_ORDERS_SUCCESS,
  payload: { ids, pagination },
});

export default {
  setVendorId,
  fetchWorkOrdersSuccess,
  fetchContactPersonsSuccess,
  fetchAttachmentsSuccess,
  fetchAssetVendorsSuccess,
  fetchSparePartsSuccess,
  fetchPurchaseOrdersSuccess,
};
