import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { PathItem, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetTreePath extends Component {
  renderRightComponent = () => {
    if (this.props.multiple === false && this.props.asset) {
      return <div className={styles['button']}>{this.props.listItemRightComponent(this.props.asset)}</div>;
    }
    return null;
  };
  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['path']}>
          <PathItem
            active={this.props.parentAssetId === 'root'}
            clickable={this.props.parentAssetId !== 'root'}
            onClick={() => this.props.onNavigate('root')}
          >
            <FormattedMessage id="components.select-asset-modal.content.root-asset" />
          </PathItem>
          {this.props.assetTreePath.map(asset => (
            <>
              <Icon regular type="angle-right" />
              <PathItem clickable onClick={() => this.props.onNavigate(asset.id)}>
                {asset.title}
              </PathItem>
            </>
          ))}
          {this.props.parentAssetId !== 'root' ? (
            <>
              <Icon regular type="angle-right" />
              <PathItem active onClick={() => this.props.onNavigate(this.props.asset.id)}>
                {this.props.asset.title}
              </PathItem>
            </>
          ) : null}
        </div>
        {this.renderRightComponent()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTreePath: EntitySelectors.getTreePathForAsset(state, ownProps.parentAssetId),
    asset: EntitySelectors.getAsset(state, ownProps.parentAssetId),
  };
}

export default connect(mapStateToProps)(AssetTreePath);
