import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class EditRequiredFieldsModal extends Component {
  state = {
    isSaving: false,
    request_description_required: false,
    request_asset_required: false,
    request_priority_required: false,
    request_type_required: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        request_description_required,
        request_asset_required,
        request_priority_required,
        request_type_required,
      } = this.props.system;
      this.setState({
        isSaving: false,
        request_description_required,
        request_asset_required,
        request_priority_required,
        request_type_required,
      });
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;
    this.setState({ isSaving: true });

    const {
      request_description_required,
      request_asset_required,
      request_priority_required,
      request_type_required,
    } = this.state;

    this.props
      .updateSystem(this.props.system.id, {
        request_description_required,
        request_asset_required,
        request_priority_required,
        request_type_required,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  closeModal = () => {
    let hasUnsavedChanges = false;
    if (
      this.state.request_description_required !== this.props.system.request_description_required ||
      this.state.request_asset_required !== this.props.system.request_asset_required ||
      this.state.request_priority_required !== this.props.system.request_priority_required ||
      this.state.request_type_required !== this.props.system.request_type_required
    ) {
      hasUnsavedChanges = true;
    }
    if (hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="screens.settings.requests.required-fields.title" />}
          subtitle={<FormattedMessage id="screens.settings.requests.required-fields.subtitle" />}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Field.Checkbox.Group>
            <Field.Checkbox disabled checked label={<FormattedMessage id="resources.request.title" />} />
            <Field.Checkbox
              checked={this.state.request_description_required}
              label={<FormattedMessage id="resources.request.description" />}
              onChange={() => {
                this.setState(prevState => ({
                  request_description_required: !prevState.request_description_required,
                }));
              }}
            />
            <Field.Checkbox
              checked={this.state.request_asset_required}
              label={<FormattedMessage id="resources.request.asset" />}
              onChange={() => {
                this.setState(prevState => ({
                  request_asset_required: !prevState.request_asset_required,
                }));
              }}
            />
            <Field.Checkbox
              checked={this.state.request_type_required}
              label={<FormattedMessage id="resources.request.request-type" />}
              onChange={() => {
                this.setState(prevState => ({
                  request_type_required: !prevState.request_type_required,
                }));
              }}
            />
            <Field.Checkbox
              checked={this.state.request_priority_required}
              label={<FormattedMessage id="resources.request.priority" />}
              onChange={() => {
                this.setState(prevState => ({
                  request_priority_required: !prevState.request_priority_required,
                }));
              }}
            />
          </Field.Checkbox.Group>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditRequiredFieldsModal));
