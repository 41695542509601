import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { withRouter } from 'react-router';
import { Decimal } from 'decimal.js';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Field,
  Button,
  ConfirmDeleteInlineModal,
  MoneyWithCurrency,
  Tooltip,
  Icon,
} from 'views/components/Shared/General';
import { ChangeCurrencyModal } from 'views/components/General';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { CostType } from 'sdk/Cost';
import toast from 'react-hot-toast';
import CostOtherCategoryContainer from './CostOtherCategoryContainer';
import VendorField from './VendorField';
import styles from './style.module.scss';

class CostModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    isDeleting: false,
    cost: {},
    costBeforeEdit: {},
    hasUnsavedChanges: false,
    showDescriptionError: false,
    showAmountError: false,
    showDateRequiredError: false,
    showChangeCurrencyModal: false,
  });

  state = this.getInitialState();

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let cost;
      if (this.props.id == null) {
        cost = {
          description: '',
          category: CostType.Other,
          date: moment().format('YYYY-MM-DD'),
          external: false,
          amount: '',
          amount_currency: this.props.currentSystem.currency,
        };
      } else {
        cost = {
          ...this.props.cost,
        };
      }
      this.setState({
        ...this.getInitialState(),
        cost,
        costBeforeEdit: cost,
      });
    } else {
      window.onbeforeunload = undefined;
    }
  }

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  saveCostValue = values => {
    const cost = { ...this.state.cost, ...values };

    const hasUnsavedChanges = !isEqual(this.state.costBeforeEdit, cost);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      cost,
      hasUnsavedChanges,
    });
  };

  getEditingChanges = () => {
    let data = {};
    const cost = this.state.cost;
    const diffProps = Object.keys(cost).filter(k => cost[k] !== this.state.costBeforeEdit[k]);
    diffProps.forEach(key => (data[key] = cost[key]));
    return data;
  };

  hasErrors = () => {
    let errors = {};
    if (!this.state.cost.description) {
      errors = {
        ...errors,
        showDescriptionError: true,
      };
    }
    if (!this.state.cost.amount) {
      errors = {
        ...errors,
        showAmountError: true,
      };
    }
    if (!this.state.cost.date) {
      errors = {
        ...errors,
        showDateRequiredError: true,
      };
    }
    this.setState({ ...errors });
    return Object.values(errors).some(item => item === true);
  };

  updateCost = () => {
    if (this.hasErrors()) {
      return;
    }

    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .updateCost(this.props.cost.id, this.getEditingChanges())
      .then(({ data: cost }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.costs.cost-modal.update-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  createCostForWorkOrder = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    const params = { ...this.state.cost };
    this.props
      .createCostForWorkOrder(this.props.match.params.id, params)
      .then(({ data: cost }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.costs.cost-modal.create-success" />}
          />
        );
        this.props.onSaved(cost);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteCost(this.props.cost.id)
      .then(() => {
        this.setState({ isDeleting: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.costs.cost-modal.delete-success" />}
          />
        );

        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  save = () => {
    if (this.state.isSaving) return;
    if (this.props.id == null) {
      this.createCostForWorkOrder();
    } else {
      this.updateCost();
    }
  };

  hasOtherCategoriesActivated = () => {
    return (
      this.props.currentSystem.cost_other_category_activated &&
      this.state.cost.category === CostType.Other &&
      this.props.costOtherCategories.length > 0
    );
  };

  renderConvertionRateAndLocalCurrency = () => {
    const { amount, amount_currency, amount_exchange_rate } = this.state.cost;
    const { currency: systemCurrency } = this.props.currentSystem;
    if (amount_currency !== systemCurrency) {
      let localValue;
      try {
        localValue = new Decimal(amount)
          .times(new Decimal(amount_exchange_rate))
          .toDecimalPlaces(2)
          .toString();
      } catch (e) {
        localValue = 0;
      }

      return (
        <div className={styles['convertion-rate-description-container']}>
          <div className={styles['convertion-rate']}>
            <Field
              fontSize={12}
              view
              singleRow
              label={<FormattedMessage id="resources.spare-part.currency-conversion-rate" />}
            >
              {new Decimal(amount_exchange_rate || 0).toDecimalPlaces(4).toString()}
            </Field>
            <div
              className={styles['icon-button']}
              onClick={() => this.setState({ showChangeCurrencyModal: true })}
            >
              <Icon regular size={11} type="pen" />
            </div>
          </div>
          <div className={styles['system-currency']}>
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />
          </div>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.cost.description" />}>
              <Field.Text
                error={this.state.showDescriptionError}
                autoFocus
                value={this.state.cost.description}
                onChange={description => {
                  if (description) {
                    this.setState({ showDescriptionError: false });
                  }
                  this.saveCostValue({ description });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.cost.date" />}>
              <Field.Date
                value={this.state.cost.date}
                error={this.state.showDateRequiredError}
                onChangeDate={date => {
                  this.setState({ showDateRequiredError: false });
                  this.saveCostValue({ date });
                }}
                onClear={() => {
                  this.saveCostValue({ date: null });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row align="end">
          <Grid.Column md={this.hasOtherCategoriesActivated() ? 6 : 12}>
            <Field label={<FormattedMessage id="resources.cost.category" />}>
              <Field.Dropdown
                value={<FormattedMessage id={`resources.cost.categories.${this.state.cost.category}`} />}
                onChange={category => this.saveCostValue({ category })}
                clearable={false}
              >
                <Field.Dropdown.Item
                  selected={this.state.cost.category === CostType.Labor}
                  onClick={() => this.saveCostValue({ category: CostType.Labor })}
                >
                  <FormattedMessage id="resources.cost.categories.labor" />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.cost.category === CostType.Part}
                  onClick={() => this.saveCostValue({ category: CostType.Part })}
                >
                  <FormattedMessage id="resources.cost.categories.part" />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.cost.category === CostType.Other}
                  onClick={() => this.saveCostValue({ category: CostType.Other })}
                >
                  <FormattedMessage id="resources.cost.categories.other" />
                </Field.Dropdown.Item>
              </Field.Dropdown>
            </Field>
          </Grid.Column>
          {this.hasOtherCategoriesActivated() ? (
            <Grid.Column md={6}>
              <CostOtherCategoryContainer
                value={this.state.cost.cost_other_category_id}
                costOtherCategories={this.props.costOtherCategories}
                onChange={cost_other_category_id => this.saveCostValue({ cost_other_category_id })}
              />
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <div className={styles['cost-label-container']}>
                  <div className={styles['label']}>
                    <FormattedMessage id="resources.cost.amount" />
                  </div>
                  <div>
                    <Button
                      type="text"
                      primary
                      fontSize={12}
                      label="screens.spare-part.info.order-information.change-currency"
                      noUnderline
                      onClick={() => {
                        this.setState({ showChangeCurrencyModal: true });
                      }}
                    />
                  </div>
                </div>
              }
              description={this.renderConvertionRateAndLocalCurrency()}
            >
              <Field.Money
                error={this.state.showAmountError}
                currency={this.state.cost.amount_currency}
                value={this.state.cost.amount}
                onChange={amount => {
                  if (amount && this.state.showAmountError) {
                    this.setState({ showAmountError: false });
                  }
                  this.saveCostValue({ amount });
                }}
                onBlur={amount => {
                  this.saveCostValue({ amount });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.cost.external}
              label={
                <div className={styles['exernal-cost-label-container']}>
                  <FormattedMessage id="resources.cost.external" />
                  <Tooltip
                    trigger={
                      <div className={styles['tooltip-container']}>
                        <Icon regular type="question-circle" />
                      </div>
                    }
                    label={
                      <FormattedMessage id="screens.work-order.costs.cost-modal.external-tooltip-text" />
                    }
                  />
                </div>
              }
              onChange={external => {
                this.saveCostValue({ external, vendor_id: null });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {this.state.cost.external ? (
          <VendorField
            id={this.state.cost.vendor_id}
            onChange={vendor_id => this.saveCostValue({ vendor_id })}
          />
        ) : null}
      </Grid>
    );
  };

  renderChangeCurrencyModal = () => {
    return (
      <ChangeCurrencyModal
        currency={this.state.cost.amount_currency}
        exchangeRate={this.state.cost.amount_exchange_rate}
        open={this.state.showChangeCurrencyModal}
        onSave={({ exchangeRate, currency }) => {
          this.saveCostValue({
            amount_currency: currency,
            amount_exchange_rate: exchangeRate,
          });
          this.setState({ showChangeCurrencyModal: false });
        }}
        onClose={() => {
          this.setState({ showChangeCurrencyModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <Modal width={500} isOpen={this.props.open}>
          <Prompt
            when={this.state.hasUnsavedChanges}
            message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
          />
          <Modal.Header
            title={
              this.props.id == null ? (
                <FormattedMessage id="screens.work-order.costs.cost-modal.new-title" />
              ) : (
                <FormattedMessage id="screens.work-order.costs.cost-modal.edit-title" />
              )
            }
            iconButtons={
              this.props.id == null ? null : (
                <React.Fragment>
                  <ConfirmDeleteInlineModal
                    trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
                    title={<FormattedMessage id="general.delete-confirm.title" />}
                    subtitle={<FormattedMessage id="general.delete-confirm.subtitle" />}
                    onDelete={this.delete}
                    position="right"
                    loading={this.state.isDeleting}
                  />
                </React.Fragment>
              )
            }
            onClose={this.closeModal}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button primary loading={this.state.isSaving} onClick={this.save} label="general.save" />
              <Button label="general.cancel" onClick={this.closeModal} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderChangeCurrencyModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCostForWorkOrder: SDKReduxOperations.createCostForWorkOrder,
      updateCost: SDKReduxOperations.updateCost,
      deleteCost: SDKReduxOperations.deleteCost,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.id) {
    return {
      cost: EntitySelectors.getCost(state, ownProps.id),
      costOtherCategories: AuthSelectors.getOtherCostCategories(state),
      currentSystem: AuthSelectors.getCurrentSystem(state),
    };
  }

  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    costOtherCategories: AuthSelectors.getOtherCostCategories(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CostModal)));
