import React from 'react';
import styles from './style.module.scss';
import { Datapill } from 'views/components/Shared/General';

const ArhiveItem = ({ label, title, number, loading }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['label-container']}>{label}</div>
      <div className={styles['item-container']}>
        {title}
        <div className={styles['datapill-container']}>
          <Datapill loading={loading} red value={number} />
        </div>
      </div>
    </div>
  );
};

export default ArhiveItem;
