import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, List, Icon } from 'views/components/Shared/General';
import { UploadingAttachmentContent } from 'views/components/Attachment';
import { WorkOrderSelectors } from 'state/ducks/workOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { AttachmentType } from 'sdk/Attachment';
import { SDKReduxOperations } from 'sdk';
import AssetAttachmentOptionsInlineModal from './AssetAttachmentOptionsInlineModal';
import { AttachmentListItem, AttachmentOptionsInlineModal } from 'views/components/Attachment';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';

class Attachments extends Component {
  state = {
    isDeletingAttachment: false,
  };

  renderAttachmentEditModal = attachment => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    return (
      <AttachmentOptionsInlineModal
        context={recurringMaintenanceId ? 'recurringMaintenance' : 'workOrder'}
        attachment={attachment}
        isDeletingAttachment={this.state.isDeletingAttachment}
        onDeleteAttachment={attachment => this.deleteAttachmentFromWorkOrder(attachment)}
        trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
      />
    );
  };

  render = () => {
    const { registrationAttachments, uploadingAttachments } = this.props;
    if (registrationAttachments.length === 0 && uploadingAttachments.length === 0) return null;
    const workOrderId = this.props.match.params.id;
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    const upploadFileNameSpace = recurringMaintenanceId || workOrderId;
    return (
      <>
        {uploadingAttachments.map(uploadingAttachment => (
          <List.Item>
            <UploadingAttachmentContent
              namespace={upploadFileNameSpace}
              uploadingAttachment={uploadingAttachment}
            />
          </List.Item>
        ))}
        {registrationAttachments.map(attachment => {
          if (attachment.type === AttachmentType.Asset) {
            return (
              <AttachmentListItem
                subtitle
                hideCreatedAt
                key={attachment.id}
                clickable={false}
                attachment={attachment}
                buttons={
                  <AssetAttachmentOptionsInlineModal
                    attachment={attachment}
                    onRemoveAssetAttachment={attachment => this.deleteAttachmentFromWorkOrder(attachment)}
                    trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                  />
                }
              />
            );
          } else {
            return (
              <AttachmentListItem
                subtitle
                hideCreatedAt
                key={attachment.id}
                clickable={false}
                attachment={attachment}
                buttons={
                  <AttachmentOptionsInlineModal
                    context={recurringMaintenanceId ? 'recurringMaintenance' : 'workOrder'}
                    attachment={attachment}
                    isDeletingAttachment={this.state.isDeletingAttachment}
                    onDeleteAttachment={attachment => this.deleteAttachmentFromWorkOrder(attachment)}
                    trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                  />
                }
              />
            );
          }
        })}
      </>
    );
  };

  deleteAttachmentFromWorkOrder = attachment => {
    if (attachment.type === AttachmentType.WorkOrderRegistration) {
      this.deleteRegistrationAttachmentFromWorkOrder(attachment);
    } else if (attachment.type === AttachmentType.Asset) {
      this.removeAssetAttachment(attachment);
    } else {
      this.removeRegistrationAttachment(attachment);
    }
  };

  deleteRegistrationAttachmentFromWorkOrder = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props
      .deleteAttachment(attachment.id)
      .then(() => {
        this.setState({ isDeletingAttachment: false });
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.work-order.delete-file-success" />} />
        );
      })
      .catch(e => {
        this.setState({ isDeletingAttachment: false });
      });
  };

  removeRegistrationAttachment = attachment => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.setState({ isDeletingAttachment: true });
      this.props
        .removeRegistrationAttachmentFromRecurringMaintenance(this.props.editingWorkOrder.id, attachment.id)
        .then(() => {
          this.setState({ isDeletingAttachment: false });
          toast(
            <ToastMessage success text={<FormattedMessage id="screens.work-order.delete-file-success" />} />
          );
        })
        .catch(e => {
          this.setState({ isDeletingAttachment: false });
        });
    } else {
      this.setState({ isDeletingAttachment: true });
      this.props
        .removeRegistrationAttachmentFromWorkOrder(this.props.editingWorkOrder.id, attachment.id)
        .then(() => {
          this.setState({ isDeletingAttachment: false });
          toast(
            <ToastMessage success text={<FormattedMessage id="screens.work-order.delete-file-success" />} />
          );
        })
        .catch(e => {
          this.setState({ isDeletingAttachment: false });
        });
    }
  };

  removeAssetAttachment = attachment => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.setState({ isDeletingAttachment: true });
      this.props
        .removeRegistrationAttachmentFromRecurringMaintenance(this.props.editingWorkOrder.id, attachment.id)
        .then(() => {
          this.setState({ isDeletingAttachment: false });
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.work-order.remove-asset-file-success" />}
            />
          );
        })
        .catch(e => {
          this.setState({ isDeletingAttachment: false });
        });
    } else {
      this.setState({ isDeletingAttachment: true });
      this.props
        .removeRegistrationAttachmentFromWorkOrder(this.props.editingWorkOrder.id, attachment.id)
        .then(() => {
          this.setState({ isDeletingAttachment: false });
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.work-order.remove-asset-file-success" />}
            />
          );
        })
        .catch(e => {
          this.setState({ isDeletingAttachment: false });
        });
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeRegistrationAttachmentFromRecurringMaintenance:
        SDKReduxOperations.removeRegistrationAttachmentFromRecurringMaintenance,
      removeRegistrationAttachmentFromWorkOrder: SDKReduxOperations.removeRegistrationAttachmentFromWorkOrder,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
      addRegistrationAttachmentToWorkOrder: SDKReduxOperations.addRegistrationAttachmentToWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderId = ownProps.match.params.id;

  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      registrationAttachments: EntitySelectors.getAttachments(
        state,
        recurringMaintenance.registration_attachments
      ),
      uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, recurringMaintenanceId),
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      registrationAttachments: EntitySelectors.getAttachments(state, workOrder.registration_attachments),
      uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, workOrder.id),
    };
  }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Attachments)));
