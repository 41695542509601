import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import { InlineModal, DatePicker, Filter } from 'views/components/Shared/General';

export default class DueDateInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showCustomDate: false,
    };
  }

  selectDate = (fromDate, toDate) => {
    this.props.onSelectDate(fromDate, toDate);
    this.setState({ isOpen: false });
  };

  clear = () => {
    this.setState({ isOpen: false });
    this.props.onClear();
  };

  isDue = () => this.props.overdue != null;
  isToday = () =>
    moment(this.props.fromDate).isSame(moment().format('YYYY-MM-DD')) &&
    moment(this.props.toDate).isSame(moment().format('YYYY-MM-DD'));
  isTomorrow = () =>
    moment(this.props.fromDate).isSame(moment().add('1', 'days').format('YYYY-MM-DD')) &&
    moment(this.props.toDate).isSame(moment().add('1', 'days').format('YYYY-MM-DD'));
  isThisWeek = () =>
    moment(this.props.fromDate).isSame(moment().startOf('week').format('YYYY-MM-DD')) &&
    moment(this.props.toDate).isSame(moment().endOf('week').format('YYYY-MM-DD'));
  isNextWeek = () =>
    moment(this.props.fromDate).isSame(moment().add('1', 'week').startOf('week').format('YYYY-MM-DD')) &&
    moment(this.props.toDate).isSame(moment().add('1', 'week').endOf('week').format('YYYY-MM-DD'));
  isThisMonth = () =>
    moment(this.props.fromDate).isSame(moment().startOf('month').format('YYYY-MM-DD')) &&
    moment(this.props.toDate).isSame(moment().endOf('month').format('YYYY-MM-DD'));
  isOtherDate = () =>
    (this.props.fromDate || this.props.toDate) &&
    !this.isDue() &&
    !this.isToday() &&
    !this.isTomorrow() &&
    !this.isThisWeek() &&
    !this.isNextWeek() &&
    !this.isThisMonth();

  renderPresetOptions = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          title={<FormattedMessage id="components.due-date-inline-modal.title" />}
          onClose={() => this.setState({ isOpen: false })}
          menuButtonComponent={this.rendermenuButtonsComponent()}
        />
        <InlineModal.Body width={300}>
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            iconColor="#C55050"
            title={<FormattedMessage id="components.due-date-inline-modal.options.due" />}
            selected={this.isDue()}
            onClick={() => {
              this.props.onSelectDue();
              this.setState({ isOpen: false });
            }}
          />
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.today" />}
            selected={this.isToday()}
            onClick={e => this.selectDate(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))}
          />
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.tomorrow" />}
            selected={this.isTomorrow()}
            onClick={e =>
              this.selectDate(
                moment().add('1', 'days').format('YYYY-MM-DD'),
                moment().add('1', 'days').format('YYYY-MM-DD')
              )
            }
          />
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.this-week" />}
            selected={this.isThisWeek()}
            onClick={e =>
              this.selectDate(
                moment().startOf('week').format('YYYY-MM-DD'),
                moment().endOf('week').format('YYYY-MM-DD')
              )
            }
          />
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.next-week" />}
            selected={this.isNextWeek()}
            onClick={e =>
              this.selectDate(
                moment().add('1', 'weeks').startOf('week').format('YYYY-MM-DD'),
                moment().add('1', 'weeks').endOf('week').format('YYYY-MM-DD')
              )
            }
          />
          <InlineModal.ListItem
            icon="calendar-alt"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.this-month" />}
            selected={this.isThisMonth()}
            onClick={e =>
              this.selectDate(
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD')
              )
            }
          />
          <InlineModal.Separator />
          <InlineModal.ListItem
            icon="calendar-day"
            iconThickness="regular"
            title={<FormattedMessage id="components.due-date-inline-modal.options.show-calendar" />}
            selected={this.isOtherDate()}
            onClick={() => this.setState({ showCustomDate: true })}
          />
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderCustomDate = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          title={<FormattedMessage id="components.due-date-inline-modal.title" />}
          onClose={() => this.setState({ isOpen: false })}
          showBackButton
          onBack={() => this.setState({ showCustomDate: false })}
          menuButtonComponent={this.rendermenuButtonsComponent()}
        />
        <InlineModal.Body width={337}>
          <DatePicker.Range
            inline
            onSelectDate={({ from, to }) => this.selectDate(from, to)}
            fromDate={this.props.fromDate}
            toDate={this.props.toDate}
          />
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderSelectedDate = () => {
    if (this.isDue()) return <FormattedMessage id="components.due-date-inline-modal.options.due" />;
    if (this.isToday()) return <FormattedMessage id="components.due-date-inline-modal.options.today" />;
    if (this.isTomorrow()) return <FormattedMessage id="components.due-date-inline-modal.options.tomorrow" />;
    if (this.isThisWeek())
      return <FormattedMessage id="components.due-date-inline-modal.options.this-week" />;
    if (this.isNextWeek())
      return <FormattedMessage id="components.due-date-inline-modal.options.next-week" />;
    if (this.isThisMonth())
      return <FormattedMessage id="components.due-date-inline-modal.options.this-month" />;
    if (this.props.fromDate != null && this.props.toDate != null && this.props.fromDate === this.props.toDate)
      return <span>{moment(this.props.fromDate).format('YYYY-MM-DD')}</span>;
    if (this.props.fromDate != null && this.props.toDate != null)
      return (
        <span>
          {moment(this.props.fromDate).format('YYYY-MM-DD')} -{' '}
          {moment(this.props.toDate).format('YYYY-MM-DD')}
        </span>
      );
    return null;
  };

  rendermenuButtonsComponent = () => {
    if (!this.props.fromDate && !this.props.toDate) return null;

    return (
      <InlineModal.HeaderButton
        primary
        onClick={this.clear}
        title={<FormattedMessage id="general.clean" />}
      />
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              showCustomDate: false,
            }));
          }}
        >
          <Filter.Button
            label="screens.work-orders.filters.quick.due-date"
            selectedValueComponent={this.renderSelectedDate()}
            onClear={this.props.onClear}
          />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          {this.state.showCustomDate ? this.renderCustomDate() : this.renderPresetOptions()}
        </InlineModal>
      </>
    );
  }
}
