import React, { PureComponent } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { NewSearchField } from 'views/components/Shared/General';
import { LIST_TYPES } from '../../';

class SearchField extends PureComponent {
  getSearchPlaceHolderForList = () => {
    switch (this.props.list) {
      case LIST_TYPES.Calendar:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.calendar',
        });

      case LIST_TYPES.Upcoming:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.upcoming',
        });

      case LIST_TYPES.Today:
        return this.props.intl.formatMessage({ id: 'screens.work-orders.search-placeholder-for-list.today' });

      case LIST_TYPES.ThisWeek:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.this-week',
        });

      case LIST_TYPES.ThisMonth:
        return this.props.intl.formatMessage(
          {
            id: 'screens.work-orders.search-placeholder-for-list.this-month',
          },
          {
            month: moment().format('MMMM').toLowerCase(),
          }
        );

      case LIST_TYPES.Overdue:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.overdue',
        });

      case LIST_TYPES.AwaitingSpareParts:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.awaiting-spare-parts',
        });

      case LIST_TYPES.Completed:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.completed',
        });
      case LIST_TYPES.All:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.all',
        });

      default:
        return this.props.intl.formatMessage({ id: 'general.search-placeholder' });
    }
  };

  render() {
    return (
      <NewSearchField
        value={this.props.value}
        debounce
        placeholder={this.getSearchPlaceHolderForList()}
        onSearch={value => {
          this.props.onSearch(value);
        }}
        onDebouncedSearch={value => {
          this.props.onDebouncedSearch(value);
        }}
        onClear={() => {
          this.props.onClear();
        }}
      />
    );
  }
}

export default injectIntl(SearchField);
