import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';
import { AuthSelectors } from 'state/ducks/auth';
import { debounce } from 'lodash-es';
import { Button, List, Icon } from 'views/components/Shared/General';
import { WorkOrderEditOperations, WorkOrderEditSelectors } from 'state/ducks/workOrderEdit';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderSelectors } from 'state/ducks/workOrder';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import TabBarItem from './TabBarItem';
import styles from './style.module.scss';
import Images from './Images';
import Attachments from './Attachments';
import SpareParts from './SpareParts';
import Reminders from './Reminders';
import Checklist from './Checklist';
import { ReminderType } from 'sdk/WorkOrder';
import smoothscroll from 'smoothscroll-polyfill';

class ItemTabBar extends Component {
  constructor() {
    super();
    this.state = {
      isTabsOverflowing: false,
    };
    this.debouncedWindowResizedFunction = debounce(this.checkTabOverflow, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedWindowResizedFunction);
    smoothscroll.polyfill();
    if (!this.shouldHideAllTabs()) {
      this.checkTabOverflow();
    }
    this.goToFirstTab();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.resourceItemsSelectedTab === 'spare_part' && !this.shouldShowSparePartTab()) ||
      (this.props.resourceItemsSelectedTab === 'checklist' && !this.shouldShowChecklistTab()) ||
      (this.props.resourceItemsSelectedTab === 'attachment' && !this.shouldShowAttachmentTab()) ||
      (this.props.resourceItemsSelectedTab === 'image' && !this.shouldShowImageTab()) ||
      (this.props.resourceItemsSelectedTab === 'reminder' && !this.shouldShowReminderTab())
    ) {
      this.goToFirstTab();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedWindowResizedFunction);
  }

  showScrollButtons = () => {
    return this.state.isTabsOverflowing && !this.shouldHideAllTabs();
  };

  checkTabOverflow = () => {
    if (this.element) {
      const boundingClientRectWidth = this.element.getBoundingClientRect().width;
      const scrollWidth = this.element.scrollWidth;
      const iOffsetWidth = this.element.offsetWidth;
      const isTabsOverflowing = scrollWidth > iOffsetWidth && boundingClientRectWidth === iOffsetWidth;
      if (isTabsOverflowing !== this.state.isTabsOverflowing) {
        this.setState({ isTabsOverflowing });
      }
    }
  };

  shouldShowSparePartTab = () =>
    this.props.sparePartReservations.length > 0 && this.props.settings.spare_parts_activated;

  shouldShowChecklistTab = () => {
    const { checklistInstances, checklistTemplateLinks, isAddingChecklistTemplate } = this.props;
    const hasInstances = checklistInstances && checklistInstances.length > 0;
    const hasTemplateLinks = checklistTemplateLinks && checklistTemplateLinks.length > 0;
    return hasInstances || hasTemplateLinks || isAddingChecklistTemplate;
  };

  shouldShowAttachmentTab = () =>
    !(this.props.registrationAttachments.length === 0 && this.props.uploadingAttachments.length === 0);
  shouldShowImageTab = () =>
    !(
      (this.props.registrationImages == null || this.props.registrationImages.length === 0) &&
      this.props.imageLoaderCount === 0
    );
  shouldShowReminderTab = () => this.props.editingWorkOrder.reminder_type !== ReminderType.None;
  shouldHideAllTabs = () =>
    !this.shouldShowSparePartTab() &&
    !this.shouldShowChecklistTab() &&
    !this.shouldShowAttachmentTab() &&
    !this.shouldShowImageTab() &&
    !this.shouldShowReminderTab();

  goToFirstTab = () => {
    if (this.shouldShowSparePartTab()) {
      return this.props.setResourceItemsSelectedTab('spare_part');
    } else if (this.shouldShowChecklistTab()) {
      return this.props.setResourceItemsSelectedTab('checklist');
    } else if (this.shouldShowAttachmentTab()) {
      return this.props.setResourceItemsSelectedTab('attachment');
    } else if (this.shouldShowImageTab()) {
      return this.props.setResourceItemsSelectedTab('image');
    } else if (this.shouldShowReminderTab()) {
      return this.props.setResourceItemsSelectedTab('reminder');
    } else return null;
  };

  renderTabContent = () => {
    switch (this.props.resourceItemsSelectedTab) {
      case 'spare_part':
        return this.props.settings.spare_parts_activated ? <SpareParts /> : null;
      case 'checklist':
        return <Checklist />;
      case 'attachment':
        return <Attachments />;
      case 'image':
        return <Images />;
      case 'reminder':
        return <Reminders />;
      default:
        return null;
    }
  };
  renderLeftScroll = () => (
    <div className={[styles['icon-container'], styles['left']].join(' ')}>
      <Button
        type="icon"
        icon={<Icon regular size={18} type="angle-left" />}
        small
        onClick={() => {
          this.element.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }}
      />
    </div>
  );

  renderRightScroll = () => (
    <div className={[styles['icon-container'], styles['right']].join(' ')}>
      <Button
        type="icon"
        icon={<Icon regular size={18} type="angle-right" />}
        small
        onClick={() => {
          this.element.scroll({ top: 0, left: 300, behavior: 'smooth' });
          //TODO: Byt ut scroll(300) till scroll(värdet på content width)
        }}
      />
    </div>
  );

  render() {
    if (this.shouldHideAllTabs()) return null;

    let classNames = [styles['tabs']];
    if (this.showScrollButtons()) {
      classNames = [...classNames, styles['with-buttons']];
    }

    return (
      <List>
        <div className={classNames.join(' ')}>
          {this.showScrollButtons() ? this.renderLeftScroll() : null}

          <div
            className={styles['tab-list']}
            ref={el => {
              this.element = el;
            }}
          >
            {this.shouldShowSparePartTab() && this.props.settings.spare_parts_activated ? (
              <TabBarItem
                active={this.props.resourceItemsSelectedTab === 'spare_part'}
                onClick={() => this.props.setResourceItemsSelectedTab('spare_part')}
              >
                <FormattedMessage id="screens.work-order.edit.spare-parts-title" />
              </TabBarItem>
            ) : null}
            {this.shouldShowChecklistTab() ? (
              <TabBarItem
                active={this.props.resourceItemsSelectedTab === 'checklist'}
                onClick={() => this.props.setResourceItemsSelectedTab('checklist')}
              >
                <FormattedMessage id="screens.work-order.edit.checklist-title" />
              </TabBarItem>
            ) : null}
            {this.shouldShowAttachmentTab() ? (
              <TabBarItem
                active={this.props.resourceItemsSelectedTab === 'attachment'}
                onClick={() => this.props.setResourceItemsSelectedTab('attachment')}
              >
                <FormattedMessage id="screens.work-order.edit.attachment-title" />
              </TabBarItem>
            ) : null}

            {this.shouldShowImageTab() ? (
              <TabBarItem
                active={this.props.resourceItemsSelectedTab === 'image'}
                onClick={() => {
                  this.props.setResourceItemsSelectedTab('image');
                }}
              >
                <FormattedMessage id="screens.work-order.edit.image-title" />
              </TabBarItem>
            ) : null}
            {this.shouldShowReminderTab() ? (
              <TabBarItem
                active={this.props.resourceItemsSelectedTab === 'reminder'}
                onClick={() => this.props.setResourceItemsSelectedTab('reminder')}
              >
                <FormattedMessage id="screens.work-order.edit.reminder-title" />
              </TabBarItem>
            ) : null}
          </div>

          {this.showScrollButtons() ? this.renderRightScroll() : null}
        </div>
        {this.renderTabContent()}
      </List>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setResourceItemsSelectedTab: WorkOrderEditOperations.setResourceItemsSelectedTab,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderId = ownProps.match.params.id;

  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      resourceItemsSelectedTab: WorkOrderEditSelectors.getResourceItemsSelectedTab(state),
      imageLoaderCount: WorkOrderEditSelectors.getImageLoaderCount(state),
      registrationImages: EntitySelectors.getImages(state, recurringMaintenance.images),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        recurringMaintenance.checklist_template_links
      ),
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, recurringMaintenance.spare_part_reservations) || [],
      registrationAttachments: EntitySelectors.getAttachments(
        state,
        recurringMaintenance.registration_attachments
      ),
      settings: AuthSelectors.getSettings(state),
      uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, recurringMaintenanceId),
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
      resourceItemsSelectedTab: WorkOrderEditSelectors.getResourceItemsSelectedTab(state),
      imageLoaderCount: WorkOrderEditSelectors.getImageLoaderCount(state),
      registrationImages: EntitySelectors.getImages(state, workOrder.registration_images),
      checklistInstances: EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        workOrder.checklist_template_links
      ),
      settings: AuthSelectors.getSettings(state),
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, workOrder.spare_part_reservations) || [],
      registrationAttachments: EntitySelectors.getAttachments(state, workOrder.registration_attachments),
      uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, workOrderId),
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
    };
  }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ItemTabBar)));
