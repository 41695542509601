import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { WhiteCard, Button, Tooltip, Icon, ViewTextArea, Field } from 'views/components/Shared/General';
import { TemplateField } from 'views/components/TemplateField';
import { AuthSelectors } from 'state/ducks/auth';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import ContentLoader from 'react-content-loader';
import SparePartTypeField from './SparePartTypeField';
import SparePartUnitField from './SparePartUnitField';
import SparePartStorageLocationField from './SparePartStorageLocationField';
import styles from './styles.module.scss';

class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingSparePart: {},
      editingSparePartBeforeEdit: {},
      editingSparePartFields: {},
      editingSparePartFieldsBeforeEdit: {},
    };
  }

  edit = () => {
    const editingSparePartFields = this.buildEditingSparePartFields();
    const editingSparePart = {
      spare_part_type_id: this.props.sparePart.spare_part_type_id,
      spare_part_unit_id: this.props.sparePart.spare_part_unit_id,
      spare_part_location_id: this.props.sparePart.spare_part_location_id,
      description: this.props.sparePart.description,
    };

    this.setState({
      editing: true,
      editingSparePart,
      editingSparePartBeforeEdit: editingSparePart,
      editingSparePartFields: editingSparePartFields,
      editingSparePartFieldsBeforeEdit: editingSparePartFields,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });

    const params = {
      ...this.getModifiedSparePartData(),
      spare_part_fields: this.getModifiedSparePartFieldsData(),
    };

    this.props
      .updateSparePart(this.props.sparePart.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="screens.asset.info.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedSparePartData = () => {
    const sparePart = this.state.editingSparePart;
    const sparePartBeforeEdit = this.state.editingSparePartBeforeEdit;

    return Object.keys(sparePart)
      .filter(key => sparePart[key] !== sparePartBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: sparePart[key],
        }),
        {}
      );
  };

  getModifiedSparePartFieldsData = () => {
    const sparePartFields = this.state.editingSparePartFields;
    const sparePartFieldsBeforeEdit = this.state.editingSparePartFieldsBeforeEdit;

    return Object.keys(sparePartFields)
      .filter(key => sparePartFields[key] !== sparePartFieldsBeforeEdit[key])
      .map(key => ({
        template_field_id: key,
        value: sparePartFields[key],
      }));
  };

  buildEditingSparePartFields = () => {
    return this.props.templateFields.reduce((acc, templateField) => {
      const sparePartField = this.props.sparePartFields.find(
        sparePartField => sparePartField.template_field_id === templateField.id
      );

      //sparePartField can be null if a new templatefield is created through the socket
      return {
        ...acc,
        [templateField.id]: sparePartField == null ? '' : sparePartField.value,
      };
    }, {});
  };

  setSparePartValue = obj => {
    const newEditingSparePart = {
      ...this.state.editingSparePart,
      ...obj,
    };

    this.setState({
      editingSparePart: newEditingSparePart,
    });
  };

  setSparePartFieldValue = (templateField, value) => {
    const newEditingSparePartFields = {
      ...this.state.editingSparePartFields,
      [templateField.id]: value,
    };

    this.setState({
      editingSparePartFields: newEditingSparePartFields,
    });
  };

  getFieldsInColumn = column =>
    this.props.templateFields
      .filter(templateField => templateField.column === column)
      .sort((a, b) => parseInt(a.sort) - parseInt(b.sort));

  getSparePartFieldValueForTemplateField = templateField => {
    if (this.state.editing) {
      return this.state.editingSparePartFields[templateField.id];
    } else {
      const sparePartField = this.props.sparePartFields.find(
        sparePartField => sparePartField.template_field_id === templateField.id
      );

      //sparePartField can be null if a new templatefield is created through the socket
      return sparePartField == null ? '' : sparePartField.value;
    }
  };

  isLoaded = () =>
    this.props.sparePart != null && this.props.sparePartFields != null && this.props.templateFields != null;

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="12" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="28" rx="2" ry="2" width="70" height="6" />
      </ContentLoader>
    </div>
  );

  renderTemplateField = templateField => (
    <TemplateField
      templateField={templateField}
      editing={this.state.editing}
      value={this.getSparePartFieldValueForTemplateField(templateField)}
      onValueChange={value => {
        this.setSparePartFieldValue(templateField, value);
      }}
    />
  );

  renderTemplateFields = () => {
    if (this.props.templateFields.length === 0) return null;

    const fieldsColumnOne = this.getFieldsInColumn(1);
    const fieldsColumnTwo = this.getFieldsInColumn(2);
    const numOfRows = Math.max(fieldsColumnOne.length, fieldsColumnTwo.length);

    let rows = [];

    for (var i = 0; i < numOfRows; i++) {
      rows = [
        ...rows,
        <Grid.Row key={i}>
          <Grid.Column>
            {fieldsColumnOne[i] ? this.renderTemplateField(fieldsColumnOne[i]) : null}
          </Grid.Column>
          <Grid.Column>
            {fieldsColumnTwo[i] ? this.renderTemplateField(fieldsColumnTwo[i]) : null}
          </Grid.Column>
        </Grid.Row>,
      ];
    }

    return (
      <React.Fragment>
        <div className={styles['separator']} />
        <Grid>{rows}</Grid>
      </React.Fragment>
    );
  };

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditSpareParts) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderDescriptionField = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.spare-part.description" />}>
          <Field.Textarea
            value={this.state.editingSparePart.description}
            onChange={description => {
              this.setSparePartValue({ description });
            }}
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.spare-part.description" />}>
        {this.props.sparePart.description ? (
          <ViewTextArea>{this.props.sparePart.description}</ViewTextArea>
        ) : (
          '-'
        )}
      </Field>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return this.renderContentLoader();

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column md={6}>
              <SparePartTypeField
                editing={this.state.editing}
                value={
                  this.state.editing
                    ? this.state.editingSparePart.spare_part_type_id
                    : this.props.sparePart.spare_part_type_id
                }
                onChange={spare_part_type_id => {
                  this.setSparePartValue({ spare_part_type_id });
                }}
              />
            </Grid.Column>
            <Grid.Column md={6}>
              <SparePartStorageLocationField
                editing={this.state.editing}
                value={
                  this.state.editing
                    ? this.state.editingSparePart.spare_part_location_id
                    : this.props.sparePart.spare_part_location_id
                }
                onChange={spare_part_location_id => {
                  this.setSparePartValue({ spare_part_location_id });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderDescriptionField()}</Grid.Column>
            <Grid.Column md={6}>
              <SparePartUnitField
                editing={this.state.editing}
                value={
                  this.state.editing
                    ? this.state.editingSparePart.spare_part_unit_id
                    : this.props.sparePart.spare_part_unit_id
                }
                onChange={spare_part_unit_id => {
                  this.setSparePartValue({ spare_part_unit_id });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderTemplateFields()}
      </React.Fragment>
    );
  };

  render() {
    return (
      <WhiteCard
        title={<FormattedMessage id="screens.spare-part.info.general-information.title" />}
        headerButtons={this.renderHeaderButtons()}
        footer={this.renderFooter()}
      >
        {this.renderContent()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const sparePartId = ownProps.match.params.id;
  const sparePart = EntitySelectors.getSparePart(state, sparePartId);
  return {
    sparePart: sparePart,
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    sparePartFields: EntitySelectors.getSparePartFields(state, sparePart.spare_part_fields),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GeneralInformation)));
