import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { debounce, isEqual } from 'lodash-es';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router';
import { Decimal } from 'decimal.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Button, Menu, List, Pagination } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { OrganisationOperations, OrganisationSelectors } from 'state/ducks/organisation';
import { AuthSelectors } from 'state/ducks/auth';
import UserListItem from './UserListItem';
import CreateUserModal from './components/CreateUserModal';
import BillingInformationModal from './BillingInformationModal';
import styles from './style.module.scss';

const listUsersRequest = SDKHelperFunctions.getCancelTokenForRequest();

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateUserModal: false,
      isFetching: true,
      discounts: [],
      showBillingInformationModal: false,
    };
    this.fetchDebouncedUsers = debounce(() => {
      this.fetchUsers();
    }, 300);
  }

  componentDidMount() {
    this.fetchUsers();
    this.props.fetchAndSetAmountOfPayingUsers(this.props.organisation.id);
  }

  componentDidUpdate(prevProps) {
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const { system: oldSystemId, type: oldType } = queryString.parse(prevProps.location.search);
    const { system: systemId, type } = queryString.parse(this.props.location.search);
    const changedSearchTerm = prevProps.searchTerm !== this.props.searchTerm;
    const changedSystem = oldSystemId !== systemId;
    const changedType = oldType !== type;
    if (changedType || changedSystem || changedQueryParams || changedSearchTerm) {
      this.setState({ isFetching: true });
      listUsersRequest.cancel();
      this.fetchDebouncedUsers();
    }
    if (prevProps.users.length !== this.props.users.length) {
      this.props.fetchAndSetAmountOfPayingUsers(this.props.organisation.id);
    }
  }

  fetchUsers = (params = {}) => {
    const { system: systemId, type } = queryString.parse(this.props.location.search);
    let attrs = {
      ...this.props.queryParameters,
    };
    if (systemId) {
      attrs = {
        ...attrs,
        system_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: systemId,
        },
      };
    }
    if (this.props.searchTerm) {
      attrs = {
        ...attrs,
        search: this.props.searchTerm,
      };
    }
    if (type) {
      attrs = {
        ...attrs,
        archived: true,
      };
    }
    attrs = {
      ...attrs,
      ...params,
    };

    this.props
      .fetchUsers(this.props.organisation.id, attrs, listUsersRequest.getCancelTokenConfig())
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
    </div>
  );

  renderArchivedUsersEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.organisation.archived-users-empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.organisation.archived-users-empty-data-set.subtitle" />
      </div>
    </div>
  );

  renderPagination = () => {
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderListHeader = () => {
    const { system } = queryString.parse(this.props.location.search);
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.user.name" />
        </List.Header.Column>
        {system == null ? (
          <List.Header.Column width={250}>
            <FormattedMessage id="resources.system.resource" />
          </List.Header.Column>
        ) : null}
        <List.Header.Column width={250}>
          <FormattedMessage id="resources.user.permission" />
        </List.Header.Column>
        <List.Header.Column width={108} />
      </List.Header>
    );
  };

  renderList = () => {
    const { type } = queryString.parse(this.props.location.search);
    if (this.state.isFetching) {
      const amountOfUsers = this.props.users.length === 0 ? 2 : this.props.users.length;
      return (
        <>
          {this.renderListHeader()}
          <List small>
            {Array(amountOfUsers)
              .fill()
              .map(() => (
                <UserListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.users.length === 0) {
      if (this.props.searchTerm.length > 0) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderArchivedUsersEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List small>
          {this.props.users.map(({ id }) => {
            return <UserListItem key={id} id={id} />;
          })}
        </List>
      </>
    );
  };

  renderCreateButton = () => {
    return (
      <>
        <Button
          fullWidth
          primary
          label="screens.organisation.create-user-button"
          onClick={() => {
            this.setState({ showCreateUserModal: true });
          }}
        />
        <Menu.Separator />
      </>
    );
  };

  renderSystems = () => {
    const { system: systemId } = queryString.parse(this.props.location.search);
    return (
      <>
        <div className={styles['systems-title']}>
          <FormattedMessage id="screens.organisation.systems-title" />
        </div>
        <PerfectScrollbar>
          <ListLayout.Content.Menu.Content>
            {this.props.systems.map(system => (
              <Menu.Item
                linkTo={{ pathname: `/organisation/users`, search: `system=${system.id}` }}
                title={system.name}
                selected={systemId === system.id}
              />
            ))}
          </ListLayout.Content.Menu.Content>
        </PerfectScrollbar>
      </>
    );
  };

  renderFormattedPrice = price => {
    return new Decimal(price)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  renderDiscounts = () => {
    return null;
    if (this.props.organisation.demo) return null;

    return (
      <div className={styles['billing-container']}>
        <Button
          gray
          fullWidth
          label="screens.organisation.see-billing-information"
          onClick={() => this.setState({ showBillingInformationModal: true })}
        />
      </div>
    );
  };

  renderLeftMenu = () => {
    const { system: systemId, type } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <PerfectScrollbar>
            <div className={styles['menu-items']}>
              <ListLayout.Content.Menu.Content>
                {this.renderCreateButton()}
                <Menu.Item
                  linkTo="/organisation/users"
                  title={<FormattedMessage id="screens.organisation.all-users" />}
                  selected={systemId == null && type !== 'archived'}
                />
                <Menu.Item
                  red
                  linkTo={{
                    pathname: '/organisation/users',
                    search: 'type=archived',
                  }}
                  title={<FormattedMessage id="screens.organisation.archived-users" />}
                  selected={type === 'archived'}
                />
              </ListLayout.Content.Menu.Content>
            </div>
            <div className={styles['systems-container']}>{this.renderSystems()}</div>
          </PerfectScrollbar>
          {this.renderDiscounts()}
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderCreateUserModal = () => {
    const { system: systemId } = queryString.parse(this.props.location.search);
    return (
      <CreateUserModal
        defaultSystemId={systemId}
        open={this.state.showCreateUserModal}
        userType="member"
        onCreated={() => {
          this.setState({ showCreateUserModal: false });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showCreateUserModal: false });
          setTimeout(() => {
            this.setState({ showCreateUserModal: true });
          }, 200);
        }}
        onClose={() => this.setState({ showCreateUserModal: false })}
      />
    );
  };

  renderBillingInformationModal = () => {
    return (
      <BillingInformationModal
        open={this.state.showBillingInformationModal}
        onClose={() => this.setState({ showBillingInformationModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderLeftMenu()}
        {this.renderMainContent()}
        {this.renderCreateUserModal()}
        {this.renderBillingInformationModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: OrganisationOperations.fetchUsers,
      addQueryParameter: OrganisationOperations.addQueryParameter,
      fetchAndSetAmountOfPayingUsers: OrganisationOperations.fetchAndSetAmountOfPayingUsers,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
    systems: AuthSelectors.getSystems(state),
    users: OrganisationSelectors.getUsers(state),
    pagination: OrganisationSelectors.getPagination(state),
    queryParameters: OrganisationSelectors.getQueryParameters(state),
    amountOfPayingMembers: OrganisationSelectors.getAmountOfPayingMembers(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
