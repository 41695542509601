/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getUploadingAttachments = (state, namespace) => {
  const { uploadProgress } = state;
  if (uploadProgress.namespaces[namespace] == null) return [];
  return uploadProgress.namespaces[namespace].uploadingAttachments;
};

const getUploadProgressForAttachment = (state, namespace, id) => {
  const { uploadProgress } = state;
  return uploadProgress.namespaces[namespace].uploadedProgress[id];
};

const getUploadingAttachmentsForFolder = (state, namespace, folderId) => {
  const { uploadProgress } = state;
  if (uploadProgress.namespaces[namespace] == null) return [];
  return uploadProgress.namespaces[namespace].uploadingAttachments.filter(
    attachment => attachment.attachment_folder_id == folderId
  );
};

export default {
  getUploadingAttachments,
  getUploadProgressForAttachment,
  getUploadingAttachmentsForFolder,
};
