import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { matchPath, withRouter } from 'react-router';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { MetersSelectors } from 'state/ducks/meters';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import ChangeUnitModal from './ChangeUnitModal';
import DeleteMeterModal from './DeleteMeterModal';
import EditTitleModal from './EditTitleModal';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showDropDown: false,
    showChangeUnitModal: false,
    isSavingTitle: false,
    showEditTitleModal: false,
    showDeleteMeterModal: false,
  };

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/meters',
      search: SDKHelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.meter.breadcrumb" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem to={`/meters/${this.props.match.params.id}`}>
          <LayoutHeader.BreadcrumbItem active>{this.props.meter.title}</LayoutHeader.BreadcrumbItem>
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderChangeUnitModal = () => {
    return (
      <ChangeUnitModal
        id={this.props.meter.id}
        open={this.state.showChangeUnitModal}
        onClose={() => this.setState({ showChangeUnitModal: false })}
      />
    );
  };

  renderDeleteMeterModal = () => {
    return (
      <DeleteMeterModal
        id={this.props.meter.id}
        open={this.state.showDeleteMeterModal}
        onClose={() => this.setState({ showDeleteMeterModal: false })}
        onDeleted={() => {
          this.props.history.push(this.getLinkForBreadCrumb());
        }}
      />
    );
  };

  renderEditDropdown = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={200}
          positionToRef={this.inlineModalPositioningRef}
          position="right"
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={<Icon regular type="pen" />}
                onClick={() =>
                  this.setState({
                    showChangeUnitModal: true,
                    showDropDown: false,
                  })
                }
              >
                <FormattedMessage id="screens.meter.change-meter-unit" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={<Icon regular type="trash-alt" red />}
                onClick={() =>
                  this.setState({
                    showDeleteMeterModal: true,
                    showDropDown: false,
                  })
                }
              >
                <p className={styles['destructive']}>
                  <FormattedMessage id="general.delete" />
                </p>
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderEditTitleModal = () => {
    return (
      <EditTitleModal
        open={this.state.showEditTitleModal}
        id={this.props.match.params.id}
        onClose={() => this.setState({ showEditTitleModal: false })}
      />
    );
  };

  renderHeaderAssetPath = () => {
    const { asset } = this.props;
    if (asset && asset.tree_path) {
      return (
        <div className={styles['header-path-container']}>
          <TreePath
            size={13}
            renderItem={asset => (
              <Link to={`/assets/${asset.id}`}>
                <span className={styles['path-item']}>{asset.title}</span>
              </Link>
            )}
            fullPath
            assetId={this.props.asset.id}
          />
        </div>
      );
    }
    return null;
  };

  renderTabs = () => (
    <LayoutHeader.TabBar>
      <LayoutHeader.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/meters/:id',
            exact: true,
          }) != null
        }
        to={`/meters/${this.props.meter.id}`}
      >
        <FormattedMessage id="screens.meter.tabs.overview.title" />
      </LayoutHeader.TabBarItem>
      <LayoutHeader.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/meters/:id/history',
            exact: true,
          }) != null
        }
        to={`/meters/${this.props.meter.id}/history`}
      >
        <FormattedMessage id="screens.meter.tabs.history.title" />
      </LayoutHeader.TabBarItem>
    </LayoutHeader.TabBar>
  );

  renderEditTitleButton = () => {
    if (this.props.canEditMeters) {
      return (
        <Button
          type="icon"
          icon={<Icon regular type="pen" />}
          onClick={() => {
            this.setState({ showEditTitleModal: true });
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <LayoutHeader
          title={this.props.meter.title}
          extraComponent={this.renderHeaderAssetPath()}
          breadcrumbComponent={this.renderBreadcrumb()}
          editTitleButton={this.renderEditTitleButton()}
          maxWidth={960}
          tabBarComponent={this.renderTabs()}
          buttonsComponent={this.props.canEditMeters ? this.renderEditDropdown() : null}
        />
        {this.renderChangeUnitModal()}
        {this.renderDeleteMeterModal()}
        {this.renderEditTitleModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const meterId = ownProps.match.params.id;
  const meter = EntitySelectors.getMeter(state, meterId);
  return {
    meter,
    asset: EntitySelectors.getAsset(state, meter.asset_id),
    canEditMeters: AuthSelectors.canEditMeters(state),
    navigatedTo: MetersSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
