import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HelperFunctions from 'utilities/HelperFunctions';
import { SparePartOperations, SparePartSelectors } from 'state/ducks/sparePart';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import NotFound from './NotFound';
import { Information, Assets, Attachments, Vendors, PurchaseOrders, Withdrawals } from './Tabs';

class SparePartDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      show: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.SpareParts);
    this.fetchSparePart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchSparePart();
    }
  }

  fetchSparePart = () => {
    this.props
      .fetchSparePart(this.props.match.params.id)
      .then(sparePart => {
        const { system_id } = sparePart;
        if (system_id !== this.props.currentSystem.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(`${sparePart.title} - ${sparePart.article_number}`);
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.sparePart) {
      return <Loader />;
    } else {
      return (
        <Switch key={this.props.match.params.id}>
          <Route path={`/spare-parts/:id`} exact component={Information} />
          {this.props.canViewAssets ? (
            <Route path={`/spare-parts/:id/assets`} exact component={Assets} />
          ) : null}
          {this.props.canViewVendors ? (
            <Route path={`/spare-parts/:id/vendors`} exact component={Vendors} />
          ) : null}
          <Route path={`/spare-parts/:id/withdrawals`} exact component={Withdrawals} />
          {this.props.canViewPurchaseOrders ? (
            <Route path={`/spare-parts/:id/purchase-orders`} exact component={PurchaseOrders} />
          ) : null}
          {this.props.isOperator === false ? (
            <Route path={`/spare-parts/:id/files`} exact component={Attachments} />
          ) : null}
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePart: SparePartOperations.fetchSparePart,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    sparePart: SparePartSelectors.getSparePart(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
    canViewAssets: AuthSelectors.canViewAssets(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartDetail));
