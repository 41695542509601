import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SettingsSelectors, SettingsOperations } from 'state/ducks/settings';
import DraggableField from './DraggableField';
import styles from './style.module.scss';

function Section(props) {
  const { id, fields, selectedTemplateField, unselectTemplateField, prepareNewField } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  let newButtonContainerClassNames = [styles['add-button-container']];
  let isPreparingNewField = false;
  if (selectedTemplateField) {
    if (selectedTemplateField.id == null && selectedTemplateField.column == id) {
      isPreparingNewField = true;
      newButtonContainerClassNames = [...newButtonContainerClassNames, styles['highlight']];
    } else {
      newButtonContainerClassNames = [...newButtonContainerClassNames, styles['hide']];
    }
  }

  return (
    <SortableContext id={id} items={fields}>
      <div ref={setNodeRef}>
        {fields.map((field, i) => (
          <DraggableField key={field.id} field={field} index={i} />
        ))}
        <div className={newButtonContainerClassNames.join(' ')}>
          {fields.length === 0 ? null : <div className={styles['add-button-separator']} />}
          <div
            className={styles['add-button']}
            onClick={() => {
              if (isPreparingNewField) {
                unselectTemplateField();
              } else {
                prepareNewField({ column: id });
              }
            }}
          >
            <FormattedMessage id="screens.settings.assets.template-fields.new-field" />
          </div>
        </div>
      </div>
    </SortableContext>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unselectTemplateField: SettingsOperations.unselectTemplateField,
      prepareNewField: SettingsOperations.prepareNewField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    fields: SettingsSelectors.getTemplateFields(state, id),
    selectedTemplateField: SettingsSelectors.getSelectedTemplateField(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
