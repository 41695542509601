import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { ReminderType } from 'sdk/WorkOrder';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Icon, FormattedNumber, WhiteCard, Button, ConfirmModal } from 'views/components/Shared/General';
import { SelectMeterModal } from 'views/components/Meter';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/WorkOrder';
import styles from '../style.module.scss';

class Meter extends Component {
  state = {
    showSelectMeterModal: false,
    showRemoveMeterConfirmModal: false,
  };

  isEditingSingleWorkOrderThatIsRecurring = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    return this.props.editingRecurringMaintenance && recurringMaintenanceId == null;
  };

  renderRemoveMeterModal = () => {
    return (
      <ConfirmModal
        open={this.state.showRemoveMeterConfirmModal}
        destructive
        title={<FormattedMessage id="screens.work-order.edit.remove-meter-confirm-modal.title" />}
        message={<FormattedMessage id="screens.work-order.edit.remove-meter-confirm-modal.message" />}
        confirmButtonText="general.remove"
        onConfirm={() => {
          let params = { meter_id: null };
          if (this.props.editingWorkOrder.reminder_type === ReminderType.RelativeToMeterReading) {
            params = {
              ...params,
              reminder_type: ReminderType.None,
              reminder_relative_to_meter: null,
            };
          }
          this.setState({ showRemoveMeterConfirmModal: false });
          this.props.setEditingWorkOrderValues(params);
        }}
        onCancel={() => {
          this.setState({ showRemoveMeterConfirmModal: false });
        }}
      />
    );
  };

  renderSelectMeterModal = () => {
    return (
      <SelectMeterModal
        assetId={this.props.editingWorkOrder.asset_id}
        open={this.state.showSelectMeterModal}
        onClose={() => {
          this.setState({ showSelectMeterModal: false });
        }}
        onSelect={id => {
          this.setState({
            showSelectMeterModal: false,
          });
          this.props.setEditingWorkOrderValues({ meter_id: id });
        }}
      />
    );
  };

  renderContent() {
    if (this.props.meter) {
      return (
        <div className={styles['meter-container']}>
          <WhiteCard noPadding>
            <div
              className={styles['meter']}
              onClick={() => {
                this.setState({ showSelectMeterModal: true });
              }}
            >
              <Field
                icon={<Icon type="tachometer-alt-slow" withBackground blue backgroundSize={36} />}
                label={<FormattedMessage id="resources.work-order.meter" />}
                value={this.props.meter.title}
                subtitle={
                  <FormattedNumber
                    value={this.props.meter.value}
                    unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                  />
                }
              />
              <div className={styles['clear-container']}>
                <Button
                  type="icon"
                  iconButtonSize={24}
                  icon={<Icon light size={16} type="times" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ showRemoveMeterConfirmModal: true });
                  }}
                />
              </div>
            </div>
          </WhiteCard>
        </div>
      );
    }
    return null;
  }

  render() {
    if (this.isEditingSingleWorkOrderThatIsRecurring()) {
      return null;
    }
    return (
      <>
        {this.renderContent()}
        {this.renderSelectMeterModal()}
        {this.renderRemoveMeterModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const editingWorkOrder = WorkOrderSelectors.getEditingWorkOrder(state);
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    editingWorkOrder,
    editingRecurringMaintenance: WorkOrderSelectors.getEditingWorkOrderRecurringMaintenance(state),
    meter,
    meterUnit,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meter));
