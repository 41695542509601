import types from './types';
import update from 'immutability-helper';
import { omit } from 'lodash-es';

const INITIAL_STATE = {
  namespaces: {},
};

/*
namespaces: {
  id: {
    uploadingAttachments: [],
    uploadedProgress: {},
  }
}
*/

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.ATTACHMENT_UPLOAD_STARTED: {
      const { temporaryId, attachment_folder_id, namespace } = action.payload;
      return update(state, {
        namespaces: {
          [namespace]: {
            $apply: namespace => {
              if (namespace) {
                return update(namespace, {
                  uploadingAttachments: {
                    $push: [{ id: temporaryId, attachment_folder_id }],
                  },
                  uploadedProgress: {
                    [temporaryId]: {
                      $set: { loaded: 0, total: 0 },
                    },
                  },
                });
              }
              return {
                uploadingAttachments: [{ id: temporaryId, attachment_folder_id }],
                uploadedProgress: {
                  [temporaryId]: { loaded: 0, total: 0 },
                },
              };
            },
          },
        },
      });
    }
    case types.ATTACHMENT_UPLOAD_CANCELLED: {
      const { temporaryId, namespace } = action.payload;

      return update(state, {
        namespaces: {
          [namespace]: {
            $apply: namespace => {
              if (namespace) {
                return update(namespace, {
                  uploadingAttachments: {
                    $set: namespace.uploadingAttachments.filter(({ id }) => id !== temporaryId),
                  },
                  uploadedProgress: {
                    $unset: [temporaryId],
                  },
                });
              }
              return {
                uploadingAttachments: [],
                uploadedProgress: {},
              };
            },
          },
        },
      });
    }
    case types.ATTACHMENT_UPLOAD_UPDATED: {
      const { loaded, total, temporaryId, namespace } = action.payload;
      return update(state, {
        namespaces: {
          [namespace]: {
            $apply: namespace => {
              if (namespace) {
                return update(namespace, {
                  uploadedProgress: {
                    [temporaryId]: {
                      $set: { loaded, total },
                    },
                  },
                });
              }
              return {
                uploadingAttachments: [],
                uploadedProgress: {},
              };
            },
          },
        },
      });
    }
    case types.ATTACHMENT_UPLOAD_COMPLETED: {
      const { temporaryId, namespace } = action.payload;
      return update(state, {
        namespaces: {
          [namespace]: {
            $apply: namespace => {
              if (namespace) {
                return update(namespace, {
                  uploadingAttachments: {
                    $set: namespace.uploadingAttachments.filter(attachment => attachment.id !== temporaryId),
                  },
                  uploadedProgress: {
                    $set: omit(namespace.uploadedProgress, temporaryId),
                  },
                });
              }
              return {
                uploadingAttachments: [],
                uploadedProgress: {},
              };
            },
          },
        },
      });
    }
    default:
      return state;
  }
};
