import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, HelperFunctions, SDKReduxOperations } from 'sdk';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { UserType } from 'sdk/SystemAccess';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeUser } from 'sdk/Schemas';
import { Button, Field, NewInlineModal, FieldErrorWrapper } from 'views/components/Shared/General';
import { SideModal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AssetTitle, ChooseAssetInlineModal } from 'views/components/Asset';
import SelectedAssetContainer from './SelectedAssetContainer';
import styles from './style.module.scss';

class CreateUserModal extends Component {
  getInitialState = () => ({
    isCreatingUserType: this.props.userType,
    sendAsMail: true,
    requirePin: false,
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    pin: '',
    showDropDown: false,
    admin: true,
    selectedAssetIds: [],
    preservedUserType: null,
    preservedSelectedAssetIds: [],
    currentPermissionProfile: null,
    permissionProfiles: [],
    createLoginCredential: false,
    closeSideModalOnClickOverlay: true,
    closeSideModalOnClickEscape: true,
    showEmptyNameError: false,
    showEmptyEmailError: false,
    showInvalidPinError: false,
    showEmptyPasswordError: false,
    showPasswordsNotMatchingError: false,
    showErrorForAlreadyUsedEmail: false,
    showInvalidEmailError: false,
    isCreatingUser: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { sso_oidc_activated } = this.props.organisationSettings;
      const { userType } = this.props;
      let createLoginCredential = true;
      if (sso_oidc_activated || userType === 'operator') {
        createLoginCredential = false;
      }
      let selectedAssetIds = [];
      if (this.state.preservedSelectedAssetIds.length > 0 && this.state.preservedUserType === 'operator') {
        selectedAssetIds = this.state.preservedSelectedAssetIds;
      }

      let isCreatingUserType = this.props.userType;
      if (this.state.preservedUserType) {
        isCreatingUserType = this.state.preservedUserType;
      }

      this.setState({
        ...this.getInitialState(),
        createLoginCredential,
        isCreatingUserType,
        selectedAssetIds,
        preservedSelectedAssetIds: [],
        preservedUserType: null,
      });
      API.listPermissionProfiles(this.props.currentSystem.id).then(res => {
        const permissionProfiles = res.data;
        this.setState({ permissionProfiles });
      });
    }
  }

  buildParams = () => {
    let system_access = null;
    switch (this.state.isCreatingUserType) {
      case 'operator':
        system_access = {
          user_type: UserType.Operator,
        };
        break;
      case 'productionSupervisor':
        system_access = {
          user_type: UserType.ProductionSupervisor,
        };
        break;
      case 'viewOnly':
        system_access = {
          user_type: UserType.ViewOnly,
        };
        break;
      case 'workRequester':
        system_access = {
          user_type: UserType.WorkRequester,
        };
        break;
      default:
        /*
          If creating member user
        */
        if (this.state.admin) {
          system_access = {
            user_type: UserType.SystemAdmin,
          };
        } else {
          system_access = {
            user_type: UserType.PermissionProfile,
            permission_profile_id: this.state.currentPermissionProfile.id,
          };
        }
        break;
    }
    let params = {
      name: this.state.name,
      email: this.state.email,
      language: this.props.language,
      system_access,
    };
    if (this.state.isCreatingUserType === 'operator') {
      params = {
        ...params,
        pin_code: this.state.pin,
        asset_operators: this.state.selectedAssetIds.map(id => ({
          asset_id: id,
        })),
      };
    }
    if (this.state.createLoginCredential === true) {
      if (!this.state.sendAsMail) {
        params = {
          ...params,
          login_credential: {
            username: this.state.email,
            password: this.state.password,
          },
        };
      } else {
        params = {
          ...params,
          invited: true,
        };
      }
    }
    return params;
  };

  createUser = createNew => {
    if (this.state.isCreatingUserType === 'operator') {
      if (!this.isSavableAsOperator()) return;
    } else if (this.state.isCreatingUserType === 'productionSupervisor') {
      if (!this.isSavableAsUserWithEmail()) return;
    } else if (this.state.isCreatingUserType === 'viewOnly') {
      if (!this.isSavableAsUserWithEmail()) return;
    } else {
      if (!this.isSavableAsUserWithEmail()) return;
    }
    this.setState({ isCreatingUser: createNew === false, isCreatingUserAndCreatingNew: createNew === true });
    this.props
      .createUser(this.props.currentSystem.id, this.buildParams())
      .then(({ data: user }) => {
        const { entities } = normalizeUser(user);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.create-user-modal.create-user-success" />}
          />
        );
        if (createNew) {
          this.setState({
            isCreatingUserAndCreatingNew: false,
            preservedUserType: this.state.isCreatingUserType,
            preservedSelectedAssetIds:
              this.state.isCreatingUserType === 'operator' ? this.state.selectedAssetIds : [],
          });
          this.props.onCreatedWithReopen();
        } else {
          this.setState({ isCreatingUser: false });
          this.props.onCreated();
        }
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'email' })) {
          this.setState({ showErrorForAlreadyUsedEmail: true });
        } else if (HelperFunctions.hasError(e, { code: '10018', key: 'email' })) {
          this.setState({ showInvalidEmailError: true });
        }
        this.setState({ isCreatingUser: false, isCreatingUserAndCreatingNew: false });
      });
  };

  isSavableAsOperator = () => {
    const { createLoginCredential, requirePin, pin } = this.state;
    let saveable = true;
    if (this.state.name.length === 0) {
      this.setState({ showEmptyNameError: true });
      saveable = false;
    }
    if (createLoginCredential === true) {
      if (this.state.email.length === 0) {
        this.setState({ showEmptyEmailError: true });
        saveable = false;
      }
      if (this.state.sendAsMail === false) {
        if (this.state.password.length === 0) {
          this.setState({ showEmptyPasswordError: true });
          saveable = false;
        }
        if (this.state.password !== this.state.confirmPassword) {
          this.setState({ showPasswordsNotMatchingError: true });
          saveable = false;
        }
      }
    }
    if (requirePin && (pin.length !== 4 || isNaN(pin))) {
      this.setState({ showInvalidPinError: true });
      saveable = false;
    }
    return saveable;
  };

  isSavableAsUserWithEmail = () => {
    let saveable = true;
    if (this.state.name.length === 0) {
      this.setState({ showEmptyNameError: true });
      saveable = false;
    }
    if (this.state.email.length === 0) {
      this.setState({ showEmptyEmailError: true });
      saveable = false;
    }
    if (!this.state.sendAsMail) {
      if (this.state.password.length === 0) {
        this.setState({ showEmptyPasswordError: true });
        saveable = false;
      }
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ showPasswordsNotMatchingError: true });
        saveable = false;
      }
    }
    return saveable;
  };

  selectPermissionProfile = permissionProfile => {
    this.setState({
      admin: false,
      operator: false,
      currentPermissionProfile: permissionProfile,
    });
  };

  renderPasswordFields = () => {
    return (
      <AnimateHeight
        duration={150}
        height={this.state.sendAsMail ? 0 : 'auto'}
        onAnimationEnd={() => this.passwordRef.focus()}
      >
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
              <Field.Text
                type="password"
                autocomplete="new-password"
                ref={ref => (this.passwordRef = ref)}
                error={this.state.showEmptyPasswordError}
                value={this.state.password}
                onChange={password => this.setState({ password, showEmptyPasswordError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="screens.users.create-user-modal.re-enter-password" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showPasswordsNotMatchingError}
                errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
              >
                <Field.Text
                  type="password"
                  autocomplete="new-password"
                  error={this.state.showEmptyPasswordError || this.state.showPasswordsNotMatchingError}
                  value={this.state.confirmPassword}
                  onChange={confirmPassword =>
                    this.setState({ confirmPassword, showPasswordsNotMatchingError: false })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </AnimateHeight>
    );
  };

  renderSelectPermission = () => {
    const { admin, operator, currentPermissionProfile } = this.state;
    if (admin) return <FormattedMessage id="screens.users.user-modal.actions.permission.admin" />;
    if (operator) return <FormattedMessage id="screens.users.user-modal.actions.permission.requestor" />;
    return currentPermissionProfile ? currentPermissionProfile.title : null;
  };

  renderUserPermission = () => {
    return (
      <>
        <Field edit label={<FormattedMessage id="screens.users.user-modal.actions.permission.permission" />}>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() =>
              this.setState(prevState => ({
                showDropDown: !prevState.showDropDown,
              }))
            }
          >
            <Field.Resource angleDown value={this.renderSelectPermission()} clearable={false} />
          </div>
        </Field>

        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({
                    currentPermissionProfile: null,
                    admin: true,
                    showDropDown: false,
                    operator: false,
                  });
                }}
                selected={this.state.admin}
              >
                <FormattedMessage id="screens.users.user-modal.actions.permission.admin" />
              </NewInlineModal.Dropdown.Item>
              {this.state.permissionProfiles.length === 0 ? null : (
                <React.Fragment>
                  <NewInlineModal.Dropdown.Separator />
                  <p className={styles['custom-permissions']}>
                    <FormattedMessage id="screens.users.user-modal.actions.permission.permission-profiles" />
                  </p>
                  {this.state.permissionProfiles.map(permissionProfile => {
                    return (
                      <NewInlineModal.Dropdown.Item
                        onClick={() => {
                          this.selectPermissionProfile(permissionProfile);
                          this.setState({ showDropDown: false });
                        }}
                        selected={
                          this.state.currentPermissionProfile &&
                          this.state.currentPermissionProfile.id === permissionProfile.id
                        }
                      >
                        <span>{permissionProfile ? permissionProfile.title : null}</span>
                      </NewInlineModal.Dropdown.Item>
                    );
                  })}
                </React.Fragment>
              )}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderSelectedLicense = () => {
    switch (this.state.isCreatingUserType) {
      case 'member': {
        return <FormattedMessage id="resources.user.member" />;
      }
      case 'productionSupervisor': {
        return <FormattedMessage id="resources.user.production-supervisor" />;
      }
      case 'operator': {
        return <FormattedMessage id="resources.user.operator" />;
      }
      case 'workRequester': {
        return <FormattedMessage id="resources.user.work-requester" />;
      }
      case 'viewOnly': {
        return <FormattedMessage id="resources.user.view-only" />;
      }
      default:
        return null;
    }
  };

  renderContentType = () => {
    const { sso_oidc_activated } = this.props.organisationSettings;
    return (
      <Field label={<FormattedMessage id="screens.users.create-user-modal.create-user-type" />}>
        <Field.Dropdown clearable={false} value={this.renderSelectedLicense()}>
          <Field.Dropdown.Item
            selected={this.state.isCreatingUserType === 'member'}
            onClick={() =>
              this.setState({
                isCreatingUserType: 'member',
                createLoginCredential: sso_oidc_activated === false,
                selectedAssetIds: [],
              })
            }
          >
            <FormattedMessage id="resources.user.member" />
          </Field.Dropdown.Item>
          {this.props.settings.production_board_activated ? (
            <Field.Dropdown.Item
              selected={this.state.isCreatingUserType === 'productionSupervisor'}
              onClick={() =>
                this.setState({
                  isCreatingUserType: 'productionSupervisor',
                  createLoginCredential: sso_oidc_activated === false,
                  selectedAssetIds: [],
                })
              }
            >
              <FormattedMessage id="resources.user.production-supervisor" />
            </Field.Dropdown.Item>
          ) : null}
          {this.props.settings.production_board_activated ? (
            <Field.Dropdown.Item
              selected={this.state.isCreatingUserType === 'operator'}
              onClick={() =>
                this.setState({
                  isCreatingUserType: 'operator',
                  createLoginCredential: false,
                })
              }
            >
              <FormattedMessage id="resources.user.operator" />
            </Field.Dropdown.Item>
          ) : null}
          <Field.Dropdown.Item
            selected={this.state.isCreatingUserType === 'workRequester'}
            onClick={() =>
              this.setState({
                isCreatingUserType: 'workRequester',
                createLoginCredential: sso_oidc_activated === false,
                selectedAssetIds: [],
              })
            }
          >
            <FormattedMessage id="resources.user.work-requester" />
          </Field.Dropdown.Item>
          <Field.Dropdown.Item
            selected={this.state.isCreatingUserType === 'viewOnly'}
            onClick={() =>
              this.setState({
                isCreatingUserType: 'viewOnly',
                createLoginCredential: sso_oidc_activated === false,
                selectedAssetIds: [],
              })
            }
          >
            <FormattedMessage id="resources.user.view-only" />
          </Field.Dropdown.Item>
        </Field.Dropdown>
      </Field>
    );
  };

  renderOperatorEmailLabel = () => {
    if (this.state.isCreatingUserType === 'operator') {
      return (
        <>
          <FormattedMessage id="resources.user.email" />
          <span className={styles['optional-email']}>
            {' '}
            - <FormattedMessage id="general.optional" />
          </span>
        </>
      );
    }
    return <FormattedMessage id="resources.user.email" />;
  };

  renderEmailErrorLabel = () => {
    if (this.state.showErrorForAlreadyUsedEmail) {
      return <FormattedMessage id="screens.users.create-user-modal.errors.email-in-use" />;
    }
    if (this.state.showInvalidEmailError) {
      return <FormattedMessage id="screens.users.create-user-modal.errors.invalid-email" />;
    }
    return null;
  };

  renderUserType = () => {
    if (this.props.isProductionSupervisor) {
      return null;
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>{this.renderContentType()}</Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderContent = () => {
    return (
      <Grid>
        {this.renderUserType()}
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.user.name" />}>
              <Field.Text
                autoFocus
                error={this.state.showEmptyNameError}
                value={this.state.name}
                onChange={name => this.setState({ name, showEmptyNameError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={this.renderOperatorEmailLabel()}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showErrorForAlreadyUsedEmail || this.state.showInvalidEmailError}
                errorElement={this.renderEmailErrorLabel()}
              >
                <Field.Text
                  error={
                    this.state.showEmptyEmailError ||
                    this.state.showErrorForAlreadyUsedEmail ||
                    this.state.showInvalidEmailError
                  }
                  value={this.state.email}
                  onChange={email =>
                    this.setState({
                      email,
                      showEmptyEmailError: false,
                      showInvalidEmailError: false,
                      showErrorForAlreadyUsedEmail: false,
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderSelectAssetsFieldForOperator()}
        {this.renderPermission()}
        {this.renderUserTypeContent()}
      </Grid>
    );
  };

  renderPin = () => {
    return (
      <AnimateHeight
        duration={150}
        height={this.state.requirePin ? 'auto' : 0}
        onAnimationEnd={() => this.pinRef.focus()}
      >
        <div className={styles['pin-code-field']}>
          <FieldErrorWrapper
            position="top"
            show={this.state.showInvalidPinError}
            errorElement={<FormattedMessage id="screens.users.action-modal.invalid-pin" />}
          >
            <Field.Text
              ref={ref => (this.pinRef = ref)}
              placeholder="1234"
              error={this.state.showInvalidPinError}
              value={this.state.pin}
              onChange={pin => {
                this.setState({ pin, showInvalidPinError: false });
              }}
            />
          </FieldErrorWrapper>
        </div>
      </AnimateHeight>
    );
  };

  renderOperatorGroupLoginAccess = () => {
    if (this.state.isCreatingUserType === 'operator') {
      return (
        <Grid.Row>
          <Grid.Column>
            <p className={styles['description']}>
              <FormattedMessage id="screens.users.create-user-modal.operator-group-login" />
            </p>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderPinField = () => {
    if (this.state.isCreatingUserType === 'operator') {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.requirePin}
              onChange={value => this.setState({ requirePin: value })}
              label={<FormattedMessage id="screens.users.create-user-modal.require-pin" />}
              questionTooltipContent={<FormattedMessage id="screens.users.create-user-modal.tooltips.pin" />}
            />
            {this.renderPin()}
          </Grid.Column>
        </Grid.Row>
      );
    }
  };

  renderSelectedAssets = () => {
    if (this.state.selectedAssetIds.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.state.selectedAssetIds.reduce(
          (accu, id) =>
            accu === null
              ? [
                  <div className={styles['selected-item']}>
                    <AssetTitle id={id} noSubtitleStyle />
                  </div>,
                ]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>
                    <AssetTitle id={id} noSubtitleStyle />
                  </div>,
                ],
          null
        )}
      </div>
    );
  };

  renderSelectAssetsFieldForOperator = () => {
    if (this.state.isCreatingUserType === 'operator') {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field
              edit
              label={<FormattedMessage id="screens.users.create-user-modal.operator-asset" />}
              questionTooltipContent={
                <FormattedMessage id="screens.users.create-user-modal.operator-asset-tooltip" />
              }
            >
              <ChooseAssetInlineModal
                hideCreateButton
                clearable={false}
                multiple
                trigger={
                  <Field.Resource
                    angleDown
                    value={this.renderSelectedAssets()}
                    onClear={() => this.setState({ selectedAssetIds: [] })}
                  />
                }
                selectedAssetIds={this.state.selectedAssetIds}
                onAddAsset={assetId =>
                  this.setState({ selectedAssetIds: [...this.state.selectedAssetIds, assetId] })
                }
                onRemoveAsset={assetId =>
                  this.setState({
                    selectedAssetIds: this.state.selectedAssetIds.filter(id => id !== assetId),
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return null;
  };

  renderLoginCredentials = () => {
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Field.Radio.Group>
              <Field.Radio
                checked={this.state.sendAsMail}
                onChange={() => this.setState({ sendAsMail: true, password: '', confirmPassword: '' })}
                label={<FormattedMessage id="screens.users.create-user-modal.send-as-email" />}
                questionTooltipContent={
                  <FormattedMessage id="screens.users.create-user-modal.tooltips.login-credential" />
                }
              />
              <Field.Radio
                checked={!this.state.sendAsMail}
                onChange={() => this.setState({ sendAsMail: false })}
                label={<FormattedMessage id="screens.users.create-user-modal.enter-password" />}
              />
            </Field.Radio.Group>
          </Grid.Column>
        </Grid.Row>
        {this.renderPasswordFields()}
      </>
    );
  };

  renderPermission = () => {
    if (this.state.isCreatingUserType === 'member') {
      return (
        <Grid.Row>
          <Grid.Column>{this.renderUserPermission()}</Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderCreateLoginCredentialsCheckbox = () => {
    if (this.state.isCreatingUserType !== 'operator') {
      return null;
    }
    return (
      <>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.createLoginCredential}
              onChange={value => this.setState({ createLoginCredential: value })}
              label={<FormattedMessage id="screens.users.create-user-modal.create-login-credential" />}
            />
          </Grid.Column>
        </Grid.Row>
        <AnimateHeight duration={250} height={this.state.createLoginCredential ? 'auto' : 0}>
          <div className={styles['invite-container']}>{this.renderLoginCredentials()}</div>
        </AnimateHeight>
      </>
    );
  };

  renderUserTypeContent = () => {
    return (
      <>
        {this.renderOperatorGroupLoginAccess()}
        {this.renderPinField()}
        {this.renderCreateLoginCredentialsCheckbox()}
        {this.state.isCreatingUserType !== 'operator' ? (
          <>
            <Grid.Separator />
            {this.renderLoginCredentials()}
          </>
        ) : null}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal
          closeOnEscape={this.state.closeSideModalOnClickEscape}
          closeOnClickOverlay={this.state.closeSideModalOnClickOverlay}
          open={this.props.open}
          onClose={() => {
            this.props.onClose();
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createUser(false)}
                loading={this.state.isCreatingUser}
              />
              <Button
                label="general.save-and-create-new"
                loading={this.state.isCreatingUserAndCreatingNew}
                onClick={() => this.createUser(true)}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          }
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header
                title={<FormattedMessage id="screens.users.create-user-modal.title" />}
                subtitle={<FormattedMessage id="screens.users.create-user-modal.subtitle" />}
                onClose={this.props.onClose}
              />
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createUser: SDKReduxOperations.createUser,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    language: AuthSelectors.getLanguage(state),
    settings: AuthSelectors.getSettings(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateUserModal));

CreateUserModal.propTypes = {
  userType: PropTypes.oneOf(['member', 'productionSupervisor', 'operator']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  closeOnClickOverlay: PropTypes.bool,
};

CreateUserModal.defaultProps = {
  userType: 'member',
  open: false,
  closeOnClickOverlay: true,
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
};
