import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const createForTemplateAsset = (templateAssetId, data) =>
  request.post(
    `operational_maintenance_template_assets/${templateAssetId}/operational_maintenance_template_recurrences`,
    data
  );
export const deleteTemplateRecurrence = recurrenceId =>
  request.delete(`operational_maintenance_template_recurrences/${recurrenceId}`);
