import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import {
  Exists,
  NotExists,
} from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Number/comparators';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Request extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } =
      props.filter.requests?.request_id || props.initialFilter.requests?.request_id;
    this.state = {
      comparator,
      hasValue: value,
      initialFilter: {
        request_id: {
          ...(props.filter.requests?.request_id || props.initialFilter.requests?.request_id),
        },
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        this.props.addFilter({
          key: FILTER_TYPES.Request,
          data: {
            work_order: {
              ...this.props.filter,
              requests: {
                ...this.props.filter.requests,
                request_id: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          },
        });
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
  };

  /*
    Render functions
  */

  renderExistsComparator = () => {
    return (
      <Exists
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === true
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: true });
        }}
      />
    );
  };

  renderNotExistsComparator = () => {
    return (
      <NotExists
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === false
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: false });
        }}
      />
    );
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={this.props.showAppliedFilters}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter.request, this.state.initialFilter.request)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Request,
                data: {
                  work_order: {
                    ...this.props.filter,
                    requests: {
                      ...this.props.filter.requests,
                      ...this.props.initialFilter.requests,
                    },
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Request,
                data: {
                  work_order: {
                    ...this.props.filter,
                    requests: {
                      ...this.props.filter.requests,
                      request: {
                        ...this.state.initialFilter.request,
                      },
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.request.resource" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          {this.renderExistsComparator()}
          {this.renderNotExistsComparator()}
          {this.renderApplyFiltersButton()}
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Request);

Request.propTypes = {
  filter: PropTypes.object,
};

Request.defaultProps = {
  filter: {
    requests: {
      request_id: {
        comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
        value: null,
      },
    },
  },
  initialFilter: {
    requests: {
      request_id: {
        comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
        value: null,
      },
    },
  },
};
