import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { normalizeWorkOrder, normalizeChecklistInstance } from 'sdk/Schemas';
import { API, HelperFunctions, SDKReduxOperations } from 'sdk';
import { ChecklistModalOperations } from 'state/ducks/checklistModal';
import { FullScreenOverlayModal } from 'views/components/Shared/Layout';
import { buildReduxChecklistRowDataWithInstanceRows } from 'sdk/ChecklistTemplate';
import { List, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import Header from './Header';
import { Values, Deviations, WorkOrder } from './MainContent';
import LeftPanel from './LeftPanel';
import styles from './style.module.scss';

class ChecklistModal extends Component {
  getInitialState = () => ({
    notFound: false,
  });

  state = this.getInitialState();

  componentDidMount() {
    if (this.props.open) {
      this.initiateChecklistModal();
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      this.initiateChecklistModal();
    } else if (prevProps.open && !this.props.open) {
      this.props.resetState();
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  initiateChecklistModal = () => {
    this.props.setEditable(this.props.editable);
    if (this.props.openedFromDeviations) {
      this.props.selectMenuItem('deviations');
    }
    if (this.props.checklistInstanceId) {
      this.props
        .fetchChecklistInstance(this.props.checklistInstanceId)
        .then(checklistInstance => {
          const { system_id } = checklistInstance;
          if (system_id !== this.props.currentSystem.id) {
            this.setState({ notFound: true });
          }
        })
        .catch(e => {
          this.setState({ notFound: e.response.status === 404 });
        });
    } else if (this.props.checklistTemplateLinkId) {
      if (this.props.isViewOnly) {
        this.fetchChecklistTemplateForTemplateLink();
      } else {
        this.createChecklistInstanceForTemplateLink();
      }
    }
  };

  fetchChecklistTemplateForTemplateLink = () => {
    this.props.fetchChecklistTemplateLink(this.props.checklistTemplateLinkId).catch(e => {
      this.setState({ notFound: e.response.status === 404 });
    });
  };

  createChecklistInstanceForTemplateLink = () => {
    const { work_order_id, id, checklist_template_id } = this.props.checklistTemplateLink;
    this.props
      .createChecklistInstanceForTemplate(
        checklist_template_id,
        {
          work_order_id,
        },
        { checklist_template_link_id: id }
      )
      .then(({ data: checklistInstance }) => {
        const { entities } = normalizeChecklistInstance(checklistInstance);
        this.props.updateEntities(entities);
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows: checklistInstance.checklist_template_version.checklist_template_rows,
            checklist_instance_rows: checklistInstance.checklist_instance_rows,
            checklist_instance_options: checklistInstance.checklist_instance_options,
          });
        this.props.setChecklistInstance({
          instanceId: checklistInstance.id,
          childIds: childRowIds,
          templateFieldIds: rootRowIds,
          instanceRowForTemplateRowIds,
          instanceOptionIdsForTemplateRowIds,
        });
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '140012', key: 'work_order_id' })) {
          API.fetchWorkOrder(work_order_id).then(({ data: workOrder }) => {
            const { entities, result } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            const { checklist_instances } = entities.workOrderById[result];
            this.props.fetchChecklistInstance(checklist_instances[0]);
            this.setState({ isCreatingChecklistInstance: false });
          });
        } else {
          this.props.onClose();
        }
      });
  };

  close = () => {
    if (this.props.checklistInstance) {
      this.props.onClose(this.props.checklistInstance.id);
    } else {
      this.props.onClose();
    }
  };

  renderLeftPanel = () => {
    if (this.state.notFound || this.props.isFetching) {
      return <LeftPanel loading />;
    }
    return <LeftPanel openedFromDeviations={this.props.openedFromDeviations} onClose={this.close} />;
  };

  renderMainContent = () => {
    let classNames = [styles['content']];
    if (this.state.notFound) {
      return (
        <div className={styles['content-container']}>
          <div className={classNames.join(' ')}>
            <WhiteCard centerContent padding="60px">
              <EmptyDataSet
                title={<FormattedMessage id="components.checklist-instance-modal.not-found.title" />}
                subtitle={<FormattedMessage id="components.checklist-instance-modal.not-found.subtitle" />}
              />
            </WhiteCard>
          </div>
        </div>
      );
    }
    if (this.props.isFetching) {
      return (
        <div className={styles['content-container']}>
          <div className={classNames.join(' ')}>
            <List>
              <List.Item>
                <List.Item.TitleColumn loading />
              </List.Item>
              <List.Item>
                <List.Item.TitleColumn loading />
              </List.Item>
            </List>
          </div>
        </div>
      );
    }
    if (this.props.selectedMenuItem === 'deviations') {
      return <Deviations />;
    }
    if (this.props.selectedMenuItem === 'workOrder') {
      return <WorkOrder />;
    }
    return (
      <div className={styles['content-container']}>
        <div className={classNames.join(' ')}>
          <Values
            openedFromDeviations={this.props.openedFromDeviations}
            onUpdateVersion={this.props.onUpdateVersion}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <FullScreenOverlayModal open={this.props.open}>
          <div className={styles['container']}>
            <Header
              loading={this.props.isFetching || this.state.notFound}
              onClose={this.close}
              openedFromDeviations={this.props.openedFromDeviations}
            />
            <div className={styles['content-wrapper']}>
              {this.renderLeftPanel()}
              {this.renderMainContent()}
            </div>
          </div>
        </FullScreenOverlayModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      resetState: ChecklistModalOperations.resetState,
      fetchChecklistInstance: ChecklistModalOperations.fetchChecklistInstance,
      setEditable: ChecklistModalOperations.setEditable,
      fetchChecklistTemplateLink: ChecklistModalOperations.fetchChecklistTemplateLink,
      selectMenuItem: ChecklistModalOperations.selectMenuItem,
      setChecklistInstance: ChecklistModalOperations.setChecklistInstance,
      createChecklistInstanceForTemplate: SDKReduxOperations.createChecklistInstanceForTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistInstance: ChecklistModalSelectors.getChecklistInstance(state),
    checklistTemplateLink: EntitySelectors.getChecklistTemplateLink(state, ownProps.checklistTemplateLinkId),
    selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
    isFetching: ChecklistModalSelectors.isFetching(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistModal);

ChecklistModal.propTypes = {
  open: PropTypes.bool,
  editable: PropTypes.bool,
  onClose: PropTypes.func,
  checklistInstanceId: PropTypes.string,
  checklistTemplateLinkId: PropTypes.string,
  showWorkOrderInformation: PropTypes.bool,
  openedFromDeviations: PropTypes.bool,
};

ChecklistModal.defaultProps = {
  open: false,
  editable: true,
  onClose: () => {},
  checklistInstanceId: null,
  checklistTemplateLinkId: null,
  showWorkOrderInformation: false,
  openedFromDeviations: false,
};
