import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import Dropdown from './Dropdown';

export default class DebitToSystemModal extends Component {
  getInitialState = () => ({
    enterprise_billing_system_id: null,
    showRequiredError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.organisation.user-permission-modal.debit-to-system-field" />}
            subtitle={
              <FormattedMessage id="screens.organisation.user-permission-modal.debit-to-system-subtitle" />
            }
            onClose={() => this.props.onClose()}
          />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Dropdown
                    showRequiredError={this.state.showRequiredError}
                    systemIds={this.props.systemIds}
                    selectedSystemId={this.state.enterprise_billing_system_id}
                    onChange={enterprise_billing_system_id => {
                      this.setState({ showRequiredError: false, enterprise_billing_system_id });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  if (this.state.enterprise_billing_system_id == null) {
                    this.setState({ showRequiredError: true });
                  } else {
                    this.props.onSave(this.state.enterprise_billing_system_id);
                  }
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
