import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';
import types from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_ID: {
      const { oldId, newId } = action.payload;
      return update(state, {
        [oldId]: {
          $set: state[newId],
        },
      });
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.operationalMaintenanceById) {
        return HelperFunctions.mergeEntities(state, action.payload.operationalMaintenanceById);
      }
      return state;
    default:
      return state;
  }
};
