import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { PriorityText } from 'views/components/WorkOrder';
import { Datapill } from 'views/components/Shared/General';
import { StatusColumn } from 'views/components/Request';
import { TreePath } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class ListItem extends PureComponent {
  renderAssetNumberDataPill = () => {
    if (this.props.settings.asset_number_activated && this.props.asset) {
      return (
        <Datapill
          label={<FormattedMessage id="resources.asset.number-long" />}
          value={`#${this.props.asset.number}`}
        />
      );
    }
    return null;
  };

  renderPriorityDataPill = () => {
    if (this.props.request.priority) {
      return <Datapill value={<PriorityText priority={this.props.request.priority} />} />;
    }
    return null;
  };

  renderRequestTypeDataPill = () => {
    if (this.props.requestType) {
      return <Datapill value={this.props.requestType.title} />;
    }
    return null;
  };

  renderCreatedByDataPill = () => {
    if (this.props.createdByUser) {
      return <Datapill value={this.props.createdByUser.name} />;
    }
    return null;
  };

  renderCreatedAtDataPill = () => {
    return (
      <Datapill
        value={
          <span>
            <span>{moment(this.props.request.created_at).format('ll')}</span>
            <span>, </span>
            <span>{moment(this.props.request.created_at).format('LT')}</span>
          </span>
        }
      />
    );
  };

  renderSubtitle = () => {
    if (this.props.asset) {
      return (
        <div className={styles['subtitle']}>
          <TreePath assetId={this.props.asset.id} fullPath size={12} />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['cell']} onClick={this.props.onClick}>
        <div className={styles['content']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>{this.props.request.title}</div>
            <div className={styles['number']}>
              <span>&nbsp;-&nbsp;#</span>
              <span>{this.props.request.number}</span>
            </div>
          </div>
          {this.renderSubtitle()}
          <div className={styles['data-pills']}>
            <Datapill.List>
              {this.renderCreatedAtDataPill()}
              {this.renderCreatedByDataPill()}
              {this.renderPriorityDataPill()}
              {this.renderRequestTypeDataPill()}
              {this.renderAssetNumberDataPill()}
            </Datapill.List>
          </div>
        </div>
        <div>
          <StatusColumn requestId={this.props.request.id} />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state, ownProps) {
  const request = EntitySelectors.getRequest(state, ownProps.id);
  return {
    request,
    asset: EntitySelectors.getAsset(state, request.asset_id),
    requestType: EntitySelectors.getRequestType(state, request.request_type_id),
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListItem));
