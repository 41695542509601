import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { bindActionCreators } from 'redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { EntitySelectors } from 'sdk/State/entities';
import { ToolbarListType } from 'views/scenes/Planning/Calendar/Calendar/utils';
import { CalendarOperations } from 'state/ducks/calendar';
import { TreePath } from 'views/components/Asset';
import { Type } from 'views/scenes/Planning/Calendar/Calendar/utils';
import styles from './style.module.scss';

const cardSource = {
  beginDrag(props) {
    const { id, registration_images, asset_id, priority, description } = props.request;
    props.beginDragFromToolbox({
      requestId: id,
    });
    return {
      fromToolboxList: ToolbarListType.Requests,
      requestId: id,
      requestData: {
        id,
        registration_images,
        asset_id,
        priority,
        description,
      },
    };
  },
  endDrag(props, monitor, component) {
    props.endDragFromToolbox();
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

class RequestListItem extends PureComponent {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  renderTitle = () => (
    <div className={styles['title-container']}>
      <div className={styles['title']}>{this.props.request.title}</div>
      <div className={styles['number']}>#{this.props.request.number}</div>
    </div>
  );

  renderSubtitle = () => {
    return (
      <div className={styles['subtitle']}>{this.props.asset == null ? '-' : this.props.asset.title}</div>
    );
  };

  render() {
    return this.props.connectDragSource(
      <div
        onClick={e => {
          e.stopPropagation();
          const { top, left, width } = this.workOrderListItemRef.getBoundingClientRect();
          const requestId = this.props.request.id;
          this.props.showPreviewRequestOverlay({ top, left: left - 6, width, requestId });
        }}
        className={`overlay-trigger ${styles['work-order-list-item']}`}
        ref={ref => (this.workOrderListItemRef = ref)}
      >
        <div className={styles['title-container']}>{this.renderTitle()}</div>
        {this.renderSubtitle()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showPreviewRequestOverlay: CalendarOperations.showPreviewRequestOverlay,
      beginDragFromToolbox: CalendarOperations.beginDragFromToolbox,
      endDragFromToolbox: CalendarOperations.endDragFromToolbox,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const request = EntitySelectors.getRequest(state, ownProps.id);
  return {
    request,
    asset: EntitySelectors.getAsset(state, request.asset_id),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragSource(Type.Calendar, cardSource, collect)(RequestListItem));
