import { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

class AssetSearchTitle extends Component {
  render() {
    return this.props.asset.title;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps)(AssetSearchTitle);
