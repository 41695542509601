export function buildAtlaskitTreeNode(asset, treeAssets, additionalData = {}) {
  const childrenIds = treeAssets
    .filter(treeAsset => treeAsset.tree_parent_id === asset.id)
    .map(treeAsset => treeAsset.id);

  return {
    id: asset.id,
    children: childrenIds,
    parentId: asset.tree_parent_id,
    hasChildren: childrenIds.length > 0,
    isExpanded: additionalData.expandedIds && additionalData.expandedIds[asset.id] === true,
    isChildrenLoading: false,
    data: {
      ...additionalData,
      asset: asset,
    },
  };
}

export function buildLoadingAtlaskitTreeNode(id) {
  return {
    id,
    children: [],
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
      isCreating: true,
    },
  };
}

/*
  opts: {
    rootId: when building tree from certain asset.
    rootParentId: when building tree from certain parent asset.
  }
*/
export function buildAtlaskitTree(assets = [], opts = {}) {
  const nodes = {};
  const rootNodes = [];

  for (let i = 0; i < assets.length; i += 1) {
    if (opts.rootId != null && opts.rootId === assets[i].id) {
      rootNodes.push(assets[i].id);
    } else if (opts.rootParentId != null && opts.rootParentId === assets[i].tree_parent_id) {
      rootNodes.push(assets[i].id);
    } else if (assets[i].tree_parent_id == null) {
      rootNodes.push(assets[i].id);
    }

    nodes[assets[i].id] = buildAtlaskitTreeNode(assets[i], assets, opts);
  }

  return {
    rootId: 1,
    items: {
      1: {
        id: '1',
        children: rootNodes,
        parentId: null,
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
      },
      ...nodes,
    },
  };
}

export function updateTreeAssetIds(tree, assetIds) {
  let items = {
    ...tree.items,
  };
  assetIds.forEach(assetId => {
    items[assetId] = {
      ...tree.items[assetId],
    };
  });

  return { ...tree, items };
}

export function removeNodeFromAtlaskitTree(tree, assetId, opts = { tree_children_mode: 'move_to_parent' }) {
  if (!tree.items[assetId]) return tree;
  if (opts.tree_children_mode === 'move_to_parent') {
    let assetsToMove = {};
    tree.items[assetId].children.forEach(assetId => {
      assetsToMove[assetId] = true;
    });
    const parentIdToMoveTo = tree.items[assetId].parentId || '1';

    const newItems = Object.keys(tree.items).reduce((acc, itemId) => {
      const currentlyLoopingDeletedAssetsParent = itemId == parentIdToMoveTo;
      if (currentlyLoopingDeletedAssetsParent) {
        let childrenOfParent = [...tree.items[parentIdToMoveTo].children];
        const indexOfDeletedAssetId = childrenOfParent.indexOf(assetId);
        childrenOfParent.splice(indexOfDeletedAssetId, 1, ...[...tree.items[assetId].children]);
        return {
          ...acc,
          [itemId]: { ...tree.items[parentIdToMoveTo], children: childrenOfParent },
        };
      } else if (assetsToMove[itemId]) {
        return {
          ...acc,
          [itemId]: { ...tree.items[itemId], parentId: parentIdToMoveTo },
        };
      }
      return {
        ...acc,
        [itemId]: { ...tree.items[itemId] },
      };
    }, {});

    return {
      ...tree,
      items: newItems,
    };
  } else {
    const removeIds = getAllTreeChildrenId(tree, assetId);

    const newItems = Object.keys(tree.items).reduce((acc, itemId) => {
      if (removeIds[itemId]) {
        return acc;
      }

      const newChildren = tree.items[itemId].children.filter(childId => childId !== assetId);
      return {
        ...acc,
        [itemId]: { ...tree.items[itemId], children: newChildren },
      };
    }, {});

    return {
      ...tree,
      items: newItems,
    };
  }
}

function getAllTreeChildrenId(tree, assetId) {
  return tree.items[assetId].children.reduce(
    (acc, childId) => {
      const children = getAllTreeChildrenId(tree, childId);
      return { ...acc, ...children };
    },
    { [assetId]: true }
  );
}

export function generateEmptyAsset() {
  return {
    title: '',
    number: '',
    asset_type_id: null,
    images: [],
  };
}
