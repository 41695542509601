import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { find } from 'lodash-es';
import { FormattedMessage, injectIntl } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations, API } from 'sdk';
import { normalizePurchaseOrder } from 'sdk/Schemas';
import { EstimatedDeliveryMode } from 'sdk/PurchaseOrder';
import { Modal, Grid } from 'views/components/Shared/Layout';
import {
  Button,
  Field,
  MoneyWithCurrency,
  FieldErrorWrapper,
  DatePicker,
} from 'views/components/Shared/General';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import UnitField from './UnitField';
import styles from './style.module.scss';

class EditPuchaseOrderRowModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    isSaving: false,
    isSavingAndCreatingNew: false,
    editingPurchaseOrderRow: {},
    editingPurchaseOrderRowBeforeEdit: {},
    addSparePart: false,
    showTitleRequiredError: false,
    showPackageQuantityField: false,
  });

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.purchaseOrderRow == null) {
        const defaultSparePartUnit = find(this.props.sparePartUnits, { default: true });
        const editingPurchaseOrderRow = {
          spare_part_unit_id: defaultSparePartUnit == null ? null : defaultSparePartUnit.id,
          ...this.props.defaultParamsForNewPurchaseOrderRow,
        };
        this.setState({
          ...this.getInitialState(),
          showPackageQuantityField: editingPurchaseOrderRow.package_quantity != null,
          editingPurchaseOrderRow,
          editingPurchaseOrderRowBeforeEdit: editingPurchaseOrderRow,
        });
      } else {
        this.setState({
          ...this.getInitialState(),
          showPackageQuantityField: this.props.purchaseOrderRow.package_quantity != null,
          editingPurchaseOrderRow: { ...this.props.purchaseOrderRow },
          editingPurchaseOrderRowBeforeEdit: { ...this.props.purchaseOrderRow },
        });
      }
    }
  }

  setPurchaseOrderRowValue = obj => {
    const newEditingPurchaseOrderRow = {
      ...this.state.editingPurchaseOrderRow,
      ...obj,
    };

    this.setState({
      editingPurchaseOrderRow: newEditingPurchaseOrderRow,
    });
  };

  getModifiedPurchaseOrdeRowData = () => {
    const editingPurchaseOrderRow = {
      ...this.state.editingPurchaseOrderRow,
      quantity: this.state.editingPurchaseOrderRow.quantity
        ? this.state.editingPurchaseOrderRow.quantity
        : '0',
    };
    const purchaseOrderRowBeforeEdit = this.props.purchaseOrderRow;

    return Object.keys(editingPurchaseOrderRow)
      .filter(key => editingPurchaseOrderRow[key] !== purchaseOrderRowBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: editingPurchaseOrderRow[key],
        }),
        {}
      );
  };

  shouldUpdateSparePartVendor = () => {
    const { number, title, price } = this.getModifiedPurchaseOrdeRowData();
    if (this.props.sparePartVendor) {
      if (number || title) {
        return true;
      }
      if (price && this.props.purchaseOrder.currency === this.props.vendor.purchase_order_currency) {
        return true;
      }
    }
    return false;
  };

  updatePurchaseOrderRow = () => {
    this.setState({ isSaving: true });
    const params = this.getModifiedPurchaseOrdeRowData();
    if (this.shouldUpdateSparePartVendor()) {
      this.props.updatePurchaseOrderRow(this.props.purchaseOrderRow.id, params).then(() => {
        API.fetchPurchaseOrder(this.props.purchaseOrder.id).then(({ data: purchaseOrder }) => {
          const { entities } = normalizePurchaseOrder(purchaseOrder);
          this.props.updateEntities(entities);
          this.props.onEditPurchaseOrderRowWithSparePartVendor({
            purchaseOrderRowId: this.props.purchaseOrderRow.id,
            sparePartVendorId: this.props.sparePartVendor.id,
          });
        });
      });
    } else {
      this.props
        .updatePurchaseOrderRow(this.props.purchaseOrderRow.id, params)
        .then(() => {
          API.fetchPurchaseOrder(this.props.purchaseOrder.id).then(({ data: purchaseOrder }) => {
            const { entities } = normalizePurchaseOrder(purchaseOrder);
            this.props.updateEntities(entities);
            this.props.onClose();
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          });
        })
        .catch(e => {
          this.setState({ isSaving: false });
        });
    }
  };

  createPurchaseOrderRowAndCreateNew = () => {
    if (!this.state.editingPurchaseOrderRow.title) {
      this.setState({ showTitleRequiredError: true });
      return;
    }
    if (this.state.isSaving || this.state.isSavingAndCreatingNew) return;
    this.setState({ isSavingAndCreatingNew: true });

    this.props
      .createPurchaseOrderRowForPurchaseOrder(this.props.purchaseOrder.id, this.state.editingPurchaseOrderRow)
      .then(({ data: purchaseOrderRow }) => {
        API.fetchPurchaseOrder(this.props.purchaseOrder.id).then(({ data: purchaseOrder }) => {
          const { entities } = normalizePurchaseOrder(purchaseOrder);
          this.props.updateEntities(entities);
          if (this.state.addSparePart) {
            this.props.onCreateAndCreateNewWithSparePart(purchaseOrderRow);
          } else {
            this.props.onCreateAndCreateNew(purchaseOrderRow);
          }
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="screens.purchase-order.info.articles.article-has-been-created-success" />
              }
            />
          );
        });
      })
      .catch(e => {
        this.setState({ isSavingAndCreatingNew: false });
      });
  };

  createPurchaseOrderRow = () => {
    if (this.state.isSaving || this.state.isSavingAndCreatingNew) return;
    this.setState({ isSaving: true });

    this.props
      .createPurchaseOrderRowForPurchaseOrder(this.props.purchaseOrder.id, this.state.editingPurchaseOrderRow)
      .then(({ data: purchaseOrderRow }) => {
        API.fetchPurchaseOrder(this.props.purchaseOrder.id).then(({ data: purchaseOrder }) => {
          const { entities } = normalizePurchaseOrder(purchaseOrder);
          this.props.updateEntities(entities);
          if (this.state.addSparePart) {
            this.props.onCreateWithNewSparePart(purchaseOrderRow);
          } else {
            this.props.onCreate(purchaseOrderRow);
          }
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="screens.purchase-order.info.articles.article-has-been-created-success" />
              }
            />
          );
        });
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  save = () => {
    if (!this.state.editingPurchaseOrderRow.title) {
      this.setState({ showTitleRequiredError: true });
      return;
    }
    if (this.props.purchaseOrderRow == null) {
      this.createPurchaseOrderRow();
    } else {
      this.updatePurchaseOrderRow();
    }
  };

  getPurchaseOrderRowSummary = purchaseOrderRow => {
    try {
      const quantity = new Decimal(purchaseOrderRow.quantity || 0);
      const price = new Decimal(purchaseOrderRow.price || 0);
      return quantity.times(price);
    } catch (e) {
      return new Decimal(0);
    }
  };

  renderQuantityField = () => {
    if (this.state.showPackageQuantityField) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.quantity" />}>
              <Field.Decimal
                rightLabel={<FormattedMessage id="resources.spare-part-vendor.package-unit" />}
                onKeyPress={this.handleKeyPress}
                value={this.state.editingPurchaseOrderRow.quantity}
                onChange={quantity => {
                  this.setPurchaseOrderRowValue({ quantity });
                }}
                onBlur={quantity => {
                  this.setPurchaseOrderRowValue({ quantity });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return (
      <Grid.Row>
        <Grid.Column md={6}>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.quantity" />}>
            <Field.Decimal
              onKeyPress={this.handleKeyPress}
              value={this.state.editingPurchaseOrderRow.quantity}
              onChange={quantity => {
                this.setPurchaseOrderRowValue({ quantity });
              }}
              onBlur={quantity => {
                this.setPurchaseOrderRowValue({ quantity });
              }}
            />
          </Field>
        </Grid.Column>
        <Grid.Column md={6}>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.unit" />}>
            <UnitField
              value={this.state.editingPurchaseOrderRow.spare_part_unit_id}
              sparePartVendorId={this.state.editingPurchaseOrderRow.spare_part_vendor_id}
              onChange={spare_part_unit_id => this.setPurchaseOrderRowValue({ spare_part_unit_id })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderEstimatedDeliveryDateField = () => {
    if (this.props.purchaseOrder.estimated_delivery_mode === EstimatedDeliveryMode.Multiple) {
      return (
        <>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field
                view={false}
                label={<FormattedMessage id="resources.purchase-order.expected-delivery-date" />}
              >
                <Field.Date
                  clearable
                  onChangeDate={estimated_delivery_date =>
                    this.setPurchaseOrderRowValue({ estimated_delivery_date })
                  }
                  value={this.state.editingPurchaseOrderRow.estimated_delivery_date}
                  footerComponent={
                    <DatePicker.Footer
                      showClear={this.state.estimated_delivery_date != null}
                      onClear={() => {
                        this.setPurchaseOrderRowValue({ estimated_delivery_date: null });
                      }}
                    />
                  }
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        </>
      );
    }
    return null;
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.number" />}>
            <Field.Text
              onKeyPress={this.handleKeyPress}
              autoFocus
              value={this.state.editingPurchaseOrderRow.number}
              onChange={number => {
                this.setPurchaseOrderRowValue({ number });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.title" />}>
            <FieldErrorWrapper
              show={this.state.showTitleRequiredError}
              errorElement={<FormattedMessage id="general.errors.is-required" />}
            >
              <Field.Text
                error={this.state.showTitleRequiredError}
                onKeyPress={this.handleKeyPress}
                value={this.state.editingPurchaseOrderRow.title}
                onChange={title => {
                  this.setPurchaseOrderRowValue({ title });
                }}
                onBlur={title => {
                  if (this.state.showTitleRequiredError && title) {
                    this.setState({ showTitleRequiredError: false });
                  } else if (!this.state.showTitleRequiredError && !title) {
                    this.setState({ showTitleRequiredError: true });
                  }
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderQuantityField()}
      <Grid.Row>
        <Grid.Column>
          <div className={styles['package-quantity-checkbox-container']}>
            <Field.Checkbox
              checked={this.state.showPackageQuantityField}
              onChange={showPackageQuantityField => {
                if (showPackageQuantityField === false) {
                  this.setPurchaseOrderRowValue({ package_quantity: null });
                }
                this.setState({ showPackageQuantityField });
              }}
              label={<FormattedMessage id="resources.spare-part-vendor.package-quantity" />}
              questionTooltipContent={
                <FormattedMessage id="resources.spare-part-vendor.package-quantity-description" />
              }
            />
          </div>
        </Grid.Column>
      </Grid.Row>

      <AnimateHeight
        duration={250}
        height={this.state.showPackageQuantityField ? 'auto' : 0}
        onAnimationEnd={() => this.packageQuantityInputRef.focus()}
      >
        <div className={styles['package-quantity-container']}>
          <Grid.Row>
            <Grid.Column md={8}>
              <Field.Decimal
                ref={ref => (this.packageQuantityInputRef = ref)}
                onKeyPress={this.handleKeyPress}
                value={this.state.editingPurchaseOrderRow.package_quantity}
                onChange={package_quantity => {
                  this.setPurchaseOrderRowValue({ package_quantity });
                }}
                onBlur={package_quantity => {
                  this.setPurchaseOrderRowValue({ package_quantity });
                }}
              />
              <div className={styles['package-quantity-description']}>
                <FormattedMessage id="screens.purchase-order.info.articles.package-quantity-description" />
              </div>
            </Grid.Column>
            <Grid.Column md={4}>
              <UnitField
                value={this.state.editingPurchaseOrderRow.spare_part_unit_id}
                sparePartVendorId={this.state.editingPurchaseOrderRow.spare_part_vendor_id}
                onChange={spare_part_unit_id => this.setPurchaseOrderRowValue({ spare_part_unit_id })}
              />
            </Grid.Column>
          </Grid.Row>
        </div>
      </AnimateHeight>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.price" />}>
            <Field.Money
              currency={this.props.purchaseOrder.currency}
              onKeyPress={this.handleKeyPress}
              value={this.state.editingPurchaseOrderRow.price}
              onChange={price => {
                this.setPurchaseOrderRowValue({ price });
              }}
              onBlur={price => {
                this.setPurchaseOrderRowValue({ price });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.total-cost" />}>
            <MoneyWithCurrency
              value={this.getPurchaseOrderRowSummary(this.state.editingPurchaseOrderRow)}
              currency={this.props.purchaseOrder.currency}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderEstimatedDeliveryDateField()}
      {this.state.editingPurchaseOrderRow.spare_part_id == null ? (
        <React.Fragment>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field.Checkbox
                checked={this.state.addSparePart}
                onChange={value => this.setState({ addSparePart: value })}
                label={
                  <FormattedMessage id="screens.purchase-order.info.articles.create-spare-part-button-label" />
                }
                questionTooltipContent={
                  <FormattedMessage
                    id="screens.purchase-order.info.articles.create-spare-part-button-tooltip"
                    values={{ vendor: this.props.vendor && this.props.vendor.name }}
                  />
                }
              />
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      ) : null}
    </Grid>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={
            this.props.purchaseOrderRow == null ? (
              <FormattedMessage id="screens.purchase-order.info.articles.edit-purchase-order-row-modal.title-new" />
            ) : (
              <FormattedMessage id="screens.purchase-order.info.articles.edit-purchase-order-row-modal.title-edit" />
            )
          }
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            {this.state.editingPurchaseOrderRow.spare_part_id == null ? (
              <Button
                label="general.save-and-create-new"
                loading={this.state.isSavingAndCreatingNew}
                onClick={() => this.createPurchaseOrderRowAndCreateNew()}
              />
            ) : null}
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.purchaseOrderRow) {
    const sparePartVendor = EntitySelectors.getSparePartVendor(
      state,
      ownProps.purchaseOrderRow.spare_part_vendor_id
    );
    return {
      sparePartVendor,
      sparePartUnits: AuthSelectors.getSparePartUnits(state),
      currentSystem: AuthSelectors.getCurrentSystem(state),
      vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
      sparePart: EntitySelectors.getSparePart(state, ownProps.purchaseOrderRow.spare_part_id),
    };
  }
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrderRow: SDKReduxOperations.updatePurchaseOrderRow,
      createPurchaseOrderRowForPurchaseOrder: SDKReduxOperations.createPurchaseOrderRowForPurchaseOrder,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditPuchaseOrderRowModal));
