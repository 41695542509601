import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash-es';
import { connect } from 'react-redux';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { ConfirmModal, Field, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import QrTemplateColumnWidthSlider from '../QrTemplateSettings/QrTemplateColumnWidthSlider';
import { SDKReduxOperations, HelperFunctions } from 'sdk';

const updateRequest = HelperFunctions.getCancelTokenForRequest();

class QrTemplateColumnSettings extends Component {
  getInititalState = () => ({
    editingQrTemplateColumn: {},
    showConfirmDeleteModal: false,
    isDeleting: false,
    showColumnWidthsForColumnId: null,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInititalState(),
      showColumnWidthsForColumnId: props.qrTemplateColumnId,
    };
    this.debouncedSave = debounce(() => {
      this.props
        .updateQrTemplateColumn(
          this.props.qrTemplateColumnId,
          this.getEditingChanges(),
          updateRequest.getCancelTokenConfig()
        )
        .then(() => {
          this.props.onHideSaveContainer();
        })
        .catch(() => {});
    }, 150);
  }

  componentDidMount() {
    this.setState({
      editingQrTemplateColumn: {
        ...this.props.qrTemplateColumn,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.qrTemplateColumnId !== this.props.qrTemplateColumnId) {
      this.setState({
        editingQrTemplateColumn: {
          ...this.props.qrTemplateColumn,
        },
        showColumnWidthsForColumnId: this.props.qrTemplateColumnId,
      });
    }
  }

  getEditingChanges = () => {
    let data = {};
    const editingQrTemplateColumn = this.state.editingQrTemplateColumn;
    const diffProps = Object.keys(editingQrTemplateColumn).filter(
      k => editingQrTemplateColumn[k] !== this.props.qrTemplateColumn[k]
    );
    diffProps.forEach(key => (data[key] = editingQrTemplateColumn[key]));
    return data;
  };

  setEditingQrTemplateColumnValues = (params, options = { save: false }) => {
    this.setState(
      {
        editingQrTemplateColumn: {
          ...this.state.editingQrTemplateColumn,
          ...params,
        },
      },
      () => {
        if (options.save === true) {
          updateRequest.cancel();
          this.props.onShowSaveContainer();
          this.debouncedSave();
        }
      }
    );
  };

  renderVerticalAlignValue = value => {
    switch (value) {
      case 'top':
        return <FormattedMessage id="components.print-qr-codes.edit-column-tab.vertical-adjustment-top" />;
      case 'bottom':
        return <FormattedMessage id="components.print-qr-codes.edit-column-tab.vertical-adjustment-bottom" />;
      case 'center':
        return <FormattedMessage id="components.print-qr-codes.edit-column-tab.vertical-adjustment-center" />;
      default:
        return null;
    }
  };

  renderVerticalAlignField = () => {
    const { editingQrTemplateColumn } = this.state;
    return (
      <Field label={<FormattedMessage id="components.print-qr-codes.edit-column-tab.vertical-adjustment" />}>
        <Field.Dropdown
          clearable={false}
          value={this.renderVerticalAlignValue(editingQrTemplateColumn.vertical_align)}
        >
          {['top', 'center', 'bottom'].map(value => (
            <Field.Dropdown.Item
              selected={editingQrTemplateColumn.vertical_align === value}
              onClick={() => {
                this.setEditingQrTemplateColumnValues({ vertical_align: value }, { save: true });
                this.props.onChangeColumnVerticalAlign(this.props.qrTemplateColumn.id, value);
              }}
            >
              {this.renderVerticalAlignValue(value)}
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </Field>
    );
  };

  renderMarginFields = () => {
    const { editingQrTemplateColumn } = this.state;
    return (
      <div className={styles['margins-wrapper']}>
        <Field label={<FormattedMessage id="components.print-qr-codes.margins" edit />}>
          <div className={styles['margins-container']}>
            <div className={[styles['margins'], styles['top']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateColumn.padding_top}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_top => {
                  this.setEditingQrTemplateColumnValues({ padding_top });
                }}
                onBlur={padding_top => {
                  this.setEditingQrTemplateColumnValues({ padding_top }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['left']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateColumn.padding_left}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_left => {
                  this.setEditingQrTemplateColumnValues({ padding_left });
                }}
                onBlur={padding_left => {
                  this.setEditingQrTemplateColumnValues({ padding_left }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['right']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateColumn.padding_right}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_right => {
                  this.setEditingQrTemplateColumnValues({ padding_right });
                }}
                onBlur={padding_right => {
                  this.setEditingQrTemplateColumnValues({ padding_right }, { save: true });
                }}
              />
            </div>
            <div className={[styles['margins'], styles['bottom']].join(' ')}>
              <Field.Number
                placeholder="0"
                value={editingQrTemplateColumn.padding_bottom}
                rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                onChange={padding_bottom => {
                  this.setEditingQrTemplateColumnValues({ padding_bottom });
                }}
                onBlur={padding_bottom => {
                  this.setEditingQrTemplateColumnValues({ padding_bottom }, { save: true });
                }}
              />
            </div>
            <div className={styles['vertical-line']} />
            <div className={styles['horizontal-line']} />
          </div>
        </Field>
      </div>
    );
  };

  renderColumnWidthSlider = () => {
    return (
      <div className={styles['column-width-container']}>
        <div className={styles['title']}>
          <FormattedMessage dark id={'components.print-qr-codes.edit-column-tab.width-for-column'} />
        </div>
        <QrTemplateColumnWidthSlider
          qrTemplateId={this.props.qrTemplateColumn.qr_template_id}
          qrTemplateColumnId={this.state.showColumnWidthsForColumnId}
          widthsForColumns={this.props.widthsForColumns}
          onChangeColumnWidths={params => this.props.onChangeColumnWidths(params)}
          onShowSaveContainer={this.props.onShowSaveContainer}
          onHideSaveContainer={this.props.onHideSaveContainer}
        />
        {this.state.showColumnWidthsForColumnId ? (
          <div className={styles['show-all-button-container']}>
            <Button
              type="text"
              primary
              fontSize={12}
              label="components.print-qr-codes.edit-column-tab.show-all-columns"
              noUnderline
              onClick={() => this.setState({ showColumnWidthsForColumnId: null })}
            />
          </div>
        ) : null}

        <div className={styles['separator']} />
      </div>
    );
  };

  deleteAndUpdateNeigboringColumn = () => {
    const indexOfCurrentColumn = this.props.qrTemplateColumns.findIndex(
      column => column.id === this.props.qrTemplateColumnId
    );
    let columnBeforeCurrentColumn = null;
    if (indexOfCurrentColumn === 0) {
      columnBeforeCurrentColumn = this.props.qrTemplateColumns[indexOfCurrentColumn + 1];
    } else {
      columnBeforeCurrentColumn = this.props.qrTemplateColumns[indexOfCurrentColumn - 1];
    }

    Promise.all([
      this.props.updateQrTemplateColumn(columnBeforeCurrentColumn.id, {
        width: columnBeforeCurrentColumn.width + this.props.qrTemplateColumn.width,
      }),
      this.props.deleteQrTemplateColumn(this.props.qrTemplateColumnId),
    ]).then(() => {
      this.props.onChangeColumnWidths({
        [columnBeforeCurrentColumn.id]: columnBeforeCurrentColumn.width + this.props.qrTemplateColumn.width,
      });
      this.props.onDeleteQrTemplateColumn(this.props.qrTemplateColumnId);
      this.props.onClose();
    });
  };

  deleteCurrentColumn = () => {
    this.props
      .deleteQrTemplateColumn(this.props.qrTemplateColumnId)
      .then(() => {
        this.props.onDeleteQrTemplateColumn(this.props.qrTemplateColumnId);
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '170001' })) {
          this.setState({ isDeleting: false, showConfirmDeleteModal: false });
          toast(
            <ToastMessage
              error
              text={
                <FormattedMessage id="components.print-qr-codes.confirm-delete-column-modal.errors.cant-delete-last-column" />
              }
            />
          );
        } else {
          this.setState({ isDeleting: false, showConfirmDeleteModal: false });
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }
      });
  };

  renderDeleteConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={<FormattedMessage id="components.print-qr-codes.confirm-delete-column-modal.title" />}
        message={<FormattedMessage id="components.print-qr-codes.confirm-delete-column-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => {
          this.setState({ isDeleting: true });
          if (this.props.qrTemplateColumns.length > 1) {
            this.deleteAndUpdateNeigboringColumn();
          } else {
            this.deleteCurrentColumn();
          }
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false, isDeleting: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="components.print-qr-codes.edit-column-tab.change-column" />
            </div>
            <div className={styles['icon-buttons']}>
              <div>
                <Button
                  type="icon"
                  icon={<Icon regular red type="trash-alt" />}
                  onClick={() => this.setState({ showConfirmDeleteModal: true })}
                />
              </div>
              <div>
                <Button
                  type="icon"
                  icon={<Icon light size={18} type="times" />}
                  onClick={() => this.props.onClose()}
                />
              </div>
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage dark id="components.print-qr-codes.edit-column-tab.settings-for-column" />
          </div>
        </div>
        {this.renderColumnWidthSlider()}
        {this.renderVerticalAlignField()}
        {this.renderMarginFields()}
        {this.renderDeleteConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplateColumn: SDKReduxOperations.updateQrTemplateColumn,
      deleteQrTemplateColumn: SDKReduxOperations.deleteQrTemplateColumn,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateColumnId } = ownProps;
  const qrTemplateColumn = EntitySelectors.getQrTemplateColumn(state, qrTemplateColumnId);
  const qrTemplate = EntitySelectors.getQrTemplate(state, qrTemplateColumn.qr_template_id);
  return {
    qrTemplateColumn: EntitySelectors.getQrTemplateColumn(state, qrTemplateColumnId),
    qrTemplateColumns: EntitySelectors.getQrTemplateColumns(state, qrTemplate.qr_template_columns),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrTemplateColumnSettings);
