const DOMAIN_PREFIX = 'newWorkOrder/';

const RESET_STATE = `${DOMAIN_PREFIX}reset_state`;

const PREPARE_NEW_WORK_ORDER = `${DOMAIN_PREFIX}prepare_new_work_order`;
const SET_EDITING_WORK_ORDER_VALUES = `${DOMAIN_PREFIX}set_editing_work_order_values`;

export default {
  RESET_STATE,
  PREPARE_NEW_WORK_ORDER,
  SET_EDITING_WORK_ORDER_VALUES,
};
