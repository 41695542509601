import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List } from 'views/components/Shared/General';
import { TreePath, AssetTitle } from 'views/components/Asset';

class AssetListItem extends Component {
  renderTitle = () => {
    return (
      <div>
        <AssetTitle id={this.props.asset.id} />
      </div>
    );
  };
  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          small
          clickable
          onClick={this.props.onClick}
          linkTo={`/assets/${this.props.asset.id}`}
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkbox
          checkboxDisabled={this.props.checkboxDisabled}
        >
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn title={this.renderTitle()} subtitle={<TreePath assetId={this.props.id} />} />
          <List.Item.Column width={350}>
            {this.props.assetType == null ? null : <div>{this.props.assetType.title}</div>}
          </List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const asset = EntitySelectors.getAsset(state, ownProps.id);
  return {
    asset,
    images: EntitySelectors.getImages(state, asset.images),
    assetType: EntitySelectors.getAssetType(state, asset.asset_type_id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(AssetListItem);
