import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { ElapsedTime } from 'views/components/Shared/General';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class EstimatedMinutes extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
      const fromEstimatedMinutes = value.split('.', 1)[0];
      const toEstimatedMinutes = value.substring(value.indexOf('.') + 3);
      return (
        <SideModal.Container.Filter.AppliedFilters.Value>
          <ElapsedTime elapsedMinutes={fromEstimatedMinutes} />
          <span>...</span>
          <ElapsedTime elapsedMinutes={toEstimatedMinutes} />
        </SideModal.Container.Filter.AppliedFilters.Value>
      );
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        <ElapsedTime elapsedMinutes={value} />
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Number
        title={<FormattedMessage id="resources.work-order.estimated-time" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.EstimatedMinutes,
            data: {
              [FILTER_TYPES.EstimatedMinutes]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                value: null,
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Number>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.EstimatedMinutes),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedMinutes);
