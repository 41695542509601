import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { API } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Icon, Button, Tooltip } from 'views/components/Shared/General';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeWorkOrder } from 'sdk/Schemas';

class PreviewWorkOrderModal extends Component {
  state = {
    isFetching: true,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      API.fetchWorkOrder(this.props.id).then(({ data: workOrder }) => {
        const { entities } = normalizeWorkOrder(workOrder);
        this.props.updateEntities(entities);
        this.setState({ isFetching: false });
      });
    }
  }

  renderSubtitle = () => {
    if (this.props.workOrder.asset_id) {
      return <TreePath size={13} fullPath assetId={this.props.workOrder.asset_id} />;
    }
    return null;
  };

  renderHeader = () => {
    if (this.state.isFetching) {
      return null;
    }
    if (this.props.workOrder) {
      return (
        <Modal.Header
          ignoreLine
          title={
            <span>
              {this.props.workOrder.title} - #{this.props.workOrder.number}
            </span>
          }
          subtitle={this.renderSubtitle()}
          iconButtons={
            <Tooltip
              trigger={
                <Link to={`/work-orders/${this.props.workOrder.id}`}>
                  <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.work-order" />}
            />
          }
          onClose={this.props.onClose}
        />
      );
    }
    return null;
  };

  renderModalContent = () => {
    if (this.state.isFetching) {
      return <Modal.Content loading />;
    }
    return (
      <Modal.Content>
        <PreviewWorkOrderContent workOrderId={this.props.id} />
      </Modal.Content>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        {this.renderHeader()}
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    workOrder: EntitySelectors.getWorkOrder(state, id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewWorkOrderModal);
