import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { NewInlineModal, Field } from 'views/components/Shared/General';

class TemplateFieldDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  renderTemplateFieldItems = () => {
    return this.props.templateFieldItems.map(templateFieldItem => {
      return (
        <NewInlineModal.Dropdown.Item
          selected={this.props.value === templateFieldItem.id}
          onClick={e => {
            this.props.onChange(templateFieldItem.id);
            this.setState({ isOpen: false });
          }}
        >
          {templateFieldItem.title}
        </NewInlineModal.Dropdown.Item>
      );
    });
  };

  renderSelectedTemplateFieldItem = () => {
    if (this.props.selectedTemplateFieldItem) {
      return this.props.selectedTemplateFieldItem.title;
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState({ isOpen: true })}
        >
          <Field.Resource
            angleDown
            value={this.renderSelectedTemplateFieldItem()}
            onClear={() => this.props.onClear()}
          />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderTemplateFieldItems()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedTemplateFieldItem: EntitySelectors.getTemplateFieldItem(state, ownProps.value),
    templateFieldItems: EntitySelectors.getTemplateFieldItems(
      state,
      ownProps.templateField.template_field_items
    ),
  };
}

export default connect(mapStateToProps)(TemplateFieldDropdown);
