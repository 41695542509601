import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, debounce } from 'lodash-es';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { BookmarkedAssetsDropdown } from 'views/components/Asset';
import { RequestsOperations, RequestsSelectors } from 'state/ducks/requests';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { LIST_TYPES, getGroupByFromList, getParamsForList } from 'sdk/Request';
import DetailContainer from './DetailContainer';
import { ListLayout } from 'views/components/Shared/Layout';
import HelperFunctions from 'utilities/HelperFunctions';
import InboxIcon from 'assets/images/InboxIcon.png';
import { Icon } from 'views/components/Shared/General';
import { Header } from 'views/scenes/OperationalMaintenances/components';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import FilterModal from './FilterModal';
import ContentMenu from './ContentMenu';
import styles from './style.module.scss';
import Inbox from './Inbox';
import List from './List';

const listRequestsRequest = SDKHelperFunctions.getCancelTokenForRequest();

export const REQUEST_VIEW = {
  Inbox: 'inbox',
  List: 'list',
};

class Requests extends Component {
  constructor(props) {
    super(props);
    const view = localStorage.getItem('requestView');
    this.listContainerRef = React.createRef();
    this.state = {
      searchTerm: '',
      showFilterModal: false,
      requestView: view || REQUEST_VIEW.Inbox,
    };
    this.fetchDebouncedRequests = debounce(params => {
      if (this.state.requestView === REQUEST_VIEW.Inbox) {
        this.fetchRequestsForInbox(params);
      } else {
        this.fetchRequestsForList(params);
      }
    }, 300);
  }

  componentDidMount() {
    const { list } = queryString.parse(this.props.location.search);
    const { isProductionSupervisor } = this.props;
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    const { request_production_supervisor_approval_activated } = this.props.settings;
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.requests.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Requests);

    let params = {};
    if (
      request_production_supervisor_approval_activated &&
      isProductionSupervisor &&
      has_asset_production_supervisors
    ) {
      this.changeList(LIST_TYPES.ToApprove, false);
      this.props.addQueryParameter({
        production_supervisor_user_id: this.props.currentUser.id,
      });
      params = {
        ...params,
        production_supervisor_user_id: this.props.currentUser.id,
      };
    } else if (this.props.isOperator) {
      this.changeList(LIST_TYPES.Open, false);
    } else if (this.props.isWorkRequester) {
      this.changeList(LIST_TYPES.MyCreated, false);
    } else {
      if (this.requestAssigneesIsActive()) {
        this.props.addQueryParameter({ assigned_to_me: true });
        params = {
          ...params,
          assigned_to_me: true,
        };
      }
    }
    this.fetchRequests(params);
    this.props.setGroupBy(getGroupByFromList(list));
  }

  componentDidUpdate(prevProps, prevState) {
    const { showFilterModal } = this.state;
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list: newList } = queryString.parse(this.props.location.search);
    const changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedList = oldList !== newList;
    const changedUser = prevProps.currentUser.id !== this.props.currentUser.id;
    const changedAssetDropdownParams = prevProps.assetDropdownParams !== this.props.assetDropdownParams;
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    const { operatorCheckedInAssetId: prevOperatorCheckedInAssetId } = prevProps;
    const { operatorCheckedInAssetId } = this.props;

    /*
      Changed from and to approve list
    */
    if (oldList !== newList && newList === LIST_TYPES.ToApprove && has_asset_production_supervisors) {
      this.props.addQueryParameter({
        production_supervisor_user_id: this.props.currentUser.id,
        assigned_to_me: null,
        assigned_to_user_id: null,
        assigned_to_group_id: null,
        asset_with_tree_children_id: null,
      });
      this.fetchRequests({
        production_supervisor_user_id: this.props.currentUser.id,
        asset_with_tree_children_id: null,
      });
      return;
    } else if (oldList !== newList && newList !== LIST_TYPES.ToApprove) {
      this.props.addQueryParameter({
        production_supervisor_user_id: null,
      });
      this.fetchRequests({
        production_supervisor_user_id: null,
      });
      return;
    }

    /*
      Changed page
    */
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      this.props.setLoading();
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchRequests();
      return;
    }

    /*
      Changed user from grouplogin
    */

    if (newList === LIST_TYPES.MyCreated && changedUser) {
      this.props.initializeView();
      this.props.setLoading();
      this.fetchRequests({ request_id: null, page: 1 });
      return;
    }

    /*
      Changed view
    */
    if (prevState.requestView !== this.state.requestView) {
      this.props.resetSelectedRequests();
      this.props.setLoading();
      localStorage.setItem('requestView', this.state.requestView);
      this.fetchRequests();
    }

    if (changedList) {
      this.props.resetSelectedRequests();
      this.props.addQueryParameter({ page: 1 });
      this.props.setGroupBy(getGroupByFromList(newList));
      this.fetchRequests({ page: 1 });
    } else if (changedQueryParams) {
      this.props.resetSelectedRequests();
      this.props.setLoading();
      this.fetchRequests();
    } else if (changedAdvancedFilters && showFilterModal === false) {
      this.props.resetSelectedRequests();
      this.props.addQueryParameter({ page: 1 });
      this.props.setLoading();
      this.fetchRequests({ page: 1 });
    } else if (changedAssetDropdownParams) {
      this.props.resetSelectedRequests();
      this.props.addQueryParameter({ page: 1 });
      this.props.setLoading();
      this.fetchRequests({ page: 1 });
    } else if (prevOperatorCheckedInAssetId !== operatorCheckedInAssetId) {
      this.props.resetSelectedRequests();
      this.props.addQueryParameter({ page: 1 });
      this.props.setLoading();
      this.fetchRequests({ page: 1 });
    }
  }

  changeQueryParams = obj => {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        ...obj,
      })}`
    );
  };

  requestAssigneesIsActive = () => {
    return (
      this.props.hasProTier &&
      this.props.canAdministrateRequests &&
      this.props.settings.request_multiple_assignees_active
    );
  };

  getAssetParamsForProductionSupervisor = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { isProductionSupervisor, assetDropdownParams } = this.props;

    if (list !== LIST_TYPES.ToApprove && isProductionSupervisor && assetDropdownParams) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: assetDropdownParams,
        },
      };
    }
    return {};
  };

  getOperatorAssetParams = () => {
    if (this.props.isOperator && this.props.operatorCheckedInAssetId) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: this.props.operatorCheckedInAssetId,
        },
      };
    }
    return {};
  };

  /*
    getParamsforList overridar sort om vi kör samma för inbox o list?
  */

  getParamsForListInUrl = attrs => {
    const { list } = queryString.parse(this.props.location.search);
    const sort = this.props.queryParameters['sort'];
    const sortOrder = this.props.queryParameters['sort-order'];
    let params = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...attrs,
      ...getParamsForList(list, this.props.currentUser.id),
      ...this.getAssetParamsForProductionSupervisor(),
      ...this.getOperatorAssetParams(),
    };
    if (this.state.requestView === REQUEST_VIEW.List && sort) {
      params = {
        ...params,
        sort,
      };
    }
    if (this.state.requestView === REQUEST_VIEW.List && sortOrder) {
      params = {
        ...params,
        'sort-order': sortOrder,
      };
    }
    return params;
  };

  fetchRequestsForInbox = params => {
    const { list } = queryString.parse(this.props.location.search);

    this.props
      .fetchRequestsForInbox(
        this.props.system.id,
        list,
        this.getParamsForListInUrl(params),
        listRequestsRequest.getCancelTokenConfig()
      )
      .catch(e => {});
  };

  fetchRequestsForList = params => {
    const { list } = queryString.parse(this.props.location.search);
    this.props
      .fetchRequestsForList(
        this.props.system.id,
        list,
        this.getParamsForListInUrl(params),
        listRequestsRequest.getCancelTokenConfig()
      )
      .catch(e => {});
  };

  fetchRequests = params => {
    listRequestsRequest.cancel();
    this.fetchDebouncedRequests(params);
  };

  changeList = (list, resetRequestId = true) => {
    const { list: listInUrl, request_id } = queryString.parse(this.props.location.search);
    if (list === listInUrl) return;
    this.props.setLoading();

    let urlParams = {
      list,
      request_id: resetRequestId ? null : request_id,
    };

    this.changeQueryParams(urlParams);
  };

  getSearchPlaceHolderForList = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.ToPlan:
        return this.props.intl.formatMessage({
          id: 'screens.requests.search-placeholder-for-list.to-plan',
        });

      case LIST_TYPES.WorkOrderCreated:
        return this.props.intl.formatMessage({
          id: 'screens.requests.search-placeholder-for-list.work-order-created',
        });

      case LIST_TYPES.WorkOrderCompleted:
        return this.props.intl.formatMessage({
          id: 'screens.requests.search-placeholder-for-list.work-order-completed',
        });

      case LIST_TYPES.Archive:
        return this.props.intl.formatMessage({
          id: 'screens.requests.search-placeholder-for-list.archived',
        });
      case LIST_TYPES.MyCreated:
        return this.props.intl.formatMessage({
          id: 'screens.requests.search-placeholder-for-list.my-created',
        });
      default:
        return this.props.intl.formatMessage({ id: 'screens.requests.search-placeholder-for-list.all' });
    }
  };

  renderRequestView = () => {
    if (this.state.requestView === REQUEST_VIEW.Inbox) {
      return (
        <>
          <div className={styles['inbox']}>
            <div className={styles['list-container']} ref={this.listContainerRef}>
              <div className={styles['list']}>
                <Inbox onShowFilter={() => this.setState({ showFilterModal: true })} />
              </div>
            </div>
            <div className={styles['detail-container']}>
              <DetailContainer location={this.props.location} />
            </div>
          </div>
        </>
      );
    }
    return (
      <List
        onShowFilter={() => this.setState({ showFilterModal: true })}
        onMassUpdate={() => {
          this.props.resetSelectedRequests();
          this.props.setLoading();
          this.fetchRequests();
        }}
      />
    );
  };

  renderBookmarkedAssetsDropdown = () => {
    if (!this.props.isProductionSupervisor) return null;
    const { list } = queryString.parse(this.props.location.search);
    const { isProductionSupervisor } = this.props;
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    const showingToApproveList = list === LIST_TYPES.ToApprove;
    const disabled = showingToApproveList && isProductionSupervisor && has_asset_production_supervisors;
    return (
      <>
        <BookmarkedAssetsDropdown disabled={disabled} />
        <div className={styles['separator']} />
      </>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.requests.title" />}
        leftContainer={
          <>
            {this.renderBookmarkedAssetsDropdown()}
            <div className={styles['icons']}>
              <Header.Button.Group>
                <Header.Button
                  selected={this.state.requestView === REQUEST_VIEW.Inbox}
                  onClick={() => {
                    this.setState({ requestView: REQUEST_VIEW.Inbox });
                  }}
                >
                  <img src={InboxIcon} />
                </Header.Button>
                <Header.Button
                  selected={this.state.requestView === REQUEST_VIEW.List}
                  onClick={() => {
                    this.setState({ requestView: REQUEST_VIEW.List });
                  }}
                >
                  <Icon type="list" />
                </Header.Button>
              </Header.Button.Group>
            </div>
          </>
        }
        totalEntries={
          <FormattedMessage
            id="screens.requests.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.getSearchPlaceHolderForList()}
        placeholder={this.getSearchPlaceHolderForList()}
        onSearch={searchTerm => {
          this.props.setLoading();
          this.setState({ searchTerm });
        }}
        onDebouncedSearch={() => {
          this.props.addQueryParameter({ page: 1, search: this.state.searchTerm });
        }}
        onClearSearch={() => {
          this.props.setLoading();
          this.setState({ searchTerm: '' }, () => {
            this.props.addQueryParameter({ page: 1, search: '' });
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            <ContentMenu />
            {this.renderRequestView()}
          </ListLayout.Content>
        </ListLayout>
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.props.resetSelectedRequests();
            this.props.setLoading();
            this.fetchRequests(params);
            this.setState({ showFilterModal: false });
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    isOperator: AuthSelectors.isOperator(state),
    filters: RequestsSelectors.getFilters(state),
    queryParameters: RequestsSelectors.getQueryParameters(state),
    filtersAsQueryParams: RequestsSelectors.getFiltersAsQueryParams(state),
    pagination: RequestsSelectors.getPagination(state),
    totalEntriesIsSelected: RequestsSelectors.getTotalEntriesIsSelected(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canCreateRequests: AuthSelectors.canCreateRequests(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    operatorCheckedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSelectedRequests: RequestsOperations.resetSelectedRequests,
      initializeView: RequestsOperations.initializeView,
      selectMenuItem: MenuOperations.selectItem,
      fetchRequestsForInbox: RequestsOperations.fetchRequestsForInbox,
      fetchRequestsForList: RequestsOperations.fetchRequestsForList,
      prepareNewRequest: RequestsOperations.prepareNewRequest,
      setLoading: RequestsOperations.setLoading,
      setGroupBy: RequestsOperations.setGroupBy,
      addQueryParameter: RequestsOperations.addQueryParameter,
      hideSelectTotalEntries: RequestsOperations.hideSelectTotalEntries,
    },
    dispatch
  );
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Requests)));
