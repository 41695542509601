import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FormattedNumber } from 'views/components/Shared/General';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import {
  WorkOrderRecurringDropdown,
  WorkOrderCustomInterval,
  RecurringMeterModal,
} from 'views/components/WorkOrder';
import { bindActionCreators } from 'redux';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { WorkOrderCategoryType } from 'sdk/System';
import { NewWorkOrderSelectors } from 'state/ducks/newWorkOrder';
import { buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import { Grid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { ErrorOperations } from 'state/ducks/error';
import { AuthSelectors } from 'state/ducks/auth';

class RecurringContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustomIntervalModal: false,
      showRecurringMeterModal: false,
      showInterval: false,
    };
  }

  saveRecurringInformation = intervalData => {
    const { start_date, ...params } = intervalData;
    this.props.onChangeValue({ ...params, date: intervalData.start_date });
  };

  clearInterval = () => {
    this.props.onChangeValue({
      frequency: null,
      interval: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
      month_type: null,
      generate_from_completed_date: false,
      meter_interval: null,
    });
  };

  renderActivateRecurring = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.showInterval}
            label={<FormattedMessage id="resources.work-order.recurring" />}
            onChange={value =>
              this.setState(
                prevState => ({ showInterval: !prevState.showInterval }),
                () => {
                  if (!this.state.showInterval) {
                    this.clearInterval();
                  } else if (this.props.editingWorkOrder.category == null) {
                    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
                      this.props.removeFieldsFromErrors(['category']);
                      this.props.onChangeValue({
                        category: WorkOrderCategory.PreventivePredetermined,
                      });
                    }
                    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Simple) {
                      this.props.removeFieldsFromErrors(['category']);
                      this.props.onChangeValue({
                        category: WorkOrderCategory.Preventive,
                      });
                    }
                  }
                }
              )
            }
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderRecurringMeterMessage = () => {
    if (this.props.editingWorkOrder.meter_interval) {
      return (
        <FormattedMessage
          id="resources.work-order.meter-interval"
          values={{
            value: (
              <FormattedNumber
                value={this.props.editingWorkOrder.meter_interval}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            ),
          }}
        />
      );
    }
    return null;
  };

  renderRecurringMeter = () => {
    return (
      <div onClick={() => this.setState({ showRecurringMeterModal: true })}>
        <Field.Resource angleDown value={this.renderRecurringMeterMessage()} onClear={this.clearInterval} />
      </div>
    );
  };

  renderRecurringDropdown = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.new-work-order-modal.interval" />}>
            <WorkOrderRecurringDropdown
              editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
              onSave={this.saveRecurringInformation}
              onClear={this.clearInterval}
              onShowCustomOptions={() => this.setState({ showCustomIntervalModal: true })}
              trigger={
                <Field.Resource
                  angleDown
                  value={
                    this.props.editingWorkOrderIsRecurring
                      ? buildIntervalMessage(
                          buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder),
                          this.props.intl
                        )
                      : null
                  }
                  onClear={this.clearInterval}
                />
              }
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderRecurring = () => {
    if (!this.state.showInterval) return null;
    if (this.props.meter) {
      return this.renderRecurringMeter();
    }
    return this.renderRecurringDropdown();
  };

  render() {
    return (
      <>
        {this.renderActivateRecurring()}
        {this.renderRecurring()}
        <WorkOrderCustomInterval
          open={this.state.showCustomIntervalModal}
          editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
          onSave={this.saveRecurringInformation}
          onClose={() => this.setState({ showCustomIntervalModal: false })}
        />
        <RecurringMeterModal
          editingWorkOrder={this.props.editingWorkOrder}
          open={this.state.showRecurringMeterModal}
          onSave={params => {
            this.props.onChangeValue(params);
            this.setState({ showRecurringMeterModal: false });
          }}
          onClose={() => this.setState({ showRecurringMeterModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { editingWorkOrder } = ownProps;
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    settings: AuthSelectors.getSettings(state),
    meter,
    meterUnit,
    editingWorkOrderIsRecurring: NewWorkOrderSelectors.editingWorkOrderIsRecurring(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RecurringContainer));
