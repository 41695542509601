import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash-es';
import { EntityOperations } from 'sdk/State/entities';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { normalizeSparePart } from 'sdk/Schemas';
import { List, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import SparePartList from '../components/SparePartList';
import SparePartListItem from '../components/SparePartList/SparePartListItem';

const listFilteredSparePartsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class SparePartTypeListContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sparePartIds: null,
      isLoading: true,
      totalAmountOfSpareParts: 0,
      isChangingPage: false,
      page: 1,
      totalPages: 0,
    };
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchFilteredSparePartsByType({ search: this.props.searchValue });
    }, 300);
  }

  componentDidMount() {
    this.fetchFilteredSparePartsByType({ search: this.props.searchValue });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sparePartTypeId !== this.props.sparePartTypeId ||
      prevProps.searchValue !== this.props.searchValue
    ) {
      this.setState({ isLoading: true });
      this.debouncedSearchValueWasChanged({ search: this.props.searchValue });
    }
  }

  changePage = page => {
    this.setState({ isChangingPage: true, page });
    this.fetchFilteredSparePartsByType({ page });
  };

  fetchFilteredSparePartsByType = params => {
    listFilteredSparePartsRequest.cancel();
    let attrs = {
      spare_part_type_with_children_id: this.props.sparePartTypeId,
      page_size: 8,
      ...params,
      ...this.props.additionalApiParams,
    };
    if (attrs.search && attrs.search.length > 0) {
      attrs = {
        ...attrs,
        sort: 'search_relevance',
      };
    }
    return API.listSpareParts(
      this.props.currentSystem.id,
      attrs,
      listFilteredSparePartsRequest.getCancelTokenConfig()
    )
      .then(res => {
        const { data, headers } = res;
        const { entities, result } = normalizeSparePart(data);
        this.props.updateEntities(entities);
        this.setState({
          sparePartIds: result,
          isLoading: false,
          isChangingPage: false,
          totalAmountOfSpareParts: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
          totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        });
      })
      .catch(e => {
        this.setState({ isLoading: false, isChangingPage: false });
      });
  };

  renderLoaders = () => {
    if (!this.state.sparePartIds || this.state.sparePartIds.length === 0) {
      return (
        <>
          <SparePartListItem loading />
          <SparePartListItem loading />
        </>
      );
    } else
      return this.state.sparePartIds.map(() => {
        return <SparePartListItem loading />;
      });
  };

  renderListLayout = () => {
    if (!this.state.isLoading && this.props.searchValue === '' && this.state.sparePartIds.length === 0)
      return this.renderEmptyDataSet();
    else if (!this.state.isLoading && this.props.searchValue && this.state.sparePartIds.length === 0)
      return this.renderEmptySearch();

    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.state.isLoading || this.state.isChangingPage ? (
            this.renderLoaders()
          ) : (
            <SparePartList
              listItemRightComponent={this.props.listItemRightComponent}
              loading={this.state.isLoading}
              ids={this.state.sparePartIds}
            />
          )}
        </List>
      </>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-spare-part-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          subtitle={<FormattedMessage id="components.select-spare-part-modal.content.empty-search" />}
        />
      </WhiteCard>
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          subtitle={<FormattedMessage id="components.select-spare-part-modal.content.empty-type-title" />}
        />
      </WhiteCard>
    );
  };

  renderPagination = () => {
    if (this.state.totalAmountOfSpareParts <= 8) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };
  render() {
    return (
      <>
        <Modal.ColumnLayout.Container.Content>{this.renderListLayout()}</Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartTypeListContent));
