import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  isDeleted: false,
  meterReadings: {
    ids: [],
    pagination: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_METER_FOR_VIEW: {
      return {
        ...state,
        id: action.payload,
        isDeleted: false,
      };
    }

    case types.LIST_METER_READINGS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        meterReadings: {
          ...state.meterReadings,
          ids,
          pagination,
        },
      };
    }

    case types.FETCH_METER_FOR_VIEW_SUCCESS:
      return {
        ...state,
        id: action.payload,
        isDeleted: false,
      };

    case SDKReduxTypes.METER_DELETED: {
      const { meterId } = action.payload;
      if (state.id === meterId) {
        return {
          ...state,
          isDeleted: true,
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.METER_READING_DELETED: {
      const { meterReadingId } = action.payload;

      return {
        ...state,
        meterReadings: {
          ...state.meterReadings,
          ids: state.meterReadings.ids.filter(id => id !== meterReadingId),
        },
      };
    }
    default:
      return state;
  }
};
