import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import ChecklistTemplateRowContainer from '../../ChecklistTemplateRowContainer';
import SelectAllRowsCheckbox from './SelectAllRowsCheckbox';
import ChecklistsImage from 'assets/images/EmptyDataSet/Checklists.png';
import styles from './style.module.scss';

class Rows extends Component {
  renderRows = () => {
    if (this.props.checklistInstance.inspection_failed_count === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['image-container']}>
            <img src={ChecklistsImage} alt="" />
          </div>
          <div className={styles['title']}>
            <FormattedMessage id="components.checklist-instance-modal.deviations-empty-data-set.title" />
          </div>
        </div>
      );
    }
    if (this.props.rowsWithDeviations.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['image-container']}>
            <img src={ChecklistsImage} alt="" />
          </div>
          <div className={styles['title']}>
            <FormattedMessage id="components.checklist-instance-modal.manage-deviations-empty-data-set.title" />
          </div>
        </div>
      );
    }
    return this.props.rowsWithDeviations.map((id, index) => {
      return (
        <ChecklistTemplateRowContainer
          id={id}
          prevTemplateRowId={this.props.rowsWithDeviations[index - 1]}
          nextTemplateRowId={this.props.rowsWithDeviations[index + 1]}
        />
      );
    });
  };

  renderSelectAllRowsCheckbox = () => {
    if (this.props.rowsWithDeviations.length === 0) {
      return null;
    }
    if (this.props.deviationTab === 'all') {
      return null;
    }
    if (this.props.isViewOnly) {
      return null;
    }

    return <SelectAllRowsCheckbox checklistTemplateRowIds={this.props.rowsWithDeviations} />;
  };

  render() {
    return (
      <>
        {this.renderSelectAllRowsCheckbox()}
        {this.renderRows()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    checklistInstance: ChecklistModalSelectors.getChecklistInstance(state),
    deviationTab: ChecklistModalSelectors.getSelectedDeviationTab(state),
    rowsWithDeviations: ChecklistModalSelectors.getRootTemplateFieldIds(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps)(Rows);
