import React, { Component } from 'react';
import { connect } from 'react-redux';
import Decimal from 'decimal.js';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { List, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue, SparePartListItemExpandable } from 'views/components/SparePart';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class PurchaseOrderRowListItem extends Component {
  renderCheckIcon = () => {
    const { delivered_quantity, quantity } = this.props.purchaseOrderRow;
    const deliveredQuantityAsDec = new Decimal(delivered_quantity || 0);
    const quantityAsDec = new Decimal(quantity || 0);
    if (deliveredQuantityAsDec.greaterThanOrEqualTo(quantityAsDec)) {
      return <Icon type="check" regular withBackground green backgroundSize={22} size={12} />;
    }
    if (deliveredQuantityAsDec.greaterThan(new Decimal(0))) {
      return <Icon type="check" regular withBackground orange backgroundSize={22} size={12} />;
    }
    return <Icon type="check" regular withBackground backgroundSize={22} size={12} />;
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.unit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  renderQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.unit}
          value={this.props.purchaseOrderRow.quantity}
        />
      );
    }
  };

  renderDeliveredQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.delivered_quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.unit}
          value={this.props.purchaseOrderRow.delivered_quantity}
        />
      );
    }
  };

  renderExpandedComponent = () => {
    if (this.props.sparePart == null) {
      return (
        <div className={styles['empty-row-text']}>
          <FormattedMessage id="screens.purchase-order.info.articles.spare-part-not-yet-created-info-text" />
        </div>
      );
    }
    return <SparePartListItemExpandable id={this.props.sparePart.id} />;
  };

  render() {
    return (
      <List.Item expandable expandedComponent={this.renderExpandedComponent()}>
        <List.Item.Column width={100}>{this.props.purchaseOrderRow.number || '-'}</List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.purchaseOrderRow.title}
          subtitle={this.renderPackageQuantity()}
        />
        <List.Item.Column alignRight width={100}>
          {this.renderQuantity()}
        </List.Item.Column>
        <List.Item.Column alignRight width={100}>
          {this.renderDeliveredQuantity()}
        </List.Item.Column>
        <List.Item.Column alignRight width={22}>
          {this.renderCheckIcon()}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePart: EntitySelectors.getSparePart(state, ownProps.purchaseOrderRow.spare_part_id),
    unit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit_id),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(PurchaseOrderRowListItem);
