import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listPurchaseOrderShippingAddresses = (systemId, params) =>
  request.get(
    `systems/${systemId}/purchase_order_shipping_addresses?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`
  );
export const createPurchaseOrderShippingAddress = (systemId, data) =>
  request.post(`systems/${systemId}/purchase_order_shipping_addresses`, data);

export const deletePurchaseOrderShippingAddress = purchaseOrderShippingAddressId =>
  request.delete(`purchase_order_shipping_addresses/${purchaseOrderShippingAddressId}`);

export const updatePurchaseOrderShippingAddress = (purchaseOrderShippingAddressId, data) =>
  request.patch(`purchase_order_shipping_addresses/${purchaseOrderShippingAddressId}`, data);
