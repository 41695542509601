import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flatten } from 'lodash-es';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import WorkOrderListItem from './WorkOrderListItem';
import { EstimatedDeliveryMode } from 'sdk/PurchaseOrder';

class Section extends Component {
  state = {
    weeksWithWorkOrders: {},
  };

  componentDidMount() {
    let weeksWithWorkOrders = {};
    this.props.workOrders.forEach(workOrder => {
      let workOrderPurchasesForWorkOrder = this.props.workOrderPurchases.filter(
        wop => wop.work_order_id === workOrder.id
      );
      const purchaseOrdersSortedByLastDeliveryDate = this.props.purchaseOrders
        .filter(purchaseOrder => {
          return workOrderPurchasesForWorkOrder.find(wop => wop.purchase_order_id === purchaseOrder.id);
        })
        .sort((a, b) => {
          let aDateToCompare = null;
          let bDateToCompare = null;
          if (a.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
            aDateToCompare = a.estimated_delivery_date;
          } else {
            aDateToCompare = a.estimated_delivery_to_row_date;
          }
          if (b.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
            bDateToCompare = b.estimated_delivery_date;
          } else {
            bDateToCompare = b.estimated_delivery_to_row_date;
          }
          if (aDateToCompare == null) {
            return 1;
          } else if (bDateToCompare == null) {
            return -1;
          }
          var dateA = new Date(aDateToCompare);
          var dateB = new Date(bDateToCompare);
          return dateB - dateA;
        });
      let week = 'none';
      const purchaseOrderWithTheLastDeliveryDate = purchaseOrdersSortedByLastDeliveryDate[0];
      let date = null;
      if (purchaseOrderWithTheLastDeliveryDate.estimated_delivery_mode === EstimatedDeliveryMode.Single) {
        date = purchaseOrderWithTheLastDeliveryDate.estimated_delivery_date;
      } else {
        date = purchaseOrderWithTheLastDeliveryDate.estimated_delivery_to_row_date;
      }
      if (date) {
        week = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
      }
      if (weeksWithWorkOrders[week]) {
        weeksWithWorkOrders[week] = [...weeksWithWorkOrders[week], workOrder];
      } else {
        weeksWithWorkOrders[week] = [workOrder];
      }
    });
    this.setState({ weeksWithWorkOrders });
  }

  renderDeliveryDateForPurchaseOrder = date => {
    if (date === 'none') {
      return <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.no-delivery-date" />;
    }
    return (
      <FormattedMessage
        id="screens.calendar.toolbox.lists.awaiting-delivery.delivery-week"
        values={{ week: moment(date).format('W') }}
      />
    );
  };

  renderWorkOrdersForDate = date => {
    return this.state.weeksWithWorkOrders[date].map(workOrder => {
      return <WorkOrderListItem id={workOrder.id} />;
    });
  };

  render() {
    return Object.keys(this.state.weeksWithWorkOrders)
      .sort((a, b) => {
        if (a === 'none') {
          return 1;
        } else if (b === 'none') {
          return -1;
        }
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA - dateB;
      })
      .map(date => (
        <>
          <div className={styles['section']}>{this.renderDeliveryDateForPurchaseOrder(date)}</div>
          <div>{this.renderWorkOrdersForDate(date)}</div>
        </>
      ));
  }
}

function mapStateToProps(state, ownProps) {
  let allWorkOrderPurchases = ownProps.workOrders.map(({ work_order_purchases }) => work_order_purchases);
  const workOrderPurchases = EntitySelectors.getWorkOrderPurchases(state, flatten(allWorkOrderPurchases));
  const purchaseOrders = EntitySelectors.getPurchaseOrders(
    state,
    workOrderPurchases.map(({ purchase_order_id }) => purchase_order_id)
  );

  return {
    workOrderPurchases,
    purchaseOrders,
  };
}

export default connect(mapStateToProps)(Section);
