import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { CardGrid } from 'views/components/Shared/Layout';
import { List, SectionHeader } from 'views/components/Shared/General';
import { PreviewChecklistTemplateModal } from 'views/components/Checklist';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import ChecklistTemplateLinkItem from './ChecklistTemplateLinkItem';

class Checklist extends Component {
  state = {
    showPreviewChecklistModal: false,
    showPreviewChecklistModalForTemplateId: null,
  };

  renderChecklistListItem = () => {
    const { checklists } = this.props;

    return (
      <>
        <CardGrid.Row>
          <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
            <FormattedMessage id="resources.operational-maintenance-template-version.checklist" />
          </SectionHeader>
          <List>
            {checklists.map(checklist => (
              <ChecklistTemplateLinkItem
                key={checklist.checklist_template_id}
                checklistTemplateLink={checklist}
                onClickPreview={() => {
                  this.setState({
                    showPreviewChecklistModal: true,
                    showPreviewChecklistModalForTemplateId: checklist.checklist_template_id,
                  });
                }}
              />
            ))}
          </List>
        </CardGrid.Row>
      </>
    );
  };

  renderPreviewChecklistModal = () => {
    return (
      <PreviewChecklistTemplateModal
        hideFooter
        open={this.state.showPreviewChecklistModal}
        id={this.state.showPreviewChecklistModalForTemplateId}
        onClose={() => {
          this.setState({ showPreviewChecklistModal: false });
        }}
      />
    );
  };

  render() {
    const { checklists } = this.props;
    if (checklists.length === 0) {
      return null;
    }
    return (
      <>
        {this.renderChecklistListItem()}
        {this.renderPreviewChecklistModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    checklists: EditOperationalMaintenanceTemplateSelectors.getChecklists(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
