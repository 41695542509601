import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { MenuUtils } from 'state/ducks/menu';
import { MenuOperations } from 'state/ducks/menu';
import { connect } from 'react-redux';
import HelperFunctions from 'utilities/HelperFunctions';
import { Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { MeterSelectors, MeterOperations } from 'state/ducks/meter';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import NotFound from './NotFound';
import { Overview, History } from './Tabs';

class MeterDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      show: false,
    };
  }
  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Meters);
    this.fetchMeter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchMeter();
    }
  }

  fetchMeter = () => {
    this.props
      .fetchMeter(this.props.match.params.id)
      .then(meter => {
        const { system_id } = meter;
        if (system_id !== this.props.system.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(meter.title);
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.meter) {
      return <Loader centerInParent />;
    } else
      return (
        <Switch key={this.props.match.params.id}>
          <Route path={`/meters/:id`} exact component={Overview} />
          <Route path={`/meters/:id/history`} component={History} />
        </Switch>
      );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMeter: MeterOperations.fetchMeter,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    meter: EntitySelectors.getMeter(state, ownProps.match.params.id),
    isDeleted: MeterSelectors.isDeleted(state),
  };
}
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeterDetail)));
