import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { getShouldOrderQuantity } from 'sdk/SparePart';
import { List, Field, MoneyWithCurrency, Button, Tooltip, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue, Title, SparePartListItemExpandable } from 'views/components/SparePart';
import { MultipleSparePartVendorsModal } from 'views/components/SparePartVendor';
import { TABS } from '../';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  state = {
    showSelectSparePartVendorModal: false,
  };

  /*
    Helper functions
  */

  getPurchaseOrderRowParamsForSparePartVendor = sparePartVendor => {
    const { sparePart } = this.props;
    const title = sparePartVendor.title || sparePart.title;
    let quantity = getShouldOrderQuantity(sparePart) || '';
    if (sparePartVendor.package_quantity) {
      quantity = '';
    }
    return {
      quantity,
      title,
      number: sparePartVendor.number,
      package_quantity: sparePartVendor.package_quantity,
      price: sparePartVendor.purchase_price,
      spare_part_unit_id: sparePart.spare_part_unit_id,
      spare_part_vendor_id: sparePartVendor.id,
      spare_part_id: this.props.id,
    };
  };

  getPurchaseOrderRowParamsForSparePart = () => {
    const { sparePart } = this.props;
    let quantity = getShouldOrderQuantity(sparePart) || '';
    return {
      quantity,
      title: sparePart.title,
      spare_part_unit_id: sparePart.spare_part_unit_id,
      spare_part_id: this.props.id,
    };
  };

  selectRow = () => {
    if (this.props.tab === TABS.All) {
      this.props.onAdd(this.getPurchaseOrderRowParamsForSparePart());
      return;
    }

    if (this.props.sparePartVendors.length === 1) {
      this.props.onAdd(this.getPurchaseOrderRowParamsForSparePartVendor(this.props.sparePartVendors[0]));
    } else {
      this.setState({ showSelectSparePartVendorModal: true });
    }
  };

  /*
    Render Functions
  */

  renderSubtitle = () => {
    const { stock_quantity, maximum_quantity } = this.props.sparePart;
    if (maximum_quantity == null) {
      return (
        <Field fontSize={12} view label={<FormattedMessage id="resources.spare-part.in-stock" />} singleRow>
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={stock_quantity} />
        </Field>
      );
    }
    return (
      <div className={styles['should-order-container']}>
        <Field
          fontSize={12}
          view
          label={
            <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order" />
          }
          singleRow
        >
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={getShouldOrderQuantity(this.props.sparePart)}
          />
        </Field>
      </div>
    );
  };

  renderExpandedComponent = () => {
    return <SparePartListItemExpandable id={this.props.sparePart.id} />;
  };

  renderMultipleSparePartVendorsModal = () => {
    return (
      <MultipleSparePartVendorsModal
        select
        open={this.state.showSelectSparePartVendorModal}
        vendorId={this.props.vendor.id}
        sparePartId={this.props.id}
        onSelectSparePartVendor={sparePartVendor => {
          this.props.onAdd(this.getPurchaseOrderRowParamsForSparePartVendor(sparePartVendor));
          this.setState({ showSelectSparePartVendorModal: false });
        }}
        onClose={() => this.setState({ showSelectSparePartVendorModal: false })}
      />
    );
  };

  renderPurchasePrice = () => {
    if (this.props.tab === TABS.All) {
      return null;
    }
    const { vendor, sparePartVendors } = this.props;
    if (vendor && sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      return (
        <List.Item.Column>
          {sparePartVendor.purchase_price ? (
            <MoneyWithCurrency
              currency={vendor.purchase_order_currency}
              value={sparePartVendor.purchase_price}
            />
          ) : null}
        </List.Item.Column>
      );
    }
  };

  renderButton = () => {
    return (
      <List.Item.Column>
        <Button gray small label="general.add" onClick={this.selectRow} />
      </List.Item.Column>
    );
  };

  renderAddedText = () => {
    if (this.props.selected) {
      return (
        <List.Item.Column>
          <span className={styles['added']}>
            <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.added" />
          </span>
        </List.Item.Column>
      );
    }
    return null;
  };

  renderPackageTooltip = () => {
    if (this.props.tab === TABS.All) {
      return null;
    }
    const { sparePartVendors } = this.props;
    if (sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        return (
          <List.Item.Column alignRight>
            <Tooltip
              label={
                <>
                  <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                  <span>: </span>
                  <SparePartUnitWithValue
                    sparePartUnit={this.props.sparePartUnit}
                    value={sparePartVendor.package_quantity}
                  />
                </>
              }
              trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  renderAmountOfAlternatives = () => {
    if (this.props.tab === TABS.All) {
      return null;
    }
    const { sparePartVendors } = this.props;
    if (sparePartVendors.length > 1) {
      return (
        <List.Item.Column alignRight>
          <FormattedPlural
            value={this.props.sparePartVendors.length}
            two={
              <FormattedMessage
                id="screens.vendor.spare-parts.amount-of-options.two"
                values={{ amount: this.props.sparePartVendors.length }}
              />
            }
            few={
              <FormattedMessage
                id="screens.vendor.spare-parts.amount-of-options.few"
                values={{ amount: this.props.sparePartVendors.length }}
              />
            }
            many={
              <FormattedMessage
                id="screens.vendor.spare-parts.amount-of-options.many"
                values={{ amount: this.props.sparePartVendors.length }}
              />
            }
            other={
              <FormattedMessage
                id="screens.vendor.spare-parts.amount-of-options.other"
                values={{ amount: this.props.sparePartVendors.length }}
              />
            }
          />
        </List.Item.Column>
      );
    }
    return null;
  };

  renderNotPrimaryWarningTooltip = () => {
    if (this.props.tab === TABS.All) {
      return null;
    }
    const { sparePart, vendor } = this.props;
    const { primary_vendor_id } = sparePart;
    if (primary_vendor_id && primary_vendor_id !== vendor.id) {
      return (
        <Tooltip
          label={<FormattedMessage id="screens.should-order-spare-parts.not-primary" />}
          trigger={<Icon size={14} regular type="info-circle" red />}
        />
      );
    }

    return null;
  };

  render() {
    return (
      <>
        <List.Item expandable expandedComponent={this.renderExpandedComponent()}>
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            title={<Title sparePart={this.props.sparePart} />}
            subtitle={this.renderSubtitle()}
          />
          {this.renderAddedText()}
          {this.renderNotPrimaryWarningTooltip()}
          {this.renderPackageTooltip()}
          {this.renderAmountOfAlternatives()}
          {this.renderPurchasePrice()}
          {this.renderButton()}
        </List.Item>
        {this.renderMultipleSparePartVendorsModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  return {
    sparePart,
    images: EntitySelectors.getImages(state, sparePart.images),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    sparePartVendors: EntitySelectors.getSparePartVendors(state, sparePart.spare_part_vendors_for_vendor),
  };
}

export default connect(mapStateToProps)(SparePartListItem);
