import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import {
  OperationalMaintenancesSelectors,
  OperationalMaintenancesOperations,
  OperationalMaintenancesUtils,
} from 'state/ducks/operationalMaintenances';
import {
  BookmarkedAssetsDropdownSelectors,
  BookmarkedAssetsDropdownOperations,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { List, Button, Banner, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { OperationalMaintenanceBreakModal } from 'views/components/OperationalMaintenance';
import { Header, LeftPanel } from 'views/scenes/OperationalMaintenances/components';
import styles from './style.module.scss';
import BreakListItem from './BreakListItem';
import TabBarItem from 'views/components/Shared/Layout/Header/TabBarItem';

const ListTypes = {
  Active: 'active',
  History: 'history',
};

class Breaks extends Component {
  getInitialState = () => ({
    isFetching: true,
    showOperationalMaintenanceBreakModal: false,
    showOperationalMaintenanceBreakModalForId: null,
    list: ListTypes.Active,
    queryParameters: {
      page: 1,
      page_size: 25,
    },
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.fetchDebouncedOperationalMaintenanceBreaks = debounce(() => {
      this.fetchOperationalMaintenanceBreaks();
    }, 300);
  }

  componentDidMount() {
    this.props.fetchBookmarkedAssets(this.props.system.id).then(() => {
      this.fetchOperationalMaintenanceBreaks();
    });
  }

  componentDidUpdate(prevProps) {
    const changedBookmarkedAssets = prevProps.bookmarkedAssets.length !== this.props.bookmarkedAssets.length;
    const changedDropdownTypeForAssetDropdown =
      prevProps.dropdownTypeForAssetDropdown !== this.props.dropdownTypeForAssetDropdown;
    const changedAssetIdForAssetDropdown =
      prevProps.assetIdForAssetDropdown !== this.props.assetIdForAssetDropdown;
    if (changedBookmarkedAssets || changedDropdownTypeForAssetDropdown || changedAssetIdForAssetDropdown) {
      this.setState({ isFetching: true });
      this.fetchDebouncedOperationalMaintenanceBreaks();
    }
  }

  componentWillUnmount() {
    this.fetchDebouncedOperationalMaintenanceBreaks.cancel();
  }

  fetchOperationalMaintenanceBreaks = () => {
    const params = {
      ...this.getParams(),
      ...this.state.queryParameters,
    };

    this.props.fetchOperationalMaintenanceBreaks(this.props.system.id, params).then(() => {
      this.setState({ isFetching: false });
    });
  };

  getParams = () => {
    const { list } = this.state;
    const { dropdownTypeForAssetDropdown, assetIdForAssetDropdown, bookmarkedAssets } = this.props;
    let params = {};
    if (list === ListTypes.Active) {
      params = {
        ...params,
        active: true,
      };
    } else {
      params = {
        ...params,
        active: false,
      };
    }
    if (dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
      params = {
        ...params,
        asset_with_tree_children_id: bookmarkedAssets.map(({ asset_id }) => asset_id).join(','),
      };
    } else if (assetIdForAssetDropdown) {
      params = {
        ...params,
        asset_with_tree_children_id: assetIdForAssetDropdown,
      };
    }
    return params;
  };

  setStateAndFetchOperationalMaintenanceBreaks = params => {
    this.setState({ ...params, isFetching: true }, () => this.fetchOperationalMaintenanceBreaks());
  };

  renderEmptyDataSetTitle = () => {
    if (this.state.list === ListTypes.Active) {
      return <FormattedMessage id="screens.operational-maintenances.breaks.empty-data-set-active.title" />;
    }
    return <FormattedMessage id="screens.operational-maintenances.breaks.empty-data-set-history.title" />;
  };

  renderEmptyDataSetSubtitle = () => {
    if (this.state.list === ListTypes.Active) {
      return <FormattedMessage id="screens.operational-maintenances.breaks.message" />;
    }
    return <FormattedMessage id="screens.operational-maintenances.breaks.message" />;
  };

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
        <div className={styles['subtitle']}>{this.renderEmptyDataSetSubtitle()}</div>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderBookmarkedAssetsEmptyDataset = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={
            <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.subtitle" />
          }
          tiny
          listContainer
        />
      </WhiteCard>
    );
  };

  renderHeader = () => {
    return (
      <List.Header small background>
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.operational-maintenance-break.resource" />
        </List.Header.Column>
      </>
    );
  };

  renderCreateButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <div className={styles['button-container']}>
          <Button
            primary
            label="screens.operational-maintenances.breaks.create-button"
            onClick={() =>
              this.setState({
                showOperationalMaintenanceBreakModal: true,
                showOperationalMaintenanceBreakModalForId: null,
              })
            }
          />
        </div>
      );
    }
    return null;
  };

  renderPagination = () => {
    if (this.props.breaks.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.state.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.state.queryParameters.page_size}
          onSelectPage={page => {
            this.setStateAndFetchOperationalMaintenanceBreaks({
              queryParameters: { ...this.state.queryParameters, page },
            });
          }}
          onChangePageSize={page_size => {
            this.setStateAndFetchOperationalMaintenanceBreaks({
              queryParameters: { ...this.state.queryParameters, page: 1, page_size },
            });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderList = () => {
    const { dropdownTypeForAssetDropdown, bookmarkedAssets, breaks } = this.props;
    if (this.state.isFetching) {
      return (
        <>
          {this.renderMessage()}
          {this.renderCreateButton()}
          {this.renderHeader()}
          <List>
            <BreakListItem loading />
            <BreakListItem loading />
          </List>
        </>
      );
    }
    if (
      dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets &&
      bookmarkedAssets.length === 0
    ) {
      return (
        <>
          {this.renderCreateButton()}
          {this.renderBookmarkedAssetsEmptyDataset()}
        </>
      );
    }
    if (breaks.length === 0) {
      return (
        <>
          {this.renderCreateButton()}
          {this.renderEmptyDataset()}
        </>
      );
    }
    return (
      <>
        {this.renderMessage()}
        {this.renderCreateButton()}
        {this.renderHeader()}
        <List>
          {this.props.breaks.map(({ id }) => {
            return (
              <BreakListItem
                key={id}
                id={id}
                onClick={() =>
                  this.setState({
                    showOperationalMaintenanceBreakModal: true,
                    showOperationalMaintenanceBreakModalForId: id,
                  })
                }
              />
            );
          })}
        </List>
      </>
    );
  };

  renderContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.FilterBar>
          <div className={styles['tab-button']}>
            <TabBarItem
              active={this.state.list === ListTypes.Active}
              onClick={() => this.setStateAndFetchOperationalMaintenanceBreaks({ list: ListTypes.Active })}
            >
              <FormattedMessage id="screens.operational-maintenances.breaks.active" />
            </TabBarItem>
          </div>
          <div className={styles['tab-button']}>
            <TabBarItem
              active={this.state.list === ListTypes.History}
              onClick={() => this.setStateAndFetchOperationalMaintenanceBreaks({ list: ListTypes.History })}
            >
              <FormattedMessage id="screens.operational-maintenances.breaks.history" />
            </TabBarItem>
          </div>
        </ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderMessage = () => {
    return (
      <div className={styles['message-container']}>
        <Banner orange>
          <FormattedMessage id="screens.operational-maintenances.breaks.message" />
        </Banner>
      </div>
    );
  };

  renderOperationalMaintenanceBreakModal = () => {
    return (
      <OperationalMaintenanceBreakModal
        open={this.state.showOperationalMaintenanceBreakModal}
        id={this.state.showOperationalMaintenanceBreakModalForId}
        onClose={() =>
          this.setState({
            showOperationalMaintenanceBreakModal: false,
          })
        }
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          <Header />
          <ListLayout.Content>
            <LeftPanel />
            {this.renderContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderOperationalMaintenanceBreakModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenanceBreaks: OperationalMaintenancesOperations.fetchOperationalMaintenanceBreaks,
      fetchBookmarkedAssets: BookmarkedAssetsDropdownOperations.fetchBookmarkedAssets,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    breaks: OperationalMaintenancesSelectors.getOperationalMaintenanceBreaks(state),
    pagination: OperationalMaintenancesSelectors.getOperationalMaintenanceBreaksPagination(state),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    assetIdForAssetDropdown: BookmarkedAssetsDropdownSelectors.getAssetId(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breaks));
