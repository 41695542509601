import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { PurchaseOrdersSelectors, PurchaseOrdersOperations } from 'state/ducks/purchaseOrders';
import VendorContainer from './VendorContainer';
import { FILTER_TYPES } from 'views/scenes/PurchaseOrders/PurchaseOrderList/PurchaseOrders/FilterModal';

class Vendor extends Component {
  renderSelectedValue = () => {
    const { value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <VendorContainer id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.purchase-order.vendor" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Vendor,
            data: {
              [FILTER_TYPES.Vendor]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                value: [],
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: PurchaseOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: PurchaseOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Vendor),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
