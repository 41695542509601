import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import Decimal from 'decimal.js';
import { ToastMessage, Modal, Grid } from 'views/components/Shared/Layout';
import { Icon, Field, Button } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import styles from './style.module.scss';

class SaveReservedSparePartModal extends Component {
  state = {
    isSaving: false,
    quantity: '1',
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const quantityWithdrawedForSparePart = this.props.sparePartWithdrawals
        .filter(sparePartWithdrawal => {
          return sparePartWithdrawal.spare_part_id === this.props.sparePartReservation.spare_part_id;
        })
        .reduce((acc, spw) => acc.add(new Decimal(spw.quantity || 0)), new Decimal(0));
      const reservationQuantityLeft = new Decimal(this.props.sparePartReservation.quantity).minus(
        new Decimal(quantityWithdrawedForSparePart)
      );

      this.setState({ isSaving: false, quantity: reservationQuantityLeft.toString() });
    }
  }

  createSparePartWithdrawalsForOperationalMaintenance = () => {
    if (this.state.isSaving) return;
    this.setState({ isSaving: true });
    this.props
      .createSparePartWithdrawalForOperationalMaintenance(this.props.operationalMaintenanceInstance.id, {
        spare_part_id: this.props.sparePart.id,
        quantity: this.state.quantity,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />}
          />
        );

        this.props.onClose();
      });
  };

  renderImage = () => {
    if (this.props.sparePart.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.sparePart.images[0]})`,
        }}
      />
    );
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-withdrawal.quantity" />}>
              <Field.Number
                autoFocus
                rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
                value={this.state.quantity}
                onChange={quantity => this.setState({ quantity })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderTitleField = () => {
    return (
      <div className={styles['title-field']}>
        {this.renderImage()}
        <div className={styles['title']}>
          <Title sparePart={this.props.sparePart} />
        </div>
      </div>
    );
  };

  renderContent = () => {
    if (this.props.sparePart) {
      return (
        <Modal.Content>
          {this.renderTitleField()}
          {this.renderFields()}
        </Modal.Content>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        <Modal.Header
          ignoreLine
          title={
            <FormattedMessage id="screens.operational-maintenance.reserved-spare-parts.use-reserved-spare-part-modal.title" />
          }
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        <Modal.Footer>
          <div className={styles['button-group']}>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={this.createSparePartWithdrawalsForOperationalMaintenance}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartWithdrawalForOperationalMaintenance:
        SDKReduxOperations.createSparePartWithdrawalForOperationalMaintenance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { sparePartReservationId, operationalMaintenanceInstance } = ownProps;
  const { spare_part_withdrawals } = operationalMaintenanceInstance;
  let sparePartReservation = EntitySelectors.getSparePartReservation(state, sparePartReservationId);
  let sparePart = null;
  let sparePartUnit = null;
  if (sparePartReservation) {
    sparePart = EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id);
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  }
  return {
    sparePartReservation,
    sparePart,
    sparePartUnit,
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawals),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveReservedSparePartModal);
