import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { SectionHeader, WhiteCard, Icon } from 'views/components/Shared/General';
import { ChecklistDeviationActionModal } from 'views/components/Checklist';
import { PreviewWorkOrderModal } from 'views/components/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class CreatedFromWorkOrder extends Component {
  state = {
    showChecklistDeviationActionModal: false,
    showChecklistDeviationActionModalForId: null,
    showPreviewWorkOrderModal: false,
    showPreviewWorkOrderModalForId: null,
  };

  renderChecklistDeviationActionModal = () => {
    return (
      <ChecklistDeviationActionModal
        id={this.state.showChecklistDeviationActionModalForId}
        open={this.state.showChecklistDeviationActionModal}
        onClose={() => this.setState({ showChecklistDeviationActionModal: false })}
      />
    );
  };

  renderPreviewWorkOrderModal = () => {
    return (
      <PreviewWorkOrderModal
        open={this.state.showPreviewWorkOrderModal}
        id={this.state.showPreviewWorkOrderModalForId}
        onClose={() => {
          this.setState({ showPreviewWorkOrderModal: false });
        }}
      />
    );
  };

  render() {
    if (this.props.isOperator) {
      return null;
    }
    if (this.props.checklistDeviationAction) {
      return (
        <>
          <div className={styles['container']}>
            <WhiteCard noPadding>
              <SectionHeader noBorderTop paddingHorizontal={26}>
                <div className={styles['section']}>
                  <FormattedMessage id="screens.request.created-from.title" />
                </div>
              </SectionHeader>
              <div
                className={styles['content-container']}
                onClick={() =>
                  this.setState({
                    showChecklistDeviationActionModal: true,
                    showChecklistDeviationActionModalForId: this.props.checklistDeviationAction.id,
                  })
                }
              >
                <div className={styles['content']}>
                  <div className={styles['value-container']}>
                    <div className={styles['label']}>
                      <FormattedMessage id="resources.checklist-instance.resource" />
                    </div>
                    <div className={styles['value']}>{this.props.checklistTemplateVersion.title}</div>
                  </div>
                  <Icon withBackground size={11} backgroundSize={22} type="list" />
                </div>
              </div>
            </WhiteCard>
          </div>
          {this.renderChecklistDeviationActionModal()}
        </>
      );
    }
    if (this.props.createdFromWorkOrder) {
      return (
        <>
          <div className={styles['container']}>
            <WhiteCard noPadding>
              <SectionHeader noBorderTop paddingHorizontal={26}>
                <div className={styles['section']}>
                  <FormattedMessage id="screens.request.created-from.title" />
                </div>
              </SectionHeader>
              <div
                className={styles['content-container']}
                onClick={() =>
                  this.setState({
                    showPreviewWorkOrderModal: true,
                    showPreviewWorkOrderModalForId: this.props.createdFromWorkOrder.id,
                  })
                }
              >
                <div className={styles['content']}>
                  <div className={styles['value-container']}>
                    <div className={styles['label']}>
                      <span>
                        <FormattedMessage id="resources.work-order.resource" />
                      </span>
                      <span> </span>
                      <span>#{this.props.createdFromWorkOrder.number}</span>
                    </div>
                    <div className={styles['value']}>{this.props.createdFromWorkOrder.title}</div>
                  </div>
                  <Icon withBackground size={11} backgroundSize={22} type="wrench" />
                </div>
              </div>
            </WhiteCard>
          </div>
          {this.renderPreviewWorkOrderModal()}
        </>
      );
    }
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const { request } = ownProps;
  const createdFromWorkOrder = EntitySelectors.getWorkOrder(state, request.created_from_work_order_id);
  const checklistDeviationAction = EntitySelectors.getChecklistDeviationAction(
    state,
    request.created_from_checklist_deviation_action
  );
  if (checklistDeviationAction) {
    const checklistInstance = EntitySelectors.getChecklistInstance(
      state,
      checklistDeviationAction.checklist_instance_id
    );
    return {
      isOperator: AuthSelectors.isOperator(state),
      checklistDeviationAction,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version_id
      ),
      workOrder: EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id),
      requests: [],
    };
  }
  return {
    createdFromWorkOrder,
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default connect(mapStateToProps)(CreatedFromWorkOrder);
