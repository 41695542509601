import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import DescriptionContainer from './DescriptionContainer';
import AttachmentsContainer from './AttachmentsContainer';
import Checklist from './Checklist';
import StatusContainer from './StatusContainer';
import UsedSpareParts from './UsedSpareParts';
import ReservedSpareParts from './ReservedSpareParts';
import MetaInfoContainer from './MetaInfoContainer';

class Information extends Component {
  render() {
    if (this.props.checklistInstances.length > 0) {
      return (
        <ContentContainer maxWidth={1100} minWidth={1000}>
          <StatusContainer operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
          <CardGrid>
            <CardGrid.Column width={320}>
              <DescriptionContainer
                operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
              />
              <MetaInfoContainer operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
              <AttachmentsContainer
                operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
              />
              <ReservedSpareParts
                operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
              />
              <CardGrid.Row>
                <UsedSpareParts operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
              </CardGrid.Row>
            </CardGrid.Column>
            <CardGrid.Column>
              <CardGrid.Row>
                <Checklist operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
              </CardGrid.Row>
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      );
    }
    return (
      <ContentContainer maxWidth={940} minWidth={940}>
        <CardGrid>
          <CardGrid.Column>
            <StatusContainer operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
            <DescriptionContainer
              operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
            />

            <AttachmentsContainer
              operationalMaintenanceInstance={this.props.operationalMaintenanceInstance}
            />
            <ReservedSpareParts operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
            <CardGrid.Row>
              <UsedSpareParts operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
            </CardGrid.Row>
          </CardGrid.Column>
          <CardGrid.Column width={280}>
            <MetaInfoContainer operationalMaintenanceInstance={this.props.operationalMaintenanceInstance} />
          </CardGrid.Column>
        </CardGrid>
      </ContentContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(state, id);
  return {
    operationalMaintenanceInstance,
    checklistInstances: EntitySelectors.getChecklistInstances(
      state,
      operationalMaintenanceInstance.checklist_instances
    ),
  };
}

export default withRouter(connect(mapStateToProps)(Information));
