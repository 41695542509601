import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { withRouter } from 'react-router';
import { EntityOperations } from 'sdk/State/entities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { normalizeSparePartType } from 'sdk/Schemas';
import { Menu, Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { SparePartTypePath } from 'views/components/SparePart';
import { MenuType } from 'views/scenes/SpareParts/SparePartList';
import styles from './style.module.scss';

class SparePartTypeMenu extends Component {
  state = {
    isFetching: true,
    showSearchField: false,
    sparePartTypes: [],
    searchValue: '',
    showMoreItemsDropdownOptions: false,
    noSparePartTypesHasChildren: false,
  };

  componentDidMount() {
    this.fetchSparePartTypes();
  }

  /*
    Requests
  */

  fetchSparePartTypes = () => {
    API.listSparePartTypes(this.props.currentSystem.id, { no_pagination: true }).then(res => {
      const { data } = res;
      const { entities, result } = normalizeSparePartType(data);
      this.props.updateEntities(entities);
      const sparePartTypes = result.map(id => entities.sparePartTypeById[id]);
      this.setState({
        noSparePartTypesHasChildren: sparePartTypes.find(({ parent_id }) => parent_id != null) == null,
        isFetching: false,
        sparePartTypes,
      });
    });
  };

  /*
    Helepr Functions
  */

  toggleSearch = () => {
    this.setState(
      prevState => ({
        showSearchField: !prevState.showSearchField,
        searchValue: '',
      }),
      () => {
        if (this.state.showSearchField) {
          this.inputRef.focus();
        }
      }
    );
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...obj,
      })}`
    );
  };

  /*
    Render functions
  */

  renderSearchField = () => {
    let classNames = [styles['search-container']];
    return (
      <div className={styles['border-positioner']}>
        <AnimateHeight duration={250} height={this.state.showSearchField ? 'auto' : 0}>
          <div className={classNames.join(' ')}>
            <div className={styles['input-container']}>
              <div className={styles['input-logo']}>
                <Icon regular type="search" />
              </div>
              <input
                ref={ref => (this.inputRef = ref)}
                className={styles['input']}
                placeholder={this.props.intl.formatMessage({
                  id: 'general.search-placeholder',
                })}
                autoComplete="off"
                value={this.state.searchValue}
                name="search"
                onChange={e => {
                  this.setState({ searchValue: e.target.value });
                }}
              />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  };

  renderSearchHeaderDropdown = () => {
    return (
      <>
        <div
          className={styles['search-title']}
          onClick={() =>
            this.setState(prevState => ({
              showMoreItemsDropdownOptions: !prevState.showMoreItemsDropdownOptions,
            }))
          }
        >
          <div className={styles['title']}>
            <FormattedMessage id="resources.spare-part.category" />
          </div>
          <div className={styles['icon']}>
            <Icon size={12} solid type="caret-down" />
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.searchHeaderDropdownRef}
          open={this.state.showMoreItemsDropdownOptions}
          position="right"
          onClose={() => this.setState({ showMoreItemsDropdownOptions: false })}
          minWidth={200}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.Asset);
                  localStorage.setItem('listMenu', MenuType.Asset);
                }}
              >
                <FormattedMessage id="resources.asset.resource" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartLocation);
                  localStorage.setItem('listMenu', MenuType.SparePartLocation);
                }}
              >
                <FormattedMessage id="resources.spare-part.location" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartType);
                  localStorage.setItem('listMenu', MenuType.SparePartType);
                }}
              >
                <FormattedMessage id="resources.spare-part.category" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderSearch() {
    return (
      <div className={styles['search-header-container']}>
        <div className={styles['search-header']} ref={ref => (this.searchHeaderDropdownRef = ref)}>
          {this.renderSearchHeaderDropdown()}
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={this.toggleSearch}
          />
        </div>
        {this.renderSearchField()}
      </div>
    );
  }

  renderListItemChildren = (spt, depth) => {
    return this.state.sparePartTypes
      .filter(({ parent_id }) => parent_id === spt.id)
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map(sparePartType => {
        return this.renderTreeListItem(sparePartType, depth);
      });
  };

  sparePartTypeHasChildren = spt => {
    return this.state.sparePartTypes.find(({ parent_id }) => parent_id === spt.id) != null;
  };

  renderTreeListItem = (sparePartType, depth = 1) => {
    const { spare_part_type_with_children_id } = queryString.parse(this.props.location.search);
    return (
      <Menu.Item
        treeStructure={this.state.noSparePartTypesHasChildren === false}
        title={sparePartType.title}
        expandable={this.sparePartTypeHasChildren(sparePartType)}
        expandableComponent={this.renderListItemChildren(sparePartType, depth + 1)}
        indent={depth}
        onClick={() => this.changeQueryParams({ spare_part_type_with_children_id: sparePartType.id })}
        selected={spare_part_type_with_children_id === sparePartType.id}
      />
    );
  };

  renderSearchedListItem = sparePartType => {
    const { spare_part_type_with_children_id } = queryString.parse(this.props.location.search);
    return (
      <Menu.Item
        title={sparePartType.title}
        subtitle={<SparePartTypePath sparePartTypeId={sparePartType.id} />}
        onClick={() => this.changeQueryParams({ spare_part_type_with_children_id: sparePartType.id })}
        selected={spare_part_type_with_children_id === sparePartType.id}
      />
    );
  };

  renderSparePartTypes = () => {
    if (this.state.isFetching) {
      return (
        <>
          <Menu.Item loading />
          <Menu.Item loading />
        </>
      );
    }
    if (this.state.sparePartTypes.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="screens.spare-parts.left-panel.spare-part-type.empty-data-set.title" />
        </span>
      );
    }
    if (this.state.showSearchField) {
      if (this.state.searchValue.length === 0) {
        return (
          <span className={styles['search-text']}>
            <FormattedMessage id="screens.spare-parts.left-panel.spare-part-type.search-text" />
          </span>
        );
      }
      const filteredSparePartTypes = this.state.sparePartTypes
        .filter(
          wot =>
            this.state.searchValue === '' ||
            wot.title.toLowerCase().includes(this.state.searchValue.toLowerCase())
        )
        .sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      if (filteredSparePartTypes.length === 0) {
        return (
          <span className={styles['search-text']}>
            <FormattedMessage id="general.empty-data-set-search.title" />
          </span>
        );
      }
      return filteredSparePartTypes.map(sparePartType => {
        return this.renderSearchedListItem(sparePartType);
      });
    }

    const rootSparePartTypes = this.state.sparePartTypes
      .filter(spt => spt.parent_id == null)
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    return rootSparePartTypes.map(sparePartType => {
      return this.renderTreeListItem(sparePartType);
    });
  };

  render() {
    return (
      <>
        {this.renderSearch()}
        <PerfectScrollbar>
          <div className={styles['items']}>{this.renderSparePartTypes()}</div>
        </PerfectScrollbar>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartTypeMenu)));
