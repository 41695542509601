import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer } from 'views/components/Shared/Layout';
import { Button, List, NewTab, EmptyDataSet, WhiteCard, Pagination } from 'views/components/Shared/General';
import { NewWorkOrderModal } from 'views/components/WorkOrder';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import WorkOrderSmall from 'assets/images/EmptyDataSet/WorkOrderSmall.png';
import WorkOrderListItem from './WorkOrderListItem';
import styles from './style.module.scss';

const FILTERS = ['page'];
const PAGE_SIZE = 8;

class WorkOrders extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      isFetching: true,
      queryParams: queryParams,
      showNewWorkOrderModal: false,
    };
  }

  componentDidMount() {
    this.fetchWorkOrders()
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {
        this.setState({ isFetching: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams, isFetching: true }, () => {
        this.fetchWorkOrders()
          .then(() => {
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          });
      });
    }
  }

  fetchWorkOrders = () => {
    const { list, page } = this.state.queryParams;
    let attrs = {};
    if (list === 'completed') {
      attrs = {
        ...attrs,
        status: WorkOrderStatus.Completed,
        sort: 'completed_date',
        'sort-order': 'desc',
      };
    } else {
      attrs = {
        ...attrs,
        list: 'upcoming',
        sort: 'due_date',
        'sort-order': 'asc',
      };
    }

    attrs = {
      ...attrs,
      page,
      page_size: PAGE_SIZE,
      assignee: `v:${this.props.match.params.id}`,
    };

    return this.props.fetchWorkOrders(this.props.currentSystem.id, attrs).then(({ data: workOrders }) => {
      return workOrders;
    });
  };

  isFiltering = () => FILTERS.some(key => this.state.queryParams[key] != null);

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  renderNewWorkOrderModal = () => {
    return (
      <NewWorkOrderModal
        defaultParams={{
          assigned_to_vendors: [this.props.match.params.id],
        }}
        open={this.state.showNewWorkOrderModal}
        onClose={() => {
          this.setState({ showNewWorkOrderModal: false });
        }}
        onCreated={workOrder => {
          this.setState({ showNewWorkOrderModal: false }, () => {
            setTimeout(() => {
              this.props.history.push(`/work-orders/${workOrder.id}`);
            }, 350);
          });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showNewWorkOrderModal: false }, () => {
            setTimeout(() => {
              this.setState({ showNewWorkOrderModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="screens.vendor.work-orders.empty-data-set.title" />}
          subtitle={<FormattedMessage id="screens.vendor.work-orders.empty-data-set.subtitle" />}
          image={WorkOrderSmall}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderCreateButton = () => {
    if (this.props.canCarryOutWorkOrders) {
      return (
        <div>
          <Button
            small
            primary
            onClick={() => this.setState({ showNewWorkOrderModal: true })}
            label="screens.asset.work-orders.create-button"
          />
        </div>
      );
    }
    return null;
  };

  renderToolbar = () => {
    const { list } = this.state.queryParams;
    return (
      <div className={styles['toolbar']}>
        <div className={styles['left-content']}>
          <NewTab.Container>
            <NewTab
              selected={list == null}
              linkTo={{
                search: null,
              }}
            >
              <FormattedMessage id="screens.vendor.work-orders.tabs.upcoming" />
            </NewTab>
            <NewTab
              selected={list === 'completed'}
              linkTo={{
                search: 'list=completed',
              }}
            >
              <FormattedMessage id="screens.vendor.work-orders.tabs.completed" />
            </NewTab>
          </NewTab.Container>
        </div>
        {this.renderCreateButton()}
      </div>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= PAGE_SIZE) return null;
    return (
      <Pagination
        totalEntriesComponent={
          <FormattedMessage
            id="screens.work-orders.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        hideOptions
        currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
        totalPages={this.props.pagination.totalPages}
        onSelectPage={page => {
          this.changeQueryParams({ page });
        }}
        style={{ justifyContent: 'flex-end', marginTop: 10 }}
      />
    );
  };

  renderHeader = () => {
    const { list } = this.state.queryParams;
    if (list === 'completed') {
      return (
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.work-order.title" />
          </List.Header.Column>
          <List.Header.Column width={140}>
            <FormattedMessage id="resources.work-order.completed-at" />
          </List.Header.Column>
          <List.Header.Column width={72}>
            <FormattedMessage id="resources.work-order.assignee" />
          </List.Header.Column>
          <List.Header.Column width={45} />
        </List.Header>
      );
    }
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order.title" />
        </List.Header.Column>
        <List.Header.Column width={140}>
          <FormattedMessage id="resources.work-order.due-date" />
        </List.Header.Column>
        <List.Header.Column width={72}>
          <FormattedMessage id="resources.work-order.assignee" />
        </List.Header.Column>
        <List.Header.Column width={45} alignRight />
      </List.Header>
    );
  };

  renderList = () => {
    const { list } = this.state.queryParams;
    if (this.state.isFetching) {
      if (this.isFiltering()) {
        const amountOfWorkOrders = this.props.workOrders.length === 0 ? 2 : this.props.workOrders.length;
        return (
          <>
            {this.renderToolbar()}
            {this.renderHeader()}
            <List>
              {Array(amountOfWorkOrders)
                .fill()
                .map(() => (
                  <List.Item small>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
            {this.renderPagination()}
          </>
        );
      }
      return (
        <>
          {this.renderToolbar()}
          {this.renderHeader()}
          <List>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.props.workOrders.length === 0)
      return (
        <>
          {this.renderToolbar()}
          {this.renderEmptyDataSet()}
        </>
      );
    return (
      <>
        {this.renderToolbar()}
        {this.renderHeader()}
        <List small>
          {this.props.workOrders.map(workOrder => {
            return <WorkOrderListItem list={list} key={workOrder.id} id={workOrder.id} />;
          })}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderList()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderNewWorkOrderModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrders: VendorOperations.fetchWorkOrders,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    workOrders: VendorSelectors.getWorkOrders(state),
    pagination: VendorSelectors.getWorkOrderListPagination(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrders)));
