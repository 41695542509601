import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { normalizeVendor } from 'sdk/Schemas';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, NewSearchField, EmptyDataSet, Loader, SectionHeader } from 'views/components/Shared/General';
import { SideModal } from 'views/components/Shared/Layout';
import { CurrencyRateModal } from 'views/components/PurchaseOrder';
import { TreePath } from 'views/components/Asset';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import VendorTiny from 'assets/images/EmptyDataSet/VendorTiny.png';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import VendorListItemForSparePart from './VendorListItemForSparePart';
import VendorListItem from './VendorListItem';

const PAGE_SIZE = 20;

class NewPurchaseOrderModal extends Component {
  getInitialState = () => ({
    searchValue: '',
    isSerching: false,
    isFetching: true,
    isFetchingMore: false,
    hasMoreData: false,
    paginateFrom: null,
    isSaving: false,
    vendorIds: [],
    primaryVendorId: null,
    selectedVendorId: null,
    showCurrencyRateModal: false,
    currencyForRateModal: null,
    rate: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      if (this.props.sparePartId) {
        this.listVendorsForSparePart();
      } else {
        this.fetchVendors().then(({ vendorIds, paginateFrom }) => {
          this.setState({
            vendorIds,
            paginateFrom,
            isFetching: false,
            hasMoreData: vendorIds.length >= PAGE_SIZE,
          });
        });
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  listVendorsForSparePart = () => {
    const listVendorsApiCall = API.listVendors(this.props.currentSystem.id, {
      page_size: 8,
      no_pagination: true,
      create_purchase_orders: true,
      spare_part_vendors_for_spare_part: this.props.sparePartId,
      spare_part_vendors: { spare_part_id: this.props.sparePartId },
      primary_for_spare_part_id: {
        [HelperFunctions.FILTER_COMPARABLES.NotExact]: this.props.sparePartId,
      },
      sort: 'name',
      'sort-order': 'asc',
    });
    const listPrimaryApiCall = API.listVendors(this.props.currentSystem.id, {
      page_size: 8,
      create_purchase_orders: true,
      spare_part_vendors_for_spare_part: this.props.sparePartId,
      spare_part_vendors: { spare_part_id: this.props.sparePartId },
      primary_for_spare_part_id: this.props.sparePartId,
      sort: 'name',
      'sort-order': 'asc',
    });
    Promise.all([listVendorsApiCall, listPrimaryApiCall])
      .then(([vendorsRes, primaryVendorRes]) => {
        const { data: vendorsData } = vendorsRes;
        const { data: primaryVendorsData } = primaryVendorRes;
        const { entities: vendorsEntities, result: vendorIds } = normalizeVendor(vendorsData);
        const { entities: primaryVendorsEntities, result: primaryVendorIds } =
          normalizeVendor(primaryVendorsData);
        this.props.updateEntities(vendorsEntities);
        this.props.updateEntities(primaryVendorsEntities);
        this.setState({
          isFetching: false,
          isSearching: false,
          vendorIds,
          primaryVendorId: primaryVendorIds[0],
        });
      })
      .catch(e => {
        this.setState({ isFetching: false });
      });
  };

  fetchVendors = params => {
    return API.listVendors(this.props.currentSystem.id, {
      ...params,
      create_purchase_orders: true,
      page_size: PAGE_SIZE,
    }).then(({ data: vendors, headers }) => {
      const { entities, result: vendorIds } = normalizeVendor(vendors);
      this.props.updateEntities(entities);
      return {
        vendorIds,
        paginateFrom: headers['paginate-from-token'],
      };
    });
  };

  fetchMoreVendors = () => {
    if (!this.state.hasMoreData || this.state.isFetchingMore) return;
    this.setState({ isFetchingMore: true });
    this.fetchVendors({ paginate_from: this.state.paginateFrom, search: this.state.searchValue }).then(
      ({ vendorIds, paginateFrom }) => {
        this.setState({
          vendorIds: [...this.state.vendorIds, ...vendorIds],
          paginateFrom,
          isFetchingMore: false,
          hasMoreData: vendorIds.length >= PAGE_SIZE,
        });
      }
    );
  };

  save = (vendor, purchaseOrderRow) => {
    if (this.state.isSaving) {
      return;
    }
    if (this.props.hasProTier) {
      this.createPurchaseOrder(vendor, purchaseOrderRow).catch(e => {
        if (HelperFunctions.hasError(e, { code: '10001', key: 'currency_exchange_rate' })) {
          this.setState({
            isSaving: false,
            showCurrencyRateModal: true,
            currencyForRateModal: vendor.purchase_order_currency,
          });
        }
      });
    } else {
      if (this.props.currentSystem.currency !== vendor.purchase_order_currency) {
        this.setState({
          showCurrencyRateModal: true,
          currencyForRateModal: vendor.purchase_order_currency,
        });
      } else {
        this.createPurchaseOrder(vendor, purchaseOrderRow);
      }
    }
  };

  createPurchaseOrder = (vendor, purchaseOrderRow) => {
    this.setState({ isSaving: true, selectedVendorId: vendor.id });

    let params = { vendor_id: vendor.id };
    if (this.state.rate) {
      params = { ...params, currency_exchange_rate: this.state.rate };
    }
    if (this.props.forWorkOrder) {
      params = { ...params, work_order_purchases: [{ work_order_id: this.props.forWorkOrder.id }] };
    }

    if (purchaseOrderRow) {
      params = {
        ...params,
        purchase_order_rows: [purchaseOrderRow],
      };
    }

    return this.props
      .createPurchaseOrder(this.props.currentSystem.id, params)
      .then(({ data: purchaseOrder }) => {
        this.setState({ isSaving: false, selectedVendorId: null });

        if (this.state.showCurrencyRateModal) {
          this.setState({ showCurrencyRateModal: false });
          setTimeout(() => {
            this.props.onCreated(purchaseOrder);
          }, 500);
        } else {
          this.props.onCreated(purchaseOrder);
        }
      });
  };

  renderCurrencyRateModal = () => (
    <CurrencyRateModal
      currency={this.state.currencyForRateModal}
      open={this.state.showCurrencyRateModal}
      isSaving={this.state.isSaving}
      onSave={rate => {
        this.setState({ rate }, () => {
          this.createPurchaseOrder();
        });
      }}
      onClose={() => {
        this.setState({ showCurrencyRateModal: false });
      }}
    />
  );

  renderSparePartVendorEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <EmptyDataSet
        title={
          <FormattedMessage id="components.new-purchase-order-modal.spare-part-vendors-empty-data-set.title" />
        }
        subtitle={
          <FormattedMessage id="components.new-purchase-order-modal.spare-part-vendors-empty-data-set.subtitle" />
        }
        image={VendorTiny}
        tiny
      />
    </div>
  );

  renderPrimaryVendorForSparePart = () => {
    const { primaryVendorId } = this.state;
    if (primaryVendorId) {
      const loading = this.state.isSaving && this.state.selectedVendorId === primaryVendorId;
      return (
        <VendorListItemForSparePart
          sparePartId={this.props.sparePartId}
          loading={loading}
          vendorId={primaryVendorId}
          onCreatePurchaseOrder={(vendor, purchaseOrderRow) => this.save(vendor, purchaseOrderRow)}
        />
      );
    }
    return null;
  };

  renderVendorsForSparePart = () => {
    const { vendorIds, primaryVendorId } = this.state;
    if (vendorIds.length === 0 && primaryVendorId == null) {
      return this.renderSparePartVendorEmptyDataSet();
    }
    if (this.state.primaryVendorId) {
      return (
        <>
          <SectionHeader noBorderBottom horizontalBorders>
            <FormattedMessage id="components.new-vendor-modal.primary-vendor-header" />
          </SectionHeader>
          <List light>{this.renderPrimaryVendorForSparePart()}</List>
          {vendorIds.length > 0 ? (
            <>
              <div className={styles['other-header']}>
                <SectionHeader noBorderBottom horizontalBorders>
                  <FormattedMessage id="components.new-vendor-modal.other-vendor-header" />
                </SectionHeader>
              </div>
              <List light>
                {this.state.vendorIds.map(id => {
                  const loading = this.state.isSaving && this.state.selectedVendorId === id;
                  return (
                    <VendorListItemForSparePart
                      sparePartId={this.props.sparePartId}
                      vendorId={id}
                      loading={loading}
                      onCreatePurchaseOrder={(vendor, purchaseOrderRow) =>
                        this.save(vendor, purchaseOrderRow)
                      }
                    />
                  );
                })}
              </List>
            </>
          ) : null}
        </>
      );
    }
    return (
      <List light>
        {this.state.vendorIds.map(id => {
          const loading = this.state.isSaving && this.state.selectedVendorId === id;
          return (
            <VendorListItemForSparePart
              sparePartId={this.props.sparePartId}
              vendorId={id}
              loading={loading}
              onCreatePurchaseOrder={(vendor, purchaseOrderRow) => this.save(vendor, purchaseOrderRow)}
            />
          );
        })}
      </List>
    );
  };

  renderVendorEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <EmptyDataSet
        title={<FormattedMessage id="components.new-purchase-order-modal.vendors-empty-data-set.title" />}
        subtitle={
          <FormattedMessage id="components.new-purchase-order-modal.vendors-empty-data-set.subtitle" />
        }
        image={VendorTiny}
        tiny
      />
    </div>
  );

  renderFetchMoreData = () => {
    if (this.state.isFetchingMore) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return null;
  };

  renderVendors = () => {
    if (this.state.vendorIds.length === 0 && this.state.searchValue.length === 0 && !this.state.isSearching) {
      return this.renderVendorEmptyDataSet();
    }
    return (
      <>
        {this.renderSearchField()}
        {this.renderVendorList()}
      </>
    );
  };

  renderVendorList = () => {
    if (this.state.isSearching) {
      return (
        <List light>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.searchValue && this.state.vendorIds.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchImage}
            tiny
          />
        </div>
      );
    }
    return (
      <List light>
        {this.state.vendorIds.map(id => {
          const loading = this.state.isSaving && this.state.selectedVendorId === id;
          return (
            <VendorListItem id={id} loading={loading} onCreatePurchaseOrder={vendor => this.save(vendor)} />
          );
        })}
        {this.renderFetchMoreData()}
      </List>
    );
  };

  renderSearchField = () => {
    return (
      <div className={styles['search-field']}>
        <NewSearchField
          value={this.state.searchValue}
          debounce
          placeholder={this.props.intl.formatMessage({
            id: 'components.new-purchase-order-modal.search-placeholder',
          })}
          onSearch={value => {
            this.setState({
              searchValue: value,
              isSearching: true,
            });
          }}
          onDebouncedSearch={value => {
            this.fetchVendors({ search: value }).then(({ vendorIds, paginateFrom }) => {
              this.setState({
                vendorIds,
                paginateFrom,
                isSearching: false,
                hasMoreData: vendorIds.length >= PAGE_SIZE,
              });
            });
          }}
          onClear={() => {
            this.setState({ isSearching: true, searchValue: '' });
            this.fetchVendors({ search: '' }).then(({ vendorIds, paginateFrom }) => {
              this.setState({
                vendorIds,
                paginateFrom,
                isSearching: false,
                hasMoreData: vendorIds.length >= PAGE_SIZE,
              });
            });
          }}
        />
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.forWorkOrder) {
      return <FormattedMessage id="components.new-purchase-order-modal.title-for-work-order" />;
    } else {
      return <FormattedMessage id="components.new-purchase-order-modal.title" />;
    }
  };

  renderSubtitle = () => {
    return <FormattedMessage id="components.new-purchase-order-modal.subtitle" />;
  };

  renderForWorkOrder = () => {
    if (!this.props.forWorkOrder) return null;

    return (
      <>
        <div className={styles['work-order']}>
          <div className={styles['title']}>{this.props.forWorkOrder.title}</div>
          {this.props.forWorkOrder.asset_id ? (
            <div className={styles['subtitle']}>
              <TreePath assetId={this.props.forWorkOrder.asset_id} fullPath />
            </div>
          ) : null}
        </div>
        <div className={styles['divider']} />
      </>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['loader-container']}>
          <Loader />
        </div>
      );
    }
    if (this.props.sparePartId) {
      return this.renderVendorsForSparePart();
    } else {
      return this.renderVendors();
    }
  };

  render() {
    return (
      <>
        <SideModal open={this.props.open} onClose={this.props.onClose}>
          <SideModal.Container>
            <InfiniteScroll
              loadMore={this.fetchMoreVendors}
              hasMore={this.state.hasMoreData}
              useWindow={false}
              initialLoad={false}
              threshold={350}
            >
              <SideModal.Container.Content>
                <SideModal.Header
                  title={this.renderTitle()}
                  subtitle={this.renderSubtitle()}
                  onClose={this.props.onClose}
                />
                {this.renderForWorkOrder()}
                {this.renderContent()}
              </SideModal.Container.Content>
            </InfiniteScroll>
          </SideModal.Container>
        </SideModal>
        {this.renderCurrencyRateModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createPurchaseOrder: SDKReduxOperations.createPurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.sparePartId),
    forWorkOrder: EntitySelectors.getWorkOrder(state, ownProps.forWorkOrderId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewPurchaseOrderModal));

NewPurchaseOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onCreatedWithReopen: PropTypes.func.isRequired,
};

NewPurchaseOrderModal.defaultProps = {
  open: false,
  onCreatedWithReopen: () => {},
  onCreated: () => {},
  onClose: () => {},
};
