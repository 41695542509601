import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API } from 'sdk';
import { normalizeAssetType } from 'sdk/Schemas';
import { NewInlineModal, Field, EmptyDataSet } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class AssetTypeInlineModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    isOpen: false,
    searchTerm: '',
    assetTypes: [],
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState(
        {
          ...this.getInitialState(),
          isOpen: true,
        },
        () => {
          this.fetchAssetTypes().then(assetTypes => {
            this.setState({
              assetTypes,
              isFetching: false,
            });
          });
        }
      );
    }
  };

  fetchAssetTypes = () => {
    let params = {
      no_pagination: true,
    };

    return API.listAssetTypes(this.props.currentSystem.id, params)
      .then(({ data: assetTypes, headers }) => {
        const { entities, result } = normalizeAssetType(assetTypes);
        this.props.updateEntities(entities);
        return result.map(id => entities.assetTypeById[id]);
      })
      .catch(() => {});
  };

  selectAssetType = assetTypeId => {
    if (this.props.multiple) {
      if (this.props.selectedAssetTypeIds.includes(assetTypeId)) {
        this.props.onRemoveAssetType(assetTypeId);
      } else {
        this.props.onAddAssetType(assetTypeId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectAssetType(assetTypeId);
        }
      );
    }
  };

  renderSearchEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="components.asset-type-inline-modal.list.empty-data-set.title" />}
      subtitle={<FormattedMessage id="components.asset-type-inline-modal.list.empty-data-set.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderSearchField = () => (
    <NewInlineModal.Header.Search
      ref={ref => (this.inlineModalSearchRef = ref)}
      placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
      value={this.state.searchTerm}
      onChange={searchTerm => this.setState({ searchTerm })}
      onClear={() => this.setState({ searchTerm: '' })}
    />
  );

  renderListItem = assetType => {
    if (this.props.multiple) {
      const selected = this.props.selectedAssetTypeIds.includes(assetType.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={assetType.id}
          onClick={e => this.selectAssetType(assetType.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectAssetType(assetType.id);
              }}
            />
          }
        >
          {assetType.title}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedAssetTypeId === assetType.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={assetType.id}
          selected={selected}
          onClick={e => this.selectAssetType(assetType.id)}
        >
          {assetType.title}
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderList = () => {
    const filteredAssetTypes = this.state.assetTypes.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    if (this.state.assetTypes.length === 0) {
      return this.renderEmptyDataset();
    }
    if (this.state.searchTerm.length > 0 && filteredAssetTypes.length === 0) {
      return this.renderSearchEmptyDataset();
    }
    return filteredAssetTypes.map(assetType => {
      return this.renderListItem(assetType);
    });
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          {this.renderSearchField()}
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    return (
      <>
        <NewInlineModal.Dropdown>
          {this.renderSearchField()}
          <NewInlineModal.Dropdown.Items>{this.renderList()}</NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetTypeInlineModal));

AssetTypeInlineModal.propTypes = {
  selectedAssetTypeId: PropTypes.string,
  selectedAssetTypeIds: PropTypes.array,
  onSelectAssetType: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
};

AssetTypeInlineModal.defaultProps = {
  selectedAssetTypeId: null,
  selectedAssetTypeIds: [],
  onSelectAssetType: () => {},
  onAddAssetType: () => {},
  onRemoveAssetType: () => {},
  onClear: () => {},
  multiple: false,
};
