import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage, Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field } from 'views/components/Shared/General';

class SparePartLocationModal extends Component {
  inputRef = null;

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      value: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      if (this.props.sparePartLocation) {
        const newState = {
          value: this.props.sparePartLocation.title,
        };
        this.setState(newState, () => {
          this.inputRef.select();
        });
      } else {
        const newState = {
          value: this.props.intl.formatMessage({ id: 'components.spare-part-location-modal.default-value' }),
        };
        this.setState(newState, () => {
          this.inputRef.select();
        });
      }
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.value) {
      this.save();
    }
  };

  save = () => {
    if (this.props.sparePartLocation) {
      this.updateSparePartLocation();
    } else {
      this.createSparePartLocation();
    }
  };

  createSparePartLocation = () => {
    this.setState({ isSaving: true });

    const params = {
      title: this.state.value,
      spare_part_location_parent_id: this.props.parentSparePartLocationId,
    };

    this.props
      .createSparePartLocation(this.props.currentSystem.id, params)
      .then(({ data: sparePartLocation }) => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="screens.settings.spare-parts.storage.created-success-message"
                values={{ title: this.state.value }}
              />
            }
          />
        );
        this.setState({ value: '', isSaving: false });
        this.props.onCreate(sparePartLocation.id);
      });
  };

  updateSparePartLocation = () => {
    this.setState({ isSaving: true });

    const params = {
      title: this.state.value,
    };

    this.props.updateSparePartLocation(this.props.sparePartLocation.id, params).then(() => {
      toast(
        <ToastMessage
          success
          text={
            <FormattedMessage
              id="screens.settings.spare-parts.storage.updated-success-message"
              values={{ title: this.state.value }}
            />
          }
        />
      );
      this.setState({ isSaving: false });
      this.props.onClose();
    });
  };

  renderHeaderTitle = () => (
    <FormattedMessage
      id={
        this.props.sparePartLocation
          ? 'components.spare-part-location-modal.title-edit'
          : 'components.spare-part-location-modal.title-new'
      }
    />
  );

  renderContent = () => (
    <Field.Text
      ref={ref => (this.inputRef = ref)}
      value={this.state.value}
      onChange={value =>
        this.setState({
          value,
        })
      }
      onKeyPress={this.handleKeyPress}
    />
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width="440">
        <Modal.Header title={this.renderHeaderTitle()} onClose={this.props.onClose} />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button
            primary
            disabled={!this.state.value}
            label="general.save"
            loading={this.state.isSaving}
            onClick={this.save}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartLocation: SDKReduxOperations.createSparePartLocation,
      updateSparePartLocation: SDKReduxOperations.updateSparePartLocation,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartLocationModal));
