import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { API } from 'sdk';
import { normalizeAsset } from 'sdk/Schemas';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import AnimateHeight from 'react-animate-height';
import {
  EmptyDataSet,
  Field,
  NewSearchField,
  PathItem,
  Tooltip,
  Loader,
  Icon,
  List,
} from 'views/components/Shared/General';
import { AssetTypeInlineModal, TreePath } from 'views/components/Asset';
import TreeContent from './TreeContent';
import SelectedAssetTypeContainer from './SelectedAssetTypeContainer';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import styles from './style.module.scss';

class SelectAssetContainer extends Component {
  expandedIds = {};

  state = {
    showAdvancedSearch: false,
    isFechingSearch: false,
    isFechingMoreSearch: false,
    searchAssets: [],
    searchPaginateFrom: null,
    searchTotalEntries: null,
    searchIsFullyLoaded: null,
    filters: {
      search: '',
      asset_type_id: null,
    },
  };

  fetchSearchAssets = () => {
    this.setState({ isFechingSearch: true });
    const params = {
      ...this.state.filters,
      archived: false,
      page_size: 20,
      sort: 'title',
      'sort-order': 'asc',
    };

    API.listAssets(this.props.currentSystem.id, params)
      .then(res => {
        const { entities, result } = normalizeAsset(res.data);
        this.props.updateEntities(entities);

        this.setState({
          searchAssets: result.map(assetId => entities.assetById[assetId]),
          searchPaginateFrom: res.headers['paginate-from-token'],
          searchIsFullyLoaded: res.data.length < 20,
          isFechingSearch: false,
        });
      })
      .catch(() => {});
  };

  fetchMoreSearchAssets = () => {
    if (!this.isSearching()) return null;
    if (this.state.isFechingMoreSearch || this.state.searchIsFullyLoaded) return null;

    const params = {
      ...this.state.filters,
      page_size: 20,
      archived: false,
      sort: 'title',
      'sort-order': 'asc',
      paginate_from: this.state.searchPaginateFrom,
    };

    this.setState({ isFechingMoreSearch: true });
    API.listAssets(this.props.currentSystem.id, params)
      .then(res => {
        const { entities, result } = normalizeAsset(res.data);
        this.props.updateEntities(entities);

        this.setState({
          searchAssets: [...this.state.searchAssets, ...result.map(assetId => entities.assetById[assetId])],
          searchPaginateFrom: res.headers['paginate-from-token'],
          searchIsFullyLoaded: res.data.length < 20,
          isFechingMoreSearch: false,
        });
      })
      .catch(() => {});
  };

  isSearching = () => {
    return this.state.filters.search.length > 0 || this.state.filters.asset_type_id != null;
  };

  renderSearchField = () => (
    <div className={styles['search-container']}>
      <div className={styles['search-field']}>
        <NewSearchField
          isShowingMoreFilters={this.state.showAdvancedSearch}
          value={this.state.filters.search}
          placeholder={this.props.intl.formatMessage({
            id: 'components.file-add-related-assets.search-field-placeholder',
          })}
          debounce
          showMoreFiltersButton
          onSearch={value => {
            this.setState({
              isFechingSearch: true,
              filters: { ...this.state.filters, search: value },
            });
          }}
          onDebouncedSearch={value => {
            this.fetchSearchAssets();
          }}
          onClear={() => {
            this.setState(
              {
                isFechingSearch: false,
                filters: { ...this.state.filters, search: '' },
              },
              () => this.fetchSearchAssets()
            );
          }}
          onClickMoreFilters={() => {
            const { q, asset_type_id } = this.state.filters;
            let resetSearch = this.state.showAdvancedSearch && q.length === 0;
            this.setState(prevState => ({
              showAdvancedSearch: !prevState.showAdvancedSearch,
              filters: {
                ...prevState.filters,
                asset_type_id: resetSearch ? null : prevState.filters.asset_type_id,
              },
            }));
          }}
        />
      </div>

      <AnimateHeight duration={250} height={this.state.showAdvancedSearch ? 'auto' : 0}>
        <div className={styles['advanced-search']}>
          <AssetTypeInlineModal
            trigger={
              <div className={styles['inline-modal-trigger']}>
                <Field edit label={<FormattedMessage id="resources.asset.type" />}>
                  <Field.Resource
                    icon={<Icon type="box-alt" />}
                    value={
                      this.state.filters.asset_type_id == null ? null : (
                        <SelectedAssetTypeContainer id={this.state.filters.asset_type_id} />
                      )
                    }
                    onClear={() => {
                      this.setState(
                        {
                          filters: {
                            ...this.state.filters,
                            asset_type_id: null,
                          },
                        },
                        () => this.fetchSearchAssets()
                      );
                    }}
                  />
                </Field>
              </div>
            }
            selectedAssetTypeId={this.state.filters.asset_type_id}
            editable={false}
            onSelectAssetType={assetTypeId => {
              this.setState(
                {
                  filters: {
                    ...this.state.filters,
                    asset_type_id: assetTypeId,
                  },
                },
                () => this.fetchSearchAssets()
              );
            }}
            onClearAssetType={() =>
              this.setState(
                {
                  filters: { ...this.state.filters, asset_type_id: null },
                },
                () => this.fetchSearchAssets()
              )
            }
          />
        </div>
      </AnimateHeight>
    </div>
  );

  renderSearchedAssetListItem = asset => {
    const disabled = this.props.inactiveAssetObjectIds[asset.id] === true;
    const selected = this.props.selectedAssetObjectIds[asset.id] === true;
    return (
      <List light usedInWhiteCard>
        <List.Item clickable={!disabled} onClick={() => this.props.onSelectAsset(asset.id)}>
          <List.Item.IconColumn
            icon={selected ? <Icon regular green type="check" /> : <Icon regular type="box" />}
          />
          <div className={`${styles['search-item']} ${disabled ? styles['disabled'] : ''}`}>
            <p className={styles['title']}>
              {this.props.settings.asset_number_activated ? <span>#{asset.number} - </span> : null}
              <span>{asset.title}</span>
            </p>
            <p className={styles['subtitle']}>
              <TreePath assetId={asset.id} />
            </p>
          </div>
          {disabled ? (
            <Tooltip
              label={
                <FormattedMessage id="components.file-add-related-assets.already-selected-popup-content" />
              }
              trigger={
                <div className={styles['disabled-container-icon']}>
                  <Icon regular type="info-circle" />
                </div>
              }
            />
          ) : null}
        </List.Item>
      </List>
    );
  };

  renderSearchedAssets = () => {
    if (this.state.isFechingSearch) {
      return (
        <div className={styles['search-loader-container']}>
          <Loader small />
        </div>
      );
    } else if (this.state.searchAssets.length === 0) {
      return (
        <div className={styles['searched-empty-data-set']}>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchImage}
            tiny
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className={styles['search-result-title']}>
            <PathItem small active>
              <FormattedMessage id="general.search-result" />
            </PathItem>
          </div>
          <div className={styles['list']}>
            {this.state.searchAssets.map(asset => this.renderSearchedAssetListItem(asset))}
          </div>
          {this.state.isFechingMoreSearch ? (
            <div className={styles['search-loader-container']}>
              <Loader small />
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  };

  renderListContent = () => {
    return (
      <InfiniteScroll
        loadMore={this.fetchMoreSearchAssets}
        hasMore={!this.state.searchIsFullyLoaded}
        useWindow={false}
        initialLoad={false}
        threshold={350}
      >
        {this.renderSearchedAssets()}
      </InfiniteScroll>
    );
  };

  renderTreeContent = () => {
    return (
      <TreeContent
        expandedIds={this.expandedIds}
        inactiveAssetObjectIds={this.props.inactiveAssetObjectIds}
        selectedAssetObjectIds={this.props.selectedAssetObjectIds}
        onSelectAsset={this.props.onSelectAsset}
        onExpand={nodeId => (this.expandedIds[nodeId] = true)}
        onCollapse={nodeId => delete this.expandedIds[nodeId]}
      />
    );
  };

  render() {
    return (
      <PerfectScrollbar>
        {this.renderSearchField()}
        {this.isSearching() ? this.renderListContent() : this.renderTreeContent()}
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectAssetContainer));

SelectAssetContainer.propTypes = {
  selectedAssetObjectIds: PropTypes.object,
  inactiveAssetObjectIds: PropTypes.object,
};

SelectAssetContainer.defaultProps = {
  selectedAssetIds: {},
  inactiveAssetIds: {},
};
