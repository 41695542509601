import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage, Grid } from 'views/components/Shared/Layout';
import { connect } from 'react-redux';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import { EntitySelectors } from 'sdk/State/entities';
import {
  Field,
  Button,
  FileUploadWrapper,
  InlineModal,
  FullScreenImagePicker,
  FieldErrorWrapper,
  DatePicker,
  Icon,
} from 'views/components/Shared/General';
import {
  NewAttachmentModal,
  AttachmentOptionsInlineModal,
  AttachmentItem,
} from 'views/components/Attachment';
import { AuthSelectors } from 'state/ducks/auth';
import { Image, Loader } from 'views/components/Image';
import { SDKReduxOperations } from 'sdk';
import { ChooseUserInlineModal, UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';

class CompleteWorkOrderContent extends Component {
  constructor(props) {
    super(props);

    const { workOrder } = props;
    this.state = {
      userInlineModalIsOpen: false,
      showNewFileInlineModalOptions: false,
      showNewAttachmentModalForType: null,
      completed_comment: workOrder.completed_comment,
      deletingImageIds: {},
      uploadingCount: 0,
      showingImageDialog: false,
      currentImage: 0,
    };
  }

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  createCompletionImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createCompletionImageForWorkOrder(this.props.workOrder.id, {
        image: file,
        mime_type,
        name,
      })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.add-image-success" />} />);
      })
      .catch(e => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  deleteCompletionImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.delete-image-success" />} />);
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  renderAttachmentsContainer = () => {
    const { completionAttachments, uploadingAttachments } = this.props;
    return (
      <Field label={<FormattedMessage id="resources.work-order.files" />}>
        {completionAttachments.length === 0 && uploadingAttachments.length === 0 ? null : (
          <AttachmentItem.Container>
            {completionAttachments.map(attachment => {
              return (
                <AttachmentItem
                  key={attachment.id}
                  attachment={attachment}
                  buttons={
                    <AttachmentOptionsInlineModal
                      type="workOrder"
                      attachment={attachment}
                      isDeletingAttachment={this.props.isDeletingAttachment}
                      onDeleteAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                      trigger={
                        <div className={styles['add-button-container']}>
                          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
                        </div>
                      }
                    />
                  }
                />
              );
            })}
            {uploadingAttachments.map(attachment => (
              <AttachmentItem
                namespace={this.props.workOrder.id}
                uploading
                key={attachment.id}
                attachment={attachment}
              />
            ))}
          </AttachmentItem.Container>
        )}
        <div
          className={styles['add-button-container']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() =>
            this.setState(prevState => ({
              showNewFileInlineModalOptions: !prevState.showNewFileInlineModalOptions,
            }))
          }
        >
          <Button type="text" label="general.add" primary noUnderline />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showNewFileInlineModalOptions}
          onClose={() => this.setState({ showNewFileInlineModalOptions: false })}
        >
          <React.Fragment>
            <InlineModal.Body width={250} dropdown>
              <InlineModal.ListItem
                icon="upload"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'file',
                  });
                }}
              />
              <InlineModal.ListItem
                icon="link"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'link',
                  });
                }}
              />
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </Field>
    );
  };

  renderImages = () => {
    if (this.props.completionImages == null) {
      return null;
    }
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader />
    );
    return (
      <React.Fragment>
        {loaderElements}
        {this.props.completionImages.map((image, index) => {
          return (
            <Image
              key={image.id}
              medium
              isDeleting={this.state.deletingImageIds[image.id] != null}
              editable
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
              onDelete={() => {
                this.deleteCompletionImage(image);
              }}
            />
          );
        })}
      </React.Fragment>
    );
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.createCompletionImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderAttachmentsAndImages = () => {
    if (this.props.workOrder.operational_maintenance) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column md={6}>{this.renderAttachmentsContainer()}</Grid.Column>
        <Grid.Column md={6}>{this.renderImagesContainer()}</Grid.Column>
      </Grid.Row>
    );
  };

  renderCompletedByUser = () => {
    if (this.props.isOperator) {
      return (
        <Field.Resource
          disabled
          clearable={false}
          focus={this.state.userInlineModalIsOpen}
          value={<UserNameWrapper user={this.props.completedByUser} />}
          onChange={completed_by_user_id => this.props.onChangeValue({ completed_by_user_id })}
          onClear={() => this.props.onChangeValue({ completed_by_user_id: null })}
        />
      );
    }
    return (
      <ChooseUserInlineModal
        hideArchived={this.props.hideArchivedUsers}
        members={this.props.workOrder.operational_maintenance === false}
        trigger={
          <Field.Resource
            clearable={false}
            focus={this.state.userInlineModalIsOpen}
            value={<UserNameWrapper user={this.props.completedByUser} />}
            onChange={completed_by_user_id => this.props.onChangeValue({ completed_by_user_id })}
            onClear={() => this.props.onChangeValue({ completed_by_user_id: null })}
          />
        }
        onOpen={() => this.setState({ userInlineModalIsOpen: true })}
        onClose={() => this.setState({ userInlineModalIsOpen: false })}
        selectedUserId={this.props.workOrder.completed_by_user_id}
        onSelectUser={id => this.props.onChangeValue({ completed_by_user_id: id })}
        clearable={false}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column md={6}>
              <Field label={<FormattedMessage id="resources.work-order.completed-at" />}>
                <FieldErrorWrapper
                  show={this.props.showCompletedDateIsRequiredError}
                  errorElement={<FormattedMessage id="general.errors.is-required" />}
                >
                  <Field.Date
                    error={this.props.showCompletedDateIsRequiredError}
                    value={this.props.workOrder.completed_date}
                    onChangeDate={completed_date => {
                      if (!completed_date) {
                        this.props.onShowCompletedDateError();
                      } else if (completed_date && this.props.showCompletedDateIsRequiredError) {
                        this.props.onHideCompletedDateError();
                      }
                      this.props.onChangeValue({ completed_date });
                    }}
                    footerComponent={
                      <DatePicker.Footer
                        showClear={this.props.workOrder.completed_date != null}
                        onClear={() => {
                          this.props.onChangeValue({ completed_date: null });
                          this.props.onShowCompletedDateError();
                        }}
                      />
                    }
                  />
                </FieldErrorWrapper>
              </Field>
            </Grid.Column>
            <Grid.Column md={6}>
              <Field label={<FormattedMessage id="resources.work-order.completed-by" />}>
                {this.renderCompletedByUser()}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.completed-comment" />}>
                <Field.Textarea
                  value={this.state.completed_comment}
                  minRows={3}
                  onChange={value => this.setState({ completed_comment: value })}
                  onBlur={() => this.props.onChangeValue({ completed_comment: this.state.completed_comment })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          {this.renderAttachmentsAndImages()}
        </Grid>
        <NewAttachmentModal
          isOpen={this.state.showNewAttachmentModalForType != null}
          type={this.state.showNewAttachmentModalForType}
          onClose={() => this.setState({ showNewAttachmentModalForType: null })}
          onCreateNew={params => this.props.onCreateAttachment(params)}
        />
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.completionImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCompletionImageForWorkOrder: SDKReduxOperations.createCompletionImageForWorkOrder,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.workOrder.asset_id),
    completionAttachments: EntitySelectors.getAttachments(state, ownProps.workOrder.completion_attachments),
    completionImages: EntitySelectors.getImages(state, ownProps.workOrder.completion_images),
    completedByUser: EntitySelectors.getUser(state, ownProps.workOrder.completed_by_user_id),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, ownProps.workOrder.id),
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CompleteWorkOrderContent));

CompleteWorkOrderContent.propTypes = {
  hideArchivedUsers: PropTypes.bool,
};

CompleteWorkOrderContent.defaultProps = {
  hideArchivedUsers: false,
};
