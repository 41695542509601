import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { API } from 'sdk';
import { SparePartsSelectors } from 'state/ducks/spareParts';
import { PrintModal as GeneralPrintModal } from 'views/components/General';

class PrintModal extends Component {
  getInitialState = () => ({
    printMode: this.props.exportType || ExportType.SparePartsListPdf,
    isPrinting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
    exclude_assets: false,
    exclude_vendors: false,
    singleSparePartId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.checkedSparePartCount >= maxQuantityAllowedForType(this.state.printMode),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.singleSparePartId) {
      return { id: this.props.singleSparePartId };
    }
    const {
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    } = queryString.parse(this.props.location.search);
    if (this.props.totalEntriesIsSelected) {
      return {
        ...{
          asset_with_tree_children_id,
          spare_part_location_with_children_id,
          spare_part_type_with_children_id,
        },
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
      };
    }

    const sort = this.props.queryParameters['sort'];
    const sortOrder = this.props.queryParameters['sort-order'];
    let params = {
      id: {
        any: Object.keys(this.props.checkedSparePartIds).toString(),
      },
    };
    if (sort) {
      params = {
        ...params,
        sort,
      };
    }
    if (sortOrder) {
      params = {
        ...params,
        'sort-order': sortOrder,
      };
    }
    return params;
  };

  print = () => {
    this.setState({ isPrinting: true });
    let params = this.buildFilterParams();
    if (this.state.printMode == ExportType.SparePartsDetailPdf) {
      params = {
        ...params,
        exclude_assets: this.state.exclude_assets,
        exclude_vendors: this.state.exclude_vendors,
      };
    }
    API.createExport(this.props.system.id, {
      params: params,
      type: this.state.printMode,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedSparePartCount}
        one={<FormattedMessage id="components.export-modal.total-spare-parts.one" />}
        two={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.two"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.few"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.many"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.export-modal.total-spare-parts.other"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
      />
    );
  };

  renderMaxQuantityErrorMessage = () => {
    if (!this.state.showQuantityExceeded) return null;
    return <FormattedMessage id="components.export-modal.pdf-quantity-exceeded" />;
  };

  renderTitle = () => {
    return <FormattedMessage id="components.export-modal.title-print" />;
  };

  renderDetailPrintOptions = () => {
    return (
      <Field.Checkbox.Group>
        <Field.Checkbox
          checked
          label={<FormattedMessage id="components.print-spare-part-modal.info" />}
          disabled
        />
        <Field.Checkbox
          checked={!this.state.exclude_assets}
          label={<FormattedMessage id="components.print-spare-part-modal.assets" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_assets: !prevState.exclude_assets,
            }));
          }}
        />
        {this.props.canViewVendors ? (
          <Field.Checkbox
            checked={!this.state.exclude_vendors}
            label={<FormattedMessage id="components.print-spare-part-modal.vendors" />}
            onChange={() => {
              this.setState(prevState => ({
                exclude_vendors: !prevState.exclude_vendors,
              }));
            }}
          />
        ) : null}
      </Field.Checkbox.Group>
    );
  };

  render() {
    return (
      <GeneralPrintModal
        open={this.props.open}
        list={this.state.printMode == ExportType.SparePartsListPdf}
        detail={this.state.printMode == ExportType.SparePartsDetailPdf}
        detailOnly={this.props.singleSparePartId != null}
        onSelectList={() => this.setState({ printMode: ExportType.SparePartsListPdf })}
        onSelectDetail={() => this.setState({ printMode: ExportType.SparePartsDetailPdf })}
        onClose={this.props.onClose}
        title={this.renderTitle()}
        subtitle={this.renderSubtitle()}
        onPrint={this.print}
        detailOptions={this.renderDetailPrintOptions()}
        loadingForExportId={this.state.showExportLoaderForId}
        loading={this.state.isPrinting}
        warning={this.renderMaxQuantityErrorMessage()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    queryParameters: SparePartsSelectors.getQueryParameters(state),
    filtersAsQueryParams: SparePartsSelectors.getFiltersAsQueryParams(state),
    checkedSparePartIds: SparePartsSelectors.getSelectedSparePartIds(state),
    checkedSparePartCount: SparePartsSelectors.getSelectedSparePartCount(state),
    totalEntriesIsSelected: SparePartsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default withRouter(connect(mapStateToProps)(PrintModal));

PrintModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.SparePartsDetailPdf, ExportType.SparePartsListPdf]),
  singleSparePartId: PropTypes.string,
};

PrintModal.defaultProps = {
  exportType: null,
  singleSparePartId: null,
};
