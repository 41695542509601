import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { SpentTimeModal } from 'views/components/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import Person75 from 'assets/images/EmptyDataSet/Person75.png';
import SpentTimeListItem from './SpentTimeListItem';
import styles from './style.module.scss';

class RegisteredTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showWorkOrderSpentTimeModal: false,
      selectedWorkOrderSpentTimeId: null,
    };
  }

  newWorkOrderSpentTime = () => {
    this.setState({ showWorkOrderSpentTimeModal: true, selectedWorkOrderSpentTimeId: null });
  };

  openWorkOrderSpentTime = id => {
    this.setState({ showWorkOrderSpentTimeModal: true, selectedWorkOrderSpentTimeId: id });
  };

  renderWorkOrderSpentTimeModal = () => (
    <SpentTimeModal
      id={this.state.selectedWorkOrderSpentTimeId}
      workOrderId={this.props.workOrder.id}
      open={this.state.showWorkOrderSpentTimeModal}
      onSaved={({ id }) => {
        this.setState({
          showWorkOrderSpentTimeModal: false,
          selectedWorkOrderSpentTimeId: null,
        });
      }}
      onClose={() => {
        this.setState({ showWorkOrderSpentTimeModal: false });
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard padding="38px">
      <EmptyDataSet
        title={<FormattedMessage id="screens.work-order.spent-times.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.work-order.spent-times.empty-data-set.subtitle" />}
        image={Person75}
        button={
          this.props.canCarryOutWorkOrders ? (
            <Button
              small
              primary
              onClick={this.newWorkOrderSpentTime}
              label="screens.work-order.spent-times.empty-data-set.button"
            />
          ) : null
        }
        micro
        horizontal
        centered={false}
      />
    </WhiteCard>
  );

  renderContentWithHeader = content => (
    <WhiteCard noPadding>
      <div className={styles['header']}>
        <div className={styles['title-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.spent-times.title" />
          </p>
        </div>
        {this.props.canCarryOutWorkOrders ? (
          <Button
            small
            primary
            label="screens.work-order.spent-times.button"
            onClick={this.newWorkOrderSpentTime}
          />
        ) : null}
      </div>
      {content}
    </WhiteCard>
  );

  renderList = () => {
    return this.renderContentWithHeader(
      <List light usedInWhiteCard>
        {this.props.workOrderSpentTimes.map(workOrderSpentTime => (
          <SpentTimeListItem
            key={workOrderSpentTime.id}
            workOrderSpentTime={workOrderSpentTime}
            onClick={() => {
              this.openWorkOrderSpentTime(workOrderSpentTime.id);
            }}
          />
        ))}
      </List>
    );
  };

  renderContent = () => {
    if (this.props.workOrderSpentTimes == null) {
      return this.renderContentWithHeader(
        <List light usedInWhiteCard>
          <SpentTimeListItem loading />
        </List>
      );
    }
    if (this.props.workOrderSpentTimes.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderList();
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}
        {this.renderWorkOrderSpentTimeModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    workOrderSpentTimes: EntitySelectors.getWorkOrderSpentTimes(
      state,
      ownProps.workOrder.work_order_spent_times
    ),
  };
}

export default connect(mapStateToProps)(injectIntl(RegisteredTime));
