import uuid from 'uuid';
import moment from 'moment';
import { mergeWith, cloneDeep } from 'lodash-es';

export const RequestStatus = {
  Active: 'active',
  WorkOrderCreated: 'work_order_created',
  Archived: 'archived',
  Completed: 'work_order_completed',
  AwaitingApproval: 'awaiting_production_supervisor_approval',
};

export const LIST_TYPES = {
  ToApprove: 'to_approve',
  Open: 'open',
  Closed: 'closed',
  ToPlan: 'to_plan',
  WorkOrderCreated: 'work_order_created',
  WorkOrderCompleted: 'work_order_completed',
  CreatedForAsset: 'created_for_asset',
  Archive: 'archived',
  MyCreated: 'my_created',
  All: 'all',
};

export const GROUP_BY_TYPES = {
  RequestCreatedDate: 'requestCreatedDate',
  RequestPriority: 'requestPriority',
  WorkOrdeDueDate: 'workOrderDueDate',
  WorkOrderCompletedDate: 'workOrderCompletedDate',
  RequestArchivedDate: 'requestArchivedDate',
};

export function generateEmptyRequest() {
  return {
    id: uuid.v4(),
    images: [],
    title: '',
    description: '',
    priority: null,
    request_type_id: null,
    asset_id: null,
  };
}

export const REPORT_TYPES = {
  Count: 'count',
};
export const REPORT_PER_TYPES = {
  Year: 'year',
  Month: 'month',
  Asset: 'asset',
  User: 'created_by_user',
  Priority: 'priority',
  RequestType: 'request_type',
};

export function getColorForStatus(status) {
  switch (status) {
    case RequestStatus.Active:
      return '#939393';
    case RequestStatus.WorkOrderCreated:
      return '#EF9028';
    case RequestStatus.Archived:
    case RequestStatus.AwaitingApproval:
      return '#C55050';
    case RequestStatus.Completed:
      return '#34AD35';
    default:
      return null;
  }
}

export function groupRequestsByPriority(requestIds, entities) {
  const data = {};
  requestIds.forEach(requestId => {
    const request = entities.requestById[requestId];
    if (request.priority === 'high') {
      data.high = data.high ? [...data.high, requestId] : [requestId];
    } else if (request.priority === 'medium') {
      data.medium = data.medium ? [...data.medium, requestId] : [requestId];
    } else if (request.priority === 'low') {
      data.low = data.low ? [...data.low, requestId] : [requestId];
    } else {
      data.none = data.none ? [...data.none, requestId] : [requestId];
    }
  });
  return data;
}

export function groupRequestsByArchivedDate(requestIds, entities) {
  const data = {};
  requestIds.forEach(requestId => {
    const request = entities.requestById[requestId];
    const firstDayOfWeek = moment(request.archived_at).isoWeekday(1).format('YYYY-MM-DD');
    data[firstDayOfWeek] = data[firstDayOfWeek] ? [...data[firstDayOfWeek], requestId] : [requestId];
  });
  return data;
}

export function groupRequestsByWorkOrderDueDate(requestIds, entities) {
  const data = {};
  requestIds.forEach(requestId => {
    const request = entities.requestById[requestId];
    const workOrder = entities.workOrderById[request.work_order_id];
    if (workOrder.due_date == null) {
      data.none = data.none ? [...data.none, requestId] : [requestId];
    } else {
      const firstDayOfWeek = moment(workOrder.due_date).isoWeekday(1).format('YYYY-MM-DD');
      data[firstDayOfWeek] = data[firstDayOfWeek] ? [...data[firstDayOfWeek], requestId] : [requestId];
    }
  });
  return data;
}

export function groupRequestsByWorkOrderCompletedDate(requestIds, entities) {
  const data = {};
  requestIds.forEach(requestId => {
    const request = entities.requestById[requestId];
    const workOrder = entities.workOrderById[request.work_order_id];
    const firstDayOfWeek = moment(workOrder.completed_date).isoWeekday(1).format('YYYY-MM-DD');
    data[firstDayOfWeek] = data[firstDayOfWeek] ? [...data[firstDayOfWeek], requestId] : [requestId];
  });
  return data;
}

export function groupRequestsByCreatedDate(requestIds, entities) {
  const data = {};
  requestIds.forEach(requestId => {
    const request = entities.requestById[requestId];
    const firstDayOfWeek = moment(request.created_at).isoWeekday(1).format('YYYY-MM-DD');
    data[firstDayOfWeek] = data[firstDayOfWeek] ? [...data[firstDayOfWeek], requestId] : [requestId];
  });
  return data;
}

export function mergeOldInboxSectionsWithNewInboxSections(oldData, newData) {
  return mergeWith(cloneDeep(oldData), newData, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return [...objValue, ...srcValue];
    }
  });
}

export function getGroupByFromList(list) {
  switch (list) {
    case LIST_TYPES.WorkOrderCreated: {
      return GROUP_BY_TYPES.WorkOrdeDueDate;
    }
    case LIST_TYPES.WorkOrderCompleted: {
      return GROUP_BY_TYPES.WorkOrderCompletedDate;
    }
    case LIST_TYPES.Archive: {
      return GROUP_BY_TYPES.RequestArchivedDate;
    }
    default: {
      return GROUP_BY_TYPES.RequestCreatedDate;
    }
  }
}

export function getParamsForList(list, currentUserId) {
  switch (list) {
    case LIST_TYPES.ToApprove: {
      return {
        status: 'awaiting_production_supervisor_approval',
        sort: 'created_at',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.Open: {
      return {
        status: 'awaiting_production_supervisor_approval,active,work_order_created',
        sort: 'created_at',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.Closed: {
      return {
        status: 'work_order_completed,archived',
        sort: 'created_at',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.ToPlan: {
      return {
        status: 'active',
        sort: 'created_at',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.WorkOrderCreated: {
      return {
        status: 'work_order_created',
        sort: 'work_order_due_date',
        'sort-order': 'asc',
      };
    }
    case LIST_TYPES.WorkOrderCompleted: {
      return {
        status: 'work_order_completed',
        sort: 'work_order_completed_date',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.Archive: {
      return {
        status: 'archived',
        sort: 'archived_at',
        'sort-order': 'desc',
      };
    }
    case LIST_TYPES.MyCreated: {
      return {
        created_by_user_id: currentUserId,
        sort: 'created_at',
        'sort-order': 'desc',
      };
    }

    default:
      return {
        sort: 'created_at',
        'sort-order': 'desc',
      };
  }
}
