import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { CardGrid, ToastMessage } from 'views/components/Shared/Layout';
import { List, SectionHeader } from 'views/components/Shared/General';
import toast from 'react-hot-toast';
import { ChecklistModal } from 'views/components/Checklist';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { normalizeChecklistInstance, normalizeWorkOrder } from 'sdk/Schemas';
import ChecklistInstanceListItem from './ChecklistInstanceListItem';
import ChecklistTemplateLinkItem from './ChecklistTemplateLinkItem';

class Checklist extends Component {
  state = {
    showChecklistInstanceModal: false,
    showChecklistInstanceModalForId: null,
    showChecklistInstanceForTemplateLinkId: null,
  };

  fetchChecklistInstance = id => {
    API.fetchChecklistInstance(id).then(({ data: checklistInstance }) => {
      const { entities } = normalizeChecklistInstance(checklistInstance);
      this.props.updateEntities(entities);
    });
  };

  renderChecklistListItem = () => {
    const { checklistInstances, checklistTemplateLinks } = this.props;
    if (checklistInstances && checklistInstances.length > 0) {
      return (
        <>
          <CardGrid.Row>
            <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
              <FormattedMessage id="resources.checklist-instance.resource" />
            </SectionHeader>
            <List>
              {this.props.checklistInstances.map(checklistInstance => (
                <ChecklistInstanceListItem
                  checklistInstance={checklistInstance}
                  onClick={() => {
                    this.setState({
                      showChecklistInstanceModal: true,
                      showChecklistInstanceModalForId: checklistInstance.id,
                    });
                  }}
                />
              ))}
            </List>
          </CardGrid.Row>
        </>
      );
    }
    if (checklistTemplateLinks && checklistTemplateLinks.length > 0) {
      return (
        <>
          <CardGrid.Row>
            <SectionHeader paddingHorizontal={30} horizontalBorders noBorderBottom>
              <FormattedMessage id="resources.checklist-instance.resource" />
            </SectionHeader>
            <List>
              {this.props.checklistTemplateLinks.map(checklistTemplateLink => (
                <ChecklistTemplateLinkItem
                  checklistTemplateLink={checklistTemplateLink}
                  onClick={() => {
                    this.setState({
                      showChecklistInstanceModal: true,
                      showChecklistInstanceForTemplateLinkId: checklistTemplateLink.id,
                    });
                  }}
                />
              ))}
            </List>
          </CardGrid.Row>
        </>
      );
    }
  };

  render() {
    return (
      <>
        {this.renderChecklistListItem()}
        <ChecklistModal
          open={this.state.showChecklistInstanceModal}
          checklistInstanceId={this.state.showChecklistInstanceModalForId}
          checklistTemplateLinkId={this.state.showChecklistInstanceForTemplateLinkId}
          workOrder={this.props.workOrder}
          onClose={id => {
            this.setState({ showChecklistInstanceModal: false });
            if (id) {
              this.fetchChecklistInstance(id);
            }
          }}
          onUpdateVersion={() => {
            API.fetchWorkOrder(this.props.workOrder.id).then(({ data: workOrder }) => {
              const { entities } = normalizeWorkOrder(workOrder);
              this.props.updateEntities(entities);
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.setState({ showChecklistInstanceModal: false });
            });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistInstances: EntitySelectors.getChecklistInstances(state, ownProps.workOrder.checklist_instances),
    checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
      state,
      ownProps.workOrder.checklist_template_links
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
