import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'views/components/Shared/General';
import ContentLoader from 'react-content-loader';
import styles from './style.module.scss';

const Item = props => {
  const renderContent = () => <div className={styles['content']}>{props.children}</div>;

  const renderRightContainer = () => {
    if (!props.rightComponent) return null;
    return <div className={styles['right-container']}>{props.rightComponent}</div>;
  };

  const renderLeftContainer = () => {
    if (!props.leftComponent) return null;
    return <div className={styles['left-container']}>{props.leftComponent}</div>;
  };

  const renderExpandableIcon = () => {
    if (props.hasExpandableChildren) {
      let classNames = [styles['expandable-icon-container']];
      if (props.isExpanded) {
        classNames = [...classNames, styles['expanded']];
      }
      return (
        <div className={classNames.join(' ')}>
          <Button
            type="icon"
            icon={<Icon regular size={14} type="angle-right" />}
            onClick={e => {
              e.stopPropagation();
              props.onExpand();
            }}
          />
        </div>
      );
    }
    return null;
  };

  const renderExpandableComponent = () => {
    if (props.isExpanded && props.expandableComponent) {
      return <div>{props.expandableComponent}</div>;
    }
    return null;
  };

  const paddingLeft = () => {
    let paddingLeft = null;
    if (props.expandable) {
      paddingLeft = 0;
      paddingLeft = paddingLeft + props.expandableDepth * 15;
      if (props.expandableDepth > 0 && !props.hasExpandableChildren) {
        paddingLeft = paddingLeft + 25;
      }
    }
    return paddingLeft;
  };

  const classNames = () => {
    let classNames = [styles['list-item']];
    if (props.selected) {
      classNames = [...classNames, styles['selected']];
    }
    if (props.loading) {
      classNames = [...classNames, styles['loading']];
    }
    if (props.destructive) {
      classNames = [...classNames, styles['destructive']];
    }
    if (props.isExpanded) {
      classNames = [...classNames, styles['expanded']];
    }
    if (props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    return classNames;
  };

  if (props.loading) {
    return (
      <div className={classNames().join(' ')} onClick={props.onClick}>
        {renderLeftContainer()}
        <div style={{ width: 100, height: 14 }}>
          <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
            <rect x="0" y="0" rx="3" ry="3" width="100" height="12" />
          </ContentLoader>
        </div>
        {renderRightContainer()}
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames().join(' ')}
        onClick={props.onClick}
        onDoubleClick={props.onDoubleClick}
        style={{ paddingLeft: paddingLeft() }}
      >
        {renderExpandableIcon()}
        {renderLeftContainer()}
        {renderContent()}
        {renderRightContainer()}
      </div>
      {renderExpandableComponent()}
    </>
  );
};

export default Item;

Item.propTypes = {
  selected: PropTypes.bool,
  destructive: PropTypes.bool,
  onClick: PropTypes.func,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
  expandableComponent: PropTypes.element,
  expandable: PropTypes.bool,
  hasExpandableChildren: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  expandableDepth: PropTypes.number,
  paddingLeft: PropTypes.number,
  disabled: PropTypes.bool,
};

Item.defaultProps = {
  selected: false,
  destructive: false,
  disabled: false,
  leftComponent: null,
  rightComponent: null,
  onClick: () => {},
  onDoubleClick: () => {},
  onExpand: () => {},
  expandable: false,
  hasExpandableChildren: false,
  isExpanded: false,
  expandableComponent: null,
  expandableDepth: 1,
  paddingLeft: null,
};
