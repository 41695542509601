import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.operationalMaintenanceTemplateById) {
        return HelperFunctions.mergeEntities(state, action.payload.operationalMaintenanceTemplateById);
      }
      return state;
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_TEMPLATE_ASSSET_CREATED_FOR_TEMPLATE: {
      const { templateId, data } = action.payload;
      if (state[templateId]) {
        return {
          ...state,
          [templateId]: {
            ...state[templateId],
            operational_maintenance_template_assets: [
              data.id,
              ...state[templateId].operational_maintenance_template_assets,
            ],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_TEMPLATE_ASSSET_DELETED: {
      const { templateAssetId, data } = action.payload;
      const { operational_maintenance_template_id } = data;
      if (state[operational_maintenance_template_id]) {
        return {
          ...state,
          [operational_maintenance_template_id]: {
            ...state[operational_maintenance_template_id],
            operational_maintenance_template_assets: state[
              operational_maintenance_template_id
            ].operational_maintenance_template_assets.filter(id => id !== templateAssetId),
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};
