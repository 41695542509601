import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Decimal } from 'decimal.js';
import { List, Field } from 'views/components/Shared/General';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import SparePartWithdrawalListItem from './SparePartWithdrawalListItem';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import MoreOptionsInlineModal from './MoreOptionsInlineModal';

class SparePartListItem extends Component {
  renderExpandedComponent = () => {
    return (
      <div className={styles['expanded-container']}>
        <div className={styles['info']}>
          {this.renderEmptyRowText()}
          {this.renderFields()}
        </div>
        {this.renderSparePartWithdrawals()}
      </div>
    );
  };

  renderEmptyRowText = () => {
    const { sparePart } = this.props;

    if (sparePart) {
      return null;
    }

    return (
      <div className={styles['empty-row-text']}>
        <FormattedMessage id="screens.purchase-order.info.articles.spare-part-not-yet-created-info-text" />
      </div>
    );
  };

  renderSparePartReservation = () => {
    const { sparePartUnit, sparePartReservation } = this.props;

    if (this.props.sparePartReservation === null) {
      return null;
    }

    return (
      <Field
        view
        alignRight
        fontSize={12}
        label={<FormattedMessage id="screens.work-order.used-spare-parts.amount-reserved-label" />}
        singleRow
      >
        <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePartReservation.quantity} />
      </Field>
    );
  };

  renderSparePartWithdrawals = () => {
    const { sparePartWithdrawals, sparePartUnit } = this.props;

    if (sparePartWithdrawals.length === 0) {
      return null;
    }

    return (
      <div className={styles['withdrawals-container']}>
        <p className={styles['title']}>
          <FormattedMessage id="screens.work-order.used-spare-parts.withdrawals-title" />
        </p>
        <div className={styles['withdrawals']}>
          {sparePartWithdrawals.map((sparePartWithdrawal, key) => (
            <SparePartWithdrawalListItem
              key={key}
              sparePartUnit={sparePartUnit}
              sparePartWithdrawal={sparePartWithdrawal}
            />
          ))}
        </div>
      </div>
    );
  };

  renderFields = () => {
    const { sparePart, hasProTier, sparePartUnit } = this.props;

    if (sparePart === null) {
      return null;
    }

    if (hasProTier === false) {
      return (
        <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
          {sparePart.spare_part_location_id == null ? (
            '-'
          ) : (
            <SparePartLocationPath sparePartLocationId={sparePart.spare_part_location_id} />
          )}
        </Field>
      );
    }

    return (
      <>
        <Field view singleRow label={<FormattedMessage id="resources.spare-part.in-stock" />}>
          <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePart.stock_quantity} />
        </Field>
        <Field
          view
          singleRow
          label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 30 }} />}
        >
          <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePart.reserved_thirty_days} />
        </Field>
        <Field
          view
          singleRow
          label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 90 }} />}
        >
          <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePart.reserved_ninety_days} />
        </Field>
        <Field
          view
          singleRow
          label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 180 }} />}
        >
          <SparePartUnitWithValue
            sparePartUnit={sparePartUnit}
            value={sparePart.reserved_hundred_and_eighty_days}
          />
        </Field>
        <Field view singleRow label={<FormattedMessage id="resources.spare-part.ordered-quantity" />}>
          <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePart.ordered_quantity} />
        </Field>
        <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
          {sparePart.spare_part_location_id == null ? (
            '-'
          ) : (
            <SparePartLocationPath sparePartLocationId={sparePart.spare_part_location_id} />
          )}
        </Field>
      </>
    );
  };

  render() {
    const {
      loading,
      workOrderSparePart,
      images,
      sparePart,
      sparePartWithdrawals,
      sparePartUnit,
      onCreateSparePart,
    } = this.props;

    if (loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }

    return (
      <List.Item
        expandable
        expandedComponent={this.renderExpandedComponent()}
        iconButtons={
          <MoreOptionsInlineModal
            workOrderSparePart={workOrderSparePart}
            onCreateSparePart={sparePartWithdrawal => onCreateSparePart(sparePartWithdrawal)}
          />
        }
      >
        <List.Item.ImageColumn imageId={images.length === 0 ? null : images[0].id} />
        <List.Item.TitleColumn
          title={sparePart ? <Title sparePart={sparePart} /> : <>{sparePartWithdrawals[0].title}</>}
        />
        <List.Item.Column alignRight>
          {sparePartWithdrawals.length === 0 ? (
            <Field
              view
              alignRight
              fontSize={12}
              label={<FormattedMessage id="screens.work-order.used-spare-parts.amount-used-label" />}
              singleRow
            >
              <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={0} />
            </Field>
          ) : (
            <Field
              view
              alignRight
              fontSize={12}
              label={<FormattedMessage id="screens.work-order.used-spare-parts.amount-used-label" />}
              singleRow
            >
              <SparePartUnitWithValue
                sparePartUnit={sparePartUnit}
                value={sparePartWithdrawals.reduce(
                  (r, sparePartWithdrawal) => r.plus(new Decimal(sparePartWithdrawal.quantity)),
                  new Decimal(0)
                )}
              />
            </Field>
          )}
          {this.renderSparePartReservation()}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const { spare_part_id, spare_part_withdrawal_ids, spare_part_reservation_id } = ownProps.workOrderSparePart;
  let sparePart = EntitySelectors.getSparePart(state, spare_part_id);
  let sparePartUnit = null;
  let images = [];
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
    images = EntitySelectors.getImages(state, sparePart.images);
  } else {
    const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, spare_part_withdrawal_ids[0]);
    sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }

  return {
    sparePart,
    sparePartUnit,
    images,
    sparePartReservation: EntitySelectors.getSparePartReservation(state, spare_part_reservation_id),
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawal_ids) || [],
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(SparePartListItem);
