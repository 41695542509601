import actions from './actions';
import { normalizeAttachment } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API, HelperFunctions } from 'sdk';

const PAGE_SIZE = 20;

const fetchAttachments = (systemId, params) => dispatch => {
  return API.listAttachments(systemId, { ...params, page_size: PAGE_SIZE }).then(res => {
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    const { entities, result } = normalizeAttachment(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    dispatch(
      actions.fetchAttachmentsSuccess({
        initialLoad: params.paginate_from == null,
        ids: result,
        paginateFrom: res.headers['paginate-from-token'],
        totalEntries: pagination.totalEntries,
        isFullyLoaded: res.data.length < PAGE_SIZE,
      })
    );
  });
};

export default {
  fetchAttachments,
};
