import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class SelectedTreeAssetItem extends PureComponent {
  render() {
    return (
      <NewInlineModal.Dropdown.Item
        onClick={() => this.props.onBack(this.props.asset.tree_parent_id)}
        leftComponent={
          <div className={styles['go-back-button']}>
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-left" />}
              onClick={e => {
                e.stopPropagation();
                this.props.onBack(this.props.asset.tree_parent_id);
              }}
            />
          </div>
        }
      >
        <span className={styles['go-back-text']}>{this.props.asset.title}</span>
      </NewInlineModal.Dropdown.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedTreeAssetItem);
