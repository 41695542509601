import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Type } from 'sdk/ChecklistTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import { WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import Row from './Row';

class ChecklistTemplateRows extends Component {
  render() {
    if (this.props.rootRows.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="screens.checklist.no-rows-empty-data-set.title" />}
            subtitle={<FormattedMessage id="screens.checklist.no-rows-empty-data-set.subtitle" />}
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }

    return this.props.rootRows.map((checklistTemplateRow, index) => {
      return (
        <Row
          id={checklistTemplateRow.id}
          childRowIds={this.props.childRowIds}
          instanceRowForTemplateRowIds={this.props.instanceRowForTemplateRowIds}
          prevTemplateRowId={this.props.rootRowIds[index - 1]}
          nextTemplateRowId={this.props.rootRowIds[index + 1]}
        />
      );
    });
  }
}

function mapStateToProps(state, ownProps) {
  const { childRowIds, rootRowIds, instanceRowForTemplateRowIds } = ownProps;
  return {
    rootRows: EntitySelectors.getChecklistTemplateRows(state, rootRowIds).filter(checklistTemplateRow => {
      if (checklistTemplateRow.type === Type.Section) {
        return (
          childRowIds[checklistTemplateRow.id].filter(id => {
            return instanceRowForTemplateRowIds[id] != null;
          }).length > 0
        );
      }
      return instanceRowForTemplateRowIds[checklistTemplateRow.id] != null;
    }),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateRows);
