import React, { Component } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import WorkOrder from './WorkOrder';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { API, SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

const dropTarget = {
  drop(props, monitor, component) {
    const { date: droppedInDate } = props;
    const {
      date: draggedFromDate,
      instanceId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
    } = monitor.getItem();
    if (droppedInDate === draggedFromDate) {
      return;
    }
    props.dropCalendarInstance({
      from: draggedFromDate,
      to: droppedInDate,
      instanceId,
    });
    const data = {
      due_date: droppedInDate,
      has_due_date_change: true,
    };
    if (workOrderId) {
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      props.updateWorkOrder(workOrderId, data).catch(e => {});
    } else {
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
      API.updateCalendarInstance(recurringMaintenanceId, recurringMaintenanceDate, data).catch(e => {});
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class Day extends Component {
  state = {
    amountOfWorkOrdersToShow: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setAmountOfWorkOrdersToShow);
    this.setAmountOfWorkOrdersToShow();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.instances.length !== this.props.instances.length) {
      this.setAmountOfWorkOrdersToShow();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.instances, nextProps.instances) ||
      !isEqual(this.props.isOver, nextProps.isOver) ||
      !isEqual(this.props.date, nextProps.date) ||
      !isEqual(this.state.amountOfWorkOrdersToShow, nextState.amountOfWorkOrdersToShow)
    );
  }

  setAmountOfWorkOrdersToShow = () => {
    if (this.instancesRef) {
      const { height } = this.instancesRef.getBoundingClientRect();
      const workOrderBottomMargin = 5;
      const verticalPaddingForInstancesContainer = 10;
      const showMoreWorkOrdersContainerHeight = 15;
      const verticalMarginForShowMoreWorkOrdersContainer = 6;
      let instancesHeight =
        height - verticalPaddingForInstancesContainer - verticalMarginForShowMoreWorkOrdersContainer;
      const totalAmountOfWorkOrders = this.props.instances.length;
      let amountOfWorkOrdersToFitInContainer = Math.floor(height / 44);
      if (amountOfWorkOrdersToFitInContainer < totalAmountOfWorkOrders) {
        instancesHeight = instancesHeight - showMoreWorkOrdersContainerHeight;
      }
      if (amountOfWorkOrdersToFitInContainer > 1) {
        instancesHeight = instancesHeight - workOrderBottomMargin * (amountOfWorkOrdersToFitInContainer - 1);
      }
      this.setState({
        amountOfWorkOrdersToShow: Math.floor(instancesHeight / 44) < 1 ? 0 : Math.floor(instancesHeight / 44),
      });
    }
  };

  isDateInAnotherMonth = () => {
    const { firstDateOfMonth, date } = this.props;
    return !moment(firstDateOfMonth).isSame(
      moment(date).isoWeekday(moment(date).isoWeekday()).format('YYYY-MM-DD'),
      'month'
    );
  };

  getClassNames = () => {
    let classNames = [styles['day-container']];
    if (moment(this.props.date).isSame(moment(), 'day')) {
      classNames = [...classNames, styles['today']];
    }
    if (this.props.isOver) {
      classNames = [...classNames, styles['is-dragging-over']];
    }
    if (this.isDateInAnotherMonth()) {
      classNames = [...classNames, styles['inactive']];
    }
    return classNames;
  };

  renderInstances = () => {
    if (this.props.instances.length === 0) {
      return null;
    }
    return (
      <div className={styles['instances']} ref={ref => (this.instancesRef = ref)}>
        {this.props.instances.slice(0, this.state.amountOfWorkOrdersToShow).map(instance => (
          <WorkOrder instance={instance} date={this.props.date} />
        ))}
        {this.renderAdditionalInstancesContainer()}
      </div>
    );
  };

  renderAdditionalInstancesContainer = () => {
    if (this.props.instances.length > this.state.amountOfWorkOrdersToShow) {
      return (
        <div
          className={`overlay-trigger ${styles['show-more']}`}
          onClick={e => {
            e.stopPropagation();
            const { top, left, width } = this.positionToRef.getBoundingClientRect();
            this.props.hidePreviewOverlay();
            this.props.showMoreWorkOrdersPreviewOverlay({
              top,
              left,
              width,
              date: this.props.date,
            });
          }}
        >
          <FormattedPlural
            value={this.props.instances.length - this.state.amountOfWorkOrdersToShow}
            one={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.additional-work-orders.one"
                values={{ amount: this.props.instances.length - this.state.amountOfWorkOrdersToShow }}
              />
            }
            two={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.additional-work-orders.two"
                values={{ amount: this.props.instances.length - this.state.amountOfWorkOrdersToShow }}
              />
            }
            few={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.additional-work-orders.few"
                values={{ amount: this.props.instances.length - this.state.amountOfWorkOrdersToShow }}
              />
            }
            many={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.additional-work-orders.many"
                values={{ amount: this.props.instances.length - this.state.amountOfWorkOrdersToShow }}
              />
            }
            other={
              <FormattedMessage
                id="screens.work-orders.preview-work-orders.additional-work-orders.other"
                values={{ amount: this.props.instances.length - this.state.amountOfWorkOrdersToShow }}
              />
            }
          />
        </div>
      );
    }
    return null;
  };

  renderBorderContainer = () => <div className={styles['border']} />;

  render() {
    return (
      <>
        {this.props.connectDropTarget(
          <div
            className={this.getClassNames().join(' ')}
            ref={ref => (this.positionToRef = ref)}
            onClick={() =>
              this.props.onCreateWorkOrder({ date: this.props.date, completed_date: this.props.date })
            }
          >
            <div className={styles['day']}>{moment(this.props.date).format('D')}</div>
            {this.renderInstances()}
            {this.renderBorderContainer()}
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMoreWorkOrdersPreviewOverlay: WorkOrdersOperations.showMoreWorkOrdersPreviewOverlay,
      hidePreviewOverlay: WorkOrdersOperations.hidePreviewOverlay,
      dropCalendarInstance: WorkOrdersOperations.dropCalendarInstance,
      updateWorkOrder: SDKReduxOperations.updateWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    instances: WorkOrdersSelectors.getInstancesForDate(state, ownProps.date) || [],
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DropTarget('month', dropTarget, collect)(Day))
);
