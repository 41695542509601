import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { NewSearchField } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class SearchField extends Component {
  render() {
    return (
      <NewSearchField
        value={this.props.value}
        debounce
        autoFocus
        placeholder={this.props.intl.formatMessage({
          id: 'general.search-placeholder',
        })}
        onDebouncedSearch={value => {
          this.props.onDebouncedSearch(value);
        }}
        onSearch={searchTerm => {
          this.props.onSearch(searchTerm);
        }}
        onClear={() => {
          this.props.onClear();
        }}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default injectIntl(connect(mapStateToProps, null)(SearchField));
