import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SDKReduxOperations, API } from 'sdk';
import { PreviewWorkOrderOverlay } from 'views/components/WorkOrder';
import { CalendarOperations, CalendarSelectors } from 'state/ducks/calendar';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';

class PreviewInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavingDueDate: false,
      isSavingAssignees: false,
      isSavingEstimatedTime: false,
    };
  }

  removeInstancesFromCalendar = ({ fromDate, instanceId, users, groups, vendors }) => {
    let removalUpdates = [];
    users.forEach(userId => {
      removalUpdates = [
        ...removalUpdates,
        {
          userId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    groups.forEach(groupId => {
      removalUpdates = [
        ...removalUpdates,
        {
          groupId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    vendors.forEach(vendorId => {
      removalUpdates = [
        ...removalUpdates,
        {
          vendorId,
          date: fromDate,
          id: instanceId,
        },
      ];
    });

    this.props.removeInstancesFromCalendar(removalUpdates);
  };

  addInstancesForCalendar = ({ toDate, instanceId, users, groups, vendors }) => {
    let addUpdates = [];

    users.forEach(userId => {
      addUpdates = [
        ...addUpdates,
        {
          userId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    groups.forEach(groupId => {
      addUpdates = [
        ...addUpdates,
        {
          groupId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    vendors.forEach(vendorId => {
      addUpdates = [
        ...addUpdates,
        {
          vendorId,
          date: toDate,
          id: instanceId,
        },
      ];
    });

    this.props.addInstancesForCalendar(addUpdates);
  };

  updateDueDate = date => {
    this.setState({ isSavingDueDate: true });
    const { type } = this.props.instance;
    let apiCall;
    if (type === 'work_order') {
      apiCall = this.updateWorkOrder({ due_date: date });
    } else {
      apiCall = this.updateCalendarInstance({ due_date: date, has_due_date_change: true });
    }
    apiCall.then(() => {
      const { date: fromDate, users, groups, vendors, id: instanceId } = this.props.instance;
      this.removeInstancesFromCalendar({
        fromDate,
        instanceId,
        users,
        groups,
        vendors,
      });
      this.addInstancesForCalendar({
        toDate: date,
        instanceId,
        users,
        groups,
        vendors,
      });
      this.setState({ isSavingDueDate: false });
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
    });
  };

  updateEstimatedTime = estimated_minutes => {
    this.setState({ isSavingEstimatedTime: true });
    const { type } = this.props.instance;
    let apiCall;
    if (type === 'work_order') {
      apiCall = this.updateWorkOrder({ estimated_minutes });
    } else {
      apiCall = this.updateCalendarInstance({ estimated_minutes });
    }
    apiCall.then(() => {
      this.setState({ isSavingEstimatedTime: false });
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
    });
  };

  updateAssignees = ({ assigned_to_users, assigned_to_groups, assigned_to_vendors }) => {
    this.setState({ isSavingAssignees: true });
    const { type } = this.props.instance;
    let apiCall;
    if (type === 'work_order') {
      apiCall = this.updateWorkOrder({ assigned_to_users, assigned_to_groups, assigned_to_vendors });
    } else {
      apiCall = this.updateCalendarInstance({
        assigned_to_users,
        assigned_to_groups,
        assigned_to_vendors,
        has_assignee_change: true,
      });
    }

    apiCall.then(() => {
      const { date, users, groups, vendors, id: instanceId } = this.props.instance;
      let removeUpdates = [];
      let addUpdates = [];
      users.forEach(userId => {
        removeUpdates = [
          ...removeUpdates,
          {
            userId,
            instanceId: instanceId,
            date,
          },
        ];
      });
      groups.forEach(groupId => {
        removeUpdates = [
          ...removeUpdates,
          {
            groupId,
            instanceId: instanceId,
            date,
          },
        ];
      });
      vendors.forEach(vendorId => {
        removeUpdates = [
          ...removeUpdates,
          {
            vendorId,
            instanceId: instanceId,
            date,
          },
        ];
      });

      assigned_to_users.forEach(userId => {
        addUpdates = [
          ...addUpdates,
          {
            userId,
            instanceId: instanceId,
            date,
          },
        ];
      });
      assigned_to_groups.forEach(groupId => {
        addUpdates = [
          ...addUpdates,
          {
            groupId,
            instanceId: instanceId,
            date,
          },
        ];
      });
      assigned_to_vendors.forEach(vendorId => {
        addUpdates = [
          ...addUpdates,
          {
            vendorId,
            instanceId: instanceId,
            date,
          },
        ];
      });
      this.props.removeAssigneesFromInstance(removeUpdates);
      this.props.addAssigneesForInstance(addUpdates);

      this.setState({ isSavingAssignees: false });
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
    });
  };

  updateWorkOrder = params => {
    const { work_order } = this.props.instance;
    return this.props.updateWorkOrder(work_order, params);
  };

  updateCalendarInstance = data => {
    const { recurring_maintenance: recurringMaintenanceId, recurring_maintenance_date: date } =
      this.props.instance;
    return API.updateCalendarInstance(recurringMaintenanceId, date, data);
  };

  render() {
    const { work_order, recurring_maintenance, users, groups, vendors, date } = this.props.instance || {};
    const workOrderId = this.props.workOrderId ? this.props.workOrderId : work_order;
    return (
      <PreviewWorkOrderOverlay
        open={this.props.open}
        top={this.props.top}
        left={this.props.left}
        extraWidth={this.props.extraWidth}
        width={600}
        editable={this.props.workOrderId == null}
        workOrderId={workOrderId}
        recurringMaintenanceId={recurring_maintenance}
        date={date}
        userIds={users}
        groupIds={groups}
        vendorIds={vendors}
        isSavingDueDate={this.state.isSavingDueDate}
        isSavingEstimatedTime={this.state.isSavingEstimatedTime}
        isSavingAssignees={this.state.isSavingAssignees}
        onSaveAssignees={({ assigned_to_users, assigned_to_groups, assigned_to_vendors }) => {
          this.updateAssignees({ assigned_to_users, assigned_to_groups, assigned_to_vendors });
        }}
        onSaveDate={date => {
          this.updateDueDate(date);
        }}
        onSaveEstimatedTime={estimated_minutes => this.updateEstimatedTime(estimated_minutes)}
        onClose={this.props.onClose}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkOrder: SDKReduxOperations.updateWorkOrder,
      removeInstancesFromCalendar: CalendarOperations.removeInstancesFromCalendar,
      addInstancesForCalendar: CalendarOperations.addInstancesForCalendar,
      addAssigneesForInstance: CalendarOperations.addAssigneesForInstance,
      removeAssigneesFromInstance: CalendarOperations.removeAssigneesFromInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    instance: CalendarSelectors.getInstance(state, ownProps.instanceId),
    calendarGroups: CalendarSelectors.getGroupsById(state),
    calendarUsers: CalendarSelectors.getUsersById(state),
    calendarVendors: CalendarSelectors.getVendorsById(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PreviewInstance));
