import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import styles from './style.module.scss';

class UserListItem extends Component {
  renderTitle = () => {
    const { user } = this.props;
    if (user.archived === true) {
      return <span className={styles['archived']}>{this.props.user.name}</span>;
    }
    return <span>{this.props.user.name}</span>;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          checkbox={this.props.multiple}
          checked={this.props.selected}
          small
          clickable
          onClick={() => this.props.onSelectUser(this.props.user)}
          onCheck={() => this.props.onSelectUser(this.props.user)}
        >
          <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.props.user.email} />
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserListItem);
