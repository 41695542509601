import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, EmptyDataSet, Button } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class PurchaseOrderPaymentTerm extends Component {
  renderPurchaseOrderDeliveryMethods = () => {
    if (this.props.purchaseOrderPaymentTerms.length == 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.purchase-order.info.general-information.payment-terms-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.purchase-order.info.general-information.payment-terms-empty-data-set.subtitle" />
            }
            button={
              <Button
                primary
                label="screens.purchase-order.info.general-information.payment-terms-empty-data-set.button"
                small
                onClick={() => this.props.history.push('/settings/purchase-orders/payment-terms')}
              />
            }
            tiny
          />
        </div>
      );
    }
    return this.props.purchaseOrderPaymentTerms.map(purchaseOrderPaymentTerm => (
      <Field.Dropdown.Item
        key={purchaseOrderPaymentTerm.id}
        selected={this.props.value === purchaseOrderPaymentTerm.id}
        onClick={() => this.props.onChange(purchaseOrderPaymentTerm.id)}
      >
        {purchaseOrderPaymentTerm.title}
      </Field.Dropdown.Item>
    ));
  };

  render() {
    return (
      <Field.Dropdown
        clearable
        value={this.props.purchaseOrderPaymentTerm ? this.props.purchaseOrderPaymentTerm.title : ''}
        onClear={() => this.props.onChange(null)}
      >
        {this.renderPurchaseOrderDeliveryMethods()}
      </Field.Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrderPaymentTerms: AuthSelectors.getPurchaseOrderPaymentTerms(state),
    purchaseOrderPaymentTerm: EntitySelectors.getPurchaseOrderPaymentTerm(state, ownProps.value),
  };
}

export default withRouter(connect(mapStateToProps)(PurchaseOrderPaymentTerm));
