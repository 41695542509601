import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, List, UpgradeToProIcon, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class SendPurchaseOrderModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderTitle = () => {
    if (this.props.fromOrdered) {
      return <FormattedMessage id="components.send-purchase-order-modal.title-ordered" />;
    } else {
      return <FormattedMessage id="components.send-purchase-order-modal.title" />;
    }
  };

  renderSubtitle = () => {
    if (this.props.fromOrdered) {
      return (
        <FormattedMessage
          id="components.send-purchase-order-modal.subtitle-ordered"
          values={{ vendor: this.props.vendor.name }}
        />
      );
    } else {
      return null;
    }
  };

  renderSkipButton = () => {
    if (!this.props.fromOrdered) return null;

    return (
      <div className={styles['skip-button']}>
        <Button small label="general.skip" onClick={this.props.onClose} />
      </div>
    );
  };

  renderExternalLinkRightComponent = () => {
    if (this.props.hasProTier) {
      return (
        <List.Item.Column alignRight>
          <div className={styles['soon']}>
            <FormattedMessage id="general.coming-soon" />
          </div>
        </List.Item.Column>
      );
    } else {
      return (
        <List.Item.Column alignRight>
          <UpgradeToProIcon />
        </List.Item.Column>
      );
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={520}>
        <Modal.Header
          title={this.renderTitle()}
          subtitle={this.renderSubtitle()}
          subtitleTopMargin
          onClose={this.props.onClose}
          ignoreLine
        />
        <Modal.Content>
          <div className={styles['list-container']}>
            <List light>
              <List.Item clickable onClick={this.props.onSendEmail}>
                <List.Item.Column width={20}>
                  <Icon regular type="envelope" />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={
                    <FormattedMessage id="components.send-purchase-order-modal.options.send-email.title" />
                  }
                  subtitle={
                    <FormattedMessage id="components.send-purchase-order-modal.options.send-email.subtitle" />
                  }
                />
              </List.Item>
              <List.Item clickable onClick={this.props.onPrint}>
                <List.Item.Column width={20}>
                  <Icon regular type="print" />
                </List.Item.Column>
                <List.Item.TitleColumn
                  title={<FormattedMessage id="components.send-purchase-order-modal.options.print.title" />}
                  subtitle={
                    <FormattedMessage id="components.send-purchase-order-modal.options.print.subtitle" />
                  }
                />
              </List.Item>
            </List>
          </div>
          {this.renderSkipButton()}
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderId);

  return {
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(SendPurchaseOrderModal)));
