import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './style.module.scss';

const Container = ({ children }) => {
  return <div className={styles['container']}>{children}</div>;
};

const Option = props => {
  if (props.loading) {
    return (
      <div className={styles['setting']}>
        <div style={{ width: 250, height: 65 }}>
          <ContentLoader
            viewBox="0 0 250 65"
            preserveAspectRatio="xMinYMin"
            primaryColor="#F5F5F5"
            secondaryColor="#EFEFEF"
          >
            <rect x="0" y="4" rx="2" ry="2" width="140" height="14" />
            <rect x="0" y="27" rx="2" ry="2" width="250" height="12" />
            <rect x="0" y="43" rx="2" ry="2" width="150" height="12" />
          </ContentLoader>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['setting']}>
      <div className={styles['description-container']}>
        <p className={styles['title']}>{props.title}</p>
        {props.subtitle ? <p className={styles['subtitle']}>{props.subtitle}</p> : null}
      </div>
      {props.rightContainer ? <div className={styles['right-container']}>{props.rightContainer}</div> : null}
    </div>
  );
};

Option.Container = Container;
export default Option;
