import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  normalizeAssetVendor,
  normalizeAttachment,
  normalizeWorkOrder,
  normalizeAsset,
  normalizeSparePartAsset,
  normalizeDowntime,
  normalizeCalendarInstance,
} from 'sdk/Schemas';

// -------
const showPreviewOverlay = actions.showPreviewOverlay;
const hidePreviewOverlay = actions.hidePreviewOverlay;
const showMoreWorkOrdersPreviewOverlay = actions.showMoreWorkOrdersPreviewOverlay;
const hideMoreWorkOrdersPreviewOverlay = actions.hideMoreWorkOrdersPreviewOverlay;
const beginDrag = actions.beginDrag;
const endDrag = actions.endDrag;
const dropCalendarInstance = actions.dropCalendarInstance;

const fetchAsset = id => dispatch => {
  dispatch(actions.fetchAssetForView(id));
  return API.fetchAsset(id).then(res => {
    const { entities, result } = normalizeAsset(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchAssetForViewSuccess(result));

    return res.data;
  });
};

const fetchWorkOrders = (systemId, params) => dispatch => {
  return API.listWorkOrders(systemId, { ...params, page_size: 8 }).then(res => {
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    const { entities, result } = normalizeWorkOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchWorkOrdersSuccess({
        ids: result,
        pagination,
      })
    );
    return res.data;
  });
};

const fetchInstancesForDates =
  (systemId, params, options = { resetCalendar: true }) =>
  dispatch => {
    const { resetCalendar } = options;
    dispatch(actions.fetchDataBetweenDates({ resetCalendar }));
    return API.showCalendar(systemId, params)
      .then(({ data }) => {
        const { instances } = data;
        let ids = [];
        const { entities } = normalizeCalendarInstance(
          instances.map(instance => {
            let id = null;
            if (instance.type === 'work_order') {
              id = instance.work_order.id;
            } else {
              id = `${instance.recurring_maintenance_date}_${instance.recurring_maintenance.id}`;
            }
            ids = [...ids, id];
            return {
              ...instance,
              id,
            };
          })
        );
        const { calendarInstanceById } = entities;
        dispatch(EntityOperations.updateEntities(entities));
        dispatch(
          actions.fetchDataBetweenDatesSuccess({
            instanceById: calendarInstanceById,
            ids,
          })
        );
      })
      .catch(() => {});
  };

const fetchAssetVendors =
  (systemId, assetId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      asset_id: assetId,
      page_size: 8,
    };

    return API.listAssetVendors(systemId, attrs).then(res => {
      const { entities, result } = normalizeAssetVendor(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchAssetVendorsSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchAttachments = (assetId, params) => dispatch => {
  return API.listAttachmentsForAsset(assetId, params).then(res => {
    const { entities, result } = normalizeAttachment(res.data);
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchAttachmentsSuccess({
        ids: result,
        pagination,
      })
    );
  });
};

const fetchSparePartAssets =
  (systemId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
    };

    return API.listSparePartAssets(systemId, attrs).then(res => {
      const { entities, result } = normalizeSparePartAsset(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchSparePartAssetsSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchDowntimes =
  (systemId, assetId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      asset_id: assetId,
      page_size: 8,
    };

    return API.listDowntimes(systemId, attrs).then(res => {
      const { entities, result } = normalizeDowntime(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchDowntimesSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

export default {
  showPreviewOverlay,
  beginDrag,
  endDrag,
  hidePreviewOverlay,
  showMoreWorkOrdersPreviewOverlay,
  hideMoreWorkOrdersPreviewOverlay,
  dropCalendarInstance,
  fetchAsset,
  fetchInstancesForDates,
  fetchDowntimes,
  fetchWorkOrders,
  fetchAssetVendors,
  fetchAttachments,
  fetchSparePartAssets,
};
