import React from 'react';
import { Link } from 'react-router-dom';
import { Button, WhiteCard } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { Section } from 'views/scenes/Settings/components';

export default () => (
  <WhiteCard>
    <Section
      header
      title={<FormattedMessage id="screens.settings.users.title" />}
      subtitle={<FormattedMessage id="screens.settings.users.subtitle" />}
    />
    <Link to="/users">
      <Button gray label="screens.settings.users.manage-users-button" />
    </Link>
  </WhiteCard>
);
