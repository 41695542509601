import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import RequestItem from './RequestItem';
import WorkOrderItem from './WorkOrderItem';
import styles from './styles.module.scss';

class SelectRequestsModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderRequestsList = () => {
    if (this.props.requests.length === 0) {
      return null;
    }
    return (
      <div className={styles['list-container']}>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.request.resource" />
          </List.Header.Column>
          <List.Header.Column alignRight width={150}>
            <FormattedMessage id="resources.request.created-by" />
          </List.Header.Column>
          <List.Header.Column alignRight width={150}>
            <FormattedMessage id="resources.request.created-at" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.requests.map(request => (
            <RequestItem request={request} onSelect={id => this.props.onSelectRequest(id)} />
          ))}
        </List>
      </div>
    );
  };

  renderWorkOrdersLists = () => {
    if (this.props.workOrders.length === 0) {
      return null;
    }
    return (
      <div className={styles['list-container']}>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.work-order.resource" />
          </List.Header.Column>
          <List.Header.Column width={140}>
            <FormattedMessage id="resources.work-order.due-date" />
          </List.Header.Column>
          <List.Header.Column alignRight width={45} />
        </List.Header>
        <List>
          {this.props.workOrders.map(workOrder => (
            <WorkOrderItem workOrder={workOrder} onSelect={id => this.props.onSelectWorkOrder(id)} />
          ))}
        </List>
      </div>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={700}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.select-follow-up-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>
          {this.renderRequestsList()}
          {this.renderWorkOrdersLists()}
        </Modal.Content>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(SelectRequestsModal);
