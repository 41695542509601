import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';

export default ({ open, onClose }) => (
  <Modal isOpen={open} width={440}>
    <Modal.Header
      title={<FormattedMessage id="components.attachment-upload-error.title" />}
      subtitle={<FormattedMessage id="components.attachment-upload-error.subtitle" />}
      onClose={onClose}
    />
    <Modal.Footer>
      <Button.Group>
        <Button primary label="general.ok" onClick={onClose} />
      </Button.Group>
    </Modal.Footer>
  </Modal>
);
