import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { AssetsOperations } from 'state/ducks/assets';
import { AuthSelectors } from 'state/ducks/auth';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getTemplateFieldMenuItems = () => {
    return this.props.templateFields.map(templateField => ({
      label: templateField.title,
      onClick: () => {
        this.props.selectFilterType(`${FILTER_TYPES.AssetField}.${templateField.id}`);
      },
    }));
  };

  getAssetMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'screens.assets.filters.advanced.asset.belongs-to',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.TreeParent);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.asset.type',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.AssetType);
        },
      },
    ];
    menuItems = [...menuItems, ...this.getTemplateFieldMenuItems()];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderAssetFilters = () => {
    const sparePartItems = this.getAssetMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.assets.filters.advanced.asset.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [...this.getAssetMenuItems()];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return this.renderAssetFilters();
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.assets.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: AssetsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    templateFields: AuthSelectors.getAssetTemplateFields(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
