import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';

class EditCommentModal extends Component {
  state = {
    description: '',
    isSaving: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        description: this.props.stocktaking.description || '',
        isSaving: false,
      });
    }
  }

  updateStocktaking = () => {
    this.setState({ isSaving: true });
    this.props
      .updateStocktaking(this.props.stocktakingId, { description: this.state.description })
      .then(() => {
        this.props.onClose();
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.stocktaking-detail.edit-description-modal.title" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Field view={false} label={<FormattedMessage id="resources.stocktaking.description" />}>
            <Field.Textarea
              autoFocus
              value={this.state.description}
              onChange={description => {
                this.setState({ description });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer ignoreLine>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={() => this.updateStocktaking()}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateStocktaking: SDKReduxOperations.updateStocktaking,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.stocktakingId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCommentModal);
