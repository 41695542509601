import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeChecklistInstanceOption } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function updateChecklistInstanceOption(checklistInstanceOptionId, params) {
  return dispatch => {
    return API.updateChecklistInstanceOption(checklistInstanceOptionId, params)
      .then(res => {
        const { entities } = normalizeChecklistInstanceOption(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistInstanceOptionId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_OPTION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createForChecklistInstance(checklistInstanceId, params) {
  return dispatch => {
    return API.createForChecklistInstance(checklistInstanceId, params)
      .then(res => {
        const { entities } = normalizeChecklistInstanceOption(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { checklistInstanceId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_OPTION_CREATED_FOR_CHECKLIST_INSTANCE, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function createForOperationalMaintenanceTemplateAsset(templateAssetId, params) {
  return dispatch => {
    return API.createForOperationalMaintenanceTemplateAsset(templateAssetId, params)
      .then(res => {
        const { entities } = normalizeChecklistInstanceOption(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { templateAssetId, params, data: res.data };
        dispatch({ type: types.CHECKLIST_INSTANCE_OPTION_CREATED_TEMPLATE_ASSET, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
