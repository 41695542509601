import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Tooltip, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class DeviationText extends Component {
  render() {
    const { checklistDeviationAction, workOrder, request } = this.props;
    if (checklistDeviationAction) {
      if (workOrder) {
        return (
          <>
            <div className={styles['work-order-created']}>
              <FormattedMessage id="components.checklist-instance-modal.deviations.status.work-order-created" />
              <span> - </span>
              <span>#{workOrder.number}</span>
            </div>
            <Tooltip
              trigger={
                <Link to={`/work-orders/${workOrder.id}`} target="_blank">
                  <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.work-order" />}
            />
          </>
        );
      }
      if (request) {
        return (
          <>
            <div className={styles['request-created']}>
              <FormattedMessage id="components.checklist-instance-modal.deviations.status.request-created" />
              <span> - </span>
              <span>#{request.number}</span>
            </div>
            <Tooltip
              trigger={
                <Link to={`/requests/${request.id}`} target="_blank">
                  <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.request" />}
            />
          </>
        );
      }
    }
    if (this.props.checklistInstanceRow.deviation_ignored) {
      return (
        <div className={styles['deviation-ignored']}>
          <FormattedMessage id="components.checklist-instance-modal.deviations.status.ignored" />
        </div>
      );
    }
    return (
      <div className={styles['open']}>
        <FormattedMessage id="components.checklist-instance-modal.deviations.status.open" />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { checklistInstanceRow } = ownProps;
  const checklistDeviationActions = EntitySelectors.getChecklistDeviationActions(
    state,
    checklistInstanceRow.checklist_deviation_actions
  );
  const checklistDeviationAction = checklistDeviationActions[0];
  if (checklistDeviationAction) {
    return {
      checklistDeviationAction,
      workOrder: EntitySelectors.getWorkOrder(state, checklistDeviationAction.work_order_id),
      request: EntitySelectors.getRequest(state, checklistDeviationAction.request_id),
    };
  }
  return {
    checklistDeviationAction,
  };
}

export default connect(mapStateToProps)(DeviationText);
