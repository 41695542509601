import update from 'immutability-helper';
import types from './types';
import { mergeWith } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  calendar: {
    shrinkedAssetIds: {},
    operationalMaintenanceIds: [],
    operationalMaintenancesForAssetId: {},
  },
  selectedOperationalMaintenances: {
    pageIsSelected: false,
    ids: {},
  },
  list: {
    ids: [],
    queryParams: {
      status: [],
    },
    count: {},
    countLoading: false,
  },
  navigatedToOperationalMaintenance: {
    search: {},
  },
  templates: {
    ids: [],
    pagination: {
      totalEntries: 0,
      totalPages: 1,
    },
    queryParams: {
      page: 1,
      page_size: 25,
    },
  },
  breaks: {
    ids: [],
    pagination: {
      totalEntries: 0,
      totalPages: 1,
    },
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.UNITIALIZE_LIST_COUNT: {
      return {
        ...state,
        list: {
          ...state.list,
          count: {},
          countLoading: false,
        },
      };
    }
    case types.TOGGLE_EXPAND_ASSET: {
      const assetId = action.payload;
      return {
        ...state,
        calendar: {
          ...state.calendar,
          shrinkedAssetIds: {
            ...state.calendar.shrinkedAssetIds,
            [assetId]: state.calendar.shrinkedAssetIds[assetId] === true ? false : true,
          },
        },
      };
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SPLIT_BY_ASSETS_SUCCESS: {
      const { operationalMaintenancesForAssetId } = action.payload;
      return {
        ...state,
        calendar: {
          ...state.calendar,
          operationalMaintenancesForAssetId,
          operationalMaintenanceIds: [],
        },
      };
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_FOR_CALENDAR_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        calendar: {
          ...state.calendar,
          operationalMaintenanceIds: ids,
          operationalMaintenancesForAssetId: {},
        },
      };
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          ids,
        },
      };
    }

    case types.SET_OPERATIONAL_MAINTENANCES_LIST_COUNT_LOADING: {
      return update(state, {
        list: {
          countLoading: { $set: true },
        },
      });
    }

    case types.FETCH_OPERATIONAL_MAINTENANCES_LIST_COUNT_SUCCESS: {
      const { data } = action.payload;

      return update(state, {
        list: {
          countLoading: { $set: false },
          count: {
            $set: data,
          },
        },
      });
    }
    case types.SET_NAVIGATED_TO_OPERATIONAL_MAINTENANCE: {
      const { search } = action.payload;
      return update(state, {
        navigatedToOperationalMaintenance: {
          search: {
            $set: search,
          },
        },
      });
    }
    case types.ADD_QUERY_PARAMETER_FOR_OPERATIONAL_MAINTENANCES: {
      return update(state, {
        list: {
          queryParams: {
            $apply: filters =>
              mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
          },
        },
      });
    }
    case types.RESET_MAINTENANCES_TEMPLATES_STATE: {
      return {
        ...state,
        templates: {
          ...INITIAL_STATE.templates,
        },
      };
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_TEMPLATES_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        templates: {
          ...state.templates,
          ids,
          pagination,
        },
      };
    }
    case types.ADD_QUERY_PARAMETER_FOR_TEMPLATES: {
      return update(state, {
        templates: {
          queryParams: {
            $apply: filters =>
              mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
          },
        },
      });
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_BREAKS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        breaks: {
          ...state.breaks,
          ids,
          pagination,
        },
      };
    }
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_BREAK_CREATED: {
      const { data: operationalMaintenanceBreak } = action.payload;
      return update(state, {
        breaks: {
          ids: {
            $set: [operationalMaintenanceBreak.id, ...state.breaks.ids],
          },
        },
      });
    }
    case SDKReduxTypes.OPERATIONAL_MAINTENANCE_BREAK_DELETED: {
      const { operationalMaintenanceBreakId } = action.payload;
      return update(state, {
        breaks: {
          ids: {
            $set: state.breaks.ids.filter(id => id !== operationalMaintenanceBreakId),
          },
        },
      });
    }
    case types.SELECT_OPERATIONAL_MAINTENANCES_PAGE: {
      if (state.selectedOperationalMaintenances.pageIsSelected) {
        return update(state, {
          selectedOperationalMaintenances: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedOperationalMaintenances.ids };
                state.list.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedOperationalMaintenances: {
          ids: {
            $apply: ids => {
              return state.list.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedOperationalMaintenances.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedOperationalMaintenances.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_OPERATIONAL_MAINTENANCE: {
      const id = action.payload;
      if (state.selectedOperationalMaintenances.ids[id]) {
        return update(state, {
          selectedOperationalMaintenances: {
            ids: {
              $unset: [id],
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.list.ids
          .filter(id => id !== id)
          .every(id => state.selectedOperationalMaintenances.ids[id] === true);
        return update(state, {
          selectedOperationalMaintenances: {
            ids: {
              [id]: {
                $set: true,
              },
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.RESET_SELECTED_OPERATIONAL_MAINTENANCES: {
      return update(state, {
        selectedOperationalMaintenances: {
          $set: {
            ...INITIAL_STATE.selectedOperationalMaintenances,
          },
        },
      });
    }

    default:
      return state;
  }
};
