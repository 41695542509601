import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Icon } from 'views/components/Shared/General';
import { API } from 'sdk';
import { SelectUserModal } from 'views/components/User';
import { normalizeGroup, normalizeUser } from 'sdk/Schemas';
import { RequestsSelectors } from 'state/ducks/requests';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import GroupDropdownItem from './GroupDropdownItem';
import UserDropdownItem from './UserDropdownItem';
import { EntitySelectors } from 'sdk/State/entities';
import SelectedAssignee from './SelectedAssignee';
import styles from './style.module.scss';

class AssigneeFilterDropdown extends Component {
  state = {
    isFetching: false,
    userIds: [],
    groupIds: [],
    open: false,
    showSelectUserModal: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      this.setState({ isFetching: true });
      Promise.all([
        API.listGroups(this.props.system.id, { type: 'request_assignee', no_pagination: true }),
        API.listUsers(this.props.system.id, { archived: false, no_pagination: true, member: true }),
      ]).then(([{ data: groups }, { data: users }]) => {
        const { entities: groupEntities, result: groupIds } = normalizeGroup(groups);
        const { entities: userEntities, result: userIds } = normalizeUser(users);
        this.props.updateEntities({ ...groupEntities, ...userEntities });
        this.setState({ isFetching: false, groupIds, userIds });
      });
    } else if (!this.state.open && prevState.open) {
      this.setState({ userIds: [], groupIds: [] });
    }
  }

  selectFilter = params => {
    this.props.onFilter({
      assigned_to_me: null,
      assigned_to_user_id: null,
      assigned_to_group_id: null,
      ...params,
    });
    this.setState({ open: false });
  };

  hasNoAssigneeFilter = () => {
    const { assigned_to_me, assigned_to_group_id, assigned_to_user_id } = this.props.queryParameters;
    return assigned_to_me == null && assigned_to_group_id == null && assigned_to_user_id == null;
  };

  renderSpecificAssignees = () => {
    const { assigned_to_group_id, assigned_to_user_id } = this.props.queryParameters;
    if (
      this.state.groupIds.length === 0 &&
      this.state.userIds.filter(id => id !== this.props.currentUser.id).length === 0
    ) {
      return <NewInlineModal.Dropdown.Separator />;
    }

    return (
      <>
        <NewInlineModal.Dropdown.Separator />
        {this.state.groupIds.map(id => (
          <GroupDropdownItem
            id={id}
            selected={assigned_to_group_id === id}
            onSelect={() => {
              this.selectFilter({ assigned_to_group_id: id });
            }}
          />
        ))}
        {this.state.userIds
          .filter(id => id !== this.props.currentUser.id)
          .map(id => (
            <UserDropdownItem
              id={id}
              selected={assigned_to_user_id === id}
              onSelect={() => {
                this.selectFilter({ assigned_to_user_id: id });
              }}
            />
          ))}
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ showSelectUserModal: true, open: false });
          }}
        >
          <span className={styles['more-options']}>
            <FormattedMessage id="general.more-options" />
          </span>
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Separator />
      </>
    );
  };

  renderContent = () => {
    const { isViewOnly } = this.props;
    const { assigned_to_me, assigned_to_user_id, assigned_to_group_id } = this.props.queryParameters;
    const { exists: userExists } = assigned_to_user_id || {};
    const { exists: groupExists } = assigned_to_group_id || {};
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    return (
      <NewInlineModal.Dropdown.Items>
        {isViewOnly === false ? (
          <NewInlineModal.Dropdown.Item
            selected={assigned_to_me === true}
            onClick={() => this.selectFilter({ assigned_to_me: true })}
          >
            <FormattedMessage id="screens.requests.filter-assignee-dropdown.assigned-to-me" />
          </NewInlineModal.Dropdown.Item>
        ) : null}

        <NewInlineModal.Dropdown.Item
          selected={this.hasNoAssigneeFilter()}
          onClick={() => this.selectFilter({})}
        >
          <FormattedMessage id="screens.requests.filter-assignee-dropdown.show-all" />
        </NewInlineModal.Dropdown.Item>
        {this.renderSpecificAssignees()}

        <NewInlineModal.Dropdown.Item
          selected={userExists === false && groupExists === false}
          onClick={() =>
            this.selectFilter({
              assigned_to_user_id: {
                exists: false,
              },
              assigned_to_group_id: {
                exists: false,
              },
            })
          }
        >
          <FormattedMessage id="screens.requests.filter-assignee-dropdown.no-assignee" />
        </NewInlineModal.Dropdown.Item>
      </NewInlineModal.Dropdown.Items>
    );
  };

  render() {
    return (
      <>
        <div className={styles['assignee-container']}>
          <div
            className={styles['select-assignee']}
            ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                open: !prevState.open,
              }));
            }}
          >
            <div className={styles['assignee-text-container']}>
              <SelectedAssignee />
            </div>
            <div>
              <Icon type="caret-down" />
            </div>
          </div>
        </div>

        <NewInlineModal
          positionToRef={this.assigneeDropdownInlineModalPositioningRef}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          minWidth={335}
        >
          <React.Fragment>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </React.Fragment>
        </NewInlineModal>
        <SelectUserModal
          open={this.state.showSelectUserModal}
          members={this.props.members}
          selectUserId={this.props.userId}
          onSelectUser={({ id }) => {
            this.setState({ showSelectUserModal: false });
            this.selectFilter({ assigned_to_user_id: id });
          }}
          onClose={() => this.setState({ showSelectUserModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { userId, groupId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    user: EntitySelectors.getUser(state, userId),
    group: EntitySelectors.getGroup(state, groupId),
    queryParameters: RequestsSelectors.getQueryParameters(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssigneeFilterDropdown));
