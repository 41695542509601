import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, HelperFunctions } from 'sdk';
import { normalizeVendor } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { List, NewSearchField, WhiteCard, EmptyDataSet, Pagination } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import VendorTiny from 'assets/images/EmptyDataSet/VendorTiny.png';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import VendorListItem from './VendorListItem';
import styles from './style.module.scss';

class SelectVendorModal extends Component {
  getInitialState = () => ({
    isEmpty: false,
    selectedTab: 'sparePartVendors',
    isFetchingDataForModalDataForModal: true,
    isSearching: false,
    searchValue: '',
    vendorIds: [],
    primaryVendorId: null,
    page: 1,
    pagination: {},
    hideTabBar: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.sparePartId == null) {
        this.setState({
          ...this.getInitialState(),
          isFetchingDataForModal: true,
          selectedTab: 'vendors',
          hideTabBar: true,
        });
        this.fetchAllVendors().then(({ vendorIds, pagination }) => {
          this.setState({
            vendorIds,
            pagination,
            isFetchingDataForModal: false,
          });
        });
      } else {
        this.setState({
          ...this.getInitialState(),
          isFetchingDataForModal: true,
          selectedTab: 'sparePartVendors',
        });
        this.fetchPrimaryAndVendorsForSparePart();
      }
    }
  }

  fetchPrimaryAndVendorsForSparePart = () => {
    const listVendorsApiCall = API.listVendors(this.props.currentSystem.id, {
      page_size: 8,
      no_pagination: true,
      spare_part_vendors: { spare_part_id: this.props.sparePartId },
      primary_for_spare_part_id: {
        [HelperFunctions.FILTER_COMPARABLES.NotExact]: this.props.sparePartId,
      },
      sort: 'name',
      'sort-order': 'asc',
    });
    const listPrimaryApiCall = API.listVendors(this.props.currentSystem.id, {
      page_size: 8,
      spare_part_vendors: { spare_part_id: this.props.sparePartId },
      primary_for_spare_part_id: this.props.sparePartId,
      sort: 'name',
      'sort-order': 'asc',
    });
    Promise.all([listVendorsApiCall, listPrimaryApiCall])
      .then(([vendorsRes, primaryVendorRes]) => {
        const { data: vendorsData } = vendorsRes;
        const { data: primaryVendorsData } = primaryVendorRes;
        const { entities: vendorsEntities, result: vendorIds } = normalizeVendor(vendorsData);
        const { entities: primaryVendorsEntities, result: primaryVendorIds } =
          normalizeVendor(primaryVendorsData);
        this.props.updateEntities(vendorsEntities);
        this.props.updateEntities(primaryVendorsEntities);
        if (vendorIds.length === 0 && primaryVendorIds.length === 0) {
          this.fetchAllVendors().then(({ vendorIds, pagination }) => {
            this.setState({
              selectedTab: 'vendors',
              vendorIds,
              pagination,
              isFetchingDataForModal: false,
              isEmpty: vendorIds.length === 0,
              hideTabBar: true,
              isFetching: false,
            });
          });
        } else {
          this.setState({
            vendorIds: vendorIds,
            isFetchingDataForModal: false,
            isFetching: false,
            primaryVendorId: primaryVendorIds[0],
          });
        }
      })
      .catch(e => {
        this.setState({ viewInitialized: true, isFetching: false });
      });
  };

  selectTab = tab => {
    this.setState({ selectedTab: tab, isFetching: true });
    if (tab === 'sparePartVendors') {
      this.fetchPrimaryAndVendorsForSparePart();
    } else {
      this.fetchAllVendors().then(({ vendorIds, pagination }) => {
        this.setState({
          isFetching: false,
          vendorIds,
          pagination,
        });
      });
    }
  };

  fetchVendorsForSparePart = () => {
    return API.listVendors(this.props.currentSystem.id, {
      no_pagination: true,
      spare_part_id: this.props.sparePartId,
      sort: 'name',
      'sort-order': 'asc',
    }).then(({ data: vendors }) => {
      const { entities, result: vendorIds } = normalizeVendor(vendors);
      this.props.updateEntities(entities);
      return vendorIds.map(id => entities.vendorById[id]);
    });
  };

  fetchAllVendors = params => {
    return API.listVendors(this.props.currentSystem.id, { ...params, page_size: 8 }).then(
      ({ data: vendors, headers }) => {
        const { entities, result: vendorIds } = normalizeVendor(vendors);
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        this.props.updateEntities(entities);
        return { vendorIds, pagination };
      }
    );
  };

  renderVendorsEmptyDataset = () => (
    <div className={styles['vendor-list-container']}>
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="screens.should-order-spare-parts.vendors-empty-data-set.title" />}
          subtitle={
            <FormattedMessage id="screens.should-order-spare-parts.vendors-empty-data-set.subtitle" />
          }
          image={VendorTiny}
          tiny
        />
      </WhiteCard>
    </div>
  );

  renderSearchedEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="general.empty-data-set-search.title" />}
        subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
        image={SearchImage}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderPrimaryVendor = () => {
    const { primaryVendorId } = this.state;
    if (primaryVendorId) {
      return (
        <>
          <div className={styles['list-title']}>
            <FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.primary-vendor" />
          </div>
          <List>
            <VendorListItem id={primaryVendorId} onSelectVendor={id => this.props.onSelectVendor(id)} />
          </List>
        </>
      );
    }
  };

  renderOtherVendorsForSparePart = () => {
    if (this.state.vendorIds.length === 0) {
      return null;
    }
    let listTitleClassNames = [styles['list-title']];
    if (this.state.primaryVendorId) {
      listTitleClassNames = [...listTitleClassNames, styles['top-margin']];
    }
    return (
      <>
        <div className={listTitleClassNames.join(' ')}>
          <FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.other-vendors" />
        </div>
        <List>
          {this.state.vendorIds.map(id => (
            <VendorListItem id={id} onSelectVendor={id => this.props.onSelectVendor(id)} />
          ))}
        </List>
      </>
    );
  };

  renderSparePartVendors = () => {
    if (this.state.isFetching) {
      return this.renderVendorFetchingLoader();
    } else {
      return (
        <>
          {this.renderPrimaryVendor()}
          {this.renderOtherVendorsForSparePart()}
        </>
      );
    }
  };

  renderSearchLoader = () => {
    const amountOfVendors = this.state.vendorIds.length === 0 ? 1 : this.state.vendorIds.length;

    return (
      <List>
        {Array(amountOfVendors)
          .fill()
          .map(() => (
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          ))}
      </List>
    );
  };

  renderVendorFetchingLoader = () => {
    return (
      <List>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    );
  };

  renderVendorsList = () => {
    if (this.state.isSearching) {
      return this.renderSearchLoader();
    } else if (this.state.isFetching) {
      return this.renderVendorFetchingLoader();
    } else if (this.state.vendorIds.length === 0) {
      return this.renderSearchedEmptyDataset();
    }

    return (
      <React.Fragment>
        <List>
          {this.state.vendorIds.map(id => (
            <VendorListItem id={id} onSelectVendor={id => this.props.onSelectVendor(id)} />
          ))}
        </List>
      </React.Fragment>
    );
  };

  renderVendors = () => {
    if (this.state.isEmpty) {
      return this.renderVendorsEmptyDataset();
    }

    return (
      <React.Fragment>
        <div className={styles['search-field-container']}>
          <NewSearchField
            autoFocus
            small
            againstGrayBackground
            value={this.state.searchValue}
            debounce
            placeholder={this.props.intl.formatMessage({
              id: 'screens.should-order-spare-parts.select-vendor-modal.search-placeholder',
            })}
            onSearch={value => {
              this.setState({
                searchValue: value,
                isSearching: true,
                page: 1,
              });
            }}
            onDebouncedSearch={value => {
              this.fetchAllVendors({ search: value }).then(({ vendorIds, pagination }) => {
                this.setState({
                  isSearching: false,
                  vendorIds,
                  pagination,
                });
              });
            }}
            onClear={() => {
              this.setState({ isSearching: true, searchValue: '', page: 1 });
              this.fetchAllVendors({ search: '' }).then(({ vendorIds, pagination }) => {
                this.setState({
                  isSearching: false,
                  vendorIds,
                  pagination,
                });
              });
            }}
          />
        </div>
        {this.renderVendorsList()}
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderPagination = () => {
    if (this.state.pagination.totalEntries <= 10 || this.state.isFetching) return null;

    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.should-order-spare-parts.select-vendor-modal.total-entries-vendors"
            values={{
              amount: this.state.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.pagination.totalPages}
          onSelectPage={page => {
            this.setState({ isSearching: true, page });
            this.fetchAllVendors({ search: this.state.searchTerm, page }).then(
              ({ vendorIds, pagination }) => {
                this.setState({
                  isSearching: false,
                  vendorIds,
                  pagination,
                });
              }
            );
          }}
        />
      </div>
    );
  };

  renderContent = () => {
    if (this.state.selectedTab === 'sparePartVendors') {
      return this.renderSparePartVendors();
    } else {
      return this.renderVendors();
    }
  };

  renderTabBar = () => {
    if (this.state.hideTabBar) {
      return null;
    }
    return (
      <Modal.Header.TabBar>
        <Modal.Header.TabBarItem
          active={this.state.selectedTab === 'sparePartVendors'}
          onClick={() => this.selectTab('sparePartVendors')}
        >
          <FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.tabs.for-spare-part" />
        </Modal.Header.TabBarItem>
        <Modal.Header.TabBarItem
          active={this.state.selectedTab === 'vendors'}
          onClick={() => this.selectTab('vendors')}
        >
          <FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.tabs.all" />
        </Modal.Header.TabBarItem>
      </Modal.Header.TabBar>
    );
  };

  render() {
    if (this.state.isFetchingDataForModal) {
      return (
        <Modal isOpen={this.props.open} width={720}>
          <Modal.Loader />
        </Modal>
      );
    }
    return (
      <Modal isOpen={this.props.open} width={720}>
        <Modal.Header
          title={<FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.title" />}
          subtitle={<FormattedMessage id="screens.should-order-spare-parts.select-vendor-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
          tabBarComponent={this.renderTabBar()}
        />
        <Modal.Content grayBackground noPadding>
          <div className={styles['content']}>{this.renderContent()}</div>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.sparePartId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectVendorModal));
