import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';

class CantEditReusableChecklistModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={450}>
        <Modal.Header
          ignoreLine
          hideCloseButton
          title={<FormattedMessage id="components.cant-edit-reusable-checklist-modal.title" />}
          subtitle={<FormattedMessage id="components.cant-edit-reusable-checklist-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="components.cant-edit-reusable-checklist-modal.button"
              onClick={() => {
                this.props.history.push(`/checklists/${this.props.id}`);
              }}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(CantEditReusableChecklistModal);
