import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';

class DeleteSparePartAssetModal extends Component {
  state = {
    isDeleting: false,
    showConfirmDeleteModal: false,
  };

  getInitialState = () => ({
    isDeleting: false,
    showConfirmDeleteModal: true,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  deleteSparePartAsset() {
    this.setState({ isDeleting: true });
    this.props.deleteSparePartAsset(this.props.sparePartAsset.id).then(() => {
      toast(
        <ToastMessage success text={<FormattedMessage id="screens.asset.spare-parts.delete-success" />} />
      );
      this.setState({ isDeleting: false });
    });
  }

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={this.props.title}
        message={this.props.subtitle}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => {
          this.deleteSparePartAsset();
          this.props.onClose();
          this.setState({ showConfirmDeleteModal: false });
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          this.props.onClose();
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePartAsset: SDKReduxOperations.deleteSparePartAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartAsset: EntitySelectors.getSparePartAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSparePartAssetModal);
