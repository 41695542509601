import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CalendarSelectors } from 'state/ducks/calendar';
import { ToolbarListType } from 'views/scenes/Planning/Calendar/Calendar/utils';
import styles from './style.module.scss';
import Manage from './Manage';
import Overdue from './Overdue';
import Requests from './Requests';
import AwaitingDelivery from './AwaitingDelivery';

class ListBar extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.isShowingList === false && nextProps.isShowingList === false) return false;
    return true;
  }

  renderManageListType = () => {
    switch (this.props.listType) {
      case ToolbarListType.Manage: {
        return <Manage />;
      }
      case ToolbarListType.Overdue: {
        return <Overdue />;
      }
      case ToolbarListType.Requests: {
        return <Requests />;
      }
      case ToolbarListType.AwaitingDelivery: {
        return this.props.settings.spare_parts_activated ? <AwaitingDelivery /> : null;
      }
      default:
        return null;
    }
  };

  isDragging = () => {
    return (
      this.props.draggingWorkOrderId != null ||
      this.props.draggingRequestId != null ||
      this.props.draggingRecurringMaintenanceId != null
    );
  };

  render() {
    let classNames = [styles['list-bar']];
    if (this.props.isShowingList) classNames = [...classNames, styles['show']];
    if (this.isDragging()) classNames = [...classNames, styles['hide-when-dragging']];
    return (
      <div className={classNames.join(' ')}>
        <PerfectScrollbar>{this.renderManageListType()}</PerfectScrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    draggingWorkOrderId: CalendarSelectors.getDraggingWorkOrderId(state),
    draggingRequestId: CalendarSelectors.getDraggingRequestId(state),
    settings: AuthSelectors.getSettings(state),
    draggingRecurringMaintenanceId: CalendarSelectors.getDraggingRecurringMaintenanceId(state),
    isShowingList: CalendarSelectors.isShowingList(state),
    listType: CalendarSelectors.getToolboxListType(state),
  };
}

export default connect(mapStateToProps)(ListBar);
