import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class Progress extends Component {
  getProgressWidthPercentage = () => {
    let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;

    let filledRowsCount = 0;
    if (this.props.checklistInstance) {
      filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
    }
    return `${(filledRowsCount / amountOfRows) * 100}%`;
  };

  renderAmountOfFilledInRowsText = () => {
    let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;

    let filledRowsCount = 0;
    if (this.props.checklistInstance) {
      filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
    }
    return `${filledRowsCount}/${amountOfRows}`;
  };

  renderFilledInProgressBar = () => {
    return <div className={styles['filled-in']} style={{ width: this.getProgressWidthPercentage() }} />;
  };

  renderIcon = () => {
    if (this.props.showIcon) {
      return (
        <div className={styles['icon']}>
          <Icon type="list" size={11} />
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.props.checklistTemplateVersion == null) {
      return null;
    }
    return (
      <div className={styles['progress-container']}>
        {this.renderIcon()}
        <div className={styles['text']}>{this.renderAmountOfFilledInRowsText()}</div>
        <div className={styles['progress']}>{this.renderFilledInProgressBar()}</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrderId } = ownProps;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  let checklistInstances = EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances);
  let checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    workOrder.checklist_template_links
  );
  let checklistInstance = null;
  let checklistTemplateLink = null;
  let checklistTemplateVersion = null;
  let checklistTemplate = null;
  if (checklistInstances && checklistInstances.length === 1) {
    checklistInstance = checklistInstances[0];
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    );
  }
  if (checklistTemplateLinks && checklistTemplateLinks.length === 1) {
    checklistTemplateLink = checklistTemplateLinks[0];
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLink.checklist_template_id
    );
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version_id
    );
  }
  return {
    checklistInstance,
    checklistTemplateLink,
    checklistTemplateVersion,
  };
}

export default connect(mapStateToProps)(Progress);
