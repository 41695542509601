import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ContentLoader, Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class QrCodeTemplateListItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  getInititalState = () => ({
    showItemDropdown: false,
  });

  renderIconButtons = () => {
    if (this.props.qrTemplate.template_type === 'asset' && this.props.canEditAssets === false) {
      return null;
    }
    if (this.props.qrTemplate.template_type === 'spare_part' && this.props.canEditSpareParts === false) {
      return null;
    }
    return (
      <>
        <div className={styles['buttons-container']}>
          <Button
            type="icon"
            icon={<Icon type="pen" regular />}
            onClick={e => {
              e.stopPropagation();
              this.props.onEdit();
            }}
          />
          <div ref={ref => (this.itemDropdown = ref)}>
            <Button
              type="icon"
              icon={<Icon type="ellipsis-h" regular />}
              onClick={e => {
                e.stopPropagation();
                this.setState({ showItemDropdown: !this.state.showItemDropdown });
              }}
            />
          </div>
        </div>
        <NewInlineModal
          minWidth={200}
          position="right"
          positionToRef={this.itemDropdown}
          open={this.state.showItemDropdown}
          onClose={() => this.setState({ showItemDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showItemDropdown: false,
                  });
                  if (this.props.qrTemplate.default) {
                    this.props.onRemoveAsDefault(this.props.id);
                  } else {
                    this.props.onSetAsDefault(this.props.id);
                  }
                }}
              >
                <FormattedMessage
                  id={
                    this.props.qrTemplate.default
                      ? 'components.print-qr-codes.remove-as-default'
                      : 'components.print-qr-codes.add-as-default'
                  }
                />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={e => {
                  e.stopPropagation();
                  this.setState({ showItemDropdown: false });
                  this.props.onDelete();
                }}
              >
                <FormattedMessage id="general.delete" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <div
          onClick={() => this.props.onPreview()}
          className={`${styles['template-list-item']} ${this.props.selected ? styles['selected'] : ''}`}
        >
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <ContentLoader width={80} height={12} dark />
            </div>
            <div style={{ marginTop: '10px' }}>
              <div className={styles['subtitle-container']}>
                <div className={styles['subtitle']}>
                  <ContentLoader width={40} height={8} dark />
                </div>
              </div>
              <div className={styles['subtitle-container']} style={{ marginTop: '10px' }}>
                <div className={styles['subtitle']}>
                  <ContentLoader width={40} height={8} dark />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        onClick={() => this.props.onPreview()}
        className={`${styles['template-list-item']} ${this.props.selected ? styles['selected'] : ''}`}
      >
        <div className={styles['title-container']}>
          <div className={styles['title']}>{this.props.qrTemplate.title}</div>
          <div style={{ marginTop: '10px' }}>
            <div className={styles['subtitle-container']}>
              <div className={styles['subtitle']}>
                <FormattedMessage id={'resources.qr-template.width'} />:
              </div>
              &nbsp;
              <div className={styles['value']}>{`${this.props.qrTemplate.width} mm`}</div>
            </div>
            <div className={styles['subtitle-container']}>
              <div className={styles['subtitle']}>
                <FormattedMessage id={'resources.qr-template.height'} />:
              </div>
              &nbsp;
              <div className={styles['value']}>{`${this.props.qrTemplate.height} mm`}</div>
            </div>
          </div>
        </div>
        {this.renderIconButtons(this.props.qrTemplate.id)}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    qrTemplate: EntitySelectors.getQrTemplate(state, id),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default connect(mapStateToProps)(QrCodeTemplateListItem);
