import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeSparePart } from 'sdk/Schemas';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { Button, List } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import SparePartListItem from './SparePartListItem';

class SelectMultipleSparePartsModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    isFetching: true,
    sparePartIds: [],
    addedSparePartIds: {},
  });

  /*
    addedSparePartIds: { [sparePartId]: true,  }
  */

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  refs = {};

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let addedSparePartIds = {};
      this.refs = {};
      this.props.purchaseOrderRows.forEach(por => {
        const { spare_part_id } = por;
        if (spare_part_id) {
          addedSparePartIds = {
            ...addedSparePartIds,
            [spare_part_id]: true,
          };
        }
      });

      this.setState({ ...this.getInitialState(), addedSparePartIds });
      this.listSpareParts({
        spare_part_vendors: { vendor_id: this.props.vendorId },
        spare_part_vendors_for_vendor: this.props.vendorId,
        stock_quantity: {
          low: true,
        },
        no_pagination: true,
        ...this.props.filterParams,
      })
        .then(sparePartIds => {
          this.setState({ sparePartIds, isFetching: false, isEmpty: sparePartIds.length === 0 });
        })
        .catch(() => {});
    }
  }

  /*
    Requests
  */

  listSpareParts = params => {
    return API.listSpareParts(this.props.currentSystem.id, params).then(({ data: spareParts }) => {
      const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
      this.props.updateEntities(entities);
      return sparePartIds;
    });
  };

  createPurchaseOrderRows = () => {
    if (this.state.isSaving) {
      return;
    }
    this.setState({ isSaving: true });
    let requests = [];
    Object.values(this.refs).forEach(ref => {
      if (ref.isAvailableForAddingToPurchaseOrder()) {
        let request = this.props.createPurchaseOrderRowForPurchaseOrder(
          this.props.purchaseOrderId,
          ref.getPurchaseOrderRow()
        );
        requests = [...requests, request];
      }
    });
    Promise.all(requests).then(() => {
      toast(
        <ToastMessage
          success
          text={
            <FormattedMessage id="screens.purchase-order.info.articles.select-multiple-spare-parts-modal.add-success" />
          }
        />
      );
      this.props.onClose();
    });
  };

  /*
    Render functions
  */

  renderSpareParts = () => {
    const { sparePartIds } = this.state;
    if (this.state.isFetching) {
      return (
        <List>
          <List.Item expandable loading>
            <List.Item.ImageColumn loading />
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item expandable loading>
            <List.Item.ImageColumn loading />
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    return (
      <List>
        {sparePartIds.map(id => (
          <SparePartListItem
            key={id}
            selected={this.state.addedSparePartIds[id] === true}
            vendorId={this.props.vendorId}
            id={id}
            ref={ref => {
              this.refs = {
                ...this.refs,
                [id]: ref,
              };
            }}
          />
        ))}
      </List>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={
            <FormattedMessage id="screens.purchase-order.info.articles.select-multiple-spare-parts-modal.title" />
          }
          subtitle={
            <FormattedMessage id="screens.purchase-order.info.articles.select-multiple-spare-parts-modal.subtitle" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>{this.renderSpareParts()}</Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button
              primary
              label="general.save"
              onClick={this.createPurchaseOrderRows}
              loading={this.state.isSaving}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createPurchaseOrderRowForPurchaseOrder: SDKReduxOperations.createPurchaseOrderRowForPurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectMultipleSparePartsModal);
