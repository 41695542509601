import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import HelperFunctions from 'utilities/HelperFunctions';
import { API } from 'sdk';
import LogoSvg from 'assets/images/logo.svg';
import { Button, FieldErrorWrapper } from 'views/components/Shared/General';
import { Input, Button as LoginButton, Layout, RedirectAuthedUserToPrivateApp } from 'views/components/Login';
import styles from './style.module.scss';

class AcceptInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      invite: null,
      password: '',
      confirmPassword: '',
      isCreatingAccount: false,
      showPasswordNotMatchingError: false,
      inviteNotFound: false,
      shouldNavigateBackToApp: false,
      showSuccessMessage: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Accept Invite');
    const token = this.props.match.params.token;
    API.fetchInvite(token)
      .then(({ data: invite }) => {
        this.setState({ invite, email: invite.email, isFetching: false });
      })
      .catch(e => {
        if (e.response && e.response.status === 404) {
          this.setState({ inviteNotFound: true, isFetching: false });
        }
      });
  }

  createAccount = () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        showPasswordNotMatchingError: true,
      });
      return;
    }
    this.setState({ isCreatingAccount: true });
    API.acceptInvite({ token: this.props.match.params.token, password: this.state.password })
      .then(res => {
        this.setState({ showSuccessMessage: true, isCreatingAccount: false });
      })
      .catch(() => {
        this.setState({ isCreatingAccount: false });
      });
  };

  renderInviteText = () => {
    if (this.state.invite.invited_by_user) {
      return `You have been invited to Mainter by ${this.state.invite.invited_by_user} at ${this.state.invite.invited_to_organisation}`;
    }
    return `You have been invited to Mainter at ${this.state.invite.invited_to_organisation}`;
  };

  renderInvite = () => {
    return (
      <>
        <div className={styles['text']}>Enter a password</div>
        <div className={styles['subtitle']}>{this.renderInviteText()}</div>
        <div className={styles['input-container']}>
          <Input
            type="password"
            autocomplete="new-password"
            autoFocus
            placeholder="New password"
            fluid
            value={this.state.password}
            onChange={password => {
              this.setState({ password });
            }}
          />
          <Input
            type="password"
            autocomplete="new-password"
            placeholder="Confirm password"
            error={this.state.showPasswordNotMatchingError}
            value={this.state.confirmPassword}
            onChange={confirmPassword => {
              this.setState({
                showPasswordNotMatchingError: false,
              });
              this.setState({ confirmPassword });
            }}
          />
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Create account"
            disabled={!this.state.password || !this.state.confirmPassword}
            loading={this.state.isCreatingAccount}
            onClick={this.createAccount}
          />
        </div>
      </>
    );
  };

  renderInviteNotFound = () => {
    return (
      <>
        <div className={styles['text']}>Something went wrong</div>
        <div className={styles['subtitle']}>
          This seems to be an invalid invite. It could be because the invite has already been used
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Go back to Mainter"
            onClick={() => {
              this.setState({ shouldNavigateBackToApp: true });
            }}
          />
        </div>
      </>
    );
  };

  renderContent = () => {
    if (this.state.showSuccessMessage) {
      return this.renderCreateUserSuccess();
    }
    if (this.state.inviteNotFound) {
      return this.renderInviteNotFound();
    }
    return this.renderInvite();
  };

  renderCreateUserSuccess = () => (
    <>
      <div className={styles['text']}>Your user has been created</div>
      <div className={styles['subtitle']}>You will now be able to login to Mainter</div>
      <div className={styles['button-container']}>
        <Button
          label={<span>Go back to Login</span>}
          translate={false}
          primary
          onClick={() => {
            this.setState({ shouldNavigateBackToApp: true });
          }}
        />
      </div>
    </>
  );

  render() {
    if (this.state.isFetching) {
      return null;
    }
    if (this.state.shouldNavigateBackToApp) {
      if (this.props.isUserAuthenticated) {
        return <RedirectAuthedUserToPrivateApp />;
      }
      return <Redirect to="/login" />;
    }
    return (
      <Layout>
        {this.renderContent()}
      </Layout>
    );
  }
}

export default withRouter(AcceptInvite);
