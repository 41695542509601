import types from './types';

const initiateWithStocktakingId = payload => ({
  type: types.INITIATE_WITH_STOCKTAKING_ID,
  payload: payload,
});

const clearSpareParts = payload => ({
  type: types.CLEAR_SPARE_PARTS,
  payload: payload,
});

const hideCompletedCard = payload => ({
  type: types.HIDE_COMPLETED_CARD,
  payload: payload,
});

const fetchSparePartsForStocktakingSuccess = payload => ({
  type: types.FETCH_SPARE_PARTS_FOR_STOCKTAKING_SUCCESS,
  payload: payload,
});

const setSparePartLoading = payload => ({
  type: types.SET_SPARE_PART_LOADING,
  payload: payload,
});

const setSparePartLoadingMore = payload => ({
  type: types.SET_SPARE_PART_LOADING_MORE,
  payload: payload,
});

export default {
  initiateWithStocktakingId,
  fetchSparePartsForStocktakingSuccess,
  clearSpareParts,
  setSparePartLoading,
  setSparePartLoadingMore,
  hideCompletedCard,
};
