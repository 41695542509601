import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { UserNameWrapper } from 'views/components/User';
import DroppableDay from './DroppableDay';
import styles from './style.module.scss';

class Week extends PureComponent {
  renderAssignee = () => {
    if (this.props.user) {
      return <UserNameWrapper user={this.props.user} />;
    } else if (this.props.group) {
      return this.props.group.title;
    } else if (this.props.vendor) {
      return this.props.vendor.name;
    }
  };

  render() {
    const { date, userId, groupId, vendorId } = this.props;
    const monday = moment(date).isoWeekday(1);
    const tuesday = moment(date).isoWeekday(2);
    const wednesday = moment(date).isoWeekday(3);
    const thursday = moment(date).isoWeekday(4);
    const friday = moment(date).isoWeekday(5);
    const saturday = moment(date).isoWeekday(6);
    const sunday = moment(date).isoWeekday(7);
    return (
      <div className={styles['week']}>
        <div className={styles['assignee']}>{this.renderAssignee()}</div>
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={monday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={tuesday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={wednesday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={thursday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={friday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={saturday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
        <DroppableDay
          onCreateWorkOrderForRequest={this.props.onCreateWorkOrderForRequest}
          onCreateWorkOrder={this.props.onCreateWorkOrder}
          date={sunday.format('YYYY-MM-DD')}
          userId={userId}
          groupId={groupId}
          vendorId={vendorId}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.userId) {
    return {
      user: EntitySelectors.getUser(state, ownProps.userId),
    };
  } else if (ownProps.groupId) {
    return {
      group: EntitySelectors.getGroup(state, ownProps.groupId),
    };
  } else if (ownProps.vendorId) {
    return {
      vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
    };
  }
}

export default connect(mapStateToProps)(Week);
