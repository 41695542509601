import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { List, Tooltip, Icon, InlineModal, Button } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';

class ContactPersonListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  delete = () => {
    this.setState({ dropdownOpen: false });
    this.props.onDelete();
  };

  renderContactPersonInfo = contactPerson => (
    <div className={styles['icons']}>
      <div className={styles['info-container']}>
        {this.props.contactPerson.phone ? (
          <Tooltip trigger={<Icon regular type="phone" />} label={this.props.contactPerson.phone} />
        ) : (
          <Icon regular disabled type="phone" />
        )}
      </div>
      <div className={styles['info-container']}>
        {this.props.contactPerson.email ? (
          <Tooltip trigger={<Icon regular type="envelope" />} label={this.props.contactPerson.email} />
        ) : (
          <Icon regular disabled type="envelope" />
        )}
      </div>
    </div>
  );

  renderIconButtons = event => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={event => {
            event.stopPropagation();
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              icon="trash-alt"
              iconThickness="regular"
              destructive
              title={<FormattedMessage id="general.delete" />}
              onClick={() => this.delete()}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item clickable onClick={this.props.onClick} new={this.props.new}>
          <List.Item.TitleColumn
            title={this.props.contactPerson.name}
            subtitle={this.props.contactPerson.position}
          />
          <List.Item.Column>{this.renderContactPersonInfo()}</List.Item.Column>
          <List.Item.Column>{this.renderIconButtons()}</List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactPerson: EntitySelectors.getContactPerson(state, ownProps.contactPersonId),
  };
}

export default connect(mapStateToProps)(ContactPersonListItem);

ContactPersonListItem.propTypes = {
  onClick: PropTypes.func,
  contactPerson: PropTypes.object,
  loading: PropTypes.bool,
  new: PropTypes.bool,
  onDelete: PropTypes.func,
};
ContactPersonListItem.defaultProps = {
  onClick: () => {},
  loading: false,
  new: false,
};
