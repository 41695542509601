import React, { Component } from 'react';
import { UploadingAttachmentContent } from 'views/components/Attachment';
import styles from './style.module.scss';

export default class UploadingAttachment extends Component {
  render() {
    return (
      <>
        <div className={styles['card']}>
          <UploadingAttachmentContent
            namespace="editOpTemplate"
            uploadingAttachment={this.props.uploadingAttachment}
          />
        </div>
      </>
    );
  }
}
