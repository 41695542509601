import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PreviewWorkOrderOverlay } from 'views/components/WorkOrder';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';

class PreviewInstance extends Component {
  render() {
    const { work_order, recurring_maintenance, users, groups, vendors, date } = this.props.instance || {};
    return (
      <PreviewWorkOrderOverlay
        open={this.props.open}
        top={this.props.top}
        left={this.props.left}
        extraWidth={this.props.extraWidth}
        width={600}
        workOrderId={work_order}
        recurringMaintenanceId={recurring_maintenance}
        date={date}
        userIds={users}
        groupIds={groups}
        vendorIds={vendors}
        onClose={this.props.onClose}
        onClickBeforeNavigate={this.props.onClickBeforeNavigate}
      />
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    instance: WorkOrdersSelectors.getInstance(state, ownProps.instanceId),
  };
}

export default connect(mapStateToProps)(PreviewInstance);
