import types from './types';
import uuid from 'uuid';
import { isEqual } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  isNew: false,
  assetVendor: null,
  assetVendorBeforeEdit: null,
  assetIdsForNew: [],
  hasUnsavedChanges: false,
  contactPersonIds: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;

    case types.PREPARE_NEW_ASSET_VENDOR: {
      const payload = action.payload || {};

      const id = uuid.v4();
      const assetVendor = {
        id,
        asset_id: null,
        contact_person_id: null,
        comment: '',
        ...payload,
      };

      return {
        ...state,
        id,
        isNew: true,
        hasUnsavedChanges: false,
        assetVendor: assetVendor,
        assetVendorBeforeEdit: assetVendor,
      };
    }

    case types.CHANGE_ASSET_VENDOR_VALUES: {
      const assetVendor = {
        ...state.assetVendor,
        ...action.payload,
      };

      return {
        ...state,
        assetVendor,
        hasUnsavedChanges: !isEqual(assetVendor, state.assetVendorBeforeEdit),
      };
    }

    case types.SET_ASSET_IDS_FOR_NEW: {
      return {
        ...state,
        assetIdsForNew: action.payload,
      };
    }

    case types.SET_ASSET_VENDOR:
      return {
        ...state,
        id: action.payload.id,
        isNew: false,
        assetVendor: action.payload,
        assetVendorBeforeEdit: action.payload,
        hasUnsavedChanges: false,
      };

    case types.SET_CONTACT_PERSONS:
      return {
        ...state,
        contactPersonIds: action.payload,
      };

    case SDKReduxTypes.ASSET_VENDOR_MULTIPLE_CREATED: {
      if (state.isNew) {
        return {
          ...state,
          isNew: false,
          hasUnsavedChanges: false,
          assetIdsForNew: [],
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.ASSET_VENDOR_UPDATED: {
      const { assetVendorId } = action.payload;
      if (state.id === assetVendorId) {
        return {
          ...state,
          hasUnsavedChanges: false,
        };
      } else {
        return state;
      }
    }
    case SDKReduxTypes.ASSET_VENDOR_DELETED: {
      const { assetVendorId } = action.payload;
      if (state.id === assetVendorId) {
        return {
          ...state,
          hasUnsavedChanges: false,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};
