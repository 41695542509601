import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MeterType } from 'sdk/ChecklistTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import { Modal } from 'views/components/Shared/Layout';
import { SectionHeader } from 'views/components/Shared/General';
import TemplateAssetListItem from './TemplateAssetListItem';
import ChecklistTemplateRow from './ChecklistTemplateRow';
import SettingsForAsset from './SettingsForAsset';
import styles from './style.module.scss';

class TemplateAssetsSettingsModal extends Component {
  getInititalState = () => ({
    operationalMaintenanceTemplateAssetId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { id } = this.props.operationalMaintenanceTemplateAssets[0] || {};
      this.setState({
        ...this.getInititalState(),
        operationalMaintenanceTemplateAssetId: id,
      });
    }
  }

  renderTemplateAssets = () => {
    if (this.props.operationalMaintenanceTemplateAssets.length === 0) {
      return (
        <div className={styles['empty-data-set']}>
          <FormattedMessage id="components.manage-bookmarked-assets-modal.bookmarked-empty-data-set.title" />
        </div>
      );
    }
    return this.props.operationalMaintenanceTemplateAssets.map(({ id }) => (
      <TemplateAssetListItem
        selected={this.state.operationalMaintenanceTemplateAssetId === id}
        key={id}
        id={id}
        onClick={() =>
          this.setState({
            operationalMaintenanceTemplateAssetId: id,
          })
        }
      />
    ));
  };

  renderMenuContent = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={25} noBorderTop>
          <FormattedMessage id="screens.operational-maintenance-template.checklist-settings-modal.assets" />
        </SectionHeader>
        {this.renderTemplateAssets()}
      </>
    );
  };

  renderSelectedTemplateAssetChecklistInstanceOptions = () => {
    return this.props.checklistTemplateRows
      .filter(({ require_option }) => require_option === true)
      .map(({ id }) => (
        <ChecklistTemplateRow
          id={id}
          operationalMaintenanceTemplateAssetId={this.state.operationalMaintenanceTemplateAssetId}
        />
      ));
  };

  renderSettingsForAsset = () => {
    if (this.state.operationalMaintenanceTemplateAssetId) {
      return <SettingsForAsset templateAssetId={this.state.operationalMaintenanceTemplateAssetId} />;
    }
    return null;
  };

  renderModalTitle = () => {
    return (
      <span>
        <FormattedMessage id="screens.operational-maintenance-template.checklist-settings-modal.title" />
        <span> - </span>
        <span>{this.props.checklistTemplate.title}</span>
      </span>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header title={this.renderModalTitle()} onClose={this.props.onClose} />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>
            <Modal.ColumnLayout.Container.Content>
              {this.renderSettingsForAsset()}
              {this.renderSelectedTemplateAssetChecklistInstanceOptions()}
            </Modal.ColumnLayout.Container.Content>
          </Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const activeOperationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  let checklistTemplateRows = [];
  let checklistTemplateLinks = [];
  let checklistInstanceOptions = [];
  let checklistTemplate = null;
  let checklistTemplateVersion = null;
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    activeOperationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  const operationalMaintenanceTemplateAssets = EntitySelectors.getOperationalMaintenanceTemplateAssets(
    state,
    operationalMaintenanceTemplate.operational_maintenance_template_assets
  );
  checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    activeOperationalMaintenanceTemplateVersion.checklist_template_links
  );
  if (checklistTemplateLinks.length === 1) {
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLinks[0].checklist_template_id
    );
  }
  checklistInstanceOptions = operationalMaintenanceTemplateAssets.reduce((acc, assetTemplate) => {
    const checklistInstanceOptions = EntitySelectors.getChecklistInstanceOptions(
      state,
      assetTemplate.checklist_instance_options
    );
    return [...acc, ...checklistInstanceOptions];
  }, []);

  if (checklistTemplate) {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version
    );
  }
  if (checklistTemplateVersion) {
    checklistTemplateRows = EntitySelectors.getChecklistTemplateRows(
      state,
      checklistTemplateVersion.checklist_template_rows
    );
  }

  return {
    checklistTemplate,
    operationalMaintenanceTemplateAssets,
    checklistTemplateRows,
  };
}

export default connect(mapStateToProps)(TemplateAssetsSettingsModal);
