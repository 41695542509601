import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Field } from 'views/components/Shared/General';

export default class ChoosePriorityInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  selectPriority = priority => {
    if (this.props.multiple) {
      if (this.props.selectedPriorities.includes(priority)) {
        this.props.onRemovePriority(priority);
      } else {
        this.props.onAddPriority(priority);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectPriority(priority);
        }
      );
    }
  };

  renderPriorityItems = priority => {
    if (this.props.multiple) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('high')}
            leftComponent={
              <Field.Checkbox
                checked={this.props.selectedPriorities.includes('high')}
                onChange={() => {
                  this.selectPriority('high');
                }}
              />
            }
          >
            <FormattedMessage id="resources.work-order.prio.high" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('medium')}
            leftComponent={
              <Field.Checkbox
                checked={this.props.selectedPriorities.includes('medium')}
                onChange={() => {
                  this.selectPriority('medium');
                }}
              />
            }
          >
            <FormattedMessage id="resources.work-order.prio.medium" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('low')}
            leftComponent={
              <Field.Checkbox
                checked={this.props.selectedPriorities.includes('low')}
                onChange={() => {
                  this.selectPriority('low');
                }}
              />
            }
          >
            <FormattedMessage id="resources.work-order.prio.low" />
          </NewInlineModal.Dropdown.Item>
        </>
      );
    } else {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('high')}
            selected={this.props.selectedPriority === 'high'}
          >
            <FormattedMessage id="resources.work-order.prio.high" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('medium')}
            selected={this.props.selectedPriority === 'medium'}
          >
            <FormattedMessage id="resources.work-order.prio.medium" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={e => this.selectPriority('low')}
            selected={this.props.selectedPriority === 'low'}
          >
            <FormattedMessage id="resources.work-order.prio.low" />
          </NewInlineModal.Dropdown.Item>
        </>
      );
    }
  };

  renderClearItem = () => {
    const { multiple, selectedPriority } = this.props;
    if (multiple === false && selectedPriority) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => {
              this.setState({ isOpen: false });
              this.props.onClear();
            }}
          >
            <FormattedMessage id="general.clean" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState({ isOpen: true })}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.renderClearItem()}
              {this.renderPriorityItems()}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

ChoosePriorityInlineModal.propTypes = {
  selectedPriority: PropTypes.string,
  selectedPriorities: PropTypes.array,
  onSelectPriority: PropTypes.func,
  onAddPriority: PropTypes.func,
  onRemovePriority: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
};

ChoosePriorityInlineModal.defaultProps = {
  selectedPriority: null,
  selectedPriorities: [],
  onSelectPriority: () => {},
  onAddPriority: () => {},
  onRemovePriority: () => {},
  onClear: () => {},
  multiple: false,
};
