import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { injectIntl } from 'react-intl';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { WhiteCard } from 'views/components/Shared/General';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './style.module.scss';

class MainInfoContainer extends Component {
  state = {
    title: this.props.editingWorkOrder.title || '',
    description: this.props.editingWorkOrder.description || '',
  };

  isCompletedBeforeRegistration = () => this.props.editingWorkOrder.completed_before_registration;

  render() {
    let className = [styles['white-card']];
    if (this.props.fieldErrors.description) {
      className = [...className, styles['has-error']];
    }
    return (
      <div className={className.join(' ')}>
        <TextareaAutosize
          minRows={3}
          className={styles['editor-container']}
          placeholder={this.props.intl.formatMessage({ id: 'screens.work-order.edit-description' })}
          value={this.state.description}
          onChange={e => {
            if (this.props.fieldErrors.description) {
              this.props.removeFieldsFromErrors(['description']);
            }
            this.setState({ description: e.target.value });
          }}
          onBlur={() => this.props.setEditingWorkOrderValues({ description: this.state.description })}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MainInfoContainer)));
