import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';

class DeleteModal extends Component {
  state = {
    isDeleting: false,
  };

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) return false;
    return true;
  }

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteStocktaking(this.props.match.params.id)
      .then(() => {
        this.setState({ isDeleting: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="screens.stocktaking-detail.delete-modal.title" />}
          subtitle={<FormattedMessage id="screens.stocktaking-detail.delete-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              destructive
              loading={this.state.isDeleting}
              label="general.delete"
              onClick={this.delete}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteStocktaking: SDKReduxOperations.deleteStocktaking,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal));
