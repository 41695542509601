import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { getIconClassNameString, getIconColor } from 'sdk/AttachmentVersion';
import { Button, Icon, Tooltip } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import styles from './style.module.scss';

const Attachment = props => {
  const renderIconButton = () => {
    if (props.attachmentVersion.type === 'file') {
      return (
        <Tooltip
          trigger={
            <Button
              type="icon"
              icon={<Icon regular type="download" />}
              onClick={() => {
                HelperFunctions.openAttachment(props.attachmentVersion);
              }}
            />
          }
          label={<FormattedMessage id="components.checklist-instance-modal.download-file" />}
        />
      );
    }
    return (
      <Tooltip
        trigger={
          <Button
            type="icon"
            icon={<Icon regular type="external-link" />}
            onClick={() => {
              HelperFunctions.openAttachment(props.attachmentVersion);
            }}
          />
        }
        label={<FormattedMessage id="components.checklist-instance-modal.open-link" />}
      />
    );
  };
  return (
    <div className={styles['attachment']}>
      <div className={styles['type']}>
        <Icon
          type={getIconClassNameString(props.attachmentVersion)}
          color={getIconColor(props.attachmentVersion)}
        />
      </div>
      <div className={styles['title']}>{props.attachmentVersion.title}</div>
      <div className={styles['icon-button']}>{renderIconButton()}</div>
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const attachment = EntitySelectors.getAttachment(state, id);
  return {
    attachmentVersion: EntitySelectors.getAttachmentVersion(state, attachment.attachment_version),
  };
}

export default connect(mapStateToProps)(Attachment);
