import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class EditRequestTypeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      hasUnsavedChanges: false,
      editingRequestType: {},
      editingRequestTypeBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        hasUnsavedChanges: false,
      });

      const { requestType } = this.props;
      if (requestType) {
        const editingRequestType = { title: requestType.title };
        this.setState({
          editingRequestType,
          editingRequestTypeBeforeEdit: editingRequestType,
        });
      } else {
        const editingRequestType = {
          title: '',
        };
        this.setState({
          editingRequestType,
          editingRequestTypeBeforeEdit: editingRequestType,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createRequestType(this.props.system.id, this.state.editingRequestType)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.requests.types.create-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updateRequestType(this.props.id, this.state.editingRequestType)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  setValue = values => {
    const editingRequestType = {
      ...this.state.editingRequestType,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingRequestTypeBeforeEdit, editingRequestType);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingRequestType, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderSubtitle = () => {
    if (this.props.requestType) {
      const { request_count: count } = this.props.requestType;
      if (count === 0) {
        return <FormattedMessage id="screens.settings.requests.types.never-used" />;
      }

      return (
        <FormattedPlural
          value={count}
          one={<FormattedMessage id="screens.settings.requests.types.used-amount.one" />}
          two={<FormattedMessage id="screens.settings.requests.types.used-amount.two" values={{ count }} />}
          few={<FormattedMessage id="screens.settings.requests.types.used-amount.few" values={{ count }} />}
          many={<FormattedMessage id="screens.settings.requests.types.used-amount.many" values={{ count }} />}
          other={
            <FormattedMessage id="screens.settings.requests.types.used-amount.other" values={{ count }} />
          }
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.requests.types.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.requests.types.new-title" />
            )
          }
          subtitle={this.renderSubtitle()}
          iconButtons={
            this.props.id == null ? null : (
              <Button
                type="icon"
                icon={<Icon regular red type="trash-alt" />}
                onClick={() => this.props.onDelete(this.props.id)}
              />
            )
          }
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Field view={false} label={<FormattedMessage id="resources.cost-other-category.title" />}>
            <Field.Text
              autoFocus
              error={this.state.showTitleError}
              value={this.state.editingRequestType.title}
              onKeyPress={this.handleKeyPress}
              onChange={title => {
                this.setValue({ title });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.editingRequestType.title}
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRequestType: SDKReduxOperations.createRequestType,
      updateRequestType: SDKReduxOperations.updateRequestType,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    requestType: EntitySelectors.getRequestType(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditRequestTypeModal));
