import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import ContentLoader from 'react-content-loader';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { normalizeVendor } from 'sdk/Schemas';
import { List, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import VendorListItem from './VendorListItem';

const listVendorsRequest = SDKHelperFunctions.getCancelTokenForRequest();
class AllVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorIds: null,
      isLoading: true,
      isLoadingPagination: false,
      totalAmountOfVendors: 0,
      isChangingPage: false,
      page: 1,
      totalPages: 0,
    };
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchVendors();
    }, 300);
  }

  componentDidMount() {
    this.fetchVendors();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ isLoading: true, isLoadingPagination: true, page: 1 });
      this.debouncedSearchValueWasChanged();
    }
  }

  changePage = page => {
    this.setState({ isChangingPage: true, page }, () => {
      this.fetchVendors({ page });
    });
  };

  fetchVendors = params => {
    listVendorsRequest.cancel();
    params = { ...params, search: this.props.searchValue };
    this.setState({ isLoading: true });
    return API.listVendors(
      this.props.currentSystem.id,
      {
        page_size: 8,
        ...params,
        ...this.props.additionalApiParams,
      },
      listVendorsRequest.getCancelTokenConfig()
    )
      .then(res => {
        const { data, headers } = res;
        const { entities, result } = normalizeVendor(data);
        this.props.updateEntities(entities);
        this.setState({
          vendorIds: result,
          isLoading: false,
          isChangingPage: false,
          isLoadingPagination: false,
          totalAmountOfVendors: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
          totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        });
      })
      .catch(e => {
        this.setState({ isLoading: false, isChangingPage: false, isLoadingPagination: false });
      });
  };

  renderLoaders = () => {
    if (!this.state.vendorIds || this.state.vendorIds.length === 0) {
      return (
        <>
          <VendorListItem loading />
          <VendorListItem loading />
        </>
      );
    } else
      return this.state.vendorIds.map(() => {
        return <VendorListItem loading />;
      });
  };

  renderPaginationLoader = () => {
    return (
      <ContentLoader
        speed={2}
        width={839}
        height={60}
        viewBox="0 0 839 60"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="330" y="22" rx="3" ry="3" width="180" height="18" />
      </ContentLoader>
    );
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          subtitle={<FormattedMessage id="components.select-vendor-modal.content.empty-content" />}
        ></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderList = () => {
    return this.state.vendorIds.map(vendorId => {
      return (
        <VendorListItem
          listItemRightComponent={this.props.listItemRightComponent}
          loading={this.state.isLoading}
          id={vendorId}
        />
      );
    });
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet subtitle={<FormattedMessage id="general.empty-data-set-search.title" />} />
      </WhiteCard>
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          subtitle={<FormattedMessage id="components.select-vendor-modal.content.empty-content" />}
        ></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderListContent = () => {
    if (this.state.isLoading || this.state.isChangingPage)
      return (
        <>
          {this.renderListHeader()}
          <List>{this.renderLoaders()}</List>
        </>
      );
    if (this.props.searchValue && this.state.vendorIds.length === 0) {
      return this.renderEmptySearch();
    }
    if (!this.state.isLoading && this.props.searchValue === '' && this.state.vendorIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>{this.renderList()}</List>
      </>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-vendor-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderPagination = () => {
    if (this.state.totalAmountOfVendors <= 8) return null;

    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };
  render() {
    return (
      <>
        <Modal.ColumnLayout.Container.Content>
          {this.renderListContent()}
        </Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVendors);
