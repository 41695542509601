import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { API } from 'sdk';
import { AssetsSelectors } from 'state/ducks/assets';
import { Field, Button } from 'views/components/Shared/General';
import { ChooseSparePartInlineModal } from 'views/components/SparePart';
import SparePartContainer from './SparePartContainer';
import QuantityField from './QuantityField';

class AddSparePartModal extends Component {
  state = {
    isSaving: false,
    sparePartId: null,
    quantity: '',
    showSparePartRequiredError: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        sparePartId: null,
        quantity: '',
        showSparePartRequiredError: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        filters: {
          ...this.props.filtersAsQueryParams,
          ...this.props.queryParameters,
        },
      };
    }
    return {
      filters: {
        id: {
          any: Object.keys(this.props.checkedAssetIds).toString(),
        },
      },
    };
  };

  buildSparePartAssetParams = () => {
    return {
      spare_part_asset: {
        spare_part_id: this.state.sparePartId,
        quantity: this.state.quantity,
      },
    };
  };

  addSparePart = () => {
    if (this.state.sparePartId == null) {
      this.setState({ showSparePartRequiredError: true });
      return;
    }
    this.setState({ isSaving: true });
    API.batchCreateSparePartAsset(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildSparePartAssetParams(),
    }).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.props.onClose();
    });
  };

  renderSparePart = () => {
    if (this.state.sparePartId == null) {
      return null;
    }
    return <SparePartContainer id={this.state.sparePartId} />;
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedAssetCount}
        one={<FormattedMessage id="components.batch-add-spare-part-modal.total-assets.one" />}
        two={
          <FormattedMessage
            id="components.batch-add-spare-part-modal.total-assets.two"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.batch-add-spare-part-modal.total-assets.few"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.batch-add-spare-part-modal.total-assets.many"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.batch-add-spare-part-modal.total-assets.other"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
      />
    );
  };

  renderQuantityRow = () => {
    if (this.state.sparePartId) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.quantity" />}>
              <QuantityField
                value={this.state.quantity}
                onChange={quantity => this.setState({ quantity })}
                sparePartId={this.state.sparePartId}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.batch-add-spare-part-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.spare-part.resource" />}>
                  <ChooseSparePartInlineModal
                    trigger={
                      <Field.Resource
                        angleDown
                        error={this.state.showSparePartRequiredError}
                        value={this.renderSparePart()}
                        onClear={() => this.setState({ sparePartId: null })}
                      />
                    }
                    selectedSparePartId={this.state.sparePartId}
                    onSelectSparePart={id => {
                      this.setState({ showSparePartRequiredError: false, sparePartId: id, quantity: '' });
                    }}
                    clearable={false}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            {this.renderQuantityRow()}
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              label="general.save"
              onClick={() => {
                this.addSparePart();
              }}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    filtersAsQueryParams: AssetsSelectors.getFiltersAsQueryParams(state),
    checkedAssetIds: AssetsSelectors.getSelectedAssetIds(state),
    checkedAssetCount: AssetsSelectors.getSelectedAssetCount(state),
    totalEntriesIsSelected: AssetsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default connect(mapStateToProps)(AddSparePartModal);
