const DOMAIN_PREFIX = 'bookmarkedAssetsDropdown/';

const FETCH_BOOKMARKED_ASSETS_SUCCESS = `${DOMAIN_PREFIX}FETCH_BOOKMARKED_ASSETS_SUCCESS`;
const SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN = `${DOMAIN_PREFIX}SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN`;
const SELECT_ASSET_ID_FOR_ASSET_DROPDOWN = `${DOMAIN_PREFIX}SELECT_ASSET_ID_FOR_ASSET_DROPDOWN`;

export default {
  FETCH_BOOKMARKED_ASSETS_SUCCESS,
  SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN,
  SELECT_ASSET_ID_FOR_ASSET_DROPDOWN,
};
