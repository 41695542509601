import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { TemplateFieldType } from 'sdk/TemplateField';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Field, Button, Icon, ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import ManageTemplateFieldItemsModal from './ManageTemplateFieldItemsModal';
import styles from './style.module.scss';

class EditTemplateFieldMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.templateField?.title || '',
      isSaving: false,
      showManageTemplateFieldItemsModal: false,
      showConfirmDeleteTemplateFieldModal: false,
      isDeletingSelectedTemplateField: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.titleRef.focus();
    }, 200);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templateFieldId !== this.props.templateFieldId) {
      this.setState({ title: this.props.templateField?.title || '' });
      this.titleRef.focus();
    }
  }

  titleIsEmpty = () => this.state.title.length === 0;

  save = () => {
    const { title } = this.state;
    this.setState({ isSaving: true });
    if (this.props.templateFieldId == null) {
      let params = {
        ...this.props.newTemplateField,
        title: title,
      };

      this.props.createTemplateField(this.props.currentSystem.id, params).then(({ data }) => {
        this.setState({ isSaving: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onCreateNewTemplateField(data);
      });
    } else {
      this.props.updateTemplateField(this.props.templateFieldId, { title }).then(() => {
        this.setState({ isSaving: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      });
    }
  };

  renderHandleValuesButton = () => {
    if (this.props.templateField == null) {
      return null;
    }
    if (this.props.templateField.type !== TemplateFieldType.ValueList) return null;

    return (
      <div className={styles['handle-values-container']}>
        <Button
          gray
          label="screens.settings.assets.manage-template-fields-modal.right-menu.manage-items"
          onClick={() => this.setState({ showManageTemplateFieldItemsModal: true })}
          disabled={this.titleIsEmpty()}
        />
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.templateField == null) {
      return (
        <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.right-menu.new-field" />
      );
    }
    return (
      <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.right-menu.edit-field" />
    );
  };

  renderConfirmDeleteTemplateFieldModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteTemplateFieldModal}
        title={
          <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.delete-field-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.delete-field-confirm-modal.message" />
        }
        confirmIsLoading={this.state.isDeletingSelectedTemplateField}
        onConfirm={() => {
          this.setState({ isDeletingSelectedTemplateField: true });
          this.props.deleteTemplateField(this.props.templateFieldId).then(() => {
            this.props.onDeleteTemplateField(this.props.templateFieldId);
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          });
        }}
        onCancel={() => this.setState({ showConfirmDeleteTemplateFieldModal: false })}
      />
    );
  };

  renderManageTemplateFieldItemsModal = () => {
    return (
      <ManageTemplateFieldItemsModal
        open={this.state.showManageTemplateFieldItemsModal}
        templateFieldId={this.props.templateFieldId}
        onClose={() => this.setState({ showManageTemplateFieldItemsModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['container']}>
          <div className={styles['title-and-close-container']}>
            <span className={styles['title']}>{this.renderTitle()}</span>
            <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
          </div>
          <div className={styles['edit-container']}>
            <Field label={<FormattedMessage id="resources.template-field.title" />}>
              <Field.Text
                value={this.state.title}
                ref={ref => (this.titleRef = ref)}
                onChange={title => this.setState({ title })}
              />
            </Field>
            {this.renderHandleValuesButton()}
          </div>
          <div className={styles['save-container']}>
            <Button
              label="general.save"
              primary
              disabled={this.titleIsEmpty()}
              onClick={this.save}
              loading={this.state.isSaving}
            />
            {this.props.templateField == null ? (
              <Button
                label="general.cancel"
                onClick={this.props.onClose}
                loading={this.props.deleteLoading}
              />
            ) : (
              <Button
                label="general.delete"
                primary
                destructive
                onClick={() => this.setState({ showConfirmDeleteTemplateFieldModal: true })}
              />
            )}
          </div>
        </div>
        {this.renderManageTemplateFieldItemsModal()}
        {this.renderConfirmDeleteTemplateFieldModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTemplateField: SDKReduxOperations.updateTemplateField,
      createTemplateField: SDKReduxOperations.createTemplateField,
      deleteTemplateField: SDKReduxOperations.deleteTemplateField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { templateFieldId } = ownProps;

  return {
    templateField: EntitySelectors.getTemplateField(state, templateFieldId),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateFieldMenu);
