import { API, HelperFunctions } from 'sdk';
import { normalizePurchaseOrder } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import actions from './actions';

const setNavigatedTo = actions.setNavigatedTo;

const resetSelectedPurchaseOrders = actions.resetSelectedPurchaseOrders;
const selectPurchaseOrder = actions.selectPurchaseOrder;
const selectPage = actions.selectPage;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;
const showLoader = actions.showLoader;

const fetchPurchaseOrders = (systemId, params, config) => dispatch => {
  return API.listPurchaseOrders(systemId, params, config).then(({ data: purchaseOrders, headers }) => {
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result: ids } = normalizePurchaseOrder(purchaseOrders);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchPurchaseOrdersSuccess({
        ids,
        pagination,
      })
    );
    return purchaseOrders;
  });
};

export default {
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
  resetSelectedPurchaseOrders,
  selectPurchaseOrder,
  showAppliedFilters,
  showAvailableFilters,
  selectFilterType,
  addFilter,
  removeFilter,
  resetFilter,
  addQueryParameter,
  showLoader,
  fetchPurchaseOrders,
  setNavigatedTo,
};
