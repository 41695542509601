import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetTitle } from 'views/components/Asset';

const AssetContainer = ({ asset }) => {
  return <AssetTitle id={asset.id} />;
};

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AssetContainer);
