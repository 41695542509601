import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import { getVendorSubtitle } from 'sdk/Vendor';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, FieldErrorWrapper, Button } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import styles from './styles.module.scss';

class SparePartVendorInfo extends Component {
  render = () => {
    if (this.props.vendor == null) {
      return null;
    }
    return (
      <>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <div className={styles['vendor-container']}>
              <div className={styles['info']}>
                <div className={styles['name']}>{this.props.vendor.name}</div>
                <div className={styles['subtitle']}>{getVendorSubtitle(this.props.vendor)}</div>
              </div>
              <div className={styles['right-container']}>
                <FormattedMessage id="resources.spare-part-vendor.vendor" />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-vendor.number" />}>
              <Field.Text
                value={this.props.editingSparePartVendor.number}
                onChange={number => this.props.onChangeEditingSparePartVendor({ number })}
              />
            </Field>
            <Button
              type="text"
              translate={false}
              primary
              fontSize={12}
              label={
                <FormattedMessage
                  id="components.new-spare-part-modal.use-article-number-from-spare-part"
                  values={{ number: this.props.editingSparePart.article_number }}
                />
              }
              noUnderline
              onClick={() => {
                this.props.onChangeEditingSparePartVendor({
                  number: this.props.editingSparePart.article_number,
                });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-vendor.title" />}>
              <Field.Text
                value={this.props.editingSparePartVendor.title}
                placeholder={this.props.titlePlaceholder}
                onChange={title => this.props.onChangeEditingSparePartVendor({ title })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-vendor.price" />}>
              <Field.Money
                currency={this.props.vendor.purchase_order_currency}
                value={this.props.editingSparePartVendor.purchase_price}
                onChange={purchase_price => this.props.onChangeEditingSparePartVendor({ purchase_price })}
                onBlur={purchase_price => this.props.onChangeEditingSparePartVendor({ purchase_price })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              questionTooltipContent={
                <FormattedMessage id="resources.spare-part-vendor.package-quantity-description" />
              }
              checked={this.props.showPackageQuantity}
              onChange={this.props.onTogglePackageQuantity}
              label={<FormattedMessage id="resources.spare-part-vendor.package-quantity" />}
            />
            <AnimateHeight
              duration={250}
              height={this.props.showPackageQuantity ? 'auto' : 0}
              onAnimationEnd={() => {
                if (this.props.showPackageQuantity) {
                  this.packageField.focus();
                }
              }}
            >
              <div className={styles['package-field']}>
                <FieldErrorWrapper
                  position="top"
                  show={this.props.showPackageQuantityBiggerThanZeroError}
                  errorElement={
                    <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.errors.package-bigger-than-zero" />
                  }
                >
                  <Field.Decimal
                    error={this.props.showPackageQuantityBiggerThanZeroError}
                    ref={ref => (this.packageField = ref)}
                    rightLabel={
                      this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation
                    }
                    value={this.props.editingSparePartVendor.title}
                    onChange={package_quantity => {
                      this.props.onChangeEditingSparePartVendor({ package_quantity });
                    }}
                    onBlur={package_quantity => {
                      this.props.onChangeEditingSparePartVendor({ package_quantity });
                    }}
                  />
                </FieldErrorWrapper>
              </div>
            </AnimateHeight>
            <div className={styles['primary-vendor-checkbox']}>
              <Field.Checkbox
                questionTooltipContent={
                  <FormattedMessage id="resources.spare-part.primary-vendor-description" />
                }
                checked={this.props.editingSparePart.primary_vendor_id != null}
                label={<FormattedMessage id="resources.spare-part.primary-vendor" />}
                onChange={() => {
                  if (this.props.editingSparePart.primary_vendor_id) {
                    this.props.onChangeEditingSparePart({ primary_vendor_id: null });
                  } else {
                    this.props.onChangeEditingSparePart({ primary_vendor_id: this.props.vendor.id });
                  }
                }}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePartUnitId),
  };
}

export default connect(mapStateToProps)(SparePartVendorInfo);
