/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getAssetId = state => state.attachmentAssetContent.assetId;
const getFolderId = state => state.attachmentAssetContent.folderId;

const getListAttachments = state => {
  const { entities } = state;
  return state.attachmentAssetContent.list.ids.map(id => entities.attachmentById[id]);
};
const getListTotalEntries = state => state.attachmentAssetContent.list.totalEntries;
const getListIsFullyLoaded = state => state.attachmentAssetContent.list.isFullyLoaded;
const getListPaginateFrom = state => state.attachmentAssetContent.list.paginateFrom;

const getSearchAttachments = state => {
  const { entities } = state;
  return state.attachmentAssetContent.search.ids.map(id => entities.attachmentById[id]);
};
const getSearchTotalEntries = state => state.attachmentAssetContent.search.totalEntries;
const getSearchIsFullyLoaded = state => state.attachmentAssetContent.search.isFullyLoaded;
const getSearchPaginateFrom = state => state.attachmentAssetContent.search.paginateFrom;

export default {
  getAssetId,
  getFolderId,
  getListAttachments,
  getListTotalEntries,
  getListIsFullyLoaded,
  getListPaginateFrom,
  getSearchAttachments,
  getSearchTotalEntries,
  getSearchIsFullyLoaded,
  getSearchPaginateFrom,
};
