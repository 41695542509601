import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AssetSelectors } from 'state/ducks/asset';
import Header from '../../Header';
import { AssetAttachmentContent } from 'views/components/Asset';

class Attachments extends Component {
  render() {
    return (
      <React.Fragment>
        <AssetAttachmentContent
          includeContentContainer
          assetId={this.props.asset.id}
          headerComponent={<Header />}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    asset: AssetSelectors.getAsset(state),
  };
}

export default connect(mapStateToProps)(Attachments);
