import types from './types';

const selectAssetId = payload => ({
  type: types.CHECK_IN_ASSET,
  payload: payload,
});

export default {
  selectAssetId,
};
