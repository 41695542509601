import React, { Component } from 'react';
import styles from './style.module.scss';
import { Button, Field } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import DropdownItem from './DropdownItem';

class ManageProductionSupervisorPermissionsModal extends Component {
  state = {
    maintenancesValue: this.props.settings.production_supervisor_permission_operational_maintenances,
    sparePartValue: this.props.settings.production_supervisor_permission_spare_parts,
    requestValue: this.props.settings.production_supervisor_permission_requests,
    userPermissionValue: this.props.settings.production_supervisor_permission_users,
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.open && this.props.open) {
      this.setState({
        maintenancesValue: this.props.settings.production_supervisor_permission_operational_maintenances,
        sparePartValue: this.props.settings.production_supervisor_permission_spare_parts,
        requestValue: this.props.settings.production_supervisor_permission_requests,
        userPermissionValue: this.props.settings.production_supervisor_permission_users,
      });
    }
  };

  getSparePartPermissionTitle = () => {
    switch (this.state.sparePartValue) {
      case 'view':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.view.title" />
        );
      case 'create_withdrawals':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.create_withdrawals.title" />
        );
      case 'create_withdrawals_and_stock_adjustment':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.make_adjustments.title" />
        );
      default:
        return null;
    }
  };

  getSparePartPermissionSubtitle = () => {
    switch (this.state.sparePartValue) {
      case 'view':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.view.subtitle" />
        );
      case 'create_withdrawals':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.create_withdrawals.subtitle" />
        );
      case 'create_withdrawals_and_stock_adjustment':
        return (
          <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.make_adjustments.subtitle" />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={506}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.title" />
          }
          subtitleTopMargin
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>
          <Field label={<FormattedMessage id={'menu.main.maintenance.operational-maintenances.title'} />}>
            <Field.Dropdown
              clearable={false}
              value={
                <DropdownItem
                  title={
                    <FormattedMessage
                      id={
                        this.state.maintenancesValue === 'carry_out'
                          ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.carry_out.title'
                          : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.administrate.title'
                      }
                    />
                  }
                  subtitle={
                    <FormattedMessage
                      id={
                        this.state.maintenancesValue === 'carry_out'
                          ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.carry_out.subtitle'
                          : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.administrate.subtitle'
                      }
                    />
                  }
                />
              }
            >
              <Field.Dropdown.Item
                selected={this.state.maintenancesValue === 'carry_out'}
                onClick={() => this.setState({ maintenancesValue: 'carry_out' })}
              >
                <DropdownItem
                  title={
                    <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.carry_out.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.carry_out.subtitle" />
                  }
                />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={this.state.maintenancesValue === 'administrate'}
                onClick={() => this.setState({ maintenancesValue: 'administrate' })}
              >
                <DropdownItem
                  title={
                    <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.administrate.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.operator-maintenance.administrate.subtitle" />
                  }
                />
              </Field.Dropdown.Item>
            </Field.Dropdown>
          </Field>

          <div className={styles['dropdown-container']}>
            <Field label={<FormattedMessage id={'menu.main.spare-parts.title'} />}>
              <Field.Dropdown
                clearable={false}
                value={
                  <DropdownItem
                    title={this.getSparePartPermissionTitle()}
                    subtitle={this.getSparePartPermissionSubtitle()}
                  />
                }
              >
                <Field.Dropdown.Item
                  selected={this.state.sparePartValue === 'view'}
                  onClick={() => this.setState({ sparePartValue: 'view' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.view.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.view.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.sparePartValue === 'create_withdrawals'}
                  onClick={() => this.setState({ sparePartValue: 'create_withdrawals' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.create_withdrawals.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.create_withdrawals.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.sparePartValue === 'create_withdrawals_and_stock_adjustment'}
                  onClick={() => this.setState({ sparePartValue: 'create_withdrawals_and_stock_adjustment' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.make_adjustments.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.spare-parts.make_adjustments.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
              </Field.Dropdown>
            </Field>
          </div>

          <div className={styles['dropdown-container']}>
            <Field label={<FormattedMessage id={'menu.main.maintenance.requests.title'} />}>
              <Field.Dropdown
                clearable={false}
                value={
                  <DropdownItem
                    title={
                      <FormattedMessage
                        id={
                          this.state.requestValue === 'view'
                            ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.view.title'
                            : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.create.title'
                        }
                      />
                    }
                    subtitle={
                      <FormattedMessage
                        id={
                          this.state.requestValue === 'view'
                            ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.view.subtitle'
                            : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.create.subtitle'
                        }
                      />
                    }
                  />
                }
              >
                <Field.Dropdown.Item
                  selected={this.state.requestValue === 'view'}
                  onClick={() => this.setState({ requestValue: 'view' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.view.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.view.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.requestValue === 'create'}
                  onClick={() => this.setState({ requestValue: 'create' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.create.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.requests.create.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
              </Field.Dropdown>
            </Field>
          </div>

          <div className={styles['dropdown-container']}>
            <Field
              label={
                <FormattedMessage
                  id={
                    'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.title'
                  }
                />
              }
            >
              <Field.Dropdown
                clearable={false}
                value={
                  <DropdownItem
                    title={
                      <FormattedMessage
                        id={
                          this.state.userPermissionValue === 'none'
                            ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.none.title'
                            : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.create_operators.title'
                        }
                      />
                    }
                    subtitle={
                      <FormattedMessage
                        id={
                          this.state.userPermissionValue === 'none'
                            ? 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.none.subtitle'
                            : 'screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.create_operators.subtitle'
                        }
                      />
                    }
                  />
                }
              >
                <Field.Dropdown.Item
                  selected={this.state.userPermissionValue === 'none'}
                  onClick={() => this.setState({ userPermissionValue: 'none' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.none.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.none.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
                <Field.Dropdown.Item
                  selected={this.state.userPermissionValue === 'create_operators'}
                  onClick={() => this.setState({ userPermissionValue: 'create_operators' })}
                >
                  <DropdownItem
                    title={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.create_operators.title" />
                    }
                    subtitle={
                      <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.modal.manage-users.create_operators.subtitle" />
                    }
                  />
                </Field.Dropdown.Item>
              </Field.Dropdown>
            </Field>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.props.loading}
              onClick={() =>
                this.props.onSave({
                  production_supervisor_permission_operational_maintenances: this.state.maintenancesValue,
                  production_supervisor_permission_spare_parts: this.state.sparePartValue,
                  production_supervisor_permission_requests: this.state.requestValue,
                  production_supervisor_permission_users: this.state.userPermissionValue,
                })
              }
              label="general.save"
            />
            <Button label="general.cancel" onClick={() => this.props.onClose()} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ManageProductionSupervisorPermissionsModal;
