import React from 'react';
import { connect } from 'react-redux';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

const UserContainer = ({ user }) => <UserNameWrapper user={user} />;

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserContainer);
