import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, FilterButton } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import SortingDropdown from './SortingDropdown';

export const SearchRelevanceSortKey = 'search_relevance';

class SortingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSortingDropdown: false,
      showSearchRelevanceOption: false,
      sortBeforeSearchRelevance: null,
      sortOrderBeforeSearchRelevance: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchRelevanceSearchTerm: prevSearchTerm } = prevProps;
    const { searchRelevanceSearchTerm: newSearchTerm } = this.props;
    const startedSearching = prevSearchTerm.length === 0 && newSearchTerm.length > 0;
    const stoppedSearching = prevSearchTerm.length > 0 && newSearchTerm.length === 0;
    if (startedSearching) {
      this.setState(
        {
          showSearchRelevanceOption: true,
          sortBeforeSearchRelevance: this.props.sort,
          sortOrderBeforeSearchRelevance: this.props.sortOrder,
        },
        () => {
          this.props.onSort({ sort: SearchRelevanceSortKey, 'sort-order': null });
        }
      );
    } else if (stoppedSearching) {
      if (this.props.sort === SearchRelevanceSortKey) {
        this.props.onSort({
          sort: this.state.sortBeforeSearchRelevance,
          'sort-order': this.state.sortOrderBeforeSearchRelevance,
        });
      } else {
        this.props.onSort({
          sort: this.props.sort,
          'sort-order': this.props.sortOrder,
        });
      }

      this.setState({
        showSearchRelevanceOption: false,
        sortBeforeSearchRelevance: null,
        sortOrderBeforeSearchRelevance: null,
      });
    }
  }

  renderSortingDropdown = () => {
    return (
      <SortingDropdown
        open={this.state.showSortingDropdown}
        positionRef={this.sortingDropdownRef}
        sortOptions={this.props.sortOptions}
        sort={this.props.sort}
        sortOrder={this.props.sortOrder}
        showSearchRelevanceOption={this.state.showSearchRelevanceOption}
        onSort={sort => {
          this.props.onSort(sort);
        }}
        onClose={() => this.setState({ showSortingDropdown: false })}
      />
    );
  };

  renderSortValue = () => {
    const label = this.props.sortOptions.find(option => option.sort === this.props.sort)?.label;
    if (this.props.sort === SearchRelevanceSortKey) {
      return (
        <span>
          <FormattedMessage id="sort.search-relevance" />
        </span>
      );
    }
    if (label) {
      return (
        <>
          <span style={{ marginRight: 5 }}>{label}</span>
          <Icon regular type={`${this.props.sortOrder === 'asc' ? 'arrow-up' : 'arrow-down'}`} size={12} />
        </>
      );
    }
    return '';
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.sortingDropdownRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showSortingDropdown: !prevState.showSortingDropdown,
            }));
          }}
        >
          <FilterButton
            clearable={false}
            caret={false}
            label={
              <div className={styles['container']}>
                <span className={styles['label']}>
                  <FormattedMessage id="sort.title" />
                  <span>: </span>
                </span>
                &nbsp;
                <span>{this.renderSortValue()}</span>
              </div>
            }
          />
        </div>
        {this.renderSortingDropdown()}
        <div className={styles['vertical-seperator']} />
      </>
    );
  }
}

SortingButton.propTypes = {
  sortOptions: PropTypes.array,
  onSort: PropTypes.func,
  searchRelevanceSearchTerm: PropTypes.string,
  useSeperator: PropTypes.bool,
};

SortingButton.defaultProps = {
  sortOptions: [],
  searchRelevanceSearchTerm: '',
  onSort: () => {},
  useSeperator: false,
};

export default SortingButton;
