import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, InlineModal, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

const ModalDropdownContent = {
  ShowOptions: 'showOptions',
  EditWithdrawal: 'editWithdrawal',
  ShowDelete: 'showDelete',
};

class MoreOptionsInlineModal extends Component {
  state = {
    showOptionsInlineModal: false,
    dropdownOption: ModalDropdownContent.ShowOptions,
    editingWithdrawal: null,
    isSaving: false,
  };

  shouldComponentUpdate(prevProps, prevState) {
    if (!prevState.showOptionsInlineModal && !this.state.showOptionsInlineModal) {
      return false;
    }
    return true;
  }

  updateCurrentWithdrawal = () => {
    this.setState({ isSaving: true });
    this.props
      .updateSparePartWithdrawal(this.state.editingWithdrawal.id, {
        quantity: this.state.editingWithdrawal.quantity,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSaving: false, showOptionsInlineModal: false });
      });
  };

  deleteWorkOrderSparePartWithdrawal = () => {
    this.setState({ isDeleting: true });
    this.props.deleteSparePartWithdrawal(this.props.sparePartWithdrawal.id).then(() => {
      this.setState({ isDeleting: false, showOptionsInlineModal: false });
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.editingWithdrawal.quantity) {
      this.updateCurrentWithdrawal();
    }
  };

  renderOptions = () => {
    return (
      <InlineModal.Body dropdown width={260}>
        <>
          <InlineModal.ListItem
            icon="pen"
            iconThickness="regular"
            title={<FormattedMessage id="general.edit" />}
            onClick={() => {
              this.setState({
                dropdownOption: ModalDropdownContent.EditWithdrawal,
                editingWithdrawal: this.props.sparePartWithdrawal,
              });
            }}
          />
          <InlineModal.ListItem
            icon="trash-alt"
            destructive
            title={<FormattedMessage id="general.delete" />}
            onClick={() => {
              this.setState({ dropdownOption: ModalDropdownContent.ShowDelete });
            }}
          />
        </>
      </InlineModal.Body>
    );
  };

  renderEditWithdrawal = () => {
    return (
      <InlineModal.Body width={390} padding="30px 40px">
        <Field view={false} label={<FormattedMessage id="components.add-spare-parts-modal.used-quantity" />}>
          <Field.Text
            onKeyPress={this.handleKeyPress}
            rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
            autoFocus
            value={this.state.editingWithdrawal.quantity}
            onChange={quantity => {
              this.setState({ editingWithdrawal: { ...this.state.editingWithdrawal, quantity } });
            }}
          />
        </Field>
        <p className={styles['current-withdrawal']}>
          <FormattedMessage
            id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.editing-current-withdrawal-subtitle"
            values={{ date: moment(this.props.sparePartWithdrawal.created_at).format('LLL') }}
          />
        </p>
        <div className={styles['group']}>
          <Button.Group>
            <Button
              primary
              small
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.updateCurrentWithdrawal}
              disabled={!this.state.editingWithdrawal.quantity}
            />
            <Button
              small
              label="general.cancel"
              onClick={() => this.setState({ showOptionsInlineModal: false, showWithdrawal: false })}
            />
          </Button.Group>
        </div>
      </InlineModal.Body>
    );
  };

  renderDeleteContent = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="screens.vendor.delete-confirm.title" />}
          onClose={() => {
            this.setState({ showOptionsInlineModal: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete
            loading={this.state.isDeleting}
            onDelete={this.deleteWorkOrderSparePartWithdrawal}
          >
            <FormattedMessage id="screens.vendor.delete-confirm.message" />
          </InlineModal.Delete>
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderInlineModalBodyContent = () => {
    switch (this.state.dropdownOption) {
      case ModalDropdownContent.ShowOptions:
        return this.renderOptions();
      case ModalDropdownContent.EditWithdrawal:
        return this.renderEditWithdrawal();
      case ModalDropdownContent.ShowDelete:
        return this.renderDeleteContent();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showOptionsInlineModal: !prevState.showOptionsInlineModal,
              dropdownOption: ModalDropdownContent.ShowOptions,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showOptionsInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showOptionsInlineModal: false })}
          position="right"
        >
          <React.Fragment>{this.renderInlineModalBodyContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartWithdrawal: SDKReduxOperations.updateSparePartWithdrawal,
      deleteSparePartWithdrawal: SDKReduxOperations.deleteSparePartWithdrawal,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(MoreOptionsInlineModal));
