import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { SparePartsOperations } from 'state/ducks/spareParts';
import { FILTER_TYPES } from 'views/scenes/SpareParts/SparePartList/FilterModal';
import TemplateFieldItem from './TemplateFieldItem';

class Dropdown extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter[this.props.templateField.id];
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <TemplateFieldItem id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter[this.props.templateField.id];
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Resource
          title={this.props.templateField.title}
          comparator={comparator}
          hasValue={value}
          onClear={() =>
            this.props.removeFilter({
              key: `${FILTER_TYPES.SparePartField}.${this.props.templateField.id}`,
              data: {
                [FILTER_TYPES.SparePartField]: {
                  ...this.props.filter,
                  [this.props.templateField.id]: {
                    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                    value: [],
                  },
                },
              },
            })
          }
        >
          {this.renderSelectedValue()}
        </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: SparePartsOperations.removeFilter,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Dropdown);
