import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';

export default props => (
  <Grid>
    <Grid.Row>
      <Grid.Column md={7}>
        <Field label={<FormattedMessage id="resources.system.address-address1" />}>
          <Field.Text
            value={props.editingSystem.company_address1}
            onChange={company_address1 => {
              props.onChange({ company_address1 });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column md={7}>
        <Field label={<FormattedMessage id="resources.system.address-address2" />}>
          <Field.Text
            value={props.editingSystem.company_address2}
            onChange={company_address2 => {
              props.onChange({ company_address2 });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column md={2}>
        <Field label={<FormattedMessage id="resources.system.address-zip" />}>
          <Field.Text
            value={props.editingSystem.company_zip}
            onChange={company_zip => {
              props.onChange({ company_zip });
            }}
          />
        </Field>
      </Grid.Column>
      <Grid.Column md={5}>
        <Field label={<FormattedMessage id="resources.system.address-city" />}>
          <Field.Text
            value={props.editingSystem.company_city}
            onChange={company_city => {
              props.onChange({ company_city });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column md={7}>
        <Field label={<FormattedMessage id="resources.system.address-country" />}>
          <Field.Text
            value={props.editingSystem.company_country}
            onChange={company_country => {
              props.onChange({ company_country });
            }}
          />
        </Field>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
