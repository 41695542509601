import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';

class CancelPurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelling: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isCancelling: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  cancelPurchaseOrder = () => {
    this.setState({ isCancelling: true });

    this.props.updatePurchaseOrder(this.props.purchaseOrder.id, { canceled: true }).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="components.cancel-purchase-order-modal.cancelled-success" />}
        />
      );
      this.props.onClose();
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={430}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.cancel-purchase-order-modal.title" />}
          subtitle={<FormattedMessage id="components.cancel-purchase-order-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              destructive
              label="components.cancel-purchase-order-modal.button"
              loading={this.state.isCancelling}
              onClick={this.cancelPurchaseOrder}
            />
            <Button label="general.close" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(CancelPurchaseOrder));
