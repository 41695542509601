import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mutateTree } from '@atlaskit/tree/';
import { withRouter } from 'react-router';
import { API, HelperFunctions } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { buildAtlaskitTree } from 'sdk/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { Loader } from 'views/components/Shared/General';
import { Tree } from 'views/components/Asset';
import { WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import TreeSmall from 'assets/images/EmptyDataSet/TreeSmall.png';
import styles from './styles.module.scss';

class IncludedAssets extends Component {
  state = {
    tree: null,
    loading: true,
    isEmpty: false,
  };

  fetchTree = () => {
    if (this.state.tree != null) return;

    const params = {
      tree_children_for_asset_id: this.props.match.params.id,
      archived: false,
      id: {
        [HelperFunctions.FILTER_COMPARABLES.NoneOf]: this.props.match.params.id,
      },
    };

    this.setState({ loading: true });
    API.fetchTree(this.props.currentSystem.id, params)
      .then(res => {
        this.setState({
          loading: false,
          tree: buildAtlaskitTree(res.data, { rootParentId: this.props.match.params.id }),
          isEmpty: res.data.length === 0,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          isEmpty: true,
        });
      });
  };

  expandTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: true }),
    });
  };

  collapseTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: false }),
    });
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-container']}>
      <EmptyDataSet
        title={<FormattedMessage id="screens.asset.info.included-assets.empty" />}
        image={TreeSmall}
        tiny
      />
    </div>
  );

  renderLoader = () => (
    <div className={styles['loader-container']}>
      <Loader small />
    </div>
  );

  renderTree = () => {
    if (this.state.tree == null) return null;

    return (
      <Tree
        tree={this.state.tree}
        onExpand={this.expandTree}
        onCollapse={this.collapseTree}
        link
        isNestingEnabled
      />
    );
  };

  renderContent = () => {
    if (this.state.loading) {
      return this.renderLoader();
    } else if (this.state.isEmpty) {
      return this.renderEmptyDataSet();
    } else {
      return this.renderTree();
    }
  };

  render() {
    return (
      <WhiteCard
        title={<FormattedMessage id="screens.asset.info.included-assets.title" />}
        collapsable
        onOpen={() => {
          this.fetchTree();
        }}
      >
        <div className={styles['tree-container']}>{this.renderContent()}</div>
      </WhiteCard>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps)(IncludedAssets));
