import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { InlineModal, NewTab, DatePicker } from 'views/components/Shared/General';
import styles from './style.module.scss';

const AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR = 4;

export default class FilterTopBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showOtherDateInlineModal: false,
      showCustomDateInsideInlineModal: false,
      showCustomDatePicker: false,
    };
  }

  renderTabs = () => {
    if (this.props.yearSinceSystemWasCreated === 0) {
      return (
        <div className={styles['years']}>
          <div className={styles['tabs']}>
            <NewTab.Container>
              <NewTab
                selected={this.props.year === moment().year()}
                onClick={() => this.props.onSelectYear(moment().year())}
              >
                <span>{this.props.yearSystemWasCreated}</span>
              </NewTab>
            </NewTab.Container>
          </div>
          {this.renderCustomDateInlineModal()}
        </div>
      );
    }
    const amountOfYearsInTabBar =
      this.props.yearSinceSystemWasCreated + 1 > AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR
        ? AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR
        : this.props.yearSinceSystemWasCreated + 1;
    const tabs = [];
    for (let i = 0; i < amountOfYearsInTabBar; i++) {
      const year = moment().subtract(i, 'years').year();
      tabs.push(
        <NewTab selected={this.props.year === year} onClick={() => this.props.onSelectYear(year)}>
          {year}
        </NewTab>
      );
    }
    return (
      <div className={styles['years']}>
        <div className={styles['tabs']}>
          <NewTab.Container>
            <NewTab
              selected={this.props.year == null && this.props.fromDate == null && this.props.toDate == null}
              onClick={() => this.props.onSelectYear(null)}
            >
              <FormattedMessage id="components.requests-overview.all-years" />
            </NewTab>
            {tabs}
          </NewTab.Container>
        </div>
        {this.renderCustomDateInlineModal()}
      </div>
    );
  };

  renderCustomDate = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          title={<FormattedMessage id="components.requests-overview.other-date" />}
          onClose={() => this.setState({ showOtherDateInlineModal: false })}
          showBackButton
          onBack={() => this.setState({ showCustomDateInsideInlineModal: false })}
        />
        <InlineModal.Body width={337}>
          <DatePicker.Range
            inline
            onSelectDate={({ from, to }) => {
              this.props.onSelectDate(from, to);
              this.setState({ showOtherDateInlineModal: false });
            }}
            fromDate={this.props.fromDate}
            toDate={this.props.toDate}
          />
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderPresetYears = () => {
    const yearsOlderThanFourYearsAgo =
      moment().subtract(AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR, 'years').year() -
      moment().set({ year: this.props.yearSystemWasCreated }).year();
    let inlineModalListItems = [];
    for (let i = 0; i <= yearsOlderThanFourYearsAgo; i++) {
      const year = moment().subtract(AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR, 'years').subtract(i, 'years').year();
      inlineModalListItems.push(
        <InlineModal.ListItem
          selected={this.props.year === year}
          title={year}
          onClick={() => {
            this.props.onSelectYear(year);
            this.setState({ showOtherDateInlineModal: false });
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <InlineModal.Header
          title={<FormattedMessage id="components.requests-overview.other-date" />}
          onClose={() => this.setState({ showOtherDateInlineModal: false })}
        />
        <InlineModal.Body width={337}>
          {inlineModalListItems}
          <InlineModal.Separator />
          <InlineModal.ListItem
            selected={this.props.fromDate || this.props.toDate}
            title={<FormattedMessage id="components.due-date-inline-modal.options.show-calendar" />}
            onClick={() => this.setState({ showCustomDateInsideInlineModal: true })}
          />
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderCustomSelectedDateText = () => {
    const { fromDate, toDate, year } = this.props;
    if (fromDate && toDate) return `${moment(fromDate).format('ll')} - ${moment(toDate).format('ll')}`;
    if (year && moment().year() - moment().set({ year }).year() >= AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR)
      return year;
    return <FormattedMessage id="components.requests-overview.other-date" />;
  };

  otherDateIsSelected = () => {
    if (this.props.fromDate && this.props.toDate) return true;
    if (
      this.props.year &&
      moment().year() - moment().set({ year: this.props.year }).year() >= AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR
    )
      return true;
  };

  renderCustomDateInlineModal = () => {
    if (this.props.yearSinceSystemWasCreated < AMOUNT_OF_YEARS_TO_SHOW_IN_TAB_BAR) {
      return (
        <DatePicker.Range
          open={this.state.showCustomDatePicker}
          onClose={() => this.setState({ showCustomDatePicker: false })}
          trigger={
            <NewTab
              onClick={() => {
                this.setState(prevState => ({
                  showCustomDatePicker: !prevState.showCustomDatePicker,
                }));
              }}
              selected={this.otherDateIsSelected()}
            >
              {this.renderCustomSelectedDateText()}
            </NewTab>
          }
          onSelectDate={({ from, to }) => {
            this.props.onSelectDate(from, to);
            this.setState({ showCustomDatePicker: false, showOtherDateInlineModal: false });
          }}
          fromDate={this.props.fromDate}
          toDate={this.props.toDate}
        />
      );
    }
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showOtherDateInlineModal: !prevState.showOtherDateInlineModal,
            }));
          }}
        >
          <NewTab selected={this.otherDateIsSelected()}>{this.renderCustomSelectedDateText()}</NewTab>
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showOtherDateInlineModal}
          onClose={() => this.setState({ showOtherDateInlineModal: false })}
        >
          {this.state.showCustomDateInsideInlineModal ? this.renderCustomDate() : this.renderPresetYears()}
        </InlineModal>
      </>
    );
  };

  renderAmountOfAppliedAdvancedFilters = () => (
    <div
      className={`${styles['more-filters']} ${
        this.props.amountOfAppliedAdvancedFilters ? styles['has-filters'] : ''
      }`}
      onClick={this.props.onShowMoreFilters}
    >
      <FormattedMessage id="components.requests-overview.more-filters" />
      {this.props.amountOfAppliedAdvancedFilters === 0 ? null : (
        <div className={styles['filter-count']}>{this.props.amountOfAppliedAdvancedFilters}</div>
      )}
    </div>
  );

  render() {
    return (
      <div className={styles['top-bar-container']}>
        <div className={styles['top-bar']}>
          {this.renderTabs()}
          {this.renderAmountOfAppliedAdvancedFilters()}
        </div>
      </div>
    );
  }
}

FilterTopBar.propTypes = {
  year: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  onSelectYear: PropTypes.func,
  onSelectDate: PropTypes.func,
  amountOfAppliedAdvancedFilters: PropTypes.number,
  yearSinceSystemWasCreated: PropTypes.number,
  yearSystemWasCreated: PropTypes.number,
};

FilterTopBar.defaultProps = {
  year: null,
  fromDate: null,
  toDate: null,
  amountOfAppliedAdvancedFilters: 0,
};
