import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChecklistFormSelectors } from 'state/ducks/checklistForm';
import { ChecklistTemplateRow } from 'views/components/Checklist';
import Row from '../';

class SectionContainer extends Component {
  render() {
    return (
      <ChecklistTemplateRow.Section
        id={this.props.id}
        childIds={this.props.childIds}
        prevTemplateRowId={this.props.prevTemplateRowId}
        nextTemplateRowId={this.props.nextTemplateRowId}
      >
        {templateRows => templateRows.map(templateRow => <Row id={templateRow.id} />)}
      </ChecklistTemplateRow.Section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    childIds: ChecklistFormSelectors.getChildRowIds(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SectionContainer);
