import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List, Tooltip, Icon } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';

const SparePartListItem = ({ sparePart, disabled, selected, images, onClick }) => {
  return (
    <List.Item
      disabled={disabled}
      clickable={!disabled}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <List.Item.ImageColumn imageId={images.length === 0 ? null : images[0].id} />
      <List.Item.TitleColumn title={<Title sparePart={sparePart} />} />
      {disabled ? (
        <List.Item.Column alignRight>
          <Tooltip
            trigger={
              <div>
                <Icon regular type="info-circle" />
              </div>
            }
            label={
              <FormattedMessage id="components.file-add-related-spare-parts.already-selected-popup-content" />
            }
          />
        </List.Item.Column>
      ) : null}
      {selected ? (
        <List.Item.Column alignRight>
          <Icon green type="check" />
        </List.Item.Column>
      ) : null}
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    images: EntitySelectors.getImages(state, ownProps.sparePart.images),
  };
}

export default connect(mapStateToProps)(SparePartListItem);
