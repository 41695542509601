import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { List, Icon } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

const RequestSelectableAssignee = props => {
  const renderIcon = () => {
    return <Icon type={props.user ? 'user' : 'users'} withBackground blue backgroundSize={34} />;
  };
  const renderUserListItem = () => {
    return (
      <List.Item light clickable onClick={() => props.onSelectUser(props.user.id)}>
        <List.Item.Column width={35}>{renderIcon()}</List.Item.Column>
        <List.Item.TitleColumn
          title={<UserNameWrapper user={props.user} />}
          subtitle={props.requestSelectableAssignee.comment}
        />
        {props.isSavingUserIdAsAssignee === props.user.id ? (
          <List.Item.Column alignRight>
            <Loader tiny />
          </List.Item.Column>
        ) : null}
      </List.Item>
    );
  };

  const renderGroupListItem = () => {
    return (
      <List.Item light clickable onClick={() => props.onSelectGroup(props.group.id)}>
        <List.Item.Column width={35}>{renderIcon()}</List.Item.Column>
        <List.Item.TitleColumn title={props.group.title} subtitle={props.requestSelectableAssignee.comment} />
        {props.isSavingGroupIdAsAssignee === props.group.id ? (
          <List.Item.Column alignRight>
            <Loader tiny />
          </List.Item.Column>
        ) : null}
      </List.Item>
    );
  };

  if (props.user) {
    return renderUserListItem();
  }
  return renderGroupListItem();
};

function mapStateToProps(state, ownProps) {
  const requestSelectableAssignee = EntitySelectors.getRequestSelectableAssignee(state, ownProps.id);
  return {
    requestSelectableAssignee,
    user: EntitySelectors.getUser(state, requestSelectableAssignee.user_id),
    group: EntitySelectors.getGroup(state, requestSelectableAssignee.group_id),
  };
}

export default connect(mapStateToProps)(RequestSelectableAssignee);
