import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Field } from '../../';
import Column from './Column';
import MultipleOptions from './MultipleOptions';

class Header extends Component {
  renderCheckbox = () => {
    if (this.props.checkbox) {
      return (
        <div className={styles['checkbox-container']}>
          <Field.Checkbox
            checked={this.props.checked}
            onChange={() => {
              this.props.onCheck();
            }}
          />
        </div>
      );
    }
    return null;
  };

  renderHeaderContent = () => {
    if (this.props.showMultipleOptions) {
      return <div className={styles['options-container']}>{this.props.multipleOptionsComponent}</div>;
    }
    return <div className={styles['columns']}>{this.props.children}</div>;
  };

  renderContent = () => {
    let classNames = [styles['header']];
    if (this.props.background) classNames = [...classNames, styles['background']];
    if (this.props.blue) classNames = [...classNames, styles['blue']];
    if (this.props.tiny) classNames = [...classNames, styles['tiny']];
    if (this.props.expandable) classNames = [...classNames, styles['expandable']];
    if (this.props.checkbox) classNames = [...classNames, styles['checkbox']];

    const style = {
      paddingRight: this.props.paddingRight ? this.props.paddingRight : null,
      ...this.props.style,
    };

    return (
      <div className={classNames.join(' ')} style={style}>
        {this.renderCheckbox()}
        {this.renderHeaderContent()}
      </div>
    );
  };

  render() {
    return this.renderContent();
  }
}

Header.Column = Column;
Header.MultipleOptions = MultipleOptions;
export default Header;

Header.propTypes = {
  checkbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  expandable: PropTypes.bool,
  background: PropTypes.bool,
  blue: PropTypes.bool,
  tiny: PropTypes.bool,
  style: PropTypes.object,
  showMultipleOptions: PropTypes.bool,
  multipleOptionsComponent: PropTypes.node,
};
Header.defaultProps = {
  onCheck: () => {},
  checkbox: false,
  checked: false,
  expandable: false,
  background: false,
  blue: false,
  tiny: false,
  style: null,
  showMultipleOptions: false,
  multipleOptionsComponent: null,
};
