import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import styles from './styles.module.scss';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.checkedWorkOrderCount >= maxQuantityAllowedForType(ExportType.WorkOrdersXslx),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
        ...this.props.listParams,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.checkedWorkOrderIds).toString(),
      },
    };
  };

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.buildFilterParams(),
      type: ExportType.WorkOrdersXslx,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedWorkOrderCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-work-orders.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.two"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.few"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.many"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.other"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
      />
    );
  };

  renderContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            <FormattedMessage id="components.export-modal.excel-quantity-exceeded" />
          </div>
        </Modal.Content>
      );
    }
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.showQuantityExceeded) {
      return null;
    }
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.export-modal.title-xslx" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: WorkOrdersSelectors.getQueryParameters(state),
    filtersAsQueryParams: WorkOrdersSelectors.getFiltersAsQueryParams(state),
    checkedWorkOrderIds: WorkOrdersSelectors.getSelectedWorkOrderIds(state),
    checkedWorkOrderCount: WorkOrdersSelectors.getSelectedWorkOrdersCount(state),
    totalEntriesIsSelected: WorkOrdersSelectors.getTotalEntriesIsSelected(state),
  };
}

export default connect(mapStateToProps)(ExportModal);
