import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { StatusColumn } from 'views/components/Request';
import { List, Icon, Button, NewInlineModal, Datapill } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class RequestItem extends Component {
  state = {
    dropdownOpen: false,
  };

  renderIconButtons = () => {
    return (
      <div className={styles['icon-buttons']}>
        {this.props.request.work_order_id && (
          <>
            <StatusColumn icon requestId={this.props.request.id} />
            <div className={styles['vertical-separator']} />
          </>
        )}
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.preventDefault();
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={250}
          position="right"
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ dropdownOpen: false });
                  this.props.onRemoveFromRequest(this.props.request.id);
                }}
              >
                <FormattedMessage id="components.downtime-modal.remove-from-downtime" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  render() {
    return (
      <List.Item
        small
        clickable
        linkTo={{
          pathname: `/requests/${this.props.request.id}`,
        }}
        dataPillsComponent={this.props.user ? <Datapill value={this.props.user.name} /> : null}
        iconButtons={this.renderIconButtons()}
      >
        <List.Item.TitleColumn
          title={
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.props.request.title}</div>
              <div className={styles['number']}> - #{this.props.request.number}</div>
            </div>
          }
          subtitle={
            this.props.request.asset_id ? <TreePath assetId={this.props.request.asset_id} fullPath /> : null
          }
        />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let user = null;
  if (ownProps.request && ownProps.request.created_by_user_id) {
    user = EntitySelectors.getUser(state, ownProps.request.created_by_user_id);
  }
  return {
    user,
  };
}

export default connect(mapStateToProps)(RequestItem);
