import moment from 'moment';
import uuid from 'uuid';

export const PurchaseOrderStatus = {
  Draft: 'draft',
  Ordered: 'ordered',
};

export const EstimatedDeliveryMode = {
  Single: 'single',
  Multiple: 'multiple',
};

export const PurchaseOrderDeliveryStatus = {
  None: 'none',
  Partial: 'partial',
  Full: 'full',
};

export function getEstimatedDeliveryDateText(purchaseOrder) {
  if (purchaseOrder.estimated_delivery_mode === EstimatedDeliveryMode.Multiple) {
    if (purchaseOrder.estimated_delivery_from_row_date && purchaseOrder.estimated_delivery_to_row_date) {
      return `${moment(purchaseOrder.estimated_delivery_from_row_date).format('ll')} - ${moment(
        purchaseOrder.estimated_delivery_to_row_date
      ).format('ll')}`;
    }
    return null;
  }
  if (purchaseOrder.estimated_delivery_date == null) {
    return null;
  } else {
    return moment(purchaseOrder.estimated_delivery_date).format('LL');
  }
}
