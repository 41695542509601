import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartsSelectors, SparePartsOperations } from 'state/ducks/spareParts';
import Text from './Text';
import Dropdown from './Dropdown';
import Date from './Date';

class SparePartField extends Component {
  render() {
    const { type } = this.props.templateField;
    if (type === 'text' || type === 'textarea') {
      return <Text templateField={this.props.templateField} />;
    } else if (type === 'valuelist') {
      return <Dropdown templateField={this.props.templateField} />;
    } else if (type === 'date') {
      return <Date templateField={this.props.templateField} />;
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFilter: SparePartsOperations.addFilter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const selectedFilterType = SparePartsSelectors.getSelectedFilterType(state);
  const keys = selectedFilterType.split('.');
  return {
    templateField: EntitySelectors.getTemplateField(state, keys[1]),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartField);
