import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { debounce } from 'lodash-es';
import { AuthSelectors } from 'state/ducks/auth';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { HelperFunctions } from 'sdk';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { ProductionBoardSelectors, ProductionBoardOperations } from 'state/ducks/productionBoard';
import { Icon, SectionHeader, Button, Loader } from 'views/components/Shared/General';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import styles from './style.module.scss';
import OperationalMaintenanceListItem from './OperationalMaintenanceListItem';

const listRequest = HelperFunctions.getCancelTokenForRequest();

class OperationalMaintenances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
    };
    this.fetchDebouncedOperationalMaintenances = debounce(() => {
      this.fetchOperationalMaintenances();
    }, 300);
  }

  componentDidMount() {
    this.fetchOperationalMaintenances();
  }

  componentDidUpdate = prevProps => {
    const changedCheckedInAsset = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    const changedDate = prevProps.operationalMaintenancesDate !== this.props.operationalMaintenancesDate;
    if (changedCheckedInAsset || changedDate) {
      this.setState({ isFetching: true });
      listRequest.cancel();
      this.fetchDebouncedOperationalMaintenances();
    }
  };

  fetchOperationalMaintenances = () => {
    const params = {
      asset_ids: this.props.checkedInAssetId,
      from_date: moment(this.props.operationalMaintenancesDate).format('YYYY-MM-DD'),
      to_date: moment(this.props.operationalMaintenancesDate).format('YYYY-MM-DD'),
    };

    this.props
      .fetchOperationalMaintenancesForDashboard(
        this.props.system.id,
        params,
        listRequest.getCancelTokenConfig()
      )
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  renderSelectedDay = () => {
    let children = null;
    if (moment().isSame(moment(this.props.operationalMaintenancesDate), 'day')) {
      children = (
        <span className={styles['today']}>
          <FormattedMessage id="calendar.today" />
        </span>
      );
    } else if (moment().subtract(1, 'day').isSame(moment(this.props.operationalMaintenancesDate), 'day')) {
      children = (
        <span className={styles['today']}>
          <FormattedMessage id="calendar.yesterday" />
        </span>
      );
    } else if (moment().add(1, 'day').isSame(moment(this.props.operationalMaintenancesDate), 'day')) {
      children = (
        <span className={styles['today']}>
          <FormattedMessage id="calendar.tomorrow" />
        </span>
      );
    } else {
      children = (
        <>
          <span>
            <span>{moment(this.props.operationalMaintenancesDate).format('D')}</span>
          </span>
          <span> </span>
          <span>{moment(this.props.operationalMaintenancesDate).format('MMMM')}</span>
        </>
      );
    }

    return (
      <>
        <span className={styles['today']}>{children}</span>
        <span className={styles['weekday']}>
          <span> - </span>
          <span>{moment(this.props.operationalMaintenancesDate).format('dddd')}</span>
        </span>
      </>
    );
  };

  renderOperationalMaintenancesHeader = () => {
    return (
      <div className={styles['op-maintenance-header-container']}>
        <div className={styles['op-maintenance-header-left-content']}>
          <div>{this.renderSelectedDay()}</div>
          <div className={styles['op-maintenance-count']}>
            <FormattedPlural
              value={this.props.operationalMaintenanceIds.length}
              one={
                <FormattedMessage
                  id="screens.production-board.overview.amount-of-activities.one"
                  values={{ amount: this.props.operationalMaintenanceIds.length }}
                />
              }
              two={
                <FormattedMessage
                  id="screens.production-board.overview.amount-of-activities.two"
                  values={{ amount: this.props.operationalMaintenanceIds.length }}
                />
              }
              few={
                <FormattedMessage
                  id="screens.production-board.overview.amount-of-activities.few"
                  values={{ amount: this.props.operationalMaintenanceIds.length }}
                />
              }
              many={
                <FormattedMessage
                  id="screens.production-board.overview.amount-of-activities.many"
                  values={{ amount: this.props.operationalMaintenanceIds.length }}
                />
              }
              other={
                <FormattedMessage
                  id="screens.production-board.overview.amount-of-activities.other"
                  values={{ amount: this.props.operationalMaintenanceIds.length }}
                />
              }
            />
          </div>
        </div>
        <div className={styles['op-maintenance-header-right-content']}>
          <Button
            type="icon"
            icon={<Icon regular size={16} type="angle-left" />}
            onClick={() => {
              this.props.setOperationalMaintenancesDate(
                moment(this.props.operationalMaintenancesDate).subtract('1', 'day').format('YYYY-MM-DD')
              );
            }}
          />
          <Button
            type="icon"
            icon={<Icon regular size={16} type="angle-right" />}
            onClick={() => {
              this.props.setOperationalMaintenancesDate(
                moment(this.props.operationalMaintenancesDate).add('1', 'day').format('YYYY-MM-DD')
              );
            }}
          />
        </div>
      </div>
    );
  };

  renderShowAllOperationalMaintenancesButton = () => {
    if (this.props.operationalMaintenanceIds.length > 3) {
      return (
        <div className={styles['op-maintenance-show-all-button-container']}>
          <Link
            to={{
              pathname: `/operational-maintenances`,
              search: 'list=calendar',
            }}
          >
            <Button
              type="text"
              primary
              translate={false}
              label={
                <>
                  <FormattedMessage id="screens.production-board.overview.operational-maintenances.button" />
                  <span> ({this.props.operationalMaintenanceIds.length})</span>
                </>
              }
              fontSize={12}
              noUnderline
            />
          </Link>
        </div>
      );
    }
    return null;
  };

  renderOperationalMaintenancesEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
        <div className={styles['title']}>
          <FormattedMessage id="screens.production-board.overview.operational-maintenances.empty-dataset" />
        </div>
      </div>
    );
  };

  renderOperationalMaintenancesContent = () => {
    if (this.state.isFetching || this.props.isRefreshing) {
      return (
        <div className={styles['op-maintenance-cells']}>
          <Loader centerInParent tiny />
        </div>
      );
    }
    if (this.props.operationalMaintenanceIds.length === 0) {
      return this.renderOperationalMaintenancesEmptyDataset();
    }
    return (
      <>
        <div className={styles['op-maintenance-cells']}>
          {this.props.operationalMaintenanceIds.slice(0, 3).map(id => (
            <OperationalMaintenanceListItem id={id} />
          ))}
        </div>
        {this.renderShowAllOperationalMaintenancesButton()}
      </>
    );
  };

  render() {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    if (operationalMaintenanceIsActivated) {
      return (
        <div className={styles['op-card-container']}>
          <div className={styles['card-header']}>
            <SectionHeader paddingHorizontal={20} horizontalBorders noBorderBottom>
              <FormattedMessage id="resources.operational-maintenance.resource" />
            </SectionHeader>
          </div>
          <div className={styles['op-card-content']}>
            {this.renderOperationalMaintenancesHeader()}
            {this.renderOperationalMaintenancesContent()}
          </div>
        </div>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenancesForDashboard:
        ProductionBoardOperations.fetchOperationalMaintenancesForDashboard,
      setOperationalMaintenancesDate: ProductionBoardOperations.setOperationalMaintenancesDate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    operationalMaintenanceIds: ProductionBoardSelectors.getOverviewOperationalMaintenanceIds(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    isRefreshing: ProductionBoardSelectors.isRefreshing(state),
    operationalMaintenancesDate: ProductionBoardSelectors.getOperationalMaintenancesDate(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenances));
