import update from 'immutability-helper';
import types from './types';

const INITIAL_STATE = {
  isFetching: true,
  editable: false,
  templateLinkId: null,
  instanceId: null,
  templateId: null,
  instanceRowForTemplateRowIds: {},
  instanceOptionIdsForTemplateRowIds: {},
  templateFieldIds: [],
  childIds: {},
  menuItem: 'checklist',
  deviations: {
    tab: 'require_action',
    selectedChecklistInstanceRowIds: {},
    instanceRowIds: [],
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_CHECKLIST_INSTANCE: {
      const {
        instanceId,
        childIds,
        templateFieldIds,
        instanceRowForTemplateRowIds,
        instanceOptionIdsForTemplateRowIds,
      } = action.payload;
      return {
        ...state,
        templateId: null,
        isFetching: false,
        instanceId,
        childIds,
        templateFieldIds,
        instanceRowForTemplateRowIds,
        instanceOptionIdsForTemplateRowIds,
      };
    }
    case types.SET_CHECKLIST_TEMPLATE: {
      const { templateLinkId, templateId, childIds, templateFieldIds, instanceOptionIdsForTemplateRowIds } =
        action.payload;
      return {
        ...state,
        instanceId: null,
        isFetching: false,
        instanceRowForTemplateRowIds: {},
        templateLinkId,
        templateId,
        childIds,
        templateFieldIds,
        instanceOptionIdsForTemplateRowIds,
      };
    }
    case types.SET_IS_EDITABLE: {
      return {
        ...state,
        editable: action.payload,
      };
    }
    case types.SELECT_MENU_ITEM: {
      return {
        ...state,
        menuItem: action.payload,
        deviations: {
          ...INITIAL_STATE.deviations,
        },
      };
    }
    case types.RESET_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SELECT_DEVIATION_TAB: {
      return {
        ...state,
        deviations: {
          ...state.deviations,
          tab: action.payload,
        },
      };
    }
    case types.ADD_DEVIATION_ROW: {
      return update(state, {
        deviations: {
          selectedChecklistInstanceRowIds: {
            [action.payload]: {
              $set: true,
            },
          },
        },
      });
    }
    case types.ADD_MULTIPLE_DEVIATION_ROWS: {
      let idsToAdd = {};
      action.payload.forEach(id => {
        idsToAdd = {
          ...idsToAdd,
          [id]: true,
        };
      });
      return update(state, {
        deviations: {
          selectedChecklistInstanceRowIds: {
            $merge: idsToAdd,
          },
        },
      });
    }
    case types.REMOVE_DEVIATION_ROW: {
      return update(state, {
        deviations: {
          selectedChecklistInstanceRowIds: {
            $unset: [action.payload],
          },
        },
      });
    }
    case types.CLEAR_DEVIATION_ROWS: {
      return update(state, {
        deviations: {
          selectedChecklistInstanceRowIds: {
            $set: {},
          },
        },
      });
    }

    default:
      return state;
  }
};
