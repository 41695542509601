import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Section, Separator, UnsavedChangesBottomBar } from 'views/scenes/Settings/components';
import CompanyInformation from './CompanyInformation';
import CompanyAddress from './CompanyAddress';
import Logo from './Logo';
import { WhiteCard } from 'views/components/Shared/General';

class Company extends Component {
  getClientErrorData = () => ({
    company_name: false,
  });

  constructor(props) {
    super(props);
    this.state = {
      editingSystem: props.currentSystem,
      editingSystemBeforeEdit: props.currentSystem,
      showBottomSaveBar: false,
      errors: this.getClientErrorData(),
      showCompanyNameRequiredError: false,
    };
  }

  hasErrors = () => {
    const { company_name } = this.state.editingSystem;
    let errors = this.getClientErrorData();
    if (!company_name) {
      errors = {
        ...errors,
        company_name: true,
      };
    }
    this.setState({ errors });
    return Object.values(errors).some(item => item === true);
  };

  updateSystem = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateSystem(
        this.props.currentSystem.id,
        HelperFunctions.getDataChanges(this.state.editingSystem, this.state.editingSystemBeforeEdit)
      )
      .then(() => {
        this.setState({
          isSaving: false,
          showBottomSaveBar: false,
          editingSystem: this.props.currentSystem,
          editingSystemBeforeEdit: this.props.currentSystem,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  changeSystemValue = params => {
    let errors = { ...this.state.errors };
    Object.keys(params).forEach(property => {
      if (errors[property] === true && params[property]) {
        errors[property] = false;
      }
    });
    this.setState(
      {
        errors,
        editingSystem: { ...this.state.editingSystem, ...params },
      },
      () => {
        const amountOfChangedProperties = Object.keys(
          HelperFunctions.getDataChanges(this.state.editingSystem, this.state.editingSystemBeforeEdit)
        ).length;
        this.setState({ showBottomSaveBar: amountOfChangedProperties > 0 });
      }
    );
  };

  render() {
    return (
      <WhiteCard>
        <Prompt
          when={this.state.showBottomSaveBar}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Section header title={<FormattedMessage id="screens.settings.company.title" />} />
        <CompanyInformation
          errors={this.state.errors}
          editingSystem={this.state.editingSystem}
          onChange={params => this.changeSystemValue(params)}
        />
        <Separator />
        <Section header title={<FormattedMessage id="screens.settings.company.address-title" />} />
        <CompanyAddress
          editingSystem={this.state.editingSystem}
          onChange={params => this.changeSystemValue(params)}
        />
        <Separator />
        <Section header title={<FormattedMessage id="screens.settings.company.logo.title" />} />
        <Logo />
        <UnsavedChangesBottomBar
          show={this.state.showBottomSaveBar}
          amountOfChanges={this.state.amountOfChangedProperties}
          loading={this.state.isSaving}
          onClose={() => this.setState({ showBottomSaveBar: false })}
          onSave={() => this.updateSystem()}
        />
      </WhiteCard>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Company));
