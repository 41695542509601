import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class RemoveEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      showHasLoginCredentialsErrorMessage: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        showHasLoginCredentialsErrorMessage: false,
      });
    }
  }

  removeEmail = () => {
    this.setState({ isSaving: true });
    this.props
      .updateMe({ email: null })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10001', key: 'email' })) {
          this.setState({ showHasLoginCredentialsErrorMessage: true });
        }
        this.setState({ isSaving: false });
      });
  };

  renderLoginCredentialsErrorMessage = () => {
    if (this.state.showHasLoginCredentialsErrorMessage) {
      return (
        <Modal.Content>
          <span className={styles['error-message']}>
            <FormattedMessage id="screens.settings.user.contact-information.remove-email-modal.has-login-credentials-error-message" />
          </span>
        </Modal.Content>
      );
    }
    return (
      <Modal.Content>
        <span className={styles['text']}>
          <FormattedMessage id="screens.settings.user.contact-information.remove-email-modal.text" />
        </span>
      </Modal.Content>
    );
  };

  renderFooter = () => {
    if (this.state.showHasLoginCredentialsErrorMessage) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button label="general.close" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return (
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            destructive
            loading={this.state.isSaving}
            label="general.remove"
            onClick={this.removeEmail}
          />
          <Button label="general.cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.user.contact-information.remove-email-modal.title" />}
          onClose={this.props.onClose}
        />
        {this.renderLoginCredentialsErrorMessage()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMe: SDKReduxOperations.updateMe,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(RemoveEmailModal);
