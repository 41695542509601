import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import { ChooseAssigneeInlineModal } from 'views/components/WorkOrder';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import UserContainer from './UserContainer';
import VendorContainer from './VendorContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class WorkOrderSpentTimeCreatedBy extends Component {
  constructor(props) {
    super(props);
    const { filter, initialFilter } = props;
    const { value: userIds, comparator } = filter.user_id || initialFilter.user_id;
    const { value: vendorIds } = filter.vendor_id || initialFilter.vendor_id;
    this.state = {
      initialFilter: {
        user_id: {
          ...(filter.user_id || initialFilter.user_id),
        },
        vendor_id: {
          ...(filter.vendor_id || initialFilter.vendor_id),
        },
      },
      userIds,
      vendorIds,
      comparator,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        this.props.addFilter({
          key: FILTER_TYPES.WorkOrderSpentTimeCreatedBy,
          data: {
            work_order_spent_time: {
              ...this.props.filter,
              user_id: {
                comparator: this.state.comparator,
                value: this.state.userIds,
              },
              vendor_id: {
                comparator: this.state.comparator,
                value: this.state.vendorIds,
              },
            },
          },
        });
      }
    }
  }

  hasFiltersApplied = () => {
    return this.state.vendorIds.length > 0 || this.state.userIds.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedPerformedBy = () => {
    if (this.state.userIds.length === 0 && this.state.vendorIds.length === 0) {
      return null;
    }

    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={[
          ...this.state.userIds.map(id => ({ id, type: 'user' })),
          ...this.state.vendorIds.map(id => ({ id, type: 'vendor' })),
        ]}
        renderItem={({ type, id }) => {
          if (type === 'user') {
            return <UserContainer id={id} />;
          }
          return <VendorContainer id={id} />;
        }}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChooseAssigneeInlineModal
        multiple
        hideGroups
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedPerformedBy()}
            onClear={() => {
              this.setState({
                userIds: [],
                vendorIds: [],
              });
            }}
          />
        }
        selectedUserIds={this.state.userIds}
        selectedVendorIds={this.state.vendorIds}
        onAddUser={({ id }) =>
          this.setState({
            userIds: [...this.state.userIds, id],
          })
        }
        onRemoveUser={({ id }) =>
          this.setState({
            userIds: this.state.userIds.filter(userId => userId !== id),
          })
        }
        onAddVendor={({ id }) =>
          this.setState({
            vendorIds: [...this.state.vendorIds, id],
          })
        }
        onRemoveVendor={({ id }) =>
          this.setState({
            vendorIds: this.state.vendorIds.filter(vendorId => vendorId !== id),
          })
        }
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (
              isEqual(this.props.initialFilter.user_id, this.state.initialFilter.user_id) &&
              isEqual(this.props.initialFilter.vendor_id, this.state.initialFilter.vendor_id)
            ) {
              this.props.removeFilter({
                key: FILTER_TYPES.WorkOrderSpentTimeCreatedBy,
                data: {
                  work_order_spent_time: {
                    ...this.props.filter,
                    user_id: {
                      ...this.props.initialFilter.user_id,
                    },
                    vendor_id: {
                      ...this.props.initialFilter.vendor_id,
                    },
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.WorkOrderSpentTimeCreatedBy,
                data: {
                  work_order_spent_time: {
                    ...this.props.filter,
                    user_id: {
                      ...this.state.initialFilter.user_id,
                    },
                    vendor_id: {
                      ...this.state.initialFilter.vendor_id,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order-spent-time.performed-by" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order_spent_time'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderSpentTimeCreatedBy);

WorkOrderSpentTimeCreatedBy.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

WorkOrderSpentTimeCreatedBy.defaultProps = {
  filter: {
    user_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
    vendor_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
  initialFilter: {
    user_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
    vendor_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
};
