import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import HelperFunctions from 'utilities/HelperFunctions';
import { API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, FieldErrorWrapper } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showPasswordsNotMatchingError: false,
      showIncorrectOldPasswordError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        showPasswordsNotMatchingError: false,
        showIncorrectOldPasswordError: false,
      });
    }
  }

  handleKeyPress = event => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (event.key === 'Enter' && oldPassword && newPassword && confirmPassword) {
      this.save();
    }
  };

  save = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (newPassword !== confirmPassword) {
      this.setState({ showPasswordsNotMatchingError: true });
      return;
    }
    this.setState({ isSaving: true });
    API.updateLoginCredentialForMe({ current_password: oldPassword, password: newPassword })
      .then(() => {
        return API.login({ email: this.props.currentUser.email, password: newPassword });
      })
      .then(response => {
        HelperFunctions.updateUserAuthToken(response.data.token);
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        if (e.response && e.response.data.errors.current_password) {
          this.setState({ showIncorrectOldPasswordError: true });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.user.change-password.edit-password-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content ignoreLine hasFooter>
          <Grid>
            <Grid.Row>
              <Grid.Column md={12}>
                <Field
                  view={false}
                  label={
                    <FormattedMessage id="screens.settings.user.change-password.edit-password-modal.old-password" />
                  }
                >
                  <FieldErrorWrapper
                    show={this.state.showIncorrectOldPasswordError}
                    errorElement={<FormattedMessage id="general.errors.incorrect-password" />}
                  >
                    <Field.Text
                      type="password"
                      autoFocus
                      error={this.state.showIncorrectOldPasswordError}
                      value={this.state.oldPassword}
                      onChange={oldPassword => {
                        if (this.state.showIncorrectOldPasswordError) {
                          this.setState({ showIncorrectOldPasswordError: false });
                        }
                        this.setState({ oldPassword });
                      }}
                      onKeyPress={this.handleKeyPress}
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column md={12}>
                <Field
                  view={false}
                  label={
                    <FormattedMessage id="screens.settings.user.change-password.edit-password-modal.new-password" />
                  }
                >
                  <Field.Text
                    type="password"
                    autocomplete="new-password"
                    value={this.state.newPassword}
                    onChange={newPassword => {
                      this.setState({ newPassword });
                    }}
                    onKeyPress={this.handleKeyPress}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column md={12}>
                <Field
                  view={false}
                  label={
                    <FormattedMessage id="screens.settings.user.change-password.edit-password-modal.confirm-password" />
                  }
                >
                  <FieldErrorWrapper
                    show={this.state.showPasswordsNotMatchingError}
                    errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
                  >
                    <Field.Text
                      type="password"
                      autocomplete="new-password"
                      error={this.state.showPasswordsNotMatchingError}
                      value={this.state.confirmPassword}
                      onChange={confirmPassword => {
                        if (this.state.showPasswordsNotMatchingError) {
                          this.setState({ showPasswordsNotMatchingError: false });
                        }
                        this.setState({ confirmPassword });
                      }}
                      onKeyPress={this.handleKeyPress}
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.oldPassword || !this.state.newPassword || !this.state.confirmPassword}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default injectIntl(connect(mapStateToProps)(ChangePasswordModal));
