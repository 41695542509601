const getVendor = state => {
  const { vendor, entities } = state;
  if (!vendor.id) return null;
  return entities.vendorById[vendor.id];
};

const getContactPersonIds = state => state.vendor.contactPersons.ids;
const getContactPersonsCreatedIds = state => state.vendor.contactPersons.createdIds;
const getContactPersonsPagination = state => state.vendor.contactPersons.pagination;

const getAssetVendorIds = state => state.vendor.assetVendors.ids;
const getAssetVendorsCreatedIds = state => state.vendor.assetVendors.createdIds;
const getAssetVendorsPagination = state => state.vendor.assetVendors.pagination;

const getWorkOrders = state => {
  const { entities, vendor } = state;
  return vendor.workOrders.ids.map(id => entities.workOrderById[id]);
};
const getWorkOrdersCreatedIds = state => state.vendor.workOrders.createdIds;
const getWorkOrderListPagination = state => state.vendor.workOrders.pagination;

const getAttachments = state => {
  const { entities, vendor } = state;
  return vendor.attachments.ids.map(id => entities.attachmentById[id]);
};

const getAttachmentsCreatedIds = state => state.vendor.attachments.createdIds;
const getAttachmentsPagination = state => state.vendor.attachments.pagination;

const getSparePartIds = state => {
  const { vendor } = state;
  return vendor.spareParts.ids;
};

const getSparePartVendorIdsForSparePart = (state, sparePartId) => {
  const { vendor } = state;
  return vendor.spareParts.sparePartVendorIdsBySparePartId[sparePartId];
};

const getSparePartsPagination = state => state.vendor.spareParts.pagination;

const getPurchaseOrders = state => {
  const { entities, vendor } = state;
  return vendor.purchaseOrders.ids.map(id => entities.purchaseOrderById[id]);
};

const getPurchaseOrdersPagination = state => state.vendor.purchaseOrders.pagination;

export default {
  getVendor,
  getContactPersonIds,
  getContactPersonsCreatedIds,
  getContactPersonsPagination,
  getAssetVendorIds,
  getAssetVendorsCreatedIds,
  getAssetVendorsPagination,
  getAttachments,
  getAttachmentsCreatedIds,
  getAttachmentsPagination,
  getSparePartIds,
  getSparePartVendorIdsForSparePart,
  getSparePartsPagination,
  getPurchaseOrders,
  getPurchaseOrdersPagination,
  getWorkOrders,
  getWorkOrdersCreatedIds,
  getWorkOrderListPagination,
};
