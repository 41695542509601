import React, { Component } from 'react';
import { CardGrid } from 'views/components/Shared/Layout';
import { Icon, SectionHeader, ViewTextArea, WhiteCard } from 'views/components/Shared/General';
import { ReminderMessage } from 'views/components/WorkOrder';
import { ReminderType } from 'sdk/WorkOrder';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';

export default class Reminder extends Component {
  renderReminderComment = () => {
    if (!this.props.workOrder.reminder_comment) return null;

    return (
      <div className={styles['comment-wrapper']}>
        <ViewTextArea>
          {this.props.workOrder.reminder_comment}
        </ViewTextArea>
      </div>
    )
  }

  renderReminder = () => {
    return (
      <CardGrid.Row>
        <WhiteCard noPadding>
          <SectionHeader noBorderTop paddingHorizontal={25}>
            <div className={styles['section']}>
              <FormattedMessage id="screens.work-order.edit.reminder-title" />
            </div>
          </SectionHeader>
          <div className={styles['title-wrapper']}>
            <ReminderMessage workOrder={this.props.workOrder} shortText={true}/>
            {this.renderReminderComment()}
          </div>
        </WhiteCard>
      </CardGrid.Row>
    );
  };

  render() {
    if (this.props.workOrder.reminder_type === ReminderType.None) return null;
    return (
      <>
        <CardGrid.Row>{this.renderReminder()}</CardGrid.Row>
      </>
    );
  }
}
