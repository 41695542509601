import React from 'react';
import DeprecatedVersion from './DeprecatedVersion';
import VerifyEmail from './VerifyEmail';
import CustomerFeedback from './CustomerFeedback';

export default () => (
  <>
    <DeprecatedVersion />
    <VerifyEmail />
    <CustomerFeedback />
  </>
);
