import types from './types';

const INITIAL_STATE = {
  rootRows: [],
  childRows: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_CHECKLIST_TEMPLATE_VERSION: {
      const { rootRows, childRows } = action.payload;
      return {
        ...state,
        rootRows,
        childRows,
      };
    }
    default:
      return state;
  }
};
