import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorRoute } from 'views/layout';
import { AuthSelectors } from 'state/ducks/auth';
import FileList from './FileList';
import FilesForAsset from './FilesForAsset';

class FilesContainer extends Component {
  render() {
    const { settings, isOperator, isViewOnly, canAdministrateWorkOrders, canEditVendors, canEditAssets } =
      this.props;

    if (isOperator && settings.operator_permission_asset_attachments === 'view') {
      return <FilesForAsset {...this.props} />;
    }
    if (isViewOnly || canAdministrateWorkOrders || canEditVendors || canEditAssets) {
      return <FileList {...this.props} />;
    }
    return <ErrorRoute {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    isOperator: AuthSelectors.isOperator(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(FilesContainer);
