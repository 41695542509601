import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, Tooltip, Icon } from 'views/components/Shared/General';
import { EditGroupModal, MembersModal } from 'views/components/Group';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { AuthSelectors } from 'state/ducks/auth';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showEditGroupModal: false,
      showEditGroupModalForId: null,
      showMembersModal: false,
      showMembersModalForGroupId: null,
    };
  }

  componentDidMount() {
    this.props
      .fetchRequestAutomationGroups(this.props.selectedSystem.id, {
        no_pagination: true,
        type: 'request_assignee',
      })
      .then(() => {
        this.setState({ isFetching: false });
      });
  }

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={
        <FormattedMessage id="screens.settings.requests.assignees.automation.groups.empty-data-set.title" />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.group.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <React.Fragment>
      <Toolbar
        buttons={
          <Button
            primary
            label="screens.settings.requests.assignees.automation.groups.create-button"
            onClick={() => this.setState({ showEditGroupModal: true, showEditGroupModalForId: null })}
          />
        }
      />
      {this.renderHeader()}
      <List small>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderContent() {
    if (this.state.isFetching) {
      return (
        <>
          <Toolbar
            buttons={
              <Button
                primary
                label="screens.settings.requests.assignees.automation.groups.create-button"
                onClick={() => this.setState({ showEditGroupModal: true, showEditGroupModalForId: null })}
              />
            }
          />
          {this.renderHeader()}
          <List small>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.props.groups.length === 0) {
      return (
        <>
          <Toolbar
            buttons={
              <Button
                primary
                label="screens.settings.requests.assignees.automation.groups.create-button"
                onClick={() => this.setState({ showEditGroupModal: true, showEditGroupModalForId: null })}
              />
            }
          />
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.settings.requests.assignees.automation.groups.empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.requests.assignees.automation.groups.empty-data-set.subtitle" />
            }
            modal
          />
        </>
      );
    }
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.settings.requests.assignees.automation.groups.create-button"
              onClick={() => this.setState({ showEditGroupModal: true, showEditGroupModalForId: null })}
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {this.props.groups.map(group => (
            <List.Item
              clickable
              onClick={() =>
                this.setState({
                  showEditGroupModal: true,
                  showEditGroupModalForId: group.id,
                })
              }
            >
              <List.Item.TitleColumn
                title={group.title}
                subtitle={
                  <FormattedMessage id="resources.group.user-count" values={{ count: group.user_count }} />
                }
              />
              <List.Item.Column alignRight>
                <Tooltip
                  trigger={
                    <div>
                      <Button
                        type="icon"
                        icon={<Icon regular type="user" />}
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            showMembersModal: true,
                            showMembersModalForGroupId: group.id,
                          });
                        }}
                      />
                    </div>
                  }
                  label={
                    <FormattedMessage id="screens.settings.requests.assignees.automation.groups.manage-members-tooltip" />
                  }
                />
              </List.Item.Column>
            </List.Item>
          ))}
        </List>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderContent()}
        <EditGroupModal
          open={this.state.showEditGroupModal}
          type="request_assignee"
          id={this.state.showEditGroupModalForId}
          onClose={() => this.setState({ showEditGroupModal: false })}
        />
        <MembersModal
          open={this.state.showMembersModal}
          groupId={this.state.showMembersModalForGroupId}
          onClose={() => this.setState({ showMembersModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRequestAutomationGroups: SettingsOperations.fetchRequestAutomationGroups,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    groups: SettingsSelectors.getRequestAutomationGroups(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
