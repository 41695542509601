import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { OperationalMaintenancesOperations } from 'state/ducks/operationalMaintenances';
import { Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';

class Instance extends Component {
  setNavigatedTo = () => {
    const { date, list } = queryString.parse(this.props.location.search);
    this.props.setNavigatedToOperationalMaintenance({
      search: {
        list,
        date,
      },
    });
  };

  renderChecklistFilledRowsCount = () => {
    if (this.props.checklistTemplateVersion) {
      let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;
      let filledRowsCount = 0;
      if (this.props.checklistInstance) {
        filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
      }
      return (
        <span className={styles['checklist-filled-in-count']}>
          <Icon size={10} type="list" />
          <span>{filledRowsCount}</span>
          <span>/</span>
          <span>{amountOfRows}</span>
        </span>
      );
    }
    return null;
  };

  renderTimeOfDay = () => {
    const { operationalMaintenanceInstance, operationalMaintenanceTemplateRecurrenceVersion } = this.props;

    if (operationalMaintenanceInstance && operationalMaintenanceInstance.due_time_of_day) {
      return (
        <span className={styles['time-of-day']}>
          {moment(operationalMaintenanceInstance.due_time_of_day, 'hh:mm').format('LT')}
        </span>
      );
    }

    if (operationalMaintenanceTemplateRecurrenceVersion == null) {
      return null;
    }
    if (operationalMaintenanceTemplateRecurrenceVersion.time_of_day) {
      return (
        <span className={styles['time-of-day']}>
          {moment(operationalMaintenanceTemplateRecurrenceVersion.time_of_day, 'hh:mm').format('LT')}
        </span>
      );
    }

    return null;
  };

  renderAsset = () => {
    return (
      <div className={styles['asset']}>
        <TreePath assetId={this.props.asset.id} size={11} fullPath fromAssetId={this.props.assetId} />
      </div>
    );
  };

  renderDataRow = () => {
    const { operationalMaintenanceInstance } = this.props;
    if (
      operationalMaintenanceInstance &&
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Skipped
    ) {
      return (
        <div className={styles['data-row']}>
          <FormattedMessage id="resources.operational-maintenance.statuses.skipped" />
        </div>
      );
    }
    return (
      <div className={styles['data-row']}>
        {this.renderTimeOfDay()}
        {this.renderChecklistFilledRowsCount()}
      </div>
    );
  };

  renderBorder = () => {
    const { operationalMaintenanceInstance } = this.props;
    let classNames = [styles['border']];
    if (operationalMaintenanceInstance) {
      if (operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed) {
        classNames = [...classNames, styles['completed']];
      } else if (operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Skipped) {
        classNames = [...classNames, styles['skipped']];
      } else if (
        moment(operationalMaintenanceInstance.due_date).isBefore(
          moment().tz(this.props.system.timezone),
          'day'
        )
      ) {
        classNames = [...classNames, styles['overdue']];
      }
    }

    return <div className={classNames.join(' ')} />;
  };

  renderTitle = () => {
    const { operationalMaintenanceInstance } = this.props;
    let classNames = [styles['title']];
    if (
      operationalMaintenanceInstance &&
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Skipped
    ) {
      classNames = [...classNames, styles['skipped']];
    }
    return (
      <div className={classNames.join(' ')}>{this.props.operationalMaintenanceTemplateVersion.title}</div>
    );
  };

  renderStatusIcon = () => {
    const { operationalMaintenanceInstance } = this.props;
    if (operationalMaintenanceInstance) {
      if (operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed) {
        return (
          <div className={`${styles['status-icon-container']} ${styles['completed']}`}>
            <Icon size={10} regular type="check" green />
          </div>
        );
      }

      if (
        operationalMaintenanceInstance.status === OperationalMaintenanceStatus.NotStarted &&
        moment(operationalMaintenanceInstance.due_date).isBefore(
          moment().tz(this.props.system.timezone),
          'day'
        )
      ) {
        return (
          <div className={`${styles['status-icon-container']} ${styles['missed']}`}>
            <Icon size={10} regular type="times" red />
          </div>
        );
      }
    }
    return null;
  };

  render() {
    let id = this.props.operationalMaintenance.id;
    if (this.props.operationalMaintenanceInstance) {
      id = this.props.operationalMaintenanceInstance.id;
    }
    return (
      <Link
        to={`/operational-maintenances/${id}`}
        className={styles['instance']}
        onClick={this.setNavigatedTo}
      >
        {this.renderBorder()}
        <div className={styles['content']}>
          {this.renderTitle()}
          <div className={styles['subtitle']}>
            {this.renderAsset()}
            {this.renderDataRow()}
          </div>
        </div>
        {this.renderStatusIcon()}
      </Link>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNavigatedToOperationalMaintenance:
        OperationalMaintenancesOperations.setNavigatedToOperationalMaintenance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const operationalMaintenance = EntitySelectors.getOperationalMaintenance(state, ownProps.id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    operationalMaintenance.operational_maintenance_instance_id
  );
  let operationalMaintenanceTemplateVersion = null;
  let operationalMaintenanceTemplateRecurrenceVersion = null;
  let asset = null;
  let checklistInstance = null;
  let checklistTemplateLink = null;
  let checklistTemplateVersion = null;
  let checklistTemplate = null;

  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );

    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenanceInstance.operational_maintenance_template_recurrence_version_id
      );

    asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);

    const checklistInstanceIds = operationalMaintenanceInstance.checklist_instances || [];
    if (checklistInstanceIds.length === 1) {
      checklistInstance = EntitySelectors.getChecklistInstance(state, checklistInstanceIds[0]);

      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistInstance.checklist_template_id
      );

      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version
      );
    }
  } else {
    const operationalMaintenanceTemplateRecurrence =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrence(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_id
      );

    const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
      state,
      operationalMaintenanceTemplateRecurrence.operational_maintenance_template_asset_id
    );

    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenance.operational_maintenance_template_version_id
    );

    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_version_id
      );

    asset = EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id);

    const checklistTemplateLinks =
      EntitySelectors.getChecklistTemplateLinks(
        state,
        operationalMaintenanceTemplateVersion.checklist_template_links
      ) || [];

    if (checklistTemplateLinks.length === 1) {
      checklistTemplateLink = checklistTemplateLinks[0];

      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistTemplateLink.checklist_template_id
      );

      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.active_version_id
      );
    }
  }
  return {
    operationalMaintenance,
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplateRecurrenceVersion,
    checklistTemplateVersion,
    operationalMaintenanceInstance,
    checklistInstance,
    asset,
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Instance));
