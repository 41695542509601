import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import queryString from 'query-string';
import { API } from 'sdk';
import { normalizeOperationalMaintenance } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer } from 'views/components/Shared/Layout';
import { OperationalMaintenanceListItem } from 'views/components/OperationalMaintenance';
import { List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import WorkOrderSmall from 'assets/images/EmptyDataSet/WorkOrderSmall.png';
import Toolbar from './Toolbar';

const FILTERS = ['page'];
const PAGE_SIZE = 8;

export const LIST_TYPES = {
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Custom: 'custom',
};

class OperationalMaintenances extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      isFetching: true,
      queryParams: queryParams,
      ids: [],
    };

    this.fetchDebouncedOperationalMaintenances = debounce(() => {
      this.fetchOperationalMaintenances();
    }, 300);
  }

  componentDidMount() {
    this.fetchOperationalMaintenances();
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams, isFetching: true }, () => {
        this.fetchDebouncedOperationalMaintenances();
      });
    }
  }

  getSelectedDate = () => {
    const { date } = this.state.queryParams;
    return date || moment().tz(this.props.system.timezone).format('YYYY-MM-DD');
  };

  getSelectedList = () => {
    const { list } = this.state.queryParams;
    return list || LIST_TYPES.Week;
  };

  fetchOperationalMaintenances = () => {
    return API.listOperationalMaintenances(this.props.system.id, this.getParams()).then(
      ({ data: operationalMaintenances }) => {
        const { entities, result } = normalizeOperationalMaintenance(operationalMaintenances);
        this.props.updateEntities(entities);

        this.setState({ isFetching: false, ids: result });
      }
    );
  };

  getParams = () => {
    const list = this.getSelectedList();

    let params = {
      asset_ids: this.props.match.params.id,
    };

    if (list === LIST_TYPES.Day) {
      params = {
        ...params,
        from_date: moment(this.getSelectedDate()).format('YYYY-MM-DD'),
        to_date: moment(this.getSelectedDate()).format('YYYY-MM-DD'),
      };
    }
    if (list === LIST_TYPES.Week) {
      params = {
        ...params,
        from_date: moment(this.getSelectedDate()).startOf('week').format('YYYY-MM-DD'),
        to_date: moment(this.getSelectedDate()).endOf('week').format('YYYY-MM-DD'),
      };
    }
    if (list === LIST_TYPES.Month) {
      params = {
        ...params,
        from_date: moment(this.getSelectedDate()).startOf('month').format('YYYY-MM-DD'),
        to_date: moment(this.getSelectedDate()).endOf('month').format('YYYY-MM-DD'),
      };
    }

    return params;
  };

  renderEmptyDataSet = () => {
    if (this.state.ids.length > 0 || this.state.isFetching) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="screens.asset.operational-maintenances.empty-data-set.title" />}
          subtitle={<FormattedMessage id="screens.asset.operational-maintenances.empty-data-set.subtitle" />}
          image={WorkOrderSmall}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderHeader = () => {
    if (this.state.ids.length == 0 && !this.state.isFetching) return null;

    return (
      <List.Header background expandable>
        <List.Header.Column flex>
          <FormattedMessage id="resources.operational-maintenance.title" />
        </List.Header.Column>
        <List.Header.Column width={180}>
          <FormattedMessage id="resources.operational-maintenance.date" />
        </List.Header.Column>
        <List.Header.Column width={18} />
      </List.Header>
    );
  };

  renderFetchingListItems = () => {
    if (this.state.ids.length > 0) {
      return (
        <List small>
          {this.state.ids.map(id => (
            <OperationalMaintenanceListItem key={id} id={id} loading />
          ))}
        </List>
      );
    } else {
      return (
        <List small>
          <OperationalMaintenanceListItem loading />
          <OperationalMaintenanceListItem loading />
        </List>
      );
    }
  };

  renderListItems = () => {
    if (this.state.ids.length == 0 && !this.state.isFetching) return null;
    if (this.state.isFetching) return this.renderFetchingListItems();

    const list = this.getSelectedList();
    let dateFormat;
    switch (list) {
      case LIST_TYPES.Day:
        dateFormat = 'day';
        break;
      case LIST_TYPES.Week:
        dateFormat = 'week';
        break;
      case LIST_TYPES.Month:
        dateFormat = 'month';
        break;
    }

    return (
      <List small>
        {this.state.ids.map(id => (
          <OperationalMaintenanceListItem
            key={id}
            id={id}
            dateFormat={dateFormat}
            fromAssetId={this.props.match.params.id}
            onClick={this.setNavigatedTo}
          />
        ))}
      </List>
    );
  };

  renderContent = () => (
    <>
      <Toolbar />
      {this.renderHeader()}
      {this.renderListItems()}
      {this.renderEmptyDataSet()}
    </>
  );

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderContent()}</ContentContainer>
        </PerfectScrollbar>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrders: AssetOperations.fetchWorkOrders,
      fetchInstancesForDates: AssetOperations.fetchInstancesForDates,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    workOrders: AssetSelectors.getWorkOrders(state),
    pagination: AssetSelectors.getWorkOrderListPagination(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenances)));
