import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';

export default class NewDowntimeModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.open} width={520}>
        <Modal.Header
          ignoreLine
          subtitleTopMargin={false}
          title={<FormattedMessage id="components.new-downtime-modal.title" />}
          subtitle={<FormattedMessage id="components.new-downtime-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <List light>
            <List.Item clickable disclosureIndicator onClick={this.props.onCreateCompletedDowntime}>
              <List.Item.TitleColumn
                title={<FormattedMessage id="components.new-downtime-modal.types.completed.title" />}
                subtitle={<FormattedMessage id="components.new-downtime-modal.types.completed.subtitle" />}
              />
            </List.Item>
            <List.Item clickable disclosureIndicator onClick={this.props.onCreateActiveDowntime}>
              <List.Item.TitleColumn
                title={<FormattedMessage id="components.new-downtime-modal.types.timer.title" />}
                subtitle={<FormattedMessage id="components.new-downtime-modal.types.timer.subtitle" />}
              />
            </List.Item>
          </List>
        </Modal.Content>
      </Modal>
    );
  }
}
