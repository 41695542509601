import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { getShouldOrderQuantity } from 'sdk/SparePart';
import { List, Field, Icon, MoneyWithCurrency, Tooltip } from 'views/components/Shared/General';
import { SparePartUnitWithValue, Title, SparePartListItemExpandable } from 'views/components/SparePart';
import { MultipleSparePartVendorsModal } from 'views/components/SparePartVendor';
import SelectedSparePartVendorItem from './SelectedSparePartVendorItem';
import QuantityInputField from './QuantityInputField';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      quantity: '',
      sparePartVendorId: null,
    };
  }

  componentDidMount() {
    const { sparePartVendors } = this.props;
    let quantity = '';
    if (sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        quantity = '';
      } else {
        quantity = getShouldOrderQuantity(this.props.sparePart) || '';
      }
      this.setState({
        sparePartVendorId: sparePartVendors[0].id,
        selected: true,
        quantity,
      });
    }
  }

  /*
    Helper functions
  */

  isAvailableForAddingToPurchaseOrder = () => {
    return this.state.selected;
  };

  getPurchaseOrderRow = () => {
    return {
      spare_part_vendor_id: this.state.sparePartVendorId,
      quantity: this.state.quantity,
    };
  };

  /*
    Render Functions
  */

  renderSubtitle = () => {
    const { stock_quantity, maximum_quantity } = this.props.sparePart;
    if (maximum_quantity == null) {
      return (
        <Field fontSize={12} view label={<FormattedMessage id="resources.spare-part.in-stock" />} singleRow>
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={stock_quantity} />
        </Field>
      );
    }
    return (
      <div className={styles['should-order-container']}>
        <Field
          fontSize={12}
          view
          label={
            <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.should-order" />
          }
          singleRow
        >
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={getShouldOrderQuantity(this.props.sparePart)}
          />
        </Field>
      </div>
    );
  };

  renderExpandedComponent = () => {
    return <SparePartListItemExpandable id={this.props.sparePart.id} />;
  };

  renderMultipleSparePartVendorsModal = () => {
    return (
      <MultipleSparePartVendorsModal
        select
        open={this.state.showSelectSparePartVendorModal}
        vendorId={this.props.vendorId}
        sparePartId={this.props.id}
        onSelectSparePartVendor={({ id }) => {
          this.setState({ selected: true, showSelectSparePartVendorModal: false, sparePartVendorId: id });
        }}
        onClose={() => this.setState({ showSelectSparePartVendorModal: false })}
      />
    );
  };

  renderPurchasePrice = () => {
    const { vendor, sparePartVendors } = this.props;
    if (sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      return (
        <List.Item.Column>
          {sparePartVendor.purchase_price ? (
            <MoneyWithCurrency
              currency={vendor.purchase_order_currency}
              value={sparePartVendor.purchase_price}
            />
          ) : null}
        </List.Item.Column>
      );
    }
  };

  renderCheckbox = () => {
    return (
      <List.Item.Column borderLeft width={37}>
        <div className={styles['checkbox-container']}>
          <Field.Checkbox
            disabled={this.state.sparePartVendorId == null}
            checked={this.state.selected}
            onChange={() => {
              this.setState(prevState => ({
                selected: !prevState.selected,
              }));
            }}
          />
        </div>
      </List.Item.Column>
    );
  };

  renderPackageDropown = () => {
    const { sparePartVendors } = this.props;
    if (sparePartVendors.length > 1) {
      if (this.state.sparePartVendorId) {
        return (
          <div
            className={styles['select-dropdown']}
            onClick={() => this.setState({ showSelectSparePartVendorModal: true })}
          >
            <SelectedSparePartVendorItem id={this.state.sparePartVendorId} />
          </div>
        );
      }
      return (
        <List.Item.Column alignRight>
          <div
            className={styles['select-dropdown']}
            onClick={() => this.setState({ showSelectSparePartVendorModal: true })}
          >
            <div>
              <FormattedMessage id="screens.should-order-spare-parts.select-alternative" />
            </div>
            <div className={styles['icon-container']}>
              <Icon type="angle-down" regular />
            </div>
          </div>
        </List.Item.Column>
      );
    }
    return null;
  };

  renderQuantityField = () => {
    return (
      <QuantityInputField
        sparePartId={this.props.id}
        sparePartVendorId={this.state.sparePartVendorId}
        disabled={this.state.sparePartVendorId == null}
        value={this.state.quantity}
        onChange={quantity => {
          this.setState({ quantity });
        }}
        onBlur={quantity => {
          this.setState({ quantity });
        }}
      />
    );
  };

  renderAddedText = () => {
    if (this.props.selected) {
      return (
        <List.Item.Column>
          <span className={styles['added']}>
            <FormattedMessage id="screens.purchase-order.info.articles.select-spare-part-modal.added" />
          </span>
        </List.Item.Column>
      );
    }
    return null;
  };

  renderPackageTooltip = () => {
    const { sparePartVendors } = this.props;
    if (sparePartVendors && sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        return (
          <List.Item.Column alignRight>
            <Tooltip
              label={
                <>
                  <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                  <span>: </span>
                  <SparePartUnitWithValue
                    sparePartUnit={this.props.sparePartUnit}
                    value={sparePartVendor.package_quantity}
                  />
                </>
              }
              trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  renderNotPrimaryWarningTooltip = () => {
    const { sparePart, vendor } = this.props;
    const { primary_vendor_id } = sparePart;
    if (primary_vendor_id && primary_vendor_id !== vendor.id) {
      return (
        <Tooltip
          label={<FormattedMessage id="screens.should-order-spare-parts.not-primary" />}
          trigger={<Icon size={14} regular type="info-circle" red />}
        />
      );
    }

    return null;
  };

  render() {
    return (
      <>
        <List.Item expandable expandedComponent={this.renderExpandedComponent()}>
          {this.renderCheckbox()}
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            title={<Title sparePart={this.props.sparePart} />}
            subtitle={this.renderSubtitle()}
          />
          {this.renderAddedText()}
          {this.renderNotPrimaryWarningTooltip()}
          {this.renderPackageTooltip()}
          {this.renderPurchasePrice()}
          {this.renderPackageDropown()}
          {this.renderQuantityField()}
        </List.Item>
        {this.renderMultipleSparePartVendorsModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  return {
    sparePart,
    images: EntitySelectors.getImages(state, sparePart.images),
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    sparePartVendors: EntitySelectors.getSparePartVendors(state, sparePart.spare_part_vendors_for_vendor),
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(SparePartListItem);
