export const TemplateFieldType = {
  Text: 'text',
  ValueList: 'valuelist',
  TextArea: 'textarea',
  Date: 'date',
};

export function getIconForTemplateFieldType(type) {
  switch (type) {
    case TemplateFieldType.Text:
      return 'font outline';
    case TemplateFieldType.TextArea:
      return 'align left outline';
    case TemplateFieldType.Date:
      return 'calendar alternate outline';
    case TemplateFieldType.ValueList:
      return 'list outline';
    default:
      return '';
  }
}
