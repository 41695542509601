export const Type = {
  Inspection: 'inspection',
  Section: 'section',
  Meter: 'meter',
};

export const MeterType = {
  Fixed: 'fixed',
  Option: 'option',
};

export function generateEmptyChecklistTemplateRow() {
  return {
    meter_id: null,
    meter_type: MeterType.Option,
    images: [],
    attachments: [],
  };
}
