import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { SparePartSelectors, SparePartOperations } from 'state/ducks/sparePart';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, Pagination, WhiteCard } from 'views/components/Shared/General';
import { NewPurchaseOrderModal } from 'views/components/PurchaseOrder';
import { Loader } from 'views/components/Shared/General';
import PurchaseOrderTiny from 'assets/images/EmptyDataSet/PurchaseOrderTiny.png';
import PurchaseOrderListItem from './PurchaseOrderListItem';
import styles from './style.module.scss';

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      showNewPurchaseOrderModal: false,
      tableLoading: false,
      viewInitialized: false,
      createdIds: {},
      queryParams,
    };
  }

  componentDidMount() {
    this.fetchPurchaseOrders()
      .then(() => {
        this.setState({ viewInitialized: true });
      })
      .catch(() => {
        this.setState({ viewInitialized: true });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchPurchaseOrders()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchPurchaseOrders = () => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);
    return this.props
      .fetchPurchaseOrders(this.props.currentSystem.id, {
        spare_part_id: this.props.match.params.id,
        ...filterParams,
      })
      .then(({ data: spare_part_assets }) => {
        return spare_part_assets;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  newPurchaseOrder = () => {
    this.setState({ showNewPurchaseOrderModal: true });
  };

  renderNewPurchaseOrderModal = () => (
    <NewPurchaseOrderModal
      sparePartId={this.props.match.params.id}
      open={this.state.showNewPurchaseOrderModal}
      onClose={() => {
        this.setState({ showNewPurchaseOrderModal: false });
      }}
      onCreated={purchaseOrder => {
        const createdIdsObject = {};
        createdIdsObject[purchaseOrder.id] = true;
        this.setState(
          {
            showNewPurchaseOrderModal: false,
            createdIds: { ...this.state.createdIds, ...createdIdsObject },
          },
          () => {
            this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
          }
        );
      }}
      onCreatedWithReopen={purchaseOrder => {
        const createdIdsObject = {};
        createdIdsObject[purchaseOrder.id] = true;
        this.setState(
          {
            showNewPurchaseOrderModal: false,
            createdIds: { ...this.state.createdIds, ...createdIdsObject },
          },
          () => {
            setTimeout(() => {
              this.setState({ showNewPurchaseOrderModal: true });
            }, 400);
          }
        );
      }}
    />
  );

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.spare-part.purchase-orders.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.spare-part.purchase-orders.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.spare-part.purchase-orders.empty-data-set.subtitle" />}
        image={PurchaseOrderTiny}
        button={
          this.props.canEditPurchaseOrders ? (
            <Button
              small
              primary
              onClick={this.newPurchaseOrder}
              label="screens.spare-part.purchase-orders.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditPurchaseOrders) {
      return (
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.spare-part.purchase-orders.create-button"
              onClick={this.newPurchaseOrder}
            />
          }
        />
      );
    }
    return null;
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.purchaseOrders.length === 0 ? 1 : this.props.purchaseOrders.length)
              .fill()
              .map(() => (
                <PurchaseOrderListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.purchaseOrders.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.purchaseOrders.map(purchaseOrder => (
            <PurchaseOrderListItem
              purchaseOrder={purchaseOrder}
              onClick={() => {
                this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
              }}
              new={this.state.createdIds[purchaseOrder.id]}
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderHeader = () => (
    <List.Header background>
      <List.Header.Column flex>
        <FormattedMessage id="resources.purchase-order.resource" />
      </List.Header.Column>
      <List.Header.Column width={150}>
        <FormattedMessage id="resources.purchase-order.status" />
      </List.Header.Column>
      <List.Header.Column width={150} alignRight>
        <FormattedMessage id="resources.purchase-order.total-cost" />
      </List.Header.Column>
    </List.Header>
  );

  renderPurchaseOrders = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderHeader()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.purchaseOrders.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderPurchaseOrders();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderNewPurchaseOrderModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrders: SparePartOperations.fetchPurchaseOrders,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    purchaseOrders: SparePartSelectors.getPurchaseOrders(state),
    pagination: SparePartSelectors.getPurchaseOrdersPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders)));
