import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, List } from 'views/components/Shared/General';
import { Toolbar, ListLayout } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { UsersSelectors, UsersOperations } from 'state/ducks/users';
import { FormattedMessage } from 'react-intl';
import EditPermissionProfileModal from './EditPermissionProfileModal';
import styles from './style.module.scss';

class PermissionProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showPermissionProfileModal: false,
      showPermissionProfileModalForPermissionProfileId: null,
    };
  }

  componentDidMount() {
    this.props.fetchPermissionProfiles(this.props.selectedSystem.id, { no_pagination: true }).then(() => {
      this.setState({ isFetching: false });
    });
  }

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.users.permission-profiles.empty-data-set.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.users.permission-profiles.empty-data-set.subtitle" />
        </div>
        <div className={styles['button']}>
          <Button
            primary
            onClick={() =>
              this.setState({
                showPermissionProfileModal: true,
                showPermissionProfileModalForPermissionProfileId: null,
              })
            }
            fontSize={12}
            label="screens.users.permission-profiles.empty-data-set.button"
          />
        </div>
      </div>
    );
  };

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.permission-profile.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <>
      <Toolbar
        buttons={
          <Button
            primary
            onClick={() =>
              this.setState({
                showPermissionProfileModal: true,
                showPermissionProfileModalForPermissionProfileId: null,
              })
            }
            label="screens.users.permission-profiles.add-button"
          />
        }
      />
      {this.renderHeader()}
      <List small>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </>
  );

  renderList = () => {
    const filteredPermissionProfiles = this.props.permissionProfiles.filter(
      wot =>
        this.props.searchTerm === '' || wot.title.toLowerCase().includes(this.props.searchTerm.toLowerCase())
    );
    if (filteredPermissionProfiles.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="general.empty-data-set-search.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="general.empty-data-set-search.subtitle" />
          </div>
        </div>
      );
    }
    return (
      <>
        {this.renderHeader()}
        <List>
          {filteredPermissionProfiles.map(permissionProfile => (
            <List.Item
              clickable
              small
              onClick={() =>
                this.setState({
                  showPermissionProfileModal: true,
                  showPermissionProfileModalForPermissionProfileId: permissionProfile.id,
                })
              }
            >
              <List.Item.TitleColumn title={permissionProfile.title} />
            </List.Item>
          ))}
        </List>
      </>
    );
  };

  renderPermissionProfiles = () => {
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showPermissionProfileModal: true,
                  showPermissionProfileModalForPermissionProfileId: null,
                })
              }
              label="screens.users.permission-profiles.add-button"
            />
          }
        />
        {this.renderList()}
      </>
    );
  };

  renderContent() {
    if (this.state.isFetching) {
      return this.renderLoader();
    } else if (this.props.permissionProfiles.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderPermissionProfiles();
    }
  }

  render() {
    return (
      <>
        <ListLayout.Content.MainContent>
          <ListLayout.Content.MainContent.Content>
            {this.renderContent()}
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
        <EditPermissionProfileModal
          id={this.state.showPermissionProfileModalForPermissionProfileId}
          open={this.state.showPermissionProfileModal}
          onClose={() => {
            this.setState({ showPermissionProfileModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPermissionProfiles: UsersOperations.fetchPermissionProfiles,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    permissionProfiles: UsersSelectors.getPermissionProfiles(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionProfiles);
