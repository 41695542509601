import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import { ChooseUserInlineModal } from 'views/components/User';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import UserContainer from './UserContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class PausedBy extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter.paused_by_user_id || props.initialFilter.paused_by_user_id;
    this.state = {
      comparator,
      ids: value,
      initialFilter: props.filter,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.PausedBy,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.PausedBy]: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.PausedBy,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.PausedBy]: {
                  comparator: this.state.comparator,
                  value: this.state.ids,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    return this.state.ids.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedUsers = () => {
    if (this.state.ids.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.ids}
        renderItem={id => <UserContainer id={id} />}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChooseUserInlineModal
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedUsers()}
            onClear={() => this.setState({ ids: [] })}
          />
        }
        selectedUserIds={this.state.ids}
        onAddUser={id => {
          this.setState({
            ids: [...this.state.ids, id],
          });
        }}
        onRemoveUser={id => {
          this.setState({
            ids: this.state.ids.filter(item => item !== id),
          });
        }}
        onClear={() => this.setState({ ids: [] })}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (
              isEqual(this.props.initialFilter.paused_by_user_id, this.state.initialFilter.paused_by_user_id)
            ) {
              this.props.removeFilter({
                key: FILTER_TYPES.PausedBy,
                data: {
                  work_order: {
                    ...this.props.filter,
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.PausedBy,
                data: {
                  work_order: {
                    ...this.props.filter,
                    paused_by_user_id: {
                      ...this.state.initialFilter.paused_by_user_id,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.paused-by" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PausedBy);

PausedBy.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

PausedBy.defaultProps = {
  filter: {
    paused_by_user_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
  initialFilter: {
    paused_by_user_id: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
};
