import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ChecklistFormSelectors } from 'state/ducks/checklistForm';
import Row from './Row';
import { EmptyDataSet } from 'views/components/Shared/General';

class Rows extends Component {
  render() {
    if (this.props.rootRowIds.length === 0) {
      return (
        <EmptyDataSet
          title={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.title" />}
          subtitle={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.subtitle" />}
          modal
        />
      );
    }
    return this.props.rootRowIds.map((id, index) => {
      return (
        <Row
          id={id}
          prevTemplateRowId={this.props.rootRowIds[index - 1]}
          nextTemplateRowId={this.props.rootRowIds[index + 1]}
        />
      );
    });
  }
}

function mapStateToProps(state, ownProps) {
  return {
    rootRowIds: ChecklistFormSelectors.getRootTemplateFieldIds(state),
  };
}

export default connect(mapStateToProps)(Rows);
