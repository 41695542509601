import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { MenuUtils, MenuOperations, MenuSelectors } from 'state/ducks/menu';
import { Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import MenuButton from '../MenuButton';
import styles from './style.module.scss';

class OperatorMenu extends Component {
  renderOperationalMaintenanceMenuButton = () => {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    if (operationalMaintenanceIsActivated) {
      return (
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.OperationalMaintenances}
          title={<FormattedMessage id="menu.main.maintenance.operational-maintenances.title" />}
          linkTo={{
            pathname: `/operational-maintenances`,
            search: `list=${localStorage.getItem('operationalMaintenancesView') || 'calendar'}`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/operational-maintenances') {
              this.props.resetListState();
            }
          }}
        />
      );
    }
    return null;
  };

  renderDowntimesButton = () => {
    if (this.props.settings.downtime_activated) {
      return (
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Asset}
          title={<FormattedMessage id="menu.main.assets.downtimes.title" />}
          linkTo={{
            pathname: `/downtimes`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/downtimes') {
              this.props.resetListState();
            }
          }}
        />
      );
    }
    return null;
  };

  renderAttachmentsButton = () => {
    if (this.props.settings.operator_permission_asset_attachments === 'view') {
      return (
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Files}
          title={<FormattedMessage id="menu.main.more-options.files.title" />}
          linkTo={{
            pathname: `/files`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/files') {
              this.props.resetListState();
            }
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.ProductionBoard}
          title={<Icon solid white type="home-alt" />}
          linkTo={{
            pathname: `/production-board`,
          }}
        />
        <div className={styles['separator']} />
        {this.renderOperationalMaintenanceMenuButton()}
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.Requests}
          title={<FormattedMessage id="menu.main.maintenance.requests.title" />}
          linkTo={{
            pathname: `/requests`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/requests') {
              this.props.resetListState();
            }
          }}
        />
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.WorkOrders}
          title={<FormattedMessage id="menu.main.maintenance.work-orders.title" />}
          linkTo={{
            pathname: `/work-orders`,
            search: 'list=upcoming',
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/work-orders') {
              this.props.resetListState();
            }
          }}
        />
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.SpareParts}
          title={<FormattedMessage id="menu.main.spare-parts.spare-parts.title" />}
          linkTo={{
            pathname: `/spare-parts`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/spare-parts') {
              this.props.resetListState();
            }
          }}
        />
        <MenuButton
          selected={this.props.selectedItem === MenuUtils.MENU_ITEM_TYPE.SparePartWithdrawals}
          title={<FormattedMessage id="menu.main.spare-parts.withdrawals.title" />}
          linkTo={{
            pathname: `/withdrawals`,
          }}
          onClick={() => {
            if (this.props.location.pathname !== '/withdrawals') {
              this.props.resetListState();
            }
          }}
        />
        {this.renderDowntimesButton()}
        {this.renderAttachmentsButton()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetListState: MenuOperations.resetListState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    selectedItem: MenuSelectors.getSelectedItem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OperatorMenu));
