import React, { Component } from 'react';
import styles from './style.module.scss';

export default class Tab extends Component {
  render() {
    let classNames = [styles['tab']];
    if (this.props.selected) {
      classNames = [...classNames, styles['selected']];
    }
    return (
      <div className={classNames.join(' ')} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}
