import React, { Component } from 'react';
import { NewTab } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import Rules from './Rules';
import Groups from './Groups';
import styles from './style.module.scss';

const TABS = {
  Rules: 'rules',
  Groups: 'groups',
};

export default class Automation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: TABS.Rules,
    };
  }

  renderTabs = () => {
    return (
      <div className={styles['tab-container']}>
        <NewTab.Container>
          <NewTab selected={this.state.tab === TABS.Rules} onClick={() => this.setState({ tab: TABS.Rules })}>
            <FormattedMessage id="screens.settings.requests.assignees.automation.tabs.rules" />
          </NewTab>
          <NewTab
            selected={this.state.tab === TABS.Groups}
            onClick={() => this.setState({ tab: TABS.Groups })}
          >
            <FormattedMessage id="screens.settings.requests.assignees.automation.tabs.groups" />
          </NewTab>
        </NewTab.Container>
      </div>
    );
  };

  renderContent = () => {
    if (this.state.tab === TABS.Rules) {
      return (
        <Rules
          editingSettings={this.props.editingSettings}
          onChange={params => this.props.onChange(params)}
        />
      );
    }
    return <Groups />;
  };

  render() {
    return (
      <>
        {this.renderTabs()}
        {this.renderContent()}
      </>
    );
  }
}
