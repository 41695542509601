import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { CardGrid } from 'views/components/Shared/Layout';
import { SectionHeader } from 'views/components/Shared/General';
import Attachment from './Attachment';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class AttachmentsContainer extends Component {
  render() {
    const { attachments } = this.props;
    if (attachments.length === 0) return null;
    return (
      <CardGrid.Row>
        <SectionHeader paddingHorizontal={30} horizontalBorders>
          <FormattedMessage id="screens.operational-maintenance.attachments.title" />
        </SectionHeader>
        {attachments.map(attachment => {
          return <Attachment attachment={attachment} />;
        })}
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateVersion } = ownProps;

  return {
    attachments:
      EntitySelectors.getAttachments(state, operationalMaintenanceTemplateVersion.attachments) || [],
  };
}

export default connect(mapStateToProps)(AttachmentsContainer);
