import React, { Component } from 'react';
import { List } from 'views/components/Shared/General';
import { DueDateColumn, StatusColumn } from 'views/components/WorkOrder';
import styles from './style.module.scss';

export default class WorkOrderItem extends Component {
  render() {
    return (
      <List.Item small clickable onClick={() => this.props.onSelect(this.props.workOrder.id)}>
        <List.Item.TitleColumn
          title={
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.props.workOrder.title}</div>
              <div className={styles['number']}> - #{this.props.workOrder.number}</div>
            </div>
          }
        />
        <List.Item.Column width={140}>
          <DueDateColumn workOrder={this.props.workOrder} />
        </List.Item.Column>
        <List.Item.Column borderLeft width={45}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StatusColumn icon workOrder={this.props.workOrder} />
          </div>
        </List.Item.Column>
      </List.Item>
    );
  }
}
