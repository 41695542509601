import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { DragOverlay } from '@dnd-kit/core';
import { AuthSelectors } from 'state/ducks/auth';
import { Banner } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import update from 'immutability-helper';
import DndContextContainer from './DndContextContainer';
import DroppableColumn from './DroppableColumn';
import TemplateField from './TemplateField';
import styles from './style.module.scss';
import NewFieldRightPanel from './NewFieldRightPanel';
import EditTemplateFieldRightPanel from './EditTemplateFieldRightPanel';
import { SDKReduxOperations } from 'sdk';

class TemplateFields extends Component {
  getInitialState = () => ({
    fields: { 1: [], 2: [] },
    isDraggingTemplateFieldId: null,
    isDraggingTemplateFieldType: null,
    editingTemplateFieldId: null,
    newTemplateField: null,
  });

  state = this.getInitialState();

  componentDidMount() {
    this.setState({
      ...this.getInitialState(),
      fields: this.generateDraggableFieldsDataStructure(),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetTypeId !== this.props.assetTypeId) {
      this.setState({ fields: this.generateDraggableFieldsDataStructure() });
      this.draggableFieldsScrollContainer.scrollTo({
        top: this.draggableFieldsScrollContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  generateDraggableFieldsDataStructure = () => {
    return {
      1: this.props.templateFields
        .filter(({ column }) => column === 1)
        .filter(({ asset_type_id }) => asset_type_id == this.props.assetTypeId)
        .sort((a, b) => a.sort - b.sort)
        .map(({ id }) => id),
      2: this.props.templateFields
        .filter(({ column }) => column === 2)
        .filter(({ asset_type_id }) => asset_type_id == this.props.assetTypeId)
        .sort((a, b) => a.sort - b.sort)
        .map(({ id }) => id),
    };
  };

  sortFieldsInColumn = ({ fromColumn, fromIndex, toIndex, templateFieldId }) => {
    this.setState(
      update(
        { ...this.state },
        {
          fields: {
            [fromColumn]: {
              $splice: [
                [fromIndex, 1],
                [toIndex, 0, templateFieldId],
              ],
            },
          },
        }
      )
    );
  };

  sortFieldsBetweenColumn = ({ fromColumn, toColumn, fromIndex, toIndex, templateFieldId }) => {
    this.setState(
      update(
        { ...this.state },
        {
          fields: {
            [fromColumn]: { $splice: [[fromIndex, 1]] },
            [toColumn]: {
              $splice: [[toIndex, 0, templateFieldId]],
            },
          },
        }
      )
    );
  };

  addNewFieldToColumn = ({ toColumn, toIndex, templateFieldId, type }) => {
    const oldType = type;
    const newState = update(
      { ...this.state },
      {
        fields: {
          [toColumn]: {
            $splice: [[toIndex, 0, templateFieldId]],
          },
        },
        isDraggingTemplateFieldType: {
          $set: null,
        },
      }
    );
    this.setState({ ...newState }, () => {
      setTimeout(() => {
        this.setState({ isDraggingTemplateFieldType: oldType });
      });
    });
  };

  findContainer = id => {
    if (id === 1 || id === 2) {
      return id;
    }
    if (this.state.fields[1].includes(id)) {
      return 1;
    }
    if (this.state.fields[2].includes(id)) {
      return 2;
    }
    return null;
  };

  onDragStart = event => {
    const { active } = event;
    const { id } = active;
    const type = id.split('_')[2];
    const isCreatingNewField = id.substr(0, 3) === 'new';
    if (isCreatingNewField) {
      this.setState({ isDraggingTemplateFieldId: null, isDraggingTemplateFieldType: type });
    } else {
      this.setState({ isDraggingTemplateFieldId: id, isDraggingTemplateFieldType: null });
    }
  };

  onDragOver = event => {
    /*
      Seems to fix infinite loop sometimes with different height elements;
    */
    if (event.delta.x === this.deltaX && event.delta.y === this.deltaY) return;
    this.deltaX = event.delta.x;
    this.deltaY = event.delta.y;

    const { active, over } = event;
    const { id: templateFieldId } = active;
    const { id: overId } = over;

    const type = templateFieldId.split('_')[2];
    const isCreatingNewField = templateFieldId.substr(0, 3) === 'new';
    const fromColumn = this.findContainer(templateFieldId);
    const toColumn = this.findContainer(overId);
    if (toColumn == null) {
      return;
    }
    let overItems = this.state.fields[toColumn];
    let overIndex = overItems.indexOf(overId);
    let toIndex = null;
    let fromIndex = null;
    if (fromColumn != null) {
      fromIndex = this.state.fields[fromColumn].findIndex(id => id === templateFieldId);
    }
    if (templateFieldId === overId) {
      return;
    }
    if (overId === 1 || overId === 2) {
      toIndex = overItems.length;
    } else {
      const isBelowLastItem =
        over &&
        overIndex === overItems.length - 1 &&
        active.rect?.current?.translated?.top > over.rect.top + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      toIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    }
    if (isCreatingNewField && fromColumn == null) {
      this.addNewFieldToColumn({ fromColumn, toColumn, fromIndex, toIndex, templateFieldId, type });
    } else if (fromColumn === toColumn) {
      this.sortFieldsInColumn({ fromColumn, fromIndex, toIndex, templateFieldId });
    } else {
      this.sortFieldsBetweenColumn({ fromColumn, toColumn, fromIndex, toIndex, templateFieldId });
    }
  };

  onDragEnd = event => {
    const { active, over } = event;
    const { id: templateFieldId } = active;
    const { id: overId } = over;
    if (this.state.isDraggingTemplateFieldType) {
      const type = templateFieldId.split('_')[2];
      const toColumn = this.findContainer(overId);
      if (toColumn == null) {
        return null;
      }
      const overItems = this.state.fields[toColumn];
      const overIndex = overItems.indexOf(templateFieldId);
      this.setState({
        isDraggingTemplateFieldId: null,
        isDraggingTemplateFieldType: null,
        newTemplateField: {
          template_type: this.props.templateType,
          asset_type_id: this.props.assetTypeId,
          column: toColumn,
          sort: overIndex + 1,
          type,
        },
      });
    } else {
      const toColumn = this.findContainer(overId);
      const overItems = this.state.fields[toColumn];
      const overIndex = overItems.indexOf(templateFieldId);
      this.props
        .updateTemplateField(templateFieldId, {
          column: toColumn,
          sort: overIndex + 1,
        })
        .then(() => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        });
      this.setState({
        isDraggingTemplateFieldId: null,
        isDraggingTemplateFieldType: null,
      });
    }
  };

  renderDragOverlay = () => {
    return (
      <DragOverlay
        style={{
          marginLeft: -(window.innerWidth - 1200) / 2,
          marginTop: -50,
        }}
      >
        {this.state.isDraggingTemplateFieldId ? (
          <TemplateField id={this.state.isDraggingTemplateFieldId} isDragging />
        ) : null}
        {this.state.isDraggingTemplateFieldType ? (
          <TemplateField type={this.state.isDraggingTemplateFieldType} isDragging />
        ) : null}
      </DragOverlay>
    );
  };

  setFieldIdForEdit = id => {
    if (this.state.newTemplateField) {
      const { column, sort } = this.state.newTemplateField;
      this.setState(
        update(
          { ...this.state },
          {
            newTemplateField: {
              $set: null,
            },
            editingTemplateFieldId: {
              $set: id,
            },
            fields: {
              [column]: { $splice: [[sort - 1, 1]] },
            },
          }
        )
      );
    } else {
      this.setState({ newTemplateField: null, editingTemplateFieldId: id });
    }
  };

  renderDraggableFields = () => {
    return (
      <>
        <div className={styles['fields']}>
          <DroppableColumn
            key={1}
            column={1}
            fieldIds={this.state.fields[1]}
            selectedTemplateFieldId={this.state.editingTemplateFieldId}
            onSelectTemplateField={id => this.setFieldIdForEdit(id)}
            disableDrag={this.state.newTemplateField != null}
          />
          <DroppableColumn
            key={2}
            column={2}
            fieldIds={this.state.fields[2]}
            selectedTemplateFieldId={this.state.editingTemplateFieldId}
            onSelectTemplateField={id => this.setFieldIdForEdit(id)}
            disableDrag={this.state.newTemplateField != null}
          />
        </div>
        {this.renderEmptyDataSet()}
      </>
    );
  };

  renderEmptyDataSetText = () => {
    if (this.props.templateType === 'asset') {
      if (this.props.assetType) {
        return (
          <span>
            <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.empty-data-set.asset.add-specific" />
            <span>: </span>
            {this.props.assetType.title}
          </span>
        );
      }
      return (
        <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.empty-data-set.asset.add-general" />
      );
    }
    return (
      <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.empty-data-set.spare-part.add-general" />
    );
  };

  renderEmptyDataSet = () => {
    const fieldIdsInColumnOne = this.state.fields[1];
    const fieldIdsInColumnTwo = this.state.fields[2];
    if (fieldIdsInColumnOne.length === 0 && fieldIdsInColumnTwo.length === 0) {
      return <div className={styles['empty-data-set-container']}>{this.renderEmptyDataSetText()}</div>;
    }
    return null;
  };

  renderBannerText = () => {
    if (this.props.templateType === 'asset') {
      if (this.props.assetType) {
        return (
          <>
            <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.banner.handle-specific-asset-type" />
            <span className={styles['banner-asset-type-text']}>: {this.props.assetType.title}</span>
          </>
        );
      }
      return (
        <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.banner.handle-all-asset-types" />
      );
    }
    return (
      <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.banner.handle-all-spare-part-types" />
    );
  };

  renderBanner = () => {
    return (
      <div className={styles['banner-container']}>
        <Banner orange>{this.renderBannerText()}</Banner>
      </div>
    );
  };

  renderDroppableColumnsWithDraggableFieldsWithoutAssetType = () => {
    return <div className={styles['fields-container']}>{this.renderDraggableFields()}</div>;
  };

  renderDroppableColumnsWithDraggableFieldsWithAssetType = () => {
    const disabledFieldIdsInColumnOne = this.props.templateFields
      .filter(({ column }) => column === 1)
      .filter(({ asset_type_id }) => asset_type_id == null)
      .sort((a, b) => a.sort - b.sort)
      .map(({ id }) => id);
    const disabledFieldIdsInColumnTwo = this.props.templateFields
      .filter(({ column }) => column === 2)
      .filter(({ asset_type_id }) => asset_type_id == null)
      .sort((a, b) => a.sort - b.sort)
      .map(({ id }) => id);
    return (
      <div className={styles['fields-container']}>
        <div className={styles['fields']}>
          <div className={styles['disabled']}>
            <DroppableColumn key={1} column={1} fieldIds={disabledFieldIdsInColumnOne} />
            <DroppableColumn key={2} column={2} fieldIds={disabledFieldIdsInColumnTwo} />
          </div>
        </div>
        <div className={styles['separator']} />
        {this.renderDraggableFields()}
      </div>
    );
  };

  renderDroppableColumnsWithDraggableFields = () => {
    if (this.props.assetTypeId == null) {
      return this.renderDroppableColumnsWithDraggableFieldsWithoutAssetType();
    }
    return this.renderDroppableColumnsWithDraggableFieldsWithAssetType();
  };

  renderRightMenu = () => {
    if (this.state.editingTemplateFieldId || this.state.newTemplateField) {
      return (
        <EditTemplateFieldRightPanel
          newTemplateField={this.state.newTemplateField}
          templateFieldId={this.state.editingTemplateFieldId}
          onCreateNewTemplateField={data => {
            this.setState({
              ...update(
                { ...this.state },
                {
                  editingTemplateFieldId: {
                    $set: null,
                  },
                  newTemplateField: {
                    $set: null,
                  },
                  fields: {
                    [data.column]: {
                      $splice: [[data.sort - 1, 1, data.id]],
                    },
                  },
                }
              ),
            });
          }}
          onDeleteTemplateField={id => {
            const column = this.findContainer(id);
            const overItems = this.state.fields[column];
            const index = overItems.indexOf(id);
            this.setState(
              update(this.state, {
                editingTemplateFieldId: {
                  $set: null,
                },
                fields: {
                  [column]: { $splice: [[index, 1]] },
                },
              })
            );
          }}
          onClose={() => {
            if (this.state.newTemplateField) {
              this.setState({
                ...update(
                  { ...this.state },
                  {
                    editingTemplateFieldId: {
                      $set: null,
                    },
                    newTemplateField: {
                      $set: null,
                    },
                    fields: {
                      [this.state.newTemplateField.column]: {
                        $splice: [[this.state.newTemplateField.sort - 1, 1]],
                      },
                    },
                  }
                ),
              });
            } else {
              this.setState({ editingTemplateFieldId: null, newTemplateField: null });
            }
          }}
        />
      );
    }
    return <NewFieldRightPanel templateType={this.props.templateType} />;
  };

  renderLoadingDroppableColumns = () => {
    return (
      <div className={styles['fields-container']}>
        <div className={styles['fields']}>
          <DroppableColumn key={1} column={1} loading />
          <DroppableColumn key={2} column={2} loading />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <>
          <div className={styles['content-wrapper']}>
            <div
              className={styles['content-container']}
              ref={ref => (this.draggableFieldsScrollContainer = ref)}
            >
              {this.renderBanner()}
              {this.renderLoadingDroppableColumns()}
            </div>
          </div>
          <div className={styles['right-menu']}>{this.renderRightMenu()}</div>
        </>
      );
    }
    return (
      <DndContextContainer
        onDragStart={this.onDragStart}
        onDragOver={this.onDragOver}
        onDragEnd={this.onDragEnd}
        onDragCancel={() => {
          this.setState({
            isDraggingTemplateFieldId: null,
            isDraggingTemplateFieldType: null,
          });
        }}
      >
        <div className={styles['content-wrapper']}>
          <div
            className={styles['content-container']}
            ref={ref => (this.draggableFieldsScrollContainer = ref)}
          >
            {this.renderBanner()}
            {this.renderDroppableColumnsWithDraggableFields()}
          </div>
        </div>
        <div className={styles['right-menu']}>{this.renderRightMenu()}</div>
        {this.renderDragOverlay()}
      </DndContextContainer>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateTemplateField: SDKReduxOperations.updateTemplateField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { templateType, assetTypeId } = ownProps;
  let templateFields = [];
  if (templateType === 'spare_part') {
    templateFields = AuthSelectors.getSparePartTemplateFields(state);
  } else {
    templateFields = AuthSelectors.getAssetTypeTemplateFields(state, assetTypeId);
  }
  return {
    templateFields,
    assetType: EntitySelectors.getAssetType(state, assetTypeId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateFields);
