import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { InlineModal, Button } from 'views/components/Shared/General';
import FolderList from './FolderList';
import ParentFolderTitleContainer from './ParentFolderTitleContainer';

class MoveFolderInlineModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentFolderId: this.props.defaultOpenFolderId,
      selectedAttachmentFolderId: null,
    };
  }

  getParentFolderIdForFolder = clickedAttachmentFolder => {
    const parentFolder = this.props.allAttachmentFolders.find(
      attachmentFolder => clickedAttachmentFolder.attachment_folder_parent_id == attachmentFolder.id
    );
    if (parentFolder != null) {
      return parentFolder.id;
    }
    return null;
  };

  showFoldersForParentFolder = () => {
    const currentlyShowingFolderForFolder = this.props.allAttachmentFolders.find(
      attachmentFolder => this.state.parentFolderId == attachmentFolder.id
    );
    const newParentFolderId = this.getParentFolderIdForFolder(currentlyShowingFolderForFolder);
    this.setState({ selectedAttachmentFolderId: null, parentFolderId: newParentFolderId });
    this.props.onSizeWasChanged();
  };

  setMoveButtonAsDisabled = () => {
    const selectedFolderToMoveTo = this.state.selectedAttachmentFolderId;
    const tryingToMoveToSameParentFolder =
      this.props.attachmentFolderToMove && this.state.parentFolderId == this.props.defaultOpenFolderId;
    if (selectedFolderToMoveTo) return false;
    if (tryingToMoveToSameParentFolder) return true;
    return false;
  };

  selectFolder = () => {
    let folderId;
    if (this.state.selectedAttachmentFolderId) {
      folderId = this.state.selectedAttachmentFolderId;
    } else if (this.state.parentFolderId) {
      folderId = this.state.parentFolderId;
    } else {
      folderId = null;
    }
    this.props.onSelectFolder(folderId);
  };

  render() {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={
            <ParentFolderTitleContainer
              parentFolderId={this.state.parentFolderId}
              rootFolderTitle={this.props.title}
            />
          }
          showBackButton={this.state.parentFolderId != null}
          onBack={this.showFoldersForParentFolder}
          menuButtonComponent={this.props.menuButtonComponent}
          onClose={this.props.onClose}
        />
        <InlineModal.Body width={300} maxHeight={450}>
          <FolderList
            showAttachmentFoldersForFolderId={this.state.parentFolderId}
            selectedAttachmentFolderId={this.state.selectedAttachmentFolderId}
            disableSelectForFolderId={this.props.disableSelectForFolderId}
            onSelectAttachmentFolder={folder => this.setState({ selectedAttachmentFolderId: folder.id })}
            showFoldersForFolder={({ id }) => {
              this.setState({ parentFolderId: id, selectedAttachmentFolderId: null });
              this.props.onSizeWasChanged();
            }}
          />
          <InlineModal.Separator />
          <InlineModal.Footer right>
            <Button
              primary
              disabled={this.setMoveButtonAsDisabled()}
              label={
                this.state.selectedAttachmentFolderId == null
                  ? 'components.folder-options-inline-modal.change-folder.move-here'
                  : this.props.selectFolderButtonTranslationId
              }
              loading={this.props.loading}
              onClick={this.selectFolder}
            />
          </InlineModal.Footer>
        </InlineModal.Body>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
  };
}

export default connect(mapStateToProps)(MoveFolderInlineModalContent);

MoveFolderInlineModalContent.propTypes = {
  defaultOpenFolderId: PropTypes.string,
  title: PropTypes.element,
  disableSelectForFolderId: PropTypes.string,
  loading: PropTypes.bool,
  onSizeWasChanged: PropTypes.func,
  onClose: PropTypes.func,
  menuButtonComponent: PropTypes.element,
  onSelectFolder: PropTypes.func,
  selectFolderButtonTranslationId: PropTypes.string,
};

MoveFolderInlineModalContent.defaultProps = {
  defaultOpenFolderId: null,
  title: null,
  disableSelectForFolderId: null,
  loading: false,
  menuButtonComponent: null,
  selectFolderButtonTranslationId: 'components.folder-options-inline-modal.change-folder.move',
};
