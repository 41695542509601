import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Icon } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import styles from './style.module.scss';

const SelectedSparePartItem = ({ sparePart, onClear }) => (
  <div className={styles['item']}>
    <div className={styles['icon-container']}>
      <Icon regular type="cogs" />
    </div>
    <div className={styles['content']}>
      <Title sparePart={sparePart} />
    </div>
    <div className={styles['clear-container']}>
      <Button type="icon" icon={<Icon light size={15} type="times" />} onClick={onClear} />
    </div>
  </div>
);

function mapStateToProps(state, ownProps) {
  return {
    sparePart: EntitySelectors.getSparePart(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedSparePartItem);
