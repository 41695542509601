import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NewInlineModal, Loader, Field } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class UserDropdownItem extends Component {
  render() {
    return (
      <NewInlineModal.Dropdown.Item
        key={this.props.id}
        leftComponent={<Field.Checkbox checked={this.props.selected} onChange={this.props.onSelect} />}
        rightComponent={this.props.loading ? <Loader tiny /> : null}
        onClick={this.props.onSelect}
      >
        <UserNameWrapper user={this.props.user} />
      </NewInlineModal.Dropdown.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserDropdownItem);
