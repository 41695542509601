import { merge } from 'lodash-es';
import update from 'immutability-helper';
import types from './types';

const INITIAL_STATE = {
  users: {
    ids: [],
    isFetching: false,
  },
  groups: {
    ids: [],
    isFetching: false,
  },
  vendors: {
    ids: [],
    isFetching: false,
    pagination: {
      totalPages: 1,
      currentPage: 1,
      totalEntries: 0,
    },
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: true,
        },
      };
    case types.FETCH_USERS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: false,
          ids,
        },
      };
    }
    case types.FETCH_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          isFetching: true,
        },
      };
    case types.FETCH_GROUPS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        groups: {
          ...state.groups,
          isFetching: false,
          ids,
        },
      };
    }
    case types.FETCH_VENDORS:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isFetching: true,
        },
      };
    case types.FETCH_VENDORS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        vendors: {
          ...state.vendors,
          isFetching: false,
          ids,
          pagination,
        },
      };
    }
    default:
      return state;
  }
};
