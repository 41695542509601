import { API } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { buildReduxChecklistRowDataWithInstanceRows } from 'sdk/ChecklistTemplate';
import { normalizeOperationalMaintenanceInstance } from 'sdk/Schemas';

const fetchOperationalMaintenanceInstance = id => dispatch => {
  return API.fetchOperationalMaintenanceInstance(id)
    .then(({ data: operationalMaintenanceInstance }) => {
      const { entities } = normalizeOperationalMaintenanceInstance(operationalMaintenanceInstance);
      dispatch(EntityOperations.updateEntities(entities));
      if (
        operationalMaintenanceInstance.checklist_instances &&
        operationalMaintenanceInstance.checklist_instances.length === 1
      ) {
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows:
              operationalMaintenanceInstance.checklist_instances[0].checklist_template_version
                .checklist_template_rows,
            checklist_instance_rows:
              operationalMaintenanceInstance.checklist_instances[0].checklist_instance_rows,
            checklist_instance_options:
              operationalMaintenanceInstance.operational_maintenance_template_asset
                .checklist_instance_options,
          });
        dispatch(
          actions.setChecklistInstance({
            instanceId: id,
            childIds: childRowIds,
            templateFieldIds: rootRowIds,
            instanceRowForTemplateRowIds,
            instanceOptionIdsForTemplateRowIds,
          })
        );
      }

      return operationalMaintenanceInstance;
    })
    .catch(e => {
      throw e;
    });
};

const createOperationalMaintenanceInstance = (systemId, params) => dispatch => {
  return API.createOperationalMaintenanceInstance(systemId, params)
    .then(({ data: operationalMaintenanceInstance }) => {
      const { entities } = normalizeOperationalMaintenanceInstance(operationalMaintenanceInstance);
      dispatch(EntityOperations.updateEntities(entities));
      if (
        operationalMaintenanceInstance.checklist_instances &&
        operationalMaintenanceInstance.checklist_instances.length === 1
      ) {
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows:
              operationalMaintenanceInstance.checklist_instances[0].checklist_template_version
                .checklist_template_rows,
            checklist_instance_rows:
              operationalMaintenanceInstance.checklist_instances[0].checklist_instance_rows,
            checklist_instance_options:
              operationalMaintenanceInstance.operational_maintenance_template_asset
                .checklist_instance_options,
          });
        dispatch(
          actions.setChecklistInstance({
            instanceId: operationalMaintenanceInstance.id,
            childIds: childRowIds,
            templateFieldIds: rootRowIds,
            instanceRowForTemplateRowIds,
            instanceOptionIdsForTemplateRowIds,
          })
        );
      }
      return operationalMaintenanceInstance;
    })
    .catch(e => {
      throw e;
    });
};

export default {
  fetchOperationalMaintenanceInstance,
  createOperationalMaintenanceInstance,
};
