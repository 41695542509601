import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { ChooseUserInlineModal } from 'views/components/User';
import { AuthSelectors } from 'state/ducks/auth';
import { OperationalMaintenancesSelectors } from 'state/ducks/operationalMaintenances';
import { API, HelperFunctions } from 'sdk';
import CompletedByUserContainer from './CompletedByUserContainer';
import styles from './styles.module.scss';

const BATCH_UPDATABLE_FIELDS = {
  Status: 'status',
};

class MassEditModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    confirmMassEdit: false,
    showEditForField: null,
    status: null,
    completed_comment: '',
    completed_date: moment().format('YYYY-MM-DD'),
    completed_by_user_id: this.props.currentUser.id,
    showCompletedDateRequiredError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    return {
      filters: {
        id: {
          any: Object.keys(this.props.selectedOperationalMaintenanceIds).toString(),
        },
      },
    };
  };

  buildDataParams = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Status: {
        if (this.state.status === OperationalMaintenanceStatus.Completed) {
          return {
            update: {
              status: this.state.status,
              completed_date: this.state.completed_date,
              completed_by_user_id: this.state.completed_by_user_id,
              completed_comment: this.state.completed_comment,
            },
          };
        }
        if (this.state.status === OperationalMaintenanceStatus.Skipped) {
          return {
            update: {
              status: this.state.status,
              skipped_by_user_id: this.props.currentUser.id,
            },
          };
        }
        return {
          update: {},
        };
      }

      default:
        return {
          update: {},
        };
    }
  };

  hasError = () => {
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status === OperationalMaintenanceStatus.Completed && !this.state.completed_date) {
        this.setState({ showCompletedDateRequiredError: true });
        return true;
      }
    }
    return false;
  };

  batchUpdate = () => {
    if (this.hasError()) {
      return;
    }
    this.setState({ isSaving: true });
    API.batchUpdateOperationalMaintenances(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildDataParams(),
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false });
        }
      });
  };

  selectFieldToUpdate = params => {
    this.setState({
      ...this.getInitialState(),
      ...params,
    });
  };

  renderSelectedField = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Status:
        return <FormattedMessage id="resources.operational-maintenance.status" />;
      default:
        return null;
    }
  };

  renderAdditionalFieldsForEditingField = () => {
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status === OperationalMaintenanceStatus.Completed) {
        return (
          <>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-at" />}>
                  <Field.Date
                    error={this.state.showCompletedDateRequiredError}
                    value={this.state.completed_date}
                    onChangeDate={completed_date => {
                      this.setState({ completed_date, showCompletedDateRequiredError: false });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-by" />}>
                  <ChooseUserInlineModal
                    members
                    trigger={
                      <Field.Resource
                        clearable={false}
                        value={<CompletedByUserContainer id={this.state.completed_by_user_id} />}
                        onChange={completed_by_user_id => this.setState({ completed_by_user_id })}
                        onClear={() => this.setState({ completed_by_user_id: null })}
                      />
                    }
                    onOpen={() => this.setState({ userInlineModalIsOpen: true })}
                    onClose={() => this.setState({ userInlineModalIsOpen: false })}
                    selectedUserId={this.state.completed_by_user_id}
                    onSelectUser={completed_by_user_id => this.setState({ completed_by_user_id })}
                    clearable={false}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.completed-comment" />}>
                  <Field.Textarea
                    value={this.state.completed_comment}
                    onChange={completed_comment => this.setState({ completed_comment })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
          </>
        );
      }
    }

    return null;
  };

  renderSelectedStatus = () => {
    switch (this.state.status) {
      case OperationalMaintenanceStatus.Completed: {
        return <FormattedMessage id="resources.operational-maintenance.statuses.completed" />;
      }
      case OperationalMaintenanceStatus.Skipped: {
        return <FormattedMessage id="resources.operational-maintenance.statuses.skipped" />;
      }
      default:
        return null;
    }
  };

  renderEditStatus = () => {
    return (
      <>
        <Field view={false} label={<FormattedMessage id="resources.operational-maintenance.status" />}>
          <Field.Dropdown clearable={false} value={this.renderSelectedStatus()}>
            <Field.Dropdown.Item
              selected={this.state.status === OperationalMaintenanceStatus.Completed}
              onClick={() => this.setState({ status: OperationalMaintenanceStatus.Completed })}
            >
              <FormattedMessage id="resources.operational-maintenance.statuses.completed" />
            </Field.Dropdown.Item>
            <Field.Dropdown.Item
              selected={this.state.status === OperationalMaintenanceStatus.Skipped}
              onClick={() => this.setState({ status: OperationalMaintenanceStatus.Skipped })}
            >
              <FormattedMessage id="resources.operational-maintenance.statuses.skipped" />
            </Field.Dropdown.Item>
          </Field.Dropdown>
        </Field>
      </>
    );
  };

  renderEditableField = () => {
    let content = null;
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.Status:
        content = this.renderEditStatus();
        break;
      default:
        return null;
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>{content}</Grid.Column>
        </Grid.Row>
        {this.renderAdditionalFieldsForEditingField()}
      </>
    );
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.mass-edit-modal.select-field-label" />}
            >
              <Field.Dropdown clearable={false} value={this.renderSelectedField()}>
                <Field.Dropdown.Item
                  selected={this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status}
                  onClick={() =>
                    this.selectFieldToUpdate({ showEditForField: BATCH_UPDATABLE_FIELDS.Status })
                  }
                >
                  <FormattedMessage id="resources.operational-maintenance.status" />
                </Field.Dropdown.Item>
              </Field.Dropdown>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.renderEditableField()}
        {this.renderConfirmCheckbox()}
      </Grid>
    );
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.selectedOperationalMaintenancesCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-operational-maintenances.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.two"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.few"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.many"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.other"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
      />
    );
  };

  renderConfirmCheckbox = () => {
    if (this.state.showEditForField == null) {
      return null;
    }
    if (this.state.showEditForField === BATCH_UPDATABLE_FIELDS.Status) {
      if (this.state.status == null) {
        return null;
      }
    }

    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.confirmMassEdit}
            label={
              <FormattedMessage
                id="components.mass-edit-modal.i-am-sure"
                values={{
                  count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                }}
              />
            }
            onChange={() => {
              this.setState(prevState => ({
                confirmMassEdit: !prevState.confirmMassEdit,
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderModalContent = () => {
    return (
      <>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.confirmMassEdit === false}
              loading={this.state.isSaving}
              onClick={this.batchUpdate}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.mass-edit-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    settings: AuthSelectors.getSettings(state),
    selectedOperationalMaintenanceIds:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenanceIds(state),
    selectedOperationalMaintenancesCount:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenancesCount(state),
  };
}

export default connect(mapStateToProps)(MassEditModal);
