import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, List } from 'views/components/Shared/General';
import { getVendorSubtitle } from 'sdk/Vendor';
import { EntitySelectors } from 'sdk/State/entities';

class VendorListItem extends PureComponent {
  render() {
    return (
      <List.Item small light clickable onClick={() => this.props.onToggle(this.props.id)}>
        <List.Item.Column width={20}>
          <Field.Checkbox checked={this.props.selected} onChange={() => this.props.onToggle(this.props.id)} />
        </List.Item.Column>
        <List.Item.TitleColumn
          title={this.props.vendor.name}
          subtitle={getVendorSubtitle(this.props.vendor)}
        />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorListItem);
