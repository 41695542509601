import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, InlineModal, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';
import WithdrawalListItem from './WithdrawalListItem';

const ModalDropdownContent = {
  ShowOptions: 'showOptions',
  SelectWithdrawal: 'selectWithdrawal',
  EditWithdrawal: 'editWithdrawal',
  ShowDelete: 'showDelete',
};

class MoreOptionsInlineModal extends Component {
  state = {
    showOptionsInlineModal: false,
    dropdownOption: ModalDropdownContent.ShowOptions,
    editingWithdrawal: null,
    usedQuantity: '',
    isSaving: false,
  };

  shouldComponentUpdate(prevProps, prevState) {
    if (!prevState.showOptionsInlineModal && !this.state.showOptionsInlineModal) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  saveWithdrawal = () => {
    if (this.state.editingWithdrawal.id) {
      this.updateCurrentWithdrawal();
    } else {
      this.createSparePartWithdrawal();
    }
  };

  createSparePartWithdrawal = () => {
    this.setState({ isSaving: true });
    this.props
      .createSparePartWithdrawalForWorkOrder(this.props.match.params.id, {
        spare_part_id: this.props.sparePart.id,
        quantity: this.state.editingWithdrawal.quantity,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.add-withdrawal-success" />
            }
          />
        );
        this.setState({ isSaving: false, showOptionsInlineModal: false });
      });
  };

  updateCurrentWithdrawal = () => {
    this.setState({ isSaving: true });
    this.props
      .updateSparePartWithdrawal(this.state.editingWithdrawal.id, {
        quantity: this.state.editingWithdrawal.quantity,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSaving: false, showOptionsInlineModal: false });
      });
  };

  batchDeleteSparePartWithdrawals = () => {
    this.setState({ isDeleting: true });
    this.props
      .batchDeleteSparePartWithdrawal(this.props.sparePartWithdrawals.map(spw => spw.id))
      .then(data => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.delete-work-order-spare-part-success" />
            }
          />
        );
        if (this._ismounted) {
          this.setState({ isDeleting: false, showOptionsInlineModal: false });
        }
      });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.editingWithdrawal.quantity) {
      this.saveWithdrawal();
    }
  };

  renderOptions = () => {
    return (
      <InlineModal.Body dropdown width={300}>
        {this.props.sparePart === null ? (
          <InlineModal.ListItem
            icon="plus"
            iconThickness="regular"
            title={
              <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.add-to-system" />
            }
            onClick={() => {
              this.setState({ showOptionsInlineModal: false });
              this.props.onCreateSparePart(this.props.sparePartWithdrawals[0]);
            }}
          />
        ) : (
          <Link to={`/spare-parts/${this.props.sparePart.id}`}>
            <InlineModal.ListItem
              icon="arrow-alt-right"
              iconThickness="regular"
              title={
                <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.go-to-spare-part" />
              }
            />
          </Link>
        )}
        {this.props.canCarryOutWorkOrders ? (
          <>
            <InlineModal.Separator />
            {this.props.sparePart ? (
              <InlineModal.ListItem
                icon="plus"
                iconThickness="regular"
                title={
                  <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.new-withdrawal" />
                }
                onClick={() => {
                  this.setState({
                    dropdownOption: ModalDropdownContent.EditWithdrawal,
                    editingWithdrawal: { quantity: '' },
                  });
                }}
              />
            ) : null}
            {this.props.sparePartWithdrawals.length === 0 ? null : (
              <InlineModal.ListItem
                icon="pen"
                iconThickness="regular"
                title={
                  <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.edit-withdrawal" />
                }
                onClick={() => {
                  if (this.props.sparePartWithdrawals.length === 1) {
                    this.setState({
                      dropdownOption: ModalDropdownContent.EditWithdrawal,
                      editingWithdrawal: this.props.sparePartWithdrawals[0],
                    });
                  } else {
                    this.setState({ dropdownOption: ModalDropdownContent.SelectWithdrawal });
                  }
                }}
              />
            )}
            {this.props.sparePartReservation != null &&
            this.props.sparePartWithdrawals.length === 0 ? null : (
              <InlineModal.ListItem
                icon="trash-alt"
                iconThickness="regular"
                destructive
                title={
                  <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.remove-spare-part" />
                }
                onClick={() => {
                  this.setState({ dropdownOption: ModalDropdownContent.ShowDelete });
                }}
              />
            )}
          </>
        ) : null}
      </InlineModal.Body>
    );
  };

  renderEditWithdrawal = () => {
    return (
      <InlineModal.Body width={390} padding="30px 40px">
        <Field
          view={false}
          label={
            <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.used-quantity" />
          }
        >
          <Field.Text
            onKeyPress={this.handleKeyPress}
            rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
            autoFocus
            value={this.state.editingWithdrawal.quantity}
            onChange={quantity => {
              this.setState({ editingWithdrawal: { ...this.state.editingWithdrawal, quantity } });
            }}
          />
        </Field>
        {this.state.editingWithdrawal.id == null ? null : (
          <p className={styles['current-withdrawal']}>
            <FormattedMessage
              id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.editing-current-withdrawal-subtitle"
              values={{ date: moment(this.state.editingWithdrawal.created_at).format('LLL') }}
            />
          </p>
        )}
        <div className={styles['group']}>
          <Button.Group>
            <Button
              primary
              small
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.saveWithdrawal}
              disabled={!this.state.editingWithdrawal.quantity}
            />
            <Button
              small
              label="general.cancel"
              onClick={() => this.setState({ showOptionsInlineModal: false, showWithdrawal: false })}
            />
          </Button.Group>
        </div>
      </InlineModal.Body>
    );
  };

  renderDeleteContent = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="general.delete-confirm.title" />}
          onClose={() => {
            this.setState({ showOptionsInlineModal: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.batchDeleteSparePartWithdrawals}>
            <p>
              <FormattedMessage id="general.delete-confirm.subtitle" />
            </p>
            {this.props.sparePartReservation == null ? null : (
              <p className={styles['reservation-text']}>
                <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.reservation-will-not-be-deleted" />
              </p>
            )}
          </InlineModal.Delete>
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderSelectWithdrawal = () => (
    <React.Fragment>
      <InlineModal.Header
        width={390}
        title={
          <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.select-withdrawal-title" />
        }
        onClose={() => {
          this.setState({ showOptionsInlineModal: false });
        }}
      />
      <InlineModal.Body width={390}>
        <div>
          {this.props.sparePartWithdrawals.map(sparePartWithdrawal => (
            <WithdrawalListItem
              sparePartUnit={this.props.sparePartUnit}
              sparePartWithdrawal={sparePartWithdrawal}
              onClick={() =>
                this.setState({
                  dropdownOption: ModalDropdownContent.EditWithdrawal,
                  editingWithdrawal: sparePartWithdrawal,
                })
              }
            />
          ))}
        </div>
      </InlineModal.Body>
    </React.Fragment>
  );

  renderInlineModalBodyContent = () => {
    switch (this.state.dropdownOption) {
      case ModalDropdownContent.ShowOptions:
        return this.renderOptions();
      case ModalDropdownContent.EditWithdrawal:
        return this.renderEditWithdrawal();
      case ModalDropdownContent.ShowDelete:
        return this.renderDeleteContent();
      case ModalDropdownContent.SelectWithdrawal:
        return this.renderSelectWithdrawal();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showOptionsInlineModal: !prevState.showOptionsInlineModal,
              dropdownOption: ModalDropdownContent.ShowOptions,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showOptionsInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showOptionsInlineModal: false })}
          position="right"
        >
          <React.Fragment>{this.renderInlineModalBodyContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartWithdrawal: SDKReduxOperations.updateSparePartWithdrawal,
      createSparePartWithdrawalForWorkOrder: SDKReduxOperations.createSparePartWithdrawalForWorkOrder,
      batchDeleteSparePartWithdrawal: SDKReduxOperations.batchDeleteSparePartWithdrawal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { spare_part_id, spare_part_withdrawal_ids, spare_part_reservation_id } = ownProps.workOrderSparePart;

  let sparePart = EntitySelectors.getSparePart(state, spare_part_id);
  let sparePartUnit = null;
  let images = [];
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
    images = EntitySelectors.getImages(state, sparePart.images);
  } else {
    const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, spare_part_withdrawal_ids[0]);
    sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }

  return {
    sparePart,
    sparePartUnit,
    images,
    sparePartReservation: EntitySelectors.getSparePartReservation(state, spare_part_reservation_id),
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawal_ids) || [],
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MoreOptionsInlineModal)));
