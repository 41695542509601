import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { EmptyDataSet, Field, List, Pagination, Banner } from 'views/components/Shared/General';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeSparePartReservation, normalizeRecurringMaintenance } from 'sdk/Schemas';
import WorkOrderTiny from 'assets/images/EmptyDataSet/WorkOrderTiny.png';
import SparePartReservationListItem from './SparePartReservationListItem';
import styles from './styles.module.scss';

const PAGE_SIZE = 5;

class ReservedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      days: 30,
      allData: [],
      filteredData: [],
      page: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.fetchInitialData();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  fetchInitialData = () => {
    this.setState({ loading: true });
    Promise.all([
      this.listWorkOrderReservations(),
      this.listRecurringMaintenanceReservations(),
      this.listRecurringMaintenances(),
    ]).then(([work_order_reservations, recurring_maintenance_reservations, recurring_maintenances]) => {
      const wo_data = work_order_reservations.map(reservation => ({
        date: reservation.work_order.due_date,
        id: reservation.id,
      }));
      const recurring_maintenance_data = recurring_maintenance_reservations.reduce((acc, reservation) => {
        const recurring_maintenance = recurring_maintenances.find(
          rm => rm.id === reservation.recurring_maintenance_id
        );

        const data = recurring_maintenance.future_work_order_dates.map(date => ({
          date,
          id: reservation.id,
        }));

        return [...acc, ...data];
      }, []);

      const data = [...wo_data, ...recurring_maintenance_data].sort((a, b) =>
        moment(a.date).isAfter(b.date, 'day') ? 1 : -1
      );

      this.setState({ allData: data }, () => {
        this.calculateDataForDays();
      });
    });
  };

  listWorkOrderReservations = () => {
    const hundred_and_eighty_days = moment().add(180, 'days');

    const params = {
      active_work_orders_until: hundred_and_eighty_days.format('YYYY-MM-DD'),
      not_fully_withdrawn: true,
      no_pagination: true,
    };

    return API.listSparePartReservationsForSparePart(this.props.sparePart.id, params).then(
      ({ data: sparePartReservations, headers }) => {
        const { entities } = normalizeSparePartReservation(sparePartReservations);
        this.props.updateEntities(entities);

        return sparePartReservations;
      }
    );
  };

  listRecurringMaintenanceReservations = () => {
    const params = {
      recurring_maintenance: {
        [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: true,
      },
      no_pagination: true,
    };

    return API.listSparePartReservationsForSparePart(this.props.sparePart.id, params).then(
      ({ data: sparePartReservations, headers }) => {
        const { entities } = normalizeSparePartReservation(sparePartReservations);
        this.props.updateEntities(entities);

        return sparePartReservations;
      }
    );
  };

  listRecurringMaintenances = () => {
    const hundred_and_eighty_days = moment().add(180, 'days');

    const params = {
      include_future_work_order_dates: true,
      until_date: hundred_and_eighty_days.format('YYYY-MM-DD'),
      reserved_spare_part_id: this.props.sparePart.id,
      no_pagination: true,
    };

    return API.listRecurringMaintenances(this.props.currentSystem.id, params).then(
      ({ data: recurringMaintenances, headers }) => {
        const { entities } = normalizeRecurringMaintenance(recurringMaintenances);
        this.props.updateEntities(entities);

        return recurringMaintenances;
      }
    );
  };

  calculateDataForDays = () => {
    const untilDate = moment().add(this.state.days, 'days');
    const filteredData = this.state.allData.filter(row => moment(untilDate).isAfter(row.date, 'day'));
    this.setState({ filteredData, loading: false, page: 1 });
  };

  changeDays = days => {
    this.setState({ days, loading: true }, () => {
      setTimeout(() => {
        this.calculateDataForDays();
      }, 450);
    });
  };

  renderDaysDropdown = () => (
    <div className={styles['days-dropdown-container']}>
      <Field.Dropdown
        value={
          <FormattedMessage id="components.reserved-modal.days-value" values={{ days: this.state.days }} />
        }
        clearable={false}
      >
        <Field.Dropdown.Item selected={this.state.days === 30} onClick={() => this.changeDays(30)}>
          <FormattedMessage id="components.reserved-modal.days-value" values={{ days: 30 }} />
        </Field.Dropdown.Item>
        <Field.Dropdown.Item selected={this.state.days === 90} onClick={() => this.changeDays(90)}>
          <FormattedMessage id="components.reserved-modal.days-value" values={{ days: 90 }} />
        </Field.Dropdown.Item>
        <Field.Dropdown.Item selected={this.state.days === 180} onClick={() => this.changeDays(180)}>
          <FormattedMessage id="components.reserved-modal.days-value" values={{ days: 180 }} />
        </Field.Dropdown.Item>
      </Field.Dropdown>
    </div>
  );

  renderPagination = () => {
    if (this.state.filteredData.length <= PAGE_SIZE) return null;

    return (
      <div className={styles['pagination-container']}>
        <Pagination
          hideOptions
          currentPage={this.state.page}
          totalPages={Math.ceil(this.state.filteredData.length / PAGE_SIZE)}
          onSelectPage={page => {
            this.setState({ page });
          }}
        />
      </div>
    );
  };

  renderList = () => {
    if (this.state.loading) {
      return (
        <List light>
          <SparePartReservationListItem loading />
          <SparePartReservationListItem loading />
        </List>
      );
    }
    if (this.state.filteredData.length === 0) {
      return (
        <div className={styles['empty-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage
                id="components.reserved-modal.empty-dataset.title"
                values={{ days: this.state.days }}
              />
            }
            image={WorkOrderTiny}
            tiny
          />
        </div>
      );
    }

    const itemsToDisplay = this.state.filteredData.slice(
      (this.state.page - 1) * PAGE_SIZE,
      this.state.page * PAGE_SIZE
    );
    return (
      <React.Fragment>
        <List light>
          {itemsToDisplay.map(row => (
            <SparePartReservationListItem id={row.id} date={row.date} />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={660}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.reserved-modal.title" />}
          subtitle={<FormattedMessage id="components.reserved-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div className={styles['banner-container']}>
            <Banner orange>
              <FormattedMessage id="components.reserved-modal.banner-info" />
            </Banner>
          </div>
          {this.renderDaysDropdown()}
          {this.renderList()}
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePart.spare_part_unit_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReservedModal));
