import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import { SparePartUnitWithValue, SparePartListItemExpandable } from 'views/components/SparePart';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          clickable
          small
          onClick={this.props.onClick}
          expandable
          expandedComponent={<SparePartListItemExpandable id={this.props.sparePart.id} />}
          linkTo={`/spare-parts/${this.props.sparePart.id}`}
          new={this.props.new}
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkboxDisabled={this.props.checkboxDisabled}
          checkbox
        >
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.Column width={70}>
            <span className={styles['art-no']}>#{this.props.sparePart.article_number}</span>
          </List.Item.Column>

          <List.Item.TitleColumn title={this.props.sparePart.title} />
          <List.Item.Column width={200}>
            {this.props.sparePartType == null ? null : (
              <div className={styles['type']}>{this.props.sparePartType.title}</div>
            )}
          </List.Item.Column>

          {this.props.hasProTier ? (
            <List.Item.Column width={150}>
              {this.props.sparePart.stock_quantity == null ? null : (
                <SparePartUnitWithValue
                  sparePartUnit={this.props.sparePartUnit}
                  value={this.props.sparePart.stock_quantity}
                />
              )}
            </List.Item.Column>
          ) : null}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    images: EntitySelectors.getImages(state, ownProps.sparePart.images),
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.sparePart.spare_part_type_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePart.spare_part_unit_id),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartListItem));
