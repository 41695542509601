const DOMAIN_PREFIX = 'operationalMaintenanceTemplateEdit/';

const SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION = `${DOMAIN_PREFIX}SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION`;
const SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_VALUES = `${DOMAIN_PREFIX}SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_VALUES`;
const PREPARE_NEW_MAINTENANCE_TEMPLATE_VERSION = `${DOMAIN_PREFIX}PREPARE_NEW_MAINTENANCE_TEMPLATE_VERSION`;
const INCREMENT_IMAGE_LOADER_COUNT = `${DOMAIN_PREFIX}INCREMENT_IMAGE_LOADER_COUNT`;
const DECREMENT_IMAGE_LOADER_COUNT = `${DOMAIN_PREFIX}DECREMENT_IMAGE_LOADER_COUNT`;
const SET_ERRORS = `${DOMAIN_PREFIX}SET_ERRORS`;
const REMOVE_ERORRS = `${DOMAIN_PREFIX}REMOVE_ERORRS`;

export default {
  SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
  SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_VALUES,
  PREPARE_NEW_MAINTENANCE_TEMPLATE_VERSION,
  INCREMENT_IMAGE_LOADER_COUNT,
  DECREMENT_IMAGE_LOADER_COUNT,
  SET_ERRORS,
  REMOVE_ERORRS,
};
