import Title from './Title';
import Description from './Description';
import Status from './Status';
import RequestType from './RequestType';
import Priority from './Priority';
import CreatedBy from './CreatedBy';
import CreatedAt from './CreatedAt';
import ArchivedBy from './ArchivedBy';
import ApprovedByProductionSupervisor from './ApprovedByProductionSupervisor';
import AssetType from './AssetType';
import Asset from './Asset';
import AssetWithTreeChildren from './AssetWithTreeChildren';
import ArchivedComment from './ArchivedComment';
import WorkOrderCompletedDate from './WorkOrderCompletedDate';

export default {
  Title,
  Description,
  Status,
  RequestType,
  Priority,
  CreatedBy,
  CreatedAt,
  ArchivedBy,
  ApprovedByProductionSupervisor,
  AssetType,
  Asset,
  AssetWithTreeChildren,
  ArchivedComment,
  WorkOrderCompletedDate,
};
