import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { PurchaseOrderSelectors } from 'state/ducks/purchaseOrder';
import { PurchaseOrdersSelectors } from 'state/ducks/purchaseOrders';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { HelperFunctions } from 'sdk';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import Menu from './Menu';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/purchase-orders',
      search: HelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.purchase-orders.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>
          <FormattedMessage
            id="screens.purchase-order.title"
            values={{ number: this.props.purchaseOrder.number }}
          />
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTabs = () => {
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/purchase-orders/${id}`}
          active={
            matchPath(this.props.location.pathname, { path: '/purchase-orders/:id/', exact: true }) != null
          }
        >
          <FormattedMessage id="screens.purchase-order.tabs.information" />
        </LayoutHeader.TabBarItem>
        <LayoutHeader.TabBarItem
          to={`/purchase-orders/${id}/deliveries?list=articles`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/purchase-orders/:id/deliveries/',
            }) != null
          }
        >
          <FormattedMessage id="screens.purchase-order.tabs.deliveries" />
        </LayoutHeader.TabBarItem>
        {this.props.hasProTier || this.props.isAdmin ? (
          <LayoutHeader.TabBarItem
            locked={this.props.hasProTier === false}
            to={`/purchase-orders/${id}/work-orders`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/purchase-orders/:id/work-orders/',
              }) != null
            }
          >
            <FormattedMessage id="screens.purchase-order.tabs.work-orders" />
          </LayoutHeader.TabBarItem>
        ) : null}
        <LayoutHeader.TabBarItem
          to={`/purchase-orders/${id}/attachments`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/purchase-orders/:id/attachments/',
            }) != null
          }
        >
          <FormattedMessage id="screens.purchase-order.tabs.attachments" />
        </LayoutHeader.TabBarItem>
      </LayoutHeader.TabBar>
    );
  };

  render() {
    return (
      <LayoutHeader
        title={
          <FormattedMessage
            id="screens.purchase-order.title"
            values={{ number: this.props.purchaseOrder.number }}
          />
        }
        minWidth={900}
        titleHoverText={`${this.props.purchaseOrder.number}`}
        breadcrumbComponent={this.renderBreadcrumb()}
        tabBarComponent={this.renderTabs()}
        buttonsComponent={this.props.canEditPurchaseOrders ? <Menu /> : null}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseOrder: PurchaseOrderSelectors.getPurchaseOrder(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    isAdmin: AuthSelectors.isAdmin(state),
    hasProTier: AuthSelectors.hasProTier(state),
    navigatedTo: PurchaseOrdersSelectors.getNavigatedTo(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
