import React from 'react';
import styles from './style.module.scss';

const ButtonGroup = ({ children, type, right }) => {
  const renderButtons = () =>
    React.Children.map(children, child =>
      child ? <div className={styles['button-container']}>{child}</div> : null
    );

  let classNames = [styles['group']];
  if (right) classNames = [...classNames, styles['right']];

  return <div className={classNames.join(' ')}>{renderButtons()}</div>;
};

export default ButtonGroup;
