import types from './types';
import update from 'immutability-helper';
import { mergeWith, uniq } from 'lodash-es';
import { MenuTypes } from 'state/ducks/menu';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  pagination: {
    totalEntries: 0,
    totalPages: 1,
  },
  navigatedTo: {
    search: {},
  },
  isSearching: false,
  selectedPurchaseOrders: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesAreSelected: false,
    ids: {},
  },
  queryParams: {
    page: 1,
    page_size: 25,
  },
  appliedFilters: [],
  filters: {},
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_NAVIGATED_TO: {
      const { search } = action.payload;
      return update(state, {
        navigatedTo: {
          search: {
            $set: search,
          },
        },
      });
    }
    case types.SELECT_FILTER_TYPE: {
      return update(state, {
        showApplyFilterForField: {
          $set: action.payload,
        },
      });
    }
    case types.ADD_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        appliedFilters: {
          $apply: appliedFilters => uniq([...appliedFilters, key]),
        },
        filters: {
          $apply: filters => mergeWith({}, filters, data, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.REMOVE_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        showingAppliedFilters: {
          $set: state.appliedFilters.filter(id => id !== key).length > 0,
        },
        showApplyFilterForField: {
          $set: null,
        },
        appliedFilters: {
          $apply: appliedFilters => appliedFilters.filter(id => id !== key),
        },
        filters: {
          $apply: filters => mergeWith({}, filters, data, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.RESET_FILTER: {
      return {
        ...state,
        isFiltering: false,
        showingAppliedFilters: false,
        appliedFilters: [],
        filters: INITIAL_STATE.filters,
      };
    }
    case types.SHOW_APPLIED_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: true,
        showApplyFilterForField: null,
      };
    }
    case types.SHOW_AVAILABLE_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: false,
      };
    }
    /*
      MultiAction
    */
    case types.SELECT_PAGE: {
      if (state.selectedPurchaseOrders.pageIsSelected) {
        return update(state, {
          selectedPurchaseOrders: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedPurchaseOrders.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedPurchaseOrders: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedPurchaseOrders.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedPurchaseOrders.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedPurchaseOrders.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_PURCHASE_ORDER: {
      const purchaseOrderId = action.payload;
      if (state.selectedPurchaseOrders.ids[purchaseOrderId]) {
        return update(state, {
          selectedPurchaseOrders: {
            ids: {
              $unset: [purchaseOrderId],
            },
            totalEntriesAreSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== purchaseOrderId)
          .every(id => state.selectedPurchaseOrders.ids[id] === true);
        return update(state, {
          selectedPurchaseOrders: {
            ids: {
              [purchaseOrderId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedPurchaseOrders: {
          totalEntriesAreSelected: {
            $set: true,
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedPurchaseOrders: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesAreSelected: {
            $set: false,
          },
        },
      });
    }
    case types.RESET_SELECTED_PURCHASE_ORDERS: {
      return update(state, {
        selectedPurchaseOrders: {
          $set: {
            ...INITIAL_STATE.selectedPurchaseOrders,
          },
        },
      });
    }
    case types.FETCH_PURCHASE_ORDERS_SUCCESS:
      const { ids, pagination } = action.payload;
      let allRowsAreSelected =
        ids.length > 0 && ids.every(id => state.selectedPurchaseOrders.ids[id] === true);
      if (state.selectedPurchaseOrders.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        isSearching: {
          $set: false,
        },
        selectedPurchaseOrders: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    case types.SHOW_LOADER: {
      return { ...state, isSearching: true };
    }

    case SDKReduxTypes.PURCHASE_ORDER_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        ids: [data.id, ...state.ids],
      };
    }

    case SDKReduxTypes.PURCHASE_ORDER_DELETED: {
      const { purchaseOrderId } = action.payload;

      return {
        ...state,
        ids: state.ids.filter(id => id !== purchaseOrderId),
      };
    }

    default:
      return state;
  }
};
