import types from './types';

const resetState = () => ({ type: types.RESET_STATE });
const setEditingWorkOrderValues = data => ({
  type: types.SET_EDITING_WORK_ORDER_VALUES,
  payload: data,
});
const prepareNewWorkOrder = payload => ({
  type: types.PREPARE_NEW_WORK_ORDER,
  payload,
});

export default {
  resetState,
  setEditingWorkOrderValues,
  prepareNewWorkOrder,
};
