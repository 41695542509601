import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { ChooseAssigneeInlineModal } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';

class AssigneeContainer extends Component {
  renderAssignee = () => {
    if (this.props.vendorId) {
      return <Field.Resource value={this.props.vendor.name} angleDown onClear={this.props.onClear} />;
    }
    return (
      <Field.Resource
        value={this.props.user == null ? null : <UserNameWrapper user={this.props.user} />}
        angleDown
        onClear={this.props.onClear}
      />
    );
  };
  render() {
    return (
      <ChooseAssigneeInlineModal
        trigger={this.renderAssignee()}
        hideGroups
        selectedUserId={this.props.userId}
        selectedVendorId={this.props.vendorId}
        onSelectUser={user => this.props.onAddUser(user)}
        onSelectVendor={vendor => this.props.onAddVendor(vendor)}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.userId),
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

export default connect(mapStateToProps)(AssigneeContainer);
