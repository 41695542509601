import React, { Component } from 'react';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../../Header';
import GeneralInformation from './GeneralInformation';
import Images from './Images';
import Meters from './Meters';
import IncludedAssets from './IncludedAssets';
import Archived from './Archived';
import ProductionSupervisor from './ProductionSupervisor';

export default class Information extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>
          <Archived />
          <CardGrid>
            <CardGrid.Column>
              <CardGrid.Row>
                <GeneralInformation />
              </CardGrid.Row>
              <CardGrid.Row>
                <IncludedAssets />
              </CardGrid.Row>
            </CardGrid.Column>
            <CardGrid.Column width={268}>
              <Meters />
              <Images />
              <ProductionSupervisor />
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}
