import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  normalizePurchaseOrder,
  normalizePurchaseOrderDelivery,
  normalizeWorkOrderPurchase,
  normalizeAttachment,
} from 'sdk/Schemas';

const fetchPurchaseOrder = id => dispatch => {
  dispatch(actions.setPurchaseOrderId(id));
  return API.fetchPurchaseOrder(id).then(res => {
    const { entities } = normalizePurchaseOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    return res.data;
  });
};

const fetchPurchaseOrderDeliveries =
  (purchaseOrderId, params = {}) =>
  dispatch => {
    return API.listPurchaseOrderDeliveriesForPurchaseOrder(purchaseOrderId, {
      sort: 'created_at',
      'sort-order': 'desc',
      ...params,
    }).then(res => {
      const { entities, result } = normalizePurchaseOrderDelivery(res.data);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchDeliveriesSuccess({
          ids: result,
        })
      );

      return res.data;
    });
  };

const fetchWorkOrderPurchases =
  (purchaseOrderId, params = {}) =>
  dispatch => {
    return API.listWorkOrderPurchasesForPurchaseOrder(purchaseOrderId).then(res => {
      const { entities, result } = normalizeWorkOrderPurchase(res.data);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchWorkOrderPurchasesSuccess({
          ids: result,
        })
      );

      return res.data;
    });
  };

const fetchAttachments =
  (purchaseOrderId, params = {}) =>
  dispatch => {
    return API.listAttachmentsForPurchaseOrder(purchaseOrderId, { ...params, page_size: 8 }).then(res => {
      const { entities, result } = normalizeAttachment(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchAttachmentsSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

export default {
  fetchPurchaseOrder,
  fetchPurchaseOrderDeliveries,
  fetchWorkOrderPurchases,
  fetchAttachments,
};
