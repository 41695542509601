import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RequestStatus } from 'sdk/Request';
import { AuthSelectors } from 'state/ducks/auth';
import { NewInlineModal, Field } from 'views/components/Shared/General';

class StatusInlineModal extends Component {
  state = {
    showStatusInlineModal: false,
  };

  addStatusAsFilter = status => {
    if (this.props.value.find(val => val === status) == null) {
      this.props.onAddStatus(status);
    } else {
      this.props.onRemoveStatus(status);
    }
  };

  renderApprovedByProductionSupervisorStatus = () => {
    const { request_production_supervisor_approval_activated } = this.props.settings;
    if (request_production_supervisor_approval_activated) {
      return (
        <NewInlineModal.Dropdown.Item
          leftComponent={
            <Field.Checkbox
              checked={this.props.value.includes(RequestStatus.AwaitingApproval)}
              onChange={() => {
                this.addStatusAsFilter(RequestStatus.AwaitingApproval);
              }}
            />
          }
          onClick={() => this.addStatusAsFilter(RequestStatus.AwaitingApproval)}
        >
          <FormattedMessage id="resources.request.statuses.awaiting-production-supervisor-approval" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showStatusInlineModal: !prevState.showStatusInlineModal,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showStatusInlineModal}
          onClose={() => this.setState({ showStatusInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(RequestStatus.Active)}
                    onChange={() => {
                      this.addStatusAsFilter(RequestStatus.Active);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(RequestStatus.Active)}
              >
                <FormattedMessage id="resources.request.statuses.active" />
              </NewInlineModal.Dropdown.Item>
              {this.renderApprovedByProductionSupervisorStatus()}
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(RequestStatus.WorkOrderCreated)}
                    onChange={() => {
                      this.addStatusAsFilter(RequestStatus.WorkOrderCreated);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(RequestStatus.WorkOrderCreated)}
              >
                <FormattedMessage id="resources.request.statuses.work-order-created" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(RequestStatus.Completed)}
                    onChange={() => {
                      this.addStatusAsFilter(RequestStatus.Completed);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(RequestStatus.Completed)}
              >
                <FormattedMessage id="resources.request.statuses.completed" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(RequestStatus.Archived)}
                    onChange={() => {
                      this.addStatusAsFilter(RequestStatus.Archived);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(RequestStatus.Archived)}
              >
                <FormattedMessage id="resources.request.statuses.archived" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(StatusInlineModal);
