import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Button, Tooltip } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class PreviewWorkOrderHeader extends Component {
  render() {
    let title;
    if (this.props.workOrderId) {
      title = this.props.workOrder.title;
    } else {
      title = this.props.recurringMaintenance.title;
    }
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']} title={title}>
          <div className={styles['title']}>{title}</div>
          <div className={styles['buttons-container']}>
            {this.props.recurringMaintenanceId ? null : (
              <Tooltip
                trigger={
                  <Link
                    onClick={() => this.props.onClickBeforeNavigate(this.props.workOrder.id)}
                    to={{
                      pathname: `/work-orders/${this.props.workOrder.id}`,
                    }}
                  >
                    <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
                  </Link>
                }
                label={<FormattedMessage id="general.navigate-to.work-order" />}
              />
            )}
            <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
          </div>
        </div>
        {this.props.asset == null ? null : <TreePath size={13} fullPath assetId={this.props.asset.id} />}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.workOrderId) {
    const workOrder = EntitySelectors.getWorkOrder(state, ownProps.workOrderId);
    return {
      workOrder,
      asset: EntitySelectors.getAsset(state, workOrder.asset_id),
    };
  } else {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(
      state,
      ownProps.recurringMaintenanceId
    );
    return {
      recurringMaintenance,
      asset: EntitySelectors.getAsset(state, recurringMaintenance.asset_id),
    };
  }
}

export default connect(mapStateToProps)(PreviewWorkOrderHeader);
