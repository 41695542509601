import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';

class RedirectAuthedUserToPrivateApp extends Component {
  render() {
    if (this.props.userIsAuthenticated) {
      const { canViewWorkOrders, isOperator } = this.props;
      if (isOperator) {
        return (
          <Redirect
            to={{
              pathname: `/production-board`,
            }}
          />
        );
      }
      if (canViewWorkOrders) {
        return (
          <Redirect
            to={{
              pathname: '/work-orders',
              search: '?list=upcoming',
            }}
          />
        );
      }
    }
    return (
      <Redirect
        to={{
          pathname: '/requests',
          search: '?list=my_created',
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const userIsAuthenticated = AuthSelectors.isUserAuthenticated(state);
  if (userIsAuthenticated) {
    return {
      userIsAuthenticated,
      isOperator: AuthSelectors.isOperator(state),
      canViewOperationalMaintenances: AuthSelectors.canViewOperationalMaintenances(state),
      canViewWorkOrders: AuthSelectors.canViewWorkOrders(state),
      settings: AuthSelectors.getSettings(state),
    };
  }
  return {};
}

export default connect(mapStateToProps)(RedirectAuthedUserToPrivateApp);
