import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { bindActionCreators } from 'redux';
import { DragSource } from 'react-dnd';
import { ChecklistEditModalOperations } from 'state/ducks/checklistEditModal';
import styles from './style.module.scss';

const cardSource = {
  beginDrag(props) {
    props.prepareNewField(props.type);
    return {
      type: props.type,
      id: 'new',
    };
  },
};

function cardCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class Field extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    connectDragPreview(getEmptyImage());
  }

  renderContent = () => {
    return this.props.connectDragSource(
      <div className={styles['field']}>
        <div className={styles['title']}>
          <FormattedMessage id={`components.edit-checklist-modal.editor.types.${this.props.type}.title`} />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id={`components.edit-checklist-modal.editor.types.${this.props.type}.subtitle`} />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderContent();
    }
    return this.props.connectDragPreview(<div>{this.renderContent()}</div>);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prepareNewField: ChecklistEditModalOperations.prepareNewField,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DragSource('checklist', cardSource, cardCollect)(Field));
