import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeOperationalMaintenanceInstance } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createOperationalMaintenanceInstance(systemId, params) {
  return dispatch => {
    return API.createOperationalMaintenanceInstance(systemId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceInstance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_INSTANCE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateOperationalMaintenanceInstance(operationalMaintenanceInstanceId, params) {
  return dispatch => {
    return API.updateOperationalMaintenanceInstance(operationalMaintenanceInstanceId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceInstance(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { operationalMaintenanceInstanceId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_INSTANCE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
