import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SelectVendorModal, NewVendorModal } from 'views/components/Vendor';
import { FormattedMessage } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, Pagination, WhiteCard } from 'views/components/Shared/General';
import AssetVendorModal from './AssetVendorModal';
import { Loader } from 'views/components/Shared/General';
import VendorSmall from 'assets/images/EmptyDataSet/VendorSmall.png';
import AssetVendorListItem from './AssetVendorListItem';
import styles from './style.module.scss';
import DeleteAssetVendorModal from 'views/components/AssetVendor/DeleteAssetVendorModal';

class AssetVendors extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      showAssetVendorModal: false,
      showAddAssetVendorModal: false,
      showCreateVendorModal: false,
      tableLoading: false,
      vendorId: null,
      viewInitialized: false,
      queryParams: queryParams,
      selectedAssetVendorId: null,
      showDeleteAssetVendorModalForId: null,
      showDeleteAssetVendorModal: false,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchAssetVendors()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchAssetVendors()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchAssetVendors = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    return this.props
      .fetchAssetVendors(this.props.currentSystem.id, this.props.match.params.id, {
        sort: 'vendor_name',
        'sort-order': 'asc',
        ...params,
        ...filterParams,
      })
      .then(({ data: asset_vendors }) => {
        return asset_vendors;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  newAssetVendor = () => {
    this.setState({ showAssetVendorModal: true });
  };

  renderAssetVendorModal = () => (
    <>
      <AssetVendorModal
        open={this.state.showAddAssetVendorModal}
        vendorId={this.state.vendorId}
        assetId={this.props.match.params.id}
        selectedAssetVendorId={this.state.selectedAssetVendorId}
        onDelete={assetVendorId =>
          setTimeout(
            () =>
              this.setState({
                showDeleteAssetVendorModal: true,
                showDeleteAssetVendorModalForId: assetVendorId,
              }),
            250
          )
        }
        onClose={status => {
          if (status === 'edit') {
            this.setState({
              showAddAssetVendorModal: false,
            });
            setTimeout(() => this.setState({ selectedAssetVendorId: null }), 100);
            return;
          } else
            this.setState({
              showAddAssetVendorModal: false,
            });
          setTimeout(() => {
            this.setState({ showAssetVendorModal: true, selectedAssetVendorId: null });
          }, 100);
          return;
        }}
      />
      <DeleteAssetVendorModal
        open={this.state.showDeleteAssetVendorModal}
        id={this.state.showDeleteAssetVendorModalForId}
        title={<FormattedMessage id="screens.asset.vendors.asset-vendor-modal.confirm-delete.title" />}
        subtitle={<FormattedMessage id="screens.asset.vendors.asset-vendor-modal.confirm-delete.subtitle" />}
        onClose={() =>
          this.setState({ showDeleteAssetVendorModal: false, showDeleteAssetVendorModalForId: null })
        }
      />
    </>
  );

  renderSelectVendorModal = () => (
    <SelectVendorModal
      open={this.state.showAssetVendorModal}
      listItemRightComponent={vendor => {
        if (vendor.exists_for_asset) {
          return this.renderAlreadyAdded();
        } else return this.renderAddVendorButton(vendor.id);
      }}
      additionalApiParams={{ exists_for_asset: this.props.match.params.id }}
      onClose={() => this.setState({ showAssetVendorModal: false })}
      subtitle={
        <FormattedMessage
          id="screens.asset.vendors.asset-vendor-modal.asset-name"
          values={{ asset: <span className={styles['highlighted-text']}>{this.props.asset.title}</span> }}
        />
      }
      onCreateVendor={() => {
        this.setState({ showAssetVendorModal: false });
        setTimeout(() => {
          this.setState({ showCreateVendorModal: true });
        }, 100);
      }}
    />
  );

  renderCreateVendorModal = () => {
    return (
      <NewVendorModal
        createForAssetId={this.props.match.params.id}
        open={this.state.showCreateVendorModal}
        onClose={() => {
          this.setState({ showCreateVendorModal: false });
          setTimeout(() => {
            this.setState({ showAssetVendorModal: true });
          }, 100);
        }}
        onCreated={vendor => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({ showAssetVendorModal: true });
            }, 350);
          });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({ showCreateVendorModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-vendor']}>
        <FormattedMessage id="general.added" />
      </div>
    );
  };

  renderAddVendorButton = vendorId => {
    return (
      <Button
        gray
        small
        label="general.add"
        onClick={() => {
          this.setState({ showAssetVendorModal: false });
          setTimeout(() => {
            this.setState({ showAddAssetVendorModal: true, vendorId, selectedAssetVendorId: null });
          }, 100);
        }}
      />
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.asset.vendors.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.asset.vendors.empty-data-set.subtitle" />}
        image={VendorSmall}
        button={
          this.props.canEditVendors && this.props.canEditAssets ? (
            <Button
              small
              primary
              onClick={this.newAssetVendor}
              label="screens.asset.vendors.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditVendors && this.props.canEditAssets) {
      return (
        <Toolbar
          buttons={
            <Button primary label="screens.asset.vendors.create-button" onClick={this.newAssetVendor} />
          }
          search={null}
        />
      );
    }
    return null;
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.asset.vendors.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.assetVendorIds.length === 0 ? 1 : this.props.assetVendorIds.length)
              .fill()
              .map(() => (
                <AssetVendorListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.assetVendorIds.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.assetVendorIds.map(id => (
            <AssetVendorListItem
              assetVendorId={id}
              onClick={() => {
                this.setState({ showAddAssetVendorModal: true, selectedAssetVendorId: id });
              }}
              onDelete={() =>
                this.setState({ showDeleteAssetVendorModalForId: id, showDeleteAssetVendorModal: true })
              }
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderAssetVendors = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.assetVendorIds.length === 0 && !this.state.tableLoading) {
      return this.renderEmptyDataset();
    } else {
      return this.renderAssetVendors();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderAssetVendorModal()}
        {this.renderSelectVendorModal()}
        {this.renderCreateVendorModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAssetVendors: AssetOperations.fetchAssetVendors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    assetVendorIds: AssetSelectors.getAssetVendorIds(state),
    asset: EntitySelectors.getAsset(state, ownProps.match.params.id),
    pagination: AssetSelectors.getAssetVendorsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetVendors));
