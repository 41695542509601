import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { Button, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { ContentContainer } from 'views/components/Shared/Layout';
import StocktakingSmall from 'assets/images/EmptyDataSet/StocktakingSmall.png';

class Deleted extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <ContentContainer>
          <WhiteCard centerContent padding="60px">
            <EmptyDataSet
              title={<FormattedMessage id="screens.stocktaking-detail.deleted.title" />}
              button={
                <Link to="/stocktaking">
                  <Button primary label="screens.stocktaking-detail.deleted.button" />
                </Link>
              }
              image={StocktakingSmall}
              tiny
              horizontal
            />
          </WhiteCard>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}

export default Deleted;
