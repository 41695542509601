import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listSparePartWithdrawals = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/spare_part_withdrawals?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const listSparePartWithdrawalsForSparePart = (sparePartId, params, config = null) =>
  request.get(
    `spare_parts/${sparePartId}/spare_part_withdrawals?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );

export const fetchSparePartWithdrawal = sparePartWithdrawalId =>
  request.get(`spare_part_withdrawals/${sparePartWithdrawalId}`);
export const updateSparePartWithdrawal = (sparePartWithdrawalId, data) =>
  request.patch(`spare_part_withdrawals/${sparePartWithdrawalId}`, data);
export const createSparePartWithdrawal = (systemId, data) =>
  request.post(`systems/${systemId}/spare_part_withdrawals`, data);
export const createSparePartWithdrawalForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/spare_part_withdrawals`, data);
export const createSparePartWithdrawalForOperationalMaintenance = (operationalMaintenanceInstanceId, data) =>
  request.post(
    `operational_maintenance_instances/${operationalMaintenanceInstanceId}/spare_part_withdrawals`,
    data
  );
export const deleteSparePartWithdrawal = sparePartWithdrawalId =>
  request.delete(`spare_part_withdrawals/${sparePartWithdrawalId}`);
