import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { AuthSelectors } from 'state/ducks/auth';
import {
  FullScreenImagePicker,
  Button,
  Field,
  FileUploadWrapper,
  Icon,
  Tooltip,
} from 'views/components/Shared/General';
import { Grid, ListLayout } from 'views/components/Shared/Layout';
import { AddButton, Image, Loader } from 'views/components/Image';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { RequestReceiverType } from 'sdk/Settings';
import { EntitySelectors } from 'sdk/State/entities';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { RequestTypeInlineModal, ChooseAssigneeModal } from 'views/components/Request';
import DowntimeOptionsDrawer from './DowntimeOptionsDrawer';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import styles from './style.module.scss';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      currentImage: 0,
      showingImageDialog: false,
      showDowntimeDrawer: false,
      title: this.props.request.title || '',
      description: this.props.request.description || '',
      deletingImageIds: {},
      uploadingCount: 0,
      errors: {
        titleRequired: false,
        descriptionRequired: false,
        assetRequired: false,
        typeRequired: false,
        priorityRequired: false,
        showAnotherAssetRequiredError: false,
        showFromDateInFutureError: false,
        showFromDateRequiredError: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isNewRequest && this.props.isNewRequest) {
      this.setState({ description: '', title: '' });
      this.titleRef.focus();
    }
  }

  getEditingRequestChanges = () => {
    let data = {};
    const diffProps = Object.keys(this.props.request).filter(
      k => this.props.request[k] !== this.props.requestBeforeEdit[k]
    );
    diffProps.forEach(key => (data[key] = this.props.request[key]));
    return data;
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...queryString.parse(this.props.location.search),
        ...obj,
      })}`
    );
  };

  hasErrors = () => {
    const {
      request_description_required,
      request_asset_required,
      request_priority_required,
      request_type_required,
    } = this.props.currentSystem;
    let errors = {};
    if (!this.state.title) {
      errors = {
        ...errors,
        titleRequired: true,
      };
    }
    if (request_description_required && !this.state.description) {
      errors = {
        ...errors,
        descriptionRequired: true,
      };
    }
    if (request_asset_required && !this.props.request.asset_id) {
      errors = {
        ...errors,
        assetRequired: true,
      };
    }
    if (request_priority_required && !this.props.request.priority) {
      errors = {
        ...errors,
        priorityRequired: true,
      };
    }
    if (request_type_required && !this.props.request.request_type_id) {
      errors = {
        ...errors,
        typeRequired: true,
      };
    }
    if (this.props.request.downtime && this.props.isNewRequest) {
      if (this.props.request.downtime.asset_id == null) {
        errors = {
          ...errors,
          showAnotherAssetRequiredError: true,
        };
      }
      if (this.props.request.downtime.from == null) {
        errors = {
          ...errors,
          showFromDateRequiredError: true,
        };
      } else if (moment(this.props.request.downtime.from).isAfter(moment())) {
        errors = {
          ...errors,
          showFromDateInFutureError: true,
        };
      }
    }
    this.setState({ errors });
    return Object.values(errors).some(item => item === true);
  };

  saveRequest = () => {
    if (this.hasErrors()) return;
    if (this.state.isSaving) return;
    if (this.props.isNewRequest) {
      this.createRequest();
    } else {
      this.updateRequest();
    }
  };

  cancelEdit = () => {
    if (this.props.isNewRequest) {
      this.props.cancelNewRequest(this.props.request);
    } else {
      this.props.setRequestForView(this.props.request.id);
    }
  };

  createRequest = () => {
    const { request_multiple_assignees_active, request_multiple_assignees_type } = this.props.settings;
    if (
      this.props.hasProTier &&
      request_multiple_assignees_active &&
      request_multiple_assignees_type === RequestReceiverType.SelectedByCreator
    ) {
      this.setState({ showChooseAssigneeModal: true });
      return;
    }
    this.setState({
      isSaving: true,
    });
    this.props.resetSelectedRequests();

    let request = { ...this.props.request };
    if (request.downtime) {
      request = {
        ...request,
        downtime: {
          ...request.downtime,
          from: moment(request.downtime.from, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm'),
        },
      };
    }

    this.props
      .createRequest(this.props.currentSystem.id, request)
      .then(({ data: request }) => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.create-success" />} />);
        this.changeQueryParams({ request_id: request.id });
      })
      .catch(e => {
        if (e?.response?.data?.errors?.downtime?.asset_id[0]?.code === '30009') {
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="screens.request.errors.asset-already-has-active-downtime" />}
            />
          );
        }
        this.setState({ isSaving: false });
      });
  };

  updateRequest = () => {
    this.setState({
      isSaving: true,
    });
    this.props
      .updateRequest(this.props.request.id, this.getEditingRequestChanges())
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.update-success" />} />);
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  addImageToRequest = ({ file, mime_type, name }) => {
    this.props
      .createImageForRequest(this.props.request.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.add-image-success" />} />);
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  addImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    if (this.props.isNewRequest) {
      this.addDraftImage({ file, mime_type, name });
    } else {
      this.addImageToRequest({ file, mime_type, name });
    }
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        if (!this.props.isNewRequest) {
          toast(
            <ToastMessage success text={<FormattedMessage id="screens.request.delete-image-success" />} />
          );
        }
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderNewImage = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader medium circle />
    );
    return (
      <React.Fragment>
        {loaderElements}
        <AddButton medium onAddImage={this.addImage} />
      </React.Fragment>
    );
  };

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader medium circle />
    );
    return (
      <>
        {this.props.images.map(image => (
          <Image
            key={image.id}
            medium
            circle
            isDeleting={this.state.deletingImageIds[image.id] != null}
            image={image}
            editable
            onClick={() => {
              this.selectImage(0);
            }}
            onDelete={() => {
              this.deleteImage(image);
            }}
          />
        ))}
        {loaderElements}
      </>
    );
  };

  renderImageContainer = () => {
    return (
      <div className={styles['images']}>
        {this.renderImages()}
        {this.renderNewImage()}
      </div>
    );
  };

  renderSelectedPriority = () => {
    switch (this.props.request.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderCreateDowntimeCheckbox = () => {
    const { request, settings, canEditDowntimes, isNewRequest } = this.props;
    if (isNewRequest && request.asset_id && settings.downtime_activated && canEditDowntimes) {
      return (
        <Grid.Row>
          <Grid.Column>
            <div style={{ marginTop: '-8px' }}>
              <Field.Checkbox
                label={<FormattedMessage id="screens.request.has-downtime-checkbox" />}
                checked={this.state.showDowntimeDrawer}
                onChange={value => {
                  this.setState({
                    showDowntimeDrawer: value,
                  });
                }}
              />
              </div>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderRequest = () => {
    return (
      <div className={styles['white-card']}>
        <div className={styles['header']}>
          <p className={styles['title']}>
            {this.props.isNewRequest ? (
              <FormattedMessage id="screens.request.new-title" />
            ) : (
              <FormattedMessage id="screens.request.edit-title" />
            )}
          </p>
          <Button
            type="icon"
            icon={<Icon light size={18} type="times" />}
            onClick={() => {
              if (this.props.hasUnsavedChanges) {
                const answer = window.confirm(
                  this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
                );
                if (answer) {
                  this.cancelEdit();
                }
              } else {
                this.cancelEdit();
              }
            }}
          />
        </div>
        <div className={styles['data-container']}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.request.title" />}>
                  <Field.Text
                    error={this.state.errors.titleRequired}
                    autoFocus
                    value={this.state.title}
                    rightLabel={
                      <div className={styles['max-characters-container']}>
                        <span className={styles['max-characters']}>
                          <FormattedMessage
                            id="general.characters-left"
                            values={{ amount: 35 - this.state.title.length }}
                          />
                        </span>
                        <Tooltip
                          trigger={
                            <div className={styles['title-popup-container']}>
                              <Icon regular type="question-circle" />
                            </div>
                          }
                          label={<FormattedMessage id="general.characters-left-popup" />}
                        />
                      </div>
                    }
                    ref={ref => (this.titleRef = ref)}
                    onChange={value => {
                      if (this.state.errors.titleRequired && value) {
                        this.setState({ errors: { ...this.state.errors, titleRequired: false } });
                      }
                      this.setState({ title: value });
                    }}
                    onBlur={() => this.props.setEditingRequestValues({ title: this.state.title })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.request.description" />}>
                  <Field.Textarea
                    error={this.state.errors.descriptionRequired}
                    minRows={3}
                    value={this.state.description}
                    onChange={value => {
                      if (this.state.errors.descriptionRequired && value) {
                        this.setState({ errors: { ...this.state.errors, descriptionRequired: false } });
                      }
                      this.setState({ description: value });
                    }}
                    onBlur={() => this.props.setEditingRequestValues({ description: this.state.description })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Separator />
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.work-order.asset" />}>
                  <ChooseAssetInlineModal
                    trigger={
                      <Field.Resource
                        angle
                        error={this.state.errors.assetRequired}
                        value={
                          this.props.asset == null ? null : (
                            <>
                              <AssetTitle id={this.props.asset.id} />
                              {this.props.asset.tree_path.length === 0 ? null : (
                                <TreePath assetId={this.props.asset.id} />
                              )}
                            </>
                          )
                        }
                        onClear={() => {
                          this.setState({
                            showDowntimeDrawer: false,
                          });
                          this.props.setEditingRequestValues({
                            asset_id: null,
                          });
                        }}
                      />
                    }
                    defaultTreeParentId={this.props.checkedInAssetId}
                    selectedAssetId={this.props.request.asset_id}
                    onSelectAsset={assetId => {
                      if (this.state.errors.assetRequired) {
                        this.setState({
                          errors: {
                            ...this.state.errors,
                            assetRequired: false,
                          },
                        });
                      }
                      this.props.setEditingRequestValues({
                        asset_id: assetId,
                      });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            {this.renderCreateDowntimeCheckbox()}
            <DowntimeOptionsDrawer
              open={this.state.showDowntimeDrawer}
              editingDowntime={this.props.request.downtime}
              assetId={this.props.request.asset_id}
              onChange={params => {
                this.props.setEditingRequestValues(params);
              }}
              onClearErrors={errors => {
                this.setState({
                  errors: {
                    ...this.state.errors,
                    ...errors,
                  },
                });
              }}
              showAnotherAssetRequiredError={this.state.errors.showAnotherAssetRequiredError}
              showFromDateRequiredError={this.state.errors.showFromDateRequiredError}
              showFromDateInFutureError={this.state.errors.showFromDateInFutureError}
            />
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.request.request-type" />}>
                  <RequestTypeInlineModal
                    trigger={
                      <Field.Resource
                        error={this.state.errors.typeRequired}
                        value={this.props.requestType == null ? null : this.props.requestType.title}
                        onClear={() => this.props.setEditingRequestValues({ request_type_id: null })}
                      />
                    }
                    editable={this.props.isAdmin}
                    selectedRequestTypeId={this.props.requestType == null ? null : this.props.requestType.id}
                    onSelectRequestType={requestTypeId => {
                      if (this.state.errors.typeRequired) {
                        this.setState({ errors: { ...this.state.errors, typeRequired: false } });
                      }
                      this.props.setEditingRequestValues({ request_type_id: requestTypeId });
                    }}
                    onClearRequestType={() => this.props.setEditingRequestValues({ request_type_id: null })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.request.priority" />}>
                  <ChoosePriorityInlineModal
                    trigger={
                      <Field.Resource
                        error={this.state.errors.priorityRequired}
                        value={this.renderSelectedPriority()}
                        onClear={() => this.props.setEditingRequestValues({ priority: null })}
                      />
                    }
                    selectedPriority={this.props.request.priority}
                    onSelectPriority={priority => {
                      if (this.state.errors.priorityRequired) {
                        this.setState({ errors: { ...this.state.errors, priorityRequired: false } });
                      }
                      this.props.setEditingRequestValues({ priority });
                    }}
                    onClear={() => this.props.setEditingRequestValues({ priority: null })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{this.renderImagesContainer()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className={styles['footer']}>
          <Button.Group>
            <Button
              small
              primary
              loading={this.state.isSaving}
              label="general.save"
              onClick={this.saveRequest}
            />
            <Button
              small
              label="general.cancel"
              onClick={() => {
                if (this.props.hasUnsavedChanges) {
                  const answer = window.confirm(
                    this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
                  );
                  if (answer) {
                    this.cancelEdit();
                  }
                } else {
                  this.cancelEdit();
                }
              }}
            />
          </Button.Group>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <Prompt
          when={this.props.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <ListLayout.Content.MainContent>
          <ListLayout.Content.MainContent.Content>
            {this.renderRequest()}
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <ChooseAssigneeModal
          open={this.state.showChooseAssigneeModal}
          request={this.props.request}
          onClose={() => this.setState({ showChooseAssigneeModal: false })}
          onCreated={request => {
            this.changeQueryParams({ request_id: request.id });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingRequestValues: RequestsOperations.setEditingRequestValues,
      cancelNewRequest: RequestsOperations.cancelNewRequest,
      setRequestForView: RequestsOperations.setRequestForView,
      createDraftImage: SDKReduxOperations.createDraftImage,
      createImageForRequest: SDKReduxOperations.createImageForRequest,
      deleteImage: SDKReduxOperations.deleteImage,
      createRequest: SDKReduxOperations.createRequest,
      updateRequest: SDKReduxOperations.updateRequest,
      resetSelectedRequests: RequestsOperations.resetSelectedRequests,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    requestType: EntitySelectors.getRequestType(state, ownProps.request.request_type_id),
    asset: EntitySelectors.getAsset(state, ownProps.request.asset_id),
    createdByUser: EntitySelectors.getUser(state, ownProps.request.created_by_user_id),
    images: EntitySelectors.getImages(state, ownProps.request.images),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    requestBeforeEdit: RequestsSelectors.getRequestBeforeEdit(state),
    isAdmin: AuthSelectors.isAdmin(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    canEditDowntimes: AuthSelectors.canEditDowntimes(state),
    hasUnsavedChanges: RequestsSelectors.hasUnsavedChanges(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit)));
