import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { REPORT_SPLIT_PER_TYPES } from 'sdk/Cost';
import { InlineModal, Icon, Button } from 'views/components/Shared/General';

export default class SortInlineModal extends Component {
  state = {
    isOpen: false,
  };

  shouldComponentUpdate(prevProps, prevState) {
    if (!prevState.isOpen && !this.state.isOpen) return false;
    return true;
  }

  renderSortItems = () => {
    const { splitPerType, sortingOnPerSplitType, sortingOnType, type, perType, sortingOnPerType } =
      this.props;
    if (splitPerType === REPORT_SPLIT_PER_TYPES.External) {
      return (
        <>
          <InlineModal.ListItem
            title={<FormattedMessage id={`components.reported-time-overview.show-per-types.${perType}`} />}
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.onSortOnPerType();
            }}
            rightContainer={sortingOnPerType === perType ? <Icon regular green type="check" /> : null}
          />
          <InlineModal.ListItem
            title={<FormattedMessage id={`components.reported-time-overview.internal`} />}
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.onSortOnSplitPerType('internal');
            }}
            rightContainer={sortingOnPerSplitType === 'internal' ? <Icon regular green type="check" /> : null}
          />
          <InlineModal.ListItem
            title={<FormattedMessage id={`components.reported-time-overview.external`} />}
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.onSortOnSplitPerType('external');
            }}
            rightContainer={sortingOnPerSplitType === 'external' ? <Icon regular green type="check" /> : null}
          />
        </>
      );
    }
    return (
      <>
        <InlineModal.ListItem
          title={<FormattedMessage id={`components.reported-time-overview.show-per-types.${perType}`} />}
          onClick={() => {
            this.setState({ isOpen: false });
            this.props.onSortOnPerType();
          }}
          rightContainer={sortingOnPerType === perType ? <Icon regular green type="check" /> : null}
        />
        <InlineModal.ListItem
          title={<FormattedMessage id={`components.reported-time-overview.show-types.${type}`} />}
          onClick={() => {
            this.setState({ isOpen: false });
            this.props.onSortOnType();
          }}
          rightContainer={sortingOnType === type ? <Icon regular green type="check" /> : null}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon solid size={15} type="sort-alpha-down" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            {this.renderSortItems()}
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}
