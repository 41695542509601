import React, { Component } from 'react';
import { API, HelperFunctions } from 'sdk';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeVendor } from 'sdk/Schemas';

class ChooseVendorInlineModal extends Component {
  getInitialState = () => ({
    isOpen: false,
    searchTerm: '',
    isFetching: true,
    isSearching: false,
    pagination: {
      totalPages: 0,
      currentPage: 0,
    },
    vendors: [],
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.debouncedSearch = debounce(params => {
      this.fetchVendors({ search: this.state.searchTerm, ...params }, false).then(() => {
        this.setState({ isSearching: false });
      });
    }, 400);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({
        ...this.getInitialState(),
        isOpen: true,
        isFetching: true,
      });
      this.fetchVendors();
    }
  };

  fetchVendors = (params, initialFetch = true) => {
    this.setState({ isFetching: initialFetch, isSearching: !initialFetch });
    return API.listVendors(this.props.currentSystem.id, {
      page_size: 8,
      ...params,
      ...this.props.params,
      ...this.props.defaultQueryParams,
    }).then(res => {
      const { headers } = res;
      const vendors = res.data;
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      const { entities } = normalizeVendor(res.data);
      this.props.updateEntities(entities);
      this.setState({
        pagination,
        vendors,
        isFetching: false,
        isSearching: false,
      });
    });
  };

  selectVendor = vendorId => {
    if (this.props.multiple) {
      if (this.props.selectedVendorIds.includes(vendorId)) {
        this.props.onRemoveVendor(vendorId);
      } else {
        this.props.onAddVendor(vendorId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectVendor(vendorId);
        }
      );
    }
  };

  selectPage = page => {
    this.setState({ page, isSearching: true });
    this.debouncedSearch({ page });
  };

  renderListItem = vendor => {
    if (this.props.multiple) {
      const selected = this.props.selectedVendorIds.includes(vendor.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={vendor.id}
          onClick={e => this.selectVendor(vendor.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectVendor(vendor.id);
              }}
            />
          }
        >
          {vendor.name}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedVendorId === vendor.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={vendor.id}
          selected={selected}
          onClick={e => this.selectVendor(vendor.id)}
        >
          {vendor.name}
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderList = () => {
    return this.state.vendors.map(vendor => this.renderListItem(vendor));
  };

  renderLoader = () => {
    const amountOfVendors = this.state.vendors.length === 0 ? 2 : this.state.vendors.length;
    return (
      <>
        <NewInlineModal.Header>{this.renderSearchField()}</NewInlineModal.Header>
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            {Array(amountOfVendors)
              .fill()
              .map(() => (
                <NewInlineModal.Dropdown.Item loading />
              ))}
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderSearchField = () => (
    <NewInlineModal.Header.Search
      ref={ref => (this.inlineModalSearchRef = ref)}
      placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
      value={this.state.searchTerm}
      onChange={searchTerm => {
        this.setState({
          isSearching: true,
          searchTerm,
          pagination: {
            ...this.state.pagination,
            currentPage: 1,
          },
        });
        this.debouncedSearch({ search: searchTerm, page: 1 });
      }}
      onClear={() => {
        this.setState({
          isSearching: true,
          searchTerm: '',
          pagination: {
            ...this.state.pagination,
            currentPage: 1,
          },
        });
        this.debouncedSearch({ search: '', page: 1 });
      }}
    />
  );

  renderContent = () => {
    if (this.state.isFetching || this.state.isSearching) {
      return this.renderLoader();
    }
    if (this.state.vendors.length === 0) {
      if (this.state.searchTerm.length > 0) {
        return (
          <>
            <NewInlineModal.Header>{this.renderSearchField()}</NewInlineModal.Header>
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.EmptyDataSet>
                <FormattedMessage id="general.empty-data-set-search.title" />
              </NewInlineModal.Dropdown.EmptyDataSet>
            </NewInlineModal.Dropdown>
          </>
        );
      }
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.asset-inline-modal.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }

    return (
      <>
        <NewInlineModal.Header>{this.renderSearchField()}</NewInlineModal.Header>
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>{this.renderList()}</NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderFooter = () => {
    const { totalPages, currentPage } = this.state.pagination;
    if (totalPages > 1) {
      return (
        <NewInlineModal.Footer>
          <NewInlineModal.Footer.Pagination
            blue
            totalPages={totalPages}
            currentPage={currentPage}
            onSelectPage={page => this.selectPage(page)}
          />
        </NewInlineModal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderContent()}
          {this.renderFooter()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseVendorInlineModal));

ChooseVendorInlineModal.propTypes = {
  selectedVendorId: PropTypes.string,
  selectedVendorIds: PropTypes.array,
  onSelectVendor: PropTypes.func,
  onAddVendor: PropTypes.func,
  onRemoveVendor: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
};

ChooseVendorInlineModal.defaultProps = {
  selectedVendorId: null,
  selectedVendorIds: [],
  onSelectVendor: () => {},
  onAddVendor: () => {},
  onRemoveVendor: () => {},
  onClear: () => {},
  multiple: false,
};
