import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ConfirmDeleteInlineModal, List, Icon } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';

export default ({ user, isRemovingUserId, onDelete }) => {
  return (
    <List.Item key={user.id}>
      <List.Item.TitleColumn
        title={<UserNameWrapper user={user} />}
        subtitle={user.invited ? null : user.email}
      />
      <List.Item.Column alignRight>
        <ConfirmDeleteInlineModal
          position="right"
          trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
          title={<FormattedMessage id="screens.settings.work-orders.groups.group-members.delete-confirm" />}
          onDelete={onDelete}
          loading={isRemovingUserId === user.id}
        />
      </List.Item.Column>
    </List.Item>
  );
};
