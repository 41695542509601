import CreatedAt from './CreatedAt';
import CreatedBy from './CreatedBy';
import SparePart from './SparePart';
import Asset from './Asset';
import SparePartLocation from './SparePartLocation';
import Comment from './Comment';

export default {
  CreatedAt,
  CreatedBy,
  Asset,
  SparePart,
  SparePartLocation,
  Comment,
};
