import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  OperatorCheckedInAssetOperations,
  OperatorCheckedInAssetSelectors,
} from 'state/ducks/operatorCheckedInAsset';
import { NewSelectAssetModal, ManageBookmarkedAssetsModal } from 'views/components/Asset';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeAssetBookmark, normalizeAsset } from 'sdk/Schemas';
import { API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, ContentLoader, Icon, NewInlineModal, SectionHeader } from 'views/components/Shared/General';
import AssetDropdownItem from './AssetDropdownItem';
import styles from './style.module.scss';

class OperatorMenu extends Component {
  state = {
    open: false,
    showMangeBookmarkedAssetsModal: false,
    showSelectAssetModal: false,
    assetBookmarkIds: [],
    assets: [],
    isFetching: false,
    loadingCheckedIn: false,
  };

  componentDidMount() {
    const assetId = localStorage.getItem('checkedInAssetId');
    if (assetId) {
      this.setState({ loadingCheckedIn: true }, () => {
        API.fetchAsset(assetId).then(({ data: asset }) => {
          const { entities } = normalizeAsset(asset);
          this.props.updateEntities(entities);
          this.props.selectAssetId(assetId);
          this.setState({ loadingCheckedIn: false });
        });
      });
    }
  }

  open = () => {
    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => {
        if (this.state.open) {
          this.fetchBookmarkedAssets();
        }
      }
    );
  };

  selectAndCheckinOnAssetId = ({ id, title }) => {
    this.props.selectAssetId(id);
    localStorage.setItem('checkedInAssetId', id);
    localStorage.setItem('checkedInAssetTitle', title);
  };

  clearCheckedInAsset = () => {
    this.props.selectAssetId(null);
    localStorage.removeItem('checkedInAssetId');
    localStorage.removeItem('checkedInAssetTitle');
  };

  fetchBookmarkedAssets = () => {
    this.setState({ isFetching: true });
    API.listAssetBookmarks(this.props.system.id).then(({ data: assets }) => {
      const { entities, result: assetBookmarkIds } = normalizeAssetBookmark(assets);
      this.props.updateEntities(entities);
      this.setState({ assetBookmarkIds, assets, isFetching: false });
    });
  };

  renderSelectedAnotherAsset = () => {
    if (this.props.checkedInAsset) {
      const selectedAssetIsBookmarked =
        this.state.assets.find(el => el.asset_id === this.props.checkedInAsset.id) != null;
      if (selectedAssetIsBookmarked === false) {
        return (
          <AssetDropdownItem
            asset_id={this.props.checkedInAsset.id}
            selectedAssetId={this.props.checkedInAsset.id}
            onClick={({ id, title }) => {
              this.selectAndCheckinOnAssetId({ id, title });
              this.setState({ open: false });
            }}
          />
        );
      }
    }
    return null;
  };

  renderInlineModalContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <div style={{ marginTop: '-12px', marginBottom: '12px' }}>
            <SectionHeader paddingHorizontal={20} noBorderTop>
              <FormattedMessage id="menu.operator-asset.my-assets" />
            </SectionHeader>
          </div>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }

    return (
      <NewInlineModal.Dropdown.Items>
        <div style={{ marginTop: '-12px', marginBottom: '12px' }}>
          <SectionHeader paddingHorizontal={20} noBorderTop>
            <FormattedMessage id="menu.operator-asset.my-assets" />
          </SectionHeader>
        </div>
        {this.state.assetBookmarkIds.map((id, index) => (
          <AssetDropdownItem
            id={id}
            key={index}
            selectedAssetId={this.props.checkedInAsset?.id}
            onClick={({ id, title }) => {
              this.selectAndCheckinOnAssetId({ id, title });
              this.setState({ open: false });
            }}
          />
        ))}
        <NewInlineModal.Dropdown.Item
          onClick={() => this.setState({ open: false, showMangeBookmarkedAssetsModal: true })}
        >
          <span className={styles['subtitle']}>
            <FormattedMessage id="menu.operator-asset.manage-my-assets" />
          </span>
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Separator />
        <NewInlineModal.Dropdown.Item
          selected={!this.props.checkedInAsset}
          onClick={() => {
            this.clearCheckedInAsset();
            this.setState({ open: false });
          }}
        >
          <FormattedMessage id="menu.operator-asset.all-assets" />
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ open: false, showSelectAssetModal: true });
          }}
        >
          <FormattedMessage id="menu.operator-asset.select-asset" />
        </NewInlineModal.Dropdown.Item>
        {this.renderSelectedAnotherAsset()}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderInlineModal = () => {
    return (
      <NewInlineModal
        position="right"
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        minWidth={335}
      >
        <>
          <NewInlineModal.Dropdown>{this.renderInlineModalContent()}</NewInlineModal.Dropdown>
        </>
      </NewInlineModal>
    );
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        title={<FormattedMessage id="menu.operator-asset.select-asset" />}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={({ id, title }) => {
          return (
            <Button
              gray
              small
              label="general.choose"
              onClick={() => {
                this.selectAndCheckinOnAssetId({ id, title });
                this.setState({ showSelectAssetModal: false });
              }}
            />
          );
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  renderBookmarkedAssetModal = () => {
    return (
      <ManageBookmarkedAssetsModal
        open={this.state.showMangeBookmarkedAssetsModal}
        onClose={() => this.setState({ showMangeBookmarkedAssetsModal: false })}
      />
    );
  };

  renderAssetTitle = () => {
    if (this.state.loadingCheckedIn) {
      return (
        <ContentLoader width={100} height={12} light>
          <rect x="0" y="0" rx="2" ry="2" width="100" height="12" />
        </ContentLoader>
      );
    }
    if (this.props.checkedInAsset) {
      return this.props.checkedInAsset.title;
    }
    return <FormattedMessage id="menu.operator-asset.all-assets" />;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          className={styles['operator-asset-button']}
          onClick={() => {
            this.open();
          }}
        >
          {this.renderAssetTitle()}
          <div className={styles['icon']}>
            <Icon solid white type="caret-down" size={12} />
          </div>
        </div>
        {this.renderInlineModal()}
        {this.renderSelectAssetModal()}
        {this.renderBookmarkedAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectAssetId: OperatorCheckedInAssetOperations.selectAssetId,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    checkedInAsset: OperatorCheckedInAssetSelectors.getAsset(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OperatorMenu));
