import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, EmptyDataSet, Button } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class UnitField extends Component {
  renderUnits = () => {
    if (this.props.sparePartUnits.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.subtitle" />
            }
            button={
              <Button
                primary
                label="screens.spare-part.info.general-information.unit-empty-data-set.button"
                small
                onClick={() => this.props.history.push('/settings/spare-parts/units')}
              />
            }
            tiny
          />
        </div>
      );
    }
    return this.props.sparePartUnits.map(sparePartUnit => (
      <Field.Dropdown.Item
        key={sparePartUnit.id}
        selected={this.props.value === sparePartUnit.id}
        onClick={() => this.props.onChange(sparePartUnit.id)}
      >
        {sparePartUnit.title}
      </Field.Dropdown.Item>
    ));
  };

  renderValue = () => {
    const { sparePartUnit } = this.props;
    if (sparePartUnit) {
      return sparePartUnit.title;
    }
    return '';
  };

  render() {
    return (
      <Field.Dropdown
        clearable
        direction="left"
        value={this.renderValue()}
        onClear={() => this.props.onChange(null)}
      >
        {this.renderUnits()}
      </Field.Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.value),
    sparePartVendor: EntitySelectors.getSparePartVendor(state, ownProps.sparePartVendorId),
  };
}

export default withRouter(connect(mapStateToProps)(UnitField));
