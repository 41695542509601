import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { DowntimeReasonInlineModal } from 'views/components/Downtime';
import { EntitySelectors } from 'sdk/State/entities';

const ReasonField = ({ downtimeReason, value, onChange }) => (
  <Field view={false} label={<FormattedMessage id="resources.downtime.reason" />}>
    <DowntimeReasonInlineModal
      trigger={
        <Field.Resource
          angleDown
          value={downtimeReason == null ? null : downtimeReason.title}
          onClear={() => onChange(null)}
        />
      }
      selectedDowntimeReasonId={value}
      onSelectDowntimeReason={id => onChange(id)}
      onClear={() => onChange(null)}
    />
  </Field>
);

function mapStateToProps(state, ownProps) {
  return {
    downtimeReason: EntitySelectors.getDowntimeReason(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(ReasonField);
