import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Priority extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter.priority || props.initialFilter.priority;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        comparator,
        priorities: [],
        hasValue: value,
        initialFilter: props.filter,
      };
    } else {
      this.state = {
        comparator,
        priorities: value,
        hasValue: false,
        initialFilter: props.filter,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.Priority,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.Priority]: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.Priority,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.Priority]: {
                  comparator: this.state.comparator,
                  value: this.state.priorities,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return this.state.priorities.length > 0;
  };

  /*
    Render functions
  */

  renderPriority = priority => {
    switch (priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderSelectedPriorities = () => {
    if (this.state.priorities.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.priorities}
        renderItem={priority => this.renderPriority(priority)}
      />
    );
  };

  renderTrigger = () => {
    return (
      <ChoosePriorityInlineModal
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedPriorities()}
            onClear={() => this.setState({ priorities: [] })}
          />
        }
        selectedPriorities={this.state.priorities}
        onAddPriority={priority => {
          this.setState({
            priorities: [...this.state.priorities, priority],
          });
        }}
        onRemovePriority={priority => {
          this.setState({
            priorities: this.state.priorities.filter(item => item !== priority),
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter.priority, this.state.initialFilter.priority)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Priority,
                data: {
                  work_order: {
                    ...this.props.filter,
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Priority,
                data: {
                  work_order: {
                    ...this.props.filter,
                    priority: {
                      ...this.state.initialFilter.priority,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order.priority" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Priority);

Priority.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Priority.defaultProps = {
  filter: {
    priority: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
  initialFilter: {
    priority: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
};
