import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import moment from 'moment';
import { MetersOperations } from 'state/ducks/meters';
import { TreePath } from 'views/components/Asset';
import { ChangeMeterValueModal } from 'views/components/Meter';
import { List, FormattedNumber, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class MeterListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditButton: false,
      isLoadingValue: false,
    };
  }

  setNavigatedTo = () => {
    const {
      location: { search },
      setNavigatedTo,
    } = this.props;
    const { list, asset_with_tree_children_id } = queryString.parse(search);

    setNavigatedTo({
      search: {
        list,
        asset_with_tree_children_id,
      },
    });
  };

  renderSubtitle = date => {
    if (!date) return '-';
    var scheduledDate = moment(date);
    var currentDate = moment().startOf('day');
    var differenceInDate = scheduledDate.diff(currentDate, 'days');

    switch (true) {
      case differenceInDate === 0:
        return <FormattedMessage id="screens.meters.next-reading.today" />;

      case differenceInDate === 1:
        return <FormattedMessage id="screens.meters.next-reading.tomorrow" />;

      case differenceInDate > 1:
        return (
          <FormattedPlural
            value={differenceInDate}
            two={
              <FormattedMessage
                id="screens.meters.next-reading.future.two"
                values={{ amount: differenceInDate }}
              />
            }
            few={
              <FormattedMessage
                id="screens.meters.next-reading.future.few"
                values={{ amount: differenceInDate }}
              />
            }
            many={
              <FormattedMessage
                id="screens.meters.next-reading.future.many"
                values={{ amount: differenceInDate }}
              />
            }
            other={
              <FormattedMessage
                id="screens.meters.next-reading.future.other"
                values={{ amount: differenceInDate }}
              />
            }
          />
        );

      default:
        const absDifferenceInDate = Math.abs(differenceInDate);

        return (
          <span className={styles['danger-text']}>
            <FormattedMessage id="screens.meters.next-reading.overdue" />
            <span> (</span>
            <FormattedPlural
              value={absDifferenceInDate}
              one={<FormattedMessage id="screens.meters.next-reading.overdue-days.one" />}
              two={
                <FormattedMessage
                  id="screens.meters.next-reading.overdue-days.two"
                  values={{ amount: absDifferenceInDate }}
                />
              }
              few={
                <FormattedMessage
                  id="screens.meters.next-reading.overdue-days.few"
                  values={{ amount: absDifferenceInDate }}
                />
              }
              many={
                <FormattedMessage
                  id="screens.meters.next-reading.overdue-days.many"
                  values={{ amount: absDifferenceInDate }}
                />
              }
              other={
                <FormattedMessage
                  id="screens.meters.next-reading.overdue-days.other"
                  values={{ amount: absDifferenceInDate }}
                />
              }
            />
            <span>)</span>
          </span>
        );
    }
  };

  render() {
    const {
      id,
      loading,
      checked,
      checkboxDisabled,
      onCheck,
      meter,
      canEditMeterReadings,
      meterUnit,
      onEditValueClick,
    } = this.props;
    const { isLoadingValue, showEditButton } = this.state;

    const editButtonOnClick = e => {
      e.preventDefault();
      onEditValueClick(id);
    };

    if (loading) {
      return (
        <List.Item small checkbox expandable>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }

    return (
      <List.Item
        loading={isLoadingValue}
        clickable
        onClick={() => this.setNavigatedTo()}
        onMouseEnter={() => this.setState({ showEditButton: true })}
        onMouseLeave={() => this.setState({ showEditButton: false })}
        linkTo={{
          pathname: `/meters/${id}`,
        }}
        checked={checked}
        checkboxDisabled={checkboxDisabled}
        onCheck={onCheck}
        checkbox
      >
        <List.Item.TitleColumn
          title={meter.title}
          subtitle={meter.asset_id ? <TreePath fullPath assetId={meter.asset_id} /> : null}
        />
        {canEditMeterReadings ? (
          <List.Item.Column alignRight hide={!showEditButton}>
            <Button
              iconButtonSize={28}
              type="icon"
              icon={<Icon regular type="pen" size={12} />}
              onClick={editButtonOnClick}
            />
          </List.Item.Column>
        ) : null}
        <List.Item.Column alignRight>
          <FormattedNumber value={meter.value} unit={meterUnit ? meterUnit.abbreviation : null} />
        </List.Item.Column>
        <List.Item.Column alignRight width={240}>
          {this.renderSubtitle(meter.next_due_date)}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNavigatedTo: MetersOperations.setNavigatedTo,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const meter = EntitySelectors.getMeter(state, ownProps.id);
  return {
    meter,
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
    canEditMeterReadings: AuthSelectors.canEditMeterReadings(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeterListItem));
