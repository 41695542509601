import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import {
  SparePartTypeInlineModal,
  SparePartUnitInlineModal,
  SparePartTypePath,
} from 'views/components/SparePart';
import {
  ChooseSparePartLocationInlineModal,
  SparePartLocationPath,
} from 'views/components/SparePartLocation';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { SparePartsSelectors } from 'state/ducks/spareParts';
import SparePartUnitContainer from './SparePartUnitContainer';
import EditSparePartField from './EditSparePartField';
import SparePartField from './SparePartField';
import styles from './styles.module.scss';

const BATCH_UPDATABLE_FIELDS = {
  SparePartType: 'spare_part_type_id',
  SparePartUnit: 'spare_part_unit_id',
  SparePartLocation: 'spare_part_location_id',
  ReorderPoint: 'reorder_point',
  MinQuantity: 'minimum_quantity',
  MaxQuantity: 'maximum_quantity',
  SparePartFields: 'spare_part_fields',
};

class MassEditModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    confirmMassEdit: false,
    showEditForField: null,
    spare_part_type_id: null,
    spare_part_unit_id: null,
    spare_part_location_id: null,
    reorder_point: null,
    minimum_quantity: null,
    maximum_quantity: null,
    spare_part_fields: null,
    showQuantityExceeded: false,
    updatableFields: this.getUpdatableFields(),
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded: this.props.checkedSparePartCount >= 5000,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  getUpdatableFields = () => {
    let updatableFields = [
      {
        title: this.props.intl.formatMessage({ id: 'resources.spare-part.category' }),
        key: BATCH_UPDATABLE_FIELDS.SparePartType,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.spare-part.location' }),
        key: BATCH_UPDATABLE_FIELDS.SparePartLocation,
      },
      {
        title: this.props.intl.formatMessage({ id: 'resources.spare-part.unit' }),
        key: BATCH_UPDATABLE_FIELDS.SparePartUnit,
      },
    ];
    if (this.props.hasProTier) {
      updatableFields = [
        ...updatableFields,
        {
          title: this.props.intl.formatMessage({ id: 'resources.spare-part.reorder-point' }),
          key: BATCH_UPDATABLE_FIELDS.ReorderPoint,
        },
        {
          title: this.props.intl.formatMessage({ id: 'resources.spare-part.min-quantity' }),
          key: BATCH_UPDATABLE_FIELDS.MinQuantity,
        },
        {
          title: this.props.intl.formatMessage({ id: 'resources.spare-part.max-quantity' }),
          key: BATCH_UPDATABLE_FIELDS.MaxQuantity,
        },
      ];
    }
    updatableFields = [
      ...updatableFields,
      ...this.props.templateFields.map(templateField => {
        return {
          title: templateField.title,
          key: BATCH_UPDATABLE_FIELDS.SparePartFields,
          spare_part_fields: {
            id: templateField.id,
            value: null,
          },
        };
      }),
    ];
    return updatableFields.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  buildFilterParams = () => {
    const {
      asset_with_tree_children_id,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    } = queryString.parse(this.props.location.search);
    if (this.props.totalEntriesIsSelected) {
      let params = {};
      if (asset_with_tree_children_id) {
        params = {
          ...params,
          spare_part_assets: {
            asset_with_tree_children_id: asset_with_tree_children_id,
          },
        };
      }
      if (spare_part_location_with_children_id) {
        params = {
          ...params,
          spare_part_location_with_children_id,
        };
      }
      if (spare_part_type_with_children_id) {
        params = {
          ...params,
          spare_part_type_with_children_id,
        };
      }
      return {
        filters: {
          ...params,
          ...this.props.filtersAsQueryParams,
          ...this.props.queryParameters,
        },
      };
    }
    return {
      filters: {
        id: {
          any: Object.keys(this.props.checkedSparePartIds).toString(),
        },
      },
    };
  };

  buildDataParams = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.SparePartType:
        return {
          update: {
            spare_part_type_id: this.state.spare_part_type_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.SparePartUnit:
        return {
          update: {
            spare_part_unit_id: this.state.spare_part_unit_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.SparePartLocation:
        return {
          update: {
            spare_part_location_id: this.state.spare_part_location_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.ReorderPoint:
        return {
          update: {
            reorder_point: this.state.reorder_point || null,
          },
        };
      case BATCH_UPDATABLE_FIELDS.MinQuantity:
        return {
          update: {
            minimum_quantity: this.state.minimum_quantity || null,
          },
        };
      case BATCH_UPDATABLE_FIELDS.MaxQuantity:
        return {
          update: {
            maximum_quantity: this.state.maximum_quantity || null,
          },
        };
      case BATCH_UPDATABLE_FIELDS.SparePartFields:
        return {
          update: {
            spare_part_fields: [
              {
                template_field_id: this.state.spare_part_fields.id,
                value: this.state.spare_part_fields.value,
              },
            ],
          },
        };
      default:
        return {
          update: {},
        };
    }
  };

  batchUpdate = () => {
    this.setState({ isSaving: true });
    API.batchUpdateSpareParts(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildDataParams(),
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false, showQuantityExceeded: true });
        }
      });
  };

  selectFieldToUpdate = params => {
    this.setState({
      ...this.getInitialState(),
      ...params,
    });
  };

  renderSelectedField = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.SparePartType:
        return <FormattedMessage id="resources.spare-part.category" />;
      case BATCH_UPDATABLE_FIELDS.SparePartLocation:
        return <FormattedMessage id="resources.spare-part.location" />;
      case BATCH_UPDATABLE_FIELDS.SparePartUnit:
        return <FormattedMessage id="resources.spare-part.unit" />;
      case BATCH_UPDATABLE_FIELDS.ReorderPoint:
        return <FormattedMessage id="resources.spare-part.reorder-point" />;
      case BATCH_UPDATABLE_FIELDS.MinQuantity:
        return <FormattedMessage id="resources.spare-part.min-quantity" />;
      case BATCH_UPDATABLE_FIELDS.MaxQuantity:
        return <FormattedMessage id="resources.spare-part.max-quantity" />;
      case BATCH_UPDATABLE_FIELDS.SparePartFields:
        return <SparePartField id={this.state.spare_part_fields.id} />;
      default:
        return null;
    }
  };

  renderSelectedSparePartType = () => {
    if (this.state.spare_part_type_id) {
      return <SparePartTypePath fullPath sparePartTypeId={this.state.spare_part_type_id} />;
    }
    return null;
  };

  renderEditSparePartType = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.category" />}>
        <SparePartTypeInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedSparePartType()}
              onClear={() => this.setState({ spare_part_type_id: null })}
            />
          }
          selectedSparePartTypeId={this.state.spare_part_type_id}
          onSelectSparePartType={id => this.setState({ spare_part_type_id: id })}
          onClear={() => this.setState({ spare_part_type_id: null })}
        />
      </Field>
    );
  };

  renderSelectedSparePartUnit = () => {
    if (this.state.spare_part_unit_id) {
      return <SparePartUnitContainer id={this.state.spare_part_unit_id} />;
    }
    return null;
  };

  renderEditSparePartUnit = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.unit" />}>
        <SparePartUnitInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedSparePartUnit()}
              onClear={() => this.setState({ spare_part_unit_id: null })}
            />
          }
          selectedSparePartUnitId={this.state.spare_part_unit_id}
          onSelectSparePartUnit={id => this.setState({ spare_part_unit_id: id })}
          onClear={() => this.setState({ spare_part_unit_id: null })}
        />
      </Field>
    );
  };

  renderSelectedSparePartLocation = () => {
    if (this.state.spare_part_location_id) {
      return <SparePartLocationPath sparePartLocationId={this.state.spare_part_location_id} />;
    }
    return null;
  };

  renderEditSparePartLocation = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.location" />}>
        <ChooseSparePartLocationInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedSparePartLocation()}
              onClear={() => this.setState({ spare_part_location_id: null })}
            />
          }
          selectedSparePartLocationId={this.state.spare_part_location_id}
          onSelectSparePartLocation={id => this.setState({ spare_part_location_id: id })}
          onClear={() => this.setState({ spare_part_location_id: null })}
        />
      </Field>
    );
  };

  renderEditReorderPoint = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.reorder-point" />}>
        <Field.Decimal
          value={this.state.reorder_point}
          onChange={reorder_point => {
            this.setState({ reorder_point });
          }}
          onBlur={reorder_point => {
            this.setState({ reorder_point });
          }}
        />
      </Field>
    );
  };

  renderEditMinQuantity = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.min-quantity" />}>
        <Field.Decimal
          value={this.state.minimum_quantity}
          onChange={minimum_quantity => {
            this.setState({ minimum_quantity });
          }}
          onBlur={minimum_quantity => {
            this.setState({ minimum_quantity });
          }}
        />
      </Field>
    );
  };

  renderEditMaxQuantity = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.spare-part.max-quantity" />}>
        <Field.Decimal
          value={this.state.maximum_quantity}
          onChange={maximum_quantity => {
            this.setState({ maximum_quantity });
          }}
          onBlur={maximum_quantity => {
            this.setState({ maximum_quantity });
          }}
        />
      </Field>
    );
  };

  renderEditSparePartField = () => {
    return (
      <EditSparePartField
        id={this.state.spare_part_fields.id}
        value={this.state.spare_part_fields.value}
        onChange={value => this.setState({ spare_part_fields: { ...this.state.spare_part_fields, value } })}
        onClear={() => this.setState({ spare_part_fields: { ...this.state.spare_part_fields, value: null } })}
      />
    );
  };

  renderEditableField = () => {
    let content = null;
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.SparePartType:
        content = this.renderEditSparePartType();
        break;
      case BATCH_UPDATABLE_FIELDS.SparePartUnit:
        content = this.renderEditSparePartUnit();
        break;
      case BATCH_UPDATABLE_FIELDS.SparePartLocation:
        content = this.renderEditSparePartLocation();
        break;
      case BATCH_UPDATABLE_FIELDS.ReorderPoint:
        content = this.renderEditReorderPoint();
        break;
      case BATCH_UPDATABLE_FIELDS.MinQuantity:
        content = this.renderEditMinQuantity();
        break;
      case BATCH_UPDATABLE_FIELDS.MaxQuantity:
        content = this.renderEditMaxQuantity();
        break;
      case BATCH_UPDATABLE_FIELDS.SparePartFields:
        content = this.renderEditSparePartField();
        break;
      default:
        return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>{content}</Grid.Column>
      </Grid.Row>
    );
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="components.mass-edit-modal.select-field-label" />}>
            <Field.Dropdown clearable={false} value={this.renderSelectedField()}>
              {this.state.updatableFields.map(({ title, key, spare_part_fields }) => (
                <Field.Dropdown.Item
                  selected={
                    this.state.showEditForField === key &&
                    this.state.spare_part_fields?.id == spare_part_fields?.id
                  }
                  onClick={() => this.selectFieldToUpdate({ showEditForField: key, spare_part_fields })}
                >
                  <span>{title}</span>
                </Field.Dropdown.Item>
              ))}
            </Field.Dropdown>
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderEditableField()}
      {this.renderConfirmCheckbox()}
    </Grid>
  );

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedSparePartCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-spare-parts.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-spare-parts.two"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-spare-parts.few"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-spare-parts.many"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-spare-parts.other"
            values={{ count: this.props.checkedSparePartCount }}
          />
        }
      />
    );
  };

  renderConfirmCheckbox = () => {
    if (this.state.showEditForField == null) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.confirmMassEdit}
            label={
              <FormattedMessage
                id="components.mass-edit-modal.i-am-sure"
                values={{
                  count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                }}
              />
            }
            onChange={() => {
              this.setState(prevState => ({
                confirmMassEdit: !prevState.confirmMassEdit,
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderModalContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            <FormattedMessage id="components.mass-edit-modal.quantity-exceeded" />
          </div>
        </Modal.Content>
      );
    }
    return (
      <>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.confirmMassEdit === false}
              loading={this.state.isSaving}
              onClick={this.batchUpdate}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.mass-edit-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: SparePartsSelectors.getQueryParameters(state),
    filtersAsQueryParams: SparePartsSelectors.getFiltersAsQueryParams(state),
    checkedSparePartIds: SparePartsSelectors.getSelectedSparePartIds(state),
    checkedSparePartCount: SparePartsSelectors.getSelectedSparePartCount(state),
    totalEntriesIsSelected: SparePartsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(MassEditModal)));
