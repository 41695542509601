import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { SettingsOperations } from 'state/ducks/settings';

function DndContextContainer(props) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const updateRequestAutomationRule = (id, data) => {
    props
      .updateRequestAutomationRule(id, data)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {});
  };

  const onDragEnd = dragData => {
    const { active, over } = dragData;
    const { id: isDraggingId } = active;
    const { id: isOverId } = over;
    const { index: fromIndex } = active?.data?.current?.sortable;
    const { index: toIndex } = over?.data?.current?.sortable;

    if (isDraggingId === isOverId) {
      return;
    }
    props.sortRuleItem({
      requestAutomationRuleId: isDraggingId,
      fromIndex,
      toIndex,
    });
    updateRequestAutomationRule(isDraggingId, { sort: toIndex + 1 });
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      {props.children}
    </DndContext>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sortRuleItem: SettingsOperations.sortRuleItem,
      updateRequestAutomationRule: SDKReduxOperations.updateRequestAutomationRule,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DndContextContainer);
