import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './style.module.scss';

export default class ErrorRoute extends Component {
  render() {
    return (
      <div className={styles['error-container']}>
        <h1>
          <FormattedMessage id="general.errors.page-not-found" />
        </h1>
        <p>
          <Link to="/">
            <FormattedMessage id="general.go-back" />
          </Link>
        </p>
      </div>
    );
  }
}
