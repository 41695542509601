import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash-es';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Toggle, Icon } from 'views/components/Shared/General';
import { CardGrid, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class VendorType extends Component {
  constructor(props) {
    super(props);
    const { assign_work_orders, create_purchase_orders } = props.vendor;
    this.state = {
      isSavingAssignWorkOrders: false,
      isSavingCreatePurchaseOrders: false,
      assign_work_orders,
      create_purchase_orders,
    };
    this.updateVendorAssign = debounce(this.updateVendorAssign, 500);
    this.updateVendorCreate = debounce(this.updateVendorCreate, 500);
  }

  updateVendorAssign = value => {
    this.props
      .updateVendor(this.props.vendor.id, { assign_work_orders: value })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSavingAssignWorkOrders: false });
      })
      .catch(() => {});
  };

  updateVendorCreate = value => {
    this.props
      .updateVendor(this.props.vendor.id, { create_purchase_orders: value })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSavingCreatePurchaseOrders: false });
      })
      .catch(() => {});
  };

  render() {
    const { assign_work_orders, create_purchase_orders } = this.state;
    return (
      <>
        <CardGrid.Separator />
        <CardGrid.Column>
          <CardGrid.Row>
            <div className={styles['whitecard-wrapper']}>
              <div
                className={`${styles['type']} ${
                  this.props.settings.spare_parts_activated ? styles['work-orders'] : null
                } ${!assign_work_orders ? styles['inactive'] : ''}`}
              >
                <Icon type="wrench" withBackground backgroundSize={34} blue />
                <div className={styles['text']}>
                  <FormattedMessage id="resources.vendor.types.assign-work-orders" />
                </div>
                {this.props.canEditVendors ? (
                  <div className={styles['checkbox']}>
                    <Toggle
                      checked={assign_work_orders}
                      loading={this.state.isSavingAssignWorkOrders}
                      onClick={() => {
                        this.setState(
                          prevState => ({
                            isSavingAssignWorkOrders: true,
                            assign_work_orders: !prevState.assign_work_orders,
                          }),
                          () => {
                            this.updateVendorAssign(this.state.assign_work_orders);
                          }
                        );
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {this.props.settings.spare_parts_activated ? (
                <div
                  className={`${styles['type']} ${styles['purchase-orders']} ${
                    !create_purchase_orders ? styles['inactive'] : ''
                  }`}
                >
                  <Icon type="file-invoice-dollar" withBackground orange backgroundSize={36} />
                  <div className={styles['text']}>
                    <FormattedMessage id="resources.vendor.types.create-purchase-orders" />
                  </div>
                  {this.props.canEditVendors ? (
                    <div className={styles['checkbox']}>
                      <Toggle
                        checked={create_purchase_orders}
                        loading={this.state.isSavingCreatePurchaseOrders}
                        onClick={() => {
                          this.setState(
                            prevState => ({
                              isSavingCreatePurchaseOrders: true,
                              create_purchase_orders: !prevState.create_purchase_orders,
                            }),
                            () => {
                              this.updateVendorCreate(this.state.create_purchase_orders);
                            }
                          );
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </CardGrid.Row>
        </CardGrid.Column>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const vendorId = ownProps.match.params.id;

  return {
    vendor: EntitySelectors.getVendor(state, vendorId),
    settings: AuthSelectors.getSettings(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorType)));
