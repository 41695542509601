import { Socket } from '../../';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeSparePartLocation, normalizeStocktaking, normalizeStocktakingRow } from 'sdk/Schemas';

const join = stocktakingId => {
  return dispatch => {
    const channel = Socket.socket.channel(`stocktaking:${stocktakingId}`);

    channel.on('stocktaking_row_created', stocktakingRow => {
      const { entities } = normalizeStocktakingRow(stocktakingRow);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_STOCKTAKING_STOCKTAKING_ROW_CREATED, payload: stocktakingRow });
    });

    channel.on('stocktaking_row_updated', stocktakingRow => {
      const { entities } = normalizeStocktakingRow(stocktakingRow);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_STOCKTAKING_STOCKTAKING_ROW_UPDATED, payload: stocktakingRow });
    });

    channel.on('stocktaking_row_deleted', stocktakingRow => {
      const { entities } = normalizeStocktakingRow(stocktakingRow);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch({ type: types.WS_STOCKTAKING_STOCKTAKING_ROW_DELETED, payload: stocktakingRow });
    });

    channel.on('spare_part_location_count_increased', data => {
      dispatch({ type: types.WS_STOCKTAKING_SPARE_PART_LOCATION_COUNT_INCREASED, payload: data });
    });

    channel.on('spare_part_location_count_decreased', data => {
      dispatch({ type: types.WS_STOCKTAKING_SPARE_PART_LOCATION_COUNT_DECREASED, payload: data });
    });

    return new Promise((resolve, reject) => {
      channel
        .join()
        .receive('ok', data => {
          const { entities: locationEntities } = normalizeSparePartLocation(data.spare_part_locations);
          const { entities: stocktakingEntities } = normalizeStocktaking(data.stocktaking);
          dispatch(EntityOperations.updateEntities({ ...locationEntities, ...stocktakingEntities }));

          const payload = { stocktakingId, data };
          dispatch({ type: types.WS_STOCKTAKING_JOIN, payload });

          resolve({ data: payload, channel });
        })
        .receive('error', reason => {
          reject({ reason, channel });
        });
    });
  };
};

export default {
  join,
};
