import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PreviewOverlay } from 'views/components/Shared/General';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import PreviewWorkOrderHeader from './PreviewWorkOrderHeader';
import styles from './style.module.scss';

export default class PreviewWorkOrderOverlay extends Component {
  render() {
    return (
      <PreviewOverlay
        open={this.props.open}
        top={this.props.top}
        left={this.props.left}
        extraWidth={this.props.extraWidth}
        width={600}
        onClose={this.props.onClose}
      >
        <div className={styles['preview-work-order']}>
          <PreviewWorkOrderHeader
            workOrderId={this.props.workOrderId}
            recurringMaintenanceId={this.props.recurringMaintenanceId}
            onClose={this.props.onClose}
            onClickBeforeNavigate={this.props.onClickBeforeNavigate}
          />
          <PreviewWorkOrderContent
            editable={this.props.editable}
            workOrderId={this.props.workOrderId}
            recurringMaintenanceId={this.props.recurringMaintenanceId}
            date={this.props.date}
            userIds={this.props.userIds}
            groupIds={this.props.groupIds}
            vendorIds={this.props.vendorIds}
            isSavingDueDate={this.props.isSavingDueDate}
            isSavingEstimatedTime={this.props.isSavingEstimatedTime}
            isSavingAssignees={this.props.isSavingAssignees}
            onSaveAssignees={this.props.onSaveAssignees}
            onSaveDate={this.props.onSaveDate}
            onSaveEstimatedTime={this.props.onSaveEstimatedTime}
            onClose={this.props.onClose}
          />
        </div>
      </PreviewOverlay>
    );
  }
}

PreviewWorkOrderOverlay.propTypes = {
  open: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  extraWidth: PropTypes.number,
  workOrderId: PropTypes.string,
  recurringMaintenanceId: PropTypes.string,
  date: PropTypes.string,
  userIds: PropTypes.array,
  groupIds: PropTypes.array,
  vendorIds: PropTypes.array,
  isSavingDueDate: PropTypes.bool,
  isSavingEstimatedTime: PropTypes.bool,
  isSavingAssignees: PropTypes.bool,
  editable: PropTypes.bool,
  onSaveAssignees: PropTypes.func,
  onSaveDate: PropTypes.func,
  onSaveEstimatedTime: PropTypes.func,
  onClose: PropTypes.func,
  onClickBeforeNavigate: PropTypes.func,
};

PreviewWorkOrderOverlay.defaultProps = {
  workOrderId: null,
  recurringMaintenanceId: null,
  date: null,
  userIds: [],
  groupIds: [],
  vendorIds: [],
  editable: false,
  isSavingDueDate: false,
  isSavingEstimatedTime: false,
  isSavingAssignees: false,
  onSaveAssignees: () => {},
  onSaveDate: () => {},
  onSaveEstimatedTime: () => {},
  onClose: () => {},
  onClickBeforeNavigate: () => {},
};
