import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { ChooseAssetInlineModal } from 'views/components/Asset';

class AssetTypeField extends Component {
  renderEditingField = () => (
    <ChooseAssetInlineModal
      width="300"
      clearable={false}
      trigger={
        <Field.Resource
          value={this.props.asset ? this.props.asset.title : null}
          angleDown
          onClear={() => this.props.onChange(null)}
        />
      }
      selectedAssetId={this.props.value}
      onSelectAsset={assetId => this.props.onChange(assetId)}
    />
  );

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return this.props.asset ? this.props.asset.title : '-';
    }
  };

  render() {
    return (
      <Field view={!this.props.editing} label={<FormattedMessage id="resources.asset.tree-parent" />}>
        {this.renderValue()}
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(AssetTypeField);

AssetTypeField.propTypes = {
  value: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

AssetTypeField.defaultProps = {
  editing: false,
};
