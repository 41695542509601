import React from 'react';
import PropTypes from 'prop-types';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import styles from './style.module.scss';

const FilterBar = props => {
  const getClassNames = () => {
    let classNames = [styles['filter-bar']];
    if (props.noMaxWidth) {
      classNames = [...classNames, styles['full-width']];
    }
    return classNames;
  };
  
  return (
    <div className={getClassNames().join(' ')}>
      <div className={styles['content']}>{props.children}</div>
    </div>
  );
};

export default FilterBar;

FilterBar.LeftContent = LeftContent;
FilterBar.RightContent = RightContent;

FilterBar.propTypes = {
  children: PropTypes.node,
  noMaxWidth: PropTypes.bool,
};

FilterBar.defaultProps = {
  children: null,
  noMaxWidth: false,
};
