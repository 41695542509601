import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorSelectors } from 'state/ducks/error';
import { FieldErrorWrapper } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';

class ConnectedFieldErrorWrapper extends Component {
  render() {
    return (
      <FieldErrorWrapper
        position={this.props.position}
        show={this.props.errorMessage != null}
        errorElement={<FormattedMessage id={this.props.errorMessage} />}
      >
        {this.props.children(this.props.errorMessage != null)}
      </FieldErrorWrapper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    errorMessage: ErrorSelectors.getErrorMessageForField(state, ownProps.field),
  };
}

export default connect(mapStateToProps)(ConnectedFieldErrorWrapper);

ConnectedFieldErrorWrapper.propTypes = {
  position: PropTypes.oneOf(['top', 'left', 'right']),
  field: PropTypes.string,
};

ConnectedFieldErrorWrapper.defaultProps = {
  position: 'left',
  show: false,
};
