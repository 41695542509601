import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { UserNameWrapper } from 'views/components/User';

const RecipientUserContainer = props => <UserNameWrapper user={props.user} />;
function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps, null)(RecipientUserContainer);
