import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NewInlineModal } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class UserDropdownItem extends Component {
  render() {
    return (
      <NewInlineModal.Dropdown.Item
        key={this.props.id}
        selected={this.props.selected}
        leftComponent={
          <ProfilePicture
            userId={this.props.user.id}
            size={20}
            defaultElement={<NewInlineModal.Dropdown.ItemIcon blue icon="user" />}
          />
        }
        onClick={e => this.props.onSelect()}
      >
        <UserNameWrapper user={this.props.user} />
      </NewInlineModal.Dropdown.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserDropdownItem);
