import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { AssigneeDropdown } from 'views/components/Request';
import { normalizeGroup, normalizeUser } from 'sdk/Schemas';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import SelectedAssignee from './SelectedAssignee';
import styles from './style.module.scss';

class NoAssigneeReceiverField extends Component {
  assigneeDropdownInlineModalPositioningRef = null;

  state = {
    showAssigneeDropdown: false,
  };

  valueIsMissingInEntities = () => {
    return (this.props.userId && this.props.user == null) || (this.props.groupId && this.props.group == null);
  };

  componentDidMount() {
    if (this.valueIsMissingInEntities()) {
      Promise.all([
        API.listGroups(this.props.system.id, { type: 'request_assignee', no_pagination: true }),
        API.listUsers(this.props.system.id, {
          archived: false,
          no_pagination: true,
          member: true,
          permission: 'administrate_requests',
        }),
      ]).then(([{ data: groups }, { data: users }]) => {
        const { entities: groupEntities } = normalizeGroup(groups);
        const { entities: userEntities } = normalizeUser(users);
        this.props.updateEntities({ ...groupEntities, ...userEntities });
      });
    }
  }
  render() {
    return (
      <div className={styles['dropdown-container']}>
        <Field
          view={false}
          label={
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.unassigned-request-assignee" />
          }
        >
          <div
            ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showAssigneeDropdown: !prevState.showAssigneeDropdown,
              }));
            }}
          >
            <Field.Resource
              loading={this.valueIsMissingInEntities()}
              value={<SelectedAssignee userId={this.props.userId} groupId={this.props.groupId} />}
              clearable={this.props.userId || this.props.groupId}
              angleDown
              onClear={() => {
                this.props.onChange({
                  request_default_assignee_group_id: null,
                  request_default_assignee_user_id: null,
                });
              }}
            />
          </div>
          <AssigneeDropdown
            positionToRef={this.assigneeDropdownInlineModalPositioningRef}
            open={this.state.showAssigneeDropdown}
            userId={this.props.userId}
            groupId={this.props.groupId}
            onClose={() => this.setState({ showAssigneeDropdown: false })}
            onSelectUser={id => {
              this.setState({ showAssigneeDropdown: false });
              this.props.onChange({
                request_default_assignee_user_id: id,
                request_default_assignee_group_id: null,
              });
            }}
            onSelectGroup={id => {
              this.setState({ showAssigneeDropdown: false });
              this.props.onChange({
                request_default_assignee_group_id: id,
                request_default_assignee_user_id: null,
              });
            }}
            onClear={id => {
              this.setState({ showAssigneeDropdown: false });
              this.props.onChange({
                request_default_assignee_group_id: null,
                request_default_assignee_user_id: null,
              });
            }}
          />
        </Field>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { userId, groupId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    user: EntitySelectors.getUser(state, userId),
    group: EntitySelectors.getGroup(state, groupId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoAssigneeReceiverField);
