import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Field, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import ComputerLogo from 'assets/images/ProScreenshots/calendar-pro-en.png';
import styles from './style.module.scss';

class FeedbackModal extends Component {
  state = {
    neverShowFeedbackAgain: false,
  };

  close = () => {
    if (this.state.neverShowFeedbackAgain) {
      this.props.updateMe({ has_seen_latest_feedback: true, never_show_feedback: true });
      this.props.onClose();
    } else {
      this.props.onClose();
    }
  };

  render() {
    return (
      <Modal width={900} centered isOpen={this.props.open}>
        <div className={styles['feedback-modal']}>
          <div className={styles['modal-title']}>Mätare & Mätar baserade arbetsordrar</div>
          <div className={styles['modal-subtitle']}>
            Vi på AM Service är i startgroparna av att bygga in operatörsunderhåll i AM Service, där
            produktionspersonal ska kunna utföra enklare jobb utan att behöva en fullt betalande licens.
            <br />
            <br />
            För att kunna utveckla det bästa möjliga funtionen och underhållsystem behöver vi din hjälp genom
            att svara på några frågor. Era tankar och åsikter hjälper oss enormt!
          </div>
          <div className={styles['image-container']}>
            <img className={styles['computer-logo']} src={ComputerLogo} alt="Computer" />
          </div>
          <div>
            <Button large primary translate={false} label={<span>Öppna formulär</span>} />
          </div>
          <div className={styles['never-show-feedback-container']}>
            <Field.Checkbox
              label={<span>Visa mig aldrig dessa meddelanden igen</span>}
              checked={this.state.neverShowFeedbackAgain}
              onChange={() => {
                this.setState(prevState => ({
                  neverShowFeedbackAgain: !prevState.neverShowFeedbackAgain,
                }));
              }}
            />
          </div>
          <div className={styles['admin-info-text']}>
            <div className={styles['separator']} />
            <div>Du får detta meddelande eftersom du är registrerad som administratör i AM Service</div>
          </div>
        </div>
        <div className={styles['background-rect']} />
        <div className={styles['close-button']}>
          <Button type="icon" icon={<Icon light type="times" size={18} />} onClick={this.close} />
        </div>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMe: SDKReduxOperations.updateMe,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(FeedbackModal);
