import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';
import { Icon } from 'views/components/Shared/General';
import Dropdown from './Dropdown';
import styles from './style.module.scss';

class MenuButton extends Component {
  componentDidMount() {
    if (this.props.dropdown) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    if (this.props.dropdown) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickOutside = e => {
    const clickedOutside = this.menuButtonContainerRef.contains(e.target) === false;
    if (clickedOutside) {
      if (this.props.onCloseDropdown) {
        this.props.onCloseDropdown(e);
      }
    }
  };

  render() {
    let classNames = [styles['menu-button']];
    const isManagingOrganisation =
      matchPath(this.props.location.pathname, {
        path: '/organisation',
      }) != null;
    if (this.props.selected && isManagingOrganisation === false) {
      classNames = [...classNames, styles['selected']];
    }

    if (this.props.linkTo) {
      return (
        <Link to={this.props.linkTo} className={styles['menu-button-container']} onClick={this.props.onClick}>
          <div className={classNames.join(' ')}>
            <div className={styles['title']}>{this.props.title}</div>
          </div>
        </Link>
      );
    }
    return (
      <div
        className={styles['menu-button-container']}
        ref={ref => (this.menuButtonContainerRef = ref)}
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
      >
        <div className={classNames.join(' ')}>
          <div className={styles['title']}>{this.props.title}</div>
          <div className={styles['caret']}>
            <Icon type="caret-down" white size={12} />
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

MenuButton.Dropdown = Dropdown;

export default withRouter(MenuButton);
