import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { API } from 'sdk';
import toast from 'react-hot-toast';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Section } from 'views/scenes/Settings/components';
import { Button, Field } from 'views/components/Shared/General';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import EditEmailModal from './EditEmailModal';
import RemoveEmailModal from './RemoveEmailModal';
import styles from './style.module.scss';

class ContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSendingEmail: false,
      showEmailModal: false,
      showRemoveEmailModal: false,
      verificationEmailSent: false,
    };
  }

  sendVerificationEmail = () => {
    this.setState({ isSendingEmail: true });
    API.sendVerificationEmail()
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.user.contact-information.verify-toast" />}
          />
        );
        this.setState({ isSendingEmail: false, verificationEmailSent: true });
      })
      .catch(e => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.setState({ isSendingEmail: false });
      });
  };

  renderEmailButtons = () => {
    const { email } = this.props.currentUser;
    const { loginCredential } = this.props;
    return (
      <div className={styles['button-container']}>
        <Button.Group>
          {email ? (
            <Button
              primary
              onClick={() => this.setState({ showEmailModal: true })}
              label="screens.settings.user.contact-information.edit-email"
            />
          ) : (
            <Button
              primary
              onClick={() => this.setState({ showEmailModal: true })}
              label="screens.settings.user.contact-information.add-email"
            />
          )}
          {email && loginCredential == null ? (
            <Button
              primary
              destructive
              onClick={() => this.setState({ showRemoveEmailModal: true })}
              label="screens.settings.user.contact-information.remove-email"
            />
          ) : null}
        </Button.Group>
      </div>
    );
  };

  render() {
    const { email_is_verified, email } = this.props.currentUser;
    return (
      <>
        <Section header title={<FormattedMessage id="screens.settings.user.contact-information.title" />} />
        <Grid>
          {email && email_is_verified === false ? (
            <>
              <Grid.Row>
                <Grid.Column>
                  <div className={styles['verify-email-warning']}>
                    <FormattedMessage id="screens.settings.user.contact-information.verify-email-warning" />
                  </div>
                  {!this.state.verificationEmailSent ? (
                    <Button
                      gray
                      small
                      loading={this.state.isSendingEmail}
                      label="screens.settings.user.contact-information.verify-button"
                      onClick={() => this.sendVerificationEmail()}
                    />
                  ) : (
                    <div className={styles['verify-email-sent']}>
                      <FormattedMessage id="screens.settings.user.contact-information.verify-email-sent" />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
              <div className={styles['separator']} />
            </>
          ) : null}
          <Grid.Row>
            <Grid.Column md={6}>
              <Field label={<FormattedMessage id="resources.user.email" />}>
                <Field.Text
                  disabled
                  value={
                    this.props.currentUser.email ||
                    this.props.intl.formatMessage({
                      id: 'screens.settings.user.contact-information.no-email-label',
                    })
                  }
                />
              </Field>
              {this.renderEmailButtons()}
            </Grid.Column>
            <Grid.Column md={6}>
              <Field label={<FormattedMessage id="resources.user.phone" />}>
                <Field.Text
                  value={this.props.editingUser.phone}
                  onChange={phone => {
                    this.props.onChange({ phone });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <EditEmailModal
          open={this.state.showEmailModal}
          onClose={() => this.setState({ showEmailModal: false })}
        />
        <RemoveEmailModal
          open={this.state.showRemoveEmailModal}
          onClose={() => this.setState({ showRemoveEmailModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const currentUser = AuthSelectors.getCurrentUser(state);
  return {
    currentUser,
    loginCredential: EntitySelectors.getLoginCredential(state, currentUser.login_credential),
  };
}

export default injectIntl(connect(mapStateToProps)(ContactInformation));
