const INITIATE_WITH_STOCKTAKING_ID = 'stocktaking/initiate_with_stocktaking_id';
const FETCH_SPARE_PARTS_FOR_STOCKTAKING_SUCCESS = 'stocktaking/fetch_spare_parts_for_stocktaking_success';
const CLEAR_SPARE_PARTS = 'stocktaking/clear_spare_parts';
const HIDE_COMPLETED_CARD = 'stocktaking/hide_completed_card';
const SET_SPARE_PART_LOADING = 'stocktaking/set_spare_part_loading';
const SET_SPARE_PART_LOADING_MORE = 'stocktaking/set_spare_part_loading_more';

export default {
  INITIATE_WITH_STOCKTAKING_ID,
  FETCH_SPARE_PARTS_FOR_STOCKTAKING_SUCCESS,
  CLEAR_SPARE_PARTS,
  SET_SPARE_PART_LOADING,
  SET_SPARE_PART_LOADING_MORE,
  HIDE_COMPLETED_CARD,
};
