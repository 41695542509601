import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listCosts = (systemId, params, config = null) =>
  request.get(`systems/${systemId}/costs?${HelperFunctions.convertObjToQueryParameters(params)}`, config);
export const createCostForWorkOrder = (workOrderId, data) =>
  request.post(`work_orders/${workOrderId}/costs`, data);
export const updateCost = (costId, data) => request.patch(`costs/${costId}`, data);
export const deleteCost = costId => request.delete(`costs/${costId}`);
export const getReport = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/cost_report?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const getReportSummary = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/cost_report_summary?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
