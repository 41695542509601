import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { Button, WhiteCard } from 'views/components/Shared/General';
import PurchaseOrderUnits from 'views/scenes/Settings/SparePart/Units';
import { EditPurchaseOrderEmailDefaultValuesModal } from 'views/components/PurchaseOrder';
import { Section, Option, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import PaymentTerms from './PaymentTerms';
import DeliveryMethods from './DeliveryMethods';
import EditLatePurchaseOrderModal from './EditLatePurchaseOrderModal';
import EditBillingAddressModal from './EditBillingAddressModal';
import ShippingAdresses from './ShippingAdresses';
import styles from './style.module.scss';

class PurchaseOrder extends Component {
  state = {
    showEditBillingAddressModal: false,
    showEditLatePurchaseOrderModal: false,
    showEditPurchaseOrderDefaultMessageModal: false,
  };

  renderRootSettings = () => (
    <>
      <Section
        header
        title={<FormattedMessage id="screens.settings.purchase-orders.title" />}
        subtitle={<FormattedMessage id="screens.settings.purchase-orders.subtitle" />}
      />
      <Separator dots />
      <Section
        title={<FormattedMessage id="screens.settings.purchase-orders.fields-title" />}
        subtitle={<FormattedMessage id="screens.settings.purchase-orders.fields-subtitle" />}
      />
      <Option.Container>
        <Option
          title={<FormattedMessage id="screens.settings.spare-parts.units.title" />}
          subtitle={<FormattedMessage id="screens.settings.spare-parts.units.subtitle" />}
          rightContainer={
            <>
              <Link to="/settings/purchase-orders/units">
                <Button primary label="general.manage" />
              </Link>
            </>
          }
        />
        <Option
          title={<FormattedMessage id="screens.settings.purchase-orders.payment-terms.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.payment-terms.subtitle" />}
          rightContainer={
            <Link to="/settings/purchase-orders/payment-terms">
              <Button primary label="general.manage" />
            </Link>
          }
        />
        <Option
          title={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.subtitle" />}
          rightContainer={
            <Link to="/settings/purchase-orders/delivery-methods">
              <Button primary label="general.manage" />
            </Link>
          }
        />
        <Option
          title={<FormattedMessage id="screens.settings.purchase-orders.billing-address.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.billing-address.subtitle" />}
          rightContainer={
            <Button
              primary
              label="general.manage"
              onClick={() => this.setState({ showEditBillingAddressModal: true })}
            />
          }
        />
        <Option
          title={<FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.subtitle" />}
          rightContainer={
            <Link to="/settings/purchase-orders/shipping-addresses">
              <Button primary label="general.manage" />
            </Link>
          }
        />
      </Option.Container>
      <div className={styles['options']}>
        <Section title={<FormattedMessage id="screens.settings.purchase-orders.other-title" />} />
        <Option.Container>
          <Option
            title={this.renderEmailMessageTitle()}
            subtitle={
              <FormattedMessage id="screens.settings.purchase-orders.default-email-values.subtitle" />
            }
            rightContainer={
              <Button
                primary
                label="general.manage"
                onClick={() => this.setState({ showEditPurchaseOrderDefaultMessageModal: true })}
              />
            }
          />
          <Option
            title={<FormattedMessage id="screens.settings.purchase-orders.late-delivery.title" />}
            subtitle={<FormattedMessage id="screens.settings.purchase-orders.late-delivery.subtitle" />}
            rightContainer={this.renderLatePurchaseOrderRightContainer()}
          />
        </Option.Container>
      </div>
    </>
  );

  renderLatePurchaseOrderRightContainer = () => {
    if (this.props.system.purchase_order_late_after_days > 0) {
      return (
        <div className={styles['days-container']}>
          <div className={styles['days']}>
            <FormattedPlural
              value={this.props.system.purchase_order_late_after_days}
              one={
                <FormattedMessage
                  id="screens.settings.purchase-orders.late-delivery.amount-of-days-value-one"
                  values={{ days: `+${this.props.system.purchase_order_late_after_days}` }}
                />
              }
              other={
                <FormattedMessage
                  id="screens.settings.purchase-orders.late-delivery.amount-of-days-value-many"
                  values={{ days: `+${this.props.system.purchase_order_late_after_days}` }}
                />
              }
            />
          </div>
          <Button
            primary
            label="general.manage"
            onClick={() => this.setState({ showEditLatePurchaseOrderModal: true })}
          />
        </div>
      );
    }
    return (
      <Button
        primary
        label="general.manage"
        onClick={() => this.setState({ showEditLatePurchaseOrderModal: true })}
      />
    );
  };

  renderEmailMessageTitle = () => {
    if (
      this.props.currentUserSettings.purchase_order_email_subject ||
      this.props.currentUserSettings.purchase_order_email_message
    ) {
      return <FormattedMessage id="screens.settings.purchase-orders.default-email-values.title" />;
    }
    return (
      <>
        <FormattedMessage id="screens.settings.purchase-orders.default-email-values.title" />
        <span className={styles['not-activated']}>
          <span> - </span>
          <FormattedMessage id="screens.settings.purchase-orders.default-email-values.not-configured" />
        </span>
      </>
    );
  };

  render() {
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/purchase-orders" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/purchase-orders/units" component={PurchaseOrderUnits} />
          <Route exact path="/settings/purchase-orders/delivery-methods" component={DeliveryMethods} />
          <Route exact path="/settings/purchase-orders/payment-terms" component={PaymentTerms} />
          <Route exact path="/settings/purchase-orders/shipping-addresses" component={ShippingAdresses} />
        </Switch>
        <EditBillingAddressModal
          open={this.state.showEditBillingAddressModal}
          onClose={() => {
            this.setState({ showEditBillingAddressModal: false });
          }}
        />
        <EditLatePurchaseOrderModal
          open={this.state.showEditLatePurchaseOrderModal}
          onClose={() => {
            this.setState({ showEditLatePurchaseOrderModal: false });
          }}
        />
        <EditPurchaseOrderEmailDefaultValuesModal
          open={this.state.showEditPurchaseOrderDefaultMessageModal}
          onClose={() => {
            this.setState({ showEditPurchaseOrderDefaultMessageModal: false });
          }}
          onSave={() => {
            this.setState({ showEditPurchaseOrderDefaultMessageModal: false });
          }}
        />
      </WhiteCard>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
  };
}

export default connect(mapStateToProps)(PurchaseOrder);
