import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FileUploadWrapper, Tooltip, Icon } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class AddButton extends Component {
  render() {
    let sizeStyle;
    if (this.props.large) {
      sizeStyle = styles['large'];
    } else if (this.props.medium) {
      sizeStyle = styles['medium'];
    } else {
      sizeStyle = styles['small'];
    }

    return (
      <FileUploadWrapper
        image
        accept="image/png, image/jpg, image/jpeg, image/gif"
        onSelectFile={this.props.onAddImage}
      >
        <Tooltip
          trigger={
            <div className={`${styles['add-button']} ${sizeStyle}`}>
              <Icon type="image" />
            </div>
          }
          label={<FormattedMessage id="screens.asset.info.images.add-tooltip" />}
        />
      </FileUploadWrapper>
    );
  }
}

export default AddButton;

AddButton.propTypes = {
  large: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  onAddImage: PropTypes.func.isRequired,
};

AddButton.defaultProps = {
  large: false,
  medium: false,
  small: true,
  onAddImage: () => {},
};
