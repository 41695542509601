import React from 'react';
import styles from './style.module.scss';

export default () => {
  return (
    <div className={styles['drag-handle']}>
      <div className={styles['row']}>
        <div className={styles['dot']} />
        <div className={styles['dot']} />
      </div>
      <div className={styles['row']}>
        <div className={styles['dot']} />
        <div className={styles['dot']} />
      </div>
      <div className={styles['row']}>
        <div className={styles['dot']} />
        <div className={styles['dot']} />
      </div>
    </div>
  );
};
