import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const AssetField = ({ templateField }) => templateField.title;

function mapStateToProps(state, ownProps) {
  return {
    templateField: EntitySelectors.getTemplateField(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AssetField);
