import { schema, normalize, denormalize } from 'normalizr';

/*
  ------------
    SCHEMA DEFINITIONS
  ------------
*/

const assetBookmarkSchema = new schema.Entity('assetBookmarkById');
const attachmentSchema = new schema.Entity('attachmentById');
const attachmentVersionSchema = new schema.Entity('attachmentVersionById');
const attachmentFolderSchema = new schema.Entity('attachmentFolderById');
const billingInvoiceSchema = new schema.Entity('billingInvoiceById');
const billingInvoiceRowSchema = new schema.Entity('billingInvoiceRowById');
const billingAdditionalCostSchema = new schema.Entity('billingAdditionalCostById');
const billingDiscountSchema = new schema.Entity('billingDiscountById');
const billingDiscountsSchema = new schema.Entity('billingDiscountsById');
const checklistDeviationActionSchema = new schema.Entity('checklistDeviationActionById');
const checklistTemplateSchema = new schema.Entity('checklistTemplateById');
const checklistTemplateLinkSchema = new schema.Entity('checklistTemplateLinkById');
const checklistTemplateVersionSchema = new schema.Entity('checklistTemplateVersionById');
const checklistTemplateRowSchema = new schema.Entity('checklistTemplateRowById');
const checklistInstanceSchema = new schema.Entity('checklistInstanceById');
const checklistInstanceOptionSchema = new schema.Entity('checklistInstanceOptionById');
const checklistInstanceRowSchema = new schema.Entity('checklistInstanceRowById');
const contactPersonSchema = new schema.Entity('contactPersonById');
const calendarInstanceSchema = new schema.Entity('calendarInstanceById');
const costSchema = new schema.Entity('costById');
const costOtherCategorySchema = new schema.Entity('costOtherCategoryById');
const groupSchema = new schema.Entity('groupById');
const imageSchema = new schema.Entity('imageById');
const assetSchema = new schema.Entity('assetById');
const assetFieldSchema = new schema.Entity('assetFieldById');
const assetOperatorSchema = new schema.Entity('assetOperatorById');
const assetProductionSupervisorSchema = new schema.Entity('assetProductionSupervisorById');
const assetTypeSchema = new schema.Entity('assetTypeById');
const assetVendorSchema = new schema.Entity('assetVendorById');
const laborTariffSchema = new schema.Entity('laborTariffById');
const loginCredentialSchema = new schema.Entity('loginCredentialById');
const laborTariffScheduleRuleSchema = new schema.Entity('laborTariffScheduleRuleById');
const permissionProfileSchema = new schema.Entity('permissionProfileById');
const purchaseOrderSchema = new schema.Entity('purchaseOrderById');
const purchaseOrderDeliverySchema = new schema.Entity('purchaseOrderDeliveryById');
const purchaseOrderDeliveryRowSchema = new schema.Entity('purchaseOrderDeliveryRowById');
const purchaseOrderRowSchema = new schema.Entity('purchaseOrderRowById');
const purchaseOrderDeliveryMethodSchema = new schema.Entity('purchaseOrderDeliveryMethodById');
const purchaseOrderPaymentTermSchema = new schema.Entity('purchaseOrderPaymentTermById');
const purchaseOrderShippingAddressSchema = new schema.Entity('purchaseOrderShippingAddressById');
const productionBoardActivitySchema = new schema.Entity('productionBoardActivityById');
const recurringMaintenanceSchema = new schema.Entity('recurringMaintenanceById');
const requestSchema = new schema.Entity('requestById');
const meterSchema = new schema.Entity('meterById');
const meterUnitSchema = new schema.Entity('meterUnitById');
const meterReadingSchema = new schema.Entity('meterReadingById');
const requestAutomationRuleSchema = new schema.Entity('requestAutomationRuleById');
const requesSelectableAssigneeSchema = new schema.Entity('requestSelectableAssigneeById');
const requestTypeSchema = new schema.Entity('requestTypeById');
const downtimeSchema = new schema.Entity('downtimeById');
const sparePartSchema = new schema.Entity('sparePartById');
const sparePartStockActivitySchema = new schema.Entity('sparePartStockActivityById');
const sparePartStockAdjustmentSchema = new schema.Entity('sparePartStockAdjustmentById');
const sparePartAssetSchema = new schema.Entity('sparePartAssetById');
const sparePartVendorSchema = new schema.Entity('sparePartVendorById');
const sparePartTypeSchema = new schema.Entity('sparePartTypeById');
const sparePartFieldSchema = new schema.Entity('sparePartFieldById');
const sparePartLocationSchema = new schema.Entity('sparePartLocationById');
const sparePartUnitSchema = new schema.Entity('sparePartUnitById');
const stocktakingSchema = new schema.Entity('stocktakingById');
const stocktakingRowSchema = new schema.Entity('stocktakingRowById');
const systemSchema = new schema.Entity('systemById');
const operationalMaintenanceSchema = new schema.Entity('operationalMaintenanceById');
const operationalMaintenanceBreakSchema = new schema.Entity('operationalMaintenanceBreakById');
const operationalMaintenanceInstanceSchema = new schema.Entity('operationalMaintenanceInstanceById');
const operationalMaintenanceTemplateSchema = new schema.Entity('operationalMaintenanceTemplateById');
const operationalMaintenanceTemplateVersionSchema = new schema.Entity(
  'operationalMaintenanceTemplateVersionById'
);
const operationalMaintenanceTemplateRecurrenceSchema = new schema.Entity(
  'operationalMaintenanceTemplateRecurrenceById'
);
const operationalMaintenanceTemplateRecurrenceVersionSchema = new schema.Entity(
  'operationalMaintenanceTemplateRecurrenceVersionById'
);
const operationalMaintenanceTemplateAssetSchema = new schema.Entity(
  'operationalMaintenanceTemplateAssetById'
);
const systemAccessSchema = new schema.Entity('systemAccessById');
const organisationSchema = new schema.Entity('organisationById');
const settingsSchema = new schema.Entity('settingsById');
const organisationSettingsSchema = new schema.Entity('organisationSettingsById');
const userSettingsSchema = new schema.Entity('userSettingsById');
const templateFieldSchema = new schema.Entity('templateFieldById');
const templateFieldItemSchema = new schema.Entity('templateFieldItemById');
const userSchema = new schema.Entity('userById');
const vendorSchema = new schema.Entity('vendorById');
const downtimeReasonSchema = new schema.Entity('downtimeReasonById');
const workOrderSchema = new schema.Entity('workOrderById');
const workOrderPurchaseSchema = new schema.Entity('workOrderPurchaseById');
const workOrderTypeSchema = new schema.Entity('workOrderTypeById');
const workOrderSpentTimeSchema = new schema.Entity('workOrderSpentTimeById');
const workOrderSpentTimeCategorySchema = new schema.Entity('workOrderSpentTimeCategoryById');
const sparePartWithdrawalSchema = new schema.Entity('sparePartWithdrawalById');
const sparePartReservationSchema = new schema.Entity('sparePartReservationById');
const workOrderActivitySchema = new schema.Entity('workOrderActivityById');
const notificationSchema = new schema.Entity('notificationById');
const exportSchema = new schema.Entity('exportById');
const qrTemplateSchema = new schema.Entity('qrTemplateById');
const qrTemplateColumnSchema = new schema.Entity('qrTemplateColumnById');
const qrTemplateRowSchema = new schema.Entity('qrTemplateRowById');

attachmentVersionSchema.define({
  created_by_user: userSchema,
});

attachmentSchema.define({
  attachment_version: attachmentVersionSchema,
  asset: assetSchema,
  spare_part: sparePartSchema,
  registration_work_order: workOrderSchema,
  completion_work_order: workOrderSchema,
  vendor: vendorSchema,
});

assetSchema.define({
  asset_vendors: [assetVendorSchema],
  spare_part_assets: [sparePartAssetSchema],
  asset_fields: [assetFieldSchema],
  asset_type: assetTypeSchema,
  template_fields: [templateFieldSchema],
  asset_production_supervisors: [assetProductionSupervisorSchema],
  tree_path: [assetSchema],
  duplicated_children: [assetSchema],
  images: [imageSchema],
  attachments: [attachmentSchema],
  active_downtime: downtimeSchema,
  meters: [meterSchema],
  archived_by_user: userSchema,
  production_supervisor_user: userSchema,
});

assetBookmarkSchema.define({
  asset: assetSchema,
});

assetVendorSchema.define({
  asset: assetSchema,
  vendor: vendorSchema,
  contact_person: contactPersonSchema,
});

laborTariffSchema.define({
  labor_tariff_schedule_rules: [laborTariffScheduleRuleSchema],
});

costSchema.define({
  work_order: workOrderSchema,
  asset: assetSchema,
  work_order_spent_time: workOrderSpentTimeSchema,
  spare_part_withdrawal: sparePartWithdrawalSchema,
  downtime: downtimeSchema,
  vendor: vendorSchema,
  cost_other_category: costOtherCategorySchema,
});

checklistTemplateSchema.define({
  draft_version: checklistTemplateVersionSchema,
  active_version: checklistTemplateVersionSchema,
});

checklistTemplateLinkSchema.define({
  checklist_template: checklistTemplateSchema,
  work_order: workOrderSchema,
  recurring_maintenance: recurringMaintenanceSchema,
  checklist_instance_options: [checklistInstanceOptionSchema],
});

checklistTemplateVersionSchema.define({
  checklist_template: checklistTemplateSchema,
  checklist_template_rows: [checklistTemplateRowSchema],
});

checklistTemplateRowSchema.define({
  meter: meterSchema,
  images: [imageSchema],
  attachments: [attachmentSchema],
});

checklistInstanceSchema.define({
  checklist_instance_rows: [checklistInstanceRowSchema],
  checklist_template_version: checklistTemplateVersionSchema,
  operational_maintenance_instance: operationalMaintenanceInstanceSchema,
  work_order: workOrderSchema,
  deviation_assigned_to_users: [userSchema],
  checklist_instance_options: [checklistInstanceOptionSchema],
});

checklistInstanceOptionSchema.define({
  meter: meterSchema,
  checklist_template_row: checklistTemplateRowSchema,
});

checklistInstanceRowSchema.define({
  images: [imageSchema],
  updated_by_user: userSchema,
  meter_reading: meterReadingSchema,
  checklist_deviation_actions: [checklistDeviationActionSchema],
});

checklistDeviationActionSchema.define({
  request: requestSchema,
  work_order: workOrderSchema,
  checklist_instance: checklistInstanceSchema,
  checklist_instance_rows: [checklistInstanceRowSchema],
});

downtimeSchema.define({
  asset: assetSchema,
  work_orders: [workOrderSchema],
  created_by_user: userSchema,
  downtime_reason: downtimeReasonSchema,
  requests: [requestSchema],
});

loginCredentialSchema.define({});

recurringMaintenanceSchema.define({
  asset: assetSchema,
  registration_attachments: [attachmentSchema],
  images: [imageSchema],
  generated_work_order: workOrderSchema,
  assigned_to_users: [userSchema],
  assigned_to_groups: [groupSchema],
  assigned_to_vendors: [vendorSchema],
  created_by_user: userSchema,
  work_order_type: workOrderTypeSchema,
  spare_part_reservations: [sparePartReservationSchema],
  checklist_template_links: [checklistTemplateLinkSchema],
  reminder_recipient_user: userSchema,
  meter: meterSchema,
});

requestAutomationRuleSchema.define({
  assign_user: userSchema,
  assign_group: groupSchema,
  assets: [assetSchema],
  asset_types: [assetTypeSchema],
  request_types: [requestTypeSchema],
});

purchaseOrderRowSchema.define({
  spare_part: sparePartSchema,
  spare_part_unit: sparePartUnitSchema,
  purchase_order: purchaseOrderSchema,
  spare_part_vendor: sparePartVendorSchema,
});

purchaseOrderDeliveryRowSchema.define({
  purchase_order_row: purchaseOrderRowSchema,
  purchase_order: purchaseOrderSchema,
  purchase_order_delivery: purchaseOrderDeliverySchema,
  spare_part: sparePartSchema,
});

purchaseOrderDeliverySchema.define({
  created_by_user: userSchema,
  purchase_order_delivery_rows: [purchaseOrderDeliveryRowSchema],
  purchase_order: purchaseOrderSchema,
});

productionBoardActivitySchema.define({
  request: requestSchema,
  asset: requestSchema,
  created_by_user: userSchema,
  work_order: workOrderSchema,
  operational_maintenance_instance: operationalMaintenanceInstanceSchema,
  downtime: downtimeSchema,
});

calendarInstanceSchema.define({
  users: [userSchema],
  groups: [groupSchema],
  vendors: [vendorSchema],
  work_order: workOrderSchema,
  recurring_maintenance: recurringMaintenanceSchema,
});

purchaseOrderSchema.define({
  vendor: vendorSchema,
  our_reference_user: userSchema,
  vendor_reference_contact_person: contactPersonSchema,
  purchase_order_payment_term: purchaseOrderPaymentTermSchema,
  purchase_order_delivery_method: purchaseOrderDeliveryMethodSchema,
  purchase_order_rows: [purchaseOrderRowSchema],
  purchase_order_deliveries: [purchaseOrderDeliverySchema],
});

requestSchema.define({
  asset: assetSchema,
  created_by_user: userSchema,
  archived_by_user: userSchema,
  assigned_to_user: userSchema,
  assigned_to_group: groupSchema,
  downtime: downtimeSchema,
  work_order: workOrderSchema,
  images: [imageSchema],
  request_type: requestTypeSchema,
  created_from_checklist_deviation_action: checklistDeviationActionSchema,
  created_from_work_order: workOrderSchema,
  approved_by_production_supervisor_user: userSchema,
});

requesSelectableAssigneeSchema.define({
  group: groupSchema,
  user: userSchema,
});

sparePartStockAdjustmentSchema.define({
  created_by_user: userSchema,
  spare_part: sparePartSchema,
});

sparePartStockActivitySchema.define({
  created_by_user: userSchema,
  purchase_order_delivery_row: purchaseOrderDeliveryRowSchema,
  spare_part_withdrawal: sparePartWithdrawalSchema,
  stocktaking_row: stocktakingRowSchema,
  spare_part_stock_adjustment: sparePartStockAdjustmentSchema,
});

sparePartSchema.define({
  spare_part_type: sparePartTypeSchema,
  spare_part_unit: sparePartUnitSchema,
  images: [imageSchema],
  template_fields: [templateFieldSchema],
  spare_part_fields: [sparePartFieldSchema],
  spare_part_location: sparePartLocationSchema,
  /*
    -----
      Returned on POST SparePart. Define in schema to normalize correctly.
    -----
  */
  spare_part_vendors: [sparePartVendorSchema],
  spare_part_assets: [sparePartAssetSchema],
  active_purchase_order_rows: [purchaseOrderRowSchema],
  primary_vendor: vendorSchema,
  spare_part_vendors_for_vendor: [sparePartVendorSchema],
});

sparePartAssetSchema.define({
  asset: assetSchema,
  spare_part: sparePartSchema,
});

sparePartVendorSchema.define({
  spare_part: sparePartSchema,
  vendor: vendorSchema,
});

sparePartTypeSchema.define({
  path: [sparePartTypeSchema],
});

sparePartLocationSchema.define({
  path: [sparePartLocationSchema],
});

sparePartUnitSchema.define({});

stocktakingSchema.define({
  created_by_user: userSchema,
  completed_by_user: userSchema,
});

stocktakingRowSchema.define({
  stocktaking: stocktakingSchema,
  spare_part: sparePartSchema,
  created_by_user: userSchema,
});

templateFieldSchema.define({
  template_field_items: [templateFieldItemSchema],
});

templateFieldSchema.define({
  template_field_items: [templateFieldItemSchema],
});

userSchema.define({
  groups: [groupSchema],
  system: systemSchema,
  system_access: systemAccessSchema,
  system_accesses: [systemAccessSchema],
  permission_profile: permissionProfileSchema,
  login_credential: loginCredentialSchema,
  organisation: organisationSchema,
  asset_operators: [assetOperatorSchema],
  asset_production_supervisors: [assetOperatorSchema],
});

assetOperatorSchema.define({
  user: userSchema,
  asset: assetSchema,
});

assetProductionSupervisorSchema.define({
  user: userSchema,
  asset: assetSchema,
});

vendorSchema.define({
  contact_persons: [contactPersonSchema],
  attachments: [attachmentSchema],
  asset_vendors: [assetVendorSchema],
  spare_part_vendors_for_spare_part: [sparePartVendorSchema],
  spare_part_vendors: [sparePartVendorSchema],
});

sparePartWithdrawalSchema.define({
  created_by_user: userSchema,
  spare_part: sparePartSchema,
  work_order: workOrderSchema,
  asset: assetSchema,
  cost: costSchema,
  operational_maintenance_instance: operationalMaintenanceInstanceSchema,
});

sparePartReservationSchema.define({
  recurring_maintenance: recurringMaintenanceSchema,
  spare_part: sparePartSchema,
  work_order: workOrderSchema,
});

settingsSchema.define({
  system: systemSchema,
});

userSettingsSchema.define({
  system: systemSchema,
  user: userSchema,
});

systemSchema.define({
  billing_discounts: [billingDiscountSchema],
  system_access: systemAccessSchema,
});

systemAccessSchema.define({
  permission_profile: permissionProfileSchema,
  system: systemSchema,
});

workOrderSpentTimeSchema.define({
  user: userSchema,
  vendor: vendorSchema,
  work_order_spent_time_category: workOrderSpentTimeCategorySchema,
  cost: costSchema,
  images: [imageSchema],
});

workOrderSchema.define({
  asset: assetSchema,
  recurring_maintenance: recurringMaintenanceSchema,
  in_progress_by_user: userSchema,
  paused_by_user: userSchema,
  completed_by_user: userSchema,
  work_order_type: workOrderTypeSchema,
  assigned_to_users: [userSchema],
  assigned_to_groups: [groupSchema],
  assigned_to_vendors: [vendorSchema],
  work_order_activities: [workOrderActivitySchema],
  created_by_user: userSchema,
  registration_attachments: [attachmentSchema],
  completion_attachments: [attachmentSchema],
  registration_images: [imageSchema],
  completion_images: [imageSchema],
  work_order_spent_times: [workOrderSpentTimeSchema],
  spare_part_withdrawals: [sparePartWithdrawalSchema],
  spare_part_reservations: [sparePartReservationSchema],
  costs: [costSchema],
  downtimes: [downtimeSchema],
  downtime_cost: costSchema,
  work_order_purchases: [workOrderPurchaseSchema],
  checklist_instances: [checklistInstanceSchema],
  meter: meterSchema,
  reminder_recipient_user: userSchema,
  checklist_template_links: [checklistTemplateLinkSchema],
  created_from_checklist_deviation_action: checklistDeviationActionSchema,
  created_from_requests: [requestSchema],
  created_from_work_order: workOrderSchema,
  created_work_orders: [workOrderSchema],
  created_requests: [requestSchema],
});

workOrderPurchaseSchema.define({
  purchase_order: purchaseOrderSchema,
  work_order: workOrderSchema,
});

workOrderActivitySchema.define({
  asset: assetSchema,
  work_order_type: workOrderTypeSchema,
  assigned_to_user: userSchema,
  assigned_to_group: groupSchema,
  assigned_to_vendor: vendorSchema,
  created_by_user: userSchema,
  completed_by_user: userSchema,
});

operationalMaintenanceTemplateVersionSchema.define({
  checklist_template_links: [checklistTemplateLinkSchema],
  attachments: [attachmentSchema],
  images: [imageSchema],
  spare_part_reservations: [sparePartReservationSchema],
  created_by_user: userSchema,
});

operationalMaintenanceTemplateAssetSchema.define({
  asset: assetSchema,
  operational_maintenance_template_recurrences: [operationalMaintenanceTemplateRecurrenceSchema],
  checklist_instance_options: [checklistInstanceOptionSchema],
});

operationalMaintenanceTemplateRecurrenceSchema.define({
  operational_maintenance_template_asset: operationalMaintenanceTemplateAssetSchema,
  operational_maintenance_template_recurrence_versions: [
    operationalMaintenanceTemplateRecurrenceVersionSchema,
  ],
});

operationalMaintenanceTemplateSchema.define({
  operational_maintenance_template_asset: operationalMaintenanceTemplateAssetSchema,
  operational_maintenance_template_assets: [operationalMaintenanceTemplateAssetSchema],
  operational_maintenance_template_versions: [operationalMaintenanceTemplateVersionSchema],
});

operationalMaintenanceSchema.define({
  operational_maintenance_template: operationalMaintenanceTemplateSchema,
  operational_maintenance_template_version: operationalMaintenanceTemplateVersionSchema,
  operational_maintenance_template_recurrence: operationalMaintenanceTemplateRecurrenceSchema,
  operational_maintenance_template_recurrence_version: operationalMaintenanceTemplateRecurrenceVersionSchema,
  operational_maintenance_instance: operationalMaintenanceInstanceSchema,
});

operationalMaintenanceBreakSchema.define({
  asset: assetSchema,
});

operationalMaintenanceInstanceSchema.define({
  checklist_instances: [checklistInstanceSchema],
  asset: assetSchema,
  operational_maintenance_template: operationalMaintenanceTemplateSchema,
  operational_maintenance_template_version: operationalMaintenanceTemplateVersionSchema,
  operational_maintenance_template_recurrence: operationalMaintenanceTemplateRecurrenceSchema,
  operational_maintenance_template_recurrence_version: operationalMaintenanceTemplateRecurrenceVersionSchema,
  operational_maintenance_template_asset: operationalMaintenanceTemplateAssetSchema,
  completed_by_user: userSchema,
  skipped_by_user: userSchema,
  spare_part_withdrawals: [sparePartWithdrawalSchema],
});

notificationSchema.define({
  work_order: workOrderSchema,
  request: requestSchema,
});

billingInvoiceSchema.define({
  system: systemSchema,
  billing_invoice_rows: [billingInvoiceRowSchema],
});

billingAdditionalCostSchema.define({
  system: systemSchema,
  billing_system: systemSchema,
});

billingDiscountSchema.define({
  system: systemSchema,
  billing_discounts: [billingDiscountsSchema],
});

meterUnitSchema.define({});

meterSchema.define({
  asset: assetSchema,
  meter_unit: meterUnitSchema,
});

meterReadingSchema.define({
  added_by_user: userSchema,
});

qrTemplateSchema.define({
  qr_template_columns: [qrTemplateColumnSchema],
});

qrTemplateColumnSchema.define({
  qr_template_rows: [qrTemplateRowSchema],
});

/*
  ------------
    Attachment
  ------------
*/
function normalizeAttachment(data) {
  return normalize(data, Array.isArray(data) ? [attachmentSchema] : attachmentSchema);
}

function denormalizeAttachment(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [attachmentSchema] : attachmentSchema, entities);
}

/*
  ------------
    AttachmentVersion
  ------------
*/
function normalizeAttachmentVersion(data) {
  return normalize(data, Array.isArray(data) ? [attachmentVersionSchema] : attachmentVersionSchema);
}

function denormalizeAttachmentVersion(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [attachmentVersionSchema] : attachmentVersionSchema, entities);
}

/*
  ------------
    AttachmentFolder
  ------------
*/
function normalizeAttachmentFolder(data) {
  return normalize(data, Array.isArray(data) ? [attachmentFolderSchema] : attachmentFolderSchema);
}

function denormalizeAttachmentFolder(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [attachmentFolderSchema] : attachmentFolderSchema, entities);
}

/*
  ------------
    Billing Invoice
  ------------
*/
function normalizeBillingInvoice(data) {
  return normalize(data, Array.isArray(data) ? [billingInvoiceSchema] : billingInvoiceSchema);
}

function denormalizeBillingInvoice(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [billingInvoiceSchema] : billingInvoiceSchema, entities);
}

/*
  ------------
    Billing Additional cost
  ------------
*/
function normalizeBillingAdditionalCost(data) {
  return normalize(data, Array.isArray(data) ? [billingAdditionalCostSchema] : billingAdditionalCostSchema);
}

/*
  ------------
    Billing Discount
  ------------
*/
function normalizeBillingDiscount(data) {
  return normalize(data, Array.isArray(data) ? [billingDiscountSchema] : billingDiscountSchema);
}

/*
  ------------
    Calendar instance
  ------------
*/

function normalizeCalendarInstance(data) {
  return normalize(data, Array.isArray(data) ? [calendarInstanceSchema] : calendarInstanceSchema);
}

/*

/*
  -----------
    Checklist Deviation Action
  -----------
*/

function normalizeChecklistDeviationAction(data) {
  return normalize(
    data,
    Array.isArray(data) ? [checklistDeviationActionSchema] : checklistDeviationActionSchema
  );
}

/*
  -----------
    Checklist instance
  -----------
*/

function normalizeChecklistInstance(data) {
  return normalize(data, Array.isArray(data) ? [checklistInstanceSchema] : checklistInstanceSchema);
}

/*
  -----------
    Checklist Instance Option
  -----------
*/

function normalizeChecklistInstanceOption(data) {
  return normalize(
    data,
    Array.isArray(data) ? [checklistInstanceOptionSchema] : checklistInstanceOptionSchema
  );
}

/*
  -----------
    Checklist Instance Row
  -----------
*/

function normalizeChecklistInstanceRow(data) {
  return normalize(data, Array.isArray(data) ? [checklistInstanceRowSchema] : checklistInstanceRowSchema);
}

/*
  ------------
    ContactPerson
  ------------
*/

function normalizeContactPerson(data) {
  return normalize(data, Array.isArray(data) ? [contactPersonSchema] : contactPersonSchema);
}

function denormalizeContactPerson(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [contactPersonSchema] : contactPersonSchema, entities);
}

/*
  ------------
    Group
  ------------
*/

function normalizeGroup(data) {
  return normalize(data, Array.isArray(data) ? [groupSchema] : groupSchema);
}

function denormalizeGroup(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [groupSchema] : groupSchema, entities);
}

/*
  ------------
    Image
  ------------
*/

function normalizeImage(data) {
  return normalize(data, Array.isArray(data) ? [imageSchema] : imageSchema);
}

/*
  ------------
    Notification
  ------------
*/

function normalizeNotification(data) {
  return normalize(data, Array.isArray(data) ? [notificationSchema] : notificationSchema);
}

function denormalizeNotification(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [notificationSchema] : notificationSchema, entities);
}

/*
  ------------
    Asset
  ------------
*/

function normalizeAsset(data) {
  return normalize(data, Array.isArray(data) ? [assetSchema] : assetSchema);
}

function denormalizeAsset(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [assetSchema] : assetSchema, entities);
}

/*
  ------------
    Asset Operator
  ------------
*/

function normalizeAssetOperator(data) {
  return normalize(data, Array.isArray(data) ? [assetOperatorSchema] : assetOperatorSchema);
}

/*
  ------------
    Asset Operator
  ------------
*/

function normalizeAssetProductionSupervisor(data) {
  return normalize(
    data,
    Array.isArray(data) ? [assetProductionSupervisorSchema] : assetProductionSupervisorSchema
  );
}

/*
  ------------
    AssetBookmark
  ------------
*/

function normalizeAssetBookmark(data) {
  return normalize(data, Array.isArray(data) ? [assetBookmarkSchema] : assetBookmarkSchema);
}

/*
  ------------
    Costs
  ------------
*/

function normalizeCosts(data) {
  return normalize(data, Array.isArray(data) ? [costSchema] : costSchema);
}

/*
  ------------
    Costs
  ------------
*/

function normalizeCostOtherCategory(data) {
  return normalize(data, Array.isArray(data) ? [costOtherCategorySchema] : costOtherCategorySchema);
}

/*
  ------------
    Checklist Template
  ------------
*/

function normalizeChecklistTemplate(data) {
  return normalize(data, Array.isArray(data) ? [checklistTemplateSchema] : checklistTemplateSchema);
}

/*
  ------------
    Checklist Template Link
  ------------
*/

function normalizeChecklistTemplateLink(data) {
  return normalize(data, Array.isArray(data) ? [checklistTemplateLinkSchema] : checklistTemplateLinkSchema);
}

/*
  ------------
    Checklist Template Version
  ------------
*/

function normalizeChecklistTemplateVersion(data) {
  return normalize(
    data,
    Array.isArray(data) ? [checklistTemplateVersionSchema] : checklistTemplateVersionSchema
  );
}

/*
  ------------
    Checklist Template Row
  ------------
*/

function normalizeChecklistTemplateRow(data) {
  return normalize(data, Array.isArray(data) ? [checklistTemplateRowSchema] : checklistTemplateRowSchema);
}

/*
  ------------
    Downtime Category
  ------------
*/

function normalizeDowntimeReason(data) {
  return normalize(data, Array.isArray(data) ? [downtimeReasonSchema] : downtimeReasonSchema);
}

/*
  ------------
    AssetField
  ------------
*/

function normalizeAssetField(data) {
  return normalize(data, Array.isArray(data) ? [assetFieldSchema] : assetFieldSchema);
}

function denormalizeAssetField(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [assetFieldSchema] : assetFieldSchema, entities);
}

/*
  ------------
    Asset Type
  ------------
*/

function normalizeAssetType(data) {
  return normalize(data, Array.isArray(data) ? [assetTypeSchema] : assetTypeSchema);
}

function denormalizeAssetType(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [assetTypeSchema] : assetTypeSchema, entities);
}

/*
  ------------
    AssetVendor
  ------------
*/

function normalizeAssetVendor(data) {
  return normalize(data, Array.isArray(data) ? [assetVendorSchema] : assetVendorSchema);
}

function denormalizeAssetVendor(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [assetVendorSchema] : assetVendorSchema, entities);
}

/*
  ------------
    Export
  ------------
*/

function normalizeExport(data) {
  return normalize(data, Array.isArray(data) ? [exportSchema] : exportSchema);
}

/*
  ------------
    DownTime
  ------------
*/

function normalizeLoginCredential(data) {
  return normalize(data, Array.isArray(data) ? [loginCredentialSchema] : loginCredentialSchema);
}

function normalizeDowntime(data) {
  return normalize(data, Array.isArray(data) ? [downtimeSchema] : downtimeSchema);
}

/*
  ------------
    Meter
  ------------
*/

function normalizeMeter(data) {
  return normalize(data, Array.isArray(data) ? [meterSchema] : meterSchema);
}

function normalizeMeterUnit(data) {
  return normalize(data, Array.isArray(data) ? [meterUnitSchema] : meterUnitSchema);
}

function normalizeMeterReading(data) {
  return normalize(data, Array.isArray(data) ? [meterReadingSchema] : meterReadingSchema);
}

/*
  ------------
    PermissionProfile
  ------------
*/

function normalizePermissionProfile(data) {
  return normalize(data, Array.isArray(data) ? [permissionProfileSchema] : permissionProfileSchema);
}

function denormalizePermissionProfile(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [permissionProfileSchema] : permissionProfileSchema, entities);
}

/*
  ------------
    PurchaseOrderPaymentTermSchema
  ------------
*/

function normalizePurchaseOrderPaymentTerm(data) {
  return normalize(
    data,
    Array.isArray(data) ? [purchaseOrderPaymentTermSchema] : purchaseOrderPaymentTermSchema
  );
}

/*
  ------------
    ProductionBoardActivitySchema
  ------------
*/

function normalizeProductionBoardActivity(data) {
  return normalize(
    data,
    Array.isArray(data) ? [productionBoardActivitySchema] : productionBoardActivitySchema
  );
}

/*
  ------------
    PurchaseOrderDeliveryMethodSchema
  ------------
*/

function normalizePurchaseOrderDeliveryMethod(data) {
  return normalize(
    data,
    Array.isArray(data) ? [purchaseOrderDeliveryMethodSchema] : purchaseOrderDeliveryMethodSchema
  );
}

/*
  ------------
    PurchaseOrderShippingAddressSchema
  ------------
*/

function normalizePurchaseOrderShippingAddress(data) {
  return normalize(
    data,
    Array.isArray(data) ? [purchaseOrderShippingAddressSchema] : purchaseOrderShippingAddressSchema
  );
}

/*
  ------------
    PurchaseOrderSchema
  ------------
*/

function normalizePurchaseOrder(data) {
  return normalize(data, Array.isArray(data) ? [purchaseOrderSchema] : purchaseOrderSchema);
}

/*
  ------------
    PurchaseOrderDeliverySchema
  ------------
*/

function normalizePurchaseOrderDelivery(data) {
  return normalize(data, Array.isArray(data) ? [purchaseOrderDeliverySchema] : purchaseOrderDeliverySchema);
}

/*
  ------------
    PurchaseOrderDeliveryRowSchema
  ------------
*/

function normalizePurchaseOrderDeliveryRow(data) {
  return normalize(
    data,
    Array.isArray(data) ? [purchaseOrderDeliveryRowSchema] : purchaseOrderDeliveryRowSchema
  );
}

/*
  ------------
    PurchaseOrderRowSchema
  ------------
*/

function normalizePurchaseOrderRow(data) {
  return normalize(data, Array.isArray(data) ? [purchaseOrderRowSchema] : purchaseOrderRowSchema);
}

/*
  ------------
    RecurringMaintenance
  ------------
*/

function normalizeRecurringMaintenance(data) {
  return normalize(data, Array.isArray(data) ? [recurringMaintenanceSchema] : recurringMaintenanceSchema);
}

function denormalizeRecurringMaintenance(ids, entities) {
  return denormalize(ids, recurringMaintenanceSchema, entities);
}

/*
  ------------
    Request
  ------------
*/

function normalizeRequest(data) {
  return normalize(data, Array.isArray(data) ? [requestSchema] : requestSchema);
}

function denormalizeRequest(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [requestSchema] : requestSchema, entities);
}

/*
  ------------
    Request Automation Rule
  ------------
*/

function normalizeRequestAutomationRule(data) {
  return normalize(data, Array.isArray(data) ? [requestAutomationRuleSchema] : requestAutomationRuleSchema);
}

/*
  ------------
    Request Selectable Assignee
  ------------
*/

function normalizeRequestSelectableAssigneee(data) {
  return normalize(
    data,
    Array.isArray(data) ? [requesSelectableAssigneeSchema] : requesSelectableAssigneeSchema
  );
}

/*
  ------------
    RequestType
  ------------
*/

function normalizeRequestType(data) {
  return normalize(data, Array.isArray(data) ? [requestTypeSchema] : requestTypeSchema);
}

function denormalizeRequestType(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [requestTypeSchema] : requestTypeSchema, entities);
}

/*
  ------------
    Spare Part
  ------------
*/

function normalizeSparePart(data) {
  return normalize(data, Array.isArray(data) ? [sparePartSchema] : sparePartSchema);
}

/*
  ------------
    Spare Part Stock Activity
  ------------
*/

function normalizeSparePartStockActivity(data) {
  return normalize(data, Array.isArray(data) ? [sparePartStockActivitySchema] : sparePartStockActivitySchema);
}

//wihu
function normalizeSparePartStockAdjustment(data) {
  return normalize(
    data,
    Array.isArray(data) ? [sparePartStockAdjustmentSchema] : sparePartStockAdjustmentSchema
  );
}

/*
  ------------
    Spare Part Asset
  ------------
*/

function normalizeSparePartAsset(data) {
  return normalize(data, Array.isArray(data) ? [sparePartAssetSchema] : sparePartAssetSchema);
}

/*
  ------------
    Spare Part Vendor
  ------------
*/

function normalizeSparePartVendor(data) {
  return normalize(data, Array.isArray(data) ? [sparePartVendorSchema] : sparePartVendorSchema);
}

/*
  ------------
    Spare Part Type
  ------------
*/

function normalizeSparePartType(data) {
  return normalize(data, Array.isArray(data) ? [sparePartTypeSchema] : sparePartTypeSchema);
}

/*
  ------------
    Spare Part Location
  ------------
*/

function normalizeSparePartLocation(data) {
  return normalize(data, Array.isArray(data) ? [sparePartLocationSchema] : sparePartLocationSchema);
}

/*
 ------------
 Spare Part Unit
 ------------
 */

function normalizeSparePartUnit(data) {
  return normalize(data, Array.isArray(data) ? [sparePartUnitSchema] : sparePartUnitSchema);
}

/*
 ------------
 Labor Tariff
 ------------
 */

function normalizeLaborTariff(data) {
  return normalize(data, Array.isArray(data) ? [laborTariffSchema] : laborTariffSchema);
}

/*
 ------------
 Labor Tariff ScheduleRule
 ------------
 */

function normalizeLaborTariffScheduleRule(data) {
  return normalize(
    data,
    Array.isArray(data) ? [laborTariffScheduleRuleSchema] : laborTariffScheduleRuleSchema
  );
}

/*
  ------------
    Org
  ------------
*/

function normalizeOrganisation(data) {
  return normalize(data, Array.isArray(data) ? [organisationSchema] : organisationSchema);
}

/*
 ------------
 Settings
 ------------
 */

function normalizeSettings(data) {
  return normalize(data, Array.isArray(data) ? [settingsSchema] : settingsSchema);
}

/*
 ------------
 Organisation Settings
 ------------
 */

function normalizeOrganisationSettings(data) {
  return normalize(data, Array.isArray(data) ? [organisationSettingsSchema] : organisationSettingsSchema);
}

/*
 ------------
 User Settings
 ------------
 */

function normalizeUserSettings(data) {
  return normalize(data, Array.isArray(data) ? [userSettingsSchema] : userSettingsSchema);
}

/*
 ------------
 Spare Part Reservation
 ------------
 */

function normalizeSparePartReservation(data) {
  return normalize(data, Array.isArray(data) ? [sparePartReservationSchema] : sparePartReservationSchema);
}

/*
 ------------
 Spare Part Withdrawal
 ------------
 */

function normalizeSparePartWithdrawal(data) {
  return normalize(data, Array.isArray(data) ? [sparePartWithdrawalSchema] : sparePartWithdrawalSchema);
}

/*
 ------------
 Stocktaking
 ------------
 */

function normalizeStocktaking(data) {
  return normalize(data, Array.isArray(data) ? [stocktakingSchema] : stocktakingSchema);
}

/*
 ------------
 Stocktaking Row
 ------------
 */

function normalizeStocktakingRow(data) {
  return normalize(data, Array.isArray(data) ? [stocktakingRowSchema] : stocktakingRowSchema);
}

/*
  ------------
    System
  ------------
*/

function normalizeSystem(data) {
  return normalize(data, Array.isArray(data) ? [systemSchema] : systemSchema);
}

function denormalizeSystem(ids, entities) {
  return denormalize(ids, [systemSchema], entities);
}

/*
  ------------
    System Access
  ------------
*/

function normalizeSystemAccess(data) {
  return normalize(data, Array.isArray(data) ? [systemAccessSchema] : systemAccessSchema);
}

/*
  ------------
    TemplateField
  ------------
*/

function normalizeTemplateField(data) {
  return normalize(data, Array.isArray(data) ? [templateFieldSchema] : templateFieldSchema);
}

function denormalizeTemplateField(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [templateFieldSchema] : templateFieldSchema, entities);
}

/*
  ------------
    TemplateFieldItem
  ------------
*/

function normalizeTemplateFieldItem(data) {
  return normalize(data, Array.isArray(data) ? [templateFieldItemSchema] : templateFieldItemSchema);
}

function denormalizeTemplateFieldItem(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [templateFieldItemSchema] : templateFieldItemSchema, entities);
}

/*
  ------------
    User
  ------------
*/

function normalizeUser(data) {
  return normalize(data, Array.isArray(data) ? [userSchema] : userSchema);
}

function denormalizeUser(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [userSchema] : userSchema, entities);
}

/*
  ------------
    OperationalMaintennace
  ------------
*/

function normalizeOperationalMaintenance(data) {
  return normalize(data, Array.isArray(data) ? [operationalMaintenanceSchema] : operationalMaintenanceSchema);
}

/*
  ------------
    OperationalMaintennaceBreak
  ------------
*/

function normalizeOperationalMaintenanceBreak(data) {
  return normalize(
    data,
    Array.isArray(data) ? [operationalMaintenanceBreakSchema] : operationalMaintenanceBreakSchema
  );
}

/*
  ------------
    OperationalMaintennaceInstance
  ------------
*/

function normalizeOperationalMaintenanceInstance(data) {
  return normalize(
    data,
    Array.isArray(data) ? [operationalMaintenanceInstanceSchema] : operationalMaintenanceInstanceSchema
  );
}

/*
  ------------
    OperationalMaintennaceTemplate
  ------------
*/

function normalizeOperationalMaintenanceTemplate(data) {
  return normalize(
    data,
    Array.isArray(data) ? [operationalMaintenanceTemplateSchema] : operationalMaintenanceTemplateSchema
  );
}

/*
  ------------
    operationalMaintenanceTemplateVersionSchema
  ------------
*/

function normalizeOperationalMaintenanceTemplateVersion(data) {
  return normalize(
    data,
    Array.isArray(data)
      ? [operationalMaintenanceTemplateVersionSchema]
      : operationalMaintenanceTemplateVersionSchema
  );
}

/*
  ------------
    operationalMaintenanceTemplateRecurrenceSchema
  ------------
*/

function normalizeOperationalMaintenanceTemplateRecurrence(data) {
  return normalize(
    data,
    Array.isArray(data)
      ? [operationalMaintenanceTemplateRecurrenceSchema]
      : operationalMaintenanceTemplateRecurrenceSchema
  );
}

/*
  ------------
    operationalMaintenanceTemplateRecurrenceVersionSchema
  ------------
*/

function normalizeOperationalMaintenanceTemplateRecurrenceVersion(data) {
  return normalize(
    data,
    Array.isArray(data)
      ? [operationalMaintenanceTemplateRecurrenceVersionSchema]
      : operationalMaintenanceTemplateRecurrenceVersionSchema
  );
}

/*
  ------------
    operationalMaintenanceTemplateAssetSchema
  ------------
*/

function normalizeOperationalMaintenanceTemplateAsset(data) {
  return normalize(
    data,
    Array.isArray(data)
      ? [operationalMaintenanceTemplateAssetSchema]
      : operationalMaintenanceTemplateAssetSchema
  );
}

/*
  ------------
    Vendor
  ------------
*/

function normalizeVendor(data) {
  return normalize(data, Array.isArray(data) ? [vendorSchema] : vendorSchema);
}

function denormalizeVendor(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [vendorSchema] : vendorSchema, entities);
}

/*
  ------------
    WorkOrder
  ------------
*/

function normalizeWorkOrder(data) {
  return normalize(data, Array.isArray(data) ? [workOrderSchema] : workOrderSchema);
}

function denormalizeWorkOrder(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [workOrderSchema] : workOrderSchema, entities);
}

/*
  ------------
    WorkOrderType
  ------------
*/

function normalizeWorkOrderType(data) {
  return normalize(data, Array.isArray(data) ? [workOrderTypeSchema] : workOrderTypeSchema);
}

function denormalizeWorkOrderType(ids, entities) {
  return denormalize(ids, Array.isArray(ids) ? [workOrderTypeSchema] : workOrderTypeSchema, entities);
}

/*
  ------------
    WorkOrderSpentTime
  ------------
*/

function normalizeWorkOrderSpentTime(data) {
  return normalize(data, Array.isArray(data) ? [workOrderSpentTimeSchema] : workOrderSpentTimeSchema);
}

/*
  ------------
    WorkOrderSpentTime
  ------------
*/

function normalizeWorkOrderSpentTimeCategory(data) {
  return normalize(
    data,
    Array.isArray(data) ? [workOrderSpentTimeCategorySchema] : workOrderSpentTimeCategorySchema
  );
}

/*
  ------------
    WorkOrderActivity
  ------------
*/

function normalizeWorkOrderActivity(data) {
  return normalize(data, Array.isArray(data) ? [workOrderActivitySchema] : workOrderActivitySchema);
}

/*
  ------------
    WorkOrderActivity
  ------------
*/

function normalizeWorkOrderPurchase(data) {
  return normalize(data, Array.isArray(data) ? [workOrderPurchaseSchema] : workOrderPurchaseSchema);
}

/*
  ------------
    Qr Template
  ------------
*/

function normalizeQrTemplate(data) {
  return normalize(data, Array.isArray(data) ? [qrTemplateSchema] : qrTemplateSchema);
}

function normalizeQrColumnTemplate(data) {
  return normalize(data, Array.isArray(data) ? [qrTemplateColumnSchema] : qrTemplateColumnSchema);
}

function normalizeQrRowTemplate(data) {
  return normalize(data, Array.isArray(data) ? [qrTemplateRowSchema] : qrTemplateRowSchema);
}

export {
  normalizeAttachment,
  denormalizeAttachment,
  normalizeAttachmentVersion,
  denormalizeAttachmentVersion,
  normalizeAttachmentFolder,
  denormalizeAttachmentFolder,
  normalizeBillingInvoice,
  denormalizeBillingInvoice,
  normalizeBillingAdditionalCost,
  normalizeBillingDiscount,
  normalizeChecklistDeviationAction,
  normalizeChecklistTemplateLink,
  normalizeChecklistInstance,
  normalizeChecklistInstanceRow,
  normalizeChecklistInstanceOption,
  normalizeChecklistTemplate,
  normalizeChecklistTemplateVersion,
  normalizeChecklistTemplateRow,
  normalizeContactPerson,
  normalizeCalendarInstance,
  normalizeCosts,
  normalizeCostOtherCategory,
  normalizeDowntimeReason,
  denormalizeContactPerson,
  normalizeGroup,
  denormalizeGroup,
  normalizeImage,
  normalizeNotification,
  denormalizeNotification,
  normalizeAsset,
  normalizeAssetOperator,
  normalizeAssetProductionSupervisor,
  normalizeAssetBookmark,
  denormalizeAsset,
  normalizeAssetField,
  denormalizeAssetField,
  normalizeAssetType,
  denormalizeAssetType,
  normalizeAssetVendor,
  denormalizeAssetVendor,
  normalizeDowntime,
  normalizeMeter,
  normalizeMeterUnit,
  normalizeMeterReading,
  normalizePermissionProfile,
  normalizePurchaseOrder,
  normalizePurchaseOrderDelivery,
  normalizePurchaseOrderDeliveryRow,
  normalizePurchaseOrderRow,
  normalizePurchaseOrderPaymentTerm,
  normalizePurchaseOrderDeliveryMethod,
  normalizePurchaseOrderShippingAddress,
  normalizeProductionBoardActivity,
  denormalizePermissionProfile,
  normalizeRecurringMaintenance,
  denormalizeRecurringMaintenance,
  normalizeRequest,
  normalizeRequestAutomationRule,
  normalizeRequestSelectableAssigneee,
  denormalizeRequest,
  normalizeRequestType,
  normalizeLaborTariff,
  normalizeLaborTariffScheduleRule,
  denormalizeRequestType,
  normalizeSparePartVendor,
  normalizeSettings,
  normalizeUserSettings,
  normalizeOrganisationSettings,
  normalizeSparePart,
  normalizeSparePartStockActivity,
  normalizeSparePartStockAdjustment,
  normalizeSparePartAsset,
  normalizeSparePartType,
  normalizeSparePartLocation,
  normalizeSparePartUnit,
  normalizeSparePartReservation,
  normalizeSparePartWithdrawal,
  normalizeStocktaking,
  normalizeStocktakingRow,
  normalizeLoginCredential,
  normalizeOrganisation,
  normalizeSystem,
  normalizeSystemAccess,
  normalizeOperationalMaintenance,
  normalizeOperationalMaintenanceInstance,
  normalizeOperationalMaintenanceBreak,
  normalizeOperationalMaintenanceTemplate,
  normalizeOperationalMaintenanceTemplateVersion,
  normalizeOperationalMaintenanceTemplateRecurrence,
  normalizeOperationalMaintenanceTemplateRecurrenceVersion,
  normalizeOperationalMaintenanceTemplateAsset,
  denormalizeSystem,
  normalizeTemplateField,
  denormalizeTemplateField,
  normalizeTemplateFieldItem,
  denormalizeTemplateFieldItem,
  normalizeUser,
  denormalizeUser,
  normalizeVendor,
  denormalizeVendor,
  normalizeWorkOrder,
  denormalizeWorkOrder,
  normalizeWorkOrderType,
  normalizeWorkOrderSpentTime,
  normalizeWorkOrderSpentTimeCategory,
  normalizeWorkOrderPurchase,
  denormalizeWorkOrderType,
  normalizeWorkOrderActivity,
  normalizeExport,
  normalizeQrTemplate,
  normalizeQrColumnTemplate,
  normalizeQrRowTemplate,
};
