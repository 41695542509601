import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment-timezone';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Image } from 'views/components/Image';
import {
  Button,
  Icon,
  InlineModal,
  ViewTextArea,
  Tooltip,
  FullScreenImagePicker,
  ConfirmModal,
} from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { SelectedAssignees } from 'views/components/WorkOrder';
import { RequestStatus } from 'sdk/Request';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import styles from './style.module.scss';

class Status extends Component {
  state = {
    currentImage: 0,
    showingImageDialog: false,
    showTooltipInlineModal: false,
    showConfirmRemoveFromWorkOrder: false,
    isRemovingFromWorkOrder: false,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderWorkOrderCreatedSubtitle = () => {
    const { assigned_to_users, assigned_to_groups, assigned_to_vendors, due_date } = this.props.workOrder;

    if (!assigned_to_users || !assigned_to_groups || !assigned_to_vendors) return null;

    const hasAssignees =
      assigned_to_users.length > 0 || assigned_to_groups.length > 0 || assigned_to_vendors.length > 0;

    if (due_date && hasAssignees) {
      return (
        <FormattedMessage
          id="screens.requests.status-subtitles.work-order-planned"
          values={{
            date: moment(this.props.workOrder.due_date).format('LL'),
            assignees: (
              <SelectedAssignees
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            ),
          }}
        />
      );
    } else if (due_date) {
      return (
        <FormattedMessage
          id="screens.requests.status-subtitles.work-order-planned-no-assignee"
          values={{
            date: moment(this.props.workOrder.due_date).format('LL'),
          }}
        />
      );
    } else if (hasAssignees) {
      return (
        <FormattedMessage
          id="screens.requests.status-subtitles.work-order-planned-no-date"
          values={{
            assignees: (
              <SelectedAssignees
                userIds={this.props.workOrder.assigned_to_users}
                groupIds={this.props.workOrder.assigned_to_groups}
                vendorIds={this.props.workOrder.assigned_to_vendors}
              />
            ),
          }}
        />
      );
    }
    return <span>-</span>;
  };

  renderConfirmRemoveFromWorkOrderModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmRemoveFromWorkOrder}
        title={<FormattedMessage id="screens.requests.remove-from-existing-work-order-confirm-modal.title" />}
        message={
          <FormattedMessage id="screens.requests.remove-from-existing-work-order-confirm-modal.message" />
        }
        confirmButtonText="screens.requests.remove-from-existing-work-order-confirm-modal.remove-button"
        confirmIsLoading={this.state.isRemovingFromWorkOrder}
        onConfirm={() => this.removeRequestFromWorkOrder()}
        onCancel={() => {
          this.setState({ showConfirmRemoveFromWorkOrder: false, showTooltipInlineModal: false });
        }}
      />
    );
  };

  removeRequestFromWorkOrder = () => {
    this.setState({ isRemovingFromWorkOrder: true });
    this.props
      .updateRequest(this.props.request.id, { work_order_id: null })
      .then(() => {
        this.setState({
          showTooltipInlineModal: false,
          showConfirmRemoveFromWorkOrder: false,
          isRemovingFromWorkOrder: false,
        });
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.requests.remove-from-existing-work-order-confirm-modal.success" />
            }
          />
        );
      })
      .catch(() => {
        this.setState({ isRemovingFromWorkOrder: false });
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
      });
  };

  renderToolTipInlineModalContent = () => {
    return (
      <InlineModal.Body width={250} dropdown>
        <InlineModal.ListItem
          title={<FormattedMessage id="screens.requests.remove-from-work-order" />}
          onClick={() =>
            this.setState({ showConfirmRemoveFromWorkOrder: true, showTooltipInlineModal: false })
          }
        />
      </InlineModal.Body>
    );
  };

  renderTooltip = () => {
    const active = this.props.request.status === RequestStatus.Active;
    const archived = this.props.request.status === RequestStatus.Archived;
    const awaitingApproval = this.props.request.status === RequestStatus.AwaitingApproval;
    if (active || archived || awaitingApproval) return null;
    if (!this.props.canAdministrateRequests) return null;
    return (
      <div>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showTooltipInlineModal: !prevState.showTooltipInlineModal,
            }));
          }}
        >
          <Button type="icon" icon={<Icon light size={24} type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showTooltipInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showTooltipInlineModal: false })}
          position="right"
        >
          {this.renderToolTipInlineModalContent()}
        </InlineModal>
      </div>
    );
  };

  renderIcon = () => {
    if (this.props.request.status === RequestStatus.Archived) {
      return <Icon type="archive" red withBackground backgroundSize={30} />;
    } else if (this.props.request.status === RequestStatus.AwaitingApproval) {
      return <Icon type="user" red withBackground backgroundSize={30} />;
    } else if (this.props.request.status === RequestStatus.Completed) {
      return <Icon regular type="check" size={16} green withBackground backgroundSize={30} />;
    } else if (this.props.request.status === RequestStatus.WorkOrderCreated) {
      if (this.props.workOrder.status === WorkOrderStatus.Paused) {
        return <Icon type="pause" gray withBackground backgroundSize={30} />;
      } else if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
        return <Icon type="wrench" orange withBackground backgroundSize={30} />;
      } else if (moment(this.props.workOrder.due_date).isBefore(moment(), 'day')) {
        return <Icon type="wrench" red withBackground backgroundSize={30} />;
      } else {
        return <Icon type="wrench" withBackground backgroundSize={30} />;
      }
    }
  };

  renderTitle = () => {
    if (this.props.request.status === RequestStatus.Archived) {
      return (
        <span className={styles['archived']}>
          <FormattedMessage id="resources.request.statuses.archived" />
        </span>
      );
    } else if (this.props.request.status === RequestStatus.AwaitingApproval) {
      return (
        <span>
          <FormattedMessage id="resources.request.statuses.awaiting-production-supervisor-approval" />
        </span>
      );
    } else if (this.props.request.status === RequestStatus.Completed) {
      return (
        <span className={styles['completed']}>
          <FormattedMessage id="resources.request.statuses.completed" />
        </span>
      );
    } else if (this.props.request.status === RequestStatus.WorkOrderCreated) {
      if (this.props.workOrder.status === WorkOrderStatus.Paused) {
        return <FormattedMessage id="screens.requests.work-order-statuses.paused" />;
      } else if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
        return (
          <span className={styles['in-progress']}>
            <FormattedMessage id="screens.requests.work-order-statuses.in-progress" />
          </span>
        );
      } else if (moment(this.props.workOrder.due_date).isBefore(moment(), 'day')) {
        return (
          <span className={styles['overdue']}>
            <FormattedMessage id="screens.requests.work-order-statuses.overdue" />
          </span>
        );
      } else {
        return <FormattedMessage id="screens.requests.work-order-statuses.planned" />;
      }
    }
  };

  renderSubtitle = () => {
    if (this.props.request.status === RequestStatus.Archived) {
      return (
        <div className={styles['subtitle']}>
          <FormattedMessage
            id="screens.requests.status-subtitles.archived"
            values={{
              date: moment(this.props.request.archived_at).format('LL'),
              user: <UserNameWrapper user={this.props.archivedByUser} />,
            }}
          />
        </div>
      );
    } else if (this.props.request.status === RequestStatus.Completed) {
      return (
        <div className={styles['subtitle']}>
          <FormattedMessage
            id="screens.requests.status-subtitles.work-order-completed"
            values={{
              date: moment(this.props.workOrder.completed_date).format('LL'),
              user: <UserNameWrapper user={this.props.workOrderCompletedByUser} />,
            }}
          />
        </div>
      );
    } else if (this.props.request.status === RequestStatus.WorkOrderCreated) {
      if (this.props.workOrder.status === WorkOrderStatus.Paused) {
        return (
          <div className={styles['subtitle']}>
            <FormattedMessage
              id="screens.requests.status-subtitles.work-order-paused"
              values={{
                date: moment(this.props.workOrder.paused_at).format('LL'),
                user: <UserNameWrapper user={this.props.workOrderPausedByUser} />,
              }}
            />
          </div>
        );
      } else if (this.props.workOrder.status === WorkOrderStatus.InProgress) {
        return (
          <div className={styles['subtitle']}>
            <FormattedMessage
              id="screens.requests.status-subtitles.work-order-in-progress"
              values={{
                timeAgo: moment(this.props.workOrder.in_progress_at).fromNow(),
                user: <UserNameWrapper user={this.props.workOrderInProgressByUser} />,
              }}
            />
          </div>
        );
      } else {
        return <div className={styles['subtitle']}>{this.renderWorkOrderCreatedSubtitle()}</div>;
      }
    }
    return null;
  };

  renderAdditionalArchivedData = () => {
    const { request } = this.props;
    if (request.archived_comment) {
      return (
        <div className={styles['comment']}>
          <ViewTextArea>{request.archived_comment}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  renderAdditionalWorkOrderCreatedData = () => {
    const { workOrder } = this.props;
    if (workOrder.status === WorkOrderStatus.Paused && workOrder.paused_comment) {
      return (
        <div className={styles['comment']}>
          <ViewTextArea>{workOrder.paused_comment}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  renderCompletedWorkOrderImages = () => {
    if (this.props.workOrderCompletionImages == null) {
      return null;
    }
    if (this.props.workOrderCompletionImages.length === 0) {
      return null;
    }
    return (
      <div className={styles['images-container']}>
        {this.props.workOrderCompletionImages.map((image, index) => {
          return (
            <Image
              medium
              loading={false}
              editable={false}
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
            />
          );
        })}
      </div>
    );
  };

  renderAdditionalWorkOrderCompletedData = () => {
    const { workOrder } = this.props;
    if (workOrder.status === WorkOrderStatus.Completed) {
      return (
        <>
          {workOrder.completed_comment ? (
            <div className={styles['comment']}>
              <ViewTextArea>{workOrder.completed_comment}</ViewTextArea>
            </div>
          ) : null}
          {this.renderCompletedWorkOrderImages()}
        </>
      );
    }
    return null;
  };

  renderAdditionalData = () => {
    const { request } = this.props;
    if (request.status === RequestStatus.Archived) {
      return this.renderAdditionalArchivedData();
    }
    if (request.status === RequestStatus.WorkOrderCreated) {
      return this.renderAdditionalWorkOrderCreatedData();
    }
    if (request.status === RequestStatus.Completed) {
      return this.renderAdditionalWorkOrderCompletedData();
    }
    return null;
  };

  renderRightButton = () => {
    let goToWorkOrder =
      this.props.request.status === RequestStatus.WorkOrderCreated ||
      this.props.request.status === RequestStatus.Completed;

    if (goToWorkOrder && !this.props.isOperator) {
      return (
        <div>
          <Tooltip
            trigger={
              <Link to={`/work-orders/${this.props.workOrder.id}`}>
                <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
              </Link>
            }
            label={<FormattedMessage id="general.navigate-to.work-order" />}
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const { request_production_supervisor_approval_activated } = this.props.settings;
    const statusWorkOrderCreated = this.props.request.status === RequestStatus.WorkOrderCreated;
    const statusWorkOrderCompleted = this.props.request.status === RequestStatus.Completed;
    const awaitingApproval = this.props.request.status === RequestStatus.AwaitingApproval;
    if (this.props.request.status === RequestStatus.Active) return null;
    if (awaitingApproval && !request_production_supervisor_approval_activated) return null;
    if ((statusWorkOrderCreated || statusWorkOrderCompleted) && this.props.workOrder == null) return null;

    return (
      <>
        <div className={styles['detail']}>
          <div className={styles['white-card']}>
            <div className={styles['content']}>
              <div className={styles['status-wrapper']}>
                <div className={styles['icon-container']}>{this.renderIcon()}</div>
                <div className={styles['status-container']}>
                  <div className={styles['title']}>{this.renderTitle()}</div>
                  {this.renderSubtitle()}
                </div>
              </div>
              {this.renderTooltip()}
              {this.renderRightButton()}
            </div>
            {this.renderAdditionalData()}
          </div>
        </div>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.workOrderCompletionImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        {this.renderConfirmRemoveFromWorkOrderModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRequest: SDKReduxOperations.updateRequest,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.request.work_order_id);
  if (workOrder && !workOrder._deleted) {
    return {
      workOrder,
      workOrderCompletedByUser: EntitySelectors.getUser(state, workOrder.completed_by_user_id),
      workOrderInProgressByUser: EntitySelectors.getUser(state, workOrder.in_progress_by_user_id),
      workOrderPausedByUser: EntitySelectors.getUser(state, workOrder.paused_by_user_id),
      workOrderCompletionImages: EntitySelectors.getImages(state, workOrder.completion_images),
      canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
      isOperator: AuthSelectors.isOperator(state),
      settings: AuthSelectors.getSettings(state),
    };
  }

  return {
    archivedByUser: EntitySelectors.getUser(state, ownProps.request.archived_by_user_id),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    isOperator: AuthSelectors.isOperator(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);
