import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import Default from './default.svg';

class ProfilePicture extends Component {
  render() {
    const { user, singleInitital } = this.props;
    if (user && user.profile_picture_image_id) {
      const backgroundImage = `${process.env.REACT_APP_BROWSER_URL}images/${user.profile_picture_image_id}`;
      const style = {
        backgroundImage: `url(${backgroundImage})`,
        width: `${this.props.size}px`,
        height: `${this.props.size}px`,
        borderRadius: this.props.size / 2,
      };
      return <div className={styles['image']} style={style} />;
    } else if (user) {
      if (this.props.defaultElement) return this.props.defaultElement;

      const initials = user.name
        .trim()
        .split(' ')
        .slice(0, singleInitital ? 1 : 2)
        .map(v => v.charAt(0))
        .join('');

      let style = {
        width: `${this.props.size}px`,
        height: `${this.props.size}px`,
        borderRadius: this.props.size / 2,
      };

      let textStyle = {
        fontSize: this.props.fontSize,
      };

      return (
        <div className={styles['initials-container']} style={style}>
          <div className={styles['initials']} style={textStyle}>
            {initials}
          </div>
        </div>
      );
    } else {
      if (this.props.defaultElement) return this.props.defaultElement;
      return <img src={Default} alt="" width={this.props.size} height={this.props.size} />;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.userId),
  };
}

export default connect(mapStateToProps, null)(ProfilePicture);

ProfilePicture.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number,
  fontSize: PropTypes.number,
  singleInitital: PropTypes.bool,
  defaultElement: PropTypes.element,
  userId: PropTypes.string,
};

ProfilePicture.defaultProps = {
  size: 22,
  fontSize: 11,
  singleInitital: false,
  userId: null,
};
