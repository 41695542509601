import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './styles.module.scss';

class EditLaborCostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      labor_cost_per_hour: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        labor_cost_per_hour: this.props.vendor.labor_cost_per_hour,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.labor_cost_per_hour) {
      this.updateVendor();
    }
  };

  updateVendor = () => {
    this.setState({ isSaving: true });
    const { labor_cost_per_hour } = this.state;
    this.props.updateVendor(this.props.vendor.id, { labor_cost_per_hour }).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({ isSaving: false });
      this.props.onClose();
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.vendor.labor-cost.edit-labor-cost-modal.title" />}
          subtitle={<FormattedMessage id="screens.vendor.labor-cost.edit-labor-cost-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div className={styles['field-container']}>
            <Field
              view={false}
              label={<FormattedMessage id="screens.vendor.labor-cost.edit-labor-cost-modal.cost" />}
            >
              <Field.Money
                value={this.state.labor_cost_per_hour}
                currency={this.props.currentSystem.currency}
                onChange={labor_cost_per_hour => {
                  this.setState({ labor_cost_per_hour });
                }}
                onBlur={labor_cost_per_hour => {
                  this.setState({ labor_cost_per_hour });
                }}
              />
            </Field>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.updateVendor} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditLaborCostModal));
