import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

class StartPurchaseOrderForVendorModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderMessage = () => {
    if (this.props.vendor) {
      return (
        <FormattedMessage
          id="screens.should-order-spare-parts.start-purchase-order-for-vendor-modal.text"
          values={{
            vendor: <span className={styles['vendor']}>{this.props.vendor.name}</span>,
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={455}>
        <Modal.Header
          ignoreLine
          title={
            <FormattedMessage id="screens.should-order-spare-parts.start-purchase-order-for-vendor-modal.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderMessage()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.props.onSave} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

export default connect(mapStateToProps)(StartPurchaseOrderForVendorModal);
