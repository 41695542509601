import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { NewTab, Icon } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ChecklistInstanceAssigneeDropdown } from 'views/components/Checklist';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class TabsContent extends Component {
  state = {
    showAssigneeDropdown: false,
    isChangingAssignedForUserIds: [],
    isClearing: false,
  };

  getNewAssignedToUserIds = userId => {
    const alreadyAssigned = this.props.checklistInstance.deviation_assigned_to_users;
    if (alreadyAssigned.includes(userId)) {
      return alreadyAssigned.filter(id => id !== userId);
    } else {
      return [...alreadyAssigned, userId];
    }
  };

  handleUserClick = id => {
    const newAssignedToUserIds = this.getNewAssignedToUserIds(id);
    this.setState({ isChangingAssignedForUserIds: [...this.state.isChangingAssignedForUserIds, id] });
    this.updateChecklistInstance({ deviation_assigned_to_users: newAssignedToUserIds }).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({
        isChangingAssignedForUserIds: this.state.isChangingAssignedForUserIds.filter(userId => userId !== id),
        isClearing: false,
      });
    });
  };

  updateChecklistInstance = data => {
    return this.props.updateChecklistInstance(this.props.checklistInstance.id, data);
  };

  renderAssigneeDropdown = () => {
    return (
      <ChecklistInstanceAssigneeDropdown
        position="right"
        positionToRef={this.assigneeDropdownInlineModalPositioningRef}
        open={this.state.showAssigneeDropdown}
        userIds={this.props.checklistInstance.deviation_assigned_to_users}
        isLoadingUserIds={this.state.isChangingAssignedForUserIds}
        isClearing={this.state.isClearing}
        onClose={() => this.setState({ showAssigneeDropdown: false })}
        onSelectUser={this.handleUserClick}
        onClear={() => {
          this.setState({
            isClearing: true,
            isChangingAssignedForUserIds: [],
          });
          this.updateChecklistInstance({ deviation_assigned_to_users: null }).then(() => {
            this.setState({ isClearing: false });
          });
        }}
      />
    );
  };

  renderAssignees = () => {
    if (!this.props.checklistInstance.deviation_assigned_to_users.length) {
      return <FormattedMessage id="components.checklist-instance-modal.deviations.no-assignee" />;
    }
    if (this.props.assignedToUsers.length === 1) {
      return <UserNameWrapper user={this.props.assignedToUsers[0]} />;
    }
    return (
      <FormattedPlural
        value={this.props.checklistInstance.deviation_assigned_to_users.length}
        one={
          <FormattedMessage
            id="components.checklist-instance-modal.amount-of-users.one"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        two={
          <FormattedMessage
            id="components.checklist-instance-modal.amount-of-users.two"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        few={
          <FormattedMessage
            id="components.checklist-instance-modal.amount-of-users.few"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        many={
          <FormattedMessage
            id="components.checklist-instance-modal.amount-of-users.many"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
        other={
          <FormattedMessage
            id="components.checklist-instance-modal.amount-of-users.other"
            values={{ amount: this.props.checklistInstance.deviation_assigned_to_users.length }}
          />
        }
      />
    );
  };

  renderAssigneeIcon = () => {
    if (this.props.assignedToUsers.length === 1) {
      return <ProfilePicture userId={this.props.assignedToUsers[0].id} size={26} />;
    }
    return <Icon type="user" withBackground blue backgroundSize={26} size={12} />;
  };

  render() {
    return (
      <>
        <div className={styles['tab-container']}>
          <NewTab.Container>
            <NewTab
              selected={this.props.deviationTab === 'require_action'}
              onClick={() => this.props.selectDeviationTab('require_action')}
            >
              <FormattedMessage id="components.checklist-instance-modal.deviations.tabs.handle" />
            </NewTab>
            <NewTab
              selected={this.props.deviationTab === 'all'}
              onClick={() => this.props.selectDeviationTab('all')}
            >
              <FormattedMessage id="components.checklist-instance-modal.deviations.tabs.all" />
            </NewTab>
          </NewTab.Container>
        </div>
        <div
          className={styles['assignee']}
          ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showAssigneeDropdown: !prevState.showAssigneeDropdown,
            }));
          }}
        >
          <div className={styles['icon-container']}>{this.renderAssigneeIcon()}</div>
          <div className={styles['field']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.checklist-instance.assignee" />
            </div>
            <div className={styles['value-container']}>
              <div className={styles['value']}>{this.renderAssignees()}</div>
              <div className={styles['dropdown-icon']}>
                <Icon type="caret-down" />
              </div>
            </div>
          </div>
        </div>
        {this.renderAssigneeDropdown()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectDeviationTab: ChecklistModalOperations.selectDeviationTab,
      updateChecklistInstance: SDKReduxOperations.updateChecklistInstance,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  return {
    checklistInstance,
    assignedToUsers: EntitySelectors.getUsers(state, checklistInstance.deviation_assigned_to_users),
    deviationTab: ChecklistModalSelectors.getSelectedDeviationTab(state),
    rowsWithDeviations: ChecklistModalSelectors.getRootTemplateFieldIds(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContent);
