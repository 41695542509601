import React, { Component } from 'react';
import { API } from 'sdk';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewInlineModal, EmptyDataSet, Button, Field, Icon } from 'views/components/Shared/General';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import SelectSparePartLocationButton from './SelectSparePartLocationButton';
import styles from './style.module.scss';

class ChooseSparePartLocationInlineModal extends Component {
  getInitialState = () => ({
    isOpen: false,
    searchTerm: '',
    isFetching: true,
    parentLocationId: null,
    selectedSparePartLocationId: null,
    sparePartLocations: [],
    rootSparePartLocations: [],
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState(
        {
          ...this.getInitialState(),
          selectedSparePartLocationId: this.props.selectedSparePartLocationId,
          isFetching: true,
          isOpen: true,
        },
        () => {
          API.listSparePartLocations(this.props.currentSystem.id)
            .then(({ data: sparePartLocationData }) => {
              const { entities, result } = normalizeSparePartLocation(sparePartLocationData);
              this.props.updateEntities(entities);
              const sparePartLocations = result.map(id => entities.sparePartLocationById[id]);
              const rootSparePartLocations = sparePartLocations.filter(
                spl => spl.spare_part_location_parent_id == null
              );
              this.setState({
                sparePartLocations,
                rootSparePartLocations,
                isFetching: false,
                isEmpty: rootSparePartLocations.length === 0,
              });
            })
            .catch(() => {
              this.setState({ isFetching: false });
            });
        }
      );
    }
  };

  navigateInTree = parentLocationId => {
    this.setState({ parentLocationId });
  };

  selectSparePartLocation = locationId => {
    if (this.props.multiple) {
      if (this.props.selectedSparePartLocationIds.includes(locationId)) {
        this.props.onRemoveSparePartLocation(locationId);
      } else {
        this.props.onAddSparePartLocation(locationId);
      }
    } else {
      if (this.state.searchTerm.length > 0) {
        this.setState(
          {
            isOpen: false,
          },
          () => {
            this.props.onSelectSparePartLocation(locationId);
          }
        );
      } else {
        this.setState({
          selectedSparePartLocationId: locationId,
        });
      }
    }
  };

  hasChildren = sparePartLocation => {
    return (
      this.state.sparePartLocations.find(
        ({ spare_part_location_parent_id }) => spare_part_location_parent_id === sparePartLocation.id
      ) != null
    );
  };

  renderSearchEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      tiny
      inlineModalContainer
    />
  );

  renderGoBackInTreeButton = () => {
    if (this.state.parentLocationId) {
      const sparePartLocation = this.state.sparePartLocations.find(
        ({ id }) => id === this.state.parentLocationId
      );
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => this.navigateInTree(sparePartLocation.spare_part_location_parent_id)}
          leftComponent={
            <div className={styles['go-back-button']}>
              <Button
                type="icon"
                icon={<Icon regular size={18} type="angle-left" />}
                onClick={e => {
                  e.stopPropagation();
                  this.navigateInTree(sparePartLocation.spare_part_location_parent_id);
                }}
              />
            </div>
          }
        >
          <span className={styles['go-back-text']}>{sparePartLocation.title}</span>
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderTreeListItem = sparePartLocation => {
    if (this.props.multiple) {
      const selected = this.props.selectedSparePartLocationIds.includes(sparePartLocation.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartLocation.id}
          onClick={e => this.selectSparePartLocation(sparePartLocation.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectSparePartLocation(sparePartLocation.id);
              }}
            />
          }
          rightComponent={
            this.hasChildren(sparePartLocation) ? (
              <div className={styles['navigate-in-tree-button']}>
                <Button
                  type="icon"
                  icon={<Icon regular size={18} type="angle-right" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.navigateInTree(sparePartLocation.id);
                  }}
                />
              </div>
            ) : null
          }
        >
          <span>{sparePartLocation.title}</span>
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.state.selectedSparePartLocationId === sparePartLocation.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartLocation.id}
          selected={selected}
          onClick={e => this.selectSparePartLocation(sparePartLocation.id)}
          onDoubleClick={() => {
            this.navigateInTree(sparePartLocation.id);
          }}
          rightComponent={
            this.hasChildren(sparePartLocation) ? (
              <div className={styles['navigate-in-tree-button']}>
                <Button
                  type="icon"
                  icon={<Icon regular size={18} type="angle-right" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.navigateInTree(sparePartLocation.id);
                  }}
                />
              </div>
            ) : null
          }
        >
          <span>{sparePartLocation.title}</span>
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderSearchedListItem = sparePartLocation => {
    if (this.props.multiple) {
      const selected = this.props.selectedSparePartLocationIds.includes(sparePartLocation.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartLocation.id}
          onClick={e => this.selectSparePartLocation(sparePartLocation.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectSparePartLocation(sparePartLocation.id);
              }}
            />
          }
        >
          <div>
            <div>
              <span>{sparePartLocation.title}</span>
            </div>
            <div className={styles['subtitle']}>
              <SparePartLocationPath fullPath={false} sparePartLocationId={sparePartLocation.id} />
            </div>
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.state.selectedSparePartLocationId === sparePartLocation.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartLocation.id}
          selected={selected}
          onClick={e => this.selectSparePartLocation(sparePartLocation.id)}
        >
          <div>
            <div>
              <span>{sparePartLocation.title}</span>
            </div>
            <div className={styles['subtitle']}>
              <SparePartLocationPath fullPath={false} sparePartLocationId={sparePartLocation.id} />
            </div>
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderTree = () => {
    const sparePartLocationsForParentId = this.state.sparePartLocations.filter(
      ({ spare_part_location_parent_id }) => spare_part_location_parent_id === this.state.parentLocationId
    );
    return (
      <>
        {this.renderGoBackInTreeButton()}
        {sparePartLocationsForParentId.map(location => {
          return this.renderTreeListItem(location);
        })}
      </>
    );
  };

  renderList = () => {
    const { searchTerm } = this.state;
    const filteredSparePartLocations = this.state.sparePartLocations.filter(
      ({ title }) => searchTerm === '' || title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredSparePartLocations.length === 0) {
      return this.renderSearchEmptyDataset();
    }
    return filteredSparePartLocations.map(location => this.renderSearchedListItem(location));
  };

  renderSearchField = () => (
    <NewInlineModal.Header.Search
      ref={ref => (this.inlineModalSearchRef = ref)}
      placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
      value={this.state.searchTerm}
      onChange={searchTerm => {
        this.setState({
          searchTerm,
        });
      }}
      onClear={() =>
        this.setState({
          searchTerm: '',
        })
      }
    />
  );

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          {this.renderSearchField()}
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    if (this.state.searchTerm.length > 0) {
      return (
        <>
          <NewInlineModal.Header>{this.renderSearchField()}</NewInlineModal.Header>
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderList()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </>
      );
    }
    if (this.state.sparePartLocations.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.spare-part-location-inline-modal.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <>
        <NewInlineModal.Header>{this.renderSearchField()}</NewInlineModal.Header>
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>{this.renderTree()}</NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </>
    );
  };

  renderFooter = () => {
    if (this.props.multiple) {
      return null;
    }
    return (
      <NewInlineModal.Footer>
        <SelectSparePartLocationButton
          sparePartLocationId={this.state.selectedSparePartLocationId}
          onClick={() => {
            this.setState({ isOpen: false }, () => {
              this.props.onSelectSparePartLocation(this.state.selectedSparePartLocationId);
            });
          }}
        />
      </NewInlineModal.Footer>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderContent()}
          {this.renderFooter()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseSparePartLocationInlineModal));

ChooseSparePartLocationInlineModal.propTypes = {
  selectedSparePartLocationId: PropTypes.string,
  selectedSparePartLocationIds: PropTypes.array,
  onSelectSparePartLocation: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
};

ChooseSparePartLocationInlineModal.defaultProps = {
  selectedSparePartLocationId: null,
  selectedSparePartLocationIds: [],
  onSelectSparePartLocation: () => {},
  onAddSparePartLocation: () => {},
  onRemoveSparePartLocation: () => {},
  onClear: () => {},
  multiple: false,
};
