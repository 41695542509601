import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { Image } from 'views/components/Image';
import { AuthSelectors } from 'state/ducks/auth';
import {
  List,
  ViewTextArea,
  FullScreenImagePicker,
  Icon,
  ElapsedTime,
} from 'views/components/Shared/General';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';

class WorkOrderSpentTimeListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      showingImageDialog: false,
    };
  }

  renderIcon = () => {
    if (this.props.vendor) {
      return <Icon type="briefcase" withBackground backgroundSize={28} />;
    }
    return (
      <ProfilePicture
        fontSize={13}
        size={28}
        userId={this.props.workOrderSpentTime.user_id}
        defaultElement={<Icon type="user" withBackground backgroundSize={28} />}
      />
    );
  };

  renderTitle = () => {
    if (this.props.vendor) {
      return this.props.vendor.name;
    }
    return <UserNameWrapper user={this.props.createdByUser} />;
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderImage = (image, index) => (
    <Image
      circle
      key={image.id}
      small
      editable={false}
      image={image}
      onClick={() => {
        this.selectImage(index);
      }}
    />
  );

  renderImages = () => {
    if (this.props.images && this.props.images.length > 0) {
      let classNames = [styles['images']];
      if (!this.props.workOrderSpentTime.comment) {
        classNames = [...classNames, styles['has-no-description']];
      }
      return (
        <div className={classNames.join(' ')}>
          {this.props.images.map((image, index) => this.renderImage(image, index))}
        </div>
      );
    }
    return null;
  };

  renderComment = () => {
    if (!this.props.workOrderSpentTime.comment) {
      return null;
    }
    return (
      <div className={styles['comment']}>
        <ViewTextArea>{this.props.workOrderSpentTime.comment}</ViewTextArea>
      </div>
    );
  };

  renderExpandedComponent = () => {
    return (
      <>
        {this.renderComment()}
        {this.renderImages()}
      </>
    );
  };

  renderHasMoreInfoIcon = () => {
    if (this.props.workOrderSpentTime.comment || (this.props.images && this.props.images.length > 0)) {
      return (
        <div className={styles['icon-container']}>
          <Icon type="comment" withBackground blue backgroundSize={22} size={10} />
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <>
          <List.Item
            expandable
            expandedComponent={this.renderExpandedComponent()}
            clickable={this.props.canCarryOutWorkOrders}
            onClick={this.props.onClick}
            paddingLeft={50}
          >
            <List.Item.Column width={30}>{this.renderIcon()}</List.Item.Column>
            <List.Item.TitleColumn
              title={this.renderTitle()}
              subtitle={moment(this.props.workOrderSpentTime.date).format('LL')}
            />
            <List.Item.Column>
              <div className={styles['right-container']}>
                {this.renderHasMoreInfoIcon()}
                <div className={styles['elapsed-time']}>
                  <ElapsedTime elapsedMinutes={this.props.workOrderSpentTime.minutes} />
                </div>
              </div>
            </List.Item.Column>
          </List.Item>
          <FullScreenImagePicker
            currentImage={this.state.currentImage}
            images={this.props.images}
            onChangeImage={index => this.selectImage(index)}
            open={this.state.showingImageDialog}
            onClose={() => this.setState({ showingImageDialog: false })}
          />
        </>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const { user_id, vendor_id, images } = ownProps.workOrderSpentTime;
  return {
    createdByUser: EntitySelectors.getUser(state, user_id),
    vendor: EntitySelectors.getVendor(state, vendor_id),
    images: EntitySelectors.getImages(state, images),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

export default connect(mapStateToProps)(WorkOrderSpentTimeListItem);

WorkOrderSpentTimeListItem.propTypes = {
  onClick: PropTypes.func,
  workOrderSpentTime: PropTypes.object,
  loading: PropTypes.bool,
};
WorkOrderSpentTimeListItem.defaultProps = {
  onClick: () => {},
  loading: false,
};
