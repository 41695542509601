import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType } from 'sdk/Export';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.props.params,
      type: ExportType.WorkOrdersReportXslx,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };
  renderContent = () => {
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.export-modal.title-statistics" />}
          subtitle={<FormattedMessage id="components.export-modal.subtitle-statistics" />}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(ExportModal);
