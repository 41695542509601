import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'views/components/Shared/General';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { WorkOrderRecurringDropdown, WorkOrderCustomInterval } from 'views/components/WorkOrder';
import { buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { FormattedMessage } from 'react-intl';

class RecurringContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustomIntervalModal: false,
    };
  }

  isRecurringMaintenance = () => this.props.editingWorkOrder.frequency != null;

  saveRecurringInformation = intervalData => {
    if (this.props.fieldErrors.interval) {
      this.props.removeFieldsFromErrors(['interval']);
    }
    const { start_date, ...params } = intervalData;
    this.props.onChangeValue({ ...params, date: intervalData.start_date });
  };

  clearInterval = () => {
    this.props.onChangeValue({
      frequency: null,
      interval: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
      month_type: null,
      generate_from_completed_date: false,
    });
  };

  renderRecurring = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.new-operational-maintenance-modal.interval" />}>
            <WorkOrderRecurringDropdown
              editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
              onSave={this.saveRecurringInformation}
              onClear={this.clearInterval}
              onShowCustomOptions={() => this.setState({ showCustomIntervalModal: true })}
              trigger={
                <ConnectedFieldErrorWrapper field="interval" position="top">
                  {hasError => (
                    <Field.Resource
                      angleDown
                      error={hasError}
                      value={
                        this.isRecurringMaintenance()
                          ? buildIntervalMessage(
                              buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder),
                              this.props.intl
                            )
                          : null
                      }
                      onClear={this.clearInterval}
                    />
                  )}
                </ConnectedFieldErrorWrapper>
              }
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    return (
      <>
        {this.renderRecurring()}
        <WorkOrderCustomInterval
          open={this.state.showCustomIntervalModal}
          editingWorkOrder={buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder)}
          onSave={this.saveRecurringInformation}
          onClose={() => this.setState({ showCustomIntervalModal: false })}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    fieldErrors: ErrorSelectors.getFieldErrors(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RecurringContainer));
