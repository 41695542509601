import types from './types';

const setChecklistInstance = payload => ({
  type: types.SET_CHECKLIST_INSTANCE,
  payload,
});

export default {
  setChecklistInstance,
};
