import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { WhiteCard, Button, Field, ViewTextArea, Tooltip, Icon } from 'views/components/Shared/General';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';

class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingVendor: {},
      editingVendorBeforeEdit: {},
    };
  }

  edit = () => {
    const editingVendor = {
      phone: this.props.vendor.phone,
      website: this.props.vendor.website,
      email: this.props.vendor.email,
      organization_number: this.props.vendor.organization_number,
      additional_information: this.props.vendor.additional_information,
    };

    this.setState({
      editing: true,
      editingVendor: editingVendor,
      editingVendorBeforeEdit: editingVendor,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    const params = this.getModifiedVendorData();
    this.setState({ isSaving: true });
    this.props
      .updateVendor(this.props.vendor.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedVendorData = () => {
    const vendor = this.state.editingVendor;
    const vendorBeforeEdit = this.state.editingVendorBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== vendorBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  setVendorValue = obj => {
    const newEditingVendor = {
      ...this.state.editingVendor,
      ...obj,
    };

    this.setState({
      editingVendor: newEditingVendor,
    });
  };

  isLoaded = () => this.props.vendor != null;

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditVendors) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return null;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column md={6}>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.vendor.organization-number" />}
              >
                {this.state.editing ? (
                  <Field.Text
                    autoFocus
                    value={this.state.editingVendor.organization_number}
                    onChange={organization_number => {
                      this.setVendorValue({ organization_number });
                    }}
                  />
                ) : (
                  this.props.vendor.organization_number || '-'
                )}
              </Field>
            </Grid.Column>
            <Grid.Column md={6}>
              <Field view={!this.state.editing} label={<FormattedMessage id="resources.vendor.website" />}>
                {this.state.editing ? (
                  <Field.Text
                    type="url"
                    value={this.state.editingVendor.website}
                    onChange={website => {
                      this.setVendorValue({ website });
                    }}
                  />
                ) : (
                  this.props.vendor.website || '-'
                )}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>
              <Field view={!this.state.editing} label={<FormattedMessage id="resources.vendor.phone" />}>
                {this.state.editing ? (
                  <Field.Text
                    type="tel"
                    value={this.state.editingVendor.phone}
                    onChange={phone => {
                      this.setVendorValue({ phone });
                    }}
                  />
                ) : (
                  this.props.vendor.phone || '-'
                )}
              </Field>
            </Grid.Column>
            <Grid.Column md={6}>
              <Field view={!this.state.editing} label={<FormattedMessage id="resources.vendor.email" />}>
                {this.state.editing ? (
                  <Field.Text
                    type="email"
                    value={this.state.editingVendor.email}
                    onChange={email => {
                      this.setVendorValue({ email });
                    }}
                  />
                ) : (
                  this.props.vendor.email || '-'
                )}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={12}>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.vendor.additional-information" />}
              >
                {this.state.editing ? (
                  <Field.Textarea
                    value={this.state.editingVendor.additional_information}
                    onChange={additional_information => {
                      this.setVendorValue({ additional_information });
                    }}
                  />
                ) : (
                  <ViewTextArea>{this.props.vendor.additional_information || '-'}</ViewTextArea>
                )}
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <WhiteCard
          title={<FormattedMessage id="screens.vendor.information.general-information.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const vendorId = ownProps.match.params.id;

  return {
    vendor: EntitySelectors.getVendor(state, vendorId),
    system: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GeneralInformation)));
