import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { AssetsSelectors } from 'state/ducks/assets';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    const keys = this.props.selectedFilterType.split('.');
    switch (keys[0]) {
      case FILTER_TYPES.AssetType: {
        return <Filter.AssetType />;
      }
      case FILTER_TYPES.TreeParent: {
        return <Filter.TreeParent />;
      }
      case FILTER_TYPES.AssetField: {
        return <Filter.AssetField />;
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFilterType: AssetsSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
