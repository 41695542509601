import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeWorkOrder, normalizeRecurringMaintenance, normalizeWorkOrderActivity } from 'sdk/Schemas';
import { groupByDate } from 'sdk/WorkOrderActivity';

const resetState = actions.resetState;
const setEditingWorkOrderValues = actions.setEditingWorkOrderValues;
const resetEditingWorkOrder = actions.resetEditingWorkOrder;

const fetchWorkOrderForView = workOrderId => dispatch => {
  dispatch(actions.fetchWorkOrder(workOrderId));
  return API.fetchWorkOrder(workOrderId).then(res => {
    const { entities } = normalizeWorkOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    return res.data;
  });
};

const fetchWorkOrderForEdit = workOrderId => dispatch => {
  return API.fetchWorkOrder(workOrderId).then(res => {
    const { entities, result } = normalizeWorkOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    const workOrder = entities.workOrderById[result];
    const editingWorkOrder = {
      ...workOrder,
      date: workOrder.due_date,
    };
    dispatch(actions.setWorkOrderForEdit(editingWorkOrder));
  });
};

const fetchRecurringMaintenanceForEdit = recurringMaintenanceId => dispatch => {
  return API.fetchRecurringMaintenance(recurringMaintenanceId).then(res => {
    const { entities, result } = normalizeRecurringMaintenance(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    const recurringMaintenance = entities.recurringMaintenanceById[result];
    const editingWorkOrder = {
      ...recurringMaintenance,
      date: recurringMaintenance.next_work_order_date,
      due_meter_value: recurringMaintenance.meter_next_value,
    };
    dispatch(actions.setWorkOrderForEdit(editingWorkOrder));
  });
};

const fetchPreviousWorkOrders = (systemId, params) => dispatch => {
  dispatch(actions.fetchPreviousWorkOrders());
  return API.listWorkOrders(systemId, params).then(res => {
    const { entities, result } = normalizeWorkOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    dispatch(
      actions.fetchPreviousWorkOrdersSuccess({
        ids: result,
        pagination: HelperFunctions.getPaginationFromHeader(res.headers),
      })
    );

    return res.data;
  });
};

const fetchWorkOrderActivities = (workOrderId, system) => dispatch => {
  return API.listWorkOrderActivities(workOrderId).then(res => {
    const { entities } = normalizeWorkOrderActivity(res.data);

    const dateData = groupByDate(res.data, system);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchWorkOrderActivitiesSuccess({
        dateData: dateData,
      })
    );

    return res.data;
  });
};

export default {
  resetState,
  resetEditingWorkOrder,
  setEditingWorkOrderValues,
  fetchWorkOrderForView,
  fetchWorkOrderForEdit,
  fetchRecurringMaintenanceForEdit,
  fetchPreviousWorkOrders,
  fetchWorkOrderActivities,
};
