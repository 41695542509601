import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { List, Pagination } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { MenuOperations } from 'state/ducks/menu';
import { MenuUtils } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { normalizeChecklistTemplate } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import ChecklistListItem from './ChecklistListItem';
import ChecklistsImage from 'assets/images/EmptyDataSet/Checklists.png';
import styles from './style.module.scss';

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      checklistTemplateIds: [],
      pagination: {
        totalEntries: 0,
        totalPages: 1,
      },
      queryParameters: {
        page: 1,
        page_size: 25,
      },
    };
    this.fetchDebouncedChecklistTemplaes = debounce(() => {
      this.fetchChecklistTemplates();
    }, 300);
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.checklists.title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Checklists);
    this.fetchChecklistTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    const changedSearchTerm = prevProps.searchTerm !== this.props.searchTerm;
    let changedQueryParams = !isEqual(prevState.queryParameters, this.state.queryParameters);
    if (changedSearchTerm || changedQueryParams) {
      this.setState({ isFetching: true });
      this.fetchDebouncedChecklistTemplaes();
    }
  }

  fetchChecklistTemplates = () => {
    let attrs = {
      ...this.state.queryParameters,
    };
    if (this.props.searchTerm) {
      attrs = {
        ...attrs,
        search: this.props.searchTerm,
      };
    }

    return API.listChecklistTemplates(this.props.system.id, { ...attrs, reusable: true })
      .then(({ data: checklistTemplates, headers }) => {
        const pagination = SDKHelperFunctions.getPaginationFromHeader(headers);
        const { entities, result: checklistTemplateIds } = normalizeChecklistTemplate(checklistTemplates);
        this.props.updateEntities(entities);
        this.setState({ isFetching: false, checklistTemplateIds, pagination });
        this.props.onUpdateTotalEntries(headers['total-entries']);
      })
      .catch(e => {});
  };

  renderPagination = () => {
    if (this.state.checklistTemplateIds.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.state.queryParameters.page}
          totalPages={this.state.pagination.totalPages}
          pageSize={this.state.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ queryParameters: { ...this.state.queryParameters, page }, isFetching: true });
          }}
          onChangePageSize={page_size => {
            this.setState({
              queryParameters: { ...this.state.queryParameters, page_size },
              isFetching: true,
            });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.checklists.empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.checklists.empty-data-set.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={ChecklistsImage} alt="" />
      </div>
    </div>
  );

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={ChecklistsImage} alt="" />
      </div>
    </div>
  );

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.checklist-template.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.checklistTemplateIds.length === 0) {
      if (this.props.searchTerm.length === 0) {
        return this.renderEmptyDataSet();
      } else {
        return this.renderSearchedEmptyDataset();
      }
    }
    return (
      <>
        {this.renderListHeader()}
        <List small>
          {this.state.checklistTemplateIds.map(checklistId => (
            <ChecklistListItem id={checklistId} loading={this.state.isFetching} key={checklistId} />
          ))}
        </List>
      </>
    );
  };

  render() {
    if (this.state.isFetching) {
      const amountOfChecklists =
        this.state.checklistTemplateIds.length === 0 ? 2 : this.state.checklistTemplateIds.length;
      return (
        <ListLayout.Content.MainContent>
          <ListLayout.Content.MainContent.Content>
            {this.renderListHeader()}
            <List>
              {Array(amountOfChecklists)
                .fill()
                .map(() => (
                  <List.Item small>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
          </ListLayout.Content.MainContent.Content>
          {this.renderPagination()}
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>
          {this.renderList()}
        </ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Templates)));
