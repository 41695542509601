import React from 'react';
import { connect } from 'react-redux';
import { DragLayer } from 'react-dnd';
import { AssetSelectors } from 'state/ducks/asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x + 8}px, ${y + 5}px)`;
  return {
    transform: transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = ({
  draggingWorkOrderId,
  draggingRecurringMaintenanceId,
  workOrder,
  recurringMaintenance,
  isDragging,
  currentOffset,
}) => {
  if (!isDragging || (draggingWorkOrderId == null && draggingRecurringMaintenanceId == null)) {
    return null;
  }

  const renderContent = () => {
    if (draggingWorkOrderId) {
      return (
        <>
          <div className={styles['title']}>{workOrder.title}</div>
          <div className={styles['subtitle']}>#{workOrder.number}</div>
        </>
      );
    }
    return <div className={styles['title']}>{recurringMaintenance.title}</div>;
  };

  return (
    <div className={styles['layer-container']}>
      <div className={styles['dragging-work-order']} style={getItemStyles(currentOffset)}>
        {renderContent()}
      </div>
    </div>
  );
};

function collect(monitor) {
  return {
    item: monitor.getItem(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

function mapStateToProps(state, ownProps) {
  const draggingWorkOrderId = AssetSelectors.getDraggingWorkOrderId(state);
  const draggingRecurringMaintenanceId = AssetSelectors.getDraggingRecurringMaintenanceId(state);
  let workOrder = null;
  let recurringMaintenance = null;
  if (draggingWorkOrderId) {
    workOrder = EntitySelectors.getWorkOrder(state, draggingWorkOrderId);
  }
  if (draggingRecurringMaintenanceId) {
    recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, draggingRecurringMaintenanceId);
  }
  return {
    draggingWorkOrderId,
    draggingRecurringMaintenanceId,
    workOrder,
    recurringMaintenance,
  };
}

export default connect(mapStateToProps)(DragLayer(collect)(CustomDragLayer));
