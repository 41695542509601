import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { normalizeLoginCredential, normalizeUser } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, Banner, Icon, FieldErrorWrapper, InlineModal } from 'views/components/Shared/General';
import UserIsArchivedBanner from '../UserIsArchivedBanner';
import styles from './style.module.scss';

class UserPinModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    email: this.props.user.email || '',
    newUser: this.props.user.login_credential ? false : true,
    hasCredentials: this.props.user.login_credential ? true : false,
    createWithPassword: false,
    password: '',
    confirmPassword: '',
    showPasswordsNotMatchingError: false,
    showRequiredEmailError: false,
    showInvalidEmailErorr: false,
    showAlreadyTakenEmailError: false,
    showErrorForEmptyPassword: false,
    deleteInlineModalIsOpen: false,
    isDeleting: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  createInvite = () => {
    if (this.state.email.length === 0) {
      this.setState({
        showErrorForEmptyEmail: true,
      });
      return;
    }
    var data = {
      invited: true,
      email: this.state.email,
    };
    this.setState({ isSaving: true });
    this.props
      .updateUser(this.props.user.id, data)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.action-modal.toasts.invite-sent" />}
          />
        );
        const { data } = res;
        const { entities } = normalizeUser(data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  createWithPassword = () => {
    if (this.state.email === '' && this.state.password === '') {
      this.setState({ showRequiredEmailError: true, showErrorForEmptyPassword: true });
      return;
    } else if (this.state.email === '') {
      this.setState({ showRequiredEmailError: true });
      return;
    } else if (this.state.password === '') {
      this.setState({ showErrorForEmptyPassword: true });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ showPasswordsNotMatchingError: true });
      return;
    }
    var data = {
      user_id: this.props.user.id,
      username: this.state.email,
      password: this.state.password,
    };
    this.setState({ isSaving: true });
    this.props
      .createLoginCredential(this.props.user.id, data)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.action-modal.toasts.login-created-success" />}
          />
        );
        const { data } = res;
        const { entities } = normalizeLoginCredential(data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        const alreadyExists = HelperFunctions.hasError(e, { code: '10002', key: 'username' });
        const invalidEmail = HelperFunctions.hasError(e, { code: '10018', key: 'username' });
        this.setState({
          showInvalidEmailErorr: invalidEmail,
          showAlreadyTakenEmailError: alreadyExists,
          isSaving: false,
        });
      });
  };

  deleteCredentials = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteLoginCredential(this.props.user.id, this.props.user.login_credential)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isDeleting: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  changeEmail = () => {
    if (this.state.email === '') {
      this.setState({ showRequiredEmailError: true });
      return;
    }
    var data = {
      username: this.state.email,
    };
    this.setState({ isSaving: true });
    this.props
      .updateLoginCredential(this.props.user.login_credential, data)
      .then(({ data: loginCredential }) => {
        const { entities } = normalizeLoginCredential(loginCredential);
        this.props.updateEntities(entities);
        API.fetchUser(this.props.user.id).then(({ data: user }) => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          const { entities } = normalizeUser(user);
          this.props.updateEntities(entities);
          this.props.onClose();
        });
      })
      .catch(e => {
        const alreadyExists = HelperFunctions.hasError(e, { code: '10002', key: 'username' });
        const invalidEmail = HelperFunctions.hasError(e, { code: '10018', key: 'username' });
        this.setState({
          showInvalidEmailErorr: invalidEmail,
          showAlreadyTakenEmailError: alreadyExists,
          isSaving: false,
        });
      });
  };

  renderInfoBox = () => {
    if (this.props.organisationSettings.sso_oidc_activated) {
      return (
        <div className={styles['banner-container']}>
          <Banner orange>
            <FormattedMessage id="screens.users.action-modal.empty-credentials-with-sso" />
          </Banner>
        </div>
      );
    }
    return (
      <div className={styles['banner-container']}>
        <Banner red>
          <FormattedMessage id="screens.users.action-modal.empty-credentials" />
        </Banner>
      </div>
    );
  };

  renderNoFields = () => {
    const { showRequiredEmailError, showInvalidEmailErorr, showAlreadyTakenEmailError } = this.state;
    return (
      <>
        {this.state.newUser ? (
          this.renderInfoBox()
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.login-credentials.username" />}>
                  <FieldErrorWrapper
                    position="top"
                    show={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                    errorElement={this.renderEmailErrorMessage()}
                  >
                    <Field.Text
                      autoFocus
                      value={this.state.email}
                      error={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                      onChange={email =>
                        this.setState({
                          email,
                          showRequiredEmailError: false,
                          showInvalidEmailErorr: false,
                          showAlreadyTakenEmailError: false,
                        })
                      }
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Field.Radio.Group>
                  <Field.Radio
                    label={<FormattedMessage id="screens.users.action-modal.invite-by-mail" />}
                    checked={!this.state.createWithPassword}
                    onChange={() => this.setState({ createWithPassword: false })}
                  />
                  <Field.Radio
                    label={<FormattedMessage id="screens.users.action-modal.with-password" />}
                    checked={this.state.createWithPassword}
                    onChange={() => this.setState({ createWithPassword: true })}
                  />
                </Field.Radio.Group>
                <AnimateHeight
                  duration={150}
                  height={this.state.createWithPassword ? 'auto' : 0}
                  onAnimationEnd={() => this.passwordRef.focus()}
                >
                  <Grid style={{ marginTop: 18 }}>
                    <Grid.Row>
                      <Grid.Column>
                        <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
                          <Field.Text
                            type="password"
                            autocomplete="new-password"
                            autoFocus
                            ref={ref => (this.passwordRef = ref)}
                            error={this.state.showErrorForEmptyPassword}
                            value={this.state.password}
                            onChange={password =>
                              this.setState({ password, showErrorForEmptyPassword: false })
                            }
                          />
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Field label={<FormattedMessage id="screens.users.action-modal.confirm-password" />}>
                          <FieldErrorWrapper
                            show={this.state.showPasswordsNotMatchingError}
                            errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
                          >
                            <Field.Text
                              type="password"
                              autocomplete="new-password"
                              error={
                                this.state.showPasswordsNotMatchingError ||
                                this.state.showErrorForEmptyPassword
                              }
                              value={this.state.confirmPassword}
                              onChange={confirmPassword => {
                                if (this.state.showPasswordsNotMatchingError) {
                                  this.setState({ showPasswordsNotMatchingError: false });
                                }
                                this.setState({ confirmPassword });
                              }}
                            />
                          </FieldErrorWrapper>
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </AnimateHeight>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {this.state.newUser ? (
          <Button
            gray
            small
            label="screens.users.action-modal.create-credential"
            onClick={() => this.setState({ newUser: false })}
          />
        ) : null}
      </>
    );
  };
  renderFields = () => {
    if (this.props.user.login_credential) return this.renderEditFields();
    else return this.renderNoFields();
  };

  renderEmailErrorMessage = () => {
    const { showRequiredEmailError, showInvalidEmailErorr, showAlreadyTakenEmailError } = this.state;
    if (showRequiredEmailError) {
      return <FormattedMessage id="general.errors.is-required" />;
    }
    if (showInvalidEmailErorr) {
      return <FormattedMessage id="general.errors.invalid-email" />;
    }
    if (showAlreadyTakenEmailError) {
      return <FormattedMessage id="general.errors.email-already-exists" />;
    }
    return null;
  };

  renderEditFields = () => {
    const { showRequiredEmailError, showInvalidEmailErorr, showAlreadyTakenEmailError } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.username" />}>
              <FieldErrorWrapper
                position="top"
                show={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                errorElement={this.renderEmailErrorMessage()}
              >
                <Field.Text
                  autoFocus
                  value={this.state.email}
                  error={showRequiredEmailError || showInvalidEmailErorr || showAlreadyTakenEmailError}
                  onChange={email =>
                    this.setState({
                      email,
                      showRequiredEmailError: false,
                      showInvalidEmailErorr: false,
                      showAlreadyTakenEmailError: false,
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
              <Field.Text disabled value="*******" />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Button
          gray
          small
          label="screens.users.action-modal.reset-password"
          onClick={() => this.props.onResetPassword()}
        />
      </Grid>
    );
  };

  renderDeleteInlineModal = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              deleteInlineModalIsOpen: !prevState.deleteInlineModalIsOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular red type="trash-alt" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.deleteInlineModalIsOpen}
          onClose={() => this.setState({ deleteInlineModalIsOpen: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({
                deleteInlineModalIsOpen: false,
              });
            }}
          />
          <InlineModal.Body width={300}>
            {
              <InlineModal.Delete loading={this.state.isDeleting} onDelete={() => this.deleteCredentials()}>
                <FormattedMessage id="screens.users.action-modal.confirm-delete" />
              </InlineModal.Delete>
            }
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          iconButtons={!this.props.user.login_credential ? null : this.renderDeleteInlineModal()}
          title={<FormattedMessage id="screens.users.action-modal.login-title" />}
          subtitle={
            <FormattedMessage
              id="screens.users.action-modal.change-subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>
          {this.props.user.archived && <UserIsArchivedBanner />}
          {this.renderFields()}
        </Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          {this.props.user.login_credential || !this.state.newUser ? (
            <Modal.Footer>
              <Button.Group>
                <Button
                  primary
                  loading={this.state.isSaving}
                  label="general.save"
                  onClick={() => {
                    if (!this.state.hasCredentials && this.state.createWithPassword)
                      this.createWithPassword();
                    else if (!this.state.hasCredentials && !this.state.createWithPassword)
                      this.createInvite();
                    else this.changeEmail();
                  }}
                />
                <Button label="general.cancel" onClick={() => this.props.onClose()} />
              </Button.Group>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      deleteLoginCredential: SDKReduxOperations.deleteLoginCredential,
      createLoginCredential: SDKReduxOperations.createLoginCredential,
      updateLoginCredential: SDKReduxOperations.updateLoginCredential,
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPinModal);
