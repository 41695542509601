import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual, omit, debounce } from 'lodash-es';
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  NewInlineModal,
  FilterButton,
  Pagination,
  List,
  SortingButton,
} from 'views/components/Shared/General';
import { PrintQRCodeModal } from 'views/components/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import { AuthSelectors } from 'state/ducks/auth';
import HelperFunctions from 'utilities/HelperFunctions';
import AssetsImage from 'assets/images/EmptyDataSet/Assets.png';
import AssetListItem from './AssetListItem';
import FilterModal from './FilterModal';
import MassEditModal from './MassEditModal';
import ExportModal from './ExportModal';
import PrintModal from './PrintModal';
import AddSparePartModal from './AddSparePartModal';
import { LIST_TYPES } from '../';
import styles from './style.module.scss';

const listAssetsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class AssetsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showFilterModal: false,
      showMassEditModal: false,
      showExportModal: false,
      showPrintModal: false,
      showAddSparePartModal: false,
      showPrintQrCodeModal: false,
    };

    this.fetchDebouncedAssets = debounce(params => {
      this.props
        .fetchAssets(this.props.system.id, params, listAssetsRequest.getCancelTokenConfig())
        .then(assets => {
          this.setState({ isFetching: false });
        })
        .catch(() => {});
    }, 250);
  }

  componentDidMount() {
    this.props.resetSelectedAssets();
    this.fetchAssets();
  }

  componentDidUpdate(prevProps, prevState) {
    const { list: prevList } = queryString.parse(prevProps.location.search);
    const { list } = queryString.parse(this.props.location.search);
    const { showFilterModal } = this.state;
    const { assetTypeId: prevAssetTypeId, searchTerm: prevSearchTerm } = prevProps;
    const { assetTypeId, searchTerm } = this.props;
    let changedAssetTypes = prevAssetTypeId !== assetTypeId;
    let changedSearchTerm = prevSearchTerm !== searchTerm;
    let changedList = prevList !== list;

    if (changedSearchTerm) {
      this.setState({ isFetching: true });
      this.fetchAssets();
      return;
    }

    if (changedAssetTypes || changedList) {
      this.setState({ isFetching: true });
      listAssetsRequest.cancel();
      this.addQueryParameter({ page: 1 });
      this.props.resetSelectedAssets();
      this.props.resetFilter();
      this.fetchAssets({ page: 1 });
      return;
    }

    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.setState({ isFetching: true });
      this.fetchAssets();
      return;
    }

    const prevQueryParamsWithoutSearch = omit(prevProps.queryParameters, 'search');
    const queryParamsWithoutSearch = omit(this.props.queryParameters, 'search');
    let changedQueryParams = !isEqual(prevQueryParamsWithoutSearch, queryParamsWithoutSearch);
    let changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    if (changedQueryParams || (changedAdvancedFilters && showFilterModal === false)) {
      this.props.resetSelectedAssets();
      this.setState({ isFetching: true });
      this.fetchAssets();
    }
  }

  fetchAssets = params => {
    listAssetsRequest.cancel();
    const { list } = queryString.parse(this.props.location.search);
    let attrs = {
      archived: false,
      ...this.props.filtersAsQueryParams,
    };
    if (list === LIST_TYPES.Archived) {
      attrs = {
        ...attrs,
        archived: true,
      };
    }
    attrs = {
      ...attrs,
      ...this.props.queryParameters,
      ...params,
    };

    if (this.props.assetTypeId) {
      attrs = {
        ...attrs,
        asset_type_id: this.props.assetTypeId,
      };
    }
    this.fetchDebouncedAssets(attrs);
  };

  getSortOptions = () => {
    let sortOptions = [];
    if (this.props.settings.asset_number_activated) {
      sortOptions = [
        ...sortOptions,
        { label: <FormattedMessage id="resources.asset.number" />, sort: 'number' },
      ];
    }
    sortOptions = [
      ...sortOptions,

      { label: <FormattedMessage id="resources.asset.title" />, sort: 'title' },
      {
        label: <FormattedMessage id="resources.asset.type" />,
        sort: 'asset_type_title',
      },
      {
        label: <FormattedMessage id="resources.asset.created-at" />,
        sort: 'created_at',
      },
    ];
    return sortOptions;
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  isFiltering = () => {
    const { isFiltering, searchTerm } = this.props;
    return isFiltering || searchTerm?.length > 0;
  };

  buildFilterParamsForQrCodeModal = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        ...{
          asset_type_id: this.props.assetTypeId,
        },
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.selectedAssetIds).toString(),
      },
    };
  };

  renderEmptyDataSetTitle = () => {
    const { asset_type_id, list } = queryString.parse(this.props.location.search);
    if (asset_type_id) {
      return <FormattedMessage id="screens.assets.list.empty-data-set.asset-type-title" />;
    }
    if (list === LIST_TYPES.Archived) {
      return <FormattedMessage id="screens.assets.list.empty-data-set.archived-title" />;
    }
    return <FormattedMessage id="screens.assets.list.empty-data-set.title" />;
  };

  renderAssetEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.assets.list.empty-data-set.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={AssetsImage} alt="" />
      </div>
    </div>
  );

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={AssetsImage} alt="" />
      </div>
    </div>
  );

  renderToolbar = () => {
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.FilterBar.RightContent>
          <FilterButton.Group>
            <SortingButton
              searchRelevanceSearchTerm={this.props.searchTerm}
              sortOptions={this.getSortOptions()}
              sort={this.props.queryParameters.sort}
              sortOrder={this.props.queryParameters['sort-order']}
              onSort={sort => this.addQueryParameter(sort)}
            />
            {this.props.isFiltering ? (
              <FilterButton
                clearable={false}
                caret={false}
                label={<FormattedMessage id="general.clean" />}
                onClick={() => {
                  this.setState({ isFetching: true });
                  this.props.resetFilter();
                }}
              />
            ) : null}
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="screens.work-orders.filter" />}
              filtered={this.props.isFiltering}
              onClick={() => this.setState({ showFilterModal: true })}
            />
          </FilterButton.Group>
        </ListLayout.Content.MainContent.FilterBar.RightContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isFetching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.assets.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedAssets()}
        />
      );
    }
    return null;
  };

  renderAddSparePartDropdownOption = () => {
    if (this.props.canEditSpareParts && this.props.canEditAssets) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ showMoreOptionsForMultipleOptions: false, showAddSparePartModal: true });
          }}
        >
          <FormattedMessage id="screens.assets.header-buttons.add-spare-part" />
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderPrintQrCodesDropdownOption = () => {
    return (
      <NewInlineModal.Dropdown.Item
        onClick={() => {
          this.setState({ showMoreOptionsForMultipleOptions: false, showPrintQrCodeModal: true });
        }}
      >
        <FormattedMessage id="screens.assets.header-buttons.print-qr-codes" />
      </NewInlineModal.Dropdown.Item>
    );
  };

  renderExportOption = () => {
    return (
      <NewInlineModal.Dropdown.Item
        onClick={() => {
          this.setState({
            showMoreOptionsForMultipleOptions: false,
            showExportModal: true,
          });
        }}
      >
        <FormattedMessage id="screens.assets.header-buttons.export" />
      </NewInlineModal.Dropdown.Item>
    );
  };

  renderMoreOptionsForMultipleOptions = () => {
    if (this.props.canEditAssets) {
      return (
        <>
          <List.Header.MultipleOptions.Button
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() =>
              this.setState(prevState => ({
                showMoreOptionsForMultipleOptions: !prevState.showMoreOptionsForMultipleOptions,
              }))
            }
            caret
            label={<FormattedMessage id="screens.assets.header-buttons.more-options" />}
          />
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.showMoreOptionsForMultipleOptions}
            position="right"
            onClose={() => this.setState({ showMoreOptionsForMultipleOptions: false })}
            minWidth={200}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                {this.renderAddSparePartDropdownOption()}
                {this.renderPrintQrCodesDropdownOption()}
                {this.renderExportOption()}
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected } = this.props;
    const { isFetching } = this.state;
    return (
      <List.Header
        small
        background
        showMultipleOptions={this.props.selectedAssetsCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.state.isFetching}
            count={this.props.selectedAssetsCount}
            buttons={
              <>
                {this.props.canEditAssets ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.assets.header-buttons.edit" />}
                    onClick={() => this.setState({ showMassEditModal: true })}
                  />
                ) : null}
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="general.print" />}
                  onClick={() => this.setState({ showPrintModal: true })}
                />
                {this.renderMoreOptionsForMultipleOptions()}
              </>
            }
          />
        }
        checkbox
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedAssets();
          } else {
            this.props.selectPage();
          }
        }}
      >
        {this.renderHeaderColumns()}
      </List.Header>
    );
  };

  renderHeaderColumns = () => {
    return (
      <>
        <List.Header.Column width={30} />
        <List.Header.Column flex>
          <FormattedMessage id="resources.asset.title" />
        </List.Header.Column>
        <List.Header.Column width={350}>
          <FormattedMessage id="resources.asset.type" />
        </List.Header.Column>
      </>
    );
  };

  setNavigatedTo = () => {
    const { list, asset_type_id } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
        asset_type_id,
      },
    });
  };

  renderList = () => {
    if (this.state.isFetching) {
      const amountOfAssets = this.props.assets.length === 0 ? 2 : this.props.assets.length;
      return (
        <>
          {this.renderHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfAssets)
              .fill()
              .map(() => (
                <AssetListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.assets.length === 0) {
      if (this.isFiltering()) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderAssetEmptyDataSet();
    }
    return (
      <>
        {this.renderHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.assets.map(({ id }) => {
            let checked = false;
            if (this.props.selectedAssetIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <AssetListItem
                key={id}
                id={id}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onClick={() => {
                  this.setNavigatedTo();
                }}
                onCheck={e => {
                  this.props.selectAsset(id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderMassEditModal = () => {
    const { asset_type_id } = queryString.parse(this.props.location.search);
    return (
      <MassEditModal
        open={this.state.showMassEditModal}
        assetTypeId={asset_type_id}
        onSave={() => {
          this.props.resetSelectedAssets();
          this.setState({ isFetching: true, showMassEditModal: false });
          this.fetchAssets();
        }}
        onClose={() => {
          this.setState({ showMassEditModal: false });
        }}
      />
    );
  };

  renderExportModal = () => {
    const { asset_type_id } = queryString.parse(this.props.location.search);
    return (
      <ExportModal
        open={this.state.showExportModal}
        assetTypeId={asset_type_id}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderPrintModal = () => {
    const { asset_type_id } = queryString.parse(this.props.location.search);

    return (
      <PrintModal
        open={this.state.showPrintModal}
        assetTypeId={asset_type_id}
        onClose={() => {
          this.setState({ showPrintModal: false });
        }}
      />
    );
  };

  renderAddSparePartModal = () => {
    return (
      <AddSparePartModal
        open={this.state.showAddSparePartModal}
        onSave={() => {
          this.setState({ showAddSparePartModal: false });
        }}
        onClose={() => {
          this.setState({ showAddSparePartModal: false });
        }}
      />
    );
  };

  renderPrintQrCodeModal = () => {
    return (
      <PrintQRCodeModal
        templateType="asset"
        open={this.state.showPrintQrCodeModal}
        amountOfQrCodes={this.props.selectedAssetsCount}
        params={this.buildFilterParamsForQrCodeModal()}
        onClose={() => this.setState({ showPrintQrCodeModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.props.resetSelectedAssets();
            this.setState({ isFetching: true, showFilterModal: false });
            this.fetchAssets(params);
          }}
        />
        {this.renderMassEditModal()}
        {this.renderExportModal()}
        {this.renderPrintModal()}
        {this.renderAddSparePartModal()}
        {this.renderPrintQrCodeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAssets: AssetsOperations.fetchAssets,
      addQueryParameter: AssetsOperations.addQueryParameter,
      resetFilter: AssetsOperations.resetFilter,
      resetSelectedAssets: AssetsOperations.resetSelectedAssets,
      selectAsset: AssetsOperations.selectAsset,
      selectTotalEntries: AssetsOperations.selectTotalEntries,
      hideSelectTotalEntries: AssetsOperations.hideSelectTotalEntries,
      selectPage: AssetsOperations.selectPage,
      setNavigatedTo: AssetsOperations.setNavigatedTo,
      resetState: AssetsOperations.resetState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    assets: AssetsSelectors.getAssets(state),
    pagination: AssetsSelectors.getAssetListPagination(state),
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: AssetsSelectors.getQueryParameters(state),
    filters: AssetsSelectors.getFilters(state),
    filtersAsQueryParams: AssetsSelectors.getFiltersAsQueryParams(state),
    isFiltering: AssetsSelectors.isFiltering(state),
    selectedAssetIds: AssetsSelectors.getSelectedAssetIds(state),
    selectedAssetsCount: AssetsSelectors.getSelectedAssetCount(state),
    totalEntriesIsSelected: AssetsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: AssetsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: AssetsSelectors.getPageIsSelected(state),
    navigatedTo: AssetsSelectors.getNavigatedTo(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsList)));
