import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List, Button } from 'views/components/Shared/General';
import { ListLayout, Toolbar } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import CreateSystemModal from './CreateSystemModal';
import styles from './style.module.scss';

class Systems extends Component {
  state = {
    showCreateSystemModal: false,
  };
  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.system.resource" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
    </div>
  );

  renderList = () => {
    const filteredSystems = this.props.systems.filter(
      system =>
        this.props.searchTerm === '' ||
        system.name.toLowerCase().includes(this.props.searchTerm.toLowerCase())
    );
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.organisation.create-system-button"
              onClick={() => this.setState({ showCreateSystemModal: true })}
            />
          }
        />
        {filteredSystems.length === 0 ? (
          this.renderSearchedEmptyDataset()
        ) : (
          <>
            {this.renderListHeader()}
            <List small>
              {filteredSystems.map(({ name }) => {
                return (
                  <List.Item small>
                    <List.Item.TitleColumn title={name} />
                  </List.Item>
                );
              })}
            </List>
          </>
        )}
      </>
    );
  };

  renderMainContent = () => {
    return (
      <ListLayout.Content.MainContent>
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
      </ListLayout.Content.MainContent>
    );
  };

  render() {
    return (
      <>
        {this.renderMainContent()}
        <CreateSystemModal
          open={this.state.showCreateSystemModal}
          onClose={() => this.setState({ showCreateSystemModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    systems: AuthSelectors.getSystems(state),
  };
}

export default connect(mapStateToProps)(Systems);
