import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { PurchaseOrderStatus, getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import styles from './styles.module.scss';

class StatusBar extends Component {
  renderEstimatedDeliveryDate = () => {
    const estimatedDate = getEstimatedDeliveryDateText(this.props.purchaseOrder);
    if (estimatedDate == null) {
      return null;
    }
    return (
      <div className={styles['subtitle']}>
        <FormattedMessage
          id="screens.purchase-order.expected-delivery-date-label"
          values={{ date: getEstimatedDeliveryDateText(this.props.purchaseOrder) }}
        />
      </div>
    );
  };

  render() {
    const { status, canceled, canceled_at } = this.props.purchaseOrder;
    const deliveredQuantity = this.props.purchaseOrderRows.reduce(
      (acc, por) => new Decimal(por.delivered_quantity || 0).plus(acc),
      new Decimal(0)
    );
    const orderedQuantity = this.props.purchaseOrderRows.reduce(
      (acc, por) => new Decimal(por.quantity || 0).plus(acc),
      new Decimal(0)
    );

    let isPartiallyDelivered = false;
    let isFullyDelivered = false;
    let isOrdered = status === PurchaseOrderStatus.Ordered || isPartiallyDelivered || isFullyDelivered;

    if (isOrdered && deliveredQuantity.greaterThanOrEqualTo(orderedQuantity)) {
      isFullyDelivered = true;
    } else if (isOrdered && deliveredQuantity.greaterThan(new Decimal(0))) {
      isPartiallyDelivered = true;
    }
    const partiallyDeliveredMomentDate = moment(this.props.purchaseOrder.most_recent_delivery_at);
    let fullyDeliveredMomentDate;
    if (this.props.purchaseOrder.most_recent_delivery_at) {
      fullyDeliveredMomentDate = moment(this.props.purchaseOrder.most_recent_delivery_at);
    } else {
      fullyDeliveredMomentDate = moment(this.props.purchaseOrder.ordered_date);
    }

    if (canceled) {
      return (
        <WhiteCard noPadding>
          <div className={styles['cancel-container']}>
            <div className={styles['cancel-icon']}>
              <Icon regular type="ban" />
            </div>
            <div className={styles['cancel-content']}>
              <p className={styles['title']}>
                <FormattedMessage id="screens.purchase-order.cancelled-title" />
              </p>
              <p className={styles['subtitle']}>
                <FormattedMessage
                  id="screens.purchase-order.cancelled-subtitle"
                  values={{
                    date: moment(canceled_at).format('LL'),
                    user: <UserNameWrapper user={this.props.cancelledByUser} />,
                  }}
                />
              </p>
            </div>
          </div>
        </WhiteCard>
      );
    }

    return (
      <WhiteCard noPadding>
        <div className={styles['status-bar']}>
          <div className={styles['status-container']}>
            <div className={`${styles['status']} ${styles['active']}`}>
              <Icon type="file-alt" />
            </div>
            <div className={`${styles['title-container']} ${styles['active']}`}>
              <p>
                <FormattedMessage id="resources.purchase-order.statuses.draft" />
              </p>
            </div>
            <div className={`${styles['line']} ${isOrdered ? styles['active'] : ''}`} />
          </div>
          <div className={styles['status-container']}>
            <div className={`${styles['status']} ${isOrdered ? styles['active'] : ''}`}>
              <Icon type="file-check" />
            </div>
            <div className={`${styles['title-container']} ${isOrdered ? styles['active'] : ''}`}>
              <p>
                <FormattedMessage id="resources.purchase-order.statuses.ordered" />
              </p>
              {isOrdered ? (
                <p className={styles['subtitle']}>
                  {moment(this.props.purchaseOrder.ordered_date).format('LL')}
                </p>
              ) : null}
            </div>
            <div
              className={`${styles['line']} ${
                isPartiallyDelivered || isFullyDelivered ? styles['active'] : ''
              }`}
            />
          </div>
          {isPartiallyDelivered ? (
            <React.Fragment>
              <div className={styles['status-container']}>
                <div className={`${styles['status']} ${styles['active']}`}>
                  <Icon type="truck" />
                </div>
                <div className={`${styles['title-container']} ${styles['active']}`}>
                  <p>
                    <FormattedMessage id="resources.purchase-order.statuses.partially-delivered" />
                  </p>
                  <p className={styles['subtitle']}>{partiallyDeliveredMomentDate.format('LL')}</p>
                </div>
                <div className={`${styles['line']}`} />
              </div>
            </React.Fragment>
          ) : null}
          <div className={styles['status-container']}>
            <div className={`${styles['status']} ${isFullyDelivered ? styles['active'] : ''}`}>
              <Icon type="check" />
            </div>
            <div className={`${styles['title-container']} ${isFullyDelivered ? styles['active'] : ''}`}>
              <p>
                <FormattedMessage id="resources.purchase-order.statuses.delivered" />
              </p>
              {isFullyDelivered ? (
                <p className={styles['subtitle']}>{fullyDeliveredMomentDate.format('LL')}</p>
              ) : (
                this.renderEstimatedDeliveryDate()
              )}
            </div>
          </div>
        </div>
      </WhiteCard>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderId);
  return {
    purchaseOrder,
    cancelledByUser: EntitySelectors.getUser(state, purchaseOrder.canceled_by_user_id),
    purchaseOrderRows: EntitySelectors.getPurchaseOrderRows(state, purchaseOrder.purchase_order_rows) || [],
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps)(StatusBar));
