const SET_CHECKLIST_TEMPLATE_VERSION = 'checklistTemplate/set_checklist_template_version';
const FETCH_CHECKLIST_TEMPLATE_VERSION = 'checklistTemplate/fetch_checklist_template_version';
const FETCH_CHECKLIST_INSTANCES_SUCCESS = 'checklistTemplate/fetch_checklist_instances_success';
const FETCH_CHECKLIST_VERSIONS_SUCCESS = 'checklistTemplate/fetch_checklist_versions_success';

export default {
  SET_CHECKLIST_TEMPLATE_VERSION,
  FETCH_CHECKLIST_TEMPLATE_VERSION,
  FETCH_CHECKLIST_INSTANCES_SUCCESS,
  FETCH_CHECKLIST_VERSIONS_SUCCESS,
};
