import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  deliveries: {
    ids: [],
  },
  workOrderPurchases: {
    ids: [],
  },
  attachments: {
    ids: [],
    pagination: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_PURCHASE_ORDER_ID: {
      return {
        ...INITIAL_STATE,
        id: action.payload,
      };
    }
    case types.FETCH_DELIVERIES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        deliveries: {
          ...state.deliveries,
          ids,
        },
      };
    }
    case types.FETCH_WORK_ORDER_PURCHASES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        workOrderPurchases: {
          ...state.workOrderPurchases,
          ids,
        },
      };
    }

    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_CREATED: {
      const { data, purchaseOrderId } = action.payload;
      if (state.id === purchaseOrderId) {
        return {
          ...state,
          deliveries: {
            ...state.deliveries,
            ids: [data.id, ...state.deliveries.ids],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_DELETED: {
      const { purchaseOrderDeliveryId, purchaseOrderDelivery } = action.payload;
      const purchaseOrderId = purchaseOrderDelivery.purchase_order_id;
      if (state.id === purchaseOrderId) {
        return {
          ...state,
          deliveries: {
            ...state.deliveries,
            ids: state.deliveries.ids.filter(id => id != purchaseOrderDeliveryId),
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.WORK_ORDER_PURCHASE_CREATED: {
      const { data } = action.payload;
      if (state.id === data.purchase_order_id) {
        return {
          ...state,
          workOrderPurchases: {
            ...state.workOrderPurchases,
            ids: [data.id, ...state.workOrderPurchases.ids],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_PURCHASE_DELETED: {
      const { workOrderPurchaseId } = action.payload;
      return {
        ...state,
        workOrderPurchases: {
          ...state.workOrderPurchases,
          ids: state.workOrderPurchases.ids.filter(id => id != workOrderPurchaseId),
        },
      };
    }
    case types.FETCH_ATTACHMENTS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids,
          pagination,
        },
      };
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { purchaseOrderId } = meta;
      if (state.id === purchaseOrderId) {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.attachment_id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_PURCHASE_ORDER: {
      const { data, params } = action.payload;
      if (params.type === 'link') {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids: state.attachments.ids.filter(id => id !== attachmentId),
        },
      };
    }
    default:
      return state;
  }
};
