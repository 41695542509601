import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

const Tabs = ({ tabs, selectedTab, onSelectTab }) => {
  const renderTitleForTab = tab => {
    if (tab.loading) {
      return (
        <div style={{ width: 75, height: 10 }}>
          <ContentLoader            
            width={75}
            height={10} 
            viewBox="0 0 75 10" 
            preserveAspectRatio="xMinYMin"
          >
            <rect x="0" y="0" rx="2" ry="2" width="75" height="10" />
          </ContentLoader>
        </div>
      );
    }
    if (tab.translate === false) {
      return tab.title;
    }
    return <FormattedMessage id={tab.title} />;
  };
  return (
    <div className={styles['tab-container']}>
      {tabs.map(tab => (
        <div
          key={tab.id}
          className={`${styles['tab']} ${tab.id === selectedTab ? styles['selected'] : ''}`}
          onClick={e => onSelectTab(tab.id)}
        >
          {renderTitleForTab(tab)}
        </div>
      ))}
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      loading: PropTypes.bool,
      translate: PropTypes.bool,
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};

Tabs.defaultProps = {
  tabs: [],
};
