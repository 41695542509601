import { omit } from 'lodash-es';
import HelperFunctions from 'utilities/HelperFunctions';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { FILTER_TYPES } from 'views/scenes/SparePartWithdrawals/FilterModal';

const getSparePartWithdrawals = state => {
  const { sparePartWithdrawals, entities } = state;
  return sparePartWithdrawals.ids.map(id => entities.sparePartWithdrawalById[id]);
};

const getQueryParameters = state => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.queryParams;
};

const getSparePartWithdrawalsPagination = state => state.sparePartWithdrawals.pagination;

/*
  MassEdit
*/

const getSelectedSparePartWithdrawalIds = state => {
  return state.sparePartWithdrawals.selectedSparePartWithdrawals.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.sparePartWithdrawals.selectedSparePartWithdrawals.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.sparePartWithdrawals.selectedSparePartWithdrawals.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.sparePartWithdrawals.selectedSparePartWithdrawals.pageIsSelected;
};

const getSelectedSparePartWithdrawalCount = state => {
  if (state.sparePartWithdrawals.selectedSparePartWithdrawals.totalEntriesAreSelected) {
    return state.sparePartWithdrawals.pagination.totalEntries;
  }
  return Object.keys(state.sparePartWithdrawals.selectedSparePartWithdrawals.ids).length;
};

/*
  Filter
*/

const getFilters = state => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.filters;
};

const getFiltersAsQueryParams = state => {
  const { sparePartWithdrawals } = state;
  let sparePartWithdrawalFilters = { ...sparePartWithdrawals.filters };
  if (sparePartWithdrawals.appliedFilters.includes('asset_with_tree_children_id')) {
    const { comparator, value } = sparePartWithdrawals.filters.asset_with_tree_children_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      sparePartWithdrawalFilters = {
        ...omit(sparePartWithdrawalFilters, 'asset_with_tree_children_id'),
        asset_id: {
          comparator,
          value,
        },
      };
    }
  }
  return HelperFunctions.buildFilter(sparePartWithdrawalFilters);
};

const getAppliedFilters = (state, key) => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.filters[key];
};

const isFiltering = state => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.appliedFilters.length > 0;
};

const showingAppliedFilters = state => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.showingAppliedFilters;
};

const getSelectedFilterType = state => {
  const { sparePartWithdrawals } = state;
  return sparePartWithdrawals.showApplyFilterForField;
};

export default {
  getSelectedSparePartWithdrawalIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedSparePartWithdrawalCount,
  getSparePartWithdrawals,
  getQueryParameters,
  getSparePartWithdrawalsPagination,
  getFilters,
  getFiltersAsQueryParams,
  getAppliedFilters,
  getFiltersForKey,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
};
