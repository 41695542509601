import React, { Component } from 'react';
import { connect } from 'react-redux';
import HelperFunctions from 'utilities/HelperFunctions';
import { EntitySelectors } from 'sdk/State/entities';
import { Icon } from 'views/components/Shared/General';
import { getIconClassNameString, getIconColor } from 'sdk/AttachmentVersion';
import parentStyles from '../style.module.scss';

class AttachmentsContainer extends Component {
  render() {
    return (
      <div
        className={parentStyles['card']}
        onClick={() => HelperFunctions.openAttachment(this.props.attachmentVersion)}
      >
        <div className={parentStyles['icon-container']}>
          <Icon
            withBackground
            gray
            backgroundSize={34}
            color={getIconColor(this.props.attachmentVersion)}
            type={getIconClassNameString(this.props.attachmentVersion)}
            size={16}
          />
        </div>
        {this.props.attachmentVersion.title}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentVersion: EntitySelectors.getAttachmentVersion(state, ownProps.attachment.attachment_version),
  };
}

export default connect(mapStateToProps)(AttachmentsContainer);
