import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API, SDKReduxOperations } from 'sdk';
import { normalizeAssetProductionSupervisor, normalizeUser } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { SectionHeader, Loader, List } from 'views/components/Shared/General';
import { AssetTitle } from 'views/components/Asset';
import ProductionSupervisorAssetItem from './ProductionSupervisorAssetItem';
import UserListItem from './UserListItem';
import styles from './style.module.scss';

class ManageProductionSupervisorsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      assetProductionSupervisorIds: [],
      userIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      return Promise.all([
        API.listUsers(this.props.currentSystem.id, {
          permission: 'production_supervisor_for_asset',
          no_pagination: true,
          archived: false,
        }),
        API.listAssetProductionSupervisors(this.props.currentSystem.id, { asset_id: this.props.assetId }),
      ]).then(([{ data: users }, { data: assetProductionSupervisors }]) => {
        const { entities: userEntities, result: userIds } = normalizeUser(users);
        const { entities: assetProductionSupervisorAssets, result: assetProductionSupervisorIds } =
          normalizeAssetProductionSupervisor(assetProductionSupervisors);
        this.props.updateEntities({ ...userEntities });
        this.props.updateEntities({ ...assetProductionSupervisorAssets });
        let productionSupervisorAssetForUserId = {};
        assetProductionSupervisorIds.forEach(id => {
          const { user_id } = assetProductionSupervisorAssets.assetProductionSupervisorById[id];
          productionSupervisorAssetForUserId = {
            ...productionSupervisorAssetForUserId,
            [user_id]: id,
          };
        });
        this.setState({
          isFetching: false,
          userIds,
          assetProductionSupervisorIds,
          productionSupervisorAssetForUserId,
        });
      });
    }
  }

  createAssetProductionSupervisor = userId => {
    this.setState({ isSavingUserId: userId });
    this.props
      .createAssetProductionSupervisor(this.props.currentSystem.id, {
        asset_id: this.props.assetId,
        user_id: userId,
      })
      .then(({ data: assetProductionSupervisor }) => {
        this.setState({
          isSavingUserId: null,
          assetProductionSupervisorIds: [
            assetProductionSupervisor.id,
            ...this.state.assetProductionSupervisorIds,
          ],
          productionSupervisorAssetForUserId: {
            ...this.state.productionSupervisorAssetForUserId,
            [userId]: assetProductionSupervisor.id,
          },
        });
      })
      .catch(() => {
        this.setState({ isSavingUserId: null });
      });
  };

  deleteAssetProductionSupervisor = (assetProductionSupervisorId, userId) => {
    this.setState({ isSavingUserId: userId });
    this.props
      .deleteAssetProductionSupervisor(assetProductionSupervisorId)
      .then(() => {
        let productionSupervisorAssetForUserId = {
          ...this.state.productionSupervisorAssetForUserId,
        };
        delete productionSupervisorAssetForUserId[userId];
        this.setState({
          isSavingUserId: null,
          productionSupervisorAssetForUserId,
          assetProductionSupervisorIds: this.state.assetProductionSupervisorIds.filter(
            id => id !== assetProductionSupervisorId
          ),
        });
      })
      .catch(() => {
        this.setState({ isSavingUserId: null });
      });
  };

  renderProductionSupervisorAssets = () => {
    if (this.state.isFetching) {
      return <Loader small centerInParent />;
    }
    if (this.state.assetProductionSupervisorIds.length === 0) {
      return (
        <div className={styles['empty-data-set']}>
          <FormattedMessage id="screens.asset.info.production-supervisor.manage-production-supervisors-modal.bookmarked-empty-data-set.title" />
        </div>
      );
    }
    return this.state.assetProductionSupervisorIds.map(id => (
      <ProductionSupervisorAssetItem
        key={id}
        id={id}
        onDelete={userId => {
          let productionSupervisorAssetForUserId = {
            ...this.state.productionSupervisorAssetForUserId,
          };
          delete productionSupervisorAssetForUserId[userId];
          this.setState({
            productionSupervisorAssetForUserId,
            assetProductionSupervisorIds: this.state.assetProductionSupervisorIds.filter(
              loopedId => loopedId !== id
            ),
          });
        }}
      />
    ));
  };

  renderMenuContent = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={25} noBorderTop>
          <FormattedMessage id="screens.asset.info.production-supervisor.manage-production-supervisors-modal.production-supervisors" />
        </SectionHeader>
        {this.renderProductionSupervisorAssets()}
      </>
    );
  };

  renderUsers = () => {
    if (this.state.isFetching) {
      return <Loader small centerInParent />;
    }
    return (
      <>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.user.resource" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.state.userIds.map(id => (
            <UserListItem
              id={id}
              key={id}
              isSavingUserId={this.state.isSavingUserId}
              selected={this.state.productionSupervisorAssetForUserId[id] != null}
              onSelectUser={id => {
                if (this.state.productionSupervisorAssetForUserId[id]) {
                  this.deleteAssetProductionSupervisor(this.state.productionSupervisorAssetForUserId[id], id);
                } else {
                  this.createAssetProductionSupervisor(id);
                }
              }}
            />
          ))}
        </List>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={
            <FormattedMessage id="screens.asset.info.production-supervisor.manage-production-supervisors-modal.title" />
          }
          subtitle={<AssetTitle id={this.props.asset.id} />}
          onClose={this.props.onClose}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>
            <Modal.ColumnLayout.Container.Content>{this.renderUsers()}</Modal.ColumnLayout.Container.Content>
          </Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createAssetProductionSupervisor: SDKReduxOperations.createAssetProductionSupervisor,
      deleteAssetProductionSupervisor: SDKReduxOperations.deleteAssetProductionSupervisor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { assetId } = ownProps;
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    asset: EntitySelectors.getAsset(state, assetId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProductionSupervisorsModal);
