import React, { Component } from 'react';
import { connect } from 'react-redux';
import Decimal from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import { ViewTextArea, Icon, FormattedNumber } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { WorkOrderActivityType } from 'sdk/WorkOrderActivity';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import moment from 'moment-timezone';
import styles from './style.module.scss';

class Activity extends Component {
  renderIcon = () => {
    switch (this.props.activityData.type) {
      case WorkOrderActivityType.Created:
        return <Icon withBackground blue backgroundSize={34} type="file-alt" />;
      case WorkOrderActivityType.DueDateChanged:
        return <Icon withBackground blue backgroundSize={34} type="calendar-alt" />;
      case WorkOrderActivityType.DueMeterValueChanged:
        return <Icon withBackground blue backgroundSize={34} type="tachometer-alt-slow" />;
      case WorkOrderActivityType.AssigneeAdded:
      case WorkOrderActivityType.AssigneeRemoved:
        return <Icon withBackground blue backgroundSize={34} type="user-alt" />;
      case WorkOrderActivityType.VendorEmailSent:
        return <Icon withBackground blue backgroundSize={34} type="envelope" />;
      case WorkOrderActivityType.NotStarted:
        return <Icon withBackground orange backgroundSize={34} type="clock" />;
      case WorkOrderActivityType.InProgress:
        return <Icon withBackground orange backgroundSize={34} type="wrench" />;
      case WorkOrderActivityType.Paused:
        return <Icon withBackground backgroundSize={34} type="pause" />;
      case WorkOrderActivityType.Completed:
        return <Icon withBackground green backgroundSize={34} regular type="check" />;
      default:
        return null;
    }
  };

  renderTitle = () => {
    let userAssignees;
    let groupAssignees;
    let vendorAssignees;
    let assignees;

    switch (this.props.activityData.type) {
      case WorkOrderActivityType.Created:
        return <FormattedMessage id="resources.work-order-activity.created" />;
      case WorkOrderActivityType.DueDateChanged:
        const { due_date, old_due_date } = this.props.workOrderActivity;

        if (due_date == null) {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-date-cleared"
              values={{
                cleared: (
                  <span className={styles['red']}>
                    <FormattedMessage id="resources.work-order-activity.due-date-cleared-text" />
                  </span>
                ),
              }}
            />
          );
        } else if (old_due_date != null) {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-date-rescheduled"
              values={{
                date: (
                  <span className={[styles['red'], styles['date']].join(' ')}>
                    {moment(due_date).format('LL')}
                  </span>
                ),
              }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-date-scheduled"
              values={{
                date: (
                  <span className={[styles['red'], styles['date']].join(' ')}>
                    {moment(due_date).format('LL')}
                  </span>
                ),
              }}
            />
          );
        }
      case WorkOrderActivityType.DueMeterValueChanged:
        const { due_meter_value, old_due_meter_value } = this.props.workOrderActivity;

        if (due_meter_value == null) {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-meter-value-cleared"
              values={{
                cleared: (
                  <span className={styles['red']}>
                    <FormattedMessage id="resources.work-order-activity.due-meter-value-cleared-text" />
                  </span>
                ),
              }}
            />
          );
        } else if (old_due_meter_value) {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-meter-value-rescheduled"
              values={{
                value: (
                  <span className={styles['red']}>
                    <FormattedNumber
                      value={new Decimal(due_meter_value || 0).toDecimalPlaces(2).toString()}
                      unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                    />
                  </span>
                ),
              }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="resources.work-order-activity.due-meter-value-scheduled"
              values={{
                value: (
                  <span className={styles['red']}>
                    <FormattedNumber
                      value={new Decimal(due_meter_value || 0).toDecimalPlaces(2).toString()}
                      unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                    />
                  </span>
                ),
              }}
            />
          );
        }

      case WorkOrderActivityType.AssigneeAdded:
        userAssignees = this.props.users.map(user => (
          <span className={styles['red']}>
            <UserNameWrapper user={user} />
          </span>
        ));

        groupAssignees = this.props.groups.map(group => (
          <span className={styles['red']}>
            {group ? group.title : <FormattedMessage id="resources.work-order-activity.removed-group" />}
          </span>
        ));

        vendorAssignees = this.props.vendors.map(vendor => (
          <span className={styles['red']}>
            {vendor ? vendor.name : <FormattedMessage id="resources.work-order-activity.removed-vendor" />}
          </span>
        ));

        // Make comma seperated string with and as last. Eg. "1, 2, 3 and 4"
        assignees = [...userAssignees, ...groupAssignees, ...vendorAssignees].flatMap((value, i, array) => {
          if (array.length - 1 === i) return value;
          if (array.length - 2 === i)
            return [
              value,
              <span>
                {' '}
                <FormattedMessage id="general.and" />{' '}
              </span>,
            ];
          else return [value, ', '];
        });

        return <FormattedMessage id="resources.work-order-activity.assignee-added" values={{ assignees }} />;

      case WorkOrderActivityType.AssigneeRemoved:
        userAssignees = this.props.users.map(user => (
          <span className={styles['red']}>
            <UserNameWrapper user={user} />
          </span>
        ));

        groupAssignees = this.props.groups.map(group => (
          <span className={styles['red']}>
            {group ? group.title : <FormattedMessage id="resources.work-order-activity.removed-group" />}
          </span>
        ));

        vendorAssignees = this.props.vendors.map(vendor => (
          <span className={styles['red']}>
            {vendor ? vendor.name : <FormattedMessage id="resources.work-order-activity.removed-vendor" />}
          </span>
        ));

        // Make comma seperated string with and as last. Eg. "1, 2, 3 and 4"
        assignees = [...userAssignees, ...groupAssignees, ...vendorAssignees].flatMap((value, i, array) => {
          if (array.length - 1 === i) return value;
          if (array.length - 2 === i)
            return [
              value,
              <span>
                {' '}
                <FormattedMessage id="general.and" />{' '}
              </span>,
            ];
          else return [value, ', '];
        });

        return (
          <FormattedMessage id="resources.work-order-activity.assignee-removed" values={{ assignees }} />
        );

      case WorkOrderActivityType.VendorEmailSent:
        const { email } = this.props.workOrderActivity.data;
        return (
          <FormattedMessage
            id="resources.work-order-activity.vendor-email-sent"
            values={{ email: <span className={styles['red']}>{email}</span> }}
          />
        );
      case WorkOrderActivityType.NotStarted:
        return (
          <FormattedMessage
            id="resources.work-order-activity.not-started"
            values={{
              not_started: (
                <span className={styles['red']}>
                  <FormattedMessage id="resources.work-order-activity.not-started-text" />
                </span>
              ),
            }}
          />
        );

      case WorkOrderActivityType.InProgress:
        const { old_status } = this.props.workOrderActivity.data || {};

        if (old_status === 'not_started') {
          return <FormattedMessage id="resources.work-order-activity.in-progress" />;
        } else {
          return <FormattedMessage id="resources.work-order-activity.resumed" />;
        }

      case WorkOrderActivityType.Paused:
        return <FormattedMessage id="resources.work-order-activity.paused" />;
      case WorkOrderActivityType.Completed:
        return <FormattedMessage id="resources.work-order-activity.completed" />;
      default:
        return null;
    }
  };

  renderSubtitle = () => {
    if (this.props.createdByRecurringMaintenance) return null;

    switch (this.props.activityData.type) {
      case WorkOrderActivityType.Completed:
        const { completed_date } = this.props.workOrderActivity.data;
        const createdDate = moment(this.props.workOrderActivity.created_at).format('YYYY-MM-DD');

        return (
          <div className={styles['subtitle']}>
            <FormattedMessage
              id="resources.work-order-activity.by-user"
              values={{ user: <UserNameWrapper user={this.props.completedByUser} /> }}
            />
            {completed_date === createdDate ? ' - ' + moment(this.props.createdAt).format('LT') : null}
          </div>
        );

      default:
        return (
          <div className={styles['subtitle']}>
            <FormattedMessage
              id="resources.work-order-activity.by-user"
              values={{ user: <UserNameWrapper user={this.props.createdByUser} /> }}
            />{' '}
            - {moment(this.props.createdAt).format('LT')}
          </div>
        );
    }
  };

  renderAdditionalInformation = () => {
    switch (this.props.activityData.type) {
      case WorkOrderActivityType.Completed:
        const { completed_comment } = this.props.workOrderActivity.data;

        if (!completed_comment) return null;

        return (
          <div className={styles['message-bubble']}>
            <ViewTextArea>{completed_comment}</ViewTextArea>
          </div>
        );

      case WorkOrderActivityType.Paused:
        const { paused_comment } = this.props.workOrderActivity.data;

        if (!paused_comment) return null;
        return (
          <div className={styles['message-bubble']}>
            <ViewTextArea>{paused_comment}</ViewTextArea>
          </div>
        );
      default:
        return null;
    }
  };

  renderLoading = () => (
    <div className={styles['activity']}>
      <div style={{ width: 300, height: 34 }}>
        <ContentLoader viewBox="0 0 300 34" preserveAspectRatio="xMinYMin">
          <circle cx="17" cy="17" r="17" />
          <rect x="49" y="5" rx="2" ry="2" width="300" height="10" />
          <rect x="49" y="20" rx="2" ry="2" width="130" height="8" />
        </ContentLoader>
      </div>
    </div>
  );

  render() {
    if (this.props.loading) return this.renderLoading();
    return (
      <div className={styles['activity']}>
        {this.renderIcon()}
        <div className={styles['content']}>
          <div className={styles['title']}>{this.renderTitle()}</div>
          {this.renderAdditionalInformation()}
          {this.renderSubtitle()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const activityData = ownProps.activityData;

  switch (activityData.type) {
    case 'assignee_added':
      const workOrderActivitiesAdd = EntitySelectors.getWorkOrderActivities(state, activityData.ids);

      return {
        currentSystem: AuthSelectors.getCurrentSystem(state),
        createdByUser: EntitySelectors.getUser(state, workOrderActivitiesAdd[0].created_by_user_id),
        createdByRecurringMaintenance: workOrderActivitiesAdd[0].created_by_recurring_maintenance,
        createdAt: workOrderActivitiesAdd[0].created_at,
        workOrderActivities: workOrderActivitiesAdd,
        users: workOrderActivitiesAdd
          .filter(activity => activity.type === 'user_assignee_added')
          .map(activity => EntitySelectors.getUser(state, activity.assigned_to_user_id)),
        groups: workOrderActivitiesAdd
          .filter(activity => activity.type === 'group_assignee_added')
          .map(activity => EntitySelectors.getGroup(state, activity.assigned_to_group_id)),
        vendors: workOrderActivitiesAdd
          .filter(activity => activity.type === 'vendor_assignee_added')
          .map(activity => EntitySelectors.getVendor(state, activity.assigned_to_vendor_id)),
      };
    case 'assignee_removed':
      let workOrderActivitiesRemove = EntitySelectors.getWorkOrderActivities(state, activityData.ids);

      return {
        currentSystem: AuthSelectors.getCurrentSystem(state),
        createdByUser: EntitySelectors.getUser(state, workOrderActivitiesRemove[0].created_by_user_id),
        createdByRecurringMaintenance: workOrderActivitiesRemove[0].created_by_recurring_maintenance,
        createdAt: workOrderActivitiesRemove[0].created_at,
        workOrderActivities: workOrderActivitiesRemove,
        users: workOrderActivitiesRemove
          .filter(activity => activity.type === 'user_assignee_removed')
          .map(activity => EntitySelectors.getUser(state, activity.assigned_to_user_id)),
        groups: workOrderActivitiesRemove
          .filter(activity => activity.type === 'group_assignee_removed')
          .map(activity => EntitySelectors.getGroup(state, activity.assigned_to_group_id)),
        vendors: workOrderActivitiesRemove
          .filter(activity => activity.type === 'vendor_assignee_removed')
          .map(activity => EntitySelectors.getVendor(state, activity.assigned_to_vendor_id)),
      };
    default:
      const workOrderActivity = EntitySelectors.getWorkOrderActivity(state, activityData.id);
      const workOrder = EntitySelectors.getWorkOrder(state, workOrderActivity.work_order_id);
      const meter = EntitySelectors.getMeter(state, workOrder.meter_id);
      let meterUnit = null;
      if (meter) {
        meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
      }
      return {
        meter,
        meterUnit,
        workOrderActivity,
        createdAt: workOrderActivity.created_at,
        currentSystem: AuthSelectors.getCurrentSystem(state),
        createdByUser: EntitySelectors.getUser(state, workOrderActivity.created_by_user_id),
        createdByRecurringMaintenance: workOrderActivity.created_by_recurring_maintenance,
        completedByUser: EntitySelectors.getUser(state, workOrderActivity.completed_by_user_id),
      };
  }
}

export default connect(mapStateToProps)(Activity);
