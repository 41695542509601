import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, ViewTextArea, Field } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class View extends Component {
  renderIconButtons = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <Button type="icon" icon={<Icon regular red type="trash-alt" />} onClick={this.props.onDelete} />
          <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.props.onEdit()} />
        </>
      );
    }
    return null;
  };

  renderAsset = () => {
    if (this.props.asset) {
      return (
        <>
          <div>{this.props.asset.title}</div>
          <div>
            <TreePath size={12} assetId={this.props.asset.id} />
          </div>
        </>
      );
    }
    return <FormattedMessage id="screens.operational-maintenances.breaks.all-assets" />;
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              view
              label={<FormattedMessage id="resources.operational-maintenance-break.from-date" />}
            >
              {moment(this.props.operationalMaintenanceBreak.from_date).format('LL')}
            </Field>
          </Grid.Column>
          <Grid.Column>
            <Field
              view
              label={<FormattedMessage id="resources.operational-maintenance-break.to-date" />}
            >
              {this.props.operationalMaintenanceBreak.to_date ? moment(this.props.operationalMaintenanceBreak.to_date).format('LL') : "-"}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view
              label={<FormattedMessage id="resources.operational-maintenance-break.description" />}
            >
              <ViewTextArea>{this.props.operationalMaintenanceBreak.description || '-'}</ViewTextArea>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view label={<FormattedMessage id="resources.asset.resource" />} fontSize={12}>
              {this.renderAsset()}
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderSubtitle = () => {
    const { from_date, to_date } = this.props.operationalMaintenanceBreak;
    if (to_date) {
      return (
        <span>
          <span>{moment(from_date).format('LL')}</span>
          <span> - </span>
          <span>{moment(to_date).format('LL')}</span>
        </span>
      );
    }
    return (
      <>
        <span>{moment(from_date).format('LL')}</span>
        <span> - </span>
        <FormattedMessage id="screens.operational-maintenances.breaks.until-further-notice" />
      </>
    );
  };

  render() {
    return (
      <>
        <Modal.Header
          title={<FormattedMessage id="components.edit-operational-maintenance-break-modal.title" />}
          subtitle={this.renderSubtitle()}
          iconButtons={this.renderIconButtons()}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const operationalMaintenanceBreak = EntitySelectors.getOperationalMaintenanceBreak(state, ownProps.id);
  return {
    operationalMaintenanceBreak,
    asset: EntitySelectors.getAsset(state, operationalMaintenanceBreak.asset_id),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
  };
}

export default connect(mapStateToProps)(View);
