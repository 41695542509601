import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { RequestTypeInlineModal } from 'views/components/Request';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { RequestsSelectors } from 'state/ducks/requests';
import RequestTypeContainer from './RequestTypeContainer';
import AssigneeDropdown from './AssigneeDropdown';
import AssetContainer from './AssetContainer';
import styles from './styles.module.scss';

const BATCH_UPDATABLE_FIELDS = {
  RequestType: 'request_type_id',
  Priority: 'priority',
  Asset: 'aset_id',
  Assignee: 'assigned_to',
};

class MassEditModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    confirmMassEdit: false,
    showEditForField: null,
    request_type_id: null,
    priority: null,
    asset_id: null,
    assigned_to_user_id: null,
    assigned_to_group_id: null,
    showQuantityExceeded: false,
    updatableFields: this.getUpdatableFields(),
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded: this.props.checkedRequestCount >= 5000,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  getUpdatableFields = () => {
    let updatableFields = [
      {
        key: BATCH_UPDATABLE_FIELDS.Asset,
        title: this.props.intl.formatMessage({ id: 'resources.request.asset' }),
      },
      {
        key: BATCH_UPDATABLE_FIELDS.RequestType,
        title: this.props.intl.formatMessage({ id: 'resources.request.request-type' }),
      },
      {
        key: BATCH_UPDATABLE_FIELDS.Priority,
        title: this.props.intl.formatMessage({ id: 'resources.request.priority' }),
      },
    ];
    if (this.props.settings.request_multiple_assignees_active) {
      updatableFields = [
        ...updatableFields,
        {
          key: BATCH_UPDATABLE_FIELDS.Assignee,
          title: this.props.intl.formatMessage({ id: 'resources.request.assignee' }),
        },
      ];
    }

    return updatableFields.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        filters: {
          ...this.props.filtersAsQueryParams,
          ...this.props.queryParameters,
          ...this.props.listParams,
        },
      };
    }
    return {
      filters: {
        id: {
          any: Object.keys(this.props.checkedRequestIds).toString(),
        },
      },
    };
  };

  buildDataParams = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.RequestType:
        return {
          update: {
            request_type_id: this.state.request_type_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Priority:
        return {
          update: {
            priority: this.state.priority,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Asset:
        return {
          update: {
            asset_id: this.state.asset_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.Assignee:
        if (this.state.assigned_to_user_id) {
          return {
            update: {
              assigned_to_user_id: this.state.assigned_to_user_id,
            },
          };
        }
        if (this.state.assigned_to_group_id) {
          return {
            update: {
              assigned_to_group_id: this.state.assigned_to_group_id,
            },
          };
        }
        return {
          update: {
            assigned_to_group_id: null,
            assigned_to_user_id: null,
          },
        };
      default:
        return {
          update: {},
        };
    }
  };

  batchUpdate = () => {
    this.setState({ isSaving: true });
    API.batchUpdateRequests(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildDataParams(),
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false, showQuantityExceeded: true });
        }
      });
  };

  selectFieldToUpdate = params => {
    this.setState({
      ...this.getInitialState(),
      ...params,
    });
  };

  renderSelectedField = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.RequestType:
        return <FormattedMessage id="resources.request.request-type" />;
      case BATCH_UPDATABLE_FIELDS.Asset:
        return <FormattedMessage id="resources.request.asset" />;
      case BATCH_UPDATABLE_FIELDS.Priority:
        return <FormattedMessage id="resources.request.priority" />;
      case BATCH_UPDATABLE_FIELDS.Assignee:
        return <FormattedMessage id="resources.request.assignee" />;
      default:
        return null;
    }
  };

  renderSelectedRequestType = () => {
    if (this.state.request_type_id) {
      return <RequestTypeContainer id={this.state.request_type_id} />;
    }
    return null;
  };

  renderEditRequestType = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.request.request-type" />}>
        <RequestTypeInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedRequestType()}
              onClear={() => this.setState({ request_type_id: null })}
            />
          }
          selectedRequestTypeId={this.state.request_type_id}
          onSelectRequestType={id => this.setState({ request_type_id: id })}
          onClear={() => this.setState({ request_type_id: null })}
        />
      </Field>
    );
  };

  renderSelectedPriority = () => {
    switch (this.state.priority) {
      case 'low':
        return <FormattedMessage id="resources.request.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.request.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.request.prio.high" />;
      default:
        return null;
    }
  };

  renderEditPriority = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.request.priority" />}>
        <ChoosePriorityInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedPriority()}
              onClear={() => this.setState({ priority: null })}
            />
          }
          selectedPriority={this.state.priority}
          onSelectPriority={priority => this.setState({ priority: priority })}
          onClear={() => this.setState({ priority: null })}
        />
      </Field>
    );
  };

  renderSelectedAsset = () => {
    if (this.state.asset_id) {
      return <AssetContainer id={this.state.asset_id} />;
    }
    return null;
  };

  renderEditAsset = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.request.asset" />}>
        <ChooseAssetInlineModal
          hideCreateButton
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedAsset()}
              onClear={() => this.setState({ asset_id: null })}
            />
          }
          selectedAssetId={this.state.asset_id}
          onSelectAsset={assetId => {
            this.setState({ asset_id: assetId });
          }}
          onClear={e => this.setState({ asset_id: null })}
        />
      </Field>
    );
  };

  renderEditAssignee = () => {
    const { assigned_to_user_id, assigned_to_group_id } = this.state;
    return (
      <AssigneeDropdown
        userId={assigned_to_user_id}
        groupId={assigned_to_group_id}
        onSelectUser={id => {
          this.setState({ assigned_to_group_id: null, assigned_to_user_id: id });
        }}
        onSelectGroup={id => {
          this.setState({ assigned_to_user_id: null, assigned_to_group_id: id });
        }}
        onClear={() => this.setState({ assigned_to_user_id: null, assigned_to_group_id: null })}
      />
    );
  };

  renderEditableField = () => {
    let content = null;
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.RequestType:
        content = this.renderEditRequestType();
        break;
      case BATCH_UPDATABLE_FIELDS.Priority:
        content = this.renderEditPriority();
        break;
      case BATCH_UPDATABLE_FIELDS.Asset:
        content = this.renderEditAsset();
        break;
      case BATCH_UPDATABLE_FIELDS.Assignee:
        content = this.renderEditAssignee();
        break;
      default:
        return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>{content}</Grid.Column>
      </Grid.Row>
    );
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="components.mass-edit-modal.select-field-label" />}>
            <Field.Dropdown clearable={false} value={this.renderSelectedField()}>
              {this.state.updatableFields.map(({ title, key }) => (
                <Field.Dropdown.Item
                  selected={this.state.showEditForField === key}
                  onClick={() => this.selectFieldToUpdate({ showEditForField: key })}
                >
                  <span>{title}</span>
                </Field.Dropdown.Item>
              ))}
            </Field.Dropdown>
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderEditableField()}
      {this.renderConfirmCheckbox()}
    </Grid>
  );

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedRequestCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-requests.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-requests.two"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-requests.few"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-requests.many"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-requests.other"
            values={{ count: this.props.checkedRequestCount }}
          />
        }
      />
    );
  };

  renderConfirmCheckbox = () => {
    if (this.state.showEditForField == null) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.confirmMassEdit}
            label={
              <FormattedMessage
                id="components.mass-edit-modal.i-am-sure"
                values={{
                  count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                }}
              />
            }
            onChange={() => {
              this.setState(prevState => ({
                confirmMassEdit: !prevState.confirmMassEdit,
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderModalContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            <FormattedMessage id="components.mass-edit-modal.quantity-exceeded" />
          </div>
        </Modal.Content>
      );
    }
    return (
      <>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.confirmMassEdit === false}
              loading={this.state.isSaving}
              onClick={this.batchUpdate}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.mass-edit-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    system: AuthSelectors.getCurrentSystem(state),
    filtersAsQueryParams: RequestsSelectors.getFiltersAsQueryParams(state),
    queryParameters: RequestsSelectors.getQueryParameters(state),
    checkedRequestIds: RequestsSelectors.getSelectedRequestIds(state),
    checkedRequestCount: RequestsSelectors.getSelectedRequestCount(state),
    totalEntriesIsSelected: RequestsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default injectIntl(connect(mapStateToProps)(MassEditModal));
