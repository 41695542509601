import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Tooltip, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

const ContactPersonField = ({ contactPerson }) => {
  if (contactPerson == null) {
    return '-';
  }
  return (
    <div className={styles['contact-person']}>
      <p>{contactPerson.name}</p>
      <div className={styles['icons']}>
        <div className={styles['info-container']}>
          {contactPerson.phone ? (
            <Tooltip trigger={<Icon regular type="phone" />} label={contactPerson.phone} />
          ) : (
            <Icon regular type="phone" disabled />
          )}
        </div>
        <div className={styles['info-container']}>
          {contactPerson.email ? (
            <Tooltip trigger={<Icon regular type="envelope" />} label={contactPerson.email} />
          ) : (
            <Icon regular type="envelope" disabled />
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    contactPerson: EntitySelectors.getContactPerson(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(ContactPersonField);
