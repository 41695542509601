import update from 'immutability-helper';
import types from './types';
import { Type } from 'sdk/ChecklistTemplateRow';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  isCreatingChecklistFromTemplatesLibrary: false,
  saveAsReusable: false,
  draggingChecklistTemplateRowId: null,
  isDraggingNewFieldType: null,
  id: null,
  editingField: null,
  rootFieldIds: [],
  childIds: {},
  showSectionCantBeDroppedInModal: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_IS_CREATING_CHECKLIST_FROM_LIBRARY: {
      return {
        ...state,
        isCreatingChecklistFromTemplatesLibrary: action.payload,
      };
    }
    case types.SET_SAVE_AS_REUSABLE: {
      return {
        ...state,
        saveAsReusable: action.payload,
      };
    }
    case types.FETCH_CHECKLIST_TEMPLATE_VERSION: {
      return {
        ...INITIAL_STATE,
        isCreatingChecklistFromTemplatesLibrary: state.isCreatingChecklistFromTemplatesLibrary,
      };
    }
    case types.SHOW_SECTION_CANT_BE_DROPPED_IN_MODAL: {
      return {
        ...state,
        showSectionCantBeDroppedInModal: true,
      };
    }
    case types.HIDE_SECTION_CANT_BE_DROPPED_IN_MODAL: {
      return {
        ...state,
        showSectionCantBeDroppedInModal: false,
      };
    }
    case types.SET_EDITING_CHECKLIST_TEMPLATE_VERSION: {
      const { id, childIds, rootFieldIds } = action.payload;
      return {
        ...state,
        id,
        rootFieldIds,
        childIds,
      };
    }
    case types.ADD_NEW_FIELD_TO_SECTION: {
      const { toSectionId, index, id } = action.payload;
      return update(
        { ...state },
        {
          childIds: {
            [toSectionId]: {
              $splice: [[index || 0, 0, id]],
            },
          },
        }
      );
    }
    case types.ADD_NEW_FIELD_TO_ROOT: {
      const { toIndex, id } = action.payload;
      return update(
        { ...state },
        {
          rootFieldIds: {
            $splice: [[toIndex, 0, id]],
          },
        }
      );
    }
    case types.MOVE_FIELD_FROM_ROOT_TO_SECTION: {
      const { fromIndex, toIndex, sectionId, id } = action.payload;
      return update(
        { ...state },
        {
          rootFieldIds: {
            $splice: [[fromIndex, 1]],
          },
          childIds: {
            [sectionId]: {
              $splice: [[toIndex, 0, id]],
            },
          },
        }
      );
    }
    case types.MOVE_FIELD_FROM_SECTION_TO_ROOT: {
      const { fromIndex, toIndex, sectionId, id } = action.payload;
      return update(
        { ...state },
        {
          rootFieldIds: {
            $splice: [[toIndex, 0, id]],
          },
          childIds: {
            [sectionId]: {
              $splice: [[fromIndex, 1]],
            },
          },
        }
      );
    }
    case types.MOVE_FIELDS_BETWEEN_SECTIONS: {
      const { fromSectionId, toSectionId, fromIndex, toIndex, id } = action.payload;
      return update(
        { ...state },
        {
          childIds: {
            [fromSectionId]: {
              $splice: [[fromIndex, 1]],
            },
            [toSectionId]: {
              $splice: [[toIndex, 0, id]],
            },
          },
        }
      );
    }
    case types.SORT_FIELD_OUTSIDE_SECTION: {
      const { fromIndex, toIndex, id } = action.payload;
      return update(
        { ...state },
        {
          rootFieldIds: {
            $splice: [
              [fromIndex, 1],
              [toIndex, 0, id],
            ],
          },
        }
      );
    }
    case types.SORT_FIELD_IN_SECTION: {
      const { fromIndex, toIndex, id, sectionId } = action.payload;
      return update(
        { ...state },
        {
          childIds: {
            [sectionId]: {
              $splice: [
                [fromIndex, 1],
                [toIndex, 0, id],
              ],
            },
          },
        }
      );
    }
    case types.BEGIN_DRAG: {
      return {
        ...state,
        draggingChecklistTemplateRowId: action.payload,
      };
    }
    case types.END_DRAG: {
      return {
        ...state,
        draggingChecklistTemplateRowId: null,
        isDraggingNewFieldType: null,
      };
    }
    case types.PREPARE_NEW_FIELD: {
      return {
        ...state,
        isDraggingNewFieldType: action.payload,
        draggingChecklistTemplateRowId: null,
      };
    }
    case types.PREPARE_NEW_FIELD_FOR_EDIT: {
      return {
        ...state,
        editingField: action.payload,
        draggingChecklistTemplateRowId: null,
      };
    }
    case types.UPDATE_EDITING_FIELD: {
      return update(state, {
        editingField: {
          $merge: action.payload,
        },
      });
    }
    case types.EDIT_FIELD: {
      return update(state, {
        editingField: {
          $set: action.payload,
        },
        rootFieldIds: {
          $apply: rootFieldIds => {
            return rootFieldIds.filter(id => id !== 'new');
          },
        },
        childIds: {
          $apply: fieldIds => {
            let newFieldIds = { ...fieldIds };
            Object.keys(fieldIds).forEach(id => {
              newFieldIds[id] = fieldIds[id].filter(id => id !== 'new');
            });
            return newFieldIds;
          },
        },
      });
    }
    case SDKReduxTypes.IMAGE_DRAFT_CREATED: {
      const { data: image } = action.payload;
      if (state.editingField && state.editingField.id === 'new') {
        return update(state, {
          editingField: {
            images: {
              $push: [image.id],
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_CHECKLIST_TEMPLATE_ROW: {
      const { data } = action.payload;
      const { checklist_template_row_id, id } = data;
      if (state.editingField == null) return state;
      if (state.editingField.id === checklist_template_row_id) {
        return update(state, {
          editingField: {
            images: {
              $push: [id],
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { attachment_id } = data;
      const { checklistTemplateRowId } = meta;
      if (state.editingField == null) return state;
      if (state.editingField.id === checklistTemplateRowId) {
        return update(state, {
          editingField: {
            attachments: {
              $push: [attachment_id],
            },
          },
        });
      }

      return state;
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_CHECKLIST_TEMPLATE_ROW: {
      const { checklistTemplateRowId, data, params } = action.payload;
      if (state.editingField == null) return state;
      if (state.editingField.id === checklistTemplateRowId) {
        if (params.type === 'link') {
          return update(state, {
            editingField: {
              attachments: {
                $push: [data.id],
              },
            },
          });
        }
      }

      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DRAFT_CREATED: {
      const { params, data: attachment } = action.payload;
      if (state.editingField && state.editingField.id === 'new') {
        if (params.type === 'link') {
          return update(state, {
            editingField: {
              attachments: {
                $push: [attachment.id],
              },
            },
          });
        }
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_CHECKLIST_TEMPlATE_ROW: {
      const { checklistTemplateRowId, attachmentId } = action.payload;
      if (state.editingField == null) return state;
      if (state.editingField.id === checklistTemplateRowId) {
        return {
          ...state,
          editingField: {
            ...state.editingField,
            attachments: state.editingField.attachments.filter(id => id !== attachmentId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId } = action.payload;
      if (state.editingField == null) return state;

      return {
        ...state,
        editingField: {
          ...state.editingField,
          images: state.editingField.images.filter(id => id !== imageId),
        },
      };
    }
    case types.CANCEL_EDIT_FIELD: {
      return update(state, {
        editingField: {
          $set: null,
        },
        rootFieldIds: {
          $apply: rootFieldIds => {
            return rootFieldIds.filter(id => id !== 'new');
          },
        },
        childIds: {
          $apply: fieldIds => {
            let newFieldIds = { ...fieldIds };
            Object.keys(fieldIds).forEach(id => {
              newFieldIds[id] = fieldIds[id].filter(id => id !== 'new');
            });
            return newFieldIds;
          },
        },
      });
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_ROW_CREATED_FOR_CHECKLIST_TEMPLATE_VERISON: {
      const { parent_row_id, sort, id } = action.payload.data;
      if (parent_row_id) {
        return update(state, {
          childIds: {
            [parent_row_id]: {
              $splice: [[sort - 1, 0, id]],
            },
          },
        });
      }
      return update(
        { ...state },
        {
          rootFieldIds: {
            $splice: [[sort - 1, 0, id]],
          },
          childIds: {
            [id]: {
              $set: [],
            },
          },
        }
      );
    }
    case SDKReduxTypes.CHECKLIST_TEMPLATE_ROW_DELETED: {
      const { id, type, parent_row_id } = action.payload.data;
      if (type === Type.Section) {
        return update(state, {
          rootFieldIds: {
            $apply: rootFieldIds => {
              return rootFieldIds.filter(sectionId => sectionId !== id);
            },
          },
          childIds: {
            $unset: [id],
          },
        });
      }
      if (parent_row_id) {
        return update(state, {
          childIds: {
            [parent_row_id]: {
              $apply: rootFieldIds => {
                return rootFieldIds.filter(sectionId => sectionId !== id);
              },
            },
          },
        });
      }
      return update(
        { ...state },
        {
          rootFieldIds: {
            $apply: rootFieldIds => {
              return rootFieldIds.filter(sectionId => sectionId !== id);
            },
          },
        }
      );
    }
    default:
      return state;
  }
};
