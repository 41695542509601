import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class StatusIcon extends Component {
  renderNotStartedIcon = () => (
    <div className={[styles['container'], styles['not-started']].join(' ')}>
      <Icon regular type="check" size={10} />
    </div>
  );

  renderMissedIcon = () => (
    <div className={[styles['container'], styles['missed']].join(' ')}>
      <Icon regular type="times" size={10} />
    </div>
  );

  renderSkippedIcon = () => (
    <div className={[styles['container'], styles['skipped']].join(' ')}>
      <Icon regular type="times" size={10} />
    </div>
  );

  renderCompletedIcon = () => (
    <div className={[styles['container'], styles['completed']].join(' ')}>
      <Icon regular type="check" size={10} />
    </div>
  );

  render() {
    if (this.props.operationalMaintenanceInstance) {
      if (this.props.operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Skipped) {
        return this.renderSkippedIcon();
      }
      if (this.props.operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed) {
        return this.renderCompletedIcon();
      }
      if (
        moment(this.props.operationalMaintenanceInstance.due_date).isBefore(
          moment().tz(this.props.system.timezone),
          'day'
        )
      ) {
        return this.renderMissedIcon();
      }
    }
    return this.renderNotStartedIcon();
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.id == null) {
    return {};
  }
  const operationalMaintenance = EntitySelectors.getOperationalMaintenance(state, ownProps.id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    operationalMaintenance.operational_maintenance_instance_id
  );

  return {
    system: AuthSelectors.getCurrentSystem(state),
    operationalMaintenance,
    operationalMaintenanceInstance,
  };
}

export default connect(mapStateToProps)(StatusIcon);
