import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { List, Button, InlineModal, Icon } from 'views/components/Shared/General';
import PurchaseOrderDeliveryListItemRow from './PurchaseOrderDeliveryListItemRow';
import styles from './style.module.scss';

class PurchaseOrderDeliveryListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  renderExpandedComponent = () => (
    <div className={styles['delivery-rows']}>
      {this.props.purchaseOrderDeliveryRows.map(purchaseOrderDeliveryRow => (
        <PurchaseOrderDeliveryListItemRow purchaseOrderDeliveryRow={purchaseOrderDeliveryRow} />
      ))}
    </div>
  );

  render() {
    return (
      <List.Item
        expandable
        expandedComponent={this.renderExpandedComponent()}
        clickable={this.props.canCreateWithdrawals}
        onClick={this.props.onEdit}
        new={this.props.new}
        iconButtons={
          <>
            <div
              ref={ref => (this.inlineModalPositioningRef = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  dropdownOpen: !prevState.dropdownOpen,
                }));
              }}
            >
              <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
            </div>
            <InlineModal
              positionToRef={this.inlineModalPositioningRef}
              open={this.state.dropdownOpen}
              onClose={() => this.setState({ dropdownOpen: false })}
              position="right"
            >
              <InlineModal.Body width={250} dropdown>
                {this.props.canCreateWithdrawals ? (
                  <InlineModal.ListItem
                    icon="pen"
                    iconThickness="regular"
                    title={<FormattedMessage id="general.edit" />}
                    onClick={() => {
                      this.setState({ dropdownOpen: false });
                      this.props.onEdit();
                    }}
                  />
                ) : null}

                <Link to={`/purchase-orders/${this.props.purchaseOrder.id}`}>
                  <InlineModal.ListItem
                    icon="arrow-alt-right"
                    iconThickness="regular"
                    title={<FormattedMessage id="screens.previous-deliveries.navigate-to-purchase-order" />}
                  />
                </Link>
              </InlineModal.Body>
            </InlineModal>
          </>
        }
      >
        <List.Item.TitleColumn
          title={this.props.vendor.name}
          subtitle={`#${this.props.purchaseOrder.number}`}
        />
        <List.Item.Column width={150}>
          {moment(this.props.purchaseOrderDelivery.created_at).format('LLL')}
        </List.Item.Column>
        <List.Item.Column width={150}>
          {this.props.createdByUser ? this.props.createdByUser.name : '-'}
        </List.Item.Column>
        <List.Item.Column alignRight width={150}>
          <FormattedPlural
            value={this.props.purchaseOrderDeliveryRows.length}
            one={
              <FormattedMessage
                id="screens.previous-deliveries.amount-of-articles.one"
                values={{ amount: this.props.purchaseOrderDeliveryRows.length }}
              />
            }
            two={
              <FormattedMessage
                id="screens.previous-deliveries.amount-of-articles.two"
                values={{ amount: this.props.purchaseOrderDeliveryRows.length }}
              />
            }
            few={
              <FormattedMessage
                id="screens.previous-deliveries.amount-of-articles.few"
                values={{ amount: this.props.purchaseOrderDeliveryRows.length }}
              />
            }
            many={
              <FormattedMessage
                id="screens.previous-deliveries.amount-of-articles.many"
                values={{ amount: this.props.purchaseOrderDeliveryRows.length }}
              />
            }
            other={
              <FormattedMessage
                id="screens.previous-deliveries.amount-of-articles.other"
                values={{ amount: this.props.purchaseOrderDeliveryRows.length }}
              />
            }
          />
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderDelivery = EntitySelectors.getPurchaseOrderDelivery(
    state,
    ownProps.purchaseOrderDeliveryId
  );
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, purchaseOrderDelivery.purchase_order_id);
  return {
    purchaseOrderDelivery,
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor_id),
    createdByUser: EntitySelectors.getUser(state, purchaseOrderDelivery.created_by_user_id),
    purchaseOrderDeliveryRows: EntitySelectors.getPurchaseOrderDeliveryRows(
      state,
      purchaseOrderDelivery.purchase_order_delivery_rows
    ),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps)(PurchaseOrderDeliveryListItem);
