import { merge } from 'lodash-es';
import update from 'immutability-helper';
import { SDKReduxTypes } from 'sdk';
import types from './types';

const INITIAL_STATE = {
  isFetching: false,
  ids: [],
  createdIds: [],
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_SPARE_PART_TYPES:
      return {
        ...state,
        createdIds: [],
        isFetching: true,
      };
    case types.FETCH_SPARE_PART_TYPES_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        isFetching: false,
        ids,
      };
    }
    case SDKReduxTypes.SPARE_PART_TYPE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        ids: [data.id, ...state.ids],
        createdIds: [data.id, ...state.createdIds],
      };
    }
    case SDKReduxTypes.SPARE_PART_TYPE_DELETED: {
      const { sparePartTypeId } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(id => id !== sparePartTypeId),
      };
    }
    default:
      return state;
  }
};
