import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { Header, ContentContainer } from 'views/components/Shared/Layout';
import { Icon } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import CostStatistics from 'assets/images/CostStatistics.png';
import RequestStatistics from 'assets/images/RequestStatistics.png';
import AssetStatistics from 'assets/images/AssetStatistics.png';
import SparePartStatistics from 'assets/images/SparePartStatistics.png';
import WorkOrderStatistics from 'assets/images/WorkOrderStatistics.png';
import styles from './style.module.scss';

class Dashboard extends Component {
  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Statistics);
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.statistics-dashboard.document-title' })
    );
  }

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header
            maxWidth={960}
            smallTitle
            title={<FormattedMessage id="screens.statistics-dashboard.title" />}
          />
          <ContentContainer maxWidth={960}>
            <div className={styles['items']}>
              {this.props.selectedSystem.costs_activated ? (
                <div className={styles['item-container']}>
                  <Link to="/statistics/costs">
                    <div className={styles['item']}>
                      <div className={styles['image-container']}>
                        <img src={CostStatistics} alt="type" />
                      </div>
                      <div className={styles['text-container']}>
                        <div className={styles['texts']}>
                          <div className={styles['title']}>
                            <FormattedMessage id="screens.statistics-dashboard.costs.title" />
                          </div>
                          <div className={styles['subtitle']}>
                            <FormattedMessage id="screens.statistics-dashboard.costs.subtitle" />
                          </div>
                        </div>
                        <div className={styles['disclosure-indicator']}>
                          <Icon light type="angle-right" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {this.props.settings.work_order_spent_time_activated &&
              (this.props.isAdmin || this.props.isViewOnly) ? (
                <div className={styles['item-container']}>
                  <Link to="/statistics/spent-time">
                    <div className={styles['item']}>
                      <div className={styles['image-container']}>
                        <img src={WorkOrderStatistics} alt="type" />
                      </div>
                      <div className={styles['text-container']}>
                        <div className={styles['texts']}>
                          <div className={styles['title']}>
                            <FormattedMessage id="screens.statistics-dashboard.reported-time.title" />
                          </div>
                          <div className={styles['subtitle']}>
                            <FormattedMessage id="screens.statistics-dashboard.reported-time.subtitle" />
                          </div>
                        </div>
                        <div className={styles['disclosure-indicator']}>
                          <Icon light type="angle-right" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {this.props.isAdmin || this.props.isViewOnly ? (
                <div className={styles['item-container']}>
                  <Link to="/statistics/requests">
                    <div className={styles['item']}>
                      <div className={styles['image-container']}>
                        <img src={RequestStatistics} alt="type" />
                      </div>
                      <div className={styles['text-container']}>
                        <div className={styles['texts']}>
                          <div className={styles['title']}>
                            <FormattedMessage id="screens.statistics-dashboard.requests.title" />
                          </div>
                          <div className={styles['subtitle']}>
                            <FormattedMessage id="screens.statistics-dashboard.requests.subtitle" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              {this.props.isAdmin || this.props.isViewOnly ? (
                <div className={styles['item-container']}>
                  <Link to="/statistics/work-orders">
                    <div className={styles['item']}>
                      <div className={styles['image-container']}>
                        <img src={WorkOrderStatistics} alt="type" />
                      </div>
                      <div className={styles['text-container']}>
                        <div className={styles['texts']}>
                          <div className={styles['title']}>
                            <FormattedMessage id="screens.statistics-dashboard.work-orders.title" />
                          </div>
                          <div className={styles['subtitle']}>
                            <FormattedMessage id="screens.statistics-dashboard.work-orders.subtitle" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
              <div className={styles['item-container']}>
                <div className={`${styles['item']} ${styles['coming-soon']}`}>
                  <div className={styles['image-container']}>
                    <img src={AssetStatistics} alt="type" />
                  </div>
                  <div className={styles['text-container']}>
                    <div className={styles['texts']}>
                      <div className={styles['title']}>
                        <FormattedMessage id="screens.statistics-dashboard.assets.title" />
                      </div>
                      <div className={styles['subtitle']}>
                        <FormattedMessage id="screens.statistics-dashboard.assets.subtitle" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['is-late-badge']}>
                    <FormattedMessage id="screens.statistics-dashboard.coming-soon" />
                  </div>
                </div>
              </div>
              <div className={styles['item-container']}>
                <div className={`${styles['item']} ${styles['coming-soon']}`}>
                  <div className={styles['image-container']}>
                    <img src={SparePartStatistics} alt="type" />
                  </div>
                  <div className={styles['text-container']}>
                    <div className={styles['texts']}>
                      <div className={styles['title']}>
                        <FormattedMessage id="screens.statistics-dashboard.spare-parts.title" />
                      </div>
                      <div className={styles['subtitle']}>
                        <FormattedMessage id="screens.statistics-dashboard.spare-parts.subtitle" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['is-late-badge']}>
                    <FormattedMessage id="screens.statistics-dashboard.coming-soon" />
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </PerfectScrollbar>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    isAdmin: AuthSelectors.isAdmin(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
