import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { normalizeAsset } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { SelectAssetModal } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API, SDKReduxOperations } from 'sdk';
import AssetModalListItem from './AssetModalListItem';
import styles from './style.module.scss';

class RelatedAssetsModal extends Component {
  state = {
    isFetching: true,
    assetIds: [],
    createdAssetIds: [],
    showAddMoreAssetsContent: false,
    isAddingAssets: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        isFetching: true,
        assetIds: [],
        createdAssetIds: [],
        showAddMoreAssetsContent: false,
        isAddingAssets: false,
      });

      API.listAssets(this.props.currentSystem.id, {
        no_pagination: true,
        attachment_id: this.props.attachment.id,
      }).then(res => {
        const { data: assets } = res;
        const { entities, result: assetIds } = normalizeAsset(assets);
        this.props.updateEntities(entities);
        this.setState({ isFetching: false, assetIds });
      });
    }
  }

  addMultipleAssetsToAttachment = assetIds => {
    this.setState({ isAddingAssets: true });
    this.props
      .addMultipleAssetsToAttachment(this.props.attachment.id, {
        asset_ids: assetIds.join(),
      })
      .then(({ data: assets }) => {
        const { entities, result } = normalizeAsset(assets);
        this.props.updateEntities(entities);
        this.setState({
          isAddingAssets: false,
          showAddMoreAssetsContent: false,
          createdAssetIds: [...result, ...this.state.createdAssetIds],
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.file-related-assets.add-assets-success" />}
          />
        );
      });
  };

  deleteAssetFromAttachment = assetId => {
    this.setState({ isDeleting: true });
    this.props
      .removeAttachmentFromAsset(assetId, this.props.attachment.id)
      .then(() => {
        this.setState({
          isDeleting: false,
          assetIds: this.state.assetIds.filter(loopedAssetId => loopedAssetId !== assetId),
          createdAssetIds: this.state.createdAssetIds.filter(loopedAssetId => loopedAssetId !== assetId),
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.file-related-assets.delete-success" />}
          />
        );
      })
      .catch(e => {
        if (e.response && e.response.data.errors.code === '90006') {
          toast(
            <ToastMessage
              error
              text={
                <FormattedMessage id="components.file-related-assets.cant-delete-last-asset-from-attachment" />
              }
            />
          );
        }
        this.setState({ isDeleting: false, isOpen: false });
      });
  };

  renderSelectAssetModal = () => (
    <SelectAssetModal
      isOpen={this.state.showAddMoreAssetsContent}
      inactiveAssetIds={[...this.state.assetIds, ...this.state.createdAssetIds]}
      onClose={() => this.setState({ showAddMoreAssetsContent: false })}
      loading={this.state.isAddingAssets}
      onSelectAssetIds={ids => {
        if (ids.length === 0) {
          this.setState({ showAddMoreAssetsContent: false });
        } else {
          const filteredIds = ids.filter(id => !this.state.assetIds.includes(id));
          this.addMultipleAssetsToAttachment(filteredIds);
        }
      }}
    />
  );

  renderToolbar = () => {
    if (this.props.isViewOnly === false) {
      return (
        <div className={styles['toolbar']}>
          <Button
            small
            primary
            onClick={() => this.setState({ showAddMoreAssetsContent: true })}
            label="components.file-related-assets.add-assets"
          />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} width={600}>
          <Modal.Header
            title={<FormattedMessage id="components.file-related-assets.title" />}
            onClose={this.props.onClose}
          />
          {this.state.isFetching ? (
            <Modal.Loader noHeader />
          ) : (
            <Modal.Content>
              <div className={styles['description-container']}>
                <p className={styles['description']}>Här kan du hantera de objekt som använder denna fil</p>
              </div>

              {this.renderToolbar()}
              <div>
                {this.state.createdAssetIds.map(assetId => (
                  <AssetModalListItem
                    created
                    id={assetId}
                    isDeleting={this.state.isDeleting}
                    onDelete={() => this.deleteAssetFromAttachment(assetId)}
                  />
                ))}
                {this.state.assetIds.map(assetId => (
                  <AssetModalListItem
                    id={assetId}
                    isDeleting={this.state.isDeleting}
                    onDelete={() => this.deleteAssetFromAttachment(assetId)}
                  />
                ))}
              </div>
            </Modal.Content>
          )}
        </Modal>
        {this.renderSelectAssetModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAttachmentFromAsset: SDKReduxOperations.removeAttachmentFromAsset,
      addMultipleAssetsToAttachment: SDKReduxOperations.addMultipleAssetsToAttachment,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RelatedAssetsModal));
