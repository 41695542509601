import React from 'react';
import { Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

const Toggle = props => {
  let classNames = [styles['container']];
  if (props.checked) {
    classNames = [...classNames, styles['checked']];
  }
  if (props.disabled) {
    classNames = [...classNames, styles['disabled']];
  }

  return (
    <div className={styles['wrapper']}>
      <div
        onClick={e => {
          if (props.disabled !== true) {
            props.onClick(e);
          }
        }}
        className={classNames.join(' ')}
      >
        {props.loading ? (
          <div className={styles['loader']}>
            <Loader tiny />
          </div>
        ) : null}
        <div className={styles['background']} />
        <div className={styles['toggle']} />
      </div>
    </div>
  );
};

export default Toggle;
