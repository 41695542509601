import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Button, InlineModal, Icon } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class EditPaymentTermModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      hasUnsavedChanges: false,
      showDeleteInlineModal: false,
      showUsedError: false,
      editingPaymentTerm: {},
      editingPaymentTermBeforeEdit: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        isDeleting: false,
        hasUnsavedChanges: false,
        showDeleteInlineModal: false,
        showUsedError: false,
      });

      const { paymentTerm } = this.props;
      if (paymentTerm) {
        const editingPaymentTerm = {
          title: paymentTerm.title,
          default: paymentTerm.default,
        };
        this.setState({
          editingPaymentTerm,
          editingPaymentTermBeforeEdit: editingPaymentTerm,
        });
      } else {
        const editingPaymentTerm = {
          title: '',
          default: false,
        };
        this.setState({
          editingPaymentTerm,
          editingWorkOrderBeforeEdit: editingPaymentTerm,
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;

    if (this.props.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    this.setState({ isSaving: true });

    this.props
      .createPaymentTerm(this.props.system.id, this.state.editingPaymentTerm)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.purchase-orders.payment-terms.create-success" />}
          />
        );
        this.props.onCreate(data.id);
      })
      .catch(e => {});
  };

  update = () => {
    this.setState({ isSaving: true });
    this.props
      .updatePaymentTerm(this.props.id, this.state.editingPaymentTerm)
      .then(({ data }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {});
  };

  delete = () => {
    this.setState({ isDeleting: true });
    this.props
      .deletePaymentTerm(this.props.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.purchase-orders.payment-terms.delete-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '110002' })) {
          this.setState({ showUsedError: true, isDeleting: false });
        }
      });
  };

  setValue = values => {
    const editingPaymentTerm = {
      ...this.state.editingPaymentTerm,
      ...values,
    };

    const hasUnsavedChanges = !isEqual(this.state.editingPaymentTermBeforeEdit, editingPaymentTerm);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({ editingPaymentTerm, hasUnsavedChanges });
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderDeleteContent = () => {
    if (this.props.id == null) return null;
    if (this.state.showUsedError) {
      return (
        <div className={styles['already-used-error']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.settings.purchase-orders.payment-terms.delete-used-error.title" />
          </div>
          <div className={styles['message']}>
            <FormattedMessage id="screens.settings.purchase-orders.payment-terms.delete-used-error.message" />
          </div>
        </div>
      );
    }
    return (
      <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.delete}>
        <FormattedMessage id="general.delete-confirm.subtitle" />
      </InlineModal.Delete>
    );
  };

  renderDeleteInlineModal = () => {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>
          <Button
            type="icon"
            icon={<Icon regular red type="trash-alt" />}
            onClick={() =>
              this.setState(prevState => ({
                confirmDelete: false,
                showUsedError: false,
                showDeleteInlineModal: !prevState.showDeleteInlineModal,
              }))
            }
          />
        </div>
        <InlineModal
          open={this.state.showDeleteInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showDeleteInlineModal: false })}
        >
          <InlineModal.Header
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showDeleteInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="screens.settings.purchase-orders.payment-terms.edit-title" />
            ) : (
              <FormattedMessage id="screens.settings.purchase-orders.payment-terms.new-title" />
            )
          }
          iconButtons={this.props.id == null ? null : this.renderDeleteInlineModal()}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.payment-term.title" />}>
                  <Field.Text
                    autoFocus
                    error={this.state.showTitleError}
                    value={this.state.editingPaymentTerm.title}
                    onKeyPress={this.handleKeyPress}
                    onChange={title => {
                      this.setValue({ title });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field.Checkbox
                  checked={this.state.editingPaymentTerm.default}
                  onChange={value => {
                    this.setValue({ default: value });
                  }}
                  label={<FormattedMessage id="resources.payment-term.default" />}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
              disabled={!this.state.editingPaymentTerm.title}
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPaymentTerm: SDKReduxOperations.createPurchaseOrderPaymentTerm,
      updatePaymentTerm: SDKReduxOperations.updatePurchaseOrderPaymentTerm,
      deletePaymentTerm: SDKReduxOperations.deletePurchaseOrderPaymentTerm,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    paymentTerm: EntitySelectors.getPurchaseOrderPaymentTerm(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditPaymentTermModal));
