import types from './types';

const setFieldErrors = data => ({
  type: types.SET_FIELD_ERRORS,
  payload: data,
});

const removeFieldsFromErrors = fields => ({
  type: types.REMOVE_FIELDS_FROM_ERRORS,
  payload: fields,
});

export default {
  setFieldErrors,
  removeFieldsFromErrors,
};
