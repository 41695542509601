import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, Field, InlineModal, Banner } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { Separator } from 'views/scenes/Settings/components';
import { AssigneeDropdown } from 'views/components/Request';
import { MembersModal } from 'views/components/Group';
import { AuthSelectors } from 'state/ducks/auth';
import RequestSelectableAssigneeListItem from './RequestSelectableAssigneeListItem';
import EditSelectableAssigneeModal from './EditSelectableAssigneeModal';
import SelectUserModal from './SelectUserModal';
import ConfirmUserModal from './ConfirmUserModal';
import SelectedAssignee from './SelectedAssignee';
import styles from './style.module.scss';

class CreatorChooses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isSaving: false,
      showEditSelectableAssigneeModal: false,
      showEditSelectableAssigneeModalForId: null,
      showMembersModal: false,
      showMembersModalForGroupId: null,
      showBottomSaveBar: false,
      isActivatingReceiverOption: false,
      showSelectUsersModal: false,
      showConfirmUserModal: false,
      showConfirmUserModalForUserId: null,
      showAssigneeDropdown: false,
    };
  }

  componentDidMount() {
    this.props.fetchRequestSelectableAssignees(this.props.system.id, { no_pagination: true }).then(() => {
      this.setState({ isFetching: false });
    });
  }

  renderReceiversListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.receiver-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderReceiversList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderReceiversListHeader()}
          <List small>
            <RequestSelectableAssigneeListItem loading />
            <RequestSelectableAssigneeListItem loading />
          </List>
        </>
      );
    }
    if (this.props.requestSelectableAssignees.length === 0) {
      return (
        <EmptyDataSet
          title={
            <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.empty-data-set.subtitle" />
          }
          modal
        />
      );
    }
    return (
      <>
        {this.renderReceiversListHeader()}
        <List small>
          {this.props.requestSelectableAssignees.map(requestSelectableAssignee => (
            <RequestSelectableAssigneeListItem
              requestSelectableAssignee={requestSelectableAssignee}
              onSelect={() => {
                this.setState({
                  showEditSelectableAssigneeModal: true,
                  showEditSelectableAssigneeModalForId: requestSelectableAssignee.id,
                });
              }}
              onManageMembers={() => {
                this.setState({
                  showMembersModal: true,
                  showMembersModalForGroupId: requestSelectableAssignee.group_id,
                });
              }}
            />
          ))}
        </List>
      </>
    );
  };

  renderNoAssigneeReceiverDropdown = () => {
    const { request_default_assignee_user_id, request_default_assignee_group_id } =
      this.props.editingSettings;
    if (this.props.editingSettings.request_allow_skip_assignee) {
      return (
        <div className={styles['dropdown-container']}>
          <Field
            view={false}
            label={
              <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.unassigned-request-assignee" />
            }
          >
            <div
              ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  showAssigneeDropdown: !prevState.showAssigneeDropdown,
                }));
              }}
            >
              <Field.Resource
                value={
                  <SelectedAssignee
                    userId={request_default_assignee_user_id}
                    groupId={request_default_assignee_group_id}
                  />
                }
                clearable={request_default_assignee_user_id || request_default_assignee_group_id}
                angleDown
                onClear={() => {
                  this.props.onChange({
                    request_default_assignee_group_id: null,
                    request_default_assignee_user_id: null,
                  });
                }}
              />
            </div>
            <AssigneeDropdown
              positionToRef={this.assigneeDropdownInlineModalPositioningRef}
              open={this.state.showAssigneeDropdown}
              userId={request_default_assignee_user_id}
              groupId={request_default_assignee_group_id}
              onClose={() => this.setState({ showAssigneeDropdown: false })}
              onSelectUser={id => {
                this.setState({ showAssigneeDropdown: false });
                this.props.onChange({
                  request_default_assignee_user_id: id,
                  request_default_assignee_group_id: null,
                });
              }}
              onSelectGroup={id => {
                this.setState({ showAssigneeDropdown: false });
                this.props.onChange({
                  request_default_assignee_group_id: id,
                  request_default_assignee_user_id: null,
                });
              }}
              onClear={() => {
                this.setState({ showAssigneeDropdown: false });
                this.props.onChange({
                  request_default_assignee_group_id: null,
                  request_default_assignee_user_id: null,
                });
              }}
            />
          </Field>
        </div>
      );
    }
    return null;
  };

  renderContent = () => {
    return (
      <>
        <Toolbar
          buttons={
            <>
              <div ref={ref => (this.addReceiverInlineModalRef = ref)} className={styles['toolbar-button']}>
                <Button
                  primary
                  label="screens.settings.requests.assignees.creator-chooses.add-button"
                  onClick={() =>
                    this.setState(prevState => ({
                      showAddReceiverDropdown: !prevState.showAddReceiverDropdown,
                    }))
                  }
                />
              </div>
              <InlineModal
                open={this.state.showAddReceiverDropdown}
                positionToRef={this.addReceiverInlineModalRef}
                onClose={() => this.setState({ showAddReceiverDropdown: false })}
              >
                <InlineModal.Body width={250} dropdown>
                  <InlineModal.ListItem
                    title={
                      <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.add-group" />
                    }
                    onClick={() => {
                      this.setState({
                        showAddReceiverDropdown: false,
                        showEditSelectableAssigneeModal: true,
                        showEditSelectableAssigneeModalForId: null,
                      });
                    }}
                  />
                  <InlineModal.ListItem
                    title={
                      <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.add-user" />
                    }
                    onClick={() => {
                      this.setState({ showSelectUsersModal: true, showAddReceiverDropdown: false });
                    }}
                  />
                </InlineModal.Body>
              </InlineModal>
            </>
          }
        />

        {this.renderReceiversList()}
        <Separator dots />
        <Field.Checkbox
          checked={this.props.editingSettings.request_allow_skip_assignee}
          label={<FormattedMessage id="screens.settings.requests.assignees.creator-chooses.skip" />}
          questionTooltipContent={
            <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.skip-tooltip" />
          }
          onChange={() =>
            this.props.onChange({
              request_allow_skip_assignee: !this.props.editingSettings.request_allow_skip_assignee,
            })
          }
        />

        {this.renderNoAssigneeReceiverDropdown()}
      </>
    );
  };

  render() {
    const { request_production_supervisor_approval_activated, production_board_activated } =
      this.props.settings;
    if (request_production_supervisor_approval_activated && production_board_activated) {
      return (
        <Banner orange>
          <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.approve-by-production-supervisor-activated-error" />
        </Banner>
      );
    }

    return (
      <>
        {this.renderContent()}
        <EditSelectableAssigneeModal
          open={this.state.showEditSelectableAssigneeModal}
          id={this.state.showEditSelectableAssigneeModalForId}
          onClose={() => this.setState({ showEditSelectableAssigneeModal: false })}
        />
        <MembersModal
          open={this.state.showMembersModal}
          groupId={this.state.showMembersModalForGroupId}
          onClose={() => this.setState({ showMembersModal: false })}
        />
        <SelectUserModal
          open={this.state.showSelectUsersModal}
          onClose={() => this.setState({ showSelectUsersModal: false })}
          onSelect={id => {
            this.setState({ showSelectUsersModal: false });
            setTimeout(() => {
              this.setState({ showConfirmUserModal: true, showConfirmUserModalForUserId: id });
            }, 250);
          }}
        />
        <ConfirmUserModal
          open={this.state.showConfirmUserModal}
          id={this.state.showConfirmUserModalForUserId}
          onClose={() => this.setState({ showConfirmUserModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRequestSelectableAssignees: SettingsOperations.fetchRequestSelectableAssignees,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    requestSelectableAssignees: SettingsSelectors.getRequestSelectableAssignees(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreatorChooses));
