import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const resetSelectedAssets = payload => ({ type: types.RESET_SELECTED_ASSETS, payload });
const selectAsset = payload => ({ type: types.SELECT_ASSET, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchAssetsSuccess = ({ ids, entities, pagination }) => ({
  type: types.FETCH_ASSETS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});

const fetchTree = () => ({ type: types.FETCH_TREE });

const fetchTreeSuccess = treeNodes => ({
  type: types.FETCH_TREE_SUCCESS,
  payload: treeNodes,
});

const expandTree = assetId => ({
  type: types.EXPAND_TREE,
  payload: assetId,
});

const moveTree = (source, destination) => ({
  type: types.MOVE_TREE,
  payload: { source, destination },
});

const collapseTree = assetId => ({
  type: types.COLLAPSE_TREE,
  payload: assetId,
});

export default {
  setNavigatedTo,
  showAppliedFilters,
  resetSelectedAssets,
  selectPage,
  selectAsset,
  selectTotalEntries,
  hideSelectTotalEntries,
  selectFilterType,
  showAvailableFilters,
  addFilter,
  removeFilter,
  resetFilter,
  addQueryParameter,
  fetchAssetsSuccess,
  fetchTree,
  fetchTreeSuccess,
  expandTree,
  collapseTree,
  moveTree,
};
