import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineModal } from 'views/components/Shared/General';
import { MoveFolderInlineModalContent } from 'views/components/Attachment';

export default class ChangeFolderInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          ref={ref => (this.inlineModalRef = ref)}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          <React.Fragment>
            <MoveFolderInlineModalContent
              selectFolderButtonTranslationId="general.choose"
              folderId={this.props.folderId}
              title={<FormattedMessage id="components.new-attachment.choose-folder-title" />}
              defaultOpenFolderId={this.props.folderId}
              onClose={() => this.setState({ isOpen: false })}
              menuButtonComponent={
                this.props.folderId == null ? null : (
                  <InlineModal.HeaderButton
                    primary
                    onClick={() => {
                      this.setState({ isOpen: false });
                      this.props.onSelectFolder();
                    }}
                    title={<FormattedMessage id="general.clean" />}
                  />
                )
              }
              onSelectFolder={folderId => {
                this.setState({ isOpen: false });
                this.props.onSelectFolder(folderId);
              }}
              onSizeWasChanged={() => this.inlineModalRef.setInlineModalHorizontalPositions()}
            />
          </React.Fragment>
        </InlineModal>
      </>
    );
  }
}
