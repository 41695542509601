import actions from './actions';
import { normalizeAssetBookmark } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API } from 'sdk';

const selectDropdownType = actions.selectDropdownType;
const selectAssetId = actions.selectAssetId;

const fetchBookmarkedAssets = systemId => dispatch => {
  return API.listAssetBookmarks(systemId).then(({ data: assets }) => {
    const { entities, result } = normalizeAssetBookmark(assets);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchBookmarkedAssetsSuccess({
        ids: result,
      })
    );
    return assets;
  });
};

export default {
  fetchBookmarkedAssets,
  selectDropdownType,
  selectAssetId,
};
