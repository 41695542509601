const DOMAIN_PREFIX = 'workOrderAssignee/';

const FETCH_USERS = `${DOMAIN_PREFIX}fetch_users`;
const FETCH_USERS_SUCCESS = `${DOMAIN_PREFIX}fetch_users_success`;
const FETCH_GROUPS = `${DOMAIN_PREFIX}fetch_groups`;
const FETCH_GROUPS_SUCCESS = `${DOMAIN_PREFIX}fetch_groups_success`;
const FETCH_VENDORS = `${DOMAIN_PREFIX}fetch_vendors`;
const FETCH_VENDORS_SUCCESS = `${DOMAIN_PREFIX}fetch_vendors_success`;

export default {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_GROUPS,
  FETCH_GROUPS_SUCCESS,
  FETCH_VENDORS,
  FETCH_VENDORS_SUCCESS,
};
