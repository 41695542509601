import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Menu, List, Pagination } from 'views/components/Shared/General';
import UserListItem from './UserListItem';
import styles from './style.module.scss';
import { normalizeUser } from 'sdk/Schemas';

class SelectUserModal extends Component {
  getInititalState = () => ({
    userIds: [],
    isFetching: true,
    selectedItem: 'all',
    searchValue: '',
    totalPages: 1,
    page: 1,
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInititalState() }, () => {
        this.fetchUsers();
      });
    }
  }

  fetchUsers = params => {
    let attrs = { ...params, archived: false, page: this.state.page };
    if (this.state.selectedItem === 'archived') {
      attrs = {
        ...attrs,
        archived: true,
      };
    }
    if (this.state.searchValue.length > 0) {
      attrs = {
        ...attrs,
        search: this.state.searchValue,
      };
    }
    if (this.props.members) {
      attrs = {
        ...attrs,
        member: true,
      };
    }
    API.listUsers(this.props.system.id, attrs).then(({ data: users, headers }) => {
      const { entities, result: userIds } = normalizeUser(users);
      this.props.updateEntities(entities);
      this.setState({
        userIds,
        isFetching: false,
        totalPages: HelperFunctions.getPaginationFromHeader(headers).totalPages,
      });
    });
  };

  changePage = page => {
    this.setState({ isFetching: true, page }, () => {
      this.fetchUsers({ page });
    });
  };

  selectUser = userId => {
    if (this.props.multiple) {
      if (this.props.selectedUserIds.includes(userId)) {
        this.props.onRemoveUser(userId);
      } else {
        this.props.onAddUser(userId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectUser(userId);
        }
      );
    }
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.user.name" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
    </div>
  );

  renderArchivedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="components.select-user-modal.archived-empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="components.select-user-modal.archived-empty-data-set.subtitle" />
      </div>
    </div>
  );

  renderMenuContent = () => {
    return (
      <>
        <div className={styles['left-panel']}>
          <Menu.Item
            selected={this.state.selectedItem === 'all'}
            title={<FormattedMessage id="components.select-user-modal.all-users" />}
            onClick={() => {
              this.setState({ isFetching: true, selectedItem: 'all' }, () => {
                this.fetchUsers();
              });
            }}
          />
          {this.props.hideArchived ? null : (
            <Menu.Item
              red
              selected={this.state.selectedItem === 'archived'}
              title={<FormattedMessage id="components.select-user-modal.archived-users" />}
              onClick={() => {
                this.setState({ isFetching: true, selectedItem: 'archived' }, () => {
                  this.fetchUsers();
                });
              }}
            />
          )}
        </div>
      </>
    );
  };

  renderUsers = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderListHeader()}
          <List>
            <UserListItem loading />
            <UserListItem loading />
          </List>
        </>
      );
    }

    if (this.state.userIds.length === 0) {
      if (this.state.searchValue.length > 0) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderArchivedEmptyDataset();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.state.userIds.map(id => {
            return (
              <UserListItem
                selected={this.props.selectedUserIds.includes(id)}
                multiple={this.props.multiple}
                id={id}
                onSelectUser={user => this.selectUser(user)}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderPagination = () => {
    if (this.state.userIds.length === 0) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.select-user-modal.title" />}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
          debounce
          searchable
          value={this.state.searchValue}
          onSearch={value => {
            this.setState({ searchValue: value, isFetching: true, page: 1 });
          }}
          onDebouncedSearch={() => {
            this.fetchUsers({ search: this.state.searchValue });
          }}
          onClear={() => {
            this.setState({ isFetching: true, searchValue: '', page: 1 }, () => {
              this.fetchUsers({ search: '' });
            });
          }}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>
            <Modal.ColumnLayout.Container.Content>{this.renderUsers()}</Modal.ColumnLayout.Container.Content>
            {this.renderPagination()}
          </Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectUserModal)));

SelectUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hideArchived: PropTypes.bool,
  multiple: PropTypes.bool,
  onSelectUser: PropTypes.func,
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onClear: PropTypes.func,
  members: PropTypes.bool,
};

SelectUserModal.defaultProps = {
  open: false,
  hideArchived: false,
  onClose: () => {},
  selectedUserId: null,
  selectedUserIds: [],
  onSelectUser: () => {},
  onAddUser: () => {},
  onRemoveUser: () => {},
  onClear: () => {},
  multiple: false,
  members: false,
};
