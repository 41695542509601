import types from './types';

const resetState = payload => ({
  type: types.RESET_STATE,
  payload: payload,
});

const setIsRefreshing = payload => ({
  type: types.SET_IS_REFRESHING,
  payload: payload,
});

const setProductionBoardActivityDate = payload => ({
  type: types.SET_PRODUCTION_BOARD_ACTIVITY_DATE,
  payload: payload,
});

const setOperationalMaintenancesDate = payload => ({
  type: types.SET_OPERATIONAL_MAINTENANCE_DATE,
  payload: payload,
});

const fetchRequestsForDashboardSuccess = payload => ({
  type: types.FETCH_REQUESTS_FOR_DASHBOARD_SUCCESS,
  payload: payload,
});

const fetchOperationalMaintenancesForDashboardSuccess = payload => ({
  type: types.FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD_SUCCESS,
  payload: payload,
});

const fetchProductionBoardActivitiesForDashboardSuccess = payload => ({
  type: types.FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD_SUCCESS,
  payload,
});

export default {
  fetchOperationalMaintenancesForDashboardSuccess,
  fetchProductionBoardActivitiesForDashboardSuccess,
  fetchRequestsForDashboardSuccess,
  setOperationalMaintenancesDate,
  setProductionBoardActivityDate,
  setIsRefreshing,
  resetState,
};
