import React, { Component } from 'react';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, NewTab, NewInlineModal, Icon, Tooltip, Banner } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { API, HelperFunctions } from 'sdk';
import { REPORT_TYPES, REPORT_PER_TYPES, REPORT_SPLIT_PER_TYPES } from 'sdk/Cost';
import { normalizeWorkOrderType, normalizeAsset } from 'sdk/Schemas';
import { WorkOrderCategoryType } from 'sdk/System';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import FilterTopBar from './FilterTopBar';
import AdvancedFilter from './AdvancedFilter';
import ListView from './ListView';
import Chart from './Chart';
import SortInlineModal from './SortInlineModal';
import CostSummary from './CostSummary';
import ExportModal from './ExportModal';
import styles from './style.module.scss';

const getReportRequest = HelperFunctions.getCancelTokenForRequest();

class Statistics extends Component {
  constructor(props) {
    super(props);
    const yearSystemWasCreated = moment(this.props.currentSystem.created_at).year();
    const yearSinceSystemWasCreated = moment().year() - moment(this.props.currentSystem.created_at).year();
    const systemWasCreatedThisYear = yearSystemWasCreated === moment().year();
    this.state = {
      isFetching: true,
      showNewUpdateBanner: true,
      hideEditStatistics: true,
      showAdvancedFilter: false,
      showPerIsOpen: false,
      showDropDown: false,
      showPerTypeIsOpen: false,
      showPerSplitTypeIsOpen: false,
      type: REPORT_TYPES.Total,
      perType: systemWasCreatedThisYear ? REPORT_PER_TYPES.Month : REPORT_PER_TYPES.Year,
      splitPerType: null,
      sortingOnType: null,
      sortingOnPerType: systemWasCreatedThisYear ? REPORT_PER_TYPES.Month : REPORT_PER_TYPES.Year,
      sortingOnPerSplitType: null,
      yearSystemWasCreated,
      yearSinceSystemWasCreated,
      systemWasCreatedThisYear,
      filters: {
        year: systemWasCreatedThisYear ? yearSystemWasCreated : null,
        from_date: null,
        to_date: null,
      },
      advancedFilters: {
        category: null,
        work_order_type_ids: null,
        work_order_priorities: null,
        asset_type_ids: null,
        work_order_is_recurring: null,
        work_order_completed_before_registration: null,
      },
      data: {},
      dataSummary: {},
      workOrderTypes: [],
      assets: [],
      showingAssetId: this.props.shownFromAssetId,
      isShowingChart: true,
      assetTypeIds: [],
      showExportModal: false,
    };
  }

  componentDidMount() {
    const { type, perType } = this.state;
    Promise.all([
      this.fetchReport({
        show: type,
        per: perType,
        asset_with_tree_children_id: this.props.shownFromAssetId,
      }),
      this.fetchReportSummary({
        asset_with_tree_children_id: this.props.shownFromAssetId,
      }),
    ]).then(([report, reportSummary]) => {
      this.setState({
        data: report,
        dataSummary: reportSummary,
        isFetching: false,
        isShowingChart: true,
      });
    });
  }

  fetchReport = params => {
    return API.getReport(this.props.currentSystem.id, params, getReportRequest.getCancelTokenConfig()).then(
      ({ data }) => {
        return data;
      }
    );
  };

  fetchReportSummary = params => {
    return API.getReportSummary(this.props.currentSystem.id, params).then(({ data }) => {
      return data;
    });
  };

  transformAdvancedFiltersToQueryParams = () => {
    const {
      category,
      work_order_type_ids,
      work_order_priorities,
      asset_type_ids,
      work_order_is_recurring,
      work_order_completed_before_registration,
    } = this.state.advancedFilters;
    let queryParams = {};
    if (category) {
      queryParams = {
        ...queryParams,
        work_order: {
          category,
        },
      };
    }
    if (work_order_type_ids) {
      queryParams = {
        ...queryParams,
        work_order: {
          ...queryParams.work_order,
          work_order_type_id: work_order_type_ids,
        },
      };
    }
    if (work_order_priorities) {
      queryParams = {
        ...queryParams,
        work_order: {
          ...queryParams.work_order,
          priority: work_order_priorities,
        },
      };
    }
    if (asset_type_ids) {
      queryParams = {
        ...queryParams,
        asset: {
          asset_type_id: asset_type_ids,
        },
      };
    }
    if (work_order_is_recurring) {
      queryParams = {
        ...queryParams,
        work_order: {
          ...queryParams.work_order,
          recurring_maintenance_id: {
            [HelperFunctions.FILTER_COMPARABLES.Exists]: true,
          },
        },
      };
    }
    if (work_order_completed_before_registration) {
      queryParams = {
        ...queryParams,
        work_order: {
          ...queryParams.work_order,
          completed_before_registration: true,
        },
      };
    }

    return queryParams;
  };

  transformFiltersToQueryParams = () => {
    const { year, from_date, to_date } = this.state.filters;
    let queryParams = {
      year,
    };
    if (from_date || to_date) {
      queryParams = {
        ...queryParams,
        date: {
          [HelperFunctions.FILTER_COMPARABLES.Between]: `${from_date}...${to_date}`,
        },
      };
    }
    return queryParams;
  };

  selectType = type => {
    if (this.state.type === type) return;
    let splitPerType = this.state.splitPerType;
    if (splitPerType === REPORT_SPLIT_PER_TYPES.Category && type !== REPORT_TYPES.Total) {
      splitPerType = null;
    }
    this.setState({
      type,
      splitPerType,
      sortingOnType: null,
      sortingOnPerSplitType: null,
      sortingOnPerType: this.state.perType,
      isFetching: true,
    });
    const params = {
      show: type,
      per: this.state.perType,
      split: splitPerType,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    };
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      params.asset_tree_parent_id = this.state.showingAssetId;
    } else {
      params.asset_with_tree_children_id = this.state.showingAssetId;
    }
    this.fetchReport(params)
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectPerType = perType => {
    if (this.state.perType === perType) return;
    let showingAssetId = this.state.showingAssetId;
    if (perType === REPORT_PER_TYPES.Asset) {
      showingAssetId = this.props.shownFromAssetId;
    }
    let splitPerType = this.state.splitPerType;
    if (
      this.state.splitPerType === REPORT_SPLIT_PER_TYPES.External &&
      perType === REPORT_PER_TYPES.External
    ) {
      splitPerType = null;
    }
    if (
      (this.state.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ||
        this.state.splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) &&
      (perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
        perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed)
    ) {
      splitPerType = null;
    }
    getReportRequest.cancel();
    this.setState({
      perType,
      sortingOnType: null,
      splitPerType,
      sortingOnPerSplitType: null,
      sortingOnPerType: perType,
      isFetching: true,
      showingAssetId,
    });
    if (perType === REPORT_PER_TYPES.WorkOrderType) {
      API.listWorkOrderTypes(this.props.currentSystem.id)
        .then(({ data }) => {
          const { entities, result } = normalizeWorkOrderType(data);
          this.props.updateEntities(entities);
          this.fetchReport({
            show: this.state.type,
            per: perType,
            split: splitPerType,
            asset_with_tree_children_id: showingAssetId,
            ...this.transformAdvancedFiltersToQueryParams(),
            ...this.transformFiltersToQueryParams(),
          })
            .then(data => {
              this.setState({
                workOrderTypes: result.map(id => entities.workOrderTypeById[id]),
                data,
                isFetching: false,
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }
    if (perType === REPORT_PER_TYPES.Asset) {
      let attrs = {
        no_pagination: true,
      };
      if (showingAssetId == null) {
        attrs = {
          ...attrs,
          tree_parent_id: {
            [HelperFunctions.FILTER_COMPARABLES.Exists]: false,
          },
        };
      } else {
        attrs = {
          ...attrs,
          tree_parent_id: showingAssetId,
        };
      }
      API.listAssets(this.props.currentSystem.id, attrs)
        .then(({ data }) => {
          const { entities, result } = normalizeAsset(data);
          this.props.updateEntities(entities);
          this.fetchReport({
            show: this.state.type,
            per: perType,
            split: splitPerType,
            asset_tree_parent_id: showingAssetId,
            ...this.transformAdvancedFiltersToQueryParams(),
            ...this.transformFiltersToQueryParams(),
          })
            .then(data => {
              this.setState({
                assets: result.map(id => entities.assetById[id]),
                data,
                isFetching: false,
              });
            })
            .catch(() => {});
        })
        .catch(() => {});
      return;
    }

    this.fetchReport({
      show: this.state.type,
      per: perType,
      split: splitPerType,
      asset_with_tree_children_id: showingAssetId,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    })
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectSplitPerType = splitPerType => {
    if (this.state.splitPerType === splitPerType) return;
    if (splitPerType === REPORT_SPLIT_PER_TYPES.Category && this.state.type !== REPORT_TYPES.Total) return;
    if (splitPerType === REPORT_SPLIT_PER_TYPES.External && this.state.perType === REPORT_PER_TYPES.External)
      return;
    if (
      (splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ||
        splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed) &&
      (this.state.perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
        this.state.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed)
    )
      return;
    if (!this.state.isShowingChart) return;
    this.setState({
      splitPerType,
      sortingOnPerSplitType: null,
      sortingOnPerType: this.state.perType,
      isFetching: true,
    });
    let params = {
      show: this.state.type,
      per: this.state.perType,
      split: splitPerType,
      ...this.transformAdvancedFiltersToQueryParams(),
      ...this.transformFiltersToQueryParams(),
    };
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      params.asset_tree_parent_id = this.state.showingAssetId;
    } else {
      params.asset_with_tree_children_id = this.state.showingAssetId;
    }
    this.fetchReport(params)
      .then(data => {
        this.setState({ data, isFetching: false });
      })
      .catch(() => {});
  };

  selectYear = year => {
    let perType = this.state.perType;
    let sortingOnPerType = this.state.sortingOnPerType;
    if (year && perType === REPORT_PER_TYPES.Year) {
      perType = REPORT_PER_TYPES.Month;
      sortingOnPerType = REPORT_PER_TYPES.Month;
    } else if (year == null && perType === REPORT_PER_TYPES.Month) {
      perType = REPORT_PER_TYPES.Year;
      sortingOnPerType = REPORT_PER_TYPES.Year;
    }
    let filters = { year, from_date: null, to_date: null };
    this.setState(
      {
        perType,
        sortingOnPerType,
        filters,
        isFetching: true,
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: perType,
          split: this.state.splitPerType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        const reportSummaryParams = {
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        Promise.all([this.fetchReport(reportParams), this.fetchReportSummary(reportSummaryParams)]).then(
          ([report, reportSummary]) => {
            this.setState({ data: report, dataSummary: reportSummary, isFetching: false });
          }
        );
      }
    );
  };

  selectCustomDate = (from, to) => {
    const filters = { year: null, from_date: from, to_date: to };
    this.setState(
      {
        filters,
        isFetching: true,
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: this.state.perType,
          split: this.state.splitPerType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        const reportSummaryParams = {
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        };
        Promise.all([this.fetchReport(reportParams), this.fetchReportSummary(reportSummaryParams)]).then(
          ([report, reportSummary]) => {
            this.setState({ data: report, dataSummary: reportSummary, isFetching: false });
          }
        );
      }
    );
  };

  applyAdvancedFilter = filters => {
    this.setState(
      {
        isFetching: true,
        showAdvancedFilter: false,
        advancedFilters: {
          ...this.state.advancedFilters,
          ...filters,
        },
      },
      () => {
        let assetParams = {};
        if (this.state.perType === REPORT_PER_TYPES.Asset) {
          assetParams.asset_tree_parent_id = this.state.showingAssetId;
        } else {
          assetParams.asset_with_tree_children_id = this.state.showingAssetId;
        }
        const reportParams = {
          show: this.state.type,
          per: this.state.perType,
          split: this.state.splitPerType,
          ...this.transformFiltersToQueryParams(),
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
        };
        const reportSummaryParams = {
          ...this.transformFiltersToQueryParams(),
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
        };
        Promise.all([this.fetchReport(reportParams), this.fetchReportSummary(reportSummaryParams)]).then(
          ([report, reportSummary]) => {
            this.setState({ data: report, dataSummary: reportSummary, isFetching: false });
          }
        );
      }
    );
  };

  amountOfAppliedAdvancedFilters = () => {
    const {
      category,
      work_order_type_ids,
      work_order_priorities,
      asset_type_ids,
      work_order_is_recurring,
      work_order_completed_before_registration,
    } = this.state.advancedFilters;
    let count = 0;
    if (category) count++;
    if (work_order_type_ids) count++;
    if (work_order_priorities) count++;
    if (asset_type_ids) count++;
    if (work_order_is_recurring) count++;
    if (work_order_completed_before_registration) count++;
    return count;
  };

  selectAsset = assetId => {
    if (this.state.showingAssetId === assetId) return;
    this.setState({ showingAssetId: assetId, isFetching: true });
    let attrs = {
      no_pagination: true,
    };
    if (assetId == null) {
      attrs = {
        ...attrs,
        tree_parent_id: {
          [HelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
    } else {
      attrs = {
        ...attrs,
        tree_parent_id: assetId,
      };
    }

    API.listAssets(this.props.currentSystem.id, attrs).then(({ data }) => {
      const { entities, result } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.fetchReport({
        show: this.state.type,
        per: this.state.perType,
        split: this.state.splitPerType,
        asset_tree_parent_id: assetId,
        ...this.transformAdvancedFiltersToQueryParams(),
        ...this.transformFiltersToQueryParams(),
      })
        .then(data => {
          this.setState({ assets: result.map(id => entities.assetById[id]), data, isFetching: false });
        })
        .catch(() => {});
    });
  };

  renderCostSummary = () => (
    <div className={styles['cost-summary']}>
      <CostSummary
        downtimeActivated={false}
        currency={this.props.currentSystem.currency}
        totalCost={this.state.dataSummary.total}
        laborCost={this.state.dataSummary.labor}
        downtimeCost={this.state.dataSummary.downtime}
        partCost={this.state.dataSummary.part}
        otherCost={this.state.dataSummary.other}
      />
    </div>
  );

  renderAssetLabel = () => {
    if (this.props.shownFromAssetId) {
      return <FormattedMessage id="components.costs-overview.show-per-types.underlying-asset" />;
    }
    return <FormattedMessage id="components.costs-overview.show-per-types.asset" />;
  };

  renderSelectedAsset = () => {
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      if (this.props.shownFromAssetId) {
        return (
          <div className={styles['selected-asset-container']}>
            <TreePath
              size={12}
              fullPath
              fromAssetId={this.props.shownFromAssetId}
              dark
              renderItem={asset => (
                <span className={styles['text']} onClick={() => this.selectAsset(asset.id)}>
                  {asset.title}
                </span>
              )}
              assetId={this.state.showingAssetId}
            />
            {this.state.assets.length === 0 ? null : (
              <>
                <div
                  className={styles['select-asset-dropdown']}
                  onClick={() => {
                    this.setState(prevState => ({
                      showDropDown: !prevState.showDropDown,
                    }));
                  }}
                  ref={ref => (this.inlineModalPositioningRef = ref)}
                >
                  <Icon type="arrow-alt-right" />
                  <span className={styles['text']}>
                    <FormattedMessage id="components.costs-overview.select-assets" />
                  </span>
                  <Icon type="angle-down" />
                </div>
                <NewInlineModal
                  positionToRef={this.inlineModalPositioningRef}
                  open={this.state.showDropDown}
                  onClose={() => this.setState({ showDropDown: false })}
                  minWidth={200}
                >
                  <NewInlineModal.Dropdown>
                    <NewInlineModal.Dropdown.Items>
                      {this.state.assets
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map(({ title, id }) => (
                          <NewInlineModal.Dropdown.Item
                            key={id}
                            onClick={() => {
                              this.selectAsset(id);
                              this.setState({ showDropDown: false });
                            }}
                          >
                            {title}
                          </NewInlineModal.Dropdown.Item>
                        ))}
                    </NewInlineModal.Dropdown.Items>
                  </NewInlineModal.Dropdown>
                </NewInlineModal>
              </>
            )}
          </div>
        );
      }
      return (
        <div className={styles['selected-asset-container']}>
          <span className={styles['text']} onClick={() => this.selectAsset(null)}>
            <FormattedMessage id="components.costs-overview.all-assets" />
          </span>
          {this.state.showingAssetId ? (
            <>
              <Icon type="arrow-alt-right" size={10} />
              <TreePath
                size={12}
                dark
                fullPath
                renderItem={asset => (
                  <span className={styles['text']} onClick={() => this.selectAsset(asset.id)}>
                    {asset.title}
                  </span>
                )}
                assetId={this.state.showingAssetId}
              />
            </>
          ) : null}
          {this.state.assets.length === 0 ? null : (
            <>
              <div
                className={styles['select-asset-dropdown']}
                onClick={() => {
                  this.setState(prevState => ({
                    showDropDown: !prevState.showDropDown,
                  }));
                }}
                ref={ref => (this.inlineModalPositioningRef = ref)}
              >
                <Icon type="arrow-alt-right" />
                <span className={styles['text']}>
                  <FormattedMessage id="components.costs-overview.select-assets" />
                </span>
                <Icon type="angle-down" />
              </div>
              <NewInlineModal
                positionToRef={this.inlineModalPositioningRef}
                open={this.state.showDropDown}
                onClose={() => this.setState({ showDropDown: false })}
                minWidth={200}
              >
                <NewInlineModal.Dropdown>
                  <NewInlineModal.Dropdown.Items>
                    {this.state.assets
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map(({ title, id }) => (
                        <NewInlineModal.Dropdown.Item
                          key={id}
                          onClick={() => {
                            this.selectAsset(id);
                            this.setState({ showDropDown: false });
                          }}
                        >
                          {title}
                        </NewInlineModal.Dropdown.Item>
                      ))}
                  </NewInlineModal.Dropdown.Items>
                </NewInlineModal.Dropdown>
              </NewInlineModal>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  renderCategorySimple = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          this.state.perType === REPORT_PER_TYPES.WorkOrderCategorySimple ? styles['selected'] : ''
        }`}
        onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderCategorySimple)}
      >
        <FormattedMessage id="components.costs-overview.show-per-types.work_order_category_simple" />
      </div>
    );
  };

  renderCategoryDetailed = () => {
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.Detailed) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          this.state.perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ? styles['selected'] : ''
        }`}
        onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderCategoryDetailed)}
      >
        <FormattedMessage id="components.costs-overview.show-per-types.work_order_category_detailed" />
      </div>
    );
  };

  renderSplitByWorkOrderCategorySimple = () => {
    const { perType, splitPerType } = this.state;
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple ? styles['selected'] : ''
        } ${
          perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
          perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ||
          !this.state.isShowingChart
            ? styles['disabled']
            : ''
        }`}
        onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.WorkOrderCategorySimple)}
      >
        <FormattedMessage id="components.costs-overview.show-per-split-types.work_order_category_simple" />
      </div>
    );
  };

  renderSplitByWorkOrderCategoryDetailed = () => {
    const { perType, splitPerType } = this.state;
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.Detailed) {
      return null;
    }
    return (
      <div
        className={`${styles['type']} ${
          splitPerType === REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed ? styles['selected'] : ''
        } ${
          perType === REPORT_PER_TYPES.WorkOrderCategorySimple ||
          perType === REPORT_PER_TYPES.WorkOrderCategoryDetailed ||
          !this.state.isShowingChart
            ? styles['disabled']
            : ''
        } `}
        onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.WorkOrderCategoryDetailed)}
      >
        <FormattedMessage id="components.costs-overview.show-per-split-types.work_order_category_detailed" />
      </div>
    );
  };

  renderStatistics = () => {
    const {
      showPerIsOpen,
      showPerTypeIsOpen,
      showPerSplitTypeIsOpen,
      systemWasCreatedThisYear,
      type,
      filters,
      perType,
      splitPerType,
      isShowingChart,
    } = this.state;
    const isFilteringOnDate = filters.year || filters.from_date || filters.to_date;
    return (
      <div className={styles['statistics-container']}>
        <div className={styles['edit']}>
          <div className={styles['content']}>
            <div className={`${styles['type-container']} ${showPerIsOpen ? styles['selected'] : ''}`}>
              <div
                className={styles['type']}
                onClick={() =>
                  this.setState(prevState => ({
                    showPerIsOpen: !prevState.showPerIsOpen,
                    showPerTypeIsOpen: false,
                    showPerSplitTypeIsOpen: false,
                  }))
                }
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.costs-overview.show" />
                  </span>
                  <span className={styles['subtitle']}>
                    <FormattedMessage id={`components.costs-overview.show-types.${type}`} />
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${type === REPORT_TYPES.Total ? styles['selected'] : ''}`}
                    onClick={() => this.selectType(REPORT_TYPES.Total)}
                  >
                    <FormattedMessage id="components.costs-overview.show-types.total" />
                  </div>
                  <div
                    className={`${styles['type']} ${type === REPORT_TYPES.Labor ? styles['selected'] : ''}`}
                    onClick={() => this.selectType(REPORT_TYPES.Labor)}
                  >
                    <FormattedMessage id="components.costs-overview.show-types.labor" />
                  </div>
                  <div
                    className={`${styles['type']} ${type === REPORT_TYPES.Part ? styles['selected'] : ''}`}
                    onClick={() => this.selectType(REPORT_TYPES.Part)}
                  >
                    <FormattedMessage id="components.costs-overview.show-types.part" />
                  </div>
                  {false ? (
                    <div
                      className={`${styles['type']} ${
                        type === REPORT_TYPES.Downtime ? styles['selected'] : ''
                      }`}
                      onClick={() => this.selectType(REPORT_TYPES.Downtime)}
                    >
                      <FormattedMessage id="components.costs-overview.show-types.downtime" />
                    </div>
                  ) : null}
                  <div
                    className={`${styles['type']} ${type === REPORT_TYPES.Other ? styles['selected'] : ''}`}
                    onClick={() => this.selectType(REPORT_TYPES.Other)}
                  >
                    <FormattedMessage id="components.costs-overview.show-types.other" />
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <div className={`${styles['type-container']} ${showPerTypeIsOpen ? styles['selected'] : ''}`}>
              <div
                className={styles['type']}
                onClick={() =>
                  this.setState(prevState => ({
                    showPerTypeIsOpen: !prevState.showPerTypeIsOpen,
                    showPerIsOpen: false,
                    showPerSplitTypeIsOpen: false,
                  }))
                }
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.costs-overview.per" />
                  </span>
                  <span className={styles['subtitle']}>
                    {perType === REPORT_PER_TYPES.Asset ? (
                      this.renderAssetLabel()
                    ) : (
                      <FormattedMessage id={`components.costs-overview.show-per-types.${perType}`} />
                    )}
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerTypeIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Year || perType === REPORT_PER_TYPES.Month
                        ? styles['selected']
                        : ''
                    }`}
                    onClick={() =>
                      this.selectPerType(
                        systemWasCreatedThisYear || perType === REPORT_PER_TYPES.Month || isFilteringOnDate
                          ? REPORT_PER_TYPES.Month
                          : REPORT_PER_TYPES.Year
                      )
                    }
                  >
                    {filters.year || filters.from_date || filters.to_date ? (
                      <FormattedMessage id="components.costs-overview.show-per-types.month" />
                    ) : (
                      <FormattedMessage id="components.costs-overview.show-per-types.year" />
                    )}
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Asset ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.Asset)}
                  >
                    {this.renderAssetLabel()}
                  </div>
                  {this.renderCategorySimple()}
                  {this.renderCategoryDetailed()}

                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.Priority ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.Priority)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-types.work_order_priority" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.WorkOrderType ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.WorkOrderType)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-types.work_order_type" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      perType === REPORT_PER_TYPES.External ? styles['selected'] : ''
                    }`}
                    onClick={() => this.selectPerType(REPORT_PER_TYPES.External)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-types.external" />
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <div
              className={`${styles['type-container']} ${showPerSplitTypeIsOpen ? styles['selected'] : ''}`}
            >
              <div
                className={`${styles['type']} ${!this.state.isShowingChart ? styles['disabled'] : ''}`}
                onClick={() => {
                  if (this.state.isShowingChart) {
                    this.setState(prevState => ({
                      showPerSplitTypeIsOpen: !prevState.showPerSplitTypeIsOpen,
                      showPerIsOpen: false,
                      showPerTypeIsOpen: false,
                    }));
                  }
                }}
              >
                <div className={styles['text-container']}>
                  <span className={styles['title']}>
                    <FormattedMessage id="components.costs-overview.split-per" />
                  </span>
                  <span className={styles['subtitle']}>
                    {splitPerType ? (
                      <FormattedMessage
                        id={`components.costs-overview.show-per-split-types.${splitPerType}`}
                      />
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <Icon regular type="angle-down" />
              </div>
              <AnimateHeight duration={250} height={showPerSplitTypeIsOpen ? 'auto' : 0}>
                <div className={styles['types']}>
                  <div
                    className={`${styles['type']} ${splitPerType == null ? styles['selected'] : ''}`}
                    onClick={() => this.selectSplitPerType(null)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-split-types.none" />
                  </div>
                  {this.renderSplitByWorkOrderCategorySimple()}
                  {this.renderSplitByWorkOrderCategoryDetailed()}
                  <div
                    className={`${styles['type']} ${
                      splitPerType === REPORT_SPLIT_PER_TYPES.Category ? styles['selected'] : ''
                    } ${
                      type !== REPORT_TYPES.Total || !this.state.isShowingChart ? styles['disabled'] : ''
                    } `}
                    onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.Category)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-split-types.cost_category" />
                  </div>
                  <div
                    className={`${styles['type']} ${
                      splitPerType === REPORT_SPLIT_PER_TYPES.External ? styles['selected'] : ''
                    } ${
                      perType === REPORT_PER_TYPES.External || !this.state.isShowingChart
                        ? styles['disabled']
                        : ''
                    }`}
                    onClick={() => this.selectSplitPerType(REPORT_SPLIT_PER_TYPES.External)}
                  >
                    <FormattedMessage id="components.costs-overview.show-per-split-types.external" />
                  </div>
                </div>
              </AnimateHeight>
            </div>
          </div>
        </div>
        <div className={styles['show']}>
          <div className={styles['container']}>
            <div className={styles['header']}>
              <div className={styles['title-container']}>
                <div className={styles['title']}>
                  <FormattedMessage id={`components.costs-overview.show-types.${type}`} />
                  <span> / </span>
                  {perType === REPORT_PER_TYPES.Asset ? (
                    this.renderAssetLabel()
                  ) : (
                    <FormattedMessage id={`components.costs-overview.show-per-types.${perType}`} />
                  )}
                </div>
                <Button
                  type="text"
                  primary
                  noUnderline
                  fontSize={12}
                  label="general.edit"
                  onClick={() =>
                    this.setState(prevState => ({ hideEditStatistics: !prevState.hideEditStatistics }))
                  }
                />
              </div>
              <div className={styles['change-view']}>
                <NewTab.Container>
                  <NewTab
                    selected={isShowingChart}
                    onClick={() => this.setState({ isShowingChart: true })}
                    small
                  >
                    <Icon type="chart-bar" size={16} />
                  </NewTab>
                  <NewTab
                    selected={!isShowingChart}
                    onClick={() => {
                      if (this.state.splitPerType) {
                        this.selectSplitPerType(null);
                      }
                      this.setState({ isShowingChart: false });
                    }}
                    small
                  >
                    <Icon type="list" size={16} />
                  </NewTab>
                </NewTab.Container>
              </div>
              <div className={styles['sort-container']}>
                <SortInlineModal
                  type={this.state.type}
                  perType={this.state.perType}
                  splitPerType={this.state.splitPerType}
                  sortingOnType={this.state.sortingOnType}
                  sortingOnPerType={this.state.sortingOnPerType}
                  sortingOnPerSplitType={this.state.sortingOnPerSplitType}
                  downtimeActivated={false}
                  onSortOnType={() =>
                    this.setState({
                      sortingOnType: this.state.type,
                      sortingOnPerType: null,
                      sortingOnPerSplitType: null,
                    })
                  }
                  onSortOnPerType={() =>
                    this.setState({
                      sortingOnType: null,
                      sortingOnPerSplitType: null,
                      sortingOnPerType: this.state.perType,
                    })
                  }
                  onSortOnSplitPerType={splitPerType => {
                    this.setState({
                      sortingOnType: null,
                      sortingOnPerType: null,
                      sortingOnPerSplitType: splitPerType,
                    });
                  }}
                />
              </div>
              <div>
                <Tooltip
                  label={<FormattedMessage id="general.export" />}
                  disabled={this.state.splitPerType != null}
                  trigger={
                    <div>
                      <Button
                        type="icon"
                        disabled={this.state.splitPerType != null}
                        icon={<Icon regular size={14} type="file-export" />}
                        onClick={() => this.setState({ showExportModal: true })}
                      />
                    </div>
                  }
                />
              </div>
            </div>
            {this.renderSelectedAsset()}
            <div className={styles['content']}>
              {this.state.isShowingChart ? (
                <Chart
                  type={this.state.type}
                  filters={this.state.filters}
                  perType={this.state.perType}
                  splitPerType={this.state.splitPerType}
                  isFetching={this.state.isFetching}
                  workOrderTypes={this.state.workOrderTypes}
                  assets={this.state.assets}
                  showingRootAssets={this.state.showingAssetId == null}
                  assetTypeIds={this.state.assetTypeIds}
                  currency={this.props.currentSystem.currency}
                  yearSystemWasCreated={this.state.yearSystemWasCreated}
                  downtimeActivated={false}
                  sortingOnType={this.state.sortingOnType}
                  data={this.state.data}
                  sortingOnPerType={this.state.sortingOnPerType}
                  sortingOnPerSplitType={this.state.sortingOnPerSplitType}
                />
              ) : (
                <ListView
                  type={this.state.type}
                  perType={this.state.perType}
                  sortingOnType={this.state.sortingOnType}
                  sortingOnPerType={this.state.sortingOnPerType}
                  data={this.state.data}
                  workOrderTypes={this.state.workOrderTypes}
                  assets={this.state.assets}
                  showingRootAssets={this.state.showingAssetId == null}
                  isFetching={this.state.isFetching}
                  yearSystemWasCreated={this.state.yearSystemWasCreated}
                  currency={this.props.currentSystem.currency}
                />
              )}
            </div>
          </div>
          <div
            className={styles['toggle']}
            onClick={() =>
              this.setState(prevState => ({ hideEditStatistics: !prevState.hideEditStatistics }))
            }
          >
            <Icon regular type="angle-left" />
          </div>
        </div>
      </div>
    );
  };

  renderCostsIncludesUnderlyingAssetsText = () => {
    if (this.props.shownFromAssetId == null) return null;
    return (
      <p className={styles['underlying-assets-text']}>
        <FormattedMessage id="components.costs-overview.showing-underlying-assets" />
      </p>
    );
  };

  showAdvancedFilter = () => {
    this.setState({ showAdvancedFilter: true });
  };

  renderExportModal = () => {
    let assetParams = {};
    if (this.state.perType === REPORT_PER_TYPES.Asset) {
      assetParams.asset_tree_parent_id = this.state.showingAssetId;
    } else {
      assetParams.asset_with_tree_children_id = this.state.showingAssetId;
    }
    return (
      <ExportModal
        open={this.state.showExportModal}
        params={{
          show: this.state.type,
          per: this.state.perType,
          ...assetParams,
          ...this.transformAdvancedFiltersToQueryParams(),
          ...this.transformFiltersToQueryParams(),
        }}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderNewUpdateBannerText = () => {
    if (this.props.currentUser.language === 'sv') {
      return (
        <div>
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            <span>Vi har förbättrat det datum olika kostnader bokförs i systemet.</span>
          </div>
          <ul>
            <li>Arbetskostnad: Det datum arbetet utfördes</li>
            <li>Materialkostnad: Det datum uttaget av reservdel registreras</li>
            <li>Övriga kostnader: Det datum kostnaden registreras (kan ändras)</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            <span>We have improved the date when different costs are recorded in the system.</span>
          </div>
          <ul>
            <li>Labor cost: Same date as the work was carried out</li>
            <li>Material cost: Same date as the spare part withdrawal was registred</li>
            <li>Other cost: Same date as the cost was registred (can be changed)</li>
          </ul>
        </div>
      );
    }
  };

  renderNewUpdateBanner = () => (
    <AnimateHeight duration={250} height={this.state.showNewUpdateBanner ? 'auto' : 0}>
      <div style={{ marginBottom: 20 }}>
        <Banner orange closeable onClose={() => this.setState({ showNewUpdateBanner: false })}>
          {this.renderNewUpdateBannerText()}
        </Banner>
      </div>
    </AnimateHeight>
  );

  render() {
    return (
      <React.Fragment>
        {this.renderNewUpdateBanner()}
        <FilterTopBar
          year={this.state.filters.year}
          fromDate={this.state.filters.from_date}
          toDate={this.state.filters.to_date}
          yearSinceSystemWasCreated={this.state.yearSinceSystemWasCreated}
          yearSystemWasCreated={this.state.yearSystemWasCreated}
          onShowMoreFilters={this.showAdvancedFilter}
          amountOfAppliedAdvancedFilters={this.amountOfAppliedAdvancedFilters()}
          onSelectYear={this.selectYear}
          onSelectDate={this.selectCustomDate}
          onClear={this.selectYear}
        />
        <div
          className={`${styles['wrapper']} ${
            this.state.hideEditStatistics ? styles['hide-edit-statistics'] : ''
          }`}
        >
          {this.renderStatistics()}
          {this.renderCostSummary()}
        </div>
        {this.renderCostsIncludesUnderlyingAssetsText()}
        <AdvancedFilter
          open={this.state.showAdvancedFilter}
          shownFromAssetId={this.props.shownFromAssetId}
          params={this.state.advancedFilters}
          amountOfAppliedAdvancedFilters={this.amountOfAppliedAdvancedFilters()}
          onClose={params => {
            if (isEqual(this.state.advancedFilters, params)) {
              this.setState({
                showAdvancedFilter: false,
              });
            } else {
              this.applyAdvancedFilter(params);
            }
          }}
          onClearFilters={() => {
            this.applyAdvancedFilter({
              category: null,
              work_order_type_ids: null,
              work_order_priorities: null,
              asset_type_ids: null,
              work_order_is_recurring: null,
              work_order_completed_before_registration: null,
            });
          }}
        />
        {this.renderExportModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

Statistics.propTypes = {
  shownFromAssetId: PropTypes.string,
};
Statistics.defaultProps = {
  shownFromAssetId: null,
};
