import types from './types';
import update from 'immutability-helper';
import { uniq } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  userIsAuthenticated: false,
  groupIsAuthenticated: false,
  isPreparingApp: true,
  organisationId: null,
  systemId: null,
  systemIds: [],
  userId: null,
  purchaseOrderDeliveryMethodIds: [],
  purchaseOrderPaymentTermIds: [],
  sparePartUnitIds: [],
  templateFieldIds: [],
  costOtherCategoryIds: [],
  workOrderSpentTimeCategoryIds: [],
  laborTariffIds: [],
  meterUnitIds: [],
  settings: {},
  organisationSettings: {},
  userSettings: {},
  hasDeprecatedVersion: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_DEPRECATED_VERSION: {
      return {
        ...state,
        hasDeprecatedVersion: true,
      };
    }
    case types.PREPARE_AUTHENTICATED_USER:
      return { ...state, isPreparingApp: true, userIsAuthenticated: true };
    case types.PREPARE_AUTHENTICATED_USER_SUCCESS: {
      const { organisationId, systemId, systemIds, userId } = action.payload;
      return {
        ...state,
        isPreparingApp: false,
        userIsAuthenticated: true,
        organisationId,
        systemId,
        userId,
        systemIds,
      };
    }
    case types.PREPARE_AUTHENTICATED_USER_ERROR:
      return {
        ...state,
        userIsAuthenticated: false,
        isPreparingApp: false,
      };
    case types.AUTHENTICATE_GROUP_SUCCESS: {
      return {
        ...state,
        groupIsAuthenticated: true,
        isPreparingApp: false,
      };
    }
    case types.UNAUTHENTICATE_GROUP: {
      return {
        ...state,
        groupIsAuthenticated: false,
      };
    }
    case types.UNAUTHENTICATE_USER:
      return { ...state, userIsAuthenticated: false, isPreparingApp: false };
    case SDKReduxTypes.WS_SETTINGS_JOINED:
    case SDKReduxTypes.WS_SETTINGS_UPDATED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    }
    case SDKReduxTypes.WS_USER_SETTINGS_JOINED:
    case SDKReduxTypes.WS_USER_SETTINGS_UPDATED: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          ...action.payload,
        },
      };
    }
    case SDKReduxTypes.WS_ORGANISATION_SETTINGS_JOINED:
    case SDKReduxTypes.WS_ORGANISATION_SETTINGS_UPDATED: {
      return {
        ...state,
        organisationSettings: {
          ...state.organisationSettings,
          ...action.payload,
        },
      };
    }

    case SDKReduxTypes.WS_SYSTEM_JOINED: {
      const {
        purchaseOrderDeliveryMethodIds,
        meterUnitIds,
        purchaseOrderPaymentTermIds,
        sparePartUnitIds,
        templateFieldIds,
        costOtherCategoryIds,
        workOrderSpentTimeCategoryIds,
        laborTariffIds,
      } = action.payload;

      return {
        ...state,
        purchaseOrderDeliveryMethodIds,
        purchaseOrderPaymentTermIds,
        sparePartUnitIds,
        templateFieldIds,
        meterUnitIds,
        costOtherCategoryIds,
        workOrderSpentTimeCategoryIds,
        laborTariffIds,
      };
    }
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_CREATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        purchaseOrderDeliveryMethodIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_DELETED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_DELIVERY_METHOD_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_DELIVERY_METHOD_DELETED) {
        id = action.payload.purchaseOrderDeliveryMethodId;
      } else {
        id = action.payload;
      }
      return update(state, {
        purchaseOrderDeliveryMethodIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_CREATED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        purchaseOrderPaymentTermIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_DELETED:
    case SDKReduxTypes.WS_SYSTEM_PURCHASE_ORDER_PAYMENT_TERM_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.PURCHASE_ORDER_PAYMENT_TERM_DELETED) {
        id = action.payload.purchaseOrderPaymentTermId;
      } else {
        id = action.payload;
      }
      return update(state, {
        purchaseOrderPaymentTermIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.SPARE_PART_UNIT_CREATED:
    case SDKReduxTypes.WS_SYSTEM_SPARE_PART_UNIT_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.SPARE_PART_UNIT_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        sparePartUnitIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.SPARE_PART_UNIT_DELETED:
    case SDKReduxTypes.WS_SYSTEM_SPARE_PART_UNIT_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.SPARE_PART_UNIT_DELETED) {
        id = action.payload.sparePartUnitId;
      } else {
        id = action.payload;
      }
      return update(state, {
        sparePartUnitIds: arr => arr.filter(item => item !== id),
      });
    }

    case SDKReduxTypes.METER_UNIT_DELETED:
    case SDKReduxTypes.WS_SYSTEM_METER_UNIT_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.METER_UNIT_DELETED) {
        id = action.payload.meterUnitId;
      } else {
        id = action.payload;
      }
      return update(state, {
        meterUnitIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.METER_UNIT_CREATED:
    case SDKReduxTypes.WS_SYSTEM_METER_UNIT_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.METER_UNIT_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        meterUnitIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.TEMPLATE_FIELD_CREATED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_CREATED) {
        id = action.payload.data.id;
      } else if (action.type === SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_CREATED) {
        id = action.payload.id;
      }
      return {
        ...state,
        templateFieldIds: uniq([...state.templateFieldIds, id]),
      };
    }
    case SDKReduxTypes.TEMPLATE_FIELD_DELETED:
    case SDKReduxTypes.WS_SYSTEM_TEMPLATE_FIELD_DELETED: {
      let id = null;
      if (action.type === SDKReduxTypes.TEMPLATE_FIELD_DELETED) {
        id = action.payload.templateFieldId;
      } else {
        id = action.payload;
      }
      return update(state, {
        templateFieldIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.COST_OTHER_CATEGORY_CREATED:
    case SDKReduxTypes.WS_COST_OTHER_CATEGORY_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.COST_OTHER_CATEGORY_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        costOtherCategoryIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.COST_OTHER_CATEGORY_DELETED:
    case SDKReduxTypes.WS_COST_OTHER_CATEGORY_DELETED: {
      let id;
      if (action.type === SDKReduxTypes.COST_OTHER_CATEGORY_DELETED) {
        id = action.payload.costOtherCategoryId;
      } else {
        id = action.payload;
      }

      return update(state, {
        costOtherCategoryIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_CREATED:
    case SDKReduxTypes.WS_WORK_ORDER_SPENT_TIME_CATEGORY_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        workOrderSpentTimeCategoryIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_DELETED:
    case SDKReduxTypes.WS_WORK_ORDER_SPENT_TIME_CATEGORY_DELETED: {
      let id;
      if (action.type === SDKReduxTypes.WORK_ORDER_SPENT_TIME_CATEGORY_DELETED) {
        id = action.payload.workOrderSpentTimeCategoryId;
      } else {
        id = action.payload;
      }

      return update(state, {
        workOrderSpentTimeCategoryIds: arr => arr.filter(item => item !== id),
      });
    }
    case SDKReduxTypes.LABOR_TARIFF_CREATED:
    case SDKReduxTypes.WS_LABOR_TARIFF_CREATED: {
      let id;
      if (action.type === SDKReduxTypes.LABOR_TARIFF_CREATED) {
        id = action.payload.data.id;
      } else {
        id = action.payload.id;
      }

      return update(state, {
        laborTariffIds: arr => uniq([...arr, id]),
      });
    }
    case SDKReduxTypes.LABOR_TARIFF_DELETED:
    case SDKReduxTypes.WS_LABOR_TARIFF_DELETED: {
      let id;
      if (action.type === SDKReduxTypes.LABOR_TARIFF_DELETED) {
        id = action.payload.laborTariffId;
      } else {
        id = action.payload;
      }

      return update(state, {
        laborTariffIds: arr => arr.filter(item => item !== id),
      });
    }
    default:
      return state;
  }
};
