import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mutateTree } from '@atlaskit/tree/';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeAsset } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { buildAtlaskitTree, updateTreeAssetIds } from 'sdk/Asset';
import { EmptyDataSet, Icon, Loader } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { Tree } from 'views/components/Asset';
import { API } from 'sdk';
import TreeLarge from 'assets/images/EmptyDataSet/TreeLarge.png';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class TreeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEmpty: false,
      tree: [],
      isFetchingAssetId: null,
    };
  }

  componentDidMount() {
    API.fetchTree(this.props.currentSystem.id, {
      ...this.props.queryParams,
      archived: false,
    }).then(res => {
      const { data } = res;
      const { entities } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.setState({
        loading: false,
        isEmpty: data.length === 0,
        tree: buildAtlaskitTree(data),
      });
    });
  }

  isSelected = assetId => {
    if (this.props.selectedAssetId === assetId) {
      return true;
    } else if (this.props.selectedAssetIds != null) {
      return this.props.selectedAssetIds.some(selectedAssetId => selectedAssetId === assetId);
    }
  };

  expandTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: true }),
    });
  };

  collapseTree = node => {
    this.setState({
      tree: mutateTree(this.state.tree, node, { isExpanded: false }),
    });
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="components.tree-modal.empty-data-set.title" />}
      image={TreeLarge}
      small
      modalContainer
    />
  );

  selectAsset = asset => {
    if (this.state.isFetchingAssetId != null) return;
    this.setState({
      isFetchingAssetId: asset.id,
      tree: updateTreeAssetIds(this.state.tree, [asset.id]),
    });

    API.fetchAsset(asset.id)
      .then(({ data: asset }) => {
        const { entities } = normalizeAsset(asset);
        this.props.updateEntities(entities);
        this.setState({ isFetchingAssetId: null });
        this.props.onSelectAsset(asset.id);
      })
      .catch(() => {
        this.setState({ isFetchingAssetId: null });
      });
  };

  renderTreeNode = item => {
    const { isFetchingAssetId } = this.state;
    const { asset } = item.data;
    return (
      <div
        className={styles['item-node']}
        onClick={() => {
          this.selectAsset(asset);
        }}
      >
        <div className={styles['title-container']}>
          <p className={styles['title']}>{asset.title}</p>
          {this.props.settings.asset_number_activated ? (
            <p className={styles['subtitle']}>#{asset.number}</p>
          ) : null}
        </div>
        {this.isSelected(item.id) && isFetchingAssetId !== asset.id ? <Icon regular type="check" /> : null}
        {isFetchingAssetId === asset.id ? <Loader tiny /> : null}
      </div>
    );
  };

  renderContent = () => {
    if (this.state.isEmpty) return this.renderEmptyDataset();
    return (
      <Tree
        tree={this.state.tree}
        onExpand={this.expandTree}
        onCollapse={this.collapseTree}
        renderNode={item => {
          return this.renderTreeNode(item);
        }}
        isDragEnabled={false}
        isNestingEnabled
      />
    );
  };

  render() {
    return this.state.loading ? (
      <Modal.Loader />
    ) : (
      <React.Fragment>
        <Modal.Content grayBackground>{this.renderContent()}</Modal.Content>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStatetoProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}
export default connect(mapStatetoProps, mapDispatchToProps)(TreeContent);
