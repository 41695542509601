import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class AttachmentSubtitle extends Component {
  renderSingleAsset = () => {
    const { attachment, asset } = this.props;
    if (!asset) return null;

    if (asset.tree_path && asset.tree_path.length > 0) {
      return <TreePath onlyShowPath fullPath assetId={attachment.asset_id} />;
    } else {
      return <span>{asset.title}</span>;
    }
  };

  renderMultipleAssets = () => {
    const { asset_count } = this.props.attachment;
    return (
      <FormattedPlural
        value={asset_count}
        one={<FormattedMessage id="screens.files.single-asset" values={{ asset_count }} />}
        other={<FormattedMessage id="screens.files.amount-of-assets" values={{ asset_count }} />}
      />
    );
  };

  renderAssetSubtitle = () => {
    if (this.props.attachment.asset_count === 1) {
      return this.renderSingleAsset();
    } else {
      return this.renderMultipleAssets();
    }
  };

  render() {
    return <div className={styles['subtitle-container']}>{this.renderAssetSubtitle()}</div>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.attachment.asset_id),
    registrationWorkOrder: EntitySelectors.getWorkOrder(
      state,
      ownProps.attachment.registration_work_order_id
    ),
    completionWorkOrder: EntitySelectors.getWorkOrder(state, ownProps.attachment.completion_work_order_id),
  };
}

export default connect(mapStateToProps, null)(AttachmentSubtitle);
