import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeUser } from 'sdk/Schemas';
import { Button, Field, FieldErrorWrapper } from 'views/components/Shared/General';
import { SideModal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import PermissionsDropdown from './PermissionsDropdown';
import DebitToSystemModal from './DebitToSystemModal';
import styles from './style.module.scss';

class CreateUserModal extends Component {
  getInitialState = () => ({
    sendAsMail: true,
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    enterprise_billing_system_id: null,
    organisation_admin: false,
    isCreatingUser: false,
    createLoginCredential: false,
    isCreatingUserAndCreatingNew: false,
    closeSideModalOnClickOverlay: true,
    closeSideModalOnClickEscape: true,
    showEmptyNameError: false,
    showEmptyEmailError: false,
    showEmptyPasswordError: false,
    showPasswordsNotMatchingError: false,
    showErrorForAlreadyUsedEmail: false,
    showInvalidEmailError: false,
    showDebitToSystemModal: false,
    openedDebitToSystemModalFromCreateAndCreateNewButton: false,
    showSystemAccessMissingPermissionError: false,
    systemAccesses: {}, // { [system_id]: data },
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let systemAccesses = {};
      if (this.props.defaultSystemId) {
        systemAccesses = {
          ...systemAccesses,
          [this.props.defaultSystemId]: {},
        };
      }
      this.setState({
        ...this.getInitialState(),
        systemAccesses,
        createLoginCredential: this.props.organisationSettings.sso_oidc_activated === false,
      });
    }
  }

  getEnterpriseBillingsystem = () => {
    const { enterprise_split_billing } = this.props.organisationSettings;
    if (enterprise_split_billing === false) {
      if (Object.keys(this.state.systemAccesses).length === 1) {
        return Object.keys(this.state.systemAccesses)[0];
      }
      return this.props.systems[0].id;
    } else {
      if (Object.keys(this.state.systemAccesses).length === 1) {
        return Object.keys(this.state.systemAccesses)[0];
      }
      return this.state.enterprise_billing_system_id;
    }
  };

  buildParams = () => {
    let params = {
      name: this.state.name,
      email: this.state.email,
      language: this.props.language,
      organisation_admin: this.state.organisation_admin,
      enterprise_billing_system_id: this.getEnterpriseBillingsystem(),
    };
    if (this.state.createLoginCredential === true) {
      if (!this.state.sendAsMail) {
        params = {
          ...params,
          login_credential: {
            username: this.state.email,
            password: this.state.password,
          },
        };
      } else {
        params = {
          ...params,
          invited: true,
        };
      }
    }
    params = {
      ...params,
      system_accesses: Object.keys(this.state.systemAccesses).map(id => {
        const { user_type, permission_profile_id } = this.state.systemAccesses[id];
        return { user_type, permission_profile_id, system_id: id };
      }),
    };
    return params;
  };

  isSaveable = () => {
    let saveable = true;
    if (this.state.name.length === 0) {
      this.setState({ showEmptyNameError: true });
      saveable = false;
    }
    if (this.state.email.length === 0) {
      this.setState({ showEmptyEmailError: true });
      saveable = false;
    }
    if (!this.state.sendAsMail) {
      if (this.state.password.length === 0) {
        this.setState({ showEmptyPasswordError: true });
        saveable = false;
      }
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({ showPasswordsNotMatchingError: true });
        saveable = false;
      }
    }
    Object.keys(this.state.systemAccesses).forEach(systemId => {
      if (Object.keys(this.state.systemAccesses[systemId]).length === 0) {
        saveable = false;
        this.setState({ showSystemAccessMissingPermissionError: true });
      }
    });
    return saveable;
  };

  createUser = createNew => {
    const { enterprise_split_billing } = this.props.organisationSettings;
    const { organisation_admin, enterprise_billing_system_id, systemAccesses } = this.state;
    if (this.isSaveable() === false) {
      return;
    }

    const hasToChooseDebitSystem = organisation_admin || Object.keys(systemAccesses).length !== 1;
    if (enterprise_split_billing && hasToChooseDebitSystem && enterprise_billing_system_id == null) {
      this.setState({
        showDebitToSystemModal: true,
        openedDebitToSystemModalFromCreateAndCreateNewButton: createNew,
      });
      return;
    }
    let params = this.buildParams();

    this.setState({ isCreatingUser: createNew === false, isCreatingUserAndCreatingNew: createNew === true });
    this.props
      .createUserForOrganisation(this.props.organisation.id, params)
      .then(({ data: user }) => {
        const { entities } = normalizeUser(user);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.create-user-modal.create-user-success" />}
          />
        );
        if (createNew) {
          this.setState({ isCreatingUserAndCreatingNew: false });
          this.props.onCreatedWithReopen();
        } else {
          this.setState({ isCreatingUser: false });
          this.props.onCreated();
        }
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'email' })) {
          this.setState({ showErrorForAlreadyUsedEmail: true, enterprise_billing_system_id: null });
        } else if (HelperFunctions.hasError(e, { code: '10018', key: 'email' })) {
          this.setState({ showInvalidEmailError: true, enterprise_billing_system_id: null });
        }
        this.setState({ isCreatingUser: false, enterprise_billing_system_id: null });
      });
  };

  selectSystem = system_id => {
    if (this.state.systemAccesses[system_id]) {
      const { [system_id]: _, ...systemAccesses } = this.state.systemAccesses;
      this.setState({
        systemAccesses,
      });
    } else {
      this.setState({
        systemAccesses: {
          ...this.state.systemAccesses,
          [system_id]: {},
        },
      });
    }
  };

  renderPasswordFields = () => {
    return (
      <AnimateHeight
        duration={150}
        height={this.state.sendAsMail ? 0 : 'auto'}
        onAnimationEnd={() => this.passwordRef.focus()}
      >
        <div style={{ marginTop: 15 }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
                  <Field.Text
                    type="password"
                    autocomplete="new-password"
                    ref={ref => (this.passwordRef = ref)}
                    error={this.state.showEmptyPasswordError}
                    value={this.state.password}
                    onChange={password => this.setState({ password, showEmptyPasswordError: false })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="screens.users.create-user-modal.re-enter-password" />}>
                  <FieldErrorWrapper
                    position="top"
                    show={this.state.showPasswordsNotMatchingError}
                    errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
                  >
                    <Field.Text
                      type="password"
                      autocomplete="new-password"
                      error={this.state.showEmptyPasswordError || this.state.showPasswordsNotMatchingError}
                      value={this.state.confirmPassword}
                      onChange={confirmPassword =>
                        this.setState({ confirmPassword, showPasswordsNotMatchingError: false })
                      }
                    />
                  </FieldErrorWrapper>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </AnimateHeight>
    );
  };

  renderEmailErrorLabel = () => {
    if (this.state.showErrorForAlreadyUsedEmail) {
      return <FormattedMessage id="screens.users.create-user-modal.errors.email-in-use" />;
    }
    if (this.state.showInvalidEmailError) {
      return <FormattedMessage id="screens.users.create-user-modal.errors.invalid-email" />;
    }
    return null;
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.user.name" />}>
              <Field.Text
                autoFocus
                error={this.state.showEmptyNameError}
                value={this.state.name}
                onChange={name => this.setState({ name, showEmptyNameError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.user.email" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.showErrorForAlreadyUsedEmail || this.state.showInvalidEmailError}
                errorElement={this.renderEmailErrorLabel()}
              >
                <Field.Text
                  error={
                    this.state.showEmptyEmailError ||
                    this.state.showErrorForAlreadyUsedEmail ||
                    this.state.showInvalidEmailError
                  }
                  value={this.state.email}
                  onChange={email =>
                    this.setState({
                      email,
                      showEmptyEmailError: false,
                      showInvalidEmailError: false,
                      showErrorForAlreadyUsedEmail: false,
                    })
                  }
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.createLoginCredential}
              onChange={value => this.setState({ createLoginCredential: value })}
              label={<FormattedMessage id="screens.users.create-user-modal.create-login-credential" />}
            />
          </Grid.Column>
        </Grid.Row>
        <div className={styles['invite-container']}>
          <AnimateHeight duration={150} height={this.state.createLoginCredential ? 'auto' : 0}>
            <Grid.Row>
              <Grid.Column>
                <Field.Radio.Group>
                  <Field.Radio
                    checked={this.state.sendAsMail}
                    onChange={() => this.setState({ sendAsMail: true, password: '', confirmPassword: '' })}
                    label={<FormattedMessage id="screens.users.create-user-modal.send-as-email" />}
                    questionTooltipContent={
                      <FormattedMessage id="screens.users.create-user-modal.tooltips.login-credential" />
                    }
                  />
                  <Field.Radio
                    checked={!this.state.sendAsMail}
                    onChange={() => this.setState({ sendAsMail: false })}
                    label={<FormattedMessage id="screens.users.create-user-modal.enter-password" />}
                  />
                </Field.Radio.Group>
                {this.renderPasswordFields()}
              </Grid.Column>
            </Grid.Row>
          </AnimateHeight>
        </div>

        <Grid.Separator />
        {this.renderPermissions()}
      </Grid>
    );
  };

  renderDebitSystemModal = () => {
    let systemIds = [];
    if (this.state.organisation_admin || Object.keys(this.state.systemAccesses).length === 0) {
      systemIds = this.props.systems.map(({ id }) => id);
    } else {
      systemIds = Object.keys(this.state.systemAccesses);
    }
    return (
      <DebitToSystemModal
        systemIds={systemIds}
        open={this.state.showDebitToSystemModal}
        onSave={enterprise_billing_system_id => {
          this.setState(
            {
              enterprise_billing_system_id,
              showDebitToSystemModal: false,
              isCreatingUser: this.state.openedDebitToSystemModalFromCreateAndCreateNewButton === false,
              isCreatingUserAndCreatingNew:
                this.state.openedDebitToSystemModalFromCreateAndCreateNewButton === true,
            },
            () => {
              this.createUser(this.state.openedDebitToSystemModalFromCreateAndCreateNewButton);
            }
          );
        }}
        onClose={() => this.setState({ showDebitToSystemModal: false })}
      />
    );
  };

  renderPermissions = () => {
    let classNames = [];
    if (this.state.organisation_admin) {
      classNames = [...classNames, styles['inactive']];
    }
    return (
      <>
        <div className={styles['permissions-title']}>
          <FormattedMessage id="screens.organisation.user-permissions-section-title" />
        </div>

        <div>
          <Field.Checkbox
            checked={this.state.organisation_admin === true}
            onChange={value =>
              this.setState(prevState => ({
                organisation_admin: !prevState.organisation_admin,
              }))
            }
            label={
              <>
                <div>
                  <FormattedMessage id="screens.organisation.user-permission-modal.org-admin-field" />
                </div>
                <div className={styles['subtitle']}>
                  <FormattedMessage id="screens.organisation.user-permission-modal.org-admin-subtitle" />
                </div>
              </>
            }
          />
          <div className={styles['separator']} />
        </div>
        <div className={classNames.join(' ')}>
          {this.props.systems.map(system => {
            return (
              <div className={styles['permission-container']}>
                <Field.Checkbox
                  checked={this.state.systemAccesses[system.id] != null}
                  onChange={value => this.selectSystem(system.id)}
                  label={system.name}
                />

                <AnimateHeight
                  duration={250}
                  height={this.state.systemAccesses[system.id] != null ? 'auto' : 0}
                >
                  <div className={styles['permission']}>
                    <Field
                      view={false}
                      label={
                        <span>
                          <FormattedMessage id="resources.user.permission" />
                          <span className={styles['subtitle']}> - {system.name}</span>
                        </span>
                      }
                    >
                      <PermissionsDropdown
                        systemId={system.id}
                        showPermissionIsRequiredError={this.state.showSystemAccessMissingPermissionError}
                        permission={this.state.systemAccesses[system.id]}
                        onChange={data => {
                          this.setState({
                            systemAccesses: {
                              ...this.state.systemAccesses,
                              [system.id]: data,
                            },
                          });
                        }}
                      />
                    </Field>
                  </div>
                </AnimateHeight>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal
          closeOnEscape={this.state.closeSideModalOnClickEscape}
          closeOnClickOverlay={this.state.closeSideModalOnClickOverlay}
          open={this.props.open}
          onClose={() => {
            this.props.onClose();
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createUser(false)}
                loading={this.state.isCreatingUser}
              />
              <Button
                label="general.save-and-create-new"
                loading={this.state.isCreatingUserAndCreatingNew}
                onClick={() => this.createUser(true)}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          }
        >
          <SideModal.Container>
            <SideModal.Container.Content>
              <SideModal.Header
                title={<FormattedMessage id="screens.users.create-user-modal.title" />}
                subtitle={<FormattedMessage id="screens.users.create-user-modal.subtitle" />}
                onClose={this.props.onClose}
              />
              {this.renderContent()}
            </SideModal.Container.Content>
          </SideModal.Container>
        </SideModal>
        {this.renderDebitSystemModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createUserForOrganisation: SDKReduxOperations.createUserForOrganisation,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
    language: AuthSelectors.getLanguage(state),
    settings: AuthSelectors.getSettings(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
    systems: AuthSelectors.getSystems(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateUserModal));

CreateUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  closeOnClickOverlay: PropTypes.bool,
};

CreateUserModal.defaultProps = {
  open: false,
  closeOnClickOverlay: true,
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
};
