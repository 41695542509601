import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'views/components/Shared/General';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

const AssetTreePath = props => {
  if (props.treePath.length === 0 && !props.fullPath) return null;

  let sizeClassName;
  if (props.size === 10) {
    sizeClassName = 'size-10';
  } else if (props.size === 11) {
    sizeClassName = 'size-11';
  } else if (props.size === 12) {
    sizeClassName = 'size-12';
  } else if (props.size === 13) {
    sizeClassName = 'size-13';
  }

  let colorClassName;
  if (props.dark) {
    colorClassName = 'color-dark';
  } else if (props.white) {
    colorClassName = 'color-white';
  } else {
    colorClassName = 'color-gray';
  }

  const renderItem = asset => {
    if (props.renderItem) {
      return props.renderItem(asset);
    } else {
      return <span>{asset.title}</span>;
    }
  };

  return (
    <div className={`${styles['tree-path']} ${styles[sizeClassName]} ${styles[colorClassName]}`}>
      {props.treePath.map((asset, i) => (
        <React.Fragment key={asset.id}>
          {i === 0 ? null : <Icon type="arrow-alt-right" />}
          {renderItem(asset)}
        </React.Fragment>
      ))}
      {props.fullPath ? (
        <React.Fragment>
          {props.treePath.length > 0 ? <Icon type="arrow-alt-right" /> : null}
          {renderItem(props.asset)}
        </React.Fragment>
      ) : null}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  let treePath = EntitySelectors.getTreePathForAsset(state, ownProps.assetId);
  let filteredTreePath = [];
  let b = false;

  if (ownProps.fromAssetId) {
    treePath.forEach(asset => {
      if (b) {
        filteredTreePath.push(asset);
      }

      if (asset.id === ownProps.fromAssetId) {
        b = true;
      }
    });
  }

  if (b == false && ownProps.fromAssetId !== ownProps.assetId) {
    filteredTreePath = treePath;
  }

  return {
    treePath: filteredTreePath,
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps)(AssetTreePath);

AssetTreePath.propTypes = {
  path: PropTypes.array,
  fullPath: PropTypes.bool,
  size: PropTypes.oneOf([10, 11, 12, 13]),
  dark: PropTypes.bool,
  fromAssetId: PropTypes.string,
};
AssetTreePath.defaultProps = {
  path: [],
  fullPath: false,
  size: 12,
  dark: false,
  white: false,
  fromAssetId: null,
};
