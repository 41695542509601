import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { normalizeMeter } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { Field, Button } from 'views/components/Shared/General';

class EditTitleModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    title: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        title: this.props.meter.title,
      });
    }
  }

  updateTitle = () => {
    const params = {
      title: this.state.title,
    };
    this.setState({ isSaving: true });
    API.updateMeter(this.props.meter.id, params)
      .then(({ data: meter }) => {
        const { entities } = normalizeMeter(meter);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <>
        <Modal width={460} isOpen={this.props.open}>
          <Modal.Header title={<FormattedMessage id="general.change-title" />} onClose={this.props.onClose} />
          <Modal.Content>
            <Field>
              <Field.Text
                autoFocus
                value={this.state.title}
                onChange={title => {
                  this.setState({ title });
                }}
              />
            </Field>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={this.updateTitle}
                disabled={this.state.title === ''}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    meter: EntitySelectors.getMeter(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTitleModal);
