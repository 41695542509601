import types from './types';

const setErrors = payload => ({
  type: types.SET_ERRORS,
  payload,
});

const removeErrors = payload => ({
  type: types.REMOVE_ERORRS,
  payload,
});

const incrementImageLoaderCount = () => ({
  type: types.INCREMENT_IMAGE_LOADER_COUNT,
});

const decrementImageLoaderCount = () => ({
  type: types.DECREMENT_IMAGE_LOADER_COUNT,
});

const prepareNewMaintenanceTemplateVersion = payload => ({
  type: types.PREPARE_NEW_MAINTENANCE_TEMPLATE_VERSION,
  payload,
});

const setEditingOperationalMaintenanceTemplateVersionValue = payload => ({
  type: types.SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_VALUES,
  payload,
});

const setEditingOperationalMaintenanceTemplateVersion = payload => ({
  type: types.SET_EDITING_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
  payload,
});

export default {
  setEditingOperationalMaintenanceTemplateVersionValue,
  setEditingOperationalMaintenanceTemplateVersion,
  prepareNewMaintenanceTemplateVersion,
  incrementImageLoaderCount,
  decrementImageLoaderCount,
  setErrors,
  removeErrors,
};
