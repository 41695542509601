import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class TemplateListItem extends Component {
  renderSubtitle = () => {
    if (this.props.operationalMaintenanceTemplate.asset_count === 1) {
      return this.props.asset.title;
    }
    return (
      <FormattedPlural
        value={this.props.operationalMaintenanceTemplate.asset_count}
        one={
          <FormattedMessage
            id="screens.operational-maintenances.templates.amount-of-assets.one"
            values={{ amount: this.props.operationalMaintenanceTemplate.asset_count }}
          />
        }
        two={
          <FormattedMessage
            id="screens.operational-maintenances.templates.amount-of-assets.two"
            values={{ amount: this.props.operationalMaintenanceTemplate.asset_count }}
          />
        }
        few={
          <FormattedMessage
            id="screens.operational-maintenances.templates.amount-of-assets.few"
            values={{ amount: this.props.operationalMaintenanceTemplate.asset_count }}
          />
        }
        many={
          <FormattedMessage
            id="screens.operational-maintenances.templates.amount-of-assets.many"
            values={{ amount: this.props.operationalMaintenanceTemplate.asset_count }}
          />
        }
        other={
          <FormattedMessage
            id="screens.operational-maintenances.templates.amount-of-assets.other"
            values={{ amount: this.props.operationalMaintenanceTemplate.asset_count }}
          />
        }
      />
    );
  };

  renderListItemColumns = () => {
    return (
      <>
        <List.Item.TitleColumn
          title={this.props.operationalMaintenanceTemplate.title}
          subtitle={this.renderSubtitle()}
        />
      </>
    );
  };
  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item small clickable linkTo={`/operational-maintenances/templates/${this.props.id}`}>
          {this.renderListItemColumns()}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  let asset = null;
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    ownProps.id
  );
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplate.operational_maintenance_template_asset
  );
  if (operationalMaintenanceTemplateAsset) {
    asset = EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id);
  }

  return {
    operationalMaintenanceTemplate,
    operationalMaintenanceTemplateAsset,
    asset,
  };
}

export default connect(mapStateToProps)(TemplateListItem);
