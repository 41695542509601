/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getAsset = state => {
  const { operatorCheckedInAsset, entities } = state;
  if (operatorCheckedInAsset.assetId) {
    return entities.assetById[operatorCheckedInAsset.assetId];
  }
  return null;
};

const getAssetId = state => {
  const { operatorCheckedInAsset } = state;
  return operatorCheckedInAsset.assetId;
};

export default {
  getAsset,
  getAssetId,
};
