const getMeterIds = state => state.meters.ids;
const getNavigatedTo = state => state.meters.navigatedTo;
const getPagination = state => state.meters.pagination;
const getQueryParameters = (state, key) => state.meters.queryParams;
const getTotalEntriesIsSelected = state => state.meters.selectedMeters.totalEntriesIsSelected;
const getPageIsSelected = state => state.meters.selectedMeters.pageIsSelected;
const getSelectedMeterIds = state => state.meters.selectedMeters.ids;
const getSelectedMetersCount = state =>
  state.meters.selectedMeters.totalEntriesIsSelected
    ? state.meters.pagination.totalEntries
    : Object.keys(state.meters.selectedMeters.ids).length;
const getShowSelectTotalEntries = state => state.meters.selectedMeters.showSelectTotalEntries;

export default {
  getMeterIds,
  getNavigatedTo,
  getPagination,
  getQueryParameters,
  getTotalEntriesIsSelected,
  getPageIsSelected,
  getSelectedMeterIds,
  getSelectedMetersCount,
  getShowSelectTotalEntries,
};
