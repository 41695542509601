import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field, Tooltip, Loader, Icon } from 'views/components/Shared/General';
import { CurrencyRateUpgradeProModal } from 'views/components/PurchaseOrder';
import { request } from 'sdk/utilities/Axios';
import styles from './style.module.scss';

class ChangeCurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingRate: false,
      isSaving: false,
      saveCurrencyOnVendor: false,
      selectedCurrency: this.props.currency,
      exchangeRate: this.props.purchaseOrder.currency_exchange_rate || '',
      searchTerm: '',
      currencies: HelperFunctions.getAvailableCurrencies(),
      showBasicFetchRateModal: false,
      showCurrencyConfirmModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        saveCurrencyOnVendor: false,
        selectedCurrency: this.props.currency,
        exchangeRate: this.props.purchaseOrder.currency_exchange_rate || '',
      });
      if (this.props.currency !== this.props.purchaseOrder.currency) {
        this.fetchCurrencyRate(this.props.currency);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    this.setState({ isSaving: true });
    if (this.state.saveCurrencyOnVendor) {
      const params = {
        vendor_id: this.props.purchaseOrder.vendor_id,
        has_purchase_price: true,
      };
      API.listSparePartVendors(this.props.currentSystem.id, params).then(({ headers }) => {
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        if (pagination.totalEntries > 0) {
          this.setState({ showCurrencyConfirmModal: true, isSaving: false });
        } else {
          this.updatePurchaseOrder();
        }
      });
    } else {
      this.updatePurchaseOrder();
    }
  };

  updatePurchaseOrder = () => {
    this.setState({ isSaving: true });

    let requests = [
      this.props.updatePurchaseOrder(this.props.purchaseOrder.id, {
        currency: this.state.selectedCurrency,
        currency_exchange_rate: this.state.exchangeRate,
      }),
    ];
    if (this.state.saveCurrencyOnVendor) {
      requests = [
        ...requests,
        this.props.updateVendor(this.props.purchaseOrder.vendor_id, {
          purchase_order_currency: this.state.selectedCurrency,
        }),
      ];
    }

    Promise.all(requests).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({ showCurrencyConfirmModal: false });
      this.props.onClose();
    });
  };

  fetchCurrencyRate = currency => {
    if (this.props.hasProTier) {
      this.setState({ isFetchingRate: true, selectedCurrency: currency });
      request
        .get(
          `systems/${this.props.currentSystem.id}/currency_exchange_rate?from=${currency}&to=${this.props.currentSystem.currency}`
        )
        .then(({ data }) => {
          this.setState({ isFetchingRate: false, exchangeRate: data.rate });
        });
    } else {
      this.setState({ selectedCurrency: currency });
    }
  };

  selectCurrency = currency => {
    if (currency !== this.state.selectedCurrency) {
      this.setState({ exchangeRate: '' });
      this.fetchCurrencyRate(currency);
    }
  };

  renderVendorCurrencyConfirmModal = () => (
    <Modal isOpen={this.state.showCurrencyConfirmModal} width={440}>
      <Modal.Header
        title={
          <FormattedMessage
            id="components.change-purchase-order-currency-modal.vendor-currency-confirm-title"
            values={{
              currency: <FormattedMessage id={`currencies.${this.state.selectedCurrency}`} />,
            }}
          />
        }
        subtitle={
          <FormattedMessage id="components.change-purchase-order-currency-modal.vendor-currency-confirm-subtitle" />
        }
        subtitleTopMargin
        onClose={() => {
          this.setState({ showCurrencyConfirmModal: false });
        }}
        ignoreLine
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            label="general.save"
            loading={this.state.isSaving}
            onClick={this.updatePurchaseOrder}
          />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showCurrencyConfirmModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderBasicFetchRateModal = () => (
    <CurrencyRateUpgradeProModal
      open={this.state.showBasicFetchRateModal}
      onClose={() => {
        this.setState({ showBasicFetchRateModal: false });
      }}
    />
  );

  renderTitle = () => {
    if (this.props.forceCurrency) {
      return (
        <FormattedMessage
          id="components.change-purchase-order-currency-modal.title-force-currency"
          values={{
            currency: <FormattedMessage id={`currencies.${this.state.selectedCurrency}`} />,
            currencyShort: this.props.currency,
          }}
        />
      );
    }
    return <FormattedMessage id="components.change-purchase-order-currency-modal.title" />;
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={475}>
          <Modal.Header
            ignoreLine
            title={this.renderTitle()}
            subtitle={<FormattedMessage id="components.change-purchase-order-currency-modal.subtitle" />}
            subtitleTopMargin
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Grid>
              {this.props.forceCurrency ? null : (
                <Grid.Row>
                  <Grid.Column>
                    <div className={styles['dropdown']}>
                      <Field
                        label={
                          <FormattedMessage id="components.change-purchase-order-currency-modal.currency-label" />
                        }
                      >
                        <Field.Currency value={this.state.selectedCurrency} onChange={this.selectCurrency} />
                      </Field>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}
              {this.props.currentSystem.currency === this.state.selectedCurrency ? null : (
                <Grid.Row>
                  <Grid.Column>
                    <Field
                      view={false}
                      label={
                        <div className={styles['conversion-rate-field']}>
                          <p className={styles['label']}>
                            <FormattedMessage
                              id="components.change-purchase-order-currency-modal.conversion-rate-label"
                              values={{ currency: this.props.currentSystem.currency }}
                            />
                          </p>
                          {this.props.hasProTier === false ? (
                            <p
                              className={styles['conversion-rate']}
                              onClick={() => this.setState({ showBasicFetchRateModal: true })}
                            >
                              <FormattedMessage id="components.change-purchase-order-currency-modal.fetch-rate" />
                            </p>
                          ) : null}
                        </div>
                      }
                    >
                      <div className={styles['conversion-rate-container']}>
                        <div className={styles['field']}>
                          <Field.Decimal
                            disabled={this.state.isFetchingRate}
                            value={this.state.exchangeRate}
                            rightLabel={this.state.isFetchingRate ? <Loader tiny /> : null}
                            onBlur={value => this.setState({ exchangeRate: value })}
                          />
                        </div>
                        {this.props.hasProTier === false ? null : (
                          <div>
                            <Tooltip
                              trigger={
                                <Button
                                  icon={<Icon regular type="redo-alt" />}
                                  onClick={() => this.fetchCurrencyRate(this.state.selectedCurrency)}
                                />
                              }
                              label={
                                <FormattedMessage id="components.change-purchase-order-currency-modal.rate-tooltip" />
                              }
                            />
                          </div>
                        )}
                      </div>
                      <p className={styles['conversion-rate-subtitle']}>
                        <span>
                          1 <FormattedMessage id={`currencies.${this.state.selectedCurrency}`} />
                        </span>
                        <span> = </span>
                        <span>
                          {this.state.exchangeRate || 0}{' '}
                          <FormattedMessage id={`currencies.${this.props.currentSystem.currency}`} />
                        </span>
                      </p>
                    </Field>
                  </Grid.Column>
                </Grid.Row>
              )}
              {this.props.vendor &&
              this.state.selectedCurrency === this.props.vendor.purchase_order_currency ? null : (
                <React.Fragment>
                  <Grid.Separator />
                  <Grid.Row>
                    <Grid.Column>
                      <Field.Checkbox
                        label={this.props.intl.formatMessage(
                          {
                            id: 'components.change-purchase-order-currency-modal.suggest-currency-on-vendor-checkbox',
                          },
                          { vendor: this.props.vendor == null ? '' : this.props.vendor.name }
                        )}
                        checked={this.state.saveCurrencyOnVendor}
                        onChange={checked => {
                          this.setState({ saveCurrencyOnVendor: checked });
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </React.Fragment>
              )}
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={
                  this.props.currentSystem.currency !== this.state.selectedCurrency &&
                  !this.state.exchangeRate
                }
                label="general.save"
                loading={this.state.isSaving && !this.state.showCurrencyConfirmModal}
                onClick={this.save}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderBasicFetchRateModal()}
        {this.renderVendorCurrencyConfirmModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrder: SDKReduxOperations.updatePurchaseOrder,
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeCurrencyModal));
