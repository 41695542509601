import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as moment from 'moment';
import { bindActionCreators } from 'redux';
import { Button, ViewTextArea, WhiteCard, Icon, NewInlineModal } from 'views/components/Shared/General';
import { Grid, CardGrid, ToastMessage } from 'views/components/Shared/Layout';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';
import { CompleteOperationalMaintenanceModal } from 'views/components/OperationalMaintenance';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';

class StatusContainer extends Component {
  state = {
    isReactivatingOperationalMaintenance: false,
    showCompleteOperationalMaintenanceModal: false,
  };

  reactivateOperationalMaintenance = () => {
    if (this.state.isReactivatingOperationalMaintenance) return;
    this.setState({
      isReactivatingOperationalMaintenance: true,
    });
    this.props
      .updateOperationalMaintenanceInstance(this.props.operationalMaintenanceInstance.id, {
        status: OperationalMaintenanceStatus.NotStarted,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isReactivatingOperationalMaintenance: false });
      })
      .catch(e => {
        this.setState({ isReactivatingOperationalMaintenance: false });
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
      });
  };

  renderStatusAdditionalInfo = () => {
    if (this.props.operationalMaintenanceInstance.completed_comment) {
      return (
        <div className={styles['additional-info']}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div className={styles['comment']}>
                  <ViewTextArea>{this.props.operationalMaintenanceInstance.completed_comment}</ViewTextArea>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }
    return null;
  };

  renderEditTemplateButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <div
            onClick={() => {
              this.setState(prevState => ({
                showMenuDropdown: !prevState.showMenuDropdown,
              }));
            }}
            ref={ref => (this.menuInlineModalPositioningRef = ref)}
          >
            <Button gray icon={<Icon regular type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            positionToRef={this.menuInlineModalPositioningRef}
            open={this.state.showMenuDropdown}
            minWidth={250}
            position="right"
            onClose={() => this.setState({ showMenuDropdown: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <Link
                  to={`/operational-maintenances/templates/${this.props.operationalMaintenanceInstance.operational_maintenance_template_id}`}
                >
                  <NewInlineModal.Dropdown.Item>
                    <FormattedMessage id="screens.operational-maintenance.edit-template" />
                  </NewInlineModal.Dropdown.Item>
                </Link>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderCompletedButtons = () => {
    if (this.props.canCarryOutOperationalMaintenances) {
      return (
        <Button.Group>
          <Button
            gray
            label="general.edit"
            onClick={() =>
              this.setState({
                showCompleteOperationalMaintenanceModal: true,
              })
            }
          />
          <Button
            gray
            loading={this.state.isReactivatingOperationalMaintenance}
            label="screens.operational-maintenance.resume"
            onClick={() => {
              this.reactivateOperationalMaintenance();
            }}
          />
          {this.renderEditTemplateButton()}
        </Button.Group>
      );
    }
    return null;
  };

  renderSkippedButtons = () => {
    if (this.props.canCarryOutOperationalMaintenances) {
      return (
        <Button.Group>
          <Button
            gray
            loading={this.state.isReactivatingOperationalMaintenance}
            label="screens.operational-maintenance.resume"
            onClick={() => {
              this.reactivateOperationalMaintenance();
            }}
          />
          {this.renderEditTemplateButton()}
        </Button.Group>
      );
    }
    return null;
  };

  renderCompleteOperationalMaintenanceModal = () => {
    return (
      <CompleteOperationalMaintenanceModal
        operationalMaintenanceInstanceId={this.props.operationalMaintenanceInstance.id}
        open={this.state.showCompleteOperationalMaintenanceModal}
        onClose={() => this.setState({ showCompleteOperationalMaintenanceModal: false })}
      />
    );
  };

  renderCompletedStatusContent = () => {
    return (
      <div className={styles['status-container']}>
        <div className={styles['status-information']}>
          <div className={`${styles['status-title-container']} ${styles['completed']}`}>
            <div className={styles['icon-container']}>
              <Icon regular green type="check" />
            </div>
            <div className={styles['info-container']}>
              <p className={styles['title']}>
                <FormattedMessage id="resources.operational-maintenance.statuses.completed" />
              </p>
              <p className={styles['subtitle']}>
                <FormattedMessage
                  id="general.date-by-user"
                  values={{
                    date: moment(this.props.operationalMaintenanceInstance.completed_date).format('LL'),
                    user: <UserNameWrapper user={this.props.completedByUser} />,
                  }}
                />
              </p>
            </div>
            {this.renderCompletedButtons()}
          </div>
          {this.renderStatusAdditionalInfo()}
        </div>
      </div>
    );
  };

  renderSkippedStatusContent = () => {
    return (
      <div className={styles['status-container']}>
        <div className={styles['status-information']}>
          <div className={`${styles['status-title-container']} ${styles['skipped']}`}>
            <div className={styles['icon-container']}>
              <Icon type="lock" size={12} />
            </div>
            <div className={styles['info-container']}>
              <p className={styles['title']}>
                <FormattedMessage id="resources.operational-maintenance.statuses.skipped" />
              </p>
              <p className={styles['subtitle']}>
                <FormattedMessage
                  id="general.created-by"
                  values={{
                    user: <UserNameWrapper user={this.props.skippedByUser} />,
                  }}
                />
              </p>
            </div>
            {this.renderSkippedButtons()}
          </div>
          {this.renderStatusAdditionalInfo()}
        </div>
      </div>
    );
  };

  renderStatusContent = () => {
    const { operationalMaintenanceInstance } = this.props;
    switch (operationalMaintenanceInstance.status) {
      case OperationalMaintenanceStatus.Completed:
        return this.renderCompletedStatusContent();

      case OperationalMaintenanceStatus.Skipped:
        return this.renderSkippedStatusContent();
    }
  };

  render() {
    if (this.props.operationalMaintenanceInstance.status === OperationalMaintenanceStatus.NotStarted)
      return null;

    return (
      <>
        <div className={styles['status-wrapper']}>
          <CardGrid.Row>
            <WhiteCard noPadding>{this.renderStatusContent()}</WhiteCard>
          </CardGrid.Row>
          <div className={styles['separator']} />
        </div>
        {this.renderCompleteOperationalMaintenanceModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOperationalMaintenanceInstance: SDKReduxOperations.updateOperationalMaintenanceInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  return {
    completedByUser: EntitySelectors.getUser(state, operationalMaintenanceInstance.completed_by_user_id),
    skippedByUser: EntitySelectors.getUser(state, operationalMaintenanceInstance.skipped_by_user_id),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusContainer);
