/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getResourceItemsSelectedTab = state => state.workOrderEdit.resourceItemsSelectedTab;
const getImageLoaderCount = state => state.workOrderEdit.imageLoaderCount;
const isAddingChecklistTemplate = state => state.workOrderEdit.isAddingChecklistTemplate;

export default {
  getResourceItemsSelectedTab,
  getImageLoaderCount,
  isAddingChecklistTemplate,
};
