import React from 'react';
import styles from './style.module.scss';
import { SectionHeader } from '../../..';

const Section = props => {
  return (
    <div className={styles['section']}>
      <SectionHeader>{props.children}</SectionHeader>
    </div>
  );
};

export default Section;
