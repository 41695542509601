import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import { Button, ConfirmModal, Datapill, Icon, NewInlineModal } from 'views/components/Shared/General';
import { Header as LayoutHeader, ToastMessage } from 'views/components/Shared/Layout';
import { CompleteOperationalMaintenanceModal } from 'views/components/OperationalMaintenance';
import { EntitySelectors } from 'sdk/State/entities';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { TreePath } from 'views/components/Asset';
import { OperationalMaintenanceSelectors } from 'state/ducks/operationalMaintenance';
import { OperationalMaintenancesSelectors } from 'state/ducks/operationalMaintenances';
import styles from './style.module.scss';

class Header extends Component {
  state = {
    showCompleteOperationalMaintenanceModal: false,
    showSkipOperationalMaintenanceModal: false,
    isChangingOperationalMaintenanceStatus: false,
    showMenuDropdown: false,
    isSkipping: false,
  };

  componentDidUpdate(prevProps) {
    const allRowsHasBeenFilledIn = !prevProps.allRowsHasBeenFilledIn && this.props.allRowsHasBeenFilledIn;
    if (
      this.props.operationalMaintenanceInstance.status !== OperationalMaintenanceStatus.Completed &&
      allRowsHasBeenFilledIn
    ) {
      this.setState({ showCompleteOperationalMaintenanceModal: true });
    }
  }

  skipOperationalMaintenance = () => {
    if (this.state.isSkipping) return;

    this.setState({ isSkipping: true });

    const params = {
      skipped_by_user_id: this.props.currentUser.id,
      status: OperationalMaintenanceStatus.Skipped,
    };

    this.props
      .updateOperationalMaintenanceInstance(this.props.operationalMaintenanceInstance.id, params)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSkipping: false, showSkipOperationalMaintenanceModal: false });
      })
      .catch(e => {
        this.setState({ isSkipping: false });
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
      });
  };

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/operational-maintenances',
      search: HelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.work-order.operational-maintenance-breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>
          {this.props.operationalMaintenanceTemplateVersion.title}
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTimeOfDayDatapill = () => {
    if (this.props.operationalMaintenanceInstance == null) {
      return null;
    }
    if (this.props.operationalMaintenanceInstance.due_time_of_day) {
      return (
        <Datapill
          value={moment(this.props.operationalMaintenanceInstance.due_time_of_day, 'hh:mm').format('LT')}
        />
      );
    }
    return null;
  };

  renderDataPills = () => {
    const { calendar_date } = this.props.operationalMaintenanceInstance;
    return (
      <div className={styles['header-bottom-container']}>
        <div className={styles['datapills-container']}>
          <Datapill.List>
            <Datapill
              value={`${moment(calendar_date).format('dddd')} ${moment(
                this.props.operationalMaintenanceInstance.calendar_date
              ).format('LL')}`}
            />
            {this.renderTimeOfDayDatapill()}
          </Datapill.List>
        </div>
        {this.renderButtons()}
      </div>
    );
  };

  renderHeaderAssetPath = () => {
    if (this.props.asset == null) return null;
    if (this.props.canViewAssets) {
      return (
        <div>
          <div className={styles['header-path-container']}>
            <TreePath
              size={13}
              renderItem={asset => (
                <Link to={`/assets/${asset.id}`}>
                  <span className={styles['path-item']}>{asset.title}</span>
                </Link>
              )}
              fullPath
              assetId={this.props.asset.id}
            />
          </div>
          {this.renderDataPills()}
        </div>
      );
    }
    return (
      <div>
        <div className={styles['header-path-container']}>
          <TreePath
            size={13}
            renderItem={asset => <span>{asset.title}</span>}
            fullPath
            assetId={this.props.asset.id}
          />
        </div>
        {this.renderDataPills()}
      </div>
    );
  };

  renderEditOperationalMaintenanceDropdownItem = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <>
          <NewInlineModal.Dropdown.Separator />
          <Link
            to={`/operational-maintenances/templates/${this.props.operationalMaintenanceInstance.operational_maintenance_template_id}`}
          >
            <NewInlineModal.Dropdown.Item>
              <FormattedMessage id="screens.operational-maintenance.edit-template" />
            </NewInlineModal.Dropdown.Item>
          </Link>
        </>
      );
    }
    return null;
  };

  renderButtons = () => {
    if (this.props.operationalMaintenanceInstance.status !== OperationalMaintenanceStatus.NotStarted) {
      return null;
    }
    if (this.props.canCarryOutOperationalMaintenances === false) {
      return null;
    }
    return (
      <div className={styles['buttons']}>
        <Button
          gray
          label="screens.operational-maintenance.complete"
          onClick={() => {
            this.setState({ showCompleteOperationalMaintenanceModal: true });
          }}
        />
        <div
          onClick={() => {
            this.setState(prevState => ({
              showMenuDropdown: !prevState.showMenuDropdown,
            }));
          }}
          ref={ref => (this.menuInlineModalPositioningRef = ref)}
        >
          <Button gray icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.menuInlineModalPositioningRef}
          open={this.state.showMenuDropdown}
          minWidth={250}
          position="right"
          onClose={() => this.setState({ showMenuDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showSkipOperationalMaintenanceModal: true, showMenuDropdown: false });
                }}
              >
                <FormattedMessage id="screens.operational-maintenance.skip" />
              </NewInlineModal.Dropdown.Item>
              {this.renderEditOperationalMaintenanceDropdownItem()}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderCompleteOperationalMaintenanceModal = () => {
    return (
      <CompleteOperationalMaintenanceModal
        operationalMaintenanceInstanceId={this.props.match.params.id}
        open={this.state.showCompleteOperationalMaintenanceModal}
        onClose={() => this.setState({ showCompleteOperationalMaintenanceModal: false })}
      />
    );
  };

  renderSkipOperationalMaintenanceModal = () => {
    return (
      <ConfirmModal
        open={this.state.showSkipOperationalMaintenanceModal}
        title={<FormattedMessage id="screens.operational-maintenance.skip-confirm-modal.title" />}
        message={<FormattedMessage id="screens.operational-maintenance.skip-confirm-modal.message" />}
        confirmButtonText="general.save"
        confirmIsLoading={this.state.isSkipping}
        onConfirm={() => {
          if (this.state.isSkipping) {
            return;
          }
          this.skipOperationalMaintenance();
        }}
        onCancel={() => {
          this.setState({ showSkipOperationalMaintenanceModal: false });
        }}
      />
    );
  };

  render() {
    let maxWidth = 1100;
    let minWidth = 1000;
    if (this.props.checklistInstances.length == 0) {
      maxWidth = 940;
      minWidth = 940;
    }

    return (
      <>
        <LayoutHeader
          maxWidth={maxWidth}
          minWidth={minWidth}
          title={this.props.operationalMaintenanceTemplateVersion.title}
          titleHoverText={this.props.operationalMaintenanceTemplateVersion.title}
          breadcrumbComponent={this.renderBreadcrumb()}
          extraComponent={this.renderHeaderAssetPath()}
        />
        {this.renderCompleteOperationalMaintenanceModal()}
        {this.renderSkipOperationalMaintenanceModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOperationalMaintenanceInstance: SDKReduxOperations.updateOperationalMaintenanceInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(state, id);
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operationalMaintenanceInstance.operational_maintenance_template_version_id
  );
  const asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
  return {
    navigatedTo: OperationalMaintenancesSelectors.getNavigatedToOperationalMaintenance(state),
    operationalMaintenanceInstance,
    operationalMaintenanceTemplateVersion,
    asset,
    currentUser: AuthSelectors.getCurrentUser(state),
    system: AuthSelectors.getCurrentSystem(state),
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    allRowsHasBeenFilledIn: OperationalMaintenanceSelectors.getAllRowsHasBeenFilledIn(state),
    checklistInstances:
      EntitySelectors.getChecklistTemplateLinks(state, operationalMaintenanceInstance.checklist_instances) ||
      [],
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
