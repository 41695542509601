import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field, NewInlineModal, SectionHeader } from 'views/components/Shared/General';
import { AssetTypeInlineModal } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { normalizeAssetType } from 'sdk/Schemas';
import { AssetsSelectors } from 'state/ducks/assets';
import AssetTypeContainer from './AssetTypeContainer';
import AssetField from './AssetField';
import EditAssetField from './EditAssetField';
import styles from './styles.module.scss';
import { EntityOperations } from 'sdk/State/entities';

const BATCH_UPDATABLE_FIELDS = {
  AssetType: 'asset_type_id',
  AssetFields: 'asset_fields',
};

class MassEditModal extends Component {
  getInitialState = () => ({
    isFetchingAssetTypes: false,
    isSaving: false,
    confirmMassEdit: false,
    showEditForField: null,
    asset_type_id: null,
    asset_fields: null,
    showQuantityExceeded: false,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
      updatableFields: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        isFetchingAssetTypes: true,
        updatableFields: [],
        showQuantityExceeded: this.props.checkedAssetCount >= 5000,
      });
      this.fetchAssetTypes();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  fetchAssetTypes = () => {
    API.listAssetTypes(this.props.system.id).then(({ data }) => {
      const { entities, result } = normalizeAssetType(data);
      this.props.updateEntities(entities);
      const assetTypes = result.map(id => entities.assetTypeById[id]);
      this.setState({ assetTypes, isFetchingAssetTypes: false }, () =>
        this.setState({ updatableFields: this.getUpdatableFields() })
      );
    });
  };

  getUpdatableFields = () => {
    let updatableFields = [
      {
        title: this.props.intl.formatMessage({ id: 'resources.asset.type' }),
        key: BATCH_UPDATABLE_FIELDS.AssetType,
      },
      ...this.getUpdatableFieldsWithoutAssetType(),
      ...this.getUpdatableFieldsPerAssetType(),
    ];
    return updatableFields;
  };

  getUpdatableFieldsWithoutAssetType = () => {
    return this.props.templateFields
      .filter(({ asset_type_id }) => asset_type_id == null)
      .map(templateField => {
        return {
          title: templateField.title,
          key: BATCH_UPDATABLE_FIELDS.AssetFields,
          asset_fields: {
            id: templateField.id,
            value: null,
          },
        };
      })
      .sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
  };

  getUpdatableFieldsPerAssetType = () => {
    return this.state.assetTypes
      .sort((a, b) => {
        return a.title - b.title;
      })
      .filter(({ id }) => this.props.templateFields.find(({ asset_type_id }) => id === asset_type_id) != null)
      .map(({ id, title }) => {
        return [
          { header: true, title },
          ...this.props.templateFields
            .filter(({ asset_type_id }) => asset_type_id == id)
            .map(templateField => {
              return {
                title: templateField.title,
                key: BATCH_UPDATABLE_FIELDS.AssetFields,
                asset_fields: {
                  id: templateField.id,
                  value: null,
                },
              };
            })
            .sort((a, b) => {
              if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
              }
              if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
              }
              return 0;
            }),
        ];
      })
      .flat();
  };

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        filters: {
          ...{
            asset_type_id: this.props.assetTypeId,
          },
          ...this.props.filtersAsQueryParams,
          ...this.props.queryParameters,
        },
      };
    }
    return {
      filters: {
        id: {
          any: Object.keys(this.props.checkedAssetIds).toString(),
        },
      },
    };
  };

  buildDataParams = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.AssetType:
        return {
          update: {
            asset_type_id: this.state.asset_type_id,
          },
        };
      case BATCH_UPDATABLE_FIELDS.AssetFields:
        return {
          update: {
            asset_fields: [
              {
                template_field_id: this.state.asset_fields.id,
                value: this.state.asset_fields.value,
              },
            ],
          },
        };
      default:
        return {
          update: {},
        };
    }
  };

  batchUpdate = () => {
    this.setState({ isSaving: true });
    API.batchUpdateAssets(this.props.system.id, {
      ...this.buildFilterParams(),
      ...this.buildDataParams(),
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false, showQuantityExceeded: true });
        }
      });
  };

  selectFieldToUpdate = params => {
    this.setState({
      ...this.getInitialState(),
      ...params,
    });
  };

  renderSelectedField = () => {
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.AssetType:
        return <FormattedMessage id="resources.asset.type" />;
      case BATCH_UPDATABLE_FIELDS.AssetFields:
        return <AssetField id={this.state.asset_fields.id} />;
      default:
        return null;
    }
  };

  renderSelectedAssetType = () => {
    if (this.state.asset_type_id) {
      return <AssetTypeContainer id={this.state.asset_type_id} />;
    }
    return null;
  };

  renderEditAssetType = () => {
    return (
      <Field view={false} label={<FormattedMessage id="resources.asset.type" />}>
        <AssetTypeInlineModal
          trigger={
            <Field.Resource
              angleDown
              value={this.renderSelectedAssetType()}
              onClear={() => this.setState({ asset_type_id: null })}
            />
          }
          selectedAssetTypeId={this.state.asset_type_id}
          onSelectAssetType={id => this.setState({ asset_type_id: id })}
          onClear={() => this.setState({ asset_type_id: null })}
        />
      </Field>
    );
  };

  renderEditAssetField = () => {
    return (
      <EditAssetField
        id={this.state.asset_fields.id}
        value={this.state.asset_fields.value}
        onChange={value => this.setState({ asset_fields: { ...this.state.asset_fields, value } })}
        onClear={() => this.setState({ asset_fields: { ...this.state.asset_fields, value: null } })}
      />
    );
  };

  renderEditableField = () => {
    let content = null;
    switch (this.state.showEditForField) {
      case BATCH_UPDATABLE_FIELDS.AssetType:
        content = this.renderEditAssetType();
        break;
      case BATCH_UPDATABLE_FIELDS.AssetFields:
        content = this.renderEditAssetField();
        break;
      default:
        return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>{content}</Grid.Column>
      </Grid.Row>
    );
  };

  renderTemplateFields = () => {
    const { asset_fields } = this.state;
    return this.props.templateFields.map(templateField => (
      <Field.Dropdown.Item
        selected={asset_fields && asset_fields.id === templateField.id}
        onClick={() =>
          this.selectFieldToUpdate({
            showEditForField: BATCH_UPDATABLE_FIELDS.AssetFields,
            asset_fields: {
              id: templateField.id,
              value: null,
            },
          })
        }
      >
        {templateField.title}
      </Field.Dropdown.Item>
    ));
  };

  renderDropdownItem = data => {
    const { header, title, subtitle, key, asset_fields } = data;
    if (header) {
      return <Field.Dropdown.Section>{title}</Field.Dropdown.Section>;
    }
    return (
      <Field.Dropdown.Item
        selected={this.state.showEditForField === key && this.state.asset_fields?.id == asset_fields?.id}
        onClick={() => this.selectFieldToUpdate({ showEditForField: key, asset_fields })}
      >
        <div>{title}</div>
        <div className={styles['subtitle']}>{subtitle}</div>
      </Field.Dropdown.Item>
    );
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="components.mass-edit-modal.select-field-label" />}>
            <Field.Dropdown clearable={false} value={this.renderSelectedField()}>
              {this.state.updatableFields.map(data => this.renderDropdownItem(data))}
            </Field.Dropdown>
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderEditableField()}
      {this.renderConfirmCheckbox()}
    </Grid>
  );

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedAssetCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-assets.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-assets.two"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-assets.few"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-assets.many"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-assets.other"
            values={{ count: this.props.checkedAssetCount }}
          />
        }
      />
    );
  };

  renderConfirmCheckbox = () => {
    if (this.state.showEditForField == null) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.confirmMassEdit}
            label={
              <FormattedMessage
                id="components.mass-edit-modal.i-am-sure"
                values={{
                  count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                }}
              />
            }
            onChange={() => {
              this.setState(prevState => ({
                confirmMassEdit: !prevState.confirmMassEdit,
              }));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderModalContent = () => {
    if (this.state.isFetchingAssetTypes) {
      return <Modal.Content loading />;
    }
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>
            <FormattedMessage id="components.mass-edit-modal.quantity-exceeded" />
          </div>
        </Modal.Content>
      );
    }
    return (
      <>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              disabled={this.state.confirmMassEdit === false}
              loading={this.state.isSaving}
              onClick={this.batchUpdate}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.mass-edit-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    templateFields: AuthSelectors.getAssetTemplateFields(state),
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: AssetsSelectors.getQueryParameters(state),
    filtersAsQueryParams: AssetsSelectors.getFiltersAsQueryParams(state),
    checkedAssetIds: AssetsSelectors.getSelectedAssetIds(state),
    checkedAssetCount: AssetsSelectors.getSelectedAssetCount(state),
    totalEntriesIsSelected: AssetsSelectors.getTotalEntriesIsSelected(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MassEditModal));
