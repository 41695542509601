import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { ExportType } from 'sdk/Export';
import { NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { PrintQRCodeModal } from 'views/components/General';
import { NewSparePartModal } from 'views/components/SparePart';
import DeactivateStockModal from './DeactivateStockModal';
import SparePartDeleteModal from './SparePartDeleteModal';
import PrintModal from 'views/scenes/SpareParts/SparePartList/PrintModal';

class Menu extends Component {
  state = {
    dropdownOpen: false,
    isDeleting: false,
    showDeactivateStockModal: false,
    showDeleteSparePartModal: false,
    showPrintQrCodeModal: false,
    showDuplicateSparePartModal: false,
    showPrintSparePartModal: false,
  };

  renderDuplicateSparePartModal = () => {
    return (
      <NewSparePartModal
        duplicate
        duplicateFromSparePartId={this.props.match.params.id}
        title={<FormattedMessage id="components.new-spare-part-modal.duplicate.title" />}
        subtitle={<FormattedMessage id="components.new-spare-part-modal.duplicate.subtitle" />}
        open={this.state.showDuplicateSparePartModal}
        onClose={() => {
          this.setState({ showDuplicateSparePartModal: false });
        }}
        onCreated={sparePart => {
          this.props.history.push(`/spare-parts/${sparePart.id}`);
        }}
        onCreatedWithReopen={sparePart => {
          this.setState(
            {
              showDuplicateSparePartModal: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ showDuplicateSparePartModal: true });
              }, 350);
            }
          );
        }}
      />
    );
  };

  renderPrintQrCodeDropdownItem = () => {
    if (this.props.canEditSpareParts) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ showPrintQrCodeModal: true, dropdownOpen: false });
          }}
        >
          <FormattedMessage id="screens.spare-part.print-qr-code" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={250}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showPrintSparePartModal: true, dropdownOpen: false });
                }}
              >
                <FormattedMessage id="screens.spare-part.print-spare-part" />
              </NewInlineModal.Dropdown.Item>
              {this.renderPrintQrCodeDropdownItem()}
              {this.props.canEditSpareParts ? <NewInlineModal.Dropdown.Separator /> : null}
              {this.props.canEditSpareParts ? (
                <>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showDuplicateSparePartModal: true, dropdownOpen: false });
                    }}
                  >
                    <FormattedMessage id="screens.spare-part.duplicate" />
                  </NewInlineModal.Dropdown.Item>
                  {this.props.sparePart.stock_quantity == null ? null : (
                    <NewInlineModal.Dropdown.Item
                      onClick={() => {
                        this.setState({ showDeactivateStockModal: true, dropdownOpen: false });
                      }}
                    >
                      <FormattedMessage id="screens.spare-part.deactivate-stock-quantity" />
                    </NewInlineModal.Dropdown.Item>
                  )}
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({ dropdownOpen: false, showDeleteSparePartModal: true });
                    }}
                  >
                    <FormattedMessage id="general.delete" />
                  </NewInlineModal.Dropdown.Item>
                </>
              ) : null}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
        <DeactivateStockModal
          open={this.state.showDeactivateStockModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ showDeactivateStockModal: false });
          }}
        />
        <SparePartDeleteModal
          open={this.state.showDeleteSparePartModal}
          sparePart={this.props.sparePart}
          onDelete={() => {
            this.props.history.push('/spare-parts');
          }}
          onClose={() => {
            this.setState({ showDeleteSparePartModal: false });
          }}
        />

        <PrintQRCodeModal
          templateType="sparePart"
          open={this.state.showPrintQrCodeModal}
          sparePartId={this.props.match.params.id}
          onClose={() => this.setState({ showPrintQrCodeModal: false })}
        />
        <PrintModal
          open={this.state.showPrintSparePartModal}
          singleSparePartId={this.props.match.params.id}
          exportType={ExportType.SparePartsDetailPdf}
          onClose={() => this.setState({ showPrintSparePartModal: false })}
        />
        {this.renderDuplicateSparePartModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartId = ownProps.match.params.id;
  return {
    sparePart: EntitySelectors.getSparePart(state, sparePartId),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default withRouter(connect(mapStateToProps)(Menu));
