import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import uuid from 'uuid';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { NewWorkOrderSelectors, NewWorkOrderOperations } from 'state/ducks/newWorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations } from 'state/ducks/error';
import { Button } from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { WorkOrderCategoryType } from 'sdk/System';
import { UploadAttachmentErrorModal } from 'views/components/Attachment';
import { SideModal } from 'views/components/Shared/Layout';
import { ErrorSelectors } from 'state/ducks/error';
import {
  StatusButtons,
  ReminderWarningModalForRecurringMaintenance,
  EditReminderModal,
} from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import {
  buildWorkOrderFromEditingWorkOrder,
  WorkOrderStatus,
  ReminderType,
  WorkOrderCategory,
} from 'sdk/WorkOrder';
import { buildRecurringMaintenanceFromEditingWorkOrder } from 'sdk/RecurringMaintenance';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import CompleteWorkOrderContent from './CompleteWorkOrderContent';
import PlanWorkOrderContent from './PlanWorkOrderContent';
import CreateFromSingleChecklistInstanceRowTitle from './CreateFromSingleChecklistInstanceRowTitle';
import styles from './style.module.scss';

const uploadFilesRequest = HelperFunctions.getCancelTokenForRequest();

class NewWorkOrderModal extends Component {
  getInitialState = () => ({
    isCreatingWorkOrder: false,
    isCreatingWorkOrderAndCreatingNew: false,
    showEditReminderModal: false,
    showReminderWarningModalForRecurringMaintenance: false,
    closeSideModalOnClickOverlay: true,
    closeSideModalOnClickEscape: true,
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let defaultParams = {};
      if (this.props.canAdministrateWorkOrders) {
        defaultParams = this.props.defaultParams;
      } else {
        let category = null;
        if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
          category = WorkOrderCategory.CorrectiveImmediate;
        }
        if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Simple) {
          category = WorkOrderCategory.Corrective;
        }

        defaultParams = {
          completed_before_registration: true,
          status: WorkOrderStatus.Completed,
          category,
          completed_date: moment().format('YYYY-MM-DD'),
          completed_by_user_id: this.props.currentUser.id,
          ...this.props.defaultParams,
        };
      }
      if (this.props.forRequestId) {
        defaultParams = {
          ...defaultParams,
          created_from_requests: [this.props.forRequestId],
        };
      }
      if (this.props.forWorkOrder) {
        defaultParams = {
          ...defaultParams,
          created_from_work_order_id: this.props.forWorkOrderId,
        };
      }
      this.setState(
        {
          ...this.getInitialState(),
        },
        () => {
          this.props.prepareNewWorkOrder(defaultParams);
        }
      );
    } else if (prevProps.open && !this.props.open) {
      this.props.resetNewWorkOrderState();
      this.props.removeFieldsFromErrors([
        'completed_date',
        'title',
        'date',
        'category',
        'priority',
        'description',
        'assignee',
        'asset_id',
        'work_order_type_id',
        'estimated_minutes',
        'work_order_spent_times',
      ]);
    }
  }

  close = () => {
    uploadFilesRequest.cancel();
    this.props.onClose();
  };

  isCompletedBeforeRegistration = () => this.props.editingWorkOrder.completed_before_registration;

  changeStatus = (status, selectedDate = null) => {
    if (status === WorkOrderStatus.Completed) {
      let category = this.props.editingWorkOrder.category;
      if (this.props.editingWorkOrder.category == null) {
        category = WorkOrderCategory.Corrective;
        if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
          this.props.removeFieldsFromErrors(['category']);
          category = WorkOrderCategory.CorrectiveImmediate;
        }
        if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Simple) {
          this.props.removeFieldsFromErrors(['category']);
          category = WorkOrderCategory.Corrective;
        }
      }
      this.props.setEditingWorkOrderValues({
        status,
        completed_before_registration: true,
        date: selectedDate || this.props.editingWorkOrder.date,
        completed_by_user_id:
          this.props.editingWorkOrder.completed_by_user_id == null
            ? this.props.currentUser.id
            : this.props.editingWorkOrder.completed_by_user_id,
        completed_comment: this.props.editingWorkOrder.description,
        completion_images: this.props.editingWorkOrder.registration_images,
        registration_images: [],
        category,
        completion_attachments: this.props.editingWorkOrder.registration_attachments,
        registration_attachments: [],
        completed_date: selectedDate || this.props.editingWorkOrder.date || moment().format('YYYY-MM-DD'),
      });
    } else {
      this.props.setEditingWorkOrderValues({
        status,
        completed_before_registration: false,
        completed_date: null,
        category: null,
        completion_attachments: [],
        registration_attachments: this.props.editingWorkOrder.completion_attachments,
        registration_images: this.props.editingWorkOrder.completion_images,
        completion_images: [],
      });
    }
  };

  hasErrorsForPlannedWorkOrder = () => {
    const {
      work_order_category_required,
      work_order_description_required,
      work_order_asset_required,
      work_order_work_order_type_required,
      work_order_due_date_required,
      work_order_estimated_time_required,
      work_order_priority_required,
      work_order_assignee_required,
    } = this.props.settings;
    const {
      title,
      description,
      asset_id,
      priority,
      category,
      work_order_type_id,
      estimated_minutes,
      assigned_to_users,
      assigned_to_groups,
      assigned_to_vendors,
      meter_id,
      due_meter_value,
      date,
      reminder_type,
      meter_interval,
    } = this.props.editingWorkOrder;
    let errors = {};
    /*
      Required Fields
    */
    if (title.length === 0) {
      errors = { ...errors, title: 'general.errors.is-required' };
    }
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (work_order_category_required && category == null) {
        errors = { ...errors, category: 'general.errors.is-required' };
      }
    }
    if (work_order_description_required && (description == null || description.length === 0)) {
      errors = { ...errors, description: 'general.errors.is-required' };
    }
    if (work_order_asset_required && asset_id == null) {
      errors = { ...errors, asset_id: 'general.errors.is-required' };
    }
    if (work_order_work_order_type_required && work_order_type_id == null) {
      errors = { ...errors, work_order_type_id: 'general.errors.is-required' };
    }
    if (work_order_estimated_time_required && estimated_minutes == null) {
      errors = { ...errors, estimated_minutes: 'general.errors.is-required' };
    }
    if (
      (this.props.hasProTier === false || work_order_due_date_required) &&
      date == null &&
      due_meter_value == null
    ) {
      errors = { ...errors, date: 'general.errors.is-required' };
    }
    if (work_order_priority_required && priority == null) {
      errors = { ...errors, priority: 'general.errors.is-required' };
    }
    if (
      work_order_assignee_required &&
      assigned_to_users.length === 0 &&
      assigned_to_groups.length === 0 &&
      assigned_to_vendors.length === 0
    ) {
      errors = { ...errors, assignee: 'general.errors.is-required' };
    }

    /*
      Data inconsistencys
    */
    if (meter_id && due_meter_value == null) {
      errors = {
        ...errors,
        date: 'screens.work-order.errors.no-meter-due-value',
      };
    }
    if (this.props.editingWorkOrderIsRecurring) {
      const dueDateIsBeforeToday = moment(date).isBefore(moment().format('YYYY-MM-DD'), 'day');
      if (meter_id == null) {
        if (date == null) {
          errors = {
            ...errors,
            date: 'screens.work-order.errors.no-date-selected',
          };
        }
        if (dueDateIsBeforeToday) {
          errors = {
            ...errors,
            date: 'screens.work-order.errors.selected-date-in-past',
          };
        }
      } else if (meter_interval == null) {
        errors = {
          ...errors,
          frequency: 'general.errors.is-required',
        };
      }
      if (reminder_type === ReminderType.Exact) {
        this.setState({ showReminderWarningModalForRecurringMaintenance: true });
        return false;
      }
    }

    if (Object.keys(errors).length > 0) {
      this.props.setFieldErrors(errors);
      return true;
    }
    return false;
  };

  hasErrorsForCompletedWorkOrder = () => {
    const {
      work_order_category_required,
      work_order_asset_required,
      work_order_work_order_type_required,
      work_order_spent_time_required,
    } = this.props.settings;
    const { title, asset_id, category, work_order_type_id, completed_date, work_order_spent_times } =
      this.props.editingWorkOrder;
    let errors = {};
    if (title.length === 0) {
      errors = { ...errors, title: 'general.errors.is-required' };
    }
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (work_order_category_required && category == null) {
        errors = { ...errors, category: 'general.errors.is-required' };
      }
    }
    if (work_order_asset_required && asset_id == null) {
      errors = { ...errors, asset_id: 'general.errors.is-required' };
    }
    if (work_order_work_order_type_required && work_order_type_id == null) {
      errors = { ...errors, work_order_type_id: 'general.errors.is-required' };
    }
    if (work_order_spent_time_required && work_order_spent_times.length === 0) {
      errors = { ...errors, work_order_spent_times: 'general.errors.is-required' };
    }

    if (completed_date == null) {
      errors = {
        ...errors,
        completed_date: 'general.errors.is-required',
      };
    }

    if (Object.keys(errors).length > 0) {
      this.props.setFieldErrors(errors);
      return true;
    }
    return false;
  };

  hasErrors = () => {
    if (this.isCompletedBeforeRegistration()) {
      return this.hasErrorsForCompletedWorkOrder();
    } else {
      return this.hasErrorsForPlannedWorkOrder();
    }
  };

  createWorkOrder = createNew => {
    if (this.state.isCreatingWorkOrder) return;
    if (this.hasErrors()) {
      return;
    }

    this.setState({
      isCreatingWorkOrder: createNew === false,
      isCreatingWorkOrderAndCreatingNew: createNew === true,
    });

    if (this.props.editingWorkOrderIsRecurring) {
      const params = buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder);

      this.props
        .createRecurringMaintenance(this.props.currentSystem.id, params)
        .then(({ data: recurringMaintenance }) => {
          toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.create-success" />} />);
          if (createNew) {
            this.props.onCreatedWithReopen(recurringMaintenance.generated_work_order);
          } else {
            this.props.onCreated(recurringMaintenance.generated_work_order);
          }
        })
        .catch(e => {
          //TODO: Replace with generic error message
        });
    } else {
      let params = buildWorkOrderFromEditingWorkOrder(this.props.editingWorkOrder);
      if (params.status !== WorkOrderStatus.Completed) {
        params = {
          ...params,
          completed_date: null,
          completed_by_user_id: null,
        };
      }
      this.props
        .createWorkOrder(this.props.currentSystem.id, params)
        .then(({ data: workOrder }) => {
          if (workOrder.completed_before_registration) {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.work-order.create-completed-before-registration-success" />
                }
              />
            );
          } else {
            toast(
              <ToastMessage success text={<FormattedMessage id="screens.work-order.create-success" />} />
            );
          }
          if (createNew) {
            this.props.onCreatedWithReopen(workOrder);
          } else {
            this.props.onCreated(workOrder);
          }
        })
        .catch(e => {
          //TODO: Replace with generic error message
        });
    }
  };

  createAttachment = ({ description, title, extension, file, type, mime_type, link_url }) => {
    if (this.isCompletedBeforeRegistration()) {
      this.createDraftCompletionAttachment({
        description,
        title,
        extension,
        file,
        type,
        mime_type,
        link_url,
      });
    } else {
      this.createDraftRegistrationAttachment({
        description,
        title,
        extension,
        file,
        type,
        mime_type,
        link_url,
      });
    }
  };

  createDraftCompletionAttachment = ({ description, title, extension, file, type, mime_type, link_url }) => {
    const temporaryId = uuid.v4();
    const workOrderId = this.props.editingWorkOrder.id;
    this.props.beginUpload({ temporaryId, namespace: this.props.editingWorkOrder.id });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: workOrderId,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  workOrderId: this.props.editingWorkOrder.id,
                  completion: true,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.editingWorkOrder.id,
                  });
                });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(
          this.props.currentSystem.id,
          {
            description,
            title,
            extension,
            file,
            type,
            mime_type,
            link_url,
          },
          { completion: true }
        )
        .then(({ data: attachment }) => {
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: this.props.editingWorkOrder.id,
          });
        });
    }
  };

  createDraftRegistrationAttachment = ({
    description,
    title,
    extension,
    file,
    type,
    mime_type,
    link_url,
  }) => {
    const temporaryId = uuid.v4();
    const workOrderId = this.props.editingWorkOrder.id;
    this.props.beginUpload({ temporaryId, namespace: this.props.editingWorkOrder.id });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: workOrderId,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  workOrderId: this.props.editingWorkOrder.id,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.editingWorkOrder.id,
                  });
                });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: workOrderId });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: this.props.editingWorkOrder.id,
          });
        });
    }
  };

  deleteAttachment = attachment => {
    if (this.isCompletedBeforeRegistration()) {
      this.props.setEditingWorkOrderValues({
        completion_attachments: this.props.editingWorkOrder.completion_attachments.filter(
          completionAttachmentId => completionAttachmentId !== attachment.id
        ),
      });
    } else {
      this.props.setEditingWorkOrderValues({
        registration_attachments: this.props.editingWorkOrder.registration_attachments.filter(
          registrationAttachmentId => registrationAttachmentId !== attachment.id
        ),
      });
    }
  };

  renderForRequest = () => {
    if (!this.props.forRequest) return null;

    return (
      <>
        <div className={styles['create-from-resource-container']}>
          <div className={styles['create-from-resource']}>
            <div className={styles['title']}>{this.props.forRequest.title}</div>
            {this.props.forRequest.asset_id ? (
              <div className={styles['subtitle']}>
                <TreePath assetId={this.props.forRequest.asset_id} fullPath />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles['divider']} />
      </>
    );
  };

  renderForWorkOrder = () => {
    if (!this.props.forWorkOrder) return null;

    return (
      <>
        <div className={styles['create-from-resource-container']}>
          <div className={styles['create-from-resource']}>
            <div className={styles['title']}>{this.props.forWorkOrder.title}</div>
            {this.props.forWorkOrder.asset_id ? (
              <div className={styles['subtitle']}>
                <TreePath assetId={this.props.forWorkOrder.asset_id} fullPath />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles['divider']} />
      </>
    );
  };

  renderForChecklist = () => {
    if (this.props.forChecklistInstanceRowIds.length === 0) return null;
    if (this.props.forChecklistInstanceRowIds.length === 1) {
      return (
        <>
          <div className={styles['create-from-resource-container']}>
            <div className={styles['create-from-resource']}>
              <div className={styles['title']}>
                <CreateFromSingleChecklistInstanceRowTitle
                  checklistInstanceRowId={this.props.forChecklistInstanceRowIds[0]}
                />
              </div>
            </div>
          </div>
          <div className={styles['divider']} />
        </>
      );
    }

    return (
      <>
        <div className={styles['create-from-resource-container']}>
          <div className={styles['create-from-resource']}>
            <div className={styles['title']}>
              <FormattedPlural
                value={this.props.forChecklistInstanceRowIds.length}
                one={
                  <FormattedMessage id="components.new-work-order-modal.amount-of-checklist-instance-rows.one" />
                }
                two={
                  <FormattedMessage
                    id="components.new-work-order-modal.amount-of-checklist-instance-rows.two"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                few={
                  <FormattedMessage
                    id="components.new-work-order-modal.amount-of-checklist-instance-rows.few"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                many={
                  <FormattedMessage
                    id="components.new-work-order-modal.amount-of-checklist-instance-rows.many"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
                other={
                  <FormattedMessage
                    id="components.new-work-order-modal.amount-of-checklist-instance-rows.other"
                    values={{ amount: this.props.forChecklistInstanceRowIds.length }}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className={styles['divider']} />
      </>
    );
  };

  renderStatusOptions = () => {
    const { status } = this.props.editingWorkOrder;
    const { canAdministrateWorkOrders } = this.props;
    return (
      <div className={styles['statuses']}>
        <StatusButtons
          selectedStatus={status}
          onStatusChanged={this.changeStatus}
          forceCompleted={!canAdministrateWorkOrders}
        />
      </div>
    );
  };

  renderContent = () => {
    if (this.props.editingWorkOrder == null) {
      return null;
    }
    return (
      <>
        {this.renderForRequest()}
        {this.renderForWorkOrder()}
        {this.renderForChecklist()}
        {this.renderStatusOptions()}
        {this.isCompletedBeforeRegistration() ? (
          <CompleteWorkOrderContent
            hideArchivedUsers
            editingWorkOrder={this.props.editingWorkOrder}
            onChangeValue={params => this.props.setEditingWorkOrderValues(params)}
            onCreateAttachment={params => this.createDraftCompletionAttachment(params)}
            onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
          />
        ) : (
          <PlanWorkOrderContent
            onShowChecklistModal={() =>
              this.setState({
                closeSideModalOnClickOverlay: false,
                closeSideModalOnClickEscape: false,
              })
            }
            onHideChecklistModal={() =>
              this.setState({
                closeSideModalOnClickOverlay: true,
                closeSideModalOnClickEscape: true,
              })
            }
            forRequest={this.props.forRequest}
            editingWorkOrder={this.props.editingWorkOrder}
            onChangeValue={params => this.props.setEditingWorkOrderValues(params)}
            onCreateAttachment={params => this.createDraftRegistrationAttachment(params)}
            onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
            onChangeCompleteBeforeRegistration={(completedBeforeRegistration, date) =>
              this.changeStatus(WorkOrderStatus.Completed, date)
            }
          />
        )}
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
        <EditReminderModal
          open={this.state.showEditReminderModal}
          editingWorkOrder={this.props.editingWorkOrder}
          onSave={data => {
            this.props.setEditingWorkOrderValues(data);
          }}
          onClose={() => {
            this.setState({ showEditReminderModal: false });
          }}
        />
        <ReminderWarningModalForRecurringMaintenance
          open={this.state.showReminderWarningModalForRecurringMaintenance}
          onClose={() => {
            this.setState({ showReminderWarningModalForRecurringMaintenance: false });
            setTimeout(() => {
              this.setState({ showEditReminderModal: true });
            }, 250);
          }}
        />
      </>
    );
  };

  renderTitle = () => {
    if (this.props.forRequestId) {
      return <FormattedMessage id="components.new-work-order-modal.from-request-title" />;
    }
    if (this.props.forChecklistInstanceRowIds.length > 0) {
      return <FormattedMessage id="components.new-work-order-modal.from-checklist-title" />;
    }
    return <FormattedMessage id="components.new-work-order-modal.title" />;
  };

  renderSaveAndCreateNewButton = () => {
    if (this.props.forRequestId) {
      return null;
    }
    if (this.props.forChecklistInstanceRowIds.length > 0) {
      return null;
    }
    return (
      <Button
        label="general.save-and-create-new"
        loading={this.state.isCreatingWorkOrderAndCreatingNew}
        onClick={() => {
          this.createWorkOrder(true);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal
          closeOnEscape={this.state.closeSideModalOnClickEscape}
          closeOnClickOverlay={this.state.closeSideModalOnClickOverlay}
          open={this.props.open}
          onClose={() => {
            if (this.props.hasUnsavedChanges) {
              const confirmed = window.confirm(
                this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
              );
              if (confirmed) {
                this.close();
              }
            } else {
              this.close();
            }
          }}
          footerComponent={
            <Button.Group>
              <Button
                primary
                label="general.save"
                onClick={() => this.createWorkOrder(false)}
                loading={this.state.isCreatingWorkOrder}
              />
              {this.renderSaveAndCreateNewButton()}
              <Button label="general.cancel" onClick={this.close} />
            </Button.Group>
          }
        >
          <DropZone onDrop={data => this.createAttachment(data)}>
            <SideModal.Container>
              <SideModal.Container.Content>
                <SideModal.Header title={this.renderTitle()} onClose={this.close} />
                {this.renderContent()}
              </SideModal.Container.Content>
            </SideModal.Container>
          </DropZone>
        </SideModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createWorkOrder: SDKReduxOperations.createWorkOrder,
      createRecurringMaintenance: SDKReduxOperations.createRecurringMaintenance,
      setFieldErrors: ErrorOperations.setFieldErrors,
      prepareNewWorkOrder: NewWorkOrderOperations.prepareNewWorkOrder,
      setEditingWorkOrderValues: NewWorkOrderOperations.setEditingWorkOrderValues,
      resetNewWorkOrderState: NewWorkOrderOperations.resetState,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createDraftAttachment: SDKReduxOperations.createDraftAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    hasUnsavedChanges: NewWorkOrderSelectors.hasUnsavedChanges(state),
    editingWorkOrder: NewWorkOrderSelectors.getEditingWorkOrder(state),
    editingWorkOrderIsRecurring: NewWorkOrderSelectors.editingWorkOrderIsRecurring(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    forRequest: EntitySelectors.getRequest(state, ownProps.forRequestId),
    forWorkOrder: EntitySelectors.getWorkOrder(state, ownProps.forWorkOrderId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewWorkOrderModal));

NewWorkOrderModal.propTypes = {
  open: PropTypes.bool,
  forRequestId: PropTypes.string,
  forChecklistInstanceRowIds: PropTypes.array,
  defaultParams: PropTypes.object,
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onCreatedWithReopen: PropTypes.func,
  closeOnClickOverlay: PropTypes.bool,
};

NewWorkOrderModal.defaultProps = {
  open: false,
  closeOnClickOverlay: true,
  forRequestId: null,
  forChecklistInstanceRowIds: [],
  defaultParams: {},
  onClose: () => {},
  onCreated: () => {},
  onCreatedWithReopen: () => {},
};
