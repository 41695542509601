import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, FieldErrorWrapper } from 'views/components/Shared/General';
import { TreePath, ChooseAssetInlineModal } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';

const AssetField = ({ asset, error, value, onChange, defaultTreeParentId }) => (
  <Field view={false} label={<FormattedMessage id="resources.downtime.asset" />}>
    <ChooseAssetInlineModal
      width="300"
      clearable={false}
      defaultTreeParentId={defaultTreeParentId}
      trigger={
        <FieldErrorWrapper
          position="top"
          show={error}
          errorElement={
            <FormattedMessage id="components.new-downtime-modal.asset-already-has-active-timer" />
          }
        >
          <Field.Resource
            clearable={false}
            angleDown
            error={error}
            value={
              asset == null ? null : (
                <React.Fragment>
                  {asset.title}
                  {asset.tree_path.length === 0 ? null : <TreePath assetId={asset.id} />}
                </React.Fragment>
              )
            }
          />
        </FieldErrorWrapper>
      }
      selectedAssetId={value}
      onSelectAsset={assetId => onChange(assetId)}
    />
  </Field>
);

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(AssetField);
