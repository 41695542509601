import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import uuid from 'uuid';
import {
  Button,
  Field,
  Icon,
  FileUploadWrapper,
  FullScreenImagePicker,
  NewInlineModal,
} from 'views/components/Shared/General';
import { Image, Loader } from 'views/components/Image';
import { UploadProgressOperations, UploadProgressSelectors } from 'state/ducks/uploadProgress';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SelectMeterModal } from 'views/components/Meter';
import {
  NewAttachmentModal,
  AttachmentItem,
  AttachmentOptionsInlineModal,
  UploadAttachmentErrorModal,
} from 'views/components/Attachment';
import { Type, MeterType, generateEmptyChecklistTemplateRow } from 'sdk/ChecklistTemplateRow';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import DeleteRowConfirmModal from './DeleteRowConfirmModal';
import SelectedMeterContainer from './SelectedMeterContainer';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

const uploadFilesRequest = HelperFunctions.getCancelTokenForRequest();

class FieldEditor extends Component {
  state = {
    isSaving: false,
    title: this.props.editingField.title,
    uploadingCount: 0,
    deletingImageIds: {},
    showingImageDialog: false,
    currentImage: 0,
    showSelectMeterModal: false,
    showMeterIsRequiredError: false,
    showMoreOptionsDropdown: false,
    showNewAttachmentModal: false,
    showNewfileDropdown: false,
    showNewAttachmentModalForType: null,
    showUploadAttachmentErrorWarning: false,
  };

  componentDidUpdate(prevProps) {
    const prevEditingField = prevProps.editingField || {};
    const editingField = this.props.editingField || {};
    if (editingField.id && prevEditingField.id !== editingField.id) {
      this.setState({
        title: editingField.title,
      });
      this.titleRef.focus();
    }
  }

  addImage = data => {
    if (this.props.editingField.id === 'new') {
      this.addDraftImage(data);
    } else {
      this.createImageForChecklistTemplateRow(data);
    }
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  createImageForChecklistTemplateRow = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createImageForChecklistTemplateRow(this.props.editingField.id, { image: file, mime_type, name })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.checklist-instance-modal.add-image-success" />}
          />
        );
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      });
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.checklist-instance-modal.delete-image-success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
        //TODO: Replace with generic error message
      });
  };

  save = () => {
    const { editingField } = this.props;
    if (this.state.isSaving) {
      return;
    }
    if (
      editingField.type === Type.Meter &&
      editingField.meter_type === MeterType.Fixed &&
      editingField.meter_id == null
    ) {
      this.setState({ showMeterIsRequiredError: true });
      return;
    }
    if (this.props.editingField.id === 'new') {
      this.createChecklistTemplateRow();
    } else {
      this.updateChecklistTemplateRow();
    }
  };

  createChecklistTemplateRow = () => {
    const { title } = this.state;
    const { type, sort, parent_row_id, images, attachments, meter_id, meter_type } = this.props.editingField;
    this.setState({ isSaving: true });
    this.props
      .createChecklistTemplateRowForChecklistTemplateVersion(this.props.checklistTemplateVersion.id, {
        title,
        type,
        sort,
        parent_row_id,
        images,
        attachments,
        meter_id,
        meter_type,
      })
      .then(({ data: checklistTemplateRow }) => {
        this.setState({ isSaving: false });
        this.props.cancelEditField();
        this.prepareNextField({ type, index: sort, sectionId: parent_row_id, id: checklistTemplateRow.id });
      });
  };

  updateChecklistTemplateRow = () => {
    const { title } = this.state;
    const { meter_id, meter_type } = this.props.editingField;
    this.setState({ isSaving: true });
    this.props
      .updateChecklistTemplateRow(this.props.editingField.id, { title, meter_id, meter_type })
      .then(() => {
        this.setState({ isSaving: false });
        this.props.cancelEditField();
      });
  };

  deleteAttachment = attachment => {
    if (this.props.editingField.id === 'new') {
      this.deleteDraftAttachment(attachment);
    } else {
      this.removeAttachmentFromChecklistTemplaterow(attachment);
    }
  };

  deleteDraftAttachment = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props.deleteAttachment(attachment.id).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({ isDeletingAttachment: false });
    });
  };

  removeAttachmentFromChecklistTemplaterow = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props
      .removeAttachmentFromChecklistTemplaterow(this.props.editingField.id, attachment.id)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isDeletingAttachment: false });
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.title) {
      this.save();
    }
  };

  prepareNextField = ({ type, index, id, sectionId }) => {
    if (type === Type.Inspection) {
      this.props.prepareNewFieldForEdit({
        ...generateEmptyChecklistTemplateRow(),
        type,
        id: 'new',
        sort: index + 1,
        parent_row_id: sectionId,
      });
      if (sectionId) {
        this.props.addNewFieldToSection({
          toSectionId: sectionId,
          id: 'new',
          index: index,
        });
      } else {
        this.props.addNewFieldToRoot({
          id: 'new',
          toIndex: index,
        });
      }
    }
  };

  renderEditFieldTitle = () => {
    if (this.props.editingField.id === 'new') {
      return <FormattedMessage id="components.edit-checklist-modal.editor.new-field-title" />;
    }
    return <FormattedMessage id="components.edit-checklist-modal.editor.edit-field-title" />;
  };

  renderAttachments = () => {
    if (this.props.uploadingAttachments.length === 0 && this.props.attachments.length === 0) return null;

    return (
      <div className={styles['attachments-list']}>
        <AttachmentItem.Container>
          {this.props.uploadingAttachments.map(attachment => (
            <AttachmentItem namespace={this.props.editingField.id} uploading attachment={attachment} />
          ))}
          {this.props.attachments.map(attachment => {
            return (
              <AttachmentItem
                key={attachment.id}
                attachment={attachment}
                buttons={
                  <AttachmentOptionsInlineModal
                    attachment={attachment}
                    isDeletingAttachment={this.state.isDeletingAttachment}
                    onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
                    trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                  />
                }
                onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
              />
            );
          })}
        </AttachmentItem.Container>
      </div>
    );
  };

  renderAttachmentsContainer = () => {
    return (
      <div className={styles['attachments-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="components.edit-checklist-modal.editor.attachments" />
        </div>
        {this.renderAttachments()}
        <div className={styles['button']} ref={ref => (this.inlineModalPositioningRef = ref)}>
          <NewInlineModal
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.showNewfileDropdown}
            onClose={() => this.setState({ showNewfileDropdown: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={e =>
                    this.setState({
                      showNewfileDropdown: false,
                      showNewAttachmentModal: true,
                      showNewAttachmentModalForType: 'file',
                    })
                  }
                >
                  <FormattedMessage id="components.edit-checklist-modal.editor.file-from-computer" />
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Item
                  onClick={e =>
                    this.setState({
                      showNewfileDropdown: false,
                      showNewAttachmentModal: true,
                      showNewAttachmentModalForType: 'link',
                    })
                  }
                >
                  <FormattedMessage id="components.edit-checklist-modal.editor.hyperlink" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
          <Button
            gray
            fullWidth
            label="general.add"
            onClick={() => {
              this.setState(prevState => ({
                showNewfileDropdown: !prevState.showNewfileDropdown,
              }));
            }}
          />
        </div>
      </div>
    );
  };

  renderImages = () => {
    if (this.props.images.length === 0 && this.state.uploadingCount === 0) return null;
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader />
    );
    return (
      <div className={styles['images']}>
        {this.props.images.map((image, index) => this.renderImage(image, index))}
        {loaderElements}
      </div>
    );
  };

  renderImage = (image, index) => (
    <Image
      key={image.id}
      small
      image={image}
      isDeleting={this.state.deletingImageIds[image.id] != null}
      onClick={() => {
        this.selectImage(index);
      }}
      onDelete={() => {
        this.deleteImage(image);
      }}
    />
  );

  renderImagesContainer = () => {
    return (
      <div className={styles['images-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="components.edit-checklist-modal.editor.images" />
        </div>
        {this.renderImages()}
        <div className={styles['button']}>
          <FileUploadWrapper
            image
            accept="image/png, image/jpg, image/jpeg, image/gif"
            onSelectFile={this.addImage}
          >
            <Button gray fullWidth label="general.add" />
          </FileUploadWrapper>
        </div>
      </div>
    );
  };

  renderSelectedMeter = () => {
    if (this.props.editingField.meter_id) {
      return (
        <SelectedMeterContainer
          meterId={this.props.editingField.meter_id}
          onChangeMeter={() => this.setState({ showSelectMeterModal: true })}
        />
      );
    }
    return (
      <>
        <Button
          gray
          fullWidth
          label="general.add"
          onClick={() => this.setState({ showSelectMeterModal: true })}
        />
        {this.renderMeterIsRequiredErrorMessage()}
      </>
    );
  };

  renderAddMeterContainer = () => {
    const { checklistTemplate, editingField, isCreatingChecklistFromTemplatesLibrary } = this.props;
    if (editingField && editingField.type === Type.Meter) {
      if (isCreatingChecklistFromTemplatesLibrary || checklistTemplate.reusable) {
        return (
          <>
            <div className={styles['meter-container']}>
              <div className={styles['title']}>
                <FormattedMessage id="resources.meter.resource" />
              </div>
              <div>
                <Field.Checkbox.Group>
                  <Field.Radio
                    questionTooltipContent={
                      <FormattedMessage id="components.edit-checklist-modal.editor.meter-option-tooltip" />
                    }
                    label={<FormattedMessage id="components.edit-checklist-modal.editor.meter-option" />}
                    checked={editingField.meter_type === MeterType.Option}
                    onChange={() => this.props.updateEditingField({ meter_type: MeterType.Option })}
                  />
                  <Field.Radio
                    label={<FormattedMessage id="components.edit-checklist-modal.editor.meter-fixed" />}
                    checked={editingField.meter_type === MeterType.Fixed}
                    onChange={() => this.props.updateEditingField({ meter_type: MeterType.Fixed })}
                  />
                </Field.Checkbox.Group>
                <AnimateHeight
                  duration={250}
                  contentClassName={styles['fixed-meter-button-wrapper']}
                  height={editingField.meter_type === MeterType.Fixed ? 'auto' : 0}
                >
                  <div className={styles['fixed-meter-button-container']}>{this.renderSelectedMeter()}</div>
                </AnimateHeight>
              </div>
            </div>
            <div className={styles['separator']} />
          </>
        );
      }
      return (
        <>
          <div className={styles['meter-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="resources.meter.resource" />
            </div>
            {this.renderSelectedMeter()}
          </div>
          <div className={styles['separator']} />
        </>
      );
    }
    return null;
  };

  renderMeterIsRequiredErrorMessage = () => {
    if (this.state.showMeterIsRequiredError) {
      return (
        <div className={styles['error-message']}>
          <FormattedMessage id="components.edit-checklist-modal.editor.errors.meter-required" />
        </div>
      );
    }
    return null;
  };

  renderExtraOptions = () => {
    const { editingField } = this.props;
    if (editingField && editingField.type !== Type.Section) {
      return (
        <>
          <div className={styles['separator']} />
          {this.renderAddMeterContainer()}
          {this.renderAttachmentsContainer()}
          {this.renderImagesContainer()}
        </>
      );
    }
  };

  renderDropdownButton = () => {
    const { editingField } = this.props;
    if (editingField && editingField.id === 'new') {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.moreOptionsInlineModalRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={200}
          position="right"
          positionToRef={this.moreOptionsInlineModalRef}
          open={this.state.showMoreOptionsDropdown}
          onClose={() => this.setState({ showMoreOptionsDropdown: false })}
        >
          <>
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  destructive
                  onClick={() => {
                    this.setState({
                      showDeleteConfirmModal: true,
                      showMoreOptionsDropdown: false,
                      showDeleteConfirmModalForRowId: this.props.editingField.id,
                    });
                  }}
                >
                  <FormattedMessage id="general.delete" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </>
        </NewInlineModal>
      </>
    );
  };

  addAttachment = data => {
    if (this.props.editingField.id === 'new') {
      this.createDraftAttachment(data);
    } else {
      this.createAttachmentForChecklistTemplateRow(data);
    }
  };

  createDraftAttachment = data => {
    const { description, title, extension, file, type, mime_type, link_url } = data;
    const temporaryId = uuid.v4();
    this.props.beginUpload({ temporaryId, namespace: this.props.editingField.id });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: this.props.editingField.id,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  checklistTemplateRowId: this.props.editingField.id,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.editingField.id,
                  });
                });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: this.props.editingField.id });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: this.props.editingField.id });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(
          this.props.currentSystem.id,
          {
            description,
            title,
            extension,
            file,
            type,
            mime_type,
            link_url,
          },
          { completion: true }
        )
        .then(({ data: attachment }) => {
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: this.props.editingField.id,
          });
        });
    }
  };

  createAttachmentForChecklistTemplateRow = params => {
    const { description, title, extension, file, type, mime_type, link_url } = params;
    const temporaryId = uuid.v4();
    if (type === 'file') {
      const attrs = {
        description,
        title,
        mime_type,
        type,
        extension,
      };

      this.props.beginUpload({ temporaryId, namespace: this.props.editingField.id });
      this.props
        .createAttachmentForChecklistTemplateRow(this.props.editingField.id, attrs)
        .then(({ data: attachment }) => {
          const s3params = { url: attachment.attachment_version.upload_url, file, mime_type };
          return HelperFunctions.uploadFileToS3(s3params, ({ loaded, total }) => {
            this.props.updateUpload({
              id: attachment.id,
              loaded,
              total,
              temporaryId,
              namespace: this.props.editingField.id,
            });
          })
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  checklistTemplateRowId: this.props.editingField.id,
                })
                .then(() => {
                  toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.editingField.id,
                  });
                });
            })
            .catch(e => {
              this.props.cancelUpload({ temporaryId, namespace: this.props.editingField.id });
              this.setState({ showUploadAttachmentErrorWarning: true });
            });
        });
    } else {
      const attrs = {
        description,
        title,
        type,
        link_url,
      };

      this.props.beginUpload({ temporaryId, namespace: this.props.editingField.id });
      this.props
        .createAttachmentForChecklistTemplateRow(this.props.editingField.id, attrs)
        .then(({ data: attachment }) => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: this.props.editingField.id,
          });
        });
    }
  };

  renderNewAttachmentModal = () => {
    return (
      <NewAttachmentModal
        isOpen={this.state.showNewAttachmentModal}
        type={this.state.showNewAttachmentModalForType}
        onClose={() => this.setState({ showNewAttachmentModal: false })}
        onCreateNew={params => this.addAttachment(params)}
      />
    );
  };

  renderAddMeterModal = () => {
    return (
      <SelectMeterModal
        assetId={this.props.assetId}
        open={this.state.showSelectMeterModal}
        onClose={() => {
          this.setState({ showSelectMeterModal: false });
        }}
        onSelect={meter_id => {
          this.setState({
            showMeterIsRequiredError: false,
            showSelectMeterModal: false,
          });
          this.props.updateEditingField({ meter_id });
        }}
      />
    );
  };

  renderSaveButtonContainer = () => {
    return (
      <div className={styles['save-button-container']}>
        <Button
          fullWidth
          primary
          loading={this.state.isSaving}
          disabled={!this.state.title}
          label="general.save"
          onClick={() => this.save()}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={parentStyles['right-bar-wrapper']}>
          <div className={parentStyles['right-bar-container']}>
            <PerfectScrollbar>
              <div className={parentStyles['right-bar']}>
                <div className={styles['edit-field']}>
                  <div className={styles['title-container']}>
                    <div className={styles['title']}>
                      <div className={styles['title-text']}>{this.renderEditFieldTitle()}</div>
                      <div className={styles['buttons']}>
                        {this.renderDropdownButton()}
                        <Button
                          type="icon"
                          icon={<Icon light size={16} type="times" />}
                          onClick={this.props.cancelEditField}
                        />
                      </div>
                    </div>
                    <div className={styles['type']}>
                      <FormattedMessage
                        id={`resources.checklist-template-row.types.${this.props.editingField.type}`}
                      />
                    </div>
                  </div>
                  <div className={styles['separator']} />
                  <div className={styles['field-options']}>
                    <Field label={<FormattedMessage id="resources.checklist-template-row.title" />}>
                      <Field.Text
                        autoFocus
                        ref={ref => (this.titleRef = ref)}
                        value={this.state.title}
                        onKeyPress={this.handleKeyPress}
                        onChange={title => {
                          this.setState({ title });
                        }}
                      />
                    </Field>
                  </div>
                  {this.renderExtraOptions()}
                </div>
              </div>
            </PerfectScrollbar>
          </div>
          {this.renderSaveButtonContainer()}
        </div>
        {this.renderAddMeterModal()}
        {this.renderNewAttachmentModal()}
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
        <DeleteRowConfirmModal
          open={this.state.showDeleteConfirmModal}
          id={this.state.showDeleteConfirmModalForRowId}
          onClose={() => {
            this.setState({ showDeleteConfirmModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEditingField: ChecklistEditModalOperations.updateEditingField,
      deleteImage: SDKReduxOperations.deleteImage,
      createDraftImage: SDKReduxOperations.createDraftImage,
      createImageForChecklistTemplateRow: SDKReduxOperations.createImageForChecklistTemplateRow,
      addNewFieldToRoot: ChecklistEditModalOperations.addNewFieldToRoot,
      addNewFieldToSection: ChecklistEditModalOperations.addNewFieldToSection,
      prepareNewFieldForEdit: ChecklistEditModalOperations.prepareNewFieldForEdit,
      cancelEditField: ChecklistEditModalOperations.cancelEditField,
      updateChecklistTemplateRow: SDKReduxOperations.updateChecklistTemplateRow,
      createChecklistTemplateRowForChecklistTemplateVersion:
        SDKReduxOperations.createChecklistTemplateRowForChecklistTemplateVersion,
      deleteChecklistTemplateRow: SDKReduxOperations.deleteChecklistTemplateRow,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
      createAttachmentForChecklistTemplateRow: SDKReduxOperations.createAttachmentForChecklistTemplateRow,
      removeAttachmentFromChecklistTemplaterow: SDKReduxOperations.removeAttachmentFromChecklistTemplaterow,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createDraftAttachment: SDKReduxOperations.createDraftAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const editingField = ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state);
  const checklistTemplateVersion = ChecklistEditModalSelectors.getChecklistTemplateVersion(state);
  return {
    isCreatingChecklistFromTemplatesLibrary:
      ChecklistEditModalSelectors.isCreatingChecklistFromTemplatesLibrary(state),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, editingField.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    editingField,
    images: EntitySelectors.getImages(state, editingField.images),
    attachments: EntitySelectors.getAttachments(state, editingField.attachments),
    checklistTemplateVersion,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateVersion.checklist_template_id
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldEditor);
