import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { getVendorSubtitle } from 'sdk/Vendor';
import { Loader } from 'views/components/Shared/General';

class VendorListItem extends Component {
  renderLoader = () => {
    if (this.props.loading) {
      return (
        <List.Item.Column width={20}>
          <Loader tiny />
        </List.Item.Column>
      );
    }
    return null;
  };

  render() {
    return (
      <List.Item clickable onClick={() => this.props.onCreatePurchaseOrder(this.props.vendor)}>
        <List.Item.TitleColumn
          title={this.props.vendor.name}
          subtitle={getVendorSubtitle(this.props.vendor)}
        />
        {this.renderLoader()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorListItem);
