import React from 'react';
import { connect } from 'react-redux';
import { ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import SelectField from './SelectField';
import EditField from './EditField';

const FieldEditor = props => {
  if (props.loading) {
    return <SelectField loading />;
  }
  if (props.editingField) {
    return <EditField assetId={props.assetId} />;
  }
  return <SelectField hideSaveAsReusable={props.hideSaveAsReusable} />;
};

function mapStateToProps(state) {
  return {
    editingField: ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state),
  };
}

export default connect(mapStateToProps)(FieldEditor);
