import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List } from 'views/components/Shared/General';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

class UserListItem extends PureComponent {
  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return (
      <List.Item small clickable onClick={() => this.props.onSelect()}>
        <List.Item.Column width={24}>
          <ProfilePicture userId={this.props.id} size={24} />
        </List.Item.Column>
        <List.Item.TitleColumn
          title={<UserNameWrapper user={this.props.user} />}
          subtitle={this.props.user.email}
        />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(UserListItem);
