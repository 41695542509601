import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import UserContainer from './UserContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class CompletedBy extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={id => <UserContainer id={id} />}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter;

    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.work-order.completed-by" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.CompletedBy,
            data: {
              [FILTER_TYPES.CompletedBy]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                value: [],
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.CompletedBy),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedBy);
