import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { injectIntl, FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WhiteCard, FullScreenImagePicker, Icon } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { AddButton, Image, Loader } from 'views/components/Image';
import styles from './styles.module.scss';

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageViewerIsShowing: false,
      imageViewerCurrentIndex: 0,
      uploadingCount: 0,
      deletingIds: {},
    };
  }

  addImage = ({ file, mime_type, name }) => {
    const params = {
      image: file,
      mime_type,
      name,
    };

    this.setState({ uploadingCount: this.state.uploadingCount + 1 });

    this.props
      .createImageForAsset(this.props.match.params.id, params)
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.asset.info.images.create-success" />} />
        );
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      });
  };

  deleteImage = image => {
    this.setState({ deletingIds: { ...this.state.deletingIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.asset.info.images.delete-success" />} />
        );
        this.setState({ deletingIds: { ...this.state.deletingIds, [image.id]: false } });
      })
      .catch(() => {
        this.setState({ deletingIds: { ...this.state.deletingIds, [image.id]: false } });
      });
  };

  openViewerWithImageIndex = index => {
    this.setState({
      imageViewerIsShowing: true,
      imageViewerCurrentIndex: index,
    });
  };

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#f5f5f5"
        secondaryColor="#EBEBEB"
        height={112}
        width={112}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="0" rx="56" ry="56" width="112" height="112" />
      </ContentLoader>
    </div>
  );

  renderImageViewer = () => (
    <FullScreenImagePicker
      currentImage={this.state.imageViewerCurrentIndex}
      images={this.props.images}
      onChangeImage={index => {
        this.setState({
          imageViewerCurrentIndex: index,
        });
      }}
      open={this.state.imageViewerIsShowing}
      onClose={() => this.setState({ imageViewerIsShowing: false })}
    />
  );

  renderTopContent = () => {
    if (this.props.images.length === 0 && this.state.uploadingCount > 0) {
      return <Loader large circle />;
    } else if (this.props.images.length === 0) {
      if (this.props.canEditAssets) {
        return <AddButton large onAddImage={this.addImage} />;
      } else {
        return (
          <div className={styles['image-container']}>
            <Icon type="image" />
          </div>
        );
      }
    } else {
      const image = this.props.images[0];

      return (
        <Image
          large
          circle
          editable={this.props.canEditAssets}
          confirmDeleteComponent={<FormattedMessage id="screens.asset.info.images.delete-message" />}
          isDeleting={this.state.deletingIds[image.id] != null}
          image={image}
          onClick={() => {
            this.openViewerWithImageIndex(0);
          }}
          onDelete={() => {
            this.deleteImage(image);
          }}
        />
      );
    }
  };

  renderBottomContainer = () => {
    if (this.props.images.length === 0 && this.state.uploadingCount === 0) return null;

    const imageElements = this.props.images.slice(1).map((image, index) => (
      <Image
        key={image.id}
        image={image}
        circle
        editable={this.props.canEditAssets}
        confirmDeleteComponent={<FormattedMessage id="screens.asset.info.images.delete-message" />}
        isDeleting={this.state.deletingIds[image.id] != null}
        onClick={() => {
          this.openViewerWithImageIndex(index + 1);
        }}
        onDelete={() => {
          this.deleteImage(image);
        }}
        allowSetAsMain
        onSetAsMain={() => {
          this.props.updateImage(image.id, { sort: 1 });
        }}
      />
    ));

    const smallLoaderCount =
      this.props.images.length === 0 ? this.state.uploadingCount - 1 : this.state.uploadingCount;

    const loaderElements = [...Array(smallLoaderCount)].map((_, index) => <Loader circle />);
    const addButton = <AddButton onAddImage={this.addImage} />;
    let elements = [...imageElements, ...loaderElements];
    if (this.props.canEditAssets) elements = [...elements, addButton];
    while ((elements.length - 1) % 3 !== 0) {
      elements = [...elements, <div className={styles['empty-column']} />];
    }

    const numberOfRows = Math.ceil(elements.length / 3);

    return (
      <div className={styles['bottom-container']}>
        {[...Array(numberOfRows)].map((_, rowIndex) => {
          return <div className={styles['row']}>{elements.slice(rowIndex * 3, rowIndex * 3 + 3)}</div>;
        })}
      </div>
    );
  };

  renderContent = () => {
    if (!this.props.images) return this.renderContentLoader();

    return (
      <>
        <div className={styles['top-container']}>{this.renderTopContent()}</div>
        {this.renderBottomContainer()}
        {this.renderImageViewer()}
      </>
    );
  };

  render() {
    return (
      <CardGrid.Row>
        <WhiteCard>{this.renderContent()}</WhiteCard>
      </CardGrid.Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
      createImageForAsset: SDKReduxOperations.createImageForAsset,
      updateImage: SDKReduxOperations.updateImage,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  const asset = EntitySelectors.getAsset(state, assetId);

  return {
    images: EntitySelectors.getImages(state, asset.images),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Images)));
