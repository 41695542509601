import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditDeliveryMethodModal from './EditDeliveryMethodModal';
import styles from './style.module.scss';

class DeliveryMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditDeliveryMethodModal: false,
      showEditDeliveryMethodModalForSpentTimeCategoryId: null,
      createdIds: [],
    };
  }

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() =>
            this.setState({
              showEditDeliveryMethodModal: true,
              showEditDeliveryMethodModalForSpentTimeCategoryId: null,
            })
          }
          label="screens.settings.purchase-orders.delivery-methods.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.delivery-method.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListItem = spentTimeCategory => (
    <List.Item
      clickable
      onClick={() =>
        this.setState({
          showEditDeliveryMethodModal: true,
          showEditDeliveryMethodModalForSpentTimeCategoryId: spentTimeCategory.id,
        })
      }
    >
      <List.Item.TitleColumn title={spentTimeCategory.title} />
      {spentTimeCategory.default ? (
        <List.Item.Column alignRight>
          <span className={styles['default']}>
            <FormattedMessage id="resources.delivery-method.default" />
          </span>
        </List.Item.Column>
      ) : null}
    </List.Item>
  );

  renderDeliveryMethods = () => {
    const newDeliveryMethods = this.state.createdIds
      .filter(createdId => this.props.deliveryMethods.find(({ id }) => id === createdId))
      .map(createdId => this.props.deliveryMethods.find(({ id }) => id === createdId));

    const oldDeliveryMethods = this.props.deliveryMethods.filter(
      ({ id }) => !this.state.createdIds.includes(id)
    );
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showEditDeliveryMethodModal: true,
                  showEditDeliveryMethodModalForSpentTimeCategoryId: null,
                })
              }
              label="screens.settings.purchase-orders.delivery-methods.add-button"
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {newDeliveryMethods.map(spentTimeCategory => this.renderListItem(spentTimeCategory))}
          {oldDeliveryMethods.map(spentTimeCategory => this.renderListItem(spentTimeCategory))}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.props.deliveryMethods.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderDeliveryMethods();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/purchase-orders" />
        <Section
          title={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.delivery-methods.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditDeliveryMethodModal
          open={this.state.showEditDeliveryMethodModal}
          id={this.state.showEditDeliveryMethodModalForSpentTimeCategoryId}
          onClose={() => this.setState({ showEditDeliveryMethodModal: false })}
          onCreate={id => {
            this.setState(prevState => ({
              showEditDeliveryMethodModal: false,
              createdIds: [id, ...prevState.createdIds],
            }));
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    deliveryMethods: AuthSelectors.getPurchaseOrderDeliveryMethods(state),
  };
}

export default injectIntl(connect(mapStateToProps)(DeliveryMethods));
