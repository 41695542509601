import { API } from 'sdk';
import actions from './actions';
import { normalizeNotification } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';

const listNotifications =
  (systemId, params = {}) =>
  dispatch => {
    const PAGE_SIZE = 20;

    return API.listNotifications(systemId, { ...params, page_size: PAGE_SIZE }).then(res => {
      const { data, headers } = res;

      const { entities, result } = normalizeNotification(data);
      dispatch(EntityOperations.updateEntities(entities));

      const newIds = data.filter(n => n.new).map(n => n.id);
      const earlierIds = data.filter(n => !n.new).map(n => n.id);

      dispatch(
        actions.fetchNotificationsSuccess({
          initialLoad: params.paginate_from == null,
          newIds: newIds,
          earlierIds: earlierIds,
          paginateFrom: headers['paginate-from-token'],
          totalEntries: headers['total-entries'],
          isFullyLoaded: data.length < PAGE_SIZE,
        })
      );

      return res.data;
    });
  };

export default {
  listNotifications,
};
