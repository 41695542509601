import React, { Component } from 'react';
import * as moment from 'moment';
import Decimal from 'decimal.js';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { buildRRule } from 'sdk/RecurringMaintenance/rruleBuilder';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, FormattedNumber } from 'views/components/Shared/General';
import styles from './style.module.scss';

class DeleteWorkOrder extends Component {
  state = {
    isDeleting: false,
  };

  deleteWorkOrder = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteWorkOrder(this.props.workOrder.id)
      .then(() => {
        this.props.onDeleteWorkOrder();
      })
      .catch(() => {
        this.setState({ isDeleting: false });
      });
  };

  buildFromCompletedDateMessage = () => {
    const { interval, frequency } = this.props.recurringMaintenance;
    let messageId;
    switch (frequency) {
      case 'daily':
        messageId = interval > 1 ? 'days' : 'day';
        break;
      case 'weekly':
        messageId = interval > 1 ? 'weeks' : 'week';
        break;
      case 'monthly':
        messageId = interval > 1 ? 'months' : 'month';
        break;
      case 'yearly':
        messageId = interval > 1 ? 'years' : 'year';
        break;
      default:
        break;
    }
    return (
      <div className={styles['text']}>
        <FormattedMessage
          id={`screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.from-completed-date.${messageId}`}
          values={{ interval }}
        />
      </div>
    );
  };

  buildNextGenerationDate = () => {
    const date = buildRRule(this.props.recurringMaintenance).after(
      moment(this.props.recurringMaintenance.next_work_order_date).toDate()
    );
    return (
      <div className={styles['text']}>
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.info-text-next-date"
          values={{ date: moment(date).format('LL') }}
        />
      </div>
    );
  };

  buildNextMeterValue = () => {
    const { meterUnit } = this.props;
    const dueMeterValue = new Decimal(this.props.workOrder.due_meter_value);
    const meterInterval = new Decimal(this.props.recurringMaintenance.meter_interval);
    const meterValueForNextWorkOrder = dueMeterValue.plus(meterInterval).toDecimalPlaces(2).toString();
    return (
      <div className={styles['text']}>
        <FormattedMessage
          id="screens.work-order.recurring-maintenance-options-inline-modal.delete-work-order.info-text-next-meter-value"
          values={{
            value: (
              <FormattedNumber
                value={meterValueForNextWorkOrder}
                unit={meterUnit ? meterUnit.abbreviation : null}
              />
            ),
          }}
        />
      </div>
    );
  };

  renderInfo = () => {
    if (this.props.workOrder.generated_for_date !== this.props.recurringMaintenance.next_work_order_date)
      return null;
    if (this.props.recurringMaintenance.generate_from_completed_date) {
      return this.buildFromCompletedDateMessage();
    }
    if (this.props.recurringMaintenance.meter_id) {
      return this.buildNextMeterValue();
    }
    return this.buildNextGenerationDate();
  };

  render() {
    return (
      <div className={styles['delete-work-order']}>
        <div className={styles['info']}>
          {this.renderInfo()}
          <Button
            primary
            destructive
            label="general.delete"
            loading={this.state.isDeleting}
            onClick={this.deleteWorkOrder}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteWorkOrder: SDKReduxOperations.deleteWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  let meter = EntitySelectors.getMeter(state, workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    workOrder,
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteWorkOrder));
