import types from './types';

const setResourceItemsSelectedTab = payload => ({
  type: types.SET_RESOURCE_ITEMS_SELECTED_TAB,
  payload,
});

const incrementImageLoaderCount = () => ({
  type: types.INCREMENT_IMAGE_LOADER_COUNT,
});

const decrementImageLoaderCount = () => ({
  type: types.DECREMENT_IMAGE_LOADER_COUNT,
});

const resetImageLoaderCount = () => ({
  type: types.RESET_IMAGE_LOADER_COUNT,
});

const showAddReusableChecklistLoader = () => ({
  type: types.SHOW_ADD_REUSABLE_CHECKLIST_LOADER,
});

const hideAddReusableChecklistLoader = () => ({
  type: types.HIDE_ADD_REUSABLE_CHECKLIST_LOADER,
});

export default {
  showAddReusableChecklistLoader,
  hideAddReusableChecklistLoader,
  setResourceItemsSelectedTab,
  incrementImageLoaderCount,
  decrementImageLoaderCount,
  resetImageLoaderCount,
};
