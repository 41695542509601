import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import {
  WhiteCard,
  Button,
  Field,
  FieldErrorWrapper,
  MoneyWithCurrency,
  Tooltip,
  Icon,
} from 'views/components/Shared/General';
import { ChangeCurrencyModal } from 'views/components/General';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import ContentLoader from 'react-content-loader';
import styles from './styles.module.scss';

class OrderInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingSparePart: {},
      editingSparePartBeforeEdit: {},
      showMinLargerThanMaxError: false,
      showMaxSmallerThanMinError: false,
      showChangeCurrencyModal: false,
      hideUpgradeCard: localStorage.getItem('settings_spare_part_hide_upgrade_card'),
    };
  }

  edit = () => {
    const {
      purchase_price,
      purchase_price_currency,
      purchase_price_exchange_rate,
      reorder_point,
      minimum_quantity,
      maximum_quantity,
    } = this.props.sparePart;
    const editingSparePart = {
      purchase_price,
      purchase_price_currency,
      purchase_price_exchange_rate,
      reorder_point,
      minimum_quantity,
      maximum_quantity,
    };

    this.setState({
      editing: true,
      editingSparePart,
      editingSparePartBeforeEdit: editingSparePart,
      showMinLargerThanMaxError: false,
      showMaxSmallerThanMinError: false,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    if (this.state.showMinLargerThanMaxError || this.state.showMaxSmallerThanMinError) return;
    this.setState({ isSaving: true });

    this.props
      .updateSparePart(this.props.sparePart.id, this.getModifiedSparePartData())
      .then(() => {
        this.setState({ isSaving: false, editing: false });
        toast(<ToastMessage success text={<FormattedMessage id="screens.asset.info.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedSparePartData = () => {
    const sparePart = this.state.editingSparePart;
    const sparePartBeforeEdit = this.state.editingSparePartBeforeEdit;

    return Object.keys(sparePart)
      .filter(key => sparePart[key] !== sparePartBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: sparePart[key],
        }),
        {}
      );
  };

  setSparePartValue = obj => {
    const newEditingSparePart = {
      ...this.state.editingSparePart,
      ...obj,
    };

    this.setState({
      editingSparePart: newEditingSparePart,
    });
  };

  isLoaded = () => this.props.sparePart != null;

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="12" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="28" rx="2" ry="2" width="70" height="6" />
      </ContentLoader>
    </div>
  );

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditSpareParts) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button primary small label="general.save" loading={this.state.isSaving} onClick={this.save} />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderEditModePurchasePrice = () => {
    const { purchase_price, purchase_price_currency } = this.state.editingSparePart;
    return (
      <Field.Money
        currency={purchase_price_currency}
        value={purchase_price}
        onChange={purchase_price => {
          this.setSparePartValue({ purchase_price });
        }}
        onBlur={purchase_price => {
          this.setSparePartValue({ purchase_price });
        }}
      />
    );
  };

  renderViewModePurchasePrice = () => {
    const { purchase_price, purchase_price_currency, purchase_price_exchange_rate } = this.props.sparePart;
    const { currency: systemCurrency } = this.props.currentSystem;
    if (!purchase_price) {
      return <span>-</span>;
    }
    if (purchase_price_currency !== systemCurrency) {
      const localValue = new Decimal(purchase_price)
        .times(new Decimal(purchase_price_exchange_rate))
        .toDecimalPlaces(2)
        .toString();
      return (
        <>
          <span>
            <MoneyWithCurrency value={purchase_price} currency={purchase_price_currency} />
          </span>
          <span>&nbsp;</span>
          <span className={styles['system-currency']}>
            (
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />)
          </span>
        </>
      );
    }
    return <MoneyWithCurrency value={purchase_price} currency={purchase_price_currency} />;
  };

  renderConvertionRateAndLocalCurrency = () => {
    const { purchase_price, purchase_price_currency, purchase_price_exchange_rate } =
      this.state.editingSparePart;
    const { currency: systemCurrency } = this.props.currentSystem;
    if (purchase_price_currency !== systemCurrency) {
      let localValue;
      try {
        localValue = new Decimal(purchase_price)
          .times(new Decimal(purchase_price_exchange_rate))
          .toDecimalPlaces(2)
          .toString();
      } catch (e) {
        localValue = 0;
      }

      return (
        <div className={styles['convertion-rate-description-container']}>
          <div className={styles['convertion-rate']}>
            <Field
              fontSize={12}
              view
              singleRow
              label={<FormattedMessage id="resources.spare-part.currency-conversion-rate" />}
            >
              {new Decimal(purchase_price_exchange_rate).toDecimalPlaces(4).toString()}
            </Field>
            <div
              className={styles['icon-button']}
              onClick={() => this.setState({ showChangeCurrencyModal: true })}
            >
              <Icon regular size={11} type="pen" />
            </div>
          </div>
          <div className={styles['system-currency']}>
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />
          </div>
        </div>
      );
    }
    return null;
  };

  renderPurchasePriceField = () => {
    if (this.state.editing) {
      return (
        <div className={styles['purchase-price-field']}>
          <Field
            view={false}
            label={
              <div className={styles['purchase-label-container']}>
                <div className={styles['label']}>
                  <FormattedMessage id="resources.spare-part.latest-purchase-price" />
                  <Tooltip
                    position="top center"
                    trigger={
                      <div className={styles['more-info-icon-container']}>
                        <Icon regular type="question-circle" />
                      </div>
                    }
                    label={
                      <FormattedMessage id="screens.spare-part.info.order-information.purchase-price-tooltip" />
                    }
                  />
                </div>
                <div>
                  <Button
                    type="text"
                    primary
                    fontSize={12}
                    label="screens.spare-part.info.order-information.change-currency"
                    noUnderline
                    onClick={() => {
                      this.setState({ showChangeCurrencyModal: true });
                    }}
                  />
                </div>
              </div>
            }
            description={this.renderConvertionRateAndLocalCurrency()}
          >
            {this.renderEditModePurchasePrice()}
          </Field>
        </div>
      );
    }
    return (
      <div className={styles['purchase-price-field']}>
        <Field
          view
          questionTooltipContent={
            <FormattedMessage id="screens.spare-part.info.order-information.purchase-price-tooltip" />
          }
          label={<FormattedMessage id="resources.spare-part.latest-purchase-price" />}
        >
          {this.renderViewModePurchasePrice()}
        </Field>
      </div>
    );
  };

  renderOrderQuantityField = () => {
    if (this.state.editing) {
      const { reorder_point } = this.state.editingSparePart;
      return (
        <Field
          view={false}
          label={<FormattedMessage id="resources.spare-part.reorder-point" />}
          questionTooltipContent={
            <FormattedMessage id="screens.spare-part.info.order-information.reorder-point-tooltip" />
          }
        >
          <Field.Decimal
            value={this.props.sparePart.stock_quantity ? reorder_point : null}
            rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
            onChange={reorder_point => {
              this.setSparePartValue({ reorder_point });
            }}
            onBlur={reorder_point => {
              this.setSparePartValue({ reorder_point });
            }}
          />
        </Field>
      );
    }
    const { reorder_point } = this.props.sparePart;
    return (
      <Field
        view
        label={<FormattedMessage id="resources.spare-part.reorder-point" />}
        questionTooltipContent={
          <FormattedMessage id="screens.spare-part.info.order-information.reorder-point-tooltip" />
        }
      >
        {this.props.sparePart.stock_quantity ? (
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={reorder_point} />
        ) : (
          '-'
        )}
      </Field>
    );
  };

  renderMinQuantityField = () => {
    if (this.state.editing) {
      const { minimum_quantity } = this.state.editingSparePart;
      return (
        <Field
          view={false}
          label={<FormattedMessage id="resources.spare-part.min-quantity" />}
          questionTooltipContent={
            <FormattedMessage id="screens.spare-part.info.order-information.min-quantity-tooltip" />
          }
        >
          <FieldErrorWrapper
            position="top"
            show={this.state.showMinLargerThanMaxError}
            errorElement={
              <FormattedMessage id="screens.spare-part.info.order-information.errors.min-quantity-larger-than-max-quantity" />
            }
          >
            <Field.Decimal
              value={this.props.sparePart.stock_quantity ? minimum_quantity : null}
              error={this.state.showMinLargerThanMaxError}
              rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
              onBlur={minimum_quantity => {
                this.setSparePartValue({ minimum_quantity });
                if (
                  minimum_quantity &&
                  this.state.editingSparePart.maximum_quantity &&
                  new Decimal(minimum_quantity).greaterThan(
                    new Decimal(this.state.editingSparePart.maximum_quantity)
                  )
                ) {
                  this.setState({ showMinLargerThanMaxError: true });
                } else {
                  this.setState({ showMinLargerThanMaxError: false, showMaxSmallerThanMinError: false });
                }
              }}
            />
          </FieldErrorWrapper>
        </Field>
      );
    }
    const { minimum_quantity } = this.props.sparePart;
    return (
      <Field
        view
        label={<FormattedMessage id="resources.spare-part.min-quantity" />}
        questionTooltipContent={
          <FormattedMessage id="screens.spare-part.info.order-information.min-quantity-tooltip" />
        }
      >
        {this.props.sparePart.stock_quantity ? (
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={minimum_quantity} />
        ) : (
          '-'
        )}
      </Field>
    );
  };

  renderMaxQuantityField = () => {
    if (this.state.editing) {
      const { maximum_quantity } = this.state.editingSparePart;
      return (
        <Field
          view={false}
          label={<FormattedMessage id="resources.spare-part.max-quantity" />}
          questionTooltipContent={
            <FormattedMessage id="screens.spare-part.info.order-information.max-quantity-tooltip" />
          }
        >
          <FieldErrorWrapper
            position="top"
            show={this.state.showMaxSmallerThanMinError}
            errorElement={
              <FormattedMessage id="screens.spare-part.info.order-information.errors.max-quantity-larger-than-min-quantity" />
            }
          >
            <Field.Decimal
              value={this.props.sparePart.stock_quantity ? maximum_quantity : null}
              error={this.state.showMaxSmallerThanMinError}
              rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
              onBlur={maximum_quantity => {
                this.setSparePartValue({ maximum_quantity });
                if (
                  maximum_quantity &&
                  this.state.editingSparePart.minimum_quantity &&
                  new Decimal(maximum_quantity).lessThan(
                    new Decimal(this.state.editingSparePart.minimum_quantity)
                  )
                ) {
                  this.setState({ showMaxSmallerThanMinError: true });
                } else {
                  this.setState({ showMaxSmallerThanMinError: false, showMinLargerThanMaxError: false });
                }
              }}
            />
          </FieldErrorWrapper>
        </Field>
      );
    }
    const { maximum_quantity } = this.props.sparePart;
    return (
      <Field
        view
        label={<FormattedMessage id="resources.spare-part.max-quantity" />}
        questionTooltipContent={
          <FormattedMessage id="screens.spare-part.info.order-information.max-quantity-tooltip" />
        }
      >
        {this.props.sparePart.stock_quantity ? (
          <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={maximum_quantity} />
        ) : (
          '-'
        )}
      </Field>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return this.renderContentLoader();

    return (
      <div className={styles['wrapper']}>
        <Grid>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderPurchasePriceField()}</Grid.Column>
            <Grid.Column md={6}>{this.renderOrderQuantityField()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderMinQuantityField()}</Grid.Column>
            <Grid.Column md={6}>{this.renderMaxQuantityField()}</Grid.Column>
          </Grid.Row>
        </Grid>
        {this.props.sparePart.stock_quantity == null ? <div className={styles['overlay']} /> : null}
      </div>
    );
  };

  renderChangeCurrencyModal = () => {
    return (
      <ChangeCurrencyModal
        currency={this.state.editingSparePart.purchase_price_currency}
        exchangeRate={this.state.editingSparePart.purchase_price_exchange_rate}
        open={this.state.showChangeCurrencyModal}
        onSave={({ exchangeRate, currency }) => {
          this.setSparePartValue({
            purchase_price_currency: currency,
            purchase_price_exchange_rate: exchangeRate,
          });
          this.setState({ showChangeCurrencyModal: false });
        }}
        onClose={() => {
          this.setState({ showChangeCurrencyModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <WhiteCard
          title={<FormattedMessage id="screens.spare-part.info.order-information.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
        {this.renderChangeCurrencyModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const sparePartId = ownProps.match.params.id;
  const sparePart = EntitySelectors.getSparePart(state, sparePartId);
  return {
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderInformation)));
