import Draggable from 'views/components/General/Draggable';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import { List, Button, Icon } from 'views/components/Shared/General';
import { useDrop } from 'react-dnd';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import React, { useState } from 'react';
import DragHandle from './DragHandle';
import styles from './style.module.scss';

const DraggableListItem = ({
  loading,
  isDraggingSparePartTypeId,
  sparePartTypes,
  sparePartType,
  onDragStart,
  onDragEnd,
  onDrop,
  onEdit,
  expandableChildren,
  parentIds,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [{ isOverCurrent }, drop] = useDrop({
    type: 'spare-part-type',
    drop: item => {
      let isInsideParent = parentIds.find(id => id === isDraggingSparePartTypeId) != null;
      if (isOverCurrent && isInsideParent === false && item.id !== sparePartType.id) {
        onDrop(item.id, sparePartType);
      }
    },
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    accept: 'spare-part-type',
  });

  if (loading) {
    return (
      <List.Item small expandable>
        <DragHandle />
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }

  const renderCount = () => {
    const { spare_part_count: count } = sparePartType;
    if (count === 0) {
      return <FormattedMessage id="screens.settings.spare-parts.types.never-used" />;
    }
    return (
      <FormattedPlural
        value={count}
        one={<FormattedMessage id="screens.settings.spare-parts.types.used-amount.one" />}
        two={<FormattedMessage id="screens.settings.spare-parts.types.used-amount.two" values={{ count }} />}
        few={<FormattedMessage id="screens.settings.spare-parts.types.used-amount.few" values={{ count }} />}
        many={
          <FormattedMessage id="screens.settings.spare-parts.types.used-amount.many" values={{ count }} />
        }
        other={
          <FormattedMessage id="screens.settings.spare-parts.types.used-amount.other" values={{ count }} />
        }
      />
    );
  };

  const isExpandable = () => {
    return sparePartTypes.find(({ parent_id }) => parent_id === sparePartType.id) != null;
  };

  const isDraggingOverCurrentItemOrParent = () => {
    let isInsideParent = parentIds.find(id => id === isDraggingSparePartTypeId) != null;
    return isOverCurrent && isInsideParent === false && isDraggingSparePartTypeId !== sparePartType.id;
  };

  const classNames = () => {
    let classNames = [styles['item']];
    if (isExpanded) {
      classNames = [...classNames, styles['expanded']];
    } else {
      classNames = [...classNames, styles['not-expanded']];
    }
    if (isExpandable()) {
      classNames = [...classNames, styles['is-expandable']];
    } else {
      classNames = [...classNames, styles['not-expandable']];
    }
    let isInsideParent = parentIds.find(id => id === isDraggingSparePartTypeId) != null;
    if (isOverCurrent && isInsideParent === false && isDraggingSparePartTypeId !== sparePartType.id) {
      classNames = [...classNames, styles['hovered']];
    }
    return classNames;
  };

  const renderExpandableButton = () => {
    if (isExpandable()) {
      let classNames = [styles['expandable-button']];
      if (isExpanded) classNames = [...classNames, styles['expanded']];
      return (
        <div className={classNames.join(' ')}>
          <div className={styles['hover-container']}>
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-right" />}
              onClick={e => {
                setIsExpanded(!isExpanded);
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classNames().join(' ')}>
      <Draggable
        type="spare-part-type"
        onDragStart={() => onDragStart(sparePartType.id)}
        onDragEnd={onDragEnd}
        item={sparePartType}
      >
        <div className={styles['content']} ref={drop} >
          <div className={styles['columns']}>
            <div className={styles['drag-handle-container']}><DragHandle /></div>
            {renderExpandableButton()}
            <div className={styles['title']}>{sparePartType.title}</div>
            <div className={styles['subtitle']}>{renderCount()}</div>
            <div className={styles['edit-button-container']}>
              <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => onEdit(sparePartType.id)}
              />
            </div>
          </div>
          {isDraggingOverCurrentItemOrParent() ? <div className={styles['border']} /> : null}
        </div>
        <AnimateHeight
          duration={250}
          height={isExpanded && isExpandable() ? 'auto' : 0}
          contentClassName={styles['outer-expand']}
        >
          {expandableChildren}
        </AnimateHeight>
      </Draggable>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  return {
    draggingSparePartType: EntitySelectors.getSparePartType(state, ownProps.isDraggingSparePartTypeId),
  };
}

export default connect(mapStateToProps)(DraggableListItem);
