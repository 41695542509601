import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Icon } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class SelectedMeterContainer extends Component {
  render() {
    return (
      <div className={styles['related-resource']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>{this.props.meter.title}</div>
          <div className={styles['subtitle']}>
            <TreePath assetId={this.props.asset.id} fullPath />
          </div>
        </div>
        <div>
          <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.props.onChangeMeter()} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { meterId } = ownProps;
  const meter = EntitySelectors.getMeter(state, meterId);
  return {
    meter,
    asset: EntitySelectors.getAsset(state, meter.asset_id),
  };
}

export default connect(mapStateToProps)(SelectedMeterContainer);
