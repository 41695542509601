const DOMAIN_PREFIX = 'users/';
const RESET_SELECTED_USERS = `${DOMAIN_PREFIX}RESET_SELECTED_USERS`;
const SELECT_USER = `${DOMAIN_PREFIX}SELECT_USER`;
const SELECT_PAGE = `${DOMAIN_PREFIX}SELECT_PAGE`;
const SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}SELECT_TOTAL_ENTRIES`;
const HIDE_SELECT_TOTAL_ENTRIES = `${DOMAIN_PREFIX}HIDE_SELECT_TOTAL_ENTRIES`;

const FETCH_USERS_SUCCESS = `${DOMAIN_PREFIX}fetch_users_success`;
const SET_AMOUNT_OF_PAYING_USERS = `${DOMAIN_PREFIX}set_amount_of_paying_users`;
const FETCH_PERMISSION_PROFILES_SUCCESS = `${DOMAIN_PREFIX}fetch_permission_profiles_success`;
const ADD_QUERY_PARAMETER = `${DOMAIN_PREFIX}add_query_parameter`;

export default {
  DOMAIN_PREFIX,
  FETCH_USERS_SUCCESS,
  SET_AMOUNT_OF_PAYING_USERS,
  FETCH_PERMISSION_PROFILES_SUCCESS,
  ADD_QUERY_PARAMETER,
  RESET_SELECTED_USERS,
  SELECT_USER,
  SELECT_PAGE,
  SELECT_TOTAL_ENTRIES,
  HIDE_SELECT_TOTAL_ENTRIES,
};
