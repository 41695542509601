import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { getVendorSubtitle } from 'sdk/Vendor';

class VendorListItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox={this.props.canEditVendors}>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          small
          clickable
          onClick={this.props.onClick}
          linkTo={`/vendors/${this.props.vendor.id}`}
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkbox={this.props.canEditVendors}
          checkboxDisabled={this.props.checkboxDisabled}
        >
          <List.Item.TitleColumn
            title={this.props.vendor.name}
            subtitle={getVendorSubtitle(this.props.vendor)}
          />
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading)
    return {
      canEditVendors: AuthSelectors.canEditVendors(state),
    };
  return {
    canEditVendors: AuthSelectors.canEditVendors(state),
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorListItem);
