import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { bindActionCreators } from 'redux';
import { normalizeMeter } from 'sdk/Schemas';
import { EntitySelectors } from 'sdk/State/entities';
import AnimateHeight from 'react-animate-height';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Field, Button, Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ChangeDailyUsageModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    dailyUsage: this.props.meter.daily_usage_type === 'manual' ? this.props.meter.average_daily_usage : null,
    calculateDailyUsage: this.props.meter.daily_usage_type || 'automatic',
    showErrorForEmptyDailyUsage: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
    }
  }
  updateDailyUsage = () => {
    if (
      this.state.calculateDailyUsage === 'manual' &&
      (this.state.dailyUsage === null || this.state.dailyUsage === '')
    ) {
      this.setState({ showErrorForEmptyDailyUsage: true });
      return;
    }
    const params = {
      average_daily_usage: this.state.dailyUsage,
      daily_usage_type: this.state.calculateDailyUsage,
    };
    this.setState({ isSaving: true });
    API.updateMeter(this.props.id, params)
      .then(({ data: meter }) => {
        const { entities } = normalizeMeter(meter);
        this.props.updateEntities(entities);
        this.setState({ isSaving: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    if (!this.props.meter) return <Loader />;
    return (
      <Grid>
        {this.props.meter.daily_usage_type === 'automatic' &&
        this.props.meter.average_daily_usage === null ? (
          <Grid.Row>
            <Grid.Column>
              <div className={styles['warning-text']}>
                <FormattedMessage id="screens.meter.modals.change-daily-usage.warning-text" />
              </div>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <div className={styles['daily-usage-type-container']}>
              <Field.Radio
                checked={this.state.calculateDailyUsage === 'automatic'}
                onChange={() => this.setState({ calculateDailyUsage: 'automatic' })}
                subtitle={
                  <FormattedMessage id="screens.meters.create-modal.automatic-daily-usage-description" />
                }
                label={
                  <span>
                    <FormattedMessage id="screens.meters.create-modal.automatic-daily-usage" />{' '}
                    <span className={styles['automatic-timespan']}>
                      (<FormattedMessage id="resources.meter.average-daily-usage-automatic-timespan" />)
                    </span>
                  </span>
                }
              />
              <Field.Radio
                checked={this.state.calculateDailyUsage === 'manual'}
                onChange={() => this.setState({ calculateDailyUsage: 'manual' })}
                subtitle={
                  <FormattedMessage id="screens.meters.create-modal.manual-daily-usage-description" />
                }
                label={<FormattedMessage id="screens.meters.create-modal.manual-daily-usage" />}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {this.renderDailyUsageField()}
      </Grid>
    );
  };

  renderDailyUsageType = () => {
    return (
      <>
        {this.props.meterUnit.abbreviation}
        {'/'}
        <FormattedMessage id="screens.meters.create-modal.daily-usage-unit" />
      </>
    );
  };

  renderDailyUsageField = () => {
    return (
      <AnimateHeight duration={150} height={this.state.calculateDailyUsage === 'automatic' ? 0 : 'auto'}>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['usage-field']}>
              <Field.Decimal
                error={this.state.showErrorForEmptyDailyUsage}
                rightLabel={
                  this.props.meterUnit ? (
                    this.renderDailyUsageType()
                  ) : (
                    <FormattedMessage id="screens.meters.create-modal.per-day" />
                  )
                }
                value={this.state.dailyUsage}
                onChange={dailyUsage => this.setState({ dailyUsage })}
                onBlur={dailyUsage => this.setState({ dailyUsage })}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </AnimateHeight>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.meter.modals.change-daily-usage.title" />}
          subtitle={<FormattedMessage id="resources.meter.average-daily-usage-description" />}
          subtitleTopMargin
          subtitleMaxWidth="none"
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.updateDailyUsage();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.id);
  return {
    meter,
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDailyUsageModal);
