import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Field } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import styles from './style.module.scss';

class SparePartListItemExpandable extends Component {
  render() {
    if (this.props.hasProTier === false) {
      return (
        <div className={styles['more-info-container']}>
          <div className={styles['field-container']}>
            <Field view label={<FormattedMessage id="resources.spare-part.resource" />} singleRow>
              <span>{this.props.sparePart.title}</span>
              <span> - #{this.props.sparePart.article_number}</span>
            </Field>
          </div>
          <div className={styles['field-container']}>
            <Field view label={<FormattedMessage id="resources.spare-part.location" />} singleRow>
              {this.props.sparePart.spare_part_location_id == null ? (
                '-'
              ) : (
                <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
              )}
            </Field>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['more-info-container']}>
        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.in-stock" />} singleRow>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.stock_quantity}
            />
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field
            view
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 30 }} />}
            singleRow
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_thirty_days}
            />
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field
            view
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 90 }} />}
            singleRow
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_ninety_days}
            />
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field
            view
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 180 }} />}
            singleRow
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_hundred_and_eighty_days}
            />
          </Field>
        </div>

        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.ordered-quantity" />} singleRow>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.ordered_quantity}
            />
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.location" />} singleRow>
            {this.props.sparePart.spare_part_location_id == null ? (
              '-'
            ) : (
              <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
            )}
          </Field>
        </div>
        <div className={styles['separator']} />
        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.resource" />} singleRow>
            <span>{this.props.sparePart.title}</span>
            <span> - #{this.props.sparePart.article_number}</span>
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.reorder-point" />} singleRow>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reorder_point}
            />
          </Field>
        </div>
        <div className={styles['field-container']}>
          <Field view label={<FormattedMessage id="resources.spare-part.min-quantity" />} singleRow>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.minimum_quantity}
            />
          </Field>
        </div>
        <Field view label={<FormattedMessage id="resources.spare-part.max-quantity" />} singleRow>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.sparePart.maximum_quantity}
          />
        </Field>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  return {
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(SparePartListItemExpandable);
