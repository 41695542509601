import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartSelectors, SparePartOperations } from 'state/ducks/sparePart';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, WhiteCard, Pagination } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { Loader } from 'views/components/Shared/General';
import AssetSmall from 'assets/images/EmptyDataSet/AssetSmall.png';
import { NewSelectAssetModal, NewAssetModal } from 'views/components/Asset';
import SparePartAssetListItem from './SparePartAssetListItem';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import SparePartAssetModal from './SparePartAssetModal';
import styles from './style.module.scss';
import DeleteSparePartAssetModal from 'views/components/SparePartAsset/DeleteSparePartAssetModal';

class SparePartAssets extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      showSparePartAssetModal: false,
      showDeleteSparePartAssetModal: false,
      showAddSparePartAssetModal: false,
      showCreateAssetModal: false,
      selectedSparePartAssetId: null,
      tableLoading: false,
      isLoadingMore: false,
      viewInitialized: false,
      assetId: null,
      showDeleteSparePartAssetModalForId: null,
      queryParams: queryParams,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchAssets()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchAssets()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.spare-part.assets.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  fetchAssets = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    return this.props
      .fetchSparePartAssets(this.props.currentSystem.id, {
        spare_part_id: this.props.match.params.id,
        sort: 'asset_title',
        'sort-order': 'asc',
        ...params,
        ...filterParams,
      })
      .then(({ data: spare_part_assets }) => {
        return spare_part_assets;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  newSparePartAsset = () => {
    this.setState({ showSparePartAssetModal: true });
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.spare-part.assets.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.spare-part.assets.empty-data-set.subtitle" />}
        image={AssetSmall}
        button={
          this.props.canEditSpareParts && this.props.canEditAssets ? (
            <Button
              small
              primary
              onClick={this.newSparePartAsset}
              label="screens.spare-part.assets.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditSpareParts && this.props.canEditAssets) {
      return (
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.spare-part.assets.create-button"
              onClick={this.newSparePartAsset}
            />
          }
        />
      );
    }
    return null;
  };

  renderEmptySearch = () => {
    if (this.props.sparePartAssetIds.length > 0 || this.state.tableLoading) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          image={SearchImage}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.sparePartAssetIds.length === 0 ? 1 : this.props.sparePartAssetIds.length)
              .fill()
              .map(() => (
                <SparePartAssetListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.sparePartAssetIds.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.sparePartAssetIds.map(id => (
            <SparePartAssetListItem
              key={id}
              sparePartAssetId={id}
              onClick={() => {
                this.setState({
                  showAddSparePartAssetModal: true,
                  selectedSparePartAssetId: id,
                });
              }}
              onDelete={id =>
                this.setState({ showDeleteSparePartAssetModalForId: id, showDeleteSparePartAssetModal: true })
              }
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };
  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        open={this.state.showSparePartAssetModal}
        listItemRightComponent={asset => {
          if (asset.exists_for_spare_part) {
            return this.renderAlreadyAdded();
          } else return this.renderAddAssetButton(asset.id);
        }}
        additionalApiParams={{ exists_for_spare_part: this.props.match.params.id }}
        onClose={() => this.setState({ showSparePartAssetModal: false })}
        subtitle={
          <FormattedMessage
            id="screens.spare-part.assets.spare-part-asset-modal.spare-part-name"
            values={{
              sparePart: (
                <span className={styles['highlight-text']}>
                  <Title inline sparePart={this.props.sparePart} />
                </span>
              ),
            }}
          />
        }
        onCreateAsset={() => {
          this.setState({ showSparePartAssetModal: false });
          setTimeout(() => {
            this.setState({ showCreateAssetModal: true });
          }, 100);
        }}
      />
    );
  };

  renderCreateAssetModal = () => {
    return (
      <NewAssetModal
        createForSparePartId={this.props.match.params.id}
        open={this.state.showCreateAssetModal}
        onClose={() => {
          this.setState({ showCreateAssetModal: false });
          setTimeout(() => {
            this.setState({ showSparePartAssetModal: true });
          }, 100);
        }}
        onCreated={asset => {
          this.setState({ showCreateAssetModal: false }, () => {
            setTimeout(() => {
              this.setState({ showSparePartAssetModal: true });
            }, 350);
          });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showSparePartAssetModal: false }, () => {
            setTimeout(() => {
              this.setState({ showCreateAssetModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-asset']}>
        <FormattedMessage id="general.added" />
      </div>
    );
  };

  renderAddAssetButton = assetId => {
    return (
      <Button
        gray
        small
        label="general.add"
        onClick={() => {
          this.setState({ showSparePartAssetModal: false });
          setTimeout(() => {
            this.setState({
              showAddSparePartAssetModal: true,
              assetId,
              selectedSparePartAssetId: null,
            });
          }, 100);
        }}
      />
    );
  };

  renderSparePartAssetModal = () => (
    <SparePartAssetModal
      open={this.state.showAddSparePartAssetModal}
      sparePartId={this.props.match.params.id}
      assetId={this.state.assetId}
      selectedSparePartAssetId={this.state.selectedSparePartAssetId}
      onDelete={id =>
        setTimeout(
          () =>
            this.setState({ showDeleteSparePartAssetModalForId: id, showDeleteSparePartAssetModal: true }),
          250
        )
      }
      onClose={status => {
        if (status === 'edit') {
          this.setState({
            showAddSparePartAssetModal: false,
          });
          setTimeout(() => this.setState({ selectedSparePartAssetId: null }), 100);
          return;
        } else if (status === 'delete') {
          this.setState({ showAddSparePartAssetModal: false, showSparePartAssetModal: false });
          return;
        } else
          this.setState({
            showAddSparePartAssetModal: false,
          });
        setTimeout(() => {
          this.setState({ showSparePartAssetModal: true, selectedSparePartAssetId: null });
        }, 100);
        return;
      }}
    />
  );

  renderDeleteSparePartAssetModal = () => (
    <DeleteSparePartAssetModal
      id={this.state.showDeleteSparePartAssetModalForId}
      open={this.state.showDeleteSparePartAssetModal}
      title={<FormattedMessage id="screens.spare-part.assets.spare-part-asset-modal.confirm-delete.title" />}
      subtitle={
        <FormattedMessage id="screens.spare-part.assets.spare-part-asset-modal.confirm-delete.subtitle" />
      }
      onClose={() => this.setState({ showDeleteSparePartAssetModal: false })}
    />
  );

  renderSparePartAssets = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderEmptySearch()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.sparePartAssetIds.length === 0 && !this.state.tableLoading) {
      return this.renderEmptyDataset();
    } else {
      return this.renderSparePartAssets();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectAssetModal()}
        {this.renderSparePartAssetModal()}
        {this.renderDeleteSparePartAssetModal()}
        {this.renderCreateAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartAssets: SparePartOperations.fetchSparePartAssets,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartAssetIds: SparePartSelectors.getSparePartAssetIds(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.match.params.id),
    pagination: SparePartSelectors.getSparePartAssetsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartAssets)));
