import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field, Banner } from 'views/components/Shared/General';
import { API } from 'sdk';

class WellKnownModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    url: '',
    error: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  hasErrors = () => {
    if (this.state.url.length === 0) {
      this.setState({ error: true });
      return true;
    }

    return false;
  };

  save = () => {
    if (this.hasErrors()) return;

    this.setState({ isSaving: true });
    let params = {
      url: this.state.url,
    };

    API.showOidcWellKnown(this.props.organisation.id, params)
      .then((res) => {
        this.props.onSave(res.data);
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  renderMultipleSystemsAlert = () => {
    if (this.props.organisation.multiple_systems === true) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Banner orange>
              <FormattedMessage id="screens.settings.integrations.edit-sso-modal.multiple-systems-alert-message" />
            </Banner>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.add-metadata" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Field
            view={false}
            label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.metadata-url" />}
          >
            <Field.Text
              autoFocus
              error={this.state.error}
              value={this.state.url}
              placeholder={this.props.intl.formatMessage({ id: "screens.settings.integrations.edit-sso-modal.metadata-url_placeholder"})}
              onChange={url => {
                this.setState({ url, error: false });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.save}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
  };
}

export default injectIntl(connect(mapStateToProps)(WellKnownModal));
