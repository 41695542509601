import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import HelperFunctions from 'utilities/HelperFunctions';
import { InlineModal, Button, Icon } from 'views/components/Shared/General';

class AssetAttachmentOptionsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              icon="download"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.download.title" />
              }
              onClick={() => {
                HelperFunctions.openAttachment(this.props.attachmentVersion);
              }}
            />
            <InlineModal.Separator />
            <InlineModal.ListItem
              icon={<Icon light size={15} type="times" />}
              title={<FormattedMessage id="screens.work-order.remove-connection-from-asset-file" />}
              onClick={() => {
                this.props.onRemoveAssetAttachment(this.props.attachment);
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentVersion: EntitySelectors.getAttachmentVersion(state, ownProps.attachment.attachment_version),
  };
}

export default connect(mapStateToProps)(AssetAttachmentOptionsInlineModal);
