import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import TemplateFields from '../../TemplateFields';

export default () => (
  <>
    <BackButton to="/settings/spare-parts" />
    <Section
      title={<FormattedMessage id="screens.settings.spare-parts.template-fields.title" />}
      subtitle={<FormattedMessage id="screens.settings.spare-parts.template-fields.subtitle" />}
    />
    <Separator />
    <TemplateFields templateType="spare_part" />
  </>
);
