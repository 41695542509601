import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NewInlineModal, Field, EmptyDataSet } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';

class SparePartUnitInlineModal extends Component {
  getInitialState = () => ({
    isOpen: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  selectSparePartUnit = sparePartUnitId => {
    if (this.props.multiple) {
      if (this.props.selectedSparePartUnitIds.includes(sparePartUnitId)) {
        this.props.onRemoveSparePartUnit(sparePartUnitId);
      } else {
        this.props.onAddSparePartUnit(sparePartUnitId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectSparePartUnit(sparePartUnitId);
        }
      );
    }
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.title" />}
      subtitle={
        <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.subtitle" />
      }
      tiny
      inlineModalContainer
    />
  );

  renderListItem = sparePartUnit => {
    if (this.props.multiple) {
      const selected = this.props.selectedSparePartUnitIds.includes(sparePartUnit.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartUnit.id}
          onClick={e => this.selectSparePartUnit(sparePartUnit.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectSparePartUnit(sparePartUnit.id);
              }}
            />
          }
        >
          {sparePartUnit.title}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedSparePartUnitId === sparePartUnit.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={sparePartUnit.id}
          selected={selected}
          onClick={e => this.selectSparePartUnit(sparePartUnit.id)}
        >
          {sparePartUnit.title}
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderList = () => {
    if (this.props.sparePartUnits.length === 0) {
      return this.renderEmptyDataset();
    }
    return this.props.sparePartUnits.map(sparePartUnit => {
      return this.renderListItem(sparePartUnit);
    });
  };

  renderContent = () => {
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>{this.renderList()}</NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState({ isOpen: true })}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
  };
}

export default injectIntl(connect(mapStateToProps)(SparePartUnitInlineModal));

SparePartUnitInlineModal.propTypes = {
  selectedSparePartUnitId: PropTypes.string,
  selectedSparePartUnitIds: PropTypes.array,
  onSelectSparePartUnit: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
};

SparePartUnitInlineModal.defaultProps = {
  selectedSparePartUnitId: null,
  selectedSparePartUnitIds: [],
  onSelectSparePartUnit: () => {},
  onAddSparePartUnit: () => {},
  onRemoveSparePartUnit: () => {},
  onClear: () => {},
  multiple: false,
};
