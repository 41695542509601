import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { ErrorSelectors } from 'state/ducks/error';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SDKReduxOperations, HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { normalizeWorkOrder, normalizeRecurringMaintenance } from 'sdk/Schemas';
import { WorkOrderOperations, WorkOrderSelectors } from 'state/ducks/workOrder';
import { WorkOrderEditOperations, WorkOrderEditSelectors } from 'state/ducks/workOrderEdit';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import uuid from 'uuid';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import AddButton from './AddButton';
import queryString from 'query-string';
import { ReminderType } from 'sdk/WorkOrder';
import toast from 'react-hot-toast';
import { SelectAssetAttachmentModal } from 'views/components/Asset';
import { FileUploadWrapper, NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SelectMeterModal } from 'views/components/Meter';
import {
  ChecklistEditModal,
  ChecklistTitleModal,
  SelectChecklistModal,
  ChecklistOptionsModal,
} from 'views/components/Checklist';
import { NewAttachmentModal } from 'views/components/Attachment';
import { Modal } from 'views/components/Shared/Layout';
import { AddSparePartModal, EditReminderModal } from 'views/components/WorkOrder';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import AMServiceLogo from 'assets/images/Logo.png';
import Checklist_SWE from 'assets/images/ProScreenshots/checklist-pro-sv.png';
import Checklist_ENG from 'assets/images/ProScreenshots/checklist-pro-en.png';
import ChecklistAlreadyExistsModal from './ChecklistAlreadyExistsModal';
import MeterAlreadyExistsModal from './MeterAlreadyExistsModal';
import CantEditChecklistForRecurringWorkOrderModal from './CantEditChecklistForRecurringWorkOrderModal';
import MeterProModal from './MeterProModal';

class AddButtons extends Component {
  state = {
    isCreatingReservations: false,
    showAddSparePartModal: false,
    createdIds: {},
    uploadingCount: 0,
    showSelectAttachmentFromAssetModal: false,
    showEditReminderModal: false,
    showNewAttachmentModalForType: null,
    showNewFileInlineModalOptions: false,
    openChecklistProModal: false,
    showNewChecklistDropdown: false,
    showChecklistTitleModal: false,
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
    showChecklistAlreadyExistsModal: false,
    showChecklistTemplatesModal: false,
    draftTemplateId: null,
    showCantEditChecklistForRecurringWorkOrderModal: false,
    showSelectMeterModal: false,
    showMeterAlreadyExistsModal: false,
    showMeteProModal: false,
    showChecklistOptionsModal: false,
    showChecklistOptionsModalForChecklistTemplateId: null,
  };

  renderReminderModal = () => {
    return (
      <EditReminderModal
        open={this.state.showEditReminderModal}
        editingWorkOrder={this.props.editingWorkOrder}
        onSave={data => {
          this.props.setEditingWorkOrderValues(data);
          this.props.setResourceItemsSelectedTab('reminder');
        }}
        onClose={() => {
          this.setState({ showEditReminderModal: false });
        }}
      />
    );
  };

  /*
    ------------
      Spare Part Reservation
    ------------
  */

  createSparePartReservations = addedSpareParts => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.createSparePartReservationForRecurringMaintenance(addedSpareParts);
    } else {
      this.createSparePartReservationForWorkOrder(addedSpareParts);
    }
  };

  createSparePartReservationForRecurringMaintenance = addedSpareParts => {
    if (this.state.isCreatingReservations) return;
    this.setState({ isCreatingReservations: true });
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    let requestsToWaitFor = addedSpareParts.map(({ spare_part_id, quantity }) =>
      this.props.createSparePartReservationForRecurringMaintenance(recurringMaintenanceId, {
        spare_part_id,
        quantity,
      })
    );
    Promise.all(requestsToWaitFor).then(results => {
      const createdSparePartReservations = results.map(({ data }) => data);
      if (createdSparePartReservations.length === 1) {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />}
          />
        );
      } else {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-parts-success" />}
          />
        );
      }
      let createdIds = {};
      createdSparePartReservations.forEach(sparePartReservation => {
        createdIds[sparePartReservation.id] = true;
      });

      this.props.setResourceItemsSelectedTab('spare_part');
      this.setState({
        isCreatingReservations: false,
        showAddSparePartModal: false,
        createdIds: {
          ...this.state.createdIds,
          ...createdIds,
        },
      });
    });
  };

  createSparePartReservationForWorkOrder = addedSpareParts => {
    if (this.state.isCreatingReservations) return;
    this.setState({ isCreatingReservations: true });
    let requestsToWaitFor = addedSpareParts.map(({ spare_part_id, quantity }) =>
      this.props.createSparePartReservationForWorkOrder(this.props.editingWorkOrder.id, {
        spare_part_id,
        quantity,
      })
    );
    Promise.all(requestsToWaitFor).then(results => {
      const createdSparePartReservations = results.map(({ data }) => data);
      if (createdSparePartReservations.length === 1) {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />}
          />
        );
      } else {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-parts-success" />}
          />
        );
      }
      let createdIds = {};
      createdSparePartReservations.forEach(sparePartReservation => {
        createdIds[sparePartReservation.id] = true;
      });

      this.props.setResourceItemsSelectedTab('spare_part');
      this.setState({
        isCreatingReservations: false,
        showAddSparePartModal: false,
        createdIds: {
          ...this.state.createdIds,
          ...createdIds,
        },
      });
    });
  };

  /*
    ------------
      Attachments
    ------------
  */

  addAttachmentFromAsset = attachmentId => {
    const temporaryId = uuid.v4();
    const workOrderId = this.props.match.params.id;
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    let upploadFileNameSpace = recurringMaintenanceId || workOrderId;
    this.props.beginUpload({ temporaryId, namespace: upploadFileNameSpace });
    const apiCall = recurringMaintenanceId
      ? this.props.addRegistrationAttachmentToRecurringMaintenance(
          this.props.editingWorkOrder.id,
          attachmentId
        )
      : this.props.addRegistrationAttachmentToWorkOrder(this.props.editingWorkOrder.id, attachmentId);
    apiCall.then(() => {
      this.props.completeUpload({ temporaryId, namespace: upploadFileNameSpace });
      toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.add-file-success" />} />);
    });
  };

  createRegistrationAttachment = params => {
    const workOrderId = this.props.match.params.id;
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    const { description, title, extension, file, type, mime_type, link_url } = params;
    const apiCall = recurringMaintenanceId
      ? this.props.createRegistrationAttachmentForRecurringMaintenance(this.props.editingWorkOrder.id, {
          description,
          title,
          mime_type,
          type,
          link_url,
          extension,
        })
      : this.props.createRegistrationAttachmentForWorkOrder(this.props.editingWorkOrder.id, {
          description,
          title,
          mime_type,
          type,
          extension,
          link_url,
        });
    const temporaryId = uuid.v4();
    let upploadFileNameSpace = recurringMaintenanceId || workOrderId;
    if (type === 'file') {
      this.props.beginUpload({ temporaryId, namespace: upploadFileNameSpace });
      apiCall.then(({ data: attachment }) => {
        return SDKHelperFunctions.uploadFileToS3(
          { url: attachment.attachment_version.upload_url, file, mime_type },
          ({ loaded, total }) => {
            this.props.updateUpload({
              id: attachment.id,
              loaded,
              total,
              temporaryId,
              namespace: upploadFileNameSpace,
            });
          }
        )
          .then(() => {
            return this.props
              .attachmentVersionUploaded(attachment.attachment_version.id, {
                workOrderId: this.props.match.params.id,
                recurringMaintenanceId: recurringMaintenanceId,
              })
              .then(() => {
                this.props.completeUpload({
                  id: attachment.id,
                  temporaryId,
                  namespace: upploadFileNameSpace,
                });
                toast(
                  <ToastMessage
                    success
                    text={<FormattedMessage id="screens.work-order.add-file-success" />}
                  />
                );
              });
          })
          .catch(e => {
            this.props.cancelUpload({ temporaryId, namespace: upploadFileNameSpace });
            this.setState({ showUploadAttachmentErrorWarning: true });
          });
      });
    } else {
      this.props.beginUpload({ temporaryId, namespace: upploadFileNameSpace });
      apiCall.then(({ data: attachment }) => {
        this.props.completeUpload({ id: attachment.id, temporaryId, namespace: upploadFileNameSpace });
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.add-file-success" />} />);
      });
    }
  };

  addImageToWorkOrder = ({ file, mime_type, name }) => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    this.props.incrementImageLoaderCount();

    this.props.setResourceItemsSelectedTab('image');
    const apiCall = recurringMaintenanceId
      ? this.props.createImageForRecurringMaintenance(this.props.editingWorkOrder.id, {
          image: file,
          mime_type,
          name,
        })
      : this.props.createRegistrationImageForWorkOrder(this.props.editingWorkOrder.id, {
          image: file,
          mime_type,
          name,
        });
    apiCall
      .then(() => {
        this.props.decrementImageLoaderCount();
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.add-image-success" />} />);
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  /*
    ------------
      Checklist
    ------------
  */

  createDraftChecklistTemplate = title => {
    this.setState({
      showNewChecklistDropdown: false,
      showChecklistTitleModal: false,
      showChecklistEditModal: true,
      showChecklistEditModalForVersionId: null,
    });
    this.props
      .createChecklistTemplate(this.props.system.id, { title })
      .then(({ data: checklistTemplate }) => {
        this.setState({
          draftTemplateId: checklistTemplate.id,
          showChecklistEditModalForVersionId: checklistTemplate.draft_version.id,
        });
      });
  };

  connectChecklistTemplate = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.setState({ isSavingTemplateAsReusable: true });
      this.props
        .updateChecklistTemplate(this.state.draftTemplateId, {
          recurring_maintenance_id: recurringMaintenanceId,
        })
        .then(() => {
          API.fetchRecurringMaintenance(recurringMaintenanceId).then(({ data: recurringMaintenance }) => {
            const { entities } = normalizeRecurringMaintenance(recurringMaintenance);
            this.props.updateEntities(entities);
            this.props.setResourceItemsSelectedTab('checklist');
            toast(
              <ToastMessage
                success
                text={<FormattedMessage id="screens.work-order.edit.checklist.create-checklist-success" />}
              />
            );
            this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
          });
        })
        .catch(error => {
          if (SDKHelperFunctions.hasError(error, { code: '140013' })) {
            API.fetchRecurringMaintenance(recurringMaintenanceId).then(({ data: recurringMaintenance }) => {
              const { entities } = normalizeRecurringMaintenance(recurringMaintenance);
              this.props.updateEntities(entities);
              toast(
                <ToastMessage
                  error
                  text={
                    <FormattedMessage id="screens.work-order.edit.checklist.errors.checklist-already-added" />
                  }
                />
              );
              this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
            });
          }
        });
    } else {
      this.setState({ isSavingTemplateAsReusable: true });
      this.props
        .updateChecklistTemplate(this.state.draftTemplateId, {
          work_order_id: this.props.match.params.id,
        })
        .then(() => {
          API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
            const { entities } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            this.props.setResourceItemsSelectedTab('checklist');
            toast(
              <ToastMessage
                success
                text={<FormattedMessage id="screens.work-order.edit.checklist.create-checklist-success" />}
              />
            );
            this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
          });
        })
        .catch(error => {
          if (SDKHelperFunctions.hasError(error, { code: '140013' })) {
            API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
              const { entities } = normalizeWorkOrder(workOrder);
              this.props.updateEntities(entities);
              this.props.setResourceItemsSelectedTab('checklist');
              toast(
                <ToastMessage
                  error
                  text={
                    <FormattedMessage id="screens.work-order.edit.checklist.errors.checklist-already-added" />
                  }
                />
              );
              this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
            });
          }
        });
    }
  };

  addReusableChecklistTemplate = data => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    this.props.showAddReusableChecklistLoader();
    this.props.setResourceItemsSelectedTab('checklist');
    this.setState({ showChecklistTemplatesModal: false });
    if (recurringMaintenanceId) {
      this.props
        .createChecklistTemplateLinkForRecurringMaintenance(recurringMaintenanceId, data)
        .then(() => {
          this.props.hideAddReusableChecklistLoader();
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.work-order.edit.checklist.add-checklist-success" />}
            />
          );
        })
        .catch(error => {
          this.props.hideAddReusableChecklistLoader();
          if (SDKHelperFunctions.hasError(error, { code: '140013' })) {
            API.fetchRecurringMaintenance(recurringMaintenanceId).then(({ data: recurringMaintenance }) => {
              const { entities } = normalizeRecurringMaintenance(recurringMaintenance);
              this.props.updateEntities(entities);
              toast(
                <ToastMessage
                  error
                  text={
                    <FormattedMessage id="screens.work-order.edit.checklist.errors.checklist-already-added" />
                  }
                />
              );
              this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
            });
          }
        });
    } else {
      this.props
        .createChecklistTemplateLinkForWorkOrder(this.props.editingWorkOrder.id, data)
        .then(() => {
          this.props.hideAddReusableChecklistLoader();
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.work-order.edit.checklist.add-checklist-success" />}
            />
          );
        })
        .catch(error => {
          this.props.hideAddReusableChecklistLoader();
          if (SDKHelperFunctions.hasError(error, { code: '140013' })) {
            API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
              const { entities } = normalizeWorkOrder(workOrder);
              this.props.updateEntities(entities);
              toast(
                <ToastMessage
                  error
                  text={
                    <FormattedMessage id="screens.work-order.edit.checklist.errors.checklist-already-added" />
                  }
                />
              );
              this.setState({ showChecklistEditModal: false, isSavingTemplateAsReusable: false });
            });
          }
        });
    }
  };

  /*
    ------------
      Render functions
    ------------
  */

  renderAttachmentsContainer = () => {
    return (
      <>
        <NewInlineModal
          positionToRef={this.attachmentsInlineModalPositioningRef}
          open={this.state.showNewFileInlineModalOptions}
          onClose={() => this.setState({ showNewFileInlineModalOptions: false })}
        >
          <React.Fragment>
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showNewFileInlineModalOptions: false,
                      showNewAttachmentModalForType: 'file',
                    });
                  }}
                >
                  <FormattedMessage id="screens.asset.attachments.file-from-computer" />
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showNewFileInlineModalOptions: false,
                      showNewAttachmentModalForType: 'link',
                    });
                  }}
                >
                  <FormattedMessage id="screens.asset.attachments.hyperlink" />
                </NewInlineModal.Dropdown.Item>
                {this.props.asset === null ? null : (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showNewFileInlineModalOptions: false,
                        showSelectAttachmentFromAssetModal: this.props.asset,
                      });
                    }}
                  >
                    <FormattedMessage
                      id="screens.asset.attachments.choose-from-asset"
                      values={{ asset: this.props.asset.title }}
                    />
                  </NewInlineModal.Dropdown.Item>
                )}
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </React.Fragment>
        </NewInlineModal>
      </>
    );
  };

  renderChecklistProModal = () => {
    return (
      <Modal width={900} centered isOpen={this.state.openChecklistProModal}>
        <div className={styles['pro-modal-content']}>
          <div className={styles['modal-title']}>
            <img className={styles['am-logo']} src={AMServiceLogo} alt="Logo" />
            <FormattedMessage id="screens.work-order.edit.checklist-pro-modals.title" />
          </div>
          <div className={styles['modal-subtitle']}>
            <FormattedMessage id="screens.work-order.edit.checklist-pro-modals.subtitle" />
          </div>
          <div className={styles['computer-container']}>
            <img
              className={styles['computer-logo']}
              src={this.props.language === 'sv' ? Checklist_SWE : Checklist_ENG}
              alt="Checklist"
            />
          </div>
          <div className={styles['upgrade-buttons-container']}>
            <Button large upgradeToPro label="general.upgrade-to-pro" />
          </div>
        </div>
        <div className={styles['background-rect']} />
        <div className={styles['close-button']}>
          <Button
            type="icon"
            icon={<Icon light size={18} type="times" />}
            onClick={() => this.setState({ openChecklistProModal: false })}
          />
        </div>
      </Modal>
    );
  };

  renderChecklistAddButton = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;

    const { checklistInstances, checklistTemplateLinks, isAddingChecklistTemplate } = this.props;
    const hasInstances = checklistInstances && checklistInstances.length > 0;
    const hasTemplateLinks = checklistTemplateLinks && checklistTemplateLinks.length > 0;
    if (this.props.hasProTier === false) {
      return (
        <AddButton
          title={<FormattedMessage id="screens.work-order.edit.buttons.checklist" />}
          subtitle={<FormattedMessage id="screens.work-order.edit.buttons.checklist-description" />}
          icon="list-ul"
          inactive
          onClick={() => {
            this.setState({ openChecklistProModal: true });
          }}
        />
      );
    }
    if (this.props.recurringMaintenance && recurringMaintenanceId == null) {
      return (
        <AddButton
          title={<FormattedMessage id="screens.work-order.edit.buttons.checklist" />}
          subtitle={<FormattedMessage id="screens.work-order.edit.buttons.checklist-description" />}
          icon="list-ul"
          onClick={() => {
            this.setState({ showCantEditChecklistForRecurringWorkOrderModal: true });
          }}
        />
      );
    }
    if (hasInstances || hasTemplateLinks || isAddingChecklistTemplate) {
      return (
        <AddButton
          title={<FormattedMessage id="screens.work-order.edit.buttons.checklist" />}
          subtitle={<FormattedMessage id="screens.work-order.edit.buttons.checklist-description" />}
          icon="list-ul"
          onClick={() => {
            this.setState({ showChecklistAlreadyExistsModal: true });
          }}
        />
      );
    }
    return (
      <>
        <div ref={ref => (this.checklistButtonRef = ref)}>
          <AddButton
            title={<FormattedMessage id="screens.work-order.edit.buttons.checklist" />}
            subtitle={<FormattedMessage id="screens.work-order.edit.buttons.checklist-description" />}
            icon="list-ul"
            onClick={() => {
              this.setState(prevState => ({
                showNewChecklistDropdown: !prevState.showNewChecklistDropdown,
              }));
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.checklistButtonRef}
          open={this.state.showNewChecklistDropdown}
          onClose={() => this.setState({ showNewChecklistDropdown: false })}
        >
          <React.Fragment>
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showNewChecklistDropdown: false,
                      showChecklistTitleModal: true,
                    });
                  }}
                >
                  <FormattedMessage id="screens.work-order.edit.checklist.dropdown.create" />
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({
                      showNewChecklistDropdown: false,
                      showChecklistTemplatesModal: true,
                    });
                  }}
                >
                  <FormattedMessage id="screens.work-order.edit.checklist.dropdown.add" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </React.Fragment>
        </NewInlineModal>
      </>
    );
  };

  renderAddMeterButton = () => {
    if (this.props.settings.meters_activated !== true) {
      return null;
    }
    if (this.props.hasProTier === false) {
      return (
        <div style={{ msGridRow: 2, msGridColumn: 1, margin: '3px' }}>
          <AddButton
            title={<FormattedMessage id="screens.work-order.edit.buttons.meter" />}
            subtitle={<FormattedMessage id="screens.work-order.edit.buttons.meter-description" />}
            icon="tachometer-alt-slow"
            inactive
            onClick={() => {
              this.setState({ showMeteProModal: true });
            }}
          />
        </div>
      );
    }
    if (this.props.meter) {
      return (
        <div style={{ msGridRow: 2, msGridColumn: 1, margin: '3px' }}>
          <AddButton
            title={<FormattedMessage id="screens.work-order.edit.buttons.meter" />}
            subtitle={<FormattedMessage id="screens.work-order.edit.buttons.meter-description" />}
            icon="tachometer-alt-slow"
            onClick={() => {
              this.setState({ showMeterAlreadyExistsModal: true });
            }}
          />
        </div>
      );
    }
    return (
      <div style={{ msGridRow: 2, msGridColumn: 1, margin: '3px' }}>
        <AddButton
          title={<FormattedMessage id="screens.work-order.edit.buttons.meter" />}
          subtitle={<FormattedMessage id="screens.work-order.edit.buttons.meter-description" />}
          icon="tachometer-alt-slow"
          onClick={() => {
            this.setState({ showSelectMeterModal: true });
          }}
        />
      </div>
    );
  };

  renderChecklistEditModal = () => {
    return (
      <ChecklistEditModal
        assetId={this.props.editingWorkOrder.asset_id}
        workOrderId={this.props.match.params.id}
        recurringMaintenanceId={queryString.parse(this.props.location.search).recurring_maintenance_id}
        open={this.state.showChecklistEditModal}
        id={this.state.showChecklistEditModalForVersionId}
        isSaving={this.state.isSavingTemplateAsReusable}
        onSave={this.connectChecklistTemplate}
        onClose={() => {
          this.setState({ showChecklistEditModal: false });
        }}
        onAddToLibrarySuccess={() => {
          this.setState({
            showChecklistEditModal: false,
          });
          this.props.setResourceItemsSelectedTab('checklist');
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="screens.work-order.edit.checklist.save-checklist-to-library-success" />
              }
            />
          );
        }}
      />
    );
  };

  renderChecklistTitleModal = () => (
    <ChecklistTitleModal
      open={this.state.showChecklistTitleModal}
      onSave={this.createDraftChecklistTemplate}
      onClose={() => {
        this.setState({ showChecklistTitleModal: false });
      }}
    />
  );

  renderChecklistAlreadyExistsModal = () => {
    return (
      <ChecklistAlreadyExistsModal
        open={this.state.showChecklistAlreadyExistsModal}
        onClose={() => this.setState({ showChecklistAlreadyExistsModal: false })}
      />
    );
  };

  renderSelectAssetAttachmentModal = () => {
    return (
      <SelectAssetAttachmentModal
        selectable
        isOpen={this.state.showSelectAttachmentFromAssetModal}
        onClose={() => this.setState({ showSelectAttachmentFromAssetModal: false })}
        onSelectAttachment={attachment => {
          this.setState({ showSelectAttachmentFromAssetModal: false });
          this.props.setResourceItemsSelectedTab('attachment');
          this.addAttachmentFromAsset(attachment.id);
        }}
        assetId={this.props.editingWorkOrder.asset_id}
      />
    );
  };

  renderSelectChecklistTemplateModal = () => {
    return (
      <SelectChecklistModal
        open={this.state.showChecklistTemplatesModal}
        onSelect={checklistTemplate => {
          if (checklistTemplate.require_options) {
            this.setState({ showChecklistTemplatesModal: false });
            setTimeout(() => {
              this.setState({
                showChecklistOptionsModal: true,
                showChecklistOptionsModalForChecklistTemplateId: checklistTemplate.id,
              });
            }, 200);
          } else {
            this.addReusableChecklistTemplate({
              checklist_template_id: checklistTemplate.id,
            });
          }
        }}
        onClose={() => {
          this.setState({ showChecklistTemplatesModal: false });
        }}
      />
    );
  };

  renderChecklistOptionsModal = () => {
    return (
      <ChecklistOptionsModal
        assetId={this.props.editingWorkOrder.asset_id}
        checklistTemplateId={this.state.showChecklistOptionsModalForChecklistTemplateId}
        open={this.state.showChecklistOptionsModal}
        onSave={params => {
          this.setState({ showChecklistOptionsModal: false });
          this.addReusableChecklistTemplate({
            checklist_template_id: this.state.showChecklistOptionsModalForChecklistTemplateId,
            ...params,
          });
        }}
        onClose={() => this.setState({ showChecklistOptionsModal: false })}
      />
    );
  };

  renderCantEditChecklistForRecurringWorkOrderModal = () => {
    return (
      <CantEditChecklistForRecurringWorkOrderModal
        open={this.state.showCantEditChecklistForRecurringWorkOrderModal}
        onClose={() => {
          this.setState({ showCantEditChecklistForRecurringWorkOrderModal: false });
        }}
      />
    );
  };

  renderSelectMeterModal = () => {
    return (
      <SelectMeterModal
        assetId={this.props.editingWorkOrder.asset_id}
        open={this.state.showSelectMeterModal}
        onClose={() => {
          this.setState({ showSelectMeterModal: false });
        }}
        onSelect={id => {
          this.setState({
            showSelectMeterModal: false,
          });
          let params = {
            meter_id: id,
          };
          if (this.props.editingWorkOrder.reminder_type === ReminderType.RelativeToDueDate) {
            params = {
              ...params,
              reminder_type: ReminderType.None,
              reminder_days_relative_to_due_date: null,
            };
          }
          this.props.setEditingWorkOrderValues(params);
        }}
      />
    );
  };

  renderMeterAlreadyExistsModal = () => {
    return (
      <MeterAlreadyExistsModal
        open={this.state.showMeterAlreadyExistsModal}
        onClose={() => this.setState({ showMeterAlreadyExistsModal: false })}
      />
    );
  };

  renderMeterProModal = () => {
    return (
      <MeterProModal
        open={this.state.showMeteProModal}
        onClose={() => this.setState({ showMeteProModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['grid-header']}>
          <FormattedMessage id="screens.work-order.edit.button-title" />
        </div>
        <div className={styles['button-grid']}>
          {this.props.settings.spare_parts_activated ? (
            <div style={{ msGridRow: 1, msGridColumn: 1, margin: '3px' }}>
              <AddButton
                title={<FormattedMessage id="screens.work-order.edit.buttons.spare-parts" />}
                subtitle={<FormattedMessage id="screens.work-order.edit.buttons.spare-parts-description" />}
                icon="cogs"
                onClick={() => this.setState({ showAddSparePartModal: true })}
              />
            </div>
          ) : null}
          <div style={{ msGridRow: 1, msGridColumn: 2, margin: '3px' }}>
            {this.renderChecklistAddButton()}
          </div>
          {this.renderAddMeterButton()}
          <div style={{ msGridRow: 2, msGridColumn: 2, margin: '3px' }}>
            <AddButton
              title={<FormattedMessage id="screens.work-order.edit.buttons.reminder" />}
              subtitle={<FormattedMessage id="screens.work-order.edit.buttons.reminder-description" />}
              icon="bell"
              onClick={() => this.setState({ showEditReminderModal: true })}
            />
          </div>
          <div style={{ msGridRow: 3, msGridColumn: 1, margin: '3px' }}>
            <div ref={ref => (this.attachmentsInlineModalPositioningRef = ref)}>
              <AddButton
                title={<FormattedMessage id="screens.work-order.edit.buttons.attached-file" />}
                subtitle={<FormattedMessage id="screens.work-order.edit.buttons.attached-file-description" />}
                icon="file-alt"
                onClick={() => {
                  this.setState(prevState => ({
                    showNewFileInlineModalOptions: !prevState.showNewFileInlineModalOptions,
                  }));
                }}
              />
            </div>
          </div>
          <div style={{ msGridRow: 3, msGridColumn: 2, margin: '3px' }}>
            <FileUploadWrapper
              image
              accept="image/png, image/jpg, image/jpeg, image/gif"
              onSelectFile={this.addImageToWorkOrder}
            >
              <AddButton
                title={<FormattedMessage id="screens.work-order.edit.buttons.image" />}
                subtitle={<FormattedMessage id="screens.work-order.edit.buttons.image-description" />}
                icon="image"
              />
            </FileUploadWrapper>
          </div>
        </div>
        <AddSparePartModal
          reservation
          assetId={this.props.editingWorkOrder.asset_id}
          isSaving={this.state.isCreatingReservations}
          excludeSparePartIds={this.props.sparePartReservations.map(({ spare_part_id }) => spare_part_id)}
          onAddSpareParts={addedSpareParts => this.createSparePartReservations(addedSpareParts)}
          open={this.state.showAddSparePartModal}
          onClose={() => this.setState({ showAddSparePartModal: false })}
        />
        <NewAttachmentModal
          isOpen={this.state.showNewAttachmentModalForType != null}
          type={this.state.showNewAttachmentModalForType}
          onClose={() => this.setState({ showNewAttachmentModalForType: null })}
          onCreateNew={params => {
            this.props.setResourceItemsSelectedTab('attachment');
            this.createRegistrationAttachment(params);
          }}
        />
        {this.renderChecklistProModal()}
        {this.renderAttachmentsContainer()}
        {this.renderChecklistTitleModal()}
        {this.renderChecklistEditModal()}
        {this.renderChecklistOptionsModal()}
        {this.renderChecklistAlreadyExistsModal()}
        {this.renderSelectChecklistTemplateModal()}
        {this.renderSelectAssetAttachmentModal()}
        {this.renderCantEditChecklistForRecurringWorkOrderModal()}
        {this.renderReminderModal()}
        {this.renderSelectMeterModal()}
        {this.renderMeterAlreadyExistsModal()}
        {this.renderMeterProModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createChecklistTemplateLinkForWorkOrder: SDKReduxOperations.createChecklistTemplateLinkForWorkOrder,
      createChecklistTemplateLinkForRecurringMaintenance:
        SDKReduxOperations.createChecklistTemplateLinkForRecurringMaintenance,
      createChecklistTemplateForWorkOrder: SDKReduxOperations.createChecklistTemplateForWorkOrder,
      createChecklistTemplateForRecurringMaintenance:
        SDKReduxOperations.createChecklistTemplateForRecurringMaintenance,
      createRegistrationImageForWorkOrder: SDKReduxOperations.createRegistrationImageForWorkOrder,
      createImageForRecurringMaintenance: SDKReduxOperations.createImageForRecurringMaintenance,
      setResourceItemsSelectedTab: WorkOrderEditOperations.setResourceItemsSelectedTab,
      incrementImageLoaderCount: WorkOrderEditOperations.incrementImageLoaderCount,
      decrementImageLoaderCount: WorkOrderEditOperations.decrementImageLoaderCount,
      createSparePartReservationForWorkOrder: SDKReduxOperations.createSparePartReservationForWorkOrder,
      createSparePartReservationForRecurringMaintenance:
        SDKReduxOperations.createSparePartReservationForRecurringMaintenance,
      createRegistrationAttachmentForRecurringMaintenance:
        SDKReduxOperations.createRegistrationAttachmentForRecurringMaintenance,
      createRegistrationAttachmentForWorkOrder: SDKReduxOperations.createRegistrationAttachmentForWorkOrder,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      addRegistrationAttachmentToWorkOrder: SDKReduxOperations.addRegistrationAttachmentToWorkOrder,
      addRegistrationAttachmentToRecurringMaintenance:
        SDKReduxOperations.addRegistrationAttachmentToRecurringMaintenance,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
      updateChecklistTemplate: SDKReduxOperations.updateChecklistTemplate,
      showAddReusableChecklistLoader: WorkOrderEditOperations.showAddReusableChecklistLoader,
      hideAddReusableChecklistLoader: WorkOrderEditOperations.hideAddReusableChecklistLoader,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const editingWorkOrder = WorkOrderSelectors.getEditingWorkOrder(state);
  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      system: AuthSelectors.getCurrentSystem(state),
      hasProTier: AuthSelectors.hasProTier(state),
      editingWorkOrder,
      asset: WorkOrderSelectors.getEditingWorkOrderAsset(state),
      registrationAttachments: EntitySelectors.getAttachments(
        state,
        recurringMaintenance.registration_attachments
      ),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        recurringMaintenance.checklist_template_links
      ),
      settings: AuthSelectors.getSettings(state),
      meter: EntitySelectors.getMeter(state, editingWorkOrder.meter_id),
      registrationImages: EntitySelectors.getImages(state, recurringMaintenance.images),
      fieldErrors: ErrorSelectors.getFieldErrors(state),
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, recurringMaintenance.spare_part_reservations) || [],
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
      language: AuthSelectors.getLanguage(state),
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      system: AuthSelectors.getCurrentSystem(state),
      hasProTier: AuthSelectors.hasProTier(state),
      editingWorkOrder,
      meter: EntitySelectors.getMeter(state, editingWorkOrder.meter_id),
      asset: WorkOrderSelectors.getEditingWorkOrderAsset(state),
      settings: AuthSelectors.getSettings(state),
      imageLoaderCount: WorkOrderEditSelectors.getImageLoaderCount(state),
      registrationAttachments: EntitySelectors.getAttachments(state, workOrder.registration_attachments),
      checklistInstances: EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances),
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        workOrder.checklist_template_links
      ),
      registrationImages: EntitySelectors.getImages(state, workOrder.registration_images),
      fieldErrors: ErrorSelectors.getFieldErrors(state),
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, workOrder.spare_part_reservations) || [],
      recurringMaintenance: WorkOrderSelectors.getEditingWorkOrderRecurringMaintenance(state),
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
      language: AuthSelectors.getLanguage(state),
    };
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddButtons)));
