import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class BackButton extends Component {
  render() {
    return (
      <div
        className={styles['back-button-container']}
        onClick={() =>
          this.props.onNavigate(this.props.asset.tree_parent_id ? this.props.asset.tree_parent_id : 'root')
        }
      >
        <Icon regular type="angle-left" blue size={11} />
        <p className={styles['back']}>
          <FormattedMessage id="general.back" />
        </p>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.parentAssetId),
  };
}

export default connect(mapStateToProps)(BackButton);
