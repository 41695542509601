import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import {
  ConfirmModal,
  SectionHeader,
  WhiteCard,
  InlineModal,
  Button,
  Icon,
} from 'views/components/Shared/General';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ElapsedTime } from 'views/components/Shared/General';
import { DowntimeTimer, DowntimeModal } from 'views/components/Downtime';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';

class RequestDowntime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDowntimeModal: false,
    };
  }

  getTotalStopTimeAsMinutes = downtime => {
    const fromDate = moment(downtime.from);
    const toDate = moment(downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  renderDowntimeModal = () => (
    <DowntimeModal
      id={this.props.downtime.id}
      open={this.state.showDowntimeModal}
      openedFromRequestId={this.props.request.id}
      onClose={() => {
        this.setState({ showDowntimeModal: false });
      }}
    />
  );

  renderLabel = () => {
    if (this.props.downtime.to == null) {
      return (
        <div className={styles['active-label']}>
          <FormattedMessage id="resources.downtime.active" />
        </div>
      );
    }
    return (
      <div className={styles['label']}>
        <ElapsedTime elapsedMinutes={this.getTotalStopTimeAsMinutes(this.props.downtime)} />
      </div>
    );
  };

  renderValue = () => {
    if (this.props.downtime.to == null) {
      return <DowntimeTimer downtime={this.props.downtime} />;
    }
    return moment(this.props.downtime.created_at).format('LL');
  };

  renderToolTipInlineModalContent = () => {
    return (
      <InlineModal.Body width={250} dropdown>
        <InlineModal.ListItem
          title={<FormattedMessage id="screens.requests.remove-from-downtime" />}
          onClick={() =>
            this.setState({ showConfirmRemoveFromDowntime: true, showTooltipInlineModal: false })
          }
        />
      </InlineModal.Body>
    );
  };

  renderTooltip = () => {
    return (
      <div className={styles['tooltip-container']}>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.stopPropagation();
            this.setState(prevState => ({
              showTooltipInlineModal: !prevState.showTooltipInlineModal,
            }));
          }}
        >
          <Button type="icon" icon={<Icon light size={24} type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showTooltipInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showTooltipInlineModal: false })}
          position="right"
        >
          {this.renderToolTipInlineModalContent()}
        </InlineModal>
      </div>
    );
  };

  renderConfirmRemoveFromWorkOrderModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmRemoveFromDowntime}
        title={<FormattedMessage id="screens.requests.remove-from-downtime-confirm-modal.title" />}
        message={<FormattedMessage id="screens.requests.remove-from-downtime-confirm-modal.message" />}
        confirmButtonText="screens.requests.remove-from-downtime-confirm-modal.remove-button"
        confirmIsLoading={this.state.isRemovingFromDowntime}
        onConfirm={() => this.removeRequestFromWorkOrder()}
        onCancel={() => {
          this.setState({ showConfirmRemoveFromDowntime: false, showTooltipInlineModal: false });
        }}
      />
    );
  };

  removeRequestFromWorkOrder = () => {
    this.setState({ isRemovingFromDowntime: true });
    this.props
      .updateRequest(this.props.request.id, { downtime_id: null, downtime: null })
      .then(() => {
        this.setState({
          showTooltipInlineModal: false,
          showConfirmRemoveFromDowntime: false,
          isRemovingFromDowntime: false,
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.requests.remove-from-downtime-confirm-modal.success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ isRemovingFromDowntime: false });
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
      });
  };

  render() {
    if (!this.props.downtime) return null;
    return (
      <>
        <div className={styles['container']}>
          <WhiteCard noPadding>
            <SectionHeader noBorderTop paddingHorizontal={26}>
              <div className={styles['section']}>
                <FormattedMessage id="resources.downtime.resource" />
              </div>
            </SectionHeader>
            <div
              className={styles['content-container']}
              onClick={() => {
                this.setState(prevState => ({
                  showDowntimeModal: !prevState.showDowntimeModal,
                }));
              }}
            >
              <div className={styles['content']}>
                <div className={styles['value-container']}>
                  {this.renderLabel()}
                  <div className={styles['value']}>{this.renderValue()}</div>
                </div>
                {this.props.canEditRequest && this.renderTooltip()}
              </div>
            </div>
          </WhiteCard>
        </div>
        {this.renderDowntimeModal()}
        {this.renderConfirmRemoveFromWorkOrderModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    downtime: EntitySelectors.getDowntime(state, ownProps.request.downtime_id),
    canEditRequest: AuthSelectors.canEditRequest(state, ownProps.request),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRequest: SDKReduxOperations.updateRequest,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestDowntime);
