import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { RequestStatus } from 'sdk/Request';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import { StatusInlineModal } from 'views/components/Request';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { FILTER_TYPES } from 'views/scenes/Requests/FilterModal';

class Status extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter;
    this.state = {
      comparator,
      statuses: value,
      initialFilter: props.filter,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        this.props.addFilter({
          key: FILTER_TYPES.Status,
          data: {
            [FILTER_TYPES.Status]: {
              comparator: this.state.comparator,
              value: this.state.statuses,
            },
          },
        });
      }
    }
  }

  hasFiltersApplied = () => {
    return this.state.statuses.length > 0;
  };

  /*
    Render functions
  */

  renderStatus = status => {
    switch (status) {
      case RequestStatus.Active:
        return <FormattedMessage id="resources.request.statuses.active" />;
      case RequestStatus.AwaitingApproval:
        return <FormattedMessage id="resources.request.statuses.awaiting-production-supervisor-approval" />;
      case RequestStatus.WorkOrderCreated:
        return <FormattedMessage id="resources.request.statuses.work-order-created" />;
      case RequestStatus.Completed:
        return <FormattedMessage id="resources.request.statuses.completed" />;
      case RequestStatus.Archived:
        return <FormattedMessage id="resources.request.statuses.archived" />;
      default:
        return null;
    }
  };

  renderSelectedStatusText = () => {
    if (this.state.statuses.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.statuses}
        renderItem={status => this.renderStatus(status)}
      />
    );
  };

  renderTrigger = () => {
    return (
      <StatusInlineModal
        value={this.state.statuses}
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedStatusText()}
            onClear={() =>
              this.setState({
                statuses: [],
              })
            }
          />
        }
        onAddStatus={status => {
          this.setState({
            statuses: [...this.state.statuses, status],
          });
        }}
        onRemoveStatus={status => {
          this.setState({
            statuses: this.state.statuses.filter(item => item !== status),
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Status,
                data: {
                  [FILTER_TYPES.Status]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Status,
                data: {
                  [FILTER_TYPES.Status]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.request.status" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            comparator={this.state.comparator}
            onChange={({ comparator }) => {
              this.setState({
                comparator,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: RequestsOperations.removeFilter,
      addFilter: RequestsOperations.addFilter,
      showAppliedFilters: RequestsOperations.showAppliedFilters,
      selectFilterType: RequestsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: RequestsSelectors.getFiltersForKey(state, FILTER_TYPES.Status),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);

Status.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Status.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: [],
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: [],
  },
};
