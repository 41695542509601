import types from './types';
import update from 'immutability-helper';
import { uniq } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  id: null,
  attachments: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  sparePartAssets: {
    ids: [],
    createdIds: [],
    pagination: {},
  },
  vendors: {
    primaryVendorId: null,
    ids: [],
    sparePartVendorIdsByVendorId: {},
    pagination: {},
  },
  purchaseOrders: {
    ids: [],
    pagination: {},
  },
  withdrawals: {
    ids: [],
    pagination: {},
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE:
      return INITIAL_STATE;

    case types.SET_SPARE_PART_ID: {
      return {
        ...INITIAL_STATE,
        id: action.payload,
      };
    }

    case types.FETCH_VENDORS_SUCCESS: {
      const { pagination, sparePartVendorIdsByVendorId, ids } = action.payload;
      return {
        ...state,
        vendors: {
          ...state.vendors,
          ids,
          sparePartVendorIdsByVendorId: {
            ...state.vendors.sparePartVendorIdsByVendorId,
            ...sparePartVendorIdsByVendorId,
          },
          pagination,
        },
      };
    }
    case types.CLEAR_PRIMARY_VENDOR: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          primaryVendorId: null,
        },
      };
    }
    case types.FETCH_PRIMARY_VENDOR_SUCCESS: {
      const { sparePartVendorIdsByVendorId, id } = action.payload;
      return {
        ...state,
        vendors: {
          ...state.vendors,
          primaryVendorId: id,
          sparePartVendorIdsByVendorId: {
            ...state.vendors.sparePartVendorIdsByVendorId,
            ...sparePartVendorIdsByVendorId,
          },
        },
      };
    }

    case types.FETCH_ATTACHMENTS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case types.FETCH_SPARE_PART_ASSETS_SUCCESS: {
      const { ids, pagination } = action.payload;

      return {
        ...state,
        sparePartAssets: {
          ...state.sparePartAssets,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }

    case types.FETCH_PURCHASE_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          ids,
          createdIds: [],
          pagination,
        },
      };
    }
    case types.FETCH_WITHDRAWALS_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          ids,
          pagination,
        },
      };
    }
    case SDKReduxTypes.SPARE_PART_UPDATED: {
      const { data, params } = action.payload;
      const { id } = data;
      const { primary_vendor_id } = params;
      if (id === state.id && params.hasOwnProperty('primary_vendor_id')) {
        return update(state, {
          vendors: {
            primaryVendorId: {
              $set: primary_vendor_id,
            },
            ids: {
              $apply: ids => {
                return [state.vendors.primaryVendorId, ...ids.filter(id => id !== primary_vendor_id)].filter(
                  val => val
                );
              },
            },
          },
        });
      }
      return state;
    }

    case SDKReduxTypes.SPARE_PART_VENDOR_DELETED: {
      const { sparePartVendorId, data } = action.payload;
      const { vendor_id } = data;
      const sparePartVendorIdsForSparePartId = state.vendors.sparePartVendorIdsByVendorId[vendor_id];
      if (sparePartVendorIdsForSparePartId) {
        if (sparePartVendorIdsForSparePartId.length === 1) {
          return update(state, {
            vendors: {
              ids: {
                $set: state.vendors.ids.filter(id => id !== vendor_id),
              },
              sparePartVendorIdsByVendorId: {
                $unset: [vendor_id],
              },
            },
          });
        }
        return update(state, {
          vendors: {
            sparePartVendorIdsByVendorId: {
              [vendor_id]: {
                $apply: vendorId => {
                  return vendorId.filter(id => id !== sparePartVendorId);
                },
              },
            },
          },
        });
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_VENDOR_CREATED: {
      const { data } = action.payload;
      const { vendor_id } = data;
      return update(state, {
        vendors: {
          ids: {
            $apply: ids => {
              if (state.vendors.primaryVendorId === vendor_id) {
                return ids;
              }
              return uniq([vendor_id, ...state.vendors.ids]);
            },
          },
          sparePartVendorIdsByVendorId: {
            [vendor_id]: {
              $apply: vendorId => {
                if (vendorId) {
                  return update(vendorId, {
                    $push: [data.id],
                  });
                }
                return [data.id];
              },
            },
          },
        },
      });
    }

    case SDKReduxTypes.ASSET_CREATED: {
      const { data } = action.payload;
      if (data.spare_part_assets) {
        return update(state, {
          sparePartAssets: {
            ids: { $unshift: [data.spare_part_assets[0].id] },
            createdIds: { $unshift: [data.spare_part_assets[0].id] },
          },
        });
      } else return state;
    }

    case SDKReduxTypes.VENDOR_CREATED: {
      const { data } = action.payload;
      if (data.spare_part_vendors) {
        return update(state, {
          vendors: {
            ids: {
              $set: [data.id, ...state.vendors.ids],
            },
            sparePartVendorIdsByVendorId: {
              [data.id]: {
                $set: [data.spare_part_vendors[0].id],
              },
            },
          },
        });
      }
      return state;
    }

    case SDKReduxTypes.SPARE_PART_CREATED: {
      const { data } = action.payload;
      if (data.spare_part_assets) {
        return update(state, {
          sparePartAssets: {
            ids: { $unshift: [data.spare_part_assets[0].id] },
            createdIds: { $unshift: [data.spare_part_assets[0].id] },
          },
        });
      } else return state;
    }

    case SDKReduxTypes.SPARE_PART_ASSET_CREATED: {
      const { data } = action.payload;
      if (data.spare_part_id === state.id) {
        return update(state, {
          sparePartAssets: {
            ids: { $unshift: [data.id] },
            createdIds: { $unshift: [data.id] },
            totalEntries: { $apply: v => v + data.id },
          },
        });
      } else {
        return state;
      }
    }

    case SDKReduxTypes.SPARE_PART_ASSET_DELETED: {
      const { sparePartAssetId } = action.payload;
      return {
        ...state,
        sparePartAssets: {
          ...state.sparePartAssets,
          ids: state.sparePartAssets.ids.filter(id => id !== sparePartAssetId),
          createdIds: state.sparePartAssets.createdIds.filter(id => id !== sparePartAssetId),
        },
      };
    }

    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { sparePartId } = meta;
      if (state.id === sparePartId) {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.attachment_id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }

    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_SPARE_PART: {
      const { data, params } = action.payload;
      if (params.type === 'link') {
        return {
          ...state,
          attachments: {
            ...state.attachments,
            ids: [data.id, ...state.attachments.ids],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_MULTIPLE_SPARE_PARTS: {
      const { data: attachment, params } = action.payload;
      if (params.type !== 'link') return state;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids: [attachment.id, ...state.attachments.ids],
        },
      };
    }

    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids: state.attachments.ids.filter(id => id !== attachmentId),
        },
      };
    }

    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_SPARE_PART: {
      const { attachmentId, sparePartId } = action.payload;

      if (sparePartId !== state.id) return state;

      return {
        ...state,
        attachments: {
          ...state.attachments,
          ids: state.attachments.ids.filter(id => id !== attachmentId),
          createdIds: state.attachments.createdIds.filter(id => id !== attachmentId),
        },
      };
    }
    case SDKReduxTypes.PURCHASE_ORDER_CREATED: {
      const { data } = action.payload;
      return update(state, {
        purchaseOrders: {
          ids: { $unshift: [data.id] },
          totalEntries: { $apply: v => v + data.id },
        },
      });
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_DELETED: {
      const { sparePartWithdrawalId } = action.payload;

      return update(
        { ...state },
        {
          withdrawals: {
            ids: { $set: state.withdrawals.ids.filter(id => id !== sparePartWithdrawalId) },
            pagination: {
              totalEntries: v => v - 1,
            },
          },
        }
      );
    }

    default:
      return state;
  }
};
