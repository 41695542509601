import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { VendorsOperations } from 'state/ducks/vendors';
import { AuthSelectors } from 'state/ducks/auth';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getVendorMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.name',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.name);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.organization-number',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.organization_number);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.website',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.website);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.phone',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.phone);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.email',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.email);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.additional-information',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.additional_information);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getPurchaseOrderMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.purchase-order-currency',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.purchase_order_currency);
        },
      },
      {
        label: this.props.intl.formatMessage({ id: 'resources.vendor.purchase-order-email' }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.purchase_order_email);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.payment-term',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.default_purchase_order_payment_term_id);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.delivery-method',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.default_purchase_order_delivery_method_id);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getAddressMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.address1',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.address1);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.address2',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.address2);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.zip',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.zip);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.city',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.city);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.vendor.country',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.country);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderVendorFilters = () => {
    const sparePartItems = this.getVendorMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.vendors.filters.advanced.vendor.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderPurchaseOrderFilters = () => {
    if (this.props.hasProTier) {
      const sparePartItems = this.getPurchaseOrderMenuItems();
      if (sparePartItems.length === 0) {
        return null;
      }
      return (
        <SideModal.Container.Filter.Section>
          <SideModal.Container.Filter.Section.Header
            title={<FormattedMessage id="screens.vendors.filters.advanced.purchase-order-info.title" />}
          />
          <SideModal.Container.Filter.Section.Items>
            {sparePartItems.map(({ label, selected, onClick }) => (
              <SideModal.Container.Filter.Section.Items.Item
                label={label}
                selected={selected}
                onClick={onClick}
              />
            ))}
          </SideModal.Container.Filter.Section.Items>
        </SideModal.Container.Filter.Section>
      );
    }
    return null;
  };

  renderAddressFilters = () => {
    const sparePartItems = this.getAddressMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.vendors.filters.advanced.address.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [
      ...this.getVendorMenuItems(),
      ...this.getPurchaseOrderMenuItems(),
      ...this.getAddressMenuItems(),
    ];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return (
      <>
        {this.renderVendorFilters()}
        {this.renderPurchaseOrderFilters()}
        {this.renderAddressFilters()}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.spare-parts.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: VendorsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
