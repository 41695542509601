import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { ExportType } from 'sdk/Export';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { API } from 'sdk';
import PrintModal from 'views/scenes/WorkOrders/WorkOrderList/PrintModal';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './styles.module.scss';

class ConfigureEmailPrintModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showPrintModal: false,
      exclude_status: false,
      exclude_checklist: false,
      exclude_spare_parts: false,
      exclude_spare_part_locations: false,
      exclude_spent_times: false,
      exclude_costs: false,
      exclude_images: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        exclude_status,
        exclude_checklist,
        exclude_spare_parts,
        exclude_spare_part_locations,
        exclude_spent_times,
        exclude_costs,
        exclude_images,
      } = this.props;
      this.setState({
        open: true,
        exclude_checklist: exclude_checklist || false,
        exclude_status: exclude_status || false,
        exclude_spare_parts: exclude_spare_parts || false,
        exclude_spare_part_locations: exclude_spare_part_locations || false,
        exclude_spent_times: exclude_spent_times || false,
        exclude_costs: exclude_costs || false,
        exclude_images: exclude_images || false,
      });
    } else if (prevProps.open && !this.props.open) {
      this.setState({ open: false });
    }
  }

  renderChecklistOption = () => {
    const checklistInstances = this.props.checklistInstances || [];
    const checklistTemplateLinks = this.props.checklistTemplateLinks || [];
    if (checklistInstances.length > 0 || checklistTemplateLinks.length > 0) {
      return (
        <Field.Checkbox
          checked={!this.state.exclude_checklist}
          label={<FormattedMessage id="components.print-work-order-modal.checklist" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_checklist: !prevState.exclude_checklist,
            }));
          }}
        />
      );
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} width={500}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="components.work-order-email-modal.configure-pdf-modal-title" />}
            subtitle={
              <FormattedMessage id="components.work-order-email-modal.configure-pdf-modal-subtitle" />
            }
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Field.Checkbox.Group>
                    <Field.Checkbox
                      checked
                      label={<FormattedMessage id="components.print-work-order-modal.planned-work" />}
                      disabled
                    />
                    <Field.Checkbox
                      checked={!this.state.exclude_images}
                      label={<FormattedMessage id="components.print-work-order-modal.images" />}
                      onChange={() => {
                        this.setState(prevState => ({
                          exclude_images: !prevState.exclude_images,
                        }));
                      }}
                    />
                    <Field.Checkbox
                      checked={!this.state.exclude_status}
                      label={<FormattedMessage id="components.print-work-order-modal.status" />}
                      onChange={() => {
                        this.setState(prevState => ({
                          exclude_status: !prevState.exclude_status,
                        }));
                      }}
                    />
                    <Field.Checkbox
                      checked={!this.state.exclude_spent_times}
                      label={<FormattedMessage id="components.print-work-order-modal.spent-times" />}
                      onChange={() => {
                        this.setState(prevState => ({
                          exclude_spent_times: !prevState.exclude_spent_times,
                        }));
                      }}
                    />
                    <Field.Checkbox
                      checked={!this.state.exclude_spare_parts}
                      label={<FormattedMessage id="components.print-work-order-modal.spare-parts" />}
                      onChange={() => {
                        this.setState(prevState => ({
                          exclude_spare_parts: !prevState.exclude_spare_parts,
                        }));
                      }}
                    />
                    <div className={styles['indented']}>
                      <Field.Checkbox
                        checked={!this.state.exclude_spare_part_locations}
                        disabled={this.state.exclude_spare_parts}
                        label={
                          <FormattedMessage id="components.print-work-order-modal.spare-part-locations" />
                        }
                        onChange={() => {
                          this.setState(prevState => ({
                            exclude_spare_part_locations: !prevState.exclude_spare_part_locations,
                          }));
                        }}
                      />
                    </div>
                    {this.props.canViewWorkOrderCosts ? (
                      <Field.Checkbox
                        checked={!this.state.exclude_costs}
                        label={<FormattedMessage id="components.print-work-order-modal.costs" />}
                        onChange={() => {
                          this.setState(prevState => ({
                            exclude_costs: !prevState.exclude_costs,
                          }));
                        }}
                      />
                    ) : null}
                    {this.renderChecklistOption()}
                  </Field.Checkbox.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                label={'general.save'}
                onClick={() => {
                  const {
                    exclude_status,
                    exclude_spare_parts,
                    exclude_spare_part_locations,
                    exclude_spent_times,
                    exclude_costs,
                    exclude_images,
                    exclude_checklist,
                  } = this.state;
                  this.props.onSave({
                    exclude_status,
                    exclude_spare_parts,
                    exclude_spare_part_locations,
                    exclude_spent_times,
                    exclude_costs,
                    exclude_images,
                    exclude_checklist,
                  });
                  this.props.onClose();
                }}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
              <div className={styles['preview']}>
                <Button
                  label="components.print-work-order-modal.preview"
                  onClick={() => {
                    const {
                      exclude_status,
                      exclude_spare_parts,
                      exclude_spare_part_locations,
                      exclude_spent_times,
                      exclude_costs,
                      exclude_images,
                      exclude_checklist,
                    } = this.state;

                    this.setState({ open: false });
                    setTimeout(() => {
                      API.createExport(this.props.system.id, {
                        params: {
                          id: this.props.workOrderId,
                          exclude_status,
                          exclude_spare_parts,
                          exclude_spare_part_locations,
                          exclude_spent_times,
                          exclude_costs,
                          exclude_images,
                          exclude_checklist,
                        },
                        type: ExportType.WorkOrdersDetailsPdf,
                      }).then(({ data }) => {
                        this.setState({ showExportLoaderForId: data.id, showPrintModal: true });
                      });
                    }, 250);
                  }}
                />
              </div>
            </Button.Group>
          </Modal.Footer>
        </Modal>
        <PrintModal
          open={this.state.showPrintModal}
          detailOnly
          singleWorkOrderId={this.props.workOrderId}
          exportType={ExportType.WorkOrdersDetailsPdf}
          showExportLoaderForId={this.state.showExportLoaderForId}
          onClose={() => {
            this.setState({ showPrintModal: false });
            setTimeout(() => {
              this.setState({ open: true });
            }, 250);
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.workOrderId);
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    checklistInstances: EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances),
    checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
      state,
      workOrder.checklist_template_links
    ),
  };
}

export default connect(mapStateToProps)(ConfigureEmailPrintModal);
