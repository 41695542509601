import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { EstimatedTimeField } from 'views/components/WorkOrder';
import {
  Between,
  LessThan,
  LessThanOrEqual,
  MoreThan,
  MoreThanOrEqual,
  NotExact,
  Exact,
  Exists,
  NotExists,
} from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Number/comparators';
import { Button } from 'views/components/Shared/General';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import styles from './style.module.scss';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class EstimatedMinutes extends Component {
  constructor(props) {
    super(props);
    const { value, comparator } = props.filter.estimated_minutes || props.initialFilter.estimated_minutes;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        estimated_minutes: null,
        from_estimated_minutes: null,
        to_estimated_minutes: null,
        initialFilter: props.filter,
        comparator,
        hasValue: value,
      };
    } else if (comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
      const from_estimated_minutes = value.split('.', 1)[0];
      const to_estimated_minutes = value.substring(value.indexOf('.') + 3);
      this.state = {
        estimated_minutes: null,
        hasValue: value,
        initialFilter: props.filter,
        comparator,
        from_estimated_minutes,
        to_estimated_minutes,
      };
    } else {
      this.state = {
        hasValue: value,
        from_estimated_minutes: null,
        to_estimated_minutes: null,
        initialFilter: props.filter,
        estimated_minutes: value,
        comparator,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      comparator: prevComparator,
      hasValue: prevHasValue,
      from_estimated_minutes: prevFromMins,
      to_estimated_minutes: fromMins,
      estimated_minutes: prevMins,
    } = prevState;
    const { comparator, hasValue, from_estimated_minutes, to_estimated_minutes, estimated_minutes } =
      this.state;
    if (
      prevComparator !== comparator ||
      prevHasValue !== hasValue ||
      prevFromMins !== from_estimated_minutes ||
      fromMins !== to_estimated_minutes ||
      prevMins !== estimated_minutes
    ) {
      if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
        this.props.addFilter({
          key: FILTER_TYPES.EstimatedMinutes,
          data: {
            work_order: {
              ...this.props.filter,
              [FILTER_TYPES.EstimatedMinutes]: {
                comparator: this.state.comparator,
                value: this.state.hasValue,
              },
            },
          },
        });
      } else if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
        this.props.addFilter({
          key: FILTER_TYPES.EstimatedMinutes,
          data: {
            work_order: {
              ...this.props.filter,
              [FILTER_TYPES.EstimatedMinutes]: {
                comparator: this.state.comparator,
                value: `${this.state.from_estimated_minutes}...${this.state.to_estimated_minutes}`,
              },
            },
          },
        });
      } else {
        this.props.addFilter({
          key: FILTER_TYPES.EstimatedMinutes,
          data: {
            work_order: {
              ...this.props.filter,
              [FILTER_TYPES.EstimatedMinutes]: {
                comparator: this.state.comparator,
                value: this.state.estimated_minutes,
              },
            },
          },
        });
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Between) {
      return this.state.from_estimated_minutes != null && this.state.to_estimated_minutes != null;
    }
    return this.state.estimated_minutes != null;
  };

  /*
    Render functions
  */

  renderExactComparator = () => {
    return (
      <Exact
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exact}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exact });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </Exact>
    );
  };

  renderNotExactComparator = () => {
    return (
      <NotExact
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.NotExact}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.NotExact });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </NotExact>
    );
  };

  renderLessThanComparator = () => {
    return (
      <LessThan
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.LessThan}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.LessThan });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </LessThan>
    );
  };

  renderLessThanOrEqualComparator = () => {
    return (
      <LessThanOrEqual
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.LessThanOrEqual });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </LessThanOrEqual>
    );
  };

  renderGreaterThanComparator = () => {
    return (
      <MoreThan
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.MoreThan}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.MoreThan });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </MoreThan>
    );
  };

  renderGreaterThanOrEqualComparator = () => {
    return (
      <MoreThanOrEqual
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.MoreThanOrEqual });
        }}
      >
        <EstimatedTimeField
          estimatedMinutes={this.state.estimated_minutes}
          onChange={estimated_minutes => this.setState({ estimated_minutes })}
        />
      </MoreThanOrEqual>
    );
  };

  renderBetweenComparator = () => {
    return (
      <Between
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Between}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Between });
        }}
      >
        <>
          <div>
            <EstimatedTimeField
              estimatedMinutes={this.state.from_estimated_minutes}
              onChange={from_estimated_minutes => this.setState({ from_estimated_minutes })}
            />
          </div>
          <div className={styles['and']}>
            <FormattedMessage id="general.and" />
          </div>
          <div>
            <EstimatedTimeField
              estimatedMinutes={this.state.to_estimated_minutes}
              onChange={to_estimated_minutes => this.setState({ to_estimated_minutes })}
            />
          </div>
        </>
      </Between>
    );
  };

  renderExistsComparator = () => {
    return (
      <Exists
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === true
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: true });
        }}
      />
    );
  };

  renderNotExistsComparator = () => {
    return (
      <NotExists
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === false
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: false });
        }}
      />
    );
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={this.props.showAppliedFilters}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (
              isEqual(this.props.initialFilter.estimated_minutes, this.state.initialFilter.estimated_minutes)
            ) {
              this.props.removeFilter({
                key: FILTER_TYPES.EstimatedMinutes,
                data: {
                  work_order: {
                    ...this.props.filter,
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.EstimatedMinutes,
                data: {
                  ...this.props.filter,
                  estimated_minutes: {
                    ...this.state.initialFilter.estimated_minutes,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order.estimated-time" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          {this.renderExactComparator()}
          {this.renderNotExactComparator()}
          {this.renderLessThanComparator()}
          {this.renderLessThanOrEqualComparator()}
          {this.renderGreaterThanComparator()}
          {this.renderGreaterThanOrEqualComparator()}
          {this.renderBetweenComparator()}
          {this.renderExistsComparator()}
          {this.renderNotExistsComparator()}
          {this.renderApplyFiltersButton()}
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedMinutes);

EstimatedMinutes.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

EstimatedMinutes.defaultProps = {
  filter: {
    estimated_minutes: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
      value: null,
    },
  },
  initialFilter: {
    estimated_minutes: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
      value: null,
    },
  },
};
