import React from 'react';
import { connect } from 'react-redux';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';

const AssetContainer = ({ asset }) => {
  return (
    <>
      <div>
        <AssetTitle id={asset.id} />
      </div>
      {asset.tree_path.length === 0 ? null : <TreePath assetId={asset.id} />}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AssetContainer);
