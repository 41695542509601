import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import ReservedSparePartListItem from './ReservedSparePartListItem';
import { EntitySelectors } from 'sdk/State/entities';

class SpareParts extends Component {
  state = {
    createdIds: {},
  };

  render = () => {
    if (this.props.loading) {
      return <ReservedSparePartListItem loading />;
    }
    return this.props.sparePartReservations.map(sparePartReservation => (
      <ReservedSparePartListItem
        key={sparePartReservation.id}
        sparePartReservation={sparePartReservation}
        new={this.state.createdIds[sparePartReservation.id]}
      />
    ));
  };
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderId = ownProps.match.params.id;

  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, recurringMaintenance.spare_part_reservations) || [],
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      sparePartReservations:
        EntitySelectors.getSparePartReservations(state, workOrder.spare_part_reservations) || [],
    };
  }
}

export default withRouter(connect(mapStateToProps)(SpareParts));
