const DOMAIN_PREFIX = 'auth/';

const PREPARE_AUTHENTICATED_USER = `${DOMAIN_PREFIX}prepare_authenticated_user`;
const PREPARE_AUTHENTICATED_USER_ERROR = `${DOMAIN_PREFIX}prepare_authenticated_user_error`;
const PREPARE_AUTHENTICATED_USER_SUCCESS = `${DOMAIN_PREFIX}prepare_authenticated_user_success`;
const UNAUTHENTICATE_USER = `${DOMAIN_PREFIX}unauthenticate_user`;

const AUTHENTICATE_GROUP_SUCCESS = `${DOMAIN_PREFIX}authenticate_group_success`;
const UNAUTHENTICATE_GROUP = `${DOMAIN_PREFIX}unauthenticate_group`;

const SET_DEPRECATED_VERSION = `${DOMAIN_PREFIX}set_deprecated_version`;

export default {
  UNAUTHENTICATE_USER,
  PREPARE_AUTHENTICATED_USER,
  PREPARE_AUTHENTICATED_USER_ERROR,
  PREPARE_AUTHENTICATED_USER_SUCCESS,
  AUTHENTICATE_GROUP_SUCCESS,
  SET_DEPRECATED_VERSION,
  UNAUTHENTICATE_GROUP,
};
