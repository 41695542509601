import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Content from './Content';
import Header from './Header';

export default () => (
  <PerfectScrollbar>
    <Header />
    <Content />
  </PerfectScrollbar>
);
