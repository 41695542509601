import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, EmptyDataSet, NewInlineModal } from 'views/components/Shared/General';
import GroupSmall from 'assets/images/EmptyDataSet/GroupSmall.png';
import styles from './styles.module.scss';

class SparePartUnitField extends Component {
  state = {
    showDropdown: false,
  };

  renderStockUnitItems = () => {
    if (this.props.sparePartUnits.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.subtitle" />
            }
            image={GroupSmall}
            tiny
          />
        </div>
      );
    }
    return (
      <>
        {this.props.sparePartUnit ? (
          <>
            <NewInlineModal.Dropdown.Item
              onClick={e => {
                this.setState({ showDropdown: false });
                this.props.onClear();
              }}
            >
              <FormattedMessage id="general.clean" />
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Separator />
          </>
        ) : null}

        {this.props.sparePartUnits.map(sparePartUnit => (
          <NewInlineModal.Dropdown.Item
            key={sparePartUnit.id}
            selected={this.props.value === sparePartUnit.id}
            onClick={e => {
              this.setState({ showDropdown: false });
              this.props.onChange(sparePartUnit);
            }}
          >
            {sparePartUnit.title}
          </NewInlineModal.Dropdown.Item>
        ))}
      </>
    );
  };

  render() {
    return (
      <>
        <Field label={<FormattedMessage id="resources.spare-part.unit" />}>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() =>
              this.setState(prevState => ({
                showDropdown: !prevState.showDropdown,
              }))
            }
          >
            <Field.Resource
              clearable
              angleDown
              value={this.props.sparePartUnit ? this.props.sparePartUnit.title : ''}
              onClear={this.props.onClear}
            />
          </div>
        </Field>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropdown}
          onClose={() => this.setState({ showDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderStockUnitItems()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(SparePartUnitField);

SparePartUnitField.propTypes = {
  value: PropTypes.string,
};
