import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AuthSelectors } from 'state/ducks/auth';
import { RequestsOperations, RequestsSelectors } from 'state/ducks/requests';
import { LIST_TYPES } from 'sdk/Request';
import { ListLayout } from 'views/components/Shared/Layout';
import { Button, Menu, Icon } from 'views/components/Shared/General';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import styles from './style.module.scss';

class ContentMenu extends Component {
  changeQueryParams = obj => {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        ...obj,
      })}`
    );
  };

  changeList = list => {
    const { list: listInUrl } = queryString.parse(this.props.location.search);
    if (list === listInUrl) return;
    this.props.setLoading();
    let urlParams = { list, request_id: null };
    this.changeQueryParams(urlParams);
  };

  renderCreateButton = () => {
    if (this.props.canCreateRequests) {
      return (
        <>
          <Button primary fullWidth label="screens.requests.new" onClick={this.props.prepareNewRequest} />
          <Menu.Separator />
        </>
      );
    }
    return null;
  };

  renderWorkRequesterMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <Menu.Item
        selected={list === LIST_TYPES.MyCreated}
        title={<FormattedMessage id="screens.requests.lists.my-created" />}
        onClick={() => {
          this.changeList(LIST_TYPES.MyCreated);
        }}
      />
    );
  };

  renderOperatorAndProductionSupervisorMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <>
        {this.renderRequestsToApprove()}
        <Menu.Item
          selected={list === LIST_TYPES.Open}
          title={<FormattedMessage id="screens.requests.lists.open" />}
          onClick={() => {
            this.changeList(LIST_TYPES.Open);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.Closed}
          title={<FormattedMessage id="screens.requests.lists.closed" />}
          onClick={() => {
            this.changeList(LIST_TYPES.Closed);
          }}
        />
        <Menu.Separator />
        <Menu.Item
          selected={list === LIST_TYPES.MyCreated}
          title={<FormattedMessage id="screens.requests.lists.my-created" />}
          onClick={() => {
            this.changeList(LIST_TYPES.MyCreated);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.All}
          title={<FormattedMessage id="screens.requests.lists.all" />}
          onClick={() => {
            this.changeList(LIST_TYPES.All);
          }}
        />
      </>
    );
  };

  renderRequestsToApprove = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { isProductionSupervisor, canAdministrateRequests } = this.props;
    const { request_production_supervisor_approval_activated } = this.props.settings;
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    const isProductionSupervisorWithProductionSupervisorAssets =
      isProductionSupervisor && has_asset_production_supervisors;
    if (request_production_supervisor_approval_activated) {
      if (canAdministrateRequests || isProductionSupervisorWithProductionSupervisorAssets) {
        return (
          <>
            <Menu.Item
              selected={list === LIST_TYPES.ToApprove}
              title={
                <>
                  <div>
                    <FormattedMessage id="screens.requests.lists.for-approval-by-title" />
                  </div>
                  <div className={styles['subtitle']}>
                    <FormattedMessage id="screens.requests.lists.for-approval-by-subtitle" />
                  </div>
                </>
              }
              onClick={() => {
                this.changeList(LIST_TYPES.ToApprove);
              }}
            />
            <Menu.Separator />
          </>
        );
      }
    }
    return null;
  };

  renderAdministrateMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <>
        <Menu.Item
          selected={list === LIST_TYPES.ToPlan}
          title={<FormattedMessage id="screens.requests.lists.to-plan" />}
          onClick={() => {
            this.changeList(LIST_TYPES.ToPlan);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.WorkOrderCreated}
          title={<FormattedMessage id="screens.requests.lists.work-order-created" />}
          onClick={() => {
            this.changeList(LIST_TYPES.WorkOrderCreated);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.WorkOrderCompleted}
          title={<FormattedMessage id="screens.requests.lists.work-order-completed" />}
          onClick={() => {
            this.changeList(LIST_TYPES.WorkOrderCompleted);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.Archive}
          title={<FormattedMessage id="screens.requests.lists.archived" />}
          onClick={() => {
            this.changeList(LIST_TYPES.Archive);
          }}
        />
        <Menu.Separator />
        {this.renderRequestsToApprove()}
        <Menu.Item
          selected={list === LIST_TYPES.MyCreated}
          title={<FormattedMessage id="screens.requests.lists.my-created" />}
          onClick={() => {
            this.changeList(LIST_TYPES.MyCreated);
          }}
        />
        <Menu.Item
          selected={list === LIST_TYPES.All}
          title={<FormattedMessage id="screens.requests.lists.all" />}
          onClick={() => {
            this.changeList(LIST_TYPES.All);
          }}
        />
      </>
    );
  };

  renderMenu = () => {
    const { canAdministrateRequests, isOperator, isProductionSupervisor, isViewOnly } = this.props;

    if (isOperator || isProductionSupervisor) {
      return this.renderOperatorAndProductionSupervisorMenu();
    } else if (canAdministrateRequests || isViewOnly) {
      return this.renderAdministrateMenu();
    } else {
      return this.renderWorkRequesterMenu();
    }
  };

  render() {
    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <ListLayout.Content.Menu.Content>
            {this.renderCreateButton()}
            {this.renderMenu()}
          </ListLayout.Content.Menu.Content>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    isOperator: AuthSelectors.isOperator(state),
    filters: RequestsSelectors.getFilters(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canCreateRequests: AuthSelectors.canCreateRequests(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      prepareNewRequest: RequestsOperations.prepareNewRequest,
      setLoading: RequestsOperations.setLoading,
    },
    dispatch
  );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentMenu));
