import types from './types';

const setAssignees = payload => ({
  type: types.SET_ASSIGNEES,
  payload: payload,
});

const showListBar = payload => ({
  type: types.SHOW_LIST_BAR,
  payload,
});

const hideListBar = () => ({
  type: types.HIDE_LIST_BAR,
});

const addInstancesForCalendar = payload => ({
  type: types.ADD_INSTANCES_FOR_CALENDAR,
  payload,
});

const removeInstancesFromCalendar = payload => ({
  type: types.REMOVE_INSTANCES_FROM_CALENDAR,
  payload,
});

const beginDrag = payload => ({
  type: types.BEGIN_DRAG,
  payload: payload,
});

const endDrag = payload => ({
  type: types.END_DRAG,
  payload: payload,
});

const beginDragFromToolbox = payload => ({
  type: types.BEGIN_DRAG_FROM_TOOLBOX,
  payload: payload,
});

const endDragFromToolbox = payload => ({
  type: types.END_DRAG_FROM_TOOLBOX,
  payload: payload,
});

const setCounts = payload => ({
  type: types.SET_COUNTS,
  payload: payload,
});

const showPreviewWorkOrderOverlay = payload => ({
  type: types.SHOW_WORK_ORDER_PREVIEW_OVERLAY,
  payload: payload,
});

const hidePreviewWorkOrderOverlay = () => ({
  type: types.HIDE_WORK_ORDER_PREVIEW_OVERLAY,
});

const showPreviewRequestOverlay = payload => ({
  type: types.SHOW_REQUEST_PREVIEW_OVERLAY,
  payload: payload,
});

const hidePreviewRequestOverlay = () => ({
  type: types.HIDE_REQUEST_PREVIEW_OVERLAY,
});

const addAssigneesForInstance = payload => ({
  type: types.ADD_ASSIGNEES_FOR_INSTANCE,
  payload: payload,
});

const removeAssigneesFromInstance = payload => ({
  type: types.REMOVE_ASSIGNEES_FROM_INSTANCE,
  payload: payload,
});

const fetchDataBetweenDatesSuccess = payload => ({
  type: types.FETCH_DATA_BETWEEN_DATES_SUCCESS,
  payload: payload,
});

const hoverCalendarInstance = payload => ({
  type: types.HOVER_CALENDAR_INSTANCE,
  payload: payload,
});

const addAssigneeToCalendarInstance = payload => ({
  type: types.SET_ADDITIONAL_ASSIGNEES,
  payload: payload,
});

const updateInstance = payload => ({
  type: types.UPDATE_INSTANCE,
  payload: payload,
});

const fetchWorkOrdersToPlanSuccess = payload => ({
  type: types.FETCH_WORK_ORDERS_TO_PLAN_SUCCESS,
  payload: payload,
});

const fetchMeterBasedWorkOrdersToPlanSuccess = payload => ({
  type: types.FETCH_METER_BASED_WORK_ORDERS_TO_PLAN_SUCCESS,
  payload: payload,
});

const fetchWorkOrdersAwaitingDeliverySuccess = payload => ({
  type: types.FETCH_WORK_ORDERS_AWAITING_DELIVERY_SUCCESS,
  payload: payload,
});

const fetchOverdueWorkOrdersSuccess = payload => ({
  type: types.FETCH_WORK_ORDERS_OVERDUE_SUCCESS,
  payload: payload,
});

const fetchRequestsSuccess = payload => ({
  type: types.FETCH_REQUESTS_SUCCESS,
  payload: payload,
});

export default {
  showListBar,
  fetchOverdueWorkOrdersSuccess,
  addInstancesForCalendar,
  fetchRequestsSuccess,
  fetchWorkOrdersAwaitingDeliverySuccess,
  removeInstancesFromCalendar,
  hideListBar,
  beginDrag,
  endDrag,
  addAssigneesForInstance,
  removeAssigneesFromInstance,
  hidePreviewWorkOrderOverlay,
  showPreviewWorkOrderOverlay,
  showPreviewRequestOverlay,
  hidePreviewRequestOverlay,
  setAssignees,
  setCounts,
  hoverCalendarInstance,
  fetchDataBetweenDatesSuccess,
  addAssigneeToCalendarInstance,
  updateInstance,
  fetchMeterBasedWorkOrdersToPlanSuccess,
  fetchWorkOrdersToPlanSuccess,
  beginDragFromToolbox,
  endDragFromToolbox,
};
