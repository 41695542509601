import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetsOperations, AssetsSelectors } from 'state/ducks/assets';
import Text from './Text';
import Date from './Date';
import Dropdown from './Dropdown';
import { FILTER_TYPES } from 'views/scenes/Assets/TreeAndList/List/FilterModal';

class AssetField extends Component {
  render() {
    const { type } = this.props.templateField;
    if (type === 'text' || type === 'textarea') {
      return <Text templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />;
    } else if (type === 'valuelist') {
      return (
        <Dropdown templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />
      );
    } else if (type === 'date') {
      return <Date templateField={this.props.templateField} id={this.props.id} filter={this.props.filter} />;
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: AssetsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    filter: AssetsSelectors.getFiltersForKey(state, FILTER_TYPES.AssetField),
    templateField: EntitySelectors.getTemplateField(state, ownProps.templateFieldId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetField);
