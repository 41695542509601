import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import NavigateButton from './NavigateButton';
import styles from './style.module.scss';
import { Button, Icon } from 'views/components/Shared/General';
import AnimateHeight from 'react-animate-height';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  wrapInLink = children => {
    if (!this.props.linkTo) return children;
    if (this.props.disabled) return children;
    return <Link to={this.props.linkTo}>{children}</Link>;
  };

  renderNumber = () => {
    if(this.props.numberLoading) {
      return (
        <div style={{ width: 24, height: 12, position: 'relative', top: -2 }}>
          <ContentLoader 
            viewBox="0 0 24 12" 
            width={24}
            height={12}
            preserveAspectRatio="xMinYMin"
            primaryColor="#00000014"
            secondaryColor="#0000000A"
          >
            <rect x="0" y="0" rx="2" ry="2" width="24" height="12" />
          </ContentLoader>
        </div>
      )
    }
    
    if (this.props.number != null) {
      return <div className={styles['number']}>{this.props.number}</div>;
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.subtitle) {
      return <div className={styles['subtitle']}>{this.props.subtitle}</div>;
    }
    return null;
  };

  renderTitle = () => {
    return (
      <>
        <div className={styles['text-container']} style={{ paddingLeft: this.props.treeStructure && '2px' }}>
          <div className={styles['title']}>{this.props.title}</div>
          {this.renderSubtitle()}
        </div>
      </>
    );
  };

  renderLoader = () => {
    let classNames = [styles['item'], styles['loading']];
    return (
      <div className={classNames.join(' ')}>
        <div style={{ width: 115, height: 16 }}>
          <ContentLoader primaryColor="#F5F5F5" secondaryColor="#EFEFEF" width={115} height={16}>
            <rect x="0" y="0" rx="2" ry="2" width="115" height="16" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  renderExpandableIcon = () => {
    if (this.props.expandable) {
      return (
        <NavigateButton
          onClick={() => {
            this.setState(prevState => ({
              isExpanded: !prevState.isExpanded,
            }));
          }}
        />
      );
    }

    return null;
  };

  renderExpandableComponent = () => {
    if (this.props.expandable) {
      return (
        <AnimateHeight duration={250} height={this.state.isExpanded ? 'auto' : 0}>
          <div className={styles['expanded-content']}>{this.props.expandableComponent}</div>
        </AnimateHeight>
      );
    }

    return null;
  };

  renderItem = () => {
    let classNames = [styles['item']];
    let additionalStyles = {};

    if (this.props.selected) classNames = [...classNames, styles['selected']];

    if (this.props.blueText) {
      classNames = [...classNames, styles['blue-text']];
    }

    if (this.props.red) {
      classNames = [...classNames, styles['red']];
    } else {
      classNames = [...classNames, styles['blue']];
    }

    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }

    if (this.state.isExpanded) {
      classNames = [...classNames, styles['expanded']];
    }

    if (this.props.treeStructure) {
      let additionalPadding = this.props.expandable ? 2 : 30;
      let indentPadding = this.props.indent === 1 ? 6 : this.props.indent * 10;
      additionalStyles = { ...additionalStyles, paddingLeft: additionalPadding + indentPadding };
    }

    return (
      <>
        {this.wrapInLink(
          <div
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            className={classNames.join(' ')}
            style={additionalStyles}
          >
            {this.renderExpandableIcon()}
            {this.renderTitle()}
            {this.renderNumber()}
            {this.props.rightComponent}
          </div>
        )}
        {this.renderExpandableComponent()}
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }
    return this.renderItem();
  }
}

Item.NavigateButton = NavigateButton;
export default Item;

Item.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  linkTo: PropTypes.any,
  selected: PropTypes.bool,
  number: PropTypes.number,
  loading: PropTypes.bool,
  indent: PropTypes.number,
  expandable: PropTypes.bool,
  treeStructure: PropTypes.bool,
};

Item.defaultProps = {
  onClick: () => {},
  disabled: false,
  selected: false,
  number: null,
  loading: false,
  indent: 0,
  expandable: false,
  treeStructure: false,
};
