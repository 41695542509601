import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, InlineModal, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

const ModalDropdownContent = {
  ShowOptions: 'showOptions',
  EditReserved: 'editReserved',
  ShowDelete: 'showDelete',
};

class MoreOptionsInlineModal extends Component {
  state = {
    showOptionsInlineModal: false,
    dropdownOption: ModalDropdownContent.ShowOptions,
    quantity: '',
    isSaving: false,
  };

  shouldComponentUpdate(prevProps, prevState) {
    if (!prevState.showOptionsInlineModal && !this.state.showOptionsInlineModal) {
      return false;
    }
    return true;
  }

  updateSparePartReservation = () => {
    this.setState({ isSaving: true });
    this.props
      .updateSparePartReservation(this.props.sparePartReservation.id, {
        quantity: this.state.quantity,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);

        this.setState({ isSaving: false, showOptionsInlineModal: false });
      });
  };

  deleteSparePartReservation = () => {
    this.setState({ isDeleting: true });
    this.props.deleteSparePartReservation(this.props.sparePartReservation.id).then(data => {
      toast(
        <ToastMessage
          success
          text={
            <FormattedMessage id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.delete-work-order-spare-part-success" />
          }
        />
      );
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.quantity && !this.state.isSaving) {
      this.updateSparePartReservation();
    }
  };

  renderOptions = () => {
    return (
      <InlineModal.Body dropdown width={300}>
        <Link to={`/spare-parts/${this.props.sparePart.id}`}>
          <InlineModal.ListItem
            icon={<Icon regular type="arrow-alt-right" />}
            title={
              <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.go-to-spare-part" />
            }
          />
        </Link>
        <InlineModal.Separator />
        <InlineModal.ListItem
          icon="pen"
          iconThickness="regular"
          title={
            <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.edit-reservation" />
          }
          onClick={() => {
            this.setState({
              dropdownOption: ModalDropdownContent.EditReserved,
              quantity: this.props.sparePartReservation.quantity,
            });
          }}
        />
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          destructive
          title={
            <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.remove-reservation" />
          }
          onClick={() => {
            this.setState({ dropdownOption: ModalDropdownContent.ShowDelete });
          }}
        />
      </InlineModal.Body>
    );
  };

  renderEditReserved = () => {
    return (
      <InlineModal.Body width={390} padding="30px 40px">
        <Field
          view={false}
          label={
            <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.reserved-quantity" />
          }
        >
          <Field.Text
            onKeyPress={this.handleKeyPress}
            rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
            autoFocus
            value={this.state.quantity}
            onChange={quantity => {
              this.setState({
                quantity,
              });
            }}
          />
        </Field>
        <div className={styles['group']}>
          <Button.Group>
            <Button
              primary
              small
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.updateSparePartReservation}
              disabled={!this.state.quantity}
            />
            <Button
              small
              label="general.cancel"
              onClick={() => this.setState({ showOptionsInlineModal: false })}
            />
          </Button.Group>
        </div>
      </InlineModal.Body>
    );
  };

  renderDeleteContent = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="general.delete-confirm.title" />}
          onClose={() => {
            this.setState({ showOptionsInlineModal: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.deleteSparePartReservation}>
            <p>
              <FormattedMessage id="general.delete-confirm.subtitle" />
            </p>
            <p className={styles['reservation-text']}>
              <FormattedMessage id="screens.work-order.reserved-spare-parts.dropdown-options-inline-modal.withdrawals-will-not-be-deleted" />
            </p>
          </InlineModal.Delete>
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderInlineModalBodyContent = () => {
    switch (this.state.dropdownOption) {
      case ModalDropdownContent.ShowOptions:
        return this.renderOptions();
      case ModalDropdownContent.EditReserved:
        return this.renderEditReserved();
      case ModalDropdownContent.ShowDelete:
        return this.renderDeleteContent();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showOptionsInlineModal: !prevState.showOptionsInlineModal,
              dropdownOption: ModalDropdownContent.ShowOptions,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showOptionsInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showOptionsInlineModal: false })}
          position="right"
        >
          <React.Fragment>{this.renderInlineModalBodyContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartReservation: SDKReduxOperations.updateSparePartReservation,
      deleteSparePartReservation: SDKReduxOperations.deleteSparePartReservation,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartReservation.spare_part_id);
  return {
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    images: EntitySelectors.getImages(state, sparePart.images),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MoreOptionsInlineModal)));
