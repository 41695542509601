import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Prompt, Switch, Route, Redirect } from 'react-router-dom';
import { matchPath } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { NewTab, Toggle, Banner, WhiteCard } from 'views/components/Shared/General';
import { Section, TabContainer, UnsavedChangesBottomBar } from 'views/scenes/Settings/components';
import App from './App';
import Email from './Email';
import Desktop from './Desktop';
import styles from './style.module.scss';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingUserSettings: props.userSettings,
      editingUserSettingsBeforeEdit: props.userSettings,
      showBottomSaveBar: false,
      isSavingActivateNotifications: false,
    };
  }

  updateUserSettings = () => {
    this.setState({ isSaving: true });
    this.props
      .updateUserSettings(
        this.props.currentSystem.id,
        this.props.currentUser.id,
        HelperFunctions.getDataChanges(
          this.state.editingUserSettings,
          this.state.editingUserSettingsBeforeEdit
        )
      )
      .then(() => {
        this.setState({
          isSaving: false,
          showBottomSaveBar: false,
          editingUserSettings: this.props.userSettings,
          editingUserSettingsBeforeEdit: this.props.userSettings,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  changeUserSettingsValue = params => {
    this.setState(
      {
        editingUserSettings: { ...this.state.editingUserSettings, ...params },
      },
      () => {
        const amountOfChangedProperties = Object.keys(
          HelperFunctions.getDataChanges(
            this.state.editingUserSettings,
            this.state.editingUserSettingsBeforeEdit
          )
        ).length;
        this.setState({ showBottomSaveBar: amountOfChangedProperties > 0 });
      }
    );
  };

  toggleActivateNotifications = params => {
    this.setState({ isSavingActivateNotifications: true });
    this.props
      .updateUserSettings(this.props.currentSystem.id, this.props.currentUser.id, params)
      .then(() => {
        this.setState({ isSavingActivateNotifications: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSavingActivateNotifications: false });
      });
  };

  routeIsActive = path => {
    return (
      matchPath(this.props.location.pathname, {
        path: path,
        exact: true,
      }) != null
    );
  };

  renderSiteBanner = () => {
    if (this.props.systems.length > 1) {
      return (
        <div className={styles['banner-container']}>
          <Banner orange>
            <FormattedMessage
              id="screens.settings.notifications.activate-for-site-text"
              values={{
                system: <span className={styles['site-name']}>{this.props.currentSystem.name}</span>,
              }}
            />
          </Banner>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <WhiteCard>
        <Prompt
          when={this.state.showBottomSaveBar}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Section
          header
          title={
            <div className={styles['title']}>
              <div>
                <FormattedMessage id="screens.settings.notifications.title" />
              </div>
              <div className={styles['toggle']}>
                <Toggle
                  checked={this.props.userSettings.notification_activated}
                  loading={this.state.isSavingActivateNotifications}
                  onClick={() => {
                    this.toggleActivateNotifications({
                      notification_activated: !this.props.userSettings.notification_activated,
                    });
                  }}
                />
              </div>
            </div>
          }
          subtitle={
            <FormattedMessage
              id="screens.settings.notifications.activate-subtitle"
              values={{ system: this.props.currentSystem.name }}
            />
          }
        />
        {this.renderSiteBanner()}
        <div className={this.props.userSettings.notification_activated === false ? styles['inactive'] : ''}>
          <TabContainer>
            <NewTab.Container>
              <NewTab
                icon="envelope"
                iconThickness="regular"
                selected={this.routeIsActive('/settings/notifications/email')}
                linkTo="/settings/notifications/email"
              >
                <FormattedMessage id="screens.settings.notifications.tabs.email" />
              </NewTab>
              <NewTab
                icon="browser"
                iconThickness="regular"
                selected={this.routeIsActive('/settings/notifications/desktop')}
                linkTo="/settings/notifications/desktop"
              >
                <FormattedMessage id="screens.settings.notifications.tabs.desktop" />
              </NewTab>
              <NewTab
                icon="mobile-alt"
                selected={this.routeIsActive('/settings/notifications/app')}
                linkTo="/settings/notifications/app"
              >
                <FormattedMessage id="screens.settings.notifications.tabs.app" />
              </NewTab>
            </NewTab.Container>
          </TabContainer>
          <Switch>
            <Route
              exact
              path="/settings/notifications"
              render={() => <Redirect to="/settings/notifications/email" />}
            />
            <Route
              path="/settings/notifications/email"
              render={() => (
                <Email
                  editingUserSettings={this.state.editingUserSettings}
                  onChange={params => this.changeUserSettingsValue(params)}
                />
              )}
            />
            <Route
              path="/settings/notifications/desktop"
              render={() => (
                <Desktop
                  editingUserSettings={this.state.editingUserSettings}
                  onChange={params => this.changeUserSettingsValue(params)}
                />
              )}
            />
            <Route
              path="/settings/notifications/app"
              render={() => (
                <App
                  editingUserSettings={this.state.editingUserSettings}
                  onChange={params => this.changeUserSettingsValue(params)}
                />
              )}
            />
          </Switch>
        </div>
        <UnsavedChangesBottomBar
          show={this.state.showBottomSaveBar}
          amountOfChanges={this.state.amountOfChangedProperties}
          loading={this.state.isSaving}
          onClose={() => this.setState({ showBottomSaveBar: false })}
          onSave={this.updateUserSettings}
        />
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettings: SDKReduxOperations.updateUserSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    systems: AuthSelectors.getSystems(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    userSettings: AuthSelectors.getCurrentUserSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Notifications));
