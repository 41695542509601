import React from 'react';
import styles from './styles.module.scss';

const Mode = ({ selected, icon, label, onClick }) => {
  let classNames = [styles['print-mode']];
  if (selected) {
    classNames = [...classNames, styles['selected-mode']];
  } else {
    classNames = [...classNames, styles['not-selected-mode']];
  }
  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      <div className={styles['icon']}>{icon}</div>
      <div className={styles['label']}>{label}</div>
    </div>
  );
};

export default Mode;
