import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import ManageAssignees from './ManageAssignees';
import styles from './style.module.scss';

export default class SettingsModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={921} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.calendar-settings-modal.title" />}
          onClose={this.props.onClose}
        />
        <div className={styles['modal-content']}>
          <div className={styles['container']}>
            <div className={styles['left-panel']}>
              <div className={`${styles['button']} ${styles['selected']}`}>
                <FormattedMessage id="components.calendar-settings-modal.show-assignees" />
              </div>
            </div>
            <div className={styles['content-container']}>
              <ManageAssignees />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
