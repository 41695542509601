import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeSparePart, normalizeStocktakingRow } from 'sdk/Schemas';

const initiateWithStocktakingId = stocktakingId => dispatch => {
  dispatch(actions.initiateWithStocktakingId({ stocktakingId }));
};

const clearSpareParts = () => dispatch => {
  dispatch(actions.clearSpareParts());
};

const hideCompletedCard = () => dispatch => {
  dispatch(actions.hideCompletedCard());
};

const fetchSparePartsForStocktaking = (stocktakingId, params = {}, config) => dispatch => {
  if (params.paginate_from == null) {
    dispatch(actions.setSparePartLoading(true));
  } else {
    dispatch(actions.setSparePartLoadingMore(true));
  }
  return API.listSparePartsForStocktaking(stocktakingId, { ...params, page_size: 20 }, config).then(res => {
    const stocktakingRows = res.data
      .map(spare_part => spare_part.stocktaking_row)
      .filter(stocktaking_row => stocktaking_row != null);

    const { entities: sparePartEntities, result: sparePartResult } = normalizeSparePart(res.data);
    const { entities: stocktakingRowEntities } = normalizeStocktakingRow(stocktakingRows);

    dispatch(EntityOperations.updateEntities({ ...sparePartEntities, ...stocktakingRowEntities }));

    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);

    const stocktakingRowIdForSparePart = stocktakingRows.reduce(
      (acc, stocktakingRow) => ({ ...acc, [stocktakingRow.spare_part_id]: stocktakingRow.id }),
      {}
    );

    dispatch(
      actions.fetchSparePartsForStocktakingSuccess({
        initialLoad: params.paginate_from == null,
        ids: sparePartResult,
        paginateFrom: res.headers['paginate-from-token'],
        totalEntries: pagination.totalEntries,
        isFullyLoaded: res.data.length < 20,
        stocktakingRowIdForSparePart,
      })
    );

    return res.data;
  });
};

export default {
  fetchSparePartsForStocktaking,
  initiateWithStocktakingId,
  clearSpareParts,
  hideCompletedCard,
};
