import React, { Component } from 'react';
import styles from './style.module.scss';

class DropdownItem extends Component {
  render() {
    return (
      <div>
        <div className={styles['title']}>{this.props.title}</div>
        <div className={styles['subtitle']}>{this.props.subtitle}</div>
      </div>
    );
  }
}

export default DropdownItem;
