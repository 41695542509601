import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { normalizeUser } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, FieldErrorWrapper } from 'views/components/Shared/General';

class EditPinModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    pin: null,
    showErrorForEmptyPin: false,
    showErrorForInvalidPin: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  update = () => {
    if (this.state.pin === null) {
      this.setState({ showErrorForEmptyPin: true });
      return;
    }
    if (this.state.pin.length !== 4 || isNaN(this.state.pin)) {
      this.setState({ showErrorForInvalidPin: true });
      return;
    }
    this.setState({ isSaving: true });
    const params = {
      pin_code: this.state.pin,
    };
    this.props
      .updateUser(this.props.user.id, params)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        const { data } = res;
        const { entities } = normalizeUser(data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <FieldErrorWrapper
        show={this.state.showErrorForInvalidPin}
        errorElement={<FormattedMessage id="screens.users.action-modal.invalid-pin" />}
      >
        <Field.Text
          placeholder="1234"
          autoFocus
          error={this.state.showErrorForEmptyPin || this.state.showErrorForInvalidPin}
          value={this.state.pin}
          onChange={pin => {
            this.setState({ pin, showErrorForEmptyPin: false, showErrorForInvalidPin: false });
          }}
        />
      </FieldErrorWrapper>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.pin-title" />}
          subtitle={
            <FormattedMessage
              id="screens.users.action-modal.change-subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={() =>
            this.props.user.pin_code_required ? this.props.onClose() : this.props.onCloseWithoutPin()
          }
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.update();
                }}
              />
              <Button
                label="general.cancel"
                onClick={() =>
                  this.props.user.pin_code_required ? this.props.onClose() : this.props.onCloseWithoutPin()
                }
              />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPinModal);
