import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { CardGrid } from 'views/components/Shared/Layout';
import { SectionHeader, FullScreenImagePicker } from 'views/components/Shared/General';
import { EditOperationalMaintenanceTemplateOperations } from 'state/ducks/editOperationalMaintenanceTemplate';
import { EntitySelectors } from 'sdk/State/entities';
import { Image } from 'views/components/Image';
import styles from './style.module.scss';

class ImagesContainer extends Component {
  state = {
    showingImageDialog: false,
    currentImage: 0,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  render() {
    if (this.props.images.length === 0) return null;
    return (
      <>
        <CardGrid.Row>
          <SectionHeader paddingHorizontal={30} horizontalBorders>
            <FormattedMessage id="components.edit-operational-maintenance-template.add-buttons.images" />
          </SectionHeader>
          <div className={styles['images']}>
            {this.props.images.map((image, index) => {
              return (
                <Image
                  key={image.id}
                  medium
                  editable={false}
                  image={image}
                  onClick={() => {
                    this.selectImage(index);
                  }}
                />
              );
            })}
          </div>
        </CardGrid.Row>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    images: EntitySelectors.getImages(state, ownProps.operationalMaintenanceTemplateVersion.images) || [],
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesContainer);
