import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IdleTimer from 'react-idle-timer';
import { Switch, Route, Redirect } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { withRouter } from 'react-router';
import moment from 'moment';
import { ErrorRoute } from 'views/layout';
import Settings from 'views/scenes/Settings';
import Users from 'views/scenes/Users';
import Requests from 'views/scenes/Requests';
import Downtimes from 'views/scenes/Downtimes';
import { FilesContainer } from 'views/scenes/Files';
import { Calendar } from 'views/scenes/Planning';
import { TreeAndList, AssetDetail } from 'views/scenes/Assets';
import { ChecklistList, ChecklistDetail } from 'views/scenes/Checklists';
import { StocktakingDetail, StocktakingList } from 'views/scenes/Stocktaking';
import { ShouldOrderSpareParts, SparePartList, SparePartDetail } from 'views/scenes/SpareParts';
import OperationalMaintenances from 'views/scenes/OperationalMaintenances';
import { MeterList, MeterDetail } from 'views/scenes/Meters';
import { WorkOrderList, WorkOrderDetail } from 'views/scenes/WorkOrders';
import { VendorList, VendorDetail } from 'views/scenes/Vendors';
import { PurchaseOrderList, PurchaseOrderDetail } from 'views/scenes/PurchaseOrders';
import Organisation from 'views/scenes/Organisation';
import SparePartWithdrawals from 'views/scenes/SparePartWithdrawals';
import Costs from 'views/scenes/Costs';
import ProductionBoard from 'views/scenes/ProductionBoard';
import {
  Costs as StatisticsCosts,
  Dashboard as StatisticsDashboard,
  SpentTime,
  Requests as RequestStatistics,
  WorkOrders as WorkOrdersStatistics,
} from 'views/scenes/Statistics';
import { ConnectedIntlProvider } from 'sdk/Components';
import { AuthSelectors, AuthOperations } from 'state/ducks/auth';
import AppLayout from 'views/layout/AppLayout';
import { SDKReduxOperations, Socket } from 'sdk';
import NewsLetterModal from './NewsLetterModal';

const AMOUNT_OF_MINUTES_UNTIL_IDLE = 3;

class PrivateApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewsLetterModal: false,
    };
    const { language } = this.props;
    moment.locale(language);
    document.documentElement.lang = language;
    moment.tz.setDefault(this.props.currentSystem.timezone);
  }

  componentDidMount() {
    if (this.props.userSettings.seen_mainter_notification8 === false) {
      this.setState({ showNewsLetterModal: true });
    }
    this.props.updateMe(this.props.currentUser.id, { default_system_id: this.props.currentSystem.id });
  }

  componentWillUnmount() {
    Socket.disconnect();
  }

  closeProductNameChangeModalAndMarkAsSeen = () => {
    this.setState({ showNewsLetterModal: false });
    this.props.updateUserSettings(this.props.currentSystem.id, this.props.currentUser.id, {
      seen_mainter_notification8: true,
    });
  };

  renderIdleDetectionComponent = () => {
    if (this.props.isGroupAuthenticated) {
      return (
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={60000 * AMOUNT_OF_MINUTES_UNTIL_IDLE}
          onIdle={this.props.unauthenticateUser}
          debounce={450}
        />
      );
    }
    return null;
  };

  renderApp = () => {
    let {
      location,
      canViewWorkOrders,
      canAdministrateWorkOrders,
      canViewAssets,
      canEditAssets,
      canViewVendors,
      canEditVendors,
      canViewSpareParts,
      isOperator,
      isProductionSupervisor,
      canViewOperationalMaintenances,
      currentSystem,
      canViewPurchaseOrders,
      canAdministrateRequests,
      canViewMeters,
      canViewFullCosts,
      isAdmin,
      isViewOnly,
      canViewDowntimes,
      settings,
      currentUser,
      organisation,
      hasProTier,
    } = this.props;
    const {
      production_board_activated,
      operational_maintenance_activated,
      production_supervisor_permission_users,
    } = settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    const parsedData = window.location.pathname.split('/');
    const systemId = parsedData[1];
    if (systemId !== currentSystem.id || location.pathname === '/') {
      let to = {};
      if (isOperator) {
        to = {
          pathname: `/production-board`,
        };
      } else if (canViewWorkOrders) {
        to = {
          pathname: '/work-orders',
          search: '?list=upcoming',
        };
      } else {
        to = {
          pathname: '/requests',
          search: '?list=my_created',
        };
      }
      return <Redirect to={to} />;
    } else {
      return (
        <AppLayout currentRoute={location.pathname} selectedSystem={currentSystem}>
          <Switch>
            <Route exact path="/calendar" component={Calendar} />
            <Route
              exact
              path="/work-orders"
              render={routeProps =>
                canViewWorkOrders ? (
                  <WorkOrderList {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            <Route
              exact
              path="/costs"
              render={routeProps => (canViewFullCosts ? <Costs {...routeProps} /> : <ErrorRoute />)}
            />

            {operationalMaintenanceIsActivated && canViewOperationalMaintenances ? (
              <Route
                path="/operational-maintenances"
                render={routeProps => {
                  return <OperationalMaintenances {...routeProps} selectedSystem={currentSystem} />;
                }}
              />
            ) : null}

            <Route
              exact
              path={['/assets', '/assets/list']}
              render={routeProps =>
                canViewAssets ? (
                  <TreeAndList {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            <Route
              path="/assets/:id"
              render={routeProps => (canViewAssets ? <AssetDetail /> : <ErrorRoute />)}
            />
            <Route
              path="/organisation"
              render={routeProps =>
                currentUser.organisation_admin && organisation.multiple_systems ? (
                  <Organisation {...routeProps} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            <Route
              exact
              path="/vendors"
              render={routeProps =>
                canViewVendors ? (
                  <VendorList {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            {settings.spare_parts_activated ? (
              <Route
                exact
                path={[
                  '/purchase-orders',
                  '/purchase-orders/active-deliveries',
                  '/purchase-orders/deliveries',
                ]}
                render={routeProps =>
                  canViewPurchaseOrders ? (
                    <PurchaseOrderList {...routeProps} selectedSystem={currentSystem} />
                  ) : (
                    <ErrorRoute />
                  )
                }
              />
            ) : null}
            {settings.spare_parts_activated ? (
              <Route
                path="/purchase-orders/:id"
                render={routeProps =>
                  canViewPurchaseOrders ? (
                    <PurchaseOrderDetail {...routeProps} selectedSystem={currentSystem} />
                  ) : (
                    <ErrorRoute />
                  )
                }
              />
            ) : null}
            <Route
              exact
              path="/statistics"
              render={routeProps =>
                hasProTier && canViewFullCosts ? (
                  <StatisticsDashboard {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            <Route
              exact
              path="/statistics/costs"
              render={routeProps =>
                hasProTier && canViewFullCosts ? (
                  <StatisticsCosts {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            {settings.work_order_spent_time_activated ? (
              <Route
                exact
                path="/statistics/spent-time"
                render={routeProps =>
                  hasProTier && (isAdmin || isViewOnly) ? (
                    <SpentTime {...routeProps} selectedSystem={currentSystem} />
                  ) : (
                    <ErrorRoute />
                  )
                }
              />
            ) : null}
            <Route
              exact
              path="/statistics/requests"
              render={routeProps =>
                hasProTier && (isAdmin || isViewOnly) ? (
                  <RequestStatistics {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />
            <Route
              exact
              path="/statistics/work-orders"
              render={routeProps =>
                hasProTier && (isAdmin || isViewOnly) ? (
                  <WorkOrdersStatistics {...routeProps} selectedSystem={currentSystem} />
                ) : (
                  <ErrorRoute />
                )
              }
            />

            {settings.spare_parts_activated ? (
              <Route
                exact
                path="/spare-parts"
                render={routeProps =>
                  canViewSpareParts ? (
                    <SparePartList {...routeProps} selectedSystem={currentSystem} />
                  ) : (
                    <ErrorRoute />
                  )
                }
              />
            ) : null}
            {settings.spare_parts_activated ? (
              <Route
                exact
                path="/withdrawals"
                render={routeProps =>
                  canViewSpareParts ? (
                    <SparePartWithdrawals {...routeProps} selectedSystem={currentSystem} />
                  ) : (
                    <ErrorRoute />
                  )
                }
              />
            ) : null}

            {settings.spare_parts_activated ? (
              <Route
                exact
                path="/spare-parts/should-order"
                render={routeProps => {
                  if (hasProTier && canViewSpareParts) {
                    return <ShouldOrderSpareParts {...routeProps} selectedSystem={currentSystem} />;
                  }
                  return <ErrorRoute />;
                }}
              />
            ) : null}
            {settings.spare_parts_activated && (canViewSpareParts || this.props.isOperator) ? (
              <Route path="/spare-parts/:id" component={SparePartDetail} />
            ) : null}
            {canViewSpareParts && settings.spare_parts_activated ? (
              <Route exact path="/stocktaking" component={StocktakingList} />
            ) : null}
            {canViewSpareParts && settings.spare_parts_activated ? (
              <Route exact path="/stocktaking/:id" component={StocktakingDetail} />
            ) : null}
            <Route
              path="/vendors/:id"
              render={routeProps => (canViewVendors ? <VendorDetail /> : <ErrorRoute />)}
            />

            <Route
              path="/work-orders/:id"
              render={routeProps => (canViewWorkOrders || isOperator ? <WorkOrderDetail /> : <ErrorRoute />)}
            />

            <Route path="/settings" component={Settings} />
            {isAdmin ||
            (isProductionSupervisor && production_supervisor_permission_users === 'create_operators') ? (
              <Route path="/users" component={Users} />
            ) : null}
            <Route
              path="/requests/:id"
              render={routeProps => {
                if (canAdministrateRequests) {
                  return <Redirect to={`/requests?list=to_plan&request_id=${routeProps.match.params.id}`} />;
                }
                if (isOperator || isProductionSupervisor) {
                  return <Redirect to={`/requests?list=open&request_id=${routeProps.match.params.id}`} />;
                }
                return <Redirect to={`/requests?list=my_created&request_id=${routeProps.match.params.id}`} />;
              }}
            />
            <Route
              path="/requests"
              render={routeProps => <Requests {...routeProps} selectedSystem={currentSystem} />}
            />
            <Route
              exact
              path="/files"
              render={routeProps => {
                return <FilesContainer {...routeProps} selectedSystem={currentSystem} />;
              }}
            />

            <Route exact path={['/checklists', '/deviations', '/deviations/:id']} component={ChecklistList} />
            <Route path="/checklists/:id" component={ChecklistDetail} />

            <Route
              exact
              path="/meters"
              render={routeProps =>
                canViewMeters && settings.meters_activated ? <MeterList {...routeProps} /> : <ErrorRoute />
              }
            />
            <Route
              exact
              path="/downtimes"
              render={routeProps => (canViewDowntimes ? <Downtimes {...routeProps} /> : <ErrorRoute />)}
            />

            <Route
              path="/meters/:id"
              render={routeProps =>
                canViewMeters && settings.meters_activated ? <MeterDetail {...routeProps} /> : <ErrorRoute />
              }
            />
            <Route path={'/production-board'} component={ProductionBoard} />

            <Route component={ErrorRoute} {...this.props} />
          </Switch>
        </AppLayout>
      );
    }
  };

  renderChangeProductnameModal = () => {
    return (
      <NewsLetterModal
        open={this.state.showNewsLetterModal}
        language={this.props.currentUser.language}
        onClose={() => this.closeProductNameChangeModalAndMarkAsSeen()}
      />
    );
  };

  render() {
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <ConnectedIntlProvider textComponent="span" language={this.props.language}>
            {this.renderIdleDetectionComponent()}
            {this.renderApp()}
            {this.renderChangeProductnameModal()}
          </ConnectedIntlProvider>
        </DndProvider>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unauthenticateUser: AuthOperations.unauthenticateUser,
      updateMe: SDKReduxOperations.updateMe,
      updateUserSettings: SDKReduxOperations.updateUserSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    canViewAssets: AuthSelectors.canViewAssets(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    isAdmin: AuthSelectors.isAdmin(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    canViewMeters: AuthSelectors.canViewMeters(state),
    isOperator: AuthSelectors.isOperator(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canViewWorkOrders: AuthSelectors.canViewWorkOrders(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    hasProTier: AuthSelectors.hasProTier(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    language: AuthSelectors.getLanguage(state),
    settings: AuthSelectors.getSettings(state),
    userSettings: AuthSelectors.getCurrentUserSettings(state),
    canViewSpareParts: AuthSelectors.canViewSpareParts(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
    canViewOperationalMaintenances: AuthSelectors.canViewOperationalMaintenances(state),
    canViewFullCosts: AuthSelectors.canViewFullCosts(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    canViewDowntimes: AuthSelectors.canViewDowntimes(state),
    organisation: AuthSelectors.getCurrentOrganisation(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateApp));
