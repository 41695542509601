import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { ProductionBoardSelectors, ProductionBoardOperations } from 'state/ducks/productionBoard';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class DaySelection extends Component {
  selectNextDay = () => {
    this.props.setProductionBoardActivityDate(moment(this.props.date).add('1', 'day').format('YYYY-MM-DD'));
  };

  selectPreviousDay = () => {
    this.props.setProductionBoardActivityDate(
      moment(this.props.date).subtract('1', 'day').format('YYYY-MM-DD')
    );
  };

  renderTitle = () => {
    return (
      <h2 className={styles['title']}>
        <FormattedMessage id="screens.production-board.overview.activities.title" />
      </h2>
    );
  };

  renderDay = () => {
    const today = moment().tz(this.props.system.timezone);

    if (today.isSame(moment(this.props.date), 'day')) {
      return (
        <span className={styles['day-text']}>
          <FormattedMessage id="screens.production-board.overview.activities.days.today" />
        </span>
      );
    } else if (today.subtract(1, 'day').isSame(moment(this.props.date), 'day')) {
      return (
        <span className={styles['day-text']}>
          <FormattedMessage id="screens.production-board.overview.activities.days.yesterday" />
        </span>
      );
    } else if (today.add(1, 'day').isSame(moment(this.props.date), 'day')) {
      return (
        <span className={styles['day-text']}>
          <FormattedMessage id="screens.production-board.overview.activities.days.tomorrow" />
        </span>
      );
    }
    return (
      <>
        <span className={styles['day-text']}>
          <span>{moment(this.props.date).format('D')}</span>
        </span>
        <span> </span>
        <span className={styles['day-text']}>{moment(this.props.date).format('MMMM')}</span>
      </>
    );
  };

  renderDaySwitchButton = () => {
    const today = moment().tz(this.props.system.timezone);
    const isToday = today.isSame(moment(this.props.date), 'day');

    return (
      <div className={styles['switch-day-container']}>
        <Button
          type="icon"
          icon={<Icon regular size={16} type="angle-left" />}
          onClick={this.selectPreviousDay}
        />
        <Button
          type="icon"
          disabled={isToday}
          icon={<Icon regular size={16} type="angle-right" />}
          onClick={this.selectNextDay}
        />
      </div>
    );
  };

  render() {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['top-container']}>
          {this.renderTitle()}
          {this.renderDaySwitchButton()}
        </div>
        {this.renderDay()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setProductionBoardActivityDate: ProductionBoardOperations.setProductionBoardActivityDate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    date: ProductionBoardSelectors.getProductionBoardActivityDate(state),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelection);
