import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { WorkOrderCategoryType } from 'sdk/System';
import { AuthSelectors } from 'state/ducks/auth';
import { CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getWorkOrderMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.title',
        }),

        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderTitle);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.description',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderDescription);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.status',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Status);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.due-date',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.DueDate);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.interval.title',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Interval);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.completed-before-registration',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CompletedBeforeRegistration);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.assignee',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Assignee);
        },
      },
    ];
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      menuItems = [
        ...menuItems,
        {
          label: this.props.intl.formatMessage({
            id: 'resources.work-order.category',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.Category);
          },
        },
      ];
    }
    menuItems = [
      ...menuItems,
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.work-order-type',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderType);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.estimated-time',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.EstimatedMinutes);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.priority',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Priority);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.in-progress-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.InProgressBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.paused-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.PausedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.paused-comment',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.PausedComment);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.completed-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CompletedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.completed-at',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CompletedAt);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.completed-comment',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CompletedComment);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order.created-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.work-orders.filters.advanced.work-order.created-at',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedAt);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getUsedSparePartMenuItems = () => {
    const menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.resource',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.UsedSparePart);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getWorkOrderSpentTimeMenuItems = () => {
    const menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order-spent-time.date',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderSpentTimeDate);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order-spent-time.performed-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderSpentTimeCreatedBy);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.work-order-spent-time.comment',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.WorkOrderSpentTimeComment);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getAssetMenuItems = () => {
    const menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.asset.type',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.AssetType);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getRequestMenuItems = () => {
    const menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.resource',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Request);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.request.request-type',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.RequestType);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderWorkOrderFilters = () => {
    const workOrderItems = this.getWorkOrderMenuItems();
    if (workOrderItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.work-order.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {workOrderItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderRequestFilters = () => {
    const requestItems = this.getRequestMenuItems();
    if (requestItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.request.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {requestItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderAssetFilters = () => {
    const assetItems = this.getAssetMenuItems();
    if (assetItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.asset.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {assetItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderWorkOrderSpentTimeFilters = () => {
    const workOrderSpentTimeItems = this.getWorkOrderSpentTimeMenuItems();
    if (workOrderSpentTimeItems.length === 0) {
      return null;
    }

    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.work-order-spent-time.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {workOrderSpentTimeItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderUsedSparePartsFilters = () => {
    const sparePartItems = this.getUsedSparePartMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }

    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.used-spare-parts.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [
      ...this.getWorkOrderMenuItems(),
      ...this.getAssetMenuItems(),
      ...this.getUsedSparePartMenuItems(),
      ...this.getWorkOrderSpentTimeMenuItems(),
      ...this.getRequestMenuItems(),
    ];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return (
      <>
        {this.renderWorkOrderFilters()}
        {this.renderAssetFilters()}
        {this.renderWorkOrderSpentTimeFilters()}
        {this.renderUsedSparePartsFilters()}
        {this.renderRequestFilters()}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.work-orders.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
