import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Grid } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import QrTemplateColumnWidthSlider from './QrTemplateColumnWidthSlider';
import { SDKReduxOperations } from 'sdk';

class QrTemplateSettings extends Component {
  getInititalState = () => ({
    editingQrTemplate: {},
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  componentDidMount() {
    this.setState({
      editingQrTemplate: {
        ...this.props.qrTemplate,
      },
    });
  }

  setEditingQrTemplateValues = (params, options = { save: false }) => {
    this.setState(
      {
        editingQrTemplate: {
          ...this.state.editingQrTemplate,
          ...params,
        },
      },
      () => {
        let changedKey = Object.keys(params)[0];
        if (
          options.save === true &&
          this.props.qrTemplate[changedKey] !== this.state.editingQrTemplate[changedKey]
        ) {
          this.props.onShowSaveContainer();
          this.props.updateQrTemplate(this.props.qrTemplateId, params).then(() => {
            this.props.onHideSaveContainer();
          });
        }
      }
    );
  };

  renderColumnWidthSliders = () => {
    return (
      <div className={styles['column-width-container']}>
        <div className={styles['title']}>
          <FormattedMessage dark id={'components.print-qr-codes.width-for-columns'} />
        </div>
        <QrTemplateColumnWidthSlider
          qrTemplateId={this.props.qrTemplateId}
          widthsForColumns={this.props.widthsForColumns}
          onChangeColumnWidths={params => this.props.onChangeColumnWidths(params)}
          onShowSaveContainer={this.props.onShowSaveContainer}
          onHideSaveContainer={this.props.onHideSaveContainer}
        />
      </div>
    );
  };

  render() {
    const { editingQrTemplate } = this.state;
    return (
      <div className={styles['general-settings-container']}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <div className={styles['title']}>
                <FormattedMessage dark id={'components.print-qr-codes.edit-template-tab.subtitle'} />
              </div>
            </Grid.Column>
          </Grid.Row>
          {this.renderColumnWidthSliders()}
          <div className={styles['border-line']} />
          <Grid.Row>
            <Grid.Column>
              <div className={styles['size-fields-container']}>
                <div className={styles['size-field-container']}>
                  <Field label={<FormattedMessage id="resources.qr-template.width" edit />}>
                    <Field.Decimal
                      value={editingQrTemplate.width}
                      decimalPlaces={2}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={width => {
                        this.setEditingQrTemplateValues({ width });
                      }}
                      onBlur={width => {
                        this.setEditingQrTemplateValues({ width }, { save: true });
                      }}
                    />
                  </Field>
                </div>
                <div className={styles['size-field-container']}>
                  <Field label={<FormattedMessage id="resources.qr-template.height" edit />}>
                    <Field.Decimal
                      value={editingQrTemplate.height}
                      decimalPlaces={2}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={height => {
                        this.setEditingQrTemplateValues({ height });
                      }}
                      onBlur={height => {
                        this.setEditingQrTemplateValues({ height }, { save: true });
                      }}
                    />
                  </Field>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="components.print-qr-codes.margins" edit />}>
                <div className={styles['margins-container']}>
                  <div className={[styles['margins'], styles['top']].join(' ')}>
                    <Field.Number
                      placeholder="0"
                      value={editingQrTemplate.padding_top}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={padding_top => this.setEditingQrTemplateValues({ padding_top })}
                      onBlur={padding_top => this.setEditingQrTemplateValues({ padding_top }, { save: true })}
                    />
                  </div>
                  <div className={[styles['margins'], styles['left']].join(' ')}>
                    <Field.Number
                      placeholder="0"
                      value={editingQrTemplate.padding_left}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={padding_left => this.setEditingQrTemplateValues({ padding_left })}
                      onBlur={padding_left =>
                        this.setEditingQrTemplateValues({ padding_left }, { save: true })
                      }
                    />
                  </div>
                  <div className={[styles['margins'], styles['right']].join(' ')}>
                    <Field.Number
                      placeholder="0"
                      value={editingQrTemplate.padding_right}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={padding_right => this.setEditingQrTemplateValues({ padding_right })}
                      onBlur={padding_right =>
                        this.setEditingQrTemplateValues({ padding_right }, { save: true })
                      }
                    />
                  </div>
                  <div className={[styles['margins'], styles['bottom']].join(' ')}>
                    <Field.Number
                      placeholder="0"
                      value={editingQrTemplate.padding_bottom}
                      rightLabel={<FormattedMessage id="components.print-qr-codes.abbreviation" />}
                      onChange={padding_bottom => this.setEditingQrTemplateValues({ padding_bottom })}
                      onBlur={padding_bottom =>
                        this.setEditingQrTemplateValues({ padding_bottom }, { save: true })
                      }
                    />
                  </div>
                  <div className={styles['vertical-line']} />
                  <div className={styles['horizontal-line']} />
                </div>
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplate: SDKReduxOperations.updateQrTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateId } = ownProps;

  return {
    qrTemplate: EntitySelectors.getQrTemplate(state, qrTemplateId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QrTemplateSettings);
