import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import queryString from 'query-string';
import { API } from 'sdk';
import AnimateHeight from 'react-animate-height';
import HelperFunctions from 'utilities/HelperFunctions';
import { Banner, Button } from 'views/components/Shared/General';
import { Input, Button as LoginButton, Layout, RedirectAuthedUserToPrivateApp } from 'views/components/Login';
import styles from './style.module.scss';
import LogoSvg from 'assets/images/logo.svg';

class SSO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: process.env.REACT_APP_USERNAME || '',
      showLoginError: false,
      isLoggingIn: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Login');

    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.error != null) {
      this.setState({ showLoginError: true });
    }
  }

  login = () => {
    const { email } = this.state;

    if (!email) return null;

    this.setState({ isLoggingIn: true });

    API.oidcInitiate({ email })
      .then(resp => {
        if (resp.data.status === 'OK') {
          if (resp.data.strategy === 'general') {
            window.location.replace(`${process.env.REACT_APP_API_URL}oidc/general?email=${email}`);
          } else if (resp.data.strategy === 'azure') {
            window.location.replace(`${process.env.REACT_APP_API_URL}oidc/azure?email=${email}`);
          }
        }
      })
      .catch(() => {
        this.setState({ showLoginError: true, isLoggingIn: false });
      });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  render() {
    if (this.props.isUserAuthenticated || this.props.isGroupAuthenticated) {
      return <RedirectAuthedUserToPrivateApp />;
    }

    return (
      <Layout>
        <div className={styles['text']}>Login with Single Sign-On</div>
        <AnimateHeight duration={250} height={this.state.showLoginError ? 'auto' : 0}>
          <div className={styles['error-container']}>
            <Banner red>Unable to login with Single Sign-On</Banner>
          </div>
        </AnimateHeight>
        <div className={styles['input-container']}>
          <Input
            type="email"
            autoFocus
            placeholder="Email"
            onKeyPress={this.handleKeyPress}
            value={this.state.email}
            onChange={email => {
              this.setState({ email });
            }}
          />
        </div>
        <div className={styles['button-container']}>
          <LoginButton
            label="Login"
            translate={false}
            primary
            fullWidth
            disabled={this.state.email.length === 0}
            loading={this.state.isLoggingIn}
            onClick={this.login}
          />
        </div>
        <div className={styles['separator']} />
        <div className={styles['additional-buttons']}>
          <Link to="/login">
            <Button gray label="Back to login" translate={false} />
          </Link>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
    isGroupAuthenticated: AuthSelectors.isGroupAuthenticated(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signIn: AuthOperations.signIn }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SSO));
