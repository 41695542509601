import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { List } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';
import PurchaseOrderDeliveryListItemRow from './PurchaseOrderDeliveryListItemRow';

class PurchaseOrderDeliveryListItem extends Component {
  renderExpandedComponent = () => {
    if (this.props.purchaseOrderDeliveryRows.length === 0) {
      return (
        <div className={styles['empty-rows']}>
          <FormattedMessage id="screens.purchase-order.deliveries.empty-rows" />
        </div>
      );
    } else {
      return (
        <div className={styles['delivery-rows']}>
          {this.props.purchaseOrderDeliveryRows.map(purchaseOrderDeliveryRow => (
            <PurchaseOrderDeliveryListItemRow purchaseOrderDeliveryRow={purchaseOrderDeliveryRow} />
          ))}
        </div>
      );
    }
  };

  render() {
    return (
      <List.Item
        clickable={this.props.canCreateWithdrawals}
        onClick={this.props.onEdit}
        expandable
        expandedComponent={this.renderExpandedComponent()}
      >
        <List.Item.TitleColumn
          title={moment(this.props.purchaseOrderDelivery.created_at).format('LLL')}
          subtitle={
            <FormattedMessage
              id="screens.purchase-order.deliveries.created-by-user"
              values={{ user: <UserNameWrapper user={this.props.createdByUser} /> }}
            />
          }
        />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    createdByUser: EntitySelectors.getUser(state, ownProps.purchaseOrderDelivery.created_by_user_id),
    purchaseOrderDeliveryRows: EntitySelectors.getPurchaseOrderDeliveryRows(
      state,
      ownProps.purchaseOrderDelivery.purchase_order_delivery_rows
    ),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps)(PurchaseOrderDeliveryListItem);
