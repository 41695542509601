import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeQrTemplate, normalizeTemplateField } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createQrTemplate(systemId, params) {
  return dispatch => {
    return API.createQrTemplate(systemId, params)
      .then(res => {
        const { entities } = normalizeQrTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateQrTemplate(qrTemplateId, params) {
  return dispatch => {
    return API.updateQrTemplate(qrTemplateId, params)
      .then(res => {
        const { entities } = normalizeQrTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { qrTemplateId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteQrTemplate(qrTemplateId) {
  return (dispatch, getState) => {
    return API.deleteQrTemplate(qrTemplateId)
      .then(res => {
        const qrTemplate = getState().entities.qrTemplateById[qrTemplateId];
        const payload = { qrTemplateId, data: qrTemplate };
        dispatch({ type: types.QR_TEMPLATE_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
