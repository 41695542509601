import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { AuthOperations } from 'state/ducks/auth';
import { List, SectionHeader, EmptyDataSet, Button } from 'views/components/Shared/General';
import { normalizeUser } from 'sdk/Schemas';
import InfiniteScroll from 'react-infinite-scroller';
import { EntityOperations } from 'sdk/State/entities';
import { MenuModal } from 'views/components/Shared/Layout';
import { HelperFunctions, API } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListItem from './UserListItem';
import SearchField from './SearchField';
import { PinCodeOverlay } from 'views/components/User';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

const PAGE_SIZE = 20;

class ChangeUserMenu extends Component {
  getInitialState = () => ({
    isFetching: false,
    userIds: [],
    paginateFrom: null,
    totalEntries: 0,
    canFetchMoreUsers: false,
    isSearching: false,
    isFetchingMoreUsers: false,
    isSigningInUserId: null,
    searchTerm: '',
    showPinCodeOverlay: false,
    showPinCodeForUserId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isFetching: true });
      this.fetchUsers()
        .then(({ userIds, paginateFrom, totalEntries }) => {
          this.setState({
            userIds,
            paginateFrom,
            totalEntries,
            isFetching: false,
            canFetchMoreUsers: userIds.length >= PAGE_SIZE,
          });
        })
        .catch(() => {
          localStorage.removeItem('gToken');
          this.setState({ isFetching: false });
          this.props.unauthenticateGroup();
        });
    } else if (this.props.open) {
      if (prevProps.currentUser.id !== this.props.currentUser.id) {
        this.props.onClose();
      }
    }
  }

  fetchUsers = params => {
    return API.listUsersForGroup({ page_size: PAGE_SIZE, ...params }).then(({ data: users, headers }) => {
      const { entities, result: userIds } = normalizeUser(users);
      const { paginateFrom, totalEntries } = HelperFunctions.getPaginationFromHeader(headers);
      this.props.updateEntities(entities);
      return { userIds, paginateFrom, totalEntries };
    });
  };

  searchUsers = searchTerm => {
    this.fetchUsers({ search: searchTerm }).then(({ userIds, paginateFrom }) => {
      this.setState({
        userIds,
        paginateFrom,
        isSearching: false,
        canFetchMoreUsers: userIds.length >= PAGE_SIZE,
      });
    });
  };

  fetchMoreUsers = () => {
    if (!this.state.canFetchMoreUsers) {
      return;
    }
    if (this.state.isFetchingMoreUsers) {
      return;
    }
    this.setState({ isFetchingMoreUsers: true });
    this.fetchUsers({ paginate_from: this.state.paginateFrom, search: this.state.searchTerm }).then(
      ({ userIds, paginateFrom }) => {
        this.setState({
          userIds: [...this.state.userIds, ...userIds],
          paginateFrom,
          isFetchingMoreUsers: false,
          canFetchMoreUsers: userIds.length >= PAGE_SIZE,
        });
      }
    );
  };

  signInUser = user => {
    if (this.state.isSigningInUserId) {
      return;
    }
    if (user.pin_code_required) {
      this.setState({ showPinCodeOverlay: true, showPinCodeForUserId: user.id });
    } else {
      this.setState({ isSigningInUserId: user.id });
      if (this.props.isUserAuthenticated) {
        this.props.signOutAndSignInGroupUser({ user_id: user.id });
      } else {
        this.props.signInGroupUser({ user_id: user.id });
      }
    }
  };
  renderEmptyDataSet = () => {
    if (this.state.searchTerm.length === 0) {
      return (
        <>
          <EmptyDataSet
            title={<FormattedMessage id="components.select-user-group-login.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.select-user-group-login.empty-data-set.subtitle" />}
            modal
          />
        </>
      );
    }
    return (
      <EmptyDataSet
        title={<FormattedMessage id="general.empty-data-set-search.title" />}
        subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
        modal
        noBackground
      />
    );
  };

  renderUsers = () => {
    if (this.state.isFetching) {
      return (
        <>
          <SectionHeader paddingHorizontal={35} noBorderBottom>
            <FormattedMessage id="components.select-user-group-login.section-header" />
          </SectionHeader>
          <List light>
            <UserListItem loading />
            <UserListItem loading />
          </List>
        </>
      );
    }
    if (this.state.isSearching) {
      const amountOfUsers = this.state.userIds.length === 0 ? 2 : this.state.userIds.length;
      return (
        <>
          <SectionHeader paddingHorizontal={35} noBorderBottom>
            <FormattedMessage id="components.select-user-group-login.section-header" />
          </SectionHeader>
          <List light>
            {Array(amountOfUsers)
              .fill()
              .map(() => (
                <>
                  <UserListItem loading />
                  <UserListItem loading />
                </>
              ))}
          </List>
        </>
      );
    }
    if (this.state.userIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        <SectionHeader paddingHorizontal={35} noBorderBottom>
          <FormattedMessage id="components.select-user-group-login.section-header" />
        </SectionHeader>
        <List light>
          {this.state.userIds.map(id => (
            <UserListItem
              id={id}
              key={id}
              isSigningInUserId={this.state.isSigningInUserId}
              onSignInGroupUser={user => this.signInUser(user)}
            />
          ))}
          {this.state.isFetchingMoreUsers ? (
            <>
              <UserListItem loading />
              <UserListItem loading />
            </>
          ) : null}
        </List>
      </>
    );
  };

  renderSearchField = () => {
    if (this.state.totalEntries <= 10) {
      return null;
    }
    return (
      <div className={styles['search-field-container']}>
        <SearchField
          value={this.state.searchTerm}
          debounce
          onDebouncedSearch={value => {
            this.searchUsers(value);
          }}
          onSearch={searchTerm => {
            this.setState({ searchTerm, isSearching: true });
          }}
          onClear={() => {
            this.setState({ searchTerm: '', isSearching: true });
            this.searchUsers('');
          }}
        />
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className={styles['footer']}>
        <div className={styles['logout-button-container']}>
          <Button gray label="menu.log-out" onClick={this.props.unauthenticateUser} />
        </div>
        <div className={styles['logged-in-text']}>
          <FormattedMessage
            id="menu.logged-in-as"
            values={{
              user: <span className={styles['highlight-text']}>{this.props.currentUser.name}</span>,
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <MenuModal width={440} open={this.props.open} onClose={() => this.props.onClose()}>
        <MenuModal.Container>
          <div className={styles['content-container']}>
            <div className={styles['scroll-content']}>
              <PerfectScrollbar>
                <InfiniteScroll
                  loadMore={this.fetchMoreUsers}
                  hasMore={this.state.canFetchMoreUsers}
                  useWindow={false}
                  initialLoad={false}
                  threshold={150}
                >
                  <MenuModal.Header
                    onClose={() => this.props.onClose()}
                    title={<FormattedMessage id="components.select-user-group-login.select-user-title" />}
                  />
                  {this.renderSearchField()}
                  {this.renderUsers()}
                </InfiniteScroll>
              </PerfectScrollbar>
              <PinCodeOverlay
                open={this.state.showPinCodeOverlay}
                userId={this.state.showPinCodeForUserId}
                onClose={() => {
                  this.setState({ showPinCodeOverlay: false });
                }}
              />
            </div>
            {this.renderFooter()}
          </div>
        </MenuModal.Container>
      </MenuModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      unauthenticateUser: AuthOperations.unauthenticateUser,
      signInGroupUser: AuthOperations.signInGroupUser,
      signOutAndSignInGroupUser: AuthOperations.signOutAndSignInGroupUser,
      unauthenticateGroup: AuthOperations.unauthenticateGroup,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserMenu);
