import React from 'react';
import { WorkOrderStatus, getColorForStatus } from 'sdk/WorkOrder';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ status }) => {
  let icon;
  switch (status) {
    case WorkOrderStatus.InProgress:
      icon = 'wrench';
      break;
    case WorkOrderStatus.Paused:
      icon = 'pause';
      break;
    case WorkOrderStatus.Completed:
      icon = 'check';
      break;
    default:
      break;
  }

  return (
    <div className={styles['container']} style={{ borderColor: getColorForStatus(status) }}>
      <Icon regular type={icon} size={10} color={getColorForStatus(status)} />
    </div>
  );
};
