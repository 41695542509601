import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const fetchOperationalMaintenanceTemplateAsset = templateAssetId =>
  request.get(`operational_maintenance_template_assets/${templateAssetId}`);
export const createTemplateAssetForTemplate = (templateId, data) =>
  request.post(
    `operational_maintenance_templates/${templateId}/operational_maintenance_template_assets`,
    data
  );
export const deleteOperationalMaintenanceTemplateAsset = (templateAssetId, data) =>
  request.delete(`operational_maintenance_template_assets/${templateAssetId}`, data);
