import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, Tooltip } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import ContentLoader from 'react-content-loader';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './styles.module.scss';
import StockAdjustmentModal from './StockAdjustmentModal';
import ActivateStockModal from './ActivateStockModal';
import ReservedModal from './ReservedModal';
import ActivePurchaseOrderRow from './ActivePurchaseOrderRow';
import StockActivitiesModal from './StockActivitiesModal';
import WithdrawalModal from './WithdrawalModal';

class StockInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockAdjustmentModal: false,
      showActivateStockModal: false,
      showReservedModal: false,
      showWithdrawalModal: false,
      showNestedModals: false,
      showStopTimeDetail: false,
      showStopTimeList: false,
      showStockActivitiesModal: false,
    };
  }

  isLoaded = () => this.props.sparePart != null;

  renderContentLoader = () => (
    <div className={styles['loader-container']}>
      <ContentLoader
        primaryColor="#F5F5F5"
        secondaryColor="#EFEFEF"
        height={50}
        preserveAspectRatio="xMinYMin"
      >
        <rect x="0" y="12" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="28" rx="2" ry="2" width="70" height="6" />
      </ContentLoader>
    </div>
  );

  renderContent = () => {
    if (!this.isLoaded()) return this.renderContentLoader();

    return (
      <React.Fragment>
        {this.renderInStock()}
        {this.renderReserved()}
        {this.renderIncoming()}
        {this.renderButtons()}
      </React.Fragment>
    );
  };

  renderInStock = () => {
    if (this.props.sparePart.stock_quantity == null) return null;
    return (
      <div className={styles['in-stock']}>
        <p className={styles['quantity']}>
          <SparePartUnitWithValue
            valueStyle={{ fontSize: 30 }}
            abbreviationStyle={{ fontSize: 17, marginLeft: '-3px' }}
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.sparePart.stock_quantity}
          />
        </p>
        <p className={styles['title']}>
          <FormattedMessage id="screens.spare-part.info.stock-information.in-stock" />
        </p>
      </div>
    );
  };

  renderReserved = () => {
    return (
      <div className={styles['reserved']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.spare-part.info.stock-information.reserved" />
          </div>
          {this.props.canAdministrateWorkOrders ? (
            <Tooltip
              trigger={
                <div
                  className={styles['show-modal']}
                  onClick={() => this.setState({ showReservedModal: true })}
                >
                  <FormattedMessage id="general.show" />
                </div>
              }
              label={
                <FormattedMessage id="screens.spare-part.info.stock-information.reservations-popup-content" />
              }
            />
          ) : null}
        </div>
        <p className={styles['reserved-text']}>
          <span className={styles['label']}>
            <FormattedMessage
              id="screens.spare-part.info.stock-information.within-days-value"
              values={{ days: 30 }}
            />
            :
          </span>
          <span className={styles['value']}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_thirty_days}
            />
          </span>
        </p>
        <p className={styles['reserved-text']}>
          <span className={styles['label']}>
            <FormattedMessage
              id="screens.spare-part.info.stock-information.within-days-value"
              values={{ days: 90 }}
            />
            :
          </span>
          <span className={styles['value']}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_ninety_days}
            />
          </span>
        </p>
        <p className={styles['reserved-text']}>
          <span className={styles['label']}>
            <FormattedMessage
              id="screens.spare-part.info.stock-information.within-days-value"
              values={{ days: 180 }}
            />
            :
          </span>
          <span className={styles['value']}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_hundred_and_eighty_days}
            />
          </span>
        </p>
      </div>
    );
  };

  renderIncoming = () => {
    if (this.props.activePurchaseOrderRows.length == 0) return null;
    return (
      <div className={styles['incoming']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.spare-part.info.stock-information.incoming" />
          </div>
        </div>
        {this.props.activePurchaseOrderRows.map(purchaseOrderRow => (
          <ActivePurchaseOrderRow purchaseOrderRow={purchaseOrderRow} />
        ))}
      </div>
    );
  };

  renderStockAdjustmentButton = () => {
    if (this.props.canAdjustStockOnSpareParts) {
      return (
        <div className={styles['button']} onClick={() => this.setState({ stockAdjustmentModal: true })}>
          <FormattedMessage id="screens.spare-part.actions.stock-adjustment.title" />
        </div>
      );
    }
  };

  renderCreateWithdrawalButton = () => {
    if (this.props.canCreateWithdrawals) {
      return (
        <div className={styles['button']} onClick={() => this.setState({ showWithdrawalModal: true })}>
          <FormattedMessage id="screens.spare-part.actions.withdrawal.title" />
        </div>
      );
    }
    return null;
  };

  renderButtons = () => {
    if (this.props.sparePart.stock_quantity == null) {
      return (
        <div className={styles['buttons']}>
          <p className={styles['button']} onClick={() => this.setState({ showActivateStockModal: true })}>
            <FormattedMessage id="screens.spare-part.actions.add-stock-balance.title" />
          </p>
        </div>
      );
    }
    return (
      <div className={styles['buttons']}>
        {this.renderCreateWithdrawalButton()}
        {this.renderStockAdjustmentButton()}
        <div className={styles['button']} onClick={() => this.setState({ showStockActivitiesModal: true })}>
          <FormattedMessage id="screens.spare-part.info.stock-information.show-stock-history" />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <WhiteCard noPadding>{this.renderContent()}</WhiteCard>
        <StockAdjustmentModal
          open={this.state.stockAdjustmentModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ stockAdjustmentModal: false });
          }}
        />
        <ActivateStockModal
          open={this.state.showActivateStockModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ showActivateStockModal: false });
          }}
        />
        <ReservedModal
          open={this.state.showReservedModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ showReservedModal: false });
          }}
        />
        <WithdrawalModal
          open={this.state.showWithdrawalModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ showWithdrawalModal: false });
          }}
        />
        <StockActivitiesModal
          open={this.state.showStockActivitiesModal}
          sparePart={this.props.sparePart}
          onClose={() => {
            this.setState({ showStockActivitiesModal: false });
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartId = ownProps.match.params.id;
  const sparePart = EntitySelectors.getSparePart(state, sparePartId);
  return {
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    activePurchaseOrderRows:
      EntitySelectors.getPurchaseOrderRows(state, sparePart.active_purchase_order_rows) || [],
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
    canAdjustStockOnSpareParts: AuthSelectors.canAdjustStockOnSpareParts(state),
  };
}

export default withRouter(connect(mapStateToProps)(StockInformation));
