import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ErrorRoute } from 'views/layout';
import { AuthSelectors } from 'state/ducks/auth';
import Calendar from './Calendar';
import Basic from './Basic';

class CalendarContainer extends Component {
  render() {
    if (this.props.hasProTier) {
      if (this.props.isViewOnly) {
        return <ErrorRoute />;
      }
      return <Calendar {...this.props} />;
    }
    return <Basic {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps)(CalendarContainer);
