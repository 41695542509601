import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartStockStockActivityType } from 'sdk/SparePartStockActivity';
import { Button, Tooltip, Icon } from 'views/components/Shared/General';
import styles from './styles.module.scss';

const SparePartStockActivitySubtitle = ({
  purchaseOrder,
  workOrder,
  sparePartStockActivity,
  sparePartWithdrawal,
  stockTakingRow,
}) => {
  const renderSubtitle = () => {
    const { type } = sparePartStockActivity;
    if (type === SparePartStockStockActivityType.Delivery) {
      return (
        <div className={styles['link-container']}>
          <FormattedMessage
            id="components.stock-history-modal.purchase-order"
            values={{ number: purchaseOrder.number }}
          />
          <Tooltip
            trigger={
              <Link to={`/purchase-orders/${purchaseOrder.id}`}>
                <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
              </Link>
            }
            label={<FormattedMessage id="components.stock-history-modal.go-to-purchase-order" />}
          />
        </div>
      );
    }
    if (type === SparePartStockStockActivityType.Withdrawal) {
      if (workOrder) {
        return (
          <div className={styles['link-container']}>
            <span>{`#${workOrder.number} - ${workOrder.title}`}</span>
            <Tooltip
              trigger={
                <Link to={`/work-orders/${workOrder.id}`}>
                  <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.work-order" />}
            />
          </div>
        );
      } else {
        return <FormattedMessage id="components.stock-history-modal.free-withdrawal" />;
      }
    }
    if (type === SparePartStockStockActivityType.Activated) {
      return (
        <div className={styles['subtitle-container']}>
          <FormattedMessage id="components.stock-history-modal.activated-stock" />
        </div>
      );
    }
    if (type === SparePartStockStockActivityType.Deactivated) {
      return (
        <div className={styles['subtitle-container']}>
          <FormattedMessage id="components.stock-history-modal.deactivated-stock" />
        </div>
      );
    }
    if (type === SparePartStockStockActivityType.Adjustment) {
      return (
        <div className={styles['subtitle-container']}>
          <FormattedMessage id="components.stock-history-modal.stock-adjustment" />
        </div>
      );
    }
    if (type === SparePartStockStockActivityType.Stocktaking) {
      if (stockTakingRow.stocktaking_id) {
        return (
          <div className={styles['link-container']}>
            <FormattedMessage id="components.stock-history-modal.stocktaking" />
            <Tooltip
              trigger={
                <Link to={`/stocktaking/${stockTakingRow.stocktaking_id}`}>
                  <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                </Link>
              }
              label={<FormattedMessage id="components.stock-history-modal.go-to-stocktaking" />}
            />
          </div>
        );
      } else {
        return (
          <div className={styles['subtitle-container']}>
            <FormattedMessage id="components.stock-history-modal.removed-stocktaking" />
          </div>
        );
      }
    }
  };

  return renderSubtitle();
};

function mapStateToProps(state, ownProps) {
  const { sparePartStockActivity } = ownProps;
  if (sparePartStockActivity.type === SparePartStockStockActivityType.Delivery) {
    const purchaseOrderDeliveryRow = EntitySelectors.getPurchaseOrderDeliveryRow(
      state,
      sparePartStockActivity.purchase_order_delivery_row_id
    );
    return {
      purchaseOrder: EntitySelectors.getPurchaseOrder(state, purchaseOrderDeliveryRow.purchase_order_id),
    };
  }
  if (sparePartStockActivity.type === SparePartStockStockActivityType.Withdrawal) {
    const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(
      state,
      sparePartStockActivity.spare_part_withdrawal_id
    );
    return {
      sparePartWithdrawal,
      workOrder: EntitySelectors.getWorkOrder(state, sparePartWithdrawal.work_order_id),
    };
  }
  if (sparePartStockActivity.type === SparePartStockStockActivityType.Stocktaking) {
    return {
      stockTakingRow: EntitySelectors.getStocktakingRow(state, sparePartStockActivity.stocktaking_row_id),
    };
  }
  return {};
}

export default connect(mapStateToProps)(SparePartStockActivitySubtitle);
