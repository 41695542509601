import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Menu } from 'views/components/Shared/General';
import AllVendors from './AllVendors';
import styles from './style.module.scss';

class SelectVendorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      searchValue: '',
      isSearching: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ searchValue: '' });
    }
  }

  renderVendorList = () => {
    return (
      <AllVendors
        additionalApiParams={this.props.additionalApiParams}
        searchValue={this.state.searchValue}
        listItemRightComponent={this.props.listItemRightComponent}
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.hideCreateNewButton) {
      return null;
    }
    return (
      <>
        <Button
          primary
          onClick={this.props.onCreateVendor}
          label="components.select-vendor-modal.menu.create-button"
        />
        <div className={styles['separator']} />
      </>
    );
  };

  renderMenuButtons = () => {
    return (
      <Menu.Item selected title={<FormattedMessage id="components.select-vendor-modal.menu.all-vendors" />} />
    );
  };

  renderMenuContent = () => {
    return (
      <div className={styles['left-panel']}>
        {this.renderCreateButton()}
        {this.renderMenuButtons()}
      </div>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.select-vendor-modal.title" />}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
          searchable
          value={this.state.searchValue}
          onSearch={value => {
            this.setState({ searchValue: value, isSearching: true });
          }}
          onClear={() => {
            this.setState({ isSearching: true, searchValue: '' });
          }}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>{this.renderVendorList()}</Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

export default connect(null)(SelectVendorModal);
