import { Decimal } from 'decimal.js';

/*
  Calculates the quantity that should be ordered, returns null if it should not be ordered
*/
export function getShouldOrderQuantity(sparePart) {
  if (sparePart.reorder_point == null) return null;
  const stockQuantity = Decimal.max(new Decimal(sparePart.stock_quantity || 0), 0);
  const maxInStock = new Decimal(sparePart.maximum_quantity || 0);
  const orderedQuantity = new Decimal(sparePart.ordered_quantity || 0);
  const reorderPoint = new Decimal(sparePart.reorder_point || 0);
  if (stockQuantity.plus(orderedQuantity).lessThanOrEqualTo(reorderPoint)) {
    return Decimal.max(maxInStock.minus(stockQuantity).minus(orderedQuantity), 0).toString();
  }
  return null;
}

export function generateEmptySparePart() {
  return {
    title: '',
    spare_part_type_id: null,
    spare_part_location_id: null,
    purchase_price: null,
    stock_quantity: null,
    reorder_point: null,
    minimum_quantity: null,
    maximum_quantity: null,
    images: [],
  };
}
