import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const resetSelectedSpareParts = payload => ({ type: types.RESET_SELECTED_SPARE_PARTS, payload });
const selectSparePart = payload => ({ type: types.SELECT_SPARE_PART, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchSpareParts = () => ({ type: types.FETCH_SPARE_PARTS });

const fetchSparePartsSuccess = payload => ({
  type: types.FETCH_SPARE_PARTS_SUCCESS,
  payload,
});

export default {
  setNavigatedTo,
  showAppliedFilters,
  resetSelectedSpareParts,
  selectPage,
  selectSparePart,
  selectTotalEntries,
  hideSelectTotalEntries,
  selectFilterType,
  showAvailableFilters,
  addFilter,
  removeFilter,
  resetFilter,
  addQueryParameter,
  fetchSpareParts,
  fetchSparePartsSuccess,
};
