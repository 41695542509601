import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeAssetProductionSupervisor } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createAssetProductionSupervisor(systemId, params) {
  return dispatch => {
    return API.createAssetProductionSupervisor(systemId, params)
      .then(res => {
        const { entities } = normalizeAssetProductionSupervisor(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.ASSET_PRODUCTION_SUPERVISOR_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteAssetProductionSupervisor(assetProductionSupervisorId) {
  return (dispatch, getState) => {
    const assetProductionSupervisor =
      getState().entities.assetProductionSupervisorById[assetProductionSupervisorId];
    return API.deleteAssetProductionSupervisor(assetProductionSupervisorId)
      .then(res => {
        const payload = { assetProductionSupervisorId, assetProductionSupervisor };
        dispatch({ type: types.ASSET_PRODUCTION_SUPERVISOR_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
