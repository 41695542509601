import React from 'react';
import Content from './Content';
import Header from './Header';

export default props => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Header onCreatedNewVersion={props.onCreatedNewVersion} />
    <Content />
  </div>
);
