import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import queryString from 'query-string';
import { EntityOperations } from 'sdk/State/entities';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { MetersOperations, MetersSelectors } from 'state/ducks/meters';
import { normalizeAsset } from 'sdk/Schemas';
import { MenuOperations, MenuUtils } from 'state/ducks/menu';
import MeterImage from 'assets/images/EmptyDataSet/Meters.png';
import { Button, Menu, List, Pagination } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { AssetMenu } from 'views/components/Asset';
import MeterListItem from './MeterListItem';
import CreateMeterModal from './components/CreateMeterModal';
import styles from './style.module.scss';
import { ExportType } from 'sdk/Export';
import ExportModal from './ExportModal';
import { ChangeMeterValueModal } from 'views/components/Meter';

const fetchMetersRequest = SDKHelperFunctions.getCancelTokenForRequest();

const LIST_TYPES = {
  IsDue: 'is_due',
  All: 'all',
};

const MODAL_TYPES = {
  CreateMeter: 'create-meter',
  PdfExport: 'pdf-export',
  ExcelExport: 'excel-export',
};

class MeterList extends Component {
  constructor(props) {
    super(props);
    const { asset_with_tree_children_id } = queryString.parse(props.location.search);
    this.state = {
      searchTerm: props.queryParameters.search || '',
      isFetching: true,
      isFetchingMenu: true,
      showDueButton: false,
      overdue: 0,
      initialTreeParentId: null,
      editMeterValueId: null,
      modalOpen: null,
      showExportModal: false,
      showCreateMeterModal: false,
      selectedAssetId: asset_with_tree_children_id || null,
    };
  }

  componentDidMount() {
    const {
      resetSelectedMeters,
      selectMenuItem,
      intl: { formatMessage },
    } = this.props;

    HelperFunctions.setDocumentTitle(formatMessage({ id: 'screens.meters.document-title' }));
    selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Meters);
    resetSelectedMeters();

    this.fetchOverdueCount(this.stateCallback);
  }

  componentDidUpdate(
    {
      queryParameters: prevQueryParameters,
      totalEntriesIsSelected: prevTotalEntriesIsSelected,
      location: { search: prevSearch },
    },
    { modalOpen: prevModalOpen, overdue: prevOverdue }
  ) {
    const {
      queryParameters,
      totalEntriesIsSelected,
      resetSelectedMeters,
      location: { search },
    } = this.props;
    const { modalOpen, overdue } = this.state;

    const { list: oldList, asset_with_tree_children_id: oldMeterId } = queryString.parse(prevSearch);
    const { list: newList, asset_with_tree_children_id: newMeterId } = queryString.parse(search);
    const isNewListSelected = oldList !== newList || oldMeterId !== newMeterId;

    if (isNewListSelected) {
      resetSelectedMeters();
    }

    if (!isEqual(prevQueryParameters, queryParameters) || isNewListSelected) {
      this.setState({ isFetching: true });
      fetchMetersRequest.cancel();
      this.fetchMeters();
    }

    if (prevModalOpen !== modalOpen) {
      switch (this.state.modalOpen) {
        case MODAL_TYPES.PdfExport:
          this.setState({ showExportModal: true, showCreateMeterModal: false });
          break;

        case MODAL_TYPES.CreateMeter:
          this.setState({ showExportModal: false, showCreateMeterModal: true });
          break;

        default:
          this.setState({ showExportModal: false, showCreateMeterModal: false });
          break;
      }
    }

    if (prevOverdue !== overdue) {
      this.setState({ showDueButton: !!overdue });
    }

    if (HelperFunctions.onlyPageWasChangedFromQueryParams(prevQueryParameters, queryParameters)) {
      if (!totalEntriesIsSelected) {
        this.props.hideSelectTotalEntries();
      }
    }
  }

  getListParams = listType => {
    switch (listType) {
      case LIST_TYPES.IsDue: {
        return {
          is_due: true,
          sort: 'next_due_date',
          'sort-order': 'asc',
        };
      }

      case LIST_TYPES.All: {
        return {
          sort: 'title',
          'sort-order': 'asc',
        };
      }

      default: {
        return {};
      }
    }
  };

  setNavigatedTo = id => {
    const {
      location: { search },
    } = this.props;
    const { list } = queryString.parse(search);

    this.props.setNavigatedTo({
      search: {
        list,
        asset_with_tree_children_id: id,
      },
    });
  };

  changeList = list => {
    const { list: listInUrl } = queryString.parse(this.props.location.search);
    if (list === listInUrl) return;
    this.setState({ isFetching: true, selectedAssetId: null });
    const params = { list, asset_with_tree_children_id: null };
    this.changeQueryParams(params);
  };

  changeQueryParams = obj => {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        ...obj,
      })}`
    );
  };

  fetchSavedAsset = async assetId => {
    const { updateEntities } = this.props;

    if (!assetId) return;

    const { data } = await API.fetchAsset(assetId);
    const { entities, tree_parent_id } = normalizeAsset(data);
    updateEntities(entities);
    this.setState({ initialTreeParentId: tree_parent_id });
  };

  stateCallback = () => {
    const {
      location: { search },
      history: { replace },
    } = this.props;
    const { overdue } = this.state;
    const { list, asset_with_tree_children_id } = queryString.parse(search);

    if (asset_with_tree_children_id) {
      this.fetchMeters();
      return;
    }

    if (overdue > 0 && list == null) {
      replace(
        `?${SDKHelperFunctions.convertObjToQueryParameters({
          list: LIST_TYPES.IsDue,
        })}`
      );
    } else if (overdue === 0 && list !== LIST_TYPES.All) {
      replace(
        `?${SDKHelperFunctions.convertObjToQueryParameters({
          list: LIST_TYPES.All,
        })}`
      );
    }

    this.fetchMeters();
  };

  fetchOverdueCount = async (stateCallback = () => {}) => {
    const { currentSystem } = this.props;

    const { data } = await API.fetchMeterListCounts(currentSystem.id);

    this.setState({ overdue: data.is_due, isFetchingMenu: false }, stateCallback);
  };

  fetchMeters = async () => {
    const {
      location: { search },
      queryParameters,
      currentSystem,
      listMeters,
    } = this.props;
    const { list, asset_with_tree_children_id } = queryString.parse(search);

    const attrs = {
      ...this.getListParams(list),
      asset_with_tree_children_id: asset_with_tree_children_id,
      ...queryParameters,
    };

    await listMeters(currentSystem.id, attrs, fetchMetersRequest.getCancelTokenConfig());
    this.setState({ isFetching: false });
  };

  renderHeader = () => {
    const {
      addQueryParameter,
      resetSelectedMeters,
      intl: { formatMessage },
      pagination: { totalEntries },
    } = this.props;
    const { searchTerm, isFetching } = this.state;

    const onSearch = value => {
      this.setState({ isFetching: true, searchTerm: value });
      resetSelectedMeters();
    };
    const onDebouncedSearch = () => {
      addQueryParameter({ search: this.state.searchTerm, page: 1 });
    };
    const onClearSearch = () => {
      this.setState({ searchTerm: '', isFetching: true });
      addQueryParameter({ search: null, page: 1 });
      resetSelectedMeters();
    };

    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.meters.title" />}
        searchable
        searchValue={searchTerm}
        searchPlaceHolder={formatMessage({ id: 'general.search-placeholder' })}
        totalEntries={
          <FormattedPlural
            value={totalEntries}
            zero={
              <FormattedMessage
                id="screens.meters.pagination-meters.zero"
                values={{ amount: isFetching ? '--' : 0 }}
              />
            }
            one={
              <FormattedMessage
                id="screens.meters.pagination-meters.one"
                values={{ amount: isFetching ? '--' : 1 }}
              />
            }
            two={
              <FormattedMessage
                id="screens.meters.pagination-meters.two"
                values={{
                  amount: isFetching ? '--' : totalEntries,
                }}
              />
            }
            few={
              <FormattedMessage
                id="screens.meters.pagination-meters.few"
                values={{
                  amount: isFetching ? '--' : totalEntries,
                }}
              />
            }
            many={
              <FormattedMessage
                id="screens.meters.pagination-meters.many"
                values={{
                  amount: isFetching ? '--' : totalEntries,
                }}
              />
            }
            other={
              <FormattedMessage
                id="screens.meters.pagination-meters.other"
                values={{
                  amount: isFetching ? '--' : totalEntries,
                }}
              />
            }
          />
        }
        onSearch={onSearch}
        onDebouncedSearch={onDebouncedSearch}
        onClearSearch={onClearSearch}
      />
    );
  };

  renderSelectTotalEntries = () => {
    const {
      showSelectTotalEntries,
      totalEntriesIsSelected,
      meterIds,
      pagination: { totalEntries },
      selectTotalEntries,
      resetSelectedMeters,
    } = this.props;
    const { isFetching } = this.state;

    if (showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={isFetching}
          selected={totalEntriesIsSelected}
          selectedCount={meterIds.length}
          totalEntriesCount={totalEntries}
          onSelectAll={() => selectTotalEntries()}
          onDeselectAll={() => resetSelectedMeters()}
        />
      );
    }

    return null;
  };

  renderEmptyDataSetTitle = () => {
    const {
      location: { search },
    } = this.props;
    const { asset_with_tree_children_id } = queryString.parse(search);

    if (asset_with_tree_children_id) {
      return <FormattedMessage id="screens.meters.empty-data-set.asset-title" />;
    }

    return <FormattedMessage id="screens.meters.empty-data-set.title" />;
  };

  renderEmptyDataSet = (title, subtitle) => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>{title}</div>
      <div className={styles['subtitle']}>{subtitle}</div>
      <div className={styles['image-container']}>
        <img src={MeterImage} alt="" />
      </div>
    </div>
  );

  renderPagination = () => {
    const {
      meterIds,
      addQueryParameter,
      pagination: { totalPages },
      queryParameters: { page, page_size },
      resetSelectedMeters,
    } = this.props;

    const onSelectPage = page => {
      addQueryParameter({ page });
    };

    const onChangePageSize = page_size => {
      addQueryParameter({ page_size });
      resetSelectedMeters();
    };

    if (meterIds.length === 0) {
      return null;
    }

    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={page}
          totalPages={totalPages}
          pageSize={page_size}
          onSelectPage={onSelectPage}
          onChangePageSize={onChangePageSize}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderListHeader = () => {
    const { selectedMetersCount, pageIsSelected, totalEntriesIsSelected, selectPage, resetSelectedMeters } =
      this.props;
    const { isFetching } = this.state;
    const checked = !isFetching && (pageIsSelected || totalEntriesIsSelected);

    const onChecked = () => {
      if (isFetching) {
        return;
      }
      if (totalEntriesIsSelected) {
        resetSelectedMeters();
      } else {
        selectPage();
      }
    };

    const onClickPrintButton = () => {
      this.setState({
        modalOpen: MODAL_TYPES.PdfExport,
      });
    };

    return (
      <List.Header
        background
        small
        checkbox
        showMultipleOptions={selectedMetersCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={isFetching}
            count={selectedMetersCount}
            buttons={
              <List.Header.MultipleOptions.Button
                label={<FormattedMessage id="general.print" />}
                onClick={onClickPrintButton}
              />
            }
          />
        }
        checked={checked}
        onCheck={onChecked}
      >
        <List.Header.Column flex>
          <FormattedMessage id="resources.meter.resource" />
        </List.Header.Column>
        <List.Header.Column width={150}>
          <FormattedMessage id="resources.meter.value" />
        </List.Header.Column>
        <List.Header.Column alignRight>
          <FormattedMessage id="resources.meter.next-due-date" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListContent = amountOfMeters => {
    const { isFetching, isFetchingMenu } = this.state;
    const { meterIds, selectedMeterIds, totalEntriesIsSelected, selectMeter } = this.props;

    const generateLoadingItems = () => {
      if (!meterIds || amountOfMeters === 0) {
        return 2;
      }

      return amountOfMeters;
    };

    if (isFetching || isFetchingMenu) {
      return Array(generateLoadingItems())
        .fill()
        .map((_, index) => <MeterListItem key={index} loading />);
    }

    return meterIds.map(meterId => (
      <MeterListItem
        key={meterId}
        id={meterId}
        checked={totalEntriesIsSelected || !!selectedMeterIds[meterId]}
        checkboxDisabled={totalEntriesIsSelected}
        onCheck={() => selectMeter(meterId)}
        onEditValueClick={meterId => {
          this.setState({ editMeterValueId: meterId });
        }}
      />
    ));
  };

  renderList = () => {
    const { isFetching, isFetchingMenu, searchTerm } = this.state;
    const { meterIds } = this.props;
    const amountOfMeters = meterIds.length;

    const generateEmptyContainerContent = () => {
      if (searchTerm.length === 0) {
        return {
          title: this.renderEmptyDataSetTitle(),
          subtitle: <FormattedMessage id="screens.meters.empty-data-set.subtitle" />,
        };
      }

      return {
        title: <FormattedMessage id="screens.meters.empty-data-set.no-results.title" />,
        subtitle: <FormattedMessage id="screens.meters.empty-data-set.no-results.subtitle" />,
      };
    };

    if (!isFetching && !isFetchingMenu && amountOfMeters === 0) {
      const containerContent = generateEmptyContainerContent();

      return this.renderEmptyDataSet(containerContent.title, containerContent.subtitle);
    }

    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.renderListContent(amountOfMeters)}
        </List>
      </>
    );
  };

  renderAssetItems = () => {
    return (
      <AssetMenu
        onSelectAssetId={assetId => {
          this.setState({ selectedAssetId: assetId }, () => {
            this.changeQueryParams({ asset_with_tree_children_id: assetId, list: null });
          });
        }}
        selectedAssetId={this.state.selectedAssetId}
      />
    );
  };

  renderCreateButton = () => {
    const { canEditMeters } = this.props;

    if (!canEditMeters) {
      return null;
    }

    return (
      <>
        <Button
          primary
          fullWidth
          label="screens.meters.create-button"
          onClick={() => this.setState({ modalOpen: MODAL_TYPES.CreateMeter })}
        />
        <Menu.Separator />
      </>
    );
  };

  renderIsDueButton = list => {
    const { isFetchingMenu, overdue, showDueButton } = this.state;

    if (!showDueButton) {
      return null;
    }

    return (
      <Menu.Item
        selected={list === LIST_TYPES.IsDue}
        loading={isFetchingMenu}
        number={overdue}
        title={<FormattedMessage id="screens.meters.left-panel.is-due" />}
        onClick={() => {
          this.changeList(LIST_TYPES.IsDue);
        }}
      />
    );
  };

  renderLeftMenu = () => {
    const {
      location: { search },
    } = this.props;
    const { isFetchingMenu } = this.state;
    const { list } = queryString.parse(search);

    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-items']}>
            <ListLayout.Content.Menu.Content>
              {this.renderCreateButton()}
              {this.renderIsDueButton(list)}
              <Menu.Item
                selected={list === LIST_TYPES.All}
                loading={isFetchingMenu}
                title={<FormattedMessage id="screens.meters.left-panel.all" />}
                onClick={() => {
                  this.changeList(LIST_TYPES.All);
                }}
              />
            </ListLayout.Content.Menu.Content>
          </div>
          <div className={styles['asset-container']}>{this.renderAssetItems()}</div>
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => (
    <ListLayout.Content.MainContent>
      <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
      {this.renderPagination()}
    </ListLayout.Content.MainContent>
  );

  renderCreateMeterModal = () => {
    const { modalOpen } = this.state;
    const { resetSelectedMeters } = this.props;

    const onCreated = meterId => {
      this.setNavigatedTo();
      this.setState({ modalOpen: null });

      setTimeout(() => {
        this.props.history.push(`/meters/${meterId}`);
      }, 250);
    };

    const onCreatedWithReopen = () => {
      this.setState({ modalOpen: null });
      resetSelectedMeters();

      setTimeout(() => {
        this.setState({ modalOpen: MODAL_TYPES.CreateMeter });
      }, 200);
    };

    const onClose = () => {
      this.setState({ modalOpen: null });
    };

    return (
      <CreateMeterModal
        open={modalOpen === MODAL_TYPES.CreateMeter}
        onCreated={onCreated}
        onCreatedWithReopen={onCreatedWithReopen}
        onClose={onClose}
      />
    );
  };

  renderExportModal = () => {
    const {
      location: { search },
    } = this.props;
    const { modalOpen, showExportModal } = this.state;
    const { list, asset_with_tree_children_id } = queryString.parse(search);

    const filterExportType = modalType => {
      if (modalType === MODAL_TYPES.PdfExport) {
        return ExportType.MetersListPdf;
      }

      return null;
    };

    const filterListParams = (list, asset_with_tree_children_id) => {
      if (list) {
        return this.getListParams(list);
      }

      if (asset_with_tree_children_id) {
        return { asset_with_tree_children_id: asset_with_tree_children_id };
      }

      return {};
    };

    const onUserAction = () => {
      this.setState({ modalOpen: null });
    };

    return (
      <ExportModal
        open={showExportModal}
        exportType={filterExportType(modalOpen)}
        onSave={onUserAction}
        onClose={onUserAction}
        listParams={filterListParams(list, asset_with_tree_children_id)}
      />
    );
  };

  renderChangeMeterValueModal = () => {
    const { editMeterValueId } = this.state;

    const onClose = () => {
      this.setState({ editMeterValueId: null });
      this.fetchOverdueCount();
    };

    if (!editMeterValueId) {
      return null;
    }

    return (
      <ChangeMeterValueModal
        meterId={editMeterValueId}
        createForMeterId={editMeterValueId}
        open={editMeterValueId !== null}
        onClose={onClose}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderCreateMeterModal()}
        {this.renderExportModal()}
        {this.renderChangeMeterValueModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSelectedMeters: MetersOperations.resetSelectedMeters,
      selectMenuItem: MenuOperations.selectItem,
      listMeters: MetersOperations.listMeters,
      addQueryParameter: MetersOperations.addQueryParameter,
      updateEntities: EntityOperations.updateEntities,
      setNavigatedTo: MetersOperations.setNavigatedTo,
      selectPage: MetersOperations.selectPage,
      selectMeter: MetersOperations.selectMeter,
      selectTotalEntries: MetersOperations.selectTotalEntries,
      hideSelectTotalEntries: MetersOperations.hideSelectTotalEntries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    queryParameters: MetersSelectors.getQueryParameters(state),
    canEditMeters: AuthSelectors.canEditMeters(state),
    canEditMeterReadings: AuthSelectors.canEditMeterReadings(state),
    pagination: MetersSelectors.getPagination(state),
    meterIds: MetersSelectors.getMeterIds(state),
    totalEntriesIsSelected: MetersSelectors.getTotalEntriesIsSelected(state),
    pageIsSelected: MetersSelectors.getPageIsSelected(state),
    selectedMeterIds: MetersSelectors.getSelectedMeterIds(state),
    selectedMetersCount: MetersSelectors.getSelectedMetersCount(state),
    showSelectTotalEntries: MetersSelectors.getShowSelectTotalEntries(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeterList));
