import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeRequest } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createRequest(systemId, params) {
  return dispatch => {
    return API.createRequest(systemId, params)
      .then(res => {
        const { entities } = normalizeRequest(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.REQUEST_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateRequest(requestId, params, config) {
  return (dispatch, getState) => {
    const beforeEdit = getState().entities.requestById[requestId];
    return API.updateRequest(requestId, params, config)
      .then(res => {
        const { entities } = normalizeRequest(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { requestId, params, data: res.data, beforeEdit };
        dispatch({ type: types.REQUEST_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteRequest(requestId) {
  return dispatch => {
    return API.deleteRequest(requestId)
      .then(res => {
        const payload = { requestId };
        dispatch({ type: types.REQUEST_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function archiveRequest(requestId, params) {
  return dispatch => {
    return API.archiveRequest(requestId, params)
      .then(res => {
        const { entities } = normalizeRequest(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { requestId, params, data: res.data };
        dispatch({ type: types.REQUEST_ARCHIVED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function resetRequestFromArchive(requestId) {
  return dispatch => {
    return API.resetRequestFromArchive(requestId)
      .then(res => {
        const { entities } = normalizeRequest(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { requestId, data: res.data };
        dispatch({ type: types.REQUEST_RESETTED_FROM_ARCHIVED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
