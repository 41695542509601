import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Loader } from 'views/components/Shared/General';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import styles from './style.module.scss';
import EditorContainer from './EditorContainer';
import DndContextContainer from './DndContextContainer';
import Section from './Section';

class TemplateFields extends Component {
  state = {
    viewInitialized: false,
    listIsEmpty: false,
    isCreatingFirstField: false,
  };

  componentDidMount() {
    this.props
      .fetchTemplateFields(this.props.selectedSystem.id, { template_type: this.props.templateType })
      .then(templateFields => {
        this.setState({ viewInitialized: true, listIsEmpty: templateFields.length === 0 });
      })
      .catch(() => {
        //TODO: Show general error message
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.isCreatingFirstField === true) {
      if (nextProps.fieldsForColumnOne.length > 0 || nextProps.fieldsForColumnTwo > 0) {
        return { isCreatingFirstField: false };
      }
    }
    return null;
  }

  renderTemplateFields = () => (
    <DndContextContainer>
      <div className={styles['wrapper']}>
        <div className={styles['container']}>
          <div className={styles['fields']}>
            <Section id={1} key={1} />
            <Section id={2} key={2} />
          </div>
          <EditorContainer
            isCreatingFirstField={this.state.isCreatingFirstField}
            isSortingField={this.state.isDragging}
            templateType={this.props.templateType}
          />
        </div>
      </div>
    </DndContextContainer>
  );

  render() {
    if (!this.state.viewInitialized) {
      return <Loader small />;
    }
    return this.renderTemplateFields();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTemplateFields: SettingsOperations.fetchTemplateFields,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    fieldsForColumnOne: SettingsSelectors.getTemplateFields(state, 1),
    fieldsForColumnTwo: SettingsSelectors.getTemplateFields(state, 2),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateFields);
