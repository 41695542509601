import types from './types';

const INITIAL_STATE = {
  ids: [],
  isFetching: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        isFetching: true,
        ids: [],
      };
    case types.FETCH_USERS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        isFetching: false,
        ids,
      };
    }
    default:
      return state;
  }
};
