import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage, Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field } from 'views/components/Shared/General';

class AttachmentFolderModal extends Component {
  inputRef = null;

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      value: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      if (this.props.attachmentFolder) {
        const newState = {
          value: this.props.attachmentFolder.title,
        };
        this.setState(newState, () => {
          this.inputRef.select();
        });
      } else {
        const newState = {
          value: this.props.intl.formatMessage({ id: 'components.attachment-folder-modal.default-value' }),
        };
        this.setState(newState, () => {
          this.inputRef.select();
        });
      }
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.value) {
      this.save();
    }
  };

  save = () => {
    if (this.props.attachmentFolder) {
      this.updateAttachmentFolder();
    } else {
      this.createAttachmentFolder();
    }
  };

  createAttachmentFolder = () => {
    this.setState({ isSaving: true });

    const params = {
      title: this.state.value,
      attachment_folder_parent_id: this.props.parentAttachmentFolderId,
    };

    this.props.createAttachmentFolder(this.props.currentSystem.id, params).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.settings.assets.attachment-folders.created-success-message" />}
        />
      );
      this.setState({ value: '', isSaving: false });
      this.props.onClose();
    });
  };

  updateAttachmentFolder = () => {
    this.setState({ isSaving: true });

    const params = {
      title: this.state.value,
    };

    this.props.updateAttachmentFolder(this.props.attachmentFolder.id, params).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      this.setState({ isSaving: false });
      this.props.onClose();
    });
  };

  createAttachmentFolder = () => {
    this.setState({ isSaving: true });

    const params = {
      title: this.state.value,
      attachment_folder_parent_id: this.props.parentAttachmentFolderId,
    };
    this.props.createAttachmentFolder(this.props.currentSystem.id, params).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.settings.assets.attachment-folders.created-success-message" />}
        />
      );
      this.setState({ value: '', isSaving: false });
      this.props.onClose();
    });
  };

  renderHeaderTitle = () => (
    <FormattedMessage
      id={
        this.props.attachmentFolder
          ? 'components.attachment-folder-modal.title-edit'
          : 'components.attachment-folder-modal.title-new'
      }
    />
  );

  renderContent = () => (
    <Field.Text
      ref={ref => (this.inputRef = ref)}
      value={this.state.value}
      onChange={value =>
        this.setState({
          value,
        })
      }
      onKeyPress={this.handleKeyPress}
    />
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width="440">
        <Modal.Header title={this.renderHeaderTitle()} onClose={this.props.onClose} />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button
            primary
            disabled={!this.state.value}
            label="general.save"
            loading={this.state.isSaving}
            onClick={this.save}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAttachmentFolder: SDKReduxOperations.createAttachmentFolder,
      updateAttachmentFolder: SDKReduxOperations.updateAttachmentFolder,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AttachmentFolderModal));
