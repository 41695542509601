import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { downtimeId } = action.payload;
      if (state[downtimeId]) {
        return {
          ...state,
          [downtimeId]: {
            ...state[downtimeId],
            _deleted: true,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.WORK_ORDER_UPDATED: {
      const { workOrderId, params } = action.payload;
      if (params.downtime_id == null) {
        return Object.entries(state).reduce((acc, [id, downtime]) => {
          return {
            ...acc,
            [id]: {
              ...downtime,
              work_orders:
                downtime.work_orders && downtime.work_orders.includes(workOrderId)
                  ? downtime.work_orders.filter(id => id !== workOrderId)
                  : downtime.work_orders,
            },
          };
        }, {});
      }
      return state;
    }
    case SDKReduxTypes.DOWNTIME_REMOVED_FROM_WORK_ORDER: {
      const { downtimeId, workOrderId } = action.payload;
      if (state[downtimeId] && state[downtimeId].work_orders) {
        return {
          ...state,
          [downtimeId]: {
            ...state[downtimeId],
            work_orders: state[downtimeId].work_orders.filter(id => id !== workOrderId),
          },
        };
      }
    }

    case SDKReduxTypes.REQUEST_UPDATED: {
      const { beforeEdit, data } = action.payload;
      const previousDowntimeId = beforeEdit && beforeEdit.downtime_id;
      const currentDowntimeId = data && data.downtime_id;
      if (state[previousDowntimeId] && state[previousDowntimeId].requests && !currentDowntimeId) {
        return update(state, {
          [previousDowntimeId]: {
            requests: { $set: state[previousDowntimeId].requests.filter(id => id !== data.id) },
          },
        });
      }
    }

    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.downtimeById) {
        return HelperFunctions.mergeEntities(state, action.payload.downtimeById);
      }
      return state;

    default:
      return state;
  }
};
