import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Information, Activities, History, Costs } from './Tabs';

const View = ({ workOrder, canViewWorkOrderCosts, hasProTier }) => (
  <React.Fragment>
    <Switch>
      <Route path={`/work-orders/:id`} exact render={() => <Information workOrder={workOrder} />} />
      {workOrder.recurring_maintenance_id ? (
        <Route path={`/work-orders/:id/history`} exact component={History} />
      ) : null}
      {canViewWorkOrderCosts && hasProTier ? (
        <Route path={`/work-orders/:id/costs`} exact component={Costs} />
      ) : null}
      <Route path={`/work-orders/:id/activities`} exact component={Activities} />
    </Switch>
  </React.Fragment>
);

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  return {
    workOrder: EntitySelectors.getWorkOrder(state, workOrderId),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(View);
