import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API, HelperFunctions } from 'sdk';
import { normalizeUser } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { List, NewSearchField, WhiteCard, EmptyDataSet, Pagination } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import { EntityOperations } from 'sdk/State/entities';
import styles from './style.module.scss';
import UserListItem from './UserListItem';

const PAGE_SIZE = 6;
const listUsersRequest = HelperFunctions.getCancelTokenForRequest();

class SelectUserModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    isSearching: false,
    searchValue: '',
    userIds: [],
    pagination: {
      currentPage: 1,
      totalPages: 0,
    },
  });
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
      this.fetchUsers()
        .then(({ userIds, pagination }) => {
          this.setState({ isFetching: false, pagination, userIds });
        })
        .catch(() => {});
    }
  }

  fetchUsers = params => {
    listUsersRequest.cancel();
    return API.listUsers(
      this.props.currentSystem.id,
      {
        member: true,
        page_size: PAGE_SIZE,
        ...params,
      },
      listUsersRequest.getCancelTokenConfig()
    ).then(({ data: users, headers }) => {
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      const { entities, result: userIds } = normalizeUser(users);
      this.props.updateEntities(entities);
      return { userIds, pagination };
    });
  };

  renderSearchedEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="general.empty-data-set-search.title" />}
        subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
        image={SearchImage}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderPagination = () => {
    if (this.state.pagination.totalPages <= 1) return null;
    return (
      <div className={styles['pagination']}>
        <Pagination
          hideOptions
          currentPage={this.state.pagination.currentPage}
          totalPages={this.state.pagination.totalPages}
          onSelectPage={page => {
            this.setState(
              { isSearching: true, pagination: { ...this.state.pagination, currentPage: page } },
              () => {
                this.fetchUsers({ page: this.state.pagination.currentPage })
                  .then(({ userIds, pagination }) => {
                    this.setState({ isSearching: false, pagination, userIds });
                  })
                  .catch(() => {});
              }
            );
          }}
        />
      </div>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <List>
          <UserListItem loading />
          <UserListItem loading />
        </List>
      );
    }
    if (this.state.isSearching) {
      const amountofUsers = this.state.userIds.length === 0 ? 2 : this.state.userIds.length;
      return (
        <>
          <List>
            {Array(amountofUsers)
              .fill()
              .map(() => (
                <UserListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.state.userIds.length === 0) {
      return this.renderSearchedEmptyDataset();
    }
    return (
      <>
        <List>
          {this.state.userIds.map(id => (
            <UserListItem
              key={id}
              id={id}
              onSelect={() => {
                this.props.onSelect(id);
                this.props.onClose();
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderContent = () => {
    return (
      <React.Fragment>
        <div className={styles['search-field-container']}>
          <NewSearchField
            autoFocus
            small
            againstGrayBackground
            value={this.state.searchValue}
            debounce
            placeholder={this.props.intl.formatMessage({
              id: 'screens.settings.requests.assignees.creator-chooses.select-user-modal.search-placeholder',
            })}
            onSearch={value => {
              this.setState({
                searchValue: value,
                isSearching: true,
              });
            }}
            onDebouncedSearch={value => {
              this.fetchUsers({ search: value })
                .then(({ userIds, pagination }) => {
                  this.setState({ isSearching: false, pagination, userIds });
                })
                .catch(() => {});
            }}
            onClear={() => {
              this.setState({ isSearching: true, searchValue: '' });
              this.fetchUsers({ search: '' })
                .then(({ userIds, pagination }) => {
                  this.setState({ isSearching: false, pagination, userIds });
                })
                .catch(() => {});
            }}
          />
        </div>
        <div className={styles['list-container']}>{this.renderList()}</div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={650}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.select-user-modal.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>{this.renderContent()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectUserModal));
