import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Button, Icon } from 'views/components/Shared/General';
import { StatusColumn } from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class WorkOrderPurchaseListItem extends Component {
  renderDeleteButton = () => {
    if (this.props.canEditPurchaseOrders) {
      return (
        <Button
          type="icon"
          icon={<Icon regular red type="trash-alt" />}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onDelete();
          }}
        />
      );
    }
    return null;
  };
  render() {
    return (
      <List.Item
        clickable
        small
        new={this.props.new}
        onClick={this.props.onEdit}
        linkTo={`/work-orders/${this.props.workOrder.id}`}
        iconButtons={this.renderDeleteButton()}
      >
        <List.Item.TitleColumn
          title={
            <span>
              <span className={styles['number']}>#{this.props.workOrder.number} - </span>
              {this.props.workOrder.title}
            </span>
          }
          subtitle={
            this.props.workOrder.asset_id != null ? <TreePath assetId={this.props.workOrder.asset_id} /> : '-'
          }
        />
        <List.Item.Column width={120} alignRight lineRight>
          <StatusColumn workOrder={this.props.workOrder} />
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrderPurchase = EntitySelectors.getWorkOrderPurchase(state, ownProps.id);
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderPurchase.work_order_id);
  return {
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    workOrderPurchase,
    workOrder,
  };
}

export default connect(mapStateToProps)(WorkOrderPurchaseListItem);
