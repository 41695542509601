const getDowntimeIds = state => state.downtimes.ids;
const getNavigatedTo = state => state.downtimes.navigatedTo;
const getPagination = state => state.downtimes.pagination;
const getQueryParameters = state => state.downtimes.queryParams;
const getTotalEntries = state => state.downtimes.pagination.totalEntries;
const getTotalEntriesIsSelected = state => state.downtimes.selectedDowntimes.totalEntriesIsSelected;
const getPageIsSelected = state => state.downtimes.selectedDowntimes.pageIsSelected;
const getSelectedDowntimeIds = state => state.downtimes.selectedDowntimes.ids;
const getSelectedDowntimesCount = state =>
  state.downtimes.selectedDowntimes.totalEntriesIsSelected
    ? state.downtimes.pagination.totalEntries
    : Object.keys(state.downtimes.selectedDowntimes.ids).length;
const getShowSelectTotalEntries = state => state.downtimes.selectedDowntimes.showSelectTotalEntries;

export default {
  getDowntimeIds,
  getNavigatedTo,
  getPagination,
  getQueryParameters,
  getTotalEntriesIsSelected,
  getPageIsSelected,
  getSelectedDowntimeIds,
  getSelectedDowntimesCount,
  getShowSelectTotalEntries,
  getTotalEntries,
};
