import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { UserNameWrapper } from 'views/components/User';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { Datapill } from 'views/components/Shared/General';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

class UsedSpareParts extends Component {
  renderQuantityDataPill = () => {
    return (
      <Datapill
        value={
          <SparePartUnitWithValue
            value={this.props.sparePartWithdrawal.quantity}
            sparePartUnit={this.props.sparePartUnit}
          />
        }
      />
    );
  };

  renderCreatedByUserDataPill = () => {
    return <Datapill value={<UserNameWrapper user={this.props.createdByUser} />} />;
  };

  renderDataPills = () => {
    return (
      <div className={styles['datapills-container']}>
        <Datapill.List>
          {this.renderQuantityDataPill()}
          {this.renderCreatedByUserDataPill()}
        </Datapill.List>
      </div>
    );
  };

  render() {
    return (
      <div className={parentStyles['card']} onClick={this.props.onClick}>
        <div>
          <div>
            <Title sparePart={this.props.sparePart} />
          </div>
          <div>{this.renderDataPills()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);

  return {
    sparePartWithdrawal,
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    createdByUser: EntitySelectors.getUser(state, sparePartWithdrawal.created_by_user_id),
  };
}

export default connect(mapStateToProps)(UsedSpareParts);
