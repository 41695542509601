import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {
  InspectionOptions,
  CommentOptions,
  ChecklistInstanceRowComments,
  Attachments,
  Attachment,
} from 'views/components/Checklist/ChecklistTemplateRow/Components';
import { Field, FullScreenImagePicker } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { Image } from 'views/components/Image';
import DeviationText from './DeviationText';
import styles from './style.module.scss';

class Inspection extends Component {
  state = {
    showTemplateRowImages: false,
    showTemplateRowAttachments: false,
    showingImageDialog: false,
    currentImage: 0,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderCheckbox = () => {
    if (this.props.checkbox) {
      return (
        <div className={styles['checkbox-container']}>
          <Field.Checkbox
            checked={this.props.checked}
            onChange={value => this.props.onCheck(!this.props.checked)}
          />
        </div>
      );
    }
    return null;
  };

  renderRightContainer = () => {
    if (this.props.deviation) {
      return <DeviationText checklistInstanceRow={this.props.checklistInstanceRow} />;
    }
    return (
      <div className={styles['right-container']}>
        <InspectionOptions editable={this.props.editable} instanceRowId={this.props.instanceRowId} />
        <CommentOptions editable={this.props.editable} instanceRowId={this.props.instanceRowId} />
      </div>
    );
  };

  renderSeparator = () => {
    if (this.props.checklistInstanceRow) {
      const { checklistTemplateRowImages, checklistTemplateRowAttachments } = this.props;
      const { images, comment } = this.props.checklistInstanceRow;
      let hasTemplateAttachments =
        checklistTemplateRowImages.length > 0 || checklistTemplateRowAttachments.length > 0;
      if (hasTemplateAttachments && (images.length > 0 || comment)) {
        return <div className={styles['separator']} />;
      }
    }
    return null;
  };

  renderComments = () => {
    if (this.props.checklistInstanceRow) {
      return <ChecklistInstanceRowComments instanceRowId={this.props.instanceRowId} />;
    }
    return null;
  };

  renderImage = (image, index) => {
    return (
      <Image
        key={image.id}
        editable={false}
        medium
        image={image}
        onClick={() => {
          this.selectImage(index);
        }}
      />
    );
  };

  renderTemplateRowImages = () => {
    if (this.props.checklistTemplateRowImages.length > 0) {
      return (
        <AnimateHeight duration={250} height={this.state.showTemplateRowImages ? 'auto' : 0}>
          <div className={styles['images']}>
            {this.props.checklistTemplateRowImages.map((image, index) => this.renderImage(image, index))}
          </div>
        </AnimateHeight>
      );
    }
  };

  renderTemplateRowAttachments = () => {
    if (this.props.checklistTemplateRowAttachments.length > 0) {
      return (
        <AnimateHeight duration={250} height={this.state.showTemplateRowAttachments ? 'auto' : 0}>
          <div className={styles['attachments']}>
            {this.props.checklistTemplateRowAttachments.map(({ id, attachment_version }) => (
              <Attachment id={id} />
            ))}
          </div>
        </AnimateHeight>
      );
    }
  };

  renderAttachments = () => {
    const { checklistTemplateRowImages, checklistTemplateRowAttachments } = this.props;
    if (checklistTemplateRowImages.length === 0 && checklistTemplateRowAttachments.length === 0) {
      return null;
    }
    return (
      <div className={styles['attachments']}>
        <Attachments
          clickable={this.props.editable || this.props.instanceRowId == null}
          imagesIsSelected={this.state.showTemplateRowImages}
          attachmentsIsSelected={this.state.showTemplateRowAttachments}
          images={this.props.checklistTemplateRowImages}
          attachments={this.props.checklistTemplateRowAttachments}
          onClickImages={() => {
            this.setState(prevState => ({
              showTemplateRowAttachments: false,
              showTemplateRowImages: !prevState.showTemplateRowImages,
            }));
          }}
          onClickAttachments={() => {
            this.setState(prevState => ({
              showTemplateRowImages: false,
              showTemplateRowAttachments: !prevState.showTemplateRowAttachments,
            }));
          }}
        />
      </div>
    );
  };

  render() {
    let classNames = [styles['inspection-container']];
    if (this.props.checkbox) {
      classNames = [...classNames, styles['checkbox']];
    }
    return (
      <>
        <div
          className={classNames.join(' ')}
          onClick={() => {
            if (this.props.checkbox) {
              this.props.onCheck(!this.props.checked);
            }
          }}
        >
          <div className={styles['container']}>
            <div className={styles['inspection']}>
              {this.renderCheckbox()}
              <div className={styles['title-container']}>
                <div>{this.props.checklistTemplateRow.title}</div>
                {this.renderAttachments()}
              </div>
              {this.renderRightContainer()}
            </div>
            {this.renderTemplateRowImages()}
            {this.renderTemplateRowAttachments()}
            {this.renderSeparator()}
            {this.renderComments()}
          </div>
        </div>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.checklistTemplateRowImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id, instanceRowId } = ownProps;
  const checklistTemplateRow = EntitySelectors.getChecklistTemplateRow(state, id);
  return {
    checklistTemplateRow,
    checklistTemplateRowImages: EntitySelectors.getImages(state, checklistTemplateRow.images),
    checklistTemplateRowAttachments: EntitySelectors.getAttachments(state, checklistTemplateRow.attachments),
    checklistInstanceRow: EntitySelectors.getChecklistInstanceRow(state, instanceRowId),
  };
}

export default connect(mapStateToProps)(Inspection);

Inspection.propTypes = {
  id: PropTypes.string,
  instanceRowId: PropTypes.string,
  editable: PropTypes.bool,
  checkbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  deviation: PropTypes.bool,
};

Inspection.defaultProps = {
  id: null,
  instanceRowId: null,
  editable: true,
  checkbox: false,
  checked: false,
  onCheck: () => {},
  deviation: false,
};
