import React from 'react';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

const Container = ({ children }) => <div className={styles['container']}>{children}</div>;
const Option = props => {
  const classNames = () => {
    let classNames = [styles['option']];
    if (props.selected) {
      classNames = [...classNames, styles['selected']];
    }
    if (props.inactive) {
      classNames = [...classNames, styles['inactive']];
    }
    if (props.fullWidth) {
      classNames = [...classNames, styles['full-width']];
    }
    return classNames;
  };
  return (
    <div className={classNames().join(' ')} onClick={props.onClick}>
      <div className={styles['icon-wrapper']}>
        <div className={styles['icon-container']}>
          <Icon type={props.icon} />
        </div>
      </div>
      <div className={styles['content']}>
        <div className={styles['title']}>{props.title}</div>
        <div className={styles['subtitle']}>{props.subtitle}</div>
      </div>
    </div>
  );
};

Option.Container = Container;
export default Option;
