import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Menu } from 'views/components/Shared/General';

class TypeItem extends Component {
  render() {
    return (
      <Menu.Item
        onClick={this.props.onClick}
        selected={this.props.selectedType === this.props.assetType.id && this.props.activeItem === true}
        title={this.props.assetType.title}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    assetType: EntitySelectors.getAssetType(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(TypeItem);
