import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import moment from 'moment';
import { connect } from 'react-redux';
import styles from './style.module.scss';
import { Datapill } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class TemplateAssetRecurrenceVersionListItem extends Component {
  renderTimeOfDayDatapill = () => {
    if (this.props.operationalMaintenanceTemplateRecurrenceVersion.time_of_day) {
      return (
        <div>
          <Datapill
            value={moment(
              this.props.operationalMaintenanceTemplateRecurrenceVersion.time_of_day,
              'hh:mm'
            ).format('HH:mm')}
          />
        </div>
      );
    }
    return null;
  };

  renderTitle = () => {
    const { from_date, to_date } = this.props.operationalMaintenanceTemplateRecurrenceVersion;
    if (to_date == null) {
      return (
        <>
          <span>{moment(from_date).format('LL')}</span>
          <span> - </span>
        </>
      );
    }
    return (
      <>
        <span>{moment(from_date).format('LL')}</span>
        <span> - </span>
        <span>{moment(to_date).format('LL')}</span>
      </>
    );
  };

  renderTimeOfDay = () => {
    const activeVersion = this.props.operationalMaintenanceTemplateRecurrenceVersion;
    if (activeVersion.time_of_day) {
      return (
        <>
          <span>, </span>
          <span>{moment(activeVersion.time_of_day, 'hh:mm').format('LT')}</span>
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['item']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>{this.renderTitle()}</div>
          <div className={styles['subtitle']}>
            <span>
              {buildIntervalMessage(
                this.props.operationalMaintenanceTemplateRecurrenceVersion,
                this.props.intl
              )}
              {this.renderTimeOfDay()}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    operationalMaintenanceTemplateRecurrenceVersion:
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(state, id),
  };
}

export default injectIntl(connect(mapStateToProps)(TemplateAssetRecurrenceVersionListItem));
