import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { StatusInlineModal } from 'views/components/WorkOrder';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Status extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter.status || props.initialFilter.status;
    this.state = {
      comparator,
      statuses: value,
      hasValue: false,
      initialFilter: props.filter,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.Status,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.Status]: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.Status,
            data: {
              work_order: {
                ...this.props.filter,
                [FILTER_TYPES.Status]: {
                  comparator: this.state.comparator,
                  value: this.state.statuses,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    return this.state.statuses.length > 0;
  };

  /*
    Render functions
  */

  renderStatus = status => {
    switch (status) {
      case WorkOrderStatus.NotStarted:
        return <FormattedMessage id="resources.work-order.statuses.not-started" />;
      case WorkOrderStatus.InProgress:
        return <FormattedMessage id="resources.work-order.statuses.in-progress" />;
      case WorkOrderStatus.Paused:
        return <FormattedMessage id="resources.work-order.statuses.paused" />;
      case WorkOrderStatus.Completed:
        return <FormattedMessage id="resources.work-order.statuses.completed" />;
      default:
        return null;
    }
  };

  renderSelectedStatusText = () => {
    if (this.state.statuses.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.statuses}
        renderItem={status => this.renderStatus(status)}
      />
    );
  };

  renderTrigger = () => {
    return (
      <StatusInlineModal
        value={this.state.statuses}
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedStatusText()}
            onClear={() =>
              this.setState({
                statuses: [],
              })
            }
          />
        }
        onAddStatus={status => {
          this.setState({
            statuses: [...this.state.statuses, status],
          });
        }}
        onRemoveStatus={status => {
          this.setState({
            statuses: this.state.statuses.filter(item => item !== status),
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter.status, this.state.initialFilter.status)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Status,
                data: {
                  work_order: {
                    ...this.props.filter,
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Status,
                data: {
                  work_order: {
                    ...this.props.filter,
                    status: {
                      ...this.state.initialFilter.status,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order.status" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);

Status.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Status.defaultProps = {
  filter: {
    status: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
  initialFilter: {
    status: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Any,
      value: [],
    },
  },
};
