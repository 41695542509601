import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';

class DeleteContactPersonModal extends Component {
  state = {
    isDeleting: false,
    showConfirmDeleteModal: false,
  };

  getInitialState = () => ({
    isDeleting: false,
    showConfirmDeleteModal: true,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  delete() {
    this.setState({ isDeleting: true });
    this.props.deleteContactPerson(this.props.contactPerson.id).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.vendor.contact-persons.delete-success" />}
        />
      );
      this.props.onClose();
      this.setState({ showConfirmDeleteModal: false });
      this.setState({ isDeleting: false });
    });
  }

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={this.props.title}
        message={this.props.subtitle}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => {
          this.delete();
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          this.props.onClose();
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteContactPerson: SDKReduxOperations.deleteContactPerson,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  var contactPerson = null;
  if (ownProps.id) {
    contactPerson = EntitySelectors.getContactPerson(state, ownProps.id);
  }
  return {
    contactPerson,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContactPersonModal);
