import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { NewInlineModal, Field } from 'views/components/Shared/General';

class TemplateFieldDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  selectTemplateFieldItem = value => {
    if (this.props.ids.includes(value)) {
      this.props.onRemove(value);
    } else {
      this.props.onAdd(value);
    }
  };

  renderTemplateFieldItems = () => {
    return this.props.templateFieldItems.map(templateFieldItem => {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={e => this.selectTemplateFieldItem(templateFieldItem.id)}
          leftComponent={
            <Field.Checkbox
              checked={this.props.ids.includes(templateFieldItem.id)}
              onChange={() => {
                this.selectTemplateFieldItem(templateFieldItem.id);
              }}
            />
          }
        >
          {templateFieldItem.title}
        </NewInlineModal.Dropdown.Item>
      );
    });
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState({ isOpen: true })}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderTemplateFieldItems()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    templateFieldItems: EntitySelectors.getTemplateFieldItems(
      state,
      ownProps.templateField.template_field_items
    ),
  };
}

export default connect(mapStateToProps)(TemplateFieldDropdown);
