import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
class ChecklistDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  deleteChecklistTemplate = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteChecklistTemplate(this.props.checklistTemplate.id)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.checklist.dropdown.delete-modal.delete-success" />}
          />
        );
        this.props.onDelete();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '140004' })) {
          toast(
            <ToastMessage
              error
              text={
                <FormattedMessage id="screens.checklist.dropdown.delete-modal.errors.cant-delete-checklist-with-instances" />
              }
            />
          );
          this.props.onClose();
        }
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="screens.checklist.dropdown.delete-modal.confirm" />}
          subtitle={<FormattedMessage id="screens.checklist.dropdown.delete-modal.confirm-subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              destructive
              primary
              label="general.delete"
              loading={this.state.isDeleting}
              onClick={() => this.deleteChecklistTemplate()}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteChecklistTemplate: SDKReduxOperations.deleteChecklistTemplate,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(ChecklistDeleteModal));
