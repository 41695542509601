import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { Field } from 'views/components/Shared/General';
import { SideModal } from 'views/components/Shared/Layout';
import { VendorsSelectors, VendorsOperations } from 'state/ducks/vendors';
import { FILTER_TYPES } from 'views/scenes/Vendors/VendorList/FilterModal';

class Currency extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter;

    this.state = {
      comparator,
      value,
      hasValue: false,
      initialFilter: props.filter,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        this.props.addFilter({
          key: FILTER_TYPES.purchase_order_currency,
          data: {
            [FILTER_TYPES.purchase_order_currency]: {
              comparator: this.state.comparator,
              value: this.state.value,
            },
          },
        });
      }
    }
  }

  renderTrigger = () => {
    return (
      <Field.Currency
        value={this.state.value}
        clearable
        onChange={purchase_order_currency => {
          this.setState({
            value: purchase_order_currency,
          });
        }}
        onClear={() => {
          this.setState({
            value: '',
          });
        }}
      />
    );
  };

  hasFiltersApplied = () => {
    return this.state.value.length > 0;
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialValue, this.state.initialValue)) {
              this.props.removeFilter({
                key: FILTER_TYPES.purchase_order_currency,
                data: {
                  [FILTER_TYPES.purchase_order_currency]: this.props.initialValue,
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.purchase_order_currency,
                data: {
                  [FILTER_TYPES.purchase_order_currency]: this.state.initialValue,
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.vendor.purchase-order-currency" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            required
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: VendorsOperations.removeFilter,
      addFilter: VendorsOperations.addFilter,
      showAppliedFilters: VendorsOperations.showAppliedFilters,
      selectFilterType: VendorsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: VendorsSelectors.getFiltersForKey(state, FILTER_TYPES.purchase_order_currency),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Currency);

Currency.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Currency.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: '',
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: '',
  },
};
