import { request, groupRequest } from '../utilities/Axios';

export const getMe = () => request.get('me');
export const login = data => request.post('login', data);
export const loginGroup = data => groupRequest.post('login_user', data);
export const signOut = data => request.put('sign_out', data);
export const signOutGroup = () => groupRequest.put('sign_out');
export const validateResetPassword = token => request.put('validate_reset_password_token', { token });
export const verifyEmail = token => request.put('verify_email_with_token', { token });
export const requestResetPassword = username => request.post('request_reset_password', { username });
export const resetPassword = ({ token, password }) =>
  request.patch('reset_password_with_token', { token, password });
export const fetchInvite = inviteId => request.get(`invites/${inviteId}`);
export const acceptInvite = params => request.post(`login_credentials_with_token`, params);

export const oidcInitiate = data => request.post('oidc/initiate', data);