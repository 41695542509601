import actions from './actions';
import { buildAtlaskitTree } from 'sdk/Asset';
import { normalizeAsset } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API, HelperFunctions } from 'sdk';

const setNavigatedTo = actions.setNavigatedTo;

const resetSelectedAssets = actions.resetSelectedAssets;
const selectAsset = actions.selectAsset;
const selectPage = actions.selectPage;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;

const fetchAssets = (systemId, params, config) => dispatch => {
  return API.listAssets(systemId, params, config).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeAsset(res.data);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchAssetsSuccess({ ids: result, pagination }));
    return res.data;
  });
};

const expandTree = actions.expandTree;
const collapseTree = actions.collapseTree;

const fetchTree =
  (systemId, expandedIds = {}) =>
  dispatch => {
    dispatch(actions.fetchTree());
    return API.fetchTree(systemId).then(res => {
      const { entities } = normalizeAsset(res.data);
      const { totalEntries } = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));
      const tree = buildAtlaskitTree(res.data, {
        expandedIds,
      });
      dispatch(actions.fetchTreeSuccess({ tree, totalEntries }));
    });
  };

const moveTree = (assetId, source, destination) => dispatch => {
  dispatch(actions.moveTree(source, destination));

  const data = {};
  if (source.parentId !== destination.parentId) {
    if (destination.parentId === '1') {
      data.tree_parent_id = null;
    } else {
      data.tree_parent_id = destination.parentId;
    }
  }
  data.tree_sort = destination.index + 1;
  return API.updateAsset(assetId, data).then(res => res.data);
};

export default {
  showAppliedFilters,
  setNavigatedTo,
  resetSelectedAssets,
  selectPage,
  selectAsset,
  selectTotalEntries,
  hideSelectTotalEntries,
  addQueryParameter,
  resetFilter,
  removeFilter,
  addFilter,
  selectFilterType,
  showAvailableFilters,
  fetchAssets,
  fetchTree,
  expandTree,
  collapseTree,
  moveTree,
};
