const getPurchaseOrderRowForSparePartId = (state, sparePartId) =>
  state.shouldOrderSpareParts.purchaseOrderRowsBySparePartId[sparePartId];

const getSelectedVendor = state => {
  const { entities, shouldOrderSpareParts } = state;
  if (entities.vendorById) {
    return entities.vendorById[shouldOrderSpareParts.selectedVendorId];
  }
  return null;
};

const getPurchaseOrderRows = state => {
  const { shouldOrderSpareParts } = state;
  return Object.values(shouldOrderSpareParts.purchaseOrderRowsBySparePartId);
};

export default {
  getSelectedVendor,
  getPurchaseOrderRowForSparePartId,
  getPurchaseOrderRows,
};
