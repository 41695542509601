import React, { Component } from 'react';
import { uniqBy } from 'lodash-es';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, List, EmptyDataSet, WhiteCard, NewInlineModal } from 'views/components/Shared/General';
import { AddSparePartModal } from 'views/components/WorkOrder';
import { NewPurchaseOrderModal } from 'views/components/PurchaseOrder';
import { NewSparePartModal } from 'views/components/SparePart';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import SparePartListItem from './SparePartListItem';
import AddSparePartRowModal from './AddSparePartRowModal';
import styles from './style.module.scss';

class SpareParts extends Component {
  constructor(props) {
    super(props);
    const { sparePartWithdrawals, sparePartReservations, spareParts, loading } = props;
    this.state = {
      isCreatingWithdrawals: false,
      showAddSparePartModal: false,
      showAddSparePartRowModal: false,
      showAddSparePartDropdown: false,
      showCreateNewSparePartModal: false,
      showCreateNewSparePartModalParams: {},
      createdWorkOrderSparePartIds: [],
      workOrderSpareParts: loading
        ? []
        : this.generateWorkOrderSpareParts({
            sparePartWithdrawals,
            sparePartReservations,
            spareParts,
          }),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) return;
    const { sparePartWithdrawals, sparePartReservations, spareParts } = this.props;
    const addedOrRemovedparePartWithdrawal =
      prevProps.sparePartWithdrawals &&
      prevProps.sparePartWithdrawals.length != this.props.sparePartWithdrawals.length;
    const addedOrRemovedSparePartReservation =
      prevProps.sparePartReservations &&
      prevProps.sparePartReservations.length != this.props.sparePartReservations.length;
    let createdWorkOrderSparePartIds = this.state.createdWorkOrderSparePartIds;
    if (prevProps.sparePartWithdrawals.length < this.props.sparePartWithdrawals.length) {
      const newSparePartWithdrawals = this.props.sparePartWithdrawals.filter(
        ({ id: id1 }) => !prevProps.sparePartWithdrawals.some(({ id: id2 }) => id2 === id1)
      );
      let workOrderSparePartId = newSparePartWithdrawals[0];
      createdWorkOrderSparePartIds = [
        ...this.state.createdWorkOrderSparePartIds,
        workOrderSparePartId.spare_part_id || workOrderSparePartId.id,
      ];
    }
    if (addedOrRemovedparePartWithdrawal || addedOrRemovedSparePartReservation) {
      this.setState({
        workOrderSpareParts: this.generateWorkOrderSpareParts({
          sparePartWithdrawals,
          sparePartReservations,
          spareParts,
        }).sort(
          (a, b) => createdWorkOrderSparePartIds.indexOf(b.id) - createdWorkOrderSparePartIds.indexOf(a.id)
        ),
        createdWorkOrderSparePartIds,
      });
    }
  }

  /*

         * Transform sparePartWithdrawals, sparePartReservations and its spareParts to workOrderSpareParts
         * @param  [sparePartWithdrawals] array of normalized sparePartWithdrawals
         * @param  [sparePartReservations] array of normalized sparePartReservations
         * @param  [spareParts] array of normalized spareParts

         * @return workOrderSpareParts [{
            id: string,
            title: string,
            sort: string,
            spare_part_id: number,
            spare_part_reservation_id: number,
            spare_part_withdrawal_ids: [number],
            sort: string ( sparePart.title ),
         }] to be displayed on screen

      */
  generateWorkOrderSpareParts = ({ sparePartWithdrawals, sparePartReservations, spareParts }) =>
    uniqBy(
      [
        ...sparePartWithdrawals.map(sparePartWithdrawal => {
          const sparePartForWithdrawal = spareParts
            .filter(sparePart => sparePart.id == sparePartWithdrawal.spare_part_id)
            .map(sparePart => sparePart)[0];
          let title = sparePartWithdrawal.title;
          if (sparePartForWithdrawal) {
            title = sparePartForWithdrawal.title;
          }
          let id = sparePartWithdrawal.id;
          if (sparePartForWithdrawal) {
            id = sparePartForWithdrawal.id;
          }
          let spare_part_withdrawal_ids = [sparePartWithdrawal.id];
          if (sparePartForWithdrawal) {
            spare_part_withdrawal_ids = sparePartWithdrawals
              .filter(spw => spw.spare_part_id == sparePartForWithdrawal.id)
              .map(({ id }) => id);
          }
          return {
            id,
            title,
            sort: title,
            spare_part_id: sparePartWithdrawal.spare_part_id,
            spare_part_withdrawal_ids: spare_part_withdrawal_ids,
            spare_part_reservation_id:
              sparePartReservations.find(
                sparePartReservation =>
                  sparePartReservation.spare_part_id == sparePartWithdrawal.spare_part_id
              ) == null
                ? null
                : sparePartReservations.find(
                    sparePartReservation =>
                      sparePartReservation.spare_part_id == sparePartWithdrawal.spare_part_id
                  ).id,
          };
        }),
        ...sparePartReservations.map(sparePartReservation => {
          const sparePartForResevation = spareParts
            .filter(sparePart => sparePart.id == sparePartReservation.spare_part_id)
            .map(sparePart => sparePart)[0];
          let id = sparePartReservation.id;
          if (sparePartForResevation) {
            id = sparePartForResevation.id;
          }
          return {
            id,
            title: sparePartForResevation.title,
            sort: sparePartForResevation.title,
            spare_part_id: sparePartReservation.spare_part_id,
            spare_part_reservation_id: sparePartReservation.id,
            spare_part_withdrawal_ids:
              sparePartWithdrawals.find(
                sparePartWithdrawal => sparePartWithdrawal.spare_part_id == sparePartReservation.spare_part_id
              ) == null
                ? null
                : [
                    ...sparePartWithdrawals
                      .filter(spw => spw.spare_part_id == sparePartReservation.spare_part_id)
                      .map(({ id }) => id),
                  ],
          };
        }),
      ],
      workOrderSparePart => workOrderSparePart.id
    ).sort((a, b) => a.sort.localeCompare(b.sort));

  renderNewPurchaseOrderModal = () => (
    <NewPurchaseOrderModal
      forWorkOrderId={this.props.workOrder.id}
      open={this.state.showNewPurchaseOrderModal}
      onClose={() => {
        this.setState({ showNewPurchaseOrderModal: false });
      }}
      onCreated={purchaseOrder => {
        this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard padding="38px">
      <EmptyDataSet
        title={<FormattedMessage id="screens.work-order.used-spare-parts.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.work-order.used-spare-parts.empty-data-set.subtitle" />}
        image={SparePartSmall}
        button={
          this.props.canCarryOutWorkOrders ? (
            <Button.Group>{this.renderAddNewSparePartDropdown()}</Button.Group>
          ) : null
        }
        micro
        horizontal
        centered={false}
      />
    </WhiteCard>
  );

  createSparePartWithdrawalsForWorkOrder = addedSpareParts => {
    if (this.state.isCreatingWithdrawals) return;
    this.setState({ isCreatingWithdrawals: true });
    let requestsToWaitFor = addedSpareParts.map(({ spare_part_id, quantity }) =>
      this.props.createSparePartWithdrawalForWorkOrder(this.props.workOrder.id, {
        spare_part_id,
        quantity,
      })
    );
    Promise.all(requestsToWaitFor).then(results => {
      const createdSparePartWithdrawals = results.map(({ data }) => data);
      if (createdSparePartWithdrawals.length === 1) {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />}
          />
        );
      } else {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-parts-success" />}
          />
        );
      }
      this.setState({
        isCreatingWithdrawals: false,
        showAddSparePartModal: false,
      });
    });
  };

  renderAddSparePartModal = () => {
    return (
      <AddSparePartModal
        open={this.state.showAddSparePartModal}
        assetId={this.props.workOrder.asset_id}
        isSaving={this.state.isCreatingWithdrawals}
        sparePartReservations={this.props.sparePartReservations}
        excludeSparePartIds={this.props.sparePartWithdrawals.map(({ spare_part_id }) => spare_part_id)}
        onAddSpareParts={addedSpareParts => this.createSparePartWithdrawalsForWorkOrder(addedSpareParts)}
        onClose={() => this.setState({ showAddSparePartModal: false })}
      />
    );
  };

  renderAddEmptyRowSparePartModal = () => {
    return (
      <AddSparePartRowModal
        isSaving={this.state.isCreatingWithdrawals}
        workOrder={this.props.workOrder}
        open={this.state.showAddSparePartRowModal}
        onSaveAndAddToSystem={sparePartWithdrawal => {
          const {
            title,
            spare_part_unit_id,
            purchase_price,
            purchase_price_currency,
            purchase_price_exchange_rate,
          } = sparePartWithdrawal;
          this.setState({
            showAddSparePartRowModal: false,
            showCreateNewSparePartModal: true,
            showCreateNewSparePartModalParams: {
              title,
              spare_part_unit_id,
              purchase_price,
              purchase_price_currency,
              purchase_price_exchange_rate,
              spare_part_withdrawal_id: sparePartWithdrawal.id,
            },
          });
        }}
        onClose={() => this.setState({ showAddSparePartRowModal: false })}
      />
    );
  };

  renderNewSparePartModal = () => {
    return (
      <NewSparePartModal
        title={<FormattedMessage id="screens.work-order.used-spare-parts.new-spare-part-modal-title" />}
        disableCreateMultiple
        open={this.state.showCreateNewSparePartModal}
        defaultParams={this.state.showCreateNewSparePartModalParams}
        onClose={() => {
          this.setState({ showCreateNewSparePartModal: false, showCreateNewSparePartModalParams: {} });
        }}
        onCreated={() => {
          this.setState({ showCreateNewSparePartModal: false, showCreateNewSparePartModalParams: {} });
        }}
      />
    );
  };

  renderAddNewSparePartDropdown = () => {
    return (
      <>
        <div ref={ref => (this.newSparePartDropdownRef = ref)}>
          <Button
            small
            primary
            caret
            onClick={() =>
              this.setState(prevState => ({
                showAddSparePartDropdown: !prevState.showAddSparePartDropdown,
              }))
            }
            label="general.add"
          />
        </div>
        <NewInlineModal
          positionToRef={this.newSparePartDropdownRef}
          open={this.state.showAddSparePartDropdown}
          position="left"
          minWidth={260}
          onClose={() => this.setState({ showAddSparePartDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() =>
                  this.setState({ showAddSparePartDropdown: false, showAddSparePartModal: true })
                }
              >
                <FormattedMessage id="screens.work-order.reserved-spare-parts.add-spare-part-dropdown.choose-spare-part-button" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() =>
                  this.setState({ showAddSparePartDropdown: false, showAddSparePartRowModal: true })
                }
              >
                <FormattedMessage id="screens.work-order.reserved-spare-parts.add-spare-part-dropdown.add-line-button" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderContentWithHeader = content => (
    <WhiteCard noPadding>
      <div className={styles['header']}>
        <div className={styles['title-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.used-spare-parts.title" />
          </p>
        </div>
        {this.props.canCarryOutWorkOrders ? (
          <Button.Group>{this.renderAddNewSparePartDropdown()}</Button.Group>
        ) : null}
      </div>
      {content}
    </WhiteCard>
  );

  renderList = () => {
    return this.renderContentWithHeader(
      <List light usedInWhiteCard>
        {this.state.workOrderSpareParts.map(workOrderSparePart => (
          <SparePartListItem
            key={workOrderSparePart.id}
            workOrderSparePart={workOrderSparePart}
            onCreateSparePart={sparePartWithdrawal => {
              const {
                title,
                spare_part_unit_id,
                purchase_price,
                purchase_price_currency,
                purchase_price_exchange_rate,
              } = sparePartWithdrawal;
              this.setState({
                showCreateNewSparePartModal: true,
                showCreateNewSparePartModalParams: {
                  title,
                  spare_part_unit_id,
                  purchase_price,
                  purchase_price_currency,
                  purchase_price_exchange_rate,
                  spare_part_withdrawal_id: sparePartWithdrawal.id,
                },
              });
            }}
          />
        ))}
      </List>
    );
  };

  renderContent = () => {
    if (this.props.loading) {
      return this.renderContentWithHeader(
        <List light usedInWhiteCard>
          <SparePartListItem loading />
        </List>
      );
    }
    if (this.state.workOrderSpareParts.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderList();
    }
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderAddSparePartModal()}
        {this.renderAddEmptyRowSparePartModal()}
        {this.renderNewPurchaseOrderModal()}
        {this.renderNewSparePartModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.workOrder.spare_part_withdrawals == null) {
    return {
      loading: true,
      sparePartWithdrawals: [],
    };
  }
  const sparePartWithdrawals =
    EntitySelectors.getSparePartWithdrawals(state, ownProps.workOrder.spare_part_withdrawals) || [];
  const sparePartReservations =
    EntitySelectors.getSparePartReservations(state, ownProps.workOrder.spare_part_reservations) || [];
  const sparePartIds = uniqBy(
    [...sparePartWithdrawals, ...sparePartReservations].filter(wosp => wosp.spare_part_id != null),
    wosp => wosp.spare_part_id
  ).map(wosp => wosp.spare_part_id);

  const spareParts = EntitySelectors.getSpareParts(state, sparePartIds) || [];

  return {
    sparePartWithdrawals,
    sparePartReservations,
    spareParts,
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartWithdrawalForWorkOrder: SDKReduxOperations.createSparePartWithdrawalForWorkOrder,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpareParts));
