import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeAsset } from 'sdk/Schemas';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { List, WhiteCard } from 'views/components/Shared/General';
import AssetListItem from '../../components/AssetList/AssetListItem';
import styles from './style.module.scss';

const listAssetTreeRequest = SDKHelperFunctions.getCancelTokenForRequest();

class AssetTreeList extends Component {
  state = {
    assetIds: null,
    loading: true,
  };

  componentDidMount() {
    this.fetchTree();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.treeParentId !== this.props.treeParentId) {
      this.fetchTree();
    }
  }

  fetchTree = () => {
    let params = {
      no_pagination: true,
      sort: 'tree_sort',
      archived: false,
      ...this.props.additionalApiParams,
    };

    if (this.props.treeParentId !== 'root') {
      params = { ...params, tree_parent_id: this.props.treeParentId };
      listAssetTreeRequest.cancel();
    } else {
      params = {
        ...params,
        tree_parent_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
        },
      };
    }

    this.setState({ loading: true });
    return API.listAssets(this.props.currentSystem.id, params, listAssetTreeRequest.getCancelTokenConfig())
      .then(({ data: assets }) => {
        const { entities, result } = normalizeAsset(assets);
        this.props.updateEntities(entities);
        this.setState({ loading: false, assetIds: result });

        return result;
      })
      .catch(() => this.setState({ loading: false }));
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-asset-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderEmptyDataSet = () => {
    let key;
    if (this.props.parentAssetId === 'root') {
      key = 'components.select-asset-modal.content.empty-list';
    } else {
      key = 'components.select-asset-modal.content.empty-content';
    }

    return (
      <WhiteCard centerContent padding="50px">
        <span className={styles['empty']}>
          <FormattedMessage id={key} />
        </span>
      </WhiteCard>
    );
  };

  renderLoading = () => (
    <>
      {this.renderListHeader()}
      <List>
        <AssetListItem loading multiple={this.props.multiple} />
        <AssetListItem loading multiple={this.props.multiple} />
      </List>
    </>
  );

  renderItems = () =>
    this.state.assetIds.map(id => {
      return (
        <AssetListItem
          id={id}
          listItemRightComponent={this.props.listItemRightComponent}
          alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
          onClick={() => this.props.onNavigateToAsset(id)}
          multiple={this.props.multiple}
          selectedAssetIds={this.props.selectedAssetIds}
          onSelectMultiple={id => this.props.onSelectMultiple(id)}
          treeItem
        />
      );
    });

  render() {
    if (this.state.loading) return this.renderLoading();
    if (this.state.assetIds.length === 0) return this.renderEmptyDataSet();

    return (
      <>
        {this.renderListHeader()}
        <List>{this.renderItems()}</List>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetTreeList);
