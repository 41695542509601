import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { injectIntl, FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Icon, Field } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { SettingsSelectors } from 'state/ducks/settings';
import styles from './style.module.scss';
import { SDKReduxOperations } from 'sdk';

class ManageItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      editingItem: null,
      newItemTitle: null,
      showConfirmDeleteValueModal: false,
      showConfirmDeleteValueModalForValueId: null,
      confirmDeleteValue: false,
      templateFieldItems: props.templateFieldItems,
      createdTemplateFieldItems: [],
      createdTemplateFieldsItemIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templateFieldItems.length < this.props.templateFieldItems.length) {
      const { id } = this.props.templateFieldItems.find(
        ({ id: id1 }) => !prevProps.templateFieldItems.some(({ id: id2 }) => id2 === id1)
      );
      this.setState({
        createdTemplateFieldsItemIds: [id, ...this.state.createdTemplateFieldsItemIds],
      });
    }
  }

  createTemplateFieldItem = () => {
    this.setState({ isSaving: true });
    this.props
      .createTemplateFieldItem(this.props.selectedTemplateField.id, { title: this.state.newItemTitle })
      .then(res => {
        this.setState({
          isSaving: false,
          newItemTitle: '',
          newItem: null,
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.create-item-success" />}
          />
        );
        this.newFieldRef.focus();
      })
      .catch(e => {
        //TODO: Show general error message
      });
  };

  updateTemplateFieldItem = (templateFieldItemId, params) => {
    this.setState({ isSaving: true });
    this.props
      .updateTemplateFieldItem(templateFieldItemId, params)
      .then(res => {
        this.setState({ isSaving: false, editingItem: null });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.update-item-success" />}
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  deleteTemplateFieldItem = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteTemplateFieldItem(this.state.showConfirmDeleteValueModalForValueId)
      .then(() => {
        this.setState({
          editingItem: null,
          showConfirmDeleteValueModal: false,
          showConfirmDeleteValueModalForValueId: null,
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.delete-item-success" />}
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  handleKeyPressOnEditingItem = e => {
    if (e.key === 'Enter' && this.state.editingItem.title) {
      this.updateTemplateFieldItem(this.state.editingItem.id, { title: this.state.editingItem.title });
    }
  };

  handleKeyPressOnNewItem = e => {
    if (e.key === 'Enter' && this.state.newItemTitle) {
      this.createTemplateFieldItem();
    }
  };

  renderViewTemplateFieldItem = item => {
    return (
      <div
        key={item.id}
        className={`${styles['item']} ${styles['view']} `}
        onClick={() => this.setState({ editingItem: item, newItemTitle: null })}
      >
        <p>{item.title}</p>
        <Button
          type="icon"
          icon={<Icon regular red type="trash-alt" />}
          onClick={e => {
            e.stopPropagation();
            this.setState({
              showConfirmDeleteValueModal: true,
              confirmDeleteValue: false,
              isDeleting: false,
              showConfirmDeleteValueModalForValueId: item.id,
            });
          }}
        />
      </div>
    );
  };

  renderEditTemplateFieldItem = () => (
    <div className={`${styles['item']} ${styles['edit']} `}>
      <div className={styles['edit-item']}>
        <Field.Text
          autoFocus
          value={this.state.editingItem.title}
          onKeyPress={this.handleKeyPressOnEditingItem}
          onChange={value => this.setState({ editingItem: { ...this.state.editingItem, title: value } })}
        />
      </div>
      <Button.Group>
        <Button
          primary
          small
          label="general.save"
          loading={this.state.isSaving}
          disabled={!this.state.editingItem.title}
          onClick={() =>
            this.updateTemplateFieldItem(this.state.editingItem.id, { title: this.state.editingItem.title })
          }
        />
        <Button
          small
          label="general.cancel"
          onClick={() => {
            this.setState({ editingItem: null });
          }}
        />
      </Button.Group>
    </div>
  );

  renderNewTemplateFieldItem = () => {
    return this.state.newItemTitle == null ? null : (
      <div className={`${styles['item']} ${styles['edit']} `}>
        <div className={styles['edit-item']}>
          <Field.Text
            autoFocus
            ref={ref => (this.newFieldRef = ref)}
            value={this.state.newItemTitle}
            onKeyPress={this.handleKeyPressOnNewItem}
            onChange={value => this.setState({ newItemTitle: value })}
          />
        </div>
        <Button.Group>
          <Button
            primary
            small
            label="general.save"
            loading={this.state.isSaving}
            disabled={!this.state.newItemTitle}
            onClick={this.createTemplateFieldItem}
          />
          <Button
            small
            label="general.cancel"
            onClick={() => {
              this.setState({ newItemTitle: null });
            }}
          />
        </Button.Group>
      </div>
    );
  };

  renderDeleteValueConfirmModal = () => (
    <Modal width={480} isOpen={this.state.showConfirmDeleteValueModal}>
      <Modal.Header
        title={<FormattedMessage id="components.asset-delete.confirm-title" />}
        onClose={() => this.setState({ showConfirmDeleteValueModal: false })}
        ignoreLine
      />
      <Modal.Content>
        <div className={styles['message']}>
          {this.props.templateType === 'asset' ? (
            <FormattedMessage id="screens.settings.assets.template-fields.delete-value-confirm-message" />
          ) : (
            <FormattedMessage id="screens.settings.spare-parts.template-fields.delete-value-confirm-message" />
          )}
        </div>
        <Field.Checkbox
          checked={this.state.confirmDeleteValue}
          onChange={value => this.setState({ confirmDeleteValue: value })}
          label={<FormattedMessage id="components.asset-delete.confirm-checkbox-text" />}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button.Group>
          <Button
            destructive
            disabled={!this.state.confirmDeleteValue}
            primary
            label={<FormattedMessage id="general.delete" />}
            loading={this.state.isDeleting}
            onClick={this.deleteTemplateFieldItem}
            translate={false}
          />
          <Button
            label="general.cancel"
            onClick={() => this.setState({ showConfirmDeleteValueModal: false })}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  render() {
    return (
      <>
        <div className={styles['go-back']} onClick={this.props.onGoBack}>
          <Icon type="arrow-alt-left" size={12} />
          <FormattedMessage id="general.done" />
        </div>
        <p className={styles['title']}>
          <FormattedMessage id="screens.settings.assets.template-fields.manage-items" />
        </p>
        <p className={styles['subtitle']}>
          <FormattedMessage id="screens.settings.assets.template-fields.manage-items-subtitle" />
        </p>
        <Button
          type="text"
          small
          primary
          noUnderline
          fontSize={12}
          label="general.add"
          onClick={() => this.setState({ editingItem: null, newItemTitle: '' })}
        />
        <div className={styles['items']}>
          {this.renderNewTemplateFieldItem()}
          {this.props.templateFieldItems
            .filter(({ id }) => this.state.createdTemplateFieldsItemIds.includes(id))
            .map(field =>
              this.state.editingItem != null && this.state.editingItem.id === field.id
                ? this.renderEditTemplateFieldItem(field)
                : this.renderViewTemplateFieldItem(field)
            )}
          {this.props.templateFieldItems
            .filter(({ id }) => this.state.createdTemplateFieldsItemIds.includes(id) === false)
            .sort((a, b) => {
              if (a.title < b.title) {
                return -1;
              }
              if (a.title > b.title) {
                return 1;
              }
              return 0;
            })
            .map(field =>
              this.state.editingItem != null && this.state.editingItem.id === field.id
                ? this.renderEditTemplateFieldItem(field)
                : this.renderViewTemplateFieldItem(field)
            )}
        </div>
        {this.renderDeleteValueConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createTemplateFieldItem: SDKReduxOperations.createTemplateFieldItem,
      deleteTemplateFieldItem: SDKReduxOperations.deleteTemplateFieldItem,
      updateTemplateFieldItem: SDKReduxOperations.updateTemplateFieldItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    selectedTemplateField: SettingsSelectors.getSelectedTemplateField(state),
    templateFieldItems: SettingsSelectors.getTemplateFieldItemsForSelectedTemplateField(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageItems));
