import moment from 'moment';
import types from './types';

const INITIAL_STATE = {
  isRefreshing: false,
  productionBoardActivityIds: [],
  productionBoardActivityDate: moment().format('YYYY-MM-DD'),
  openRequestIds: [],
  operationalMaintenancesDate: moment().format('YYYY-MM-DD'),
  operationalMaintenanceIds: [],
  openRequestsCount: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_IS_REFRESHING: {
      return {
        ...state,
        isRefreshing: action.payload,
      };
    }
    case types.SET_PRODUCTION_BOARD_ACTIVITY_DATE: {
      return {
        ...state,
        productionBoardActivityDate: action.payload,
      };
    }
    case types.SET_OPERATIONAL_MAINTENANCE_DATE: {
      return {
        ...state,
        operationalMaintenancesDate: action.payload,
      };
    }
    case types.FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        productionBoardActivityIds: ids,
      };
    }
    case types.FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        operationalMaintenanceIds: ids,
      };
    }
    case types.FETCH_REQUESTS_FOR_DASHBOARD_SUCCESS: {
      const { ids, totalEntries } = action.payload;
      return {
        ...state,
        openRequestIds: ids,
        openRequestsCount: totalEntries,
      };
    }

    default:
      return state;
  }
};
