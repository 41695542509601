import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineModal, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class SortInlineModal extends Component {
  state = {
    isOpen: false,
  };

  renderSortText = () => {
    const { sort } = this.props;
    switch (sort) {
      case 'title':
        return <FormattedMessage id="screens.files.sort-options.title" />;
      case 'created_at':
        return <FormattedMessage id="screens.files.sort-options.created-at" />;
      case 'updated_at':
        return <FormattedMessage id="screens.files.sort-options.updated-at" />;
      case 'link_count':
        return <FormattedMessage id="screens.files.sort-options.link-count" />;
      default:
        return <FormattedMessage id="general.sort" />;
    }
  };

  render() {
    const { sort } = this.props;
    return (
      <>
        <div
          className={styles['sort']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          <p>{this.renderSortText()}</p>
          <Icon type="sort" />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            <InlineModal.ListItem
              selected={sort == null || sort === 'title'}
              title={<FormattedMessage id="screens.files.sort-options.title" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSort('title', 'asc');
              }}
            />
            <InlineModal.ListItem
              selected={sort === 'created_at'}
              title={<FormattedMessage id="screens.files.sort-options.created-at" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSort('created_at', 'desc');
              }}
            />
            <InlineModal.ListItem
              selected={sort === 'updated_at'}
              title={<FormattedMessage id="screens.files.sort-options.updated-at" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSort('updated_at', 'desc');
              }}
            />
            <InlineModal.ListItem
              selected={sort === 'link_count'}
              title={<FormattedMessage id="screens.files.sort-options.link-count" />}
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSort('link_count', 'desc');
              }}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}
