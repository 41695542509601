import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { Button, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import SparePartLocationContent from './SparePartLocationContent';
import styles from './style.module.scss';

class SelectSparePartLocationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSparePartLocationId: null,
      parentSparePartLocationId: null,
      isFetching: false,
      isEmpty: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        parentSparePartLocationId: this.props.defaultSparePartLocationId,
        selectedSparePartLocationId: this.props.selectedSparePartLocationId,
        isFetching: true,
      });
      API.listSparePartLocations(this.props.currentSystem.id)
        .then(({ data: sparePartLocations }) => {
          const { entities } = normalizeSparePartLocation(sparePartLocations);
          this.props.updateEntities(entities);
          this.setState({ isFetching: false, isEmpty: sparePartLocations.length === 0 });
        })
        .catch(() => {
          this.setState({ isFetching: false });
        });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <React.Fragment>
          <Modal.Content hasFooter grayBackground>
            <SparePartLocationContent isFetching />
          </Modal.Content>
          <Modal.Footer container>
            <Button.Group>
              <Button
                primary
                disabled={this.state.selectedSparePartLocationId == null}
                label="general.choose"
                loading={this.state.isSaving}
                onClick={() => {
                  this.props.onSelectLocation(this.state.selectedSparePartLocationId);
                  this.props.onClose();
                }}
              />
              {this.props.selectedSparePartLocationId ? (
                <Button
                  label="general.clean"
                  onClick={() => {
                    this.props.onSelectLocation(null);
                    this.props.onClose();
                  }}
                />
              ) : null}
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </React.Fragment>
      );
    }
    if (this.state.isEmpty) {
      return (
        <Modal.Content grayBackground>
          <WhiteCard centerContent>
            <EmptyDataSet
              title={
                <FormattedMessage id="components.select-spare-part-location-modal.empty-dataset.title" />
              }
              subtitle={
                <FormattedMessage id="components.select-spare-part-location-modal.empty-dataset.subtitle" />
              }
              button={
                <Button
                  primary
                  label="components.select-spare-part-location-modal.empty-dataset.button"
                  small
                  onClick={() => this.props.history.push('/settings/spare-parts/storage')}
                />
              }
              small
            />
          </WhiteCard>
        </Modal.Content>
      );
    }
    return (
      <React.Fragment>
        <Modal.Content hasFooter grayBackground>
          <SparePartLocationContent
            hideSparePartCount={this.props.hideSparePartCount}
            selectedSparePartLocationId={this.state.selectedSparePartLocationId}
            parentSparePartLocationId={this.state.parentSparePartLocationId}
            onSelect={selectedSparePartLocationId => this.setState({ selectedSparePartLocationId })}
            onNavigate={parentSparePartLocationId => this.setState({ parentSparePartLocationId })}
          />
        </Modal.Content>
        <Modal.Footer container>
          <div className={styles['button-group']}>
            <div>
              <Button
                primary
                disabled={this.state.selectedSparePartLocationId == null}
                label="general.choose"
                loading={this.state.isSaving}
                onClick={() => {
                  this.props.onSelectLocation(this.state.selectedSparePartLocationId);
                  this.props.onClose();
                }}
              />
            </div>
            {this.props.selectedSparePartLocationId || this.props.missingLocationIsSelected ? (
              <div>
                <Button
                  label="general.clean"
                  onClick={() => {
                    this.props.onSelectLocation(null);
                    this.props.onClose();
                  }}
                />
              </div>
            ) : null}
            <div>
              <Button label="general.cancel" onClick={this.props.onClose} />
            </div>
            {this.props.showMissingLocationOption ? (
              <div className={styles['no-location']}>
                <Button
                  label="components.select-spare-part-location-modal.no-location"
                  onClick={() => {
                    this.props.onSelectNoLocation();
                    this.props.onClose();
                  }}
                />
              </div>
            ) : null}
          </div>
        </Modal.Footer>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1080}>
        <Modal.Header
          title={<FormattedMessage id={'components.select-spare-part-location-modal.title'} />}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectSparePartLocationModal));

SelectSparePartLocationModal.propTypes = {
  open: PropTypes.bool,
  selectedSparePartLocationId: PropTypes.string,
  defaultSparePartLocationId: PropTypes.string,
  showMissingLocationOption: PropTypes.bool,
  missingLocationIsSelected: PropTypes.bool,
  onSelectLocation: PropTypes.func,
  onSelectNoLocation: PropTypes.func,
  onClose: PropTypes.func,
  hideSparePartCount: PropTypes.bool,
};
SelectSparePartLocationModal.defaultProps = {
  open: false,
  selectedSparePartLocationId: null,
  defaultSparePartLocationId: null,
  showMissingLocationOption: false,
  missingLocationIsSelected: false,
  hideSparePartCount: false,
  onSelectLocation: () => {},
  onSelectNoLocation: () => {},
  onClose: () => {},
};
