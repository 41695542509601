import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, FormattedNumber, Loader } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';

class MeterItem extends PureComponent {
  renderSubtitle = () => {
    if (this.props.showingForAsset) {
      return this.props.asset.title;
    }
    if (this.props.meter.asset_id) {
      return <TreePath fullPath assetId={this.props.meter.asset_id} />;
    }
    return null;
  };
  render() {
    return (
      <List.Item clickable onClick={() => this.props.onSelect(this.props.id)}>
        <List.Item.TitleColumn title={this.props.meter.title} subtitle={this.renderSubtitle()} />
        <List.Item.Column alignRight>
          <FormattedNumber
            value={this.props.meter.value}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </List.Item.Column>
        {this.props.isSaving ? (
          <List.Item.Column alignRight>
            <Loader tiny />
          </List.Item.Column>
        ) : null}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.id);
  return {
    meter,
    asset: EntitySelectors.getAsset(state, meter.asset_id),
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
  };
}

export default connect(mapStateToProps)(MeterItem);
