import React from 'react';
import { Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';
import MainterLogo from 'assets/images/LoginLogo.png';

const AppLoader = () => {
  return (
    <div className={styles['app-loader']}>
      <div>
        <Loader light />
      </div>
      <div>
        <img src={MainterLogo} />
      </div>
    </div>
  );
};

export default AppLoader;
