import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip } from 'views/components/Shared/General';
import { CalendarSelectors } from 'state/ducks/calendar';
import { getMinutesFromElapsedMinutes, getHoursFromElapsedMinutes } from 'sdk/WorkOrder';
import styles from '../style.module.scss';

class EstimatedTimeContainer extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.calendarInstances, nextProps.calendarInstances) ||
      !isEqual(this.props.draggingWorkOrderId, nextProps.draggingWorkOrderId) ||
      !isEqual(this.props.estimatedTime, nextProps.estimatedTime)
    );
  }

  renderEstimatedTime = () => {
    let estimatedHrs = getHoursFromElapsedMinutes(this.props.estimatedTime);
    let estimatedMin = getMinutesFromElapsedMinutes(this.props.estimatedTime);

    if (estimatedHrs === 0 && estimatedMin === 0) return null;
    return (
      <span>
        {estimatedHrs > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-hrs" values={{ value: estimatedHrs }} />
        ) : null}
        <span> </span>
        {estimatedMin > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-min" values={{ value: estimatedMin }} />
        ) : null}
      </span>
    );
  };

  render() {
    return (
      <div
        className={`${styles['total-estimated-time-container']} ${
          this.props.draggingWorkOrderId != null ? styles['is-dragging'] : ''
        }`}
      >
        <Tooltip
          trigger={
            <div className={styles['button']} onClick={this.props.onCreateWorkOrder}>
              <Icon regular type="plus-circle" />
            </div>
          }
          label={<FormattedMessage id="screens.calendar.plan-work-order-tooltip" />}
        />

        <div className={styles['time']}>{this.renderEstimatedTime()}</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    estimatedTime: CalendarSelectors.getEstimatedTimeForDay(state, ownProps.calendarInstances),
    draggingWorkOrderId: CalendarSelectors.getDraggingWorkOrderId(state),
  };
}

export default connect(mapStateToProps)(EstimatedTimeContainer);
