import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, SectionHeader, Icon } from 'views/components/Shared/General';
import { PreviewRequestModal } from 'views/components/Request';
import { CardGrid } from 'views/components/Shared/Layout';
import { ChecklistDeviationActionModal } from 'views/components/Checklist';
import { PreviewWorkOrderModal } from 'views/components/WorkOrder';
import SelectRequestsModal from './SelectRequestsModal';
import styles from './styles.module.scss';

class CreatedFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRequestsModal: false,
      showRequestModal: false,
      showRequestModalForId: null,
      showPreviewWorkOrderModal: false,
      showPreviewWorkOrderModalForId: null,
      showChecklistDeviationActionModal: false,
      showChecklistDeviationActionModalForId: null,
      openedRequestModalFromSelectRequestsModal: false,
    };
  }

  renderAmountOfAdditionalRequests = () => {
    const amountOfAdditionalRequests = this.props.requests.length;
    if (amountOfAdditionalRequests > 1) {
      return <div className={styles['additional-requests']}>+{amountOfAdditionalRequests - 1}</div>;
    }
  };

  renderSelectRequestsModal = () => {
    return (
      <SelectRequestsModal
        open={this.state.showSelectRequestsModal}
        requests={this.props.requests}
        onSelect={requestId => {
          this.setState({ showSelectRequestsModal: false });
          setTimeout(() => {
            this.setState({
              openedRequestModalFromSelectRequestsModal: true,
              showRequestModal: true,
              showRequestModalForId: requestId,
            });
          }, 200);
        }}
        onClose={() => this.setState({ showSelectRequestsModal: false })}
      />
    );
  };

  renderRequestModal = () => {
    return (
      <PreviewRequestModal
        open={this.state.showRequestModal}
        id={this.state.showRequestModalForId}
        onClose={() => {
          if (this.state.openedRequestModalFromSelectRequestsModal) {
            this.setState({ showRequestModal: false });
            setTimeout(() => {
              this.setState({
                openedRequestModalFromSelectRequestsModal: false,
                showSelectRequestsModal: true,
              });
            }, 200);
          } else {
            this.setState({ showRequestModal: false });
          }
        }}
      />
    );
  };

  renderPreviewWorkOrderModal = () => {
    return (
      <PreviewWorkOrderModal
        open={this.state.showPreviewWorkOrderModal}
        id={this.state.showPreviewWorkOrderModalForId}
        onClose={() => {
          this.setState({ showPreviewWorkOrderModal: false });
        }}
      />
    );
  };

  renderChecklistDeviationActionModal = () => {
    return (
      <ChecklistDeviationActionModal
        id={this.state.showChecklistDeviationActionModalForId}
        open={this.state.showChecklistDeviationActionModal}
        onClose={() => this.setState({ showChecklistDeviationActionModal: false })}
      />
    );
  };

  renderContent = () => {
    if (this.props.createdFromWorkOrder) {
      return (
        <>
          <div
            className={styles['content-container']}
            onClick={() => {
              this.setState({
                showPreviewWorkOrderModal: true,
                showPreviewWorkOrderModalForId: this.props.createdFromWorkOrder.id,
              });
            }}
          >
            <div className={styles['content']}>
              <div className={styles['value-container']}>
                <div className={styles['label']}>
                  <span>
                    <FormattedMessage id="resources.work-order.resource" />
                  </span>
                  <span> </span>
                  <span>#{this.props.createdFromWorkOrder.number}</span>
                </div>
                <div className={styles['value']}>{this.props.createdFromWorkOrder.title}</div>
              </div>
            </div>
          </div>
          {this.renderPreviewWorkOrderModal()}
        </>
      );
    }
    if (this.props.requests.length > 0) {
      const request = this.props.requests[0];
      return (
        <>
          <div
            className={styles['content-container']}
            onClick={() => {
              if (this.props.requests.length === 1) {
                this.setState({
                  showRequestModal: true,
                  showRequestModalForId: request.id,
                });
              } else {
                this.setState({ showSelectRequestsModal: true });
              }
            }}
          >
            <div className={styles['content']}>
              <div className={styles['value-container']}>
                <div className={styles['label']}>
                  <span>
                    <FormattedMessage id="resources.request.resource" />
                  </span>
                  <span> </span>
                  <span>#{request.number}</span>
                </div>
                <div className={styles['value']}>{request.title}</div>
              </div>
              {this.renderAmountOfAdditionalRequests()}
            </div>
          </div>
          {this.renderSelectRequestsModal()}
          {this.renderRequestModal()}
        </>
      );
    }
    if (this.props.checklistDeviationAction) {
      const { checklistTemplateVersion } = this.props;
      return (
        <>
          <div
            className={styles['content-container']}
            onClick={() =>
              this.setState({
                showChecklistDeviationActionModal: true,
                showChecklistDeviationActionModalForId: this.props.checklistDeviationAction.id,
              })
            }
          >
            <div className={styles['content']}>
              <div className={styles['value-container']}>
                <div className={styles['label']}>
                  <FormattedMessage id="resources.checklist-instance.resource" />
                </div>
                <div className={styles['value']}>{checklistTemplateVersion.title}</div>
              </div>
              <Icon withBackground backgroundSize={22} size={11} type="list" />
            </div>
          </div>
          {this.renderChecklistDeviationActionModal()}
        </>
      );
    }
  };

  render() {
    const { requests, checklistDeviationAction, createdFromWorkOrder } = this.props;
    if (
      (requests == null || requests.length === 0) &&
      checklistDeviationAction == null &&
      createdFromWorkOrder == null
    ) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <WhiteCard noPadding>
            <SectionHeader noBorderTop paddingHorizontal={26}>
              <div className={styles['section']}>
                <FormattedMessage id="screens.work-order.created-from.title" />
              </div>
            </SectionHeader>
            {this.renderContent()}
          </WhiteCard>
        </CardGrid.Row>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { workOrder } = ownProps;
  const createdFromWorkOrder = EntitySelectors.getWorkOrder(state, workOrder.created_from_work_order_id);
  const requests = EntitySelectors.getRequests(state, workOrder.created_from_requests);
  const checklistDeviationAction = EntitySelectors.getChecklistDeviationAction(
    state,
    workOrder.created_from_checklist_deviation_action
  );
  if (checklistDeviationAction) {
    const checklistInstance = EntitySelectors.getChecklistInstance(
      state,
      checklistDeviationAction.checklist_instance_id
    );
    return {
      checklistDeviationAction,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version_id
      ),
      workOrder: EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id),
      requests: [],
    };
  }
  return {
    requests,
    createdFromWorkOrder,
  };
}

export default withRouter(connect(mapStateToProps)(CreatedFrom));
