import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  EmptyDataSet,
  NewSearchField,
  Field,
  List,
  Pagination,
  Icon,
} from 'views/components/Shared/General';
import { SparePartTypeInlineModal, SparePartTypePath } from 'views/components/SparePart';
import AnimateHeight from 'react-animate-height';
import { Modal } from 'views/components/Shared/Layout';
import SelectedSparePartItem from './SelectedSparePartItem';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeSparePart } from 'sdk/Schemas';
import { API, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import SparePartTiny from 'assets/images/EmptyDataSet/SparePartTiny.png';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import SparePartListItem from './SparePartListItem';

class SelectSparePartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSparePartIds: {},
      inactiveSparePartIds: {},
      filters: {
        search: '',
        spare_part_type_with_children_id: null,
      },
      isSearching: false,
      spareParts: [],
      totalPages: 0,
      page: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        isSearching: true,
        selectedSparePartIds: this.props.selectedSparePartIds.reduce((a, b) => ((a[b] = true), a), {}),
        inactiveSparePartIds: this.props.inactiveSparePartIds.reduce((a, b) => ((a[b] = true), a), {}),
      });
      this.fetchSpareParts().then(({ spareParts, headers }) => {
        const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
        this.setState({
          spareParts,
          isSearching: false,
          totalPages,
        });
      });
    }
  }

  fetchSpareParts = () => {
    const params = {
      ...this.state.filters,
      page: this.state.page,
      page_size: 8,
      sort: 'title',
      'sort-order': 'asc',
    };

    return API.listSpareParts(this.props.currentSystem.id, params).then(res => {
      const { entities, result: sparePartIds } = normalizeSparePart(res.data);
      this.props.updateEntities(entities);
      return { spareParts: sparePartIds.map(id => entities.sparePartById[id]), headers: res.headers };
    });
  };

  changePage = page => {
    this.setState({ page, isSearching: true }, () => {
      this.fetchSpareParts().then(({ spareParts, headers }) => {
        const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
        this.setState({
          spareParts,
          isSearching: false,
          totalPages,
        });
      });
    });
  };

  selectSparePart = sparePartId => {
    if (this.state.selectedSparePartIds[sparePartId] === true) {
      let newselectedSparePartIds = { ...this.state.selectedSparePartIds };
      delete newselectedSparePartIds[sparePartId];
      this.setState({
        selectedSparePartIds: newselectedSparePartIds,
      });
    } else {
      let newselectedSparePartIds = { ...this.state.selectedSparePartIds };
      newselectedSparePartIds[sparePartId] = true;
      this.setState({
        selectedSparePartIds: newselectedSparePartIds,
      });
    }
  };

  renderSelectedSpareParts = () => {
    return (
      <div className={styles['selected-spare-parts-container']}>
        <PerfectScrollbar>
          <div className={`${styles['selected-spare-parts-content']}`}>
            {Object.keys(this.state.selectedSparePartIds).length === 0 ? (
              <EmptyDataSet
                title={
                  <FormattedMessage id="components.file-add-related-spare-parts.selected-empty-data-set.title" />
                }
                image={SparePartTiny}
                tiny
              />
            ) : (
              Object.keys(this.state.selectedSparePartIds).map(sparePartId => (
                <SelectedSparePartItem
                  id={sparePartId}
                  onClear={() => {
                    let newselectedSparePartIds = { ...this.state.selectedSparePartIds };
                    delete newselectedSparePartIds[sparePartId];
                    this.setState({
                      selectedSparePartIds: newselectedSparePartIds,
                    });
                  }}
                />
              ))
            )}
          </div>
        </PerfectScrollbar>
      </div>
    );
  };

  renderSearchField = () => (
    <div className={styles['search-container']}>
      <div className={styles['search-field']}>
        <NewSearchField
          isShowingMoreFilters={this.state.showAdvancedSearch}
          value={this.state.filters.search}
          placeholder={this.props.intl.formatMessage({
            id: 'components.file-add-related-spare-parts.search-field-placeholder',
          })}
          debounce
          showMoreFiltersButton
          onSearch={value => {
            this.setState({
              isSearching: true,
              filters: { ...this.state.filters, search: value },
            });
          }}
          onDebouncedSearch={value => {
            this.fetchSpareParts().then(({ spareParts, headers }) => {
              const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
              this.setState({ spareParts, isSearching: false, totalPages, page: 1 });
            });
          }}
          onClear={() => {
            this.setState(
              {
                isSearching: true,
                filters: { ...this.state.filters, search: '' },
              },
              () =>
                this.fetchSpareParts().then(({ spareParts, headers }) => {
                  const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
                  this.setState({ spareParts, isSearching: false, totalPages, page: 1 });
                })
            );
          }}
          onClickMoreFilters={() => {
            const { q } = this.state.filters;
            let resetSearch = this.state.showAdvancedSearch && q.length === 0;
            this.setState(prevState => ({
              showAdvancedSearch: !prevState.showAdvancedSearch,
              filters: {
                ...prevState.filters,
                spare_part_type_with_children_id: resetSearch
                  ? null
                  : prevState.filters.spare_part_type_with_children_id,
              },
            }));
          }}
        />
      </div>

      <AnimateHeight duration={250} height={this.state.showAdvancedSearch ? 'auto' : 0}>
        <div className={styles['advanced-search']}>
          <SparePartTypeInlineModal
            trigger={
              <div className={styles['inline-modal-trigger']}>
                <Field edit label={<FormattedMessage id="resources.spare-part.category" />}>
                  <Field.Resource
                    icon={<Icon type="tag" />}
                    value={
                      this.state.filters.spare_part_type_with_children_id == null ? null : (
                        <SparePartTypePath
                          fullPath
                          dark
                          size={13}
                          sparePartTypeId={this.state.filters.spare_part_type_with_children_id}
                        />
                      )
                    }
                    onClear={() => {
                      this.setState(
                        {
                          isSearching: true,
                          filters: {
                            ...this.state.filters,
                            spare_part_type_with_children_id: null,
                          },
                        },
                        () =>
                          this.fetchSpareParts().then(({ spareParts, headers }) => {
                            const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
                            this.setState({ spareParts, isSearching: false, totalPages, page: 1 });
                          })
                      );
                    }}
                  />
                </Field>
              </div>
            }
            selectedSparePartTypeId={this.state.filters.spare_part_type_with_children_id}
            editable={false}
            onSelectSparePartType={id => {
              this.setState(
                {
                  isSearching: true,
                  filters: {
                    ...this.state.filters,
                    spare_part_type_with_children_id: id,
                  },
                },
                () =>
                  this.fetchSpareParts().then(({ spareParts, headers }) => {
                    const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
                    this.setState({ spareParts, isSearching: false, totalPages, page: 1 });
                  })
              );
            }}
            onClearSparePartType={() =>
              this.setState(
                {
                  isSearching: true,
                  filters: { ...this.state.filters, spare_part_type_with_children_id: null },
                },
                () =>
                  this.fetchSpareParts().then(({ spareParts, headers }) => {
                    const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
                    this.setState({ spareParts, isSearching: false, totalPages, page: 1 });
                  })
              )
            }
          />
        </div>
      </AnimateHeight>
    </div>
  );

  renderSpareParts = () => {
    if (this.state.isSearching) {
      return (
        <List light>
          {Array(this.state.spareParts.length === 0 ? 1 : this.state.spareParts.length)
            .fill()
            .map(() => (
              <List.Item>
                <List.Item.ImageColumn loading />
                <List.Item.TitleColumn loading />
              </List.Item>
            ))}
        </List>
      );
    }
    if (this.state.spareParts.length === 0) {
      return (
        <div className={styles['searched-empty-data-set']}>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchImage}
            tiny
          />
        </div>
      );
    }
    return (
      <List light>
        {this.state.spareParts.map(sparePart => (
          <SparePartListItem
            sparePart={sparePart}
            disabled={this.state.inactiveSparePartIds[sparePart.id] != null}
            selected={this.state.selectedSparePartIds[sparePart.id] != null}
            onClick={() => this.selectSparePart(sparePart.id)}
          />
        ))}
      </List>
    );
  };

  renderPagination = () => {
    if (this.state.totalPages <= 1) return null;
    return (
      <div className={styles['pagination']}>
        <Pagination
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </div>
    );
  };

  renderSelectSparePartsContainer = () => (
    <div className={styles['select-spare-parts-wrapper']}>
      <PerfectScrollbar>
        <div className={styles['select-spare-parts-container']}>
          {this.renderSearchField()}
          {this.renderSpareParts()}
          {this.renderPagination()}
        </div>
      </PerfectScrollbar>
    </div>
  );

  renderSaveButton = () => {
    if (Object.keys(this.state.selectedSparePartIds).length === 0) {
      return (
        <Button small primary label="general.choose" onClick={() => this.props.onSelectSparePartIds([])} />
      );
    } else {
      return (
        <Button
          small
          loading={this.props.loading}
          primary
          saving={this.props.loading}
          translate={false}
          label={
            <FormattedMessage
              id="components.file-add-related-spare-parts.choose-amount"
              values={{ amount: Object.keys(this.state.selectedSparePartIds).length }}
            />
          }
          onClick={() => this.props.onSelectSparePartIds(Object.keys(this.state.selectedSparePartIds))}
        />
      );
    }
  };

  renderModalContent = () => (
    <React.Fragment>
      <Modal.Header
        title={<FormattedMessage id="components.file-add-related-spare-parts.title" />}
        onClose={this.props.onClose}
      />
      <div className={styles['content']}>
        <div className={styles['container']}>
          {this.renderSelectedSpareParts()}
          {this.renderSelectSparePartsContainer()}
        </div>
      </div>
      <Modal.Footer container>
        <Button.Group>
          {this.renderSaveButton()}
          <Button small label="general.cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.isOpen} width={960} fullHeight>
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectSparePartModal));

SelectSparePartModal.propTypes = {
  selectedSparePartIds: PropTypes.array,
  inactiveSparePartIds: PropTypes.array,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

SelectSparePartModal.defaultProps = {
  selectedSparePartIds: [],
  inactiveSparePartIds: [],
};
