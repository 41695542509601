import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listQRTemplates = (systemId, params) =>
  request.get(`systems/${systemId}/qr_templates?${HelperFunctions.convertObjToQueryParameters(params)}`);

export const fetchQrTemplate = qrTemplateId => request.get(`qr_templates/${qrTemplateId}`);

export const createQrTemplate = (systemId, data) => request.post(`systems/${systemId}/qr_templates`, data);

export const updateQrTemplate = (qrTemplateId, data) => request.patch(`qr_templates/${qrTemplateId}`, data);

export const deleteQrTemplate = qrTemplateId => request.delete(`qr_templates/${qrTemplateId}`);
