import types from './types';

const resetSelectedSparePartWithdrawals = payload => ({
  type: types.RESET_SELECTED_SPARE_PART_WITHDRAWALS,
  payload,
});
const selectSparePartWithdrawal = payload => ({ type: types.SELECT_SPARE_PART_WITHDRAWAL, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchSparePartWithdrawals = () => ({ type: types.FETCH_SPARE_PART_WITHDRAWALS });

const fetchSparePartWithdrawalsSuccess = payload => ({
  type: types.FETCH_SPARE_PART_WITHDRAWALS_SUCCESS,
  payload,
});

export default {
  selectFilterType,
  showAppliedFilters,
  showAvailableFilters,
  addFilter,
  removeFilter,
  resetFilter,
  resetSelectedSparePartWithdrawals,
  selectPage,
  selectSparePartWithdrawal,
  selectTotalEntries,
  hideSelectTotalEntries,
  addQueryParameter,
  fetchSparePartWithdrawals,
  fetchSparePartWithdrawalsSuccess,
};
