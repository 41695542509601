import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Modal } from 'views/components/Shared/Layout';
import {
  Button,
  Toggle,
  Banner,
  WhiteCard,
  SectionHeader,
  Datapill,
  ConfirmModal,
} from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import AnimateHeight from 'react-animate-height';
import { AuthSelectors } from 'state/ducks/auth';
import { RequestReceiverType } from 'sdk/Settings';
import {
  Section,
  SectionChild,
  SendRequestToProductionSupervisorModal,
} from 'views/scenes/Settings/components';
import MainterLogoMini from 'assets/images/logo-mini.png';
import Costs_Eng from 'assets/images/ProScreenshots/Costs (Eng).png';
import Costs_Swe from 'assets/images/ProScreenshots/Costs (Swe).png';
import styles from './style.module.scss';
import OperatorRequestModal from './OperatorRequestModal';
import OperatorSparePartModal from './OperatorSparePartModal';
import OperatorDowntimeModal from './OperatorDowntimeModal';
import OperatorAttachmentsModal from './OperatorAttachmentsModal';
import ManageProductionSupervisorPermissionsModal from 'views/components/User/ManageProductionSupervisorPermissionsModal';
import { MENU_ITEMS } from 'views/scenes/Users';

class ProductionBoard extends Component {
  state = {
    showActivateModal: false,
    showDeactivateModal: false,
    showActivateOperatorModal: false,
    showDeactivateOperatorModal: false,
    showOperatorRequestModal: false,
    showOperatorSparePartModal: false,
    showSupervisorAccessModal: false,
    showSendRequestToProductionSupervisorModal: false,
    isSavingActivateProductionBoards: false,
    updatingRequestProductionSupervisorApproval: false,
    showCreatorChooseIsActivatedConfirmModal: false,
    showOperatorDowntimeModal: false,
    showOperatorAttachmentsModal: false,
  };

  updateSettings = params => {
    if (this.state.isSavingActivateProductionBoards) return;
    this.setState({ isSavingActivateProductionBoards: true });
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          isSavingActivateProductionBoards: false,
          showActivateModal: false,
          showDeactivateModal: false,
          showActivateOperatorModal: false,
          showDeactivateOperatorModal: false,
          showOperatorRequestModal: false,
          showOperatorSparePartModal: false,
          showOperatorDowntimeModal: false,
          showSupervisorAccessModal: false,
          showOperatorAttachmentsModal: false,
          showSendRequestToProductionSupervisorModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          isSavingActivateProductionBoards: false,
          showActivateModal: false,
          showDeactivateModal: false,
          showActivateOperatorModal: false,
          showDeactivateOperatorModal: false,
          showOperatorRequestModal: false,
          showOperatorSparePartModal: false,
          showOperatorDowntimeModal: false,
          showSupervisorAccessModal: false,
          showOperatorAttachmentsModal: false,
          showSendRequestToProductionSupervisorModal: false,
        });
      });
  };

  renderActivateConfirmModal = () => (
    <Modal isOpen={this.state.showActivateModal} width={440}>
      <Modal.Header
        title={<FormattedMessage id="screens.settings.production-boards.activate-confirm.title" />}
        subtitle={<FormattedMessage id="screens.settings.production-boards.subtitle" />}
        subtitleTopMargin
        onClose={() => {
          this.setState({ showActivateModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            loading={this.state.isSavingActivateProductionBoards}
            onClick={() => {
              this.updateSettings({ production_board_activated: true });
            }}
            label="screens.settings.production-boards.activate-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showActivateModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderDeactivateConfirmModal = () => (
    <Modal isOpen={this.state.showDeactivateModal} width={440}>
      <Modal.Header
        title={<FormattedMessage id="screens.settings.production-boards.deactivate-confirm.title" />}
        subtitle={<FormattedMessage id="screens.settings.production-boards.deactivate-confirm.message" />}
        subtitleTopMargin
        onClose={() => {
          this.setState({ showDeactivateModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            destructive
            loading={this.state.isSavingActivateProductionBoards}
            onClick={() => {
              this.updateSettings({ production_board_activated: false });
            }}
            label="screens.settings.production-boards.deactivate-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showDeactivateModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderOperatorRequestModal = () => (
    <OperatorRequestModal
      open={this.state.showOperatorRequestModal}
      onClose={() => this.setState({ showOperatorRequestModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingActivateProductionBoards}
      onSave={val => {
        this.updateSettings(val);
      }}
    />
  );

  renderOperatorSparePartModal = () => (
    <OperatorSparePartModal
      open={this.state.showOperatorSparePartModal}
      onClose={() => this.setState({ showOperatorSparePartModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingActivateProductionBoards}
      onSave={val => {
        this.updateSettings(val);
      }}
    />
  );

  renderOperatorDowntimeModal = () => (
    <OperatorDowntimeModal
      open={this.state.showOperatorDowntimeModal}
      onClose={() => this.setState({ showOperatorDowntimeModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingActivateProductionBoards}
      onSave={val => {
        this.updateSettings(val);
      }}
    />
  );

  renderOperatorAttachmentsModal = () => (
    <OperatorAttachmentsModal
      open={this.state.showOperatorAttachmentsModal}
      onClose={() => this.setState({ showOperatorAttachmentsModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingActivateProductionBoards}
      onSave={val => {
        this.updateSettings(val);
      }}
    />
  );

  renderSupervisorAccessModal = () => (
    <ManageProductionSupervisorPermissionsModal
      open={this.state.showSupervisorAccessModal}
      onClose={() => this.setState({ showSupervisorAccessModal: false })}
      settings={this.props.settings}
      onSave={val => {
        this.updateSettings(val);
      }}
    />
  );

  renderSendRequestToProductionSupervisorModal = () => (
    <SendRequestToProductionSupervisorModal
      open={this.state.showSendRequestToProductionSupervisorModal}
      onClose={() => this.setState({ showSendRequestToProductionSupervisorModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingActivateProductionBoards}
      onSave={() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ showSendRequestToProductionSupervisorModal: false });
      }}
    />
  );

  renderOperatorMaintenanceDatapill = () => {
    return this.props.settings.operational_maintenance_activated ? (
      <Datapill
        value={
          <FormattedMessage id="screens.settings.production-boards.operational-maintenance.activated-datapill" />
        }
      />
    ) : null;
  };

  renderOperatorRequestDatapills = () => {
    if (this.props.settings.operator_permission_requests === 'view') {
      return (
        <Datapill
          value={
            <FormattedMessage
              id={`screens.settings.production-boards.data-pill.operator-work-request.view`}
            />
          }
        />
      );
    } else {
      return (
        <>
          <Datapill
            value={
              <FormattedMessage
                id={`screens.settings.production-boards.data-pill.operator-work-request.view`}
              />
            }
          />
          <Datapill
            value={
              <FormattedMessage
                id={`screens.settings.production-boards.data-pill.operator-work-request.create`}
              />
            }
          />
        </>
      );
    }
  };

  renderOperatorSparePartDatapills = () => {
    let datapills = [];
    const canView = this.props.settings.operator_permission_spare_parts === 'view';
    const canCreateWithdrawals = this.props.settings.operator_permission_spare_parts === 'create_withdrawals';
    const canMakeAdjustments =
      this.props.settings.operator_permission_spare_parts === 'create_withdrawals_and_stock_adjustment';
    if (canView || canCreateWithdrawals || canMakeAdjustments) {
      datapills.push(
        <Datapill
          value={
            <FormattedMessage id={`screens.settings.production-boards.data-pill.operator-spare-parts.view`} />
          }
        />
      );
    }
    if (canCreateWithdrawals || canMakeAdjustments) {
      datapills.push(
        <Datapill
          value={
            <FormattedMessage
              id={`screens.settings.production-boards.data-pill.operator-spare-parts.create_withdrawals`}
            />
          }
        />
      );
    }
    if (canMakeAdjustments) {
      datapills.push(
        <Datapill
          value={
            <FormattedMessage
              id={`screens.settings.production-boards.data-pill.operator-spare-parts.make_adjustments`}
            />
          }
        />
      );
    }

    return datapills;
  };

  renderOperatorDowntimeDatapills = () => {
    if (this.props.settings.operator_permission_downtimes === 'view') {
      return (
        <Datapill
          value={
            <FormattedMessage id="screens.settings.production-boards.data-pill.operator-downtimes.view" />
          }
        />
      );
    } else {
      return (
        <>
          <Datapill
            value={
              <FormattedMessage id="screens.settings.production-boards.data-pill.operator-downtimes.view" />
            }
          />
          <Datapill
            value={
              <FormattedMessage id="screens.settings.production-boards.data-pill.operator-downtimes.create" />
            }
          />
        </>
      );
    }
  };

  renderOperatorAttachmentDatapills = () => {
    if (this.props.settings.operator_permission_asset_attachments === 'none') {
      return (
        <Datapill
          gray
          value={
            <FormattedMessage id="screens.settings.production-boards.data-pill.operator-attachments.none" />
          }
        />
      );
    } else {
      return (
        <Datapill
          value={
            <FormattedMessage id="screens.settings.production-boards.data-pill.operator-attachments.view" />
          }
        />
      );
    }
  };

  renderUpgradeToPro = () => (
    <WhiteCard>
      <div className={styles['upgrade-container']}>
        <div className={styles['upgrade-title-container']}>
          <img src={MainterLogoMini} alt="logo" />
          <p>
            <FormattedMessage id="screens.settings.production-boards.upgrade-to-pro.title" />
          </p>
        </div>
        <p className={styles['upgrade-subtitle']}>
          <FormattedMessage id="screens.settings.production-boards.upgrade-to-pro.description" />
        </p>
        <img
          className={styles['upgrade-screenshot']}
          alt="screenshot"
          src={this.props.language === 'sv' ? Costs_Swe : Costs_Eng}
        />
        <div className={styles['upgrade-buttons-container']}>
          <Button upgradeToPro label="general.upgrade-to-pro" />
        </div>
      </div>
    </WhiteCard>
  );

  renderCreatorChoosesIsActivatedConfirm = () => {
    return (
      <ConfirmModal
        open={this.state.showCreatorChooseIsActivatedConfirmModal}
        title={
          <FormattedMessage id="screens.settings.requests.production-supervisor-approval.creator-chooses-activated-confim.title" />
        }
        message={
          <FormattedMessage id="screens.settings.requests.production-supervisor-approval.creator-chooses-activated-confim.subtitle" />
        }
        confirmButtonText="general.ok"
        onConfirm={() => {
          this.setState({ showCreatorChooseIsActivatedConfirmModal: false });
        }}
        onCancel={() => {
          this.setState({ showCreatorChooseIsActivatedConfirmModal: false });
        }}
      />
    );
  };

  renderContentAlertMessages = () => {
    return (
      <>
        <FormattedMessage id="screens.settings.production-boards.new-features.title" />
        <div className={styles['banner-container']} style={{ marginBottom: '20px' }}>
          <Banner.List>
            <Banner orange>
              <div className={styles['title']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.operational-maintenance.title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.operational-maintenance.subtitle" />
              </div>
            </Banner>
          </Banner.List>
        </div>
        <FormattedMessage id="screens.settings.production-boards.new-features.title" />
        <div className={styles['banner-container']}>
          <Banner.List>
            <Banner orange>
              <div className={styles['title']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.production-supervisor.title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.production-supervisor.subtitle" />
              </div>
            </Banner>
            <Banner orange>
              <div className={styles['title']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.operator.title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="screens.settings.production-boards.new-features.operator.subtitle" />
              </div>
            </Banner>
          </Banner.List>
        </div>
      </>
    );
  };

  renderSettings = () => {
    if (!this.props.settings.production_board_activated) return null;
    return (
      <>
        <div className={styles['separator']} />
        <div className={styles['list-group']}>
          <SectionHeader paddingHorizontal={50} horizontalBorders>
            <div className={styles['operator-permission']}>
              <div className={styles['title']}>
                <FormattedMessage id="screens.settings.production-boards.options.section-title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="screens.settings.production-boards.options.section-subtitle" />
              </div>
            </div>
          </SectionHeader>
          <SectionChild
            title={<FormattedMessage id="screens.settings.production-boards.operational-maintenance.title" />}
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.operational-maintenance.subtitle" />
            }
            datapills={this.renderOperatorMaintenanceDatapill()}
            rightComponent={
              <Link to="/settings/operational-maintenances">
                <Button gray label={'general.manage'} />
              </Link>
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.request.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.request.subtitle" />
            }
            rightComponent={
              <Button
                gray
                label={'general.manage'}
                onClick={() => this.setState({ showOperatorRequestModal: true })}
              />
            }
            datapills={this.renderOperatorRequestDatapills()}
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.work-order.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.work-order.subtitle" />
            }
            datapills={
              <Datapill
                value={
                  <FormattedMessage id="screens.settings.production-boards.data-pill.operator-work-order.view" />
                }
              />
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.spare-parts.subtitle" />
            }
            datapills={this.renderOperatorSparePartDatapills()}
            rightComponent={
              <Button
                gray
                label={'general.manage'}
                onClick={() => this.setState({ showOperatorSparePartModal: true })}
              />
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.downtimes.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.downtimes.subtitle" />
            }
            datapills={this.renderOperatorDowntimeDatapills()}
            rightComponent={
              <Button
                gray
                label={'general.manage'}
                onClick={() => this.setState({ showOperatorDowntimeModal: true })}
              />
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.attachments.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.primary-settings.attachments.subtitle" />
            }
            datapills={this.renderOperatorAttachmentDatapills()}
            rightComponent={
              <Button
                gray
                label={'general.manage'}
                onClick={() => this.setState({ showOperatorAttachmentsModal: true })}
              />
            }
          />
        </div>
        <div className={styles['list-group']}>
          <SectionHeader paddingHorizontal={50} horizontalBorders>
            <FormattedMessage id="screens.settings.production-boards.options.other-settings.title" />
          </SectionHeader>
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.auth-for-supervisors.subtitle" />
            }
            rightComponent={
              <Button
                gray
                label={'general.manage'}
                onClick={() => this.setState({ showSupervisorAccessModal: true })}
              />
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.production-supervisor-approval.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.production-supervisor-approval.subtitle" />
            }
            rightComponent={
              <div className={styles['toggle-and-button-container']}>
                <Toggle
                  loading={this.state.updatingRequestProductionSupervisorApproval}
                  checked={this.props.settings.request_production_supervisor_approval_activated}
                  onClick={() => {
                    const { request_multiple_assignees_active, request_multiple_assignees_type } =
                      this.props.settings;
                    if (
                      this.props.hasProTier &&
                      request_multiple_assignees_active &&
                      request_multiple_assignees_type === RequestReceiverType.SelectedByCreator
                    ) {
                      this.setState({ showCreatorChooseIsActivatedConfirmModal: true });
                      return;
                    }

                    this.setState({ updatingRequestProductionSupervisorApproval: true });
                    const nextVal = !this.props.settings.request_production_supervisor_approval_activated;
                    this.props
                      .updateSettings(this.props.system.id, {
                        request_production_supervisor_approval_activated: nextVal,
                      })
                      .then(() => {
                        this.setState({ updatingRequestProductionSupervisorApproval: false });
                        toast(
                          <ToastMessage success text={<FormattedMessage id="general.update-success" />} />
                        );
                      });
                  }}
                />
                <Button
                  disabled={!this.props.settings.request_production_supervisor_approval_activated}
                  gray
                  label={'general.manage'}
                  onClick={() => this.setState({ showSendRequestToProductionSupervisorModal: true })}
                />
              </div>
            }
          />
          <SectionChild
            title={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.operator-login.title" />
            }
            subtitle={
              <FormattedMessage id="screens.settings.production-boards.options.other-settings.operator-login.subtitle" />
            }
            rightComponent={
              <Link to={{ pathname: '/users', search: `type=${MENU_ITEMS.OperatorLogin}` }}>
                <Button gray label={'general.manage'} />
              </Link>
            }
          />
        </div>
      </>
    );
  };

  renderContent = () => {
    return (
      <AnimateHeight duration={250} height={this.props.settings.production_board_activated ? 0 : 'auto'}>
        {this.renderContentAlertMessages()}
      </AnimateHeight>
    );
  };

  render() {
    if (this.props.hasProTier === false) {
      return this.renderUpgradeToPro();
    }
    return (
      <>
        <WhiteCard>
          <Section
            header
            title={<FormattedMessage id="screens.settings.production-boards.title" />}
            subtitle={<FormattedMessage id="screens.settings.production-boards.subtitle" />}
            rightComponent={
              <Toggle
                checked={this.props.settings.production_board_activated}
                onClick={() => {
                  if (this.props.settings.production_board_activated) {
                    this.setState({ showDeactivateModal: true });
                  } else {
                    this.setState({ showActivateModal: true });
                  }
                }}
              />
            }
          />
          {this.renderContent()}
        </WhiteCard>
        {this.renderSettings()}
        {this.renderActivateConfirmModal()}
        {this.renderDeactivateConfirmModal()}
        {this.renderOperatorRequestModal()}
        {this.renderOperatorSparePartModal()}
        {this.renderSupervisorAccessModal()}
        {this.renderSendRequestToProductionSupervisorModal()}
        {this.renderCreatorChoosesIsActivatedConfirm()}
        {this.renderOperatorDowntimeModal()}
        {this.renderOperatorAttachmentsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    settings: AuthSelectors.getSettings(state),
    system: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductionBoard));
