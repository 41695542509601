import React, { Component } from 'react';
import { CardGrid } from 'views/components/Shared/Layout';
import { ViewTextArea, WhiteCard } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class DescriptionContainer extends Component {
  render() {
    if (this.props.operationalMaintenanceTemplateVersion.description) {
      return (
        <CardGrid.Row>
          <WhiteCard noPadding>
            <div className={styles['title-container']}>
              <ViewTextArea>{this.props.operationalMaintenanceTemplateVersion.description}</ViewTextArea>
            </div>
          </WhiteCard>
        </CardGrid.Row>
      );
    }
    return null;
  }
}
