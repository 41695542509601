import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import { List, Button, Tooltip, Icon } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { FailedInspectionCount } from 'views/components/Checklist';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import moment from 'moment';
import styles from './style.module.scss';

class ChecklistInstanceListItem extends Component {
  renderTitle = () => {
    const { workOrder, operationalMaintenanceInstance } = this.props;
    if (workOrder) {
      if (workOrder.status === WorkOrderStatus.Completed && workOrder.completed_date) {
        return moment(workOrder.completed_date).format('LL');
      }
      return (
        <span className={styles['not-completed']}>
          <FormattedMessage id="screens.checklist.instances.work-order-not-completed" />
        </span>
      );
    }
    if (operationalMaintenanceInstance) {
      if (
        operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed &&
        operationalMaintenanceInstance.completed_date
      ) {
        return moment(operationalMaintenanceInstance.completed_date).format('LL');
      }
      return (
        <span className={styles['not-completed']}>
          <FormattedMessage id="screens.checklist.instances.operational-maintenance-not-completed" />
        </span>
      );
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.asset) {
      return <TreePath fullPath assetId={this.props.asset.id} />;
    }
    return '-';
  };

  renderFailedInspectionCount = () => {
    if (this.props.checklistInstance && this.props.checklistInstance.inspection_failed_count > 0) {
      return (
        <List.Item.Column alignRight>
          <FailedInspectionCount checklistInstanceId={this.props.checklistInstance.id} />
        </List.Item.Column>
      );
    }
    return null;
  };

  renderLinkButton = () => {
    if (this.props.workOrder) {
      return (
        <Tooltip
          trigger={
            <Link to={`/work-orders/${this.props.workOrder.id}`}>
              <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
            </Link>
          }
          label={<FormattedMessage id="general.navigate-to.work-order" />}
        />
      );
    }
    if (this.props.operationalMaintenanceInstance) {
      return (
        <Tooltip
          trigger={
            <Link to={`/operational-maintenances/${this.props.operationalMaintenanceInstance.id}`}>
              <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
            </Link>
          }
          label={<FormattedMessage id="general.navigate-to.operational-maintenance" />}
        />
      );
    }
    return null;
  };

  renderItem = () => (
    <List.Item flex clickable onClick={this.props.onClick}>
      <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />
      {this.renderFailedInspectionCount()}
      <List.Item.Column alignRight>{this.renderLinkButton()}</List.Item.Column>
    </List.Item>
  );

  render() {
    return this.renderItem();
  }
}

function mapStateToProps(state, ownProps) {
  const checklistInstance = EntitySelectors.getChecklistInstance(state, ownProps.id);
  const workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    checklistInstance.operational_maintenance_instance_id
  );
  let asset = null;
  if (workOrder) {
    asset = EntitySelectors.getAsset(state, workOrder.asset_id);
  } else {
    asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
  }

  return {
    checklistInstance,
    asset,
    workOrder,
    operationalMaintenanceInstance,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps)(ChecklistInstanceListItem)));
