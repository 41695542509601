import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { FILTER_TYPES } from 'views/scenes/Requests/FilterModal';

class Priority extends Component {
  renderPriority = priority => {
    switch (priority) {
      case 'low':
        return <FormattedMessage id="resources.request.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.request.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.request.prio.high" />;
      default:
        return null;
    }
  };

  renderSelectedValues = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={value}
        renderItem={priority => this.renderPriority(priority)}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.request.priority" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Priority,
            data: {
              [FILTER_TYPES.Priority]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                value: [],
              },
            },
          })
        }
      >
        {this.renderSelectedValues()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: RequestsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: RequestsSelectors.getFiltersForKey(state, FILTER_TYPES.Priority),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Priority);
