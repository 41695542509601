import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { SideModal } from 'views/components/Shared/Layout';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Interval extends Component {
  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    const interval = value.split('_')[0];
    const frequency = value.split('_')[1];
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {buildIntervalMessage(
          {
            frequency,
            interval,
          },
          this.props.intl
        )}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  renderSubtitle = () => {
    switch (this.props.filter.comparator) {
      case HelperFunctions.FILTER_COMPARABLES.Exact: {
        return <FormattedMessage id="filters.types.number.exact.title" />;
      }
      case HelperFunctions.FILTER_COMPARABLES.Exists: {
        if (this.props.filter.value) {
          return (
            <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.exact.title" />
          );
        }
        return (
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.not-exact.title" />
        );
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Container
        title={<FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.title" />}
        subtitle={this.renderSubtitle()}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Interval,
            data: {
              [FILTER_TYPES.Interval]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                value: null,
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Interval),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Interval));
