import React, { Component } from 'react';
import * as moment from 'moment';
import { InlineModal } from 'views/components/Shared/General';
import 'react-day-picker/lib/style.css';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import Header from '../Header';

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.fromDate == null ? null : moment(props.fromDate).toDate(),
      to: props.toDate == null ? null : moment(props.toDate).toDate(),
      enteredTo: props.toDate == null ? null : moment(props.toDate).toDate(),
      headerDate: props.fromDate == null ? moment() : moment(props.fromDate),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        showDatePicker: true,
        from: this.props.fromDate == null ? null : moment(this.props.fromDate).toDate(),
        to: this.props.toDate == null ? null : moment(this.props.toDate).toDate(),
        enteredTo: this.props.toDate == null ? null : moment(this.props.toDate).toDate(),
        headerDate: this.props.fromDate == null ? moment() : moment(this.props.fromDate),
      });
    }
  }

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  changeMonth = date => {
    this.setState({ headerDate: moment(date) });
  };

  handleDayClick = day => {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.setState({
        from: null,
        to: null,
        enteredTo: null,
      });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState(
        {
          to: day,
          enteredTo: day,
        },
        () => {
          this.props.onSelectDate({
            from: moment(this.state.from).format('YYYY-MM-DD'),
            to: moment(this.state.to).format('YYYY-MM-DD'),
          });
        }
      );
    }
  };

  handleDayMouseEnter = day => {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  };

  renderFixedDatePicker = () => {
    const { from, enteredTo, headerDate } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>{this.props.trigger}</div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <React.Fragment>
            <InlineModal.Body paddingTop>
              <DayPicker
                locale={moment.locale()}
                className="Range"
                showWeekNumbers
                localeUtils={MomentLocaleUtils}
                month={headerDate.toDate()}
                selectedDays={selectedDays}
                modifiers={modifiers}
                onDayClick={this.handleDayClick}
                onDayMouseEnter={this.handleDayMouseEnter}
                navbarElement={<Header date={headerDate.format('YYYY-MM-DD')} onChange={this.changeMonth} />}
                captionElement={() => null}
                disabledDays={this.props.disabledDays}
              />
              {this.props.footerComponent}
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </>
    );
  };

  renderInlineDatePicker = () => {
    const { from, enteredTo, headerDate } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    return (
      <>
        <DayPicker
          locale={moment.locale()}
          className="Range"
          showWeekNumbers
          localeUtils={MomentLocaleUtils}
          month={headerDate.toDate()}
          selectedDays={selectedDays}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
          navbarElement={<Header date={headerDate.format('YYYY-MM-DD')} onChange={this.changeMonth} />}
          captionElement={() => null}
          disabledDays={this.props.disabledDays}
        />
        {this.props.footerComponent}
      </>
    );
  };

  render() {
    return this.props.inline ? this.renderInlineDatePicker() : this.renderFixedDatePicker();
  }
}
