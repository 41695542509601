import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class ChecklistTemplateLinkListItem extends Component {
  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['title']}>{this.props.checklistTemplate.title}</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateLink = EntitySelectors.getChecklistTemplateLink(state, ownProps.id);
  return {
    checklistTemplateLink,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLink.checklist_template_id
    ),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateLinkListItem);
