import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import UserContainer from './UserContainer';
import VendorContainer from './VendorContainer';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class WorkOrderSpentTimeCreatedBy extends Component {
  renderSelectedValue = () => {
    const { value: userIds } = this.props.filter.user_id;
    const { value: vendorIds } = this.props.filter.vendor_id;
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        data={[
          ...userIds.map(id => ({ id, type: 'user' })),
          ...vendorIds.map(id => ({ id, type: 'vendor' })),
        ]}
        renderItem={({ type, id }) => {
          if (type === 'user') {
            return <UserContainer id={id} />;
          }
          return <VendorContainer id={id} />;
        }}
      />
    );
  };

  render() {
    const { comparator, value } = this.props.filter.user_id;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order-spent-time.created-by" />
        }
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.WorkOrderSpentTimeCreatedBy,
            data: {
              work_order_spent_time: {
                ...this.props.filter,
                user_id: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
                vendor_id: {
                  comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                  value: [],
                },
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order_spent_time'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderSpentTimeCreatedBy);
