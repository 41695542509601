import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

class VerifyEmail extends Component {
  state = {
    showAlert: true,
    showAlertAfterAnimation: true,
  };

  componentDidMount() {
    const { email, email_is_verified } = this.props.currentUser;
    let showAlert = email && email_is_verified === false;
    this.setState({ showAlert, showAlertAfterAnimation: showAlert });
  }

  render() {
    let alertClassNames = [parentStyles['alert'], styles['alert']];
    if (this.state.showAlertAfterAnimation) {
      return (
        <Link to="/settings/user">
          <AnimateHeight
            duration={250}
            height={this.state.showAlert ? 'auto' : 0}
            onAnimationEnd={() =>
              this.setState({
                showAlertAfterAnimation: false,
              })
            }
            contentClassName={alertClassNames.join(' ')}
          >
            <div className={styles['title']}>
              <FormattedMessage id="menu.verify-email" />
              <span>&nbsp;-&nbsp;</span>
              <span>{this.props.currentUser.email}</span>
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light white type="times" size={16} />}
                iconColor="#FFF"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ showAlert: false });
                }}
              />
            </div>
          </AnimateHeight>
        </Link>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(VerifyEmail);
