import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { List, Icon } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';

const UserListItem = props => {
  return (
    <List.Item small clickable onClick={() => props.onSelectUser(props.user.id)}>
      <List.Item.Column width={35}>
        <ProfilePicture
          userId={props.id}
          size={34}
          defaultElement={<Icon type="user" withBackground blue backgroundSize={34} />}
        />
      </List.Item.Column>
      <List.Item.TitleColumn title={<UserNameWrapper user={props.user} />} subtitle={props.user.email} />
      {props.isSavingUserId === props.user.id ? (
        <List.Item.Column alignRight>
          <Loader tiny />
        </List.Item.Column>
      ) : null}
      {props.selected ? (
        <List.Item.Column alignRight>
          <Icon type="check" green light size={14} />
        </List.Item.Column>
      ) : null}
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    user: EntitySelectors.getUser(state, id),
  };
}

export default connect(mapStateToProps)(UserListItem);
