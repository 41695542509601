import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class UnarchiveAssetModal extends Component {
  state = {
    isSaving: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
      });
    }
  }

  unarchiveAsset = () => {
    this.setState({ isSaving: true });

    this.props
      .updateAsset(this.props.asset.id, {
        archived: false,
      })
      .then(res => {
        this.props.onSave();
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={420}>
        <Modal.Header
          title={<FormattedMessage id="components.unarchive-asset-modal.title" />}
          subtitle={<FormattedMessage id="components.unarchive-asset-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Footer>
          <Button.Group>
            <Button
              label="components.unarchive-asset-modal.button"
              primary
              onClick={this.unarchiveAsset}
              loading={this.state.isSaving}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnarchiveAssetModal);
