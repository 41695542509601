import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { CostType } from 'sdk/Cost';
import {
  List,
  Datapill,
  ElapsedTime,
  MoneyWithCurrency,
  Icon,
  Button,
  NewInlineModal,
} from 'views/components/Shared/General';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { UserNameWrapper } from 'views/components/User';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class CostListItem extends Component {
  state = {
    dropdownOpen: false,
  };

  renderQuantityDatapill = () => {
    return (
      <Datapill
        value={
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.sparePartWithdrawal.quantity}
          />
        }
      />
    );
  };

  renderCategory = () => {
    switch (this.props.cost.category) {
      case CostType.Labor:
        return <FormattedMessage id="resources.cost.categories.labor-short" />;
      case CostType.Part:
        return <FormattedMessage id="resources.cost.categories.part-short" />;
      case CostType.Other:
        return <FormattedMessage id="resources.cost.categories.other-short" />;
    }
  };

  renderSpentTimeDataPill = () => {
    if (this.props.workOrderSpentTime) {
      return (
        <Datapill orange value={<ElapsedTime elapsedMinutes={this.props.workOrderSpentTime.minutes} />} />
      );
    }
    return null;
  };

  renderSparePartWithdrawalQuantityDataPill = () => {
    if (this.props.sparePartWithdrawal) {
      return (
        <Datapill
          orange
          value={
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePartWithdrawal.quantity}
            />
          }
        />
      );
    }
    return null;
  };

  renderWorkOrderDataPill = () => {
    if (this.props.workOrder) {
      return (
        <Datapill
          orange
          value={
            <>
              <FormattedMessage id="resources.work-order.resource" />
              <span> </span>
              <span>#{this.props.workOrder.number}</span>
            </>
          }
        />
      );
    }
    return null;
  };

  renderDataPills = () => {
    return (
      <Datapill.List>
        {this.renderSpentTimeDataPill()}
        {this.renderSparePartWithdrawalQuantityDataPill()}
        {this.renderWorkOrderDataPill()}
      </Datapill.List>
    );
  };

  renderLoading = () => {
    return (
      <List.Item checkbox checkboxDisabled>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  };

  renderTitle = () => {
    if (this.props.workOrderSpentTime) {
      if (this.props.workOrderSpentTimeCreatedByVendor) {
        return this.props.workOrderSpentTimeCreatedByVendor.name;
      }
      return <UserNameWrapper user={this.props.workOrderSpentTimeCreatedByUser} />;
    }
    if (this.props.sparePart) {
      return <Title sparePart={this.props.sparePart} />;
    }
    if (this.props.sparePartWithdrawal) {
      return this.props.sparePartWithdrawal.title;
    }
    return this.props.cost.description;
  };

  renderSubtitle = () => {
    if (this.props.asset) {
      return <TreePath assetId={this.props.asset.id} fullPath />;
    }
    return null;
  };

  renderCost = () => {
    const { amount, amount_currency, amount_exchange_rate } = this.props.cost;
    const { currency: systemCurrency } = this.props.system;
    if (this.props.cost.amount == null) {
      return <Datapill red value="-" />;
    }
    if (amount_currency !== systemCurrency) {
      const localValue = new Decimal(amount)
        .times(new Decimal(amount_exchange_rate))
        .toDecimalPlaces(2)
        .toString();
      return (
        <Datapill
          orange
          value={
            <>
              <span>
                <MoneyWithCurrency value={amount} currency={amount_currency} />
              </span>
              <span>&nbsp;</span>
              <span className={styles['system-currency']}>
                (
                <MoneyWithCurrency value={localValue} currency={this.props.system.currency} />)
              </span>
            </>
          }
        />
      );
    }
    return <Datapill orange value={<MoneyWithCurrency value={amount} currency={amount_currency} />} />;
  };

  renderDropDownItems = () => {
    let dropDownItems = [];
    if (this.props.workOrder) {
      dropDownItems.push(
        <Link to={`/work-orders/${this.props.workOrder.id}`}>
          <NewInlineModal.Dropdown.Item>
            <FormattedMessage id={'general.navigate-to.work-order'} />
          </NewInlineModal.Dropdown.Item>
        </Link>
      );
    }
    if (this.props.sparePart) {
      dropDownItems.push(
        <Link to={`/spare-parts/${this.props.sparePart.id}`}>
          <NewInlineModal.Dropdown.Item>
            <FormattedMessage id={'general.navigate-to.spare-part'} />
          </NewInlineModal.Dropdown.Item>
        </Link>
      );
    }
    return dropDownItems;
  };

  renderIconButtons = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={250}
          position="right"
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>{this.renderDropDownItems()}</NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoading();
    }
    return (
      <List.Item
        small
        dataPillsComponent={this.renderDataPills()}
        iconButtons={this.renderIconButtons()}
        onCheck={this.props.onCheck}
        checkbox
        checked={this.props.checked}
        checkboxDisabled={this.props.checkboxDisabled}
      >
        <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />
        <List.Item.Column width={150} alignRight>
          {this.renderCategory()}
        </List.Item.Column>
        <List.Item.Column width={150} alignRight>
          {moment(this.props.cost.date).format('LL')}
        </List.Item.Column>
        <List.Item.Column width={200} alignRight>
          {this.renderCost()}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const cost = EntitySelectors.getCost(state, ownProps.id);
  let sparePart = null;
  let sparePartUnit = null;
  let workOrderSpentTimeCreatedByUser = null;
  let workOrderSpentTimeCreatedByVendor = null;
  const asset = EntitySelectors.getAsset(state, cost.asset_id);
  const workOrder = EntitySelectors.getWorkOrder(state, cost.work_order_id);
  const workOrderSpentTime = EntitySelectors.getWorkOrderSpentTime(state, cost.work_order_spent_time_id);
  if (workOrderSpentTime) {
    workOrderSpentTimeCreatedByUser = EntitySelectors.getUser(state, workOrderSpentTime.user_id);
    workOrderSpentTimeCreatedByVendor = EntitySelectors.getVendor(state, workOrderSpentTime.vendor_id);
  }
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, cost.spare_part_withdrawal_id);
  if (sparePartWithdrawal) {
    sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
    if (sparePart) {
      sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
    } else {
      sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
    }
  }
  return {
    system: AuthSelectors.getCurrentSystem(state),
    cost,
    sparePart,
    sparePartUnit,
    sparePartWithdrawal,
    workOrderSpentTime,
    workOrderSpentTimeCreatedByUser,
    workOrderSpentTimeCreatedByVendor,
    workOrder,
    asset,
  };
}

export default connect(mapStateToProps)(CostListItem);
