import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import HelperFunctions from 'utilities/HelperFunctions';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon, SectionHeader } from 'views/components/Shared/General';
import { ProductionBoardOperations } from 'state/ducks/productionBoard';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { MenuOperations, MenuUtils } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { ToastMessage } from 'views/components/Shared/Layout';
import { DowntimeModal, NewDowntimeModal } from 'views/components/Downtime';
import { AddSparePartModal } from 'views/components/WorkOrder';
import { NewRequestModal } from 'views/components/Request';
import { SDKReduxOperations } from 'sdk';
import { Header } from 'views/scenes/ProductionBoard/components';
import styles from './style.module.scss';
import OperationalMaintenances from './OperationalMaintenances';
import Requests from './Requests';
import Activities from './Activities';

class ProductionBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewRequestModal: false,
      showAddSparePartsModal: false,
      isCreatingSparePartWithdrawals: false,
      showNewDowntimeModal: false,
      showDowntimeModal: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.ProductionBoard);
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.production-board.document-title' })
    );
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  createSparePartWithdrawals = selectedSpareParts => {
    this.setState({ isCreatingSparePartWithdrawals: true });
    let requestsToWaitFor = selectedSpareParts.map(({ spare_part_id, quantity }) =>
      this.props.createSparePartWithdrawal(this.props.system.id, {
        spare_part_id,
        quantity,
      })
    );
    Promise.all(requestsToWaitFor).then(() => {
      this.setState({ showAddSparePartsModal: false, isCreatingSparePartWithdrawals: false });
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="screens.production-board.overview.create-withdrawal-success" />}
        />
      );
    });
  };

  renderCreateResources = () => {
    const { canCreateRequests, canCreateWithdrawals, settings } = this.props;
    if (!canCreateRequests && !canCreateWithdrawals && settings.downtime_activated === false) {
      return null;
    }
    return (
      <div className={styles['create-resources']}>
        <SectionHeader paddingHorizontal={20} horizontalBorders>
          <FormattedMessage id="screens.production-board.overview.create-resources.title" />
        </SectionHeader>
        <div className={styles['create-resource-buttons']}>
          {this.renderCreateRequestButton()}
          {this.renderCreateWithdrawalButton()}
          {this.renderCreateDowntimeButton()}
        </div>
      </div>
    );
  };

  renderCreateRequestButton = () => {
    if (!this.props.canCreateRequests) return null;

    return (
      <div
        className={styles['create-resource-button']}
        onClick={() => this.setState({ showNewRequestModal: true })}
      >
        <div className={styles['create-resource-button-icon']}>
          <Icon type="exclamation-circle" size={16} />
        </div>
        <div>
          <FormattedMessage id="screens.production-board.overview.create-resources.request" />
        </div>
      </div>
    );
  };

  renderCreateWithdrawalButton = () => {
    if (!this.props.canCreateWithdrawals) return null;

    return (
      <div
        className={styles['create-resource-button']}
        onClick={() => this.setState({ showAddSparePartsModal: true })}
      >
        <div className={styles['create-resource-button-icon']}>
          <Icon type="cogs" size={16} />
        </div>
        <div>
          <FormattedMessage id="screens.production-board.overview.create-resources.withdrawal" />
        </div>
      </div>
    );
  };

  renderCreateDowntimeButton = () => {
    if (this.props.settings.downtime_activated && this.props.canEditDowntimes) {
      return (
        <div
          className={styles['create-resource-button']}
          onClick={() => this.setState({ showNewDowntimeModal: true })}
        >
          <div className={styles['create-resource-button-icon']}>
            <Icon solid type="history" size={16} />
          </div>
          <div>
            <FormattedMessage id="screens.production-board.overview.create-resources.downtime" />
          </div>
        </div>
      );
    }
    return null;
  };

  renderNewRequestModal = () => {
    return (
      <NewRequestModal
        open={this.state.showNewRequestModal}
        defaultTreeParentIdForAsset={this.props.checkedInAssetId}
        onClose={() => {
          this.setState({ showNewRequestModal: false });
        }}
        onCreated={() => {
          this.setState({ showNewRequestModal: false });
        }}
        onCreatedWithReopen={() => {
          this.setState(
            {
              showNewRequestModal: false,
            },
            () => {
              setTimeout(() => {
                this.setState({ showNewRequestModal: true });
              }, 350);
            }
          );
        }}
      />
    );
  };

  renderAddSparePartModal = () => {
    return (
      <AddSparePartModal
        open={this.state.showAddSparePartsModal}
        skipSubtitle
        title={<FormattedMessage id="screens.production-board.overview.select-spare-part-modal-title" />}
        isSaving={this.state.isCreatingSparePartWithdrawals}
        onAddSpareParts={addedSpareParts => this.createSparePartWithdrawals(addedSpareParts)}
        onClose={() => this.setState({ showAddSparePartsModal: false })}
      />
    );
  };

  renderDowntimeModal = () => {
    return (
      <DowntimeModal
        open={this.state.showDowntimeModal}
        defaultTreeParentId={this.props.checkedInAssetId}
        isCreatingActiveDowntime={this.state.isCreatingActiveDowntime}
        onClose={() => {
          this.setState({ showDowntimeModal: false });
        }}
      />
    );
  };

  renderNewDowntimeModal = () => (
    <NewDowntimeModal
      open={this.state.showNewDowntimeModal}
      onClose={() => {
        this.setState({ showNewDowntimeModal: false });
      }}
      onCreateCompletedDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: false });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true });
        }, 200);
      }}
      onCreateActiveDowntime={() => {
        this.setState({ showNewDowntimeModal: false, isCreatingActiveDowntime: true });
        setTimeout(() => {
          this.setState({ showDowntimeModal: true });
        }, 200);
      }}
    />
  );

  render() {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;

    let dataCardsClassNames = [styles['data-cards']];
    if (!operationalMaintenanceIsActivated) {
      dataCardsClassNames = [...dataCardsClassNames, styles['single-column']];
    }
    return (
      <>
        <div className={styles['top-container']}>
          <Header />
          <div className={styles['content']}>
            <div className={styles['main-content']}>
              <PerfectScrollbar>
                <div className={styles['overview']}>
                  <div className={dataCardsClassNames.join(' ')}>
                    <OperationalMaintenances />
                    <Requests />
                  </div>
                  {this.renderCreateResources()}
                </div>
              </PerfectScrollbar>
            </div>
            <div className={styles['right-content']}>
              <Activities />
            </div>
          </div>
        </div>
        {this.renderNewRequestModal()}
        {this.renderAddSparePartModal()}
        {this.renderNewDowntimeModal()}
        {this.renderDowntimeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      createSparePartWithdrawal: SDKReduxOperations.createSparePartWithdrawal,
      resetState: ProductionBoardOperations.resetState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canCreateRequests: AuthSelectors.canCreateRequests(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
    settings: AuthSelectors.getSettings(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    canEditDowntimes: AuthSelectors.canEditDowntimes(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductionBoard)));
