import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { Button, Icon } from 'views/components/Shared/General';
import moment from 'moment';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import Day from './Day';
import styles from './style.module.scss';

const fetchCalendarRequest = HelperFunctions.getCancelTokenForRequest();

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      fromDate: this.getStartDateFromDate(props.date),
      endDate: this.getEndDateFromDate(props.date),
      firstDateOfMonth: this.getFirstDateOfMonth(props.date),
      dateSummary: {},
    };
  }

  componentDidMount() {
    this.fetchCalendarSummary = debounce(this.fetchCalendarSummary, 400);
    this.fetchCalendarSummary({
      from: this.state.fromDate,
      to: this.state.endDate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date) {
      this.setState({
        fromDate: this.getStartDateFromDate(this.props.date),
        endDate: this.getEndDateFromDate(this.props.date),
        firstDateOfMonth: this.getFirstDateOfMonth(this.props.date),
      });
    } else if (prevState.fromDate !== this.state.fromDate) {
      const { fromDate, endDate } = this.state;
      this.setState({ isFetching: true });
      this.fetchCalendarSummary({
        from: fromDate,
        to: endDate,
      });
    }
  }

  getStartDateFromDate = date => {
    if (date == null) {
      return moment().startOf('month').isoWeekday(1).format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').isoWeekday(1).format('YYYY-MM-DD');
  };

  getEndDateFromDate = date => {
    if (date == null) {
      return moment().startOf('month').add(5, 'weeks').isoWeekday(7).format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').add(5, 'weeks').isoWeekday(7).format('YYYY-MM-DD');
  };

  getFirstDateOfMonth = date => {
    if (date == null) {
      return moment().startOf('month').format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').format('YYYY-MM-DD');
  };

  fetchCalendarSummary = params => {
    fetchCalendarRequest.cancel();
    API.showCalendarSummary(this.props.system.id, params, fetchCalendarRequest.getCancelTokenConfig())
      .then(({ data: dateSummary }) => {
        this.setState({ dateSummary, isFetching: false });
      })
      .catch(() => {});
  };

  goToPreviousMonth = () => {
    const previousMonthDate = moment(this.state.firstDateOfMonth).subtract(1, 'months').format('YYYY-MM-DD');
    this.setState({
      fromDate: this.getStartDateFromDate(previousMonthDate),
      endDate: this.getEndDateFromDate(previousMonthDate),
      firstDateOfMonth: previousMonthDate,
    });
  };

  goToNextMonth = () => {
    const nextMonthDate = moment(this.state.firstDateOfMonth).add(1, 'months').format('YYYY-MM-DD');
    this.setState({
      fromDate: this.getStartDateFromDate(nextMonthDate),
      endDate: this.getEndDateFromDate(nextMonthDate),
      firstDateOfMonth: nextMonthDate,
    });
  };

  renderDay = dayOfWeek => {
    return moment().day(dayOfWeek).format('dd').charAt(0);
  };

  renderDays = () => {
    const { fromDate, endDate } = this.state;
    let children = [];
    for (var date = moment(fromDate); date.isBefore(moment(endDate)); date.add(1, 'days')) {
      children = [...children, <div className={styles['day']}>{date.format('D')}</div>];
    }
    return children;
  };

  renderWeeks = () => {
    let children = [];
    for (
      const date = moment(this.state.fromDate).clone();
      !date.isAfter(this.state.endDate, 'week');
      date.add(1, 'weeks')
    ) {
      const showingWeekOfYearInPlanningCalendar = moment(this.props.date).week();
      const weekOfYear = date.week();
      const week = date.format('w');
      const monday = date.isoWeekday(1).clone();
      const tuesday = date.isoWeekday(2).clone();
      const wednesday = date.isoWeekday(3).clone();
      const thursday = date.isoWeekday(4).clone();
      const friday = date.isoWeekday(5).clone();
      const saturday = date.isoWeekday(6).clone();
      const sunday = date.isoWeekday(7).clone();
      children = [
        ...children,
        <div className={styles['row']}>
          <div
            className={`${styles['week-number']} ${
              showingWeekOfYearInPlanningCalendar === weekOfYear ? styles['current-week'] : ''
            }`}
            onClick={() => this.props.onGoToDate(monday.format('YYYY-MM-DD'))}
          >
            {week}
          </div>
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={monday}
            dateSummary={this.state.dateSummary[monday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(monday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={tuesday}
            dateSummary={this.state.dateSummary[tuesday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(tuesday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={wednesday}
            dateSummary={this.state.dateSummary[wednesday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(wednesday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={thursday}
            dateSummary={this.state.dateSummary[thursday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(thursday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={friday}
            dateSummary={this.state.dateSummary[friday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(friday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={saturday}
            dateSummary={this.state.dateSummary[saturday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(saturday.format('YYYY-MM-DD'))}
          />
          <Day
            firstDateOfMonth={this.state.firstDateOfMonth}
            date={sunday}
            dateSummary={this.state.dateSummary[sunday.format('YYYY-MM-DD')]}
            onGoToDate={() => this.props.onGoToDate(sunday.format('YYYY-MM-DD'))}
          />
        </div>,
      ];
    }
    return children;
  };

  render() {
    return (
      <>
        <div className={styles['calendar-header']}>
          <div className={styles['month']}>{`${moment(this.state.firstDateOfMonth).format('MMMM')} ${moment(
            this.state.firstDateOfMonth
          ).format('YYYY')}`}</div>
          <div className={styles['navigation-container']}>
            <Button type="icon" icon={<Icon regular type="angle-left" />} onClick={this.goToPreviousMonth} />
            <Button type="icon" icon={<Icon regular type="angle-right" />} onClick={this.goToNextMonth} />
          </div>
        </div>
        <div className={styles['calendar']}>
          <div className={styles['weekdays']}>
            <div className={`${styles['weekday']} ${styles['week-number-placeholder']}`} />
            <div className={styles['weekday']}>{this.renderDay(1)}</div>
            <div className={styles['weekday']}>{this.renderDay(2)}</div>
            <div className={styles['weekday']}>{this.renderDay(3)}</div>
            <div className={styles['weekday']}>{this.renderDay(4)}</div>
            <div className={styles['weekday']}>{this.renderDay(5)}</div>
            <div className={styles['weekday']}>{this.renderDay(6)}</div>
            <div className={styles['weekday']}>{this.renderDay(7)}</div>
          </div>
          <div className={styles['container']}>{this.renderWeeks()}</div>
          <div className={`${styles['loader']} ${this.state.isFetching ? styles['show'] : ''}`} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(Calendar);
