import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import ContentLoader from 'react-content-loader';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TemplateField from '../TemplateField';
import DraggableTemplateFieldContainer from '../DraggableTemplateFieldContainer';
import styles from './style.module.scss';

export default function DroppableColumn(props) {
  const { setNodeRef } = useDroppable({
    id: props.column,
  });

  function disableSortingStrategy() {
    return null;
  }
  if (props.loading) {
    return (
      <div className={styles['column']}>
        <div className={styles['field-container']}>
          <div>
            <ContentLoader viewBox="0 0 270 65" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="65" height="14" />
              <rect x="0" y="22" rx="3" ry="3" width="270" height="39" />
            </ContentLoader>
          </div>
        </div>
        <div className={styles['field-container']}>
          <div>
            <ContentLoader viewBox="0 0 270 65" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="65" height="14" />
              <rect x="0" y="22" rx="3" ry="3" width="270" height="39" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  }
  return (
    <SortableContext
      id={props.column}
      items={props.fieldIds}
      key={props.column}
      strategy={disableSortingStrategy}
    >
      <div className={styles['column']} ref={setNodeRef}>
        {props.fieldIds.map(id => {
          const type = id.split('_')[2];
          return (
            <DraggableTemplateFieldContainer id={id} key={id} disableDrag={props.disableDrag}>
              <TemplateField
                key={id}
                id={id}
                selected={id === props.selectedTemplateFieldId}
                onSelectTemplateField={props.onSelectTemplateField}
                type={type}
              />
            </DraggableTemplateFieldContainer>
          );
        })}
      </div>
    </SortableContext>
  );
}
