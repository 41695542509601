import HelperFunctions from 'utilities/HelperFunctions';
import { omit } from 'lodash-es';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getNavigatedTo = state => {
  const { workOrders } = state;
  return workOrders.navigatedTo;
};

const getWorkOrders = state => {
  const { entities } = state;
  return state.workOrders.ids.map(id => entities.workOrderById[id]);
};

const getInstancesForDate = (state, date) => {
  const { workOrders } = state;
  if (workOrders.calendar[date] == null) {
    return [];
  }
  return workOrders.calendar[date].map(id => workOrders.instanceById[id]);
};

const getPreviewOverlayData = state => {
  const { workOrders } = state;
  return workOrders.previewOverlay;
};

const getPreviewMoreWorkOrdersOverlayData = state => {
  const { workOrders } = state;
  return workOrders.showPreviewMoreWorkOrdersOverlay;
};

const getEstimatedTimeForDay = (state, calendarInstances) => {
  const { entities } = state;
  return calendarInstances.reduce((acc, instance) => {
    if (instance.type === 'work_order') {
      const workOrder = entities.workOrderById[instance.work_order];
      return acc + workOrder.estimated_minutes;
    } else {
      const recurringMaintenance = entities.recurringMaintenanceById[instance.recurring_maintenance];
      return acc + recurringMaintenance.estimated_minutes;
    }
  }, 0);
};

const getDraggingWorkOrderId = state => {
  const { workOrders } = state;
  return workOrders.isDraggingWorkOrderId;
};
const getDraggingRecurringMaintenanceId = state => {
  const { workOrders } = state;
  return workOrders.isDraggingRecurringMaintenanceId;
};

const getInstance = (state, id) => {
  const { workOrders } = state;
  return workOrders.instanceById[id];
};

const getWorkOrderListPagination = state => state.workOrders.pagination;
const isFetchingWorkOrders = state => state.workOrders.isFetching;
const isFetchingCounts = state => state.workOrders.isFetchingCounts;

const getWorkOrderListCounts = state => {
  const { workOrders } = state;
  return workOrders.counts;
};

/*
  Filter
*/

const getQueryParameters = (state, key) => {
  const { workOrders } = state;
  return workOrders.queryParams;
};

const getFilters = (state, key) => {
  const { workOrders } = state;
  return workOrders.filters;
};

const getFiltersAsQueryParams = (state, key) => {
  const { workOrders } = state;
  let workOrderFilters = { ...workOrders.filters };
  if (workOrders.appliedFilters.includes(FILTER_TYPES.Assignee)) {
    const assignee = workOrders.filters.assignee;
    const comparator = Object.keys(assignee)[0];
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const value = assignee[comparator];
      workOrderFilters = {
        ...workOrderFilters,
        assignee: {
          [comparator]: value,
        },
      };
    } else {
      const { userIds, groupIds, vendorIds } = assignee[comparator];
      const userIdsString = userIds?.length > 0 ? `${userIds.map(id => `u:${id}`).toString()},` : '';
      const groupIdsString = groupIds?.length > 0 ? `${groupIds.map(id => `g:${id}`).toString()},` : '';
      const vendorIdsString = vendorIds?.length > 0 ? `${vendorIds.map(id => `v:${id}`).toString()},` : '';
      workOrderFilters = {
        ...workOrderFilters,
        assignee: {
          [comparator]: `${userIdsString}${groupIdsString}${vendorIdsString}`,
        },
      };
    }
  }
  if (workOrders.appliedFilters.includes(FILTER_TYPES.Request)) {
    const { comparator, value } = workOrders.filters.requests.request_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const { request_id, ...requestsFilter } = workOrderFilters.requests;
      workOrderFilters = {
        ...workOrderFilters,
        requests: {
          ...requestsFilter,
          exists: value,
        },
      };
    }
  }
  if (workOrders.appliedFilters.includes(FILTER_TYPES.UsedSparePart)) {
    const { comparator, value } = workOrders.filters.spare_part_withdrawals.spare_part_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const { spare_part_id, ...sparePartWithdrawalsFilter } = workOrderFilters.spare_part_withdrawals;
      workOrderFilters = {
        ...workOrderFilters,
        spare_part_withdrawals: {
          ...sparePartWithdrawalsFilter,
          exists: value,
        },
      };
    }
  }
  if (workOrders.appliedFilters.includes(FILTER_TYPES.Interval)) {
    const { comparator, value } = workOrderFilters[FILTER_TYPES.Interval];
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      workOrderFilters = omit(
        {
          ...workOrderFilters,
          recurring_maintenance_id: {
            exists: value,
          },
        },
        'recurring_interval'
      );
    } else {
      workOrderFilters = {
        ...workOrderFilters,
        recurring_interval: value,
      };
    }
  }
  return HelperFunctions.buildFilter(workOrderFilters);
};

const getAppliedFilters = (state, key) => {
  const { workOrders } = state;
  return workOrders.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { workOrders } = state;
  return workOrders.filters[key];
};

const isFiltering = (state, key) => {
  const { workOrders } = state;
  return workOrders.appliedFilters.length > 0;
};

const showingAppliedFilters = (state, key) => {
  const { workOrders } = state;
  return workOrders.showingAppliedFilters;
};

const getSelectedFilterType = (state, key) => {
  const { workOrders } = state;
  return workOrders.showApplyFilterForField;
};

/*
  MassEdit
*/

const getSelectedWorkOrderIds = state => {
  return state.workOrders.selectedWorkOrders.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.workOrders.selectedWorkOrders.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.workOrders.selectedWorkOrders.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.workOrders.selectedWorkOrders.pageIsSelected;
};

const getSelectedWorkOrdersCount = state => {
  if (state.workOrders.selectedWorkOrders.totalEntriesAreSelected) {
    return state.workOrders.pagination.totalEntries;
  }
  return Object.keys(state.workOrders.selectedWorkOrders.ids).length;
};

export default {
  getNavigatedTo,
  getPageIsSelected,
  getShowSelectTotalEntries,
  getTotalEntriesIsSelected,
  getAppliedFilters,
  getQueryParameters,
  getDraggingWorkOrderId,
  getDraggingRecurringMaintenanceId,
  getFiltersForKey,
  getFiltersAsQueryParams,
  getFilters,
  getEstimatedTimeForDay,
  getWorkOrders,
  isFetchingWorkOrders,
  getWorkOrderListPagination,
  getInstancesForDate,
  getInstance,
  getPreviewOverlayData,
  getPreviewMoreWorkOrdersOverlayData,
  getWorkOrderListCounts,
  isFetchingCounts,
  getSelectedFilterType,
  isFiltering,
  showingAppliedFilters,
  getSelectedWorkOrderIds,
  getSelectedWorkOrdersCount,
};
