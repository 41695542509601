import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'views/components/Shared/General';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EntitySelectors } from 'sdk/State/entities';
import { OperationalMaintenanceTemplateOperations } from 'state/ducks/operationalMaintenanceTemplate';
import NotFound from './NotFound';
import View from './View';

class OperatorMaintenanceTemplateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      isFetching: true,
    };
  }

  componentDidMount() {
    this.fetchOperationalMaintenance();
  }

  fetchOperationalMaintenance = () => {
    this.props
      .fetchOperationalMaintenanceTemplate(this.props.match.params.id)
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return (
        <PerfectScrollbar>
          <NotFound />
        </PerfectScrollbar>
      );
    } else if (this.state.isFetching) {
      return <Loader centerInParent />;
    } else {
      return (
        <View
          onCreatedNewVersion={() => {
            this.setState({ isFetching: true });
            this.fetchOperationalMaintenance();
          }}
        />
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenanceTemplate:
        OperationalMaintenanceTemplateOperations.fetchOperationalMaintenanceTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(state, id);
  return {
    operationalMaintenanceTemplate,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorMaintenanceTemplateDetail);
