import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Section, Option, Separator } from 'views/scenes/Settings/components';
import { Button, ConfirmModal, WhiteCard } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import EditSSOModal from './EditSSOModal';
import styles from './style.module.scss';

class Integrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditSSOModal: false,
      showDeactivateSSOConfirmModal: false,
      isDeactivatingSSO: false,
    };
  }

  renderSsoTitle = () => {
    if (this.props.organisationSettings.sso_oidc_activated) {
      return (
        <>
          <FormattedMessage id="screens.settings.integrations.open-id.title" />
          <span className={styles['activated']}>
            <span> - </span>
            <FormattedMessage id="screens.settings.integrations.open-id.activated" />
          </span>
        </>
      );
    }
    return (
      <>
        <FormattedMessage id="screens.settings.integrations.open-id.title" />
        <span className={styles['not-activated']}>
          <span> - </span>
          <FormattedMessage id="screens.settings.integrations.open-id.not-activated" />
        </span>
      </>
    );
  };

  renderDeactivateSSoConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showDeactivateSSOConfirmModal}
        title={<FormattedMessage id="screens.settings.integrations.deactivate-sso-modal.title" />}
        message={<FormattedMessage id="screens.settings.integrations.deactivate-sso-modal.subtitle" />}
        confirmButtonText="general.deactivate"
        confirmIsLoading={this.state.isDeactivatingSSO}
        onConfirm={() => {
          this.setState({ isDeactivatingSSO: true });
          this.props
            .updateOrganisationSettings(this.props.currentOrganisation.id, { sso_oidc_activated: false })
            .then(() => {
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.setState({ showDeactivateSSOConfirmModal: false, isDeactivatingSSO: false });
            })
            .catch(() => {
              this.setState({ isDeactivatingSSO: false });
            });
        }}
        onCancel={() => {
          this.setState({ showDeactivateSSOConfirmModal: false });
          setTimeout(() => {
            this.setState({ showEditSSOModal: true });
          }, 100);
        }}
      />
    );
  };

  renderEditSSOModal = () => {
    return (
      <EditSSOModal
        open={this.state.showEditSSOModal}
        onDelete={() => {
          this.setState({ showEditSSOModal: false });
          setTimeout(() => {
            this.setState({ showDeactivateSSOConfirmModal: true });
          }, 100);
        }}
        onClose={() => this.setState({ showEditSSOModal: false })}
      />
    );
  };

  render() {
    return (
      <WhiteCard>
        <Section
          header
          title={<FormattedMessage id="screens.settings.integrations.title" />}
          subtitle={<FormattedMessage id="screens.settings.integrations.subtitle" />}
        />
        <Separator />
        <div className={styles['options']}>
          <Option.Container>
            <Option
              title={this.renderSsoTitle()}
              subtitle={<FormattedMessage id="screens.settings.integrations.open-id.subtitle" />}
              rightContainer={
                <>
                  <Button
                    primary
                    label={
                      this.props.organisationSettings.sso_oidc_activated
                        ? 'general.manage'
                        : 'general.activate'
                    }
                    onClick={() => this.setState({ showEditSSOModal: true })}
                  />
                </>
              }
            />
          </Option.Container>
        </div>
        {this.renderEditSSOModal()}
        {this.renderDeactivateSSoConfirmModal()}
      </WhiteCard>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationSettings: SDKReduxOperations.updateOrganisationSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentOrganisation: AuthSelectors.getCurrentOrganisation(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Integrations));
