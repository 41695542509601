import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { AssetAttachmentContent } from 'views/components/Asset';
import { Modal } from 'views/components/Shared/Layout';

export default class SelectAssetAttachmentModal extends Component {
  renderContent = () => {
    if (!this.props.assetId) return null;
    return (
      <React.Fragment>
        <Modal.Header
          title={<FormattedMessage id="components.select-asset-attachment.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <AssetAttachmentContent
            selectable
            onSelectAttachment={this.props.onSelectAttachment}
            assetId={this.props.assetId}
          />
        </Modal.Content>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} width="850">
        {this.renderContent()}
      </Modal>
    );
  }
}
