import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import * as moment from 'moment';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Button, EmptyDataSet } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Title } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AssetTitle, ChooseAssetInlineModal, TreePath } from 'views/components/Asset';
import UnitContainer from './UnitContainer';
import styles from './styles.module.scss';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingSparePartWithrawal: { ...props.sparePartWithdrawal },
      showTitleRequiredError: false,
      showQuantityRequiredError: false,
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  cancelEdit = () => {
    const hasUnsavedChanged = !isEqual(this.props.sparePartWithdrawal, this.state.editingSparePartWithrawal);
    if (hasUnsavedChanged) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onCancel();
      }
    } else {
      this.props.onCancel();
    }
  };

  changeValue = params => {
    this.setState({
      editingSparePartWithrawal: {
        ...this.state.editingSparePartWithrawal,
        ...params,
      },
    });
  };

  getEditingChanges = () => {
    let data = {};
    const sparePartWithdrawal = this.state.editingSparePartWithrawal;
    const diffProps = Object.keys(sparePartWithdrawal).filter(
      k => sparePartWithdrawal[k] !== this.props.sparePartWithdrawal[k]
    );
    diffProps.forEach(key => {
      data[key] = sparePartWithdrawal[key];
    });
    return data;
  };

  closeModal = () => {
    const hasUnsavedChanged = !isEqual(this.props.sparePartWithdrawal, this.state.editingSparePartWithrawal);
    if (hasUnsavedChanged) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  hasErrors = () => {
    let hasErors = false;
    if (this.props.sparePart == null && this.state.editingSparePartWithrawal.title.length === 0) {
      hasErors = true;
      this.setState({ showTitleRequiredError: true });
    }
    if (this.state.editingSparePartWithrawal.quantity.length === 0) {
      hasErors = true;
      this.setState({ showQuantityRequiredError: true });
    }
    return hasErors;
  };

  updateSparePartWithdrawal = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .updateSparePartWithdrawal(this.props.sparePartWithdrawal.id, this.getEditingChanges())
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onCancel();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderChooseAssetField = () => {
    const { work_order_id, operational_maintenance_instance_id } = this.props.sparePartWithdrawal;
    if (work_order_id == null && operational_maintenance_instance_id == null) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.work-order.asset" />}>
              <ChooseAssetInlineModal
                hideCreateButton
                trigger={
                  <Field.Resource
                    angleDown
                    value={
                      this.state.editingSparePartWithrawal.asset_id ? (
                        <>
                          <AssetTitle id={this.state.editingSparePartWithrawal.asset_id} />
                          <TreePath assetId={this.state.editingSparePartWithrawal.asset_id} />
                        </>
                      ) : null
                    }
                    onClear={() => {
                      this.changeValue({ asset_id: null });
                    }}
                  />
                }
                selectedAssetId={this.state.editingSparePartWithrawal.asset_id}
                onSelectAsset={treeParentId => {
                  this.changeValue({ asset_id: treeParentId });
                }}
                onClear={e => this.changeValue({ asset_id: null })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderInfo = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.spare-part-withdrawal-modal.spare-part" />}>
            {this.renderTitleField()}
          </Field>
        </Grid.Column>
      </Grid.Row>
      {this.renderUnitField()}

      {this.renderChooseAssetField()}

      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.spare-part-withdrawal-modal.quantity" />}>
            <Field.Decimal
              error={this.state.showQuantityRequiredError}
              rightLabel={this.props.sparePartUnit ? this.props.sparePartUnit.abbreviation : null}
              value={this.state.editingSparePartWithrawal.quantity}
              onChange={quantity => {
                if (this.state.showQuantityRequiredError && quantity) {
                  this.setState({ showQuantityRequiredError: false });
                }
                this.changeValue({ quantity });
              }}
              onBlur={quantity => this.changeValue({ quantity })}
            />
          </Field>
        </Grid.Column>
        {this.renderCostColumn()}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.spare-part-withdrawal-modal.comment" />}>
            <Field.Textarea
              value={this.state.editingSparePartWithrawal.comment}
              onChange={comment => this.changeValue({ comment })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  renderCostColumn = () => {
    const { canViewWorkOrderCosts, hasProTier } = this.props;
    if (canViewWorkOrderCosts && hasProTier) {
      return (
        <Grid.Column>
          <Field label={<FormattedMessage id="components.spare-part-withdrawal-modal.cost-per" />}>
            <Field.Money
              currency={this.props.sparePartWithdrawal.purchase_price_currency}
              value={this.state.editingSparePartWithrawal.purchase_price}
              onChange={purchase_price => {
                this.changeValue({ purchase_price });
              }}
              onBlur={purchase_price => {
                this.changeValue({ purchase_price });
              }}
            />
          </Field>
        </Grid.Column>
      );
    }
    return null;
  };

  renderUnits = () => {
    if (this.props.sparePart) {
      return null;
    }
    if (this.props.sparePartUnits.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.spare-part.info.general-information.unit-empty-data-set.subtitle" />
            }
            tiny
          />
        </div>
      );
    }
    return this.props.sparePartUnits.map(sparePartUnit => (
      <Field.Dropdown.Item
        key={sparePartUnit.id}
        selected={this.state.editingSparePartWithrawal.spare_part_unit_id === sparePartUnit.id}
        onClick={() => this.changeValue({ spare_part_unit_id: sparePartUnit.id })}
      >
        {sparePartUnit.title}
      </Field.Dropdown.Item>
    ));
  };

  renderSelectedSparePartUnit = () => {
    if (this.state.editingSparePartWithrawal.spare_part_unit_id) {
      return <UnitContainer id={this.state.editingSparePartWithrawal.spare_part_unit_id} />;
    }
    return null;
  };

  renderUnitField = () => {
    if (this.props.sparePart) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field view={false} label={<FormattedMessage id="resources.spare-part.unit" />}>
            <Field.Dropdown
              clearable
              direction="left"
              value={this.renderSelectedSparePartUnit()}
              onClear={() => this.changeValue({ spare_part_unit_id: null })}
            >
              {this.renderUnits()}
            </Field.Dropdown>
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderTitleField = () => {
    if (this.props.sparePart) {
      return <Field.Resource clearable={false} disabled value={<Title sparePart={this.props.sparePart} />} />;
    }
    return (
      <Field.Text
        value={this.state.editingSparePartWithrawal.title}
        error={this.state.showTitleRequiredError}
        autoFocus
        onChange={title => {
          if (this.state.showTitleRequiredError && title) {
            this.setState({ showTitleRequiredError: false });
          }
          this.changeValue({ title });
        }}
      />
    );
  };

  renderSubtitle = () => {
    if (this.props.sparePart) {
      return <Title sparePart={this.props.sparePart} />;
    }
    return this.props.sparePartWithdrawal.title;
  };

  render() {
    return (
      <>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={
            <>
              <FormattedMessage id="components.spare-part-withdrawal-modal.title" />
              <span> - </span>
              <span>
                {moment(this.props.sparePartWithdrawal.created_at)
                  .tz(this.props.currentSystem.timezone)
                  .format('LLL')}
              </span>
            </>
          }
          subtitle={this.renderSubtitle()}
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderInfo()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              onClick={this.updateSparePartWithdrawal}
              label="general.save"
            />
            <Button label="general.cancel" onClick={this.cancelEdit} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartWithdrawal: SDKReduxOperations.updateSparePartWithdrawal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
  let sparePartUnit = null;
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  } else {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }
  return {
    sparePartWithdrawal,
    sparePart,
    sparePartUnit,
    asset: EntitySelectors.getAsset(state, sparePartWithdrawal.asset_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    hasProTier: AuthSelectors.hasProTier(state),
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit));
