const getPurchaseOrder = state => {
  const { purchaseOrder, entities } = state;
  if (!purchaseOrder.id) return null;
  return entities.purchaseOrderById[purchaseOrder.id];
};

const getPurchaseOrderDeliveries = state => {
  const { entities, purchaseOrder } = state;
  return purchaseOrder.deliveries.ids.map(id => entities.purchaseOrderDeliveryById[id]);
};

const getWorkOrderPurchases = state => {
  const { entities, purchaseOrder } = state;
  return purchaseOrder.workOrderPurchases.ids.map(id => entities.workOrderPurchaseById[id]);
};

const getAttachments = state => {
  const { entities, purchaseOrder } = state;
  return purchaseOrder.attachments.ids.map(id => entities.attachmentById[id]);
};

const getAttachmentsPagination = state => state.purchaseOrder.attachments.pagination;

export default {
  getPurchaseOrder,
  getAttachments,
  getAttachmentsPagination,
  getPurchaseOrderDeliveries,
  getWorkOrderPurchases,
};
