import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Datapill, ElapsedTime } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class DowntimeListItem extends Component {
  renderDataPills = () => {
    const toTime = this.props.downtime.to ? moment(this.props.downtime.to) : moment();
    const elapsedMinutes = toTime.diff(moment(this.props.downtime.from), 'minutes');

    return (
      <Datapill.List>
        {this.props.downtime.to ? null : (
          <Datapill
            red
            value={<FormattedMessage id="components.select-downtime-modal.downtime-list-item.active" />}
          />
        )}
        <Datapill value={<ElapsedTime elapsedMinutes={elapsedMinutes} />} />
        {this.props.settings.asset_number_activated ? (
          <Datapill
            value={
              <span>
                <FormattedMessage id="resources.asset.number-long" />
                {':  #'}
                {this.props.asset.number}
              </span>
            }
          />
        ) : null}
      </Datapill.List>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }

    return (
      <List.Item dataPillsComponent={this.renderDataPills()}>
        <List.Item.TitleColumn
          title={
            <span className={this.props.downtime.to ? null : styles['active-label']}>
              <FormattedMessage
                id="components.select-downtime-modal.downtime-list-item.created-time"
                values={{
                  date: moment(this.props.downtime.from).format('D MMMM YYYY'),
                  time: moment(this.props.downtime.from).format('HH:mm'),
                }}
              />
            </span>
          }
          subtitle={<TreePath fullPath assetId={this.props.asset.id} />}
        />
        <List.Item.Column>{this.props.listItemRightComponent(this.props.downtime)}</List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const downtime = EntitySelectors.getDowntime(state, ownProps.id);
  return {
    downtime,
    asset: EntitySelectors.getAsset(state, downtime.asset_id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(DowntimeListItem);
