import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions } from 'sdk';
import { CalendarSelectors, CalendarOperations } from 'state/ducks/calendar';
import styles from '../style.module.scss';
import WorkOrderListItem from './WorkOrderListItem';

const request = HelperFunctions.getCancelTokenForRequest();

class Overdue extends Component {
  state = {
    isFetching: true,
    isFetchingMore: false,
    paginateFrom: null,
  };

  componentDidMount() {
    this.fetchWorkOrders()
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workOrders.length >= 20 && this.props.workOrders.length < 20) {
      this.fetchMoreWorkOrders();
    }
  }

  componentWillUnmount() {
    request.cancel();
  }

  fetchWorkOrders = (params = {}) => {
    return this.props.fetchOverdueWorkOrders(
      this.props.currentSystem.id,
      params,
      request.getCancelTokenConfig()
    );
  };

  fetchMoreWorkOrders = () => {
    if (this.state.isFetchingMore || this.state.isFetching || !this.props.canFetchMoreWorkOrders) return;
    this.setState({ isFetchingMore: true });
    this.fetchWorkOrders({ paginate_from: this.props.paginateFrom })
      .then(() => {
        this.setState({ isFetchingMore: false });
      })
      .catch(() => {});
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny />
        </div>
      );
    }
    if (this.props.workOrders.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['separator']} />
          <div className={styles['text']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.overdue.empty-data-set.title" />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['list']}>
        {this.props.workOrders.map((workOrder, index) => (
          <WorkOrderListItem index={index} id={workOrder.id} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <InfiniteScroll
        loadMore={this.fetchMoreWorkOrders}
        hasMore={this.props.canFetchMoreWorkOrders}
        loader={
          this.state.isFetchingMore ? (
            <div className={styles['loader-container']}>
              <Loader tiny />
            </div>
          ) : null
        }
        useWindow={false}
        initialLoad={false}
        threshold={150}
      >
        <div className={styles['header-container']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.calendar.toolbox.lists.overdue.title" />
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={() => {
                  this.props.hideListBar();
                }}
              />
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.overdue.subtitle" />
          </div>
        </div>
        {this.renderContent()}
      </InfiniteScroll>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideListBar: CalendarOperations.hideListBar,
      fetchOverdueWorkOrders: CalendarOperations.fetchOverdueWorkOrders,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    workOrders: CalendarSelectors.getOverdueWorkOrders(state),
    paginateFrom: CalendarSelectors.getPaginateFromForOverdueWorkOrders(state),
    canFetchMoreWorkOrders: CalendarSelectors.canFetchMoreOverdueWorkOrders(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overdue);
