import React from 'react';
import { connect } from 'react-redux';
import { List, Icon } from 'views/components/Shared/General';
import { getVendorSubtitle } from 'sdk/Vendor';
import { EntitySelectors } from 'sdk/State/entities';

const VendorListItem = props => (
  <List.Item clickable onClick={() => props.onSelectVendor(props.id)}>
    <List.Item.TitleColumn title={props.vendor.name} subtitle={getVendorSubtitle(props.vendor)} />
    {props.vendor.id === props.selectedVendorId ? (
      <List.Item.Column width={20}>
        <Icon green type="check" />
      </List.Item.Column>
    ) : null}
  </List.Item>
);

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorListItem);
