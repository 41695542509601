import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class CreatedAt extends Component {
  state = {
    initialFilter: this.props.filter,
  };

  render() {
    const { comparator, value } =
      this.props.filter[FILTER_TYPES.CreatedAt] || this.props.initialFilter[FILTER_TYPES.CreatedAt];
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter.created_at, this.state.initialFilter.created_at)) {
              this.props.removeFilter({
                key: FILTER_TYPES.CreatedAt,
                data: {
                  work_order: {
                    ...this.props.filter,
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.CreatedAt,
                data: {
                  work_order: {
                    ...this.props.filter,
                    created_at: {
                      ...this.state.initialFilter.created_at,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.created-at" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Date
            value={value}
            required
            comparator={comparator}
            onChange={({ value, comparator }) => {
              this.props.addFilter({
                key: FILTER_TYPES.CreatedAt,
                data: {
                  work_order: {
                    ...this.props.filter,
                    [FILTER_TYPES.CreatedAt]: {
                      comparator,
                      value,
                    },
                  },
                },
              });
            }}
            onApplyfilter={this.props.showAppliedFilters}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatedAt);

CreatedAt.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

CreatedAt.defaultProps = {
  filter: {
    created_at: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
      value: null,
    },
  },
  initialFilter: {
    created_at: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
      value: null,
    },
  },
};
