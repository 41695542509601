import actions from './actions';
import { normalizeAssetBookmark } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API } from 'sdk';

const selectAssetId = actions.selectAssetId;

export default {
  selectAssetId,
};
