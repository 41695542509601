import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { ReminderType, WorkOrderCategory } from 'sdk/WorkOrder';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { CategoryInlineModal } from 'views/components/WorkOrder';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Category extends Component {
  constructor(props) {
    super(props);
    const { comparator, value } = props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        comparator,
        category: null,
        hasValue: value,
        initialFilter: props.filter,
      };
    } else {
      this.state = {
        comparator,
        category: value,
        hasValue: false,
        initialFilter: props.filter,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: FILTER_TYPES.Category,
            data: {
              [FILTER_TYPES.Category]: {
                comparator: this.state.comparator,
                value: this.state.hasValue,
              },
            },
          });
        } else {
          this.props.addFilter({
            key: FILTER_TYPES.Category,
            data: {
              [FILTER_TYPES.Category]: {
                comparator: this.state.comparator,
                value: this.state.category,
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return this.state.category != null;
  };

  /*
    Render functions
  */

  renderSelectedCategoryValue = () => {
    switch (this.state.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.corrective" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.corrective" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.corrective-defered" />
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.preventive" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.preventive" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderSelectedCategory = () => {
    if (this.state.category == null) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {this.renderSelectedCategoryValue()}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  renderTrigger = () => {
    return (
      <CategoryInlineModal
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedCategory()}
            onClear={() => this.setState({ category: null })}
          />
        }
        selectedCategory={this.state.category}
        onSelect={category => {
          this.setState({
            category,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Category,
                data: {
                  [FILTER_TYPES.Category]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Category,
                data: {
                  [FILTER_TYPES.Category]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order.category" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
      addFilter: WorkOrdersOperations.addFilter,
      showAppliedFilters: WorkOrdersOperations.showAppliedFilters,
      selectFilterType: WorkOrdersOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Category),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);

Category.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Category.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: null,
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Any,
    value: null,
  },
};
