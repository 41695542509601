import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeQrRowTemplate } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createQrTemplateRow(qrTemplateId, params) {
  return dispatch => {
    return API.createQrTemplateRow(qrTemplateId, params)
      .then(res => {
        const { entities } = normalizeQrRowTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { qrTemplateId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_ROW_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateQrTemplateRow(qrTemplateRowId, params, config) {
  return dispatch => {
    return API.updateQrTemplateRow(qrTemplateRowId, params, config)
      .then(res => {
        const { entities } = normalizeQrRowTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { qrTemplateRowId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_ROW_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteQrTemplateRow(qrTemplateRowId) {
  return (dispatch, getState) => {
    const qrTemplateRow = getState().entities.qrTemplateRowById[qrTemplateRowId];
    return API.deleteQrTemplateRow(qrTemplateRowId)
      .then(res => {
        const payload = { qrTemplateRowId, data: qrTemplateRow };
        dispatch({ type: types.QR_TEMPLATE_ROW_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
