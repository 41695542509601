import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import ChecklistTemplateRows from './ChecklistTemplateRows';
import DragLayer from './DragLayer';
import FieldEditor from './FieldEditor';
import { FullScreenOverlayModal } from 'views/components/Shared/Layout';
import Header from './Header';
import CantDropSectionInSectionModal from './CantDropSectionInSectionModal';
import styles from './style.module.scss';

class ChecklistEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      this.props.setIsCreatingChecklistFromTemplatesLibrary(this.props.isCreatingChecklistFromLibrary);

      if (this.props.id) {
        this.props.fetchChecklistTemplateVersion(this.props.id).then(() => {
          this.setState({ isFetching: false });
        });
      }
    } else if (!prevProps.id && this.props.id) {
      this.setState({ isFetching: true });
      this.props.fetchChecklistTemplateVersion(this.props.id).then(() => {
        this.setState({ isFetching: false });
      });
    }
  }

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['container']}>
          <Header loading onClose={this.props.onClose} />
          <div className={styles['content']}>
            <div className={styles['main-content']}>
              <ChecklistTemplateRows loading />
            </div>
            <FieldEditor loading />
          </div>
        </div>
      );
    }
    return (
      <>
        <Prompt
          when={this.props.showUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <div className={styles['container']}>
          <Header
            checklistTemplateLinkId={this.props.checklistTemplateLinkId}
            showAbortChangesButton={this.props.showAbortChangesButton}
            isSaving={this.props.isSaving}
            isEditingDraft={this.props.isEditingDraft}
            isRevertingChanges={this.props.isRevertingChanges}
            workOrderId={this.props.workOrderId}
            recurringMaintenanceId={this.props.recurringMaintenanceId}
            operationalMaintenanceTemplateVersionId={this.props.operationalMaintenanceTemplateVersionId}
            new={this.props.new}
            onClose={this.props.onClose}
            onSave={this.props.onSave}
            onAddToLibrarySuccess={this.props.onAddToLibrarySuccess}
            onRevertChanges={this.props.onRevertChanges}
          />
          <div className={styles['content']}>
            <div className={styles['main-content']}>
              <ChecklistTemplateRows />
            </div>
            <FieldEditor assetId={this.props.assetId} hideSaveAsReusable={this.props.hideSaveAsReusable} />
          </div>
        </div>
        <DragLayer />
        <CantDropSectionInSectionModal
          open={this.props.showSectionCantBeDroppedInModal}
          onClose={() => {
            this.props.hideSectionCantBeDroppedInModal();
          }}
        />
      </>
    );
  };

  render() {
    return <FullScreenOverlayModal open={this.props.open}>{this.renderContent()}</FullScreenOverlayModal>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setShowEditTitleModal: ChecklistEditModalOperations.setShowEditTitleModal,
      setIsCreatingChecklistFromTemplatesLibrary:
        ChecklistEditModalOperations.setIsCreatingChecklistFromTemplatesLibrary,
      fetchChecklistTemplateVersion: ChecklistEditModalOperations.fetchChecklistTemplateVersion,
      hideSectionCantBeDroppedInModal: ChecklistEditModalOperations.hideSectionCantBeDroppedInModal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    checklistTemplateVersion: ChecklistEditModalSelectors.getChecklistTemplateVersion(state),
    showSectionCantBeDroppedInModal: ChecklistEditModalSelectors.showSectionCantBeDroppedInModal(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChecklistEditModal)));

ChecklistEditModal.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  checklistTemplateLinkId: PropTypes.string,
  onClose: PropTypes.func,
  onAddToLibrary: PropTypes.func,
  isSaving: PropTypes.bool,
  isRevertingChanges: PropTypes.bool,
  isEditingDraft: PropTypes.bool,
  showUnsavedChanges: PropTypes.bool,
  isCreatingChecklistFromLibrary: PropTypes.bool,
  hideSaveAsReusable: PropTypes.bool, // Temporary prop for operational maintenance
  workOrderId: PropTypes.string,
  recurringMaintenanceId: PropTypes.string,
  assetId: PropTypes.string,
  new: PropTypes.bool,
};

ChecklistEditModal.defaultProps = {
  open: false,
  id: null,
  checklistTemplateLinkId: null,
  onClose: () => {},
  onSave: () => {},
  onRevertChanges: () => {},
  onAddToLibrary: () => {},
  isSaving: false,
  isRevertingChanges: false,
  hideSaveAsReusable: false,
  isEditingDraft: false,
  showUnsavedChanges: true,
  isCreatingChecklistFromLibrary: false,
  workOrderId: null,
  recurringMaintenance: null,
  assetId: null,
  new: false, // true from <NewWorkOrderModal /> when there is no RO or RM.
};
