import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, InlineModal, Tooltip, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class FolderList extends Component {
  renderButtons = attachmentFolder => {
    if (attachmentFolder.id === this.props.disableSelectForFolderId) {
      return (
        <Tooltip
          label={
            <FormattedMessage id="components.folder-options-inline-modal.change-folder.cant-move-to-own-folder" />
          }
          trigger={
            <div className={styles['question-icon']}>
              <Icon regular type="question-circle" />
            </div>
          }
        />
      );
    } else {
      return (
        <Button
          type="icon"
          icon={<Icon regular size={18} type="angle-right" />}
          small
          onClick={() => {
            this.props.showFoldersForFolder(attachmentFolder);
          }}
        />
      );
    }
  };

  render() {
    return this.props.attachmentFolders.length === 0 ? (
      <div className={styles['empty']}>
        <p>
          <FormattedMessage id="components.folder-options-inline-modal.change-folder.empty" />
        </p>
      </div>
    ) : (
      <React.Fragment>
        {this.props.attachmentFolders.map(attachmentFolder => (
          <InlineModal.ListItem
            key={attachmentFolder.id}
            icon="folder"
            title={attachmentFolder.title}
            disabled={attachmentFolder.id === this.props.disableSelectForFolderId}
            selected={attachmentFolder.id === this.props.selectedAttachmentFolderId}
            onClick={() => {
              if (attachmentFolder.id !== this.props.disableSelectForFolderId) {
                this.props.onSelectAttachmentFolder(attachmentFolder);
              }
            }}
            buttons={this.renderButtons(attachmentFolder)}
          />
        ))}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentFolders: EntitySelectors.getAttachmentFoldersForFolder(
      state,
      ownProps.showAttachmentFoldersForFolderId
    ),
  };
}

export default connect(mapStateToProps)(FolderList);
