export default {
  RESET_SELECTED_VENDORS: 'vendors/reset_selected_vendors',
  TOGGLE_ALL_SELECTED_VENDORS: 'vendors/toggle_all_selected_vendors',
  SELECT_PAGE: 'vendors/select_page',
  SELECT_VENDOR: 'vendors/select_vendor',
  SELECT_TOTAL_ENTRIES: 'vendors/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'vendors/hide_select_total_entries',
  FETCH_VENDORS: 'vendors/fetch_vendors',
  FETCH_VENDORS_SUCCESS: 'vendors/fetch_vendors_success',
  ADD_QUERY_PARAMETER: 'vendors/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'vendors/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'vendors/show_available_filters',
  SELECT_FILTER_TYPE: 'vendors/select_filter_type',
  ADD_FILTER: 'vendors/add_filter',
  REMOVE_FILTER: 'vendors/remove_filter',
  RESET_FILTER: 'vendors/reset_filter',
};
