import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { ChooseAssetInlineModal, AssetTitle } from 'views/components/Asset';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from '../style.module.scss';

class AssetField extends Component {
  renderSelectedAssets = () => {
    if (this.props.assets.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.props.assets.reduce(
          (accu, asset) =>
            accu === null
              ? [
                  <div className={styles['selected-item']}>
                    <AssetTitle id={asset.id} noSubtitleStyle />
                  </div>,
                ]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>
                    <AssetTitle id={asset.id} noSubtitleStyle />
                  </div>,
                ],
          null
        )}
      </div>
    );
  };
  render() {
    return (
      <Field
        view={false}
        label={
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.edit-rule-modal.options.asset.title" />
        }
      >
        <ChooseAssetInlineModal
          hideCreateButton
          clearable={false}
          multiple
          trigger={
            <Field.Resource
              angleDown
              error={this.props.hasError}
              value={this.renderSelectedAssets()}
              onClear={() => this.props.onClear()}
            />
          }
          selectedAssetIds={this.props.ids}
          onAddAsset={assetId => this.props.onAddAsset(assetId)}
          onRemoveAsset={assetId => this.props.onRemoveAsset(assetId)}
        />
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assets: EntitySelectors.getAssets(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(AssetField);
