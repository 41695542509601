const DOMAIN_PREFIX = 'productionBoard/';

const FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD`;
const FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD_SUCCESS = `${DOMAIN_PREFIX}FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD_SUCCESS`;
const FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD_SUCCESS = `${DOMAIN_PREFIX}FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD_SUCCESS`;
const FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD = `${DOMAIN_PREFIX}FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD`;
const FETCH_REQUESTS_FOR_DASHBOARD = `${DOMAIN_PREFIX}FETCH_REQUESTS_FOR_DASHBOARD`;
const FETCH_REQUESTS_FOR_DASHBOARD_SUCCESS = `${DOMAIN_PREFIX}FETCH_REQUESTS_FOR_DASHBOARD_SUCCESS`;
const SET_PRODUCTION_BOARD_ACTIVITY_DATE = `${DOMAIN_PREFIX}SET_PRODUCTION_BOARD_ACTIVITY_DATE`;
const SET_OPERATIONAL_MAINTENANCE_DATE = `${DOMAIN_PREFIX}SET_OPERATIONAL_MAINTENANCE_DATE`;
const SET_IS_REFRESHING = `${DOMAIN_PREFIX}SET_IS_REFRESHING`;
const RESET_STATE = `${DOMAIN_PREFIX}RESET_STATE`;

export default {
  FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD,
  FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD,
  FETCH_OPERATIONAL_MAINTENANCES_FOR_DASHBOARD_SUCCESS,
  FETCH_PRODUCTION_BOARD_ACTIVITIES_FOR_DASHBOARD_SUCCESS,
  FETCH_REQUESTS_FOR_DASHBOARD,
  FETCH_REQUESTS_FOR_DASHBOARD_SUCCESS,
  SET_PRODUCTION_BOARD_ACTIVITY_DATE,
  SET_OPERATIONAL_MAINTENANCE_DATE,
  SET_IS_REFRESHING,
  RESET_STATE,
};
