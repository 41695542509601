import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { FailedInspectionCount } from 'views/components/Checklist';
import styles from './style.module.scss';

class ChecklistInstanceListItem extends Component {
  renderFailedInspectionCount = () => {
    if (this.props.checklistInstance && this.props.checklistInstance.inspection_failed_count > 0) {
      return (
        <div className={styles['failed-count-container']}>
          <FailedInspectionCount checklistInstanceId={this.props.checklistInstance.id} />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['title']}>{this.props.checklistTemplateVersion.title}</div>
        {this.renderFailedInspectionCount()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const checklistInstance = EntitySelectors.getChecklistInstance(state, ownProps.id);
  return {
    checklistInstance,
    checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    ),
  };
}

export default connect(mapStateToProps)(ChecklistInstanceListItem);
