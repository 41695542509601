import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import { FILTER_TYPES } from 'views/scenes/Assets/TreeAndList/List/FilterModal';

class Text extends Component {
  constructor(props) {
    super(props);
    const { filter, initialFilter, templateField } = props;
    if (filter && filter[templateField.id]) {
      this.state = {
        initialFilter: filter[templateField.id],
      };
    } else {
      this.state = {
        initialFilter: initialFilter,
      };
    }
  }

  renderTextType = () => {
    const { filter, templateField } = this.props;
    if (filter && filter[templateField.id]) {
      const { value, comparator } = this.props.filter[this.props.templateField.id];
      return (
        <SideModal.Container.Filter.Detail.Types.Text
          value={value}
          comparator={comparator}
          onChange={({ value, comparator }) => {
            this.props.addFilter({
              key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
              data: {
                [FILTER_TYPES.AssetField]: {
                  ...this.props.filter,
                  [this.props.templateField.id]: {
                    comparator,
                    value,
                  },
                },
              },
            });
          }}
          onApplyfilter={this.props.showAppliedFilters}
        />
      );
    } else {
      return (
        <SideModal.Container.Filter.Detail.Types.Text
          onChange={({ value, comparator }) => {
            this.props.addFilter({
              key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
              data: {
                [FILTER_TYPES.AssetField]: {
                  ...this.props.filter,
                  [this.props.templateField.id]: {
                    comparator,
                    value,
                  },
                },
              },
            });
          }}
          onApplyfilter={this.props.showAppliedFilters}
        />
      );
    }
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
                data: {
                  [FILTER_TYPES.AssetField]: {
                    ...this.props.filter,
                    [this.props.templateField.id]: {
                      ...this.props.initialFilter,
                    },
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
                data: {
                  [FILTER_TYPES.AssetField]: {
                    ...this.props.filter,
                    [this.props.templateField.id]: {
                      ...this.state.initialFilter,
                    },
                  },
                },
              });
            }

            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          {this.props.templateField.title}
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          {this.renderTextType()}
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: AssetsOperations.removeFilter,
      addFilter: AssetsOperations.addFilter,
      showAppliedFilters: AssetsOperations.showAppliedFilters,
      selectFilterType: AssetsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: AssetsSelectors.getFiltersForKey(state, FILTER_TYPES.AssetField),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);

Text.propTypes = {
  initialFilter: PropTypes.object,
};

Text.defaultProps = {
  initialFilter: {
    comparator: SDKHelperFunctions.FILTER_COMPARABLES.Search,
    value: '',
  },
};
