const getImageLoaderCount = state => state.editOperationalMaintenanceTemplate.imageLoaderCount;
const getErrors = state => state.editOperationalMaintenanceTemplate.errors;
const hasUnsavedChanges = state => state.editOperationalMaintenanceTemplate.hasUnsavedChanges;

const getEditingOperationalMaintenanceTemplateVersion = state => {
  const { editOperationalMaintenanceTemplate } = state;
  return editOperationalMaintenanceTemplate.editingOperationalMaintenanceTemplateVersion;
};

const getChecklists = state => {
  const { editOperationalMaintenanceTemplate } = state;
  return editOperationalMaintenanceTemplate.editingOperationalMaintenanceTemplateVersion.checklists;
};

const getReservedSpareParts = state => {
  const { editOperationalMaintenanceTemplate } = state;
  return editOperationalMaintenanceTemplate.editingOperationalMaintenanceTemplateVersion
    .sparePartReservations;
};

const getAttachments = state => {
  const { editOperationalMaintenanceTemplate } = state;
  return editOperationalMaintenanceTemplate.editingOperationalMaintenanceTemplateVersion.attachments;
};

const getImages = state => {
  const { editOperationalMaintenanceTemplate } = state;
  return editOperationalMaintenanceTemplate.editingOperationalMaintenanceTemplateVersion.images;
};

export default {
  getEditingOperationalMaintenanceTemplateVersion,
  hasUnsavedChanges,
  getImageLoaderCount,
  getErrors,
  getChecklists,
  getReservedSpareParts,
  getAttachments,
  getImages,
};
