import { Socket } from '../../';
import EntityOperations from 'sdk/State/entities/operations';
import * as types from './reduxTypes';
import { normalizeAttachmentFolder } from 'sdk/Schemas';

const join = systemId => {
  return dispatch => {
    const channel = Socket.socket.channel(`attachment_folders:${systemId}`);
    channel.join().receive('ok', data => {
      const { entities } = normalizeAttachmentFolder(data);
      dispatch(EntityOperations.updateEntities(entities));
    });

    channel.on('folder_created', ({ attachment_folder }) => {
      const { entities } = normalizeAttachmentFolder(attachment_folder);
      dispatch(EntityOperations.updateEntities(entities));
    });

    channel.on('folder_updated', ({ attachment_folder }) => {
      const { entities } = normalizeAttachmentFolder(attachment_folder);
      dispatch(EntityOperations.updateEntities(entities));
    });

    channel.on('folder_deleted', payload => {
      dispatch({ type: types.WS_ATTACHMENT_FOLDERS_FOLDER_DELETED, payload });
    });

    return channel;
  };
};

export default {
  join,
};
