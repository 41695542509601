import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { NewInlineModal, Button, Icon, ElapsedTime } from 'views/components/Shared/General';
import Container from './Container';
import styles from './style.module.scss';

class WorkOrderSpentTimeItem extends Component {
  state = {
    showWorkOrderSpentTimeDropdown: false,
  };

  renderTitle = () => {
    if (this.props.vendor) {
      return this.props.vendor.name;
    }
    return this.props.user.name;
  };

  render() {
    return (
      <div className={styles['item']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>{this.renderTitle()}</div>
          <div className={styles['subtitle']}>
            <ElapsedTime short elapsedMinutes={this.props.workOrderSpentTime.minutes} />
          </div>
        </div>
        <div className={styles['button-container']}>
          <div>
            <div
              ref={ref => (this.workOrderSpentTimeDropdownRef = ref)}
              onClick={() =>
                this.setState(prevState => ({
                  showWorkOrderSpentTimeDropdown: !prevState.showWorkOrderSpentTimeDropdown,
                }))
              }
            >
              <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
            </div>
            <NewInlineModal
              minWidth={200}
              positionToRef={this.workOrderSpentTimeDropdownRef}
              position="right"
              open={this.state.showWorkOrderSpentTimeDropdown}
              onClose={() => this.setState({ showWorkOrderSpentTimeDropdown: false })}
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showWorkOrderSpentTimeDropdown: false,
                      });
                      this.props.onEdit();
                    }}
                  >
                    <FormattedMessage id="general.edit" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({
                        showWorkOrderSpentTimeDropdown: false,
                      });
                      this.props.onDelete();
                    }}
                  >
                    <FormattedMessage id="general.delete" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </div>
        </div>
      </div>
    );
  }
}

WorkOrderSpentTimeItem.Container = Container;

function mapStateToProps(state, ownProps) {
  const { user_id, vendor_id } = ownProps.workOrderSpentTime;

  return {
    user: EntitySelectors.getUser(state, user_id),
    vendor: EntitySelectors.getVendor(state, vendor_id),
  };
}

export default connect(mapStateToProps, null)(WorkOrderSpentTimeItem);
