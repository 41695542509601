import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContentLoader from 'react-content-loader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { API, HelperFunctions } from 'sdk';
import { normalizeWorkOrder } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import {
  WhiteCard,
  EmptyDataSet,
  List,
  Menu,
  NewSearchField,
  Pagination,
  FilterButton,
} from 'views/components/Shared/General';
import { ChooseAssetInlineModal } from 'views/components/Asset';
import { ChooseAssigneeInlineModal } from 'views/components/WorkOrder';
import { Modal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import WorkOrderListItem from './WorkOrderListItem';
import WorkOrderTiny from 'assets/images/EmptyDataSet/WorkOrderTiny.png';
import SearchTiny from 'assets/images/EmptyDataSet/SearchTiny.png';

import styles from './style.module.scss';

export const ListType = {
  Upcoming: 'upcoming',
  Completed: 'completed',
  All: 'all',
};

class SelectWorkOrderModal extends Component {
  state = {
    loading: true,
    workOrderIds: null,
    pagination: null,
    searchTerm: '',
    page: 1,
    filteredAssetId: null,
    filteredUserId: null,
    filteredGroupId: null,
    filteredVendorId: null,
    list: ListType.Upcoming,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(
        {
          loading: true,
          loadingPagination: true,
          workOrderIds: null,
          pagination: null,
          searchTerm: '',
          page: 1,
          filteredAssetId: null,
          filteredUserId: null,
          filteredGroupId: null,
          filteredVendorId: null,
          list: ListType.Upcoming,
        },
        () => {
          this.fetchWorkOrders();
        }
      );
    }
  }

  fetchWorkOrders = (params = {}) => {
    this.setState({ loading: true });

    params = {
      page_size: 8,
      page: this.state.page,
      ...this.getParamsForList(),
      ...this.getFilterParams(),
      ...params,
    };
    this.listScrollRef.scrollTop = 0;

    return API.listWorkOrders(this.props.currentSystem.id, params).then(res => {
      const { headers, data } = res;
      const { entities, result } = normalizeWorkOrder(data);
      const pagination = HelperFunctions.getPaginationFromHeader(headers);

      this.props.updateEntities(entities);

      this.setState({
        loading: false,
        loadingPagination: false,
        pagination: pagination,
        workOrderIds: result,
      });
    });
  };

  getParamsForList = () => {
    switch (this.state.list) {
      case ListType.Upcoming: {
        return {
          list: 'upcoming',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case ListType.Completed: {
        return {
          list: 'completed',
          sort: 'completed_date',
          'sort-order': 'asc',
        };
      }
      case ListType.All: {
        return {
          sort: 'created_at',
          'sort-order': 'asc',
        };
      }

      default:
        return {};
    }
  };

  getFilterParams = () => {
    const { filteredUserId, filteredGroupId, filteredVendorId, filteredAssetId, searchTerm } = this.state;
    let params = {
      search: searchTerm,
      asset_with_tree_children_id: filteredAssetId,
    };
    if (filteredUserId) {
      params = {
        ...params,
        assignee: `u:${filteredUserId}`,
      };
    }
    if (filteredGroupId) {
      params = {
        ...params,
        assignee: `g:${filteredGroupId}`,
      };
    }
    if (filteredVendorId) {
      params = {
        ...params,
        assignee: `v:${filteredVendorId}`,
      };
    }
    return params;
  };

  isFiltering = () =>
    this.state.searchTerm !== '' ||
    this.state.filteredAssetId ||
    this.state.filteredUserId ||
    this.state.filteredGroupId ||
    this.state.filteredVendorId;

  changeList = list => {
    if (list !== this.state.list) {
      this.setState({ list, loadingPagination: true, page: 1 }, this.fetchWorkOrders);
    }
  };

  addAssigneeFilter = obj => {
    this.setState(
      {
        filteredUserId: null,
        filteredGroupId: null,
        filteredVendorId: null,
        ...obj,
      },
      this.fetchWorkOrders
    );
  };

  clearAssigneeFilter = obj => {
    this.setState(
      {
        filteredUserId: null,
        filteredGroupId: null,
        filteredVendorId: null,
      },
      this.fetchWorkOrders
    );
  };

  renderLeftPanel = () => (
    <div className={styles['left-panel']}>
      <PerfectScrollbar>
        <Menu.Item
          title={<FormattedMessage id="screens.work-orders.lists.upcoming-short" />}
          selected={this.state.list === 'upcoming'}
          onClick={() => {
            this.changeList('upcoming');
          }}
        />
        <Menu.Item
          title={<FormattedMessage id="screens.work-orders.lists.completed" />}
          selected={this.state.list === 'completed'}
          onClick={() => {
            this.changeList('completed');
          }}
        />
        <div className={styles['separator']} />
        <Menu.Item
          title={<FormattedMessage id="screens.work-orders.lists.all" />}
          selected={this.state.list === 'all'}
          onClick={() => {
            this.changeList('all');
          }}
        />
      </PerfectScrollbar>
    </div>
  );

  renderListItems = () => {
    if (this.state.loading) {
      if (!this.state.workOrderIds || this.state.workOrderIds.length === 0) {
        return (
          <>
            <WorkOrderListItem loading />
            <WorkOrderListItem loading />
          </>
        );
      } else {
        return this.state.workOrderIds.map(workOrderId => (
          <WorkOrderListItem list={this.state.list} loading />
        ));
      }
    }

    return this.state.workOrderIds.map(workOrderId => (
      <WorkOrderListItem
        list={this.state.list}
        onClick={() => {
          this.props.onSelect(workOrderId);
        }}
        id={workOrderId}
      />
    ));
  };

  renderListHeader = () => {
    let dateLabel;
    if (this.state.list === 'completed') {
      dateLabel = <FormattedMessage id="resources.work-order.completed-at" />;
    } else {
      dateLabel = <FormattedMessage id="resources.work-order.due-date" />;
    }
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order.title" />
        </List.Header.Column>
        <List.Header.Column width={140}>{dateLabel}</List.Header.Column>
        <List.Header.Column width={72} />
        <List.Header.Column width={45} />
      </List.Header>
    );
  };

  renderPagination = () => {
    if (!this.state.pagination || this.state.pagination.totalEntries <= 8) return null;
    if (this.state.loadingPagination) {
      return (
        <div className={styles['footer']}>
          <div style={{ width: 240, height: 16 }}>
            <ContentLoader viewBox="0 0 240 16" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="240" height="16" />
            </ContentLoader>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['footer']}>
        <Pagination
          blue
          hideOptions
          currentPage={this.state.page}
          totalPages={this.state.pagination.totalPages}
          onSelectPage={page => {
            this.setState({ page }, () => {
              this.fetchWorkOrders();
            });
          }}
        />
      </div>
    );
  };

  getSearchPlaceHolderForList = () => {
    switch (this.state.list) {
      case 'upcoming':
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.upcoming-short',
        });

      case 'completed':
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.completed',
        });

      case 'all':
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.all',
        });

      default:
        return null;
    }
  };

  renderAssetFilterTrigger = () => {
    return (
      <FilterButton
        label={<FormattedMessage id="resources.asset.resource" />}
        filtered={this.state.filteredAssetId != null}
        onClear={e => {
          e.stopPropagation();
          this.setState({ filteredAssetId: null }, this.fetchWorkOrders);
        }}
      />
    );
  };

  renderAssetFilter = () => {
    return (
      <ChooseAssetInlineModal
        hideCreateButton
        width="300"
        trigger={this.renderAssetFilterTrigger()}
        selectedAssetId={this.state.filteredAssetId == null ? null : this.state.filteredAssetId}
        onSelectAsset={assetId => {
          this.setState({ filteredAssetId: assetId }, this.fetchWorkOrders);
        }}
        onClear={() => {
          this.setState({ filteredAssetId: null }, this.fetchWorkOrders);
        }}
      />
    );
  };

  renderAssigneeFilterTrigger = () => {
    const { filteredUserId, filteredGroupId, filteredVendorId } = this.state;
    const noSelectedAssignees = filteredUserId == null && filteredGroupId == null && filteredVendorId == null;

    return (
      <FilterButton
        label={<FormattedMessage id="resources.work-order.assignee" />}
        filtered={noSelectedAssignees ? false : true}
        onClear={e => {
          e.stopPropagation();
          this.addAssigneeFilter({
            filteredUserId: null,
            filteredGroupId: null,
            filteredVendorId: null,
          });
        }}
      />
    );
  };

  renderAssigneeFilter = () => {
    return (
      <ChooseAssigneeInlineModal
        trigger={this.renderAssigneeFilterTrigger()}
        selectedUserId={this.state.filteredUserId}
        selectedGroupId={this.state.filteredGroupId}
        selectedVendorId={this.state.filteredVendorId}
        onSelectUser={({ id }) => {
          this.addAssigneeFilter({ filteredUserId: id });
        }}
        onSelectGroup={({ id }) => {
          this.addAssigneeFilter({ filteredGroupId: id });
        }}
        onSelectVendor={({ id }) => {
          this.addAssigneeFilter({ filteredVendorId: id });
        }}
      />
    );
  };

  renderToolbar = () => (
    <div className={styles['toolbar']}>
      <div className={styles['filter-container']}>
        <FilterButton.Group>
          {this.renderAssetFilter()}
          {this.renderAssigneeFilter()}
        </FilterButton.Group>
      </div>
      <div className={styles['search']}>
        <NewSearchField
          small
          value={this.state.searchTerm}
          debounce
          placeholder={this.getSearchPlaceHolderForList()}
          onSearch={value => {
            this.listScrollRef.scrollTop = 0;
            this.setState({ loading: true, loadingPagination: true, searchTerm: value });
          }}
          onDebouncedSearch={value => {
            this.fetchWorkOrders();
          }}
          onClear={() => {
            this.setState({ searchTerm: '' }, this.fetchWorkOrders);
          }}
        />
      </div>
    </div>
  );

  renderEmptyDataSetTitle = () => {
    switch (this.state.list) {
      case ListType.Upcoming: {
        return <FormattedMessage id="screens.work-orders.empty-data-sets.upcoming.all.title" />;
      }
      case ListType.Completed: {
        return <FormattedMessage id="screens.work-orders.empty-data-sets.completed.all.title" />;
      }
      default: {
        return <FormattedMessage id="screens.work-orders.empty-data-sets.all.all.title" />;
      }
    }
  };

  renderEmptyDataset = () => {
    if (this.isFiltering()) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            image={SearchTiny}
            tiny
            horizontal
            listContainer
          />
        </WhiteCard>
      );
    } else {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet title={this.renderEmptyDataSetTitle()} image={WorkOrderTiny} tiny listContainer />
        </WhiteCard>
      );
    }
  };

  renderListContent = () => {
    if (!this.state.loading && this.state.workOrderIds.length === 0) return this.renderEmptyDataset();

    return (
      <>
        {this.renderListHeader()}
        <List>{this.renderListItems()}</List>
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1150} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.select-work-order-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          <div className={styles['content']}>
            {this.renderLeftPanel()}
            <div className={styles['right-panel']}>
              {this.renderToolbar()}
              <div className={styles['list']}>
                <PerfectScrollbar containerRef={ref => (this.listScrollRef = ref)}>
                  <div className={styles['list-content']}>{this.renderListContent()}</div>
                </PerfectScrollbar>
              </div>
              {this.renderPagination()}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SelectWorkOrderModal));
