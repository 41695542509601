import HelperFunctions from 'utilities/HelperFunctions';

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getRequestBeforeEdit = state => {
  const { requests } = state;
  const { entities } = state;
  return entities.requestById[requests.selectedRequestId];
};

const getSelectedEditingRequest = state => {
  const { requests } = state;
  return requests.editingRequest == null ? null : requests.editingRequest;
};

const getInboxSections = state => {
  return state.requests.inboxSections;
};

const getRequestsForSection = (state, section) => {
  const { entities } = state;
  return state.requests.inboxSections[section].map(id => entities.requestById[id]);
};

const getRequests = state => {
  const { entities } = state;
  return state.requests.ids.map(id => entities.requestById[id]);
};

const getPagination = state => {
  const { requests } = state;
  return requests.pagination;
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const moreDataCanBeFetched = state => state.requests.moreDataCanBeFetched;
const isFetchingMoreRequests = state => state.requests.isFetchingMoreRequests;
const getPaginateFrom = state => state.requests.pagination.paginateFrom;

const isNewRequest = state => state.requests.isNewRequest;
const hasUnsavedChanges = state => state.requests.hasUnsavedChanges;
const isFetching = state => state.requests.isFetching;

/*
  Filter
*/

const getQueryParameters = (state, key) => {
  const { requests } = state;
  return requests.queryParams;
};

const getFilters = (state, key) => {
  const { requests } = state;
  return requests.filters;
};

const getFiltersAsQueryParams = (state, key) => {
  const { requests } = state;
  return HelperFunctions.buildFilter(requests.filters);
};

const getFiltersForKey = (state, key) => {
  const { requests } = state;
  return requests.filters[key];
};

const getAppliedFilters = (state, key) => {
  const { requests } = state;
  return requests.appliedFilters;
};

const isFiltering = (state, key) => {
  const { requests } = state;
  return requests.appliedFilters.length > 0;
};

const showingAppliedFilters = (state, key) => {
  const { requests } = state;
  return requests.showingAppliedFilters;
};

const getSelectedFilterType = (state, key) => {
  const { requests } = state;
  return requests.showApplyFilterForField;
};

/*
  MassEdit
*/

const getSelectedRequestIds = state => {
  return state.requests.selectedRequests.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.requests.selectedRequests.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.requests.selectedRequests.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.requests.selectedRequests.pageIsSelected;
};

const getSelectedRequestCount = state => {
  if (state.requests.selectedRequests.totalEntriesAreSelected) {
    return state.requests.pagination.totalEntries;
  }
  return Object.keys(state.requests.selectedRequests.ids).length;
};

export default {
  getFiltersAsQueryParams,
  getAppliedFilters,
  getSelectedRequestIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedRequestCount,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
  getQueryParameters,
  getFiltersForKey,
  getFilters,
  isFetching,
  getRequestBeforeEdit,
  getSelectedEditingRequest,
  getRequestsForSection,
  isNewRequest,
  getRequests,
  getInboxSections,
  getPagination,
  hasUnsavedChanges,
  moreDataCanBeFetched,
  isFetchingMoreRequests,
  getPaginateFrom,
};
