import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Tooltip, Icon } from 'views/components/Shared/General';
import { ChooseUserInlineModal, UserNameWrapper } from 'views/components/User';
import styles from './styles.module.scss';

class UserField extends Component {
  renderSelectedUser = () => {
    return (
      <div className={styles['user']}>
        <UserNameWrapper user={this.props.user} />
        <div className={styles['icons']}>
          <div className={styles['info-container']}>
            {this.props.user.phone ? (
              <Tooltip trigger={<Icon regular type="phone" />} label={this.props.user.phone} />
            ) : (
              <Icon regular type="phone" disabled />
            )}
          </div>
          <div className={styles['info-container']}>
            {this.props.user.email ? (
              <Tooltip trigger={<Icon regular type="envelope" />} label={this.props.user.email} />
            ) : (
              <Icon regular type="envelope" disabled />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.edit) {
      return (
        <ChooseUserInlineModal
          hideArchived
          trigger={
            <Field.Resource
              value={this.props.value ? <UserNameWrapper user={this.props.user} /> : null}
              onClear={() => this.props.onChange(null)}
            />
          }
          members
          selectedUserId={this.props.value}
          onSelectUser={id => {
            this.props.onChange(id);
          }}
          onClear={() => this.props.onChange(null)}
        />
      );
    }
    return this.props.user == null ? '-' : this.renderSelectedUser();
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(UserField);
