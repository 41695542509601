import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import uuid from 'uuid';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import HelperFunctions from 'utilities/HelperFunctions';
import { SDKReduxOperations, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { UploadProgressOperations, UploadProgressSelectors } from 'state/ducks/uploadProgress';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ContentContainer, Toolbar, ToastMessage } from 'views/components/Shared/Layout';
import {
  Button,
  List,
  EmptyDataSet,
  NewSearchField,
  WhiteCard,
  Pagination,
  InlineModal,
  Icon,
} from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { Loader } from 'views/components/Shared/General';
import {
  NewAttachmentModal,
  AttachmentListItem,
  AttachmentOptionsInlineModal,
  UploadingAttachmentContent,
  UploadAttachmentErrorModal,
} from 'views/components/Attachment';
import AttachmentTiny from 'assets/images/EmptyDataSet/AttachmentTiny.png';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import styles from './style.module.scss';

class Attachments extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      tableLoading: false,
      viewInitialized: false,
      showNewInlineModal: false,
      showNewAttachmentModalWithType: null,
      searchTerm: queryParams.search || '',
      queryParams: queryParams,
      showUploadAttachmentErrorWarning: false,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchAttachments()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchAttachments()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchAttachments = (params = {}) => {
    return this.props
      .fetchAttachments(this.props.vendor.id, this.state.queryParams)
      .then(({ data: attachments }) => {
        return attachments;
      });
  };

  createAttachmentForVendor = params => {
    if (params.type === 'file') {
      this.createFileAttachment(params);
    } else {
      this.createLinkAttachment(params);
    }
  };

  createFileAttachment = params => {
    const { description, title, extension, file, type, mime_type } = params;
    const temporaryId = uuid.v4();

    const attrs = {
      description,
      title,
      mime_type,
      type,
      extension,
    };

    this.props.beginUpload({ temporaryId, namespace: this.props.match.params.id });
    this.props.createAttachmentForVendor(this.props.match.params.id, attrs).then(({ data: attachment }) => {
      const s3params = { url: attachment.attachment_version.upload_url, file, mime_type };
      return SDKHelperFunctions.uploadFileToS3(s3params, ({ loaded, total }) => {
        this.props.updateUpload({
          id: attachment.id,
          loaded,
          total,
          temporaryId,
          namespace: this.props.match.params.id,
        });
      })
        .then(() => {
          return this.props
            .attachmentVersionUploaded(attachment.attachment_version.id, {
              vendorId: this.props.match.params.id,
            })
            .then(() => {
              this.props.completeUpload({
                id: attachment.id,
                temporaryId,
                namespace: this.props.match.params.id,
              });
            });
        })
        .catch(e => {
          this.props.cancelUpload({ temporaryId, namespace: this.props.match.params.id });
          this.setState({ showUploadAttachmentErrorWarning: true });
        });
    });
  };

  createLinkAttachment = params => {
    const { description, title, type, link_url } = params;
    const temporaryId = uuid.v4();

    const attrs = {
      description,
      title,
      type,
      link_url,
    };

    this.props.beginUpload({ temporaryId, namespace: this.props.match.params.id });
    this.props.createAttachmentForVendor(this.props.match.params.id, attrs).then(({ data: attachment }) => {
      this.props.completeUpload({ id: attachment.id, temporaryId, namespace: this.props.match.params.id });
    });
  };

  deleteAttachment = attachment => {
    this.setState({ isDeleting: true });
    this.props
      .deleteAttachment(attachment.id)
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.vendor.attachments.delete-success" />} />
        );
        this.setState({ isDeleting: false });
      })
      .catch(() => {
        this.setState({ isDeleting: false });
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  isFiltering = () => this.state.searchTerm !== '';

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.vendor.attachments.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.vendor.attachments.empty-data-set.subtitle" />}
        image={AttachmentTiny}
        button={
          this.props.canEditVendors
            ? this.renderNewInlineModal(
                <Button small primary label="screens.vendor.attachments.empty-data-set.button" />
              )
            : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => (
    <Toolbar
      buttons={
        this.props.canEditVendors
          ? this.renderNewInlineModal(<Button primary label="screens.vendor.attachments.create-button" />)
          : null
      }
      search={
        <NewSearchField
          againstGrayBackground
          small
          debounce
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.searchTerm}
          onSearch={searchTerm => this.setState({ searchTerm, tableLoading: true })}
          onClear={() => {
            this.setState({ tableLoading: true, searchTerm: '' });
            this.changeQueryParams({
              search: '',
              page: null,
            });
          }}
          onDebouncedSearch={value => {
            this.changeQueryParams({
              search: value,
              page: null,
            });
          }}
        />
      }
    />
  );

  renderNewInlineModal = trigger => (
    <>
      <div
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            showNewInlineModal: !prevState.showNewInlineModal,
          }));
        }}
      >
        {trigger}
      </div>
      <InlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.showNewInlineModal}
        onClose={() => this.setState({ showNewInlineModal: false })}
      >
        <InlineModal.Body width={250} dropdown>
          <InlineModal.ListItem
            icon="upload"
            iconThickness="regular"
            title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
            onClick={() => {
              this.setState({ showNewInlineModal: false, showNewAttachmentModalWithType: 'file' });
            }}
          />
          <InlineModal.ListItem
            icon="link"
            iconThickness="regular"
            title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
            onClick={() => {
              this.setState({ showNewInlineModal: false, showNewAttachmentModalWithType: 'link' });
            }}
          />
        </InlineModal.Body>
      </InlineModal>
    </>
  );

  renderNewAttachmentModal = () => {
    if (!this.state.showNewAttachmentModalWithType) return null;

    return (
      <NewAttachmentModal
        isOpen={this.state.showNewAttachmentModalWithType != null}
        type={this.state.showNewAttachmentModalWithType}
        onClose={() => this.setState({ showNewAttachmentModalWithType: null })}
        onCreateNew={params => this.createAttachmentForVendor(params)}
      />
    );
  };

  renderEmptySearch = () => {
    if (this.props.attachments.length > 0 || !this.isFiltering() || this.state.tableLoading) return null;

    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          image={SearchImage}
          tiny
          horizontal
          listContainer
        />
      </WhiteCard>
    );
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.attachments.length === 0 ? 1 : this.props.attachments.length)
              .fill()
              .map(() => (
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.attachments.length === 0 && this.props.uploadingAttachments.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.uploadingAttachments.map(uploadingAttachment => (
            <List.Item>
              <UploadingAttachmentContent
                namespace={this.props.match.params.id}
                uploadingAttachment={uploadingAttachment}
              />
            </List.Item>
          ))}
          {this.props.attachments.map(attachment => (
            <AttachmentListItem
              key={attachment.id}
              attachment={attachment}
              buttons={
                this.props.canEditVendors ? (
                  <AttachmentOptionsInlineModal
                    attachment={attachment}
                    type="vendor"
                    isDeletingAttachment={this.state.isDeleting}
                    onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
                    trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                  />
                ) : null
              }
              onClick={(attachment, attachmentVersion) => {
                HelperFunctions.openAttachment(attachmentVersion);
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.vendor.attachments.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderAttachments = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderEmptySearch()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (
      this.props.uploadingAttachments.length === 0 &&
      this.props.attachments.length === 0 &&
      !this.isFiltering() &&
      !this.state.tableLoading
    ) {
      return this.renderEmptyDataset();
    } else {
      return this.renderAttachments();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          {!this.state.viewInitialized ? (
            <ContentContainer key={this.props.match.params.id}>
              <Loader />
            </ContentContainer>
          ) : (
            <DropZone
              active={this.props.canEditVendors}
              onDrop={data => this.createAttachmentForVendor(data)}
            >
              <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
            </DropZone>
          )}
        </PerfectScrollbar>
        {this.renderNewAttachmentModal()}
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createAttachmentForVendor: SDKReduxOperations.createAttachmentForVendor,
      fetchAttachments: VendorOperations.fetchAttachments,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: VendorSelectors.getVendor(state),
    attachments: VendorSelectors.getAttachments(state),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(state, ownProps.match.params.id),
    createdIds: VendorSelectors.getAttachmentsCreatedIds(state),
    pagination: VendorSelectors.getAttachmentsPagination(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Attachments)));
