import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listAssetProductionSupervisors = (systemId, params) =>
  request.get(
    `systems/${systemId}/asset_production_supervisors?${HelperFunctions.convertObjToQueryParameters(params)}`
  );
export const createAssetProductionSupervisor = (systemId, data) =>
  request.post(`systems/${systemId}/asset_production_supervisors`, data);
export const deleteAssetProductionSupervisor = productionSupervisorId =>
  request.delete(`asset_production_supervisors/${productionSupervisorId}`);
