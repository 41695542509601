import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { Tooltip } from 'views/components/Shared/General';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SelectedSparePartVendorItem extends Component {
  renderTitle = () => {
    const { sparePartVendor, sparePart } = this.props;
    const title = sparePartVendor.title || sparePart.title;
    if (sparePartVendor.number) {
      return (
        <>
          <span># {sparePartVendor.number}</span>
          <span> - {title}</span>
        </>
      );
    }
    return title;
  };

  renderPackage = () => {
    if (this.props.sparePartVendor.package_quantity) {
      return (
        <div className={styles['package-container']}>
          <Tooltip
            label={
              <>
                <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                <span>: </span>
                <SparePartUnitWithValue
                  sparePartUnit={this.props.sparePartUnit}
                  value={this.props.sparePartVendor.package_quantity}
                />
              </>
            }
            trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['title-container']}>
          <Tooltip
            label={this.renderTitle()}
            trigger={<div className={styles['title']}>{this.renderTitle()}</div>}
          />
        </div>
        {this.renderPackage()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartVendor = EntitySelectors.getSparePartVendor(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartVendor.spare_part_id);
  return {
    sparePartVendor,
    sparePart,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}
export default connect(mapStateToProps)(SelectedSparePartVendorItem);
