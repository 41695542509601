import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InlineModal, Button } from 'views/components/Shared/General';
import { EstimatedTimeField } from 'views/components/WorkOrder';
import styles from './style.module.scss';

export default class EstimatedTimeInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  open = () => {
    this.props.onOpen();
    this.setState({ open: true });
  };

  close = () => {
    this.props.onClose();
    this.setState({ open: false });
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            if (this.state.open) {
              this.close();
            } else {
              this.open();
            }
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          width={335}
          open={this.state.open}
          positionToRef={this.inlineModalPositioningRef}
          position={this.props.position}
          onClose={this.close}
        >
          <InlineModal.Body width={335} paddingTop>
            <EstimatedTimeField
              estimatedMinutes={this.props.estimatedMinutes}
              onChange={estimated_minutes => this.props.onChange(estimated_minutes)}
            />
            {this.props.showSaveButton ? (
              <div className={styles['button-container']}>
                <Button primary label="general.save" small onClick={this.close} />
              </div>
            ) : null}
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  }
}

EstimatedTimeInlineModal.propTypes = {
  estimatedMinutes: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  showSaveButton: PropTypes.bool,
  position: PropTypes.string,
};

EstimatedTimeInlineModal.defaultProps = {
  showSaveButton: false,
  estimatedMinutes: null,
  onOpen: () => {},
  onClose: () => {},
  position: 'left',
};
