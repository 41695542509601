import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { UnarchiveAssetModal } from 'views/components/Asset';
import { Tooltip, NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './styles.module.scss';

class Archived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownInlineModal: false,
      showUnarchivedAssetModal: false,
    };
  }

  renderDropdownMenu = () => {
    return (
      <>
        <div ref={ref => (this.inlineModalDropdownPositioningRef = ref)}>
          <Button
            type="icon"
            iconButtonSize={28}
            icon={<Icon regular size={14} type="ellipsis-h" />}
            onClick={() => {
              this.setState(prevState => ({
                showDropdownInlineModal: !prevState.showDropdownInlineModal,
              }));
            }}
          />
        </div>
        <NewInlineModal
          minWidth={230}
          position="right"
          positionToRef={this.inlineModalDropdownPositioningRef}
          open={this.state.showDropdownInlineModal}
          onClose={() => {
            this.setState({ showDropdownInlineModal: false });
          }}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() =>
                  this.setState({ showDropdownInlineModal: false, showUnarchivedAssetModal: true })
                }
              >
                <FormattedMessage id="screens.asset.unarchive" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderDropdownButton = () => {
    if (this.props.canEditAssets) {
      return <div className={styles['dropdown-button']}>{this.renderDropdownMenu()}</div>;
    }
    return null;
  };

  render() {
    if (this.props.asset.archived === false) {
      return null;
    }
    return (
      <>
        <div className={styles['content']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.asset.archived" />
          </div>
          <div className={styles['dropdown-container']}>
            <div>
              <Tooltip
                label={
                  <FormattedMessage
                    id="screens.asset.archived-by-at-date"
                    values={{
                      user: <UserNameWrapper user={this.props.archivedByUser} />,
                      date: moment(this.props.asset.archived_at).format('LL'),
                    }}
                  />
                }
                trigger={<Icon size={14} regular type="info-circle" />}
              />
            </div>
            {this.renderDropdownButton()}
          </div>
        </div>
        <UnarchiveAssetModal
          open={this.state.showUnarchivedAssetModal}
          asset={this.props.asset}
          onClose={() => this.setState({ showUnarchivedAssetModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const asset = EntitySelectors.getAsset(state, ownProps.match.params.id);

  return {
    asset,
    archivedByUser: EntitySelectors.getUser(state, asset.archived_by_user_id),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(connect(mapStateToProps)(Archived));
