import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { Modal } from 'views/components/Shared/Layout';
import { Field, Button } from 'views/components/Shared/General';

class EditNameModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    name: '',
    showNameRequiredError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        name: this.props.vendor.name,
      });
    }
  }

  update = () => {
    if (this.state.name.length === 0) {
      this.setState({ showNameRequiredError: true });
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateVendor(this.props.vendor.id, {
        name: this.state.name,
      })
      .then(({ data: meter }) => {
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    return (
      <>
        <Modal width={460} isOpen={this.props.open}>
          <Modal.Header
            title={<FormattedMessage id="screens.vendor.edit-vendor" />}
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Field label={<FormattedMessage id="resources.vendor.name" />}>
              <Field.Text
                autoFocus
                error={this.state.showNameRequiredError}
                value={this.state.name}
                onChange={name => {
                  if (name) {
                    this.setState({ showNameRequiredError: false });
                  }
                  this.setState({ name });
                }}
              />
            </Field>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button primary loading={this.state.isSaving} label="general.save" onClick={this.update} />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(EditNameModal);
