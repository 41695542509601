import React from 'react';
import { Icon } from 'views/components/Shared/General';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import ContentLoader from 'react-content-loader';
import styles from './style.module.scss';

export default props => {
  const renderTitleContent = () => {
    if (props.contentLoader) {
      return (
        <div className={styles['loader-container']}>
          <ContentLoader primaryColor="#F5F5F5" secondaryColor="#EFEFEF" width={123} height={50}>
            <rect x="0" y="6" rx="3" ry="3" width="110" height="6" />
            <rect x="0" y="22" rx="3" ry="3" width="70" height="6" />
          </ContentLoader>
        </div>
      );
    } else {
      return (
        <div className={styles['title-container']}>
          <p className={styles['folder-title']}>{props.attachmentFolder.title}</p>
          <p className={styles['subtitle']}>
            <FormattedPlural
              value={props.attachmentCount}
              one={
                <FormattedMessage
                  id="components.attachment-folder.count-one"
                  values={{ num: props.attachmentCount }}
                />
              }
              other={
                <FormattedMessage
                  id="components.attachment-folder.count-other"
                  values={{ num: props.attachmentCount }}
                />
              }
            />
          </p>
        </div>
      );
    }
  };

  return (
    <div className={styles['folder']} onClick={props.onClick}>
      <div className={styles['content-container']}>
        <Icon type="folder" />
        {renderTitleContent()}
      </div>
      {props.button == null ? null : props.button}
    </div>
  );
};
