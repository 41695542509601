import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartSelectors, SparePartOperations } from 'state/ducks/sparePart';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import { NewVendorModal } from 'views/components/Vendor';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, WhiteCard, Pagination } from 'views/components/Shared/General';
import { SelectVendorModal } from 'views/components/Vendor';
import { Loader } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { MultipleSparePartVendorsModal, DeleteSparePartVendorModal } from 'views/components/SparePartVendor';
import VendorTiny from 'assets/images/EmptyDataSet/VendorTiny.png';
import VendorListItem from './VendorListItem';
import SparePartVendorModal from './SparePartVendorModal';
import styles from './style.module.scss';
import MultipleSparePartVendorsDeleteModal from 'views/components/SparePartVendor/MultipleSparePartVendorsDeleteModal';

class Vendors extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      isFetching: false,
      viewInitialized: false,
      showSelectVendorModal: false,
      showCreateVendorModal: false,
      showSparePartVendorModal: false,
      showSparePartVendorModalForSparePartVendorId: null,
      createSparePartVendorForVendorId: null,
      showMultipleSparePartVendorsModal: false,
      showMultipleSparePartVendorsModalForVendorId: null,
      showDeleteMultipleSparePartVendorsModal: false,
      showDeleteSparePartVendorModal: false,
      showDeleteSparePartVendorModalForId: null,
      showDeleteMultipleSparePartVendorsModalForVendorId: null,
      openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
      openedSparePartVendorModalFromSelectVendorModal: false,
      closedSparePartVendorModalFromDelete: false,
      isAddingAlternativeArticle: false,
      queryParams: queryParams,
    };
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    this.props.clearPrimaryVendor();
    Promise.all([this.fetchVendors(), this.fetchPrimaryVendor()])
      .then(() => {
        this.setState({ viewInitialized: true, isFetching: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, isFetching: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ isFetching: true });
        this.fetchVendors()
          .then(() => {
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          });
      });
    }
  }

  fetchPrimaryVendor = () => {
    return this.props.fetchPrimaryVendor(this.props.currentSystem.id, this.props.match.params.id);
  };

  fetchVendors = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    return this.props.fetchVendors(this.props.currentSystem.id, this.props.match.params.id, {
      sort: 'vendor_name',
      'sort-order': 'asc',
      ...params,
      ...filterParams,
    });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  renderAddVendorButton = vendor => {
    let sparePartVendors = [];
    if (vendor.spare_part_vendors_for_spare_part) {
      sparePartVendors = vendor.spare_part_vendors_for_spare_part;
    }
    return (
      <>
        {sparePartVendors.length === 0 ? null : (
          <span className={styles['added-amount-of-times-label']}>
            <FormattedPlural
              value={sparePartVendors.length}
              one={
                <FormattedMessage
                  id="screens.spare-part.vendors.added-amount-of-times.one"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              two={
                <FormattedMessage
                  id="screens.spare-part.vendors.added-amount-of-times.two"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              few={
                <FormattedMessage
                  id="screens.spare-part.vendors.added-amount-of-times.few"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              many={
                <FormattedMessage
                  id="screens.spare-part.vendors.added-amount-of-times.many"
                  values={{ amount: sparePartVendors.length }}
                />
              }
              other={
                <FormattedMessage
                  id="screens.spare-part.vendors.added-amount-of-times.other"
                  values={{ amount: sparePartVendors.length }}
                />
              }
            />
          </span>
        )}
        <Button
          gray
          small
          label="general.add"
          onClick={() => {
            this.setState({ showSelectVendorModal: false });
            setTimeout(() => {
              this.setState({
                showSparePartVendorModal: true,
                isAddingAlternativeArticle: false,
                createSparePartVendorForVendorId: vendor.id,
                showSparePartVendorModalForSparePartVendorId: null,
                openedSparePartVendorModalFromSelectVendorModal: true,
              });
            }, 250);
          }}
        />
      </>
    );
  };

  renderSelectVendorModal = () => (
    <SelectVendorModal
      open={this.state.showSelectVendorModal}
      listItemRightComponent={vendor => {
        return this.renderAddVendorButton(vendor);
      }}
      additionalApiParams={{
        create_purchase_orders: true,
        spare_part_vendors_for_spare_part: this.props.match.params.id,
      }}
      onClose={() =>
        this.setState({
          showSelectVendorModal: false,
          openedSparePartVendorModalFromSelectVendorModal: false,
        })
      }
      subtitle={
        <FormattedMessage
          id="screens.spare-part.vendors.vendor-spare-part-modal.spare-part-name"
          values={{
            sparePart: (
              <span className={styles['highlight-text']}>
                <Title inline sparePart={this.props.sparePart} />
              </span>
            ),
          }}
        />
      }
      onCreateVendor={() => {
        this.setState({ showSelectVendorModal: false });
        setTimeout(() => {
          this.setState({ showCreateVendorModal: true });
        }, 100);
      }}
    />
  );

  renderCreateVendorModal = () => {
    return (
      <NewVendorModal
        createForSparePartId={this.props.match.params.id}
        open={this.state.showCreateVendorModal}
        onClose={() => {
          this.setState({ showCreateVendorModal: false });
          setTimeout(() => {
            this.setState({ showSelectVendorModal: true });
          }, 100);
        }}
        onCreated={vendor => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({ showSelectVendorModal: true });
            }, 350);
          });
        }}
        onCreatedWithReopen={() => {
          this.setState({ showCreateVendorModal: false }, () => {
            setTimeout(() => {
              this.setState({ showCreateVendorModal: true });
            }, 400);
          });
        }}
      />
    );
  };

  renderSparePartVendorModal = () => (
    <SparePartVendorModal
      open={this.state.showSparePartVendorModal}
      createForSparePartId={this.props.match.params.id}
      createForVendorId={this.state.createSparePartVendorForVendorId}
      isAddingArticleForExistingVendor={this.state.isAddingAlternativeArticle}
      openedFromMultipleSparePartVendorsModal={
        this.state.openedSparePartVendorModalFromMultipleSparePartVendorsModal
      }
      id={this.state.showSparePartVendorModalForSparePartVendorId}
      onAddArticle={vendorId => {
        this.setState({ showSparePartVendorModal: false });
        setTimeout(() => {
          this.setState({
            showSparePartVendorModal: true,
            isAddingAlternativeArticle: true,
            openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
            createSparePartVendorForVendorId: vendorId,
            showSparePartVendorModalForSparePartVendorId: null,
          });
        }, 250);
      }}
      onDelete={sparePartVendorId => {
        this.setState({ closedSparePartVendorModalFromDelete: true });
        setTimeout(
          () =>
            this.setState({
              showDeleteSparePartVendorModalForId: sparePartVendorId,
              showDeleteSparePartVendorModal: true,
            }),
          250
        );
      }}
      onClose={() => {
        if (this.state.closedSparePartVendorModalFromDelete) {
          this.setState({ showSparePartVendorModal: false });
          return;
        }
        if (this.state.openedSparePartVendorModalFromMultipleSparePartVendorsModal) {
          this.setState({
            showSparePartVendorModal: false,
          });
          setTimeout(() => this.setState({ showMultipleSparePartVendorsModal: true }), 250);
          return;
        } else if (this.state.openedSparePartVendorModalFromSelectVendorModal) {
          this.setState({
            showSparePartVendorModal: false,
          });
          setTimeout(() => {
            this.setState({
              showSelectVendorModal: true,
              showSparePartVendorModalForSparePartVendorId: null,
            });
          }, 250);
        } else {
          this.setState({
            showSparePartVendorModal: false,
          });
        }
      }}
    />
  );

  renderMultipleSparePartVendorsModal = () => {
    return (
      <MultipleSparePartVendorsModal
        sparePartId={this.props.match.params.id}
        vendorId={this.state.showMultipleSparePartVendorsModalForVendorId}
        open={this.state.showMultipleSparePartVendorsModal}
        onAdd={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(() => {
            this.setState({
              openedSparePartVendorModalFromMultipleSparePartVendorsModal: true,
              isAddingAlternativeArticle: true,
              showSparePartVendorModal: true,
              createSparePartVendorForVendorId: this.state.showMultipleSparePartVendorsModalForVendorId,
              showSparePartVendorModalForSparePartVendorId: null,
            });
          }, 250);
        }}
        onDelete={sparePartVendor =>
          setTimeout(
            () =>
              this.setState({
                showDeleteSparePartVendorModal: true,
                showDeleteSparePartVendorModalForId: sparePartVendor.id,
              }),
            250
          )
        }
        onDeleteAll={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(
            () =>
              this.setState({
                showDeleteMultipleSparePartVendorsModal: true,
                showDeleteMultipleSparePartVendorsModalForVendorId:
                  this.state.showDeleteMultipleSparePartVendorsModalForVendorId,
              }),
            250
          );
        }}
        onSelectSparePartVendor={({ id }) => {
          this.setState({ showMultipleSparePartVendorsModal: false });
          setTimeout(
            () =>
              this.setState({
                openedSparePartVendorModalFromMultipleSparePartVendorsModal: true,
                showSparePartVendorModal: true,
                isAddingAlternativeArticle: false,
                showSparePartVendorModalForSparePartVendorId: id,
              }),
            250
          );
        }}
        onClose={() => {
          this.setState({ showMultipleSparePartVendorsModal: false });
        }}
      />
    );
  };

  renderMultipleSparePartVendorsDeleteModal = () => {
    return (
      <MultipleSparePartVendorsDeleteModal
        vendorId={this.state.showDeleteMultipleSparePartVendorsModalForVendorId}
        sparePartId={this.props.match.params.id}
        title={
          <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.confirm-delete.title-multiple" />
        }
        subtitle={
          <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.confirm-delete.subtitle" />
        }
        open={this.state.showDeleteMultipleSparePartVendorsModal}
        onClose={() => {
          this.setState({ showDeleteMultipleSparePartVendorsModal: false });
        }}
      />
    );
  };

  renderDeleteSparePartVendorModal = () => {
    return (
      <DeleteSparePartVendorModal
        id={this.state.showDeleteSparePartVendorModalForId}
        open={this.state.showDeleteSparePartVendorModal}
        title={
          <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.confirm-delete.title" />
        }
        subtitle={
          <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.confirm-delete.subtitle" />
        }
        onClose={() => this.setState({ showDeleteSparePartVendorModal: false })}
      />
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.spare-part.vendors.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.spare-part.vendors.empty-data-set.subtitle" />}
        image={VendorTiny}
        button={
          this.props.canEditSpareParts && this.props.canEditVendors ? (
            <Button
              small
              primary
              onClick={() => this.setState({ showSelectVendorModal: true })}
              label="screens.spare-part.vendors.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditSpareParts && this.props.canEditVendors) {
      return (
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.spare-part.vendors.create-button"
              onClick={() =>
                this.setState({
                  openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                  showSelectVendorModal: true,
                })
              }
            />
          }
        />
      );
    }
    return null;
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.spare-part.vendors.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderHeader = () => {
    const { primaryVendorId } = this.props;
    let label = 'resources.spare-part-vendor.vendor';
    if (primaryVendorId) {
      label = 'screens.spare-part.vendors.other-header';
    }
    return (
      <List.Header background paddingRight={74}>
        <List.Header.Column flex>
          <FormattedMessage id={label} />
        </List.Header.Column>
        <List.Header.Column width={150} alignRight>
          <FormattedMessage id="resources.spare-part-vendor.price" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderHeader()}
          <List>
            {Array(this.props.vendorIds.length === 0 ? 1 : this.props.vendorIds.length)
              .fill()
              .map(() => (
                <VendorListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.props.vendorIds.length === 0) return null;

    return (
      <>
        {this.renderHeader()}
        <List>
          {this.props.vendorIds.map(id => (
            <VendorListItem
              id={id}
              onClick={sparePartVendors => {
                if (sparePartVendors.length === 1) {
                  this.setState({
                    openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                    showSparePartVendorModal: true,
                    isAddingAlternativeArticle: false,
                    showSparePartVendorModalForSparePartVendorId: sparePartVendors[0].id,
                  });
                } else {
                  this.setState({
                    showMultipleSparePartVendorsModal: true,
                    showMultipleSparePartVendorsModalForVendorId: sparePartVendors[0].vendor_id,
                  });
                }
              }}
              onDelete={sparePartVendors => {
                if (sparePartVendors.length === 1) {
                  this.setState({
                    showDeleteSparePartVendorModalForId: sparePartVendors[0].id,
                    showDeleteSparePartVendorModal: true,
                  });
                } else {
                  this.setState({
                    showDeleteMultipleSparePartVendorsModal: true,
                    showDeleteMultipleSparePartVendorsModalForVendorId: sparePartVendors[0].vendor_id,
                  });
                }
              }}
              onAddArticle={() => {
                this.setState({
                  showSparePartVendorModal: true,
                  openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                  createSparePartVendorForVendorId: id,
                  showSparePartVendorModalForSparePartVendorId: null,
                  isAddingAlternativeArticle: true,
                });
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderPrimaryVendor = () => {
    if (this.props.primaryVendorId) {
      /*
        This <VendorListItem /> can be rendered although the
        spare part vendor is already deleted but this case is handled in <VendorListItem />
      */
      const { vendorIds } = this.props;
      return (
        <>
          <List.Header background paddingRight={74}>
            <List.Header.Column flex>
              <FormattedMessage id="screens.spare-part.vendors.primary-header" />
            </List.Header.Column>
            <List.Header.Column width={150} alignRight>
              <FormattedMessage id="resources.spare-part-vendor.price" />
            </List.Header.Column>
          </List.Header>
          <List>
            <VendorListItem
              id={this.props.primaryVendorId}
              onClick={sparePartVendors => {
                if (sparePartVendors.length === 1) {
                  this.setState({
                    openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                    showSparePartVendorModal: true,
                    isAddingAlternativeArticle: false,
                    showSparePartVendorModalForSparePartVendorId: sparePartVendors[0].id,
                  });
                } else {
                  this.setState({
                    showMultipleSparePartVendorsModal: true,
                    showMultipleSparePartVendorsModalForVendorId: sparePartVendors[0].vendor_id,
                  });
                }
              }}
              onAddArticle={() => {
                this.setState({
                  showSparePartVendorModal: true,
                  openedSparePartVendorModalFromMultipleSparePartVendorsModal: false,
                  createSparePartVendorForVendorId: this.props.primaryVendorId,
                  showSparePartVendorModalForSparePartVendorId: null,
                  isAddingAlternativeArticle: true,
                });
              }}
            />
          </List>
          {vendorIds.length === 0 ? null : <div className={styles['separator']} />}
        </>
      );
    }
    return null;
  };

  renderVendors = () => {
    return (
      <>
        {this.renderToolbar()}
        {this.renderPrimaryVendor()}
        {this.renderList()}
      </>
    );
  };

  renderView = () => {
    const { vendorIds, primaryVendorId } = this.props;
    const { isFetching } = this.state;
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (primaryVendorId == null && vendorIds.length === 0 && !isFetching) {
      return this.renderEmptyDataset();
    } else {
      return this.renderVendors();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectVendorModal()}
        {this.renderCreateVendorModal()}
        {this.renderSparePartVendorModal()}
        {this.renderDeleteSparePartVendorModal()}
        {this.renderMultipleSparePartVendorsModal()}
        {this.renderMultipleSparePartVendorsDeleteModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchVendors: SparePartOperations.fetchVendors,
      fetchPrimaryVendor: SparePartOperations.fetchPrimaryVendor,
      clearPrimaryVendor: SparePartOperations.clearPrimaryVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    vendorIds: SparePartSelectors.getVendorIds(state),
    primaryVendorId: SparePartSelectors.getPrimaryVendorId(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.match.params.id),
    pagination: SparePartSelectors.getVendorsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendors)));
