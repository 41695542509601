import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';

class EditTitleModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  getInititalState = () => ({
    title: '',
    isSaving: false,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInititalState(), title: this.props.qrTemplate.title });
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={<FormattedMessage id="components.print-qr-codes.edit-title-modal.title-edit" />}
          onClose={() => {
            this.props.onClose();
          }}
        />
        <Modal.Content>
          <div style={{ marginBottom: '20px' }}>
            <Field label="Name">
              <Field.Text autoFocus value={this.state.title} onChange={title => this.setState({ title })} />
            </Field>
          </div>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              label="general.save"
              onClick={() => {
                this.setState({ isSaving: true });
                this.props
                  .updateQrTemplate(this.props.qrTemplateId, {
                    title: this.state.title,
                  })
                  .then(() => {
                    this.props.onClose();
                  });
              }}
            />
            <Button
              label="general.cancel"
              onClick={() => {
                this.props.onClose();
              }}
            />
          </Button.Group>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQrTemplate: SDKReduxOperations.updateQrTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateId } = ownProps;

  return {
    qrTemplate: EntitySelectors.getQrTemplate(state, qrTemplateId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTitleModal);
