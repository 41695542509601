import React, { Component } from 'react';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { List, ViewTextArea, Datapill, Icon } from 'views/components/Shared/General';
import { StatusIcon } from 'views/components/OperationalMaintenance';
import { AuthSelectors } from 'state/ducks/auth';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import styles from './style.module.scss';

class OperationalMaintenanceListItem extends Component {
  renderSubtitle = () => {
    if (this.props.asset) {
      return <TreePath assetId={this.props.asset.id} fullPath fromAssetId={this.props.fromAssetId} />;
    }
    return null;
  };

  renderDate = () => {
    const momentDate = moment(this.props.operationalMaintenance.date);
    switch (this.props.dateFormat) {
      case 'day':
        if (moment().tz(this.props.system.timezone).isSame(momentDate, 'day')) {
          return <FormattedMessage id="calendar.today" />;
        }
        return `${momentDate.format('dddd')}, ${momentDate.format('LL')}`;

      case 'week':
        return momentDate.format('dddd');

      case 'month':
        return `${momentDate.format('dddd')}, ${momentDate.format('LL')}`;

      default:
        return `${momentDate.format('dddd')}, ${momentDate.format('LL')}`;
    }
  };

  renderCompletedIcon = () => {
    const { operationalMaintenanceInstance } = this.props;
    if (
      operationalMaintenanceInstance &&
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed
    ) {
      return (
        <div className={styles['completed-icon']}>
          <Icon regular type="check" size={10} green />
        </div>
      );
    }
    return null;
  };

  renderListItemColumns = () => {
    return (
      <>
        <List.Item.TitleColumn
          title={this.props.operationalMaintenanceTemplateVersion.title}
          subtitle={this.renderSubtitle()}
        />
        <List.Item.Column width={180}>{this.renderDate()}</List.Item.Column>
        <List.Item.Column alignRight width={18}>
          <StatusIcon id={this.props.id} />
        </List.Item.Column>
      </>
    );
  };

  renderExpandableComponent = () => {
    return (
      <div className={styles['expandable-container']}>
        <ViewTextArea>{this.props.operationalMaintenanceTemplateVersion.description || '-'}</ViewTextArea>
      </div>
    );
  };

  showAssetNumberDataPill = () => (this.props.settings.asset_number_activated && this.props.asset)

  renderAssetNumberDataPill = () => {
    if(!this.showAssetNumberDataPill()) return null;
    
    return (
      <Datapill
        label={<FormattedMessage id="resources.asset.number-long" />}
        value={`#${this.props.asset.number}`}
      />
    );
  };

  showChecklistFilledRowsCountDatapill = () => (this.props.checklistTemplateVersion)

  renderChecklistFilledRowsCountDatapill = () => {
    if(!this.showChecklistFilledRowsCountDatapill()) return null;

    let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;
    let filledRowsCount = 0;
    if (this.props.checklistInstance) {
      filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
    }

    return (
      <Datapill
        gray={filledRowsCount === 0}
        icon="list"
        value={
          <span>
            <span>{filledRowsCount}</span>
            <span>/</span>
            <span>{amountOfRows}</span>
          </span>
        }
      />
    );
  };

  showTimeOfDayDatapill = () => {
    const { operationalMaintenanceInstance, operationalMaintenanceTemplateRecurrenceVersion } = this.props;
    
    if (operationalMaintenanceInstance && operationalMaintenanceInstance.due_time_of_day) {
      return true;
    }
    
    if (operationalMaintenanceTemplateRecurrenceVersion && operationalMaintenanceTemplateRecurrenceVersion.time_of_day) {
      return true;
    }
    
    return false
  }

  renderTimeOfDayDatapill = () => {
    if(!this.showTimeOfDayDatapill()) return null;

    const { operationalMaintenanceInstance, operationalMaintenanceTemplateRecurrenceVersion } = this.props;
    if (operationalMaintenanceInstance && operationalMaintenanceInstance.due_time_of_day) {
      return (
        <Datapill value={moment(operationalMaintenanceInstance.due_time_of_day, 'hh:mm').format('LT')} />
      );
    }

    if (operationalMaintenanceTemplateRecurrenceVersion.time_of_day) {
      return (
        <Datapill
          value={moment(operationalMaintenanceTemplateRecurrenceVersion.time_of_day, 'hh:mm').format('LT')}
        />
      );
    }

    return null;
  };

  renderDataPills = () => {
    if(
      !this.showAssetNumberDataPill() &&
      !this.showChecklistFilledRowsCountDatapill() &&
      !this.showTimeOfDayDatapill()
    ) return null;
    
    return (
      <Datapill.List>
        {this.renderAssetNumberDataPill()}
        {this.renderChecklistFilledRowsCountDatapill()}
        {this.renderTimeOfDayDatapill()}
      </Datapill.List>
    );
  };

  renderLoadingDataPills = () => {
    if (this.props.checklistTemplateVersion) {
      return (
        <Datapill.List>
          <Datapill icon="list" loading />
        </Datapill.List>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item
          small
          checkbox={this.props.checkbox}
          expandable
          dataPillsComponent={this.renderLoadingDataPills()}
        >
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      let id = this.props.operationalMaintenance.id;
      if (this.props.operationalMaintenanceInstance) {
        id = this.props.operationalMaintenanceInstance.id;
      }
      return (
        <List.Item
          small
          clickable
          linkTo={`/operational-maintenances/${id}`}
          expandable
          checkbox={this.props.checkbox}
          checkboxDisabled={this.props.checkboxDisabled}
          checked={this.props.checked}
          expandedComponent={this.renderExpandableComponent()}
          dataPillsComponent={this.renderDataPills()}
          onClick={this.props.onClick}
          onCheck={this.props.onCheck}
        >
          {this.renderListItemColumns()}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.id == null) {
    return {};
  }

  const operationalMaintenance = EntitySelectors.getOperationalMaintenance(state, ownProps.id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    operationalMaintenance.operational_maintenance_instance_id
  );
  let operationalMaintenanceTemplateVersion = null;
  let operationalMaintenanceTemplateRecurrenceVersion = null;
  let asset = null;
  let checklistInstance = null;
  let checklistTemplateLink = null;
  let checklistTemplateVersion = null;
  let checklistTemplate = null;

  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );

    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenanceInstance.operational_maintenance_template_recurrence_version_id
      );

    asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);

    const checklistInstanceIds = operationalMaintenanceInstance.checklist_instances || [];
    if (checklistInstanceIds.length === 1) {
      checklistInstance = EntitySelectors.getChecklistInstance(state, checklistInstanceIds[0]);

      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistInstance.checklist_template_id
      );

      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version
      );
    }
  } else {
    const operationalMaintenanceTemplateRecurrence =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrence(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_id
      );

    const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
      state,
      operationalMaintenanceTemplateRecurrence.operational_maintenance_template_asset_id
    );

    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenance.operational_maintenance_template_version_id
    );

    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_version_id
      );

    asset = EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id);

    const checklistTemplateLinks =
      EntitySelectors.getChecklistTemplateLinks(
        state,
        operationalMaintenanceTemplateVersion.checklist_template_links
      ) || [];

    if (checklistTemplateLinks.length === 1) {
      checklistTemplateLink = checklistTemplateLinks[0];
      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistTemplateLink.checklist_template_id
      );
      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.active_version_id
      );
    }
  }

  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    operationalMaintenance,
    operationalMaintenanceInstance,
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplateRecurrenceVersion,
    checklistTemplateVersion,
    checklistInstance,
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(OperationalMaintenanceListItem));
