import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, FieldErrorWrapper } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';
import { SCIMFieldsDisabledBanner } from 'views/components/User/SCIMFieldsDisabledBanner';

class EditEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      email: '',
      showInvalidEmailError: false,
      showEmailHasBeenSentMessage: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        showInvalidEmailError: false,
        showEmailHasBeenSentMessage: false,
        isSaving: false,
        email: '',
      });
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.email) {
      this.save();
    }
  };

  save = () => {
    const { email } = this.state;
    if (!HelperFunctions.validateEmail(email)) {
      this.setState({ showInvalidEmailError: true });
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateMe({ email })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ showEmailHasBeenSentMessage: true });
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { scim } = this.props.currentUser;
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.settings.user.contact-information.edit-email-modal.title" />}
          subtitle={
            <FormattedMessage id="screens.settings.user.contact-information.edit-email-modal.subtitle" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content ignoreLine hasFooter>
          {scim ? <SCIMFieldsDisabledBanner /> : null}

          {this.state.showEmailHasBeenSentMessage ? (
            <div className={styles['verification-text']}>
              <FormattedMessage
                id="screens.settings.user.contact-information.edit-email-modal.email-sent-message"
                values={{ email: this.state.email }}
              />
            </div>
          ) : (
            <Field
              view={false}
              label={
                <FormattedMessage id="screens.settings.user.contact-information.edit-email-modal.new-email" />
              }
            >
              <FieldErrorWrapper
                show={this.state.showInvalidEmailError}
                errorElement={
                  <FormattedMessage id="screens.settings.user.contact-information.edit-email-modal.invalid-email-error" />
                }
              >
                <Field.Text
                  autoFocus
                  disabled={this.state.showEmailHasBeenSentMessage || scim}
                  error={this.state.showInvalidEmailError}
                  value={this.state.email}
                  onChange={email => {
                    if (this.state.showInvalidEmailError && email) {
                      this.setState({ showInvalidEmailError: false });
                    }
                    this.setState({ email });
                  }}
                  onKeyPress={this.handleKeyPress}
                />
              </FieldErrorWrapper>
            </Field>
          )}
        </Modal.Content>
        <Modal.Footer>
          {this.state.showEmailHasBeenSentMessage ? (
            <Button primary label="general.ok" onClick={this.props.onClose} />
          ) : (
            <Button.Group>
              <Button
                primary
                disabled={!this.state.email}
                label="general.save"
                loading={this.state.isSaving}
                onClick={this.save}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMe: SDKReduxOperations.updateMe,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditEmailModal));
