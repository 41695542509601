import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { TreePath } from 'views/components/Asset';

const SelectedAssetContainer = props => {
  return (
    <>
      <div>{props.asset.title}</div>
      {props.asset.tree_path.length === 0 ? null : (
        <div>
          <TreePath assetId={props.asset.id} />
        </div>
      )}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedAssetContainer);
