import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { debounce, isEqual } from 'lodash-es';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import moment from 'moment';
import { AuthSelectors } from 'state/ducks/auth';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ExportType } from 'sdk/Export';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { HelperFunctions } from 'sdk';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import {
  OperationalMaintenancesSelectors,
  OperationalMaintenancesOperations,
  OperationalMaintenancesUtils,
} from 'state/ducks/operationalMaintenances';
import { FilterButton, Field, List, Icon, NewInlineModal } from 'views/components/Shared/General';
import { Header, LeftPanel } from 'views/scenes/OperationalMaintenances/components';
import { OperationalMaintenanceListItem } from 'views/components/OperationalMaintenance';
import { ListLayout } from 'views/components/Shared/Layout';
import { LIST_TYPES } from 'views/scenes/OperationalMaintenances/components/LeftPanel';
import styles from './style.module.scss';
import {
  BookmarkedAssetsDropdownSelectors,
  BookmarkedAssetsDropdownOperations,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { EntitySelectors } from 'sdk/State/entities';
import ExportModal from './ExportModal';
import MassEditModal from './MassEditModal';

const listRequest = HelperFunctions.getCancelTokenForRequest();

class OperationalMaintenancesList extends Component {
  getInitialState = () => ({
    isFetching: true,
    showExportModal: false,
    showExportModalForType: null,
    showMassEditModal: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.fetchDebouncedOperationalMaintenances = debounce(() => {
      this.fetchOperationalMaintenances();
    }, 300);
  }

  componentDidMount() {
    this.props.fetchBookmarkedAssets(this.props.system.id).then(() => {
      this.fetchDebouncedOperationalMaintenances();
    });
    this.props.resetSelectedOperationalMaintenances();
  }

  componentDidUpdate(prevProps) {
    const {
      list: prevList,
      date: prevDate,
      from_date: prevFromDate,
      to_date: prevToDate,
    } = queryString.parse(prevProps.location.search);
    const { list, date, from_date, to_date } = queryString.parse(this.props.location.search);
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedList = prevList !== list;
    const changedDate = prevDate !== date;
    const changedFromDate = prevFromDate !== from_date;
    const changedToDate = prevToDate !== to_date;
    const changedBookmarkedAssets = prevProps.bookmarkedAssets.length !== this.props.bookmarkedAssets.length;
    const changedCheckedinAssetId = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    const changedDropdownTypeForAssetDropdown =
      prevProps.dropdownTypeForAssetDropdown !== this.props.dropdownTypeForAssetDropdown;
    const changedAssetIdForAssetDropdown =
      prevProps.assetIdForAssetDropdown !== this.props.assetIdForAssetDropdown;

    if (
      changedQueryParams ||
      changedFromDate ||
      changedToDate ||
      changedDate ||
      changedBookmarkedAssets ||
      changedList ||
      changedDropdownTypeForAssetDropdown ||
      changedAssetIdForAssetDropdown ||
      changedCheckedinAssetId
    ) {
      this.setState({ isFetching: true });
      listRequest.cancel();
      this.fetchDebouncedOperationalMaintenances();
      this.props.resetSelectedOperationalMaintenances();
    }
  }

  componentWillUnmount() {
    this.fetchDebouncedOperationalMaintenances.cancel();
  }

  fetchOperationalMaintenances = () => {
    const dateParams = this.getDateParams();
    const params = { ...dateParams, ...this.getParams(), ...this.getQueryParametes() };

    this.props
      .fetchOperationalMaintenances(this.props.system.id, params, listRequest.getCancelTokenConfig())
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  getDateParams = () => {
    const { list, from_date, to_date } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.ThisWeek) {
      return {
        from_date: moment(this.getDateFromUrl()).startOf('isoWeek').format('YYYY-MM-DD'),
        to_date: moment(this.getDateFromUrl()).endOf('isoWeek').format('YYYY-MM-DD'),
      };
    }
    if (list === LIST_TYPES.ThisMonth) {
      return {
        from_date: moment(this.getDateFromUrl()).startOf('month').format('YYYY-MM-DD'),
        to_date: moment(this.getDateFromUrl()).endOf('month').format('YYYY-MM-DD'),
      };
    }
    if (list === LIST_TYPES.Custom) {
      return {
        from_date: moment(from_date).format('YYYY-MM-DD'),
        to_date: moment(to_date).format('YYYY-MM-DD'),
      };
    }
    return {
      from_date: moment(this.getDateFromUrl()).format('YYYY-MM-DD'),
      to_date: moment(this.getDateFromUrl()).format('YYYY-MM-DD'),
    };
  };

  getParams = () => {
    let params = {};
    if (this.props.isOperator) {
      if (this.props.checkedInAssetId) {
        params = {
          ...params,
          asset_ids: this.props.checkedInAssetId,
        };
      }
    } else {
      if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
        params = {
          ...params,
          asset_ids: this.props.bookmarkedAssets.map(({ asset_id }) => asset_id).join(','),
        };
      } else if (this.props.assetIdForAssetDropdown) {
        params = {
          ...params,
          asset_ids: this.props.assetIdForAssetDropdown,
        };
      }
    }

    return params;
  };

  getQueryParametes = () => {
    let params = {};
    if (this.props.queryParameters.status.length > 0) {
      params = {
        status: this.props.queryParameters.status.join(','),
      };
    }
    return params;
  };

  getDateFromUrl = () => {
    const { date } = queryString.parse(this.props.location.search);
    return date || moment().tz(this.props.system.timezone).format('YYYY-MM-DD');
  };

  changeUrlQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...queryString.parse(this.props.location.search),
        ...obj,
      })}`
    );
  };

  setNavigatedTo = () => {
    const { list, date } = queryString.parse(this.props.location.search);
    this.props.setNavigatedToOperationalMaintenance({
      search: {
        list,
        date,
      },
    });
  };

  goToPreviousTimespan = () => {
    const { list } = queryString.parse(this.props.location.search);
    let per = 'day';
    if (list === LIST_TYPES.ThisWeek) {
      per = 'week';
    }
    if (list === LIST_TYPES.ThisMonth) {
      per = 'month';
    }
    this.changeUrlQueryParams({
      date: moment(this.getDateFromUrl()).subtract('1', per).format('YYYY-MM-DD'),
    });
  };

  goToNextTimespan = () => {
    const { list } = queryString.parse(this.props.location.search);
    let per = 'day';
    if (list === LIST_TYPES.ThisWeek) {
      per = 'week';
    }
    if (list === LIST_TYPES.ThisMonth) {
      per = 'month';
    }
    this.changeUrlQueryParams({ date: moment(this.getDateFromUrl()).add('1', per).format('YYYY-MM-DD') });
  };

  renderEmptyDataSetTitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Today) {
      return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-day.title" />;
    }
    if (list === LIST_TYPES.ThisWeek) {
      return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-week.title" />;
    }
    return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-month.title" />;
  };

  renderEmptyDataSetSubtitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Today) {
      return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-day.subtitle" />;
    }
    if (list === LIST_TYPES.ThisWeek) {
      return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-week.subtitle" />;
    }
    return <FormattedMessage id="screens.operational-maintenances.list.empty-data-set-month.subtitle" />;
  };

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
        <div className={styles['subtitle']}>{this.renderEmptyDataSetSubtitle()}</div>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderBookmarkedAssetsEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.operational-maintenances.bookmarked-assets-empty-data-set.subtitle" />
        </div>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderHeader = () => {
    const { pageIsSelected } = this.props;
    const { isFetching } = this.state;
    return (
      <List.Header
        small
        background
        expandable
        checkbox
        showMultipleOptions={this.props.selectedOperationalMaintenancesCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={isFetching}
            count={this.props.selectedOperationalMaintenancesCount}
            buttons={
              <>
                {this.props.canAdministrateOperationalMaintenances ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.work-orders.header-buttons.edit" />}
                    onClick={() => this.setState({ showMassEditModal: true })}
                  />
                ) : null}
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="general.print" />}
                  onClick={() =>
                    this.setState({
                      showExportModal: true,
                      showExportModalForType: ExportType.OperationalMaintenancesListPdf,
                    })
                  }
                />
              </>
            }
          />
        }
        checked={isFetching === false && pageIsSelected === true}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          this.props.selectPage();
        }}
      >
        <List.Header.Column flex>
          <FormattedMessage id="resources.operational-maintenance.title" />
        </List.Header.Column>
        <List.Header.Column width={180}>
          <FormattedMessage id="resources.operational-maintenance.date" />
        </List.Header.Column>
        <List.Header.Column width={18} />
      </List.Header>
    );
  };

  renderList = () => {
    const { list } = queryString.parse(this.props.location.search);
    let dateFormat;
    switch (list) {
      case LIST_TYPES.Today:
        dateFormat = 'day';
        break;
      case LIST_TYPES.ThisWeek:
        dateFormat = 'week';
        break;
      case LIST_TYPES.ThisMonth:
        dateFormat = 'month';
        break;
    }

    return (
      <List>
        {this.props.operationalMaintenances
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(({ id }) => {
            let checked = false;
            if (this.props.selectedOperationalMaintenanceIds[id] === true) {
              checked = true;
            }
            return (
              <OperationalMaintenanceListItem
                key={id}
                id={id}
                checkbox
                checked={checked}
                dateFormat={dateFormat}
                onClick={this.setNavigatedTo}
                onCheck={e => {
                  this.props.selectOperationalMaintenance(id);
                }}
              />
            );
          })}
      </List>
    );
  };

  renderContent = () => {
    const { dropdownTypeForAssetDropdown, bookmarkedAssets, operationalMaintenances } = this.props;
    if (this.state.isFetching) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderHeader()}
            <List>
              <OperationalMaintenanceListItem checkbox loading />
              <OperationalMaintenanceListItem checkbox loading />
            </List>
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    if (
      dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets &&
      bookmarkedAssets.length === 0
    ) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderBookmarkedAssetsEmptyDataset()}
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    if (operationalMaintenances.length === 0) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderEmptyDataset()}
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>
          {this.renderHeader()}
          {this.renderList()}
        </ListLayout.Content.MainContent.Content>
      </ListLayout.Content.MainContent>
    );
  };

  renderDates = () => {
    const { list, from_date, to_date } = queryString.parse(this.props.location.search);

    if (list === LIST_TYPES.Today) {
      return (
        <div className={styles['dates']}>
          <div className={styles['pill']}>{moment(this.getDateFromUrl()).format('dddd')}</div>
          <div className={styles['date']}>{moment(this.getDateFromUrl()).format('LL')}</div>
        </div>
      );
    }
    if (list === LIST_TYPES.ThisWeek) {
      return (
        <div className={styles['dates']}>
          <div className={styles['pill']}>
            {moment(this.getDateFromUrl()).format('MMMM')} {moment(this.getDateFromUrl()).format('YYYY')}
          </div>
          <div className={styles['date']}>
            <FormattedMessage
              id="screens.production-board.operational-maintenances.week"
              values={{ week: moment(this.getDateFromUrl()).format('w') }}
            />
          </div>
        </div>
      );
    }
    if (list === LIST_TYPES.ThisMonth) {
      return (
        <div className={styles['dates']}>
          <div className={styles['date']}>
            {moment(this.getDateFromUrl()).format('MMMM')} {moment(this.getDateFromUrl()).format('YYYY')}
          </div>
        </div>
      );
    }
    if (list === LIST_TYPES.Custom) {
      return (
        <div className={styles['dates']}>
          <div className={styles['date']}>
            <span>{moment(from_date).format('LL')}</span>
            <span> - </span>
            <span>{moment(to_date).format('LL')}</span>
          </div>
        </div>
      );
    }
  };

  renderNextandPreviousDateButtons = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Custom) {
      return null;
    }
    return (
      <div className={styles['right-content']}>
        <Header.Button.Group>
          <Header.Button small onClick={() => this.goToPreviousTimespan()}>
            <Icon type="angle-left" regular />
          </Header.Button>
          <Header.Button small onClick={() => this.goToNextTimespan()}>
            <Icon type="angle-right" regular />
          </Header.Button>
        </Header.Button.Group>
      </div>
    );
  };

  changeStatus = status => {
    const { status: oldStatus } = this.props.queryParameters;
    this.props.addQueryParameter({
      status: oldStatus.includes(status)
        ? oldStatus.filter(loopedStatus => loopedStatus !== status)
        : [...oldStatus, status],
    });
  };

  renderStatusFilterButton = () => {
    const { status } = this.props.queryParameters;

    return (
      <div
        ref={ref => (this.statusFilterPositioningRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            showStatusFilterInlineModal: !prevState.showStatusFilterInlineModal,
          }));
        }}
      >
        <FilterButton
          filtered={status.length > 0}
          label={<FormattedMessage id="resources.operational-maintenance.status" />}
          onClear={e => {
            e.stopPropagation();
            this.props.addQueryParameter({ status: [] });
          }}
        />
        <NewInlineModal
          minWidth={250}
          positionToRef={this.statusFilterPositioningRef}
          open={this.state.showStatusFilterInlineModal}
          onClose={() => {
            this.setState({ showStatusFilterInlineModal: false });
          }}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={status.includes(OperationalMaintenanceStatus.NotStarted)}
                    onChange={() => {
                      this.changeStatus(OperationalMaintenanceStatus.NotStarted);
                    }}
                  />
                }
                onClick={e => {
                  e.stopPropagation();
                  this.changeStatus(OperationalMaintenanceStatus.NotStarted);
                }}
              >
                <FormattedMessage id="resources.operational-maintenance.statuses.not-started" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={status.includes(OperationalMaintenanceStatus.Completed)}
                    onChange={() => {
                      this.changeStatus(OperationalMaintenanceStatus.Completed);
                    }}
                  />
                }
                onClick={e => {
                  e.stopPropagation();
                  this.changeStatus(OperationalMaintenanceStatus.Completed);
                }}
              >
                <FormattedMessage id="resources.operational-maintenance.statuses.completed" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={status.includes(OperationalMaintenanceStatus.Skipped)}
                    onChange={() => {
                      this.changeStatus(OperationalMaintenanceStatus.Skipped);
                    }}
                  />
                }
                onClick={e => {
                  e.stopPropagation();
                  this.changeStatus(OperationalMaintenanceStatus.Skipped);
                }}
              >
                <FormattedMessage id="resources.operational-maintenance.statuses.skipped" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderToolbar = () => (
    <ListLayout.Content.MainContent.FilterBar>
      <ListLayout.Content.MainContent.FilterBar.LeftContent>
        {this.renderStatusFilterButton()}
      </ListLayout.Content.MainContent.FilterBar.LeftContent>
      <ListLayout.Content.MainContent.FilterBar.RightContent>
        <div className={styles['right-content']}>
          {this.renderDates()}
          {this.renderNextandPreviousDateButtons()}
        </div>
      </ListLayout.Content.MainContent.FilterBar.RightContent>
    </ListLayout.Content.MainContent.FilterBar>
  );

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        exportType={this.state.showExportModalForType}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderMassEditModal = () => {
    return (
      <MassEditModal
        open={this.state.showMassEditModal}
        onSave={() => {
          this.props.resetSelectedOperationalMaintenances();
          this.fetchOperationalMaintenances();
          this.setState({ showMassEditModal: false });
        }}
        onClose={() => {
          this.setState({ showMassEditModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          <Header />
          <ListLayout.Content>
            <LeftPanel />
            {this.renderContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderExportModal()}
        {this.renderMassEditModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenances: OperationalMaintenancesOperations.fetchOperationalMaintenances,
      fetchBookmarkedAssets: BookmarkedAssetsDropdownOperations.fetchBookmarkedAssets,
      selectOperationalMaintenance: OperationalMaintenancesOperations.selectOperationalMaintenance,
      selectPage: OperationalMaintenancesOperations.selectOperationalMaintenancesPage,
      setNavigatedToOperationalMaintenance:
        OperationalMaintenancesOperations.setNavigatedToOperationalMaintenance,
      resetSelectedOperationalMaintenances:
        OperationalMaintenancesOperations.resetSelectedOperationalMaintenances,
      addQueryParameter: OperationalMaintenancesOperations.addQueryParameterForOperationalMaintenances,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    isOperator: AuthSelectors.isOperator(state),
    asset: EntitySelectors.getAsset(state, id),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    operationalMaintenances: OperationalMaintenancesSelectors.getOperationalMaintenances(state),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    assetIdForAssetDropdown: BookmarkedAssetsDropdownSelectors.getAssetId(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    selectedOperationalMaintenanceIds:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenanceIds(state),
    selectedOperationalMaintenancesCount:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenancesCount(state),
    pageIsSelected: OperationalMaintenancesSelectors.getPageIsSelected(state),
    queryParameters: OperationalMaintenancesSelectors.getQueryParametersForOperationalMaintenances(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenancesList));
