import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ selectedValueComponent, onClear, label, inline }) => {
  return (
    <div
      className={`${styles['filter-button-container']} ${inline ? styles['inline'] : ''} ${
        selectedValueComponent ? styles['selected'] : ''
      }`}
    >
      {selectedValueComponent != null ? (
        <div className={styles['selected-template']}>
          <div className={styles['template']}>{selectedValueComponent}</div>
          <div
            className={styles['close']}
            onClick={e => {
              e.stopPropagation();
              onClear();
            }}
          >
            <Icon light type="times" />
          </div>
        </div>
      ) : (
        <div className={styles['label']}>
          <FormattedMessage id={label} />
          <Icon type="caret-down" />
        </div>
      )}
    </div>
  );
};
