import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';

class TestPrintQrCodeModal extends Component {
  state = {
    showExportLoaderForId: null,
  };
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.export();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  export = () => {
    API.createExport(this.props.system.id, this.props.printQrCodeParams).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.print-qr-codes.preview-template-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(TestPrintQrCodeModal);
