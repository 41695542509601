import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { List, ElapsedTime, Datapill } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { DowntimeTimer } from 'views/components/Downtime';

const DowntimeListItem = props => {
  const getTotalStopTimeAsMinutes = () => {
    const fromDate = moment(props.downtime.from);
    const toDate = moment(props.downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  const renderTime = () => {
    if (props.downtime.to == null) {
      return (
        <Datapill
          red
          value={
            <FormattedMessage
              id="screens.asset.downtimes.downtime-is-active"
              values={{
                timer: <DowntimeTimer downtime={props.downtime} />,
              }}
            />
          }
        />
      );
    }
    return <Datapill orange value={<ElapsedTime elapsedMinutes={getTotalStopTimeAsMinutes()} />} />;
  };

  if (props.loading) {
    return (
      <List.Item checkbox>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }
  return (
    <List.Item
      clickable
      onClick={props.onClick}
      checked={props.checked}
      onCheck={props.onCheck}
      checkboxDisabled={props.checkboxDisabled}
      checkbox
    >
      <List.Item.TitleColumn
        title={moment(props.downtime.from).format('LLL')}
        subtitle={<TreePath fullPath assetId={props.downtime.asset_id} />}
      />
      <List.Item.Column alignRight>{renderTime()}</List.Item.Column>
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    downtime: EntitySelectors.getDowntime(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(DowntimeListItem);
