import HelperFunctions from 'utilities/HelperFunctions';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { FILTER_TYPES } from 'views/scenes/Vendors/VendorList/FilterModal';

const getVendors = state => {
  const { vendors, entities } = state;
  return vendors.ids.map(id => entities.vendorById[id]);
};

const getQueryParameters = (state, key) => {
  const { vendors } = state;
  return vendors.queryParams;
};

const getFilters = (state, key) => {
  const { vendors } = state;
  return vendors.filters;
};

const getFiltersAsQueryParams = state => {
  const { vendors } = state;
  let vendorFilters = { ...vendors.filters };
  if (vendors.appliedFilters.includes(FILTER_TYPES.purchase_order_currency)) {
    const { comparator } = vendors.filters.purchase_order_currency;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Any) {
      vendorFilters = {
        ...vendorFilters,
        purchase_order_currency: {
          ...vendorFilters.purchase_order_currency,
          comparator: SDKHelperFunctions.FILTER_COMPARABLES.Search,
        },
      };
    }
  }

  return HelperFunctions.buildFilter(vendorFilters);
};

const getAppliedFilters = (state, key) => {
  const { vendors } = state;
  return vendors.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { vendors } = state;
  return vendors.filters[key];
};

const isFiltering = state => {
  const { vendors } = state;
  return vendors.appliedFilters.length > 0;
};

const showingAppliedFilters = state => {
  const { vendors } = state;
  return vendors.showingAppliedFilters;
};

const getSelectedFilterType = state => {
  const { vendors } = state;
  return vendors.showApplyFilterForField;
};

const getVendorsPagination = state => state.vendors.pagination;

/*
  MassEdit
*/

const getSelectedVendorIds = state => {
  return state.vendors.selectedVendors.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.vendors.selectedVendors.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.vendors.selectedVendors.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.vendors.selectedVendors.pageIsSelected;
};

const getSelectedVendorCount = state => {
  if (state.vendors.selectedVendors.totalEntriesAreSelected) {
    return state.vendors.pagination.totalEntries;
  }
  return Object.keys(state.vendors.selectedVendors.ids).length;
};

export default {
  getSelectedVendorIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedVendorCount,
  getVendors,
  getQueryParameters,
  getVendorsPagination,
  getFilters,
  getFiltersAsQueryParams,
  getAppliedFilters,
  getFiltersForKey,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
};
