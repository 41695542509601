import update from 'immutability-helper';
import EntityTypes from '../types';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import types from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.REQUEST_DELETED: {
      const { requestId } = action.payload;
      if (state[requestId]) {
        return {
          ...state,
          [requestId]: {
            ...state[requestId],
            _deleted: true,
          },
        };
      }
      return state;
    }
    case types.ADD_IMAGE: {
      const { requestId, data } = action.payload;
      return update(
        { ...state },
        {
          [requestId]: {
            images: {
              $push: [data.id],
            },
          },
        }
      );
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_REQUEST: {
      const { requestId, data } = action.payload;
      const { id } = data;
      if (state[requestId]) {
        return update(
          { ...state },
          {
            [requestId]: {
              images: {
                $push: [id],
              },
              image_count: {
                $apply: n => n + 1,
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { request_id } = data;
      if (state[request_id] == null) return state;
      return {
        ...state,
        [request_id]: {
          ...state[request_id],
          images: state[request_id].images.filter(id => id !== imageId),
          image_count: --state[request_id].image_count,
        },
      };
    }
    case SDKReduxTypes.WORK_ORDER_CREATED: {
      const { data: workOrder } = action.payload;
      if (workOrder.created_from_requests.length === 0) return state;
      const requestId = workOrder.created_from_requests[0].id;

      if (state[requestId] == null) return state;
      return {
        ...state,
        [requestId]: {
          ...state[requestId],
          work_order: workOrder.id,
          work_order_id: workOrder.id,
        },
      };
    }
    case SDKReduxTypes.REQUEST_TYPE_DELETED: {
      const { requestTypeId } = action.payload;

      return Object.entries(state).reduce((acc, [id, request]) => {
        return {
          ...acc,
          [id]: {
            ...request,
            request_type_id: request.request_type_id === requestTypeId ? null : request.request_type_id,
            request_type: request.request_type === requestTypeId ? null : request.request_type,
          },
        };
      }, {});
    }
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { meta } = action.payload;
      const { request_id } = meta;

      if (state[request_id]) {
        return {
          ...state,
          [request_id]: {
            ...state[request_id],
            downtime: null,
            downtime_id: null,
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.requestById) {
        return HelperFunctions.mergeEntities(state, action.payload.requestById);
      }
      return state;

    default:
      return state;
  }
};
