import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import * as moment from 'moment';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldErrorWrapper, Button, DatePicker } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { generateEmptyOperationalMaintenanceBreak } from 'sdk/OperationalMaintenanceBreak';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import AssetField from './AssetField';

class Edit extends Component {
  constructor(props) {
    super(props);
    let showAssetField = false;
    if (props.new !== true && props.operationalMaintenanceBreak.asset_id) {
      showAssetField = true;
    }
    this.state = {
      showFromDateRequiredError: false,
      showFromDateLargerThanToDateError: false,
      hasUnsavedChanges: false,
      showAssetField,
      operationalMaintenanceBreak: props.new
        ? generateEmptyOperationalMaintenanceBreak()
        : { ...props.operationalMaintenanceBreak },
      operationalMaintenanceBreakBeforeEdit: props.new
        ? generateEmptyOperationalMaintenanceBreak()
        : { ...props.operationalMaintenanceBreak },
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  cancelEdit = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onCancel();
      }
    } else {
      this.props.onCancel();
    }
  };

  setOperationalMaintenanceBreakValue = values => {
    const operationalMaintenanceBreak = { ...this.state.operationalMaintenanceBreak, ...values };

    const hasUnsavedChanges = !isEqual(
      this.state.operationalMaintenanceBreakBeforeEdit,
      operationalMaintenanceBreak
    );

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      operationalMaintenanceBreak,
      hasUnsavedChanges,
    });
  };

  getEditingChanges = () => {
    let data = {};
    const operationalMaintenanceBreak = this.state.operationalMaintenanceBreak;
    const diffProps = Object.keys(operationalMaintenanceBreak).filter(
      k => operationalMaintenanceBreak[k] !== this.state.operationalMaintenanceBreakBeforeEdit[k]
    );
    diffProps.forEach(key => {
      data[key] = operationalMaintenanceBreak[key];
    });
    return data;
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  hasErrors = () => {
    let hasErrors = false;
    if (this.state.operationalMaintenanceBreak.from_date == null) {
      this.setState({ showFromDateRequiredError: true });
      hasErrors = true;
    }
    if (
      this.state.operationalMaintenanceBreak.to_date &&
      moment(this.state.operationalMaintenanceBreak.from_date).isAfter(
        moment(this.state.operationalMaintenanceBreak.to_date)
      )
    ) {
      this.setState({ showFromDateLargerThanToDateError: true });
      hasErrors = true;
    }

    return hasErrors;
  };

  updateOperationalMaintenanceBreak = () => {
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    let params = {
      ...this.getEditingChanges(),
    };
    if (this.state.showAssetField === false) {
      params = {
        ...params,
        asset_id: null,
      };
    }
    this.props
      .updateOperationalMaintenanceBreak(this.props.operationalMaintenanceBreak.id, params)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onCancel();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  createOperationalMaintenanceBreak = () => {
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    let params = {
      ...this.state.operationalMaintenanceBreak,
    };
    if (this.state.showAssetField === false) {
      params = {
        ...params,
        asset_id: null,
      };
    }
    this.props
      .createOperationalMaintenanceBreak(this.props.system.id, params)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="components.edit-operational-maintenance-break-modal.create-success" />
            }
          />
        );
        this.props.onCancel();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  save = () => {
    if (this.hasErrors()) {
      return;
    }
    if (this.props.new) {
      this.createOperationalMaintenanceBreak();
    } else {
      this.updateOperationalMaintenanceBreak();
    }
  };

  renderTimeFields = () => {
    return (
      <Grid.Row>
        <Grid.Column md={6}>
          <Field label={<FormattedMessage id="resources.operational-maintenance-break.from-date" />}>
            <FieldErrorWrapper
              position="top"
              show={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
              errorElement={
                this.state.showFromDateRequiredError ? (
                  <FormattedMessage id="general.errors.is-required" />
                ) : (
                  <FormattedMessage id="components.edit-operational-maintenance-break-modal.errors.from-date-larger-than-to-date" />
                )
              }
            >
              <Field.Date
                error={this.state.showFromDateRequiredError || this.state.showFromDateLargerThanToDateError}
                value={this.state.operationalMaintenanceBreak.from_date}
                onChangeDate={date => {
                  if (this.state.showFromDateRequiredError) {
                    this.setState({
                      showFromDateRequiredError: false,
                    });
                  }
                  if (this.state.showFromDateLargerThanToDateError) {
                    this.setState({
                      showFromDateLargerThanToDateError: false,
                    });
                  }
                  this.setOperationalMaintenanceBreakValue({
                    from_date: date,
                  });
                }}
                footerComponent={
                  <DatePicker.Footer
                    showClear={this.state.operationalMaintenanceBreak.from_date != null}
                    onClear={() => {
                      this.setOperationalMaintenanceBreakValue({ from_date: null });
                    }}
                  />
                }
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
        <Grid.Column md={6}>
          <Field label={<FormattedMessage id="resources.operational-maintenance-break.to-date" />}>
            <Field.Date
              value={this.state.operationalMaintenanceBreak.to_date}
              onChangeDate={date => {
                if (this.state.showFromDateLargerThanToDateError) {
                  this.setState({
                    showFromDateLargerThanToDateError: false,
                  });
                }
                this.setOperationalMaintenanceBreakValue({
                  to_date: date,
                });
              }}
              footerComponent={
                <DatePicker.Footer
                  showClear={this.state.operationalMaintenanceBreak.to_date != null}
                  onClear={() => {
                    this.setOperationalMaintenanceBreakValue({ to_date: null });
                  }}
                />
              }
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderDescription = () => {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.operational-maintenance-break.description" />}>
            <Field.Textarea
              value={this.state.operationalMaintenanceBreak.description}
              onChange={description => {
                this.setOperationalMaintenanceBreakValue({ description });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderAssetField = () => {
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.showAssetField}
              onChange={() =>
                this.setState(prevState => ({
                  showAssetField: !prevState.showAssetField,
                }))
              }
              label={
                <FormattedMessage id="components.edit-operational-maintenance-break-modal.add-asset-checkbox" />
              }
            />
          </Grid.Column>
        </Grid.Row>

        <AnimateHeight duration={250} height={this.state.showAssetField ? 'auto' : 0}>
          <Grid.Row>
            <Grid.Column>
              <AssetField
                value={this.state.operationalMaintenanceBreak.asset_id}
                onChange={asset_id => this.setOperationalMaintenanceBreakValue({ asset_id })}
              />
            </Grid.Column>
          </Grid.Row>
        </AnimateHeight>
      </>
    );
  };

  renderInfo = () => (
    <>
      <Grid>
        {this.renderTimeFields()}
        {this.renderDescription()}
        {this.renderAssetField()}
      </Grid>
    </>
  );

  render() {
    return (
      <>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={
            this.props.new ? (
              <FormattedMessage id="components.edit-operational-maintenance-break-modal.title-new" />
            ) : (
              <FormattedMessage id="components.edit-operational-maintenance-break-modal.title-edit" />
            )
          }
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderInfo()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary loading={this.state.isSaving} onClick={this.save} label="general.save" />
            <Button label="general.cancel" onClick={this.cancelEdit} />
          </Button.Group>
        </Modal.Footer>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOperationalMaintenanceBreak: SDKReduxOperations.updateOperationalMaintenanceBreak,
      createOperationalMaintenanceBreak: SDKReduxOperations.createOperationalMaintenanceBreak,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.new)
    return {
      system: AuthSelectors.getCurrentSystem(state),
    };
  const operationalMaintenanceBreak = EntitySelectors.getOperationalMaintenanceBreak(state, ownProps.id);
  return {
    operationalMaintenanceBreak,
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit));
