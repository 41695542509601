import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { SideModal } from 'views/components/Shared/Layout';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class Category extends Component {
  renderCategory = category => {
    switch (category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.corrective" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.corrective" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.corrective-defered" />
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.preventive" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <FormattedMessage id="resources.work-order.categories.preventive" />
            <span>&nbsp;-&nbsp;</span>
            <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderSelectedValue = () => {
    const { comparator, value } = this.props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Value>
        {this.renderCategory(value)}
      </SideModal.Container.Filter.AppliedFilters.Value>
    );
  };

  render() {
    const { comparator, value } = this.props.filter;
    return (
      <SideModal.Container.Filter.AppliedFilters.Filter.Resource
        title={<FormattedMessage id="resources.work-order.category" />}
        comparator={comparator}
        hasValue={value}
        onClear={() =>
          this.props.removeFilter({
            key: FILTER_TYPES.Category,
            data: {
              [FILTER_TYPES.Category]: {
                comparator: HelperFunctions.FILTER_COMPARABLES.Any,
                value: null,
              },
            },
          })
        }
      >
        {this.renderSelectedValue()}
      </SideModal.Container.Filter.AppliedFilters.Filter.Resource>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Category),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);
