import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import { Datapill, NewInlineModal, Button, Icon, ConfirmModal } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartReservationListItem extends Component {
  inlineModalPositioningRef = null;

  state = {
    dropdownOpen: false,
    showConfirmRemoveSparePartModal: false,
  };

  renderQuantityDataPill = () => {
    return (
      <Datapill
        value={
          <SparePartUnitWithValue
            value={this.props.sparePartReservation.quantity.toString()}
            sparePartUnit={this.props.sparePartUnit}
          />
        }
      />
    );
  };

  renderDataPills = () => {
    return (
      <div className={styles['datapills-container']}>
        <Datapill.List>{this.renderQuantityDataPill()}</Datapill.List>
      </div>
    );
  };

  renderMoreOptionsColumn = () => {
    return (
      <div>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={150}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={() => {
                  this.setState({ dropdownOpen: false, showConfirmRemoveSparePartModal: true });
                }}
              >
                <FormattedMessage id="general.remove" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderRemoveSparePartConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmRemoveSparePartModal}
        title={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-spare-part-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-spare-part-confirm-modal.subtitle" />
        }
        destructive
        confirmButtonText="general.remove"
        onConfirm={() => {
          this.setState({ showConfirmRemoveSparePartModal: true });
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            sparePartReservations: [
              ...this.props.editingOperationalMaintenanceTemplateVersion.sparePartReservations,
            ].filter(({ spare_part_id }) => spare_part_id !== this.props.sparePartReservation.spare_part_id),
          });
        }}
        onCancel={() => {
          this.setState({ showConfirmRemoveSparePartModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['reservation']}>
          <div className={styles['title-container']}>
            <div>
              <Title sparePart={this.props.sparePart} />
            </div>
            <div>{this.renderDataPills()}</div>
          </div>
          {this.renderMoreOptionsColumn()}
        </div>
        {this.renderRemoveSparePartConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { spare_part_id } = ownProps.sparePartReservation;
  const sparePart = EntitySelectors.getSparePart(state, spare_part_id);
  return {
    sparePart: EntitySelectors.getSparePart(state, spare_part_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartReservationListItem);
