import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { omit } from 'lodash-es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Modal } from 'views/components/Shared/Layout';
import {
  Button,
  List,
  WhiteCard,
  EmptyDataSet,
  NewSearchField,
  Pagination,
  Loader,
} from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import { normalizeSparePart } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import SparePartListItem from './SparePartListItem';
import SelectedSparePartItem from './SelectedSparePartItem';
import Tabs from './Tabs';
import styles from './style.module.scss';

const PAGE_SIZE = 6;

const fetchSparePartsRequest = HelperFunctions.getCancelTokenForRequest();

class AddSparePartModal extends Component {
  getInitialState = () => ({
    sparePartIds: [],
    sparePartReservations: [],
    selectedSpareParts: {},
    viewIsInitialized: false,
    isFetching: false,
    isSearching: false,
    searchValue: '',
    selectedTab: 'reserved',
    page: 1,
    totalPages: 1,
  });

  state = this.getInitialState();

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const sparePartReservations = this.props.sparePartReservations.filter(
        sparePartReservation => !this.props.excludeSparePartIds.includes(sparePartReservation.spare_part_id)
      );
      const showReserved = sparePartReservations.length > 0 && !this.props.reservation;

      let selectedTab = 'all';
      if (this.props.assetId) {
        selectedTab = 'asset';
      }
      if (showReserved) {
        selectedTab = 'reserved';
      }
      this.setState(
        {
          ...this.getInitialState(),
          selectedTab,
          sparePartReservations,
        },
        () => {
          this.fetchSpareParts().then(({ ids, totalPages }) => {
            this.setState({
              viewIsInitialized: true,
              sparePartIds: ids,
              selectedSpareParts: {},
              totalPages,
            });
          });
        }
      );
    }
  }

  fetchSpareParts = () => {
    fetchSparePartsRequest.cancel();
    let params = {
      page_size: PAGE_SIZE,
      page: this.state.page,
    };
    if (this.state.searchValue && this.state.searchValue.length > 0) {
      params = {
        ...params,
        search: this.state.searchValue,
        sort: 'search_relevance',
      };
    }
    if (this.state.selectedTab === 'asset') {
      params = {
        ...params,
        spare_part_assets: { asset_id: this.props.assetId },
      };
    }
    if (this.props.excludeSparePartIds.length > 0) {
      params = {
        ...params,
        id: {
          [HelperFunctions.FILTER_COMPARABLES.NoneOf]: this.props.excludeSparePartIds.join(','),
        },
      };
    }

    return API.listSpareParts(
      this.props.currentSystem.id,
      params,
      fetchSparePartsRequest.getCancelTokenConfig()
    )
      .then(res => {
        const { totalPages } = HelperFunctions.getPaginationFromHeader(res.headers);
        const { entities, result } = normalizeSparePart(res.data);
        this.props.updateEntities(entities);

        return { ids: result, totalPages };
      })
      .catch(error => {});
  };

  addSpareParts = () => {
    this.props.onAddSpareParts(
      Object.values(this.state.selectedSpareParts).map(({ sparePart, quantity }) => ({
        spare_part_id: sparePart.id,
        quantity,
      }))
    );
  };

  renderReservedSpareParts = () => {
    return (
      <List>
        {this.state.sparePartReservations.map(sparePartReservation => (
          <SparePartListItem
            key={sparePartReservation.id}
            sparePartReservationId={sparePartReservation.id}
            selectedSparePart={this.state.selectedSpareParts[sparePartReservation.spare_part_id]}
            onAddUsedSparePart={({ sparePart, quantity }) => {
              this.setState({
                selectedSpareParts: {
                  ...this.state.selectedSpareParts,
                  [sparePart.id]: { sparePart, quantity },
                },
              });
            }}
          />
        ))}
      </List>
    );
  };

  renderSparePartsList = () => {
    const amountOfItems = this.state.sparePartIds.length === 0 ? 2 : this.state.sparePartIds.length;
    if (this.state.isSearching) {
      return (
        <>
          <List>
            {Array(amountOfItems)
              .fill()
              .map(() => (
                <SparePartListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.state.isFetching) {
      return (
        <React.Fragment>
          <List>
            {Array(amountOfItems)
              .fill()
              .map(() => (
                <SparePartListItem loading />
              ))}
          </List>
        </React.Fragment>
      );
    }
    if (this.state.sparePartIds.length === 0) {
      if (this.state.searchValue.length > 0) {
        return this.renderSearchedEmptyDataset();
      }
      if (this.state.selectedTab === 'asset') {
        return this.renderAssetSparePartsEmptyDataSet();
      }
      return this.renderAllSparePartsEmptyDataSet();
    }
    return (
      <>
        <List>
          {this.state.sparePartIds.map(sparePartId => (
            <SparePartListItem
              key={sparePartId}
              sparePartId={sparePartId}
              selectedSparePart={this.state.selectedSpareParts[sparePartId]}
              onAddUsedSparePart={({ sparePart, quantity }) => {
                this.setState({
                  selectedSpareParts: {
                    ...this.state.selectedSpareParts,
                    [sparePart.id]: { sparePart, quantity },
                  },
                });
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderPagination = () => {
    if (this.state.totalPages <= 1) return null;
    return (
      <div className={styles['pagination']}>
        <Pagination
          hideOptions
          currentPage={this.state.page}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.setState({ page, isSearching: true }, () => {
              this.fetchSpareParts()
                .then(({ ids: sparePartIds, totalPages }) => {
                  this.setState({
                    isSearching: false,
                    sparePartIds,
                    totalPages,
                  });
                })
                .catch(e => {});
            });
          }}
        />
      </div>
    );
  };

  renderMainContent = () => {
    if (this.state.selectedTab === 'reserved') {
      return this.renderReservedSpareParts();
    }
    return (
      <>
        {this.renderSearchField()}
        {this.renderSparePartsList()}
      </>
    );
  };

  renderAllSparePartsEmptyDataSet = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={
          <FormattedMessage id="components.add-spare-parts-modal.all-spare-parts-empty-data-set.title" />
        }
        subtitle={
          <FormattedMessage id="components.add-spare-parts-modal.all-spare-parts-empty-data-set.subtitle" />
        }
        image={SparePartSmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderAssetSparePartsEmptyDataSet = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={
          <FormattedMessage id="components.add-spare-parts-modal.asset-spare-parts-empty-data-set.title" />
        }
        subtitle={
          <FormattedMessage id="components.add-spare-parts-modal.asset-spare-parts-empty-data-set.subtitle" />
        }
        image={SparePartSmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderSearchedEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="general.empty-data-set-search.title" />}
        subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
        image={SearchImage}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderSearchField = () => {
    const { sparePartIds, searchValue, isSearching } = this.state;
    if (sparePartIds.length === 0 && searchValue.length === 0 && isSearching === false) {
      return null;
    }
    return (
      <div className={styles['search-field-container']}>
        <NewSearchField
          autoFocus
          small
          value={this.state.searchValue}
          againstGrayBackground
          debounce
          placeholder={this.props.intl.formatMessage({
            id: 'components.add-spare-parts-modal.search-placeholder',
          })}
          onSearch={value => {
            this.setState({
              page: 1,
              searchValue: value,
              isSearching: true,
            });
          }}
          onDebouncedSearch={value => {
            this.fetchSpareParts()
              .then(({ ids: sparePartIds, totalPages }) => {
                this.setState({
                  isSearching: false,
                  sparePartIds,
                  page: 1,
                  totalPages,
                });
              })
              .catch(e => {});
          }}
          onClear={() => {
            this.setState({ page: 1, isSearching: true, searchValue: '' }, () => {
              this.fetchSpareParts()
                .then(({ ids: sparePartIds, totalPages }) => {
                  this.setState({
                    isSearching: false,
                    sparePartIds,
                    page: 1,
                    totalPages,
                  });
                })
                .catch(e => {});
            });
          }}
        />
      </div>
    );
  };

  closeModal = () => {
    if (Object.values(this.state.selectedSpareParts).length > 0) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  renderModalHeader = () => {
    if (this.props.reservation) {
      let title = <FormattedMessage id="components.add-spare-parts-modal.add-reservation-title" />;
      if (this.props.title) {
        title = this.props.title;
      }
      return (
        <Modal.Header
          title={title}
          subtitle={
            this.props.skipSubtitle ? null : (
              <FormattedMessage id="components.add-spare-parts-modal.add-reservation-subtitle" />
            )
          }
          subtitleTopMargin
          onClose={this.closeModal}
        />
      );
    } else {
      let title = <FormattedMessage id="components.add-spare-parts-modal.add-used-title" />;
      if (this.props.title) {
        title = this.props.title;
      }
      return (
        <Modal.Header
          title={title}
          subtitle={
            this.props.skipSubtitle ? null : (
              <FormattedMessage id="components.add-spare-parts-modal.add-used-subtitle" />
            )
          }
          subtitleTopMargin
          onClose={this.closeModal}
        />
      );
    }
  };

  renderSelectedList = () => {
    if (Object.values(this.state.selectedSpareParts).length === 0) {
      return (
        <p className={styles['empty-list']}>
          <FormattedMessage id="components.add-spare-parts-modal.empty-selected-list" />
        </p>
      );
    } else {
      return (
        <div className={styles['selected']}>
          {Object.values(this.state.selectedSpareParts).map(selectedSparePart => (
            <SelectedSparePartItem
              key={selectedSparePart.sparePart.id}
              selectedSparePart={selectedSparePart}
              updateQuantity={quantity => {
                this.setState({
                  selectedSpareParts: {
                    ...this.state.selectedSpareParts,
                    [selectedSparePart.sparePart.id]: {
                      ...this.state.selectedSpareParts[selectedSparePart.sparePart.id],
                      quantity,
                    },
                  },
                });
              }}
              onDelete={() => {
                this.setState({
                  selectedSpareParts: omit(this.state.selectedSpareParts, selectedSparePart.sparePart.id),
                });
              }}
            />
          ))}
        </div>
      );
    }
  };

  renderTabs = () => {
    const showReserved = this.state.sparePartReservations.length > 0 && !this.props.reservation;
    if (!showReserved && this.props.assetId == null) return null;

    return (
      <Tabs>
        {showReserved ? (
          <Tabs.Tab
            selected={this.state.selectedTab === 'reserved'}
            onClick={() => {
              this.setState({ selectedTab: 'reserved' });
            }}
          >
            <FormattedMessage id="components.add-spare-parts-modal.reserved-label" />
          </Tabs.Tab>
        ) : null}
        {this.props.assetId ? (
          <Tabs.Tab
            selected={this.state.selectedTab === 'asset'}
            onClick={() => {
              this.setState({ selectedTab: 'asset', page: 1, searchValue: '', isFetching: true }, () => {
                this.fetchSpareParts()
                  .then(({ ids: sparePartIds, totalPages }) => {
                    this.setState({
                      isFetching: false,
                      sparePartIds,
                      totalPages,
                    });
                  })
                  .catch(e => {});
              });
            }}
          >
            <FormattedMessage id="components.add-spare-parts-modal.asset-spare-parts-title" />
          </Tabs.Tab>
        ) : null}
        <Tabs.Tab
          selected={this.state.selectedTab === 'all'}
          onClick={() => {
            this.setState({ selectedTab: 'all', page: 1, searchValue: '', isFetching: true }, () => {
              this.fetchSpareParts()
                .then(({ ids: sparePartIds, totalPages }) => {
                  this.setState({
                    isFetching: false,
                    sparePartIds,
                    totalPages,
                  });
                })
                .catch(e => {});
            });
          }}
        >
          <FormattedMessage id="components.add-spare-parts-modal.all-spare-parts-title" />
        </Tabs.Tab>
      </Tabs>
    );
  };

  renderMainContainer = () => {
    if (!this.state.viewIsInitialized) {
      return (
        <div className={styles['main-content']}>
          <div className={styles['loader']}>
            <Loader small />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['main-content']}>
        {this.renderTabs()}
        <PerfectScrollbar>
          <div className={styles['list-container']}>{this.renderMainContent()}</div>
        </PerfectScrollbar>
      </div>
    );
  };

  renderContent = () => {
    return (
      <Modal.Content grayBackground noPadding>
        <div className={styles['container']}>
          <div className={styles['selected-container']}>
            <PerfectScrollbar>
              <div className={styles['list-container']}>{this.renderSelectedList()}</div>
            </PerfectScrollbar>
            <div className={styles['save-container']}>
              <Button
                large
                fullWidth
                primary
                loading={this.props.isSaving}
                disabled={Object.values(this.state.selectedSpareParts).length === 0}
                onClick={this.addSpareParts}
                label="general.add"
              />
            </div>
          </div>
          <div className={styles['main-container']}>{this.renderMainContainer()}</div>
        </div>
      </Modal.Content>
    );
  };

  render() {
    return (
      <Modal verticalMargin="30px" fullHeight isOpen={this.props.open} width={1000}>
        {this.renderModalHeader()}
        {this.renderContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddSparePartModal));

AddSparePartModal.propTypes = {
  title: PropTypes.any,
  skipSubtitle: PropTypes.bool,
  excludeSparePartIds: PropTypes.array,
  sparePartReservations: PropTypes.array,
};

AddSparePartModal.defaultProps = {
  title: null,
  skipSubtitle: false,
  excludeSparePartIds: [],
  sparePartReservations: [],
};
