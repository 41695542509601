import update from 'immutability-helper';

export function buildReduxChecklistRowData({
  checklist_template_rows = [],
  checklist_instance_options = [],
}) {
  let rootRowIds = [];
  let childRowIds = {};
  let instanceOptionIdsForTemplateRowIds = {};
  checklist_template_rows
    .sort((a, b) => {
      if (a.parent_row_id && b.parent_row_id == null) {
        return 1;
      } else if (a.parent_row_id == null && b.parent_row_id) {
        return -1;
      }
      return a.sort - b.sort;
    })
    .forEach(checklistTemplateRow => {
      const { id, parent_row_id } = checklistTemplateRow;
      if (parent_row_id == null) {
        rootRowIds = [...rootRowIds, id];
        childRowIds = {
          ...childRowIds,
          [id]: [],
        };
      } else {
        let parentId = parent_row_id;
        if (childRowIds[parentId] == null) {
          childRowIds = {
            ...childRowIds,
            [parentId]: [id],
          };
        } else {
          childRowIds = {
            ...childRowIds,
            [parentId]: [...childRowIds[parentId], id],
          };
        }
      }
    });
  checklist_instance_options.forEach(option => {
    const { checklist_template_row_id, id } = option;
    instanceOptionIdsForTemplateRowIds = update(instanceOptionIdsForTemplateRowIds, {
      [checklist_template_row_id]: {
        $apply: optionIds => {
          if (Array.isArray(optionIds)) {
            return [...optionIds, id];
          }
          return [id];
        },
      },
    });
  });

  return {
    rootRowIds,
    childRowIds,
    instanceOptionIdsForTemplateRowIds,
  };
}

export function buildReduxChecklistRowDataWithInstanceRows({
  checklist_template_rows = [],
  checklist_instance_options = [],
  checklist_instance_rows = [],
}) {
  let rootRowIds = [];
  let childRowIds = {};
  let instanceRowForTemplateRowIds = {};
  let instanceOptionIdsForTemplateRowIds = {};
  checklist_template_rows
    .sort((a, b) => {
      if (a.parent_row_id && b.parent_row_id == null) {
        return 1;
      } else if (a.parent_row_id == null && b.parent_row_id) {
        return -1;
      }
      return a.sort - b.sort;
    })
    .forEach(checklistTemplateRow => {
      const { id, parent_row_id } = checklistTemplateRow;
      let instanceRow = checklist_instance_rows.find(
        ({ checklist_template_row_id }) => checklist_template_row_id === id
      );
      if (instanceRow) {
        instanceRowForTemplateRowIds = update(instanceRowForTemplateRowIds, {
          [id]: {
            $set: instanceRow.id,
          },
        });
      }
      if (parent_row_id == null) {
        rootRowIds = [...rootRowIds, id];
        childRowIds = {
          ...childRowIds,
          [id]: [],
        };
      } else {
        let parentId = parent_row_id;
        if (childRowIds[parentId] == null) {
          childRowIds = {
            ...childRowIds,
            [parentId]: [id],
          };
        } else {
          childRowIds = {
            ...childRowIds,
            [parentId]: [...childRowIds[parentId], id],
          };
        }
      }
    });
  checklist_instance_options.forEach(option => {
    const { checklist_template_row_id, id } = option;
    instanceOptionIdsForTemplateRowIds = update(instanceOptionIdsForTemplateRowIds, {
      [checklist_template_row_id]: {
        $apply: optionIds => {
          if (Array.isArray(optionIds)) {
            return [...optionIds, id];
          }
          return [id];
        },
      },
    });
  });
  return {
    rootRowIds,
    childRowIds,
    instanceRowForTemplateRowIds,
    instanceOptionIdsForTemplateRowIds,
  };
}
