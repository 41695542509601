import React, { Component } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Button,
  FormattedNumber,
  ConfirmModal,
  FullScreenImagePicker,
} from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import {
  CommentOptions,
  ChecklistInstanceRowComments,
  Attachments,
  Attachment,
} from 'views/components/Checklist/ChecklistTemplateRow/Components';
import { ChecklistOptionsModal } from 'views/components/Checklist';
import { ChangeMeterValueModal } from 'views/components/Meter';
import { Image } from 'views/components/Image';
import { buildReduxChecklistRowDataWithInstanceRows } from 'sdk/ChecklistTemplate';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { MeterType } from 'sdk/ChecklistTemplateRow';
import { Type } from 'sdk/ChecklistInstanceOption';
import { ChecklistModalOperations } from 'state/ducks/checklistModal';
import { API, SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class Meter extends Component {
  state = {
    showConfirmDeleteModal: false,
    isDeletingReading: false,
    showChangeMeterValueModal: false,
    showTemplateRowImages: false,
    showTemplateRowAttachments: false,
    showingImageDialog: false,
    showConfirmSettingsModal: false,
    showMissingSettingsModal: false,
    showChecklistOptionsModal: false,
    isSavingOptions: false,
    currentImage: 0,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  batchUpdateChecklistOptions = params => {
    const { checklist_instance_options } = params;
    this.setState({ isSavingOptions: true });
    let requestsToWaitFor = checklist_instance_options.map(option => {
      const { meter_id, checklist_template_row_id, type, id } = option;
      if (id) {
        return this.props.updateChecklistInstanceOption(id, {
          meter_id,
        });
      } else {
        return this.props.createForChecklistInstance(this.props.checklistInstance.id, {
          meter_id,
          checklist_template_row_id,
          type,
        });
      }
    });
    return Promise.all(requestsToWaitFor).then(data => {
      API.fetchChecklistInstance(this.props.checklistInstance.id).then(({ data: checklistInstance }) => {
        const { entities } = normalizeChecklistInstance(checklistInstance);
        this.props.updateEntities(entities);
        const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
          buildReduxChecklistRowDataWithInstanceRows({
            checklist_template_rows: checklistInstance.checklist_template_version.checklist_template_rows,
            checklist_instance_rows: checklistInstance.checklist_instance_rows,
            checklist_instance_options: checklistInstance.checklist_instance_options,
          });
        this.props.setChecklistInstance({
          instanceId: checklistInstance.id,
          childIds: childRowIds,
          templateFieldIds: rootRowIds,
          instanceRowForTemplateRowIds,
          instanceOptionIdsForTemplateRowIds,
        });
        this.setState({ showChecklistOptionsModal: false, isSavingOptions: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      });
    });
  };

  deleteReading = () => {
    this.props
      .deleteMeterReading(this.props.meterReading.id)
      .then(() => {
        this.setState({ isDeletingReading: false, showConfirmDeleteModal: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.delete-success" />}
          />
        );
      })
      .catch(e => {
        this.setState({ isDeletingReading: false });
      });
  };

  renderAddMeterReadingButton = () => {
    if (this.props.meterReading) {
      return (
        <Button
          gray
          translate={false}
          clickable={this.props.editable}
          label={
            <FormattedNumber
              value={this.props.meterReading.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          }
          onClick={() => {
            this.setState({ showChangeMeterValueModal: true });
          }}
        />
      );
    }
    if (this.props.meter) {
      return (
        <Button
          gray
          clickable={this.props.editable === true}
          label="components.checklist-instance-modal.add-meter-reading"
          onClick={() => {
            this.setState({ showChangeMeterValueModal: true });
          }}
        />
      );
    }
    if (this.props.checklistTemplateRow.meter_type === MeterType.Option) {
      return (
        <Button
          gray
          label="components.checklist-instance-modal.add-meter-reading"
          clickable={this.props.editable}
          onClick={() => {
            if (this.props.canAdministrateWorkOrders) {
              this.setState({ showConfirmSettingsModal: true });
            } else {
              this.setState({ showMissingSettingsModal: true });
            }
          }}
        />
      );
    }

    return (
      <span className={styles['meter-deleted']}>
        <FormattedMessage id="components.edit-checklist-modal.meter-deleted" />
      </span>
    );
  };

  renderDeleteConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.title" />}
        message={<FormattedMessage id="screens.meter.modals.change-value.confirm-delete.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingReading}
        onConfirm={() => {
          this.setState({ isDeletingReading: true });
          this.deleteReading();
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          setTimeout(() => {
            this.setState({ showChangeMeterValueModal: true });
          }, 100);
        }}
      />
    );
  };

  renderUpdateSettingsConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmSettingsModal}
        title={
          <FormattedMessage id="components.edit-checklist-modal.update-missing-meter-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="components.edit-checklist-modal.update-missing-meter-confirm-modal.subtitle" />
        }
        confirmButtonText="general.yes"
        onConfirm={() => {
          this.setState({ showConfirmSettingsModal: false });
          setTimeout(() => {
            this.setState({ showChecklistOptionsModal: true });
          }, 250);
        }}
        onCancel={() => {
          this.setState({ showConfirmSettingsModal: false });
        }}
      />
    );
  };

  renderMissingSettingsConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showMissingSettingsModal}
        title={<FormattedMessage id="components.edit-checklist-modal.missing-meter-confirm-modal.title" />}
        message={
          <FormattedMessage id="components.edit-checklist-modal.missing-meter-confirm-modal.subtitle" />
        }
        confirmButtonText="general.ok"
        onConfirm={() => {
          this.setState({ showMissingSettingsModal: false });
        }}
        onCancel={() => {
          this.setState({ showMissingSettingsModal: false });
        }}
      />
    );
  };

  renderChecklistOptionsModal = () => {
    let checklistInstanceOptionsIds = [];
    let checklistInstanceId = null;
    if (this.props.checklistInstance) {
      checklistInstanceId = this.props.checklistInstance.id;
      checklistInstanceOptionsIds = this.props.checklistInstance.checklist_instance_options;
    }
    return (
      <ChecklistOptionsModal
        checklistInstanceId={checklistInstanceId}
        checklistTemplateId={this.props.checklistTemplateVersion.checklist_template_id}
        checklistInstanceOptionIds={checklistInstanceOptionsIds}
        open={this.state.showChecklistOptionsModal}
        isSaving={this.state.isSavingOptions}
        onSave={params => {
          this.batchUpdateChecklistOptions(params);
        }}
        onClose={() => this.setState({ showChecklistOptionsModal: false })}
      />
    );
  };

  renderUpdateMeterValueModal = () => {
    if (this.props.instanceRowId == null) {
      return null;
    }
    if (this.props.meterReading) {
      return (
        <ChangeMeterValueModal
          meterId={this.props.meter.id}
          updateForMeterReadingId={this.props.meterReading.id}
          confirmDelete={() => {
            this.setState({ showChangeMeterValueModal: false });
            setTimeout(() => {
              this.setState({ showConfirmDeleteModal: true });
            }, 100);
          }}
          open={this.state.showChangeMeterValueModal}
          onClose={() => this.setState({ showChangeMeterValueModal: false })}
          onUpdateMeterReading={() => this.setState({ showChangeMeterValueModal: false })}
        />
      );
    }
    if (this.props.meter) {
      return (
        <ChangeMeterValueModal
          meterId={this.props.meter.id}
          createForChecklistInstanceRowId={this.props.instanceRowId}
          open={this.state.showChangeMeterValueModal}
          onClose={() => this.setState({ showChangeMeterValueModal: false })}
        />
      );
    }
    return null;
  };

  renderComments = () => {
    if (this.props.checklistInstanceRow) {
      return <ChecklistInstanceRowComments instanceRowId={this.props.instanceRowId} />;
    }
    return null;
  };

  renderSeparator = () => {
    if (this.props.checklistInstanceRow) {
      const { checklistTemplateRowImages, checklistTemplateRowAttachments } = this.props;
      const { images, comment } = this.props.checklistInstanceRow;
      let hasTemplateAttachments =
        checklistTemplateRowImages.length > 0 || checklistTemplateRowAttachments.length > 0;
      if (hasTemplateAttachments && (images.length > 0 || comment)) {
        return <div className={styles['separator']} />;
      }
    }
    return null;
  };

  renderImage = (image, index) => {
    return (
      <Image
        key={image.id}
        editable={false}
        medium
        image={image}
        onClick={() => {
          this.selectImage(index);
        }}
      />
    );
  };

  renderTemplateRowImages = () => {
    if (this.props.checklistTemplateRowImages.length > 0) {
      return (
        <AnimateHeight duration={250} height={this.state.showTemplateRowImages ? 'auto' : 0}>
          <div className={styles['images']}>
            {this.props.checklistTemplateRowImages.map((image, index) => this.renderImage(image, index))}
          </div>
        </AnimateHeight>
      );
    }
  };

  renderTemplateRowAttachments = () => {
    if (this.props.checklistTemplateRowAttachments.length > 0) {
      return (
        <AnimateHeight duration={250} height={this.state.showTemplateRowAttachments ? 'auto' : 0}>
          <div className={styles['attachments']}>
            {this.props.checklistTemplateRowAttachments.map(({ id, attachment_version }) => (
              <Attachment id={id} />
            ))}
          </div>
        </AnimateHeight>
      );
    }
  };

  renderAttachments = () => {
    const { checklistTemplateRowImages, checklistTemplateRowAttachments } = this.props;
    if (checklistTemplateRowImages.length === 0 && checklistTemplateRowAttachments.length === 0) {
      return null;
    }
    return (
      <div className={styles['attachments']}>
        <Attachments
          clickable={this.props.editable || this.props.instanceRowId == null}
          imagesIsSelected={this.state.showTemplateRowImages}
          attachmentsIsSelected={this.state.showTemplateRowAttachments}
          images={this.props.checklistTemplateRowImages}
          attachments={this.props.checklistTemplateRowAttachments}
          onClickImages={() => {
            this.setState(prevState => ({
              showTemplateRowAttachments: false,
              showTemplateRowImages: !prevState.showTemplateRowImages,
            }));
          }}
          onClickAttachments={() => {
            this.setState(prevState => ({
              showTemplateRowImages: false,
              showTemplateRowAttachments: !prevState.showTemplateRowAttachments,
            }));
          }}
        />
      </div>
    );
  };

  render() {
    let classNames = [styles['meter-container']];
    return (
      <>
        <div className={classNames.join(' ')}>
          <div className={styles['container']}>
            <div className={styles['meter']}>
              <div className={styles['title-container']}>
                <div>{this.props.checklistTemplateRow.title}</div>
                {this.renderAttachments()}
              </div>
              <div className={styles['right-container']}>
                <div>{this.renderAddMeterReadingButton()}</div>
                <CommentOptions editable={this.props.editable} instanceRowId={this.props.instanceRowId} />
              </div>
            </div>
            {this.renderTemplateRowImages()}
            {this.renderTemplateRowAttachments()}
            {this.renderSeparator()}
            {this.renderComments()}
          </div>
        </div>
        {this.renderUpdateMeterValueModal()}
        {this.renderDeleteConfirmModal()}
        {this.renderUpdateSettingsConfirmModal()}
        {this.renderMissingSettingsConfirmModal()}
        {this.renderChecklistOptionsModal()}
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.checklistTemplateRowImages}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setChecklistInstance: ChecklistModalOperations.setChecklistInstance,
      updateEntities: EntityOperations.updateEntities,
      deleteMeterReading: SDKReduxOperations.deleteMeterReading,
      updateChecklistInstanceOption: SDKReduxOperations.updateChecklistInstanceOption,
      createForChecklistInstance: SDKReduxOperations.createForChecklistInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id, instanceRowId, options } = ownProps;
  const checklistTemplateRow = EntitySelectors.getChecklistTemplateRow(state, id);
  let newState = {
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    checklistTemplateRow,
    checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplateRow.checklist_template_version_id
    ),
    checklistTemplateRowImages: EntitySelectors.getImages(state, checklistTemplateRow.images),
    checklistTemplateRowAttachments: EntitySelectors.getAttachments(state, checklistTemplateRow.attachments),
  };
  if (checklistTemplateRow.meter_type === MeterType.Fixed) {
    const meter = EntitySelectors.getMeter(state, checklistTemplateRow.meter_id);
    let meterUnit = null;
    if (meter) {
      meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
    }
    newState = {
      ...newState,
      meter,
      meterUnit,
    };
  } else if (options && checklistTemplateRow.meter_type === MeterType.Option) {
    const meterOption = options.find(({ type }) => type === Type.Meter);
    let meterUnit = null;
    let meter = null;
    if (meterOption) {
      meter = EntitySelectors.getMeter(state, meterOption.meter_id);
      meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
    }
    newState = {
      ...newState,
      meter,
      meterUnit,
    };
  }
  if (instanceRowId) {
    const checklistInstanceRow = EntitySelectors.getChecklistInstanceRow(state, instanceRowId);
    const checklistInstance = EntitySelectors.getChecklistInstance(
      state,
      checklistInstanceRow.checklist_instance_id
    );
    newState = {
      ...newState,
      checklistInstanceRow,
      checklistInstance,
      meterReading: EntitySelectors.getMeterReading(state, checklistInstanceRow.meter_reading),
    };
  }

  return newState;
}
export default connect(mapStateToProps, mapDispatchToProps)(Meter);

Meter.propTypes = {
  id: PropTypes.string,
  instanceRowId: PropTypes.string,
  editable: PropTypes.bool,
};

Meter.defaultProps = {
  id: null,
  instanceRowId: null,
  editable: true,
};
