import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import { API } from 'sdk';
import { normalizeGroup, normalizeUser } from 'sdk/Schemas';
import { UserNameWrapper } from 'views/components/User';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import GroupDropdownItem from './GroupDropdownItem';
import UserDropdownItem from './UserDropdownItem';
import { EntitySelectors } from 'sdk/State/entities';

class AssigneeDropdown extends Component {
  state = {
    isFetching: false,
    userIds: [],
    groupIds: [],
    open: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.open && this.state.open) {
      this.setState({ isFetching: true });
      Promise.all([
        API.listGroups(this.props.system.id, { type: 'request_assignee', no_pagination: true }),
        API.listUsers(this.props.system.id, { archived: false, no_pagination: true, member: true }),
      ]).then(([{ data: groups }, { data: users }]) => {
        const { entities: groupEntities, result: groupIds } = normalizeGroup(groups);
        const { entities: userEntities, result: userIds } = normalizeUser(users);
        this.props.updateEntities({ ...groupEntities, ...userEntities });
        this.setState({ isFetching: false, groupIds, userIds });
      });
    } else if (!this.state.open && prevState.open) {
      this.setState({ userIds: [], groupIds: [] });
    }
  }

  renderContent = () => {
    const { userId, groupId } = this.props;
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    return (
      <NewInlineModal.Dropdown.Items>
        {this.state.groupIds.map(id => (
          <GroupDropdownItem
            id={id}
            selected={groupId === id}
            onSelect={() => {
              this.props.onSelectGroup(id);
              this.setState({ open: false });
            }}
          />
        ))}
        {this.state.userIds.map(id => (
          <UserDropdownItem
            id={id}
            selected={userId === id}
            onSelect={() => {
              this.props.onSelectUser(id);
              this.setState({ open: false });
            }}
          />
        ))}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderSelectedAssignee = () => {
    const { user, group } = this.props;
    if (user) {
      return <UserNameWrapper user={user} />;
    }
    if (group) {
      return group.title;
    }
    return null;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              open: !prevState.open,
            }));
          }}
        >
          <Field view={false} label={<FormattedMessage id="resources.request.assignee" />}>
            <Field.Resource
              angleDown
              value={this.renderSelectedAssignee()}
              onClear={() => this.props.onClear()}
            />
          </Field>
        </div>

        <NewInlineModal
          positionToRef={this.assigneeDropdownInlineModalPositioningRef}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <React.Fragment>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </React.Fragment>
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { userId, groupId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    user: EntitySelectors.getUser(state, userId),
    group: EntitySelectors.getGroup(state, groupId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeDropdown);
