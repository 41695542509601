import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { matchPath } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { ListLayout } from 'views/components/Shared/Layout';
import { Menu } from 'views/components/Shared/General';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class LeftBarComponent extends Component {
  routeIsActive = path => {
    return (
      matchPath(this.props.location.pathname, {
        path: path,
      }) != null
    );
  };

  renderAdminSettings = () => {
    if (this.props.isAdmin) {
      return (
        <>
          <Menu.Separator />
          <Menu.Item
            linkTo="/settings/company"
            title={<FormattedMessage id="screens.settings.company.title" />}
            selected={this.routeIsActive('/settings/company')}
          />
          <Menu.Item
            linkTo="/settings/users"
            title={<FormattedMessage id="screens.settings.users.title" />}
            selected={this.routeIsActive('/settings/users')}
          />
          <Menu.Item
            linkTo="/settings/work-orders"
            title={<FormattedMessage id="screens.settings.work-orders.title" />}
            selected={this.routeIsActive('/settings/work-orders')}
          />
          <Menu.Item
            linkTo="/settings/meters"
            title={<FormattedMessage id="screens.settings.meters.title" />}
            selected={this.routeIsActive('/settings/meters')}
          />
          <Menu.Item
            linkTo="/settings/requests"
            title={<FormattedMessage id="screens.settings.requests.title" />}
            selected={this.routeIsActive('/settings/requests')}
          />
          <Menu.Item
            linkTo="/settings/assets"
            title={<FormattedMessage id="screens.settings.assets.title" />}
            selected={this.routeIsActive('/settings/assets')}
          />
          <Menu.Item
            linkTo="/settings/spare-parts"
            title={<FormattedMessage id="screens.settings.spare-parts.title" />}
            selected={this.routeIsActive('/settings/spare-parts')}
          />
          {this.props.settings.spare_parts_activated ? (
            <Menu.Item
              linkTo="/settings/purchase-orders"
              title={<FormattedMessage id="screens.settings.purchase-orders.title" />}
              selected={this.routeIsActive('/settings/purchase-orders')}
            />
          ) : null}
          <Menu.Item
            linkTo="/settings/costs"
            title={<FormattedMessage id="screens.settings.costs.title" />}
            selected={this.routeIsActive('/settings/costs')}
          />
          {this.props.hasProTier ? (
            <Menu.Item
              linkTo="/settings/operational-maintenances"
              title={<FormattedMessage id="screens.settings.operational-maintenance.title" />}
              selected={this.routeIsActive('/settings/operational-maintenances')}
            />
          ) : null}
          <Menu.Item
            linkTo="/settings/production-boards"
            title={<FormattedMessage id="screens.settings.production-boards.title" />}
            selected={this.routeIsActive('/settings/production-boards')}
          />
          {this.props.hasEnterpriseTier &&
          this.props.currentUser.organisation_admin &&
          this.props.organisationSettings.sso_oidc_toggle ? (
            <Menu.Item
              linkTo="/settings/integrations"
              title={<FormattedMessage id="screens.settings.integrations.title" />}
              selected={this.routeIsActive('/settings/integrations')}
            />
          ) : null}
        </>
      );
    }
    return null;
  };

  renderProductionSupervisorSettings = () => {
    const { isProductionSupervisor } = this.props;
    const { production_supervisor_permission_users } = this.props.settings;
    if (isProductionSupervisor && production_supervisor_permission_users === 'create_operators') {
      return (
        <>
          <Menu.Separator />
          <Menu.Item
            linkTo="/settings/users"
            title={<FormattedMessage id="screens.settings.users.title" />}
            selected={this.routeIsActive('/settings/users')}
          />
        </>
      );
    }
  };

  render() {
    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <div className={styles['left-panel-container']}>
            <div className={styles['list-buttons']}>
              <ListLayout.Content.Menu.Content>
                <Menu.Item
                  linkTo="/settings/user"
                  title={<FormattedMessage id="screens.settings.user.title" />}
                  selected={this.routeIsActive('/settings/user')}
                />
                {this.props.isViewOnly === false ? (
                  <Menu.Item
                    linkTo="/settings/notifications/email"
                    title={<FormattedMessage id="screens.settings.notifications.title" />}
                    selected={this.routeIsActive('/settings/notifications')}
                  />
                ) : null}

                {this.renderAdminSettings()}
                {this.renderProductionSupervisorSettings()}
              </ListLayout.Content.Menu.Content>
            </div>
          </div>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: AuthSelectors.isAdmin(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    settings: AuthSelectors.getSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    hasProTier: AuthSelectors.hasProTier(state),
    hasEnterpriseTier: AuthSelectors.hasEnterpriseTier(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
  };
}

export default withRouter(connect(mapStateToProps)(LeftBarComponent));
