import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Menu } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { AuthSelectors } from 'state/ducks/auth';
import SectionsMenuItem from './SectionsMenuItem';
import DeviationsMenuItem from './DeviationsMenuItem';
import styles from './style.module.scss';

class LeftPanel extends Component {
  state = {
    isCompletingChecklist: false,
  };

  renderChecklistRowRecap = () => {
    return (
      <SectionsMenuItem
        openedFromDeviations={this.props.openedFromDeviations}
        onSelectChecklistItem={() => this.props.selectMenuItem('checklist')}
        onSelectSectionItem={templateFieldId => this.props.selectMenuItem(templateFieldId)}
      />
    );
  };

  renderDeviations = () => {
    const {
      workOrder,
      operationalMaintenanceInstance,
      canAdministrateWorkOrders,
      isProductionSupervisor,
      isViewOnly,
    } = this.props;
    const workOrderChecklistCompleted = workOrder && workOrder.status === WorkOrderStatus.Completed;
    const operationalMaintenanceInstanceChecklistCompleted =
      operationalMaintenanceInstance &&
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed;
    const checklistCompleted =
      workOrderChecklistCompleted || operationalMaintenanceInstanceChecklistCompleted;
    if (checklistCompleted && (canAdministrateWorkOrders || isProductionSupervisor || isViewOnly)) {
      return (
        <>
          <Menu.Separator />
          <DeviationsMenuItem />
        </>
      );
    }
  };

  renderWorkOrderMenuItem = () => {
    if (this.props.openedFromDeviations && this.props.workOrder) {
      return (
        <Menu.Item
          title={<FormattedMessage id="resources.work-order.resource" />}
          selected={this.props.selectedMenuItem === 'workOrder'}
          onClick={() => this.props.selectMenuItem('workOrder')}
        />
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.props.loading) {
      return (
        <>
          <div className={styles['list-buttons']}>
            <ListLayout.Content.Menu.Content>
              <Menu.Item loading />
              <Menu.Item loading />
            </ListLayout.Content.Menu.Content>
          </div>
        </>
      );
    }
    return (
      <>
        <PerfectScrollbar>
          <div className={styles['list-buttons']}>
            <ListLayout.Content.Menu.Content>
              {this.renderChecklistRowRecap()}
              {this.renderDeviations()}
              {this.renderWorkOrderMenuItem()}
            </ListLayout.Content.Menu.Content>
          </div>
        </PerfectScrollbar>
      </>
    );
  };

  render() {
    return (
      <div className={styles['left-panel']}>
        <div className={styles['left-panel-container']}>{this.renderContent()}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: ChecklistModalOperations.selectMenuItem,
      selectMenuItemSection: ChecklistModalOperations.selectMenuItemSection,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  let workOrder = null;
  let operationalMaintenanceInstance = null;
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  if (checklistInstance) {
    workOrder = EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id);
    operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
      state,
      checklistInstance.operational_maintenance_instance_id
    );
  }
  return {
    workOrder,
    operationalMaintenanceInstance,
    selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
