import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SideModal } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import { FILTER_TYPES } from 'views/scenes/Assets/TreeAndList/List/FilterModal';
import TemplateFieldDropdown from './TemplateFieldDropdown';
import TemplateFieldItemContainer from './TemplateFieldItemContainer';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    const { filter, initialFilter, templateField } = this.props;
    if (filter && filter[templateField.id]) {
      const { value, comparator } = filter[templateField.id];
      if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
        this.state = {
          initialFilter: filter[templateField.id],
          comparator,
          hasValue: value,
          ids: [],
        };
      } else {
        this.state = {
          initialFilter: filter[templateField.id],
          ids: value,
          comparator,
          hasValue: false,
        };
      }
    } else {
      this.state = {
        initialFilter: initialFilter,
        comparator: initialFilter.comparator,
        ids: [],
        hasValue: false,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      if (this.hasFiltersApplied()) {
        if (this.state.comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
          this.props.addFilter({
            key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
            data: {
              [FILTER_TYPES.AssetField]: {
                ...this.props.filter,
                [this.props.templateField.id]: {
                  comparator: this.state.comparator,
                  value: this.state.hasValue,
                },
              },
            },
          });
        } else {
          this.props.addFilter({
            key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
            data: {
              [FILTER_TYPES.AssetField]: {
                ...this.props.filter,
                [this.props.templateField.id]: {
                  comparator: this.state.comparator,
                  value: this.state.ids,
                },
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    if (this.state.comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return this.state.ids.length > 0;
  };

  /*
    Render functions
  */

  renderSelectedValues = () => {
    if (this.state.ids.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.AppliedFilters.Values
        skipBackground
        data={this.state.ids}
        renderItem={id => <TemplateFieldItemContainer id={id} />}
      />
    );
  };

  renderTrigger = () => {
    return (
      <TemplateFieldDropdown
        trigger={
          <Field.Resource
            angleDown
            value={this.renderSelectedValues()}
            onClear={() => this.setState({ ids: [] })}
          />
        }
        templateField={this.props.templateField}
        ids={this.state.ids}
        onAdd={id => {
          this.setState({
            ids: [...this.state.ids, id],
          });
        }}
        onRemove={id => {
          this.setState({
            ids: this.state.ids.filter(item => item !== id),
          });
        }}
        onClear={() => this.setState({ ids: [] })}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
                data: {
                  [FILTER_TYPES.AssetField]: {
                    ...this.props.filter,
                    [this.props.templateField.id]: {
                      ...this.props.initialFilter,
                    },
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: `${FILTER_TYPES.AssetField}.${this.props.templateField.id}`,
                data: {
                  [FILTER_TYPES.AssetField]: {
                    ...this.props.filter,
                    [this.props.templateField.id]: {
                      ...this.state.initialFilter,
                    },
                  },
                },
              });
            }

            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          {this.props.templateField.title}
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Resource
            value={this.state.hasValue}
            comparator={this.state.comparator}
            onChange={({ comparator, value }) => {
              this.setState({
                comparator,
                hasValue: value,
              });
            }}
            hasFiltersApplied={this.hasFiltersApplied()}
            onApplyfilter={this.props.showAppliedFilters}
            trigger={this.renderTrigger()}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: AssetsOperations.removeFilter,
      addFilter: AssetsOperations.addFilter,
      showAppliedFilters: AssetsOperations.showAppliedFilters,
      selectFilterType: AssetsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: AssetsSelectors.getFiltersForKey(state, FILTER_TYPES.AssetField),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);

Dropdown.propTypes = {
  initialFilter: PropTypes.object,
};

Dropdown.defaultProps = {
  initialFilter: {
    comparator: SDKHelperFunctions.FILTER_COMPARABLES.Any,
    value: [],
  },
};
