import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectSparePartModal, NewSparePartModal } from 'views/components/SparePart';
import { ContentContainer } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, WhiteCard, Pagination, Field } from 'views/components/Shared/General';
import { Loader } from 'views/components/Shared/General';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import SparePartAssetListItem from './SparePartAssetListItem';
import SparePartAssetModal from './SparePartAssetModal';
import styles from './style.module.scss';
import DeleteSparePartAssetModal from 'views/components/SparePartAsset/DeleteSparePartAssetModal';

class SparePartAssets extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      loading: false,
      tableLoading: false,
      viewInitialized: false,
      queryParams: queryParams,
      showSelectSparePartModal: false,
      showAddSparePartAssetModal: false,
      selectedSparePartAssetId: null,
      sparePartId: null,
      showCreateNewSparePartModal: false,
      includeUnderlyingAssets: true,
      showDeleteSparePartAssetModal: false,
      showDeleteSparePartAssetModalForId: null,
    };
  }

  componentDidMount() {
    this.setState({ tableLoading: true });
    this.fetchSpareParts()
      .then(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      })
      .catch(() => {
        this.setState({ viewInitialized: true, tableLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchSpareParts()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  fetchSpareParts = (params = {}) => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);

    let attrs = {
      sort: 'spare_part_title',
      'sort-order': 'asc',
      ...params,
      ...filterParams,
    };
    if (this.state.includeUnderlyingAssets) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id: this.props.match.params.id,
      };
    } else {
      attrs = {
        ...attrs,
        asset_id: this.props.match.params.id,
      };
    }
    return this.props
      .fetchSparePartAssets(this.props.currentSystem.id, attrs)
      .then(({ data: spare_part_assets }) => {
        return spare_part_assets;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  newSparePartAsset = () => {
    this.setState({ showSelectSparePartModal: true });
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.asset.spare-parts.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderSelectSparePartModal = () => (
    <SelectSparePartModal
      open={this.state.showSelectSparePartModal}
      onCreateNew={() => {
        this.setState({ showSelectSparePartModal: false });
        setTimeout(() => {
          this.setState({ showCreateNewSparePartModal: true });
        }, 250);
      }}
      listItemRightComponent={sparePart => {
        if (sparePart.exists_for_asset) {
          return this.renderAlreadyAdded();
        } else return this.renderAddSparePartButton(sparePart.id);
      }}
      additionalApiParams={{ exists_for_asset: this.props.match.params.id }}
      onClose={() => this.setState({ showSelectSparePartModal: false })}
      subtitle={
        <FormattedMessage
          id="screens.asset.spare-parts.spare-part-asset-modal.asset-name"
          values={{ asset: <span style={{ color: '#515151' }}>{this.props.asset.title}</span> }}
        />
      }
    />
  );

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-spare-part']}>
        <FormattedMessage id="general.added" />
      </div>
    );
  };

  renderAddSparePartButton = sparePartId => {
    return (
      <Button
        gray
        small
        label="general.add"
        onClick={() => {
          this.setState({ showSelectSparePartModal: false });
          setTimeout(() => {
            this.setState({
              showAddSparePartAssetModal: true,
              sparePartId,
              selectedSparePartAssetId: null,
            });
          }, 100);
        }}
      />
    );
  };

  renderSparePartAssetModal = () => (
    <>
      <SparePartAssetModal
        open={this.state.showAddSparePartAssetModal}
        assetId={this.props.match.params.id}
        sparePartId={this.state.sparePartId}
        selectedSparePartAssetId={this.state.selectedSparePartAssetId}
        onDelete={sparePartAssetId =>
          setTimeout(
            () =>
              this.setState({
                showDeleteSparePartAssetModal: true,
                showDeleteSparePartAssetModalForId: sparePartAssetId,
              }),
            250
          )
        }
        onClose={status => {
          if (status === 'edit') {
            this.setState({
              showAddSparePartAssetModal: false,
            });
            setTimeout(() => this.setState({ selectedSparePartAssetId: null }), 100);
            return;
          } else
            this.setState({
              showAddSparePartAssetModal: false,
            });
          setTimeout(() => {
            this.setState({ showSelectSparePartModal: true });
          }, 100);
          return;
        }}
      />
      <DeleteSparePartAssetModal
        open={this.state.showDeleteSparePartAssetModal}
        id={this.state.showDeleteSparePartAssetModalForId}
        title={
          <FormattedMessage id="screens.asset.spare-parts.asset-spare-part-modal.confirm-delete.title" />
        }
        subtitle={
          <FormattedMessage id="screens.asset.spare-parts.asset-spare-part-modal.confirm-delete.subtitle" />
        }
        onClose={() =>
          this.setState({ showDeleteSparePartAssetModal: false, showDeleteSparePartAssetModalForId: null })
        }
      />
    </>
  );

  renderNewSparePartModal = () => (
    <NewSparePartModal
      open={this.state.showCreateNewSparePartModal}
      createForAssetId={this.props.match.params.id}
      onClose={() => {
        this.setState({ showCreateNewSparePartModal: false });
        setTimeout(() => {
          this.setState({ showSelectSparePartModal: true });
        }, 250);
      }}
      onCreated={() => {
        this.setState({ showCreateNewSparePartModal: false });
        setTimeout(() => {
          this.setState({ showSelectSparePartModal: true });
        }, 250);
      }}
      onCreatedWithReopen={() => {
        this.setState(
          {
            showCreateNewSparePartModal: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ showCreateNewSparePartModal: true });
            }, 350);
          }
        );
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.asset.spare-parts.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.asset.spare-parts.empty-data-set.subtitle" />}
        image={SparePartSmall}
        button={
          this.props.canEditAssets && this.props.canEditSpareParts ? (
            <Button
              small
              primary
              onClick={this.newSparePartAsset}
              label="screens.asset.spare-parts.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditAssets && this.props.canEditSpareParts) {
      return (
        <div className={styles['toolbar']}>
          <div className={styles['add-button']}>
            <Button
              primary
              label="screens.asset.spare-parts.create-button"
              onClick={this.newSparePartAsset}
            />
          </div>
          <div className={styles['checkbox']}>
            <Field.Checkbox
              checked={this.state.includeUnderlyingAssets}
              label={<FormattedMessage id="screens.asset.work-orders.include-underlying-assets" />}
              onChange={() => {
                this.setState(
                  prevState => ({
                    tableLoading: true,
                    includeUnderlyingAssets: !prevState.includeUnderlyingAssets,
                  }),
                  () => {
                    this.fetchSpareParts()
                      .then(() => {
                        this.setState({ tableLoading: false });
                      })
                      .catch(() => {
                        this.setState({ tableLoading: false });
                      });
                  }
                );
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <>
          <List.Header expandable flex background paddingRight={65}>
            <List.Header.Column width={30} />
            <List.Header.Column flex>
              <FormattedMessage id="resources.spare-part-asset.spare-part" />
            </List.Header.Column>
            <List.Header.Column alignRight width={170}>
              <FormattedMessage id="resources.spare-part-asset.quantity" />
            </List.Header.Column>
            <List.Header.Column alignRight width={140}>
              <FormattedMessage id="resources.spare-part.in-stock" />
            </List.Header.Column>
          </List.Header>
          <List>
            {Array(this.props.sparePartAssetIds.length === 0 ? 1 : this.props.sparePartAssetIds.length)
              .fill()
              .map(() => (
                <SparePartAssetListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.props.sparePartAssetIds.length === 0)
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="screens.asset.spare-parts.empty-data-set.title" />}
            subtitle={<FormattedMessage id="screens.asset.spare-parts.empty-data-set.subtitle" />}
            image={SparePartSmall}
            tiny
            horizontal
            listContainer
          />
        </WhiteCard>
      );
    return (
      <>
        <List.Header expandable flex background paddingRight={65}>
          <List.Header.Column width={30} />
          <List.Header.Column flex>
            <FormattedMessage id="resources.spare-part-asset.spare-part" />
          </List.Header.Column>
          <List.Header.Column alignRight width={170}>
            <FormattedMessage id="resources.spare-part-asset.quantity" />
          </List.Header.Column>
          <List.Header.Column alignRight width={140}>
            <FormattedMessage id="resources.spare-part.in-stock" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.sparePartAssetIds.map(id => (
            <SparePartAssetListItem
              key={id}
              sparePartAssetId={id}
              onClick={() => {
                this.setState({
                  showAddSparePartAssetModal: true,
                  selectedSparePartAssetId: id,
                });
              }}
              onDelete={() =>
                this.setState({ showDeleteSparePartAssetModalForId: id, showDeleteSparePartAssetModal: true })
              }
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderSparePartAssets = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else {
      return this.renderSparePartAssets();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectSparePartModal()}
        {this.renderSparePartAssetModal()}
        {this.renderNewSparePartModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartAssets: AssetOperations.fetchSparePartAssets,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartAssetIds: AssetSelectors.getSparePartAssetIds(state),
    asset: EntitySelectors.getAsset(state, ownProps.match.params.id),
    pagination: AssetSelectors.getSparePartAssetsPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(SparePartAssets)));
