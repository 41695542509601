import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { debounce } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { List, Pagination, Banner, Button } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import { ListLayout } from 'views/components/Shared/Layout';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { UsersSelectors, UsersOperations } from 'state/ducks/users';
import UserListItem from './UserListItem';
import styles from './style.module.scss';
import { MENU_ITEMS } from '../';
import MassEditPermissionModal from './components/MassEditPermissionModal';
import { Link } from 'react-router-dom';
import ManageProductionSupervisorPermissionsModal from 'views/components/User/ManageProductionSupervisorPermissionsModal';

const listUsersRequest = SDKHelperFunctions.getCancelTokenForRequest();

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showMassEditPermisisonModal: false,
      showSupervisorAccessModal: false,
      isSavingAccessChanges: false,
    };
    this.fetchDebouncedUsers = debounce(() => {
      this.fetchUsers();
    }, 300);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedSearchTerm = prevProps.searchTerm !== this.props.searchTerm;
    const { type: oldType } = queryString.parse(prevProps.location.search);
    const { type } = queryString.parse(this.props.location.search);
    const changedType = oldType !== type;
    const changedPage = HelperFunctions.onlyPageWasChangedFromQueryParams(
      prevProps.queryParameters,
      this.props.queryParameters
    );
    if (changedPage) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.setState({ isFetching: true });
      listUsersRequest.cancel();
      this.fetchDebouncedUsers();
      return;
    }

    if (changedQueryParams || changedSearchTerm || changedType) {
      this.setState({ isFetching: true });
      listUsersRequest.cancel();
      this.fetchDebouncedUsers();
      this.props.resetSelectedUsers();
    }
    if (prevProps.users.length !== this.props.users.length) {
      this.props.fetchAndSetAmountOfPayingUsers(this.props.system.id);
    }
  }

  getParamsForList = () => {
    const { type } = queryString.parse(this.props.location.search);
    let attrs = {
      archived: false,
    };
    if (type === MENU_ITEMS.Members) {
      attrs = {
        ...attrs,
        member: true,
      };
    }
    if (type === MENU_ITEMS.Operator) {
      attrs = {
        ...attrs,
        operator: true,
      };
    }
    if (type === MENU_ITEMS.WorkRequester) {
      attrs = {
        ...attrs,
        work_requester: true,
      };
    }
    if (type === MENU_ITEMS.ProductionSupervisor) {
      attrs = {
        ...attrs,
        production_supervisor: true,
      };
    }
    if (type === MENU_ITEMS.Archived) {
      attrs = {
        ...attrs,
        archived: true,
      };
    }
    if (type === MENU_ITEMS.ViewOnly) {
      attrs = {
        ...attrs,
        permission: 'view_only',
      };
    }
    return attrs;
  };

  fetchUsers = (params = {}) => {
    const { type } = queryString.parse(this.props.location.search);
    let attrs = {
      ...this.props.queryParameters,
      archived: false,
      ...this.getParamsForList(),
    };
    if (type === MENU_ITEMS.Operator) {
      attrs = {
        ...attrs,
        asset_operators: true,
      };
    }
    if (this.props.searchTerm) {
      attrs = {
        ...attrs,
        search: this.props.searchTerm,
      };
    }
    attrs = {
      ...attrs,
      ...params,
    };

    this.props
      .fetchUsers(this.props.system.id, attrs, listUsersRequest.getCancelTokenConfig())
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <FormattedMessage id="general.empty-data-set-search.title" />
    </div>
  );

  renderEmptyDataSet = () => {
    const { type } = queryString.parse(this.props.location.search);
    if (type === MENU_ITEMS.ProductionSupervisor) {
      return (
        <div className={styles['empty-data-set-container']}>
          <FormattedMessage id="screens.users.production-supervisors.empty-data-set.title" />
        </div>
      );
    } else if (type === MENU_ITEMS.Operator) {
      return (
        <div className={styles['empty-data-set-container']}>
          <FormattedMessage id="screens.users.operators.empty-data-set.title" />
        </div>
      );
    } else if (type === MENU_ITEMS.Archived) {
      return (
        <div className={styles['empty-data-set-container']}>
          <FormattedMessage id="screens.users.archived.empty-data-set.title" />
        </div>
      );
    } else if (type === MENU_ITEMS.ViewOnly) {
      return (
        <div className={styles['empty-data-set-container']}>
          <FormattedMessage id="screens.users.view-only.empty-data-set.title" />
        </div>
      );
    } else if (type === MENU_ITEMS.WorkRequester) {
      return (
        <div className={styles['empty-data-set-container']}>
          <FormattedMessage id="screens.users.work-requesters.empty-data-set.title" />
        </div>
      );
    }
    return null;
  };

  renderPagination = () => {
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isFetching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isFetching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.users.length}
          totalEntriesCount={this.props.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedUsers()}
        />
      );
    }
    return null;
  };

  renderListHeader = () => {
    const { selectedUsersCount, pageIsSelected, totalEntriesIsSelected } = this.props;
    const { isFetching } = this.state;
    return (
      <List.Header
        small
        background
        checkbox={this.props.isAdmin}
        showMultipleOptions={selectedUsersCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={isFetching}
            count={selectedUsersCount}
            buttons={
              <>
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="screens.users.mass-actions.change-permission" />}
                  onClick={() => this.setState({ showMassEditPermisisonModal: true })}
                />
              </>
            }
          />
        }
        checked={isFetching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isFetching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedUsers();
          } else {
            this.props.selectPage();
          }
        }}
      >
        <List.Header.Column flex>
          <FormattedMessage id="resources.user.name" />
        </List.Header.Column>
        <List.Header.Column width={367}>
          <FormattedMessage id="resources.user.permission" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderList = () => {
    const { type } = queryString.parse(this.props.location.search);
    if (this.state.isFetching) {
      const amountOfUsers = this.props.users.length === 0 ? 2 : this.props.users.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfUsers)
              .fill()
              .map(() => (
                <UserListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.users.length === 0) {
      if (this.props.searchTerm.length === 0) {
        return this.renderEmptyDataSet();
      }
      return this.renderSearchedEmptyDataset();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.users.map(({ id }) => {
            let checked = false;
            if (this.props.selectedUserIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <UserListItem
                key={id}
                id={id}
                showingOperators={type === MENU_ITEMS.Operator}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={e => {
                  this.props.selectUser(id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderMessage = () => {
    const { type } = queryString.parse(this.props.location.search);
    switch (type) {
      case MENU_ITEMS.Operator: {
        return (
          <div className={styles['message-container']}>
            <Banner orange>
              <FormattedMessage id="screens.users.operators.message" />
            </Banner>
          </div>
        );
      }
      case MENU_ITEMS.ProductionSupervisor: {
        return (
          <div className={styles['message-container']}>
            <Banner orange>
              <FormattedMessage id="screens.users.production-supervisors.message" />
            </Banner>
          </div>
        );
      }
      case MENU_ITEMS.WorkRequester: {
        return (
          <div className={styles['message-container']}>
            <Banner orange>
              <FormattedMessage id="screens.users.work-requesters.message" />
            </Banner>
          </div>
        );
      }
      case MENU_ITEMS.ViewOnly: {
        return (
          <div className={styles['message-container']}>
            <Banner orange>
              <FormattedMessage id="screens.users.view-only.message" />
            </Banner>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  renderMassEditPermissionsModal = () => {
    return (
      <MassEditPermissionModal
        open={this.state.showMassEditPermisisonModal}
        searchTerm={this.props.searchTerm}
        listParams={this.getParamsForList()}
        onSave={() => {
          this.props.resetSelectedUsers();
          this.setState({ isFetching: true });
          this.fetchUsers();
          this.setState({ showMassEditPermisisonModal: false });
        }}
        onClose={() => {
          this.setState({ showMassEditPermisisonModal: false });
        }}
      />
    );
  };

  renderSupervisorAccessModal = () => (
    <ManageProductionSupervisorPermissionsModal
      open={this.state.showSupervisorAccessModal}
      onClose={() => this.setState({ showSupervisorAccessModal: false })}
      settings={this.props.settings}
      loading={this.state.isSavingAccessChanges}
      onSave={val => {
        this.setState({ isSavingAccessChanges: true }, () => {
          this.props.updateSettings(this.props.system.id, val).then(() => {
            this.setState({ isSavingAccessChanges: false, showSupervisorAccessModal: false });
          });
        });
      }}
    />
  );

  renderTopBar = () => {
    const { type } = queryString.parse(this.props.location.search);
    switch (type) {
      case MENU_ITEMS.Operator: {
        return (
          <ListLayout.Content.MainContent.FilterBar>
            <ListLayout.Content.MainContent.FilterBar.RightContent>
              <Link to={`/settings/production-boards`}>
                <Button gray label="screens.users.operators.permissions-button" />
              </Link>
            </ListLayout.Content.MainContent.FilterBar.RightContent>
          </ListLayout.Content.MainContent.FilterBar>
        );
      }
      case MENU_ITEMS.ProductionSupervisor: {
        return (
          <ListLayout.Content.MainContent.FilterBar>
            <ListLayout.Content.MainContent.FilterBar.RightContent>
              <Button
                gray
                label="screens.users.production-supervisors.permissions-button"
                onClick={() => this.setState({ showSupervisorAccessModal: true })}
              />
            </ListLayout.Content.MainContent.FilterBar.RightContent>
          </ListLayout.Content.MainContent.FilterBar>
        );
      }
      default: {
        return null;
      }
    }
  };

  render() {
    return (
      <>
        <ListLayout.Content.MainContent>
          {this.renderTopBar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderMessage()}
            {this.renderList()}
          </ListLayout.Content.MainContent.Content>
          {this.renderPagination()}
        </ListLayout.Content.MainContent>
        {this.renderMassEditPermissionsModal()}
        {this.renderSupervisorAccessModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: UsersOperations.fetchUsers,
      addQueryParameter: UsersOperations.addQueryParameter,
      fetchAndSetAmountOfPayingUsers: UsersOperations.fetchAndSetAmountOfPayingUsers,
      selectUser: UsersOperations.selectUser,
      selectPage: UsersOperations.selectPage,
      selectTotalEntries: UsersOperations.selectTotalEntries,
      resetSelectedUsers: UsersOperations.resetSelectedUsers,
      hideSelectTotalEntries: UsersOperations.hideSelectTotalEntries,
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    isAdmin: AuthSelectors.isAdmin(state),
    users: UsersSelectors.getUsers(state),
    pagination: UsersSelectors.getUsersPagination(state),
    queryParameters: UsersSelectors.getUsersQueryParameters(state),
    selectedUserIds: UsersSelectors.getSelectedUserIds(state),
    totalEntries: UsersSelectors.getTotalEntries(state),
    totalEntriesIsSelected: UsersSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: UsersSelectors.getShowSelectTotalEntries(state),
    selectedUsersCount: UsersSelectors.getSelectedUsersCount(state),
    pageIsSelected: UsersSelectors.getPageIsSelected(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
