import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { AssetTitle } from 'views/components/Asset';
import { Icon, Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdding: false,
    };
  }

  addToBookmark = () => {
    this.setState({ isAdding: true });
    this.props
      .createAssetBookmark(this.props.system.id, {
        asset_id: this.props.asset.id,
      })
      .then(({ data }) => {
        this.props.onAddToBookmark({
          assetId: this.props.asset.id,
          assetBookmarkId: data.id,
        });
        this.setState({ isAdding: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      });
  };

  removeAssetFromUserBookmark = () => {
    this.setState({ isAdding: true });
    this.props.deleteAssetBookmark(this.props.assetBookmarkId).then(() => {
      this.props.onRemoveFromBookmark({
        assetId: this.props.asset.id,
        assetBookmarkId: this.props.assetBookmarkId,
      });
      this.setState({ isAdding: false });
      toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
    });
  };

  renderIcon = () => {
    if (this.props.assetBookmarkId) {
      return <Icon solid type="star" color="orange" />;
    }
    return <Icon regular type="star" color="gray" />;
  };

  renderLoader = () => {
    if (this.state.isAdding) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny centerInParent />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['item-node']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <AssetTitle id={this.props.asset.id} />
          </div>
        </div>
        <div
          className={styles['icon-container']}
          onClick={() => {
            if (this.props.assetBookmarkId) {
              this.removeAssetFromUserBookmark();
            } else {
              this.addToBookmark();
            }
          }}
        >
          {this.renderIcon(this.props.asset.id)}
          {this.renderLoader()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createAssetBookmark: SDKReduxOperations.createAssetBookmark,
      deleteAssetBookmark: SDKReduxOperations.deleteAssetBookmark,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    asset: EntitySelectors.getAsset(state, id),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetItem);
