import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer } from 'views/components/Shared/Layout';
import { SpentTimeModal } from 'views/components/WorkOrder';
import {
  WhiteCard,
  MoneyWithCurrency,
  List,
  Button,
  EmptyDataSet,
  Icon,
} from 'views/components/Shared/General';
import { DowntimeModal } from 'views/components/Downtime';
import { CostType } from 'sdk/Cost';
import Cost from './Cost';
import Header from '../../Header';
import CostModal from './CostModal';
import MaterialCostModal from './MaterialCostModal';
import styles from './style.module.scss';

class Costs extends Component {
  state = {
    showDowntimeModal: false,
    showDowntimeModalForDowntimeId: null,
    showCostModal: false,
    showCostModalForCostId: null,
    showMaterialCostModal: false,
    showMaterialCostModalForSparePartWithdrawalId: null,
    showWorkOrderSpentTimeModal: false,
    showWorkOrderSpentTimeModalForWorkOrderSpentTimeId: null,
    createdIds: [],
  };

  renderLaborCosts = () => {
    const laborCosts = this.props.costs.filter(({ category }) => category === CostType.Labor);
    if (laborCosts.length === 0) return null;
    return (
      <div className={styles['cost-type-container']}>
        <div className={styles['label']}>
          <FormattedMessage id="screens.work-order.costs.labor" />
        </div>
        <div className={styles['values']}>
          <List>
            {laborCosts.map(cost => (
              <Cost
                key={cost.id}
                costId={cost.id}
                new={this.state.createdIds.includes(cost.id)}
                onClick={cost => {
                  if (cost.work_order_spent_time_id) {
                    this.setState({
                      showWorkOrderSpentTimeModal: true,
                      showWorkOrderSpentTimeModalForWorkOrderSpentTimeId: cost.work_order_spent_time_id,
                    });
                  } else {
                    this.setState({
                      showCostModal: true,
                      showCostModalForCostId: cost.id,
                    });
                  }
                }}
              />
            ))}
          </List>
        </div>
      </div>
    );
  };

  renderPartsCosts = () => {
    const partCosts = this.props.costs.filter(({ category }) => category === CostType.Part);
    if (partCosts.length === 0) return null;
    return (
      <div className={styles['cost-type-container']}>
        <div className={styles['label']}>
          <FormattedMessage id="screens.work-order.costs.parts" />
        </div>
        <div className={styles['values']}>
          <List>
            {partCosts.map(cost => (
              <Cost
                key={cost.id}
                costId={cost.id}
                new={this.state.createdIds.includes(cost.id)}
                onClick={cost => {
                  if (cost.spare_part_withdrawal_id) {
                    this.setState({
                      showMaterialCostModal: true,
                      showMaterialCostModalForCostId: cost.id,
                    });
                  } else {
                    this.setState({
                      showCostModal: true,
                      showCostModalForCostId: cost.id,
                    });
                  }
                }}
              />
            ))}
          </List>
        </div>
      </div>
    );
  };

  renderDownTimeCosts = () => {
    const downtimeCosts = this.props.costs.filter(({ category }) => category === CostType.DownTime);
    if (downtimeCosts.length === 0) return null;
    return (
      <div className={styles['cost-type-container']}>
        <div className={styles['label']}>
          <FormattedMessage id="screens.work-order.costs.downtime" />
        </div>
        <div className={styles['values']}>
          <List>
            {downtimeCosts.map(cost => (
              <Cost
                key={cost.id}
                costId={cost.id}
                new={this.state.createdIds.includes(cost.id)}
                onClick={({ downtime_id }) => {
                  this.setState({
                    showDowntimeModal: true,
                    showDowntimeModalForDowntimeId: downtime_id,
                  });
                }}
              />
            ))}
          </List>
        </div>
      </div>
    );
  };

  renderOtherCosts = () => {
    const otherCosts = this.props.costs.filter(({ category }) => category === CostType.Other);
    if (otherCosts.length === 0) return null;
    return (
      <div className={styles['cost-type-container']}>
        <div className={styles['label']}>
          <FormattedMessage id="screens.work-order.costs.other" />
        </div>
        <div className={styles['values']}>
          <List>
            {otherCosts.map(cost => (
              <Cost
                key={cost.id}
                costId={cost.id}
                new={this.state.createdIds.includes(cost.id)}
                onClick={({ id }) => {
                  this.setState({
                    showCostModal: true,
                    showCostModalForCostId: id,
                  });
                }}
              />
            ))}
          </List>
        </div>
      </div>
    );
  };

  renderEmptyDataSet = () => (
    <WhiteCard centerContent padding="50px">
      <EmptyDataSet
        title={<FormattedMessage id="screens.work-order.costs.empty-dataset.title" />}
        subtitle={<FormattedMessage id="screens.work-order.costs.empty-dataset.subtitle" />}
        button={
          <Button
            primary
            label="screens.work-order.costs.empty-dataset.button"
            small
            onClick={() => this.setState({ showCostModal: true, showCostModalForCostId: null })}
          />
        }
        small
      />
    </WhiteCard>
  );

  renderCosts = () => {
    if (this.props.costs == null)
      return (
        <List>
          <List.Item>
            <List.Item.Column width={30}>
              <div className={styles['left-icon']}>
                <Icon regular type="dollar-sign" />
              </div>
            </List.Item.Column>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.Column width={30}>
              <div className={styles['left-icon']}>
                <Icon regular type="dollar-sign" />
              </div>
            </List.Item.Column>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    if (this.props.costs.length === 0) return this.renderEmptyDataSet();
    return (
      <>
        {this.renderLaborCosts()}
        {this.renderPartsCosts()}
        {this.renderDownTimeCosts()}
        {this.renderOtherCosts()}
        <div className={styles['button-container']}>
          <Button
            primary
            label="screens.work-order.costs.add-cost"
            onClick={() => this.setState({ showCostModal: true, showCostModalForCostId: null })}
          />
        </div>
      </>
    );
  };

  renderCostSummary = () => {
    const costs = this.props.costs || [];
    return (
      <WhiteCard padding="3px 25px 56px 25px">
        <div className={`${styles['cost']} ${styles['total']}`}>
          <p className={`${styles['money']} ${styles['total']}`}>
            <MoneyWithCurrency
              currency={this.props.currentSystem.currency}
              value={costs.reduce(
                (acc, cost) => acc.add(new Decimal(cost.system_amount || 0)),
                new Decimal(0)
              )}
              fractionSize={0}
              currencyFontSize={16}
            />
          </p>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.costs.total-cost" />
          </p>
        </div>
        <div className={styles['cost']}>
          <p className={styles['money']}>
            <MoneyWithCurrency
              currency={this.props.currentSystem.currency}
              value={costs
                .filter(({ category }) => category === CostType.Labor)
                .reduce((acc, cost) => acc.add(new Decimal(cost.system_amount || 0)), new Decimal(0))}
              fractionSize={0}
              currencyFontSize={16}
            />
          </p>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.costs.labor-cost" />
          </p>
        </div>
        <div className={styles['cost']}>
          <p className={styles['money']}>
            <MoneyWithCurrency
              currency={this.props.currentSystem.currency}
              value={costs
                .filter(({ category }) => category === CostType.Part)
                .reduce((acc, cost) => acc.add(new Decimal(cost.system_amount || 0)), new Decimal(0))}
              fractionSize={0}
              currencyFontSize={16}
            />
          </p>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.costs.part-cost" />
          </p>
        </div>
        {false ? (
          <div className={styles['cost']}>
            <p className={styles['money']}>
              <MoneyWithCurrency
                currency={this.props.currentSystem.currency}
                value={costs
                  .filter(({ category }) => category === CostType.DownTime)
                  .reduce((acc, cost) => acc.add(new Decimal(cost.system_amount || 0)), new Decimal(0))}
                fractionSize={0}
                currencyFontSize={16}
              />
            </p>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.costs.downtime-cost" />
            </p>
          </div>
        ) : null}
        <div className={styles['cost']}>
          <p className={styles['money']}>
            <MoneyWithCurrency
              currency={this.props.currentSystem.currency}
              value={costs
                .filter(({ category }) => category === CostType.Other)
                .reduce((acc, cost) => acc.add(new Decimal(cost.system_amount || 0)), new Decimal(0))}
              fractionSize={0}
              currencyFontSize={16}
            />
          </p>
          <p className={styles['title']}>
            <FormattedMessage id="screens.work-order.costs.other-cost" />
          </p>
        </div>
      </WhiteCard>
    );
  };

  renderContent = () => {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['costs']}>{this.renderCosts()}</div>
        <div className={styles['cost-summary']}>{this.renderCostSummary()}</div>
      </div>
    );
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer>{this.renderContent()}</ContentContainer>
        </PerfectScrollbar>
        <CostModal
          open={this.state.showCostModal}
          id={this.state.showCostModalForCostId}
          onClose={() => this.setState({ showCostModal: false })}
          onSaved={({ id }) => {
            this.setState({
              showCostModal: false,
              createdIds: [id, ...this.state.createdIds],
            });
          }}
        />
        <MaterialCostModal
          open={this.state.showMaterialCostModal}
          costId={this.state.showMaterialCostModalForCostId}
          onClose={() => this.setState({ showMaterialCostModal: false })}
        />
        <SpentTimeModal
          id={this.state.showWorkOrderSpentTimeModalForWorkOrderSpentTimeId}
          workOrderId={this.props.match.params.id}
          open={this.state.showWorkOrderSpentTimeModal}
          hideDeleteIcon
          onSaved={({ id }) => {
            this.setState({
              showWorkOrderSpentTimeModal: false,
            });
          }}
          onClose={() => {
            this.setState({ showWorkOrderSpentTimeModal: false });
          }}
        />
        <DowntimeModal
          open={this.state.showDowntimeModal}
          id={this.state.showDowntimeModalForDowntimeId}
          onClose={() => this.setState({ showDowntimeModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.match.params.id);
  return {
    costs: EntitySelectors.getCosts(state, workOrder.costs),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(Costs);
