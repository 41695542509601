import React from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ sparePartUnit, value, isPackage = false, valueStyle = null, abbreviationStyle = null }) => {
  if (value == null) return '-';

  if (isPackage) {
    return (
      <React.Fragment>
        <span style={valueStyle}>{`${value}`} </span>
        <span style={abbreviationStyle}>
          <FormattedMessage id="resources.spare-part-vendor.package-unit" />
        </span>
      </React.Fragment>
    );
  } else {
    if (sparePartUnit == null) return <span style={valueStyle}>{`${value}`}</span>;
    return (
      <React.Fragment>
        <span style={valueStyle}>{`${value}`} </span>
        <span style={abbreviationStyle}>{`${sparePartUnit.abbreviation}`}</span>
      </React.Fragment>
    );
  }
};
