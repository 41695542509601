import React, { Component } from 'react';
import { List } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { DueDateText, StatusColumn } from 'views/components/WorkOrder';
import { Icon, Button, NewInlineModal, Datapill } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

export default class WorkOrderItem extends Component {
  state = {
    dropdownOpen: false,
  };

  renderIconButtons = () => {
    return (
      <div className={styles['icon-buttons']}>
        <StatusColumn icon workOrder={this.props.workOrder} />
        <div className={styles['vertical-separator']} />
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.preventDefault();
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={250}
          position="right"
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ dropdownOpen: false });
                  this.props.onRemoveFromWorkOrder(this.props.workOrder.id);
                }}
              >
                <FormattedMessage id="components.downtime-modal.remove-from-downtime" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  render() {
    return (
      <List.Item
        small
        clickable
        linkTo={{
          pathname: `/work-orders/${this.props.workOrder.id}`,
        }}
        dataPillsComponent={
          this.props.workOrder.due_date ? (
            <Datapill value={<DueDateText workOrder={this.props.workOrder} />} />
          ) : null
        }
        iconButtons={this.renderIconButtons()}
      >
        <List.Item.TitleColumn
          title={
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.props.workOrder.title}</div>
              <div className={styles['number']}> - #{this.props.workOrder.number}</div>
            </div>
          }
          subtitle={
            this.props.workOrder.asset_id ? (
              <TreePath assetId={this.props.workOrder.asset_id} fullPath />
            ) : null
          }
        />
      </List.Item>
    );
  }
}
