import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const VendorContainer = ({ vendor }) => {
  return vendor.name;
};

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(VendorContainer);
