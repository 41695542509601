import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Decimal } from 'decimal.js';
import { List, Button, Field } from 'views/components/Shared/General';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  state = {
    inlineModalIsOpen: false,
    quantity: '',
  };

  renderExpandedComponent = () => {
    if (this.props.hasProTier === false)
      return (
        <div className={styles['expanded-container']}>
          <div className={styles['info']}>
            <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
              {this.props.sparePart.spare_part_location_id == null ? (
                '-'
              ) : (
                <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
              )}
            </Field>
          </div>
        </div>
      );
    return (
      <div className={styles['expanded-container']}>
        <div className={styles['info']}>
          {this.props.sparePartReservation ? (
            <Field view singleRow label={<FormattedMessage id="resources.spare-part.in-stock" />}>
              <SparePartUnitWithValue
                sparePartUnit={this.props.sparePartUnit}
                value={this.props.sparePart.stock_quantity}
              />
            </Field>
          ) : null}
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 30 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_thirty_days}
            />
          </Field>
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 90 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_ninety_days}
            />
          </Field>
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 180 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_hundred_and_eighty_days}
            />
          </Field>
          <Field view singleRow label={<FormattedMessage id="resources.spare-part.ordered-quantity" />}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.ordered_quantity}
            />
          </Field>
          <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
            {this.props.sparePart.spare_part_location_id == null ? (
              '-'
            ) : (
              <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
            )}
          </Field>
        </div>
      </div>
    );
  };

  renderAddButton = () => {
    if (this.props.disabled) {
      return <Button primary label="general.choose" disabled />;
    }
    return this.renderAddButtonInlineModal();
  };

  save = () => {
    if (!this.state.quantity) return null;

    this.props.onAddUsedSparePart({
      sparePart: this.props.sparePart,
      quantity: this.state.quantity,
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.quantity) {
      this.save();
    }
  };

  renderSubtitle = () => {
    if (this.props.hasProTier === false) return null;
    if (this.props.sparePartReservation) {
      const quantity = new Decimal(this.props.sparePartReservation.quantity);
      const withdrawedQuantity = new Decimal(this.props.sparePartReservation.withdrawed_quantity);

      return (
        <span>
          <FormattedMessage id="components.add-spare-parts-modal.reserved-label" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={quantity.minus(withdrawedQuantity)}
          />
        </span>
      );
    } else if (this.props.sparePart.stock_quantity == null) {
      return <FormattedMessage id="components.add-spare-parts-modal.not-being-tracked" />;
    } else {
      return (
        <span>
          <FormattedMessage id="resources.spare-part.in-stock" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.sparePart.stock_quantity}
          />
        </span>
      );
    }
  };

  renderRightColumn = () => {
    if (this.props.selectedSparePart) {
      return (
        <div className={styles['align-right']}>
          <Field
            view
            singleRow
            label={<FormattedMessage id="components.add-spare-parts-modal.already-added" />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.selectedSparePart.quantity}
            />
          </Field>
        </div>
      );
    } else {
      return (
        <div className={styles['align-right']}>
          <div className={styles['input']}>
            <Field.Decimal
              alignRight
              ref={ref => (this.decimalFieldRef = ref)}
              rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
              value={this.state.quantity}
              onKeyPress={this.handleKeyPress}
              onChange={quantity => {
                this.setState({ quantity });
              }}
              onBlur={quantity => {
                this.setState({ quantity });
              }}
            />
          </div>
          <Button
            primary
            disabled={!this.state.quantity}
            label="general.add"
            loading={this.state.isSaving}
            onClick={this.save}
          />
        </div>
      );
    }
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item loading expandable>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item expandable expandedComponent={this.renderExpandedComponent()}>
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            wrapTitle
            title={<Title inline sparePart={this.props.sparePart} />}
            subtitle={this.renderSubtitle()}
          />
          <List.Item.Column width={250} alignRight>
            {this.renderRightColumn()}
          </List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  let sparePart;
  let sparePartReservation;
  if (ownProps.sparePartReservationId) {
    sparePartReservation = EntitySelectors.getSparePartReservation(state, ownProps.sparePartReservationId);
    sparePart = EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id);
  } else {
    sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  }

  return {
    sparePart,
    sparePartReservation,
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    images: EntitySelectors.getImages(state, sparePart.images),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps)(SparePartListItem));
