import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import styles from './style.module.scss';
import { SDKReduxOperations } from 'sdk';
import { Icon, Datapill } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class TemplateAssetRecurrenceListItem extends Component {
  state = {
    isCreatingRecurrenceVersion: false,
  };

  getActiveRecurrenceVersion = () => {
    const today = moment().tz(this.props.system.timezone);

    return this.props.operationalMaintenanceTemplateRecurrenceVersions.find(version => {
      const from_date =
        version.generated_from_date == null || moment(version.generated_from_date).isSameOrBefore(today);

      const to_date =
        version.generated_to_date == null || moment(version.generated_to_date).isSameOrAfter(today);

      return from_date && to_date;
    });
  };

  renderEndDateDatapill = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion.to_date) {
      if (moment(activeVersion.to_date).isBefore(moment())) {
        return (
          <Datapill
            red
            value={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.interval-ended" />
            }
          />
        );
      }
      return (
        <Datapill
          red
          label={
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.interval-ends" />
          }
          value={moment(activeVersion.to_date).format('LL')}
        />
      );
    }
    return null;
  };

  renderTimeOfDayDatapill = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion.time_of_day) {
      return <Datapill value={moment(activeVersion.time_of_day, 'hh:mm').format('LT')} />;
    }
    return null;
  };

  renderDataPills = () => {
    const activeRecurrence = this.getActiveRecurrenceVersion();
    if (activeRecurrence == null) {
      return null;
    }
    const { to_date, time_of_day } = activeRecurrence;
    if (to_date || time_of_day) {
      return (
        <div className={styles['data-pills-container']}>
          <Datapill.List>
            {this.renderTimeOfDayDatapill()}
            {this.renderEndDateDatapill()}
          </Datapill.List>
        </div>
      );
    }
  };

  renderIntervalMessage = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion) {
      return buildIntervalMessage(activeVersion, this.props.intl);
    }
    return <FormattedMessage id="screens.operational-maintenance-template.settings.asset.no-recurrence" />;
  };

  render() {
    return (
      <>
        <div className={styles['item']} onClick={() => this.props.onShowSettingsForTemplateRecurrenceId()}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>{this.renderIntervalMessage()}</div>
            {this.renderDataPills()}
          </div>
          <div className={styles['navigation-container']}>
            <Icon light type="angle-right" size={24} />
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createOperationalMaintenanceTemplateVersionForRecurrence:
        SDKReduxOperations.createOperationalMaintenanceTemplateVersionForRecurrence,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;
  const operationalMaintenanceTemplateRecurrence =
    EntitySelectors.getOperationalMaintenanceTemplateRecurrence(state, id);
  return {
    operationalMaintenanceTemplateRecurrence,
    operationalMaintenanceTemplateRecurrenceVersions:
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersions(
        state,
        operationalMaintenanceTemplateRecurrence.operational_maintenance_template_recurrence_versions
      ),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TemplateAssetRecurrenceListItem));
