import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OperationalMaintenanceSelectors } from 'state/ducks/operationalMaintenance';
import { ChecklistTemplateRow } from 'views/components/Checklist';
import { AuthSelectors } from 'state/ducks/auth';

class Meter extends Component {
  render() {
    const { instanceRow, options } = this.props;
    return (
      <ChecklistTemplateRow.Meter
        editable={this.props.canCarryOutOperationalMaintenances}
        id={this.props.id}
        instanceRowId={instanceRow.id}
        key={instanceRow.id}
        options={options}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
    instanceRow: OperationalMaintenanceSelectors.getInstanceRowForTemplateRowId(state, ownProps.id),
    options: OperationalMaintenanceSelectors.getChecklistInstanceOptionsForTemplateRowId(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(Meter);
