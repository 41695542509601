import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { chunk } from 'lodash-es';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'views/components/WorkOrder';
import { ArchiveRequestModal } from 'views/components/Request';
import { Image } from 'views/components/Image';
import { Icon, Button, FullScreenImagePicker } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class PreviewRequestContent extends Component {
  state = {
    showArchiveRequestModal: false,
    showingImageDialog: false,
    currentImage: false,
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderCreatedAt = () => {
    return (
      <div className={`${styles['field']} ${styles['date']}`}>
        <Field
          small
          iconStyle="blue"
          icon={<Icon type="calendar-alt" withBackground blue backgroundSize={30} />}
          label={<FormattedMessage id="resources.request.created-at" />}
          value={moment(this.props.request.created_at).format('LLL')}
        />
      </div>
    );
  };

  renderCreatedBy = () => {
    return (
      <div className={`${styles['field']}`}>
        <Field
          small
          icon={<Icon type="user-alt" withBackground blue backgroundSize={30} />}
          label={<FormattedMessage id="resources.request.created-by" />}
          value={<UserNameWrapper user={this.props.createdByUser} />}
        />
      </div>
    );
  };

  renderAssetField = () => {
    const { asset } = this.props;
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="box-alt" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.request.asset" />}
          value={
            <>
              {asset.title}
              <TreePath assetId={asset.id} />
            </>
          }
        />
      </div>
    );
  };

  renderRequestTypeField = () => {
    const { requestType } = this.props;
    return (
      <div className={styles['field']}>
        <Field
          small
          icon={<Icon type="wrench" withBackground backgroundSize={30} />}
          label={<FormattedMessage id="resources.request.request-type" />}
          value={requestType.title}
        />
      </div>
    );
  };

  getFields = () => {
    const { requestType, asset } = this.props;
    let children = [];
    children = [...children, this.renderCreatedAt()];
    children = [...children, this.renderCreatedBy()];
    if (asset) {
      children = [...children, this.renderAssetField()];
    }
    if (requestType) {
      children = [...children, this.renderRequestTypeField()];
    }
    return children;
  };

  renderFields = () => {
    const fields = this.getFields();

    if (fields.length === 0) return null;
    const fieldRows = chunk(fields, 2);
    return (
      <>
        <div className={styles['fields']}>
          {fieldRows.map(fieldRow => (
            <div className={styles['field-row']}>{fieldRow.map(field => field)}</div>
          ))}
        </div>
      </>
    );
  };

  renderPriority = () => {
    switch (this.props.request.priority) {
      case 'low':
        return (
          <>
            <div className={`${styles['priority']} ${styles['low']}`}>
              <FormattedMessage id="resources.request.prio.low" />
            </div>
          </>
        );
      case 'medium':
        return (
          <>
            <div className={`${styles['priority']} ${styles['medium']}`}>
              <FormattedMessage id="resources.request.prio.medium" />
            </div>
          </>
        );
      case 'high':
        return (
          <>
            <div className={`${styles['priority']} ${styles['high']}`}>
              <FormattedMessage id="resources.request.prio.high" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  renderDescription = () => {
    const { images } = this.props;
    const { description, priority } = this.props.request;
    if ((images == null || images.length === 0) && description == null && priority == null) {
      return null;
    }
    return (
      <>
        <div className={styles['description-container']}>
          <div>{this.props.request.description}</div>
          {this.renderImages()}
          {this.renderPriority()}
        </div>
        <div className={styles['separator']} />
      </>
    );
  };

  renderImages = () => {
    if (this.props.images == null) {
      return (
        <div className={styles['images']}>
          <Image small loading />
          <Image small loading />
          <Image small loading />
        </div>
      );
    }

    if (this.props.images.length === 0) return null;
    return (
      <div className={styles['images']}>
        {this.props.images.map((image, index) => this.renderImage(image, index))}
      </div>
    );
  };

  renderImage = (image, index) => (
    <Image
      circle
      key={image.id}
      small
      editable={false}
      image={image}
      onClick={() => {
        this.selectImage(index);
      }}
    />
  );

  renderButtons = () => {
    if (this.props.editable === false) {
      return null;
    }
    return (
      <div className={styles['buttons']}>
        <div className={styles['buttons-separator']}>
          <div className={styles['circle']} />
          <div className={styles['circle']} />
          <div className={styles['circle']} />
        </div>
        <Button.Group>
          <Button
            primary
            label="components.preview-request.create-work-order"
            onClick={() => {
              const { id, registration_images, asset_id, priority, description } = this.props.request;
              this.props.onCreateWorkOrder({
                registration_images,
                asset_id,
                priority,
                description,
                requests: [id],
              });
            }}
          />
          <Button
            primary
            destructive
            label="components.preview-request.archive-request"
            onClick={() => this.setState({ showArchiveRequestModal: true })}
          />
        </Button.Group>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderDescription()}
        {this.renderFields()}
        {this.renderButtons()}
        <ArchiveRequestModal
          open={this.state.showArchiveRequestModal}
          id={this.props.request.id}
          onClose={() => {
            this.setState({ showArchiveRequestModal: false });
          }}
        />
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const request = EntitySelectors.getRequest(state, ownProps.id);

  return {
    request,
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
    asset: EntitySelectors.getAsset(state, request.asset_id),
    requestType: EntitySelectors.getRequestType(state, request.request_type_id),
    images: EntitySelectors.getImages(state, request.images),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps)(PreviewRequestContent));

PreviewRequestContent.propTypes = {
  id: PropTypes.string,
  editable: PropTypes.func,
  onCreateWorkOrder: PropTypes.func,
};

PreviewRequestContent.defaultProps = {
  id: null,
  onCreateWorkOrder: () => {},
  editable: false,
};
