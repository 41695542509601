import Title from './Title';
import Description from './Description';
import SparePartType from './SparePartType';
import SparePartUnit from './SparePartUnit';
import ReorderPoint from './ReorderPoint';
import MinQuantity from './MinQuantity';
import MaxQuantity from './MaxQuantity';
import StockQuantity from './StockQuantity';
import Vendor from './Vendor';
import VendorArticleNumber from './VendorArticleNumber';
import SparePartLocation from './SparePartLocation';
import SparePartField from './SparePartField';
import ArticleNumber from './ArticleNumber';

export default {
  ArticleNumber,
  Title,
  Description,
  SparePartType,
  SparePartUnit,
  ReorderPoint,
  MinQuantity,
  MaxQuantity,
  StockQuantity,
  SparePartLocation,
  Vendor,
  VendorArticleNumber,
  SparePartField,
};
