import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const showOrganisationSettings = (organisationId, params, config = null) =>
  request.get(
    `organisations/${organisationId}/settings?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const updateOrganisationSettings = (organisationId, data, config = null) =>
  request.patch(`organisations/${organisationId}/settings`, data, config);
