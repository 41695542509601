import React, { Component } from 'react';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import UpdateHeaderSwe from 'assets/images/productUpdate/UpdateHeaderSwe.png';
import UpdateHeaderEng from 'assets/images/productUpdate/UpdateHeaderEng.png';
import UpdateVideoFrame from 'assets/images/productUpdate/UpdateVideoFrame.png';
import UpdateFeature1 from 'assets/images/productUpdate/UpdateFeature1.png';
import UpdateFeature2 from 'assets/images/productUpdate/UpdateFeature2.png';
import UpdateFeature3 from 'assets/images/productUpdate/UpdateFeature3.png';

export default class NewsLetterModal extends Component {
  renderSweContent = () => (
    <div>
      <div className={styles['image-container']}>
        <img src={UpdateHeaderSwe} style={{ width: '100%' }} alt="UpdateHeaderSwe" />
      </div>
      <div className={styles['content']}>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          Vårvintern är här! Norra Sveriges femte årstid och vi säger hej till fler nya och förbättrade
          funktioner. Denna gång har vi filat på bättre utskrifter i appen, ökade behörigheter för operatör
          och arbetsledare samt möjligheten till olika timkostnad per tekniker. Hoppas det gillas!
          <br />
          <br />
          Uppdateringen når användarna den 28 mars.
        </div>
        <div className={styles['separator']} />
        <a href="https://6672520.hs-sites.com/share/hubspotvideo/161152330286" target="_blank">
          <img src={UpdateVideoFrame} style={{ borderRadius: 22, width: '100%' }} alt="UpdateVideoFrame" />
        </a>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature1} alt="UpdateFeature1" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>Nya utskriftmöjligheter i appen</div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>Nu kan ni direkt från appen skriva ut arbetsorder, arbetsbegäran, reservdelar och objekt.</li>
            <li>Ni kan även lista era QR-koder och skriva ut dem.</li>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature2} alt="UpdateFeature2" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>Ökade behörigheter* för licensen Operatör & Arbetsledare</div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>Nu kan operatörer få tillgång till alla filer på objektvyn.</li>
            <li>Operatörer och arbetsledare kan nu utföra saldojusteringar.</li>
            <br />
            <span style={{ color: 'gray', fontStyle: 'italic' }}>
              *Båda behörigheterna aktiveras via inställningssidan.
            </span>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature3} alt="UpdateFeature3" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>Tekniker med olika timdebitering</div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>
              För bättre kostnadsuppföljning kan ni numera registrera varierade timkostnader för olika
              tekniker.
            </li>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          Annat smått & gott
        </div>
        <div style={{ lineHeight: '173.182%', fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>Nu blir det enklare att avsluta driftsstopp när ni färdigställer en arbetsorder</li>
            <li>Möjlighet att filtrera era leverantörer (gäller desktop)</li>
            <li>Generella buggfixar och förbättringar på både app och desktop</li>
          </ul>
        </div>
        <div style={{ marginTop: 25 }}>
          <Button gray label="components.product-name-change-modal.button" onClick={this.props.onClose} />
        </div>
      </div>
    </div>
  );

  renderEngContent = () => (
    <div>
      <div className={styles['image-container']}>
        <img src={UpdateHeaderEng} style={{ width: '100%' }} alt="UpdateHeaderEng" />
      </div>
      <div className={styles['content']}>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          Spring has arrived! We are saying hello to new and improved features. This time we have improved
          exports in the app, increased permissions for operators and production supervisors, and added the
          ability to set individual labor costs per employee. Hope you like it!
          <br />
          <br />
          The updates will reach users on March 28th.
        </div>
        <div className={styles['separator']} />
        <a href="https://6672520.hs-sites.com/share/hubspotvideo/161152330286" target="_blank">
          <img src={UpdateVideoFrame} style={{ borderRadius: 22, width: '100%' }} alt="UpdateVideoFrame" />
        </a>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature1} alt="UpdateFeature1" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>New functionality to print in the app.</div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>Now you can print work orders, requests, spare parts, and assets directly from the app.</li>
            <li>You can also list your QR codes and print them.</li>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature2} alt="UpdateFeature2" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>
            Increased permissions* for the Operator & Production Supervisor licenses.
          </div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>Now operators can access all files in the asset view.</li>
            <li>Operators and production supervisors can also make stock adjustments.</li>
            <br />
            <span style={{ color: 'gray', fontStyle: 'italic' }}>
              *Both permissions are activated via the Settings page.
            </span>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          <img src={UpdateFeature3} alt="UpdateFeature3" style={{ marginLeft: -20 }} />
          <div style={{ marginLeft: 10 }}>Technicians with different labor costs</div>
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>
              For better cost tracking, you can now register varied hourly rates for different technicians.
            </li>
          </ul>
        </div>
        <div className={styles['separator']} />
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: 22 }}>
          Other features
        </div>
        <div style={{ lineHeight: '173.182%', fontSize: 14, fontWeight: 500 }}>
          <ul>
            <li>It's now easier to complete downtimes when completing a work order</li>
            <li>Option to filter your vendors (applies to desktop)</li>
            <li>General bug fixes and improvements on both the app and desktop</li>
          </ul>
        </div>
        <div style={{ marginTop: 25 }}>
          <Button gray label="components.product-name-change-modal.button" onClick={this.props.onClose} />
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <>
        <Modal width={730} isOpen={this.props.open}>
          <div
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
          >
            <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
          </div>
          {this.props.language === 'sv' ? this.renderSweContent() : this.renderEngContent()}
        </Modal>
      </>
    );
  }
}
