import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, API } from 'sdk';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, List, Icon, DatePicker } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import styles from './style.module.scss';
import { normalizePurchaseOrder } from 'sdk/Schemas';

class PurchaseOrderRowListItem extends Component {
  constructor(props) {
    super(props);
    const { estimated_delivery_date } = props.purchaseOrderRow;
    this.state = {
      isSaving: false,
      showSavedSuccess: false,
      estimated_delivery_date: estimated_delivery_date || null,
    };
  }

  updateEstimatedDeliveryDate = () => {
    this.setState({ isSaving: true, showSavedSuccess: false });
    this.props
      .updatePurchaseOrderRow(this.props.purchaseOrderRow.id, {
        estimated_delivery_date: this.state.estimated_delivery_date,
      })
      .then(() => {
        API.fetchPurchaseOrder(this.props.purchaseOrderRow.purchase_order_id).then(
          ({ data: purchaseOrder }) => {
            const { entities } = normalizePurchaseOrder(purchaseOrder);
            this.props.updateEntities(entities);
            this.setState({ isSaving: false });
            setTimeout(() => {
              this.setState({ showSavedSuccess: true });
              setTimeout(() => {
                this.setState({ showSavedSuccess: false });
              }, 1000);
            }, 150);
          }
        );
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderTitle = () => {
    if (this.props.purchaseOrderRow.number) {
      return (
        <span>
          <span>{this.props.purchaseOrderRow.title}</span>
          <span> - </span>
          <span className={styles['subtitle']}>#{this.props.purchaseOrderRow.number}</span>
        </span>
      );
    }
    return this.props.purchaseOrderRow.title;
  };

  renderFieldUnit = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return this.props.intl.formatMessage({ id: 'resources.spare-part-vendor.package-unit' });
    } else if (this.props.sparePartUnit != null) {
      return this.props.sparePartUnit.abbreviation;
    }
    return null;
  };

  renderPackageQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return (
        <span>
          <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
          <span>: </span>
          <SparePartUnitWithValue
            sparePartUnit={this.props.sparePartUnit}
            value={this.props.purchaseOrderRow.package_quantity}
          />
        </span>
      );
    }
    return null;
  };

  renderLoaderContainer = () => {
    let classNames = [styles['loader-container']];
    if (this.state.isSaving) {
      classNames = [...classNames, styles['show']];
    }
    return (
      <div className={classNames.join(' ')}>
        <Loader tiny />
      </div>
    );
  };

  renderIconContainer = () => {
    let classNames = [styles['icon-container']];
    if (this.state.showSavedSuccess) {
      classNames = [...classNames, styles['show']];
    }
    return (
      <div className={classNames.join(' ')}>
        <Icon regular type="check" size={15} green />
      </div>
    );
  };

  renderQuantity = () => {
    if (this.props.purchaseOrderRow.package_quantity) {
      return <SparePartUnitWithValue isPackage value={this.props.purchaseOrderRow.quantity} />;
    } else {
      return (
        <SparePartUnitWithValue
          sparePartUnit={this.props.sparePartUnit}
          value={this.props.purchaseOrderRow.quantity}
        />
      );
    }
  };

  render() {
    return (
      <List.Item>
        <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderPackageQuantity()} />
        <List.Item.Column width={100}>{this.renderQuantity()}</List.Item.Column>
        <List.Item.Column alignRight width={250}>
          <div className={styles['add-container']}>
            <div className={styles['left-container']}>
              {this.renderLoaderContainer()}
              {this.renderIconContainer()}
            </div>
            <Field.Date
              clearable
              onChangeDate={estimated_delivery_date =>
                this.setState({ estimated_delivery_date }, () => {
                  this.updateEstimatedDeliveryDate();
                })
              }
              value={this.state.estimated_delivery_date}
              footerComponent={
                <DatePicker.Footer
                  showClear={this.state.estimated_delivery_date != null}
                  onClear={() => {
                    this.setState({ estimated_delivery_date: null }, () => {
                      this.updateEstimatedDeliveryDate();
                    });
                  }}
                />
              }
            />
          </div>
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePurchaseOrderRow: SDKReduxOperations.updatePurchaseOrderRow,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.purchaseOrderRow.spare_part_unit),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderRowListItem);
