import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { SparePartsOperations } from 'state/ducks/spareParts';
import { AuthSelectors } from 'state/ducks/auth';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getTemplateFieldMenuItems = () => {
    return this.props.templateFields.map(templateField => ({
      label: templateField.title,
      onClick: () => {
        this.props.selectFilterType(`${FILTER_TYPES.SparePartField}.${templateField.id}`);
      },
    }));
  };

  getSparePartMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.title',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartTitle);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.article-number',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.ArticleNumber);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.description',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartDescription);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.category',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartType);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.unit',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartUnit);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.location',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartLocation);
        },
      },
    ];
    if (this.props.hasProTier) {
      menuItems = [
        ...menuItems,
        {
          label: this.props.intl.formatMessage({
            id: 'screens.spare-parts.filters.advanced.spare-part.in-stock',
          }),
          onClick: () => {
            this.props.selectFilterType(FILTER_TYPES.StockQuantity);
          },
        },
      ];
    }
    menuItems = [...menuItems, ...this.getTemplateFieldMenuItems()];
    return this.getFilteredMenuItems(menuItems);
  };

  getOrderMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.reorder-point',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.ReorderPoint);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.min-quantity',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.MinQuantity);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.max-quantity',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.MaxQuantity);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getVendorMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'screens.spare-parts.filters.advanced.vendor.vendor',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Vendor);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.spare-parts.filters.advanced.vendor.artnum',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.VendorArticleNumber);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderSparePartFilters = () => {
    const sparePartItems = this.getSparePartMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.spare-parts.filters.advanced.spare-part.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderOrderInformationFilters = () => {
    if (this.props.hasProTier) {
      const sparePartItems = this.getOrderMenuItems();
      if (sparePartItems.length === 0) {
        return null;
      }
      return (
        <SideModal.Container.Filter.Section>
          <SideModal.Container.Filter.Section.Header
            title={<FormattedMessage id="screens.spare-parts.filters.advanced.order-information.title" />}
          />
          <SideModal.Container.Filter.Section.Items>
            {sparePartItems.map(({ label, selected, onClick }) => (
              <SideModal.Container.Filter.Section.Items.Item
                label={label}
                selected={selected}
                onClick={onClick}
              />
            ))}
          </SideModal.Container.Filter.Section.Items>
        </SideModal.Container.Filter.Section>
      );
    }
    return null;
  };

  renderVendorFilters = () => {
    const sparePartItems = this.getVendorMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.spare-parts.filters.advanced.vendor.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [
      ...this.getSparePartMenuItems(),
      ...this.getOrderMenuItems(),
      ...this.getVendorMenuItems(),
    ];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return (
      <>
        {this.renderSparePartFilters()}
        {this.renderOrderInformationFilters()}
        {this.renderVendorFilters()}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.spare-parts.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: SparePartsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
