import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { List, Datapill, ViewTextArea } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { AssigneeColumn } from 'views/components/WorkOrder';
import { HelperFunctions } from 'sdk';
import { StatusColumn, Title } from 'views/components/Request';
import { UserNameWrapper } from 'views/components/User';
import { PriorityText } from 'views/components/WorkOrder';
import { LIST_TYPES } from 'sdk/Request';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class RequestListItem extends Component {
  renderPriority = () => {
    switch (this.props.request.priority) {
      case 'low':
        return (
          <>
            <div className={`${styles['priority']} ${styles['low']}`}>
              <FormattedMessage id="resources.request.prio.low" />
            </div>
          </>
        );
      case 'medium':
        return (
          <>
            <div className={`${styles['priority']} ${styles['medium']}`}>
              <FormattedMessage id="resources.request.prio.medium" />
            </div>
          </>
        );
      case 'high':
        return (
          <>
            <div className={`${styles['priority']} ${styles['high']}`}>
              <FormattedMessage id="resources.request.prio.high" />
            </div>
          </>
        );
      default:
        return '-';
    }
  };

  renderRequestType = () => {
    if (this.props.requestType) {
      return this.props.requestType.title;
    }
    return '-';
  };

  renderWorkOrderDueDate = () => {
    if (this.props.workOrder && this.props.workOrder.due_date) {
      return moment(this.props.workOrder.due_date).format('LL');
    }
    return '-';
  };

  renderWorkOrderAssignees = () => {
    if (this.props.workOrder && this.props.workOrder.due_date) {
      return (
        <AssigneeColumn
          userIds={this.props.workOrder.assigned_to_users}
          groupIds={this.props.workOrder.assigned_to_groups}
          vendorIds={this.props.workOrder.assigned_to_vendors}
        />
      );
    }
    return '-';
  };

  renderWorkOrderCompletedDate = () => {
    if (this.props.workOrder && this.props.workOrder.completed_date) {
      return moment(this.props.workOrder.completed_date).format('LL');
    }
    return '-';
  };

  renderWorkOrderCompletedByUser = () => {
    if (this.props.workOrderCompletedByUser) {
      return <UserNameWrapper user={this.props.workOrderCompletedByUser} />;
    }
    return '-';
  };

  renderArchivedDate = () => {
    if (this.props.request.archived_at) {
      return moment(this.props.request.archived_at).format('LL');
    }
    return '-';
  };

  renderArchivedByUser = () => {
    if (this.props.archivedByUser) {
      return <UserNameWrapper user={this.props.archivedByUser} />;
    }
    return '-';
  };

  renderListItemColumns = () => {
    switch (this.props.list) {
      case LIST_TYPES.ToPlan: {
        return (
          <>
            <List.Item.TitleColumn
              title={<Title request={this.props.request} />}
              subtitle={
                this.props.request.asset_id ? (
                  <TreePath assetId={this.props.request.asset_id} fullPath />
                ) : null
              }
            />
            <List.Item.Column width={150}>{this.renderPriority()}</List.Item.Column>
            <List.Item.Column width={205}>{this.renderRequestType()}</List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.WorkOrderCreated: {
        return (
          <>
            <List.Item.TitleColumn
              title={<Title request={this.props.request} />}
              subtitle={
                this.props.request.asset_id ? (
                  <TreePath assetId={this.props.request.asset_id} fullPath />
                ) : null
              }
            />
            <List.Item.Column width={150}>{this.renderWorkOrderDueDate()}</List.Item.Column>
            <List.Item.Column width={150}>{this.renderWorkOrderAssignees()}</List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn requestId={this.props.request.id} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.WorkOrderCompleted: {
        return (
          <>
            <List.Item.TitleColumn
              title={<Title request={this.props.request} />}
              subtitle={
                this.props.request.asset_id ? (
                  <TreePath assetId={this.props.request.asset_id} fullPath />
                ) : null
              }
            />
            <List.Item.Column width={150}>{this.renderWorkOrderCompletedDate()}</List.Item.Column>
            <List.Item.Column width={150}>{this.renderWorkOrderCompletedByUser()}</List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn requestId={this.props.request.id} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      case LIST_TYPES.Archive: {
        return (
          <>
            <List.Item.TitleColumn
              title={<Title request={this.props.request} />}
              subtitle={
                this.props.request.asset_id ? (
                  <TreePath assetId={this.props.request.asset_id} fullPath />
                ) : null
              }
            />
            <List.Item.Column width={150}>{this.renderArchivedDate()}</List.Item.Column>
            <List.Item.Column width={150}>{this.renderArchivedByUser()}</List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn requestId={this.props.request.id} />
              </div>
            </List.Item.Column>
          </>
        );
      }
      default: {
        return (
          <>
            <List.Item.TitleColumn
              title={<Title request={this.props.request} />}
              subtitle={
                this.props.request.asset_id ? (
                  <TreePath assetId={this.props.request.asset_id} fullPath />
                ) : null
              }
            />
            <List.Item.Column width={150}>{this.renderPriority()}</List.Item.Column>
            <List.Item.Column width={150}>{this.renderRequestType()}</List.Item.Column>
            <List.Item.Column borderLeft width={45}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StatusColumn requestId={this.props.request.id} />
              </div>
            </List.Item.Column>
          </>
        );
      }
    }
  };

  renderAssetNumberDataPill = () => {
    if (this.props.settings.asset_number_activated && this.props.asset) {
      return (
        <Datapill
          label={<FormattedMessage id="resources.asset.number-long" />}
          value={`#${this.props.asset.number}`}
        />
      );
    }
    return null;
  };

  renderPriorityDataPill = () => {
    if (this.props.request.priority) {
      return <Datapill value={<PriorityText priority={this.props.request.priority} />} />;
    }
    return null;
  };

  renderRequestTypeDataPill = () => {
    if (this.props.requestType) {
      return <Datapill value={this.props.requestType.title} />;
    }
    return null;
  };

  renderCreatedByDataPill = () => {
    if (this.props.createdByUser) {
      return <Datapill value={this.props.createdByUser.name} />;
    }
    return null;
  };

  renderHasDowntimeDataPill = () => {
    if (!this.props.request.downtime_id) {
      return null;
    }
    return <Datapill red value={<FormattedMessage id="resources.downtime.resource" />} />;
  };

  renderDataPills = () => {
    return (
      <div className={styles['data-pills-container']}>
        <Datapill.List>
          {this.renderPriorityDataPill()}
          {this.renderRequestTypeDataPill()}
          {this.renderCreatedByDataPill()}
          {this.renderAssetNumberDataPill()}
          {this.renderHasDowntimeDataPill()}
        </Datapill.List>
      </div>
    );
  };

  renderExpandableComponentText = () => {
    return this.props.request.description || '-';
  };

  renderExpandableComponent = () => {
    return (
      <div className={styles['expandable-container']}>
        <ViewTextArea>{this.renderExpandableComponentText()}</ViewTextArea>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small checkbox>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          small
          clickable
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkboxDisabled={this.props.checkboxDisabled}
          expandable
          expandedComponent={this.renderExpandableComponent()}
          dataPillsComponent={this.renderDataPills()}
          checkbox
          linkTo={{
            pathname: this.props.location.pathname,
            search: HelperFunctions.convertObjToQueryParameters({
              list: this.props.list,
              request_id: this.props.request.id,
            }),
          }}
        >
          {this.renderListItemColumns()}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const request = EntitySelectors.getRequest(state, ownProps.id);
  const workOrder = EntitySelectors.getWorkOrder(state, request.work_order_id);
  let props = {
    request,
    workOrder,
    asset: EntitySelectors.getAsset(state, request.asset_id),
    requestType: EntitySelectors.getRequestType(state, request.request_type_id),
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
    archivedByUser: EntitySelectors.getUser(state, request.archived_by_user_id),
    settings: AuthSelectors.getSettings(state),
  };
  if (workOrder) {
    props = {
      ...props,
      workOrderCompletedByUser: EntitySelectors.getUser(state, workOrder.completed_by_user_id),
    };
  }
  return props;
}

export default withRouter(connect(mapStateToProps)(RequestListItem));
