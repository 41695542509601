import { denormalizePermissionProfile } from 'sdk/Schemas';
import { pickBy } from 'lodash-es';

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getGroups = state => {
  const { settings, entities } = state;
  return settings.groups.ids.map(id => entities.groupById[id]);
};

const getCreatedGroups = state => {
  const { settings, entities } = state;
  return settings.groups.createdIds.map(id => entities.groupById[id]);
};

const isGroupListEmpty = state => state.settings.groups.listIsEmpty;

const getCreatedPermissionProfiles = state => {
  const { settings, entities } = state;
  return denormalizePermissionProfile(settings.permissionProfiles.createdIds, entities);
};

const getTemplateFields = (state, col) => {
  const { settings, entities } = state;
  return settings.templateFields.columns[col].map(id => entities.templateFieldById[id]);
};

const getSelectedTemplateField = state => state.settings.templateFields.selectedTemplateField;

const getTemplateFieldItemsForSelectedTemplateField = state => {
  const { entities } = state;
  const editingTemplateField = state.settings.templateFields.selectedTemplateField;
  return editingTemplateField == null
    ? []
    : editingTemplateField.template_field_items.map(id => entities.templateFieldItemById[id]);
};

const getPurchareOrderShippingAddresses = state => {
  const { settings, entities } = state;
  return settings.purchaseOrderShippingAddresses.ids.map(id => entities.purchaseOrderShippingAddressById[id]);
};

const getWorkOrderTypes = state => {
  const { settings, entities } = state;
  return settings.workOrderTypes.ids.map(id => entities.workOrderTypeById[id]);
};

const getDowntimeReasons = state => {
  const { settings, entities } = state;
  return settings.downtimeReasons.ids.map(id => entities.downtimeReasonById[id]);
};

const getCreatedWorkOrderTypes = state => {
  const { settings, entities } = state;
  return settings.workOrderTypes.createdIds.map(id => entities.workOrderTypeById[id]);
};

const getWorkOrderTypeSearchTerm = state => state.settings.workOrderTypes.searchTerm;

const getAssetTypes = state => {
  const { settings, entities } = state;
  return settings.assetTypes.ids.map(id => entities.assetTypeById[id]);
};

const getCreatedAssetTypes = state => {
  const { settings, entities } = state;
  return settings.assetTypes.createdIds.map(id => entities.assetTypeById[id]);
};

const getSparePartTypes = state => {
  const { settings, entities } = state;
  return settings.sparePartTypes.ids
    .map(id => entities.sparePartTypeById[id])
    .filter(
      sparePartType =>
        settings.sparePartTypes.searchTerm === '' ||
        sparePartType.title.toLowerCase().includes(settings.sparePartTypes.searchTerm.toLowerCase())
    );
};
const getCreatedSparePartTypes = state => {
  const { settings, entities } = state;
  return settings.sparePartTypes.createdIds
    .map(id => entities.sparePartTypeById[id])
    .filter(
      sparePartType =>
        settings.sparePartTypes.searchTerm === '' ||
        sparePartType.title.toLowerCase().includes(settings.sparePartTypes.searchTerm.toLowerCase())
    );
};
const getCreatedSparePartUnits = state => {
  const { settings, entities } = state;
  return settings.sparePartUnits.createdIds.map(id => entities.sparePartUnitById[id]);
};
const getSparePartTypesearchTerm = state => state.settings.sparePartTypes.searchTerm;

const getRequestTypes = state => {
  const { settings, entities } = state;
  return settings.requestTypes.ids.map(id => entities.requestTypeById[id]);
};

const getRequestSelectableAssignees = state => {
  const { settings, entities } = state;
  return settings.requestSelectableAssignees.ids.map(id => entities.requestSelectableAssigneeById[id]);
};

const getRequestAutomationRules = state => {
  const { settings, entities } = state;
  return settings.requestAutomationRules.ids.map(id => entities.requestAutomationRuleById[id]);
};

const getRequestAutomationGroups = state => {
  const { settings, entities } = state;
  return settings.requestAutomationGroups.ids.map(id => entities.groupById[id]);
};

const getCreatedRequestTypes = state => {
  const { settings, entities } = state;
  return settings.requestTypes.createdIds.map(id => entities.requestTypeById[id]);
};

const getSparePartLocationsForSparePartLocation = (state, sparePartLocationId) => {
  return Object.values(
    pickBy(
      state.entities.sparePartLocationById,
      sparePartLocation => sparePartLocation.spare_part_location_parent_id === sparePartLocationId
    )
  );
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

export default {
  getGroups,
  getRequestAutomationGroups,
  getCreatedGroups,
  isGroupListEmpty,
  getCreatedPermissionProfiles,
  getPurchareOrderShippingAddresses,
  getWorkOrderTypes,
  getCreatedWorkOrderTypes,
  getWorkOrderTypeSearchTerm,
  getTemplateFields,
  getTemplateFieldItemsForSelectedTemplateField,
  getSelectedTemplateField,
  getAssetTypes,
  getSparePartTypes,
  getCreatedSparePartUnits,
  getCreatedSparePartTypes,
  getSparePartTypesearchTerm,
  getCreatedAssetTypes,
  getRequestTypes,
  getRequestAutomationRules,
  getCreatedRequestTypes,
  getRequestSelectableAssignees,
  getSparePartLocationsForSparePartLocation,
  getDowntimeReasons,
};
