import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { normalizeSparePartAsset } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { ToastMessage, Modal, Grid } from 'views/components/Shared/Layout';
import { Loader, Icon, Field, Button } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import styles from './style.module.scss';

class SparePartAssetModal extends Component {
  state = {
    isSaving: false,
    showConfirmDeleteModal: false,
    sparePartParams: {
      quantity: '',
      comment: '',
    },
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.selectedSparePartAssetId !== null) {
        this.setState({
          sparePartParams: {
            quantity: this.props.sparePartAsset.quantity,
            comment: this.props.sparePartAsset.comment,
          },
        });
      } else {
        this.setState({
          sparePartParams: {},
        });
      }
    }
  }
  isLoaded = () =>
    (this.props.selectedSparePartAssetId || this.props.sparePartId) &&
    !this.props.loading &&
    this.props.sparePart;
  close = () => {
    if (this.props.selectedSparePartAssetId) {
      this.props.onClose('edit');
    } else {
      this.props.onClose();
    }
  };

  update = () => {
    this.setState({ isSaving: true });
    if (this.state.sparePartParams.title === '')
      this.setState({
        sparePartParams: { ...this.state.sparePartParams, title: this.props.sparePart.title },
      });
    const params = {
      ...this.state.sparePartParams,
      spare_part_id: this.props.sparePart.id,
      asset_id: this.props.asset.id,
    };
    this.props.updateSparePartAsset(this.props.sparePartAsset.id, params).then(res => {
      const { data } = res;
      const { entities } = normalizeSparePartAsset(data);
      this.props.updateEntities(entities);
      toast(
        <ToastMessage success text={<FormattedMessage id="screens.asset.spare-parts.update-success" />} />
      );
      this.close();
      this.setState({ isSaving: false });
    });
  };
  create = () => {
    this.setState({
      isSaving: true,
    });
    if (this.state.sparePartParams.title === '')
      this.setState({
        sparePartParams: { ...this.state.sparePartParams, title: this.props.sparePart.title },
      });
    const params = {
      ...this.state.sparePartParams,
      spare_part_id: this.props.sparePartId,
      asset_id: this.props.assetId,
    };
    this.props
      .createSparePartAsset(this.props.currentSystem.id, params)
      .then(res => {
        const { data } = res;
        const { entities } = normalizeSparePartAsset(data);
        this.props.updateEntities(entities);
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.asset.spare-parts.create-success" />} />
        );
        this.close();
        this.setState({ isSaving: false });
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderImage = () => {
    if (this.props.loading) {
      return (
        <div style={{ width: this.props.size, height: this.props.size }}>
          <ContentLoader viewBox={`0 0 ${this.props.size} ${this.props.size}`} preserveAspectRatio="xMinYMin">
            <circle cx={this.props.size / 2} cy={this.props.size / 2} r={this.props.size / 2} />
          </ContentLoader>
        </div>
      );
    }
    if (this.props.sparePart.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.sparePart.images[0]})`,
        }}
      />
    );
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.quantity" />}>
              <Field.Number
                disabled={!this.props.canEditAssets && !this.props.canEditSpareParts}
                rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
                value={this.state.sparePartParams.quantity}
                onChange={quantity =>
                  this.setState({ sparePartParams: { ...this.state.sparePartParams, quantity } })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.spare-part-asset.comment" />}>
              <Field.Textarea
                disabled={!this.props.canEditAssets && !this.props.canEditSpareParts}
                value={this.state.sparePartParams.comment}
                onChange={comment =>
                  this.setState({ sparePartParams: { ...this.state.sparePartParams, comment } })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.isLoaded) return <Loader />;
    else
      return (
        <>
          <Modal.Header
            ignoreLine
            title={
              this.props.canEditAssets && this.props.canEditSpareParts ? (
                this.props.selectedSparePartAssetId !== null ? (
                  <FormattedMessage id="screens.asset.spare-parts.spare-part-asset-modal.edit-title" />
                ) : (
                  <FormattedMessage id="screens.asset.spare-parts.spare-part-asset-modal.title" />
                )
              ) : (
                <FormattedMessage id="screens.asset.spare-parts.spare-part-asset-modal.view-title" />
              )
            }
            subtitle={
              <FormattedMessage
                id="screens.asset.spare-parts.spare-part-asset-modal.asset-name"
                values={{ asset: <span style={{ color: '#515151' }}>{this.props.asset.title}</span> }}
              />
            }
            onClose={() => this.close()}
          />
          <Modal.Content>
            {this.renderTitleField()}
            {this.renderFields()}
          </Modal.Content>
        </>
      );
  };

  renderTitleField = () => {
    return (
      <div className={styles['title-field']}>
        {this.renderImage()}
        <div className={styles['title']}>
          <Title sparePart={this.props.sparePart} />
        </div>
      </div>
    );
  };
  render() {
    if (!this.isLoaded()) return <Loader />;
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          {this.props.canEditAssets && this.props.canEditSpareParts ? (
            <Modal.Footer>
              <div className={styles['button-group']}>
                <Button.Group>
                  <Button
                    primary
                    loading={this.state.isSaving}
                    label="general.save"
                    onClick={() => {
                      this.props.selectedSparePartAssetId ? this.update() : this.create();
                    }}
                  />
                  <Button label="general.cancel" onClick={() => this.close()} />
                </Button.Group>
                {this.props.selectedSparePartAssetId ? (
                  <Button
                    primary
                    destructive
                    label="general.delete"
                    onClick={() => {
                      this.props.onDelete(this.props.sparePartAsset.id);
                      this.close();
                    }}
                  />
                ) : null}
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartAsset: SDKReduxOperations.updateSparePartAsset,
      createSparePartAsset: SDKReduxOperations.createSparePartAsset,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  var sparePart = null;
  var sparePartAsset = null;
  var asset = null;
  var sparePartUnit = null;
  if (ownProps.selectedSparePartAssetId) {
    sparePartAsset = EntitySelectors.getSparePartAsset(state, ownProps.selectedSparePartAssetId);
    sparePart = EntitySelectors.getSparePart(state, sparePartAsset.spare_part_id);
    asset = EntitySelectors.getAsset(state, sparePartAsset.asset_id);
  } else {
    sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
    asset = EntitySelectors.getAsset(state, ownProps.assetId);
  }
  if (sparePart != null)
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  return {
    sparePart,
    asset,
    sparePartUnit,
    sparePartAsset,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartAssetModal);
