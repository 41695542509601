import types from './types';

const setChecklistTemplateVersion = payload => ({
  type: types.SET_CHECKLIST_TEMPLATE_VERSION,
  payload: payload,
});

export default {
  setChecklistTemplateVersion,
};
