import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, ConfirmDeleteInlineModal, List, Icon } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';

const SparePartListItem = ({ id, sparePart, created, images, isDeleting, onDelete, isViewOnly }) => {
  return (
    <List.Item
      new={created}
      iconButtons={
        isViewOnly === false ? (
          <ConfirmDeleteInlineModal
            position="right"
            trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
            title={<FormattedMessage id="components.file-related-spare-parts.delete.title" />}
            subtitle={<FormattedMessage id="components.file-related-spare-parts.delete.description" />}
            loading={isDeleting}
            onDelete={onDelete}
          />
        ) : null
      }
    >
      <List.Item.ImageColumn imageId={images.length === 0 ? null : images[0].id} />
      <List.Item.TitleColumn title={<Title sparePart={sparePart} />} subtitle={sparePart.description} />
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  return {
    sparePart,
    images: EntitySelectors.getImages(state, sparePart.images),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default connect(mapStateToProps)(SparePartListItem);
