import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Tooltip, FormattedNumber } from 'views/components/Shared/General';

class DueDateText extends Component {
  renderMeterDueDate = () => {
    return (
      <span>
        <span>
          <span>
            <FormattedNumber
              value={this.props.workOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
            <span> </span>
            <span>
              (
              <FormattedMessage id="general.now" />
              <span> </span>
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
              )
            </span>
          </span>
        </span>
      </span>
    );
  };

  render() {
    if (this.props.workOrder.meter_id) {
      return this.renderMeterDueDate();
    }
    if (!this.props.workOrder.due_date) return null;

    let value;
    if (this.props.today) {
      if (moment().isSame(moment(this.props.workOrder.due_date), 'day')) {
        value = (
          <Tooltip
            trigger={
              <span>
                <FormattedMessage id="calendar.today" />
              </span>
            }
            label={<span>{moment(this.props.workOrder.due_date).format('LL')}</span>}
          />
        );
      } else {
        value = moment(this.props.workOrder.due_date).format('LL');
      }
    } else if (this.props.weekDay) {
      if (moment().isSame(moment(this.props.workOrder.due_date), 'week')) {
        value = (
          <Tooltip
            trigger={<span>{moment(this.props.workOrder.due_date).format('dddd')}</span>}
            label={<span>{moment(this.props.workOrder.due_date).format('LL')}</span>}
          />
        );
      } else {
        value = moment(this.props.workOrder.due_date).format('LL');
      }
    } else {
      value = moment(this.props.workOrder.due_date).format('LL');
    }

    return <span>{value}</span>;
  }
}

function mapStateToProps(state, ownProps) {
  let meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
  };
}

export default connect(mapStateToProps)(DueDateText);
