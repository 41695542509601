import { zipObject, map } from 'lodash-es';
import update from 'immutability-helper';

const init = () => {
  update.extend('$updateEntity', (newValue, originalObject) => {
    if (Array.isArray(newValue)) {
      const transformedObject = zipObject(
        newValue.map(attachment => attachment.id),
        map(newValue, attachment => attachment)
      );
      return originalObject == null ? { ...transformedObject } : { ...originalObject, ...transformedObject };
    } else {
      const transformedObject = {};
      transformedObject[newValue['id']] = newValue;
      return originalObject == null ? { ...transformedObject } : { ...originalObject, ...transformedObject };
    }
  });
};

export default {
  init,
};
