import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingSelectors } from 'state/ducks/stocktaking';
import SparePartListItem from '../../../Components/SparePartListItem';
import ProgressCircleBar from '../../../Components/ProgressCircleBar';
import styles from './style.module.scss';

class SparePartList extends Component {
  state = {
    focusIndex: null,
  };

  renderProgressBar = () => {
    if (this.props.stocktaking.completed || this.props.progressValue == null) return null;

    return <ProgressCircleBar againstGrayBackground value={this.props.progressValue} />;
  };

  renderHeader = () => {
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.stocktaking-detail.locations.spare-part-title" />
          </p>
          <p className={styles['subtitle']}>
            <FormattedMessage
              id="screens.stocktaking-detail.locations.spare-part-subtitle"
              values={{ count: this.props.loading ? 0 : this.props.totalEntries }}
            />
          </p>
        </div>
        {this.renderProgressBar()}
      </div>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column width={30} />
        <List.Header.Column width={70}>
          <FormattedMessage id="resources.spare-part.article-number-short" />
        </List.Header.Column>
        <List.Header.Column flex>
          <FormattedMessage id="resources.spare-part.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => {
    if (this.props.count && this.props.count.total_count === 0) return null;

    return (
      <>
        {this.renderHeader()}
        {this.renderListHeader()}
        <List>
          <SparePartListItem loading />
        </List>
      </>
    );
  };

  renderList = () => {
    if (!this.props.sparePartIds || this.props.sparePartIds.length === 0) return null;
    return (
      <>
        {this.renderHeader()}
        {this.renderListHeader()}
        <List>
          {this.props.sparePartIds.map((id, index) => (
            <SparePartListItem
              key={id}
              sparePartId={id}
              setFocus={this.state.focusIndex === index}
              onListWasSaved={() => {
                this.setState({ focusIndex: index + 1 });
              }}
            />
          ))}
          {this.props.loadingMore ? (
            <React.Fragment>
              <SparePartListItem loading />
              <SparePartListItem loading />
            </React.Fragment>
          ) : null}
        </List>
      </>
    );
  };

  render() {
    if (this.props.loading) return this.renderLoader();
    return this.renderList();
  }
}

function mapStateToProps(state, ownProps) {
  const queryParams = queryString.parse(ownProps.location.search);

  return {
    sparePartIds: StocktakingSelectors.getSparePartIds(state),
    totalEntries: StocktakingSelectors.getSparePartsTotalEntries(state),
    paginateFrom: StocktakingSelectors.getSparePartsPaginateFrom(state),
    isFullyLoaded: StocktakingSelectors.getSparePartsIsFullyLoaded(state),
    loading: StocktakingSelectors.getSparePartsLoading(state),
    loadingMore: StocktakingSelectors.getSparePartsLoadingMore(state),
    count: StocktakingSelectors.getCountForLocation(state, queryParams.parentId),
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartList));
