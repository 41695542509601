import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeOperationalMaintenanceBreak, normalizeWorkOrder } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createOperationalMaintenanceBreak(systemId, params) {
  return dispatch => {
    return API.createOperationalMaintenanceBreak(systemId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceBreak(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { systemId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_BREAK_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateOperationalMaintenanceBreak(operationalMaintenanceBreakId, params) {
  return dispatch => {
    return API.updateOperationalMaintenanceBreak(operationalMaintenanceBreakId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceBreak(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { operationalMaintenanceBreakId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_BREAK_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteOperationalMaintenanceBreak(operationalMaintenanceBreakId) {
  return dispatch => {
    return API.deleteOperationalMaintenanceBreak(operationalMaintenanceBreakId)
      .then(res => {
        const payload = { operationalMaintenanceBreakId };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_BREAK_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
