import React, { Component } from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, FormattedNumber } from 'views/components/Shared/General';
import { SelectedAssignees } from 'views/components/WorkOrder';
import { Grid, Modal } from 'views/components/Shared/Layout';
import { ReminderType, ReminderRecipientType } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import ChooseRecipientInlineModal from './ChooseRecipientInlineModal';
import RecipientUserContainer from './RecipientUserContainer';
import styles from './style.module.scss';

class EditReminderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showExactDateRequiredError: false,
      showDaysBeforeDropdownValue: true,
      showMeterValueBeforeDropdownValue: true,
      reminder_type: ReminderType.None,
      reminder_recipient_user_id: null,
      reminder_recipient_type: null,
      reminder_date_exact: null,
      reminder_days_relative_to_due_date: null,
      reminder_relative_to_meter: null,
      reminder_comment: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        reminder_days_relative_to_due_date,
        reminder_relative_to_meter,
        reminder_date_exact,
        reminder_type,
        reminder_recipient_type,
        reminder_recipient_user_id,
        reminder_comment,
      } = this.props.editingWorkOrder;

      let showDaysBeforeDropdownValue = true;
      if (reminder_type === ReminderType.RelativeToDueDate && reminder_days_relative_to_due_date > 0) {
        showDaysBeforeDropdownValue = false;
      }

      let showMeterValueBeforeDropdownValue = true;
      if (reminder_type === ReminderType.RelativeToMeterReading && reminder_relative_to_meter > 0) {
        showMeterValueBeforeDropdownValue = false;
      }

      let reminderType = reminder_type;
      if (this.isRecurringMaintenance() && reminder_type === ReminderType.Exact) {
        reminderType = ReminderType.None;
      }

      this.setState({
        showExactDateRequiredError: false,
        showDaysBeforeDropdownValue,
        showMeterValueBeforeDropdownValue,
        reminder_type: reminderType,
        reminder_recipient_type,
        reminder_date_exact,
        reminder_recipient_user_id,
        reminder_days_relative_to_due_date: Math.abs(reminder_days_relative_to_due_date),
        reminder_relative_to_meter: Math.abs(reminder_relative_to_meter),
        reminder_comment,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  isRecurringMaintenance = () => {
    if (this.props.editingWorkOrder.frequency) {
      return true;
    }
    if (this.props.editingWorkOrder.meter_interval) {
      return true;
    }
    return false;
  };

  save = () => {
    const {
      reminder_type,
      reminder_date_exact,
      reminder_days_relative_to_due_date,
      reminder_relative_to_meter,
      reminder_recipient_type,
      reminder_recipient_user_id,
      reminder_comment,
    } = this.state;
    let params = {
      reminder_type,
      reminder_recipient_type,
      reminder_recipient_user_id,
      reminder_comment,
    };
    if (reminder_type === ReminderType.Exact) {
      if (!reminder_date_exact) {
        this.setState({ showExactDateRequiredError: true });
        return;
      }
      params = {
        ...params,
        reminder_date_exact,
      };
    } else if (reminder_type === ReminderType.RelativeToDueDate) {
      if (this.state.showDaysBeforeDropdownValue) {
        params = {
          ...params,
          reminder_days_relative_to_due_date: -Math.abs(reminder_days_relative_to_due_date),
        };
      } else {
        params = {
          ...params,
          reminder_days_relative_to_due_date: Math.abs(reminder_days_relative_to_due_date),
        };
      }
    } else if (reminder_type === ReminderType.RelativeToMeterReading) {
      if (this.state.showMeterValueBeforeDropdownValue) {
        params = {
          ...params,
          reminder_relative_to_meter: -Math.abs(reminder_relative_to_meter),
        };
      } else {
        params = {
          ...params,
          reminder_relative_to_meter: Math.abs(reminder_relative_to_meter),
        };
      }
    } else {
      params = {
        ...params,
        reminder_comment: null,
      };
    }
    this.props.onSave(params);
    this.props.onClose();
  };

  renderDaysBeforeOrAfter = () => {
    if (this.state.showDaysBeforeDropdownValue) {
      return <FormattedMessage id="components.reminder-modal.days-before" />;
    }
    return <FormattedMessage id="components.reminder-modal.days-after" />;
  };

  renderMeterValueBeforeOrAfter = () => {
    if (this.state.showMeterValueBeforeDropdownValue) {
      return <FormattedMessage id="components.reminder-modal.before" />;
    }
    return <FormattedMessage id="components.reminder-modal.after" />;
  };

  renderRelativeToDueDateField = () => {
    return (
      <Field label={<FormattedMessage id="components.reminder-modal.send-reminder" />}>
        <div className={styles['dropdown-field-container']}>
          <div className={styles['value-field']}>
            <Field.Number
              value={this.state.reminder_days_relative_to_due_date}
              onChange={reminder_days_relative_to_due_date => {
                this.setState({
                  reminder_days_relative_to_due_date,
                });
              }}
              onBlur={reminder_days_relative_to_due_date => {
                this.setState({
                  reminder_days_relative_to_due_date,
                });
              }}
            />
          </div>
          <div className={styles['dropdown-field']}>
            <Field.Dropdown clearable={false} value={this.renderDaysBeforeOrAfter()}>
              <Field.Dropdown.Item
                selected={this.state.showDaysBeforeDropdownValue}
                onClick={() => this.setState({ showDaysBeforeDropdownValue: true })}
              >
                <FormattedMessage id="components.reminder-modal.days-before" />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={!this.state.showDaysBeforeDropdownValue}
                onClick={() => this.setState({ showDaysBeforeDropdownValue: false })}
              >
                <FormattedMessage id="components.reminder-modal.days-after" />
              </Field.Dropdown.Item>
            </Field.Dropdown>
          </div>
        </div>
      </Field>
    );
  };

  renderRelativeToDueMeterReading = () => {
    return (
      <Field label={<FormattedMessage id="components.reminder-modal.send-reminder" />}>
        <div className={styles['dropdown-field-container']}>
          <div className={styles['value-field']}>
            <Field.Number
              rightLabel={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              value={this.state.reminder_relative_to_meter}
              onChange={reminder_relative_to_meter => {
                this.setState({
                  reminder_relative_to_meter,
                });
              }}
              onBlur={reminder_relative_to_meter => {
                this.setState({
                  reminder_relative_to_meter,
                });
              }}
            />
          </div>
          <div className={styles['dropdown-field']}>
            <Field.Dropdown clearable={false} value={this.renderMeterValueBeforeOrAfter()}>
              <Field.Dropdown.Item
                selected={this.state.showMeterValueBeforeDropdownValue}
                onClick={() => this.setState({ showMeterValueBeforeDropdownValue: true })}
              >
                <FormattedMessage id="components.reminder-modal.before" />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={!this.state.showMeterValueBeforeDropdownValue}
                onClick={() => this.setState({ showMeterValueBeforeDropdownValue: false })}
              >
                <FormattedMessage id="components.reminder-modal.after" />
              </Field.Dropdown.Item>
            </Field.Dropdown>
          </div>
        </div>
      </Field>
    );
  };

  renderExactDateField = () => {
    const tomorrow = moment().add(1, 'day').tz(this.props.currentSystem.timezone).toDate();

    return (
      <Field label={<FormattedMessage id="components.reminder-modal.send-reminder" />}>
        <Field.Date
          error={this.state.showExactDateRequiredError}
          disabledDays={{
            before: tomorrow,
          }}
          value={this.state.reminder_date_exact}
          onChangeDate={reminder_date_exact => {
            this.setState({
              reminder_date_exact,
              showExactDateRequiredError: false,
            });
          }}
          onClear={() => {
            this.setState({
              reminder_date_exact: null,
            });
          }}
        />
      </Field>
    );
  };

  renderReminderField = () => {
    switch (this.state.reminder_type) {
      case ReminderType.RelativeToDueDate: {
        return this.renderRelativeToDueDateField();
      }
      case ReminderType.RelativeToMeterReading: {
        return this.renderRelativeToDueMeterReading();
      }
      case ReminderType.Exact: {
        return this.renderExactDateField();
      }
      default:
        return null;
    }
  };

  renderRelativeToDueDate = () => {
    if (this.props.editingWorkOrder.meter_id) {
      return null;
    }
    return (
      <Field.Radio
        label={<FormattedMessage id="components.reminder-modal.options.relative-to-due-date" />}
        checked={this.state.reminder_type === ReminderType.RelativeToDueDate}
        onChange={() => this.setState({ reminder_type: ReminderType.RelativeToDueDate })}
      />
    );
  };

  renderRelativeToMeter = () => {
    if (this.props.editingWorkOrder.meter_id) {
      return (
        <Field.Radio
          label={<FormattedMessage id="components.reminder-modal.options.relative-to-meter-value" />}
          checked={this.state.reminder_type === ReminderType.RelativeToMeterReading}
          onChange={() => this.setState({ reminder_type: ReminderType.RelativeToMeterReading })}
        />
      );
    }
    return null;
  };

  renderRecipientFieldValue = () => {
    if (this.state.reminder_recipient_type === ReminderRecipientType.Assignees) {
      return (
        <>
          <div>
            <FormattedMessage id="components.reminder-modal.assignees-label" />
          </div>
          <div className={styles['selected-asignees']}>
            <SelectedAssignees
              userIds={this.props.editingWorkOrder.assigned_to_users}
              groupIds={this.props.editingWorkOrder.assigned_to_groups}
              vendorIds={this.props.editingWorkOrder.assigned_to_vendors}
            />
          </div>
        </>
      );
    }
    return <RecipientUserContainer id={this.state.reminder_recipient_user_id} />;
  };

  renderComments = () => {
    return (
      <>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <FormattedMessage id="resources.work-order.comment" />
            <Field.Textarea
              value={this.state.reminder_comment}
              onChange={value => this.setState({ reminder_comment: value })}
            />
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  renderRecipientField = () => {
    if (this.state.reminder_type === ReminderType.None) {
      return null;
    }
    return (
      <div className={styles['recipient-field']}>
        <Field
          label={<FormattedMessage id="components.reminder-modal.send-to" />}
          description={<FormattedMessage id="components.reminder-modal.not-sending-to-vendor-info-text" />}
        >
          <ChooseRecipientInlineModal
            editingWorkOrder={this.props.editingWorkOrder}
            reminderRecipientType={this.state.reminder_recipient_type}
            reminderRecipientUserId={this.state.reminder_recipient_user_id}
            trigger={<Field.Resource angleDown value={this.renderRecipientFieldValue()} clearable={false} />}
            onChange={params => {
              this.setState({
                ...params,
              });
            }}
          />
        </Field>
      </div>
    );
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox.Group>
            <Field.Radio
              label={<FormattedMessage id="components.reminder-modal.options.none" />}
              checked={this.state.reminder_type === ReminderType.None}
              onChange={() => this.setState({ reminder_type: ReminderType.None })}
            />
            {this.renderRelativeToMeter()}
            {this.renderRelativeToDueDate()}
            {this.isRecurringMaintenance() ? null : (
              <Field.Radio
                label={<FormattedMessage id="components.reminder-modal.options.exact" />}
                checked={this.state.reminder_type === ReminderType.Exact}
                onChange={() => this.setState({ reminder_type: ReminderType.Exact })}
              />
            )}
          </Field.Checkbox.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {this.renderRecipientField()}
          {this.renderReminderField()}
          {this.renderExtraValues()}
        </Grid.Column>
      </Grid.Row>
      {this.state.reminder_type === ReminderType.None ? null : this.renderComments()}
    </Grid>
  );

  getReminderAtMeterReadingValue = () => {
    if (this.state.showMeterValueBeforeDropdownValue === true) {
      try {
        return new Decimal(this.props.editingWorkOrder.due_meter_value)
          .toDecimalPlaces(2)
          .minus(new Decimal(this.state.reminder_relative_to_meter).toDecimalPlaces(2))
          .toString();
      } catch (e) {
        return this.props.editingWorkOrder.due_meter_value;
      }
    }
    try {
      return new Decimal(this.props.editingWorkOrder.due_meter_value)
        .toDecimalPlaces(2)
        .plus(new Decimal(this.state.reminder_relative_to_meter).toDecimalPlaces(2))
        .toString();
    } catch (e) {
      return this.props.editingWorkOrder.due_meter_value;
    }
  };

  getReminderAtRelativeToDueDateValue = () => {
    if (!this.props.editingWorkOrder.date) {
      return '-';
    }
    if (this.state.showDaysBeforeDropdownValue === true) {
      return moment(this.props.editingWorkOrder.date)
        .subtract(this.state.reminder_days_relative_to_due_date, 'days')
        .format('LL');
    }
    return moment(this.props.editingWorkOrder.date)
      .add(this.state.reminder_days_relative_to_due_date, 'days')
      .format('LL');
  };

  renderExtraValues = () => {
    const { date, due_meter_value } = this.props.editingWorkOrder;
    if (this.state.reminder_type === ReminderType.Exact && date) {
      return (
        <div className={styles['extra-values']}>
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            {this.props.editingWorkOrder.date ? moment(this.props.editingWorkOrder.date).format('LL') : '-'}
          </Field>
        </div>
      );
    }
    if (this.state.reminder_type === ReminderType.RelativeToMeterReading && due_meter_value) {
      return (
        <div className={styles['extra-values']}>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="components.reminder-modal.reminder-sent-at" />}
          >
            <FormattedNumber
              value={this.getReminderAtMeterReadingValue()}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            <FormattedNumber
              value={new Decimal(this.props.editingWorkOrder.due_meter_value || 0)
                .toDecimalPlaces(2)
                .toString()}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="screens.work-order.meter-current-value" />}
          >
            <FormattedNumber
              value={this.props.meter.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
        </div>
      );
    }
    if (this.state.reminder_type === ReminderType.RelativeToDueDate && date) {
      return (
        <div className={styles['extra-values']}>
          {this.state.reminder_days_relative_to_due_date ? (
            <Field
              singleRow
              view
              fontSize={12}
              label={<FormattedMessage id="components.reminder-modal.reminder-sent-at" />}
            >
              {this.getReminderAtRelativeToDueDateValue()}
            </Field>
          ) : null}
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            {this.props.editingWorkOrder.date ? moment(this.props.editingWorkOrder.date).format('LL') : '-'}
          </Field>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={501}>
        <Modal.Header
          ignoreLine
          title={
            this.props.editingWorkOrder.reminder_type === ReminderType.None ? (
              <FormattedMessage id="components.reminder-modal.title" />
            ) : (
              <FormattedMessage id="components.reminder-modal.title-existing" />
            )
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { meter_id } = ownProps.editingWorkOrder;
  const meter = EntitySelectors.getMeter(state, meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(EditReminderModal);
