import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const WorkOrderTypeContainer = ({ workOrderType }) => {
  return workOrderType.title;
};

function mapStateToProps(state, ownProps) {
  return {
    workOrderType: EntitySelectors.getWorkOrderType(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(WorkOrderTypeContainer);
