import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Button, Icon } from 'views/components/Shared/General';
import ContentLoader from 'react-content-loader';

export default class Resource extends Component {
  renderIcon = () => {
    if (this.props.leftComponent) {
      return <div className={styles['icon-container']}>{this.props.leftComponent}</div>;
    }
    if (!this.props.icon) return null;

    return <div className={styles['icon-container']}>{this.props.icon}</div>;
  };

  renderAngleDownIcon = () => (
    <div className={styles['angle-down-container']}>
      <Icon regular type="angle-down" />
    </div>
  );

  renderClearIcon = () => (
    <div
      className={styles['clear-container']}
      onClick={e => {
        e.stopPropagation();
        this.props.onClear();
      }}
    >
      <Button type="icon" iconButtonSize={24} icon={<Icon light size={16} type="times" />} />
    </div>
  );

  renderRightIcon = () => {
    if (this.props.clearable && this.props.value) {
      return this.renderClearIcon();
    } else if (this.props.angleDown && !this.props.icon) {
      return this.renderAngleDownIcon();
    }
  };

  renderContent = () => {
    if (this.props.value) {
      return <div className={styles['value']}>{this.props.value}</div>;
    }
    return <div className={styles['placeholder']}>{this.props.placeholder}</div>;
  };

  renderLoading = () => (
    <div className={styles['input']}>
      <div className={styles['loader']}>
        <ContentLoader width="100%" height="100%">
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>
  );

  render() {
    if (this.props.loading) {
      return this.renderLoading();
    }

    let additionalStyles = {};
    let classNames = [styles['input']];

    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    if (this.props.focus) {
      classNames = [...classNames, styles['focus']];
    }
    if (this.props.icon || this.props.leftComponent) {
      classNames = [...classNames, styles['has-icon']];
    }
    if (this.props.alignRight) {
      classNames = [...classNames, styles['align-right']];
    }
    if (this.props.padding) {
      additionalStyles = { ...styles, padding: this.props.padding };
    }

    return (
      <div className={classNames.join(' ')} style={additionalStyles} tabIndex="0">
        {this.renderIcon()}
        {this.renderContent()}
        {this.renderRightIcon()}
      </div>
    );
  }
}

Resource.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.node,
  focus: PropTypes.bool,
  error: PropTypes.bool,
  angleDown: PropTypes.bool,
  alignRight: PropTypes.bool,
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  padding: PropTypes.string,
  placeholder: PropTypes.node,
};
Resource.defaultProps = {
  focus: false,
  alignRight: false,
  error: false,
  angleDown: false,
  clearable: true,
  padding: null,
  placeholder: null,
  onClear: () => {},
};
