import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import HelperFunctions from 'utilities/HelperFunctions';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderOperations } from 'state/ducks/workOrder';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import NotFound from './NotFound';
import Edit from './Edit';
import View from './View';

class WorkOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.WorkOrders);
    this.fetchWorkOrder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchWorkOrder();
    }
  }

  fetchWorkOrder = () => {
    this.props
      .fetchWorkOrderForView(this.props.match.params.id)
      .then(workOrder => {
        const { system_id } = workOrder;
        if (system_id !== this.props.currentSystem.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(workOrder.title);
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return (
        <PerfectScrollbar>
          <NotFound />
        </PerfectScrollbar>
      );
    } else if (!this.props.workOrder) {
      return <Loader centerInParent />;
    } else {
      let paths = ['/work-orders/:id', '/work-orders/:id/activities', '/work-orders/:id/history'];
      if (this.props.currentSystem.costs_activated) {
        paths = [...paths, '/work-orders/:id/costs'];
      }
      return (
        <Switch key={this.props.match.params.id}>
          <Route path={paths} exact component={View} />
          {!this.props.canEditWorkOrder ? null : (
            <Route path={`/work-orders/:id/edit`} exact component={Edit} />
          )}
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrderForView: WorkOrderOperations.fetchWorkOrderForView,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  return {
    workOrder,
    canEditWorkOrder: AuthSelectors.canEditWorkOrder(state, workOrder),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderDetail));
