import { uniq } from 'lodash-es';
/*
  --------------------
    DATA SELECTORS
  --------------------
*/
const getUserIds = state => {
  const { calendar } = state;
  return calendar.userIds;
};

const getGroupIds = state => {
  const { calendar } = state;
  return calendar.groupIds;
};

const getVendorIds = state => {
  const { calendar } = state;
  return uniq([...calendar.vendorIds, ...calendar.temporaryVendorIds]);
};

const getUsersById = state => {
  const { calendar } = state;
  return calendar.calendarInstanceIdsForUser;
};

const getGroupsById = state => {
  const { calendar } = state;
  return calendar.calendarInstanceIdsForGroup;
};

const getVendorsById = state => {
  const { calendar } = state;
  return calendar.calendarInstanceIdsForVendor;
};

const getInstance = (state, id) => {
  const { calendar } = state;
  return calendar.instanceById[id];
};

const getInstancesForUserAtDate = (state, userId, date) => {
  const { calendar } = state;
  if (calendar.calendarInstanceIdsForUser[userId] && calendar.calendarInstanceIdsForUser[userId][date]) {
    return calendar.calendarInstanceIdsForUser[userId][date].map(id => calendar.instanceById[id]);
  }
  return [];
};

const getInstancesForGroupAtDate = (state, groupId, date) => {
  const { calendar } = state;
  if (calendar.calendarInstanceIdsForGroup[groupId] && calendar.calendarInstanceIdsForGroup[groupId][date]) {
    return calendar.calendarInstanceIdsForGroup[groupId][date].map(id => calendar.instanceById[id]);
  }
  return [];
};

const getInstancesForVendorAtDate = (state, vendorId, date) => {
  const { calendar } = state;
  if (
    calendar.calendarInstanceIdsForVendor[vendorId] &&
    calendar.calendarInstanceIdsForVendor[vendorId][date]
  ) {
    return calendar.calendarInstanceIdsForVendor[vendorId][date].map(id => calendar.instanceById[id]);
  }
  return [];
};

const getHoveringInstanceId = state => {
  const { calendar } = state;
  return calendar.hoveringCalendarInstanceId;
};

const getDraggingWorkOrderId = state => {
  const { calendar } = state;
  return calendar.isDraggingWorkOrderId;
};

const getDraggingRequestId = state => {
  const { calendar } = state;
  return calendar.isDraggingRequestId;
};

const getDraggingRecurringMaintenanceId = state => {
  const { calendar } = state;
  return calendar.isDraggingRecurringMaintenanceId;
};

const getMeterBasedWorkOrdersToPlan = state => {
  const { calendar, entities } = state;
  return calendar.toolbox.to_plan.meterBasedWorkOrderIds.map(id => entities.workOrderById[id]);
};

const getWorkOrdersToPlan = state => {
  const { calendar, entities } = state;
  return calendar.toolbox.to_plan.workOrderIds.map(id => entities.workOrderById[id]);
};

const getOverdueWorkOrders = state => {
  const { calendar, entities } = state;
  return calendar.toolbox.overdue.workOrderIds.map(id => entities.workOrderById[id]);
};

const getWorkOrdersAwaitingDelivery = state => {
  const { calendar, entities } = state;
  return calendar.toolbox.awaiting_delivery.workOrderIds.map(id => entities.workOrderById[id]);
};

const getRequests = state => {
  const { calendar, entities } = state;
  return calendar.toolbox.request.requestIds.map(id => entities.requestById[id]);
};

const getPaginateFromForWorkOrdersToPlan = state => {
  const { calendar } = state;
  return calendar.toolbox.to_plan.paginateFrom;
};

const getPaginateFromForOverdueWorkOrders = state => {
  const { calendar } = state;
  return calendar.toolbox.overdue.paginateFrom;
};

const getPaginateFromForRequests = state => {
  const { calendar } = state;
  return calendar.toolbox.request.paginateFrom;
};

const canFetchMoreWorkOrdersToPlan = state => {
  const { calendar } = state;
  return calendar.toolbox.to_plan.canFetchMore;
};

const canFetchMoreOverdueWorkOrders = state => {
  const { calendar } = state;
  return calendar.toolbox.overdue.canFetchMore;
};

const canFetchMoreRequests = state => {
  const { calendar } = state;
  return calendar.toolbox.request.canFetchMore;
};

const getAmountToPlan = state => {
  const { calendar } = state;
  return calendar.toolbox.to_plan.count;
};

const getAmountOverdue = state => {
  const { calendar } = state;
  return calendar.toolbox.overdue.count;
};

const getAmountAwaitingSpareParts = state => {
  const { calendar } = state;
  return calendar.toolbox.awaiting_delivery.count;
};

const getAmountOfRequests = state => {
  const { calendar } = state;
  return calendar.toolbox.request.count;
};

const getPreviewWorkOrderOverlayData = state => {
  const { calendar } = state;
  return calendar.workOrderPreviewOverlay;
};

const getPreviewRequestOverlayData = state => {
  const { calendar } = state;
  return calendar.requestPreviewOverlay;
};

const isShowingList = state => {
  const { calendar } = state;
  return calendar.toolbox.showList;
};

const getToolboxListType = state => {
  const { calendar } = state;
  return calendar.toolbox.listType;
};

const getEstimatedTimeForDay = (state, calendarInstances) => {
  const { entities } = state;
  return calendarInstances.reduce((acc, instance) => {
    if (instance.type === 'work_order') {
      const workOrder = entities.workOrderById[instance.work_order];
      return acc + workOrder.estimated_minutes;
    } else {
      const recurringMaintenance = entities.recurringMaintenanceById[instance.recurring_maintenance];
      return acc + recurringMaintenance.estimated_minutes;
    }
  }, 0);
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/

export default {
  getPreviewWorkOrderOverlayData,
  getPreviewRequestOverlayData,
  getWorkOrdersAwaitingDelivery,
  getAmountOfRequests,
  getUserIds,
  getGroupIds,
  getVendorIds,
  getInstancesForUserAtDate,
  getInstancesForGroupAtDate,
  getInstancesForVendorAtDate,
  getHoveringInstanceId,
  getUsersById,
  getGroupsById,
  getVendorsById,
  getMeterBasedWorkOrdersToPlan,
  getWorkOrdersToPlan,
  getOverdueWorkOrders,
  getRequests,
  getDraggingRequestId,
  getDraggingWorkOrderId,
  getDraggingRecurringMaintenanceId,
  getAmountToPlan,
  getAmountOverdue,
  getAmountAwaitingSpareParts,
  getEstimatedTimeForDay,
  getInstance,
  isShowingList,
  getToolboxListType,
  getPaginateFromForWorkOrdersToPlan,
  getPaginateFromForOverdueWorkOrders,
  getPaginateFromForRequests,
  canFetchMoreWorkOrdersToPlan,
  canFetchMoreOverdueWorkOrders,
  canFetchMoreRequests,
};
