import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { List, ViewTextArea } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { TreePath } from 'views/components/Asset';
import { AssigneeColumn, DueDateColumn, StatusColumn, TitleColumn } from 'views/components/WorkOrder';
import styles from './style.module.scss';

class WorkOrderListItem extends Component {
  renderSubtitle = () => {
    if (this.props.workOrder.asset_id) {
      return <TreePath assetId={this.props.workOrder.asset_id} fullPath />;
    }
    return null;
  };

  renderDateColumn = () => {
    switch (this.props.list) {
      case 'completed':
        return moment(this.props.workOrder.completed_date).format('LL');

      default:
        return <DueDateColumn workOrder={this.props.workOrder} />;
    }
  };

  renderExpandableComponentText = () => {
    if (this.props.workOrder.completed_before_registration) {
      return this.props.workOrder.completed_comment || '-';
    }
    return this.props.workOrder.description || '-';
  };

  renderExpandableComponent = () => {
    return (
      <div className={styles['expandable-container']}>
        <ViewTextArea>{this.renderExpandableComponentText()}</ViewTextArea>
      </div>
    );
  };

  renderListItem = () => {
    return (
      <List.Item
        small
        clickable
        onClick={this.props.onClick}
        expandable
        expandedComponent={this.renderExpandableComponent()}
      >
        <TitleColumn workOrder={this.props.workOrder} />
        <List.Item.Column width={140}>{this.renderDateColumn()}</List.Item.Column>
        <List.Item.Column width={72} alignRight>
          <AssigneeColumn
            userIds={this.props.workOrder.assigned_to_users}
            groupIds={this.props.workOrder.assigned_to_groups}
            vendorIds={this.props.workOrder.assigned_to_vendors}
          />
        </List.Item.Column>
        <List.Item.Column borderLeft width={45}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StatusColumn icon workOrder={this.props.workOrder} />
          </div>
        </List.Item.Column>
      </List.Item>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small expandable>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return this.renderListItem();
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    workOrder: EntitySelectors.getWorkOrder(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(WorkOrderListItem);
