import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { MeterType, Type, generateEmptyChecklistTemplateRow } from 'sdk/ChecklistTemplateRow';
import { SDKReduxOperations } from 'sdk';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import ChecklistTemplateRow from '../../..';
import styles from './style.module.scss';

const dropTarget = {
  drop(props, monitor, component) {
    const { id: toSection, isCreatingChecklistFromTemplatesLibrary } = props;
    const { id: draggedId, index: fromIndex, parent: fromSection } = monitor.getItem();
    const isNew = monitor.getItem().id === 'new';
    const draggingType = monitor.getItem().type;
    if (draggingType === Type.Section) {
      props.showSectionCantBeDroppedInModal();
      return;
    }
    let meterType = MeterType.Option;
    if (isCreatingChecklistFromTemplatesLibrary === false) {
      meterType = MeterType.Fixed;
    }
    if (isNew) {
      props.prepareNewFieldForEdit({
        ...generateEmptyChecklistTemplateRow(),
        meter_type: meterType,
        type: monitor.getItem().type,
        sort: 1,
        parent_row_id: toSection,
        id: draggedId,
      });
      props.addNewFieldToSection({
        toSectionId: toSection,
        id: draggedId,
      });
      return;
    }
    if (fromSection) {
      props.moveFieldsBetweenSections({
        fromSectionId: fromSection,
        toSectionId: toSection,
        id: draggedId,
        fromIndex: fromIndex,
        toIndex: 0,
      });
    } else {
      props.moveFieldFromRootToSection({
        sectionId: toSection,
        id: draggedId,
        fromIndex: fromIndex,
        toIndex: 0,
      });
    }
    props.updateChecklistTemplateRow(draggedId, { parent_row_id: toSection, sort: 1 });
  },
};

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

class ChecklistTemplateRows extends Component {
  renderEmptySection = () => {
    const { draggingChecklistTemplateRow, isOver, draggingNewFieldType } = this.props;
    let classNames = [styles['empty']];
    if (isOver) {
      if (draggingChecklistTemplateRow && draggingChecklistTemplateRow.type !== Type.Section) {
        classNames = [...classNames, styles['is-over']];
      }
      if (draggingNewFieldType && draggingNewFieldType !== Type.Section) {
        classNames = [...classNames, styles['is-over']];
      }
    }
    return this.props.connectDropTarget(<div className={classNames.join(' ')} />);
  };

  renderSectionWithRows = () => {
    return this.props.fields.map(({ id, parent_row_id, type }, index) => (
      <ChecklistTemplateRow id={id} parent={this.props.id} index={index} type={type} key={id} />
    ));
  };

  render() {
    if (this.props.id === 'new' || this.props.fields.length === 0) {
      return this.renderEmptySection();
    }
    return this.renderSectionWithRows();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      moveFieldFromRootToSection: ChecklistEditModalOperations.moveFieldFromRootToSection,
      moveFieldsBetweenSections: ChecklistEditModalOperations.moveFieldsBetweenSections,
      addNewFieldToSection: ChecklistEditModalOperations.addNewFieldToSection,
      prepareNewFieldForEdit: ChecklistEditModalOperations.prepareNewFieldForEdit,
      updateChecklistTemplateRow: SDKReduxOperations.updateChecklistTemplateRow,
      showSectionCantBeDroppedInModal: ChecklistEditModalOperations.showSectionCantBeDroppedInModal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.id === 'new') {
    return {};
  }
  return {
    fields: ChecklistEditModalSelectors.getChildFieldsForField(state, ownProps.id),
    draggingChecklistTemplateRow: ChecklistEditModalSelectors.getDraggingChecklistTemplateRow(state),
    draggingNewFieldType: ChecklistEditModalSelectors.getNewDraggingFieldType(state),
    isCreatingChecklistFromTemplatesLibrary:
      ChecklistEditModalSelectors.isCreatingChecklistFromTemplatesLibrary(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropTarget('checklist', dropTarget, dropCollect)(injectIntl(ChecklistTemplateRows)));
