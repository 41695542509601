import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions } from 'sdk';
import { CalendarSelectors, CalendarOperations } from 'state/ducks/calendar';
import styles from '../style.module.scss';
import RequestListItem from './RequestListItem';

const request = HelperFunctions.getCancelTokenForRequest();

class Requests extends Component {
  state = {
    isFetching: true,
    isFetchingMore: false,
    paginateFrom: null,
  };

  componentDidMount() {
    this.fetchRequests()
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requests.length >= 20 && this.props.requests.length < 20) {
      this.fetchMoreRequests();
    }
  }

  componentWillUnmount() {
    request.cancel();
  }

  fetchRequests = (params = {}) => {
    return this.props.fetchRequests(this.props.currentSystem.id, params, request.getCancelTokenConfig());
  };

  fetchMoreRequests = () => {
    if (this.state.isFetchingMore || this.state.isFetching || !this.props.canFetchMoreRequests) return;
    this.setState({ isFetchingMore: true });
    this.fetchRequests({ paginate_from: this.props.paginateFrom })
      .then(() => {
        this.setState({ isFetchingMore: false });
      })
      .catch(() => {});
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny />
        </div>
      );
    }
    if (this.props.requests.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['separator']} />
          <div className={styles['text']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.requests.empty-data-set.title" />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['list']}>
        {this.props.requests.map((request, index) => (
          <RequestListItem index={index} id={request.id} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <InfiniteScroll
        loadMore={this.fetchMoreRequests}
        hasMore={this.props.canFetchMoreRequests}
        loader={
          this.state.isFetchingMore ? (
            <div className={styles['loader-container']}>
              <Loader tiny />
            </div>
          ) : null
        }
        useWindow={false}
        initialLoad={false}
        threshold={150}
      >
        <div className={styles['header-container']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.calendar.toolbox.lists.requests.title" />
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={() => {
                  this.props.hideListBar();
                }}
              />
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.requests.subtitle" />
          </div>
        </div>
        {this.renderContent()}
      </InfiniteScroll>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideListBar: CalendarOperations.hideListBar,
      fetchRequests: CalendarOperations.fetchRequests,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    requests: CalendarSelectors.getRequests(state),
    paginateFrom: CalendarSelectors.getPaginateFromForRequests(state),
    canFetchMoreRequests: CalendarSelectors.canFetchMoreRequests(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
