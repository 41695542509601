import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mutateTree } from '@atlaskit/tree/';
import { isEqual } from 'lodash-es';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeAsset } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { buildAtlaskitTree, updateTreeAssetIds } from 'sdk/Asset';
import { EmptyDataSet, Icon, Tooltip, Loader } from 'views/components/Shared/General';
import { Tree } from 'views/components/Asset';
import { API } from 'sdk';
import TreeLarge from 'assets/images/EmptyDataSet/TreeLarge.png';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class TreeContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEmpty: false,
      tree: [],
      isFetchingAssetId: null,
    };
  }

  componentDidMount() {
    API.fetchTree(this.props.currentSystem.id, {
      ...this.props.queryParams,
      archived: false,
    }).then(res => {
      const { data } = res;
      const { entities } = normalizeAsset(data);
      this.props.updateEntities(entities);
      this.setState({
        loading: false,
        data,
        isEmpty: data.length === 0,
        tree: buildAtlaskitTree(data, { expandedIds: this.props.expandedIds }),
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.selectedAssetObjectIds, this.props.selectedAssetObjectIds)) {
      const updatedAssetIds = {
        ...prevProps.selectedAssetObjectIds,
        ...this.props.selectedAssetObjectIds,
      };
      this.setState({ tree: updateTreeAssetIds(this.state.tree, Object.keys(updatedAssetIds)) });
    }
  }

  expandTree = nodeId => {
    this.props.onExpand(nodeId);
    this.setState({
      tree: mutateTree(this.state.tree, nodeId, { isExpanded: true }),
    });
  };

  collapseTree = nodeId => {
    this.props.onCollapse(nodeId);
    this.setState({
      tree: mutateTree(this.state.tree, nodeId, { isExpanded: false }),
    });
  };

  selectAsset = asset => {
    if (this.state.isFetchingAssetId != null) return;
    this.setState({
      isFetchingAssetId: asset.id,
      tree: updateTreeAssetIds(this.state.tree, [asset.id]),
    });
    API.fetchAsset(asset.id)
      .then(({ data: asset }) => {
        const { entities } = normalizeAsset(asset);
        this.props.updateEntities(entities);
        this.setState({ isFetchingAssetId: null });
        this.props.onSelectAsset(asset.id);
      })
      .catch(() => {
        this.setState({ isFetchingAssetId: null });
      });
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="components.tree-modal.empty-data-set.title" />}
      image={TreeLarge}
      small
      modalContainer
    />
  );

  renderTreeNode = item => {
    const { asset } = item.data;
    const disabled = this.props.inactiveAssetObjectIds[asset.id] === true;
    const selected = this.props.selectedAssetObjectIds[asset.id] === true;
    const { isFetchingAssetId } = this.state;
    return (
      <div
        className={`${styles['item-node']} ${disabled ? styles['disabled'] : ''}`}
        onClick={() => {
          if (disabled) return;
          this.selectAsset(asset);
        }}
      >
        <div className={styles['title-container']}>
          <p className={styles['title']}>{asset.title}</p>
          {this.props.settings.asset_number_activated ? (
            <p className={styles['subtitle']}>#{asset.number}</p>
          ) : null}
        </div>
        {selected && isFetchingAssetId !== asset.id ? <Icon regular green type="check" /> : null}
        {isFetchingAssetId === asset.id ? <Loader tiny /> : null}
        {disabled ? (
          <Tooltip
            label={
              <FormattedMessage id="components.file-add-related-assets.already-selected-popup-content" />
            }
            trigger={
              <div className={styles['disabled-container-icon']}>
                <Icon regular type="info-circle" />
              </div>
            }
          />
        ) : null}
      </div>
    );
  };

  render() {
    if (this.state.loading)
      return (
        <div className={styles['loader-container']}>
          <Loader small />
        </div>
      );
    if (this.state.isEmpty) return this.renderEmptyDataset();
    return (
      <div className={styles['container']}>
        <Tree
          tree={this.state.tree}
          onExpand={this.expandTree}
          onCollapse={this.collapseTree}
          renderNode={item => this.renderTreeNode(item)}
          isDragEnabled={false}
          isNestingEnabled
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStatetoProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}
export default connect(mapStatetoProps, mapDispatchToProps)(TreeContent);
