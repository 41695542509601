import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { bindActionCreators } from 'redux';
import { normalizeUser } from 'sdk/Schemas';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Button, Field, FieldErrorWrapper } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ResendInviteModal extends Component {
  getInitialState = () => ({
    isSendingInvite: false,
    showEmailField: false,
    showEmailRequiredError: false,
    showUniqueEmailError: false,
    email: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), email: this.props.user.email });
    }
  }

  sendInvite = () => {
    if (this.state.email.length === 0) {
      this.setState({ showEmailRequiredError: true });
      return;
    }
    this.setState({ isSendingInvite: true });
    let params = { invite_resend_email: true };
    if (this.state.showEmailField) {
      params = {
        ...params,
        email: this.state.email,
      };
    }
    this.props
      .updateUser(this.props.user.id, params)
      .then(res => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.users.resend-invite-modal.invite-has-been-sent-success" />}
          />
        );
        const { entities } = normalizeUser(res.data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'email' })) {
          this.setState({ showUniqueEmailError: true });
        }
        this.setState({ isSendingInvite: false });
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.resend-invite-modal.title" />}
          subtitle={
            <FormattedMessage
              id="screens.users.resend-invite-modal.subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div className={styles['info-box']}>
            <FormattedMessage
              id="screens.users.resend-invite-modal.invite-sent"
              values={{
                email: <span className={styles['email']}>{this.props.user.email}</span>,
              }}
            />
          </div>
          <Button
            type="text"
            primary
            label="screens.users.resend-invite-modal.change-email"
            noUnderline
            fontSize={12}
            onClick={() => this.setState({ showEmailField: true })}
          />
          <AnimateHeight
            duration={150}
            height={this.state.showEmailField ? 'auto' : 0}
            onAnimationEnd={() => this.emailRef.focus()}
          >
            <Grid style={{ marginTop: 20 }}>
              <Grid.Row>
                <Grid.Column>
                  <FieldErrorWrapper
                    show={this.state.showUniqueEmailError}
                    errorElement={
                      <FormattedMessage id="screens.users.resend-invite-modal.unique-email-error" />
                    }
                  >
                    <Field.Text
                      error={this.state.showUniqueEmailError || this.state.showEmailRequiredError}
                      ref={ref => (this.emailRef = ref)}
                      value={this.state.email}
                      onChange={email => {
                        const { showUniqueEmailError, showEmailRequiredError } = this.state;
                        if (showUniqueEmailError || showEmailRequiredError) {
                          this.setState({ showUniqueEmailError: false, showEmailRequiredError: false });
                        }
                        this.setState({ email });
                      }}
                    />
                  </FieldErrorWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </AnimateHeight>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSendingInvite}
              label="screens.users.resend-invite-modal.send-button"
              onClick={this.sendInvite}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendInviteModal);
