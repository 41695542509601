import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class CompletedBeforeRegistration extends Component {
  render() {
    const { value } = this.props.filter.completed_before_registration;
    return (
      <>
        <SideModal.Container.Filter.AppliedFilters.Filter.Boolean
          title={
            <FormattedMessage id="screens.work-orders.filters.advanced.work-order.completed-before-registration" />
          }
          value={value}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.CompletedBeforeRegistration,
              data: {
                work_order: {
                  ...this.props.filter,
                  [FILTER_TYPES.CompletedBeforeRegistration]: {
                    comparator: HelperFunctions.FILTER_COMPARABLES.Exists,
                    value: null,
                  },
                },
              },
            })
          }
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedBeforeRegistration);
