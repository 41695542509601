import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon } from 'views/components/Shared/General';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ChecklistOptionsModal } from 'views/components/Checklist';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';

class ChecklistSettingsButton extends Component {
  state = {
    isSavingChecklistInstanceOptions: false,
    showChecklistOptionsModal: false,
    showChecklistOptionsModalForChecklistTemplateId: null,
  };

  saveChecklistInstanceOption = ({ type, meter_id, checklist_template_row_id }) => {
    const { checklistInstanceOptions } = this.props;
    const checklistInstanceOption = checklistInstanceOptions.find(
      ({ checklist_template_row_id: ctr_id }) => checklist_template_row_id === ctr_id
    );
    if (checklistInstanceOption) {
      this.updateChecklistInstanceOption(checklistInstanceOption.id, {
        type,
        meter_id,
        checklist_template_row_id,
      });
    } else {
      this.createForOperationalMaintenanceTemplateAsset({ type, meter_id, checklist_template_row_id });
    }
  };

  createForOperationalMaintenanceTemplateAsset = data => {
    this.setState({ isSavingChecklistInstanceOptions: true });
    this.props
      .createForOperationalMaintenanceTemplateAsset(this.props.operationalMaintenanceTemplateAssetId, data)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSavingChecklistInstanceOptions: false });
      })
      .catch(() => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.setState({ isSavingChecklistInstanceOptions: false });
      });
  };

  updateChecklistInstanceOption = (
    checklistInstanceOptionId,
    { type, meter_id, checklist_template_row_id }
  ) => {
    this.setState({ isSavingChecklistInstanceOptions: true });
    this.props
      .updateChecklistInstanceOption(checklistInstanceOptionId, {
        meter_id,
        type,
        checklist_template_row_id,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState({ isSavingChecklistInstanceOptions: false });
      })
      .catch(() => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.setState({ isSavingChecklistInstanceOptions: false });
      });
  };

  renderChecklistOptionsModal = () => {
    return (
      <ChecklistOptionsModal
        assetId={this.props.operationalMaintenanceTemplateAssetId.asset_id}
        checklistTemplateId={this.props.checklistTemplate.id}
        checklistInstanceOptionIds={this.props.checklistInstanceOptions.map(({ id }) => id)}
        isSaving={this.state.isSavingChecklistInstanceOptions}
        open={this.state.showChecklistOptionsModal}
        onSave={({ checklist_instance_options }) => {
          this.setState({ showChecklistOptionsModal: false });
          const params = checklist_instance_options[0];

          this.saveChecklistInstanceOption(params);
        }}
        onClose={() => this.setState({ showChecklistOptionsModal: false })}
      />
    );
  };

  renderButtonLabel = () => {
    const optionsOnChecklist = this.props.checklistTemplateRows.filter(
      ({ require_option }) => require_option === true
    ).length;
    const optionsOnTemplateAsset = this.props.checklistInstanceOptions.length;
    if (optionsOnChecklist > optionsOnTemplateAsset) {
      return (
        <>
          <span style={{ marginRight: 8 }}>
            <Icon solid red type="exclamation-triangle" size={12} />
          </span>
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.checklist-settings-for-asset" />
        </>
      );
    }
    return (
      <FormattedMessage id="screens.operational-maintenance-template.settings.asset.checklist-settings-for-asset" />
    );
  };

  render() {
    if (this.props.checklistTemplateRows.find(({ require_option }) => require_option === true) != null) {
      return (
        <>
          <div className={styles['settings-button-container']}>
            <Button
              onClick={() => this.setState({ showChecklistOptionsModal: true })}
              gray
              translate={false}
              label={this.renderButtonLabel()}
            />
          </div>
          {this.renderChecklistOptionsModal()}
        </>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createForOperationalMaintenanceTemplateAsset:
        SDKReduxOperations.createForOperationalMaintenanceTemplateAsset,
      updateChecklistInstanceOption: SDKReduxOperations.updateChecklistInstanceOption,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateAssetId } = ownProps;
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplateAssetId
  );
  const activeOperationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  let checklistTemplateRows = [];
  let checklistTemplateLinks = [];
  let checklistTemplate = null;
  let checklistTemplateVersion = null;
  checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    activeOperationalMaintenanceTemplateVersion.checklist_template_links
  );
  if (checklistTemplateLinks.length === 1) {
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLinks[0].checklist_template_id
    );
  }
  if (checklistTemplate) {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version
    );
  }
  if (checklistTemplateVersion) {
    checklistTemplateRows = EntitySelectors.getChecklistTemplateRows(
      state,
      checklistTemplateVersion.checklist_template_rows
    );
  }

  return {
    checklistTemplate,
    checklistTemplateRows,
    checklistInstanceOptions: EntitySelectors.getChecklistInstanceOptions(
      state,
      operationalMaintenanceTemplateAsset.checklist_instance_options
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistSettingsButton);
