import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { Button, Toggle, ConfirmModal, WhiteCard } from 'views/components/Shared/General';
import { EditWorkOrderEmailDefaultValuesModal } from 'views/components/WorkOrder';
import { Section, Option } from 'views/scenes/Settings/components';
import Types from './Types';
import SpentTimeCategories from './SpentTimeCategories';
import Groups from './Groups';
import MaintenanceCategories from './MaintenanceCategories';
import EditRequiredFieldsModal from './EditRequiredFieldsModal';
import styles from './style.module.scss';

class WorkOrder extends Component {
  state = {
    showEditWorkOrderDefaultMessageModal: false,
    showDeactivateWorkOrderSpentTimeModal: false,
    showActivateWorkOrderSpentTimeModal: false,
    spentTimesHidden: false,
    isSavingActivateWorkOrderSpentTimeModal: false,
    isSavingDeactivateWorkOrderSpentTimeModal: false,
    removeWorkOrderSpentTimeCategoriesFromDom: false,
    showEditRequiredFieldsModal: false,
  };

  updateSettings = params => {
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          showDeactivateWorkOrderSpentTimeModal: false,
          showActivateWorkOrderSpentTimeModal: false,
          isSavingActivateWorkOrderSpentTimeModal: false,
          isSavingDeactivateWorkOrderSpentTimeModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          showDeactivateWorkOrderSpentTimeModal: false,
          showActivateWorkOrderSpentTimeModal: false,
          isSavingActivateWorkOrderSpentTimeModal: false,
          isSavingDeactivateWorkOrderSpentTimeModal: false,
        });
      });
  };

  renderActivateWorkOrderSpentTimeModal = () => (
    <ConfirmModal
      open={this.state.showActivateWorkOrderSpentTimeModal}
      title={
        <FormattedMessage id="screens.settings.work-orders.work-order-spent-time.activate-modal-title" />
      }
      message={<FormattedMessage id="screens.settings.work-orders.work-order-spent-time.subtitle" />}
      confirmButtonText="general.activate"
      confirmIsLoading={this.state.isSavingActivateWorkOrderSpentTimeModal}
      onConfirm={() => {
        if (this.state.isSavingActivateWorkOrderSpentTimeModal) {
          return;
        }
        this.setState({
          removeWorkOrderSpentTimeCategoriesFromDom: false,
          isSavingActivateWorkOrderSpentTimeModal: true,
        });
        this.updateSettings({ work_order_spent_time_activated: true });
      }}
      onCancel={() => {
        this.setState({ showActivateWorkOrderSpentTimeModal: false });
      }}
    />
  );

  renderDeactivateWorkOrderSpentTimeModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeactivateWorkOrderSpentTimeModal}
      title={
        <FormattedMessage id="screens.settings.work-orders.work-order-spent-time.deactivate-modal-title" />
      }
      message={<FormattedMessage id="screens.settings.work-orders.work-order-spent-time.subtitle" />}
      confirmButtonText="general.deactivate"
      confirmIsLoading={this.state.isSavingDeactivateWorkOrderSpentTimeModal}
      onConfirm={() => {
        if (this.state.isSavingDeactivateWorkOrderSpentTimeModal) {
          return;
        }
        this.setState({ isSavingDeactivateWorkOrderSpentTimeModal: true });
        this.updateSettings({ work_order_spent_time_activated: false });
      }}
      onCancel={() => {
        this.setState({ showDeactivateWorkOrderSpentTimeModal: false });
      }}
    />
  );

  renderSpentTimeCategoriesTitle = () => {
    if (this.props.system.work_order_spent_time_category_activated === true) {
      return <FormattedMessage id="screens.settings.work-orders.spent-time-categories.title" />;
    }
    return (
      <>
        <FormattedMessage id="screens.settings.work-orders.spent-time-categories.title" />
        <span className={styles['not-activated']}>
          <span> - </span>
          <FormattedMessage id="screens.settings.work-orders.spent-time-categories.not-activated" />
        </span>
      </>
    );
  };

  renderEmailMessageTitle = () => {
    if (this.props.system.work_order_email_subject || this.props.system.work_order_email_message) {
      return <FormattedMessage id="screens.settings.work-orders.default-email-values.title" />;
    }
    return (
      <>
        <FormattedMessage id="screens.settings.work-orders.default-email-values.title" />
        <span className={styles['not-activated']}>
          <span> - </span>
          <FormattedMessage id="screens.settings.work-orders.default-email-values.not-configured" />
        </span>
      </>
    );
  };

  renderWorkOrderSpentTimeCategoriesOption = () => {
    if (this.state.removeWorkOrderSpentTimeCategoriesFromDom === true) {
      return null;
    }
    if (this.props.hasProTier === false) {
      return null;
    }
    return (
      <AnimateHeight
        duration={150}
        height={this.props.settings.work_order_spent_time_activated ? 'auto' : 0}
        onAnimationEnd={() => {
          if (this.props.settings.work_order_spent_time_activated === false) {
            this.setState({ removeWorkOrderSpentTimeCategoriesFromDom: true });
          }
        }}
      >
        <Option
          title={this.renderSpentTimeCategoriesTitle()}
          subtitle={<FormattedMessage id="screens.settings.work-orders.spent-time-categories.subtitle" />}
          rightContainer={
            <Link to="/settings/work-orders/spent-time-categories">
              <Button primary label="general.manage" />
            </Link>
          }
        />
      </AnimateHeight>
    );
  };

  renderRootSettings = () => {
    return (
      <>
        <Section
          header
          title={<FormattedMessage id="screens.settings.work-orders.title" />}
          subtitle={<FormattedMessage id="screens.settings.work-orders.subtitle" />}
        />
        <div className={styles['options']}>
          <Option.Container>
            <Option
              title={
                <>
                  <FormattedMessage id="screens.settings.work-orders.categories.title" />
                  <span className={styles['title-description']}>
                    <span> - </span>
                    <FormattedMessage id="screens.settings.work-orders.categories.title-description" />
                  </span>
                </>
              }
              subtitle={<FormattedMessage id="screens.settings.work-orders.categories.subtitle" />}
              rightContainer={
                <>
                  <Link to="/settings/work-orders/categories">
                    <Button primary label="general.manage" />
                  </Link>
                </>
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.work-orders.types.title" />}
              subtitle={<FormattedMessage id="screens.settings.work-orders.types.subtitle" />}
              rightContainer={
                <>
                  <Link to="/settings/work-orders/types">
                    <Button primary label="general.manage" />
                  </Link>
                </>
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.work-orders.groups.title" />}
              subtitle={<FormattedMessage id="screens.settings.work-orders.groups.subtitle" />}
              rightContainer={
                <>
                  <Link to="/settings/work-orders/groups">
                    <Button primary label="general.manage" />
                  </Link>
                </>
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.work-orders.work-order-spent-time.title" />}
              subtitle={<FormattedMessage id="screens.settings.work-orders.work-order-spent-time.subtitle" />}
              rightContainer={
                <Toggle
                  checked={this.props.settings.work_order_spent_time_activated}
                  onClick={() => {
                    if (this.props.settings.work_order_spent_time_activated) {
                      this.setState({ showDeactivateWorkOrderSpentTimeModal: true });
                    } else {
                      this.setState({ showActivateWorkOrderSpentTimeModal: true });
                    }
                  }}
                />
              }
            />
            {this.renderWorkOrderSpentTimeCategoriesOption()}
            <Option
              title={this.renderEmailMessageTitle()}
              subtitle={<FormattedMessage id="screens.settings.work-orders.default-email-values.subtitle" />}
              rightContainer={
                <>
                  <Button
                    primary
                    label="general.manage"
                    onClick={() => this.setState({ showEditWorkOrderDefaultMessageModal: true })}
                  />
                </>
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.work-orders.required-fields.title" />}
              subtitle={<FormattedMessage id="screens.settings.work-orders.required-fields.subtitle" />}
              rightContainer={
                <Button
                  primary
                  label="general.manage"
                  onClick={() => this.setState({ showEditRequiredFieldsModal: true })}
                />
              }
            />
          </Option.Container>
        </div>
      </>
    );
  };

  render() {
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/work-orders" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/work-orders/types" component={Types} />
          <Route exact path="/settings/work-orders/groups" component={Groups} />
          <Route exact path="/settings/work-orders/categories" component={MaintenanceCategories} />
          {this.props.hasProTier ? (
            <Route exact path="/settings/work-orders/spent-time-categories" component={SpentTimeCategories} />
          ) : null}
        </Switch>
        <EditWorkOrderEmailDefaultValuesModal
          open={this.state.showEditWorkOrderDefaultMessageModal}
          onClose={() => {
            this.setState({ showEditWorkOrderDefaultMessageModal: false });
          }}
          onSave={() => {
            this.setState({ showEditWorkOrderDefaultMessageModal: false });
          }}
        />
        <EditRequiredFieldsModal
          open={this.state.showEditRequiredFieldsModal}
          onClose={() => {
            this.setState({ showEditRequiredFieldsModal: false });
          }}
        />
        {this.renderActivateWorkOrderSpentTimeModal()}
        {this.renderDeactivateWorkOrderSpentTimeModal()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
