import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import { CardGrid } from 'views/components/Shared/Layout';
import { Loader, WhiteCard } from 'views/components/Shared/General';
import DescriptionContainer from './DescriptionContainer';
import AttachmentsContainer from './AttachmentsContainer';
import Checklist from './Checklist';
import ReservedSpareParts from './ReservedSpareParts';
import ImagesContainer from './ImagesContainer';
import Settings from './Settings';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';

class Information extends Component {
  hasData = () => {
    const { description, attachments, images, checklist_template_links, spare_part_reservations } =
      this.props.operationalMaintenanceTemplateVersion;
    let hasData = false;
    if (description) {
      hasData = true;
    }
    if (attachments.length > 0) {
      hasData = true;
    }
    if (images.length > 0) {
      hasData = true;
    }
    if (checklist_template_links.length > 0) {
      hasData = true;
    }
    if (spare_part_reservations.length > 0) {
      hasData = true;
    }
    return hasData;
  };

  renderContent = () => {
    if (this.props.isFetchingOperationalMaintenanceVersion) {
      return <Loader />;
    }
    if (this.hasData() === false) {
      return (
        <CardGrid>
          <CardGrid.Column>
            <CardGrid.Row>
              <WhiteCard padding="25px">
                <span className={styles['no-description']}>
                  <FormattedMessage id="components.edit-operational-maintenance-template.no-description" />
                </span>
              </WhiteCard>
            </CardGrid.Row>
          </CardGrid.Column>
        </CardGrid>
      );
    }
    return (
      <CardGrid>
        <CardGrid.Column>
          <DescriptionContainer
            operationalMaintenanceTemplateVersion={this.props.operationalMaintenanceTemplateVersion}
          />
          <Checklist
            operationalMaintenanceTemplateVersion={this.props.operationalMaintenanceTemplateVersion}
          />
          <ReservedSpareParts
            operationalMaintenanceTemplateVersion={this.props.operationalMaintenanceTemplateVersion}
          />
          <AttachmentsContainer
            operationalMaintenanceTemplateVersion={this.props.operationalMaintenanceTemplateVersion}
          />

          <ImagesContainer
            operationalMaintenanceTemplateVersion={this.props.operationalMaintenanceTemplateVersion}
          />
        </CardGrid.Column>
      </CardGrid>
    );
  };

  render() {
    return (
      <div className={styles['content-container']}>
        <div className={styles['content-wrapper']}>
          <PerfectScrollbar>
            <div className={styles['content']}>{this.renderContent()}</div>
          </PerfectScrollbar>
        </div>
        <Settings />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const operationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    operationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  const operationalMaintenanceTemplateAssets = EntitySelectors.getOperationalMaintenanceTemplateAssets(
    state,
    operationalMaintenanceTemplate.operational_maintenance_template_assets
  );
  return {
    isFetchingOperationalMaintenanceVersion:
      OperationalMaintenanceTemplateSelectors.isFetchingOperationalMaintenanceVersion(state),
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplate,
    assets: EntitySelectors.getAssets(
      state,
      operationalMaintenanceTemplateAssets.map(({ asset_id }) => asset_id)
    ),
  };
}

export default withRouter(connect(mapStateToProps)(Information));
