import { API, HelperFunctions } from 'sdk';
import { normalizeCosts } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import actions from './actions';

const resetSelectedCosts = actions.resetSelectedCosts;
const selectCost = actions.selectCost;
const selectPage = actions.selectPage;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;

const addQueryParameter = actions.addQueryParameter;

const fetchCosts = (systemId, params, config) => dispatch => {
  dispatch(actions.fetchCosts());
  return API.listCosts(systemId, params, config).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeCosts(res.data);

    dispatch(EntityOperations.updateEntities(entities));

    dispatch(actions.fetchCostsSuccess({ ids: result, pagination }));
    return res.data;
  });
};

export default {
  resetSelectedCosts,
  selectPage,
  selectTotalEntries,
  hideSelectTotalEntries,
  selectCost,
  addQueryParameter,
  fetchCosts,
  showAppliedFilters,
  showAvailableFilters,
  selectFilterType,
  addFilter,
  removeFilter,
  resetFilter,
};
