import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Icon } from 'views/components/Shared/General';
import { ChecklistProgress } from 'views/components/WorkOrder';

class ChecklistTemplateLinkListItem extends Component {
  renderProgressBar = () => {
    return (
      <List.Item.Column alignRight>
        <ChecklistProgress workOrderId={this.props.checklistTemplateLink.work_order_id} />
      </List.Item.Column>
    );
  };

  render() {
    return (
      <List.Item clickable onClick={this.props.onClick}>
        <List.Item.Column>
          <Icon type="list" withBackground backgroundSize={34} />
        </List.Item.Column>
        <List.Item.TitleColumn title={this.props.checklistTemplate.title} />
        {this.renderProgressBar()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      ownProps.checklistTemplateLink.checklist_template_id
    ),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateLinkListItem);
