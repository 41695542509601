import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Decimal as DecimalJs } from 'decimal.js';
import Text from '../Text';

export default class Decimal extends Component {
  textRef = null;

  constructor(props) {
    super(props);
    try {
      const value = new DecimalJs(props.value);
      this.state = {
        value,
      };
    } catch (e) {
      this.state = {
        value: '',
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      try {
        let value = new DecimalJs(this.props.value).toString();
        let cleanedValue = value.replace(/,/g, '.').replace(/[^0-9.]/g, '');
        this.setState({ value: cleanedValue });
      } catch (e) {
        this.setState({ value: '' });
      }
    }
  }

  focus = () => {
    this.textRef.inputRef.focus();
  };

  select = () => {
    this.textRef.inputRef.select();
  };

  onChange = value => {
    if (typeof this.props.onChange === 'function') {
      this.setState({ value }, () => {
        this.props.onChange(value);
      });
    } else {
      this.setState({ value });
    }
  };

  onBlur = value => {
    if (typeof this.props.onBlur === 'function') {
      let cleanedValue = value.replace(/,/g, '.').replace(/[^0-9.]/g, '');
      try {
        if (this.props.decimalPlaces) {
          cleanedValue = cleanedValue = new DecimalJs(cleanedValue)
            .toDecimalPlaces(this.props.decimalPlaces, DecimalJs.ROUND_DOWN)
            .toString();
        } else {
          cleanedValue = new DecimalJs(cleanedValue).toString();
        }
        this.setState({ value: cleanedValue }, () => {
          this.props.onBlur(this.state.value);
        });
      } catch (e) {
        this.setState({ value: '' }, () => {
          this.props.onBlur(this.state.value);
        });
      }
    }
  };

  render() {
    return (
      <Text
        {...this.props}
        ref={ref => (this.textRef = ref)}
        value={this.state.value}
        onChange={value => this.onChange(value)}
        onBlur={value => this.onBlur(value)}
      />
    );
  }
}

Decimal.propTypes = {
  decimalPlaces: PropTypes.string,
};

Decimal.defaultProps = {
  decimalPlaces: null,
};
