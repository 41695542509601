import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field, MoneyWithCurrency } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class UpdateSparePartVendorModal extends Component {
  getInitialState = () => ({
    showUpdateNumberCheckbox: false,
    showUpdateTitleCheckbox: false,
    showUpdatePriceCheckbox: false,
    updateNumber: true,
    updateTitle: true,
    updatePrice: true,
    isUpdatingSparePartVendor: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { sparePart, sparePartVendor, purchaseOrderRow } = this.props;
      let sparePartTitle = sparePartVendor.title;
      if (!sparePartTitle) {
        sparePartTitle = sparePart.title;
      }
      let showUpdatePriceCheckbox = sparePartVendor.purchase_price !== purchaseOrderRow.price;
      if (this.props.purchaseOrder.currency !== this.props.vendor.purchase_order_currency) {
        showUpdatePriceCheckbox = false;
      }
      this.setState({
        showUpdateNumberCheckbox: sparePartVendor.number !== purchaseOrderRow.number,
        showUpdateTitleCheckbox: sparePartTitle !== purchaseOrderRow.title,
        showUpdatePriceCheckbox,
      });
    }
  }

  getChanges = () => {
    const { purchaseOrderRow } = this.props;
    const { price, number, title } = purchaseOrderRow;
    let params = {};
    if (this.state.showUpdateNumberCheckbox && this.state.updateNumber) {
      params = {
        ...params,
        number,
      };
    }
    if (this.state.showUpdateTitleCheckbox && this.state.updateTitle) {
      params = {
        ...params,
        title,
      };
    }
    if (this.state.showUpdatePriceCheckbox && this.state.updatePrice) {
      params = {
        ...params,
        purchase_price: price,
      };
    }
    return params;
  };

  updateSparePartVendor = () => {
    const { sparePartVendor } = this.props;
    this.setState({ isUpdatingSparePartVendor: true });
    this.props
      .updateSparePartVendor(sparePartVendor.id, this.getChanges())
      .then(({ data: sparePartVendor }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.close();
      })
      .catch(e => {
        this.close();
      });
  };

  renderChangedArticleNumber = () => {
    if (this.state.showUpdateNumberCheckbox) {
      const { sparePartVendor, purchaseOrderRow } = this.props;
      return (
        <div
          className={styles['checkbox']}
          onClick={() => {
            this.setState(prevstate => ({ updateNumber: !prevstate.updateNumber }));
          }}
        >
          <Field.Checkbox checked={this.state.updateNumber} />
          <div className={styles['changed-value-container']}>
            <p>
              <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-number-title" />
            </p>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.old-value" />
              }
              singleRow
            >
              <span className={styles['old-value']}>
                {sparePartVendor.number ? sparePartVendor.number : '-'}
              </span>
            </Field>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-value" />
              }
              singleRow
            >
              {purchaseOrderRow.number}
            </Field>
          </div>
        </div>
      );
    }
    return null;
  };

  renderChangedTitle = () => {
    if (this.state.showUpdateTitleCheckbox) {
      const { sparePart, sparePartVendor, purchaseOrderRow } = this.props;
      let sparePartTitle = sparePartVendor.title;
      if (!sparePartTitle) {
        sparePartTitle = sparePart.title;
      }
      return (
        <div
          className={styles['checkbox']}
          onClick={() => {
            this.setState(prevstate => ({ updateTitle: !prevstate.updateTitle }));
          }}
        >
          <Field.Checkbox checked={this.state.updateTitle} />
          <div className={styles['changed-value-container']}>
            <p>
              <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-title-title" />
            </p>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.old-value" />
              }
              singleRow
            >
              <span className={styles['old-value']}>{sparePartTitle ? sparePartTitle : '-'}</span>
            </Field>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-value" />
              }
              singleRow
            >
              {purchaseOrderRow.title}
            </Field>
          </div>
        </div>
      );
    }
    return null;
  };

  renderChangedPrice = () => {
    if (this.state.showUpdatePriceCheckbox) {
      const { sparePartVendor, purchaseOrderRow } = this.props;
      return (
        <div
          className={styles['checkbox']}
          onClick={() => {
            this.setState(prevstate => ({ updatePrice: !prevstate.updatePrice }));
          }}
        >
          <Field.Checkbox checked={this.state.updatePrice} />
          <div className={styles['changed-value-container']}>
            <p>
              <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-price-title" />
            </p>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.old-value" />
              }
              singleRow
            >
              <span className={styles['old-value']}>
                {sparePartVendor.purchase_price ? (
                  <MoneyWithCurrency
                    currency={this.props.purchaseOrder.currency}
                    value={sparePartVendor.purchase_price}
                  />
                ) : (
                  '-'
                )}
              </span>
            </Field>
            <Field
              fontSize={12}
              view
              label={
                <FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.new-value" />
              }
              singleRow
            >
              <MoneyWithCurrency
                currency={this.props.purchaseOrder.currency}
                value={purchaseOrderRow.price}
              />
            </Field>
          </div>
        </div>
      );
    }
    return null;
  };

  close = () => {
    this.setState(this.getInitialState(), () => {
      this.props.onClose();
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={475}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.purchase-order.info.articles.update-spare-part-modal.title" />}
          subtitle={
            <FormattedMessage
              id="screens.purchase-order.info.articles.update-spare-part-modal.subtitle"
              values={{ vendor: this.props.vendor == null ? '' : this.props.vendor.name }}
            />
          }
          subtitleTopMargin
          onClose={this.close}
        />
        <Modal.Content>
          <div className={styles['more-info-container']}>
            {this.renderChangedArticleNumber()}
            {this.renderChangedTitle()}
            {this.renderChangedPrice()}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="screens.purchase-order.info.articles.update-spare-part-modal.update-button"
              loading={this.state.isUpdatingSparePartVendor}
              onClick={this.updateSparePartVendor}
            />
            <Button
              label="screens.purchase-order.info.articles.update-spare-part-modal.skip-button"
              onClick={this.close}
            />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartVendor: SDKReduxOperations.updateSparePartVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.sparePartVendorId) {
    const purchaseOrderRow = EntitySelectors.getPurchaseOrderRow(state, ownProps.purchaseOrderRowId);
    return {
      purchaseOrderRow,
      sparePartVendor: EntitySelectors.getSparePartVendor(state, ownProps.sparePartVendorId),
      sparePart: EntitySelectors.getSparePart(state, purchaseOrderRow.spare_part_id),
      vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    };
  }
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSparePartVendorModal);
