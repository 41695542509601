import React from 'react';
import ReactDOM from 'react-dom';
import { Menu, Alert } from './components';
import styles from './style.module.scss';
import { Toaster } from 'react-hot-toast';

export default ({ children }) => {
  return (
    <>
      <div id="content" className={[styles['content'], 'app-container'].join(' ')}>
        <Menu />
        <div
          id="main"
          className={`
            ${styles['main']}
          `}
        >
          <Alert />
          <div className={styles['children']}>{children}</div>
        </div>
        {ReactDOM.createPortal(
          <Toaster position="bottom-left" containerStyle={{ bottom: 30, left: 250 }} />,
          document.getElementById('root')
        )}
      </div>
    </>
  );
};
