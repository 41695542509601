const DOMAIN_PREFIX = 'asset/';

const FETCH_ASSET_FOR_VIEW = `${DOMAIN_PREFIX}fetch_asset_for_view`;
const FETCH_ASSET_FOR_VIEW_SUCCESS = `${DOMAIN_PREFIX}fetch_asset_for_view_success`;

const FETCH_WORK_ORDERS_SUCCESS = `${DOMAIN_PREFIX}fetch_work_orders_success`;
const FETCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_attachments_success`;
const FETCH_ASSET_VENDORS_SUCCESS = `${DOMAIN_PREFIX}fetch_asset_vendors_success`;
const FETCH_SPARE_PART_ASSETS_SUCCESS = `${DOMAIN_PREFIX}fetch_spare_part_assets_success`;
const FETCH_DOWNTIMES_SUCCESS = `${DOMAIN_PREFIX}fetch_downtimes_success`;
const FETCH_DATA_BETWEEN_DATES = `${DOMAIN_PREFIX}fetch_data_between_dates`;
const FETCH_DATA_BETWEEN_DATES_SUCCESS = `${DOMAIN_PREFIX}fetch_data_between_dates_success`;
const SHOW_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}show_preview_overlay`;
const HIDE_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}hide_preview_overlay`;
const SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}/show_more_work_orders_preview_overlay`;
const HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY = `${DOMAIN_PREFIX}/hide_more_work_orders_preview_overlay`;
const BEGIN_DRAG = `${DOMAIN_PREFIX}/being_drag`;
const END_DRAG = `${DOMAIN_PREFIX}/end_drag`;
const DROP_CALENDAR_INSTANCE = `${DOMAIN_PREFIX}drop_calendar_instance`;

export default {
  FETCH_ASSET_FOR_VIEW,
  FETCH_ASSET_FOR_VIEW_SUCCESS,
  FETCH_DATA_BETWEEN_DATES,
  FETCH_DATA_BETWEEN_DATES_SUCCESS,
  FETCH_SPARE_PART_ASSETS_SUCCESS,
  FETCH_WORK_ORDERS_SUCCESS,
  DROP_CALENDAR_INSTANCE,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ASSET_VENDORS_SUCCESS,
  FETCH_DOWNTIMES_SUCCESS,
  SHOW_PREVIEW_OVERLAY,
  HIDE_PREVIEW_OVERLAY,
  SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
  HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
  BEGIN_DRAG,
  END_DRAG,
};
