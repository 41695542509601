import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { ElapsedTime, Loader } from 'views/components/Shared/General';
import { DowntimeTimer } from 'views/components/Downtime';
import styles from './styles.module.scss';
import { TreePath } from 'views/components/Asset';

class AddDowntimeListItem extends Component {
  getTotalStopTimeAsMinutes = () => {
    const fromDate = moment(this.props.downtime.from);
    const toDate = moment(this.props.downtime.to);
    return toDate.diff(fromDate, 'minutes');
  };

  renderSubtitle = () => {
    if (this.props.downtime.to == null) {
      return (
        <span>
          <FormattedMessage
            id="screens.asset.downtimes.downtime-is-active"
            values={{
              timer: <DowntimeTimer downtime={this.props.downtime} />,
            }}
          />
        </span>
      );
    }
    return <ElapsedTime elapsedMinutes={this.getTotalStopTimeAsMinutes()} />;
  };

  render() {
    if (this.props.loading) {
      return <Loader tiny />;
    }
    return (
      <>
        <div className={styles['title']}>
          <FormattedMessage id="screens.work-order.downtime.add-active-downtime" />
        </div>
        <div className={styles['subtitle']}>
          <span>
            <TreePath fullPath assetId={this.props.downtime.asset_id} />
          </span>
        </div>
        <div className={styles['subtitle']}>
          <span>
            <FormattedMessage id="resources.downtime.started-at" />:{' '}
            {moment(this.props.downtime.from).format('LL')} {moment(this.props.downtime.from).format('HH:mm')}
          </span>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const { id } = ownProps;
  const downtime = EntitySelectors.getDowntime(state, id);
  return {
    downtime,
    asset: EntitySelectors.getAsset(state, downtime.asset_id),
  };
}

export default connect(mapStateToProps)(AddDowntimeListItem);
