import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const SparePartUnitContainer = ({ sparePartUnit }) => {
  return sparePartUnit.title;
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SparePartUnitContainer);
