import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import { HelperFunctions } from 'sdk';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';

class WorkOrderSpentTimeComment extends Component {
  constructor(props) {
    super(props);
    const { filter, initialFilter } = props;
    this.state = {
      initialFilter: {
        comment: {
          ...(filter.comment || initialFilter.comment),
        },
      },
    };
  }

  render() {
    const { value, comparator } = this.props.filter.comment || this.props.initialFilter.comment;
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter.comment, this.state.initialFilter.comment)) {
              this.props.removeFilter({
                key: FILTER_TYPES.WorkOrderSpentTimeComment,
                data: {
                  work_order_spent_time: {
                    ...this.props.filter,
                    comment: {
                      ...this.props.initialFilter.comment,
                    },
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.WorkOrderSpentTimeComment,
                data: {
                  work_order_spent_time: {
                    ...this.props.filter,
                    comment: {
                      ...this.state.initialFilter.comment,
                    },
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.work-order-spent-time.comment" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Text
            value={value}
            comparator={comparator}
            onChange={({ value, comparator }) => {
              this.props.addFilter({
                key: FILTER_TYPES.WorkOrderSpentTimeComment,
                data: {
                  work_order_spent_time: {
                    ...this.props.filter,
                    comment: {
                      comparator: comparator,
                      value: value,
                    },
                  },
                },
              });
            }}
            onApplyfilter={this.props.showAppliedFilters}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFilter: CostsOperations.addFilter,
      showAppliedFilters: CostsOperations.showAppliedFilters,
      selectFilterType: CostsOperations.selectFilterType,
      removeFilter: CostsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: CostsSelectors.getFiltersForKey(state, 'work_order_spent_time'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderSpentTimeComment);

WorkOrderSpentTimeComment.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

WorkOrderSpentTimeComment.defaultProps = {
  filter: {
    comment: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Search,
      value: '',
    },
  },
  initialFilter: {
    comment: {
      comparator: HelperFunctions.FILTER_COMPARABLES.Search,
      value: '',
    },
  },
};
