import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { bindActionCreators } from 'redux';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { SCIMFieldsDisabledBanner } from 'views/components/User/SCIMFieldsDisabledBanner';

class UserArchiveModal extends Component {
  state = {
    isArchiving: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isArchiving: false,
      });
    }
  }

  archive = () => {
    this.setState({ isArchiving: true });
    this.props
      .updateUser(this.props.user.id, { archived: true })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);

        this.setState({ isArchiving: false });
      });
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.users.archive-modal.title" />}
            subtitle={
              <div className={styles['danger-text']}>
                <p>
                  {this.props.user.name} - {this.props.user.email}
                </p>
              </div>
            }
            onClose={() => this.props.onClose()}
          />
          <Modal.Content>
            {!this.props.user.scim ? (
              <div className={styles['info-box']}>
                <div className={styles['info-text']}>
                  <FormattedMessage id="screens.users.archive-modal.message" />
                </div>
              </div>
            ) : (
              <SCIMFieldsDisabledBanner />
            )}
          </Modal.Content>
          {!this.props.user.scim ? (
            <Modal.Footer>
              <Button.Group>
                <Button
                  primary
                  destructive
                  loading={this.state.isArchiving}
                  label="screens.users.archive-modal.button"
                  onClick={() => {
                    this.archive();
                  }}
                />
                <Button label="general.cancel" onClick={() => this.props.onClose()} />
              </Button.Group>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserArchiveModal);
