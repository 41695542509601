import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import RequestItem from './RequestItem';

class SelectRequestsModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={700}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.work-order.created-from.select-request-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>
          <List.Header small background>
            <List.Header.Column flex>
              <FormattedMessage id="resources.request.resource" />
            </List.Header.Column>
            <List.Header.Column alignRight width={150}>
              <FormattedMessage id="resources.request.created-by" />
            </List.Header.Column>
            <List.Header.Column alignRight width={150}>
              <FormattedMessage id="resources.request.created-at" />
            </List.Header.Column>
          </List.Header>
          <List>
            {this.props.requests.map(request => (
              <RequestItem request={request} onSelect={id => this.props.onSelect(id)} />
            ))}
          </List>
        </Modal.Content>
      </Modal>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(SelectRequestsModal);
