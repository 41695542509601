import types from './types';

const setNavigatedTo = payload => ({ type: types.SET_NAVIGATED_TO, payload });

const resetSelectedPurchaseOrders = payload => ({ type: types.RESET_SELECTED_PURCHASE_ORDERS, payload });
const selectPurchaseOrder = payload => ({ type: types.SELECT_PURCHASE_ORDER, payload });
const selectPage = payload => ({ type: types.SELECT_PAGE, payload });
const selectTotalEntries = payload => ({ type: types.SELECT_TOTAL_ENTRIES, payload });
const hideSelectTotalEntries = payload => ({ type: types.HIDE_SELECT_TOTAL_ENTRIES, payload });

const selectFilterType = payload => ({
  type: types.SELECT_FILTER_TYPE,
  payload: payload,
});

const showAppliedFilters = () => ({
  type: types.SHOW_APPLIED_FILTERS,
});

const showAvailableFilters = payload => ({
  type: types.SHOW_AVAILABLE_FILTERS,
  payload: payload,
});

const addFilter = payload => ({
  type: types.ADD_FILTER,
  payload: payload,
});

const removeFilter = payload => ({
  type: types.REMOVE_FILTER,
  payload: payload,
});

const resetFilter = payload => ({
  type: types.RESET_FILTER,
  payload: payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

const fetchPurchaseOrdersSuccess = payload => ({
  type: types.FETCH_PURCHASE_ORDERS_SUCCESS,
  payload,
});

const showLoader = payload => ({ type: types.SHOW_LOADER, payload });

export default {
  showLoader,
  showAppliedFilters,
  resetSelectedPurchaseOrders,
  selectPage,
  selectPurchaseOrder,
  selectTotalEntries,
  hideSelectTotalEntries,
  fetchPurchaseOrdersSuccess,
  selectFilterType,
  showAvailableFilters,
  addFilter,
  removeFilter,
  resetFilter,
  addQueryParameter,
  setNavigatedTo,
};
