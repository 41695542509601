import * as API from './api';
import * as types from './reduxTypes';

export function createSparePartStockAdjustment(sparePartId, params) {
  return dispatch => {
    return API.createSparePartStockAdjustment(sparePartId, params)
      .then(res => {
        const payload = { params, data: res.data };
        dispatch({ type: types.SPARE_PART_STOCK_ADJUSTMENT_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
