import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { Loader } from 'views/components/Shared/General';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FilesOperations } from 'state/ducks/files';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { NewSearchField, Button, EmptyDataSet, List, Icon } from 'views/components/Shared/General';
import { SDKReduxOperations } from 'sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import {
  AttachmentListItem,
  AttachmentOptionsInlineModal,
  SortInlineModal,
} from 'views/components/Attachment';
import { Header, ContentContainer } from 'views/components/Shared/Layout';
import { MenuOperations } from 'state/ducks/menu';
import { FilesSelectors } from 'state/ducks/files';
import { MenuUtils } from 'state/ducks/menu';
import styles from './style.module.scss';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import AttachmentSmall from 'assets/images/EmptyDataSet/AttachmentSmall.png';
import AttachmentSubtitle from './AttachmentSubtitle';

const PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA = 350;
const FILTERS = ['search'];

class FileList extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      attachmentCount: [],
      isViewLoading: false,
      isTableLoading: false,
      isFetchingMoreFiles: false,
      queryParams: queryParams,
      searchValue: queryParams.search || '',
      isDeletingAttachment: false,
      showSortInlineModal: false,
    };
  }

  componentDidMount() {
    this.loadInitialAttachments();
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.files.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Files);
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.fetchAttachments();
      });
    }
  }

  deleteAttachment = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props
      .deleteAttachment(attachment.id)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.files.delete-success" />} />);
        this.setState({ isDeletingAttachment: false });
      })
      .catch(() => {
        this.setState({ isDeletingAttachment: false });
      });
  };

  loadInitialAttachments = () => {
    this.setState({ isViewLoading: true });
    this.fetchAttachments().then(() => {
      this.setState({ isViewLoading: false });
    });
  };

  fetchAttachments = () => {
    const params = this.getFilterParams();

    this.setState({ isTableLoading: true });
    return this.props
      .fetchAttachments(this.props.selectedSystem.id, params)
      .then(attachments => {
        this.setState({ isTableLoading: false });
        return attachments;
      })
      .catch(e => {});
  };

  fetchMoreAttachments = () => {
    if (this.state.isFetchingMoreFiles || this.props.isFullyLoaded) return;
    this.setState({ isFetchingMoreFiles: true });

    const params = {
      paginate_from: this.props.paginateFrom,
      ...this.getFilterParams(),
    };

    return this.props.fetchAttachments(this.props.selectedSystem.id, params).then(() => {
      this.setState({ isFetchingMoreFiles: false });
    });
  };

  getFilterParams = () => ({
    sort: 'title',
    'sort-order': 'asc',
    ...SDKHelperFunctions.buildQueryParamsForList(this.state.queryParams, FILTERS),
  });

  isFiltering = () => this.state.searchTerm || FILTERS.some(key => this.state.queryParams[key] != null);

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  fetchMoreLoader = () => (
    <div className={styles['fetch-more-loader-container']}>
      <Loader small />
    </div>
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.files.empty-data-set.title" />}
      subtitle={<FormattedMessage id="screens.files.empty-data-set.subtitle" />}
      image={AttachmentSmall}
      big
      mainContentContainer
    />
  );

  renderSearchField = () => (
    <div className={styles['search-container']}>
      <NewSearchField
        value={this.state.searchValue}
        againstGrayBackground
        small
        debounce
        placeholder={this.props.intl.formatMessage({
          id: 'screens.files.search-field-placeholder',
        })}
        onSearch={value => {
          this.setState({
            searchValue: value,
            isTableLoading: true,
          });
        }}
        onDebouncedSearch={value => {
          this.changeQueryParams({ search: value });
        }}
        onClear={() => {
          this.setState({ isTableLoading: true, searchValue: '' });
          this.changeQueryParams({ search: '' });
        }}
      />
    </div>
  );

  renderFiles = () => {
    if (this.props.attachments.length === 0) {
      if (this.state.searchValue.length > 0) {
        return (
          <div className={styles['no-search-result-container']}>
            <EmptyDataSet
              title={<FormattedMessage id="general.empty-data-set-search.title" />}
              subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
              image={SearchImage}
            />
          </div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-container']}>
            <p className={styles['title']}>
              <FormattedMessage
                id={
                  this.state.searchValue
                    ? 'screens.files.search-result-title'
                    : 'screens.files.all-files-title'
                }
              />
            </p>
            <p className={styles['total-entries']}>
              <FormattedMessage
                id="screens.files.total-entries"
                values={{ amount: this.props.totalEntries }}
              />
            </p>
          </div>
          <SortInlineModal
            onSort={(sort, sortOrder) => {
              this.changeQueryParams({ sort, 'sort-order': sortOrder });
            }}
            sort={this.state.queryParams.sort}
          />
        </div>
        <List>
          {this.props.attachments.map(attachment => (
            <AttachmentListItem
              key={attachment.id}
              attachment={attachment}
              hideFolder
              subtitle={<AttachmentSubtitle attachment={attachment} />}
              onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
              buttons={
                <AttachmentOptionsInlineModal
                  context="files"
                  attachment={attachment}
                  isDeletingAttachment={this.state.isDeletingAttachment}
                  onDeleteAttachment={attachment => this.deleteAttachment(attachment)}
                  trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                />
              }
            />
          ))}
        </List>
      </React.Fragment>
    );
  };

  render() {
    if (this.state.isViewLoading) {
      return <Loader centerInParent />;
    } else {
      return (
        <PerfectScrollbar>
          <Header smallTitle title={<FormattedMessage id="screens.files.title" />} />
          <InfiniteScroll
            loadMore={this.fetchMoreAttachments}
            hasMore={this.props.totalEntries >= 20}
            loader={this.state.isFetchingMoreFiles ? this.fetchMoreLoader() : null}
            useWindow={false}
            initialLoad={false}
            threshold={PIXELS_FROM_BOTTOM_TO_FETCH_MORE_DATA}
          >
            <ContentContainer>
              {this.props.attachments.length === 0 && !this.isFiltering() && !this.state.isTableLoading ? (
                this.renderEmptyDataset()
              ) : (
                <div>
                  {this.renderSearchField()}
                  {this.state.isTableLoading ? (
                    <div className={styles['search-container']}>
                      <Loader />
                    </div>
                  ) : (
                    this.renderFiles()
                  )}
                </div>
              )}
            </ContentContainer>
          </InfiniteScroll>
        </PerfectScrollbar>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAttachments: FilesOperations.fetchAttachments,
      selectMenuItem: MenuOperations.selectItem,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    attachments: FilesSelectors.getAttachments(state),
    totalEntries: FilesSelectors.getTotalEntries(state),
    paginateFrom: FilesSelectors.getPaginateFrom(state),
    isFullyLoaded: FilesSelectors.getIsFullyLoaded(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(FileList)));
