import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { Button, MoneyWithCurrency } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import LaborCostModal from './LaborCostModal';
import styles from './style.module.scss';

class LaborCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLaborCostModal: false,
    };
  }

  renderLaborCost = () => {
    if (this.props.vendor.labor_cost_per_hour) {
      return (
        <div
          className={`${styles['whitecard-wrapper']} ${this.props.canEditVendors ? styles['clickable'] : ''}`}
          onClick={() => {
            if (this.props.canEditVendors) {
              this.setState({ showLaborCostModal: true });
            }
          }}
        >
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.vendor.labor-cost.labor-cost" />
          </div>
          <div className={styles['value']}>
            <FormattedMessage
              id="screens.vendor.labor-cost.cost-per-hour"
              values={{
                cost: (
                  <MoneyWithCurrency
                    currency={this.props.vendor.purchase_order_currency}
                    value={this.props.vendor.labor_cost_per_hour}
                    fractionSize={0}
                  />
                ),
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['whitecard-wrapper']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.vendor.labor-cost.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.vendor.labor-cost.subtitle" />
        </div>
        {this.props.canEditVendors ? (
          <div className={styles['actions']}>
            <Button
              type="text"
              primary
              fontSize={12}
              noUnderline
              label="general.add"
              onClick={() => this.setState({ showLaborCostModal: true })}
            />
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    if (this.props.hasProTier === false) return null;
    if (this.props.system.costs_activated === false) return null;
    if (this.props.vendor.assign_work_orders === false) return null;
    return (
      <>
        <CardGrid.Separator />
        <CardGrid.Column>
          <CardGrid.Row>
            <React.Fragment>
              {this.renderLaborCost()}
              <LaborCostModal
                open={this.state.showLaborCostModal}
                vendor={this.props.vendor}
                onClose={() => this.setState({ showLaborCostModal: false })}
              />
            </React.Fragment>
          </CardGrid.Row>
        </CardGrid.Column>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const vendorId = ownProps.match.params.id;

  return {
    vendor: EntitySelectors.getVendor(state, vendorId),
    system: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LaborCost));
