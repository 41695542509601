import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import {
  PurchaseOrderStatus,
  PurchaseOrderDeliveryStatus,
  getEstimatedDeliveryDateText,
} from 'sdk/PurchaseOrder';
import { Icon, Tooltip } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class PurchaseItem extends Component {
  renderSubtitle = () => {
    const { status, delivery_status, canceled } = this.props.purchaseOrder;
    if (status === PurchaseOrderStatus.Draft) {
      return <FormattedMessage id="resources.purchase-order.statuses.draft" />;
    } else if (canceled) {
      return <FormattedMessage id="resources.purchase-order.cancelled" />;
    } else if (delivery_status === PurchaseOrderDeliveryStatus.None) {
      return <FormattedMessage id="resources.purchase-order.statuses.ordered" />;
    } else if (delivery_status === PurchaseOrderDeliveryStatus.Partial) {
      return <FormattedMessage id="resources.purchase-order.statuses.partially-delivered" />;
    } else if (delivery_status === PurchaseOrderDeliveryStatus.Full) {
      return <FormattedMessage id="resources.purchase-order.statuses.delivered" />;
    }
  };

  subtitleColorStyle = () => {
    const { status, delivery_status, canceled } = this.props.purchaseOrder;
    if (status === PurchaseOrderStatus.Draft) {
      return styles['light-gray'];
    } else if (canceled) {
      return styles['light-gray'];
    } else if (delivery_status === PurchaseOrderDeliveryStatus.None) {
      return styles['orange'];
    } else if (delivery_status === PurchaseOrderDeliveryStatus.Partial) {
      return styles['orange'];
    } else if (delivery_status === PurchaseOrderDeliveryStatus.Full) {
      return styles['green'];
    }
  };

  renderVendorName = () => {
    if (this.props.vendor == null) {
      return (
        <div style={{ width: 100, height: 14 }}>
          <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
            <rect x="0" y="0" rx="3" ry="3" width="100" height="10" />
          </ContentLoader>
        </div>
      );
    }
    return <div className={styles['title']}>{this.props.vendor.name}</div>;
  };

  renderEstimatedDeliveryDate = () => {
    if (this.props.purchaseOrder.delivery_status === PurchaseOrderDeliveryStatus.Full) {
      return null;
    }
    const estimatedDate = getEstimatedDeliveryDateText(this.props.purchaseOrder);
    if (estimatedDate == null) {
      return null;
    }
    let classNames = [styles['icon-container']];
    if (this.props.purchaseOrder.status === PurchaseOrderStatus.Draft) {
      classNames = [...classNames, styles['draft']];
    }
    return (
      <div className={classNames.join(' ')}>
        <Tooltip
          trigger={
            <div className={styles['icon']}>
              <Icon regular type="calendar-alt" />
            </div>
          }
          label={
            <span className={styles['estimated-date']}>
              <FormattedMessage
                id="screens.work-order.estimated-purchase-order-delivery-date"
                values={{ date: getEstimatedDeliveryDateText(this.props.purchaseOrder) }}
              />
            </span>
          }
        />
      </div>
    );
  };

  renderPurchaseOrderNumber = () => {
    if (this.props.canViewPurchaseOrders) {
      return <div className={styles['number']}>#{this.props.purchaseOrder.number}</div>;
    }
    return null;
  };

  renderContent = () => (
    <div className={`${styles['item']} ${this.props.canViewPurchaseOrders ? styles['clickable'] : ''}`}>
      <div className={styles['content']}>
        {this.renderVendorName()}
        <div className={[styles['subtitle'], this.subtitleColorStyle()].join(' ')}>
          <div>{this.renderSubtitle()}</div>
          {this.renderEstimatedDeliveryDate()}
        </div>
      </div>
      {this.renderPurchaseOrderNumber()}
    </div>
  );

  render() {
    if (this.props.canViewPurchaseOrders) {
      return <Link to={`/purchase-orders/${this.props.purchaseOrder.id}`}>{this.renderContent()}</Link>;
    }
    return this.renderContent();
  }
}

function mapStateToProps(state, ownProps) {
  const workOrderPurchase = EntitySelectors.getWorkOrderPurchase(state, ownProps.workOrderPurchaseId);
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, workOrderPurchase.purchase_order);
  return {
    workOrderPurchase,
    purchaseOrder,
    vendor: EntitySelectors.getVendor(state, purchaseOrder.vendor),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
  };
}

export default connect(mapStateToProps)(PurchaseItem);
