import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { Decimal } from 'decimal.js';
import { normalizeUser } from 'sdk/Schemas';
import { SDKReduxOperations, API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { ToastMessage } from 'views/components/Shared/Layout';
import {
  Field,
  Button,
  List,
  NewSearchField,
  EmptyDataSet,
  Pagination,
  ContentLoader,
} from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

const TABS = {
  general: 'general',
  perUser: 'perUser',
};

const PAGE_SIZE = 6;

class EditLaborCostModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    labor_cost_per_hour: '',
    selectedTab: TABS.general,
    isFetchingMembers: true,
    membersSearchTerm: '',
    members: [],
    page: 1,
    totalPages: 0,
    isLoadingPagination: true,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        labor_cost_per_hour: this.props.settings.labor_cost_per_hour,
      });
    }
    if (prevState.selectedTab !== TABS.perUser && this.state.selectedTab === TABS.perUser) {
      this.fetchMembers();
    }
  }

  updateUserLaborCost = (userId, labor_cost_per_hour) => {
    this.props
      .updateUserSettings(this.props.system.id, userId, {
        labor_cost_per_hour,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState(prevState => ({
          members: prevState.members.map(member => {
            if (member.id === userId) {
              return {
                ...member,
                user_setting: {
                  ...member.user_setting,
                  labor_cost_per_hour: labor_cost_per_hour,
                },
              };
            }
            return member;
          }),
        }));
      });
  };

  fetchMembers = attrs => {
    let params = {
      member: true,
      archived: false,
      ...attrs,
      page_size: PAGE_SIZE,
      user_setting: 'labor_cost_per_hour',
    };
    if (this.state.membersSearchTerm.length > 0) {
      params = {
        ...params,
        search: this.state.membersSearchTerm,
      };
    } else {
      params = {
        ...params,
        page: this.state.page,
      };
    }

    this.setState({ isFetchingMembers: true });
    API.listUsers(this.props.system.id, params).then(res => {
      const { data, headers } = res;
      const { entities, result } = normalizeUser(data);
      this.props.updateEntities(entities);
      this.setState({
        isFetchingMembers: false,
        members: result.map(id => entities.userById[id]),
        totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        isLoadingPagination: false,
        isChangingPage: false,
      });
    });
  };

  save = () => {
    if (this.state.isSaving) return;
    this.setState({ isSaving: true });

    const { labor_cost_per_hour } = this.state;
    this.props
      .updateSettings(this.props.system.id, { labor_cost_per_hour })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);

        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  closeModal = () => {
    if (this.state.labor_cost_per_hour !== this.props.settings.labor_cost_per_hour) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderTabs = () => {
    return (
      <Modal.Header.TabBar>
        <Modal.Header.TabBarItem
          onClick={() => this.setState({ selectedTab: TABS.general })}
          active={this.state.selectedTab === TABS.general}
        >
          <FormattedMessage id="screens.settings.costs.labor-cost.edit-labor-cost-modal.tabs.general" />
        </Modal.Header.TabBarItem>
        <Modal.Header.TabBarItem
          onClick={() => this.setState({ selectedTab: TABS.perUser })}
          active={this.state.selectedTab === TABS.perUser}
        >
          <FormattedMessage id="screens.settings.costs.labor-cost.edit-labor-cost-modal.tabs.per-user" />
        </Modal.Header.TabBarItem>
      </Modal.Header.TabBar>
    );
  };

  renderGeneralCostContent = () => {
    return (
      <div className={styles['cost-field']}>
        <Field label={<FormattedMessage id="resources.labor-tariff.cost-per-hour" />}>
          <Field.Money
            currency={this.props.system.currency}
            value={this.state.labor_cost_per_hour}
            onBlur={labor_cost_per_hour => {
              this.setState({ labor_cost_per_hour });
            }}
            onChange={labor_cost_per_hour => {
              this.setState({ labor_cost_per_hour });
            }}
          />
        </Field>
      </div>
    );
  };

  renderUserListItem = user => {
    return (
      <List.Item>
        <List.Item.TitleColumn title={user.name} subtitle={user.email} flex />
        <List.Item.Column alignRight>
          <Field
            singleRow
            label={
              <span className={styles['list-cost-label']}>
                <FormattedMessage id="resources.labor-tariff.cost-per-hour" />
              </span>
            }
          >
            <div className={styles['cost-field']}>
              <Field.Money
                placeholder={
                  this.props.settings.labor_cost_per_hour
                    ? Math.floor(this.props.settings.labor_cost_per_hour)
                    : null
                }
                value={user.user_setting?.labor_cost_per_hour}
                currency={this.props.system.currency}
                onBlur={member_labor_cost_per_hour => {
                  const costPerhHour = user.user_setting.labor_cost_per_hour || -1;
                  const editedCostPerHour = member_labor_cost_per_hour || -1;
                  if (new Decimal(costPerhHour).equals(new Decimal(editedCostPerHour)) === false) {
                    this.updateUserLaborCost(user.id, member_labor_cost_per_hour);
                  }
                }}
              />
            </div>
          </Field>
        </List.Item.Column>
      </List.Item>
    );
  };

  renderLoadingUserListItem = key => (
    <List.Item key={key}>
      <List.Item.TitleColumn loading flex />
      <List.Item.Column alignRight>
        <Field
          singleRow
          label={
            <span className={styles['list-cost-label']}>
              <FormattedMessage id="resources.labor-tariff.cost-per-hour" />
            </span>
          }
        >
          <div className={styles['cost-field']}>
            <ContentLoader width={106} height={40} dark />
          </div>
        </Field>
      </List.Item.Column>
    </List.Item>
  );

  renderUserCostList = () => {
    if (this.state.isChangingPage) {
      return (
        <>
          <List.Header small background>
            <List.Header.Column flex>
              <FormattedMessage id="resources.user.resource" />
            </List.Header.Column>
          </List.Header>
          <List>{[...Array(PAGE_SIZE)].map((_, index) => this.renderLoadingUserListItem(index))}</List>
        </>
      );
    }
    if (this.state.isFetchingMembers) {
      return (
        <>
          <List.Header small background>
            <List.Header.Column flex>
              <FormattedMessage id="resources.user.resource" />
            </List.Header.Column>
          </List.Header>
          <List>
            {this.renderLoadingUserListItem(0)}
            {this.renderLoadingUserListItem(1)}
          </List>
        </>
      );
    }

    if (this.state.members.length === 0) {
      return (
        <div className={styles['empty-data-set']}>
          <EmptyDataSet
            noBackground
            title={<FormattedMessage id="general.empty-data-set-search.title" />}
            subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
            listContainer
          />
        </div>
      );
    }

    return (
      <>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.user.resource" />
          </List.Header.Column>
        </List.Header>
        <List>{this.state.members.map(user => this.renderUserListItem(user))}</List>
      </>
    );
  };

  renderPagination = () => {
    if (this.state.members.length === 0) return null;
    return (
      <Pagination
        style={{ justifyContent: 'flex-end', marginTop: 15 }}
        blue
        loading={this.state.isLoadingPagination}
        hideOptions
        currentPage={this.state.page ? Number(this.state.page) : 1}
        totalPages={this.state.totalPages}
        onSelectPage={page => {
          this.changePage(page);
        }}
      />
    );
  };

  renderPerUserCostContent = () => {
    return (
      <>
        <div className={styles['search-bar']}>
          <NewSearchField
            value={this.state.membersSearchTerm}
            small
            againstGrayBackground
            debounce
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            onSearch={value => {
              this.setState({
                membersSearchTerm: value,
                isFetchingMembers: true,
                page: 1,
              });
            }}
            onDebouncedSearch={value => {
              this.setState(
                {
                  membersSearchTerm: value,
                },
                () => {
                  this.fetchMembers({ search: this.state.membersSearchTerm });
                }
              );
            }}
            onClear={() => {
              this.setState({ membersSearchTerm: '' }, () => {
                this.fetchMembers();
              });
            }}
          />
        </div>
        {this.renderUserCostList()}
        {this.renderPagination()}
      </>
    );
  };

  renderContent = () => {
    switch (this.state.selectedTab) {
      case TABS.perUser:
        return this.renderPerUserCostContent();
      default:
        return this.renderGeneralCostContent();
    }
  };

  changePage = page => {
    this.setState({ isChangingPage: true, page }, () => {
      this.fetchMembers({ page });
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={680}>
        <Modal.Header
          title={<FormattedMessage id="screens.settings.costs.labor-cost.title" />}
          subtitle={<FormattedMessage id="screens.settings.costs.labor-cost.subtitle" />}
          onClose={this.closeModal}
          tabBarComponent={this.renderTabs()}
        />
        <Modal.Content>
          <div className={styles['content-container']}>{this.renderContent()}</div>
        </Modal.Content>
        {this.state.selectedTab === TABS.general && (
          <Modal.Footer>
            <Button.Group>
              <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
              <Button label="general.cancel" onClick={this.closeModal} />
            </Button.Group>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserSettings: SDKReduxOperations.updateUserSettings,
      updateSettings: SDKReduxOperations.updateSettings,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditLaborCostModal));
