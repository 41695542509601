const DOMAIN_PREFIX = 'settings/';

const FETCH_GROUPS_SUCCESS = `${DOMAIN_PREFIX}fetch_groups_success`;
const SEARCH_GROUPS = `${DOMAIN_PREFIX}search_groups`;

const FETCH_REQUEST_AUTOMATION_GROUPS_SUCCESS = `${DOMAIN_PREFIX}fetch_request_automation_groups_success`;

const FETCH_TEMPLATE_FIELDS_SUCCESS = `${DOMAIN_PREFIX}fetch_template_fields_success`;

const FETCH_WORK_ORDER_TYPES_SUCCESS = `${DOMAIN_PREFIX}fetch_work_order_types_success`;
const SEARCH_WORK_ORDER_TYPES = `${DOMAIN_PREFIX}search_work_order_types`;

const FETCH_DOWN_TIME_REASONS_SUCCESS = `${DOMAIN_PREFIX}fetch_downtimes_success`;

const SELECT_TEMPLATE_FIELD = `${DOMAIN_PREFIX}select_template_field`;
const UNSELECT_TEMPLATE_FIELD = `${DOMAIN_PREFIX}unselect_template_field`;
const PREPARE_NEW_FIELD = `${DOMAIN_PREFIX}prepare_new_field`;

const TEMPLATE_FIELD_SORTED = '/template_field_sorted';
const TEMPLATE_FIELD_SORTED_BETWEEN_COLUMNS = '/template_field_sorted_between_columns';
const REQUEST_AUTOMATION_RULE_SORTED = `${DOMAIN_PREFIX}request_automation_rule_sorted`;

const FETCH_ASSET_TYPES_SUCCESS = `${DOMAIN_PREFIX}search_asset_types_success`;
const SEARCH_ASSET_TYPES = `${DOMAIN_PREFIX}search_asset_types`;

const FETCH_SPARE_PART_TYPES_SUCCESS = `${DOMAIN_PREFIX}fetch_spare_part_types_success`;

const FETCH_REQUEST_TYPES_SUCCESS = `${DOMAIN_PREFIX}fetch_request_types_success`;
const SEARCH_REQUEST_TYPES = `${DOMAIN_PREFIX}search_request_types`;
const FETCH_REQUEST_SELECTABLE_ASSIGNEES_SUCCESS = `${DOMAIN_PREFIX}fetch_selectable_assignees_success`;
const FETCH_REQUEST_AUTOMATION_RULES_SUCCESS = `${DOMAIN_PREFIX}fetch_request_automation_rules_success`;
const SEARCH_SPARE_PART_TYPES = `${DOMAIN_PREFIX}search_spare_part_types`;

const FETCH_PURCHASE_ORDER_SHIPPING_ADDRESSES_SUCCESS = `${DOMAIN_PREFIX}fetch_purchase_order_shipping_adresses_success`;
export default {
  DOMAIN_PREFIX,
  FETCH_GROUPS_SUCCESS,
  SEARCH_GROUPS,
  FETCH_WORK_ORDER_TYPES_SUCCESS,
  SEARCH_WORK_ORDER_TYPES,
  FETCH_TEMPLATE_FIELDS_SUCCESS,
  SELECT_TEMPLATE_FIELD,
  UNSELECT_TEMPLATE_FIELD,
  FETCH_DOWN_TIME_REASONS_SUCCESS,
  PREPARE_NEW_FIELD,
  TEMPLATE_FIELD_SORTED,
  TEMPLATE_FIELD_SORTED_BETWEEN_COLUMNS,
  REQUEST_AUTOMATION_RULE_SORTED,
  FETCH_ASSET_TYPES_SUCCESS,
  SEARCH_ASSET_TYPES,
  FETCH_SPARE_PART_TYPES_SUCCESS,
  FETCH_REQUEST_TYPES_SUCCESS,
  SEARCH_SPARE_PART_TYPES,
  SEARCH_REQUEST_TYPES,
  FETCH_REQUEST_AUTOMATION_RULES_SUCCESS,
  FETCH_REQUEST_AUTOMATION_GROUPS_SUCCESS,
  FETCH_REQUEST_SELECTABLE_ASSIGNEES_SUCCESS,
  FETCH_PURCHASE_ORDER_SHIPPING_ADDRESSES_SUCCESS,
};
