import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FormattedMessage, injectIntl, FormattedPlural } from 'react-intl';
import { Button, PreviewOverlay, Icon } from 'views/components/Shared/General';
import WorkOrderListItem from './WorkOrderListItem';
import EstimatedTimeContainer from './EstimatedTimeContainer';
import { AssetSelectors, AssetOperations } from 'state/ducks/asset';
import styles from './style.module.scss';

class PreviewWorkOrders extends PureComponent {
  render() {
    const { previewMoreWorkOrdersOverlayData } = this.props;
    const { open, top, left, width, date } = previewMoreWorkOrdersOverlayData;
    return (
      <PreviewOverlay
        open={open}
        top={top}
        left={left}
        width={300}
        extraWidth={width}
        onClose={() => {
          this.props.hideMoreWorkOrdersPreviewOverlay();
        }}
      >
        <div className={styles['preview-work-orders']}>
          <div className={styles['header']}>
            <div className={styles['title-wrapper']}>
              <div className={styles['title-container']}>
                <span className={styles['day']}>{moment(date).format('D')}</span>
                <span className={styles['month']}> {moment(date).format('MMMM')}</span>
              </div>
              <div className={styles['buttons']}>
                <Button
                  type="icon"
                  icon={<Icon light size={18} type="times" />}
                  onClick={this.props.hideMoreWorkOrdersPreviewOverlay}
                />
              </div>
            </div>
            <div className={styles['subtitle-container']}>
              <FormattedPlural
                value={this.props.instances.length}
                one={
                  <FormattedMessage
                    id="screens.work-orders.preview-work-orders.amount-of-work-orders.one"
                    values={{ amount: this.props.instances.length }}
                  />
                }
                two={
                  <FormattedMessage
                    id="screens.work-orders.preview-work-orders.amount-of-work-orders.two"
                    values={{ amount: this.props.instances.length }}
                  />
                }
                few={
                  <FormattedMessage
                    id="screens.work-orders.preview-work-orders.amount-of-work-orders.few"
                    values={{ amount: this.props.instances.length }}
                  />
                }
                many={
                  <FormattedMessage
                    id="screens.work-orders.preview-work-orders.amount-of-work-orders.many"
                    values={{ amount: this.props.instances.length }}
                  />
                }
                other={
                  <FormattedMessage
                    id="screens.work-orders.preview-work-orders.amount-of-work-orders.other"
                    values={{ amount: this.props.instances.length }}
                  />
                }
              />
            </div>
          </div>
          <div className={styles['section']}>
            <div className={styles['section-title']}>
              <FormattedMessage id="screens.work-orders.preview-work-orders.work-orders-title" />
            </div>
            <div className={styles['subtitle']}>
              <EstimatedTimeContainer instances={this.props.instances} />
            </div>
          </div>
          <div className={styles['work-orders']}>
            {this.props.instances.map(instance => (
              <WorkOrderListItem instance={instance} date={date} />
            ))}
          </div>
        </div>
      </PreviewOverlay>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMoreWorkOrdersPreviewOverlay: AssetOperations.showMoreWorkOrdersPreviewOverlay,
      hideMoreWorkOrdersPreviewOverlay: AssetOperations.hideMoreWorkOrdersPreviewOverlay,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const previewMoreWorkOrdersOverlayData = AssetSelectors.getPreviewMoreWorkOrdersOverlayData(state);
  const { date } = previewMoreWorkOrdersOverlayData;
  return {
    instances: AssetSelectors.getInstancesForDate(state, date) || [],
    previewMoreWorkOrdersOverlayData: AssetSelectors.getPreviewMoreWorkOrdersOverlayData(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PreviewWorkOrders));
