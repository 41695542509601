import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Pagination, List, EmptyDataSet } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { Status } from 'sdk/ChecklistTemplateVersion';
import styles from './styles.module.scss';
import { normalizeChecklistTemplateVersion } from 'sdk/Schemas';
import OldVersionListItem from './OldVersionListItem';

class OldVersionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [],
      isLoading: false,
      pagination: {},
      page: 1,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.fetchChecklistVersions();
    }
  }

  fetchChecklistVersions = () => {
    this.setState({ isLoading: true });
    API.fetchTemplateChecklistVersionsForTemplate(this.props.templateId, {
      page: this.state.page,
      page_size: 8,
      status: `${Status.Active},${Status.Archived}`,
    }).then(res => {
      const { entities, result: ids } = normalizeChecklistTemplateVersion(res.data);
      const pagination = SDKHelperFunctions.getPaginationFromHeader(res.headers);
      this.props.updateEntities(entities);
      this.setState({
        ids,
        isLoading: false,
        pagination,
      });
    });
  };

  renderListItem = () => {
    return this.state.ids.map(templateId => {
      return (
        <OldVersionListItem
          id={templateId}
          onClose={this.props.onClose}
          currentVersion={templateId === this.props.templateId}
        />
      );
    });
  };

  renderPagination = () => {
    if (this.state.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.checklist.dropdown.old-version-modal.total-versions"
            values={{
              amount: this.state.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          currentPage={this.state.page}
          totalPages={this.state.pagination.totalPages}
          onSelectPage={page => {
            this.setState({ page }, () => this.fetchChecklistVersions());
          }}
        />
      </div>
    );
  };

  renderHasNoArchivedVersionsEmptyDatasSet = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.checklist.dropdown.old-version-modal.empty-data-set.title" />}
      subtitle={
        <FormattedMessage id="screens.checklist.dropdown.old-version-modal.empty-data-set.subtitle" />
      }
      modal
    />
  );

  renderContent = () => {
    if (this.state.isLoading) {
      return this.renderLoaders();
    }
    const hasNoArchivedVersions = this.state.ids.length <= 1 && this.state.page === 1;
    if (hasNoArchivedVersions) {
      return this.renderHasNoArchivedVersionsEmptyDatasSet();
    }
    return this.renderListItems();
  };

  renderListItems = () => {
    return (
      <>
        <List light>{this.renderListItem()}</List>
        <div className={styles['pagination-container']}>{this.renderPagination()}</div>
      </>
    );
  };

  renderLoaders = () => {
    return this.state.ids.length === 0 ? (
      <List light>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    ) : (
      <List light>
        {this.state.ids.map(() => {
          return (
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          );
        })}
      </List>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={550}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.checklist.dropdown.old-version-modal.title" />}
          subtitle={<FormattedMessage id="screens.checklist.dropdown.old-version-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(OldVersionsModal);
