import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, EmptyDataSet, List, Toggle } from 'views/components/Shared/General';
import { Toolbar, ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditSpentTimeCategoryModal from './EditSpentTimeCategoryModal';
import styles from './style.module.scss';

class SpentTimeCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpentTimeCategoryModal: false,
      showSpentTimeCategoryModalForSpentTimeCategoryId: null,
      createdIds: [],
      isSavingActivateCategories: false,
    };
  }

  updateSystem = params => {
    if (this.state.isSavingActivateCategories) return;
    this.setState({ isSavingActivateCategories: true });
    this.props
      .updateSystem(this.props.currentSystem.id, params)
      .then(() => {
        this.setState({ isSavingActivateCategories: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({ isSavingActivateCategories: false });
      });
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={
        <FormattedMessage id="screens.settings.work-orders.spent-time-categories.empty-data-set.title" />
      }
      subtitle={
        <FormattedMessage id="screens.settings.work-orders.spent-time-categories.empty-data-set.subtitle" />
      }
      button={
        <Button
          primary
          onClick={() =>
            this.setState({
              showSpentTimeCategoryModal: true,
              showSpentTimeCategoryModalForSpentTimeCategoryId: null,
            })
          }
          label="screens.settings.work-orders.spent-time-categories.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order-spent-time-category.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListItem = spentTimeCategory => (
    <List.Item
      clickable
      onClick={() =>
        this.setState({
          showSpentTimeCategoryModal: true,
          showSpentTimeCategoryModalForSpentTimeCategoryId: spentTimeCategory.id,
        })
      }
    >
      <List.Item.TitleColumn title={spentTimeCategory.title} />
      {spentTimeCategory.default ? (
        <List.Item.Column alignRight>
          <span className={styles['default']}>
            <FormattedMessage id="resources.work-order-spent-time-category.default" />
          </span>
        </List.Item.Column>
      ) : null}
    </List.Item>
  );

  renderSpentTimeCategories = () => {
    const newCategories = this.state.createdIds
      .filter(createdId => this.props.spentTimeCategories.find(({ id }) => id === createdId))
      .map(createdId => this.props.spentTimeCategories.find(({ id }) => id === createdId));

    const oldCategories = this.props.spentTimeCategories.filter(
      ({ id }) => !this.state.createdIds.includes(id)
    );
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showSpentTimeCategoryModal: true,
                  showSpentTimeCategoryModalForSpentTimeCategoryId: null,
                })
              }
              label="screens.settings.work-orders.spent-time-categories.add-button"
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {newCategories.map(spentTimeCategory => this.renderListItem(spentTimeCategory))}
          {oldCategories.map(spentTimeCategory => this.renderListItem(spentTimeCategory))}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.props.spentTimeCategories.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderSpentTimeCategories();
    }
  }

  render() {
    return (
      <>
        <BackButton to={this.props.fromCosts === true ? '/settings/costs' : '/settings/work-orders'} />
        <Section
          title={
            <FormattedMessage id="screens.settings.work-orders.spent-time-categories.activate-card.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.work-orders.spent-time-categories.activate-card.subtitle" />
          }
          rightComponent={
            <Toggle
              checked={this.props.currentSystem.work_order_spent_time_category_activated}
              loading={this.state.isSavingActivateCategories}
              onClick={() => {
                this.updateSystem({
                  work_order_spent_time_category_activated:
                    !this.props.currentSystem.work_order_spent_time_category_activated,
                });
              }}
            />
          }
        />
        <AnimateHeight
          duration={250}
          height={this.props.currentSystem.work_order_spent_time_category_activated ? 'auto' : 0}
        >
          <>
            <Separator />
            {this.renderContent()}
          </>
        </AnimateHeight>
        <EditSpentTimeCategoryModal
          open={this.state.showSpentTimeCategoryModal}
          id={this.state.showSpentTimeCategoryModalForSpentTimeCategoryId}
          onClose={() => this.setState({ showSpentTimeCategoryModal: false })}
          onCreate={id => {
            this.setState(prevState => ({
              showSpentTimeCategoryModal: false,
              createdIds: [id, ...prevState.createdIds],
            }));
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSystem: SDKReduxOperations.updateSystem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    spentTimeCategories: AuthSelectors.getWorkOrderSpentTimeCategories(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SpentTimeCategories));
