import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { ChecklistTemplateRow } from 'views/components/Checklist';

class Meter extends Component {
  isEditable = () => {
    const { isEditable, selectedMenuItem } = this.props;
    return isEditable === true && selectedMenuItem !== 'deviations';
  };

  render() {
    const { instanceRow, options } = this.props;
    const instanceRowId = instanceRow ? instanceRow.id : null;
    return (
      <ChecklistTemplateRow.Meter
        id={this.props.id}
        instanceRowId={instanceRowId}
        options={options}
        editable={this.isEditable()}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    instanceRow: ChecklistModalSelectors.getInstanceRowForTemplateRowId(state, ownProps.id),
    options: ChecklistModalSelectors.getChecklistInstanceOptionsForTemplateRowId(state, ownProps.id),
    isEditable: ChecklistModalSelectors.isEditable(state),
    selectedMenuItem: ChecklistModalSelectors.getSelectedMenuItem(state),
  };
}

export default connect(mapStateToProps)(Meter);
