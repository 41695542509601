import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Icon } from 'views/components/Shared/General';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import styles from './style.module.scss';

class SelectedSparePartItem extends Component {
  state = {
    showOptionsInlineModal: false,
    quantity: this.props.selectedSparePart.quantity,
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && this.state.quantity) {
      this.setState({
        showOptionsInlineModal: false,
        quantity: this.props.selectedSparePart.quantity,
      });
      this.props.updateQuantity(this.state.quantity);
    }
  };

  render() {
    const { sparePart, quantity } = this.props.selectedSparePart;
    return (
      <div className={styles['item-container']}>
        <div className={styles['item']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>{<Title sparePart={sparePart} />}</div>
          </div>
          <div className={styles['right-container']}>
            <div className={styles['quantity']}>
              <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={quantity} />
            </div>
            <div className={styles['buttons']}>
              <Button
                type="icon"
                onClick={this.props.onDelete}
                icon={<Icon regular red type="trash-alt" />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { sparePart } = ownProps.selectedSparePart;
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(SelectedSparePartItem);
