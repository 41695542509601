import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { HelperFunctions, SDKReduxOperations, API } from 'sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { WhiteCard, Button, Field, Tooltip, Icon } from 'views/components/Shared/General';
import { Grid, Modal, CardGrid, ToastMessage } from 'views/components/Shared/Layout';
import { DeliveryMethodDropdown, PaymentTermDropdown } from 'views/components/PurchaseOrder';
import { AuthSelectors } from 'state/ducks/auth';

class PurchaseOrderInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      isSaving: false,
      editingVendor: {},
      editingVendorBeforeEdit: {},
      showCurrencyConfirmModal: false,
    };
  }

  edit = () => {
    const editingVendor = {
      purchase_order_email: this.props.vendor.purchase_order_email,
      purchase_order_currency: this.props.vendor.purchase_order_currency,
      default_purchase_order_payment_term_id: this.props.vendor.default_purchase_order_payment_term_id,
      default_purchase_order_delivery_method_id: this.props.vendor.default_purchase_order_delivery_method_id,
    };

    this.setState({
      editing: true,
      editingVendor: editingVendor,
      editingVendorBeforeEdit: editingVendor,
    });
  };

  cancelEdit = () => {
    if (this.state.isSaving) return null;

    this.setState({ editing: false });
  };

  save = () => {
    this.setState({ isSaving: true });
    if (
      this.state.editingVendor.purchase_order_currency !==
      this.state.editingVendorBeforeEdit.purchase_order_currency
    ) {
      const params = {
        vendor_id: this.props.vendor.id,
        has_purchase_price: true,
      };
      API.listSparePartVendors(this.props.system.id, params).then(({ headers }) => {
        const pagination = HelperFunctions.getPaginationFromHeader(headers);
        if (pagination.totalEntries > 0) {
          this.setState({ showCurrencyConfirmModal: true, isSaving: false });
        } else {
          this.updateVendor();
        }
      });
    } else {
      this.updateVendor();
    }
  };

  updateVendor = () => {
    const params = this.getModifiedVendorData();
    this.setState({ isSaving: true });
    this.props
      .updateVendor(this.props.vendor.id, params)
      .then(() => {
        this.setState({ isSaving: false, editing: false, showCurrencyConfirmModal: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  getModifiedVendorData = () => {
    const vendor = this.state.editingVendor;
    const vendorBeforeEdit = this.state.editingVendorBeforeEdit;

    return Object.keys(vendor)
      .filter(key => vendor[key] !== vendorBeforeEdit[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: vendor[key],
        }),
        {}
      );
  };

  setVendorValue = obj => {
    const newEditingVendor = {
      ...this.state.editingVendor,
      ...obj,
    };

    this.setState({
      editingVendor: newEditingVendor,
    });
  };

  isLoaded = () => this.props.vendor != null;

  renderHeaderButtons = () => {
    if (this.state.editing || !this.props.canEditVendors) return null;

    return (
      <Tooltip
        trigger={
          <div>
            <Button type="icon" icon={<Icon regular type="pen" />} onClick={() => this.edit()} />
          </div>
        }
        label={<FormattedMessage id="general.edit" />}
      />
    );
  };

  renderCurrencyConfirmModal = () => (
    <Modal isOpen={this.state.showCurrencyConfirmModal} width={480}>
      <Modal.Header
        title={
          <FormattedMessage
            id="screens.vendor.information.general-information.currency-confirm-title"
            values={{
              currency: this.state.editingVendor.purchase_order_currency,
            }}
          />
        }
        subtitle={
          <FormattedMessage id="screens.vendor.information.general-information.currency-confirm-subtitle" />
        }
        subtitleTopMargin
        onClose={() => {
          this.setState({ showCurrencyConfirmModal: false });
        }}
        ignoreLine
      />
      <Modal.Footer>
        <Button.Group>
          <Button primary label="general.save" loading={this.state.isSaving} onClick={this.updateVendor} />
          <Button
            label="general.cancel"
            onClick={() => {
              this.setState({ showCurrencyConfirmModal: false });
            }}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderFooter = () => {
    if (!this.state.editing) return null;

    return (
      <Button.Group>
        <Button
          primary
          small
          label="general.save"
          loading={this.state.isSaving && !this.state.showCurrencyConfirmModal}
          onClick={this.save}
        />
        <Button small label="general.cancel" onClick={this.cancelEdit} />
      </Button.Group>
    );
  };

  renderCurrency = () => {
    const currency = this.props.vendor.purchase_order_currency;

    return (
      <span>
        <FormattedMessage id={`currencies.${currency}`} /> <span> - {currency}</span>
      </span>
    );
  };

  renderPaymentTermField = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.vendor.payment-term" />}>
          <PaymentTermDropdown
            editing
            value={this.state.editingVendor.default_purchase_order_payment_term_id}
            onChange={default_purchase_order_payment_term_id => {
              this.setVendorValue({ default_purchase_order_payment_term_id });
            }}
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.vendor.payment-term" />}>
        {this.props.purchaseOrderPaymentTerm?.title || '-'}
      </Field>
    );
  };

  renderDeliveryMethodField = () => {
    if (this.state.editing) {
      return (
        <Field view={false} label={<FormattedMessage id="resources.vendor.delivery-method" />}>
          <DeliveryMethodDropdown
            editing
            value={this.state.editingVendor.default_purchase_order_delivery_method_id}
            onChange={default_purchase_order_delivery_method_id => {
              this.setVendorValue({ default_purchase_order_delivery_method_id });
            }}
          />
        </Field>
      );
    }
    return (
      <Field view label={<FormattedMessage id="resources.vendor.delivery-method" />}>
        {this.props.purchaseOrderDeliveryMethod?.title || '-'}
      </Field>
    );
  };

  renderContent = () => {
    if (!this.isLoaded()) return null;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column md={6}>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.vendor.purchase-order-currency" />}
              >
                {this.state.editing ? (
                  <Field.Currency
                    value={this.state.editingVendor.purchase_order_currency}
                    onChange={purchase_order_currency => {
                      this.setVendorValue({ purchase_order_currency });
                    }}
                  />
                ) : (
                  this.renderCurrency() || '-'
                )}
              </Field>
            </Grid.Column>
            <Grid.Column md={6}>
              <Field
                view={!this.state.editing}
                label={<FormattedMessage id="resources.vendor.purchase-order-email" />}
              >
                {this.state.editing ? (
                  <Field.Text
                    type="email"
                    value={this.state.editingVendor.purchase_order_email}
                    onChange={purchase_order_email => {
                      this.setVendorValue({ purchase_order_email });
                    }}
                  />
                ) : (
                  this.props.vendor.purchase_order_email || '-'
                )}
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderPaymentTermField()}</Grid.Column>
            <Grid.Column md={6}>{this.renderDeliveryMethodField()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.vendor.create_purchase_orders === false) return null;
    return (
      <CardGrid.Row>
        <WhiteCard
          title={<FormattedMessage id="screens.vendor.purchase-order-information.title" />}
          headerButtons={this.renderHeaderButtons()}
          footer={this.renderFooter()}
        >
          {this.renderContent()}
        </WhiteCard>
        {this.renderCurrencyConfirmModal()}
      </CardGrid.Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateVendor: SDKReduxOperations.updateVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const vendorId = ownProps.match.params.id;
  const vendor = EntitySelectors.getVendor(state, vendorId);
  const purchaseOrderDeliveryMethod = EntitySelectors.getPurchaseOrderDeliveryMethod(
    state,
    vendor.default_purchase_order_delivery_method_id
  );
  const purchaseOrderPaymentTerm = EntitySelectors.getPurchaseOrderPaymentTerm(
    state,
    vendor.default_purchase_order_payment_term_id
  );
  return {
    vendor,
    purchaseOrderDeliveryMethod,
    purchaseOrderPaymentTerm,
    system: AuthSelectors.getCurrentSystem(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderInformation)));
