import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import Group from './Group';

const Button = props => {
  const renderCaret = () => {
    if (props.caret) {
      return (
        <div className={styles['caret']}>
          <Icon type="caret-down" />
        </div>
      );
    }
  }

  let classNames = [styles['button']];
  if (props.selected) {
    classNames = [...classNames, styles['selected']];
  }
  if (props.small) {
    classNames = [...classNames, styles['small']];
  }
  if (props.linkTo) {
    return (
      <Link className={classNames.join(' ')} to={props.linkTo}>
        {props.children}
        {renderCaret()}
      </Link>
    );
  }
  return (
    <div className={classNames.join(' ')} onClick={props.onClick}>
      {props.children}
      {renderCaret()}
    </div>
  );
};

Button.Group = Group;

export default Button;
