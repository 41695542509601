import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { isEqual, times } from 'lodash-es';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { ListLayout } from 'views/components/Shared/Layout';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import {
  Menu,
  List,
  Pagination,
  FilterButton,
  InlineModal,
  NewInlineModal,
  DatePicker,
} from 'views/components/Shared/General';
import { AssetMenu } from 'views/components/Asset';
import { ExportType } from 'sdk/Export';
import { CostType } from 'sdk/Cost';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { CostsSelectors, CostsOperations } from 'state/ducks/costs';
import FilterModal from './FilterModal';
import ExportModal from './ExportModal';
import CostListItem from './CostListItem';
import styles from './style.module.scss';

const listCostsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class Costs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isViewLoading: true,
      isSearching: false,
      showExportModal: false,
      showFilterModal: false,
      showDateFilterInlineModal: false,
      showCategoryFilterInlineModal: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.costs.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Costs);
    this.props.resetSelectedCosts();
    this.fetchCosts();
  }

  componentDidUpdate(prevProps) {
    const { showFilterModal } = this.state;
    const changedUrlSearchParams = !isEqual(prevProps.location.search, this.props.location.search);
    const changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    const changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.pagination.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchCosts();
      return;
    }
    if (
      changedUrlSearchParams ||
      changedQueryParams ||
      (changedAdvancedFilters && showFilterModal === false)
    ) {
      this.setState({ isSearching: true });
      this.props.resetSelectedCosts();
      this.fetchCosts();
    }
  }

  getParams = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    let attrs = {
      ...this.props.filtersAsQueryParams,
      sort: 'date',
      'sort-order': 'desc',
    };

    let queryParameters = {
      ...this.props.queryParameters,
    };
    if (queryParameters.from && queryParameters.to) {
      queryParameters = {
        ...queryParameters,
        to: null,
        from: null,
        date: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Between]: `${queryParameters.from}...${queryParameters.to}`,
        },
      };
    }
    attrs = {
      ...attrs,
      ...queryParameters,
    };

    if (asset_with_tree_children_id) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id,
      };
    }
    return attrs;
  };

  fetchCosts = () => {
    listCostsRequest.cancel();

    return this.props
      .fetchCosts(this.props.system.id, this.getParams(), listCostsRequest.getCancelTokenConfig())
      .then(costs => {
        this.setState({ isViewLoading: false, isSearching: false });
        return costs;
      });
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  changeQueryParams = obj => {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryParams,
        ...obj,
      })}`
    );
  };

  renderEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="screens.costs.empty-data-set.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="screens.costs.empty-data-set.subtitle" />
      </div>
    </div>
  );

  renderPagination = () => {
    if (this.props.costs.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.setState({ isSearching: true });
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.state.isSearching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.costs.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedCosts()}
        />
      );
    }
    return null;
  };

  renderListHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected, selectedCostCount } = this.props;
    const { isSearching } = this.state;
    return (
      <List.Header
        small
        checkbox
        background
        paddingRight={75}
        showMultipleOptions={selectedCostCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={isSearching}
            count={selectedCostCount}
            buttons={
              <>
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="screens.costs.header-buttons.export" />}
                  onClick={() =>
                    this.setState({
                      showExportModal: true,
                      showExportModalForType: ExportType.CostsXslx,
                    })
                  }
                />
              </>
            }
          />
        }
        checked={isSearching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isSearching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedCosts();
          } else {
            this.props.selectPage();
          }
        }}
      >
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.cost.resource" />
        </List.Header.Column>
        <List.Header.Column width={150} alignRight>
          <FormattedMessage id="resources.cost.category" />
        </List.Header.Column>
        <List.Header.Column width={150} alignRight>
          <FormattedMessage id="resources.cost.date" />
        </List.Header.Column>
        <List.Header.Column width={200} alignRight />
      </>
    );
  };

  renderList = () => {
    if (this.state.isSearching) {
      const amountOfCosts = this.props.costs.length === 0 ? 2 : this.props.costs.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfCosts)
              .fill()
              .map(() => (
                <CostListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.costs.length === 0) {
      return this.renderEmptyDataset();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.costs.map(({ id }) => {
            let checked = false;
            if (this.props.selectedCostIds[id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <CostListItem
                key={id}
                id={id}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onCheck={() => {
                  this.props.selectCost(id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.costs.title" />}
        totalEntries={
          <FormattedPlural
            value={this.props.pagination.totalEntries}
            zero={
              <FormattedMessage
                id="screens.costs.total-entries.zero"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
            one={
              <FormattedMessage
                id="screens.costs.total-entries.one"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
            two={
              <FormattedMessage
                id="screens.costs.total-entries.two"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
            few={
              <FormattedMessage
                id="screens.costs.total-entries.few"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
            many={
              <FormattedMessage
                id="screens.costs.total-entries.many"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
            other={
              <FormattedMessage
                id="screens.costs.total-entries.other"
                values={{ amount: this.props.pagination.totalEntries }}
              />
            }
          />
        }
      />
    );
  };

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        params={this.getParams()}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderAssetItems = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    return (
      <AssetMenu
        onSelectAssetId={assetId => {
          this.changeQueryParams({ asset_with_tree_children_id: assetId, list: null });
        }}
        selectedAssetId={asset_with_tree_children_id}
      />
    );
  };

  renderAssetContainer = () => {
    return <div className={styles['asset-container']}>{this.renderAssetItems()}</div>;
  };

  renderLeftMenu = () => {
    const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-items']}>
            <ListLayout.Content.Menu.Content>
              <Menu.Item
                linkTo="/costs"
                title={<FormattedMessage id="screens.costs.title" />}
                selected={asset_with_tree_children_id == null}
              />
            </ListLayout.Content.Menu.Content>
          </div>
          {this.renderAssetContainer()}
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderFilterDateButtonLabel = () => {
    const { from, to } = this.props.queryParameters;
    if (from && to) {
      return (
        <span>
          <span>{moment(from).format('L')}</span>
          <span> - </span>
          <span>{moment(to).format('L')}</span>
        </span>
      );
    }
    return <FormattedMessage id="screens.costs.filters.date" />;
  };

  renderDateFilterButton = () => {
    const { from, to } = this.props.queryParameters;
    return (
      <>
        <div ref={ref => (this.dateInlineModalPositioningRef = ref)}>
          <FilterButton
            filtered={from != null && to != null}
            onClear={e => {
              e.stopPropagation();
              this.addQueryParameter({ from: null, to: null });
              this.setState({ showDateFilterInlineModal: false });
            }}
            label={this.renderFilterDateButtonLabel()}
            onClick={() => {
              this.setState(prevState => ({
                showDateFilterInlineModal: !prevState.showDateFilterInlineModal,
              }));
            }}
          />
        </div>
        <InlineModal
          positionToRef={this.dateInlineModalPositioningRef}
          open={this.state.showDateFilterInlineModal}
          onClose={() => this.setState({ showDateFilterInlineModal: false })}
        >
          <InlineModal.Body width={337} paddingTop={20}>
            <DatePicker.Range
              inline
              onSelectDate={({ from, to }) => {
                this.addQueryParameter({ from, to });
                this.setState({ showDateFilterInlineModal: false });
              }}
              fromDate={from}
              toDate={to}
            />
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderFilterCategoryButtonLabel = () => {
    const { category } = this.props.queryParameters;
    switch (category) {
      case CostType.Part:
        return <FormattedMessage id="resources.cost.categories.part" />;
      case CostType.Labor:
        return <FormattedMessage id="resources.cost.categories.labor" />;
      case CostType.Other:
        return <FormattedMessage id="resources.cost.categories.other" />;
      default:
        return <FormattedMessage id="screens.costs.filters.category" />;
    }
  };

  renderCategoryFilterButton = () => {
    const { category } = this.props.queryParameters;
    return (
      <>
        <div ref={ref => (this.categoryInlineModalPositioningRef = ref)}>
          <FilterButton
            filtered={category != null}
            onClear={e => {
              e.stopPropagation();
              this.addQueryParameter({ category: null });
              this.setState({ showCategoryFilterInlineModal: false });
            }}
            label={this.renderFilterCategoryButtonLabel()}
            onClick={() => {
              this.setState(prevState => ({
                showCategoryFilterInlineModal: !prevState.showCategoryFilterInlineModal,
              }));
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.categoryInlineModalPositioningRef}
          open={this.state.showCategoryFilterInlineModal}
          minWidth={250}
          onClose={() => this.setState({ showCategoryFilterInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                selected={category === 'labor'}
                onClick={e => this.addQueryParameter({ category: 'labor' })}
              >
                <FormattedMessage id="resources.cost.categories.labor" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={category === 'part'}
                onClick={e => this.addQueryParameter({ category: 'part' })}
              >
                <FormattedMessage id="resources.cost.categories.part" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected={category === 'other'}
                onClick={e => this.addQueryParameter({ category: 'other' })}
              >
                <FormattedMessage id="resources.cost.categories.other" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderToolbarLeftContent = () => {
    return (
      <ListLayout.Content.MainContent.FilterBar.LeftContent>
        <FilterButton.Group>
          {this.renderDateFilterButton()}
          {this.renderCategoryFilterButton()}
        </FilterButton.Group>
      </ListLayout.Content.MainContent.FilterBar.LeftContent>
    );
  };

  renderToolbar = () => (
    <ListLayout.Content.MainContent.FilterBar>
      {this.renderToolbarLeftContent()}
      <ListLayout.Content.MainContent.FilterBar.RightContent>
        <FilterButton.Group>
          {this.props.isFiltering ? (
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="general.clean" />}
              onClick={() => {
                this.setState({ isSearching: true });
                this.props.resetFilter();
              }}
            />
          ) : null}
          <FilterButton
            clearable={false}
            caret={false}
            filtered={this.props.isFiltering}
            label={<FormattedMessage id="screens.spare-parts.filter" />}
            onClick={() => this.setState({ showFilterModal: true })}
          />
        </FilterButton.Group>
      </ListLayout.Content.MainContent.FilterBar.RightContent>
    </ListLayout.Content.MainContent.FilterBar>
  );

  renderMainContent = () => {
    if (this.state.isViewLoading) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderListHeader()}
            <List>
              <CostListItem loading />
              <CostListItem loading />
            </List>
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderExportModal()}
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.addQueryParameter({ page: 1 });
            this.props.resetSelectedCosts();
            this.setState({ isSearching: true });
            this.fetchCosts(params);
            this.setState({ showFilterModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCosts: CostsOperations.fetchCosts,
      addQueryParameter: CostsOperations.addQueryParameter,
      selectMenuItem: MenuOperations.selectItem,
      resetSelectedCosts: CostsOperations.resetSelectedCosts,
      selectCost: CostsOperations.selectCost,
      selectTotalEntries: CostsOperations.selectTotalEntries,
      hideSelectTotalEntries: CostsOperations.hideSelectTotalEntries,
      selectPage: CostsOperations.selectPage,
      resetFilter: CostsOperations.resetFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    costs: CostsSelectors.getCosts(state),
    queryParameters: CostsSelectors.getQueryParameters(state),
    filtersAsQueryParams: CostsSelectors.getFiltersAsQueryParams(state),
    filters: CostsSelectors.getFilters(state),
    pagination: CostsSelectors.getCostsPagination(state),
    selectedCostIds: CostsSelectors.getSelectedCostIds(state),
    selectedCostCount: CostsSelectors.getSelectedCostsCount(state),
    totalEntriesIsSelected: CostsSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: CostsSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: CostsSelectors.getPageIsSelected(state),
    isFiltering: CostsSelectors.isFiltering(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Costs)));
