import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import QrTemplateColumn from './QrTemplateColumn';
import styles from './style.module.scss';

class QrTemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  componentDidMount() {
    this.setRowsAndColumnsFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.qrTemplateId !== this.props.qrTemplateId) {
      this.setRowsAndColumnsFromProps();
    }
  }

  getInititalState = () => ({
    columns: [],
    rows: {},
  });

  setRowsAndColumnsFromProps = () => {
    let rows = {};
    this.props.qrTemplateColumns.forEach(column => {
      const { id, qr_template_rows } = column;
      rows = {
        ...rows,
        [id]: qr_template_rows,
      };
    });
    this.setState({
      columns: this.props.qrTemplateColumns.map(({ id }) => id),
      rows,
    });
  };

  getStylesFromQrTemplate = () => {
    const { width, height, padding_bottom, padding_top, padding_left, padding_right } = this.props.qrTemplate;
    return {
      width: `${width}mm`,
      height: `${height}mm`,
      maxHeight: `${height}mm`,
      maxWidth: `${width}mm`,
      minHeight: `${height}mm`,
      minWidth: `${width}mm`,
      paddingTop: `${padding_top}mm`,
      paddingLeft: `${padding_left}mm`,
      paddingRight: `${padding_right}mm`,
      paddingBottom: `${padding_bottom}mm`,
    };
  };

  getColumns = () => {
    if (this.props.columns) {
      return this.props.columns;
    }
    return this.state.columns;
  };

  getRows = () => {
    if (this.props.rows) {
      return this.props.rows;
    }
    return this.state.rows;
  };

  renderColumns = () => {
    return this.getColumns().map(id => (
      <QrTemplateColumn
        width={
          this.props.widthsForColumns && this.props.widthsForColumns[id] != null
            ? this.props.widthsForColumns[id]
            : null
        }
        preview={this.props.preview}
        selectedTemplateColumnId={this.props.selectedTemplateColumnId}
        selectedTemplateRowId={this.props.selectedTemplateRowId}
        id={id}
        key={id}
        rowIds={this.getRows()[id]}
        onSelectRow={id => this.props.onSelectRow(id)}
        onSelect={id => this.props.onSelectColumn(id)}
      />
    ));
  };

  renderContent = () => {
    return (
      <div className={styles['qr-template-editor']} style={this.getStylesFromQrTemplate()}>
        {this.renderColumns()}
      </div>
    );
  };

  render() {
    return this.renderContent();
  }
}

function mapStateToProps(state, ownProps) {
  const { qrTemplateId } = ownProps;
  const qrTemplate = EntitySelectors.getQrTemplate(state, qrTemplateId);
  return {
    qrTemplate,
    qrTemplateColumns: EntitySelectors.getQrTemplateColumns(state, qrTemplate.qr_template_columns),
  };
}

export default connect(mapStateToProps)(QrTemplateEditor);
