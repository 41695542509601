/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getUsers = state => {
  const { workOrderAssignee } = state;
  const { entities } = state;
  return workOrderAssignee.users.ids.map(userId => entities.userById[userId]);
};

const getGroups = state => {
  const { workOrderAssignee } = state;
  const { entities } = state;
  return workOrderAssignee.groups.ids.map(groupId => entities.groupById[groupId]);
};

const getVendors = state => {
  const { workOrderAssignee } = state;
  const { entities } = state;
  return workOrderAssignee.vendors.ids.map(vendorId => entities.vendorById[vendorId]);
};
/*
  --------------------
    UI SELECTORS
  --------------------
*/

const isFetchingUsers = state => state.workOrderAssignee.users.isFetching;
const isFetchingGroups = state => state.workOrderAssignee.groups.isFetching;
const isFetchingVendors = state => state.workOrderAssignee.vendors.isFetching;
const getVendorListPagination = state => state.workOrderAssignee.vendors.pagination;

export default {
  getUsers,
  getGroups,
  getVendors,
  isFetchingUsers,
  isFetchingGroups,
  isFetchingVendors,
  getVendorListPagination,
};
