import actions from './actions';
import { normalizeUser } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';

const addQueryParameter = actions.addQueryParameter;
const resetState = actions.resetState;

const fetchUsers = (orgId, params, config) => dispatch => {
  dispatch(actions.fetchUsers());
  return API.listUsersForOrganisation(orgId, params, config)
    .then(res => {
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      const { totalEntries } = pagination;
      const { entities, result } = normalizeUser(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchUsersSuccess({
          ids: result,
          pagination,
          totalEntries,
        })
      );

      return res;
    })
    .catch(e => {
      throw e;
    });
};

const fetchAndSetAmountOfPayingUsers = orgId => dispatch => {
  return API.listUsersForOrganisation(orgId, {
    billing_type: {
      [HelperFunctions.FILTER_COMPARABLES.Any]: 'full',
    },
  }).then(({ headers }) => {
    const { totalEntries } = HelperFunctions.getPaginationFromHeader(headers);
    dispatch(actions.setAmountOfPayingUsers(totalEntries));
  });
};

export default {
  fetchAndSetAmountOfPayingUsers,
  addQueryParameter,
  fetchUsers,
  resetState,
};
