import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Decimal from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class EditModal extends Component {
  state = {
    chosenType: 'current',
  };

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ chosenType: 'current' });
    }
  }

  renderCurrentSubtitle = () => {
    if (this.props.workOrder.due_date != null) {
      return (
        <FormattedMessage
          id="components.edit-work-order-modal.options.current.subtitle"
          values={{ date: moment(this.props.workOrder.due_date).format('LL') }}
        />
      );
    } else if (this.props.meter != null && this.props.workOrder.due_meter_value != null) {
      const meterValue = new Decimal(this.props.workOrder.due_meter_value).toDecimalPlaces(2).toString();
      if (this.props.meterUnit) {
        return (
          <FormattedMessage
            id="components.edit-work-order-modal.options.current.subtitle"
            values={{ date: `${meterValue} ${this.props.meterUnit.abbreviation}` }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id="components.edit-work-order-modal.options.current.subtitle"
            values={{ date: meterValue }}
          />
        );
      }
    } else {
      return <FormattedMessage id="components.edit-work-order-modal.options.current.subtitle-no-date" />;
    }
  };

  renderWorkOrdersTimeLine = () => {
    if (this.props.recurringMaintenance == null || this.props.recurringMaintenance.status === 'archived')
      return null;
    return (
      <div className={styles['work-orders-timeline']}>
        <div className={styles['overlay']} />
        {[...Array(9)].map((e, i) => {
          const selectedChosenType = this.state.chosenType === 'current' && i === 0;
          const selectedFutureType = this.state.chosenType === 'future';
          if (i === 0) {
            return (
              <div
                className={`${styles['work-order']} ${
                  selectedChosenType || selectedFutureType ? styles['selected'] : ''
                }`}
              >
                {selectedChosenType || selectedFutureType ? (
                  <React.Fragment>
                    <Icon type="wrench" />
                    <div className={styles['label-tooltip']}>
                      <FormattedMessage id="components.edit-work-order-modal.current-work-order-popup" />
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            );
          } else {
            return (
              <div
                className={`${styles['work-order']} ${
                  selectedChosenType || selectedFutureType ? styles['selected'] : ''
                }`}
              >
                {selectedChosenType || selectedFutureType ? <Icon type="wrench" /> : null}
              </div>
            );
          }
        })}
      </div>
    );
  };

  render() {
    const recurringMaintenanceIsActive =
      this.props.recurringMaintenance && this.props.recurringMaintenance.status === 'active';
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={
            recurringMaintenanceIsActive ? (
              <FormattedMessage id="components.edit-work-order-modal.is-recurring-title" />
            ) : (
              <FormattedMessage id="components.edit-work-order-modal.has-been-recurring-title" />
            )
          }
          subtitle={<FormattedMessage id="components.edit-work-order-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          {this.renderWorkOrdersTimeLine()}
          <div>
            <div
              className={styles['button']}
              onClick={this.props.onSelectRecurringWorkOrders}
              onMouseEnter={() => this.setState({ chosenType: 'future' })}
            >
              <div>
                <p className={styles['title']}>
                  {recurringMaintenanceIsActive ? (
                    <FormattedMessage id="components.edit-work-order-modal.options.future.title" />
                  ) : (
                    <FormattedMessage id="components.edit-work-order-modal.options.resume.title" />
                  )}
                </p>
                <p className={styles['subtitle']}>
                  {recurringMaintenanceIsActive ? (
                    <FormattedMessage id="components.edit-work-order-modal.options.future.subtitle" />
                  ) : (
                    <FormattedMessage id="components.edit-work-order-modal.options.resume.subtitle" />
                  )}
                </p>
              </div>
            </div>
            <div
              className={styles['button']}
              onClick={this.props.onSelectSingleWorkOrder}
              onMouseEnter={() => this.setState({ chosenType: 'current' })}
            >
              <div>
                <p className={styles['title']}>
                  <FormattedMessage id="components.edit-work-order-modal.options.current.title" />
                </p>
                <p className={styles['subtitle']}>{this.renderCurrentSubtitle()}</p>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }

  return {
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(
      state,
      ownProps.workOrder.recurring_maintenance
    ),
    meter,
    meterUnit,
  };
}

export default connect(mapStateToProps)(EditModal);
