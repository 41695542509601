import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { SDKReduxOperations } from 'sdk';
import { OperationalMaintenanceOperations } from 'state/ducks/operationalMaintenance';
import { EmptyDataSet, WhiteCard, Button, ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { PreviewChecklistTemplateModal } from 'views/components/Checklist';
import { OperationalMaintenanceSelectors } from 'state/ducks/operationalMaintenance';
import ChecklistTemplateRowContainer from './ChecklistTemplateRowContainer';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';

class Checklist extends Component {
  state = {
    isUpdatingChecklistVersion: false,
    showUpdateChecklistVerisonConfirmModal: false,
    showPreviewChecklistModal: false,
    showPreviewChecklistModalForTemplateId: null,
  };

  updateChecklistInstance = () => {
    this.setState({ isUpdatingChecklistVersion: true });
    this.props
      .updateToActiveVersion(this.props.checklistInstance.id)
      .then(() => {
        this.props
          .fetchOperationalMaintenanceInstance(this.props.operationalMaintenanceInstance.id)
          .then(() => {
            this.setState({
              isUpdatingChecklistVersion: false,
              showUpdateChecklistVerisonConfirmModal: false,
            });
          });
      })
      .catch(e => {
        this.setState({ isUpdatingChecklistVersion: false, showUpdateChecklistVerisonConfirmModal: false });
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
      });
  };

  renderUpdateChecklistModal = () => (
    <ConfirmModal
      open={this.state.showUpdateChecklistVerisonConfirmModal}
      title={<FormattedMessage id="components.checklist-instance-modal.update-version-confirm-modal.title" />}
      message={
        <FormattedMessage id="components.checklist-instance-modal.update-version-confirm-modal.description" />
      }
      confirmButtonText="components.checklist-instance-modal.update-version-confirm-modal.button"
      confirmIsLoading={this.state.isUpdatingChecklistVersion}
      onConfirm={() => {
        if (this.state.isUpdatingChecklistVersion) {
          return;
        }
        this.updateChecklistInstance();
      }}
      onCancel={() => {
        this.setState({ showUpdateChecklistVerisonConfirmModal: false });
      }}
    />
  );

  renderOutdatedVersionBanner = () => {
    const { checklistTemplateVersion, operationalMaintenanceInstance } = this.props;
    const checklistIsArchived =
      checklistTemplateVersion && checklistTemplateVersion.status === Status.Archived;
    const operationalMaintenanceIsActive =
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.NotStarted;
    if (checklistIsArchived && operationalMaintenanceIsActive) {
      let classNames = [styles['banner'], styles['outdated']];
      return (
        <>
          <div className={classNames.join(' ')}>
            <div className={styles['title']}>
              <FormattedMessage
                id="components.checklist-instance-modal.old-version-text"
                values={{
                  time: moment(this.props.checklistInstance.created_at).format('LL'),
                }}
              />
            </div>
            <div>
              <Button.Group>
                <Button
                  fontSize={12}
                  type="text"
                  label="general.preview"
                  onClick={() => this.setState({ showPreviewChecklistModal: true })}
                />
                <Button
                  fontSize={12}
                  type="text"
                  label="components.checklist-instance-modal.update-version"
                  onClick={() => this.setState({ showUpdateChecklistVerisonConfirmModal: true })}
                />
              </Button.Group>
            </div>
          </div>
          <div className={styles['separator']} />
        </>
      );
    }
    return null;
  };

  renderRows = () => {
    if (this.props.rootRowIds.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.checklist-instance-modal.empty-data-set.subtitle" />}
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }
    return this.props.rootRowIds.map((id, index) => {
      return (
        <ChecklistTemplateRowContainer
          id={id}
          key={id}
          prevTemplateRowId={this.props.rootRowIds[index - 1]}
          nextTemplateRowId={this.props.rootRowIds[index + 1]}
        />
      );
    });
  };

  renderPreviewChecklistTemplateModal = () => {
    return (
      <PreviewChecklistTemplateModal
        open={this.state.showPreviewChecklistModal}
        id={this.props.checklistInstance.checklist_template_id}
        onClose={() => this.setState({ showPreviewChecklistModal: false })}
        hideFooter
      />
    );
  };

  render() {
    return (
      <>
        {this.renderOutdatedVersionBanner()}
        {this.renderRows()}
        {this.renderUpdateChecklistModal()}
        {this.renderPreviewChecklistTemplateModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateToActiveVersion: SDKReduxOperations.updateToActiveVersion,
      fetchOperationalMaintenanceInstance:
        OperationalMaintenanceOperations.fetchOperationalMaintenanceInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const checklistInstances = EntitySelectors.getChecklistInstances(
    state,
    operationalMaintenanceInstance.checklist_instances
  );
  const checklistInstance = checklistInstances[0];
  return {
    rootRowIds: OperationalMaintenanceSelectors.getRootTemplateFieldIds(state),
    checklistInstance,
    checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistInstance.checklist_template_version_id
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
