const DOMAIN_PREFIX = 'purchaseOrder/';

const SET_PURCHASE_ORDER_ID = `${DOMAIN_PREFIX}set_purchase_order_id`;
const FETCH_DELIVERIES_SUCCESS = `${DOMAIN_PREFIX}fetch_deliveries_success`;
const FETCH_WORK_ORDER_PURCHASES_SUCCESS = `${DOMAIN_PREFIX}fetch_work_order_purchases_success`;
const FETCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_attachments_success`;

export default {
  SET_PURCHASE_ORDER_ID,
  FETCH_DELIVERIES_SUCCESS,
  FETCH_WORK_ORDER_PURCHASES_SUCCESS,
  FETCH_ATTACHMENTS_SUCCESS,
};
