import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import queryString from 'query-string';
import { Menu } from 'views/components/Shared/General';

class AssetMenuItem extends Component {
  render() {
    if (this.props.loading) {
      return (
        <>
          <Menu.Item loading />
        </>
      );
    } else {
      const { asset_with_tree_children_id } = queryString.parse(this.props.location.search);

      return (
        <Menu.Item
          title={this.props.asset.title}
          onClick={() => this.props.onSelect(this.props.asset.id)}
          onDoubleClick={this.props.asset.tree_has_children ? this.props.onClick : null}
          selected={asset_with_tree_children_id === this.props.id}
          subtitle={this.props.subtitle}
          rightComponent={
            this.props.asset.tree_has_children && !this.props.searchedAsset ? (
              <Menu.Item.NavigateButton
                onClick={() => {
                  this.props.onClick();
                }}
              />
            ) : null
          }
        />
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const asset = EntitySelectors.getAsset(state, ownProps.id);
  return {
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(AssetMenuItem));
