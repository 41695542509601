import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { bindActionCreators } from 'redux';
import { Loader } from 'views/components/Shared/General';
import uuid from 'uuid';
import { omit } from 'lodash-es';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Prompt } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { WorkOrderOperations, WorkOrderSelectors } from 'state/ducks/workOrder';
import { SDKReduxOperations, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { WorkOrderCategoryType } from 'sdk/System';
import { WorkOrderEditOperations } from 'state/ducks/workOrderEdit';
import { ErrorOperations } from 'state/ducks/error';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import {
  buildWorkOrderFromEditingWorkOrder,
  ReminderType,
  NotEditableKeys as WorkOrderNotEditableKeys,
} from 'sdk/WorkOrder';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { EditReminderModal, ReminderWarningModalForRecurringMaintenance } from 'views/components/WorkOrder';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { UploadAttachmentErrorModal } from 'views/components/Attachment';
import {
  buildRecurringMaintenanceFromEditingWorkOrder,
  NotEditableKeys as RecurringMaintenanceNotEditableKeys,
} from 'sdk/RecurringMaintenance';
import Header from './Header';
import InfoContainer from './InfoContainer';
import MetaInfoContainer from './MetaInfoContainer';
import CancelRepeatModal from '../CancelRepeatModal';
import AddButtons from './AddButtons';
import ItemTabBar from './ItemTabBar';
import styles from './style.module.scss';

class Edit extends Component {
  state = {
    isSaving: false,
    showCancelRepeatModal: false,
    showEditReminderModal: false,
    showUploadAttachmentErrorWarning: false,
    showReminderWarningModalForRecurringMaintenance: false,
    selectedTab: '',
  };

  componentDidMount() {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.props.fetchRecurringMaintenanceForEdit(recurringMaintenanceId);
    } else {
      this.props.fetchWorkOrderForEdit(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.removeFieldsFromErrors([
      'title',
      'date',
      'category',
      'priority',
      'description',
      'assignee',
      'asset_id',
      'work_order_type_id',
      'estimated_minutes',
    ]);
    this.props.resetImageLoaderCount();
    this.props.resetEditingWorkOrder();
  }

  /*
    Helper functions
  */

  isCompletedBeforeRegistration = () => this.props.editingWorkOrder.completed_before_registration;

  removedRepetition = () => {
    const { editingWorkOrder } = this.props;
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    let isEditingRecurringMaintenance = recurringMaintenanceId != null;
    let hasNoRecurringDateFrequency = editingWorkOrder.frequency == null;
    let hasNoRecurringMeterInterval = editingWorkOrder.meter_interval == null;
    if (isEditingRecurringMaintenance) {
      if (hasNoRecurringDateFrequency && hasNoRecurringMeterInterval) {
        return true;
      }
    }
    return false;
  };

  addedRepetition = () => {
    const { workOrderBeforeEdit, editingWorkOrder } = this.props;
    let isRecurringOnDate = editingWorkOrder.frequency != null;
    let isRecurringOnMeter = editingWorkOrder.meter_interval != null;
    return workOrderBeforeEdit.recurring_maintenance_id == null && (isRecurringOnDate || isRecurringOnMeter);
  };

  getEditingRecurringMaintenanceChanges = () => {
    let data = {};
    const editedRecurringMaintenance = buildRecurringMaintenanceFromEditingWorkOrder(
      this.props.editingWorkOrder
    );
    const diffProps = Object.keys(editedRecurringMaintenance).filter(
      k => editedRecurringMaintenance[k] !== this.props.recurringMaintenanceBeforeEdit[k]
    );
    diffProps.forEach(key => (data[key] = editedRecurringMaintenance[key]));
    if (
      this.props.recurringMaintenanceBeforeEdit.next_work_order_date === editedRecurringMaintenance.start_date
    ) {
      data = omit(data, 'start_date');
    }
    return omit(data, RecurringMaintenanceNotEditableKeys);
  };

  getEditingWorkOrderChanges = () => {
    let data = {};
    const editedWorkOrder = buildWorkOrderFromEditingWorkOrder(this.props.editingWorkOrder);
    const diffProps = Object.keys(editedWorkOrder).filter(
      k => editedWorkOrder[k] !== this.props.workOrderBeforeEdit[k]
    );
    diffProps.forEach(key => (data[key] = editedWorkOrder[key]));
    return omit(data, WorkOrderNotEditableKeys);
  };

  getChecklistTemplateLinksForWorkOrder = () => {
    const workOrderBeforeEditChecklistTemplateLinks =
      this.props.workOrderBeforeEditChecklistTemplateLinks || [];
    const workOrderBeforeEditChecklistInstances = this.props.workOrderBeforeEditChecklistInstances || [];
    return [
      ...workOrderBeforeEditChecklistTemplateLinks.map(({ checklist_template_id }) => ({
        checklist_template_id,
        checklist_instance_options: this.props.checklistInstanceOptions,
      })),
      ...workOrderBeforeEditChecklistInstances.map(({ checklist_template_id }) => ({
        checklist_template_id,
        checklist_instance_options: this.props.checklistInstanceOptions,
      })),
    ];
  };

  hasErrorsForPlannedWorkOrder = () => {
    const {
      work_order_category_required,
      work_order_description_required,
      work_order_asset_required,
      work_order_work_order_type_required,
      work_order_due_date_required,
      work_order_estimated_time_required,
      work_order_priority_required,
      work_order_assignee_required,
    } = this.props.settings;
    const {
      description,
      asset_id,
      priority,
      category,
      work_order_type_id,
      estimated_minutes,
      assigned_to_users,
      assigned_to_groups,
      assigned_to_vendors,
      meter_id,
      due_meter_value,
      date,
      frequency,
      meter_interval,
    } = this.props.editingWorkOrder;
    let errors = {};
    /*
      Required Fields
    */
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (work_order_category_required && category == null) {
        errors = { ...errors, category: 'general.errors.is-required' };
      }
    }
    if (work_order_description_required && (description == null || description.length === 0)) {
      errors = { ...errors, description: 'general.errors.is-required' };
    }
    if (work_order_asset_required && asset_id == null) {
      errors = { ...errors, asset_id: 'general.errors.is-required' };
    }
    if (work_order_work_order_type_required && work_order_type_id == null) {
      errors = { ...errors, work_order_type_id: 'general.errors.is-required' };
    }
    if (work_order_estimated_time_required && estimated_minutes == null) {
      errors = { ...errors, estimated_minutes: 'general.errors.is-required' };
    }
    if (
      (this.props.hasProTier === false || work_order_due_date_required) &&
      date == null &&
      due_meter_value == null
    ) {
      errors = { ...errors, date: 'general.errors.is-required' };
    }
    if (work_order_priority_required && priority == null) {
      errors = { ...errors, priority: 'general.errors.is-required' };
    }
    if (
      work_order_assignee_required &&
      assigned_to_users.length === 0 &&
      assigned_to_groups.length === 0 &&
      assigned_to_vendors.length === 0
    ) {
      errors = { ...errors, assignee: 'general.errors.is-required' };
    }

    /*
      General data inconsistencys
    */
    if (meter_id && due_meter_value == null) {
      errors = {
        ...errors,
        date: 'screens.work-order.errors.no-meter-due-value',
      };
    }
    if (this.props.editingWorkOrderIsRecurring) {
      if (meter_id == null) {
        if (date == null) {
          errors = {
            ...errors,
            date: 'screens.work-order.errors.no-date-selected',
          };
        }
        if (frequency == null) {
          errors = {
            ...errors,
            frequency: 'general.errors.is-required',
          };
        }
        let changedDate = false;
        if (
          this.props.recurringMaintenanceBeforeEdit == null ||
          this.props.recurringMaintenanceBeforeEdit.next_work_order_date !==
            buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder).start_date
        ) {
          changedDate = true;
        }
        const dueDateIsBeforeToday = moment(date).isBefore(moment().format('YYYY-MM-DD'), 'day');
        if (changedDate && dueDateIsBeforeToday) {
          errors = {
            ...errors,
            date: 'screens.work-order.errors.selected-date-in-past',
          };
        }
      } else {
        if (meter_interval == null) {
          errors = {
            ...errors,
            frequency: 'general.errors.is-required',
          };
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      this.props.setFieldErrors(errors);
      return true;
    }
    return false;
  };

  hasErrorsForCompletedWorkOrder = () => {
    const {
      work_order_category_required,
      work_order_asset_required,
      work_order_work_order_type_required,
      work_order_checklist_required,
      work_order_spent_time_required,
    } = this.props.settings;
    const {
      asset_id,
      category,
      work_order_type_id,
      completed_date,
      checklist_instances,
      work_order_spent_times,
    } = this.props.editingWorkOrder;
    let errors = {};
    if (this.props.settings.work_order_category_type !== WorkOrderCategoryType.None) {
      if (work_order_category_required && category == null) {
        errors = { ...errors, category: 'general.errors.is-required' };
      }
    }
    if (work_order_asset_required && asset_id == null) {
      errors = { ...errors, asset_id: 'general.errors.is-required' };
    }
    if (work_order_work_order_type_required && work_order_type_id == null) {
      errors = { ...errors, work_order_type_id: 'general.errors.is-required' };
    }
    if (work_order_checklist_required && checklist_instances == null) {
      errors = { ...errors, checklist_instances: 'general.errors.is-required' };
    }
    if (work_order_spent_time_required && work_order_spent_times == null) {
      errors = { ...errors, work_order_spent_times: 'general.errors.is-required' };
    }

    if (completed_date == null) {
      errors = {
        ...errors,
        completed_date: 'general.errors.is-required',
      };
    }

    if (Object.keys(errors).length > 0) {
      this.props.setFieldErrors(errors);
      return true;
    }
    return false;
  };

  hasErrors = () => {
    if (this.isCompletedBeforeRegistration()) {
      return this.hasErrorsForCompletedWorkOrder();
    } else {
      return this.hasErrorsForPlannedWorkOrder();
    }
  };

  saveWorkOrder = () => {
    if (this.hasErrors()) return;
    if (this.addedRepetition()) {
      if (this.props.editingWorkOrder.reminder_type === ReminderType.Exact) {
        this.setState({ showReminderWarningModalForRecurringMaintenance: true });
        return;
      }
      this.transformWorkOrderToRecurringMaintenance();
      return;
    }
    if (this.removedRepetition()) {
      this.setState({ showCancelRepeatModal: true });
      return;
    }

    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    if (recurringMaintenanceId) {
      this.updateRecurringMaintenance();
    } else {
      this.updateWorkOrder();
    }
  };

  updateRecurringMaintenance = () => {
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    const workOrderId = this.props.match.params.id;
    this.setState({ isSaving: true });
    let params = this.getEditingRecurringMaintenanceChanges();
    if (this.props.recurringMaintenanceBeforeEdit.status === 'archived') {
      params = {
        start_date: params.start_date || this.props.recurringMaintenanceBeforeEdit.next_work_order_date,
        meter_start_value:
          params.meter_start_value || this.props.recurringMaintenanceBeforeEdit.meter_next_value,
      };
    }

    this.props
      .updateRecurringMaintenance(recurringMaintenanceId, params)
      .then(() => {
        if (this.props.recurringMaintenanceBeforeEdit.status === 'archived') {
          return this.props.activateRecurringMaintenance(recurringMaintenanceId);
        } else {
          return Promise.resolve(null);
        }
      })
      .then(() => {
        return this.props.fetchWorkOrderForView(workOrderId);
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
        this.props.history.push({
          pathname: `/work-orders/${workOrderId}`,
        });
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  updateWorkOrder = () => {
    this.setState({ isSaving: true });
    const workOrderId = this.props.match.params.id;
    this.props
      .updateWorkOrder(workOrderId, this.getEditingWorkOrderChanges())
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
        this.props.history.push({
          pathname: `/work-orders/${workOrderId}`,
        });
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  createRegistrationAttachment = params => {
    const workOrderId = this.props.match.params.id;
    const recurringMaintenanceId = queryString.parse(this.props.location.search).recurring_maintenance_id;
    const uploadFileNameSpace = recurringMaintenanceId || workOrderId;
    const { description, title, extension, file, type, mime_type, link_url } = params;
    const apiCall = recurringMaintenanceId
      ? this.props.createRegistrationAttachmentForRecurringMaintenance(this.props.editingWorkOrder.id, {
          description,
          title,
          mime_type,
          type,
          link_url,
          extension,
        })
      : this.props.createRegistrationAttachmentForWorkOrder(this.props.editingWorkOrder.id, {
          description,
          title,
          mime_type,
          type,
          extension,
          link_url,
        });
    const temporaryId = uuid.v4();
    this.props.setResourceItemsSelectedTab('attachment');
    if (type === 'file') {
      this.props.beginUpload({ temporaryId, namespace: uploadFileNameSpace });
      apiCall.then(({ data: attachment }) => {
        return SDKHelperFunctions.uploadFileToS3(
          { url: attachment.attachment_version.upload_url, file, mime_type },
          ({ loaded, total }) => {
            this.props.updateUpload({
              id: attachment.id,
              loaded,
              total,
              temporaryId,
              namespace: uploadFileNameSpace,
            });
          }
        )
          .then(() => {
            return this.props
              .attachmentVersionUploaded(attachment.attachment_version.id, {
                workOrderId: this.props.match.params.id,
                recurringMaintenanceId: recurringMaintenanceId,
              })
              .then(() => {
                this.props.completeUpload({
                  id: attachment.id,
                  temporaryId,
                  namespace: uploadFileNameSpace,
                });
                toast(
                  <ToastMessage
                    success
                    text={<FormattedMessage id="screens.work-order.add-file-success" />}
                  />
                );
              });
          })
          .catch(e => {
            this.props.cancelUpload({ temporaryId, namespace: uploadFileNameSpace });
            this.setState({ showUploadAttachmentErrorWarning: true });
          });
      });
    } else {
      this.props.beginUpload({ temporaryId, namespace: uploadFileNameSpace });
      apiCall.then(({ data: attachment }) => {
        this.props.completeUpload({ id: attachment.id, temporaryId, namespace: uploadFileNameSpace });
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.add-file-success" />} />);
      });
    }
  };

  transformWorkOrderToRecurringMaintenance = () => {
    this.setState({ isSaving: true });
    const buildRecurringMaintenanceFromEditingWorkOrderParams = omit(
      buildRecurringMaintenanceFromEditingWorkOrder(this.props.editingWorkOrder),
      ['created_from_requests', 'checklist_templates']
    );

    const workOrderId = this.props.match.params.id;
    const params = {
      ...buildRecurringMaintenanceFromEditingWorkOrderParams,
      converted_from_work_order_id: workOrderId,
      spare_part_reservations: this.props.sparePartReservations.map(({ spare_part_id, quantity }) => ({
        spare_part_id,
        quantity,
      })),
      checklist_template_links: this.getChecklistTemplateLinksForWorkOrder(),
    };

    this.props
      .createRecurringMaintenance(this.props.currentSystem.id, params)
      .then(({ data: recurringMaintenance }) => {
        toast(<ToastMessage success text={<FormattedMessage id="screens.work-order.update-success" />} />);
        this.props.history.push({
          pathname: `/work-orders/${recurringMaintenance.generated_work_order.id}`,
        });
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  /*
    Render functions
  */

  renderAddButtons = () => {
    if (this.isCompletedBeforeRegistration()) {
      return null;
    }
    return (
      <>
        <CardGrid.Separator />
        <AddButtons onCreateRegistrationAttachment={params => this.createRegistrationAttachment(params)} />
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Prompt
          when={this.props.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <div className={styles['wrapper']}>
          <DropZone onDrop={data => this.createRegistrationAttachment(data)}>
            <PerfectScrollbar>
              <Header />
              <ContentContainer>
                {this.props.editingWorkOrder == null ? (
                  <Loader />
                ) : (
                  <>
                    <CardGrid>
                      <CardGrid.Column>
                        <CardGrid.Row>
                          <InfoContainer
                            onCreateRegistrationAttachment={params =>
                              this.createRegistrationAttachment(params)
                            }
                          />
                        </CardGrid.Row>
                        <ItemTabBar />
                        {this.renderAddButtons()}
                      </CardGrid.Column>
                      <CardGrid.Column width={268}>
                        <CardGrid.Row>
                          <MetaInfoContainer />
                        </CardGrid.Row>
                      </CardGrid.Column>
                    </CardGrid>
                    <EditReminderModal
                      open={this.state.showEditReminderModal}
                      editingWorkOrder={this.props.editingWorkOrder}
                      onSave={data => {
                        this.props.setEditingWorkOrderValues(data);
                      }}
                      onClose={() => {
                        this.setState({ showEditReminderModal: false });
                      }}
                    />
                  </>
                )}
              </ContentContainer>
            </PerfectScrollbar>
          </DropZone>
        </div>
        <div className={styles['save-container']}>
          <ContentContainer noPadding>
            <Button.Group>
              <Button
                small
                primary
                disabled={this.props.editingWorkOrder == null}
                loading={this.state.isSaving}
                onClick={this.saveWorkOrder}
                label="general.save"
              />
              <Button
                small
                label="general.cancel"
                onClick={() => this.props.history.push(`/work-orders/${this.props.match.params.id}`)}
              />
            </Button.Group>
          </ContentContainer>
        </div>
        <CancelRepeatModal
          workOrder={this.props.workOrderBeforeEdit}
          onArchiveRecurringMaintenance={deletedWorkOrderIds => {
            this.setState({ showCancelRepeatModal: false });
            if (deletedWorkOrderIds.includes(this.props.match.params.id)) {
              this.props.history.push(this.props.canAdministrateWorkOrders ? '/work-orders' : '/work-orders');
            } else {
              this.props.history.push(`/work-orders/${this.props.match.params.id}`);
            }
          }}
          open={this.state.showCancelRepeatModal}
          onClose={() => {
            this.setState({ showCancelRepeatModal: false });
          }}
        />
        <ReminderWarningModalForRecurringMaintenance
          open={this.state.showReminderWarningModalForRecurringMaintenance}
          onClose={() => {
            this.setState({ showReminderWarningModalForRecurringMaintenance: false });
            setTimeout(() => {
              this.setState({ showEditReminderModal: true });
            }, 250);
          }}
        />
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateWorkOrder: SDKReduxOperations.updateWorkOrder,
      setResourceItemsSelectedTab: WorkOrderEditOperations.setResourceItemsSelectedTab,
      createRecurringMaintenance: SDKReduxOperations.createRecurringMaintenance,
      updateRecurringMaintenance: SDKReduxOperations.updateRecurringMaintenance,
      activateRecurringMaintenance: SDKReduxOperations.activateRecurringMaintenance,
      fetchWorkOrderForEdit: WorkOrderOperations.fetchWorkOrderForEdit,
      resetEditingWorkOrder: WorkOrderOperations.resetEditingWorkOrder,
      fetchRecurringMaintenanceForEdit: WorkOrderOperations.fetchRecurringMaintenanceForEdit,
      fetchWorkOrderForView: WorkOrderOperations.fetchWorkOrderForView,
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      setFieldErrors: ErrorOperations.setFieldErrors,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      resetImageLoaderCount: WorkOrderEditOperations.resetImageLoaderCount,
      createRegistrationAttachmentForRecurringMaintenance:
        SDKReduxOperations.createRegistrationAttachmentForRecurringMaintenance,
      createRegistrationAttachmentForWorkOrder: SDKReduxOperations.createRegistrationAttachmentForWorkOrder,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderBeforeEdit = EntitySelectors.getWorkOrder(state, workOrderId);
  const workOrder = recurringMaintenanceId
    ? EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId)
    : EntitySelectors.getWorkOrder(state, workOrderId);

  let checklistInstanceOptions = [];
  const workOrderBeforeEditChecklistInstances = EntitySelectors.getChecklistInstances(
    state,
    workOrderBeforeEdit.checklist_instances
  );
  const workOrderBeforeEditChecklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    workOrderBeforeEdit.checklist_template_links
  );
  if (workOrderBeforeEditChecklistInstances && workOrderBeforeEditChecklistInstances.length === 1) {
    const checklistInstance = workOrderBeforeEditChecklistInstances[0];
    checklistInstanceOptions = EntitySelectors.getChecklistInstanceOptions(
      state,
      checklistInstance.checklist_instance_options
    );
  } else if (
    workOrderBeforeEditChecklistTemplateLinks &&
    workOrderBeforeEditChecklistTemplateLinks.length === 1
  ) {
    const checklistTemplateLink = workOrderBeforeEditChecklistTemplateLinks[0];
    checklistInstanceOptions = EntitySelectors.getChecklistInstanceOptions(
      state,
      checklistTemplateLink.checklist_instance_options
    );
  }
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    workOrderBeforeEdit,
    editingWorkOrderIsRecurring: WorkOrderSelectors.editingWorkOrderIsRecurring(state),
    recurringMaintenanceBeforeEdit: EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId),
    editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
    sparePartReservations: EntitySelectors.getSparePartReservations(state, workOrder.spare_part_reservations),
    workOrderBeforeEditChecklistTemplateLinks,
    workOrderBeforeEditChecklistInstances,
    checklistInstanceOptions,
    hasUnsavedChanges: WorkOrderSelectors.hasUnsavedChanges(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit));
