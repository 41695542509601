const DOMAIN_PREFIX = 'workOrder/';

const RESET_STATE = `${DOMAIN_PREFIX}reset_state`;

const FETCH_WORK_ORDER = `${DOMAIN_PREFIX}fetch_work_order`;
const RESET_EDITING_WORK_ORDER = `${DOMAIN_PREFIX}reset_editing_work_order`;
const SET_WORK_ORDER_FOR_EDIT = `${DOMAIN_PREFIX}set_work_order_for_edit`;
const SET_EDITING_WORK_ORDER_VALUES = `${DOMAIN_PREFIX}set_editing_work_order_values`;

const FETCH_PREVIOUS_WORK_ORDERS = `${DOMAIN_PREFIX}fetch_previous_work_orders`;
const FETCH_PREVIOUS_WORK_ORDERS_SUCCESS = `${DOMAIN_PREFIX}fetch_previous_work_orders_success`;

const FETCH_WORK_ORDER_ACTIVITIES_SUCCESS = `${DOMAIN_PREFIX}fetch_work_order_activities_success`;

export default {
  RESET_STATE,
  FETCH_WORK_ORDER,
  RESET_EDITING_WORK_ORDER,
  SET_EDITING_WORK_ORDER_VALUES,
  FETCH_WORK_ORDER_ACTIVITIES_SUCCESS,
  SET_WORK_ORDER_FOR_EDIT,
  FETCH_PREVIOUS_WORK_ORDERS,
  FETCH_PREVIOUS_WORK_ORDERS_SUCCESS,
};
