import HelperFunctions from 'utilities/HelperFunctions';

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getPurchaseOrders = state => {
  const { entities } = state;
  if (!state.purchaseOrders.ids) return [];
  return state.purchaseOrders.ids.map(id => entities.purchaseOrderById[id]);
};

const isSearching = state => state.purchaseOrders.isSearching;
const getPagination = state => state.purchaseOrders.pagination;

const getNavigatedTo = state => {
  const { purchaseOrders } = state;
  return purchaseOrders.navigatedTo;
};

/*
  Filter
*/

const getQueryParameters = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.queryParams;
};

const getFilters = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.filters;
};

const getFiltersAsQueryParams = (state, key) => {
  const { purchaseOrders } = state;
  return HelperFunctions.buildFilter(purchaseOrders.filters);
};

const getAppliedFilters = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.filters[key];
};

const isFiltering = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.appliedFilters.length > 0;
};

const showingAppliedFilters = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.showingAppliedFilters;
};

const getSelectedFilterType = (state, key) => {
  const { purchaseOrders } = state;
  return purchaseOrders.showApplyFilterForField;
};

/*
  MassEdit
*/

const getSelectedPurchaseOrderIds = state => {
  return state.purchaseOrders.selectedPurchaseOrders.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.purchaseOrders.selectedPurchaseOrders.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.purchaseOrders.selectedPurchaseOrders.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.purchaseOrders.selectedPurchaseOrders.pageIsSelected;
};

const getSelectedPurchaseOrderCount = state => {
  if (state.purchaseOrders.selectedPurchaseOrders.totalEntriesAreSelected) {
    return state.purchaseOrders.pagination.totalEntries;
  }
  return Object.keys(state.purchaseOrders.selectedPurchaseOrders.ids).length;
};

export default {
  getFiltersAsQueryParams,
  getAppliedFilters,
  getQueryParameters,
  getFilters,
  getFiltersForKey,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
  getSelectedPurchaseOrderIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPurchaseOrders,
  getSelectedPurchaseOrderCount,
  getPageIsSelected,
  isSearching,
  getPagination,
  getNavigatedTo,
};
