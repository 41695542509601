import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { UserNameWrapper } from 'views/components/User';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import styles from './style.module.scss';
import MoreOptionsInlineModal from './MoreOptionsInlineModal';

const SparePartWithdrawalListItem = ({
  createdByUser,
  sparePartWithdrawal,
  sparePartUnit,
  canCarryOutWorkOrders,
}) => {
  return (
    <div className={styles['withdrawal']}>
      <div className={styles['info']}>
        <span className={styles['label']}>
          <FormattedMessage
            id="screens.work-order.used-spare-parts.withdrawals-by-label"
            values={{
              date: (
                <span className={styles['date']}>{moment(sparePartWithdrawal.created_at).format('LLL')}</span>
              ),
              name: (
                <span className={styles['name']}>
                  <UserNameWrapper user={createdByUser} />
                </span>
              ),
            }}
          />
        </span>
      </div>
      <div className={styles['title']}>
        <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePartWithdrawal.quantity} />
      </div>
      {canCarryOutWorkOrders ? (
        <div>
          <MoreOptionsInlineModal sparePartUnit={sparePartUnit} sparePartWithdrawal={sparePartWithdrawal} />
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    createdByUser: EntitySelectors.getUser(state, ownProps.sparePartWithdrawal.created_by_user_id),
  };
}

export default connect(mapStateToProps)(SparePartWithdrawalListItem);
