import types from './types';

const addSparePart = payload => ({
  type: types.ADD_SPARE_PART,
  payload,
});

const removeSparePart = payload => ({
  type: types.REMOVE_SPARE_PART,
  payload,
});

const updateSparePart = payload => ({
  type: types.UPDATE_SPARE_PART,
  payload,
});

const addMultipleSpareParts = payload => ({
  type: types.ADD_MULTIPLE_SPARE_PARTS,
  payload,
});

const removeMultipleSpareParts = payload => ({
  type: types.REMOVE_MULTIPLE_SPARE_PARTS,
  payload,
});

const setVendor = payload => ({
  type: types.SET_VENDOR,
  payload,
});

const cancelPurchaseOrder = () => ({
  type: types.CANCEL_PURCHASE_ORDER,
});

const resetState = () => ({
  type: types.RESET_STATE,
});

export default {
  addSparePart,
  setVendor,
  addMultipleSpareParts,
  removeMultipleSpareParts,
  removeSparePart,
  updateSparePart,
  resetState,
  cancelPurchaseOrder,
};
