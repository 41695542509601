import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.operationalMaintenanceTemplateRecurrenceVersionById) {
        return HelperFunctions.mergeEntities(
          state,
          action.payload.operationalMaintenanceTemplateRecurrenceVersionById
        );
      }
      return state;
    default:
      return state;
  }
};
