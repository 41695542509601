import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import {
  FullScreenImagePicker,
  Button,
  Field,
  InlineModal,
  Icon,
  FileUploadWrapper,
  NewInlineModal,
  ViewTextArea,
  Tooltip,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { TreePath } from 'views/components/Asset';
import {
  ChoosePriorityInlineModal,
  WorkOrderTypeInlineModal,
  ChooseAssigneeInlineModal,
  SelectedAssignees,
  EditReminderModal,
  ReminderMessage,
  CategoryInlineModal,
} from 'views/components/WorkOrder';
import {
  NewAttachmentModal,
  AttachmentOptionsInlineModal,
  AttachmentItem,
} from 'views/components/Attachment';
import {
  ChecklistEditModal,
  ChecklistTitleModal,
  SelectChecklistModal,
  ChecklistOptionsModal,
} from 'views/components/Checklist';
import { AssetTitle, ChooseAssetInlineModal, SelectAssetAttachmentModal } from 'views/components/Asset';
import { SelectMeterModal } from 'views/components/Meter';
import { Image, Loader } from 'views/components/Image';
import { NewWorkOrderSelectors } from 'state/ducks/newWorkOrder';
import { getNewWeekdays } from 'sdk/RecurringMaintenance';
import { ReminderType, WorkOrderCategory } from 'sdk/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import { WorkOrderCategoryType } from 'sdk/System';
import { SDKReduxOperations, API } from 'sdk';
import { AttachmentType } from 'sdk/Attachment';
import HelperFunctions from 'utilities/HelperFunctions';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import DueDate from './DueDate';
import AssetAttachmentOptionsInlineModal from './AssetAttachmentOptionsInlineModal';
import RecurringContainer from './RecurringContainer';
import ChecklistTemplateContainer from './ChecklistTemplateContainer';
import CantEditReusableChecklistModal from './CantEditReusableChecklistModal';
import styles from '../style.module.scss';

class NewWorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      showingImageDialog: false,
      title: this.props.editingWorkOrder.title || '',
      description: this.props.editingWorkOrder.description || '',
      showAssetAttachmentContent: false,
      showNewAttachmentModalForType: null,
      showNewFileInlineModalOptions: false,
      deletingImageIds: {},
      uploadingCount: 0,
      draftTemplateId: null,
      showChecklistTitleModal: false,
      showChecklistEditModal: false,
      showChecklistEditModalForVersionId: null,
      showNewChecklistDropdown: false,
      showSelectChecklistTemplateModal: false,
      showChecklistDropdown: false,
      showDeleteContentForChecklistDropdown: false,
      showCantEditReusableChecklistModal: false,
      showSelectMeterModal: false,
      showMeterDropdownInlineModal: false,
      showChecklistOptionsModal: false,
      showChecklistOptionsModalForChecklistTemplateId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editingWorkOrder.status !== this.props.editingWorkOrder.status) {
      this.titleInputRef.focus();
    }
  }

  addDraftImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    this.props
      .createDraftImage(this.props.currentSystem.id, { image: file, mime_type, name })
      .then(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
        //TODO: Replace with generic error message
      });
  };

  deleteImage = image => {
    this.props.onChangeValue({
      registration_images: this.props.editingWorkOrder.registration_images.filter(
        imageId => image.id !== imageId
      ),
      completion_images: this.props.editingWorkOrder.completion_images.filter(
        imageId => image.id !== imageId
      ),
    });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  addAttachmentFromAsset = attachmentId => {
    this.props.onChangeValue({
      registration_attachments: [attachmentId, ...this.props.editingWorkOrder.registration_attachments],
    });
  };

  changeTitle = value => {
    if (value.length > 0 && this.props.fieldErrors.title) {
      this.props.removeFieldsFromErrors(['title']);
    }
    this.setState({ title: value });
  };

  selectDate = date => {
    this.props.removeFieldsFromErrors(['date']);
    if (this.props.editingWorkOrderIsRecurring) {
      const newWeekdays = getNewWeekdays(this.props.editingWorkOrder, date);
      this.props.onChangeValue({ date, on_occasion: false, ...newWeekdays });
    } else {
      this.props.onChangeValue({ date, on_occasion: false });
    }
  };

  createDraftChecklistTemplate = title => {
    this.setState({
      draftTemplateId: null,
      showChecklistTitleModal: false,
      showChecklistEditModalForVersionId: null,
      showNewChecklistDropdown: false,
      showChecklistEditModal: true,
    });
    this.props.onShowChecklistModal();
    this.props
      .createChecklistTemplate(this.props.currentSystem.id, { title })
      .then(({ data: checklistTemplate }) => {
        this.setState({
          draftTemplateId: checklistTemplate.id,
          showChecklistEditModalForVersionId: checklistTemplate.draft_version.id,
        });
      });
  };

  editChecklistTemplate = () => {
    if (this.state.draftTemplateId) {
      this.setState({
        showChecklistDropdown: false,
        showChecklistEditModal: true,
        showChecklistEditModalForVersionId: null,
      });
      API.fetchChecklistTemplate(this.state.draftTemplateId).then(({ data: checklistTemplate }) => {
        const { draft_version } = checklistTemplate;
        this.setState({ showChecklistEditModalForVersionId: draft_version.id });
      });
    } else {
      this.setState({ showChecklistDropdown: false, showCantEditReusableChecklistModal: true });
    }
  };

  saveChecklistModal = () => {
    this.props.onHideChecklistModal();
    if (this.props.editingWorkOrder.checklist_template_links.length === 0) {
      this.props.onChangeValue({
        checklist_template_links: [{ checklist_template_id: this.state.draftTemplateId }],
      });
      this.setState({ showChecklistEditModal: false });
    } else {
      this.setState({ showChecklistEditModal: false });
    }
  };

  /*
    Render functions
  */

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader />
    );
    return (
      <React.Fragment>
        {this.props.images.map((image, index) => {
          return (
            <Image
              key={image.id}
              medium
              isDeleting={this.state.deletingImageIds[image.id] != null}
              editable
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
              onDelete={() => {
                this.deleteImage(image);
              }}
            />
          );
        })}
        {loaderElements}
        {this.props.requestImages.map((image, index) => {
          return (
            <div className={styles['request-image']}>
              <Image
                key={image.id}
                medium
                editable={false}
                image={image}
                onClick={() => {
                  this.selectImage(this.props.images.length + index);
                }}
              />
              <div className={styles['icon']}>
                <Tooltip
                  trigger={
                    <div>
                      <Icon regular type="exclamation-circle" />
                    </div>
                  }
                  label={<FormattedMessage id="components.new-work-order-modal.image-from-request" />}
                />
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addDraftImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderRegistrationAttachmentsContainer = () => {
    const { registrationAttachments, uploadingAttachments, asset } = this.props;
    return (
      <Field label={<FormattedMessage id="resources.work-order.files" />}>
        {registrationAttachments.length === 0 && uploadingAttachments.length === 0 ? null : (
          <AttachmentItem.Container>
            {uploadingAttachments.map(attachment => (
              <AttachmentItem namespace={this.props.editingWorkOrder.id} uploading attachment={attachment} />
            ))}
            {registrationAttachments.map(attachment => {
              if (attachment.type === AttachmentType.Asset) {
                return (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    subtitle={
                      <div className={styles['subtitle-container']}>
                        <Icon type="box" />
                        <p>
                          <FormattedMessage id="screens.work-order.file-from-asset" />
                        </p>
                      </div>
                    }
                    buttons={
                      <AssetAttachmentOptionsInlineModal
                        attachment={attachment}
                        onRemoveAssetAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                        trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                      />
                    }
                    onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
                  />
                );
              } else {
                return (
                  <AttachmentItem
                    key={attachment.id}
                    attachment={attachment}
                    buttons={
                      <AttachmentOptionsInlineModal
                        context={
                          this.props.editingWorkOrderIsRecurring ? 'recurringMaintenance' : 'workOrder'
                        }
                        attachment={attachment}
                        isDeletingAttachment={this.state.isDeletingAttachment}
                        onDeleteAttachment={attachment => this.props.onDeleteAttachment(attachment)}
                        trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
                      />
                    }
                    onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
                  />
                );
              }
            })}
          </AttachmentItem.Container>
        )}
        <div
          className={styles['add-button-container']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() =>
            this.setState(prevState => ({
              showNewFileInlineModalOptions: !prevState.showNewFileInlineModalOptions,
            }))
          }
        >
          <Button type="text" label="general.add" primary noUnderline />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showNewFileInlineModalOptions}
          onClose={() => this.setState({ showNewFileInlineModalOptions: false })}
        >
          <React.Fragment>
            <InlineModal.Body width={250} dropdown>
              <InlineModal.ListItem
                icon="upload"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.file-from-computer" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'file',
                  });
                }}
              />
              <InlineModal.ListItem
                icon="link"
                iconThickness="regular"
                title={<FormattedMessage id="screens.asset.attachments.hyperlink" />}
                onClick={() => {
                  this.setState({
                    showNewFileInlineModalOptions: false,
                    showNewAttachmentModalForType: 'link',
                  });
                }}
              />
              {asset == null ? null : (
                <InlineModal.ListItem
                  icon="box"
                  iconThickness="regular"
                  title={
                    <FormattedMessage
                      id="screens.asset.attachments.choose-from-asset"
                      values={{ asset: asset.title }}
                    />
                  }
                  onClick={() => {
                    this.setState({
                      showNewFileInlineModalOptions: false,
                      showAssetAttachmentContent: true,
                    });
                  }}
                />
              )}
            </InlineModal.Body>
          </React.Fragment>
        </InlineModal>
      </Field>
    );
  };

  renderSelectedPriority = () => {
    switch (this.props.editingWorkOrder.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };

  renderAssignee = () => {
    const { assigned_to_users, assigned_to_groups, assigned_to_vendors } = this.props.editingWorkOrder;
    let hasNoAssignees = [...assigned_to_users, ...assigned_to_groups, ...assigned_to_vendors].length === 0;
    return (
      <Field label={<FormattedMessage id="resources.work-order.assignee" />}>
        <ChooseAssigneeInlineModal
          hideArchivedUsers
          multiple
          trigger={
            <Field.Resource
              angleDown
              error={this.props.fieldErrors.assignee}
              value={
                hasNoAssignees ? null : (
                  <SelectedAssignees
                    userIds={assigned_to_users}
                    groupIds={assigned_to_groups}
                    vendorIds={assigned_to_vendors}
                  />
                )
              }
              onClear={() => {
                this.props.onChangeValue({
                  assigned_to_users: [],
                  assigned_to_groups: [],
                  assigned_to_vendors: [],
                });
              }}
            />
          }
          selectedUserIds={this.props.editingWorkOrder.assigned_to_users}
          selectedGroupIds={this.props.editingWorkOrder.assigned_to_groups}
          selectedVendorIds={this.props.editingWorkOrder.assigned_to_vendors}
          onAddUser={user => {
            if (this.props.fieldErrors.assignee) {
              this.props.removeFieldsFromErrors(['assignee']);
            }
            this.props.onChangeValue({
              assigned_to_users: [...this.props.editingWorkOrder.assigned_to_users, user.id],
            });
          }}
          onRemoveUser={user =>
            this.props.onChangeValue({
              assigned_to_users: this.props.editingWorkOrder.assigned_to_users.filter(id => id !== user.id),
            })
          }
          onAddGroup={group => {
            if (this.props.fieldErrors.assignee) {
              this.props.removeFieldsFromErrors(['assignee']);
            }
            this.props.onChangeValue({
              assigned_to_groups: [...this.props.editingWorkOrder.assigned_to_groups, group.id],
            });
          }}
          onRemoveGroup={group =>
            this.props.onChangeValue({
              assigned_to_groups: this.props.editingWorkOrder.assigned_to_groups.filter(
                id => id !== group.id
              ),
            })
          }
          onAddVendor={vendor => {
            if (this.props.fieldErrors.assignee) {
              this.props.removeFieldsFromErrors(['assignee']);
            }
            this.props.onChangeValue({
              assigned_to_vendors: [...this.props.editingWorkOrder.assigned_to_vendors, vendor.id],
            });
          }}
          onRemoveVendor={vendor =>
            this.props.onChangeValue({
              assigned_to_vendors: this.props.editingWorkOrder.assigned_to_vendors.filter(
                id => id !== vendor.id
              ),
            })
          }
        />
      </Field>
    );
  };

  renderGetTitleFromRequest = () => {
    if (this.props.forRequest) {
      return (
        <div className={styles['fetch-description-from-request']}>
          <Tooltip
            trigger={
              <div>
                <Button
                  type="text"
                  noUnderline
                  fontSize={11}
                  primary
                  label="components.new-work-order-modal.fetch-title-from-request"
                  onClick={() => {
                    this.setState({ title: this.props.forRequest.title }, () => {
                      this.props.onChangeValue({
                        title: this.state.title,
                      });
                    });
                  }}
                />
              </div>
            }
            label={<ViewTextArea>{this.props.forRequest.title}</ViewTextArea>}
          />
        </div>
      );
    }
    return null;
  };

  renderGetDescriptionFromRequest = () => {
    if (this.props.forRequest && this.props.forRequest.description) {
      return (
        <div className={styles['fetch-description-from-request']}>
          <Tooltip
            trigger={
              <div>
                <Button
                  type="text"
                  noUnderline
                  fontSize={11}
                  primary
                  label="components.new-work-order-modal.fetch-description-from-request"
                  onClick={() => {
                    this.setState({ description: this.props.forRequest.description }, () => {
                      this.props.onChangeValue({
                        description: this.state.description,
                      });
                    });
                  }}
                />
              </div>
            }
            label={<ViewTextArea>{this.props.forRequest.description}</ViewTextArea>}
          />
        </div>
      );
    }
    return null;
  };

  renderReminder = () => {
    if (this.props.editingWorkOrder.reminder_type === ReminderType.None) {
      return null;
    }
    return (
      <div className={styles['related-resource']}>
        <ReminderMessage workOrder={this.props.editingWorkOrder} />
      </div>
    );
  };

  renderReminderContainer = () => {
    return (
      <>
        <Field label={<FormattedMessage id="components.new-work-order-modal.reminder" />}>
          {this.renderReminder()}
          <Button
            type="text"
            primary
            label={
              this.props.editingWorkOrder.reminder_type === ReminderType.None ? 'general.add' : 'general.edit'
            }
            noUnderline
            onClick={() => {
              this.setState({
                showEditReminderModal: true,
              });
            }}
          />
        </Field>
      </>
    );
  };

  renderMeter = () => {
    if (this.props.editingWorkOrder.meter_id == null) {
      return null;
    }
    return (
      <div className={styles['related-resource']}>
        <div className={styles['title']}>{this.props.meter.title}</div>
        <div
          ref={ref => (this.inlineModalMeterPositioningRef = ref)}
          onClick={() =>
            this.setState({ showMeterDropdownInlineModal: !this.state.showMeterDropdownInlineModal })
          }
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          minWidth={200}
          positionToRef={this.inlineModalMeterPositioningRef}
          position="right"
          open={this.state.showMeterDropdownInlineModal}
          onClose={() => this.setState({ showMeterDropdownInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                destructive
                leftComponent={<Icon regular type="trash-alt" red />}
                onClick={() => {
                  this.setState({
                    showMeterDropdownInlineModal: false,
                  });
                  let params = { meter_id: null };
                  if (this.props.editingWorkOrder.reminder_type === ReminderType.RelativeToMeterReading) {
                    params = {
                      ...params,
                      reminder_type: ReminderType.None,
                      reminder_relative_to_meter: null,
                    };
                  }
                  this.props.onChangeValue(params);
                }}
              >
                <FormattedMessage id="general.delete" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderAddMeterButtonContainer = () => {
    return (
      <>
        <Field label={<FormattedMessage id="components.new-work-order-modal.meter" />}>
          {this.renderMeter()}
          {this.props.editingWorkOrder.meter_id == null ? (
            <Button
              type="text"
              primary
              label={'general.add'}
              noUnderline
              onClick={() => {
                this.setState({
                  showSelectMeterModal: true,
                });
              }}
            />
          ) : null}
        </Field>
      </>
    );
  };

  renderInlineModalBodyContent = () => {
    if (this.state.showDeleteContentForChecklistDropdown) {
      return (
        <>
          <InlineModal.Header
            width={300}
            title={<FormattedMessage id="general.delete-confirm.title" />}
            onClose={() => {
              this.setState({ showChecklistDropdown: false });
            }}
          />
          <InlineModal.Body width={300}>
            <InlineModal.Delete
              onDelete={() => {
                this.props.onChangeValue({ checklist_template_links: [] });
                this.setState({ showChecklistDropdown: false });
              }}
            />
          </InlineModal.Body>
        </>
      );
    }
    return (
      <InlineModal.Body dropdown width={250}>
        <InlineModal.ListItem
          icon="pen"
          iconThickness="regular"
          title={<FormattedMessage id="general.edit" />}
          onClick={this.editChecklistTemplate}
        />
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          destructive
          title={<FormattedMessage id="general.delete" />}
          onClick={() => {
            this.setState({ showDeleteContentForChecklistDropdown: true });
          }}
        />
      </InlineModal.Body>
    );
  };

  renderChecklistContainer = () => {
    if (this.props.editingWorkOrder.checklist_template_links.length === 0) {
      return (
        <>
          <Field label={<FormattedMessage id="components.new-work-order-modal.checklist" />}>
            <div ref={ref => (this.checklistButtonRef = ref)}>
              <Button
                type="text"
                primary
                label="general.add"
                noUnderline
                onClick={() => {
                  this.setState(prevState => ({
                    showNewChecklistDropdown: !prevState.showNewChecklistDropdown,
                    showDeleteContentForChecklistDropdown: false,
                  }));
                }}
              />
            </div>
          </Field>
          <NewInlineModal
            positionToRef={this.checklistButtonRef}
            open={this.state.showNewChecklistDropdown}
            onClose={() => this.setState({ showNewChecklistDropdown: false })}
          >
            <React.Fragment>
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showNewChecklistDropdown: false,
                        showChecklistTitleModal: true,
                      });
                    }}
                  >
                    <FormattedMessage id="screens.work-order.edit.checklist.dropdown.create" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showNewChecklistDropdown: false,
                        showSelectChecklistTemplateModal: true,
                      });
                    }}
                  >
                    <FormattedMessage id="screens.work-order.edit.checklist.dropdown.add" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </React.Fragment>
          </NewInlineModal>
        </>
      );
    }
    return (
      <>
        <Field label={<FormattedMessage id="components.new-work-order-modal.checklist" />}>
          <div className={styles['related-resource']}>
            <div className={styles['title']}>
              <ChecklistTemplateContainer
                id={this.props.editingWorkOrder.checklist_template_links[0].checklist_template_id}
              />
            </div>
            <div
              ref={ref => (this.checklistInlineModalDropdown = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  showChecklistDropdown: !prevState.showChecklistDropdown,
                  showDeleteContentForChecklistDropdown: false,
                }));
              }}
            >
              <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
            </div>
          </div>
        </Field>
        <InlineModal
          open={this.state.showChecklistDropdown}
          positionToRef={this.checklistInlineModalDropdown}
          onClose={() => this.setState({ showChecklistDropdown: false })}
          position="right"
        >
          {this.renderInlineModalBodyContent()}
        </InlineModal>
      </>
    );
  };

  renderAddButtons = () => {
    let showChecklistButton = true;
    let showMeterOption = true;
    if (this.props.hasProTier === false) {
      showChecklistButton = false;
    }
    if (this.props.hasProTier === false) {
      showMeterOption = false;
    }
    if (this.props.settings.meters_activated !== true) {
      showMeterOption = false;
    }
    if (showMeterOption && showChecklistButton) {
      return (
        <>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderAddMeterButtonContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderChecklistContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderReminderContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderRegistrationAttachmentsContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderImagesContainer()}</Grid.Column>
          </Grid.Row>
        </>
      );
    }
    if (showMeterOption) {
      return (
        <>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderAddMeterButtonContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderReminderContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderRegistrationAttachmentsContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderImagesContainer()}</Grid.Column>
          </Grid.Row>
        </>
      );
    }
    if (showChecklistButton) {
      return (
        <>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderChecklistContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderReminderContainer()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={6}>{this.renderRegistrationAttachmentsContainer()}</Grid.Column>
            <Grid.Column md={6}>{this.renderImagesContainer()}</Grid.Column>
          </Grid.Row>
        </>
      );
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column md={6}>{this.renderReminderContainer()}</Grid.Column>
          <Grid.Column md={6}>{this.renderRegistrationAttachmentsContainer()}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.renderImagesContainer()}</Grid.Column>
        </Grid.Row>
      </>
    );
  };

  renderChecklistTitleModal = () => (
    <ChecklistTitleModal
      open={this.state.showChecklistTitleModal}
      onSave={this.createDraftChecklistTemplate}
      onClose={() => {
        this.setState({ showChecklistTitleModal: false });
      }}
    />
  );

  renderChecklistEditModal = () => (
    <ChecklistEditModal
      new
      open={this.state.showChecklistEditModal}
      id={this.state.showChecklistEditModalForVersionId}
      showUnsavedChanges={false}
      onSave={this.saveChecklistModal}
      onAddToLibrarySuccess={() => {
        this.props.onHideChecklistModal();
        this.props.onChangeValue({
          checklist_template_links: [{ checklist_template_id: this.state.draftTemplateId }],
        });
        this.setState({ showChecklistEditModal: false, draftTemplateId: null });
      }}
      onClose={() => {
        this.props.onHideChecklistModal();
        this.setState({ showChecklistEditModal: false });
      }}
    />
  );

  renderSelectedCategory = () => {
    switch (this.props.editingWorkOrder.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return null;
    }
  };

  renderCategory = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.work-order.category" />}>
            <CategoryInlineModal
              trigger={
                <Field.Resource
                  angleDown
                  error={this.props.fieldErrors.category}
                  value={this.renderSelectedCategory()}
                  onClear={() => this.props.onChangeValue({ category: null })}
                />
              }
              selectedCategory={this.props.editingWorkOrder.category}
              onSelect={category => {
                this.props.removeFieldsFromErrors(['category']);
                this.props.onChangeValue({ category });
              }}
              onClear={e => this.props.onChangeValue({ category: null })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.title" />}>
                <Field.Text
                  ref={ref => (this.titleInputRef = ref)}
                  autoFocus
                  error={this.props.fieldErrors.title}
                  rightLabel={
                    <div className={styles['max-characters-container']}>
                      <span className={styles['max-characters']}>
                        <FormattedMessage
                          id="general.characters-left"
                          values={{ amount: 35 - this.state.title.length }}
                        />
                      </span>
                      <Tooltip
                        trigger={
                          <div className={styles['title-popup-container']}>
                            <Icon regular type="question-circle" />
                          </div>
                        }
                        label={<FormattedMessage id="general.characters-left-popup" />}
                      />
                    </div>
                  }
                  value={this.state.title}
                  onChange={value => this.changeTitle(value)}
                  onBlur={() => this.props.onChangeValue({ title: this.state.title })}
                />
              </Field>
              {this.renderGetTitleFromRequest()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.description" />}>
                <Field.Textarea
                  error={this.props.fieldErrors.description}
                  value={this.state.description}
                  onChange={value => {
                    if (value && this.props.fieldErrors.description) {
                      this.props.removeFieldsFromErrors(['description']);
                    }
                    this.setState({ description: value });
                  }}
                  onBlur={() => this.props.onChangeValue({ description: this.state.description })}
                />
              </Field>
              {this.renderGetDescriptionFromRequest()}
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <DueDate
            editingWorkOrder={this.props.editingWorkOrder}
            onSelectDate={date => this.selectDate(date)}
            onChangeValue={params => this.props.onChangeValue(params)}
            onCompleteBeforeRegister={date => this.props.onChangeCompleteBeforeRegistration(true, date)}
          />
          <Grid.Row>
            <Grid.Column>{this.renderAssignee()}</Grid.Column>
          </Grid.Row>
          <RecurringContainer
            editingWorkOrder={this.props.editingWorkOrder}
            onChangeValue={intervalData => {
              this.props.onChangeValue(intervalData);
              if (intervalData.start_date) {
                this.props.removeFieldsFromErrors(['date']);
              }
            }}
          />
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.asset" />}>
                <ChooseAssetInlineModal
                  trigger={
                    <Field.Resource
                      angleDown
                      error={this.props.fieldErrors.asset_id}
                      value={
                        this.props.asset == null ? null : (
                          <>
                            <AssetTitle id={this.props.asset.id} />
                            {this.props.asset.tree_path.length === 0 ? null : (
                              <div className={styles['tree-path-container']}>
                                <TreePath assetId={this.props.asset.id} />
                              </div>
                            )}
                          </>
                        )
                      }
                      onClear={() => this.props.onChangeValue({ asset_id: null })}
                    />
                  }
                  selectedAssetId={this.props.editingWorkOrder.asset_id}
                  onSelectAsset={assetId => {
                    if (this.props.fieldErrors.asset_id) {
                      this.props.removeFieldsFromErrors(['asset_id']);
                    }
                    this.props.onChangeValue({ asset_id: assetId });
                  }}
                  onClear={e => this.props.onChangeValue({ asset_id: null })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          {this.renderCategory()}
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.work-order-type" />}>
                <WorkOrderTypeInlineModal
                  trigger={
                    <Field.Resource
                      angleDown
                      error={this.props.fieldErrors.work_order_type_id}
                      value={this.props.workOrderType == null ? null : this.props.workOrderType.title}
                      onClear={() => this.props.onChangeValue({ work_order_type_id: null })}
                    />
                  }
                  selectedWorkOrderTypeId={
                    this.props.workOrderType == null ? null : this.props.workOrderType.id
                  }
                  onSelectWorkOrderType={id => {
                    if (this.props.fieldErrors.work_order_type_id) {
                      this.props.removeFieldsFromErrors(['work_order_type_id']);
                    }
                    this.props.onChangeValue({ work_order_type_id: id });
                  }}
                  onClear={() => this.props.onChangeValue({ work_order_type_id: null })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.work-order.priority" />}>
                <ChoosePriorityInlineModal
                  trigger={
                    <Field.Resource
                      error={this.props.fieldErrors.priority}
                      angleDown
                      value={this.renderSelectedPriority()}
                      onClear={() => this.props.onChangeValue({ priority: null })}
                    />
                  }
                  selectedPriority={this.props.editingWorkOrder.priority}
                  onSelectPriority={priority => {
                    if (this.props.fieldErrors.priority) {
                      this.props.removeFieldsFromErrors(['priority']);
                    }
                    this.props.onChangeValue({ priority });
                  }}
                  onClear={() => this.props.onChangeValue({ priority: null })}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          {this.renderAddButtons()}
        </Grid>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={[...this.props.images, ...this.props.requestImages]}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <SelectAssetAttachmentModal
          isOpen={this.state.showAssetAttachmentContent}
          onClose={() => this.setState({ showAssetAttachmentContent: false })}
          onSelectAttachment={attachment => {
            this.addAttachmentFromAsset(attachment.id);
            this.setState({ showAssetAttachmentContent: false });
          }}
          assetId={this.props.editingWorkOrder.asset_id}
        />
        <NewAttachmentModal
          isOpen={this.state.showNewAttachmentModalForType != null}
          type={this.state.showNewAttachmentModalForType}
          onClose={() => this.setState({ showNewAttachmentModalForType: null })}
          onCreateNew={params => this.props.onCreateAttachment(params)}
        />
        <EditReminderModal
          open={this.state.showEditReminderModal}
          editingWorkOrder={this.props.editingWorkOrder}
          onSave={data => {
            this.props.onChangeValue(data);
          }}
          onClose={() => {
            this.setState({ showEditReminderModal: false });
          }}
        />
        {this.renderChecklistEditModal()}
        {this.renderChecklistTitleModal()}
        <SelectChecklistModal
          open={this.state.showSelectChecklistTemplateModal}
          onSelect={checklistTemplate => {
            if (checklistTemplate.require_options) {
              this.setState({ showSelectChecklistTemplateModal: false });
              setTimeout(() => {
                this.setState({
                  showChecklistOptionsModal: true,
                  showChecklistOptionsModalForChecklistTemplateId: checklistTemplate.id,
                });
              }, 200);
            } else {
              this.props.onChangeValue({
                checklist_template_links: [{ checklist_template_id: checklistTemplate.id }],
              });
              this.setState({ draftTemplateId: null, showSelectChecklistTemplateModal: false });
            }
          }}
          onClose={() => {
            this.setState({ showSelectChecklistTemplateModal: false });
          }}
        />
        <ChecklistOptionsModal
          assetId={this.props.editingWorkOrder.asset_id}
          checklistTemplateId={this.state.showChecklistOptionsModalForChecklistTemplateId}
          open={this.state.showChecklistOptionsModal}
          onSave={params => {
            this.setState({ showChecklistOptionsModal: false });
            this.props.onChangeValue({
              checklist_template_links: [
                {
                  checklist_template_id: this.state.showChecklistOptionsModalForChecklistTemplateId,
                  ...params,
                },
              ],
            });
          }}
          onClose={() => this.setState({ showChecklistOptionsModal: false })}
        />
        <CantEditReusableChecklistModal
          open={this.state.showCantEditReusableChecklistModal}
          onClose={() => this.setState({ showCantEditReusableChecklistModal: false })}
        />
        <SelectMeterModal
          assetId={this.props.editingWorkOrder.asset_id}
          open={this.state.showSelectMeterModal}
          onClose={() => {
            this.setState({ showSelectMeterModal: false });
          }}
          onSelect={id => {
            this.setState({
              showSelectMeterModal: false,
            });
            let params = {
              meter_id: id,
            };
            if (this.props.editingWorkOrder.reminder_type === ReminderType.RelativeToDueDate) {
              params = {
                ...params,
                reminder_type: ReminderType.None,
                reminder_days_relative_to_due_date: null,
              };
            }
            if (this.props.editingWorkOrder.category == null) {
              if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Detailed) {
                this.props.removeFieldsFromErrors(['category']);
                params = {
                  ...params,
                  category: WorkOrderCategory.PreventiveConditionBased,
                };
              }
              if (this.props.settings.work_order_category_type === WorkOrderCategoryType.Simple) {
                this.props.removeFieldsFromErrors(['category']);
                params = {
                  ...params,
                  category: WorkOrderCategory.Preventive,
                };
              }
            }
            this.props.onChangeValue(params);
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createDraftImage: SDKReduxOperations.createDraftImage,
      deleteImage: SDKReduxOperations.deleteImage,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    editingWorkOrderIsRecurring: NewWorkOrderSelectors.editingWorkOrderIsRecurring(state),
    settings: AuthSelectors.getSettings(state),
    isAdmin: AuthSelectors.isAdmin(state),
    hasProTier: AuthSelectors.hasProTier(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    asset: EntitySelectors.getAsset(state, ownProps.editingWorkOrder.asset_id),
    workOrderType: EntitySelectors.getWorkOrderType(state, ownProps.editingWorkOrder.work_order_type_id),
    userAssignee: EntitySelectors.getUser(state, ownProps.editingWorkOrder.assigned_to_user_id),
    groupAssignee: EntitySelectors.getGroup(state, ownProps.editingWorkOrder.assigned_to_group_id),
    vendorAssignee: EntitySelectors.getVendor(state, ownProps.editingWorkOrder.assigned_to_vendor_id),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachments(
      state,
      ownProps.editingWorkOrder.id
    ),
    registrationAttachments: EntitySelectors.getAttachments(
      state,
      ownProps.editingWorkOrder.registration_attachments
    ),
    meter: EntitySelectors.getMeter(state, ownProps.editingWorkOrder.meter_id),
    images: EntitySelectors.getImages(state, ownProps.editingWorkOrder.registration_images),
    requestImages: ownProps.forRequest ? EntitySelectors.getImages(state, ownProps.forRequest.images) : [],
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewWorkOrder)));
