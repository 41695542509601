import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Button, NewInlineModal, Loader } from 'views/components/Shared/General';
import { SelectUserModal } from 'views/components/User';
import { API } from 'sdk';
import { normalizeGroup, normalizeUser } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import GroupDropdownItem from './GroupDropdownItem';
import UserDropdownItem from './UserDropdownItem';
import { EntitySelectors } from 'sdk/State/entities';

class AssigneeDropdown extends Component {
  getInitialState = () => ({
    isFetching: false,
    users: [],
    groups: [],
    searchTerm: '',
  });

  state = this.getInitialState();

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isFetching: true });
      Promise.all([
        API.listGroups(this.props.system.id, { type: 'request_assignee', no_pagination: true }),
        API.listUsers(this.props.system.id, {
          archived: false,
          no_pagination: true,
          member: true,
          permission: 'administrate_requests',
        }),
      ]).then(([{ data: groups }, { data: users }]) => {
        const { entities: groupEntities, result: groupIds } = normalizeGroup(groups);
        const { entities: userEntities, result: userIds } = normalizeUser(users);
        this.props.updateEntities({ ...groupEntities, ...userEntities });
        this.setState({
          isFetching: false,
          groups: groupIds.map(id => groupEntities.groupById[id]),
          users: userIds.map(id => userEntities.userById[id]),
        });
      });
    } else if (!this.props.open && prevProps.open) {
      this.setState({ users: [], groups: [] });
    }
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  isClearable = () => {
    if (this.props.clearable && this.props.userId) return true;
    if (this.props.clearable && this.props.groupId) return true;
    return false;
  };

  renderUsers = filteredUsers => {
    if (filteredUsers.length === 0) {
      return null;
    }
    return filteredUsers.map(({ id }) => (
      <UserDropdownItem
        id={id}
        selected={this.props.userId === id}
        loading={this.props.isLoadingUserId === id}
        onSelect={() => {
          this.props.onSelectUser(id);
          this.setState({ open: false });
        }}
      />
    ));
  };

  renderGroups = (filteredGroups, filteredUsers) => {
    if (filteredGroups.length === 0) {
      return null;
    }
    return (
      <>
        {filteredGroups.map(({ id }) => (
          <GroupDropdownItem
            id={id}
            loading={this.props.isLoadingGroupId === id}
            selected={this.props.groupId === id}
            onSelect={() => {
              this.props.onSelectGroup(id);
              this.setState({ open: false });
            }}
          />
        ))}
        {filteredGroups.length > 0 && filteredUsers.length > 0 ? <NewInlineModal.Dropdown.Separator /> : null}
      </>
    );
  };

  renderUserAndGroups = () => {
    const { searchTerm } = this.state;
    const filteredGroups = this.state.groups.filter(
      group => searchTerm === '' || group.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredUsers = this.state.users.filter(
      user => searchTerm === '' || user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredGroups.length === 0 && filteredUsers.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <>
        {this.renderGroups(filteredGroups, filteredUsers)}
        {this.renderUsers(filteredUsers)}
      </>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    return (
      <NewInlineModal.Dropdown.Items>
        {this.isClearable() ? (
          <>
            <NewInlineModal.Dropdown.Item
              onClick={e => this.props.onClear()}
              rightComponent={this.props.isClearing ? <Loader tiny /> : null}
            >
              <FormattedMessage id="components.request-assignee-dropdown.clear-assignee" />
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Separator />
          </>
        ) : null}
        {this.renderUserAndGroups()}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderHeader = () => {
    return (
      <NewInlineModal.Header>
        <NewInlineModal.Header.Search
          ref={ref => (this.inlineModalSearchRef = ref)}
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.searchTerm}
          onChange={searchTerm => {
            this.setState({ searchTerm });
          }}
          onClear={() => {
            this.setState({ searchTerm: '' });
          }}
          rightLabel={
            <Button
              type="text"
              fontSize={12}
              primary
              label="general.more-options"
              noUnderline
              onClick={() => {
                this.props.onClose();
                this.setState({ showSelectUserModal: true });
              }}
            />
          }
        />
      </NewInlineModal.Header>
    );
  };

  render() {
    return (
      <>
        <NewInlineModal
          positionToRef={this.props.positionToRef}
          open={this.props.open}
          onClose={this.props.onClose}
          minWidth={335}
        >
          <>
            {this.renderHeader()}
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </>
        </NewInlineModal>
        <SelectUserModal
          open={this.state.showSelectUserModal}
          members={this.props.members}
          selectUserId={this.props.userId}
          onSelectUser={({ id }) => {
            this.setState({ showSelectUserModal: false });
            this.props.onSelectUser(id);
          }}
          onClose={() => this.setState({ showSelectUserModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { userId, groupId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    user: EntitySelectors.getUser(state, userId),
    group: EntitySelectors.getGroup(state, groupId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssigneeDropdown));

AssigneeDropdown.propTypes = {
  userId: PropTypes.string,
  groupId: PropTypes.string,
  onSelectGroup: PropTypes.func,
  onSelectUser: PropTypes.func,
  isLoadingUserId: PropTypes.string,
  isLoadingGroupId: PropTypes.string,
  onClear: PropTypes.func,
  clearable: PropTypes.bool,
};

AssigneeDropdown.defaultProps = {
  userId: null,
  groupId: null,
  isLoadingUserId: null,
  isLoadingGroupId: null,
  clearable: true,
  onSelectUser: () => {},
  onSelectGroup: () => {},
  onClear: () => {},
};
