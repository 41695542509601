import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { FilterButton, List, Pagination, Field } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { MenuOperations } from 'state/ducks/menu';
import HelperFunctions from 'utilities/HelperFunctions';
import { PurchaseOrdersOperations, PurchaseOrdersSelectors } from 'state/ducks/purchaseOrders';
import { CreateDeliveryModal } from 'views/components/PurchaseOrder';
import { AuthSelectors } from 'state/ducks/auth';
import PurchaseOrderListItem from './PurchaseOrderListItem';
import PurchaseOrdersImage from 'assets/images/EmptyDataSet/PurchaseOrders.png';
import styles from './style.module.scss';
import FilterModal from './FilterModal';
import ExportModal from './ExportModal';

const listPurchaseOrdersRequest = SDKHelperFunctions.getCancelTokenForRequest();

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewModal: false,
      showFilterModal: false,
      showExportModal: false,
      showCreateDeliveryModal: false,
      showCreateDeliveryModalForPurchaseOrder: null,
    };
  }

  componentDidMount() {
    this.props.resetSelectedPurchaseOrders();
    this.fetchPurchaseOrders();
  }

  componentDidUpdate(prevProps) {
    const { showFilterModal } = this.state;
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list: newList } = queryString.parse(this.props.location.search);
    let changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    let changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    let changedList = oldList !== newList;
    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchPurchaseOrders();
      return;
    }
    if (changedList || changedQueryParams || (changedAdvancedFilters && showFilterModal === false)) {
      this.props.resetSelectedPurchaseOrders();
      this.fetchPurchaseOrders();
    }
  }

  getParamsForList = () => {
    const { list } = queryString.parse(this.props.location.search);
    let params = {};
    if (list === 'draft') {
      params = {
        ...params,
        status: 'draft',
      };
    }
    if (list === 'awaiting_delivery') {
      params = {
        ...params,
        status: 'partially_delivered,ordered',
      };
    }
    if (list === 'fully_delivered') {
      params = {
        ...params,
        status: 'delivered',
      };
    }
    return params;
  };

  fetchPurchaseOrders = params => {
    listPurchaseOrdersRequest.cancel();

    const attrs = {
      ...this.props.filtersAsQueryParams,
      ...this.props.queryParameters,
      ...params,
      ...this.getParamsForList(),
    };

    this.props.showLoader();
    return this.props
      .fetchPurchaseOrders(
        this.props.currentSystem.id,
        attrs,
        listPurchaseOrdersRequest.getCancelTokenConfig()
      )
      .then(purchaseOrders => {
        return purchaseOrders;
      })
      .catch(e => {});
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.number?.length > 0;
  };

  setNavigatedTo = () => {
    const { list } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
      },
    });
  };

  renderEmptyDataSet = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'draft') {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.purchase-orders.drafts.empty-data-set.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.purchase-orders.drafts.empty-data-set.subtitle" />
          </div>
          <div className={styles['image-container']}>
            <img src={PurchaseOrdersImage} alt="" />
          </div>
        </div>
      );
    }
    if (list === 'awaiting_delivery') {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.purchase-orders.active-deliveries.empty-data-set.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.purchase-orders.active-deliveries.empty-data-set.subtitle" />
          </div>
          <div className={styles['image-container']}>
            <img src={PurchaseOrdersImage} alt="" />
          </div>
        </div>
      );
    }
    if (list === 'fully_delivered') {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.purchase-orders.fully-delivered.empty-data-set.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.purchase-orders.fully-delivered.empty-data-set.subtitle" />
          </div>
          <div className={styles['image-container']}>
            <img src={PurchaseOrdersImage} alt="" />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.purchase-orders.empty-data-set.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="screens.purchase-orders.empty-data-set.subtitle" />
        </div>
        <div className={styles['image-container']}>
          <img src={PurchaseOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={PurchaseOrdersImage} alt="" />
      </div>
    </div>
  );

  renderAllPurchaseOrdersHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.purchase-order.resource" />
        </List.Header.Column>
        <List.Header.Column width={150}>
          <FormattedMessage id="resources.purchase-order.status" />
        </List.Header.Column>
        <List.Header.Column width={120} alignRight>
          <FormattedMessage id="resources.purchase-order.total-cost" />
        </List.Header.Column>
      </>
    );
  };

  renderDraftHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.purchase-order.resource" />
        </List.Header.Column>
        <List.Header.Column width={220}>
          <FormattedMessage id="resources.purchase-order.created-at" />
        </List.Header.Column>
        <List.Header.Column width={220} alignRight>
          <FormattedMessage id="resources.purchase-order.total-cost" />
        </List.Header.Column>
      </>
    );
  };

  renderAwaitingDeliveryHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.purchase-order.resource" />
        </List.Header.Column>
        <List.Header.Column width={300}>
          <FormattedMessage id="resources.purchase-order.expected-delivery-date-short" />
        </List.Header.Column>
        <List.Header.Column width={120} alignRight>
          <FormattedMessage id="resources.purchase-order.total-cost" />
        </List.Header.Column>
        <List.Item.Column width={150} alignRight />
      </>
    );
  };

  renderFullyDeliveredHeaderColumns = () => {
    return (
      <>
        <List.Header.Column flex>
          <FormattedMessage id="resources.purchase-order.resource" />
        </List.Header.Column>
        <List.Header.Column width={468}>
          <FormattedMessage id="resources.purchase-order.delivered-date" />
        </List.Header.Column>
        <List.Header.Column width={120} alignRight>
          <FormattedMessage id="resources.purchase-order.total-cost" />
        </List.Header.Column>
      </>
    );
  };

  renderHeader = () => {
    const { totalEntriesIsSelected, pageIsSelected, isSearching } = this.props;
    return (
      <List.Header
        background
        expandable
        checkbox={this.props.canEditPurchaseOrders}
        checked={isSearching === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (isSearching) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedPurchaseOrders();
          } else {
            this.props.selectPage();
          }
        }}
        showMultipleOptions={this.props.selectedPurchaseOrderCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.isSearching}
            count={this.props.selectedPurchaseOrderCount}
            buttons={
              <List.Header.MultipleOptions.Button
                label={<FormattedMessage id="screens.purchase-orders.header-buttons.export" />}
                onClick={() => this.setState({ showExportModal: true })}
              />
            }
          />
        }
      >
        {this.renderHeaderColumns()}
      </List.Header>
    );
  };

  renderHeaderColumns = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'draft') {
      return this.renderDraftHeaderColumns();
    }
    if (list === 'awaiting_delivery') {
      return this.renderAwaitingDeliveryHeaderColumns();
    }
    if (list === 'fully_delivered') {
      return this.renderFullyDeliveredHeaderColumns();
    }
    return this.renderAllPurchaseOrdersHeaderColumns();
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.props.isSearching}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.purchaseOrders.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedPurchaseOrders()}
        />
      );
    }
    return null;
  };

  renderList = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (this.props.isSearching) {
      const amountOfPurchaseOrders =
        this.props.purchaseOrders.length === 0 ? 1 : this.props.purchaseOrders.length;
      return (
        <>
          {this.renderHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfPurchaseOrders)
              .fill()
              .map(() => (
                <PurchaseOrderListItem loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.purchaseOrders.length === 0) {
      if (this.isFiltering()) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.purchaseOrders.map(purchaseOrder => {
            let checked = false;
            if (this.props.selectedPurchaseOrderIds[purchaseOrder.id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <PurchaseOrderListItem
                list={list}
                key={purchaseOrder.id}
                purchaseOrder={purchaseOrder}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onClick={() => this.setNavigatedTo()}
                onCheck={e => {
                  this.props.selectPurchaseOrder(purchaseOrder.id);
                }}
                onCreateDelivery={purchaseOrder => {
                  this.setState({
                    showCreateDeliveryModal: true,
                    showCreateDeliveryModalForPurchaseOrder: purchaseOrder,
                  });
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderToolbar = () => {
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.FilterBar.RightContent>
          <FilterButton.Group>
            {this.props.isFiltering ? (
              <FilterButton
                clearable={false}
                caret={false}
                label={<FormattedMessage id="general.clean" />}
                onClick={() => {
                  this.setState({ isSearching: true });
                  this.props.resetFilter();
                }}
              />
            ) : null}
            <FilterButton
              clearable={false}
              caret={false}
              label={<FormattedMessage id="screens.purchase-orders.filter" />}
              filtered={this.props.isFiltering}
              onClick={() => this.setState({ showFilterModal: true })}
            />
          </FilterButton.Group>
        </ListLayout.Content.MainContent.FilterBar.RightContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderPagination = () => {
    if (this.props.purchaseOrders.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderExportModal = () => {
    return (
      <ExportModal
        open={this.state.showExportModal}
        onSave={() => {
          this.setState({ showExportModal: false });
        }}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderCreateDeliveryModal = () => (
    <CreateDeliveryModal
      open={this.state.showCreateDeliveryModal}
      purchaseOrder={this.state.showCreateDeliveryModalForPurchaseOrder}
      onSave={() => {
        this.setState({
          showCreateDeliveryModal: false,
        });
      }}
      onClose={() => {
        this.setState({ showCreateDeliveryModal: false });
      }}
    />
  );

  render() {
    return (
      <>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            this.props.resetSelectedPurchaseOrders();
            this.props.showLoader();
            this.setState({ showFilterModal: false });
            this.fetchPurchaseOrders(params);
          }}
        />
        {this.renderExportModal()}
        {this.renderCreateDeliveryModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoader: PurchaseOrdersOperations.showLoader,
      fetchPurchaseOrders: PurchaseOrdersOperations.fetchPurchaseOrders,
      selectMenuItem: MenuOperations.selectItem,
      addQueryParameter: PurchaseOrdersOperations.addQueryParameter,
      selectPurchaseOrder: PurchaseOrdersOperations.selectPurchaseOrder,
      resetFilter: PurchaseOrdersOperations.resetFilter,
      resetSelectedPurchaseOrders: PurchaseOrdersOperations.resetSelectedPurchaseOrders,
      selectTotalEntries: PurchaseOrdersOperations.selectTotalEntries,
      hideSelectTotalEntries: PurchaseOrdersOperations.hideSelectTotalEntries,
      selectPage: PurchaseOrdersOperations.selectPage,
      setNavigatedTo: PurchaseOrdersOperations.setNavigatedTo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    purchaseOrders: PurchaseOrdersSelectors.getPurchaseOrders(state),
    filters: PurchaseOrdersSelectors.getFilters(state),
    filtersAsQueryParams: PurchaseOrdersSelectors.getFiltersAsQueryParams(state),
    isFiltering: PurchaseOrdersSelectors.isFiltering(state),
    isSearching: PurchaseOrdersSelectors.isSearching(state),
    pagination: PurchaseOrdersSelectors.getPagination(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    queryParameters: PurchaseOrdersSelectors.getQueryParameters(state),
    selectedPurchaseOrderIds: PurchaseOrdersSelectors.getSelectedPurchaseOrderIds(state),
    selectedPurchaseOrderCount: PurchaseOrdersSelectors.getSelectedPurchaseOrderCount(state),
    totalEntriesIsSelected: PurchaseOrdersSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: PurchaseOrdersSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: PurchaseOrdersSelectors.getPageIsSelected(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders));
