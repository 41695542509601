import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, Loader } from 'views/components/Shared/General';
import styles from './styles.module.scss';

const checkDuplicateAssetsRequest = HelperFunctions.getCancelTokenForRequest();

class EditAssetModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    title: '',
    number: '',
    isCheckingDuplicateAssetNumber: false,
    showAssetNumberAlreadyExistsWarning: false,
    showTitleRequiredError: false,
    showNumberRequiredError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        title: this.props.asset.title,
        number: this.props.asset.number,
      });
    }
  }

  checkDuplicatedAssetNumber = number => {
    if (number === this.props.asset.number) {
      this.setState({
        isCheckingDuplicateAssetNumber: false,
        showAssetNumberAlreadyExistsWarning: false,
      });
      return;
    }
    let params = {
      number: {
        [HelperFunctions.FILTER_COMPARABLES.Exact]: number,
      },
    };
    API.listAssets(this.props.currentSystem.id, params, checkDuplicateAssetsRequest.getCancelTokenConfig())
      .then(({ data }) => {
        this.setState({
          isCheckingDuplicateAssetNumber: false,
          showAssetNumberAlreadyExistsWarning: data.length > 0,
        });
      })
      .catch(() => {
        this.setState({
          showAssetNumberAlreadyExistsWarning: false,
          isCheckingDuplicateAssetNumber: false,
        });
      });
  };

  isSaveable = () => {
    let isSavable = true;
    if (this.state.title.length === 0) {
      this.setState({ showTitleRequiredError: true });
      isSavable = false;
    }
    if (this.state.number.length === 0) {
      this.setState({ showNumberRequiredError: true });
      isSavable = false;
    }
    if (this.state.showAssetNumberAlreadyExistsWarning) {
      this.setState({ showNumberRequiredError: true });
      isSavable = false;
    }
    return isSavable;
  };

  save = () => {
    const { title, number } = this.state;
    if (!this.isSaveable()) {
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateAsset(this.props.asset.id, { title, number })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderNumberAlreadyExistsError = () => {
    if (this.state.showAssetNumberAlreadyExistsWarning) {
      return (
        <span className={styles['number-already-exists']}>
          <FormattedMessage id="components.new-asset-modal.number-already-exists" />
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.asset.edit-asset" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field view={false} label={<FormattedMessage id="resources.asset.title" />}>
                  <Field.Text
                    autoFocus
                    error={this.state.showTitleRequiredError}
                    value={this.state.title}
                    onChange={title => {
                      if (title && this.state.showTitleRequiredError) {
                        this.setState({ showTitleRequiredError: false });
                      }
                      this.setState({ title });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            {this.props.settings.asset_number_activated ? (
              <Grid.Row>
                <Grid.Column>
                  <Field view={false} label={<FormattedMessage id="resources.asset.number" />}>
                    <Field.Text
                      value={this.state.number}
                      error={
                        this.state.showAssetNumberAlreadyExistsWarning || this.state.showNumberRequiredError
                      }
                      rightLabel={this.state.isCheckingDuplicateAssetNumber ? <Loader tiny /> : null}
                      onChange={number => {
                        if (this.state.showNumberRequiredError && number) {
                          this.setState({ showNumberRequiredError: false });
                        }
                        this.setState({ number });
                      }}
                      onBlur={number => {
                        checkDuplicateAssetsRequest.cancel();
                        if (number) {
                          this.setState({ isCheckingDuplicateAssetNumber: true });
                          this.checkDuplicatedAssetNumber(number);
                        } else {
                          this.setState({
                            showAssetNumberAlreadyExistsWarning: false,
                          });
                        }
                      }}
                    />
                  </Field>
                  {this.renderNumberAlreadyExistsError()}
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAssetModal);
