import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { Exact } from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Number/comparators';
import { Button, Field } from 'views/components/Shared/General';
import { WorkOrdersSelectors, WorkOrdersOperations } from 'state/ducks/workOrders';
import { FILTER_TYPES } from 'views/scenes/WorkOrders/WorkOrderList/FilterModal';
import Container from 'views/components/Shared/Layout/SideModal/Container/Filter/Detail/Types/Container';
import styles from './style.module.scss';

class Interval extends Component {
  constructor(props) {
    super(props);
    const { value, comparator } = props.filter;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      this.state = {
        interval: null,
        frequency: null,
        initialFilter: props.filter,
        comparator,
        hasValue: value,
      };
    } else {
      const interval = value ? value.split('_')[0] : null;
      const frequency = value ? value.split('_')[1] : 'weekly';
      this.state = {
        hasValue: value,
        interval,
        frequency,
        initialFilter: this.props.filter,
        comparator,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      comparator: prevComparator,
      hasValue: prevHasValue,
      interval: prevInterval,
      frequency: prevFrequency,
    } = prevState;
    const { comparator, hasValue, interval, frequency } = this.state;
    const changedFilter = interval !== prevInterval || prevFrequency !== frequency;
    const changedComparator = prevComparator !== comparator;
    const changedHasValue = prevHasValue !== hasValue;
    if (changedFilter || changedComparator || changedHasValue) {
      if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
        this.props.addFilter({
          key: FILTER_TYPES.Interval,
          data: {
            [FILTER_TYPES.Interval]: {
              comparator: this.state.comparator,
              value: this.state.hasValue,
            },
          },
        });
      } else {
        if (interval && frequency) {
          this.props.addFilter({
            key: FILTER_TYPES.Interval,
            data: {
              [FILTER_TYPES.Interval]: {
                comparator: this.state.comparator,
                value: `${interval}_${frequency}`,
              },
            },
          });
        }
      }
    }
  }

  hasFiltersApplied = () => {
    const { comparator, frequency, interval } = this.state;
    if (comparator === HelperFunctions.FILTER_COMPARABLES.Exists) {
      return true;
    }
    return frequency != null && interval != null;
  };

  /*
    Render functions
  */

  renderSelectedValue = () => {
    switch (this.state.frequency) {
      case 'weekly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.weekly" />;
      case 'monthly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.monthly" />;
      case 'yearly':
        return <FormattedMessage id="components.interval-options-inline-modal.interval-options.yearly" />;
      default:
        return null;
    }
  };

  renderExactComparator = () => {
    return (
      <Exact
        selected={this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exact}
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exact });
        }}
      >
        <div className={styles['interval']}>
          <div>
            <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.per" />
            <span>: </span>
          </div>
          <div className={styles['per']}>
            <Field.Number
              value={this.state.interval}
              min={1}
              onChange={interval => {
                this.setState({
                  interval,
                });
              }}
            />
          </div>
          <div className={styles['frequency']}>
            <Field.Dropdown clearable={false} value={this.renderSelectedValue()}>
              <Field.Dropdown.Item
                selected={this.state.frequency === 'weekly'}
                onClick={() => this.setState({ frequency: 'weekly' })}
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.weekly" />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={this.state.frequency === 'monthly'}
                onClick={() => this.setState({ frequency: 'monthly' })}
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.monthly" />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={this.state.frequency === 'yearly'}
                onClick={() => this.setState({ frequency: 'yearly' })}
              >
                <FormattedMessage id="components.interval-options-inline-modal.interval-options.yearly" />
              </Field.Dropdown.Item>
            </Field.Dropdown>
          </div>
        </div>
      </Exact>
    );
  };

  renderExistsComparator = () => {
    return (
      <Container
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === true
        }
        title={<FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.exact.title" />}
        subtitle={
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.exact.subtitle" />
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: true });
        }}
      />
    );
  };

  renderNotExistsComparator = () => {
    return (
      <Container
        selected={
          this.state.comparator === HelperFunctions.FILTER_COMPARABLES.Exists && this.state.hasValue === false
        }
        title={
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.not-exact.title" />
        }
        subtitle={
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.not-exact.subtitle" />
        }
        onClick={() => {
          this.setState({ comparator: HelperFunctions.FILTER_COMPARABLES.Exists, hasValue: false });
        }}
      />
    );
  };

  renderApplyFiltersButton = () => {
    return (
      <Button
        primary
        disabled={this.hasFiltersApplied() === false}
        label="filters.add-button"
        onClick={this.props.showAppliedFilters}
      />
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.Interval,
                data: {
                  [FILTER_TYPES.Interval]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.Interval,
                data: {
                  [FILTER_TYPES.Interval]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="screens.work-orders.filters.advanced.work-order.interval.title" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          {this.renderExactComparator()}
          {this.renderExistsComparator()}
          {this.renderNotExistsComparator()}

          {this.renderApplyFiltersButton()}
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: WorkOrdersOperations.removeFilter,
      addFilter: WorkOrdersOperations.addFilter,
      showAppliedFilters: WorkOrdersOperations.showAppliedFilters,
      selectFilterType: WorkOrdersOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: WorkOrdersSelectors.getFiltersForKey(state, FILTER_TYPES.Interval),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Interval);

Interval.propTypes = {
  filter: PropTypes.object,
  initialFilter: PropTypes.object,
};

Interval.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
    value: null,
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
    value: null,
  },
};
