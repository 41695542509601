import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import PurchaseOrderRowListItem from './PurchaseOrderRowListItem';

class Articles extends Component {
  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.subtitle" />}
        image={SparePartSmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  render() {
    if (this.props.purchaseOrderRows.length === 0) {
      return this.renderEmptyDataset();
    }
    return (
      <>
        <List.Header expandable background paddingRight={68}>
          <List.Header.Column width={100}>
            <FormattedMessage id="resources.purchase-order-row.number" />
          </List.Header.Column>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-row.title" />
          </List.Header.Column>
          <List.Header.Column alignRight width={100}>
            <FormattedMessage id="screens.purchase-order.info.articles.quantity-ordered" />
          </List.Header.Column>
          <List.Header.Column alignRight width={100}>
            <FormattedMessage id="screens.purchase-order.info.articles.quantity-delivered" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.purchaseOrderRows.map(purchaseOrderRow => (
            <PurchaseOrderRowListItem key={purchaseOrderRow.id} purchaseOrderRow={purchaseOrderRow} />
          ))}
        </List>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrder = EntitySelectors.getPurchaseOrder(state, ownProps.match.params.id);
  return {
    purchaseOrder,
    purchaseOrderRows: EntitySelectors.getPurchaseOrderRows(state, purchaseOrder.purchase_order_rows),
  };
}

export default withRouter(connect(mapStateToProps)(Articles));
