export const RowType = {
  SparePartTitle: 'spare_part_title',
  SparePartArticleNumber: 'spare_part_article_number',
  SparePartDescription: 'spare_part_description',
  SparePartLocation: 'spare_part_location',
  SparePartType: 'spare_part_type',
  SparePartUnit: 'spare_part_unit',
  Qr: 'qr',
  AssetTitle: 'asset_title',
  AssetNumber: 'asset_number',
  AssetType: 'asset_type',
  TemplateField: 'template_field',
};
