import React from 'react';
import { withRouter } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer } from 'views/components/Shared/Layout';
import { Statistics } from 'views/components/Cost';
import Header from '../../Header';

const Costs = ({ match }) => (
  <React.Fragment>
    <PerfectScrollbar>
      <Header />
      <ContentContainer key={match.params.id}>
        <Statistics shownFromAssetId={match.params.id} />
      </ContentContainer>
    </PerfectScrollbar>
  </React.Fragment>
);

export default withRouter(Costs);
