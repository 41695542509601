import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ChecklistTemplateOperations, ChecklistTemplateSelectors } from 'state/ducks/checklistTemplate';
import { MenuOperations } from 'state/ducks/menu';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import { MenuUtils } from 'state/ducks/menu';
import { Button, InlineModal, Icon } from 'views/components/Shared/General';
import HelperFunctions from 'utilities/HelperFunctions';
import { Header as LayoutHeader, ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { ChecklistEditModal } from 'views/components/Checklist';
import { Status } from 'sdk/ChecklistTemplateVersion';
import { SDKReduxOperations } from 'sdk';
import NotFound from './NotFound';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import ChecklistFormContainer from './ChecklistFormContainer';
import ChecklistInstance from './ChecklistInstance';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OldVersionsModal from './OldVersionsModal';
import ChecklistDeleteModal from './ChecklistDeleteModal';

class ChecklistDetail extends Component {
  state = {
    notFound: false,
    dropdownOpen: false,
    deleteModalOpen: false,
    showOldVersionsModal: false,
    showChecklistEditModal: false,
    showChecklistEditModalForVersionId: null,
    isRevertingChanges: false,
    isSaving: false,
    isEditingDraft: false,
    duplicatingTemplateId: false,
  };

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Checklists);
    this.fetchChecklistTemplate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchChecklistTemplate();
    }
  }

  fetchChecklistTemplate = () => {
    return this.props
      .fetchChecklistTemplate(this.props.match.params.id)
      .then(checklistTemplate => {
        const { system_id } = checklistTemplate;
        if (system_id !== this.props.system.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(checklistTemplate.title);
        }
        return checklistTemplate;
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  saveEditChecklistTemplate = () => {
    if (this.state.duplicatingTemplateId) {
      this.setState({ isSaving: true });
      this.props
        .updateChecklistTemplate(this.state.duplicatingTemplateId, { reusable: true })
        .then(({ data: checklistTemplate }) => {
          this.props.history.push(`/checklists/${checklistTemplate.id}`);
          this.setState({ duplicatingTemplateId: null, showChecklistEditModal: false, isSaving: false });
          toast(
            <ToastMessage success text={<FormattedMessage id="screens.checklist.duplicate-success" />} />
          );
        });
    } else {
      this.setState({ isSaving: true });
      this.props
        .updateChecklistTemplateVersion(this.props.checklistTemplate.draft_version, {
          status: Status.Active,
        })
        .then(() => {
          this.fetchChecklistTemplate().then(() => {
            this.setState({ showChecklistEditModal: false, isSaving: false });
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          });
        });
    }
  };

  deleteChecklistTemplateVersion = () => {
    this.setState({ isRevertingChanges: true });
    this.props.deleteChecklistTemplateVersion(this.props.checklistTemplate.draft_version).then(() => {
      this.setState({ showChecklistEditModal: false, isRevertingChanges: false });
    });
  };

  duplicateChecklistTemplate = () => {
    this.setState({
      isEditingDraft: false,
      duplicatingTemplateId: null,
      showChecklistEditModalForVersionId: null,
      showChecklistEditModal: true,
      dropdownOpen: false,
    });
    this.props
      .createChecklistTemplate(this.props.system.id, {
        title: this.props.intl.formatMessage(
          { id: 'screens.checklist.duplicate-title' },
          {
            title: this.props.checklistTemplate.title,
          }
        ),
        duplicate_from_id: this.props.match.params.id,
      })
      .then(({ data: checklistTemplate }) => {
        const { draft_version } = checklistTemplate;
        this.setState({
          duplicatingTemplateId: checklistTemplate.id,
          showChecklistEditModalForVersionId: draft_version.id,
        });
      });
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem
          to={{
            pathname: '/checklists',
          }}
        >
          <FormattedMessage id="screens.checklist.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem to={`/checklists/${this.props.match.params.id}`}>
          <LayoutHeader.BreadcrumbItem active>
            {this.props.checklistTemplate.title}
          </LayoutHeader.BreadcrumbItem>
        </LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderDeleteModal = () => {
    return (
      <ChecklistDeleteModal
        open={this.state.deleteModalOpen}
        checklistTemplate={this.props.checklistTemplate}
        onClose={() => this.setState({ deleteModalOpen: false })}
        onDelete={() => {
          this.setState({ deleteModalOpen: false });
          this.props.history.push(`/checklists`);
        }}
      />
    );
  };

  renderDropdown = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.dropdownOpen}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          <InlineModal.Body width={250} dropdown>
            {this.props.canAdministrateWorkOrders ? (
              <InlineModal.ListItem
                icon="clone"
                iconThickness="regular"
                title={<FormattedMessage id="screens.checklist.dropdown.duplicate" />}
                onClick={() => {
                  this.duplicateChecklistTemplate();
                }}
              />
            ) : null}

            <InlineModal.ListItem
              icon="history"
              iconThickness="regular"
              title={<FormattedMessage id="screens.checklist.dropdown.old-versions" />}
              onClick={() => {
                this.setState({ dropdownOpen: false, showOldVersionsModal: true });
              }}
            />
            {this.props.canAdministrateWorkOrders ? (
              <InlineModal.ListItem
                icon="trash-alt"
                iconThickness="regular"
                title={<FormattedMessage id="screens.checklist.dropdown.delete" />}
                destructive
                onClick={() => {
                  this.setState({ dropdownOpen: false, deleteModalOpen: true });
                }}
              />
            ) : null}
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderTabs = () => (
    <LayoutHeader.TabBar>
      <LayoutHeader.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/checklists/:id',
            exact: true,
          }) != null
        }
        to={`/checklists/${this.props.checklistTemplate.id}`}
      >
        <FormattedMessage id="screens.checklist.tabs.show-form" />
      </LayoutHeader.TabBarItem>
      <LayoutHeader.TabBarItem
        active={
          matchPath(this.props.location.pathname, {
            path: '/checklists/:id/instances',
            exact: true,
          }) != null
        }
        to={`/checklists/${this.props.checklistTemplate.id}/instances`}
      >
        <FormattedMessage id="screens.checklist.tabs.filled-checklists" />
      </LayoutHeader.TabBarItem>
    </LayoutHeader.TabBar>
  );

  renderEditTemplateButton = () => {
    if (this.props.checklistTemplate.active_version && this.props.checklistTemplate.draft_version) {
      return null;
    }
    if (this.props.canAdministrateWorkOrders === false) {
      return null;
    }
    return (
      <Button
        small
        label={'screens.checklist.buttons.change-template'}
        onClick={() => {
          this.setState({
            showChecklistEditModal: true,
            duplicatingTemplateId: null,
            showChecklistEditModalForVersionId: null,
            isEditingDraft: false,
          });
          this.props
            .createChecklistTemplateVersionForChecklistTemplate(this.props.match.params.id, {
              title: this.props.checklistTemplate.title,
            })
            .then(({ data: checklistTemplateVersion }) => {
              this.setState({
                showChecklistEditModalForVersionId: checklistTemplateVersion.id,
              });
            });
        }}
      />
    );
  };

  renderButtons = () => {
    if (this.props.checklistTemplateVersion == null) {
      return null;
    }
    return (
      <Button.Group>
        {this.renderEditTemplateButton()}
        {this.renderDropdown()}
      </Button.Group>
    );
  };

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.checklistTemplate) {
      return <Loader centerInParent />;
    } else {
      return (
        <>
          <PerfectScrollbar>
            <LayoutHeader
              title={this.props.checklistTemplate.title}
              breadcrumbComponent={this.renderBreadcrumb()}
              tabBarComponent={this.renderTabs()}
              buttonsComponent={this.renderButtons()}
            />

            <Switch key={this.props.match.params.id}>
              <Route
                path={`/checklists/:id`}
                exact
                render={props => (
                  <ChecklistFormContainer
                    {...props}
                    onEditDraftTemplateVersion={() => {
                      this.setState({
                        isEditingDraft: true,
                        showChecklistEditModal: true,
                        showChecklistEditModalForVersionId: this.props.checklistTemplate.draft_version,
                      });
                    }}
                  />
                )}
              />
              <Route path={`/checklists/:id/instances`} exact component={ChecklistInstance} />
            </Switch>
            {this.renderDeleteModal()}
          </PerfectScrollbar>
          <OldVersionsModal
            templateId={this.props.match.params.id}
            open={this.state.showOldVersionsModal}
            onClose={() => this.setState({ showOldVersionsModal: false })}
          />
          <ChecklistEditModal
            isCreatingChecklistFromLibrary
            showUnsavedChanges={this.state.duplicatingTemplateId == null}
            open={this.state.showChecklistEditModal}
            id={this.state.showChecklistEditModalForVersionId}
            showAbortChangesButton={this.state.isEditingDraft}
            isSaving={this.state.isSaving}
            isRevertingChanges={this.state.isRevertingChanges}
            onSave={this.saveEditChecklistTemplate}
            onRevertChanges={this.deleteChecklistTemplateVersion}
            onClose={() => {
              if (this.state.duplicatingTemplateId || this.state.isEditingDraft) {
                this.setState({ showChecklistEditModal: false });
              } else {
                this.deleteChecklistTemplateVersion();
              }
            }}
          />
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
      createChecklistTemplateVersionForChecklistTemplate:
        SDKReduxOperations.createChecklistTemplateVersionForChecklistTemplate,
      fetchChecklistTemplate: ChecklistTemplateOperations.fetchChecklistTemplate,
      fetchChecklistTemplateVersion: ChecklistTemplateOperations.fetchChecklistTemplateVersion,
      selectMenuItem: MenuOperations.selectItem,
      updateChecklistTemplate: SDKReduxOperations.updateChecklistTemplate,
      deleteChecklistTemplateVersion: SDKReduxOperations.deleteChecklistTemplateVersion,
      updateChecklistTemplateVersion: SDKReduxOperations.updateChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateId = ownProps.match.params.id;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, checklistTemplateId),
    checklistTemplateVersion: ChecklistTemplateSelectors.getTemplateVersion(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChecklistDetail)));
