import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import { InlineModal, Icon } from 'views/components/Shared/General';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.png';
import styles from './styles.module.scss';

class Image extends Component {
  state = {
    dropdownOpen: false,
    dropdownMode: 'list',
  };

  renderInlineModalContent = () => {
    switch (this.state.dropdownMode) {
      case 'list':
        return (
          <InlineModal.Body width={250} dropdown>
            {this.props.allowSetAsMain ? (
              <React.Fragment>
                <InlineModal.ListItem
                  icon="image"
                  iconThickness="regular"
                  title={<FormattedMessage id="components.image.set-as-main" />}
                  onClick={() => {
                    this.setState({ dropdownOpen: false });
                    this.props.onSetAsMain();
                  }}
                />
                <InlineModal.Separator />
              </React.Fragment>
            ) : null}
            <InlineModal.ListItem
              icon="trash-alt"
              iconThickness="regular"
              iconColor="#C55050"
              destructive
              title={<FormattedMessage id="general.delete" />}
              onClick={() => {
                this.setState({ dropdownMode: 'delete' });
              }}
            />
          </InlineModal.Body>
        );
      case 'delete':
        return (
          <React.Fragment>
            <InlineModal.Header
              width={300}
              title={<FormattedMessage id="screens.asset.info.images.delete-title" />}
              onClose={() => {
                this.setState({ dropdownOpen: false });
              }}
            />
            <InlineModal.Body width={300}>
              <InlineModal.Delete
                loading={this.props.isDeleting}
                onDelete={() => {
                  this.props.onDelete();
                }}
                label="general.remove"
              >
                {this.props.confirmDeleteComponent}
              </InlineModal.Delete>
            </InlineModal.Body>
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  renderDropdownButton = () => {
    if (!this.props.editable) return null;
    return (
      <div className={styles['dropdown-button-container']}>
        <div
          className={styles['dropdown-button']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.stopPropagation();
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
              dropdownMode: 'list',
            }));
          }}
        >
          <Icon regular type="ellipsis-h" />
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          onClose={() => this.setState({ dropdownOpen: false })}
          position="right"
        >
          {this.renderInlineModalContent()}
        </InlineModal>
      </div>
    );
  };

  renderImagesContentLoader = () => {
    let sizeStyle;
    let size;
    if (this.props.large) {
      sizeStyle = styles['large'];
      size = 112;
    } else if (this.props.medium) {
      sizeStyle = styles['medium'];
      size = 75;
    } else {
      sizeStyle = styles['small'];
      size = 48;
    }
    return (
      <div className={`${styles['images-loader-container']} ${sizeStyle}`}>
        <ContentLoader
          primaryColor="#f5f5f5"
          secondaryColor="#EBEBEB"
          height={size}
          width={size}
          preserveAspectRatio="xMinYMin"
        >
          <rect x="0" y="0" rx="56" ry="56" width={`${size}`} height={`${size}`} />
        </ContentLoader>
      </div>
    );
  };

  render() {
    const { image, isDeleting, circle } = this.props;

    let classNames = [styles['image']];
    if (isDeleting) {
      classNames = [...classNames, styles['loading']];
    }
    if (circle) {
      classNames = [...classNames, styles['circle']];
    }
    if (this.props.large) {
      classNames = [...classNames, styles['large']];
    } else if (this.props.medium) {
      classNames = [...classNames, styles['medium']];
    } else {
      classNames = [...classNames, styles['small']];
    }
    if (this.props.loading) {
      return this.renderImagesContentLoader();
    }

    return (
      <div className={classNames.join(' ')}>
        <div
          className={classNames.join(' ')}
          style={{ overflow: 'hidden' }}
          onClick={e => {
            e.stopPropagation();
            this.props.onClick(e);
          }}
        >
          <img
            ref={ref => (this.imageRef = ref)}
            src={`${process.env.REACT_APP_BROWSER_URL}images/${image.id}`}
            onError={e => (this.imageRef.src = ImagePlaceholder)}
          />
          <div className={styles['overlay']} />
        </div>
        {this.renderDropdownButton()}
      </div>
    );
  }
}

export default Image;

Image.propTypes = {
  large: PropTypes.bool,
  loading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  confirmDeleteComponent: PropTypes.element,
  allowSetAsMain: PropTypes.bool,
  onSetAsMain: PropTypes.func,
};

Image.defaultProps = {
  large: false,
  small: false,
  loading: false,
  tiny: false,
  medium: false,
  circle: false,
  editable: true,
  onClick: () => {},
  onDelete: () => {},
  isDeleting: false,
  confirmDeleteComponent: null,
  allowSetAsMain: false,
  onSetAsMain: () => null,
};
