import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  normalizeVendor,
  normalizeSparePart,
  normalizeContactPerson,
  normalizeAttachment,
  normalizeAssetVendor,
  normalizePurchaseOrder,
  normalizeWorkOrder,
} from 'sdk/Schemas';

const fetchVendor = id => dispatch => {
  dispatch(actions.setVendorId(id));
  return API.fetchVendor(id).then(res => {
    const { entities } = normalizeVendor(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    return res.data;
  });
};

const fetchContactPersons =
  (vendorId, params = {}) =>
  dispatch => {
    return API.listContactPersonsForVendor(vendorId, { ...params, page_size: 8 }).then(res => {
      const { entities, result } = normalizeContactPerson(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchContactPersonsSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

const fetchAttachments =
  (vendorId, params = {}) =>
  dispatch => {
    return API.listAttachmentsForVendor(vendorId, { ...params, page_size: 8 }).then(res => {
      const { entities, result } = normalizeAttachment(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchAttachmentsSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

const fetchAssetVendors =
  (systemId, vendorId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      sort: 'asset_title',
      'sort-order': 'asc',
      vendor_id: vendorId,
      page_size: 8,
    };

    return API.listAssetVendors(systemId, attrs).then(res => {
      const { entities, result } = normalizeAssetVendor(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchAssetVendorsSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchSpareParts =
  (systemId, vendorId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
      spare_part_vendors: { vendor_id: vendorId },
      spare_part_vendors_for_vendor: vendorId,
      sort: 'title',
      'sort-order': 'asc',
    };
    return API.listSpareParts(systemId, attrs).then(({ data: spareParts, headers }) => {
      let sparePartVendorIdsBySparePartId = {};
      spareParts.forEach(sparePart => {
        sparePartVendorIdsBySparePartId = {
          ...sparePartVendorIdsBySparePartId,
          [sparePart.id]: sparePart.spare_part_vendors_for_vendor.map(({ id }) => id),
        };
      });
      const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchSparePartsSuccess({
          ids: sparePartIds,
          sparePartVendorIdsBySparePartId,
          pagination,
        })
      );
    });
  };

const fetchPurchaseOrders =
  (systemId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
    };

    return API.listPurchaseOrders(systemId, attrs).then(res => {
      const { entities, result } = normalizePurchaseOrder(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchPurchaseOrdersSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchWorkOrders = (systemId, params) => dispatch => {
  return API.listWorkOrders(systemId, params).then(res => {
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    const { entities, result } = normalizeWorkOrder(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchWorkOrdersSuccess({
        ids: result,
        pagination,
      })
    );
    return res.data;
  });
};

export default {
  fetchVendor,
  fetchContactPersons,
  fetchAttachments,
  fetchAssetVendors,
  fetchSpareParts,
  fetchPurchaseOrders,
  fetchWorkOrders,
};
