import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Banner } from 'views/components/Shared/General';
import styles from './style.module.scss';

const UserIsArchivedBanner = () => {
  return (
    <div className={styles['banner']}>
      <Banner red>
        <FormattedMessage id="screens.users.archived-banner" />
      </Banner>
    </div>
  );
};

export default UserIsArchivedBanner;
