const MENU_ITEM_TYPE = {
  ProductionBoard: 'productionBoard',
  WorkOrders: 'work_orders',
  Downtimes: 'downtimes',
  Costs: 'costs',
  OperationalMaintenances: 'operationalMaintennaces',
  Requests: 'requests',
  Asset: 'asset',
  Statistics: 'statistics',
  Vendor: 'vendor',
  Files: 'files',
  Profile: 'profile',
  Settings: 'settings',
  SpareParts: 'spareParts',
  SparePartWithdrawals: 'sparePartWithdrawals',
  Stocktaking: 'stocktaking',
  LogOut: 'logOut',
  PurchaseOrders: 'purchaseOrders',
  Calendar: 'calendar',
  Checklists: 'checklistTemplates',
  Meters: 'meters',
};

export default {
  MENU_ITEM_TYPE,
};
