import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Type } from 'sdk/ChecklistTemplateRow';
import SectionContainer from './SectionContainer';
import { ChecklistTemplateRow } from 'views/components/Checklist';

class ChecklistTemplateRowContainer extends Component {
  render() {
    switch (this.props.checklistTemplateRow.type) {
      case Type.Inspection:
        return <ChecklistTemplateRow.Inspection id={this.props.id} editable={false} />;
      case Type.Meter:
        return <ChecklistTemplateRow.Meter id={this.props.id} editable={false} />;
      case Type.Section:
        return (
          <SectionContainer
            editable={false}
            id={this.props.id}
            prevTemplateRowId={this.props.prevTemplateRowId}
            nextTemplateRowId={this.props.nextTemplateRowId}
          />
        );
      default:
        return null;
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateRowContainer);
