import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { AuthOperations } from 'state/ducks/auth';
import {
  List,
  SectionHeader,
  NewInlineModal,
  Icon,
  EmptyDataSet,
  ConfirmModal,
} from 'views/components/Shared/General';
import { normalizeUser } from 'sdk/Schemas';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import { EntityOperations } from 'sdk/State/entities';
import { ConnectedIntlProvider } from 'sdk/Components';
import { HelperFunctions, API } from 'sdk';
import styles from './style.module.scss';
import UserListItem from './UserListItem';
import SearchField from './SearchField';
import Layout from './Layout';
import { PinCodeOverlay } from 'views/components/User';

const PAGE_SIZE = 20;

class LoginUserForGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIds: [],
      paginateFrom: null,
      canFetchMoreUsers: false,
      isFetching: true,
      isSearching: false,
      isFetchingMoreUsers: false,
      isSigningInUserId: null,
      searchTerm: '',
      language: localStorage.getItem('gLoginLang') || 'sv',
      showPinCodeOverlay: false,
      showPinCodeForUserId: null,
      showConfirmLogoutModal: false,
      showMobileLanguageInlineModal: false,
      showAssetsDropdown: false,
      checkedInAssetId: localStorage.getItem('checkedInAssetId') || null,
    };
  }

  componentDidMount() {
    this.fetchUsers({
      asset_operators: {
        asset_with_tree_children_id: this.state.checkedInAssetId,
      },
    })
      .then(({ userIds, paginateFrom }) => {
        if (this.state.checkedInAssetId && userIds.length === 0) {
          this.setState({ checkedInAssetId: null });
          this.fetchUsers().then(({ userIds, paginateFrom }) => {
            this.setState({
              userIds,
              paginateFrom,
              isFetching: false,
              canFetchMoreUsers: userIds.length >= PAGE_SIZE,
            });
          });
        } else {
          this.setState({
            userIds,
            paginateFrom,
            isFetching: false,
            canFetchMoreUsers: userIds.length >= PAGE_SIZE,
          });
        }
      })
      .catch(() => {
        localStorage.removeItem('gSystemId');
        localStorage.removeItem('gToken');
        this.props.unauthenticateGroup();
      });
  }

  fetchUsers = params => {
    return API.listUsersForGroup({ page_size: PAGE_SIZE, ...params }).then(({ data: users, headers }) => {
      const { entities, result: userIds } = normalizeUser(users);
      const { paginateFrom } = HelperFunctions.getPaginationFromHeader(headers);
      this.props.updateEntities(entities);
      return { userIds, paginateFrom };
    });
  };

  searchUsers = () => {
    let params = {
      search: this.state.searchTerm,
    };
    if (this.state.searchTerm.length === 0) {
      params = {
        ...params,
        asset_operators: {
          asset_with_tree_children_id: this.state.checkedInAssetId,
        },
      };
    }
    this.fetchUsers(params).then(({ userIds, paginateFrom }) => {
      this.setState({
        userIds,
        paginateFrom,
        isSearching: false,
        canFetchMoreUsers: userIds.length >= PAGE_SIZE,
      });
    });
  };

  fetchMoreUsers = () => {
    if (!this.state.canFetchMoreUsers) {
      return;
    }
    if (this.state.isFetchingMoreUsers) {
      return;
    }
    this.setState({ isFetchingMoreUsers: true });
    this.fetchUsers({ paginate_from: this.state.paginateFrom, search: this.state.searchTerm }).then(
      ({ userIds, paginateFrom }) => {
        this.setState({
          userIds: [...this.state.userIds, ...userIds],
          paginateFrom,
          isFetchingMoreUsers: false,
          canFetchMoreUsers: userIds.length >= PAGE_SIZE,
        });
      }
    );
  };

  signInUser = user => {
    if (this.state.isSigningInUserId) {
      return;
    }
    if (user.pin_code_required) {
      this.setState({ showPinCodeOverlay: true, showPinCodeForUserId: user.id });
    } else {
      this.setState({ isSigningInUserId: user.id });
      this.props.signInGroupUser({ user_id: user.id });
    }
  };

  selectLanguage = language => {
    this.setState({ language, showLanguageInlineModal: false });
    localStorage.setItem('gLoginLang', language);
  };

  renderEmptyDataSet = () => {
    if (this.state.searchTerm.length === 0) {
      return (
        <>
          <EmptyDataSet
            title={<FormattedMessage id="components.select-user-group-login.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.select-user-group-login.empty-data-set.subtitle" />}
            modal
          />
        </>
      );
    }
    return (
      <>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          modal
        />
      </>
    );
  };
  renderConfirmLogoutModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmLogoutModal}
        title={<FormattedMessage id="components.select-user-group-login.logout-confirm-title" />}
        message={<FormattedMessage id="components.select-user-group-login.logout-confirm-subtitle" />}
        confirmButtonText="components.select-user-group-login.logout"
        onConfirm={this.props.unauthenticateGroup}
        onCancel={() => this.setState({ showConfirmLogoutModal: false })}
      />
    );
  };

  renderMobileButtons = () => {
    return (
      <>
        <div className={styles['mobile-buttons-container']}>
          <div className={styles['sign-out-button-mobile']} onClick={this.props.unauthenticateGroup}>
            <FormattedMessage id="menu.log-out" />
          </div>
          <div
            className={styles['language-button-mobile']}
            ref={ref => (this.mobileLanguageInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showMobileLanguageInlineModal: !prevState.showMobileLanguageInlineModal,
              }));
            }}
          >
            <div>
              <Icon type="globe-europe" regular size={14} />
            </div>
            <div className={styles['label']}>
              <FormattedMessage id={`general.languages.${this.state.language}`} />
            </div>
            <div>
              <Icon type="caret-down" size={12} />
            </div>
          </div>
        </div>
        <NewInlineModal
          minWidth={150}
          width={150}
          positionToRef={this.mobileLanguageInlineModalPositioningRef}
          open={this.state.showMobileLanguageInlineModal}
          onClose={() => this.setState({ showMobileLanguageInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => this.selectLanguage('en')}
                selected={this.state.language === 'en'}
              >
                <FormattedMessage id="general.languages.en" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => this.selectLanguage('sv')}
                selected={this.state.language === 'sv'}
              >
                <FormattedMessage id="general.languages.sv" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderLeftContainerButtons = () => {
    return (
      <>
        <div className={styles['buttons']}>
          <div className={styles['button']} onClick={() => this.setState({ showConfirmLogoutModal: true })}>
            <FormattedMessage id="menu.log-out" />
          </div>
          <div
            className={styles['language-button']}
            ref={ref => (this.languageInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showLanguageInlineModal: !prevState.showLanguageInlineModal,
              }));
            }}
          >
            <div>
              <Icon type="globe-europe" regular size={14} color="#FFF" />
            </div>
            <div className={styles['label']}>
              <FormattedMessage id={`general.languages.${this.state.language}`} />
            </div>
            <div>
              <Icon type="caret-down" size={12} color="#FFF" />
            </div>
          </div>
        </div>
        <NewInlineModal
          minWidth={150}
          positionToRef={this.languageInlineModalPositioningRef}
          open={this.state.showLanguageInlineModal}
          onClose={() => this.setState({ showLanguageInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => this.selectLanguage('en')}
                selected={this.state.language === 'en'}
              >
                <FormattedMessage id="general.languages.en" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => this.selectLanguage('sv')}
                selected={this.state.language === 'sv'}
              >
                <FormattedMessage id="general.languages.sv" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderCheckedInAsset = () => {
    const classes = [styles['selected-asset']];
    if (this.state.searchTerm.length > 0) {
      classes.push(styles['disabled']);
    }
    if (localStorage.getItem('checkedInAssetTitle')) {
      return (
        <>
          <div className={styles['checked-in-asset-container']}>
            <div className={styles['label']}>
              <FormattedMessage id="components.select-user-group-login.show-users-for" />
              <span>: </span>
            </div>
            <div
              className={classes.join(' ')}
              ref={ref => (this.assetsDropdownRef = ref)}
              onClick={() => {
                if (this.state.searchTerm.length > 0) {
                  return;
                }
                this.setState(prevState => ({
                  showAssetsDropdown: !prevState.showAssetsDropdown,
                }));
              }}
            >
              <div>
                {this.state.checkedInAssetId && this.state.searchTerm.length === 0 ? (
                  localStorage.getItem('checkedInAssetTitle')
                ) : (
                  <FormattedMessage id="components.select-user-group-login.all-assets" />
                )}
              </div>
              {this.state.searchTerm.length === 0 ? (
                <div className={styles['icon-container']}>
                  <Icon type="caret-down" />
                </div>
              ) : null}
            </div>
          </div>

          <NewInlineModal
            minWidth={250}
            positionToRef={this.assetsDropdownRef}
            open={this.state.showAssetsDropdown}
            onClose={() => this.setState({ showAssetsDropdown: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState(
                      {
                        checkedInAssetId: localStorage.getItem('checkedInAssetId'),
                        isSearching: true,
                        showAssetsDropdown: false,
                      },
                      () =>
                        this.fetchUsers({
                          asset_operators: {
                            asset_with_tree_children_id: this.state.checkedInAssetId,
                          },
                        }).then(({ userIds, paginateFrom }) => {
                          this.setState({
                            userIds,
                            paginateFrom,
                            isSearching: false,
                            canFetchMoreUsers: userIds.length >= PAGE_SIZE,
                          });
                        })
                    );
                  }}
                  selected={this.state.checkedInAssetId != null}
                >
                  <span>{localStorage.getItem('checkedInAssetTitle')}</span>
                </NewInlineModal.Dropdown.Item>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState(
                      { checkedInAssetId: null, isSearching: true, showAssetsDropdown: false },
                      () =>
                        this.fetchUsers({
                          asset_operators: {
                            asset_with_tree_children_id: this.state.checkedInAssetId,
                          },
                        }).then(({ userIds, paginateFrom }) => {
                          this.setState({
                            userIds,
                            paginateFrom,
                            isSearching: false,
                            canFetchMoreUsers: userIds.length >= PAGE_SIZE,
                          });
                        })
                    );
                  }}
                  selected={this.state.checkedInAssetId == null}
                >
                  <FormattedMessage id="components.select-user-group-login.all-assets" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </>
      );
    }
    return null;
  };

  renderUsers = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderCheckedInAsset()}
          <SectionHeader noBorderBottom>
            <FormattedMessage id="components.select-user-group-login.section-header" />
          </SectionHeader>
          <List light>
            <UserListItem loading />
            <UserListItem loading />
          </List>
        </>
      );
    }
    if (this.state.isSearching) {
      const amountOfUsers = this.state.userIds.length === 0 ? 2 : this.state.userIds.length;
      return (
        <>
          {this.renderCheckedInAsset()}
          <SectionHeader noBorderBottom>
            <FormattedMessage id="components.select-user-group-login.section-header" />
          </SectionHeader>
          <List light>
            {Array(amountOfUsers)
              .fill()
              .map(() => (
                <>
                  <UserListItem loading />
                  <UserListItem loading />
                </>
              ))}
          </List>
        </>
      );
    }
    if (this.state.userIds.length === 0) {
      return (
        <>
          {this.renderCheckedInAsset()}
          {this.renderEmptyDataSet()}
        </>
      );
    }
    return (
      <>
        {this.renderCheckedInAsset()}
        <SectionHeader noBorderBottom>
          <FormattedMessage id="components.select-user-group-login.section-header" />
        </SectionHeader>
        <List light>
          {this.state.userIds.map(id => (
            <UserListItem
              id={id}
              key={id}
              isSigningInUserId={this.state.isSigningInUserId}
              onSignInGroupUser={user => this.signInUser(user)}
            />
          ))}
          {this.state.isFetchingMoreUsers ? (
            <>
              <UserListItem loading />
              <UserListItem loading />
            </>
          ) : null}
        </List>
      </>
    );
  };

  renderSearchField = () => {
    return (
      <div className={styles['search-field-container']}>
        <SearchField
          autoFocus
          value={this.state.searchTerm}
          debounce
          onDebouncedSearch={value => {
            this.searchUsers(value);
          }}
          onSearch={searchTerm => {
            this.setState({ searchTerm, isSearching: true });
          }}
          onClear={() => {
            this.setState({ searchTerm: '', isSearching: true }, () => this.searchUsers());
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <ConnectedIntlProvider textComponent="span" language={this.state.language}>
        <Layout>
          <Layout.LeftContainer
            title={<FormattedMessage id="components.select-user-group-login.title" />}
            subtitle={<FormattedMessage id="components.select-user-group-login.subtitle" />}
            buttons={this.renderLeftContainerButtons()}
          />
          <Layout.RightContainer ignoreScroll>
            <PerfectScrollbar>
              <InfiniteScroll
                loadMore={this.fetchMoreUsers}
                hasMore={this.state.canFetchMoreUsers}
                useWindow={false}
                initialLoad={false}
                threshold={150}
              >
                <div className={styles['mobile-position']}>
                  <Layout.RightContainer.Content>
                    <div className={styles['header']}>
                      <div className={styles['text']}>
                        <FormattedMessage id="components.select-user-group-login.select-user-title" />
                      </div>
                      <div className={styles['subtitle']}>
                        <FormattedMessage id="components.select-user-group-login.select-user-subtitle" />
                      </div>
                    </div>
                    {this.renderSearchField()}
                    {this.renderUsers()}
                  </Layout.RightContainer.Content>
                </div>
              </InfiniteScroll>
            </PerfectScrollbar>
            {this.renderMobileButtons()}
            <PinCodeOverlay
              open={this.state.showPinCodeOverlay}
              userId={this.state.showPinCodeForUserId}
              onClose={() => {
                this.setState({ showPinCodeOverlay: false });
              }}
            />
          </Layout.RightContainer>
        </Layout>
        {this.renderConfirmLogoutModal()}
      </ConnectedIntlProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      signInGroupUser: AuthOperations.signInGroupUser,
      unauthenticateGroup: AuthOperations.unauthenticateGroup,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(LoginUserForGroup);
