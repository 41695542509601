import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'views/components/Shared/Layout';
import View from './View';
import Edit from './Edit';

class ContactPersonModal extends Component {
  state = {
    id: null,
    mode: null,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id) {
        this.setState({ mode: 'view', id: this.props.id });
      } else {
        this.setState({ mode: 'new', id: null });
      }
    }
  }

  renderContent = () => {
    switch (this.state.mode) {
      case 'new':
        return (
          <Edit
            new
            onClose={this.props.onClose}
            onCancel={() => {
              this.props.onClose();
            }}
            onSaved={contactPerson => {
              this.props.onClose();
            }}
          />
        );
      case 'edit':
        return (
          <Edit
            id={this.state.id}
            onClose={this.props.onClose}
            onCancel={() => {
              this.setState({ mode: 'view' });
            }}
            onSaved={() => {
              this.setState({ mode: 'view' });
            }}
          />
        );
      case 'view':
        return (
          <View
            id={this.state.id}
            onClose={this.props.onClose}
            onEdit={() => {
              this.setState({ mode: 'edit' });
            }}
            onDelete={id => this.props.onDelete(id)}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        {this.renderContent()}
      </Modal>
    );
  }
}

export default ContactPersonModal;

ContactPersonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ContactPersonModal.defaultProps = {
  onClose: () => {},
};
