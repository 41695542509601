import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType } from 'sdk/Export';
import { OperationalMaintenancesSelectors } from 'state/ducks/operationalMaintenances';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    return {
      id: {
        any: Object.keys(this.props.selectedOperationalMaintenanceIds).toString(),
      },
    };
  };

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.buildFilterParams(),
      type: this.props.exportType,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.selectedOperationalMaintenancesCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-operational-maintenances.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.two"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.few"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.many"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-operational-maintenances.other"
            values={{ count: this.props.selectedOperationalMaintenancesCount }}
          />
        }
      />
    );
  };

  renderContent = () => {
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.exportType === ExportType.WorkOrdersXslx) {
      return <FormattedMessage id="components.export-modal.title-xslx" />;
    }
    return <FormattedMessage id="components.export-modal.title-print" />;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={this.renderTitle()}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    selectedOperationalMaintenanceIds:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenanceIds(state),
    selectedOperationalMaintenancesCount:
      OperationalMaintenancesSelectors.getSelectedOperationalMaintenancesCount(state),
  };
}

export default connect(mapStateToProps)(ExportModal);

ExportModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.WorkOrdersXslx, ExportType.WorkOrdersListPdf]),
};

ExportModal.defaultProps = {
  exportType: null,
};
