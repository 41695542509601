import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flatten } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SelectAllRowsCheckbox extends Component {
  render() {
    const checked = this.props.checklistInstanceRowIds.length === this.props.selectedInstanceRows.length;
    return (
      <div className={styles['select-all-rows-container']}>
        <Field.Checkbox
          checked={checked}
          onChange={() => {
            if (checked) {
              this.props.clearDeviationRows();
            } else {
              this.props.addMultipleDeviationRows(this.props.checklistInstanceRowIds);
            }
          }}
          label={<FormattedMessage id="components.checklist-instance-modal.deviations.select-all-rows" />}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearDeviationRows: ChecklistModalOperations.clearDeviationRows,
      addMultipleDeviationRows: ChecklistModalOperations.addMultipleDeviationRows,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    selectedInstanceRows: ChecklistModalSelectors.getSelectedInstanceRows(state),
    checklistInstanceRowIds: flatten(
      ownProps.checklistTemplateRowIds.map(id => {
        const templateRow = EntitySelectors.getChecklistTemplateRow(state, id);
        if (templateRow.type === 'section') {
          let childIds = ChecklistModalSelectors.getChildTemplateIdsForRow(state, id);
          return childIds.map(id => {
            return ChecklistModalSelectors.getInstanceRowForTemplateRowId(state, id).id;
          });
        } else {
          return ChecklistModalSelectors.getInstanceRowForTemplateRowId(state, id).id;
        }
      })
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllRowsCheckbox);
