/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getUsers = state => {
  const { users, entities } = state;
  return users.users.ids.map(id => entities.userById[id]);
};

const getAmountOfPayingMembers = state => {
  const { users } = state;
  return users.users.amountOfPayingMembers;
};

const getPermissionProfiles = state => {
  const { users, entities } = state;
  return users.permissionProfiles.ids.map(id => entities.permissionProfileById[id]);
};

/*
  --------------------
    UI SELECTORS
  --------------------
*/
const getTotalEntries = state => state.users.totalEntries;
const getUsersPagination = state => state.users.users.pagination;
const getUsersQueryParameters = state => {
  const { users } = state;
  return users.users.queryParams;
};

/*
  MassEdit
*/

const getSelectedUserIds = state => {
  return state.users.users.selectedUsers.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.users.users.selectedUsers.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.users.users.selectedUsers.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.users.users.selectedUsers.pageIsSelected;
};

const getSelectedUsersCount = state => {
  if (state.users.users.selectedUsers.totalEntriesAreSelected) {
    return state.users.totalEntries;
  }
  return Object.keys(state.users.users.selectedUsers.ids).length;
};

export default {
  getUsers,
  getUsersPagination,
  getAmountOfPayingMembers,
  getPermissionProfiles,
  getTotalEntries,
  getUsersQueryParameters,
  getSelectedUserIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedUsersCount,
};
