import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TemplateFieldType } from 'sdk/TemplateField';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { connect } from 'react-redux';
import DraggableTemplateFieldContainer from '../DraggableTemplateFieldContainer';
import styles from './style.module.scss';

class NewFieldRightPanel extends React.Component {
  generateDraggableFields = () => {
    return [
      {
        id: `new_${uuidv4()}_${TemplateFieldType.Text}`,
        type: TemplateFieldType.Text,
      },
      {
        id: `new_${uuidv4()}_${TemplateFieldType.TextArea}`,
        type: TemplateFieldType.TextArea,
      },
      {
        id: `new_${uuidv4()}_${TemplateFieldType.ValueList}`,
        type: TemplateFieldType.ValueList,
      },
      {
        id: `new_${uuidv4()}_${TemplateFieldType.Date}`,
        type: TemplateFieldType.Date,
      },
    ];
  };

  state = {
    fields: this.generateDraggableFields(),
  };

  getTranslationIdForType = type => {
    switch (type) {
      case TemplateFieldType.Text:
        return 'resources.template-field.types.text';
      case TemplateFieldType.TextArea:
        return 'resources.template-field.types.textarea';
      case TemplateFieldType.ValueList:
        return 'resources.template-field.types.dropdown';
      case TemplateFieldType.Date:
        return 'resources.template-field.types.date';
      default:
        return null;
    }
  };

  renderFields = () => {
    return this.state.fields.map(({ id, type }) => {
      return (
        <DraggableTemplateFieldContainer key={id} id={id} new>
          {this.renderField(type)}
        </DraggableTemplateFieldContainer>
      );
    });
  };
  renderField = type => {
    return (
      <div className={styles['type']}>
        <div className={styles['drag-handle']}>
          <div className={styles['row']}>
            <div className={styles['dot']} />
            <div className={styles['dot']} />
          </div>
          <div className={styles['row']}>
            <div className={styles['dot']} />
            <div className={styles['dot']} />
          </div>
          <div className={styles['row']}>
            <div className={styles['dot']} />
            <div className={styles['dot']} />
          </div>
        </div>
        <div>
          <FormattedMessage id={this.getTranslationIdForType(type)} />
        </div>
      </div>
    );
  };

  renderSubtitle = () => {
    if (this.props.templateType === 'asset') {
      return (
        <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.right-menu.add-field-subtitle.asset" />
      );
    }

    if (this.props.templateType === 'spare_part') {
      return (
        <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.right-menu.add-field-subtitle.spare-part" />
      );
    }
    return null;
  };

  renderHeader = () => {
    return (
      <>
        <div className={styles['title']}>
          <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.right-menu.add-field" />
        </div>
        <div className={styles['subtitle']}>{this.renderSubtitle()}</div>
      </>
    );
  };

  render() {
    return (
      <div className={styles['container']}>
        {this.renderHeader()}
        {this.renderFields()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    templateField: EntitySelectors.getTemplateField(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(NewFieldRightPanel);
