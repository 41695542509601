import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import { SectionHeader } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import VersionListItem from './VersionListItem';

class Versions extends Component {
  renderVersions = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={28}>
          <span>
            <FormattedMessage id="screens.operational-maintenance-template.settings.versions.section-title" />
          </span>
        </SectionHeader>
        {this.props.operationalMaintenanceTemplateVersions.map(({ id }) => (
          <VersionListItem id={id} key={id} />
        ))}
      </>
    );
  };

  renderVersionsDescription = () => {
    return (
      <div className={styles['versions-description']}>
        <FormattedMessage id="screens.operational-maintenance-template.settings.versions.description" />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderVersionsDescription()}
        {this.renderVersions()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const operationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    operationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  return {
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplate,
    operationalMaintenanceTemplateVersions: EntitySelectors.getOperationalMaintenanceTemplateVersions(
      state,
      operationalMaintenanceTemplate.operational_maintenance_template_versions
    ),
  };
}

export default connect(mapStateToProps)(Versions);
