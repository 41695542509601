import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { SparePartTypeInlineModal } from 'views/components/SparePart';
import { SparePartTypePath } from 'views/components/SparePart';

class SparePartTypeField extends Component {
  render() {
    return (
      <Field label={<FormattedMessage id="resources.spare-part.category" />}>
        <SparePartTypeInlineModal
          trigger={
            <Field.Resource
              value={
                this.props.sparePartType ? (
                  <SparePartTypePath fullPath dark size={13} sparePartTypeId={this.props.sparePartType.id} />
                ) : null
              }
              angleDown
              onClear={() => this.props.onChange(null)}
            />
          }
          selectedSparePartTypeId={this.props.value}
          onSelectSparePartType={id => this.props.onChange(id)}
          onClear={() => this.props.onChange(null)}
        />
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(SparePartTypeField);

SparePartTypeField.propTypes = {
  value: PropTypes.string,
};
