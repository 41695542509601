const DOMAIN_PREFIX = 'requests/';

const RESET_SELECTED_REQUESTS = 'requests/reset_selected_requests';
const HIDE_SELECT_TOTAL_ENTRIES = 'requests/hide_select_total_entries';
const SELECT_TOTAL_ENTRIES = 'requests/select_total_entries';
const SELECT_PAGE = 'requests/select_page';
const SELECT_REQUEST = 'requests/select_request';

const ADD_QUERY_PARAMETER = `${DOMAIN_PREFIX}add_query_parameter`;
const SHOW_APPLIED_FILTERS = `${DOMAIN_PREFIX}show_applied_filters`;
const SHOW_AVAILABLE_FILTERS = `${DOMAIN_PREFIX}show_available_filters`;
const SELECT_FILTER_TYPE = `${DOMAIN_PREFIX}select_filter_type`;
const ADD_FILTER = `${DOMAIN_PREFIX}add_filter`;
const REMOVE_FILTER = `${DOMAIN_PREFIX}remove_filter`;
const RESET_FILTER = `${DOMAIN_PREFIX}reset_filter`;

const SET_LOADING = `${DOMAIN_PREFIX}set_loading`;
const INITIALIZE_VIEW = `${DOMAIN_PREFIX}initialize_view`;
const SET_GROUP_BY = `${DOMAIN_PREFIX}set_group_by`;

const FETCH_REQUESTS_FOR_INBOX = `${DOMAIN_PREFIX}fetch_requests_for_inbox`;
const FETCH_REQUESTS_FOR_INBOX_SUCCESS = `${DOMAIN_PREFIX}fetch_requests_success_for_inbox`;
const FETCH_MORE_REQUESTS_FOR_INBOX = `${DOMAIN_PREFIX}fetch_more_requests_for_inbox`;
const FETCH_MORE_REQUESTS_FOR_INBOX_SUCCESS = `${DOMAIN_PREFIX}fetch_more_requests_for_inbox_success`;

const FETCH_REQUESTS_FOR_LIST = `${DOMAIN_PREFIX}fetch_requests_for_list`;
const FETCH_REQUESTS_FOR_LIST_SUCCESS = `${DOMAIN_PREFIX}fetch_requests_for_list_success`;

const SET_REQUEST_FOR_VIEW = `${DOMAIN_PREFIX}set_request_for_view`;
const SET_REQUEST_FOR_EDIT = `${DOMAIN_PREFIX}set_request_for_edit`;

const PREPARE_NEW_REQUEST = `${DOMAIN_PREFIX}prepare_new_request`;
const CANCEL_NEW_REQUEST = `${DOMAIN_PREFIX}cancel_new_request`;
const SET_EDITING_REQUEST_VALUES = `${DOMAIN_PREFIX}set_editing_request_value`;

export default {
  RESET_SELECTED_REQUESTS,
  HIDE_SELECT_TOTAL_ENTRIES,
  SELECT_REQUEST,
  SELECT_TOTAL_ENTRIES,
  SELECT_PAGE,
  RESET_FILTER,
  ADD_QUERY_PARAMETER,
  SHOW_APPLIED_FILTERS,
  SHOW_AVAILABLE_FILTERS,
  SELECT_FILTER_TYPE,
  ADD_FILTER,
  REMOVE_FILTER,
  DOMAIN_PREFIX,
  SET_LOADING,
  SET_GROUP_BY,
  CANCEL_NEW_REQUEST,
  SET_REQUEST_FOR_VIEW,
  SET_REQUEST_FOR_EDIT,
  FETCH_REQUESTS_FOR_INBOX,
  FETCH_REQUESTS_FOR_INBOX_SUCCESS,
  FETCH_REQUESTS_FOR_LIST,
  FETCH_REQUESTS_FOR_LIST_SUCCESS,
  FETCH_MORE_REQUESTS_FOR_INBOX,
  FETCH_MORE_REQUESTS_FOR_INBOX_SUCCESS,
  INITIALIZE_VIEW,
  PREPARE_NEW_REQUEST,
  SET_EDITING_REQUEST_VALUES,
};
