import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field, Tooltip, Loader, Icon } from 'views/components/Shared/General';
import { CurrencyRateUpgradeProModal } from 'views/components/PurchaseOrder';
import { request } from 'sdk/utilities/Axios';
import styles from './style.module.scss';

class CurrencyRateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingRate: false,
      exchangeRate: '',
      showBasicFetchRateModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        exchangeRate: '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  fetchCurrencyRate = currency => {
    if (this.props.hasProTier) {
      this.setState({ isFetchingRate: true, selectedCurrency: currency });
      request
        .get(
          `systems/${this.props.system.id}/currency_exchange_rate?from=${this.props.currency}&to=${this.props.system.currency}`
        )
        .then(({ data }) => {
          this.setState({ isFetchingRate: false, exchangeRate: data.rate });
        });
    } else {
      this.setState({ selectedCurrency: currency });
    }
  };

  renderBasicFetchRateModal = () => (
    <CurrencyRateUpgradeProModal
      open={this.state.showBasicFetchRateModal}
      onClose={() => {
        this.setState({ showBasicFetchRateModal: false });
      }}
    />
  );

  renderCurrency = currency => {
    return <FormattedMessage id={`currencies.${currency}`} />;
  };

  renderTitle = () => {
    return (
      <FormattedMessage
        id="components.new-purchase-order-modal.currency-rate-modal.title"
        values={{
          currency: this.renderCurrency(this.props.currency),
        }}
      />
    );
  };

  renderSubtitle = () => {
    if (this.props.hasProTier === false) {
      return (
        <FormattedMessage
          id="components.new-purchase-order-modal.currency-rate-modal.subtitle-basic"
          values={{
            currency: this.renderCurrency(this.props.system.currency),
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="components.new-purchase-order-modal.currency-rate-modal.subtitle-pro"
          values={{
            currency: this.renderCurrency(this.props.system.currency),
          }}
        />
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={475}>
          <Modal.Header
            ignoreLine
            title={this.renderTitle()}
            subtitle={this.renderSubtitle()}
            subtitleTopMargin
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    view={false}
                    label={
                      <div className={styles['conversion-rate-field']}>
                        <p className={styles['label']}>
                          <FormattedMessage
                            id="components.change-purchase-order-currency-modal.conversion-rate-label"
                            values={{ currency: this.props.system.currency }}
                          />
                        </p>
                        {this.props.hasProTier === false ? (
                          <p
                            className={styles['conversion-rate']}
                            onClick={() => this.setState({ showBasicFetchRateModal: true })}
                          >
                            <FormattedMessage id="components.change-purchase-order-currency-modal.fetch-rate" />
                          </p>
                        ) : null}
                      </div>
                    }
                  >
                    <div className={styles['conversion-rate-container']}>
                      <Field.Decimal
                        disabled={this.state.isFetchingRate}
                        value={this.state.exchangeRate}
                        rightLabel={this.state.isFetchingRate ? <Loader tiny /> : null}
                        onBlur={value => this.setState({ exchangeRate: value })}
                      />
                      {this.props.hasProTier === false ? null : (
                        <Tooltip
                          trigger={
                            <span>
                              <Button
                                icon={<Icon regular type="redo-alt" />}
                                onClick={() => this.fetchCurrencyRate()}
                              />
                            </span>
                          }
                          label={
                            <FormattedMessage id="components.change-purchase-order-currency-modal.rate-tooltip" />
                          }
                        />
                      )}
                    </div>

                    <p className={styles['conversion-rate-subtitle']}>
                      <span>
                        1 <FormattedMessage id={`currencies.${this.props.currency}`} />
                      </span>
                      <span> = </span>
                      <span>
                        {this.state.exchangeRate || 0}{' '}
                        <FormattedMessage id={`currencies.${this.props.system.currency}`} />
                      </span>
                    </p>
                  </Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={
                  this.props.system.currency !== this.state.selectedCurrency && !this.state.exchangeRate
                }
                label="general.save"
                loading={this.props.isSaving}
                onClick={() => {
                  this.props.onSave(this.state.exchangeRate);
                }}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderBasicFetchRateModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps)(CurrencyRateModal));
