import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, find } from 'lodash-es';
import AnimateHeight from 'react-animate-height';
import { withRouter } from 'react-router';
import * as moment from 'moment';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  getElapsedMinutesFromHourAndMinutes,
} from 'sdk/WorkOrder';
import {
  Field,
  FullScreenImagePicker,
  Button,
  FileUploadWrapper,
  ConfirmDeleteInlineModal,
  DatePicker,
  Icon,
} from 'views/components/Shared/General';
import { Image, Loader } from 'views/components/Image';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ElapsedTime } from 'views/components/Shared/General';
import styles from './style.module.scss';
import RegisteredByContainer from './RegisteredByContainer';
import WorkOrderSpentTimeCategoryContainer from './WorkOrderSpentTimeCategoryContainer';

class SpentTimeModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    workOrderSpentTime: {},
    workOrderSpentTimeBeforeEdit: {},
    hasUnsavedChanges: false,
    editDate: false,
    editUser: false,
    showEditTariff: false,
    showDateError: false,
    showDatePicker: false,
    showMinutesRequiredError: false,
    deletingImageIds: {},
    uploadingCount: 0,
    currentImage: 0,
    showingImageDialog: false,
    images: [],
    showAdvancedSettings: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id) {
        this.setState({
          ...this.getInitialState(),
          images: this.props.images,
          workOrderSpentTime: this.props.workOrderSpentTime,
          workOrderSpentTimeBeforeEdit: this.props.workOrderSpentTime,
        });
      } else if (this.props.clientWorkOrderSpentTime) {
        const workOrderSpentTime = {
          ...this.props.clientWorkOrderSpentTime,
        };
        this.setState({
          ...this.getInitialState(),
          images: this.props.images,
          workOrderSpentTime,
          workOrderSpentTimeBeforeEdit: workOrderSpentTime,
        });
      } else {
        this.setState({
          ...this.getInitialState(),
          workOrderSpentTime: this.getNewWorkOrderSpentTime(),
          workOrderSpentTimeBeforeEdit: this.getNewWorkOrderSpentTime(),
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  getNewWorkOrderSpentTime = () => {
    const defaultWospCategory = find(this.props.workOrderSpentTimeCategories, { default: true });
    let laborCostPerHour = this.props.settings.labor_cost_per_hour;
    if (
      this.props.currentUserSettings.labor_cost_per_hour ||
      this.props.currentUserSettings.labor_cost_per_hour == '0'
    ) {
      laborCostPerHour = this.props.currentUserSettings.labor_cost_per_hour;
    }

    return {
      date: moment().format('YYYY-MM-DD'),
      cost_per_hour: laborCostPerHour,
      work_order_spent_time_category_id: defaultWospCategory == null ? null : defaultWospCategory.id,
      user_id: this.props.currentUser.id,
      minutes: null,
      comment: '',
      images: [],
    };
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  setWorkOrderSpentTimeValue = values => {
    const workOrderSpentTime = { ...this.state.workOrderSpentTime, ...values };

    const hasUnsavedChanges = !isEqual(this.state.workOrderSpentTimeBeforeEdit, workOrderSpentTime);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      workOrderSpentTime,
      hasUnsavedChanges,
    });
  };

  getEditingChanges = () => {
    let data = {};
    const workOrderSpentTime = this.state.workOrderSpentTime;
    const diffProps = Object.keys(workOrderSpentTime).filter(
      k => workOrderSpentTime[k] !== this.state.workOrderSpentTimeBeforeEdit[k]
    );
    diffProps.forEach(key => (data[key] = workOrderSpentTime[key]));
    return data;
  };

  updateWorkOrderSpentTime = () => {
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    const changes = this.getEditingChanges();
    this.props
      .updateWorkOrderSpentTime(this.props.workOrderSpentTime.id, changes)
      .then(({ data: workOrderSpentTime }) => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  createWorkOrderSpentTime = () => {
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    const workOrderSpentTimeWithImages = {
      ...this.state.workOrderSpentTime,
      images: this.state.images.map(({ id }) => id),
    };
    this.props
      .createWorkOrderSpentTime(this.props.match.params.id, workOrderSpentTimeWithImages)
      .then(({ data: workOrderSpentTime }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.work-order-spent-time-modal.create-success" />}
          />
        );
        this.props.onSaved(workOrderSpentTime);
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  delete = () => {
    if (this.props.workOrder) {
      this.setState({ isDeleting: true });
      this.props
        .deleteWorkOrderSpentTime(this.props.workOrderSpentTime.id)
        .then(() => {
          this.setState({ isDeleting: false });
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="components.work-order-spent-time-modal.delete-success" />}
            />
          );
          this.props.onClose();
        })
        .catch(e => {
          this.setState({ isDeleting: false });
        });
    } else {
      this.props.onDelete(this.state.workOrderSpentTime);
    }
  };

  isSaveable = () => {
    let isSavable = true;
    if (!this.state.workOrderSpentTime.date) {
      this.setState({ showDateError: true });
      isSavable = false;
    }
    if (!this.state.workOrderSpentTime.minutes) {
      this.setState({ showMinutesRequiredError: true });
      isSavable = false;
    }
    return isSavable;
  };

  save = () => {
    if (!this.isSaveable()) {
      return;
    }
    if (this.state.isSaving) return;
    if (this.props.workOrder) {
      if (this.props.id) {
        this.updateWorkOrderSpentTime();
      } else {
        this.createWorkOrderSpentTime();
      }
    } else {
      const workOrderSpentTimeWithImages = {
        ...this.state.workOrderSpentTime,
        minutes: getElapsedMinutesFromHourAndMinutes(
          this.state.workOrderSpentTime.hours,
          this.state.workOrderSpentTime.minutes
        ),
        images: this.state.images.map(({ id }) => id),
      };
      this.props.onSaved(workOrderSpentTimeWithImages);
    }
  };

  showWorkOrderSpentTimeCategories = () => {
    return (
      this.props.currentSystem.work_order_spent_time_category_activated &&
      this.props.workOrderSpentTimeCategories.length > 0
    );
  };

  addImageToWorkOrderSpentTime = ({ file, mime_type, name }) => {
    this.props
      .createImageForWorkOrderSpentTime(this.props.workOrderSpentTime.id, { image: file, mime_type, name })
      .then(({ data: image }) => {
        this.setState({
          uploadingCount: this.state.uploadingCount - 1,
          images: [...this.state.images, image],
        });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      });
  };

  addDraftImage = ({ file, mime_type, name }) => {
    this.props
      .createDraftImage(
        this.props.currentSystem.id,
        { image: file, mime_type, name },
        { namespace: 'workOrderSpentTime' }
      )
      .then(({ data: image }) => {
        this.setState({
          uploadingCount: this.state.uploadingCount - 1,
          images: [...this.state.images, image],
        });
      })
      .catch(() => {
        this.setState({ uploadingCount: this.state.uploadingCount - 1 });
      });
  };

  addImage = ({ file, mime_type, name }) => {
    this.setState({ uploadingCount: this.state.uploadingCount + 1 });
    if (this.props.workOrderSpentTime == null) {
      this.addDraftImage({ file, mime_type, name });
    } else {
      this.addImageToWorkOrderSpentTime({ file, mime_type, name });
    }
  };

  deleteImage = image => {
    this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: true } });
    this.props
      .deleteImage(image.id)
      .then(() => {
        this.setState({
          deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false },
          images: this.state.images.filter(({ id }) => id !== image.id),
        });
      })
      .catch(() => {
        this.setState({ deletingImageIds: { ...this.state.deletingImageIds, [image.id]: false } });
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderNewImage = () => {
    return (
      <div className={styles['add-button-container']}>
        <FileUploadWrapper
          image
          accept="image/png, image/jpg, image/jpeg, image/gif"
          onSelectFile={this.addImage}
        >
          <Button type="text" primary label="general.add" noUnderline />
        </FileUploadWrapper>
      </div>
    );
  };

  renderImages = () => {
    const loaderElements = [...Array(this.state.uploadingCount === 0 ? 0 : this.state.uploadingCount)].map(
      (_, index) => <Loader medium circle />
    );
    return (
      <>
        {this.state.images.map((image, index) => (
          <Image
            key={image.id}
            medium
            circle
            isDeleting={this.state.deletingImageIds[image.id] != null}
            image={image}
            editable
            onClick={() => {
              this.selectImage(0);
            }}
            onDelete={() => {
              this.deleteImage(image);
            }}
          />
        ))}
        {loaderElements}
      </>
    );
  };

  renderImagesContainer = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order-spent-time.images" />}>
        <div className={styles['images']}>{this.renderImages()}</div>
        {this.renderNewImage()}
      </Field>
    );
  };

  renderCostField = () => {
    const { canViewWorkOrderCosts, currentSystem, hasProTier } = this.props;
    if (canViewWorkOrderCosts && hasProTier && currentSystem.costs_activated) {
      return (
        <AnimateHeight duration={250} height={this.state.showAdvancedSettings ? 'auto' : 0}>
          <Grid.Row>
            <Grid.Column>
              <div className={styles['separator']} />
              <Field label={<FormattedMessage id="resources.work-order-spent-time.cost-per-hour" />}>
                <Field.Money
                  currency={this.props.currentSystem.currency}
                  value={this.state.workOrderSpentTime.cost_per_hour}
                  onBlur={cost_per_hour => {
                    this.setWorkOrderSpentTimeValue({ cost_per_hour });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
        </AnimateHeight>
      );
    }
    return null;
  };

  renderSuggestEstimatedTime = () => {
    const { workOrder, workOrderSpentTimes } = this.props;
    if (workOrder) {
      if (workOrder.estimated_minutes && (workOrderSpentTimes == null || workOrderSpentTimes.length == 0)) {
        return (
          <Button
            type="text"
            primary
            translate={false}
            onClick={() =>
              this.setWorkOrderSpentTimeValue({
                minutes: workOrder.estimated_minutes,
              })
            }
            fontSize={12}
            label={
              <FormattedMessage
                id="components.work-order-spent-time-modal.fetch-from-estimated-time"
                values={{ value: <ElapsedTime short elapsedMinutes={workOrder.estimated_minutes} /> }}
              />
            }
            noUnderline
          />
        );
      }
    }
    return null;
  };

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column md={this.showWorkOrderSpentTimeCategories() ? 6 : 12}>
            <RegisteredByContainer
              userId={this.state.workOrderSpentTime.user_id}
              vendorId={this.state.workOrderSpentTime.vendor_id}
              onChangeUser={user => {
                const { id, user_setting } = user;

                let laborCostPerHour = this.props.settings.labor_cost_per_hour;
                if (user_setting?.labor_cost_per_hour || user_setting?.labor_cost_per_hour == '0') {
                  laborCostPerHour = user_setting?.labor_cost_per_hour;
                }

                this.setWorkOrderSpentTimeValue({
                  external: false,
                  user_id: id,
                  vendor_id: null,
                  cost_per_hour: laborCostPerHour,
                });
              }}
              onChangeVendor={({ id, labor_cost_per_hour }) => {
                this.setWorkOrderSpentTimeValue({
                  external: true,
                  vendor_id: id,
                  user_id: null,
                  cost_per_hour: labor_cost_per_hour,
                });
              }}
            />
          </Grid.Column>
          {this.showWorkOrderSpentTimeCategories() ? (
            <Grid.Column md={6}>
              <WorkOrderSpentTimeCategoryContainer
                workOrderSpentTimeCategories={this.props.workOrderSpentTimeCategories}
                value={this.state.workOrderSpentTime.work_order_spent_time_category_id}
                onChange={work_order_spent_time_category_id =>
                  this.setWorkOrderSpentTimeValue({ work_order_spent_time_category_id })
                }
              />
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column md={6}>
            <Field label={<FormattedMessage id="resources.work-order-spent-time.date" />}>
              <Field.Date
                open={this.state.showDatePicker}
                error={this.state.showDateError}
                onClose={() => {
                  this.setState({ showDatePicker: false });
                }}
                onFocus={() => this.setState({ showDatePicker: true })}
                onChangeDate={date => {
                  if (date && this.state.showDateError) {
                    this.setState({ showDatePicker: false, showDateError: false });
                  } else {
                    this.setState({
                      showDatePicker: false,
                    });
                  }
                  this.setWorkOrderSpentTimeValue({ date });
                }}
                value={this.state.workOrderSpentTime.date}
                footerComponent={
                  <DatePicker.Footer
                    showClear={this.state.workOrderSpentTime.date != null}
                    onClear={() => {
                      this.setState({ showDatePicker: false });
                      this.setWorkOrderSpentTimeValue({ date: null });
                    }}
                  />
                }
              />
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field label={<FormattedMessage id="resources.work-order-spent-time.time" />}>
              <div className={styles['elapsed-time-container']}>
                <Field.Number
                  error={this.state.showMinutesRequiredError}
                  value={getHoursFromElapsedMinutes(this.state.workOrderSpentTime.minutes) || ''}
                  onChange={val => {
                    if (val && this.state.showMinutesRequiredError) {
                      this.setState({ showMinutesRequiredError: false });
                    }
                  }}
                  onBlur={hours => {
                    this.setWorkOrderSpentTimeValue({
                      minutes: getElapsedMinutesFromHourAndMinutes(
                        hours,
                        getMinutesFromElapsedMinutes(this.state.workOrderSpentTime.minutes)
                      ),
                    });
                  }}
                  rightLabel={<FormattedMessage id="screens.work-order.costs.time-cost-modal.hours-short" />}
                />
                <Field.Number
                  error={this.state.showMinutesRequiredError}
                  value={getMinutesFromElapsedMinutes(this.state.workOrderSpentTime.minutes) || ''}
                  onChange={val => {
                    if (val && this.state.showMinutesRequiredError) {
                      this.setState({ showMinutesRequiredError: false });
                    }
                  }}
                  onBlur={minutes => {
                    this.setWorkOrderSpentTimeValue({
                      minutes: getElapsedMinutesFromHourAndMinutes(
                        getHoursFromElapsedMinutes(this.state.workOrderSpentTime.minutes),
                        minutes
                      ),
                    });
                  }}
                  rightLabel={
                    <FormattedMessage id="screens.work-order.costs.time-cost-modal.minutes-short" />
                  }
                />
              </div>
              {this.renderSuggestEstimatedTime()}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.work-order-spent-time.comment" />}>
              <Field.Textarea
                value={this.state.workOrderSpentTime.comment}
                onChange={comment => this.setWorkOrderSpentTimeValue({ comment })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.renderImagesContainer()}</Grid.Column>
        </Grid.Row>
        {this.renderCostField()}
      </Grid>
    );
  };

  renderDeleteIcon = () => {
    if (this.props.workOrder) {
      if (this.props.id && this.props.hideDeleteIcon !== true) {
        return (
          <ConfirmDeleteInlineModal
            trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
            title={<FormattedMessage id="general.delete-confirm.title" />}
            subtitle={<FormattedMessage id="general.delete-confirm.subtitle" />}
            onDelete={this.delete}
            position="right"
            loading={this.state.isDeleting}
          />
        );
      }
    }
    if (this.props.clientWorkOrderSpentTime && this.props.clientWorkOrderSpentTime.id) {
      return (
        <ConfirmDeleteInlineModal
          trigger={<Button type="icon" icon={<Icon regular red type="trash-alt" />} />}
          title={<FormattedMessage id="general.delete-confirm.title" />}
          subtitle={<FormattedMessage id="general.delete-confirm.subtitle" />}
          onDelete={this.delete}
          position="right"
          loading={this.state.isDeleting}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={
            this.props.id ? (
              <FormattedMessage id="components.work-order-spent-time-modal.title-edit" />
            ) : (
              <FormattedMessage id="components.work-order-spent-time-modal.title-new" />
            )
          }
          iconButtons={this.renderDeleteIcon()}
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <div className={styles['button-group']}>
            <div>
              <Button primary loading={this.state.isSaving} onClick={this.save} label="general.save" />
            </div>
            <div>
              <Button label="general.cancel" onClick={this.closeModal} />
            </div>
            {this.state.showAdvancedSettings === false &&
            this.props.canViewWorkOrderCosts &&
            this.props.hasProTier &&
            this.props.currentSystem.costs_activated ? (
              <div className={styles['settings']}>
                <Button
                  primary
                  type="text"
                  noUnderline
                  label="components.work-order-spent-time-modal.settings"
                  onClick={() => this.setState({ showAdvancedSettings: true })}
                />
              </div>
            ) : null}
          </div>
        </Modal.Footer>
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.state.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createWorkOrderSpentTime: SDKReduxOperations.createWorkOrderSpentTime,
      updateWorkOrderSpentTime: SDKReduxOperations.updateWorkOrderSpentTime,
      deleteWorkOrderSpentTime: SDKReduxOperations.deleteWorkOrderSpentTime,
      createImageForWorkOrderSpentTime: SDKReduxOperations.createImageForWorkOrderSpentTime,
      createDraftImage: SDKReduxOperations.createDraftImage,
      deleteImage: SDKReduxOperations.deleteImage,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id, workOrderId, clientWorkOrderSpentTime } = ownProps;
  let workOrderSpentTime;
  let workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  if (ownProps.id) {
    workOrderSpentTime = EntitySelectors.getWorkOrderSpentTime(state, id);
  }
  let images = [];
  if (workOrderSpentTime) {
    images = EntitySelectors.getImages(state, workOrderSpentTime.images);
  }
  if (clientWorkOrderSpentTime) {
    images = EntitySelectors.getImages(state, clientWorkOrderSpentTime.images);
  }
  let workOrderSpentTimes = [];
  if (workOrder) {
    workOrderSpentTimes = EntitySelectors.getWorkOrderSpentTimes(state, workOrder.work_order_spent_times);
  }
  return {
    workOrderSpentTime,
    workOrderSpentTimes,
    workOrder,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    workOrderSpentTimeCategories: AuthSelectors.getWorkOrderSpentTimeCategories(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    hasProTier: AuthSelectors.hasProTier(state),
    images,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SpentTimeModal)));
