import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

const CostOtherCategoryContainer = ({ value, costOtherCategories, costOtherCategory, onChange }) => (
  <Field.Dropdown
    value={costOtherCategory == null ? null : costOtherCategory.title}
    onChange={cost_other_category_id => onChange(cost_other_category_id)}
    onClear={() => onChange(null)}
  >
    {costOtherCategories.map(({ title, id }) => (
      <Field.Dropdown.Item selected={value === id} onClick={() => onChange(id)}>
        {title}
      </Field.Dropdown.Item>
    ))}
  </Field.Dropdown>
);

function mapStateToProps(state, ownProps) {
  return {
    costOtherCategory: EntitySelectors.getCostOtherCategory(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(CostOtherCategoryContainer);
