import moment from 'moment-timezone';

export const WorkOrderActivityType = {
  Created: 'created',
  NotStarted: 'not_started',
  InProgress: 'in_progress',
  Paused: 'paused',
  Completed: 'completed',
  DueDateChanged: 'due_date_changed',
  AssigneeAdded: 'assignee_added',
  AssigneeRemoved: 'assignee_removed',
  VendorEmailSent: 'vendor_email_sent',
  DueMeterValueChanged: 'due_meter_value_changed',
};

const validTypes = [
  WorkOrderActivityType.Created,
  WorkOrderActivityType.DueDateChanged,
  WorkOrderActivityType.DueMeterValueChanged,
  WorkOrderActivityType.AssigneeAdded,
  WorkOrderActivityType.AssigneeRemoved,
  WorkOrderActivityType.VendorEmailSent,
  WorkOrderActivityType.NotStarted,
  WorkOrderActivityType.InProgress,
  WorkOrderActivityType.Paused,
  WorkOrderActivityType.Completed,
];
const assigneeAddedTypes = ['user_assignee_added', 'group_assignee_added', 'vendor_assignee_added'];
const assigneeRemovedTypes = ['user_assignee_removed', 'group_assignee_removed', 'vendor_assignee_removed'];

function groupAssigneeActivities(activities, activity, types) {
  const start = moment(activity.created_at);
  let i = 0;

  let groupedActivities = [];

  while (i < activities.length) {
    const checkActivity = activities[i];
    const end = moment(checkActivity.created_at);
    const diff = moment.duration(end.diff(start));
    const withinTenMinutes = diff.asMinutes() <= 10;
    const sameUser = activity.created_by_user_id === checkActivity.created_by_user_id;

    if (types.includes(checkActivity.type) && withinTenMinutes && sameUser) {
      groupedActivities = [...groupedActivities, checkActivity];

      i += 1;
    } else {
      break;
    }
  }

  return groupedActivities;
}
export function groupByDate(activities, system) {
  const days = {};

  let i = 0;

  let completionActivitiesToAddLast = [];

  while (i < activities.length) {
    const activity = activities[i];
    const date = moment(activity.created_at).tz(system.timezone).format('YYYY-MM-DD');

    if (activity.type === WorkOrderActivityType.Completed) {
      const { completed_date } = activity.data;

      if (completed_date === date) {
        days[date] = days[date] || [];
        days[date].push({
          type: activity.type,
          id: activity.id,
        });

        i += 1;
      } else {
        completionActivitiesToAddLast = [...completionActivitiesToAddLast, activity];

        i += 1;
      }
    } else if (assigneeAddedTypes.includes(activity.type)) {
      const remainingActivities = activities.slice(i);
      const assigneeActivities = groupAssigneeActivities(remainingActivities, activity, assigneeAddedTypes);

      days[date] = days[date] || [];
      days[date].push({
        type: 'assignee_added',
        ids: assigneeActivities.map(assigneeActivity => assigneeActivity.id),
      });

      i += assigneeActivities.length;
    } else if (assigneeRemovedTypes.includes(activity.type)) {
      const remainingActivities = activities.slice(i);
      const assigneeActivities = groupAssigneeActivities(remainingActivities, activity, assigneeRemovedTypes);

      days[date] = days[date] || [];
      days[date].push({
        type: 'assignee_removed',
        ids: assigneeActivities.map(assigneeActivity => assigneeActivity.id),
      });

      i += assigneeActivities.length;
    } else if (validTypes.includes(activity.type)) {
      days[date] = days[date] || [];
      days[date].push({
        type: activity.type,
        id: activity.id,
      });

      i += 1;
    } else {
      i += 1;
    }
  }

  completionActivitiesToAddLast.forEach(activity => {
    const { completed_date } = activity.data;

    days[completed_date] = days[completed_date] || [];
    days[completed_date].push({
      type: activity.type,
      id: activity.id,
    });
  });

  return days;
}
