import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import * as moment from 'moment-timezone';
import { RequestsOperations } from 'state/ducks/requests';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { NewWorkOrderOperations } from 'state/ducks/newWorkOrder';
import {
  FullScreenImagePicker,
  ViewTextArea,
  Button,
  InlineModal,
  NewInlineModal,
  ConfirmModal,
  Icon,
} from 'views/components/Shared/General';
import { TreePath, AssetTitle } from 'views/components/Asset';
import { Image } from 'views/components/Image';
import { Grid, ListLayout } from 'views/components/Shared/Layout';
import { ArchiveRequestModal, AssigneeDropdown } from 'views/components/Request';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { Field, SelectWorkOrderModal, NewWorkOrderModal } from 'views/components/WorkOrder';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { ExportType } from 'sdk/Export';
import { RequestStatus } from 'sdk/Request';
import AddConfirmModal from './AddConfirmModal';
import PrintModal from '../../List/PrintModal';
import Status from './Status';
import CreatedFromWorkOrder from './CreatedFromWorkOrder';
import RequestDowntime from './RequestDowntime';
import styles from './style.module.scss';

const changeAssigneeRequest = HelperFunctions.getCancelTokenForRequest();

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      showingImageDialog: false,
      showDeleteDropdown: false,
      showDropdownInlineModal: false,
      isResettingFromArchive: false,
      isDeletingRequest: false,
      showArchiveRequestModal: false,
      showSelectWorkOrderModal: false,
      showAddWorkOrderConfirm: false,
      showAddWorkOrderConfirmForWorkOrderId: null,
      isAddingExistingWorkOrderToRequest: false,
      showAssigneeDropdown: false,
      isAssigningRequestToUserId: null,
      isAssigningRequestToGroupId: null,
      isClearingAssignee: false,
      showNewWorkOrderModal: false,
      newWorkOrderParams: {},
      showPrintRequestModal: false,
      showConfirmSendRequestToMaintenanceTeamModal: false,
      isSendingRequestToMaintenanceTeam: false,
    };
  }

  delete = () => {
    this.setState({ isDeletingRequest: true });
    this.props
      .deleteRequest(this.props.request.id)
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.requests.delete-request-success" />} />
        );
        this.setState({ isDeletingRequest: false });
        const queryParams = queryString.parse(this.props.location.search);
        this.props.history.replace(
          `?${HelperFunctions.convertObjToQueryParameters({
            ...queryParams,
            ...{ request_id: null },
          })}`
        );
      })
      .catch(e => {
        this.setState({ isDeletingRequest: false });
        //TODO: Show general error message
      });
  };

  updateRequest = params => {
    changeAssigneeRequest.cancel();
    this.props
      .updateRequest(this.props.request.id, params, changeAssigneeRequest.getCancelTokenConfig())
      .then(() => {
        this.setState({
          showAssigneeDropdown: false,
          isAssigningRequestToUserId: null,
          isAssigningRequestToGroupId: null,
          isClearingAssignee: false,
          isSendingRequestToMaintenanceTeam: false,
          showConfirmSendRequestToMaintenanceTeamModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="screens.request.update-success" />} />);
      })
      .catch(e => {
        //TODO: Replace with generic error message
      });
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  createWorkOrder = () => {
    let downtimes = null;
    if (this.props.request.downtime_id) {
      downtimes = [this.props.request.downtime_id];
    }
    this.setState({
      showNewWorkOrderModal: true,
      newWorkOrderParams: {
        asset_id: this.props.request.asset_id,
        priority: this.props.request.priority,
        downtimes,
      },
    });
  };

  resetRequestFromArchive = () => {
    if (this.state.isResettingFromArchive) return;
    this.setState({ isResettingFromArchive: true });
    this.props
      .resetRequestFromArchive(this.props.request.id)
      .then(() => {
        this.setState({ isResettingFromArchive: false });
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.requests.reset-archive-success" />} />
        );
      })
      .catch(() => {
        this.setState({ isResettingFromArchive: false });
      });
  };

  addRequestToExistingWorkOrder = () => {
    if (this.state.isAddingExistingWorkOrderToRequest) return;
    this.setState({ isAddingExistingWorkOrderToRequest: true });
    this.props
      .updateRequest(this.props.request.id, {
        work_order_id: this.state.showAddWorkOrderConfirmForWorkOrderId,
      })
      .then(() => {
        this.setState({ isAddingExistingWorkOrderToRequest: false, showAddWorkOrderConfirm: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.requests.connect-existing-work-order-success" />}
          />
        );
      })
      .catch(() => {
        this.setState({ isAddingExistingWorkOrderToRequest: false, showAddWorkOrderConfirm: false });
      });
  };

  renderDescription = () => {
    if (this.props.request.description) {
      return (
        <div className={styles['description']}>
          <ViewTextArea>{this.props.request.description}</ViewTextArea>
        </div>
      );
    }
  };

  renderDescriptionAndPriority = () => {
    if (this.props.request.description == null && this.props.request.priority == null) {
      return null;
    }
    return (
      <>
        {this.renderDescription()}
        {this.renderPriority()}
        <div className={styles['separator-short']} />
      </>
    );
  };

  renderImage = (image, index) => (
    <Image
      circle
      key={image.id}
      medium
      editable={false}
      image={image}
      onClick={() => {
        this.selectImage(index);
      }}
    />
  );

  renderPriority = () => {
    switch (this.props.request.priority) {
      case 'low':
        return (
          <>
            <div className={`${styles['priority']} ${styles['low']}`}>
              <FormattedMessage id="resources.request.prio.low" />
            </div>
          </>
        );
      case 'medium':
        return (
          <>
            <div className={`${styles['priority']} ${styles['medium']}`}>
              <FormattedMessage id="resources.request.prio.medium" />
            </div>
          </>
        );
      case 'high':
        return (
          <>
            <div className={`${styles['priority']} ${styles['high']}`}>
              <FormattedMessage id="resources.request.prio.high" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  renderAsset = () => {
    if (this.props.asset == null) {
      return null;
    }
    if (this.props.canViewAssets) {
      return (
        <Grid.Column md={6}>
          <Field
            icon={<Icon type="box-alt" withBackground backgroundSize={36} />}
            label={<FormattedMessage id="resources.request.asset" />}
            value={
              <>
                <Link to={`/assets/${this.props.asset.id}`}>
                  <AssetTitle id={this.props.asset.id} />
                </Link>
                {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
                  <div className={styles['tree-path-container']}>
                    <TreePath assetId={this.props.asset.id} />
                  </div>
                )}
              </>
            }
          />
        </Grid.Column>
      );
    }
    return (
      <Grid.Column md={6}>
        <Field
          icon={<Icon type="box-alt" withBackground backgroundSize={36} />}
          label={<FormattedMessage id="resources.request.asset" />}
          value={
            <>
              <AssetTitle id={this.props.asset.id} />
              {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
                <div className={styles['tree-path-container']}>
                  <TreePath assetId={this.props.asset.id} />
                </div>
              )}
            </>
          }
        />
      </Grid.Column>
    );
  };

  renderRequestType = () => {
    if (this.props.requestType) {
      return (
        <Grid.Column md={6}>
          <Field
            icon={<Icon type="tag" withBackground backgroundSize={36} />}
            label={<FormattedMessage id="resources.request.request-type" />}
            value={this.props.requestType.title}
          />
        </Grid.Column>
      );
    }
    return null;
  };

  renderApprovedByUser = () => {
    const { production_supervisor_approval_status } = this.props.request;
    const { request_production_supervisor_approval_activated } = this.props.settings;
    if (
      request_production_supervisor_approval_activated &&
      production_supervisor_approval_status === 'approved'
    ) {
      return (
        <div className={styles['approved-by-user-container']}>
          <FormattedMessage
            id="screens.request.approved-by-user"
            values={{
              at: moment(this.props.request.approved_by_production_supervisor_at).format('LLL'),
              user: <UserNameWrapper user={this.props.approvedByUser} />,
            }}
          />
        </div>
      );
    }
  };

  renderFieldData = () => (
    <div className={styles['field-data-container']}>
      <Grid>
        <Grid.Row>
          <Grid.Column md={6}>
            <Field
              icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
              label={<FormattedMessage id="resources.request.created-at" />}
              value={moment(this.props.request.created_at).format('LLL')}
            />
          </Grid.Column>
          <Grid.Column md={6}>
            <Field
              icon={
                <ProfilePicture
                  userId={this.props.request.created_by_user_id}
                  size={34}
                  defaultElement={<Icon type="user" withBackground blue backgroundSize={36} />}
                />
              }
              label={<FormattedMessage id="resources.request.created-by" />}
              value={<UserNameWrapper user={this.props.createdByUser} />}
            />
          </Grid.Column>
        </Grid.Row>
        {this.props.asset == null && this.props.requestType == null ? null : (
          <Grid.Row>
            {this.renderAsset()}
            {this.renderRequestType()}
          </Grid.Row>
        )}
      </Grid>
    </div>
  );

  renderImages = () => {
    if (this.props.images == null) {
      return (
        <>
          <div className={styles['images']}>
            <Image medium loading />
            <Image medium loading />
            <Image medium loading />
          </div>
        </>
      );
    }

    if (this.props.images.length === 0) return null;
    return (
      <>
        <div className={styles['separator-short']} />
        <div className={styles['images']}>
          {this.props.images.map((image, index) => this.renderImage(image, index))}
        </div>
      </>
    );
  };

  renderAssigneeDropdown = () => {
    const { request_allow_skip_assignee } = this.props.settings;
    return (
      <AssigneeDropdown
        positionToRef={this.assigneeDropdownInlineModalPositioningRef}
        open={this.state.showAssigneeDropdown}
        userId={this.props.request.assigned_to_user_id}
        groupId={this.props.request.assigned_to_group_id}
        isLoadingUserId={this.state.isAssigningRequestToUserId}
        isLoadingGroupId={this.state.isAssigningRequestToGroupId}
        isClearing={this.state.isClearingAssignee}
        clearable={request_allow_skip_assignee === true}
        onClose={() => this.setState({ showAssigneeDropdown: false })}
        onSelectUser={id => {
          this.setState({
            isAssigningRequestToUserId: id,
            isAssigningRequestToGroupId: null,
            isClearingAssignee: false,
          });
          this.updateRequest({ assigned_to_user_id: id });
        }}
        onSelectGroup={id => {
          this.setState({
            isAssigningRequestToGroupId: id,
            isAssigningRequestToUserId: null,
            isClearingAssignee: false,
          });
          this.updateRequest({ assigned_to_group_id: id });
        }}
        onClear={() => {
          this.setState({
            isClearingAssignee: true,
            isAssigningRequestToGroupId: null,
            isAssigningRequestToUserId: null,
          });
          this.updateRequest({ assigned_to_group_id: null, assigned_to_user_id: null });
        }}
      />
    );
  };

  renderAssignee = () => {
    if (this.props.assignedToUser) {
      return (
        <>
          <div
            className={styles['assignee']}
            ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showAssigneeDropdown: !prevState.showAssigneeDropdown,
              }));
            }}
          >
            <div className={styles['icon-container']}>
              <ProfilePicture
                userId={this.props.assignedToUser.id}
                size={26}
                defaultElement={<Icon type="user" withBackground blue backgroundSize={26} size={12} />}
              />
            </div>
            <div className={styles['field']}>
              <div className={styles['label']}>
                <FormattedMessage id="resources.request.assignee" />
              </div>
              <div className={styles['value-container']}>
                <div className={styles['value']}>
                  <UserNameWrapper user={this.props.assignedToUser} />
                </div>
                <div className={styles['dropdown-icon']}>
                  <Icon type="caret-down" />
                </div>
              </div>
            </div>
          </div>
          {this.renderAssigneeDropdown()}
        </>
      );
    }
    if (this.props.assignedToGroup) {
      return (
        <>
          <div
            className={styles['assignee']}
            ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showAssigneeDropdown: !prevState.showAssigneeDropdown,
              }));
            }}
          >
            <div className={styles['icon-container']}>
              <Icon type="users" withBackground backgroundSize={26} blue size={12} />
            </div>
            <div className={styles['field']}>
              <div className={styles['label']}>
                <FormattedMessage id="resources.request.assignee" />
              </div>
              <div className={styles['value-container']}>
                <div className={styles['value']}>{this.props.assignedToGroup.title}</div>
                <div className={styles['dropdown-icon']}>
                  <Icon type="caret-down" />
                </div>
              </div>
            </div>
          </div>
          {this.renderAssigneeDropdown()}
        </>
      );
    }
    return (
      <>
        <div
          className={styles['assignee']}
          ref={ref => (this.assigneeDropdownInlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showAssigneeDropdown: !prevState.showAssigneeDropdown,
            }));
          }}
        >
          <div className={styles['icon-container']}>
            <Icon type="user" withBackground backgroundSize={26} blue size={12} />
          </div>
          <div className={styles['field']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.request.assignee" />
            </div>
            <div className={styles['value-container']}>
              <div className={styles['value']}>
                <FormattedMessage id="screens.requests.no-assignee" />
              </div>
              <div className={styles['dropdown-icon']}>
                <Icon type="caret-down" />
              </div>
            </div>
          </div>
        </div>
        {this.renderAssigneeDropdown()}
      </>
    );
  };

  renderAdministrateTopBar = () => {
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <div className={styles['top-bar']}>
          {this.renderTopBarAssignee()}
          {this.renderTopBarButtonsForAdministrator()}
        </div>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderProductionSupervisorTopBar = () => {
    if (this.props.request.status === RequestStatus.AwaitingApproval) {
      return (
        <ListLayout.Content.MainContent.FilterBar>
          <div className={styles['top-bar']}>{this.renderTopBarButtonsForProductionSupervisor()}</div>
        </ListLayout.Content.MainContent.FilterBar>
      );
    }
    return null;
  };

  renderTopBar = () => {
    const { canAdministrateRequests, isProductionSupervisor } = this.props;
    const { request_production_supervisor_approval_activated } = this.props.settings;
    const { has_asset_production_supervisors } = this.props.currentUserSettings;
    const isProdSupervisorAndHasProdAssets = isProductionSupervisor && has_asset_production_supervisors;
    if (canAdministrateRequests) {
      return this.renderAdministrateTopBar();
    }
    if (request_production_supervisor_approval_activated && isProdSupervisorAndHasProdAssets) {
      return this.renderProductionSupervisorTopBar();
    }
    return null;
  };

  renderTopBarAssignee = () => {
    const { request_multiple_assignees_active } = this.props.settings;
    if (request_multiple_assignees_active === false) {
      return null;
    }
    if (this.props.hasProTier === false) {
      return null;
    }
    return <div className={styles['assignee-container']}>{this.renderAssignee()}</div>;
  };

  renderTopBarButtonsForProductionSupervisor = () => {
    if (this.props.request.status === RequestStatus.AwaitingApproval) {
      return (
        <div className={styles['options']}>
          <Button.Group>
            <Button
              primary
              small
              label="screens.requests.actions.send-to-maintenance-team"
              onClick={() => this.setState({ showConfirmSendRequestToMaintenanceTeamModal: true })}
            />
            <div ref={ref => (this.moreOptionsInlineModalPositioningRef = ref)}>
              <Button
                small
                label="general.more-options"
                onClick={() => {
                  this.setState(prevState => ({
                    showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
                  }));
                }}
              />
            </div>
            <NewInlineModal
              open={this.state.showMoreOptionsDropdown}
              minWidth={250}
              positionToRef={this.moreOptionsInlineModalPositioningRef}
              onClose={() => this.setState({ showMoreOptionsDropdown: false })}
              position="right"
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({ showArchiveRequestModal: true, showMoreOptionsDropdown: false });
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.archive" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </Button.Group>
        </div>
      );
    }
    return null;
  };

  renderTopBarButtonsForAdministrator = () => {
    if (this.props.request.status === RequestStatus.AwaitingApproval) {
      return (
        <div className={styles['options']}>
          <Button.Group>
            <Button
              primary
              small
              label="screens.requests.actions.send-to-maintenance-team"
              onClick={() => this.setState({ showConfirmSendRequestToMaintenanceTeamModal: true })}
            />
            <div ref={ref => (this.moreOptionsInlineModalPositioningRef = ref)}>
              <Button
                small
                label="general.more-options"
                onClick={() => {
                  this.setState(prevState => ({
                    showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
                  }));
                }}
              />
            </div>
            <NewInlineModal
              minWidth={250}
              open={this.state.showMoreOptionsDropdown}
              positionToRef={this.moreOptionsInlineModalPositioningRef}
              onClose={() => this.setState({ showMoreOptionsDropdown: false })}
              position="right"
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showMoreOptionsDropdown: false });
                      this.createWorkOrder();
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.create-work-order" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showSelectWorkOrderModal: true, showMoreOptionsDropdown: false });
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.connect-to-existing-work-order" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({ showArchiveRequestModal: true, showMoreOptionsDropdown: false });
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.archive" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </Button.Group>
        </div>
      );
    }
    if (this.props.request.status === RequestStatus.Active) {
      return (
        <div className={styles['options']}>
          <Button.Group>
            <Button
              primary
              small
              label="screens.requests.actions.create-work-order"
              onClick={this.createWorkOrder}
            />
            <div ref={ref => (this.moreOptionsInlineModalPositioningRef = ref)}>
              <Button
                small
                label="general.more-options"
                onClick={() => {
                  this.setState(prevState => ({
                    showMoreOptionsDropdown: !prevState.showMoreOptionsDropdown,
                  }));
                }}
              />
            </div>
            <NewInlineModal
              minWidth={250}
              open={this.state.showMoreOptionsDropdown}
              positionToRef={this.moreOptionsInlineModalPositioningRef}
              onClose={() => this.setState({ showMoreOptionsDropdown: false })}
              position="right"
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({ showSelectWorkOrderModal: true, showMoreOptionsDropdown: false });
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.connect-to-existing-work-order" />
                  </NewInlineModal.Dropdown.Item>
                  <NewInlineModal.Dropdown.Item
                    destructive
                    onClick={() => {
                      this.setState({ showArchiveRequestModal: true, showMoreOptionsDropdown: false });
                    }}
                  >
                    <FormattedMessage id="screens.requests.actions.archive" />
                  </NewInlineModal.Dropdown.Item>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </Button.Group>
        </div>
      );
    }
    if (this.props.request.status === RequestStatus.Archived) {
      return (
        <div className={styles['options']}>
          <Button
            primary
            small
            loading={this.state.isResettingFromArchive}
            label="screens.requests.actions.resume"
            onClick={this.resetRequestFromArchive}
          />
        </div>
      );
    }
    return null;
  };

  renderInlineModalEditOption = () => {
    const { createdByUser, canCreateRequests, canAdministrateRequests, currentUser, isProductionSupervisor } =
      this.props;
    const createdByCurrentUser = createdByUser && createdByUser.id === currentUser.id;
    if (canAdministrateRequests || isProductionSupervisor || (canCreateRequests && createdByCurrentUser)) {
      return (
        <InlineModal.ListItem
          icon="pen"
          iconThickness="regular"
          title={<FormattedMessage id="general.edit" />}
          onClick={() => {
            this.setState({ showDropdownInlineModal: false });
            this.props.setRequestForEdit(this.props.request);
          }}
        />
      );
    }
    return null;
  };

  renderInlineModalDeleteOption = () => {
    const { createdByUser, canCreateRequests, canAdministrateRequests, currentUser, isProductionSupervisor } =
      this.props;
    const createdByCurrentUser = createdByUser && createdByUser.id === currentUser.id;
    if (canAdministrateRequests || isProductionSupervisor || (canCreateRequests && createdByCurrentUser)) {
      return (
        <>
          <InlineModal.Separator />
          <InlineModal.ListItem
            icon="trash-alt"
            iconThickness="regular"
            iconColor="#C55050"
            destructive
            title={<FormattedMessage id="general.delete" />}
            onClick={() => {
              this.setState({ showDeleteDropdown: true });
            }}
          />
        </>
      );
    }
    return null;
  };

  renderInlineModalContent = () => {
    if (this.state.showDeleteDropdown) {
      return (
        <>
          <InlineModal.Header
            width={300}
            title={<FormattedMessage id="screens.requests.delete-inline-modal.title" />}
            onClose={() => {
              this.setState({ showDropdownInlineModal: false });
            }}
          />
          <InlineModal.Body width={300}>
            <InlineModal.Delete
              loading={this.state.isDeletingRequest}
              onDelete={() => {
                this.delete();
              }}
            >
              <FormattedMessage id="screens.requests.delete-inline-modal.subtitle" />
            </InlineModal.Delete>
          </InlineModal.Body>
        </>
      );
    }

    return (
      <InlineModal.Body width={250} dropdown>
        {this.renderInlineModalEditOption()}
        <InlineModal.ListItem
          icon="print"
          iconThickness="regular"
          title={<FormattedMessage id="screens.work-order.print" />}
          onClick={() => {
            this.setState({ showDropdownInlineModal: false, showPrintRequestModal: true });
          }}
        />
        {this.renderInlineModalDeleteOption()}
      </InlineModal.Body>
    );
  };

  renderButtons = () => {
    return (
      <div className={styles['buttons']}>
        <div className={styles['button']}>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                showDropdownInlineModal: !prevState.showDropdownInlineModal,
                showDeleteDropdown: false,
              }));
            }}
          >
            <Button type="icon" icon={<Icon light size={24} type="ellipsis-h" />} />
          </div>
          <InlineModal
            open={this.state.showDropdownInlineModal}
            positionToRef={this.inlineModalPositioningRef}
            onClose={() => this.setState({ showDropdownInlineModal: false })}
            position="right"
          >
            {this.renderInlineModalContent()}
          </InlineModal>
        </div>
        <div className={styles['button']}>
          {this.props.showClose ? (
            <Button
              type="icon"
              icon={<Icon light size={20} type="times" />}
              onClick={() => {
                this.props.history.push(
                  `?${HelperFunctions.convertObjToQueryParameters({
                    ...queryString.parse(this.props.location.search),
                    ...{ request_id: null },
                  })}`
                );
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  renderAssetTreePathSubtitle = () => {
    if (this.props.asset == null) {
      return null;
    }
    if (this.props.canViewAssets) {
      return (
        <TreePath
          assetId={this.props.request.asset_id}
          renderItem={asset => {
            return (
              <Link to={`/assets/${asset.id}`}>
                <span className={styles['path-item']}>{asset.title}</span>
              </Link>
            );
          }}
          fullPath
        />
      );
    }
    return (
      <TreePath
        assetId={this.props.request.asset_id}
        renderItem={asset => {
          return <span>{asset.title}</span>;
        }}
        fullPath
      />
    );
  };

  renderRequest = () => {
    return (
      <>
        <div className={styles['detail']}>
          <Status request={this.props.request} />
          <div className={styles['white-card']}>
            <div className={styles['header']}>
              <div className={styles['title-wrapper']}>
                <div className={styles['title-container']}>
                  <div className={styles['title']}>
                    <span className={styles['title-text']}>{this.props.request.title}</span>
                    <span className={styles['number']}>&nbsp;-&nbsp;#{this.props.request.number}</span>
                  </div>
                  <div>{this.renderButtons()}</div>
                </div>
                {this.renderAssetTreePathSubtitle()}
              </div>
            </div>
            {this.renderDescriptionAndPriority()}
            {this.renderFieldData()}
            {this.renderImages()}
            {this.renderApprovedByUser()}
          </div>
          <RequestDowntime request={this.props.request} />
          <CreatedFromWorkOrder request={this.props.request} />
        </div>
      </>
    );
  };

  renderConfirmSendToMaintenanceTeamModal = () => (
    <ConfirmModal
      open={this.state.showConfirmSendRequestToMaintenanceTeamModal}
      title={<FormattedMessage id="screens.requests.confirm-send-to-maintenance-team-modal.title" />}
      message={<FormattedMessage id="screens.requests.confirm-send-to-maintenance-team-modal.subtitle" />}
      confirmButtonText="general.send"
      confirmIsLoading={this.state.isSendingRequestToMaintenanceTeam}
      onConfirm={() => {
        if (this.state.isSendingRequestToMaintenanceTeam) {
          return;
        }
        this.setState({ isSendingRequestToMaintenanceTeam: true });
        this.updateRequest({ production_supervisor_approval_status: 'approved' });
      }}
      onCancel={() => {
        this.setState({ showConfirmSendRequestToMaintenanceTeamModal: false });
      }}
    />
  );

  render() {
    return (
      <>
        <ListLayout.Content.MainContent>
          {this.renderTopBar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderRequest()}
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>

        <NewWorkOrderModal
          open={this.state.showNewWorkOrderModal}
          forRequestId={this.props.request.id}
          defaultParams={this.state.newWorkOrderParams}
          onClose={() => {
            this.setState({ showNewWorkOrderModal: false });
          }}
          onCreated={workOrder => {
            this.setState({ showNewWorkOrderModal: false });
          }}
        />
        <ArchiveRequestModal
          open={this.state.showArchiveRequestModal}
          id={this.props.request.id}
          onClose={() => {
            this.setState({ showArchiveRequestModal: false });
          }}
        />
        <SelectWorkOrderModal
          open={this.state.showSelectWorkOrderModal}
          onSelect={id => {
            this.setState({
              showAddWorkOrderConfirm: true,
              showAddWorkOrderConfirmForWorkOrderId: id,
              showSelectWorkOrderModal: false,
            });
          }}
          onClose={() => {
            this.setState({ showSelectWorkOrderModal: false });
          }}
        />
        <AddConfirmModal
          workOrderId={this.state.showAddWorkOrderConfirmForWorkOrderId}
          open={this.state.showAddWorkOrderConfirm}
          isSaving={this.state.isAddingExistingWorkOrderToRequest}
          onSave={() => {
            this.addRequestToExistingWorkOrder();
          }}
          onClose={() => {
            this.setState({ showAddWorkOrderConfirm: false });
          }}
        />
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={this.props.images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
        <PrintModal
          open={this.state.showPrintRequestModal}
          singleRequestId={this.props.request.id}
          exportType={ExportType.RequestsDetailPdf}
          onClose={() => this.setState({ showPrintRequestModal: false })}
        />
        {this.renderConfirmSendToMaintenanceTeamModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteRequest: SDKReduxOperations.deleteRequest,
      setRequestForEdit: RequestsOperations.setRequestForEdit,
      prepareNewWorkOrder: NewWorkOrderOperations.prepareNewWorkOrder,
      resetRequestFromArchive: SDKReduxOperations.resetRequestFromArchive,
      updateRequest: SDKReduxOperations.updateRequest,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    requestType: EntitySelectors.getRequestType(state, ownProps.request.request_type_id),
    asset: EntitySelectors.getAsset(state, ownProps.request.asset_id),
    createdByUser: EntitySelectors.getUser(state, ownProps.request.created_by_user_id),
    approvedByUser: EntitySelectors.getUser(
      state,
      ownProps.request.approved_by_production_supervisor_user_id
    ),
    assignedToUser: EntitySelectors.getUser(state, ownProps.request.assigned_to_user_id),
    assignedToGroup: EntitySelectors.getGroup(state, ownProps.request.assigned_to_group_id),
    workOrder: EntitySelectors.getWorkOrder(state, ownProps.request.work_order_id),
    images: EntitySelectors.getImages(state, ownProps.request.images),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    canCreateRequests: AuthSelectors.canCreateRequests(state),
    isOperator: AuthSelectors.isOperator(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    canViewAssets: AuthSelectors.canViewAssets(state),
    settings: AuthSelectors.getSettings(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(View)));
