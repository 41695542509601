import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import {
  Button,
  ConfirmModal,
  SectionHeader,
  Loader,
  Icon,
  NewInlineModal,
} from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { ExportLoader, LegacyPrintQrCodeModal } from 'views/components/General';
import { EntitySelectors } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import EditQrTemplateModal from './EditQrTemplateModal';
import QrCodeTemplateListItem from './QrCodeTemplateListItem';
import { normalizeQrTemplate } from 'sdk/Schemas';
import QrTemplateEditor from './QrTemplateEditor';
import NewQrTemplateModal from './NewQrTemplateModal';

class PrintQRCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  getInititalState = () => ({
    open: false,
    previewQrTemplateId: null,
    isFetchingTemplates: false,
    qrTemplateIds: [],
    showEditQrTemplateModal: false,
    showEditQrTemplateModalForQrTemplateId: null,
    showConfirmDeleteQrTemplateModal: false,
    showConfirmDeleteQrTemplateModalForQrTemplateId: null,
    isDeletingQrTemplate: false,
    showExportLoaderForId: null,
    isFetchingQrTemplate: false,
    showConfirmSetAsDefaultModal: false,
    showConfirmSetAsDefaultModalForQrTemplateId: null,
    showConfirmRemoveAsDefaultModal: false,
    showConfirmRemoveAsDefaultModalForQrTemplateId: null,
    dropdownOpen: false,
    showLegacyPrintQrCodeModal: false,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInititalState(), open: true });
      this.fetchQrCodeTemplates();
    } else if (prevProps.open && !this.props.open) {
      this.setState({ open: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.open && !nextProps.open && !this.state.open && !nextState.open) return false;
    return true;
  }

  fetchQrCodeTemplates = () => {
    this.setState({ isFetchingTemplates: true });
    API.listQRTemplates(this.props.currentSystem.id, {
      template_type: this.props.templateType === 'sparePart' ? 'spare_part' : 'asset',
    }).then(({ data: qrTemplates }) => {
      const { entities, result } = normalizeQrTemplate(qrTemplates);
      this.props.updateEntities(entities);
      let previewQrTemplateId = qrTemplates.find(qrTemplate => qrTemplate.default === true)?.id;
      if (previewQrTemplateId == null && qrTemplates.length > 0) {
        previewQrTemplateId = qrTemplates[0].id;
      }
      this.setState({
        isFetchingTemplates: false,
        qrTemplateIds: result.sort((a, b) => {
          return entities.qrTemplateById[a].default ? -1 : 1;
        }),
        previewQrTemplateId,
      });
    });
  };

  getParamsForPrintQrCode = () => {
    let params = {
      type: this.props.templateType === 'asset' ? ExportType.AssetsQRLabel : ExportType.SparePartsQRLabel,
      params: {
        new: true,
        qr_template_id: this.state.previewQrTemplateId,
      },
    };
    if (this.props.assetId) {
      params = {
        ...params,
        params: {
          ...params.params,
          id: this.props.assetId,
        },
      };
    } else if (this.props.sparePartId) {
      params = {
        ...params,
        params: {
          ...params.params,
          id: this.props.sparePartId,
        },
      };
    } else {
      params = {
        ...params,
        params: {
          ...params.params,
          ...this.props.params,
        },
      };
    }
    return params;
  };

  printQrCode = () => {
    API.createExport(this.props.currentSystem.id, this.getParamsForPrintQrCode()).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderQrTemplates = () => {
    if (this.state.isFetchingTemplates) {
      return (
        <>
          <QrCodeTemplateListItem loading key={1} />
          <QrCodeTemplateListItem loading key={2} />
        </>
      );
    }
    if (this.state.qrTemplateIds.length === 0) {
      return null;
    }
    return this.state.qrTemplateIds.map(id => (
      <QrCodeTemplateListItem
        id={id}
        key={id}
        selected={this.state.previewQrTemplateId === id}
        onPreview={() => {
          this.setState({
            previewQrTemplateId: id,
          });
        }}
        onSetAsDefault={id => {
          this.setState({
            showConfirmSetAsDefaultModal: true,
            showConfirmSetAsDefaultModalForQrTemplateId: id,
          });
        }}
        onRemoveAsDefault={id => {
          this.setState({
            showConfirmRemoveAsDefaultModal: true,
            showConfirmRemoveAsDefaultModalForQrTemplateId: id,
          });
        }}
        onEdit={() => {
          this.setState({ open: false });
          setTimeout(() => {
            this.setState({ showEditQrTemplateModal: true, showEditQrTemplateModalForQrTemplateId: id });
          }, 250);
        }}
        onDelete={() => {
          this.setState({
            showConfirmDeleteQrTemplateModal: true,
            showConfirmDeleteQrTemplateModalForQrTemplateId: id,
          });
        }}
      />
    ));
  };

  renderQrTemplateEditor = () => {
    if (this.state.isFetchingQrTemplate) {
      return <Loader centerInParent small />;
    }
    if (this.state.previewQrTemplateId) {
      return <QrTemplateEditor preview qrTemplateId={this.state.previewQrTemplateId} />;
    }
    return null;
  };

  renderAddButtonContainer = () => {
    if (this.props.templateType === 'asset' && this.props.canEditAssets === false) {
      return null;
    }
    if (this.props.templateType === 'sparePart' && this.props.canEditSpareParts === false) {
      return null;
    }
    return (
      <div className={styles['add-button-container']}>
        <Button
          gray
          loading={this.state.isAddingColumn}
          label="components.print-qr-codes.create-new-template"
          onClick={() => {
            this.setState({ open: false });
            setTimeout(() => {
              this.setState({ showTitleModal: true });
            }, 250);
          }}
        />
      </div>
    );
  };

  renderDownloadPdfButton = () => {
    if (
      this.props.templateType === 'sparePart' &&
      this.props.amountOfQrCodes >= maxQuantityAllowedForType(ExportType.SparePartsQRLabel)
    ) {
      return (
        <span className={styles['quantity-exceeded']}>
          <FormattedMessage id="components.print-qr-codes.quantity-exceeded" />
        </span>
      );
    }
    if (
      this.props.templateType === 'asset' &&
      this.props.amountOfQrCodes >= maxQuantityAllowedForType(ExportType.AssetsQRLabel)
    ) {
      return (
        <span className={styles['quantity-exceeded']}>
          <FormattedMessage id="components.print-qr-codes.quantity-exceeded" />
        </span>
      );
    }
    return (
      <Button
        primary
        fullWidth
        disabled={this.state.previewQrTemplateId == null}
        loading={this.state.isExporting}
        label="components.print-qr-codes.download-pdf"
        onClick={() => this.printQrCode()}
      />
    );
  };

  renderRightViewContent = () => {
    if (this.state.showExportLoaderForId) {
      return (
        <div className={styles['export-loader-container']}>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </div>
      );
    }
    return (
      <>
        <div className={styles['qr-templates-list-container']}>
          <PerfectScrollbar>
            <div className={styles['header']}>
              <SectionHeader noBorderTop>
                <FormattedMessage id="components.print-qr-codes.select-template" />
              </SectionHeader>
            </div>
            <div className={styles['content']}>
              {this.renderQrTemplates()}
              {this.renderAddButtonContainer()}
            </div>
          </PerfectScrollbar>
        </div>

        <div className={styles['button-container']}>{this.renderDownloadPdfButton()}</div>
      </>
    );
  };

  renderContent = () => {
    return (
      <div className={styles['container']}>
        <div className={styles['left-view']}>{this.renderQrTemplateEditor()}</div>
        <div className={styles['right-view']}>{this.renderRightViewContent()}</div>
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.sparePartId || this.props.assetId) {
      return <FormattedMessage id="components.print-qr-codes.title-single" />;
    }
    return <FormattedMessage id="components.print-qr-codes.title" />;
  };

  renderSubtitle = () => {
    if (this.props.templateType === 'sparePart') {
      if (this.props.sparePartId) {
        return <Title sparePart={this.props.sparePart} />;
      } else {
        const amount = this.props.amountOfQrCodes;

        return (
          <FormattedPlural
            value={this.props.amountOfQrCodes}
            zero={<FormattedMessage id="components.print-qr-codes.subtitle-spare-part.zero" />}
            one={<FormattedMessage id="components.print-qr-codes.subtitle-spare-part.one" />}
            two={
              <FormattedMessage id="components.print-qr-codes.subtitle-spare-part.two" values={{ amount }} />
            }
            few={
              <FormattedMessage id="components.print-qr-codes.subtitle-spare-part.few" values={{ amount }} />
            }
            many={
              <FormattedMessage id="components.print-qr-codes.subtitle-spare-part.many" values={{ amount }} />
            }
            other={
              <FormattedMessage
                id="components.print-qr-codes.subtitle-spare-part.other"
                values={{ amount }}
              />
            }
          />
        );
      }
    } else if (this.props.templateType === 'asset') {
      if (this.props.assetId) {
        return this.props.asset.title;
      } else {
        const amount = this.props.amountOfQrCodes;

        return (
          <FormattedPlural
            value={this.props.amountOfQrCodes}
            zero={<FormattedMessage id="components.print-qr-codes.subtitle-asset.zero" />}
            one={<FormattedMessage id="components.print-qr-codes.subtitle-asset.one" />}
            two={<FormattedMessage id="components.print-qr-codes.subtitle-asset.two" values={{ amount }} />}
            few={<FormattedMessage id="components.print-qr-codes.subtitle-asset.few" values={{ amount }} />}
            many={<FormattedMessage id="components.print-qr-codes.subtitle-asset.many" values={{ amount }} />}
            other={
              <FormattedMessage id="components.print-qr-codes.subtitle-asset.other" values={{ amount }} />
            }
          />
        );
      }
    }
    return null;
  };

  fetchEditedQrTemplate = () => {
    this.setState({ isFetchingQrTemplate: true });
    API.fetchQrTemplate(this.state.showEditQrTemplateModalForQrTemplateId).then(({ data }) => {
      const { entities } = normalizeQrTemplate(data);
      this.props.updateEntities(entities);
      this.setState({ isFetchingQrTemplate: false });
    });
  };

  renderEditQrTemplateModal = () => {
    return (
      <EditQrTemplateModal
        open={this.state.showEditQrTemplateModal}
        printQrCodeParams={this.getParamsForPrintQrCode()}
        qrTemplateId={this.state.showEditQrTemplateModalForQrTemplateId}
        templateType={this.props.templateType}
        onClose={() => {
          this.setState({ showEditQrTemplateModal: false });
          setTimeout(() => {
            this.setState({ open: true });
            this.fetchEditedQrTemplate();
          }, 250);
        }}
      />
    );
  };

  renderNewQrTemplateModal = () => {
    return (
      <NewQrTemplateModal
        open={this.state.showTitleModal}
        templateType={this.props.templateType}
        onSave={id => {
          this.setState({ showTitleModal: false, qrTemplateIds: [id, ...this.state.qrTemplateIds] });
          setTimeout(() => {
            this.setState({ showEditQrTemplateModal: true, showEditQrTemplateModalForQrTemplateId: id });
          }, 250);
        }}
        onClose={() => {
          this.setState({ showTitleModal: false });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
      />
    );
  };

  renderDeleteQrTemplateConfirmModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteQrTemplateModal}
        title={<FormattedMessage id="components.print-qr-codes.confirm-delete-template-modal.title" />}
        message={<FormattedMessage id="components.print-qr-codes.confirm-delete-template-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingQrTemplate}
        onConfirm={() => {
          this.setState({ isDeletingQrTemplate: true });
          this.props.deleteQrTemplate(this.state.showConfirmDeleteQrTemplateModalForQrTemplateId).then(() => {
            let previewQrTemplateId = this.state.previewQrTemplateId;
            if (previewQrTemplateId === this.state.showConfirmDeleteQrTemplateModalForQrTemplateId) {
              previewQrTemplateId = null;
            }
            this.setState({
              previewQrTemplateId,
              showConfirmDeleteQrTemplateModal: false,
              showConfirmDeleteQrTemplateModalForQrTemplateId: null,
              isDeletingQrTemplate: false,
              qrTemplateIds: this.state.qrTemplateIds.filter(
                id => id !== this.state.showConfirmDeleteQrTemplateModalForQrTemplateId
              ),
            });
          });
        }}
        onCancel={() => {
          this.setState({
            showConfirmDeleteQrTemplateModal: false,
            showConfirmDeleteQrTemplateModalForQrTemplateId: null,
          });
        }}
      />
    );
  };

  renderConfirmSetAsDefaultModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmSetAsDefaultModal}
        title={<FormattedMessage id="components.print-qr-codes.add-as-default-modal.title" />}
        message={<FormattedMessage id="components.print-qr-codes.add-as-default-modal.message" />}
        confirmButtonText="general.save"
        confirmIsLoading={this.state.isSavingQrTemplateAsDefault}
        onConfirm={() => {
          if (this.state.isSavingQrTemplateAsDefault) {
            return;
          }
          this.setState({ isSavingQrTemplateAsDefault: true });
          this.props
            .updateQrTemplate(this.state.showConfirmSetAsDefaultModalForQrTemplateId, { default: true })
            .then(() => {
              this.setState({ isSavingQrTemplateAsDefault: false, showConfirmSetAsDefaultModal: false });
            });
        }}
        onCancel={() => {
          this.setState({ showConfirmSetAsDefaultModal: false });
        }}
      />
    );
  };

  renderConfirmRemoveAsDefaultModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmRemoveAsDefaultModal}
        title={<FormattedMessage id="components.print-qr-codes.remove-as-default-modal.title" />}
        message={<FormattedMessage id="components.print-qr-codes.remove-as-default-modal.message" />}
        confirmButtonText="general.save"
        confirmIsLoading={this.state.isSavingQrTemplateAsDefault}
        onConfirm={() => {
          if (this.state.isSavingQrTemplateAsDefault) {
            return;
          }
          this.setState({ isSavingQrTemplateAsDefault: true });
          this.props
            .updateQrTemplate(this.state.showConfirmRemoveAsDefaultModalForQrTemplateId, { default: false })
            .then(() => {
              this.setState({ isSavingQrTemplateAsDefault: false, showConfirmRemoveAsDefaultModal: false });
            });
        }}
        onCancel={() => {
          this.setState({ showConfirmRemoveAsDefaultModal: false });
        }}
      />
    );
  };

  renderLegacyPrintQrCodeModal = () => {
    return (
      <LegacyPrintQrCodeModal
        open={this.state.showLegacyPrintQrCodeModal}
        type={this.props.templateType}
        assetId={this.props.assetId}
        sparePartId={this.props.sparePartId}
        amountOfQrCodes={this.props.amountOfQrCodes}
        onClose={() => {
          this.setState({
            showLegacyPrintQrCodeModal: false,
          });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
      />
    );
  };

  renderIconButtons = () => {
    if (this.props.settings.qr_label_legacy_toogle === true) {
      return (
        <div className={styles['icon-buttons']}>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={e => {
              e.preventDefault();
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <NewInlineModal
            minWidth={250}
            position="right"
            positionToRef={this.inlineModalPositioningRef}
            open={this.state.dropdownOpen}
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                <NewInlineModal.Dropdown.Item
                  onClick={() => {
                    this.setState({ dropdownOpen: false, open: false });
                    setTimeout(() => {
                      this.setState({ showLegacyPrintQrCodeModal: true });
                    }, 250);
                  }}
                >
                  <FormattedMessage id="components.print-qr-codes.legacy-print-modal" />
                </NewInlineModal.Dropdown.Item>
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          </NewInlineModal>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} width={1200} fullHeight>
          <Modal.Header
            ignoreLine
            title={this.renderTitle()}
            subtitle={this.renderSubtitle()}
            iconButtons={this.renderIconButtons()}
            onClose={this.props.onClose}
          />
          <Modal.Content noPadding>{this.renderContent()}</Modal.Content>
        </Modal>
        {this.renderEditQrTemplateModal()}
        {this.renderNewQrTemplateModal()}
        {this.renderDeleteQrTemplateConfirmModal()}
        {this.renderConfirmSetAsDefaultModal()}
        {this.renderConfirmRemoveAsDefaultModal()}
        {this.renderLegacyPrintQrCodeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
      deleteQrTemplate: SDKReduxOperations.deleteQrTemplate,
      updateQrTemplate: SDKReduxOperations.updateQrTemplate,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    sparePart: EntitySelectors.getSparePart(state, ownProps.sparePartId),
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintQRCodeModal);
