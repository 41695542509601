import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MaxUploadSize } from 'sdk/Attachment';
import { EntitySelectors } from 'sdk/State/entities';
import { Button, Field, FieldErrorWrapper, FileUploadWrapper, Icon } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SelectAssetModal } from 'views/components/Asset';
import { DeprecatedSelectSparePartModal } from 'views/components/SparePart';
import { ErrorOperations } from 'state/ducks/error';
import { AuthSelectors } from 'state/ducks/auth';
import SelectedFolderContainer from './SelectedFolderContainer';
import styles from './style.module.scss';

class NewAttachmentModal extends Component {
  getInitialState = props => ({
    folderId: props.folderId,
    showAddMoreAssetsModalContent: false,
    showAddMoreSparePartsModal: false,
    isFetching: true,
    attachmentToUpload: null,
    assetIds: [],
    sparePartIds: [],
    showLinkUrlError: false,
    attachment: {
      title: '',
      description: '',
      link_url: '',
    },
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.isOpen && !nextProps.isOpen) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(this.getInitialState(this.props));
    }
  }

  componentWillUnmount() {
    this.props.removeFieldsFromErrors(['file']);
  }

  getAttachmentTitle = () => {
    if (this.state.attachment.title) return this.state.attachment.title;
    if (this.props.type === 'file') {
      return this.state.attachmentToUpload.name;
    }
    if (this.props.type === 'link') {
      return this.state.attachment.link_url;
    }
  };

  createNewAttachment = () => {
    if (!this.validateSave()) return null;

    const { file, mime_type } = this.state.attachmentToUpload || {};
    let asset_ids = null;
    let spare_part_ids = null;
    const additionalSelectedAssetIds = this.state.assetIds.filter(id => id != this.props.createForAssetId);
    const additionalSelectedSparePartIds = this.state.sparePartIds.filter(
      id => id != this.props.createForSparePartId
    );
    if (additionalSelectedAssetIds.length > 0) {
      asset_ids = additionalSelectedAssetIds;
    }
    if (additionalSelectedSparePartIds.length > 0) {
      spare_part_ids = additionalSelectedSparePartIds;
    }
    this.props.onCreateNew({
      asset_ids: asset_ids == null ? null : [this.props.createForAssetId, ...additionalSelectedAssetIds],
      spare_part_ids:
        spare_part_ids == null ? null : [this.props.createForSparePartId, ...additionalSelectedSparePartIds],
      attachment_folder_id: this.state.folderId,
      title: this.getAttachmentTitle(),
      extension: this.state.attachment.extension,
      description: this.state.attachment.description,
      ...(this.props.type === 'file'
        ? {
            type: 'file',
            file,
            mime_type,
          }
        : {
            type: 'link',
            link_url: this.state.attachment.link_url,
          }),
    });
    this.props.onClose();
  };

  validateSave = () => {
    if (this.props.type === 'link' && this.state.attachment.link_url === '') {
      this.setState({
        showLinkUrlError: true,
      });

      return false;
    }
    return true;
  };

  selectFile = ({ file, mime_type, name, extension }) => {
    this.setState({
      attachment: {
        ...this.state.attachment,
        extension,
        title: this.state.attachmentToUpload == null ? name : this.state.attachment.title,
      },
      attachmentToUpload: { file, mime_type, name },
    });
  };

  renderSelectedFolderContainer = () => {
    if (
      this.props.createForAssetId != null &&
      this.props.allAttachmentFolders.length > 0 &&
      this.props.hasProTier
    ) {
      return (
        <Grid.Row>
          <Grid.Column>
            <SelectedFolderContainer
              id={this.state.folderId}
              onSelectFolder={folderId => this.setState({ folderId })}
            />
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderFormContent = () => {
    return (
      <Grid>
        {this.props.type === 'file' ? (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <div className={styles['choosen-file-container']}>
                  <div className={styles['field']}>
                    <Field
                      icon={<Icon regular type="file-alt" />}
                      view
                      label={<FormattedMessage id="components.new-attachment.chosen-file" />}
                    >
                      {this.state.attachmentToUpload.name}
                    </Field>
                  </div>
                  <div className={styles['button']}>
                    {this.renderFileUploadWrapper(<Button type="icon" icon={<Icon regular type="pen" />} />)}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Separator />
          </React.Fragment>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.attachment.name" />}>
              <Field.Text
                fluid
                autoFocus
                value={this.state.attachment.title}
                onChange={title =>
                  this.setState({
                    attachment: {
                      ...this.state.attachment,
                      title,
                    },
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.props.type === 'link' ? (
          <Grid.Row>
            <Grid.Column>
              <Field label={<FormattedMessage id="resources.attachment.hyperlink" />}>
                <FieldErrorWrapper
                  position="top"
                  show={this.state.showLinkUrlError}
                  errorElement={<FormattedMessage id="general.errors.is-required" />}
                >
                  <Field.Text
                    fluid
                    type="url"
                    error={this.state.showLinkUrlError}
                    value={this.state.attachment.link_url}
                    onChange={linkUrl =>
                      this.setState({
                        showLinkUrlError: false,
                        attachment: {
                          ...this.state.attachment,
                          link_url: linkUrl,
                        },
                      })
                    }
                  />
                </FieldErrorWrapper>
              </Field>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {this.renderSelectedFolderContainer()}
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.attachment.description" />}>
              <Field.Textarea
                value={this.state.attachment.description}
                onChange={description =>
                  this.setState({
                    attachment: {
                      ...this.state.attachment,
                      description,
                    },
                  })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.props.createForAssetId ? this.renderSelectedAssets() : null}
        {this.props.createForSparePartId ? this.renderSelectedSpareParts() : null}
      </Grid>
    );
  };

  renderSelectAssetModal = () => (
    <SelectAssetModal
      isOpen={this.state.showAddMoreAssetsModalContent}
      selectedAssetIds={this.state.assetIds}
      onClose={() => this.setState({ showAddMoreAssetsModalContent: false })}
      onSelectAssetIds={ids => {
        this.setState({
          showAddMoreAssetsModalContent: false,
          assetIds: ids.filter(id => id !== this.props.createForAssetId),
        });
      }}
    />
  );

  renderSelectSparePartModal = () => (
    <DeprecatedSelectSparePartModal
      isOpen={this.state.showAddMoreSparePartsModal}
      selectedSparePartIds={this.state.selectedSparePartIds}
      onClose={() => this.setState({ showAddMoreSparePartsModal: false })}
      onSelectSparePartIds={ids => {
        this.setState({
          showAddMoreSparePartsModal: false,
          sparePartIds: ids.filter(id => id !== this.props.createForSparePartId),
        });
      }}
    />
  );

  renderSelectedAssets = () => {
    const amountOfAddedAssets = this.state.assetIds.length;
    return (
      <Grid.Row>
        <Grid.Column>
          <div className={styles['additional-resources']}>
            {amountOfAddedAssets === 0 ? null : (
              <p className={styles['amount']}>
                <span>+</span>
                <FormattedMessage
                  id="components.new-attachment.amount-of-assets-added"
                  values={{ amount: amountOfAddedAssets }}
                />
              </p>
            )}
            <Button
              type="text"
              fontSize="11"
              label="components.new-attachment.connect-more-assets"
              onClick={() => {
                this.setState({ showAddMoreAssetsModalContent: true });
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderSelectedSpareParts = () => {
    const amountOfAddedSpareParts = this.state.sparePartIds.length;
    return (
      <Grid.Row>
        <Grid.Column>
          <div className={styles['additional-resources']}>
            {amountOfAddedSpareParts === 0 ? null : (
              <p className={styles['amount']}>
                <span>+</span>
                <FormattedMessage
                  id="components.new-attachment.amount-of-spare-parts-added"
                  values={{ amount: amountOfAddedSpareParts }}
                />
              </p>
            )}
            <Button
              type="text"
              fontSize="11"
              label="components.new-attachment.connect-more-spare-parts"
              onClick={() => {
                this.setState({ showAddMoreSparePartsModal: true });
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderFileUploadWrapper = trigger => (
    <FileUploadWrapper
      onSelectFile={({ file, mime_type, name, extension, size }) => {
        if (size > MaxUploadSize) {
          this.props.setFieldErrors({
            file: 'components.new-attachment.errors.file-to-big',
          });
          return;
        }
        this.props.removeFieldsFromErrors(['file']);
        this.selectFile({ file, mime_type, name, extension });
      }}
    >
      {trigger}
    </FileUploadWrapper>
  );

  renderUploadContent = () =>
    this.renderFileUploadWrapper(
      <Button
        fullWidth
        primary
        label={'components.attachment-options-inline-modal.options.change-file.choose-file'}
      />
    );

  renderContent = () => {
    if (this.props.type === 'file' && !this.state.attachmentToUpload) {
      return <Modal.Content>{this.renderUploadContent()}</Modal.Content>;
    } else {
      return (
        <React.Fragment>
          <Modal.Content>{this.renderFormContent()}</Modal.Content>
          <Modal.Footer container>
            <Button.Group>
              <Button
                small
                primary
                loading={this.state.isSaving}
                onClick={this.createNewAttachment}
                label="general.save"
              />
              <Button small label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} width={440}>
          <Modal.Header
            title={
              <FormattedMessage
                id={
                  this.props.type === 'file'
                    ? 'components.new-attachment.title-file'
                    : 'components.new-attachment.title-hyperlink'
                }
              />
            }
            onClose={this.props.onClose}
          />
          {this.renderContent()}
        </Modal>
        {this.renderSelectAssetModal()}
        {this.renderSelectSparePartModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
      setFieldErrors: ErrorOperations.setFieldErrors,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAttachmentModal);

NewAttachmentModal.propTypes = {
  createForAssetId: PropTypes.string,
  createForSparePartId: PropTypes.string,
  type: PropTypes.oneOf(['file', 'link']),
  folderId: PropTypes.string,
  disableSelectForFolderId: PropTypes.string,
  onCreateNew: PropTypes.func,
  onClose: PropTypes.func,
};

NewAttachmentModal.defaultProps = {
  createForAssetId: null,
  createForSparePartId: null,
  folderId: null,
};
