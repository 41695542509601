import React, { Component } from 'react';
import { injectIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { normalizeAssetType } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Menu, Loader } from 'views/components/Shared/General';
import List from './List';
import AssetTypes from './AssetTypes';
import AssetTree from './AssetTree';
import styles from './style.module.scss';

class NewSelectAssetModal extends Component {
  getInitialState = () => ({
    assetTypeIds: [],
    selectedItem: 'tree',
    isLoadingTypes: false,
    searchValue: '',
    selectedAssetTypeId: null,
    archived: null,
    selectedAssetIdsForMultiple: {}, // { id: true }
  });
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState(), isLoadingTypes: true });
      this.fetchAssetTypes();
    }
  }

  fetchAssetTypes = () => {
    API.listAssetTypes(this.props.currentSystem.id)
      .then(res => {
        const { data } = res;
        const { entities, result } = normalizeAssetType(data);
        this.props.updateEntities(entities);
        this.setState({ isLoadingTypes: false, assetTypeIds: result });
      })
      .catch(e => {
        this.setState({ isLoadingTypes: false });
      });
  };

  renderAssetList = () => {
    return (
      <List
        additionalApiParams={this.props.additionalApiParams}
        searchValue={this.state.searchValue}
        assetTypeId={this.state.selectedAssetTypeId}
        archived={this.state.archived}
        listItemRightComponent={this.props.listItemRightComponent}
        alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
        multiple={this.props.multiple}
        selectedAssetIds={this.state.selectedAssetIdsForMultiple}
        onSelectMultiple={id => {
          if (this.state.selectedAssetIdsForMultiple[id] == null) {
            this.setState({
              selectedAssetIdsForMultiple: {
                ...this.state.selectedAssetIdsForMultiple,
                [id]: true,
              },
            });
          } else {
            let selectedAssetIdsForMultiple = {
              ...this.state.selectedAssetIdsForMultiple,
            };
            delete selectedAssetIdsForMultiple[id];
            this.setState({
              selectedAssetIdsForMultiple,
            });
          }
        }}
      />
    );
  };

  renderAssetTypes = () => {
    return (
      <AssetTypes
        ids={this.state.assetTypeIds}
        activeItem={this.state.selectedItem === 'filtered'}
        onItemClick={value =>
          this.setState({ archived: null, selectedAssetTypeId: value, selectedItem: 'filtered' })
        }
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.canEditAssets === false) return null;
    if (this.props.hideCreateButton) return null;
    return (
      <>
        <Button
          primary
          onClick={this.props.onCreateAsset}
          label="components.select-asset-modal.menu.create-button"
        />
        <div className={styles['separator']} />
      </>
    );
  };

  renderAddMutlipleAssetsButton = () => {
    if (this.props.multiple === false) return null;
    if (Object.keys(this.state.selectedAssetIdsForMultiple).length === 0) {
      return null;
    }
    return (
      <div className={styles['add-multiple-button-container']}>
        <Button
          primary
          fullWidth
          onClick={() => this.props.onSelectAssets(Object.keys(this.state.selectedAssetIdsForMultiple))}
          translate={false}
          label={
            <FormattedPlural
              value={Object.keys(this.state.selectedAssetIdsForMultiple).length}
              one={<FormattedMessage id="components.select-asset-modal.select-multiple-assets.one" />}
              two={
                <FormattedMessage
                  id="components.select-asset-modal.select-multiple-assets.two"
                  values={{ amount: Object.keys(this.state.selectedAssetIdsForMultiple).length }}
                />
              }
              few={
                <FormattedMessage
                  id="components.select-asset-modal.select-multiple-assets.few"
                  values={{ amount: Object.keys(this.state.selectedAssetIdsForMultiple).length }}
                />
              }
              many={
                <FormattedMessage
                  id="components.select-asset-modal.select-multiple-assets.many"
                  values={{ amount: Object.keys(this.state.selectedAssetIdsForMultiple).length }}
                />
              }
              other={
                <FormattedMessage
                  id="components.select-asset-modal.select-multiple-assets.other"
                  values={{ amount: Object.keys(this.state.selectedAssetIdsForMultiple).length }}
                />
              }
            />
          }
        />
      </div>
    );
  };

  renderMenuButtons = () => {
    return (
      <>
        <Menu.Item
          selected={this.state.selectedItem === 'tree'}
          title={<FormattedMessage id="components.select-asset-modal.menu.tree" />}
          onClick={() => {
            this.setState({ selectedAssetTypeId: null, archived: null, selectedItem: 'tree' });
          }}
        />
        <Menu.Item
          selected={this.state.selectedItem === 'asset'}
          title={<FormattedMessage id="components.select-asset-modal.menu.all-assets" />}
          onClick={() => {
            this.setState({ selectedAssetTypeId: null, archived: null, selectedItem: 'asset' });
          }}
        />
        <Menu.Expandable>
          <Menu.Item
            red
            title={<FormattedMessage id="screens.assets.left-panel.archived" />}
            selected={this.state.selectedItem === 'archived'}
            onClick={() => {
              this.setState({ selectedAssetTypeId: null, archived: true, selectedItem: 'archived' });
            }}
          />
        </Menu.Expandable>
      </>
    );
  };

  renderTreeView = () => {
    return (
      <AssetTree
        additionalApiParams={this.props.additionalApiParams}
        searchValue={this.state.searchValue}
        multiple={this.props.multiple}
        selectedAssetIds={this.state.selectedAssetIdsForMultiple}
        onSelectMultiple={id => {
          if (this.state.selectedAssetIdsForMultiple[id] == null) {
            this.setState({
              selectedAssetIdsForMultiple: {
                ...this.state.selectedAssetIdsForMultiple,
                [id]: true,
              },
            });
          } else {
            let selectedAssetIdsForMultiple = {
              ...this.state.selectedAssetIdsForMultiple,
            };
            delete selectedAssetIdsForMultiple[id];
            this.setState({
              selectedAssetIdsForMultiple,
            });
          }
        }}
        listItemRightComponent={this.props.listItemRightComponent}
        alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
      />
    );
  };

  renderMenuLoader = () => {
    return <Loader />;
  };

  renderMenuContent = () => {
    return (
      <>
        <div className={styles['menu-container']}>
          <div className={styles['left-panel-container']}>
            <PerfectScrollbar>
              <div className={styles['left-panel']}>
                {this.renderCreateButton()}
                {this.renderMenuButtons()}
              </div>
              {this.state.isLoadingTypes ? this.renderMenuLoader() : this.renderAssetTypes()}
            </PerfectScrollbar>
          </div>

          {this.renderAddMutlipleAssetsButton()}
        </div>
      </>
    );
  };

  renderModalView = () => {
    switch (this.state.selectedItem) {
      case 'asset':
      case 'filtered':
      case 'archived':
        return this.renderAssetList();
      case 'tree':
        return this.renderTreeView();
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={this.props.title}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
          searchable
          value={this.state.searchValue}
          onSearch={value => {
            this.setState({ searchValue: value });
          }}
          onClear={() => {
            this.setState({ searchValue: '' });
          }}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>{this.renderModalView()}</Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewSelectAssetModal));

NewSelectAssetModal.propTypes = {
  open: PropTypes.bool,
  hideCreateButton: PropTypes.bool,
  multiple: PropTypes.bool,
  title: PropTypes.any,
  listItemRightComponent: PropTypes.func,
  alreadySelectedForMultiple: PropTypes.func,
  onClose: PropTypes.func,
  onSelectAssets: PropTypes.func,
};

NewSelectAssetModal.defaultProps = {
  open: false,
  hideCreateButton: false,
  multiple: false,
  title: <FormattedMessage id="components.select-asset-modal.title" />,
};
