import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions } from 'sdk';
import { CalendarSelectors, CalendarOperations } from 'state/ducks/calendar';
import styles from '../style.module.scss';
import Section from './Section';

const request = HelperFunctions.getCancelTokenForRequest();

class AwaitingDelivery extends Component {
  state = {
    isFetching: true,
  };

  componentDidMount() {
    this.fetchWorkOrders()
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    request.cancel();
  }

  fetchWorkOrders = (params = {}) => {
    return this.props.fetchWorkOrdersAwaitingDelivery(
      this.props.currentSystem.id,
      { purchases_status: 'awaiting_delivery', no_pagination: true, ...params },
      request.getCancelTokenConfig()
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny />
        </div>
      );
    }
    if (this.props.workOrders.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['separator']} />
          <div className={styles['text']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.empty-data-set.title" />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['list']}>
        <Section workOrders={this.props.workOrders} />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={styles['header-container']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.title" />
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={() => {
                  this.props.hideListBar();
                }}
              />
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.awaiting-delivery.subtitle" />
          </div>
        </div>
        {this.renderContent()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideListBar: CalendarOperations.hideListBar,
      fetchWorkOrdersAwaitingDelivery: CalendarOperations.fetchWorkOrdersAwaitingDelivery,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    workOrders: CalendarSelectors.getWorkOrdersAwaitingDelivery(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AwaitingDelivery);
