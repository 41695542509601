import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';

class DeleteAssetVendorModal extends Component {
  state = {
    isDeleting: false,
    showConfirmDeleteModal: false,
  };

  getInitialState = () => ({
    isDeleting: false,
    showConfirmDeleteModal: true,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ showConfirmDeleteModal: true });
    }
  }

  deleteAssetVendor() {
    this.setState({ isDeleting: true });
    this.props.deleteAssetVendor(this.props.assetVendor.id).then(() => {
      toast(<ToastMessage success text={<FormattedMessage id="screens.asset.vendors.delete-success" />} />);
      this.setState({ isDeleting: false });
      this.props.onClose();
      this.setState({ showConfirmDeleteModal: false });
    });
  }

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={this.props.title}
        message={this.props.subtitle}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => this.deleteAssetVendor()}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          this.props.onClose();
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAssetVendor: SDKReduxOperations.deleteAssetVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  var assetVendor = null;
  if (ownProps.id) {
    assetVendor = EntitySelectors.getAssetVendor(state, ownProps.id);
  }
  return {
    assetVendor,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAssetVendorModal);
