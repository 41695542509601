import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader as ImageLoader } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class Loader extends Component {
  render() {
    let loaderSize;
    let classNames = [styles['loader']];
    if (this.props.circle) {
      classNames = [...classNames, styles['circle']];
    }
    if (this.props.large) {
      classNames = [...classNames, styles['large']];
      loaderSize = 'small';
    } else if (this.props.medium) {
      classNames = [...classNames, styles['medium']];
      loaderSize = 'tiny';
    } else {
      classNames = [...classNames, styles['small']];
      loaderSize = 'tiny';
    }

    return (
      <div className={classNames.join(' ')}>
        <ImageLoader tiny={loaderSize === 'tiny'} small={loaderSize === 'small'} />
      </div>
    );
  }
}

export default Loader;

Loader.propTypes = {
  large: PropTypes.bool,
  medium: PropTypes.bool,
  tiny: PropTypes.bool,
  circle: PropTypes.bool,
};

Loader.defaultProps = {
  large: false,
  medium: false,
  tiny: false,
  circle: false,
};
