import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listOperationalMaintenances = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenances?${HelperFunctions.convertObjToQueryParameters(params)}`,
    config
  );
export const fetchOperationalMaintenanceListCount = (systemId, params, config = null) =>
  request.get(
    `systems/${systemId}/operational_maintenances/list_count?${HelperFunctions.convertObjToQueryParameters(
      params
    )}`,
    config
  );

export const fetchOperationalMaintenance = operationalMaintenanceInstanceId =>
  request.get(`operational_maintenances/${operationalMaintenanceInstanceId}`);

export const batchUpdateOperationalMaintenances = (systemId, data) =>
  request.put(`systems/${systemId}/operational_maintenances/batch`, data);
