import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { withRouter } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { EntityOperations } from 'sdk/State/entities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import { Menu, Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { MenuType } from 'views/scenes/SpareParts/SparePartList';
import styles from './style.module.scss';

class SparePartLocationMenu extends Component {
  animationDirection = 'forward';

  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      showSearchField: false,
      sparePartLocations: [],
      rootSparePartLocations: [],
      parentLocationId: null,
      isEmpty: false,
      searchValue: '',
      showMoreItemsDropdownOptions: false,
    };
  }

  componentDidMount() {
    this.fetchSparePartLocations();
  }

  /*
    Requests
  */

  fetchSparePartLocations = params => {
    const { spare_part_location_with_children_id } = queryString.parse(this.props.location.search);
    API.listSparePartLocations(this.props.currentSystem.id, { no_pagination: true }).then(
      ({ data: sparePartLocationData }) => {
        const { entities, result } = normalizeSparePartLocation(sparePartLocationData);
        this.props.updateEntities(entities);
        const sparePartLocations = result.map(id => entities.sparePartLocationById[id]);
        const rootSparePartLocations = sparePartLocations.filter(
          spl => spl.spare_part_location_parent_id == null
        );
        let parentLocationId = null;
        if (spare_part_location_with_children_id) {
          parentLocationId = sparePartLocations.find(
            ({ id }) => id === spare_part_location_with_children_id
          ).spare_part_location_parent_id;
        }
        this.setState({
          sparePartLocations,
          rootSparePartLocations,
          parentLocationId,
          isFetching: false,
          isEmpty: rootSparePartLocations.length === 0,
        });
      }
    );
  };

  /*
    Helepr Functions
  */

  toggleSearch = () => {
    this.setState(
      prevState => ({
        showSearchField: !prevState.showSearchField,
        searchValue: '',
      }),
      () => {
        if (this.state.showSearchField) {
          this.inputRef.focus();
        }
      }
    );
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...obj,
      })}`
    );
  };

  hasChildren = sparePartLocation => {
    return (
      this.state.sparePartLocations.find(
        ({ spare_part_location_parent_id }) => spare_part_location_parent_id === sparePartLocation.id
      ) != null
    );
  };

  getParentSparePartLocation = () => {
    return this.state.sparePartLocations.find(({ id }) => id === this.state.parentLocationId);
  };

  /*
    Render functions
  */

  renderSearchField = () => {
    let classNames = [styles['search-container']];

    return (
      <div className={styles['border-positioner']}>
        <AnimateHeight duration={250} height={this.state.showSearchField ? 'auto' : 0}>
          <div className={classNames.join(' ')}>
            <div className={styles['input-container']}>
              <div className={styles['input-logo']}>
                <Icon regular type="search" />
              </div>
              <input
                ref={ref => (this.inputRef = ref)}
                className={styles['input']}
                placeholder={this.props.intl.formatMessage({
                  id: 'general.search-placeholder',
                })}
                autoComplete="off"
                value={this.state.searchValue}
                name="search"
                onChange={e => {
                  this.setState({ searchValue: e.target.value });
                }}
              />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  };

  renderSearchTitle = () => {
    const parentLocation = this.getParentSparePartLocation();
    if (this.state.showSearchField) {
      return <FormattedMessage id="resources.spare-part.location" />;
    } else if (parentLocation) {
      return <span>{parentLocation.title}</span>;
    } else {
      return <FormattedMessage id="resources.spare-part.location" />;
    }
  };

  renderSearchHeaderDropdown = () => {
    return (
      <>
        <div
          className={styles['search-title']}
          onClick={() =>
            this.setState(prevState => ({
              showMoreItemsDropdownOptions: !prevState.showMoreItemsDropdownOptions,
            }))
          }
        >
          <div className={styles['title']}>{this.renderSearchTitle()}</div>
          <div className={styles['icon']}>
            <Icon size={12} solid type="caret-down" />
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.searchHeaderDropdownRef}
          open={this.state.showMoreItemsDropdownOptions}
          position="right"
          onClose={() => this.setState({ showMoreItemsDropdownOptions: false })}
          minWidth={200}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.Asset);
                  localStorage.setItem('listMenu', MenuType.Asset);
                }}
              >
                <FormattedMessage id="resources.asset.resource" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                selected
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartLocation);
                  localStorage.setItem('listMenu', MenuType.SparePartLocation);
                }}
              >
                <FormattedMessage id="resources.spare-part.location" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ showMoreItemsDropdownOptions: false });
                  this.props.onChangeMenu(MenuType.SparePartType);
                  localStorage.setItem('listMenu', MenuType.SparePartType);
                }}
              >
                <FormattedMessage id="resources.spare-part.category" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderSearch() {
    return (
      <div className={styles['search-header-container']}>
        <div className={styles['search-header']} ref={ref => (this.searchHeaderDropdownRef = ref)}>
          {this.renderSearchHeaderDropdown()}
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={this.toggleSearch}
          />
        </div>
        {this.renderSearchField()}
      </div>
    );
  }

  renderBackButton = () => {
    const parentLocation = this.getParentSparePartLocation();
    if (parentLocation) {
      return (
        <div
          className={styles['back-button-container']}
          onClick={() => {
            this.animationDirection = 'backward';
            this.setState({
              parentLocationId: parentLocation.spare_part_location_parent_id,
            });
          }}
        >
          <Icon regular type="angle-left" blue size={11} />
          <p className={styles['back']}>
            <FormattedMessage id="general.back" />
          </p>
        </div>
      );
    }
    return null;
  };

  renderTreeItems = () => {
    const sparePartLocationsForParentId = this.state.sparePartLocations.filter(
      ({ spare_part_location_parent_id }) => spare_part_location_parent_id === this.state.parentLocationId
    );
    const { spare_part_location_with_children_id } = queryString.parse(this.props.location.search);
    return sparePartLocationsForParentId.map(sparePartLocation => {
      return (
        <Menu.Item
          title={sparePartLocation.title}
          onClick={() =>
            this.changeQueryParams({ spare_part_location_with_children_id: sparePartLocation.id })
          }
          onDoubleClick={() => {
            if (this.hasChildren(sparePartLocation)) {
              this.animationDirection = 'forward';
              this.setState({ parentLocationId: sparePartLocation.id });
            }
          }}
          selected={spare_part_location_with_children_id === sparePartLocation.id}
          rightComponent={
            this.hasChildren(sparePartLocation) ? (
              <Menu.Item.NavigateButton
                onClick={() => {
                  this.animationDirection = 'forward';
                  this.setState({ parentLocationId: sparePartLocation.id });
                }}
              />
            ) : null
          }
        />
      );
    });
  };

  renderTree = () => {
    let classNames = [];
    if (this.animationDirection === 'forward') {
      classNames = [...classNames, styles['animate-forward']];
    } else {
      classNames = [...classNames, styles['animate-backward']];
    }

    return (
      <div className={classNames.join(' ')}>
        <SwitchTransition>
          <CSSTransition
            key={this.state.parentLocationId}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            onEntering={() => this.scrollBarRef.updateScroll()}
            classNames={{
              enter: styles['fade-enter'],
              enterActive: styles['fade-enter-active'],
              exit: styles['fade-exit'],
              exitActive: styles['fade-exit-active'],
            }}
          >
            <div className={styles['items']}>
              {this.renderBackButton()}
              {this.renderTreeItems()}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    );
  };

  renderSearchedSparePartLocations = () => {
    const { searchValue } = this.state;
    const { spare_part_location_with_children_id } = queryString.parse(this.props.location.search);

    if (this.state.searchValue.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="screens.spare-parts.left-panel.spare-part-location.search-text" />
        </span>
      );
    }
    const filteredSparePartLocations = this.state.sparePartLocations.filter(
      ({ title }) => searchValue === '' || title.toLowerCase().includes(searchValue.toLowerCase())
    );
    if (filteredSparePartLocations.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </span>
      );
    }
    return (
      <div className={styles['items']}>
        {filteredSparePartLocations
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map(sparePartLocation => {
            return (
              <Menu.Item
                title={sparePartLocation.title}
                subtitle={
                  <SparePartLocationPath fullPath={false} sparePartLocationId={sparePartLocation.id} />
                }
                onClick={() =>
                  this.changeQueryParams({ spare_part_location_with_children_id: sparePartLocation.id })
                }
                selected={spare_part_location_with_children_id === sparePartLocation.id}
              />
            );
          })}
      </div>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <div className={styles['items']}>
          <Menu.Item loading />
          <Menu.Item loading />
        </div>
      );
    }
    if (this.state.sparePartLocations.length === 0) {
      return (
        <div className={styles['items']}>
          <span className={styles['search-text']}>
            <FormattedMessage id="screens.spare-parts.left-panel.spare-part-location.empty-data-set.title" />
          </span>
        </div>
      );
    }
    if (this.state.showSearchField) {
      return this.renderSearchedSparePartLocations();
    }
    return this.renderTree();
  };

  render() {
    return (
      <>
        {this.renderSearch()}
        <PerfectScrollbar
          ref={ref => {
            this.scrollBarRef = ref;
          }}
        >
          {this.renderContent()}
        </PerfectScrollbar>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartLocationMenu)));
