import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEqual } from 'lodash-es';
import { DropTarget } from 'react-dnd';
import moment from 'moment';
import { CalendarSelectors } from 'state/ducks/calendar';
import WorkOrder from './WorkOrder';
import EstimatedTimeContainer from './EstimatedTimeContainer';
import styles from './style.module.scss';
import { Type } from 'views/scenes/Planning/Calendar/Calendar/utils';

const dropTarget = {
  drop(props, monitor, component) {
    const { date: toDate, groupId: toGroupId, userId: toUserId, vendorId: toVendorId } = props;
    const {
      date: fromDate,
      groupId: fromGroupId,
      userId: fromUserId,
      vendorId: fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
      requestId,
      requestData,
    } = monitor.getItem();
    props.onDrop({
      toDate,
      toGroupId,
      toUserId,
      toVendorId,
      fromDate,
      fromGroupId,
      fromUserId,
      fromVendorId,
      workOrderId,
      recurringMaintenanceId,
      recurringMaintenanceDate,
      users,
      groups,
      vendors,
      instanceId,
      fromToolboxList,
      requestId,
      requestData,
    });
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class Day extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.calendarInstances, nextProps.calendarInstances) ||
      !isEqual(this.props.date, nextProps.date) ||
      !isEqual(this.props.isOver, nextProps.isOver)
    );
  }

  createWorkOrder = () => {
    this.props.onCreateWorkOrder({
      date: this.props.date,
      assigned_to_users: this.props.userId ? [this.props.userId] : [],
      assigned_to_groups: this.props.groupId ? [this.props.groupId] : [],
      assigned_to_vendors: this.props.vendorId ? [this.props.vendorId] : [],
      completed_by_user_id: this.props.userId,
      completed_date: this.props.date,
    });
  };

  render() {
    const { date } = this.props;
    const isWeekend = moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7;
    let classNames = [styles['day-container']];
    if (this.props.calendarInstances.length === 0) {
      classNames = [...classNames, styles['empty']];
    }
    if (this.props.isOver) {
      classNames = [...classNames, styles['is-dragging-over']];
    }
    if (isWeekend) {
      classNames = [...classNames, styles['weekend']];
    }
    return this.props.connectDropTarget(
      <div className={classNames.join(' ')}>
        <div className={styles['day-wrapper']}>
          <div
            className={`${styles['day']}`}
            onClick={e => {
              this.createWorkOrder();
            }}
          >
            {this.props.calendarInstances.map((instance, i) => (
              <WorkOrder
                index={i}
                key={instance.id}
                instance={instance}
                userId={this.props.userId}
                date={this.props.date}
                groupId={this.props.groupId}
                vendorId={this.props.vendorId}
              />
            ))}
          </div>
        </div>
        <EstimatedTimeContainer
          calendarInstances={this.props.calendarInstances}
          onCreateWorkOrder={this.createWorkOrder}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.userId) {
    return {
      calendarInstances: CalendarSelectors.getInstancesForUserAtDate(state, ownProps.userId, ownProps.date),
    };
  } else if (ownProps.groupId) {
    return {
      calendarInstances: CalendarSelectors.getInstancesForGroupAtDate(state, ownProps.groupId, ownProps.date),
    };
  } else if (ownProps.vendorId) {
    return {
      calendarInstances: CalendarSelectors.getInstancesForVendorAtDate(
        state,
        ownProps.vendorId,
        ownProps.date
      ),
    };
  }
}

export default injectIntl(connect(mapStateToProps)(DropTarget(Type.Calendar, dropTarget, collect)(Day)));
