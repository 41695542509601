import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeQrColumnTemplate } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createQrTemplateColumn(qrTemplateId, params) {
  return dispatch => {
    return API.createQrTemplateColumn(qrTemplateId, params)
      .then(res => {
        const { entities } = normalizeQrColumnTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { qrTemplateId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_COLUMN_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateQrTemplateColumn(qrTemplateColumnId, params, config) {
  return dispatch => {
    return API.updateQrTemplateColumn(qrTemplateColumnId, params, config)
      .then(res => {
        const { entities } = normalizeQrColumnTemplate(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { qrTemplateColumnId, params, data: res.data };
        dispatch({ type: types.QR_TEMPLATE_COLUMN_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteQrTemplateColumn(qrTemplateColumnId) {
  return (dispatch, getState) => {
    return API.deleteQrTemplateColumn(qrTemplateColumnId)
      .then(res => {
        const qrTemplateColumn = getState().entities.qrTemplateColumnById[qrTemplateColumnId];
        const payload = { qrTemplateColumnId, data: qrTemplateColumn };
        dispatch({ type: types.QR_TEMPLATE_COLUMN_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
