import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Decimal } from 'decimal.js';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

class StockAdjustmentModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    stockQuantity: '',
    comment: '',
  });
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.value) {
      this.updateSparePart();
    }
  };

  createSparePartStockAdjustment = () => {
    this.setState({ isSaving: true });

    let params = {
      quantity: this.state.stockQuantity,
    };
    if (this.state.comment.length) {
      params = {
        ...params,
        comment: this.state.comment,
      };
    }

    this.props.createSparePartStockAdjustment(this.props.sparePart.id, params).then(() => {
      toast(
        <ToastMessage
          success
          text={<FormattedMessage id="components.adjust-stock-modal.update-value-success" />}
        />
      );
      this.setState({ isSaving: false });
      this.props.onClose();
    });
  };

  renderDifference = () => {
    if (this.state.stockQuantity === '' || this.props.sparePart.stock_quantity == null) return;
    try {
      const newStockValue = new Decimal(this.state.stockQuantity);
      const oldStockValue = new Decimal(this.props.sparePart.stock_quantity);
      const difference = newStockValue.minus(oldStockValue);

      const plusString = difference.gt(0) ? '+' : '';

      return (
        <Field
          view
          singleRow
          fontSize={12}
          label={<FormattedMessage id="components.adjust-stock-modal.difference" />}
        >
          <span>
            {plusString}
            <SparePartUnitWithValue sparePartUnit={this.props.sparePartUnit} value={difference.toString()} />
          </span>
        </Field>
      );
    } catch (e) {
      return null;
    }
  };

  renderStockQuantityField = () => (
    <Field view={false} label={<FormattedMessage id="components.adjust-stock-modal.new-value" />}>
      <Field.Decimal
        rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
        autoFocus
        value={this.state.stockQuantity}
        onChange={stockQuantity => {
          this.setState({ stockQuantity });
        }}
        onBlur={stockQuantity => {
          this.setState({ stockQuantity });
        }}
      />
    </Field>
  );

  renderStockQuantity = () => (
    <div className={styles['values']}>
      <Field
        view
        singleRow
        fontSize={12}
        label={<FormattedMessage id="components.adjust-stock-modal.current-value" />}
      >
        <SparePartUnitWithValue
          sparePartUnit={this.props.sparePartUnit}
          value={this.props.sparePart.stock_quantity}
        />
      </Field>
    </div>
  );

  renderCommentField = () => {
    return (
      <div className={styles['comment']}>
        <Field label={<FormattedMessage id="components.adjust-stock-modal.comment" />}>
          <Field.Textarea
            minRows={3}
            value={this.state.comment}
            onChange={value => {
              this.setState({ comment: value });
            }}
          />
        </Field>
      </div>
    );
  };

  renderContent = () => (
    <div>
      {this.renderStockQuantityField()}
      {this.renderStockQuantity()}
      {this.renderDifference()}
      {this.renderCommentField()}
    </div>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.adjust-stock-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={this.state.stockQuantity.length === 0}
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.createSparePartStockAdjustment}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePart.spare_part_unit_id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartStockAdjustment: SDKReduxOperations.createSparePartStockAdjustment,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentModal));
