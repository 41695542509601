import React from 'react';
import { connect } from 'react-redux';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';

const AssetContainer = ({ asset }) => {
  if (asset) {
    return (
      <>
        {asset.title}
        {asset.tree_path.length === 0 ? null : (
          <div>
            <TreePath assetId={asset.id} />
          </div>
        )}
      </>
    );
  }
  return '...';
};

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AssetContainer);
