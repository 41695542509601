import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEqual, debounce, omit } from 'lodash-es';
import moment from 'moment';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { MenuOperations } from 'state/ducks/menu';
import { WorkOrdersOperations, WorkOrdersSelectors } from 'state/ducks/workOrders';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { MenuUtils } from 'state/ducks/menu';
import { BookmarkedAssetsDropdown } from 'views/components/Asset';
import { NewWorkOrderModal } from 'views/components/WorkOrder';
import {
  Toggle,
  List,
  Pagination,
  Field,
  Button,
  Menu,
  Icon,
  SortingButton,
  FilterButton,
  NewInlineModal,
} from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import { AuthSelectors } from 'state/ducks/auth';
import Calendar from './Calendar';
import { ExportType } from 'sdk/Export';
import HelperFunctions from 'utilities/HelperFunctions';
import { WorkOrderListItem } from 'views/scenes/WorkOrders/WorkOrderList/components';
import FilterModal from './FilterModal';
import MassEditModal from './MassEditModal';
import ExportModal from './ExportModal';
import PrintModal from './PrintModal';
import styles from './style.module.scss';

const listWorkOrdersRequest = SDKHelperFunctions.getCancelTokenForRequest();
const listWorkOrderCountsRequest = SDKHelperFunctions.getCancelTokenForRequest();

export const LIST_TYPES = {
  Upcoming: 'upcoming',
  Today: 'today',
  ThisWeek: 'this_week',
  ThisMonth: 'this_month',
  Overdue: 'overdue',
  AwaitingSpareParts: 'awaiting_spare_parts',
  Completed: 'completed',
  Calendar: 'calendar',
  All: 'all',
};

class WorkOrderList extends Component {
  constructor(props) {
    super(props);
    const { date } = this.props.queryParameters;
    this.state = {
      fromDate: this.getStartDateFromDate(date),
      endDate: this.getEndDateFromDate(date),
      firstDateOfMonth: this.getFirstDateOfMonth(date),
      showNewWorkOrderModal: false,
      showFilterModal: false,
      newWorkOrderParams: {},
      searchTerm: '',
      showMeterBasedInlineModal: false,
      showMassEditModal: false,
      showExportModal: false,
      showExportModalForType: null,
      showPrintModal: false,
      showSortingDropdown: false,
    };
    this.fetchDebouncedWorkOrders = debounce(params => {
      this.props
        .fetchWorkOrders(this.props.system.id, params, listWorkOrdersRequest.getCancelTokenConfig())
        .then(workOrders => {
          return workOrders;
        })
        .catch(e => {});
    }, 300);
  }

  componentDidMount() {
    this.setListInUrlIfEmpty();

    const { list } = queryString.parse(this.props.location.search);
    if (this.props.queryParameters?.sort == null) {
      this.props.addQueryParameter({
        sort: list === LIST_TYPES.Completed ? 'completed_date' : 'due_date',
        'sort-order': list === LIST_TYPES.Completed ? 'desc' : 'asc',
      });
    }

    const { canCarryOutWorkOrders } = this.props;
    const setAssignedToMeAsDefault =
      canCarryOutWorkOrders && localStorage.getItem('assigned_to_me_filter') === 'true';
    if (setAssignedToMeAsDefault) {
      this.addQueryParameter({ assigned_to_me: true });
    }
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.work-orders.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.WorkOrders);
    this.props.getWorkOrderListCounts(this.props.system.id, {
      assigned_to_me: setAssignedToMeAsDefault === true ? true : null,
    });

    this.props.resetSelectedWorkOrders();
    const { search } = this.props.queryParameters;
    this.setState({ searchTerm: search || '' });
    if (list === LIST_TYPES.Calendar) {
      const { fromDate, endDate } = this.state;
      this.fetchInstancesForDates(fromDate, endDate);
    } else if (list != null) {
      this.fetchWorkOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showFilterModal } = this.state;
    const sort = this.props.queryParameters['sort'];
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list: newList } = queryString.parse(this.props.location.search);
    let changedQueryParams = !isEqual(prevProps.queryParameters, this.props.queryParameters);
    let changedAdvancedFilters = !isEqual(prevProps.filters, this.props.filters);
    let changedList = oldList !== newList;
    const changedAssetDropdownParams = prevProps.assetDropdownParams !== this.props.assetDropdownParams;
    const changedOperatorCheckedInAssetId =
      prevProps.operatorCheckedInAssetId !== this.props.operatorCheckedInAssetId;

    if (
      HelperFunctions.onlyPageWasChangedFromQueryParams(prevProps.queryParameters, this.props.queryParameters)
    ) {
      if (this.props.totalEntriesIsSelected === false) {
        this.props.hideSelectTotalEntries();
      }
      this.fetchWorkOrders();
      return;
    }

    const runFetch =
      changedQueryParams ||
      changedList ||
      changedAssetDropdownParams ||
      changedOperatorCheckedInAssetId ||
      (changedAdvancedFilters && showFilterModal === false);

    if (changedList) {
      const sort = this.getParamsForList(newList)['sort'];
      const sortOrder = this.getParamsForList(newList)['sort-order'];
      this.props.addQueryParameter({ sort: sort, 'sort-order': sortOrder });
    }
    if (runFetch) {
      this.props.resetSelectedWorkOrders();
      if (changedOperatorCheckedInAssetId || changedAssetDropdownParams || changedList) {
        this.addQueryParameter({ page: 1, has_meter: null, meter_value_reached: null });
      }
      const { date } = this.props.queryParameters;
      if (newList === LIST_TYPES.Calendar) {
        const fromDate = this.getStartDateFromDate(date);
        const endDate = this.getEndDateFromDate(date);
        const firstDateOfMonth = this.getFirstDateOfMonth(date);
        this.setState({ fromDate, endDate, firstDateOfMonth });
        this.fetchInstancesForDates(fromDate, endDate);
      } else {
        this.fetchWorkOrders();
      }
    }
  }

  setListInUrlIfEmpty = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list == null) {
      this.props.history.replace(
        `?${SDKHelperFunctions.convertObjToQueryParameters({
          list: LIST_TYPES.Upcoming,
        })}`
      );
    }
  };

  transformQueryParameters = () => {
    const { has_meter } = this.props.queryParameters;
    let queryParams = {
      ...this.props.queryParameters,
      has_meter: null,
    };

    /*
      Meter
    */
    if (has_meter) {
      queryParams = {
        ...queryParams,
        meter_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: has_meter,
        },
      };
    }
    return queryParams;
  };

  /*
    Requests
  */

  fetchWorkOrders = () => {
    const { list } = queryString.parse(this.props.location.search);
    let params = {
      ...this.props.filtersAsQueryParams,
      ...this.getParamsForList(list),
      ...this.transformQueryParameters(),
      ...this.getAssetParamsForProductionSupervisor(),
      ...this.getOperatorAssetParams(),
    };
    listWorkOrdersRequest.cancel();
    this.props.setLoading();
    this.fetchDebouncedWorkOrders(params);
  };

  fetchInstancesForDates = (fromDate, toDate, params = {}, options) => {
    listWorkOrdersRequest.cancel();
    let attrs = {
      ...this.props.filtersAsQueryParams,
      ...this.transformQueryParameters(),
      ...this.getAssetParamsForProductionSupervisor(),
      ...this.getOperatorAssetParams(),
      ...params,
    };

    this.props.fetchInstancesForDates(
      this.props.system.id,
      {
        from: fromDate,
        to: toDate,
        ...omit({ ...attrs }, ['list', 'date']),
      },
      options,
      listWorkOrdersRequest.getCancelTokenConfig()
    );
  };

  /*
    Helper functions
  */

  getStartDateFromDate = date => {
    if (date == null) {
      return moment().startOf('month').isoWeekday(1).format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').isoWeekday(1).format('YYYY-MM-DD');
  };

  getEndDateFromDate = date => {
    if (date == null) {
      return moment().endOf('month').isoWeekday(7).format('YYYY-MM-DD');
    }
    return moment(date).endOf('month').isoWeekday(7).format('YYYY-MM-DD');
  };

  getFirstDateOfMonth = date => {
    if (date == null) {
      return moment().startOf('month').format('YYYY-MM-DD');
    }
    return moment(date).startOf('month').format('YYYY-MM-DD');
  };

  getParamsForList = list => {
    switch (list) {
      case LIST_TYPES.Completed: {
        return {
          status: WorkOrderStatus.Completed,
          sort: 'completed_date',
          'sort-order': 'desc',
        };
      }
      case LIST_TYPES.Upcoming: {
        return {
          list: 'upcoming',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case LIST_TYPES.Overdue: {
        return {
          list: 'overdue',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case LIST_TYPES.Today: {
        return {
          list: 'today',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case LIST_TYPES.ThisWeek: {
        return {
          list: 'this_week',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case LIST_TYPES.ThisMonth: {
        return {
          list: 'this_month',
          sort: 'due_date',
          'sort-order': 'asc',
        };
      }
      case LIST_TYPES.AwaitingSpareParts: {
        return {
          list: 'awaiting_spare_parts',
        };
      }

      default:
        return {
          sort: 'created_at',
          'sort-order': 'asc',
        };
    }
  };

  getAssetParamsForProductionSupervisor = () => {
    if (this.props.isProductionSupervisor && this.props.assetDropdownParams) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: this.props.assetDropdownParams,
        },
      };
    }
    return null;
  };

  getOperatorAssetParams = () => {
    if (this.props.isOperator && this.props.operatorCheckedInAssetId) {
      return {
        asset_with_tree_children_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.Any]: this.props.operatorCheckedInAssetId,
        },
      };
    }
    return null;
  };

  getLinkForListType = list => {
    return `/work-orders?list=${list}`;
  };

  addQueryParameter = params => {
    this.props.addQueryParameter({ page: 1, ...params });
  };

  showFilter = () => {
    this.setState({ showFilterModal: true });
  };

  createWorkOrder = params => {
    this.setState({ newWorkOrderParams: params, showNewWorkOrderModal: true });
  };

  goToPreviousMonth = () => {
    const { date: urlDate } = this.props.queryParameters;
    let date = urlDate == null ? moment() : moment(urlDate);
    const newDate = date.subtract(1, 'months').format('YYYY-MM-DD');
    this.addQueryParameter({ date: newDate });
  };

  goToNextMonth = () => {
    const { date: urlDate } = this.props.queryParameters;
    let date = urlDate == null ? moment() : moment(urlDate);
    const newDate = date.add(1, 'months').format('YYYY-MM-DD');
    this.addQueryParameter({ date: newDate });
  };

  getSearchPlaceHolderForList = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.Calendar:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.calendar',
        });

      case LIST_TYPES.Upcoming:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.upcoming',
        });

      case LIST_TYPES.Today:
        return this.props.intl.formatMessage({ id: 'screens.work-orders.search-placeholder-for-list.today' });

      case LIST_TYPES.ThisWeek:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.this-week',
        });

      case LIST_TYPES.ThisMonth:
        return this.props.intl.formatMessage(
          {
            id: 'screens.work-orders.search-placeholder-for-list.this-month',
          },
          {
            month: moment().format('MMMM').toLowerCase(),
          }
        );

      case LIST_TYPES.Overdue:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.overdue',
        });

      case LIST_TYPES.AwaitingSpareParts:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.awaiting-spare-parts',
        });

      case LIST_TYPES.Completed:
        return this.props.intl.formatMessage({
          id: 'screens.work-orders.search-placeholder-for-list.completed',
        });

      default:
        return this.props.intl.formatMessage({ id: 'screens.work-orders.search-placeholder-for-list.all' });
    }
  };

  isFiltering = () => {
    const { isFiltering, queryParameters } = this.props;
    return isFiltering || queryParameters.search?.length > 0;
  };

  getSortOptions = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Completed) {
      return [
        { label: <FormattedMessage id="resources.work-order.title" />, sort: 'title' },
        { label: <FormattedMessage id="resources.work-order.priority" />, sort: 'priority' },
        {
          label: <FormattedMessage id="resources.work-order.completed-at" />,
          sort: 'completed_date',
        },
        {
          label: <FormattedMessage id="resources.asset.resource" />,
          sort: 'asset_title',
        },
        {
          label: <FormattedMessage id="resources.work-order.work-order-type" />,
          sort: 'work_order_type_title',
        },
        { label: <FormattedMessage id="resources.work-order.created-at" />, sort: 'created_at' },
      ];
    }
    return [
      { label: <FormattedMessage id="resources.work-order.title" />, sort: 'title' },
      { label: <FormattedMessage id="resources.work-order.priority" />, sort: 'priority' },
      { label: <FormattedMessage id="resources.work-order.due-date" />, sort: 'due_date' },
      {
        label: <FormattedMessage id="resources.asset.resource" />,
        sort: 'asset_title',
      },
      {
        label: <FormattedMessage id="resources.work-order.work-order-type" />,
        sort: 'work_order_type_title',
      },
      { label: <FormattedMessage id="resources.work-order.created-at" />, sort: 'created_at' },
    ];
  };

  /*
    Render functions
  */

  renderListHeader = () => {
    const { totalEntriesIsSelected, loading, pageIsSelected } = this.props;
    return (
      <List.Header
        small
        expandable
        background
        checkbox
        showMultipleOptions={this.props.selectedWorkOrdersCount > 0}
        multipleOptionsComponent={
          <List.Header.MultipleOptions
            loading={this.props.loading}
            count={this.props.selectedWorkOrdersCount}
            buttons={
              <>
                {this.props.canAdministrateWorkOrders ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.work-orders.header-buttons.edit" />}
                    onClick={() => this.setState({ showMassEditModal: true })}
                  />
                ) : null}
                <List.Header.MultipleOptions.Button
                  label={<FormattedMessage id="general.print" />}
                  onClick={() =>
                    this.setState({
                      showPrintModal: true,
                    })
                  }
                />
                {this.props.canAdministrateWorkOrders ? (
                  <List.Header.MultipleOptions.Button
                    label={<FormattedMessage id="screens.work-orders.header-buttons.export" />}
                    onClick={() =>
                      this.setState({
                        showExportModal: true,
                        showExportModalForType: ExportType.WorkOrdersXslx,
                      })
                    }
                  />
                ) : null}
              </>
            }
          />
        }
        checked={loading === false && (pageIsSelected === true || totalEntriesIsSelected === true)}
        onCheck={() => {
          if (loading) {
            return;
          }
          if (totalEntriesIsSelected) {
            this.props.resetSelectedWorkOrders();
          } else {
            this.props.selectPage();
          }
        }}
      >
        {this.renderListHeaderColumns()}
      </List.Header>
    );
  };

  renderListHeaderColumns = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.AwaitingSpareParts: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.work-order.title" />
            </List.Header.Column>
            <List.Header.Column width={180}>
              <FormattedMessage id="resources.purchase-order.resource" />
            </List.Header.Column>
            <List.Header.Column width={180} alignRight>
              <FormattedMessage id="resources.purchase-order.expected-delivery-date" />
            </List.Header.Column>
          </>
        );
      }
      case LIST_TYPES.Completed: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.work-order.title" />
            </List.Header.Column>
            <List.Header.Column width={140}>
              <FormattedMessage id="resources.work-order.completed-at" />
            </List.Header.Column>
            <List.Header.Column width={72}>
              <FormattedMessage id="resources.work-order.assignee" />
            </List.Header.Column>
            <List.Header.Column width={45} />
          </>
        );
      }
      default: {
        return (
          <>
            <List.Header.Column flex>
              <FormattedMessage id="resources.work-order.title" />
            </List.Header.Column>
            <List.Header.Column width={180}>
              <FormattedMessage id="resources.work-order.due-date" />
            </List.Header.Column>
            <List.Header.Column width={72}>
              <FormattedMessage id="resources.work-order.assignee" />
            </List.Header.Column>
            <List.Header.Column width={45} alignRight />
          </>
        );
      }
    }
  };

  setNavigatedTo = () => {
    const { list } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
      },
    });
  };

  renderList = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (this.props.loading) {
      const amountOfWorkOrders = this.props.workOrders.length === 0 ? 2 : this.props.workOrders.length;
      return (
        <>
          {this.renderListHeader()}
          <List>
            {this.renderSelectTotalEntries()}
            {Array(amountOfWorkOrders)
              .fill()
              .map((_, key) => (
                <WorkOrderListItem key={key} loading />
              ))}
          </List>
        </>
      );
    }
    if (this.props.workOrders.length === 0) {
      if (this.isFiltering()) {
        return this.renderSearchedEmptyDataset();
      }
      return this.renderEmptyDataset();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.renderSelectTotalEntries()}
          {this.props.workOrders.map(workOrder => {
            let checked = false;
            if (this.props.selectedWorkOrderIds[workOrder.id] === true) {
              checked = true;
            }
            if (this.props.totalEntriesIsSelected) {
              checked = true;
            }
            return (
              <WorkOrderListItem
                list={list}
                key={workOrder.id}
                id={workOrder.id}
                checked={checked}
                checkboxDisabled={this.props.totalEntriesIsSelected}
                onClick={() => this.setNavigatedTo()}
                onCheck={e => {
                  this.props.selectWorkOrder(workOrder.id);
                }}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderSelectTotalEntries = () => {
    if (this.props.showSelectTotalEntries) {
      return (
        <List.SelectTotalEntries
          loading={this.props.loading}
          selected={this.props.totalEntriesIsSelected}
          selectedCount={this.props.workOrders.length}
          totalEntriesCount={this.props.pagination.totalEntries}
          onSelectAll={() => this.props.selectTotalEntries()}
          onDeselectAll={() => this.props.resetSelectedWorkOrders()}
        />
      );
    }
    return null;
  };

  renderEmptyDataSetTitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { assigned_to_me } = this.props.queryParameters;

    if (this.props.isFiltering) return <FormattedMessage id="general.empty-data-set-search.title" />;

    switch (list) {
      case LIST_TYPES.Upcoming: {
        if (assigned_to_me === true) {
          return <FormattedMessage id="screens.work-orders.empty-data-sets.upcoming.assigned-to-me.title" />;
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.upcoming.all.title" />;
      }
      case LIST_TYPES.Completed: {
        if (assigned_to_me === true) {
          return <FormattedMessage id="screens.work-orders.empty-data-sets.completed.assigned-to-me.title" />;
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.completed.all.title" />;
      }
      case LIST_TYPES.Today: {
        if (assigned_to_me === true) {
          return <FormattedMessage id="screens.work-orders.empty-data-sets.today.assigned-to-me.title" />;
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.today.all.title" />;
      }
      case LIST_TYPES.ThisWeek: {
        if (assigned_to_me === true) {
          return <FormattedMessage id="screens.work-orders.empty-data-sets.this-week.assigned-to-me.title" />;
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.this-week.all.title" />;
      }
      case LIST_TYPES.ThisMonth: {
        if (assigned_to_me === true) {
          return (
            <FormattedMessage id="screens.work-orders.empty-data-sets.this-month.assigned-to-me.title" />
          );
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.this-month.all.title" />;
      }
      default: {
        if (assigned_to_me === true) {
          return <FormattedMessage id="screens.work-orders.empty-data-sets.all.assigned-to-me.title" />;
        }
        return <FormattedMessage id="screens.work-orders.empty-data-sets.all.all.title" />;
      }
    }
  };

  renderEmptyDataSetSubtitle = () => {
    if (this.props.isFiltering) {
      return <FormattedMessage id="general.empty-data-set-search.subtitle" />;
    } else {
      return <FormattedMessage id="screens.work-orders.empty-data-sets.subtitle" />;
    }
  };

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
        <div className={styles['subtitle']}>{this.renderEmptyDataSetSubtitle()}</div>
        <div className={styles['image-container']}>
          <img src={WorkOrdersImage} alt="" />
        </div>
      </div>
    );
  };

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={WorkOrdersImage} alt="" />
      </div>
    </div>
  );

  isHideCompletedCheckboxChecked = () => {
    if (this.props.filters.status) {
      const { status } = this.props.filters;
      const { comparator, value } = status;
      let checked = false;
      if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.NoneOf) {
        if (
          !value.includes(WorkOrderStatus.NotStarted) &&
          !value.includes(WorkOrderStatus.InProgress) &&
          !value.includes(WorkOrderStatus.Paused) &&
          value.includes(WorkOrderStatus.Completed)
        ) {
          checked = true;
        }
      }
      return checked;
    }
    return false;
  };

  renderHideCompletedWorkOrdersCheckbox = () => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.Today:
      case LIST_TYPES.ThisWeek:
      case LIST_TYPES.ThisMonth:
        return (
          <Field.Checkbox
            checked={this.isHideCompletedCheckboxChecked()}
            label={<FormattedMessage id="screens.work-orders.hide-completed" />}
            onChange={() => {
              this.props.setLoading();
              if (this.isHideCompletedCheckboxChecked()) {
                this.props.removeFilter({
                  key: 'status',
                  data: {
                    status: {
                      comparator: SDKHelperFunctions.FILTER_COMPARABLES.Any,
                      value: [],
                    },
                  },
                });
              } else {
                this.props.addFilter({
                  key: 'status',
                  data: {
                    status: {
                      comparator: SDKHelperFunctions.FILTER_COMPARABLES.NoneOf,
                      value: [WorkOrderStatus.Completed],
                    },
                  },
                });
              }
              this.fetchWorkOrders();
            }}
          />
        );
      default:
        return null;
    }
  };

  renderCalendarMonthNavigation = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Calendar) {
      const { date: dateUrlParam } = this.props.queryParameters;
      const date = dateUrlParam == null ? moment() : moment(dateUrlParam);
      return (
        <div className={styles['month-navigation']}>
          <div className={styles['navigation-container']}>
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-left" />}
              onClick={this.goToPreviousMonth}
            />
            <Button
              type="icon"
              icon={<Icon regular size={18} type="angle-right" />}
              onClick={this.goToNextMonth}
            />
          </div>
          <div className={styles['month']}>{`${date.format('MMMM')} ${date.format('YYYY')}`}</div>
        </div>
      );
    }
    return null;
  };

  renderMeterBasedWorkOrdersFilterButton = () => {
    const { list } = queryString.parse(this.props.location.search);

    if (this.props.isOperator) return null;
    if (!this.props.settings.meters_activated) return null;
    if (list !== LIST_TYPES.Upcoming) return null;

    const { meter_value_reached, has_meter } = this.props.queryParameters;
    return (
      <div className={styles['filter-buttons']}>
        <div
          ref={ref => (this.meterBasedFilterPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showMeterBasedInlineModal: !prevState.showMeterBasedInlineModal,
            }));
          }}
        >
          <FilterButton
            label={<FormattedMessage id="screens.work-orders.filters.meter-based.title" />}
            filtered={meter_value_reached || has_meter}
            onClear={e => {
              e.stopPropagation();
              this.addQueryParameter({ meter_value_reached: null, has_meter: null });
            }}
          />
        </div>
        <NewInlineModal
          minWidth={267}
          positionToRef={this.meterBasedFilterPositioningRef}
          open={this.state.showMeterBasedInlineModal}
          onClose={() => {
            this.setState({ showMeterBasedInlineModal: false });
          }}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={meter_value_reached === true}
                    onChange={() => {
                      if (meter_value_reached === true) {
                        this.addQueryParameter({ meter_value_reached: null });
                      } else {
                        this.addQueryParameter({ has_meter: null, meter_value_reached: true });
                      }
                      this.setState({ showMeterBasedInlineModal: false });
                    }}
                  />
                }
                onClick={() => {
                  if (meter_value_reached === true) {
                    this.addQueryParameter({ meter_value_reached: null });
                  } else {
                    this.addQueryParameter({ has_meter: null, meter_value_reached: true });
                  }
                  this.setState({ showMeterBasedInlineModal: false });
                }}
              >
                <FormattedMessage id="screens.work-orders.filters.meter-based.show-within-tolerance" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={has_meter === true}
                    onChange={() => {
                      if (has_meter === true) {
                        this.addQueryParameter({ has_meter: null });
                      } else {
                        this.addQueryParameter({ meter_value_reached: null, has_meter: true });
                      }
                      this.setState({ showMeterBasedInlineModal: false });
                    }}
                  />
                }
                onClick={() => {
                  if (has_meter === true) {
                    this.addQueryParameter({ has_meter: null });
                  } else {
                    this.addQueryParameter({ meter_value_reached: null, has_meter: true });
                  }
                  this.setState({ showMeterBasedInlineModal: false });
                }}
              >
                <FormattedMessage id="screens.work-orders.filters.meter-based.show-all" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderToolbar = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.MainContent.FilterBar noMaxWidth={list === LIST_TYPES.Calendar}>
        <ListLayout.Content.MainContent.FilterBar.LeftContent>
          {this.renderCalendarMonthNavigation()}
          {this.renderHideCompletedWorkOrdersCheckbox()}
          {this.renderMeterBasedWorkOrdersFilterButton()}
        </ListLayout.Content.MainContent.FilterBar.LeftContent>
        <ListLayout.Content.MainContent.FilterBar.RightContent>
          {list !== LIST_TYPES.Calendar ? (
            <SortingButton
              sortOptions={this.getSortOptions()}
              sort={this.props.queryParameters.sort}
              sortOrder={this.props.queryParameters['sort-order']}
              onSort={sort => {
                this.addQueryParameter(sort);
              }}
            />
          ) : null}

          <FilterButton.Group>
            {this.props.isFiltering ? (
              <FilterButton
                clearable={false}
                caret={false}
                label={<FormattedMessage id="general.clean" />}
                onClick={() => {
                  this.props.resetFilter();
                  this.props.setLoading();
                  this.fetchWorkOrders();
                }}
              />
            ) : null}

            <FilterButton
              clearable={false}
              caret={false}
              filtered={this.props.isFiltering}
              label={<FormattedMessage id="screens.work-orders.filter" />}
              onClick={this.showFilter}
            />
          </FilterButton.Group>
        </ListLayout.Content.MainContent.FilterBar.RightContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderPagination = () => {
    if (this.props.workOrders.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.props.queryParameters.page}
          totalPages={this.props.pagination.totalPages}
          pageSize={this.props.queryParameters.page_size}
          onSelectPage={page => {
            this.addQueryParameter({ page });
          }}
          onChangePageSize={page_size => {
            this.addQueryParameter({ page_size });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderMainContent = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === LIST_TYPES.Calendar) {
      return (
        <ListLayout.Content.MainContent>
          {this.renderToolbar()}
          <Calendar
            fromDate={this.state.fromDate}
            endDate={this.state.endDate}
            firstDateOfMonth={this.state.firstDateOfMonth}
            onCreateWorkOrder={params => this.createWorkOrder(params)}
          />
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderToolbar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderAssignedToMe = () => {
    const { assigned_to_me } = this.props.queryParameters;
    if (this.props.canAdministrateWorkOrders) {
      return (
        <div className={styles['assigned-to-me']}>
          <div className={styles['text']}>
            <FormattedMessage id="screens.work-orders.only-assigned-to-me" />
          </div>
          <div>
            <Toggle
              checked={assigned_to_me === true}
              onClick={() => {
                this.addQueryParameter({ assigned_to_me: assigned_to_me === true ? null : true });
                if (assigned_to_me === true) {
                  localStorage.removeItem('assigned_to_me_filter');
                } else {
                  localStorage.setItem('assigned_to_me_filter', true);
                }
                listWorkOrderCountsRequest.cancel();
                this.props
                  .getWorkOrderListCounts(
                    this.props.system.id,
                    {
                      assigned_to_me: assigned_to_me === true ? null : true,
                    },
                    listWorkOrderCountsRequest.getCancelTokenConfig()
                  )
                  .catch(e => {});
              }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  renderCreateButton = () => {
    if (this.props.canCarryOutWorkOrders) {
      return (
        <>
          <Button fullWidth primary label="screens.work-orders.new" onClick={() => this.createWorkOrder()} />
          <Menu.Separator />
        </>
      );
    }
    return null;
  };

  renderLeftMenu = () => {
    const {
      isFetchingCounts,
      location: { search },
      workOrderListCounts: { awaiting_spare_parts, overdue, this_month, this_week, today },
    } = this.props;
    const { list } = queryString.parse(search);

    return (
      <ListLayout.Content.Menu>
        <PerfectScrollbar>
          <div className={styles['left-panel-container']}>
            <div className={styles['list-buttons']}>
              <ListLayout.Content.Menu.Content>
                {this.renderCreateButton()}
                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.Upcoming)}
                  title={<FormattedMessage id="screens.work-orders.lists.upcoming" />}
                  selected={list === LIST_TYPES.Upcoming}
                />
                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.Today)}
                  title={<FormattedMessage id="screens.work-orders.lists.today" />}
                  selected={list === LIST_TYPES.Today}
                  number={isFetchingCounts ? null : today}
                />
                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.ThisWeek)}
                  title={
                    <div className={styles['text']}>
                      <FormattedMessage id="screens.work-orders.lists.this-week" />
                      <span className={styles['week']}>
                        <span> - </span>
                        <FormattedMessage
                          id="screens.work-orders.week-number"
                          values={{ week: moment().format('W') }}
                        />
                      </span>
                    </div>
                  }
                  selected={list === LIST_TYPES.ThisWeek}
                  number={isFetchingCounts ? null : this_week}
                />
                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.ThisMonth)}
                  title={
                    <div className={styles['text']}>
                      <span>{moment().format('MMMM')}</span>
                    </div>
                  }
                  selected={list === LIST_TYPES.ThisMonth}
                  number={isFetchingCounts ? null : this_month}
                />

                {overdue > 0 ? (
                  <Menu.Item
                    red
                    linkTo={this.getLinkForListType(LIST_TYPES.Overdue)}
                    title={<FormattedMessage id="screens.work-orders.lists.overdue" />}
                    selected={list === LIST_TYPES.Overdue}
                    number={isFetchingCounts ? null : overdue}
                  />
                ) : null}
                {awaiting_spare_parts > 0 ? (
                  <Menu.Item
                    linkTo={this.getLinkForListType(LIST_TYPES.AwaitingSpareParts)}
                    title={<FormattedMessage id="screens.work-orders.lists.awaiting-spare-parts" />}
                    selected={list === LIST_TYPES.AwaitingSpareParts}
                    number={isFetchingCounts ? null : awaiting_spare_parts}
                  />
                ) : null}

                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.Completed)}
                  title={<FormattedMessage id="screens.work-orders.lists.completed" />}
                  selected={list === LIST_TYPES.Completed}
                />

                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.Calendar)}
                  title={<FormattedMessage id="screens.work-orders.lists.calendar" />}
                  selected={list === LIST_TYPES.Calendar}
                />

                <Menu.Separator />
                <Menu.Item
                  linkTo={this.getLinkForListType(LIST_TYPES.All)}
                  title={<FormattedMessage id="screens.work-orders.lists.all" />}
                  selected={list === LIST_TYPES.All}
                />
              </ListLayout.Content.Menu.Content>
            </div>
            {this.renderAssignedToMe()}
          </div>
        </PerfectScrollbar>
      </ListLayout.Content.Menu>
    );
  };

  renderBookmarkedAssetsDropdown = () => {
    if (!this.props.isProductionSupervisor) return null;
    return <BookmarkedAssetsDropdown />;
  };

  renderHeader = () => {
    const { pagination, loading, setLoading, isFiltering } = this.props;
    const totalWorkOrders = loading && !isFiltering ? 0 : pagination.totalEntries;

    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.work-orders.title" />}
        leftContainer={this.renderBookmarkedAssetsDropdown()}
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.getSearchPlaceHolderForList()}
        totalEntries={
          <FormattedMessage
            id="screens.work-orders.total-entries"
            values={{
              amount: totalWorkOrders,
            }}
          />
        }
        onSearch={searchTerm => {
          setLoading();
          this.setState({ searchTerm });
        }}
        onDebouncedSearch={() => {
          this.addQueryParameter({ search: this.state.searchTerm });
        }}
        onClearSearch={() => {
          setLoading();
          this.setState({ searchTerm: '' }, () => {
            this.addQueryParameter({ search: '' });
          });
        }}
      />
    );
  };

  renderMassEditModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <MassEditModal
        open={this.state.showMassEditModal}
        listParams={this.getParamsForList(list)}
        onSave={() => {
          this.props.resetSelectedWorkOrders();
          this.props.setLoading();
          this.fetchWorkOrders();
          this.setState({ showMassEditModal: false });
        }}
        onClose={() => {
          this.setState({ showMassEditModal: false });
        }}
      />
    );
  };

  renderExportModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ExportModal
        open={this.state.showExportModal}
        listParams={this.getParamsForList(list)}
        onClose={() => {
          this.setState({ showExportModal: false });
        }}
      />
    );
  };

  renderPrintModal = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <PrintModal
        open={this.state.showPrintModal}
        listParams={this.getParamsForList(list)}
        onClose={() => {
          this.setState({ showPrintModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        <NewWorkOrderModal
          open={this.state.showNewWorkOrderModal}
          defaultParams={this.state.newWorkOrderParams}
          onClose={() => {
            this.setState({ newWorkOrderParams: {}, showNewWorkOrderModal: false });
          }}
          onCreated={workOrder => {
            this.setNavigatedTo();
            this.props.resetSelectedWorkOrders();
            this.setState({ showNewWorkOrderModal: false, newWorkOrderParams: {} });
            setTimeout(() => {
              this.props.history.push(`/work-orders/${workOrder.id}`);
            }, 250);
          }}
          onCreatedWithReopen={workOrder => {
            this.props.resetSelectedWorkOrders();
            const { list } = this.props.queryParameters;
            if (list === LIST_TYPES.Calendar && workOrder.recurring_maintenance_id) {
              const { fromDate, endDate } = this.state;
              this.fetchInstancesForDates(
                fromDate,
                endDate,
                {
                  recurring_maintenance_id: workOrder.recurring_maintenance_id,
                },
                { resetCalendar: false }
              );
            }
            this.setState({ showNewWorkOrderModal: false });
            setTimeout(() => {
              this.setState({ showNewWorkOrderModal: true });
            }, 400);
          }}
        />
        <FilterModal
          open={this.state.showFilterModal}
          onClose={params => {
            const { list } = queryString.parse(this.props.location.search);
            if (list === LIST_TYPES.Calendar) {
              this.props.setLoading();
              const { fromDate, endDate } = this.state;
              this.fetchInstancesForDates(fromDate, endDate, params);
              this.setState({ showFilterModal: false });
            } else {
              this.props.resetSelectedWorkOrders();
              this.props.setLoading();
              this.fetchWorkOrders(params);
              this.setState({ showFilterModal: false });
            }
          }}
        />
        {this.renderMassEditModal()}
        {this.renderPrintModal()}
        {this.renderExportModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSelectedWorkOrders: WorkOrdersOperations.resetSelectedWorkOrders,
      selectPage: WorkOrdersOperations.selectPage,
      selectMenuItem: MenuOperations.selectItem,
      setLoading: WorkOrdersOperations.setLoading,
      addFilter: WorkOrdersOperations.addFilter,
      removeFilter: WorkOrdersOperations.removeFilter,
      resetFilter: WorkOrdersOperations.resetFilter,
      getWorkOrderListCounts: WorkOrdersOperations.getWorkOrderListCounts,
      fetchWorkOrders: WorkOrdersOperations.fetchWorkOrders,
      fetchInstancesForDates: WorkOrdersOperations.fetchInstancesForDates,
      addQueryParameter: WorkOrdersOperations.addQueryParameter,
      selectWorkOrder: WorkOrdersOperations.selectWorkOrder,
      selectTotalEntries: WorkOrdersOperations.selectTotalEntries,
      hideSelectTotalEntries: WorkOrdersOperations.hideSelectTotalEntries,
      setNavigatedTo: WorkOrdersOperations.setNavigatedTo,
      resetState: WorkOrdersOperations.resetState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    isOperator: AuthSelectors.isOperator(state),
    workOrders: WorkOrdersSelectors.getWorkOrders(state),
    loading: WorkOrdersSelectors.isFetchingWorkOrders(state),
    pagination: WorkOrdersSelectors.getWorkOrderListPagination(state),
    filtersAsQueryParams: WorkOrdersSelectors.getFiltersAsQueryParams(state),
    filters: WorkOrdersSelectors.getFilters(state),
    queryParameters: WorkOrdersSelectors.getQueryParameters(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    operatorCheckedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    isFiltering: WorkOrdersSelectors.isFiltering(state),
    workOrderListCounts: WorkOrdersSelectors.getWorkOrderListCounts(state),
    isFetchingCounts: WorkOrdersSelectors.isFetchingCounts(state),
    selectedWorkOrderIds: WorkOrdersSelectors.getSelectedWorkOrderIds(state),
    selectedWorkOrdersCount: WorkOrdersSelectors.getSelectedWorkOrdersCount(state),
    totalEntriesIsSelected: WorkOrdersSelectors.getTotalEntriesIsSelected(state),
    showSelectTotalEntries: WorkOrdersSelectors.getShowSelectTotalEntries(state),
    pageIsSelected: WorkOrdersSelectors.getPageIsSelected(state),
    navigatedTo: WorkOrdersSelectors.getNavigatedTo(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WorkOrderList));
