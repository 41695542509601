import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import styles from './style.module.scss';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';

class Header extends Component {
  state = {
    isSaving: false,
  };

  hasErrors = () => {
    let hasErrors = false;
    if (this.props.editingOperationalMaintenanceTemplateVersion.title.length === 0) {
      this.props.setErrors({ title: true });
      hasErrors = true;
    }
    return hasErrors;
  };

  save = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSaving: true });
    const { title, description, sparePartReservations, checklists, attachments, images, estimated_minutes } =
      this.props.editingOperationalMaintenanceTemplateVersion;
    if (this.props.operationalMaintenanceTemplateId) {
      this.props
        .createOperationalTemplateVersionForTemplate(this.props.operationalMaintenanceTemplateId, {
          title,
          description,
          checklist_template_links: checklists.map(({ checklist_template_id }) => ({
            checklist_template_id,
          })),
          attachments: attachments.map(({ attachment_id }) => attachment_id),
          images: images.map(({ id }) => id),
          spare_part_reservations: sparePartReservations,
          from_date: moment().format('YYYY-MM-DD'),
          estimated_minutes,
        })
        .then(({ data }) => {
          this.props.onCreated(data.id);
        });
    } else {
      this.props
        .createOperationalMaintenanceTemplate(this.props.system.id, {
          title,
          operational_maintenance_template_versions: [
            {
              title,
              description,
              checklist_template_links: checklists.map(({ checklist_template_id }) => ({
                checklist_template_id,
              })),
              attachments: attachments.map(({ attachment_id }) => attachment_id),
              images: images.map(({ id }) => id),
              spare_part_reservations: sparePartReservations,
              estimated_minutes,
            },
          ],
        })
        .then(({ data }) => {
          this.props.onCreated(data.id);
        });
    }
  };

  renderTitle = () => {
    if (this.props.operationalMaintenanceTemplateId) {
      return <FormattedMessage id="components.edit-operational-maintenance-template.edit-title" />;
    }
    return <FormattedMessage id="components.edit-operational-maintenance-template.new-title" />;
  };

  render() {
    return (
      <>
        <div className={styles['header-wrapper']}>
          <div className={styles['header']}>
            <div className={styles['title-container']}>
              <div className={styles['title']}>{this.renderTitle()}</div>
            </div>
            <div className={styles['buttons']}>
              <div className={styles['button']}>
                <Button.Group>
                  <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
                  <Button label="general.cancel" onClick={this.props.onClose} />
                </Button.Group>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createOperationalMaintenanceTemplate: SDKReduxOperations.createOperationalMaintenanceTemplate,
      createOperationalTemplateVersionForTemplate:
        SDKReduxOperations.createOperationalTemplateVersionForTemplate,
      setErrors: EditOperationalMaintenanceTemplateOperations.setErrors,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
