import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { NewInlineModal, Loader } from 'views/components/Shared/General';
import { API } from 'sdk';
import { normalizeUser } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import UserDropdownItem from './UserDropdownItem';
import { EntitySelectors } from 'sdk/State/entities';

class AssigneeDropdown extends Component {
  state = {
    isFetching: false,
    users: [],
    searchTerm: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      API.listUsers(this.props.system.id, {
        no_pagination: true,
        administrate_checklist_instance_deviations: true,
        archived: false,
      }).then(({ data: users }) => {
        const { entities: userEntities, result: userIds } = normalizeUser(users);
        this.props.updateEntities(userEntities);
        this.setState({ isFetching: false, users: userIds.map(id => userEntities.userById[id]) });
      });
    } else if (!this.props.open && prevProps.open) {
      this.setState({ users: [] });
    }
    if (prevState.isFetching && !this.state.isFetching && this.inlineModalSearchRef) {
      this.inlineModalSearchRef.focus();
    }
  }

  renderUsers = () => {
    const { searchTerm } = this.state;
    const filteredUsers = this.state.users.filter(
      user => searchTerm === '' || user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredUsers.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return filteredUsers.map(({ id }) => (
      <UserDropdownItem
        id={id}
        selected={this.props.userIds.includes(id)}
        loading={this.props.isLoadingUserIds.includes(id)}
        onSelect={() => {
          this.props.onSelectUser(id);
          this.setState({ open: false });
        }}
      />
    ));
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    return (
      <NewInlineModal.Dropdown.Items>
        {this.props.userIds?.length ? (
          <>
            <NewInlineModal.Dropdown.Item
              onClick={e => this.props.onClear()}
              rightComponent={this.props.isClearing ? <Loader tiny /> : null}
            >
              <FormattedMessage id="components.request-assignee-dropdown.clear-assignee" />
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Separator />
          </>
        ) : null}
        {this.renderUsers()}
      </NewInlineModal.Dropdown.Items>
    );
  };

  render() {
    return (
      <>
        <NewInlineModal
          positionToRef={this.props.positionToRef}
          position={this.props.position}
          open={this.props.open}
          onClose={this.props.onClose}
          minWidth={335}
        >
          <>
            <NewInlineModal.Header>
              <NewInlineModal.Header.Search
                ref={ref => (this.inlineModalSearchRef = ref)}
                placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
                value={this.state.searchTerm}
                onChange={searchTerm => {
                  this.setState({ searchTerm });
                }}
                onClear={() => {
                  this.setState({ searchTerm: '' });
                }}
              />
            </NewInlineModal.Header>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </>
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { userId } = ownProps;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    user: EntitySelectors.getUser(state, userId),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssigneeDropdown));

AssigneeDropdown.propTypes = {
  onSelectUser: PropTypes.func,
  loadingUserIds: PropTypes.array,
  userIds: PropTypes.array,
};

AssigneeDropdown.defaultProps = {
  onSelectUser: () => {},
  loadingUserIds: [],
  userIds: [],
};
