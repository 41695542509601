import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ChecklistModalSelectors } from 'state/ducks/checklistModal';
import { PreviewWorkOrderContent } from 'views/components/WorkOrder';
import { Button, Tooltip, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class WorkOrder extends Component {
  renderNavigateToWorkOrderButton = () => {
    if (this.props.isProductionSupervisor) {
      return null;
    }
    return (
      <div>
        <Tooltip
          trigger={
            <Link
              to={{
                pathname: `/work-orders/${this.props.workOrder.id}`,
              }}
            >
              <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
            </Link>
          }
          label={<FormattedMessage id="general.navigate-to.work-order" />}
        />
      </div>
    );
  };
  render() {
    return (
      <div className={styles['container']}>
        <div className={styles['work-order']}>
          <div className={styles['header']}>
            <div className={styles['title-container']} title={this.props.workOrder.title}>
              <div className={styles['title']}>
                {this.props.workOrder.title} - #{this.props.workOrder.number}
              </div>
              {this.renderNavigateToWorkOrderButton()}
            </div>
            {this.props.workOrder.asset_id == null ? null : (
              <TreePath size={13} fullPath assetId={this.props.workOrder.asset_id} />
            )}
          </div>
          <PreviewWorkOrderContent workOrderId={this.props.workOrder.id} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  return {
    checklistInstance,
    workOrder: EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
  };
}

export default connect(mapStateToProps)(WorkOrder);
