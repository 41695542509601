import React, { Component } from 'react';
import { Loader } from 'views/components/Shared/General';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import WorkOrdersImage from 'assets/images/EmptyDataSet/WorkOrders.png';
import {
  AssetDeleteModal,
  AssetDuplicateModal,
  ArchiveAssetModal,
  UnarchiveAssetModal,
} from 'views/components/Asset';
import { PrintQRCodeModal } from 'views/components/General';
import { AssetsSelectors, AssetsOperations } from 'state/ducks/assets';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import styles from './style.module.scss';
import CustomTree from './CustomTree';
import SelectAssetsForQRCodeModal from './SelectAssetsForQRCodeModal';

let scrollTop = null;

class Tree extends Component {
  expandedIds = {};

  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showDeleteModalForAsset: null,
      showArchiveModal: false,
      showArchiveModalForAsset: null,
      showUnarchiveModal: false,
      showUnarchiveModalForAsset: null,
      showDuplicateModalForAsset: null,
      showSelectAssetsModal: false,
      showSelectAssetsModalForAssetId: null,
      amountOfAssetsToPrint: null,
      assetIdsToPrintQRCodesFor: [],
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
    this.expandedIds = JSON.parse(localStorage.getItem('expIds')) || {};

    const { reload } = this.props.location.state || {};
    this.props.addQueryParameter({ page: 1 });
    if (reload === true) {
      this.loadTree();
    } else {
      if (this.props.isEmpty) {
        this.loadTree();
      } else if (this.wrapperRef) {
        this.wrapperRef.scrollTop = scrollTop || 0;
      }
    }
    this.props.history.replace({ ...this.props.history.location, state: null });
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup);
  }

  loadTree = () => {
    this.props.fetchTree(this.props.selectedSystem.id, this.expandedIds);
  };

  componentCleanup = () => {
    localStorage.setItem('expIds', JSON.stringify(this.expandedIds));
  };

  onDragEnd = (source, destination) => {
    if (!destination) {
      return;
    }

    const positionInTreeDidNotChange =
      source.parentId === destination.parentId &&
      (source.index === destination.index || destination.index == null);
    if (positionInTreeDidNotChange) {
      return;
    }
    const assetId = this.props.tree.items[source.parentId].children[source.index];
    this.props.moveTree(assetId, source, destination).then(asset => {
      if (asset.tree_parent_id) {
        const [parentAsset] = asset.tree_path.slice(-1);
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="screens.assets.tree.asset-moved-alert"
                values={{ title: asset.title, parent_title: parentAsset.title }}
              />
            }
          />
        );
      } else {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage
                id="screens.assets.tree.asset-moved-alert-root"
                values={{ title: asset.title }}
              />
            }
          />
        );
      }
    });
  };

  expandTree = nodeId => {
    this.expandedIds[nodeId] = true;
    this.props.expandTree(nodeId);
  };

  collapseTree = nodeId => {
    delete this.expandedIds[nodeId];
    this.props.collapseTree(nodeId);
  };

  getScrollPosition = () => this.wrapperRef.scrollTop;

  setNavigatedTo = () => {
    const { list } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
      },
    });
  };

  renderContent = () => {
    if (this.props.isFetching) {
      return <Loader centerInParent />;
    } else if (this.props.isEmpty) {
      return (
        <div className={styles['empty-data-set-wrapper']}>
          <div className={styles['empty-data-set-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.assets.list.empty-data-set.title" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="screens.assets.list.empty-data-set.subtitle" />
            </div>
            <div className={styles['image-container']}>
              <img src={WorkOrdersImage} alt="" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles['wrapper']} ref={ref => (this.wrapperRef = ref)}>
        <div className={styles['tree-container']}>
          <div className={styles['tree']}>
            <CustomTree
              tree={this.props.tree}
              onItemClicked={() => {
                this.setNavigatedTo();
                scrollTop = this.getScrollPosition();
              }}
              onCreateAssetForAsset={assetId => this.props.onCreateAssetForAsset(assetId)}
              onExpand={this.expandTree}
              onCollapse={this.collapseTree}
              onDragEnd={this.onDragEnd}
              onDelete={asset => this.setState({ showDeleteModal: true, showDeleteModalForAsset: asset })}
              onArchive={asset => this.setState({ showArchiveModal: true, showArchiveModalForAsset: asset })}
              onUnarchive={asset =>
                this.setState({ showUnarchiveModal: true, showUnarchiveModalForAsset: asset })
              }
              onCreateSuccess={nodeId => (this.expandedIds[nodeId] = true)}
              onDuplicate={asset => this.setState({ showDuplicateModalForAsset: asset })}
              onPrintQRCodes={({ id }) => {
                this.setState({
                  showSelectAssetsModalForAssetId: id,
                  showSelectAssetsModal: true,
                });
              }}
              isNestingEnabled
              canEditAssets={this.props.canEditAssets}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderContent()}
        <AssetDeleteModal
          open={this.state.showDeleteModal}
          onClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          asset={this.state.showDeleteModalForAsset}
        />
        <ArchiveAssetModal
          open={this.state.showArchiveModal}
          asset={this.state.showArchiveModalForAsset}
          onSave={() => {
            this.setState({ showArchiveModal: false });
            this.loadTree();
          }}
          onClose={() => this.setState({ showArchiveModal: false })}
        />
        <UnarchiveAssetModal
          open={this.state.showUnarchiveModal}
          asset={this.state.showUnarchiveModalForAsset}
          onSave={() => {
            this.setState({ showUnarchiveModal: false });
            this.loadTree();
          }}
          onClose={() => this.setState({ showUnarchiveModal: false })}
        />
        {this.state.showDuplicateModalForAsset ? (
          <AssetDuplicateModal
            open={this.state.showDuplicateModalForAsset != null}
            asset={this.state.showDuplicateModalForAsset}
            onClose={() => this.setState({ showDuplicateModalForAsset: null })}
          />
        ) : null}
        <SelectAssetsForQRCodeModal
          open={this.state.showSelectAssetsModal}
          showChildrenForAssetId={this.state.showSelectAssetsModalForAssetId}
          onPrintQrCodes={ids => {
            this.setState({
              showSelectAssetsModal: false,
              showQRCodeModal: true,
              assetIdsToPrintQRCodesFor: ids,
            });
          }}
          onClose={() =>
            this.setState({ showSelectAssetsModal: false, showSelectAssetsModalForAssetId: null })
          }
        />
        <PrintQRCodeModal
          templateType="asset"
          open={this.state.showQRCodeModal}
          params={{ id: this.state.assetIdsToPrintQRCodesFor.join(',') }}
          amountOfQrCodes={this.state.assetIdsToPrintQRCodesFor.length}
          onClose={() => this.setState({ showQRCodeModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTree: AssetsOperations.fetchTree,
      expandTree: AssetsOperations.expandTree,
      collapseTree: AssetsOperations.collapseTree,
      moveTree: AssetsOperations.moveTree,
      changeTree: AssetsOperations.changeTree,
      addQueryParameter: AssetsOperations.addQueryParameter,
      setNavigatedTo: AssetsOperations.setNavigatedTo,
      resetState: AssetsOperations.resetState,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    tree: AssetsSelectors.getTreeData(state),
    isFetching: AssetsSelectors.isFetchingTree(state),
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    isEmpty: AssetsSelectors.isEmpty(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
    navigatedTo: AssetsSelectors.getNavigatedTo(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tree)));
