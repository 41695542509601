import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { ChangeCurrencyModal } from 'views/components/General';
import PriceField from './PriceField';
import UnitField from './UnitField';
import { find } from 'lodash-es';
import { SDKReduxOperations } from 'sdk';

class AddSparePartRowModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    showAddSparePartRowModal: false,
    showSelectCurrencyModal: false,
    showTitleRequiredError: false,
    showQuantityRequiredError: false,
    addToSystemCheckbox: false,
    editingSparePartWithdrawal: this.generateEmptySparePartWithdrawal(),
  });

  generateEmptySparePartWithdrawal = () => ({
    title: '',
    quantity: '1',
    purchase_price: null,
    spare_part_unit_id: null,
    purchase_price_currency: null,
    purchase_price_exchange_rate: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const defaultSparePartUnit = this.getDefaultSparePartUnit();
      this.setState({
        ...this.getInitialState(),
        showAddSparePartRowModal: true,
        editingSparePartWithdrawal: {
          ...this.generateEmptySparePartWithdrawal(),
          purchase_price_currency: this.props.currentSystem.currency,
          spare_part_unit_id: defaultSparePartUnit ? defaultSparePartUnit.id : null,
        },
      });
    } else if (prevProps.open && !this.props.open) {
      this.setState({ showAddSparePartRowModal: false });
    }
  }

  getDefaultSparePartUnit = () => find(this.props.sparePartUnits, { default: true });

  hasErrors = () => {
    let hasErrors = false;
    if (this.state.editingSparePartWithdrawal.title.length === 0) {
      hasErrors = true;
      this.setState({ showTitleRequiredError: true });
    }
    if (this.state.editingSparePartWithdrawal.quantity.length === 0) {
      hasErrors = true;
      this.setState({ showQuantityRequiredError: true });
    }
    return hasErrors;
  };

  createSparePartWithdrawalRow = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .createSparePartWithdrawalForWorkOrder(this.props.workOrder.id, this.state.editingSparePartWithdrawal)
      .then(({ data }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.add-spare-parts-modal.add-spare-part-success" />}
          />
        );
        if (this.state.addToSystemCheckbox) {
          this.props.onSaveAndAddToSystem({ ...this.state.editingSparePartWithdrawal, id: data.id });
        } else {
          this.props.onClose();
        }
      })
      .catch(e => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.props.onClose();
      });
  };

  setEditingSparePartWithdrawalData = data => {
    this.setState({
      editingSparePartWithdrawal: {
        ...this.state.editingSparePartWithdrawal,
        ...data,
      },
    });
  };

  renderCurrencyModal = () => {
    return (
      <ChangeCurrencyModal
        currency={this.state.editingSparePartWithdrawal.purchase_price_currency}
        exchangeRate={this.state.editingSparePartWithdrawal.purchase_price_exchange_rate}
        open={this.state.showSelectCurrencyModal}
        onSave={({ currency, exchangeRate }) => {
          this.setState({ showSelectCurrencyModal: false, showAddSparePartRowModal: true });
          this.setEditingSparePartWithdrawalData({
            purchase_price_currency: currency,
            purchase_price_exchange_rate: exchangeRate,
          });
        }}
        onClose={() => {
          this.setState({ showSelectCurrencyModal: false, showAddSparePartRowModal: true });
        }}
      />
    );
  };

  renderAddToSystemField() {
    return (
      <Field.Checkbox
        checked={this.state.addToSystemCheckbox}
        onChange={() => {
          this.setState(prevState => ({
            addToSystemCheckbox: !prevState.addToSystemCheckbox,
          }));
        }}
        label={<FormattedMessage id="screens.purchase-order.info.articles.create-spare-part-button-label" />}
        questionTooltipContent={
          <FormattedMessage id="screens.purchase-order.info.articles.create-spare-part-button-label" />
        }
      />
    );
  }

  renderQuantityField() {
    return (
      <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.quantity" />}>
        <Field.Decimal
          error={this.state.showQuantityRequiredError}
          value={this.state.editingSparePartWithdrawal.quantity}
          onChange={quantity => {
            if (this.state.showQuantityRequiredError && quantity) {
              this.setState({ showQuantityRequiredError: false });
            }
            this.setEditingSparePartWithdrawalData({ quantity });
          }}
          onBlur={quantity => {
            this.setEditingSparePartWithdrawalData({ quantity });
          }}
        />
      </Field>
    );
  }

  renderTitleField() {
    return (
      <Field view={false} label={<FormattedMessage id="resources.purchase-order-row.title" />}>
        <Field.Text
          autoFocus
          error={this.state.showTitleRequiredError}
          value={this.state.editingSparePartWithdrawal.title}
          onChange={title => {
            if (this.state.showTitleRequiredError && title) {
              this.setState({ showTitleRequiredError: false });
            }
            this.setEditingSparePartWithdrawalData({ title });
          }}
        />
      </Field>
    );
  }

  renderContent = () => {
    return (
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column>{this.renderTitleField()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column xl={3}>{this.renderQuantityField()}</Grid.Column>
            <Grid.Column xl={3} style={{ paddingTop: '18px' }}>
              <UnitField
                value={this.state.editingSparePartWithdrawal.spare_part_unit_id}
                sparePartUnits={this.props.sparePartUnits}
                onClear={() => this.setEditingSparePartWithdrawalData({ spare_part_unit_id: null })}
                onChange={spare_part_unit_id => {
                  this.setEditingSparePartWithdrawalData({ spare_part_unit_id });
                }}
              />
            </Grid.Column>
            <Grid.Column xl={6}>
              <PriceField
                price={this.state.editingSparePartWithdrawal.price}
                currency={this.state.editingSparePartWithdrawal.purchase_price_currency}
                exchangeRate={this.state.editingSparePartWithdrawal.purchase_price_exchange_rate}
                onChange={purchase_price => {
                  this.setEditingSparePartWithdrawalData({ purchase_price });
                }}
                onBlur={purchase_price => {
                  this.setEditingSparePartWithdrawalData({ purchase_price });
                }}
                onClickCurrency={() => {
                  this.setState({ showSelectCurrencyModal: true, showAddSparePartRowModal: false });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>{this.renderAddToSystemField()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    );
  };

  render() {
    return (
      <>
        <Modal verticalMargin="30px" isOpen={this.state.showAddSparePartRowModal}>
          <Modal.Header
            title={
              <FormattedMessage id="screens.work-order.reserved-spare-parts.add-spare-part-modal.title" />
            }
            onClose={this.props.onClose}
          />
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                loading={this.state.isSaving}
                primary
                label="general.save"
                onClick={() => {
                  this.createSparePartWithdrawalRow();
                }}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderCurrencyModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createSparePartWithdrawalForWorkOrder: SDKReduxOperations.createSparePartWithdrawalForWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const sparePartUnits = AuthSelectors.getSparePartUnits(state);
  const findDefaultUnit = find(sparePartUnits, { default: true });
  const getDefaultSparePartUnit = findDefaultUnit ? findDefaultUnit.id : null;

  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    sparePartUnits,
    getDefaultSparePartUnit,
  };
}

AddSparePartRowModal.propTypes = {};

AddSparePartRowModal.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddSparePartRowModal));
