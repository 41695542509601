import React from 'react';
import { CardGrid } from 'views/components/Shared/Layout';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

export default ({ workOrder }) => {
  return workOrder.completed_before_registration === false ? null : (
    <CardGrid.Row>
      <WhiteCard noPadding>
        <div className={styles['after-registered']}>
          <div className={styles['icon']}>
            <Icon regular type="check" />
          </div>
          <div className={styles['title-container']}>
            <p className={styles['title']}>
              <FormattedMessage id="screens.work-order.after-registered-title" />
            </p>
            <p className={styles['subtitle']}>
              <FormattedMessage id="screens.work-order.after-registered-subtitle" />
            </p>
          </div>
        </div>
      </WhiteCard>
    </CardGrid.Row>
  );
};
