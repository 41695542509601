import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AttachmentListItem } from 'views/components/Attachment';
import HelperFunctions from 'utilities/HelperFunctions';
import AttachmentSubtitle from 'views/scenes/Files/FilesContainer/FileList/AttachmentSubtitle';

class AttachmentListItemContainer extends Component {
  render() {
    return (
      <AttachmentListItem
        key={this.props.attachment.id}
        attachment={this.props.attachment}
        hideFolder
        subtitle={<AttachmentSubtitle attachment={this.props.attachment} />}
        onClick={(_, attachmentVersion) => HelperFunctions.openAttachment(attachmentVersion)}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachment: EntitySelectors.getAttachment(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AttachmentListItemContainer);
