import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HelperFunctions from 'utilities/HelperFunctions';
import { VendorOperations, VendorSelectors } from 'state/ducks/vendor';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import NotFound from './NotFound';
import {
  Information,
  ContactPersons,
  AssetVendors,
  Attachments,
  SpareParts,
  PurchaseOrders,
  WorkOrders,
} from './Tabs';

class VendorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      show: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Vendor);
    this.fetchVendor();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchVendor();
    }
  }

  fetchVendor = () => {
    this.props
      .fetchVendor(this.props.match.params.id)
      .then(vendor => {
        const { system_id } = vendor;
        if (system_id !== this.props.currentSystem.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(vendor.title);
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.vendor) {
      return <Loader />;
    } else {
      return (
        <Switch key={this.props.match.params.id}>
          <Route path={`/vendors/:id`} exact component={Information} />
          <Route path={`/vendors/:id/contact-persons`} component={ContactPersons} />
          {this.props.settings.spare_parts_activated ? (
            <Route path={`/vendors/:id/spare-parts`} component={SpareParts} />
          ) : null}
          <Route path={`/vendors/:id/assets`} component={AssetVendors} />
          {this.props.canViewPurchaseOrders && this.props.settings.spare_parts_activated ? (
            <Route path={`/vendors/:id/purchase-orders`} component={PurchaseOrders} />
          ) : null}
          <Route path={`/vendors/:id/files`} component={Attachments} />
          <Route path={`/vendors/:id/work-orders`} component={WorkOrders} />
        </Switch>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchVendor: VendorOperations.fetchVendor,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    vendor: VendorSelectors.getVendor(state),
    settings: AuthSelectors.getSettings(state),
    canViewPurchaseOrders: AuthSelectors.canViewPurchaseOrders(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorDetail));
