import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Button } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class ChecklistTemplateItem extends PureComponent {
  render() {
    return (
      <List.Item>
        <List.Item.TitleColumn title={this.props.checklistTemplate.title} />
        <List.Item.Column alignRight>
          <Button.Group>
            <Button
              gray
              small
              label="general.choose"
              onClick={() => this.props.onSelect(this.props.checklistTemplate)}
            />
            <Button
              gray
              small
              label="components.select-checklist-modal.preview"
              onClick={() => this.props.onPreview(this.props.checklistTemplate)}
            />
          </Button.Group>
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateItem);
