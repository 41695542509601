import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Icon } from 'views/components/Shared/General';
import ChangeFolderInlineModal from './ChangeFolderInlineModal';
import { FormattedMessage } from 'react-intl';

class SelectedFolderContainer extends Component {
  render() {
    return (
      <ChangeFolderInlineModal
        folderId={this.props.id}
        onSelectFolder={folderId => this.props.onSelectFolder(folderId)}
        trigger={
          <Field edit label={<FormattedMessage id="resources.attachment.folder" />}>
            <Field.Resource
              icon={<Icon type="folder" />}
              value={
                this.props.attachmentFolder == null ? (
                  <FormattedMessage id="components.new-attachment.no-folder-chosen" />
                ) : (
                  <span>{this.props.attachmentFolder.title}</span>
                )
              }
              clearable={this.props.attachmentFolder != null}
              onClear={() => this.props.onSelectFolder(null)}
            />
          </Field>
        }
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentFolder: EntitySelectors.getAttachmentFolder(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SelectedFolderContainer);
