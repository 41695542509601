import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { VendorSelectors, VendorOperations } from 'state/ducks/vendor';
import { AuthSelectors } from 'state/ducks/auth';
import Header from '../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HelperFunctions, SDKReduxOperations } from 'sdk';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, List, EmptyDataSet, Pagination, WhiteCard } from 'views/components/Shared/General';
import { CurrencyRateModal } from 'views/components/PurchaseOrder';
import { Loader } from 'views/components/Shared/General';
import PurchaseOrderTiny from 'assets/images/EmptyDataSet/PurchaseOrderTiny.png';
import { EntitySelectors } from 'sdk/State/entities';
import PurchaseOrderListItem from './PurchaseOrderListItem';
import styles from './style.module.scss';

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);

    this.state = {
      isSaving: false,
      tableLoading: false,
      viewInitialized: false,
      showCurrencyRateModal: false,
      rate: null,
      queryParams,
    };
  }

  componentDidMount() {
    this.fetchPurchaseOrders()
      .then(() => {
        this.setState({ viewInitialized: true });
      })
      .catch(() => {
        this.setState({ viewInitialized: true });
      });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams }, () => {
        this.setState({ tableLoading: true });
        this.fetchPurchaseOrders()
          .then(() => {
            this.setState({ tableLoading: false });
          })
          .catch(() => {
            this.setState({ tableLoading: false });
          });
      });
    }
  }

  createPurchaseOrder = () => {
    this.setState({ isSaving: true });
    let params = {
      vendor_id: this.props.match.params.id,
    };
    if (this.props.hasProTier) {
      this.props
        .createPurchaseOrder(this.props.currentSystem.id, params)
        .then(({ data: purchaseOrder }) => {
          this.setState({ isSaving: false });
          this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
        })
        .catch(e => {
          if (HelperFunctions.hasError(e, { code: '10001', key: 'currency_exchange_rate' })) {
            this.setState({
              isSaving: false,
              showCurrencyRateModal: true,
              currencyForRateModal: this.props.vendor.purchase_order_currency,
            });
          }
        });
    } else {
      if (
        this.props.currentSystem.currency !== this.props.vendor.purchase_order_currency &&
        this.state.rate == null
      ) {
        this.setState({
          isSaving: false,
          showCurrencyRateModal: true,
          currencyForRateModal: this.props.vendor.purchase_order_currency,
        });
      } else {
        params = { ...params, currency_exchange_rate: this.state.rate };
        this.props
          .createPurchaseOrder(this.props.currentSystem.id, params)
          .then(({ data: purchaseOrder }) => {
            this.setState({ isSaving: false });
            this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
          });
      }
    }
  };

  fetchPurchaseOrders = () => {
    const filterParams = HelperFunctions.buildQueryParamsForList(this.state.queryParams);
    return this.props
      .fetchPurchaseOrders(this.props.currentSystem.id, {
        vendor_id: this.props.match.params.id,
        ...filterParams,
      })
      .then(({ data: purchaseOrders }) => {
        return purchaseOrders;
      });
  };

  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= 8) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.vendor.purchase-orders.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
          totalPages={this.props.pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.vendor.purchase-orders.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.vendor.purchase-orders.empty-data-set.subtitle" />}
        image={PurchaseOrderTiny}
        button={
          this.props.canEditPurchaseOrders ? (
            <Button
              small
              primary
              onClick={this.createPurchaseOrder}
              loading={this.state.isSaving}
              label="screens.vendor.purchase-orders.empty-data-set.button"
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderToolbar = () => {
    if (this.props.canEditPurchaseOrders) {
      return (
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.vendor.purchase-orders.create-button"
              onClick={this.createPurchaseOrder}
              loading={this.state.isSaving}
            />
          }
        />
      );
    }
    return null;
  };

  renderList = () => {
    if (this.state.tableLoading) {
      return (
        <React.Fragment>
          <List>
            {Array(this.props.purchaseOrders.length === 0 ? 1 : this.props.purchaseOrders.length)
              .fill()
              .map(() => (
                <PurchaseOrderListItem loading />
              ))}
          </List>
          {this.renderPagination()}
        </React.Fragment>
      );
    }
    if (this.props.purchaseOrders.length === 0) return null;

    return (
      <React.Fragment>
        <List>
          {this.props.purchaseOrders.map(purchaseOrder => (
            <PurchaseOrderListItem
              purchaseOrder={purchaseOrder}
              onClick={() => {
                this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </React.Fragment>
    );
  };

  renderHeader = () => (
    <List.Header background>
      <List.Header.Column flex>
        <FormattedMessage id="resources.purchase-order.resource" />
      </List.Header.Column>
      <List.Header.Column width={150}>
        <FormattedMessage id="resources.purchase-order.status" />
      </List.Header.Column>
      <List.Header.Column width={150} alignRight>
        <FormattedMessage id="resources.purchase-order.total-cost" />
      </List.Header.Column>
    </List.Header>
  );

  renderPurchaseOrders = () => {
    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderHeader()}
        {this.renderList()}
      </React.Fragment>
    );
  };

  renderView = () => {
    if (!this.state.viewInitialized) {
      return <Loader />;
    } else if (this.props.purchaseOrders.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderPurchaseOrders();
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        <CurrencyRateModal
          currency={this.state.currencyForRateModal}
          open={this.state.showCurrencyRateModal}
          isSaving={this.state.isSaving}
          onSave={rate => {
            this.setState({ rate }, () => {
              this.createPurchaseOrder();
            });
          }}
          onClose={() => {
            this.setState({ showCurrencyRateModal: false });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrders: VendorOperations.fetchPurchaseOrders,
      createPurchaseOrder: SDKReduxOperations.createPurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.match.params.id),
    purchaseOrders: VendorSelectors.getPurchaseOrders(state),
    pagination: VendorSelectors.getPurchaseOrdersPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    hasProTier: AuthSelectors.hasProTier(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrders)));
