import types from './types';

const setSaveAsReusable = payload => ({
  type: types.SET_SAVE_AS_REUSABLE,
  payload: payload,
});

const setIsCreatingChecklistFromTemplatesLibrary = payload => ({
  type: types.SET_IS_CREATING_CHECKLIST_FROM_LIBRARY,
  payload: payload,
});

const fetchChecklistTemplateVersion = payload => ({
  type: types.FETCH_CHECKLIST_TEMPLATE_VERSION,
  payload: payload,
});

const setEditingChecklistTemplateVersion = payload => ({
  type: types.SET_EDITING_CHECKLIST_TEMPLATE_VERSION,
  payload: payload,
});

const sortFieldInSection = payload => ({
  type: types.SORT_FIELD_IN_SECTION,
  payload: payload,
});

const sortFieldOutsideSection = payload => ({
  type: types.SORT_FIELD_OUTSIDE_SECTION,
  payload: payload,
});

const moveFieldFromRootToSection = payload => ({
  type: types.MOVE_FIELD_FROM_ROOT_TO_SECTION,
  payload: payload,
});

const moveFieldFromSectionToRoot = payload => ({
  type: types.MOVE_FIELD_FROM_SECTION_TO_ROOT,
  payload: payload,
});

const moveFieldsBetweenSections = payload => ({
  type: types.MOVE_FIELDS_BETWEEN_SECTIONS,
  payload: payload,
});

const addNewFieldToRoot = payload => ({
  type: types.ADD_NEW_FIELD_TO_ROOT,
  payload: payload,
});

const addNewFieldToSection = payload => ({
  type: types.ADD_NEW_FIELD_TO_SECTION,
  payload: payload,
});

const beginDrag = payload => ({
  type: types.BEGIN_DRAG,
  payload: payload,
});

const endDrag = payload => ({
  type: types.END_DRAG,
  payload: payload,
});

const prepareNewField = payload => ({
  type: types.PREPARE_NEW_FIELD,
  payload: payload,
});

const prepareNewFieldForEdit = payload => ({
  type: types.PREPARE_NEW_FIELD_FOR_EDIT,
  payload: payload,
});

const editField = payload => ({
  type: types.EDIT_FIELD,
  payload: payload,
});

const updateEditingField = payload => ({
  type: types.UPDATE_EDITING_FIELD,
  payload: payload,
});

const cancelEditField = () => ({
  type: types.CANCEL_EDIT_FIELD,
});

const showSectionCantBeDroppedInModal = payload => ({
  type: types.SHOW_SECTION_CANT_BE_DROPPED_IN_MODAL,
  payload,
});

const hideSectionCantBeDroppedInModal = payload => ({
  type: types.HIDE_SECTION_CANT_BE_DROPPED_IN_MODAL,
  payload,
});

export default {
  setIsCreatingChecklistFromTemplatesLibrary,
  updateEditingField,
  setSaveAsReusable,
  fetchChecklistTemplateVersion,
  moveFieldsBetweenSections,
  setEditingChecklistTemplateVersion,
  sortFieldInSection,
  sortFieldOutsideSection,
  moveFieldFromRootToSection,
  beginDrag,
  endDrag,
  prepareNewField,
  moveFieldFromSectionToRoot,
  editField,
  cancelEditField,
  addNewFieldToRoot,
  addNewFieldToSection,
  prepareNewFieldForEdit,
  showSectionCantBeDroppedInModal,
  hideSectionCantBeDroppedInModal,
};
