import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Field, Icon } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class View extends Component {
  delete = () => {
    this.props.onDelete(this.props.contactPerson.id);
    this.props.onClose();
  };

  renderContent = () => {
    return (
      <div className={styles['info-container']}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                icon={<Icon regular type="phone" />}
                label={<FormattedMessage id="resources.contact-person.phone" />}
              >
                <p>{this.props.contactPerson.phone || '-'}</p>
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                icon={<Icon regular type="envelope" />}
                label={<FormattedMessage id="resources.contact-person.email" />}
              >
                <p>{this.props.contactPerson.email || '-'}</p>
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal.Header
          title={this.props.contactPerson.name}
          subtitle={this.props.contactPerson.position}
          iconButtons={
            !this.props.canEditVendors ? null : (
              <React.Fragment>
                <Button type="icon" icon={<Icon regular type="pen" />} onClick={this.props.onEdit} />
                <Button
                  type="icon"
                  icon={<Icon regular red type="trash-alt" />}
                  onClick={() => this.delete()}
                />
              </React.Fragment>
            )
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    contactPerson: EntitySelectors.getContactPerson(state, ownProps.id),
    canEditVendors: AuthSelectors.canEditVendors(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(View)));
