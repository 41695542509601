import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { TreePath } from 'views/components/Asset';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { Datapill, Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import moment from 'moment';

class ListItem extends Component {
  renderAsset = () => {
    if (this.props.asset) {
      return <TreePath assetId={this.props.asset.id} fullPath />;
    }
    return null;
  };

  renderCompletedIcon = () => {
    const { operationalMaintenanceInstance } = this.props;
    if (
      operationalMaintenanceInstance &&
      operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed
    ) {
      return (
        <div className={styles['completed-icon']}>
          <Icon regular type="check" size={10} green />
        </div>
      );
    }
    return null;
  };

  showChecklistFilledRowsCountDatapill = () => this.props.checklistTemplateVersion;

  renderChecklistFilledRowsCountDatapill = () => {
    if (!this.showChecklistFilledRowsCountDatapill()) return null;

    let amountOfRows = this.props.checklistTemplateVersion.fillable_rows_count;
    let filledRowsCount = 0;
    if (this.props.checklistInstance) {
      filledRowsCount = this.props.checklistInstance.filled_in_rows_count;
    }

    return (
      <Datapill
        gray={filledRowsCount === 0}
        icon="list"
        value={
          <span>
            <span>{filledRowsCount}</span>
            <span>/</span>
            <span>{amountOfRows}</span>
          </span>
        }
      />
    );
  };

  showAssetNumberDataPill = () => this.props.settings.asset_number_activated && this.props.asset;

  renderAssetNumberDataPill = () => {
    if (!this.showAssetNumberDataPill()) return null;

    return (
      <Datapill
        label={<FormattedMessage id="resources.asset.number-long" />}
        value={`#${this.props.asset.number}`}
      />
    );
  };

  showTimeOfDayDatapill = () => {
    const { operationalMaintenanceInstance, operationalMaintenanceTemplateRecurrenceVersion } = this.props;

    if (operationalMaintenanceInstance && operationalMaintenanceInstance.due_time_of_day) {
      return true;
    }

    if (
      operationalMaintenanceTemplateRecurrenceVersion &&
      operationalMaintenanceTemplateRecurrenceVersion.time_of_day
    ) {
      return true;
    }

    return false;
  };

  renderTimeOfDayDatapill = () => {
    if (!this.showTimeOfDayDatapill()) return null;

    const { operationalMaintenanceInstance, operationalMaintenanceTemplateRecurrenceVersion } = this.props;
    if (operationalMaintenanceInstance && operationalMaintenanceInstance.due_time_of_day) {
      return (
        <Datapill value={moment(operationalMaintenanceInstance.due_time_of_day, 'hh:mm').format('LT')} />
      );
    }

    if (operationalMaintenanceTemplateRecurrenceVersion.time_of_day) {
      return (
        <Datapill
          value={moment(operationalMaintenanceTemplateRecurrenceVersion.time_of_day, 'hh:mm').format('LT')}
        />
      );
    }

    return null;
  };

  renderDataPills = () => {
    if (
      !this.showAssetNumberDataPill() &&
      !this.showChecklistFilledRowsCountDatapill() &&
      !this.showTimeOfDayDatapill()
    )
      return null;

    return (
      <Datapill.List>
        {this.renderAssetNumberDataPill()}
        {this.renderChecklistFilledRowsCountDatapill()}
        {this.renderTimeOfDayDatapill()}
      </Datapill.List>
    );
  };

  render() {
    let id = this.props.operationalMaintenance.id;
    if (this.props.operationalMaintenanceInstance) {
      id = this.props.operationalMaintenanceInstance.id;
    }
    return (
      <div className={styles['op-maintenance-cell-wrapper']}>
        <div className={styles['op-maintenance-cell']}>
          <div className={styles['content-container']}>
            <div className={styles['left-content-container']}>
              <div className={styles['left-content']}>
                <div className={styles['op-maintenance-cell-title-container']}>
                  <div className={styles['op-maintenace-cell-title']}>
                    {this.props.operationalMaintenanceTemplateVersion.title}
                  </div>
                  <div className={styles['op-maintenance-cell-subtitle']}>{this.renderAsset()}</div>
                  <div className={styles['border-circle-container']}>
                    <div className={styles['border-circle']} />
                  </div>
                </div>
                <div className={styles['op-maintenance-cell-data-pills']}>{this.renderDataPills()}</div>
              </div>
              {this.renderCompletedIcon()}
            </div>
            <div className={styles['button-container']}>
              <Link to={`/operational-maintenances/${id}`}>
                <Button gray label="general.open" small />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const operationalMaintenance = EntitySelectors.getOperationalMaintenance(state, ownProps.id);
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    operationalMaintenance.operational_maintenance_instance_id
  );
  let operationalMaintenanceTemplateVersion = null;
  let asset = null;
  let checklistInstance = null;
  let checklistTemplateLink = null;
  let checklistTemplateVersion = null;
  let checklistTemplate = null;
  let operationalMaintenanceTemplateRecurrenceVersion = null;
  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );
    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenanceInstance.operational_maintenance_template_recurrence_version_id
      );
    asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
    const checklistInstanceIds = operationalMaintenanceInstance.checklist_instances || [];
    if (checklistInstanceIds.length === 1) {
      checklistInstance = EntitySelectors.getChecklistInstance(state, checklistInstanceIds[0]);

      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistInstance.checklist_template_id
      );

      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistInstance.checklist_template_version
      );
    }
  } else {
    const operationalMaintenanceTemplateRecurrence =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrence(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_id
      );
    const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
      state,
      operationalMaintenanceTemplateRecurrence.operational_maintenance_template_asset_id
    );
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenance.operational_maintenance_template_version_id
    );

    asset = EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id);
    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenance.operational_maintenance_template_recurrence_version_id
      );
    const checklistTemplateLinkIds = operationalMaintenanceTemplateVersion.checklist_template_links || [];
    if (checklistTemplateLinkIds.length === 1) {
      checklistTemplateLink = EntitySelectors.getChecklistTemplateLink(state, checklistTemplateLinkIds[0]);
      checklistTemplate = EntitySelectors.getChecklistTemplate(
        state,
        checklistTemplateLink.checklist_template_id
      );
      checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.active_version_id
      );
    }
  }

  return {
    settings: AuthSelectors.getSettings(state),
    operationalMaintenance,
    operationalMaintenanceInstance,
    operationalMaintenanceTemplateVersion,
    checklistTemplateVersion,
    checklistInstance,
    operationalMaintenanceTemplateRecurrenceVersion,
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(ListItem));
