import actions from './actions';

const resetState = actions.resetState;
const prepareNewWorkOrder = actions.prepareNewWorkOrder;
const setEditingWorkOrderValues = actions.setEditingWorkOrderValues;

export default {
  resetState,
  prepareNewWorkOrder,
  setEditingWorkOrderValues,
};
