import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { SparePartWithdrawalsOperations } from 'state/ducks/sparePartWithdrawals';
import { AuthSelectors } from 'state/ducks/auth';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getSparePartWithdrawalMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part-withdrawal.comment',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Comment);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.withdrawals.filters.advanced.withdrawal.created-at',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedAt);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.withdrawals.filters.advanced.withdrawal.created-by',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.CreatedBy);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getSparePartMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'screens.withdrawals.filters.advanced.spare-parts.spare-part',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePart);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.withdrawals.filters.advanced.spare-parts.location',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartLocation);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  getAssetMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'screens.withdrawals.filters.advanced.asset.asset',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Asset);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderSparePartWithdrawalMenuItems = () => {
    const sparePartItems = this.getSparePartWithdrawalMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.withdrawals.filters.advanced.withdrawal.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSparePartFilters = () => {
    if (this.props.hasProTier) {
      const sparePartItems = this.getSparePartMenuItems();
      if (sparePartItems.length === 0) {
        return null;
      }
      return (
        <SideModal.Container.Filter.Section>
          <SideModal.Container.Filter.Section.Header
            title={<FormattedMessage id="screens.withdrawals.filters.advanced.spare-parts.title" />}
          />
          <SideModal.Container.Filter.Section.Items>
            {sparePartItems.map(({ label, selected, onClick }) => (
              <SideModal.Container.Filter.Section.Items.Item
                label={label}
                selected={selected}
                onClick={onClick}
              />
            ))}
          </SideModal.Container.Filter.Section.Items>
        </SideModal.Container.Filter.Section>
      );
    }
    return null;
  };

  renderAssetFilters = () => {
    const sparePartItems = this.getAssetMenuItems();
    if (sparePartItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.withdrawals.filters.advanced.asset.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {sparePartItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [
      ...this.getSparePartWithdrawalMenuItems(),
      ...this.getSparePartMenuItems(),
      ...this.getAssetMenuItems(),
    ];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return (
      <>
        {this.renderSparePartWithdrawalMenuItems()}
        {this.renderSparePartFilters()}
        {this.renderAssetFilters()}
      </>
    );
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.withdrawals.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: SparePartWithdrawalsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    templateFields: AuthSelectors.getSparePartTemplateFields(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AvailableFilters));
