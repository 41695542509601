import types from './types';

const resetState = () => ({
  type: types.RESET_STATE,
});

const fetchUsers = () => ({
  type: types.FETCH_USERS,
});

const fetchUsersSuccess = payload => ({
  type: types.FETCH_USERS_SUCCESS,
  payload,
});

const setAmountOfPayingUsers = payload => ({
  type: types.SET_AMOUNT_OF_PAYING_USERS,
  payload,
});

const addQueryParameter = payload => ({
  type: types.ADD_QUERY_PARAMETER,
  payload: payload,
});

export default {
  resetState,
  fetchUsers,
  setAmountOfPayingUsers,
  fetchUsersSuccess,
  addQueryParameter,
};
