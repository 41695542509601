import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Icon } from 'views/components/Shared/General';
import { BookmarkedAssetsDropdown } from 'views/components/Asset';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import Button from './Button';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';

class Header extends Component {
  renderChildren = () => {
    if (this.props.children == null) {
      return null;
    }
    return (
      <>
        <div className={styles['border']} />
        {this.props.children}
      </>
    );
  };

  renderBookmarkedAssetsContent = () => {
    if (this.props.isOperator === false) {
      return (
        <>
          <BookmarkedAssetsDropdown />
          <div className={styles['border']} />
        </>
      );
    }
    return null;
  };

  render() {
    const { list } = queryString.parse(this.props.location.search);

    return (
      <div className={styles['header']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.operational-maintenances.title" />
        </div>
        <div className={styles['content']}>
          {this.renderBookmarkedAssetsContent()}
          <Header.Button.Group>
            <Header.Button
              selected={list === 'calendar'}
              linkTo={{
                pathname: '/operational-maintenances',
                search: 'list=calendar',
              }}
            >
              <Icon type="calendar-alt" regular />
            </Header.Button>
            <Header.Button
              selected={list !== 'calendar'}
              linkTo={{
                pathname: '/operational-maintenances',
                search: 'list=today',
              }}
            >
              <Icon type="list-alt" regular />
            </Header.Button>
          </Header.Button.Group>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

Header.Button = Button;

function mapStateToProps(state) {
  return {
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
