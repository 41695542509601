import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SDKReduxOperations } from 'sdk';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import toast from 'react-hot-toast';
import { normalizeUser } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { List, Icon, Loader } from 'views/components/Shared/General';

class UserPinModal extends Component {
  state = {
    isDeleting: false,
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isDeleting: false });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });
    const params = {
      pin_code: null,
      pin_code_required: false,
    };
    this.props
      .updateUser(this.props.user.id, params)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        const { data } = res;
        const { entities } = normalizeUser(data);
        this.props.updateEntities(entities);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeleting: false });
      });
  };

  renderFields = () => {
    return (
      <List light small>
        <List.Item small clickable onClick={() => this.props.openEditPin()}>
          <List.Item.Column width={32}>
            <Icon regular type="pen" blue withBackground backgroundSize={32} />
          </List.Item.Column>
          <List.Item.TitleColumn title={<FormattedMessage id="screens.users.action-modal.change-pin" />} />
        </List.Item>
        <List.Item small clickable onClick={() => this.delete()}>
          <List.Item.Column width={32}>
            {this.state.isDeleting ? (
              <Loader />
            ) : (
              <Icon regular type="trash-alt" red withBackground backgroundSize={32} />
            )}
          </List.Item.Column>
          <List.Item.TitleColumn title={<FormattedMessage id="screens.users.action-modal.delete-pin" />} />
        </List.Item>
      </List>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.pin-title" />}
          subtitle={
            <FormattedMessage
              id="screens.users.action-modal.change-subtitle"
              values={{ user: this.props.user.name }}
            />
          }
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateUser: SDKReduxOperations.updateUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPinModal);
