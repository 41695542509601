import store from 'state';
import { request, setUserBaseUrl, setGroupBaseUrl } from 'sdk/utilities/Axios';
import HelperFunctions from 'utilities/HelperFunctions';
import { AuthTypes } from 'state/ducks/auth';

const init = () => {
  setUserBaseUrl(process.env.REACT_APP_API_URL);
  setGroupBaseUrl(`${process.env.REACT_APP_API_URL}group_login`);
  request.interceptors.response.use(
    response => {
      if (response.headers['required-web-version']) {
        if (Number(process.env.REACT_APP_VERSION_CODE) < Number(response.headers['required-web-version'])) {
          store.dispatch({ type: AuthTypes.SET_DEPRECATED_VERSION });
        }
      }
      return response;
    },
    error => {
      if (error.response) {
        const endpoint = error.request.responseURL.substr(error.request.responseURL.lastIndexOf('/') + 1);
        if (endpoint !== 'login' && error.response.status === 401) {
          HelperFunctions.clearUserAuthToken();
          store.dispatch({ type: AuthTypes.UNAUTHENTICATE_USER });
          return Promise.reject(error);
        }
        if (error.response.headers['required-web-version']) {
          if (
            Number(process.env.REACT_APP_VERSION_CODE) <
            Number(error.response.headers['required-web-version'])
          ) {
            store.dispatch({ type: AuthTypes.SET_DEPRECATED_VERSION });
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return Promise.reject(error);
    }
  );
};

export default {
  init,
};
