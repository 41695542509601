import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, List } from 'views/components/Shared/General';
import { AddSparePartModal } from 'views/components/WorkOrder';
import { AuthSelectors } from 'state/ducks/auth';
import SelectedSparePart from './SelectedSparePart';
import styles from './styles.module.scss';
import { AssetTitle, ChooseAssetInlineModal, TreePath } from 'views/components/Asset';

class WithdrawalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      comment: '',
      selectedSpareParts: [],
      showAddMoreSparePartsModal: false,
      selectedAssetId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        selectedSpareParts: [
          {
            sparePartId: this.props.sparePart.id,
            quantity: '',
          },
        ],
        comment: '',
        showAddMoreSparePartsModal: false,
        selectedAssetId: null,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  createWithdrawal = () => {
    this.setState({ isSaving: true });
    let requestsToWaitFor = this.state.selectedSpareParts.map(({ sparePartId, quantity }) =>
      this.props.createSparePartWithdrawal(this.props.currentSystem.id, {
        spare_part_id: sparePartId,
        quantity,
        comment: this.state.comment,
        asset_id: this.state.selectedAssetId,
      })
    );
    Promise.all(requestsToWaitFor).then(([data]) => {
      this.props.onClose();
      toast(
        <ToastMessage success text={<FormattedMessage id="components.free-withdrawal.create-success" />} />
      );
    });
  };

  renderContent = () => (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <div className={styles['selected-spare-parts']}>
            <List light>
              {this.state.selectedSpareParts.map(({ sparePartId, quantity }) => (
                <SelectedSparePart
                  sparePartId={sparePartId}
                  quantity={quantity}
                  onChangeQuantity={quantity => {
                    this.setState({
                      selectedSpareParts: [
                        ...this.state.selectedSpareParts.map(selectedSparePart =>
                          selectedSparePart.sparePartId === sparePartId
                            ? { ...selectedSparePart, quantity }
                            : selectedSparePart
                        ),
                      ],
                    });
                  }}
                />
              ))}
            </List>
            <div className={styles['text-button']}>
              <Button
                type="text"
                fontSize={12}
                label="components.free-withdrawal.add-more-spare-parts"
                onClick={() => {
                  this.setState({ showAddMoreSparePartsModal: true });
                }}
              />
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.work-order.asset" />}>
            <ChooseAssetInlineModal
              hideCreateButton
              trigger={
                <Field.Resource
                  angleDown
                  value={
                    this.state.selectedAssetId ? (
                      <>
                        <AssetTitle id={this.state.selectedAssetId} />
                        <TreePath assetId={this.state.selectedAssetId} />
                      </>
                    ) : null
                  }
                  onClear={() => this.setState({ selectedAssetId: null })}
                />
              }
              selectedAssetId={this.state.selectedAssetId}
              onSelectAsset={treeParentId => {
                this.setState({ selectedAssetId: treeParentId });
              }}
              onClear={e => this.setState({ selectedAssetId: null })}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="components.free-withdrawal.comment" />}>
            <Field.Textarea value={this.state.comment} onChange={comment => this.setState({ comment })} />
          </Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  renderAddSparePartModal = () => (
    <AddSparePartModal
      open={this.state.showAddMoreSparePartsModal}
      onClose={() => this.setState({ showAddMoreSparePartsModal: false })}
      excludeSparePartIds={this.state.selectedSpareParts.map(({ sparePartId }) => sparePartId)}
      onAddSpareParts={addedSpareParts =>
        this.setState({
          showAddMoreSparePartsModal: false,
          selectedSpareParts: [
            ...this.state.selectedSpareParts,
            ...addedSpareParts.map(({ spare_part_id, quantity }) => ({
              sparePartId: spare_part_id,
              quantity,
            })),
          ],
        })
      }
    />
  );

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={600}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="components.free-withdrawal.title" />}
            subtitle={<FormattedMessage id="components.free-withdrawal.info" />}
            onClose={this.props.onClose}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
          <Modal.Footer container>
            <Button.Group>
              <Button
                primary
                label="general.save"
                disabled={this.state.selectedSpareParts.some(({ quantity }) => quantity.length === 0)}
                loading={this.state.isSaving}
                onClick={this.createWithdrawal}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderAddSparePartModal()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSparePartWithdrawal: SDKReduxOperations.createSparePartWithdrawal,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WithdrawalModal));
