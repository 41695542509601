import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, NewInlineModal, Button, Icon } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import ManageProductionSupervisorsModal from './ManageProductionSupervisorsModal';
import ProductionSupervisorAssetItem from './ProductionSupervisorAssetItem';
import styles from './styles.module.scss';

class ProductionSupervisor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showManageProductionSupevisorsModal: false,
      showDropDown: false,
    };
  }

  renderContent = () => {
    let classNames = [styles['content']];
    if (this.props.productionSupervisorUsers.length === 0) {
      if (this.props.canEditAssets) {
        classNames = [...classNames, styles['editable']];
      }
      return (
        <>
          <div
            className={classNames.join(' ')}
            onClick={() => {
              if (this.props.canEditAssets) {
                this.setState({ showManageProductionSupevisorsModal: true });
              }
            }}
          >
            <div className={styles['title-container']}>
              <div className={styles['title']}>
                <FormattedMessage id="screens.asset.info.production-supervisor.title" />
              </div>
              <div className={styles['subtitle']}>
                <FormattedMessage id="screens.asset.info.production-supervisor.subtitle" />
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={classNames.join(' ')}>
          <div className={styles['user-container']}>
            <div className={styles['user']}>
              <div className={styles['label']}>
                <FormattedMessage id="resources.asset.production-supervisor" />
              </div>
              <div className={styles['value']}>
                {this.props.productionSupervisorUsers.reduce(
                  (accu, aps) =>
                    accu === null
                      ? [<ProductionSupervisorAssetItem id={aps.id} />]
                      : [...accu, <span>, </span>, <ProductionSupervisorAssetItem id={aps.id} />],
                  null
                )}
              </div>
            </div>
            {this.props.canEditAssets ? (
              <>
                <div
                  className={styles['edit-button']}
                  ref={ref => (this.inlineModalPositioningRef = ref)}
                  onClick={() => this.setState({ showDropDown: !this.state.showDropDown })}
                >
                  <Button type="icon" icon={<Icon regular size={14} type="ellipsis-h" />} />
                </div>
                <NewInlineModal
                  minWidth={200}
                  positionToRef={this.inlineModalPositioningRef}
                  position="right"
                  open={this.state.showDropDown}
                  onClose={() => this.setState({ showDropDown: false })}
                >
                  <NewInlineModal.Dropdown>
                    <NewInlineModal.Dropdown.Items>
                      <NewInlineModal.Dropdown.Item
                        onClick={() =>
                          this.setState({ showDropDown: false, showManageProductionSupevisorsModal: true })
                        }
                      >
                        <FormattedMessage id="general.edit" />
                      </NewInlineModal.Dropdown.Item>
                    </NewInlineModal.Dropdown.Items>
                  </NewInlineModal.Dropdown>
                </NewInlineModal>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  render() {
    if (this.props.settings.production_board_activated !== true) {
      return null;
    }
    if (this.props.canEditAssets === false && this.props.productionSupervisorUser == null) {
      return null;
    }
    return (
      <>
        <CardGrid.Row>
          <WhiteCard noPadding>{this.renderContent()}</WhiteCard>
        </CardGrid.Row>
        <ManageProductionSupervisorsModal
          open={this.state.showManageProductionSupevisorsModal}
          assetId={this.props.match.params.id}
          onClose={() => this.setState({ showManageProductionSupevisorsModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAsset: SDKReduxOperations.updateAsset,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  const asset = EntitySelectors.getAsset(state, assetId);
  return {
    asset,
    settings: AuthSelectors.getSettings(state),
    productionSupervisorUsers:
      EntitySelectors.getAssetProductionSupervisors(state, asset.asset_production_supervisors) || [],
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductionSupervisor));
