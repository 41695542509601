/*
  --------------------
    DATA SELECTORS
  --------------------
*/

/*
  --------------------
    UI SELECTORS
  --------------------
*/

const getSelectedItem = state => state.menu.selectedItem;

export default {
  getSelectedItem,
};
