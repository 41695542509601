import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, EmptyDataSet } from 'views/components/Shared/General';
import WorkOrderSmall from 'assets/images/EmptyDataSet/WorkOrderSmall.png';
import styles from './style.module.scss';

class NotFound extends Component {
  render() {
    return (
      <div className={styles['container']}>
        <EmptyDataSet
          title={<FormattedMessage id="screens.work-order.not-found.title" />}
          subtitle={<FormattedMessage id="screens.work-order.not-found.subtitle" />}
          image={WorkOrderSmall}
          small
        />
      </div>
    );
  }
}

export default NotFound;
