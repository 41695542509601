import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContentContainer } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Pagination } from 'views/components/Shared/General';
import { DueDateColumn, AssigneeColumn, StatusColumn } from 'views/components/WorkOrder';
import Header from '../../Header';
import styles from './style.module.scss';

const FILTERS = ['page'];
const PAGE_SIZE = 8;

class History extends Component {
  constructor(props) {
    super(props);
    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      isFetching: true,
      queryParams,
    };
  }

  componentDidMount() {
    this.fetchPreviousWorkOrders().then(() => {
      this.setState({ isFetching: false });
    });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({ queryParams, isFetching: true }, () => {
        this.fetchPreviousWorkOrders()
          .then(() => {
            this.setState({ isFetching: false });
          })
          .catch(() => {
            this.setState({ isFetching: false });
          });
      });
    }
  }

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        page: null,
        ...obj,
      })}`
    );
  };

  isFiltering = () => FILTERS.some(key => this.state.queryParams[key] != null);

  fetchPreviousWorkOrders = () => {
    const params = SDKHelperFunctions.buildQueryParamsForList(this.state.queryParams);
    return this.props.fetchPreviousWorkOrders(this.props.currentSystem.id, {
      ...params,
      page_size: PAGE_SIZE,
      recurring_maintenance_id: this.props.workOrder.recurring_maintenance_id,
    });
  };

  renderPagination = () => {
    if (this.props.pagination.totalEntries <= PAGE_SIZE) return null;
    return (
      <Pagination
        totalEntriesComponent={
          <FormattedMessage
            id="screens.work-orders.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        hideOptions
        currentPage={this.state.queryParams.page ? Number(this.state.queryParams.page) : 1}
        totalPages={this.props.pagination.totalPages}
        onSelectPage={page => {
          this.changeQueryParams({ page });
        }}
        style={{ justifyContent: 'flex-end', marginTop: 10 }}
      />
    );
  };

  renderSubtitle = workOrder => {
    if (workOrder.id === this.props.match.params.id) {
      return (
        <div className={styles['current-work-order']}>
          <FormattedMessage id="screens.work-order.current-work-order" />
        </div>
      );
    }
    return null;
  };

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order.title" />
        </List.Header.Column>
        <List.Header.Column width={140}>
          <FormattedMessage id="resources.work-order.due-date" />
        </List.Header.Column>
        <List.Header.Column width={140}>
          <FormattedMessage id="resources.work-order.completed-at" />
        </List.Header.Column>
        <List.Header.Column width={72}>
          <FormattedMessage id="resources.work-order.assignee" />
        </List.Header.Column>
        <List.Header.Column width={45} alignRight />
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.isFetching) {
      if (this.isFiltering()) {
        const amountOfWorkOrders = this.props.workOrders.length === 0 ? 2 : this.props.workOrders.length;
        return (
          <>
            {this.renderHeader()}
            <List>
              {Array(amountOfWorkOrders)
                .fill()
                .map(() => (
                  <List.Item small>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
            {this.renderPagination()}
          </>
        );
      }
      return (
        <>
          {this.renderHeader()}
          <List>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item small>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    return (
      <>
        {this.renderHeader()}
        <List small>
          {this.props.workOrders.map(workOrder => (
            <List.Item
              small
              clickable
              linkTo={{
                pathname: `/work-orders/${workOrder.id}`,
              }}
            >
              <List.Item.TitleColumn title={workOrder.title} subtitle={this.renderSubtitle(workOrder)} />
              <List.Item.Column width={140}>
                <DueDateColumn workOrder={workOrder} />
              </List.Item.Column>
              <List.Item.Column width={140}>
                {workOrder.completed_date ? moment(workOrder.completed_date).format('LL') : '-'}
              </List.Item.Column>
              <List.Item.Column width={72}>
                <AssigneeColumn
                  userIds={workOrder.assigned_to_users}
                  groupIds={workOrder.assigned_to_groups}
                  vendorIds={workOrder.assigned_to_vendors}
                />
              </List.Item.Column>
              <List.Item.Column borderLeft width={45}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <StatusColumn icon workOrder={workOrder} />
                </div>
              </List.Item.Column>
            </List.Item>
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>{this.renderList()}</ContentContainer>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPreviousWorkOrders: WorkOrderOperations.fetchPreviousWorkOrders,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  return {
    workOrder: EntitySelectors.getWorkOrder(state, workOrderId),
    workOrders: WorkOrderSelectors.getPreviousWorkOrders(state),
    pagination: WorkOrderSelectors.getWorkOrderListPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(History));
