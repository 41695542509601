import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_CREATED_FOR_OPERATIONAL_MAINTENANCE: {
      const { operationalMaintenanceInstanceId, data } = action.payload;
      if (state[operationalMaintenanceInstanceId]) {
        return {
          ...state,
          [operationalMaintenanceInstanceId]: {
            ...state[operationalMaintenanceInstanceId],
            spare_part_withdrawals: [
              data.id,
              ...state[operationalMaintenanceInstanceId].spare_part_withdrawals,
            ],
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SPARE_PART_WITHDRAWAL_DELETED: {
      const { data, sparePartWithdrawalId } = action.payload;
      const operationalMaintenanceInstanceId = data.operational_maintenance_instance_id;
      if (
        state[operationalMaintenanceInstanceId] &&
        state[operationalMaintenanceInstanceId].spare_part_withdrawals
      ) {
        return {
          ...state,
          [operationalMaintenanceInstanceId]: {
            ...state[operationalMaintenanceInstanceId],
            spare_part_withdrawals: state[operationalMaintenanceInstanceId].spare_part_withdrawals.filter(
              id => id !== sparePartWithdrawalId
            ),
          },
        };
      }
      return state;
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.operationalMaintenanceInstanceById) {
        return HelperFunctions.mergeEntities(state, action.payload.operationalMaintenanceInstanceById);
      }
      return state;
    default:
      return state;
  }
};
