import Title from './Title';
import Description from './Description';
import DueDate from './DueDate';
import Status from './Status';
import WorkOrderType from './WorkOrderType';
import Assignee from './Assignee';
import EstimatedMinutes from './EstimatedMinutes';
import Priority from './Priority';
import CreatedBy from './CreatedBy';
import CreatedAt from './CreatedAt';
import CompletedBy from './CompletedBy';
import CompletedAt from './CompletedAt';
import PausedBy from './PausedBy';
import AssetType from './AssetType';
import WorkOrderSpentTimeDate from './WorkOrderSpentTimeDate';
import WorkOrderSpentTimeCreatedBy from './WorkOrderSpentTimeCreatedBy';
import WorkOrderSpentTimeComment from './WorkOrderSpentTimeComment';
import InProgressBy from './InProgressBy';
import CompletedComment from './CompletedComment';
import UsedSparePart from './UsedSparePart';
import PausedComment from './PausedComment';
import CompletedBeforeRegistration from './CompletedBeforeRegistration';
import Request from './Request';
import RequestType from './RequestType';
import Interval from './Interval';
import Category from './Category';

export default {
  Title,
  PausedComment,
  Description,
  DueDate,
  Status,
  WorkOrderType,
  Assignee,
  EstimatedMinutes,
  Priority,
  CreatedBy,
  CreatedAt,
  CompletedBy,
  CompletedAt,
  PausedBy,
  AssetType,
  WorkOrderSpentTimeDate,
  WorkOrderSpentTimeCreatedBy,
  WorkOrderSpentTimeComment,
  InProgressBy,
  CompletedComment,
  UsedSparePart,
  CompletedBeforeRegistration,
  RequestType,
  Request,
  Interval,
  Category,
};
