import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import { normalizeWorkOrder } from 'sdk/Schemas';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import uuid from 'uuid';
import { Button, Field, Banner } from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { Modal } from 'views/components/Shared/Layout';
import { DowntimeModal } from 'views/components/Downtime';
import { UploadAttachmentErrorModal } from 'views/components/Attachment';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { AuthSelectors } from 'state/ducks/auth';
import { Grid, ToastMessage } from 'views/components/Shared/Layout';
import { SpentTimeModal } from 'views/components/WorkOrder';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API, SDKReduxOperations, HelperFunctions } from 'sdk';
import CompleteWorkOrderContent from './CompleteWorkOrderContent';
import { Duration } from 'views/components/Shared/General';
import styles from './style.module.scss';

class CompleteWorkOrderModal extends Component {
  getInitialState = () => ({
    open: false,
    isSaving: false,
    isDeletingAttachment: false,

    hideActiveDowntimeWarningForIds: [],
    showNoSpentTimesWarning: true,
    showUnusedSparePartsWarning: true,
    showUploadAttachmentErrorWarning: false,

    showSpentTimeRequiredError: false,
    showChecklistRequiredError: false,
    showCompletedDateIsRequiredError: false,

    uploadingCount: 0,
    deletingImageIds: {},
    showDowntimeModalForId: null,
    showAddSpentTimeModal: false,
  });

  state = this.getInitialState();

  shouldComponentUpdate(prevProps, prevState) {
    if (!this.props.open && !prevProps.open && !prevState.open && !this.state.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { workOrder, user } = this.props;
      this.setState({
        ...this.getInitialState(),
        open: true,
        isSaving: false,
        completed_date: workOrder.completed_date
          ? moment(workOrder.completed_date).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        completed_by_user_id:
          workOrder.completed_by_user_id == null ? this.props.currentUser.id : workOrder.completed_by_user_id,
        completed_comment: workOrder.completed_comment ? workOrder.completed_comment : '',
        comment_for_next_work_order: workOrder.comment_for_next_work_order
          ? workOrder.comment_for_next_work_order
          : '',
        user,
      });
    } else if (prevProps.open && !this.props.open) {
      this.setState({ open: false });
    }
  }

  createCompletionAttachment = params => {
    const { description, title, extension, file, type, mime_type, link_url } = params;
    const temporaryId = uuid.v4();
    this.props.beginUpload({ temporaryId, namespace: this.props.workOrder.id });
    if (type === 'file') {
      this.props
        .createCompletionAttachmentForWorkOrder(this.props.workOrder.id, {
          description,
          title,
          mime_type,
          type,
          extension,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: this.props.workOrder.id,
              });
            }
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  workOrderId: this.props.workOrder.id,
                  completion: true,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.workOrder.id,
                  });
                });
            })
            .catch(e => {
              this.props.cancelUpload({ temporaryId, namespace: this.props.workOrder.id });
              this.setState({ showUploadAttachmentErrorWarning: true });
            });
        })
        .catch(e => {});
    } else {
      this.props
        .createCompletionAttachmentForWorkOrder(this.props.workOrder.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          this.props.completeUpload({ id: attachment.id, temporaryId, namespace: this.props.workOrder.id });
        });
    }
  };

  deleteCompletionAttachment = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props
      .deleteAttachment(attachment.id)
      .then(() => {
        this.setState({ isDeletingAttachment: false });
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.work-order.delete-file-success" />} />
        );
      })
      .catch(e => {
        this.setState({ isDeletingAttachment: false });
      });
  };

  save = () => {
    if (this.state.isSaving) return;
    this.setState({
      isSaving: true,
    });
    API.updateWorkOrder(this.props.workOrder.id, {
      completed_date: this.state.completed_date,
      completed_comment: this.state.completed_comment,
      completed_by_user_id: this.state.completed_by_user_id,
      status: WorkOrderStatus.Completed,
      comment_for_next_work_order: this.state.comment_for_next_work_order,
    })
      .then(res => {
        const { entities } = normalizeWorkOrder(res.data);
        this.props.updateEntities(entities);
        const firstActiveDowntime = this.props.downtimes?.find(downtime => downtime.to == null);
        if (firstActiveDowntime) {
          this.props.onCompleteWithActiveDowntimeId(firstActiveDowntime.id);
          return;
        }
        this.props.onClose();
      })
      .catch(e => {
        const showSpentTimeRequiredError = HelperFunctions.hasError(e, { code: '60018' });
        const showChecklistRequiredError = HelperFunctions.hasError(e, { code: '60019' });
        if (showSpentTimeRequiredError) {
          this.setState({ isSaving: false, showSpentTimeRequiredError: true });
        }
        if (showChecklistRequiredError) {
          this.setState({ isSaving: false, showChecklistRequiredError: true });
        }
        if (!showSpentTimeRequiredError && !showChecklistRequiredError) {
          this.setState({ isSaving: false });
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }

        //TODO: Show general error message
      });
  };

  getNextDateFromCompletedDate = () => {
    switch (this.props.recurringMaintenance.frequency) {
      case 'daily':
        return moment(this.state.completed_date).add(this.props.recurringMaintenance.interval, 'days');
      case 'weekly':
        return moment(this.state.completed_date).add(this.props.recurringMaintenance.interval, 'weeks');
      case 'monthly':
        return moment(this.state.completed_date).add(this.props.recurringMaintenance.interval, 'months');
      case 'yearly':
        return moment(this.state.completed_date).add(this.props.recurringMaintenance.interval, 'years');
      default:
        return null;
    }
  };

  renderActiveDowntimeDuration = downtime => {
    return <Duration from={downtime.from} to={downtime.to} />;
  };

  renderActiveDowntimeWarnings = () => {
    if (!this.showActiveDowntimeWarning()) return null;

    const { downtimes } = this.props;
    const { hideActiveDowntimeWarningForIds } = this.state;
    const activeDowntimes = downtimes.filter(downtime => downtime.to == null);

    return (
      <>
        {activeDowntimes.map(downtime => (
          <AnimateHeight
            key={downtime.id}
            duration={250}
            height={!hideActiveDowntimeWarningForIds.includes(downtime.id) ? 'auto' : 0}
          >
            <div>
              <Banner
                key={downtime.id}
                closeable
                onClose={() =>
                  this.setState({
                    hideActiveDowntimeWarningForIds: [...hideActiveDowntimeWarningForIds, downtime.id],
                  })
                }
                red
                button
                buttonLabel={<FormattedMessage id="general.manage" />}
                onClick={() => {
                  this.setState({ open: false });
                  setTimeout(() => {
                    this.setState({ showDowntimeModalForId: downtime.id });
                  }, 250);
                }}
              >
                <div>
                  <FormattedMessage id="screens.work-order.completion-modal.active-downtime" />
                </div>
                <div>{this.renderActiveDowntimeDuration(downtime)}</div>
              </Banner>
            </div>
          </AnimateHeight>
        ))}
      </>
    );
  };

  renderSpentTimeRequiredWarning = () => {
    return (
      <AnimateHeight duration={250} height={this.showNoSpentTimesWarning() ? 'auto' : 0}>
        <Banner
          closeable
          onClose={() => this.setState({ showNoSpentTimesWarning: false })}
          orange
          button
          buttonLabel={<FormattedMessage id="general.add" />}
          onClick={() => {
            this.setState({ open: false });
            setTimeout(() => {
              this.setState({ showAddSpentTimeModal: true });
            }, 250);
          }}
        >
          <FormattedMessage id="screens.work-order.completion-modal.errors.no-registered-time" />
        </Banner>
      </AnimateHeight>
    );
  };

  renderUnusedSparePartsListItem = () => {
    return (
      <AnimateHeight duration={250} height={this.showUnusedSparePartsWarning() ? 'auto' : 0}>
        <Banner closeable onClose={() => this.setState({ showUnusedSparePartsWarning: false })} orange>
          <FormattedMessage id="screens.work-order.completion-modal.errors.no-registered-spare-parts" />
        </Banner>
      </AnimateHeight>
    );
  };

  showActiveDowntimeWarning = () => {
    return this.props.downtimes?.some(downtime => downtime.to == null);
  };

  showUnusedSparePartsWarning = () => {
    if (this.state.showUnusedSparePartsWarning === false) {
      return false;
    }
    return (
      this.props.sparePartReservations.length > 0 &&
      this.props.sparePartReservations.every(
        sparePartReservation =>
          this.props.sparePartWithdrawals.find(
            sparePartWithdrawal => sparePartReservation.spare_part_id == sparePartWithdrawal.spare_part_id
          ) == null
      )
    );
  };

  showNoSpentTimesWarning = () => {
    if (this.state.showNoSpentTimesWarning === false) return false;
    return (
      this.props.settings.work_order_spent_time_activated === true &&
      this.props.workOrder.work_order_spent_times &&
      this.props.workOrder.work_order_spent_times.length === 0 &&
      this.props.workOrder.operational_maintenance === false
    );
  };

  renderWarnings = () => {
    if (this.props.workOrder.operational_maintenance) {
      return null;
    }
    if (this.state.showSpentTimeRequiredError || this.state.showChecklistRequiredError) {
      return null;
    }
    if (
      !this.showNoSpentTimesWarning() &&
      !this.showUnusedSparePartsWarning() &&
      !this.showActiveDowntimeWarning()
    ) {
      return null;
    }
    return (
      <div className={styles['banners-container']}>
        <Banner.List>
          {this.renderActiveDowntimeWarnings()}
          {this.renderSpentTimeRequiredWarning()}
          {this.renderUnusedSparePartsListItem()}
        </Banner.List>
      </div>
    );
  };

  renderSpentTimeRequiredError = () => {
    if (this.state.showSpentTimeRequiredError === false) {
      return null;
    }
    return (
      <Banner red>
        <FormattedMessage dark size={13} id="screens.work-order.errors.spent-time-required" />
      </Banner>
    );
  };

  renderChecklistRequiredError = () => {
    if (this.state.showChecklistRequiredError === false) {
      return null;
    }
    return (
      <Banner red>
        <FormattedMessage dark size={13} id="screens.work-order.errors.checklist-required" />
      </Banner>
    );
  };

  renderErrors = () => {
    if (this.state.showSpentTimeRequiredError || this.state.showChecklistRequiredError) {
      return (
        <div className={styles['banners-container']}>
          <Banner.List>
            {this.renderSpentTimeRequiredWarning()}
            {this.renderSpentTimeRequiredError()}
            {this.renderChecklistRequiredError()}
          </Banner.List>
        </div>
      );
    }
    return null;
  };

  renderNextMessageForRecurring = () => {
    if (!this.props.recurringMaintenance || this.props.recurringMaintenance.status === 'archived') {
      return null;
    }
    if (this.props.workOrder.operational_maintenance) {
      return null;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid.Separator />
            <div>
              <Field
                label={<FormattedMessage id="screens.work-order.completion-modal.comment-for-next.title" />}
                subtitle="screens.work-order.completion-modal.comment-for-next.subtitle"
              >
                <Field.Textarea
                  minRows={3}
                  value={this.state.comment_for_next_work_order}
                  onChange={value => this.setState({ comment_for_next_work_order: value })}
                />
              </Field>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderCompletedContent = () => {
    const { completed_date, comment_for_next_work_order, completed_comment, completed_by_user_id } =
      this.state;
    return (
      <>
        <div className={styles['warnings-container']}>
          {this.renderWarnings()}
          {this.renderErrors()}
        </div>
        <CompleteWorkOrderContent
          workOrder={{
            ...this.props.workOrder,
            completed_by_user_id,
            completed_date,
            comment_for_next_work_order,
            completed_comment,
          }}
          showCompletedDateIsRequiredError={this.state.showCompletedDateIsRequiredError}
          isDeletingAttachment={this.state.isDeletingAttachment}
          onDeleteAttachment={attachment => this.deleteCompletionAttachment(attachment)}
          onChangeValue={params => this.setState(params)}
          onShowCompletedDateError={() => this.setState({ showCompletedDateIsRequiredError: true })}
          onHideCompletedDateError={() => this.setState({ showCompletedDateIsRequiredError: false })}
          onCreateAttachment={params => this.createCompletionAttachment(params)}
        />
        {this.renderNextMessageForRecurring()}
      </>
    );
  };

  getPrimaryButtonLabel = () => {
    if (this.props.workOrder.status === WorkOrderStatus.Completed) {
      return 'general.save';
    }
    return 'screens.work-order.completion-modal.complete';
  };

  renderFooterButtons = () => {
    if (this.props.openedFromChecklist) {
      return (
        <div className={styles['buttons']}>
          <div className={styles['primary']}>
            <Button.Group>
              <Button
                primary
                disabled={this.state.showCompletedDateIsRequiredError}
                loading={this.state.isSaving}
                onClick={this.save}
                label={this.getPrimaryButtonLabel()}
              />
              <Button
                onClick={this.props.onClose}
                label="screens.work-order.completion-modal.complete-later"
              />
            </Button.Group>
          </div>
          <div>
            <Button label="general.cancel" onClick={this.props.onClose} />
          </div>
        </div>
      );
    }
    return (
      <Button.Group>
        <Button
          primary
          disabled={this.state.showCompletedDateIsRequiredError}
          loading={this.state.isSaving}
          onClick={this.save}
          label={this.getPrimaryButtonLabel()}
        />
        <Button label="general.cancel" onClick={this.props.onClose} />
      </Button.Group>
    );
  };

  renderSpentTimeModal = () => {
    return (
      <SpentTimeModal
        open={this.state.showAddSpentTimeModal}
        workOrderId={this.props.workOrder.id}
        onSaved={() => {
          this.setState({ showAddSpentTimeModal: false, showSpentTimeRequiredError: false });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
        onClose={() => {
          this.setState({ showAddSpentTimeModal: false });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
      />
    );
  };

  renderDowntimeModal = () => {
    return (
      <DowntimeModal
        open={this.state.showDowntimeModalForId !== null}
        id={this.state.showDowntimeModalForId}
        onClose={() => {
          this.setState({ showDowntimeModalForId: null });
          setTimeout(() => {
            this.setState({ open: true });
          }, 250);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} width={600}>
          <DropZone onDrop={data => this.createCompletionAttachment(data)}>
            <Modal.Header
              title={<FormattedMessage id="screens.work-order.completion-modal.title" />}
              onClose={this.props.onClose}
            />
            <Modal.Content>{this.renderCompletedContent()}</Modal.Content>
            <Modal.Footer>{this.renderFooterButtons()}</Modal.Footer>
          </DropZone>
        </Modal>
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
        {this.renderDowntimeModal()}
        {this.renderSpentTimeModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      beginUpload: UploadProgressOperations.beginUpload,
      cancelUpload: UploadProgressOperations.cancelUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      updateEntities: EntityOperations.updateEntities,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
      createCompletionAttachmentForWorkOrder: SDKReduxOperations.createCompletionAttachmentForWorkOrder,
      createSparePartWithdrawalForWorkOrder: SDKReduxOperations.createSparePartWithdrawalForWorkOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.workOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
    downtimes: EntitySelectors.getDowntimes(state, ownProps.workOrder.downtimes),
    currentUser: AuthSelectors.getCurrentUser(state),
    sparePartReservations:
      EntitySelectors.getSparePartReservations(state, ownProps.workOrder.spare_part_reservations) || [],
    sparePartWithdrawals:
      EntitySelectors.getSparePartWithdrawals(state, ownProps.workOrder.spare_part_withdrawals) || [],
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteWorkOrderModal);
