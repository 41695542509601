export const AttachmentVersionStatus = {
  Active: 'active',
  Uploading: 'uploading',
  History: 'history',
};

export const getIconClassNameString = attachmentVersion => {
  if (attachmentVersion.type === 'link') return 'link';
  switch (attachmentVersion.mime_type) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return 'file-image';
    case 'application/pdf':
      return 'file-pdf';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'file-word';
    case 'application/mspowerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'file-powerpoint';
    case 'application/msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-excel';
    case 'audio/aac':
    case 'audio/wav':
    case 'audio/mpeg':
    case 'audio/mp4':
    case 'audio/ogg':
      return 'file-audio';
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/mp4':
    case 'video/ogg':
    case 'video/quicktime':
    case 'video/webm':
      return 'file-video';
    case 'application/gzip':
    case 'application/zip':
      return 'file-archive';
    case 'text/css':
    case 'text/html':
    case 'text/javascript':
    case 'application/javascript':
      return 'file-code';
    case 'text/csv':
    case 'text/plain':
    case 'text/richtext':
    case 'text/rtf':
      return 'file-alt';
    default:
      return 'file-alt';
  }
};

export const getIconClassNameStringForReactNative = attachmentVersion => {
  if (attachmentVersion.type === 'link') return 'link';
  switch (attachmentVersion.mime_type) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return 'file-image';
    case 'application/pdf':
      return 'file-pdf';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'file-word';
    case 'application/mspowerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'file powerpoint';
    case 'application/msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-excel';
    case 'audio/aac':
    case 'audio/wav':
    case 'audio/mpeg':
    case 'audio/mp4':
    case 'audio/ogg':
      return 'file-audio';
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/mp4':
    case 'video/ogg':
    case 'video/quicktime':
    case 'video/webm':
      return 'file-video';
    case 'application/gzip':
    case 'application/zip':
      return 'file-archive';
    case 'text/css':
    case 'text/html':
    case 'text/javascript':
    case 'application/javascript':
      return 'file-code';
    case 'text/csv':
    case 'text/plain':
    case 'text/richtext':
    case 'text/rtf':
      return 'file-alt';
    default:
      return 'file-alt';
  }
};

export const getIconColor = attachmentVersion => {
  if (attachmentVersion.type === 'link') return null;
  switch (attachmentVersion.mime_type) {
    case 'application/pdf':
      return '#A53B3B';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '#568FCC';
    case 'application/msexcel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return '#3EA43D';
    default:
      return null;
  }
};
