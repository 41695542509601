import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import Decimal from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import {
  Field,
  MoneyWithCurrency,
  ViewTextArea,
  Banner,
  Button,
  Icon,
  Tooltip,
} from 'views/components/Shared/General';
import { Grid } from 'views/components/Shared/Layout';
import { UserNameWrapper } from 'views/components/User';
import { Title, SparePartUnitWithValue } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import { AssetTitle, TreePath } from 'views/components/Asset';

class Info extends Component {
  renderCreatedForOperationalMaintenance = () => {
    if (!this.props.operationalMaintenanceTemplateVersion) return null;

    return (
      <div className={styles['created-for']}>
        <Banner orange>
          <div className={styles['banner-content']}>
            <div className={styles['created-for-title']}>
              <span>
                <FormattedMessage id="components.spare-part-withdrawal-modal.withdrawal-for-operational-maintenance" />
                {': '}
                {this.props.operationalMaintenanceTemplateVersion.title}
              </span>
            </div>
            <div>
              <Tooltip
                trigger={
                  <Link to={`/operational-maintenances/${this.props.operationalMaintenanceInstance.id}`}>
                    <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                  </Link>
                }
                label={<FormattedMessage id="general.navigate-to.operational-maintenance" />}
              />
            </div>
          </div>
        </Banner>
      </div>
    );
  };
  renderCreatedForWorkOrder = () => {
    if (!this.props.workOrder) return null;
    return (
      <div className={styles['created-for']}>
        <Banner orange>
          <div className={styles['banner-content']}>
            <div className={styles['created-for-title']}>
              <FormattedMessage id="components.spare-part-withdrawal-modal.withdrawal-for-work-order" />
              <span>:&nbsp;</span>
              <span>
                #{this.props.workOrder.number} - {this.props.workOrder.title}
              </span>
            </div>
            <div>
              <Tooltip
                trigger={
                  <Link to={`/work-orders/${this.props.workOrder.id}`}>
                    <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                  </Link>
                }
                label={<FormattedMessage id="general.navigate-to.work-order" />}
              />
            </div>
          </div>
        </Banner>
      </div>
    );
  };

  renderCostAmount = () => {
    if (this.props.sparePartWithdrawal.purchase_price_currency !== this.props.currentSystem.currency) {
      const localValue = new Decimal(this.props.sparePartWithdrawal.purchase_price || 0)
        .times(new Decimal(this.props.sparePartWithdrawal.purchase_price_exchange_rate))
        .toDecimalPlaces(2)
        .toString();
      return (
        <>
          <span>
            <MoneyWithCurrency
              value={this.props.sparePartWithdrawal.purchase_price}
              currency={this.props.sparePartWithdrawal.purchase_price_currency}
            />
          </span>
          <span>&nbsp;</span>
          <span className={styles['system-currency']}>
            (
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />)
          </span>
        </>
      );
    }
    return (
      <MoneyWithCurrency
        value={this.props.sparePartWithdrawal.purchase_price}
        currency={this.props.sparePartWithdrawal.purchase_price_currency}
      />
    );
  };

  renderTotalCostAmount = () => {
    if (this.props.sparePartWithdrawal.purchase_price_currency !== this.props.currentSystem.currency) {
      const total = new Decimal(this.props.sparePartWithdrawal.purchase_price || 0)
        .times(new Decimal(this.props.sparePartWithdrawal.quantity))
        .toDecimalPlaces(2)
        .toString();
      const localValue = new Decimal(total)
        .times(new Decimal(this.props.sparePartWithdrawal.purchase_price_exchange_rate))
        .toDecimalPlaces(2)
        .toString();
      return (
        <>
          <div className={styles['subtitle']}>
            <span>
              <MoneyWithCurrency
                value={total}
                currency={this.props.sparePartWithdrawal.purchase_price_currency}
              />
            </span>
            <span>&nbsp;</span>
            <span className={styles['system-currency']}>
              (
              <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />)
            </span>
          </div>
        </>
      );
    }
    const total = new Decimal(this.props.sparePartWithdrawal.purchase_price || 0)
      .times(new Decimal(this.props.sparePartWithdrawal.quantity))
      .toDecimalPlaces(2)
      .toString();
    return (
      <div className={styles['subtitle']}>
        <FormattedMessage id="components.spare-part-withdrawal-modal.total" />
        <span>: </span>
        <MoneyWithCurrency value={total} currency={this.props.sparePartWithdrawal.purchase_price_currency} />
      </div>
    );
  };

  renderCostColumn = () => {
    const { canViewWorkOrderCosts, hasProTier } = this.props;
    if (canViewWorkOrderCosts && hasProTier) {
      return (
        <Grid.Column>
          <Field
            view
            label={<FormattedMessage id="components.spare-part-withdrawal-modal.cost-per" />}
            fontSize={12}
          >
            {this.renderCostAmount()}
            {this.renderTotalCostAmount()}
          </Field>
        </Grid.Column>
      );
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.sparePart) {
      return (
        <>
          <div>
            <Title sparePart={this.props.sparePart} />
          </div>
          <div>
            <Tooltip
              trigger={
                <Link to={`/spare-parts/${this.props.sparePart.id}`}>
                  <Button type="icon" icon={<Icon regular size={13} type="arrow-circle-right" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.spare-part" />}
            />
          </div>
        </>
      );
    }
    return this.props.sparePartWithdrawal.title;
  };

  render() {
    return (
      <>
        <Grid>
          {this.renderCreatedForWorkOrder()}
          {this.renderCreatedForOperationalMaintenance()}
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                label={<FormattedMessage id="components.spare-part-withdrawal-modal.spare-part" />}
                fontSize={12}
              >
                <div className={styles['spare-part-title-container']}>{this.renderTitle()}</div>
              </Field>
            </Grid.Column>
          </Grid.Row>
          {this.props.asset ? (
            <Grid.Row>
              <Grid.Column>
                <Field view fontSize={12} label={<FormattedMessage id="resources.work-order.asset" />}>
                  <AssetTitle id={this.props.asset.id} />
                  <TreePath assetId={this.props.asset.id} />
                </Field>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                label={<FormattedMessage id="components.spare-part-withdrawal-modal.date" />}
                fontSize={12}
              >
                {moment(this.props.sparePartWithdrawal.created_at)
                  .tz(this.props.currentSystem.timezone)
                  .format('LLL')}
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field
                view
                label={<FormattedMessage id="components.spare-part-withdrawal-modal.created-by" />}
                fontSize={12}
              >
                <UserNameWrapper user={this.props.createdByUser} />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                label={<FormattedMessage id="components.spare-part-withdrawal-modal.quantity" />}
                fontSize={12}
              >
                <SparePartUnitWithValue
                  sparePartUnit={this.props.sparePartUnit}
                  value={this.props.sparePartWithdrawal.quantity}
                />
              </Field>
            </Grid.Column>
            {this.renderCostColumn()}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view
                label={<FormattedMessage id="components.spare-part-withdrawal-modal.comment" />}
                fontSize={12}
              >
                <ViewTextArea>{this.props.sparePartWithdrawal.comment || '-'}</ViewTextArea>
              </Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
  let sparePartUnit = null;
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  } else {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }
  const operationalMaintenanceInstance = EntitySelectors.getOperationalMaintenanceInstance(
    state,
    sparePartWithdrawal.operational_maintenance_instance_id
  );
  let operationalMaintenanceTemplateVersion = null;
  if (operationalMaintenanceInstance) {
    operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
      state,
      operationalMaintenanceInstance.operational_maintenance_template_version_id
    );
  }
  return {
    sparePartWithdrawal,
    sparePart,
    sparePartUnit,
    operationalMaintenanceInstance,
    operationalMaintenanceTemplateVersion,
    asset: EntitySelectors.getAsset(state, sparePartWithdrawal.asset_id),
    workOrder: EntitySelectors.getWorkOrder(state, sparePartWithdrawal.work_order_id),
    createdByUser: EntitySelectors.getUser(state, sparePartWithdrawal.created_by_user_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(Info);
