import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { getShouldOrderQuantity } from 'sdk/SparePart';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { MoneyWithCurrency, Tooltip, Loader } from 'views/components/Shared/General';
import { MultipleSparePartVendorsModal } from 'views/components/SparePartVendor';
import { SparePartUnitWithValue } from 'views/components/SparePart';

class VendorListItemForSparePart extends Component {
  state = {
    showSelectSparePartVendorModal: false,
  };

  selectVendor = () => {
    const { sparePartVendors } = this.props;
    if (sparePartVendors.length === 1) {
      this.props.onCreatePurchaseOrder(this.props.vendor, {
        spare_part_vendor_id: sparePartVendors[0].id,
        quantity: getShouldOrderQuantity(this.props.sparePart) || '0',
      });
    } else {
      this.setState({
        showSelectSparePartVendorModal: true,
        selectedVendorId: this.props.vendorId,
      });
    }
  };

  renderArticleNumber = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      if (sparePartVendor.number) {
        return (
          <>
            <span>{`#${sparePartVendor.number}`}</span>
            <span> - </span>
          </>
        );
      }
    }

    return null;
  };

  renderArticleTitle = () => {
    if (this.props.sparePartVendors.length === 1) {
      const sparePartVendor = this.props.sparePartVendors[0];
      const title = sparePartVendor.title || this.props.sparePart.title;
      return <span>{title}</span>;
    }
    return null;
  };

  renderSubtitle = () => {
    if (this.props.sparePartVendors.length === 1) {
      return (
        <>
          {this.renderArticleNumber()}
          {this.renderArticleTitle()}
        </>
      );
    }
    return (
      <FormattedPlural
        value={this.props.sparePartVendors.length}
        two={
          <FormattedMessage
            id="screens.spare-part.vendors.amount-of-options.two"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        few={
          <FormattedMessage
            id="screens.spare-part.vendors.amount-of-options.few"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        many={
          <FormattedMessage
            id="screens.spare-part.vendors.amount-of-options.many"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
        other={
          <FormattedMessage
            id="screens.spare-part.vendors.amount-of-options.other"
            values={{ amount: this.props.sparePartVendors.length }}
          />
        }
      />
    );
  };

  renderPrice = () => {
    const { sparePartVendors } = this.props;
    if (sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.purchase_price) {
        return (
          <List.Item.Column alignRight>
            <MoneyWithCurrency
              value={sparePartVendor.purchase_price}
              currency={this.props.vendor.purchase_order_currency}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  renderPackageQuantity = () => {
    const { sparePartVendors } = this.props;
    if (sparePartVendors.length === 1) {
      const sparePartVendor = sparePartVendors[0];
      if (sparePartVendor.package_quantity) {
        return (
          <List.Item.Column alignRight>
            <Tooltip
              label={
                <>
                  <FormattedMessage id="resources.spare-part-vendor.package-quantity" />
                  <span>: </span>
                  <SparePartUnitWithValue
                    sparePartUnit={this.props.sparePartUnit}
                    value={sparePartVendor.package_quantity}
                  />
                </>
              }
              trigger={<Icon type="box-open" withBackground blue size={11} backgroundSize={22} />}
            />
          </List.Item.Column>
        );
      }
    }
    return null;
  };

  renderMultipleSparePartVendorsModal = () => {
    return (
      <MultipleSparePartVendorsModal
        select
        open={this.state.showSelectSparePartVendorModal}
        vendorId={this.props.vendorId}
        sparePartId={this.props.sparePartId}
        onSelectSparePartVendor={sparePartVendor => {
          this.setState({ showSelectSparePartVendorModal: false });
          let quantity = '0';
          if (sparePartVendor.package_quantity == null) {
            quantity = getShouldOrderQuantity(this.props.sparePart) || '0';
          }
          this.props.onCreatePurchaseOrder(this.props.vendor, {
            spare_part_vendor_id: sparePartVendor.id,
            quantity,
          });
        }}
        onClose={() => this.setState({ showSelectSparePartVendorModal: false })}
      />
    );
  };

  renderLoader = () => {
    if (this.props.loading) {
      return (
        <List.Item.Column width={20}>
          <Loader tiny />
        </List.Item.Column>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <List.Item clickable onClick={this.selectVendor}>
          <List.Item.TitleColumn title={this.props.vendor.name} subtitle={this.renderSubtitle()} />
          {this.renderPackageQuantity()}
          {this.renderPrice()}
          {this.renderLoader()}
        </List.Item>
        {this.renderMultipleSparePartVendorsModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let sparePartUnit = null;
  const vendor = EntitySelectors.getVendor(state, ownProps.vendorId);
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  }
  const sparePartVendors = EntitySelectors.getSparePartVendors(
    state,
    vendor.spare_part_vendors_for_spare_part
  );
  return {
    vendor,
    sparePartVendors,
    sparePart,
    sparePartUnit,
  };
}

export default connect(mapStateToProps)(VendorListItemForSparePart);
