import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Status, ViewTextArea } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import styles from '../style.module.scss';

class HistoryStocktakingListItem extends Component {
  renderDescription = () => {
    if (this.props.stocktaking.description) {
      return (
        <div className={styles['description']}>
          <ViewTextArea>{this.props.stocktaking.description}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          linkTo={{
            pathname: `/stocktaking/${this.props.stocktakingId}`,
          }}
          new={this.props.new}
          disclosureIndicator
          dataPillsComponent={this.renderDescription()}
        >
          <List.Item.TitleColumn
            title={moment(this.props.stocktaking.completed_at).format('LL')}
            subtitle={
              <FormattedMessage
                id="screens.stocktaking.history.completed-by"
                values={{
                  name: <UserNameWrapper user={this.props.completedByUser} />,
                }}
              />
            }
          />
          <List.Item.Column alignRight width={200}>
            <Status label="screens.stocktaking.history.status" backgroundColor="#2BAF2D" />
          </List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const stocktaking = EntitySelectors.getStocktaking(state, ownProps.stocktakingId);
  return {
    stocktaking,
    completedByUser: EntitySelectors.getUser(state, stocktaking.completed_by_user_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps)(HistoryStocktakingListItem));
