import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Button, Tooltip } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { Title } from 'views/components/WorkOrder';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class PreviewWorkOrderHeader extends Component {
  render() {
    return (
      <div className={styles['header']}>
        <div className={styles['title-container']} title={this.props.workOrder.title}>
          <div className={styles['title']}>
            <Title workOrder={this.props.workOrder} />
          </div>
          <div className={styles['buttons-container']}>
            <Tooltip
              trigger={
                <Link
                  to={{
                    pathname: `/work-orders/${this.props.workOrder.id}`,
                  }}
                >
                  <Button type="icon" icon={<Icon regular size={16} type="expand" />} />
                </Link>
              }
              label={<FormattedMessage id="general.navigate-to.work-order" />}
            />
          </div>
        </div>
        {this.props.asset == null ? null : <TreePath size={13} fullPath assetId={this.props.asset.id} />}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrder = EntitySelectors.getWorkOrder(state, ownProps.workOrderId);
  return {
    workOrder,
    asset: EntitySelectors.getAsset(state, workOrder.asset_id),
  };
}

export default connect(mapStateToProps)(PreviewWorkOrderHeader);
