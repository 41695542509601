import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const TemplateFieldItemContainer = ({ templateFieldItem }) => {
  return templateFieldItem.title;
};

function mapStateToProps(state, ownProps) {
  return {
    templateFieldItem: EntitySelectors.getTemplateFieldItem(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(TemplateFieldItemContainer);
