import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { API, SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Button, EmptyDataSet, List, Icon, NewInlineModal } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { normalizeUser } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { Modal } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';
import UserListItem from './UserListItem';

class GroupMembersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalEntries: 0,
      },
      membersAlreadyInGroup: [],
      membersAddedToGroup: [],
      membersAvailableForAdding: [],
      membersSelectedForAdding: [],
      loading: false,
      availableMembersDropdownIsOpen: false,
      isFetchingDataForView: true,
      isRemovingUser: false,
      isRemovingUserId: null,
      isAddingUsers: false,
      userSearchTerm: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        userSearchTerm: '',
        isFetchingDataForView: true,
        membersAddedToGroup: [],
        membersSelectedForAdding: [],
      });
      return Promise.all([
        API.listUsers(this.props.selectedSystem.id, {
          no_pagination: true,
          group_id: {
            [HelperFunctions.FILTER_COMPARABLES.NoneOf]: this.props.groupId,
          },
          member: true,
          archived: false,
        }),
        API.listUsers(this.props.selectedSystem.id, {
          group_id: this.props.groupId,
          no_pagination: true,
        }),
      ]).then(([eligibleForAddingRes, membersAlreadyInGroupRes]) => {
        const { entities: eligibleForAddingEntities, result: eligibleForAddingIds } = normalizeUser(
          eligibleForAddingRes.data
        );
        const { entities: membersAlreadyInGroupEntities, result: membersAlreadyInGroupIds } = normalizeUser(
          membersAlreadyInGroupRes.data
        );
        this.props.updateEntities({ ...eligibleForAddingEntities, ...membersAlreadyInGroupEntities });
        const membersAvailableForAdding = eligibleForAddingIds.map(
          id => eligibleForAddingEntities.userById[id]
        );
        const membersAlreadyInGroup = membersAlreadyInGroupIds.map(
          id => membersAlreadyInGroupEntities.userById[id]
        );
        const pagination = HelperFunctions.getPaginationFromHeader(membersAlreadyInGroupRes.headers);
        this.setState({
          pagination,
          membersAlreadyInGroup,
          membersAvailableForAdding,
          isFetchingDataForView: false,
        });
      });
    }
  }

  addSelectedUsers = () => {
    this.setState({ isAddingUsers: true });
    this.props
      .addUsers(this.props.groupId, this.state.membersSelectedForAdding.map(({ id }) => id).join(','))
      .then(({ data: users }) => {
        const { entities, result } = normalizeUser(users);
        this.props.updateEntities(entities);
        this.setState({
          isAddingUsers: false,
          membersAddedToGroup: [
            ...result.map(id => entities.userById[id]),
            ...this.state.membersAddedToGroup,
          ],
          membersSelectedForAdding: [],
        });
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.settings.work-orders.groups.group-members.add-users-success" />
            }
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  removeUser = (groupId, removedUser) => {
    this.setState({ isRemovingUserId: removedUser.id });
    this.props
      .removeUser(groupId, removedUser.id)
      .then(() => {
        let newMembersAvailableForAdding = [removedUser, ...this.state.membersAvailableForAdding];
        this.setState({
          isRemovingUserId: null,
          membersAlreadyInGroup: this.state.membersAlreadyInGroup.filter(user => user.id !== removedUser.id),
          membersAddedToGroup: this.state.membersAddedToGroup.filter(user => user.id !== removedUser.id),
          membersAvailableForAdding: newMembersAvailableForAdding,
        });
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.settings.work-orders.groups.group-members.remove-user-success" />
            }
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  selectUser = user => {
    this.setState(
      {
        membersSelectedForAdding: [...this.state.membersSelectedForAdding, user],
        membersAvailableForAdding: this.state.membersAvailableForAdding.filter(({ id }) => id !== user.id),
      },
      () => {
        if (this.state.membersAvailableForAdding.length === 0) {
          this.setState({ isOpen: false });
        }
      }
    );
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.work-orders.groups.group-members.empty-data-set.title" />}
      modal
      small
    />
  );

  renderUsers = () => {
    const { membersAlreadyInGroup, membersAddedToGroup } = this.state;
    let users = [...membersAddedToGroup, ...membersAlreadyInGroup];
    if (users.length === 0) return null;
    return (
      <List small>
        {users.map(user => (
          <UserListItem
            isRemovingUserId={this.state.isRemovingUserId}
            user={user}
            onDelete={() => this.removeUser(this.props.groupId, user)}
          />
        ))}
      </List>
    );
  };

  renderLoading = () => {
    const { membersAlreadyInGroup, membersAddedToGroup } = this.state;
    const amountOfUsers =
      membersAlreadyInGroup.length + membersAddedToGroup.length === 0
        ? 1
        : membersAlreadyInGroup.length + membersAddedToGroup.length;
    return (
      <List small>
        {Array(amountOfUsers)
          .fill()
          .map(() => (
            <List.Item>
              <List.Item.TitleColumn loading />
            </List.Item>
          ))}
      </List>
    );
  };

  renderList = () => {
    return (
      <>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="screens.settings.work-orders.groups.group-members.member-title" />
          </List.Header.Column>
        </List.Header>
        {this.state.loading ? this.renderLoading() : this.renderUsers()}
      </>
    );
  };

  renderPlaceholder = () => {
    const { membersSelectedForAdding } = this.state;
    if (membersSelectedForAdding.length === 0) {
      return (
        <div
          className={styles['users']}
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
            }));
          }}
        >
          <span className={styles['placeholder']}>
            <FormattedMessage id="screens.settings.work-orders.groups.group-members.add-users" />
          </span>
        </div>
      );
    }
    return (
      <div
        className={`${styles['users']} ${styles['has-data']}`}
        ref={ref => (this.inlineModalPositioningRef = ref)}
        onClick={() => {
          this.setState(prevState => ({
            isOpen: !prevState.isOpen,
          }));
        }}
      >
        {this.state.membersSelectedForAdding.map(user => (
          <div className={styles['user']} onClick={e => e.stopPropagation()}>
            <UserNameWrapper user={user} />
            <div
              className={styles['icon-container']}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  membersSelectedForAdding: this.state.membersSelectedForAdding.filter(
                    loopedUser => loopedUser.id !== user.id
                  ),
                  membersAvailableForAdding: [...this.state.membersAvailableForAdding, user],
                });
              }}
            >
              <Icon white type="times" size={10} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderSelectedUsers = () => {
    const { membersAvailableForAdding } = this.state;
    const users = membersAvailableForAdding.filter(
      user =>
        this.state.userSearchTerm === '' ||
        user.name.toLowerCase().includes(this.state.userSearchTerm.toLowerCase())
    );
    return (
      <>
        <div className={styles['dropdown']}>{this.renderPlaceholder()}</div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          <NewInlineModal.Header.Search
            autoFocus
            placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
            value={this.state.userSearchTerm}
            onChange={value => this.setState({ userSearchTerm: value })}
            onClear={() => this.setState({ userSearchTerm: '' })}
          />
          {users.length === 0 ? (
            <NewInlineModal.Dropdown.EmptyDataSet>
              {this.state.userSearchTerm.length === 0 ? (
                <FormattedMessage id="screens.settings.work-orders.groups.group-members.users-already-added" />
              ) : (
                <FormattedMessage id="general.empty-data-set-search.title" />
              )}
            </NewInlineModal.Dropdown.EmptyDataSet>
          ) : (
            <NewInlineModal.Dropdown>
              <NewInlineModal.Dropdown.Items>
                {users.map(user => {
                  return (
                    <NewInlineModal.Dropdown.Item onClick={() => this.selectUser(user)}>
                      <UserNameWrapper user={user} />
                    </NewInlineModal.Dropdown.Item>
                  );
                })}
              </NewInlineModal.Dropdown.Items>
            </NewInlineModal.Dropdown>
          )}
        </NewInlineModal>
      </>
    );
  };

  renderContent = () => {
    return (
      <React.Fragment>
        <Modal.Header
          title={
            <FormattedMessage
              id="screens.settings.work-orders.groups.group-members.title"
              values={{ name: this.props.group.title }}
            />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div
            className={`${styles['add-users']} ${
              this.state.membersSelectedForAdding.length === 0 ? styles['no-chosen-users'] : ''
            }`}
          >
            <div className={styles['add-container']}>
              {this.renderSelectedUsers()}
              <Button
                primary
                loading={this.state.isAddingUsers}
                disabled={this.state.membersSelectedForAdding.length === 0}
                onClick={this.addSelectedUsers}
                label="general.add"
                small
              />
            </div>
          </div>
          {this.state.membersAlreadyInGroup.length === 0 && this.state.membersAddedToGroup.length === 0
            ? this.renderEmptyDataset()
            : this.renderList()}
        </Modal.Content>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.open} width={730}>
          {this.state.isFetchingDataForView ? (
            <React.Fragment>
              <Modal.Content loading />
            </React.Fragment>
          ) : (
            this.renderContent()
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      addUsers: SDKReduxOperations.addUsers,
      removeUser: SDKReduxOperations.removeUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    group: EntitySelectors.getGroup(state, ownProps.groupId),
    selectedSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GroupMembersModal));
