import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List, NewSearchField } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditWorkOrderTypeModal from './EditWorkOrderTypeModal';
import DeleteWorkOrderTypeModal from './DeleteWorkOrderTypeModal';
import styles from './style.module.scss';

class Types extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewInitialized: false,
      showWorkOrderTypeModal: false,
      showDeleteWorkOrderTypeModal: false,
      showDeleteWorkOrderTypeModalForWorkOrderTypeId: null,
      searchTerm: '',
    };
  }

  componentDidMount() {
    this.props.fetchWorkOrderTypes(this.props.selectedSystem.id, { work_order_count: true }).then(() => {
      this.setState({ viewInitialized: true });
    });
  }

  listIsEmpty = () =>
    this.props.workOrderTypes.length === 0 &&
    this.props.createdWorkOrderTypes.length === 0 &&
    this.state.searchTerm.length === 0;

  renderSearchedEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="general.empty-data-set-search.title" />}
      subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
      modal
    />
  );

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.work-orders.types.empty-data-set.title" />}
      subtitle={<FormattedMessage id="screens.settings.work-orders.types.empty-data-set.subtitle" />}
      button={
        <Button
          primary
          onClick={() => this.setState({ showWorkOrderTypeModal: true, showWorkOrderTypeModalForId: null })}
          label="screens.settings.work-orders.types.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.work-order-type.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoader = () => (
    <React.Fragment>
      <Toolbar
        buttons={
          <Button
            primary
            onClick={() => this.setState({ showWorkOrderTypeModal: true, showWorkOrderTypeModalForId: null })}
            label="screens.settings.work-orders.types.add-button"
          />
        }
        search={
          <NewSearchField
            value={this.state.searchTerm}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            onSearch={searchTerm => this.setState({ searchTerm })}
          />
        }
      />
      {this.renderHeader()}
      <List small>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
        <List.Item>
          <List.Item.TitleColumn loading />
        </List.Item>
      </List>
    </React.Fragment>
  );

  renderWorkOrderTypes = () => {
    const filteredWorkOrderTypes = this.props.workOrderTypes.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const filteredCreatedWorkOrderTypes = this.props.createdWorkOrderTypes.filter(
      wot =>
        this.state.searchTerm === '' || wot.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    const foundNoSearchResult =
      filteredCreatedWorkOrderTypes.length === 0 && filteredWorkOrderTypes.length === 0;
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({ showWorkOrderTypeModal: true, showWorkOrderTypeModalForId: null })
              }
              label="screens.settings.work-orders.types.add-button"
            />
          }
          search={
            <NewSearchField
              value={this.state.searchTerm}
              placeholder={this.props.intl.formatMessage({
                id: 'general.search-placeholder',
              })}
              onSearch={searchTerm => {
                this.setState({ searchTerm });
              }}
              onClear={() => this.setState({ searchTerm: '' })}
            />
          }
        />
        {foundNoSearchResult ? (
          this.renderSearchedEmptyDataset()
        ) : (
          <>
            {this.renderHeader()}
            <List small>
              {filteredCreatedWorkOrderTypes.map(workOrderType => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showWorkOrderTypeModal: true,
                      showWorkOrderTypeModalForId: workOrderType.id,
                    })
                  }
                >
                  <List.Item.TitleColumn title={workOrderType.title} />
                  {this.renderRightColumn(workOrderType)}
                </List.Item>
              ))}
              {filteredWorkOrderTypes.map(workOrderType => (
                <List.Item
                  clickable
                  onClick={() =>
                    this.setState({
                      showWorkOrderTypeModal: true,
                      showWorkOrderTypeModalForId: workOrderType.id,
                    })
                  }
                >
                  <List.Item.TitleColumn title={workOrderType.title} />
                  {this.renderRightColumn(workOrderType)}
                </List.Item>
              ))}
            </List>
          </>
        )}
      </>
    );
  };

  renderRightColumn = workOrderType => {
    const { work_order_count: count } = workOrderType;
    if (count === 0) {
      return (
        <span className={`${styles['work-order-count']} ${styles['never-used']}`}>
          <FormattedMessage id="screens.settings.work-orders.types.never-used" />
        </span>
      );
    }
    return (
      <List.Item.Column alignRight>
        <span className={styles['work-order-count']}>
          <FormattedPlural
            value={count}
            one={<FormattedMessage id="screens.settings.work-orders.types.used-amount.one" />}
            two={
              <FormattedMessage id="screens.settings.work-orders.types.used-amount.two" values={{ count }} />
            }
            few={
              <FormattedMessage id="screens.settings.work-orders.types.used-amount.few" values={{ count }} />
            }
            many={
              <FormattedMessage id="screens.settings.work-orders.types.used-amount.many" values={{ count }} />
            }
            other={
              <FormattedMessage
                id="screens.settings.work-orders.types.used-amount.other"
                values={{ count }}
              />
            }
          />
        </span>
      </List.Item.Column>
    );
  };

  renderContent() {
    if (!this.state.viewInitialized) {
      return this.renderLoader();
    } else if (this.listIsEmpty()) {
      return this.renderEmptyDataset();
    } else {
      return this.renderWorkOrderTypes();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/work-orders" />
        <Section
          title={<FormattedMessage id="screens.settings.work-orders.types.title" />}
          subtitle={<FormattedMessage id="screens.settings.work-orders.types.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditWorkOrderTypeModal
          open={this.state.showWorkOrderTypeModal}
          id={this.state.showWorkOrderTypeModalForId}
          onDelete={workOrderTypeId => {
            this.setState({
              showWorkOrderTypeModal: false,
            });
            setTimeout(() => {
              this.setState({
                showDeleteWorkOrderTypeModalForWorkOrderTypeId: workOrderTypeId,
                showDeleteWorkOrderTypeModal: true,
              });
            }, 250);
          }}
          onClose={() => this.setState({ showWorkOrderTypeModal: false })}
        />
        <DeleteWorkOrderTypeModal
          open={this.state.showDeleteWorkOrderTypeModal}
          id={this.state.showDeleteWorkOrderTypeModalForWorkOrderTypeId}
          onClose={() =>
            this.setState({
              showDeleteWorkOrderTypeModal: false,
            })
          }
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrderTypes: SettingsOperations.fetchWorkOrderTypes,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    createdWorkOrderTypes: SettingsSelectors.getCreatedWorkOrderTypes(state),
    workOrderTypes: SettingsSelectors.getWorkOrderTypes(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Types));
