import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field, FormattedNumber } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class RecurringMeterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meter_interval: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        meter_interval: this.props.editingWorkOrder.meter_interval || '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderContent = () => {
    if (this.props.meter == null) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field.Decimal
              autoFocus
              rightLabel={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              value={this.state.meter_interval}
              onChange={meter_interval => this.setState({ meter_interval })}
              onBlur={meter_interval => this.setState({ meter_interval })}
            />
            <Field
              singleRow
              view
              fontSize={11}
              label={<FormattedMessage id="screens.work-order.meter-current-value" />}
            >
              <FormattedNumber
                value={this.props.meter.value}
                unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.work-order.edit.meter-recurring-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={() =>
                this.props.onSave({
                  meter_interval: this.state.meter_interval,
                })
              }
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { editingWorkOrder } = ownProps;
  const meter = EntitySelectors.getMeter(state, editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    editingWorkOrder,
    meter,
    meterUnit,
  };
}

export default injectIntl(connect(mapStateToProps)(RecurringMeterModal));
