import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const CreateFromSingleChecklistInstanceRowTitle = props => props.checklistTemplateRow.title;

function mapStateToProps(state, ownProps) {
  const { checklistInstanceRowId } = ownProps;
  const checklistInstanceRow = EntitySelectors.getChecklistInstanceRow(state, checklistInstanceRowId);
  return {
    checklistInstanceRow,
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(
      state,
      checklistInstanceRow.checklist_template_row_id
    ),
  };
}

export default connect(mapStateToProps)(CreateFromSingleChecklistInstanceRowTitle);
