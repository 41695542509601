import { API, Socket, Channels } from 'sdk';
import { setGroupAuthToken } from 'sdk/utilities/Axios';
import * as Sentry from '@sentry/browser';
import actions from './actions';
import { normalizeUser, normalizeSystem } from 'sdk/Schemas';
import HelperFunctions from 'utilities/HelperFunctions';
import { EntityOperations } from 'sdk/State/entities';

const getMe = (dispatch, token, defaultSystemId = null) => {
  return API.getMe().then(({ data: currentUser }) => {
    const { name, email, organisation_id, default_system_id } = currentUser;
    const { entities: userEntities, result: userId } = normalizeUser(currentUser);
    return API.listSystemsForOrganisation(organisation_id, { system_access: true, no_pagination: true }).then(
      ({ data: systems }) => {
        const { entities: systemEntities, result: systemIds } = normalizeSystem(systems);
        dispatch(EntityOperations.updateEntities({ ...userEntities, ...systemEntities }));
        Sentry.configureScope(scope => {
          scope.setUser({ email, name });
        });
        let systemId = defaultSystemId || default_system_id || null;
        if (systemIds.includes(systemId) === false) {
          systemId = systemIds[0];
        }
        return {
          organisationId: organisation_id,
          systemIds,
          systemId,
          userId,
          token,
        };
      }
    );
  });
};

const joinChannels = (dispatch, data) => {
  const { userId, systemId, token, organisationId } = data;
  return Socket.connect(process.env.REACT_APP_SOCKET_URL, {
    token: token,
  }).then(() => {
    return Promise.all([
      Channels.SystemChannel.join(systemId),
      Channels.SettingChannel.join(systemId),
      Channels.OrganisationSettingChannel.join(organisationId),
      Channels.UserSettingChannel.join(systemId, userId),
      Channels.NotificationsChannel.join(systemId, userId),
    ]).then(
      ([
        systemChannelData,
        settingChannelData,
        organisationSettingChannelData,
        userSettingChannelData,
        notificationChannelData,
      ]) => {
        dispatch(Channels.SystemChannel.subscribe(systemChannelData));
        dispatch(Channels.SettingChannel.subscribe(settingChannelData));
        dispatch(Channels.OrganisationSettingChannel.subscribe(organisationSettingChannelData));
        dispatch(Channels.UserSettingChannel.subscribe(userSettingChannelData));
        dispatch(Channels.NotificationsChannel.subscribe(notificationChannelData));
        return data;
      }
    );
  });
};

const signIn =
  ({ email, password }) =>
  dispatch => {
    return API.login({ email, password }).then(({ data }) => {
      const { token, system_id } = data;
      const loggedInAsGroup = token.charAt(0) === 'g' && token.charAt(1) === '.';
      if (loggedInAsGroup) {
        localStorage.setItem('gToken', token);
        localStorage.setItem('gSystemId', system_id);
        setGroupAuthToken(token);
        dispatch(actions.authenticateGroupSuccess());
      } else {
        HelperFunctions.updateUserAuthToken(token);
        return getMe(dispatch, token)
          .then(data => {
            return joinChannels(dispatch, data);
          })
          .then(data => {
            dispatch(actions.prepareAuthenticatedUserSuccess(data));
          })
          .catch(e => {
            dispatch(actions.prepareAuthenticatedUserError());
          });
      }
    });
  };

const signInGroupUser = params => dispatch => {
  return API.loginGroup(params).then(({ data }) => {
    const { token } = data;
    HelperFunctions.updateUserAuthToken(token);
    return getMe(dispatch, token, localStorage.getItem('gSystemId'))
      .then(data => {
        return joinChannels(dispatch, data);
      })
      .then(data => {
        dispatch(actions.prepareAuthenticatedUserSuccess(data));
      })
      .catch(() => {
        dispatch(actions.prepareAuthenticatedUserError());
      });
  });
};

const signOutAndSignInGroupUser = params => dispatch => {
  return API.loginGroup(params).then(({ data }) => {
    const { token } = data;
    Socket.disconnect();
    API.signOut();
    HelperFunctions.updateUserAuthToken(token);
    return getMe(dispatch, token, localStorage.getItem('gSystemId'))
      .then(data => {
        return joinChannels(dispatch, data);
      })
      .then(data => {
        dispatch(actions.prepareAuthenticatedUserSuccess(data));
      })
      .catch(e => {
        dispatch(actions.prepareAuthenticatedUserError());
      });
  });
};

const prepareAuthenticatedUser =
  (token, systemId = null) =>
  dispatch => {
    HelperFunctions.updateUserAuthToken(token);
    dispatch(actions.prepareAuthenticatedUser());
    return getMe(dispatch, token, systemId)
      .then(data => {
        return joinChannels(dispatch, data);
      })
      .then(data => {
        dispatch(actions.prepareAuthenticatedUserSuccess(data));
      })
      .catch(() => {
        dispatch(actions.prepareAuthenticatedUserError());
      });
  };

const prepareAuthenticatedGroupUser = () => dispatch => {
  setGroupAuthToken(localStorage.getItem('gToken'));
  dispatch(actions.authenticateGroupSuccess());
};

const unauthenticateUser = () => dispatch => {
  if (localStorage.getItem('token')) {
    return API.signOut().then(() => {
      localStorage.removeItem('operationalMaintenancesView');
      localStorage.removeItem('requestView');
      localStorage.removeItem('listMenu');
      localStorage.removeItem('assigned_to_me_filter');
      HelperFunctions.clearUserAuthToken();
      dispatch(actions.unAuthenticateUser());
    });
  } else {
    dispatch(actions.unAuthenticateUser());
  }
};

const unauthenticateGroup = () => dispatch => {
  if (localStorage.getItem('gToken')) {
    return API.signOutGroup().then(() => {
      localStorage.removeItem('gSystemId');
      localStorage.removeItem('gToken');
      localStorage.removeItem('checkedInAssetId');
      localStorage.removeItem('checkedInAssetTitle');
      dispatch(actions.unAuthenticateGroup());
    });
  } else {
    dispatch(actions.unAuthenticateGroup());
  }
};

export default {
  unauthenticateGroup,
  signIn,
  signInGroupUser,
  signOutAndSignInGroupUser,
  unauthenticateUser,
  prepareAuthenticatedUser,
  prepareAuthenticatedGroupUser,
};
