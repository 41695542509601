import { API } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  buildReduxChecklistRowDataWithInstanceRows,
  buildReduxChecklistRowData,
} from 'sdk/ChecklistTemplate';
import { normalizeChecklistInstance, normalizeChecklistTemplateLink } from 'sdk/Schemas';

const resetState = actions.resetState;
const setEditable = actions.setEditable;
const setChecklistInstance = actions.setChecklistInstance;
const selectMenuItem = actions.selectMenuItem;
const selectDeviationTab = actions.selectDeviationTab;
const clearDeviationRows = actions.clearDeviationRows;
const addMultipleDeviationRows = actions.addMultipleDeviationRows;
const addDeviationRow = actions.addDeviationRow;
const removeDeviationRow = actions.removeDeviationRow;

const fetchChecklistInstance = id => dispatch => {
  return API.fetchChecklistInstance(id)
    .then(res => {
      const { entities } = normalizeChecklistInstance(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      const { rootRowIds, childRowIds, instanceRowForTemplateRowIds, instanceOptionIdsForTemplateRowIds } =
        buildReduxChecklistRowDataWithInstanceRows({
          checklist_template_rows: res.data.checklist_template_version.checklist_template_rows,
          checklist_instance_rows: res.data.checklist_instance_rows,
          checklist_instance_options: res.data.checklist_instance_options,
        });
      dispatch(
        actions.setChecklistInstance({
          instanceId: id,
          childIds: childRowIds,
          templateFieldIds: rootRowIds,
          instanceRowForTemplateRowIds,
          instanceOptionIdsForTemplateRowIds,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchChecklistTemplateLink = id => dispatch => {
  return API.fetchChecklistTemplateLink(id)
    .then(({ data: checklistTemplateLink }) => {
      const { entities } = normalizeChecklistTemplateLink(checklistTemplateLink);
      dispatch(EntityOperations.updateEntities(entities));
      const { rootRowIds, childRowIds, instanceOptionIdsForTemplateRowIds } = buildReduxChecklistRowData({
        checklist_template_rows:
          checklistTemplateLink.checklist_template.active_version.checklist_template_rows,
        checklist_instance_options: checklistTemplateLink.checklist_instance_options,
      });

      dispatch(
        actions.setChecklistTemplate({
          templateLinkId: id,
          templateId: checklistTemplateLink.checklist_template.id,
          childIds: childRowIds,
          templateFieldIds: rootRowIds,
          instanceOptionIdsForTemplateRowIds,
        })
      );

      return checklistTemplateLink;
    })
    .catch(e => {
      throw e;
    });
};

export default {
  clearDeviationRows,
  addMultipleDeviationRows,
  selectDeviationTab,
  addDeviationRow,
  removeDeviationRow,
  resetState,
  selectMenuItem,
  setEditable,
  setChecklistInstance,
  fetchChecklistInstance,
  fetchChecklistTemplateLink,
};
