import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import {
  normalizeSparePart,
  normalizeSparePartAsset,
  normalizeAttachment,
  normalizeVendor,
  normalizePurchaseOrder,
  normalizeSparePartWithdrawal,
} from 'sdk/Schemas';

const clearPrimaryVendor = actions.clearPrimaryVendor;
const fetchSparePart = id => dispatch => {
  dispatch(actions.setSparePartId(id));
  return API.fetchSparePart(id).then(res => {
    const { entities } = normalizeSparePart(res.data);
    dispatch(EntityOperations.updateEntities(entities));

    return res.data;
  });
};

const fetchAttachments =
  (sparePartId, params = {}) =>
  dispatch => {
    return API.listAttachmentsForSparePart(sparePartId, { ...params, page_size: 8 }).then(res => {
      const { entities, result } = normalizeAttachment(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchAttachmentsSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    });
  };

const fetchSparePartAssets =
  (systemId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
    };

    return API.listSparePartAssets(systemId, attrs).then(res => {
      const { entities, result } = normalizeSparePartAsset(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchSparePartAssetsSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchVendors =
  (systemId, sparePartId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
      spare_part_vendors: {
        spare_part_id: sparePartId,
      },
      spare_part_vendors_for_spare_part: sparePartId,
      primary_for_spare_part_id: {
        [HelperFunctions.FILTER_COMPARABLES.NotExact]: sparePartId,
      },
      sort: 'name',
      'sort-order': 'asc',
    };
    return API.listVendors(systemId, attrs).then(({ data: vendors, headers }) => {
      let sparePartVendorIdsByVendorId = {};
      vendors.forEach(vendor => {
        sparePartVendorIdsByVendorId = {
          ...sparePartVendorIdsByVendorId,
          [vendor.id]: vendor.spare_part_vendors_for_spare_part.map(({ id }) => id),
        };
      });
      const { entities, result: vendorIds } = normalizeVendor(vendors);
      const pagination = HelperFunctions.getPaginationFromHeader(headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchVendorsSuccess({
          ids: vendorIds,
          sparePartVendorIdsByVendorId,
          pagination,
        })
      );
    });
  };

const fetchPrimaryVendor =
  (systemId, sparePartId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      primary_for_spare_part_id: sparePartId,
      spare_part_vendors_for_spare_part: sparePartId,
      page_size: 8,
    };
    return API.listVendors(systemId, attrs).then(({ data: vendors, headers }) => {
      let sparePartVendorIdsByVendorId = {};
      vendors.forEach(vendor => {
        sparePartVendorIdsByVendorId = {
          ...sparePartVendorIdsByVendorId,
          [vendor.id]: vendor.spare_part_vendors_for_spare_part.map(({ id }) => id),
        };
      });
      const { entities, result: vendorIds } = normalizeVendor(vendors);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchPrimaryVendorSuccess({ id: vendorIds[0], sparePartVendorIdsByVendorId }));
    });
  };

const fetchPurchaseOrders =
  (systemId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
    };

    return API.listPurchaseOrders(systemId, attrs).then(res => {
      const { entities, result } = normalizePurchaseOrder(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchPurchaseOrdersSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

const fetchSparePartWithdrawals =
  (systemId, params = {}) =>
  dispatch => {
    const attrs = {
      ...params,
      page_size: 8,
    };

    return API.listSparePartWithdrawals(systemId, attrs).then(res => {
      const { entities, result } = normalizeSparePartWithdrawal(res.data);
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      dispatch(EntityOperations.updateEntities(entities));

      dispatch(
        actions.fetchSparePartWithdrawalsSuccess({
          ids: result,
          pagination,
        })
      );
    });
  };

export default {
  fetchSparePart,
  fetchAttachments,
  fetchSparePartAssets,
  fetchVendors,
  clearPrimaryVendor,
  fetchPrimaryVendor,
  fetchPurchaseOrders,
  fetchSparePartWithdrawals,
};
