import actions from './actions';
import {
  normalizeGroup,
  normalizeUser,
  normalizeTemplateField,
  normalizePermissionProfile,
  normalizeWorkOrderType,
  normalizeAssetType,
  normalizeRequestType,
  normalizeSparePartType,
  normalizeRequestSelectableAssigneee,
  normalizeRequestAutomationRule,
  normalizeDowntimeReason,
  normalizePurchaseOrderShippingAddress,
} from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';

const searchGroups = actions.searchGroups;
const searchWorkOrderTypes = actions.searchWorkOrderTypes;
const searchAssetTypes = actions.searchAssetTypes;
const searchRequestTypes = actions.searchRequestTypes;
const searchSparePartTypes = actions.searchSparePartTypes;

const fetchGroups = (systemId, params) => dispatch => {
  return API.listGroups(systemId, params)
    .then(res => {
      const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
      const { entities, result } = normalizeGroup(res.data);

      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchGroupsSuccess({
          ids: result,
          pagination,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchRequestAutomationGroups = (systemId, params) => dispatch => {
  return API.listGroups(systemId, params)
    .then(res => {
      const { entities, result } = normalizeGroup(res.data);

      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchRequestAutomationGroupsSuccess(result));

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchTemplateFields = (systemId, params) => dispatch => {
  return API.listTemplateFields(systemId, params)
    .then(res => {
      const { entities } = normalizeTemplateField(res.data);

      dispatch(EntityOperations.updateEntities(entities));
      const sectionOneIds = res.data.filter(field => field.column === 1).map(field => field.id);
      const sectionTwoIds = res.data.filter(field => field.column === 2).map(field => field.id);
      dispatch(
        actions.fetchTemplateFieldsSuccess({
          sectionOneIds,
          sectionTwoIds,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const sortTemplateField = actions.sortTemplateField;
const sortTemplateFieldBetweenColumns = actions.sortTemplateFieldBetweenColumns;
const sortRuleItem = actions.sortRuleItem;
const selectTemplateField = actions.selectTemplateField;
const unselectTemplateField = actions.unselectTemplateField;
const prepareNewField = actions.prepareNewField;

const fetchWorkOrderTypes = (systemId, params) => dispatch => {
  return API.listWorkOrderTypes(systemId, params)
    .then(res => {
      const { entities, result } = normalizeWorkOrderType(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchWorkOrderTypesSuccess({
          ids: result,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchPurchaseOrderShippingAddresses = systemId => dispatch => {
  return API.listPurchaseOrderShippingAddresses(systemId)
    .then(res => {
      const { entities, result } = normalizePurchaseOrderShippingAddress(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchPurchaseOrderShippingAddresses({
          ids: result,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};
const fetchDowntimeReasons = (systemId, params) => dispatch => {
  return API.listDowntimeReasons(systemId, params)
    .then(res => {
      const { entities, result } = normalizeDowntimeReason(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchDowntimeReasonsSuccess({
          ids: result,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchAssetTypes = (systemId, params) => dispatch => {
  return API.listAssetTypes(systemId, params)
    .then(res => {
      const { entities, result } = normalizeAssetType(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchAssetTypesSuccess(result));
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchRequestTypes = (systemId, params) => dispatch => {
  return API.listRequestTypes(systemId, params)
    .then(res => {
      const { entities, result } = normalizeRequestType(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchRequestTypesSuccess(result));
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchRequestSelectableAssignees = (systemId, params) => dispatch => {
  return API.listRequestSelectableAssignee(systemId, params)
    .then(res => {
      const { entities, result } = normalizeRequestSelectableAssigneee(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchRequestSelectableAssigneesSuccess(result));
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchRequestAutomationRules = (systemId, params) => dispatch => {
  return API.listRequestAutomationRules(systemId, params)
    .then(res => {
      const { entities, result } = normalizeRequestAutomationRule(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(actions.fetchRequestAutomationRulesSuccess(result));
      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

const fetchSparePartTypes = (systemId, params) => dispatch => {
  return API.listSparePartTypes(systemId, params)
    .then(res => {
      const { entities, result } = normalizeSparePartType(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchSparePartTypesSuccess({
          ids: result,
        })
      );

      return res.data;
    })
    .catch(e => {
      throw e;
    });
};

export default {
  sortTemplateField,
  sortTemplateFieldBetweenColumns,
  sortRuleItem,
  unselectTemplateField,
  fetchGroups,
  fetchRequestAutomationGroups,
  searchGroups,
  selectTemplateField,
  fetchWorkOrderTypes,
  searchWorkOrderTypes,
  fetchTemplateFields,
  prepareNewField,
  fetchAssetTypes,
  searchAssetTypes,
  searchRequestTypes,
  fetchRequestAutomationRules,
  searchSparePartTypes,
  fetchRequestTypes,
  fetchSparePartTypes,
  fetchRequestSelectableAssignees,
  fetchDowntimeReasons,
  fetchPurchaseOrderShippingAddresses,
};
