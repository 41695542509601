import types from './types';
import update from 'immutability-helper';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  versionId: null,
  isFetchingVersion: true,
  isDeleted: false,
  pagination: {},
  checklistInstances: {
    ids: [],
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_CHECKLIST_TEMPLATE_VERSION: {
      return {
        ...state,
        versionId: null,
        isFetchingVersion: true,
      };
    }
    case types.SET_CHECKLIST_TEMPLATE_VERSION: {
      const { id } = action.payload;
      return {
        ...state,
        versionId: id,
        isFetchingVersion: false,
      };
    }

    case types.FETCH_CHECKLIST_INSTANCES_SUCCESS: {
      const { ids, pagination } = action.payload;
      return {
        ...state,
        checklistInstances: {
          ...state.checklistInstances,
          ids,
        },
        pagination,
      };
    }
    case types.FETCH_CHECKLIST_VERSIONS_SUCCESS: {
      const { versions } = action.payload;
      return {
        ...state,
        versions,
      };
    }

    case SDKReduxTypes.CHECKLIST_TEMPLATE_DELETED: {
      const { checklistTemplateId } = action.payload;

      return update(
        { ...state },
        {
          checklistInstances: {
            ids: { $set: state.checklistInstances.ids.filter(id => id !== checklistTemplateId) },
          },
          pagination: {
            totalEntries: v => v - 1,
          },
        }
      );
    }
    default:
      return state;
  }
};
