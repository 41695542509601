import React from 'react';
import { connect } from 'react-redux';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import styles from '../style.module.scss';

const SelectedAssigneeValue = ({ users, groups, vendors }) => {
  const assignees = [
    ...vendors.map(assignee => assignee.name),
    ...groups.map(assignee => assignee.title),
    ...users.map(assignee => <UserNameWrapper user={assignee} />),
  ];

  const hasAssignees = () => {
    return users.length > 0 || groups.length > 0 || vendors.length > 0;
  };

  return !hasAssignees() ? (
    '-'
  ) : (
    <div className={styles['value']}>
      {assignees.reduce((accu, assignee) => (accu === null ? [assignee] : [...accu, ', ', assignee]), null)}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    users: EntitySelectors.getUsers(state, ownProps.userIds),
    groups: EntitySelectors.getGroups(state, ownProps.groupIds),
    vendors: EntitySelectors.getVendors(state, ownProps.vendorIds),
  };
}

export default connect(mapStateToProps)(SelectedAssigneeValue);
