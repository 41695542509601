import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ConnectedIntlProvider } from 'sdk/Components';
import { Button } from 'views/components/Shared/General';
import { Layout, Button as LoginButton } from 'views/components/Login';
import { AuthSelectors, AuthOperations } from 'state/ducks/auth';
import LogoSvg from 'assets/images/logo.svg';
import styles from './style.module.scss';

class DemoExpired extends Component {
  render() {
    return (
      <ConnectedIntlProvider textComponent="span" language={this.props.language}>
        <Layout>
          <div className={styles['text']}>
            <FormattedMessage id="screens.demo-expired.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.demo-expired.subtitle" />
          </div>
          <div className={styles['button-container']}>
            <a href="mailto:support@mainter.com">
              <LoginButton
                label={<FormattedMessage id="screens.demo-expired.button" />}
                primary
                large
                onClick={() => {
                  this.setState({ shouldNavigateBackToApp: true });
                }}
              />
            </a>
          </div>
          <div className={styles['separator']} />
          <div className={styles['additional-buttons']}>
            <Button label="menu.log-out" gray onClick={this.props.unauthenticateUser} />
          </div>
        </Layout>
      </ConnectedIntlProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unauthenticateUser: AuthOperations.unauthenticateUser,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    language: AuthSelectors.getLanguage(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoExpired);
