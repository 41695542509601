import types from './types';

const INITIAL_STATE = {
  resourceItemsSelectedTab: null,
  imageLoaderCount: 0,
  isAddingChecklistTemplate: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_RESOURCE_ITEMS_SELECTED_TAB: {
      return {
        ...state,
        resourceItemsSelectedTab: action.payload,
      };
    }
    case types.INCREMENT_IMAGE_LOADER_COUNT: {
      return {
        ...state,
        imageLoaderCount: state.imageLoaderCount + 1,
      };
    }
    case types.DECREMENT_IMAGE_LOADER_COUNT: {
      return {
        ...state,
        imageLoaderCount: state.imageLoaderCount - 1,
      };
    }
    case types.RESET_IMAGE_LOADER_COUNT: {
      return {
        ...state,
        imageLoaderCount: 0,
      };
    }
    case types.SHOW_ADD_REUSABLE_CHECKLIST_LOADER: {
      return {
        ...state,
        isAddingChecklistTemplate: true,
      };
    }
    case types.HIDE_ADD_REUSABLE_CHECKLIST_LOADER: {
      return {
        ...state,
        isAddingChecklistTemplate: false,
      };
    }
    default:
      return state;
  }
};
