import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NewPurchaseOrderModal } from 'views/components/PurchaseOrder';
import { WhiteCard, SectionHeader, Icon, NewInlineModal } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import { NewWorkOrderModal } from 'views/components/WorkOrder';
import { NewRequestModal } from 'views/components/Request';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeWorkOrder } from 'sdk/Schemas';
import { API } from 'sdk';
import AddDowntimeInlineModal from './AddDowntimeInlineModal';
import styles from './styles.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

class CreateRelatedResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewFollowUpDropdown: false,
      showNewPurchaseOrderModal: false,
      showNewWorkOrderModal: false,
      showNewDowntimeModal: false,
      newWorkOrderModalParams: {
        asset_id: props.workOrder.asset_id,
      },
      showNewRequestModal: false,
      newRequestModalParams: {
        asset_id: props.workOrder.asset_id,
      },
      isCreatingActiveDowntime: false,
    };
  }

  renderNewFollowUp = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          className={styles['content-container']}
          onClick={() =>
            this.setState(prevState => ({
              showNewFollowUpDropdown: !prevState.showNewFollowUpDropdown,
            }))
          }
        >
          <div className={styles['content']}>
            <div>
              <Icon type="wrench" withBackground backgroundSize={34} />
            </div>
            <div className={styles['value-container']}>
              <FormattedMessage id="screens.work-order.create-from.options.follow-up" />
            </div>
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showNewFollowUpDropdown}
          onClose={() => this.setState({ showNewFollowUpDropdown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={e => {
                  this.setState({ showNewFollowUpDropdown: false });
                  setTimeout(() => {
                    this.setState({ showNewWorkOrderModal: true });
                  }, 250);
                }}
              >
                <FormattedMessage id="screens.work-order.create-from.follow-up-dropdown.work-order" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                onClick={e => {
                  this.setState({ showNewFollowUpDropdown: false });
                  setTimeout(() => {
                    this.setState({ showNewRequestModal: true });
                  }, 250);
                }}
              >
                <FormattedMessage id="screens.work-order.create-from.follow-up-dropdown.request" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderNewPurchaseOrder = () => {
    if (this.props.canEditPurchaseOrders && this.props.workOrder.completed_before_registration === false) {
      return (
        <div
          className={`${styles['content-container']} ${styles['no-bottom-border']}`}
          onClick={() => this.setState({ showNewPurchaseOrderModal: true })}
        >
          <div className={styles['content']}>
            <div>
              <Icon type="file-invoice-dollar" withBackground backgroundSize={34} />
            </div>
            <div className={styles['value-container']}>
              <FormattedMessage id="screens.work-order.create-from.options.purchase-order" />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderNewWorkOrderModal = () => {
    return (
      <NewWorkOrderModal
        open={this.state.showNewWorkOrderModal}
        forWorkOrderId={this.props.workOrder.id}
        defaultParams={this.state.newWorkOrderModalParams}
        onClose={() => {
          this.setState({ showNewWorkOrderModal: false });
        }}
        onCreated={() => {
          API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
            const { entities } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            this.setState({ showNewWorkOrderModal: false });
          });
        }}
        onCreatedWithReopen={() => {
          API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
            const { entities } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            this.setState({ showNewWorkOrderModal: false });
            setTimeout(() => {
              this.setState({ showNewWorkOrderModal: true });
            }, 250);
          });
        }}
      />
    );
  };

  renderNewRequestModal = () => {
    return (
      <NewRequestModal
        open={this.state.showNewRequestModal}
        forWorkOrderId={this.props.workOrder.id}
        defaultParams={this.state.newRequestModalParams}
        onClose={() => {
          this.setState({ showNewRequestModal: false });
        }}
        onCreated={() => {
          API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
            const { entities } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            this.setState({ showNewRequestModal: false });
          });
        }}
        onCreatedWithReopen={() => {
          API.fetchWorkOrder(this.props.match.params.id).then(({ data: workOrder }) => {
            const { entities } = normalizeWorkOrder(workOrder);
            this.props.updateEntities(entities);
            this.setState({ showNewRequestModal: false });
            setTimeout(() => {
              this.setState({ showNewRequestModal: true });
            }, 250);
          });
        }}
      />
    );
  };

  renderAddDowntime = () => {
    if (!this.props.settings.downtime_activated) return null;
    return (
      <AddDowntimeInlineModal
        workOrder={this.props.workOrder}
        trigger={
          <div className={styles['content-container']}>
            <div className={styles['content']}>
              <div>
                <Icon type="exclamation-triangle" withBackground backgroundSize={36} />
              </div>
              <div className={styles['value-container']}>
                <FormattedMessage id="screens.work-order.create-from.options.downtime" />
              </div>
            </div>
          </div>
        }
      />
    );
  };

  render() {
    const { canCarryOutWorkOrders, hasProTier } = this.props;
    if (hasProTier && canCarryOutWorkOrders) {
      return (
        <>
          <CardGrid.Row>
            <WhiteCard noPadding>
              <SectionHeader noBorderTop paddingHorizontal={26}>
                <div className={styles['section']}>
                  <FormattedMessage id="screens.work-order.create-from.title" />
                </div>
              </SectionHeader>
              {this.renderNewFollowUp()}
              {this.renderAddDowntime()}
              {this.renderNewPurchaseOrder()}
            </WhiteCard>
          </CardGrid.Row>
          <NewPurchaseOrderModal
            forWorkOrderId={this.props.match.params.id}
            open={this.state.showNewPurchaseOrderModal}
            onClose={() => {
              this.setState({ showNewPurchaseOrderModal: false });
            }}
            onCreated={purchaseOrder => {
              this.props.history.push(`/purchase-orders/${purchaseOrder.id}`);
            }}
          />
          {this.renderNewWorkOrderModal()}
          {this.renderNewRequestModal()}
        </>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canCarryOutWorkOrders: AuthSelectors.canCarryOutWorkOrders(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateRelatedResource));
