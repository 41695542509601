import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip, Loader } from 'views/components/Shared/General';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import styles from './style.module.scss';

export default class StatusButtons extends Component {
  renderNotStartedButton = () => {
    let classNames = [styles['status'], styles['not-started']];
    let onClick = () => null;
    const loading = this.props.loadingStatus === WorkOrderStatus.NotStarted;

    if (this.props.selectedStatus === WorkOrderStatus.NotStarted) {
      classNames = [...classNames, styles['selected']];
    }
    if (loading) {
      classNames = [...classNames, styles['loading']];
    }

    if (this.props.forceCompleted) {
      classNames = [...classNames, styles['inactive']];
    } else {
      onClick = e => this.props.onStatusChanged(WorkOrderStatus.NotStarted);
    }

    const button = (
      <div className={classNames.join(' ')} onClick={onClick}>
        <div className={styles['icon']}>{loading ? <Loader tiny /> : <Icon type="clock" />}</div>
        <FormattedMessage id="resources.work-order.statuses.not-started" />
      </div>
    );

    if (this.props.forceCompleted) {
      return this.renderWrappedInInactivePopup(button);
    } else {
      return button;
    }
  };

  renderInProgressButton = () => {
    let classNames = [styles['status'], styles['in-progress']];
    let onClick = () => null;
    const loading = this.props.loadingStatus === WorkOrderStatus.InProgress;

    if (this.props.selectedStatus === WorkOrderStatus.InProgress) {
      classNames = [...classNames, styles['selected']];
    }
    if (loading) {
      classNames = [...classNames, styles['loading']];
    }

    if (this.props.forceCompleted) {
      classNames = [...classNames, styles['inactive']];
    } else {
      onClick = e => this.props.onStatusChanged(WorkOrderStatus.InProgress);
    }

    const button = (
      <div className={classNames.join(' ')} onClick={onClick}>
        <div className={styles['icon']}>{loading ? <Loader tiny /> : <Icon type="wrench" />}</div>
        <FormattedMessage id="resources.work-order.statuses.in-progress" />
      </div>
    );

    if (this.props.forceCompleted) {
      return this.renderWrappedInInactivePopup(button);
    } else {
      return button;
    }
  };

  renderPausedButton = () => {
    let classNames = [styles['status'], styles['paused']];
    let onClick = () => null;
    const loading = this.props.loadingStatus === WorkOrderStatus.Paused;
    if (this.props.selectedStatus === WorkOrderStatus.Paused) {
      classNames = [...classNames, styles['selected']];
    }
    if (loading) {
      classNames = [...classNames, styles['loading']];
    }

    if (this.props.forceCompleted) {
      classNames = [...classNames, styles['inactive']];
    } else {
      onClick = e => this.props.onStatusChanged(WorkOrderStatus.Paused);
    }

    const button = (
      <div className={classNames.join(' ')} onClick={onClick}>
        <div className={styles['icon']}>{loading ? <Loader tiny /> : <Icon type="pause" />}</div>
        <FormattedMessage id="resources.work-order.statuses.paused" />
      </div>
    );

    if (this.props.forceCompleted) {
      return this.renderWrappedInInactivePopup(button);
    } else {
      return button;
    }
  };

  renderCompletedButton = () => {
    let classNames = [styles['status'], styles['completed']];
    const loading = this.props.loadingStatus === WorkOrderStatus.Completed;
    if (this.props.selectedStatus === WorkOrderStatus.Completed) {
      classNames = [...classNames, styles['selected']];
    }
    if (loading) {
      classNames = [...classNames, styles['loading']];
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={e => this.props.onStatusChanged(WorkOrderStatus.Completed)}
      >
        <div className={styles['icon']}>{loading ? <Loader tiny /> : <Icon type="check" />}</div>
        <FormattedMessage id="resources.work-order.statuses.completed" />
      </div>
    );
  };

  renderWrappedInInactivePopup = button => (
    <Tooltip
      trigger={button}
      label={<FormattedMessage id="components.new-work-order-modal.cant-plan-work-order-popup" />}
    />
  );

  render() {
    let classNames = [styles['status-options']];
    if (this.props.disabled) {
      classNames = [...classNames, styles['disabled']];
    }
    return (
      <div className={classNames.join(' ')}>
        {this.renderNotStartedButton()}
        {this.renderInProgressButton()}
        {this.renderPausedButton()}
        {this.renderCompletedButton()}
      </div>
    );
  }
}
