import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { FullScreenOverlayModal } from 'views/components/Shared/Layout';
import { DropZone } from 'views/components/General';
import { UploadProgressOperations } from 'state/ducks/uploadProgress';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import Header from './Header';
import RightPanel from './RightPanel';
import Content from './Content';
import styles from './style.module.scss';
import { AuthSelectors } from 'state/ducks/auth';

const uploadFilesRequest = HelperFunctions.getCancelTokenForRequest();

class EditOperationalMaintenanceModal extends Component {
  getInitialState = () => ({
    notFound: false,
  });

  state = this.getInitialState();

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      this.init();
    } else if (prevProps.open && !this.props.open) {
      uploadFilesRequest.cancel();
      window.onbeforeunload = undefined;
    }
  }

  close = () => {
    if (this.props.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  init = () => {
    if (this.props.operationalMaintenanceTemplateId) {
      this.props.setEditingOperationalMaintenanceTemplateVersion(this.props.operationalMaintenanceTemplateId);
    } else {
      this.props.prepareNewMaintenanceTemplateVersion();
    }
  };

  createDraftAttachment = ({ description, title, extension, file, type, mime_type, link_url }) => {
    const temporaryId = 'editOpTemplate';
    this.props.beginUpload({ temporaryId: 'editOpTemplate', namespace: temporaryId });
    if (type === 'file') {
      this.props
        .createDraftAttachment(this.props.currentSystem.id, {
          description,
          title,
          extension,
          file,
          type,
          mime_type,
          link_url,
        })
        .then(({ data: attachment }) => {
          return HelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: temporaryId,
              });
            },
            uploadFilesRequest.getCancelTokenConfig()
          )
            .then(() => {
              return this.props.attachmentVersionUploaded(attachment.attachment_version.id).then(() => {
                this.props.completeUpload({
                  id: attachment.id,
                  temporaryId,
                  namespace: temporaryId,
                });
                this.props.setEditingOperationalMaintenanceTemplateVersionValue({
                  attachments: [
                    {
                      attachment_id: attachment.id,
                      attachment_version_id: attachment.attachment_version.id,
                    },
                    ...this.props.attachments,
                  ],
                });
              });
            })
            .catch(e => {
              if (axios.isCancel(e)) {
                this.props.cancelUpload({ temporaryId, namespace: temporaryId });
              } else {
                this.props.cancelUpload({ temporaryId, namespace: temporaryId });
                this.setState({ showUploadAttachmentErrorWarning: true });
              }
            });
        });
    } else {
      this.props
        .createDraftAttachment(
          this.props.currentSystem.id,
          {
            description,
            title,
            extension,
            file,
            type,
            mime_type,
            link_url,
          },
          { completion: true }
        )
        .then(({ data: attachment }) => {
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            attachments: [
              { attachment_id: attachment.id, attachment_version_id: attachment.attachment_version.id },
              ...this.props.attachments,
            ],
          });
          this.props.completeUpload({
            id: attachment.id,
            temporaryId,
            namespace: temporaryId,
          });
        });
    }
  };

  renderRightPanel = () => {
    return <RightPanel operationalMaintenanceTemplateId={this.props.operationalMaintenanceTemplateId} />;
  };

  renderMainContent = () => {
    let classNames = [styles['content']];
    return (
      <DropZone onDrop={data => this.createDraftAttachment(data)}>
        <div className={styles['content-container']}>
          <div className={classNames.join(' ')}>
            <Content />
          </div>
        </div>
      </DropZone>
    );
  };

  render() {
    return (
      <>
        <FullScreenOverlayModal open={this.props.open}>
          <div className={styles['container']}>
            <Header
              operationalMaintenanceTemplateId={this.props.operationalMaintenanceTemplateId}
              onClose={this.close}
              onCreated={this.props.onCreated}
            />
            <div className={styles['content-wrapper']}>
              {this.renderMainContent()}
              {this.renderRightPanel()}
            </div>
          </div>
        </FullScreenOverlayModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersion:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersion,
      prepareNewMaintenanceTemplateVersion:
        EditOperationalMaintenanceTemplateOperations.prepareNewMaintenanceTemplateVersion,
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      createDraftImage: SDKReduxOperations.createDraftImage,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createDraftAttachment: SDKReduxOperations.createDraftAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    attachments: EditOperationalMaintenanceTemplateSelectors.getAttachments(state),
    hasUnsavedChanges: EditOperationalMaintenanceTemplateSelectors.hasUnsavedChanges(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditOperationalMaintenanceModal));

EditOperationalMaintenanceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  operationalMaintenanceTemplateId: PropTypes.string,
};

EditOperationalMaintenanceModal.defaultProps = {
  open: false,
  onClose: () => {},
  operationalMaintenanceTemplateId: null,
};
