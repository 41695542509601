import update from 'immutability-helper';
import { SDKReduxTypes, HelperFunctions } from 'sdk';
import EntityTypes from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case SDKReduxTypes.ASSET_PRODUCTION_SUPERVISOR_CREATED: {
      const { data } = action.payload;
      const { id, asset_id } = data;
      if (state[asset_id]) {
        return update(
          { ...state },
          {
            [asset_id]: {
              asset_production_supervisors: {
                $apply: val => {
                  if (val) {
                    return [id, ...val];
                  }
                  return [id];
                },
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.ASSET_PRODUCTION_SUPERVISOR_DELETED: {
      const { id, asset_id } = action.payload.assetProductionSupervisor;
      if (state[asset_id]) {
        return update(
          { ...state },
          {
            [asset_id]: {
              asset_production_supervisors: {
                $apply: val => {
                  if (val) {
                    return val.filter(loopedId => loopedId !== id);
                  }
                },
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.DOWNTIME_CREATED: {
      const { data } = action.payload;
      const { asset_id: assetId } = data;
      if (state[assetId] && data.to == null) {
        return {
          ...state,
          [assetId]: {
            ...state[assetId],
            active_downtime: data.id,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.DOWNTIME_DELETED: {
      const { downtimeId, data } = action.payload;
      const { asset_id } = data;
      if (state[asset_id]) {
        return {
          ...state,
          [asset_id]: {
            ...state[asset_id],
            active_downtime:
              downtimeId === state[asset_id].active_downtime ? null : state[asset_id].active_downtime,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.DOWNTIME_UPDATED: {
      const { downtimeId, data, meta } = action.payload;
      const { asset_id, to } = data;
      const previousAssetId = meta.asset_id;
      if (state[previousAssetId]) {
        return {
          ...state,
          [previousAssetId]: {
            ...state[previousAssetId],
            active_downtime: null,
          },
        };
      }
      if (state[asset_id] && to != null) {
        return {
          ...state,
          [asset_id]: {
            ...state[asset_id],
            active_downtime:
              downtimeId === state[asset_id].active_downtime ? null : state[asset_id].active_downtime,
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_CREATED_FOR_ASSET: {
      const { assetId, data } = action.payload;
      const { id } = data;
      if (state[assetId]) {
        return update(
          { ...state },
          {
            [assetId]: {
              images: {
                $push: [id],
              },
            },
          }
        );
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId, data } = action.payload;
      if (!data) return state;

      const { asset_id } = data;
      if (state[asset_id] == null) return state;
      return {
        ...state,
        [asset_id]: {
          ...state[asset_id],
          images: state[asset_id].images.filter(id => id !== imageId),
        },
      };
    }
    case SDKReduxTypes.IMAGE_UPDATED: {
      const { imageId, data, params } = action.payload;
      const { asset_id } = data;
      if (state[asset_id] == null) return state;
      if (params.sort !== 1) return null;
      return {
        ...state,
        [asset_id]: {
          ...state[asset_id],
          images: [imageId, ...state[asset_id].images.filter(id => id !== imageId)],
        },
      };
    }
    case EntityTypes.UPDATE_ENTITIES:
      if (action.payload && action.payload.assetById) {
        return HelperFunctions.mergeEntities(state, action.payload.assetById);
      }
      return state;

    case SDKReduxTypes.ASSET_DELETED: {
      const { assetId } = action.payload;
      return update(state, {
        [assetId]: {
          _deleted: { $set: true },
        },
      });
    }
    default:
      return state;
  }
};
