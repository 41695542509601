import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { List, ViewTextArea, Datapill } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class BreakListItem extends Component {
  renderSubtitle = () => {
    if (this.props.asset) {
      return <TreePath fullPath assetId={this.props.asset.id} />;
    }
    return <FormattedMessage id="screens.operational-maintenances.breaks.all-assets" />;
  };

  renderDescription = () => {
    if (this.props.operationalMaintenanceBreak.description) {
      return (
        <div className={styles.comment}>
          <ViewTextArea>{this.props.operationalMaintenanceBreak.description}</ViewTextArea>
        </div>
      );
    }
    return null;
  };

  renderTitle = () => {
    const { from_date, to_date } = this.props.operationalMaintenanceBreak;
    if (to_date) {
      return (
        <span>
          <span>{moment(from_date).format('LL')}</span>
          <span> - </span>
          <span>{moment(to_date).format('LL')}</span>
        </span>
      );
    }
    return (
      <>
        <span>{moment(from_date).format('LL')}</span>
        <span> </span>
        <span>-</span>
      </>
    );
  };

  renderListItemColumns = () => {
    return (
      <>
        <List.Item.TitleColumn title={this.renderTitle()} subtitle={this.renderSubtitle()} />
      </>
    );
  };

  renderUntilFurtherNoticeColumn = () => {
    if (this.props.operationalMaintenanceBreak.to_date == null) {
      return (
        <List.Item.Column alignRight>
          <Datapill
            value={<FormattedMessage id="screens.operational-maintenances.breaks.until-further-notice" />}
          />
        </List.Item.Column>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item small clickable onClick={this.props.onClick} dataPillsComponent={this.renderDescription()}>
          {this.renderListItemColumns()}
          {this.renderUntilFurtherNoticeColumn()}
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const operationalMaintenanceBreak = EntitySelectors.getOperationalMaintenanceBreak(state, ownProps.id);
  const asset = EntitySelectors.getAsset(state, operationalMaintenanceBreak.asset_id);
  return {
    operationalMaintenanceBreak,
    asset,
  };
}

export default withRouter(connect(mapStateToProps)(BreakListItem));
