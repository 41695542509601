import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistEditModalOperations } from 'state/ducks/checklistEditModal';
import { Modal } from 'views/components/Shared/Layout';
import { Type } from 'sdk/ChecklistTemplateRow';
import { Field, Button } from 'views/components/Shared/General';

class DeleteRowConfirmModal extends Component {
  state = {
    isDeleting: false,
    confirmDelete: false,
  };

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isDeleting: false,
        confirmDelete: false,
      });
    }
  }

  delete = () => {
    this.setState({ isDeleting: true });
    this.props.deleteChecklistTemplateRow(this.props.id).then(() => {
      toast(
        <ToastMessage
          success
          text={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.delete-field-success" />
          }
        />
      );
      this.setState({ isDeleting: false });
      this.props.cancelEditField();
      this.props.onClose();
    });
  };

  renderHeader = () => {
    if (this.props.checklistTemplateRow == null) {
      return null;
    }
    const { type } = this.props.checklistTemplateRow;
    if (type === Type.Section) {
      return (
        <Modal.Header
          title={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.section.title" />
          }
          subtitle={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.section.subtitle" />
          }
          onClose={this.props.onClose}
          ignoreLine
        />
      );
    }
    if (type === Type.Inspection) {
      return (
        <Modal.Header
          title={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.inspection.title" />
          }
          subtitle={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.inspection.subtitle" />
          }
          onClose={this.props.onClose}
          ignoreLine
        />
      );
    }
    if (type === Type.Meter) {
      return (
        <Modal.Header
          title={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.meter.title" />
          }
          subtitle={
            <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.meter.subtitle" />
          }
          onClose={this.props.onClose}
          ignoreLine
        />
      );
    }
    return null;
  };

  render() {
    return (
      <Modal width={480} isOpen={this.props.open}>
        {this.renderHeader()}
        <Modal.Content>
          <Field.Checkbox
            checked={this.state.confirmDelete}
            onChange={value => this.setState({ confirmDelete: value })}
            label={
              <FormattedMessage id="components.edit-checklist-modal.editor.delete-row-modal.confirm-checkbox-text" />
            }
          />
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              destructive
              disabled={!this.state.confirmDelete}
              primary
              label={'general.delete'}
              loading={this.state.isDeleting}
              onClick={this.delete}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteChecklistTemplateRow: SDKReduxOperations.deleteChecklistTemplateRow,
      cancelEditField: ChecklistEditModalOperations.cancelEditField,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeleteRowConfirmModal));
