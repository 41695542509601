import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { SelectSparePartLocationModal, SparePartLocationPath } from 'views/components/SparePartLocation';

class SparePartStorageLocationField extends Component {
  state = {
    showSelectSparePartLocationModal: false,
  };

  renderEditingField = () => (
    <div onClick={() => this.setState({ showSelectSparePartLocationModal: true })}>
      <Field.Resource
        value={
          this.props.sparePartLocation ? (
            <SparePartLocationPath sparePartLocationId={this.props.sparePartLocation.id} />
          ) : null
        }
        onClear={() => this.props.onChange(null)}
        clearable
        clickable
        angleDown
      />
    </div>
  );

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return this.props.sparePartLocation ? (
        <SparePartLocationPath sparePartLocationId={this.props.sparePartLocation.id} />
      ) : (
        '-'
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <Field view={!this.props.editing} label={<FormattedMessage id="resources.spare-part.location" />}>
          {this.renderValue()}
        </Field>
        <SelectSparePartLocationModal
          selectedSparePartLocationId={this.props.value}
          defaultSparePartLocationId={
            this.props.sparePartLocation == null
              ? null
              : this.props.sparePartLocation.spare_part_location_parent_id
          }
          open={this.state.showSelectSparePartLocationModal}
          onSelectLocation={sparePartLocationId => this.props.onChange(sparePartLocationId)}
          onClose={() => {
            this.setState({ showSelectSparePartLocationModal: false });
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocation: EntitySelectors.getSparePartLocation(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(SparePartStorageLocationField);

SparePartStorageLocationField.propTypes = {
  value: PropTypes.string,
  editing: PropTypes.bool.isRequired,
};

SparePartStorageLocationField.defaultProps = {
  editing: false,
};
