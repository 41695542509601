import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuid from 'uuid';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { UploadProgressSelectors, UploadProgressOperations } from 'state/ducks/uploadProgress';
import {
  Button,
  PathItem,
  NewSearchField,
  EmptyDataSet,
  WhiteCard,
  Tooltip,
  Icon,
  Loader,
} from 'views/components/Shared/General';
import { DropZone } from 'views/components/General';
import { ContentContainer, ToastMessage } from 'views/components/Shared/Layout';
import { NewAttachmentModal, UploadAttachmentErrorModal } from 'views/components/Attachment';
import {
  AttachmentAssetContentOperations,
  AttachmentAssetContentSelectors,
} from 'state/ducks/attachmentAssetContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import { SDKReduxOperations, Channels, HelperFunctions as SDKHelperFunctions } from 'sdk';
import AttachmentFolders from './AttachmentFolders';
import AttachmentFiles from './AttachmentFiles';
import RelatedFilesPopupContent from './RelatedFilesPopupContent';
import AssetAttachmentsSmall from 'assets/images/EmptyDataSet/AssetAttachmentSmall.png';
import CreateOptionsInlineModal from './CreateOptionsInlineModal';
import styles from './style.module.scss';

class AssetAttachmentContent extends Component {
  state = {
    isInitialFetch: true,
    isFetchingFiles: false,
    isLoadingMore: false,
    folderId: null,
    sort: null,
    sortOrder: null,
    showNewAttachmentModalForType: null,
    isDeletingAttachment: false,
    attachmentCountPerFolderId: {},
    everyFolderIsEmpty: true,
    showSearch: false,
    showRelated: false,
    isSearching: false,
    searchTerm: '',
    showUploadAttachmentErrorWarning: false,
  };

  folderChannel = null;

  componentDidMount() {
    this.props.setAssetId(this.props.assetId);
    this.folderChannel = this.props.joinAttachmentFoldersChannel(this.props.currentSystem.id);
    this.fetchAttachments();
  }

  componentDidUpdate(prevProps) {
    const attachmentFolders = this.props.allAttachmentFolders;
    const prevAttachmentFolders = prevProps.allAttachmentFolders;
    if (!attachmentFolders.every(e => prevAttachmentFolders.includes(e))) {
      this.fetchCountForFolders();
    }

    if (this.props.folderId !== prevProps.folderId) {
      this.fetchAttachments();
    }
  }

  componentWillUnmount() {
    this.folderChannel.leave();
    this.props.setFolderId(null);
  }

  getFetchParams = () => {
    const { folderId } = this.props;
    let params = {
      sort: this.state.sort || 'title',
      'sort-order': this.state.sortOrder || 'asc',
    };

    if (this.state.showSearch) {
      params = { ...params, search: this.state.searchTerm };
    } else {
      if (folderId) {
        params = { ...params, attachment_folder_id: folderId };
      } else {
        params = {
          ...params,
          attachment_folder_id: {
            [SDKHelperFunctions.FILTER_COMPARABLES.Exists]: false,
          },
        };
      }
    }

    if (this.state.showRelated) {
      params = { ...params, include_related_assets: true };
    }

    return params;
  };

  fetchAttachments = (params = {}) => {
    this.setState({ isFetchingFiles: true });

    let fetchFunction;
    if (this.state.showSearch) {
      fetchFunction = this.props.fetchSearchAttachments;
    } else {
      fetchFunction = this.props.fetchListAttachments;
    }

    return fetchFunction(this.props.assetId, this.getFetchParams()).then(result => {
      this.setState({ isFetchingFiles: false, isInitialFetch: false });
      return result;
    });
  };

  fetchMoreAttachments = () => {
    if (this.state.showSearch && this.props.searchIsFullyLoaded) return null;
    if (!this.state.showSearch && this.props.listIsFullyLoaded) return null;
    if (this.state.isLoadingMore) return null;

    let fetchFunction;
    let params;
    if (this.state.showSearch) {
      fetchFunction = this.props.fetchSearchAttachments;
      params = { paginate_from: this.props.searchPaginateFrom };
    } else {
      fetchFunction = this.props.fetchListAttachments;
      params = { paginate_from: this.props.listPaginateFrom };
    }

    this.setState({ isLoadingMore: true });
    fetchFunction(this.props.assetId, { ...this.getFetchParams(), ...params })
      .then(() => {
        this.setState({ isLoadingMore: false });
      })
      .catch(() => {
        this.setState({ isLoadingMore: false });
      });
  };

  fetchCountForFolders = () => {
    const attrs = {
      attachment_folder_ids: this.props.allAttachmentFolders.map(folder => folder.id).join(),
      include_related_assets: this.state.showRelated,
    };
    this.folderChannel.push(`get_attachment_count:${this.props.asset.id}`, attrs).receive('ok', payload => {
      const everyFolderIsEmpty = Object.keys(payload.count).every(key => payload.count[key] === 0);
      this.setState({ everyFolderIsEmpty, attachmentCountPerFolderId: payload.count });
    });
  };

  createAttachment = params => {
    if (params.attachment_folder_id !== this.props.folderId) {
      this.props.setFolderId(params.attachment_folder_id);
    }

    if (params.asset_ids) {
      this.createAttachmentForMultipleAssets(params);
    } else {
      this.createAttachmentForSingleAsset(params);
    }
  };

  createAttachmentForSingleAsset = params => {
    const { attachment_folder_id, description, title, extension, file, type, mime_type, link_url } = params;
    const temporaryId = uuid.v4();
    if (type === 'file') {
      this.props.beginUpload({ temporaryId, attachment_folder_id, namespace: this.props.assetId });
      this.props
        .createAttachmentForAsset(this.props.asset.id, {
          attachment_folder_id,
          description,
          title,
          mime_type,
          type,
          extension,
        })
        .then(({ data: attachment }) => {
          return SDKHelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: this.props.assetId,
              });
            }
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  attachment,
                  assetId: this.props.assetId,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.assetId,
                  });
                  this.fetchCountForFolders();
                });
            })
            .catch(e => {
              this.props.cancelUpload({ temporaryId, namespace: this.props.assetId });
              this.setState({ showUploadAttachmentErrorWarning: true });
            });
        });
    } else {
      this.props.beginUpload({ temporaryId, attachment_folder_id, namespace: this.props.assetId });
      this.props
        .createAttachmentForAsset(this.props.asset.id, {
          attachment_folder_id,
          description,
          title,
          type,
          link_url,
        })
        .then(({ data: attachment }) => {
          this.props.completeUpload({ id: attachment.id, temporaryId, namespace: this.props.assetId });
          this.fetchCountForFolders();
        });
    }
  };

  createAttachmentForMultipleAssets = params => {
    const {
      attachment_folder_id,
      asset_ids,
      description,
      title,
      extension,
      file,
      type,
      mime_type,
      link_url,
    } = params;
    const temporaryId = uuid.v4();
    if (type === 'file') {
      this.props.beginUpload({ temporaryId, attachment_folder_id, namespace: this.props.assetId });
      this.props
        .createAttachmentForMultipleAssets(this.props.currentSystem.id, {
          asset_ids,
          attachment_folder_id,
          description,
          title,
          mime_type,
          type,
          extension,
        })
        .then(({ data: attachment }) => {
          return SDKHelperFunctions.uploadFileToS3(
            { url: attachment.attachment_version.upload_url, file, mime_type },
            ({ loaded, total }) => {
              this.props.updateUpload({
                id: attachment.id,
                loaded,
                total,
                temporaryId,
                namespace: this.props.assetId,
              });
            }
          )
            .then(() => {
              return this.props
                .attachmentVersionUploaded(attachment.attachment_version.id, {
                  attachment,
                  assetId: this.props.assetId,
                })
                .then(() => {
                  this.props.completeUpload({
                    id: attachment.id,
                    temporaryId,
                    namespace: this.props.assetId,
                  });
                  this.fetchCountForFolders();
                });
            })
            .catch(e => {
              this.props.cancelUpload({ temporaryId, namespace: this.props.assetId });
              this.setState({ showUploadAttachmentErrorWarning: true });
            });
        });
    } else {
      this.props.beginUpload({ temporaryId, attachment_folder_id, namespace: this.props.assetId });
      this.props
        .createAttachmentForMultipleAssets(this.props.currentSystem.id, {
          attachment_folder_id,
          asset_ids,
          description,
          title,
          type,
          link_url,
        })
        .then(({ data: attachment }) => {
          this.props.completeUpload({ id: attachment.id, temporaryId, namespace: this.props.assetId });
          this.fetchCountForFolders();
        });
    }
  };

  deleteAttachment = attachment => {
    this.setState({ isDeletingAttachment: true });
    this.props
      .deleteAttachment(attachment.id)
      .then(() => {
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.asset.attachments.delete-success" />} />
        );
        this.setState({ isDeletingAttachment: false });
        this.fetchCountForFolders();
      })
      .catch(() => {
        this.setState({ isDeletingAttachment: false });
      });
  };

  removeAssetFromAttachment = attachment => {
    this.setState({ isRemovingAttachmentFromAsset: true });
    this.props
      .removeAttachmentFromAsset(this.props.asset.id, attachment.id)
      .then(() => {
        this.setState({
          isRemovingAttachmentFromAsset: false,
        });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.asset.attachments.remove-connection-success" />}
          />
        );
      })
      .catch(e => {
        this.setState({ isRemovingAttachmentFromAsset: false });
      });
  };

  toogleSearch = () => {
    this.setState(prevState => ({
      showSearch: !prevState.showSearch,
      searchTerm: '',
    }));
  };

  toogleRelated = () => {
    this.setState(
      prevState => ({
        showRelated: !prevState.showRelated,
      }),
      () => {
        this.fetchAttachments();
        this.fetchCountForFolders();
      }
    );
  };

  buildFolderPathForFolderId = folderId => {
    const attachmentFolder = this.props.allAttachmentFolders.find(
      attachmentFolder => folderId === attachmentFolder.id
    );
    if (attachmentFolder == null) return [];
    let attachmentFolders = [attachmentFolder];
    this.props.allAttachmentFolders.forEach(loopedAttachmentFolder => {
      if (attachmentFolder.attachment_folder_parent_id === loopedAttachmentFolder.id) {
        attachmentFolders = [
          ...this.buildFolderPathForFolderId(loopedAttachmentFolder.id),
          ...attachmentFolders,
        ];
      }
    });
    return attachmentFolders;
  };

  hasNoFilesOrFolders = () => {
    if (
      !this.state.isFetchingFiles &&
      this.props.folderId == null &&
      this.state.everyFolderIsEmpty &&
      this.props.listAttachments.length === 0 &&
      this.props.uploadingAttachments.length === 0
    )
      return true;

    return false;
  };

  renderLoader = () => (
    <div className={styles['files-loader']}>
      <Loader small />
    </div>
  );

  renderEmptyDataset = () => {
    if (this.props.selectable) {
      return (
        <div className={styles['empty-container']}>
          <WhiteCard centerContent>
            <EmptyDataSet
              title={
                <FormattedMessage id="components.asset-attachment-content.selectable-empty-dataset.title" />
              }
              subtitle={
                <FormattedMessage id="components.asset-attachment-content.selectable-empty-dataset.subtitle" />
              }
              image={AssetAttachmentsSmall}
              tiny
              horizontal
              listContainer
            />
          </WhiteCard>
        </div>
      );
    } else {
      return (
        <div className={styles['empty-container']}>
          <WhiteCard centerContent>
            <EmptyDataSet
              title={<FormattedMessage id="screens.asset.attachments.empty-data-set.title" />}
              subtitle={<FormattedMessage id="screens.asset.attachments.empty-data-set.subtitle" />}
              image={AssetAttachmentsSmall}
              tiny
              horizontal
              listContainer
            />
          </WhiteCard>
        </div>
      );
    }
  };

  renderNewAttachmentModal = () => {
    if (!this.state.showNewAttachmentModalWithType) return null;

    return (
      <NewAttachmentModal
        folderId={this.props.folderId}
        createForAssetId={this.props.assetId}
        isOpen={this.state.showNewAttachmentModalWithType != null}
        type={this.state.showNewAttachmentModalWithType}
        onClose={() => this.setState({ showNewAttachmentModalWithType: null })}
        onCreateNew={this.createAttachment}
      />
    );
  };

  renderFolderPathTitle = () => {
    const folderId = this.props.folderId;
    const folderPath = this.buildFolderPathForFolderId(folderId);
    if (this.state.showSearch) {
      return (
        <div className={styles['folder-path']}>
          <PathItem active clickable={false}>
            <FormattedMessage
              id="components.asset-attachment-content.search-title"
              values={{ asset: this.props.asset.title }}
            />
          </PathItem>
        </div>
      );
    }
    return (
      <div className={styles['folder-path']}>
        <PathItem
          active={folderId == null}
          clickable={folderId != null}
          onClick={() => {
            this.props.setFolderId(null);
          }}
        >
          {this.props.asset.title}
        </PathItem>
        {folderId == null ? null : (
          <React.Fragment>
            {folderPath.map((attachmentFolder, index) => {
              if (index !== folderPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem
                      clickable
                      onClick={() => {
                        this.props.setFolderId(attachmentFolder.id);
                      }}
                    >
                      {attachmentFolder.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem active>{attachmentFolder.title}</PathItem>
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderPlusButton = () => {
    if (!this.props.canEditAssets) return null;
    if (this.props.selectable) return null;
    if (this.state.showSearch) {
      return <Button disabled icon={<Icon regular type="plus" />} />;
    }
    return (
      <CreateOptionsInlineModal
        onCreateNew={type => this.setState({ showNewAttachmentModalWithType: type })}
      />
    );
  };

  renderToolbar = () => (
    <div className={styles['title-container']}>
      {this.renderFolderPathTitle()}
      <Button.Group>
        <Tooltip
          label={
            <RelatedFilesPopupContent showRelated={this.state.showRelated} assetId={this.props.assetId} />
          }
          trigger={
            <div>
              <Button
                label={
                  this.state.showRelated
                    ? 'components.asset-attachment-content.hide-related-button'
                    : 'components.asset-attachment-content.show-related-button'
                }
                onClick={this.toogleRelated}
              />
            </div>
          }
        />
        <Button
          active={this.state.showSearch}
          key={this.state.showSearch}
          icon={<Icon regular type="search" />}
          onClick={this.toogleSearch}
        />
        {this.renderPlusButton()}
      </Button.Group>
    </div>
  );

  renderSearch = () => (
    <div className={styles['search-field-container']}>
      <NewSearchField
        value={this.state.searchTerm}
        autoFocus
        small
        againstGrayBackground
        debounce
        placeholder={this.props.intl.formatMessage({
          id: 'general.search-placeholder',
        })}
        onSearch={value => {
          this.setState({
            searchTerm: value,
            isSearching: true,
          });
        }}
        onDebouncedSearch={value => {
          this.fetchAttachments().then(() => {
            this.setState({ isSearching: false });
          });
        }}
        alwaysShowClear
        onClear={() => {
          this.toogleSearch();
        }}
      />
    </div>
  );

  renderFolders = () => (
    <AttachmentFolders
      attachmentCountPerFolderId={this.state.attachmentCountPerFolderId}
      assetId={this.props.assetId}
      parentFolderId={this.props.folderId}
      onSelect={attachmentFolder => {
        this.props.setFolderId(attachmentFolder.id);
      }}
    />
  );

  renderFiles = () => {
    if (this.state.showSearch && this.state.searchTerm === '') return null;

    return (
      <AttachmentFiles
        asset={this.props.asset}
        loading={this.state.isFetchingFiles || this.state.isSearching}
        isLoadingMore={this.state.isLoadingMore}
        searchList={this.state.showSearch}
        showRelated={this.state.showRelated}
        parentFolderId={this.props.folderId}
        isRemovingAttachmentFromAsset={this.state.isRemovingAttachmentFromAsset}
        isDeletingAttachment={this.state.isDeletingAttachment}
        deleteAttachment={this.deleteAttachment}
        removeAssetFromAttachment={this.removeAssetFromAttachment}
        fetchCountForFolders={this.fetchCountForFolders}
        selectable={this.props.selectable}
        onSelectAttachment={this.props.onSelectAttachment}
        sort={this.state.sort}
        onSortChange={(sort, sortOrder) => {
          this.setState({ sort, sortOrder }, () => {
            this.fetchAttachments();
          });
        }}
      />
    );
  };

  renderContent = () => {
    if (this.state.isInitialFetch) {
      return this.renderLoader();
    } else if (this.state.showSearch) {
      return (
        <React.Fragment>
          {this.renderToolbar()}
          {this.renderSearch()}
          {this.renderFiles()}
        </React.Fragment>
      );
    } else if (this.hasNoFilesOrFolders()) {
      return (
        <React.Fragment>
          {this.renderToolbar()}
          {this.renderEmptyDataset()}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.renderToolbar()}
          {this.renderFolders()}
          {this.renderFiles()}
        </React.Fragment>
      );
    }
  };

  renderDropzoneIfNeeded = children => {
    if (!this.props.selectable) {
      return (
        <DropZone
          active={this.props.canEditAssets}
          folderId={this.props.folderId}
          onDrop={data => this.createAttachment(data)}
        >
          {children}
        </DropZone>
      );
    } else {
      return children;
    }
  };

  renderContentContainerIfNeeded = children => {
    if (this.props.includeContentContainer) {
      return <ContentContainer key={this.props.match.params.id}>{children}</ContentContainer>;
    } else {
      return children;
    }
  };

  render() {
    return (
      <>
        <PerfectScrollbar>
          <InfiniteScroll
            loadMore={this.fetchMoreAttachments}
            hasMore={this.state.showSearch ? !this.props.searchIsFullyLoaded : !this.props.listIsFullyLoaded}
            useWindow={false}
            initialLoad={false}
            threshold={350}
            className={styles['infinite-scroll']}
          >
            {this.props.headerComponent}
            {this.renderDropzoneIfNeeded(
              this.renderContentContainerIfNeeded(
                <React.Fragment>
                  {this.renderContent()}
                  {this.renderNewAttachmentModal()}
                </React.Fragment>
              )
            )}
          </InfiniteScroll>
        </PerfectScrollbar>
        <UploadAttachmentErrorModal
          open={this.state.showUploadAttachmentErrorWarning}
          onClose={() => this.setState({ showUploadAttachmentErrorWarning: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      joinAttachmentFoldersChannel: Channels.AttachmentFoldersChannel.join,
      leaveAttachmentFoldersChannel: Channels.AttachmentFoldersChannel.leave,
      cancelUpload: UploadProgressOperations.cancelUpload,
      beginUpload: UploadProgressOperations.beginUpload,
      updateUpload: UploadProgressOperations.updateUpload,
      completeUpload: UploadProgressOperations.completeUpload,
      attachmentVersionUploaded: SDKReduxOperations.attachmentVersionUploaded,
      createAttachmentForMultipleAssets: SDKReduxOperations.createAttachmentForMultipleAssets,
      createAttachmentForAsset: SDKReduxOperations.createAttachmentForAsset,
      deleteAttachment: SDKReduxOperations.deleteAttachment,
      removeAttachmentFromAsset: SDKReduxOperations.removeAttachmentFromAsset,
      fetchListAttachments: AttachmentAssetContentOperations.fetchListAttachments,
      fetchSearchAttachments: AttachmentAssetContentOperations.fetchSearchAttachments,
      setFolderId: AttachmentAssetContentOperations.setFolderId,
      setAssetId: AttachmentAssetContentOperations.setAssetId,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.assetId),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
    folderId: AttachmentAssetContentSelectors.getFolderId(state),
    uploadingAttachments: UploadProgressSelectors.getUploadingAttachmentsForFolder(
      state,
      ownProps.assetId,
      ownProps.parentFolderId
    ),
    listAttachments: AttachmentAssetContentSelectors.getListAttachments(state),
    listPaginateFrom: AttachmentAssetContentSelectors.getListPaginateFrom(state),
    listIsFullyLoaded: AttachmentAssetContentSelectors.getListIsFullyLoaded(state),
    searchAttachments: AttachmentAssetContentSelectors.getSearchAttachments(state),
    searchPaginateFrom: AttachmentAssetContentSelectors.getSearchPaginateFrom(state),
    searchIsFullyLoaded: AttachmentAssetContentSelectors.getSearchIsFullyLoaded(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetAttachmentContent)));

AssetAttachmentContent.propTypes = {
  assetId: PropTypes.string,
  selectable: PropTypes.bool,
  onSelectAttachment: PropTypes.func,
};

AssetAttachmentContent.defaultProps = {
  selectable: false,
  onSelectAttachment: () => null,
};
