import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { SDKReduxOperations } from 'sdk';
import { WorkOrderEditSelectors } from 'state/ducks/workOrderEdit';
import { List, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import ChecklistListItem from './ChecklistListItem';

class Checklist extends Component {
  render() {
    const { checklistInstances, checklistTemplateLinks, isAddingChecklistTemplate } = this.props;
    if (checklistInstances && checklistInstances.length > 0) {
      return <ChecklistListItem instanceId={this.props.checklistInstances[0].id} />;
    }
    if (checklistTemplateLinks && checklistTemplateLinks.length > 0) {
      return <ChecklistListItem templateLinkId={this.props.checklistTemplateLinks[0].id} />;
    }
    if (isAddingChecklistTemplate) {
      return (
        <List.Item>
          <List.Item.IconColumn icon={<Icon type="list" />} loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteChecklistTemplate: SDKReduxOperations.deleteChecklistTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenanceId = queryString.parse(ownProps.location.search).recurring_maintenance_id;
  const workOrderId = ownProps.match.params.id;

  if (recurringMaintenanceId) {
    const recurringMaintenance = EntitySelectors.getRecurringMaintenance(state, recurringMaintenanceId);
    return {
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        recurringMaintenance.checklist_template_links
      ),
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
    };
  } else {
    const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
    return {
      checklistTemplateLinks: EntitySelectors.getChecklistTemplateLinks(
        state,
        workOrder.checklist_template_links
      ),
      checklistInstances: EntitySelectors.getChecklistInstances(state, workOrder.checklist_instances),
      isAddingChecklistTemplate: WorkOrderEditSelectors.isAddingChecklistTemplate(state),
    };
  }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Checklist)));
