import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { API } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import LogoSvg from 'assets/images/logo.svg';
import { Button } from 'views/components/Shared/General';
import { Layout, Button as LoginButton, RedirectAuthedUserToPrivateApp } from 'views/components/Login';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isVerified: false,
      shouldNavigateBackToApp: false,
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle('Verify Email');
    const token = this.props.match.params.token;
    API.verifyEmail(token)
      .then(() => {
        this.setState({ isFetching: false, isVerified: true });
      })
      .catch(() => {
        this.setState({ isFetching: false, isVerified: false });
      });
  }

  renderVerifyEmailSuccess = () => (
    <>
      <div className={styles['text']}>Your email has been verified</div>
      <div className={styles['subtitle']}>
        You will now be able to receive important notifications to your email address
      </div>
      <div className={styles['button-container']}>
        <LoginButton
          label="Go back to Mainter"
          onClick={() => {
            this.setState({ shouldNavigateBackToApp: true });
          }}
        />
      </div>
    </>
  );

  renderVerifyEmailFailure = () => (
    <>
      <div className={styles['text']}>Your email could not be verified</div>
      <div className={styles['subtitle']}>There was an issue verifying your email</div>
      <div className={styles['button-container']}>
        <LoginButton
          label="Go back to Mainter"
          onClick={() => {
            this.setState({ shouldNavigateBackToApp: true });
          }}
        />
      </div>
    </>
  );

  renderContent = () => {
    if (this.state.isVerified) {
      return this.renderVerifyEmailSuccess();
    }
    return this.renderVerifyEmailFailure();
  };

  render() {
    if (this.state.isFetching) {
      return null;
    }
    if (this.state.shouldNavigateBackToApp) {
      if (this.props.isUserAuthenticated) {
        return <RedirectAuthedUserToPrivateApp />;
      }
      return <Redirect to="/login" />;
    }
    return (
      <Layout>
        {this.renderContent()}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
  };
}

export default connect(mapStateToProps)(VerifyEmail);
