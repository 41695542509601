import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { RequestsSelectors } from 'state/ducks/requests';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    switch (this.props.selectedFilterType) {
      case FILTER_TYPES.RequestTitle: {
        return <Filter.Title />;
      }
      case FILTER_TYPES.RequestDescription: {
        return <Filter.Description />;
      }
      case FILTER_TYPES.Status: {
        return <Filter.Status />;
      }
      case FILTER_TYPES.RequestType: {
        return <Filter.RequestType />;
      }
      case FILTER_TYPES.Priority: {
        return <Filter.Priority />;
      }
      case FILTER_TYPES.CreatedBy: {
        return <Filter.CreatedBy />;
      }
      case FILTER_TYPES.CreatedAt: {
        return <Filter.CreatedAt />;
      }
      case FILTER_TYPES.ArchivedBy: {
        return <Filter.ArchivedBy />;
      }
      case FILTER_TYPES.ApprovedByProductionSupervisor: {
        return <Filter.ApprovedByProductionSupervisor />;
      }
      case FILTER_TYPES.ArchivedComment: {
        return <Filter.ArchivedComment />;
      }
      case FILTER_TYPES.AssetType: {
        return <Filter.AssetType />;
      }
      case FILTER_TYPES.Asset: {
        return <Filter.Asset />;
      }
      case FILTER_TYPES.AssetWithTreeChildren: {
        return <Filter.AssetWithTreeChildren />;
      }
      case FILTER_TYPES.WorkOrderCompletedDate: {
        return <Filter.WorkOrderCompletedDate />;
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFilterType: RequestsSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
