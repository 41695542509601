import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';
import {
  Button,
  EmptyDataSet,
  List,
  Field,
  Pagination,
  SectionHeader,
  Menu,
  WhiteCard,
  NewInlineModal,
  FilterButton,
  Icon,
} from 'views/components/Shared/General';
import { Header } from 'views/components/Shared/Layout';
import { Loader } from 'views/components/Shared/General';
import { SelectSparePartLocationModal } from 'views/components/SparePartLocation';
import { SparePartTypeInlineModal } from 'views/components/SparePart';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { normalizeSparePart, normalizeVendor } from 'sdk/Schemas';
import HelperFunctions from 'utilities/HelperFunctions';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import {
  ShouldOrderSparePartsOperations,
  ShouldOrderSparePartSelectors,
} from 'state/ducks/shouldOrderSpareParts';
import { EntitySelectors } from 'sdk/State/entities';
import SparePartLarge from 'assets/images/EmptyDataSet/SparePartLarge.png';
import SparePartListItem from './SparePartListItem';
import SelectVendorModal from './SelectVendorModal';
import CreatePurchaseOrderForVendorModal from './CreatePurchaseOrderForVendorModal';
import StartPurchaseOrderForVendorModal from './StartPurchaseOrderForVendorModal';
import CancelPurchaseOrderForVendorModal from './CancelPurchaseOrderForVendorModal';
import StartPurchaseOrderForVendorContainer from './StartPurchaseOrderForVendorContainer';
import styles from './style.module.scss';

const FILTERS = [
  'page',
  'not_linked_to_vendor',
  'spare_part_location_with_children_id',
  'spare_part_type_with_children_id',
];

const listSparePartVendorsRequest = SDKHelperFunctions.getCancelTokenForRequest();
const listSparePartsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class ShouldOrderSpareParts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingDataForView: true,
      isEmpty: false,
      sparePartIds: [],
      vendors: [],
      isLoadingSparePartsList: false,
      isLoadingVendors: false,
      pagination: {
        totalPages: 0,
        totalEntries: 0,
      },
      amountOfSparePartsWithLowStockQuantity: 0,
      amountOfSparePartsWithLowStockQuantityAndIsMissingVendor: 0,
      showSelectVendorModal: false,
      showSelectVendorModalForSparePartId: null,
      showCreatePurchaseOrderForVendorModal: false,
      showStartPurchaseOrderForVendorModal: false,
      showCancelPurchaseOrderForVendorModal: false,
      showSelectSparePartLocationModal: false,
    };
  }

  componentWillMount() {
    this.refs = {};
  }

  componentDidMount() {
    this.props.resetState();
    const queryParams = queryString.parse(this.props.location.search);
    const { vendor_id, not_linked_to_vendor } = queryParams;
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.should-order-spare-parts.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.SpareParts);
    Promise.all([
      this.fetchAllSpareParts(queryParams),
      this.fetchVendors(),
      this.fetchAllSpareParts({ ...queryParams, not_linked_to_vendor: true }),
    ]).then(([allSparePartsRequest, vendors, sparePartsWithoutVendorsRequest]) => {
      const { sparePartIds, headers } = allSparePartsRequest;
      const { sparePartIds: sparePartIdsNotLinkedToVendor, headers: hasNoSparePartVendorHeaders } =
        sparePartsWithoutVendorsRequest;
      if (vendor_id) {
        this.listSparePartsForVendor().then(sparePartIds => {
          this.setState({
            sparePartIds,
            isEmpty: sparePartIds.length === 0,
            vendors,
            amountOfSparePartsWithLowStockQuantity:
              SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            amountOfSparePartsWithLowStockQuantityAndIsMissingVendor:
              SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalEntries,
            pagination: {
              ...this.state.pagination,
              totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
              totalEntries: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            },
            isFetchingDataForView: false,
            isLoadingSparePartsList: false,
          });
        });
      } else {
        if (not_linked_to_vendor) {
          this.setState({
            sparePartIds: sparePartIdsNotLinkedToVendor,
            isEmpty: sparePartIds.length === 0,
            vendors,
            amountOfSparePartsWithLowStockQuantity:
              SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            amountOfSparePartsWithLowStockQuantityAndIsMissingVendor:
              SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalEntries,
            pagination: {
              ...this.state.pagination,
              totalPages: SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalPages,
              totalEntries:
                SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalEntries,
            },
            isFetchingDataForView: false,
            isLoadingSparePartsList: false,
          });
        } else {
          this.setState({
            sparePartIds,
            isEmpty: sparePartIds.length === 0,
            vendors,
            amountOfSparePartsWithLowStockQuantity:
              SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            amountOfSparePartsWithLowStockQuantityAndIsMissingVendor:
              SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalEntries,
            pagination: {
              ...this.state.pagination,
              totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
              totalEntries: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            },
            isFetchingDataForView: false,
            isLoadingSparePartsList: false,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (oldQueryParams.show_selected == null && queryParams.show_selected != null) {
      return;
    }
    const oldQueryParamsThatShouldRefetchVendors = {
      spare_part_location_with_children_id: oldQueryParams.spare_part_location_with_children_id,
      spare_part_type_with_children_id: oldQueryParams.spare_part_type_with_children_id,
      primary: oldQueryParams.primary,
    };
    const queryParamsThatShouldRefetchVendors = {
      spare_part_location_with_children_id: queryParams.spare_part_location_with_children_id,
      spare_part_type_with_children_id: queryParams.spare_part_type_with_children_id,
      primary: queryParams.primary,
    };
    const shouldRefetchVendors = !isEqual(
      oldQueryParamsThatShouldRefetchVendors,
      queryParamsThatShouldRefetchVendors
    );
    if (shouldRefetchVendors) {
      this.setState({ isLoadingVendors: true });
      Promise.all([
        this.fetchAllSpareParts(queryParams),
        this.fetchVendors(),
        this.fetchAllSpareParts({ ...queryParams, not_linked_to_vendor: true }),
      ]).then(([allSparePartsRequest, vendors, sparePartsWithoutVendorsRequest]) => {
        const { headers: allSparePartsHeaders } = allSparePartsRequest;
        const { headers: hasNoSparePartVendorHeaders } = sparePartsWithoutVendorsRequest;
        this.setState({
          vendors,
          amountOfSparePartsWithLowStockQuantity:
            SDKHelperFunctions.getPaginationFromHeader(allSparePartsHeaders).totalEntries,
          amountOfSparePartsWithLowStockQuantityAndIsMissingVendor:
            SDKHelperFunctions.getPaginationFromHeader(hasNoSparePartVendorHeaders).totalEntries,
          isLoadingVendors: false,
        });
      });
    }
    const shouldRefetchSpareParts = !isEqual(oldQueryParams, queryParams);
    if (shouldRefetchSpareParts) {
      const { vendor_id } = queryParams;
      if (vendor_id) {
        this.listSparePartsForVendor()
          .then(sparePartIds => {
            this.refs = {};
            this.setState({ isLoadingSparePartsList: false, sparePartIds });
          })
          .catch(() => {});
      } else {
        this.setState({
          isLoadingSparePartsList: true,
        });
        this.fetchAllSpareParts(queryParams)
          .then(({ sparePartIds, headers }) => {
            this.setState({
              sparePartIds,
              isLoadingSparePartsList: false,
              pagination: {
                ...this.state.pagination,
                totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
                totalEntries: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
              },
            });
          })
          .catch(e => e);
      }
    }
  }

  /*
    -------
      Requests
    -------
  */

  cancelSparePartsRequests = () => {
    listSparePartVendorsRequest.cancel();
    listSparePartsRequest.cancel();
  };

  fetchVendors = () => {
    const { primary, spare_part_location_with_children_id, spare_part_type_with_children_id } =
      queryString.parse(this.props.location.search);
    let params = {
      no_pagination: true,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
      primary: true,
    };
    if (primary === 'false') {
      params = {
        ...params,
        primary: null,
      };
    }
    return API.listVendorsForShouldOrder(this.props.currentSystem.id, params)
      .then(({ data: vendorsForShouldOrder }) => {
        const { entities, result: vendorIds } = normalizeVendor(vendorsForShouldOrder);
        this.props.updateEntities(entities);
        return vendorIds.map(id => entities.vendorById[id]);
      })
      .catch(e => e);
  };

  fetchAllSpareParts = (params = {}) => {
    let attrs = {
      ...SDKHelperFunctions.buildQueryParamsForList(params, FILTERS),
    };
    if (attrs.not_linked_to_vendor) {
      attrs = {
        ...attrs,
        not_linked_to_vendor: null,
        spare_part_vendors: {
          exists: false,
        },
      };
    }
    return API.listSpareParts(
      this.props.currentSystem.id,
      {
        stock_quantity: { low: true },
        page_size: 8,
        ...attrs,
      },
      listSparePartsRequest.getCancelTokenConfig()
    ).then(({ data: spareParts, headers }) => {
      const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
      this.props.updateEntities(entities);
      return { sparePartIds, headers };
    });
  };

  listSparePartsForVendor = () => {
    const { vendor_id, primary, spare_part_location_with_children_id, spare_part_type_with_children_id } =
      queryString.parse(this.props.location.search);
    this.setState({
      isLoadingSparePartsList: true,
      sparePartIds: [],
    });
    let params = {
      stock_quantity: { low: true },
      no_pagination: true,
      spare_part_location_with_children_id,
      spare_part_type_with_children_id,
    };
    if (primary === 'false') {
      params = {
        ...params,
        vendor_id,
        spare_part_vendors_for_vendor: vendor_id,
        spare_part_vendors: { vendor_id: vendor_id },
      };
    } else {
      params = {
        ...params,
        vendor_id,
        spare_part_vendors_for_vendor: vendor_id,
        primary_vendor_id: {
          [SDKHelperFunctions.FILTER_COMPARABLES.ExactOrNil]: vendor_id,
        },
        spare_part_vendors: { vendor_id: vendor_id },
      };
    }
    return API.listSpareParts(
      this.props.currentSystem.id,
      params,
      listSparePartVendorsRequest.getCancelTokenConfig()
    ).then(({ data: spareParts }) => {
      const { entities, result: sparePartIds } = normalizeSparePart(spareParts);
      this.props.updateEntities(entities);
      return sparePartIds;
    });
  };

  /*
    -------
      Helper functions
    -------
  */

  changeQueryParams = obj => {
    this.props.history.push(
      `?${SDKHelperFunctions.convertObjToQueryParameters({
        ...queryString.parse(this.props.location.search),
        page: null,
        ...obj,
      })}`
    );
  };

  /*
    -------
      Render functions
    -------
  */

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.should-order-spare-parts.empty-data-set.title" />}
      subtitle={<FormattedMessage id="screens.should-order-spare-parts.empty-data-set.subtitle" />}
      image={SparePartLarge}
      big
      mainContentContainer
    />
  );

  renderSearchedEmptyDataset = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          tiny
          listContainer
        />
      </WhiteCard>
    );
  };

  renderVendorsEmptyDataSet = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['title']}>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </div>
        <div className={styles['subtitle']}>
          <FormattedMessage id="general.empty-data-set-search.subtitle" />
        </div>
      </div>
    );
  };

  renderLeftContainerVendorHeader = () => {
    const { primary } = queryString.parse(this.props.location.search);
    return (
      <div className={styles['vendor-header-container']}>
        <SectionHeader>
          <div className={styles['vendor-header']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.should-order-spare-parts.vendor-title" />
            </div>
            <div className={styles['show-primary-button-container']}>
              <div ref={ref => (this.goToPageInlineModalPositioningRef = ref)}>
                <Button
                  type="icon"
                  iconButtonSize={28}
                  icon={<Icon regular size={14} type="ellipsis-h" />}
                  onClick={() => {
                    this.setState(prevState => ({
                      showGoToPageInlineModal: !prevState.showGoToPageInlineModal,
                    }));
                  }}
                />
              </div>
              <NewInlineModal
                minWidth={267}
                positionToRef={this.goToPageInlineModalPositioningRef}
                open={this.state.showGoToPageInlineModal}
                onClose={() => {
                  this.setState({ showGoToPageInlineModal: false });
                }}
              >
                <NewInlineModal.Header>
                  <SectionHeader noBorderTop>
                    <FormattedMessage id="screens.should-order-spare-parts.settings" />
                  </SectionHeader>
                </NewInlineModal.Header>
                <div className={styles['primary-checkbox-container']}>
                  <Field.Checkbox
                    checked={primary === 'false'}
                    label={
                      <FormattedMessage id="screens.should-order-spare-parts.show-spare-parts-with-primary" />
                    }
                    onChange={() => {
                      this.changeQueryParams({
                        primary: primary === 'false' ? null : false,
                      });
                    }}
                  />
                </div>
              </NewInlineModal>
            </div>
          </div>
        </SectionHeader>
      </div>
    );
  };

  renderLeftContainer = () => {
    if (this.props.selectedVendor) {
      return this.renderLeftContainerWithVendor();
    }
    return this.renderLeftContainerWithoutVendor();
  };

  renderLeftContainerWithVendor = () => {
    const { not_linked_to_vendor, vendor_id, show_selected } = queryString.parse(this.props.location.search);
    return (
      <div className={styles['left-panel-container']}>
        <SectionHeader noBorderTop>
          <FormattedMessage id="screens.should-order-spare-parts.show-spare-parts-at-vendor" />
        </SectionHeader>
        <div className={styles['left-panel']}>
          <PerfectScrollbar>
            <div className={styles['all-spare-parts-container']}>
              <Menu.Item
                selected={vendor_id && show_selected == null}
                title={this.props.selectedVendor.name}
                onClick={() => {
                  this.changeQueryParams({
                    show_selected: null,
                    vendor_id: this.props.selectedVendor.id,
                    not_linked_to_vendor: null,
                  });
                }}
                number={this.props.selectedVendor.spare_part_count}
              />
              <Menu.Item
                selected={not_linked_to_vendor == null && vendor_id == null && show_selected == null}
                title={<FormattedMessage id="screens.should-order-spare-parts.other-vendors" />}
                onClick={() => {
                  this.changeQueryParams({
                    show_selected: null,
                    vendor_id: null,
                    not_linked_to_vendor: null,
                  });
                }}
                number={this.state.amountOfSparePartsWithLowStockQuantity}
              />
            </div>
          </PerfectScrollbar>
        </div>
        <div className={styles['selected-vendor']}>
          <div>
            <SectionHeader>
              <div className={styles['vendor-section']}>
                <div className={styles['vendor']}>
                  <div className={styles['vendor-label']}>
                    <FormattedMessage id="screens.should-order-spare-parts.selected-vendor" />
                  </div>
                  <div className={styles['vendor-text']}>{this.props.selectedVendor.name}</div>
                </div>
                <div>
                  <div ref={ref => (this.vendorInlineModalPositioningRef = ref)}>
                    <Button
                      type="icon"
                      iconButtonSize={28}
                      icon={<Icon regular size={14} type="ellipsis-h" />}
                      onClick={() => {
                        this.setState(prevState => ({
                          showVendorInlineModal: !prevState.showVendorInlineModal,
                        }));
                      }}
                    />
                  </div>
                  <NewInlineModal
                    minWidth={230}
                    positionToRef={this.vendorInlineModalPositioningRef}
                    open={this.state.showVendorInlineModal}
                    onClose={() => {
                      this.setState({ showVendorInlineModal: false });
                    }}
                  >
                    <NewInlineModal.Dropdown>
                      <NewInlineModal.Dropdown.Items>
                        <NewInlineModal.Dropdown.Item
                          onClick={() => {
                            this.setState({
                              showVendorInlineModal: false,
                              showCancelPurchaseOrderForVendorModal: true,
                            });
                            this.changeQueryParams({ primary: null });
                          }}
                        >
                          <FormattedMessage id="screens.should-order-spare-parts.cancel-purchase-order" />
                        </NewInlineModal.Dropdown.Item>
                      </NewInlineModal.Dropdown.Items>
                    </NewInlineModal.Dropdown>
                  </NewInlineModal>
                </div>
              </div>
            </SectionHeader>
          </div>
          <div className={styles['selected-articles']}>
            <Menu.Item
              selected={show_selected != null}
              title={<FormattedMessage id="screens.should-order-spare-parts.selected-articles" />}
              onClick={() => {
                this.changeQueryParams({ show_selected: true });
              }}
              number={this.props.purchaseOrderRows.length.toString()}
            />
            <Button
              primary
              label="screens.should-order-spare-parts.create-draft"
              onClick={() => {
                this.setState({ showCreatePurchaseOrderForVendorModal: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderLeftContainerWithoutVendor = () => {
    const { not_linked_to_vendor, vendor_id, show_selected } = queryString.parse(this.props.location.search);
    const selected = not_linked_to_vendor == null && vendor_id == null && show_selected == null;
    return (
      <div className={styles['left-panel-container']}>
        <PerfectScrollbar>
          <div className={styles['left-panel']}>
            <div className={styles['all-spare-parts-container']}>
              <Menu.Item
                selected={selected}
                title={<FormattedMessage id="screens.should-order-spare-parts.all-spare-parts" />}
                onClick={() => {
                  this.changeQueryParams({ vendor_id: null, not_linked_to_vendor: null });
                }}
                number={this.state.amountOfSparePartsWithLowStockQuantity}
              />
            </div>
            {this.renderLeftContainerVendorHeader()}
            {this.renderVendors()}
          </div>
        </PerfectScrollbar>
      </div>
    );
  };

  renderMissingVendorsButton = () => {
    const { not_linked_to_vendor } = queryString.parse(this.props.location.search);
    const { amountOfSparePartsWithLowStockQuantityAndIsMissingVendor } = this.state;
    if (amountOfSparePartsWithLowStockQuantityAndIsMissingVendor === 0) {
      return null;
    }
    return (
      <Menu.Item
        selected={not_linked_to_vendor === 'true'}
        title={<FormattedMessage id="screens.should-order-spare-parts.spare-parts-missing-vendors" />}
        onClick={() => {
          this.changeQueryParams({
            vendor_id: null,
            not_linked_to_vendor: true,
          });
        }}
        number={this.state.amountOfSparePartsWithLowStockQuantityAndIsMissingVendor}
      />
    );
  };

  renderVendorButtons = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    const { amountOfSparePartsWithLowStockQuantityAndIsMissingVendor, vendors } = this.state;
    if (vendors.length === 0 && amountOfSparePartsWithLowStockQuantityAndIsMissingVendor === 0) {
      return this.renderVendorsEmptyDataSet();
    }
    return (
      <>
        {this.state.vendors.map(vendor => {
          return (
            <Menu.Item
              selected={vendor_id === vendor.id}
              title={vendor.name}
              onClick={() => {
                this.changeQueryParams({
                  show_selected: null,
                  not_linked_to_vendor: null,
                  vendor_id: vendor.id,
                });
              }}
              number={vendor.spare_part_count}
            />
          );
        })}
        {this.renderMissingVendorsButton()}
      </>
    );
  };

  renderVendors = () => {
    if (this.state.isLoadingVendors) {
      return (
        <div className={styles['list-items-container']}>
          <Menu.Item loading />
          <Menu.Item loading />
        </div>
      );
    }
    return <div className={styles['list-items-container']}>{this.renderVendorButtons()}</div>;
  };

  renderPagination = () => {
    const { page, vendor_id } = queryString.parse(this.props.location.search);
    const { pagination } = this.state;
    if (pagination.totalEntries <= 8) return null;
    if (vendor_id) return null;
    return (
      <div className={styles['pagination']}>
        <p className={styles['total-entries']}>
          <FormattedMessage
            id="screens.spare-parts.total-entries"
            values={{
              amount: pagination.totalEntries,
            }}
          />
        </p>
        <Pagination
          hideOptions
          currentPage={page ? Number(page) : 1}
          totalPages={pagination.totalPages}
          onSelectPage={page => {
            this.changeQueryParams({ page });
          }}
        />
      </div>
    );
  };

  renderSparePartListItemLoader = () => {
    const { selectedVendor } = this.props;
    return (
      <List.Item expandable loading>
        {selectedVendor ? (
          <List.Item.Column borderLeft width={37}>
            <div className={styles['checkbox-container']}>
              <span>
                <Field.Checkbox disabled />
              </span>
            </div>
          </List.Item.Column>
        ) : null}
        <List.Item.ImageColumn loading />
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  };

  renderLoader = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    const amountOfSpareParts = this.state.sparePartIds.length === 0 ? 2 : this.state.sparePartIds.length;
    return (
      <>
        {vendor_id ? this.renderSelectAllCheckbox() : null}
        <List>
          {Array(amountOfSpareParts)
            .fill()
            .map(() => this.renderSparePartListItemLoader())}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderSelectAllCheckbox = () => {
    const { purchaseOrderRows } = this.props;
    const { sparePartIds, isLoadingSparePartsList } = this.state;
    const allRowsAreSelected = purchaseOrderRows.length >= sparePartIds.length;
    if (this.props.selectedVendor) {
      return (
        <div className={styles['add-all-container']}>
          <Field.Checkbox
            disabled={isLoadingSparePartsList}
            checked={allRowsAreSelected && isLoadingSparePartsList === false}
            label={<FormattedMessage id="screens.should-order-spare-parts.select-all" />}
            onChange={value => {
              if (allRowsAreSelected) {
                this.props.removeMultipleSpareParts();
              } else {
                let values = {};
                Object.values(this.refs).forEach(ref => {
                  values = {
                    ...values,
                    [ref.props.sparePartId]: ref.getPurchaseOrderRow(),
                  };
                });
                this.props.addMultipleSpareParts(values);
              }
            }}
          />
        </div>
      );
    }
    return null;
  };

  renderSpareParts = () => {
    const { vendor_id, show_selected } = queryString.parse(this.props.location.search);
    if (this.state.isLoadingSparePartsList) {
      return this.renderLoader();
    }
    if (show_selected) {
      if (this.props.purchaseOrderRows.length === 0) {
        return (
          <WhiteCard centerContent>
            <EmptyDataSet
              subtitle={
                <FormattedMessage id="screens.should-order-spare-parts.selected-spare-parts-empty-data-set.title" />
              }
            />
          </WhiteCard>
        );
      }
      return (
        <List>
          {this.props.purchaseOrderRows.map(({ spare_part_id }) => (
            <SparePartListItem sparePartId={spare_part_id} />
          ))}
        </List>
      );
    }
    if (vendor_id) {
      if (this.state.sparePartIds.length === 0) {
        return this.renderSearchedEmptyDataset();
      }
      return (
        <>
          {this.renderSelectAllCheckbox()}
          <List>
            {this.state.sparePartIds.map(sparePartId => (
              <SparePartListItem
                sparePartId={sparePartId}
                ref={instance => {
                  this.refs[sparePartId] = instance;
                }}
                onSelectVendor={() => {
                  this.setState({
                    showSelectVendorModal: true,
                    showSelectVendorModalForSparePartId: sparePartId,
                  });
                }}
                onStartPurchaseOrder={() => {
                  this.setState({ showStartPurchaseOrderForVendorModal: true });
                }}
              />
            ))}
          </List>
        </>
      );
    }
    if (this.state.sparePartIds.length === 0) {
      return this.renderSearchedEmptyDataset();
    }
    return (
      <>
        <List>
          {this.state.sparePartIds.map(sparePartId => (
            <SparePartListItem
              sparePartId={sparePartId}
              onSelectVendor={() => {
                this.setState({
                  showSelectVendorModal: true,
                  showSelectVendorModalForSparePartId: sparePartId,
                });
              }}
              onStartPurchaseOrder={() => {
                this.setState({
                  showSelectVendorModal: true,
                  showSelectVendorModalForSparePartId: sparePartId,
                });
              }}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderMiddleContainerTopBar = () => {
    const { vendor_id, primary, show_selected } = queryString.parse(this.props.location.search);
    if (this.props.canEditPurchaseOrders && this.props.canEditSpareParts) {
      if (vendor_id && this.props.selectedVendor == null) {
        return (
          <div className={styles['top-bar']}>
            <div>
              <Button
                gray
                small
                label="screens.should-order-spare-parts.start-purchase-order"
                onClick={() => this.setState({ showStartPurchaseOrderForVendorModal: true })}
              />
            </div>
            <div className={styles['selected-vendor']}>
              <FormattedMessage
                id="screens.should-order-spare-parts.start-purchase-order-for-vendor"
                values={{ vendor: <StartPurchaseOrderForVendorContainer id={vendor_id} /> }}
              />
            </div>
          </div>
        );
      }
      if (vendor_id && show_selected == null && this.props.selectedVendor) {
        return (
          <div className={styles['top-bar']}>
            <Field.Checkbox
              checked={primary === 'false'}
              label={<FormattedMessage id="screens.should-order-spare-parts.show-spare-parts-with-primary" />}
              onChange={() => {
                this.changeQueryParams({
                  primary: primary === 'false' ? null : false,
                });
              }}
            />
          </div>
        );
      }
    }

    return null;
  };

  renderMiddleContainer = () => {
    return (
      <div className={styles['middle-container']}>
        <div className={styles['spare-parts-wrapper']}>
          {this.renderMiddleContainerTopBar()}
          <PerfectScrollbar>
            <div className={styles['spare-parts-container']}>{this.renderSpareParts()}</div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  };

  renderSelectVendorModal = () => (
    <SelectVendorModal
      open={this.state.showSelectVendorModal}
      sparePartId={this.state.showSelectVendorModalForSparePartId}
      createPurchaseOrderForVendorId={this.state.createPurchaseOrderForVendorId}
      onSelectVendor={id => {
        this.props.setVendor(id);
        this.changeQueryParams({ vendor_id: id, show_selected: null });
        this.setState({ showSelectVendorModal: false });
      }}
      onClose={() => this.setState({ showSelectVendorModal: false })}
    />
  );

  renderCreatePurchaseOrderForVendorModal = () => {
    return (
      <CreatePurchaseOrderForVendorModal
        open={this.state.showCreatePurchaseOrderForVendorModal}
        onClose={() => this.setState({ showCreatePurchaseOrderForVendorModal: false })}
      />
    );
  };

  renderStartPurchaseOrderForVendorModal = () => {
    const { vendor_id } = queryString.parse(this.props.location.search);
    return (
      <StartPurchaseOrderForVendorModal
        open={this.state.showStartPurchaseOrderForVendorModal}
        vendorId={vendor_id}
        onSave={() => {
          this.changeQueryParams({
            show_selected: null,
          });
          this.props.setVendor(vendor_id);
          this.setState({ showStartPurchaseOrderForVendorModal: false });
        }}
        onClose={() => {
          this.setState({ showStartPurchaseOrderForVendorModal: false });
        }}
      />
    );
  };

  renderCancelPurchaseOrderForVendorModal = () => {
    return (
      <CancelPurchaseOrderForVendorModal
        open={this.state.showCancelPurchaseOrderForVendorModal}
        onSave={() => {
          this.props.cancelPurchaseOrder();
          this.setState({ showCancelPurchaseOrderForVendorModal: false });
        }}
        onClose={() => {
          this.setState({ showCancelPurchaseOrderForVendorModal: false });
        }}
      />
    );
  };

  renderBreadcrumb = () => {
    return (
      <Header.Breadcrumb>
        <Header.BreadcrumbItem to={{ pathname: '/spare-parts' }}>
          <FormattedMessage id="screens.spare-parts.breadcrumb-root" />
        </Header.BreadcrumbItem>
        <Header.BreadcrumbItem>
          <FormattedMessage id="screens.should-order-spare-parts.breadcrumb-root" />
        </Header.BreadcrumbItem>
      </Header.Breadcrumb>
    );
  };

  renderView = () => {
    if (this.state.isFetchingDataForView) {
      return <Loader centerInParent />;
    }
    if (this.state.isEmpty) {
      return this.renderEmptyDataset();
    }
    return (
      <div className={styles['wrapper']}>
        <div className={styles['filter-bar']}>{this.renderFilterButtons()}</div>
        <div className={styles['container']}>
          {this.renderLeftContainer()}
          {this.renderMiddleContainer()}
        </div>
      </div>
    );
  };

  renderStorageLocationFilterButton = () => {
    const { spare_part_location_with_children_id } = queryString.parse(this.props.location.search);
    return (
      <FilterButton
        label={<FormattedMessage id="resources.spare-part.location" />}
        onClick={() => this.setState({ showSelectSparePartLocationModal: true })}
        filtered={spare_part_location_with_children_id != null}
        onClear={e => {
          e.stopPropagation();
          this.changeQueryParams({ spare_part_location_with_children_id: null });
        }}
      />
    );
  };

  renderSparePartTypeFilterButton = () => {
    const { spare_part_type_with_children_id } = queryString.parse(this.props.location.search);
    return (
      <SparePartTypeInlineModal
        minWidth={280}
        trigger={
          <FilterButton
            label={<FormattedMessage id="resources.spare-part.category" />}
            filtered={spare_part_type_with_children_id != null}
            onClear={e => {
              e.stopPropagation();
              this.changeQueryParams({ spare_part_type_with_children_id: null });
            }}
          />
        }
        selectedSparePartTypeId={spare_part_type_with_children_id}
        onSelectSparePartType={id => {
          this.changeQueryParams({ spare_part_type_with_children_id: id });
        }}
        onClear={() => this.changeQueryParams({ spare_part_type_with_children_id: null })}
      />
    );
  };

  renderSelectSparePartLocationModal = () => {
    const { filteredSparePartLocation } = this.props;
    return (
      <SelectSparePartLocationModal
        hideSparePartCount
        selectedSparePartLocationId={filteredSparePartLocation ? filteredSparePartLocation.id : null}
        defaultSparePartLocationId={
          filteredSparePartLocation ? filteredSparePartLocation.spare_part_location_parent_id : null
        }
        open={this.state.showSelectSparePartLocationModal}
        onSelectLocation={spare_part_location_with_children_id => {
          this.changeQueryParams({ spare_part_location_with_children_id });
          this.setState({ showSelectSparePartLocationModal: false });
        }}
        onClose={() => {
          this.setState({ showSelectSparePartLocationModal: false });
        }}
      />
    );
  };

  renderFilterButtons = () => {
    return (
      <FilterButton.Group>
        {this.renderStorageLocationFilterButton()}
        {this.renderSparePartTypeFilterButton()}
      </FilterButton.Group>
    );
  };

  render() {
    return (
      <>
        <div className={styles['should-order-container']}>
          <Header
            noMaxWidth
            smallTitle
            title={<FormattedMessage id="screens.should-order-spare-parts.title" />}
            extraComponent={
              <div className={styles['header-subtitle']}>
                <FormattedMessage id="screens.should-order-spare-parts.subtitle" />
              </div>
            }
            breadcrumbComponent={this.renderBreadcrumb()}
          />
          {this.renderView()}
        </div>
        {this.renderSelectVendorModal()}
        {this.renderStartPurchaseOrderForVendorModal()}
        {this.renderCreatePurchaseOrderForVendorModal()}
        {this.renderCancelPurchaseOrderForVendorModal()}
        {this.renderSelectSparePartLocationModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
      updateEntities: EntityOperations.updateEntities,
      addMultipleSpareParts: ShouldOrderSparePartsOperations.addMultipleSpareParts,
      removeMultipleSpareParts: ShouldOrderSparePartsOperations.removeMultipleSpareParts,
      setVendor: ShouldOrderSparePartsOperations.setVendor,
      resetState: ShouldOrderSparePartsOperations.resetState,
      cancelPurchaseOrder: ShouldOrderSparePartsOperations.cancelPurchaseOrder,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { spare_part_location_with_children_id } = queryString.parse(ownProps.location.search);
  let filteredSparePartLocation = null;
  if (spare_part_location_with_children_id) {
    filteredSparePartLocation = EntitySelectors.getSparePartLocation(
      state,
      spare_part_location_with_children_id
    );
  }
  return {
    filteredSparePartLocation,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    selectedVendor: ShouldOrderSparePartSelectors.getSelectedVendor(state),
    purchaseOrderRows: ShouldOrderSparePartSelectors.getPurchaseOrderRows(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShouldOrderSpareParts)));
