export const AttachmentType = {
  WorkOrderRegistration: 'work_order_registration',
  WorkOrderCompletion: 'work_order_completion',
  RecurringMaintenance: 'recurring_maintenance',
  Vendor: 'vendor',
  SparePart: 'spare_part',
  Asset: 'asset',
  Draft: 'draft',
};

//500MB
export const MaxUploadSize = 500000000;
