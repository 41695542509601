import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Button,
  SectionHeader,
  ConfirmModal,
  Icon,
  Datapill,
  NewInlineModal,
} from 'views/components/Shared/General';
import { AssetTitle } from 'views/components/Asset';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import styles from './style.module.scss';
import { SDKReduxOperations, API } from 'sdk';
import { normalizeOperationalMaintenanceTemplateAsset } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { IntervalModal } from 'views/components/OperationalMaintenance';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import TemplateAssetRecurrenceVersionListItem from './TemplateAssetRecurrenceVersionListItem';
import RemoveIntervalConfirmModal from './RemoveIntervalConfirmModal';

class SettingsForTemplateRecurrence extends Component {
  inlineModalPositioningRef = null;

  state = {
    showEditRecurrenceModal: false,
    showRemoveIntervalConfirmModal: false,
    isCreatingRecurrenceVersion: false,
    dropdownOpen: false,
    showConfirmDeleteModal: false,
    isDeletingRecurrence: false,
  };

  deleteTemplateRecurrence = () => {
    this.props
      .deleteTemplateRecurrence(this.props.operationalMaintenanceTemplateRecurrenceId, {
        operational_maintenance_template_asset_id: this.props.operationalMaintenanceTemplateAsset.id,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-interval-confirm-modal.delete-success" />
            }
          />
        );
        this.setState({ isDeletingRecurrence: false, showConfirmDeleteModal: false });
        this.props.onCancel();
      });
  };

  saveRecurringInformation = intervalData => {
    this.setState({ isCreatingRecurrenceVersion: true });
    this.props
      .createOperationalMaintenanceTemplateVersionForRecurrence(
        this.props.operationalMaintenanceTemplateRecurrenceId,
        intervalData
      )
      .then(() => {
        API.fetchOperationalMaintenanceTemplateAsset(this.props.operationalMaintenanceTemplateAsset.id).then(
          ({ data }) => {
            const { entities } = normalizeOperationalMaintenanceTemplateAsset(data);
            this.props.updateEntities(entities);
            this.setState({ isCreatingRecurrenceVersion: false, showEditRecurrenceModal: false });
            toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          }
        );
      })
      .catch(() => {
        this.setState({ isCreatingRecurrenceVersion: false });
      });
  };

  getActiveRecurrenceVersion = () => {
    const today = moment().tz(this.props.system.timezone);

    return this.props.operationalMaintenanceTemplateRecurrenceVersions.find(version => {
      const from_date =
        version.generated_from_date == null || moment(version.generated_from_date).isSameOrBefore(today);

      const to_date =
        version.generated_to_date == null || moment(version.generated_to_date).isSameOrAfter(today);

      return from_date && to_date;
    });
  };

  renderRemoveIntervalButton = () => {
    const { to_date } = this.getActiveRecurrenceVersion();
    if (to_date == null) {
      return (
        <Button
          gray
          label="screens.operational-maintenance-template.settings.asset.end-interval"
          onClick={() => this.setState({ showRemoveIntervalConfirmModal: true })}
        />
      );
    }
    return null;
  };

  renderButtons = () => {
    if (this.props.canAdministrateOperationalMaintenances && this.getActiveRecurrenceVersion()) {
      return (
        <div className={styles['buttons-container']}>
          <Button.Group>
            <Button
              onClick={() => this.setState({ showEditRecurrenceModal: true })}
              gray
              label="screens.operational-maintenance-template.settings.asset.change-interval"
            />
            {this.renderRemoveIntervalButton()}
          </Button.Group>
        </div>
      );
    }
    return null;
  };

  renderRecurrences = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={25}>
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.versions" />
        </SectionHeader>
        {this.props.operationalMaintenanceTemplateRecurrenceVersions
          .sort((a, b) => new Date(b.from_date) - new Date(a.from_date))
          .map(({ id }) => (
            <TemplateAssetRecurrenceVersionListItem id={id} key={id} />
          ))}
      </>
    );
  };

  renderEndDateDatapill = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion.to_date) {
      if (moment(activeVersion.to_date).isBefore(moment())) {
        return (
          <Datapill
            red
            value={
              <FormattedMessage id="screens.operational-maintenance-template.settings.asset.interval-ended" />
            }
          />
        );
      }
      return (
        <Datapill
          red
          label={
            <FormattedMessage id="screens.operational-maintenance-template.settings.asset.interval-ends" />
          }
          value={moment(activeVersion.to_date).format('LL')}
        />
      );
    }
    return null;
  };

  renderTimeOfDayDatapill = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion.time_of_day) {
      return <Datapill value={moment(activeVersion.time_of_day, 'hh:mm').format('LT')} />;
    }
    return null;
  };

  renderDataPills = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion == null) {
      return null;
    }
    const { to_date, time_of_day } = activeVersion;
    if (to_date || time_of_day) {
      return (
        <div className={styles['data-pills-container']}>
          <Datapill.List>
            {this.renderTimeOfDayDatapill()}
            {this.renderEndDateDatapill()}
          </Datapill.List>
        </div>
      );
    }
    return null;
  };

  renderMoreOptionsDropdown = () => {
    if (this.props.canAdministrateOperationalMaintenances === false) {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={150}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={() => {
                  this.setState({ dropdownOpen: false, showConfirmDeleteModal: true });
                }}
              >
                <FormattedMessage id="general.delete" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderIntervalMessage = () => {
    const activeVersion = this.getActiveRecurrenceVersion();
    if (activeVersion) {
      return buildIntervalMessage(activeVersion, this.props.intl);
    }
    return <FormattedMessage id="screens.operational-maintenance-template.settings.asset.no-recurrence" />;
  };

  renderRecurrence = () => {
    return (
      <div className={styles['asset']}>
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <div>{this.renderIntervalMessage()}</div>
            <div className={styles['subtitle']}>
              <AssetTitle id={this.props.asset.id} />
            </div>
          </div>
        </div>
        <div>{this.renderMoreOptionsDropdown()}</div>
      </div>
    );
  };

  renderGoBackButton = () => {
    return (
      <div className={styles['go-back-container']}>
        <Button gray label="general.go-back" onClick={this.props.onCancel} />
      </div>
    );
  };

  renderIntervalModal = () => {
    if (this.getActiveRecurrenceVersion() == null) {
      return null;
    }
    const {
      from_date,
      to_date,
      interval,
      frequency,
      month_type,
      time_of_day,
      mon,
      tue,
      wed,
      thu,
      fri,
      sat,
      sun,
    } = this.getActiveRecurrenceVersion();
    return (
      <IntervalModal
        open={this.state.showEditRecurrenceModal}
        intervalData={{
          start_date: from_date,
          to_date,
          interval,
          time_of_day,
          frequency,
          month_type,
          mon: mon || false,
          tue: tue || false,
          wed: wed || false,
          thu: thu || false,
          fri: fri || false,
          sat: sat || false,
          sun: sun || false,
        }}
        isSaving={this.state.isCreatingRecurrenceVersion}
        onSave={this.saveRecurringInformation}
        onClose={() => this.setState({ showEditRecurrenceModal: false })}
      />
    );
  };

  renderRemoveIntervalConfirmModal = () => {
    if (this.getActiveRecurrenceVersion() == null) {
      return null;
    }
    return (
      <RemoveIntervalConfirmModal
        open={this.state.showRemoveIntervalConfirmModal}
        recurrenceVersionId={this.getActiveRecurrenceVersion().id}
        onClose={() => {
          this.setState({ showRemoveIntervalConfirmModal: false });
        }}
      />
    );
  };

  renderDeleteRecurrenceVersionModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-interval-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.delete-interval-confirm-modal.subtitle" />
        }
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingRecurrence}
        onConfirm={() => {
          this.setState({ isDeletingRecurrence: true });
          this.deleteTemplateRecurrence();
        }}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderGoBackButton()}
        {this.renderRecurrence()}
        {this.renderDataPills()}
        {this.renderButtons()}
        {this.renderRecurrences()}
        {this.renderIntervalModal()}
        {this.renderRemoveIntervalConfirmModal()}
        {this.renderDeleteRecurrenceVersionModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      createOperationalMaintenanceTemplateVersionForRecurrence:
        SDKReduxOperations.createOperationalMaintenanceTemplateVersionForRecurrence,
      deleteTemplateRecurrence: SDKReduxOperations.deleteTemplateRecurrence,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceTemplateRecurrenceId } = ownProps;
  const operationalMaintenanceTemplateRecurrence =
    EntitySelectors.getOperationalMaintenanceTemplateRecurrence(
      state,
      operationalMaintenanceTemplateRecurrenceId
    );
  const operationalMaintenanceTemplateAsset = EntitySelectors.getOperationalMaintenanceTemplateAsset(
    state,
    operationalMaintenanceTemplateRecurrence.operational_maintenance_template_asset_id
  );
  return {
    operationalMaintenanceTemplateAsset,
    operationalMaintenanceTemplateRecurrence,
    operationalMaintenanceTemplateRecurrenceVersions:
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersions(
        state,
        operationalMaintenanceTemplateRecurrence.operational_maintenance_template_recurrence_versions
      ),
    asset: EntitySelectors.getAsset(state, operationalMaintenanceTemplateAsset.asset_id),
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsForTemplateRecurrence));
