import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NotificationsSelectors } from 'state/ducks/notifications';
import { Loader, Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ExportLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newNotifications.length < this.props.newNotifications.length) {
      const exportNotification = this.props.newNotifications.find(
        ({ export_id }) => export_id === this.props.exportId
      );

      if (exportNotification != null) {
        window.open(`${process.env.REACT_APP_BROWSER_URL}exports/${exportNotification.export_id}`, '_blank');

        this.setState({
          notification: exportNotification,
        });
      }
    }
  }

  render() {
    if (this.state.notification == null) {
      return (
        <div className={styles['export-loader']}>
          <div className={styles['loader-container']}>
            <Loader small />
          </div>
          <div className={styles['text']}>
            <FormattedMessage id="components.export-loader.notification-text" />
          </div>
        </div>
      );
    }
    return (
      <>
        <div className={styles['export-finished']}>
          <span>{this.state.notification.export.title}</span>
        </div>
        <Button
          primary
          label="components.export-loader.open-file"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BROWSER_URL}exports/${this.state.notification.export_id}`,
              '_blank'
            );
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    newNotifications: NotificationsSelectors.getNewNotifications(state),
  };
}

export default connect(mapStateToProps)(ExportLoader);
