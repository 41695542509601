import React, { Component } from 'react';
import { connect } from 'react-redux';
import Decimal from 'decimal.js';
import AnimateHeight from 'react-animate-height';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Button, FieldErrorWrapper, Icon, Toggle } from 'views/components/Shared/General';
import { Title } from 'views/components/SparePart';
import { Grid } from 'views/components/Shared/Layout';
import { SideModal } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

class NewVendorModal extends Component {
  initialState = () => ({
    isSaving: false,
    isSavingWithCreateNew: false,
    hasUnsavedChanges: false,
    editingVendor: this.buildEmptyEditingVendor(),
    editingVendorBeforeChange: this.buildEmptyEditingVendor(),
    showNameError: false,
    showPackageQuantity: false,
    showPackageQuantityBiggerThanZeroError: false,
    primaryVendor: true,
    spare_part_vendor: {
      number: null,
      title: null,
      purchase_price: null,
    },
    asset_vendor: {
      comment: null,
    },
  });

  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.initialState());
    }

    if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  buildEmptyEditingVendorFromSparePart = () => ({
    ...this.state.editingVendor,
    spare_part_vendors: [
      {
        spare_part_id: this.props.sparePart.id,
        number: this.state.spare_part_vendor.number,
        purchase_price: this.state.spare_part_vendor.purchase_price,
        title: this.state.spare_part_vendor.title,
        package_quantity: this.state.spare_part_vendor.package_quantity,
      },
    ],
  });

  buildEmptyEditingVendorFromAsset = () => ({
    ...this.state.editingVendor,
    asset_vendors: [
      {
        asset_id: this.props.asset.id,
        comment: this.state.asset_vendor.comment,
      },
    ],
  });

  buildEmptyEditingVendor = () => ({
    name: '',
    organization_number: '',
    phone: '',
    website: '',
    email: '',
    purchase_order_email: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: '',
    create_purchase_orders: true,
    assign_work_orders: true,
  });

  save = reopenAfterSave => {
    if (this.state.isSaving || this.state.isSavingWithCreateNew) return;
    var editingVendor = null;
    if (this.props.createForSparePartId) {
      editingVendor = this.buildEmptyEditingVendorFromSparePart();
    } else if (this.props.createForAssetId) {
      editingVendor = this.buildEmptyEditingVendorFromAsset();
    } else editingVendor = this.state.editingVendor;
    if (!this.validateSave(editingVendor)) return null;
    if (reopenAfterSave) {
      this.setState({ isSavingWithCreateNew: true, hasUnsavedChanges: false });
    } else {
      this.setState({ isSaving: true, hasUnsavedChanges: false });
    }

    this.props.createVendor(this.props.currentSystem.id, editingVendor).then(({ data: vendor }) => {
      if (this.props.createForSparePartId && this.state.primaryVendor) {
        this.props
          .updateSparePart(this.props.createForSparePartId, { primary_vendor_id: vendor.id })
          .then(() => {
            if (reopenAfterSave) {
              this.props.onCreatedWithReopen(vendor);
            } else {
              this.props.onCreated(vendor);
            }
          });
      } else {
        if (reopenAfterSave) {
          this.props.onCreatedWithReopen(vendor);
        } else {
          this.props.onCreated(vendor);
        }
      }
    });
  };

  validateSave = params => {
    let stateErrors = {};
    if (!params.name) {
      stateErrors = {
        ...stateErrors,
        showNameError: true,
      };
    }
    if (
      this.state.showPackageQuantity &&
      new Decimal(this.state.spare_part_vendor.package_quantity || 0).lessThanOrEqualTo(new Decimal(1))
    ) {
      stateErrors = {
        ...stateErrors,
        showPackageQuantityBiggerThanZeroError: true,
      };
    }
    this.setState({
      ...stateErrors,
    });
    return Object.keys(stateErrors).length === 0;
  };

  setVendorValue = values => {
    const editingVendor = { ...this.state.editingVendor, ...values };

    const hasUnsavedChanges = !isEqual(this.state.editingVendorBeforeChange, editingVendor);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      editingVendor,
      hasUnsavedChanges,
    });
  };

  renderImage = () => {
    if (this.props.sparePart.images[0] == null) {
      return (
        <div className={styles['image-container']}>
          <Icon type="image" />
        </div>
      );
    }
    return (
      <div
        className={styles['image-container']}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BROWSER_URL}images/${this.props.sparePart.images[0]})`,
        }}
      />
    );
  };

  renderAssetFields = () => {
    if (!this.props.createForAssetId) return null;
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['title-field']}>
              <p className={styles['title']}>{this.props.asset.title}</p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.asset-vendor.comment" />}>
              <Field.Textarea
                value={this.state.asset_vendor.comment}
                onChange={comment => {
                  this.setState({
                    asset_vendor: {
                      ...this.state.asset_vendor,
                      comment,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderSparePartFields = () => {
    if (!this.props.createForSparePartId) return null;
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['title-field']}>
              {this.renderImage()}
              <div className={styles['title']}>
                <Title sparePart={this.props.sparePart} />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="components.new-vendor-modal.spare-part-article-number" />}>
              <Field.Text
                value={this.state.spare_part_vendor.number}
                onChange={number => {
                  this.setState({
                    spare_part_vendor: {
                      ...this.state.spare_part_vendor,
                      number,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="components.new-vendor-modal.spare-part-title" />}>
              <Field.Text
                value={this.state.spare_part_vendor.title}
                onChange={title => {
                  this.setState({
                    spare_part_vendor: {
                      ...this.state.spare_part_vendor,
                      title,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="components.new-vendor-modal.spare-part-purchase-price" />}>
              <Field.Money
                currency={this.props.currentSystem.currency}
                value={this.state.spare_part_vendor.purchase_price}
                onBlur={purchase_price => {
                  this.setState({
                    spare_part_vendor: {
                      ...this.state.spare_part_vendor,
                      purchase_price,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              questionTooltipContent={
                <FormattedMessage id="resources.spare-part-vendor.package-quantity-description" />
              }
              checked={this.state.showPackageQuantity}
              onChange={() => {
                this.setState(prevState => ({
                  showPackageQuantity: !prevState.showPackageQuantity,
                }));
              }}
              label={<FormattedMessage id="resources.spare-part-vendor.package-quantity" />}
            />
            <AnimateHeight
              duration={250}
              height={this.state.showPackageQuantity ? 'auto' : 0}
              onAnimationEnd={() => {
                if (this.state.showPackageQuantity) {
                  this.packageField.focus();
                }
              }}
            >
              <div className={styles['package-field']}>
                <FieldErrorWrapper
                  position="top"
                  show={this.state.showPackageQuantityBiggerThanZeroError}
                  errorElement={
                    <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.errors.package-bigger-than-zero" />
                  }
                >
                  <Field.Decimal
                    error={this.state.showPackageQuantityBiggerThanZeroError}
                    ref={ref => (this.packageField = ref)}
                    rightLabel={
                      this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation
                    }
                    value={this.state.spare_part_vendor.package_quantity}
                    onChange={package_quantity => {
                      if (this.state.showPackageQuantityBiggerThanZeroError) {
                        this.setState({ showPackageQuantityBiggerThanZeroError: false });
                      }
                      this.setState({
                        spare_part_vendor: {
                          ...this.state.spare_part_vendor,
                          package_quantity,
                        },
                      });
                    }}
                    onBlur={package_quantity => {
                      this.setState({
                        spare_part_vendor: {
                          ...this.state.spare_part_vendor,
                          package_quantity,
                        },
                      });
                    }}
                  />
                </FieldErrorWrapper>
              </div>
            </AnimateHeight>
            <Field.Checkbox
              questionTooltipContent={
                <FormattedMessage id="resources.spare-part.primary-vendor-description" />
              }
              checked={this.state.primaryVendor}
              onChange={() =>
                this.setState(prevState => ({
                  primaryVendor: !prevState.primaryVendor,
                }))
              }
              label={<FormattedMessage id="resources.spare-part.primary-vendor" />}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
      </>
    );
  };

  renderFooter = () => (
    <Button.Group>
      <Button primary label="general.save" loading={this.state.isSaving} onClick={() => this.save()} />
      <Button
        label="general.save-and-create-new"
        loading={this.state.isSavingWithCreateNew}
        onClick={() => this.save(true)}
      />
      <Button label="general.cancel" onClick={this.props.onClose} />
    </Button.Group>
  );

  renderContent = () => (
    <Grid>
      {this.renderAssetFields()}
      {this.renderSparePartFields()}
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.name" />}>
            <FieldErrorWrapper
              position="top"
              show={this.state.showNameError}
              errorElement={<FormattedMessage id="general.errors.is-required" />}
            >
              <Field.Text
                autoFocus
                error={this.state.showNameError}
                value={this.state.editingVendor.name}
                onChange={name => {
                  if (this.state.showNameError && name.length > 0) {
                    this.setState({ showNameError: false });
                  }
                  this.setVendorValue({ name });
                }}
              />
            </FieldErrorWrapper>
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.organization-number" />}>
            <Field.Text
              value={this.state.editingVendor.organization_number}
              onChange={organization_number => {
                this.setVendorValue({ organization_number });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div className={styles['type-options']}>
            <div
              className={`${styles['type']} ${
                !this.state.editingVendor.assign_work_orders ? styles['inactive'] : ''
              }`}
            >
              <div className={styles['icon']}>
                <Icon type="wrench" withBackground backgroundSize={34} blue />
              </div>
              <div className={styles['text']}>
                <FormattedMessage id="resources.vendor.types.assign-work-orders" />
              </div>
              <div className={styles['checkbox']}>
                <Toggle
                  checked={this.state.editingVendor.assign_work_orders}
                  onClick={() =>
                    this.setVendorValue({ assign_work_orders: !this.state.editingVendor.assign_work_orders })
                  }
                />
              </div>
            </div>
            {this.props.settings.spare_parts_activated ? (
              <div
                className={`${styles['type']} ${
                  !this.state.editingVendor.create_purchase_orders ? styles['inactive'] : ''
                }`}
              >
                <div className={styles['icon']}>
                  <Icon type="file-invoice-dollar" withBackground orange backgroundSize={34} />
                </div>
                <div className={styles['text']}>
                  <FormattedMessage id="resources.vendor.types.create-purchase-orders" />
                </div>
                <div className={styles['checkbox']}>
                  <Toggle
                    checked={this.state.editingVendor.create_purchase_orders}
                    onClick={() =>
                      this.setVendorValue({
                        create_purchase_orders: !this.state.editingVendor.create_purchase_orders,
                      })
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Separator />
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.phone" />}>
            <Field.Text
              type="tel"
              value={this.state.editingVendor.phone}
              onChange={phone => {
                this.setVendorValue({ phone });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.website" />}>
            <Field.Text
              type="url"
              value={this.state.editingVendor.website}
              onChange={website => {
                this.setVendorValue({ website });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.email" />}>
            <Field.Text
              type="email"
              value={this.state.editingVendor.email}
              onChange={email => {
                this.setVendorValue({ email });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Separator />
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.address1" />}>
            <Field.Text
              value={this.state.editingVendor.address1}
              onChange={address1 => {
                this.setVendorValue({ address1 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.address2" />}>
            <Field.Text
              value={this.state.editingVendor.address2}
              onChange={address2 => {
                this.setVendorValue({ address2 });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column md={4}>
          <Field label={<FormattedMessage id="resources.vendor.zip" />}>
            <Field.Text
              value={this.state.editingVendor.zip}
              onChange={zip => {
                this.setVendorValue({ zip });
              }}
            />
          </Field>
        </Grid.Column>
        <Grid.Column md={8}>
          <Field label={<FormattedMessage id="resources.vendor.city" />}>
            <Field.Text
              value={this.state.editingVendor.city}
              onChange={city => {
                this.setVendorValue({ city });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.vendor.country" />}>
            <Field.Text
              value={this.state.editingVendor.country}
              onChange={country => {
                this.setVendorValue({ country });
              }}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  renderSubtitle = () => {
    if (this.props.createForSparePartId) {
      return (
        <FormattedMessage
          id="components.new-vendor-modal.add-spare-part-subtitle"
          values={{
            sparePart: (
              <span className={styles['highlight-text']}>
                <Title inline sparePart={this.props.sparePart} />
              </span>
            ),
          }}
        />
      );
    } else if (this.props.createForAssetId) {
      return (
        <FormattedMessage
          id="components.new-vendor-modal.add-asset-subtitle"
          values={{
            asset: <span className={styles['highlight-text']}>{this.props.asset.title}</span>,
          }}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <SideModal
        open={this.props.open}
        footerComponent={this.renderFooter()}
        onClose={() => {
          if (this.state.hasUnsavedChanges) {
            const confirmed = window.confirm(
              this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
            );
            if (confirmed) {
              this.props.onClose();
            }
          } else {
            this.props.onClose();
          }
        }}
      >
        <SideModal.Container>
          <SideModal.Container.Content>
            <SideModal.Header
              title={<FormattedMessage id="components.new-vendor-modal.title" />}
              subtitle={this.renderSubtitle()}
              onClose={this.props.onClose}
            />
            {this.renderContent()}
          </SideModal.Container.Content>
        </SideModal.Container>
      </SideModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createVendor: SDKReduxOperations.createVendor,
      updateSparePart: SDKReduxOperations.updateSparePart,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  let sparePart = EntitySelectors.getSparePart(state, ownProps.createForSparePartId);
  let sparePartUnit = null;
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  }
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    sparePart,
    sparePartUnit,
    asset: EntitySelectors.getAsset(state, ownProps.createForAssetId),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewVendorModal));

NewVendorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onCreatedWithReopen: PropTypes.func.isRequired,
  title: PropTypes.element,
  footerComponent: PropTypes.element,
};
