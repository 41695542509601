import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import { InlineModal, WarningMessage, Button, Field, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { ToastMessage } from 'views/components/Shared/Layout';

class OptionsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showDeleteInlineModalContent: false,
      isDeleting: false,
      confirmInput: '',
    };
  }

  deleteAttachmentVersion = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteAttachmentVersion(this.props.attachmentVersion.id)
      .then(() => {
        this.setState({ isDeleting: false, isOpen: false });
        this.props.onDelete(this.props.attachmentVersion);
        toast(
          <ToastMessage success text={<FormattedMessage id="components.file-history.delete-success" />} />
        );
      })
      .catch(() => {
        this.setState({ isDeleting: false });
      });
  };

  renderOptions = () => (
    <InlineModal.Body width={250} dropdown>
      <InlineModal.ListItem
        icon={<Icon regular type="download" />}
        title={<FormattedMessage id="general.download" />}
        onClick={() => {
          HelperFunctions.openAttachment(this.props.attachmentVersion);
        }}
      />
      <InlineModal.ListItem
        icon="trash-alt"
        iconThickness="regular"
        iconColor="#C55050"
        destructive
        title={<FormattedMessage id="general.delete" />}
        onClick={() => {
          this.setState({
            showDeleteInlineModalContent: true,
          });
        }}
      />
    </InlineModal.Body>
  );

  renderDeleteContent = () => {
    if (this.props.attachmentVersion.work_order_count === 0) {
      return (
        <InlineModal.Delete
          loading={this.state.isDeleting}
          onDelete={() => this.deleteAttachmentVersion(this.props.attachmentVersion)}
        >
          <FormattedMessage id="components.file-history.delete.description" />
        </InlineModal.Delete>
      );
    }

    let label =
      this.props.attachmentVersion.work_order_count > 1
        ? 'components.attachment-options-inline-modal.options.delete.confirm-description-texts.work-orders-files-context-count-other'
        : 'components.attachment-options-inline-modal.options.delete.confirm-description-texts.work-orders-files-context-count-one';
    return (
      <div className={styles['delete-wrapper']}>
        <WarningMessage hideIcon type="destructive">
          <FormattedMessage
            id={label}
            values={{ work_order_count: this.props.attachmentVersion.work_order_count }}
          />
        </WarningMessage>
        <Field.Text
          placeholder={this.props.intl.formatMessage({ id: 'general.delete' })}
          value={this.state.confirmInput}
          onChange={value => {
            this.setState({ confirmInput: value });
          }}
        />
        <div className={styles['confirm-button-container']}>
          <Button
            primary
            destructive
            disabled={
              this.state.confirmInput.toLowerCase() !==
              this.props.intl.formatMessage({ id: 'general.delete' }).toLowerCase()
            }
            label="general.delete"
            onClick={() => this.deleteAttachmentVersion(this.props.attachmentVersion)}
            loading={this.state.isDeleting}
          />
        </div>
      </div>
    );
  };

  renderDelete = () => (
    <React.Fragment>
      <InlineModal.Header
        title={<FormattedMessage id="components.file-history.delete.title" />}
        onClose={() => this.setState({ isOpen: false })}
      />
      <InlineModal.Body width={300}>{this.renderDeleteContent()}</InlineModal.Body>
    </React.Fragment>
  );

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              showDeleteInlineModalContent: false,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <React.Fragment>
            {this.state.showDeleteInlineModalContent ? this.renderDelete() : this.renderOptions()}
          </React.Fragment>
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAttachmentVersion: SDKReduxOperations.deleteAttachmentVersion,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(OptionsInlineModal));
