import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Decimal } from 'decimal.js';
import { EntitySelectors } from 'sdk/State/entities';
import { InlineModal } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { UserNameWrapper } from 'views/components/User';

const EarlierDeliveryRow = ({
  purchaseOrderDeliveryRow,
  purchaseOrderDelivery,
  sparePartUnit,
  isPackage,
  createdByUser,
}) => (
  <InlineModal.ListItem
    clickable={false}
    title={moment(purchaseOrderDelivery.created_at).format('LLL')}
    subtitle={
      <FormattedMessage
        id="components.create-delivery-modal.by-user"
        values={{ user: <UserNameWrapper user={createdByUser} /> }}
      />
    }
    rightContainer={
      <SparePartUnitWithValue
        value={new Decimal(purchaseOrderDeliveryRow.quantity).toString()}
        isPackage={isPackage}
        sparePartUnit={sparePartUnit}
      />
    }
  />
);

function mapStateToProps(state, ownProps) {
  return {
    createdByUser: EntitySelectors.getUser(state, ownProps.purchaseOrderDeliveryRow.created_by_user_id),
    purchaseOrderDelivery: EntitySelectors.getPurchaseOrderDelivery(
      state,
      ownProps.purchaseOrderDeliveryRow.purchase_order_delivery_id
    ),
  };
}

export default connect(mapStateToProps)(EarlierDeliveryRow);
