import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { ChecklistFormSelectors, ChecklistFormOperations } from 'state/ducks/checklistForm';
import { EntitySelectors } from 'sdk/State/entities';
import { WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import Rows from './Rows';

class ChecklistVersionForm extends Component {
  componentDidMount() {
    this.props.setChecklistTemplateVersion(this.props.id);
  }

  render() {
    if (this.props.rootRows.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="screens.checklist.no-rows-empty-data-set.title" />}
            subtitle={<FormattedMessage id="screens.checklist.no-rows-empty-data-set.subtitle" />}
            tiny
            listContainer
          />
        </WhiteCard>
      );
    }
    return <Rows />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChecklistTemplateVersion: ChecklistFormOperations.fetchChecklistTemplateVersion,
      setChecklistTemplateVersion: ChecklistFormOperations.setChecklistTemplateVersion,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(state, ownProps.id),
    rootRows: ChecklistFormSelectors.getRootTemplateFieldIds(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistVersionForm);
