import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { InlineModal } from 'views/components/Shared/General';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import { UserNameWrapper } from 'views/components/User';
import { SDKReduxOperations } from 'sdk';

const WithdrawalListItem = ({ sparePartWithdrawal, createdByUser, sparePartUnit, onClick }) => (
  <InlineModal.ListItem
    key={sparePartWithdrawal.id}
    title={moment(sparePartWithdrawal.created_at).format('LLL')}
    subtitle={
      <FormattedMessage
        id="screens.work-order.used-spare-parts.dropdown-options-inline-modal.amount-by-user"
        values={{
          amount: (
            <SparePartUnitWithValue sparePartUnit={sparePartUnit} value={sparePartWithdrawal.quantity} />
          ),
          user: <UserNameWrapper user={createdByUser} />,
        }}
      />
    }
    onClick={onClick}
  />
);

function mapStateToProps(state, ownProps) {
  return {
    createdByUser: EntitySelectors.getUser(state, ownProps.sparePartWithdrawal.created_by_user_id),
  };
}

export default connect(mapStateToProps)(WithdrawalListItem);
