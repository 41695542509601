import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class ValueList extends Component {
  renderItems = () =>
    this.props.templateFieldItems.map(templateFieldItem => (
      <Field.Dropdown.Item
        selected={this.props.value === templateFieldItem.id}
        onClick={() => this.props.onChange(templateFieldItem.id)}
      >
        {templateFieldItem.title}
      </Field.Dropdown.Item>
    ));

  getSelectedTemplateFieldTitle = () => {
    if (!this.props.selectedTemplateFieldItem) return null;
    return this.props.selectedTemplateFieldItem.title;
  };

  render() {
    return (
      <Field.Dropdown
        clearable
        onClear={() => this.props.onChange(null)}
        value={this.getSelectedTemplateFieldTitle()}
      >
        {this.renderItems()}
      </Field.Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let selectedTemplateFieldItem;
  if (ownProps.value) {
    selectedTemplateFieldItem = EntitySelectors.getTemplateFieldItem(state, ownProps.value);
  }
  return {
    selectedTemplateFieldItem,
    templateFieldItems: EntitySelectors.getTemplateFieldItems(
      state,
      ownProps.templateField.template_field_items
    ),
  };
}

export default connect(mapStateToProps)(ValueList);
