import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default ({ show, loading, onSave, onCancel }) => (
  <div className={`${styles['save-container']} ${show ? styles['show'] : ''}`}>
    <Button.Group>
      <Button primary label="general.save" loading={loading} onClick={onSave} />
      <Button label="general.cancel" onClick={onCancel} />
    </Button.Group>
    <span className={styles['unsaved-changes']}>
      <FormattedMessage id="general.unsaved-changes" />
    </span>
  </div>
);
