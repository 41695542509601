export default {
  RESET_SELECTED_SPARE_PART_WITHDRAWALS: 'spare_part_withdrawals/reset_selected_spare_part_withdrawals',
  TOGGLE_ALL_SELECTED_SPARE_PART_WITHDRAWALS:
    'spare_part_withdrawals/toggle_all_selected_spare_part_withdrawals',
  SELECT_PAGE: 'spare_part_withdrawals/select_page',
  SELECT_SPARE_PART_WITHDRAWAL: 'spare_part_withdrawals/select_spare_part_withdrawal',
  SELECT_TOTAL_ENTRIES: 'spare_part_withdrawals/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'spare_part_withdrawals/hide_select_total_entries',
  FETCH_SPARE_PART_WITHDRAWALS: 'spare_part_withdrawals/fetch_spare_part_withdrawals',
  FETCH_SPARE_PART_WITHDRAWALS_SUCCESS: 'spare_part_withdrawals/fetch_spare_part_withdrawals_success',
  ADD_QUERY_PARAMETER: 'spare_part_withdrawals/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'spare_part_withdrawals/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'spare_part_withdrawals/show_available_filters',
  SELECT_FILTER_TYPE: 'spare_part_withdrawals/select_filter_type',
  ADD_FILTER: 'spare_part_withdrawals/add_filter',
  REMOVE_FILTER: 'spare_part_withdrawals/remove_filter',
  RESET_FILTER: 'spare_part_withdrawals/reset_filter',
};
