export default {
  SET_NAVIGATED_TO: 'spareParts/set_navigated_to',
  RESET_SELECTED_SPARE_PARTS: 'spareParts/reset_selected_spare_parts',
  TOGGLE_ALL_SELECTED_SPARE_PARTS: 'spareParts/toggle_all_selected_spare_parts',
  SELECT_PAGE: 'spareParts/select_page',
  SELECT_SPARE_PART: 'spareParts/select_spare_part',
  SELECT_TOTAL_ENTRIES: 'spareParts/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'spareParts/hide_select_total_entries',
  ADD_QUERY_PARAMETER: 'spareParts/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'spareParts/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'spareParts/show_available_filters',
  SELECT_FILTER_TYPE: 'spareParts/select_filter_type',
  ADD_FILTER: 'spareParts/add_filter',
  REMOVE_FILTER: 'spareParts/remove_filter',
  RESET_FILTER: 'spareParts/reset_filter',
  FETCH_SPARE_PARTS: 'spareParts/fetch_spare_parts',
  FETCH_SPARE_PARTS_SUCCESS: 'spareParts/fetch_spare_parts_success',
};
