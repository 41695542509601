import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeMeter } from 'sdk/Schemas';
import { API, HelperFunctions } from 'sdk';

const setNavigatedTo = actions.setNavigatedTo;
const addQueryParameter = actions.addQueryParameter;
const selectPage = actions.selectPage;
const resetSelectedMeters = actions.resetSelectedMeters;
const selectMeter = actions.selectMeter;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;
const selectTotalEntries = actions.selectTotalEntries;

const listMeters = (systemId, params, config) => dispatch => {
  return API.listMeters(systemId, params, config).then(res => {
    const meters = res.data;
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    const { entities, result } = normalizeMeter(meters);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchMetersSuccess({
        ids: result,
        pagination,
      })
    );
  });
};

export default {
  setNavigatedTo,
  listMeters,
  addQueryParameter,
  selectPage,
  resetSelectedMeters,
  selectMeter,
  hideSelectTotalEntries,
  selectTotalEntries,
};
