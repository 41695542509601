import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SDKReduxOperations } from 'sdk';
import HelperFunctions from 'utilities/HelperFunctions';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AttachmentType } from 'sdk/Attachment';
import { EntitySelectors } from 'sdk/State/entities';
import { InlineModal } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { MoveFolderInlineModalContent } from 'views/components/Attachment';
import EditInfo from './EditInfo';
import Delete from './Delete';
import HistoryModal from './HistoryModal';
import RelatedAssetsModal from './RelatedAssetsModal';
import RelatedSparePartsModal from './RelatedSparePartsModal';
import styles from './style.module.scss';

const DROPDOWN_OPTIONS = {
  DropdownItems: 'dropdownItems',
  MoveFolder: 'movefolder',
  EditInfo: 'editInfo',
  Delete: 'delete',
  History: 'history',
  RelatedAssets: 'relatedAssets',
  RelatedSpareParts: 'relatedSpareParts',
};

class FilesOptionsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangingFolder: false,
      isOpen: false,
      selectedOption: DROPDOWN_OPTIONS.DropdownItems,
    };
  }

  renderOptions = () => {
    const { attachment, context, attachmentVersion } = this.props;
    return (
      <InlineModal.Body width={250} dropdown>
        <div className={styles['options']}>
          {attachmentVersion.type === 'file' ? (
            <InlineModal.ListItem
              icon="download"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.download.title" />
              }
              onClick={() => {
                HelperFunctions.openAttachment(this.props.attachmentVersion);
              }}
            />
          ) : (
            <InlineModal.ListItem
              icon="external-link"
              iconThickness="regular"
              title={<FormattedMessage id="components.attachment-options-inline-modal.options.open.title" />}
              onClick={() => {
                HelperFunctions.openAttachment(this.props.attachmentVersion);
              }}
            />
          )}
          {this.props.isViewOnly === false &&
          context === 'asset' &&
          attachment.type === AttachmentType.Asset &&
          this.props.allAttachmentFolders.length > 0 ? (
            <InlineModal.ListItem
              icon="folder"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.change-folder.title" />
              }
              onClick={() => {
                this.setState({
                  selectedOption: DROPDOWN_OPTIONS.MoveFolder,
                });
              }}
            />
          ) : null}
          {attachment.type === AttachmentType.Asset ? (
            <InlineModal.ListItem
              icon="box"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.show-related-assets.title" />
              }
              onClick={() => {
                this.setState({
                  isOpen: false,
                  selectedOption: DROPDOWN_OPTIONS.RelatedAssets,
                });
              }}
            />
          ) : null}
          {attachment.type === AttachmentType.SparePart ? (
            <InlineModal.ListItem
              icon="cogs"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.show-related-spare-parts.title" />
              }
              onClick={() => {
                this.setState({
                  isOpen: false,
                  selectedOption: DROPDOWN_OPTIONS.RelatedSpareParts,
                });
              }}
            />
          ) : null}
          {context === 'workOrder' ||
          attachmentVersion.version_number === 1 ||
          attachment.type === AttachmentType.WorkOrderRegistration ||
          attachment.type === AttachmentType.WorkOrderCompletion ||
          attachment.type === AttachmentType.Draft ? null : (
            <InlineModal.ListItem
              icon="history"
              iconThickness="regular"
              title={
                <FormattedMessage id="components.attachment-options-inline-modal.options.history.title" />
              }
              onClick={() => {
                this.setState({
                  isOpen: false,
                  selectedOption: DROPDOWN_OPTIONS.History,
                });
              }}
            />
          )}
          {this.props.isViewOnly === false ? (
            <>
              <InlineModal.Separator />
              <InlineModal.ListItem
                icon="pen"
                iconThickness="regular"
                title={<FormattedMessage id="general.edit" />}
                onClick={() => {
                  this.setState({
                    selectedOption: DROPDOWN_OPTIONS.EditInfo,
                  });
                }}
              />
              <InlineModal.ListItem
                icon="trash-alt"
                iconThickness="regular"
                iconColor="#C55050"
                destructive
                title={<FormattedMessage id="general.delete" />}
                onClick={() => {
                  this.setState({
                    selectedOption: DROPDOWN_OPTIONS.Delete,
                  });
                }}
              />
            </>
          ) : null}
        </div>
      </InlineModal.Body>
    );
  };

  renderEditInfo = () => (
    <EditInfo
      context={this.props.context}
      attachment={this.props.attachment}
      attachmentVersion={this.props.attachmentVersion}
      onClose={() => this.setState({ isOpen: false })}
      onBack={() => {
        this.setState({
          selectedOption: DROPDOWN_OPTIONS.DropdownItems,
        });
      }}
    />
  );

  renderDelete = () => (
    <Delete
      context={this.props.context}
      assetToRemoveFromAttachment={this.props.assetToRemoveFromAttachment}
      sparePartToRemoveFromAttachment={this.props.sparePartToRemoveFromAttachment}
      attachment={this.props.attachment}
      attachmentVersion={this.props.attachmentVersion}
      onClose={() => this.setState({ isOpen: false })}
      isRemovingAttachment={this.props.isRemovingAttachment}
      isDeletingAttachment={this.props.isDeletingAttachment}
      onDeleteAttachment={() => this.props.onDeleteAttachment(this.props.attachment)}
      onRemovedFromAsset={() => this.props.onRemovedFromAsset(this.props.attachment)}
      onRemovedFromSparePart={() => this.props.onRemovedFromSparePart(this.props.attachment)}
      onBack={() => {
        this.setState({
          selectedOption: DROPDOWN_OPTIONS.DropdownItems,
        });
      }}
    />
  );

  renderMoveFolder = () => (
    <MoveFolderInlineModalContent
      title={<FormattedMessage id="components.attachment-options-inline-modal.options.change-folder.title" />}
      defaultOpenFolderId={this.props.attachment.attachment_folder_id}
      loading={this.state.isChangingFolder}
      onSelectFolder={folder => {
        this.setState({ isChangingFolder: true });
        this.props
          .updateAttachment(this.props.attachment.id, {
            attachment_folder_id: folder,
          })
          .then(() => {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="components.attachment-options-inline-modal.options.change-folder.save-success" />
                }
              />
            );
            if (this.props.callBackOnMoveAttachmentToFolder) {
              this.props.onMoveAttachmentToFolder(folder);
            }
            this.setState({ isChangingFolder: false, isOpen: false });
          });
      }}
      onClose={() => this.setState({ isOpen: false })}
      onSizeWasChanged={() => this.inlineModalRef.setInlineModalHorizontalPositions()}
    />
  );

  renderContent = () => {
    switch (this.state.selectedOption) {
      case DROPDOWN_OPTIONS.DropdownItems:
        return this.renderOptions();
      case DROPDOWN_OPTIONS.EditInfo:
        return this.renderEditInfo();
      case DROPDOWN_OPTIONS.Delete:
        return this.renderDelete();
      case DROPDOWN_OPTIONS.MoveFolder:
        return this.renderMoveFolder();
      default:
        return null;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          ref={ref => (this.inlineModalPositionRef = ref)}
          onClick={() =>
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              selectedOption: DROPDOWN_OPTIONS.DropdownItems,
            }))
          }
        >
          {this.props.trigger}
        </div>
        <InlineModal
          ref={ref => (this.inlineModalRef = ref)}
          open={this.state.isOpen}
          positionToRef={this.inlineModalPositionRef}
          onClose={() => this.setState({ isOpen: false })}
          position="right"
        >
          <React.Fragment>{this.renderContent()}</React.Fragment>
        </InlineModal>
        <HistoryModal
          isOpen={this.state.selectedOption === DROPDOWN_OPTIONS.History}
          attachment={this.props.attachment}
          onClose={() => {
            this.setState({ selectedOption: DROPDOWN_OPTIONS.DropdownItems });
          }}
        />
        <RelatedAssetsModal
          isOpen={this.state.selectedOption === DROPDOWN_OPTIONS.RelatedAssets}
          attachment={this.props.attachment}
          onClose={() => {
            this.setState({ selectedOption: DROPDOWN_OPTIONS.DropdownItems });
          }}
        />
        <RelatedSparePartsModal
          isOpen={this.state.selectedOption === DROPDOWN_OPTIONS.RelatedSpareParts}
          attachment={this.props.attachment}
          onClose={() => {
            this.setState({ selectedOption: DROPDOWN_OPTIONS.DropdownItems });
          }}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAttachment: SDKReduxOperations.updateAttachment,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    attachmentVersion: EntitySelectors.getAttachmentVersion(state, ownProps.attachment.attachment_version),
    allAttachmentFolders: EntitySelectors.getAttachmentFolders(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FilesOptionsInlineModal));

FilesOptionsInlineModal.propTypes = {
  context: PropTypes.oneOf([
    'asset',
    'vendor',
    'purchaseOrder',
    'workOrder',
    'files',
    'recurringMaintenance',
  ]),
};
FilesOptionsInlineModal.defaultProps = {
  context: null,
};
