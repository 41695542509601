import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const SparePartTypeContainer = ({ sparePartType }) => {
  return sparePartType.title;
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SparePartTypeContainer);
