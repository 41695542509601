import React from 'react';
import { FormattedMessage } from 'react-intl';

const UserNameWrapper = ({ user }) => {
  if (user == null) {
    return <FormattedMessage id="general.removed-user" />;
  }
  return user.name;
};

export default UserNameWrapper;
