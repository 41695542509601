import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { API } from 'sdk';
import { InlineModal, Button } from 'views/components/Shared/General';
import SparePartLocations from './SparePartLocations';
import ParentLocationTitleContainer from './ParentLocationTitleContainer';

class MoveSparePartLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentSparePartLocationId: null,
      selectedSparePartLocationId: null,
      isSaving: false,
    };
  }

  componentDidMount() {
    this.setState({
      parentSparePartLocationId: this.props.sparePartLocation.spare_part_location_parent_id,
    });
  }

  goToParentLocation = () => {
    const currentlyShowingFolderForFolder = this.props.allSparePartLocations.find(
      sparePartLocation => this.state.parentSparePartLocationId == sparePartLocation.id
    );
    this.setState({
      selectedSparePartLocationId: null,
      parentSparePartLocationId: currentlyShowingFolderForFolder.spare_part_location_parent_id,
    });
    this.props.onSizeWasChanged();
  };

  isSaveButtonDisabled = () => {
    const { selectedSparePartLocationId, parentSparePartLocationId } = this.state;
    const { sparePartLocation } = this.props;
    const tryingToMoveToSameParentFolder =
      parentSparePartLocationId == sparePartLocation.spare_part_location_parent_id;
    if (selectedSparePartLocationId) return false;
    if (tryingToMoveToSameParentFolder) return true;
    return false;
  };

  saveSparePartLocation = () => {
    let parentId = null;
    if (this.state.selectedSparePartLocationId) {
      parentId = this.state.selectedSparePartLocationId;
    } else if (this.state.parentSparePartLocationId) {
      parentId = this.state.parentSparePartLocationId;
    }
    this.setState({ isSaving: true });
    API.updateSparePartLocation(this.props.sparePartLocation.id, {
      spare_part_location_parent_id: parentId,
    }).then(({ data: sparePartLocation }) => {
      const { entities } = normalizeSparePartLocation(sparePartLocation);
      this.props.updateEntities(entities);
      toast(
        <ToastMessage
          success
          text={
            <FormattedMessage id="components.move-spare-part-location-inline-modal.move-spare-part-location.save-success" />
          }
        />
      );
      this.props.onClose();
    });
  };

  renderSaveButtonLabel = () => {
    if (this.state.selectedSparePartLocationId) {
      return 'components.move-spare-part-location-inline-modal.move-spare-part-location.move';
    }
    return 'components.folder-options-inline-modal.change-folder.move-here';
  };

  render() {
    return (
      <>
        <InlineModal.Header
          width={300}
          title={
            <ParentLocationTitleContainer parentSparePartLocationId={this.state.parentSparePartLocationId} />
          }
          showBackButton={this.state.parentSparePartLocationId != null}
          onBack={this.goToParentLocation}
          onClose={this.props.onClose}
        />
        <InlineModal.Body width={300} maxHeight={450}>
          <SparePartLocations
            showSparePartLocationsForParentId={this.state.parentSparePartLocationId}
            selectedSparePartLocationId={this.state.selectedSparePartLocationId}
            disableSelectForSparePartLocationId={this.props.sparePartLocation.id}
            onSelectSparePartLocation={({ id }) => {
              if (this.state.selectedSparePartLocationId === id) {
                this.setState({ selectedSparePartLocationId: null });
              } else {
                this.setState({ selectedSparePartLocationId: id });
              }
            }}
            showChildrenForSparePartLocation={({ id }) => {
              this.setState({ parentSparePartLocationId: id, selectedSparePartLocationId: null });
              this.props.onSizeWasChanged();
            }}
          />
          <InlineModal.Separator />
          <InlineModal.Footer right>
            <Button
              primary
              disabled={this.isSaveButtonDisabled()}
              label={this.renderSaveButtonLabel()}
              loading={this.state.isSaving}
              onClick={this.saveSparePartLocation}
            />
          </InlineModal.Footer>
        </InlineModal.Body>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSparePartLocations: EntitySelectors.getSparePartLocations(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveSparePartLocation);
