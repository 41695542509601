import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash-es';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldErrorWrapper, Button } from 'views/components/Shared/General';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      contactPerson: this.props.contactPerson,
      contactPersonBeforeEdit: this.props.contactPerson,
      hasUnsavedChanges: false,
      showNameError: false,
    };
  }

  componentDidMount() {
    if (this.props.new) {
      const contactPerson = {
        name: '',
        position: '',
        phone: '',
        email: '',
      };

      this.setState({
        contactPerson: contactPerson,
        contactPersonBeforeEdit: contactPerson,
      });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.contactPerson && this.props.contactPerson) {
      this.setState({
        contactPerson: this.props.contactPerson,
        contactPersonBeforeEdit: this.props.contactPerson,
      });
    }
  }

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  cancel = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onCancel();
      }
    } else {
      this.props.onCancel();
    }
  };

  setContactPersonValue = values => {
    const contactPerson = { ...this.state.contactPerson, ...values };

    const hasUnsavedChanges = !isEqual(this.state.contactPersonBeforeEdit, contactPerson);

    if (hasUnsavedChanges && !this.state.hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else if (!hasUnsavedChanges && this.state.hasUnsavedChanges) {
      window.onbeforeunload = undefined;
    }

    this.setState({
      contactPerson,
      hasUnsavedChanges,
    });
  };

  getEditingChanges = () => {
    let data = {};
    const contactPerson = this.state.contactPerson;
    const diffProps = Object.keys(contactPerson).filter(
      k => contactPerson[k] !== this.state.contactPersonBeforeEdit[k]
    );
    diffProps.forEach(key => (data[key] = contactPerson[key]));
    return data;
  };

  updateContactPerson = () => {
    if (!this.isSaveable()) return;

    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .updateContactPerson(this.state.contactPerson.id, this.getEditingChanges())
      .then(({ data: contactPerson }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.vendor.contact-persons.update-success" />}
          />
        );
        this.props.onSaved(contactPerson);
      })
      .catch(e => {
        this.setState({ isSaving: false });
        //TODO: Replace with generic error message
      });
  };

  createContactPerson = () => {
    if (!this.isSaveable()) return;

    this.setState({ isSaving: true, hasUnsavedChanges: false });
    const params = this.state.contactPerson;

    this.props
      .createContactPerson(this.props.match.params.id, params)
      .then(({ data: contactPerson }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.vendor.contact-persons.create-success" />}
          />
        );
        this.props.onSaved(contactPerson);
      })
      .catch(e => {
        this.setState({ isSaving: false });
        //TODO: Replace with generic error message
      });
  };

  save = () => {
    if (this.props.new) {
      if (!this.state.contactPerson.name) {
        this.setState({ showNameError: true });
      } else {
        this.createContactPerson();
      }
    } else {
      this.updateContactPerson();
    }
  };

  isSaveable = () => this.state.contactPerson.name != null && this.state.contactPerson.name !== '';

  renderContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.contact-person.name" />}>
              <FieldErrorWrapper
                show={this.state.showNameError}
                errorElement={<FormattedMessage id="general.errors.is-required" />}
              >
                <Field.Text
                  error={this.state.showNameError}
                  autoFocus
                  value={this.state.contactPerson.name}
                  onChange={name => {
                    this.setContactPersonValue({ name });
                    this.setState({ showNameError: false });
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.contact-person.position" />}>
              <Field.Text
                value={this.state.contactPerson.position}
                placeholder={this.props.intl.formatMessage({
                  id: 'screens.vendor.contact-persons.position-placeholder',
                })}
                onChange={position => {
                  this.setContactPersonValue({ position });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.contact-person.phone" />}>
              <Field.Text
                value={this.state.contactPerson.phone}
                onChange={phone => {
                  this.setContactPersonValue({ phone });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.contact-person.email" />}>
              <Field.Text
                value={this.state.contactPerson.email}
                onChange={email => {
                  this.setContactPersonValue({ email });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    if (!this.state.contactPerson) {
      return (
        <React.Fragment>
          <Modal.Header ignoreLine onClose={this.closeModal} />
          <Modal.Loader noHeader />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={
            this.props.new ? (
              <FormattedMessage id="screens.vendor.contact-persons.create-title" />
            ) : (
              <FormattedMessage id="screens.vendor.contact-persons.update-title" />
            )
          }
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button small primary loading={this.state.isSaving} onClick={this.save} label="general.save" />
            <Button small label="general.cancel" onClick={this.cancel} />
          </Button.Group>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createContactPerson: SDKReduxOperations.createContactPerson,
      updateContactPerson: SDKReduxOperations.updateContactPerson,
      deleteContactPerson: SDKReduxOperations.deleteContactPerson,
      fetchContactPerson: EntityOperations.fetchContactPerson,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  let contactPerson;
  if (!ownProps.new) {
    contactPerson = EntitySelectors.getContactPerson(state, ownProps.id);
  }

  return {
    contactPerson,
    canEditVendors: AuthSelectors.canEditVendors(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Edit)));
