import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import { ExportLoader } from '..';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Mode from './Mode';
import styles from './styles.module.scss';

class PrintModal extends Component {
  renderPrintModes = () => {
    if (this.props.detailOnly) return false;
    return (
      <div className={styles['print-modes-container']}>
        <Mode
          label={<FormattedMessage id="components.print-modal.modes.list" />}
          icon={<Icon size={18} type="list-ul" />}
          selected={this.props.list}
          onClick={this.props.onSelectList}
        />
        <Mode
          label={<FormattedMessage id="components.print-modal.modes.detail" />}
          icon={<Icon size={18} regular type="file-alt" />}
          selected={this.props.detail}
          onClick={this.props.onSelectDetail}
        />
      </div>
    );
  };

  renderDetailOptions = () => {
    return (
      <AnimateHeight duration={250} height={this.props.detail ? 'auto' : 0}>
        {this.props.detailOptions && !this.props.detailOnly ? (
          <div className={styles['detail-options-label']}>
            <FormattedMessage id="components.print-modal.choose-information" />
          </div>
        ) : null}
        {this.props.detailOptions}
      </AnimateHeight>
    );
  };

  renderContent = () => {
    if (this.props.warning) {
      return <div className={styles['warning']}>{this.props.warning}</div>;
    }
    if (this.props.loadingForExportId) {
      return <ExportLoader exportId={this.props.loadingForExportId} />;
    }

    return (
      <>
        {this.renderPrintModes()}
        {this.renderDetailOptions()}
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={580}>
        <Modal.Header
          ignoreLine
          title={this.props.title}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        {this.props.loadingForExportId || this.props.warning ? null : (
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.props.loading}
                label="components.print-modal.print-button"
                onClick={this.props.onPrint}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default PrintModal;

PrintModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  detailOnly: PropTypes.bool,
  list: PropTypes.bool,
  detail: PropTypes.bool,
  onSelectList: PropTypes.func,
  onSelectDetail: PropTypes.func,
  loadingForExportId: PropTypes.string,
  warning: PropTypes.string,
  onPrint: PropTypes.func,
  onClose: PropTypes.func,
};

PrintModal.defaultProps = {
  title: '',
  subtitle: '',
  detailOnly: false,
  list: true,
  detail: false,
  onSelectList: null,
  onSelectDetail: null,
  loadingForExportId: null,
  warning: null,
  onPrint: null,
  onClose: null,
};
