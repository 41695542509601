import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { Button, Loader } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthOperations, AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions } from 'sdk';
import Input from './Input';
import styles from './style.module.scss';

class PinCodeOverlay extends Component {
  getInitialState = () => ({
    pinCodeValueOne: '',
    pinCodeValueTwo: '',
    pinCodeValueThree: '',
    pinCodeValueFour: '',
    showWrongPinCodeError: false,
    isLoggingIn: false,
  });

  state = this.getInitialState();

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
      this.pinCodeInputOneRef.focus();
    }
  }

  enterPinCode = value => {
    this.setState(
      {
        showWrongPinCodeError: false,
        ...value,
      },
      () => {
        const { pinCodeValueOne, pinCodeValueTwo, pinCodeValueThree, pinCodeValueFour } = this.state;
        if (pinCodeValueOne && pinCodeValueTwo && pinCodeValueThree && pinCodeValueFour) {
          let pinCode = `${pinCodeValueOne}${pinCodeValueTwo}${pinCodeValueThree}${pinCodeValueFour}`;
          this.setState({ isLoggingIn: true });
          if (this.props.isUserAuthenticated) {
            this.props
              .signOutAndSignInGroupUser({ user_id: this.props.userId, pin_code: pinCode })
              .catch(e => {
                if (HelperFunctions.hasError(e, { code: '10003', key: 'pin_code' })) {
                  this.setState({ ...this.getInitialState(), showWrongPinCodeError: true });
                  this.pinCodeInputOneRef.focus();
                }
                this.setState({ isLoggingIn: false });
              });
          } else {
            this.props.signInGroupUser({ user_id: this.props.userId, pin_code: pinCode }).catch(e => {
              if (HelperFunctions.hasError(e, { code: '10003', key: 'pin_code' })) {
                this.setState({ ...this.getInitialState(), showWrongPinCodeError: true });
                this.pinCodeInputOneRef.focus();
              }
              this.setState({ isLoggingIn: false });
            });
          }
        }
      }
    );
  };

  renderLoginLoader = () => {
    if (this.state.isLoggingIn) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <CSSTransition
        in={this.props.open}
        mountOnEnter
        unmountOnExit
        timeout={250}
        classNames={{
          enter: styles['overlay-enter'],
          enterActive: styles['overlay-enter-active'],
          enterDone: styles['overlay-enter-done'],
          exit: styles['overlay-exit'],
          exitActive: styles['overlay-exit-active'],
          exitDone: styles['overlay-exit-done'],
        }}
      >
        <div className={styles['pin-code']}>
          <div className={styles['title']}>
            <FormattedMessage id="components.select-user-group-login.pin-code.title" />
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage
              id="components.select-user-group-login.pin-code.for-user"
              values={{
                user: (
                  <span className={styles['user']}>
                    <UserNameWrapper user={this.props.user} />
                  </span>
                ),
              }}
            />
          </div>
          <div className={styles['input-container']}>
            <Input
              error={this.state.showWrongPinCodeError}
              ref={ref => (this.pinCodeInputOneRef = ref)}
              value={this.state.pinCodeValueOne}
              onChange={pinCodeValueOne => {
                this.enterPinCode({ pinCodeValueOne });
                if (pinCodeValueOne) {
                  this.pinCodeInputTwoRef.focus();
                }
              }}
            />
            <Input
              error={this.state.showWrongPinCodeError}
              ref={ref => (this.pinCodeInputTwoRef = ref)}
              value={this.state.pinCodeValueTwo}
              onChange={pinCodeValueTwo => {
                this.enterPinCode({ pinCodeValueTwo });
                if (pinCodeValueTwo) {
                  this.pinCodeInputThreeRef.focus();
                }
              }}
              onGoToPreviousInput={() => {
                this.pinCodeInputOneRef.focus();
              }}
            />
            <Input
              error={this.state.showWrongPinCodeError}
              ref={ref => (this.pinCodeInputThreeRef = ref)}
              value={this.state.pinCodeValueThree}
              onChange={pinCodeValueThree => {
                this.enterPinCode({ pinCodeValueThree });
                if (pinCodeValueThree) {
                  this.pinCodeInputFourRef.focus();
                }
              }}
              onGoToPreviousInput={() => {
                this.pinCodeInputTwoRef.focus();
              }}
            />
            <Input
              error={this.state.showWrongPinCodeError}
              ref={ref => (this.pinCodeInputFourRef = ref)}
              value={this.state.pinCodeValueFour}
              onChange={pinCodeValueFour => {
                this.enterPinCode({ pinCodeValueFour });
              }}
              onGoToPreviousInput={() => {
                this.pinCodeInputThreeRef.focus();
              }}
            />
            {this.renderLoginLoader()}
          </div>
          <div className={styles['separator']} />
          <Button
            gray
            disabled={this.state.isLoggingIn}
            label="components.select-user-group-login.pin-code.change-user"
            onClick={() => this.props.onClose()}
          />
        </div>
      </CSSTransition>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signInGroupUser: AuthOperations.signInGroupUser,
      signOutAndSignInGroupUser: AuthOperations.signOutAndSignInGroupUser,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.userId),
    isUserAuthenticated: AuthSelectors.isUserAuthenticated(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PinCodeOverlay);
