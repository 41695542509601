const getMeter = state => {
  const { meter, entities } = state;
  if (!meter.id) return null;
  return entities.meterById[meter.id];
};

const isDeleted = state => state.meter.isDeleted;

const getMeterReadings = state => state.meter.meterReadings.ids;

const getMeterReadingsPagination = state => state.meter.meterReadings.pagination;

export default {
  getMeter,
  isDeleted,
  getMeterReadings,
  getMeterReadingsPagination,
};
