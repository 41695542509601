import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { List, MoneyWithCurrency, Icon, ElapsedTime } from 'views/components/Shared/General';
import { CostType } from 'sdk/Cost';
import { AuthSelectors } from 'state/ducks/auth';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { VendorAvatar } from 'views/components/Vendor';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import styles from './style.module.scss';

class Cost extends Component {
  renderTitle = () => {
    const { sparePart, sparePartWithdrawal } = this.props;

    if (sparePart) {
      return <Title sparePart={sparePart} />;
    }

    return sparePartWithdrawal.title;
  };

  renderSpentTime = () => {
    if (this.props.workOrderSpentTimeCategory) {
      return (
        <>
          <span>{this.props.workOrderSpentTimeCategory.title}</span>
          <span> - </span>
          <ElapsedTime elapsedMinutes={this.props.workOrderSpentTime.minutes} />
        </>
      );
    }
    return <ElapsedTime elapsedMinutes={this.props.workOrderSpentTime.minutes} />;
  };

  renderLeftIconColumn = () => {
    if (this.props.workOrderSpentTime) {
      if (this.props.vendor) {
        return (
          <List.Item.Column width={30}>
            <VendorAvatar fontSize={13} size={30} vendor={this.props.vendor} />
          </List.Item.Column>
        );
      }
      if (this.props.createdByUser) {
        return (
          <List.Item.Column width={30}>
            <ProfilePicture fontSize={13} size={28} userId={this.props.createdByUser.id} />
          </List.Item.Column>
        );
      }
      return (
        <List.Item.Column width={30}>
          <ProfilePicture fontSize={13} size={28} userId={null} />
        </List.Item.Column>
      );
    }
    if (this.props.sparePartWithdrawal) {
      return (
        <List.Item.ImageColumn
          imageId={this.props.sparePart?.images === 0 ? null : this.props.sparePart?.images[0]}
        />
      );
    }
    if (this.props.downtime) {
      return (
        <List.Item.Column width={30}>
          <div className={styles['left-icon']}>
            <Icon type="clock" />
          </div>
        </List.Item.Column>
      );
    }
    return (
      <List.Item.Column width={30}>
        <div className={styles['left-icon']}>
          <Icon regular type="dollar-sign" />
        </div>
      </List.Item.Column>
    );
  };

  renderTitleColumn = () => {
    if (this.props.workOrderSpentTime) {
      if (this.props.vendor)
        return <List.Item.TitleColumn title={this.props.vendor.name} subtitle={this.renderSpentTime()} />;
      if (this.props.createdByUser) {
        return (
          <List.Item.TitleColumn
            title={<UserNameWrapper user={this.props.createdByUser} />}
            subtitle={this.renderSpentTime()}
          />
        );
      }
      return (
        <List.Item.TitleColumn title={<UserNameWrapper user={null} />} subtitle={this.renderSpentTime()} />
      );
    }
    if (this.props.sparePartWithdrawal) {
      return (
        <List.Item.TitleColumn
          title={this.renderTitle()}
          subtitle={
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePartWithdrawal.quantity}
            />
          }
        />
      );
    }
    if (this.props.downtime) {
      return (
        <List.Item.TitleColumn
          title={this.props.asset.title}
          subtitle={<ElapsedTime elapsedMinutes={this.props.downtime.missed_operating_minutes} />}
        />
      );
    }
    return (
      <List.Item.TitleColumn
        title={this.props.cost.description}
        subtitle={this.props.costOtherCategory == null ? null : this.props.costOtherCategory.title}
      />
    );
  };

  renderCostAmount = () => {
    const { amount, amount_currency, amount_exchange_rate } = this.props.cost;
    const { currency: systemCurrency } = this.props.currentSystem;
    if (this.props.cost.amount == null) {
      return (
        <span className={styles['no-cost']}>
          <FormattedMessage id="screens.work-order.costs.no-cost" />
        </span>
      );
    }
    if (amount_currency !== systemCurrency) {
      const localValue = new Decimal(amount)
        .times(new Decimal(amount_exchange_rate))
        .toDecimalPlaces(2)
        .toString();
      return (
        <>
          <span>
            <MoneyWithCurrency value={amount} currency={amount_currency} />
          </span>
          <span>&nbsp;</span>
          <span className={styles['system-currency']}>
            (
            <MoneyWithCurrency value={localValue} currency={this.props.currentSystem.currency} />)
          </span>
        </>
      );
    }
    return <MoneyWithCurrency value={amount} currency={amount_currency} />;
  };

  renderCostAmountColumn = () => {
    return <List.Item.Column>{this.renderCostAmount()}</List.Item.Column>;
  };

  render() {
    return (
      <List.Item clickable onClick={() => this.props.onClick(this.props.cost)} new={this.props.new}>
        {this.renderLeftIconColumn()}
        {this.renderTitleColumn()}
        {this.renderCostAmountColumn()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const cost = EntitySelectors.getCost(state, ownProps.costId);

  switch (cost.category) {
    case CostType.Labor: {
      const workOrderSpentTime = EntitySelectors.getWorkOrderSpentTime(state, cost.work_order_spent_time_id);
      let workOrderSpentTimeCategory = null;
      let createdByUser = null;
      let vendor = null;
      if (workOrderSpentTime) {
        workOrderSpentTimeCategory = EntitySelectors.getWorkOrderSpentTimeCategory(
          state,
          workOrderSpentTime.work_order_spent_time_category_id
        );
        createdByUser = EntitySelectors.getUser(state, workOrderSpentTime.user_id);
        vendor = EntitySelectors.getVendor(state, workOrderSpentTime.vendor_id);
      }
      return {
        cost,
        workOrderSpentTime,
        workOrderSpentTimeCategory,
        createdByUser,
        vendor,
        currentSystem: AuthSelectors.getCurrentSystem(state),
      };
    }
    case CostType.Part: {
      let sparePart = null;
      let sparePartUnit = null;
      let createdByUser = null;
      const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(
        state,
        cost.spare_part_withdrawal_id
      );
      if (sparePartWithdrawal) {
        if (sparePartWithdrawal.spare_part_id) {
          sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
          sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
        } else {
          sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
        }

        createdByUser = EntitySelectors.getUser(state, sparePartWithdrawal.created_by_user_id);
      }
      return {
        cost,
        sparePartWithdrawal,
        sparePart,
        sparePartUnit,
        createdByUser,
        currentSystem: AuthSelectors.getCurrentSystem(state),
      };
    }
    case CostType.DownTime: {
      const downtime = EntitySelectors.getDowntime(state, cost.downtime_id);
      return {
        cost,
        downtime,
        asset: EntitySelectors.getAsset(state, downtime.asset_id),
        currentSystem: AuthSelectors.getCurrentSystem(state),
      };
    }
    default:
      return {
        cost,
        costOtherCategory: EntitySelectors.getCostOtherCategory(state, cost.cost_other_category_id),
        currentSystem: AuthSelectors.getCurrentSystem(state),
      };
  }
}

export default connect(mapStateToProps)(Cost);
