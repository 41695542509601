export function getVendorSubtitle(vendor) {
  if (vendor.city) {
    if (vendor.country) {
      return vendor.city + ', ' + vendor.country;
    } else {
      return vendor.city;
    }
  } else if (vendor.country) {
    return vendor.country;
  } else if (vendor.address1) {
    return vendor.address1;
  } else if (vendor.address2) {
    return vendor.address2;
  }
  return null;
}

export function generateEmptyVendor() {
  return {
    name: '',
    purchase_order_currency: 'SEK',
    assign_work_orders: true,
    create_purchase_orders: true,
  };
}
