import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { VendorsSelectors, VendorsOperations } from 'state/ducks/vendors';
import { FILTER_TYPES } from 'views/scenes/Vendors/VendorList/FilterModal';

class Email extends Component {
  state = {
    initialFilter: this.props.filter,
  };

  render() {
    const { value, comparator } = this.props.filter;
    return (
      <>
        <SideModal.Container.Filter.Detail.BackButton
          onClick={() => {
            if (isEqual(this.props.initialFilter, this.state.initialFilter)) {
              this.props.removeFilter({
                key: FILTER_TYPES.email,
                data: {
                  [FILTER_TYPES.email]: {
                    ...this.props.initialFilter,
                  },
                },
              });
            } else {
              this.props.addFilter({
                key: FILTER_TYPES.email,
                data: {
                  [FILTER_TYPES.email]: {
                    ...this.state.initialFilter,
                  },
                },
              });
            }
            this.props.selectFilterType(null);
          }}
        />
        <SideModal.Container.Filter.Detail.Title>
          <FormattedMessage id="resources.vendor.email" />
        </SideModal.Container.Filter.Detail.Title>
        <SideModal.Container.Filter.Detail.Types>
          <SideModal.Container.Filter.Detail.Types.Text
            value={value}
            comparator={comparator}
            onChange={({ value, comparator }) => {
              this.props.addFilter({
                key: FILTER_TYPES.email,
                data: {
                  [FILTER_TYPES.email]: {
                    comparator,
                    value,
                  },
                },
              });
            }}
            onApplyfilter={this.props.showAppliedFilters}
          />
        </SideModal.Container.Filter.Detail.Types>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: VendorsOperations.removeFilter,
      addFilter: VendorsOperations.addFilter,
      showAppliedFilters: VendorsOperations.showAppliedFilters,
      selectFilterType: VendorsOperations.selectFilterType,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: VendorsSelectors.getFiltersForKey(state, FILTER_TYPES.email),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Email);

Email.propTypes = {
  value: PropTypes.string,
  initialValue: PropTypes.string,
};

Email.defaultProps = {
  filter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Search,
    value: '',
  },
  initialFilter: {
    comparator: HelperFunctions.FILTER_COMPARABLES.Search,
    value: '',
  },
};
