import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { normalizeSparePartLocation, normalizeSparePart } from 'sdk/Schemas';
import { injectIntl, FormattedMessage } from 'react-intl';
import { List, WhiteCard, Pagination } from 'views/components/Shared/General';
import { SparePartLocation } from 'views/components/SparePartLocation';
import { Modal } from 'views/components/Shared/Layout';
import SparePartList from '../components/SparePartList';
import SparePartListItem from '../components/SparePartList/SparePartListItem';
import SparePartLocationPath from './SparePartLocationPath';
import styles from './style.module.scss';

const listSparePartsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class SparePartLocations extends Component {
  animationDirection = 'forward';
  constructor(props) {
    super(props);
    this.state = {
      locationData: {},
      locationsIsEmpty: false,
      parentLocationId: 'root',
      isLoadingSpareParts: false,
      isLoadingLocation: false,
      isLoadingPagination: false,
      sparePartIds: [],
      savedData: null,
      params: {},
      totalAmountOfSpareParts: 0,
      isChangingPage: false,
      page: 1,
      totalPages: 0,
    };
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchSparePartsForLocation({ search: this.props.searchValue });
    }, 300);
  }

  componentDidMount() {
    this.fetchSparePartLocationsForSystem({ search: this.props.searchValue });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ isLoadingSpareParts: true, isLoadingPagination: true });
      this.debouncedSearchValueWasChanged({ search: this.props.searchValue });
    }
  }

  buildLocationData = sparePartLocations => {
    let data = {};
    sparePartLocations.forEach(location => {
      const parentId = location.spare_part_location_parent_id || 'root';

      if (!data[parentId]) {
        data[parentId] = [location.id];
      } else {
        data[parentId] = [...data[parentId], location.id];
      }
    });
    this.setState({
      isLoadingLocation: false,
      locationData: data,
      locationsIsEmpty: sparePartLocations.length === 0,
    });
  };

  changePage = page => {
    this.setState({ isChangingPage: true, page });
    this.fetchSparePartsForLocation({ page });
  };

  fetchSparePartLocationsForSystem = () => {
    this.setState({ isLoadingLocation: true });
    API.listSparePartLocations(this.props.currentSystem.id).then(res => {
      const { data } = res;
      const { entities } = normalizeSparePartLocation(data);
      this.props.updateEntities(entities);
      this.buildLocationData(data);
    });
  };

  fetchSparePartsForLocation = params => {
    this.setState({ isLoadingSpareParts: true });
    if (this.state.parentLocationId === 'root') {
      this.setState({ sparePartIds: [] });
    } else {
      listSparePartsRequest.cancel();
      let attrs = {
        ...params,
        spare_part_location_id: this.state.parentLocationId,
        ...this.props.additionalApiParams,
        page_size: 8,
      };
      if (attrs.search && attrs.search.length > 0) {
        attrs = {
          ...attrs,
          sort: 'search_relevance',
        };
      }

      API.listSpareParts(this.props.currentSystem.id, attrs, listSparePartsRequest.getCancelTokenConfig())
        .then(res => {
          const { data, headers } = res;
          const { entities, result } = normalizeSparePart(data);
          this.props.updateEntities(entities);
          this.setState({
            sparePartIds: result,
            isLoadingSpareParts: false,
            isChangingPage: false,
            isLoadingPagination: false,
            totalAmountOfSpareParts: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
            totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
          });
        })
        .catch(e => {
          this.setState({ isLoadingSpareParts: false, isChangingPage: false, isLoadingPagination: false });
        });
    }
  };

  renderEmptyDataSet = () => {
    if (
      this.state.locationData[this.state.parentLocationId] &&
      this.state.locationData[this.state.parentLocationId].length > 0
    )
      return null;
    return (
      <WhiteCard centerContent noPadding>
        <div className={styles['empty-dataset']}>
          <FormattedMessage id="components.select-spare-part-modal.content.empty-content" />
        </div>
      </WhiteCard>
    );
  };

  renderEmptySearch = () => {
    return (
      <>
        {this.renderSeparator()}
        <WhiteCard centerContent noPadding>
          <div className={styles['empty-dataset']}>
            <FormattedMessage id="components.select-spare-part-modal.content.empty-search" />
          </div>
        </WhiteCard>
      </>
    );
  };

  renderLocationsEmptyDataset = () => {
    return (
      <WhiteCard centerContent noPadding>
        <div className={styles['empty-dataset']}>
          <FormattedMessage id="components.select-spare-part-modal.content.empty-locations" />
        </div>
      </WhiteCard>
    );
  };

  renderLoaders = () => {
    if (this.state.sparePartIds.length === 0)
      return (
        <>
          <SparePartListItem loading />
          <SparePartListItem loading />
        </>
      );
    else
      return this.state.sparePartIds.map(() => {
        return <SparePartListItem loading />;
      });
  };

  renderSeparator = () => {
    if (
      !this.state.locationData[this.state.parentLocationId] ||
      this.state.locationData[this.state.parentLocationId].length === 0
    ) {
      return null;
    } else {
      return <div className={styles['separator']} />;
    }
  };

  renderListContent = () => {
    if (this.state.parentLocationId === 'root') return null;
    if (!this.state.isLoadingSpareParts && this.props.searchValue && this.state.sparePartIds.length === 0)
      return this.renderEmptySearch();
    if (
      !this.state.isLoadingSpareParts &&
      this.props.searchValue === '' &&
      this.state.sparePartIds.length === 0
    )
      return this.renderEmptyDataSet();

    return (
      <>
        {this.renderSeparator()}
        {this.renderListHeader()}
        <List>
          {this.state.isLoadingSpareParts || this.state.isChangingPage ? (
            this.renderLoaders()
          ) : (
            <SparePartList
              listItemRightComponent={this.props.listItemRightComponent}
              loading={this.state.isLoadingSpareParts}
              ids={this.state.sparePartIds}
            />
          )}
        </List>
      </>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-spare-part-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoadingLocations = () => {
    return (
      <div className={styles['location-container']}>
        <SparePartLocation loading />
        <SparePartLocation loading />
      </div>
    );
  };

  renderChildrenLocations = () => {
    if (this.state.isLoadingLocation) return this.renderLoadingLocations();
    if (this.state.locationsIsEmpty) return this.renderLocationsEmptyDataset();
    if (!this.state.locationData[this.state.parentLocationId]) return;

    return (
      <>
        <div className={styles['location-container']}>
          {this.state.locationData[this.state.parentLocationId].map(locationId => {
            return (
              <SparePartLocation
                id={locationId}
                onClick={() => {
                  this.animationDirection = 'forward';
                  this.setState({ parentLocationId: locationId }, () =>
                    this.fetchSparePartsForLocation({ search: this.props.searchValue })
                  );
                }}
                disclosureIndicator
              />
            );
          })}
        </div>
      </>
    );
  };

  renderPagination = () => {
    if (this.state.totalAmountOfSpareParts <= 8) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };
  renderLocationPath = () => {
    return (
      <div className={styles['path-container']}>
        <SparePartLocationPath
          parentSparePartLocationId={this.state.parentLocationId}
          onNavigate={locationId => {
            this.animationDirection = 'backward';
            this.setState({ parentLocationId: locationId }, () =>
              this.fetchSparePartsForLocation({ search: this.props.searchValue })
            );
          }}
        />
      </div>
    );
  };
  render() {
    let classNames = [];
    if (this.animationDirection === 'forward') {
      classNames = [...classNames, styles['animate-forward']];
    } else {
      classNames = [...classNames, styles['animate-backward']];
    }
    return (
      <>
        <Modal.ColumnLayout.Container.Content>
          {this.renderLocationPath()}
          <div className={classNames.join(' ')}>
            <SwitchTransition>
              <CSSTransition
                key={this.state.parentLocationId}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false);
                }}
                classNames={{
                  enter: styles['fade-enter'],
                  enterActive: styles['fade-enter-active'],
                  exit: styles['fade-exit'],
                  exitActive: styles['fade-exit-active'],
                }}
              >
                <div>
                  {this.renderChildrenLocations()}
                  {this.renderListContent()}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartLocations));
