const DOMAIN_PREFIX = 'vendor/';

const SET_VENDOR_ID = `${DOMAIN_PREFIX}set_vendor_id`;
const FETCH_CONTACT_PERSONS_SUCCESS = `${DOMAIN_PREFIX}fetch_contact_persons_success`;
const FETCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_attachments_success`;
const FETCH_ASSET_VENDORS_SUCCESS = `${DOMAIN_PREFIX}fetch_asset_vendors_success`;
const FETCH_SPARE_PARTS_SUCCESS = `${DOMAIN_PREFIX}fetch_spare_parts_success`;
const FETCH_PURCHASE_ORDERS_SUCCESS = `${DOMAIN_PREFIX}fetch_purchase_orders_success`;
const FETCH_WORK_ORDERS_SUCCESS = `${DOMAIN_PREFIX}fetch_work_orders_success`;

export default {
  SET_VENDOR_ID,
  FETCH_CONTACT_PERSONS_SUCCESS,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ASSET_VENDORS_SUCCESS,
  FETCH_SPARE_PARTS_SUCCESS,
  FETCH_PURCHASE_ORDERS_SUCCESS,
  FETCH_WORK_ORDERS_SUCCESS,
};
