import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PurchaseOrderStatus, PurchaseOrderDeliveryStatus } from 'sdk/PurchaseOrder';
import styles from './style.module.scss';

export default ({ purchaseOrder }) => {
  const renderStatus = () => {
    const { status, delivery_status, canceled } = purchaseOrder;
    if (status == PurchaseOrderStatus.Draft) {
      return (
        <span className={styles['draft']}>
          <FormattedMessage id="resources.purchase-order.statuses.draft" />
        </span>
      );
    }
    if (canceled) {
      return (
        <span className={styles['cancelled']}>
          <FormattedMessage id="resources.purchase-order.cancelled" />
        </span>
      );
    }
    switch (delivery_status) {
      case PurchaseOrderDeliveryStatus.None:
        return (
          <span className={styles['ordered']}>
            <FormattedMessage id="resources.purchase-order.statuses.ordered" />
          </span>
        );
      case PurchaseOrderDeliveryStatus.Partial:
        return (
          <span className={styles['partially-delivered']}>
            <FormattedMessage id="resources.purchase-order.statuses.partially-delivered" />
          </span>
        );
      case PurchaseOrderDeliveryStatus.Full:
        return (
          <span className={styles['delivered']}>
            <FormattedMessage id="resources.purchase-order.statuses.delivered" />
          </span>
        );

      default:
        return null;
    }
  };

  return renderStatus();
};
