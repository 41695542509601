import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import styles from '../style.module.scss';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { List, Icon, ViewTextArea, Button } from 'views/components/Shared/General';
import { WorkOrderOperations, WorkOrderSelectors } from 'state/ducks/workOrder';
import { ReminderType } from 'sdk/WorkOrder';
import { ReminderMessage } from 'views/components/WorkOrder';
import { EditReminderModal } from 'views/components/WorkOrder';

class Reminders extends Component {
  state = {
    showEditReminderModal: false,
    showEditButton: false,
  };

  renderModal = () => {
    return (
      <EditReminderModal
        open={this.state.showEditReminderModal}
        editingWorkOrder={this.props.editingWorkOrder}
        onSave={data => {
          this.props.setEditingWorkOrderValues(data);
        }}
        onClose={() => {
          this.setState({ showEditReminderModal: false });
        }}
      />
    );
  };
  render = () => {
    return this.props.editingWorkOrder.reminder_type === ReminderType.None ? null : (
      <>
        <List.Item 
        alignItems={this.props.editingWorkOrder.reminder_comment ? "start" : null} 
        onMouseEnter={() => this.setState({ showEditButton: true })} 
        onMouseLeave={() => this.setState({ showEditButton: false })}
        >
          <List.Item.Column>
            <Icon type="bell" withBackground orange backgroundSize={34} />
          </List.Item.Column>
          <List.Item.Column flex>
            <span className={styles['reminder-text']}>
              <ReminderMessage workOrder={this.props.editingWorkOrder} />
            </span>
            {this.props.editingWorkOrder.reminder_comment ? (
              <div className={styles['comment-container']}>
                <ViewTextArea>
                  {this.props.editingWorkOrder.reminder_comment}
                </ViewTextArea>
              </div>
            ) : null}
          </List.Item.Column>
          <List.Item.Column hide={!this.state.showEditButton}>
            <Button
                type="icon"
                icon={<Icon regular type="pen" />}
                onClick={() => this.setState({ showEditReminderModal: true })}
              />
          </List.Item.Column>
        </List.Item>
        {this.renderModal()}
      </>
    );
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Reminders)));
