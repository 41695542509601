import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { ConfirmModal, WhiteCard, Icon, Button } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { Section } from 'views/scenes/Settings/components';
import ActivateLoginModal from './ActivateLoginModal';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePasswordModal from './ChangePasswordModal';
import styles from './style.module.scss';

class OperatorLogin extends Component {
  state = {
    showConfigureGroupLoginModal: false,
    isLoadingCredentials: false,
    credentials: {},
  };

  componentDidMount() {
    this.fetchGroupLoginCredential();
  }

  fetchGroupLoginCredential = () => {
    this.setState({ isLoadingCredentials: true });
    return API.fetchGroupLoginCredential(this.props.system.id)
      .then(res => {
        const { data } = res;
        this.setState({ isLoadingCredentials: false, credentials: data });
      })
      .catch(e => {
        this.setState({ isLoadingCredentials: false, credentials: null });
      });
  };

  deleteLoginCredential = () => {
    this.setState({ isDeletingLoginCredential: true });
    API.deleteLoginCredential(this.state.credentials.id)
      .then(() => {
        this.setState({ credentials: null, isDeletingLoginCredential: false, showConfirmDeleteModal: false });
      })
      .catch(e => {
        this.setState({ isDeletingLoginCredential: false, showConfirmDeleteModal: false });
      });
  };

  renderActivateLoginModal = () => {
    return (
      <ActivateLoginModal
        open={this.state.showActivateLoginModal}
        onClose={() => this.setState({ showActivateLoginModal: false })}
        onActivated={credentials => {
          this.setState({ credentials, showActivateLoginModal: false });
        }}
      />
    );
  };

  renderChangeEmailModal = () => {
    return (
      <ChangeEmailModal
        email={this.state.credentials ? this.state.credentials.username : null}
        credentialId={this.state.credentials ? this.state.credentials.id : null}
        open={this.state.showChangeEmailModal}
        onClose={() => this.setState({ showChangeEmailModal: false })}
        onChanged={email => {
          this.setState({
            showChangeEmailModal: false,
            credentials: {
              ...this.state.credentials,
              username: email,
            },
          });
        }}
      />
    );
  };

  renderChangePasswordModal = () => {
    return (
      <ChangePasswordModal
        open={this.state.showChangePasswordModal}
        credentialId={this.state.credentials ? this.state.credentials.id : null}
        onClose={() => this.setState({ showChangePasswordModal: false })}
        onChanged={() => {
          this.setState({ showChangePasswordModal: false });
        }}
      />
    );
  };

  renderConfirmDeleteModal = () => {
    return (
      <ConfirmModal
        destructive
        title={<FormattedMessage id="screens.users.group-logins.modal.confirm-delete.title" />}
        message={<FormattedMessage id="screens.users.group-logins.modal.confirm-delete.subtitle" />}
        confirmButtonText="screens.users.group-logins.modal.confirm-delete.button"
        open={this.state.showConfirmDeleteModal}
        confirmIsLoading={this.state.isDeletingLoginCredential}
        onConfirm={() => this.deleteLoginCredential()}
        onClose={() => this.setState({ showConfirmDeleteModal: false })}
        onCancel={() => this.setState({ showConfirmDeleteModal: false })}
      />
    );
  };

  renderActivated = () => {
    if (this.state.isLoadingCredentials) {
      return (
        <>
          <div className={styles['field-group']}>
            <div className={styles['login-detail']}>
              <Icon type="user" blue withBackground backgroundSize={30} size={14} />
              <div className={styles['login-field']}>
                <div className={styles['login-title']}>
                  <FormattedMessage id="resources.login-credentials.username" />
                </div>
                <div className={styles['login-subtitle-loader']}>
                  <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
                    <rect x="0" y="0" rx="3" ry="3" width="250" height="18" />
                  </ContentLoader>
                </div>
              </div>
            </div>

            <div className={styles['login-detail']}>
              <Icon type="lock" red withBackground backgroundSize={30} size={14} />
              <div className={styles['login-field']}>
                <div className={styles['login-title']}>
                  <FormattedMessage id="resources.login-credentials.password" />
                </div>
                <div className={styles['login-subtitle-loader']}>
                  <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
                    <rect x="0" y="0" rx="3" ry="3" width="250" height="18" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles['field-group']}>
          <div className={styles['login-detail']}>
            <Icon type="user" blue withBackground backgroundSize={30} size={14} />
            <div className={styles['login-field']}>
              <div className={styles['login-title']}>
                <FormattedMessage id="resources.login-credentials.username" />
              </div>
              <div className={styles['login-subtitle']}>{this.state.credentials.username}</div>
            </div>
            <Button
              type="icon"
              icon={<Icon regular type="pen" size={12} />}
              onClick={() => this.setState({ showChangeEmailModal: true })}
            />
          </div>
          <div className={styles['login-detail']}>
            <Icon type="lock" red withBackground backgroundSize={30} size={14} />
            <div className={styles['login-field']}>
              <div className={styles['login-title']}>
                <FormattedMessage id="resources.login-credentials.password" />
              </div>
              <div className={styles['login-subtitle']}>**********</div>
            </div>
            <Button
              type="icon"
              icon={<Icon regular type="pen" size={12} />}
              onClick={() => this.setState({ showChangePasswordModal: true })}
            />
          </div>
        </div>
        <div className={styles['separator']} />
        <Button
          gray
          small
          onClick={() => this.setState({ showConfirmDeleteModal: true })}
          label="screens.users.group-logins.modal.deactivate"
        />
      </>
    );
  };

  renderNotActivated = () => (
    <>
      <div className={styles['not-activated']}>
        <dv className={styles['not-activated-title']}>
          <FormattedMessage id="screens.users.group-logins.modal.not-activated" />
        </dv>
      </div>
      <div>
        <Button
          gray
          small
          onClick={() => this.setState({ showActivateLoginModal: true })}
          label="general.activate"
        />
      </div>
    </>
  );

  render() {
    return (
      <>
        <ListLayout.Content.MainContent>
          <ListLayout.Content.MainContent.Content>
            <WhiteCard>
              <div>
                <Section
                  header
                  title={<FormattedMessage id="screens.users.operator-login" />}
                  subtitle={<FormattedMessage id="screens.users.operator-login-subtitle" />}
                />
                {this.state.credentials ? this.renderActivated() : this.renderNotActivated()}
              </div>
            </WhiteCard>
          </ListLayout.Content.MainContent.Content>
        </ListLayout.Content.MainContent>
        {this.renderActivateLoginModal()}
        {this.renderChangeEmailModal()}
        {this.renderChangePasswordModal()}
        {this.renderConfirmDeleteModal()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(OperatorLogin);
