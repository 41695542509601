import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { Field, NewInlineModal, Button } from 'views/components/Shared/General';
import { WorkOrderAssigneeOperations, WorkOrderAssigneeSelectors } from 'state/ducks/workOrderAssignee';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UserNameWrapper, ProfilePicture, SelectUserModal } from 'views/components/User';

class ChooseAssigneeInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedTab: 'user',
      userSearchTerm: '',
      groupSearchTerm: '',
      vendorSearchTerm: '',
      initialUsers: [],
      initialGroups: [],
      users: props.users,
      groups: props.groups,
      vendors: props.vendors,
      isSearchingVendors: false,
      currentPage: 0,
      totalVendors: 0,
      showSelectUserModal: false,
    };

    this.debouncedPagination = debounce(page => {
      this.props
        .fetchVendors(this.props.currentSystem.id, { page, search: this.state.vendorSearchTerm })
        .then(() => {
          if (this.state.currentPage === page) {
            this.setState({ isSearchingVendors: false });
          }
        })
        .catch(() => {});
    }, 400);
    this.searchVendors = debounce(this.searchVendors, 300);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedTab === 'user' && prevState.initialUsers.length !== nextProps.users.length) {
      return { users: nextProps.users, initialUsers: nextProps.users };
    }
    if (prevState.selectedTab === 'group' && prevState.initialGroups.length !== nextProps.groups.length) {
      return { groups: nextProps.groups, initialGroups: nextProps.groups };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.inlineModalSearchRef == null) return;
    if (prevProps.isFetchingUsers && !this.props.isFetchingUsers) {
      this.inlineModalSearchRef.focus();
    } else if (prevProps.isFetchingGroups && !this.props.isFetchingGroups) {
      this.inlineModalSearchRef.focus();
    } else if (prevProps.isFetchingVendors && !this.props.isFetchingVendors) {
      this.inlineModalSearchRef.focus();
    }
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({
        isOpen: true,
        userSearchTerm: '',
        groupSearchTerm: '',
        vendorSearchTerm: '',
      });
      this.selectTab(this.getInitialTab());
    }
  };

  userSearchTextWasChanged = searchTerm => {
    this.setState({ userSearchTerm: searchTerm });
    searchTerm = searchTerm.toLowerCase();

    if (searchTerm === '') {
      this.setState({ users: this.props.users });
    } else {
      const filteredUsers = this.props.users.filter(user => {
        return user.name.toLowerCase().includes(searchTerm);
      });
      this.setState({ users: filteredUsers });
    }
  };

  groupSearchTextWasChanged = searchTerm => {
    this.setState({
      groupSearchTerm: searchTerm,
      groups: this.props.groups.filter(
        group => searchTerm === '' || group.title.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    });
  };

  searchVendors = searchTerm => {
    this.props
      .fetchVendors(this.props.currentSystem.id, { search: searchTerm })
      .then(() => {
        if (searchTerm === this.state.vendorSearchTerm) {
          this.setState({ isSearchingVendors: false });
        }
      })
      .catch(() => {});
  };

  selectUser = user => {
    if (!this.props.multiple) {
      this.props.onSelectUser(user);
      this.toggle();
    } else if (this.props.selectedUserIds.includes(user.id)) this.props.onRemoveUser(user);
    else this.props.onAddUser(user);
  };

  selectGroup = group => {
    if (!this.props.multiple) {
      this.props.onSelectGroup(group);
      this.toggle();
    } else if (this.props.selectedGroupIds.includes(group.id)) this.props.onRemoveGroup(group);
    else this.props.onAddGroup(group);
  };

  selectVendor = vendor => {
    if (!this.props.multiple) {
      this.props.onSelectVendor(vendor);
      this.toggle();
    } else if (this.props.selectedVendorIds.includes(vendor.id)) this.props.onRemoveVendor(vendor);
    else this.props.onAddVendor(vendor);
  };

  selectPage = page => {
    this.setState({ isSearchingVendors: true, currentPage: page });
    this.debouncedPagination(page);
  };

  getInitialTab = () => {
    if (this.props.multiple) {
      if (this.props.selectedVendors.length > 0) return 'vendor';
      if (!this.props.hideGroups && this.props.selectedGroups.length > 0) return 'group';
      return 'user';
    } else {
      if (this.props.selectedVendorId) return 'vendor';
      if (this.props.selectedGroupId) return 'group';
      return 'user';
    }
  };

  selectTab = id => {
    this.setState({ selectedTab: id });
    if (id === 'user') {
      const work_order_assignee_included_user_id = this.props.selectedUserIds.join(',');
      if (this.props.includeAssignedOperators) {
        this.props.fetchUsers(this.props.currentSystem.id, {
          work_order_assignee: true,
        });
      } else {
        this.props.fetchUsers(this.props.currentSystem.id, {
          work_order_assignee_included_user_id,
          member: true,
        });
      }
    } else if (id === 'group') {
      this.props.fetchGroups(this.props.currentSystem.id);
    } else if (id === 'vendor') {
      this.props.fetchVendors(this.props.currentSystem.id).then(({ totalEntries: totalVendors }) => {
        this.setState({ totalVendors });
      });
    }
  };

  getTabs = () => {
    let tabs = [
      {
        id: 'user',
        title: 'components.assignee-inline-modal.users.title',
      },
    ];
    if (!this.props.hideGroups) {
      tabs = [
        ...tabs,
        {
          id: 'group',
          title: 'components.assignee-inline-modal.groups.title',
        },
      ];
    }
    if (this.props.canViewVendors) {
      tabs = [
        ...tabs,
        {
          id: 'vendor',
          title: 'components.assignee-inline-modal.vendors.title',
        },
      ];
    }
    return tabs;
  };

  renderSearchEmptyDataset = () => (
    <NewInlineModal.Dropdown.EmptyDataSet>
      <FormattedMessage id="general.empty-data-set-search.title" />
    </NewInlineModal.Dropdown.EmptyDataSet>
  );

  renderUserItem = user => {
    if (this.props.multiple) {
      const selected = this.props.selectedUserIds.includes(user.id);

      return (
        <NewInlineModal.Dropdown.Item
          key={user.id}
          leftComponent={<Field.Checkbox checked={selected} onChange={() => this.selectUser(user)} />}
          onClick={e => this.selectUser(user)}
        >
          <UserNameWrapper user={user} />
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      return (
        <NewInlineModal.Dropdown.Item
          key={user.id}
          selected={user.id === this.props.selectedUserId}
          leftComponent={
            <ProfilePicture
              userId={user.id}
              size={20}
              defaultElement={<NewInlineModal.Dropdown.ItemIcon icon="user" />}
            />
          }
          onClick={() => this.selectUser(user)}
        >
          <UserNameWrapper user={user} />
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderUsersTab = () => {
    return (
      <React.Fragment>
        {this.state.users.length === 0 ? (
          this.renderSearchEmptyDataset()
        ) : (
          <NewInlineModal.Dropdown.Items>
            {this.state.users.map(this.renderUserItem)}
          </NewInlineModal.Dropdown.Items>
        )}
      </React.Fragment>
    );
  };

  renderGroupItem = group => {
    if (this.props.multiple) {
      const selected = this.props.selectedGroupIds.includes(group.id);

      return (
        <NewInlineModal.Dropdown.Item
          key={group.id}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectGroup(group);
              }}
            />
          }
          onClick={e => this.selectGroup(group)}
        >
          {group.title}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      return (
        <NewInlineModal.Dropdown.Item
          key={group.id}
          selected={group.id === this.props.selectedGroupId}
          leftComponent={<NewInlineModal.Dropdown.ItemIcon icon="users" />}
          onClick={() => this.selectGroup(group)}
        >
          {group.title}
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderGroupsTab = () => {
    if (this.props.groups.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.assignee-inline-modal.groups.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }

    return (
      <React.Fragment>
        {this.state.groups.length === 0 ? (
          this.renderSearchEmptyDataset()
        ) : (
          <NewInlineModal.Dropdown.Items>
            {this.state.groups.map(this.renderGroupItem)}
          </NewInlineModal.Dropdown.Items>
        )}
      </React.Fragment>
    );
  };

  renderVendorItem = vendor => {
    if (this.props.multiple) {
      const selected = this.props.selectedVendorIds.includes(vendor.id);

      return (
        <NewInlineModal.Dropdown.Item
          key={vendor.id}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectVendor(vendor);
              }}
            />
          }
          onClick={e => this.selectVendor(vendor)}
        >
          {vendor.name}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      return (
        <NewInlineModal.Dropdown.Item
          key={vendor.id}
          selected={vendor.id === this.props.selectedVendorId}
          leftComponent={<NewInlineModal.Dropdown.ItemIcon icon="briefcase" />}
          onClick={() => this.selectVendor(vendor)}
        >
          {vendor.name}
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderVendorsTab = () => {
    if (this.props.vendors.length === 0 && !this.state.vendorSearchTerm && !this.state.isSearchingVendors) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.assignee-inline-modal.vendors.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    if (this.state.isSearchingVendors) {
      return this.renderLoader();
    }
    return (
      <React.Fragment>
        {this.props.vendors.length === 0 ? (
          this.renderSearchEmptyDataset()
        ) : (
          <NewInlineModal.Dropdown.Items>
            {this.props.vendors.map(this.renderVendorItem)}
          </NewInlineModal.Dropdown.Items>
        )}
      </React.Fragment>
    );
  };

  renderFooter = () => {
    const { totalPages, currentPage } = this.props.vendorListPagination;
    if (this.state.selectedTab === 'vendor' && totalPages > 1) {
      return (
        <NewInlineModal.Footer>
          <NewInlineModal.Footer.Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onSelectPage={page => this.selectPage(page)}
          />
        </NewInlineModal.Footer>
      );
    }
    return null;
  };

  renderLoader = () => {
    return (
      <NewInlineModal.Dropdown.Items>
        <NewInlineModal.Dropdown.Item loading />
        <NewInlineModal.Dropdown.Item loading />
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderContent = () => {
    if (this.state.selectedTab === 'user')
      return this.props.isFetchingUsers ? this.renderLoader() : this.renderUsersTab();
    if (this.state.selectedTab === 'group')
      return this.props.isFetchingGroups ? this.renderLoader() : this.renderGroupsTab();
    if (this.state.selectedTab === 'vendor')
      return this.props.isFetchingVendors && !this.state.isSearchingVendors
        ? this.renderLoader()
        : this.renderVendorsTab();
    return null;
  };

  renderSearchField = () => {
    if (this.state.selectedTab === 'vendor' && this.state.totalVendors >= 10) {
      return (
        <NewInlineModal.Header.Search
          ref={ref => (this.inlineModalSearchRef = ref)}
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.vendorSearchTerm}
          onChange={value => {
            this.setState({ isSearchingVendors: true, vendorSearchTerm: value });
            this.searchVendors(value);
          }}
          onClear={() => {
            this.setState({ isSearchingVendors: true, vendorSearchTerm: '' });
            this.searchVendors('');
          }}
        />
      );
    } else if (this.state.selectedTab === 'user') {
      return (
        <NewInlineModal.Header.Search
          ref={ref => (this.inlineModalSearchRef = ref)}
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.userSearchTerm}
          onChange={value => this.userSearchTextWasChanged(value)}
          onClear={() => this.userSearchTextWasChanged('')}
          rightLabel={
            <Button
              type="text"
              fontSize={12}
              primary
              label="general.more-options"
              noUnderline
              onClick={() => this.setState({ isOpen: false, showSelectUserModal: true })}
            />
          }
        />
      );
    } else if (this.state.selectedTab === 'group' && this.props.groups.length >= 10) {
      return (
        <NewInlineModal.Header.Search
          ref={ref => (this.inlineModalSearchRef = ref)}
          placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
          value={this.state.groupSearchTerm}
          onChange={value => this.groupSearchTextWasChanged(value)}
          onClear={() => this.groupSearchTextWasChanged('')}
        />
      );
    }

    return null;
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalTriggerRef = ref)} onClick={this.toggle}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalTriggerRef}
          open={this.state.isOpen}
          position={this.props.position}
          onClose={() => {
            this.props.onClose();
            this.setState({ isOpen: false });
          }}
          minWidth={335}
        >
          <React.Fragment>
            <NewInlineModal.Header>
              <NewInlineModal.Header.Tabs
                tabs={this.getTabs()}
                selectedTab={this.state.selectedTab}
                onSelectTab={id => this.selectTab(id)}
              />
              {this.renderSearchField()}
            </NewInlineModal.Header>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
            {this.renderFooter()}
          </React.Fragment>
        </NewInlineModal>
        <SelectUserModal
          open={this.state.showSelectUserModal}
          members
          multiple={this.props.multiple}
          hideArchived={this.props.hideArchivedUsers}
          selectUserId={this.props.selectUserId}
          selectedUserIds={this.props.selectedUserIds}
          onAddUser={user => {
            this.selectUser(user);
          }}
          onRemoveUser={user => {
            this.selectUser(user);
          }}
          onSelectUser={user => {
            this.setState({ showSelectUserModal: false });
            this.selectUser(user);
          }}
          onClose={() => this.setState({ showSelectUserModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: WorkOrderAssigneeOperations.fetchUsers,
      fetchGroups: WorkOrderAssigneeOperations.fetchGroups,
      fetchVendors: WorkOrderAssigneeOperations.fetchVendors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    users: WorkOrderAssigneeSelectors.getUsers(state),
    groups: WorkOrderAssigneeSelectors.getGroups(state),
    vendors: WorkOrderAssigneeSelectors.getVendors(state),
    isFetchingUsers: WorkOrderAssigneeSelectors.isFetchingUsers(state),
    isFetchingGroups: WorkOrderAssigneeSelectors.isFetchingGroups(state),
    isFetchingVendors: WorkOrderAssigneeSelectors.isFetchingVendors(state),
    vendorListPagination: WorkOrderAssigneeSelectors.getVendorListPagination(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewVendors: AuthSelectors.canViewVendors(state),
    selectedUsers: EntitySelectors.getUsers(state, ownProps.selectedUserIds),
    selectedGroups: EntitySelectors.getGroups(state, ownProps.selectedGroupIds),
    selectedVendors: EntitySelectors.getVendors(state, ownProps.selectedVendorIds),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseAssigneeInlineModal));

ChooseAssigneeInlineModal.propTypes = {
  selectedUserIds: PropTypes.array,
  selectedGroupIds: PropTypes.array,
  selectedVendorIds: PropTypes.array,
  onSelectUser: PropTypes.func,
  onSelectGroup: PropTypes.func,
  onSelectVendor: PropTypes.func,
  includeAssignedOperators: PropTypes.bool,
  hideGroups: PropTypes.bool,
  position: PropTypes.string,
  hideArchivedUsers: PropTypes.bool,
};

ChooseAssigneeInlineModal.defaultProps = {
  selectedUserIds: [],
  selectedGroupIds: [],
  selectedVendorIds: [],
  onAddUser: () => {},
  onRemoveUser: () => {},
  onAddGroup: () => {},
  onRemoveGroup: () => {},
  onAddVendor: () => {},
  onRemoveVendor: () => {},
  onClear: () => {},
  onClose: () => {},
  includeAssignedOperators: false,
  hideGroups: false,
  multiple: false,
  position: 'left',
  hideArchivedUsers: false,
};
