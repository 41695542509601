import types from './types';
import update from 'immutability-helper';
import { uniq, mergeWith } from 'lodash-es';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { MenuTypes } from 'state/ducks/menu';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  ids: [],
  navigatedTo: {
    search: {},
  },
  pagination: {
    totalEntries: 0,
    totalPages: 1,
  },
  showingAppliedFilters: false,
  showApplyFilterForField: null,
  selectedWorkOrders: {
    pageIsSelected: false,
    showSelectTotalEntries: false,
    totalEntriesAreSelected: false,
    ids: {},
  },
  queryParams: {
    page: 1,
    page_size: 25,
  },
  appliedFilters: [],
  filters: {},
  isFetching: false,
  calendar: {},
  instanceById: {},
  isDraggingWorkOrderId: null,
  isDraggingRecurringMaintenanceId: null,
  previewOverlay: {
    open: false,
    instanceId: null,
    workOrderId: null,
    top: 0,
    left: 0,
    width: 0,
  },
  showPreviewMoreWorkOrdersOverlay: {
    open: false,
    top: 0,
    left: 0,
    width: 0,
  },
  isFetchingCounts: false,
  counts: {
    awaiting_spare_parts: 0,
    on_occasion: 0,
    overdue: 0,
    this_month: 0,
    this_week: 0,
    today: 0,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case MenuTypes.RESET_LIST_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.SET_NAVIGATED_TO: {
      const { search } = action.payload;
      return update(state, {
        navigatedTo: {
          search: {
            $set: search,
          },
        },
      });
    }
    /*
      Filter
    */
    case types.SELECT_FILTER_TYPE: {
      return update(state, {
        showApplyFilterForField: {
          $set: action.payload,
        },
      });
    }
    case types.ADD_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        appliedFilters: {
          $apply: appliedFilters => uniq([...appliedFilters, key]),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.REMOVE_FILTER: {
      const { key, data } = action.payload;
      return update(state, {
        showingAppliedFilters: {
          $set: state.appliedFilters.filter(id => id !== key).length > 0,
        },
        showApplyFilterForField: {
          $set: null,
        },
        appliedFilters: {
          $apply: appliedFilters => appliedFilters.filter(id => id !== key),
        },
        filters: {
          $merge: data,
        },
      });
    }
    case types.SHOW_APPLIED_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: true,
        showApplyFilterForField: null,
      };
    }
    case types.RESET_FILTER: {
      return {
        ...state,
        showingAppliedFilters: false,
        appliedFilters: [],
        filters: INITIAL_STATE.filters,
      };
    }
    case types.SHOW_AVAILABLE_FILTERS: {
      return {
        ...state,
        showingAppliedFilters: false,
      };
    }
    /*
      MultiAction
    */
    case types.SELECT_PAGE: {
      if (state.selectedWorkOrders.pageIsSelected) {
        return update(state, {
          selectedWorkOrders: {
            ids: {
              $apply: () => {
                let ids = { ...state.selectedWorkOrders.ids };
                state.ids.forEach(id => {
                  delete ids[id];
                });
                return ids;
              },
            },
            pageIsSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        });
      }
      return update(state, {
        selectedWorkOrders: {
          ids: {
            $apply: ids => {
              return state.ids.reduce((acc, id) => {
                return {
                  ...acc,
                  [id]: true,
                };
              }, state.selectedWorkOrders.ids);
            },
          },
          pageIsSelected: {
            $apply: () => {
              return !state.selectedWorkOrders.pageIsSelected;
            },
          },
          showSelectTotalEntries: {
            $apply: () => {
              return !state.selectedWorkOrders.pageIsSelected;
            },
          },
        },
      });
    }
    case types.SELECT_WORK_ORDER: {
      const workOrderId = action.payload;
      if (state.selectedWorkOrders.ids[workOrderId]) {
        return update(state, {
          selectedWorkOrders: {
            ids: {
              $unset: [workOrderId],
            },
            totalEntriesAreSelected: {
              $set: false,
            },
            showSelectTotalEntries: {
              $set: false,
            },
            pageIsSelected: {
              $set: false,
            },
          },
        });
      } else {
        const allRowsAreSelected = state.ids
          .filter(id => id !== workOrderId)
          .every(id => state.selectedWorkOrders.ids[id] === true);
        return update(state, {
          selectedWorkOrders: {
            ids: {
              [workOrderId]: {
                $set: true,
              },
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedWorkOrders: {
          totalEntriesAreSelected: {
            $set: true,
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        selectedWorkOrders: {
          showSelectTotalEntries: {
            $set: false,
          },
          totalEntriesAreSelected: {
            $set: false,
          },
        },
      });
    }
    case types.RESET_SELECTED_WORK_ORDERS: {
      return update(state, {
        selectedWorkOrders: {
          $set: {
            ...INITIAL_STATE.selectedWorkOrders,
          },
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.FETCH_WORK_ORDER_COUNTS: {
      return {
        ...state,
        isFetchingCounts: true,
      };
    }
    case types.FETCH_WORK_ORDER_COUNTS_SUCCESS: {
      return {
        ...state,
        counts: action.payload,
        isFetchingCounts: false,
      };
    }
    case types.FETCH_WORK_ORDERS:
      return {
        ...state,
        isFetching: true,
      };

    case types.SET_LOADING:
      return { ...state, isFetching: true };

    case types.FETCH_WORK_ORDERS_SUCCESS: {
      const { ids, pagination } = action.payload;
      let allRowsAreSelected = ids.length > 0 && ids.every(id => state.selectedWorkOrders.ids[id] === true);
      if (state.selectedWorkOrders.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        ids: {
          $set: ids,
        },
        pagination: {
          $set: pagination,
        },
        isFetching: {
          $set: false,
        },
        selectedWorkOrders: {
          pageIsSelected: {
            $set: allRowsAreSelected,
          },
          showSelectTotalEntries: {
            $set: allRowsAreSelected,
          },
        },
      });
    }

    case types.DROP_CALENDAR_INSTANCE: {
      const { from, to, instanceId } = action.payload;
      return update(state, {
        calendar: calendar =>
          update(calendar, {
            [from]: fromDate =>
              update(fromDate || [], {
                $apply: fromDate => fromDate.filter(id => id !== instanceId),
              }),
            [to]: toDate =>
              update(toDate || [], {
                $push: [instanceId],
              }),
          }),
      });
    }
    case types.BEGIN_DRAG: {
      const { workOrderId, recurringMaintenanceId } = action.payload;
      return {
        ...state,
        isDraggingWorkOrderId: workOrderId == null ? null : workOrderId,
        isDraggingRecurringMaintenanceId: recurringMaintenanceId == null ? null : recurringMaintenanceId,
        previewOverlay: {
          ...state.previewOverlay,
          open: false,
        },
        showPreviewMoreWorkOrdersOverlay: {
          ...state.showPreviewMoreWorkOrdersOverlay,
          open: false,
        },
      };
    }
    case types.END_DRAG: {
      return {
        ...state,
        isDraggingWorkOrderId: null,
        isDraggingRecurringMaintenanceId: null,
      };
    }
    case types.SHOW_PREVIEW_OVERLAY: {
      const { top, left, width, instanceId } = action.payload;
      return {
        ...state,
        previewOverlay: {
          ...state.previewOverlay,
          open: true,
          instanceId,
          top,
          left,
          width,
        },
      };
    }
    case types.HIDE_PREVIEW_OVERLAY: {
      return {
        ...state,
        previewOverlay: {
          ...state.previewOverlay,
          open: false,
        },
      };
    }
    case types.SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY: {
      const { top, left, width, date } = action.payload;
      return {
        ...state,
        showPreviewMoreWorkOrdersOverlay: {
          ...state.showPreviewMoreWorkOrdersOverlay,
          open: true,
          top,
          left,
          width,
          date,
        },
      };
    }
    case types.HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY: {
      return {
        ...state,
        showPreviewMoreWorkOrdersOverlay: {
          ...state.showPreviewMoreWorkOrdersOverlay,
          open: false,
        },
        previewOverlay: {
          ...state.previewOverlay,
          open: false,
        },
      };
    }
    case types.FETCH_DATA_BETWEEN_DATES: {
      const { resetCalendar } = action.payload;
      let newState = { ...state, isFetching: true };
      if (resetCalendar) {
        newState = {
          ...newState,
          calendar: {},
        };
      }
      return newState;
    }
    case types.FETCH_DATA_BETWEEN_DATES_SUCCESS: {
      const { ids, instanceById } = action.payload;
      let newState = { ...state };
      ids.forEach(id => {
        const { date } = instanceById[id];
        newState = update(newState, {
          calendar: calendar =>
            update(calendar || {}, {
              [date]: date =>
                update(date || [], {
                  $apply: date => {
                    if (date.includes(id)) {
                      return date;
                    }
                    return [...date, id];
                  },
                }),
            }),
          instanceById: {
            $merge: instanceById,
          },
        });
      });
      return {
        ...newState,
        isFetching: false,
      };
    }
    case SDKReduxTypes.WORK_ORDER_CREATED: {
      const workOrder = action.payload.data;
      if (workOrder.operational_maintenance) {
        return state;
      }
      let date = workOrder.due_date;
      if (workOrder.status === WorkOrderStatus.Completed) {
        date = workOrder.completed_date;
      }
      if (date == null) {
        return update(state, {
          ids: ids =>
            update(ids || [], {
              $apply: () => {
                return [workOrder.id, ...ids];
              },
            }),
        });
      }
      return update(state, {
        calendar: calendar =>
          update(calendar, {
            [date]: date =>
              update(date || [], {
                $push: [workOrder.id],
              }),
          }),
        instanceById: instanceById =>
          update(instanceById || {}, {
            $merge: {
              [workOrder.id]: {
                id: workOrder.id,
                date,
                users: workOrder.assigned_to_users.map(({ id }) => id),
                groups: workOrder.assigned_to_groups.map(({ id }) => id),
                vendors: workOrder.assigned_to_vendors.map(({ id }) => id),
                work_order: workOrder.id,
                type: 'work_order',
              },
            },
          }),
        ids: ids =>
          update(ids || [], {
            $apply: () => {
              return [workOrder.id, ...ids];
            },
          }),
      });
    }
    case SDKReduxTypes.RECURRING_MAINTENANCE_CREATED: {
      const { data: recurringMaintenance } = action.payload;
      const workOrder = recurringMaintenance.generated_work_order;
      if (workOrder.operational_maintenance) {
        return state;
      }
      return update(state, {
        ids: ids =>
          update(ids || [], {
            $apply: () => {
              return uniq([workOrder.id, ...ids]);
            },
          }),
      });
    }
    default:
      return state;
  }
};
