import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal } from 'views/components/Shared/Layout';
import { Field, Button } from 'views/components/Shared/General';

class CompleteModal extends Component {
  state = {
    isSaving: false,
    comment: '',
  };

  shouldComponentUpdate(prevProps) {
    if (!prevProps.open && !this.props.open) return false;
    return true;
  }

  save = () => {
    this.setState({ isSaving: true });

    const params = {
      completed: true,
      completed_comment: this.state.comment,
    };

    this.props
      .updateStocktaking(this.props.match.params.id, params)
      .then(({ data }) => {
        this.setState({ isSaving: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderContent = () => (
    <React.Fragment>
      <Field label={<FormattedMessage id="resources.stocktaking.completed-comment" />}>
        <Field.Textarea
          value={this.state.comment}
          onChange={comment => {
            this.setState({ comment });
          }}
        />
      </Field>
      <p>
        <FormattedMessage
          id="screens.stocktaking-detail.complete-modal.description"
          values={{
            name: this.props.currentUser.name,
            date: moment().format('LLL'),
          }}
        />
      </p>
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={560}>
        <Modal.Header
          title={<FormattedMessage id="screens.stocktaking-detail.complete-modal.title" />}
          subtitle={<FormattedMessage id="screens.stocktaking-detail.complete-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              label="screens.stocktaking-detail.complete-modal.button"
              onClick={this.save}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateStocktaking: SDKReduxOperations.updateStocktaking,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompleteModal));
