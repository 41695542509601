import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage } from 'react-intl';
import { Field, EmptyDataSet, Button } from 'views/components/Shared/General';
import styles from './styles.module.scss';

class PurchaseOrderDeliveryMethod extends Component {
  renderPurchaseOrderDeliveryMethods = () => {
    if (this.props.purchaseOrderDeliveryMethods.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={
              <FormattedMessage id="screens.purchase-order.info.general-information.delivery-method-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="screens.purchase-order.info.general-information.delivery-method-empty-data-set.subtitle" />
            }
            button={
              <Button
                primary
                label="screens.purchase-order.info.general-information.delivery-method-empty-data-set.button"
                small
                onClick={() => this.props.history.push('/settings/purchase-orders/delivery-methods')}
              />
            }
            tiny
          />
        </div>
      );
    }
    return this.props.purchaseOrderDeliveryMethods.map(purchaseOrderDeliveryMethod => (
      <Field.Dropdown.Item
        key={purchaseOrderDeliveryMethod.id}
        selected={this.props.value === purchaseOrderDeliveryMethod.id}
        onClick={() => this.props.onChange(purchaseOrderDeliveryMethod.id)}
      >
        {purchaseOrderDeliveryMethod.title}
      </Field.Dropdown.Item>
    ));
  };

  render() {
    return (
      <Field.Dropdown
        clearable
        value={this.props.purchaseOrderDeliveryMethod ? this.props.purchaseOrderDeliveryMethod.title : ''}
        onClear={() => this.props.onChange(null)}
      >
        {this.renderPurchaseOrderDeliveryMethods()}
      </Field.Dropdown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrderDeliveryMethods: AuthSelectors.getPurchaseOrderDeliveryMethods(state),
    purchaseOrderDeliveryMethod: EntitySelectors.getPurchaseOrderDeliveryMethod(state, ownProps.value),
  };
}

export default withRouter(connect(mapStateToProps)(PurchaseOrderDeliveryMethod));
