import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import { getMinutesFromElapsedMinutes, getHoursFromElapsedMinutes } from 'sdk/WorkOrder';

class EstimatedTimeContainer extends Component {
  render() {
    let estimatedHrs = getHoursFromElapsedMinutes(this.props.estimatedTime);
    let estimatedMin = getMinutesFromElapsedMinutes(this.props.estimatedTime);

    if (estimatedHrs === 0 && estimatedMin === 0) return null;
    return (
      <span>
        {estimatedHrs > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-hrs" values={{ value: estimatedHrs }} />
        ) : null}
        <span> </span>
        {estimatedMin > 0 ? (
          <FormattedMessage id="screens.calendar.estimated-min" values={{ value: estimatedMin }} />
        ) : null}
      </span>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    estimatedTime: WorkOrdersSelectors.getEstimatedTimeForDay(state, ownProps.instances),
  };
}

export default connect(mapStateToProps)(EstimatedTimeContainer);
