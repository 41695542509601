import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { List } from 'views/components/Shared/General';
import { UploadingAttachmentContent } from 'views/components/Attachment';
import { AttachmentAssetContentSelectors } from 'state/ducks/attachmentAssetContent';
import { UploadProgressSelectors } from 'state/ducks/uploadProgress';
import { AuthSelectors } from 'state/ducks/auth';
import {
  AttachmentListItem,
  AttachmentOptionsInlineModal,
  SortInlineModal,
} from 'views/components/Attachment';
import { Button, WhiteCard, EmptyDataSet, Icon } from 'views/components/Shared/General';
import SearchImage from 'assets/images/EmptyDataSet/SearchSmall.png';
import AttachmentSubtitle from './AttachmentSubtitle';
import styles from './style.module.scss';

class AttachmentFiles extends Component {
  renderSubtitle = attachment => {
    if (!this.props.showRelated) return null;

    return <AttachmentSubtitle attachment={attachment} />;
  };

  renderListItem = attachment => {
    if (this.props.selectable) {
      return (
        <AttachmentListItem
          key={attachment.id}
          onClick={() => {
            this.props.onSelectAttachment(attachment);
          }}
          subtitle={this.renderSubtitle(attachment)}
          attachment={attachment}
          hideFolder
          hideCreatedAt
        />
      );
    } else {
      return (
        <AttachmentListItem
          key={attachment.id}
          attachment={attachment}
          hideFolder
          onClick={(_, attachmentVersion) => {
            HelperFunctions.openAttachment(attachmentVersion);
          }}
          subtitle={this.renderSubtitle(attachment)}
          buttons={
            !this.props.canEditAssets ? null : (
              <AttachmentOptionsInlineModal
                attachment={attachment}
                context="asset"
                callBackOnMoveAttachmentToFolder
                onMoveAttachmentToFolder={this.props.fetchCountForFolders}
                assetToRemoveFromAttachment={this.props.asset}
                isRemovingAttachment={this.props.isRemovingAttachmentFromAsset}
                isDeletingAttachment={this.props.isDeletingAttachment}
                onDeleteAttachment={this.props.deleteAttachment}
                onRemovedFromAsset={this.props.removeAssetFromAttachment}
                trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
              />
            )
          }
        />
      );
    }
  };

  renderSearchList = () => {
    if (!this.props.loading && this.props.searchAttachments.length === 0) {
      return (
        <div className={`${styles['files-container']}`}>
          <div className={`${styles['search-sort-container']}`}>
            <SortInlineModal onSort={this.props.onSortChange} sort={this.props.sort} />
          </div>
          <WhiteCard centerContent>
            <EmptyDataSet
              title={<FormattedMessage id="general.empty-data-set-search.title" />}
              subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
              image={SearchImage}
              tiny
              horizontal
              listContainer
            />
          </WhiteCard>
        </div>
      );
    }

    if (this.props.loading) {
      return (
        <div className={`${styles['files-container']}`}>
          <div className={`${styles['search-sort-container']}`}>
            <SortInlineModal onSort={this.props.onSortChange} sort={this.props.sort} />
          </div>
          <List>
            <AttachmentListItem loading />
          </List>
        </div>
      );
    } else {
      return (
        <div className={`${styles['files-container']}`}>
          <div className={`${styles['search-sort-container']}`}>
            <SortInlineModal onSort={this.props.onSortChange} sort={this.props.sort} />
          </div>
          <List>
            {this.props.searchAttachments.map(attachment => this.renderListItem(attachment))}
            {this.props.isLoadingMore ? <AttachmentListItem loading /> : null}
          </List>
        </div>
      );
    }
  };

  renderList = () => {
    if (
      !this.props.loading &&
      this.props.listAttachments.length === 0 &&
      this.props.uploadingAttachments.length === 0
    )
      return null;

    return (
      <div className={`${styles['files-container']}`}>
        <React.Fragment>
          <div className={styles['title-container']}>
            <p className={styles['title']}>
              <FormattedMessage id="components.asset-attachment-content.files-title" />
            </p>
            <SortInlineModal onSort={this.props.onSortChange} sort={this.props.sort} />
          </div>
          {this.props.loading ? (
            <List>
              <AttachmentListItem loading />
            </List>
          ) : (
            <List>
              {this.props.uploadingAttachments.map(uploadingAttachment => (
                <List.Item>
                  <UploadingAttachmentContent
                    namespace={this.props.asset.id}
                    uploadingAttachment={uploadingAttachment}
                  />
                </List.Item>
              ))}
              {this.props.listAttachments.map(attachment => this.renderListItem(attachment))}
              {this.props.isLoadingMore ? <AttachmentListItem loading /> : null}
            </List>
          )}
        </React.Fragment>
      </div>
    );
  };

  render() {
    if (this.props.searchList) {
      return this.renderSearchList();
    } else {
      return this.renderList();
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    uploadingAttachments: ownProps.searchList
      ? []
      : UploadProgressSelectors.getUploadingAttachmentsForFolder(
          state,
          ownProps.asset.id,
          ownProps.parentFolderId
        ),
    listAttachments: AttachmentAssetContentSelectors.getListAttachments(state),
    searchAttachments: AttachmentAssetContentSelectors.getSearchAttachments(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default connect(mapStateToProps)(AttachmentFiles);
