import HelperFunctions from 'utilities/HelperFunctions';
import { omit } from 'lodash-es';
import { HelperFunctions as SDKHelperFunctions } from 'sdk';
import { FILTER_TYPES } from 'views/scenes/Costs/FilterModal';

const getCosts = state => {
  const { costs, entities } = state;
  return costs.ids.map(id => entities.costById[id]);
};

const getQueryParameters = state => {
  const { costs } = state;
  return costs.queryParams;
};

const getCostsPagination = state => state.costs.pagination;

/*
  MassEdit
*/

const getSelectedCostIds = state => {
  return state.costs.selectedCosts.ids;
};

const getTotalEntriesIsSelected = state => {
  return state.costs.selectedCosts.totalEntriesAreSelected;
};

const getShowSelectTotalEntries = state => {
  return state.costs.selectedCosts.showSelectTotalEntries;
};

const getPageIsSelected = state => {
  return state.costs.selectedCosts.pageIsSelected;
};

const getSelectedCostsCount = state => {
  if (state.costs.selectedCosts.totalEntriesAreSelected) {
    return state.costs.pagination.totalEntries;
  }
  return Object.keys(state.costs.selectedCosts.ids).length;
};

/*
  Filter
*/

const getFilters = state => {
  const { costs } = state;
  return costs.filters;
};

const getFiltersAsQueryParams = state => {
  const { costs } = state;
  let costsFilters = { ...costs.filters };
  if (costs.appliedFilters.includes(FILTER_TYPES.Assignee)) {
    const assignee = costs.filters.work_order.assignee;
    const comparator = Object.keys(assignee)[0];
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const value = assignee[comparator];
      costsFilters = {
        ...costsFilters,
        work_order: {
          ...costsFilters.work_order,
          assignee: {
            [comparator]: value,
          },
        },
      };
    } else {
      const { userIds, groupIds, vendorIds } = assignee[comparator];
      const userIdsString = userIds?.length > 0 ? `${userIds.map(id => `u:${id}`).toString()},` : '';
      const groupIdsString = groupIds?.length > 0 ? `${groupIds.map(id => `g:${id}`).toString()},` : '';
      const vendorIdsString = vendorIds?.length > 0 ? `${vendorIds.map(id => `v:${id}`).toString()},` : '';
      costsFilters = {
        ...costsFilters,
        work_order: {
          ...costsFilters.work_order,
          assignee: {
            [comparator]: `${userIdsString}${groupIdsString}${vendorIdsString}`,
          },
        },
      };
    }
  }
  if (costs.appliedFilters.includes(FILTER_TYPES.Request)) {
    const { comparator, value } = costs.filters.work_order.requests.request_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const { request_id, ...requestsFilter } = costsFilters.work_order.requests;
      costsFilters = {
        ...costsFilters,
        work_order: {
          ...costsFilters.work_order,
          requests: {
            ...requestsFilter,
            exists: value,
          },
        },
      };
    }
  }
  if (costs.appliedFilters.includes(FILTER_TYPES.UsedSparePart)) {
    const { comparator, value } = costs.filters.spare_part_withdrawals.spare_part_id;
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      const { spare_part_id, ...sparePartWithdrawalsFilter } = costsFilters.spare_part_withdrawals;
      costsFilters = {
        ...costsFilters,
        spare_part_withdrawals: {
          ...sparePartWithdrawalsFilter,
          exists: value,
        },
      };
    }
  }
  if (costs.appliedFilters.includes(FILTER_TYPES.Interval)) {
    const { comparator, value } = costsFilters.work_order[FILTER_TYPES.Interval];
    if (comparator === SDKHelperFunctions.FILTER_COMPARABLES.Exists) {
      costsFilters = omit(
        {
          ...costsFilters,
          work_order: {
            ...costsFilters.work_order,
            recurring_maintenance_id: {
              exists: value,
            },
          },
        },
        'recurring_interval'
      );
    } else {
      costsFilters = {
        ...costsFilters,
        work_order: {
          ...costsFilters.work_order,
          recurring_interval: value,
        },
      };
    }
  }
  return HelperFunctions.buildFilter(costsFilters);
};

const getAppliedFilters = (state, key) => {
  const { costs } = state;
  return costs.appliedFilters;
};

const getFiltersForKey = (state, key) => {
  const { costs } = state;
  return costs.filters[key];
};

const isFiltering = state => {
  const { costs } = state;
  return costs.appliedFilters.length > 0;
};

const isFilteringOnKey = (state, key) => {
  const { costs } = state;
  return costs.appliedFilters.find(filter => filter == key) != null;
};

const showingAppliedFilters = state => {
  const { costs } = state;
  return costs.showingAppliedFilters;
};

const getSelectedFilterType = state => {
  const { costs } = state;
  return costs.showApplyFilterForField;
};

export default {
  getSelectedCostIds,
  getTotalEntriesIsSelected,
  getShowSelectTotalEntries,
  getPageIsSelected,
  getSelectedCostsCount,
  getCosts,
  getQueryParameters,
  getCostsPagination,
  getFilters,
  getFiltersAsQueryParams,
  getAppliedFilters,
  getFiltersForKey,
  isFiltering,
  showingAppliedFilters,
  getSelectedFilterType,
  isFilteringOnKey,
};
