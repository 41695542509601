import React, { Component } from 'react';
import { Switch, Route, matchPath } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { MenuOperations, MenuUtils } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { normalizeAsset } from 'sdk/Schemas';
import { BookmarkedAssetsDropdownOperations } from 'state/ducks/bookmarkedAssetsDropdown';
import { OperationalMaintenancesOperations } from 'state/ducks/operationalMaintenances';
import Templates from './Templates';
import Breaks from './Breaks';
import OperationalMaintenances from './OperationalMaintenances';
import OperatorMaintenanceDetail from './OperatorMaintenanceDetail';
import OperatorMaintenanceTemplateDetail from './OperatorMaintenanceTemplateDetail';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';

class OperationalMaintenancesContainer extends Component {
  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.OperationalMaintenances);
    const { list, asset_id } = queryString.parse(this.props.location.search);
    if (asset_id) {
      this.props.selectAssetId(asset_id);
      if (this.props.assetFromUrl == null) {
        API.fetchAsset(asset_id).then(({ data: asset }) => {
          const { entities } = normalizeAsset(asset);
          this.props.updateEntities(entities);
        });
      }
    }
    if (this.isExactPathActive('/operational-maintenances') && list == null) {
      const list = localStorage.getItem('operationalMaintenancesView') || 'calendar';
      this.props.history.replace({ pathname: '/operational-maintenances', search: `list=${list}` });
    }
  }

  componentDidUpdate(prevProps) {
    const { list: prevList } = queryString.parse(prevProps.location.search);
    const { list } = queryString.parse(this.props.location.search);
    const isCalendarOrToday = list === 'calendar' || list === 'today';
    if (this.isExactPathActive('/operational-maintenances') && list == null) {
      const list = localStorage.getItem('operationalMaintenancesView') || 'calendar';
      this.props.history.replace({ pathname: '/operational-maintenances', search: `list=${list}` });
    } else if (list !== prevList && isCalendarOrToday) {
      localStorage.setItem('operationalMaintenancesView', list);
    }
  }

  componentWillUnmount() {
    this.props.unitializeListCount();
  }

  isExactPathActive = path => {
    return (
      matchPath(this.props.location.pathname, {
        path,
        exact: true,
      }) != null
    );
  };

  render() {
    if (this.props.isOperator) {
      return (
        <Switch>
          <Route exact path="/operational-maintenances" component={OperationalMaintenances} />
          <Route exact path="/operational-maintenances/:id" component={OperatorMaintenanceDetail} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route exact path="/operational-maintenances" component={OperationalMaintenances} />
        <Route exact path="/operational-maintenances/templates" component={Templates} />
        <Route
          exact
          path="/operational-maintenances/templates/:id"
          component={OperatorMaintenanceTemplateDetail}
        />
        <Route exact path="/operational-maintenances/breaks" component={Breaks} />
        <Route exact path="/operational-maintenances/:id" component={OperatorMaintenanceDetail} />
      </Switch>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectMenuItem: MenuOperations.selectItem,
      unitializeListCount: OperationalMaintenancesOperations.unitializeListCount,
      selectAssetId: BookmarkedAssetsDropdownOperations.selectAssetId,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { asset_id } = queryString.parse(ownProps.location.search);
  return {
    assetFromUrl: EntitySelectors.getAsset(state, asset_id),
    isOperator: AuthSelectors.isOperator(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenancesContainer);
