import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import AnimateHeight from 'react-animate-height';
import toast from 'react-hot-toast';
import { ToastMessage, Modal, Grid } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { SsoOidcType } from 'sdk/OrganisationSettings';
import { Button, Field, NewInlineModal, Icon, Banner } from 'views/components/Shared/General';
import WellKnownModal from './WellKnownModal';
import styles from './style.module.scss';

const GeneralRedirectUri = `${process.env.REACT_APP_API_URL}oidc/general/callback`
const AzureRedirectUri = `${process.env.REACT_APP_API_URL}oidc/azure/callback`
const ScimEndpointUri = `${process.env.REACT_APP_BROWSER_URL}scim/v2/`

class EditSSOModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    dropdownOpen: false,
    openWellKnownModal: false,
    azureRedirectUriCopied: false,
    generalRedirectUriCopied: false,
    scimEndpointUriCopied: false,
    scimSecretKeyCopyable: false,
    scimSecretKeyCopied: false,
    sso_oidc_type: SsoOidcType.Azure,
    sso_oidc_azure_client_id: '',
    sso_oidc_azure_client_secret: '',
    sso_oidc_azure_tenant_id: '',
    sso_oidc_general_client_id: '',
    sso_oidc_general_client_secret: '',
    sso_oidc_general_authorize_url: '',
    sso_oidc_general_token_url: '',
    sso_oidc_general_user_info_url: '',
    sso_oidc_general_pkce_activated: false,
    scim_activated: false,
    scim_secret_key: this.props.organisationSettings.scim_secret_key_exists ? '***************' : '',
    errors: {
      sso_oidc_azure_client_id: false,
      sso_oidc_azure_client_secret: false,
      sso_oidc_azure_tenant_id: false,
      sso_oidc_general_client_id: false,
      sso_oidc_general_client_secret: false,
      sso_oidc_general_authorize_url: false,
      sso_oidc_general_token_url: false,
      sso_oidc_general_user_info_url: false,
      sso_oidc_general_pkce_activated: false,
      scim_secret_key: false,
    },
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const {
        sso_oidc_type,
        sso_oidc_azure_client_id,
        sso_oidc_azure_tenant_id,
        sso_oidc_general_client_id,
        sso_oidc_general_authorize_url,
        sso_oidc_general_token_url,
        sso_oidc_general_user_info_url,
        sso_oidc_general_pkce_activated,
        scim_activated,
        scim_secret_key,
      } = this.props.organisationSettings;
      this.setState({
        ...this.getInitialState(),
        sso_oidc_type: sso_oidc_type || SsoOidcType.Azure,
        sso_oidc_azure_client_id: sso_oidc_azure_client_id || '',
        sso_oidc_azure_client_secret: '',
        sso_oidc_azure_tenant_id: sso_oidc_azure_tenant_id || '',
        sso_oidc_general_client_id: sso_oidc_general_client_id || '',
        sso_oidc_general_client_secret: '',
        sso_oidc_general_authorize_url: sso_oidc_general_authorize_url || '',
        sso_oidc_general_token_url: sso_oidc_general_token_url || '',
        sso_oidc_general_user_info_url: sso_oidc_general_user_info_url || '',
        sso_oidc_general_pkce_activated: sso_oidc_general_pkce_activated || false,
        scim_activated: scim_activated || false,
        scim_secret_key: scim_secret_key || (this.props.organisationSettings.scim_secret_key_exists ? '***************' : ''),
      });
    }
  }

  hasErrors = () => {
    const {
      sso_oidc_type,
      sso_oidc_azure_client_id,
      sso_oidc_azure_client_secret,
      sso_oidc_azure_tenant_id,
      sso_oidc_general_client_id,
      sso_oidc_general_client_secret,
      sso_oidc_general_authorize_url,
      sso_oidc_general_token_url,
      sso_oidc_general_user_info_url,
    } = this.state;
    let errors = {};
    if (sso_oidc_type === SsoOidcType.Azure) {
      if (sso_oidc_azure_client_id.length === 0) {
        errors = {
          ...errors,
          sso_oidc_azure_client_id: true,
        };
      }
      if (
        this.props.organisationSettings.sso_oidc_activated === false &&
        sso_oidc_azure_client_secret.length === 0
      ) {
        errors = {
          ...errors,
          sso_oidc_azure_client_secret: true,
        };
      }
      if (sso_oidc_azure_tenant_id.length === 0) {
        errors = {
          ...errors,
          sso_oidc_azure_tenant_id: true,
        };
      }
    } else {
      if (sso_oidc_general_client_id.length === 0) {
        errors = {
          ...errors,
          sso_oidc_general_client_id: true,
        };
      }
      if (
        this.props.organisationSettings.sso_oidc_activated === false &&
        sso_oidc_general_client_secret.length === 0
      ) {
        errors = {
          ...errors,
          sso_oidc_general_client_secret: true,
        };
      }
      if (sso_oidc_general_authorize_url.length === 0) {
        errors = {
          ...errors,
          sso_oidc_general_authorize_url: true,
        };
      }
      if (sso_oidc_general_token_url.length === 0) {
        errors = {
          ...errors,
          sso_oidc_general_token_url: true,
        };
      }
      if (sso_oidc_general_user_info_url.length === 0) {
        errors = {
          ...errors,
          sso_oidc_general_user_info_url: true,
        };
      }
    }

    if (this.state.scim_activated) {
      if (this.state.scim_secret_key.length === 0) {
        errors = {
          ...errors,
          scim_secret_key: true,
        };
      }
    }

    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    });
    return Object.keys(errors).length > 0;
  };

  save = () => {
    if (this.hasErrors()) {
      return;
    }
    const {
      sso_oidc_type,
      sso_oidc_azure_client_id,
      sso_oidc_azure_client_secret,
      sso_oidc_azure_tenant_id,
      sso_oidc_general_client_id,
      sso_oidc_general_client_secret,
      sso_oidc_general_authorize_url,
      sso_oidc_general_token_url,
      sso_oidc_general_user_info_url,
      sso_oidc_general_pkce_activated,
      scim_activated,
      scim_secret_key,
    } = this.state;

    this.setState({ isSaving: true });
    let params = {
      sso_oidc_activated: true,
    };
    if (sso_oidc_type === SsoOidcType.Azure) {
      params = {
        ...params,
        sso_oidc_type: SsoOidcType.Azure,
        sso_oidc_azure_client_id,
        sso_oidc_azure_tenant_id,
      };

      if (sso_oidc_azure_client_secret) {
        params = { ...params, sso_oidc_azure_client_secret };
      }
    } else {
      params = {
        ...params,
        sso_oidc_type: SsoOidcType.General,
        sso_oidc_general_client_id,
        sso_oidc_general_authorize_url,
        sso_oidc_general_token_url,
        sso_oidc_general_user_info_url,
        sso_oidc_general_pkce_activated,
      };

      if (sso_oidc_general_client_secret) {
        params = { ...params, sso_oidc_general_client_secret };
      }
    }
    if (scim_activated) {
      params = {
        ...params,
        scim_activated: true,
        scim_secret_key,
      };
    }

    this.props
      .updateOrganisationSettings(this.props.organisation.id, params)
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(() => {
        this.setState({ isSaving: false });
      });
  };

  generateScimSecretKey = () => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    this.setState({ scim_secret_key: result, scimSecretKeyCopyable: true })
  }

  renderMultipleSystemsAlert = () => {
    if (this.props.organisation.multiple_systems === true) {
      return (
        <Grid.Row>
          <Grid.Column>
            <Banner orange>
              <FormattedMessage id="screens.settings.integrations.edit-sso-modal.multiple-systems-alert-message" />
            </Banner>
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  };

  renderFields = () => {
    if (this.state.sso_oidc_type === SsoOidcType.Azure) {
      return (
        <>
          <Grid.Row>
            <Grid.Column>
              <Field
                view={false}
                label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-client-id" />}
                description={
                  <FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-client-id-description" />
                }
              >
                <Field.Text
                  error={this.state.errors.sso_oidc_azure_client_id}
                  value={this.state.sso_oidc_azure_client_id}
                  onChange={sso_oidc_azure_client_id => {
                    this.setState({
                      sso_oidc_azure_client_id,
                      errors: {
                        ...this.state.errors,
                        sso_oidc_azure_client_id: false,
                      },
                    });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view={false}
                label={
                  <FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-client-secret" />
                }
                description={
                  <FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-client-secret-description" />
                }
              >
                <Field.Text
                  value={this.state.sso_oidc_azure_client_secret}
                  placeholder={
                    this.props.organisationSettings.sso_oidc_azure_client_secret_exists ? '******' : null
                  }
                  error={this.state.errors.sso_oidc_azure_client_secret}
                  onChange={sso_oidc_azure_client_secret => {
                    this.setState({
                      sso_oidc_azure_client_secret,
                      errors: {
                        ...this.state.errors,
                        sso_oidc_azure_client_secret: false,
                      },
                    });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                view={false}
                label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-tenant-id" />}
                description={
                  <FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure-tenant-id-description" />
                }
              >
                <Field.Text
                  error={this.state.errors.sso_oidc_azure_tenant_id}
                  value={this.state.sso_oidc_azure_tenant_id}
                  onChange={sso_oidc_azure_tenant_id => {
                    this.setState({
                      sso_oidc_azure_tenant_id,
                      errors: {
                        ...this.state.errors,
                        sso_oidc_azure_tenant_id: false,
                      },
                    });
                  }}
                />
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field
                view={false}
                label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.redirect-uri" />}
                description={
                  <FormattedMessage id="screens.settings.integrations.edit-sso-modal.redirect-uri-description" />
                }
              >
                <div className={styles['field-with-button']}>
                  <div className={styles['field']}>
                    <Field.Text value={AzureRedirectUri} />
                  </div>
                  <div className={styles['button']}>
                    <Button
                      gray
                      label={this.state.azureRedirectUriCopied ? 'general.copied' : 'general.copy'}
                      onClick={() => {
                        navigator.clipboard.writeText(AzureRedirectUri);
                        this.setState({ azureRedirectUriCopied: true });
                        setTimeout(() => {
                          this.setState({ azureRedirectUriCopied: false });
                        }, 3000);
                      }}
                    />
                  </div>
                </div>
              </Field>
            </Grid.Column>
          </Grid.Row>
        </>
      );
    }
    return (
      <>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-client-id" />}
            >
              <Field.Text
                error={this.state.errors.sso_oidc_general_client_id}
                value={this.state.sso_oidc_general_client_id}
                onChange={sso_oidc_general_client_id => {
                  this.setState({
                    sso_oidc_general_client_id,
                    errors: {
                      ...this.state.errors,
                      sso_oidc_general_client_id: false,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-client-secret" />
              }
            >
              <Field.Text
                error={this.state.errors.sso_oidc_general_client_secret}
                placeholder={
                  this.props.organisationSettings.sso_oidc_general_client_secret_exists ? '******' : null
                }
                value={this.state.sso_oidc_general_client_secret}
                onChange={sso_oidc_general_client_secret => {
                  this.setState({
                    sso_oidc_general_client_secret,
                    errors: {
                      ...this.state.errors,
                      sso_oidc_general_client_secret: false,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <div>
              <Banner orange>
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.add-metadata-info-banner" />
              </Banner>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              gray
              label="screens.settings.integrations.edit-sso-modal.add-metadata"
              onClick={() => {
                this.setState({ openWellKnownModal: true });
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-auth-url" />}
            >
              <Field.Text
                placeholder="https://"
                error={this.state.errors.sso_oidc_general_authorize_url}
                value={this.state.sso_oidc_general_authorize_url}
                onChange={sso_oidc_general_authorize_url => {
                  this.setState({
                    sso_oidc_general_authorize_url,
                    errors: {
                      ...this.state.errors,
                      sso_oidc_general_authorize_url: false,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-token-url" />}
            >
              <Field.Text
                placeholder="https://"
                error={this.state.errors.sso_oidc_general_token_url}
                value={this.state.sso_oidc_general_token_url}
                onChange={sso_oidc_general_token_url => {
                  this.setState({
                    sso_oidc_general_token_url,
                    errors: {
                      ...this.state.errors,
                      sso_oidc_general_token_url: false,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-user-info-url" />
              }
            >
              <Field.Text
                placeholder="https://"
                error={this.state.errors.sso_oidc_general_user_info_url}
                value={this.state.sso_oidc_general_user_info_url}
                onChange={sso_oidc_general_user_info_url => {
                  this.setState({
                    sso_oidc_general_user_info_url,
                    errors: {
                      ...this.state.errors,
                      sso_oidc_general_user_info_url: false,
                    },
                  });
                }}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              checked={this.state.sso_oidc_general_pkce_activated}
              onChange={() =>
                this.setState(prevState => ({
                  sso_oidc_general_pkce_activated: !prevState.sso_oidc_general_pkce_activated,
                }))
              }
              label={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-activate-pkce" />
              }
              questionTooltipContent={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-activate-pkce_tooltip" />
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.redirect-uri" />}
              description={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.redirect-uri-description" />
              }
            >
              <div className={styles['field-with-button']}>
                <div className={styles['field']}>
                  <Field.Text value={GeneralRedirectUri} />
                </div>
                <div className={styles['buttons']}>
                  <Button
                    gray
                    label={this.state.generalRedirectUriCopied ? 'general.copied' : 'general.copy'}
                    onClick={() => {
                      navigator.clipboard.writeText(GeneralRedirectUri);
                      this.setState({ generalRedirectUriCopied: true });
                      setTimeout(() => {
                        this.setState({ generalRedirectUriCopied: false });
                      }, 3000);
                    }}
                  />
                </div>
              </div>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  };

  renderScimFields = () => (
    <>
      <Grid.Row>
        <Grid.Column>
          <Field.Checkbox
            checked={this.state.scim_activated}
            onChange={() =>
              this.setState(prevState => ({
                scim_activated: !prevState.scim_activated,
              }))
            }
            label={
              <div>
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.activate-scim" />
                <div className={styles['beta-tag']}>
                  <FormattedMessage id="general.beta" />
                </div>
              </div>
            }
          />
        </Grid.Column>
      </Grid.Row>
      <AnimateHeight height={this.state.scim_activated ? 'auto' : 0}>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.scim-endpoint-uri" />}
            >
              <div className={styles['field-with-button']}>
                <div className={styles['field']}>
                  <Field.Text value={ScimEndpointUri} />
                </div>
                <div className={styles['buttons']}>
                  <Button
                    gray
                    label={this.state.scimEndpointUriCopied ? "general.copied" : "general.copy"}
                    onClick={() => {
                      navigator.clipboard.writeText(ScimEndpointUri)
                      this.setState({ scimEndpointUriCopied: true })
                      setTimeout(() => {
                        this.setState({ scimEndpointUriCopied: false })
                      }, 3000);
                    }}
                  />
                </div>
              </div>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.scim-secret-key" />}
              description={
                <FormattedMessage id="screens.settings.integrations.edit-sso-modal.scim-secret-key-description" />
              }
            >
              <div className={styles['field-with-button']}>
                <div className={styles['field']}>
                  <Field.Text
                    error={this.state.errors.scim_secret_key}
                    value={this.state.scim_secret_key}
                  />
                </div>
                <div className={styles['buttons']}>
                  <Button
                    gray
                    label="screens.settings.integrations.edit-sso-modal.scim-secret-key-generate"
                    onClick={this.generateScimSecretKey}
                  />
                  <Button
                    gray
                    disabled={!this.state.scimSecretKeyCopyable}
                    label={this.state.scimSecretKeyCopied ? "general.copied" : "general.copy"}
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.scim_secret_key)
                      this.setState({ scimSecretKeyCopied: true })
                      setTimeout(() => {
                        this.setState({ scimSecretKeyCopied: false })
                      }, 3000);
                    }}
                  />
                </div>
              </div>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </AnimateHeight>
    </>
  )

  renderContent = () => {
    return (
      <Grid>
        {this.renderMultipleSystemsAlert()}
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox.Group>
              <Field.Radio
                label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.azure" />}
                checked={this.state.sso_oidc_type === SsoOidcType.Azure}
                onChange={() => this.setState({ sso_oidc_type: SsoOidcType.Azure })}
              />
              <Field.Radio
                label={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.general-idp" />}
                checked={this.state.sso_oidc_type === SsoOidcType.General}
                onChange={() => this.setState({ sso_oidc_type: SsoOidcType.General })}
              />
            </Field.Checkbox.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        {this.renderFields()}
        <Grid.Separator />
        {this.renderScimFields()}
      </Grid>
    );
  };

  renderIconButtons = () => {
    if (this.props.organisationSettings.sso_oidc_activated === false) {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={235}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item onClick={this.props.onDelete} destructive>
                <FormattedMessage id="general.deactivate" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderWellKnownModal = () => (
    <WellKnownModal
      open={this.state.openWellKnownModal}
      onClose={() => {
        this.setState({ openWellKnownModal: false });
      }}
      onSave={data => {
        this.setState({
          openWellKnownModal: false,
          sso_oidc_general_authorize_url: data.sso_oidc_general_authorize_url,
          sso_oidc_general_token_url: data.sso_oidc_general_token_url,
          sso_oidc_general_user_info_url: data.sso_oidc_general_user_info_url,
          errors: {
            ...this.state.errors,
            sso_oidc_general_user_info_url: false,
            sso_oidc_general_token_url: false,
            sso_oidc_general_user_info_url: false,
          },
        });
      }}
    />
  );

  render() {
    return (
      <>
        <Modal isOpen={this.props.open && !this.state.openWellKnownModal} width={600}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.settings.integrations.edit-sso-modal.title" />}
            onClose={this.props.onClose}
            iconButtons={this.renderIconButtons()}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                label={
                  this.props.organisationSettings.sso_oidc_activated ? 'general.save' : 'general.activate'
                }
                loading={this.state.isSaving}
                onClick={this.save}
              />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderWellKnownModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationSettings: SDKReduxOperations.updateOrganisationSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
    organisationSettings: AuthSelectors.getOrganisationSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditSSOModal));
