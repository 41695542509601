import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, WhiteCard } from 'views/components/Shared/General';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import HelperFunctions from 'utilities/HelperFunctions';
import Header from '../../../Header';
import { ContentContainer } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IMAGE_SV from 'assets/images/ProScreenshots/WorkOrderPurchase PRO (SV).png';
import IMAGE_EN from 'assets/images/ProScreenshots/WorkOrderPurchase PRO (EN).png';
import styles from './style.module.scss';

class CalendarBasic extends Component {
  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.calendar.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Calendar);
  }

  render() {
    return (
      <>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>
            <WhiteCard centerContent padding="60px">
              <div className={styles['container']}>
                <div className={styles['title']}>
                  <FormattedMessage id="screens.purchase-order.work-orders.basic.title" />
                </div>
                <div className={styles['subtitle']}>
                  <FormattedMessage id="screens.purchase-order.work-orders.basic.subtitle" />
                </div>
                <div className={styles['upgrade-screenshot-container']}>
                  <img
                    className={styles['upgrade-screenshot']}
                    alt="screenshot"
                    src={this.props.language === 'sv' ? IMAGE_SV : IMAGE_EN}
                  />
                </div>
                <div className={styles['upgrade-buttons-container']}>
                  <Button upgradeToPro label="general.upgrade-to-pro" />
                </div>
              </div>
            </WhiteCard>
          </ContentContainer>
        </PerfectScrollbar>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CalendarBasic));
