import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { PurchaseOrdersSelectors } from 'state/ducks/purchaseOrders';
import styles from './styles.module.scss';

class ExportModal extends Component {
  getInitialState = () => ({
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showQuantityExceeded:
          this.props.checkedPurchaseOrderCount >= maxQuantityAllowedForType(this.props.exportType),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      return {
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
      };
    }
    return {
      id: {
        any: Object.keys(this.props.checkedPurchaseOrderIds).toString(),
      },
    };
  };

  export = () => {
    this.setState({ isExporting: true });
    API.createExport(this.props.system.id, {
      params: this.buildFilterParams(),
      type: ExportType.PurchaseOrdersXslx,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.checkedPurchaseOrderCount}
        one={<FormattedMessage id="components.export-modal.total-purchase-orders.one" />}
        two={
          <FormattedMessage
            id="components.export-modal.total-purchase-orders.two"
            values={{ count: this.props.checkedPurchaseOrderCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.export-modal.total-purchase-orders.few"
            values={{ count: this.props.checkedPurchaseOrderCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.export-modal.total-purchase-orders.many"
            values={{ count: this.props.checkedPurchaseOrderCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.export-modal.total-purchase-orders.other"
            values={{ count: this.props.checkedPurchaseOrderCount }}
          />
        }
      />
    );
  };

  renderMaxQuantityErrorMessage = () => {
    if (this.props.exportType === ExportType.PurchaseOrdersXslx) {
      return <FormattedMessage id="components.export-modal.excel-quantity-exceeded" />;
    }
    return null;
  };

  renderContent = () => {
    if (this.state.showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>{this.renderMaxQuantityErrorMessage()}</div>
        </Modal.Content>
      );
    }
    if (this.state.isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={this.state.showExportLoaderForId} />
        </Modal.Content>
      );
    }
    return null;
  };

  renderFooter = () => {
    if (this.state.showQuantityExceeded) {
      return null;
    }
    if (this.state.isExporting === false) {
      return (
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" onClick={this.export} />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.export-modal.title-xslx" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: PurchaseOrdersSelectors.getQueryParameters(state),
    filtersAsQueryParams: PurchaseOrdersSelectors.getFiltersAsQueryParams(state),
    checkedPurchaseOrderIds: PurchaseOrdersSelectors.getSelectedPurchaseOrderIds(state),
    checkedPurchaseOrderCount: PurchaseOrdersSelectors.getSelectedPurchaseOrderCount(state),
    totalEntriesIsSelected: PurchaseOrdersSelectors.getTotalEntriesIsSelected(state),
  };
}

export default connect(mapStateToProps)(ExportModal);

ExportModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.PurchaseOrdersXslx]),
};

ExportModal.defaultProps = {
  exportType: ExportType.PurchaseOrdersXslx,
};
