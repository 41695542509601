import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEqual, debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { List, Pagination, Field, FilterButton } from 'views/components/Shared/General';
import { ListLayout } from 'views/components/Shared/Layout';
import { ChecklistModal } from 'views/components/Checklist';
import { BookmarkedAssetsDropdownSelectors } from 'state/ducks/bookmarkedAssetsDropdown';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { ChooseAssetInlineModal, ProductionSupervisorChooseAssetInlineModal } from 'views/components/Asset';
import { BookmarkedAssetsDropdown } from 'views/components/Asset';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import { MenuOperations } from 'state/ducks/menu';
import { MenuUtils } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import queryString from 'query-string';
import ChecklistInstanceListItem from './ChecklistInstanceListItem';
import ChecklistsImage from 'assets/images/EmptyDataSet/Checklists.png';
import styles from './style.module.scss';

class Deviations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      checklistInstanceIds: [],
      pagination: {
        totalEntries: 0,
        totalPages: 1,
      },
      queryParameters: {
        page: 1,
        page_size: 25,
      },
      deviation_assigned_to_me: false,
      asset_with_tree_children_id: null,
      navigatedToChecklistModalForList: 'active',
    };
    this.fetchDebouncedChecklistInstances = debounce(() => {
      this.listChecklistInstances();
    }, 300);
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.checklists.deviations' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Checklists);
    this.listChecklistInstances();
  }

  componentDidUpdate(prevProps, prevState) {
    const { list: oldList } = queryString.parse(prevProps.location.search);
    const { list: newList } = queryString.parse(this.props.location.search);
    const changedQueryParams = !isEqual(prevState.queryParameters, this.state.queryParameters);
    const changedList = oldList != null && newList != null && oldList !== newList;
    const navigatedFromDeviationDetail =
      prevProps.match.params.id != null && this.props.match.params.id == null;
    const changedSearchTerm = prevProps.searchTerm !== this.props.searchTerm;
    const changedAssignedToMe = prevState.deviation_assigned_to_me !== this.state.deviation_assigned_to_me;
    const changedAsset = prevState.asset_with_tree_children_id !== this.state.asset_with_tree_children_id;
    const changedAssetDropdownParams = prevProps.assetDropdownParams !== this.props.assetDropdownParams;
    if (
      navigatedFromDeviationDetail ||
      changedAsset ||
      changedAssignedToMe ||
      changedSearchTerm ||
      changedList ||
      changedQueryParams ||
      changedAssetDropdownParams
    ) {
      this.setState({ isFetching: true });
      this.fetchDebouncedChecklistInstances();
    }
    if (navigatedFromDeviationDetail) {
      if (newList === 'active') {
        this.props.onUpdateActiveDeviationsCount();
      }
    }
  }

  listChecklistInstances = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { asset_with_tree_children_id, deviation_assigned_to_me } = this.state;
    this.setState({ isFetching: true });

    let attrs = {
      ...this.state.queryParameters,
      deviation_require_action: false,
    };
    if (this.props.searchTerm) {
      attrs = {
        ...attrs,
        search: this.props.searchTerm,
      };
    }
    if (list === 'active' || this.props.match.params.id) {
      attrs = {
        ...attrs,
        deviation_require_action: true,
      };
    }
    if (asset_with_tree_children_id) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id,
      };
    }
    if (this.props.isProductionSupervisor && this.props.assetDropdownParams) {
      attrs = {
        ...attrs,
        asset_with_tree_children_id: this.props.assetDropdownParams,
      };
    }
    if (deviation_assigned_to_me) {
      attrs = {
        ...attrs,
        deviation_assigned_to_me: true,
      };
    }

    return API.listChecklistInstancesForSystem(this.props.system.id, attrs)
      .then(({ data: checklistInstances, headers }) => {
        const pagination = SDKHelperFunctions.getPaginationFromHeader(headers);
        const { entities, result: checklistInstanceIds } = normalizeChecklistInstance(checklistInstances);
        this.props.updateEntities(entities);
        this.setState({ isFetching: false, checklistInstanceIds, pagination });
        this.props.onUpdateTotalEntries(headers['total-entries']);
      })
      .catch(e => {});
  };

  renderPagination = () => {
    if (this.state.checklistInstanceIds.length === 0) {
      return null;
    }
    return (
      <ListLayout.Content.MainContent.Pagination>
        <Pagination
          blue
          currentPage={this.state.queryParameters.page}
          totalPages={this.state.pagination.totalPages}
          pageSize={this.state.queryParameters.page_size}
          onSelectPage={page => {
            this.setState({ queryParameters: { ...this.state.queryParameters, page }, isFetching: true });
          }}
          onChangePageSize={page_size => {
            this.setState({
              queryParameters: { ...this.state.queryParameters, page_size },
              isFetching: true,
            });
          }}
        />
      </ListLayout.Content.MainContent.Pagination>
    );
  };

  renderEmptyDataSetTitle = () => {
    const { list } = queryString.parse(this.props.location.search);
    if (list === 'active') {
      return <FormattedMessage id="screens.checklists.active-deviations-empty-data-set.title" />;
    }
    return <FormattedMessage id="screens.checklists.history-deviations-empty-data-set.title" />;
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>{this.renderEmptyDataSetTitle()}</div>
      <div className={styles['image-container']}>
        <img src={ChecklistsImage} alt="" />
      </div>
    </div>
  );

  renderSearchedEmptyDataset = () => (
    <div className={styles['empty-data-set-container']}>
      <div className={styles['title']}>
        <FormattedMessage id="general.empty-data-set-search.title" />
      </div>
      <div className={styles['subtitle']}>
        <FormattedMessage id="general.empty-data-set-search.subtitle" />
      </div>
      <div className={styles['image-container']}>
        <img src={ChecklistsImage} alt="" />
      </div>
    </div>
  );

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.checklist-instance.title" />
        </List.Header.Column>
        <List.Header.Column width={250}>
          <FormattedMessage id="resources.checklist-instance.completed-date" />
        </List.Header.Column>
        <List.Header.Column width={200}>
          <FormattedMessage id="resources.checklist-instance.assignee" />
        </List.Header.Column>
        <List.Header.Column width={150} />
      </List.Header>
    );
  };

  renderList = () => {
    if (this.state.checklistInstanceIds.length === 0) {
      if (this.props.searchTerm.length === 0) {
        return this.renderEmptyDataSet();
      } else {
        return this.renderSearchedEmptyDataset();
      }
    }
    return (
      <>
        {this.renderListHeader()}
        <List small>
          {this.state.checklistInstanceIds.map(checklistIntanceId => (
            <ChecklistInstanceListItem
              id={checklistIntanceId}
              loading={this.state.isFetching}
              key={checklistIntanceId}
              onClick={() => {
                const { list } = queryString.parse(this.props.location.search);
                this.setState({
                  navigatedToChecklistModalForList: list,
                });
              }}
            />
          ))}
        </List>
      </>
    );
  };

  renderAssetFilterTrigger = () => {
    return (
      <FilterButton
        label={<FormattedMessage id="resources.asset.resource" />}
        filtered={this.state.asset_with_tree_children_id != null}
        onClear={e => {
          e.stopPropagation();
          this.setState({ asset_with_tree_children_id: null });
        }}
      />
    );
  };

  renderTopBar = () => {
    if (this.props.isProductionSupervisor) {
      return (
        <ListLayout.Content.MainContent.FilterBar>
          <ListLayout.Content.MainContent.FilterBar.LeftContent>
            <BookmarkedAssetsDropdown />
          </ListLayout.Content.MainContent.FilterBar.LeftContent>
        </ListLayout.Content.MainContent.FilterBar>
      );
    }
    return (
      <ListLayout.Content.MainContent.FilterBar>
        <ListLayout.Content.MainContent.FilterBar.LeftContent>
          <ChooseAssetInlineModal
            width="300"
            trigger={this.renderAssetFilterTrigger()}
            selectedAssetId={this.state.asset_with_tree_children_id}
            onSelectAsset={assetId => {
              this.setState({ asset_with_tree_children_id: assetId });
            }}
            onClear={() => {
              this.setState({ asset_with_tree_children_id: null });
            }}
          />
          <div className={styles['separator']} />
          <Field.Checkbox
            checked={this.state.deviation_assigned_to_me}
            label={<FormattedMessage id="screens.checklists.assigned-to-me" />}
            onChange={value =>
              this.setState(prevState => ({
                deviation_assigned_to_me: !prevState.deviation_assigned_to_me,
              }))
            }
          />
        </ListLayout.Content.MainContent.FilterBar.LeftContent>
      </ListLayout.Content.MainContent.FilterBar>
    );
  };

  renderMainContent = () => {
    if (this.state.isFetching) {
      const amountOfChecklists =
        this.state.checklistInstanceIds.length === 0 ? 2 : this.state.checklistInstanceIds.length;
      return (
        <ListLayout.Content.MainContent>
          {this.renderTopBar()}
          <ListLayout.Content.MainContent.Content>
            {this.renderListHeader()}
            <List>
              {Array(amountOfChecklists)
                .fill()
                .map(() => (
                  <List.Item small>
                    <List.Item.TitleColumn loading />
                  </List.Item>
                ))}
            </List>
          </ListLayout.Content.MainContent.Content>
          {this.renderPagination()}
        </ListLayout.Content.MainContent>
      );
    }
    return (
      <ListLayout.Content.MainContent>
        {this.renderTopBar()}
        <ListLayout.Content.MainContent.Content>{this.renderList()}</ListLayout.Content.MainContent.Content>
        {this.renderPagination()}
      </ListLayout.Content.MainContent>
    );
  };

  renderChecklistModal = () => {
    return (
      <ChecklistModal
        openedFromDeviations
        open={this.props.match.params.id != null}
        checklistInstanceId={this.props.match.params.id}
        onClose={() => {
          this.props.history.push({
            pathname: '/deviations',
            search: `list=${this.state.navigatedToChecklistModalForList}`,
          });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderMainContent()}
        {this.renderChecklistModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Deviations)));
