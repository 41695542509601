import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { InlineModal } from 'views/components/Shared/General';
import 'react-day-picker/lib/style.css';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import Header from '../Header';

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerDate: this.isDateStringValid(props.date) ? moment(props.date) : moment(),
    };
  }

  isDateStringValid = dateString => moment(dateString, 'YYYY-MM-DD').isValid();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date) {
      this.setState({
        headerDate: this.isDateStringValid(this.props.date) ? moment(this.props.date) : moment(),
      });
    }
  }

  setDate = (date, { disabled }) => {
    if (disabled) return;
    this.props.onSelectDate(moment(date).format('YYYY-MM-DD'));
  };

  changeMonth = date => {
    this.setState({ headerDate: moment(date) });
  };

  getSelectedDaysForDayPicker = () => {
    if (this.isDateStringValid(this.props.date)) {
      return moment(this.props.date, 'YYYY-MM-DD').toDate();
    }
    return null;
  };

  renderFixedDatePicker = () => {
    const { headerDate } = this.state;
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)}>{this.props.trigger}</div>
        <InlineModal
          position={this.props.position}
          positionToRef={this.inlineModalPositioningRef}
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <InlineModal.Body paddingTop>
            <DayPicker
              locale={moment.locale()}
              showWeekNumbers
              localeUtils={MomentLocaleUtils}
              onDayClick={this.setDate}
              selectedDays={this.getSelectedDaysForDayPicker()}
              month={headerDate.toDate()}
              navbarElement={<Header date={headerDate.format('YYYY-MM-DD')} onChange={this.changeMonth} />}
              captionElement={() => null}
            />
            {this.props.footerComponent}
          </InlineModal.Body>
        </InlineModal>
      </>
    );
  };

  renderInlineDatePicker = () => {
    const { headerDate } = this.state;
    return (
      <>
        <DayPicker
          locale={moment.locale()}
          showWeekNumbers
          localeUtils={MomentLocaleUtils}
          onDayClick={this.setDate}
          selectedDays={this.getSelectedDaysForDayPicker()}
          month={headerDate.toDate()}
          navbarElement={<Header date={headerDate.format('YYYY-MM-DD')} onChange={this.changeMonth} />}
          captionElement={() => null}
          disabledDays={this.props.disabledDays}
        />
        {this.props.footerComponent}
      </>
    );
  };

  render() {
    return this.props.inline ? this.renderInlineDatePicker() : this.renderFixedDatePicker();
  }
}

DatePicker.propTypes = {
  onClose: PropTypes.func,
  date: PropTypes.string, //YYYY-MM-DD
  position: PropTypes.string,
  disabledDays: PropTypes.object,
  footerComponent: PropTypes.node,
};

DatePicker.defaultProps = {
  onClose: () => {},
  date: null,
  position: 'left',
  disabledDays: {},
  footerComponent: null,
};
