import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditCostOtherCategoryModal from './EditCostOtherCategoryModal';

class CostOtherCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCostOtherCategoryModal: false,
      showCostOtherCategoryModalForId: null,
      createdIds: [],
    };
  }

  listIsEmpty = () => this.props.costOtherCategories.length === 0;

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.costs.cost-other-categories.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() =>
            this.setState({ showCostOtherCategoryModal: true, showCostOtherCategoryModalForId: null })
          }
          label="screens.settings.costs.cost-other-categories.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderToolbar = () => (
    <Toolbar
      buttons={
        <Button
          primary
          onClick={() =>
            this.setState({ showCostOtherCategoryModal: true, showCostOtherCategoryModalForId: null })
          }
          label="screens.settings.costs.cost-other-categories.add-button"
        />
      }
    />
  );

  renderHeader = () => {
    return (
      <List.Header background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.cost-other-category.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListItem = costOtherCategory => (
    <List.Item
      clickable
      onClick={() =>
        this.setState({
          showCostOtherCategoryModal: true,
          showCostOtherCategoryModalForId: costOtherCategory.id,
        })
      }
    >
      <List.Item.TitleColumn title={costOtherCategory.title} />
    </List.Item>
  );

  renderCostOtherCategories = () => {
    if (this.props.costOtherCategories.length === 0) return null;

    const newCategories = this.state.createdIds
      .filter(createdId => this.props.costOtherCategories.find(({ id }) => id === createdId))
      .map(createdId => this.props.costOtherCategories.find(({ id }) => id === createdId));

    const oldCategories = this.props.costOtherCategories.filter(
      ({ id }) => !this.state.createdIds.includes(id)
    );

    return (
      <>
        {this.renderToolbar()}
        {this.renderHeader()}
        <List>
          {newCategories.map(costOtherCategory => this.renderListItem(costOtherCategory))}
          {oldCategories.map(costOtherCategory => this.renderListItem(costOtherCategory))}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.listIsEmpty()) {
      return this.renderEmptyDataset();
    } else {
      return this.renderCostOtherCategories();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/costs" />
        <Section
          title={<FormattedMessage id="screens.settings.costs.cost-other-categories.title" />}
          subtitle={<FormattedMessage id="screens.settings.costs.cost-other-categories.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditCostOtherCategoryModal
          open={this.state.showCostOtherCategoryModal}
          id={this.state.showCostOtherCategoryModalForId}
          onClose={() => this.setState({ showCostOtherCategoryModal: false })}
          onCreate={id => {
            this.setState(prevState => ({
              showCostOtherCategoryModal: false,
              createdIds: [id, ...prevState.createdIds],
            }));
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    costOtherCategories: AuthSelectors.getOtherCostCategories(state),
  };
}

export default injectIntl(connect(mapStateToProps)(CostOtherCategories));
