import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { Button, List, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';

class ConfirmUserModal extends Component {
  state = {
    isSaving: false,
    comment: '',
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        comment: '',
        isSaving: false,
      });
    }
  }

  createRequestSelectableAssignee = () => {
    if (this.state.isSaving) return;
    this.setState({ isSaving: true });

    this.props
      .createRequestSelectableAssignee(this.props.system.id, {
        user_id: this.props.user.id,
        comment: this.state.comment,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.confirm-user-modal.create-success" />
            }
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10002', key: 'user_id' })) {
          toast(
            <ToastMessage
              error
              text={
                <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.confirm-user-modal.errors.already-added" />
              }
            />
          );
          this.props.onClose();
        }
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          title={
            <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.confirm-user-modal.title" />
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <List small>
                  <List.Item>
                    <List.Item.Column width={30}>
                      <ProfilePicture userId={this.props.user && this.props.user.id} size={30} />
                    </List.Item.Column>
                    <List.Item.TitleColumn
                      title={<UserNameWrapper user={this.props.user} />}
                      subtitle={this.props.user && this.props.user.email}
                    />
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  view={false}
                  label={
                    <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.confirm-user-modal.comment" />
                  }
                  questionTooltipContent={
                    <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.confirm-user-modal.comment-tooltip" />
                  }
                >
                  <Field.Textarea
                    autoFocus
                    value={this.state.comment}
                    onChange={comment => {
                      this.setState({ comment });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.add"
              loading={this.state.isSaving}
              onClick={this.createRequestSelectableAssignee}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRequestSelectableAssignee: SDKReduxOperations.createRequestSelectableAssignee,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfirmUserModal));
