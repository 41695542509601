import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkOrderStatus } from 'sdk/WorkOrder';
import { NewInlineModal, Field } from 'views/components/Shared/General';

class StatusInlineModal extends Component {
  state = {
    showStatusInlineModal: false,
  };

  addStatusAsFilter = status => {
    if (this.props.value.find(val => val === status) == null) {
      this.props.onAddStatus(status);
    } else {
      this.props.onRemoveStatus(status);
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showStatusInlineModal: !prevState.showStatusInlineModal,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showStatusInlineModal}
          onClose={() => this.setState({ showStatusInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(WorkOrderStatus.NotStarted)}
                    onChange={() => {
                      this.addStatusAsFilter(WorkOrderStatus.NotStarted);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(WorkOrderStatus.NotStarted)}
              >
                <FormattedMessage id="resources.work-order.statuses.not-started" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(WorkOrderStatus.InProgress)}
                    onChange={() => {
                      this.addStatusAsFilter(WorkOrderStatus.InProgress);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(WorkOrderStatus.InProgress)}
              >
                <FormattedMessage id="resources.work-order.statuses.in-progress" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(WorkOrderStatus.Paused)}
                    onChange={() => {
                      this.addStatusAsFilter(WorkOrderStatus.Paused);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(WorkOrderStatus.Paused)}
              >
                <FormattedMessage id="resources.work-order.statuses.paused" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes(WorkOrderStatus.Completed)}
                    onChange={() => {
                      this.addStatusAsFilter(WorkOrderStatus.Completed);
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter(WorkOrderStatus.Completed)}
              >
                <FormattedMessage id="resources.work-order.statuses.completed" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

export default StatusInlineModal;
