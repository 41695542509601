import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { InlineModal } from 'views/components/Shared/General';
import { MoveFolderInlineModalContent } from 'views/components/Attachment';
import Delete from './Delete';

const DROPDOWN_OPTIONS = {
  DropdownItems: 'dropdownItems',
  MoveFolder: 'moveFolder',
  DeleteFolder: 'deleteFolder',
};

class FolderOptionsInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: DROPDOWN_OPTIONS.DropdownItems,
    };
  }

  renderOptions = () => (
    <InlineModal.Body width={250} dropdown>
      <InlineModal.ListItem
        icon="pen"
        iconThickness="regular"
        title={<FormattedMessage id="components.folder-options-inline-modal.change-name" />}
        onClick={() => {
          this.setState({ isOpen: false });
          this.props.onEditAttachmentFolder(this.props.attachmentFolder);
        }}
      />
      <InlineModal.ListItem
        icon="folder"
        iconThickness="regular"
        title={<FormattedMessage id="components.folder-options-inline-modal.change-folder.title" />}
        onClick={() => {
          this.setState({
            selectedOption: DROPDOWN_OPTIONS.MoveFolder,
          });
        }}
      />
      <InlineModal.Separator />
      <InlineModal.ListItem
        icon="trash-alt"
        iconThickness="regular"
        iconColor="#C55050"
        destructive
        title={<FormattedMessage id="general.delete" />}
        onClick={() => {
          this.setState({
            selectedOption: DROPDOWN_OPTIONS.DeleteFolder,
          });
        }}
      />
    </InlineModal.Body>
  );

  renderMoveFolder = () => (
    <MoveFolderInlineModalContent
      title={<FormattedMessage id="components.folder-options-inline-modal.change-folder.title" />}
      loading={this.state.isChangingFolder}
      defaultOpenFolderId={this.props.attachmentFolder.attachment_folder_parent_id}
      disableSelectForFolderId={this.props.attachmentFolder.id}
      attachmentFolderToMove={this.props.attachmentFolder}
      onClose={() => this.setState({ isOpen: false })}
      onSelectFolder={folderId => {
        this.setState({ isChangingFolder: true });
        this.props
          .updateAttachmentFolder(this.props.attachmentFolder.id, {
            attachment_folder_parent_id: folderId,
          })
          .then(() => {
            this.setState({ isChangingFolder: false, isOpen: false });
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.settings.assets.attachment-folders.moved-success-message" />
                }
              />
            );
          });
      }}
      onSizeWasChanged={() => this.inlineModalRef.setInlineModalHorizontalPositions()}
    />
  );

  renderDelete = () => (
    <Delete
      attachmentFolder={this.props.attachmentFolder}
      onAttachmentFolderDeleted={() => this.setState({ isOpen: false })}
      onClose={() => this.setState({ isOpen: false })}
    />
  );

  renderContent = () => {
    switch (this.state.selectedOption) {
      case DROPDOWN_OPTIONS.DropdownItems:
        return this.renderOptions();
      case DROPDOWN_OPTIONS.MoveFolder:
        return this.renderMoveFolder();
      case DROPDOWN_OPTIONS.DeleteFolder:
        return this.renderDelete();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.stopPropagation();
            this.setState(prevState => ({
              isOpen: !prevState.isOpen,
              selectedOption: DROPDOWN_OPTIONS.DropdownItems,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <InlineModal
          ref={ref => (this.inlineModalRef = ref)}
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        >
          <React.Fragment>{this.renderContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAttachmentFolder: SDKReduxOperations.updateAttachmentFolder,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(FolderOptionsInlineModal));
