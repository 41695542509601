import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { Header, ContentContainer } from 'views/components/Shared/Layout';
import { Statistics } from 'views/components/Cost';
import HelperFunctions from 'utilities/HelperFunctions';

class Costs extends Component {
  state = {
    isFetching: true,
    purchaseOrderIds: [],
  };

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Statistics);
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.statistics-costs.document-title' })
    );
  }

  renderBreadcrumb = () => {
    return (
      <Header.Breadcrumb>
        <Header.BreadcrumbItem to={{ pathname: '/statistics' }}>
          <FormattedMessage id="screens.statistics-dashboard.breadcrumb-root" />
        </Header.BreadcrumbItem>
        <Header.BreadcrumbItem>
          <FormattedMessage id="screens.statistics-costs.breadcrumb-root" />
        </Header.BreadcrumbItem>
      </Header.Breadcrumb>
    );
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header
            maxWidth={960}
            smallTitle
            title={<FormattedMessage id="screens.statistics-costs.title" />}
            breadcrumbComponent={this.renderBreadcrumb()}
          />
          <ContentContainer maxWidth={960}>
            <Statistics />
          </ContentContainer>
        </PerfectScrollbar>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(Costs));
