import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Address } from 'views/components/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditShippingAddressModal from './EditShippingAddressModal';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';

class ShippingAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      showCreateAddressModal: false,
      showCreateAddressModalId: null,
    };
  }

  componentDidMount = () => {
    this.setState({ isFetching: true }, () => {
      this.props.fetchPurchaseOrderShippingAdresses(this.props.currentSystem.id).then(() => {
        this.setState({ isFetching: false });
      });
    });
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={
        <FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.empty-data-set.title" />
      }
      button={
        <Button
          primary
          onClick={() =>
            this.setState({
              showCreateAddressModal: true,
            })
          }
          label="screens.settings.purchase-orders.shipping-addresses.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderSubtitleRow = strings => {
    return (
      <div>
        {strings.map(string => (
          <span>
            {string}
            {strings.indexOf(string) === strings.length ? '' : ' '}
          </span>
        ))}
      </div>
    );
  };

  renderListItem = shippingAddress => {
    return (
      <List.Item
        clickable
        onClick={() => {
          this.setState({ showCreateAddressModal: true, showCreateAddressModalId: shippingAddress.id });
        }}
      >
        <List.Item.TitleColumn
          title={shippingAddress.name}
          subtitle={
            <Address
              ignoreName
              address1={shippingAddress.address1}
              address2={shippingAddress.address2}
              zip={shippingAddress.zip}
              city={shippingAddress.city}
              country={shippingAddress.country}
            />
          }
        />
        {shippingAddress.default ? (
          <List.Item.Column>
            <FormattedMessage id="resources.purchase-order-shipping-address.default" />
          </List.Item.Column>
        ) : null}
      </List.Item>
    );
  };

  renderShippingAddresses = () => {
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showCreateAddressModalId: null,
                  showCreateAddressModal: true,
                })
              }
              label="screens.settings.purchase-orders.shipping-addresses.add-button"
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {this.state.isFetching ? (
            <>
              <List.Item>
                <List.Item.TitleColumn loading />
              </List.Item>
              <List.Item>
                <List.Item.TitleColumn loading />
              </List.Item>
            </>
          ) : (
            this.props.shippingAddresses.map(shippingAddressId => this.renderListItem(shippingAddressId))
          )}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.props.shippingAddresses.length === 0 && !this.state.isFetching) {
      return this.renderEmptyDataset();
    } else {
      return this.renderShippingAddresses();
    }
  }

  handleModalClose = () => {
    this.setState({ showCreateAddressModal: false });
  };

  render() {
    return (
      <>
        <BackButton to="/settings/purchase-orders" />
        <Section
          title={<FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.title" />}
          subtitle={<FormattedMessage id="screens.settings.purchase-orders.shipping-addresses.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        {
          <EditShippingAddressModal
            open={this.state.showCreateAddressModal}
            id={this.state.showCreateAddressModalId}
            onClose={this.handleModalClose}
          />
        }
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrderShippingAdresses: SettingsOperations.fetchPurchaseOrderShippingAddresses,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    shippingAddresses: SettingsSelectors.getPurchareOrderShippingAddresses(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShippingAddresses));
