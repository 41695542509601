import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import { AuthSelectors } from 'state/ducks/auth';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import AutomationRuleItem from './AutomationRuleItem';
import EditRuleOverlayModal from './EditRuleOverlayModal';
import DndContextContainer from './DndContextContainer';
import NoAssigneeReceiverField from './NoAssigneeReceiverField';
import styles from './style.module.scss';

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isSaving: false,
      showEditRuleOverlayModal: false,
      showEditRuleOverlayModalForId: null,
      showAssigneeDropdown: false,
    };
  }

  componentDidMount() {
    this.props.fetchRequestAutomationRules(this.props.system.id).then(() => {
      this.setState({ isFetching: false });
    });
  }

  renderRulesHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column width={0} />
        <List.Header.Column flex>
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.rules-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderRules = () => {
    if (this.state.isFetching) {
      return (
        <>
          {this.renderRulesHeader()}
          <List small>
            <AutomationRuleItem loading />
            <AutomationRuleItem loading />
          </List>
        </>
      );
    }
    if (this.props.requestAutomationRules.length === 0) {
      return (
        <EmptyDataSet
          title={
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.requests.assignees.automation.rules.empty-data-set.subtitle" />
          }
          modal
        />
      );
    }
    return (
      <>
        <SortableContext items={this.props.requestAutomationRules} strategy={verticalListSortingStrategy}>
          {this.renderRulesHeader()}

          <List small draggable>
            {this.props.requestAutomationRules.map((requestAutomationRule, index) => (
              <AutomationRuleItem
                id={requestAutomationRule.id}
                index={index}
                key={requestAutomationRule.id}
                onSelect={() =>
                  this.setState({
                    showEditRuleOverlayModal: true,
                    showEditRuleOverlayModalForId: requestAutomationRule.id,
                  })
                }
              />
            ))}
          </List>
        </SortableContext>
      </>
    );
  };

  renderNoAssigneeReceiverDropdown = () => {
    const { request_default_assignee_user_id, request_default_assignee_group_id } =
      this.props.editingSettings;

    return (
      <NoAssigneeReceiverField
        userId={request_default_assignee_user_id}
        groupId={request_default_assignee_group_id}
        onChange={params => {
          this.props.onChange(params);
        }}
      />
    );
  };

  renderContent = () => {
    return (
      <DndContextContainer>
        <div className={styles['description']}>
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.description" />
        </div>
        <Toolbar
          buttons={
            <Button
              primary
              label="screens.settings.requests.assignees.automation.rules.create-button"
              onClick={() =>
                this.setState({ showEditRuleOverlayModal: true, showEditRuleOverlayModalForId: null })
              }
            />
          }
        />
        {this.renderRules()}
        {this.renderNoAssigneeReceiverDropdown()}
      </DndContextContainer>
    );
  };

  render() {
    return (
      <>
        {this.renderContent()}
        <EditRuleOverlayModal
          open={this.state.showEditRuleOverlayModal}
          id={this.state.showEditRuleOverlayModalForId}
          onClose={() => this.setState({ showEditRuleOverlayModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRequestAutomationRules: SettingsOperations.fetchRequestAutomationRules,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    requestAutomationRules: SettingsSelectors.getRequestAutomationRules(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
