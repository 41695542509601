import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class DebitToSystemDropdown extends Component {
  renderSelectedSystem = () => {
    if (this.props.system) {
      return this.props.system.name;
    }
    return null;
  };
  render() {
    return (
      <Field
        label={<FormattedMessage id="screens.organisation.user-permission-modal.debit-to-system-field" />}
        description={
          <FormattedMessage id="screens.organisation.user-permission-modal.debit-to-system-subtitle" />
        }
      >
        <Field.Dropdown clearable={false} value={this.renderSelectedSystem()}>
          {this.props.systems.map(system => (
            <Field.Dropdown.Item
              selected={this.props.system && this.props.system.id === system.id}
              onClick={() => this.props.onChange(system.id)}
            >
              {system.name}
            </Field.Dropdown.Item>
          ))}
        </Field.Dropdown>
      </Field>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { systemIds, selectedSystemId } = ownProps;
  return {
    system: EntitySelectors.getSystem(state, selectedSystemId),
    systems: EntitySelectors.getSystems(state, systemIds),
  };
}

export default connect(mapStateToProps)(DebitToSystemDropdown);
