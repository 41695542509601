import types from './types';

const fetchUsers = () => ({ type: types.FETCH_USERS });
const fetchUsersSuccess = ({ ids, entities }) => ({
  type: types.FETCH_USERS_SUCCESS,
  payload: {
    ids,
    entities,
  },
});

const fetchGroups = () => ({ type: types.FETCH_GROUPS });
const fetchGroupsSuccess = ({ ids, entities }) => ({
  type: types.FETCH_GROUPS_SUCCESS,
  payload: {
    ids,
    entities,
  },
});

const fetchVendors = () => ({ type: types.FETCH_VENDORS });
const fetchVendorsSuccess = ({ ids, entities, pagination }) => ({
  type: types.FETCH_VENDORS_SUCCESS,
  payload: {
    ids,
    entities,
    pagination,
  },
});

export default {
  fetchUsers,
  fetchUsersSuccess,
  fetchGroups,
  fetchGroupsSuccess,
  fetchVendors,
  fetchVendorsSuccess,
};
