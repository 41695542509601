import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { EntityOperations } from 'sdk/State/entities';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AssetsOperations } from 'state/ducks/assets';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { normalizeAsset } from 'sdk/Schemas';
import { List, WhiteCard, EmptyDataSet, Pagination } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import AssetTreePath from './AssetTreePath';
import AssetTreeList from './AssetTreeList';
import AssetListItem from '../components/AssetList/AssetListItem';
import styles from './style.module.scss';

const listSearchedAssetsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class AssetTree extends Component {
  animationDirection = 'forward';

  state = {
    searchedAssetIds: null,
    isLoading: true,
    treeParentId: 'root',
    isChangingPage: false,
    isLoadingPagination: false,
    totalAmountOfAssets: 0,
    page: 1,
    totalPages: 0,
  };

  constructor(props) {
    super(props);

    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchSearchAssets();
    }, 300);
  }

  componentDidMount() {
    if (this.props.searchValue !== '') {
      this.fetchSearchAssets();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ isLoading: true, isLoadingPagination: true, page: 1 });
      this.debouncedSearchValueWasChanged();
    }
  }

  changePage = page => {
    this.setState({ isChangingPage: true, page }, () => {
      this.fetchSearchAssets({ page });
    });
  };

  fetchSearchAssets = params => {
    const attrs = {
      ...params,
      search: this.props.searchValue,
      archived: false,
      page_size: 8,
      sort: 'title',
      ...this.props.additionalApiParams,
    };

    listSearchedAssetsRequest.cancel();

    return API.listAssets(
      this.props.currentSystem.id,
      attrs,
      listSearchedAssetsRequest.getCancelTokenConfig()
    )
      .then(res => {
        const { data, headers } = res;
        const { entities, result } = normalizeAsset(data);
        this.props.updateEntities(entities);
        this.setState({
          isLoading: false,
          searchedAssetIds: result,
          isLoadingPagination: false,
          isChangingPage: false,
          totalAmountOfAssets: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
          totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        });

        return result;
      })
      .catch(() => this.setState({ isLoading: false, isLoadingPagination: false, isChangingPage: false }));
  };

  navigateInTree = assetId => {
    this.setState({ treeParentId: assetId });
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-asset-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderLoaders = () => {
    if (!this.state.searchedAssetIds)
      return (
        <>
          <AssetListItem loading multiple={this.props.multiple} />
          <AssetListItem loading multiple={this.props.multiple} />
        </>
      );
    return (
      <>
        {this.state.searchedAssetIds.map(() => {
          return <AssetListItem loading multiple={this.props.multiple} />;
        })}
      </>
    );
  };

  renderSearchItems = () => {
    if (this.state.isLoading || this.state.isChangingPage || !this.state.searchedAssetIds)
      return (
        <>
          {this.renderListHeader()}
          <List>{this.renderLoaders()}</List>
        </>
      );
    if (!this.state.isLoading && this.props.searchValue && this.state.searchedAssetIds.length === 0) {
      return this.renderEmptySearch();
    }

    return (
      <>
        {this.renderListHeader()}
        <List>
          {this.state.searchedAssetIds.map(id => {
            return (
              <AssetListItem
                id={id}
                listItemRightComponent={this.props.listItemRightComponent}
                alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
                multiple={this.props.multiple}
                selectedAssetIds={this.props.selectedAssetIds}
                onSelectMultiple={this.props.onSelectMultiple}
              />
            );
          })}
        </List>
      </>
    );
  };

  renderListContent = () => {
    if (this.props.searchValue !== '') {
      return <div className={styles['list-container']}>{this.renderSearchItems()}</div>;
    }

    let classNames = [styles['list-container']];
    if (this.animationDirection === 'forward') {
      classNames = [...classNames, styles['animate-forward']];
    } else {
      classNames = [...classNames, styles['animate-backward']];
    }

    return (
      <div className={classNames.join(' ')}>
        <SwitchTransition>
          <CSSTransition
            key={this.state.treeParentId}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames={{
              enter: styles['fade-enter'],
              enterActive: styles['fade-enter-active'],
              exit: styles['fade-exit'],
              exitActive: styles['fade-exit-active'],
            }}
          >
            <div>
              <AssetTreeList
                additionalApiParams={this.props.additionalApiParams}
                listItemRightComponent={this.props.listItemRightComponent}
                alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
                treeParentId={this.state.treeParentId}
                multiple={this.props.multiple}
                selectedAssetIds={this.props.selectedAssetIds}
                onSelectMultiple={this.props.onSelectMultiple}
                onNavigateToAsset={assetId => {
                  this.animationDirection = 'forward';
                  this.setState({ treeParentId: assetId });
                }}
              />
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    );
  };

  renderPagination = () => {
    if (this.state.totalAmountOfAssets <= 8) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet subtitle={<FormattedMessage id="general.empty-data-set-search.title" />}></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderPath = () => {
    if (this.props.searchValue.length > 0) {
      return null;
    }
    return (
      <div className={styles['path-container']}>
        <AssetTreePath
          multiple={this.props.multiple}
          listItemRightComponent={this.props.listItemRightComponent}
          onNavigate={assetId => {
            this.animationDirection = 'backward';
            this.setState({ treeParentId: assetId });
          }}
          parentAssetId={this.state.treeParentId}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <Modal.ColumnLayout.Container.Content>
          {this.renderPath()}
          {this.renderListContent()}
        </Modal.ColumnLayout.Container.Content>
        {this.props.searchValue !== '' ? this.renderPagination() : null}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetTree));
