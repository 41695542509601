import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

const VendorField = ({ vendor }) => {
  return vendor.name;
};

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(VendorField);
