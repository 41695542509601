import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { TreePath, AssetTitle } from 'views/components/Asset';

class AssetContainer extends Component {
  render() {
    return (
      <>
        <AssetTitle id={this.props.asset.id} />
        {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
          <div>
            <TreePath assetId={this.props.id} />
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(AssetContainer);
