import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Icon, Button as GeneralButton } from 'views/components/Shared/General';
import { AssetTitle, NewSelectAssetModal } from 'views/components/Asset';
import { AuthSelectors } from 'state/ducks/auth';
import { RequestsSelectors } from 'state/ducks/requests';
import { API } from 'sdk';
import styles from './style.module.scss';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeAsset } from 'sdk/Schemas';

class ApproveForAssetsDropdown extends Component {
  inlineModalPositioningRef = null;

  state = {
    open: false,
    isFetching: false,
    showSelectAssetModal: false,
    assetIds: [],
  };

  toggle = () => {
    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => {
        if (this.state.open) {
          this.fetchAssets();
        }
      }
    );
  };

  fetchAssets = () => {
    this.setState({ isFetching: true });
    API.listAssets(this.props.system.id, {
      production_supervisor_user_id: this.props.currentUser.id,
    }).then(({ data: assets }) => {
      const { entities, result: assetIds } = normalizeAsset(assets);
      this.props.updateEntities(entities);
      this.setState({ assetIds, isFetching: false });
    });
  };

  closeDropdownAndFilter = params => {
    const { asset_with_tree_children_id, production_supervisor_user_id } = this.props.queryParameters;
    this.setState({ open: false });
    this.props.onFilter({
      asset: null,
      asset_with_tree_children_id,
      production_supervisor_user_id,
      ...params,
    });
  };

  renderSelectedAnotherAsset = () => {
    const { asset_with_tree_children_id } = this.props.queryParameters;
    if (
      asset_with_tree_children_id &&
      this.state.assetIds.find(id => id === asset_with_tree_children_id) == null
    ) {
      return (
        <NewInlineModal.Dropdown.Item selected>
          <div className={styles['asset']}>
            <AssetTitle id={asset_with_tree_children_id} />
          </div>
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    const { production_supervisor_user_id, asset_with_tree_children_id } = this.props.queryParameters;
    return (
      <NewInlineModal.Dropdown.Items>
        <NewInlineModal.Dropdown.Item
          selected={production_supervisor_user_id != null}
          onClick={() => {
            this.closeDropdownAndFilter({
              production_supervisor_user_id: this.props.currentUser.id,
              asset_with_tree_children_id: null,
            });
          }}
        >
          <FormattedMessage id="screens.requests.approve-for-assets-dropdown.assigned-to-me" />
        </NewInlineModal.Dropdown.Item>
        {this.state.assetIds.map(id => (
          <NewInlineModal.Dropdown.Item
            selected={asset_with_tree_children_id === id}
            onClick={() => {
              this.closeDropdownAndFilter({
                asset_with_tree_children_id: id,
                production_supervisor_user_id: null,
              });
            }}
          >
            <div className={styles['asset']}>
              <AssetTitle id={id} />
            </div>
          </NewInlineModal.Dropdown.Item>
        ))}
        <NewInlineModal.Dropdown.Separator />
        <NewInlineModal.Dropdown.Item
          selected={production_supervisor_user_id == null && asset_with_tree_children_id == null}
          onClick={() => {
            this.closeDropdownAndFilter({
              asset_with_tree_children_id: null,
              production_supervisor_user_id: null,
            });
          }}
        >
          <FormattedMessage id="screens.requests.approve-for-assets-dropdown.show-all-assets" />
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.setState({ open: false, showSelectAssetModal: true });
          }}
        >
          <FormattedMessage id="screens.requests.approve-for-assets-dropdown.select-another-asset" />
        </NewInlineModal.Dropdown.Item>
        {this.renderSelectedAnotherAsset()}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderLabel = () => {
    if (this.props.queryParameters.production_supervisor_user_id) {
      return <FormattedMessage id="screens.requests.approve-for-assets-dropdown.assigned-to-me" />;
    }
    if (this.props.queryParameters.asset_with_tree_children_id) {
      return <AssetTitle id={this.props.queryParameters.asset_with_tree_children_id} />;
    }
    return <FormattedMessage id="screens.requests.approve-for-assets-dropdown.show-all-assets" />;
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        title={<FormattedMessage id="screens.users.action-dropdown.suggest-checked-in-asset" />}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={asset => {
          return (
            <GeneralButton
              gray
              small
              label="general.choose"
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  showSelectAssetModal: false,
                });
                this.closeDropdownAndFilter({
                  production_supervisor_user_id: null,
                  asset_with_tree_children_id: asset.id,
                });
              }}
            />
          );
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.toggle();
          }}
          className={styles['my-assets']}
        >
          <div>{this.renderLabel()}</div>
          <div className={styles['icon-container']}>
            <Icon type="caret-down" />
          </div>
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          minWidth={335}
        >
          <>
            <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
          </>
        </NewInlineModal>
        {this.renderSelectAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    queryParameters: RequestsSelectors.getQueryParameters(state),
    system: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveForAssetsDropdown);
