import React, { Component } from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { connect } from 'react-redux';
import { API, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { UserType } from 'sdk/SystemAccess';
import { UsersSelectors } from 'state/ducks/users';
import { Modal, ToastMessage, Grid } from 'views/components/Shared/Layout';
import { Field, Button, NewInlineModal } from 'views/components/Shared/General';
import styles from './style.module.scss';

class MassEditPermissionModal extends Component {
  getInitialState = () => ({
    isFetching: false,
    isSaving: false,
    showRequiredError: false,
    confirmMassEdit: false,
    permissionProfiles: [],
    currentPermissionProfile: null,
    admin: false,
    operator: false,
    production_supervisor: false,
    view_only: false,
    work_requester: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        isFetching: true,
      });

      API.listPermissionProfiles(this.props.system.id).then(res => {
        const permissionProfiles = res.data;
        this.setState({
          permissionProfiles,
          isFetching: false,
        });
      });
    }
  }

  selectPermission = params => {
    this.setState({
      showRequiredError: false,
      currentPermissionProfile: null,
      showDropDown: false,
      admin: false,
      operator: false,
      production_supervisor: false,
      view_only: false,
      work_requester: false,
      ...params,
    });
  };

  buildFilterParams = () => {
    if (this.props.totalEntriesIsSelected) {
      if (this.props.searchTerm) {
        return {
          filters: {
            ...this.props.listParams,
            search: this.props.searchTerm,
          },
        };
      }
      return {
        filters: {
          ...this.props.listParams,
        },
      };
    }
    return {
      filters: {
        user_id: {
          any: Object.keys(this.props.selectedUserIds).toString(),
        },
      },
    };
  };

  hasError = () => {
    const { currentPermissionProfile, admin, operator, production_supervisor, view_only, work_requester } =
      this.state;
    let hasErrors = false;
    if (
      !work_requester &&
      !admin &&
      !operator &&
      !production_supervisor &&
      !view_only &&
      currentPermissionProfile == null
    ) {
      this.setState({ showRequiredError: true });
      hasErrors = true;
    }
    return hasErrors;
  };

  batchUpdate = () => {
    if (this.hasError()) {
      return;
    }
    const { admin, operator, work_requester, production_supervisor, view_only, currentPermissionProfile } =
      this.state;
    let data = {};
    if (admin) {
      data = {
        ...data,
        user_type: UserType.SystemAdmin,
      };
    } else if (operator) {
      data = {
        ...data,
        user_type: UserType.Operator,
      };
    } else if (production_supervisor) {
      data = {
        ...data,
        user_type: UserType.ProductionSupervisor,
      };
    } else if (view_only) {
      data = {
        ...data,
        user_type: UserType.ViewOnly,
      };
    } else if (work_requester) {
      data = {
        ...data,
        user_type: UserType.WorkRequester,
      };
    } else {
      data = {
        ...data,
        user_type: UserType.PermissionProfile,
        permission_profile_id: currentPermissionProfile.id,
      };
    }
    this.setState({ isSaving: true });
    API.batchUpdateSystemAccesses(this.props.system.id, {
      ...this.buildFilterParams(),
      update: {
        ...data,
      },
    })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onSave();
      })
      .catch(e => {
        if (HelperFunctions.hasError(e, { code: '10022' })) {
          this.setState({ isSaving: false, showQuantityExceeded: true });
        }
      });
  };

  renderSelectPermission = () => {
    const { admin, operator, production_supervisor, work_requester, view_only, currentPermissionProfile } =
      this.state;
    if (admin) {
      return <FormattedMessage id="resources.user.admin" />;
    }
    if (production_supervisor) {
      return <FormattedMessage id="resources.user.production-supervisor" />;
    }
    if (view_only) {
      return <FormattedMessage id="resources.user.view-only" />;
    }
    if (work_requester) {
      return <FormattedMessage id="resources.user.work-requester" />;
    }
    if (operator) return <FormattedMessage id="resources.user.operator" />;
    return currentPermissionProfile ? currentPermissionProfile.title : null;
  };

  renderProductionSupervisorMenuItem = () => {
    if (this.props.settings.production_board_activated) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.selectPermission({
              production_supervisor: true,
            });
          }}
          selected={this.state.production_supervisor}
        >
          <FormattedMessage id="resources.user.production-supervisor" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderOperatorDropdownMenuItem = () => {
    if (this.props.settings.production_board_activated) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.selectPermission({
              operator: true,
            });
          }}
          selected={this.state.operator}
        >
          <FormattedMessage id="resources.user.operator" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderDropdownContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({
                admin: true,
              });
            }}
            selected={this.state.admin}
          >
            <FormattedMessage id="resources.user.admin" />
          </NewInlineModal.Dropdown.Item>
          {this.renderProductionSupervisorMenuItem()}
          {this.renderOperatorDropdownMenuItem()}
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({
                work_requester: true,
              });
            }}
            selected={this.state.work_requester}
          >
            <FormattedMessage id="resources.user.work-requester" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({
                view_only: true,
              });
            }}
            selected={this.state.view_only}
          >
            <FormattedMessage id="resources.user.view-only" />
          </NewInlineModal.Dropdown.Item>
          {this.state.permissionProfiles.length === 0 ? null : (
            <React.Fragment>
              <NewInlineModal.Dropdown.Separator />
              <p className={styles['custom-permissions']}>
                <FormattedMessage id="screens.users.mass-edit-permission-modal.permission-profiles" />
              </p>
              {this.state.permissionProfiles.map(permissionProfile => {
                return (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.selectPermission({
                        currentPermissionProfile: permissionProfile,
                      });
                    }}
                    selected={
                      this.state.currentPermissionProfile &&
                      this.state.currentPermissionProfile.id === permissionProfile.id
                    }
                  >
                    <span>{permissionProfile ? permissionProfile.title : null}</span>
                  </NewInlineModal.Dropdown.Item>
                );
              })}
            </React.Fragment>
          )}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  renderSubtitle = () => {
    return (
      <FormattedPlural
        value={this.props.selectedUsersCount}
        one={<FormattedMessage id="screens.users.mass-edit-permission-modal.total-users.one" />}
        two={
          <FormattedMessage
            id="screens.users.mass-edit-permission-modal.total-users.two"
            values={{ count: this.props.selectedUsersCount }}
          />
        }
        few={
          <FormattedMessage
            id="screens.users.mass-edit-permission-modal.total-users.few"
            values={{ count: this.props.selectedUsersCount }}
          />
        }
        many={
          <FormattedMessage
            id="screens.users.mass-edit-permission-modal.total-users.many"
            values={{ count: this.props.selectedUsersCount }}
          />
        }
        other={
          <FormattedMessage
            id="screens.users.mass-edit-permission-modal.total-users.other"
            values={{ count: this.props.selectedUsersCount }}
          />
        }
      />
    );
  };

  renderFields = () => {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                edit
                label={<FormattedMessage id="screens.users.mass-edit-permission-modal.permission" />}
              >
                <div
                  ref={ref => (this.inlineModalPositioningRef = ref)}
                  onClick={() =>
                    this.setState(prevState => ({
                      showDropDown: !prevState.showDropDown,
                    }))
                  }
                >
                  <Field.Resource
                    error={this.state.showRequiredError}
                    angleDown
                    value={this.renderSelectPermission()}
                    clearable={false}
                  />
                </div>
              </Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field.Checkbox
                checked={this.state.confirmMassEdit}
                label={
                  <FormattedMessage
                    id="components.mass-edit-modal.i-am-sure"
                    values={{
                      count: <span className={styles['confirm-subtitle']}>{this.renderSubtitle()}</span>,
                    }}
                  />
                }
                onChange={() => {
                  this.setState(prevState => ({
                    confirmMassEdit: !prevState.confirmMassEdit,
                  }));
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          {this.renderDropdownContent()}
        </NewInlineModal>
      </>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.rights-title" />}
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                disabled={this.state.confirmMassEdit === false}
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.batchUpdate();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    totalEntriesIsSelected: UsersSelectors.getTotalEntriesIsSelected(state),
    selectedUserIds: UsersSelectors.getSelectedUserIds(state),
    queryParameters: UsersSelectors.getUsersQueryParameters(state),
    selectedUsersCount: UsersSelectors.getSelectedUsersCount(state),
  };
}

export default connect(mapStateToProps)(MassEditPermissionModal);
