import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import HelperFunctions from 'utilities/HelperFunctions';
import { AssetOperations } from 'state/ducks/asset';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import NotFound from './NotFound';
import ActiveDowntime from './ActiveDowntime';
import {
  Information,
  WorkOrders,
  OperationalMaintenances,
  AssetVendors,
  Attachments,
  SpareParts,
  Downtimes,
  Costs,
} from './Tabs';

class AssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false,
      show: false,
    };
  }

  componentDidMount() {
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Asset);
    this.fetchAsset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchAsset();
    }
  }

  fetchAsset = () => {
    this.props
      .fetchAsset(this.props.match.params.id)
      .then(asset => {
        const { system_id } = asset;
        if (system_id !== this.props.currentSystem.id) {
          this.setState({ notFound: true });
        } else {
          HelperFunctions.setDocumentTitle(asset.title);
        }
      })
      .catch(e => {
        this.setState({ notFound: e.response && e.response.status === 404 });
      });
  };

  render() {
    const { production_board_activated, operational_maintenance_activated } = this.props.settings;
    const operationalMaintenanceIsActivated = production_board_activated && operational_maintenance_activated;
    if (this.state.notFound) {
      return <NotFound />;
    } else if (!this.props.asset) {
      return <Loader />;
    } else {
      return (
        <>
          <ActiveDowntime />
          <Switch key={this.props.match.params.id}>
            <Route path={`/assets/:id`} exact component={Information} />
            <Route path={`/assets/:id/work-orders`} exact component={WorkOrders} />
            {operationalMaintenanceIsActivated ? (
              <Route
                path={`/assets/:id/operational-maintenances`}
                exact
                component={OperationalMaintenances}
              />
            ) : null}
            {this.props.settings.spare_parts_activated ? (
              <Route path={`/assets/:id/spare-parts`} exact component={SpareParts} />
            ) : null}
            {this.props.hasProTier && this.props.settings.downtime_activated ? (
              <Route path={`/assets/:id/downtimes`} exact component={Downtimes} />
            ) : null}
            {this.props.hasProTier &&
            this.props.currentSystem.costs_activated &&
            this.props.canViewFullCosts ? (
              <Route path={`/assets/:id/costs`} exact component={Costs} />
            ) : null}
            {this.props.canViewVendors ? (
              <Route path={`/assets/:id/vendors`} exact component={AssetVendors} />
            ) : null}
            <Route path={`/assets/:id/files`} exact component={Attachments} />
          </Switch>
        </>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAsset: AssetOperations.fetchAsset,
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const assetId = ownProps.match.params.id;
  return {
    asset: EntitySelectors.getAsset(state, assetId),
    canViewVendors: AuthSelectors.canViewVendors(state),
    hasProTier: AuthSelectors.hasProTier(state),
    settings: AuthSelectors.getSettings(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canViewFullCosts: AuthSelectors.canViewFullCosts(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetDetail));
