import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { List, MoneyWithCurrency, Datapill } from 'views/components/Shared/General';
import { ListItemStatus } from 'views/components/PurchaseOrder';

const PurchaseOrderListItem = ({ ourReferenceUser, purchaseOrder, loading }) => {
  const renderOurReferenceDatapill = () => {
    return (
      <Datapill
        value={ourReferenceUser ? ourReferenceUser.name : '-'}
        label={<FormattedMessage id="resources.purchase-order.our-ref" />}
      />
    );
  };

  const renderDataPills = () => {
    return <Datapill.List>{renderOurReferenceDatapill()}</Datapill.List>;
  };

  if (loading) {
    return (
      <List.Item>
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }
  return (
    <List.Item
      dataPillsComponent={renderDataPills()}
      linkTo={{
        pathname: `/purchase-orders/${purchaseOrder.id}`,
      }}
    >
      <List.Item.TitleColumn title={`#${purchaseOrder.number}`} />
      <List.Item.Column width={150}>{<ListItemStatus purchaseOrder={purchaseOrder} />}</List.Item.Column>
      <List.Item.Column width={150} alignRight>
        <MoneyWithCurrency currency={purchaseOrder.currency} value={purchaseOrder.total} />
      </List.Item.Column>
    </List.Item>
  );
};

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  return {
    ourReferenceUser: EntitySelectors.getUser(state, ownProps.purchaseOrder.our_reference_user_id),
  };
}

export default connect(mapStateToProps)(PurchaseOrderListItem);
