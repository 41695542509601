import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

export default class ChecklistTitleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      error: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        title: this.props.title || '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  save = () => {
    if (this.state.title.length === 0) {
      this.setState({ error: true });
      return;
    }

    this.props.onSave(this.state.title);
  };

  renderTitle = () => {
    if (this.props.reusable) {
      return <FormattedMessage id="components.checklist-title-modal.reusable-title" />;
    }
    return <FormattedMessage id="components.checklist-title-modal.title" />;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header ignoreLine title={this.renderTitle()} onClose={this.props.onClose} />
        <Modal.Content>
          <Field view={false} label={<FormattedMessage id="resources.checklist-template.title" />}>
            <Field.Text
              autoFocus
              value={this.state.title}
              error={this.state.error}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.save();
                }
              }}
              onChange={title => {
                this.setState({ error: false, title });
              }}
            />
          </Field>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={() => {
                this.save();
              }}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

ChecklistTitleModal.propTypes = {
  open: PropTypes.bool,
  reusable: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

ChecklistTitleModal.defaultProps = {
  open: false,
  reusable: false,
  onSave: () => {},
  onClose: () => {},
};
