import React from 'react';
import AtlaskitTree from '@atlaskit/tree/';
import Item from './Item';
import styles from './style.module.scss';

export default props => {
  return (
    <div className={`${styles['tree']}`}>
      <AtlaskitTree
        {...props}
        isDragEnabled={props.canEditAssets}
        renderItem={itemProps => (
          <Item
            canEditAssets={props.canEditAssets}
            onItemClicked={props.onItemClicked}
            onDelete={props.onDelete}
            onArchive={props.onArchive}
            onUnarchive={props.onUnarchive}
            onCreateSuccess={props.onCreateSuccess}
            onDuplicate={props.onDuplicate}
            onPrintQRCodes={props.onPrintQRCodes}
            onCreateAssetForAsset={props.onCreateAssetForAsset}
            {...itemProps}
          />
        )}
      />
    </div>
  );
};
