import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import SelectedAssetItem from './SelectedAssetItem';
import SelectAssetContainer from './SelectAssetContainer';
import styles from './style.module.scss';
import AssetTiny from 'assets/images/EmptyDataSet/AssetTiny.png';

class SelectAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssetObjectIds: {},
      inactiveAssetObjectIds: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        selectedAssetObjectIds: this.props.selectedAssetIds.reduce((a, b) => ((a[b] = true), a), {}),
        inactiveAssetObjectIds: this.props.inactiveAssetIds.reduce((a, b) => ((a[b] = true), a), {}),
      });
    }
  }

  selectAsset = assetId => {
    if (this.state.selectedAssetObjectIds[assetId] === true) {
      let newSelectedAssetObjectIds = { ...this.state.selectedAssetObjectIds };
      delete newSelectedAssetObjectIds[assetId];
      this.setState({
        selectedAssetObjectIds: newSelectedAssetObjectIds,
      });
    } else {
      let newSelectedAssetObjectIds = { ...this.state.selectedAssetObjectIds };
      newSelectedAssetObjectIds[assetId] = true;
      this.setState({
        selectedAssetObjectIds: newSelectedAssetObjectIds,
      });
    }
  };

  isFiltering = () => {
    return this.state.filters.searchValue.length > 0 || this.state.filters.asset_type_id != null;
  };

  renderContent = () => {
    if (this.isFiltering()) {
      return this.renderSearchedAssets();
    } else {
      return this.renderTree();
    }
  };

  renderSelectedAssets = () => {
    return (
      <div className={styles['selected-assets-container']}>
        <PerfectScrollbar>
          <div className={`${styles['selected-assets-content']}`}>
            {Object.keys(this.state.selectedAssetObjectIds).length === 0 ? (
              <EmptyDataSet
                title={<FormattedMessage id="components.asset-inline-modal.selected-multiple-none" />}
                image={AssetTiny}
                tiny
              />
            ) : (
              Object.keys(this.state.selectedAssetObjectIds).map(assetId => (
                <SelectedAssetItem
                  id={assetId}
                  onClear={() => {
                    let newSelectedAssetObjectIds = { ...this.state.selectedAssetObjectIds };
                    delete newSelectedAssetObjectIds[assetId];
                    this.setState({
                      selectedAssetObjectIds: newSelectedAssetObjectIds,
                    });
                  }}
                />
              ))
            )}
          </div>
        </PerfectScrollbar>
      </div>
    );
  };

  renderSelectAssetContainer = () => (
    <div className={styles['select-assets-container']}>
      <SelectAssetContainer
        onSelectAsset={this.selectAsset}
        selectedAssetObjectIds={this.state.selectedAssetObjectIds}
        inactiveAssetObjectIds={this.state.inactiveAssetObjectIds}
      />
    </div>
  );

  renderSaveButton = () => {
    if (Object.keys(this.state.selectedAssetObjectIds).length === 0) {
      return <Button small primary label="general.choose" onClick={() => this.props.onSelectAssetIds([])} />;
    } else {
      return (
        <Button
          small
          loading={this.props.loading}
          primary
          saving={this.props.loading}
          label={this.props.intl.formatMessage(
            {
              id: 'components.file-add-related-assets.choose-amount',
            },
            { amount: Object.keys(this.state.selectedAssetObjectIds).length }
          )}
          onClick={() => this.props.onSelectAssetIds(Object.keys(this.state.selectedAssetObjectIds))}
        />
      );
    }
  };

  renderModalContent = () => (
    <React.Fragment>
      <Modal.Header
        title={<FormattedMessage id="components.file-add-related-assets.title" />}
        onClose={this.props.onClose}
      />
      <div className={styles['content']}>
        <div className={styles['container']}>
          {this.renderSelectedAssets()}
          {this.renderSelectAssetContainer()}
        </div>
      </div>
      <Modal.Footer container>
        <Button.Group>
          {this.renderSaveButton()}
          <Button small label="general.cancel" onClick={this.props.onClose} />
        </Button.Group>
      </Modal.Footer>
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.isOpen} width={960} fullHeight>
        {this.renderModalContent()}
      </Modal>
    );
  }
}

export default injectIntl(SelectAssetModal);

SelectAssetModal.propTypes = {
  selectedAssetIds: PropTypes.array,
  inactiveAssetIds: PropTypes.array,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

SelectAssetModal.defaultProps = {
  selectedAssetIds: [],
  inactiveAssetIds: [],
};
