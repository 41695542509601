import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Button, List } from 'views/components/Shared/General';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import toast from 'react-hot-toast';
import styles from './style.module.scss';

class MaterialCostModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    isDeleting: false,
    purchase_price: 0,
    showAmountError: false,
  });

  state = this.getInitialState();

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        purchase_price: this.props.sparePartWithdrawal.purchase_price,
      });
    } else {
      window.onbeforeunload = undefined;
    }
  }

  closeModal = () => {
    if (this.props.sparePartWithdrawal.purchase_price !== this.state.purchase_price) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        this.props.onClose();
      }
    } else {
      this.props.onClose();
    }
  };

  updateSparePartWithdrawal = () => {
    if (!this.state.purchase_price) {
      this.setState({
        showAmountError: !this.state.purchase_price,
      });
      return;
    }
    const { purchase_price } = this.state;
    this.setState({ isSaving: true, hasUnsavedChanges: false });
    this.props
      .updateSparePartWithdrawal(this.props.sparePartWithdrawal.id, {
        purchase_price,
      })
      .then(({ data: cost }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.work-order.costs.cost-modal.update-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
        //TODO: Replace with generic error message
      });
  };

  renderTitle = () => {
    const { sparePart, sparePartWithdrawal } = this.props;

    if (sparePart) {
      return <Title sparePart={sparePart} />;
    }

    return sparePartWithdrawal.title;
  };

  renderContent = () => {
    if (this.props.costId == null) return null;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List light>
              <List.Item>
                <List.Item.ImageColumn
                  imageId={this.props.sparePart?.images.length === 0 ? null : this.props.sparePart?.images[0]}
                />
                <List.Item.TitleColumn
                  title={this.renderTitle()}
                  subtitle={
                    <SparePartUnitWithValue
                      sparePartUnit={this.props.sparePartUnit}
                      value={this.props.sparePartWithdrawal.quantity}
                    />
                  }
                />
                <div className={styles['price-column']}>
                  <p>
                    <FormattedMessage id="screens.work-order.costs.material-cost-modal.unit-price" />
                  </p>
                  <List.Item.Column width={110}>
                    <Field.Money
                      error={this.state.showAmountError}
                      currency={this.props.cost.amount_currency}
                      value={this.state.purchase_price}
                      onChange={purchase_price => {
                        if (purchase_price && this.state.showAmountError) {
                          this.setState({ showAmountError: false });
                        }
                        this.setState({ purchase_price });
                      }}
                      onBlur={purchase_price => {
                        this.setState({ purchase_price });
                      }}
                    />
                  </List.Item.Column>
                </div>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    return (
      <Modal width={560} isOpen={this.props.open}>
        <Prompt
          when={this.state.hasUnsavedChanges}
          message={location => this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })}
        />
        <Modal.Header
          title={<FormattedMessage id="screens.work-order.costs.material-cost-modal.title" />}
          onClose={this.closeModal}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              loading={this.state.isSaving}
              onClick={this.updateSparePartWithdrawal}
              label="general.save"
            />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePartWithdrawal: SDKReduxOperations.updateSparePartWithdrawal,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.costId == null) return {};
  const cost = EntitySelectors.getCost(state, ownProps.costId);
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, cost.spare_part_withdrawal_id);
  const sparePart = sparePartWithdrawal.spare_part_id
    ? EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id)
    : null;
  const sparePartUnit = EntitySelectors.getSparePartUnit(
    state,
    sparePart ? sparePart.spare_part_unit_id : sparePartWithdrawal.spare_part_unit_id
  );

  return {
    cost,
    sparePartWithdrawal,
    sparePart,
    sparePartUnit,
    costOtherCategories: AuthSelectors.getOtherCostCategories(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MaterialCostModal));
