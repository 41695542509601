import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { injectIntl, FormattedMessage, FormattedPlural } from 'react-intl';
import { Button, Menu, SectionHeader } from 'views/components/Shared/General';
import { ListLayout, ToastMessage } from 'views/components/Shared/Layout';
import { ChecklistEditModal, ChecklistTitleModal } from 'views/components/Checklist';
import { SDKReduxOperations, API } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import Templates from './Templates';
import Deviations from './Deviations';

class Checklists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      draftChecklistTemplateId: null,
      showChecklistTitleModal: false,
      showChecklistEditModal: false,
      showChecklistEditModalForVersionId: null,
      showChecklistEditModalUnsavedChanges: true,
      activeDeviationsCount: 0,
      totalEntries: 0,
    };
  }

  componentDidMount() {
    this.fetchActiveDeviationsCount();
  }

  fetchActiveDeviationsCount = () => {
    API.getChecklistInstanceListCounts(this.props.system.id).then(({ data }) => {
      const { deviation_count } = data;
      this.setState({ activeDeviationsCount: deviation_count });
    });
  };

  createChecklist = title => {
    this.setState({ showChecklistEditModal: true, showChecklistTitleModal: false });

    this.props.createChecklistTemplate(this.props.system.id, { title }).then(({ data: checklist }) => {
      const { draft_version } = checklist;
      this.setState({
        draftChecklistTemplateId: checklist.id,
        showChecklistEditModalForVersionId: draft_version.id,
      });
    });
  };

  updateChecklistTemplateToReusable = () => {
    this.setState({ isSavingTemplateAsReusable: true, showChecklistEditModalUnsavedChanges: false });
    this.props
      .updateChecklistTemplate(this.state.draftChecklistTemplateId, { reusable: true })
      .then(({ data: checklistTemplate }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.checklists.create-checklist-success" />}
          />
        );
        this.props.history.push(`/checklists/${this.state.draftChecklistTemplateId}`);
      });
  };

  renderTotalEntries = () => {
    if (
      matchPath(this.props.location.pathname, {
        path: '/checklists',
        exact: true,
      }) != null
    ) {
      return (
        <FormattedPlural
          value={this.state.totalEntries}
          zero={<FormattedMessage id="screens.checklists.total-entries-templates.zero" />}
          one={<FormattedMessage id="screens.checklists.total-entries-templates.one" />}
          two={
            <FormattedMessage
              id="screens.checklists.total-entries-templates.two"
              values={{ amount: this.state.totalEntries }}
            />
          }
          few={
            <FormattedMessage
              id="screens.checklists.total-entries-templates.few"
              values={{ amount: this.state.totalEntries }}
            />
          }
          many={
            <FormattedMessage
              id="screens.checklists.total-entries-templates.many"
              values={{ amount: this.state.totalEntries }}
            />
          }
          other={
            <FormattedMessage
              id="screens.checklists.total-entries-templates.other"
              values={{ amount: this.state.totalEntries }}
            />
          }
        />
      );
    }
    return (
      <FormattedPlural
        value={this.state.totalEntries}
        zero={<FormattedMessage id="screens.checklists.total-entries-deviations.zero" />}
        one={<FormattedMessage id="screens.checklists.total-entries-deviations.one" />}
        two={
          <FormattedMessage
            id="screens.checklists.total-entries-deviations.two"
            values={{ amount: this.state.totalEntries }}
          />
        }
        few={
          <FormattedMessage
            id="screens.checklists.total-entries-deviations.few"
            values={{ amount: this.state.totalEntries }}
          />
        }
        many={
          <FormattedMessage
            id="screens.checklists.total-entries-deviations.many"
            values={{ amount: this.state.totalEntries }}
          />
        }
        other={
          <FormattedMessage
            id="screens.checklists.total-entries-deviations.other"
            values={{ amount: this.state.totalEntries }}
          />
        }
      />
    );
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        title={<FormattedMessage id="screens.checklists.title" />}
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
        totalEntries={this.renderTotalEntries()}
        onSearch={searchTerm => {
          this.setState({ searchTerm });
        }}
        onClearSearch={() => {
          this.setState({ searchTerm: '' });
        }}
      />
    );
  };

  renderCreateButton = () => {
    return (
      <>
        <Button
          fullWidth
          primary
          label="screens.checklists.create-button"
          onClick={() => {
            this.setState({ showChecklistTitleModal: true });
          }}
        />
        <Menu.Separator />
      </>
    );
  };

  renderDeviationsSection = () => {
    return (
      <SectionHeader paddingHorizontal={20} noBorderTop={this.props.canAdministrateWorkOrders === false}>
        <FormattedMessage id="screens.checklists.deviations" />
      </SectionHeader>
    );
  };

  renderLeftMenuTemplatesInformation = () => {
    if (this.props.canAdministrateWorkOrders || this.props.isProductionSupervisor) {
      return (
        <ListLayout.Content.Menu.Content>
          {this.renderCreateButton()}
          <Menu.Item
            linkTo="/checklists"
            title={<FormattedMessage id="screens.checklists.templates" />}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/checklists',
                exact: true,
              }) != null
            }
          />
        </ListLayout.Content.Menu.Content>
      );
    }
    if (this.props.isViewOnly) {
      return (
        <ListLayout.Content.Menu.Content>
          <Menu.Item
            linkTo="/checklists"
            title={<FormattedMessage id="screens.checklists.templates" />}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/checklists',
                exact: true,
              }) != null
            }
          />
        </ListLayout.Content.Menu.Content>
      );
    }
    return null;
  };

  renderLeftMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    const { activeDeviationsCount } = this.state;
    return (
      <ListLayout.Content.Menu>
        {this.renderLeftMenuTemplatesInformation()}
        {this.renderDeviationsSection()}
        <ListLayout.Content.Menu.Content>
          <Menu.Item
            red
            linkTo="/deviations?list=active"
            number={activeDeviationsCount === 0 ? null : activeDeviationsCount}
            title={<FormattedMessage id="screens.checklists.deviations-require-action" />}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/deviations',
              }) != null && list === 'active'
            }
          />
          <Menu.Item
            linkTo="/deviations?list=history"
            title={<FormattedMessage id="screens.checklists.deviations-history" />}
            selected={
              matchPath(this.props.location.pathname, {
                path: '/deviations',
              }) != null && list === 'history'
            }
          />
        </ListLayout.Content.Menu.Content>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    return (
      <Switch>
        <Route
          exact
          path="/checklists"
          render={props => (
            <Templates
              {...props}
              searchTerm={this.state.searchTerm}
              onUpdateTotalEntries={totalEntries => this.setState({ totalEntries })}
            />
          )}
        />
        <Route
          exact
          path={['/deviations', '/deviations/:id']}
          render={props => (
            <Deviations
              {...props}
              searchTerm={this.state.searchTerm}
              onUpdateTotalEntries={totalEntries => this.setState({ totalEntries })}
              onUpdateActiveDeviationsCount={() => this.fetchActiveDeviationsCount()}
            />
          )}
        />
      </Switch>
    );
  };

  renderChecklistEditModal = () => (
    <ChecklistEditModal
      isCreatingChecklistFromLibrary
      open={this.state.showChecklistEditModal}
      id={this.state.showChecklistEditModalForVersionId}
      isSaving={this.state.isSavingTemplateAsReusable}
      onSave={this.updateChecklistTemplateToReusable}
      onClose={() => {
        this.setState({
          showChecklistEditModal: false,
          draftChecklistTemplateId: null,
          showChecklistEditModalForVersionId: null,
        });
      }}
      showUnsavedChanges={this.state.showChecklistEditModalUnsavedChanges}
    />
  );

  renderChecklistTitleModal = () => (
    <ChecklistTitleModal
      reusable
      open={this.state.showChecklistTitleModal}
      onSave={this.createChecklist}
      onClose={() => {
        this.setState({ showChecklistTitleModal: false });
      }}
    />
  );

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderChecklistEditModal()}
        {this.renderChecklistTitleModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createChecklistTemplate: SDKReduxOperations.createChecklistTemplate,
      updateChecklistTemplate: SDKReduxOperations.updateChecklistTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    isViewOnly: AuthSelectors.isViewOnly(state),
    isProductionSupervisor: AuthSelectors.isProductionSupervisor(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Checklists)));
