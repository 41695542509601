import update from 'immutability-helper';
import { mergeWith } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';
import types from './types';

const INITIAL_STATE = {
  totalEntries: 0,
  users: {
    selectedUsers: {
      pageIsSelected: false,
      showSelectTotalEntries: false,
      totalEntriesAreSelected: false,
      ids: {},
    },
    listIsEmpty: false,
    ids: [],
    pagination: {
      totalPages: 1,
      totalEntries: 0,
    },
    queryParams: {
      page: 1,
      page_size: 25,
    },
    amountOfPayingMembers: 0,
  },
  permissionProfiles: {
    ids: [],
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SELECT_PAGE: {
      if (state.users.selectedUsers.pageIsSelected) {
        return update(state, {
          users: {
            selectedUsers: {
              ids: {
                $apply: () => {
                  let ids = { ...state.users.selectedUsers.ids };
                  state.users.ids.forEach(id => {
                    delete ids[id];
                  });
                  return ids;
                },
              },
              pageIsSelected: {
                $set: false,
              },
              showSelectTotalEntries: {
                $set: false,
              },
              totalEntriesAreSelected: {
                $set: false,
              },
            },
          },
        });
      }
      return update(state, {
        users: {
          selectedUsers: {
            ids: {
              $apply: ids => {
                return state.users.ids.reduce((acc, id) => {
                  return {
                    ...acc,
                    [id]: true,
                  };
                }, state.users.selectedUsers.ids);
              },
            },
            pageIsSelected: {
              $apply: () => {
                return !state.users.selectedUsers.pageIsSelected;
              },
            },
            showSelectTotalEntries: {
              $apply: () => {
                return !state.users.selectedUsers.pageIsSelected;
              },
            },
          },
        },
      });
    }
    case types.SELECT_USER: {
      const userId = action.payload;
      if (state.users.selectedUsers.ids[userId]) {
        return update(state, {
          users: {
            selectedUsers: {
              ids: {
                $unset: [userId],
              },
              totalEntriesAreSelected: {
                $set: false,
              },
              showSelectTotalEntries: {
                $set: false,
              },
              pageIsSelected: {
                $set: false,
              },
            },
          },
        });
      } else {
        const allRowsAreSelected = state.users.ids
          .filter(id => id !== userId)
          .every(id => state.users.selectedUsers.ids[id] === true);
        return update(state, {
          users: {
            selectedUsers: {
              ids: {
                [userId]: {
                  $set: true,
                },
              },
              showSelectTotalEntries: {
                $set: allRowsAreSelected,
              },
              pageIsSelected: {
                $set: allRowsAreSelected,
              },
            },
          },
        });
      }
    }
    case types.SELECT_TOTAL_ENTRIES: {
      return update(state, {
        users: {
          selectedUsers: {
            totalEntriesAreSelected: {
              $set: true,
            },
          },
        },
      });
    }
    case types.HIDE_SELECT_TOTAL_ENTRIES: {
      return update(state, {
        users: {
          selectedUsers: {
            showSelectTotalEntries: {
              $set: false,
            },
            totalEntriesAreSelected: {
              $set: false,
            },
          },
        },
      });
    }
    case types.RESET_SELECTED_USERS: {
      return update(state, {
        users: {
          selectedUsers: {
            $set: {
              ...INITIAL_STATE.users.selectedUsers,
            },
          },
        },
      });
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        users: {
          queryParams: {
            $apply: filters =>
              mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
          },
        },
      });
    }
    case types.SET_AMOUNT_OF_PAYING_USERS: {
      return {
        ...state,
        users: {
          ...state.users,
          amountOfPayingMembers: action.payload,
        },
      };
    }
    case types.FETCH_USERS_SUCCESS: {
      const { ids, pagination, totalEntries } = action.payload;
      let allRowsAreSelected = ids.length > 0 && ids.every(id => state.users.selectedUsers.ids[id] === true);
      if (state.users.selectedUsers.totalEntriesAreSelected === true) {
        allRowsAreSelected = true;
      }
      return update(state, {
        totalEntries: {
          $set: totalEntries,
        },
        users: {
          ids: {
            $set: ids,
          },
          pagination: {
            $set: pagination,
          },
          listIsEmpty: {
            $set: ids.length === 0,
          },
          selectedUsers: {
            pageIsSelected: {
              $set: allRowsAreSelected,
            },
            showSelectTotalEntries: {
              $set: allRowsAreSelected,
            },
          },
        },
      });
    }
    case SDKReduxTypes.USER_CREATED: {
      const { data: user } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          listIsEmpty: false,
          ids: [user.id, ...state.users.ids],
          pagination: {
            ...state.users.pagination,
            totalEntries: state.users.pagination.totalEntries + 1,
          },
        },
      };
    }
    case SDKReduxTypes.USER_DELETED: {
      const { userId } = action.payload;

      return {
        ...state,
        users: {
          ...state.users,
          ids: state.users.ids.filter(id => id !== userId),
          listIsEmpty: state.users.ids.filter(id => id !== userId).length === 0,
          pagination: {
            ...state.users.pagination,
            totalEntries: state.users.pagination.totalEntries - 1,
          },
        },
      };
    }
    case SDKReduxTypes.USER_UPDATED: {
      const { data, beforeEdit } = action.payload;
      const { id: userId } = data;
      if (data.archived !== beforeEdit.archived) {
        return {
          ...state,
          users: {
            ...state.users,
            ids: state.users.ids.filter(id => id !== userId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.SYSTEM_ACCESS_DELETED: {
      const { systemAccess } = action.payload;
      const { user_id } = systemAccess;
      return {
        ...state,
        users: {
          ...state.users,
          ids: state.users.ids.filter(id => id !== user_id),
        },
      };
    }

    case types.FETCH_PERMISSION_PROFILES_SUCCESS: {
      const { ids, totalEntries } = action.payload;
      return {
        ...state,
        totalEntries,
        permissionProfiles: {
          ...state.permissionProfiles,
          ids,
        },
      };
    }
    case SDKReduxTypes.PERMISSION_PROFILE_CREATED: {
      const { data } = action.payload;
      return {
        ...state,
        permissionProfiles: {
          ...state.permissionProfiles,
          ids: [data.id, ...state.permissionProfiles.ids],
        },
      };
    }
    case SDKReduxTypes.PERMISSION_PROFILE_DELETED: {
      const { permissionProfileId } = action.payload;
      return {
        ...state,
        permissionProfiles: {
          ...state.permissionProfiles,
          ids: state.permissionProfiles.ids.filter(id => id !== permissionProfileId),
        },
      };
    }

    default:
      return state;
  }
};
