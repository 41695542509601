import types from './types';
import { isEqual } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';
import { generateEmptyWorkOrder } from 'sdk/WorkOrder';
import { RequestsTypes } from 'state/ducks/requests';

const INITIAL_STATE = {
  editingWorkOrder: null,
  editingWorkOrderBeforeEdit: null,
  hasUnsavedChanges: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case RequestsTypes.PREPARE_NEW_REQUEST:
    case RequestsTypes.SET_REQUEST_FOR_VIEW:
    case types.RESET_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.PREPARE_NEW_WORK_ORDER: {
      const payload = action.payload || {};
      const { registration_images, requests } = payload;
      const editingWorkOrderWithRequestValues = {
        ...generateEmptyWorkOrder(),
        ...payload,
        registration_images: registration_images || [],
        requests: requests || [],
      };
      return {
        ...state,
        editingWorkOrder: editingWorkOrderWithRequestValues,
        editingWorkOrderBeforeEdit: editingWorkOrderWithRequestValues,
        hasUnsavedChanges: false,
      };
    }
    case types.SET_EDITING_WORK_ORDER_VALUES: {
      const editingWorkOrder = {
        ...state.editingWorkOrder,
        ...action.payload,
      };
      return {
        ...state,
        editingWorkOrder,
        hasUnsavedChanges: !isEqual(editingWorkOrder, state.editingWorkOrderBeforeEdit),
      };
    }
    case types.CANCEL_NEW_WORK_ORDER: {
      return {
        ...state,
        editingWorkOrder: null,
      };
    }
    case SDKReduxTypes.WORK_ORDER_TYPE_DELETED: {
      const { workOrderTypeId: deletedId } = action.payload;
      const { editingWorkOrder } = state;

      if (state.editingWorkOrder == null) return state;
      return {
        ...state,
        editingWorkOrder: {
          ...editingWorkOrder,
          work_order_type_id:
            editingWorkOrder.work_order_type_id === deletedId ? null : editingWorkOrder.work_order_type_id,
        },
      };
    }
    case SDKReduxTypes.IMAGE_DRAFT_CREATED: {
      const { data, meta } = action.payload;
      const { id } = data;
      const { namespace } = meta;
      if (namespace !== 'workOrderSpentTime' && state.editingWorkOrder) {
        if (state.editingWorkOrder.completed_before_registration) {
          return {
            ...state,
            editingWorkOrder: {
              ...state.editingWorkOrder,
              completion_images: [...state.editingWorkOrder.completion_images, id],
            },
          };
        } else {
          return {
            ...state,
            editingWorkOrder: {
              ...state.editingWorkOrder,
              registration_images: [...state.editingWorkOrder.registration_images, id],
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.IMAGE_DELETED: {
      const { imageId } = action.payload;
      if (state.editingWorkOrder) {
        return {
          ...state,
          editingWorkOrder: {
            ...state.editingWorkOrder,
            registration_images: state.editingWorkOrder.registration_images.filter(id => id !== imageId),
            completion_images: state.editingWorkOrder.completion_images.filter(id => id !== imageId),
          },
        };
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_DRAFT_CREATED: {
      const { data, params, meta } = action.payload;
      if (params.type === 'link' && state.editingWorkOrder) {
        if (meta.completion) {
          return {
            ...state,
            editingWorkOrder: {
              ...state.editingWorkOrder,
              completion_attachments: [data.id, ...state.editingWorkOrder.completion_attachments],
            },
          };
        } else {
          return {
            ...state,
            editingWorkOrder: {
              ...state.editingWorkOrder,
              registration_attachments: [data.id, ...state.editingWorkOrder.registration_attachments],
            },
          };
        }
      }
      return state;
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      const { completion, workOrderId } = meta;
      if (state.editingWorkOrder == null) return state;
      if (state.editingWorkOrder.id !== workOrderId) return state;
      if (completion) {
        return {
          ...state,
          editingWorkOrder: {
            ...state.editingWorkOrder,
            completion_attachments: [data.attachment_id, ...state.editingWorkOrder.completion_attachments],
          },
        };
      } else {
        return {
          ...state,
          editingWorkOrder: {
            ...state.editingWorkOrder,
            registration_attachments: [
              data.attachment_id,
              ...state.editingWorkOrder.registration_attachments,
            ],
          },
        };
      }
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;
      if (state.editingWorkOrder == null) return state;
      return {
        ...state,
        editingWorkOrder: {
          ...state.editingWorkOrder,
          registration_attachments: state.editingWorkOrder.registration_attachments.filter(
            id => id !== attachmentId
          ),
          completion_attachments: state.editingWorkOrder.completion_attachments.filter(
            id => id !== attachmentId
          ),
        },
      };
    }
    default:
      return state;
  }
};
