const DOMAIN_PREFIX = 'checklistModal/';

const CLEAR_DEVIATION_ROWS = `${DOMAIN_PREFIX}CLEAR_DEVIATION_ROWS`;
const ADD_MULTIPLE_DEVIATION_ROWS = `${DOMAIN_PREFIX}ADD_MULTIPLE_DEVIATION_ROWS`;
const ADD_DEVIATION_ROW = `${DOMAIN_PREFIX}ADD_DEVIATION_ROW`;
const REMOVE_DEVIATION_ROW = `${DOMAIN_PREFIX}REMOVE_DEVIATION_ROW`;
const SELECT_DEVIATION_TAB = `${DOMAIN_PREFIX}SELECT_DEVIATION_TAB`;
const SET_CHECKLIST_INSTANCE = `${DOMAIN_PREFIX}SET_CHECKLIST_INSTANCE`;
const SET_CHECKLIST_TEMPLATE = `${DOMAIN_PREFIX}SET_CHECKLIST_TEMPLATE`;
const SET_IS_EDITABLE = `${DOMAIN_PREFIX}SET_IS_EDITABLE`;
const SELECT_MENU_ITEM = `${DOMAIN_PREFIX}SELECT_MENU_ITEM`;
const RESET_STATE = `${DOMAIN_PREFIX}RESET_STATE`;

export default {
  CLEAR_DEVIATION_ROWS,
  ADD_MULTIPLE_DEVIATION_ROWS,
  SET_CHECKLIST_INSTANCE,
  SET_CHECKLIST_TEMPLATE,
  SET_IS_EDITABLE,
  SELECT_MENU_ITEM,
  RESET_STATE,
  ADD_DEVIATION_ROW,
  REMOVE_DEVIATION_ROW,
  SELECT_DEVIATION_TAB,
};
