import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Icon } from 'views/components/Shared/General';
import { TreePath } from 'views/components/Asset';
import styles from './style.module.scss';

class SelectedAssetContainer extends Component {
  render() {
    return (
      <div className={styles['item']}>
        <div className={styles['icon-container']}>
          <Icon regular type="box" />
        </div>
        <div className={styles['content']}>
          <p>
            {this.props.settings.asset_number_activated ? (
              <span className={styles['number']}>#{this.props.asset.number} - </span>
            ) : null}
            <span>{this.props.asset.title}</span>
          </p>
          <TreePath onlyShowPath assetId={this.props.id} />
        </div>
        <div className={styles['clear-container']}>
          <Button type="icon" icon={<Icon light size={15} type="times" />} onClick={this.props.onClear} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.id),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(SelectedAssetContainer);
