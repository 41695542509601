import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { List, Pagination, NewSearchField, EmptyDataSet } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { API, HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeChecklistTemplate } from 'sdk/Schemas';
import ChecklistTemplateItem from './ChecklistTemplateItem';
import styles from './styles.module.scss';

const PAGE_SIZE = 6;

class SelectChecklistTemplateModal extends Component {
  getInitialState = () => ({
    ids: [],
    isFetching: true,
    isSearching: false,
    page: 1,
    totalPages: 1,
    searchValue: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
      this.listChecklistTemplates({ page_size: PAGE_SIZE }).then(({ totalPages, ids }) => {
        this.setState({
          totalPages,
          ids,
          isFetching: false,
        });
      });
    }
  }

  changePage = page => {
    this.setState({ page, isSearching: true }, () => {
      this.listChecklistTemplates({ page_size: PAGE_SIZE, page }).then(({ totalPages, ids }) => {
        this.setState({
          totalPages,
          ids,
          isSearching: false,
        });
      });
    });
  };

  listChecklistTemplates = () => {
    const params = {
      page: this.state.page,
      search: this.state.searchValue,
    };
    return API.listChecklistTemplates(this.props.system.id, {
      page_size: PAGE_SIZE,
      reusable: true,
      ...params,
    }).then(({ data: checklistTemplates, headers }) => {
      const { entities, result } = normalizeChecklistTemplate(checklistTemplates);
      this.props.updateEntities(entities);
      const { totalPages } = HelperFunctions.getPaginationFromHeader(headers);
      return {
        totalPages,
        ids: result,
      };
    });
  };

  renderPagination = () => {
    if (this.state.totalPages > 1) {
      return (
        <div className={styles['pagination-container']}>
          <Pagination
            hideOptions
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onSelectPage={page => {
              this.changePage(page);
            }}
          />
        </div>
      );
    }
    return null;
  };

  renderChecklistTemplates = () => {
    if (this.state.isFetching) {
      return (
        <List light>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item>
            <List.Item.TitleColumn loading />
          </List.Item>
        </List>
      );
    }
    if (this.state.isSearching) {
      const amountOfLoaderItems = this.state.ids.length === 0 ? 2 : this.state.ids.length;
      return (
        <>
          <List light>
            {Array(amountOfLoaderItems)
              .fill()
              .map(() => (
                <List.Item>
                  <List.Item.TitleColumn loading />
                </List.Item>
              ))}
          </List>
          {this.renderPagination()}
        </>
      );
    }
    if (this.state.ids.length === 0) {
      if (this.state.searchValue.length === 0) {
        return (
          <EmptyDataSet
            title={<FormattedMessage id="components.select-checklist-modal.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.select-checklist-modal.empty-data-set.subtitle" />}
            modal
          />
        );
      }
      return (
        <EmptyDataSet
          title={<FormattedMessage id="general.empty-data-set-search.title" />}
          subtitle={<FormattedMessage id="general.empty-data-set-search.subtitle" />}
          modal
        />
      );
    }
    return (
      <>
        <List light>
          {this.state.ids.map(id => (
            <ChecklistTemplateItem
              id={id}
              key={id}
              onSelect={checklistTemplate => this.props.onSelect(checklistTemplate)}
              onPreview={checklistTemplate => this.props.onPreview(checklistTemplate)}
            />
          ))}
        </List>
        {this.renderPagination()}
      </>
    );
  };

  renderSearchField = () => {
    return (
      <div className={styles['search-field']}>
        <NewSearchField
          value={this.state.searchValue}
          debounce
          placeholder={this.props.intl.formatMessage({
            id: 'general.search-placeholder',
          })}
          onSearch={value => {
            this.setState({
              page: 1,
              searchValue: value,
              isSearching: true,
            });
          }}
          onDebouncedSearch={() => {
            this.listChecklistTemplates().then(({ ids, totalPages }) => {
              this.setState({
                totalPages,
                ids,
                isSearching: false,
              });
            });
          }}
          onClear={() => {
            this.setState({ page: 1, isSearching: true, searchValue: '' }, () => {
              this.listChecklistTemplates().then(({ ids, totalPages }) => {
                this.setState({
                  totalPages,
                  ids,
                  isSearching: false,
                });
              });
            });
          }}
        />
      </div>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.renderSearchField()}
        {this.renderChecklistTemplates()}
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.select-checklist-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectChecklistTemplateModal));
