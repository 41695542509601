import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Button } from 'views/components/Shared/General';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';
import Assets from './Assets';
import SettingsForAsset from './SettingsForAsset';
import SettingsForTemplateRecurrence from './SettingsForTemplateRecurrence';
import Versions from './Versions';
import styles from './style.module.scss';

const Tabs = {
  Asset: 'asset',
  Versions: 'versions',
};
class Information extends Component {
  state = {
    tab: Tabs.Asset,
    showSettingsForOperationalMaintenanceTemplateAssetId: null,
    showSettingsForTemplateRecurrenceId: null,
  };

  renderSettings = () => {
    if (this.state.showSettingsForTemplateRecurrenceId) {
      return (
        <SettingsForTemplateRecurrence
          operationalMaintenanceTemplateRecurrenceId={this.state.showSettingsForTemplateRecurrenceId}
          onshowSettingsForTemplateRecurrenceId={id =>
            this.setState({
              showSettingsForTemplateRecurrenceId: id,
            })
          }
          onCancel={() => this.setState({ showSettingsForTemplateRecurrenceId: null })}
        />
      );
    }
    if (this.state.showSettingsForOperationalMaintenanceTemplateAssetId) {
      return (
        <SettingsForAsset
          operationalMaintenanceTemplateAssetId={
            this.state.showSettingsForOperationalMaintenanceTemplateAssetId
          }
          onShowSettingsForTemplateRecurrenceId={id =>
            this.setState({
              showSettingsForTemplateRecurrenceId: id,
            })
          }
          onCancel={() => this.setState({ showSettingsForOperationalMaintenanceTemplateAssetId: null })}
        />
      );
    }
    return (
      <>
        {this.renderTabs()}
        {this.state.tab === Tabs.Asset ? (
          <Assets
            onShowSettingsForTemplateAssetId={operationalMaintenanceTemplateAssetId =>
              this.setState({
                showSettingsForOperationalMaintenanceTemplateAssetId: operationalMaintenanceTemplateAssetId,
              })
            }
          />
        ) : (
          <Versions />
        )}
      </>
    );
  };

  renderTabs = () => {
    return (
      <div className={styles['tabs']}>
        <div
          className={`${styles['tab']} ${this.state.tab === Tabs.Asset ? styles['active'] : ''}`}
          onClick={() => this.setState({ tab: Tabs.Asset })}
        >
          <FormattedMessage id="screens.operational-maintenance-template.settings.tabs.asset" />
        </div>
        <div
          className={`${styles['tab']} ${this.state.tab === Tabs.Versions ? styles['active'] : ''}`}
          onClick={() => this.setState({ tab: Tabs.Versions })}
        >
          <FormattedMessage id="screens.operational-maintenance-template.settings.tabs.versions" />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles['settings']}>
        <PerfectScrollbar>{this.renderSettings()}</PerfectScrollbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const operationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    operationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  const operationalMaintenanceTemplateAssets = EntitySelectors.getOperationalMaintenanceTemplateAssets(
    state,
    operationalMaintenanceTemplate.operational_maintenance_template_assets
  );
  return {
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplate,
    assets: EntitySelectors.getAssets(
      state,
      operationalMaintenanceTemplateAssets.map(({ asset_id }) => asset_id)
    ),
  };
}

export default connect(mapStateToProps)(Information);
