import types from './types';
import { SDKReduxTypes } from 'sdk';

const INITIAL_STATE = {
  assetId: null,
  folderId: null,
  list: {
    ids: [],
    paginateFrom: null,
    totalEntries: 0,
    isFullyLoaded: false,
  },
  search: {
    ids: [],
    paginateFrom: null,
    totalEntries: 0,
    isFullyLoaded: false,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.FETCH_LIST_ATTACHMENTS_BEGIN: {
      const { initialLoad } = action.payload;
      if (!initialLoad) return state;

      return {
        ...state,
        list: {
          ...state.list,
          ids: [],
        },
      };
    }
    case types.FETCH_LIST_ATTACHMENTS_SUCCESS: {
      const { ids, paginateFrom, isFullyLoaded, totalEntries } = action.payload;

      return {
        ...state,
        list: {
          ids,
          paginateFrom,
          isFullyLoaded,
          totalEntries,
        },
      };
    }
    case types.FETCH_MORE_LIST_ATTACHMENTS_SUCCESS: {
      const { ids, paginateFrom, isFullyLoaded } = action.payload;

      return {
        ...state,
        list: {
          ids: [...state.list.ids, ...ids],
          paginateFrom,
          isFullyLoaded,
        },
      };
    }
    case types.FETCH_SEARCH_ATTACHMENTS_SUCCESS: {
      const { ids, paginateFrom, isFullyLoaded, totalEntries } = action.payload;

      return {
        ...state,
        search: {
          ids,
          paginateFrom,
          isFullyLoaded,
          totalEntries,
        },
      };
    }
    case types.FETCH_MORE_SEARCH_ATTACHMENTS_SUCCESS: {
      const { ids, paginateFrom, isFullyLoaded } = action.payload;

      return {
        ...state,
        search: {
          ids: [...state.search.ids, ...ids],
          paginateFrom,
          isFullyLoaded,
        },
      };
    }
    case types.SET_FOLDER_ID: {
      return {
        ...state,
        folderId: action.payload,
      };
    }
    case types.SET_ASSET_ID: {
      return {
        ...state,
        assetId: action.payload,
      };
    }
    case SDKReduxTypes.ATTACHMENT_VERSION_UPLOADED: {
      const { data, meta } = action.payload;
      if (!meta.attachment || meta.attachment.attachment_folder_id !== state.folderId) return state;
      if (meta && meta.assetId !== state.assetId) return state;
      return {
        ...state,
        list: {
          ...state.list,
          ids: [data.attachment_id, ...state.list.ids],
        },
      };
    }
    case SDKReduxTypes.ATTACHMENT_UPDATED: {
      const { params, data } = action.payload;
      if (params.attachment_folder_id === undefined) return state;

      if (params.attachment_folder_id === state.folderId) {
        if (state.list.ids.includes(data.id)) return state;

        return {
          ...state,
          list: {
            ...state.list,
            ids: [data.id, ...state.list.ids],
          },
        };
      } else {
        return {
          ...state,
          list: {
            ...state.list,
            ids: state.list.ids.filter(id => id !== data.id),
          },
        };
      }
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_ASSET: {
      const { data, params } = action.payload;
      if (data.attachment_folder_id !== state.folderId) return state;
      if (params.type !== 'link') return state;

      return {
        ...state,
        list: {
          ...state.list,
          ids: [data.id, ...state.list.ids],
        },
      };
    }
    case SDKReduxTypes.ATTACHMENT_CREATED_FOR_MULTIPLE_ASSETS: {
      const { data: attachment, params } = action.payload;
      if (attachment.attachment_folder_id !== state.folderId) return state;
      if (params.type !== 'link') return state;

      return {
        ...state,
        list: {
          ...state.list,
          ids: [attachment.id, ...state.list.ids],
        },
      };
    }
    case SDKReduxTypes.ATTACHMENT_REMOVED_FROM_ASSET: {
      const { attachmentId, assetId } = action.payload;

      if (assetId !== state.assetId) return state;

      return {
        ...state,
        list: {
          ...state.list,
          ids: state.list.ids.filter(id => id !== attachmentId),
        },
        search: {
          ...state.search,
          ids: state.search.ids.filter(id => id !== attachmentId),
        },
      };
    }
    case SDKReduxTypes.ATTACHMENT_DELETED: {
      const { attachmentId } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          ids: state.list.ids.filter(id => id !== attachmentId),
        },
        search: {
          ...state.search,
          ids: state.search.ids.filter(id => id !== attachmentId),
        },
      };
    }
    default:
      return state;
  }
};
