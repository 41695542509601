export default {
  SET_NAVIGATED_TO: 'assets/set_navigated_to',
  RESET_SELECTED_ASSETS: 'assets/reset_selected_assets',
  TOGGLE_ALL_SELECTED_ASSETS: 'assets/toggle_all_selected_assets',
  SELECT_PAGE: 'assets/select_page',
  SELECT_ASSET: 'assets/select_asset',
  SELECT_TOTAL_ENTRIES: 'assets/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'assets/hide_select_total_entries',
  ADD_QUERY_PARAMETER: 'assets/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'assets/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'assets/show_available_filters',
  SELECT_FILTER_TYPE: 'assets/select_filter_type',
  ADD_FILTER: 'assets/add_filter',
  REMOVE_FILTER: 'assets/remove_filter',
  RESET_FILTER: 'assets/reset_filter',
  FETCH_ASSETS_SUCCESS: 'assets/fetch_assets_success',
  FETCH_TREE: 'assets/fetch_tree',
  FETCH_TREE_SUCCESS: 'assets/fetch_tree_success',
  EXPAND_TREE: 'assets/expand_tree',
  COLLAPSE_TREE: 'assets/collapse_tree',
  MOVE_TREE: 'assets/move_tree',
};
