import React from 'react';
import { Button, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import List from './List';

const Banner = ({
  green = false,
  orange = false,
  red = false,
  children,
  button = false,
  buttonLabel = null,
  onClick = () => {},
  closeable = false,
  onClose = () => {},
}) => {
  let classNames = [styles['banner']];
  if (green) {
    classNames = [...classNames, styles['green']];
  }
  if (orange) {
    classNames = [...classNames, styles['orange']];
  }
  if (red) {
    classNames = [...classNames, styles['red']];
  }

  const renderButton = () => {
    if (button) {
      return (
        <div className={styles['button']} onClick={onClick}>
          {buttonLabel}
        </div>
      );
    }
  };
  const renderClose = () => {
    if (closeable) {
      return (
        <div className={styles['close']} onClick={onClose}>
          <Button type="icon" icon={<Icon light size={16} type="times" />} onClick={onClose} />
        </div>
      );
    }
  };

  return (
    <div className={classNames.join(' ')}>
      <div className={styles['title']}>{children}</div>
      <div className={styles['right-container']}>
        {renderButton()}
        {renderClose()}
      </div>
    </div>
  );
};

Banner.List = List;
export default Banner;
