import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { API } from 'sdk';
import { normalizeDowntimeReason } from 'sdk/Schemas';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';

class DowntimeReasonInlineModal extends Component {
  getInititalState = () => ({
    isEmpty: false,
    isFetching: true,
    isOpen: false,
    downtimeReasons: [],
    searchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInititalState();
  }

  onOpen = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      return;
    }
    this.fetchDowntimeReasons();
  };

  fetchDowntimeReasons = () => {
    this.setState({ ...this.getInititalState(), isOpen: true, isFetching: true });
    API.listDowntimeReasons(this.props.system.id, { no_pagination: true }).then(
      ({ data: downtimeReasons }) => {
        const { entities, result } = normalizeDowntimeReason(downtimeReasons);
        this.props.updateEntities(entities);
        this.setState(
          {
            isEmpty: downtimeReasons.length === 0,
            isFetching: false,
            downtimeReasons: result.map(id => entities.downtimeReasonById[id]),
          },
          () => {
            if (this.inlineModalSearchRef) {
              this.inlineModalSearchRef.focus();
            }
          }
        );
      }
    );
  };

  selectDowntimeReason = downtimeReasonId => {
    if (this.props.multiple) {
      if (this.props.selectedDowntimeReasonIds.includes(downtimeReasonId)) {
        this.props.onRemoveDowntimeReason(downtimeReasonId);
      } else {
        this.props.onAddDowntimeReason(downtimeReasonId);
      }
    } else {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.onSelectDowntimeReason(downtimeReasonId);
        }
      );
    }
  };

  renderDowntimeReasonItem = downtimeReason => {
    if (this.props.multiple) {
      const selected = this.props.selectedDowntimeReasonIds.includes(downtimeReason.id);
      return (
        <NewInlineModal.Dropdown.Item
          key={downtimeReason.id}
          onClick={e => this.selectDowntimeReason(downtimeReason.id)}
          leftComponent={
            <Field.Checkbox
              checked={selected}
              onChange={() => {
                this.selectDowntimeReason(downtimeReason.id);
              }}
            />
          }
        >
          {downtimeReason.title}
        </NewInlineModal.Dropdown.Item>
      );
    } else {
      const selected = this.props.selectedDowntimeReasonId === downtimeReason.id;
      return (
        <NewInlineModal.Dropdown.Item
          key={downtimeReason.id}
          selected={selected}
          onClick={e => this.selectDowntimeReason(downtimeReason.id)}
        >
          <span>{downtimeReason.title}</span>
        </NewInlineModal.Dropdown.Item>
      );
    }
  };

  renderClearItem = () => {
    const { clearable, multiple, selectedDowntimeReasonId } = this.props;
    if (clearable && multiple === false && selectedDowntimeReasonId) {
      return (
        <>
          <NewInlineModal.Dropdown.Item
            onClick={e => {
              this.setState({ isOpen: false });
              this.props.onClear();
            }}
          >
            <FormattedMessage id="general.clean" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Separator />
        </>
      );
    }
    return null;
  };

  renderDowntimeReasons = () => {
    const { searchTerm } = this.state;
    const filteredDowntimeReasons = this.state.downtimeReasons.filter(
      wot => searchTerm === '' || wot.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredDowntimeReasons.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return filteredDowntimeReasons.map(downtimeReasonItem =>
      this.renderDowntimeReasonItem(downtimeReasonItem)
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    if (this.state.isEmpty) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="components.downtime-reason-inline-modal.empty-data-set.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          {this.renderClearItem()}
          {this.renderDowntimeReasons()}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  renderHeader = () => {
    if (this.state.downtimeReasons.length > 10) {
      return (
        <NewInlineModal.Header>
          <NewInlineModal.Header.Search
            ref={ref => (this.inlineModalSearchRef = ref)}
            placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
            value={this.state.searchTerm}
            onChange={searchTerm => {
              this.setState({ searchTerm });
            }}
            onClear={() => {
              this.setState({ searchTerm: '' });
            }}
          />
        </NewInlineModal.Header>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalPositioningRef = ref)} onClick={this.onOpen}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.isOpen}
          minWidth={300}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          position={this.props.position}
        >
          {this.renderHeader()}
          {this.renderContent()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DowntimeReasonInlineModal));

DowntimeReasonInlineModal.propTypes = {
  selectedDowntimeReasonId: PropTypes.string,
  selectedDowntimeReasonIds: PropTypes.array,
  onSelectDowntimeReason: PropTypes.func,
  onAddDowntimeReason: PropTypes.func,
  onRemoveDowntimeReason: PropTypes.func,
  onClear: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
};

DowntimeReasonInlineModal.defaultProps = {
  selectedDowntimeReasonId: null,
  selectedDowntimeReasonIds: [],
  onSelectDowntimeReason: () => {},
  onAddDowntimeReason: () => {},
  onRemoveDowntimeReason: () => {},
  onClear: () => {},
  multiple: false,
  clearable: true,
};
