import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { TemplateFieldType } from 'sdk/TemplateField';
import { Button, Field } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { SettingsOperations, SettingsSelectors } from 'state/ducks/settings';
import styles from './style.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SDKReduxOperations } from 'sdk';
import ManageItems from './ManageItems';

class Editor extends Component {
  state = {
    isSaving: false,
    isDeleting: false,
    showManageValues: false,
    showConfirmDeleteFieldModal: false,
    confirmDeleteField: false,
  };

  componentDidUpdate(prevProps) {
    const { id } = prevProps.selectedTemplateField || {};
    if (this.props.selectedTemplateField && this.props.selectedTemplateField.id !== id) {
      this.setState({ showManageValues: false });
    }
  }

  createTemplateField = field => {
    this.setState({ isSaving: true });
    this.props
      .createTemplateField(this.props.selectedSystem.id, { ...field, template_type: this.props.templateType })
      .then(res => {
        this.setState({ isSaving: false, isCreatingFirstField: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.create-item-success" />}
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  updateTemplateField = field => {
    this.setState({ isSaving: true });
    this.props
      .updateTemplateField(field.id, { title: field.title })
      .then(res => {
        this.setState({ isSaving: false });
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.update-item-success" />}
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  deleteTemplateField = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteTemplateField(this.props.selectedTemplateField.id)
      .then(() => {
        this.setState({ showConfirmDeleteFieldModal: false });
        this.props.unselectTemplateField();
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="screens.settings.assets.template-fields.delete-success" />}
          />
        );
      })
      .catch(() => {
        //TODO: Show general error message
      });
  };

  selectFieldType = type => {
    const { column } = this.props.selectedTemplateField || {};
    this.props.prepareNewField({ column, type });
  };

  renderSelectFieldContent = () => (
    <div className={styles['container']}>
      <header>
        <div className={styles['title-container']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.settings.assets.template-fields.select-field" />
          </p>
        </div>
      </header>
      <div
        className={`${styles['select-field-type']}`}
        onClick={() => this.selectFieldType(TemplateFieldType.Text)}
      >
        <FormattedMessage id="resources.template-field.types.text" />
      </div>
      <div
        className={`${styles['select-field-type']}`}
        onClick={() => this.selectFieldType(TemplateFieldType.ValueList)}
      >
        <FormattedMessage id="resources.template-field.types.dropdown" />
      </div>
      <div
        className={`${styles['select-field-type']}`}
        onClick={() => this.selectFieldType(TemplateFieldType.Date)}
      >
        <FormattedMessage id="resources.template-field.types.date" />
      </div>
      <div
        className={`${styles['select-field-type']}`}
        onClick={() => this.selectFieldType(TemplateFieldType.TextArea)}
      >
        <FormattedMessage id="resources.template-field.types.textarea" />
      </div>
    </div>
  );

  renderEditField = () => {
    const { type } = this.props.selectedTemplateField || {};
    return (
      <>
        <header>
          <div className={styles['title-container']}>
            <p className={styles['title']}>
              <FormattedMessage id="screens.settings.assets.template-fields.edit-field-title" />
            </p>
            <p className={styles['subtitle']}>
              <FormattedMessage id="screens.settings.assets.template-fields.edit-field-subtitle" />
            </p>
          </div>
        </header>
        <Field label={<FormattedMessage id="resources.template-field.title" />}>
          <Field.Text
            value={this.props.selectedTemplateField.title}
            onChange={title => this.props.prepareNewField({ ...this.props.selectedTemplateField, title })}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.updateTemplateField(this.props.selectedTemplateField);
              }
            }}
          />
        </Field>
        <div className={styles['save-button']}>
          <Button
            primary
            label="general.save"
            loading={this.state.isSaving}
            disabled={!this.props.selectedTemplateField.title}
            onClick={() => this.updateTemplateField(this.props.selectedTemplateField)}
          />
        </div>
        <div className={styles['more-buttons']}>
          {type === TemplateFieldType.ValueList ? (
            <Button
              type="text"
              primary
              noUnderline
              label="screens.settings.assets.template-fields.manage-items"
              onClick={() => this.setState({ showManageValues: true })}
            />
          ) : null}
          <Button
            type="text"
            noUnderline
            destructive
            label="screens.settings.assets.template-fields.delete-title"
            onClick={() =>
              this.setState({
                showConfirmDeleteFieldModal: true,
                isDeleting: false,
                confirmDeleteField: false,
              })
            }
          />
        </div>
      </>
    );
  };

  renderEditFieldContent = () => {
    return (
      <div className={styles['container']}>
        {this.state.showManageValues ? (
          <ManageItems
            onGoBack={() => this.setState({ showManageValues: false })}
            templateType={this.props.templateType}
          />
        ) : (
          this.renderEditField()
        )}
      </div>
    );
  };

  renderNewField = () => {
    return (
      <>
        <Field label={<FormattedMessage id="resources.template-field.title" />}>
          <Field.Text
            autoFocus
            value={this.props.selectedTemplateField.title}
            onChange={title => this.props.prepareNewField({ ...this.props.selectedTemplateField, title })}
            onKeyPress={e => {
              if (e.key === 'Enter' && this.props.selectedTemplateField.title) {
                this.createTemplateField(this.props.selectedTemplateField);
              }
            }}
          />
        </Field>
        <div className={styles['save-button']}>
          <Button
            primary
            label="general.save"
            loading={this.state.isSaving}
            disabled={!this.props.selectedTemplateField.title}
            onClick={() => this.createTemplateField(this.props.selectedTemplateField)}
          />
        </div>
      </>
    );
  };

  renderDeleteFieldConfirmModal = () => (
    <Modal width={480} isOpen={this.state.showConfirmDeleteFieldModal}>
      <Modal.Header
        title={<FormattedMessage id="components.asset-delete.confirm-title" />}
        onClose={() => this.setState({ showConfirmDeleteFieldModal: false })}
        ignoreLine
      />
      <Modal.Content>
        <div className={styles['message']}>
          {this.props.templateType === 'asset' ? (
            <FormattedMessage id="screens.settings.assets.template-fields.delete-field-confirm-message" />
          ) : (
            <FormattedMessage id="screens.settings.spare-parts.template-fields.delete-field-confirm-message" />
          )}
        </div>
        <Field.Checkbox
          checked={this.state.confirmDeleteField}
          onChange={value => this.setState({ confirmDeleteField: value })}
          label={<FormattedMessage id="components.asset-delete.confirm-checkbox-text" />}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button.Group>
          <Button
            destructive
            disabled={!this.state.confirmDeleteField}
            primary
            label={<FormattedMessage id="general.delete" />}
            loading={this.state.isDeleting}
            onClick={this.deleteTemplateField}
            translate={false}
          />
          <Button
            label="general.cancel"
            onClick={() => this.setState({ showConfirmDeleteFieldModal: false })}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderNewFieldContent = () => {
    return (
      <div className={styles['container']}>
        <header>
          <div className={styles['title-container']}>
            <p className={styles['title']}>
              <FormattedMessage id="screens.settings.assets.template-fields.select-field" />
            </p>
          </div>
        </header>
        {this.renderNewField()}
      </div>
    );
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-data-set-text']}>
      <FormattedMessage id="screens.settings.assets.template-fields.select-field-to-edit-text" />
    </div>
  );

  renderContent = () => {
    const { type, id } = this.props.selectedTemplateField || {};
    if (this.props.selectedTemplateField == null) {
      return this.renderEmptyDataSet();
    }
    if (type == null) {
      return this.renderSelectFieldContent();
    }
    if (id) {
      return this.renderEditFieldContent();
    }
    return this.renderNewFieldContent();
  };

  render() {
    return (
      <>
        <div className={styles['editor']}>{this.renderContent()}</div>
        {this.renderDeleteFieldConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTemplateFields: SettingsOperations.fetchTemplateFields,
      updateTemplateField: SDKReduxOperations.updateTemplateField,
      createTemplateField: SDKReduxOperations.createTemplateField,
      deleteTemplateField: SDKReduxOperations.deleteTemplateField,
      sortTemplateField: SDKReduxOperations.sortTemplateField,
      selectTemplateField: SettingsOperations.selectTemplateField,
      unselectTemplateField: SettingsOperations.unselectTemplateField,
      prepareNewField: SettingsOperations.prepareNewField,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    selectedSystem: AuthSelectors.getCurrentSystem(state),
    selectedTemplateField: SettingsSelectors.getSelectedTemplateField(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Editor));
