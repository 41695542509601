import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { startCase } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { injectIntl } from 'react-intl';
import styles from './style.module.scss';

const GRAPH_TIME_TYPES = {
  Month: 'month',
  Year: 'year',
  Day: 'day',
};
class Graph extends Component {
  state = {
    dates: [],
    values: [],
    hasNoGraphData: false,
    graphTimeType: GRAPH_TIME_TYPES.month,
  };

  componentDidMount() {
    const meterData = this.props.meter.graph_data || [];
    if (meterData.length <= 1) {
      this.setState({ hasNoGraphData: true });
    } else {
      this.buildGraph();
    }
  }

  componentDidUpdate(prevProps) {
    const prevMeterData = prevProps.meter.graph_data || [];
    const meterData = this.props.meter.graph_data || [];
    if (prevMeterData.length !== meterData.length) {
      if (meterData.length <= 1) {
        this.setState({ hasNoGraphData: true });
      } else {
        this.buildGraph();
      }
    }
  }

  buildGraph = () => {
    var dateArray = [];
    var valueArray = [];
    const firstDate = this.props.meter.graph_data[0][0];
    const lastDate = this.props.meter.graph_data[this.props.meter.graph_data.length - 1][0];
    let graphTimeType = null;
    if (moment(lastDate).diff(firstDate, 'days') < 60) {
      graphTimeType = GRAPH_TIME_TYPES.Day;
    } else if (moment(lastDate).diff(firstDate, 'days') < 365) {
      graphTimeType = GRAPH_TIME_TYPES.Month;
    } else {
      graphTimeType = GRAPH_TIME_TYPES.Year;
    }
    this.props.meter.graph_data.forEach(data => {
      const date = data[0];
      const value = data[1];
      let formattedDate = date;
      if (graphTimeType === GRAPH_TIME_TYPES.Year) {
        formattedDate = moment(date).format('YYYY');
      } else if (graphTimeType === GRAPH_TIME_TYPES.Month) {
        formattedDate = moment(date).format('YYYY-MM');
      }
      dateArray = [...dateArray, formattedDate];
      valueArray = [...valueArray, value];
    });
    this.setState({
      graphTimeType,
      dates: dateArray,
      values: valueArray,
      hasNoGraphData: false,
    });
  };

  render() {
    if (this.state.hasNoGraphData) {
      return (
        <div className={styles['no-data']}>
          <FormattedMessage id="screens.meter.tabs.overview.no-graph-data" />
        </div>
      );
    }
    return (
      <Line
        width={550}
        height={380}
        data={{
          labels: this.state.dates,
          datasets: [
            {
              fill: false,
              borderWidth: 2,
              data: this.state.values,
              borderColor: '#4c9ac8',
            },
          ],
        }}
        options={{
          legend: false,
          tooltips: {
            enabled: false,
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: this.state.graphTimeType,
                  displayFormats: {
                    day: 'D MMM',
                    month: 'MMM YYYY',
                    year: 'YYYY',
                  },
                },
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  padding: 13,
                  autoSkip: true,
                  fontStyle: '600',
                  maxTicksLimit: 3,
                  callback: function (value, index, ticks) {
                    return startCase(value);
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  padding: 13,
                  beginAtZero: true,
                  fontStyle: '600',
                  maxTicksLimit: 3,
                  callback: (value, index, values) => {
                    if (this.props.meterUnit) {
                      return `${value} ${this.props.meterUnit.abbreviation}`;
                    }
                    return value;
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
          },
          elements: {
            line: {
              tension: 0,
            },
            point: {
              radius: 0,
            },
          },
        }}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.match.params.id);
  return {
    meter,
    meterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
  };
}
export default withRouter(injectIntl(connect(mapStateToProps)(Graph)));
