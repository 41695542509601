import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeDowntime } from 'sdk/Schemas';
import { API, HelperFunctions } from 'sdk';

const addQueryParameter = actions.addQueryParameter;
const selectPage = actions.selectPage;
const selectDowntime = actions.selectDowntime;
const resetSelectedDowntimes = actions.resetSelectedDowntimes;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;
const selectTotalEntries = actions.selectTotalEntries;

const listDowntimes = (systemId, params, config) => dispatch => {
  return API.listDowntimes(systemId, params, config).then(({ data: downtimes, headers }) => {
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeDowntime(downtimes);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchDowntimesSuccess({
        ids: result,
        pagination,
      })
    );
    return downtimes;
  });
};

export default {
  listDowntimes,
  addQueryParameter,
  selectPage,
  selectDowntime,
  resetSelectedDowntimes,
  hideSelectTotalEntries,
  selectTotalEntries,
};
