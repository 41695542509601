import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { List, Icon, Loader } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class UserListItem extends Component {
  renderIcon = () => {
    return (
      <ProfilePicture
        fontSize={13}
        size={28}
        userId={this.props.user.id}
        defaultElement={<Icon type="user" withBackground backgroundSize={28} />}
      />
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.Column width={30} loading>
            <Icon type="user" withBackground backgroundSize={28} />
          </List.Item.Column>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return (
      <List.Item
        clickable
        onClick={() => {
          this.props.onSignInGroupUser(this.props.user);
        }}
      >
        <List.Item.Column width={30}>{this.renderIcon()}</List.Item.Column>
        <List.Item.TitleColumn title={<UserNameWrapper user={this.props.user} />} />
        <List.Item.Column width={30}>
          {this.props.isSigningInUserId === this.props.id ? <Loader tiny /> : null}
        </List.Item.Column>
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
  };
}

export default connect(mapStateToProps, null)(UserListItem);
