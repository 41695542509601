import React from 'react';
import styles from './style.module.scss';
import { RowType } from 'sdk/QRTemplateRow';
import { FormattedMessage } from 'react-intl';

export default function NewField(props) {
  function renderTitle() {
    switch (props.rowType) {
      case RowType.SparePartTitle:
        return <FormattedMessage id="resources.spare-part.title" />;
      case RowType.SparePartArticleNumber:
        return <FormattedMessage id="resources.spare-part.article-number" />;
      case RowType.SparePartDescription:
        return <FormattedMessage id="resources.spare-part.description" />;
      case RowType.SparePartLocation:
        return <FormattedMessage id="resources.spare-part.location" />;
      case RowType.SparePartType:
        return <FormattedMessage id="resources.spare-part.category" />;
      case RowType.SparePartUnit:
        return <FormattedMessage id="resources.spare-part.unit" />;
      case RowType.AssetTitle:
        return <FormattedMessage id="resources.asset.title" />;
      case RowType.AssetNumber:
        return <FormattedMessage id="resources.asset.number" />;
      case RowType.AssetType:
        return <FormattedMessage id="resources.asset.type" />;
      case RowType.Qr:
        return <FormattedMessage id="components.print-qr-codes.qr-code" />;
      case RowType.TemplateField:
        return props.title;
      default:
        return null;
    }
  }

  return (
    <div className={styles['field']}>
      <div className={styles['drag-handle']}>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
      </div>
      <div>{renderTitle()}</div>
    </div>
  );
}
