import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Icon } from 'views/components/Shared/General';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, SectionHeader, Tooltip } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { HelperFunctions } from 'sdk';
import { CalendarSelectors, CalendarOperations } from 'state/ducks/calendar';
import styles from '../style.module.scss';
import WorkOrderListItem from './WorkOrderListItem';

const workOrdersRequest = HelperFunctions.getCancelTokenForRequest();
const meterBasedWorkOrdersRequest = HelperFunctions.getCancelTokenForRequest();

class Manage extends Component {
  state = {
    isFetching: true,
  };

  componentDidMount() {
    Promise.all([
      this.fetchWorkOrders(),
      this.props.fetchWorkOrdersToPlanAndIsMeterBased(
        this.props.currentSystem.id,
        meterBasedWorkOrdersRequest.getCancelTokenConfig()
      ),
    ])
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workOrders.length >= 20 && this.props.workOrders.length < 20) {
      this.fetchMoreWorkOrders();
    }
  }

  componentWillUnmount() {
    workOrdersRequest.cancel();
    meterBasedWorkOrdersRequest.cancel();
  }

  fetchWorkOrders = (params = {}) => {
    return this.props.fetchWorkOrdersToPlan(
      this.props.currentSystem.id,
      params,
      workOrdersRequest.getCancelTokenConfig()
    );
  };

  fetchMoreWorkOrders = () => {
    if (this.state.isFetchingMore || this.state.isFetching || !this.props.canFetchMoreWorkOrders) return;
    this.setState({ isFetchingMore: true });
    this.fetchWorkOrders({ paginate_from: this.props.paginateFrom })
      .then(() => {
        this.setState({ isFetchingMore: false });
      })
      .catch(() => {});
  };

  renderContent = () => {
    const { workOrders, meterBasedWorkOrders } = this.props;
    if (this.state.isFetching) {
      return (
        <div className={styles['loader-container']}>
          <Loader tiny />
        </div>
      );
    }
    if (workOrders.length === 0 && meterBasedWorkOrders.length === 0) {
      return (
        <div className={styles['empty-data-set-container']}>
          <div className={styles['separator']} />
          <div className={styles['text']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.manage.empty-data-set.title" />
          </div>
        </div>
      );
    }
    if (this.props.meterBasedWorkOrders.length >= 1 && this.props.workOrders.length >= 1) {
      return (
        <div className={styles['list']}>
          <SectionHeader noBorderTop>
            <div className={styles['meter-based-section']}>
              <FormattedMessage id="screens.calendar.toolbox.lists.manage.meter-based-section-header" />
              <Tooltip
                trigger={<Icon size={12} regular type="question-circle" />}
                label={
                  <FormattedMessage id="screens.calendar.toolbox.lists.manage.meter-based-section-header-tooltip" />
                }
              />
            </div>
          </SectionHeader>
          {this.props.meterBasedWorkOrders.map((workOrder, index) => (
            <WorkOrderListItem index={index} id={workOrder.id} />
          ))}
          <SectionHeader noBorderTop>
            <FormattedMessage id="screens.calendar.toolbox.lists.manage.rest-section-header" />
          </SectionHeader>
          {this.props.workOrders.map((workOrder, index) => (
            <WorkOrderListItem index={index} id={workOrder.id} />
          ))}
        </div>
      );
    }
    if (this.props.meterBasedWorkOrders.length >= 1) {
      return (
        <div className={styles['list']}>
          <SectionHeader noBorderTop>
            <FormattedMessage id="screens.calendar.toolbox.lists.manage.meter-based-section-header" />
          </SectionHeader>
          {this.props.meterBasedWorkOrders.map((workOrder, index) => (
            <WorkOrderListItem index={index} id={workOrder.id} />
          ))}
        </div>
      );
    }
    return (
      <div className={styles['list']}>
        {this.props.workOrders.map((workOrder, index) => (
          <WorkOrderListItem index={index} id={workOrder.id} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <InfiniteScroll
        loadMore={this.fetchMoreWorkOrders}
        hasMore={this.props.canFetchMoreWorkOrders}
        loader={
          this.state.isFetchingMore ? (
            <div className={styles['loader-container']}>
              <Loader tiny />
            </div>
          ) : null
        }
        useWindow={false}
        initialLoad={false}
        threshold={150}
      >
        <div className={styles['header-container']}>
          <div className={styles['title-container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.calendar.toolbox.lists.manage.title" />
            </div>
            <div>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                onClick={() => {
                  this.props.hideListBar();
                }}
              />
            </div>
          </div>
          <div className={styles['subtitle']}>
            <FormattedMessage id="screens.calendar.toolbox.lists.manage.subtitle" />
          </div>
        </div>
        {this.renderContent()}
      </InfiniteScroll>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideListBar: CalendarOperations.hideListBar,
      fetchWorkOrdersToPlan: CalendarOperations.fetchWorkOrdersToPlan,
      fetchWorkOrdersToPlanAndIsMeterBased: CalendarOperations.fetchWorkOrdersToPlanAndIsMeterBased,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    workOrders: CalendarSelectors.getWorkOrdersToPlan(state),
    meterBasedWorkOrders: CalendarSelectors.getMeterBasedWorkOrdersToPlan(state),
    paginateFrom: CalendarSelectors.getPaginateFromForWorkOrdersToPlan(state),
    canFetchMoreWorkOrders: CalendarSelectors.canFetchMoreWorkOrdersToPlan(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
