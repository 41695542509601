import update from 'immutability-helper';
import moment from 'moment';
import { uniq } from 'lodash-es';
import types from './types';
import { SDKReduxTypes } from 'sdk';
import { WorkOrderStatus } from 'sdk/WorkOrder';

const INITIAL_STATE = {
  userIds: [],
  groupIds: [],
  vendorIds: [],
  temporaryVendorIds: [],
  isDraggingWorkOrderId: null,
  hoveringCalendarInstanceId: null,
  calendarInstanceIdsForUser: {},
  calendarInstanceIdsForGroup: {},
  calendarInstanceIdsForVendor: {},
  instanceById: {},
  toolbox: {
    listType: null,
    showList: false,
    to_plan: {
      meterBasedWorkOrderIds: [],
      workOrderIds: [],
      canFetchMore: true,
      paginateFrom: null,
      count: 0,
    },
    overdue: {
      workOrderIds: [],
      canFetchMore: true,
      paginateFrom: null,
      count: 0,
    },
    awaiting_delivery: {
      workOrderIds: [],
      count: 0,
    },
    request: {
      requestIds: [],
      canFetchMore: true,
      paginateFrom: null,
      count: 0,
    },
  },
  workOrderPreviewOverlay: {
    open: false,
    instanceId: null,
    workOrderId: null,
    top: 0,
    left: 0,
    width: 0,
  },
  requestPreviewOverlay: {
    open: false,
    requestId: null,
    top: 0,
    left: 0,
    width: 0,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_COUNTS: {
      const { awaiting_delivery, overdue, request, to_plan } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          to_plan: {
            ...state.toolbox.to_plan,
            count: to_plan,
          },
          overdue: {
            ...state.toolbox.overdue,
            count: overdue,
          },
          request: {
            ...state.toolbox.request,
            count: request,
          },
          awaiting_delivery: {
            ...state.toolbox.awaiting_delivery,
            count: awaiting_delivery,
          },
        },
      };
    }
    case types.SHOW_LIST_BAR: {
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          listType: action.payload,
          showList: true,
          to_plan: {
            ...state.toolbox.to_plan,
            workOrderIds: [],
          },
          overdue: {
            ...state.toolbox.overdue,
            workOrderIds: [],
          },
          awaiting_delivery: {
            ...state.toolbox.awaiting_delivery,
            workOrderIds: [],
          },
          request: {
            ...state.toolbox.request,
            requestIds: [],
          },
        },
      };
    }
    case types.HIDE_LIST_BAR: {
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          listType: null,
          showList: false,
        },
      };
    }
    case types.BEGIN_DRAG: {
      const { workOrderId, recurringMaintenanceId } = action.payload;
      return {
        ...state,
        isDraggingWorkOrderId: workOrderId == null ? null : workOrderId,
        isDraggingRecurringMaintenanceId: recurringMaintenanceId == null ? null : recurringMaintenanceId,
        workOrderPreviewOverlay: {
          ...state.workOrderPreviewOverlay,
          open: false,
        },
        requestPreviewOverlay: {
          ...state.requestPreviewOverlay,
          open: false,
        },
      };
    }
    case types.END_DRAG: {
      return {
        ...state,
        isDraggingWorkOrderId: null,
        isDraggingRecurringMaintenanceId: null,
      };
    }
    case types.BEGIN_DRAG_FROM_TOOLBOX: {
      const { workOrderId, requestId } = action.payload;
      return {
        ...state,
        isDraggingWorkOrderId: workOrderId == null ? null : workOrderId,
        isDraggingRequestId: requestId == null ? null : requestId,
        recurringMaintenanceId: null,
        workOrderPreviewOverlay: {
          ...state.workOrderPreviewOverlay,
          open: false,
        },
        requestPreviewOverlay: {
          ...state.requestPreviewOverlay,
          open: false,
        },
      };
    }
    case types.END_DRAG_FROM_TOOLBOX: {
      return {
        ...state,
        isDraggingWorkOrderId: null,
        isDraggingRecurringMaintenanceId: null,
        isDraggingRequestId: null,
      };
    }
    case types.SHOW_WORK_ORDER_PREVIEW_OVERLAY: {
      const { top, left, width, instanceId, workOrderId } = action.payload;
      return {
        ...state,
        workOrderPreviewOverlay: {
          ...state.workOrderPreviewOverlay,
          open: true,
          instanceId,
          workOrderId,
          top,
          left,
          width,
        },
        requestPreviewOverlay: {
          ...state.requestPreviewOverlay,
          open: false,
        },
      };
    }
    case types.HIDE_WORK_ORDER_PREVIEW_OVERLAY: {
      return {
        ...state,
        workOrderPreviewOverlay: {
          ...state.workOrderPreviewOverlay,
          open: false,
        },
      };
    }
    case types.SHOW_REQUEST_PREVIEW_OVERLAY: {
      const { top, left, width, requestId } = action.payload;
      return {
        ...state,
        requestPreviewOverlay: {
          ...state.requestPreviewOverlay,
          open: true,
          requestId,
          top,
          left,
          width,
        },
        workOrderPreviewOverlay: {
          ...state.workOrderPreviewOverlay,
          open: false,
        },
      };
    }
    case types.HIDE_REQUEST_PREVIEW_OVERLAY: {
      return {
        ...state,
        requestPreviewOverlay: {
          ...state.requestPreviewOverlay,
          open: false,
        },
      };
    }
    case types.SET_ASSIGNEES: {
      const { userIds, groupIds, vendorIds } = action.payload;
      return {
        ...state,
        userIds,
        groupIds,
        vendorIds,
      };
    }
    case types.FETCH_DATA_BETWEEN_DATES_SUCCESS: {
      const {
        fromDate,
        toDate,
        instancesById,
        vendorIds: temporaryVendorIds,
        resetCalendar,
      } = action.payload;
      /*
        Generate calendar structure
        {
          calendarInstanceIdsForUser: {
            [id]: {
              [2021-01-01]: [...instanceIds]
              ...
            }
          },
          calendarInstanceIdsForGroup: {
            [id]: {
              [2021-01-01]: [...instanceIds]
              ...
            }
          }
        }
      */
      let newState = { ...state };
      if (resetCalendar) {
        newState = {
          ...newState,
          calendarInstanceIdsForUser: {},
          calendarInstanceIdsForGroup: {},
          calendarInstanceIdsForVendor: {},
        };
      }
      let instanceIds = instancesById == null ? [] : Object.keys(instancesById);
      for (const m = moment(fromDate); m.diff(toDate, 'days') <= 0; m.add(1, 'days')) {
        const loopedDate = m.format('YYYY-MM-DD');
        const instancesForDate = instanceIds
          .map(id => instancesById[id])
          .filter(({ date }) => date === loopedDate);
        state.userIds.forEach(id => {
          newState = update(newState, {
            calendarInstanceIdsForUser: calendarInstanceIdsForUser =>
              update(calendarInstanceIdsForUser || {}, {
                [id]: user =>
                  update(user || {}, {
                    [loopedDate]: loopedDate =>
                      update(loopedDate || [], {
                        $apply: loopedDate => {
                          return uniq([
                            ...loopedDate,
                            ...instancesForDate
                              .filter(instance => instance.users.includes(id))
                              .map(({ id }) => id),
                          ]);
                        },
                      }),
                  }),
              }),
          });
        });
        state.groupIds.forEach(id => {
          newState = update(newState, {
            calendarInstanceIdsForGroup: calendarInstanceIdsForGroup =>
              update(calendarInstanceIdsForGroup || {}, {
                [id]: group =>
                  update(group || {}, {
                    [loopedDate]: loopedDate =>
                      update(loopedDate || [], {
                        $apply: loopedDate => {
                          return uniq([
                            ...loopedDate,
                            ...instancesForDate
                              .filter(instance => instance.groups.includes(id))
                              .map(({ id }) => id),
                          ]);
                        },
                      }),
                  }),
              }),
          });
        });
        [...state.vendorIds, ...temporaryVendorIds].forEach(id => {
          newState = update(newState, {
            calendarInstanceIdsForVendor: calendarInstanceIdsForVendor =>
              update(calendarInstanceIdsForVendor || {}, {
                [id]: vendor =>
                  update(vendor || {}, {
                    [loopedDate]: loopedDate =>
                      update(loopedDate || [], {
                        $apply: loopedDate => {
                          return uniq([
                            ...loopedDate,
                            ...instancesForDate
                              .filter(instance => instance.vendors.includes(id))
                              .map(({ id }) => id),
                          ]);
                        },
                      }),
                  }),
              }),
          });
        });
      }
      return {
        ...newState,
        temporaryVendorIds,
        instanceById: {
          ...state.instanceById,
          ...instancesById,
        },
      };
    }
    case types.REMOVE_ASSIGNEES_FROM_INSTANCE: {
      let newState = { ...state };
      action.payload.forEach(assignee => {
        const { userId, groupId, vendorId, date, instanceId } = assignee;
        if (userId) {
          newState = update(newState, {
            calendarInstanceIdsForUser: users =>
              update(users || {}, {
                [userId]: user =>
                  update(user || {}, {
                    [date]: date =>
                      update(date || [], {
                        $apply: date => date.filter(id => id !== instanceId),
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    users: users =>
                      update(users || [], {
                        $set: users.filter(id => id !== userId),
                      }),
                  }),
              }),
          });
        } else if (groupId) {
          newState = update(newState, {
            calendarInstanceIdsForGroup: groups =>
              update(groups || {}, {
                [groupId]: group =>
                  update(group || {}, {
                    [date]: date =>
                      update(date || [], {
                        $apply: date => date.filter(id => id !== instanceId),
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    groups: groups =>
                      update(groups || [], {
                        $set: groups.filter(id => id !== groupId),
                      }),
                  }),
              }),
          });
        } else if (vendorId) {
          newState = update(newState, {
            calendarInstanceIdsForVendor: vendors =>
              update(vendors || {}, {
                [vendorId]: vendor =>
                  update(vendor || {}, {
                    [date]: date =>
                      update(date || [], {
                        $apply: date => date.filter(id => id !== instanceId),
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    vendors: vendors =>
                      update(vendors || [], {
                        $set: vendors.filter(id => id !== vendorId),
                      }),
                  }),
              }),
          });
        }
      });
      return newState;
    }
    case types.ADD_ASSIGNEES_FOR_INSTANCE: {
      let newState = { ...state };
      action.payload.forEach(assignee => {
        const { userId, groupId, vendorId, date, instanceId } = assignee;
        if (userId) {
          newState = update(newState, {
            calendarInstanceIdsForUser: users =>
              update(users || {}, {
                [userId]: user =>
                  update(user || {}, {
                    [date]: fromDate =>
                      update(fromDate || [], {
                        $push: [instanceId],
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    users: users =>
                      update(users || [], {
                        $push: [userId],
                      }),
                  }),
              }),
          });
        } else if (groupId) {
          newState = update(newState, {
            calendarInstanceIdsForGroup: groups =>
              update(groups || {}, {
                [groupId]: group =>
                  update(group || {}, {
                    [date]: fromDate =>
                      update(fromDate || [], {
                        $push: [instanceId],
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    groups: groups =>
                      update(groups || [], {
                        $push: [groupId],
                      }),
                  }),
              }),
          });
        } else if (vendorId) {
          newState = update(newState, {
            calendarInstanceIdsForVendor: vendors =>
              update(vendors || {}, {
                [vendorId]: vendor =>
                  update(vendor || {}, {
                    [date]: fromDate =>
                      update(fromDate || [], {
                        $push: [instanceId],
                      }),
                  }),
              }),
            instanceById: instanceById =>
              update(instanceById, {
                [instanceId]: instance =>
                  update(instance, {
                    vendors: vendors =>
                      update(vendors || [], {
                        $push: [vendorId],
                      }),
                  }),
              }),
          });
        }
      });
      return newState;
    }
    case types.REMOVE_INSTANCES_FROM_CALENDAR: {
      let newState = { ...state };
      action.payload.forEach(dragUpdate => {
        const { userId, groupId, vendorId, date, id } = dragUpdate;

        let updateFromResourceById = update(newState, {
          calendarInstanceIdsForGroup: groups =>
            update(groups || {}, {
              $apply: groups => {
                if (groupId) {
                  return update(groups || {}, {
                    [groupId]: fromGroupId =>
                      update(fromGroupId || {}, {
                        [date]: fromDate =>
                          update(fromDate || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => date.filter(instanceId => instanceId !== id),
                              });
                            },
                          }),
                      }),
                  });
                }
                return groups;
              },
            }),
          calendarInstanceIdsForUser: users =>
            update(users || {}, {
              $apply: users => {
                if (userId) {
                  return update(users || {}, {
                    [userId]: fromUserId =>
                      update(fromUserId || {}, {
                        [date]: date =>
                          update(date || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => date.filter(instanceId => instanceId !== id),
                              });
                            },
                          }),
                      }),
                  });
                }
                return users;
              },
            }),
          calendarInstanceIdsForVendor: vendors =>
            update(vendors || {}, {
              $apply: vendors => {
                if (vendorId) {
                  return update(vendors || {}, {
                    [vendorId]: fromVendorId =>
                      update(fromVendorId || {}, {
                        [date]: fromDate =>
                          update(fromDate || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => date.filter(instanceId => instanceId !== id),
                              });
                            },
                          }),
                      }),
                  });
                }
                return vendors;
              },
            }),
          instanceById: instances =>
            update(instances || {}, {
              $apply: instances => {
                if (instances[id] == null) {
                  return instances;
                }
                return update(instances || {}, {
                  [id]: instance =>
                    update(instance, {
                      users: users =>
                        update(users || [], {
                          $apply: users => {
                            return update(users || [], {
                              $apply: users => users.filter(id => id !== userId),
                            });
                          },
                        }),
                      groups: groups =>
                        update(groups || [], {
                          $apply: groups => {
                            return update(groups || [], {
                              $apply: groups => groups.filter(id => id !== groupId),
                            });
                          },
                        }),
                      vendors: vendors =>
                        update(vendors || [], {
                          $apply: vendors => {
                            return update(vendors || [], {
                              $apply: vendors => vendors.filter(id => id !== vendorId),
                            });
                          },
                        }),
                    }),
                });
              },
            }),
        });
        newState = {
          ...newState,
          ...updateFromResourceById,
        };
      });
      return {
        ...newState,
      };
    }
    case types.ADD_INSTANCES_FOR_CALENDAR: {
      let newState = { ...state };
      action.payload.forEach(dragUpdate => {
        const { userId, groupId, vendorId, date, id } = dragUpdate;
        let updateFromResourceById = update(newState, {
          calendarInstanceIdsForGroup: groups =>
            update(groups || {}, {
              $apply: groups => {
                if (groupId) {
                  return update(groups || {}, {
                    [groupId]: fromGroupId =>
                      update(fromGroupId || {}, {
                        [date]: fromDate =>
                          update(fromDate || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => [...date, id],
                              });
                            },
                          }),
                      }),
                  });
                }
                return groups;
              },
            }),
          calendarInstanceIdsForUser: users =>
            update(users || {}, {
              $apply: users => {
                if (userId) {
                  return update(users || {}, {
                    [userId]: fromUserId =>
                      update(fromUserId || {}, {
                        [date]: date =>
                          update(date || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => [...date, id],
                              });
                            },
                          }),
                      }),
                  });
                }
                return users;
              },
            }),
          calendarInstanceIdsForVendor: vendors =>
            update(vendors || {}, {
              $apply: vendors => {
                if (vendorId) {
                  return update(vendors || {}, {
                    [vendorId]: fromVendorId =>
                      update(fromVendorId || {}, {
                        [date]: fromDate =>
                          update(fromDate || [], {
                            $apply: date => {
                              return update(date || [], {
                                $apply: date => [...date, id],
                              });
                            },
                          }),
                      }),
                  });
                }
                return vendors;
              },
            }),
          instanceById: instances =>
            update(instances, {
              $apply: instances => {
                return update(instances, {
                  [id]: instance =>
                    update(
                      instance || {
                        id,
                        date,
                        work_order: id,
                        type: 'work_order',
                      },
                      {
                        date: oldDate =>
                          update(oldDate, {
                            $set: date,
                          }),
                        users: users =>
                          update(users || [], {
                            $apply: users => {
                              return update(users || [], {
                                $apply: users => [...users, userId].filter(val => val),
                              });
                            },
                          }),
                        groups: groups =>
                          update(groups || [], {
                            $apply: groups => {
                              return update(groups || [], {
                                $apply: groups => [...groups, groupId].filter(val => val),
                              });
                            },
                          }),
                        vendors: vendors =>
                          update(vendors || [], {
                            $apply: vendors => {
                              return update(vendors || [], {
                                $apply: vendors => [...vendors, vendorId].filter(val => val),
                              });
                            },
                          }),
                      }
                    ),
                });
              },
            }),
        });
        newState = {
          ...newState,
          ...updateFromResourceById,
        };
      });
      return {
        ...newState,
      };
    }
    case types.HOVER_CALENDAR_INSTANCE: {
      return {
        ...state,
        hoveringCalendarInstanceId: action.payload,
      };
    }
    case types.FETCH_METER_BASED_WORK_ORDERS_TO_PLAN_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          to_plan: {
            ...state.toolbox.to_plan,
            meterBasedWorkOrderIds: ids,
          },
        },
      };
    }
    case types.FETCH_WORK_ORDERS_TO_PLAN_SUCCESS: {
      const { ids, paginateFrom } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          to_plan: {
            ...state.toolbox.to_plan,
            workOrderIds: uniq([...state.toolbox.to_plan.workOrderIds, ...ids]),
            canFetchMore: ids.length >= 20,
            paginateFrom: paginateFrom,
          },
        },
      };
    }
    case types.FETCH_WORK_ORDERS_OVERDUE_SUCCESS: {
      const { ids, paginateFrom } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          overdue: {
            ...state.toolbox.overdue,
            workOrderIds: uniq([...state.toolbox.overdue.workOrderIds, ...ids]),
            canFetchMore: ids.length >= 20,
            paginateFrom: paginateFrom,
          },
        },
      };
    }
    case types.FETCH_WORK_ORDERS_AWAITING_DELIVERY_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          awaiting_delivery: {
            ...state.toolbox.awaiting_delivery,
            workOrderIds: ids,
          },
        },
      };
    }
    case types.FETCH_REQUESTS_SUCCESS: {
      const { ids, paginateFrom } = action.payload;
      return {
        ...state,
        toolbox: {
          ...state.toolbox,
          request: {
            ...state.toolbox.request,
            requestIds: [...state.toolbox.request.requestIds, ...ids],
            canFetchMore: ids.length >= 20,
            paginateFrom,
          },
        },
      };
    }
    case types.UPDATE_INSTANCE: {
      const { id, data } = action.payload;
      return {
        ...state,
        instanceById: {
          ...state.instanceById,
          [id]: {
            ...state.instanceById[id],
            ...data,
          },
        },
      };
    }
    case SDKReduxTypes.REQUEST_ARCHIVED: {
      const { requestId } = action.payload;
      return update(state, {
        toolbox: toolbox =>
          update(toolbox, {
            request: {
              requestIds: requestIds =>
                update(requestIds, {
                  $apply: requestIds => requestIds.filter(id => id !== requestId),
                }),
              count: count =>
                update(count, {
                  $apply: count => {
                    return count - 1;
                  },
                }),
            },
          }),
        requestPreviewOverlay: requestPreviewOverlay =>
          update(requestPreviewOverlay, {
            open: {
              $set: false,
            },
          }),
      });
    }
    case SDKReduxTypes.WORK_ORDER_UPDATING: {
      const { beforeEdit, params } = action.payload;
      const afterEdit = {
        ...beforeEdit,
        ...params,
      };
      let newState = { ...state };

      let addToMeterBasedPlanList = false;
      if (afterEdit.meter_id && afterEdit.due_date == null) {
        addToMeterBasedPlanList = true;
      }
      let addToPlanList = false;
      if (afterEdit.due_date == null) {
        addToPlanList = true;
      }
      if (
        afterEdit.assigned_to_users.length === 0 &&
        afterEdit.assigned_to_groups.length === 0 &&
        afterEdit.assigned_to_vendors.length === 0
      ) {
        addToPlanList = true;
      }

      if (
        beforeEdit.due_date == null ||
        (beforeEdit.assigned_to_users.length === 0 &&
          beforeEdit.assigned_to_groups.length === 0 &&
          beforeEdit.assigned_to_vendors.length === 0)
      ) {
        addToPlanList = false;
      }

      if (addToMeterBasedPlanList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              to_plan: {
                meterBasedWorkOrderIds: meterBasedWorkOrderIds =>
                  update(meterBasedWorkOrderIds, {
                    $apply: meterBasedWorkOrderIds => uniq([afterEdit.id, ...meterBasedWorkOrderIds]),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count + 1;
                    },
                  }),
              },
            }),
        });
      } else if (addToPlanList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              to_plan: {
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => uniq([afterEdit.id, ...workOrderIds]),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count + 1;
                    },
                  }),
              },
            }),
        });
      }
      let removeFromPlanList = false;
      if (
        afterEdit.due_date &&
        (afterEdit.assigned_to_users.length > 0 ||
          afterEdit.assigned_to_groups.length > 0 ||
          afterEdit.assigned_to_vendors.length > 0)
      ) {
        removeFromPlanList = true;
      }

      if (
        beforeEdit.due_date != null &&
        (beforeEdit.assigned_to_users.length > 0 ||
          beforeEdit.assigned_to_groups.length > 0 ||
          beforeEdit.assigned_to_vendors.length > 0)
      ) {
        removeFromPlanList = false;
      }

      if (removeFromPlanList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              to_plan: {
                meterBasedWorkOrderIds: meterBasedWorkOrderIds =>
                  update(meterBasedWorkOrderIds, {
                    $apply: meterBasedWorkOrderIds =>
                      meterBasedWorkOrderIds.filter(id => id !== afterEdit.id),
                  }),
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => workOrderIds.filter(id => id !== afterEdit.id),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count - 1;
                    },
                  }),
              },
            }),
        });
      }
      let addToOverdueList = false;
      if (
        !moment(beforeEdit.due_date).isBefore(moment(), 'day') &&
        moment(afterEdit.due_date).isBefore(moment(), 'day')
      ) {
        addToOverdueList = true;
      }
      if (addToOverdueList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              overdue: {
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => uniq([afterEdit.id, ...workOrderIds]),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count + 1;
                    },
                  }),
              },
            }),
        });
      }
      let removeFromOverdueList = false;
      if (
        moment(beforeEdit.due_date).isBefore(moment(), 'day') &&
        !moment(afterEdit.due_date).isBefore(moment(), 'day')
      ) {
        removeFromOverdueList = true;
      }
      if (removeFromOverdueList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              overdue: {
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => workOrderIds.filter(id => id !== afterEdit.id),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count - 1;
                    },
                  }),
              },
            }),
        });
      }
      return newState;
    }
    case SDKReduxTypes.WORK_ORDER_CREATED: {
      const workOrder = action.payload.data;

      let newState = { ...state };

      if (workOrder.created_from_requests.length > 0) {
        newState = update(state, {
          toolbox: toolbox =>
            update(toolbox, {
              request: {
                requestIds: requestIds =>
                  update(requestIds, {
                    $apply: requestIds =>
                      requestIds.filter(id => id !== workOrder.created_from_requests[0].id),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count - 1;
                    },
                  }),
              },
            }),
        });
      }

      let addToPlanList = false;
      if (workOrder.due_date == null) {
        addToPlanList = true;
      }
      if (
        workOrder.assigned_to_users.length === 0 &&
        workOrder.assigned_to_groups.length === 0 &&
        workOrder.assigned_to_vendors.length === 0
      ) {
        addToPlanList = true;
      }
      if (addToPlanList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              to_plan: {
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => uniq([workOrder.id, ...workOrderIds]),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count + 1;
                    },
                  }),
              },
            }),
        });
      }

      let addToOverdueList = false;
      if (moment(workOrder.due_date).isBefore(moment(), 'day')) {
        addToOverdueList = true;
      }
      if (addToOverdueList) {
        newState = update(newState, {
          toolbox: toolbox =>
            update(toolbox, {
              overdue: {
                workOrderIds: workOrderIds =>
                  update(workOrderIds, {
                    $apply: workOrderIds => uniq([workOrder.id, ...workOrderIds]),
                  }),
                count: count =>
                  update(count, {
                    $apply: count => {
                      return count + 1;
                    },
                  }),
              },
            }),
        });
      }

      if (workOrder.status === WorkOrderStatus.Completed) {
        const { completed_date, id: workOrderId, completed_by_user_id } = workOrder;
        return update(newState, {
          calendarInstanceIdsForUser: calendarInstanceIdsForUser =>
            update(calendarInstanceIdsForUser || {}, {
              [completed_by_user_id]: id =>
                update(id || {}, {
                  [completed_date]: completed_date =>
                    update(completed_date || [], {
                      $push: [workOrderId],
                    }),
                }),
            }),
          instanceById: {
            $merge: {
              [workOrderId]: {
                id: workOrderId,
                date: completed_date,
                users: [],
                groups: [],
                vendors: [],
                work_order: workOrderId,
                type: 'work_order',
              },
            },
          },
        });
      } else {
        const {
          due_date,
          assigned_to_users,
          assigned_to_groups,
          assigned_to_vendors,
          id: workOrderId,
        } = workOrder;
        let assignees = [...assigned_to_users, ...assigned_to_groups, ...assigned_to_vendors];
        if (due_date && assignees.length > 0) {
          assigned_to_users.forEach(({ id }) => {
            newState = update(
              { ...newState },
              {
                calendarInstanceIdsForUser: calendarInstanceIdsForUser =>
                  update(calendarInstanceIdsForUser || {}, {
                    [id]: id =>
                      update(id || {}, {
                        [due_date]: due_date =>
                          update(due_date || [], {
                            $push: [workOrderId],
                          }),
                      }),
                  }),
              }
            );
          });
          assigned_to_groups.forEach(({ id }) => {
            newState = update(
              { ...newState },
              {
                calendarInstanceIdsForGroup: calendarInstanceIdsForGroup =>
                  update(calendarInstanceIdsForGroup || {}, {
                    [id]: id =>
                      update(id || {}, {
                        [due_date]: due_date =>
                          update(due_date || [], {
                            $push: [workOrderId],
                          }),
                      }),
                  }),
              }
            );
          });
          assigned_to_vendors.forEach(({ id }) => {
            newState = update(
              { ...newState },
              {
                calendarInstanceIdsForVendor: calendarInstanceIdsForVendor =>
                  update(calendarInstanceIdsForVendor || {}, {
                    [id]: id =>
                      update(id || {}, {
                        [due_date]: due_date =>
                          update(due_date || [], {
                            $push: [workOrderId],
                          }),
                      }),
                  }),
              }
            );
          });
          return update(newState, {
            instanceById: {
              [workOrderId]: {
                $set: {
                  id: workOrderId,
                  date: due_date,
                  users: assigned_to_users.map(({ id }) => id),
                  groups: assigned_to_groups.map(({ id }) => id),
                  vendors: assigned_to_vendors.map(({ id }) => id),
                  work_order: workOrderId,
                  type: 'work_order',
                },
              },
            },
          });
        }
        return newState;
      }
    }
    default:
      return state;
  }
};
