import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { CostsSelectors } from 'state/ducks/costs';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    switch (this.props.selectedFilterType) {
      case FILTER_TYPES.WorkOrderTitle: {
        return <Filter.Title />;
      }
      case FILTER_TYPES.WorkOrderDescription: {
        return <Filter.Description />;
      }
      case FILTER_TYPES.Status: {
        return <Filter.Status />;
      }
      case FILTER_TYPES.DueDate: {
        return <Filter.DueDate />;
      }
      case FILTER_TYPES.Assignee: {
        return <Filter.Assignee />;
      }
      case FILTER_TYPES.Category: {
        return <Filter.Category />;
      }
      case FILTER_TYPES.WorkOrderType: {
        return <Filter.WorkOrderType />;
      }
      case FILTER_TYPES.EstimatedMinutes: {
        return <Filter.EstimatedMinutes />;
      }
      case FILTER_TYPES.Priority: {
        return <Filter.Priority />;
      }
      case FILTER_TYPES.CreatedBy: {
        return <Filter.CreatedBy />;
      }
      case FILTER_TYPES.CreatedAt: {
        return <Filter.CreatedAt />;
      }
      case FILTER_TYPES.CompletedBy: {
        return <Filter.CompletedBy />;
      }
      case FILTER_TYPES.CompletedAt: {
        return <Filter.CompletedAt />;
      }
      case FILTER_TYPES.CompletedComment: {
        return <Filter.CompletedComment />;
      }
      case FILTER_TYPES.InProgressBy: {
        return <Filter.InProgressBy />;
      }
      case FILTER_TYPES.PausedBy: {
        return <Filter.PausedBy />;
      }
      case FILTER_TYPES.PausedComment: {
        return <Filter.PausedComment />;
      }
      case FILTER_TYPES.AssetType: {
        return <Filter.AssetType />;
      }
      case FILTER_TYPES.WorkOrderSpentTimeDate: {
        return <Filter.WorkOrderSpentTimeDate />;
      }
      case FILTER_TYPES.WorkOrderSpentTimeCreatedBy: {
        return <Filter.WorkOrderSpentTimeCreatedBy />;
      }
      case FILTER_TYPES.WorkOrderSpentTimeComment: {
        return <Filter.WorkOrderSpentTimeComment />;
      }
      case FILTER_TYPES.UsedSparePart: {
        return <Filter.UsedSparePart />;
      }
      case FILTER_TYPES.CompletedBeforeRegistration: {
        return <Filter.CompletedBeforeRegistration />;
      }
      case FILTER_TYPES.Request: {
        return <Filter.Request />;
      }
      case FILTER_TYPES.RequestType: {
        return <Filter.RequestType />;
      }
      case FILTER_TYPES.Interval: {
        return <Filter.Interval />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedFilterType: CostsSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
