import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ConfirmModal } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeOperationalMaintenanceBreak } from 'sdk/Schemas';
import { SDKReduxOperations, API } from 'sdk';
import View from './View';
import Edit from './Edit';

class OperationalMaintenanceBreakModal extends Component {
  state = {
    isFetching: false,
    mode: 'view',
    showOperationalMaintenanceBreakModal: false,
    showDeleteOperationalMaintenanceBreakModal: false,
    isDeletingOperationalMaintenanceBreak: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !this.props.open &&
      !nextProps.open &&
      !this.state.showOperationalMaintenanceBreakModal &&
      !nextState.showOperationalMaintenanceBreakModal
    )
      return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id) {
        this.setState({ isFetching: true });
        API.fetcOperationalMaintenanceBreak(this.props.id).then(({ data: operationalMaintenanceBreak }) => {
          const { entities } = normalizeOperationalMaintenanceBreak(operationalMaintenanceBreak);
          this.props.updateEntities(entities);
          this.setState({ isFetching: false });
        });
        this.setState({ mode: 'view', showOperationalMaintenanceBreakModal: true });
      } else {
        this.setState({ mode: 'new', showOperationalMaintenanceBreakModal: true });
      }
    } else if (prevProps.open && !this.props.open) {
      this.setState({ showOperationalMaintenanceBreakModal: false });
    }
  }

  deleteOperationalMaintenanceBreak = () => {
    this.setState({ isDeletingOperationalMaintenanceBreak: true });
    this.props
      .deleteOperationalMaintenanceBreak(this.props.id)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="components.delete-operational-maintenance-break-modal.delete-success" />
            }
          />
        );
        this.setState({
          isDeletingOperationalMaintenanceBreak: false,
          showDeleteOperationalMaintenanceBreakModal: false,
        });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeletingOperationalMaintenanceBreak: false });
      });
  };

  renderDeleteOperationalMaintenanceBreakModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showDeleteOperationalMaintenanceBreakModal}
        title={<FormattedMessage id="components.delete-operational-maintenance-break-modal.title" />}
        message={<FormattedMessage id="components.delete-operational-maintenance-break-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingOperationalMaintenanceBreak}
        onConfirm={() => {
          this.deleteOperationalMaintenanceBreak();
        }}
        onCancel={() => {
          this.setState({ showDeleteOperationalMaintenanceBreakModal: false });
          this.props.onClose();
        }}
      />
    );
  };

  renderContent = () => {
    switch (this.state.mode) {
      case 'new':
        return (
          <Edit
            new
            onClose={this.props.onClose}
            onCancel={() => {
              this.props.onClose();
            }}
            onSaved={() => {
              this.props.onClose();
            }}
          />
        );
      case 'edit':
        return (
          <Edit
            id={this.props.id}
            onClose={this.props.onClose}
            onCancel={() => {
              this.setState({ mode: 'view' });
            }}
            onSaved={() => {
              this.setState({ mode: 'view' });
            }}
          />
        );
      case 'view': {
        if (this.state.isFetching) {
          return (
            <>
              <Modal.Content loading />
            </>
          );
        }
        return (
          <View
            id={this.props.id}
            onClose={this.props.onClose}
            onEdit={params => {
              this.setState({ mode: 'edit' });
            }}
            onDelete={() => {
              this.setState({ showOperationalMaintenanceBreakModal: false });
              setTimeout(() => {
                this.setState({ showDeleteOperationalMaintenanceBreakModal: true });
              }, 250);
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.showOperationalMaintenanceBreakModal} width={600}>
          {this.renderContent()}
        </Modal>
        {this.renderDeleteOperationalMaintenanceBreakModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOperationalMaintenanceBreak: SDKReduxOperations.deleteOperationalMaintenanceBreak,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(OperationalMaintenanceBreakModal);
