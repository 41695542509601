import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { List, Icon, Button } from 'views/components/Shared/General';
import { OperationalMaintenanceTemplateSelectors } from 'state/ducks/operationalMaintenanceTemplate';

class ChecklistTemplateLinkListItem extends Component {
  renderButtonLabel = () => {
    const optionsOnChecklist =
      this.props.checklistTemplateRows.filter(({ require_option }) => require_option === true).length *
      this.props.operationalMaintenanceTemplateAssets.length;
    const optionsOnTemplateAsset = this.props.checklistInstanceOptions.length;
    if (optionsOnChecklist > optionsOnTemplateAsset) {
      return (
        <>
          <span style={{ marginRight: 8 }}>
            <Icon solid red type="exclamation-triangle" size={12} />
          </span>
          <FormattedMessage id="screens.operational-maintenance-template.settings.asset.checklist-settings" />
        </>
      );
    }
    return (
      <FormattedMessage id="screens.operational-maintenance-template.settings.asset.checklist-settings" />
    );
  };

  renderSettingsButton = () => {
    const {
      canAdministrateOperationalMaintenances,
      operationalMaintenanceTemplateAssets,
      checklistTemplateRows,
    } = this.props;
    const hasAnyChecklistTemplateRowThatRequiresOptions =
      checklistTemplateRows.find(({ require_option }) => require_option === true) != null;

    if (
      canAdministrateOperationalMaintenances &&
      operationalMaintenanceTemplateAssets.length > 0 &&
      hasAnyChecklistTemplateRowThatRequiresOptions
    ) {
      return (
        <Button
          small
          onClick={this.props.onClickSettings}
          gray
          translate={false}
          label={this.renderButtonLabel()}
        />
      );
    }
    return null;
  };

  renderButtons = () => {
    return (
      <Button.Group>
        <Button small onClick={this.props.onClickPreview} gray label="general.preview" />
        {this.renderSettingsButton()}
      </Button.Group>
    );
  };

  render() {
    return (
      <List.Item>
        <List.Item.Column>
          <Icon type="list" withBackground backgroundSize={34} />
        </List.Item.Column>
        <List.Item.TitleColumn title={this.props.checklistTemplate.title} />
        {this.renderButtons()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const activeOperationalMaintenanceTemplateVersion =
    OperationalMaintenanceTemplateSelectors.getCurrentOperationalMaintenanceTemplateVersion(state);
  let checklistTemplateRows = [];
  let checklistTemplateLinks = [];
  let checklistInstanceOptions = [];
  let checklistTemplate = null;
  let checklistTemplateVersion = null;
  const operationalMaintenanceTemplate = EntitySelectors.getOperationalMaintenanceTemplate(
    state,
    activeOperationalMaintenanceTemplateVersion.operational_maintenance_template_id
  );
  const operationalMaintenanceTemplateAssets = EntitySelectors.getOperationalMaintenanceTemplateAssets(
    state,
    operationalMaintenanceTemplate.operational_maintenance_template_assets
  );
  checklistTemplateLinks = EntitySelectors.getChecklistTemplateLinks(
    state,
    activeOperationalMaintenanceTemplateVersion.checklist_template_links
  );
  if (checklistTemplateLinks.length === 1) {
    checklistTemplate = EntitySelectors.getChecklistTemplate(
      state,
      checklistTemplateLinks[0].checklist_template_id
    );
  }
  checklistInstanceOptions = operationalMaintenanceTemplateAssets.reduce((acc, assetTemplate) => {
    const checklistInstanceOptions = EntitySelectors.getChecklistInstanceOptions(
      state,
      assetTemplate.checklist_instance_options
    );
    return [...acc, ...checklistInstanceOptions];
  }, []);

  if (checklistTemplate) {
    checklistTemplateVersion = EntitySelectors.getChecklistTemplateVersion(
      state,
      checklistTemplate.active_version
    );
  }
  if (checklistTemplateVersion) {
    checklistTemplateRows = EntitySelectors.getChecklistTemplateRows(
      state,
      checklistTemplateVersion.checklist_template_rows
    );
  }

  return {
    checklistTemplate,
    operationalMaintenanceTemplateAssets,
    checklistTemplateRows,
    checklistInstanceOptions,
    checklistTemplate: EntitySelectors.getChecklistTemplate(
      state,
      ownProps.checklistTemplateLink.checklist_template_id
    ),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateLinkListItem);
