import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NewInlineModal, Field } from 'views/components/Shared/General';

class StatusInlineModal extends Component {
  state = {
    showStatusInlineModal: false,
  };

  addStatusAsFilter = status => {
    if (this.props.value.find(val => val === status) == null) {
      this.props.onAddStatus(status);
    } else {
      this.props.onRemoveStatus(status);
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showStatusInlineModal: !prevState.showStatusInlineModal,
            }));
          }}
        >
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showStatusInlineModal}
          onClose={() => this.setState({ showStatusInlineModal: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes('draft')}
                    onChange={() => {
                      this.addStatusAsFilter('draft');
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter('draft')}
              >
                <FormattedMessage id="resources.purchase-order.statuses.draft" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes('ordered')}
                    onChange={() => {
                      this.addStatusAsFilter('ordered');
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter('ordered')}
              >
                <FormattedMessage id="resources.purchase-order.statuses.ordered" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes('partially_delivered')}
                    onChange={() => {
                      this.addStatusAsFilter('partially_delivered');
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter('partially_delivered')}
              >
                <FormattedMessage id="resources.purchase-order.statuses.partially-delivered" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes('delivered')}
                    onChange={() => {
                      this.addStatusAsFilter('delivered');
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter('delivered')}
              >
                <FormattedMessage id="resources.purchase-order.statuses.delivered" />
              </NewInlineModal.Dropdown.Item>
              <NewInlineModal.Dropdown.Separator />
              <NewInlineModal.Dropdown.Item
                leftComponent={
                  <Field.Checkbox
                    checked={this.props.value.includes('canceled')}
                    onChange={() => {
                      this.addStatusAsFilter('canceled');
                    }}
                  />
                }
                onClick={() => this.addStatusAsFilter('canceled')}
              >
                <FormattedMessage id="resources.purchase-order.statuses.canceled" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

export default StatusInlineModal;
