import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import AnimateHeight from 'react-animate-height';
import { ToastMessage } from 'views/components/Shared/Layout';
import { API, HelperFunctions, SDKReduxOperations } from 'sdk';
import { normalizeContactPerson } from 'sdk/Schemas';
import { Modal, Grid } from 'views/components/Shared/Layout';
import {
  Button,
  Field,
  InlineModal,
  FieldErrorWrapper,
  EmptyDataSet,
  Icon,
  Tooltip,
} from 'views/components/Shared/General';
import { EditPurchaseOrderEmailDefaultValuesModal } from 'views/components/PurchaseOrder';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import PrintPurchaseOrderModal from '../PrintPurchaseOrderModal';
import styles from './styles.module.scss';

class SendEmailModal extends Component {
  getInitialState = props => ({
    isFetching: false,
    isSending: false,
    emailMessage: {
      subject: '',
      message: '',
    },
    to: '',
    cc: '',
    bcc: '',
    selectedContactPersonId: null,
    selectedVendorEmail: false,
    selectedVendorPurchaseOrderEmail: false,
    showTextInput: false,
    dropdownOpen: false,
    contactPersons: [],
    errors: {
      showInvalidEmailError: false,
      showSubjectRequiredError: false,
      showMessageRequiredError: false,
      showInvalidEmailErrorForCopyField: false,
      showInvalidEmailErrorForSecretCopyField: false,
    },
    purchaseOrderPdfOptions: {
      showSparePartsInfo: false,
      showPrice: true,
      language: props.language,
    },
    showEditPurchaseOrderEmailDefaultValuesModal: false,
    showCopyField: false,
    showSecretCopyField: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(this.props),
        isFetching: true,
        emailMessage: {
          subject: this.props.currentUserSettings.purchase_order_email_subject,
          message: this.props.currentUserSettings.purchase_order_email_message,
        },
        to: this.props.vendor.purchase_order_email,
        selectedVendorPurchaseOrderEmail: true,
      });

      if (this.props.vendor.purchase_order_email) {
        this.setState({
          to: this.props.vendor.purchase_order_email,
          selectedVendorPurchaseOrderEmail: true,
        });
      }

      API.listContactPersonsForVendor(this.props.purchaseOrder.vendor_id)
        .then(({ data: contactPersons }) => {
          const { entities } = normalizeContactPerson(contactPersons);
          this.setState({ isFetching: false, contactPersons }, () => {
            this.setState({ showTextInput: !this.vendorHasAnyEmails() });
          });
          this.props.updateEntities(entities);
        })
        .catch(() => {
          this.setState({ showTextInput: true, isFetching: false });
        });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  vendorHasAnyEmails = () => {
    const contactPersonsWithAnEmail = this.state.contactPersons.filter(({ email }) => email);
    let hasEmailsToChoseFrom = false;
    if (this.props.vendor.email) hasEmailsToChoseFrom = true;
    if (this.props.vendor.purchase_order_email) hasEmailsToChoseFrom = true;
    if (contactPersonsWithAnEmail.length > 0) hasEmailsToChoseFrom = true;
    return hasEmailsToChoseFrom;
  };

  hasErrors = () => {
    let errors = {
      showInvalidEmailError: false,
      showSubjectRequiredError: false,
      showMessageRequiredError: false,
    };
    if (!HelperFunctions.validateEmail(this.state.to)) {
      errors = {
        ...errors,
        showInvalidEmailError: true,
      };
    }
    if (
      this.state.showCopyField &&
      this.state.cc.length > 0 &&
      !HelperFunctions.validateEmail(this.state.cc)
    ) {
      errors = {
        ...errors,
        showInvalidEmailErrorForCopyField: true,
      };
    }
    if (
      this.state.showSecretCopyField &&
      this.state.bcc.length > 0 &&
      !HelperFunctions.validateEmail(this.state.bcc)
    ) {
      errors = {
        ...errors,
        showInvalidEmailErrorForSecretCopyField: true,
      };
    }
    if (!this.state.emailMessage.subject) {
      errors = {
        ...errors,
        showSubjectRequiredError: true,
      };
    }
    if (!this.state.emailMessage.message) {
      errors = {
        ...errors,
        showMessageRequiredError: true,
      };
    }
    this.setState({ errors });
    return Object.values(errors).some(item => item === true);
  };

  sendEmailToVendor = () => {
    if (this.hasErrors()) {
      return;
    }
    this.setState({ isSending: true });
    const { to, cc, bcc } = this.state;
    const { showPrice, showSparePartsInfo, language } = this.state.purchaseOrderPdfOptions;
    const { subject, message } = this.state.emailMessage;
    this.props
      .sendPurchaseOrder(this.props.purchaseOrder.id, {
        to,
        cc,
        bcc,
        subject,
        message,
        show_price: showPrice,
        new_show_spare_part_info: showSparePartsInfo,
        language,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.purchase-order-email-modal.send-email-success" />}
          />
        );
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSending: false });
      });
  };

  renderContactPersonsItems = () => {
    const contactPersons = this.state.contactPersons.filter(({ email }) => email);
    if (contactPersons.length === 0) return null;
    return (
      <>
        <Field.Dropdown.Divider />
        {contactPersons.map(({ email, name, position, id }) => (
          <Field.Dropdown.Item
            key={id}
            selected={this.state.selectedContactPersonId === id}
            onClick={() =>
              this.setState({
                to: email,
                selectedContactPersonId: id,
                selectedVendorEmail: false,
                selectedVendorPurchaseOrderEmail: false,
                errors: {
                  ...this.state.errors,
                  showInvalidEmailError: false,
                },
              })
            }
          >
            <span>{name}</span>
            {position ? (
              <span>
                <span>&nbsp;</span>
                <span>-</span>
                <span>&nbsp;</span>
                {position}
              </span>
            ) : null}

            <span className={styles['subtitle']}>
              <span>&nbsp;</span>
              <span>-</span>
              <span>&nbsp;</span>
              {email}
            </span>
          </Field.Dropdown.Item>
        ))}
      </>
    );
  };

  renderDropdownItems = () => {
    const contactPersonsWithAnEmail = this.state.contactPersons.filter(({ email }) => email);
    let hasEmailsToChoseFrom = false;
    if (this.props.vendor.email) hasEmailsToChoseFrom = true;
    if (this.props.vendor.purchase_order_email) hasEmailsToChoseFrom = true;
    if (contactPersonsWithAnEmail.length > 0) hasEmailsToChoseFrom = true;
    if (!hasEmailsToChoseFrom) {
      return (
        <div className={styles['empty-data-set-container']}>
          <EmptyDataSet
            title={<FormattedMessage id="components.purchase-order-email-modal.empty-data-set.title" />}
            subtitle={<FormattedMessage id="components.purchase-order-email-modal.empty-data-set.subtitle" />}
            tiny
          />
        </div>
      );
    }
    return (
      <>
        {this.props.vendor.purchase_order_email ? (
          <Field.Dropdown.Item
            selected={this.state.selectedVendorPurchaseOrderEmail}
            onClick={() =>
              this.setState({
                to: this.props.vendor.purchase_order_email,
                selectedContactPersonId: null,
                selectedVendorEmail: false,
                selectedVendorPurchaseOrderEmail: true,
                errors: {
                  ...this.state.errors,
                  showInvalidEmailError: false,
                },
              })
            }
          >
            {this.props.vendor.purchase_order_email}
          </Field.Dropdown.Item>
        ) : null}
        {this.props.vendor.email ? (
          <Field.Dropdown.Item
            selected={this.state.selectedVendorEmail}
            onClick={() =>
              this.setState({
                to: this.props.vendor.email,
                selectedContactPersonId: null,
                selectedVendorEmail: true,
                selectedVendorPurchaseOrderEmail: false,
                errors: {
                  ...this.state.errors,
                  showInvalidEmailError: false,
                },
              })
            }
          >
            {this.props.vendor.email}
          </Field.Dropdown.Item>
        ) : null}
        {this.renderContactPersonsItems()}
      </>
    );
  };

  renderSelectedToValue = () => {
    if (this.state.selectedContactPersonId) {
      const contactPerson = this.state.contactPersons.find(
        ({ id }) => id === this.state.selectedContactPersonId
      );
      return (
        <>
          <span>{contactPerson.name}</span>
          {contactPerson.position ? (
            <span>
              <span>&nbsp;</span>
              <span>-</span>
              <span>&nbsp;</span>
              {contactPerson.position}
            </span>
          ) : null}
          <span className={styles['subtitle']}>
            <span>&nbsp;</span>
            <span>-</span>
            <span>&nbsp;</span>
            {this.state.to}
          </span>
        </>
      );
    } else {
      return this.state.to;
    }
  };

  renderDropdownMenu = () => {
    if (this.state.showTextInput) {
      return (
        <Field
          view={false}
          label={
            <div className={styles['label-container']}>
              <FormattedMessage id="components.purchase-order-email-modal.to" />
              <Button
                primary
                fontSize={12}
                type="text"
                label="components.purchase-order-email-modal.show-dropdown"
                noUnderline
                onClick={() =>
                  this.setState({
                    showTextInput: false,
                    to: '',
                    selectedContactPersonId: null,
                    selectedVendorEmail: false,
                    selectedVendorPurchaseOrderEmail: false,
                    errors: {
                      ...this.state.errors,
                      showInvalidEmailError: false,
                    },
                  })
                }
              />
            </div>
          }
        >
          <FieldErrorWrapper
            position="top"
            show={this.state.errors.showInvalidEmailError}
            errorElement={
              <FormattedMessage id="components.purchase-order-email-modal.errors.invalid-email" />
            }
          >
            <Field.Text
              autoFocus
              error={this.state.errors.showInvalidEmailError}
              value={this.state.to}
              onChange={to => {
                if (this.state.errors.showInvalidEmailError) {
                  this.setState({
                    to,
                    errors: {
                      ...this.state.errors,
                      showInvalidEmailError: false,
                    },
                  });
                } else {
                  this.setState({ to });
                }
              }}
            />
          </FieldErrorWrapper>
        </Field>
      );
    }
    return (
      <Field
        view={false}
        label={
          <div className={styles['label-container']}>
            <FormattedMessage id="components.purchase-order-email-modal.to" />
            <Button
              primary
              fontSize={12}
              type="text"
              label="components.purchase-order-email-modal.own-value"
              noUnderline
              onClick={() =>
                this.setState({
                  showTextInput: true,
                  to: '',
                  selectedContactPersonId: null,
                  selectedVendorEmail: false,
                  selectedVendorPurchaseOrderEmail: false,
                  errors: {
                    ...this.state.errors,
                    showInvalidEmailError: false,
                  },
                })
              }
            />
          </div>
        }
      >
        <FieldErrorWrapper
          position="top"
          show={this.state.errors.showInvalidEmailError}
          errorElement={<FormattedMessage id="components.purchase-order-email-modal.errors.invalid-email" />}
        >
          <Field.Dropdown
            error={this.state.errors.showInvalidEmailError}
            clearable={false}
            value={this.renderSelectedToValue()}
          >
            {this.renderDropdownItems()}
          </Field.Dropdown>
        </FieldErrorWrapper>
      </Field>
    );
  };

  renderCopyField = () => {
    return (
      <>
        <AnimateHeight duration={250} height={this.state.showCopyField ? 'auto' : 0}>
          <div className={styles['cc-field']}>
            <Field view={false} label={<FormattedMessage id="components.purchase-order-email-modal.cc" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.errors.showInvalidEmailErrorForCopyField}
                errorElement={
                  <FormattedMessage id="components.purchase-order-email-modal.errors.invalid-email" />
                }
              >
                <Field.Text
                  autoFocus
                  error={this.state.errors.showInvalidEmailErrorForCopyField}
                  value={this.state.cc}
                  onChange={cc => {
                    if (this.state.errors.showInvalidEmailErrorForCopyField) {
                      this.setState({
                        cc,
                        errors: {
                          ...this.state.errors,
                          showInvalidEmailErrorForCopyField: false,
                        },
                      });
                    } else {
                      this.setState({ cc });
                    }
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </div>
        </AnimateHeight>
      </>
    );
  };

  renderSecretCopyField = () => {
    return (
      <>
        <AnimateHeight duration={250} height={this.state.showSecretCopyField ? 'auto' : 0}>
          <div className={styles['bcc-field']}>
            <Field view={false} label={<FormattedMessage id="components.purchase-order-email-modal.bcc" />}>
              <FieldErrorWrapper
                position="top"
                show={this.state.errors.showInvalidEmailErrorForSecretCopyField}
                errorElement={
                  <FormattedMessage id="components.purchase-order-email-modal.errors.invalid-email" />
                }
              >
                <Field.Text
                  autoFocus
                  error={this.state.errors.showInvalidEmailErrorForSecretCopyField}
                  value={this.state.bcc}
                  onChange={bcc => {
                    if (this.state.errors.showInvalidEmailErrorForSecretCopyField) {
                      this.setState({
                        bcc,
                        errors: {
                          ...this.state.errors,
                          showInvalidEmailErrorForSecretCopyField: false,
                        },
                      });
                    } else {
                      this.setState({ bcc });
                    }
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </div>
        </AnimateHeight>
      </>
    );
  };

  renderContent = () => (
    <>
      <div className={styles['send-to']}>{this.renderDropdownMenu()}</div>
      <div className={styles['cc-buttons']}>
        <Button
          type="text"
          primary
          fontSize={12}
          label="components.purchase-order-email-modal.cc"
          noUnderline
          onClick={() => this.setState({ showCopyField: true })}
        />
        <Button
          type="text"
          primary
          fontSize={12}
          label="components.purchase-order-email-modal.bcc"
          noUnderline
          onClick={() => this.setState({ showSecretCopyField: true })}
        />
      </div>
      {this.renderCopyField()}
      {this.renderSecretCopyField()}
      <div className={styles['from']}>
        <Field view singleRow label={<FormattedMessage id="components.purchase-order-email-modal.from" />}>
          <>
            <span>{this.props.currentUser.name}</span>
            <span> - </span>
            <span>{this.props.currentSystem.name} </span>
            <span className={styles['subtitle']}>(no-reply@mainter.com)</span>
          </>
        </Field>
        <Field
          view
          singleRow
          label={<FormattedMessage id="components.purchase-order-email-modal.reply-to" />}
        >
          <span>{this.props.currentUser.email}</span>
        </Field>
      </div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.purchase-order-email-modal.subject" />}
            >
              <FieldErrorWrapper
                position="top"
                show={this.state.errors.showSubjectRequiredError}
                errorElement={<FormattedMessage id="general.errors.is-required" />}
              >
                <Field.Text
                  value={this.state.emailMessage.subject}
                  error={this.state.errors.showSubjectRequiredError}
                  onChange={subject => {
                    if (this.state.errors.showSubjectRequiredError) {
                      this.setState({
                        emailMessage: {
                          ...this.state.emailMessage,
                          subject,
                        },
                        errors: {
                          ...this.state.errors,
                          showSubjectRequiredError: false,
                        },
                      });
                    } else {
                      this.setState({
                        emailMessage: {
                          ...this.state.emailMessage,
                          subject,
                        },
                      });
                    }
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={false}
              label={<FormattedMessage id="components.purchase-order-email-modal.message" />}
            >
              <FieldErrorWrapper
                position="top"
                show={this.state.errors.showMessageRequiredError}
                errorElement={<FormattedMessage id="general.errors.is-required" />}
              >
                <Field.Textarea
                  value={this.state.emailMessage.message}
                  error={this.state.errors.showMessageRequiredError}
                  onChange={message => {
                    if (this.state.errors.showMessageRequiredError) {
                      this.setState({
                        emailMessage: {
                          ...this.state.emailMessage,
                          message,
                        },
                        errors: {
                          ...this.state.errors,
                          showMessageRequiredError: false,
                        },
                      });
                    } else {
                      this.setState({
                        emailMessage: {
                          ...this.state.emailMessage,
                          message,
                        },
                      });
                    }
                  }}
                />
              </FieldErrorWrapper>
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div className={styles['show-file']}>
              <div className={styles['icon-container']}>
                <Icon type="file-pdf" red />
              </div>
              <Button
                type="text"
                fontSize={12}
                translate={false}
                label={
                  <FormattedMessage
                    id="components.purchase-order-email-modal.purchase-order-text"
                    values={{ number: this.props.purchaseOrder.number }}
                  />
                }
                onClick={() => {
                  const { showPrice, showSparePartsInfo } = this.state.purchaseOrderPdfOptions;
                  window.open(
                    `${process.env.REACT_APP_BROWSER_URL}purchase_orders/${this.props.purchaseOrder.id}/purchase_order.pdf?new_show_spare_part_info=${showSparePartsInfo}&show_price=${showPrice}`,
                    '_blank'
                  );
                }}
              />
              <div className={styles['separator']} />
              <Tooltip
                trigger={
                  <Button
                    type="icon"
                    icon={<Icon regular type="cog" />}
                    onClick={() => this.setState({ showPrintPurchaseOrderModal: true })}
                  />
                }
                label={<FormattedMessage id="components.work-order-email-modal.configure-pdf-options" />}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );

  render() {
    if (!this.props.vendor) return null;
    return (
      <>
        <Modal isOpen={this.props.open} width={550}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="components.purchase-order-email-modal.title" />}
            subtitle={
              <FormattedMessage
                id="components.purchase-order-email-modal.subtitle"
                values={{ name: this.props.vendor.name }}
              />
            }
            onClose={this.props.onClose}
            iconButtons={
              <>
                <div
                  ref={ref => (this.inlineModalPositioningRef = ref)}
                  onClick={() => {
                    this.setState(prevState => ({
                      dropdownOpen: !prevState.dropdownOpen,
                    }));
                  }}
                >
                  <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
                </div>
                <InlineModal
                  open={this.state.dropdownOpen}
                  positionToRef={this.inlineModalPositioningRef}
                  onClose={() => this.setState({ dropdownOpen: false })}
                  position="right"
                >
                  <InlineModal.Body dropdown>
                    <InlineModal.ListItem
                      icon="envelope"
                      iconThickness="regular"
                      onClick={() =>
                        this.setState({
                          dropdownOpen: false,
                          showEditPurchaseOrderEmailDefaultValuesModal: true,
                        })
                      }
                      title={
                        <FormattedMessage id="components.purchase-order-email-modal.manage-default-values" />
                      }
                    />
                  </InlineModal.Body>
                </InlineModal>
              </>
            }
          />
          {this.state.isFetching ? (
            <Modal.Loader />
          ) : (
            <>
              <Modal.Content>{this.renderContent()}</Modal.Content>
              <Modal.Footer>
                <Button.Group>
                  <Button
                    primary
                    label="general.send"
                    loading={this.state.isSending}
                    onClick={this.sendEmailToVendor}
                  />
                  <Button label="general.cancel" onClick={this.props.onClose} />
                </Button.Group>
              </Modal.Footer>
            </>
          )}
        </Modal>
        <PrintPurchaseOrderModal
          fromEmailVendor
          showSparePartsInfo={this.state.purchaseOrderPdfOptions.showSparePartsInfo}
          showPrice={this.state.purchaseOrderPdfOptions.showPrice}
          language={this.state.purchaseOrderPdfOptions.language}
          open={this.state.showPrintPurchaseOrderModal}
          onSave={({ showSparePartsInfo, showPrice, language }) => {
            this.setState({
              purchaseOrderPdfOptions: {
                showSparePartsInfo,
                showPrice,
                language,
              },
            });
          }}
          onClose={() => this.setState({ showPrintPurchaseOrderModal: false })}
        />
        <EditPurchaseOrderEmailDefaultValuesModal
          open={this.state.showEditPurchaseOrderEmailDefaultValuesModal}
          onClose={() => {
            this.setState({ showEditPurchaseOrderEmailDefaultValuesModal: false });
          }}
          onSave={({ purchase_order_email_subject, purchase_order_email_message }) => {
            const { subject, message } = this.state.emailMessage;
            if (!subject && !message && purchase_order_email_subject && purchase_order_email_message) {
              this.setState({
                emailMessage: {
                  subject: purchase_order_email_subject,
                  message: purchase_order_email_message,
                },
                showEditPurchaseOrderEmailDefaultValuesModal: false,
              });
            } else {
              this.setState({
                showEditPurchaseOrderEmailDefaultValuesModal: false,
              });
            }
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: AuthSelectors.getCurrentUser(state),
    currentUserSettings: AuthSelectors.getCurrentUserSettings(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    language: AuthSelectors.getLanguage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      sendPurchaseOrder: SDKReduxOperations.sendPurchaseOrder,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SendEmailModal));
