import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash-es';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Field, List, WhiteCard, EmptyDataSet, ViewTextArea } from 'views/components/Shared/General';
import PurchaseOrderDeliverySmall from 'assets/images/EmptyDataSet/PurchaseOrderDeliverySmall.png';
import { Modal, Grid } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import styles from './style.module.scss';
import PurchaseOrderRowListItem from './PurchaseOrderRowListItem';
import VendorField from './VendorField';
import ContactPersonField from './ContactPersonField';

const ModalTabs = {
  Articles: 'articles',
  PurchaseOrder: 'purchaseOrder',
};

class CreateDeliveryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      listOfPurchaseOrderRowsToDeliver: {},
      purchaseOrderRows: [],
      hideFullyDelivered: true,
      hasAnyFullyDelivereRows: false,
      selectedTab: ModalTabs.Articles,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let hasAnyFullyDelivereRows = this.props.purchaseOrderRows.some(({ quantity, delivered_quantity }) =>
        new Decimal(delivered_quantity).greaterThanOrEqualTo(new Decimal(quantity))
      );
      let purchaseOrderRowsOpenForDelivery = this.props.purchaseOrderRows.filter(
        ({ quantity, delivered_quantity }) => new Decimal(delivered_quantity).lessThan(new Decimal(quantity))
      );
      let listOfPurchaseOrderRowsToDeliver = {};
      purchaseOrderRowsOpenForDelivery.forEach(({ id, quantity, delivered_quantity }) => {
        listOfPurchaseOrderRowsToDeliver[id] = {
          purchase_order_row_id: id,
          quantity: new Decimal(quantity).minus(new Decimal(delivered_quantity)).toString(),
        };
      });
      this.setState({
        selectedTab: ModalTabs.Articles,
        listOfPurchaseOrderRowsToDeliver,
        isSaving: false,
        hideFullyDelivered: true,
        hasAnyFullyDelivereRows,
        purchaseOrderRows: purchaseOrderRowsOpenForDelivery,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === false) return false;
    return true;
  }

  getCreatePurchaseOrderDeliveryRowsParams = () => {
    return Object.values(this.state.listOfPurchaseOrderRowsToDeliver);
  };

  save = () => {
    this.setState({ isSaving: true });
    this.props
      .createPurchaseOrderDelivery(this.props.purchaseOrder.id, {
        purchase_order_delivery_rows: this.getCreatePurchaseOrderDeliveryRowsParams(),
      })
      .then(({ data: purchaseOrderDelivery }) => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.create-delivery-modal.create-success" />}
          />
        );
        this.props.onSave(purchaseOrderDelivery);
      });
  };

  renderFullyDeliveredEmptyDataset = () => (
    <WhiteCard centerContent noPadding>
      <EmptyDataSet
        title={
          <FormattedMessage id="components.create-delivery-modal.fully-delivered-empty-data-set.title" />
        }
        subtitle={
          <FormattedMessage id="components.create-delivery-modal.fully-delivered-empty-data-set.subtitle" />
        }
        image={PurchaseOrderDeliverySmall}
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderPurchaseOrderRows = () => {
    if (this.state.purchaseOrderRows.length === 0) {
      return this.renderFullyDeliveredEmptyDataset();
    }
    return (
      <List>
        {this.state.purchaseOrderRows.map(purchaseOrderRow => (
          <PurchaseOrderRowListItem
            key={purchaseOrderRow.id}
            purchaseOrderRow={purchaseOrderRow}
            onBlur={quantity => {
              this.setState({
                listOfPurchaseOrderRowsToDeliver: {
                  ...this.state.listOfPurchaseOrderRowsToDeliver,
                  [purchaseOrderRow.id]: {
                    ...this.state.listOfPurchaseOrderRowsToDeliver[purchaseOrderRow.id],
                    quantity,
                  },
                },
              });
            }}
            onSelect={({ selected, quantity }) => {
              let value = {};
              value[purchaseOrderRow.id] = {
                purchase_order_row_id: purchaseOrderRow.id,
                quantity,
              };
              if (selected) {
                this.setState({
                  listOfPurchaseOrderRowsToDeliver: {
                    ...this.state.listOfPurchaseOrderRowsToDeliver,
                    ...value,
                  },
                });
              } else {
                let newListOfPurchaseOrderRowsToDeliver = omit(
                  this.state.listOfPurchaseOrderRowsToDeliver,
                  purchaseOrderRow.id
                );
                this.setState({
                  listOfPurchaseOrderRowsToDeliver: { ...newListOfPurchaseOrderRowsToDeliver },
                });
              }
            }}
          />
        ))}
      </List>
    );
  };

  renderTabs = () => (
    <Modal.Header.TabBar>
      <Modal.Header.TabBarItem
        onClick={() => this.setState({ selectedTab: ModalTabs.Articles })}
        active={this.state.selectedTab === ModalTabs.Articles}
      >
        <FormattedMessage id="components.create-delivery-modal.tabs.articles" />
      </Modal.Header.TabBarItem>

      <Modal.Header.TabBarItem
        onClick={() => this.setState({ selectedTab: ModalTabs.PurchaseOrder })}
        active={this.state.selectedTab === ModalTabs.PurchaseOrder}
      >
        <FormattedMessage id="components.create-delivery-modal.tabs.purchase-order" />
      </Modal.Header.TabBarItem>
    </Modal.Header.TabBar>
  );

  renderArticles = () => (
    <React.Fragment>
      {this.state.hasAnyFullyDelivereRows ? (
        <div className={styles['checkbox-container']}>
          <Field.Checkbox
            checked={this.state.hideFullyDelivered}
            label={<FormattedMessage id="components.create-delivery-modal.hide-fully-delivered" />}
            onChange={value =>
              this.setState({ hideFullyDelivered: value }, () => {
                if (this.state.hideFullyDelivered) {
                  let ids = this.props.purchaseOrderRows
                    .filter(({ quantity, delivered_quantity }) =>
                      new Decimal(delivered_quantity).greaterThanOrEqualTo(new Decimal(quantity))
                    )
                    .map(({ id }) => id);

                  this.setState({
                    purchaseOrderRows: this.props.purchaseOrderRows.filter(
                      ({ quantity, delivered_quantity }) =>
                        new Decimal(delivered_quantity).lessThan(new Decimal(quantity))
                    ),
                    listOfPurchaseOrderRowsToDeliver: {
                      ...omit(this.state.listOfPurchaseOrderRowsToDeliver, ids),
                    },
                  });
                } else {
                  this.setState({
                    purchaseOrderRows: this.props.purchaseOrderRows,
                  });
                }
              })
            }
          />
        </div>
      ) : null}

      {this.renderPurchaseOrderRows()}
    </React.Fragment>
  );

  renderPurchaseOrderVendorInformation = () => (
    <WhiteCard
      title={<FormattedMessage id="screens.purchase-order.info.vendor.title" />}
      collapsable
      defaultOpen={false}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              view={!this.state.editing}
              label={<FormattedMessage id="resources.purchase-order.vendor" />}
            >
              <VendorField
                edit={this.state.editing}
                value={this.props.purchaseOrder.vendor_id}
                onChange={vendor_id => this.setPurchaseOrderValue({ vendor_id })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field view label={<FormattedMessage id="resources.purchase-order.your-ref" />}>
              <ContactPersonField value={this.props.purchaseOrder.vendor_reference_contact_person_id} />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </WhiteCard>
  );

  renderPurchaseOrderGeneralInformation = () => (
    <WhiteCard
      title={<FormattedMessage id="screens.purchase-order.info.general-information.title" />}
      collapsable
      defaultOpen={false}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.ordered-date" />}>
              {moment(this.props.purchaseOrder.ordered_date).format('LL')}
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field
              view={!this.state.editing}
              label={<FormattedMessage id="resources.purchase-order.expected-delivery-date" />}
            >
              {getEstimatedDeliveryDateText(this.props.purchaseOrder)}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Separator />
        <Grid.Row>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.internal-comment" />}>
              {this.props.purchaseOrder.internal_comment ? (
                <ViewTextArea>{this.props.purchaseOrder.internal_comment}</ViewTextArea>
              ) : (
                '-'
              )}
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field view label={<FormattedMessage id="resources.purchase-order.external-comment" />}>
              {this.props.purchaseOrder.external_comment ? (
                <ViewTextArea>{this.props.purchaseOrder.external_comment}</ViewTextArea>
              ) : (
                '-'
              )}
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </WhiteCard>
  );

  renderPurchaseOrderInformation = () => (
    <React.Fragment>
      <div className={styles['white-card-container']}>{this.renderPurchaseOrderGeneralInformation()}</div>
      {this.renderPurchaseOrderVendorInformation()}
    </React.Fragment>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={720}>
        <Modal.Header
          title={<FormattedMessage id="components.create-delivery-modal.title" />}
          subtitle={<FormattedMessage id="components.create-delivery-modal.subtitle" />}
          onClose={this.props.onClose}
          tabBarComponent={this.renderTabs()}
          ignoreLine
        />
        <Modal.Content grayBackground hasTabs>
          {this.state.selectedTab === ModalTabs.Articles
            ? this.renderArticles()
            : this.renderPurchaseOrderInformation()}
        </Modal.Content>
        <Modal.Footer container>
          <Button.Group>
            <Button
              disabled={this.getCreatePurchaseOrderDeliveryRowsParams().length === 0}
              loading={this.state.isSaving}
              primary
              label="general.save"
              onClick={this.save}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPurchaseOrderDelivery: SDKReduxOperations.createPurchaseOrderDelivery,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (!ownProps.open) return {};
  return {
    purchaseOrderRows:
      EntitySelectors.getPurchaseOrderRows(state, ownProps.purchaseOrder.purchase_order_rows) || [],
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateDeliveryModal));
