import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { Button, PathItem, EmptyDataSet, Icon } from 'views/components/Shared/General';
import {
  SparePartLocationOptionsInlineModal,
  SparePartLocation,
  SparePartLocationModal,
} from 'views/components/SparePartLocation';
import EntityOperations from 'sdk/State/entities/operations';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { HelperFunctions } from 'sdk';
import { normalizeSparePartLocation } from 'sdk/Schemas';
import styles from './style.module.scss';

class SparePartLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSparePartLocationModal: false,
      editingSparePartLocation: null,
      isFetching: true,
      createdSparePartLocationIds: [],
    };
  }

  componentDidMount() {
    API.listSparePartLocations(this.props.currentSystem.id).then(({ data: sparePartLocations }) => {
      const { entities } = normalizeSparePartLocation(sparePartLocations);
      this.props.updateEntities(entities);
      this.setState({ isFetching: false });
    });
  }

  buildSparePartLocationForSparePartLocationId = sparePartLocationId => {
    const sparePartLocation = this.props.allSparePartLocations.find(
      sparePartLocation => sparePartLocationId === sparePartLocation.id
    );
    if (sparePartLocation == null) return [];
    let sparePartLocations = [sparePartLocation];
    this.props.allSparePartLocations.forEach(loopedSparePartLocation => {
      if (sparePartLocation.spare_part_location_parent_id === loopedSparePartLocation.id) {
        sparePartLocations = [
          ...this.buildSparePartLocationForSparePartLocationId(loopedSparePartLocation.id),
          ...sparePartLocations,
        ];
      }
    });
    return sparePartLocations;
  };

  renderSparePartLocationPathTitle = () => {
    const sparePartLocationId = queryString.parse(this.props.location.search).spare_part_location_id;
    const sparePartLocationPath = this.buildSparePartLocationForSparePartLocationId(sparePartLocationId);
    return (
      <div className={styles['spare-part-location-path']}>
        <PathItem
          active={sparePartLocationId == null}
          clickable={sparePartLocationId != null}
          onClick={() => {
            this.setState({ createdSparePartLocationIds: [] });
            this.props.history.push(`/settings/spare-parts/storage`);
          }}
        >
          <FormattedMessage id="screens.settings.spare-parts.storage.root-title" />
        </PathItem>
        {sparePartLocationId == null ? null : (
          <React.Fragment>
            {sparePartLocationPath.map((sparePartLocation, index) => {
              if (index !== sparePartLocationPath.length - 1) {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <PathItem
                      clickable
                      onClick={() => {
                        this.setState({ createdSparePartLocationIds: [] });
                        this.props.history.push(
                          `?${HelperFunctions.convertObjToQueryParameters({
                            spare_part_location_id: sparePartLocation.id,
                          })}`
                        );
                      }}
                    >
                      {sparePartLocation.title}
                    </PathItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Icon regular type="angle-right" />
                    <SparePartLocationOptionsInlineModal
                      sparePartLocation={sparePartLocation}
                      trigger={
                        <PathItem active clickable caret>
                          {sparePartLocation.title}
                        </PathItem>
                      }
                      onEditSparePartLocation={sparePartLocation => {
                        this.setState({
                          showSparePartLocationModal: true,
                          editingSparePartLocation: sparePartLocation,
                        });
                      }}
                    />
                  </React.Fragment>
                );
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderSparePartLocation = sparePartLocation => {
    return (
      <SparePartLocation
        id={sparePartLocation.id}
        onClick={() => {
          this.setState({ createdSparePartLocationIds: [] });
          this.props.history.push(
            `?${HelperFunctions.convertObjToQueryParameters({
              spare_part_location_id: sparePartLocation.id,
            })}`
          );
        }}
        button={
          <SparePartLocationOptionsInlineModal
            sparePartLocation={sparePartLocation}
            onEditSparePartLocation={sparePartLocation => {
              this.setState({
                showSparePartLocationModal: true,
                editingSparePartLocation: sparePartLocation,
              });
            }}
            trigger={<Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />}
          />
        }
      />
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <>
          <div className={styles['title-container']}>{this.renderSparePartLocationPathTitle()}</div>
          <div className={styles['spare-part-locations-container']}>
            <div className={styles['spare-part-locations']}>
              <SparePartLocation loading />
              <SparePartLocation loading />
            </div>
          </div>
        </>
      );
    }
    if (this.props.allSparePartLocations.length === 0) {
      return this.renderEmptyDataset();
    }
    return (
      <React.Fragment>
        <div className={styles['title-container']}>{this.renderSparePartLocationPathTitle()}</div>
        <div className={styles['spare-part-locations-container']}>
          <div className={styles['spare-part-locations']}>
            {this.props.sparePartLocations
              .filter(({ id }) => this.state.createdSparePartLocationIds.includes(id) === false)
              .sort((a, b) => {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                  return -1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map(sparePartLocation => this.renderSparePartLocation(sparePartLocation))}
            {this.props.sparePartLocations
              .filter(({ id }) => this.state.createdSparePartLocationIds.includes(id))
              .map(sparePartLocation => this.renderSparePartLocation(sparePartLocation))}
            <div
              className={styles['spare-part-location']}
              onClick={() => {
                this.setState({ showSparePartLocationModal: true });
              }}
            >
              <div className={styles['content-container']}>
                <Icon type="plus" />
                <div>
                  <p className={styles['spare-part-location-title']}>
                    <FormattedMessage id="screens.settings.spare-parts.storage.new-storage-part-location" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.spare-parts.storage.empty-data-set.title" />}
      button={
        <Button
          small
          primary
          onClick={() => {
            this.setState({ showSparePartLocationModal: true });
          }}
          label="screens.settings.spare-parts.storage.empty-data-set.button"
        />
      }
      modal
    />
  );

  render() {
    return (
      <>
        <BackButton to="/settings/spare-parts" />
        <Section
          title={<FormattedMessage id="screens.settings.spare-parts.storage.title" />}
          subtitle={<FormattedMessage id="screens.settings.spare-parts.storage.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <SparePartLocationModal
          open={this.state.showSparePartLocationModal}
          parentSparePartLocationId={queryString.parse(this.props.location.search).spare_part_location_id}
          sparePartLocation={this.state.editingSparePartLocation}
          onClose={() => {
            this.setState({ showSparePartLocationModal: false, editingSparePartLocation: null });
          }}
          onCreate={id => {
            this.setState({
              createdSparePartLocationIds: [...this.state.createdSparePartLocationIds, id],
              showSparePartLocationModal: false,
              editingSparePartLocation: null,
            });
          }}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const sparePartLocationId = queryString.parse(ownProps.location.search).spare_part_location_id;
  return {
    sparePartLocations: EntitySelectors.getSparePartLocationsForLocation(state, sparePartLocationId),
    allSparePartLocations: EntitySelectors.getSparePartLocations(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePartLocations)));
