import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';

class PrintPurchaseOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSparePartsInfo: false,
      showPrice: true,
      language: props.currentUserLanguage,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { showSparePartsInfo, showPrice, language, currentUserLanguage } = this.props;
      this.setState({
        showSparePartsInfo: showSparePartsInfo || false,
        showPrice: showPrice == null ? true : showPrice,
        language: language || currentUserLanguage,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderEnglishLanguageOption = () => {
    if (this.props.currentUserLanguage === 'sv') {
      return (
        <>
          <Grid.Separator />
          <Grid.Row>
            <Grid.Column>
              <Field.Checkbox.Group>
                <Field.Radio
                  label={<FormattedMessage id="components.print-purchase-order-modal.language.sv" />}
                  checked={this.state.language === 'sv'}
                  onChange={() => this.setState({ language: 'sv' })}
                />
                <Field.Radio
                  label={<FormattedMessage id="components.print-purchase-order-modal.language.en" />}
                  checked={this.state.language === 'en'}
                  onChange={() => this.setState({ language: 'en' })}
                />
              </Field.Checkbox.Group>
            </Grid.Column>
          </Grid.Row>
        </>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={500}>
        <Modal.Header
          ignoreLine
          title={
            this.props.fromEmailVendor ? (
              <FormattedMessage id="components.print-purchase-order-modal.configure-pdf-modal-title" />
            ) : (
              <FormattedMessage id="components.print-purchase-order-modal.title" />
            )
          }
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field.Checkbox.Group>
                  <Field.Checkbox
                    disabled
                    checked
                    label={
                      <FormattedMessage id="components.print-purchase-order-modal.purchase-order-info" />
                    }
                  />
                  <Field.Checkbox
                    checked={this.state.showPrice}
                    label={<FormattedMessage id="components.print-purchase-order-modal.price" />}
                    onChange={() => {
                      this.setState(prevState => ({
                        showPrice: !prevState.showPrice,
                      }));
                    }}
                  />
                  <Field.Checkbox
                    checked={this.state.showSparePartsInfo}
                    label={
                      <FormattedMessage id="components.print-purchase-order-modal.show-spare-part-info" />
                    }
                    onChange={() => {
                      this.setState(prevState => ({
                        showSparePartsInfo: !prevState.showSparePartsInfo,
                      }));
                    }}
                  />
                </Field.Checkbox.Group>
              </Grid.Column>
            </Grid.Row>
            {this.renderEnglishLanguageOption()}
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label={
                this.props.fromEmailVendor ? 'general.save' : 'components.print-purchase-order-modal.print'
              }
              onClick={() => {
                const { showSparePartsInfo, showPrice, language } = this.state;
                if (this.props.fromEmailVendor) {
                  this.props.onSave({
                    showSparePartsInfo,
                    showPrice,
                    language,
                  });
                  this.props.onClose();
                } else {
                  window.open(
                    `${process.env.REACT_APP_BROWSER_URL}purchase_orders/${this.props.purchaseOrder.id}/purchase_order.pdf?new_show_spare_part_info=${showSparePartsInfo}&show_price=${showPrice}&language=${this.state.language}`,
                    '_blank'
                  );
                  this.props.onClose();
                }
              }}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUserLanguage: AuthSelectors.getLanguage(state),
  };
}

export default connect(mapStateToProps)(PrintPurchaseOrderModal);
