import actions from './actions';
import { buildReduxChecklistRowData } from 'sdk/ChecklistTemplate';

const setChecklistTemplateVersion = id => (dispatch, getState) => {
  const checklistTemplateVersion = getState().entities.checklistTemplateVersionById[id];
  const checklistTemplateRows = checklistTemplateVersion.checklist_template_rows.map(
    id => getState().entities.checklistTemplateRowById[id]
  );
  const { rootRowIds, childRowIds } = buildReduxChecklistRowData({
    checklist_template_rows: checklistTemplateRows,
  });
  dispatch(
    actions.setChecklistTemplateVersion({
      id,
      rootRows: rootRowIds,
      childRows: childRowIds,
    })
  );
};

export default {
  setChecklistTemplateVersion,
};
