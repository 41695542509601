import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { HelperFunctions } from 'sdk';
import { SideModal } from 'views/components/Shared/Layout';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { FILTER_TYPES } from 'views/scenes/Requests/FilterModal';

class WorkOrderCompletedDate extends Component {
  render() {
    const { comparator, value } = this.props.filter.completed_date;
    if (value) {
      return (
        <SideModal.Container.Filter.AppliedFilters.Filter.Date
          title={<FormattedMessage id="resources.work-order.completed-at" />}
          comparator={comparator}
          onClear={() =>
            this.props.removeFilter({
              key: FILTER_TYPES.WorkOrderCompletedDate,
              data: {
                work_order: {
                  ...this.props.filter,
                  completed_date: {
                    comparator: HelperFunctions.FILTER_COMPARABLES.Exact,
                    value: null,
                  },
                },
              },
            })
          }
        >
          <SideModal.Container.Filter.AppliedFilters.Value>
            {value}
          </SideModal.Container.Filter.AppliedFilters.Value>
        </SideModal.Container.Filter.AppliedFilters.Filter.Date>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter: RequestsOperations.removeFilter,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    filter: RequestsSelectors.getFiltersForKey(state, 'work_order'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderCompletedDate);
