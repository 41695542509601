import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { FormattedNumber } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { ReminderType } from 'sdk/WorkOrder';

const ReminderMessage = props => {
  if (props.workOrder.reminder_type === ReminderType.Exact) {
    return (
      <>{moment(props.workOrder.reminder_date_exact).format('LL')}</>
    );
  }
  if (props.workOrder.reminder_type === ReminderType.RelativeToDueDate) {
    if (props.workOrder.reminder_days_relative_to_due_date === 0) {
      return <FormattedMessage id="screens.work-order.reminder.text-relative-to-due-date-same" />;
    }
    if (props.workOrder.reminder_days_relative_to_due_date > 0) {
      return (
        <FormattedPlural
          value={Math.abs(props.workOrder.reminder_days_relative_to_due_date)}
          one={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-due-date-after.one"
              values={{ days: Math.abs(props.workOrder.reminder_days_relative_to_due_date) }}
            />
          }
          other={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-due-date-after.other"
              values={{ days: Math.abs(props.workOrder.reminder_days_relative_to_due_date) }}
            />
          }
        />
      );
    }
    if (props.workOrder.reminder_days_relative_to_due_date < 0) {
      return (
        <FormattedPlural
          value={Math.abs(props.workOrder.reminder_days_relative_to_due_date)}
          one={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-due-date-before.one"
              values={{ days: Math.abs(props.workOrder.reminder_days_relative_to_due_date) }}
            />
          }
          other={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-due-date-before.other"
              values={{ days: Math.abs(props.workOrder.reminder_days_relative_to_due_date) }}
            />
          }
        />
      );
    }
  }
  if (props.workOrder.reminder_type === ReminderType.RelativeToMeterReading) {
    if (props.workOrder.reminder_relative_to_meter === 0) {
      return <FormattedMessage id="screens.work-order.reminder.text-relative-to-meter-value-same" />;
    }
    if (props.workOrder.reminder_relative_to_meter > 0) {
      return (
        <FormattedPlural
          value={Math.abs(props.workOrder.reminder_relative_to_meter)}
          one={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-meter-value-after.one"
              values={{
                value: (
                  <FormattedNumber
                    value={Math.abs(props.workOrder.reminder_relative_to_meter)}
                    unit={props.meterUnit ? props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          }
          other={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-meter-value-after.other"
              values={{
                value: (
                  <FormattedNumber
                    value={Math.abs(props.workOrder.reminder_relative_to_meter)}
                    unit={props.meterUnit ? props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          }
        />
      );
    }
    if (props.workOrder.reminder_relative_to_meter < 0) {
      return (
        <FormattedPlural
          value={Math.abs(props.workOrder.reminder_relative_to_meter)}
          one={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-meter-value-before.one"
              values={{
                value: (
                  <FormattedNumber
                    value={Math.abs(props.workOrder.reminder_relative_to_meter)}
                    unit={props.meterUnit ? props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          }
          other={
            <FormattedMessage
              id="screens.work-order.reminder.text-relative-to-meter-value-before.other"
              values={{
                value: (
                  <FormattedNumber
                    value={Math.abs(props.workOrder.reminder_relative_to_meter)}
                    unit={props.meterUnit ? props.meterUnit.abbreviation : null}
                  />
                ),
              }}
            />
          }
        />
      );
    }
  }
  return null;
};

function mapStateToProps(state, ownProps) {
  const { meter_id } = ownProps.workOrder;
  const meter = EntitySelectors.getMeter(state, meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    meter,
    meterUnit,
  };
}

export default connect(mapStateToProps)(ReminderMessage);
