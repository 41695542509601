import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Route, Switch, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Section, Option } from 'views/scenes/Settings/components';
import { Button, Toggle, ConfirmModal, WhiteCard } from 'views/components/Shared/General';
import AMServiceLogo from 'assets/images/Logo.png';
import Meters_Eng from 'assets/images/ProScreenshots/SettingsMeters(en).png';
import Meters_Swe from 'assets/images/ProScreenshots/SettingsMeters(sv).png';
import Units from './Units';
import styles from './style.module.scss';

class Meters extends Component {
  state = {
    isActivatingMeters: false,
    showActivateMetersModal: false,
    showDeactivateMetersModal: false,
    isSavingDeactivateMetersModal: false,
    isSavingActivateMetersModal: false,
  };

  updateSettings = params => {
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          showActivateMetersModal: false,
          showDeactivateMetersModal: false,
          isSavingDeactivateMetersModal: false,
          isSavingActivateMetersModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          showActivateMetersModal: false,
          showDeactivateMetersModal: false,
          isSavingDeactivateMetersModal: false,
          isSavingActivateMetersModal: false,
        });
      });
  };

  renderActivateMetersModal = () => (
    <ConfirmModal
      open={this.state.showActivateMetersModal}
      title={<FormattedMessage id="screens.settings.meters.activate-modal-title" />}
      message={<FormattedMessage id="screens.settings.meters.description" />}
      confirmButtonText="general.activate"
      confirmIsLoading={this.state.isSavingActivateMetersModal}
      onConfirm={() => {
        if (this.state.isSavingActivateMetersModal) {
          return;
        }
        this.setState({ isSavingActivateMetersModal: true });
        this.updateSettings({ meters_activated: true });
      }}
      onCancel={() => {
        this.setState({ showActivateMetersModal: false });
      }}
    />
  );

  renderDeactivateMetersModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeactivateMetersModal}
      title={<FormattedMessage id="screens.settings.meters.deactivate-modal-title" />}
      message={<FormattedMessage id="screens.settings.meters.description" />}
      confirmButtonText="general.deactivate"
      confirmIsLoading={this.state.isSavingDeactivateMetersModal}
      onConfirm={() => {
        if (this.state.isSavingDeactivateMetersModal) {
          return;
        }
        this.setState({ isSavingDeactivateMetersModal: true });
        this.updateSettings({ meters_activated: false });
      }}
      onCancel={() => {
        this.setState({ showDeactivateMetersModal: false });
      }}
    />
  );

  renderRootSettings = () => (
    <>
      <Section
        header
        title={<FormattedMessage id="screens.settings.meters.title" />}
        subtitle={<FormattedMessage id="screens.settings.meters.description" />}
        rightComponent={
          <Toggle
            checked={this.props.settings.meters_activated}
            loading={this.state.isActivatingMeters}
            onClick={() => {
              if (this.props.settings.meters_activated) {
                this.setState({ showDeactivateMetersModal: true });
              } else {
                this.setState({ showActivateMetersModal: true });
              }
            }}
          />
        }
      />
      <div className={styles['options']}>
        {this.props.settings.meters_activated ? (
          <Option.Container>
            <Option
              title={<FormattedMessage id="screens.settings.meters.units.title" />}
              subtitle={<FormattedMessage id="screens.settings.meters.units.subtitle" />}
              rightContainer={
                <Link to="/settings/meters/units">
                  <Button primary label="general.manage" />
                </Link>
              }
            />
          </Option.Container>
        ) : null}
      </div>
    </>
  );

  renderUpgradeToPro = () => (
    <WhiteCard>
      <div className={styles['upgrade-container']}>
        <div className={styles['upgrade-title-container']}>
          <img src={AMServiceLogo} alt="logo" />
          <p>
            <FormattedMessage id="screens.settings.meters.upgrade-to-pro.title" />
          </p>
        </div>
        <p className={styles['upgrade-subtitle']}>
          <FormattedMessage id="screens.settings.meters.upgrade-to-pro.description" />
        </p>
        <img
          className={styles['upgrade-screenshot']}
          alt="screenshot"
          src={this.props.language === 'sv' ? Meters_Swe : Meters_Eng}
        />
        <div className={styles['upgrade-buttons-container']}>
          <Button upgradeToPro label="general.upgrade-to-pro" />
        </div>
      </div>
    </WhiteCard>
  );

  render() {
    if (this.props.hasProTier === false) {
      return this.renderUpgradeToPro();
    }
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/meters" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/meters/units" render={props => <Units {...props} />} />
        </Switch>
        {this.renderActivateMetersModal()}
        {this.renderDeactivateMetersModal()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    hasProTier: AuthSelectors.hasProTier(state),
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Meters));
