import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, InlineModal, Icon } from 'views/components/Shared/General';

const ModalDropdownContent = {
  ShowOptions: 'showOptions',
  ShowDelete: 'showDelete',
};

export default class MoreOptionsInlineModal extends Component {
  state = {
    showOptionsInlineModal: false,
    dropdownOption: ModalDropdownContent.ShowOptions,
  };

  shouldComponentUpdate(prevProps, prevState) {
    if (!prevState.showOptionsInlineModal && !this.state.showOptionsInlineModal) {
      return false;
    }
    return true;
  }

  renderOptions = () => {
    return (
      <InlineModal.Body dropdown width={300}>
        {this.props.purchaseOrderRow.spare_part_id == null ? (
          <InlineModal.ListItem
            icon="cogs"
            iconThickness="regular"
            title={<FormattedMessage id="screens.purchase-order.info.articles.create-spare-part" />}
            onClick={() => {
              this.setState({ showOptionsInlineModal: false });
              this.props.onCreateSparePart(this.props.purchaseOrderRow);
            }}
          />
        ) : (
          <InlineModal.ListItem
            icon="arrow-alt-right"
            iconThickness="regular"
            title={<FormattedMessage id="screens.purchase-order.info.articles.go-to-spare-part" />}
            onClick={this.props.onNavigateToSparePart}
          />
        )}
        <InlineModal.Separator />
        <InlineModal.ListItem
          icon="pen"
          iconThickness="regular"
          title={<FormattedMessage id="general.edit" />}
          onClick={() => {
            this.setState({ showOptionsInlineModal: false });
            this.props.onEdit();
          }}
        />
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          destructive
          title={<FormattedMessage id="general.delete" />}
          onClick={() => {
            this.setState({ dropdownOption: ModalDropdownContent.ShowDelete });
          }}
        />
      </InlineModal.Body>
    );
  };

  renderDeleteContent = () => {
    return (
      <React.Fragment>
        <InlineModal.Header
          width={300}
          title={<FormattedMessage id="general.delete-confirm.title" />}
          onClose={() => {
            this.setState({ showOptionsInlineModal: false });
          }}
        />
        <InlineModal.Body width={300}>
          <InlineModal.Delete loading={this.props.isDeleting} onDelete={this.props.onDelete}>
            <p>
              <FormattedMessage id="general.delete-confirm.subtitle" />
            </p>
          </InlineModal.Delete>
        </InlineModal.Body>
      </React.Fragment>
    );
  };

  renderInlineModalBodyContent = () => {
    switch (this.state.dropdownOption) {
      case ModalDropdownContent.ShowOptions:
        return this.renderOptions();
      case ModalDropdownContent.ShowDelete:
        return this.renderDeleteContent();
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              showOptionsInlineModal: !prevState.showOptionsInlineModal,
              dropdownOption: ModalDropdownContent.ShowOptions,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular type="ellipsis-h" />} />
        </div>
        <InlineModal
          open={this.state.showOptionsInlineModal}
          positionToRef={this.inlineModalPositioningRef}
          onClose={() => this.setState({ showOptionsInlineModal: false })}
          position="right"
        >
          <React.Fragment>{this.renderInlineModalBodyContent()}</React.Fragment>
        </InlineModal>
      </>
    );
  }
}
