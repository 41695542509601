import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import { Button, Tooltip, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

const SparePartLocation = props => {
  const renderSparePartCount = () => {
    if (props.hideSparePartCount === false) {
      if (props.sparePartLocation.spare_part_count === 0) {
        return (
          <p className={styles['subtitle']}>
            <FormattedMessage id="components.select-spare-part-location-modal.no-spare-parts" />
          </p>
        );
      }
      return (
        <p className={styles['subtitle']}>
          <FormattedMessage
            id="components.select-spare-part-location-modal.amount-of-spare-parts"
            values={{ amount: props.sparePartLocation.spare_part_count }}
          />
        </p>
      );
    }
  };
  const renderTitleContent = () => {
    if (props.loading) {
      return (
        <div className={styles['loader-container']}>
          <ContentLoader primaryColor="#F5F5F5" secondaryColor="#EFEFEF" width={123} height={50}>
            <rect x="12" y="3" rx="3" ry="3" width="110" height="6" />
            <rect x="12" y="15" rx="3" ry="3" width="70" height="6" />
          </ContentLoader>
        </div>
      );
    } else {
      return (
        <div className={styles['title-container']}>
          <p className={styles['spare-part-location-title']}>{props.sparePartLocation.title}</p>
          {renderSparePartCount()}
        </div>
      );
    }
  };

  const renderNotSelectable = () => (
    <div className={styles['spare-part-location']} onClick={props.onClick}>
      <div className={styles['content-container']}>
        <Icon type="warehouse-alt" withBackground backgroundSize={28} size={11} />
        {renderTitleContent()}
      </div>
      {props.disclosureIndicator ? (
        <div className={styles['disclosure-indicator']}>
          <Icon regular type="angle-right" size={18} />
        </div>
      ) : null}
      {props.button == null ? null : props.button}
    </div>
  );

  const renderSelectable = () => (
    <div
      className={`${styles['spare-part-location']} ${props.selected ? styles['selected'] : ''}`}
      onClick={props.onSelect}
      onDoubleClick={() => (props.hasChildren ? props.onNavigate() : null)}
    >
      <div className={styles['content-container']}>
        {props.selected ? (
          <Icon
            type="warehouse-alt"
            withBackground
            color="#4c9ac8"
            backgroundColor="#FFF"
            backgroundSize={28}
            size={11}
          />
        ) : (
          <Icon type="warehouse-alt" withBackground backgroundSize={28} size={11} />
        )}

        {renderTitleContent()}
      </div>
      {props.hasChildren ? (
        <Tooltip
          trigger={
            <div className={styles['navigate-container']}>
              <Button
                type="icon"
                icon={<Icon regular size={16} type="angle-right" />}
                onClick={e => {
                  e.stopPropagation();
                  props.onNavigate();
                }}
              />
            </div>
          }
          label={
            <FormattedMessage
              id="components.select-spare-part-location-modal.open-location"
              values={{ location: props.sparePartLocation.title }}
            />
          }
        />
      ) : null}
    </div>
  );

  return props.selectable ? renderSelectable() : renderNotSelectable();
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocation: EntitySelectors.getSparePartLocation(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(SparePartLocation);

SparePartLocation.propTypes = {
  selectable: PropTypes.bool,
  hasChildren: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  button: PropTypes.node,
  hideSparePartCount: PropTypes.bool,
};
SparePartLocation.defaultProps = {
  selectable: false,
  hasChildren: false,
  hideSparePartCount: false,
  onClick: () => {},
  onSelect: () => {},
  onNavigate: () => {},
  button: null,
};
