import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import AMServiceLogo from 'assets/images/Logo.png';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Icon } from 'views/components/Shared/General';
import Meter_SWE from 'assets/images/ProScreenshots/meter-pro-sv.png';
import Meter_ENG from 'assets/images/ProScreenshots/meter-pro-en.png';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class MeterProModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal width={900} centered isOpen={this.props.open}>
        <div className={styles['pro-modal-content']}>
          <div className={styles['modal-title']}>
            <img className={styles['am-logo']} src={AMServiceLogo} alt="Logo" />
            <FormattedMessage id="screens.work-order.edit.meter-pro-modal.title" />
          </div>
          <div className={styles['modal-subtitle']}>
            <FormattedMessage id="screens.work-order.edit.meter-pro-modal.subtitle" />
          </div>
          <div className={styles['computer-container']}>
            <img
              className={styles['computer-logo']}
              src={this.props.language === 'sv' ? Meter_SWE : Meter_ENG}
              alt="Checklist"
            />
          </div>
          <div className={styles['upgrade-buttons-container']}>
            <Button large upgradeToPro label="general.upgrade-to-pro" />
          </div>
        </div>
        <div className={styles['background-rect']} />
        <div className={styles['close-button']}>
          <Button type="icon" icon={<Icon light size={18} type="times" />} onClick={this.props.onClose} />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    language: AuthSelectors.getLanguage(state),
  };
}

export default connect(mapStateToProps)(MeterProModal);
