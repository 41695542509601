import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { List, Field } from 'views/components/Shared/General';
import { SparePartLocationPath } from 'views/components/SparePartLocation';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class SparePartListItem extends Component {
  renderExpandedComponent = () => {
    if (this.props.hasProTier === false) {
      return (
        <div className={styles['expanded-container']}>
          <div className={styles['info']}>
            <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
              {this.props.sparePart.spare_part_location_id == null ? (
                '-'
              ) : (
                <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
              )}
            </Field>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['expanded-container']}>
        <div className={styles['info']}>
          <Field view singleRow label={<FormattedMessage id="resources.spare-part.in-stock" />}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.stock_quantity}
            />
          </Field>
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 30 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_thirty_days}
            />
          </Field>
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 90 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_ninety_days}
            />
          </Field>
          <Field
            view
            singleRow
            label={<FormattedMessage id="resources.spare-part.reserved-within-days" values={{ days: 180 }} />}
          >
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.reserved_hundred_and_eighty_days}
            />
          </Field>
          <Field view singleRow label={<FormattedMessage id="resources.spare-part.ordered-quantity" />}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.sparePart.ordered_quantity}
            />
          </Field>
          <Field view singleRow label={<FormattedMessage id="resources.spare-part.location" />}>
            {this.props.sparePart.spare_part_location_id == null ? (
              '-'
            ) : (
              <SparePartLocationPath sparePartLocationId={this.props.sparePart.spare_part_location_id} />
            )}
          </Field>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item expandable>
          <List.Item.ImageColumn loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item expandable expandedComponent={this.renderExpandedComponent()} new={this.props.new}>
          <List.Item.ImageColumn imageId={this.props.images.length === 0 ? null : this.props.images[0].id} />
          <List.Item.TitleColumn
            title={<Title sparePart={this.props.sparePart} />}
            subtitle={this.props.sparePartType ? this.props.sparePartType.title : ''}
          />
          <List.Item.Column alignRight>
            {this.props.listItemRightComponent(this.props.sparePart)}
          </List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  const sparePart = EntitySelectors.getSparePart(state, ownProps.id);
  return {
    sparePart,
    sparePartType: EntitySelectors.getSparePartType(state, sparePart.spare_part_type_id),
    images: EntitySelectors.getImages(state, sparePart.images),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default connect(mapStateToProps)(SparePartListItem);
