import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import { ContentContainer } from 'views/components/Shared/Layout';
import styles from './style.module.scss';

const UnsavedChangesBottomBar = ({ show, onSave, onClose, loading }) => (
  <div className={`${styles['save-container']} ${show ? styles['show'] : ''}`}>
    <ContentContainer noPadding>
      <div className={styles['content']}>
        <div>
          <Button.Group>
            <Button primary label="general.save" loading={loading} onClick={onSave} />
            <Button label="general.cancel" onClick={onClose} />
          </Button.Group>
        </div>
        <div>
          <span className={styles['unsaved-changes']}>
            <FormattedMessage id="general.unsaved-changes" />
          </span>
        </div>
      </div>
    </ContentContainer>
  </div>
);

export default UnsavedChangesBottomBar;

UnsavedChangesBottomBar.propTypes = {
  amountOfChanges: PropTypes.number,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UnsavedChangesBottomBar.defaultProps = {
  show: false,
  loading: false,
};
