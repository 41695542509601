import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getVendorSubtitle } from 'sdk/Vendor';
import { EntitySelectors } from 'sdk/State/entities';
import { WhiteCard, SectionHeader } from 'views/components/Shared/General';
import { CardGrid } from 'views/components/Shared/Layout';
import styles from './styles.module.scss';

class PrimaryVendor extends Component {
  render() {
    if (this.props.vendor == null) {
      return null;
    }
    return (
      <CardGrid.Row>
        <WhiteCard noPadding>
          <SectionHeader noBorderTop paddingHorizontal={25}>
            <FormattedMessage id="screens.spare-part.info.primary-vendor.title" />
          </SectionHeader>
          <Link className={styles['content']} to={`/spare-parts/${this.props.sparePart.id}/vendors`}>
            <div>{this.props.vendor.name}</div>
            <div className={styles['subtitle']}>{getVendorSubtitle(this.props.vendor)}</div>
          </Link>
        </WhiteCard>
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartId = ownProps.match.params.id;
  const sparePart = EntitySelectors.getSparePart(state, sparePartId);
  return {
    sparePart,
    vendor: EntitySelectors.getVendor(state, sparePart.primary_vendor_id),
  };
}

export default withRouter(connect(mapStateToProps)(PrimaryVendor));
