const DOMAIN_PREFIX = 'organisations/';
const FETCH_USERS = `${DOMAIN_PREFIX}fetch_users`;
const FETCH_USERS_SUCCESS = `${DOMAIN_PREFIX}fetch_users_success`;
const SET_AMOUNT_OF_PAYING_USERS = `${DOMAIN_PREFIX}set_amount_of_paying_users`;
const ADD_QUERY_PARAMETER = `${DOMAIN_PREFIX}add_query_parameter`;
const RESET_STATE = `${DOMAIN_PREFIX}reset_state`;

export default {
  DOMAIN_PREFIX,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  RESET_STATE,
  SET_AMOUNT_OF_PAYING_USERS,
  ADD_QUERY_PARAMETER,
};
