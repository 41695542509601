import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { Field } from 'views/components/Shared/General';
import {
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  getElapsedMinutesFromHourAndMinutes,
} from 'sdk/WorkOrder';
import { Grid } from 'views/components/Shared/Layout';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

export default class ChooseDueDateInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  dateIsBeforeToday = date => {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days') < 0;
  };

  chooseDate = date => {
    if (this.dateIsBeforeToday(date)) {
      this.setState({
        date,
      });
      this.props.onSelectDate(date);
    } else {
      this.selectDate(date);
    }
  };

  selectDate = date => {
    this.setState({
      date,
    });
    this.props.onSelectDate(date);
  };

  renderDate = () => {
    return (
      <ConnectedFieldErrorWrapper field="date" position="top">
        {hasError => (
          <Field.Date
            error={hasError}
            onChangeDate={date => this.chooseDate(date)}
            value={this.props.editingWorkOrder.date}
          />
        )}
      </ConnectedFieldErrorWrapper>
    );
  };

  render() {
    return (
      <>
        <Grid.Row>
          <Grid.Column md={7}>
            <Field label={<FormattedMessage id="resources.work-order.due-date" />}>{this.renderDate()}</Field>
          </Grid.Column>
          <Grid.Column md={5}>
            <Field label={<FormattedMessage id="resources.work-order.estimated-time" />}>
              <div className={styles['elapsed-time-container']}>
                <Field.Number
                  value={getHoursFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)}
                  onBlur={hours => {
                    this.props.onChangeEstimatedMinutes({
                      estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                        hours,
                        getMinutesFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)
                      ),
                    });
                  }}
                  rightLabel={<FormattedMessage id="screens.work-order.costs.time-cost-modal.hours-short" />}
                />
                <Field.Number
                  value={getMinutesFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)}
                  onBlur={minutes => {
                    this.props.onChangeEstimatedMinutes({
                      estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                        getHoursFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes),
                        minutes
                      ),
                    });
                  }}
                  rightLabel={
                    <FormattedMessage id="screens.work-order.costs.time-cost-modal.minutes-short" />
                  }
                />
              </div>
            </Field>
          </Grid.Column>
        </Grid.Row>
      </>
    );
  }
}

ChooseDueDateInlineModal.propTypes = {
  editingWorkOrder: PropTypes.object,
  onCompleteBeforeRegister: PropTypes.func,
  onSelectDate: PropTypes.func,
};

ChooseDueDateInlineModal.defaultProps = {
  editingWorkOrder: null,
  onCompleteBeforeRegister: () => {},
  onSelectDate: () => {},
};
