import { request } from '../utilities/Axios';
import HelperFunctions from '../utilities/HelperFunctions';

export const listQrTemplateColumns = (qr_template_id, params) =>
  request.get(`/qr_templates/${qr_template_id}/qr_template_columns`);

export const fetchQrTemplateColumn = qrTemplateId => request.get(`template_fields/${qrTemplateId}`);

export const createQrTemplateColumn = (qr_template_id, data) =>
  request.post(`/qr_template/${qr_template_id}/qr_template_columns`, data);

export const updateQrTemplateColumn = (qr_template_column_id, data, config = null) =>
  request.patch(`qr_template_columns/${qr_template_column_id}`, data, config);

export const deleteQrTemplateColumn = qr_template_column_id =>
  request.delete(`qr_template_columns/${qr_template_column_id}`);
