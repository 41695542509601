import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { API, SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { normalizeRequestType } from 'sdk/Schemas';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Banner, Field, SectionHeader } from 'views/components/Shared/General';
import { RequestTypeInlineModal } from 'views/components/Request';
import { ChoosePriorityInlineModal } from 'views/components/WorkOrder';
import { Modal } from 'views/components/Shared/Layout';
import ListedRequestTypeTitles from './ListedRequestTypeTitles';
import ListedPriorities from './ListedPriorities';
import styles from './style.module.scss';

class SendRequestToProductionSupervisorModal extends Component {
  getInitialState = () => ({
    isSavingSettings: false,
    isFetching: true,
    request_production_supervisor_approval_ignore_request_types:
      this.props.settings.request_production_supervisor_approval_ignore_request_types || [],
    request_production_supervisor_approval_ignore_request_priorities:
      this.props.settings.request_production_supervisor_approval_ignore_request_priorities || [],
    request_production_supervisor_approval_ignore_downtime:
      this.props.settings.request_production_supervisor_approval_ignore_downtime,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
      this.fetchRequestTypes().then(() => {
        this.setState({ isFetching: false });
      });
    }
  };

  fetchRequestTypes = () => {
    let params = {
      no_pagination: true,
    };

    return API.listRequestTypes(this.props.currentSystem.id, params)
      .then(({ data: requestTypes }) => {
        const { entities } = normalizeRequestType(requestTypes);
        this.props.updateEntities(entities);
      })
      .catch(() => {});
  };

  saveSettings = () => {
    if (this.state.isSavingSettings) return;
    this.setState({ isSavingSettings: true });
    const params = {
      request_production_supervisor_approval_ignore_request_types:
        this.state.request_production_supervisor_approval_ignore_request_types,
      request_production_supervisor_approval_ignore_request_priorities:
        this.state.request_production_supervisor_approval_ignore_request_priorities,
      request_production_supervisor_approval_ignore_downtime:
        this.state.request_production_supervisor_approval_ignore_downtime,
    };

    this.props.updateSettings(this.props.currentSystem.id, params).then(() => {
      this.setState({ isSavingSettings: false });
      this.props.onSave();
    });
  };

  renderRequestTypesDropdownField = () => {
    return (
      <RequestTypeInlineModal
        multiple
        trigger={
          <Field.Resource
            loading={this.state.isFetching}
            angleDown
            value={
              <div>
                <ListedRequestTypeTitles
                  ids={this.state.request_production_supervisor_approval_ignore_request_types}
                />
              </div>
            }
            clearable={this.state.request_production_supervisor_approval_ignore_request_types.length}
            onClear={() => this.setState({ request_production_supervisor_approval_ignore_request_types: [] })}
          />
        }
        selectedRequestTypeIds={this.state.request_production_supervisor_approval_ignore_request_types}
        onAddRequestType={id => {
          this.setState(prevState => ({
            request_production_supervisor_approval_ignore_request_types: [
              ...prevState.request_production_supervisor_approval_ignore_request_types,
              id,
            ],
          }));
        }}
        onRemoveRequestType={id => {
          this.setState(prevState => ({
            request_production_supervisor_approval_ignore_request_types:
              prevState.request_production_supervisor_approval_ignore_request_types.filter(
                item => item !== id
              ),
          }));
        }}
      />
    );
  };

  renderRequestPrioritiesDropdownField = () => {
    return (
      <ChoosePriorityInlineModal
        multiple
        trigger={
          <Field.Resource
            angleDown
            value={
              <ListedPriorities
                priorities={this.state.request_production_supervisor_approval_ignore_request_priorities}
              />
            }
            clearable={this.state.request_production_supervisor_approval_ignore_request_priorities.length}
            onClear={() =>
              this.setState({ request_production_supervisor_approval_ignore_request_priorities: [] })
            }
          />
        }
        selectedPriorities={this.state.request_production_supervisor_approval_ignore_request_priorities}
        onAddPriority={id => {
          this.setState(prevState => ({
            request_production_supervisor_approval_ignore_request_priorities: [
              ...prevState.request_production_supervisor_approval_ignore_request_priorities,
              id,
            ],
          }));
        }}
        onRemovePriority={id => {
          this.setState(prevState => ({
            request_production_supervisor_approval_ignore_request_priorities:
              prevState.request_production_supervisor_approval_ignore_request_priorities.filter(
                item => item !== id
              ),
          }));
        }}
      />
    );
  };

  renderContent = () => {
    return (
      <Modal.Content>
        <Banner orange>
          <FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.banner" />
        </Banner>
        <div className={styles['separator']} />
        <SectionHeader horizontalBorders>
          <FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.section-title" />
        </SectionHeader>
        <div className={styles['section']}>
          <div className={styles['section-child']}>
            <div className={styles['field-label']}>
              <FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.request-type" />
            </div>
            {this.renderRequestTypesDropdownField()}
          </div>
          <div className={styles['section-child']}>
            {' '}
            <div className={styles['field-label']}>
              <FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.priority" />
            </div>
            {this.renderRequestPrioritiesDropdownField()}
          </div>
          <div className={styles['section-child']}>
            <Field.Checkbox
              label={
                <FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.has-downtime" />
              }
              checked={this.state.request_production_supervisor_approval_ignore_downtime}
              onChange={() =>
                this.setState(prevState => ({
                  request_production_supervisor_approval_ignore_downtime:
                    !prevState.request_production_supervisor_approval_ignore_downtime,
                }))
              }
            />
          </div>
        </div>
      </Modal.Content>
    );
  };

  render() {
    return (
      <Modal width={588} isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="screens.settings.production-supervisor-request-filter-modal.title" />}
          onClose={this.props.onClose}
          ignoreLine
        />

        {this.renderContent()}
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              onClick={() => this.saveSettings()}
              loading={this.state.isSavingSettings}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendRequestToProductionSupervisorModal);
