import moment from 'moment';
import Decimal from 'decimal.js';
import uuid from 'uuid';

export const NotEditableKeys = [
  'checklist_template_links',
  'checklist_instances',
  'registration_attachments',
  'completion_attachments',
  'costs',
  'images',
  'registration_images',
  'completion_images',
  'requests',
  'spare_part_reservations',
  'spare_part_withdrawals',
  'work_order_purchases',
  'work_order_spent_times',
];

export const WorkOrderCategory = {
  Preventive: 'preventive',
  PreventiveConditionBased: 'preventive_condition_based',
  PreventivePredetermined: 'preventive_predetermined',
  Corrective: 'corrective',
  CorrectiveDefered: 'corrective_defered',
  CorrectiveImmediate: 'corrective_immediate',
  Improvement: 'improvement',
  Modification: 'modification',
};

export const WorkOrderStatus = {
  NotStarted: 'not_started',
  InProgress: 'in_progress',
  Paused: 'paused',
  Completed: 'completed',
};

export const ReminderType = {
  None: 'none',
  RelativeToDueDate: 'relative_to_due_date',
  RelativeToMeterReading: 'relative_to_meter_reading',
  Exact: 'exact',
};

export const ReminderRecipientType = {
  Assignees: 'assignees',
  User: 'user',
};

export const REPORT_TYPES = {
  Count: 'count',
};
export const REPORT_PER_TYPES = {
  Year: 'year',
  Month: 'month',
  Asset: 'asset',
  Assignee: 'assignee',
  Priority: 'priority',
  WorkOrderType: 'work_order_type',
  CompletedBy: 'completed_by_user',
  WorkOrderCategorySimple: 'category_simple',
  WorkOrderCategoryDetailed: 'category_detailed',
};

export const REPORT_SPLIT_PER_TYPES = {
  Priority: 'priority',
  WorkOrderCategorySimple: 'category_simple',
  WorkOrderCategoryDetailed: 'category_detailed',
};

export function getTranslationForStatus(status) {
  switch (status) {
    case 'not_started':
      return 'resources.work-order.statuses.not-started';
    case 'in_progress':
      return 'resources.work-order.statuses.in-progress';
    case 'paused':
      return 'resources.work-order.statuses.paused';
    case 'completed':
      return 'resources.work-order.statuses.completed';
    default:
      return null;
  }
}

export function generateEmptyWorkOrder() {
  return {
    id: uuid.v4(),
    status: WorkOrderStatus.NotStarted,
    estimated_minutes: null,
    title: '',
    description: '',
    asset_id: null,
    date: null,
    assigned_to_users: [],
    assigned_to_groups: [],
    assigned_to_vendors: [],
    work_order_type_id: null,
    reminder_type: ReminderType.None,
    reminder_recipient_type: ReminderRecipientType.Assignees,
    reminder_days_relative_to_due_date: null,
    reminder_relative_to_meter: null,
    reminder_date_exact: null,
    reminder_comment: null,
    priority: null,
    frequency: null,
    interval: null,
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
    sun: null,
    month_type: null,
    generate_from_completed_date: false,
    registration_images: [],
    completion_images: [],
    registration_attachments: [],
    completion_attachments: [],
    created_from_requests: [],
    checklist_template_links: [],
    work_order_spent_times: [],
  };
}

export function getColorForStatus(status) {
  switch (status) {
    case WorkOrderStatus.NotStarted:
      return '#4D9BC7';
    case WorkOrderStatus.InProgress:
      return '#EF9028';
    case WorkOrderStatus.Paused:
      return '#616161';
    case WorkOrderStatus.Completed:
      return '#34AD35';
    default:
      return null;
  }
}

export function getUserStatusText(workOrder) {
  if (workOrder.in_progress_by_user_id != null)
    return 'screens.work-orders.list.user-statuses.in-progress-by';
  if (workOrder.paused_by_user_id != null) return 'screens.work-orders.list.user-statuses.paused-by';
  if (workOrder.completed_by_user_id != null) return 'screens.work-orders.list.user-statuses.completed-by';
  return null;
}

export function isOverdue(workOrder) {
  return (
    moment(workOrder.due_date).isBefore(moment().format('YYYY-MM-DD')) &&
    workOrder.status !== WorkOrderStatus.Completed
  );
}

export function getEstimatedDueDateFromMeterAsMoment(params = {}) {
  const { meter_value, due_meter_value, average_daily_usage, value_last_updated } = params;
  if (value_last_updated == null || average_daily_usage == null || due_meter_value == null) {
    return null;
  }
  let decimalMeterValue = null;
  let decimalDueMeterValue = null;
  let decimalAverageDailyMeterUsage = null;
  try {
    decimalMeterValue = new Decimal(meter_value);
  } catch (e) {
    decimalMeterValue = new Decimal(0);
  }
  try {
    decimalDueMeterValue = new Decimal(due_meter_value);
  } catch (e) {
    decimalDueMeterValue = new Decimal(0);
  }
  try {
    decimalAverageDailyMeterUsage = new Decimal(average_daily_usage);
  } catch (e) {
    decimalAverageDailyMeterUsage = new Decimal(0);
  }
  const daysInFuture = decimalDueMeterValue
    .minus(decimalMeterValue)
    .dividedBy(decimalAverageDailyMeterUsage)
    .toString();
  return moment(value_last_updated).add(daysInFuture, 'days');
}

export function getMinutesFromElapsedMinutes(elapsedMinutes) {
  return elapsedMinutes == null ? null : Number(elapsedMinutes % 60);
}

export function getHoursFromElapsedMinutes(elapsedMinutes) {
  return elapsedMinutes == null ? null : Number(Math.floor(elapsedMinutes / 60));
}

export function getElapsedMinutesFromHourAndMinutes(hours = 0, minutes = 0) {
  let elapsedMinutes = 0;
  elapsedMinutes = Number(elapsedMinutes + minutes);
  elapsedMinutes = Number(elapsedMinutes + hours * 60);
  return elapsedMinutes === 0 ? null : elapsedMinutes;
}

export function buildWorkOrderFromEditingWorkOrder(editingWorkOrder) {
  const due_date = editingWorkOrder.date;
  const { date, ...workOrderWithoutDate } = editingWorkOrder;
  return {
    ...workOrderWithoutDate,
    due_date,
  };
}

export function isConsideredActiveByCurrentUser(workOrder, currentUserId) {
  return workOrder.in_progress_by_user_id === currentUserId || workOrder.paused_by_user_id === currentUserId;
}
