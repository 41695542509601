import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';
import { ContentContainer } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingOperations, StocktakingSelectors } from 'state/ducks/stocktaking';
import { HelperFunctions } from 'sdk';
import SparePartList from './SparePartList';
import SparePartLocations from './SparePartLocations';
import Header from '../../Header';
import CompletedWhiteCard from '../../Components/CompletedWhiteCard';

const listSparePartsForStorageLocationRequest = HelperFunctions.getCancelTokenForRequest();
class Locations extends Component {
  componentDidMount() {
    this.fetchSpareParts();
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (oldQueryParams.parentId !== queryParams.parentId) {
      this.fetchSpareParts();
    }

    if (!prevProps.stocktaking.completed && this.props.stocktaking.completed) {
      this.fetchSpareParts();
    }
  }

  fetchMoreSpareParts = () => {
    if (this.props.isFullyLoaded || this.props.loadingMore) return;

    this.fetchSpareParts({ paginate_from: this.props.paginateFrom });
  };

  fetchSpareParts = (params = {}) => {
    listSparePartsForStorageLocationRequest.cancel();
    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams.parentId != null) {
      const stocktakingId = this.props.match.params.id;
      let filterParams = {
        ...params,
        spare_part_location_id: queryParams.parentId,
      };

      if (this.props.stocktaking.completed) {
        filterParams = { ...filterParams, has_stocktaking_row: true };
      }

      return this.props
        .fetchSparePartsForStocktaking(
          stocktakingId,
          filterParams,
          listSparePartsForStorageLocationRequest.getCancelTokenConfig()
        )
        .then(({ data: spareParts }) => {
          return spareParts;
        });
    } else {
      return new Promise((resolve, reject) => {
        this.props.clearSpareParts();
        resolve();
      });
    }
  };

  getProgressPercentage = () => {
    if (!this.props.count) return null;
    const { total_count, completed_count } = this.props.count;
    if (total_count === 0) return 1;
    else return completed_count / total_count;
  };

  renderView = () => {
    return (
      <React.Fragment>
        {this.props.stocktaking.completed ? <CompletedWhiteCard /> : null}
        <SparePartLocations />
        <SparePartList progressValue={this.getProgressPercentage()} />
      </React.Fragment>
    );
  };

  render() {
    return (
      <PerfectScrollbar>
        <InfiniteScroll
          loadMore={this.fetchMoreSpareParts}
          hasMore={!this.props.isFullyLoaded}
          useWindow={false}
          initialLoad={false}
          threshold={350}
        >
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </InfiniteScroll>
      </PerfectScrollbar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSparePartsForStocktaking: StocktakingOperations.fetchSparePartsForStocktaking,
      clearSpareParts: StocktakingOperations.clearSpareParts,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const parentId = queryString.parse(ownProps.location.search).parentId;

  return {
    isFullyLoaded: StocktakingSelectors.getSparePartsIsFullyLoaded(state),
    loadingMore: StocktakingSelectors.getSparePartsLoadingMore(state),
    paginateFrom: StocktakingSelectors.getSparePartsPaginateFrom(state),
    count: StocktakingSelectors.getCountForLocation(state, parentId),
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));
