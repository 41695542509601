import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Toggle, WhiteCard, Banner } from 'views/components/Shared/General';
import { Section, Option, Separator } from 'views/scenes/Settings/components';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import EditRequiredFieldsModal from './EditRequiredFieldsModal';
import styles from './style.module.scss';

class OperationalMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavingChanges: false,
      showActivateOperatorModal: false,
      showDeactivateOperatorModal: false,
      showEditRequiredFieldsModal: false,
    };
  }

  updateSettings = params => {
    if (this.state.isSavingChanges) return;
    this.setState({ isSavingChanges: true });
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          showActivateOperatorModal: false,
          showDeactivateOperatorModal: false,
          isSavingChanges: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          isSavingChanges: false,
          showActivateOperatorModal: false,
          showDeactivateOperatorModal: false,
        });
      });
  };

  renderActivateOperatorConfirmModal = () => (
    <Modal isOpen={this.state.showActivateOperatorModal} width={440}>
      <Modal.Header
        title={
          <FormattedMessage id="screens.settings.operational-maintenance.activate-operator-confirm.title" />
        }
        subtitle={<FormattedMessage id="screens.settings.operational-maintenance.subtitle" />}
        subtitleTopMargin
        onClose={() => {
          this.setState({ showActivateOperatorModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            loading={this.state.isSavingChanges}
            onClick={() => {
              this.updateSettings({
                operational_maintenance_activated: true,
              });
            }}
            label="screens.settings.operational-maintenance.activate-operator-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => this.setState({ showActivateOperatorModal: false })}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderDeactivateOperatorConfirmModal = () => (
    <Modal isOpen={this.state.showDeactivateOperatorModal} width={440}>
      <Modal.Header
        title={
          <FormattedMessage id="screens.settings.operational-maintenance.deactivate-operator-confirm.title" />
        }
        subtitle={
          <FormattedMessage id="screens.settings.operational-maintenance.deactivate-operator-confirm.message" />
        }
        subtitleTopMargin
        onClose={() => {
          this.setState({ showDeactivateOperatorModal: false });
        }}
      />
      <Modal.Footer>
        <Button.Group>
          <Button
            primary
            destructive
            loading={this.state.isSavingChanges}
            onClick={() => {
              this.updateSettings({
                operational_maintenance_activated: false,
              });
            }}
            label="screens.settings.operational-maintenance.deactivate-operator-confirm.button"
          />
          <Button
            label="general.cancel"
            onClick={() => this.setState({ showDeactivateOperatorModal: false })}
          />
        </Button.Group>
      </Modal.Footer>
    </Modal>
  );

  renderBanner = () => {
    if (this.props.settings.production_board_activated) return null;
    return (
      <div className={styles['banner-container']}>
        <Banner
          orange
          button
          buttonLabel={<FormattedMessage id="screens.settings.operational-maintenance.banner.button" />}
          onClick={() => this.props.history.push('/settings/production-boards')}
        >
          <FormattedMessage id="screens.settings.operational-maintenance.banner.message" />
        </Banner>
      </div>
    );
  };

  renderEditRequiredFieldsModal = () => {
    return (
      <EditRequiredFieldsModal
        open={this.state.showEditRequiredFieldsModal}
        onClose={() => {
          this.setState({ showEditRequiredFieldsModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        {this.renderBanner()}
        <WhiteCard>
          <Section
            header
            title={<FormattedMessage id="screens.settings.operational-maintenance.title" />}
            subtitle={<FormattedMessage id="screens.settings.operational-maintenance.subtitle" />}
            rightComponent={
              <Toggle
                disabled={!this.props.settings.production_board_activated}
                checked={
                  this.props.settings.operational_maintenance_activated &&
                  this.props.settings.production_board_activated
                }
                onClick={() => {
                  if (
                    this.props.settings.operational_maintenance_activated &&
                    this.props.settings.production_board_activated
                  ) {
                    this.setState({ showDeactivateOperatorModal: true });
                  } else {
                    this.setState({ showActivateOperatorModal: true });
                  }
                }}
              />
            }
          />
          <AnimateHeight
            duration={250}
            height={this.props.settings.operational_maintenance_activated ? 'auto' : 0}
          >
            <>
              <Separator />
              <Option.Container>
                <Option
                  title={
                    <FormattedMessage id="screens.settings.operational-maintenance.required-fields.title" />
                  }
                  subtitle={
                    <FormattedMessage id="screens.settings.operational-maintenance.required-fields.subtitle" />
                  }
                  rightContainer={
                    <Button
                      primary
                      disabled={this.props.settings.production_board_activated === false}
                      label="general.manage"
                      onClick={() => this.setState({ showEditRequiredFieldsModal: true })}
                    />
                  }
                />
              </Option.Container>
            </>
          </AnimateHeight>
        </WhiteCard>
        {this.renderActivateOperatorConfirmModal()}
        {this.renderDeactivateOperatorConfirmModal()}
        {this.renderEditRequiredFieldsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
    settings: AuthSelectors.getSettings(state),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenance)));
