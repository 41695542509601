import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { List, Field, Tooltip } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TreePath } from 'views/components/Asset';
import { EntitySelectors } from 'sdk/State/entities';
import { ItemType } from 'sdk/RequestAutomationRule';
import styles from './style.module.scss';

function AutomationRuleItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function hasNoRules() {
    return (
      props.requestAutomationRule.assets.length === 0 &&
      props.requestAutomationRule.asset_types.length === 0 &&
      props.requestAutomationRule.request_types.length === 0
    );
  }

  function renderTitle() {
    if (hasNoRules()) {
      return (
        <span className={styles['no-rule']}>
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.no-rule" />
        </span>
      );
    }
    if (props.requestAutomationRule.item_type === ItemType.Asset) {
      return <FormattedMessage id="screens.settings.requests.assignees.automation.rules.item-types.asset" />;
    } else if (props.requestAutomationRule.item_type === ItemType.AssetType) {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.automation.rules.item-types.asset-type" />
      );
    } else if (props.requestAutomationRule.item_type === ItemType.RequestType) {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.automation.rules.item-types.request-type" />
      );
    }
    return null;
  }

  function renderDataWithCommas(data, renderItem) {
    return (
      <div className={styles['subtitle']}>
        <div className={styles['label']}>
          <FormattedMessage id="screens.settings.requests.assignees.automation.rules.rule-subtitle" />
        </div>
        <div className={styles['rule-items']}>
          {data.reduce(
            (accu, item) =>
              accu === null
                ? [<div className={styles['rule-item']}>{renderItem(item)}</div>]
                : [
                    ...accu,
                    <div className={styles['comma']}>,</div>,
                    <div className={styles['rule-item']}>{renderItem(item)}</div>,
                  ],
            null
          )}
        </div>
      </div>
    );
  }

  function renderSubtitleData() {
    if (hasNoRules()) {
      return <FormattedMessage id="screens.settings.requests.assignees.automation.rules.no-rule-subtitle" />;
    }
    if (props.requestAutomationRule.item_type === ItemType.Asset) {
      return renderDataWithCommas(props.assets, asset => (
        <Tooltip trigger={<div>{asset.title}</div>} label={<TreePath assetId={asset.id} />} />
      ));
    } else if (props.requestAutomationRule.item_type === ItemType.AssetType) {
      return renderDataWithCommas(props.assetTypes, assetType => assetType.title);
    } else if (props.requestAutomationRule.item_type === ItemType.RequestType) {
      return renderDataWithCommas(props.requestTypes, requestType => requestType.title);
    }
  }

  function renderAssignee() {
    if (props.assignedToUser) {
      return (
        <Field
          fontSize={12}
          view
          singleRow
          label={<FormattedMessage id="screens.settings.requests.assignees.automation.rules.assignee" />}
        >
          <UserNameWrapper user={props.assignedToUser} />
        </Field>
      );
    }
    if (props.assignedToGroup) {
      return (
        <Field
          fontSize={12}
          view
          singleRow
          label={<FormattedMessage id="screens.settings.requests.assignees.automation.rules.assignee" />}
        >
          {props.assignedToGroup.title}
        </Field>
      );
    }
    return (
      <span className={styles['no-assignee']}>
        <FormattedMessage id="screens.settings.requests.assignees.automation.rules.no-assignee" />
      </span>
    );
  }

  const renderDragHandleColumn = () => (
    <List.Item.Column width={12}>
      <div className={styles['drag-handle']}>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
        <div className={styles['row']}>
          <div className={styles['dot']} />
          <div className={styles['dot']} />
        </div>
      </div>
    </List.Item.Column>
  );

  const renderSortIcon = () => {
    return (
      <div className={`${styles['icon-container']} ${hasNoRules() ? styles['empty'] : ''}`}>
        {props.index + 1}
      </div>
    );
  };
  if (props.loading) {
    return (
      <List.Item>
        {renderDragHandleColumn()}
        <List.Item.ImageColumn loading />
        <List.Item.TitleColumn loading />
      </List.Item>
    );
  }
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <List.Item clickable onClick={() => props.onSelect()} paddingLeft={18} isDragging={false}>
        <div>{renderDragHandleColumn()}</div>
        <List.Item.Column width={20}>{renderSortIcon()}</List.Item.Column>
        <List.Item.TitleColumn
          title={<span className={styles['title']}>{renderTitle()}</span>}
          subtitle={renderSubtitleData()}
        />
        <List.Item.Column alignRight>{renderAssignee()}</List.Item.Column>
      </List.Item>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const requestAutomationRule = EntitySelectors.getRequestAutomationRule(state, ownProps.id);
  return {
    requestAutomationRule,
    assignedToUser: EntitySelectors.getUser(state, requestAutomationRule.assign_user_id),
    assignedToGroup: EntitySelectors.getGroup(state, requestAutomationRule.assign_group_id),
    assets: EntitySelectors.getAssets(state, requestAutomationRule.assets),
    assetTypes: EntitySelectors.getAssetTypes(state, requestAutomationRule.asset_types),
    requestTypes: EntitySelectors.getRequestTypes(state, requestAutomationRule.request_types),
  };
}

export default connect(mapStateToProps)(AutomationRuleItem);
