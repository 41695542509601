import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { PurchaseOrderSelectors, PurchaseOrderOperations } from 'state/ducks/purchaseOrder';
import { FormattedMessage } from 'react-intl';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import { Button, List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { CreateDeliveryModal, EditDeliveryModal } from 'views/components/PurchaseOrder';
import { EntitySelectors } from 'sdk/State/entities';
import PurchaseOrderDeliverySmall from 'assets/images/EmptyDataSet/PurchaseOrderDeliverySmall.png';
import PurchaseOrderDeliveryListItem from './PurchaseOrderDeliveryListItem';

class Deliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showCreateDeliveryModal: false,
      showEditDeliveryModal: false,
      showEditDeliveryModalForPurchaseOrderDeliveryId: null,
    };
  }

  componentDidMount() {
    this.props
      .fetchPurchaseOrderDeliveries(this.props.match.params.id, { no_pagination: true })
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {
        this.setState({ isFetching: false });
      });
  }

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.purchase-order.deliveries.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.purchase-order.deliveries.empty-data-set.subtitle" />}
        image={PurchaseOrderDeliverySmall}
        button={
          this.props.purchaseOrder.status === PurchaseOrderStatus.Draft ? null : (
            <Button
              small
              primary
              label="screens.purchase-order.deliveries.empty-data-set.button"
              onClick={() => this.setState({ showCreateDeliveryModal: true })}
            />
          )
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderCreateDeliveryModal = () => (
    <CreateDeliveryModal
      open={this.state.showCreateDeliveryModal}
      purchaseOrder={this.props.purchaseOrder}
      onSave={() => {
        this.setState({
          showCreateDeliveryModal: false,
        });
      }}
      onClose={() => {
        this.setState({ showCreateDeliveryModal: false });
      }}
    />
  );

  renderEditDeliveryModal = () => (
    <EditDeliveryModal
      open={this.state.showEditDeliveryModal}
      purchaseOrderDeliveryId={this.state.showEditDeliveryModalForPurchaseOrderDeliveryId}
      onDelete={() => {
        this.setState({
          showEditDeliveryModal: false,
          showEditDeliveryModalForPurchaseOrderDeliveryId: null,
        });
      }}
      onClose={() => {
        this.setState({
          showEditDeliveryModal: false,
          showEditDeliveryModalForPurchaseOrderDeliveryId: null,
        });
      }}
    />
  );

  renderContent() {
    if (this.state.isFetching) {
      return (
        <>
          <List.Header expandable background>
            <List.Header.Column flex>
              <FormattedMessage id="resources.purchase-order-deliveries.resource" />
            </List.Header.Column>
          </List.Header>
          <List>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
            <List.Item expandable>
              <List.Item.TitleColumn loading />
            </List.Item>
          </List>
        </>
      );
    }
    if (this.props.purchaseOrderDeliveries.length === 0) {
      return this.renderEmptyDataset();
    }
    return (
      <>
        <List.Header expandable background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-deliveries.resource" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.purchaseOrderDeliveries.map(purchaseOrderDelivery => (
            <PurchaseOrderDeliveryListItem
              key={purchaseOrderDelivery.id}
              purchaseOrderDelivery={purchaseOrderDelivery}
              onEdit={() => {
                this.setState({
                  showEditDeliveryModal: true,
                  showEditDeliveryModalForPurchaseOrderDeliveryId: purchaseOrderDelivery.id,
                });
              }}
            />
          ))}
        </List>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderCreateDeliveryModal()}
        {this.renderEditDeliveryModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPurchaseOrderDeliveries: PurchaseOrderOperations.fetchPurchaseOrderDeliveries,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrder: EntitySelectors.getPurchaseOrder(state, ownProps.match.params.id),
    purchaseOrderDeliveries: PurchaseOrderSelectors.getPurchaseOrderDeliveries(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deliveries));
