import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { PurchaseOrderSelectors, PurchaseOrderOperations } from 'state/ducks/purchaseOrder';
import Header from '../../../Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { ContentContainer, Toolbar } from 'views/components/Shared/Layout';
import { Button, ConfirmModal, List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import { SelectWorkOrderModal } from 'views/components/WorkOrder';
import { Loader } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import WorkOrderPurchaseListItem from './WorkOrderPurchaseListItem';
import AddConfirmModal from './AddConfirmModal';
import WorkOrderTiny from 'assets/images/EmptyDataSet/WorkOrderTiny.png';

class WorkOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isDeleting: false,
      isViewLoading: true,
      confirmDeleteForId: null,
      showSelectWorkOrderModal: false,
      newIds: [],
    };
  }

  componentDidMount() {
    this.props
      .fetchWorkOrderPurchases(this.props.match.params.id, { no_pagination: true })
      .then(() => {
        this.setState({ isViewLoading: false });
      })
      .catch(() => {
        this.setState({ isViewLoading: false });
      });
  }

  createWorkOrderPurchase = workOrderId => {
    this.setState({ isCreating: true });

    const params = {
      purchase_order_id: this.props.purchaseOrder.id,
    };

    this.props
      .createWorkOrderPurchase(workOrderId, params)
      .then(({ data }) => {
        this.setState({
          newIds: [...this.state.newIds, data.id],
          isCreating: false,
          showAddConfirm: false,
        });
      })
      .catch(error => {
        if (HelperFunctions.hasError(error, { code: '10002', key: 'purchase_order_id' })) {
          toast(
            <ToastMessage
              error
              text={<FormattedMessage id="screens.purchase-order.work-orders.already-added" />}
            />
          );
          this.setState({ isCreating: false, showAddConfirm: false });
        }
      });
  };

  deleteWorkOrderPurchase = id => {
    this.setState({ isDeleting: true });

    this.props.deleteWorkOrderPurchase(id).then(() => {
      this.setState({
        showDeleteConfirm: false,
        isDeleting: false,
      });
    });
  };

  renderSelectWorkOrderModal = () => (
    <SelectWorkOrderModal
      open={this.state.showSelectWorkOrderModal}
      onSelect={id => {
        this.setState({ showAddConfirm: true, confirmForWorkOrderId: id, showSelectWorkOrderModal: false });
      }}
      onClose={() => {
        this.setState({ showSelectWorkOrderModal: false });
      }}
    />
  );

  renderAddConfirmModal = () => (
    <AddConfirmModal
      workOrderId={this.state.confirmForWorkOrderId}
      open={this.state.showAddConfirm}
      isSaving={this.state.isCreating}
      onSave={() => {
        this.createWorkOrderPurchase(this.state.confirmForWorkOrderId);
      }}
      onClose={() => {
        this.setState({ showAddConfirm: null });
      }}
    />
  );

  renderDeleteConfirmModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeleteConfirm}
      title={<FormattedMessage id="screens.purchase-order.work-orders.delete-confirm.title" />}
      message={<FormattedMessage id="screens.purchase-order.work-orders.delete-confirm.description" />}
      confirmButtonText="general.remove"
      confirmIsLoading={this.state.isDeleting}
      onConfirm={() => {
        this.deleteWorkOrderPurchase(this.state.confirmDeleteForId);
      }}
      onCancel={() => {
        this.setState({ showDeleteConfirm: null });
      }}
    />
  );

  renderEmptyDataset = () => (
    <WhiteCard centerContent>
      <EmptyDataSet
        title={<FormattedMessage id="screens.purchase-order.work-orders.empty-data-set.title" />}
        subtitle={<FormattedMessage id="screens.purchase-order.work-orders.empty-data-set.subtitle" />}
        image={WorkOrderTiny}
        button={
          this.props.canEditPurchaseOrders ? (
            <Button
              small
              primary
              label="screens.purchase-order.work-orders.create-button"
              onClick={() => this.setState({ showSelectWorkOrderModal: true })}
            />
          ) : null
        }
        tiny
        horizontal
        listContainer
      />
    </WhiteCard>
  );

  renderList = () => {
    return (
      <React.Fragment>
        <Toolbar
          buttons={
            this.props.canEditPurchaseOrders ? (
              <Button
                primary
                label="screens.purchase-order.work-orders.create-button"
                onClick={() => this.setState({ showSelectWorkOrderModal: true })}
              />
            ) : null
          }
        />
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.work-order.title" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.workOrderPurchases.map(workOrderPurchase => (
            <WorkOrderPurchaseListItem
              new={this.state.newIds.includes(workOrderPurchase.id)}
              key={workOrderPurchase.id}
              id={workOrderPurchase.id}
              onDelete={() => {
                this.setState({ showDeleteConfirm: true, confirmDeleteForId: workOrderPurchase.id });
              }}
            />
          ))}
        </List>
      </React.Fragment>
    );
  };

  renderView = () => {
    if (this.state.isViewLoading) {
      return <Loader />;
    } else if (this.props.workOrderPurchases.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderList();
    }
  };

  render() {
    return (
      <React.Fragment>
        <PerfectScrollbar>
          <Header />
          <ContentContainer key={this.props.match.params.id}>{this.renderView()}</ContentContainer>
        </PerfectScrollbar>
        {this.renderSelectWorkOrderModal()}
        {this.renderAddConfirmModal()}
        {this.renderDeleteConfirmModal()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchWorkOrderPurchases: PurchaseOrderOperations.fetchWorkOrderPurchases,
      createWorkOrderPurchase: SDKReduxOperations.createWorkOrderPurchase,
      deleteWorkOrderPurchase: SDKReduxOperations.deleteWorkOrderPurchase,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    purchaseOrder: EntitySelectors.getPurchaseOrder(state, ownProps.match.params.id),
    workOrderPurchases: PurchaseOrderSelectors.getWorkOrderPurchases(state),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WorkOrders));
