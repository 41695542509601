import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { List, Icon, Loader } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';

class UserListItem extends Component {
  isCurrentUser = () => {
    if (this.props.currentUser == null) {
      return false;
    }
    return this.props.currentUser.id === this.props.id;
  };

  renderIcon = () => {
    return (
      <ProfilePicture
        fontSize={13}
        size={28}
        userId={this.props.user.id}
        defaultElement={<Icon type="user" withBackground backgroundSize={28} />}
      />
    );
  };

  renderRightComponent = () => {
    if (this.props.isSigningInUserId === this.props.id) {
      return (
        <List.Item.Column alignRight>
          <Loader tiny />
        </List.Item.Column>
      );
    }
    if (this.isCurrentUser()) {
      return (
        <List.Item.Column alignRight>
          <Icon green regular type="check" />
        </List.Item.Column>
      );
    }
    return null;
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.Column width={30} loading>
            <Icon type="user" withBackground backgroundSize={28} />
          </List.Item.Column>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    }
    return (
      <List.Item
        clickable={this.isCurrentUser() === false}
        onClick={() => {
          this.props.onSignInGroupUser(this.props.user);
        }}
      >
        <List.Item.Column width={30}>{this.renderIcon()}</List.Item.Column>
        <List.Item.TitleColumn title={<UserNameWrapper user={this.props.user} />} />
        {this.renderRightComponent()}
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: EntitySelectors.getUser(state, ownProps.id),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, null)(UserListItem);
