export default {
  SET_NAVIGATED_TO: 'purchaseOrders/set_navigated_to',
  RESET_SELECTED_PURCHASE_ORDERS: 'purchaseOrders/reset_selected_purchase_orders',
  TOGGLE_ALL_SELECTED_PURCHASE_ORDERS: 'purchaseOrders/toggle_all_selected_purchase_orders',
  SELECT_PAGE: 'purchaseOrders/select_page',
  SELECT_PURCHASE_ORDER: 'purchaseOrders/select_purchase_order',
  SELECT_TOTAL_ENTRIES: 'purchaseOrders/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'purchaseOrders/hide_select_total_entries',
  ADD_QUERY_PARAMETER: 'purchaseOrders/add_query_parameter',
  SHOW_APPLIED_FILTERS: 'assets/show_applied_filters',
  SHOW_AVAILABLE_FILTERS: 'purchaseOrders/show_available_filters',
  SELECT_FILTER_TYPE: 'purchaseOrders/select_filter_type',
  ADD_FILTER: 'purchaseOrders/add_filter',
  REMOVE_FILTER: 'purchaseOrders/remove_filter',
  RESET_FILTER: 'purchaseOrders/reset_filter',
  SET_FILTERS: 'purchase_orders/set_filters',
  SET_NAVIGATED_TO_ID: 'purchase_orders/set_navigated_to_id',
  FETCH_PURCHASE_ORDERS_SUCCESS: 'purchase_orders/fetch_purchase_orders_success',
  SHOW_LOADER: 'purchase_orders/show_loader',
};
