import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './style.module.scss';

class ListedPriorities extends Component {
  renderPriority = priority => {
    switch (priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  };
  render() {
    if (this.props.priorities.length === 0) {
      return null;
    }
    return (
      <div className={styles['selected-items']}>
        {this.props.priorities.reduce(
          (accu, priority) =>
            accu === null
              ? [<div className={styles['selected-item']}>{this.renderPriority(priority)}</div>]
              : [
                  ...accu,
                  <div className={styles['comma']}>,</div>,
                  <div className={styles['selected-item']}>{this.renderPriority(priority)}</div>,
                ],
          null
        )}
      </div>
    );
  }
}

export default ListedPriorities;
