import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class ActivateStockModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      stockQuantity: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        stockQuantity: '',
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.value) {
      this.updateSparePart();
    }
  };

  updateSparePart = () => {
    this.setState({ isSaving: true });

    this.props
      .updateSparePart(this.props.sparePart.id, { stock_quantity: this.state.stockQuantity })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.adjust-stock-modal.update-value-success" />}
          />
        );
        this.setState({ isSaving: false });
        this.props.onClose();
      });
  };

  renderValueWithAbbreviation = value => {
    if (this.props.sparePartUnit == null) return value;
    return `${value} ${this.props.sparePartUnit.abbreviation} `;
  };

  renderContent = () => (
    <Field view={false} label={<FormattedMessage id="components.activate-stock-modal.current-value" />}>
      <Field.Decimal
        rightLabel={this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation}
        autoFocus
        value={this.state.stockQuantity}
        onChange={stockQuantity => {
          this.setState({ stockQuantity });
        }}
        onBlur={stockQuantity => {
          this.setState({ stockQuantity });
        }}
      />
    </Field>
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={475}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.activate-stock-modal.title" />}
          subtitle={<FormattedMessage id="components.activate-stock-modal.subtitle" />}
          subtitleTopMargin
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderContent()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              disabled={this.state.stockQuantity.length === 0}
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.updateSparePart}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartUnit: EntitySelectors.getSparePartUnit(state, ownProps.sparePart.spare_part_unit_id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
    },
    dispatch
  );
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ActivateStockModal));
