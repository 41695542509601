import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NotificationsOperations, NotificationsSelectors } from 'state/ducks/notifications';
import { AuthSelectors } from 'state/ducks/auth';
import InfiniteScroll from 'react-infinite-scroller';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { MenuModal } from 'views/components/Shared/Layout';
import { Loader, SectionHeader, List } from 'views/components/Shared/General';
import NotificationListItem from './NotificationListItem';
import styles from './style.module.scss';

class NotificationList extends Component {
  state = {
    isOpen: false,
    isFetching: false,
    isFetchingMore: false,
  };

  componentDidMount() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      return;
    }
    this.setState({ isOpen: true, isFetching: true });
    this.listNotifications().then(() => {
      this.setState({ isFetching: false });
    });
  }

  fetchMoreNotifications = () => {
    if (this.props.isFullyLoaded || this.state.isFetchingMore) return;

    this.setState({ isFetchingMore: true });
    const params = { paginate_from: this.props.paginateFrom };
    this.listNotifications(params)
      .then(() => {
        this.setState({ isFetchingMore: false });
      })
      .catch(() => {});
  };

  listNotifications = params => {
    return this.props
      .listNotifications(this.props.currentSystem.id, params)
      .then(({ data: notifications }) => {
        return notifications;
      });
  };

  isInitialFetching = () =>
    this.state.isFetching &&
    this.props.newNotifications.length === 0 &&
    this.props.earlierNotifications.length === 0;

  isEmpty = () =>
    !this.state.isFetching &&
    this.props.newNotifications.length === 0 &&
    this.props.earlierNotifications.length === 0;

  renderEmptyDataSet = () => (
    <div className={styles['container']}>
      <FormattedMessage id="screens.notifications.empty-data-set.title" />
    </div>
  );

  renderLoader = () => (
    <div className={styles['center-container']}>
      <Loader small />
    </div>
  );

  renderMoreLoader = () => {
    if (!this.state.isFetchingMore) return null;

    return (
      <div className={styles['more-loader-container']}>
        <Loader small />
      </div>
    );
  };

  renderNewNotifications = () => (
    <>
      <SectionHeader noBorderBottom paddingHorizontal={35}>
        <FormattedMessage id="screens.notifications.new" />
      </SectionHeader>
      <List light small>
        {this.props.newNotifications.map((notification, key) => (
          <NotificationListItem key={key} notification={notification} />
        ))}
      </List>
    </>
  );

  renderEarlierNotifications = () => {
    if (this.props.earlierNotifications.length === 0) return null;

    return (
      <>
        <SectionHeader
          noBorderBottom
          paddingHorizontal={35}
          noBorderTop={this.props.newNotifications.length !== 0}
        >
          <FormattedMessage id="screens.notifications.earlier" />
        </SectionHeader>
        <List light small>
          {this.props.earlierNotifications.map((notification, key) => (
            <NotificationListItem key={key} notification={notification} />
          ))}
        </List>
      </>
    );
  };

  renderContent = () => {
    if (this.isInitialFetching()) return this.renderLoader();
    if (this.isEmpty()) return this.renderEmptyDataSet();

    return (
      <>
        {this.renderNewNotifications()}
        {this.renderEarlierNotifications()}
        {this.renderMoreLoader()}
      </>
    );
  };

  render() {
    return (
      <MenuModal width={500} open={this.props.open} onClose={() => this.props.onClose()}>
        <MenuModal.Container>
          <PerfectScrollbar>
            <InfiniteScroll
              loadMore={this.fetchMoreNotifications}
              hasMore={!this.props.isFullyLoaded}
              useWindow={false}
              initialLoad={false}
              threshold={350}
            >
              <MenuModal.Header
                onClose={() => this.props.onClose()}
                title={<FormattedMessage id="menu.notifications" />}
              />
              {this.renderContent()}
            </InfiniteScroll>
          </PerfectScrollbar>
        </MenuModal.Container>
      </MenuModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listNotifications: NotificationsOperations.listNotifications,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    newNotifications: NotificationsSelectors.getNewNotifications(state),
    earlierNotifications: NotificationsSelectors.getEarlierNotifications(state),
    notificationBadgeCount: NotificationsSelectors.getBadgeCount(state),
    paginateFrom: NotificationsSelectors.getPaginateFrom(state),
    totalEntries: NotificationsSelectors.getTotalEntries(state),
    isFullyLoaded: NotificationsSelectors.getIsFullyLoaded(state),
    canAdministrateRequests: AuthSelectors.canAdministrateRequests(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationList));
