import React from 'react';
import styles from './style.module.scss';

export default ({ dots = false }) => {
  if (dots) {
    return (
      <div className={styles['dot-separator']}>
        <div />
        <div />
        <div />
      </div>
    );
  }
  return <div className={styles['separator']} />;
};
