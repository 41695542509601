import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { WorkOrderSelectors, WorkOrderOperations } from 'state/ducks/workOrder';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, Button, Icon } from 'views/components/Shared/General';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import { ProfilePicture } from 'views/components/User';
import { TreePath } from 'views/components/Asset';
import { ChooseAssetInlineModal, AssetTitle } from 'views/components/Asset';
import { WorkOrderCategory } from 'sdk/WorkOrder';
import { WorkOrderCategoryType } from 'sdk/System';
import { AuthSelectors } from 'state/ducks/auth';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import {
  ChooseAssigneeInlineModal,
  WorkOrderTypeInlineModal,
  ChoosePriorityInlineModal,
  EstimatedTimeInlineModal,
  Field,
  SelectedAssignees,
  CategoryInlineModal,
} from 'views/components/WorkOrder';
import { ElapsedTime } from 'views/components/Shared/General';
import Meter from './Meter';
import DueDate from './DueDate';
import Recurring from './Recurring';
import styles from './style.module.scss';

class MetaInfoContainer extends Component {
  isCompletedBeforeRegistration = () => this.props.editingWorkOrder.completed_before_registration;

  renderAssigneeFieldIconContainer = () => {
    const userLength = this.props.editingWorkOrder.assigned_to_users.length;
    const groupLength = this.props.editingWorkOrder.assigned_to_groups.length;
    const vendorLength = this.props.editingWorkOrder.assigned_to_vendors.length;

    if (vendorLength > 0) {
      return <Icon type="briefcase" withBackground blue backgroundSize={36} />;
    } else if (groupLength > 0) {
      return <Icon type="users" withBackground blue backgroundSize={36} />;
    } else if (userLength === 1) {
      return (
        <ProfilePicture
          size={34}
          userId={this.props.editingWorkOrder.assigned_to_users[0]}
          defaultElement={<Icon type="user" withBackground blue backgroundSize={36} />}
        />
      );
    } else {
      return <Icon type="user" withBackground blue backgroundSize={36} />;
    }
  };

  renderAssigneeField = () => {
    const assignees = [
      ...this.props.editingWorkOrder.assigned_to_users,
      ...this.props.editingWorkOrder.assigned_to_groups,
      ...this.props.editingWorkOrder.assigned_to_vendors,
    ];
    if (assignees.length > 0) {
      return (
        <div className={styles['edit-row']}>
          <Field
            icon={this.renderAssigneeFieldIconContainer()}
            label={<FormattedMessage id="resources.work-order.assignee" />}
            value={
              <SelectedAssignees
                userIds={this.props.editingWorkOrder.assigned_to_users}
                groupIds={this.props.editingWorkOrder.assigned_to_groups}
                vendorIds={this.props.editingWorkOrder.assigned_to_vendors}
              />
            }
          />
          <div className={styles['clear-container']}>
            <Button
              type="icon"
              iconButtonSize={24}
              icon={<Icon light size={16} type="times" />}
              onClick={e => {
                e.stopPropagation();
                this.props.setEditingWorkOrderValues({
                  assigned_to_users: [],
                  assigned_to_groups: [],
                  assigned_to_vendors: [],
                });
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles['edit-row']}>
        <Field
          error={this.props.fieldErrors.assignee}
          icon={this.renderAssigneeFieldIconContainer()}
          label={<FormattedMessage id="resources.work-order.assignee" />}
          value="-"
        />
      </div>
    );
  };

  renderAssignee = () => (
    <ChooseAssigneeInlineModal
      multiple
      trigger={this.renderAssigneeField()}
      position="right"
      hideArchivedUsers
      selectedUserIds={this.props.editingWorkOrder.assigned_to_users}
      selectedGroupIds={this.props.editingWorkOrder.assigned_to_groups}
      selectedVendorIds={this.props.editingWorkOrder.assigned_to_vendors}
      onAddUser={user => {
        if (this.props.fieldErrors.assignee) {
          this.props.removeFieldsFromErrors(['assignee']);
        }
        this.props.setEditingWorkOrderValues({
          assigned_to_users: [...this.props.editingWorkOrder.assigned_to_users, user.id],
        });
      }}
      onRemoveUser={user =>
        this.props.setEditingWorkOrderValues({
          assigned_to_users: this.props.editingWorkOrder.assigned_to_users.filter(id => id !== user.id),
        })
      }
      onAddGroup={group => {
        if (this.props.fieldErrors.assignee) {
          this.props.removeFieldsFromErrors(['assignee']);
        }
        this.props.setEditingWorkOrderValues({
          assigned_to_groups: [...this.props.editingWorkOrder.assigned_to_groups, group.id],
        });
      }}
      onRemoveGroup={group =>
        this.props.setEditingWorkOrderValues({
          assigned_to_groups: this.props.editingWorkOrder.assigned_to_groups.filter(id => id !== group.id),
        })
      }
      onAddVendor={vendor => {
        if (this.props.fieldErrors.assignee) {
          this.props.removeFieldsFromErrors(['assignee']);
        }
        this.props.setEditingWorkOrderValues({
          assigned_to_vendors: [...this.props.editingWorkOrder.assigned_to_vendors, vendor.id],
        });
      }}
      onRemoveVendor={vendor =>
        this.props.setEditingWorkOrderValues({
          assigned_to_vendors: this.props.editingWorkOrder.assigned_to_vendors.filter(id => id !== vendor.id),
        })
      }
    />
  );

  renderAsset = () => (
    <ChooseAssetInlineModal
      position="right"
      trigger={
        <div className={styles['edit-row']}>
          <Field
            error={this.props.fieldErrors.asset_id}
            icon={<Icon type="box-alt" withBackground backgroundSize={36} />}
            label={<FormattedMessage id="resources.work-order.asset" />}
            value={
              this.props.asset == null ? (
                '-'
              ) : (
                <React.Fragment>
                  <AssetTitle id={this.props.asset.id} />
                  {this.props.asset.tree_path.length === 0 ? null : (
                    <div className={styles['tree-path-container']}>
                      <TreePath assetId={this.props.asset.id} />
                    </div>
                  )}
                </React.Fragment>
              )
            }
          />
          {this.props.asset == null ? null : (
            <div className={styles['clear-container']}>
              <Button
                type="icon"
                iconButtonSize={24}
                icon={<Icon light size={16} type="times" />}
                onClick={e => {
                  e.stopPropagation();
                  this.props.setEditingWorkOrderValues({ asset_id: null });
                }}
              />
            </div>
          )}
        </div>
      }
      selectedAssetId={this.props.editingWorkOrder.asset_id}
      onSelectAsset={assetId => {
        if (this.props.fieldErrors.asset_id) {
          this.props.removeFieldsFromErrors(['asset_id']);
        }
        this.props.setEditingWorkOrderValues({ asset_id: assetId });
      }}
      onClear={e => this.props.setEditingWorkOrderValues({ asset_id: null })}
    />
  );

  renderSelectedCategory = () => {
    switch (this.props.editingWorkOrder.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return (
          <>
            <div>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
          </>
        );
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories.modification" />;
      }
      default:
        return '-';
    }
  };

  renderCategory = () => {
    if (this.props.settings.work_order_category_type === WorkOrderCategoryType.None) {
      return null;
    }
    return (
      <CategoryInlineModal
        trigger={
          <div className={styles['category-container']}>
            <WhiteCard noPadding>
              <div className={styles['category']}>
                <Field
                  error={this.props.fieldErrors.category}
                  icon={<Icon type="tag" withBackground backgroundSize={36} />}
                  label={<FormattedMessage id="resources.work-order.category" />}
                  value={this.renderSelectedCategory()}
                />

                {this.props.editingWorkOrder.category == null ? null : (
                  <div className={styles['clear-container']}>
                    <Button
                      type="icon"
                      iconButtonSize={24}
                      icon={<Icon light size={16} type="times" />}
                      onClick={e => {
                        e.stopPropagation();
                        this.props.setEditingWorkOrderValues({ category: null });
                      }}
                    />
                  </div>
                )}
              </div>
            </WhiteCard>
          </div>
        }
        selectedCategory={this.props.editingWorkOrder.category}
        onSelect={category => {
          this.props.removeFieldsFromErrors(['category']);
          this.props.setEditingWorkOrderValues({ category });
        }}
        onClear={e => this.props.setEditingWorkOrderValues({ category: null })}
      />
    );
  };

  renderWorkOrderType = () => (
    <WorkOrderTypeInlineModal
      trigger={
        <div className={styles['edit-row']}>
          <Field
            error={this.props.fieldErrors.work_order_type_id}
            icon={<Icon type="tag" withBackground backgroundSize={36} />}
            label={<FormattedMessage id="resources.work-order.work-order-type" />}
            value={this.props.workOrderType == null ? '-' : this.props.workOrderType.title}
          />
          {this.props.workOrderType == null ? null : (
            <div className={styles['clear-container']}>
              <Button
                type="icon"
                icon={<Icon light size={16} type="times" />}
                iconButtonSize={24}
                onClick={e => {
                  e.stopPropagation();
                  this.props.setEditingWorkOrderValues({ work_order_type_id: null });
                }}
              />
            </div>
          )}
        </div>
      }
      position="right"
      editable={this.props.isAdmin}
      selectedWorkOrderTypeId={this.props.editingWorkOrder.work_order_type_id}
      onSelectWorkOrderType={work_order_type_id => {
        if (this.props.fieldErrors.work_order_type_id) {
          this.props.removeFieldsFromErrors(['work_order_type_id']);
        }
        this.props.setEditingWorkOrderValues({ work_order_type_id });
      }}
      onClear={() => this.props.setEditingWorkOrderValues({ work_order_type_id: null })}
    />
  );

  renderPriority = () => (
    <ChoosePriorityInlineModal
      trigger={
        <div className={styles['edit-row']}>
          <Field
            error={this.props.fieldErrors.priority}
            icon={<Icon type="exclamation-circle" withBackground backgroundSize={36} />}
            label={<FormattedMessage id="resources.work-order.priority" />}
            value={this.renderSelectedPriority()}
          />
          {this.props.editingWorkOrder.priority == null ? null : (
            <div className={styles['clear-container']}>
              <Button
                type="icon"
                iconButtonSize={24}
                icon={<Icon light size={16} type="times" />}
                onClick={e => {
                  e.stopPropagation();
                  this.props.setEditingWorkOrderValues({ priority: null });
                }}
              />
            </div>
          )}
        </div>
      }
      position="right"
      selectedPriority={this.props.editingWorkOrder.priority}
      onSelectPriority={priority => {
        if (this.props.fieldErrors.priority) {
          this.props.removeFieldsFromErrors(['priority']);
        }
        this.props.setEditingWorkOrderValues({ priority });
      }}
      onClear={() => this.props.setEditingWorkOrderValues({ priority: null })}
    />
  );

  renderSelectedPriority = () => {
    switch (this.props.editingWorkOrder.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return '-';
    }
  };

  renderEstimatedTime = () => (
    <EstimatedTimeInlineModal
      estimatedMinutes={this.props.editingWorkOrder.estimated_minutes}
      onChange={estimated_minutes => {
        if (this.props.fieldErrors.estimated_minutes) {
          this.props.removeFieldsFromErrors(['estimated_minutes']);
        }
        this.props.setEditingWorkOrderValues({ estimated_minutes });
      }}
      position="right"
      trigger={
        <div className={`${styles['edit-row']}`}>
          <div className={styles['estimated-time']}>
            <Field
              error={this.props.fieldErrors.estimated_minutes}
              icon={<Icon type="clock" withBackground orange backgroundSize={36} />}
              label={<FormattedMessage id="resources.work-order.estimated-time" />}
              value={
                this.props.editingWorkOrder.estimated_minutes ? (
                  <ElapsedTime elapsedMinutes={this.props.editingWorkOrder.estimated_minutes} />
                ) : (
                  '-'
                )
              }
            />
            {this.props.editingWorkOrder.estimated_minutes == null ? null : (
              <div className={styles['clear-container']}>
                <Button
                  type="icon"
                  iconButtonSize={24}
                  icon={<Icon light size={16} type="times" />}
                  onClick={e => {
                    e.stopPropagation();
                    this.props.setEditingWorkOrderValues({ estimated_minutes: null });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      }
    />
  );

  renderMeter = () => {
    return <Meter />;
  };

  renderDueDate = () => {
    return <DueDate />;
  };

  renderRecurring = () => {
    return <Recurring />;
  };

  render() {
    return (
      <>
        {this.renderMeter()}
        {this.renderCategory()}
        <WhiteCard noPadding>
          <div className={styles['meta']}>
            {this.isCompletedBeforeRegistration() ? null : (
              <>
                {this.renderDueDate()}
                {this.renderAssignee()}
                {this.renderEstimatedTime()}
              </>
            )}
            {this.renderAsset()}
            {this.renderWorkOrderType()}
            {this.isCompletedBeforeRegistration() ? null : (
              <>
                {this.renderPriority()}
                {this.renderRecurring()}
              </>
            )}
          </div>
        </WhiteCard>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingWorkOrderValues: WorkOrderOperations.setEditingWorkOrderValues,
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    editingWorkOrder: WorkOrderSelectors.getEditingWorkOrder(state),
    asset: WorkOrderSelectors.getEditingWorkOrderAsset(state),
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    workOrderType: WorkOrderSelectors.getEditingWorkOrderType(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MetaInfoContainer)));
