export default {
  SET_NAVIGATED_TO: 'workOrders/set_navigated_to',
  RESET_SELECTED_WORK_ORDERS: 'workOrders/reset_selected_work_orders',
  SELECT_PAGE: 'workOrders/SELECT_PAGE',
  SELECT_WORK_ORDER: 'workOrders/select_work_order',
  ADD_QUERY_PARAMETER: 'workOrders/add_query_parameter',
  SHOW_AVAILABLE_FILTERS: 'workOrders/show_available_filters',
  SELECT_FILTER_TYPE: 'workOrders/select_filter_type',
  SHOW_APPLIED_FILTERS: 'workOrders/show_applied_filters',
  ADD_FILTER: 'workOrders/add_filter',
  REMOVE_FILTER: 'workOrders/remove_filter',
  RESET_FILTER: 'workOrders/reset_filter',
  SET_LOADING: 'workOrders/set_loading',
  FETCH_WORK_ORDERS: 'workOrders/fetch_work_orders',
  FETCH_WORK_ORDERS_SUCCESS: 'workOrders/fetch_work_orders_success',
  FETCH_DATA_BETWEEN_DATES: 'workOrders/fetch_data_between_dates',
  FETCH_DATA_BETWEEN_DATES_SUCCESS: 'workOrders/fetch_data_between_dates_success',
  SHOW_PREVIEW_OVERLAY: `workOrders/show_preview_overlay`,
  HIDE_PREVIEW_OVERLAY: `workOrders/hide_preview_overlay`,
  SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY: `workOrders/show_more_work_orders_preview_overlay`,
  HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY: `workOrders/hide_more_work_orders_preview_overlay`,
  BEGIN_DRAG: `workOrders/being_drag`,
  END_DRAG: `workOrders/end_drag`,
  DROP_CALENDAR_INSTANCE: 'workOrders/drop_calendar_instance',
  FETCH_WORK_ORDER_COUNTS_SUCCESS: 'workOrders/fetch_work_order_counts_success',
  FETCH_WORK_ORDER_COUNTS: 'workOrders/fetch_work_order_counts',
  SELECT_TOTAL_ENTRIES: 'workOrders/select_total_entries',
  HIDE_SELECT_TOTAL_ENTRIES: 'workOrders/hide_select_total_entries',
};
