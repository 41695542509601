import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import ContentLoader from 'react-content-loader';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { SparePartsOperations } from 'state/ducks/spareParts';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { normalizeSparePart } from 'sdk/Schemas';
import { List, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import SparePartList from '../components/SparePartList';
import SparePartListItem from '../components/SparePartList/SparePartListItem';

class AllSpareParts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sparePartIds: null,
      isLoading: true,
      isLoadingPagination: false,
      totalAmountOfSpareParts: 0,
      isChangingPage: false,
      page: 1,
      totalPages: 0,
    };
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchSpareParts();
    }, 300);
  }

  componentDidMount() {
    this.fetchSpareParts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ isLoading: true, isLoadingPagination: true, page: 1 });
      this.debouncedSearchValueWasChanged();
    }
  }

  changePage = page => {
    this.setState({ isChangingPage: true, page }, () => {
      this.fetchSpareParts({ page });
    });
  };

  fetchSpareParts = params => {
    let attrs = { ...params, search: this.props.searchValue };
    this.setState({ isLoading: true });
    attrs = {
      page_size: 8,
      ...attrs,
      ...this.props.additionalApiParams,
    };
    if (attrs.search && attrs.search.length > 0) {
      attrs = {
        ...attrs,
        sort: 'search_relevance',
      };
    }

    return API.listSpareParts(this.props.currentSystem.id, attrs)
      .then(res => {
        const { data, headers } = res;
        const { entities, result } = normalizeSparePart(data);
        this.props.updateEntities(entities);
        this.setState({
          sparePartIds: result,
          isLoading: false,
          isChangingPage: false,
          isLoadingPagination: false,
          totalAmountOfSpareParts: SDKHelperFunctions.getPaginationFromHeader(headers).totalEntries,
          totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        });
      })
      .catch(e => {
        this.setState({ isLoading: false, isChangingPage: false, isLoadingPagination: false });
      });
  };

  renderLoaders = () => {
    if (!this.state.sparePartIds || this.state.sparePartIds.length === 0) {
      return (
        <>
          <SparePartListItem loading />
          <SparePartListItem loading />
        </>
      );
    } else
      return this.state.sparePartIds.map(() => {
        return <SparePartListItem loading />;
      });
  };

  renderPaginationLoader = () => {
    return (
      <ContentLoader
        speed={2}
        width={839}
        height={60}
        viewBox="0 0 839 60"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="330" y="22" rx="3" ry="3" width="180" height="18" />
      </ContentLoader>
    );
  };

  renderListContent = () => {
    if (this.state.isLoading || this.state.isChangingPage)
      return (
        <>
          {this.renderListHeader()}
          <List>{this.renderLoaders()}</List>
        </>
      );
    if (this.props.searchValue && this.state.sparePartIds.length === 0) {
      return this.renderEmptySearch();
    }
    if (!this.state.isLoading && this.props.searchValue === '' && this.state.sparePartIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          {
            <>
              <SparePartList
                listItemRightComponent={this.props.listItemRightComponent}
                loading={this.state.isLoading}
                ids={this.state.sparePartIds}
              />
            </>
          }
        </List>
      </>
    );
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet subtitle={<FormattedMessage id="general.empty-data-set-search.title" />}></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          subtitle={<FormattedMessage id="components.select-spare-part-modal.content.empty-list" />}
        ></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-spare-part-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderPagination = () => {
    if (this.state.totalAmountOfSpareParts <= 8) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };
  render() {
    return (
      <>
        <Modal.ColumnLayout.Container.Content>
          {this.renderListContent()}
        </Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      setFilters: SparePartsOperations.setFilters,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AllSpareParts));
