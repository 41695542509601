import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { SDKReduxOperations } from 'sdk';
import { EntitySelectors } from 'sdk/State/entities';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button, Field, List, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import styles from './style.module.scss';

class ManageTemplateFieldItemsModal extends Component {
  getInitialState = () => ({
    openedTemplateFieldItemId: null,
    editingValueTitle: '',
    isSaving: false,
    showCreateNewItemOption: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  createTemplateFieldItemFromChanges = () => {
    this.setState({ isSaving: true });
    this.props
      .createTemplateFieldItem(this.props.templateFieldId, {
        title: this.state.editingValueTitle,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState(this.getInitialState(), () => {
          this.setState({ showCreateNewItemOption: true });
        });
      });
  };

  saveChanges = () => {
    if (this.state.showCreateNewItemOption) {
      this.createTemplateFieldItemFromChanges();
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateTemplateFieldItem(this.state.openedTemplateFieldItemId, { title: this.state.editingValueTitle })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.setState(this.getInitialState());
      });
  };

  renderOpenedListItem = () => {
    return (
      <List.Item>
        <div className={styles['opened-container']}>
          <Field>
            <Field.Text
              value={this.state.editingValueTitle}
              autoFocus
              onChange={value => this.setState({ editingValueTitle: value })}
            />
          </Field>
          <div className={styles['opened-item-buttons-container']}>
            <div className={styles['left-buttons']}>
              <Button
                primary
                label="general.save"
                loading={this.state.isSaving}
                disabled={!this.state.editingValueTitle?.length}
                onClick={this.saveChanges}
              />
              <div className={styles['cancel-button']}>
                <Button label="general.cancel" onClick={() => this.setState(this.getInitialState())} />
              </div>
            </div>
            {this.state.showCreateNewItemOption ? null : (
              <Button
                primary
                destructive
                label="general.delete"
                loading={this.state.isDeleting}
                onClick={() => this.props.deleteTemplateFieldItem(this.state.openedTemplateFieldItemId)}
              />
            )}
          </div>
        </div>
      </List.Item>
    );
  };

  renderListItem = templateFieldItem => {
    if (this.state.openedTemplateFieldItemId === templateFieldItem.id) {
      return this.renderOpenedListItem();
    }
    return (
      <List.Item
        key={templateFieldItem.id}
        clickable={!this.state.showCreateNewItemOption}
        onClick={() =>
          this.setState({
            openedTemplateFieldItemId: templateFieldItem.id,
            editingValueTitle: templateFieldItem.title,
          })
        }
      >
        <List.Item.TitleColumn title={templateFieldItem.title} />
      </List.Item>
    );
  };

  renderEmptyDataSet = () => (
    <div className={styles['empty-data-set']}>
      <WhiteCard>
        <EmptyDataSet
          title={
            <FormattedMessage id="screens.settings.assets.manage-template-field-items-modal.empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="screens.settings.assets.manage-template-field-items-modal.empty-data-set.subtitle" />
          }
        />
      </WhiteCard>
    </div>
  );

  renderList = () => {
    if (!this.props.templateFieldItems?.length && !this.state.showCreateNewItemOption) {
      return this.renderEmptyDataSet();
    }
    return (
      <div className={styles['list-container']}>
        <List.Header small background>
          <List.Header.Column flex>
            <FormattedMessage id="resources.template-field-item.title" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.state.showCreateNewItemOption ? this.renderOpenedListItem() : null}
          {this.props.templateFieldItems.map(templateFieldItem => this.renderListItem(templateFieldItem))}
        </List>
      </div>
    );
  };

  renderCreateNewButton = () => (
    <Button
      disabled={this.state.showCreateNewItemOption}
      primary
      label="screens.settings.assets.manage-template-field-items-modal.add-button"
      onClick={() =>
        this.setState({
          showCreateNewItemOption: true,
          editingValueTitle: '',
          openedTemplateFieldItemId: null,
        })
      }
    />
  );

  render() {
    return (
      <Modal isOpen={this.props.open} width={615}>
        <Modal.Header
          title={<FormattedMessage id="screens.settings.assets.manage-template-field-items-modal.title" />}
          subtitle={
            <span>
              <FormattedMessage id="screens.settings.assets.manage-template-field-items-modal.subtitle" />
              <span>: </span>
              <span className={styles['asset-type-in-modal-subtitle']}>
                {this.props.templateField?.title}
              </span>
            </span>
          }
          onClose={this.props.onClose}
        />
        <Modal.Content noPadding>
          <div className={styles['content-container']}>
            {this.renderCreateNewButton()}
            {this.renderList()}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createTemplateFieldItem: SDKReduxOperations.createTemplateFieldItem,
      updateTemplateFieldItem: SDKReduxOperations.updateTemplateFieldItem,
      deleteTemplateFieldItem: SDKReduxOperations.deleteTemplateFieldItem,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { templateFieldId } = ownProps;
  let templateField = EntitySelectors.getTemplateField(state, templateFieldId);
  let templateFieldItems = [];
  if (templateField) {
    templateFieldItems = EntitySelectors.getTemplateFieldItems(state, templateField.template_field_items);
  }
  return {
    templateField,
    templateFieldItems,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTemplateFieldItemsModal);
