import { API, HelperFunctions } from 'sdk';
import { normalizeSparePart } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import actions from './actions';

const setNavigatedTo = actions.setNavigatedTo;

const resetSelectedSpareParts = actions.resetSelectedSpareParts;
const selectSparePart = actions.selectSparePart;
const selectPage = actions.selectPage;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;

const fetchSpareParts = (systemId, params, config) => dispatch => {
  dispatch(actions.fetchSpareParts());
  return API.listSpareParts(systemId, params, config).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeSparePart(res.data);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchSparePartsSuccess({ ids: result, pagination }));
    return res.data;
  });
};

export default {
  setNavigatedTo,
  showAppliedFilters,
  resetSelectedSpareParts,
  selectPage,
  selectSparePart,
  selectTotalEntries,
  hideSelectTotalEntries,
  addQueryParameter,
  resetFilter,
  removeFilter,
  addFilter,
  selectFilterType,
  showAvailableFilters,
  fetchSpareParts,
};
