import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeDowntime } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createDowntime(systemId, params, meta) {
  return dispatch => {
    return API.createDowntime(systemId, params)
      .then(res => {
        const { entities } = normalizeDowntime(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { params, data: res.data, meta: meta };
        dispatch({ type: types.DOWNTIME_CREATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateDowntime(downtimeId, params, meta = {}) {
  return dispatch => {
    return API.updateDowntime(downtimeId, params)
      .then(res => {
        const { entities } = normalizeDowntime(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { downtimeId, params, data: res.data, meta };
        dispatch({ type: types.DOWNTIME_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function deleteDowntime(downtimeId, meta = {}) {
  return (dispatch, getState) => {
    const downtime = getState().entities.downtimeById[downtimeId];
    return API.deleteDowntime(downtimeId)
      .then(res => {
        const payload = { downtimeId, data: downtime, meta };
        dispatch({ type: types.DOWNTIME_DELETED, payload });
        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
