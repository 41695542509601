import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { CardGrid, ContentContainer } from 'views/components/Shared/Layout';
import PerfectScrollbar from 'react-perfect-scrollbar';
import GeneralInformation from './GeneralInformation';
import LaborCost from './LaborCost';
import Address from './Address';
import PurchaseOrderInformation from './PurchaseOrderInformation';
import VendorType from './VendorType';
import Header from '../../Header';

class Information extends Component {
  render() {
    return (
      <PerfectScrollbar>
        <Header />
        <ContentContainer>
          <CardGrid>
            <CardGrid.Column>
              <CardGrid.Row>
                <GeneralInformation />
              </CardGrid.Row>
              {this.props.settings.spare_parts_activated ? <PurchaseOrderInformation /> : null}
            </CardGrid.Column>
            <CardGrid.Column width={342}>
              <CardGrid.Row>
                <Address />
              </CardGrid.Row>
              <CardGrid.Row>
                <VendorType />
              </CardGrid.Row>
              <CardGrid.Row>
                <LaborCost />
              </CardGrid.Row>
            </CardGrid.Column>
          </CardGrid>
        </ContentContainer>
      </PerfectScrollbar>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps)(Information);
