import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Section } from 'views/scenes/Settings/components';
import { Grid } from 'views/components/Shared/Layout';
import { Field } from 'views/components/Shared/General';

export default props => {
  const renderSelectedLanguage = () => {
    switch (props.editingUser.language) {
      case 'sv':
        return <FormattedMessage id="general.languages.sv" />;
      case 'en':
        return <FormattedMessage id="general.languages.en" />;
      default:
        return null;
    }
  };

  return (
    <Section
      title={<FormattedMessage id="screens.settings.user.language.title" />}
      subtitle={<FormattedMessage id="screens.settings.user.language.subtitle" />}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column md={4}>
            <Field.Dropdown clearable={false} value={renderSelectedLanguage()}>
              <Field.Dropdown.Item
                selected={props.editingUser.language === 'en'}
                onClick={() => props.onChange({ language: 'en' })}
              >
                <FormattedMessage id="general.languages.en" />
              </Field.Dropdown.Item>
              <Field.Dropdown.Item
                selected={props.editingUser.language === 'sv'}
                onClick={() => props.onChange({ language: 'sv' })}
              >
                <FormattedMessage id="general.languages.sv" />
              </Field.Dropdown.Item>
            </Field.Dropdown>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};
