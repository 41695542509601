import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import { OperationalMaintenanceStatus } from 'sdk/OperationalMaintenanceInstance';
import { EntitySelectors } from 'sdk/State/entities';
import { SDKReduxOperations, HelperFunctions } from 'sdk';
import { Button, Field, Banner } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { ChooseUserInlineModal } from 'views/components/User';
import { AuthSelectors } from 'state/ducks/auth';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Grid } from 'views/components/Shared/Layout';
import UserContainer from './UserContainer';

class CompleteOperationalMaintenanceModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    showCompletedDateIsRequiredError: false,
    showChecklistIsRequiredError: false,
    userInlineModalIsOpen: false,
  });

  state = this.getInitialState();

  shouldComponentUpdate(prevProps) {
    if (!this.props.open && !prevProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const { operationalMaintenanceInstance } = this.props;
      this.setState({
        ...this.getInitialState(),
        isSaving: false,
        completed_date: operationalMaintenanceInstance.completed_date
          ? moment(operationalMaintenanceInstance.completed_date).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        completed_by_user_id:
          operationalMaintenanceInstance.completed_by_user_id == null
            ? this.props.currentUser.id
            : operationalMaintenanceInstance.completed_by_user_id,
        completed_comment: operationalMaintenanceInstance.completed_comment
          ? operationalMaintenanceInstance.completed_comment
          : '',
      });
    }
  }

  save = () => {
    if (this.state.isSaving) return;

    this.setState({
      isSaving: true,
    });

    this.props
      .updateOperationalMaintenanceInstance(this.props.operationalMaintenanceInstance.id, {
        completed_date: this.state.completed_date,
        completed_comment: this.state.completed_comment,
        completed_by_user_id: this.state.completed_by_user_id,
        status: OperationalMaintenanceStatus.Completed,
      })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        const showChecklistIsRequiredError = HelperFunctions.hasError(e, { code: '160005' });
        this.setState({ isSaving: false, showChecklistIsRequiredError });
        if (!showChecklistIsRequiredError) {
          toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        }
      });
  };

  renderCompletedByUser = () => {
    if (this.props.isOperator) {
      return (
        <Field.Resource
          disabled
          clearable={false}
          angleDown
          focus={this.state.userInlineModalIsOpen}
          value={<UserContainer id={this.state.completed_by_user_id} />}
          onChange={completed_by_user_id => this.setState({ completed_by_user_id })}
          onClear={() => this.setState({ completed_by_user_id: null })}
        />
      );
    }
    return (
      <ChooseUserInlineModal
        members={false}
        trigger={
          <Field.Resource
            angleDown
            clearable={false}
            focus={this.state.userInlineModalIsOpen}
            value={<UserContainer id={this.state.completed_by_user_id} />}
            onChange={completed_by_user_id => this.setState({ completed_by_user_id })}
            onClear={() => this.setState({ completed_by_user_id: null })}
          />
        }
        onOpen={() => this.setState({ userInlineModalIsOpen: true })}
        onClose={() => this.setState({ userInlineModalIsOpen: false })}
        selectedUserId={this.state.completed_by_user_id}
        onSelectUser={id => this.setState({ completed_by_user_id: id })}
        clearable={false}
      />
    );
  };

  renderCompletedContent = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column md={6}>
            <Field label={<FormattedMessage id="resources.operational-maintenance.completed-date" />}>
              <Field.Date
                error={this.state.showCompletedDateIsRequiredError}
                value={this.state.completed_date}
                onChangeDate={completed_date => {
                  this.setState({ completed_date, showCompletedDateIsRequiredError: !completed_date });
                }}
              />
            </Field>
          </Grid.Column>
          <Grid.Column md={6}>
            <Field label={<FormattedMessage id="resources.operational-maintenance.completed-by" />}>
              {this.renderCompletedByUser()}
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.operational-maintenance.completed-comment" />}>
              <Field.Textarea
                value={this.state.completed_comment}
                minRows={3}
                onChange={value => this.setState({ completed_comment: value })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  getPrimaryButtonLabel = () => {
    if (this.props.operationalMaintenanceInstance.status === OperationalMaintenanceStatus.Completed) {
      return 'general.save';
    }
    return 'components.complete-operational-maintenance-modal.complete-button';
  };

  renderChecklistRequiredBanner = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Banner.List>
          <Banner red>
            <FormattedMessage id="components.complete-operational-maintenance-modal.errors.checklist-required" />
          </Banner>
        </Banner.List>
      </div>
    );
  };

  renderFooterButtons = () => {
    return (
      <Button.Group>
        <Button
          primary
          disabled={this.state.showCompletedDateIsRequiredError}
          loading={this.state.isSaving}
          onClick={this.save}
          label={this.getPrimaryButtonLabel()}
        />
        <Button label="general.cancel" onClick={this.props.onClose} />
      </Button.Group>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={600}>
        <Modal.Header
          title={<FormattedMessage id="components.complete-operational-maintenance-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          {this.state.showChecklistIsRequiredError && this.renderChecklistRequiredBanner()}
          {this.renderCompletedContent()}
        </Modal.Content>
        <Modal.Footer>{this.renderFooterButtons()}</Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOperationalMaintenanceInstance: SDKReduxOperations.updateOperationalMaintenanceInstance,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstanceId } = ownProps;

  return {
    operationalMaintenanceInstance: EntitySelectors.getOperationalMaintenanceInstance(
      state,
      operationalMaintenanceInstanceId
    ),
    currentUser: AuthSelectors.getCurrentUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteOperationalMaintenanceModal);
