import actions from './actions';
import { normalizeWorkOrder, normalizeCalendarInstance } from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';
import { API, HelperFunctions } from 'sdk';

const setNavigatedTo = actions.setNavigatedTo;

const selectPage = actions.selectPage;
const resetSelectedWorkOrders = actions.resetSelectedWorkOrders;
const selectWorkOrder = actions.selectWorkOrder;
const selectTotalEntries = actions.selectTotalEntries;
const hideSelectTotalEntries = actions.hideSelectTotalEntries;

const showAppliedFilters = actions.showAppliedFilters;
const showAvailableFilters = actions.showAvailableFilters;
const selectFilterType = actions.selectFilterType;
const addFilter = actions.addFilter;
const removeFilter = actions.removeFilter;
const resetFilter = actions.resetFilter;
const addQueryParameter = actions.addQueryParameter;
const setLoading = actions.setLoading;
const showPreviewOverlay = actions.showPreviewOverlay;
const hidePreviewOverlay = actions.hidePreviewOverlay;
const showMoreWorkOrdersPreviewOverlay = actions.showMoreWorkOrdersPreviewOverlay;
const hideMoreWorkOrdersPreviewOverlay = actions.hideMoreWorkOrdersPreviewOverlay;
const beginDrag = actions.beginDrag;
const endDrag = actions.endDrag;
const dropCalendarInstance = actions.dropCalendarInstance;

const fetchWorkOrders = (systemId, params, config) => dispatch => {
  dispatch(actions.fetchWorkOrders());
  return API.listWorkOrders(systemId, params, config).then(res => {
    const { headers } = res;
    const pagination = HelperFunctions.getPaginationFromHeader(headers);
    const { entities, result } = normalizeWorkOrder(res.data);

    dispatch(EntityOperations.updateEntities(entities));
    dispatch(actions.fetchWorkOrdersSuccess({ ids: result, pagination }));
    return res.data;
  });
};

const getWorkOrderListCounts = (systemId, params, config) => dispatch => {
  dispatch(actions.fetchWorkOrderListCounts());
  return API.getWorkOrderListCounts(systemId, params, config).then(({ data: counts }) => {
    dispatch(actions.fetchWorkOrderListCountsSuccess(counts));
  });
};

const fetchInstancesForDates =
  (systemId, params, options = { resetCalendar: true }, config) =>
  dispatch => {
    const { resetCalendar } = options;
    dispatch(actions.fetchDataBetweenDates({ resetCalendar }));
    return API.showCalendar(systemId, params, config)
      .then(({ data }) => {
        const { instances } = data;
        let ids = [];
        const { entities } = normalizeCalendarInstance(
          instances.map(instance => {
            let id = null;
            if (instance.type === 'work_order') {
              id = instance.work_order.id;
            } else {
              id = `${instance.recurring_maintenance_date}_${instance.recurring_maintenance.id}`;
            }
            ids = [...ids, id];
            return {
              ...instance,
              id,
            };
          })
        );
        const { calendarInstanceById } = entities;
        dispatch(EntityOperations.updateEntities(entities));
        dispatch(
          actions.fetchDataBetweenDatesSuccess({
            instanceById: calendarInstanceById,
            ids,
          })
        );
      })
      .catch(() => {});
  };

export default {
  setNavigatedTo,
  showAppliedFilters,
  hideSelectTotalEntries,
  selectTotalEntries,
  resetSelectedWorkOrders,
  selectPage,
  addQueryParameter,
  resetFilter,
  showAvailableFilters,
  selectFilterType,
  addFilter,
  removeFilter,
  getWorkOrderListCounts,
  showMoreWorkOrdersPreviewOverlay,
  hideMoreWorkOrdersPreviewOverlay,
  beginDrag,
  dropCalendarInstance,
  endDrag,
  setLoading,
  fetchInstancesForDates,
  fetchWorkOrders,
  showPreviewOverlay,
  hidePreviewOverlay,
  selectWorkOrder,
};
