import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { Title } from 'views/components/SparePart';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { Info, WorkOrder } from './Tabs';
import styles from './style.module.scss';

const ModalTabs = {
  Info: 'info',
  WorkOrder: 'workOrder',
};

class View extends Component {
  getInitialState = () => ({
    tab: ModalTabs.Info,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  renderContent = () => {
    switch (this.state.tab) {
      case ModalTabs.Info:
        return <Info id={this.props.sparePartWithdrawal.id} />;
      case ModalTabs.WorkOrder:
        return <WorkOrder {...this.props} />;
      default:
        return null;
    }
  };

  renderIconButtons = () => {
    if (this.props.canEditSparePartWithdrawal) {
      return (
        <>
          <Button
            type="icon"
            icon={<Icon regular red type="trash-alt" />}
            onClick={() => this.props.onDelete(this.props.id)}
          />
          <Button type="icon" icon={<Icon regular type="pen" />} onClick={this.props.onEdit} />
        </>
      );
    }
    return null;
  };

  renderTabs = () => {
    if (this.props.workOrder) {
      return (
        <Modal.Header.TabBar>
          <Modal.Header.TabBarItem
            onClick={() => this.setState({ tab: ModalTabs.Info })}
            active={this.state.tab === ModalTabs.Info}
          >
            <FormattedMessage id="components.spare-part-withdrawal-modal.tabs.info" />
          </Modal.Header.TabBarItem>
          {this.props.workOrder ? (
            <Modal.Header.TabBarItem
              onClick={() => this.setState({ tab: ModalTabs.WorkOrder })}
              active={this.state.tab === ModalTabs.WorkOrder}
            >
              <FormattedMessage id="components.spare-part-withdrawal-modal.tabs.work-order" />
            </Modal.Header.TabBarItem>
          ) : null}
        </Modal.Header.TabBar>
      );
    }
    return null;
  };

  renderTitle = () => {
    if (this.props.sparePart) {
      return <Title sparePart={this.props.sparePart} />;
    }
    return this.props.sparePartWithdrawal.title;
  };

  render() {
    return (
      <>
        <Modal.Header
          title={
            <>
              <FormattedMessage id="components.spare-part-withdrawal-modal.title" />
              <span> - </span>
              <span>
                {moment(this.props.sparePartWithdrawal.created_at)
                  .tz(this.props.currentSystem.timezone)
                  .format('LLL')}
              </span>
            </>
          }
          tabBarComponent={this.renderTabs()}
          iconButtons={this.renderIconButtons()}
          subtitle={this.renderTitle()}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <div className={styles['content']}>{this.renderContent()}</div>
        </Modal.Content>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePartWithdrawal = EntitySelectors.getSparePartWithdrawal(state, ownProps.id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartWithdrawal.spare_part_id);
  let sparePartUnit = null;
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  } else {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePartWithdrawal.spare_part_unit_id);
  }

  return {
    sparePartWithdrawal,
    sparePart,
    sparePartUnit,
    workOrder: EntitySelectors.getWorkOrder(state, sparePartWithdrawal.work_order_id),
    canEditSparePartWithdrawal: AuthSelectors.canEditSparePartWithdrawal(state, sparePartWithdrawal),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps)(View);
