import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { bindActionCreators } from 'redux';
import { normalizeLoginCredential } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, FieldErrorWrapper } from 'views/components/Shared/General';

class ChangePasswordModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    password: '',
    confirmPassword: '',
    showPasswordsNotMatchingError: false,
    showEmptyPasswordError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  activate = () => {
    if (this.state.password === '') {
      this.setState({ showEmptyPasswordError: true });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ showPasswordsNotMatchingError: true });
      return;
    }
    const params = {
      password: this.state.password,
    };
    this.setState({ isSaving: true });
    API.updateLoginCredential(this.props.credentialId, params)
      .then(({ data: loginCredential }) => {
        const { entities } = normalizeLoginCredential(loginCredential);
        this.props.updateEntities(entities);
        this.setState({ isSaving: false });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onChanged();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field label={<FormattedMessage id="resources.login-credentials.password" />}>
              <Field.Text
                autoFocus
                type="password"
                autocomplete="new-password"
                value={this.state.password}
                error={this.state.showEmptyPasswordError}
                onChange={password => this.setState({ password, showEmptyPasswordError: false })}
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FieldErrorWrapper
              show={this.state.showPasswordsNotMatchingError}
              errorElement={<FormattedMessage id="general.errors.passwords-not-matching" />}
            >
              <Field label={<FormattedMessage id="screens.users.action-modal.confirm-password" />}>
                <Field.Text
                  type="password"
                  autocomplete="new-password"
                  error={this.state.showPasswordsNotMatchingError || this.state.showEmptyPasswordError}
                  value={this.state.confirmPassword}
                  onChange={confirmPassword => {
                    if (this.state.showPasswordsNotMatchingError) {
                      this.setState({ showPasswordsNotMatchingError: false });
                    }
                    this.setState({ confirmPassword });
                  }}
                />
              </Field>
            </FieldErrorWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.group-logins.modal.change-password" />}
          subtitle={<FormattedMessage id="screens.users.group-logins.modal.activate-subtitle" />}
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.activate();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
