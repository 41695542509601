import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import { ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import toast from 'react-hot-toast';

class DeleteSparePartVendorModal extends Component {
  state = {
    isDeleting: false,
    showConfirmDeleteModal: false,
  };

  getInitialState = () => ({
    isDeleting: false,
    showConfirmDeleteModal: true,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ ...this.getInitialState() });
    }
  }

  delete() {
    this.setState({ isDeleting: true });
    this.props.deleteSparePartVendor(this.props.sparePartVendor.id).then(() => {
      toast(
        <ToastMessage success text={<FormattedMessage id="screens.vendor.spare-parts.delete-success" />} />
      );
      this.setState({ isDeleting: false });
      this.props.onClose();
      this.setState({ showConfirmDeleteModal: false });
    });
  }

  render() {
    return (
      <ConfirmModal
        destructive
        open={this.state.showConfirmDeleteModal}
        title={this.props.title}
        message={this.props.subtitle}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeleting}
        onConfirm={() => this.delete()}
        onCancel={() => {
          this.setState({ showConfirmDeleteModal: false });
          this.props.onClose();
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSparePartVendor: SDKReduxOperations.deleteSparePartVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  var sparePartVendor = null;
  if (ownProps.id) {
    sparePartVendor = EntitySelectors.getSparePartVendor(state, ownProps.id);
  }
  return {
    sparePartVendor,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSparePartVendorModal);
