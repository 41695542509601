import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartUnitWithValue } from 'views/components/SparePart';
import styles from './style.module.scss';

class PurchaseOrderDeliveryListItemRow extends Component {
  renderNumber = () => {
    if (this.props.purchaseOrderRow.number == null) return null;
    return (
      <React.Fragment>
        <span className={styles['subtitle']}>#{this.props.purchaseOrderRow.number}</span>
        {this.props.purchaseOrderRow.title != null ? <span> - </span> : null}
      </React.Fragment>
    );
  };

  renderQuantity = () => {
    if (this.props.purchaseOrderRow && this.props.purchaseOrderRow.package_quantity) {
      return (
        <SparePartUnitWithValue
          isPackage
          value={this.props.purchaseOrderDeliveryRow.quantity}
        />
      )
    }
    else {
      return (
        <SparePartUnitWithValue
          value={this.props.purchaseOrderDeliveryRow.quantity}
          sparePartUnit={this.props.sparePartUnit}
        />
      )
    }
  }

  render() {
    if (this.props.purchaseOrderRow == null) {
      return (
        <div className={styles['delivery-row']}>
          <p className={styles['title']}>
            <FormattedMessage id="screens.purchase-order.deliveries.removed-purchase-order-row" />
          </p>
          <p className={styles['quantity']}>
            <SparePartUnitWithValue
              sparePartUnit={this.props.sparePartUnit}
              value={this.props.purchaseOrderDeliveryRow.quantity}
            />
          </p>
        </div>
      );
    }
    return (
      <div className={styles['delivery-row']}>
        <p className={styles['title']}>
          {this.renderNumber()}
          <span>{this.props.purchaseOrderRow.title}</span>
        </p>
        <p className={styles['quantity']}>
          {this.renderQuantity()}
        </p>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderRow = EntitySelectors.getPurchaseOrderRow(
    state,
    ownProps.purchaseOrderDeliveryRow.purchase_order_row_id
  );
  return {
    purchaseOrderRow,
    sparePartUnit:
      purchaseOrderRow == null
        ? null
        : EntitySelectors.getSparePartUnit(state, purchaseOrderRow.spare_part_unit_id),
  };
}

export default connect(mapStateToProps)(PurchaseOrderDeliveryListItemRow);
