import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { EntitySelectors } from 'sdk/State/entities';
import { Field, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

const VendorField = ({ vendor, edit, history }) => {
  if (vendor == null) return null;
  if (edit) {
    return (
      <Field view={!edit} label={<FormattedMessage id="resources.purchase-order.vendor" />}>
        <Field.Resource clearable={false} disabled icon={<Icon type="briefcase" />} value={vendor.name} />
      </Field>
    );
  } else {
    return (
      <Field view={!edit} label={<FormattedMessage id="resources.purchase-order.vendor" />}>
        <div className={styles['vendor']}>
          <Link to={`/vendors/${vendor.id}`}>{vendor.name}</Link>
        </div>
      </Field>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(VendorField);
