import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SDKReduxOperations } from 'sdk';
import styles from './style.module.scss';

class NotificationListItem extends Component {
  renderLink = children => {
    const notification = this.props.notification;

    switch (notification.type) {
      case 'work-order-reminder':
      case 'work-order-assigned':
      case 'work-order-purchases-delivered': {
        return <Link to={`/work-orders/${notification.work_order_id}/`}>{children}</Link>;
      }
      case 'request-created':
      case 'request-created-and-assigned':
      case 'request-reassigned':
      case 'request-created-unassigned':
      case 'request-archived':
      case 'request-work-order-created':
      case 'request-work-order-completed':
      case 'request-work-order-paused': {
        if (this.props.canAdministrateRequests) {
          return <Link to={`/requests?list=to_plan&request_id=${notification.request_id}`}>{children}</Link>;
        } else {
          return (
            <Link to={`/requests?list=my_created&request_id=${notification.request_id}`}>{children}</Link>
          );
        }
      }
      case 'spare-part-low-stock-quantity': {
        return <Link to="/spare-parts/should-order">{children}</Link>;
      }
      case 'late-purchase-order': {
        if (notification.data.count > 1) {
          return <Link to="/purchase-orders?late=true">{children}</Link>;
        } else {
          return <Link to={`/purchase-orders/${notification.purchase_order.id}`}>{children}</Link>;
        }
      }
      case 'meters-due': {
        return <Link to="/meters">{children}</Link>;
      }
      case 'exports-work-orders-xlsx':
      case 'exports-requests-xlsx':
      case 'exports-spare-parts-xlsx':
      case 'exports-assets-xlsx':
      case 'exports-vendors-xlsx':
      case 'exports-purchase-orders-xlsx':
      case 'exports-assets-qr-label':
      case 'exports-spare-parts-qr-label':
      case 'exports-work-orders-list-pdf':
      case 'exports-requests-list-pdf':
      case 'exports-assets-list-pdf':
      case 'exports-spare-parts-list-pdf':
      case 'exports-work-orders-report-xlsx':
      case 'exports-requests-report-xlsx':
      case 'exports-costs-report-xlsx':
      case 'exports-spent-times-report-xlsx':
      case 'exports-costs-xlsx':
      case 'exports-operational-work-orders-list-pdf':
      case 'exports-work-orders-detail-pdf':
      case 'exports-requests-detail-pdf':
      case 'exports-assets-detail-pdf':
      case 'exports-spare-parts-detail-pdf': {
        return (
          <a href={`${process.env.REACT_APP_BROWSER_URL}exports/${notification.export_id}`} target="_blank">
            {children}
          </a>
        );
      }
      case 'checklist-instance-deviation-assigned':
      case 'checklist-instance-deviation-reassigned': {
        return <Link to={`/deviations/${notification.checklist_instance_id}`}>{children}</Link>;
      }

      default:
        return null;
    }
  };

  renderIndicator = () => {
    if (this.props.notification.opened) return null;
    return <div className={styles['indicator']} />;
  };

  setNotificationAsOpened = () => {
    this.props.updateNotification(this.props.notification.id, { opened: true });
  };

  render() {
    const { notification } = this.props;
    return this.renderLink(
      <div className={styles['item']} onClick={this.setNotificationAsOpened}>
        {this.renderIndicator()}
        <div className={styles['title-container']}>
          <div className={styles['title']}>{notification.title}</div>
          <div className={styles['subtitle']}>{notification.message}</div>
        </div>
        <div className={styles['timestamp']}>{moment(notification.created_at).format('LL')}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateNotification: SDKReduxOperations.updateNotification,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { work_order_id, request_id } = ownProps.notification;

  const request = EntitySelectors.getRequest(state, request_id);
  let asset;
  if (request) {
    asset = EntitySelectors.getAsset(state, request.asset_id);
  }

  return {
    workOrder: EntitySelectors.getWorkOrder(state, work_order_id),
    request,
    asset,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListItem);
