import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';

class ChecklistTemplateContainer extends Component {
  render() {
    const { checklistTemplate, checklistTemplateVersion } = this.props;
    if (checklistTemplateVersion) {
      return checklistTemplateVersion.title;
    }
    return checklistTemplate.title;
  }
}

function mapStateToProps(state, ownProps) {
  const checklistTemplate = EntitySelectors.getChecklistTemplate(state, ownProps.id);
  if (checklistTemplate.active_version) {
    return {
      checklistTemplate,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.active_version
      ),
    };
  }
  if (checklistTemplate.draft_version) {
    return {
      checklistTemplate,
      checklistTemplateVersion: EntitySelectors.getChecklistTemplateVersion(
        state,
        checklistTemplate.draft_version
      ),
    };
  }
  return {
    checklistTemplate: EntitySelectors.getChecklistTemplate(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(ChecklistTemplateContainer);
