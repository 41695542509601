import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { ExportLoader } from 'views/components/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import styles from './styles.module.scss';
import { MetersSelectors } from 'state/ducks/meters';

class ExportModal extends Component {
  initialState = {
    isExporting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
  };

  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  componentDidUpdate(prevProps) {
    const { open, selectedMetersCount, exportType } = this.props;
    const { open: prevOpen } = prevProps;

    if (!prevOpen && open) {
      this.setState({
        ...this.initialState,
        showQuantityExceeded: selectedMetersCount >= maxQuantityAllowedForType(exportType),
      });
    }
  }

  shouldComponentUpdate = nextProps => this.props.open || nextProps.open;

  buildFilterParams = () => {
    const { selectedMeterIds, queryParameters, totalEntriesIsSelected, listParams } = this.props;

    if (totalEntriesIsSelected) {
      return {
        ...queryParameters,
        ...listParams,
      };
    }

    return {
      id: {
        any: Object.keys(selectedMeterIds).toString(),
      },
    };
  };

  export = async () => {
    const { exportType, system } = this.props;

    this.setState({ isExporting: true });

    const payload = {
      params: this.buildFilterParams(),
      type: exportType,
    };

    const { data } = await API.createExport(system.id, payload);
    this.setState({ showExportLoaderForId: data.id });
  };

  renderMaxQuantityErrorMessage = () => {
    switch (this.props.exportType) {
      case ExportType.MetersListPdf:
        return <FormattedMessage id="components.export-modal.pdf-quantity-exceeded" />;

      default:
        return null;
    }
  };

  renderContent = () => {
    const { showQuantityExceeded, isExporting, showExportLoaderForId } = this.state;

    if (showQuantityExceeded) {
      return (
        <Modal.Content>
          <div className={styles['quantity-exceeded']}>{this.renderMaxQuantityErrorMessage()}</div>
        </Modal.Content>
      );
    }

    if (isExporting) {
      return (
        <Modal.Content>
          <ExportLoader exportId={showExportLoaderForId} />
        </Modal.Content>
      );
    }

    return null;
  };

  renderFooter = () => {
    const { showQuantityExceeded, isExporting } = this.state;
    const { onClose } = this.props;

    if (showQuantityExceeded || isExporting) {
      return null;
    }

    return (
      <Modal.Footer>
        <Button.Group>
          <Button primary label="general.save" onClick={this.export} />
          <Button label="general.cancel" onClick={onClose} />
        </Button.Group>
      </Modal.Footer>
    );
  };

  renderTitle = () => {
    const { exportType } = this.props;

    switch (exportType) {
      case ExportType.MetersListPdf:
        return <FormattedMessage id="components.export-modal.title-print" />;

      default:
        return null;
    }
  };

  renderSubtitle = () => {
    const { selectedMetersCount } = this.props;

    return (
      <FormattedPlural
        value={selectedMetersCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-meters.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-meters.two"
            values={{ count: selectedMetersCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-meters.few"
            values={{ count: selectedMetersCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-meters.many"
            values={{ count: selectedMetersCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-meters.other"
            values={{ count: selectedMetersCount }}
          />
        }
      />
    );
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Modal isOpen={open} width={440}>
        <Modal.Header
          ignoreLine
          title={this.renderTitle()}
          subtitle={this.renderSubtitle()}
          onClose={onClose}
        />
        {this.renderContent()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    selectedMeterIds: MetersSelectors.getSelectedMeterIds(state),
    selectedMetersCount: MetersSelectors.getSelectedMetersCount(state),
    totalEntriesIsSelected: MetersSelectors.getTotalEntriesIsSelected(state),
    queryParameters: MetersSelectors.getQueryParameters(state),
  };
}

ExportModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.MetersListPdf]),
};

ExportModal.defaultProps = {
  exportType: null,
};

export default connect(mapStateToProps)(ExportModal);
