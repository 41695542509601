import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Grid, Modal } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';
import { Field, Button } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: '',
      height: '',
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      let width = '';
      let height = '';
      let margin_top = '';
      let margin_bottom = '';
      let margin_left = '';
      let margin_right = '';
      if (this.props.type === 'sparePart') {
        width = this.props.settings.spare_part_qr_width || '';
        height = this.props.settings.spare_part_qr_height || '';
        margin_top = this.props.settings.spare_part_qr_margin_top || '';
        margin_bottom = this.props.settings.spare_part_qr_margin_bottom || '';
        margin_left = this.props.settings.spare_part_qr_margin_left || '';
        margin_right = this.props.settings.spare_part_qr_margin_right || '';
      } else {
        width = this.props.settings.asset_qr_width || '';
        height = this.props.settings.asset_qr_height || '';
        margin_top = this.props.settings.asset_qr_margin_top || '';
        margin_bottom = this.props.settings.asset_qr_margin_bottom || '';
        margin_left = this.props.settings.asset_qr_margin_left || '';
        margin_right = this.props.settings.asset_qr_margin_right || '';
      }
      this.setState({
        width,
        height,
        margin_top,
        margin_bottom,
        margin_left,
        margin_right,
        isSaving: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  updateSettings = () => {
    let params = {};
    if (this.props.type === 'sparePart') {
      params = {
        spare_part_qr_width: this.state.width,
        spare_part_qr_height: this.state.height,
        spare_part_qr_margin_top: this.state.margin_top,
        spare_part_qr_margin_bottom: this.state.margin_bottom,
        spare_part_qr_margin_left: this.state.margin_left,
        spare_part_qr_margin_right: this.state.margin_right,
      };
    } else {
      params = {
        asset_qr_width: this.state.width,
        asset_qr_height: this.state.height,
        asset_qr_margin_top: this.state.margin_top,
        asset_qr_margin_bottom: this.state.margin_bottom,
        asset_qr_margin_left: this.state.margin_left,
        asset_qr_margin_right: this.state.margin_right,
      };
    }
    this.setState({ isSaving: true });
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.props.onClose();
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  renderSubtitle = () => {
    if (this.props.type === 'sparePart') {
      return (
        <FormattedMessage id="components.legacy-print-qr-codes.size-settings-modal.subtitle-spare-part" />
      );
    }
    return <FormattedMessage id="components.legacy-print-qr-codes.size-settings-modal.subtitle-asset" />;
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={430}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.legacy-print-qr-codes.size-settings-modal.title" />}
          subtitle={this.renderSubtitle()}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="components.legacy-print-qr-codes.width" edit />}>
                  <Field.Decimal
                    value={this.state.width}
                    rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                    onChange={width => this.setState({ width })}
                    onBlur={width => this.setState({ width })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="components.legacy-print-qr-codes.height" edit />}>
                  <Field.Decimal
                    value={this.state.height}
                    rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                    onChange={height => this.setState({ height })}
                    onBlur={height => this.setState({ height })}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field label={<FormattedMessage id="components.legacy-print-qr-codes.margins" edit />}>
                  <div className={styles['margins-container']}>
                    <div className={[styles['margins'], styles['top']].join(' ')}>
                      <Field.Number
                        placeholder="5"
                        value={this.state.margin_top}
                        rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                        onChange={margin_top => this.setState({ margin_top })}
                        onBlur={margin_top => this.setState({ margin_top })}
                      />
                    </div>
                    <div className={[styles['margins'], styles['left']].join(' ')}>
                      <Field.Number
                        placeholder="5"
                        value={this.state.margin_left}
                        rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                        onChange={margin_left => this.setState({ margin_left })}
                        onBlur={margin_left => this.setState({ margin_left })}
                      />
                    </div>
                    <div className={[styles['margins'], styles['right']].join(' ')}>
                      <Field.Number
                        placeholder="5"
                        value={this.state.margin_right}
                        rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                        onChange={margin_right => this.setState({ margin_right })}
                        onBlur={margin_right => this.setState({ margin_right })}
                      />
                    </div>
                    <div className={[styles['margins'], styles['bottom']].join(' ')}>
                      <Field.Number
                        placeholder="5"
                        value={this.state.margin_bottom}
                        rightLabel={<FormattedMessage id="components.legacy-print-qr-codes.abbreviation" />}
                        onChange={margin_bottom => this.setState({ margin_bottom })}
                        onBlur={margin_bottom => this.setState({ margin_bottom })}
                      />
                    </div>
                    <div className={styles['vertical-line']} />
                    <div className={styles['horizontal-line']} />
                  </div>
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.updateSettings}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
