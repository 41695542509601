import { API } from 'sdk';
import actions from './actions';
import moment from 'moment';
import {
  normalizeOperationalMaintenanceTemplate,
  normalizeOperationalMaintenanceTemplateVersion,
} from 'sdk/Schemas';
import EntityOperations from 'sdk/State/entities/operations';

const fetchOperationalMaintenanceTemplate = id => dispatch => {
  return API.fetchOperationalMaintenanceTemplate(id)
    .then(({ data: operationalMaintenanceTemplate }) => {
      const { id } = operationalMaintenanceTemplate.operational_maintenance_template_versions[0];
      dispatch(actions.setActiveOperationalMaintenanceTemplateVersion(id));
      return API.fetchOperationalMaintenanceTemplateVersion(id).then(
        ({ data: operationalMaintenanceTemplateVersion }) => {
          const { entities: templateEntities } = normalizeOperationalMaintenanceTemplate(
            operationalMaintenanceTemplate
          );
          const { entities: versionEntities } = normalizeOperationalMaintenanceTemplateVersion(
            operationalMaintenanceTemplateVersion
          );
          dispatch(EntityOperations.updateEntities(templateEntities));
          dispatch(EntityOperations.updateEntities(versionEntities));

          dispatch(
            actions.fetchOperationalMaintenanceTemplateVersionSuccess(
              operationalMaintenanceTemplateVersion.id
            )
          );
          return normalizeOperationalMaintenanceTemplate;
        }
      );
    })
    .catch(e => {
      throw e;
    });
};

const fetchOperationalMaintenanceTemplateVersion = (id, config) => dispatch => {
  dispatch(actions.setOperationalMaintenanceTemplateVersion(id));
  return API.fetchOperationalMaintenanceTemplateVersion(id, config)
    .then(({ data: operationalMaintenanceTemplateVersion }) => {
      const { entities: versionEntities } = normalizeOperationalMaintenanceTemplateVersion(
        operationalMaintenanceTemplateVersion
      );
      dispatch(EntityOperations.updateEntities(versionEntities));

      dispatch(
        actions.fetchOperationalMaintenanceTemplateVersionSuccess(operationalMaintenanceTemplateVersion.id)
      );
      return operationalMaintenanceTemplateVersion;
    })
    .catch(e => {});
};

export default {
  fetchOperationalMaintenanceTemplate,
  fetchOperationalMaintenanceTemplateVersion,
};
