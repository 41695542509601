import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditMeterUnitModal from './EditMeterUnitModal';
import styles from './style.module.scss';

class MeterUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditMeterUnitModal: false,
      showEditMeterUnitModalForMeterUnitId: null,
      createdIds: [],
    };
  }

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.meters.units.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() =>
            this.setState({
              showEditMeterUnitModal: true,
              showEditMeterUnitModalForMeterUnitId: null,
            })
          }
          label="screens.settings.meters.units.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.spare-part-unit.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListItem = meterUnit => (
    <List.Item
      clickable
      onClick={() =>
        this.setState({
          showEditMeterUnitModal: true,
          showEditMeterUnitModalForMeterUnitId: meterUnit.id,
        })
      }
    >
      <List.Item.TitleColumn title={meterUnit.title} />
      {meterUnit.default ? (
        <List.Item.Column alignRight>
          <span className={styles['default']}>
            <FormattedMessage id="resources.spare-part-unit.default" />
          </span>
        </List.Item.Column>
      ) : null}
    </List.Item>
  );

  rendermeterUnits = () => {
    const newmeterUnits = this.state.createdIds
      .filter(createdId => this.props.meterUnits.find(({ id }) => id === createdId))
      .map(createdId => this.props.meterUnits.find(({ id }) => id === createdId));

    const oldmeterUnits = this.props.meterUnits.filter(({ id }) => !this.state.createdIds.includes(id));
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showEditMeterUnitModal: true,
                  showEditMeterUnitModalForMeterUnitId: null,
                })
              }
              label="screens.settings.meters.units.add-button"
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {newmeterUnits.map(meterUnit => this.renderListItem(meterUnit))}
          {oldmeterUnits.map(meterUnit => this.renderListItem(meterUnit))}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.props.meterUnits.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.rendermeterUnits();
    }
  }

  render() {
    return (
      <>
        <BackButton to="/settings/meters" />
        <Section
          title={<FormattedMessage id="screens.settings.meters.units.title" />}
          subtitle={<FormattedMessage id="screens.settings.meters.units.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditMeterUnitModal
          open={this.state.showEditMeterUnitModal}
          id={this.state.showEditMeterUnitModalForMeterUnitId}
          onClose={() => this.setState({ showEditMeterUnitModal: false })}
          onCreate={id => {
            this.setState(prevState => ({
              showEditMeterUnitModal: false,
              createdIds: [id, ...prevState.createdIds],
            }));
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    meterUnits: AuthSelectors.getMeterUnits(state),
  };
}

export default connect(mapStateToProps)(MeterUnits);
