import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { EntitySelectors } from 'sdk/State/entities';
import { RequestsSelectors, RequestsOperations } from 'state/ducks/requests';
import { AuthSelectors } from 'state/ducks/auth';
import { EmptyDataSet } from 'views/components/Shared/General';
import View from './View';
import Edit from './Edit';
import RequestSmall from 'assets/images/EmptyDataSet/RequestSmall.png';

class Container extends Component {
  state = {
    notFound: false,
  };

  componentDidMount() {
    const { isNewRequest } = this.props;
    const { request_id: requestId } = queryString.parse(this.props.location.search);
    if (!isNewRequest && requestId != null) {
      this.props
        .fetchRequest(requestId)

        .then(request => {
          const { system_id } = request;
          if (system_id !== this.props.currentSystem.id) {
            this.setState({ notFound: true });
          }
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            this.setState({ notFound: true });
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { request_id: prevRequestId } = queryString.parse(prevProps.location.search);
    const { request_id: requestId } = queryString.parse(this.props.location.search);
    if (requestId && prevRequestId !== requestId) {
      this.setState({ notFound: false });
      this.props
        .fetchRequest(requestId)
        .then(request => {
          const { system_id } = request;
          if (system_id !== this.props.currentSystem.id) {
            this.setState({ notFound: true });
          }
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            this.setState({ notFound: true });
          }
        });
    }
  }

  render() {
    if (this.props.isNewRequest) return <Edit request={this.props.editingRequest} isNewRequest />;
    if (this.props.editingRequest) return <Edit request={this.props.editingRequest} />;
    if (this.state.notFound)
      return (
        <EmptyDataSet
          title={<FormattedMessage id="screens.request.not-found.title" />}
          subtitle={<FormattedMessage id="screens.request.not-found.subtitle" />}
          image={RequestSmall}
          small
        />
      );
    if (this.props.selectedRequest) {
      return (
        <View
          request={this.props.selectedRequest}
          showClose={this.props.showClose}
          onClick={this.props.onClose}
        />
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRequest: RequestsOperations.fetchRequest,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { request_id: requestId } = queryString.parse(ownProps.location.search);
  return {
    selectedRequest: EntitySelectors.getRequest(state, requestId),
    editingRequest: RequestsSelectors.getSelectedEditingRequest(state),
    isNewRequest: RequestsSelectors.isNewRequest(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
