import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class GroupListItem extends PureComponent {
  render() {
    return (
      <List.Item small light clickable onClick={() => this.props.onToggle(this.props.id)}>
        <List.Item.Column width={20}>
          <Field.Checkbox checked={this.props.selected} onChange={() => this.props.onToggle(this.props.id)} />
        </List.Item.Column>
        <List.Item.TitleColumn title={this.props.group.title} />
      </List.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    group: EntitySelectors.getGroup(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(GroupListItem);
