import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistEditModalOperations, ChecklistEditModalSelectors } from 'state/ducks/checklistEditModal';
import { Button } from 'views/components/Shared/General';
import { MeterType } from 'sdk/ChecklistTemplateRow';
import { CommentOptions, Attachments } from 'views/components/Checklist/ChecklistTemplateRow/Components';
import DragHandle from '../../DragHandle';
import styles from './style.module.scss';

class Meter extends Component {
  renderRightContainer = () => (
    <div className={styles['right-container']}>
      <div className={styles['add-meter-reading']}>
        <Button gray label="components.checklist-instance-modal.add-meter-reading" />
      </div>
      <CommentOptions editable={false} />
    </div>
  );

  renderAttachments = () => {
    const { images, attachments } = this.props;
    if (images.length > 0 || attachments.length > 0)
      return (
        <div className={styles['attachments']}>
          <Attachments images={this.props.images} attachments={this.props.attachments} />
        </div>
      );
  };

  renderNewMeterRow = () => {
    let classNames = [styles['item'], styles['new'], styles['editing']];
    return (
      <div className={classNames.join(' ')}>
        <DragHandle />
        <div className={styles['title-container']}>
          <div className={styles['title']}>
            <span className={styles['placeholder']}>
              <FormattedMessage id="components.edit-checklist-modal.new-meter-placeholder" />
            </span>
          </div>
          {this.renderAttachments()}
        </div>
        <div className={styles['right-container']}>
          <div className={styles['add-meter-reading']}>
            <Button gray label="components.checklist-instance-modal.add-meter-reading" />
          </div>
          <CommentOptions editable={false} />
        </div>
      </div>
    );
  };

  renderMeterRow = () => {
    const { editingField, checklistTemplateRow, draggingChecklistTemplateRow } = this.props;
    let isDragging =
      draggingChecklistTemplateRow && draggingChecklistTemplateRow.id === this.props.checklistTemplateRow.id;
    let classNames = [styles['item']];
    if (isDragging) {
      classNames = [...classNames, styles['dragging']];
    }

    if (editingField && editingField.id === checklistTemplateRow.id) {
      classNames = [...classNames, styles['editing']];
    }

    return this.props.connectDragSource(
      this.props.connectDropTarget(
        <div
          ref={this.props.innerRef}
          className={classNames.join(' ')}
          onClick={e => {
            e.stopPropagation();
            if (editingField && editingField.id === checklistTemplateRow.id) {
              this.props.cancelEditField();
            } else {
              this.props.editField(this.props.checklistTemplateRow);
            }
          }}
        >
          <DragHandle />
          <div className={styles['title-container']}>
            <div className={styles['title']}>{this.props.checklistTemplateRow.title}</div>
            {this.renderAttachments()}
          </div>
          <div className={styles['right-container']}>
            <div className={styles['add-meter-reading']}>
              {checklistTemplateRow.meter_id || checklistTemplateRow.meter_type === MeterType.Option ? (
                <Button gray label="components.checklist-instance-modal.add-meter-reading" />
              ) : (
                <span className={styles['meter-deleted']}>
                  <FormattedMessage id="components.edit-checklist-modal.meter-deleted" />
                </span>
              )}
            </div>
            <CommentOptions editable={false} />
          </div>
        </div>
      )
    );
  };

  render() {
    if (this.props.id === 'new') {
      return this.renderNewMeterRow();
    }
    return this.renderMeterRow();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editField: ChecklistEditModalOperations.editField,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const checklistTemplateRow = EntitySelectors.getChecklistTemplateRow(state, ownProps.id);
  const editingField = ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state);
  if (ownProps.id === 'new') {
    return {
      images: EntitySelectors.getImages(state, editingField.images),
      attachments: EntitySelectors.getAttachments(state, editingField.attachments),
      editingField: ChecklistEditModalSelectors.getEditingChecklistTemplateRow(state),
    };
  }
  return {
    checklistTemplateRow,
    images: EntitySelectors.getImages(state, checklistTemplateRow.images),
    attachments: EntitySelectors.getAttachments(state, checklistTemplateRow.attachments),
    draggingChecklistTemplateRow: ChecklistEditModalSelectors.getDraggingChecklistTemplateRow(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Meter);
