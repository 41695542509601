import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { RequestsSelectors } from 'state/ducks/requests';
import { LIST_TYPES } from 'sdk/Request';
import { SectionHeader } from 'views/components/Shared/General';
import ListItem from './ListItem';
import styles from './style.module.scss';

class Section extends Component {
  renderSection = section => {
    const { list } = queryString.parse(this.props.location.search);
    switch (list) {
      case LIST_TYPES.WorkOrderCreated:
        return this.renderWorkOrderDueDateSection(section);
      case LIST_TYPES.WorkOrderCompleted:
        return this.renderWorkOrderCompletedDateSection(section);
      case LIST_TYPES.Archive:
        return this.renderRequestArchivedAtDate(section);
      case LIST_TYPES.MyCreated:
        return this.renderCreatedAtDate(section);
      default:
        return this.renderCreatedAtDate(section);
    }
  };

  renderWeekAndYear = date => {
    if (moment(date).isSame(moment(), 'year')) {
      return <FormattedMessage id="screens.requests.week" values={{ week: moment(date).format('W') }} />;
    }
    return (
      <>
        <FormattedMessage
          id="screens.requests.week"
          values={{
            week: moment(date).format('W'),
          }}
        />
        <span className={styles['year']}> - {moment(date).format('Y')}</span>
      </>
    );
  };

  renderPrioritySection = priority => {
    return (
      <SectionHeader noBorderTop>
        {priority === 'none' ? (
          <FormattedMessage id="screens.requests.no-priority" />
        ) : (
          <FormattedMessage id={`resources.request.prio.${priority}`} />
        )}
      </SectionHeader>
    );
  };

  renderWorkOrderDueDateSection = firstDayOfWeek => {
    return (
      <SectionHeader noBorderTop>
        {firstDayOfWeek === 'none' ? (
          <FormattedMessage id="screens.requests.no-due-date" />
        ) : (
          this.renderWeekAndYear(firstDayOfWeek)
        )}
      </SectionHeader>
    );
  };

  renderWorkOrderCompletedDateSection = firstDayOfWeek => {
    return (
      <SectionHeader noBorderTop>
        {firstDayOfWeek === 'none' ? (
          <FormattedMessage id="screens.requests.no-completed-date" />
        ) : (
          this.renderWeekAndYear(firstDayOfWeek)
        )}
      </SectionHeader>
    );
  };

  renderRequestArchivedAtDate = firstDayOfWeek => {
    return (
      <SectionHeader noBorderTop>
        {firstDayOfWeek === 'none' ? (
          <FormattedMessage id="screens.requests.no-archived-date" />
        ) : (
          this.renderWeekAndYear(firstDayOfWeek)
        )}
      </SectionHeader>
    );
  };

  renderCreatedAtDate = firstDayOfWeek => {
    return <SectionHeader noBorderTop>{this.renderWeekAndYear(firstDayOfWeek)}</SectionHeader>;
  };

  render() {
    if (this.props.loading) {
      return <SectionHeader loading noBorderTop />;
    }
    return (
      <>
        {this.renderSection(this.props.sectionKey)}
        {this.props.requests.map(request => (
          <ListItem key={request.id} requestId={request.id} />
        ))}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  return {
    requests: RequestsSelectors.getRequestsForSection(state, ownProps.sectionKey),
  };
}

export default withRouter(connect(mapStateToProps)(Section));
