import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import moment from 'moment';
import { Button, Icon } from 'views/components/Shared/General';
import { CalendarSelectors } from 'state/ducks/calendar';
import SettingsModal from './SettingsModal';
import styles from './style.module.scss';

class Header extends PureComponent {
  state = {
    showSettingsModal: false,
  };

  renderTimeline = () => {
    const { date } = this.props;
    let monthAtStartOfCurrentWeek = moment(date).startOf('isoWeek');
    let monthAtEndOfCurrentWeek = moment(date).endOf('isoWeek');
    let month;
    if (monthAtStartOfCurrentWeek.month() === monthAtEndOfCurrentWeek.month()) {
      month = moment(monthAtStartOfCurrentWeek).format('MMMM');
    } else {
      month = `${moment(monthAtStartOfCurrentWeek).format('MMMM')} - ${moment(monthAtEndOfCurrentWeek).format(
        'MMMM'
      )}`;
    }
    return (
      <div className={styles['timeline']}>
        <span className={styles['month']}>{`${month} ${moment(date).format('YYYY')}`}</span>
        <span className={styles['separator']}> - </span>
        <span className={styles['week']}>
          <FormattedMessage id="screens.calendar.week" values={{ week: moment(date).format('w') }} />
        </span>
      </div>
    );
  };

  getTotalCount = () => {
    const { amountToPlan, amountOverdue, amountOfRequests, amountAwaitingSpareParts } = this.props;
    if (this.props.settings.spare_parts_activated)
      return amountToPlan + amountOverdue + amountOfRequests + amountAwaitingSpareParts;
    else return amountToPlan + amountOverdue + amountOfRequests;
  };

  renderHeader = () => {
    return (
      <div className={styles['header']}>
        <div className={styles['left-content']}>
          <div className={styles['title']}>
            <FormattedMessage id="screens.calendar.title" />
          </div>
          <div className={styles['time-navigation']}>
            <Button
              type="icon"
              icon={<Icon regular size={20} type="angle-left" />}
              onClick={this.props.onGoToPreviousWeek}
            />
            <Button
              type="icon"
              icon={<Icon regular size={20} type="angle-right" />}
              onClick={this.props.onGoToNextWeek}
            />
          </div>
          {this.renderTimeline()}
        </div>
        <div className={styles['right-content']}>
          <div className={styles['toolbar']}>
            {this.props.canAdministateWorkOrders ? (
              <>
                <div>
                  <Button
                    type="icon"
                    icon={<Icon regular size={15} type="plus" />}
                    onClick={this.props.onCreateWorkOrder}
                  />
                </div>
                <div>
                  <Button
                    type="icon"
                    icon={<Icon regular size={15} type="cog" />}
                    onClick={() => this.setState({ showSettingsModal: true })}
                  />
                </div>
              </>
            ) : null}

            <div className={styles['inbox-container']}>
              <Button
                type="icon"
                icon={<Icon regular size={15} type="inbox" />}
                onClick={this.props.onToggleToolbar}
              />
              {this.getTotalCount() === 0 ? null : (
                <div className={styles['number']}>{this.getTotalCount()}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDays = () => {
    const { date } = this.props;
    const monday = moment(date).isoWeekday(1);
    const tuesday = moment(date).isoWeekday(2);
    const wednesday = moment(date).isoWeekday(3);
    const thursday = moment(date).isoWeekday(4);
    const friday = moment(date).isoWeekday(5);
    const saturday = moment(date).isoWeekday(6);
    const sunday = moment(date).isoWeekday(7);
    return (
      <div className={`${styles['days']}`}>
        <div className={`${styles['days-view']}`}>
          <div className={styles['user-placeholder']} />
          <div
            className={`${styles['day-container']} ${
              moment(monday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{monday.format('dddd')}</div>
            <div className={styles['date']}>{monday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(tuesday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{tuesday.format('dddd')}</div>
            <div className={styles['date']}>{tuesday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(wednesday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{wednesday.format('dddd')}</div>
            <div className={styles['date']}>{wednesday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(thursday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{thursday.format('dddd')}</div>
            <div className={styles['date']}>{thursday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(friday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{friday.format('dddd')}</div>
            <div className={styles['date']}>{friday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(saturday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{saturday.format('dddd')}</div>
            <div className={styles['date']}>{saturday.format('D MMMM')}</div>
          </div>
          <div
            className={`${styles['day-container']} ${
              moment(sunday).isSame(moment(), 'day') ? styles['selected'] : ''
            }`}
          >
            <div>{sunday.format('dddd')}</div>
            <div className={styles['date']}>{sunday.format('D MMMM')}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let classNames = [styles['header-container']];
    if (this.props.showSideBar) classNames = [...classNames, styles['show-sidebar']];
    if (this.props.isShowingToolbarList) {
      classNames = [...classNames, styles['show-toolbar-list']];
    }
    return (
      <>
        <div className={classNames.join(' ')}>
          {this.renderHeader()}
          {this.renderDays()}
        </div>
        <SettingsModal
          date={this.props.date}
          open={this.state.showSettingsModal}
          onClose={() => this.setState({ showSettingsModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    amountToPlan: CalendarSelectors.getAmountToPlan(state),
    amountOverdue: CalendarSelectors.getAmountOverdue(state),
    settings: AuthSelectors.getSettings(state),
    canAdministateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    amountAwaitingSpareParts: CalendarSelectors.getAmountAwaitingSpareParts(state),
    amountOfRequests: CalendarSelectors.getAmountOfRequests(state),
    isShowingToolbarList: CalendarSelectors.isShowingList(state),
  };
}

export default connect(mapStateToProps)(Header);
