import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './styles.module.scss';

class MakeVendorPrimaryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  updateSparePart = () => {
    this.setState({ isSaving: true });

    this.props
      .updateSparePart(this.props.sparePart.id, { primary_vendor_id: this.props.vendorId })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      });
  };

  renderMessage = () => {
    if (this.props.fromVendor) {
      return (
        <FormattedMessage
          id="components.make-vendor-primary-modal.change-primary-vendor-text"
          values={{
            from: <span className={styles['from']}>{this.props.fromVendor.name}</span>,
            to: <span className={styles['to']}>{this.props.toVendor.name}</span>,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="components.make-vendor-primary-modal.make-primary-vendor-text"
        values={{
          to: <span className={styles['to']}>{this.props.toVendor.name}</span>,
        }}
      />
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={455}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.make-vendor-primary-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>{this.renderMessage()}</Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              label="general.save"
              loading={this.state.isSaving}
              onClick={this.updateSparePart}
            />
            <Button label="general.cancel" onClick={this.props.onClose} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  return {
    sparePart,
    fromVendor: EntitySelectors.getVendor(state, sparePart.primary_vendor_id),
    toVendor: EntitySelectors.getVendor(state, ownProps.vendorId),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeVendorPrimaryModal);
