import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import {
  BookmarkedAssetsDropdownOperations,
  BookmarkedAssetsDropdownSelectors,
} from 'state/ducks/bookmarkedAssetsDropdown';
import { bindActionCreators } from 'redux';
import { NewSelectAssetModal } from 'views/components/Asset';
import { Button, Icon, NewInlineModal } from 'views/components/Shared/General';
import { EditOperationalMaintenanceModal } from 'views/components/OperationalMaintenance';
import {
  OperationalMaintenancesOperations,
  OperationalMaintenancesUtils,
} from 'state/ducks/operationalMaintenances';
import { EntitySelectors } from 'sdk/State/entities';
import { Header } from 'views/scenes/OperationalMaintenances/components';
import styles from './style.module.scss';
import Asset from './Asset';
import { SDKReduxOperations } from 'sdk';

const listRequest = HelperFunctions.getCancelTokenForRequest();

class OperationalMaintenances extends Component {
  getInitialState = () => ({
    isFetching: true,
    showSelectAssetModal: false,
    isAddingAssetIdToUserBookmarks: null,
    showEditOperationalMaintenanceTemplateModal: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.fetchDebouncedOperationalMaintenances = debounce(() => {
      this.fetchOperationalMaintenances();
    }, 300);
  }

  componentDidMount() {
    this.props.unitializeListCount();
    this.props.fetchBookmarkedAssets(this.props.system.id).then(() => {
      this.fetchDebouncedOperationalMaintenances();
    });
  }

  componentDidUpdate(prevProps) {
    const { date: prevDate } = queryString.parse(prevProps.location.search);
    const { date } = queryString.parse(this.props.location.search);
    const changedDate = prevDate !== date;
    const changedAssetDropdownParams = prevProps.assetDropdownParams !== this.props.assetDropdownParams;
    const changedCheckedinAssetId = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    const addedBookmarkedAssets = prevProps.bookmarkedAssets.length < this.props.bookmarkedAssets.length;
    if (changedDate || addedBookmarkedAssets || changedAssetDropdownParams || changedCheckedinAssetId) {
      this.setState({ isFetching: true });
      listRequest.cancel();
      this.fetchDebouncedOperationalMaintenances();
    }
  }

  componentWillUnmount() {
    this.fetchDebouncedOperationalMaintenances.cancel();
  }

  changeUrlQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...queryString.parse(this.props.location.search),
        ...obj,
      })}`
    );
  };

  getDateFromUrl = () => {
    const { date } = queryString.parse(this.props.location.search);
    return date || moment().format('YYYY-MM-DD');
  };

  getAssetParams = () => {
    let params = {};
    if (this.props.isOperator && this.props.checkedInAssetId) {
      params = {
        ...params,
        split_assets: true,
        asset_ids: this.props.checkedInAssetId,
      };
    } else {
      if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
        params = {
          ...params,
          asset_ids: this.props.bookmarkedAssets.map(({ asset_id }) => asset_id).join(','),
          split_assets: true,
        };
      } else if (this.props.assetIdForAssetDropdown) {
        params = {
          ...params,
          asset_ids: this.props.assetIdForAssetDropdown,
          split_assets: true,
        };
      }
    }
    return params;
  };

  getParams = () => {
    let params = {
      from_date: moment(this.getDateFromUrl()).startOf('isoWeek').format('YYYY-MM-DD'),
      to_date: moment(this.getDateFromUrl()).endOf('isoWeek').format('YYYY-MM-DD'),
    };
    params = {
      ...params,
      ...this.getAssetParams(),
    };

    return params;
  };

  fetchOperationalMaintenances = () => {
    this.props
      .fetchOperationalMaintenancesForCalendar(
        this.props.system.id,
        this.getParams(),
        listRequest.getCancelTokenConfig()
      )
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          this.setState({ isFetching: false });
        }
      });
  };

  goToPreviousWeek = () => {
    this.changeUrlQueryParams({
      date: moment(this.getDateFromUrl()).subtract('1', 'week').format('YYYY-MM-DD'),
    });
  };

  goToNextWeek = () => {
    this.changeUrlQueryParams({
      date: moment(this.getDateFromUrl()).add('1', 'week').format('YYYY-MM-DD'),
    });
  };

  renderHeaderDay = momentDay => {
    let classNames = [styles['day']];
    if (moment().isSame(momentDay, 'day')) {
      classNames = [...classNames, styles['today']];
    }
    return (
      <div className={classNames.join(' ')}>
        <div>{momentDay.format('dddd')}</div>
        <div className={styles['subtitle']}>
          <span>
            <span>{momentDay.format('D')}</span>
          </span>
          <span> </span>
          <span>{momentDay.format('MMMM')}</span>
        </div>
      </div>
    );
  };

  renderCreateButton = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <Button
          fullWidth
          primary
          label="screens.operational-maintenances.list.create-button"
          onClick={() => this.setState({ showEditOperationalMaintenanceTemplateModal: true })}
        />
      );
    }
    return null;
  };

  renderHeader = () => {
    const monday = moment(this.getDateFromUrl()).isoWeekday(1);
    const tuesday = moment(this.getDateFromUrl()).isoWeekday(2);
    const wednesday = moment(this.getDateFromUrl()).isoWeekday(3);
    const thursday = moment(this.getDateFromUrl()).isoWeekday(4);
    const friday = moment(this.getDateFromUrl()).isoWeekday(5);
    const saturday = moment(this.getDateFromUrl()).isoWeekday(6);
    const sunday = moment(this.getDateFromUrl()).isoWeekday(7);
    return (
      <div className={styles['calendar-days-container']}>
        <div className={styles['left-panel']}>{this.renderCreateButton()}</div>
        <div className={styles['calendar-days']}>
          {this.renderHeaderDay(monday)}
          {this.renderHeaderDay(tuesday)}
          {this.renderHeaderDay(wednesday)}
          {this.renderHeaderDay(thursday)}
          {this.renderHeaderDay(friday)}
          {this.renderHeaderDay(saturday)}
          {this.renderHeaderDay(sunday)}
        </div>
      </div>
    );
  };

  renderAddAssetsToBookmarkButton = () => {
    if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
      return (
        <div className={styles['add-asset-container']}>
          <Button
            type="text"
            primary
            noUnderline
            label="screens.operational-maintenances.calendar.add-asset"
            onClick={() => this.setState({ showSelectAssetModal: true })}
          />
        </div>
      );
    }
    return null;
  };

  renderConfigureOperationalMaintenancesButtons = () => {
    if (this.props.canAdministrateOperationalMaintenances) {
      return (
        <div className={styles['button-container']}>
          <Link to="/operational-maintenances/templates">
            <Button gray label="screens.operational-maintenances.calendar.configure-op-maintenances" />
          </Link>

          <div className={styles['buttons-container']}>
            <div
              ref={ref => (this.inlineModalPositioningRef = ref)}
              onClick={() => {
                this.setState(prevState => ({
                  dropdownOpen: !prevState.dropdownOpen,
                }));
              }}
            >
              <Button gray icon={<Icon regular size={16} type="ellipsis-h" />} />
            </div>
            <NewInlineModal
              minWidth={250}
              positionToRef={this.inlineModalPositioningRef}
              open={this.state.dropdownOpen}
              onClose={() => this.setState({ dropdownOpen: false })}
            >
              <NewInlineModal.Dropdown>
                <NewInlineModal.Dropdown.Items>
                  <Link to="/operational-maintenances/breaks">
                    <NewInlineModal.Dropdown.Item>
                      <FormattedMessage id="screens.operational-maintenances.calendar.manage-breaks" />
                    </NewInlineModal.Dropdown.Item>
                  </Link>
                </NewInlineModal.Dropdown.Items>
              </NewInlineModal.Dropdown>
            </NewInlineModal>
          </div>
        </div>
      );
    }
    return null;
  };

  renderConfigureView = () => {
    let classNames = [styles['configure-container']];
    if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
      classNames = [...classNames, styles['showing-my-assets']];
    }
    if (this.props.bookmarkedAssets.length > 0) {
      classNames = [...classNames, styles['has-assets']];
    }
    return (
      <div className={classNames.join(' ')}>
        {this.renderAddAssetsToBookmarkButton()}
        <div className={styles['configure']}>{this.renderConfigureOperationalMaintenancesButtons()}</div>
      </div>
    );
  };

  renderAssets = () => {
    if (this.props.isOperator) {
      return <Asset assetId={this.props.checkedInAssetId} date={this.getDateFromUrl()} />;
    }
    if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.AllAssets) {
      return <Asset date={this.getDateFromUrl()} />;
    }

    if (this.props.dropdownTypeForAssetDropdown === OperationalMaintenancesUtils.MenuItem.MyAssets) {
      return this.props.bookmarkedAssets.map(({ id, asset_id }) => (
        <Asset
          shrinkable={this.props.bookmarkedAssets.length > 1}
          assetId={asset_id}
          bookmarkedAssetId={id}
          date={this.getDateFromUrl()}
        />
      ));
    }
    return <Asset assetId={this.props.assetIdForAssetDropdown} date={this.getDateFromUrl()} />;
  };

  renderHeaderContent = () => {
    return (
      <>
        <div className={styles['header']}>
          <div className={styles['dates-container']}>
            <div>
              <Header.Button.Group>
                <Header.Button onClick={() => this.goToPreviousWeek()}>
                  <Icon type="angle-left" regular />
                </Header.Button>
                <Header.Button onClick={() => this.goToNextWeek()}>
                  <Icon type="angle-right" regular />
                </Header.Button>
              </Header.Button.Group>
            </div>
            <div className={styles['dates']}>
              <div className={styles['week']}>
                <FormattedMessage
                  id="screens.production-board.operational-maintenances.week"
                  values={{ week: moment(this.getDateFromUrl()).format('w') }}
                />
              </div>
              <div className={styles['month']}>
                <span>{moment(this.getDateFromUrl()).format('MMMM')}</span>
                <span> </span>
                <span>{moment(this.getDateFromUrl()).format('YYYY')}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderAlreadyAdded = () => {
    return (
      <div className={styles['already-added-asset']}>
        <FormattedMessage id="general.added" />
      </div>
    );
  };

  renderSelectAssetButton = id => {
    return (
      <Button
        gray
        small
        label="general.choose"
        loading={this.state.isAddingAssetIdToUserBookmarks === id}
        onClick={e => {
          e.stopPropagation();
          this.setState({ isAddingAssetIdToUserBookmarks: id });
          this.props.createAssetBookmark(this.props.system.id, { asset_id: id }).then(() => {
            this.setState({ showSelectAssetModal: false, isAddingAssetIdToUserBookmarks: null });
          });
        }}
      />
    );
  };

  renderSelectAssetModal = () => {
    return (
      <NewSelectAssetModal
        hideCreateButton
        title={<FormattedMessage id="screens.operational-maintenances.calendar.add-asset" />}
        open={this.state.showSelectAssetModal}
        listItemRightComponent={asset => {
          if (this.props.bookmarkedAssets.find(({ asset_id }) => asset_id === asset.id) != null) {
            return this.renderAlreadyAdded();
          } else return this.renderSelectAssetButton(asset.id);
        }}
        onClose={() => this.setState({ showSelectAssetModal: false })}
      />
    );
  };

  renderEditOperationalMaintenanceModal = () => {
    return (
      <EditOperationalMaintenanceModal
        open={this.state.showEditOperationalMaintenanceTemplateModal}
        onCreated={templateId => this.props.history.push(`/operational-maintenances/templates/${templateId}`)}
        onClose={() => this.setState({ showEditOperationalMaintenanceTemplateModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['view-container']}>
          <Header>{this.renderHeaderContent()}</Header>
          <div className={styles['content-wrapper']}>
            <div className={styles['container']}>
              <div className={styles['wrapper']}>
                {this.renderHeader()}
                <div className={styles['content-container']}>
                  <div className={styles['content']}>
                    {this.renderAssets()}
                    {this.renderConfigureView()}
                  </div>
                  <div className={`${styles['loader']} ${this.state.isFetching ? styles['show'] : ''}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderSelectAssetModal()}
        {this.renderEditOperationalMaintenanceModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOperationalMaintenancesForCalendar:
        OperationalMaintenancesOperations.fetchOperationalMaintenancesForCalendar,
      fetchBookmarkedAssets: BookmarkedAssetsDropdownOperations.fetchBookmarkedAssets,
      createAssetBookmark: SDKReduxOperations.createAssetBookmark,
      unitializeListCount: OperationalMaintenancesOperations.unitializeListCount,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  return {
    system: AuthSelectors.getCurrentSystem(state),
    currentUser: AuthSelectors.getCurrentUser(state),
    canAdministrateOperationalMaintenances: AuthSelectors.canAdministrateOperationalMaintenances(state),
    asset: EntitySelectors.getAsset(state, id),
    bookmarkedAssets: BookmarkedAssetsDropdownSelectors.getBookmarkedAssets(state),
    dropdownTypeForAssetDropdown: BookmarkedAssetsDropdownSelectors.getDropdownType(state),
    assetIdForAssetDropdown: BookmarkedAssetsDropdownSelectors.getAssetId(state),
    assetDropdownParams: BookmarkedAssetsDropdownSelectors.getParams(state),
    isOperator: AuthSelectors.isOperator(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationalMaintenances));
