import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { Field, Button, DatePicker, FormattedNumber } from 'views/components/Shared/General';
import { ConnectedFieldErrorWrapper } from 'views/components/General';
import {
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  getElapsedMinutesFromHourAndMinutes,
  getEstimatedDueDateFromMeterAsMoment,
} from 'sdk/WorkOrder';
import { NewWorkOrderSelectors } from 'state/ducks/newWorkOrder';
import { ErrorOperations, ErrorSelectors } from 'state/ducks/error';
import { Grid } from 'views/components/Shared/Layout';
import { EditMeterDueDateModal } from 'views/components/Meter';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class DueDate extends Component {
  constructor(props) {
    super(props);
    let showAlreadyCompletedWarning = false;
    if (props.editingWorkOrder.date && this.dateIsBeforeToday(props.editingWorkOrder.date)) {
      showAlreadyCompletedWarning = true;
    }
    this.state = {
      date: null,
      showAlreadyCompletedWarning,
      showMeterDueDateModal: false,
    };
  }

  dateIsBeforeToday = date => {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days') < 0;
  };

  selectDate = date => {
    this.setState({
      date,
      showAlreadyCompletedWarning: false,
    });
    this.props.onSelectDate(date);
  };

  renderEstimatedDueDate = () => {
    const estimatedDate = getEstimatedDueDateFromMeterAsMoment({
      meter_value: this.props.meter.value,
      due_meter_value: this.props.editingWorkOrder.due_meter_value,
      average_daily_usage: this.props.meter.average_daily_usage,
      value_last_updated: this.props.meter.value_last_updated,
    });
    if (estimatedDate == null) {
      return null;
    }

    return (
      <Field
        singleRow
        view
        fontSize={12}
        label={<FormattedMessage id="resources.work-order.estimated-meter-due-date" />}
      >
        {estimatedDate.format('LL')}
      </Field>
    );
  };

  renderAverageDailyUsage = () => {
    if (this.props.meter.average_daily_usage) {
      return (
        <Field
          singleRow
          view
          fontSize={12}
          label={<FormattedMessage id="screens.work-order.meter-average-increase" />}
        >
          <FormattedMessage
            id="screens.work-order.meter-average-increase-value"
            values={{
              value: (
                <FormattedNumber
                  value={this.props.meter.average_daily_usage}
                  unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                />
              ),
            }}
          />
        </Field>
      );
    }
    return null;
  };

  renderTolerance = () => {
    if (new Decimal(this.props.editingWorkOrder.due_meter_tolerance || 0).greaterThan(new Decimal(0))) {
      return (
        <Field
          singleRow
          view
          fontSize={12}
          label={<FormattedMessage id="screens.work-order.meter-tolerance" />}
        >
          <FormattedNumber
            value={this.props.editingWorkOrder.due_meter_tolerance}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </Field>
      );
    }
    return null;
  };

  renderExtraMeterValues = () => {
    if (this.props.editingWorkOrder.date) {
      return (
        <>
          <Field singleRow view fontSize={12} label={<FormattedMessage id="resources.work-order.due-date" />}>
            <FormattedNumber
              value={this.props.editingWorkOrder.due_meter_value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
          <Field
            singleRow
            view
            fontSize={12}
            label={<FormattedMessage id="screens.work-order.meter-current-value" />}
          >
            <FormattedNumber
              value={this.props.meter.value}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          </Field>
        </>
      );
    }
    return (
      <>
        {this.renderTolerance()}
        <Field
          singleRow
          view
          fontSize={12}
          label={<FormattedMessage id="screens.work-order.meter-current-value" />}
        >
          <FormattedNumber
            value={this.props.meter.value}
            unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
          />
        </Field>
        {this.renderAverageDailyUsage()}
        {this.renderEstimatedDueDate()}
      </>
    );
  };

  renderMeterValue = () => {
    if (this.props.editingWorkOrder.date && !this.props.editingWorkOrderIsRecurring) {
      return (
        <Field
          label={<FormattedMessage id="resources.work-order.due-date" />}
          description={this.renderExtraMeterValues()}
        >
          <ConnectedFieldErrorWrapper field="date" position="top">
            {hasError => (
              <>
                <div onClick={() => this.setState({ showMeterDueDateModal: true })}>
                  <Field.Resource
                    error={hasError}
                    value={moment(this.props.editingWorkOrder.date).format('LL')}
                    clearable={false}
                  />
                </div>
              </>
            )}
          </ConnectedFieldErrorWrapper>
        </Field>
      );
    }
    return (
      <Field
        label={<FormattedMessage id="resources.work-order.due-date" />}
        description={this.renderExtraMeterValues()}
      >
        <ConnectedFieldErrorWrapper field="date" position="top">
          {hasError => (
            <>
              <div onClick={() => this.setState({ showMeterDueDateModal: true })}>
                <Field.Resource
                  error={hasError}
                  value={
                    this.props.editingWorkOrder.due_meter_value ? (
                      <FormattedNumber
                        value={this.props.editingWorkOrder.due_meter_value}
                        unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
                      />
                    ) : null
                  }
                  clearable={false}
                />
              </div>
            </>
          )}
        </ConnectedFieldErrorWrapper>
      </Field>
    );
  };

  renderDateValue = () => {
    return (
      <Field label={<FormattedMessage id="resources.work-order.due-date" />}>
        <ConnectedFieldErrorWrapper field="date" position="top">
          {hasError => (
            <Field.Date
              error={hasError}
              onChangeDate={date => {
                if (this.dateIsBeforeToday(date)) {
                  this.setState({
                    date,
                    showAlreadyCompletedWarning: true,
                  });
                  this.props.onSelectDate(date);
                } else {
                  this.selectDate(date);
                }
              }}
              value={this.props.editingWorkOrder.date}
              footerComponent={
                <DatePicker.Footer
                  showClear={this.props.editingWorkOrder.date != null}
                  onClear={() => this.selectDate(null)}
                />
              }
            />
          )}
        </ConnectedFieldErrorWrapper>
      </Field>
    );
  };

  renderContent = () => {
    if (this.props.editingWorkOrder.meter_id) {
      return this.renderMeterValue();
    }
    return this.renderDateValue();
  };

  render() {
    return (
      <>
        <Grid.Row>
          <Grid.Column md={7}>{this.renderContent()}</Grid.Column>
          <Grid.Column md={5}>
            <Field label={<FormattedMessage id="resources.work-order.estimated-time" />}>
              <div className={styles['elapsed-time-container']}>
                <Field.Number
                  error={this.props.fieldErrors.estimated_minutes}
                  value={getHoursFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)}
                  onBlur={hours => {
                    if (this.props.fieldErrors.estimated_minutes) {
                      this.props.removeFieldsFromErrors(['estimated_minutes']);
                    }
                    this.props.onChangeValue({
                      estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                        hours,
                        getMinutesFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)
                      ),
                    });
                  }}
                  rightLabel={<FormattedMessage id="screens.work-order.costs.time-cost-modal.hours-short" />}
                />
                <Field.Number
                  error={this.props.fieldErrors.estimated_minutes}
                  value={getMinutesFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes)}
                  onBlur={minutes => {
                    if (this.props.fieldErrors.estimated_minutes) {
                      this.props.removeFieldsFromErrors(['estimated_minutes']);
                    }
                    this.props.onChangeValue({
                      estimated_minutes: getElapsedMinutesFromHourAndMinutes(
                        getHoursFromElapsedMinutes(this.props.editingWorkOrder.estimated_minutes),
                        minutes
                      ),
                    });
                  }}
                  rightLabel={
                    <FormattedMessage id="screens.work-order.costs.time-cost-modal.minutes-short" />
                  }
                />
              </div>
            </Field>
          </Grid.Column>
        </Grid.Row>
        {this.state.showAlreadyCompletedWarning ? (
          <Grid.Row>
            <Grid.Column>
              <div className={styles['already-completed-warning']}>
                <p className={styles['title']}>
                  <FormattedMessage id="components.new-work-order-modal.already-completed-title" />
                </p>
                <p className={styles['subtitle']}>
                  <FormattedMessage id="components.new-work-order-modal.already-completed-subtitle" />
                </p>
                <Button.Group>
                  <Button
                    primary
                    label="components.new-work-order-modal.already-completed-button"
                    onClick={() => {
                      this.setState({ showAlreadyCompletedWarning: false });
                      this.props.onCompleteBeforeRegister(this.state.date);
                    }}
                  />
                  <Button
                    label="components.new-work-order-modal.plan-work-order-button"
                    onClick={() => {
                      this.setState({ showAlreadyCompletedWarning: false });
                    }}
                  />
                </Button.Group>
              </div>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {this.state.showAlreadyCompletedWarning ? <Grid.Separator /> : null}
        <EditMeterDueDateModal
          hideExactDate={this.props.editingWorkOrderIsRecurring}
          editingWorkOrder={this.props.editingWorkOrder}
          open={this.state.showMeterDueDateModal}
          onSave={params => {
            this.setState({ showMeterDueDateModal: false });
            this.props.onChangeValue({
              ...params,
            });
          }}
          onClose={() => this.setState({ showMeterDueDateModal: false })}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFieldsFromErrors: ErrorOperations.removeFieldsFromErrors,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  let meter = EntitySelectors.getMeter(state, ownProps.editingWorkOrder.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    fieldErrors: ErrorSelectors.getFieldErrors(state),
    meter,
    meterUnit,
    editingWorkOrderIsRecurring: NewWorkOrderSelectors.editingWorkOrderIsRecurring(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DueDate);

DueDate.propTypes = {
  editingWorkOrder: PropTypes.object,
  onCompleteBeforeRegister: PropTypes.func,
  onSelectDate: PropTypes.func,
  onChangeValue: PropTypes.func,
};

DueDate.defaultProps = {
  editingWorkOrder: null,
  onCompleteBeforeRegister: () => {},
  onSelectDate: () => {},
  onChangeValue: () => {},
};
