import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import {
  getMinutesFromElapsedMinutes,
  getHoursFromElapsedMinutes,
  getElapsedMinutesFromHourAndMinutes,
} from 'sdk/WorkOrder';
import styles from './style.module.scss';

export default class EstimatedTimeField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimatedHours: getHoursFromElapsedMinutes(props.estimatedMinutes) || '',
      estimatedMinutes: getMinutesFromElapsedMinutes(props.estimatedMinutes) || '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.estimatedMinutes !== this.props.estimatedMinutes) {
      this.setState({
        estimatedHours: getHoursFromElapsedMinutes(this.props.estimatedMinutes) || '',
        estimatedMinutes: getMinutesFromElapsedMinutes(this.props.estimatedMinutes) || '',
      });
    }
  }

  render() {
    return (
      <div className={styles['fields']}>
        <div className={styles['field']}>
          <Field.Number
            autoFocus={this.props.autoFocus}
            value={this.state.estimatedHours}
            onChange={estimatedHours => this.setState({ estimatedHours })}
            onBlur={estimatedHours =>
              this.setState({ estimatedHours }, () => {
                this.props.onChange(
                  getElapsedMinutesFromHourAndMinutes(this.state.estimatedHours, this.state.estimatedMinutes)
                );
              })
            }
            rightLabel={<FormattedMessage id="components.estimated-time-inline-modal.hours-short" />}
          />
        </div>
        <div className={styles['field']}>
          <Field.Number
            value={this.state.estimatedMinutes}
            onChange={estimatedMinutes => this.setState({ estimatedMinutes })}
            onBlur={estimatedMinutes =>
              this.setState({ estimatedMinutes }, () => {
                this.props.onChange(
                  getElapsedMinutesFromHourAndMinutes(this.state.estimatedHours, this.state.estimatedMinutes)
                );
              })
            }
            rightLabel={<FormattedMessage id="components.estimated-time-inline-modal.minutes-short" />}
          />
        </div>
      </div>
    );
  }
}

EstimatedTimeField.propTypes = {
  autoFocus: PropTypes.bool,
  estimatedMinutes: PropTypes.number,
};

EstimatedTimeField.defaultProps = {
  autoFocus: true,
  estimatedMinutes: null,
};
