import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import queryString from 'query-string';
import { HelperFunctions } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Header } from 'views/scenes/OperationalMaintenances/components';
import { Button, NewInlineModal, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';
import { LIST_TYPES } from '../';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      queryParams: queryParams,
    };
  }

  componentDidUpdate(prevProps) {
    const oldQueryParams = queryString.parse(prevProps.location.search);
    const queryParams = queryString.parse(this.props.location.search);
    if (!isEqual(oldQueryParams, queryParams)) {
      this.setState({
        queryParams: queryParams,
      });
    }
  }

  getSelectedDate = () => {
    const { date } = this.state.queryParams;
    return date || moment().tz(this.props.system.timezone).format('YYYY-MM-DD');
  };

  getSelectedList = () => {
    const { list } = this.state.queryParams;
    return list || LIST_TYPES.Week;
  };

  changeUrlQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...this.state.queryParams,
        ...obj,
      })}`
    );
  };

  goToPreviousTimespan = () => {
    const list = this.getSelectedList();
    let per = 'day';
    if (list === LIST_TYPES.Week) {
      per = 'week';
    }
    if (list === LIST_TYPES.Month) {
      per = 'month';
    }
    this.changeUrlQueryParams({
      date: moment(this.getSelectedDate()).subtract('1', per).format('YYYY-MM-DD'),
    });
  };

  goToNextTimespan = () => {
    const list = this.getSelectedList();
    let per = 'day';
    if (list === LIST_TYPES.Week) {
      per = 'week';
    }
    if (list === LIST_TYPES.Month) {
      per = 'month';
    }
    this.changeUrlQueryParams({ date: moment(this.getSelectedDate()).add('1', per).format('YYYY-MM-DD') });
  };

  renderListFieldSelectedValue = () => {
    const list = this.getSelectedList();
    return this.renderListFieldValue(list);
  };

  renderListFieldValue = list => {
    switch (list) {
      case LIST_TYPES.Day:
        return <FormattedMessage id="screens.asset.operational-maintenances.list-field.options.day" />;
      case LIST_TYPES.Week:
        return <FormattedMessage id="screens.asset.operational-maintenances.list-field.options.week" />;
      case LIST_TYPES.Month:
        return <FormattedMessage id="screens.asset.operational-maintenances.list-field.options.month" />;
      default:
        return null;
    }
  };

  renderListField = () => (
    <div className={styles['list-field-container']}>
      <span className={styles['label']}>
        <FormattedMessage id="screens.asset.operational-maintenances.list-field.label" />
        <span>:</span>
      </span>
      <div
        onClick={() => {
          this.setState(prevState => ({
            listFieldDropdownOpen: !prevState.listFieldDropdownOpen,
          }));
        }}
        ref={ref => (this.inlineModalPositioningRef = ref)}
      >
        <Header.Button.Group>
          <Header.Button selected caret>
            {this.renderListFieldSelectedValue()}
          </Header.Button>
        </Header.Button.Group>
      </div>
      <NewInlineModal
        positionToRef={this.inlineModalPositioningRef}
        open={this.state.listFieldDropdownOpen}
        minWidth={200}
        onClose={() => this.setState({ listFieldDropdownOpen: false })}
      >
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item
              selected={this.getSelectedList() === LIST_TYPES.Day}
              onClick={() => {
                this.setState({ listFieldDropdownOpen: false });
                this.changeUrlQueryParams({ list: LIST_TYPES.Day });
              }}
            >
              {this.renderListFieldValue(LIST_TYPES.Day)}
            </NewInlineModal.Dropdown.Item>
            <NewInlineModal.Dropdown.Item
              selected={this.getSelectedList() === LIST_TYPES.Week}
              onClick={() => {
                this.setState({ listFieldDropdownOpen: false });
                this.changeUrlQueryParams({ list: LIST_TYPES.Week });
              }}
            >
              {this.renderListFieldValue(LIST_TYPES.Week)}
            </NewInlineModal.Dropdown.Item>

            <NewInlineModal.Dropdown.Item
              selected={this.getSelectedList() === LIST_TYPES.Month}
              onClick={() => {
                this.setState({ listFieldDropdownOpen: false });
                this.changeUrlQueryParams({ list: LIST_TYPES.Month });
              }}
            >
              {this.renderListFieldValue(LIST_TYPES.Month)}
            </NewInlineModal.Dropdown.Item>
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      </NewInlineModal>
    </div>
  );

  renderDates = () => {
    const { from_date, to_date } = queryString.parse(this.props.location.search);
    const list = this.getSelectedList();

    if (list === LIST_TYPES.Day) {
      return <div className={styles['value']}>{moment(this.getSelectedDate()).format('LL')}</div>;
    }
    if (list === LIST_TYPES.Week) {
      return (
        <div className={styles['value']}>
          <FormattedMessage
            id="calendar.week"
            values={{ week: moment(this.getSelectedDate()).format('w') }}
          />
        </div>
      );
    }
    if (list === LIST_TYPES.Month) {
      return (
        <div className={styles['value']}>
          {moment(this.getSelectedDate()).format('MMMM')} {moment(this.getSelectedDate()).format('YYYY')}
        </div>
      );
    }
    if (list === LIST_TYPES.Custom) {
      return (
        <div className={styles['value']}>
          <span>{moment(from_date).format('LL')}</span>
          <span> - </span>
          <span>{moment(to_date).format('LL')}</span>
        </div>
      );
    }
  };

  renderDateField = () => (
    <div className={styles['date-field-container']}>
      {this.renderDates()}
      <div>
        <Header.Button.Group>
          <Header.Button onClick={() => this.goToPreviousTimespan()}>
            <Icon type="angle-left" regular />
          </Header.Button>
          <Header.Button onClick={() => this.goToNextTimespan()}>
            <Icon type="angle-right" regular />
          </Header.Button>
        </Header.Button.Group>
      </div>
    </div>
  );

  render() {
    return (
      <div className={styles['toolbar']}>
        <div className={styles['left-content']}>{this.renderListField()}</div>
        <div className={styles['right-content']}>
          {this.renderDateField()}
          <div className={styles['separator']} />
          <Link
            to={{
              pathname: '/operational-maintenances',
              search: `list=calendar&asset_id=${this.props.match.params.id}`,
            }}
          >
            <Button gray label="screens.asset.operational-maintenances.go-to-calendar" />
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default withRouter(connect(mapStateToProps)(Toolbar));
