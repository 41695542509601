import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Route, Switch, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { SDKReduxOperations } from 'sdk';
import { ToastMessage } from 'views/components/Shared/Layout';
import { Section, Option, ManageTemplateFieldsModal } from 'views/scenes/Settings/components';
import { Button, Toggle, ConfirmModal, WhiteCard } from 'views/components/Shared/General';
import Fields from './Fields';
import Units from './Units';
import StorageLocations from './StorageLocations';
import styles from './style.module.scss';
import SparePartTypeModal from 'views/components/SparePart/SparePartTypeModal';

class SparePart extends Component {
  state = {
    isActivatingSpareParts: false,
    showManageTemplateFieldsModal: false,
    showActivateSparePartsModal: false,
    showDeactivateSparePartsModal: false,
    isSavingDeactivateSparePartsModal: false,
    isSavingActivateSparePartsModal: false,
    showSparePartTypesModal: false,
  };
  updateSettings = params => {
    this.props
      .updateSettings(this.props.system.id, params)
      .then(() => {
        this.setState({
          showActivateSparePartsModal: false,
          showDeactivateSparePartsModal: false,
          isSavingDeactivateSparePartsModal: false,
          isSavingActivateSparePartsModal: false,
        });
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
      })
      .catch(() => {
        this.setState({
          showActivateSparePartsModal: false,
          showDeactivateSparePartsModal: false,
          isSavingDeactivateSparePartsModal: false,
          isSavingActivateSparePartsModal: false,
        });
      });
  };

  renderActivateSparePartsModal = () => (
    <ConfirmModal
      open={this.state.showActivateSparePartsModal}
      title={<FormattedMessage id="screens.settings.spare-parts.activate-modal-title" />}
      message={<FormattedMessage id="screens.settings.spare-parts.description" />}
      confirmButtonText="general.activate"
      confirmIsLoading={this.state.isSavingActivateSparePartsModal}
      onConfirm={() => {
        if (this.state.isSavingActivateSparePartsModal) {
          return;
        }
        this.setState({ isSavingActivateSparePartsModal: true });
        this.updateSettings({ spare_parts_activated: true });
      }}
      onCancel={() => {
        this.setState({ showActivateSparePartsModal: false });
      }}
    />
  );

  renderDeactivateSparePartsModal = () => (
    <ConfirmModal
      destructive
      open={this.state.showDeactivateSparePartsModal}
      title={<FormattedMessage id="screens.settings.spare-parts.deactivate-modal-title" />}
      message={<FormattedMessage id="screens.settings.spare-parts.description" />}
      confirmButtonText="general.deactivate"
      confirmIsLoading={this.state.isSavingDeactivateSparePartsModal}
      onConfirm={() => {
        if (this.state.isSavingDeactivateSparePartsModal) {
          return;
        }
        this.setState({ isSavingDeactivateSparePartsModal: true });
        this.updateSettings({ spare_parts_activated: false });
      }}
      onCancel={() => {
        this.setState({ showDeactivateSparePartsModal: false });
      }}
    />
  );

  renderSparePartTypesModal = () => (
    <SparePartTypeModal
      open={this.state.showSparePartTypesModal}
      onClose={() => {
        this.setState({ showSparePartTypesModal: false });
      }}
    />
  );

  renderRootSettings = () => (
    <>
      <Section
        header
        title={<FormattedMessage id="screens.settings.spare-parts.title" />}
        subtitle={<FormattedMessage id="screens.settings.spare-parts.description" />}
        rightComponent={
          <Toggle
            checked={this.props.settings.spare_parts_activated}
            loading={this.state.isActivatingSpareParts}
            onClick={() => {
              if (this.props.settings.spare_parts_activated) {
                this.setState({ showDeactivateSparePartsModal: true });
              } else {
                this.setState({ showActivateSparePartsModal: true });
              }
            }}
          />
        }
      />
      <div className={styles['options']}>
        {this.props.settings.spare_parts_activated ? (
          <Option.Container>
            <Option
              title={<FormattedMessage id="screens.settings.spare-parts.types.title" />}
              subtitle={<FormattedMessage id="screens.settings.spare-parts.types.subtitle" />}
              rightContainer={
                <Button
                  primary
                  label="general.manage"
                  onClick={() => this.setState({ showSparePartTypesModal: true })}
                />
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.spare-parts.template-fields.title" />}
              subtitle={<FormattedMessage id="screens.settings.spare-parts.template-fields.subtitle" />}
              rightContainer={
                <Button
                  primary
                  label="general.manage"
                  onClick={() => this.setState({ showManageTemplateFieldsModal: true })}
                />
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.spare-parts.units.title" />}
              subtitle={<FormattedMessage id="screens.settings.spare-parts.units.subtitle" />}
              rightContainer={
                <Link to="/settings/spare-parts/units">
                  <Button primary label="general.manage" />
                </Link>
              }
            />
            <Option
              title={<FormattedMessage id="screens.settings.spare-parts.storage.title" />}
              subtitle={<FormattedMessage id="screens.settings.spare-parts.storage.subtitle" />}
              rightContainer={
                <Link to="/settings/spare-parts/storage">
                  <Button primary label="general.manage" />
                </Link>
              }
            />
          </Option.Container>
        ) : null}
      </div>
    </>
  );

  renderManageTemplateFieldsModal = () => {
    return (
      <ManageTemplateFieldsModal
        open={this.state.showManageTemplateFieldsModal}
        onClose={() => this.setState({ showManageTemplateFieldsModal: false })}
        templateType="spare_part"
      />
    );
  };

  render() {
    return (
      <WhiteCard>
        <Switch>
          <Route exact path="/settings/spare-parts" render={() => this.renderRootSettings()} />
          <Route exact path="/settings/spare-parts/fields" component={Fields} />
          <Route
            exact
            path="/settings/spare-parts/units"
            render={props => <Units fromSparePart {...props} />}
          />
          <Route exact path="/settings/spare-parts/storage" component={StorageLocations} />
        </Switch>
        {this.renderActivateSparePartsModal()}
        {this.renderSparePartTypesModal()}
        {this.renderManageTemplateFieldsModal()}
        {this.renderDeactivateSparePartsModal()}
      </WhiteCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSettings: SDKReduxOperations.updateSettings,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SparePart));
