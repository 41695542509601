import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { normalizeSparePart } from 'sdk/Schemas';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations, API, HelperFunctions } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { Button, Field, Loader } from 'views/components/Shared/General';
import DuplicateSparePartsModal from './DuplicateSparePartsModal';
import styles from './styles.module.scss';

class EditSparePartModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    title: '',
    article_number: '',
    isCheckingDuplicateArticleNumber: false,
    showArticleNumberAlreadyExistsWarning: false,
    duplicateSparePartIds: [],
    showDuplicateSparePartsModal: false,
    showTitleRequiredError: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        title: this.props.sparePart.title,
        article_number: this.props.sparePart.article_number,
      });
    }
  }

  checkDuplicatedArticleNumber = article_number => {
    const params = {
      article_number: {
        [HelperFunctions.FILTER_COMPARABLES.Exact]: article_number,
      },
    };
    API.listSpareParts(this.props.currentSystem.id, params).then(({ data }) => {
      const { entities, result: duplicateSparePartIds } = normalizeSparePart(data);
      this.props.updateEntities(entities);
      this.setState({
        isCheckingDuplicateArticleNumber: false,
        showArticleNumberAlreadyExistsWarning: data.length > 0,
        duplicateSparePartIds,
      });
    });
  };

  save = () => {
    const { title, article_number } = this.state;
    if (title.length === 0) {
      this.setState({ showTitleRequiredError: true });
      return;
    }
    this.setState({ isSaving: true });
    this.props
      .updateSparePart(this.props.sparePart.id, { title, article_number })
      .then(() => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderArticleNumberAlreadyExistsWarning = () => {
    if (this.state.showArticleNumberAlreadyExistsWarning) {
      return (
        <div className={styles['article-number-already-exists-warning-container']}>
          <div className={styles['article-number-exists-warning']}>
            <FormattedMessage id="components.new-spare-part-modal.article-number-already-exists-warning" />
          </div>
          <Button.Group>
            <Button
              gray
              small
              onClick={() => this.setState({ showDuplicateSparePartsModal: true })}
              label="components.new-spare-part-modal.show-duplicates"
            />
            <Button
              gray
              small
              onClick={() => this.setState({ showArticleNumberAlreadyExistsWarning: false })}
              label="components.new-spare-part-modal.ignore"
            />
          </Button.Group>
        </div>
      );
    }
    return null;
  };

  renderDuplicatedSparePartsModal = () => {
    return (
      <DuplicateSparePartsModal
        open={this.state.showDuplicateSparePartsModal}
        number={this.state.article_number}
        ids={this.state.duplicateSparePartIds}
        onClose={() => this.setState({ showDuplicateSparePartsModal: false })}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={500}>
          <Modal.Header
            ignoreLine
            title={<FormattedMessage id="screens.spare-part.edit-spare-part" />}
            onClose={this.props.onClose}
          />
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Field view={false} label={<FormattedMessage id="resources.spare-part.title" />}>
                    <Field.Text
                      autoFocus
                      error={this.state.showTitleRequiredError}
                      value={this.state.title}
                      onChange={title => {
                        if (title && this.state.showTitleRequiredError) {
                          this.setState({ showTitleRequiredError: false });
                        }
                        this.setState({ title });
                      }}
                    />
                  </Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Field view={false} label={<FormattedMessage id="resources.spare-part.article-number" />}>
                    <Field.Text
                      value={this.state.article_number}
                      rightLabel={this.state.isCheckingDuplicateArticleNumber ? <Loader tiny /> : null}
                      onChange={article_number => {
                        this.setState({ article_number });
                      }}
                      onBlur={article_number => {
                        if (article_number && article_number !== this.props.sparePart.article_number) {
                          this.setState({ isCheckingDuplicateArticleNumber: true });
                          this.checkDuplicatedArticleNumber(article_number);
                        } else {
                          this.setState({
                            isCheckingDuplicateArticleNumber: false,
                            showArticleNumberAlreadyExistsWarning: false,
                          });
                        }
                      }}
                    />
                  </Field>
                  {this.renderArticleNumberAlreadyExistsWarning()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Footer>
            <Button.Group>
              <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
              <Button label="general.cancel" onClick={this.props.onClose} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.renderDuplicatedSparePartsModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSparePartModal);
