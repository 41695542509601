import React, { Component } from 'react';
import { CardGrid } from 'views/components/Shared/Layout';
import DescriptionContainer from './DescriptionContainer';
import AttachmentsContainer from './AttachmentsContainer';
import Checklist from './Checklist';
import ReservedSpareParts from './ReservedSpareParts';
import ImagesContainer from './ImagesContainer';

export default class Information extends Component {
  render() {
    return (
      <CardGrid>
        <CardGrid.Column>
          <DescriptionContainer />
          <Checklist />
          <ReservedSpareParts />
          <AttachmentsContainer />
          <ImagesContainer />
        </CardGrid.Column>
      </CardGrid>
    );
  }
}
