import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { API, SDKReduxOperations } from 'sdk';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { AuthSelectors } from 'state/ducks/auth';
import { UserType } from 'sdk/SystemAccess';
import { normalizeUser } from 'sdk/Schemas';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { Field, Button, NewInlineModal } from 'views/components/Shared/General';
import { UsersOperations } from 'state/ducks/users';
import UserIsArchivedBanner from '../UserIsArchivedBanner';
import styles from './style.module.scss';

class UserPermissionModal extends Component {
  getInitialState = () => ({
    isFetching: false,
    isSaving: false,
    email: this.props.email || '',
    permissionProfiles: [],
    currentPermissionProfile: null,
    admin: false,
    operator: false,
    production_supervisor: false,
    view_only: false,
    work_requester: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        admin: this.props.systemAccess.user_type === UserType.SystemAdmin,
        operator: this.props.systemAccess.user_type === UserType.Operator,
        production_supervisor: this.props.systemAccess.user_type === UserType.ProductionSupervisor,
        view_only: this.props.systemAccess.user_type === UserType.ViewOnly,
        work_requester: this.props.systemAccess.user_type === UserType.WorkRequester,
        currentPermissionProfile: this.props.permissionProfile,
        isFetching: true,
      });
      API.listPermissionProfiles(this.props.currentSystem.id).then(res => {
        const permissionProfiles = res.data;
        this.setState({
          permissionProfiles,
          isFetching: false,
        });
      });
    }
  }

  selectPermission = params => {
    this.setState({
      currentPermissionProfile: null,
      showDropDown: false,
      admin: false,
      operator: false,
      production_supervisor: false,
      view_only: false,
      work_requester: false,
      ...params,
    });
  };

  updatePermissionProfile = () => {
    const { admin, operator, work_requester, production_supervisor, view_only, currentPermissionProfile } =
      this.state;
    let data = {};
    if (admin) {
      data = {
        ...data,
        user_type: UserType.SystemAdmin,
      };
    } else if (operator) {
      data = {
        ...data,
        user_type: UserType.Operator,
      };
    } else if (production_supervisor) {
      data = {
        ...data,
        user_type: UserType.ProductionSupervisor,
      };
    } else if (view_only) {
      data = {
        ...data,
        user_type: UserType.ViewOnly,
      };
    } else if (work_requester) {
      data = {
        ...data,
        user_type: UserType.WorkRequester,
      };
    } else {
      data = {
        ...data,
        user_type: UserType.PermissionProfile,
        permission_profile_id: currentPermissionProfile.id,
      };
    }
    this.setState({ isSaving: true });
    this.props
      .updateSystemAccess(this.props.systemAccess.id, data)
      .then(res => {
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        const { data } = res;
        this.props.fetchAndSetAmountOfPayingUsers(this.props.currentSystem.id);
        const { entities } = normalizeUser(data);
        this.props.updateEntities(entities);

        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderSelectPermission = () => {
    const { admin, operator, production_supervisor, work_requester, view_only, currentPermissionProfile } =
      this.state;
    if (admin) {
      return <FormattedMessage id="resources.user.admin" />;
    }
    if (production_supervisor) {
      return <FormattedMessage id="resources.user.production-supervisor" />;
    }
    if (view_only) {
      return <FormattedMessage id="resources.user.view-only" />;
    }
    if (work_requester) {
      return <FormattedMessage id="resources.user.work-requester" />;
    }
    if (operator) return <FormattedMessage id="screens.users.user-modal.actions.permission.requestor" />;
    return currentPermissionProfile ? currentPermissionProfile.title : null;
  };

  renderProductionSupervisorMenuItem = () => {
    if (this.props.settings.production_board_activated) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.selectPermission({
              production_supervisor: true,
            });
          }}
          selected={this.state.production_supervisor}
        >
          <FormattedMessage id="resources.user.production-supervisor" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderOperatorDropdownMenuItem = () => {
    if (this.props.settings.production_board_activated) {
      return (
        <NewInlineModal.Dropdown.Item
          onClick={() => {
            this.selectPermission({
              operator: true,
            });
          }}
          selected={this.state.operator}
        >
          <FormattedMessage id="resources.user.operator" />
        </NewInlineModal.Dropdown.Item>
      );
    }
    return null;
  };

  renderDropdownContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({
                admin: true,
              });
            }}
            selected={this.state.admin}
          >
            <FormattedMessage id="resources.user.admin" />
          </NewInlineModal.Dropdown.Item>
          {this.renderProductionSupervisorMenuItem()}
          {this.renderOperatorDropdownMenuItem()}
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({
                work_requester: true,
              });
            }}
            selected={this.state.work_requester}
          >
            <FormattedMessage id="resources.user.work-requester" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            onClick={() => {
              this.selectPermission({ view_only: true });
            }}
            selected={this.state.view_only}
          >
            <FormattedMessage id="resources.user.view-only" />
          </NewInlineModal.Dropdown.Item>
          {this.state.permissionProfiles.length === 0 ? null : (
            <React.Fragment>
              <NewInlineModal.Dropdown.Separator />
              <p className={styles['custom-permissions']}>
                <FormattedMessage id="screens.users.user-modal.actions.permission.permission-profiles" />
              </p>
              {this.state.permissionProfiles.map(permissionProfile => {
                return (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.selectPermission({
                        currentPermissionProfile: permissionProfile,
                      });
                    }}
                    selected={
                      this.state.currentPermissionProfile &&
                      this.state.currentPermissionProfile.id === permissionProfile.id
                    }
                  >
                    <span>{permissionProfile ? permissionProfile.title : null}</span>
                  </NewInlineModal.Dropdown.Item>
                );
              })}
            </React.Fragment>
          )}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  renderFields = () => {
    return (
      <>
        <Field edit label={<FormattedMessage id="screens.users.user-modal.actions.permission.permission" />}>
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() =>
              this.setState(prevState => ({
                showDropDown: !prevState.showDropDown,
              }))
            }
          >
            <Field.Resource angleDown value={this.renderSelectPermission()} clearable={false} />
          </div>
        </Field>

        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          {this.renderDropdownContent()}
        </NewInlineModal>
      </>
    );
  };

  renderContent = () => {
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.users.action-modal.rights-title" />}
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>
          {this.props.user.archived && <UserIsArchivedBanner />}
          {this.renderFields()}
        </Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.updatePermissionProfile();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      updateSystemAccess: SDKReduxOperations.updateSystemAccess,
      fetchAndSetAmountOfPayingUsers: UsersOperations.fetchAndSetAmountOfPayingUsers,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const user = EntitySelectors.getUser(state, ownProps.id);
  const systemAccess = EntitySelectors.getSystemAccess(state, user.system_access);
  return {
    user,
    systemAccess,
    permissionProfile: EntitySelectors.getPermissionProfile(state, systemAccess.permission_profile_id),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionModal);
