import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'views/components/Shared/General';
import { NewInlineModal } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class GroupDropdownItem extends Component {
  render() {
    return (
      <NewInlineModal.Dropdown.Item
        key={this.props.id}
        selected={this.props.selected}
        leftComponent={<NewInlineModal.Dropdown.ItemIcon icon="users" />}
        rightComponent={this.props.loading ? <Loader tiny /> : null}
        onClick={() => this.props.onSelect()}
      >
        {this.props.group.title}
      </NewInlineModal.Dropdown.Item>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    group: EntitySelectors.getGroup(state, ownProps.id),
  };
}

export default connect(mapStateToProps)(GroupDropdownItem);
