import React from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/Shared/General';

const QuantityField = props => (
  <Field.Number
    value={props.value}
    onChange={value => props.onChange(value)}
    rightLabel={props.sparePartUnit == null ? null : props.sparePartUnit.abbreviation}
  />
);

function mapStateToProps(state, ownProps) {
  let sparePartUnit = null;
  const sparePart = EntitySelectors.getSparePart(state, ownProps.sparePartId);
  if (sparePart) {
    sparePartUnit = EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id);
  }
  return {
    sparePartUnit,
  };
}

export default connect(mapStateToProps)(QuantityField);
