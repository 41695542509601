import { AuthSelectors } from 'state/ducks/auth';

export default store => next => action => {
  if (action.meta != null && action.meta.includeCurrentUser === true) {
    return next({
      ...action,
      meta: {
        ...action.meta,
        currentUser: AuthSelectors.getCurrentUser(store.getState()),
      },
    });
  } else {
    return next(action);
  }
};
