import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Type } from 'sdk/ChecklistTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class Section extends Component {
  renderChildTemplateRows = () => {
    if (this.props.childTemplateRows.length === 0) return null;
    return this.props.children(this.props.childTemplateRows);
  };

  render() {
    const { nextTemplateRow, prevTemplateRow } = this.props;
    let classNames = [styles['section-container']];
    if (prevTemplateRow && prevTemplateRow.type !== Type.Section) {
      classNames = [...classNames, styles['top-margin']];
    }
    if (nextTemplateRow) {
      classNames = [...classNames, styles['bottom-margin']];
    }
    return (
      <div className={classNames.join(' ')} id={`sec:${this.props.id}`}>
        <div className={styles['section']}>{this.props.checklistTemplateRow.title}</div>
        {this.renderChildTemplateRows()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    checklistTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.id),
    childTemplateRows: EntitySelectors.getChecklistTemplateRows(state, ownProps.childIds),
    prevTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.prevTemplateRowId),
    nextTemplateRow: EntitySelectors.getChecklistTemplateRow(state, ownProps.nextTemplateRowId),
  };
}

export default connect(mapStateToProps)(Section);
