import update from 'immutability-helper';
import { mergeWith, uniq } from 'lodash-es';
import { SDKReduxTypes } from 'sdk';
import types from './types';

const INITIAL_STATE = {
  ids: [],
  pagination: {
    totalPages: 1,
    totalEntries: 0,
  },
  queryParams: {
    page: 1,
    page_size: 25,
  },
  amountOfPayingMembers: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.RESET_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.ADD_QUERY_PARAMETER: {
      return update(state, {
        queryParams: {
          $apply: filters =>
            mergeWith({}, filters, action.payload, (a, b) => (Array.isArray(b) ? b : undefined)),
        },
      });
    }
    case types.SET_AMOUNT_OF_PAYING_USERS: {
      return {
        ...state,
        amountOfPayingMembers: action.payload,
      };
    }
    case types.FETCH_USERS: {
      return {
        ...state,
        totalEntries: 0,
      };
    }
    case types.FETCH_USERS_SUCCESS: {
      const { ids, pagination, totalEntries } = action.payload;
      return {
        ...state,
        totalEntries,
        ids,
        pagination,
      };
    }
    case SDKReduxTypes.USER_CREATED_FOR_ORGANISATION: {
      const { data: user } = action.payload;
      return {
        ...state,
        ids: [user.id, ...state.ids],
        pagination: {
          ...state.pagination,
          totalEntries: state.pagination.totalEntries + 1,
        },
      };
    }
    case SDKReduxTypes.USER_DELETED: {
      const { userId, user } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(id => id !== userId),
        pagination: {
          ...state.pagination,
          totalEntries: state.pagination.totalEntries - 1,
        },
      };
    }
    case SDKReduxTypes.USER_UPDATED: {
      const { data, beforeEdit } = action.payload;
      const { id: userId } = data;
      if (data.archived !== beforeEdit.archived) {
        return {
          ...state,
          ids: state.ids.filter(id => id !== userId),
        };
      }
      return state;
    }

    default:
      return state;
  }
};
