import types from './types';

const prepareAuthenticatedUser = () => ({ type: types.PREPARE_AUTHENTICATED_USER });
const prepareAuthenticatedUserError = () => ({ type: types.PREPARE_AUTHENTICATED_USER_ERROR });
const prepareAuthenticatedUserSuccess = payload => ({
  type: types.PREPARE_AUTHENTICATED_USER_SUCCESS,
  payload,
});

const unAuthenticateUser = () => ({ type: types.UNAUTHENTICATE_USER });

const authenticateGroupSuccess = () => ({ type: types.AUTHENTICATE_GROUP_SUCCESS });
const unAuthenticateGroup = () => ({ type: types.UNAUTHENTICATE_GROUP });

export default {
  prepareAuthenticatedUserSuccess,
  unAuthenticateUser,
  prepareAuthenticatedUser,
  prepareAuthenticatedUserError,
  authenticateGroupSuccess,
  unAuthenticateGroup,
};
