import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { UserType } from 'sdk/SystemAccess';
import { API } from 'sdk';
import { EntityOperations } from 'sdk/State/entities';
import { NewInlineModal, Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import { EntitySelectors } from 'sdk/State/entities';
import { normalizePermissionProfile } from 'sdk/Schemas';

class PermissionsDropdown extends Component {
  state = {
    isFetching: false,
    open: false,
    permissionProfiles: [],
    selectedPermissionProfile: null,
  };

  open = () => {
    this.setState({ isFetching: true, open: true });
    API.listPermissionProfiles(this.props.systemId).then(({ data: permissionProfiles }) => {
      const { entities } = normalizePermissionProfile(permissionProfiles);
      this.props.updateEntities(entities);
      this.setState({
        permissionProfiles,
        isFetching: false,
      });
    });
  };

  selectPermission = permission => {
    this.props.onChange(permission);
    this.setState({ open: false });
  };

  renderValue = () => {
    const { permission } = this.props;
    if (permission) {
      const { user_type } = permission;
      if (user_type === UserType.SystemAdmin) {
        return <FormattedMessage id="resources.user.admin" />;
      }
      if (user_type === UserType.ProductionSupervisor) {
        return <FormattedMessage id="resources.user.production-supervisor" />;
      }
      if (user_type === UserType.ViewOnly) {
        return <FormattedMessage id="resources.user.view-only" />;
      }
      if (user_type === UserType.WorkRequester) {
        return <FormattedMessage id="resources.user.work-requester" />;
      }
      if (user_type === UserType.PermissionProfile && this.props.permissionProfile) {
        return <span>{this.props.permissionProfile.title}</span>;
      }
    }
    return null;
  };

  renderItems = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown>
          <NewInlineModal.Dropdown.Items>
            <NewInlineModal.Dropdown.Item loading />
            <NewInlineModal.Dropdown.Item loading />
          </NewInlineModal.Dropdown.Items>
        </NewInlineModal.Dropdown>
      );
    }
    const { permission } = this.props;
    return (
      <NewInlineModal.Dropdown>
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item
            selected={permission && permission.user_type === UserType.SystemAdmin}
            onClick={() => this.selectPermission({ user_type: UserType.SystemAdmin })}
          >
            <FormattedMessage id="resources.user.admin" />
          </NewInlineModal.Dropdown.Item>
          {this.props.settings.production_board_activated ? (
            <NewInlineModal.Dropdown.Item
              selected={permission && permission.user_type === UserType.ProductionSupervisor}
              onClick={() => this.selectPermission({ user_type: UserType.ProductionSupervisor })}
            >
              <FormattedMessage id="resources.user.production-supervisor" />
            </NewInlineModal.Dropdown.Item>
          ) : null}
          <NewInlineModal.Dropdown.Item
            selected={permission && permission.user_type === UserType.ViewOnly}
            onClick={() => this.selectPermission({ user_type: UserType.ViewOnly })}
          >
            <FormattedMessage id="resources.user.view-only" />
          </NewInlineModal.Dropdown.Item>
          <NewInlineModal.Dropdown.Item
            selected={permission && permission.user_type === UserType.WorkRequester}
            onClick={() => this.selectPermission({ user_type: UserType.WorkRequester })}
          >
            <FormattedMessage id="resources.user.work-requester" />
          </NewInlineModal.Dropdown.Item>

          {this.state.permissionProfiles.length === 0 ? null : (
            <>
              <NewInlineModal.Dropdown.Separator />
              <div className={styles['custom-permissions']}>
                <FormattedMessage id="screens.users.user-modal.actions.permission.permission-profiles" />
              </div>
              {this.state.permissionProfiles.map(permissionProfile => {
                return (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.selectPermission({
                        user_type: UserType.PermissionProfile,
                        permission_profile_id: permissionProfile.id,
                      });
                    }}
                    selected={
                      this.props.permissionProfile && this.props.permissionProfile.id === permissionProfile.id
                    }
                  >
                    <span>{permissionProfile.title}</span>
                  </NewInlineModal.Dropdown.Item>
                );
              })}
            </>
          )}
        </NewInlineModal.Dropdown.Items>
      </NewInlineModal.Dropdown>
    );
  };

  showMissingPermisionsError = () => {
    const { showPermissionIsRequiredError, permission } = this.props;
    return permission && permission.user_type == null && showPermissionIsRequiredError;
  };

  render() {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.open();
          }}
        >
          <Field.Resource
            error={this.showMissingPermisionsError()}
            angleDown
            clearable={false}
            value={this.renderValue()}
          />
        </div>

        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          {this.renderItems()}
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  let permissionProfile = null;
  if (ownProps.permission) {
    const { user_type, permission_profile_id } = ownProps.permission;
    if (user_type === UserType.PermissionProfile) {
      permissionProfile = EntitySelectors.getPermissionProfile(state, permission_profile_id);
    }
  }
  return {
    system: AuthSelectors.getCurrentSystem(state),
    settings: AuthSelectors.getSettings(state),
    permissionProfile,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsDropdown);
