import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { SparePartsSelectors } from 'state/ducks/spareParts';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    const keys = this.props.selectedFilterType.split('.');
    switch (keys[0]) {
      case FILTER_TYPES.SparePartTitle: {
        return <Filter.Title />;
      }
      case FILTER_TYPES.SparePartDescription: {
        return <Filter.Description />;
      }
      case FILTER_TYPES.SparePartType: {
        return <Filter.SparePartType />;
      }
      case FILTER_TYPES.SparePartUnit: {
        return <Filter.SparePartUnit />;
      }
      case FILTER_TYPES.SparePartLocation: {
        return <Filter.SparePartLocation />;
      }
      case FILTER_TYPES.ReorderPoint: {
        return <Filter.ReorderPoint />;
      }
      case FILTER_TYPES.MinQuantity: {
        return <Filter.MinQuantity />;
      }
      case FILTER_TYPES.MaxQuantity: {
        return <Filter.MaxQuantity />;
      }
      case FILTER_TYPES.StockQuantity: {
        return <Filter.StockQuantity />;
      }
      case FILTER_TYPES.Vendor: {
        return <Filter.Vendor />;
      }
      case FILTER_TYPES.VendorArticleNumber: {
        return <Filter.VendorArticleNumber />;
      }
      case FILTER_TYPES.SparePartField: {
        return <Filter.SparePartField />;
      }
      case FILTER_TYPES.ArticleNumber: {
        return <Filter.ArticleNumber />;
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFilterType: SparePartsSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
