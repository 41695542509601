import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { ChecklistModalSelectors, ChecklistModalOperations } from 'state/ducks/checklistModal';
import { EntityOperations, EntitySelectors } from 'sdk/State/entities';
import { Button, ConfirmModal } from 'views/components/Shared/General';
import { ToastMessage } from 'views/components/Shared/Layout';
import { NewWorkOrderModal } from 'views/components/WorkOrder';
import { NewRequestModal } from 'views/components/Request';
import { API, SDKReduxOperations } from 'sdk';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeChecklistInstance } from 'sdk/Schemas';
import styles from './style.module.scss';

class BottomBar extends Component {
  state = {
    showNewWorkOrderModal: false,
    showNewRequestModal: false,
    newWorkOrderModalParams: {},
    isBatchIgnoringDeviations: false,
    showBatchIgnoreDeviationsModal: false,
  };

  fetchChecklistInstance = () => {
    return API.fetchChecklistInstance(this.props.checklistInstance.id).then(({ data: checklistInstance }) => {
      const { entities } = normalizeChecklistInstance(checklistInstance);
      this.props.updateEntities(entities);
    });
  };

  batchIgnoreDeviations = () => {
    this.setState({ isBatchIgnoringDeviations: true });
    const params = {
      filters: {
        id: this.props.selectedInstanceRowIds.join(','),
      },
    };
    this.props.batchIgnoreDeviations(this.props.checklistInstance.id, params).then(() => {
      this.fetchChecklistInstance().then(() => {
        toast(
          <ToastMessage
            success
            text={
              <FormattedMessage id="components.checklist-instance-modal.deviations.ignore-confirm-modal.ignore-success" />
            }
          />
        );
        this.props.clearDeviationRows();
        this.setState({ showBatchIgnoreDeviationsModal: false, isBatchIgnoringDeviations: false });
      });
    });
  };

  renderNewWorkOrderModal = () => {
    return (
      <NewWorkOrderModal
        open={this.state.showNewWorkOrderModal}
        forChecklistInstanceId={this.props.checklistInstance.id}
        forChecklistInstanceRowIds={this.props.selectedInstanceRowIds}
        defaultParams={this.state.newWorkOrderModalParams}
        onClose={() => {
          this.setState({ showNewWorkOrderModal: false });
        }}
        onCreated={() => {
          /*
            Fetch checklistinstance to update rows with devation actions
          */
          this.fetchChecklistInstance().then(() => {
            this.props.clearDeviationRows();
            this.setState({ showNewWorkOrderModal: false });
          });
        }}
      />
    );
  };

  renderRequestModal = () => {
    return (
      <NewRequestModal
        open={this.state.showNewRequestModal}
        forChecklistInstanceRowIds={this.props.selectedInstanceRowIds}
        defaultParams={this.state.newRequestModalParams}
        onClose={() => {
          this.setState({ showNewRequestModal: false });
        }}
        onCreated={() => {
          /*
            Fetch checklistinstance to update rows with devation actions
          */
          this.fetchChecklistInstance().then(() => {
            this.props.clearDeviationRows();
            this.setState({ showNewRequestModal: false });
          });
        }}
      />
    );
  };

  renderIgnoreDeviationsConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showBatchIgnoreDeviationsModal}
        title={
          <FormattedMessage id="components.checklist-instance-modal.deviations.ignore-confirm-modal.title" />
        }
        message={this.renderSelectedRows()}
        confirmButtonText="general.save"
        confirmIsLoading={this.state.isBatchIgnoringDeviations}
        onConfirm={() => {
          this.batchIgnoreDeviations();
        }}
        onCancel={() => {
          this.setState({ showBatchIgnoreDeviationsModal: false });
        }}
      />
    );
  };

  renderSelectedRows = () => {
    return (
      <FormattedPlural
        value={this.props.selectedInstanceRowIds.length}
        one={<FormattedMessage id="components.checklist-instance-modal.deviations.selected-rows.one" />}
        two={
          <FormattedMessage
            id="components.checklist-instance-modal.deviations.selected-rows.two"
            values={{ amount: this.props.selectedInstanceRowIds.length }}
          />
        }
        few={
          <FormattedMessage
            id="components.checklist-instance-modal.deviations.selected-rows.few"
            values={{ amount: this.props.selectedInstanceRowIds.length }}
          />
        }
        many={
          <FormattedMessage
            id="components.checklist-instance-modal.deviations.selected-rows.many"
            values={{ amount: this.props.selectedInstanceRowIds.length }}
          />
        }
        other={
          <FormattedMessage
            id="components.checklist-instance-modal.deviations.selected-rows.other"
            values={{ amount: this.props.selectedInstanceRowIds.length }}
          />
        }
      />
    );
  };

  renderCreateWorkOrderButton = () => {
    let asset_id = null;
    if (this.props.workOrder && this.props.workOrder.asset_id) {
      asset_id = this.props.workOrder.asset_id;
    } else if (
      this.props.operationalMaintenanceInstance &&
      this.props.operationalMaintenanceInstance.asset_id
    ) {
      asset_id = this.props.operationalMaintenanceInstance.asset_id;
    }
    if (this.props.canAdministrateWorkOrders) {
      return (
        <Button
          gray
          label="components.checklist-instance-modal.deviations.create-work-order"
          onClick={() => {
            this.setState({
              showNewWorkOrderModal: true,
              newWorkOrderModalParams: {
                asset_id,
                created_from_checklist_deviation_action: {
                  checklist_instance_id: this.props.checklistInstance.id,
                  checklist_instance_rows: this.props.selectedInstanceRowIds,
                },
              },
            });
          }}
        />
      );
    }
    return null;
  };

  render() {
    if (this.props.checklistInstance.inspection_failed_count === 0) {
      return null;
    }
    if (this.props.selectedInstanceRowIds.length === 0) {
      return null;
    }
    let asset_id = null;
    if (this.props.workOrder && this.props.workOrder.asset_id) {
      asset_id = this.props.workOrder.asset_id;
    } else if (
      this.props.operationalMaintenanceInstance &&
      this.props.operationalMaintenanceInstance.asset_id
    ) {
      asset_id = this.props.operationalMaintenanceInstance.asset_id;
    }
    return (
      <>
        <div className={styles['content']}>
          <div className={styles['left-actions']}>
            <div className={styles['marked-rows']}>{this.renderSelectedRows()}</div>
            <Button.Group>
              {this.renderCreateWorkOrderButton()}
              <Button
                gray
                label="components.checklist-instance-modal.deviations.create-request"
                onClick={() => {
                  this.setState({
                    showNewRequestModal: true,
                    newRequestModalParams: {
                      asset_id,
                      created_from_checklist_deviation_action: {
                        checklist_instance_id: this.props.checklistInstance.id,
                        checklist_instance_rows: this.props.selectedInstanceRowIds,
                      },
                    },
                  });
                }}
              />
            </Button.Group>
          </div>
          <div className={styles['right-actions']}>
            <Button
              gray
              loading={this.state.isBatchIgnoringDeviations}
              label="components.checklist-instance-modal.deviations.ignore"
              onClick={() => {
                this.setState({ showBatchIgnoreDeviationsModal: true });
              }}
            />
          </div>
        </div>
        {this.renderNewWorkOrderModal()}
        {this.renderRequestModal()}
        {this.renderIgnoreDeviationsConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearDeviationRows: ChecklistModalOperations.clearDeviationRows,
      updateEntities: EntityOperations.updateEntities,
      batchIgnoreDeviations: SDKReduxOperations.batchIgnoreDeviations,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  const checklistInstance = ChecklistModalSelectors.getChecklistInstance(state);
  return {
    checklistInstance,
    workOrder: EntitySelectors.getWorkOrder(state, checklistInstance.work_order_id),
    operationalMaintenanceInstance: EntitySelectors.getOperationalMaintenanceInstance(
      state,
      checklistInstance.operational_maintenance_instance_id
    ),
    selectedInstanceRowIds: ChecklistModalSelectors.getSelectedInstanceRows(state),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);
