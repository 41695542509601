import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Decimal } from 'decimal.js';
import AnimateHeight from 'react-animate-height';
import { EntitySelectors, EntityOperations } from 'sdk/State/entities';
import { SDKReduxOperations, API } from 'sdk';
import { normalizeSparePartVendor, normalizeSparePart } from 'sdk/Schemas';
import { SparePartOperations } from 'state/ducks/sparePart';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import toast from 'react-hot-toast';
import { Modal, Grid, ToastMessage } from 'views/components/Shared/Layout';
import { Title } from 'views/components/SparePart';
import { Field, Button, FieldErrorWrapper, NewInlineModal, Icon } from 'views/components/Shared/General';
import styles from './style.module.scss';

class SparePartVendorModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    showPackageQuantity: false,
    showPackageQuantityBiggerThanZeroError: false,
    primaryVendorBeforeEdit: false,
    primaryVendor: false,
    sparePartParams: {
      number: '',
      title: '',
      purchase_price: '',
      package_quantity: null,
    },
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.id !== null) {
        const { vendor, sparePart } = this.props;
        const primaryVendor = vendor.id === sparePart.primary_vendor_id;
        this.setState({
          ...this.getInitialState(),
          showPackageQuantity: this.props.sparePartVendor.package_quantity != null,
          primaryVendorBeforeEdit: primaryVendor,
          primaryVendor,
          sparePartParams: {
            number: this.props.sparePartVendor.number,
            title: this.props.sparePartVendor.title,
            purchase_price: this.props.sparePartVendor.purchase_price,
            package_quantity: this.props.sparePartVendor.package_quantity,
          },
        });
      } else {
        this.setState({
          ...this.getInitialState(),
        });
      }
    }
  }

  delete = () => {
    this.props.onDelete(this.props.sparePartVendor.id);
    this.props.onClose();
  };

  update = () => {
    this.setState({ isSaving: true });
    let params = {
      ...this.state.sparePartParams,
    };
    if (this.state.showPackageQuantity === false) {
      params = {
        ...params,
        package_quantity: null,
      };
    }
    if (
      this.state.showPackageQuantity &&
      new Decimal(this.state.sparePartParams.package_quantity || 0).lessThanOrEqualTo(new Decimal(1))
    ) {
      this.setState({ isSaving: false, showPackageQuantityBiggerThanZeroError: true });
      return;
    }
    this.props.updateSparePartVendor(this.props.sparePartVendor.id, params).then(({ data }) => {
      let allEntities = {
        ...normalizeSparePartVendor(data).entities,
      };
      if (this.state.primaryVendorBeforeEdit !== this.state.primaryVendor) {
        this.updatePrimaryVendor(this.state.primaryVendor).then(({ data }) => {
          allEntities = {
            ...allEntities,
            ...normalizeSparePart(data).entities,
          };
          this.props.updateEntities(allEntities);
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          this.props.onClose();
        });
      } else {
        this.props.updateEntities(allEntities);
        toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
        this.props.onClose();
      }
    });
  };

  create = () => {
    this.setState({
      isSaving: true,
    });
    let params = {
      ...this.state.sparePartParams,
      spare_part_id: this.props.sparePart.id,
      vendor_id: this.props.vendor.id,
    };
    if (this.state.showPackageQuantity === false) {
      params = {
        ...params,
        package_quantity: null,
      };
    }
    if (
      this.state.showPackageQuantity &&
      new Decimal(this.state.sparePartParams.package_quantity || 0).lessThanOrEqualTo(new Decimal(1))
    ) {
      this.setState({ isSaving: false, showPackageQuantityBiggerThanZeroError: true });
      return;
    }
    this.props.createSparePartVendor(this.props.currentSystem.id, params).then(({ data }) => {
      let allEntities = {
        ...normalizeSparePartVendor(data).entities,
      };
      if (this.state.primaryVendorBeforeEdit !== this.state.primaryVendor) {
        this.updatePrimaryVendor(this.state.primaryVendor).then(({ data }) => {
          allEntities = {
            ...allEntities,
            ...normalizeSparePart(data).entities,
          };
          this.props.updateEntities(allEntities);
          toast(
            <ToastMessage
              success
              text={<FormattedMessage id="screens.spare-part.vendors.create-success" />}
            />
          );
          this.props.onClose();
        });
      } else {
        this.props.updateEntities(allEntities);
        toast(
          <ToastMessage success text={<FormattedMessage id="screens.spare-part.vendors.create-success" />} />
        );
        this.props.onClose();
      }
    });
  };

  updatePrimaryVendor = primary => {
    return this.props.updateSparePart(this.props.sparePart.id, {
      primary_vendor_id: primary ? this.props.vendor.id : null,
    });
  };

  renderPrimaryField = () => {
    if (this.props.openedFromMultipleSparePartVendorsModal) {
      return null;
    }
    if (this.props.isAddingArticleForExistingVendor) {
      return null;
    }
    return (
      <div className={styles['primary-checkbox']}>
        <Field.Checkbox
          disabled={!this.props.canEditSpareParts && !this.props.canEditVendors}
          questionTooltipContent={<FormattedMessage id="resources.spare-part.primary-vendor-description" />}
          checked={this.state.primaryVendor}
          onChange={() =>
            this.setState(prevState => ({
              primaryVendor: !prevState.primaryVendor,
            }))
          }
          label={<FormattedMessage id="resources.spare-part.primary-vendor" />}
        />
      </div>
    );
  };

  renderFetchNumberFromSparePartButton = () => {
    if (this.props.canEditSpareParts && this.props.canEditVendors) {
      return (
        <Button
          type="text"
          translate={false}
          primary
          fontSize={12}
          label={
            <FormattedMessage
              id="screens.vendor.spare-parts.spare-part-vendor-modal.use-article-number-from-spare-part"
              values={{ number: this.props.sparePart.article_number }}
            />
          }
          noUnderline
          onClick={() => {
            this.setState({
              sparePartParams: {
                ...this.state.sparePartParams,
                number: this.props.sparePart.article_number,
              },
            });
          }}
        />
      );
    }
    return null;
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.article-number" />
              }
            >
              <Field.Text
                autoFocus
                disabled={!this.props.canEditSpareParts && !this.props.canEditVendors}
                value={this.state.sparePartParams.number}
                onChange={number =>
                  this.setState({ sparePartParams: { ...this.state.sparePartParams, number } })
                }
              />
            </Field>
            {this.renderFetchNumberFromSparePartButton()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.spare-part-title" />
              }
            >
              <Field.Text
                disabled={!this.props.canEditSpareParts && !this.props.canEditVendors}
                placeholder={this.props.sparePart.title}
                value={this.state.sparePartParams.title}
                onChange={title =>
                  this.setState({ sparePartParams: { ...this.state.sparePartParams, title } })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field
              label={
                <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.purchase-order-price" />
              }
            >
              <Field.Money
                disabled={!this.props.canEditSpareParts && !this.props.canEditVendors}
                currency={
                  this.props.vendor == null
                    ? this.props.currentSystem.currency
                    : this.props.vendor.purchase_order_currency
                }
                value={this.state.sparePartParams.purchase_price}
                onBlur={purchase_price =>
                  this.setState({ sparePartParams: { ...this.state.sparePartParams, purchase_price } })
                }
              />
            </Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Field.Checkbox
              disabled={!this.props.canEditSpareParts && !this.props.canEditVendors}
              questionTooltipContent={
                <FormattedMessage id="resources.spare-part-vendor.package-quantity-description" />
              }
              checked={this.state.showPackageQuantity}
              onChange={() =>
                this.setState(prevState => ({
                  showPackageQuantity: !prevState.showPackageQuantity,
                }))
              }
              label={<FormattedMessage id="resources.spare-part-vendor.package-quantity" />}
            />
            <AnimateHeight
              duration={250}
              height={this.state.showPackageQuantity ? 'auto' : 0}
              onAnimationEnd={() => {
                if (this.state.showPackageQuantity) {
                  this.packageField.focus();
                }
              }}
            >
              <div className={styles['package-field']}>
                <FieldErrorWrapper
                  position="top"
                  show={this.state.showPackageQuantityBiggerThanZeroError}
                  errorElement={
                    <FormattedMessage id="screens.vendor.spare-parts.spare-part-vendor-modal.errors.package-bigger-than-zero" />
                  }
                >
                  <Field.Decimal
                    error={this.state.showPackageQuantityBiggerThanZeroError}
                    ref={ref => (this.packageField = ref)}
                    rightLabel={
                      this.props.sparePartUnit == null ? null : this.props.sparePartUnit.abbreviation
                    }
                    value={this.state.sparePartParams.package_quantity}
                    onChange={package_quantity => {
                      if (this.state.showPackageQuantityBiggerThanZeroError) {
                        this.setState({ showPackageQuantityBiggerThanZeroError: false });
                      }
                      this.setState({ sparePartParams: { ...this.state.sparePartParams, package_quantity } });
                    }}
                    onBlur={package_quantity => {
                      this.setState({ sparePartParams: { ...this.state.sparePartParams, package_quantity } });
                    }}
                  />
                </FieldErrorWrapper>
              </div>
            </AnimateHeight>
            {this.renderPrimaryField()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderTitleField = () => {
    return (
      <div className={styles['title-field']}>
        <p className={styles['title']}>{this.props.vendor.name}</p>
        <p className={styles['subtitle']}>{this.props.vendor.address1}</p>
      </div>
    );
  };

  renderTitle = () => {
    if (this.props.isAddingArticleForExistingVendor) {
      return (
        <FormattedMessage id="screens.spare-part.vendors.vendor-spare-part-modal.add-alternative-article-title" />
      );
    }
    if (this.props.canEditSpareParts && this.props.canEditVendors) {
      if (this.props.id) {
        return <FormattedMessage id="screens.spare-part.vendors.vendor-spare-part-modal.edit-title" />;
      }
      return <FormattedMessage id="screens.spare-part.vendors.vendor-spare-part-modal.title" />;
    }
    return <FormattedMessage id="screens.spare-part.vendors.vendor-spare-part-modal.view-title" />;
  };

  renderIconButtons = () => {
    if (this.props.id == null) {
      return null;
    }
    if (this.props.openedFromMultipleSparePartVendorsModal) {
      return null;
    }
    if (!this.props.canEditSpareParts || !this.props.canEditVendors) {
      return null;
    }
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => {
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={235}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                onClick={() => {
                  this.setState({ dropdownOpen: false });
                  this.props.onAddArticle(this.props.vendor.id);
                }}
              >
                <FormattedMessage id="screens.spare-part.vendors.add-another-article" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderModalContent = () => {
    if (this.props.vendor == null) {
      return null;
    }
    return (
      <>
        <Modal.Header
          ignoreLine
          title={this.renderTitle()}
          subtitle={
            <FormattedMessage
              id="screens.spare-part.vendors.vendor-spare-part-modal.spare-part-name"
              values={{
                sparePart: (
                  <span style={{ color: '#515151' }}>
                    <Title inline sparePart={this.props.sparePart} />
                  </span>
                ),
              }}
            />
          }
          onClose={() => this.props.onClose()}
          iconButtons={this.renderIconButtons()}
        />
        <Modal.Content>
          {this.renderTitleField()}
          {this.renderFields()}
        </Modal.Content>
        {this.props.canEditSpareParts && this.props.canEditVendors ? (
          <Modal.Footer>
            <div className={styles['button-group']}>
              <Button.Group>
                <Button
                  primary
                  loading={this.state.isSaving}
                  label="general.save"
                  onClick={() => {
                    if (this.props.id) {
                      this.update();
                    } else {
                      this.create();
                    }
                  }}
                />
                <Button label="general.cancel" onClick={() => this.props.onClose()} />
              </Button.Group>
              {this.props.id ? (
                <Button primary destructive label="general.delete" onClick={() => this.delete()} />
              ) : null}
            </div>
          </Modal.Footer>
        ) : null}
      </>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={460}>
        {this.renderModalContent()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSparePart: SDKReduxOperations.updateSparePart,
      updateSparePartVendor: SDKReduxOperations.updateSparePartVendor,
      createSparePartVendor: SDKReduxOperations.createSparePartVendor,
      updateEntities: EntityOperations.updateEntities,
      clearPrimaryVendor: SparePartOperations.clearPrimaryVendor,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};
  var sparePart = null;
  var sparePartVendor = null;
  var vendor = null;
  if (ownProps.id !== null) {
    sparePartVendor = EntitySelectors.getSparePartVendor(state, ownProps.id);
    sparePart = EntitySelectors.getSparePart(state, sparePartVendor.spare_part_id);
    vendor = EntitySelectors.getVendor(state, sparePartVendor.vendor_id);
  } else {
    sparePart = EntitySelectors.getSparePart(state, ownProps.createForSparePartId);
    vendor = EntitySelectors.getVendor(state, ownProps.createForVendorId);
  }
  return {
    sparePart,
    vendor,
    sparePartVendor,
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditSpareParts: AuthSelectors.canEditSpareParts(state),
    canEditVendors: AuthSelectors.canEditVendors(state),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartVendorModal);
