import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import { API } from 'sdk';
import { ExportType, maxQuantityAllowedForType } from 'sdk/Export';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import { PrintModal as GeneralPrintModal } from 'views/components/General';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

class PrintModal extends Component {
  getInitialState = () => ({
    printMode: this.props.exportType || ExportType.WorkOrdersListPdf,
    isPrinting: false,
    showExportLoaderForId: null,
    showQuantityExceeded: false,
    exclude_spent_times: false,
    exclude_spare_parts: false,
    exclude_spare_part_locations: false,
    exclude_costs: false,
    exclude_status: false,
    exclude_images: false,
    exclude_checklist: false,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
        showExportLoaderForId: this.props.showExportLoaderForId,
        showQuantityExceeded:
          this.props.checkedWorkOrderCount >= maxQuantityAllowedForType(this.state.printMode),
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderMaxQuantityExceeded = () => {
    if (!this.state.showQuantityExceeded) return null;
    return <FormattedMessage id="components.export-modal.pdf-quantity-exceeded" />;
  };

  renderTitle = () => {
    if (this.props.singleWorkOrderId)
      return <FormattedMessage id="components.print-work-order-modal.title" />;
    return <FormattedMessage id="components.export-modal.title-print" />;
  };

  renderSubtitle = () => {
    if (this.props.singleWorkOrderId)
      return <FormattedMessage id="components.print-modal.choose-information" />;
    return (
      <FormattedPlural
        value={this.props.checkedWorkOrderCount}
        one={<FormattedMessage id="components.mass-edit-modal.total-work-orders.one" />}
        two={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.two"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        few={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.few"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        many={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.many"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
        other={
          <FormattedMessage
            id="components.mass-edit-modal.total-work-orders.other"
            values={{ count: this.props.checkedWorkOrderCount }}
          />
        }
      />
    );
  };

  renderDetailPrintOptions = () => {
    return (
      <Field.Checkbox.Group>
        <Field.Checkbox
          checked
          disabled
          label={<FormattedMessage id="components.print-work-order-modal.planned-work" />}
        />
        <Field.Checkbox
          checked={!this.state.exclude_images}
          label={<FormattedMessage id="components.print-work-order-modal.images" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_images: !prevState.exclude_images,
            }));
          }}
        />
        <Field.Checkbox
          checked={!this.state.exclude_status}
          label={<FormattedMessage id="components.print-work-order-modal.status" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_status: !prevState.exclude_status,
            }));
          }}
        />
        <Field.Checkbox
          checked={!this.state.exclude_spent_times}
          label={<FormattedMessage id="components.print-work-order-modal.spent-times" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_spent_times: !prevState.exclude_spent_times,
            }));
          }}
        />
        <Field.Checkbox
          checked={!this.state.exclude_spare_parts}
          label={<FormattedMessage id="components.print-work-order-modal.spare-parts" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_spare_parts: !prevState.exclude_spare_parts,
            }));
          }}
        />
        <div className={styles['indented']}>
          <Field.Checkbox
            checked={!this.state.exclude_spare_part_locations && !this.state.exclude_spare_parts}
            disabled={this.state.exclude_spare_parts}
            label={<FormattedMessage id="components.print-work-order-modal.spare-part-locations" />}
            onChange={() => {
              this.setState(prevState => ({
                exclude_spare_part_locations: !prevState.exclude_spare_part_locations,
              }));
            }}
          />
        </div>
        {this.props.canViewWorkOrderCosts ? (
          <Field.Checkbox
            checked={!this.state.exclude_costs}
            label={<FormattedMessage id="components.print-work-order-modal.costs" />}
            onChange={() => {
              this.setState(prevState => ({
                exclude_costs: !prevState.exclude_costs,
              }));
            }}
          />
        ) : null}
        <Field.Checkbox
          checked={!this.state.exclude_checklist}
          label={<FormattedMessage id="components.print-work-order-modal.checklist" />}
          onChange={() => {
            this.setState(prevState => ({
              exclude_checklist: !prevState.exclude_checklist,
            }));
          }}
        />
      </Field.Checkbox.Group>
    );
  };

  buildFilterParams = () => {
    if (this.props.singleWorkOrderId) {
      return { id: this.props.singleWorkOrderId };
    }
    if (this.props.totalEntriesIsSelected) {
      return {
        ...this.props.filtersAsQueryParams,
        ...this.props.queryParameters,
        ...this.props.listParams,
      };
    }
    const sort = this.props.queryParameters['sort'];
    const sortOrder = this.props.queryParameters['sort-order'];
    let params = {
      id: {
        any: Object.keys(this.props.checkedWorkOrderIds).toString(),
      },
    };
    if (sort) {
      params = {
        ...params,
        sort,
      };
    }
    if (sortOrder) {
      params = {
        ...params,
        'sort-order': sortOrder,
      };
    }
    return params;
  };

  print = () => {
    this.setState({ isPrinting: true });
    let params = this.buildFilterParams();
    if (this.state.printMode == ExportType.WorkOrdersDetailsPdf) {
      params = {
        ...params,
        exclude_checklist: false,
        exclude_spent_times: this.state.exclude_spent_times,
        exclude_spare_parts: this.state.exclude_spare_parts,
        exclude_spare_part_locations: this.state.exclude_spare_part_locations,
        exclude_costs: this.state.exclude_costs,
        exclude_status: this.state.exclude_status,
        exclude_images: this.state.exclude_images,
      };
    }

    API.createExport(this.props.system.id, {
      params: params,
      type: this.state.printMode,
    }).then(({ data }) => {
      this.setState({ showExportLoaderForId: data.id });
    });
  };

  render() {
    return (
      <GeneralPrintModal
        open={this.props.open}
        list={this.state.printMode == ExportType.WorkOrdersListPdf}
        detail={this.state.printMode == ExportType.WorkOrdersDetailsPdf}
        detailOnly={this.props.singleWorkOrderId != null}
        onSelectList={() => this.setState({ printMode: ExportType.WorkOrdersListPdf })}
        onSelectDetail={() => this.setState({ printMode: ExportType.WorkOrdersDetailsPdf })}
        onClose={this.props.onClose}
        title={this.renderTitle()}
        subtitle={this.renderSubtitle()}
        onPrint={this.print}
        detailOptions={this.renderDetailPrintOptions()}
        loadingForExportId={this.state.showExportLoaderForId}
        loading={this.state.isPrinting}
        warning={this.renderMaxQuantityExceeded()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: WorkOrdersSelectors.getQueryParameters(state),
    filtersAsQueryParams: WorkOrdersSelectors.getFiltersAsQueryParams(state),
    checkedWorkOrderIds: WorkOrdersSelectors.getSelectedWorkOrderIds(state),
    checkedWorkOrderCount: WorkOrdersSelectors.getSelectedWorkOrdersCount(state),
    totalEntriesIsSelected: WorkOrdersSelectors.getTotalEntriesIsSelected(state),
  };
}

export default connect(mapStateToProps)(PrintModal);

PrintModal.propTypes = {
  exportType: PropTypes.oneOf([ExportType.WorkOrdersDetailsPdf, ExportType.WorkOrdersListPdf]),
  singleWorkOrderId: PropTypes.string,
  showExportLoaderForId: PropTypes.string,
};

PrintModal.defaultProps = {
  exportType: null,
  singleWorkOrderId: null,
  showExportLoaderForId: null,
};
