import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'views/components/Shared/Layout';
import { Button } from 'views/components/Shared/General';
import styles from './style.module.scss';

export default class ConfirmModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  renderTitle = () => {
    if (this.props.destructive) {
      return <span className={styles['destructive-title']}>{this.props.title}</span>;
    } else {
      return this.props.title;
    }
  };

  render() {
    const primaryButtonLabel = this.props.confirmButtonText || 'general.ok';
    const cancelButtonLabel = this.props.cancelButtonText || 'general.cancel';
    return (
      <Modal isOpen={this.props.open} width={420}>
        <Modal.Header title={this.renderTitle()} hideCloseButton />
        <Modal.Content>
          <div className={styles['content']}>{this.props.message}</div>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button
              primary
              destructive={this.props.destructive}
              label={primaryButtonLabel}
              loading={this.props.confirmIsLoading}
              onClick={this.props.onConfirm}
            />
            <Button label={cancelButtonLabel} onClick={this.props.onCancel} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  destructive: PropTypes.bool,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  destructive: false,
  loading: false,
  onConfirm: () => null,
  onCancel: () => null,
  confirmButtonText: 'general.ok',
  cancelButtonText: 'general.cancel',
};
