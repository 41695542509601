import React from 'react';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { Duration } from 'views/components/Shared/General';

const getMinutesFromElapsedMinutes = elapsedMinutes => {
  return elapsedMinutes == null ? null : Number(elapsedMinutes % 60);
};

const getHoursFromElapsedMinutes = elapsedMinutes => {
  return elapsedMinutes == null ? null : Number(Math.floor(elapsedMinutes / 60));
};

export default ({ elapsedMinutes, short = false, displayDays = false }) => {
  const minutes = getMinutesFromElapsedMinutes(elapsedMinutes);
  const hours = getHoursFromElapsedMinutes(elapsedMinutes);

  if (hours >= 24 && displayDays) {
    return <Duration from={new Date(Date.now() - elapsedMinutes * 60000)} />;
  }

  const renderContent = () => {
    if (short) {
      if (hours === 0) {
        if (minutes === 1)
          return <FormattedMessage id="components.elapsed-time.minute-short" values={{ minutes }} />;
        return <FormattedMessage id="components.elapsed-time.minutes-short" values={{ minutes }} />;
      }
      if (hours >= 1 && minutes > 0) {
        if (minutes > 1) {
          return (
            <FormattedPlural
              value={hours}
              one={
                <FormattedMessage
                  id="components.elapsed-time.hour-and-minutes-short"
                  values={{ minutes, hours }}
                />
              }
              other={
                <FormattedMessage
                  id="components.elapsed-time.hours-and-minutes-short"
                  values={{ hours, minutes }}
                />
              }
            />
          );
        } else {
          return (
            <FormattedPlural
              value={hours}
              one={
                <FormattedMessage
                  id="components.elapsed-time.hour-and-minute-short"
                  values={{ minute: minutes, hours }}
                />
              }
              other={
                <FormattedMessage
                  id="components.elapsed-time.hours-and-minute-short"
                  values={{ hours, minute: minutes }}
                />
              }
            />
          );
        }
      }
      return (
        <FormattedPlural
          value={hours}
          one={<FormattedMessage id="components.elapsed-time.hour-short" values={{ hours }} />}
          other={<FormattedMessage id="components.elapsed-time.hours-short" values={{ hours }} />}
        />
      );
    }
    if (hours === 0) {
      if (minutes === 1) return <FormattedMessage id="components.elapsed-time.minute" values={{ minutes }} />;
      return <FormattedMessage id="components.elapsed-time.minutes" values={{ minutes }} />;
    }
    if (hours >= 1 && minutes > 0) {
      if (minutes > 1) {
        return (
          <FormattedPlural
            value={hours}
            one={
              <FormattedMessage id="components.elapsed-time.hour-and-minutes" values={{ minutes, hours }} />
            }
            other={
              <FormattedMessage id="components.elapsed-time.hours-and-minutes" values={{ hours, minutes }} />
            }
          />
        );
      } else {
        return (
          <FormattedPlural
            value={hours}
            one={
              <FormattedMessage
                id="components.elapsed-time.hour-and-minute"
                values={{ minute: minutes, hours }}
              />
            }
            other={
              <FormattedMessage
                id="components.elapsed-time.hours-and-minute"
                values={{ hours, minute: minutes }}
              />
            }
          />
        );
      }
    }
    return (
      <FormattedPlural
        value={hours}
        one={<FormattedMessage id="components.elapsed-time.hour" values={{ hours }} />}
        other={<FormattedMessage id="components.elapsed-time.hours" values={{ hours }} />}
      />
    );
  };

  return renderContent();
};
