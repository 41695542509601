import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AnimateHeight from 'react-animate-height';
import { API } from 'sdk';
import { normalizeAssetType, normalizeTemplateField } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import { EntityOperations } from 'sdk/State/entities';
import { Modal } from 'views/components/Shared/Layout';
import { Button, Icon, Menu } from 'views/components/Shared/General';
import AssetTypeMenu from './AssetTypeMenu';
import Template from './Template';
import styles from './style.module.scss';

class ManageTemplateFieldsModal extends Component {
  getInitialState = () => ({
    isFetchingTemplateFields: true,
    showSearchAssetTypes: false,
    assetTypeSearchTerm: '',
    isFetchingAssetTypes: true,
    assetTypeIds: [],
    selectedAssetTypeId: null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState(this.getInitialState(), () => {
        if (this.props.templateType === 'asset') {
          this.fetchAssetTypes();
        }
        this.fetchTemplateFields();
      });
    }
  }

  fetchTemplateFields = () => {
    return API.listTemplateFields(this.props.currentSystem.id, {
      no_pagination: true,
      template_type: this.props.templateType,
    }).then(({ data: templateFields }) => {
      const { result, entities } = normalizeTemplateField(templateFields);
      this.props.updateEntities(entities);
      this.setState({ isFetchingTemplateFields: false });
      return result;
    });
  };

  fetchAssetTypes = () => {
    if (this.props.templateType === 'asset') {
      API.listAssetTypes(this.props.currentSystem.id, { no_pagination: true }).then(
        ({ data: assetTypes }) => {
          const { result, entities } = normalizeAssetType(assetTypes);
          this.props.updateEntities(entities);
          this.setState({ isFetchingAssetTypes: false, assetTypeIds: result });
        }
      );
    }
  };

  renderAssetTypeSearchField = () => {
    return (
      <AnimateHeight duration={250} height={this.state.showSearchAssetTypes ? 'auto' : 0}>
        <div className={styles['type-search-container']}>
          <div className={styles['search-logo']}>
            <Icon regular type="search" />
          </div>
          <input
            ref={ref => (this.inputRef = ref)}
            className={styles['input']}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            autoComplete="off"
            value={this.state.assetTypeSearchTerm}
            name="search"
            onChange={e => {
              this.setState({ isLoading: true, assetTypeSearchTerm: e.target.value });
            }}
          />
        </div>
      </AnimateHeight>
    );
  };

  renderAssetTypeSearch() {
    return (
      <div>
        <div className={styles['type-search-header']}>
          <div className={styles['type-search-title']}>
            <FormattedMessage id="resources.asset.type" />
          </div>
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={() => {
              if (this.state.showSearchAssetTypes) {
                this.setState({ assetTypeSearchTerm: '' });
              }
              this.setState(
                prevState => ({
                  showSearchAssetTypes: !prevState.showSearchAssetTypes,
                }),
                () => {
                  if (this.state.showSearchAssetTypes) {
                    this.inputRef.focus();
                  }
                }
              );
            }}
          />
        </div>
        {this.renderAssetTypeSearchField()}
      </div>
    );
  }

  renderLeftMenu = () => {
    return (
      <PerfectScrollbar>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-item']}>
            {this.props.templateType === 'asset' && (
              <Menu.Item
                title={
                  <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.left-menu.all-assets" />
                }
                selected={this.state.selectedAssetTypeId === null}
                onClick={() => this.setState({ selectedAssetTypeId: null })}
              />
            )}
            {this.props.templateType === 'spare_part' && (
              <Menu.Item
                title={
                  <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.left-menu.all-spare-parts" />
                }
                selected={this.props.templateType === 'spare_part'}
              />
            )}
          </div>
          {this.props.templateType === 'asset' && (
            <div className={styles['type-menu-container']}>
              {this.renderAssetTypeSearch()}
              <div className={styles['items']}>
                <AssetTypeMenu
                  ids={this.state.assetTypeIds}
                  searchTerm={this.state.assetTypeSearchTerm}
                  onSelectAssetTypeId={id => this.setState({ selectedAssetTypeId: id })}
                  selectedAssetTypeId={this.state.selectedAssetTypeId}
                  loading={this.state.isFetchingAssetTypes}
                />
              </div>
            </div>
          )}
        </div>
      </PerfectScrollbar>
    );
  };

  renderTemplate = () => {
    return (
      <Template
        loading={this.state.isFetchingTemplateFields}
        assetTypeId={this.state.selectedAssetTypeId}
        templateType={this.props.templateType}
      />
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={1200} fullHeight>
          <Modal.Header
            title={
              this.props.templateType === 'asset' ? (
                <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.title.asset" />
              ) : (
                <FormattedMessage id="screens.settings.assets.manage-template-fields-modal.title.spare-part" />
              )
            }
            onClose={this.props.onClose}
          />
          <Modal.ColumnLayout>
            <Modal.ColumnLayout.Menu>{this.renderLeftMenu()}</Modal.ColumnLayout.Menu>
            {this.renderTemplate()}
          </Modal.ColumnLayout>
        </Modal>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageTemplateFieldsModal));

ManageTemplateFieldsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  templateType: PropTypes.string,
};
