import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { TemplateFieldType } from 'sdk/TemplateField';
import { Field, ViewTextArea } from 'views/components/Shared/General';
import TextField from './InputFields/Text';
import DateField from './InputFields/Date';
import TextAreaField from './InputFields/TextArea';
import ValueListField from './InputFields/ValueList';
import TemplateFieldItemTitle from './TemplateFieldItemTitle';
import styles from './styles.module.scss';

class TemplateField extends Component {
  renderViewValue = () => {
    switch (this.props.templateField.type) {
      case TemplateFieldType.TextArea:
        return <ViewTextArea>{this.props.value || '-'}</ViewTextArea>;
      case TemplateFieldType.Date:
        if (this.props.value) {
          return moment(this.props.value).format('LL');
        } else {
          return '-';
        }
      case TemplateFieldType.ValueList:
        if (this.props.value) {
          return <TemplateFieldItemTitle templateFieldItemId={this.props.value} />;
        } else {
          return '-';
        }
      default:
        return this.props.value || '-';
    }
  };

  renderEditingField = () => {
    switch (this.props.templateField.type) {
      case TemplateFieldType.Text:
        return <TextField value={this.props.value} onChange={value => this.props.onValueChange(value)} />;
      case TemplateFieldType.Date:
        return (
          <DateField
            value={this.props.value}
            onChange={value => {
              this.props.onValueChange(value);
            }}
            onClear={() => {
              this.props.onValueChange('');
            }}
          />
        );
      case TemplateFieldType.TextArea:
        return <TextAreaField value={this.props.value} onChange={value => this.props.onValueChange(value)} />;
      case TemplateFieldType.ValueList:
        return (
          <ValueListField
            templateField={this.props.templateField}
            value={this.props.value}
            onChange={value => {
              this.props.onValueChange(value);
            }}
          />
        );
      default:
        return null;
    }
  };

  renderValue = () => {
    if (this.props.editing) {
      return this.renderEditingField();
    } else {
      return <p className={styles['data']}>{this.renderViewValue()}</p>;
    }
  };

  render() {
    return (
      <Field view={!this.props.editing} label={this.props.templateField.title}>
        {this.renderValue()}
      </Field>
    );
  }
}

export default TemplateField;

TemplateField.propTypes = {
  templateField: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
};

TemplateField.defaultProps = {
  editing: false,
};
