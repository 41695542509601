import Name from './Name';
import OrgNumber from './OrgNumber';
import Website from './Website';
import Phone from './Phone';
import Email from './Email';
import AdditionalInformation from './AdditionalInformation';
import PurchaseOrderEmail from './PurchaseOrderEmail';
import Address1 from './Address1';
import Address2 from './Address2';
import Zip from './Zip';
import City from './City';
import Country from './Country';
import Currency from './Currency';
import PaymentTerm from './PaymentTerm';
import DeliveryMethod from './DeliveryMethod';

export default {
  Name,
  OrgNumber,
  Website,
  Phone,
  Email,
  AdditionalInformation,
  PurchaseOrderEmail,
  Address1,
  Address2,
  Zip,
  City,
  Country,
  PaymentTerm,
  DeliveryMethod,
  Currency,
};
