import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { EntitySelectors } from 'sdk/State/entities';
import { NewInlineModal, Button, Icon, ConfirmModal } from 'views/components/Shared/General';
import {
  EditOperationalMaintenanceTemplateSelectors,
  EditOperationalMaintenanceTemplateOperations,
} from 'state/ducks/editOperationalMaintenanceTemplate';
import { getIconClassNameString, getIconColor } from 'sdk/AttachmentVersion';
import styles from './style.module.scss';

class AttachmentsContainer extends Component {
  inlineModalPositioningRef = null;

  state = {
    dropdownOpen: false,
    showConfirmRemoveAttachmentModal: false,
  };

  renderMoreOptionsColumn = () => {
    return (
      <div>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={e => {
            e.stopPropagation();
            this.setState(prevState => ({
              dropdownOpen: !prevState.dropdownOpen,
            }));
          }}
        >
          <Button type="icon" icon={<Icon regular size={16} type="ellipsis-h" />} />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.dropdownOpen}
          minWidth={150}
          position="right"
          onClose={() => this.setState({ dropdownOpen: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              <NewInlineModal.Dropdown.Item
                destructive
                onClick={e => {
                  e.stopPropagation();
                  this.setState({ dropdownOpen: false, showConfirmRemoveAttachmentModal: true });
                }}
              >
                <FormattedMessage id="general.remove" />
              </NewInlineModal.Dropdown.Item>
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </div>
    );
  };

  renderRemoveAttachmentConfirmModal = () => {
    return (
      <ConfirmModal
        open={this.state.showConfirmRemoveAttachmentModal}
        title={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-attachment-confirm-modal.title" />
        }
        message={
          <FormattedMessage id="components.edit-operational-maintenance-template.remove-attachment-confirm-modal.subtitle" />
        }
        destructive
        confirmButtonText="general.remove"
        onConfirm={() => {
          this.setState({ showConfirmRemoveAttachmentModal: true });
          this.props.setEditingOperationalMaintenanceTemplateVersionValue({
            attachments: [...this.props.editingOperationalMaintenanceTemplateVersion.attachments].filter(
              ({ attachment_version_id: loopedId }) =>
                loopedId !== this.props.attachment.attachment_version_id
            ),
          });
        }}
        onCancel={() => {
          this.setState({ showConfirmRemoveAttachmentModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div
          className={styles['card']}
          onClick={() => HelperFunctions.openAttachment(this.props.attachmentVersion)}
        >
          <div className={styles['icon-container']}>
            <Icon
              withBackground
              gray
              backgroundSize={34}
              color={getIconColor(this.props.attachmentVersion)}
              type={getIconClassNameString(this.props.attachmentVersion)}
              size={16}
            />
          </div>
          <div className={styles['title']}>{this.props.attachmentVersion.title}</div>
          {this.renderMoreOptionsColumn()}
        </div>
        {this.renderRemoveAttachmentConfirmModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingOperationalMaintenanceTemplateVersionValue:
        EditOperationalMaintenanceTemplateOperations.setEditingOperationalMaintenanceTemplateVersionValue,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const { attachment_version_id } = ownProps.attachment;
  return {
    editingOperationalMaintenanceTemplateVersion:
      EditOperationalMaintenanceTemplateSelectors.getEditingOperationalMaintenanceTemplateVersion(state),
    attachmentVersion: EntitySelectors.getAttachmentVersion(state, attachment_version_id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsContainer);
