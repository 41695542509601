import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SideModal } from 'views/components/Shared/Layout';
import { PurchaseOrdersOperations } from 'state/ducks/purchaseOrders';
import { FILTER_TYPES } from '../';

class AvailableFilters extends Component {
  getInitialState = () => ({
    fieldLabelSearchTerm: '',
  });

  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  /*
    Helper functions
  */

  getFilteredMenuItems = menuItems => {
    const { fieldLabelSearchTerm } = this.state;
    return menuItems.filter(
      ({ label }) =>
        fieldLabelSearchTerm === '' || label.toLowerCase().includes(fieldLabelSearchTerm.toLowerCase())
    );
  };

  getPurchaseOrderMenuItems = () => {
    let menuItems = [
      {
        label: this.props.intl.formatMessage({
          id: 'resources.purchase-order.status',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Status);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.purchase-order.vendor',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Vendor);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'screens.purchase-orders.filters.advanced.purchase-order.late',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.Late);
        },
      },
      {
        label: this.props.intl.formatMessage({
          id: 'resources.spare-part.location',
        }),
        onClick: () => {
          this.props.selectFilterType(FILTER_TYPES.SparePartLocation);
        },
      },
    ];
    return this.getFilteredMenuItems(menuItems);
  };

  /*
    Render functions
  */

  renderPurchaseOrderFilters = () => {
    const purchaseOrderItems = this.getPurchaseOrderMenuItems();
    if (purchaseOrderItems.length === 0) {
      return null;
    }
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.Header
          title={<FormattedMessage id="screens.purchase-orders.filters.advanced.purchase-order.title" />}
        />
        <SideModal.Container.Filter.Section.Items>
          {purchaseOrderItems.map(({ label, selected, onClick }) => (
            <SideModal.Container.Filter.Section.Items.Item
              label={label}
              selected={selected}
              onClick={onClick}
            />
          ))}
        </SideModal.Container.Filter.Section.Items>
      </SideModal.Container.Filter.Section>
    );
  };

  renderSearchFieldEmpyDataSet = () => {
    return (
      <SideModal.Container.Filter.Section>
        <SideModal.Container.Filter.Section.EmptyDataSet />
      </SideModal.Container.Filter.Section>
    );
  };

  renderFilterOptions = () => {
    const filteredMenuItems = [...this.getPurchaseOrderMenuItems()];
    if (filteredMenuItems.length === 0) {
      return this.renderSearchFieldEmpyDataSet();
    }
    return this.renderPurchaseOrderFilters();
  };

  render() {
    return (
      <>
        <SideModal.Container.Filter.Header
          title={<FormattedMessage id="screens.purchase-orders.filters.advanced.title" />}
          searchable
          searchValue={this.state.fieldLabelSearchTerm}
          onSearch={fieldLabelSearchTerm => {
            this.setState({ fieldLabelSearchTerm });
          }}
          onClearSearch={() => {
            this.setState({ fieldLabelSearchTerm: '' });
          }}
          onClose={() => this.props.onClose()}
        />

        <SideModal.Container.Filter>{this.renderFilterOptions()}</SideModal.Container.Filter>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectFilterType: PurchaseOrdersOperations.selectFilterType,
    },
    dispatch
  );
}

export default injectIntl(connect(null, mapDispatchToProps)(AvailableFilters));
