import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewInlineModal } from 'views/components/Shared/General';
import { UserNameWrapper, ProfilePicture } from 'views/components/User';
import { SelectedAssignees } from 'views/components/WorkOrder';
import { API } from 'sdk';
import { ReminderRecipientType } from 'sdk/WorkOrder';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeUser } from 'sdk/Schemas';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';

class ChooseRecipientInlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      isOpen: false,
      users: [],
    };
  }

  listUsers = () => {
    this.setState({ searchTerm: '', isOpen: true, isFetching: true });
    return API.listUsers(this.props.system.id, { archived: false, member: true, no_pagination: true }).then(
      res => {
        const { entities, result } = normalizeUser(res.data);
        this.props.updateEntities(entities);
        this.setState({
          users: result.map(id => entities.userById[id]),
          isFetching: false,
        });
      }
    );
  };

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.listUsers();
    }
  };

  renderHeader = () => {
    if (this.state.users.length > 10) {
      return (
        <NewInlineModal.Header>
          <NewInlineModal.Header.Search
            ref={ref => (this.inlineModalSearchRef = ref)}
            placeholder={this.props.intl.formatMessage({ id: 'general.search-placeholder' })}
            value={this.state.searchTerm}
            onChange={searchTerm => {
              this.setState({ searchTerm });
            }}
            onClear={() => {
              this.setState({ searchTerm: '' });
            }}
          />
        </NewInlineModal.Header>
      );
    }
  };

  renderUsers = () => {
    const filteredUsers = this.state.users.filter(
      user =>
        this.state.searchTerm === '' ||
        (user.name && user.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
    );
    if (filteredUsers.length === 0) {
      return (
        <NewInlineModal.Dropdown.EmptyDataSet>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </NewInlineModal.Dropdown.EmptyDataSet>
      );
    }
    return (
      <NewInlineModal.Dropdown.Items>
        <NewInlineModal.Dropdown.Item
          selected={this.props.reminderRecipientType === ReminderRecipientType.Assignees}
          onClick={e => {
            this.props.onChange({
              reminder_recipient_type: ReminderRecipientType.Assignees,
              reminder_recipient_user_id: null,
            });
            this.setState({ isOpen: false });
          }}
        >
          <div className={styles['assignees']}>
            <div>
              <FormattedMessage id="components.reminder-modal.assignees-label" />
            </div>
            <div className={styles['selected-asignees']}>
              <SelectedAssignees
                userIds={this.props.editingWorkOrder.assigned_to_users}
                groupIds={this.props.editingWorkOrder.assigned_to_groups}
                vendorIds={this.props.editingWorkOrder.assigned_to_vendors}
              />
            </div>
          </div>
        </NewInlineModal.Dropdown.Item>
        <NewInlineModal.Dropdown.Separator />
        {filteredUsers.map(user => (
          <NewInlineModal.Dropdown.Item
            key={user.id}
            selected={this.props.reminderRecipientUserId === user.id}
            leftComponent={
              <ProfilePicture
                userId={user.id}
                size={20}
                defaultElement={<NewInlineModal.Dropdown.ItemIcon icon="user" />}
              />
            }
            onClick={e => {
              this.props.onChange({
                reminder_recipient_type: ReminderRecipientType.User,
                reminder_recipient_user_id: user.id,
              });
              this.setState({ isOpen: false });
            }}
          >
            <UserNameWrapper user={user} />
          </NewInlineModal.Dropdown.Item>
        ))}
      </NewInlineModal.Dropdown.Items>
    );
  };

  renderContent = () => {
    if (this.state.isFetching) {
      return (
        <NewInlineModal.Dropdown.Items>
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Separator />
          <NewInlineModal.Dropdown.Item loading />
          <NewInlineModal.Dropdown.Item loading />
        </NewInlineModal.Dropdown.Items>
      );
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderUsers()}
      </>
    );
  };

  render() {
    return (
      <>
        <div ref={ref => (this.inlineModalTriggerRef = ref)} onClick={this.toggle}>
          {this.props.trigger}
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalTriggerRef}
          open={this.state.isOpen}
          position={this.props.position}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
        >
          <NewInlineModal.Dropdown>{this.renderContent()}</NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseRecipientInlineModal));
