import types from './types';

const setSparePartId = payload => ({
  type: types.SET_SPARE_PART_ID,
  payload: payload,
});

const fetchAttachmentsSuccess = payload => ({
  type: types.FETCH_ATTACHMENTS_SUCCESS,
  payload: payload,
});

const fetchSparePartAssetsSuccess = payload => ({
  type: types.FETCH_SPARE_PART_ASSETS_SUCCESS,
  payload: payload,
});

const fetchVendorsSuccess = payload => ({
  type: types.FETCH_VENDORS_SUCCESS,
  payload: payload,
});

const fetchPrimaryVendorSuccess = payload => ({
  type: types.FETCH_PRIMARY_VENDOR_SUCCESS,
  payload: payload,
});

const clearPrimaryVendor = () => ({
  type: types.CLEAR_PRIMARY_VENDOR,
});

const fetchPurchaseOrdersSuccess = payload => ({
  type: types.FETCH_PURCHASE_ORDERS_SUCCESS,
  payload: payload,
});

const fetchSparePartWithdrawalsSuccess = payload => ({
  type: types.FETCH_WITHDRAWALS_SUCCESS,
  payload: payload,
});

export default {
  setSparePartId,
  fetchAttachmentsSuccess,
  fetchSparePartAssetsSuccess,
  fetchVendorsSuccess,
  fetchPurchaseOrdersSuccess,
  clearPrimaryVendor,
  fetchPrimaryVendorSuccess,
  fetchSparePartWithdrawalsSuccess,
};
