import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Menu } from 'views/components/Shared/General';
import styles from './style.module.scss';

class AssetTypes extends Component {
  filteredAssetTypes = () => {
    const { searchTerm } = this.props;
    return this.props.assetTypes.filter(
      ({ title }) => searchTerm === '' || title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  render() {
    const filteredAssetTypes = this.filteredAssetTypes();
    if (this.props.searchTerm === '' && this.props.isSearching) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="screens.assets.left-panel.search-text" />
        </span>
      );
    }
    if (filteredAssetTypes.length === 0) {
      return (
        <span className={styles['search-text']}>
          <FormattedMessage id="general.empty-data-set-search.title" />
        </span>
      );
    }
    return filteredAssetTypes.map(assetType => (
      <Menu.Item
        selected={this.props.selectedAssetTypeId === assetType.id}
        title={assetType.title}
        linkTo={`/assets?asset_type_id=${assetType.id}`}
      />
    ));
  }
}

function mapStateToProps(state, ownProps) {
  return {
    assetTypes: EntitySelectors.getAssetTypes(state, ownProps.ids),
  };
}

export default connect(mapStateToProps)(AssetTypes);
