import types from './types';

const setActiveOperationalMaintenanceTemplateVersion = payload => ({
  type: types.SET_ACTIVE_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
  payload,
});

const setOperationalMaintenanceTemplateVersion = payload => ({
  type: types.SET_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION,
  payload,
});

const fetchOperationalMaintenanceTemplateVersionSuccess = payload => ({
  type: types.FETCH_CURRENT_OPERATIONAL_MAINTENANCE_TEMPLATE_VERSION_SUCCESS,
  payload,
});

export default {
  setActiveOperationalMaintenanceTemplateVersion,
  setOperationalMaintenanceTemplateVersion,
  fetchOperationalMaintenanceTemplateVersionSuccess,
};
