import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { ChecklistTemplateRow } from 'views/components/Checklist';
import Row from '../';

class SectionContainer extends Component {
  render() {
    return (
      <ChecklistTemplateRow.Section
        id={this.props.id}
        childIds={this.props.childIds}
        prevTemplateRowId={this.props.prevTemplateRowId}
        nextTemplateRowId={this.props.nextTemplateRowId}
      >
        {templateRows =>
          templateRows.map(templateRow => (
            <Row
              id={templateRow.id}
              childRowIds={this.props.childRowIds}
              instanceRowForTemplateRowIds={this.props.instanceRowForTemplateRowIds}
            />
          ))
        }
      </ChecklistTemplateRow.Section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { instanceRowForTemplateRowIds } = ownProps;
  return {
    childIds: EntitySelectors.getChecklistTemplateRows(state, ownProps.childRowIds[ownProps.id])
      .filter(checklistTemplateRow => {
        return instanceRowForTemplateRowIds[checklistTemplateRow.id] != null;
      })
      .map(({ id }) => id),
  };
}

export default connect(mapStateToProps)(SectionContainer);
