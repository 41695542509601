import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { SparePartUnitWithValue, Title } from 'views/components/SparePart';
import { WhiteCard, Datapill, Button } from 'views/components/Shared/General';
import styles from './style.module.scss';
import Decimal from 'decimal.js';
import { AuthSelectors } from 'state/ducks/auth';

class SparePartReservationListItem extends Component {
  renderQuantityDataPill = () => {
    const quantityWithdrawedForSparePart = this.props.sparePartWithdrawals
      .filter(sparePartWithdrawal => {
        return sparePartWithdrawal.spare_part_id === this.props.sparePartReservation.spare_part_id;
      })
      .reduce((acc, spw) => acc.add(new Decimal(spw.quantity || 0)), new Decimal(0));
    const reservationQuantityLeft = new Decimal(this.props.sparePartReservation.quantity).minus(
      new Decimal(quantityWithdrawedForSparePart)
    );
    return (
      <Datapill
        value={
          <SparePartUnitWithValue
            value={reservationQuantityLeft.toString()}
            sparePartUnit={this.props.sparePartUnit}
          />
        }
      />
    );
  };
  renderDataPills = () => {
    return (
      <div className={styles['datapills-container']}>
        <Datapill.List>{this.renderQuantityDataPill()}</Datapill.List>
      </div>
    );
  };

  renderButton = () => {
    const { canCreateWithdrawals, canCarryOutOperationalMaintenances } = this.props;
    if (canCreateWithdrawals && canCarryOutOperationalMaintenances) {
      return (
        <div className={styles['button-container']}>
          <Button
            gray
            label="screens.operational-maintenance.reserved-spare-parts.create-withdrawal-button"
            onClick={() => this.props.onClick(this.props.id)}
          />
          <div className={styles['overlay']} />
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={styles['reservation']}>
        <WhiteCard padding="18px 30px">
          <div>
            <div>
              <Title sparePart={this.props.sparePart} />
            </div>
            <div>{this.renderDataPills()}</div>
          </div>
        </WhiteCard>

        {this.renderButton()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id, operationalMaintenanceInstance } = ownProps;
  const { spare_part_withdrawals } = operationalMaintenanceInstance;
  const sparePartReservation = EntitySelectors.getSparePartReservation(state, id);
  const sparePart = EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id);
  return {
    sparePartReservation,
    sparePart: EntitySelectors.getSparePart(state, sparePartReservation.spare_part_id),
    sparePartUnit: EntitySelectors.getSparePartUnit(state, sparePart.spare_part_unit_id),
    sparePartWithdrawals: EntitySelectors.getSparePartWithdrawals(state, spare_part_withdrawals),
    canCarryOutOperationalMaintenances: AuthSelectors.canCarryOutOperationalMaintenances(state),
    canCreateWithdrawals: AuthSelectors.canCreateWithdrawals(state),
  };
}

export default connect(mapStateToProps)(SparePartReservationListItem);
