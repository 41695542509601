import React, { Component } from 'react';
import { Decimal as DecimalJs } from 'decimal.js';
import styles from './style.module.scss';

export default class Input extends Component {
  state = {
    focused: false,
  };

  focus = () => {
    this.setState({ focused: true });
    this.inputRef.focus();
  };

  changeValue = value => {
    try {
      let valueAsDecimal = new DecimalJs(value).toString();
      let cleanedValue = valueAsDecimal.replace(/,/g, '.').replace(/[^0-9.]/g, '');
      this.props.onChange(cleanedValue);
    } catch (e) {
      this.props.onChange('');
    }
  };

  render() {
    let classNames = [styles['input']];
    if (this.state.focused || this.props.value || this.props.error) {
      classNames = [...classNames, styles['focused']];
    }
    if (this.props.value) {
      classNames = [...classNames, styles['has-value']];
    }
    if (this.props.error) {
      classNames = [...classNames, styles['error']];
    }
    return (
      <div className={classNames.join(' ')}>
        <input
          type="text"
          ref={ref => (this.inputRef = ref)}
          value={this.props.value}
          onChange={e => {
            if (this.props.value) {
              this.changeValue(e.target.value.charAt(1));
            } else {
              this.changeValue(e.target.value.charAt(0));
            }
          }}
          onKeyDown={e => {
            if (typeof this.props.onGoToPreviousInput === 'function') {
              const key = e.keyCode || e.charCode;
              //if Backspace
              if ((key === 8 || key === 46) && !this.props.value) {
                this.props.onGoToPreviousInput();
              }
            }
          }}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
        />
      </div>
    );
  }
}
