const DOMAIN_PREFIX = 'attachmentAssetContent/';

const SET_ASSET_ID = `${DOMAIN_PREFIX}set_asset_id`;
const SET_FOLDER_ID = `${DOMAIN_PREFIX}set_folder_id`;
const FETCH_LIST_ATTACHMENTS_BEGIN = `${DOMAIN_PREFIX}fetch_list_attachments_begin`;
const FETCH_LIST_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_list_attachments_success`;
const FETCH_SEARCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_search_attachments_success`;
const FETCH_MORE_LIST_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_more_list_attachments_success`;
const FETCH_MORE_SEARCH_ATTACHMENTS_SUCCESS = `${DOMAIN_PREFIX}fetch_more_search_attachments_success`;

export default {
  SET_ASSET_ID,
  SET_FOLDER_ID,
  FETCH_LIST_ATTACHMENTS_BEGIN,
  FETCH_LIST_ATTACHMENTS_SUCCESS,
  FETCH_SEARCH_ATTACHMENTS_SUCCESS,
  FETCH_MORE_LIST_ATTACHMENTS_SUCCESS,
  FETCH_MORE_SEARCH_ATTACHMENTS_SUCCESS,
};
