import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import { HelperFunctions as SDKHelperFunctions, API } from 'sdk';
import { AssetsOperations } from 'state/ducks/assets';
import { AuthSelectors } from 'state/ducks/auth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { normalizeAsset } from 'sdk/Schemas';
import { List, Pagination, WhiteCard, EmptyDataSet } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import AssetList from '../components/AssetList';
import AssetListItem from '../components/AssetList/AssetListItem';

const listAssetsRequest = SDKHelperFunctions.getCancelTokenForRequest();

class AllAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetIds: [],
      isLoading: true,
      isLoadingPagination: false,
      isChangingPage: false,
      page: 1,
      totalPages: 0,
    };
    this.debouncedSearchValueWasChanged = debounce(value => {
      this.fetchAssetsForSystem();
    }, 300);
  }

  componentDidMount() {
    this.fetchAssetsForSystem();
  }

  componentDidUpdate(prevProps) {
    const changedSearchTerm = prevProps.searchValue !== this.props.searchValue;
    const changedAssetType = prevProps.assetTypeId !== this.props.assetTypeId;
    const changedArchived = prevProps.archived !== this.props.archived;
    if (changedSearchTerm || changedAssetType || changedArchived) {
      this.setState({ isLoading: true, isLoadingPagination: true, page: 1 });
      this.debouncedSearchValueWasChanged();
    }
  }

  changePage = page => {
    this.setState({ isChangingPage: true, page }, () => {
      this.fetchAssetsForSystem({ page });
    });
  };

  fetchAssetsForSystem = params => {
    listAssetsRequest.cancel();
    let attrs = {
      archived: false,
      ...params,
    };
    if (this.props.assetTypeId) {
      attrs = {
        ...attrs,
        asset_type_id: this.props.assetTypeId,
      };
    }
    if (this.props.archived) {
      attrs = {
        ...attrs,
        archived: this.props.archived,
      };
    }
    if (this.props.searchValue) {
      attrs = {
        ...attrs,
        search: this.props.searchValue,
        sort: 'search_relevance',
      };
    } else {
      attrs = {
        ...attrs,
        sort: 'title',
      };
    }
    this.setState({ isLoading: true });
    return API.listAssets(
      this.props.currentSystem.id,
      {
        page_size: 8,
        ...attrs,
        ...this.props.additionalApiParams,
      },
      listAssetsRequest.getCancelTokenConfig()
    )
      .then(res => {
        const { data, headers } = res;
        const { entities, result } = normalizeAsset(data);
        this.props.updateEntities(entities);
        this.setState({
          assetIds: result,
          isLoading: false,
          isChangingPage: false,
          isLoadingPagination: false,
          totalPages: SDKHelperFunctions.getPaginationFromHeader(headers).totalPages,
        });
      })
      .catch(e => {
        this.setState({ isLoading: false, isChangingPage: false, isLoadingPagination: false });
      });
  };

  renderListContent = () => {
    if (this.state.isLoading || this.state.isChangingPage)
      return (
        <>
          {this.renderListHeader()}
          <List>
            <AssetListItem loading />
            <AssetListItem loading />
          </List>
        </>
      );
    if (this.props.searchValue && this.state.assetIds.length === 0) {
      return this.renderEmptySearch();
    }
    if (!this.state.isLoading && this.props.searchValue === '' && this.state.assetIds.length === 0) {
      return this.renderEmptyDataSet();
    }
    return (
      <>
        {this.renderListHeader()}
        <List>
          <>
            <AssetList
              listItemRightComponent={this.props.listItemRightComponent}
              loading={this.state.isLoading}
              ids={this.state.assetIds}
              multiple={this.props.multiple}
              alreadySelectedForMultiple={this.props.alreadySelectedForMultiple}
              selectedAssetIds={this.props.selectedAssetIds}
              onSelectMultiple={this.props.onSelectMultiple}
            />
          </>
        </List>
      </>
    );
  };

  renderEmptySearch = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet subtitle={<FormattedMessage id="general.empty-data-set-search.title" />}></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderEmptyDataSetTitle = () => {
    if (this.props.assetTypeId) {
      return <FormattedMessage id="components.select-asset-modal.content.empty-data-sets.asset-types" />;
    }
    if (this.props.archived) {
      return <FormattedMessage id="components.select-asset-modal.content.empty-data-sets.archived" />;
    }
    return <FormattedMessage id="components.select-asset-modal.content.empty-data-sets.all" />;
  };

  renderEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet subtitle={this.renderEmptyDataSetTitle()}></EmptyDataSet>
      </WhiteCard>
    );
  };

  renderListHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="components.select-asset-modal.content.list-title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderPagination = () => {
    if (this.state.assetIds.length === 0) return null;
    return (
      <Modal.ColumnLayout.Container.Pagination>
        <Pagination
          blue
          hideOptions
          loading={this.state.isLoadingPagination}
          currentPage={this.state.page ? Number(this.state.page) : 1}
          totalPages={this.state.totalPages}
          onSelectPage={page => {
            this.changePage(page);
          }}
        />
      </Modal.ColumnLayout.Container.Pagination>
    );
  };
  render() {
    return (
      <>
        <Modal.ColumnLayout.Container.Content>
          {this.renderListContent()}
        </Modal.ColumnLayout.Container.Content>
        {this.renderPagination()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
      setFilters: AssetsOperations.setFilters,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AllAssets));
