import types from './types';

const fetchAssetForView = payload => ({
  type: types.FETCH_ASSET_FOR_VIEW,
  payload: payload,
});

const fetchAssetForViewSuccess = payload => ({
  type: types.FETCH_ASSET_FOR_VIEW_SUCCESS,
  payload: payload,
});

const fetchDowntimesSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_DOWNTIMES_SUCCESS,
  payload: { ids, pagination },
});

const fetchWorkOrdersSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_WORK_ORDERS_SUCCESS,
  payload: { ids, pagination },
});
const fetchDataBetweenDates = payload => ({
  type: types.FETCH_DATA_BETWEEN_DATES,
  payload,
});
const fetchDataBetweenDatesSuccess = payload => ({
  type: types.FETCH_DATA_BETWEEN_DATES_SUCCESS,
  payload,
});

const fetchAssetVendorsSuccess = payload => ({
  type: types.FETCH_ASSET_VENDORS_SUCCESS,
  payload: payload,
});

const fetchAttachmentsSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_ATTACHMENTS_SUCCESS,
  payload: { ids, pagination },
});

const fetchSparePartAssetsSuccess = payload => ({
  type: types.FETCH_SPARE_PART_ASSETS_SUCCESS,
  payload: payload,
});

const beginDrag = payload => ({
  type: types.BEGIN_DRAG,
  payload: payload,
});

const dropCalendarInstance = payload => ({
  type: types.DROP_CALENDAR_INSTANCE,
  payload: payload,
});

const endDrag = payload => ({
  type: types.END_DRAG,
  payload: payload,
});

const showPreviewOverlay = payload => ({
  type: types.SHOW_PREVIEW_OVERLAY,
  payload: payload,
});

const hidePreviewOverlay = () => ({
  type: types.HIDE_PREVIEW_OVERLAY,
});

const showMoreWorkOrdersPreviewOverlay = payload => ({
  type: types.SHOW_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
  payload: payload,
});

const hideMoreWorkOrdersPreviewOverlay = () => ({
  type: types.HIDE_MORE_WORK_ORDERS_PREVIEW_OVERLAY,
});

export default {
  beginDrag,
  endDrag,
  dropCalendarInstance,
  showPreviewOverlay,
  hidePreviewOverlay,
  showMoreWorkOrdersPreviewOverlay,
  hideMoreWorkOrdersPreviewOverlay,
  fetchDataBetweenDates,
  fetchDataBetweenDatesSuccess,
  fetchAssetForView,
  fetchAssetForViewSuccess,
  fetchAssetVendorsSuccess,
  fetchDowntimesSuccess,
  fetchWorkOrdersSuccess,
  fetchAttachmentsSuccess,
  fetchSparePartAssetsSuccess,
};
