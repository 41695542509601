import React from 'react';
import { connect } from 'react-redux';
import { RowType } from 'sdk/QRTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import { Loader } from 'views/components/Shared/General';
import styles from './style.module.scss';
import qrCodeSrc from 'assets/images/QRCodeTemplate.png';
import QrTemplateRowDraggableContainer from '../QrTemplateRowDraggableContainer';
import QrTemplateRowTitle from 'views/components/General/PrintQRCodeModal/QrTemplateRowTitle';

function QrTemplateRow(props) {
  function getJustifyContentFromVerticalAlign() {
    switch (props.qrTemplateRow.horizontal_align) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  }

  function getTextAlignFromHorizontalAlign() {
    switch (props.qrTemplateRow.horizontal_align) {
      case 'left':
        return 'start';
      case 'right':
        return 'end';
      default:
        return 'center';
    }
  }

  function getStylesFromQrTemplateColumn() {
    const {
      bold,
      italic,
      font_size,
      padding_top,
      padding_left,
      padding_bottom,
      padding_right,
      strikethrough,
      underline,
    } = props.qrTemplateRow;
    return {
      color: '#000000',
      fontSize: font_size,
      fontWeight: bold ? 700 : 600,
      paddingBottom: `${padding_bottom}mm`,
      paddingTop: `${padding_top}mm`,
      paddingLeft: `${padding_left}mm`,
      paddingRight: `${padding_right}mm`,
      fontStyle: italic ? 'italic' : 'normal',
      textDecoration: underline ? 'underline' : strikethrough ? 'line-through' : 'none',
      justifyContent: getJustifyContentFromVerticalAlign(),
      textAlign: getTextAlignFromHorizontalAlign(),
    };
  }

  function renderContent() {
    if (props.qrTemplateRow.row_type === RowType.Qr) {
      const { qr_code_size } = props.qrTemplateRow;
      return (
        <img
          style={{
            height: `${qr_code_size}mm`,
            width: `${qr_code_size}mm`,
          }}
          src={qrCodeSrc}
        />
      );
    }
    return <QrTemplateRowTitle qrTemplateRowId={props.qrTemplateRow.id} />;
  }

  let classNames = [styles['row']];

  if (props.placeholder) {
    classNames = [...classNames, styles['placeholder']];
    return <div className={classNames.join(' ')} />;
  }
  if (props.loading) {
    classNames = [...classNames, styles['placeholder']];
    return (
      <div className={classNames.join(' ')}>
        <Loader tiny centerInParent />
      </div>
    );
  }

  if (props.preview !== true) {
    classNames = [...classNames, styles['editing']];
    if (props.selectedTemplateRowId === props.id) {
      classNames = [...classNames, styles['selected']];
    }

    return (
      <QrTemplateRowDraggableContainer id={props.id}>
        {isDragging => {
          if (isDragging) {
            classNames = [...classNames, styles['placeholder-for-dragged-row']];
          }
          return (
            <div
              title={props.id}
              style={{ ...getStylesFromQrTemplateColumn() }}
              className={classNames.join(' ')}
              onClick={e => {
                e.stopPropagation();
                props.onSelect(props.id);
              }}
            >
              {renderContent()}
            </div>
          );
        }}
      </QrTemplateRowDraggableContainer>
    );
  }

  return (
    <div className={classNames.join(' ')} style={getStylesFromQrTemplateColumn()} title={props.id}>
      {renderContent()}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { loading, placeholder, id } = ownProps;
  if (loading) {
    return {
      loading: true,
    };
  }
  if (placeholder) {
    return {
      placeholder: true,
    };
  }
  let qrTemplateRow = EntitySelectors.getQrTemplateRow(state, id);
  let templateField = null;
  if (qrTemplateRow) {
    templateField = EntitySelectors.getTemplateField(state, qrTemplateRow.template_field_id);
  }
  return {
    qrTemplateRow,
    templateField,
  };
}

export default connect(mapStateToProps)(QrTemplateRow);
