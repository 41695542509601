import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid';
import styles from './style.module.scss';

const Tooltip = props => {
  const getColor = () => {
    if (props.dark) return '#26292B';
    if (props.light) return '#fff';
    return '#26292B';
  };
  const getTextColor = () => {
    if (props.dark) return '#fff';
    if (props.light) return '#515151';
    return '#fff';
  };
  const getPosition = () => {
    if (props.top) return 'top';
    if (props.bottom) return 'bottom';
    if (props.right) return 'right';
    if (props.left) return 'left';
    return 'top';
  };

  const getOffset = () => {
    if (props.top) return `{'top': ${props.margin}}`;
    if (props.bottom) return `{'bottom': ${props.margin}}`;
    if (props.left) return `{'left': ${props.margin}}`;
    if (props.right) return `{'right': ${props.margin}}`;
    return null;
  };
  const tempId = uuid.v4();
  if (props.disabled) {
    return props.trigger;
  }
  return (
    <>
      <div data-tip data-for={tempId}>
        {props.trigger}
      </div>
      {ReactDOM.createPortal(
        <ReactTooltip
          className={styles['tooltip']}
          backgroundColor={getColor()}
          textColor={getTextColor()}
          offset={getOffset()}
          effect="solid"
          delayShow={100}
          delayHide={100}
          place={getPosition()}
          id={tempId}
        >
          {props.label}
        </ReactTooltip>,
        document.getElementById('root')
      )}
    </>
  );
};

export default Tooltip;

Tooltip.defaultProps = {
  margin: 0,
  disabled: false,
};
