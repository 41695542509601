import * as API from './api';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeOperationalMaintenanceTemplateRecurrenceVersion } from 'sdk/Schemas';
import * as types from './reduxTypes';

export function createOperationalMaintenanceTemplateVersionForRecurrence(recurrenceId, params) {
  return dispatch => {
    return API.createOperationalMaintenanceTemplateVersionForRecurrence(recurrenceId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceTemplateRecurrenceVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurrenceId, params, data: res.data };
        dispatch({
          type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_RECURRENCE_VERSION_CREATED_FOR_RECURRENCE,
          payload,
        });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}

export function updateOperationalMaintenanceTemplareRecurrenceVersion(recurrenceId, params) {
  return dispatch => {
    return API.updateOperationalMaintenanceTemplareRecurrenceVersion(recurrenceId, params)
      .then(res => {
        const { entities } = normalizeOperationalMaintenanceTemplateRecurrenceVersion(res.data);
        dispatch(EntityOperations.updateEntities(entities));

        const payload = { recurrenceId, params, data: res.data };
        dispatch({ type: types.OPERATIONAL_MAINTENANCE_TEMPLATE_RECURRENCE_VERSION_UPDATED, payload });

        return payload;
      })
      .catch(e => {
        throw e;
      });
  };
}
