import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import { StocktakingSelectors } from 'state/ducks/stocktaking';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import ProgressCircleBar from '../../../../Components/ProgressCircleBar';
import styles from './style.module.scss';

class SparePartLocationItem extends Component {
  getPercentage = () => {
    const { total_count, completed_count } = this.props.count;
    if (total_count === 0) return 1;
    else return completed_count / total_count;
  };

  renderRightContainer = () => {
    if (this.props.stocktaking.completed) return null;

    return <ProgressCircleBar value={this.getPercentage()} />;
  };

  renderCompletedSubtitle = () => {
    const { completed_count } = this.props.count;

    return (
      <FormattedPlural
        value={completed_count}
        zero={<FormattedMessage id="screens.stocktaking-detail.locations.location-completed-subtitle.zero" />}
        one={<FormattedMessage id="screens.stocktaking-detail.locations.location-completed-subtitle.one" />}
        two={
          <FormattedMessage
            id="screens.stocktaking-detail.locations.location-completed-subtitle.two"
            values={{
              amount: completed_count,
            }}
          />
        }
        few={
          <FormattedMessage
            id="screens.stocktaking-detail.locations.location-completed-subtitle.few"
            values={{
              amount: completed_count,
            }}
          />
        }
        many={
          <FormattedMessage
            id="screens.stocktaking-detail.locations.location-completed-subtitle.many"
            values={{
              amount: completed_count,
            }}
          />
        }
        other={
          <FormattedMessage
            id="screens.stocktaking-detail.locations.location-completed-subtitle.other"
            values={{
              amount: completed_count,
            }}
          />
        }
      />
    );
  };

  renderSubtitle = () => {
    if (this.props.stocktaking.completed) return this.renderCompletedSubtitle();

    const { total_count, completed_count } = this.props.count;
    const countLeft = total_count - completed_count;

    if (total_count === 0) {
      return <FormattedMessage id="screens.stocktaking-detail.locations.location-subtitle-total-zero" />;
    } else if (countLeft === 0) {
      return <FormattedMessage id="screens.stocktaking-detail.locations.location-subtitle.zero" />;
    } else {
      return (
        <FormattedPlural
          value={countLeft}
          zero={<FormattedMessage id="screens.stocktaking-detail.locations.location-subtitle.zero" />}
          one={<FormattedMessage id="screens.stocktaking-detail.locations.location-subtitle.one" />}
          two={
            <FormattedMessage
              id="screens.stocktaking-detail.locations.location-subtitle.two"
              values={{
                amount: countLeft,
              }}
            />
          }
          few={
            <FormattedMessage
              id="screens.stocktaking-detail.locations.location-subtitle.few"
              values={{
                amount: countLeft,
              }}
            />
          }
          many={
            <FormattedMessage
              id="screens.stocktaking-detail.locations.location-subtitle.many"
              values={{
                amount: countLeft,
              }}
            />
          }
          other={
            <FormattedMessage
              id="screens.stocktaking-detail.locations.location-subtitle.other"
              values={{
                amount: countLeft,
              }}
            />
          }
        />
      );
    }
  };

  render() {
    return (
      <div className={styles['container']} onClick={this.props.onClick}>
        <div className={styles['title-container']}>
          <p className={styles['title']}>{this.props.sparePartLocation.title}</p>
          <p className={styles['subtitle']}>{this.renderSubtitle()}</p>
        </div>
        <div className={styles['right-container']}>{this.renderRightContainer()}</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    sparePartLocation: EntitySelectors.getSparePartLocation(state, ownProps.sparePartLocationId),
    count: StocktakingSelectors.getCountForLocation(state, ownProps.sparePartLocationId),
    stocktaking: EntitySelectors.getStocktaking(state, ownProps.match.params.id),
  };
}

export default withRouter(connect(mapStateToProps)(SparePartLocationItem));
