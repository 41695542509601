import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'views/components/Shared/General';
import { MenuUtils, MenuOperations } from 'state/ducks/menu';
import { AuthSelectors } from 'state/ducks/auth';
import HelperFunctions from 'utilities/HelperFunctions';
import Checklist_SWE from 'assets/images/ProScreenshots/checklist-pro-sv.png';
import Checklist_ENG from 'assets/images/ProScreenshots/checklist-pro-en.png';
import styles from './style.module.scss';

class ChecklistBasic extends Component {
  componentDidMount() {
    HelperFunctions.setDocumentTitle(
      this.props.intl.formatMessage({ id: 'screens.calendar.document-title' })
    );
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Checklists);
  }

  render() {
    return (
      <div className={styles['wrapper']}>
        <PerfectScrollbar>
          <div className={styles['svg-container']}>
            <svg
              width="1078"
              height="379"
              viewBox="0 0 1078 379"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0H1078V301.423C1078 301.423 814.5 379 539 379C263.5 379 0 301.423 0 301.423V0Z"
                fill="#E2EFF6"
              />
            </svg>
          </div>
          <div className={styles['container']}>
            <div className={styles['title']}>
              <FormattedMessage id="screens.checklists.basic.title" />
            </div>
            <div className={styles['subtitle']}>
              <FormattedMessage id="screens.checklists.basic.subtitle" />
            </div>
            <div className={styles['upgrade-screenshot-container']}>
              <img
                className={styles['upgrade-screenshot']}
                alt="screenshot"
                src={this.props.language === 'sv' ? Checklist_SWE : Checklist_ENG}
              />
            </div>
            <div className={styles['upgrade-buttons-container']}>
              <Button upgradeToPro large label="general.upgrade-to-pro" />
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectMenuItem: MenuOperations.selectItem,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    language: AuthSelectors.getLanguage(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChecklistBasic));
