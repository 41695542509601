import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { List, Icon } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { getIconClassNameString, getIconColor } from 'sdk/AttachmentVersion';
import styles from './style.module.scss';

class AttachmentListItem extends Component {
  renderTitle = () => (
    <React.Fragment>
      {this.props.attachmentVersion.title}
      {this.props.attachmentVersion.type === 'link' ? (
        <span className={styles['external-link-icon']}>
          <Icon type="external-link" regular />
        </span>
      ) : null}
    </React.Fragment>
  );

  renderSubtitle = () => {
    if (this.props.subtitle) {
      return this.props.subtitle;
    } else if (this.props.attachmentVersion.description) {
      return this.props.attachmentVersion.description;
    } else {
      return null;
    }
  };

  renderCreatedBy = () => {
    if (this.props.hideCreatedAt) return null;

    return (
      <div className={styles['created-by-container']}>
        <div className={styles['image']}>
          <ProfilePicture userId={this.props.attachmentVersion.created_by_user_id} />
        </div>
        <div className={styles['user']}>
          <p className={styles['time-ago']}>
            <FormattedMessage
              id="general.created-at"
              values={{
                timeAgo: moment(this.props.attachment.created_at).fromNow(),
              }}
            />
          </p>
          <p className={styles['name']}>
            <FormattedMessage
              id="general.created-by"
              values={{
                user: <UserNameWrapper user={this.props.createdByUser} />,
              }}
            />
          </p>
        </div>
      </div>
    );
  };

  renderButtons = () => {
    if (this.props.buttons) {
      return (
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {this.props.buttons}
        </div>
      );
    }
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item>
          <List.Item.IconColumn icon={<Icon type="file-alt" />} loading />
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          iconButtons={this.renderButtons()}
          clickable={this.props.clickable}
          onClick={() => this.props.onClick(this.props.attachment, this.props.attachmentVersion)}
        >
          <List.Item.Column width={30}>
            <Icon
              color={getIconColor(this.props.attachmentVersion)}
              type={getIconClassNameString(this.props.attachmentVersion)}
              size={20}
            />
          </List.Item.Column>
          <List.Item.TitleColumn flex title={this.renderTitle()} subtitle={this.renderSubtitle()} />
          <List.Item.Column width={200}>{this.renderCreatedBy()}</List.Item.Column>
        </List.Item>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) return {};

  const attachmentVersion = EntitySelectors.getAttachmentVersion(
    state,
    ownProps.attachment.attachment_version
  );

  return {
    attachmentVersion,
    attachmentFolder: EntitySelectors.getAttachmentFolder(state, ownProps.attachment.attachment_folder_id),
    createdByUser: EntitySelectors.getUser(state, attachmentVersion.created_by_user_id),
  };
}

export default connect(mapStateToProps, null)(AttachmentListItem);

AttachmentListItem.propTypes = {
  onClick: PropTypes.func,
  hideCreatedAt: PropTypes.bool,
  loading: PropTypes.bool,
};
AttachmentListItem.defaultProps = {
  onClick: () => {},
  loading: false,
  hideCreatedAt: false,
  clickable: true,
};
