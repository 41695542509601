import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SideModal } from 'views/components/Shared/Layout';
import Filter from './Filter';
import { VendorsSelectors } from 'state/ducks/vendors';
import { FILTER_TYPES } from '../';

class ApplyFilter extends Component {
  renderFilterTypeComparatorsForSelectedField = () => {
    const keys = this.props.selectedFilterType.split('.');
    switch (keys[0]) {
      case FILTER_TYPES.name: {
        return <Filter.Name />;
      }
      case FILTER_TYPES.organization_number: {
        return <Filter.OrgNumber />;
      }
      case FILTER_TYPES.phone: {
        return <Filter.Phone />;
      }
      case FILTER_TYPES.website: {
        return <Filter.Website />;
      }
      case FILTER_TYPES.email: {
        return <Filter.Email />;
      }
      case FILTER_TYPES.additional_information: {
        return <Filter.AdditionalInformation />;
      }
      case FILTER_TYPES.purchase_order_email: {
        return <Filter.PurchaseOrderEmail />;
      }
      case FILTER_TYPES.address1: {
        return <Filter.Address1 />;
      }
      case FILTER_TYPES.address2: {
        return <Filter.Address2 />;
      }
      case FILTER_TYPES.zip: {
        return <Filter.Zip />;
      }
      case FILTER_TYPES.city: {
        return <Filter.City />;
      }
      case FILTER_TYPES.country: {
        return <Filter.Country />;
      }
      case FILTER_TYPES.purchase_order_currency: {
        return <Filter.Currency />;
      }
      case FILTER_TYPES.default_purchase_order_payment_term_id: {
        return <Filter.PaymentTerm />;
      }
      case FILTER_TYPES.default_purchase_order_delivery_method_id: {
        return <Filter.DeliveryMethod />;
      }

      default:
        return null;
    }
  };

  render() {
    return (
      <SideModal.Container.Filter>
        <SideModal.Container.Filter.Detail>
          {this.renderFilterTypeComparatorsForSelectedField()}
        </SideModal.Container.Filter.Detail>
      </SideModal.Container.Filter>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFilterType: VendorsSelectors.getSelectedFilterType(state),
  };
}

export default connect(mapStateToProps)(ApplyFilter);
