import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LastUpdated from './LastUpdated';
import styles from './style.module.scss';

export default class Header extends Component {
  render() {
    return (
      <div className={styles['header']}>
        <div className={styles['title']}>
          <FormattedMessage id="screens.production-board.title" />
        </div>
        <div className={styles['right-container']}>
          <LastUpdated />
        </div>
      </div>
    );
  }
}
