import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntityOperations } from 'sdk/State/entities';
import { API } from 'sdk';
import { bindActionCreators } from 'redux';
import { AuthSelectors } from 'state/ducks/auth';
import { EntitySelectors } from 'sdk/State/entities';
import { normalizeMeter } from 'sdk/Schemas';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { Field, Button, Loader, NewInlineModal } from 'views/components/Shared/General';

class ChangeUnitModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    showDropDown: false,
    currentMeterUnit: this.props.currentMeterUnit || null,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
    }
  }
  updateUnit = () => {
    const params = {
      meter_unit_id: this.state.currentMeterUnit ? this.state.currentMeterUnit.id : null,
    };
    this.setState({ isSaving: true });
    API.updateMeter(this.props.id, params)
      .then(({ data: meter }) => {
        const { entities } = normalizeMeter(meter);
        this.props.updateEntities(entities);
        this.setState({ isSaving: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isSaving: false });
      });
  };

  renderUnitDropdown = () => {
    return (
      <>
        <div
          ref={ref => (this.inlineModalPositioningRef = ref)}
          onClick={() => this.setState(prevState => ({ showDropDown: !prevState.showDropDown }))}
        >
          <Field.Resource
            angleDown
            value={this.state.currentMeterUnit ? this.state.currentMeterUnit.title : null}
            onClear={() => {
              this.setState({ currentMeterUnit: null });
            }}
          />
        </div>
        <NewInlineModal
          positionToRef={this.inlineModalPositioningRef}
          open={this.state.showDropDown}
          onClose={() => this.setState({ showDropDown: false })}
        >
          <NewInlineModal.Dropdown>
            <NewInlineModal.Dropdown.Items>
              {this.props.meterUnits.map(meterUnit => {
                return (
                  <NewInlineModal.Dropdown.Item
                    onClick={() => {
                      this.setState({
                        showDropDown: false,
                        currentMeterUnit: meterUnit,
                      });
                    }}
                    selected={this.state.currentMeterUnit && this.state.currentMeterUnit.id === meterUnit.id}
                  >
                    <span>{meterUnit ? meterUnit.title : null}</span>
                  </NewInlineModal.Dropdown.Item>
                );
              })}
            </NewInlineModal.Dropdown.Items>
          </NewInlineModal.Dropdown>
        </NewInlineModal>
      </>
    );
  };

  renderFields = () => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>{this.renderUnitDropdown()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderContent = () => {
    if (!this.props.meter) return <Loader />;
    return (
      <>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="screens.meter.modals.change-unit.title" />}
          subtitleTopMargin
          subtitleMaxWidth="none"
          onClose={() => this.props.onClose()}
        />
        <Modal.Content>{this.renderFields()}</Modal.Content>
      </>
    );
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} width={460}>
          {this.renderContent()}
          <Modal.Footer>
            <Button.Group>
              <Button
                primary
                loading={this.state.isSaving}
                label="general.save"
                onClick={() => {
                  this.updateUnit();
                }}
              />
              <Button label="general.cancel" onClick={() => this.props.onClose()} />
            </Button.Group>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const meter = EntitySelectors.getMeter(state, ownProps.id);
  return {
    meter,
    currentMeterUnit: EntitySelectors.getMeterUnit(state, meter.meter_unit_id),
    meterUnits: AuthSelectors.getMeterUnits(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUnitModal);
