import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'views/components/Shared/General';
import PropTypes from 'prop-types';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

const SparePartTypePath = props => {
  if (props.sparePartTypePath.length === 0 && !props.fullPath) return null;
  const renderItem = sparePartType => {
    if (props.renderItem) {
      return props.renderItem(sparePartType);
    } else {
      return <span>{sparePartType.title}</span>;
    }
  };

  return (
    <div className={styles['path']}>
      {props.sparePartTypePath.map((sparePartType, i) => (
        <React.Fragment key={sparePartType.id}>
          {i === 0 ? null : <Icon type="arrow-alt-right" />}
          {renderItem(sparePartType)}
        </React.Fragment>
      ))}
      {props.fullPath ? (
        <React.Fragment>
          {props.sparePartTypePath.length > 0 ? <Icon type="arrow-alt-right" /> : null}
          {renderItem(props.sparePartType)}
        </React.Fragment>
      ) : null}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    sparePartTypePath: EntitySelectors.getPathForSparePartType(state, ownProps.sparePartTypeId),
    sparePartType: EntitySelectors.getSparePartType(state, ownProps.sparePartTypeId),
  };
}

export default connect(mapStateToProps)(SparePartTypePath);

SparePartTypePath.propTypes = {
  path: PropTypes.array,
  fullPath: PropTypes.bool,
  size: PropTypes.oneOf([10, 11, 12, 13]),
  dark: PropTypes.bool,
};
SparePartTypePath.defaultProps = {
  path: [],
  fullPath: false,
};
