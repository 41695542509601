import update from 'immutability-helper';
import types from './types';
import { SDKReduxTypes } from 'sdk';
import Utils from './utils';

const INITIAL_STATE = {
  bookmarkedAssetIds: [],
  assetDropdown: {
    selectedType: Utils.MenuItem.AllAssets,
    assetId: null,
  },
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SELECT_DROPDOWN_TYPE_FOR_ASSET_DROPDOWN: {
      return {
        ...state,
        assetDropdown: {
          ...state.assetDropdown,
          selectedType: action.payload,
          assetId: null,
        },
      };
    }
    case types.SELECT_ASSET_ID_FOR_ASSET_DROPDOWN: {
      return {
        ...state,
        assetDropdown: {
          ...state.assetDropdown,
          selectedType: null,
          assetId: action.payload,
        },
      };
    }
    case SDKReduxTypes.ASSET_BOOKMARK_CREATED: {
      const { id } = action.payload.data;
      return {
        ...state,
        bookmarkedAssetIds: [id, ...state.bookmarkedAssetIds],
      };
    }
    case SDKReduxTypes.ASSET_BOOKMARK_DELETED: {
      const { assetBookmarkId } = action.payload;
      return {
        ...state,
        bookmarkedAssetIds: state.bookmarkedAssetIds.filter(id => id !== assetBookmarkId),
      };
    }
    case types.FETCH_BOOKMARKED_ASSETS_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        bookmarkedAssetIds: ids,
        assetDropdown: {
          ...state.assetDropdown,
        },
      };
    }

    default:
      return state;
  }
};
