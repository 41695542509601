import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Decimal } from 'decimal.js';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import { HelperFunctions } from 'sdk';
import { EmptyDataSet } from 'views/components/Shared/General';
import { REPORT_PER_TYPES } from 'sdk/WorkOrder';
import styles from './style.module.scss';

class ListView extends PureComponent {
  state = {
    isBuildingDataStructure: true,
  };

  componentDidMount() {
    const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
    const showingAssignees = this.props.perType === REPORT_PER_TYPES.Assignee;
    const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
    const showingUsers = this.props.perType === REPORT_PER_TYPES.CompletedBy;
    if (this.props.isFetching) {
      return;
    }
    if (showingWorkOrderTypes) {
      this.buildWorkOrderTypesDataStructure();
    } else if (showingAssignees) {
      this.buildAssigneesDataStructure();
    } else if (showingAssets) {
      this.buildAssetsDataStructure();
    } else if (showingUsers) {
      this.buildUsersDataStructure();
    } else {
      this.setState({ isBuildingDataStructure: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.perType !== this.props.perType) {
      this.setState({ isBuildingDataStructure: true });
    } else if (!prevProps.isFetching && this.props.isFetching) {
      this.setState({ isBuildingDataStructure: true });
    } else {
      const finishedLoading = prevProps.isFetching && !this.props.isFetching;
      const changedSortOrder = prevProps.sortingOnPerType !== this.props.sortingOnPerType;
      if (finishedLoading || (!this.props.isFetching && changedSortOrder)) {
        const showingWorkOrderTypes = this.props.perType === REPORT_PER_TYPES.WorkOrderType;
        const showingAssignees = this.props.perType === REPORT_PER_TYPES.Assignee;
        const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
        const showingUsers = this.props.perType === REPORT_PER_TYPES.CompletedBy;
        if (showingWorkOrderTypes) {
          this.buildWorkOrderTypesDataStructure();
        } else if (showingAssignees) {
          this.buildAssigneesDataStructure();
        } else if (showingAssets) {
          this.buildAssetsDataStructure();
        } else if (showingUsers) {
          this.buildUsersDataStructure();
        } else {
          this.setState({ isBuildingDataStructure: false });
        }
      }
    }
  }

  buildUsersDataStructure = () => {
    let users = this.props.completedByUsers.map(({ name, id }) => ({
      name,
      count: new Decimal(this.props.data[id] || 0),
      id,
    }));
    if (this.props.sortingOnType === this.props.type) {
      users = users.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      users = users.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.setState({
      users,
      isBuildingDataStructure: false,
    });
  };

  buildAssigneesDataStructure = () => {
    const { assigneeUsers, assigneeGroups, assigneeVendors } = this.props;
    let userData = {};
    let groupData = {};
    let vendorData = {};
    Object.keys(this.props.data).forEach(key => {
      if (key.charAt(0) === 'u') {
        const count = this.props.data[key];
        const userId = key.substring(2);
        userData = {
          ...userData,
          [userId]: count,
        };
      } else if (key.charAt(0) === 'g') {
        const groupId = key.substring(2);
        const count = this.props.data[key];
        groupData = {
          ...groupData,
          [groupId]: count,
        };
      } else if (key.charAt(0) === 'v') {
        const vendorId = key.substring(2);
        const count = this.props.data[key];
        vendorData = {
          ...vendorData,
          [vendorId]: count,
        };
      }
    });
    let assignees = [
      ...assigneeUsers.map(({ name, id }) => ({
        title: name,
        count: new Decimal(userData[id] || 0),
      })),
      ...assigneeGroups.map(({ title, id }) => ({
        title,
        count: new Decimal(groupData[id] || 0),
      })),
      ...assigneeVendors.map(({ name, id }) => ({
        title: name,
        count: new Decimal(vendorData[id] || 0),
      })),
    ];

    if (this.props.sortingOnType === this.props.type) {
      assignees = assignees.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      assignees = assignees.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      assignees,
      isBuildingDataStructure: false,
    });
  };

  buildAssetsDataStructure = () => {
    let assets = this.props.assets.map(({ title, id }) => ({
      title,
      count: new Decimal(this.props.data[id] || 0),
      id,
    }));
    if (this.props.sortingOnType === this.props.type) {
      assets = assets.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      assets = assets.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      assets,
      isBuildingDataStructure: false,
    });
  };

  buildWorkOrderTypesDataStructure = () => {
    let workOrderTypes = this.props.workOrderTypes.map(({ title, id }) => ({
      title,
      count: new Decimal(this.props.data[id] || 0),
    }));
    if (this.props.sortingOnType === this.props.type) {
      workOrderTypes = workOrderTypes.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      workOrderTypes = workOrderTypes.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      workOrderTypes,
      isBuildingDataStructure: false,
    });
  };

  renderPriorityList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const items = [
        { priority: 'high', count: new Decimal(this.props.data['high'] || 0) },
        { priority: 'medium', count: new Decimal(this.props.data['medium'] || 0) },
        { priority: 'low', count: new Decimal(this.props.data['low'] || 0) },
      ];
      items.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      return items.map(({ priority, count }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <FormattedMessage id={`resources.work-order.prio.${priority}`} />
          </div>
          <div className={styles['value']}>
            <FormattedMessage
              id="components.work-orders-overview.count"
              values={{ value: count.toString() }}
            />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.high" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['high'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.medium" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['medium'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.prio.low" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['low'] || 0 }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderCategoriesSimpleList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const categories = [
        {
          category: 'corrective',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.corrective' }),
          count: new Decimal(this.props.data['corrective'] || 0),
        },
        {
          category: 'preventive',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.preventive' }),
          count: new Decimal(this.props.data['preventive'] || 0),
        },
        {
          category: 'improvement',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.improvement' }),
          cost: new Decimal(this.props.data['improvement'] || 0),
        },
        {
          category: 'modification',
          label: this.props.intl.formatMessage({ id: 'resources.work-order.categories.modification' }),
          cost: new Decimal(this.props.data['modification'] || 0),
        },
      ];
      categories.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      return categories.map(({ count, label }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <span>{label}</span>
          </div>
          <div className={styles['value']}>
            <FormattedMessage
              id="components.work-orders-overview.count"
              values={{ value: count.toString() }}
            />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['corrective'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['preventive'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.improvement" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['improvement'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.modification" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['modification'] || 0 }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderCategoriesDetailedList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    if (sortingOnType === type) {
      const categories = [
        {
          category: 'corrective_immediate',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-immediate',
          })}`,
          count: new Decimal(this.props.data['corrective_immediate'] || 0),
        },
        {
          category: 'corrective_defered',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.corrective-defered',
          })}`,
          count: new Decimal(this.props.data['corrective_defered'] || 0),
        },
        {
          category: 'preventive_condition_based',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-condition-based',
          })}`,
          count: new Decimal(this.props.data['preventive_condition_based'] || 0),
        },
        {
          category: 'preventive_predetermined',
          label: `${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive',
          })} - ${this.props.intl.formatMessage({
            id: 'resources.work-order.categories.preventive-predetermined',
          })}`,
          count: new Decimal(this.props.data['preventive_predetermined'] || 0),
        },
      ];
      categories.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
      return categories.map(({ count, label }) => (
        <div className={styles['item']}>
          <div className={styles['label']}>
            <span>{label}</span>
          </div>
          <div className={styles['value']}>
            <FormattedMessage
              id="components.work-orders-overview.count"
              values={{ value: count.toString() }}
            />
          </div>
        </div>
      ));
    } else if (sortingOnPerType === perType) {
      return (
        <>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.corrective-immediate" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['corrective_immediate'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.corrective" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.corrective-defered" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['corrective_defered'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.preventive-condition-based" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['preventive_condition_based'] || 0 }}
              />
            </div>
          </div>
          <div className={styles['item']}>
            <div className={styles['label']}>
              <FormattedMessage id="resources.work-order.categories.preventive" />
              <span> - </span>
              <FormattedMessage id="resources.work-order.categories.preventive-predetermined" />
            </div>
            <div className={styles['value']}>
              <FormattedMessage
                id="components.work-orders-overview.count"
                values={{ value: this.props.data['preventive_predetermined'] || 0 }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  renderWorkOrderTypeList = () => {
    if (this.props.workOrderTypes.length === 0)
      return (
        <div className={styles['list-item-empty-data-set']}>
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.work-orders-overview.no-work-order-types-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.work-orders-overview.no-work-order-types-empty-data-set.subtitle" />
            }
          />
        </div>
      );
    return this.state.workOrderTypes.map(({ title, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{title}</span>
        </div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderAssetList = () => {
    if (this.props.assets.length === 0) {
      if (this.props.showingRootAssets) {
        return (
          <div className={styles['list-item-empty-data-set']}>
            <EmptyDataSet
              modal
              title={<FormattedMessage id="components.work-orders-overview.no-assets-empty-data-set.title" />}
              subtitle={
                <FormattedMessage id="components.work-orders-overview.no-assets-empty-data-set.subtitle" />
              }
            />
          </div>
        );
      } else {
        return (
          <div className={styles['list-item-empty-data-set']}>
            <EmptyDataSet
              modal
              title={
                <FormattedMessage id="components.work-orders-overview.no-children-assets-empty-data-set.title" />
              }
              subtitle={
                <FormattedMessage id="components.work-orders-overview.no-children-assets-empty-data-set.subtitle" />
              }
            />
          </div>
        );
      }
    }

    return this.state.assets.map(({ title, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{title}</span>
        </div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderAssigneesList = () => {
    return this.state.assignees.map(({ title, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{title}</span>
        </div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderUsersList = () => {
    return this.state.users.map(({ name, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span>{name}</span>
        </div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderYearList = () => {
    const { type, perType, sortingOnType, sortingOnPerType } = this.props;
    const currentYear = moment().year();
    let amountOfYearsSinceSystemWasCreated = currentYear - this.props.yearSystemWasCreated;
    let items = []; // [{ year, count: Decimal }]
    for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
      const year = currentYear - i;
      items.push({ year, count: new Decimal(this.props.data[year] || 0) });
    }
    if (sortingOnType === type) {
      items.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (sortingOnPerType === perType) {
      items.sort((a, b) => a.year - b.year);
    }
    return items.map(({ year, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>{year}</div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderMonthsList = () => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({
        monthNum: i,
        month: HelperFunctions.capitalizeFirstLetter(
          moment()
            .month(i - 1)
            .format('MMMM')
        ),
        count: new Decimal(this.props.data[i] || 0),
      });
    }
    if (this.props.sortingOnType === this.props.type) {
      months.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      months.sort((a, b) => a.monthNum - b.monthNum);
    }
    return months.map(({ month, count }) => (
      <div className={styles['item']}>
        <div className={styles['label']}>{month}</div>
        <div className={styles['value']}>
          <FormattedMessage id="components.work-orders-overview.count" values={{ value: count.toString() }} />
        </div>
      </div>
    ));
  };

  renderLoadingList = () => (
    <>
      <div className={styles['item']}>
        <div className={styles['label']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
        <div className={styles['value']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={styles['item']}>
        <div className={styles['label']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
        <div className={styles['value']}>
          <div style={{ width: 100, height: 16 }}>
            <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
              <rect x="0" y="0" rx="3" ry="3" width="100" height="14" />
            </ContentLoader>
          </div>
        </div>
      </div>
    </>
  );

  renderList = () => {
    if (this.props.isFetching || this.state.isBuildingDataStructure) return this.renderLoadingList();
    switch (this.props.perType) {
      case REPORT_PER_TYPES.Year: {
        return this.renderYearList();
      }
      case REPORT_PER_TYPES.Month: {
        return this.renderMonthsList();
      }
      case REPORT_PER_TYPES.Priority: {
        return this.renderPriorityList();
      }
      case REPORT_PER_TYPES.WorkOrderType: {
        return this.renderWorkOrderTypeList();
      }
      case REPORT_PER_TYPES.Asset: {
        return this.renderAssetList();
      }
      case REPORT_PER_TYPES.Assignee: {
        return this.renderAssigneesList();
      }
      case REPORT_PER_TYPES.CompletedBy: {
        return this.renderUsersList();
      }
      case REPORT_PER_TYPES.WorkOrderCategorySimple: {
        return this.renderCategoriesSimpleList();
      }
      case REPORT_PER_TYPES.WorkOrderCategoryDetailed: {
        return this.renderCategoriesDetailedList();
      }
      default:
        return null;
    }
  };

  renderHeader = () => {
    if (this.props.perType === REPORT_PER_TYPES.WorkOrderType && this.props.workOrderTypes.length === 0)
      return null;
    if (this.props.perType === REPORT_PER_TYPES.Asset && this.props.assets.length === 0) return null;
    return (
      <div className={styles['header']}>
        <div className={styles['label']}>
          <FormattedMessage id={`components.work-orders-overview.show-per-types.${this.props.perType}`} />
        </div>
        <div className={styles['value']}>
          <FormattedMessage id={`components.work-orders-overview.show-types.${this.props.type}`} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles['list']}>
        {this.renderHeader()}
        {this.renderList()}
      </div>
    );
  }
}

export default injectIntl(ListView);
