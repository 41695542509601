import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkOrderCategory } from 'sdk/WorkOrder';

export default class CategoryText extends Component {
  render() {
    switch (this.props.category) {
      case WorkOrderCategory.Corrective: {
        return <FormattedMessage id="resources.work-order.categories-short.corrective" />;
      }
      case WorkOrderCategory.CorrectiveImmediate: {
        return <FormattedMessage id="resources.work-order.categories-short.corrective-immediate" />;
      }
      case WorkOrderCategory.CorrectiveDefered: {
        return <FormattedMessage id="resources.work-order.categories-short.corrective-defered" />;
      }
      case WorkOrderCategory.Preventive: {
        return <FormattedMessage id="resources.work-order.categories-short.preventive" />;
      }
      case WorkOrderCategory.PreventiveConditionBased: {
        return <FormattedMessage id="resources.work-order.categories-short.preventive-condition-based" />;
      }
      case WorkOrderCategory.PreventivePredetermined: {
        return <FormattedMessage id="resources.work-order.categories-short.preventive-predetermined" />;
      }
      case WorkOrderCategory.Improvement: {
        return <FormattedMessage id="resources.work-order.categories-short.improvement" />;
      }
      case WorkOrderCategory.Modification: {
        return <FormattedMessage id="resources.work-order.categories-short.modification" />;
      }
      default:
        return null;
    }
  }
}
