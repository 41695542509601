const DOMAIN_PREFIX = 'assetVendor/';

const RESET_STATE = `${DOMAIN_PREFIX}reset_state`;
const PREPARE_NEW_ASSET_VENDOR = `${DOMAIN_PREFIX}PREPARE_NEW_ASSET_VENDOR`;
const CHANGE_ASSET_VENDOR_VALUES = `${DOMAIN_PREFIX}CHANGE_ASSET_VENDOR_VALUES`;
const SET_ASSET_IDS_FOR_NEW = `${DOMAIN_PREFIX}set_asset_ids_for_new`;

const SET_ASSET_VENDOR = `${DOMAIN_PREFIX}set_asset_vendor`;
const SET_CONTACT_PERSONS = `${DOMAIN_PREFIX}set_contact_persons`;

export default {
  RESET_STATE,
  PREPARE_NEW_ASSET_VENDOR,
  CHANGE_ASSET_VENDOR_VALUES,
  SET_ASSET_VENDOR,
  SET_CONTACT_PERSONS,
  SET_ASSET_IDS_FOR_NEW,
};
