import update from 'immutability-helper';
import types from './types';

const INITIAL_STATE = {
  assetId: null,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.CHECK_IN_ASSET: {
      return {
        ...state,
        assetId: action.payload,
      };
    }
    default:
      return state;
  }
};
