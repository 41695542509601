import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { EntitySelectors } from 'sdk/State/entities';
import PropTypes from 'prop-types';
import {
  List,
  MoneyWithCurrency,
  Button,
  EmptyDataSet,
  WhiteCard,
  Datapill,
} from 'views/components/Shared/General';
import { getEstimatedDeliveryDateText } from 'sdk/PurchaseOrder';
import { ListItemStatus } from 'views/components/PurchaseOrder';
import { AuthSelectors } from 'state/ducks/auth';
import PurchaseOrderRowListItem from './PurchaseOrderRowListItem';
import SparePartSmall from 'assets/images/EmptyDataSet/SparePartSmall.png';
import styles from './style.module.scss';

class PurchaseOrderListItem extends Component {
  renderActiveDeliveries = () => {
    return (
      <>
        <List.Header tiny blue>
          <List.Header.Column width={100}>
            <FormattedMessage id="resources.purchase-order-row.number" />
          </List.Header.Column>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-row.title" />
          </List.Header.Column>
          <List.Header.Column width={150} alignRight>
            <FormattedMessage id="screens.purchase-orders.active-deliveries.rows.ordered" />
          </List.Header.Column>
          <List.Header.Column width={150} alignRight>
            <FormattedMessage id="screens.purchase-orders.active-deliveries.rows.delivered" />
          </List.Header.Column>
          <List.Header.Column width={150} alignRight>
            <FormattedMessage id="screens.purchase-orders.active-deliveries.rows.summary" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.purchaseOrderRows.map(purchaseOrderRow => (
            <PurchaseOrderRowListItem
              list={this.props.list}
              purchaseOrder={this.props.purchaseOrder}
              purchaseOrderRow={purchaseOrderRow}
            />
          ))}
        </List>
      </>
    );
  };

  renderPurchaseOrderRows = () => {
    return (
      <>
        <List.Header tiny blue>
          <List.Header.Column width={100}>
            <FormattedMessage id="resources.purchase-order-row.number" />
          </List.Header.Column>
          <List.Header.Column flex>
            <FormattedMessage id="resources.purchase-order-row.title" />
          </List.Header.Column>
          <List.Header.Column width={150} alignRight>
            <FormattedMessage id="resources.purchase-order-row.quantity" />
          </List.Header.Column>
          <List.Header.Column width={150} alignRight>
            <FormattedMessage id="resources.purchase-order-row.total-cost" />
          </List.Header.Column>
        </List.Header>
        <List>
          {this.props.purchaseOrderRows.map(purchaseOrderRow => (
            <PurchaseOrderRowListItem
              list={this.props.list}
              purchaseOrder={this.props.purchaseOrder}
              purchaseOrderRow={purchaseOrderRow}
            />
          ))}
        </List>
      </>
    );
  };

  renderExpandedComponent = () => {
    if (this.props.purchaseOrderRows.length === 0) {
      return (
        <WhiteCard centerContent>
          <EmptyDataSet
            title={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.title" />}
            subtitle={<FormattedMessage id="screens.purchase-order.info.articles.empty-data-set.subtitle" />}
            image={SparePartSmall}
            tiny
            horizontal
            listContainer
          />
        </WhiteCard>
      );
    }
    if (this.props.list === 'awaiting_delivery') {
      return this.renderActiveDeliveries();
    }
    return this.renderPurchaseOrderRows();
  };

  renderColumns = () => {
    const { list } = this.props;
    if (list === 'draft') {
      return this.renderDraftColumns();
    }
    if (list === 'awaiting_delivery') {
      return this.renderAwaitingDeliveryColumns();
    }
    if (list === 'fully_delivered') {
      return this.renderFullyDeliveredColumns();
    }
    return this.renderAllPurchaseOrdersColumns();
  };

  renderDraftColumns = () => {
    return (
      <>
        <List.Item.TitleColumn
          title={<p>{this.props.vendor.name}</p>}
          subtitle={<p className={styles['subtitle']}>{`#${this.props.purchaseOrder.number}`}</p>}
        />
        <List.Item.Column width={220}>
          {moment(this.props.purchaseOrder.created_at).format('LL')}
        </List.Item.Column>
        <List.Item.Column width={220} alignRight>
          <MoneyWithCurrency
            currency={this.props.purchaseOrder.currency}
            value={this.props.purchaseOrder.total}
          />
        </List.Item.Column>
      </>
    );
  };

  renderAwaitingDeliveryColumns = () => {
    return (
      <>
        <List.Item.TitleColumn
          title={<p>{this.props.vendor.name}</p>}
          subtitle={<p className={styles['subtitle']}>{`#${this.props.purchaseOrder.number}`}</p>}
        />
        <List.Item.Column width={300}>
          {getEstimatedDeliveryDateText(this.props.purchaseOrder)}
        </List.Item.Column>
        <List.Item.Column width={120} alignRight>
          <MoneyWithCurrency
            currency={this.props.purchaseOrder.currency}
            value={this.props.purchaseOrder.total}
          />
        </List.Item.Column>
        <List.Item.Column width={150} alignRight>
          {this.props.canRegisterDeliveries ? (
            <Button
              gray
              label="screens.purchase-orders.create-delivery"
              onClick={e => {
                e.preventDefault();
                this.props.onCreateDelivery(this.props.purchaseOrder);
              }}
            />
          ) : null}
        </List.Item.Column>
      </>
    );
  };

  renderFullyDeliveredColumns = () => {
    let deliveryDate = this.props.purchaseOrder.ordered_date;
    if (this.props.purchaseOrder.most_recent_delivery_at) {
      deliveryDate = this.props.purchaseOrder.most_recent_delivery_at;
    }
    return (
      <>
        <List.Item.TitleColumn
          title={<p>{this.props.vendor.name}</p>}
          subtitle={<p className={styles['subtitle']}>{`#${this.props.purchaseOrder.number}`}</p>}
        />
        <List.Item.Column width={468}>{moment(deliveryDate).format('LL')}</List.Item.Column>
        <List.Item.Column width={120} alignRight>
          <MoneyWithCurrency
            currency={this.props.purchaseOrder.currency}
            value={this.props.purchaseOrder.total}
          />
        </List.Item.Column>
      </>
    );
  };

  renderAllPurchaseOrdersColumns = () => {
    return (
      <>
        <List.Item.TitleColumn
          title={<p>{this.props.vendor.name}</p>}
          subtitle={<p className={styles['subtitle']}>{`#${this.props.purchaseOrder.number}`}</p>}
        />
        <List.Item.Column width={150}>
          <ListItemStatus purchaseOrder={this.props.purchaseOrder} />
        </List.Item.Column>
        <List.Item.Column width={120} alignRight>
          <MoneyWithCurrency
            currency={this.props.purchaseOrder.currency}
            value={this.props.purchaseOrder.total}
          />
        </List.Item.Column>
      </>
    );
  };

  renderOurReferenceDatapill = () => {
    return (
      <Datapill
        value={this.props.ourReferenceUser ? this.props.ourReferenceUser.name : '-'}
        label={<FormattedMessage id="resources.purchase-order.our-ref" />}
      />
    );
  };

  renderOrderedDateDataPill = () => {
    if (!this.props.purchaseOrder.ordered_date) {
      return null;
    }
    return (
      <Datapill
        value={moment(this.props.purchaseOrder.ordered_date).format('LL')}
        label={<FormattedMessage id="resources.purchase-order.ordered-date" />}
      />
    );
  };

  renderDataPills = () => {
    return (
      <Datapill.List>
        {this.renderOurReferenceDatapill()}
        {this.renderOrderedDateDataPill()}
      </Datapill.List>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <List.Item small expandable checkbox={this.props.canEditPurchaseOrders}>
          <List.Item.TitleColumn loading />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          clickable
          onClick={this.props.onClick}
          linkTo={`/purchase-orders/${this.props.purchaseOrder.id}`}
          small
          expandable
          dataPillsComponent={this.renderDataPills()}
          outerExpandedComponent={this.renderExpandedComponent()}
          checked={this.props.checked}
          onCheck={this.props.onCheck}
          checkbox={this.props.canEditPurchaseOrders}
          checkboxDisabled={this.props.checkboxDisabled}
        >
          {this.renderColumns()}
        </List.Item>
      );
    }
  }
}
function mapStateToProps(state, ownProps) {
  if (ownProps.loading)
    return {
      canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    };
  return {
    vendor: EntitySelectors.getVendor(state, ownProps.purchaseOrder.vendor_id),
    ourReferenceUser: EntitySelectors.getUser(state, ownProps.purchaseOrder.our_reference_user_id),
    canEditPurchaseOrders: AuthSelectors.canEditPurchaseOrders(state),
    canRegisterDeliveries: AuthSelectors.canRegisterDeliveries(state),
    purchaseOrderRows: EntitySelectors.getPurchaseOrderRows(
      state,
      ownProps.purchaseOrder.purchase_order_rows
    ),
  };
}

export default withRouter(connect(mapStateToProps)(PurchaseOrderListItem));

PurchaseOrderListItem.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};
PurchaseOrderListItem.defaultProps = {
  onClick: () => {},
  loading: false,
};
