import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { RequestStatus } from 'sdk/Request';
import { RequestsOperations } from 'state/ducks/requests';
import { EntitySelectors } from 'sdk/State/entities';
import { PriorityText } from 'views/components/WorkOrder';
import { TreePath } from 'views/components/Asset';
import { Datapill } from 'views/components/Shared/General';
import { HelperFunctions } from 'sdk';
import { StatusColumn, Title } from 'views/components/Request';
import { AuthSelectors } from 'state/ducks/auth';
import { RequestsSelectors } from 'state/ducks/requests';
import styles from './style.module.scss';

class ListItem extends PureComponent {
  changeQueryParams = obj => {
    this.props.history.push(
      `?${HelperFunctions.convertObjToQueryParameters({
        ...queryString.parse(this.props.location.search),
        ...obj,
      })}`
    );
  };

  selectRequest = () => {
    const selectedRequestId = queryString.parse(this.props.location.search).request_id;
    if (selectedRequestId === this.props.request.id) {
      this.changeQueryParams({ request_id: null });
    } else {
      this.changeQueryParams({ request_id: this.props.request.id });
    }
  };

  renderTitle = () => {
    return (
      <div className={styles['title-container']}>
        <div className={styles['title']}>
          <Title request={this.props.request} />
        </div>
        {this.props.request.status === RequestStatus.Active ? null : (
          <div className={styles['status']}>
            <StatusColumn requestId={this.props.request.id} />
          </div>
        )}
      </div>
    );
  };

  renderTreePath = () => {
    if (this.props.asset == null) return <div className={styles['subtitle']}>-</div>;
    return (
      <div className={styles['subtitle']}>
        <TreePath assetId={this.props.asset.id} fullPath />
      </div>
    );
  };

  renderLoader = () => {
    return (
      <div className={styles['item']}>
        <div style={{ width: 100, height: 22 }}>
          <ContentLoader viewBox="0 0 100 22" preserveAspectRatio="xMinYMin">
            <rect x="0" y="0" rx="3" ry="3" width="100" height="6" />
            <rect x="0" y="16" rx="3" ry="3" width="70" height="6" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  renderListItem = () => {
    return (
      <>
        {this.renderTitle()}
        {this.renderTreePath()}
        {this.renderDataPills()}
      </>
    );
  };

  renderAssetNumberDataPill = () => {
    if (this.props.settings.asset_number_activated && this.props.asset) {
      return (
        <Datapill
          label={<FormattedMessage id="resources.asset.number-long" />}
          value={`#${this.props.asset.number}`}
        />
      );
    }
    return null;
  };

  renderPriorityDataPill = () => {
    if (this.props.request.priority) {
      return <Datapill value={<PriorityText priority={this.props.request.priority} />} />;
    }
    return null;
  };

  renderRequestTypeDataPill = () => {
    if (this.props.requestType) {
      return <Datapill value={this.props.requestType.title} />;
    }
    return null;
  };

  renderCreatedByDataPill = () => {
    if (this.props.createdByUser) {
      return <Datapill value={this.props.createdByUser.name} />;
    }
    return null;
  };

  renderHasDowntimeDataPill = () => {
    if (!this.props.request.downtime_id) {
      return null;
    }
    return <Datapill red value={<FormattedMessage id="resources.downtime.resource" />} />;
  };

  renderDataPills = () => {
    return (
      <div className={styles['data-pills-container']}>
        <Datapill.List>
          {this.renderPriorityDataPill()}
          {this.renderRequestTypeDataPill()}
          {this.renderCreatedByDataPill()}
          {this.renderAssetNumberDataPill()}
          {this.renderHasDowntimeDataPill()}
        </Datapill.List>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }
    const { request_id } = queryString.parse(this.props.location.search);
    const isSelected = this.props.request.id === request_id && !this.props.isNewRequest;
    return (
      <div
        onClick={this.selectRequest}
        className={`${styles['item']} ${isSelected ? styles['selected'] : ''}`}
      >
        <div className={styles['item-content']}>{this.renderListItem()}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideFilter: RequestsOperations.hideFilter,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  if (ownProps.loading) {
    return {};
  }
  const request = EntitySelectors.getRequest(state, ownProps.requestId);
  return {
    request,
    asset: EntitySelectors.getAsset(state, request.asset_id),
    requestType: EntitySelectors.getRequestType(state, request.request_type_id),
    createdByUser: EntitySelectors.getUser(state, request.created_by_user_id),
    workOrder: EntitySelectors.getWorkOrder(state, request.work_order_id),
    isNewRequest: RequestsSelectors.isNewRequest(state),
    settings: AuthSelectors.getSettings(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListItem));
