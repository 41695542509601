import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

const WorkOrderSpentTimeCategoryContainer = ({
  value,
  workOrderSpentTimeCategories,
  workOrderSpentTimeCategory,
  onChange,
}) => (
  <Field label={<FormattedMessage id="resources.work-order-spent-time.category" />}>
    <Field.Dropdown
      value={workOrderSpentTimeCategory == null ? null : workOrderSpentTimeCategory.title}
      onChange={work_order_spent_time_category_id => onChange(work_order_spent_time_category_id)}
      onClear={() => onChange(null)}
    >
      {workOrderSpentTimeCategories.map(({ title, id }) => (
        <Field.Dropdown.Item selected={value === id} onClick={() => onChange(id)}>
          {title}
        </Field.Dropdown.Item>
      ))}
    </Field.Dropdown>
  </Field>
);

function mapStateToProps(state, ownProps) {
  return {
    workOrderSpentTimeCategory: EntitySelectors.getWorkOrderSpentTimeCategory(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(WorkOrderSpentTimeCategoryContainer);
