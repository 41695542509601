import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'react-hot-toast';
import { SDKReduxOperations } from 'sdk';
import { FormattedMessage } from 'react-intl';
import { Button, Field, List } from 'views/components/Shared/General';
import { Modal, ToastMessage } from 'views/components/Shared/Layout';
import { AuthSelectors } from 'state/ducks/auth';
import { API, HelperFunctions } from 'sdk';
import styles from './style.module.scss';

class DeleteModal extends Component {
  getInitialState = () => ({
    isFetching: true,
    isDeleting: false,
    confirmDeletion: false,
    operationalMaintenanceInstanceCount: 0,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.getInitialState(),
      });
      this.fetchOperationalMaintenanceInstancesCount();
    }
  }

  fetchOperationalMaintenanceInstancesCount = () => {
    API.listOperationalMaintenanceInstances(this.props.system.id, {
      operational_maintenance_template_id: this.props.id,
    }).then(({ headers }) => {
      this.setState({
        isFetching: false,
        operationalMaintenanceInstanceCount: HelperFunctions.getPaginationFromHeader(headers).totalEntries,
      });
    });
  };

  deleteOperationalMaintenanceTemplate = () => {
    this.setState({ isDeleting: true });
    this.props
      .deleteOperationalMaintenanceTemplate(this.props.id)
      .then(() => {
        this.props.onDelete();
      })
      .catch(() => {
        toast(<ToastMessage error text={<FormattedMessage id="general.errors.general-error" />} />);
        this.props.onClose();
      });
  };

  render() {
    return (
      <Modal width={480} isOpen={this.props.open}>
        <Modal.Header
          title={<FormattedMessage id="screens.operational-maintenance-template.delete-modal.title" />}
          onClose={this.props.onClose}
          ignoreLine
        />
        {this.state.isFetching ? (
          <Modal.Loader />
        ) : (
          <>
            <Modal.Content>
              <div className={styles['message']}>
                <FormattedMessage id="screens.operational-maintenance-template.delete-modal.confirm-subtitle" />
              </div>
              <div className={styles['list-container']}>
                <List light small>
                  <List.Item>
                    <List.Item.TitleColumn
                      title={
                        <FormattedMessage id="screens.operational-maintenance-template.delete-modal.operational-maintenances-count" />
                      }
                    />
                    <List.Item.Column alignRight>
                      <span
                        className={
                          this.state.operationalMaintenanceInstanceCount > 0
                            ? styles['warning-text']
                            : styles['empty-text']
                        }
                      >
                        <FormattedMessage
                          id="components.asset-delete.delete-items.asset-amount"
                          values={{ amount: this.state.operationalMaintenanceInstanceCount }}
                        />
                      </span>
                    </List.Item.Column>
                  </List.Item>
                </List>
              </div>
              <Field.Checkbox
                checked={this.state.confirmDeletion}
                onChange={value => this.setState({ confirmDeletion: value })}
                label={<FormattedMessage id="components.asset-delete.confirm-checkbox-text" />}
              />
            </Modal.Content>
            <Modal.Footer>
              <Button.Group>
                <Button
                  destructive
                  disabled={!this.state.confirmDeletion}
                  primary
                  label="general.delete"
                  loading={this.state.isDeleting}
                  onClick={this.deleteOperationalMaintenanceTemplate}
                />
                <Button label="general.cancel" onClick={this.props.onClose} />
              </Button.Group>
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteOperationalMaintenanceTemplate: SDKReduxOperations.deleteOperationalMaintenanceTemplate,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
