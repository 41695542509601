import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Loader } from 'views/components/Shared/General';
import { Decimal } from 'decimal.js';
import { HelperFunctions } from 'sdk';
import moment from 'moment';
import { EmptyDataSet } from 'views/components/Shared/General';
import { REPORT_PER_TYPES } from 'sdk/Request';
import styles from './style.module.scss';

const BAR_COLOR = '#4C9AC8';

class Chart extends PureComponent {
  state = {
    labels: [],
    datasets: [],
    isBuildingDataStructure: true,
    isEmpty: false,
  };

  componentDidMount() {
    const showingRequestTypes = this.props.perType === REPORT_PER_TYPES.RequestType;
    const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
    const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
    const showingUsers = this.props.perType === REPORT_PER_TYPES.User;
    const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
    const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
    if (showingRequestTypes) {
      this.buildRequestTypesDataStructure();
    } else if (showingYears) {
      this.buildYearsDataStructure();
    } else if (showingMonths) {
      this.buildMonthsDataStructure();
    } else if (showingAssets) {
      this.buildAssetsDataStructure();
    } else if (showingPriorities) {
      this.buildPrioritiesDataStructure();
    } else if (showingUsers) {
      this.buildUsersDataStructure();
    } else {
      this.setState({ isBuildingDataStructure: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isFetching && this.props.isFetching) {
      this.setState({ isBuildingDataStructure: true });
    } else {
      const finishedLoading = prevProps.isFetching && !this.props.isFetching;
      const changedSortOrder =
        prevProps.sortingOnPerType !== this.props.sortingOnPerType ||
        prevProps.sortingOnPerSplitType !== this.props.sortingOnPerSplitType;
      if (finishedLoading || (!this.props.isFetching && changedSortOrder)) {
        const showingRequestTypes = this.props.perType === REPORT_PER_TYPES.RequestType;
        const showingAssets = this.props.perType === REPORT_PER_TYPES.Asset;
        const showingYears = this.props.perType === REPORT_PER_TYPES.Year;
        const showingUsers = this.props.perType === REPORT_PER_TYPES.User;
        const showingMonths = this.props.perType === REPORT_PER_TYPES.Month;
        const showingPriorities = this.props.perType === REPORT_PER_TYPES.Priority;
        if (showingRequestTypes) {
          this.buildRequestTypesDataStructure();
        } else if (showingYears) {
          this.buildYearsDataStructure();
        } else if (showingMonths) {
          this.buildMonthsDataStructure();
        } else if (showingAssets) {
          this.buildAssetsDataStructure();
        } else if (showingPriorities) {
          this.buildPrioritiesDataStructure();
        } else if (showingUsers) {
          this.buildUsersDataStructure();
        } else {
          this.setState({ isBuildingDataStructure: false });
        }
      }
    }
  }

  buildRequestTypesDataStructure = () => {
    if (this.props.requestTypes.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    let requestTypes = this.props.requestTypes.map(({ title, id }) => ({
      title,
      count: new Decimal(this.props.data[id] || 0),
    }));
    if (this.props.sortingOnType === this.props.type) {
      requestTypes = requestTypes.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      requestTypes = requestTypes.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      labels: requestTypes.map(({ title }) => title),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: requestTypes.map(({ count }) => count.toString()),
        },
      ],
      isEmpty: false,
      isBuildingDataStructure: false,
    });
  };

  buildUsersDataStructure = () => {
    if (this.props.users.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    let users = this.props.users.map(({ name, id }) => ({
      name,
      count: new Decimal(this.props.data[id] || 0),
    }));
    if (this.props.sortingOnType === this.props.type) {
      users = users.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      users = users.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.setState({
      labels: users.map(({ name }) => name),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: users.map(({ count }) => count.toString()),
        },
      ],
      isEmpty: false,
      isBuildingDataStructure: false,
    });
  };

  buildAssetsDataStructure = () => {
    if (this.props.assets.length === 0) {
      this.setState({ isBuildingDataStructure: false, isEmpty: true });
      return;
    }
    let assets = this.props.assets.map(({ title, id }) => ({
      title,
      count: new Decimal(this.props.data[id] || 0),
    }));
    if (this.props.sortingOnType === this.props.type) {
      assets = assets.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      assets = assets.sort((a, b) => a.title.localeCompare(b.title));
    }
    this.setState({
      labels: assets.map(({ title }) => title),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: assets.map(({ count }) => count.toString()),
        },
      ],
      isEmpty: false,
      isBuildingDataStructure: false,
    });
  };

  buildYearsDataStructure = () => {
    const currentYear = moment().year();
    let amountOfYearsSinceSystemWasCreated = currentYear - this.props.yearSystemWasCreated;
    let years = [];
    for (let i = 0; i <= amountOfYearsSinceSystemWasCreated; i++) {
      const year = currentYear - i;
      years.push({ year, count: new Decimal(this.props.data[year] || 0) });
    }
    if (this.props.sortingOnType === this.props.type) {
      years.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      years.sort((a, b) => a.year - b.year);
    }
    this.setState({
      labels: years.map(({ year }) => year),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: years.map(({ count }) => count.toString()),
        },
      ],
      isBuildingDataStructure: false,
      isEmpty: false,
    });
  };

  buildMonthsDataStructure = () => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({
        monthNum: i,
        month: HelperFunctions.capitalizeFirstLetter(
          moment()
            .month(i - 1)
            .format('MMM')
        ),
        count: new Decimal(this.props.data[i] || 0),
      });
    }
    if (this.props.sortingOnType === this.props.type) {
      months.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    } else if (this.props.sortingOnPerType === this.props.perType) {
      months.sort((a, b) => a.monthNum - b.monthNum);
    }
    this.setState({
      labels: months.map(({ month }) => month),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: months.map(({ count }) => count.toString()),
        },
      ],
      isBuildingDataStructure: false,
      isEmpty: false,
    });
  };

  buildPrioritiesDataStructure = () => {
    let priorities = [
      { priority: 'high', count: new Decimal(this.props.data['high'] || 0) },
      { priority: 'medium', count: new Decimal(this.props.data['medium'] || 0) },
      { priority: 'low', count: new Decimal(this.props.data['low'] || 0) },
    ];
    if (this.props.sortingOnType === this.props.type) {
      priorities.sort((a, b) => (b.count.greaterThan(a.count) ? 1 : -1));
    }
    this.setState({
      labels: priorities.map(({ priority }) => priority),
      datasets: [
        {
          maxBarThickness: 36,
          minBarLength: 2,
          backgroundColor: BAR_COLOR,
          data: priorities.map(({ count }) => count.toString()),
        },
      ],
      isEmpty: false,
      isBuildingDataStructure: false,
    });
  };

  renderContent = () => {
    if (this.props.isFetching || this.state.isBuildingDataStructure) return <Loader small />;
    if (this.state.isEmpty) {
      if (this.props.perType === REPORT_PER_TYPES.Asset) {
        if (this.props.showingRootAssets) {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={<FormattedMessage id="components.requests-overview.no-assets-empty-data-set.title" />}
                subtitle={
                  <FormattedMessage id="components.requests-overview.no-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        } else {
          return (
            <div className={styles['list-item-empty-data-set']}>
              <EmptyDataSet
                modal
                title={
                  <FormattedMessage id="components.requests-overview.no-children-assets-empty-data-set.title" />
                }
                subtitle={
                  <FormattedMessage id="components.requests-overview.no-children-assets-empty-data-set.subtitle" />
                }
              />
            </div>
          );
        }
      } else if (this.props.perType === REPORT_PER_TYPES.RequestType) {
        return (
          <EmptyDataSet
            modal
            title={
              <FormattedMessage id="components.requests-overview.no-request-types-empty-data-set.title" />
            }
            subtitle={
              <FormattedMessage id="components.requests-overview.no-request-types-empty-data-set.subtitle" />
            }
          />
        );
      }
    }
    return (
      <Bar
        data={{
          labels: this.state.labels,
          datasets: this.state.datasets,
        }}
        width={582}
        height={410}
        options={{
          legend: false,
          maintainAspectRatio: false,
          tooltips: {
            displayColors: false,
            callbacks: {
              title: (tooltipItem, { datasets }) => {
                const { label, datasetIndex } = tooltipItem[0];
                if (datasets.length > 1) {
                  let tooltipLabel = label;
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    tooltipLabel = this.props.intl.formatMessage({
                      id: `resources.work-order.prio.${label}`,
                    });
                  }
                  return `${tooltipLabel} - ${datasets[datasetIndex].label}`;
                } else {
                  if (this.props.perType === REPORT_PER_TYPES.Priority) {
                    return this.props.intl.formatMessage({ id: `resources.work-order.prio.${label}` });
                  }
                  return label;
                }
              },
              label: ({ value }) => {
                return this.props.intl.formatMessage(
                  {
                    id: 'components.requests-overview.count',
                  },
                  {
                    value,
                  }
                );
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  padding: 13,
                  fontStyle: '600',
                  suggestedMax: 10,
                  beginAtZero: true,
                  maxTicksLimit: 4,
                  callback: (value, index, values) => {
                    return this.props.intl.formatMessage(
                      {
                        id: 'components.requests-overview.count',
                      },
                      {
                        value,
                      }
                    );
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 11,
                  fontFamily: "'Open Sans', 'Helvetica Neue'",
                  fontColor: '#515151',
                  fontStyle: '600',
                  callback: (value, index, values) => {
                    if (this.props.perType === REPORT_PER_TYPES.Priority) {
                      return this.props.intl.formatMessage({ id: `resources.work-order.prio.${value}` });
                    }
                    return value;
                  },
                },
                gridLines: {
                  lineWidth: 0,
                  zeroLineColor: '#E1E1E1',
                },
              },
            ],
          },
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['chart-container']}>{this.renderContent()}</div>
      </>
    );
  }
}

export default injectIntl(Chart);
