import types from './types';

const fetchGroupsSuccess = ({ ids, pagination }) => ({
  type: types.FETCH_GROUPS_SUCCESS,
  payload: {
    ids,
    pagination,
  },
});
const searchGroups = searchTerm => ({ type: types.SEARCH_GROUPS, payload: searchTerm });

const fetchRequestAutomationGroupsSuccess = payload => ({
  type: types.FETCH_REQUEST_AUTOMATION_GROUPS_SUCCESS,
  payload,
});

const fetchTemplateFieldsSuccess = data => ({
  type: types.FETCH_TEMPLATE_FIELDS_SUCCESS,
  payload: data,
});

const fetchWorkOrderTypesSuccess = ({ ids }) => ({
  type: types.FETCH_WORK_ORDER_TYPES_SUCCESS,
  payload: {
    ids,
  },
});

const fetchDowntimeReasonsSuccess = ({ ids }) => ({
  type: types.FETCH_DOWN_TIME_REASONS_SUCCESS,
  payload: {
    ids,
  },
});

const searchWorkOrderTypes = searchTerm => ({ type: types.SEARCH_WORK_ORDER_TYPES, payload: searchTerm });
const fetchAssetTypesSuccess = ids => ({ type: types.FETCH_ASSET_TYPES_SUCCESS, payload: ids });
const searchAssetTypes = searchTerm => ({ type: types.SEARCH_ASSET_TYPES, payload: searchTerm });
const searchSparePartTypes = searchTerm => ({ type: types.SEARCH_SPARE_PART_TYPES, payload: searchTerm });

const fetchRequestTypesSuccess = ids => ({ type: types.FETCH_REQUEST_TYPES_SUCCESS, payload: ids });
const searchRequestTypes = searchTerm => ({ type: types.SEARCH_REQUEST_TYPES, payload: searchTerm });
const fetchRequestSelectableAssigneesSuccess = ids => ({
  type: types.FETCH_REQUEST_SELECTABLE_ASSIGNEES_SUCCESS,
  payload: ids,
});
const fetchRequestAutomationRulesSuccess = ids => ({
  type: types.FETCH_REQUEST_AUTOMATION_RULES_SUCCESS,
  payload: ids,
});

const fetchSparePartTypesSuccess = ids => ({ type: types.FETCH_SPARE_PART_TYPES_SUCCESS, payload: ids });

const selectTemplateField = field => ({ type: types.SELECT_TEMPLATE_FIELD, payload: field });
const unselectTemplateField = field => ({ type: types.UNSELECT_TEMPLATE_FIELD });
const prepareNewField = field => ({ type: types.PREPARE_NEW_FIELD, payload: field });

const sortTemplateField = ({ templateFieldId, fromIndex, toIndex, column }) => ({
  type: types.TEMPLATE_FIELD_SORTED,
  payload: { templateFieldId, fromIndex, toIndex, column },
});

const sortRuleItem = payload => ({
  type: types.REQUEST_AUTOMATION_RULE_SORTED,
  payload,
});

const sortTemplateFieldBetweenColumns = ({ templateFieldId, fromIndex, toIndex, fromColumn, toColumn }) => ({
  type: types.TEMPLATE_FIELD_SORTED_BETWEEN_COLUMNS,
  payload: { templateFieldId, fromIndex, toIndex, fromColumn, toColumn },
});

const fetchPurchaseOrderShippingAddresses = payload => ({
  type: types.FETCH_PURCHASE_ORDER_SHIPPING_ADDRESSES_SUCCESS,
  payload,
});

export default {
  fetchTemplateFieldsSuccess,
  fetchGroupsSuccess,
  fetchRequestAutomationGroupsSuccess,
  selectTemplateField,
  searchGroups,
  fetchWorkOrderTypesSuccess,
  searchWorkOrderTypes,
  unselectTemplateField,
  prepareNewField,
  sortTemplateField,
  fetchAssetTypesSuccess,
  fetchSparePartTypesSuccess,
  searchSparePartTypes,
  sortTemplateFieldBetweenColumns,
  sortRuleItem,
  searchAssetTypes,
  fetchRequestTypesSuccess,
  fetchRequestAutomationRulesSuccess,
  fetchRequestSelectableAssigneesSuccess,
  searchRequestTypes,
  fetchDowntimeReasonsSuccess,
  fetchPurchaseOrderShippingAddresses,
};
