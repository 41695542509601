import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AuthSelectors } from 'state/ducks/auth';
import { normalizeSystem } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { API } from 'sdk';
import SystemListItem from './SystemListItem';
import { EntityOperations } from 'sdk/State/entities';

class BillingInformationModal extends Component {
  state = {
    isFetching: false,
    systemIds: [],
    discounts: [],
  };

  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });

      const systemParams = {
        no_pagination: true,
        billable_user_count: true,
      };

      Promise.all([
        API.listSystemsForOrganisation(this.props.organisation.id, systemParams),
        API.listBillingDiscounts(this.props.organisation.id),
      ])
        .then(([systemsRes, discountsRes]) => {
          const { entities, result: systemIds } = normalizeSystem(systemsRes.data);
          this.props.updateEntities(entities);
          const discounts = discountsRes.data.filter(
            ({ expiration_date, tier }) => (expiration_date == null || moment(expiration_date).diff(moment(), 'days') >= 0) && tier == this.props.organisation.billing_tier
          )

          this.setState({ isFetching: false, systemIds, discounts });
        })
    }
  }

  renderList = () => {
    if (this.state.isFetching) {
      return (
        <>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
          <List.Item small>
            <List.Item.TitleColumn loading />
          </List.Item>
        </>
      );
    }
    return this.state.systemIds.map(id => {
      return <SystemListItem discounts={this.state.discounts} id={id} />;
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width="750">
        <Modal.Header
          title={<FormattedMessage id="screens.organisation.billing-information-modal.title" />}
          onClose={this.props.onClose}
        />
        <Modal.Content grayBackground>
          <List.Header small background>
            <List.Header.Column flex>
              <FormattedMessage id="resources.system.resource" />
            </List.Header.Column>
          </List.Header>
          <List small>{this.renderList()}</List>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    organisation: AuthSelectors.getCurrentOrganisation(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInformationModal);
