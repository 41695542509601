import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class PriorityText extends Component {
  render() {
    switch (this.props.priority) {
      case 'low':
        return <FormattedMessage id="resources.work-order.prio.low" />;
      case 'medium':
        return <FormattedMessage id="resources.work-order.prio.medium" />;
      case 'high':
        return <FormattedMessage id="resources.work-order.prio.high" />;
      default:
        return null;
    }
  }
}
