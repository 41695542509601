import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { AuthSelectors } from 'state/ducks/auth';
import { FormattedMessage } from 'react-intl';
import { OperatorCheckedInAssetSelectors } from 'state/ducks/operatorCheckedInAsset';
import { ProductionBoardSelectors, ProductionBoardOperations } from 'state/ducks/productionBoard';
import { SectionHeader, Button, Loader } from 'views/components/Shared/General';
import { PreviewRequestModal } from 'views/components/Request';
import RequestImage from 'assets/images/EmptyDataSet/Requests.png';
import styles from './style.module.scss';
import RequestListItem from './RequestListItem';

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      showPreviewRequestModal: false,
      showPreviewRequestModalForId: null,
    };
  }

  componentDidMount() {
    this.fetchRequests();
  }

  componentDidUpdate = prevProps => {
    const changedCheckedInAsset = prevProps.checkedInAssetId !== this.props.checkedInAssetId;
    if (changedCheckedInAsset) {
      this.setState({ isFetching: true });
      this.fetchRequests();
    }
  };

  fetchRequests = () => {
    this.props
      .fetchRequestsForDashboard(this.props.system.id, {
        asset_with_tree_children_id: this.props.checkedInAssetId,
      })
      .then(() => {
        this.setState({ isFetching: false });
      });
  };

  renderShowAllRequestsButton = () => {
    if (this.props.openRequestsCount > 3) {
      return (
        <div className={styles['requests-show-all-button-container']}>
          <Link
            to={{
              pathname: `/requests`,
              search: 'list=open',
            }}
          >
            <Button
              type="text"
              primary
              translate={false}
              label={
                <>
                  <FormattedMessage id="screens.production-board.overview.requests.button" />
                  <span> ({this.props.openRequestsCount})</span>
                </>
              }
              fontSize={12}
              noUnderline
            />
          </Link>
        </div>
      );
    }
    return null;
  };

  renderOpenRequestCells = () => {
    return (
      <div className={styles['open-request-cells']}>
        {this.props.openRequestIds.slice(0, 3).map(id => (
          <RequestListItem
            id={id}
            onClick={() =>
              this.setState({
                showPreviewRequestModal: true,
                showPreviewRequestModalForId: id,
              })
            }
          />
        ))}
      </div>
    );
  };

  renderLoader = () => (
    <div className={styles['open-request-cells']}>
      <Loader centerInParent tiny />
    </div>
  );

  renderEmptyDataset = () => {
    return (
      <div className={styles['empty-data-set-container']}>
        <div className={styles['image-container']}>
          <img src={RequestImage} alt="" />
        </div>
        <div className={styles['title']}>
          <FormattedMessage id="screens.production-board.overview.requests.empty-dataset" />
        </div>
      </div>
    );
  };

  renderOpenRequestsContent = () => {
    if (this.state.isFetching || this.props.isRefreshing) return this.renderLoader();
    if (this.props.openRequestIds.length === 0) return this.renderEmptyDataset();

    return (
      <>
        {this.renderOpenRequestCells()}
        {this.renderShowAllRequestsButton()}
      </>
    );
  };

  renderRequestModal = () => {
    return (
      <PreviewRequestModal
        open={this.state.showPreviewRequestModal}
        id={this.state.showPreviewRequestModalForId}
        onClose={() => {
          this.setState({ showPreviewRequestModal: false });
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div className={styles['request-card-container']}>
          <div className={styles['card-header']}>
            <SectionHeader paddingHorizontal={20} horizontalBorders noBorderBottom>
              <FormattedMessage id="screens.production-board.overview.requests.title" />
            </SectionHeader>
          </div>
          <div className={styles['request-card-content']}>{this.renderOpenRequestsContent()}</div>
        </div>
        {this.renderRequestModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRequestsForDashboard: ProductionBoardOperations.fetchRequestsForDashboard,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    system: AuthSelectors.getCurrentSystem(state),
    openRequestIds: ProductionBoardSelectors.getOpenRequestIds(state),
    checkedInAssetId: OperatorCheckedInAssetSelectors.getAssetId(state),
    openRequestsCount: ProductionBoardSelectors.getOpenRequestsCount(state),
    isRefreshing: ProductionBoardSelectors.isRefreshing(state),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Requests));
