import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/Shared/General';
import { TreePath, ChooseAssetInlineModal, AssetTitle } from 'views/components/Asset';
import { Grid } from 'views/components/Shared/Layout';

class TreeParentField extends Component {
  render() {
    return (
      <Grid.Row>
        <Grid.Column>
          <Field label={<FormattedMessage id="resources.asset.tree-parent" />}>
            <ChooseAssetInlineModal
              hideCreateButton
              trigger={
                <Field.Resource
                  angleDown
                  value={
                    this.props.value === null ? null : (
                      <>
                        <AssetTitle id={this.props.asset.id} />
                        <TreePath assetId={this.props.value} />
                      </>
                    )
                  }
                  onClear={() => this.props.onChange(null)}
                />
              }
              selectedAssetId={this.props.value}
              onSelectAsset={treeParentId => {
                this.props.onChange(treeParentId);
              }}
              onClear={e => this.props.onChange(null)}
            />
          </Field>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    asset: EntitySelectors.getAsset(state, ownProps.value),
  };
}

export default connect(mapStateToProps)(TreeParentField);
