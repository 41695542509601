import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { FormattedMessage } from 'react-intl';
import { Icon, FormattedNumber } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';
import { Field } from 'views/components/WorkOrder';
import styles from '../style.module.scss';

class Recurring extends Component {
  /*
    Render functions
  */

  renderRecurringMessage = () => {
    return buildIntervalMessage(this.props.recurringMaintenance, this.props.intl);
  };

  renderRecurringMeterMessage = () => {
    return (
      <FormattedMessage
        id="resources.work-order.meter-interval"
        values={{
          value: (
            <FormattedNumber
              value={this.props.recurringMaintenance.meter_interval}
              unit={this.props.meterUnit ? this.props.meterUnit.abbreviation : null}
            />
          ),
        }}
      />
    );
  };

  renderContent = () => {
    if (this.props.recurringMaintenance.status === 'active') {
      if (this.props.meter) {
        return this.renderRecurringMeterMessage();
      }
      return this.renderRecurringMessage();
    } else {
      return <FormattedMessage id="resources.work-order.interval.canceled" />;
    }
  };

  render() {
    if (this.props.recurringMaintenance == null) {
      return null;
    }
    return (
      <div className={styles['row']}>
        <Field
          icon={<Icon type="repeat" withBackground backgroundSize={36} />}
          label={<FormattedMessage id="resources.work-order.recurring" />}
          value={this.renderContent()}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const recurringMaintenance = EntitySelectors.getRecurringMaintenance(
    state,
    ownProps.workOrder.recurring_maintenance_id
  );
  if (recurringMaintenance == null) {
    return {};
  }
  const meter = EntitySelectors.getMeter(state, recurringMaintenance.meter_id);
  let meterUnit = null;
  if (meter) {
    meterUnit = EntitySelectors.getMeterUnit(state, meter.meter_unit_id);
  }
  return {
    recurringMaintenance,
    meter,
    meterUnit,
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(Recurring)));
