import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import queryString from 'query-string';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage, injectIntl } from 'react-intl';
import HelperFunctions from 'utilities/HelperFunctions';
import { ListLayout } from 'views/components/Shared/Layout';
import { Menu, Button, Icon } from 'views/components/Shared/General';
import { NewAssetModal } from 'views/components/Asset';
import { API, HelperFunctions as SDKHelperFunctions } from 'sdk';
import { normalizeAssetType } from 'sdk/Schemas';
import { AssetsOperations, AssetsSelectors } from 'state/ducks/assets';
import { MenuOperations, MenuUtils } from 'state/ducks/menu';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import Tree from './Tree';
import List from './List';
import AssetTypes from './AssetTypes';
import styles from './style.module.scss';

export const LIST_TYPES = {
  Tree: 'tree',
  List: 'list',
  Archived: 'archived',
};

class TreeAndList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateAssetModal: false,
      defaultParamsForCreateAssetModal: {},
      showSearchAssetTypesField: false,
      isFetchingAssetTypes: true,
      assetTypeIds: [],
      searchTerm: props.queryParameters.search || '',
      assetTypesSearchTerm: '',
    };
  }

  componentDidMount() {
    HelperFunctions.setDocumentTitle(this.props.intl.formatMessage({ id: 'screens.assets.document-title' }));
    this.props.selectMenuItem(MenuUtils.MENU_ITEM_TYPE.Asset);
    this.fetchAssetTypes();
    this.setListInUrlIfEmpty();
  }

  setListInUrlIfEmpty = () => {
    const { list, asset_type_id } = queryString.parse(this.props.location.search);
    if (list == null && asset_type_id == null) {
      this.props.history.replace(
        `?${SDKHelperFunctions.convertObjToQueryParameters({
          list: LIST_TYPES.Tree,
        })}`
      );
    }
  };

  fetchAssetTypes = () => {
    API.listAssetTypes(this.props.system.id).then(({ data: assetTypes }) => {
      const { result, entities } = normalizeAssetType(assetTypes);
      this.props.updateEntities(entities);
      this.setState({ isFetchingAssetTypes: false, assetTypeIds: result });
    });
  };

  setNavigatedTo = () => {
    const { list, asset_type_id } = queryString.parse(this.props.location.search);
    this.props.setNavigatedTo({
      search: {
        list,
        asset_type_id,
      },
    });
  };

  renderHeader = () => {
    return (
      <ListLayout.Header
        searchable
        searchValue={this.state.searchTerm}
        searchPlaceHolder={this.props.intl.formatMessage({
          id: 'general.search-placeholder',
        })}
        totalEntries={
          <FormattedMessage
            id="screens.assets.total-entries"
            values={{
              amount: this.props.pagination.totalEntries,
            }}
          />
        }
        onSearch={searchTerm => {
          this.setState({ searchTerm });
          this.props.addQueryParameter({ search: searchTerm });
        }}
        onClearSearch={() => {
          this.props.addQueryParameter({ search: '' });
          this.setState({ searchTerm: '' });
        }}
        title={<FormattedMessage id="screens.assets.title" />}
      />
    );
  };

  renderCreateButton = () => {
    if (this.props.canEditAssets) {
      return (
        <>
          <Button
            fullWidth
            primary
            label="screens.assets.create-button"
            onClick={() => {
              this.setState({ defaultParamsForCreateAssetModal: {}, showCreateAssetModal: true });
            }}
          />
          <Menu.Separator />
        </>
      );
    }
    return null;
  };

  renderSearchField = () => {
    return (
      <AnimateHeight duration={250} height={this.state.showSearchAssetTypesField ? 'auto' : 0}>
        <div className={styles['asset-type-search-container']}>
          <div className={styles['search-logo']}>
            <Icon regular type="search" />
          </div>
          <input
            ref={ref => (this.inputRef = ref)}
            className={styles['input']}
            placeholder={this.props.intl.formatMessage({
              id: 'general.search-placeholder',
            })}
            autoComplete="off"
            value={this.state.assetTypesSearchTerm}
            name="search"
            onChange={e => {
              this.setState({ isLoading: true, assetTypesSearchTerm: e.target.value });
            }}
          />
        </div>
      </AnimateHeight>
    );
  };

  renderSearch() {
    return (
      <div>
        <div className={styles['asset-type-search-header']}>
          <div className={styles['asset-type-search-title']}>
            <FormattedMessage id="resources.asset.type" />
          </div>
          <Button
            type="icon"
            icon={<Icon regular type="search" />}
            iconButtonSize={28}
            onClick={() => {
              this.setState(
                prevState => ({
                  showSearchAssetTypesField: !prevState.showSearchAssetTypesField,
                  assetTypesSearchTerm: '',
                }),
                () => {
                  if (this.state.showSearchAssetTypesField) {
                    this.inputRef.focus();
                  }
                }
              );
            }}
          />
        </div>
        {this.renderSearchField()}
      </div>
    );
  }

  renderAssetTypes = () => {
    const { asset_type_id } = queryString.parse(this.props.location.search);
    if (this.state.isFetchingAssetTypes) {
      return (
        <div className={styles['asset-container']}>
          {this.renderSearch()}
          <PerfectScrollbar>
            <div className={styles['items']}>
              <Menu.Item loading />
              <Menu.Item loading />
            </div>
          </PerfectScrollbar>
        </div>
      );
    }
    if (this.state.assetTypeIds.length === 0) {
      return null;
    }
    return (
      <div className={styles['asset-container']}>
        {this.renderSearch()}
        <PerfectScrollbar>
          <div className={styles['items']}>
            <AssetTypes
              selectedAssetTypeId={asset_type_id}
              ids={this.state.assetTypeIds}
              searchTerm={this.state.assetTypesSearchTerm}
              isSearching={this.state.showSearchAssetTypesField}
            />
          </div>
        </PerfectScrollbar>
      </div>
    );
  };

  renderLeftMenu = () => {
    const { list } = queryString.parse(this.props.location.search);
    return (
      <ListLayout.Content.Menu>
        <div className={styles['left-panel-container']}>
          <div className={styles['menu-items']}>
            <ListLayout.Content.Menu.Content>
              {this.renderCreateButton()}
              <Menu.Item
                linkTo={{
                  pathname: '/assets',
                  search: 'list=tree',
                  state: { reload: true },
                }}
                title={<FormattedMessage id="screens.assets.left-panel.tree" />}
                selected={list === LIST_TYPES.Tree}
              />
              <Menu.Item
                linkTo={{
                  pathname: '/assets',
                  search: 'list=list',
                }}
                title={<FormattedMessage id="screens.assets.left-panel.list" />}
                selected={list === LIST_TYPES.List}
              />
              <Menu.Expandable defaultExpanded={list === LIST_TYPES.Archived}>
                <Menu.Item
                  linkTo={{
                    pathname: '/assets',
                    search: 'list=archived',
                  }}
                  red
                  title={<FormattedMessage id="screens.assets.left-panel.archived" />}
                  selected={list === LIST_TYPES.Archived}
                />
              </Menu.Expandable>
            </ListLayout.Content.Menu.Content>
          </div>
          {this.renderAssetTypes()}
        </div>
      </ListLayout.Content.Menu>
    );
  };

  renderMainContent = () => {
    const { searchTerm } = this.state;
    const { list, asset_type_id } = queryString.parse(this.props.location.search);
    if (
      searchTerm.length > 0 ||
      list === LIST_TYPES.List ||
      list === LIST_TYPES.Archived ||
      asset_type_id != null
    ) {
      return (
        <ListLayout.Content.MainContent>
          <List assetTypeId={asset_type_id} searchTerm={searchTerm} />
        </ListLayout.Content.MainContent>
      );
    }
    if (list === LIST_TYPES.Tree) {
      return (
        <ListLayout.Content.MainContent>
          <Tree
            onCreateAssetForAsset={assetId =>
              this.setState({
                showCreateAssetModal: true,
                defaultParamsForCreateAssetModal: {
                  tree_parent_id: assetId,
                },
              })
            }
          />
        </ListLayout.Content.MainContent>
      );
    }
  };

  renderCreateAssetModal = () => {
    return (
      <NewAssetModal
        open={this.state.showCreateAssetModal}
        defaultParams={this.state.defaultParamsForCreateAssetModal}
        onClose={() => {
          this.setState({ showCreateAssetModal: false });
        }}
        onCreated={asset => {
          this.props.resetSelectedAssets();
          this.setNavigatedTo();
          this.setState({ showCreateAssetModal: false });
          setTimeout(() => {
            this.props.history.push(`/assets/${asset.id}`);
          }, 250);
        }}
        onCreatedWithReopen={() => {
          this.props.resetSelectedAssets();
          this.setState({ showCreateAssetModal: false });
          setTimeout(() => {
            this.setState({ showCreateAssetModal: true });
          }, 200);
        }}
      />
    );
  };

  render() {
    return (
      <>
        <ListLayout>
          {this.renderHeader()}
          <ListLayout.Content>
            {this.renderLeftMenu()}
            {this.renderMainContent()}
          </ListLayout.Content>
        </ListLayout>
        {this.renderCreateAssetModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSelectedAssets: AssetsOperations.resetSelectedAssets,
      selectMenuItem: MenuOperations.selectItem,
      fetchAssets: AssetsOperations.fetchAssets,
      updateEntities: EntityOperations.updateEntities,
      addQueryParameter: AssetsOperations.addQueryParameter,
      setNavigatedTo: AssetsOperations.setNavigatedTo,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    canEditAssets: AuthSelectors.canEditAssets(state),
    pagination: AssetsSelectors.getAssetListPagination(state),
    system: AuthSelectors.getCurrentSystem(state),
    queryParameters: AssetsSelectors.getQueryParameters(state),
    navigatedTo: AssetsSelectors.getNavigatedTo(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TreeAndList));
