import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getVendorSubtitle } from 'sdk/Vendor';
import { Modal } from 'views/components/Shared/Layout';
import { List } from 'views/components/Shared/General';
import { EntitySelectors } from 'sdk/State/entities';

class SelectVendorModal extends Component {
  shouldComponentUpdate(nextProps) {
    if (!this.props.open && !nextProps.open) return false;
    return true;
  }

  render() {
    return (
      <Modal isOpen={this.props.open} width={550}>
        <Modal.Header
          ignoreLine
          title={<FormattedMessage id="components.select-vendor-for-email-modal.title" />}
          subtitle={<FormattedMessage id="components.select-vendor-for-email-modal.subtitle" />}
          onClose={this.props.onClose}
        />
        <Modal.Content>
          <List light small>
            {this.props.vendors.map(vendor => (
              <List.Item clickable onClick={() => this.props.onSelectVendor(vendor)}>
                <List.Item.TitleColumn title={vendor.name} subtitle={getVendorSubtitle(vendor)} />
              </List.Item>
            ))}
          </List>
        </Modal.Content>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vendors: EntitySelectors.getVendors(state, ownProps.vendorIds) || [],
  };
}

export default connect(mapStateToProps)(SelectVendorModal);
