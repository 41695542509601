import { API, HelperFunctions } from 'sdk';
import actions from './actions';
import EntityOperations from 'sdk/State/entities/operations';
import { normalizeUser, normalizeGroup, normalizeVendor } from 'sdk/Schemas';

const fetchUsers =
  (systemId, params = {}) =>
  dispatch => {
    dispatch(actions.fetchUsers());
    return API.listUsers(systemId, {
      ...params,
      no_pagination: true,
      user_setting: 'labor_cost_per_hour',
    }).then(res => {
      const { entities, result } = normalizeUser(res.data);
      dispatch(EntityOperations.updateEntities(entities));
      dispatch(
        actions.fetchUsersSuccess({
          ids: result,
        })
      );
    });
  };

const fetchGroups = systemId => dispatch => {
  dispatch(actions.fetchGroups());
  return API.listGroups(systemId, { type: 'work_order_assignee' }).then(res => {
    const { entities, result } = normalizeGroup(res.data);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchGroupsSuccess({
        ids: result,
      })
    );
  });
};

const fetchVendors = (systemId, params) => dispatch => {
  dispatch(actions.fetchVendors());
  return API.listVendors(systemId, { ...params, assign_work_orders: true }).then(res => {
    const { entities, result } = normalizeVendor(res.data);
    const pagination = HelperFunctions.getPaginationFromHeader(res.headers);
    dispatch(EntityOperations.updateEntities(entities));
    dispatch(
      actions.fetchVendorsSuccess({
        ids: result,
        pagination,
      })
    );
    return pagination;
  });
};

export default {
  fetchUsers,
  fetchGroups,
  fetchVendors,
};
