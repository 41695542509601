import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AuthSelectors } from 'state/ducks/auth';
import { Button } from 'views/components/Shared/General';
import { Header as LayoutHeader } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import EditModal from './EditModal';
import { HelperFunctions } from 'sdk';
import CancelRepeatModal from 'views/scenes/WorkOrders/WorkOrderDetail/CancelRepeatModal';
import { TreePath } from 'views/components/Asset';
import { WorkOrdersSelectors } from 'state/ducks/workOrders';
import Menu from './Menu';
import styles from './style.module.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showCancelRepeatModal: false,
    };
  }

  getLinkForBreadCrumb = () => {
    const { search } = this.props.navigatedTo;
    return {
      pathname: '/work-orders',
      search: HelperFunctions.convertObjToQueryParameters(search),
    };
  };

  renderBreadcrumb = () => {
    return (
      <LayoutHeader.Breadcrumb>
        <LayoutHeader.BreadcrumbItem to={this.getLinkForBreadCrumb()}>
          <FormattedMessage id="screens.work-order.breadcrumb-root" />
        </LayoutHeader.BreadcrumbItem>
        <LayoutHeader.BreadcrumbItem>{this.props.workOrder.title}</LayoutHeader.BreadcrumbItem>
      </LayoutHeader.Breadcrumb>
    );
  };

  renderTabs = () => {
    const id = this.props.match.params.id;

    return (
      <LayoutHeader.TabBar>
        <LayoutHeader.TabBarItem
          to={`/work-orders/${id}`}
          active={matchPath(this.props.location.pathname, { path: '/work-orders/:id', exact: true }) != null}
        >
          <FormattedMessage id="screens.work-order.tabs.information" />
        </LayoutHeader.TabBarItem>
        {this.props.canViewWorkOrderCosts &&
        this.props.hasProTier &&
        this.props.currentSystem.costs_activated ? (
          <LayoutHeader.TabBarItem
            to={`/work-orders/${id}/costs`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/work-orders/:id/costs',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.work-order.tabs.costs" />
          </LayoutHeader.TabBarItem>
        ) : null}
        <LayoutHeader.TabBarItem
          to={`/work-orders/${id}/activities`}
          active={
            matchPath(this.props.location.pathname, {
              path: '/work-orders/:id/activities/',
              exact: true,
            }) != null
          }
        >
          <FormattedMessage id="screens.work-order.tabs.activities" />
        </LayoutHeader.TabBarItem>
        {this.props.workOrder.recurring_maintenance_id ? (
          <LayoutHeader.TabBarItem
            to={`/work-orders/${id}/history`}
            active={
              matchPath(this.props.location.pathname, {
                path: '/work-orders/:id/history',
                exact: true,
              }) != null
            }
          >
            <FormattedMessage id="screens.work-order.tabs.history" />
          </LayoutHeader.TabBarItem>
        ) : null}
      </LayoutHeader.TabBar>
    );
  };

  renderHeaderAssetPath = () => {
    if (this.props.asset == null) return null;
    return (
      <div className={styles['header-path-container']}>
        <TreePath
          size={13}
          renderItem={asset => (
            <Link to={`/assets/${asset.id}`}>
              <span className={styles['path-item']}>{asset.title}</span>
            </Link>
          )}
          fullPath
          assetId={this.props.asset.id}
        />
      </div>
    );
  };

  renderButtons = () => {
    return (
      <div className={styles['buttons']}>
        {this.props.canEditWorkOrder ? (
          <Button
            small
            label="screens.work-order.edit-planning-button"
            onClick={() => {
              if (this.props.workOrder.recurring_maintenance) {
                this.setState({ showEditModal: true });
              } else {
                this.props.history.push(`/work-orders/${this.props.match.params.id}/edit`);
              }
            }}
          />
        ) : null}
        <Menu onCancelRepeat={() => this.setState({ showCancelRepeatModal: true })} />
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <LayoutHeader
          title={this.props.workOrder.title}
          titleHoverText={this.props.workOrder.title}
          subtitle={`#${this.props.workOrder.number}`}
          breadcrumbComponent={this.renderBreadcrumb()}
          extraComponent={this.renderHeaderAssetPath()}
          tabBarComponent={this.renderTabs()}
          buttonsComponent={this.renderButtons()}
        />
        <EditModal
          workOrder={this.props.workOrder}
          onSelectRecurringWorkOrders={() => {
            this.setState({ showEditModal: false });
            this.props.history.push(
              `/work-orders/${this.props.match.params.id}/edit?recurring_maintenance_id=${this.props.workOrder.recurring_maintenance_id}`
            );
          }}
          onSelectSingleWorkOrder={() => {
            this.setState({ showEditModal: false });
            this.props.history.push(`/work-orders/${this.props.match.params.id}/edit`);
          }}
          open={this.state.showEditModal}
          onClose={() => {
            this.setState({ showEditModal: false });
          }}
        />
        <CancelRepeatModal
          workOrder={this.props.workOrder}
          onArchiveRecurringMaintenance={deletedWorkOrderIds => {
            if (deletedWorkOrderIds.includes(this.props.workOrder.id)) {
              this.props.history.push(this.getLinkForBreadCrumb());
            } else {
              this.setState({ showCancelRepeatModal: false });
            }
          }}
          open={this.state.showCancelRepeatModal}
          onClose={() => {
            this.setState({ showCancelRepeatModal: false });
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const workOrderId = ownProps.match.params.id;
  const workOrder = EntitySelectors.getWorkOrder(state, workOrderId);
  return {
    workOrder,
    recurringMaintenance: EntitySelectors.getRecurringMaintenance(state, workOrder.recurring_maintenance_id),
    asset: EntitySelectors.getAsset(state, workOrder.asset_id),
    canEditWorkOrder: AuthSelectors.canEditWorkOrder(state, workOrder),
    canAdministrateWorkOrders: AuthSelectors.canAdministrateWorkOrders(state),
    canViewWorkOrderCosts: AuthSelectors.canViewWorkOrderCosts(state),
    currentSystem: AuthSelectors.getCurrentSystem(state),
    navigatedTo: WorkOrdersSelectors.getNavigatedTo(state),
    hasProTier: AuthSelectors.hasProTier(state),
  };
}

export default withRouter(connect(mapStateToProps)(Header));
