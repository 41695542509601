import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityOperations } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { mutateTree } from '@atlaskit/tree/';
import { API } from 'sdk';
import { buildAtlaskitTree } from 'sdk/Asset';
import { Tree } from 'views/components/Asset';
import { normalizeAsset, normalizeAssetBookmark } from 'sdk/Schemas';
import { Modal } from 'views/components/Shared/Layout';
import { SectionHeader, Loader, EmptyDataSet, WhiteCard } from 'views/components/Shared/General';
import AssetItem from './AssetItem';
import BookmarkedAssetItem from './BookmarkedAssetItem';
import styles from './style.module.scss';

class NewSelectAssetModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      bookmarkedAssetIds: [],
      assetBookmarkIdForAssetId: {},
      tree: [],
      treeIsEmpty: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ isFetching: true });
      return Promise.all([
        API.fetchTree(this.props.currentSystem.id, {
          archived: false,
        }),
        API.listAssetBookmarks(this.props.currentSystem.id),
      ]).then(([{ data: tree }, { data: bookmarkedAssets }]) => {
        const { entities: treeEntities } = normalizeAsset(tree);
        const { entities: bookmarkedAssetEntities, result } = normalizeAssetBookmark(bookmarkedAssets);
        this.props.updateEntities({ ...treeEntities });
        this.props.updateEntities({ ...bookmarkedAssetEntities });
        let assetBookmarkIdForAssetId = {};
        result.forEach(id => {
          const { asset_id } = bookmarkedAssetEntities.assetBookmarkById[id];
          assetBookmarkIdForAssetId = {
            ...assetBookmarkIdForAssetId,
            [asset_id]: id,
          };
        });
        this.setState({
          isFetching: false,
          treeIsEmpty: tree.length === 0,
          tree: buildAtlaskitTree(tree),
          bookmarkedAssetIds: result,
          assetBookmarkIdForAssetId,
        });
      });
    }
  }

  expandTree = nodeId => {
    this.setState({
      tree: mutateTree(this.state.tree, nodeId, { isExpanded: true }),
    });
  };

  collapseTree = nodeId => {
    this.setState({
      tree: mutateTree(this.state.tree, nodeId, { isExpanded: false }),
    });
  };

  renderBookmarkedAssets = () => {
    if (this.state.isFetching) {
      return <Loader small centerInParent />;
    }
    if (this.state.bookmarkedAssetIds.length === 0) {
      return (
        <div className={styles['empty-data-set']}>
          <FormattedMessage id="components.manage-bookmarked-assets-modal.bookmarked-empty-data-set.title" />
        </div>
      );
    }
    return this.state.bookmarkedAssetIds.map(id => (
      <BookmarkedAssetItem
        key={id}
        id={id}
        onRemoveFromBookmark={({ assetId, assetBookmarkId }) =>
          this.setState(
            {
              assetBookmarkIdForAssetId: {
                ...this.state.assetBookmarkIdForAssetId,
                [assetId]: null,
              },
              bookmarkedAssetIds: this.state.bookmarkedAssetIds.filter(
                loopedId => loopedId !== assetBookmarkId
              ),
            },
            () => this.setState({ tree: mutateTree(this.state.tree, assetId) })
          )
        }
      />
    ));
  };

  renderMenuContent = () => {
    return (
      <>
        <SectionHeader paddingHorizontal={25} noBorderTop>
          <FormattedMessage id="components.manage-bookmarked-assets-modal.my-assets" />
        </SectionHeader>
        {this.renderBookmarkedAssets()}
      </>
    );
  };

  renderTreeNode = item => {
    return (
      <AssetItem
        id={item.data.asset.id}
        assetBookmarkId={this.state.assetBookmarkIdForAssetId[item.data.asset.id]}
        onAddToBookmark={({ assetId, assetBookmarkId }) =>
          this.setState(
            {
              assetBookmarkIdForAssetId: {
                ...this.state.assetBookmarkIdForAssetId,
                [assetId]: assetBookmarkId,
              },
              bookmarkedAssetIds: [...this.state.bookmarkedAssetIds, assetBookmarkId],
            },
            () => {
              this.setState({ tree: mutateTree(this.state.tree, item.data.asset.id) });
            }
          )
        }
        onRemoveFromBookmark={({ assetId, assetBookmarkId }) =>
          this.setState(
            {
              assetBookmarkIdForAssetId: {
                ...this.state.assetBookmarkIdForAssetId,
                [assetId]: null,
              },
              bookmarkedAssetIds: this.state.bookmarkedAssetIds.filter(
                loopedId => loopedId !== assetBookmarkId
              ),
            },
            () => this.setState({ tree: mutateTree(this.state.tree, item.data.asset.id) })
          )
        }
      />
    );
  };

  renderTreeEmptyDataSet = () => {
    return (
      <WhiteCard centerContent>
        <EmptyDataSet
          title={
            <FormattedMessage id="components.manage-bookmarked-assets-modal.tree-empty-data-set.title" />
          }
          subtitle={
            <FormattedMessage id="components.manage-bookmarked-assets-modal.tree-empty-data-set.subtitle" />
          }
          tiny
          listContainer
        />
      </WhiteCard>
    );
  };

  renderTree = () => {
    if (this.state.isFetching) {
      return <Loader small centerInParent />;
    }
    if (this.state.treeIsEmpty) {
      return this.renderTreeEmptyDataSet();
    }
    return (
      <Tree
        tree={this.state.tree}
        onExpand={this.expandTree}
        onCollapse={this.collapseTree}
        renderNode={item => this.renderTreeNode(item)}
        isDragEnabled={false}
        isNestingEnabled
      />
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={1100} fullHeight>
        <Modal.Header
          title={<FormattedMessage id="components.manage-bookmarked-assets-modal.title" />}
          subtitle={this.props.subtitle}
          onClose={this.props.onClose}
        />
        <Modal.ColumnLayout>
          <Modal.ColumnLayout.Menu>{this.renderMenuContent()}</Modal.ColumnLayout.Menu>
          <Modal.ColumnLayout.Container>
            <Modal.ColumnLayout.Container.Content>{this.renderTree()}</Modal.ColumnLayout.Container.Content>
          </Modal.ColumnLayout.Container>
        </Modal.ColumnLayout>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEntities: EntityOperations.updateEntities,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentSystem: AuthSelectors.getCurrentSystem(state),
    canEditAssets: AuthSelectors.canEditAssets(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewSelectAssetModal));
