import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { PurchaseOrderStatus } from 'sdk/PurchaseOrder';
import { InlineModal, Button, Icon } from 'views/components/Shared/General';
import OrderPurchaseOrder from './OrderPurchaseOrder';
import SendPurchaseOrderModal from './SendPurchaseOrderModal';
import CancelPurchaseOrder from './CancelPurchaseOrder';
import ChangeCurrencyModal from './ChangeCurrencyModal';
import DeleteModal from './DeleteModal';
import SendEmailModal from './SendEmailModal';
import PrintPurchaseOrderModal from './PrintPurchaseOrderModal';

class Menu extends Component {
  state = {
    dropdownOpen: false,
    isDeleting: false,
    showOrderPurchaseOrder: false,
    showSendPurchaseOrderModal: false,
    showSendPurchaseOrderModalFromOrdered: false,
    showCancelPurchaseOrderModal: false,
    showChangeCurrencyModal: false,
    showDeleteModal: false,
    showSendEmailModal: false,
    showPrintPurchaseOrderModal: false,
  };

  renderInlineModalListItems = () => {
    const { purchaseOrder } = this.props;

    return (
      <React.Fragment>
        <InlineModal.ListItem
          icon="print"
          iconThickness="regular"
          title={<FormattedMessage id="screens.purchase-order.menu.print" />}
          onClick={() => {
            this.setState({ dropdownOpen: false, showPrintPurchaseOrderModal: true });
          }}
        />
        {purchaseOrder.status === PurchaseOrderStatus.Ordered && !purchaseOrder.canceled ? (
          <InlineModal.ListItem
            icon="envelope"
            iconThickness="regular"
            title={<FormattedMessage id="screens.purchase-order.menu.send-email" />}
            onClick={() => {
              this.setState({
                dropdownOpen: false,
                showSendEmailModal: true,
              });
            }}
          />
        ) : null}
        <InlineModal.ListItem
          icon="dollar-sign"
          iconThickness="regular"
          title={<FormattedMessage id="screens.purchase-order.menu.change-currency" />}
          onClick={() => {
            this.setState({ dropdownOpen: false, showChangeCurrencyModal: true });
          }}
        />

        <InlineModal.Separator />
        {purchaseOrder.status === PurchaseOrderStatus.Ordered && !purchaseOrder.canceled ? (
          <InlineModal.ListItem
            icon="ban"
            iconThickness="regular"
            title={<FormattedMessage id="screens.purchase-order.menu.cancel" />}
            onClick={() => {
              this.setState({ dropdownOpen: false, showCancelPurchaseOrderModal: true });
            }}
          />
        ) : null}
        <InlineModal.ListItem
          icon="trash-alt"
          iconThickness="regular"
          iconColor="#C55050"
          destructive
          title={<FormattedMessage id="general.delete" />}
          onClick={() => {
            this.setState({ showDeleteModal: true, dropdownOpen: false });
          }}
        />
      </React.Fragment>
    );
  };

  renderInlnineModalBody = () => {
    return (
      <InlineModal.Body width={250} dropdown>
        {this.renderInlineModalListItems()}
      </InlineModal.Body>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Button.Group>
          {this.props.purchaseOrder.status === PurchaseOrderStatus.Ordered ? null : (
            <Button
              small
              label="screens.purchase-order.menu.order"
              onClick={() => {
                this.setState({ showOrderPurchaseOrder: true });
              }}
            />
          )}
          <div
            ref={ref => (this.inlineModalPositioningRef = ref)}
            onClick={() => {
              this.setState(prevState => ({
                dropdownOpen: !prevState.dropdownOpen,
              }));
            }}
          >
            <Button small icon={<Icon regular size={16} type="ellipsis-h" />} />
          </div>
          <InlineModal
            open={this.state.dropdownOpen}
            positionToRef={this.inlineModalPositioningRef}
            onClose={() => this.setState({ dropdownOpen: false })}
            position="right"
          >
            {this.renderInlnineModalBody()}
          </InlineModal>
        </Button.Group>
        <OrderPurchaseOrder
          open={this.state.showOrderPurchaseOrder}
          purchaseOrder={this.props.purchaseOrder}
          onOrdered={() => {
            this.setState({ showOrderPurchaseOrder: false }, () => {
              setTimeout(() => {
                this.setState({
                  showSendPurchaseOrderModal: true,
                  showSendPurchaseOrderModalFromOrdered: true,
                });
              }, 200);
            });
          }}
          onClose={() => {
            this.setState({ showOrderPurchaseOrder: false });
          }}
        />
        <SendPurchaseOrderModal
          open={this.state.showSendPurchaseOrderModal}
          fromOrdered={this.state.showSendPurchaseOrderModalFromOrdered}
          onSendEmail={() => {
            this.setState({ showSendPurchaseOrderModal: false });
            setTimeout(() => {
              this.setState({ showSendEmailModal: true });
            }, 200);
          }}
          onPrint={() => {
            this.setState({ showSendPurchaseOrderModal: false });
            setTimeout(() => {
              this.setState({ showPrintPurchaseOrderModal: true });
            }, 200);
          }}
          onClose={() => {
            this.setState({ showSendPurchaseOrderModal: false });
          }}
        />
        <CancelPurchaseOrder
          open={this.state.showCancelPurchaseOrderModal}
          purchaseOrder={this.props.purchaseOrder}
          onClose={() => {
            this.setState({ showCancelPurchaseOrderModal: false });
          }}
        />
        <ChangeCurrencyModal
          currency={this.props.purchaseOrder.currency}
          open={this.state.showChangeCurrencyModal}
          purchaseOrder={this.props.purchaseOrder}
          onClose={() => {
            this.setState({ showChangeCurrencyModal: false });
          }}
        />
        <DeleteModal
          open={this.state.showDeleteModal}
          purchaseOrder={this.props.purchaseOrder}
          onDelete={() => this.props.history.push('/purchase-orders')}
          onClose={() => {
            this.setState({ showDeleteModal: false });
          }}
        />
        <SendEmailModal
          open={this.state.showSendEmailModal}
          purchaseOrder={this.props.purchaseOrder}
          onClose={() => {
            this.setState({ showSendEmailModal: false });
          }}
        />
        <PrintPurchaseOrderModal
          open={this.state.showPrintPurchaseOrderModal}
          purchaseOrder={this.props.purchaseOrder}
          onClose={() => {
            this.setState({ showPrintPurchaseOrderModal: false });
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const purchaseOrderId = ownProps.match.params.id;
  return {
    purchaseOrder: EntitySelectors.getPurchaseOrder(state, purchaseOrderId),
  };
}

export default withRouter(connect(mapStateToProps)(Menu));
