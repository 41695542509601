import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'views/components/Shared/General';
import { AuthSelectors } from 'state/ducks/auth';
import styles from './style.module.scss';
import parentStyles from '../style.module.scss';

class DeprecatedVersion extends Component {
  state = {
    showAlert: true,
    showAlertAfterAnimation: true,
  };

  componentDidMount() {
    let showAlert = this.props.hasDeprecatedVersion;
    this.setState({ showAlert, showAlertAfterAnimation: showAlert });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasDeprecatedVersion === false && this.props.hasDeprecatedVersion === true) {
      this.setState({ showAlert: true, showAlertAfterAnimation: true });
    }
  }

  render() {
    let alertClassNames = [parentStyles['alert'], styles['alert']];
    if (this.state.showAlertAfterAnimation) {
      return (
        <AnimateHeight
          duration={250}
          height={this.state.showAlert ? 'auto' : 0}
          onAnimationEnd={() =>
            this.setState({
              showAlertAfterAnimation: false,
            })
          }
          contentClassName={alertClassNames.join(' ')}
        >
          <div className={styles['title']}>
            <FormattedMessage
              id="components.alert-message.update-available"
              values={{
                reload: (
                  <span onClick={() => window.location.reload()} className={styles['reload']}>
                    <FormattedMessage id="components.alert-message.reload" />
                  </span>
                ),
              }}
            />
          </div>
          <div>
            <Button
              type="icon"
              icon={<Icon light white type="times" size={16} />}
              iconColor="#FFF"
              onClick={e => {
                e.preventDefault();
                this.setState({ showAlert: false });
              }}
            />
          </div>
        </AnimateHeight>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    hasDeprecatedVersion: AuthSelectors.hasDeprecatedVersion(state),
  };
}

export default connect(mapStateToProps)(DeprecatedVersion);
