const getSelectedOperationalMaintenanceIds = state => {
  return state.operationalMaintenances.selectedOperationalMaintenances.ids;
};

const getPageIsSelected = state => {
  return state.operationalMaintenances.selectedOperationalMaintenances.pageIsSelected;
};

const getSelectedOperationalMaintenancesCount = state => {
  return Object.keys(state.operationalMaintenances.selectedOperationalMaintenances.ids).length;
};

const getQueryParametersForTemplates = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.templates.queryParams;
};

const getNavigatedToOperationalMaintenance = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.navigatedToOperationalMaintenance;
};

/*
  --------------------
    DATA SELECTORS
  --------------------
*/

const getOperationalMaintenances = state => {
  const { operationalMaintenances, entities } = state;
  return operationalMaintenances.list.ids.map(id => entities.operationalMaintenanceById[id]);
};

const getOperationalMaintenanceListCount = state => {
  return state.operationalMaintenances.list.count;
};

const getOperationalMaintenanceListCountLoading = state => {
  return state.operationalMaintenances.list.countLoading;
};

const isCountInitialized = state => {
  const isLoading = state.operationalMaintenances.list.countLoading === true;
  const hasCounts = Object.keys(state.operationalMaintenances.list.count).length > 0;
  return isLoading || hasCounts;
};

const getQueryParametersForOperationalMaintenances = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.list.queryParams;
};

const getOperationalMaintenancesForCalendar = state => {
  const { operationalMaintenances, entities } = state;
  return operationalMaintenances.calendar.operationalMaintenanceIds.map(
    id => entities.operationalMaintenanceById[id]
  );
};

const getOperationalMaintenancesForCalendarForAssetId = (state, assetId) => {
  const { operationalMaintenances, entities } = state;
  if (operationalMaintenances.calendar.operationalMaintenancesForAssetId[assetId]) {
    return operationalMaintenances.calendar.operationalMaintenancesForAssetId[assetId].map(
      id => entities.operationalMaintenanceById[id]
    );
  }
  return [];
};

const getShrinkedForAssetId = (state, assetId) => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.calendar.shrinkedAssetIds[assetId] === true;
};

const allAssetsAreShrinked = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.calendar.assetIds.every(
    id => operationalMaintenances.calendar.shrinkedAssetIds[id] === true
  );
};

const getOperationalMaintenanceTemplates = state => {
  const { operationalMaintenances, entities } = state;
  return operationalMaintenances.templates.ids.map(id => entities.operationalMaintenanceTemplateById[id]);
};

const getOperationalMaintenanceTemplatesPagination = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.templates.pagination;
};

const getOperationalMaintenanceBreaks = state => {
  const { operationalMaintenances, entities } = state;
  return operationalMaintenances.breaks.ids.map(id => entities.operationalMaintenanceBreakById[id]);
};

const getOperationalMaintenanceBreaksPagination = state => {
  const { operationalMaintenances } = state;
  return operationalMaintenances.breaks.pagination;
};

export default {
  getOperationalMaintenances,
  getOperationalMaintenanceListCount,
  getOperationalMaintenanceListCountLoading,
  getOperationalMaintenancesForCalendarForAssetId,
  getOperationalMaintenanceTemplates,
  getOperationalMaintenanceBreaks,
  getOperationalMaintenanceTemplatesPagination,
  getOperationalMaintenanceBreaksPagination,
  getShrinkedForAssetId,
  allAssetsAreShrinked,
  getOperationalMaintenancesForCalendar,
  getSelectedOperationalMaintenanceIds,
  getPageIsSelected,
  getSelectedOperationalMaintenancesCount,
  isCountInitialized,
  getQueryParametersForTemplates,
  getNavigatedToOperationalMaintenance,
  getQueryParametersForOperationalMaintenances,
};
