import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flatten } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { FullScreenImagePicker, WhiteCard, Icon, Tooltip } from 'views/components/Shared/General';
import { Image } from 'views/components/Image';
import styles from './style.module.scss';

class MainInfoContainer extends Component {
  state = {
    currentImage: 0,
    showingImageDialog: false,
  };

  hasRequestImages = () => {
    return this.props.requestImages && this.props.requestImages.length > 0;
  };

  hasWorkOrderImages = () => {
    return this.props.workOrderImages && this.props.workOrderImages.length > 0;
  };

  renderWorkOrderImages = () => {
    if (this.hasWorkOrderImages()) {
      return this.props.workOrderImages.map((image, index) => {
        return (
          <div className={styles['image']}>
            <Image
              small
              loading={false}
              circle
              editable={false}
              image={image}
              onClick={() => {
                this.selectImage(index);
              }}
            />
          </div>
        );
      });
    }
  };

  renderRequestImages = () => {
    if (this.hasRequestImages()) {
      return this.props.requestImages.map((image, index) => {
        return (
          <div className={styles['image']}>
            <div className={styles['request-image']}>
              <Image
                small
                loading={false}
                editable={false}
                circle
                image={image}
                onClick={() => {
                  this.selectImage(this.props.workOrderImages.length + index);
                }}
              />

              <div className={styles['icon']}>
                <Tooltip
                  trigger={
                    <div>
                      <Icon regular type="exclamation-circle" />
                    </div>
                  }
                  label={<FormattedMessage id="screens.work-order.image-from-request" />}
                />
              </div>
            </div>
          </div>
        );
      });
    }
  };

  renderImageSeparator = () => {
    return <div className={styles['separator']} />;
  };

  renderImages = () => {
    if (this.hasWorkOrderImages() || this.hasRequestImages()) {
      return (
        <div className={styles['images']}>
          {this.renderWorkOrderImages()}
          {this.hasWorkOrderImages() && this.hasRequestImages() ? this.renderImageSeparator() : null}
          {this.renderRequestImages()}
        </div>
      );
    }
    return null;
  };

  renderMessageFromLastWorkOrder = () => {
    return !this.props.workOrder.comment_from_last_work_order ? null : (
      <div className={styles['comment-from-last-work-order']}>
        <div>
          <Icon type="exclamation-triangle" />
        </div>
        <div>
          <p className={styles['title']}>
            <FormattedMessage id="resources.work-order.comment-from-last-work-order" />
          </p>
          <p className={styles['subtitle']}>{this.props.workOrder.comment_from_last_work_order}</p>
        </div>
      </div>
    );
  };

  selectImage = index => {
    this.setState({
      showingImageDialog: true,
      currentImage: index,
    });
  };

  renderFullScreenImagePicker = () => {
    if (this.props.workOrderImages && this.props.requestImages) {
      const images = [...this.props.workOrderImages, ...this.props.requestImages];
      return (
        <FullScreenImagePicker
          currentImage={this.state.currentImage}
          images={images}
          onChangeImage={index => this.selectImage(index)}
          open={this.state.showingImageDialog}
          onClose={() => this.setState({ showingImageDialog: false })}
        />
      );
    }
  };

  render() {
    return (
      <WhiteCard>
        <React.Fragment>
          <div className={styles['title']}>{this.props.workOrder.title}</div>
          <div
            className={`${styles['description']} ${
              !this.props.workOrder.description ? styles['no-description'] : ''
            }`}
          >
            {!this.props.workOrder.description ? (
              <FormattedMessage id="screens.work-order.no-description" />
            ) : (
              this.props.workOrder.description
            )}
          </div>
          {this.renderMessageFromLastWorkOrder()}
          {this.renderImages()}
        </React.Fragment>
        {this.renderFullScreenImagePicker()}
      </WhiteCard>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let requestImageIds = [];
  if (ownProps.workOrder.created_from_requests) {
    const arrayOfRequestImages = EntitySelectors.getRequests(
      state,
      ownProps.workOrder.created_from_requests
    ).map(request => request.images || []);
    requestImageIds = flatten(arrayOfRequestImages);
  }

  return {
    workOrderImages: EntitySelectors.getImages(state, ownProps.workOrder.registration_images),
    requestImages: EntitySelectors.getImages(state, requestImageIds),
  };
}

export default connect(mapStateToProps)(MainInfoContainer);
