import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { CardGrid } from 'views/components/Shared/Layout';
import { EntitySelectors } from 'sdk/State/entities';
import { buildIntervalMessage } from 'sdk/RecurringMaintenance/intervalMessageBuilder';
import { FormattedMessage } from 'react-intl';
import { WhiteCard, Icon } from 'views/components/Shared/General';
import { Field } from 'views/components/WorkOrder';
import { ElapsedTime } from 'views/components/Shared/General';
import { TreePath, AssetTitle } from 'views/components/Asset';
import styles from './style.module.scss';

class MetaInfoContainer extends Component {
  renderEstimatedTime = () => {
    if (this.props.operationalMaintenanceTemplateVersion.estimated_minutes) {
      return (
        <div className={`${styles['row']} ${styles['estimated-time']}`}>
          <Field
            icon={<Icon type="clock" withBackground orange backgroundSize={36} />}
            label={<FormattedMessage id="resources.operational-maintenance.estimated-minutes" />}
            value={
              <ElapsedTime
                elapsedMinutes={this.props.operationalMaintenanceTemplateVersion.estimated_minutes}
              />
            }
          />
        </div>
      );
    }
    return null;
  };

  renderDueDate = () => {
    return (
      <Field
        icon={<Icon type="calendar-alt" withBackground blue backgroundSize={36} />}
        label={<FormattedMessage id="resources.operational-maintenance.due-date" />}
        value={moment(this.props.operationalMaintenanceInstance.due_date).format('LL')}
      />
    );
  };

  renderAsset = () => {
    return (
      <Field
        icon={<Icon type="box-alt" withBackground backgroundSize={36} />}
        label={<FormattedMessage id="resources.asset.resource" />}
        value={
          <>
            <Link to={`/assets/${this.props.asset.id}`}>
              <AssetTitle id={this.props.asset.id} />
            </Link>
            {this.props.asset.tree_path && this.props.asset.tree_path.length === 0 ? null : (
              <div className={styles['tree-path-container']}>
                <TreePath assetId={this.props.asset.id} />
              </div>
            )}
          </>
        }
      />
    );
  };

  renderRecuccurenceValue = () => {
    return buildIntervalMessage(this.props.operationalMaintenanceTemplateRecurrenceVersion, this.props.intl);
  };

  renderRecurrence = () => {
    if (!this.props.operationalMaintenanceTemplateRecurrenceVersion) return null;

    return (
      <Field
        icon={<Icon type="repeat" withBackground backgroundSize={36} />}
        label={<FormattedMessage id="resources.operational-maintenance.recurring" />}
        value={this.renderRecuccurenceValue()}
      />
    );
  };

  render() {
    return (
      <CardGrid.Row>
        <WhiteCard padding="25px">
          <div className={styles['fields']}>
            {this.renderDueDate()}
            {this.renderEstimatedTime()}
            {this.renderAsset()}
            {this.renderRecurrence()}
          </div>
        </WhiteCard>
      </CardGrid.Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { operationalMaintenanceInstance } = ownProps;
  const operationalMaintenanceTemplateVersion = EntitySelectors.getOperationalMaintenanceTemplateVersion(
    state,
    operationalMaintenanceInstance.operational_maintenance_template_version_id
  );
  const asset = EntitySelectors.getAsset(state, operationalMaintenanceInstance.asset_id);
  let operationalMaintenanceTemplateRecurrenceVersion = null;
  if (operationalMaintenanceInstance.operational_maintenance_template_recurrence_version) {
    operationalMaintenanceTemplateRecurrenceVersion =
      EntitySelectors.getOperationalMaintenanceTemplateRecurrenceVersion(
        state,
        operationalMaintenanceInstance.operational_maintenance_template_recurrence_version_id
      );
  }
  return {
    operationalMaintenanceInstance,
    operationalMaintenanceTemplateVersion,
    operationalMaintenanceTemplateRecurrenceVersion,
    asset,
  };
}

export default connect(mapStateToProps)(injectIntl(MetaInfoContainer));
