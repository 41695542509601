import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, EmptyDataSet, List } from 'views/components/Shared/General';
import { Toolbar } from 'views/components/Shared/Layout';
import { FormattedMessage } from 'react-intl';
import { BackButton, Section, Separator } from 'views/scenes/Settings/components';
import { AuthSelectors } from 'state/ducks/auth';
import EditSparePartUnitModal from './EditSparePartUnitModal';
import styles from './style.module.scss';

class SparePartUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditSparePartUnitModal: false,
      showEditSparePartUnitModalForsparePartUnitId: null,
      createdIds: [],
    };
  }

  renderEmptyDataset = () => (
    <EmptyDataSet
      title={<FormattedMessage id="screens.settings.spare-parts.units.empty-data-set.title" />}
      button={
        <Button
          primary
          onClick={() =>
            this.setState({
              showEditSparePartUnitModal: true,
              showEditSparePartUnitModalForsparePartUnitId: null,
            })
          }
          label="screens.settings.spare-parts.units.empty-data-set.button"
        />
      }
      modal
    />
  );

  renderHeader = () => {
    return (
      <List.Header small background>
        <List.Header.Column flex>
          <FormattedMessage id="resources.spare-part-unit.title" />
        </List.Header.Column>
      </List.Header>
    );
  };

  renderListItem = sparePartUnit => (
    <List.Item
      clickable
      onClick={() =>
        this.setState({
          showEditSparePartUnitModal: true,
          showEditSparePartUnitModalForsparePartUnitId: sparePartUnit.id,
        })
      }
    >
      <List.Item.TitleColumn title={sparePartUnit.title} />
      {sparePartUnit.default ? (
        <List.Item.Column alignRight>
          <span className={styles['default']}>
            <FormattedMessage id="resources.spare-part-unit.default" />
          </span>
        </List.Item.Column>
      ) : null}
    </List.Item>
  );

  renderSparePartUnits = () => {
    const newSparePartUnits = this.state.createdIds
      .filter(createdId => this.props.sparePartUnits.find(({ id }) => id === createdId))
      .map(createdId => this.props.sparePartUnits.find(({ id }) => id === createdId));

    const oldSparePartUnits = this.props.sparePartUnits.filter(
      ({ id }) => !this.state.createdIds.includes(id)
    );
    return (
      <>
        <Toolbar
          buttons={
            <Button
              primary
              onClick={() =>
                this.setState({
                  showEditSparePartUnitModal: true,
                  showEditSparePartUnitModalForsparePartUnitId: null,
                })
              }
              label="screens.settings.spare-parts.units.add-button"
            />
          }
        />
        {this.renderHeader()}
        <List small>
          {newSparePartUnits.map(sparePartUnit => this.renderListItem(sparePartUnit))}
          {oldSparePartUnits.map(sparePartUnit => this.renderListItem(sparePartUnit))}
        </List>
      </>
    );
  };

  renderContent() {
    if (this.props.sparePartUnits.length === 0) {
      return this.renderEmptyDataset();
    } else {
      return this.renderSparePartUnits();
    }
  }

  render() {
    return (
      <>
        <BackButton to={this.props.fromSparePart ? '/settings/spare-parts' : '/settings/purchase-orders'} />
        <Section
          title={<FormattedMessage id="screens.settings.spare-parts.units.title" />}
          subtitle={<FormattedMessage id="screens.settings.spare-parts.units.subtitle" />}
        />
        <Separator />
        {this.renderContent()}
        <EditSparePartUnitModal
          open={this.state.showEditSparePartUnitModal}
          id={this.state.showEditSparePartUnitModalForsparePartUnitId}
          onClose={() => this.setState({ showEditSparePartUnitModal: false })}
          onCreate={id => {
            this.setState(prevState => ({
              showEditSparePartUnitModal: false,
              createdIds: [id, ...prevState.createdIds],
            }));
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sparePartUnits: AuthSelectors.getSparePartUnits(state),
  };
}

export default connect(mapStateToProps)(SparePartUnits);
