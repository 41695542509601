import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { ConfirmModal } from 'views/components/Shared/General';
import { Modal } from 'views/components/Shared/Layout';
import { EntityOperations } from 'sdk/State/entities';
import { normalizeDowntime } from 'sdk/Schemas';
import { SDKReduxOperations, API } from 'sdk';
import View from './View';
import Edit from './Edit';

class DowntimeModal extends Component {
  state = {
    isFetching: false,
    showDowntimeModal: false,
    mode: 'view',
    showDeleteDowntimeModal: false,
    showRemoveFromWorkOrderModal: false,
    showRemoveFromWorkOrderModalForId: null,
    showRemoveFromRequestModal: false,
    showRemoveFromRequestModalForId: null,
    isDeletingDowntime: false,
    isRemovingWorkOrderFromDowntime: false,
    isRemovingDowntimeFromRequest: false,
    defaultParams: {},
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.open && !nextProps.open && !this.state.showDowntimeModal && !nextState.showDowntimeModal)
      return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ defaultParams: { ...this.props.defaultParams } });
      if (this.props.id) {
        this.setState({ isFetching: true });
        API.fetchDowntime(this.props.id).then(({ data: downtime }) => {
          const { entities } = normalizeDowntime(downtime);
          this.props.updateEntities(entities);
          this.setState({ isFetching: false });
        });
        this.setState({ mode: 'view', showDowntimeModal: true });
      } else {
        this.setState({ mode: 'new', showDowntimeModal: true });
      }
    } else if (prevProps.open && !this.props.open) {
      this.setState({ showDowntimeModal: false });
    }
  }

  deleteDowntime = () => {
    this.setState({ isDeletingDowntime: true });
    this.props
      .deleteDowntime(this.props.id, {
        work_order_id: this.props.openedFromWorkOrderId,
        request_id: this.props.openedFromRequestId,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.delete-downtime-modal.delete-success" />}
          />
        );
        this.setState({ isDeletingDowntime: false, showDeleteDowntimeModal: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isDeletingDowntime: false });
      });
  };

  removeFromWorkOrder = () => {
    this.setState({ isRemovingWorkOrderFromDowntime: true });
    this.props
      .removeDowntimeFromWorkOrder(this.props.id, this.state.showRemoveFromWorkOrderModalForId)
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.downtime-modal.remove-from-work-order.success" />}
          />
        );
        this.setState({ isRemovingWorkOrderFromDowntime: false, showRemoveFromWorkOrderModal: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isRemovingWorkOrderFromDowntime: false });
      });
  };

  removeDowntimeFromRequest = () => {
    this.setState({ isRemovingDowntimeFromRequest: true });
    this.props
      .updateRequest(this.state.showRemoveFromRequestModalForId, {
        downtime_id: null,
        downtime: null,
      })
      .then(() => {
        toast(
          <ToastMessage
            success
            text={<FormattedMessage id="components.downtime-modal.remove-from-request.success" />}
          />
        );
        this.setState({ isRemovingDowntimeFromRequest: false, showRemoveFromRequestModal: false });
        this.props.onClose();
      })
      .catch(e => {
        this.setState({ isRemovingDowntimeFromRequest: false });
      });
  };

  renderDeleteDowntimeModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showDeleteDowntimeModal}
        title={<FormattedMessage id="components.delete-downtime-modal.title" />}
        message={<FormattedMessage id="components.delete-downtime-modal.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isDeletingDowntime}
        onConfirm={() => {
          this.deleteDowntime();
        }}
        onCancel={() => {
          this.setState({ showDeleteDowntimeModal: false });
          setTimeout(() => {
            this.setState({ showDowntimeModal: true });
          }, 250);
        }}
      />
    );
  };

  renderRemoveDowntimeFromWorkorderModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showRemoveFromWorkOrderModal}
        title={<FormattedMessage id="components.downtime-modal.remove-from-work-order.title" />}
        message={<FormattedMessage id="components.downtime-modal.remove-from-work-order.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isRemovingWorkOrderFromDowntime}
        onConfirm={() => {
          this.removeFromWorkOrder();
        }}
        onCancel={() => {
          this.setState({ showRemoveFromWorkOrderModal: false });
          this.props.onClose();
        }}
      />
    );
  };

  renderRemoveDowntimeFromRequestModal = () => {
    return (
      <ConfirmModal
        destructive
        open={this.state.showRemoveFromRequestModal}
        title={<FormattedMessage id="components.downtime-modal.remove-from-request.title" />}
        message={<FormattedMessage id="components.downtime-modal.remove-from-request.subtitle" />}
        confirmButtonText="general.delete"
        confirmIsLoading={this.state.isRemovingDowntimeFromRequest}
        onConfirm={() => {
          this.removeDowntimeFromRequest();
        }}
        onCancel={() => {
          this.setState({ showRemoveFromRequestModal: false });
          this.props.onClose();
        }}
      />
    );
  };

  renderContent = () => {
    switch (this.state.mode) {
      case 'new':
        return (
          <Edit
            new
            defaultTreeParentId={this.props.defaultTreeParentId}
            isCreatingActiveDowntime={this.props.isCreatingActiveDowntime}
            assetId={this.props.assetId}
            openedFromWorkOrderId={this.props.openedFromWorkOrderId}
            defaultParams={this.state.defaultParams}
            onClose={this.props.onClose}
            onCancel={() => {
              this.props.onClose();
            }}
            onSaved={() => {
              this.props.onClose();
            }}
          />
        );
      case 'edit':
        return (
          <Edit
            id={this.props.id}
            defaultParams={this.state.defaultParams}
            defaultTreeParentId={this.props.defaultTreeParentIdForAsset}
            onClose={this.props.onClose}
            onCancel={() => {
              this.setState({ mode: 'view' });
            }}
            onSaved={() => {
              this.setState({ mode: 'view' });
            }}
          />
        );
      case 'view': {
        if (this.state.isFetching || this.props.id == null) {
          return (
            <>
              <Modal.Content loading />
            </>
          );
        }
        return (
          <View
            id={this.props.id}
            onClose={this.props.onClose}
            openedFromWorkOrderId={this.props.openedFromWorkOrderId}
            onEdit={params => {
              this.setState({ mode: 'edit', defaultParams: { ...this.props.defaultParams, ...params } });
            }}
            onDelete={() => {
              this.setState({ showDowntimeModal: false });
              setTimeout(() => {
                this.setState({ showDeleteDowntimeModal: true });
              }, 250);
            }}
            onRemoveFromWorkOrder={id => {
              this.setState({ showDowntimeModal: false });
              setTimeout(() => {
                this.setState({ showRemoveFromWorkOrderModal: true, showRemoveFromWorkOrderModalForId: id });
              }, 250);
            }}
            onRemoveFromRequest={id => {
              this.setState({ showDowntimeModal: false });
              setTimeout(() => {
                this.setState({ showRemoveFromRequestModal: true, showRemoveFromRequestModalForId: id });
              }, 250);
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.showDowntimeModal} width={600}>
          {this.renderContent()}
        </Modal>
        {this.renderDeleteDowntimeModal()}
        {this.renderRemoveDowntimeFromWorkorderModal()}
        {this.renderRemoveDowntimeFromRequestModal()}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeDowntimeFromWorkOrder: SDKReduxOperations.removeDowntimeFromWorkOrder,
      deleteDowntime: SDKReduxOperations.deleteDowntime,
      updateEntities: EntityOperations.updateEntities,
      updateRequest: SDKReduxOperations.updateRequest,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DowntimeModal);
