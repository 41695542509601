export const ExportType = {
  WorkOrdersXslx: 'work_orders_xlsx',
  WorkOrdersListPdf: 'work_orders_list_pdf',
  WorkOrdersDetailsPdf: 'work_orders_detail_pdf',
  WorkOrdersReportXslx: 'work_orders_report_xlsx',
  RequestsXslx: 'requests_xlsx',
  RequestsListPdf: 'requests_list_pdf',
  RequestsDetailPdf: 'requests_detail_pdf',
  RequestsReportXslx: 'requests_report_xlsx',
  AssetsXslx: 'assets_xlsx',
  AssetsQRLabel: 'assets_qr_label',
  AssetsListPdf: 'assets_list_pdf',
  AssetsDetailPdf: 'assets_detail_pdf',
  SparePartsXslx: 'spare_parts_xlsx',
  SparePartsQRLabel: 'spare_parts_qr_label',
  SparePartsListPdf: 'spare_parts_list_pdf',
  SparePartsDetailPdf: 'spare_parts_detail_pdf',
  VendorsXlsx: 'vendors_xlsx',
  PurchaseOrdersXslx: 'purchase_orders_xlsx',
  OperationalMaintenancesListPdf: 'operational_maintenances_list_pdf',
  CostsReportXslx: 'costs_report_xlsx',
  SpentTimesReportXslx: 'spent_times_report_xlsx',
  SparePartWithdrawalsXlsx: 'spare_part_withdrawals_xlsx',
  MetersListPdf: 'meters_list_pdf',
  DowntimesXslx: 'downtimes_xlsx',
  CostsXslx: 'costs_xlsx',
};

export function maxQuantityAllowedForType(type) {
  switch (type) {
    case ExportType.WorkOrdersXslx:
    case ExportType.RequestsXslx:
    case ExportType.SparePartsXslx:
    case ExportType.AssetsXslx:
    case ExportType.VendorsXlsx:
    case ExportType.SparePartWithdrawalsXlsx:
    case ExportType.DowntimesXslx:
    case ExportType.PurchaseOrdersXslx: {
      return 20000;
    }
    case ExportType.WorkOrdersListPdf:
    case ExportType.WorkOrdersDetailsPdf:
    case ExportType.RequestsListPdf:
    case ExportType.RequestsDetailPdf:
    case ExportType.AssetsListPdf:
    case ExportType.AssetsDetailPdf:
    case ExportType.SparePartsListPdf:
    case ExportType.SparePartsDetailPdf:
    case ExportType.OperationalMaintenancesListPdf:
    case ExportType.AssetsQRLabel:
    case ExportType.SparePartsQRLabel:
    case ExportType.MetersListPdf: {
      return 500;
    }
    default:
      return 500;
  }
}
