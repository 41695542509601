import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { ToastMessage } from 'views/components/Shared/Layout';
import { FormattedMessage, injectIntl } from 'react-intl';
import { EntitySelectors } from 'sdk/State/entities';
import { AuthSelectors } from 'state/ducks/auth';
import { Field, Button, InlineModal, List, Icon } from 'views/components/Shared/General';
import { ProfilePicture, UserNameWrapper } from 'views/components/User';
import { Modal, Grid } from 'views/components/Shared/Layout';
import { SDKReduxOperations } from 'sdk';

class EditSelectableAssigneeModal extends Component {
  getInitialState = () => ({
    isSaving: false,
    isDeleting: false,
    hasUnsavedChanges: false,
    showDeleteInlineModal: false,
    confirmDelete: false,
    showTitleRequiredError: false,
    title: '',
    comment: '',
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.group) {
        this.setState({
          ...this.getInitialState(),
          title: this.props.group.title,
          comment: this.props.requestSelectableAssignee.comment,
        });
      } else if (this.props.user) {
        this.setState({
          ...this.getInitialState(),
          comment: this.props.requestSelectableAssignee.comment,
        });
      } else {
        this.setState({
          ...this.getInitialState(),
          title: '',
          comment: '',
        });
      }
    } else if (prevProps.open && !this.props.open) {
      window.onbeforeunload = undefined;
    }
  }

  save = () => {
    if (this.state.isSaving) return;
    if (this.props.user == null && this.state.title.length === 0) {
      this.setState({ showTitleRequiredError: true });
      return;
    }
    if (this.props.id) {
      this.update();
    } else {
      this.createGroup();
    }
  };

  createGroup = () => {
    this.setState({ isSaving: true });
    const { title, comment } = this.state;
    this.props
      .createGroup(this.props.system.id, { title, type: 'request_assignee' })
      .then(({ data }) => {
        this.props
          .createRequestSelectableAssignee(this.props.system.id, {
            group_id: data.id,
            comment,
          })
          .then(() => {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.create-group-success" />
                }
              />
            );
            this.props.onClose();
          });
      })
      .catch(e => {});
  };

  update = () => {
    const { title, comment } = this.state;
    if (this.props.group) {
      this.setState({ isSaving: true });
      this.props
        .updateGroup(this.props.group.id, { title })
        .then(({ data }) => {
          this.props
            .updateRequestSelectableAssignee(this.props.id, {
              comment,
            })
            .then(() => {
              toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
              this.props.onClose();
            });
        })
        .catch(e => {});
    } else {
      this.setState({ isSaving: true });
      this.props
        .updateRequestSelectableAssignee(this.props.id, {
          comment,
        })
        .then(() => {
          toast(<ToastMessage success text={<FormattedMessage id="general.update-success" />} />);
          this.props.onClose();
        });
    }
  };

  delete = () => {
    if (this.props.group) {
      this.setState({ isDeleting: true });
      this.props
        .deleteRequestSelectableAssignee(this.props.id)
        .then(() => {
          this.props.deleteGroup(this.props.group.id).then(() => {
            toast(
              <ToastMessage
                success
                text={
                  <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.delete-group-success" />
                }
              />
            );
            this.props.onClose();
          });
        })
        .catch(e => {
          this.setState({ isDeleting: false });
        });
    } else {
      this.setState({ isDeleting: true });
      this.props
        .deleteRequestSelectableAssignee(this.props.id)
        .then(() => {
          toast(
            <ToastMessage
              success
              text={
                <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.delete-user-success" />
              }
            />
          );
          this.props.onClose();
        })
        .catch(e => {
          this.setState({ isDeleting: false });
        });
    }
  };

  closeModal = () => {
    if (this.state.hasUnsavedChanges) {
      const confirmed = window.confirm(
        this.props.intl.formatMessage({ id: 'general.abort-unsaved-changes' })
      );
      if (confirmed) {
        window.onbeforeunload = undefined;
        this.props.onClose();
      }
    } else {
      window.onbeforeunload = undefined;
      this.props.onClose();
    }
  };

  renderRequestSelectableAssignee = () => {
    if (this.props.user) {
      return (
        <List small>
          <List.Item small>
            <List.Item.Column width={30}>
              <ProfilePicture userId={this.props.user.id} size={30} />
            </List.Item.Column>
            <List.Item.TitleColumn
              title={<UserNameWrapper user={this.props.user} />}
              subtitle={this.props.user.email}
            />
          </List.Item>
        </List>
      );
    }
    return (
      <Field
        view={false}
        label={
          <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.title" />
        }
      >
        <Field.Text
          autoFocus
          error={this.state.showTitleRequiredError}
          value={this.state.title}
          onChange={title => {
            if (this.state.showTitleRequiredError && title) {
              this.setState({ title, showTitleRequiredError: false });
            } else {
              this.setState({ title });
            }
          }}
        />
      </Field>
    );
  };

  renderTitle = () => {
    if (this.props.group) {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.edit-group-title" />
      );
    } else if (this.props.user) {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.edit-user-title" />
      );
    } else {
      return (
        <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.new-group-title" />
      );
    }
  };

  renderIconButtons = () => {
    if (this.props.group || this.props.user) {
      return (
        <>
          <div ref={ref => (this.inlineModalPositioningRef = ref)}>
            <Button
              type="icon"
              icon={<Icon regular red type="trash-alt" />}
              onClick={() =>
                this.setState(prevState => ({
                  confirmDelete: false,
                  showDeleteInlineModal: !prevState.showDeleteInlineModal,
                }))
              }
            />
          </div>
          <InlineModal
            open={this.state.showDeleteInlineModal}
            positionToRef={this.inlineModalPositioningRef}
            position="right"
            onClose={() => this.setState({ showDeleteInlineModal: false })}
          >
            <InlineModal.Header
              title={
                <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.delete-assignee-inline-modal.title" />
              }
              onClose={() => {
                this.setState({ showDeleteInlineModal: false });
              }}
            />
            <InlineModal.Body width={300}>
              <InlineModal.Delete loading={this.state.isDeleting} onDelete={this.delete}>
                <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-assignee-modal.delete-assignee-inline-modal.subtitle" />
              </InlineModal.Delete>
            </InlineModal.Body>
          </InlineModal>
        </>
      );
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.open} width={440}>
        <Modal.Header
          title={this.renderTitle()}
          iconButtons={this.renderIconButtons()}
          onClose={this.closeModal}
        />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>{this.renderRequestSelectableAssignee()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field
                  view={false}
                  label={
                    <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-comment-modal.comment" />
                  }
                  questionTooltipContent={
                    <FormattedMessage id="screens.settings.requests.assignees.creator-chooses.edit-comment-modal.comment-tooltip" />
                  }
                >
                  <Field.Textarea
                    value={this.state.comment}
                    onChange={comment => {
                      this.setState({ comment });
                    }}
                  />
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <Button.Group>
            <Button primary label="general.save" loading={this.state.isSaving} onClick={this.save} />
            <Button label="general.cancel" onClick={this.closeModal} />
          </Button.Group>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createGroup: SDKReduxOperations.createGroup,
      updateGroup: SDKReduxOperations.updateGroup,
      deleteGroup: SDKReduxOperations.deleteGroup,
      createRequestSelectableAssignee: SDKReduxOperations.createRequestSelectableAssignee,
      updateRequestSelectableAssignee: SDKReduxOperations.updateRequestSelectableAssignee,
      deleteRequestSelectableAssignee: SDKReduxOperations.deleteRequestSelectableAssignee,
    },
    dispatch
  );
}

function mapStateToProps(state, ownProps) {
  const requestSelectableAssignee = EntitySelectors.getRequestSelectableAssignee(state, ownProps.id);
  const { user_id, group_id } = requestSelectableAssignee || {};
  if (user_id) {
    return {
      requestSelectableAssignee,
      user: EntitySelectors.getUser(state, user_id),
      system: AuthSelectors.getCurrentSystem(state),
    };
  }
  return {
    requestSelectableAssignee,
    group: EntitySelectors.getGroup(state, group_id),
    system: AuthSelectors.getCurrentSystem(state),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditSelectableAssigneeModal));
