import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Tooltip } from 'views/components/Shared/General';
import { UserNameWrapper } from 'views/components/User';
import EditCommentModal from './EditCommentModal';
import { Type } from 'sdk/ChecklistTemplateRow';
import { EntitySelectors } from 'sdk/State/entities';
import styles from './style.module.scss';

class InspectionOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditCommentModal: false,
    };
  }

  renderEditCommentModal = () => {
    if (this.props.instanceRow) {
      return (
        <EditCommentModal
          open={this.state.showEditCommentModal}
          id={this.props.instanceRow.id}
          onClose={() => {
            this.setState({ showEditCommentModal: false });
          }}
        />
      );
    }
    return null;
  };

  renderUpdatedByUser = () => {
    let classNames = [styles['updated-by-user']];
    if (this.props.editable === false) {
      classNames = [...classNames, styles['not-editable']];
    }
    let userNameElement = '...';
    if (this.props.updatedByUser) {
      classNames = [...classNames, styles['has-value']];
      userNameElement = (
        <Tooltip
          trigger={<UserNameWrapper user={this.props.updatedByUser} />}
          label={<UserNameWrapper user={this.props.updatedByUser} />}
        />
      );
    }

    return (
      <div className={classNames.join(' ')}>
        <div className={styles['name']}>{userNameElement}</div>
        <div className={styles['icon']}>
          <Icon size={13} type="user" />
        </div>
      </div>
    );
  };

  renderComment = () => {
    let classNames = [styles['comment']];
    const { editable, instanceRow, images } = this.props;
    if (instanceRow && (instanceRow.comment || images.length > 0)) {
      classNames = [...classNames, styles['has-value']];
    }
    if (editable) {
      classNames = [...classNames, styles['editable']];
    }
    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          if (editable) {
            this.setState({ showEditCommentModal: true });
          }
        }}
      >
        <div className={styles['icon']}>
          <Icon size={13} type="comment" />
        </div>
      </div>
    );
  };

  renderEditCommentModal = () => {
    if (this.props.instanceRow) {
      return (
        <EditCommentModal
          open={this.state.showEditCommentModal}
          instanceRowId={this.props.instanceRowId}
          onClose={() => {
            this.setState({ showEditCommentModal: false });
          }}
        />
      );
    }
    return null;
  };

  render() {
    let classNames = [styles['extra-buttons']];
    if (this.props.editable === false) {
      classNames = [...classNames, styles['not-editable']];
    }
    return (
      <>
        <div className={classNames.join(' ')}>
          {this.renderUpdatedByUser()}
          {this.renderComment()}
        </div>
        {this.renderEditCommentModal()}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { instanceRowId } = ownProps;
  if (instanceRowId) {
    const instanceRow = EntitySelectors.getChecklistInstanceRow(state, instanceRowId);
    const { template_row_type } = instanceRow;
    if (template_row_type === Type.Inspection) {
      return {
        instanceRow,
        updatedByUser: EntitySelectors.getUser(state, instanceRow.updated_by_user_id),
        images: EntitySelectors.getImages(state, instanceRow.images),
      };
    }
    if (template_row_type === Type.Meter) {
      const meterReading = EntitySelectors.getMeterReading(state, instanceRow.meter_reading);
      return {
        instanceRow,
        updatedByUser: EntitySelectors.getUser(
          state,
          meterReading == null ? null : meterReading.added_by_user_id
        ),
        images: EntitySelectors.getImages(state, instanceRow.images),
      };
    }
  }
  return {};
}

export default connect(mapStateToProps)(InspectionOptions);

InspectionOptions.propTypes = {
  editable: PropTypes.bool,
  instanceRowId: PropTypes.bool,
};

InspectionOptions.defaultProps = {
  editable: true,
  instanceRowId: null,
};
